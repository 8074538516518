//VERY IMPORTANT VERY IMPORT
//DO NOT MISS THIS
//The only way I found to make this config properly work was
//to remove it from the global configuration file (sfe-entity-date-configuration.service)
//and manually add it into the directive, by injecting it into a controller and then
//giving it to sfe-list-2 using its variable listConfig

InvoiceDetailConfiguration.$inject = ['gettext', '$q', '$state'];
function InvoiceDetailConfiguration(gettext, $q, $state) {
  var configuration;
  let invoiceId;

  // we have this functions so that invoiceId is updated every time we open an invoice
  //(if we didn't do that) it would never update and be always undefined
  function updateFn() {
    invoiceId = $state.params.invoiceId;
    configuration.title.link.params[1].value = invoiceId;
    configuration.shortActions[0].link.params[1].value = invoiceId;
    configuration.buttons[0].link.params[0].value = invoiceId;
  }
  configuration = {
    update: updateFn,
    listId: 'InvoiceDetailListId',
    entity: {
      singular: gettext('Invoice Detail'),
      plural: gettext('Invoice Details')
    },
    api: {
      query: {
        method: 'custom.readDetailInvoice',
        entity: 'detail-invoices'
      },
      enrich: Enrich
    },
    title: {
      param: 'invoiceName',
      link: {
        state: 'invoices-invoices-detail-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'invoiceDetailId'
          },
          {
            value: invoiceId,
            queryParam: 'invoiceId'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'invoices-invoices-detail-new',
          params: [
            {
              value: invoiceId,
              queryParam: 'invoiceId'
            }
          ]
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'invoices-invoices-detail-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'invoiceDetailId'
            },
            {
              value: invoiceId,
              queryParam: 'invoiceId'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'detail-invoices',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Energy source type'),
        param: 'energySourceType',
        type: 'text'
      },
      {
        title: gettext('Amount'),
        param: 'sum',
        type: 'text'
      },
      {
        title: gettext('Measuring Point'),
        param: 'measuringPointName',
        link: {
          state: 'company-resources-measuring-points-view',
          params: [
            {
              valueParam: 'measuringPointId',
              queryParam: 'id'
            }
          ]
        },
        type: 'link'
      },
      {
        title: gettext('Location'),
        param: 'locationName',
        link: {
          state: 'company-resources-locations-view',
          params: [
            {
              valueParam: 'locationId',
              queryParam: 'id'
            }
          ]
        },
        type: 'link'
      }
    ],
    badges: [
      {
        title: gettext('Icon'),
        param: 'icon',
        type: 'icon'
      }
    ],
    filter: [
      {
        query: {
          entity: 'measuringpoints',
          method: 'read'
        },
        entity: 'measuringpoints',
        display: gettext('Measuring Point'),
        param: 'measuringPoint',
        displayList: ['name'],
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        query: {
          entity: 'energy-source-types',
          method: 'read'
        },
        entity: 'energy-source-types',
        display: gettext('Energy Source Type'),
        param: 'energySourceType',
        displayList: ['code', 'name'],
        refreshObject: {
          order: 'code'
        },
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        query: {
          entity: 'master-invoices',
          method: 'read'
        },
        entity: 'master-invoices',
        display: gettext('Master Invoice'),
        param: 'masterInvoice',
        displayList: ['number'],
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      }
    ]
  };

  /*
   *
   *   ENRICH
   *
   */
  function Enrich(item) {
    var deferred = $q.defer();
    if (
      typeof item.absoluteIndex !== 'undefined' &&
      item.absoluteIndex !== null
    ) {
      item.invoiceName = 'Invoice ' + item.absoluteIndex;
    } else {
      item.invoiceName = 'Invoice ' + item._id;
    }
    if (
      typeof item.energySourceType === 'object' &&
      item.energySourceType != null
    ) {
      item.icon = item.energySourceType.icon;
      item.energySourceType = item.energySourceType.name;
    }

    if (
      typeof item.measuringPoint === 'object' &&
      item.measuringPoint != null
    ) {
      var location = item.measuringPoint.location;
      item.measuringPointName = item.measuringPoint.name;
      item.measuringPointId = item.measuringPoint._id;
      item.locationName = location ? location.name : '';
      item.locationId = location ? location._id : null;
    }
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default InvoiceDetailConfiguration;
