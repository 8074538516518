import template from './analysis-form-filter-repeat.component.html';
/**
 * @ngdoc component
 * @name analysis.AnalysisFormFilterRepeat
 * @description This component is used to add filters and add/change its filter presets
 * @param {Array} filters - list of selected filters
 * @param {Array} columns - list of all column belonging to a dataset
 * @param {boolean} edit - Tells us, if we are creating or editing a form
 * @param {boolean} presetButtons - Tells us, if we should display preset buttons or not.
 * @param {Array} inputFilters - One way binding that passes filters on page load.
 * @example
 * <analysis-form-filter-repeat
 *   filters="vm.filters"
 *   columns="vm.columns"
 *   edit="vm.editMode"
 *   presetButtons="true"
 *   inputFilters="vm.inputFilters"
 * ></analysis-form-filter-repeat>
 */
export default {
  template,
  restrict: 'E',
  bindings: {
    filters: '=?',
    columns: '<',
    edit: '=',
    presetButtons: '=',
    inputFilters: '<'
  },
  require: {
    form: '^^form'
  },
  controller: AnalysisFormFilterRepeatController,
  controllerAs: 'vm',
  bindToController: true
};

AnalysisFormFilterRepeatController.$inject = [
  'gettext',
  '$timeout',
  'TranslationService',
  'AnalysisFilterBuilder',
  'AlertingService',
  '$scope'
];

function AnalysisFormFilterRepeatController(
  gettext,
  $timeout,
  TranslationService,
  AnalysisFilterBuilder,
  AlertingService,
  $scope
) {
  const vm = this;
  let filterOptions = [];
  vm.removeFilter = removeFilter;

  /**
   * @description when inputFilters are changed it adds the two together to create vm.filters..
   * @function
   */
  vm.$onChanges = async function(changes) {
    if (changes.inputFilters && Array.isArray(vm.inputFilters)) {
      try {
        vm.filters = await initiateFilters(vm.inputFilters, vm.columns);
        $scope.$applyAsync();
      } catch (err) {
        AlertingService.Error(err);
      }
    }
  };

  var filterConfig = {
    label: gettext('Filter'),
    placeholder: gettext('Select Filter'),
    ctrlFn: getFilterOptions,
    displayOptions: ['displayEntityName', 'default'],
    valueField: 'rawFieldName',
    onClose: filterChanged
  };

  vm.addFilter = [
    {
      title: gettext('Add a filter'),
      fn: addFilter,
      color: 'primary',
      disabledFn: function() {
        return vm.filters ? filterOptions.length <= vm.filters.length : false;
      }
    }
  ];
  /**
   * @description function that is called upon filter initiation.
   * It calls a function that creates all filter options and
   * @function
   * @param {Array} inputFilters - the initial filter data on page load/dataset change
   * @param {Array} columns - list of columns associated with selected dataset
   * @return {Array} array containing enriched filter data.
   */
  async function initiateFilters(inputFilters, columns) {
    filterOptions = initFilterOptions(vm.columns);
    try {
      const analysisConfigs = await AnalysisFilterBuilder.buildFilters(
        inputFilters,
        columns
      );
      if (analysisConfigs) {
        return analysisConfigs.map((config, index) => ({
          ...config,
          mongoId: config._id,
          selectedFilterEntity: {
            ...config.selectedFilterEntity,
            _id: config.rawFieldName
          },
          isPreset:
            !!(config.filterValues && config.filterValues.length) ||
            !!(config.entityItemObjects && config.entityItemObjects.length),
          filterConfig: {
            ...filterConfig,
            edit: true, //this is always true, because even when creating, the input value is input.
            ctrlFn: getFilterOptions.bind({
              index: index
            })
          },
          filterOptions: angular.copy(filterOptions)
        }));
      }
      return [];
    } catch (err) {
      AlertingService.Error(err);
      return [];
    }
  }
  //called when you change a filter
  /**
   * @description function called when filter is changed.
   * the function doesn't return anything, but it changes vm.filter at a specific index.
   * @function
   * @param {Object} selectedFilterEntity - data of the filter being changed.
   */
  async function filterChanged(selectedFilterEntity) {
    if (selectedFilterEntity !== undefined) {
      const selected = filterOptions.find(
        item => item.rawFieldName === selectedFilterEntity._id
      );
      let changedItemIndex = vm.filters.findIndex(
        item => item.selectedFilterEntity._id === selected.rawFieldName
      );
      const changedItem = vm.filters[changedItemIndex];
      if (changedItem.filterValues) {
        changedItem.filterValues = [];
      }
      if (changedItem.available) {
        changedItem.available = [];
        changedItem.entityItemObjects = [];
      }
      changedItem.rawFieldName = changedItem.selectedFilterEntity._id;
      try {
        const {
          0: enrichedFilterValues
        } = await AnalysisFilterBuilder.buildFilters(
          [{ rawFieldName: changedItem.selectedFilterEntity._id }],
          vm.columns
        );
        $timeout(() => {
          vm.filters[changedItemIndex] = {
            ...changedItem,
            ...enrichedFilterValues
          };
        });
      } catch (err) {
        AlertingService.Error(err);
      }
    }
  }
  /**
   * @description A function which is called when we we want to list all available filters.
   * It calls a function that gets all the available filter options for the selected dataset.
   * Before it returns the value, it removes all the already selected values, except the one
   * that is already selected in the field we clicked
   * @function
   * @return {Array} all available filter values
   */
  function getFilterOptions() {
    const currentFieldIndex = this.index;
    filterOptions = initFilterOptions(vm.columns);
    return $timeout(function() {
      var listOfCurrentlySelectedFilters = angular.copy(vm.filters);
      if (vm.filters) {
        return filterOptions.reduce((array, filterOption) => {
          const isNotCurrentlySelected = !listOfCurrentlySelectedFilters.some(
            (filter, index) => {
              if (currentFieldIndex !== index) {
                return filter.rawFieldName === filterOption.rawFieldName;
              }
            }
          );
          if (isNotCurrentlySelected) {
            return [...array, filterOption];
          }
          return array;
        }, []);
      }
      return filterOptions;
    });
  }

  function initFilterOptions(columns) {
    return columns
      .filter(column => {
        if (column.filterEnabled) {
          return true;
        }
      })
      .map(filterOption => {
        //type 2 is integer type, which has specific rules
        if (filterOption.type !== 2) {
          const displayNameEntityObject = TranslationService.GetCollectionById(
            'codelists.entities',
            filterOption.entity
          );
          if (displayNameEntityObject) {
            return {
              ...filterOption,
              displayEntityName: displayNameEntityObject.name
            };
          }
          return {
            ...filterOption,
            displayEntityName: filterOption.displayFieldName
          };
        }
        const { entity, ...everythingElse } = filterOption;
        return {
          ...everythingElse,
          displayEntityName: filterOption.displayFieldName
        };
      });
  }

  function removeFilter(indexToDel) {
    vm.filters.splice(indexToDel, 1);
    vm.filters.forEach(function(filter, index) {
      filter.filterConfig.ctrlFn = getFilterOptions.bind({
        index: index
      });
    });
  }
  /**
   * @description Adds a new filter to the components and updates all .
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function addFilter() {
    if (!vm.filters) {
      vm.filters = [];
    }
    const newFilter = {
      entityItems: [],
      filterConfig: angular.copy(filterConfig),
      model: {}
    };
    vm.filters = vm.filters.concat(newFilter).map((filter, index) => {
      return {
        ...filter,
        filterConfig: {
          ...filter.filterConfig,
          ctrlFn: getFilterOptions.bind({
            index: index
          })
        }
      };
    });
  }
}
