SfeListItemController.$inject = [
  'SfeListConfigurations',
  'CrudToastFactory',
  'AlertingService',
  '$smartAuth',
  'InfoDialog',
  'gettext',
  '$mdDialog',
  'CrawlerMethods',
  'ColorService',
  '$scope'
];

export default function SfeListItemController(
  SfeListConfigurations,
  CrudToastFactory,
  AlertingService,
  $smartAuth,
  InfoDialog,
  gettext,
  $mdDialog,
  CrawlerMethods,
  ColorService,
  $scope
) {
  var vm = this;
  vm.$onInit = init;

  vm.badColor = ColorService.getApplicationColor('warn');
  vm.goodColor = ColorService.getApplicationColor('success');
  function init() {
    vm.config =
      angular.copy(SfeListConfigurations.configuration[vm.entity]) || {};
    if (vm.listConfig) {
      var config = vm.listConfig;
      Object.assign(vm.config || {}, config || {});
    }

    if (vm.config.shortActions) {
      vm.config.shortActions = configureShortActions(vm.config.shortActions);
    }

    vm.extensionOpen = false;
    vm.GenerateUISrefLink = GenerateUISrefLink;
    vm.ToggleExtended = ToggleExtended;
    vm.ShortActionQuery = ShortActionQuery;
    vm.RefreshExtended = RefreshExtended;
    vm.fetchingExtended = true;
    vm.LinkDefined = LinkDefined;

    if (vm.config.api.enrich && !vm.values.enriched) {
      if (
        vm.paginationPage !== undefined &&
        vm.paginationLimit !== undefined &&
        vm.index !== undefined
      ) {
        vm.values.absoluteIndex =
          (vm.paginationPage - 1) * vm.paginationLimit + (vm.index + 1);
      }
      vm.values.fetchingObject = {};
      vm.config.api.enrich(vm.values).then(function() {
        vm.values.enriched = true;
        $scope.$evalAsync();
      });
    }
  }

  function configureShortActions(shortActions) {
    shortActions.forEach(function(action) {
      if (action.iconType === 'fa') {
        action.icon = 'fa-' + action.icon;
      }
    });
    return shortActions;
  }

  function ToggleExtended() {
    vm.fetchingExtended = true;
    vm.extensionOpen = !vm.extensionOpen;
    if (vm.extensionOpen) {
      vm.config.extended.query(vm.values).then(function(extendedInfo) {
        vm.values.extended = extendedInfo;
        vm.fetchingExtended = false;
      });
    }
  }

  function RefreshExtended() {
    vm.fetchingExtended = true;
    vm.config.extended.query(vm.values).then(function(extendedInfo) {
      vm.values.extended = extendedInfo;
      vm.fetchingExtended = false;
    });
  }

  function GenerateUISrefLink(linkConfigObject) {
    if (linkConfigObject) {
      var params = linkConfigObject.params;
      var resultParams = {};
      var resultParamsString = '';
      if (params && params.length > 0) {
        params.forEach(function(param) {
          var queryParam = param.queryParam || vm.values[param.queryParamName];
          // var queryParam = 'id';
          resultParams[queryParam] = param.value || vm.values[param.valueParam];
        });
        resultParamsString = '(' + JSON.stringify(resultParams) + ')';
      }
      var state =
        linkConfigObject.state || vm.values[linkConfigObject.stateName];
      return state + resultParamsString;
    } else {
      return null;
    }
  }

  function LinkDefined(linkConfigObject) {
    var linkOK = true;
    var params = linkConfigObject.params;
    if (params && params.length > 0) {
      params.forEach(function(param) {
        if (param.valueParam != null && !vm.values[param.valueParam]) {
          linkOK = false;
        }
      });
    } else {
      linkOK = false;
    }
    return linkOK;
  }

  function ShortActionQuery(actionObject) {
    if (
      actionObject.method === 'delete' ||
      actionObject.configuration.method === 'delete'
    ) {
      var title = gettext('Confirmation');
      var textItem = {
        text: gettext('Are you sure you want to delete this item?'),
        type: 'text'
      };
      var actions = [
        {
          title: gettext('Cancel'),
          cancel: true,
          color: 'primary'
        },
        {
          title: gettext('Delete'),
          fn: Delete,
          color: 'warn'
        }
      ];
      InfoDialog.open(title, null, [textItem], actions);
    }

    async function Delete() {
      //close confirmation dialog
      $mdDialog.cancel();

      vm.loadingStatus = true;
      let queryParams = {};

      queryParams[actionObject.queryParam] = vm.values[actionObject.valueParam];
      let method;
      if (actionObject.configuration) {
        if (actionObject.configuration.params) {
          queryParams = {
            ...queryParams,
            ...actionObject.configuration.params
          };
        }
        method = CrawlerMethods.getMethod(actionObject.configuration)(
          queryParams
        );
      } else {
        method = actionObject.fn.delete(queryParams).$promise;
      }
      try {
        await method;
        vm.objectManipulation.RemoveObjectById(
          vm.values[actionObject.valueParam]
        );
        CrudToastFactory.toast('delete');
        // if config contans callback trigger it
        if (typeof actionObject.callback == 'function') {
          actionObject.callback(queryParams, vm.values);
        }
      } catch (err) {
        AlertingService.Error(err);
      }
      vm.loadingStatus = false;
      $scope.$applyAsync();
    }
  }
}
