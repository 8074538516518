noticeConfiguration.$inject = ['gettext', '$q'];

function noticeConfiguration(gettext, $q) {
  var configuration = {
    entity: {
      singular: gettext('Created Notification'),
      plural: gettext('Created Notifications')
    },
    api: {
      query: {
        entity: 'notifications',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'subject',
      title: gettext('Subject'),
      link: {
        state: 'users-created-notifications-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'users-created-notifications-new'
        },
        color: 'accent'
      }
    ],
    attributes: [],
    filter: [
      {
        display: gettext('Subject'),
        param: 'subject',
        type: 'string'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    item.subject = item.subject || 'No subject';
    return deferred.promise;
  }

  return configuration;
}

export default noticeConfiguration;
