/*
    var crawlerConfiguration = {
    entity: 'entityName',
    method: 'methodName',
    steps: [
      {
        entity: '',
        valueParams: [],
        queryParams: [],
        method: '',
        methodType: '' (populate, add, populateArray, addArray),
        newParamNames: [] only for add method types
      }]
  */
LocationTypeModel.$inject = ['ModelUtilities'];

function LocationTypeModel(ModelUtilities) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'location-types'
  };

  var networkConfigurationAllLang = {
    host: 'api',
    path: 'location-types',
    allLang: true
  };

  var networkConfigurationV1 = {
    host: 'api',
    path: 'v1/location-types'
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readAllLang: ModelUtilities.crud(
        'GET',
        networkConfigurationAllLang,
        modelStorage
      ),
      createV1: ModelUtilities.crud(
        'POST',
        networkConfigurationV1,
        modelStorage
      ),
      updateV1: ModelUtilities.crud('PUT', networkConfigurationV1, modelStorage)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default LocationTypeModel;
