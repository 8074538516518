ExternalWriterTangoProperties.$inject = [
  'gettextCatalog',
  'TangoPropertiesHelper',
  'StandardUtils',
  'TranslationService'
];

function ExternalWriterTangoProperties(
  gettextCatalog,
  TangoPropertiesHelper,
  StandardUtils,
  TranslationService
) {
  /**
   * @description get input time series configuration.
   * @function
   * @param {Object} item - inputTimeSeries
   * @return {Array}
   */
  function getInputTimeSeries(item) {
    let properties = [];
    if (
      item.inputTimeSeries != null &&
      typeof item.inputTimeSeries === 'object'
    ) {
      let linkParams = JSON.stringify({ id: item.inputTimeSeries._id });
      properties = [
        {
          title: gettextCatalog.getString('Input time series'),
          testId: 'inputTimeSeries',
          type: 'basic',
          values: [
            {
              text: item.inputTimeSeries.name,
              link: `data-time-series-view(${linkParams})`
            }
          ]
        }
      ];
    }
    return properties;
  }
  /**
   * @description get time limit configuration.
   * @function
   * @param {Object} item - timeLimit
   * @return {Array}
   */
  function getTimeLimit(item) {
    let properties = [];
    if (item.inputTimeSeries != null && typeof item.timeout === 'object') {
      if (
        item.timeout.maxTimeoutInMilliseconds != null &&
        typeof item.timeout.maxTimeoutInMilliseconds === 'number'
      ) {
        properties.push({
          title: gettextCatalog.getString('Max timeout'),
          testId: 'maxTimeout',
          type: 'basic',
          values: [
            {
              text: StandardUtils.readableExactTimeOutOfSeconds(
                item.timeout.maxTimeoutInMilliseconds / 1000
              )
            }
          ]
        });
      }
      if (
        item.timeout.action != null &&
        typeof item.timeout.action === 'number'
      ) {
        let timeoutAction = TranslationService.GetCollectionById(
          'codelists.externalWriterFlowTimeoutActions',
          item.timeout.action
        );
        if (timeoutAction != null) {
          properties.push({
            title: gettextCatalog.getString('Timeout action'),
            testId: 'timeoutAction',
            type: 'basic',
            values: [
              {
                text: timeoutAction.name
              }
            ]
          });
        }
      }
      if (
        item.timeout.alarm != null &&
        typeof item.timeout.alarm === 'object'
      ) {
        let linkParams = JSON.stringify({ id: item.timeout.alarm._id });
        properties.push({
          title: gettextCatalog.getString('Alarm after timeout'),
          testId: 'alarmAfterTimeout',
          type: 'basic',
          values: [
            {
              text: item.timeout.alarm.name,
              link: `alarms-and-rules-alarm-definitions-view(${linkParams})`
            }
          ]
        });
      }
    }
    return properties;
  }
  /**
   * @description get IoT configuration.
   * @function
   * @param {Object} item - IoTConfiguration
   * @return {Array}
   */
  function getIOTConfiguration(item) {
    let properties = [];
    if (
      item.externalDatasource != null &&
      typeof item.externalDatasource === 'object'
    ) {
      let linkParams = JSON.stringify({ id: item.externalDatasource._id });
      properties.push({
        title: gettextCatalog.getString('External Datasource'),
        testId: 'externalDatasource',
        type: 'basic',
        values: [
          {
            text: item.externalDatasource.name,
            link: `external-datasources-external-datasources-view(${linkParams})`
          }
        ]
      });
    }
    if (
      item.tangoAgentConfig != null &&
      typeof item.tangoAgentConfig === 'object'
    ) {
      if (
        item.tangoAgentConfig.connectionConfig != null &&
        typeof item.tangoAgentConfig.connectionConfig === 'object'
      ) {
        let linkParams = JSON.stringify({
          id: item.tangoAgentConfig.connectionConfig._id
        });
        properties.push({
          title: gettextCatalog.getString('Tango agent config'),
          testId: 'tangoAgentConfig',
          type: 'basic',
          values: [
            {
              text: item.tangoAgentConfig.connectionConfig.name,
              link: `external-datasources-tango-agent-connection-config-view(${linkParams})`
            }
          ]
        });
      }
      if (
        item.tangoAgentConfig.tag != null &&
        typeof item.tangoAgentConfig.tag === 'object'
      ) {
        properties.push({
          title: gettextCatalog.getString('Tag on source'),
          testId: 'tagOnSource',
          type: 'basic',
          values: [
            {
              text: item.tangoAgentConfig.tag.name
            }
          ]
        });
      }
    }
    return properties;
  }
  /**
   * @description get write back rules configuration.
   * @function
   * @param {Object} item - writeBackRules
   * @return {Array}
   */
  function getWriteBackRules(item) {
    return TangoPropertiesHelper.getRulesAlarmsProperties(
      item.writeCancelRules
    );
  }
  /**
   * @description returns configuration object to get tango properties configuration for external writer flow.
   * @function
   * @param {object} flow flow object
   * @return {Object}
   */
  function get(flow) {
    return {
      type: 'dynamic',
      valueFn: store => {
        let item = {};
        if (
          store != null &&
          store.externalWriterFlow != null &&
          store.externalWriterFlow[flow.flow] != null &&
          store.externalWriterFlow[flow.flow].data != null
        ) {
          item = store.externalWriterFlow[flow.flow].data;
        }
        return [
          {
            canClose: false,
            title: gettextCatalog.getString('Input time series'),
            properties: getInputTimeSeries(item)
          },
          {
            canClose: false,
            title: gettextCatalog.getString('Timeout'),
            properties: getTimeLimit(item)
          },
          {
            canClose: false,
            title: gettextCatalog.getString('Tango agent config'),
            properties: getIOTConfiguration(item)
          },
          {
            canClose: false,
            title: gettextCatalog.getString('Write back rules'),
            properties: getWriteBackRules(item)
          }
        ];
      }
    };
  }

  return {
    get
  };
}

export default ExternalWriterTangoProperties;
