import './sfe-table.scss';
import template from './sfe-table.directive.html';

export default [
  '$filter',
  '$state',
  'AlertingService',
  'InfoDialog',
  'CrudToastFactory',
  '$timeout',
  'StorageService',
  'gettext',
  'gettextCatalog',
  function(
    $filter,
    $state,
    AlertingService,
    InfoDialog,
    CrudToastFactory,
    $timeout,
    StorageService,
    gettext,
    gettextCatalog
  ) {
    return {
      restrict: 'E',
      template,
      scope: {
        hideToolbar: '=',
        options: '=',
        data: '=',
        headers: '=',
        filtered: '='
      },
      link: function link(scope) {
        var bookmark;
        var keysToChange = [];
        var optionsWatcher = scope.$watch('options', function(newVal) {
          if (typeof newVal !== 'undefined') {
            if (scope.options.selected) {
              scope.select = true;
            } else {
              scope.select = false;
            }

            if (!containsAllAscii(scope.options.order)) {
              scope.options.order = scope.options.order.replace(
                /[^\000-\177]/g,
                ''
              );
              scope.options.order = scope.options.order.replace(/\s/g, '');
            }
            init();
            optionsWatcher();
          }
        });
        var dataWatcher = scope.$watch('data', function() {
          if (typeof scope.data !== 'undefined') {
            if (keysToChange.length) {
              scope.data.forEach(function(item) {
                keysToChange.forEach(function(header) {
                  item[header.order] = item.header[header.sort];
                });
              });
            }
            dataWatcher();
          }
        });
        var queryFilterWatcher = scope.$watch('queryFilter', function(
          newValue,
          oldValue
        ) {
          if (scope.options && !oldValue) {
            bookmark = scope.options.page;
            return;
          }

          if (scope.options && newValue !== oldValue) {
            scope.options.page = 1;
          }

          if (scope.options && !newValue) {
            scope.options.page = bookmark;
          }

          scope.filtered = $filter('filter')(scope.data, newValue);
        });
        scope.$on('$destroy', function() {
          if (optionsWatcher) {
            optionsWatcher();
          }
          if (dataWatcher) {
            dataWatcher();
          }
          if (queryFilterWatcher) {
            queryFilterWatcher();
          }
        });
        scope.delete = deleteEl;
        scope.inspectGo = inspectGo;
        scope.editGo = editGo;
        scope.removeFilter = removeFilter;
        scope.newGo = newGo;
        scope.onPaginate = onPaginate;
        scope.reset = reset;
        scope.filter = {};
        scope.createParamsObject = createParamsObject;
        scope.$state = $state;

        scope.paginationLabels = {
          of: gettextCatalog.getString('of'),
          rowsPerPage: gettextCatalog.getString('Per page'),
          page: gettextCatalog.getString('page')
        };

        function init() {
          // init pages containers
          if (scope.headers) {
            scope.headers.forEach(function(header) {
              if (!containsAllAscii(header.sort)) {
                header.order = header.sort.replace(/[^\000-\177]/g, '');
                header.order = header.order.replace(/\s/g, '');
                keysToChange.push(header);
              } else {
                header.order = header.sort;
              }
            });
          }

          var i = 5;
          if (
            scope.options &&
            scope.options.rowPerPageOptions &&
            scope.options.rowPerPageOptions.length > 0
          ) {
            scope.limitOptions = scope.options.rowPerPageOptions;
          } else {
            scope.limitOptions = [];
            while (i <= 50) {
              scope.limitOptions.push(i);
              i += 5;
            }
          }
          getTotalPageFromStorage();
        }

        function containsAllAscii(str) {
          return /^[\000-\177]*$/.test(str);
        }

        function createParamsObject(paramName, paramValue) {
          var object = {};
          object[paramName] = paramValue;
          return object;
        }

        if (scope.options && typeof scope.options.inspect !== 'undefined') {
          scope.inspect = true;
        } else if (
          scope.options &&
          typeof scope.options.fnInspect !== 'undefined'
        ) {
          scope.fnInspect = true;
        }

        function getTotalPageFromStorage() {
          if (!scope.options) {
            return;
          }
          if (scope.limitOptions && scope.limitOptions.length > 1) {
            scope.options.limit =
              StorageService.getListRowPerPageData(scope.options.listId) || 5;
            scope.currentLimit = scope.options.limit;
          } else if (scope.limitOptions) {
            scope.options.limit = scope.limitOptions[0];
            scope.currentLimit = scope.options.limit;
          }
        }

        function storeLimitData() {
          if (!scope.options) {
            return;
          }
          scope.currentLimit = scope.options.limit;
          StorageService.saveListRowPerPageParameter(
            scope.options.listId,
            scope.options.limit
          );
        }

        function deleteQuery() {
          async.every(
            scope.options.selected,
            function(obj, callback) {
              var id = obj._id;
              var el = {};
              var param = scope.options.delete.params;
              el[param] = id;
              scope.options.delete.fn.delete(el).$promise.then(
                function() {
                  _.remove(scope.filtered, function(obj) {
                    return obj._id === id;
                  });
                  callback();
                },
                function(err) {
                  AlertingService.Error(err);
                  callback(true);
                }
              );
            },
            function(err) {
              if (!err) {
                scope.options.selected = [];
                if (
                  scope.options.page > 1 &&
                  (scope.options.page - 1) * scope.options.limit <=
                    scope.filtered.length
                ) {
                  scope.options.page = scope.options.page - 1;
                }
                CrudToastFactory.toast(scope.options.delete.msg);
              }
            }
          );
        }

        function deleteEl() {
          var title = scope.options.delete.title
            ? scope.options.delete.title
            : gettext('Delete element');
          var content = {
            text: scope.options.delete.content
              ? scope.options.delete.content
              : gettext('Are you sure you want to delete '),
            type: 'text'
          };
          _.each(scope.options.selected, function(el, index) {
            if (index == scope.options.selected.length - 1) {
              content += el.name + '?';
            } else {
              content += el.name + ', ';
            }
          });

          var actions = [
            {
              title: gettext('Cancel'),
              cancel: true,
              color: 'primary'
            },
            {
              title: gettext('Delete'),
              fn: deleteQuery,
              color: 'warn'
            }
          ];

          InfoDialog.open(title, null, [content], actions);
        }

        function inspectGo(id) {
          var stateToGo = scope.options.inspect.state;
          var params = scope.options.inspect.params;
          var obj = {};
          obj[params] = id;
          $state.go(stateToGo, obj);
        }

        function editGo() {
          var stateToGo = scope.options.edit.state;
          var params = scope.options.edit.params;
          var obj = {};
          var id = scope.options.selected[0]._id;
          obj[params] = id;
          $state.go(stateToGo, obj);
        }
        function removeFilter() {
          scope.filter.query = '';
        }

        function newGo() {
          var stateToGo = scope.options.new.state;
          $state.go(stateToGo);
        }
        function onPaginate(page, limit) {
          if (scope.currentLimit !== limit) {
            storeLimitData();
          }
          if (scope.options.selected) {
            scope.options.selected = [];
          }
        }

        function reset(item) {
          $timeout(function() {
            _.each(scope.filtered, function(el) {
              if (el._id == item._id) {
                _.each(item, function(value, key) {
                  el[key] = item[key];
                });
              }
            });
          }, 700);

          scope.options.selected = [];
        }
      }
    };
  }
];
