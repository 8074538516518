import { RouteManager, gettext } from '../routes';

import TriggeredAlarmView from './pages/triggered-alarm/triggered-alarm.view.html';
import TriggeredAlarmsListView from './pages/triggered-alarms-list/triggered-alarms-list.view.html';

loadEntity.$inject = ['$transition$', 'StateResolveService'];
function loadEntity($transition$, StateResolveService) {
  return StateResolveService.fetchEntityForView($transition$);
}

let MonitoringRouteManager = new RouteManager();
MonitoringRouteManager
  // DASHBOARD for ANALYTIC MODULE
  .state('monitoring', {
    url: '/monitoring',
    isMainMenuItem: true,
    menuIndex: 11,
    pageTitle: gettext('Monitoring'),
    icon: 'notifications_active',
    redirectTo: 'monitoring-triggered-alarms-list',
    theme: 'tango',
    accessScopeFe: 'tis_fe:monitoring-module',
    accessScopeBe: 'tis_fe:monitoring-module'
  })
  // LIST of ALARMS
  .state('monitoring-triggered-alarms-list', {
    url: '/monitoring/triggered-alarms/list?severity?assignee?responsible',
    template: TriggeredAlarmsListView,
    controller: 'TriggeredAlarmsListController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Triggered Alarms'),
    icon: 'warning',
    parentState: 'monitoring',
    theme: 'tango',
    accessScopeFe: 'tis_fe:alarms-in-progress',
    accessScopeBe: 'tis_be:alarms-in-progress',
    entity: 'triggered-alarms'
  })
  // triggered alarm view
  .state('monitoring-triggered-alarms-view', {
    url: '/monitoring/triggered-alarms/{id}',
    template: TriggeredAlarmView,
    controller: 'TriggeredAlarmController',
    controllerAs: 'vm',
    pageTitle: gettext('Triggered Alarm'),
    parentState: 'monitoring',
    theme: 'tango',
    accessScopeFe: 'tis_fe:alarms-in-progress',
    accessScopeBe: 'tis_be:alarms-in-progress',
    mode: 'read',
    entity: 'triggered-alarms',
    resolve: {
      triggeredAlarm: loadEntity
    }
  });

export default MonitoringRouteManager.routes;
