UserModel.$inject = ['ModelUtilities'];

function UserModel(ModelUtilities) {
  const networkConfiguration = {
    host: 'identity',
    path: 'users'
  };

  const networkConfiguration2 = {
    host: 'authenticator',
    path: 'addUser'
  };

  const networkConfiguration3 = {
    host: 'authenticator',
    path: 'oauthChangePassword'
  };

  const networkConfiguration4 = {
    host: 'authenticator',
    path: 'resetPassword'
  };

  const modelStorage = {};

  const methods = {
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    custom: {
      addUser: ModelUtilities.crud('POST', networkConfiguration2, modelStorage),
      changePassword: ModelUtilities.crud(
        'POST',
        networkConfiguration3,
        modelStorage
      ),
      resetPassword: ModelUtilities.crud(
        'POST',
        networkConfiguration4,
        modelStorage
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default UserModel;
