import entities from '../../../config/tis/entities';

EntitiesService.$inject = ['LocalizationService', '$window'];

/**
 * @ngdoc service
 * @name core.EntitiesService
 * @description Used for fetching configuration and differenet parts of configuration of entities.
 * @property {function} getDialog See getDialog method below.
 * @property {function} getName See getName method below.
 * @property {function} getPermissionConfiguration See getPermissionConfiguration method below.
 * @property {function} getDisplayFields See getDisplayFields method below.
 * @property {function} getNetworkModel See getNetworkModel method below.
 * @property {function} getNetworkModelByEntity See getNetworkModelByEntity method below.
 * @property {function} getDisplayFieldsByPath See getDisplayFieldsByPath method below.
 * @property {function} getPath See getPath method below.
 * @property {function} getTreeViewConfiguration See getTreeViewConfiguration method below.
 * @property {function} getSelectedParamName See getSelectedParamName method below.
 * @property {function} getPathByScope See getPathByScope method below.
 * @property {function} getScope See getScope method below.
 * @property {function} getHierarchyTree See getHierarchyTree method below.
 * @property {function} getEntity See getEntity method below.
 * @property {function} getFilterGrid See getFilterGrid method below.
 * @property {function} getEntityById See getEntityById method below.
 */
function EntitiesService(LocalizationService) {
  /**
   * @description Returns a property of a given entity.
   * @function
   * @param {string} property Property to be returned
   */
  var getProperty = function getProperty(property) {
    return function() {
      var entity =
        arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return entity[property];
    };
  };

  /**
   * @description Returns a property of a given scope.
   * @function
   * @param {string} property Property to be returned
   */
  var findPropertyByScope = function findPropertyByScope(property) {
    return function(scope) {
      return getProperty(property)(
        entities.find(function(e) {
          return e.scope === scope;
        })
      );
    };
  };

  /**
   * @description Returns an entity based on the passed entityid.
   * @function
   * @param {number} entityId Id of the entity to be returned
   * @return {Object} Entity with the passed in id
   */
  var findEntityById = function(entityId) {
    return (
      entities.find(function(e) {
        return e.entityId === entityId;
      }) || null
    );
  };

  /**
   * @description Returns a property of the given entity
   * @function
   * @param {number} property Property of the entity to be returned
   */
  var findProperty = function findProperty(property) {
    return function(entity) {
      return getProperty(property)(
        entities.find(function(e) {
          return e.entity === entity;
        })
      );
    };
  };

  /**
   * @description Returns a configured array of fields to be displayed
   * @function
   * @param {Array} displayFields Display fields array to be configured
   */
  function constructFiledsTitle(displayFields) {
    if (displayFields) {
      displayFields.forEach(function(field) {
        if (field.title) {
          var langualge = LocalizationService.GetSelectedLang().code;
          field.title =
            typeof field.title === 'object'
              ? langualge
                ? field.title[langualge]
                : field['en']
              : field.title;
        }
      });
      return displayFields;
    } else {
      return null;
    }
  }

  /**
   * @memberof core.EntitiesService
   * @description Gets dialog configuration of the selected entity.
   * @param {string} entity Name of the entity.
   * @return {Object} Dialog configuration.
   */
  function getDialog(entity) {
    var dialog = angular.copy(findProperty('dialog')(entity));

    if (dialog) {
      dialog.displayFields = constructFiledsTitle(dialog.displayFields);
      dialog.title =
        dialog.title[LocalizationService.GetSelectedLang().code] ||
        dialog.title['en'];
      dialog.listInfo =
        dialog.listInfo[LocalizationService.GetSelectedLang().code] ||
        dialog.listInfo['en'];
      dialog.path = findProperty('path')(entity) || null;
    }
    return dialog || null;
  }

  /**
   * @memberof core.EntitiesService
   * @description Gets translated name of the selected entity.
   * @param {string} entity Name of the entity.
   * @return {string} Translated name.
   */
  function getName(entity) {
    var name = findProperty('name')(entity) || null;
    return name
      ? name[LocalizationService.GetSelectedLang().code]
        ? name.en
        : null
      : null;
  }

  /**
   * @memberof core.EntitiesService
   * @description Gets permission configuration of the selected entity.
   * @param {string} entity Name of the entity.
   * @return {Object} Permission configuration of the entity.
   */
  function getPermissionConfiguration(entity) {
    return findProperty('permissionConfiguration')(entity) || null;
  }

  /**
   * @memberof core.EntitiesService
   * @description Gets the network model of the selected scope.
   * @param {string} scope Name of the scope.
   * @return {Object} Network model of the selected scope.
   */
  function getNetworkModel(scope) {
    return findPropertyByScope('networkModel')(scope) || null;
  }

  /**
   * @memberof core.EntitiesService
   * @description Gets the network model of the selected entity.
   * @param {string} scope Name of the entity.
   * @return {Object} Network model of the selected entity.
   */
  function getNetworkModelByEntity(entity) {
    return findProperty('networkModel')(entity) || null;
  }

  /**
   * @memberof core.EntitiesService
   * @description Gets display fields of the selected scope.
   * @param {string} scope Name of the scope.
   * @return {Array} Display fields array of the selected scope.
   */
  function getDisplayFields(scope) {
    var displayFields = findPropertyByScope('displayFields')(scope) || null;
    return constructFiledsTitle(displayFields);
  }

  /**
   * @memberof core.EntitiesService
   * @description Gets display fields of the selected path.
   * @param {string} path Name of the path.
   * @return {Array} Display fields array of the selected path.
   */
  function getDisplayFieldsByPath(path) {
    var displayFields = findProperty('displayFields')(path) || null;
    return constructFiledsTitle(displayFields);
  }

  /**
   * @memberof core.EntitiesService
   * @description Gets path of the selected entity.
   * @param {string} entity Name of the entity.
   * @return {string} Path of the selected entity.
   */
  function getPath(entity) {
    return findProperty('path')(entity) || null;
  }

  /**
   * @memberof core.EntitiesService
   * @description Gets select tree configuration of the selected entity.
   * @param {string} entity Name of the entity.
   * @return {Object} Select tree configuration of the selected entity.
   */
  function getTreeViewConfiguration(entity) {
    var treeConfig = findProperty('selectTree')(entity) || null;
    if (treeConfig) {
      treeConfig.displayFields = constructFiledsTitle(treeConfig.displayFields);
      treeConfig.path = findProperty('path')(entity) || null;
      treeConfig.name = getName(entity);
    }
    return treeConfig || null;
  }

  /**
   * @memberof core.EntitiesService
   * @description Gets selectedParamName of the selected entity - used for querying.
   * @param {string} entity Name of the entity.
   * @return {string} selectedParamName of the selected entity.
   */
  function getSelectedParamName(entity) {
    return findProperty('selectedParamName')(entity) || null;
  }

  /**
   * @memberof core.EntitiesService
   * @description Gets be path of the selected scope.
   * @param {string} scope Name of the scope.
   * @return {string} Be path of the selected scope.
   */
  function getPathByScope(scope) {
    return findPropertyByScope('path')(scope) || null;
  }

  /**
   * @memberof core.EntitiesService
   * @description Gets be scope of the selected entity.
   * @param {string} entity Name of the entity.
   * @return {string} Be scope of the selected entity.
   */
  function getScope(entity) {
    return findProperty('scope')(entity) || null;
  }

  /**
   * @memberof core.EntitiesService
   * @description Gets sfe tree configuration of the selected entity.
   * @param {string} entity Name of the entity.
   * @return {Object} Sfe tree configuration of the selected entity.
   */
  function getHierarchyTree(entity) {
    return findProperty('sfeTree')(entity) || null;
  }

  /**
   * @memberof core.EntitiesService
   * @description Gets entity name of the selected scope.
   * @param {string} scope Name of the scope.
   * @return {string} Entity name of the selected scope.
   */
  function getEntity(scope) {
    return findPropertyByScope('entity')(scope) || null;
  }

  /**
   * @memberof core.EntitiesService
   * @description Gets configuration for the filter grid of the selected entity.
   * @param {string} entity Name of the entity.
   * @return {Object} Configuration for the filter grid.
   */
  function getFilterGrid(entity) {
    var config = angular.copy(findProperty('filterGrid')(entity)) || null;
    if (config) {
      config.title =
        config.title[LocalizationService.GetSelectedLang().code] ||
        config.title['en'];
    }
    return config;
  }

  /**
   * @memberof core.EntitiesService
   * @description Gets entity configuration based on id.
   * @param {string} id Id of the entity.
   * @return {Object} Entity configuration based on entity id.
   */
  function getEntityById(id) {
    var obj = findEntityById(id);
    if (obj) {
      return obj.entity || null;
    } else {
      return null;
    }
  }

  /**
   * @memberof core.EntitiesService
   * @description Gets entity store key name based on id.
   * @param {string} id Id of the entity.
   * @return {string} entity store key name
   */
  function getStoreName(id) {
    var obj = findEntityById(id);
    if (obj) {
      return obj.storeKey || null;
    } else {
      return null;
    }
  }

  return {
    getDialog,
    getName,
    getPermissionConfiguration,
    getDisplayFields,
    getNetworkModel,
    getNetworkModelByEntity,
    getDisplayFieldsByPath,
    getPath,
    getTreeViewConfiguration,
    getSelectedParamName,
    getPathByScope,
    getScope,
    getHierarchyTree,
    getEntity,
    getFilterGrid,
    getEntityById,
    getStoreName
  };
}

export default EntitiesService;
