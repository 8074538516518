billingTypeConfiguration.$inject = [
  'gettext',
  '$q',
  'SfeFormDialogService',
  'AlertingService',
  'MultiLanguage',
  'CrudToastFactory',
  'BillingTypeModel',
  'ListFormService'
];

function billingTypeConfiguration(
  gettext,
  $q,
  SfeFormDialogService,
  AlertingService,
  MultiLanguage,
  CrudToastFactory,
  BillingTypeModel,
  ListFormService
) {
  const fieldNames = ['name', 'description', 'code', 'order'];

  function openCreateDialog(relistData) {
    var config = getForm();
    var title = gettext('New Billing Type');
    SfeFormDialogService.openSfeFormDialog(false, config, {}, title).then(
      function(object) {
        if (object) {
          create(object, config, relistData);
        }
      }
    );
  }

  function create(obj, config, relistData) {
    var data = MultiLanguage.constructForm(config, obj);
    BillingTypeModel.create(
      ListFormService.generateBodyObject(data, fieldNames)
    ).then(
      function() {
        CrudToastFactory.toast('create');
        relistData();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function getForm() {
    return [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'multilanguage',
        required: true
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        maxlength: 500,
        required: false,
        componentType: 'multilanguage'
      },
      {
        placeholder: gettext('Code'),
        name: 'code',
        componentType: 'textField'
      },
      {
        placeholder: gettext('Order'),
        name: 'order',
        type: 'number',
        componentType: 'textField'
      }
    ];
  }

  function update(id, obj, config, relistData) {
    var data = MultiLanguage.constructForm(config, obj);
    BillingTypeModel.update(
      {
        id: id
      },
      ListFormService.generateBodyObject(data, fieldNames)
    ).then(
      function() {
        CrudToastFactory.toast('update');
        relistData();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function openUpdateDialog(item, relistData) {
    BillingTypeModel.custom
      .readAllLang({
        id: item._id
      })
      .then(
        function(res) {
          var config = getForm();
          var title = gettext('Edit Billing Type');
          SfeFormDialogService.openSfeFormDialog(
            true,
            config,
            res.data,
            title
          ).then(function(object) {
            if (object) {
              update(item._id, object, config, relistData);
            }
          });
        },
        function(err) {
          AlertingService.Error(err);
        }
      );
  }

  var configuration = {
    entity: {
      singular: gettext('Billing Type'),
      plural: gettext('Billing Types')
    },
    api: {
      query: {
        entity: 'billing-types',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name'
    },
    buttons: [
      {
        title: gettext('Create'),
        fn: openCreateDialog,
        relist: true,
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        action: openUpdateDialog
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'billing-types',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description')
      },
      {
        param: 'code',
        title: gettext('Code'),
        type: 'code'
      },
      {
        param: 'order',
        title: gettext('Order')
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Code'),
        param: 'code',
        type: 'string'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich() {
    var deferred = $q.defer();
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default billingTypeConfiguration;
