const MAINTENANCE_FLOW_READ = 'MAINTENANCE_FLOW_READ';
const MAINTENANCE_FLOW_UPDATE = 'MAINTENANCE_FLOW_UPDATE';
const MAINTENANCE_FLOW_CREATE = 'MAINTENANCE_FLOW_CREATE';
const MAINTENANCE_FLOW_ADD = 'MAINTENANCE_FLOW_ADD';

export default {
  MAINTENANCE_FLOW_READ,
  MAINTENANCE_FLOW_UPDATE,
  MAINTENANCE_FLOW_CREATE,
  MAINTENANCE_FLOW_ADD
};
