NewExternalDatasourceFormController.$inject = [
  '$stateParams',
  'AlertingService',
  'InfoDialog',
  'gettext',
  'MetadataService',
  'CrawlerMethods',
  'ToastService',
  'SfeHeader',
  '$mdDialog',
  '$timeout',
  'CrudToastFactory',
  'externalDatasource'
];

function NewExternalDatasourceFormController(
  $stateParams,
  AlertingService,
  InfoDialog,
  gettext,
  MetadataService,
  CrawlerMethods,
  ToastService,
  SfeHeader,
  $mdDialog,
  $timeout,
  CrudToastFactory,
  externalDatasource
) {
  var vm = this;
  var externalDatasourceId = $stateParams.id;

  var data = [];
  vm.editMode = !!externalDatasourceId;
  vm.device = {};
  vm.dataConfig = {};

  init();

  async function init() {
    data.push(
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        showCond: true
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textArea',
        maxlength: 500,
        required: false
      },
      {
        componentType: 'autocompleteDialog',
        edit: vm.editMode,
        configuration: {
          required: true,
          codelist: 'externalDatasourceTypes',
          noDialog: true,
          floatingLabel: gettext('Select external datasource type'),
          searchParamName: 'name',
          selectedParam: 'id',
          filterFn: type => type.filter(item => item.configurable)
        },
        name: 'externalDatasourceType'
      },
      {
        componentType: 'title',
        title: gettext('Timeout settings')
      },
      {
        title: gettext('Timeout'),
        componentType: 'title'
      },
      {
        componentType: 'fourInRow',
        subData: [
          {
            placeholder: 'Days',
            name: 'days',
            pattern: new RegExp('^([0-9])*$'),
            patternExample: '14',
            type: 'number',
            required: true
          },
          {
            placeholder: 'Hours',
            name: 'hours',
            type: 'number',
            min: 0,
            max: 23,
            required: true
          },
          {
            placeholder: 'Minutes',
            name: 'minutes',
            type: 'number',
            min: 0,
            max: 59,
            required: true
          },
          {
            placeholder: 'Seconds',
            name: 'seconds',
            type: 'number',
            min: 0,
            max: 59,
            required: true
          }
        ]
      },
      {
        componentType: 'address',
        name: 'addressObject',
        noAddress: true,
        config: {
          noSetAddress: true,
          locationFromAddress: true
        }
      }
    );

    vm.dataConfig.data = data;

    vm.dataConfig.redirect = {
      param: 'id',
      paramValue: externalDatasourceId,
      location: 'external-datasources-external-datasources-view'
    };

    vm.dataConfig.action = {
      ctrlFn: true,
      title: vm.editMode ? gettext('Update') : gettext('Create'),
      fn: createOrUpdate
    };

    if (externalDatasourceId) {
      MetadataService.Loading(true);
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit External Datasource'),
        'external-datasources-external-datasources-view',
        $stateParams
      );
      vm.sendingRequest = true;
      $timeout(() => {
        MetadataService.Loading(false);
        MetadataService.ChangeMetadata(
          'Edit ' + externalDatasource.name,
          externalDatasource.description
        );
      });

      externalDatasource.addressObject = {};
      externalDatasource.addressObject.geolocationX = externalDatasource.geoLocation
        ? externalDatasource.geoLocation[0]
        : '';
      externalDatasource.addressObject.geolocationY = externalDatasource.geoLocation
        ? externalDatasource.geoLocation[1]
        : '';
      vm.dataConfig.dataObj = {
        ...externalDatasource,
        ...convertMsInFormObject(externalDatasource.timeoutInMilliseconds)
      };
      vm.sendingRequest = false;
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New External Datasource'),
        'external-datasources-external-datasources-list'
      );

      vm.dataConfig.dataObj = {
        addressObject: {},
        ...convertMsInFormObject(60000)
      };
    }
  }

  function convertMsInFormObject(nMs) {
    let seconds = nMs / 1000;
    let days = Math.floor(seconds / 3600 / 24);
    let hours = Math.floor(seconds / 3600) - days * 24;
    let minutes = Math.floor((seconds - hours * 3600) / 60 - days * 24 * 60);
    seconds = seconds - hours * 3600 - minutes * 60 - days * 24 * 3600;

    return {
      days,
      hours,
      minutes,
      seconds
    };
  }

  async function openCredentialsDialog(credentials) {
    var items = [
      {
        type: 'subtitle',
        text: gettext(
          'These credentials are required for installation of TIS client on your external datasource. Make sure you have stored them safely. External datasource secret will never be shown anymore!'
        )
      },
      {
        text: 'Key',
        value: credentials.MQTTPublicKey,
        type: 'credential'
      },
      {
        text: 'Secret',
        value: credentials.MQTTSecret,
        type: 'credential'
      }
    ];
    var action = [
      {
        title: gettext('I have safely stored the secret'),
        fn: function() {
          $mdDialog.cancel();
        },
        color: 'accent'
      }
    ];

    var header = gettext('External datasource credentials');
    function copyToClipboard(value, elementName) {
      // create new element, append it to body, store 'value' in it, copy to clipboard and delete the element
      var tempElement = document.createElement('input');
      document.body.appendChild(tempElement);
      tempElement.setAttribute('value', value);
      tempElement.select();
      document.execCommand('copy');
      document.body.removeChild(tempElement);
      if (elementName === 'Key') {
        ToastService.showToast(gettext('Key was copied to clipboard.'));
      } else {
        ToastService.showToast(gettext('Secret was copied to clipboard.'));
      }
    }
    try {
      await InfoDialog.open(header, null, items, action, copyToClipboard);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  // create and update functions
  async function createOrUpdate() {
    vm.sendingRequest = true;
    const type = vm.editMode ? 'update' : 'create';

    const method = CrawlerMethods.getMethod({
      entity: 'external-datasources',
      method: vm.editMode ? 'update' : 'create'
    });
    let methodIdObject = {};
    if (vm.editMode) {
      methodIdObject = {
        id: externalDatasourceId
      };
    }

    try {
      let { data: externalDatasource } = await method(
        methodIdObject,
        dataToSave()
      );
      const credentials = {
        MQTTPublicKey: externalDatasource.MQTTPublicKey,
        MQTTSecret: externalDatasource.MQTTSecret
      };
      if (credentials.MQTTSecret != null) {
        await openCredentialsDialog(credentials, externalDatasource._id, type);
      }

      var redirect = {
        state: 'external-datasources-external-datasources-view',
        paramName: 'id',
        paramValue: externalDatasource._id
      };
      CrudToastFactory.toast(type, redirect);
    } catch (err) {
      vm.sendingRequest = false;
      AlertingService.Error(err);
    }
  }

  function dataToSave() {
    const timeoutInMilliseconds =
      1000 *
      (vm.dataConfig.dataObj.seconds * 1 +
        vm.dataConfig.dataObj.minutes * 60 +
        vm.dataConfig.dataObj.hours * 60 * 60 +
        vm.dataConfig.dataObj.days * 60 * 60 * 24 || 0);
    return {
      name: vm.dataConfig.dataObj.name,
      description: vm.dataConfig.dataObj.description,
      externalDatasourceType: vm.dataConfig.dataObj.externalDatasourceType.id,
      timeoutInMilliseconds,
      geoLocation: [
        vm.dataConfig.dataObj.addressObject.geolocationX,
        vm.dataConfig.dataObj.addressObject.geolocationY
      ]
    };
  }
}

export default NewExternalDatasourceFormController;
