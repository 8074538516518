import types from './time-series-processing-values.type';

function addUpdateValuesTag(payload) {
  return {
    type: types.TIME_SERIES_PROCESSING_VALUES_ADD,
    payload
  };
}

function removeUpdateValuesTag(payload) {
  return {
    type: types.TIME_SERIES_PROCESSING_VALUES_REMOVE,
    payload
  };
}

export default {
  addUpdateValuesTag,
  removeUpdateValuesTag
};
