xhrService.$inject = ['$q', '$smartAuth', 'LocalizationService'];
/**
 * @ngdoc service
 * @name common.xhrService
 * @description constructs xhr request.
 * @property {function} fetch
 * @property {function} asyncFetch
 */
export default function xhrService($q, $smartAuth, LocalizationService) {
  /**
   * @memberof xhrService.fetch
   * @description executes thread blocking xhr request.
   * @param {string} endpoint request endpoint (https://host/path)
   * @param {string} responseType type of response (arraybuffer)
   * @param {bool} asyncStatus indicates if request is async (default false)
   * @return {dataType}
   */
  function fetch(endpoint, responseType, asyncStatus) {
    var request = new XMLHttpRequest();
    request.open('GET', endpoint, asyncStatus);
    request.withCredentials = true;
    var token = $smartAuth.getToken();
    if (responseType) {
      request.responseType = responseType;
    }
    const currentLanguageNetworkCode = LocalizationService.GetSelectedLangNetworkCode();
    request.setRequestHeader('Authorization', 'Bearer ' + token);
    request.setRequestHeader('language', currentLanguageNetworkCode);
    request.send();
    return request;
  }

  /**
   * @memberof xhrService.asyncFetch
   * @description triggers asynchronous xhr fetch request.
   * @param {string} endpoint request endpoint (https://host/path)
   * @param {string} responseType type of response (arraybuffer)
   * @return {Promise}
   */
  function asyncFetch(endpoint, responseType) {
    var deferred = $q.defer();
    var request = fetch(endpoint, responseType, true);
    request.onload = function() {
      var response = this;
      deferred.resolve(response);
    };
    request.onerror = function(err) {
      deferred.reject(err);
    };
    return deferred.promise;
  }

  return {
    fetch,
    asyncFetch
  };
}
