import './month-select.scss';
import template from './month-select.component.html';

export default {
  template,
  bindings: {
    model: '=',
    required: '<',
    label: '<'
  },
  require: {
    form: '?^form'
  },
  controller: MonthSelectController,
  controllerAs: 'vm'
};
/**
 * @ngdoc component
 * @name common.monthSelect
 * @description component that aloows you to select ior writein month and year.
 * @param {object} model - object containing data we operate with.
 * @param {boolean} required - tells us if the field is required
 * @param {string} label - label on the input field
 * @example
 * <month-select
 *   label="'Select month'"
 *   model="vm.month"
 *   required="false"
 * ></month-select>
 */
MonthSelectController.$inject = ['$scope', 'gettext', '$mdMenu'];

function MonthSelectController($scope, gettext, $mdMenu) {
  var vm = this;
  vm.selectYear = selectYear;
  vm.previousYear = previousYear;
  vm.nextYear = nextYear;
  vm.buttonTitle = gettext('Select month');
  vm.initDates = init;
  vm.monthChanged = monthChanged;
  vm.$onInit = init;
  /**
   * @description function called on inizilization.
   * @function
   */
  function init() {
    if (vm.form) {
      $scope.monthForm = vm.form;
    }
    vm.options = [
      [
        {
          name: gettext('Jan'),
          count: 1
        },
        {
          name: gettext('Feb'),
          count: 2
        },
        {
          name: gettext('Mar'),
          count: 3
        },
        {
          name: gettext('Apr'),
          count: 4
        }
      ],
      [
        {
          name: gettext('Maj'),
          count: 5
        },
        {
          name: gettext('Jun'),
          count: 6
        },
        {
          name: gettext('Jul'),
          count: 7
        },
        {
          name: gettext('Avg'),
          count: 8
        }
      ],
      [
        {
          name: gettext('Sep'),
          count: 9
        },
        {
          name: gettext('Okt'),
          count: 10
        },
        {
          name: gettext('Nov'),
          count: 11
        },
        {
          name: gettext('Dec'),
          count: 12
        }
      ]
    ];
    if (typeof vm.model !== 'undefined') {
      vm.currentYear = vm.model.value
        ? vm.model.value.year || new Date().getFullYear()
        : new Date().getFullYear();
      vm.model.readable = vm.model.value.month + '.' + vm.model.value.year;
    } else {
      vm.currentYear = new Date().getFullYear();
    }
  }
  /**
   * @description decreases year in calander
   * @function
   */
  function previousYear() {
    vm.currentYear--;
  }
  /**
   * @description increases year in calander
   * @function
   */
  function nextYear() {
    vm.currentYear++;
  }
  /**
   * @description function called when month in component is changed
   * @function
   */
  function monthChanged() {
    if (vm.model.readable) {
      var parsedDate = vm.model.readable.match(
        /^(0?[1-9]|1[012])[.]([0-9]{4})$/
      );
      vm.currentYear = parsedDate[2];
      vm.model.value = {
        month: parsedDate[1],
        year: vm.currentYear
      };
    }
  }
  /**
   * @description function called when year is selected
   * @function
   */
  function selectYear(item) {
    if (!vm.model) {
      vm.model = {};
    }
    vm.model.readable = ('0' + item.count).slice(-2) + '.' + vm.currentYear;
    vm.model.value = {
      month: item.count,
      year: vm.currentYear
    };
    $mdMenu.hide();
  }
}
