import template from './sfe-item-content.component.html';
import './sfe-item-content.scss';
export default {
  template,
  bindings: {
    entity: '<',
    entityId: '<',
    configuration: '<'
  },
  controller: SfeItemContentController,
  controllerAs: 'vm'
};

SfeItemContentController.$inject = ['AlertingService'];

function SfeItemContentController(AlertingService) {
  const vm = this;
  let unsubscribe;

  vm.noContent = true;
  vm.onTabChange = onTabChange;

  vm.$onChanges = () => {
    if (vm.entity && vm.entityId && vm.configuration && !unsubscribe) {
      vm.noContent = false;
      vm.actions = vm.configuration.getActions(vm);
      //Trigger first action fn
      //to display first tab content
      vm.actions[0].fn();
    }
  };

  /**
   * @description Function is triggered on every tab change and apply template and binding values to scope.
   * @function
   * @param {Object} res {template, bindings}
   * @param {String} res.template name of template
   * @param {Object} res.bindings object ob bindings
   */
  function onTabChange({ template, bindings }) {
    if (typeof template == 'string' && template != '') {
      vm.contentTemplate = template;
      vm.bindings = bindings;
    } else {
      AlertingService.devWarn('Tango item template is missing');
    }
  }
}
