import template from './tango-timeseries-group-editor.component.html';
import './tango-timeseries-group-editor.scss';

export default {
  template,
  bindings: {
    api: '<',
    timeSeriesGroup: '<',
    query: '<',
    filtersAreShown: '<'
  },
  controller: TangoTimeseriesGroupEditor,
  controllerAs: 'vm'
};

TangoTimeseriesGroupEditor.$inject = [
  '$scope',
  'gettext',
  '$timeout',
  'ColorService',
  'TangoTimeseriesGroupEditorHelperService',
  '$state',
  'AlertingService',
  'InfoDialog',
  'gettextCatalog',
  'ShareTimeSeriesGroupService',
  'MetadataService',
  'PrivilegeManagementDialog',
  'AuthorizationToEditService',
  'TimeSeriesGroupModel',
  'CrudToastFactory'
];

function TangoTimeseriesGroupEditor(
  $scope,
  gettext,
  $timeout,
  ColorService,
  TangoTimeseriesGroupEditorHelperService,
  $state,
  AlertingService,
  InfoDialog,
  gettextCatalog,
  ShareTimeSeriesGroupService,
  MetadataService,
  PrivilegeManagementDialog,
  AuthorizationToEditService,
  TimeSeriesGroupModel,
  CrudToastFactory
) {
  let vm = this;
  //KEEPS VALUE OF GROUP GENERAL PROPERTIES (name, desc ...).  GETS SET WHEN USER COMES BACK TO PREVIEW FROM FORM EDITOR OR WHEN TIMESERIES GROUP IS LOADING FIRST TIME
  let METADATA = {};
  //KEEPS VALUE OF GROUP SCHEDULE CLASSIFICATION PROPERTIES (visualization config and timeseries)
  let FormModel = [];
  let savingGroup;
  vm.timeseriesGroupFormApi = {};
  vm.title = gettextCatalog.getString('Time series group preview');

  vm.$onChanges = async changes => {
    if (changes.timeSeriesGroup && vm.timeSeriesGroup != null) {
      vm.id = vm.timeSeriesGroup._id;
      let {
        group,
        metadata,
        formModel
      } = TangoTimeseriesGroupEditorHelperService.createModelFromGroupId(
        vm.timeSeriesGroup
      );
      vm.title = metadata.name;
      vm.group = group;
      METADATA = metadata;
      FormModel = formModel;
      //will be passed to sandbox component

      let displaySettings = {
        displayType: METADATA.dataVisualizationConfig.displayType || 3, //CHART TABLE
        chartType: METADATA.dataVisualizationConfig.defaultChartType || 10, // STACKED COLUMN
        sortType: METADATA.dataVisualizationConfig.sortedBySumOfValues
          ? 10 //SORT BY SUM
          : 1 //NONE
      };
      await $timeout();
      vm.timeseriesSandboxApi.setDisplayChartSettings(displaySettings);
      $scope.$applyAsync();
      //set tab title
      MetadataService.ChangeMetadata(METADATA.name);
    } else if (changes.query && vm.query != null) {
      initializeTimeSeriesGroupFromQuery();
    }
  };

  vm.$onInit = () => {
    vm.filtersAreShown = true;
    vm.timeseriesSandboxApi = {};
    vm.options = {
      groupId: 'timeseriesGroup'
    };
    vm.showSandbox = true;
    resetActions();
    $timeout(() => {
      vm.filterApi = getFilterApi();
    });
  };
  /**
   * @description creates sandbox configurations and sets values.
   * @function
   */
  async function initializeTimeSeriesGroupFromQuery() {
    //PARSE QUERY STRING INTO OBJECTS
    let {
      model,
      formModel,
      previewItems,
      displayType,
      sortType,
      chartType
    } = ShareTimeSeriesGroupService.parseQueryIntoParams(vm.query);

    let displaySettings = {
      displayType,
      chartType,
      sortType
    };
    // vm.displayType = displayType || 3;

    FormModel = formModel || [];
    //ACTIVE CLASSIFICATION ID
    let activeClassification = TangoTimeseriesGroupEditorHelperService.getActiveClassificationFromModel(
      FormModel
    );

    try {
      //POPULATE TIMESERIES ADN FORM MODEL
      let {
        previewTimeseries,
        formModel: updatedFormModel
      } = await TangoTimeseriesGroupEditorHelperService.constructChartSettings(
        model,
        previewItems,
        formModel
      );
      // SET SANDBOX MODEL
      vm.timeseriesSandboxApi.setModel(
        TangoTimeseriesGroupEditorHelperService.constructSandboxModelOutOfGroupFormModel(
          updatedFormModel
        ),
        activeClassification,
        previewTimeseries,
        displaySettings
      );
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  /**
   * @description returns object with functions that should be passed to filtration component.
   * @function
   * @return {Object}
   */
  function getFilterApi() {
    return {
      getValues: vm.timeseriesSandboxApi.getShownValues,
      setValue: (_, values) => {
        let newValues = values.map(value => ({
          ...value,
          color: value.color || ColorService.RandomColor(),
          chartType: value.chartType || 1
        }));
        newValues = newValues.filter((value, index, list) => {
          let foundIndex = list.findIndex(item => item._id == value._id);
          return foundIndex === index;
        });
        vm.timeseriesSandboxApi.setShownValues(_, newValues);
      }
    };
  }

  /**
   * @description creates share link out of current configuration.
   * @function
   */
  function constructShareLink() {
    let classificationsModel = vm.timeseriesSandboxApi.getValue();
    let previewTimeseries = vm.timeseriesSandboxApi.getPreview();
    let displaySettings = vm.timeseriesSandboxApi.getDisplayChartSettings();
    let query = ShareTimeSeriesGroupService.createQueryStringOutOfModel(
      classificationsModel,
      previewTimeseries,
      FormModel,
      displaySettings
    );
    let url = $state.href(
      $state.current.name,
      { query },
      { absolute: true, inherit: false }
    );
    ShareTimeSeriesGroupService.openShareDialog(url);
  }

  function resetActions() {
    if (vm.showSandbox) {
      vm.actions = [
        {
          title: gettext('Share link'),
          fn: constructShareLink
        },
        {
          title: gettext('Cancel'),
          color: 'warn',
          fn: () => {
            const title = gettext('Cancel updating time series group');
            const textItem = {
              text: gettext('Are you sure you want to cancel?'),
              type: 'text'
            };
            const actions = [
              {
                title: gettext('Cancel'),
                cancel: true,
                color: 'primary'
              },
              {
                title: gettext('Yes'),
                fn: () => {
                  $state.go('data-time-series-group-list');
                },
                color: 'success'
              }
            ];
            InfoDialog.open(title, null, [textItem], actions);
          }
        },
        {
          title: vm.id == null ? gettext('Save as') : gettext('Update'),
          color: 'success',
          raised: true,
          /**
           * @description sets all needed properties to display group form.
           * @function
           */
          fn: async () => {
            let previewItems = vm.timeseriesSandboxApi.getPreview();
            let model = vm.timeseriesSandboxApi.getValue();

            let displaySettings = vm.timeseriesSandboxApi.getDisplayChartSettings();
            if (Array.isArray(previewItems) && previewItems.length > 0) {
              let result = await TangoTimeseriesGroupEditorHelperService.openMovePreviewItemsDialog(
                previewItems,
                model
              );
              if (result == null) {
                return;
              } else {
                model = result;
              }
            }
            vm.showSandbox = false;
            resetActions();

            await $timeout();
            //SET GROUP FORM VALUES
            if (model != null) {
              let scheduleClassifications = Object.keys(model)
                .map(key => {
                  let formItem =
                    FormModel.find(
                      item => item.scheduleClassification.id == key
                    ) || {};
                  return {
                    ...formItem,
                    scheduleClassification: key,
                    order: model[key].scheduleClassification.order,
                    chartSettings: model[key].series
                  };
                })
                .sort((a, b) => a.order - b.order);

              if (
                vm.timeseriesGroupFormApi != null &&
                typeof vm.timeseriesGroupFormApi.setValues == 'function'
              ) {
                vm.timeseriesGroupFormApi.setValues({
                  metadata: {
                    ...METADATA,
                    ...displaySettings
                  },
                  scheduleClassifications
                });
              }
            }
            vm.timeseriesSandboxApi = null;
            vm.filterApi = null;
          }
        }
      ];
      let authorizedToEdit = AuthorizationToEditService.isAuthorizedToEdit();
      if (vm.id != null && authorizedToEdit === true) {
        let privilegeManagementAction = {
          title: gettext('Privilege management'),
          color: 'primary',
          /**
           * @description opens privilege management dialog.
           * @function
           */
          fn: () => {
            let config = {
              entityId: vm.id,
              identifier: 'tis_be:time-series-groups'
            };
            PrivilegeManagementDialog.open(config);
          }
        };
        let saveChangesAction = {
          title: gettext('Save'),
          color: 'primary',
          raised: true,
          /**
           * @description save changes made to groups / charts.
           * @function
           */
          fn: async () => {
            const {
              displayType,
              sortType,
              chartType
            } = vm.timeseriesSandboxApi.getDisplayChartSettings();
            const groupData = vm.timeseriesSandboxApi.getValue();
            savingGroup = true;
            let displaySettings = {
              sortedBySumOfValues: false,
              defaultChartType: 10 //STACKED COLUMN
            };
            let scheduleClassifications = [];
            // SERIES
            if (groupData != null) {
              scheduleClassifications = FormModel.map(item => {
                let seriesData = groupData[item.scheduleClassification.id];
                let series = seriesData.series.map(item => {
                  return {
                    timeSeries: item._id,
                    dataVisualizationConfig: {
                      color: item.color,
                      chartType: item.chartType
                    }
                  };
                });

                return {
                  scheduleClassification: seriesData.scheduleClassification.id,
                  isMain: seriesData.isMain,
                  defaultFilter: seriesData.defaultFilter,
                  _id: seriesData._id,
                  series
                };
              });
            }

            if (sortType == 10) {
              //SORT BY SUM
              displaySettings = {
                ...displaySettings,
                defaultChartType: chartType || 10, //STACKED COLUMN
                sortedBySumOfValues: true
              };
            }
            let apiObject = {
              dataVisualizationConfig: {
                displayType: displayType,
                ...displaySettings
              },
              timeSeriesGroupType: METADATA.timeSeriesGroupType._id,
              scheduleClassifications
            };
            try {
              await TimeSeriesGroupModel.update({ id: vm.id }, apiObject);

              CrudToastFactory.toast('update');
            } catch (err) {
              AlertingService.Error(err);
            }
            savingGroup = false;
          },
          disabledFn: () => {
            return savingGroup;
          }
        };
        vm.actions = [
          privilegeManagementAction,
          ...vm.actions,
          saveChangesAction
        ];
      }
    } else {
      vm.actions = [
        {
          title: gettext('Preview'),
          /**
           * @description sets all needed property do display sandbox preview.
           * @function
           */
          fn: async () => {
            let model = vm.timeseriesGroupFormApi.getValues();
            METADATA = model.metadata;

            FormModel = model.scheduleClassifications;
            let activeClassification = TangoTimeseriesGroupEditorHelperService.getActiveClassificationFromModel(
              FormModel
            );

            let displaySettings = {
              displayType: METADATA.displayType,
              chartType: METADATA.chartType || 10, //STACKED COLUMN
              sortType: METADATA.sortType || 1 //NONE
            };

            //set group to null
            //if group is not set to null
            // sandbox will init from original group
            vm.group = null;
            vm.showSandbox = true;
            resetActions();
            vm.timeseriesSandboxApi = {};
            await $timeout();

            vm.timeseriesSandboxApi.setModel(
              TangoTimeseriesGroupEditorHelperService.constructSandboxModelOutOfGroupFormModel(
                FormModel
              ),
              activeClassification,
              [],
              displaySettings
            );

            vm.filterApi = getFilterApi();
          }
        }
      ];
    }
  }
}
