import actionTypes from '../action/mt-analysis-flow.type';

MTAnalysisFlowMiddleware.$inject = [
  'MtAnalysisFlowModel',
  'AlertingService',
  'CrudToastFactory'
];
function MTAnalysisFlowMiddleware(
  MtAnalysisFlowModel,
  AlertingService,
  CrudToastFactory
) {
  return () => next => async action => {
    switch (action.type) {
    case actionTypes.MT_ANALYSIS_FLOW_READ:
      try {
        let result = await MtAnalysisFlowModel.read({
          ...action.payload,
          populate: 'inputTimeSeriesForXAxis,inputTimeSeriesForYAxis'
        });
        action.payload = {
          ...action.payload,
          result
        };
      } catch (err) {
        AlertingService.Error(err);
        action.payload.result = err;
      }
      break;
    case actionTypes.MT_ANALYSIS_FLOW_UPDATE:
      try {
        await MtAnalysisFlowModel.update(
          action.payload.query,
          action.payload.body
        );

        action.payload.result = await MtAnalysisFlowModel.read({
          ...action.payload.query,
          populate: 'inputTimeSeriesForXAxis,inputTimeSeriesForYAxis'
        });
        CrudToastFactory.toast('update');
      } catch (err) {
        AlertingService.Error(err);
        action.payload.result = err;
      }
      break;
    case actionTypes.MT_ANALYSIS_FLOW_CREATE:
      try {
        action.payload.result = await MtAnalysisFlowModel.create(
          action.payload.query || {},
          action.payload.body
        );
      } catch (err) {
        AlertingService.Error(err);
      }
      break;
    }
    next(action);
  };
}

export default MTAnalysisFlowMiddleware;
