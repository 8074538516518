NewStepController.$inject = [
  '$stateParams',
  '$state',
  'AlertingService',
  'gettext',
  'MultiLanguage',
  'MetadataService',
  'LocalizationService',
  'createOrUpdateService',
  'SfeHeader',
  'step',
  '$timeout'
];

function NewStepController(
  $stateParams,
  $state,
  AlertingService,
  gettext,
  MultiLanguage,
  MetadataService,
  LocalizationService,
  createOrUpdateService,
  SfeHeader,
  step,
  $timeout
) {
  const vm = this;
  const stepId = $stateParams.id;
  vm.duplicateMode =
    $state.current.name === 'alarms-and-rules-sop-steps-duplicate';

  init();

  function init() {
    vm.editMode = !!stepId;
    vm.formValid = false;
    vm.dataConfig = {};

    if (vm.editMode) {
      MetadataService.Loading(true);
      if (vm.duplicateMode) {
        vm.dataConfig.header = SfeHeader.constructFormHeader(
          'primary',
          gettext('Duplicate step'),
          'alarms-and-rules-sop-steps-view',
          { id: stepId }
        );
      } else {
        vm.dataConfig.header = SfeHeader.constructFormHeader(
          'primary',
          gettext('Edit step'),
          'alarms-and-rules-sop-steps-view',
          { id: stepId }
        );
      }
      GetSteps();
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New step'),
        'alarms-and-rules-sop-steps-list'
      );
      vm.dataConfig.dataObj = {
        name: '',
        description: '',
        tasks: []
      };
    }

    let data = [
      {
        placeholder: gettext('Name'),
        name: 'name',
        type: 'text',
        componentType: 'multilanguage',
        required: true
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        maxlength: 500,
        type: 'text',
        required: false,
        componentType: 'multilanguage'
      },
      {
        title: gettext('TasksTitle'),
        componentType: 'title'
      },
      {
        componentType: 'multiSelectList',
        name: 'tasks',
        removeTitle: gettext('Remove Task'),
        addLabel: gettext('Add Task'),
        groupLabel: gettext('Task'),
        selectConfigs: [
          {
            componentType: 'autocomplete',
            edit: vm.editMode,
            entity: 'tasks',
            searchParamName: 'filter',
            selectedParam: 'id',
            required: true,
            query: {
              entity: 'tasks',
              method: 'read'
            },
            placeholder: gettext('Choose Task'),
            floatingLabel: gettext('Task'),
            reset: true,
            filterFn: items => {
              if (Array.isArray(items)) {
                let filteredItems = items.filter(item => {
                  let foundItem = vm.dataConfig.dataObj.tasks.find(
                    task =>
                      Array.isArray(task) &&
                      task[0] != null &&
                      task[0]._id === item._id
                  );
                  if (!foundItem) {
                    return item;
                  }
                });
                return filteredItems;
              }
              return [];
            }
          }
        ]
      }
    ];
    vm.dataConfig.data = data;
    vm.dataConfig.edit = vm.editMode;
    vm.dataConfig.action = {
      ctrlFn: true,
      title:
        vm.editMode && !vm.duplicateMode
          ? gettext('Update')
          : gettext('Create'),
      fn: createOrUpdate
    };
  }

  function GetSteps() {
    let selectedLang = LocalizationService.GetSelectedLangNetworkCode();
    step.tasks = step.tasks.map(function(item) {
      return [
        {
          name: item.name[selectedLang],
          _id: item._id
        }
      ];
    });
    $timeout(() => {
      MetadataService.Loading(false);
      if (vm.duplicateMode) {
        MetadataService.ChangeMetadata(
          'Duplicate ' + step.name[selectedLang],
          step.description[selectedLang]
        );
      } else {
        MetadataService.ChangeMetadata(
          'Edit ' + step.name[selectedLang],
          step.description[selectedLang]
        );
      }
    });
    vm.dataConfig.dataObj = step;
  }

  // create or update functions

  function createOrUpdate() {
    vm.sendingRequest = true;
    createOrUpdateService
      .simpleCall(
        {
          entity: 'steps',
          method: vm.editMode && !vm.duplicateMode ? 'update' : 'create'
        },
        vm.editMode,
        vm.duplicateMode ? null : stepId,
        dataToSave(),
        'alarms-and-rules-sop-steps-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function dataToSave() {
    const data = MultiLanguage.constructForm(
      vm.dataConfig.data,
      vm.dataConfig.dataObj
    );
    return {
      name: data.name,
      description: data.description,
      tasks: data.tasks.map(item => item[0]._id)
    };
  }
}

export default NewStepController;
