DatasetController.$inject = [
  '$stateParams',
  'gettext',
  'DatasetColumnHelper',
  'TranslationService',
  'MetadataService',
  'dataset',
  '$timeout'
];

function DatasetController(
  $stateParams,
  gettext,
  DatasetColumnHelper,
  TranslationService,
  MetadataService,
  dataset,
  $timeout
) {
  const vm = this;
  const datasetId = $stateParams.id;

  init();

  function init() {
    MetadataService.Loading(true);
    vm.loading = true;
    vm.headerData = constructHeader(dataset);
    vm.loading = false;
    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(dataset.name, dataset.description);
    });
    constructColumnTable();
  }

  function constructHeader(dataset) {
    var types = TranslationService.GetCollection('codelists.dataTypes');
    vm.columns = _.map(dataset.columns, function(column) {
      var typeObject = _.find(types, {
        id: column.type
      });
      return {
        rawFieldName: column.rawFieldName,
        displayFieldName: column.displayFieldName,
        filterEnabled: column.filterEnabled,
        typeName: typeObject ? typeObject.name : gettext('Unknown'),
        entityName: DatasetColumnHelper.getEntityName(column.entity)
      };
    });
    const comparableColumns = [
      {
        title: gettext('Comparable columns'),
        type: 'tags',
        paramName: 'value',
        values: _.map(dataset.comparableColumns, function(column) {
          return {
            value: column
          };
        })
      }
    ];
    const actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: datasetId,
        endpoint: {
          entity: 'datasets',
          method: 'delete'
        },
        redirectState: 'analytics-datasets-list'
      },
      {
        title: gettext('Update'),
        state: 'analytics-datasets-edit',
        param: 'id',
        paramValue: dataset._id
      }
    ];
    const properties = [
      {
        title: gettext('Code'),
        value: dataset.code,
        type: 'code'
      }
    ];
    const userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];

    const propertySections = [
      {
        title: gettext('properties'),
        properties: properties
      },
      {
        title: gettext('Comparable columns'),
        properties: comparableColumns
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'datasets',
        target: dataset._id,
        type: 'userAccess'
      }
    ];

    return {
      metadata: {
        definition: 'Dataset',
        title: dataset.name,
        description: dataset.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }

  function constructColumnTable() {
    vm.headers = DatasetColumnHelper.header;
    vm.tableConfig = DatasetColumnHelper.viewConfig();
  }
}

export default DatasetController;
