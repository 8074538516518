EnergySourceViewController.$inject = [
  '$stateParams',
  'gettext',
  'MetadataService',
  'PhysicalCollectionService',
  'AlertingService',
  'gettextCatalog',
  'energySourceType'
];

function EnergySourceViewController(
  $stateParams,
  gettext,
  MetadataService,
  PhysicalCollectionService,
  AlertingService,
  gettextCatalog,
  energySourceType
) {
  var vm = this;
  var typeId = $stateParams.id;

  init();

  function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    readPhysicalData(energySourceType);
  }

  function readPhysicalData(data) {
    var type = angular.copy(data);
    var obj = {};
    obj.physicalQuantity = type.defaultPhysicalQuantity;
    obj.metricPrefix = type.defaultMetricPrefix;
    obj.measurementUnit = type.defaultMeasurementUnit;
    PhysicalCollectionService.getReadablePhysicalData(obj).then(
      function(res) {
        type.defaultPhysicalQuantityName = res.physicalQuantity
          ? res.physicalQuantity.name
          : gettext('None');
        type.defaultMeasurementUnitName = res.readableMeasurementUnitItemView;
        vm.headerData = constructHeader(type);
        vm.loading = false;
        MetadataService.Loading(false);
        MetadataService.ChangeMetadata(type.name, type.description);
      },
      function(err) {
        AlertingService.Error(err);
        type.defaultPhysicalQuantityName = 'Error';
        type.defaultMeasurementUnitName = 'Error';
        vm.headerData = constructHeader(type);
        vm.loading = false;
        MetadataService.Loading(false);
        MetadataService.ChangeMetadata(type.name, type.description);
      }
    );
  }
  function constructHeader(type) {
    var properties = [];

    if (type.hasOwnProperty('parent') && type.parent !== null) {
      properties.push({
        type: 'link',
        title: gettext('Parent'),
        linkTitle: type.parent.name,
        state: 'configurations-energy-management-energy-source-types-view',
        paramObject: {
          id: type.parent._id
        }
      });
    } else {
      properties.push({
        type: 'translate',
        title: gettext('Parent'),
        value: gettext('None')
      });
    }

    properties.push(
      {
        title: gettext('Default physical quantity'),
        value: type.defaultPhysicalQuantityName
          ? type.defaultPhysicalQuantityName
          : gettextCatalog.getString('None'),
        type: 'simple'
      },
      {
        title: gettext('Measurement unit'),
        value: type.defaultMeasurementUnitName
          ? type.defaultMeasurementUnitName
          : gettextCatalog.getString('None'),
        type: 'simple'
      }
    );
    properties.push({
      title: gettext('Code'),
      value: type.code,
      type: 'code'
    });
    if (type.externalCode) {
      properties.push({
        title: gettext('External code'),
        value: type.externalCode,
        type: 'code'
      });
    }
    if (type.icon && type.icon.name) {
      properties.push({
        title: gettext('Icon'),
        type: 'simple',
        energyIcon: type.icon
      });
    }

    var propertySections = [
      {
        title: gettext('Properties'),
        properties: properties
      }
    ];

    var propertiesSection2 = [
      {
        type: 'simple',
        title: gettext('Transformation to kWh'),
        value: type.transformationToKwh
      },
      {
        type: 'simple',
        title: gettext('CO2 per kWh'),
        value: type.co2perKwh
      },
      {
        type: 'simple',
        title: gettext('CO per kWh'),
        value: type.coPerKwh
      },
      {
        type: 'simple',
        title: gettext('NOx per kWh'),
        value: type.noxPerKwh
      },
      {
        type: 'simple',
        title: gettext('SO2 per kWh'),
        value: type.so2perKwh
      },
      {
        type: 'simple',
        title: gettext('Parts per kWh'),
        value: type.partsPerKwh
      }
    ];
    propertySections.push({
      properties: propertiesSection2
    });

    var actions = [
      {
        title: gettext('Update'),
        state: 'configurations-energy-management-energy-source-types-edit',
        param: 'id',
        paramValue: typeId
      }
    ];

    return {
      metadata: {
        definition: gettext('Energy Source Type'),
        title: type.name,
        description: type.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}

export default EnergySourceViewController;
