import themesJSON from '../../../config/tis/themes.json';

function ThemingProvider() {
  var self = this;

  this.shades = {
    primary: {
      default: '500',
      'hue-1': '400',
      'hue-2': '800',
      'hue-3': '300',
      'hue-4': '100'
    },
    warn: {
      default: '500',
      'hue-1': '400',
      'hue-2': '800',
      'hue-3': '300'
    },
    accent: {
      default: '500',
      'hue-1': '400',
      'hue-2': '800',
      'hue-3': '300'
    },
    success: {
      default: '500',
      'hue-1': '400',
      'hue-2': '800',
      'hue-3': '300'
    }
  };

  this.palettes = {};
  this.themes = {};

  themesJSON.forEach(function(theme) {
    self.themes[theme.name] = {};
    Object.keys(theme).forEach(function(paletteKey) {
      var palette = {};
      if (paletteKey !== 'name') {
        Object.keys(theme[paletteKey].palette).forEach(function(shadeKey) {
          palette[shadeKey] = theme[paletteKey].palette[
            shadeKey
          ].value.hex.substr(1);
        });
        var darkColors = theme[paletteKey].contrastDarkColors;
        var lightColors = theme[paletteKey].contrastLightColors;
        palette.contrastDefaultColor = theme[paletteKey].contrastDefaultColor;
        palette.contrastDarkColors = Array.isArray(darkColors)
          ? darkColors.map(mapToValueColors)
          : typeof darkColors === 'undefined'
            ? []
            : [darkColors];
        palette.contrastLightColors = Array.isArray(lightColors)
          ? lightColors.map(mapToValueColors)
          : typeof lightColors === 'undefined'
            ? []
            : [lightColors];
        self.palettes[theme.name + '-' + paletteKey] = palette;
        self.themes[theme.name][paletteKey] = theme.name + '-' + paletteKey;
      }
    });

    function mapToValueColors(color) {
      return color.value;
    }

    // create accent theme aswell
    self.themes[theme.name + '-accent'] = {
      accent: self.themes[theme.name].primary,
      primary: self.themes[theme.name].accent,
      success: self.themes[theme.name].success,
      warn: self.themes[theme.name].warn
    };
  });

  this.moduleTheme = {
    default: 'grey'
  };

  this.setTheme = function(mod, theme) {
    self.moduleTheme[mod] = theme;
  };

  this.$get = function() {
    return {
      moduleTheme: function(name) {
        return self.moduleTheme[name]
          ? self.moduleTheme[name]
          : self.moduleTheme.default;
      },
      setModuleTheme: function(mod, theme) {
        self.setTheme(mod, theme);
      },
      palette: function(name) {
        return _.find(self.palettes, function(paletteVal, paletteName) {
          return name === paletteName;
        });
      },
      palettes: function() {
        return self.palettes;
      },
      theme: function(name) {
        return _.find(self.themes, function(themeVal, themeName) {
          return name === themeName;
        });
      },
      themes: function() {
        return self.themes;
      },
      shades: function() {
        return self.shades;
      },
      getShade: function(paletteName, shadeName) {
        if (!shadeName) {
          // default color
          shadeName = 'default';
        }

        var palette = self.$get().palette(paletteName);
        var shadeCode = self.shades.primary[shadeName];

        if (palette && palette[shadeCode]) {
          return palette[shadeCode];
        }
      }
    };
  };

  return self;
}

export default ThemingProvider;
