MetadataService.$inject = ['gettext', '$interval', 'faviconConstant'];
/**
 * @ngdoc service
 * @name core.MetadataService
 * @description Sets metadata of the current state.
 * @property {function} ChangeMetadata - Sets the metadata displayed of the displayed document.
 * @property {function} Loading - Controls animation of the favicon.
 */
function MetadataService(gettext, $interval, faviconConstant) {
  /**
   * @description Triggers animation of the favicon.
   * @function
   * @param {boolean} arg
   */
  var pageIsLoading = false;
  var cancelCycle = false;
  var interval = null;
  var stateLoadingListeners = [];

  /**
   * @description Toggles loading state when on state.
   * @function
   * @param {boolean} isLoading
   */
  function Loading(isLoading) {
    if (typeof isLoading === 'boolean' && pageIsLoading !== isLoading) {
      pageIsLoading = isLoading;
      if (pageIsLoading) {
        cancelCycle = false;
        loaderCycle();
      } else {
        cancelCycle = true;
      }
    }
  }

  /**
   * @description Triggers all listeners when state change appears.
   * @function
   * @param {boolean} isLoading
   */
  function stateChangeHappening(isLoading) {
    if (isLoading) {
      stateLoadingListeners.forEach(listener => listener.isLoadingCallback());
    } else {
      stateLoadingListeners.forEach(listener =>
        listener.stoppedLoadingCallback()
      );
    }
  }

  /**
   * @description Called when loading starts.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function register(isLoadingCallback, stoppedLoadingCallback) {
    stateLoadingListeners.push({
      isLoadingCallback,
      stoppedLoadingCallback
    });
  }

  /**
   * @description Sets the metadata displayed of the displayed document.
   * @function
   * @param {string} title Title of the page.
   * @param {string} description Description of the page.
   */
  function ChangeMetadata(title, description) {
    if (typeof title === 'undefined') {
      title = gettext('TIS');
    }
    if (typeof description === 'undefined') {
      description = gettext('Energy management system.');
    }
    document.title = title;
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', description);
  }

  /**
   * @description description.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function loaderCycle() {
    var state = 0;
    if (!interval) {
      interval = $interval(function() {
        if (state === 6) {
          if (cancelCycle) {
            $interval.cancel(interval);
            interval = null;
          } else {
            state = 0;
          }
        } else {
          changeFavicon(state);
        }
        state++;
      }, 333);
    }
  }

  /**
   * @description Changes favicon based on index of the animation cycle.
   * @function
   * @param {Number} index
   */
  function changeFavicon(index) {
    var linkDOM = document.getElementById('dynamic-favicon');
    linkDOM.href = 'data:image/png;base64,' + faviconConstant[index];
    linkDOM = null;
  }

  return {
    ChangeMetadata,
    Loading,
    register,
    stateChangeHappening
  };
}

export default MetadataService;
