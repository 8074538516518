module.exports = {
  entity: 'groups',
  path: 'group',
  identityService: true,
  name: {
    en: 'Detail invoices',
    sl_SI: 'Računi'
  },
  networkModel: {
    entity: 'groups',
    read: 'read'
  },
  scope: 'identity:group',

  dialog: {
    title: {
      en: 'Select group',
      sl_SI: 'Izberite skupino'
    },
    listInfo: {
      en: 'Select group from the list below',
      sl_SI: 'Izberite skupino s spodnjega seznama'
    },
    identityService: true
  },
  sfeTree: {
    showConnectedEntities: false,
    route: 'groups',
    parentEntity: 'groups',
    parentKey: 'parent',
    icon: 'group',
    childrenConfiguration: [
      {
        type: 'simple',
        usedIn: ['everywhere'],
        route: 'groups',
        childKey: 'parent',
        icon: 'group',
        existenceParam: 'possible'
      }
    ]
  }
}
