AlarmDefinitionController.$inject = [
  '$stateParams',
  'gettext',
  'MetadataService',
  'AlarmMonitoringConfiguration',
  'gettextCatalog',
  'StandardUtils',
  'alarm',
  '$timeout'
];

function AlarmDefinitionController(
  $stateParams,
  gettext,
  MetadataService,
  AlarmMonitoringConfiguration,
  gettextCatalog,
  StandardUtils,
  alarm,
  $timeout
) {
  const vm = this;
  const alarmDefinitionId = $stateParams.id;
  vm.alarm = {};
  init();

  function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    vm.alarm = alarm;
    vm.headerData = constructHeader(alarm);
    $timeout(() => {
      MetadataService.ChangeMetadata(vm.alarm.name, vm.alarm.description);
      MetadataService.Loading(false);
      vm.loading = false;
    });
    initAlarmsInProgress();
    getAlarmsInProgressConfiguration();
  }

  function constructHeader(alarm) {
    let alarmProperties = [];
    let responsibilityProperties = [];
    let notificationProperties = [];
    let userAccess = [];
    alarmProperties.push(
      {
        title: gettext('Type'),
        value: alarm.type ? alarm.type.name : gettext('Unknown'),
        type: 'simple'
      },
      {
        title: gettext('Severity'),
        value: alarm.severity ? alarm.severity.name : gettext('Unknown'),
        type: 'simple'
      },
      {
        title: gettext('Created'),
        value: alarm.created,
        type: 'date'
      },
      {
        title: gettext('Updated'),
        value: alarm.updated || gettext('Unknown'),
        type: 'date'
      }
    );

    responsibilityProperties.push({
      title: gettext('Response and resolution time'),
      value: alarm.responseTimeRequired,
      type: 'radio'
    });

    if (alarm.responseTimeRequired) {
      responsibilityProperties.push(
        {
          title: gettext('Response time'),
          value: alarm.responseTime
            ? alarm.responseTime.name
            : gettext('Unknown'),
          type: 'simple'
        },
        {
          title: gettext('Resolution in'),
          value: alarm.resolutionTime
            ? alarm.resolutionTime.name
            : gettext('Unknown'),
          type: 'simple'
        }
      );
    }

    responsibilityProperties.push({
      title: gettextCatalog.getString('Time To Trigger'),
      value:
        StandardUtils.readableExactTimeOutOfSeconds(
          alarm.timeToTrigger / 1000
        ) || alarm.timeToTrigger / 1000 + 's',
      type: 'simple'
    });

    userAccess.push(
      {
        title: gettext('Users'),
        users: true,
        type: 'userAccess'
      },
      {
        title: gettext('Groups'),
        users: false,
        type: 'userAccess'
      }
    );

    notificationProperties.push(
      {
        title: gettext('Notification via E-mail'),
        value: alarm.email,
        type: 'radio'
      },
      {
        title: gettext('Notification via SMS'),
        value: alarm.sms,
        type: 'radio'
      }
    );
    let propertySections = [
      {
        title: gettext('Properties'),
        properties: alarmProperties
      },
      {
        title: gettext('Responsibility'),
        properties: responsibilityProperties
      },
      {
        title: gettext('Notifications'),
        properties: notificationProperties
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'alarms',
        target: alarmDefinitionId,
        type: 'userAccess'
      }
    ];

    let actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: alarm._id,
        endpoint: {
          entity: 'alarms',
          method: 'delete'
        },
        redirectState: 'alarms-and-rules-alarm-definitions-list'
      },
      {
        title: gettext('duplicate'),
        state: 'alarms-and-rules-alarm-definitions-duplicate',
        param: 'id',
        paramValue: alarm._id
      },
      {
        title: gettext('Update'),
        state: 'alarms-and-rules-alarm-definitions-edit',
        param: 'id',
        paramValue: alarm._id
      }
    ];

    return {
      metadata: {
        definition: gettext('alarm definition'),
        title: alarm.name,
        description: alarm.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }

  function initAlarmsInProgress() {
    vm.filters = {
      alarm: alarmDefinitionId
    };
  }

  function getAlarmsInProgressConfiguration() {
    let alarmsListConfiguration = angular.copy(AlarmMonitoringConfiguration);
    alarmsListConfiguration.filter = alarmsListConfiguration.filter.filter(
      function(item) {
        return item.param !== 'alarm';
      }
    );
    vm.alarmConfig = alarmsListConfiguration;
  }
}

export default AlarmDefinitionController;
