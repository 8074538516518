CalculatedDetailInvoiceNormalizedServiceDateModel.$inject = ['ModelUtilities'];

function CalculatedDetailInvoiceNormalizedServiceDateModel(ModelUtilities) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'esb',
    path: 'calculate-detail-invoice-normalized-service-date'
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),

    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default CalculatedDetailInvoiceNormalizedServiceDateModel;
