import { DateTime } from 'luxon';

TriggeredAlarmController.$inject = [
  '$state',
  'AlertingService',
  'gettext',
  'UserModel',
  'ToastService',
  'gettextCatalog',
  'MetadataService',
  'TranslationService',
  'SfeFormDialogService',
  'AlarmMonitoringHelper',
  'TriggeredAlarmModel',
  'triggeredAlarm',
  'SopModel'
];

function TriggeredAlarmController(
  $state,
  AlertingService,
  gettext,
  UserModel,
  ToastService,
  gettextCatalog,
  MetadataService,
  TranslationService,
  SfeFormDialogService,
  AlarmMonitoringHelper,
  TriggeredAlarmModel,
  triggeredAlarm,
  SopModel
) {
  const vm = this;
  const alarmId = $state.params.id;
  vm.sops = [];
  vm.resolve = openResolveDialog;
  init();

  function init() {
    MetadataService.Loading(true);
    vm.loading = true;

    enrichAlarmDetails();
    constructActivityTable();
  }

  async function GetSOPs(alarm) {
    if (Array.isArray(alarm.sops) && alarm.sops.length > 0) {
      try {
        const { data } = await SopModel.read({ _id: alarm.sops });
        vm.sops = data.sort((a, b) => {
          let aIndex = alarm.sops.indexOf(a._id);
          let bIndex = alarm.sops.indexOf(b._id);
          return aIndex - bIndex;
        });
        vm.noSOPsMSg = gettextCatalog.getPlural(
          vm.sops.length,
          'There is one standard operating procedure bound to this alarm',
          'There are {{count}} standard operating procedures bound to this alarm.',
          { count: vm.sops.length }
        );
      } catch (err) {
        AlertingService.Error(err);
        vm.noSOPsMSg = gettextCatalog.getPlural(
          vm.sops.length,
          'There is one standard operating procedure bound to this alarm',
          'There are {{count}} standard operating procedures bound to this alarm.',
          { count: vm.sops.length }
        );
      }
    }
  }

  async function enrichAlarmDetails() {
    try {
      let event;
      try {
        event = await AlarmMonitoringHelper.fetchEvent(triggeredAlarm);
      } catch (err) {
        AlertingService.Error(err);
      }
      vm.headerData = constructHeader(triggeredAlarm, event);
      fetchActivityMetadata(triggeredAlarm.triggeredAlarmActivity);
      if (triggeredAlarm.alarm) {
        GetSOPs(triggeredAlarm.alarm);
      }
      vm.loading = false;
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(
        vm.headerData.metadata.title,
        vm.headerData.metadata.description
      );
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  function constructHeader(alarm, eventItem) {
    let state;
    let param;

    let alarmData = [];
    let actions = [];
    let resolutionTime = null;

    const eventType = TranslationService.GetCollectionById(
      'codelists.eventTypes',
      alarm.eventType
    );

    if (typeof alarm.alarm === 'object' && alarm.alarm != null) {
      alarmData = [
        {
          title: gettext('Alarm'),
          state: 'alarms-and-rules-alarm-definitions-view',
          param: 'id',
          paramValue: alarm.alarm._id,
          linkTitle: alarm.alarm.name,
          type: 'link'
        }
      ];
    }
    if (alarm.timeSeries && typeof alarm.timeSeries == 'object') {
      alarmData.push({
        title: gettext('Time series'),
        state: 'data-time-series-view',
        param: 'id',
        paramValue: alarm.timeSeries._id,
        linkTitle: alarm.timeSeries.name,
        type: 'link'
      });
    }
    if (eventType) {
      alarmData.push({
        title: gettext('Event type'),
        value: eventType.name,
        type: 'simple'
      });
    }
    if (alarm.alarm) {
      if (alarm.alarm.severity)
        alarmData.push({
          title: gettext('Severity'),
          value: alarm.alarm.severity.name,
          type: 'simple',
          color: alarm.alarm.severity.color || '#000'
        });
      if (alarm.alarmStatus !== 3 && alarm.alarm.resolutionTime) {
        //NOT RESOLVED
        resolutionTime =
          alarm.alarm.resolutionTime.milliseconds + alarm.triggeredTimestamp;
      }
    }

    if (alarm.triggeredTimestamp) {
      alarmData.push({
        title: gettext('Triggered at'),
        value: alarm.triggeredTimestamp,
        type: 'date'
      });
    }

    if (eventType) {
      state = '';
      param = '';
      switch (alarm.eventType) {
      case 1: //rule
        state = 'alarms-and-rules-rules-view';
        param = 'id';
        break;
      case 2: //mapping-rule
        state = 'alarms-and-rules-mapping-rules-view';
        param = 'id';
        break;
      }
      if (typeof eventItem == 'object' && eventItem != null) {
        alarmData.push({
          title: eventType.name || gettext('Unknown'),
          linkTitle: eventItem.name,
          state: state,
          param: param,
          paramValue: eventItem._id,
          type: 'link'
        });
      }
    }

    let alarmPersonel = [];
    if (alarm.responsiblePersonObject) {
      alarmPersonel.push({
        title: gettext('Person responsible'),
        state: 'users-users-view',
        param: 'id',
        paramValue: alarm.responsiblePersonObject._id,
        linkTitle:
          alarm.responsiblePersonObject.name +
          ' ' +
          alarm.responsiblePersonObject.family_name,
        type: 'link'
      });
    }

    if (alarm.currentAssigneeObject) {
      alarmPersonel.push({
        title: gettext('Current Assignee'),
        state: 'users-users-view',
        param: 'id',
        paramValue: alarm.currentAssigneeObject._id,
        linkTitle:
          alarm.currentAssigneeObject.name +
          ' ' +
          alarm.currentAssigneeObject.family_name,
        type: 'link'
      });
    }

    let alarmStatus = [];
    const status = TranslationService.GetCollectionById(
      'codelists.alarmStatuses',
      alarm.alarmStatus
    );
    if (status) {
      alarmStatus.push({
        title: gettext('Status'),
        value: status.name,
        type: 'simple'
      });
    }

    if (resolutionTime !== null) {
      alarmStatus.push({
        title: gettext('Must be resolved by'),
        value: resolutionTime,
        type: 'date',
        color: Date.now() >= resolutionTime ? 'rgb(207, 14, 14)' : 'black'
      });
    }

    //RESOLVED
    if (alarm.alarmStatus === 3 && alarm.resolvedAt) {
      alarmStatus.push({
        title: gettext('Resolved at'),
        value: alarm.resolvedAt,
        type: 'date'
      });
    }

    if (alarm.resolutionComment) {
      alarmStatus.push({
        title: gettext('Resolution comment'),
        value: alarm.resolutionComment,
        showMoreShowLess: alarm.resolutionComment.length > 70,
        substringLength: 70,
        simple: alarm.resolutionComment.length < 70,
        type: 'simple'
      });
    }

    let alarmRelatedCompanyResources = [];

    if (alarm.locationDetails && typeof alarm.locationDetails == 'object') {
      if (
        alarm.locationDetails.location &&
        typeof alarm.locationDetails.location == 'object'
      ) {
        alarmRelatedCompanyResources.push({
          title: gettext('Location'),
          state: 'company-resources-locations-view',
          param: 'id',
          paramValue: alarm.locationDetails.location._id,
          linkTitle: alarm.locationDetails.location.name,
          type: 'link'
        });
      }
      if (
        alarm.locationDetails.asset &&
        typeof alarm.locationDetails.asset == 'object'
      ) {
        alarmRelatedCompanyResources.push({
          title: gettext('Asset'),
          state: 'company-resources-assets-view',
          param: 'id',
          paramValue: alarm.locationDetails.asset._id,
          linkTitle: alarm.locationDetails.asset.name,
          type: 'link'
        });
      }
      if (
        alarm.locationDetails.measuringPoint &&
        typeof alarm.locationDetails.measuringPoint == 'object'
      ) {
        alarmRelatedCompanyResources.push({
          title: gettext('Measuring point'),
          state: 'company-resources-measuring-points-view',
          param: 'id',
          paramValue: alarm.locationDetails.measuringPoint._id,
          linkTitle: alarm.locationDetails.measuringPoint.name,
          type: 'link'
        });
      }

      if (
        alarm.locationDetails.costCentre &&
        typeof alarm.locationDetails.costCentre == 'object'
      ) {
        alarmRelatedCompanyResources.push({
          title: gettext('Cost centre'),
          state: 'company-resources-cost-centres-view',
          param: 'id',
          paramValue: alarm.locationDetails.costCentre._id,
          linkTitle: alarm.locationDetails.costCentre.name,
          type: 'link'
        });
      }
    }

    let alarmProperties = [];

    // getting time untill alarm must be resolved
    let alarmIsElapsed = false;
    let timeString;
    let till;
    if (alarm.timeToResolve) {
      till = DateTime.fromJSDate(new Date(alarm.timeToResolve));
      let duration = till.diffNow(['days', 'hours', 'minutes', 'seconds']);
      timeString =
        duration.days +
        ' days ' +
        duration.hours +
        ' hours ' +
        duration.minutes +
        ' minutes ' +
        duration.seconds +
        ' seconds.';
      if (
        duration.days < 0 ||
        duration.hours < 0 ||
        duration.minutes < 0 ||
        duration.seconds < 0
      ) {
        alarmIsElapsed = true;
      }
    }

    if (alarm.acceptedAt) {
      alarmProperties.push({
        title: gettext('Accepted at '),
        value: alarm.dateTime,
        type: 'date'
      });
    }

    if (alarm.timeToResponse) {
      if (!alarmIsElapsed) {
        alarmProperties.push({
          title: gettext('Must be resolved by'),
          value: new Date(till),
          format: 'DD.MM.YYYY hh:mm:ss',
          comment: timeString,
          type: 'dateTo',
          color: 'grey',
          shade: 'hue-3'
        });
      } else {
        alarmProperties.push({
          type: 'dateTo',
          colorTime: 'primary',
          title: gettext('Must be resolved by'),
          value: new Date(till),
          format: 'DD.MM.YYYY hh:mm:ss'
        });
      }
    }

    let comment = [];
    if (alarm.resolutionComment) {
      comment.push({
        title: gettext('Comment'),
        value: alarm.resolutionComment,
        type: 'simple'
      });
    }

    let externalCode;
    if (alarm.externalCode) {
      externalCode = {
        title: gettext('External code'),
        properties: [
          {
            title: gettext('External Code'),
            value: alarm.externalCode,
            type: 'simple'
          }
        ]
      };
    }
    let propertySections = [
      {
        title: gettext('Alarm Data'),
        properties: alarmData
      },
      {
        title: gettext('People'),
        properties: alarmPersonel
      },
      {
        title: gettext('Status'),
        properties: alarmStatus
      }
    ];
    if (externalCode) {
      propertySections.push(externalCode);
    }

    if (alarmRelatedCompanyResources.length > 0) {
      propertySections.push({
        title: gettext('Related company resources'),
        properties: alarmRelatedCompanyResources
      });
    }

    if (alarm.alarmStatus === 1) {
      actions.push({
        title: gettext('Accept'),
        script: true,
        fn: acceptAlarm
      });
    }

    if (alarm.alarmStatus !== 3) {
      //NOT resolved
      actions.push(
        {
          title: gettext('Assign'),
          script: true,
          fn: () => openAssignDialog(alarm)
        },
        {
          title: gettext('Resolve'),
          script: true,
          fn: openResolveDialog
        }
      );
    }

    let metadata = {
      definition: '',
      title: '',
      description: ''
    };
    metadata.definition = gettext('alarm');
    if (alarm.alarm) {
      metadata.title = alarm.alarm.name || gettext('Unknown');
      metadata.description = alarm.alarm.description;
    }
    return {
      metadata: metadata,
      actions: actions,
      propertySections: propertySections
    };
  }

  async function acceptAlarm() {
    const obj = {
      alarmStatus: 2
    };
    try {
      const { data } = await TriggeredAlarmModel.update(
        {
          id: alarmId
        },
        obj
      );

      const { alarmStatus } = data;
      triggeredAlarm = { ...triggeredAlarm, alarmStatus };
      ToastService.showToast(gettext('Alarm was successfully accepted!'));
      init();
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function fetchActivityMetadata(activities) {
    if (activities) {
      let usersToFetch = [];
      activities.forEach(function(activity, index) {
        activity.action = TranslationService.GetCollectionById(
          'codelists.alarmStatuses',
          activity.alarmStatus
        );
        activity.actionName = activity.action
          ? activity.action.name
          : gettext('Unknown');
        if (index) {
          activity.previousState = activities[index - 1].actionName;
        }
        if (!activity.comment) {
          activity.comment = '/';
        }
        if (activity.currentAssignee) {
          usersToFetch.push(activity.currentAssignee);
        }
        if (activity.activityUser) {
          usersToFetch.push(activity.activityUser);
        }
      });
      usersToFetch = usersToFetch.filter(onlyUnique);
      if (usersToFetch) {
        UserModel.read({
          _id: usersToFetch
        }).then(
          function(res) {
            activities.forEach(function(activity) {
              activity.assignee = res.data.find(function(item) {
                return item._id === activity.currentAssignee;
              });
              activity.currentAssigneeName = activity.assignee
                ? (activity.assignee.name || '') +
                  ' ' +
                  (activity.assignee.family_name || '')
                : gettext('None');

              activity.user = res.data.find(function(item) {
                return item._id === activity.activityUser;
              });
              activity.activityUserName = activity.user
                ? (activity.user.name || '') +
                  ' ' +
                  (activity.user.family_name || '')
                : gettext('None');
            });
            vm.alarmActivity = activities;
          },
          function(err) {
            AlertingService.Error(err);
            vm.alarmActivity = activities;
          }
        );
      } else {
        vm.alarmActivity = activities;
      }
    }
  }

  function constructActivityTable() {
    vm.headers = [
      {
        name: gettext('Action'),
        sort: 'actionName',
        text: true
      },
      {
        name: gettext('Date'),
        sort: 'activityDateTime',
        date: true,
        format: 'dd. MM. yyyy, HH:mm'
      },
      {
        name: gettext('New assignee'),
        sort: 'currentAssigneeName',
        text: true
      },
      {
        name: gettext('Previous state'),
        sort: 'previousState',
        text: true
      },
      {
        name: gettext('User'),
        sort: 'activityUserName',
        text: true
      }
    ];

    vm.tableConfig = {
      toolbar: {
        title: gettext('Activity'),
        filter: false,
        create: false,
        edit: false,
        delete: false
      },
      empty: gettext('There were no activities yet.'),
      page: 1,
      limit: 5,
      order: '-activityDateTime',
      state: $state.current.name,
      listId: 'activityList'
    };
  }

  function openResolveDialog() {
    const config = [
      {
        title: gettext('Solution'),
        componentType: 'title'
      },
      {
        placeholder: gettext('Enter your comment here.'),
        name: 'solution',
        componentType: 'textArea',
        maxlength: 500,
        type: 'text',
        required: true
      }
    ];

    SfeFormDialogService.openSfeFormDialog(
      false,
      config,
      {},
      gettext('Resolve Alarm')
    ).then(async function(result) {
      if (result) {
        try {
          const obj = {
            resolutionComment: result.solution,
            alarmStatus: 3
          };
          const { data } = await TriggeredAlarmModel.update(
            {
              id: alarmId
            },
            obj
          );
          const {
            resolutionComment,
            alarmStatus,
            triggeredAlarmActivity
          } = data;
          triggeredAlarm = {
            ...triggeredAlarm,
            resolutionComment,
            alarmStatus,
            triggeredAlarmActivity
          };

          ToastService.showToast(gettext('Alarm was successfully resolved!'));
          init();
        } catch (err) {
          AlertingService.Error(err);
        }
      }
    });
  }
  /**
   * @description opens dialog that allows to choose new alarm assignee user.
   * @function
   * @param {Object} alarm alarm that we want to assign
   */
  async function openAssignDialog(alarm) {
    const config = [
      {
        title: gettext('Assignee'),
        componentType: 'title'
      },
      {
        name: 'user',
        edit: true,
        componentType: 'autocompleteDialog',
        configuration: {
          query: {
            entity: 'users',
            method: 'read'
          },
          floatingLabel: gettext('Select Assignee'),
          searchParamName: 'full_name',
          entity: 'users',
          createRedirect: {
            state: 'company-resources-assets-new'
          },
          required: true
        }
      }
    ];

    const formObject = {
      user: alarm.currentAssigneeObject ? alarm.currentAssigneeObject._id : null
    };

    const customButton = {
      displayDefault: false,
      buttons: [
        {
          title: gettext('Assign'),
          callDialogSaveFunction: true
        }
      ]
    };
    try {
      const result = await SfeFormDialogService.openSfeFormDialog(
        false,
        config,
        formObject,
        gettext('Assign Alarm'),
        customButton
      );
      if (result != null) {
        const updateObject = {
          comment: result.comment,
          currentAssignee: result.user._id
        };
        const { data } = await TriggeredAlarmModel.update(
          {
            id: alarmId
          },
          updateObject
        );
        const { alarmStatus, comment, triggeredAlarmActivity } = data;
        triggeredAlarm = {
          ...triggeredAlarm,
          alarmStatus,
          comment,
          currentAssigneeObject: result.user,
          triggeredAlarmActivity
        };
        ToastService.showToast(gettext('Alarm was successfully assigned!'));
        init();
      }
    } catch (err) {
      AlertingService.err(err);
    }
  }
}

export default TriggeredAlarmController;
