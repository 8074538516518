import './sfe-form.scss';
import template from './sfe-form.component.html';

/**
 * @ngdoc component
 * @name common.sfeForm
 * @description Component for display and handling of the form.
 * @param {Object} config - paramDescription
 * @param {Object} parentForm - paramDescription
 * @param {boolean} sendingRequest - Indicates if request is being processed
 * @param {Object} formObject - Object to track validity of another form on the same page
 * @example
 *  <sfe-form
 *   parent-form="Form"
 *   sending-request="vm.sendingRequest"
 *   config="vm.dataConfig"
 * ></sfe-form>
 */

export default {
  restrict: 'E',
  template,
  bindings: {
    config: '=',
    parentForm: '=',
    sendingRequest: '=?',
    formObject: '='
  },
  controller: SfeFormController,
  controllerAs: 'vm',
  bindToController: true
};

SfeFormController.$inject = [
  '$state',
  'AlertingService',
  'CrudToastFactory',
  'DateAutocomplete',
  'MultiLanguage',
  'CrawlerMethods',
  '$scope',
  '$document',
  'Formatting'
];
function SfeFormController(
  $state,
  AlertingService,
  CrudToastFactory,
  DateAutocomplete,
  MultiLanguage,
  CrawlerMethods,
  $scope,
  $document,
  Formatting
) {
  var vm = this;

  vm.createOrUpdate = [];
  /**
   * @description Sets up cancel and create or update buttons.
   * @function
   */
  vm.$onInit = function() {
    if (vm.config) {
      if (vm.config.cancel) {
        vm.createOrUpdate.push({
          title: vm.config.cancel.title,
          fn: vm.config.cancel.fn
        });
      }
      if (vm.config.action) {
        vm.createOrUpdate.push({
          title: vm.config.action
            ? vm.config.action.title
            : vm.createOrUpdate
              ? vm.createOrUpdate.title
              : null,
          fn: action,
          color: vm.config.action.color || 'primary',
          raised: true,
          disabledFn: function() {
            return (
              ($scope.ItemForm && !$scope.ItemForm.$valid) ||
              invalidFn() ||
              checkSendingRequest()
            );
          }
        });
      }
    }
  };

  $scope.$on('$destroy', function() {
    if (dataObjWatcher) {
      dataObjWatcher();
    }
    if (itemFormWatcher) {
      itemFormWatcher();
    }
  });

  var dataObjWatcher = $scope.$watch('vm.config.dataObj', function(newVal) {
    if (typeof newVal !== 'undefined') {
      // $scope.ItemForm.$setDirty()
      if (vm.config) {
        _.each(vm.config.data, function(element) {
          if (typeof element.required === 'undefined') {
            element.required = true;
          }
          if (element.twoInRow) {
            element.subData[0].required =
              typeof element.subData[0].required === 'undefined'
                ? true
                : element.subData[0].required;
            element.subData[1].required =
              typeof element.subData[1].required === 'undefined'
                ? true
                : element.subData[1].required;
          }
        });
      }
      if (vm.parentForm) {
        $scope.ItemForm = vm.parentForm;
      }
    }
  });

  var itemFormWatcher = $scope.$watch('$scope.ItemForm', function() {
    if (vm.formObject && $scope.ItemForm) {
      vm.formObject = $scope.ItemForm;
    }
    itemFormWatcher();
  });

  /**
   * @description sets new date and time to single date component.
   * @function
   * @param {Object} config single date form  configuration object
   * @param {number} index index in form needed to set component validity
   * @param {bool} next indicates witch button is clicked
   */
  vm.setSingleDate = function(config, index, next) {
    var getDate = config.getPreviousDate;
    if (next) {
      getDate = config.getNextDate;
    }

    if (getDate) {
      var newDate = getDate(
        vm.config.dataObj[config.dateName],
        vm.config.dataObj[config.timeName]
      );

      if (newDate) {
        vm.config.dataObj[config.dateName] = newDate;
        vm.config.dataObj[config.timeName] = Formatting.getHoursAndMinutes(
          newDate,
          true
        );
        vm.singleDateChanged(config, index);
      }
    }
  };

  function invalidFn() {
    if (typeof vm.config.action.invalidFn === 'undefined') {
      return false;
    } else {
      return vm.config.action.invalidFn();
    }
  }

  function checkSendingRequest() {
    return vm.sendingRequest;
  }

  /**
   * @description Sets the validity of the date object after it's been changed.
   * @function
   * @param {Object} config Contains info on custom validation, if it exists
   * @param {number} index Form index of the item
   */
  vm.singleDateChanged = function(config, index) {
    if (Array.isArray(config.customValidation)) {
      let valid;
      config.customValidation.forEach(customValidation => {
        valid = customValidation.script(
          vm.config.dataObj[config.dateName],
          vm.config.dataObj[config.timeName]
        );
        $scope.ItemForm['item_' + index + 'date'].$setValidity(
          customValidation.name,
          valid
        );
        if ($scope.ItemForm['item_' + index]) {
          $scope.ItemForm['item_' + index].$setValidity(
            customValidation.name,
            valid
          );
        }
      });
    }

    if (config.change) {
      config.change();
    }
  };

  /**
   * @description in case of To date is earlier then From date set both date inputs validity to false and vice versa.
   * @function
   * @param {Object} config component configuration
   */
  function validateFromTo(config) {
    var index = vm.config.data.findIndex(function(item) {
      return item.name === config.name;
    });
    if (index > -1 && config.name) {
      if (vm.config.dataObj[config.to] >= vm.config.dataObj[config.from]) {
        $scope.ItemForm['item_' + index + 'to'].$setValidity('mindate', true);
        $scope.ItemForm['item_' + index + 'from'].$setValidity('maxdate', true);
      }
    }
  }

  /**
   * @description Sets the validity of the password item after it's been changed.
   * @function
   * @param {Object} config Contains info on custom validation, if it exists
   * @param {number} name Name of the password item in the ItemForm
   */
  vm.passwordChanged = function(config, name) {
    if (config.validation && config.validation.equalTo) {
      if (
        vm.config.dataObj[config.validation.equalTo] &&
        vm.config.dataObj[config.name] &&
        vm.config.dataObj[config.validation.equalTo] !==
          vm.config.dataObj[config.name]
      ) {
        $scope.ItemForm[name].$setValidity('passwordMatch', false);
      } else {
        var configIndex;
        vm.config.data.forEach(function(componentConfig, index) {
          if (componentConfig.name === config.validation.equalTo) {
            configIndex = index;
          }
        });
        if (typeof configIndex !== 'undefined') {
          $scope.ItemForm['item_' + configIndex].$setValidity(
            'passwordMatch',
            true
          );
        }
        $scope.ItemForm[name].$setValidity('passwordMatch', true);
      }
    }
  };

  /**
   * @description Checks if setMinDate precedes dateFrom
   * @function
   * @param {string} dateFrom Picked date
   * @param {string} setMinDate Default min date
   * @return {string} Returns the most recent date
   */
  vm.minDate = function(dateFrom, setMinDate, config) {
    if (vm.config.dataObj) {
      config.earliestDate = vm.config.dataObj[config.from];
      if (dateFrom > setMinDate || setMinDate === undefined) {
        return dateFrom;
      }
    }
    return setMinDate;
  };

  /**
   * @description Checks if setMinDate precedes dateFrom
   * @function
   * @param {string} dateFrom Picked date
   * @param {string} setMinDate Default min date
   * @return {string} Returns the most recent date
   */
  vm.maxDate = function(dateTo, setMaxDate, config) {
    if (vm.config.dataObj) {
      config.latestDate = vm.config.dataObj[config.to];
      if (dateTo > setMaxDate || setMaxDate === undefined) {
        return dateTo;
      }
    }
    return setMaxDate;
  };

  /**
   * @description Validates inputs of datepicker
   * @function
   * @param {Object} config Contains info on boundaries of the date
   * @param {boolean} fromChanged Indicates if 'from' date changed
   */
  vm.datePickerFromToDateChanged = function(config, fromChanged) {
    config.earliestDate = vm.config.dataObj[config.from];
    config.latestDate = vm.config.dataObj[config.to];
    validateFromTo(config);
    if (!fromChanged) {
      if (config.onChangeTo) {
        config.onChangeTo(vm.config.dataObj);
      }
    } else {
      if (config.onChangeFrom) {
        config.onChangeFrom(vm.config.dataObj);
      }
    }
  };

  /**
   * @description Shows and hides the password.
   * @function
   * @param {number} index Index of the password item
   */
  vm.showPassword = function(index) {
    if (typeof vm.config.data[index].showPwd === 'undefined') {
      vm.config.data[index].showPwd = true;
    } else {
      vm.config.data[index].showPwd = !vm.config.data[index].showPwd;
    }
  };

  /**
   * @description Shows a previously hidden element of the item form.
   * @function
   * @param {number} index Index of the item to be shown
   */
  vm.showFn = function(index) {
    if (
      typeof vm.config.data === 'undefined' ||
      typeof vm.config.data[index].showFn === 'undefined'
    ) {
      return true;
    } else {
      return vm.config.data[index].showFn();
    }
  };

  /**
   * @description Detects a change of the element of the item form.
   * @function
   * @param {number} index Index of the item of which the change should be detected
   * @param {Object} value New value
   */
  vm.changeDetect = function(index, value) {
    if (
      typeof vm.config.data !== 'undefined' &&
      typeof vm.config.data[index].changeFn !== 'undefined'
    ) {
      vm.config.data[index].changeFn(value, vm.config.dataObj);
    }
  };

  /**
   * @description Action that is triggered upon clicking the create/update button
   * @function
   */
  function action() {
    var action = vm.config.action;
    vm.sendingRequest = true;
    if (action.resource) {
      var obj = MultiLanguage.constructForm(
        vm.config.data,
        angular.copy(vm.config.dataObj)
      );
      var missingLanguages = [];
      var filledLang;

      _.each(vm.config.data, function(el) {
        if (el.multilanguage) {
          missingLanguages = [];
          _.each(obj[el.name], function(item, key) {
            if (!item) {
              missingLanguages.push(key);
            } else {
              filledLang = key;
            }
          });
          if (missingLanguages.length) {
            _.each(missingLanguages, function(key) {
              obj[el.name][key] = obj[el.name][filledLang];
            });
          }
        } else {
          if (el.objToId) {
            obj[el.name] = obj[el.name]._id || obj[el.name].id;
          } else if (el.idToObj) {
            obj[el.name] = {
              _id: obj[el.name]
            };
          }
        }
      });

      var method;

      if (action.configuration) {
        method = CrawlerMethods.getMethod(action.configuration);
      }

      if (action.method === 'POST' || action.method === 'post') {
        if (!method) {
          method = action.fn.post(obj).$promise;
        } else {
          method = method(obj);
        }
        method.then(
          function(res) {
            var redirectObj = {
              state: vm.config.redirect.location,
              paramName: vm.config.redirect.param,
              paramValue: res.data._id
            };
            CrudToastFactory.toast(vm.config.action.successMsg, redirectObj);
            vm.sendingRequest = false;
          },
          function(err) {
            vm.sendingRequest = false;
            if (err) {
              AlertingService.Error(err);
            }
          }
        );
      } else if (action.method === 'PUT' || action.method === 'put') {
        var updateObject = {};
        updateObject[action.paramName] = vm.config.redirect.paramValue;

        if (!method) {
          method = action.fn.put(updateObject, obj).$promise;
        } else {
          method = method(updateObject, obj);
        }
        method.then(
          function(res) {
            var redirectObj = {
              state: vm.config.redirect.location,
              paramName: vm.config.redirect.param,
              paramValue: res.data._id
            };
            CrudToastFactory.toast(vm.config.action.successMsg, redirectObj);
            vm.sendingRequest = false;
          },
          function(err) {
            vm.sendingRequest = false;
            if (err) {
              AlertingService.Error(err);
            }
          }
        );
      }
    } else if (action.ctrlFn) {
      if ($state.current.parentState === 'company-resources') {
        if (vm.config.dataObj.validFrom) {
          var validFrom = vm.config.dataObj.validFrom;
          var timeFrom = vm.config.dataObj.timeFrom;
          if (timeFrom) {
            validFrom.setHours(timeFrom.split(':')[0], timeFrom.split(':')[1]);
          } else {
            validFrom.setHours('00', '00');
          }
        }
        if (vm.config.dataObj.validTo) {
          var validTo = vm.config.dataObj.validTo;
          var timeTo = vm.config.dataObj.timeTo;
          if (timeTo) {
            validTo.setHours(timeTo.split(':')[0], timeTo.split(':')[1]);
          } else {
            validTo.setHours('23', '59');
          }
        }
      }

      action.fn(vm.config.dataObj);
    } else {
      vm.sendingRequest = false;
    }
  }

  /**
   * @description Called on focusing/blurring selected date.
   * If date input has onBlur or onFocus function it's triggered from here.
   * @function
   * @param {string} datePickerType Type of the input (from/to)
   * @param {string} id date input id
   * @param {string} partialNgModelName Name of the part of the input (usually validTo/validFrom)
   * @param {boolean} ignoreEmptyField Signalling if empty field should be ignored
   * @param {string} event blur/focus
   * @param {index} index configuration index
   */
  vm.callAutoCompleteDate = function(
    datePickerType,
    id, //index
    partialNgModelName,
    ignoreEmptyField,
    event,
    index
  ) {
    var htmlClass;
    let blurFunction = 'onBlur';
    let focusFunction = 'onFocus';
    /*eslint no-fallthrough: "error"*/
    switch (datePickerType) {
    case 'none':
      htmlClass = '.datepicker' + id + vm.config.data[id].idDate;
      break;
    case 'from':
      blurFunction = 'onBlurFrom';
      focusFunction = 'onFocusFrom';
      // falls through
    case 'to':
      blurFunction = 'onBlurTo';
      focusFunction = 'onFocusTo';
      // falls through
    default:
      htmlClass = '.datepicker' + datePickerType + id;
    }
    //ng-model only passes valid dates and we also want the value when not valid
    var inputDateValue = $document[0]
      .querySelectorAll(htmlClass)[0]
      .querySelectorAll('input')[0].value;
    if (!ignoreEmptyField || inputDateValue) {
      vm.config.dataObj[partialNgModelName] = DateAutocomplete.get(
        inputDateValue
      );
    }
    switch (event) {
    case 'blur':
      if (vm.config.data[index][blurFunction]) {
        vm.config.data[index][blurFunction](
          vm.config.dataObj[partialNgModelName]
        );
      }
      break;
    case 'focus':
      if (vm.config.data[index][focusFunction]) {
        vm.config.data[index][focusFunction](
          vm.config.dataObj[partialNgModelName]
        );
      }
    }
  };
}
