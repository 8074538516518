module.exports = {
  entity: 'alarms',
  path: 'alarms',
  entityId: 20,
  name: {
    en: 'Alarm',
    sl_SI: 'Alarm'
  },
  networkModel: {
    entity: 'alarms',
    read: 'read'
  },
  scope: 'tis_be:alarms',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select alarm',
      sl_SI: 'Izberite alarm'
    },
    listInfo: {
      en: 'Select alarm from the list below',
      sl_SI: 'Izberite alarm s spodnjega seznama'
    }
  }
}
