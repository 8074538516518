AlarmTypeModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function AlarmTypeModel(ModelUtilities, CrawlerMethods) {
  const modelStorage = {};
  const networkConfiguration = {
    host: 'api',
    path: 'alarm-types'
  };

  const crawlerConfigurationSystemTags = {
    entity: 'alarm-types',
    method: 'read',
    steps: [
      {
        entity: 'system-tag-entities',
        valueParams: ['_id'],
        queryParams: ['entityId'],
        method: 'custom.readWithTag',
        methodType: 'add',
        newParamNames: ['tags']
      }
    ]
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readWithSystemTags: CrawlerMethods.crawler(crawlerConfigurationSystemTags)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default AlarmTypeModel;
