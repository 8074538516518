import { DateTime } from 'luxon';

/**
 * @ngdoc service
 * @name analytics.analysisQueryBuilder
 * @description analysis helper methods.
 * @property {Function} getQuery - returns analysis query string
 * @property {Function} readAnalysis - returns analysis query string
 * @property {Function} fetchNameTitle - returns analysis query string
 */
analysisQueryBuilder.$inject = [
  'AnalysesModel',
  '$q',
  'ExpressionModel',
  'AnalysisFilterConfigModel'
];

function analysisQueryBuilder(
  AnalysesModel,
  $q,
  ExpressionModel,
  AnalysisFilterConfigModel
) {
  /**
   * @memberof analytics.getQuery
   * @description constructs analysis fetch query.
   * @param {Object} analysis analysis object that have enriched all needed properties (dataset, aggregation group etc)
   * @param {Array} analysisFilters array of entity filters selected entites shoud be in array under entityItemObjects key
   * @param {Date} startDate start date filter
   * @param {Date} endDate end date filter
   * @param {String} activeComparableColumn name of comparable column
   * @return {dataType}
   */
  function getQuery({
    analysis,
    analysisFilters,
    startDate,
    endDate,
    activeComparableColumn,
    startDateMonth,
    endDateMonth
  }) {
    var queryObject = {};
    var seriesNames = [];

    // SET AGGREGATION GROUP
    if (
      analysis.group &&
      analysis.group.condition &&
      analysis.group.condition.length
    ) {
      queryObject.groupBy = [].concat(analysis.group.condition);
    }

    // SET ENTITY FILTERS
    if (analysisFilters && analysisFilters.length) {
      var valueParam;
      if (analysisFilters) {
        analysisFilters.forEach(function(filter) {
          valueParam = '_id';
          if (
            filter.entity == 224 /*'billingTypeBillingKinds'*/ ||
            filter.entity == 249 /* schedule classification */ ||
            filter.entity == 251 /* alarm statuses */ ||
            filter.entity == 252 /* event types */
          ) {
            valueParam = 'id';
          } else if (filter.entity === 140) {
            //master invoice
            valueParam = 'masterInvoice';
          }

          switch (filter.type) {
          case 2: //temp integer input
            queryObject[filter.rawFieldName] = filter.filterValues[0];
            break;
          default:
            if (filter.entityItemObjects && filter.entityItemObjects.length) {
              queryObject[filter.rawFieldName] = filter.entityItemObjects.map(
                function(item) {
                  if (typeof item === 'object') {
                    return item[valueParam];
                  }
                  return item;
                }
              );
            }
          }
        });
      }
    }
    // SET DATE FILTERS
    if (activeComparableColumn === 'validityDateCompare') {
      if (
        startDateMonth !== 'Invalid Date' &&
        endDateMonth !== 'Invalid Date' &&
        startDateMonth &&
        endDateMonth
      ) {
        const from = DateTime.fromJSDate(new Date(startDateMonth));
        const to = DateTime.fromJSDate(new Date(endDateMonth));
        queryObject.validityIntervalFromYear = from.year;
        queryObject.validityIntervalFromMonth = from.month;

        queryObject.validityIntervalToYear = to.year;
        queryObject.validityIntervalToMonth = to.month;
        if (activeComparableColumn) {
          queryObject['compareColumn'] = activeComparableColumn;
        }
      }
    } else {
      if (startDate !== 'Invalid Date' && endDate !== 'Invalid Date') {
        if (startDate && endDate && activeComparableColumn) {
          queryObject[activeComparableColumn] =
            '{gte}' + startDate.toISOString() + '{lte}' + endDate.toISOString();
        } else if (startDate && activeComparableColumn) {
          queryObject[activeComparableColumn] =
            '{gte}' + startDate.toISOString();
        } else if (endDate && activeComparableColumn) {
          queryObject[activeComparableColumn] = '{lte}' + endDate.toISOString();
        }
        if (activeComparableColumn) {
          queryObject['compareColumn'] = activeComparableColumn;
        }
      }
    }

    // SET EXPRESSION
    if (analysis.calculation) {
      queryObject = analysis.calculation.series.reduce(function(
        accumulator,
        item
      ) {
        if (item.type === 'Accumulator') {
          if (!accumulator.accum) {
            accumulator.accum = [];
          }
          accumulator.accum.push(item.name);
          accumulator[item.name] =
            '{' + item.value.aggregationType + '}' + item.value.value;
          if (seriesNames.length < 1) {
            seriesNames.push(item.name);
          }
        } else {
          if (!accumulator.arith) {
            accumulator.arith = [];
          }
          accumulator.arith.push(item.name);
          accumulator[item.name] = item.value;
        }
        return accumulator;
      },
      queryObject);
    }

    // SET ORDER
    queryObject.order = '';
    if (analysis.sort) {
      queryObject.order = analysis.sort.reduce(function(
        accumulator,
        sort,
        index
      ) {
        if (sort.type === 'DESC') {
          accumulator += '-';
        }
        accumulator += '_id.' + sort.name;

        if (index < analysis.sort.length - 1) {
          accumulator += ',';
        }
        return accumulator;
      },
      queryObject.order);
    }
    return {
      apiObject: queryObject,
      seriesNames: seriesNames
    };
  }

  /**
   * @description fetches analysis and all connected items.
   * @function
   * @param {string} analysisId analysis id
   * @param {string} cacheInvalidationTime cache invalidation time
   * @return {Promise}
   */
  function readAnalysis(analysisId, cacheInvalidationTime) {
    var deferred = $q.defer();
    var waterfall = [
      async.apply(enrichAnalysis, analysisId, cacheInvalidationTime),
      readExpressions,
      getFilterConfigs
    ];
    async.waterfall(waterfall, function(err, analysis) {
      if (err) {
        deferred.reject(err);
      } else {
        deferred.resolve(analysis);
      }
    });
    return deferred.promise;
  }

  /**
   * @description fetches analysis.
   * @function
   * @param {string} analysisId analysis id
   * @param {string} cacheInvalidationTime cache invalidation time
   * @param {function} callback callback function
   */
  function enrichAnalysis(analysisId, cacheInvalidationTime, callback) {
    AnalysesModel.custom
      .readAll(
        {
          id: analysisId
        },
        cacheInvalidationTime
      )
      .then(
        function(res) {
          callback(null, res.data, cacheInvalidationTime);
        },
        function(err) {
          callback(err);
        }
      );
  }
  /**
   * @description fetches expression and resolves fetched object or undefined.
   * @function
   * @param {string} id expression id
   * @param {string} cacheInvalidationTime cache invalidation time
   * @returns {Promise}
   */
  function readExpressionsWrapper(id, cacheInvalidationTime) {
    var deferred = $q.defer();
    ExpressionModel.read(
      {
        id: id
      },
      cacheInvalidationTime
    ).then(
      function(res) {
        deferred.resolve(res.data);
      },
      function() {
        deferred.resolve();
      }
    );
    return deferred.promise;
  }
  /**
   * @description mocks promise that resolves to undefined.
   * @function
   * @return {Promise}
   */
  function blank() {
    return $q.resolve();
  }
  /**
   * @description fetches expression
   * @function
   * @param {object} analysis analysis object
   * @param {string} cacheInvalidationTime cache invalidation time
   * @param {function} callback callback fn
   */
  function readExpressions(analysis, cacheInvalidationTime, callback) {
    if (
      analysis &&
      analysis.calculationObject &&
      analysis.calculationObject.series
    ) {
      var promises = analysis.calculationObject.series.map(function(series) {
        if (series.type === 'Accumulator') {
          return readExpressionsWrapper(series.value, cacheInvalidationTime);
        } else {
          return blank();
        }
      });
      $q.all(promises).then(function(results) {
        results.forEach(function(result, index) {
          if (result) {
            analysis.calculationObject.series[index].value = result;
          }
        });
        callback(null, analysis, cacheInvalidationTime);
      });
    } else {
      callback(null, analysis, cacheInvalidationTime);
    }
  }
  /**
   * @description fetches filter configurations.
   * @function
   * @param {object} analysis analysis object
   * @param {number} cacheInvalidationTime cache invalidation time
   * @param {function} callback callback fn
   */
  function getFilterConfigs(analysis, cacheInvalidationTime, callback) {
    AnalysisFilterConfigModel.read(
      {
        analysis: analysis._id
      },
      cacheInvalidationTime
    ).then(
      function(res) {
        analysis.filterConfigs = res.data;
        callback(null, analysis);
      },
      function() {
        callback(null, analysis);
      }
    );
  }

  return {
    getQuery: getQuery,
    readAnalysis: readAnalysis
  };
}

export default analysisQueryBuilder;
