import template from './sfe-checkboxes-linear.component.html';
import './sfe-checkboxes-linear.component.scss';
export default {
  restrict: 'E',
  template,
  bindings: {
    model: '=',
    networkModel: '<',
    compareFn: '<'
  },
  controller: sfeCheckboxesLinearController,
  controllerAs: 'vm'
};
sfeCheckboxesLinearController.$inject = [
  'AlertingService',
  'CrawlerMethods',
  '$scope'
];

function sfeCheckboxesLinearController(
  AlertingService,
  CrawlerMethods,
  $scope
) {
  const vm = this;
  vm.items = [];
  vm.itemExists = itemExists;
  vm.toggle = toggle;
  vm.$onInit = init;

  function init() {
    getItems();
  }

  async function getItems() {
    if (typeof vm.networkModel === 'object') {
      const method = CrawlerMethods.getMethod(vm.networkModel);
      try {
        const { data: items } = await method();
        vm.items = items;
        $scope.$evalAsync();
      } catch (err) {
        AlertingService.Error(err);
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('There is no query object defined to fetch items');
    }
  }

  function itemIndex(item, selected) {
    for (let i = 0; i < selected.length; i++) {
      if (vm.compareFn(item, selected[i])) {
        return i;
      }
    }
    return -1;
  }

  function itemExists(item, selected) {
    return selected ? itemIndex(item, selected) > -1 : false;
  }

  function toggle(item, selected) {
    const idx = itemIndex(item, selected);
    if (idx > -1) {
      selected.splice(idx, 1);
    } else {
      selected.push(item);
    }
  }
}
