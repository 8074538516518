DetailInvoiceModelBillingDataByEmg.$inject = ['ModelUtilities'];

function DetailInvoiceModelBillingDataByEmg(ModelUtilities) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'detail-invoices/:detailInvoiceId/billing-data-by-emg'
  };

  var methods = {
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };
  return methods;
}

export default DetailInvoiceModelBillingDataByEmg;
