module.exports = {
  entity: 'reports',
  path: 'reports',
  name: {
    en: 'Report',
    sl_SI: 'Poročilo'
  },
  networkModel: {
    entity: 'reports',
    read: 'read'
  },
  scope: 'tis_be:reports',
  dialog: {
    title: {
      en: 'Select report',
      sl_SI: 'Izberite poročilo'
    },
    listInfo: {
      en: 'Select report from the list below',
      sl_SI: 'Izberite poročilo s spodnjega seznama'
    }
  }
}
