import templateFullscreen from './sfe-list-fullscreen.component.html';
import template2 from './sfe-list-2.component.html';
import templateFilters from './sfe-list-2-filters.dialog.html';
import './sfe-list-fullscreen.scss';
import './sfe-list.scss';

/**
 * @ngdoc component
 * @name common.sfeList2
 * @description Forms a list ob items
 * @param {String} entity - Which entity's configuration should be used.
 * @param {String} display - Used to determing which template to use - fullscreen or 2.
 * @param {Object} filterObject - Used for filtering the data.
 * @param {Object} listConfig - Configuration of the list to be displayed.
 * @param {string} defaultDisplayMode - Default display mode.
 * @param {function} listData - Function for listing the data.
 * @param {function} relistData - Function for re-listing the data.
 * @param {boolean} dialogFilter - Determines wheter filters of the list can be opened in a dialog with a button.
 * @param {boolean} noFilters - Whether no filters should be displayed.
 * @param {boolean} loadingStatus - Determines the data loading status.
 * @param {array} data - Displayed items
 * @param {Array} filterValuesOverride - array of filters that overrides stored filter values
 * @example
 * <sfe-list-2
 *   entity="vm.entity"
 *   display="fullscreen"
 *   default-display-mode="table"
 *   list-data="vm.listData(data)"
 *   relist-data="vm.relistData"
 *   no-filters="false"
 *   list-config="vm.alarmConfig"
 *   filter-object="vm.filters"
 *   dialog-filter="true"
 *   data="[]"
 * ></sfe-list-2>
 */

sfeList2TemplateCtrl.$inject = ['$attrs'];
/**
 * @description Defines which template to use.
 * @function
 * @return {string} Path to correct template.
 */
function sfeList2TemplateCtrl($attrs) {
  if ($attrs.display == 'fullscreen') {
    return templateFullscreen;
  } else {
    return template2;
  }
}

export default {
  template: sfeList2TemplateCtrl,
  bindings: {
    entity: '@',
    display: '@',
    filterObject: '=?',
    listConfig: '<?',
    defaultDisplayMode: '@',
    listData: '&?',
    relistData: '=?',
    dialogFilter: '@?',
    noFilters: '@?',
    loadingStatus: '=?',
    data: '=?',
    filterValuesOverride: '<',
    noTitle: '<?'
  },
  controller: SfeListController,
  controllerAs: 'vm'
};

SfeListController.$inject = [
  '$scope',
  'SfeListConfigurations',
  'StorageService',
  '$mdDialog',
  'ListItemsService',
  '$q',
  'EntitiesService',
  'SfeFilterService',
  'gettext'
];

function SfeListController(
  $scope,
  SfeListConfigurations,
  StorageService,
  $mdDialog,
  ListItemsService,
  $q,
  EntitiesService,
  SfeFilterService,
  gettext
) {
  const vm = this;
  let stopWatchForFilterObject;
  let stopWatchData;
  let filterUpdatedWatcher = $scope.$on('filterUpdated', function(event) {
    event.stopPropagation();
    $mdDialog.hide();
  });
  let cardFilterObject = {
    page: 1
  };
  let cardPath;
  let gridConfiguration;
  let FilterDialogController = [
    '$scope',
    function($scope) {
      $scope.vm = vm;
      $scope.filtersHeader = {
        title: gettext('Select filters'),
        isDialog: true,
        actions: [
          {
            icon: {
              name: 'close',
              type: 2
            },
            fn: function() {
              $mdDialog.cancel();
            }
          }
        ]
      };
    }
  ];
  vm.collapsable = {};

  let cardRelistWatcher;
  vm.fetchItems = fetchItems;

  window.addEventListener('resize', resizeEventListener);

  $scope.$on('$destroy', function() {
    if (stopWatchForFilterObject) {
      stopWatchForFilterObject();
    }
    if (stopWatchData) {
      stopWatchData();
    }
    if (filterUpdatedWatcher) {
      filterUpdatedWatcher();
    }
    if (cardRelistWatcher) {
      cardRelistWatcher();
    }

    window.removeEventListener('resize', resizeEventListener);
  });

  function resizeEventListener() {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    setScreenDependingProperties(vw);
  }

  function setScreenDependingProperties(width) {
    const displayMode = StorageService.getListDisplayMode(vm.listId);

    if (width > 720) {
      if (vm.defaultDisplayMode) {
        vm.displayMode = vm.defaultDisplayMode;
      } else {
        if (typeof displayMode !== 'undefined') {
          vm.displayMode = displayMode;
        } else {
          vm.displayMode = 'table';
        }
      }

      let modeButtons = {
        displayMode: vm.displayMode,
        changeViewMode: changeViewMode
      };
      //HIDE DISPLAY MODE BUTTONS
      if (
        vm.entityConfiguration.modeConfiguration &&
        vm.entityConfiguration.modeConfiguration.hideModeButtons
      ) {
        modeButtons = {
          hideModeButtons: true
        };
        vm.displayMode = vm.entityConfiguration.modeConfiguration.displayMode;
      }

      vm.sfeDisplayTypeButtonsConfig = {
        ...modeButtons,
        configId: vm.listId,
        treeView: !!vm.entityConfiguration.treeView,
        cardView: !!vm.entityConfiguration.cardView
      };
    } else {
      if (vm.displayMode == null) {
        vm.displayMode =
          displayMode != null && displayMode != 'table' ? displayMode : 'list';
      } else {
        vm.displayMode = vm.displayMode != 'table' ? vm.displayMode : 'list';
      }

      vm.sfeDisplayTypeButtonsConfig = {
        hideModeButtons: true,
        configId: vm.listId,
        changeViewMode: changeViewMode,
        displayMode: vm.displayMode,
        treeView: !!vm.entityConfiguration.treeView,
        cardView: !!vm.entityConfiguration.cardView
      };
    }
  }
  /**
   * @description Initializes the list.
   * @function
   */
  vm.$onInit = async () => {
    vm.entityConfiguration =
      angular.copy(SfeListConfigurations.configuration[vm.entity]) || {};
    if (vm.listConfig) {
      Object.assign(vm.entityConfiguration, vm.listConfig);
    }
    vm.entity = vm.entity || vm.entityConfiguration.listId;
    vm.actions = createActionsArray(
      vm.entityConfiguration,
      vm.dialogFilter,
      vm.entity
    );

    !!vm.relistData;
    vm.GenerateUISrefLink = GenerateUISrefLink;
    vm.relistDataFn = RemoveObjectById;
    vm.openFilters = openFilters;
    vm.closeOpenProperties = closeOpenProperties;
    vm.DataManipulation = {
      RemoveObjectById: RemoveObjectById
    };
    vm.listId = vm.entityConfiguration.listId || 'default';
    vm.onSelect = onSelect;
    if (vm.entityConfiguration && vm.entityConfiguration.cardView) {
      await initCards();
    }
    vm.prefetchAction =
      vm.entityConfiguration && vm.entityConfiguration.api
        ? vm.entityConfiguration.api.prefetchAction
        : null;

    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );

    setScreenDependingProperties(vw);

    //LIMIT OPTIONS
    if (
      Array.isArray(vm.entityConfiguration.limitOptions) &&
      vm.entityConfiguration.limitOptions.length > 0
    ) {
      vm.limitOptions = vm.entityConfiguration.limitOptions;
    }

    if (vm.entityConfiguration.shortActions) {
      vm.entityConfiguration.shortActions = configureShortActions(
        vm.entityConfiguration.shortActions
      );
    }

    if (vm.displayMode === 'card') {
      fetchItems();
      watchRelistDataCardView();
    }

    var filterStatus = StorageService.getFilterDisplayStatus(vm.listId);
    if (typeof filterStatus !== 'undefined') {
      vm.collapsable.show = filterStatus;
    } else {
      vm.collapsable.show = true;
    }

    if (vm.listData) {
      dataWatch();
    }
    vm.filterObject = vm.filterObject || {};
    if (vm.entityConfiguration.api) {
      if (vm.entityConfiguration.api.filterObject) {
        mergeFilterObjects();
      }

      if (vm.entityConfiguration.api.watchFilterObject) {
        stopWatchForFilterObject = $scope.$watch(
          'vm.entityConfiguration.api.filterObject.isActive',
          function() {
            mergeFilterObjects();
            RemoveObjectById();
          }
        );
      }
    }
  };

  function configureShortActions(shortActions) {
    shortActions.forEach(function(action) {
      if (action.iconType === 'fa') {
        action.icon = 'fa-' + action.icon;
      }
    });
    return shortActions;
  }

  /*CARD LOGIC*/
  /**
   * @description Initializes the cards.
   * @function
   */
  async function initCards() {
    var networkModel = EntitiesService.getNetworkModelByEntity(vm.entity);
    cardPath = {
      entity: networkModel.entity,
      method: networkModel.read
    };
    if (vm.entityConfiguration.api.cardFilterObject) {
      cardFilterObject = {
        ...cardFilterObject,
        ...vm.entityConfiguration.api.cardFilterObject
      };
    }
    gridConfiguration = EntitiesService.getFilterGrid(vm.entity);
    await getStoredFilters();
  }

  /**
   * @description Returns the stored filters for current entity list.
   * @function
   */
  async function getStoredFilters() {
    const filters = Array.isArray(vm.filterValuesOverride)
      ? vm.filterValuesOverride
      : StorageService.getFilters(vm.sfeListId);
    await SfeFilterService.assignSelectedFiltersValues(
      vm.entityConfiguration.filter,
      filters
    );
    $scope.$evalAsync();
  }

  /**
   * @description Stores the metadata of the selected list.
   * @function
   */
  function storeListMetadata() {
    StorageService.saveListData(vm.listId, vm.entityConfiguration.filter);
  }

  /**
   * @description Fetches items to be displayed in the list.
   * @function
   * @return {Object} Returns a promise.
   */
  function fetchItems() {
    var deferred = $q.defer();
    vm.loadingStatus = true;
    cardFilterObject.limit = 6;
    ListItemsService.fetchItems(
      cardPath,
      vm.entityConfiguration.filter,
      cardFilterObject
    ).then(function(res) {
      storeListMetadata();
      vm.loadingStatus = false;
      vm.loadMoreItems =
        res.pagination.page * res.pagination['per_page'] < res.pagination.total;
      cardFilterObject.page = res.pagination.page + 1;
      if (!vm.elements) {
        vm.elements = mapCards(res.data, 0);
        deferred.resolve();
      } else {
        var index = vm.elements.length;
        var temp = mapCards(res.data, index);
        vm.elements = vm.elements.concat(temp);
        deferred.resolve();
      }
      vm.moreLeft =
        res.pagination.total - res.pagination.page * res.pagination['per_page'];
      vm.relistData = false;
    });
    return deferred.promise;
  }

  /**
   * @description Returns an array of elements to be displayed as cards.
   * @function
   * @param {Array} elements Array of elements to be displayed as cards.
   * @param {number} startIndex Current page of items to be displayed as cards.
   * @return {Array} Array of elements to be displayed as cards.
   */
  function mapCards(elements, startIndex) {
    if (elements) {
      return elements.map(function(item, index) {
        var obj = {
          order: startIndex + index,
          _id: startIndex + index,
          initComplete: true
        };
        switch (gridConfiguration.dashboardCardType) {
        case 2:
          obj.chartType = item.chartType;
          obj.id = item._id;
          break;
        default:
          obj.config = item;
        }
        if (gridConfiguration.entities) {
          obj.entities = [];
          var entityObject;
          gridConfiguration.entities.forEach(function(entity) {
            entityObject = {};
            for (var key in entity) {
              entityObject[key] = item[entity[key]];
            }
            obj.entities.push(entityObject);
          });
        }
        for (var key in gridConfiguration) {
          if (key !== 'entities') {
            obj[key] = gridConfiguration[key];
          }
        }

        if (vm.entityConfiguration && vm.entityConfiguration.title) {
          obj.titleObject = {
            title: item[vm.entityConfiguration.title.param]
          };
          if (vm.entityConfiguration.title.link) {
            var resultParams = {};
            var resultParamsString = '';
            obj.titleObject.state = vm.entityConfiguration.title.link.state;
            if (
              vm.entityConfiguration.title.link.params &&
              vm.entityConfiguration.title.link.params.length > 0
            ) {
              vm.entityConfiguration.title.link.params.forEach(function(param) {
                resultParams[param.queryParam] =
                  param.value || item[param.valueParam];
              });
              resultParamsString = '(' + JSON.stringify(resultParams) + ')';
              obj.titleObject.state += resultParamsString;
            }
          }
        }
        return obj;
      });
    }
  }

  /**
   * @description Shows the first page of the items as cards.
   * @function
   */
  function relistCardItems() {
    cardFilterObject.page = 1;
    vm.elements = [];
    fetchItems();
  }

  /**
   * @description Watcher that relists card view on trigger.
   * @function
   */
  function watchRelistDataCardView() {
    cardRelistWatcher = $scope.$watch('vm.relistData', function() {
      if (vm.relistData) {
        relistCardItems();
      }
    });
  }

  /*CARD LOGIC END*/
  /**
   * @description Changes the view mode.
   * @param {string} mode Defines which mode is used.
   * @function
   */
  function changeViewMode(mode) {
    vm.displayMode = mode;
    if (mode === 'card') {
      watchRelistDataCardView();
      cardFilterObject = {
        page: 1
      };
      vm.elements = [];
      vm.relistData = true;
    } else {
      if (cardRelistWatcher) {
        cardRelistWatcher();
      }
    }
    saveMode(vm.listId, vm.displayMode);
  }

  /**
   * @description Assigns the selected item.
   * @param {string} item Item to be selected.
   * @function
   */
  function onSelect(item) {
    vm.selected = item;
  }

  /**
   * @description Merges passed filter object with the entity configuration filter object.
   * @function
   */
  function mergeFilterObjects() {
    Object.assign(vm.filterObject, vm.entityConfiguration.api.filterObject);
  }

  /**
   * @description Triggers relisting of data.
   * @function
   */
  function RemoveObjectById() {
    vm.relistData = true;
  }

  /**
   * @description Returns the state redirect string.
   * @param {Object} linkConfigObject Object containing data for redirect string.
   * @function
   * @return {string} Redirect state.
   */
  function GenerateUISrefLink(linkConfigObject) {
    var params = linkConfigObject.params;
    var resultParams = {};
    var resultParamsString = '';
    if (params && params.length > 0) {
      params.forEach(function(param) {
        resultParams[param.queryParam] = param.value;
      });
      resultParamsString = '(' + JSON.stringify(resultParams) + ')';
    }
    return linkConfigObject.state + resultParamsString;
  }

  /**
   * @description Triggers the opening of dialog filters.
   * @function
   */
  function openFilters() {
    vm.relistData = false;
    $mdDialog
      .show({
        template: templateFilters,
        parent: angular.element(document.body),
        preserveScope: false,
        scope: $scope.$new(true),
        controller: FilterDialogController
      })
      .catch(angular.noop);
  }

  /**
   * @description Opens and closes the filters.
   * @function
   */
  function closeOpenProperties() {
    if (vm.collapsable && vm.collapsable.show == true) {
      vm.collapsable.show = false;
    } else {
      vm.collapsable.show = true;
    }
    StorageService.saveFilterDisplayStatus(vm.listId, vm.collapsable.show);
  }

  /**
   * @description Watches the changes of passed in data.
   * @function
   */
  function dataWatch() {
    stopWatchData = $scope.$watch('vm.data', function(newVal, oldVal) {
      if (newVal !== oldVal) {
        vm.listData({
          data: newVal
        });
      }
    });
  }
  /**
   * @description function that transforms the configuration.buttons array into something that can be used by sfe-actions component.
   * @function
   * @param {Object} configuration - list configuration object
   * @param {boolean} hasDialogFilter - tells us, if it should have a separate filterDialog
   * @return {dataType}
   */
  function createActionsArray(configuration, hasDialogFilter, entity) {
    let buttons = [];
    if (
      configuration &&
      Array.isArray(configuration.buttons) &&
      configuration.buttons.length > 0
    ) {
      buttons = configuration.buttons.reduce((arr, button) => {
        const iconName = button.icon ? 'icon:' + button.icon.name + ' ' : '';
        const testName = `${iconName}${button.title}`;
        let testId = testName.toLowerCase();
        if (entity != null) {
          testId = `${testId}_${entity}`.toLowerCase();
        }

        if (button.link) {
          arr.push({
            title: button.title,
            link: GenerateUISrefLink(button.link),
            color: button.color,
            icon: button.icon,
            disabledFn: button.disabledFn,
            testId
          });
        }
        if (button.fn) {
          if (button.relist) {
            arr.push({
              title: button.title,
              fn: () => {
                return button.fn(vm.relistDataFn);
              },
              color: button.color,
              icon: button.icon,
              disabledFn: button.disabledFn,
              testId
            });
          } else {
            arr.push({
              title: button.title,
              fn: button.fn,
              color: button.color,
              icon: button.icon,
              disabledFn: button.disabledFn,
              testId
            });
          }
        }
        return arr;
      }, []);
    }
    if (hasDialogFilter) {
      buttons.push({
        icon: {
          name: 'filter_list',
          type: 2
        },
        fn: openFilters,
        color: 'primary'
      });
    }

    return buttons;
  }

  /**
   * @description Saves the selected mode on mode change.
   * @function
   */
  function saveMode(listId, displayMode) {
    StorageService.saveListDisplayMode(listId, displayMode);
  }
}
