systemTagsConfiguration.$inject = ['gettext', '$q'];

function systemTagsConfiguration(gettext, $q) {
  var configuration = {
    entity: {
      singular: gettext('System Tags'),
      plural: gettext('System Tags')
    },
    api: {
      query: {
        entity: 'system-tags',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name'
    },
    attributes: [
      {
        param: 'description',
        title: gettext('Description')
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich() {
    var deferred = $q.defer();
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default systemTagsConfiguration;
