TaskDetailsController.$inject = [
  '$stateParams',
  'AlertingService',
  'gettext',
  'MetadataService',
  'task',
  '$timeout'
];

function TaskDetailsController(
  $stateParams,
  AlertingService,
  gettext,
  MetadataService,
  task,
  $timeout
) {
  var vm = this;
  var taskDetailsId = $stateParams.id;

  init();

  function init() {
    MetadataService.Loading(true);
    vm.loading = true;
    if (!taskDetailsId) {
      AlertingService.Error('Missing params: id');
      vm.loading = false;
    } else {
      $timeout(GetSOPTask);
    }
  }

  function GetSOPTask() {
    vm.headerData = constructHeader(task);
    MetadataService.Loading(false);
    vm.loading = false;
    MetadataService.ChangeMetadata(task.name, null);
  }

  function constructHeader(data) {
    if (data) {
      var actions = [
        {
          title: gettext('delete'),
          delete: true,
          id: data._id,
          endpoint: {
            entity: 'tasks',
            method: 'delete'
          },
          redirectState: 'alarms-and-rules-sop-tasks-list'
        },
        {
          title: gettext('duplicate'),
          state: 'alarms-and-rules-sop-tasks-duplicate',
          param: 'id',
          paramValue: data._id
        },
        {
          title: gettext('Update'),
          state: 'alarms-and-rules-sop-tasks-edit',
          param: 'id',
          paramValue: data._id
        }
      ];
      var userAccess = [
        {
          type: 'userAccess',
          title: gettext('Users'),
          users: true
        },
        {
          type: 'userAccess',
          title: gettext('Groups'),
          users: false
        }
      ];
      var propertySections = [
        {
          title: gettext('Authorized personnel'),
          properties: userAccess,
          identifier: 'tasks',
          target: taskDetailsId,
          type: 'userAccess'
        }
      ];

      return {
        metadata: {
          definition: gettext('Standard operational procedure task'),
          title: data.name,
          description: data.description
        },
        actions: actions,
        propertySections: propertySections
      };
    }
    return {
      metadata: {
        title: gettext('Error loading task')
      },
      actions: [],
      propertySections: []
    };
  }
}

export default TaskDetailsController;
