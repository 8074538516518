TangoAgentConnectionConfigConfiguration.$inject = [
  'gettext',
  '$q',
  'TranslationService',
  '$timeout',
  '$state'
];

function TangoAgentConnectionConfigConfiguration(
  gettext,
  $q,
  TranslationService,
  $timeout,
  $state
) {
  var configuration = {
    listId: 'tangoAgentConnectionConfig',
    getConfigurationWithUpdatedStates,
    entity: {
      singular: gettext('Tango Agent Connection Configuration'),
      plural: gettext('Tango Agent Connection Configurations')
    },
    api: {
      query: {
        entity: 'tango-agent-connection-configs',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'external-datasources-tango-agent-connection-config-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: getLinkData(),
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'external-datasources-tango-agent-connection-config-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'tango-agent-connection-configs',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'typeName',
        title: gettext('Type')
      }
    ],
    badges: [],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Tango Agent Configuration Type'),
        collection: TranslationService.GetCollection(
          'codelists.tangoAgentConfigTypes'
        ),
        valueField: 'id',
        param: 'tangoAgentConfigType'
      },
      {
        display: gettext('External Datasource'),
        query: {
          entity: 'external-datasources',
          method: 'read'
        },
        entity: 'external-datasources',
        param: 'externalDatasource',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      },
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'name DESC',
        params: '-name'
      }
    ]
  };

  function getLinkData() {
    let stateName = $state.current.name;
    let paramObject;
    switch (stateName) {
    case 'external-datasources-connectors-opc-view':
      paramObject = {
        value: $state.params.id,
        queryParam: 'opcId'
      };
      break;
    case 'external-datasources-external-datasources-view':
      paramObject = {
        value: $state.params.id,
        queryParam: 'externalDatasourceId'
      };
      break;
    case 'external-datasources-connectors-webservice-view':
      paramObject = {
        value: $state.params.id,
        queryParam: 'webserviceId'
      };
      break;
    case 'external-datasources-connectors-database-view':
      paramObject = {
        value: $state.params.id,
        queryParam: 'databaseId'
      };
      break;
    case 'external-datasources-connectors-file-view':
      paramObject = {
        value: $state.params.id,
        queryParam: 'fileId'
      };
      break;
    default:
      return {
        state: 'external-datasources-tango-agent-connection-config-new'
      };
    }
    if (paramObject) {
      return {
        state: 'external-datasources-tango-agent-connection-config-new',
        params: [paramObject]
      };
    }
  }
  /**
   * @description returns the configuration with the correct state, so that the links in the buttons work properly.
   * @return {Object} the data-source configuration
   */
  function getConfigurationWithUpdatedStates() {
    const configurationCopy = angular.copy(configuration);
    const updatedLinkButtons = getLinkData();
    configurationCopy.buttons[0].link = updatedLinkButtons;
    return configurationCopy;
  }
  /*
   *
   *   ENRICH
   *
   */

  function Enrich(item) {
    var deferred = $q.defer();
    var type = TranslationService.GetCollection(
      'codelists.tangoAgentConfigTypes'
    ).find(function(type) {
      return type.id === item.tangoAgentConfigType;
    });
    item.typeName = type ? type.name : gettext('None');
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default TangoAgentConnectionConfigConfiguration;
