module.exports = {
  entity: 'users',
  path: 'users',
  identityService: true,
  name: {
    en: 'Users',
    sl_SI: 'Uporabniki'
  },
  networkModel: {
    entity: 'users',
    read: 'read'
  },
  scope: 'identity:user',

  dialog: {
    title: {
      en: 'Select user',
      sl_SI: 'Izberite uporabnika'
    },
    listInfo: {
      en: 'Select user from the list below',
      sl_SI: 'Izberite uporabnika s spodnjega seznama'
    },
    identityService: true,
    displayFields: [
      {
        combined: true,
        field1: 'name',
        field2: 'family_name',
        type: 'string'
      }
    ]
  },
  displayFields: [
    {
      combined: true,
      field1: 'name',
      field2: 'family_name',
      type: 'string'
    }
  ]
}
