import { DateTime } from 'luxon';

TimeSeriesOutOfMeasuringpoint.$inject = [
  'gettextCatalog',
  'CrawlerMethods',
  '$smartAuth',
  'AlertingService'
];
/**
 * @ngdoc service
 * @name companyResources.TimeSeriesOutOfMeasuringpoint
 * @description create time series and reader flow to measuring point
 * @property {function} create
 * @property {function} getCreateTimeSeriesActionConfiguration
 */
export default function TimeSeriesOutOfMeasuringpoint(
  gettextCatalog,
  CrawlerMethods,
  $smartAuth,
  AlertingService
) {
  function parameterIsNotFetched(arrayOfItems, paramName) {
    return arrayOfItems.reduce((result, item) => {
      return (
        result || typeof item[paramName] != 'object' || item[paramName] == null
      );
    }, false);
  }
  /**
   * @memberof TimeSeriesOutOfMeasuringpoint.create
   * @description returns async function to create time series.
   * @param {Object} physicalData physical data object should contain {physicalQuantity, measurementUnit, metricPrefix}
   * @param {Object} measuringpoint measuring point
   * @return {Function}
   */
  function create(physicalData, measuringpoint) {
    /**
     * @description fetches all needed items to create time series and creates them.
     * @function
     * @return {Promise}
     */
    return async () => {
      let method;
      // MEASURING POINT NAME
      // MEASURING POINT ID
      const {
        name: measuringpointName,
        _id: measuringpointId
      } = measuringpoint;
      if (
        !physicalData.physicalQuantity ||
        !physicalData.measurementUnit ||
        !physicalData.metricPrefix
      ) {
        throw gettextCatalog.getString(
          'Energy source type is missing physical data'
        );
      }

      method = CrawlerMethods.getMethod({
        entity: 'measuringpoint-counter-types',
        method: 'custom.readCounterType'
      });
      const { data: measuringPointCounterTypes } = await method({
        measuringpoint: measuringpointId,
        isActive: true
      });

      if (measuringPointCounterTypes.length < 1) {
        throw gettextCatalog.getString('Measuringpoint has no counter types');
      }

      const { counterType } = measuringPointCounterTypes[0];
      const { registers } = counterType;
      if (!registers || registers.length < 1) {
        throw gettextCatalog.getString('Counter type has no registers');
      }

      const currentUser = await $smartAuth.profile();
      if (!currentUser) {
        throw gettextCatalog.getString('Failed to fetch current user');
      }
      // TIME SERIES TYPE
      method = CrawlerMethods.getMethod({
        entity: 'time-series-types',
        method: 'custom.populateWithTimeSeriesTypes'
      });
      const registersWithTypes = await method(registers);
      const failedRegisterTimeSeriesType = parameterIsNotFetched(
        registersWithTypes,
        'timeSeriesType'
      );

      if (failedRegisterTimeSeriesType) {
        throw gettextCatalog.getString('Failed to fetch time series types');
      }

      // TARIFFS
      method = CrawlerMethods.getMethod({
        entity: 'tariffs',
        method: 'custom.populateWithTariffs'
      });
      const registersWithTariffsAndTypes = await method(registersWithTypes);
      const failedRegisterTariffs = parameterIsNotFetched(
        registersWithTariffsAndTypes,
        'tariff'
      );
      if (failedRegisterTariffs) {
        throw gettextCatalog.getString('Failed to fetch tariffs');
      }

      // SCHEDULER
      method = CrawlerMethods.getMethod({
        entity: 'schedulers',
        method: 'read'
      });
      const { data: schedulers } = await method({
        scheduleClassification: 2,
        isStandard: true
      });
      if (!Array.isArray(schedulers) || schedulers.length < 1) {
        throw gettextCatalog.getString('Failed to fetch scheduler');
      } else if (schedulers.length > 1) {
        AlertingService.Error('More than one scheduler were fond');
      }
      const schedulerId = schedulers[0]._id;

      // CREATE TIME SERIES
      method = CrawlerMethods.getMethod({
        entity: 'time-series',
        method: 'create'
      });
      let promises = registersWithTariffsAndTypes.map(register => {
        return method({
          name:
            measuringpointName +
            ' - ' +
            register.timeSeriesType.name +
            ' - ' +
            register.tariff.code,
          ...physicalData,
          timeSeriesType: register.timeSeriesType._id,
          dataType: 3,
          precision: 2,
          timeSeriesStatus: 100, //Active
          tariff: register.tariff._id,
          dataScheduler: schedulerId,
          contentResponsiblePerson: currentUser._id,
          systemResponsiblePerson: currentUser._id,
          timeZone: 188,
          dataSamplingType: 100,
          dataInterpretationType: 100
        });
      });
      const timeSeries = await Promise.all(promises);
      // CREATE TIME SERIES MEASURINGPOINTS
      method = CrawlerMethods.getMethod({
        entity: 'time-series-measuringpoints',
        method: 'create'
      });

      let validFrom = DateTime.fromJSDate(new Date())
        .minus({ year: 10 })
        .startOf('day')
        .toJSDate();
      promises = timeSeries.map(res => {
        return method({
          timeSeries: res.data._id,
          measuringpoint: measuringpointId,
          validFrom
        });
      });

      await Promise.all(promises);

      method = CrawlerMethods.getMethod({
        entity: 'external-reader-flows',
        method: 'create'
      });

      promises = timeSeries.map(result => {
        return method(
          {
            timeSeriesId: result.data._id
          },
          {
            readScheduler: schedulerId,
            timeout: {
              maxTimeoutInMilliseconds: 2147483647,
              action: 1
            }
          }
        );
      });

      const eternalReaderFlows = await Promise.all(promises);
      // CREATE TIME SERIES CONFIGURATIONS
      method = CrawlerMethods.getMethod({
        entity: 'time-series-configurations',
        method: 'create'
      });
      promises = eternalReaderFlows.map(flow => {
        return method(
          {
            timeSeriesId: flow.data.timeSeries
          },
          {
            flowRef: 100,
            flow: flow.data._id,
            validFrom
          }
        );
      });

      await Promise.all(promises);
    };
  }

  /**
   * @memberof TimeSeriesOutOfMeasuringpoint.getCreateTimeSeriesActionConfiguration
   * @description returns create time series out of measuring point function if measuring point doesn't have any connected points  .
   * @param {object} measuringpoint measuring point object
   * @return {Promise} resolves to async function to create time series
   */
  async function getCreateTimeSeriesActionConfiguration(measuringpoint) {
    const { _id: measuringpointId, energySourceType } = measuringpoint;
    let method = CrawlerMethods.getMethod({
      entity: 'time-series-measuringpoints',
      method: 'read'
    });
    const { data: measuringPointTimeSeries } = await method({
      isActive: true,
      measuringpoint: measuringpointId
    });

    if (
      energySourceType != null &&
      (!measuringPointTimeSeries || measuringPointTimeSeries.length < 1)
    ) {
      const physicalData = {
        physicalQuantity: energySourceType.defaultPhysicalQuantity,
        metricPrefix: energySourceType.defaultMetricPrefix,
        measurementUnit: energySourceType.defaultMeasurementUnit
      };
      return create(physicalData, measuringpoint);
    }
  }

  return {
    getCreateTimeSeriesActionConfiguration: getCreateTimeSeriesActionConfiguration
  };
}
