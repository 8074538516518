TimeSeriesWizardConfiguration.$inject = [
  'PersonResponsibleTimeSeriesFormConfiguration',
  'CommonPropertiesTimeSeriesFormConfiguration',
  'RulesAndAlarmsTimeSeriesFormConfiguration',
  'gettextCatalog',
  'AlertingService',
  'TimeSeriesModel',
  'DataVisualizationConfiguration',
  'EntityTagService',
  'MaintenanceFlowModel',
  'MtAnalysisFlowModel',
  'CalculatedFlowFormConfigurations',
  'ExternalWriterFlowFormConfigurations',
  'CalculatedFlowModel',
  'ExternalWriterFlowModel',
  'ExternalReaderFlowFormConfigurations',
  'ExternalReaderFlowModel',
  'MtAnalysisFlowFormConfigurations',
  'MaintenanceFlowFormConfigurations',
  'TimeSeriesConfigurationModel',
  'codelistsConstant',
  'CrudToastFactory',
  'BoundariesConfiguration',
  'gettext'
];
/**
 * @ngdoc service
 * @name common.TimeSeriesWizardConfiguration
 * @description Get configuration for time series wizard.
 * @property {function} get
 */
export default function TimeSeriesWizardConfiguration(
  PersonResponsibleTimeSeriesFormConfiguration,
  CommonPropertiesTimeSeriesFormConfiguration,
  RulesAndAlarmsTimeSeriesFormConfiguration,
  gettextCatalog,
  AlertingService,
  TimeSeriesModel,
  DataVisualizationConfiguration,
  EntityTagService,
  MaintenanceFlowModel,
  MtAnalysisFlowModel,
  CalculatedFlowFormConfigurations,
  ExternalWriterFlowFormConfigurations,
  CalculatedFlowModel,
  ExternalWriterFlowModel,
  ExternalReaderFlowFormConfigurations,
  ExternalReaderFlowModel,
  MtAnalysisFlowFormConfigurations,
  MaintenanceFlowFormConfigurations,
  TimeSeriesConfigurationModel,
  codelistsConstant,
  CrudToastFactory,
  BoundariesConfiguration,
  gettext
) {
  let saved = false;

  /**
   * @description map time series body.
   * @function
   * @param {Object} body
   * @param {Object} bodyData
   * @return {Object} body
   */
  const mapTimeSeriesBody = (body, bodyData) => {
    body.name = bodyData.name;
    body.description = bodyData.description;
    body.timeSeriesType =
      bodyData.timeSeriesType != null ? bodyData.timeSeriesType._id : null;

    body.dataInterpretationType = bodyData.dataInterpretationType;
    body.dataSamplingType = bodyData.dataSamplingType;

    body.dataScheduler =
      body.dataSamplingType === 100 && bodyData.scheduler != null
        ? bodyData.scheduler._id
        : null;

    body.timeSeriesStatus = bodyData.timeSeriesStatus;
    body.physicalQuantity =
      bodyData.physicalQuantity != null ? bodyData.physicalQuantity._id : null;
    if (bodyData.measurementData != null) {
      body.measurementUnit =
        bodyData.measurementData.measurementUnit != null
          ? bodyData.measurementData.measurementUnit._id
          : null;
      body.metricPrefix =
        bodyData.measurementData.metricPrefix != null
          ? bodyData.measurementData.metricPrefix.id
          : null;
    }
    body.dataType = bodyData.dataType != null ? bodyData.dataType.id : null;
    // Decimal
    body.precision =
      body.dataType != null && body.dataType === 3 ? bodyData.precision : null;

    body.timeZone = bodyData.timeZone != null ? bodyData.timeZone.id : null;
    body.contentResponsiblePerson =
      bodyData.contentResponsiblePerson != null
        ? bodyData.contentResponsiblePerson._id
        : null;
    if (Array.isArray(bodyData.contentNotificationUsers)) {
      body.contentNotificationUsers = bodyData.contentNotificationUsers.map(
        user => user._id
      );
    }
    body.systemResponsiblePerson =
      bodyData.systemResponsiblePerson != null
        ? bodyData.systemResponsiblePerson._id
        : null;
    if (Array.isArray(bodyData.systemNotificationUsers)) {
      body.systemNotificationUsers = bodyData.systemNotificationUsers.map(
        user => user._id
      );
    }
    body.tariff = bodyData.tariff != null ? bodyData.tariff._id : null;
    body.gisData = bodyData.gisData;
    body.activeTimeSeriesConfiguration = bodyData.activeTimeSeriesConfiguration;
    body.dataVisualizationConfig = {
      displayType: bodyData.displayType != null ? bodyData.displayType : null,
      chartType: bodyData.chartType != null ? bodyData.chartType.id : null,
      color: bodyData.color,
      minimumValue: bodyData.minimumValue,
      maximumValue: bodyData.maximumValue
    };
    body.dataVisualizationConfig.defaultFilter = {
      filterType: bodyData.filterType
    };
    if (bodyData.numberOfValues != null && bodyData.numberOfValues !== '') {
      body.dataVisualizationConfig.defaultFilter.numberOfValues =
        bodyData.numberOfValues;
    }
    if (
      bodyData.historyScheduleClassification != null &&
      bodyData.historyNumberOfUnits != null
    ) {
      body.dataVisualizationConfig.defaultFilter.historyTimeRange = {
        scheduleClassification:
          bodyData.historyScheduleClassification != null
            ? bodyData.historyScheduleClassification.id
            : null,
        numberOfUnits: bodyData.historyNumberOfUnits
      };
    }
    if (
      bodyData.futureScheduleClassification != null &&
      bodyData.futureNumberOfUnits != null
    ) {
      body.dataVisualizationConfig.defaultFilter.futureTimeRange = {
        scheduleClassification: bodyData.futureScheduleClassification.id,
        numberOfUnits: bodyData.futureNumberOfUnits
      };
    }
    //RULE ALARMS
    if (Array.isArray(bodyData.writeCancelRules)) {
      body.rules = bodyData.writeCancelRules.map(value => {
        let ruleObj = {
          rule: value.rule != null ? value.rule._id : null
        };
        if (Array.isArray(value.alarms)) {
          let alarms = value.alarms.map(alarm => alarm._id);
          ruleObj.alarms = alarms;
        }
        ruleObj.integrityValue = value.integrityValue;
        return ruleObj;
      });
    }
    body.entityTags = bodyData.tags;
    body.boundaries = bodyData.boundaries;
    return body;
  };

  /**
   * @description get codelist name by codelist type and id.
   * @function
   * @param {number} id
   * @param {string} codelistType
   * @return {string} name
   */
  const getCodelistName = (codelistType, id) => {
    let codelist = codelistsConstant[codelistType].find(item => item.id === id);
    return codelist != null ? codelist.name : null;
  };

  /**
   * @description Add custom step to the configuration..
   * @function
   * @param {Object} configuration
   * @param {Object} step
   */
  const addStep = (configuration, step) => {
    if (
      configuration != null &&
      Array.isArray(configuration.steps) &&
      step != null
    ) {
      step.stepId = Math.random()
        .toString(36)
        .substring(2);
      step.formConfiguration.actions = [];
      step.addedStep = true;
      configuration.steps.push(step);
    }
  };

  /**
   * @description prepare body for timeSeries and bodyData for flows.
   * @function
   * @param {string} entityName
   * @param {Object} configuration
   * @return {Object} { timeSeriesBody, flowBody }
   */
  const prepareBodies = (entityName, configuration) => {
    let timeSeriesBody;
    let flowBody = configuration.steps.reduce((data, step) => {
      let stepData = step.api.getValues();
      if (step.entity === entityName) {
        timeSeriesBody = { ...timeSeriesBody, ...stepData };
      } else {
        data[step.stepId] = stepData;
        data[step.stepId].stepEntity = step.entity;
      }
      return data;
    }, {});
    return { timeSeriesBody, flowBody };
  };

  /**
   * @description create time series.
   * @function
   * @param {Object} timeSeriesBody
   * @return {number} timeSeriesId
   */
  const createTimeSeries = async timeSeriesBody => {
    try {
      let modelBody = mapTimeSeriesBody({}, timeSeriesBody);
      let entityTags = modelBody.entityTags;
      delete modelBody.entityTags;
      let createdTimeSeries = await TimeSeriesModel.create({}, modelBody);
      let timeSeries = createdTimeSeries.data;
      if (Array.isArray(entityTags)) {
        await EntityTagService.createSystemTags(
          entityTags,
          [],
          234,
          timeSeries._id,
          true
        );
      }
      return timeSeries;
    } catch (err) {
      saved = false;
      AlertingService.Error(err);
    }
  };

  /**
   * @description create externalReaderFlow.
   * @function
   * @param {Object} body
   * @param {number} timeSeriesId
   */
  const createExternalReaderFlow = async (body, timeSeriesId) => {
    try {
      let modelBody = ExternalReaderFlowFormConfigurations.mapBodyData(
        {},
        body
      );
      let createdFlow = await ExternalReaderFlowModel.create(
        { timeSeriesId },
        modelBody
      );
      await createTimeSeriesConfiguration(
        createdFlow.data._id,
        100,
        timeSeriesId,
        body.validFrom
      );
    } catch (err) {
      saved = false;
      AlertingService.Error(err);
    }
  };

  /**
   * @description create externalWriterFlow.
   * @function
   * @param {Object} body
   * @param {number} timeSeriesId
   */
  const createExternalWriterFlow = async (body, timeSeriesId) => {
    try {
      let modelBody = ExternalWriterFlowFormConfigurations.mapBodyData(
        {},
        body
      );
      let createdFlow = await ExternalWriterFlowModel.create(
        { timeSeriesId },
        modelBody
      );
      await createTimeSeriesConfiguration(
        createdFlow.data._id,
        200,
        timeSeriesId,
        body.validFrom
      );
    } catch (err) {
      saved = false;
      AlertingService.Error(err);
    }
  };

  /**
   * @description create calculatedFlow.
   * @function
   * @param {Object} body
   * @param {number} timeSeriesId
   */
  const createCalculatedFlow = async (body, timeSeriesId) => {
    try {
      let modelBody = CalculatedFlowFormConfigurations.mapBodyData({}, body);
      let createdFlow = await CalculatedFlowModel.create(
        { timeSeriesId },
        modelBody
      );
      await createTimeSeriesConfiguration(
        createdFlow.data._id,
        300,
        timeSeriesId,
        body.validFrom
      );
    } catch (err) {
      saved = false;
      AlertingService.Error(err);
    }
  };

  /**
   * @description create mtAnalysisFlow.
   * @function
   * @param {Object} body
   * @param {number} timeSeriesId
   */
  const createMtAnalysisFlow = async (body, timeSeriesId) => {
    try {
      let modelBody = MtAnalysisFlowFormConfigurations.mapBodyData({}, body);
      let createdFlow = await MtAnalysisFlowModel.create(
        { timeSeriesId },
        modelBody
      );
      await createTimeSeriesConfiguration(
        createdFlow.data._id,
        320,
        timeSeriesId,
        body.validFrom
      );
    } catch (err) {
      saved = false;
      AlertingService.Error(err);
    }
  };

  /**
   * @description create maintenanceFlow.
   * @function
   * @param {Object} body
   * @param {number} timeSeriesId
   */
  const createMaintenanceFlow = async (body, timeSeriesId) => {
    try {
      let modelBody = MaintenanceFlowFormConfigurations.mapBodyData({}, body);
      let createdFlow = await MaintenanceFlowModel.create(
        { timeSeriesId },
        modelBody
      );
      await createTimeSeriesConfiguration(
        createdFlow.data._id,
        500,
        timeSeriesId,
        body.validFrom
      );
    } catch (err) {
      saved = false;
      AlertingService.Error(err);
    }
  };

  /**
   * @description create timeSeriesConfiguration.
   * @function
   * @param {Object} body
   * @param {number} timeSeriesId
   */
  const createTimeSeriesConfiguration = async (
    flow,
    flowRef,
    timeSeriesId,
    validFrom
  ) => {
    try {
      let timeSeriesConfiguration = {
        validFrom: Array.isArray(validFrom) ? validFrom[0] : null,
        validTo: null,
        flow,
        flowRef
      };
      await TimeSeriesConfigurationModel.create(
        { timeSeriesId },
        timeSeriesConfiguration
      );
    } catch (err) {
      saved = false;
      AlertingService.Error(err);
    }
  };

  /**
   * @description create flows.
   * @function
   * @param {Object} bodyData
   * @param {number} timeSeriesId
   */
  const createFlows = async (
    bodyData,
    timeSeriesId,
    timeSeriesDataSamplingType
  ) => {
    let promises = Object.values(bodyData).map(body => {
      let entityCodelistName = body.stepEntity;
      delete body.stepEntity;
      delete body.stepValid;
      switch (entityCodelistName) {
      // External reader flow
      case 'ExternalReaderFlow': {
        return createExternalReaderFlow(
          {
            ...body,
            dataSamplingType: timeSeriesDataSamplingType
          },
          timeSeriesId
        );
      }
      // External writer flow.
      case 'ExternalWriterFlow': {
        return createExternalWriterFlow(body, timeSeriesId);
      }
      // Calculated flow
      case 'CalculatedFlow': {
        return createCalculatedFlow(body, timeSeriesId);
      }
      // M&T Analysis Flow
      case 'MonitorAndTargetAnalysisFlow': {
        return createMtAnalysisFlow(body, timeSeriesId);
      }
      // Maintenance flow
      case 'MaintenanceFlow': {
        return createMaintenanceFlow(body, timeSeriesId);
      }
      }
    });
    return Promise.all(promises);
  };

  /**
   * @description add flow to configuration as step.
   * @function
   * @param {Object} configuration
   * @param {string} title
   * @param {Object} formConfiguration
   * @param {number} entity
   */
  const addFlowToConfiguration = (
    configuration,
    title,
    formConfiguration,
    entity
  ) => {
    let step = {
      title: gettextCatalog.getString(title),
      api: {},
      entity: getCodelistName('entities', entity),
      formConfiguration
    };
    let validFrom = {
      id: 'validFrom',
      title: gettextCatalog.getString('Valid from'),
      type: {
        name: 'date',
        options: {
          enableTime: true,
          enableSeconds: true,
          useCurrentTime: false
        }
      },
      initialize: () => {
        return null;
      },
      required: true
    };
    step.formConfiguration.fields = [
      validFrom,
      ...step.formConfiguration.fields
    ];
    addStep(configuration, step);
  };

  /**
   * @description add external reader flow to configuration.
   * @function
   * @param {Object} configuration
   */
  const addExternalReaderFlow = async (configuration, item) => {
    let formConfiguration = await ExternalReaderFlowFormConfigurations.get({
      actionType: 'create',
      steps: configuration.steps,
      duplicateData: item
    });

    addFlowToConfiguration(
      configuration,
      'External reader flow',
      formConfiguration,
      240
    );
  };

  /**
   * @description add external writer flow to configuration.
   * @function
   * @param {Object} configuration
   * @param {Object} item
   */
  const addExternalWriterFlow = async (configuration, item) => {
    let formConfiguration = await ExternalWriterFlowFormConfigurations.get({
      actionType: 'create',
      steps: configuration.steps,
      duplicateData: item
    });
    addFlowToConfiguration(
      configuration,
      'External writer flow',
      formConfiguration,
      241
    );
  };

  /**
   * @description add calculated flow to configuration.
   * @function
   * @param {Object} configuration
   * @param {Object} item
   */
  const addCalculatedFlow = async (configuration, item, steps) => {
    let formConfiguration = await CalculatedFlowFormConfigurations.get({
      actionType: 'create',
      steps,
      duplicateData: item
    });

    addFlowToConfiguration(
      configuration,
      'Calculated flow',
      formConfiguration,
      242
    );
  };

  /**
   * @description add mt analysis flow to configuration.
   * @function
   * @param {Object} configuration
   * @param {Object} item
   */
  const addMtAnalysisFlow = async (configuration, item) => {
    let formConfiguration = await MtAnalysisFlowFormConfigurations.get({
      actionType: 'create',
      steps: configuration.steps,
      duplicateData: item
    });
    addFlowToConfiguration(
      configuration,
      'Mt analysis flow',
      formConfiguration,
      244
    );
  };

  /**
   * @description add maintenance flow to configuration.
   * @function
   * @param {Object} configuration
   * @param {Object} item
   */
  const addMaintenanceFlow = async (configuration, item) => {
    let formConfiguration = await MaintenanceFlowFormConfigurations.get({
      actionType: 'create',
      steps: configuration.steps,
      duplicateData: item
    });
    addFlowToConfiguration(
      configuration,
      'Maintenance flow',
      formConfiguration,
      246
    );
  };

  /**
   * @description if timeSeries has active flow add it.
   * @function
   * @param {Object} configuration
   * @param {Object} item
   */
  const addActiveFlow = async (configuration, item) => {
    if (item != null) {
      if (item.externalReaderFlow != null) {
        await addExternalReaderFlow(configuration, item);
      } else if (item.externalWriterFlow != null) {
        await addExternalWriterFlow(configuration, item);
      } else if (item.calculatedFlow != null) {
        await addCalculatedFlow(configuration, item, configuration.steps);
      } else if (item.mtAnalysisFlow != null) {
        await addMtAnalysisFlow(configuration, item);
      } else if (item.maintenanceFlow != null) {
        await addMaintenanceFlow(configuration, item);
      }
    }
  };

  /**
   * @memberof TangoItemConfiguration.get
   * @description  returns object that contains configurations to build tango item
   * @param {Object} item - timeSeries data, used for editing & duplicating
   * @param {number} entityName - code list entity name
   * @param {string} entityId - entity id
   * @param {Function} onSaveAction - optional function that's executed on save
   * @return {Object}
   */
  async function get(item, entityName, entityId, onSaveAction) {
    saved = false;
    let configurationTitle;
    if (item != null) {
      configurationTitle = gettextCatalog.getString('Duplicate time series');
    } else {
      configurationTitle = gettextCatalog.getString('Create time series');
    }
    let configuration = {
      id: () => (typeof entityId === 'string' ? entityId : null),
      title: configurationTitle,
      steps: [],
      actions: [],
      addConfiguration: {}
    };
    let personResponsibleConfiguration = await PersonResponsibleTimeSeriesFormConfiguration.asyncGet(
      item,
      configuration.steps
    );
    let commonPropertiesConfiguration = await CommonPropertiesTimeSeriesFormConfiguration.get(
      item,
      configuration.steps
    );
    let rulesAndAlarmsConfiguration = RulesAndAlarmsTimeSeriesFormConfiguration.get(
      item,
      configuration.steps
    );
    let dataVisualizationConfiguration = DataVisualizationConfiguration.get(
      item,
      configuration.steps
    );
    let boundariesConfigruation = BoundariesConfiguration.get(
      item,
      configuration.steps
    );
    configuration.steps.push(
      {
        stepId: 'timeSeriesCommonProperties',
        title: gettextCatalog.getString('Properties'),
        formConfiguration: {
          fields: commonPropertiesConfiguration.fields,
          name: commonPropertiesConfiguration.name,
          title: commonPropertiesConfiguration.title
        },
        api: {},
        entity: entityName
      },
      {
        stepId: 'timeSeriesPersonResponsible',
        title: gettextCatalog.getString(
          'Responsible persons and notifications'
        ),
        formConfiguration: {
          fields: personResponsibleConfiguration.fields,
          name: personResponsibleConfiguration.name,
          title: personResponsibleConfiguration.title
        },
        api: {},
        entity: entityName
      },
      {
        stepId: 'timeSeriesAlarmAndRules',
        title: gettextCatalog.getString('Alarms and rules'),
        formConfiguration: {
          fields: rulesAndAlarmsConfiguration.fields,
          name: rulesAndAlarmsConfiguration.name,
          title: rulesAndAlarmsConfiguration.title
        },
        api: {},
        entity: entityName
      },
      {
        stepId: 'timeSeriesDataVisualization',
        title: gettextCatalog.getString('Data visualization'),
        formConfiguration: {
          fields: dataVisualizationConfiguration.fields,
          name: dataVisualizationConfiguration.name,
          title: dataVisualizationConfiguration.title
        },
        api: {},
        entity: entityName
      },
      {
        stepId: 'timeSeriesBoundaries',
        title: gettextCatalog.getString('Boundaries'),
        formConfiguration: {
          fields: boundariesConfigruation.fields,
          name: boundariesConfigruation.name,
          title: boundariesConfigruation.title
        },
        api: {},
        entity: entityName
      }
    );
    configuration.actions.push({
      //SAVE
      title: gettext('Save'),
      fn: async () => {
        saved = true;
        let { timeSeriesBody, flowBody } = prepareBodies(
          entityName,
          configuration
        );
        let timeSeries = await createTimeSeries(timeSeriesBody);
        let timeSeriesId = timeSeries ? timeSeries._id : null;
        let timeSeriesDataSamplingType = timeSeries
          ? timeSeries.dataSamplingType
          : null;
        if (timeSeriesId != null) {
          try {
            await createFlows(
              flowBody,
              timeSeriesId,
              timeSeriesDataSamplingType
            );
            if (typeof onSaveAction === 'function') {
              onSaveAction(timeSeries);
            } else {
              const redirectObj = {
                state: 'data-time-series-view',
                paramName: 'id',
                paramValue: timeSeriesId
              };
              CrudToastFactory.toast('create', redirectObj);
            }
          } catch (error) {
            saved = false;
            AlertingService.Error(error);
          }
        } else {
          saved = false;
        }
      },
      disabledFn: () => {
        let foundInvalid = configuration.steps.find(
          step => step.api.formValidity() === false
        );
        if (foundInvalid == null && saved === false) {
          return false;
        }
        return true;
      },
      color: 'primary',
      raised: false
    });
    configuration.addConfiguration = {
      testId: 'add_flow_action',
      title: gettext('Add flow'),
      color: 'primary',
      menuWidth: 5,
      items: [
        {
          title: gettext('External reader flow'),
          testId: 'external_reader_flow_action',
          fn: () => {
            addExternalReaderFlow(configuration, item);
          }
        },
        {
          title: gettext('External writer flow'),
          testId: 'external_writer_flow_action',
          fn: () => {
            addExternalWriterFlow(configuration, item);
          }
        },
        {
          title: gettext('Calculated flow'),
          testId: 'calculated_flow_action',
          fn: () => {
            addCalculatedFlow(configuration, item, configuration.steps);
          },
          disabledFn: () => {
            let stepWithDataType = configuration.steps.find(step => {
              return step.api.getFieldConfiguration('dataType').id != null;
            });
            let dataType;
            let dataTypeName;
            if (stepWithDataType != null) {
              dataType = stepWithDataType.api.getValue('dataType');
              if (dataType != null) {
                dataTypeName = getCodelistName('dataTypes', dataType.id);
              }
            }
            return dataTypeName === 'Decimal' ? false : true;
          }
        },
        {
          title: gettext('Mt analysis flow'),
          testId: 'mt_analysis_flow_action',
          fn: () => {
            addMtAnalysisFlow(configuration, item);
          },
          disabledFn: () => {
            let stepWithDataType = configuration.steps.find(step => {
              return step.api.getFieldConfiguration('dataType').id != null;
            });
            let dataType;
            let dataTypeName;
            if (stepWithDataType != null) {
              dataType = stepWithDataType.api.getValue('dataType');
              if (dataType != null) {
                dataTypeName = getCodelistName('dataTypes', dataType.id);
              }
            }
            return dataTypeName === 'Decimal' ? false : true;
          }
        },
        {
          title: gettext('Maintenance flow'),
          testId: 'maintenance_flow_action',
          fn: () => {
            addMaintenanceFlow(configuration, item);
          }
        }
      ],
      disabledFn: () => {
        // Check if flow is already added.
        if (Array.isArray(configuration.steps)) {
          let addedStep = configuration.steps.find(step => {
            return step.addedStep === true;
          });
          if (addedStep != null) {
            return true;
          }
        }
        return false;
      }
    };
    await addActiveFlow(configuration, item);
    return configuration;
  }
  return {
    get
  };
}
