XAxisIntervalModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function XAxisIntervalModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'x-axis-intervals'
  };

  var crawlerConfigurationList = {
    entity: 'x-axis-intervals',
    method: 'read',
    steps: [
      {
        entity: 'target-for-x-axis-intervals',
        valueParams: ['_id'],
        queryParams: ['xAxisInterval'],
        method: 'read',
        methodType: 'addArray',
        newParamNames: ['xAxisIntervalObject'],
        filters: {
          isActive: true
        },
        mapping: {
          localField: '_id',
          foreignField: 'xAxisInterval'
        }
      }
    ]
  };

  var crawlerConfiguration = {
    entity: 'x-axis-intervals',
    method: 'read',
    steps: [
      {
        entity: 'target-for-x-axis-intervals',
        valueParams: ['_id'],
        queryParams: ['xAxisInterval'],
        method: 'read',
        methodType: 'add',
        newParamNames: ['xAxisIntervalArray'],
        filters: {
          isActive: true
        }
      }
    ]
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      listTarget: CrawlerMethods.crawler(crawlerConfigurationList),
      readTarget: CrawlerMethods.crawler(crawlerConfiguration)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default XAxisIntervalModel;
