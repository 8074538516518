import './sfe-tag-picker.scss';

TagSelectorController.$inject = [
  'tangoAgentConnectionConfigId',
  '$mdDialog',
  'gettext'
];

export default function TagSelectorController(
  tangoAgentConnectionConfigId,
  $mdDialog,
  gettext
) {
  const vm = this;
  vm.tags = [];
  vm.tangoAgentConnectionConfig = tangoAgentConnectionConfigId;
  vm.header = {
    title: gettext('Tag Selector'),
    isDialog: true,
    actions: [
      {
        icon: {
          name: 'close',
          type: 2
        },
        cancel: true
      }
    ]
  };
  vm.finish = function() {
    $mdDialog.hide(vm.selectedItem);
  };
  vm.cancel = function() {
    $mdDialog.cancel();
  };
  vm.deselectItem = () => {
    vm.selectedItem = null;
  };
}
