import template from './analysis-filters.directive.html';

function analysisFilters() {
  return {
    restrict: 'E',
    template,
    scope: {
      filter: '=',
      uniqueId: '='
    },
    link: linkFn,
    controller: AnalysisFiltersController,
    controllerAs: 'vm',
    bindToController: true
  };

  function linkFn() {}
}

AnalysisFiltersController.$inject = [
  '$scope',
  'AlertingService',
  'EntitiesService',
  'CrawlerMethods',
  'LongQueryHelper'
];

function AnalysisFiltersController(
  $scope,
  AlertingService,
  EntitiesService,
  CrawlerMethods,
  LongQueryHelper
) {
  const vm = this;
  let route;
  vm.initiating = false;
  vm.initFilter = initFilter;
  vm.preset = false;

  vm.$onChanges = function() {
    if (vm.filter) {
      initFilter();
    }
  };
  async function initFilter() {
    vm.initiating = true;
    const entity = EntitiesService.getEntityById(vm.filter.entity);
    vm.displayFields = EntitiesService.getDisplayFieldsByPath(entity) || [
      'name'
    ];
    let filterValues = [];
    let selectedParamName = EntitiesService.getSelectedParamName(entity);

    // INTEGER LIMIT INPUT
    if (vm.filter.type === 2) {
      vm.displayType = 'integerInput';
      vm.filter.filterValues[0] = Number(vm.filter.filterValues[0]);
    } else {
      if (vm.filter.filterValues && vm.filter.filterValues.length) {
        vm.preset = true;

        if (vm.filter.entity == 140) {
          //Master invoice custom view
          selectedParamName = 'masterInvoice';
        }

        filterValues = vm.filter.filterValues.map(function(filterValue) {
          if (typeof filterValue === 'object') {
            return filterValue._id;
          }
          return filterValue;
        });
        route = EntitiesService.getPath(entity);
      } else {
        switch (vm.filter.entity) {
        case 234: //time-series
        case 2: //measuring point
        case 4: //cost-centre
        case 3: //location
        case 104: //energy-source-type
        case 134: //locationType
        case 158: ///MeasuringPointType
        case 43: //Asset
        case 53: //Asset type
        case 235: //TimeSeriesType
        case 237: //TimeSeriesGroup
        case 238: //TimeSeriesGroupType
        case 105: //EntityTag
        case 140: //MasterInvoice
        case 20: //Alarms
        case 187: //Severity
        case 30: //alarmType
        case 248: //TangoAgentConnectionConfig
        case 247: //externalDatasource
          vm.displayType = 'dialog';
          break;
        case 251: //alarmStatuses
        case 252: //EventTypes
        case 224: //billingTypeBillingKinds
        case 249: //scheduleClassification
        case 58: //BillingType
        case 55: // BillingKind
          vm.displayType = 'dnd';
          break;
        }
      }
      if (route) {
        switch (vm.filter.filterType) {
        case 'dialog':
          // eslint-disable-next-line no-case-declarations
          let networkModel = EntitiesService.getNetworkModelByEntity(entity);
          if (networkModel.dialogMethod != null) {
            networkModel = {
              entity: networkModel.dialogMethod.entity,
              read: networkModel.dialogMethod.method
            };
          }

          // eslint-disable-next-line no-case-declarations
          const method = CrawlerMethods.getMethod({
            entity: networkModel.entity,
            method: networkModel.read
          });
          try {
            const items = await LongQueryHelper.execute(
              filterValues,
              method,
              selectedParamName
            );
            vm.filter.entityItemObjects = items;
          } catch (err) {
            AlertingService.Error(err);
          }
          vm.displayType = 'dialog';
          break;
        case 'dnd':
          //dnd data is called elsewhere
          vm.displayType = 'dnd';
          break;
        }
      }
    }
    vm.initiating = false;
    $scope.$applyAsync();
  }
}
export default analysisFilters;
