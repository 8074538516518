import './sfe-multi-select-list.scss';
import template from './sfe-multi-select-list.component.html';
/**
 * @ngdoc component
 * @name common.sfeMultiSelectList
 * @description displays table of autocomplete components or select components. It is possible to combine both
 * @param {string} addLabel label that will be displayed on the button that adds a new table row
 * @param {Array} configurations array of autocomplete or select component table row configurations
 * @param {Array} models table of selected values
 * @param {string} groupLabel table row title
 * @param {string} removeTitle title that will be shown on hover over remove icon (x)
 * @param {bool} disabledAdd disables select component and add button
 * @param {bool} disabledBtnAdd disables add button
 * @param {function} onAdd callback function that is triggered on row add
 * @param {function} onRemove callback function that is triggered on row remove
 * @param {string} noDataMessage displayed when there are no items selected. Default is No item is currently selected.
 * @param {bool} isRequiredToCreate indicates required status
 * @example
 */
export default {
  restrict: 'E',
  template,
  bindings: {
    addLabel: '<',
    configurations: '<',
    models: '=?',
    groupLabel: '<',
    removeTitle: '<',
    disabledAdd: '<',
    disabledBtnAdd: '<', //disabledAdd disables everything, this is for only disabling the add button.
    onAdd: '<',
    noDataMessage: '<',
    onRemove: '<',
    isRequiredToCreate: '<?'
  },
  controller: MultiSelectListController,
  controllerAs: 'vm',
  bindToController: true
};

MultiSelectListController.$inject = ['gettextCatalog', '$scope'];

function MultiSelectListController(gettextCatalog, $scope) {
  const vm = this;
  let cancelModelWatcher;

  $scope.$on('$destroy', () => {
    if (cancelModelWatcher) {
      cancelModelWatcher();
    }
  });

  function init() {
    if (!vm.noDataMessage) {
      vm.noDataMessage = gettextCatalog.getString(
        'No item is currently selected'
      );
    }
    if (vm.isRequiredToCreate === true) {
      vm.addRow();
    }
  }

  vm.addRow = addRow;
  vm.removeRow = removeRow;

  function addRow() {
    const listRow = [];

    if (!vm.models || !Array.isArray(vm.models)) {
      vm.models = [];
    }

    vm.configurations.forEach(config => {
      switch (config.componentType) {
      case 'autocomplete':
        listRow.push(null);
        break;
      default:
        listRow.push({});
        break;
      }
    });

    vm.models.push(listRow);
    if (vm.onAdd) {
      vm.onAdd();
    }
  }

  function removeRow(index) {
    vm.models.splice(index, 1);
    if (vm.onRemove) {
      vm.onRemove();
    }
  }

  this.$onInit = init;
}
