TimeseriesTangoAgentConnectionConfigConfiguration.$inject = [
  'gettext',
  '$q',
  'TimeSeriesConfiguration'
];
//externalReaderFlows.liveIngestionConfig.tangoAgentConfig.connectionConfig
function TimeseriesTangoAgentConnectionConfigConfiguration(
  gettext,
  $q,
  TimeSeriesConfiguration
) {
  const configuration = angular.copy(TimeSeriesConfiguration);
  configuration.api = {
    ...configuration.api,
    query: {
      entity: 'time-series',
      method: 'custom.readTACC'
    }
  };
  return configuration;
}
export default TimeseriesTangoAgentConnectionConfigConfiguration;
