MeasurementDatapointModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function MeasurementDatapointModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'measurement-datapoints'
  };
  var crawlerConfiguration = {
    entity: 'measurement-datapoints',
    method: 'read',
    steps: [
      {
        entity: 'datapoints',
        valueParams: ['datapoint'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'addArray',
        newParamNames: ['point']
      }
    ]
  };
  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readPoint: CrawlerMethods.crawler(crawlerConfiguration)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default MeasurementDatapointModel;
