import { DateTime } from 'luxon';

DateAutocomplete.$inject = ['LocalizationService'];

/**
 * @ngdoc service
 * @name common.DateAutocomplete
 * @description Service that autocompltes dates.
 * @property {function} get - See get method.
 */
export default function DateAutocomplete(LocalizationService) {
  /**
   * @memberof common.DateAutocomplete
   * @description Autocompletes dates based on the current input (example write in date an it autocompletes it with current month and year)
   * @param {string} inputDate - A date string.
   * @param {boolean} useCurrentTime - when date is not entered it wil be set to current time
   * @return {Date} Autocompleted date.
   */
  function get(inputDate, useCurrentTime) {
    let completedDate = new Date();
    if (inputDate) {
      const inputDateArray = inputDate.trim().split('.');
      let day, month, year, hour, minute, second;
      inputDateArray.forEach(function(dateValue, index) {
        if (dateValue) {
          switch (index) {
          case 0: {
            //day
            day = Number(dateValue.replace(/ /g, ''));
            break;
          }
          case 1: {
            //month
            month = Number(dateValue.replace(/ /g, ''));
            break;
          }
          case 2:
            {
              //year and time
              const yearTimeArray = dateValue.trim().split(' ');
              const yearValue = yearTimeArray[0];
              switch (yearValue.length) {
              case 1:
                year = Number('200' + yearValue);
                break;
              case 2:
                year = Number('20' + yearValue);
                break;
              case 3:
                year = Number('2' + yearValue);
                break;
              case 4:
                year = Number(yearValue);
                break;
              }
              if (yearTimeArray[1]) {
                const timeValue = yearTimeArray[1].split(':');
                const hourValue = Number(timeValue[0]);
                if (timeValue[2]) {
                  hour = hourValue;
                  minute = Number(timeValue[1]);
                  second = Number(timeValue[2]);
                } else if (timeValue[1]) {
                  hour = hourValue;
                  minute = Number(timeValue[1]);
                } else {
                  hour = hourValue;
                }
              }
            }
            break;
          }
        }
      });

      const now = DateTime.fromJSDate(new Date());
      if (day == null) {
        day = now.day;
      }
      if (month == null) {
        month = now.month;
      }
      if (year == null) {
        year = now.year;
      }
      if (useCurrentTime) {
        if (hour == null) {
          hour = now.hour;
        }
        if (minute == null) {
          minute = now.minute;
        }
        if (second == null) {
          second = now.second;
        }
      } else {
        if (hour == null) {
          hour = 0;
        }
        if (minute == null) {
          minute = 0;
        }
        if (second == null) {
          second = 0;
        }
      }

      const luxonDate = DateTime.local(
        year,
        month,
        day,
        hour,
        minute,
        second
      ).setLocale(LocalizationService.getLocale());

      if (!luxonDate.invalid) {
        completedDate = luxonDate.toJSDate();
      }
    }

    return completedDate;
  }

  return {
    get: get
  };
}
