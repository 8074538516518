module.exports = {
  entity: 'alarm-statuses',
  path: 'alarm-statuses',
  entityId: 251,
  name: {
    en: 'Alarm status',
    sl_SI: 'Status alarma'
  },
  codelist: 'alarmStatuses',
  scope: 'tis_be:alarmStatuses',
  selectedParamName: 'id',
  dialog: {
    title: {
      en: 'Select alarm status',
      sl_SI: 'Izberite status alarma'
    },
    listInfo: {
      en: 'Select alarm status from the list below',
      sl_SI: 'Izberite status alarma s spodnjega seznama'
    }
  }
}
