SfeListConfigurations.$inject = [
  'AlarmDefinitionConfiguration',
  'CostCentreConfiguration',
  'BusinessPartnerConfiguration',
  'MeasuringPointConfiguration',
  'AssetConfiguration',
  'LocationConfiguration',
  'ExternalDatasourceConfiguration',
  'TimeseriesTangoAgentConnectionConfigConfiguration',
  'PriceListConfiguration',
  'PriceListDetailsConfiguration',
  'MasterInvoiceConfiguration',
  'AlarmMonitoringConfiguration',
  'TimeIntervalFilterConfiguration',
  'DatasetConfiguration',
  'ExpressionConfiguration',
  'AggregationGroupConfiguration',
  'AnalysisConfiguration',
  'schedulerConfiguration',
  'ruleConfiguration',
  'mappingRuleConfiguration',
  'TangoAgentConnectionConfigConfiguration',
  'opcConfiguration',
  'webserviceConfiguration',
  'databaseConfiguration',
  'fileConfiguration',
  'sopConfiguration',
  'sopStepConfiguration',
  'sopTaskConfiguration',
  'alarmTypeConfiguration',
  'severityConfiguration',
  'responseConfiguration',
  'nationalHolidayConfiguration',
  'documentClassificationsConfiguration',
  'myNotificationConfiguration',
  'ownershipConfiguration',
  'assetTypeConfiguration',
  'assetStatusConfiguration',
  'assetBrandConfiguration',
  'assetModelConfiguration',
  'assetGroupConfiguration',
  'locationTypeConfiguration',
  'locationStatusConfiguration',
  'TimeSeriesTypeConfiguration',
  'TimeSeriesGroupTypeConfiguration',
  'measuringPointTypeConfiguration',
  'energySourceTypeConfiguration',
  'counterTypeConfiguration',
  'priceListItemGroupConfiguration',
  'tariffConfiguration',
  'billingTypeConfiguration',
  'billingKindConfiguration',
  'energyManagementGroupConfiguration',
  'physicalQuantityConfiguration',
  'measurementUnitConfiguration',
  'domainConfiguration',
  'domainAttributeConfiguration',
  'CalculationConfiguration',
  'locationClassificationConfiguration',
  'UserConfiguration',
  'GroupConfiguration',
  'locationActionStatusConfiguration',
  'ActionConfiguration',
  'reportConfiguration',
  'DashboardConfiguration',
  'DashboardPlaylistConfiguration',
  'jobConfiguration',
  'noticeConfiguration',
  'ManualInputConfiguration',
  'TimeSeriesManualInputConfiguration',
  'InvoiceDetailConfiguration',
  'gisConfiguration',
  'projectTypeConfiguration',
  'projectFieldConfiguration',
  'ProjectConfiguration',
  'UploadFilesConfiguration',
  'companyStructureNodeConfiguration',
  'profitCentreConfiguration',
  'visualizationConfiguration',
  'systemTagsConfiguration',
  'TimeSeriesConfiguration',
  'TimeSeriesGroupConfiguration',
  'RatedPowerConfiguration'
];

export default function SfeListConfigurations(
  AlarmDefinitionConfiguration,
  CostCentreConfiguration,
  BusinessPartnerConfiguration,
  MeasuringPointConfiguration,
  AssetConfiguration,
  LocationConfiguration,
  ExternalDatasourceConfiguration,
  TimeseriesTangoAgentConnectionConfigConfiguration,
  PriceListConfiguration,
  PriceListDetailsConfiguration,
  MasterInvoiceConfiguration,
  AlarmMonitoringConfiguration,
  TimeIntervalFilterConfiguration,
  DatasetConfiguration,
  ExpressionConfiguration,
  AggregationGroupConfiguration,
  AnalysisConfiguration,
  schedulerConfiguration,
  ruleConfiguration,
  mappingRuleConfiguration,
  TangoAgentConnectionConfigConfiguration,
  opcConfiguration,
  webserviceConfiguration,
  databaseConfiguration,
  fileConfiguration,
  sopConfiguration,
  sopStepConfiguration,
  sopTaskConfiguration,
  alarmTypeConfiguration,
  severityConfiguration,
  responseConfiguration,
  nationalHolidayConfiguration,
  documentClassificationsConfiguration,
  myNotificationConfiguration,
  ownershipConfiguration,
  assetTypeConfiguration,
  assetStatusConfiguration,
  assetBrandConfiguration,
  assetModelConfiguration,
  assetGroupConfiguration,
  locationTypeConfiguration,
  locationStatusConfiguration,
  TimeSeriesTypeConfiguration,
  TimeSeriesGroupTypeConfiguration,
  measuringPointTypeConfiguration,
  energySourceTypeConfiguration,
  counterTypeConfiguration,
  priceListItemGroupConfiguration,
  tariffConfiguration,
  billingTypeConfiguration,
  billingKindConfiguration,
  energyManagementGroupConfiguration,
  physicalQuantityConfiguration,
  measurementUnitConfiguration,
  domainConfiguration,
  domainAttributeConfiguration,
  CalculationConfiguration,
  locationClassificationConfiguration,
  UserConfiguration,
  GroupConfiguration,
  locationActionStatusConfiguration,
  ActionConfiguration,
  reportConfiguration,
  DashboardConfiguration,
  DashboardPlaylistConfiguration,
  jobConfiguration,
  noticeConfiguration,
  ManualInputConfiguration,
  TimeSeriesManualInputConfiguration,
  InvoiceDetailConfiguration,
  gisConfiguration,
  projectTypeConfiguration,
  projectFieldConfiguration,
  ProjectConfiguration,
  UploadFilesConfiguration,
  companyStructureNodeConfiguration,
  profitCentreConfiguration,
  visualizationConfiguration,
  systemTagsConfiguration,
  TimeSeriesConfiguration,
  TimeSeriesGroupConfiguration,
  RatedPowerConfiguration
) {
  var configurations = {};

  // ALARM
  configurations['alarm-definitions'] = AlarmDefinitionConfiguration;
  configurations['alarms'] = AlarmDefinitionConfiguration;
  configurations['sops'] = sopConfiguration;
  configurations['sop-steps'] = sopStepConfiguration;
  configurations['steps'] = sopStepConfiguration;
  configurations['sop-tasks'] = sopTaskConfiguration;
  configurations['tasks'] = sopTaskConfiguration;

  //VUSIALIZATIONS
  configurations['visualizations'] = visualizationConfiguration;

  // BILLING
  configurations['price-lists'] = PriceListConfiguration;
  configurations['price-list-masters'] = PriceListConfiguration;
  configurations['price-list-details'] = PriceListDetailsConfiguration;
  configurations['master-invoices'] = MasterInvoiceConfiguration;
  configurations['detail-invoices'] = InvoiceDetailConfiguration;

  // COMPANY RESOURCES
  configurations['assets'] = AssetConfiguration;
  configurations['locations'] = LocationConfiguration;
  configurations['upload-files'] = UploadFilesConfiguration;
  configurations['cost-centres'] = CostCentreConfiguration;
  configurations['business-partners'] = BusinessPartnerConfiguration;
  configurations['measuringpoints'] = MeasuringPointConfiguration;
  configurations['location-actions'] = ActionConfiguration;
  configurations['projects'] = ProjectConfiguration;

  // CONFIG
  configurations['alarm-types'] = alarmTypeConfiguration;
  configurations['severities'] = severityConfiguration;
  configurations['response-resolution-times'] = responseConfiguration;
  configurations['national-holidays'] = nationalHolidayConfiguration;
  configurations[
    'document-classifications'
  ] = documentClassificationsConfiguration;
  configurations['ownerships'] = ownershipConfiguration;
  configurations['asset-types'] = assetTypeConfiguration;
  configurations['asset-statuses'] = assetStatusConfiguration;
  configurations['asset-brands'] = assetBrandConfiguration;
  configurations['asset-models'] = assetModelConfiguration;
  configurations['asset-groups'] = assetGroupConfiguration;
  configurations['rated-powers'] = RatedPowerConfiguration;

  configurations['location-types'] = locationTypeConfiguration;
  configurations['location-statuses'] = locationStatusConfiguration;
  configurations[
    'location-classifications'
  ] = locationClassificationConfiguration;
  configurations['time-series-types'] = TimeSeriesTypeConfiguration;
  configurations['time-series-group-types'] = TimeSeriesGroupTypeConfiguration;
  configurations['measuring-point-types'] = measuringPointTypeConfiguration;
  configurations['measuringpoint-types'] = measuringPointTypeConfiguration;
  configurations['energy-source-types'] = energySourceTypeConfiguration;
  configurations['counter-types'] = counterTypeConfiguration;
  configurations['price-list-item-groups'] = priceListItemGroupConfiguration;
  configurations['tariffs'] = tariffConfiguration;
  configurations['billing-types'] = billingTypeConfiguration;
  configurations['billing-kinds'] = billingKindConfiguration;
  configurations[
    'energy-management-groups'
  ] = energyManagementGroupConfiguration;
  configurations['physical-quantities'] = physicalQuantityConfiguration;
  configurations['measurement-units'] = measurementUnitConfiguration;
  configurations['domains'] = domainConfiguration;
  configurations['domain-attributes'] = domainAttributeConfiguration;
  configurations[
    'location-action-statuses'
  ] = locationActionStatusConfiguration;
  configurations['project-types'] = projectTypeConfiguration;
  configurations['project-fields'] = projectFieldConfiguration;
  configurations['company-structure-nodes'] = companyStructureNodeConfiguration;
  configurations['profit-centres'] = profitCentreConfiguration;
  configurations['system-tags'] = systemTagsConfiguration;

  // DEVICE
  configurations['external-datasources'] = ExternalDatasourceConfiguration;
  configurations[
    'time-series-tango-agent-connection-config'
  ] = TimeseriesTangoAgentConnectionConfigConfiguration;
  configurations['jobs'] = jobConfiguration;

  // Data
  configurations['schedulers'] = schedulerConfiguration;
  configurations['rules'] = ruleConfiguration;
  configurations['mapping-rules'] = mappingRuleConfiguration;
  configurations['mappers'] = mappingRuleConfiguration;
  configurations[
    'tango-agent-connection-configs'
  ] = TangoAgentConnectionConfigConfiguration;
  configurations['opcs'] = opcConfiguration;
  configurations['webservices'] = webserviceConfiguration;
  configurations['databases'] = databaseConfiguration;
  configurations['files'] = fileConfiguration;
  configurations['time-series'] = TimeSeriesConfiguration;
  configurations['time-series-groups'] = TimeSeriesGroupConfiguration;
  // MONITORING
  configurations['triggered-alarms'] = AlarmMonitoringConfiguration;

  // ENERGY MANAGEMENT ANALYSIS
  configurations['time-interval-filters'] = TimeIntervalFilterConfiguration;
  configurations['period-intervals'] = TimeIntervalFilterConfiguration;
  configurations['datasets'] = DatasetConfiguration;
  configurations['expressions'] = ExpressionConfiguration;
  configurations['aggregation-groups'] = AggregationGroupConfiguration;
  configurations['analyses'] = AnalysisConfiguration;
  configurations['analysis-calculations'] = CalculationConfiguration;
  configurations['calculations'] = CalculationConfiguration;

  // REPORTING

  configurations['reports'] = reportConfiguration;

  // USER
  configurations['users'] = UserConfiguration;
  configurations['groups'] = GroupConfiguration;
  // DASHBOARD
  configurations['dashboards'] = DashboardConfiguration;
  configurations['dashboard-playlists'] = DashboardPlaylistConfiguration;
  // NOTIFICATIONS
  configurations['notification'] = noticeConfiguration;
  configurations['my-notification'] = myNotificationConfiguration;
  // manual input
  configurations['manual-inputs'] = ManualInputConfiguration;
  configurations[
    'time-series-manual-input'
  ] = TimeSeriesManualInputConfiguration;
  configurations['gis-map'] = gisConfiguration;

  return {
    configuration: configurations
  };
}
