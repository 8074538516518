import template from '../pages/itemSelector/item-selector.dialog.html';

ItemSelector.$inject = ['$mdDialog'];
/**
 * @ngdoc service
 * @name common.ItemSelector
 * @description opens select item dialog and resolves selected item.
 * @property {function} open
 */
export default function ItemSelector($mdDialog) {
  /**
   * @memberof ItemSelector.open
   * @description opens select item dialog and resolves selected item..
   * @param {Array} entities array of strings that represent entities
   * @param {Array} configurations array of dialog configuration objects
   * @param {Array} idsToExclude array of item ids that need to be excluded
   * @return {Promise}
   */
  function open(entities, configurations, idsToExclude) {
    return $mdDialog.show({
      controller: 'SelectItemController',
      controllerAs: 'vm',
      template,
      parent: angular.element(document.body),
      preserveScope: true,
      locals: {
        entities,
        configurations: configurations || [],
        idsToExclude
      }
    });
  }

  return {
    open: open
  };
}
