DashboardController.$inject = ['$timeout', 'MetadataService', 'dashboard'];

function DashboardController($timeout, MetadataService, dashboard) {
  var vm = this;
  MetadataService.Loading(true);
  vm.dashboard = dashboard;

  MetadataService.Loading(false);
  $timeout(() => {
    MetadataService.ChangeMetadata(dashboard.name, dashboard.description);
  });
}

export default DashboardController;
