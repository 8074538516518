NewPriceListController.$inject = [
  '$q',
  '$state',
  'gettext',
  'AlertingService',
  'ToastService',
  'MetadataService',
  'createOrUpdateService',
  'SfeHeader',
  '$timeout',
  'PriceListDetailModel',
  'PriceListItemModel',
  'TranslationService',
  'CrudToastFactory',
  'priceListMaster'
];

function NewPriceListController(
  $q,
  $state,
  gettext,
  AlertingService,
  ToastService,
  MetadataService,
  createOrUpdateService,
  SfeHeader,
  $timeout,
  PriceListDetailModel,
  PriceListItemModel,
  TranslationService,
  CrudToastFactory,
  priceListMaster
) {
  var vm = this;
  var priceListId = $state.params.priceListId;
  vm.editMode = priceListId !== undefined;
  vm.duplicateMode = false;
  var priceListDetail = {};
  var priceListItems = [];

  if ($state.current.name === 'invoices-price-lists-duplicate') {
    vm.editMode = false;
    vm.duplicateMode = true;
  }

  init();

  function init() {
    vm.dataConfig = {
      edit: vm.editMode || vm.duplicateMode,
      action: {
        ctrlFn: true,
        title: vm.editMode ? gettext('Update') : gettext('Create'),
        fn: createOrUpdate
      },
      data: [
        {
          placeholder: 'Name',
          name: 'name',
          componentType: 'textField',
          type: 'text'
        },
        {
          placeholder: 'Description',
          name: 'description',
          componentType: 'textArea',
          type: 'text',
          maxlength: 500,
          required: false
        },
        {
          placeholder: gettext('External Code'),
          name: 'externalCode',
          componentType: 'textField',
          type: 'text',
          required: false
        },
        {
          componentType: 'autocompleteDialog',
          edit: false,
          name: 'currency',
          configuration: {
            entity: 'currencies',
            dialogTitle: gettext('Select Currency'),
            floatingLabel: gettext('Select Currency'),
            codelist: 'currencies',
            empty: gettext('There is no currency to select.'),
            required: true
          }
        },
        {
          componentType: 'autocompleteDialog',
          edit: false,
          name: 'customer',
          configuration: {
            query: {
              entity: 'business-partners',
              method: 'read'
            },
            displayFields: ['name', 'code'],
            floatingLabel: gettext('Select customer'),
            searchParamName: 'name',
            entity: 'business-partners',
            dialogConfiguration: {
              title: gettext('Select customer')
            },
            selectedParam: 'id',
            createRedirect: {
              state: 'company-resources-business-partners-new'
            },
            filterObject: {
              order: 'name'
            },
            required: true
          }
        },
        {
          componentType: 'autocompleteDialog',
          edit: false,
          name: 'invoiceIssuer',
          configuration: {
            displayFields: ['name', 'code'],
            entity: 'business-partners',
            dialogConfiguration: {
              title: gettext('Select issuer'),
              filterObject: {
                isDistributer: true,
                order: 'name'
              }
            },
            dialogTitle: gettext('Select issuer'),
            floatingLabel: gettext('Select issuer'),
            query: {
              entity: 'business-partners',
              method: 'read'
            },
            searchParamName: 'name',
            filterObject: {
              isDistributer: true,
              order: 'name'
            },
            selectedParam: 'id',
            empty: gettext('There are no issuers to select.'),
            createRedirect: {
              state: 'company-resources-business-partners-new'
            },
            required: true
          }
        },
        {
          edit: false,
          name: 'energySourceType',
          componentType: 'autocompleteDialog',
          configuration: {
            dialog: false,
            query: {
              entity: 'energy-source-types',
              method: 'read'
            },
            entity: 'energy-source-types',
            dialogConfiguration: {
              multiple: false
            },
            floatingLabel: gettext('Select energy source type'),
            searchParamName: 'filter',
            selectedParam: 'id',
            required: true
          }
        }
      ]
    };

    if (vm.editMode || vm.duplicateMode) {
      MetadataService.Loading(true);
      setPriceListForm();
    } else {
      vm.dataConfig.dataObj = {
        name: '',
        description: ''
      };
    }

    if (vm.editMode) {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit price list'),
        'invoices-price-lists-view',
        $state.params
      );
    } else if (vm.duplicateMode) {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Duplicate price list'),
        'invoices-price-lists-view',
        $state.params
      );
      getPriceListDetail();
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New Price List'),
        'invoices-price-lists'
      );
    }
  }

  function setPriceListForm() {
    vm.dataConfig.dataObj = {
      name: priceListMaster.name,
      description: priceListMaster.description,
      externalCode: priceListMaster.externalCode,
      invoiceIssuer: priceListMaster.invoiceIssuer,
      customer: priceListMaster.businessPartnerCustomer,
      energySourceType: priceListMaster.energySourceType,
      currency: TranslationService.GetCollectionById(
        'codelists.currencies',
        priceListMaster.currency
      )
    };

    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(
        (vm.editMode && !vm.duplicateMode ? 'Edit ' : 'Duplicate ') +
          priceListMaster.name,
        priceListMaster.description
      );
    });
  }

  function getPriceListDetail() {
    PriceListDetailModel.read({
      priceListMaster: $state.params.priceListId
    }).then(
      function(res) {
        if (res.hasOwnProperty('data')) {
          if (res.data.length > 0) {
            res.data.sort(function(a, b) {
              return a.validFrom < b.validFrom;
            });
            priceListDetail = res.data[0];
            getPriceListItems(priceListDetail);
          }
        }
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function getPriceListItems(detail) {
    PriceListItemModel.read({
      priceListDetail: detail._id
    }).then(
      function(res) {
        if (res.hasOwnProperty('data')) {
          if (res.data.length > 0) {
            priceListItems = res.data;
          }
        }
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  // create and update functions //

  function createOrUpdate() {
    vm.sendingRequest = true;
    var redirectObj = {
      state: 'invoices-price-lists-view',
      paramName: 'priceListId'
    };
    const type = vm.editMode ? 'update' : 'create';
    createOrUpdateService
      .simpleCall(
        {
          entity: 'price-list-masters',
          method: vm.editMode && !vm.duplicateMode ? 'update' : 'create'
        },
        vm.editMode,
        priceListId,
        dataToSave()
      )
      .then(
        function(data) {
          redirectObj.paramValue = data._id;
          if (vm.duplicateMode) {
            if (!angular.equals(priceListDetail, {})) {
              createPriceListDetail(data);
            } else {
              ToastService.showToast(
                gettext('There is no price list'),
                redirectObj
              );
            }
          } else {
            CrudToastFactory.toast(type, redirectObj);
          }
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function dataToSave() {
    var dataObj = vm.dataConfig.dataObj;
    return {
      name: dataObj.name,
      description: dataObj.description,
      externalCode: dataObj.externalCode,
      invoiceIssuer: dataObj.invoiceIssuer._id,
      businessPartnerCustomer: dataObj.customer._id,
      energySourceType: dataObj.energySourceType._id,
      currency: dataObj.currency.id
    };
  }

  function createPriceListDetail(master) {
    PriceListDetailModel.create({
      priceListMaster: master._id,
      validFrom: new Date(priceListDetail.validFrom).setHours(0, 0, 0, 0),
      validTo: priceListDetail.validTo
        ? new Date(priceListDetail.validTo).setHours(0, 0, 0, 0)
        : priceListDetail.validTo,
      maxValue: priceListDetail.maxValue,
      minValue: priceListDetail.minValue
    }).then(
      function(res) {
        createPriceListItems(master, res.data);
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function createPriceListItems(master, detail) {
    const promises = priceListItems.map(item => {
      return PriceListItemModel.create({
        priceListDetail: detail._id,
        priceListItemGroup: item.priceListItemGroup,
        energyManagementGroup: item.energyManagementGroup,
        name: item.name,
        description: item.description,
        order: item.order,
        price: item.price,
        physicalQuantity: item.physicalQuantity,
        measurementUnit: item.measurementUnit,
        metricPrefix: item.metricPrefix,
        transferToNextMonth: item.transferToNextMonth,
        externalCode: item.externalCode
      });
    });
    $q.all(promises).then(
      function() {
        const redirectObject = {
          state: 'invoices-price-lists-view',
          paramName: 'priceListId',
          paramValue: master._id
        };
        CrudToastFactory.toast('create', redirectObject);
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }
}

export default NewPriceListController;
