//TIME SERIES
import timeSeriesConfigsProperties from './templates/time-series-configs-properties.template.html';
import calculatedTimeSeriesFlow from './templates/calculated-time-series-flow.template.html';
import mtAnalysisTimeSeriesFlow from './templates/mt-analysis-time-series-flow.template.html';

export default () => {
  return {
    timeSeriesConfigsProperties,
    calculatedTimeSeriesFlow,
    mtAnalysisTimeSeriesFlow
  };
};
