DomainCollectionListConfigService.$inject = ['gettext'];

function DomainCollectionListConfigService(gettext) {
  function getHeaders(domain) {
    var headerConfig = [];
    var mainName = {};
    _.each(domain.fields, function(domainField) {
      if (domainField.name === 'name') {
        mainName = {
          title: domainField.name,
          param: domainField.rawFieldName,
          _id: domainField._id
        };
      } else {
        var obj = {
          title: domainField.name,
          param: domainField.rawFieldName,
          _id: domainField._id
        };
        headerConfig.push(obj);
      }
    });

    return {
      title: mainName,
      attributes: headerConfig
    };
  }

  function getConfig(domainId) {
    return {
      entity: {
        singular: gettext('Domain Collection'),
        plural: gettext('Domain Collections')
      },
      api: {
        query: {
          entity: 'domain-collections',
          method: 'read'
        }
      },
      buttons: [
        {
          title: gettext('Create'),
          link: {
            state: 'configurations-dynamic-attributes-domains-collections-new',
            params: [
              {
                value: domainId,
                queryParam: 'domainId'
              }
            ]
          },
          color: 'accent'
        }
      ],
      shortActions: [
        {
          icon: 'mode_edit',
          link: {
            state: 'configurations-dynamic-attributes-domains-collections-edit',
            params: [
              {
                valueParam: '_id',
                queryParam: 'collectionId'
              },
              {
                value: domainId,
                queryParam: 'domainId'
              }
            ]
          }
        },
        {
          icon: 'delete',
          query: {
            queryParam: 'id',
            valueParam: '_id',
            method: 'delete',
            configuration: {
              entity: 'domain-collections',
              method: 'delete',
              params: {
                domainId: domainId
              }
            }
          }
        }
      ],
      sort: [
        {
          display: 'created DESC',
          params: '-_id'
        },
        {
          display: 'created ASC',
          params: '_id'
        }
      ]
    };
  }

  return {
    getConfig: getConfig,
    getHeaders: getHeaders
  };
}

export default DomainCollectionListConfigService;
