ExternalDatasourceController.$inject = [
  'InfoDialog',
  'AlertingService',
  '$mdDialog',
  'StandardUtils',
  '$timeout',
  'ToastService',
  '$state',
  'gettext',
  'TranslationService',
  'SfeListConfigurations',
  'SfeFormDialogService',
  'MetadataService',
  'ExternalDatasourceRegenerateCredentialsModel',
  'JobModel',
  'TimeSeriesConfiguration',
  'gettextCatalog',
  'CrudToastFactory',
  'TangoAgentConnectionConfigConfiguration',
  'ExternalDatasourceDataXConfiguration',
  'externalDatasource'
];

function ExternalDatasourceController(
  InfoDialog,
  AlertingService,
  $mdDialog,
  StandardUtils,
  $timeout,
  ToastService,
  $state,
  gettext,
  TranslationService,
  SfeListConfigurations,
  SfeFormDialogService,
  MetadataService,
  ExternalDatasourceRegenerateCredentialsModel,
  JobModel,
  TimeSeriesConfiguration,
  gettextCatalog,
  CrudToastFactory,
  TangoAgentConnectionConfigConfiguration,
  ExternalDatasourceDataXConfiguration,
  externalDatasource
) {
  const vm = this;
  let TangoAgentConnectionConfig = [];

  vm.externalDatasourceId = $state.params.id;
  vm.health = null;
  vm.now = Date.now();
  vm.closeDialog = closeDialog;
  vm.convertTime = convertTime;
  vm.apiDataX = {};

  init();

  function init() {
    vm.loading = true;
    TangoAgentConnectionConfig = [
      ...externalDatasource.TangoAgentConnectionConfig
    ];
    vm.isTypeTangoProcessingAgent =
      externalDatasource.externalDatasourceType === 100;
    MetadataService.Loading(true);
    vm.lastHeartbeat = externalDatasource.lastHeartbeat;
    prepareView(externalDatasource);
    vm.headerData = constructHeader(externalDatasource);
    vm.loading = false;
    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(
        externalDatasource.name,
        externalDatasource.description
      );
    });
  }

  function prepareView(externalDatasource) {
    externalDatasource.type = TranslationService.GetCollectionById(
      'codelists.externalDatasourceTypes',
      externalDatasource.externalDatasourceType
    );
    vm.filter = {
      externalDatasource: vm.externalDatasourceId
    };
    vm.externalReaderFlowFilter = {
      'activeTimeSeriesConfiguration.externalDatasource_':
        vm.externalDatasourceId
    };
    vm.deviceDatasourceFilter = {};
    vm.configuration = TangoAgentConnectionConfigConfiguration.getConfigurationWithUpdatedStates();
    let timeSeriesConfigurationLocal = angular.copy(TimeSeriesConfiguration);
    vm.timeSeriesConfiguration = timeSeriesConfigurationLocal;
    vm.deviceDatasourceFilter['externalDatasource'] = vm.externalDatasourceId;
    const config = angular.copy(SfeListConfigurations.configuration['jobs']);

    config.filter = null;
    config.buttons = [
      {
        title: gettext('Refresh'),
        fn: function(relist) {
          relist();
        },
        relist: true
      }
    ];

    vm.config = config;
    vm.chartConfig = ExternalDatasourceDataXConfiguration.get(
      vm.externalDatasourceId
    );
  }

  function closeDialog() {
    $mdDialog.cancel();
  }

  function openRetrieveTagsDialog() {
    let datasourceFilter = {
      externalDatasource: vm.externalDatasourceId
    };
    let tagDialogConfig = [
      {
        placeholder: gettext('Job name'),
        name: 'name',
        componentType: 'textField',
        showCond: true
      },
      {
        componentType: 'multiSelect',
        config: {
          label: gettext('Job types'),
          placeholder: gettext('Pick a job type'),
          ctrlFn: function() {
            return $timeout(function() {
              return TranslationService.GetCollection('codelists.jobTypes');
            }, 200);
          },
          empty: gettext('There are no job types to select from.'),
          valueField: 'id',
          displayOptions: ['name'],
          edit: true
        },
        name: 'type'
      },
      {
        componentType: 'autocompleteDialog',
        name: 'tangoAgentConnectionConfiguration',
        configuration: {
          floatingLabel: gettext('Select tango agent connection configuration'),
          query: {
            entity: 'tango-agent-connection-configs',
            method: 'read'
          },
          required: true,
          searchParamName: 'filter',
          filterObject: datasourceFilter,
          entity: 'tango-agent-connection-configs',
          dialogConfiguration: {
            filterObject: datasourceFilter
          },
          createRedirect: {
            state: 'external-datasources-tango-agent-connection-config-new'
          }
        }
      }
    ];
    let title = gettext('New Job');

    let createObject = {
      name: '',
      type: { _id: 1 },
      datasource: ''
    };

    SfeFormDialogService.openSfeFormDialog(
      false,
      tagDialogConfig,
      createObject,
      title
    ).then(function(object) {
      if (typeof object !== 'undefined') {
        let jobObject = {};
        jobObject.externalDatasource = vm.externalDatasourceId;
        jobObject.name = object.name;
        jobObject.type = object.type._id;
        jobObject.tangoAgentConnectionConfig =
          object.tangoAgentConnectionConfiguration._id;

        JobModel.create(jobObject).then(
          function() {
            vm.relistJobData = true;
            CrudToastFactory.toast('create');
          },
          function(err) {
            AlertingService.Error(err);
          }
        );
      }
    });
  }

  function convertTime(time) {
    let dateAgo = StandardUtils.readableTimeObjectSince(
      new Date(time).getTime()
    );
    if (dateAgo === '') {
      dateAgo = gettext('0 seconds');
    }

    return dateAgo;
  }

  function constructHeader(externalDatasource) {
    let address = {
      geoLocation: externalDatasource.geoLocation
    };

    let properties = [];
    let deviceProperties = [];
    let userAccess = [];
    let credKey;
    if (externalDatasource.MQTTPublicKey != null) {
      credKey = externalDatasource.MQTTPublicKey;
    }

    userAccess.push(
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    );

    let timeObject = convertMsInFormObject(
      externalDatasource.timeoutInMilliseconds
    );

    properties.push(
      {
        title: gettext('Last heartbeat'),
        value:
          typeof externalDatasource.lastHeartbeat === 'undefined'
            ? gettextCatalog.getString('No heartbeat yet')
            : vm.convertTime(externalDatasource.lastHeartbeat),
        type: 'raw'
      },
      {
        title: gettext('Timeout'),
        value: `${
          timeObject.days
            ? gettextCatalog.getPlural(
              timeObject.days,
              '1 day',
              '{{days}} days',
              { days: timeObject.days }
            )
            : ''
        } ${
          timeObject.hours
            ? gettextCatalog.getPlural(
              timeObject.hours,
              '1 hour',
              '{{hours}} hours',
              { hours: timeObject.hours }
            )
            : ''
        } ${
          timeObject.minutes
            ? gettextCatalog.getPlural(
              timeObject.minutes,
              '1 minute',
              '{{minutes}} minutes',
              { minutes: timeObject.minutes }
            )
            : ''
        } ${
          timeObject.seconds
            ? gettextCatalog.getPlural(
              timeObject.seconds,
              '1 second',
              '{{seconds}} seconds',
              { seconds: timeObject.seconds }
            )
            : ''
        }`,
        type: 'simple'
      }
    );

    deviceProperties.push(
      {
        title: gettext('Type'),
        value: externalDatasource.type.name,
        tooltip: externalDatasource.type.description,
        type: 'simple'
      },
      {
        title: gettext('Client ID'),
        value: externalDatasource._id,
        type: 'simple'
      }
    );

    if (typeof credKey !== 'undefined') {
      deviceProperties.push({
        title: gettext('Key'),
        value: externalDatasource.MQTTPublicKey,
        type: 'simple',
        __identifier__: 'key'
      });
    }

    if (externalDatasource.externalDatasourceVersion) {
      deviceProperties.push({
        type: 'simple',
        title: gettext('Version'),
        value: externalDatasource.externalDatasourceVersion
      });
    }
    let actions = [];

    if (typeof credKey !== 'undefined') {
      actions.push({
        title: gettext('Regenerate external datasource credentials'),
        color: 'warn',
        fn: regenerateDeviceCredentials
      });
    }
    if (externalDatasource.externalDatasourceType === 100) {
      actions.push({
        title: gettext('Submit job'),
        script: true,
        fn: openRetrieveTagsDialog,
        disabledFn: function() {
          TangoAgentConnectionConfig.length === 0;
        }
      });
    }
    actions.push(
      {
        title: gettext('delete'),
        delete: true,
        id: vm.externalDatasourceId,
        endpoint: {
          entity: 'external-datasources',
          method: 'delete'
        },
        redirectState: 'external-datasources-external-datasources-list'
      },
      {
        title: gettext('Update'),
        state: 'external-datasources-external-datasources-edit',
        param: 'id',
        paramValue: externalDatasource._id
      }
    );
    let propertySections = [
      {
        properties: properties,
        title: gettext('properties')
      },
      {
        properties: deviceProperties,
        title: gettext('external datasource')
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'external-datasources',
        target: vm.externalDatasourceId,
        type: 'userAccess'
      }
    ];

    return {
      metadata: {
        definition: gettext('External datasource'),
        title: externalDatasource.name,
        description: externalDatasource.description
      },
      actions: actions,
      propertySections: propertySections,
      address: address
    };
  }

  function convertMsInFormObject(nMs) {
    let seconds = nMs / 1000;
    let days = Math.floor(seconds / 3600 / 24);
    let hours = Math.floor(seconds / 3600) - days * 24;
    let minutes = Math.floor((seconds - hours * 3600) / 60 - days * 24 * 60);
    seconds = seconds - hours * 3600 - minutes * 60 - days * 24 * 3600;

    return {
      days,
      hours,
      minutes,
      seconds
    };
  }

  async function regenerateDeviceCredentials() {
    const title = gettext('Confirmation');
    const textItem = {
      text: gettext('Are you sure you want to regenerate credentials?'),
      type: 'text'
    };
    const actions = [
      {
        title: gettext('Cancel'),
        cancel: true,
        color: 'primary'
      },
      {
        title: gettext('Regenerate'),
        fn: async () => {
          try {
            const {
              data
            } = await ExternalDatasourceRegenerateCredentialsModel.create(
              {
                externalDatasourceId: $state.params.id
              },
              {}
            );
            openCredentialsDialog(data);
            updateDeviceKey(data.MQTTPublicKey);
          } catch (err) {
            AlertingService.Error(err);
          }
          $mdDialog.hide();
        },
        color: 'warn'
      }
    ];
    InfoDialog.open(title, null, [textItem], actions);
  }

  function updateDeviceKey(key) {
    const propertyToUpdate = vm.headerData.propertySections.reduce(
      (result, section) =>
        result ||
        section.properties.find(property => property.__identifier__ == 'key'),
      null
    );
    if (propertyToUpdate) {
      propertyToUpdate.value = key;
    }
  }

  function openCredentialsDialog(credentials) {
    let items = [
      {
        type: 'subtitle',
        text: gettext(
          'These credentials are required for installation of TIS client on your device. Make sure you have stored them safely. Device secret will never be shown anymore!'
        )
      },
      {
        text: 'Key',
        value: credentials.MQTTPublicKey,
        type: 'credential'
      },
      {
        text: 'Secret',
        value: credentials.MQTTSecret,
        type: 'credential'
      }
    ];
    let action = [
      {
        title: gettext('I have safely stored the secret'),
        cancel: true,
        color: 'accent'
      }
    ];
    let header = gettext('External datasource credentials');
    InfoDialog.open(header, null, items, action, copyToClipboard);

    function copyToClipboard(value, elementName) {
      // create new element, append it to body, store 'value' in it, copy to clipboard and delete the element
      let tempElement = document.createElement('input');
      document.body.appendChild(tempElement);
      tempElement.setAttribute('value', value);
      tempElement.select();
      document.execCommand('copy');
      document.body.removeChild(tempElement);
      if (elementName === 'Key') {
        ToastService.showToast(gettext('Key was copied to clipboard.'));
      } else {
        ToastService.showToast(gettext('Secret was copied to clipboard.'));
      }
    }
  }
}

export default ExternalDatasourceController;
