import mtAnalysisFlowAction from '../../../redux/mt-analysis-flow/action/mt-analysis-flow.action';
import timeSeriesConfigurationsAction from '../../../redux/time-series-configurations/action/times-series-configurations.action';

/**
 * @ngdoc service
 * @name common.MtAnalysisFlowFormConfiguration
 * @description Opens form dialog
 */

MtAnalysisFlowFormConfiguration.$inject = [
  'gettextCatalog',
  'gettext',
  '$ngRedux',
  '$mdDialog',
  'TimeSeriesConfigurationModel',
  'MtAnalysisFlowModel',
  'AlertingService',
  'CrudToastFactory',
  'SchedulerModel',
  'TranslationService',
  '$timeout',
  'SfeForm2DateFromPriorToValidationService',
  'TimeSeriesFlowHelper'
];
export default function MtAnalysisFlowFormConfiguration(
  gettextCatalog,
  gettext,
  $ngRedux,
  $mdDialog,
  TimeSeriesConfigurationModel,
  MtAnalysisFlowModel,
  AlertingService,
  CrudToastFactory,
  SchedulerModel,
  TranslationService,
  $timeout,
  SfeForm2DateFromPriorToValidationService,
  TimeSeriesFlowHelper
) {
  /**
   * @description map body data.
   * @function
   * @param {Object} body
   * @param {Object} bodyData
   */
  const mapBodyData = (
    body,
    {
      inputTimeSeriesForXAxis,
      inputTimeSeriesForYAxis,
      xValuesFrom,
      xValuesTo,
      dateForCalcOfRegressionFuncFrom,
      dateForCalcOfRegressionFuncTo
    }
  ) => {
    let setDateForCalcOfRegressionFuncFrom;
    let setDateForCalcOfRegressionFuncTo;
    if (Array.isArray(dateForCalcOfRegressionFuncFrom)) {
      setDateForCalcOfRegressionFuncFrom = dateForCalcOfRegressionFuncFrom[0];
      setDateForCalcOfRegressionFuncFrom.setHours(0, 0, 0);
    }
    if (Array.isArray(dateForCalcOfRegressionFuncTo)) {
      setDateForCalcOfRegressionFuncTo = dateForCalcOfRegressionFuncTo[0];
      setDateForCalcOfRegressionFuncTo.setHours(23, 59, 59);
    }
    return {
      ...body,
      inputTimeSeriesForXAxis: inputTimeSeriesForXAxis
        ? inputTimeSeriesForXAxis._id
        : null,
      inputTimeSeriesForYAxis: inputTimeSeriesForYAxis
        ? inputTimeSeriesForYAxis._id
        : null,
      dateForCalcOfRegressionFuncFrom: setDateForCalcOfRegressionFuncFrom,
      dateForCalcOfRegressionFuncTo: setDateForCalcOfRegressionFuncTo,
      xValuesFrom,
      xValuesTo
    };
  };
  /**
   * @description returns timeseries display object.
   * @function
   * @param {Object} item timeseries object
   * @return {Object}
   */
  let displayTimeSeries = item => {
    let schedulerClassificationAbbreviation = '';
    if (item != null) {
      if (
        item.dataScheduler != null &&
        item.dataScheduler.scheduleClassification
      ) {
        let schedulerClassification = TranslationService.GetCollectionById(
          'codelists.scheduleClassifications',
          item.dataScheduler.scheduleClassification
        );
        if (
          schedulerClassification != null &&
          schedulerClassification.name != ''
        ) {
          schedulerClassificationAbbreviation = ` - ${schedulerClassification.name}`;
        }
      }
      return {
        text: `${item.name} ${schedulerClassificationAbbreviation}`
      };
    }
    return { text: gettextCatalog.getString('Unknown') };
  };
  /**
   * @description populates input time series with dataScheduler.
   * @function
   * @param {Object} item flow object
   * @return {Object}
   */
  async function populateInputTimeSeries(item) {
    if (item != null) {
      let schedulersToFetch = [];
      if (
        item.inputTimeSeriesForXAxis != null &&
        typeof item.inputTimeSeriesForXAxis === 'object' &&
        item.inputTimeSeriesForXAxis.dataScheduler != null
      ) {
        schedulersToFetch.push(item.inputTimeSeriesForXAxis.dataScheduler);
      }
      if (
        item.inputTimeSeriesForYAxis != null &&
        typeof item.inputTimeSeriesForYAxis === 'object' &&
        item.inputTimeSeriesForYAxis.dataScheduler != null
      ) {
        schedulersToFetch.push(item.inputTimeSeriesForYAxis.dataScheduler);
      }

      schedulersToFetch = schedulersToFetch.filter(
        (value, index, allItems) => allItems.indexOf(value) === index
      );

      if (schedulersToFetch.length > 0) {
        try {
          let { data } = await SchedulerModel.read({ _id: schedulersToFetch });
          if (
            item.inputTimeSeriesForXAxis != null &&
            typeof item.inputTimeSeriesForXAxis === 'object' &&
            item.inputTimeSeriesForXAxis.dataScheduler != null
          ) {
            let xScheduler = data.find(
              scheduler =>
                scheduler._id === item.inputTimeSeriesForXAxis.dataScheduler
            );
            if (xScheduler != null) {
              item.inputTimeSeriesForXAxis.dataScheduler = xScheduler;
            }
          }
          if (
            item.inputTimeSeriesForYAxis != null &&
            typeof item.inputTimeSeriesForYAxis === 'object' &&
            item.inputTimeSeriesForYAxis.dataScheduler != null
          ) {
            let yScheduler = data.find(
              scheduler =>
                scheduler._id === item.inputTimeSeriesForYAxis.dataScheduler
            );
            if (yScheduler != null) {
              item.inputTimeSeriesForYAxis.dataScheduler = yScheduler;
            }
          }
        } catch (err) {
          AlertingService.Error(err);
        }
      }
    }
    return item;
  }

  /**
   * @description get common properties maintenance flow form configuration.
   * @function
   * @param {Object} entityId
   * @param {Array} steps - Array of objects, containing tangoWizard steps.
   * @param {Object} duplicateData - object containing data for duplicating
   * @return {Object} configuration
   */
  async function get(options) {
    const {
      item,
      timeSeries,
      timeSeriesConfiguration
    } = await TimeSeriesFlowHelper.getFlowData({
      ...options,
      entity: 'mt-analysis-flows',
      populate: 'inputTimeSeriesForXAxis,inputTimeSeriesForYAxis',
      processResult: populateInputTimeSeries
    });
    const { actionType } = options;

    let fromToDateCalculationOfRegressionValidation = SfeForm2DateFromPriorToValidationService.get(
      'dateForCalcOfRegressionFuncFrom',
      'dateForCalcOfRegressionFuncTo'
    );
    let title;
    switch (actionType) {
    case 'update':
      title = gettextCatalog.getString('Update M&T analysis flow');
      break;
    case 'create':
      title = gettextCatalog.getString('Create M&T analysis flow');
      break;
    case 'duplicate':
      title = gettextCatalog.getString('Duplicate M&T analysis flow');
      break;
    default:
      title = gettextCatalog.getString('M&T analysis flow');
    }

    let config = {
      name: 'mtAnalysisFlow',
      actions: [
        {
          //SAVE ACTION
          title: gettext('Save'),
          fn: async form => {
            let bodyData = form.getValues();
            let body = mapBodyData({}, bodyData);

            // /UPDATE
            if (
              item != null &&
              timeSeriesConfiguration != null &&
              actionType === 'update'
            ) {
              const flowParams = {
                query: { timeSeriesId: item.timeSeries, id: item._id },
                body
              };
              const configurationParams = {
                query: {
                  timeSeriesId: item.timeSeries,
                  id: timeSeriesConfiguration._id
                },
                body: {
                  validFrom: bodyData.validFrom[0],
                  validTo: Array.isArray(bodyData.validTo)
                    ? bodyData.validTo[0]
                    : null
                }
              };
              let actionFlow = mtAnalysisFlowAction.updateMtAnalysisFlow;
              await $ngRedux.dispatch(actionFlow(flowParams));
              if (timeSeriesConfiguration.validTo == null) {
                let action = timeSeriesConfigurationsAction.updateTimeSeriesConfigurations(
                  configurationParams
                );
                $ngRedux.dispatch(action);
              }
            } else {
              //CREATE
              try {
                let timeSeriesConfigurationBody = {
                  validFrom: bodyData.validFrom[0],
                  flowRef: 320
                };
                let createdFlow = await MtAnalysisFlowModel.create(
                  { timeSeriesId: timeSeries._id },
                  body
                );
                let actionFlow = mtAnalysisFlowAction.addMtAnalysisFlow;
                await $ngRedux.dispatch(actionFlow({ result: createdFlow }));
                timeSeriesConfigurationBody.flow = createdFlow.data._id;

                let createdTimeSeriesConfiguration = await TimeSeriesConfigurationModel.create(
                  { timeSeriesId: timeSeries._id },
                  timeSeriesConfigurationBody
                );
                let action =
                  timeSeriesConfigurationsAction.addTimeSeriesConfigurations;
                await $ngRedux.dispatch(
                  action({ result: createdTimeSeriesConfiguration })
                );
                CrudToastFactory.toast('create');
              } catch (err) {
                AlertingService.Error(err);
              }
            }
            $mdDialog.hide();
          },
          color: 'primary',
          disabledFn: form => {
            if (form.formValidity() === true) {
              return false;
            }
            return true;
          }
        }
      ],
      title,
      fields: [
        {
          id: 'inputTimeSeriesTitle',
          type: {
            name: 'title',
            options: {
              value: gettextCatalog.getPlural(
                3,
                'Input Time Series',
                'Input Time Series',
                {}
              ),
              theme: 'primary'
            }
          }
        },
        {
          id: 'inputTimeSeriesForXAxis',
          title: gettextCatalog.getString('Input time series for X axis'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'time-series',
                method: 'read'
              },
              valueParam: '_id',
              crawlerParams: text => {
                let params = {
                  order: '-name',
                  populate: 'dataScheduler'
                };
                if (text != null && text != '') {
                  return {
                    ...params,
                    filter: text
                  };
                }
                return params;
              },
              display: displayTimeSeries,
              dialog: {
                entity: 'time-series'
              }
            }
          },
          initialize: () => {
            return item ? item.inputTimeSeriesForXAxis : null;
          },
          required: true
        },
        {
          id: 'inputTimeSeriesForYAxis',
          title: gettextCatalog.getString('Input time series for Y axis'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'time-series',
                method: 'read'
              },
              valueParam: '_id',
              crawlerParams: text => {
                let params = {
                  order: '-name',
                  populate: 'dataScheduler'
                };
                if (text != null && text != '') {
                  return {
                    ...params,
                    filter: text
                  };
                }
                return params;
              },
              display: displayTimeSeries,
              dialog: {
                entity: 'time-series'
              }
            }
          },
          initialize: () => {
            return item ? item.inputTimeSeriesForYAxis : null;
          },
          required: true
        },
        {
          id: 'xValuesFrom',
          title: gettextCatalog.getString('X values from'),
          type: {
            name: 'text',
            options: {
              type: 'numerical',
              onlyInteger: true
            }
          },
          initialize: () => {
            return item != null ? item.xValuesFrom : '';
          },
          validators: {
            maximumValueFrom: {
              fn: (modelValue, viewValue, api) => {
                let xValuesTo = api.getValue('xValuesTo');
                if (
                  modelValue != null &&
                  modelValue !== '' &&
                  xValuesTo != null &&
                  xValuesTo !== '' &&
                  modelValue >= xValuesTo
                ) {
                  return false;
                }
                return true;
              },
              text: gettextCatalog.getString('Must be less than X values to')
            }
          },
          onChange: form => {
            $timeout(() => {
              form.revalidate();
            });
          },
          width: 6,
          required: true
        },
        {
          id: 'xValuesTo',
          title: gettextCatalog.getString('X values to'),
          type: {
            name: 'text',
            options: {
              type: 'numerical',
              onlyInteger: true
            }
          },
          initialize: () => {
            return item != null ? item.xValuesTo : '';
          },
          validators: {
            minimumValueTo: {
              fn: (modelValue, viewValue, api) => {
                let xValuesFrom = api.getValue('xValuesFrom');
                if (
                  modelValue != null &&
                  modelValue !== '' &&
                  xValuesFrom != null &&
                  xValuesFrom !== '' &&
                  modelValue <= xValuesFrom
                ) {
                  return false;
                }
                return true;
              },
              text: gettextCatalog.getString(
                'Must be greater than X values from'
              )
            }
          },
          onChange: form => {
            $timeout(() => {
              form.revalidate();
            });
          },
          width: 6,
          required: true
        },
        {
          id: 'dateForCalculationOfRegressionTitle',
          type: {
            name: 'title',
            options: {
              value: gettextCatalog.getString(
                'Date for calculation of regression'
              ),
              theme: 'primary'
            }
          }
        },
        {
          id: 'dateForCalcOfRegressionFuncFrom',
          title: gettextCatalog.getString(
            'Date for calculation of regression function from'
          ),
          type: {
            name: 'date',
            options: {}
          },
          initialize: () => {
            return item != null
              ? new Date(item.dateForCalcOfRegressionFuncFrom)
              : null;
          },
          validators: {
            beforeToDate: fromToDateCalculationOfRegressionValidation
          },
          onChange: form => {
            $timeout(form.revalidate);
          },

          width: 6,
          required: true
        },
        {
          id: 'dateForCalcOfRegressionFuncTo',
          title: gettextCatalog.getString(
            'Date for calculation of regression function to'
          ),
          type: {
            name: 'date',
            options: {}
          },
          initialize: () => {
            return item != null
              ? new Date(item.dateForCalcOfRegressionFuncTo)
              : null;
          },
          validators: {
            afterFromDate: fromToDateCalculationOfRegressionValidation
          },
          onChange: form => {
            $timeout(form.revalidate);
          },

          width: 6,
          required: true
        }
      ]
    };
    return config;
  }

  return { get, mapBodyData };
}
