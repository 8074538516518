MaintenanceFlowTimeSeriesConfigurations.$inject = [
  'TranslationService',
  'gettextCatalog',
  'Formatting'
];

function MaintenanceFlowTimeSeriesConfigurations(
  TranslationService,
  gettextCatalog,
  Formatting
) {
  /**
   * @description get maintenance flow configuration.
   * @function
   * @param {Object} item - maintenance flow
   * @return {Array}
   */
  function getProperties(item) {
    const maintenanceFlowType = TranslationService.GetCollectionById(
      'codelists.flowTypes',
      item.flowType
    );

    let properties = [
      {
        type: 'basic',
        title: gettextCatalog.getString('Maintenance flow type'),
        testId: 'maintenanceFloeType',
        values: [
          {
            text:
              maintenanceFlowType != null
                ? maintenanceFlowType.name
                : gettextCatalog.getString('Unknown')
          }
        ]
      }
    ];
    if (item.flowType == 510) {
      let initialValue = [];
      let finalValue = [];

      if (item.finalValue != null) {
        finalValue = [
          {
            text: Formatting.formatNumber(item.finalValue)
          }
        ];
      }

      if (item.initialValue != null) {
        initialValue = [
          {
            text: Formatting.formatNumber(item.initialValue)
          }
        ];
      }

      properties = [
        ...properties,
        {
          type: 'basic',
          title: gettextCatalog.getString('Final value'),
          testId: 'finalValue',
          values: finalValue
        },
        {
          type: 'basic',
          title: gettextCatalog.getString('Initial value'),
          testId: 'initialValue',
          values: initialValue
        }
      ];
    }

    return properties;
  }
  /**
   * @description returns configuration object to get tango properties configuration for maintenance flow time series configuration.
   * @function
   * @param {object} flow flow object
   * @return {Object}
   */
  function get(flow) {
    return {
      type: 'dynamic',
      valueFn: store => {
        let item = {};
        if (
          store != null &&
          store.maintenanceFlow != null &&
          store.maintenanceFlow[flow.flow] != null &&
          store.maintenanceFlow[flow.flow].data != null
        ) {
          item = store.maintenanceFlow[flow.flow].data;
        }
        return [
          {
            canClose: false,
            properties: getProperties(item)
          }
        ];
      }
    };
  }
  return {
    get
  };
}

export default MaintenanceFlowTimeSeriesConfigurations;
