GisUserModel.$inject = ['ModelUtilities'];

function GisUserModel(ModelUtilities) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'gis',
    path: 'user'
  };
  var networkConfigurationToken = {
    host: 'gis',
    path: 'user/:userId/token'
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PATCH', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      createToken: ModelUtilities.crud(
        'POST',
        networkConfigurationToken,
        modelStorage
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default GisUserModel;
