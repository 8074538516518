import template from './sfe-theme-selector.component.html';
import themesJSON from '../../../../config/tis/themes.json';
import './sfe-theme-selector.scss';

/**
 * @ngdoc component
 * @name user.sfeThemeSelector
 * @description allows user to select different themes.
 * @example
 * <sfe-theme-selector
 * paramName="'paramValue'"
 * ></sfe-theme-selector>
 */
export default {
  template,
  bindings: {},
  controller: SfeThemeSelectorController,
  controllerAs: 'vm'
};

SfeThemeSelectorController.$inject = ['$rootScope', '$theming'];

function SfeThemeSelectorController($rootScope, $theming) {
  var vm = this;
  vm.selectedTheme = null;
  vm.themes = {};
  vm.openMenu = openMenu;
  vm.setTheme = setTheme;
  vm.resetTheme = resetTheme;
  /**
   * @description initializes availbale theme array.
   * @function
   */
  vm.$onInit = function() {
    vm.themes = themesJSON.sort(function(t1, t2) {
      return (
        getHue(t1.primary.palette['500'].value.hex) >
        getHue(t2.primary.palette['500'].value.hex)
      );
    });
    vm.selectedTheme = getCurrentTheme(vm.themes, $rootScope.activeTheme);
  };
  /**
   * @description returns currently selected theme.
   * @function
   * @param {array} themes array of availbale themes
   * @param {string} themeName selected theme name
   * @return {object}
   */
  function getCurrentTheme(themes, themeName) {
    return themes.find(function(el) {
      return el.name === themeName;
    });
  }
  /**
   * @description sets selected theme globally.
   * @function
   * @param {object} theme selected theme
   * @param {bool} saveSetting indicates if we should save settings
   */
  function setTheme(theme, saveSetting) {
    if (!vm.selectedTheme || theme.name != vm.selectedTheme.name) {
      if (saveSetting) {
        vm.selectedTheme = theme;
      }
      $theming.setGlobalTheme(theme.name, saveSetting);
    }
  }
  /**
   * @description on mouse leave sets theme to currently selected theme.
   * @function
   */
  function resetTheme() {
    if (vm.selectedTheme) {
      $theming.setGlobalTheme(vm.selectedTheme.name, false);
    }
  }
  /**
   * @description opens theme menu.
   * @function
   * @param {object} $mdMenu menu
   * @param {event} ev event that triggered this function
   */
  function openMenu($mdMenu, ev) {
    $mdMenu.open(ev);
  }
  /**
   * @description sort helper function, returns hue out of hex values.
   * @function
   * @param {string} hex hex color representation
   * @return {number}
   */
  function getHue(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);

    (r /= 255), (g /= 255), (b /= 255);
    var max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    var h;

    if (max == min) {
      h = 0; // achromatic
    } else {
      var d = max - min;
      switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      }
      h /= 6;
    }

    h = Math.round(360 * h);

    return h;
  }
}
