import './entity-type-configuration.scss';

NewEntityTypeConfigController.$inject = [
  '$q',
  '$state',
  'AlertingService',
  'gettext',
  'gettextCatalog',
  'LocalizationService',
  'MultiLanguage',
  'MetadataService',
  'createOrUpdateService',
  'SfeHeader',
  'CrawlerMethods',
  'DomainAttributeModel',
  'SystemTagEntityModel',
  '$scope',
  'entityTypeObject',
  '$timeout'
];

function NewEntityTypeConfigController(
  $q,
  $state,
  AlertingService,
  gettext,
  gettextCatalog,
  LocalizationService,
  MultiLanguage,
  MetadataService,
  createOrUpdateService,
  SfeHeader,
  CrawlerMethods,
  DomainAttributeModel,
  SystemTagEntityModel,
  $scope,
  entityTypeObject,
  $timeout
) {
  // controller for new asset, location, time series and measuring point type configuration
  var vm = this;
  vm.addAttribute = addAttribute;
  vm.removeAttribute = removeAttribute;
  vm.createOrUpdate = createOrUpdate;
  // set the entity object based on current state
  var stateName = $state.current.name;
  vm.entityProperties = {};
  switch (stateName) {
  case 'configurations-company-resources-assets-asset-types-new':
  case 'configurations-company-resources-assets-asset-types-edit':
    vm.entityProperties = {
      titleNew: gettextCatalog.getString('New asset type'),
      titleEdit: gettextCatalog.getString('Edit asset type'),
      entity: 'asset-types',
      attribute: {
        entity: 'asset-type-attributes',
        method: 'read'
      },
      paramName: 'id',
      redirect: 'configurations-company-resources-assets-asset-types-view',
      cancelStringEdit:
          'configurations-company-resources-assets-asset-types-view',
      cancelString: 'configurations-company-resources-assets-list'
    };
    break;
  case 'configurations-company-resources-locations-location-types-new':
  case 'configurations-company-resources-locations-location-types-edit':
    vm.entityProperties = {
      titleNew: gettextCatalog.getString('New location type'),
      titleEdit: gettextCatalog.getString('Edit location type'),
      entity: 'location-types',
      attribute: {
        entity: 'location-type-attributes',
        method: 'read'
      },
      paramName: 'id',
      redirect:
          'configurations-company-resources-locations-location-types-view',
      cancelStringEdit:
          'configurations-company-resources-locations-location-types-view',
      cancelString: 'configurations-company-resources-locations-list'
    };
    break;
  case 'configurations-company-resources-time-series-time-series-types-new':
  case 'configurations-company-resources-time-series-time-series-types-edit':
    vm.entityProperties = {
      name: gettextCatalog.getString('Time series type'),
      titleNew: gettextCatalog.getString('New time series type'),
      titleEdit: gettextCatalog.getString('Edit time series type'),
      entity: 'time-series-types',
      attribute: {
        entity: 'time-series-type-attributes',
        method: 'read'
      },
      paramName: 'id',
      redirect:
          'configurations-company-resources-time-series-time-series-types-view',
      cancelStringEdit:
          'configurations-company-resources-time-series-time-series-types-view',
      cancelString: 'configurations-company-resources-time-series-list'
    };
    break;
  case 'configurations-company-resources-measuring-point-types-new':
  case 'configurations-company-resources-measuring-point-types-edit':
    vm.entityProperties = {
      titleNew: gettextCatalog.getString('New measuring point type'),
      titleEdit: gettextCatalog.getString('Edit measuring point type'),
      entity: 'measuringpoint-types',
      attribute: {
        entity: 'measuringpoint-type-attributes',
        method: 'read'
      },
      paramName: 'id',
      redirect: 'configurations-company-resources-measuring-point-types-view',
      cancelStringEdit:
          'configurations-company-resources-measuring-point-types-view',
      cancelString:
          'configurations-company-resources-measuring-point-types-list'
    };
    break;
  }

  init();

  function init() {
    vm.attributes = [];
    vm.attributesHeader = SfeHeader.constructFormHeader(
      'primary',
      gettext('Attributes')
    );
    vm.dataConfig = {
      PH16: true
    };
    // if we're in edit mode
    if ($state.params.id) {
      MetadataService.Loading(true);
      vm.editMode = true;
      vm.entityTypeId = $state.params.id;
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        vm.entityProperties.titleEdit,
        vm.entityProperties.cancelStringEdit,
        {
          id: vm.entityTypeId
        }
      );
      readEntityType();
    } else {
      vm.editMode = false;
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        vm.entityProperties.titleNew,
        vm.entityProperties.cancelString
      );
      vm.dataConfig.dataObj = {
        name: '',
        description: '',
        systemTags: []
      };
    }
    // set the dataConfig object based on state
    vm.dataConfig.data = [
      {
        placeholder: gettext('Name'),
        name: 'name',
        type: 'text',
        componentType: 'multilanguage',
        required: true
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        maxlength: 500,
        type: 'text',
        required: false,
        componentType: 'multilanguage'
      }
    ];

    switch (stateName) {
    case 'configurations-company-resources-assets-asset-types-new':
    case 'configurations-company-resources-assets-asset-types-edit':
      vm.dataConfig.data.push(
        {
          placeholder: gettext('External Code'),
          name: 'externalCode',
          componentType: 'textArea',
          maxlength: 50,
          type: 'text',
          required: false
        },
        {
          placeholder: gettext('Code'),
          name: 'code',
          componentType: 'textArea',
          maxlength: 50,
          type: 'text',
          required: true
        },
        {
          componentType: 'autocompleteDialog',
          edit: false,
          name: 'assetGroup',
          configuration: {
            query: {
              entity: 'asset-groups',
              method: 'read'
            },
            entity: 'asset-groups',
            dialogConfiguration: {
              multiple: false,
              title: gettext('Select Asset Group')
            },
            floatingLabel: gettext('Select Asset Group'),
            searchParamName: 'filter',
            required: true
          }
        }
      );
      break;
    case 'configurations-company-resources-locations-location-types-new':
    case 'configurations-company-resources-locations-location-types-edit':
      vm.dataConfig.data.push(
        {
          placeholder: gettext('Code'),
          name: 'code',
          componentType: 'textField',
          type: 'text'
        },
        {
          placeholder: gettext('External Code'),
          name: 'externalCode',
          componentType: 'textField',
          type: 'text',
          required: false
        },
        {
          componentType: 'checkBox',
          label: gettext('External'),
          name: 'isExternal'
        }
      );

      break;
    case 'configurations-company-resources-time-series-time-series-types-new':
    case 'configurations-company-resources-time-series-time-series-types-edit':
      vm.dataConfig.data.push(
        {
          placeholder: gettext('Code'),
          name: 'code',
          componentType: 'textArea',
          type: 'text',
          required: true
        },
        {
          placeholder: gettext('External Code'),
          name: 'externalCode',
          componentType: 'textField',
          type: 'text',
          required: false
        },
        {
          componentType: 'autocompleteDialog',
          edit: vm.editMode,
          name: 'readingType',
          configuration: {
            codelist: 'readingTypes',
            entity: 'readingTypes',
            dialogConfiguration: {
              multiple: false,
              title: gettext('Reading Type')
            },
            floatingLabel: gettext('Reading Type'),
            searchParamName: 'name',
            required: true,
            change: readingTypeChange
          }
        },
        {
          componentType: 'autocompleteDialog',
          edit: vm.editMode,
          name: 'pairedTimeSeriesType',
          hide: true,
          showParam: 'showPairedMeasurementType',
          configuration: {
            query: {
              entity: 'time-series-types',
              method: 'read'
            },
            entity: 'time-series-types',
            dialogConfiguration: {
              multiple: false,
              title: gettext('Select paired time series type')
            },
            filterFn: items => {
              if (Array.isArray(items) && $state.params.id) {
                return items.filter(item => item._id != $state.params.id);
              }
              return items;
            },
            floatingLabel: gettext('Select paired time series type'),
            searchParamName: 'filter',
            required: false,
            createRedirect: {
              state:
                  'configurations-company-resources-time-series-time-series-types-new'
            }
          }
        },
        {
          componentType: 'checkBoxesLinear',
          networkModel: {
            entity: 'system-tags',
            method: 'read'
          },
          name: 'systemTags',
          title: gettext('System Tags'),
          compareFn: function(item1, item2) {
            return item1._id === item2._id;
          }
        }
      );

      break;
    }

    function readingTypeChange() {
      vm.dataConfig.dataObj.showPairedMeasurementType = vm.dataConfig.dataObj
        .readingType
        ? vm.dataConfig.dataObj.readingType.id === 1
        : false;
    }

    if (
      stateName ===
        'configurations-company-resources-measuring-point-types-new' ||
      stateName ===
        'configurations-company-resources-measuring-point-types-edit'
    ) {
      vm.dataConfig.data.push(
        {
          placeholder: gettext('Code'),
          name: 'code',
          componentType: 'textArea',
          type: 'text',
          required: true
        },
        {
          placeholder: gettext('External Code'),
          name: 'externalCode',
          componentType: 'textField',
          type: 'text',
          required: false
        }
      );
    }
  }

  function addAttribute() {
    vm.attributes.push({});
  }

  function removeAttribute(index) {
    vm.attributes.splice(index, 1);
  }

  vm.attributeAutocomplete = {
    query: {
      entity: 'domain-attributes',
      method: 'read'
    },
    filterFn: function(items) {
      if (items && vm.attributes) {
        var attributeExist;
        return items.filter(function(attributeItem) {
          attributeExist = vm.attributes.find(function(attribute) {
            return attribute.model
              ? attribute.model._id === attributeItem._id
              : false;
          });
          return !attributeExist;
        });
      } else {
        return items;
      }
    },
    floatingLabel: gettext('Select attribute'),
    searchParamName: 'filter',
    required: true,
    entity: 'domain-attributes',
    dialogConfiguration: {
      multiple: false
    },
    selectedParam: 'id'
  };

  function listAllDomains(attributes, page, result, deferred) {
    if (!deferred) {
      deferred = $q.defer();
    }

    DomainAttributeModel.read({
      _id: attributes,
      page: page
    }).then(function(res) {
      if (!result) {
        result = res.data;
      } else {
        result = result.concat(res.data);
      }
      if (
        res.pagination['per_page'] * res.pagination.page <
        res.pagination.total
      ) {
        listAllDomains(attributes, page + 1, result, deferred);
      } else {
        deferred.resolve(result);
      }
    });
    return deferred.promise;
  }

  function listAttributesFirstTime(attributes) {
    // ko bomo imeli mergan populate notr lahko uporabimo populate
    listAllDomains(attributes, 1).then(
      function(res) {
        if (Array.isArray(res)) {
          vm.attributes = attributes.map(function(attribute) {
            return {
              model: res.find(function(item) {
                return attribute === item._id;
              })
            };
          });
        }
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function readEntityType() {
    entityTypeObject;
    delete entityTypeObject._id;
    delete entityTypeObject.__v;
    vm.dataConfig.dataObj = {
      ...entityTypeObject
    };
    const selectedLang = LocalizationService.GetSelectedLangNetworkCode();
    // tags are needed only in time series configuration
    if (
      stateName ===
      'configurations-company-resources-time-series-time-series-types-edit'
    ) {
      fetchEntitySystemTags(function(err, tags) {
        if (!err) {
          if (tags) {
            vm.dataConfig.dataObj.systemTags = tags.map(function(tag) {
              return tag.systemTag;
            });
          }
        }
      });
    }
    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(
        'Edit ' +
          (entityTypeObject.name != null
            ? entityTypeObject.name[selectedLang]
            : ''),
        entityTypeObject.description != null
          ? entityTypeObject.description[selectedLang]
          : ''
      );
    });
    listEntityTypeAttributes();
  }

  function fetchEntitySystemTags(callback) {
    SystemTagEntityModel.custom
      .readWithTag({
        entityId: $state.params.id
      })
      .then(
        function(res) {
          callback(null, res.data);
        },
        function(err) {
          AlertingService.Error(err);
          callback(null, []);
        }
      );
  }

  function listEntityTypeAttributes() {
    var method = CrawlerMethods.getMethod(vm.entityProperties.attribute);
    method({
      type: vm.entityTypeId,
      order: '_id'
    }).then(
      function(res) {
        if (Array.isArray(res.data)) {
          var attributes = res.data.map(function(attribute) {
            return attribute.domainAttribute;
          });
          listAttributesFirstTime(attributes);
        }
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  // create and update functions

  function createOrUpdate() {
    vm.sendingRequest = true;
    createOrUpdateService
      .simpleCall(
        {
          entity: vm.entityProperties.entity,
          method: vm.editMode ? 'custom.updateV1' : 'custom.createV1'
        },
        vm.editMode,
        vm.entityTypeId,
        dataToSave(),
        vm.entityProperties.redirect,
        vm.entityProperties.paramName
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function dataToSave() {
    const entityTypeFormObject = MultiLanguage.constructForm(
      vm.dataConfig.data,
      vm.dataConfig.dataObj
    );
    const obj = {
      name: entityTypeFormObject.name,
      description: entityTypeFormObject.description,
      externalCode: vm.dataConfig.dataObj.externalCode,
      code: vm.dataConfig.dataObj.code
    };

    if (
      stateName ==
        'configurations-company-resources-locations-location-types-new' ||
      stateName ==
        'configurations-company-resources-locations-location-types-edit'
    ) {
      obj.isExternal = vm.dataConfig.dataObj.isExternal;
    }

    if (
      stateName == 'configurations-company-resources-assets-asset-types-new' ||
      stateName == 'configurations-company-resources-assets-asset-types-edit'
    ) {
      obj.assetGroup = vm.dataConfig.dataObj.assetGroup
        ? vm.dataConfig.dataObj.assetGroup._id
        : null;
    }

    if (
      stateName ==
        'configurations-company-resources-time-series-time-series-types-new' ||
      stateName ==
        'configurations-company-resources-time-series-time-series-types-edit'
    ) {
      obj.pairedTimeSeriesType = vm.dataConfig.dataObj.pairedTimeSeriesType
        ? vm.dataConfig.dataObj.pairedTimeSeriesType._id
        : null;
      obj.readingType = vm.dataConfig.dataObj.readingType
        ? vm.dataConfig.dataObj.readingType.id
        : null;
      if (obj.readingType !== 1) {
        obj.pairedTimeSeriesType = null;
      }
    }

    obj.attributes = _.map(vm.attributes, function(attribute) {
      return {
        domainAttribute: attribute.model._id,
        isMulti: false,
        isRequired: false
      };
    });

    if (
      (stateName ===
        'configurations-company-resources-time-series-time-series-types-new' ||
        stateName ===
          'configurations-company-resources-time-series-time-series-types-edit') &&
      vm.dataConfig.dataObj.systemTags
    ) {
      obj.systemTags = vm.dataConfig.dataObj.systemTags.map(function(tag) {
        return {
          _id: tag._id
        };
      });
    }
    return obj;
  }
  vm.actions = [
    {
      title: vm.editMode ? gettext('Update') : gettext('Create'),
      fn: vm.createOrUpdate,
      color: 'primary',
      raised: true,
      disabledFn: () => {
        return $scope.LocationAttributeForm.$invalid || vm.sendingRequest;
      }
    }
  ];
}

export default NewEntityTypeConfigController;
