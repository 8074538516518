module.exports = {
  entity: 'scheduler-datasource-datapoints',
  path: 'scheduler-datasource-datapoints',
  name: {
    en: 'Scheduler datasource data points',
    sl_SI: 'Urnik podatkovnega vira podatkovnih točk'
  },
  networkModel: {
    entity: 'scheduler-datasource-datapoints',
    read: 'read'
  },
  scope: 'tis_be:scheduler-datasource-datapoints',
  assigned: 'tis_be:scheduler-datasource-datapoints',
  dialog: {
    title: {
      en: 'Select scheduler datasource data points',
      sl_SI: 'Izberite urnik podatkovnega vira podatkovnih točk'
    },
    listInfo: {
      en: 'Select scheduler datasource data points from the list below',
      sl_SI:
        'Izberiteurnik podatkovnega vira podatkovnih točk s spodnjega seznama'
    }
  }
}
