module.exports = {
  entity: 'dashboards',
  path: 'dashboards',
  name: {
    en: 'Dashboard',
    sl_SI: 'Nadzorna plošča'
  },
  networkModel: {
    entity: 'dashboards',
    read: 'read'
  },
  scope: 'tis_be:dashboards',

  dialog: {
    title: {
      en: 'Select dashboard',
      sl_SI: 'Izberite nadzorno ploščo'
    },
    listInfo: {
      en: 'Select dashboard from the list below',
      sl_SI: 'Izberite nadzorno ploščo s spodnjega seznama'
    }
  }
}
