import template from './sfe-grid-item-element.component.html';
import './sfe-grid-item-element.scss';
/**
 * @ngdoc component
 * @name common.sfe-grid-item-element
 * @description description.
 * @param {object} element - object containg about the element being shown
 * @param {object} functions - object containing functions passed from parent to component
 * @param {string} cacheInvalidationTime - cache invalidation time
 * @param {boolean} mxClientIsLoaded - tells us if the mxClieant has loaded
 * @param {boolean} highchartsIsLoaded - tells us if highcharts has loaded
 * @example
 * <sfe-grid-item-element
 *   element="element"
 *   functions="vm.config.function"
 *   cache-invalidation-time="vm.cacheInvalidationTime"
 *   mx-client-is-loaded="vm.mxClientIsLoaded"
 *   highcharts-is-loaded="vm.highchartsIsLoaded"
 * ></sfe-grid-item-element>
 */
export default {
  template,
  bindings: {
    element: '<',
    functions: '<',
    cacheInvalidationTime: '<?',
    mxClientIsLoaded: '<',
    highchartsIsLoaded: '<'
  },
  controller: SfeGridItemElementController,
  controllerAs: 'vm'
};

SfeGridItemElementController.$inject = [
  '$smartAuth',
  'AlertingService',
  'utilService',
  'TimeSeriesLastValueService',
  'VisualizationModel',
  'dashboardTemplates'
];
function SfeGridItemElementController(
  $smartAuth,
  AlertingService,
  utilService,
  TimeSeriesLastValueService,
  VisualizationModel,
  dashboardTemplates
) {
  var vm = this;
  vm.$onChanges = function(changes) {
    if (changes.element && vm.element.dashboardCardType) {
      const templateNames = Object.keys(dashboardTemplates);
      const dashboardCardType = templateNames.find(
        template => template == vm.element.dashboardCardType
      );
      if (dashboardCardType == null) {
        vm.dashboardCardTypeError = true;
      } else {
        init();
      }
    }
  };

  /**
  * @description component initilization function
  * @function

  */
  async function init() {
    if (vm.element.dashboardCardType === 13) {
      try {
        const response = await $smartAuth.profile();
        vm.filterObject = {
          inProgress: true,
          currentAssignee: response._id
        };
      } catch (err) {
        AlertingService.Error(err);
      }
    }
    if (
      vm.element.dashboardCardType == 6 &&
      vm.element.entities &&
      vm.element.entities.length > 0
    ) {
      vm.element.pointId = vm.element.entities[0].entityId;
    }
    switch (vm.element.dashboardCardType) {
    case 12:
      vm.datapointValuesGetter = TimeSeriesLastValueService.getCacheWrapper(
        vm.cacheInvalidationTime
      );
      vm.visualizationGetter = () => {
        return VisualizationModel.read(
          {
            id: vm.element.entities[0].entityId
          },
          vm.cacheInvalidationTime
        );
      };
      break;
    case 16:
      if (vm.element.entities && vm.element.entities.length > 0) {
        if (vm.element.entities[0].entityId) {
          vm.element.pictureUrl =
              utilService.getApiHost +
              '/pictures/' +
              vm.element.entities[0].entityId +
              '/image';
        }
      }
      break;
    case 20:
      // eslint-disable-next-line no-case-declarations
      let center;
      if (vm.element.details.xCoordinate && vm.element.details.yCoordinate) {
        center = {
          easting: vm.element.details.xCoordinate,
          northing: vm.element.details.yCoordinate
        };
      }
      vm.element.details.scale = vm.element.details.scale || 1000;
      vm.element.gisData = {
        map: vm.element.details.gisMapId,
        center,
        markCenter: false
      };
    }
  }
}
