module.exports = {
  entity: 'databases',
  path: 'databases',
  name: {
    en: 'Database',
    sl_SI: 'Baza podatkov'
  },
  networkModel: {
    entity: 'databases',
    read: 'read'
  },
  scope: 'tis_be:databases',

  dialog: {
    title: {
      en: 'Select database',
      sl_SI: 'Izberite bazo podatkov'
    },
    listInfo: {
      en: 'Select database from the list below',
      sl_SI: 'Izberite bazo podatkov s spodnjega seznama'
    }
  }
}
