TariffModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function TariffModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'tariffs'
  };

  var networkConfigurationAllLang = {
    host: 'api',
    path: 'tariffs',
    allLang: true
  };

  var measurementTypeSteps = [
    {
      entity: 'tariffs',
      valueParams: ['tariff'],
      queryParams: ['_id'],
      method: 'read',
      methodType: 'populateArray'
    }
  ];

  async function populateWithTariffs(items) {
    return await CrawlerMethods.populate(items, measurementTypeSteps);
  }

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readAllLang: ModelUtilities.crud(
        'GET',
        networkConfigurationAllLang,
        modelStorage
      ),
      populateWithTariffs
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default TariffModel;
