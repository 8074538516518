import routes from './dashboard.routes';

// components
import sfeDashboard from './components/sfe-dashboard/sfe-dashboard.component';

// controllers
import DashboardController from './pages/dashboard/dashboard.controller';
import NewDashboardController from './pages/dashboard/new-dashboard.controller';
import NewDashboardPlaylistController from './pages/dashboard-playlist/new-dashboard-playlist.controller';
import RunDashboardPlaylistController from './pages/dashboard-playlist/run-dashboard-playlist.controller';
import EnergySegmentsController from './pages/energy-segments-dashboard/energy-segments-dashboard.controller';

// directives
import sfeDashboardLocationMap from './directives/dashboard-map/sfe-dashboard-locations-map.directive';
import energySegments from './directives/energy-segments/energy-segments.directive';
import sfeDashboardValuesCard from './directives/sfe-dashboard-measurement-card/sfe-dashboard-values-card.directive';
import sfeMeasurementCardSelector from './directives/sfe-dashboard-measurement-card-selector/sfe-dashboard-measurement-card-selector.directive';

// factories
import TimeSeriesValuesDashboardCard from './factories/TimeSeriesValuesDashboardCard.service';

// services
import dashboardTemplates from './services/dashboard-templates/dashboard-templates.service';

// configurations
import DashboardConfiguration from './configurations/dashboard-configuration';
import DashboardPlaylistConfiguration from './configurations/dashboard-playlist-configuration';

// network models
import DashboardModel from './network-models/Dashboard.model';
import DashboardCardModel from './network-models/DashboardCard.model';
import DashboardPlaylistModel from './network-models/DashboardPlaylist.model';
import DashboardUserModel from './network-models/DashboardUser.model';

export default {
  routes,
  components: {
    sfeDashboard
  },
  controllers: {
    DashboardController,
    NewDashboardController,
    NewDashboardPlaylistController,
    RunDashboardPlaylistController,
    EnergySegmentsController
  },
  directives: {
    sfeDashboardLocationMap,
    energySegments,
    sfeDashboardValuesCard,
    sfeMeasurementCardSelector
  },
  factories: {
    TimeSeriesValuesDashboardCard,
    DashboardConfiguration,
    DashboardPlaylistConfiguration
  },
  services: {
    dashboardTemplates,
    DashboardModel,
    DashboardCardModel,
    DashboardPlaylistModel,
    DashboardUserModel
  }
};
