import './tango-timeseries-group-filtration.scss';
import template from './tango-timeseries-group-filtration.component.html';

/**
 * @ngdoc component
 * @name data.tangoTimeSeriesGroupFiltration
 * @description Displays filter on the right side of the screen and allows to dnd items to a new list.
 * @param {Object} options
 *     @param {String} groupId name of the list group where dragging is allowed
 *     @param {String} fieldId name of the field used to get and set items (select all/add)
 * @param {Object} api must have setValue and getValues functions
 * @example
 * <tango-timeseries-group-filtration
 *   options="vm.options"
 *   api="vm.api"
 * ></tango-timeseries-group-filtration>
 */
export default {
  template,
  bindings: {
    options: '<',
    api: '<',
    filtersAreShown: '=?'
  },
  controller: TangoTimeseriesGroupFiltration,
  controllerAs: 'vm',
  bindToController: true
};

TangoTimeseriesGroupFiltration.$inject = [
  'TimeSeriesConfiguration',
  'gettextCatalog',
  'gettext',
  '$timeout',
  'TranslationService'
];

function TangoTimeseriesGroupFiltration(
  TimeSeriesConfiguration,
  gettextCatalog,
  gettext,
  $timeout,
  TranslationService
) {
  const vm = this;
  vm.filtersAreShown = true;

  vm.toggleFilters = async value => {
    vm.filtersAreShown = value;
    await $timeout();
    window.dispatchEvent(new Event('resize'));
  };

  vm.resetAction = resetAction;
  //fab button title
  vm.openFilters = gettextCatalog.getString('Open time series filters');
  vm.dragIcon = {
    type: 2,
    name: 'drag_indicator'
  };

  vm.displayTitle = item => {
    let schedulerClassificationAbbreviation = '';
    if (item != null) {
      if (
        item.dataScheduler != null &&
        item.dataScheduler.scheduleClassification
      ) {
        let schedulerClassification = TranslationService.GetCollectionById(
          'codelists.scheduleClassifications',
          item.dataScheduler.scheduleClassification
        );
        if (
          schedulerClassification != null &&
          schedulerClassification.name != ''
        ) {
          schedulerClassificationAbbreviation = ` - ${schedulerClassification.name}`;
        }
      }
      return {
        text: `${item.name} ${schedulerClassificationAbbreviation}`
      };
    }
    return { text: gettextCatalog.getString('Unknown') };
  };

  vm.$onChanges = changes => {
    if (changes.options && vm.options != null && vm.options.groupId != null) {
      initializeSortListConfig();
      initializeListOptions(vm.options.groupId);
    }
    if (changes.api) {
      resetAction();
    }
  };
  //INIT ACTIONS
  resetAction();

  /**
   * @description returns add action object.
   * @function
   * @return {Object}
   */
  function getAddAction() {
    return {
      title: gettext('Add'),
      /**
       * @description gets currently selected items from api merges them with newly selected items and updates model using api.
       * @function
       */
      fn: () => {
        let currentItems = vm.api.getValues();
        let itemsToAdd = vm.listItems.reduce((result, item) => {
          if (item.selectedToDrag) {
            result = [...result, item];
          }
          return result;
        }, []);
        vm.api.setValue(vm.options.fieldId, [...currentItems, ...itemsToAdd]);
        vm.listItems = vm.listItems.map(item => ({
          ...item,
          selectedToDrag: false
        }));
        resetAction();
      },
      color: 'success',
      opaqueButton: true,
      /**
       * @description disables add button in no items have been selected.
       * @function
       * @return {Boolean}
       */
      disabledFn: () => {
        if (Array.isArray(vm.listItems)) {
          return !vm.listItems.reduce((disabled, item) => {
            return disabled || item.selectedToDrag;
          }, false);
        }
        return true;
      }
    };
  }
  /**
   * @description returns select all action object.
   * @function
   * @return {Object}
   */
  function getSelectAllAction() {
    return {
      __isSelect: true,
      title: gettext('Select all'),
      opaqueButton: true,
      /**
       * @description description.
       * @function
       * @param {dataType} binding/paramName
       * @return {dataType}
       */
      fn: () => {
        let selectAction = vm.actions[0];
        let selectedToDrag = false;
        let title = gettextCatalog.getString('Select all');
        if (selectAction.__isSelect) {
          selectedToDrag = true;
          title = gettextCatalog.getString('Deselect all');
        }
        selectAction.__isSelect = !selectAction.__isSelect;

        vm.listItems = vm.listItems.map(item => ({
          ...item,
          selectedToDrag
        }));
        selectAction.title = title;
        // change select all action title
        vm.actions = vm.actions.map((action, index) => {
          if (index == 0) {
            return selectAction;
          }
          return action;
        });
      }
    };
  }
  /**
   * @description sets actions to its default state.
   * @function
   * @return {Array}
   */
  function resetAction() {
    if (vm.api != null && typeof vm.api == 'object') {
      vm.actions = [getSelectAllAction(), getAddAction()];
    } else {
      vm.actions = [];
    }
  }
  /**
   * @description creates configuration for sort list.
   * @function
   * @param {String} groupName name of the group
   */
  function initializeSortListConfig(groupName) {
    vm.sortConfig = {
      group: {
        name: groupName,
        pull: 'clone',
        put: false
      },
      handle: '.filtration-container__items__item__handle', //sets handle class, located in tango-timeseries-group-filtration-item component
      animation: 100,
      sort: false,
      filter: '.filtration-container__items__item--disable-drag',
      ghostClass: 'filtration-container__items__item--ghost',
      dragClass: 'filtration-container__items__item--drag',
      chosenClass: 'filtration-container__items__item--chosen'
    };
  }

  /**
  * @description initializes timeseries list data. TODO change to timeseries configuration
  * @function

  */
  function initializeListOptions() {
    const listConfig = angular.copy(TimeSeriesConfiguration);
    vm.filters = listConfig.filter;
    vm.query = listConfig.api.query;
    vm.limitOptions = [15, 20, 25, 30];
    vm.sfeOrder = '-_id';
  }
}
