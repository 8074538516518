module.exports = {
  entity: 'physical-quantities',
  path: 'physical-quantities',
  name: {
    en: 'Physical quantities',
    sl_SI: 'Fizične količine'
  },
  networkModel: {
    entity: 'physical-quantities',
    read: 'read'
  },
  selectedParamName: 'id',
  scope: 'tis_be:physical-quantities',

  dialog: {
    title: {
      en: 'Select physical quantity',
      sl_SI: 'Izberite fizikalno količino'
    },
    listInfo: {
      en: 'Select physical quantity from the list below',
      sl_SI: 'Izberite fizikalno količino s spodnjega seznama'
    }
  }
}
