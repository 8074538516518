ComponentEditorController.$inject = [
  '$scope',
  'ItemSelector',
  'TimeSeriesLastValueService'
];

function ComponentEditorController(
  $scope,
  ItemSelector,
  TimeSeriesLastValueService
) {
  var vm = this;
  vm.loaded = true;
  $scope.measurementSelector = function() {
    return ItemSelector.open(['time-series']);
  };
  $scope.measurementGetter = TimeSeriesLastValueService.get;
}

export default ComponentEditorController;
