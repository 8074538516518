// styles
import '../node_modules/angular-material/angular-material.css';
import '../node_modules/angular-material-data-table/dist/md-data-table.css';
import '../node_modules/fullcalendar/dist/fullcalendar.css';
import '../node_modules/@smartis/smart-visualisation/dist/visualizations.min.css';
import '../node_modules/flag-icon-css/css/flag-icon.css';
import '../node_modules/@smartis/petrol-icons/petrol-icons.css';
import '../node_modules/font-awesome/css/font-awesome.css';
import '../node_modules/@smartis/smartis-frontend-components/dist/smartis-frontend-components.min.css';
import '../node_modules/@smartis/weather-icons/weather-icons.css';
import '../node_modules/@smartis/weather-icons/weather-icons-wind.css';
import '../node_modules/quill/dist/quill.snow.css';
import '../node_modules/flatpickr/dist/flatpickr.min.css';
import '@simonwep/pickr/dist/themes/nano.min.css';
// ag-grid
import '../node_modules/ag-grid-community/dist/styles/ag-grid.css';
import '../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css';

// dependencies
import 'lodash';
import { DateTime } from 'luxon';
import 'fullcalendar';

// angular
import angular from 'angular';
import 'angular-animate';
import 'angular-aria';
import 'angular-drag-and-drop-lists';
import 'angular-file-upload';
import 'angular-gettext';
import 'angular-material';
import 'angular-material-data-table';
import 'angular-messages';
import 'angular-resource';
import 'angular-route';
import 'angular-sanitize';
import 'angular-ui-calendar';
import '@uirouter/angularjs';
import 'angularjs-gauge';
import 'angular-cache';
import 'ng-quill';
import 'ngmap';
import 'redux';
import 'ng-redux';

//moment
import moment from 'moment-timezone';
window.moment = moment;

//later
import later from '@breejs/later';
window.later = later;

// smartis
import '@smartis/ng-oauthsmartis';
import '@smartis/smart-visualisation';
import '@smartis/smartis-frontend-components/dist/smartis-frontend-components.min.js';

// smartis-frontend-components dependencies
import Pickr from '@simonwep/pickr';
window.Pickr = Pickr;
import { Grid } from 'ag-grid-community';
window.Grid = Grid;

// overrides gettext getPlurals factory because of wrong slovenian translations
import './core/factories/getPlurals.factory';
import app from './config';
import { GenerateRouteConfig } from './routes';

// reducers
import { combineReducers } from 'redux';
import timeSeries from './data/redux/time-series/reducer/times-series.reducer';
import timeSeriesConfigurations from './data/redux/time-series-configurations/reducer/times-series-configurations.reducer';
import externalReaderFlow from './data/redux/external-reader-flow/reducer/external-reader-flow.reducer';
import externalWriterFlow from './data/redux/external-writer-flow/reducer/external-writer-flow.reducer';
import calculatedFlow from './data/redux/calculated-flow/reducer/calculated-flow.reducer';
import monitorAndTargetAnalysisFlow from './data/redux/mt-analysis-flow/reducer/mt-analysis-flow.reducer';
import maintenanceFlow from './data/redux/maintenance-flow/reducer/maintenance-flow.reducer';
import timeSeriesProcessingValues from './data/redux/time-series-processing-values/reducer/time-series-processing-values.reducer';

// Start by defining the main module and adding the module dependencies
angular.module(
  app.applicationModuleName,
  app.applicationModuleVendorDependencies
);

angular
  .module(app.applicationModuleName)
  .config(bootstrapConfig)
  .run([
    '$rootScope',
    '$smartAuth',
    '$state',
    '$q',
    'MetadataService',
    'StorageService',
    'LocalizationService',
    'TranslationService',
    'gettextCatalog',
    'GisAuth',
    '$theming',
    '$mdDialog',
    '$animate',
    'AuthorizationToEditService',
    'CachingControl',
    'CachingParams',
    '$transitions',
    function(
      $rootScope,
      $smartAuth,
      $state,
      $q,
      MetadataService,
      StorageService,
      LocalizationService,
      TranslationService,
      gettextCatalog,
      GisAuth,
      $theming,
      $mdDialog,
      $animate,
      AuthorizationToEditService,
      CachingControl,
      CachingParams,
      $transitions
    ) {
      $animate.enabled(false);
      // set default theme
      $theming.setGlobalTheme('tango');

      // function set
      function CheckIfAuthorized(toState, transition, failureToInitialize) {
        if (!$smartAuth.loggedIn() && toState.name !== 'unauthenticated') {
          transition.abort();
          $state.go('unauthenticated');
        }
        if (failureToInitialize) {
          window.closeLoadingScreen();
        }
      }

      function CheckOauthProfile(toState, transition) {
        if (window.initLoading_UpdateProgress != null) {
          window.initLoading_UpdateProgress();
        }
        var deferred = $q.defer();
        $smartAuth.profile().then(
          function() {
            GisAuth.init();
            CheckIfAuthorized(toState, transition);
            deferred.resolve();
          },
          function() {
            deferred.reject();
          }
        );
        return deferred.promise;
      }

      function InitialLoad(transition) {
        const toState = transition.to();
        const toParams = transition.params();
        TranslationService.Initiate();
        if (StorageService.initiatedQuickcheck()) {
          // storage service inited
          // set theme
          var selectedTheme = StorageService.get('selectedTheme');

          $theming.setGlobalTheme(selectedTheme);
          CheckOauthProfile(toState, transition);
        } else {
          // Storage service not yet initialized, true Initiation
          if (toState.name !== 'unauthenticated') {
            transition.abort();
          }

          CheckOauthProfile(toState, transition)
            .then(function() {
              window.initLoading_UpdateProgress();
              // logged in
              return StorageService.initiate();
            })
            .then(function() {
              return LocalizationService.Initiate();
            })
            .then(function() {
              return AuthorizationToEditService.initiate();
            })
            .then(function() {
              CachingControl.SetCache(StorageService.getGlobalCache());
              CachingControl.StartInvalidatingCache();
              CachingParams.StartInvalidatingParams();

              var newToState = toState || 'home';
              var newToStateParams = toParams || {};
              var lastVisitedState = StorageService.get('lastVisitedState');
              var lastState = {
                state: lastVisitedState
                  ? StorageService.get('lastVisitedState').state
                  : null,
                params: lastVisitedState
                  ? StorageService.get('lastVisitedState').params
                  : null
              };
              if (lastState.state && toState.name === 'redirect') {
                newToState = lastState.state.name;
                newToStateParams = lastState.params;
              } else if (toState.name === 'redirect') {
                newToState = 'home';
                newToStateParams = {};
              }

              $state.go(newToState, newToStateParams);
            })
            .catch(function(err) {
              CheckIfAuthorized(toState, transition, true);
              // eslint-disable-next-line no-console
              console.warn(err);
            });
        }
      }
      $transitions.onStart({}, InitialLoad);

      $transitions.onSuccess({}, transition => {
        const toState = transition.to();
        const toStateParams = transition.params();
        // close all opened dialogs on state change
        $mdDialog.cancel();
        if (toState.doNotSaveState !== true) {
          StorageService.save('lastVisitedState', {
            state: toState,
            params: toStateParams
          });
        }

        if (toState.name == 'data-time-series-list') {
          //Override side menu title - because we cannot use angularjs modules in route files
          toState.pageTitle = gettextCatalog.getPlural(
            3,
            'Time Series',
            'Time Series',
            {}
          );
        }
        MetadataService.ChangeMetadata(
          gettextCatalog.getString(toState.pageTitle),
          toState.pageDescription
        );
      });

      $rootScope.$on('$locationChangeSuccess', function() {
        document.getElementById('main-content').scrollTop = 0;
      });
    }
  ]);

bootstrapConfig.$inject = [
  '$locationProvider',
  '$smartAuthProvider',
  '$mdDateLocaleProvider',
  '$resourceProvider',
  '$mdAriaProvider',
  '$sceDelegateProvider',
  '$compileProvider',
  '$httpProvider',
  '$visualizationBackendProvider',
  '$visualizationAssetsProvider',
  '$ngReduxProvider'
];
function bootstrapConfig(
  $locationProvider,
  $smartAuthProvider,
  $mdDateLocaleProvider,
  $resourceProvider,
  $mdAriaProvider,
  $sceDelegateProvider,
  $compileProvider,
  $httpProvider,
  $visualizationBackend,
  $visualizationAssets,
  $ngReduxProvider
) {
  // NO DEBUG IN PRODUCTION
  if (window.NODE_ENV === 'production') {
    $compileProvider.debugInfoEnabled(false);
    $compileProvider.commentDirectivesEnabled(false);
    $compileProvider.cssClassDirectivesEnabled(false);
  }
  $httpProvider.useLegacyPromiseExtensions = false;

  $mdAriaProvider.disableWarnings();
  $mdDateLocaleProvider.formatDate = function(date) {
    var newDate;
    if (date) {
      newDate = DateTime.fromJSDate(new Date(date));
    }
    return newDate && newDate.isValid ? newDate.toFormat('dd. MM. yyyy') : '';
  };
  $mdDateLocaleProvider.parseDate = function(dateString) {
    var formatedDate;
    if (dateString) {
      formatedDate = DateTime.fromFormat(dateString, 'dd. MM. yyyy');
    }
    return formatedDate && formatedDate.isValid
      ? formatedDate.toJSDate()
      : new Date(NaN);
  };

  $resourceProvider.defaults.cancellable = true;
  $locationProvider.html5Mode(true).hashPrefix('!');

  // REDUX
  // set timeout in case more then one reducer resolves at the same time
  $ngReduxProvider.config.debounce = {
    wait: 1000,
    maxWait: 2000
  };

  $ngReduxProvider.createStoreWith(
    combineReducers({
      timeSeries,
      timeSeriesConfigurations,
      externalReaderFlow,
      externalWriterFlow,
      calculatedFlow,
      monitorAndTargetAnalysisFlow,
      maintenanceFlow,
      timeSeriesProcessingValues
    }),
    [
      'TimeSeriesMiddleware',
      'TimeSeriesConfigurationMiddleware',
      'ExternalReaderFlowMiddleware',
      'ExternalWriterFlowMiddleware',
      'CalculatedFlowMiddleware',
      'MtAnalysisFlowMiddleware',
      'MaintenanceFlowMiddleware'
    ]
  );
  $smartAuthProvider.init({
    clientId: window.customization.oauth.client_id,
    redirectUri: window.customization.oauth.redirect_uri,
    authorizationEndpoint: window.customization.oauth.authorization_enpoint,
    rootDomain: window.customization.oauth.rootDomain
  });
  //set $sce whitelist
  const gisBackendString =
    window.customization.gisBackend.protocol +
    '://' +
    window.customization.gisBackend.host +
    '/**';
  $visualizationBackend.setBackend(
    window.customization.backend.protocol +
      '://' +
      window.customization.backend.host +
      ':' +
      window.customization.backend.port +
      '/' +
      window.customization.backend.prefix
  );
  $visualizationAssets.setAssets('/lib/@smartis/smart-visualisation/');
  const backendString =
    window.customization.backend.protocol +
    '://' +
    window.customization.backend.host +
    '/**';
  const iframeUrl = window.customization.iframeRootUrl + '/**';
  $sceDelegateProvider.resourceUrlWhitelist([
    'self',
    gisBackendString,
    backendString,
    iframeUrl
  ]);
}
// Then define the init function for starting up the application
angular
  .element(document)
  .ready(() =>
    angular.bootstrap(document, [app.applicationModuleName], { strictDi: true })
  );

let allRoutes = [];
app.modules.forEach(angularModule => {
  allRoutes = allRoutes.concat(initModule(angularModule));
});
angular
  .module(app.applicationModuleName)
  .config(GenerateRouteConfig(allRoutes));

function initModule(angularModule) {
  let routes = angularModule.routes || [];
  let components = angularModule.components || {};
  let configs = angularModule.configs || {};
  let controllers = angularModule.controllers || {};
  let directives = angularModule.directives || {};
  let factories = angularModule.factories || {};
  let filters = angularModule.filters || {};
  let services = angularModule.services || {};
  let providers = angularModule.providers || {};
  let constants = angularModule.constants || {};
  let runs = angularModule.runs || {};
  let registered = angular.module(app.applicationModuleName);
  Object.keys(components).forEach(function(key) {
    registered.component(key, components[key]);
  });
  Object.keys(configs).forEach(function(key) {
    registered.config(configs[key]);
  });
  Object.keys(controllers).forEach(function(key) {
    registered.controller(key, controllers[key]);
  });
  Object.keys(directives).forEach(function(key) {
    registered.directive(key, directives[key]);
  });
  Object.keys(factories).forEach(function(key) {
    registered.factory(key, factories[key]);
  });
  Object.keys(filters).forEach(function(key) {
    registered.filter(key, filters[key]);
  });
  Object.keys(services).forEach(function(key) {
    registered.service(key, services[key]);
  });
  Object.keys(providers).forEach(function(key) {
    registered.provider(key, providers[key]);
  });
  Object.keys(constants).forEach(function(key) {
    registered.constant(key, constants[key]);
  });
  Object.keys(runs).forEach(function(key) {
    registered.run(runs[key]);
  });
  return routes;
}
