module.exports = {
  entity: 'schedule-classifications',
  path: 'schedule-classifications',
  entityId: 249,
  name: {
    en: 'Schedule Classifications',
    sl_SI: 'Klasifikacija urnika'
  },
  networkModel: {
    entity: 'schedule-classifications',
    read: 'read'
  },
  scope: 'tis_be:schedule-classifications',

  dialog: {
    title: {
      en: 'Select schedule classification',
      sl_SI: 'Izberite klasifikacijo urnika'
    },
    listInfo: {
      en: 'Select schedule classification from the list below',
      sl_SI: 'Izberite klasifikacijo urnika s spodnjega seznama'
    }
  }
}
