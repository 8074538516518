/**
 * @ngdoc service
 * @name common.ToastService
 * @description Used for showing a toast message.
 * @property {anonymousFunction} showToast - Displays a toast with a message. Can redirect afterwards.
 */
CrudToastFactory.$inject = ['gettextCatalog', 'ToastService'];

export default function CrudToastFactory(gettextCatalog, ToastService) {
  /**
   * @memberof common.CrudToastFactory
   * @function
   * @description Function that triggers the display of the toast.
   * @param {string} type the type of crud toast we want to display
   * @param {Object} redirectObj Used for state redirection. Can have defined 'params' for multiple params or 'paramName' and 'paramValue' for single param redirection.
   * @param {Object} transitionOptions Used in case there are any specific transition options we want to ui-router https://ui-router.github.io/ng1/docs/latest/interfaces/transition.transitionoptions.html
   */
  function toast(type, redirectObject, transitionOptions = {}) {
    const messages = {
      create: gettextCatalog.getString('Item was successfully created'),
      update: gettextCatalog.getString('Item was successfully updated'),
      delete: gettextCatalog.getString('Item was successfully removed'),
      duplicate: gettextCatalog.getString('Item was successfully duplicated')
    };
    ToastService.showToast(messages[type], redirectObject, transitionOptions);
  }

  return {
    toast
  };
}
