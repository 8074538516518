MeasuringpointCounterTypesEntityDateConfiguration.$inject = [
  'gettext',
  '$q',
  'AlertingService',
  'MeasuringpointCounterTypeModel',
  'CrudToastFactory',
  'Formatting'
];

function MeasuringpointCounterTypesEntityDateConfiguration(
  gettext,
  $q,
  AlertingService,
  MeasuringpointCounterTypeModel,
  CrudToastFactory,
  Formatting
) {
  var createObject = {
    validFrom: new Date(new Date().setHours(0, 0, 0, 0)),
    validTo: null
  };

  var configuration = {
    listId: 'measuringPointCounterTypes',
    type: 'single',
    entity: {
      inactiveTitle: gettext('Inactive Counter Types'),
      title: gettext('Counter Types')
    },
    api: {
      query: {
        entity: 'measuringpoint-counter-types',
        method: 'custom.readCounterType'
      },
      activeFilterObject: {
        isActive: true
      },
      inactiveFilterObject: {
        isActive: false
      },
      enrich: Enrich
    },
    create: {
      form: getMeasuringpointBPFormConfig(),
      createObject: angular.copy(createObject),
      createFn: create
    },
    update: update,
    getUpdateObject: getUpdateObject,
    delete: function(item) {
      var deferred = $q.defer();
      MeasuringpointCounterTypeModel.delete({
        id: item._id
      }).then(
        function() {
          CrudToastFactory.toast('delete');
          deferred.resolve();
        },
        function(err) {
          AlertingService.Error(err);
          deferred.reject();
        }
      );
      return deferred.promise;
    },
    title: {
      param: 'nameReadable'
    },
    shortActions: [
      {
        type: 'edit',
        icon: 'mode_edit'
      },
      {
        icon: 'delete',
        type: 'delete'
      }
    ],
    attributes: [
      {
        title: gettext('Valid from'),
        param: 'validFromReadable',
        type: 'date-short'
      },
      {
        title: gettext('Valid to'),
        param: 'validToReadable',
        type: 'date-short'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    item.fetchingObject['validFromReadable'] = {
      fetching: false,
      success: true,
      nodata: false
    };
    item.fetchingObject['validToReadable'] = {
      fetching: false,
      success: true,
      nodata: false
    };

    if (
      item.hasOwnProperty('validFrom') &&
      item.validFrom !== null &&
      item.validFrom !== undefined
    ) {
      item.validFromReadable = item.validFrom;
    } else {
      item.fetchingObject['validFromReadable'].nodata = true;
    }

    if (
      item.hasOwnProperty('validTo') &&
      item.validTo !== null &&
      item.validTo !== undefined
    ) {
      item.validToReadable = item.validTo;
    } else {
      item.fetchingObject['validToReadable'].nodata = true;
    }

    item.nameReadable = item.counterType.name;
    return deferred.promise;
  }

  function getMeasuringpointBPFormConfig() {
    return [
      {
        name: 'counterType',
        edit: true,
        componentType: 'autocompleteDialog',
        configuration: {
          query: {
            entity: 'counter-types',
            method: 'read'
          },
          required: true,
          floatingLabel: gettext('Select Counter type'),
          searchParamName: 'filter',
          entity: 'counter-types',
          createRedirect: {
            state: 'configurations-energy-management-counter-types-list'
          }
        }
      },
      {
        componentType: 'datePickerFromTo',
        from: 'validFrom',
        to: 'validTo',
        valToEmpty: true,
        labelFrom: gettext('Valid from *'),
        labelTo: gettext('Valid to'),
        required: true,
        onlyStartRequired: true,
        idFrom: 'from',
        idTo: 'to'
      }
    ];
  }

  function create(obj, paramObject) {
    var deferred = $q.defer();
    var apiObj = {
      validFrom: Formatting.formatDate(obj.validFrom, '00:00:00'),
      validTo: Formatting.formatDate(obj.validTo, '23:59:59'),
      counterType: obj.counterType._id
    };

    apiObj = Object.assign(apiObj, paramObject);
    MeasuringpointCounterTypeModel.create(apiObj).then(
      function() {
        CrudToastFactory.toast('create');
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );
    return deferred.promise;
  }

  function update(obj, originalItem, paramObject) {
    var deferred = $q.defer();
    var apiObj = {
      validFrom: Formatting.formatDate(obj.validFrom, '00:00:00'),
      validTo: Formatting.formatDate(obj.validTo, '23:59:59') || null,
      counterType: obj.counterType._id
    };

    apiObj = Object.assign(apiObj, paramObject);
    MeasuringpointCounterTypeModel.update(
      {
        id: originalItem._id
      },
      apiObj
    ).then(
      function() {
        CrudToastFactory.toast('update');
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );
    return deferred.promise;
  }

  function getUpdateObject(item) {
    var validFrom = new Date(item.validFrom);
    var validTo =
      item.validTo && new Date(item.validTo) instanceof Date
        ? new Date(item.validTo)
        : null;
    return {
      validFrom: validFrom,
      validTo: validTo,
      counterType: item.counterType
    };
  }

  return configuration;
}
export default MeasuringpointCounterTypesEntityDateConfiguration;
