import { DateTime } from 'luxon';

MasterInvoiceConfiguration.$inject = [
  'gettext',
  'TranslationService',
  'MasterInvoiceListHelper'
];

function MasterInvoiceConfiguration(
  gettext,
  TranslationService,
  MasterInvoiceListHelper
) {
  var configuration = {
    listId: 'masterInvoiceListId',
    entity: {
      singular: gettext('Invoice'),
      plural: gettext('Invoices')
    },
    api: {
      query: {
        entity: 'invoicelist',
        method: 'custom.readList',
        methodTotal: 'custom.readTotal'
      },
      /**
       * @description validates date filters and returns error if needed.
       * @function
       * @param {Bool} initialLoad indicates if it is initial load or search button was clicked
       * @param {Array} filters array of invoice filters
       * @return {dataType}
       */
      prefetchAction: function(filters, initialLoad) {
        var result = {
          success: false
        };
        var from, to;
        var toConfig;
        var fromConfig;
        let billingIntervalIsValid = false;
        if (Array.isArray(filters)) {
          filters.forEach(function(filter) {
            if (filter.type === 'dateCompare') {
              if (filter.paramType === 'lte') {
                toConfig = filter;
                to = filter.date;
              } else if (filter.paramType === 'gte') {
                fromConfig = filter;
                from = filter.date;
              }
              if (from && to) {
                if (from.getTime() < to.getTime()) {
                  to = DateTime.fromJSDate(to);
                  from = DateTime.fromJSDate(from);
                  var timeDiff = to.diff(from, 'years');
                  if (timeDiff && timeDiff.toObject().years <= 2) {
                    billingIntervalIsValid = true;
                    result.error = null;
                  } else {
                    result.error = gettext(
                      'Billing date time range must be less then two years.'
                    );
                  }
                } else {
                  result.error = gettext(
                    'Billing date from must occur before billing date to.'
                  );
                }
              } else {
                result.error = gettext(
                  'Billing date time range must be less then two years.'
                );
              }
            }
          });

          if (!result.error) {
            result = MasterInvoiceListHelper.validateValidityIntervalFilter(
              filters
            );
          }
        }

        if (initialLoad && !billingIntervalIsValid) {
          to = DateTime.local();
          from = to.minus({ month: 6 });
          toConfig.date = to.toJSDate();
          fromConfig.date = from.toJSDate();
          result.error = null;
        }

        return result;
      },
      enrich: Enrich
    },
    paginationType: 'countTotal',
    title: {
      title: gettext('Invoice number'),
      param: 'masterInvoiceNumber',
      link: {
        state: 'invoices-invoices-view',
        params: [
          {
            valueParam: 'masterInvoice',
            queryParam: 'invoiceId'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'invoices-invoices-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'link',
        title: gettext('Detail invoice'),
        disableFn: item => {
          return item.detailInvoice == null;
        },
        link: {
          state: 'invoices-invoices-detail-view',
          params: [
            {
              valueParam: 'masterInvoice',
              queryParam: 'invoiceId'
            },
            {
              valueParam: 'detailInvoice',
              queryParam: 'invoiceDetailId'
            }
          ]
        }
      },
      {
        icon: 'content_copy',
        link: {
          state: 'invoices-invoices-duplicate',
          params: [
            {
              valueParam: 'masterInvoice',
              queryParam: 'invoiceId'
            }
          ]
        }
      },
      {
        icon: 'mode_edit',
        link: {
          state: 'invoices-invoices-edit',
          params: [
            {
              valueParam: 'masterInvoice',
              queryParam: 'invoiceId'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: 'masterInvoice',
          method: 'delete',
          configuration: {
            method: 'delete',
            entity: 'master-invoices'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Measuring point'),
        param: 'measuringPointName',
        link: {
          state: 'company-resources-measuring-points-view',
          params: [
            {
              valueParam: 'measuringPoint',
              queryParam: 'id'
            }
          ]
        },
        type: 'link'
      },
      {
        param: 'billingDate',
        title: gettext('Billing date'),
        type: 'date-short'
      },
      {
        param: 'masterInvoiceTotal',
        title: gettext('Total'),
        type: 'numerical'
      },
      {
        title: gettext('Total with VAT'),
        param: 'masterInvoiceTotalWithVat',
        type: 'numerical'
      },
      {
        title: gettext('Detail invoice total'),
        param: 'detailInvoiceTotal',
        type: 'numerical'
      },
      {
        param: 'detailInvoiceServiceDateTo',
        title: gettext('Detail invoice service date to'),
        type: 'date-short'
      },
      {
        title: gettext('Invoice Issuer'),
        param: 'supplierName',
        link: {
          state: 'company-resources-business-partners-view',
          params: [
            {
              valueParam: 'supplier',
              queryParam: 'id'
            }
          ]
        },
        type: 'link'
      },
      {
        title: gettext('Customer'),
        param: 'customerName',
        link: {
          state: 'company-resources-business-partners-view',
          params: [
            {
              valueParam: 'customer',
              queryParam: 'id'
            }
          ]
        },
        type: 'link'
      },
      {
        title: gettext('Invoice status'),
        param: 'invoiceStatusName',
        type: 'text'
      }
    ],
    filter: [
      {
        display: gettext('Invoice number'),
        param: 'masterInvoiceNumber',
        type: 'string'
      },
      {
        query: {
          entity: 'business-partners',
          method: 'read'
        },
        entity: 'business-partners',
        display: gettext('Invoice Issuer'),
        param: 'supplier',
        displayList: ['code', 'name'],
        refreshObject: {
          isDistributer: true,
          order: 'name'
        },
        type: 'searchSelect',
        refreshParameterName: 'name',
        selectedParamName: '_id'
      },
      {
        query: {
          entity: 'business-partners',
          method: 'read'
        },
        entity: 'business-partners',
        display: gettext('Customer'),
        param: 'customer',
        displayList: ['code', 'name'],
        refreshObject: {
          order: 'name'
        },
        type: 'searchSelect',
        refreshParameterName: 'name',
        selectedParamName: '_id'
      },
      {
        query: {
          entity: 'measuringpoints',
          method: 'read'
        },
        entity: 'measuringpoints',
        display: gettext('Measuring Point'),
        param: 'measuringPoint',
        displayList: ['code', 'name'],
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        type: 'dateCompare',
        label: gettext('Billing date from'),
        param: 'billingDate',
        paramType: 'gte',
        formatType: 'isoString',
        uniqId: 'billingDateFrom'
      },
      {
        type: 'dateCompare',
        label: gettext('Billing date to'),
        param: 'billingDate',
        paramType: 'lte',
        formatType: 'isoString',
        uniqId: 'billingDateTo'
      },
      {
        type: 'customDate',
        label: gettext('Validity interval from'),
        options: {
          monthSelector: true
        },
        uniqId: 'validityIntervalFrom',
        param: 'validityIntervalFrom',
        getFilterValue: el => {
          if (Array.isArray(el.date) && el.date[0] != null) {
            const date = DateTime.fromJSDate(new Date(el.date[0]));
            return {
              validityIntervalFromYear: `${date.year}`,
              validityIntervalFromMonth: `${date.month}`
            };
          }
          return {};
        }
      },
      {
        type: 'customDate',
        label: gettext('Validity interval to'),
        options: {
          monthSelector: true
        },
        uniqId: 'validityIntervalTo',
        param: 'validityIntervalTo',
        getFilterValue: el => {
          if (Array.isArray(el.date) && el.date[0] != null) {
            const date = DateTime.fromJSDate(new Date(el.date[0]));
            return {
              validityIntervalToYear: `${date.year}`,
              validityIntervalToMonth: `${date.month}`
            };
          }
          return {};
        }
      },
      {
        display: gettext('Total with Vat min'),
        param: 'totalWithVat',
        type: 'number',
        paramType: 'gte',
        uniqId: 'totalWithVatMin'
      },
      {
        display: gettext('Total with Vat max'),
        param: 'totalWithVat',
        type: 'number',
        paramType: 'lte',
        uniqId: 'totalWithVatMax'
      },

      {
        query: {
          entity: 'energy-source-types',
          method: 'read'
        },
        entity: 'energy-source-types',
        display: gettext('Energy Source Type'),
        param: 'energySourceType',
        displayList: ['code', 'name'],
        refreshObject: {
          order: 'code'
        },
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        collection: TranslationService.GetCollection('codelists.invoiceTypes'),
        display: gettext('Invoice type'),
        displayList: ['name'],
        param: 'invoiceType'
      },
      {
        collection: TranslationService.GetCollection(
          'codelists.invoiceInputSources'
        ),
        display: gettext('Input source'),
        displayList: ['name'],
        param: 'inputSource'
      },
      {
        collection: TranslationService.GetCollection(
          'codelists.invoiceStatuses'
        ),
        display: gettext('Invoice Status'),
        param: 'invoiceStatus',
        valueField: 'id'
      },
      {
        query: {
          entity: 'locations',
          method: 'read'
        },
        entity: 'locations',
        display: gettext('Location'),
        param: 'location',
        displayList: ['name'],
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      }
    ],
    sort: [
      {
        display: gettext('billing DESC'),
        params: '-billingDate'
      },
      {
        display: gettext('billing ASC'),
        params: 'billingDate'
      },
      {
        display: gettext('number ASC'),
        params: 'masterInvoiceNumber'
      },
      {
        display: gettext('number DESC'),
        params: '-masterInvoiceNumber'
      },
      {
        display: gettext('created DESC'),
        params: '-masterInvoice'
      },
      {
        display: gettext('created ASC'),
        params: 'masterInvoice'
      }
    ]
  };

  async function Enrich(item) {
    item.invoiceStatusName =
      TranslationService.GetCollectionById(
        'codelists.invoiceStatuses',
        item.invoiceStatus
      )?.name || '/';

    configuration.attributes.forEach(attr => {
      if (
        item[attr.param] == null &&
        attr.param !== 'detailInvoiceServiceDateTo' &&
        attr.param !== 'billingDate'
      ) {
        item[attr.param] = '/';
      }
    });
  }

  return configuration;
}
export default MasterInvoiceConfiguration;
