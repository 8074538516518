import './info-dialog.scss';
import template from './info.dialog.html';

/**
 * @ngdoc factory
 * @name common.InfoDialog
 * @description Used for opening of a dialog which mostly just displays critical info. Can have custom functions.
 * @property {function} open - Function that triggers the opening of the dialog
 */

InfoDialog.$inject = ['$mdDialog'];

export default function InfoDialog($mdDialog) {
  /**
   * @description Triggers the opening of the dialog.
   * @function
   * @memberof InfoDialog
   * @param {string} title Title of the dialog
   * @param {string} subtitle Subtitle of the dialog
   * @param {Array} items Items to be displayed
   * @param {Array} actions Array of actions
   * @param {dataType} fn Used just in device/new device, to add the button to the item
   */
  function open(
    title,
    subtitle,
    items,
    actions,
    fn,
    clickOutsideToClose = true,
    hideCancelButton
  ) {
    return $mdDialog.show({
      template,
      controller: [
        'title',
        'subtitle',
        'items',
        'actions',
        'fn',
        'hideCancelButton',
        InfoDialogController
      ],
      controllerAs: 'vm',
      parent: angular.element(document.body),
      clickOutsideToClose,
      locals: {
        title: title,
        subtitle: subtitle,
        items: items,
        actions: actions,
        fn: fn,
        hideCancelButton
      }
    });
  }

  /**
   * @description Dialog controller.
   * @function
   */
  function InfoDialogController(
    title,
    subtitle,
    items,
    actions,
    fn,
    hideCancelButton
  ) {
    var vm = this;
    vm.subtitle = subtitle;
    vm.items = items;
    vm.actions = actions;
    vm.copyToClipboard = fn;
    let headerActions = [];

    if (!hideCancelButton) {
      headerActions = [
        {
          icon: {
            name: 'close',
            type: 2
          },
          fn: function() {
            $mdDialog.cancel();
          }
        }
      ];
    }
    vm.header = {
      title: title,
      isDialog: true,
      actions: headerActions
    };
  }

  return {
    open: open
  };
}
