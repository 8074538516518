RuleTargetForXAxisIntervalModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function RuleTargetForXAxisIntervalModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'rule-target-for-x-axis-intervals'
  };
  var crawlerConfiguration = {
    entity: 'rule-target-for-x-axis-intervals',
    method: 'read',
    steps: [
      {
        entity: 'rules',
        valueParams: ['rule'],
        queryParams: ['_id'],
        method: 'custom.readRuleAlarmTarget',
        methodType: 'populateArray'
      }
    ]
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readRules: CrawlerMethods.crawler(crawlerConfiguration)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default RuleTargetForXAxisIntervalModel;
