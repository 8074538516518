module.exports = {
  entity: 'location-classifications',
  path: 'location-classifications',
  name: {
    en: 'Location classification',
    sl_SI: 'Klasifikacija lokacije'
  },
  networkModel: {
    entity: 'location-classifications',
    read: 'read'
  },
  scope: 'tis_be:location-classifications',

  selectedParamName: 'id',
  dialog: {
    title: {
      en: 'Select location classification',
      sl_SI: 'Izberite klasifikacijo lokacije'
    },
    listInfo: {
      en: 'Select location classification from the list below',
      sl_SI: 'Izberite klasifikacijo lokacije s spodnjega seznama'
    }
  }
}
