BasicMapFiltersController.$inject = [
  '$scope',
  'SfeListConfigurations',
  'AlertingService',
  '$mdDialog',
  'ListItemsService',
  'filterObject',
  'fetchCallback',
  'gettextCatalog',
  'SfeFilterService'
];

function BasicMapFiltersController(
  $scope,
  SfeListConfigurations,
  AlertingService,
  $mdDialog,
  ListItemsService,
  filterObject,
  fetchCallback,
  gettextCatalog,
  SfeFilterService
) {
  var vm = this;
  vm.entityConfiguration = SfeListConfigurations.configuration['locations'];
  vm.relistData = false;
  vm.resourceFn = vm.entityConfiguration.api.query;
  vm.sfeFilters = vm.entityConfiguration.filter;
  vm.sfeFilterObject = vm.entityConfiguration.filterObject;

  // service merges original filter object and filter object saved in storage
  //  because we are not saving filter to storage and have them with
  // selected props only in controller
  SfeFilterService.assignSelectedFiltersValues(vm.sfeFilters, vm.sfeFilters);
  vm.sfeLimit = 0;
  vm.sfePage = 0;
  vm.sfeOrder = '_id';

  vm.headerConfiguration = {
    title: gettextCatalog.getString('Basic Map filters'),
    isDialog: true,
    actions: [
      {
        icon: {
          name: 'close',
          type: 2
        },
        fn: function() {
          $mdDialog.cancel();
        }
      }
    ]
  };
  $scope.$on('$destroy', function() {
    if (relistWatcher) {
      relistWatcher();
    }
  });

  $scope.$on('$stateChangeSuccess', function() {
    $mdDialog.hide([]);
  });

  // watch for changes
  var relistWatcher = $scope.$watch('vm.relistData', function() {
    if (vm.relistData) {
      vm.relistData = false;
      getCollection();
    }
  });

  async function getCollection() {
    fetchCallback(true);
    try {
      const locations = await ListItemsService.fetchAllItems(
        {
          entity: 'locations',
          method: 'read'
        },
        vm.sfeFilters,
        filterObject
      );

      $mdDialog.hide(locations);
      fetchCallback(false);
    } catch (err) {
      AlertingService.Error(err);
      fetchCallback(false);
    }
  }
}
export default BasicMapFiltersController;
