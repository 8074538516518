DatasetColumnHelper.$inject = [
  'gettext',
  '$state',
  'gettextCatalog',
  'TranslationService',
  '$q',
  'EntitiesService',
  'SfeFormDialogService'
];
/**
 * @ngdoc service
 * @name common.DatasetColumnHelper
 * @description contains helper methods to work with dataset columns.
 * @property {Function} validateFilters
 * @property {Function} config Object of dataset table configuration
 * @property {Array} header array of dataset table headers
 * @property {Function} getDatasetEditHeader
 * @property {Function} constructColumnObject
 * @property {Function} setFilterTreeConfig
 * @property {Function} getEntityName
 * @property {Function} getDateTypeName
 * @property {Function} getTypeName
 * @property {Function} entities array of entities
 * @property {Function} types array of data types
 * @property {Function} dateTypes array of date types
 * @property {Function} editColumn
 * @property {Function} getColumnConfig
 * @property {Function} checkEntityFilter
 */
function DatasetColumnHelper(
  gettext,
  $state,
  gettextCatalog,
  TranslationService,
  $q,
  EntitiesService,
  SfeFormDialogService
) {
  let entities = TranslationService.GetCollection('codelists.entities');
  let types = TranslationService.GetCollection('codelists.dataTypes');
  let dateTypes = TranslationService.GetCollection('codelists.dateTypes');

  let header = [
    {
      name: gettext('Raw field'),
      sort: 'rawFieldName',
      raw: true
    },
    {
      name: gettext('Display field'),
      sort: 'displayFieldName',
      raw: true
    },
    {
      name: gettext('Type'),
      sort: 'typeName',
      text: true
    },
    {
      name: gettext('Filter enabled'),
      sort: 'filterEnabled',
      twoOptions: true,
      textTrue: gettext('YES'),
      textFalse: gettext('NO'),
      colorTrue: 'green',
      colorFalse: 'red'
    },
    {
      name: gettext('Entity'),
      sort: 'entityName',
      text: true
    }
  ];

  /**
   * @memberof datasetColumnHelper.getEntityName
   * @description creates an array used for configuraing header of the columns tables.
   * @param {Array} columns - array containing part of the of the configuration array
   * @return {Array} array containing configuration objects for headers.
   */
  function getDatasetEditHeader(columns) {
    return [
      ...header,
      {
        shortActions: [
          {
            icon: 'edit',
            fn: column => {
              editColumn(columns, column);
            }
          },
          {
            icon: 'delete',
            fn: column => {
              const index = columns.findIndex(
                item => item.index === column.index
              );
              columns.splice(index, 1);
            }
          }
        ]
      }
    ];
  }
  /**
   * @memberof datasetColumnHelper.getEntityName
   * @description creates the object used in one row of the columns table
   * @param {Object} object - object containing data of a single column table entry (so a column row)
   * @return {Object} enriched object
   */
  function constructColumnObject(object) {
    if (object.entity) {
      object.entityName =
        object.entity.name || gettextCatalog.getString('Unknown');
    } else {
      object.entityName = gettextCatalog.getString('None');
    }
    let typeObject = TranslationService.GetCollectionById(
      'codelists.dataTypes',
      object.type.id
    );
    object.typeName = typeObject
      ? typeObject.name
      : gettextCatalog.getString('Unknown');
    return object;
  }

  let config = {
    empty: gettext('There are no columns.'),
    page: 1,
    limit: 5,
    order: 'rawFieldName',
    state: $state.current.name,
    listId: 'columns'
  };
  function viewConfig() {
    return {
      ...config,
      toolbar: {
        title: gettext('Columns')
      }
    };
  }
  /**
   * @memberof datasetColumnHelper.getEntityName
   * @description returns entity name by entity id or string none.
   * @param {Number} entityId entity id
   * @return {String}
   */
  function getEntityName(entityId) {
    let temp = entities.find(function(item) {
      return item.id === entityId;
    });
    if (temp) {
      return temp.name;
    }
    return gettextCatalog.getString('None');
  }
  /**
   * @memberof datasetColumnHelper.getDateTypeName
   * @description returns date type name by entity id or string none.
   * @param {Number} dateTypeId date type id
   * @return {String}
   */
  function getDateTypeName(dateTypeId) {
    let temp = dateTypes.find(function(item) {
      return item.id === dateTypeId;
    });

    if (temp) {
      return temp.name;
    }
    return gettextCatalog.getString('None');
  }
  /**
   * @memberof datasetColumnHelper.getTypeName
   * @description returns type name by entity id or string none.
   * @param {Number} typeId type id
   * @return {String}
   */
  function getTypeName(typeId) {
    let temp = types.find(function(type) {
      return type.id === typeId;
    });

    if (temp) {
      return temp.name;
    }
    return gettextCatalog.getString('None');
  }
  /**
   * @memberof DatasetColumnHelper.setFilterTreeConfig
   * @description sets configuration to a filter object.
   * @param {Object} filter filter object
   * @param {Number} entity entity codelist id
   */
  function setFilterTreeConfig(filter, entity) {
    let entityObject = TranslationService.GetCollectionById(
      'codelists.entities',
      entity
    );

    let entityName = entityObject ? entityObject.name : 'Unknown';
    const filterTitle = gettextCatalog.getString('{{entityName}}', {
      entityName: entityName
    });
    let networkModelConfiguration;
    let filterType;
    let displayFields;
    let codelistPath;
    let valueField = '_id';
    let networkConfiguration;
    switch (Number(entity)) {
    case 234: //time-series
    case 2: //measuringpoint
    case 3: //location
    case 4: //costcentre
    case 104: //energysourcetype
    case 134: //locationType
    case 158: ///MeasuringPointType
    case 43: //Asset
    case 53: //Asset type
    case 235: //TimeSeriesType
    case 237: //TimeSeriesGroup
    case 238: //TimeSeriesGroupType
    case 105: //EntityTag
    case 140: //MasterInvoice
    case 20: //Alarms
    case 187: //Severity
    case 30: //alarmType
    case 248: //TangoAgentConnectionConfig
    case 247: //externalDatasource
      filterType = 'dialog';
      break;
    case 55:
      filterType = 'dnd';
      networkConfiguration = EntitiesService.getNetworkModelByEntity(
        'billing-kinds'
      );
      networkModelConfiguration = {
        entity: networkConfiguration.entity,
        method: networkConfiguration.read
      };
      displayFields = ['name'];
      break;
    case 58:
      filterType = 'dnd';
      networkConfiguration = EntitiesService.getNetworkModelByEntity(
        'billing-types'
      );
      networkModelConfiguration = {
        entity: networkConfiguration.entity,
        method: networkConfiguration.read
      };

      displayFields = ['name'];
      break;
    case 224:
    case 249: //schedule classifications
    case 251: //alarmStatus
    case 252: //eventType
      filterType = 'dnd';
      codelistPath = 'codelists.' + entityObject.enumerator;
      displayFields = ['name'];
      valueField = 'id';
      break;
    }
    return {
      ...filter,
      displayFields,
      valueField,
      networkModelConfiguration,
      codelistPath,
      filterType,
      entity,
      filters: true,
      filterTitle
    };
  }
  /**
   * @memberof analysis.DatasetColumnHelper
   * @description returns validate on object if filters are not valid it contains error key with an error message inside.
   * @param {Array} filters array of filters must contain under entity key entity codelist id an d under selectedFilters key array of selected items
   * @param {Array} defaultEntities array of codelist ids of default entities
   * @return {Object}
   */
  function validateFilters(filters, defaultEntities) {
    let defaultSelected = false;
    let entityLimitReached = false;
    let filterEntities;
    filters.forEach(function(filter) {
      if (defaultEntities.includes(filter.entity)) {
        filterEntities = filter.entityItems;
        if (Array.isArray(filterEntities)) {
          if (filterEntities.length > 0) {
            defaultSelected = true;
          }
          if (filterEntities.length > 300) {
            entityLimitReached = true;
          }
        }
      }
    });
    let result = {};

    if (defaultSelected) {
      if (entityLimitReached) {
        result.error = gettextCatalog.getString(
          'You are not allowed to select more than 300 items'
        );
      }
    } else {
      let errorMsg = gettextCatalog.getString(
        'You have to add at least one of the following filters: '
      );
      let selectedEntity;
      defaultEntities.forEach(function(entity, index) {
        selectedEntity = TranslationService.GetCollectionById(
          'codelists.entities',
          entity
        );
        if (selectedEntity) {
          errorMsg += selectedEntity.name;
        }
        if (index < defaultEntities.length - 1) {
          errorMsg += ', ';
        } else {
          errorMsg += '.';
        }
      });
      result.error = errorMsg;
    }
    return result;
  }
  /**
   * @memberof analysis.DatasetColumnHelper
   * @description function called to edit dataset columns.
   * @param {Array} A list of all the datasets.
   * @param {object} The object contains data about the column we want to edit.
   */
  function editColumn(columns, column) {
    const index = columns.findIndex(item => item.index === column.index);
    let config = getColumnConfig();
    let dataObj = Object.assign(
      {
        _preserve_: true
      },
      column
    );
    let title = gettext('Edit column');
    let entityConfig = config.find(function(conf) {
      return conf.name === 'entity';
    });

    if (dataObj.filterEnabled) {
      entityConfig.configuration.required = true;
    }
    const enableFilterEnabledConfig = config.find(function(conf) {
      return conf.name === 'filterEnabled';
    });
    if (enableFilterEnabledConfig) {
      enableFilterEnabledConfig.action = value => {
        if (value) {
          entityConfig.configuration.required = true;
        } else {
          entityConfig.configuration.required = false;
        }
      };
    }
    if (entityConfig && entityConfig.configuration) {
      entityConfig.configuration.change = checkEntityFilter.bind({
        obj: dataObj
      });
      entityConfig.configuration.change();
    }
    SfeFormDialogService.openSfeFormDialog(true, config, dataObj, title).then(
      function(object) {
        if (!object) {
          return;
        }
        columns[index] = constructColumnObject(object);
      },
      function() {}
    );
  }
  /**
   * @memberof analysis.DatasetColumnHelper
   * @description Returns an object for the column creation/edit dialog.
   * @return {Array} Array containing configuration objects
   */
  function getColumnConfig() {
    return [
      {
        placeholder: 'Raw field name',
        name: 'rawFieldName',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: 'Display field name',
        name: 'displayFieldName',
        componentType: 'textField',
        type: 'text'
      },
      {
        componentType: 'autocompleteDialog',
        edit: false,
        configuration: {
          noDialog: true,
          codelist: 'dataTypes',
          floatingLabel: gettext('Select data types'),
          searchParamName: 'name',
          required: true,
          entity: 'entities',
          dialogConfiguration: {
            multiple: false
          },
          selectedParam: 'id'
        },
        name: 'type'
      },
      {
        componentType: 'autocompleteDialog',
        edit: false,
        configuration: {
          filterFn: function(items) {
            return items.filter(function(item) {
              return (
                item.analyticsConfig &&
                item.analyticsConfig.canBeUsedForDatasetDefintion
              );
            });
          },
          noDialog: true,
          codelist: 'entities',
          floatingLabel: gettext('Select entity'),
          searchParamName: 'name',
          required: false,
          entity: 'entities',
          dialogConfiguration: {
            multiple: false
          },
          selectedParam: 'id'
        },
        name: 'entity'
      },
      {
        hide: true,
        showParam: 'showDateEntityFilter',
        componentType: 'autocompleteDialog',
        edit: false,
        configuration: {
          noDialog: true,
          codelist: 'dateTypes',
          floatingLabel: gettext('Select date types'),
          searchParamName: 'name',
          required: false,
          entity: 'dateTypes',
          dialogConfiguration: {
            multiple: false
          },
          selectedParam: 'id'
        },
        name: 'dateType'
      },
      {
        componentType: 'checkBox',
        label: gettext('Enable filter'),
        name: 'filterEnabled'
      }
    ];
  }
  /**
   * @memberof analysis.DatasetColumnHelper
   * @description checks if entity filter is set to a specic value.
   * @function
   */
  function checkEntityFilter() {
    let obj = this.obj;

    if (obj && obj.entity && obj.entity.id === 223) {
      // entity.id === 223 is when the entity name is date
      obj.showDateEntityFilter = true;
    } else {
      obj.showDateEntityFilter = false;
      obj.dateType = null;
    }
  }

  return {
    viewConfig,
    checkEntityFilter,
    getColumnConfig,
    constructColumnObject,
    headerEdit: getDatasetEditHeader,
    validateFilters,
    config: config,
    header: header,
    setFilterTreeConfig: setFilterTreeConfig,
    getEntityName: getEntityName,
    getDateTypeName: getDateTypeName,
    getTypeName: getTypeName,
    entities: entities,
    types: types,
    dateTypes: dateTypes
  };
}

export default DatasetColumnHelper;
