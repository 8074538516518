InputParametersParserService.$inject = ['gettextCatalog', 'TranslationService'];
/**
 * @ngdoc service
 * @name common.InputParametersParserService
 * @description Service used to parse input parameters (calculated flow rules)
 * @property {function} parseApiToForm - see method RandomColor.
 */
export default function InputParametersParserService(
  gettextCatalog,
  TranslationService
) {
  /**
   * @description parses input parameters
   * @function
   * @param {Array} params
   * @return {Array}
   */
  function parseApiToForm(params) {
    if (Array.isArray(params)) {
      return params.map(param => {
        let exceptionHandlerActionTypeArgument = '';
        if (
          param.exceptionHandler != null &&
          Array.isArray(param.exceptionHandler.actionArguments) &&
          param.exceptionHandler.actionArguments.length > 0
        ) {
          exceptionHandlerActionTypeArgument =
            param.exceptionHandler.actionArguments[0].value;
        }
        let mappingFunctionTypeArgument = null;
        let mappingFunctionNArgument = '';

        if (
          Array.isArray(param.mappingFunctionArguments) &&
          param.mappingFunctionArguments.length > 0
        ) {
          if (param.mappingFunctionArguments[0].name === 'type') {
            mappingFunctionTypeArgument =
              param.mappingFunctionArguments[0].value;
          }
          if (param.mappingFunctionArguments[0].name === 'n') {
            mappingFunctionNArgument = param.mappingFunctionArguments[0].value;
          }
        }
        let dateTimeFilterType = null;
        let includeValueOnTheEdge;
        let fromDateTimeMapper = null;
        let toDateTimeMapper = null;
        let timeUnit = null;
        let offsetValue = '';
        if (param.calculationTimestampDateTimeFilter != null) {
          dateTimeFilterType = param.calculationTimestampDateTimeFilter.type;
          if (dateTimeFilterType == 500) {
            if (
              param.calculationTimestampDateTimeFilter.fromDateTimeMapper !=
              null
            ) {
              fromDateTimeMapper = {
                ...param.calculationTimestampDateTimeFilter.fromDateTimeMapper,
                boundaryType:
                  param.calculationTimestampDateTimeFilter.fromBoundaryType
              };
            } else {
              fromDateTimeMapper = {
                boundaryType:
                  param.calculationTimestampDateTimeFilter.fromBoundaryType
              };
            }
            if (
              param.calculationTimestampDateTimeFilter.toDateTimeMapper != null
            ) {
              toDateTimeMapper = {
                ...param.calculationTimestampDateTimeFilter.toDateTimeMapper,
                boundaryType:
                  param.calculationTimestampDateTimeFilter.toBoundaryType
              };
            } else {
              toDateTimeMapper = {
                boundaryType:
                  param.calculationTimestampDateTimeFilter.toBoundaryType
              };
            }
          }

          if (dateTimeFilterType == 400) {
            timeUnit = param.calculationTimestampDateTimeFilter.timeUnit;
            offsetValue = param.calculationTimestampDateTimeFilter.offsetValue;
          }

          if (dateTimeFilterType == 200) {
            includeValueOnTheEdge =
              param.calculationTimestampDateTimeFilter.boundaryType === 2; //codelist id of opened type
          }
        }
        return {
          variableName: param.name,
          paramObject: {
            streamArchiveProperty: param.streamArchiveProperty,
            mappingFunction: param.mappingFunction,
            dateTimeFilterType,
            exceptionHandlerActionTypeArgument,
            mappingFunctionTypeArgument,
            mappingFunctionNArgument,
            fromDateTimeMapper,
            toDateTimeMapper,
            includeValueOnTheEdge,
            timeUnit,
            offsetValue
          }
        };
      });
    }
  }

  /**
   * @description returns variable configurations.
   * @function
   * @param {Array} params
   * @return {Array}
   */
  function parseApiToItemView(params, hideDateTimeFilter) {
    return params.map(inputVariableParam => {
      let mappingValue = getMappingValue(
        inputVariableParam.mappingFunction,
        inputVariableParam.mappingFunctionArguments
      );

      // STREAM ARCHIVE PROPERTY
      let streamArchiveProperty = TranslationService.GetCollectionById(
        'codelists.streamArchiveProperties',
        inputVariableParam.streamArchiveProperty
      );
      let streamArchivePropertyConfig;
      if (streamArchiveProperty != null) {
        streamArchivePropertyConfig = {
          testId: 'streamArchiveProperty',
          title: gettextCatalog.getString('Stream Archive Property'),
          value: streamArchiveProperty.name
        };
      }

      const {
        filterTypeConfig,
        boundaryTypeConfig,
        timeOffsetConfig,
        fromConfig,
        toConfig
      } = getCalculationTimestampDateTimeFilterConfigurations(
        inputVariableParam.calculationTimestampDateTimeFilter
      );

      let exceptionHandlerConfig;
      if (inputVariableParam.exceptionHandler != null) {
        let exceptionHandlerType = TranslationService.GetCollectionById(
          'codelists.mappingExceptionActionTypes',
          inputVariableParam.exceptionHandler.actionType
        );
        if (exceptionHandlerType != null) {
          let value;
          if (exceptionHandlerType.id == 200) {
            //VALUE
            value =
              inputVariableParam.exceptionHandler.actionArguments[0].value;
          } else {
            value = `${exceptionHandlerType.name}`;
            if (
              Array.isArray(
                inputVariableParam.exceptionHandler.actionArguments
              ) &&
              inputVariableParam.exceptionHandler.actionArguments.length > 0
            ) {
              value = `${exceptionHandlerType.name}( ${inputVariableParam.exceptionHandler.actionArguments[0].value} )`;
            }
          }
          exceptionHandlerConfig = {
            testId: 'exceptionHandler',
            title: gettextCatalog.getString('Exception Handler'),
            value
          };
        }
      }

      let configurations = [
        {
          value: inputVariableParam.name,
          title: gettextCatalog.getString('Variable name'),
          testId: 'variableName'
        }
      ];
      if (mappingValue) {
        configurations.push(mappingValue);
      }
      if (streamArchivePropertyConfig != null) {
        configurations.push(streamArchivePropertyConfig);
      }

      if (filterTypeConfig != null && !hideDateTimeFilter) {
        configurations.push(filterTypeConfig);
        if (boundaryTypeConfig != null) {
          configurations.push(boundaryTypeConfig);
        }

        if (timeOffsetConfig != null) {
          configurations.push(timeOffsetConfig);
        }

        if (fromConfig != null) {
          configurations.push(fromConfig);
        }
        if (toConfig != null) {
          configurations.push(toConfig);
        }
      }

      if (exceptionHandlerConfig != null) {
        configurations.push(exceptionHandlerConfig);
      }

      return configurations;
    });
  }

  function constructDateTimeMapper(mapper) {
    if (mapper != null) {
      return Object.keys(mapper).map(mapperKey => {
        let id = getTimeUnitId(mapperKey);
        let timeUnit = TranslationService.GetCollectionById(
          'codelists.timeUnits',
          id
        );
        let timeUnitName = '';
        if (timeUnit != null) {
          timeUnitName = timeUnit.name;
        }
        let dateTimeMappingType = TranslationService.GetCollectionById(
          'codelists.dateTimeMappingTypes',
          mapper[mapperKey].dateTimeMappingType
        );
        let dateTimeMappingTypeName;
        if (dateTimeMappingType != null) {
          dateTimeMappingTypeName = dateTimeMappingType.name;
        }

        return {
          timeUnitName,
          dateTimeMappingTypeName,
          value: mapper[mapperKey].value
        };
      });
    }
  }

  function getTimeUnitId(key) {
    switch (key) {
    case 'seconds':
      return 1;
    case 'minutes':
      return 2;
    case 'hours':
      return 3;
    case 'days':
      return 4;
    case 'weeks':
      return 5;
    case 'months':
      return 6;
    case 'years':
      return 7;
    }
    return 0;
  }

  function getMappingValue(mappingFunctionId, mappingFunctionArguments) {
    let mappingValue;
    let mappingArgs = '';
    let mappingFunction;
    mappingFunction = TranslationService.GetCollectionById(
      'codelists.ruleMappingFunctions',
      mappingFunctionId
    );
    if (Array.isArray(mappingFunctionArguments)) {
      // Go over mapping function args and create function for view.
      mappingArgs = mappingFunctionArguments.reduce(
        (result, mappingFunctionArg, index) => {
          if (mappingFunctionArg.name === 'n') {
            result += mappingFunctionArg.value;
          } else if (mappingFunctionArg.name === 'type') {
            let typeArgument = TranslationService.GetCollectionById(
              'codelists.integrationMethods',
              mappingFunctionArg.value
            );
            if (typeArgument != null) {
              result += typeArgument.name;
            }
          }
          if (index !== mappingFunctionArguments.length - 1) {
            result += ', ';
          }
          return result;
        },
        ''
      );
      mappingArgs = `(${mappingArgs})`;
    }
    if (mappingFunction != null) {
      mappingValue = {
        value: mappingFunction.name + mappingArgs,
        title: gettextCatalog.getString('Mapping Function'),
        testId: 'mappingFunction'
      };
    } else {
      mappingValue = 'Invalid mapping function';
    }
    return mappingValue;
  }

  function getCalculationTimestampDateTimeFilterConfigurations(
    calculationTimestampDateTimeFilter
  ) {
    let filterTypeConfig;
    let boundaryTypeConfig;
    let timeOffsetConfig;
    let fromConfig;
    let toConfig;
    if (calculationTimestampDateTimeFilter != null) {
      if (calculationTimestampDateTimeFilter != null) {
        let timeFilterType = TranslationService.GetCollectionById(
          'codelists.dateTimeFilterTypes',
          calculationTimestampDateTimeFilter.type
        );
        if (timeFilterType != null) {
          filterTypeConfig = {
            testId: 'timeFilterType',
            title: gettextCatalog.getString('Date Time Filter Type'),
            value: timeFilterType.name
          };
        }
      }

      let boundaryType;

      let timeUnit;
      let timeUnitName;

      let fromBoundaryType;
      let toBoundaryType;

      let fromBoundaryTypeName;
      let toBoundaryTypeName;

      let fromMapper;
      let toMapper;
      switch (calculationTimestampDateTimeFilter.type) {
      case 200:
        boundaryType = TranslationService.GetCollectionById(
          'codelists.boundaryTypes',
          calculationTimestampDateTimeFilter.boundaryType
        );
        if (boundaryType != null) {
          boundaryTypeConfig = {
            testId: 'boundaryType',
            title: gettextCatalog.getString('Boundary Type'),
            value: boundaryType.name
          };
        }
        break;
      case 400:
        timeUnit = TranslationService.GetCollectionById(
          'codelists.timeUnits',
          calculationTimestampDateTimeFilter.timeUnit
        );
        if (timeUnit != null) {
          timeUnitName = timeUnit.name;
          timeOffsetConfig = {
            testId: 'timeOffset',
            title: gettextCatalog.getString('Time offset'),
            value: `${calculationTimestampDateTimeFilter.offsetValue} ${timeUnitName}`
          };
        }
        break;
      case 500:
        fromMapper = constructDateTimeMapper(
          calculationTimestampDateTimeFilter.fromDateTimeMapper
        );
        toMapper = constructDateTimeMapper(
          calculationTimestampDateTimeFilter.toDateTimeMapper
        );
        fromBoundaryType = TranslationService.GetCollectionById(
          'codelists.boundaryTypes',
          calculationTimestampDateTimeFilter.fromBoundaryType
        );
        toBoundaryType = TranslationService.GetCollectionById(
          'codelists.boundaryTypes',
          calculationTimestampDateTimeFilter.toBoundaryType
        );
        if (fromBoundaryType != null) {
          fromBoundaryTypeName = fromBoundaryType.name;
        }
        if (toBoundaryType != null) {
          toBoundaryTypeName = toBoundaryType.name;
        }
        fromConfig = {
          title: gettextCatalog.getString('From'),
          mapper: fromMapper,
          value: fromBoundaryTypeName
        };
        toConfig = {
          title: gettextCatalog.getString('To'),
          mapper: toMapper,
          value: toBoundaryTypeName
        };
      }
    }
    return {
      filterTypeConfig,
      boundaryTypeConfig,
      timeOffsetConfig,
      fromConfig,
      toConfig
    };
  }
  return {
    parseApiToForm,
    parseApiToItemView
  };
}
