TangoPropertiesHelper.$inject = [
  'TranslationService',
  'AlertingService',
  'ValidateObjectConfiguration',
  'UserTangoProperties',
  'SchedulerTangoProperties',
  'MeasurementUnitTangoProperties',
  'gettextCatalog',
  'RuleViewerDialog'
];
/**
 * @ngdoc service
 * @name common.TangoPropertiesHelper
 * @description
 * @property {function} getProperty
 * @property {function} getCodeListValue
 * @property {function} getCommonPropertyConfiguration
 * @property {function} getBasicConfig
 * @property {function} getPropertyValue
 *
 */
export default function TangoPropertiesHelper(
  TranslationService,
  AlertingService,
  ValidateObjectConfiguration,
  UserTangoProperties,
  SchedulerTangoProperties,
  MeasurementUnitTangoProperties,
  gettextCatalog,
  RuleViewerDialog
) {
  const simpleObjectConfiguration = [
    {
      fieldName: '_id',
      type: 'string',
      required: true
    },
    {
      fieldName: 'name',
      type: 'string',
      required: true
    }
  ];
  /**
   * @description returns sfe-property configuration.
   * @function
   * @param {Object}
   *   title <string>
   *   item <Object> entity item that contains values to be displayed
   *   type <string> sfe-property configuration type basic|large|mathExpression ....
   *   stateName <string> !optional name of the state to create redirect link
   *   parameters <Array> !optional [{name, value}] name is the name of the parameter, value is the key name where parameter value is stored in item object
   *   validationConfig <Object>  {validity, warning, error} validation result configuration
   *   getSfePropertyValues <function> custom function to get sfe-property values should return array of values
   * @return {dataType}
   */
  function sfePropertyConfig({
    title,
    item,
    type,
    stateName,
    parameters,
    validationConfig,
    getSfePropertyValues,
    testId
  }) {
    let values;
    if (item == null) {
      AlertingService.devWarn(
        'To create basic configuration item cannot be undefined'
      );
    } else if (validationConfig == null) {
      AlertingService.devWarn('There are no validation');
    } else {
      if (validationConfig.validity) {
        values = getValueWrapper({
          getSfePropertyValues,
          value: item[validationConfig.fieldName],
          stateName,
          parameters,
          item
        });
      } else {
        if (
          validationConfig.warning != null &&
          validationConfig.warning.noValue
        ) {
          values = [{}];
        }
      }
    }

    return {
      type,
      title,
      testId,
      values
    };
  }
  /**
   * @description helper method to get sfe-property configuration values.
   * @function
   * @param {Object}
   *   title <string>
   *   item <Object> entity item that contains values to be displayed
   *   value <Any> value that has to be displayed
   *   stateName <string> !optional name of the state to create redirect link
   *   parameters <Array> !optional [{name, value}] name is the name of the parameter, value is the key name where parameter value is stored in item object
   *   getSfePropertyValues <function> custom function to get sfe-property values should return array of values
   * @return {dataType}
   */
  function getValueWrapper({
    getSfePropertyValues,
    value,
    stateName,
    parameters,
    item
  }) {
    if (typeof getSfePropertyValues == 'function') {
      return getSfePropertyValues({
        value,
        stateName,
        parameters,
        item
      });
    } else {
      return [
        getPropertyValue({
          value,
          stateName,
          parameters,
          item
        })
      ];
    }
  }
  /**
   * @description Triggers basic object validation (_id and name must exist) and returns single value configuration for item
   * as display parameter will be used name.
   * @function
   * @param {Object} item object that is used to construct property value
   * @param {string} stateName name of the state if we want link to be created
   * @param {Array} parameters array of objects link parameter configuration {name, value}
   * @return {Object}
   */
  function getPropertyValue({ value, stateName, parameters }) {
    const validationResult = ValidateObjectConfiguration.validate(
      value,
      simpleObjectConfiguration
    );
    if (validationResult.validity) {
      let link;
      if (stateName != null) {
        const params = Array.isArray(parameters)
          ? parameters.reduce((result, param) => {
            return {
              ...result,
              [param.name]: value[param.value]
            };
          }, {})
          : {};
        link = `${stateName}(${JSON.stringify(params)})`;
      }
      return {
        text: value.name,
        link
      };
    }
  }
  /**
   * @description returns function that gets codelist value.
   * @function
   * @param {string} codelist codelist table name
   * @return {function}
   */
  function getCodeListValue(codelist) {
    /**
     * @description returns configuration for codelist value.
     * @function
     * @param {object} object where codelist value is stored
     * @param {object} config validation config
     * @return {Array}
     */
    return ({ value }) => {
      const itemInCodeList = TranslationService.GetCollectionById(
        `codelists.${codelist}`,
        value
      );
      return [
        {
          text: itemInCodeList != null ? itemInCodeList.name : null
        }
      ];
    };
  }

  /**
   * @description returns array of sfe-property-list configurations to display time series rules and alarms.
   * @function
   * @param {Object} item time-series item
   * @return {Array}
   */
  function getRulesAlarmsProperties(items, showIntegrity) {
    if (Array.isArray(items)) {
      //create sfe-property configuration <Array>
      //to display rules and alarms
      const alarmRules = items.reduce((result, ruleObject, index) => {
        let { rule, integrityValue, alarms } = ruleObject;
        if (rule != null && rule.expression != null) {
          if (index != 0) {
            result = [
              ...result,
              {
                type: 'divider'
              }
            ];
          }
          //Add rule math Expression to configuration
          result = [
            ...result,
            {
              type: 'basic',
              title: gettextCatalog.getString('Rule {{index}}', {
                index: index + 1
              }),
              testId: `rules_${index}`,
              actions: [
                {
                  fn: () => {
                    RuleViewerDialog.open(rule);
                  },
                  icon: {
                    type: 2,
                    name: 'info_outline',
                    color: 'grey'
                  }
                }
              ],
              values: [
                {
                  text: rule.name,
                  link: `alarms-and-rules-rules-view(${JSON.stringify({
                    id: rule._id
                  })})`
                }
              ]
            }
          ];
          if (showIntegrity) {
            result = [
              ...result,
              {
                type: 'basic',
                title: gettextCatalog.getString('Integrity value'),
                testId: 'integrityValue',
                values: [
                  {
                    text: integrityValue
                  }
                ]
              }
            ];
          }
          //When rules have alarms
          if (Array.isArray(alarms)) {
            //create array of sfe-property configurations
            //to display alarms
            const alarmValues = alarms.reduce((alarmResult, alarm) => {
              //create sfe-property value configuration
              //for single alarm
              let alarmValueConfig = getPropertyValue({
                value: alarm,
                stateName: 'alarms-and-rules-alarm-definitions-view',
                parameters: [{ name: 'id', value: '_id' }]
              });
              if (alarmValueConfig != null) {
                //concat result alarm values and new alarm value
                alarmResult = [...alarmResult, alarmValueConfig];
              }
              return alarmResult;
            }, []);
            //If there is at least one alarm configuration
            // add configuration to final rule alarms configuration
            if (Array.isArray(alarmValues) && alarmValues.length > 0) {
              result = [
                ...result,
                {
                  type: 'basic',
                  title: gettextCatalog.getString('Alarms'),
                  testId: `alarms_${index}`,
                  values: alarmValues
                }
              ];
            }
          }
        }
        return result;
      }, []);
      return alarmRules;
    } else {
      //When there is no rule alarms
      //create empty configuration
      return [
        {
          type: 'basic',
          values: [{}]
        }
      ];
    }
  }

  /**
   * @description returns entity custom configuration object.
   * @function
   * @param {string} entity entity name that we want to create configuration for
   * @param {string} type of configuration
   * @param {object} item item that contains object that we want to create property for
   * @param {string} key key to the item that we want to create property for
   * @param {object} configurations additional configurations
   * @return {object}
   */
  function getCommonPropertyConfiguration({
    entity,
    type,
    item,
    key,
    options
  }) {
    switch (entity) {
    case 'schedulers':
      return SchedulerTangoProperties[type]({ item, key, options });
    case 'users':
      return UserTangoProperties[type]({ item, key, options });
    case 'measurement-units':
      return MeasurementUnitTangoProperties[type]({ item, key, options });
    default:
      AlertingService.devWarn(`Unknown entity ${entity}`);
    }
  }
  return {
    sfePropertyConfig,
    getCodeListValue,
    getCommonPropertyConfiguration,
    getPropertyValue,
    getRulesAlarmsProperties
  };
}
