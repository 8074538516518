module.exports = {
  entity: 'visualization-elements',
  path: 'visualization-elements',
  name: {
    en: 'visualization element',
    sl_SI: 'Vizualizacijski element'
  },
  networkModel: {
    entity: 'visualization-elements',
    read: 'read'
  },
  scope: 'tis_be:visualization-elements',

  dialog: {
    title: {
      en: 'Select visualization element',
      sl_SI: 'Izberite vizualizacijski element'
    },
    listInfo: {
      en: 'Select visualization element from the list below',
      sl_SI: 'Izberite vizualizacijski element s spodnjega seznama'
    }
  }
}
