UploadFilesConfiguration.$inject = [
  'gettext',
  '$q',
  'SfeFormDialogService',
  'utilService',
  'AlertingService',
  'ToastService',
  'InfoDialog',
  'ListFormService',
  'TranslationService',
  '$mdDialog',
  'MetadataService',
  'xhrService',
  'DocumentClassificationModel',
  'CrawlerMethods',
  'UploadFileModel',
  'gettextCatalog'
];

function UploadFilesConfiguration(
  gettext,
  $q,
  SfeFormDialogService,
  utilService,
  AlertingService,
  ToastService,
  InfoDialog,
  ListFormService,
  TranslationService,
  $mdDialog,
  MetadataService,
  xhrService,
  DocumentClassificationModel,
  CrawlerMethods,
  UploadFileModel,
  gettextCatalog
) {
  var title = gettext('Confirmation');
  var textItem = {
    text: gettext('Are you sure you want to delete this item?'),
    type: 'text'
  };

  var actions = [
    {
      title: gettext('Cancel'),
      cancel: true,
      color: 'primary'
    },
    {
      title: gettext('Delete'),
      fn: deleteFile,
      color: 'warn'
    }
  ];
  var item;
  var relistDataFunction;

  function uploadFileDialog(relistData) {
    var config = [
      {
        componentType: 'fileUpload',
        name: 'files',
        fileUploader: 'fileUploader',
        endpoint: utilService.getApiHost + '/upload-files',
        queueLimit: 10
      }
    ];

    var obj = {
      useSupplier: true,
      files: []
    };

    var dialogButtonsConfiguration = {
      displayDefault: false,
      buttons: [
        {
          title: gettext('Upload'),
          script: function(closeDialog, res) {
            if (res) {
              res.fileUploader.onCompleteItem = function(
                item,
                response,
                status
              ) {
                if (status !== 200) {
                  if (response.error) {
                    AlertingService.Error(response.error.message);
                  } else {
                    const fileName = item.file
                      ? item.file.name
                      : gettextCatalog.getString('Unknown');
                    AlertingService.Error(
                      gettextCatalog.getString(
                        'Something went wrong during file {{fileName}} upload',
                        { fileName }
                      )
                    );
                  }
                } else {
                  configuration.files.push(response.data._id);
                }
              };

              res.fileUploader.onCompleteAll = function() {
                var method = CrawlerMethods.getMethod({
                  entity: configuration.route,
                  method: 'update'
                });
                method(
                  {
                    id: configuration.id
                  },
                  {
                    documents: configuration.files
                  }
                ).then(
                  function() {
                    ToastService.showToast(
                      gettext('Files were successfully uploaded')
                    );
                    relistData();
                  },
                  function(err) {
                    AlertingService.Error(err);
                  }
                );
              };
              res.fileUploader.uploadAll();
            }
            closeDialog();
          }
        }
      ]
    };

    SfeFormDialogService.openSfeFormDialog(
      false,
      config,
      obj,
      gettext('File Uploader'),
      dialogButtonsConfiguration
    );
  }

  function updateFileDialog(item, relistData) {
    UploadFileModel.read({
      id: item._id
    }).then(
      function(res) {
        res.data.documentClassification = {
          _id: res.data.classification
        };
        var config = ListFormService.generateForm(
          ['name', 'description', 'documentClassification'],
          false
        );
        var title = gettext('Edit File');
        SfeFormDialogService.openSfeFormDialog(
          true,
          config,
          res.data,
          title
        ).then(function(object) {
          if (object) {
            UploadFileModel.update(
              {
                id: object._id
              },
              {
                name: object.name,
                classification: object.documentClassification._id,
                description: object.description
              }
            ).then(
              function() {
                relistData();
                ToastService.showToast(
                  gettext('File was successfully updated')
                );
              },
              function(err) {
                relistData();
                AlertingService.Error(err);
              }
            );
          }
        });
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function deletePrompt(file, relistData) {
    item = file;
    relistDataFunction = relistData;
    InfoDialog.open(title, null, [textItem], actions);
  }

  function deleteFile() {
    $mdDialog.cancel();
    UploadFileModel.delete({
      id: item._id
    }).then(
      function() {
        ToastService.showToast(gettext('File was successfully deleted'));
      },
      function(err) {
        AlertingService.Error(err);
      }
    );

    var index = configuration.files.indexOf(item._id);
    if (index !== -1) configuration.files.splice(index, 1);

    var method = CrawlerMethods.getMethod({
      entity: configuration.route,
      method: 'update'
    });
    method(
      {
        id: configuration.id
      },
      {
        documents: configuration.files
      }
    ).then(
      function() {
        relistDataFunction();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function downloadFile(file) {
    var url = utilService.getApiHost + '/upload-files/' + file._id + '/file';

    xhrService.asyncFetch(url, 'arraybuffer').then(
      function(res) {
        var arrayBufferView = new Uint8Array(res.response);
        var fileType = TranslationService.GetCollectionById(
          'codelists.fileTypes',
          file.fileType
        );
        if (fileType) {
          fileType = fileType.mimeTypes[0];
          var a = document.createElement('a');
          var blob = new Blob([arrayBufferView], {
            type: fileType
          });
          var urlCreator = window.URL || window.webkitURL;

          a.href = urlCreator.createObjectURL(blob);
          a.download = file.name;
          a.click();
        } else {
          AlertingService.Error(gettext('Unknown file type'));
        }
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  var getFiles = {
    query: function() {
      var deferred = $q.defer();
      MetadataService.Loading(true);
      if (configuration.files && configuration.files.length) {
        UploadFileModel.read({
          _id:
            configuration.files && configuration.files.length
              ? configuration.files
              : JSON.stringify(configuration.files)
        }).then(
          function(res) {
            MetadataService.Loading(false);
            deferred.resolve(res);
          },
          function(err) {
            MetadataService.Loading(false);
            deferred.reject(err);
          }
        );
      } else {
        deferred.resolve({ data: [] });
      }
      return {
        $promise: deferred.promise
      };
    }
  };

  var configuration = {
    entity: {
      singular: gettext('File'),
      plural: gettext('Files')
    },
    api: {
      query: getFiles,
      enrich: Enrich
    },
    title: {
      param: 'name',
      icon: true,
      action: downloadFile,
      relist: true
    },
    buttons: [
      {
        title: gettext('Upload file'),
        fn: uploadFileDialog,
        color: 'accent',
        relist: true
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        action: updateFileDialog
      },
      {
        icon: 'delete',
        action: deletePrompt
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description'),
        type: 'raw'
      },
      {
        title: gettext('Document Classification'),
        param: 'classificationName',
        type: 'text'
      },
      {
        title: gettext('Uploaded'),
        param: 'createdAt',
        type: 'date'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    var icon = {
      type: 5
    };
    icon.name = TranslationService.GetCollectionById(
      'codelists.fileTypes',
      item.fileType
    ).icon;
    item.icon = icon;
    if (item.classification) {
      DocumentClassificationModel.read({
        id: item.classification
      }).then(
        function(res) {
          item.classificationName = res.data.name;
          deferred.resolve();
        },
        function(err) {
          AlertingService.Error(err);
          deferred.resolve();
        }
      );
    }
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}

export default UploadFilesConfiguration;
