import template from './sfe-user-modules.component.html';
import './user-modules.scss';
/**
 * @ngdoc component
 * @name user.sfeUserModules
 * @description displays and allows interaction with user modules access tree.
 * @param {string} userId user id
 * @param {string} treeId tree id
 * @example
 *  <sfe-user-modules
 *   user-id="vm.userId"
 *   tree-id="vm.selectedEntityTreeId"
 *   >
 *  </sfe-user-modules>
 */
export default {
  template,
  bindings: {
    userId: '<',
    treeId: '='
  },
  controller: sfeUserModulesController,
  controllerAs: 'vm'
};

sfeUserModulesController.$inject = [
  '$scope',
  'AuthorizationStaticAccessModel',
  'AlertingService',
  'AuthorizationTreeAccessModel',
  'ToastService',
  'gettext'
];

function sfeUserModulesController(
  $scope,
  AuthorizationStaticAccessModel,
  AlertingService,
  AuthorizationTreeAccessModel,
  ToastService,
  gettext
) {
  var vm = this;
  var TREE_ITEMS;
  vm.changeStaticAccess = changeStaticAccess;
  vm.selectAllInTreeChanged = selectAllInTreeChanged;
  vm.clearFilter = clearFilter;
  /**
   * @description resets search text.
   * @function
   */
  function clearFilter() {
    vm.entitySearch = '';
  }
  /**
   * @description initiates tree.
   * @function
   * @param {event} event
   * @param {args} args broadcast arguments
   */
  $scope.$on('user-module-changed', function(event, args) {
    if (vm.userId && args.treeId) {
      fetchTreeChildren(args.treeId);
    }
  });
  /**
   * @description fetches tree configuration to construct modules access tree.
   * @function
   * @param {string} treeId tree id
   * @return {dataType}
   */
  function fetchTreeChildren(treeId) {
    vm.dataIsLoading = true;
    AuthorizationTreeAccessModel.read({
      owner: vm.userId,
      tree: treeId
    }).then(
      function(res) {
        constructTree(res.data.tree, res.data.access);
        vm.dataIsLoading = false;
      },
      function(err) {
        AlertingService.Error(err);
        vm.dataIsLoading = false;
      }
    );
  }

  function setIndeterminate(item) {
    if (item && item.children) {
      var atLeastOneTrue = false;
      const allTrue = item.children.reduce((result, child) => {
        return result && child.show;
      }, true);
      item.children.forEach(child => {
        setIndeterminate(child);
        if (child.show) {
          atLeastOneTrue = true;
        }
      });
      item.showIndeterminate = !allTrue && atLeastOneTrue && item.show;
    }
  }
  /**
   * @description updates every item in items array and its children.
   * @function
   * @param {Array} items
   * @param {Boolean} value display value status
   * @return {Objet} {promises[], apiObjects[]}
   */
  function getAllItemsToUpdate(items, value, childrenLevel) {
    return items.reduce(
      (result, item) => {
        if (!childrenLevel || item.show != value) {
          let itemsApiObject = {
            scope: item.scope,
            owner: vm.userId,
            show: value,
            access: item.access
          };
          let itemPromise = AuthorizationStaticAccessModel.create(
            itemsApiObject
          );
          let childrenResult = { promises: [], apiObjects: [] };
          if (item.children && item.children.length) {
            childrenResult = getAllItemsToUpdate(item.children, value, true);
          }

          return {
            promises: [
              ...result.promises,
              itemPromise,
              ...childrenResult.promises
            ],
            apiObjects: [
              ...result.apiObjects,
              itemsApiObject,
              ...childrenResult.apiObjects
            ]
          };
        }
        return result;
      },
      {
        promises: [],
        apiObjects: []
      }
    );
  }
  /**
   * @description set module permissions on checkbox click.
   * @function
   * @param {Object} item permission item
   */
  async function changeStaticAccess(item) {
    vm.dataIsLoading = true;
    const { promises, apiObjects } = getAllItemsToUpdate([item], item.show);
    try {
      const results = await Promise.allSettled(promises);

      setValues(results, apiObjects);
      const parentApiObject = findParent(item, item.show);

      await setStaticAccessToParents(parentApiObject);
      vm.dataIsLoading = false;
      vm.selectAllInTree = setSelectAllInTree();
      updateIndeterminateState();
      ToastService.showToast(gettext('Permissions were successfully updated.'));
    } catch (err) {
      AlertingService.Error(err);
      vm.dataIsLoading = false;
      //reset show status
      item.show = !item.show;
    }

    $scope.$applyAsync();
  }

  function setValues(results, apiObjects) {
    results.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        const foundItem = find(apiObjects[index].scope, vm.trees);
        if (foundItem) {
          foundItem.show = apiObjects[index].show;
        }
      }
    });
  }

  async function setStaticAccessToParents(apiObjects) {
    if (Array.isArray(apiObjects)) {
      const promises = apiObjects.map(apiObject =>
        AuthorizationStaticAccessModel.create(apiObject)
      );
      const results = await Promise.allSettled(promises);
      setValues(results, apiObjects);
      vm.selectAllInTree = setSelectAllInTree();
      updateIndeterminateState();
    }
  }
  /**
   * @description returns promises and post objects to change all module privileges.
   * @function
   * @param {Array} items tree items
   * @return {Object} {promise<Array>, postObjects<Array>}
   */
  function constructTreePromises(items) {
    return items.reduce(
      (result, treeItem) => {
        let newPromises = [...result.promises];

        let newPostObjects = [...result.postObjects];
        if (treeItem.show != vm.selectAllInTree) {
          const postObject = {
            owner: vm.userId,
            scope: treeItem.scope,
            scopeName: treeItem.scopeName,
            show: vm.selectAllInTree,
            access: treeItem.access
          };
          newPromises = [
            ...newPromises,
            AuthorizationStaticAccessModel.create(postObject)
          ];

          newPostObjects = [...newPostObjects, postObject];
        }

        if (treeItem.children && treeItem.children.length) {
          const childResult = constructTreePromises(treeItem.children);
          const { promises, postObjects } = childResult;
          newPromises = [...newPromises, ...promises];

          newPostObjects = [...newPostObjects, ...postObjects];
        }

        return {
          postObjects: newPostObjects,
          promises: newPromises
        };
      },
      { postObjects: [], promises: [] }
    );
  }
  /**
   * @description update tree values on toggle.
   * @function
   */
  async function selectAllInTreeChanged() {
    vm.dataIsLoading = true;
    const { promises, postObjects } = constructTreePromises(vm.trees);
    const results = await Promise.allSettled(promises);
    results.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        const item = find(postObjects[index].scope, vm.trees);
        if (item) {
          item.show = vm.selectAllInTree;
        }
      }
    });
    vm.dataIsLoading = false;
    updateIndeterminateState();
    $scope.$applyAsync();
  }

  function findParent(item, value, apiObjects) {
    if (!apiObjects) {
      apiObjects = [];
    }

    const parent = TREE_ITEMS.find(treeItem => treeItem.child === item.scope);

    if (parent && parent.parent !== parent.child) {
      const parentItem = find(parent.parent, vm.trees);
      if (parentItem) {
        let atLeastOneTrue = false;
        let allTrue = true;
        parentItem.children.forEach(child => {
          if (child.show) {
            atLeastOneTrue = true;
          } else {
            allTrue = false;
          }
        });
        if (parentItem.show && atLeastOneTrue && !allTrue) {
          parentItem.showIndeterminate = true;
        }

        if (allTrue || atLeastOneTrue) {
          apiObjects.push({
            scope: parentItem.scope,
            owner: vm.userId,
            show: true,
            access: parentItem.access
          });
        } else {
          parentItem.show = false;
          apiObjects.push({
            scope: parentItem.scope,
            owner: vm.userId,
            show: false,
            access: parentItem.access
          });
        }
        findParent(parentItem);
      }
    }
    return apiObjects;
  }
  /**
   * @description searches for item with scope identifier.
   * @function
   * @param {string} scope scope identifier
   * @param {array} items array of configurations
   * @return {object}
   */
  function find(scope, items) {
    var i = 0;
    var found;

    for (; i < items.length; i++) {
      if (items[i].scope === scope) {
        return items[i];
      } else if (_.isArray(items[i].children)) {
        found = find(scope, items[i].children);
        if (found) {
          return found;
        }
      }
    }
  }
  /**
   * @description recursively checks if all accesses a set to the same value and sets global access value.
   * @function
   * @param {array} items array of tree configurations
   * @return {bool}
   */
  function setSelectAllInTree(items) {
    var allTrue = true;
    if (!items) {
      items = vm.trees;
    }
    for (var i = 0; i < items.length && allTrue; i++) {
      if (!items[i].show) {
        allTrue = false;
      } else {
        if (items[i].children && items[i].children.length) {
          allTrue = setSelectAllInTree(items[i].children);
        }
      }
    }
    return allTrue;
  }
  /**
   * @description iterates over tree configurations and triggers  setIndeterminate function.
   * @function
   */
  function updateIndeterminateState() {
    _.each(vm.trees, function(item) {
      setIndeterminate(item);
    });
  }
  /**
   * @description constructs tree configuration.
   * @function
   */
  function constructTree(treeItems, accessItems) {
    TREE_ITEMS = treeItems;
    var children = {};
    _.each(accessItems, function(item) {
      _.each(treeItems, function(tree) {
        if (item.scope === tree.parent && item.scope !== tree.child) {
          if (!item.children) {
            item.children = [];
          }
          var child = _.find(accessItems, {
            scope: tree.child
          });
          item.children.push(child);
          children[tree.child] = true;
        }
      });
    });

    var showTree = [];
    _.each(accessItems, function(item) {
      if (!children[item.scope]) {
        showTree.push(item);
      }
    });
    vm.trees = showTree;
    vm.selectAllInTree = setSelectAllInTree();
    updateIndeterminateState();
  }
}
