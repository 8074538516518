NewNotificationController.$inject = [
  '$state',
  'gettext',
  'AlertingService',
  'CrudToastFactory',
  'StandardUtils',
  'SfeHeader',
  'NotificationModel'
];

function NewNotificationController(
  $state,
  gettext,
  AlertingService,
  CrudToastFactory,
  StandardUtils,
  SfeHeader,
  NotificationModel
) {
  var vm = this;

  init();

  function init() {
    vm.editMode = !!$state.params.notificationId;
    vm.dataConfig = {
      data: getConfig()
    };

    if (vm.editMode) {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit notification'),
        'notification-notification',
        $state.params
      );
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New notification'),
        'users-created-notifications-list'
      );
    }

    vm.dataConfig.action = {
      ctrlFn: true,
      title: gettext('Create'),
      fn: create,
      invalidFn: function() {
        var input = vm.dataConfig.dataObj;
        if (
          input &&
          (input.sms_content ||
            input.notification_content ||
            input.email_content)
        ) {
          return false;
        }
        return true;
      }
    };
  }

  function getConfig() {
    var config = [
      {
        placeholder: gettext('Subject'),
        name: 'subject',
        componentType: 'textField',
        type: 'text',
        required: true
      },
      {
        name: 'users',
        empty: gettext('There are no users to select.'),
        edit: true,
        componentType: 'autocompleteChipDialog',
        fallbackUrl: './img/profile/nopic_192.gif',
        entity: 'users',
        searchParam: 'full_name',
        required: false,
        filterFn: items => {
          return items.map(item => {
            return {
              ...item,
              imageUrl: StandardUtils.getUserImageUrl(item, 'sm')
            };
          });
        },
        networkModel: {
          list: {
            entity: 'users',
            method: 'read'
          }
        },
        floatingLabel: gettext('Select User')
      },
      {
        name: 'groups',
        empty: gettext('There are no groups to select.'),
        edit: true,
        componentType: 'autocompleteChipDialog',
        entity: 'groups',
        searchParam: 'name',
        required: false,
        networkModel: {
          list: {
            entity: 'groups',
            method: 'read'
          }
        },
        floatingLabel: gettext('Select group'),
        displayFields: ['name']
      },
      {
        name: 'organizations',
        empty: gettext('There are no organizations to select.'),
        edit: true,
        componentType: 'autocompleteChipDialog',
        entity: 'realms',
        searchParam: 'name',
        required: false,
        networkModel: {
          list: {
            entity: 'realms',
            method: 'read'
          }
        },
        floatingLabel: gettext('Select organization'),
        displayFields: ['name']
      },
      {
        componentType: 'labelValue',
        label: gettext(
          'Fill in at least one field of email, notification or sms content.'
        )
      },
      {
        placeholder: gettext('Email content'),
        name: 'email_content',
        componentType: 'textAreaEditor'
      },
      {
        placeholder: gettext('Notification content'),
        name: 'notification_content',
        componentType: 'textAreaEditor'
      },
      {
        placeholder: gettext('Sms content'),
        name: 'sms_content',
        componentType: 'textArea',
        maxlength: 500,
        required: false
      }
    ];

    return config;
  }

  function getApiObject(item) {
    return {
      subject: item.subject,
      email_content: item.email_content || null,
      sms_content: item.sms_content || null,
      notification_content: item.notification_content || null,
      users: _.map(item.users, '_id'),
      groups: _.map(item.groups, '_id'),
      organizations: _.map(item.organizations, '_id')
    };
  }

  function create(item) {
    var apiObject = getApiObject(item);
    vm.sendingRequest = true;
    NotificationModel.create(apiObject).then(
      function() {
        vm.sendingRequest = false;
        var redirectObj = {
          state: 'users-created-notifications-list'
        };
        CrudToastFactory.toast('create', redirectObj);
        vm.dataConfig.dataObj = {};
      },
      function(err) {
        AlertingService.Error(err);
        vm.sendingRequest = false;
      }
    );
  }
}
export default NewNotificationController;
