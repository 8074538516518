module.exports = {
  entity: 'detail-invoices',
  path: 'detail-invoices',
  name: {
    en: 'Detail invoices',
    sl_SI: 'Računi'
  },
  networkModel: {
    entity: 'detail-invoices',
    read: 'custom.readMasterPopulate'
  },
  scope: 'tis_be:detail-invoices',

  enrichArray: [
    {
      from: 'master-invoices',
      localField: '_id',
      foreignField: 'masterInvoice',
      as: 'masterInvoiceObject'
    }
  ],
  displayFields: [
    {
      field1: 'masterInvoice',
      field2: 'number',
      type: 'string'
    },
    {
      field1: 'readDate',
      type: 'date'
    }
  ],
  dialog: {
    title: {
      en: 'Select detail invoice',
      sl_SI: 'Izberite račun'
    },
    listInfo: {
      en: 'Select detail invoices from the list below',
      sl_SI: 'Izberite račun s spodnjega seznama'
    },
    enrichArray: [
      {
        from: 'master-invoices',
        localField: '_id',
        foreignField: 'masterInvoice',
        as: 'masterInvoiceObject'
      }
    ],
    displayFields: [
      {
        field1: 'masterInvoice',
        field2: 'number',
        type: 'string'
      },
      {
        field1: 'readDate',
        type: 'date'
      }
    ]
  }
}
