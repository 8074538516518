import './sfe-alarm-card.scss';
import template from './sfe-alarm-card.directive.html';

export default function sfeAlarmCard() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      size: '=',
      cacheInvalidationTime: '<?',
      refresher: '<'
    },
    link: linkFn,
    controller: sfeAlarmCardController,
    controllerAs: 'vm',
    bindToController: true
  };
  return directive;

  function linkFn() {}
}

sfeAlarmCardController.$inject = [
  '$scope',
  '$smartAuth',
  'ListItemsService',
  'AlertingService',
  'TriggeredAlarmModel',
  'gettextCatalog',
  'Refreshing',
  'AlarmMonitoringConfiguration',
  'SmallAlarmCardConfiguration'
];

function sfeAlarmCardController(
  $scope,
  $smartAuth,
  ListItemsService,
  AlertingService,
  TriggeredAlarmModel,
  gettextCatalog,
  Refreshing,
  AlarmMonitoringConfiguration,
  SmallAlarmCardConfiguration
) {
  var vm = this;
  vm.missingDataIcon = {
    type: 2,
    name: 'not_interested'
  };

  vm.alarmsBySeverity = {};
  vm.page = 1;
  vm.limit = 3;
  vm.onPaginate = onPaginate;
  vm.$onInit = init;

  vm.paginationLabels = {
    of: gettextCatalog.getString('of'),
    rowsPerPage: gettextCatalog.getString('Per page'),
    page: gettextCatalog.getString('page')
  };

  var alarmStatuses = [1, 2, 5, 4];
  var refresherId, refresherFnId;

  async function init() {
    try {
      vm.userId = (await $smartAuth.profile())._id;
      vm.alarmsAreLoading = true;
      await setUpCard();
      if (vm.refresher) {
        await ({ refresherId, refresherFnId } = vm.refresher(async () => {
          await setUpCard();
        }));
      }
    } catch (err) {
      AlertingService.Error(err);
    }
  }
  /**
   * @description triggers alarm init methods according to the card size.
   * @function
   */
  async function setUpCard() {
    switch (vm.size) {
    case 1:
      await getAlarms();
      break;
    case 2:
    case 3:
    case 4:
      fetchAlarmsForMediumCard();
      break;
    case 5:
      setFullWidthDoubleHightCard();
      break;
    }
  }

  $scope.$on('$destroy', function() {
    if (refresherId) {
      Refreshing.removeFn(refresherId, refresherFnId);
    }
  });
  /**
   * @description function is triggered on page change and when needed fetches missing data.
   * @function
   * @param {Number} page current page
   * @param {Number} limit current limit
   */
  function onPaginate(page, limit) {
    if (page * limit > vm.alarms.length && vm.alarms.length < vm.total) {
      vm.alarmsReLoading = true;
      fetchAlarmsForMediumCard();
    }
  }
  /**
   * @description fetches triggered alarms for small card.
   * @function
   */
  async function getAlarms() {
    vm.smallCardListConfig = SmallAlarmCardConfiguration.get(vm.userId);

    vm.smallCardFilterObject = {
      currentAssignee: vm.userId,
      alarmStatus: alarmStatuses
    };
    const { pagination } = await TriggeredAlarmModel.read(
      {
        currentAssignee: vm.userId,
        alarmStatus: alarmStatuses,
        limit: 1
      },
      vm.cacheInvalidationTime
    );

    vm.alarmsAreLoading = false;
    if (pagination != null && typeof pagination == 'object') {
      vm.alarmsCount = pagination.total;
    } else {
      vm.alarmsCount = 0;
    }
  }
  /**
   * @description fetches triggered alarms and sets vm.alarm array.
   * @function
   */
  function fetchAlarmsForMediumCard() {
    vm.limit = vm.size === 2 || vm.size === 4 ? 3 : 12;
    const filterObject = {
      currentAssignee: [vm.userId],
      limit: vm.limit,
      page: vm.page || 1,
      alarmStatus: alarmStatuses,
      populate: 'timeSeries'
    };

    vm.listConfiguration = {
      hideLimitOptions: true
    };
    const apiCall = {
      entity: 'triggered-alarms',
      method: 'custom.readAlarmCache'
    };

    ListItemsService.fetchItems(
      apiCall,
      [],
      filterObject,
      vm.cacheInvalidationTime
    ).then(function(res) {
      vm.total = res.pagination.total;
      if (!vm.alarms) {
        vm.alarms = [];
      }
      let { data: alarms } = res;
      alarms = alarms.map(alarm => {
        let triggeredAlarmTranslations = {
          alarmName: gettextCatalog.getString('Unknown Alarm'),
          timeSeriesName: gettextCatalog.getString('Unknown Time Series')
        };
        if (alarm.alarmObject != null && alarm.alarmObject.name != null) {
          triggeredAlarmTranslations = {
            ...triggeredAlarmTranslations,
            alarmName: alarm.alarmObject.name
          };
          let redirectParams = JSON.stringify({ id: alarm.alarmObject._id });
          alarm.alarmLink = [
            {
              icon: {
                name: 'link',
                type: 2
              },
              link: `alarms-and-rules-alarm-definitions-view(${redirectParams})`
            }
          ];
        }
        if (alarm.timeSeries != null && alarm.timeSeries.name != null) {
          triggeredAlarmTranslations = {
            ...triggeredAlarmTranslations,
            timeSeriesName: alarm.timeSeries.name
          };
          let redirectParams = JSON.stringify({ id: alarm.timeSeries._id });

          alarm.timeSeriesLink = [
            {
              icon: {
                name: 'link',
                type: 2
              },
              link: `data-time-series-view(${redirectParams})`
            }
          ];
        }
        return {
          ...alarm,
          title: gettextCatalog.getString(
            '{{alarmName}} at {{timeSeriesName}}',
            triggeredAlarmTranslations
          ),
          style: {
            color:
              alarm && alarm.alarmObject && alarm.alarmObject.severity
                ? alarm.alarmObject.severity.color
                : ''
          }
        };
      });
      vm.alarms = vm.alarms.concat(alarms);
      vm.alarmsAreLoading = false;
      vm.alarmsReLoading = false;
      $scope.$evalAsync();
    });
  }
  /**
   * @description sets list data for FULL WIDTH DOUBLE HEIGHT alarm card.
   * @function
   */
  async function setFullWidthDoubleHightCard() {
    vm.alarmsAreLoading = true;
    let alarmConfig = angular.copy(AlarmMonitoringConfiguration);
    vm.triggeredAlarmSfeListConfig = {
      ...alarmConfig,
      limitOptions: [8, 10]
    };
    // await $timeout(() => {}, 10000000)
    if (vm.userId) {
      vm.filterObject = {
        currentAssignee: vm.userId,
        alarmStatus: alarmStatuses
      };
      TriggeredAlarmModel.read(vm.filterObject, vm.cacheInvalidationTime).then(
        function(res) {
          vm.total = res.pagination.total;
          vm.alarmsAreLoading = false;
        },
        function(err) {
          vm.alarmsAreLoading = false;
          AlertingService.Error(err);
        }
      );
    }
  }
}
