gisConfiguration.$inject = ['gettext', 'GisMapModel', '$q', 'filterService'];

function gisConfiguration(gettext, GisMapModel, $q, filterService) {
  var gisMaps;

  var fetchMaps = {
    query: function(filterInput) {
      var deferred = $q.defer();
      var data;
      var filterObjects = [];
      configuration.filter.forEach(function(filter) {
        if (filterInput[filter.param]) {
          filterObjects.push({
            searchTerm: filterInput[filter.param],
            param: filter.param
          });
        }
      });

      if (gisMaps && gisMaps.length) {
        data = filterService.filterDataV1(gisMaps, filterObjects);
        filterObjects = [];
        deferred.resolve({ data: data });
      } else {
        GisMapModel.read().then(
          function(res) {
            gisMaps = res.data;
            data = filterService.filterDataV1(gisMaps, filterObjects);
            filterObjects = [];
            deferred.resolve({ data: data });
          },
          function() {
            deferred.resolve({
              data: []
            });
          }
        );
      }
      return {
        $promise: deferred.promise
      };
    }
  };

  var configuration = {
    entity: {
      singular: gettext('GIS Map'),
      plural: gettext('GIS Maps')
    },
    api: {
      query: fetchMaps
    },
    title: {
      param: 'title',
      link: {
        state: 'maps-gis-maps-view',
        params: [
          {
            valueParam: 'id',
            queryParam: 'id'
          }
        ]
      }
    },
    attributes: [],
    filter: [
      {
        display: gettext('Name'),
        param: 'title',
        type: 'string'
      }
    ]
  };

  return configuration;
}
export default gisConfiguration;
