ActionConfiguration.$inject = [
  'gettext',
  '$q',
  'AlertingService',
  'LocationActionModel',
  'SfeFormDialogService',
  'CrudToastFactory',
  '$state',
  '$filter'
];

function ActionConfiguration(
  gettext,
  $q,
  AlertingService,
  LocationActionModel,
  SfeFormDialogService,
  CrudToastFactory,
  $state,
  $filter
) {
  function onStatusClose(selected) {
    if (this.config) {
      var locationActionStatusConfig = this.config.find(function(item) {
        return item.name === 'locationActionStatus';
      });
    }
    if (locationActionStatusConfig) {
      var selectedOption = locationActionStatusConfig.options.find(function(
        option
      ) {
        return selected._id === option._id;
      });

      this.dataObj.statusIsFinished =
        selectedOption && selectedOption.code === 'F';
    }
  }
  function getForm(editDialog) {
    return [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        showCond: true
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textArea',
        maxlength: 500,
        required: false
      },
      {
        componentType: 'multiSelect',
        config: {
          label: gettext('Action status'),
          placeholder: gettext('Select action status'),
          refreshFn: {
            entity: 'location-action-statuses',
            method: 'read'
          },
          empty: gettext('There are no action statuses to select from.'),
          onClose: onStatusClose,
          edit: editDialog
        },
        name: 'locationActionStatus'
      },
      {
        hide: true,
        showParam: 'statusIsFinished',
        componentType: 'singleDate',
        dateName: 'completedOn',
        dateLabel: gettext('Date Finished'),
        idDate: 'unique',
        noTimeInput: true,
        required: false
      }
    ];
  }

  var createObject = {
    name: '',
    description: '',
    status: {},
    completedOn: ''
  };

  function openCreateDialog(relistData) {
    var config = getForm(false);
    var title = gettext('New action');
    config.forEach(function(item) {
      if (item.select) {
        item.config.edit = false;
        if (item.name === 'locationActionStatus') {
          item.config.onClose = item.config.onClose.bind({
            dataObj: createObject,
            config: config
          });
        }
      } else {
        item.edit = false;
      }
    });
    SfeFormDialogService.openSfeFormDialog(
      false,
      config,
      createObject,
      title
    ).then(function(object) {
      if (object) {
        create(object, relistData);
      }
    });
  }

  function openUpdateDialog(item, relistData) {
    var config = getForm(true);
    var title = gettext('Update Action');
    var completedOn =
      new Date(item.completedOn) instanceof Date
        ? new Date(item.completedOn)
        : undefined;

    var updateObject = {
      name: item.name,
      description: item.description,
      completedOn: completedOn,
      locationActionStatus: Object.assign({}, item.locationActionStatus),
      location: item.location,
      asset: item.asset,
      statusIsFinished:
        item.locationActionStatus && item.locationActionStatus === 'F'
    };
    config.forEach(function(item) {
      if (item.select) {
        item.config.edit = true;
        if (item.name === 'locationActionStatus') {
          item.config.onClose = item.config.onClose.bind({
            dataObj: updateObject,
            config: config
          });
        }
      } else {
        item.edit = true;
      }
    });
    SfeFormDialogService.openSfeFormDialog(
      true,
      config,
      updateObject,
      title
    ).then(function(object) {
      if (object) {
        update(object, item, relistData);
      }
    });
  }

  function update(obj, originalItem, relistData) {
    var apiObj = {
      name: obj.name,
      description: obj.description,
      locationActionStatus: obj.locationActionStatus._id,
      completedOn: obj.statusIsFinished ? obj.completedOn : null,
      location: obj.location,
      asset: obj.asset
    };
    LocationActionModel.update(
      {
        id: originalItem._id
      },
      apiObj
    ).then(
      function() {
        relistData();
        CrudToastFactory.toast('create');
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function create(obj, relistData) {
    var apiObj = {
      name: obj.name,
      description: obj.description,
      completedOn: obj.statusIsFinished ? obj.completedOn : undefined,
      locationActionStatus: obj.locationActionStatus._id
    };
    if ($state.current.name === 'company-resources-locations-view') {
      apiObj.location = $state.params.id;
    }
    if ($state.current.name === 'company-resources-assets-view') {
      apiObj.asset = $state.params.id;
    }
    LocationActionModel.create(apiObj).then(
      function() {
        CrudToastFactory.toast('create');
        relistData();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  var configuration = {
    listId: 'actionListId',
    entity: {
      singular: gettext('Action'),
      plural: gettext('Actions')
    },
    api: {
      query: {
        entity: 'location-actions',
        method: 'custom.readLocationActionStatuses'
      },
      enrich: Enrich
    },
    title: {
      param: 'name'
    },
    buttons: [
      {
        title: gettext('Create'),
        fn: openCreateDialog,
        relist: true,
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        action: openUpdateDialog
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'location-actions',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Status'),
        param: 'statusName',
        type: 'text'
      },
      {
        title: gettext('Date finished'),
        param: 'completedOnReadable',
        type: 'text'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        param: 'locationActionStatus',
        query: {
          entity: 'location-action-statuses',
          method: 'read'
        },
        entity: 'location-action-statuses',
        display: gettext('Status')
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      },
      {
        display: 'name DESC',
        params: '-name'
      },
      {
        display: 'name ASC',
        params: 'name'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    if (item.hasOwnProperty('locationActionStatus')) {
      item.statusName = item.locationActionStatus
        ? item.locationActionStatus.name
        : undefined;
    }
    item.completedOnReadable = item.completedOn
      ? $filter('date')(new Date(item.completedOn), 'dd. MM. yyyy.')
      : 'None';
    return deferred.promise;
  }

  return configuration;
}
export default ActionConfiguration;
