import template from './sfe-user-card.component.html';
/**
 * @ngdoc component
 * @name user.sfeUserCard
 * @description description.
 * @param {string} userId user/group  id
 * @param {bool} isGroup inidcates if item is a group
 * @example
 * <sfe-user-card user-id="::vm.groupId" is-group="true"></sfe-user-card>
 */
export default {
  template,
  bindings: {
    userId: '<',
    isGroup: '<',
    entityItem: '<'
  },
  controller: SfeUserCardController,
  controllerAs: 'vm'
};

SfeUserCardController.$inject = [
  '$q',
  'AlertingService',
  'SfeFormDialogService',
  'gettext',
  'InfoDialog',
  'ToastService',
  'utilService',
  '$smartAuth',
  'StandardUtils',
  'AuthorizationToEditService',
  'TranslationService',
  'filterService',
  '$window',
  '$state',
  'LocalizationService',
  '$mdDialog',
  'UserModel',
  'CrawlerMethods',
  '$rootScope',
  'gettextCatalog',
  'GenerateUISrefLinkService',
  'Formatting'
];

function SfeUserCardController(
  $q,
  AlertingService,
  SfeFormDialogService,
  gettext,
  InfoDialog,
  ToastService,
  utilService,
  $smartAuth,
  StandardUtils,
  AuthorizationToEditService,
  TranslationService,
  filterService,
  $window,
  $state,
  LocalizationService,
  $mdDialog,
  UserModel,
  CrawlerMethods,
  $rootScope,
  gettextCatalog,
  GenerateUISrefLinkService,
  Formatting
) {
  var vm = this;
  var gisGroups;
  var gisUsers;
  vm.relistData = false;
  vm.loading = true;
  vm.openAddUserToGroupDialog = openAddUserToGroupDialog;
  vm.removeUserFromGroupPrompt = removeUserFromGroupPrompt;
  vm.addMultipleUsersToGroup = addMultipleUsersToGroup;
  vm.uploadUserAvatar = uploadUserAvatar;
  vm.pairWithGis = pairWithGis;
  vm.addGisGroups = addGisGroups;
  vm.resetPassword = resetPassword;
  vm.changePassword = changePassword;
  /**
   * @description when user id is avaialable set loading to false.
   * @function
   * @param {array} changes array of changed bindings
   */
  vm.$onChanges = function(changes) {
    if (changes.userId && vm.userId) {
      vm.loading = false;
    }
  };
  /**
   * @description initiates user/group card components.
   * @function
   */
  vm.$onInit = function() {
    vm.filters = {
      id: vm.userId
    };

    vm.configuration = {
      entity: {
        singular: gettext('Member'),
        plural: gettext('Members')
      },
      api: {
        query: {
          entity: 'users-in-group',
          method: 'read'
        },
        queryParam: 'group',
        enrich: Enrich
      },
      title: {
        imgUrlParam: 'imageUrl',
        param: 'userName',
        gravatarParam: 'entity',
        link: {
          state: 'users-users-view',
          params: [
            {
              valueParam: 'entity',
              queryParam: 'id'
            }
          ]
        }
      },
      shortActions: [
        {
          icon: 'delete',
          type: 'delete',
          action: function(item) {
            var deferred = $q.defer();
            removeUserFromGroupPrompt(item.entity, vm.userId, true);
            return deferred.resolve();
          }
        }
      ],
      attributes: [],
      sort: [
        {
          display: 'name ASC',
          params: 'name'
        },
        {
          display: 'name DESC',
          params: '-name'
        }
      ]
    };
    init();
  };
  /**
   * @description fetches user metadata and sets vales to scope.
   * @function
   */
  async function init() {
    await isLoggedInUser();
    var parallel = {
      groups: getGroups,
      loggedInUser: getLoggedInUser
    };
    async.parallel(parallel, function(err, res) {
      if (err || !res) {
        return;
      }
      vm.editPermissions = AuthorizationToEditService.isAuthorizedToEdit();
      var country;
      if (vm.entityItem && vm.entityItem.address) {
        country = TranslationService.GetCollectionById(
          'codelists.countries',
          parseInt(vm.entityItem.address.country)
        );
      }
      vm.entityItem.groups = res.groups;
      vm.user = vm.entityItem;
      vm.userLanguage = LocalizationService.FindLanguageByCode(
        vm.user.language
      );
      vm.user.country = country;
      vm.usersInGroup = res.usersInGroup;
      if (res.loggedInUser._id === vm.entityItem._id) {
        vm.displayEditImg = true;
      }
      vm.user.imageUrl = StandardUtils.getUserImageUrl(vm.user, 'md');
      if (!vm.isGroup) {
        if (vm.user.gisUserId) {
          fetchGisUser(vm.user.gisUserId).then(function(gisUser) {
            vm.gisUser = gisUser;
          });
        }
      }
      if (vm.isGroup && vm.user.parent) {
        getGroupParent().then(function(parent) {
          vm.user.parentObject = parent;
        });
      }
      if (vm.user.phone_number) {
        vm.user.phone_number_formated = Formatting.formatPhoneNumber(
          vm.user.phone_number
        );
      }
      vm.actions = createActionsArray(
        vm.userId,
        vm.isGroup,
        !!vm.user.gisUserId,
        vm.editPermissions,
        vm.isLoggedIn
      );
    });
  }
  /**
   * @description filters array of items.
   * @function
   * @param {array} fetched items fetched from api
   * @param {array} filter array of filters to apply to fetch items
   * @param {array} added array of already added items
   * @return {array}
   */
  function filterItems(fetched, filter, added) {
    var found;
    var data = [];
    var items = filterService.filterDataV1(fetched, filter, 'id');
    if (items) {
      items.forEach(function(item) {
        if (added) {
          found = added.find(function(addedGroup) {
            return addedGroup.id === item.id;
          });
          if (!found) {
            data.push(item);
          }
        } else {
          data.push(item);
        }
      });
    }
    return data;
  }
  /**
   * @description fetches gis groups.
   * @function
   * @param {object} paramObj filter parameter object
   * @return {object} {$promise}
   */
  async function fetchGroups(paramObj) {
    var dataObj = this.dataObj;
    var data;
    var filter = [
      {
        param: 'name',
        searchTerm: paramObj.name,
        type: 'string'
      }
    ];
    if (gisGroups && gisGroups.length) {
      data = filterItems(gisGroups, filter, dataObj.user_group_ids);
      return data;
    } else {
      var method = CrawlerMethods.getMethod({
        entity: 'gis-user-group',
        method: 'read'
      });
      try {
        const { data: res } = await method();
        gisGroups = res;
        data = filterItems(gisGroups, filter, dataObj.user_group_ids);
        return data;
      } catch (err) {
        AlertingService.Error(err);
        return [];
      }
    }
  }
  /**
   * @description opens gis group selector dialog and saves gis groups.
   * @function
   */
  function addGisGroups() {
    let groups =
      vm.gisUser != null && Array.isArray(vm.gisUser.groups)
        ? vm.gisUser.groups
        : [];
    var groupsDataObj = {
      user_group_ids: groups,
      _preserve_: true
    };
    var originalItems = angular.copy(groups);
    var config = [
      {
        name: 'user_group_ids',
        empty: gettext('There are no groups to select.'),
        edit: true,
        componentType: 'autocompleteChipDialog',
        required: true,
        entity: 'gis-user-group',
        searchParam: 'name',
        valueParam: 'id',
        query: fetchGroups.bind({ dataObj: groupsDataObj }),
        floatingLabel: gettext('Select group'),
        displayFields: ['name']
      }
    ];

    SfeFormDialogService.openSfeFormDialog(
      true,
      config,
      groupsDataObj,
      gettext('Update groups')
    ).then(
      function(forObject) {
        if (forObject) {
          var method = CrawlerMethods.getMethod({
            entity: 'gis-users',
            method: 'update'
          });
          method(
            {
              id: vm.gisUser.id
            },
            {
              user_group_ids: forObject.user_group_ids.map(function(group) {
                return group.id;
              })
            }
          ).then(
            function() {
              vm.gisUser.groups = forObject.user_group_ids;
              ToastService.showToast(
                gettext('Gis user groups were successfully updated.')
              );
            },
            function(err) {
              AlertingService.Error(err);
            }
          );
        }
      },
      function() {
        vm.gisUser.groups = originalItems;
      }
    );
  }
  /**
   * @description checks if profile we are editing is currently logedin user.
   * @function
   */
  async function isLoggedInUser() {
    await $smartAuth.profile().then(
      function(userProfile) {
        if (userProfile._id === $state.params.id) {
          vm.isLoggedIn = true;
        } else {
          vm.isLoggedIn = false;
        }
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }
  /**
   * @description opens change password dialog and saves new password data.
   * @function
   */
  function changePassword() {
    var obj = {};

    var config = [
      {
        componentType: 'password',
        name: 'newPassword',
        placeholder: gettext('New password'),
        validation: {
          equalTo: 'confirmPassword'
        }
      },
      {
        componentType: 'password',
        name: 'confirmPassword',
        placeholder: gettext('Confirm new password'),
        validation: {
          equalTo: 'newPassword'
        }
      }
    ];
    var title = gettext('Change password');

    SfeFormDialogService.openSfeFormDialog(false, config, obj, title).then(
      function(obj) {
        if (obj && obj.newPassword) {
          UserModel.custom
            .changePassword({
              password: obj.newPassword
            })
            .then(
              function() {
                ToastService.showToast(
                  gettext('Password was successfully updated.')
                );
              },
              function(err) {
                AlertingService.Error(err);
              }
            );
        }
      }
    );
  }
  /**
   * @description opens gis user selector dialog.
   * on new user selection updates tango user gis token and gis user data
   * @function
   */
  function pairWithGis() {
    var apiObj = {
      _preserve_: true
    };
    if (vm.user && vm.user.gisUserId) {
      apiObj.gisUser = vm.user.gisUserId;
    }
    var config = [
      {
        name: 'gisUser',
        componentType: 'autocompleteDialog',
        required: true,
        edit: !!apiObj.gisUser,
        configuration: {
          searchParamName: 'name',
          query: {
            query: function(paramObj) {
              var deferred = $q.defer();
              var data;
              var filter = [
                {
                  param: 'first_name',
                  searchTerm: paramObj.name,
                  type: 'string'
                },
                {
                  param: 'last_name',
                  searchTerm: paramObj.name,
                  type: 'string'
                }
              ];

              if (gisUsers && gisUsers.length) {
                data = filterItems(gisUsers, filter, []);
                deferred.resolve({ data: data });
              } else {
                var method = CrawlerMethods.getMethod({
                  entity: 'gis-users',
                  method: 'read'
                });
                method(paramObj).then(
                  function(res) {
                    if (res.data && res.data.length) {
                      gisUsers = res.data;
                      data = filterItems(gisUsers, filter, []);
                      deferred.resolve({ data: data });
                    } else {
                      deferred.resolve(res);
                    }
                  },
                  function() {
                    deferred.resolve({
                      data: []
                    });
                  }
                );
              }

              return {
                $promise: deferred.promise
              };
            }
          },
          entity: 'gis-users',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Gis User')
          },
          floatingLabel: gettext('Select GIS user'),
          required: true,
          action: {
            icon: 'add',
            execute: function() {
              var dataObj = {
                _preserve_: true
              };
              var configuration = [
                {
                  placeholder: gettext('Username'),
                  name: 'username',
                  componentType: 'textField',
                  type: 'text'
                },
                {
                  placeholder: gettext('Name'),
                  name: 'first_name',
                  componentType: 'textField',
                  type: 'text'
                },
                {
                  placeholder: gettext('Lastname'),
                  name: 'last_name',
                  componentType: 'textField',
                  type: 'text'
                },
                {
                  placeholder: gettext('Title'),
                  name: 'title',
                  componentType: 'textField',
                  type: 'text'
                },
                {
                  placeholder: gettext('E-mail'),
                  name: 'email',
                  componentType: 'textField',
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  patternExample: 'example@email.com',
                  type: 'text'
                },
                {
                  placeholder: gettext('Password'),
                  componentType: 'password',
                  name: 'password',
                  validation: {
                    equalTo: 'password_confirmation'
                  }
                },
                {
                  placeholder: gettext('Password confirmation'),
                  componentType: 'password',
                  name: 'password_confirmation',
                  validation: {
                    equalTo: 'password'
                  }
                },
                {
                  name: 'user_group_ids',
                  empty: gettext('There are no groups to select.'),
                  edit: true,
                  componentType: 'autocompleteChipDialog',
                  entity: 'gis-group',
                  searchParam: 'name',
                  valueParam: 'id',
                  required: true,
                  query: fetchGroups.bind({ dataObj: dataObj }),
                  floatingLabel: gettext('Select group'),
                  displayFields: ['name']
                }
              ];
              SfeFormDialogService.openSfeFormDialog(
                false,
                configuration,
                dataObj,
                gettext('Create GIS user')
              ).then(
                function(newUser) {
                  if (newUser) {
                    newUser.user_group_ids = (newUser.user_group_ids || []).map(
                      function(item) {
                        return item.id;
                      }
                    );
                    var method = CrawlerMethods.getMethod({
                      entity: 'gis-users',
                      method: 'create'
                    });
                    method(newUser).then(
                      function(res) {
                        method = CrawlerMethods.getMethod({
                          entity: 'gis-users',
                          method: 'read'
                        });
                        method({
                          id: res.data.user_id
                        }).then(
                          function(res) {
                            apiObj.gisUser = res.data;
                          },
                          function(err) {
                            AlertingService.Error(err);
                          }
                        );
                        ToastService.showToast(
                          gettext('Gis user was successfully created.')
                        );
                      },
                      function(err) {
                        AlertingService.Error(err);
                      }
                    );
                  }
                },
                function() {}
              );
            }
          }
        }
      }
    ];
    var title = gettext('Pair GIS user');
    SfeFormDialogService.openSfeFormDialog(false, config, apiObj, title).then(
      function(formObject) {
        if (formObject && formObject.gisUser && formObject.gisUser.id) {
          var waterfall = [
            async.apply(createGisToken, formObject.gisUser.id),
            connectGisToTisUser
          ];
          async.waterfall(waterfall, function(err) {
            if (!err) {
              vm.gisUser = formObject.gisUser;
              vm.user.gisUserId = formObject.gisUser.id;
              if (!formObject.gisUser.groups) {
                fetchGisUser(vm.user.gisUserId).then(
                  function(user) {
                    vm.gisUser = user;
                  },
                  function() {
                    AlertingService.Error('Couldn\'t set gis user groups.');
                  }
                );
              }
              ToastService.showToast('Gis user was successfully paired');
            } else {
              AlertingService.Error(err);
            }
          });
        }
      },
      function() {}
    );
  }
  /**
   * @description requests new gis token.
   * @function
   * @param {string} gisUserId gis user id
   * @param {function} callback callback function
   */
  function createGisToken(gisUserId, callback) {
    var method = CrawlerMethods.getMethod({
      entity: 'gis-users',
      method: 'custom.createToken'
    });
    method(
      {
        userId: gisUserId
      },
      {
        scopes: ['api-all'],
        name: 'TIS' + gisUserId
      }
    ).then(
      function(res) {
        callback(null, gisUserId, res.data.token);
      },
      function(err) {
        callback(err);
      }
    );
  }
  /**
   * @description updates tango user with new gis user data.
   * @function
   * @param {string} gisUserId gis user id
   * @param {string} token gis token
   * @param {function} callback
   */
  function connectGisToTisUser(gisUserId, token, callback) {
    var method = CrawlerMethods.getMethod({
      entity: 'profiles',
      method: 'create'
    });
    method(
      {},
      {
        gisUserId: gisUserId,
        gisUserToken: token,
        user: vm.user._id,
        phone_number: vm.user.phone_number,
        email: vm.user.email,
        name: vm.user.name,
        family_name: vm.user.family_name
      }
    ).then(
      function() {
        callback(null, token);
      },
      function(err) {
        callback(err);
      }
    );
  }
  function resetPassword(userId) {
    function reset() {
      $mdDialog.cancel();
      UserModel.custom
        .resetPassword({
          user: userId,
          link: $window.location.origin
        })
        .then(
          function() {
            ToastService.showToast(gettext('Password was successfully reset'));
          },
          function(err) {
            AlertingService.Error(err);
          }
        );
    }
    var title = gettext('Confirmation');
    var textItem = {
      text: gettext(
        'Are you sure you want to reset password? User will have to set new password at the next login.'
      ),
      type: 'text'
    };
    var actions = [
      {
        title: gettext('Cancel'),
        cancel: true,
        color: 'primary'
      },
      {
        title: gettext('Reset'),
        fn: reset,
        color: 'warn'
      }
    ];
    InfoDialog.open(title, null, [textItem], actions);
  }
  /**
   * @description opens image uploader dialog and updates user profile image.
   * @function
   */
  function uploadUserAvatar() {
    var config = [
      {
        componentType: 'fileUpload',
        name: 'files',
        fileUploader: 'fileUploader',
        endpoint: utilService.getApiAccount + '/fileup/image',
        single: false,
        alias: 'image',
        queueLimit: 1,
        filters: [
          {
            name: 'imageFilter',
            fn: function(item) {
              var type =
                '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
              return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
            }
          }
        ]
      }
    ];

    SfeFormDialogService.openSfeFormDialog(
      false,
      config,
      {},
      gettext('Upload image')
    ).then(
      function(res) {
        if (res) {
          res.fileUploader.onCompleteItem = function(item, response, status) {
            if (status === 200) {
              updateUserImg(response.data._id);
            } else {
              AlertingService.Error(response.error);
            }
          };
          res.fileUploader.uploadAll();
        }
      },
      function() {}
    );
  }
  /**
   * @description updates user profile image.
   * @function
   * @param {string} img new image id
   */
  function updateUserImg(img) {
    var method = CrawlerMethods.getMethod({
      entity: 'profiles',
      method: 'create'
    });
    method({
      picture: img,
      user: vm.user._id,
      phone_number: vm.user.phone_number,
      email: vm.user.email,
      name: vm.user.name,
      family_name: vm.user.family_name
    }).then(
      function() {
        vm.user.picture = img;
        $rootScope.$broadcast('userChanged', vm.user);
        vm.user.imageUrl = StandardUtils.getUserImageUrl(vm.user, 'md');
        var confirmationText = img
          ? gettext('Image was successfully uploaded.')
          : gettext('Image was successfully removed.');
        vm.user.picture = img;
        ToastService.showToast(confirmationText);
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }
  /**
   * @description users in current group enrich function.
   * @function
   * @param {object} item item to enrich
   * @return {Promise}
   */
  function Enrich(item) {
    var deferred = $q.defer();
    item.userName = item.family_name
      ? item.name + ' ' + item.family_name
      : item.name;
    item.imageUrl = StandardUtils.getUserImageUrl(item, 'sm');
    item.entity = item._id;
    deferred.resolve();

    return deferred.promise;
  }
  /**
   * @description fetches logedin user.
   * @function
   * @param {function} callback
   */
  function getLoggedInUser(callback) {
    $smartAuth.profile().then(
      function(userProfile) {
        callback(null, userProfile);
      },
      function() {
        callback(null, null);
      }
    );
  }
  /**
   * @description fetches gisUser.
   * @function
   * @param {string} gisUserId gis user id
   * @return {Promise}
   */
  function fetchGisUser(gisUserId) {
    var deferred = $q.defer();
    var method = CrawlerMethods.getMethod({
      entity: 'gis-users',
      method: 'read'
    });
    method({
      id: gisUserId
    }).then(
      function(res) {
        deferred.resolve(res.data);
      },
      function(err) {
        AlertingService.Error(err);
        deferred.resolve();
      }
    );
    return deferred.promise;
  }
  /**
   * @description fetch users groups.
   * @function
   * @param {function} callback
   */
  function getGroups(callback) {
    if (vm.isGroup) {
      callback(null, null);
    } else {
      var method = CrawlerMethods.getMethod({
        entity: 'groups-of-user',
        method: 'read'
      });
      method({
        user: vm.userId
      }).then(
        function(res) {
          callback(null, res.data);
        },
        function(err) {
          AlertingService.Error(err);
          callback(true);
        }
      );
    }
  }
  /**
   * @description fetch parent group.
   * @function
   * @return {Promise}
   */
  function getGroupParent() {
    var method = CrawlerMethods.getMethod({
      entity: 'groups',
      method: 'read'
    });
    return method({
      id: vm.user.parent
    }).then(
      function(res) {
        return res.data;
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }
  /**
   * @description opens group selector dialog and updates user group data
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function openAddUserToGroupDialog() {
    var data = [
      {
        componentType: 'autocompleteDialog',
        configuration: {
          query: {
            entity: 'groups',
            method: 'read'
          },
          entity: 'groups',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select group')
          },
          floatingLabel: gettext('Group'),
          searchParamName: 'name',
          required: true
        },
        name: 'group'
      }
    ];

    var dialogButtonsConfiguration = {
      displayDefault: false,
      buttons: [
        {
          title: gettext('Add'),
          script: function(closeDialog, item) {
            if (item) {
              modifyGroup('add', vm.userId, item.group._id).then(function() {
                getGroups(function(err, res) {
                  if (err) {
                    AlertingService.Error(err);
                  } else {
                    vm.user.groups = res;
                    ToastService.showToast(gettext('Membership updated.'));
                  }
                });
              });
            }
            closeDialog();
          }
        }
      ]
    };
    SfeFormDialogService.openSfeFormDialog(
      false,
      data,
      {},
      gettext('Add group'),
      dialogButtonsConfiguration
    );
  }
  /**
   * @description opens user selector dialog and adds new users to group.
   * @function
   */
  function addMultipleUsersToGroup() {
    var data = [
      {
        componentType: 'autocompleteAdder',
        name: 'users',
        entity: 'users',
        configuration: {
          label: gettextCatalog.getString('Select User'),
          searchParam: 'full_name',
          fallbackUrl: './img/profile/nopic_192.gif'
        }
      }
    ];
    SfeFormDialogService.openSfeFormDialog(
      [],
      data,
      {},
      gettext('Add user')
    ).then(
      function(res) {
        var count = 0;
        var sum = 0;
        if (res && res.users) {
          res.users.forEach(function(user) {
            if (user && user._id) {
              count++;
              modifyGroup('add', user._id, vm.userId).then(
                function() {
                  sum++;
                  if (sum === count) {
                    vm.relistData = true;
                  }
                },
                function(err) {
                  AlertingService.Error(err);
                }
              );
            }
          });
        }
      },
      function() {}
    );
  }
  /**
   * @description opens remove user confirmation dialog and removes user.
   * @function
   * @param {string} userId
   * @param {string} groupId
   * @param {bool} relist indicates if user relist items is needed
   * @return {dataType}
   */
  function removeUserFromGroupPrompt(userId, groupId, relist) {
    // confirmParam: userId
    function remove() {
      modifyGroup('remove', userId, groupId).then(function() {
        getGroups(function(err, res) {
          if (err) {
            AlertingService.Error(err);
          } else {
            if (relist) {
              vm.relistData = true;
            } else {
              vm.user.groups = res;
            }
            ToastService.showToast(gettext('Membership updated.'));
          }
        });
      });
      $mdDialog.cancel();
    }
    var title = gettext('Confirmation');
    var textItem = {
      text: gettext('Are you sure you want to remove user from group?'),
      type: 'text'
    };
    var actions = [
      {
        title: gettext('Cancel'),
        cancel: true,
        color: 'primary'
      },
      {
        title: gettext('Remove'),
        fn: remove,
        color: 'warn'
      }
    ];
    InfoDialog.open(title, null, [textItem], actions);
  }
  /**
   * @description send request to modify-group endpoint.
   * @function
   * @param {string} mode action mode identifier (update, remove)
   * @param {string} userId user id
   * @param {string} groupId group id
   * @return {dataType}
   */
  function modifyGroup(mode, userId, groupId) {
    var deferred = $q.defer();
    var postObj = {
      action: mode,
      user: userId,
      group: groupId
    };
    var method = CrawlerMethods.getMethod({
      entity: 'modify-group',
      method: 'create'
    });
    method(postObj).then(
      function() {
        deferred.resolve(postObj);
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );
    return deferred.promise;
  }

  /**
   * @description Function that is used to create an array of actions that are then passed to sfe-actions.
   * @function
   * @param {string} userId - id of the user we are viewing
   * @param {boolean} isGroup - tells us if we are on a group or user card
   * @param {boolean} isGisUser - tells us if it is a gis user
   * @param {boolean} editPermissions -   tells us, if current user has the permission to edit
   * @param {boolean} isLoggedIn - tells us if the logged in user is on his own user card
   * @return {Array} array of actions
   */
  function createActionsArray(
    userId,
    isGroup,
    isGisUser,
    editPermissions,
    isLoggedIn
  ) {
    const buttons = [];
    if (!isGroup && isGisUser) {
      buttons.push({
        title: gettext('gis groups'),
        fn: vm.addGisGroups,
        color: 'primary'
      });
    }
    if (isGroup) {
      buttons.push({
        title: gettext('add users to group'),
        fn: vm.addMultipleUsersToGroup,
        color: 'primary'
      });
      buttons.push({
        title: gettextCatalog.getString('Update'),
        link: GenerateUISrefLinkService.generateUrl({
          state: 'users-groups-edit',
          params: [{ queryParam: 'id', value: userId }]
        }),
        color: 'success'
      });
    }
    if (!isGroup) {
      buttons.push({
        title: gettext('assign gis pair'),
        fn: vm.pairWithGis,
        color: 'primary'
      });
      buttons.push({
        title: gettext('Reset Password'),
        fn: () => {
          vm.resetPassword(userId);
        },
        color: 'primary'
      });
      if (isLoggedIn) {
        buttons.push({
          title: gettext('Change Password'),
          fn: vm.changePassword,
          color: 'primary'
        });
      }
      buttons.push({
        title: gettext('Update'),
        link: GenerateUISrefLinkService.generateUrl({
          state: 'users-users-edit',
          params: [{ queryParam: 'id', value: userId }]
        }),
        color: 'success'
      });
    }
    if (isGroup && editPermissions) {
      buttons.push({
        title: gettext('Edit Privileges'),
        link: GenerateUISrefLinkService.generateUrl({
          state: 'user-group-privilege',
          params: [{ queryParam: 'id', value: userId }]
        }),
        color: 'accent'
      });
    }
    if (!isGroup && editPermissions) {
      buttons.push({
        title: gettext('Edit Privileges'),
        link: GenerateUISrefLinkService.generateUrl({
          state: 'user-privilege',
          params: [{ queryParam: 'id', value: userId }]
        }),
        color: 'accent'
      });
    }
    return buttons;
  }
}
