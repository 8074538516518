import template from './indirect-access-item.component.html';
import './indirect-access-item.scss';
/**
 * @ngdoc component
 * @name user.indirectAccessItem
 * @description displays indirect access entity item andd allows to edit item access.
 * @param {object} item access item
 * @param {string} userId user id
 * @param {string} targetScope target scope identifier
 * @param {string} treeId privilege management tree id
 * @param {bool} relistData when flag is set to true items relist is triggered
 * @param {bool} isLoading indicates when items are loading
 * @example
 * <indirect-access-item
 *   is-loading="vm.isLoading"
 *   item="instance"
 *   target-scope="vm.entity"
 *   user-id="vm.userId"
 *   tree-id="vm.treeId"
 *   relist-data="vm.relistData"
 * ></indirect-access-item>
 */
export default {
  template,
  bindings: {
    item: '<',
    userId: '<',
    targetScope: '<',
    treeId: '<',
    relistData: '=',
    isLoading: '='
  },
  controllerAs: 'vm',
  controller: indirectAccessItemController
};

indirectAccessItemController.$inject = [
  'EntitiesService',
  'AlertingService',
  'ToastService',
  'gettext',
  'CrawlerMethods',
  '$scope'
];

function indirectAccessItemController(
  EntitiesService,
  AlertingService,
  ToastService,
  gettext,
  CrawlerMethods,
  $scope
) {
  const vm = this;
  /**
   * @description triggered when component is initiated to scope.
   * @function
   */
  vm.$onInit = async () => {
    await fetchInstanceInfo();
    $scope.$evalAsync();
  };

  vm.changeAccess = changeAccess;

  vm.access = ['read', 'update', 'delete', 'list'];
  /**
   * @description fetches indirect access item metadata.
   * @function
   */
  async function fetchInstanceInfo() {
    vm.item.displayPath = vm.item.treePath;
    vm.item.displayFields = EntitiesService.getDisplayFields(
      vm.item.instanceScope
    ) || ['name'];
    if (vm.item.instanceScope) {
      const networkModel = EntitiesService.getNetworkModel(
        vm.item.instanceScope
      );
      let method;
      let queryParam = 'id';
      if (networkModel) {
        if (networkModel.privilegeMethod) {
          queryParam = networkModel.privilegeMethod.queryParam;
          method = CrawlerMethods.getMethod(networkModel.privilegeMethod);
        } else {
          method = CrawlerMethods.getMethod({
            entity: networkModel.entity,
            method: networkModel.read
          });
        }
      } else {
        /*eslint no-console: ["error", { allow: ["error"] }] */
        console.error(
          'There is no network configuration for ' + vm.item.instance
        );
      }
      try {
        const { data } = await method({
          [queryParam]: vm.item.instanceId
        });
        vm.item.instanceObject =
          Array.isArray(data) && data.length > 0 ? data[0] : data;
        vm.item.originalAccess = angular.copy(vm.item.access);
      } catch (err) {
        AlertingService.Error(err);
      }
    }
  }
  /**
   * @description triggered when item access is changed.
   * @function
   */
  async function changeAccess(remove) {
    if (remove) {
      for (let access in vm.item.access) {
        vm.item.access[access] = false;
      }
    }
    let apiObject = {
      owner: vm.userId,
      tree: vm.treeId,
      targetScope: vm.targetScope,
      instanceId: [vm.item.instanceId],
      treePath: vm.item.treePath
        ? vm.item.treePath.map(function(item) {
          return item.identifier;
        })
        : [],
      instanceScope: vm.item.instanceScope,
      access: vm.item.access
    };

    vm.editMode = true;
    vm.isLoading = true;
    const method = CrawlerMethods.getMethod({
      entity: 'authorization-tree-path',
      method: 'create'
    });
    try {
      await method(apiObject);
      vm.editMode = false;
      vm.isLoading = false;
      vm.item.originalAccess = angular.copy(vm.item.access);
      ToastService.showToast(gettext('Configuration updated.'));
      let accessExist = false;
      for (let access in vm.item.access) {
        if (vm.item.access[access]) {
          accessExist = true;
        }
      }
      if (!accessExist) {
        vm.relistData = true;
      }
    } catch (err) {
      AlertingService.Error(err);
      vm.editMode = false;
      vm.isLoading = false;
      vm.item.access = vm.item.originalAccess;
    }
  }
}
