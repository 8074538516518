TimeSeriesProcessingExtendedValuesByTimestampModel.$inject = ['ModelUtilities'];

/**
 * @ngdoc model
 * @name common.TimeSeriesProcessingExtendedValuesByTimestamp.model
 * @description TimeSeriesProcessingExtendedValuesByTimestamp.model model.
 */
function TimeSeriesProcessingExtendedValuesByTimestampModel(ModelUtilities) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'time-series/:timeSeriesId/processing/extended-values/:timestamp/versions'
  };
  
  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default TimeSeriesProcessingExtendedValuesByTimestampModel;
