module.exports = {
  entity: 'interactions',
  path: 'interactions',
  name: {
    en: 'interactions',
    sl_SI: 'Sporocila'
  },
  networkModel: {
    entity: 'interactions',
    read: 'custom.readWithNotifications',
    dialogMethod: {
      entity: 'interactions',
      method: 'custom.listWithNotifications'
    }
  },
  scope: 'notificator:interaction',

  displayFields: [
    {
      field1: 'notification',
      field2: 'subject',
      type: 'string'
    }
  ],
  dialog: {
    title: {
      en: 'Select interaction',
      sl_SI: 'Izberite sporocilo'
    },
    listInfo: {
      en: 'Select interaction from the list below',
      sl_SI: 'Izberite sporocilo s spodnjega seznama'
    }
  }
}
