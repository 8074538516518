import template from './sfe-show-operators-and-functions.directive.html';

export default function sfeShowOperatorsAndFunctions() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      usedConstant: '@',
      icon: '@'
    },
    link: linkFn,
    controller: sfeshowOperatorsAndFunctionsController,
    controllerAs: 'vm',
    bindToController: true
  };
  return directive;
  function linkFn() {}
}

sfeshowOperatorsAndFunctionsController.$inject = [
  'InfoDialog',
  'gettextCatalog',
  'FormulaInputDialogContent',
  'AllowedFunctionsService',
  'AllowedOperatorsService'
];

function sfeshowOperatorsAndFunctionsController(
  InfoDialog,
  gettextCatalog,
  FormulaInputDialogContent,
  AllowedFunctionsService,
  AllowedOperatorsService
) {
  const vm = this;
  vm.openHelperDialog = openHelperDialog;
  /**
   * @description Opens the helper dialog containing valid functions that can be used.
   * @function
   */
  function openHelperDialog() {
    const allowedFunctions = AllowedFunctionsService.getDialogConfig();
    const allowedOperators = AllowedOperatorsService.getDialogConfig();

    var constantValues = allowedFunctions;
    if (vm.usedConstant) {
      const allFormulaConstants = {
        functions: allowedFunctions,
        FormulaInputDialogContent,
        operators: allowedOperators
      };
      const arrayOfConstantNames = vm.usedConstant.split(',');
      constantValues = arrayOfConstantNames.reduce(function(
        constantArray,
        constant
      ) {
        return [...constantArray, ...allFormulaConstants[constant.trim()]];
      },
      []);
    }
    const header = gettextCatalog.getString('Formula input');
    InfoDialog.open(header, null, constantValues);
  }
}
