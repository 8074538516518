module.exports = {
  entity: 'rule-kpis',
  path: 'rule-kpis',
  name: {
    en: 'Rule KPIs',
    sl_SI: 'KPI pravila'
  },
  networkModel: {
    entity: 'rule-kpis',
    read: 'read'
  },
  scope: 'tis_be:rule-kpis',

  dialog: {
    title: {
      en: 'Select rule KPI',
      sl_SI: 'Izberite KPI pravilo'
    },
    listInfo: {
      en: 'Select rule KPI from the list below',
      sl_SI: 'Izberite KPI pravilo s spodnjega seznama'
    }
  }
}
