ReportGeneralModel.$inject = ['ModelUtilities'];

function ReportGeneralModel(ModelUtilities) {
  var modelStorage = {};

  var networkConfigurationReportSchema = {
    host: 'report',
    path: 'getReportSchema'
  };

  var networkConfigurationReportData = {
    host: 'report',
    path: 'getReportData/:reportId'
  };

  function general(config) {
    return {
      get: ModelUtilities.crud('GET', config, modelStorage)
    };
  }

  var methods = {
    custom: {
      general: general,
      getReportSchema: ModelUtilities.crud(
        'GET',
        networkConfigurationReportSchema,
        modelStorage
      ),
      getReportData: ModelUtilities.crud(
        'POST',
        networkConfigurationReportData,
        modelStorage
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default ReportGeneralModel;
