import './rule-variables.scss';
import template from './rule-variables.component.html';

/**
 * @ngdoc component
 * @name alarms-and-rules.ruleVariables
 * @description adds rule variables
 * @param {Object} api
 * @param {Function} onChange
 **/
export default {
  restrict: 'E',
  template,
  bindings: {
    api: '<',
    onChange: '&'
  },
  require: {
    model: 'ngModel',
    form: '^form'
  },
  controller: ruleVariablesController,
  controllerAs: 'vm',
  bindToController: true
};

ruleVariablesController.$inject = [
  'gettextCatalog',
  'TranslationService',
  '$scope'
];

function ruleVariablesController(gettextCatalog, TranslationService, $scope) {
  const vm = this;

  const defaultValue = {
    dateTimeFilterType: 200,
    includeValueOnTheEdge: true //boundary type 2
  };

  vm.ruleVariables = [];

  vm.options = {
    mappingFunctionsCodelist: 'ruleMappingFunctions'
  };

  $scope.$on('$destroy', () => {
    stopWatcher();
  });

  let stopWatcher = $scope.$watch(function() {
    if (vm.model != null) {
      return vm.model.$modelValue;
    }
  }, initiateVariables);
  /**
   * @description sets model values to view values.
   * @function
   * @param {Array} model
   */
  function initiateVariables(model) {
    vm.ruleVariables = model || [];
  }
  /**
   * @description returns uniq variable name.
   * @function
   * @return {String}
   */
  function createVariableName() {
    const usedNames = vm.ruleVariables.map(item => item.variableName);
    let counter = usedNames.length;
    var variableName = nextVariableName(counter);
    // using for instead of while to escape accidental forever loop
    for (let x = 0; x < usedNames.length + 1; x++) {
      if (
        usedNames.indexOf(variableName) === -1 &&
        variableName !== 'or' &&
        variableName !== 'and'
      ) {
        // var is ok
        break;
      } else {
        // get next var name
        counter++;
        variableName = nextVariableName(counter);
      }
    }
    return variableName;
  }
  /**
   * @description returns variable name.
   * @function
   * @param {number} counter number of variable in use
   * @return {String}
   */
  function nextVariableName(counter) {
    const repeats = Math.floor(counter / 26);
    let result = '';
    if (repeats > 0) {
      result += String.fromCharCode(97 + repeats - 1);
    }
    return result + String.fromCharCode(97 + (counter - repeats * 26));
  }
  /**
   * @description after function is being selected checks if it has arguments and sets hasArgs flag.
   * @function
   * @param {Number} index
   */
  vm.checkForArgument = index => {
    const ruleVariable = vm.ruleVariables[index];
    const selectedFunction = TranslationService.GetCollectionById(
      'codelists.expressionParamMappingFunctions',
      ruleVariable.function
    );
    if (selectedFunction != null && selectedFunction.hasArguments) {
      ruleVariable.hasArgs = true;
    } else {
      ruleVariable.hasArgs = false;
    }
  };
  /**
   * @description removes variable on index.
   * @function
   * @param {Number} index
   */
  vm.removeItem = index => {
    vm.ruleVariables.splice(index, 1);
    vm.api.parseLogicalExpression();

    if (vm.ruleVariables.length === 0) {
      addInputParameter();
    }
  };
  /**
   * @description adds new parameter and updates model value.
   * @function
   */
  function addInputParameter() {
    vm.ruleVariables.push({
      variableName: createVariableName(),
      paramObject: {
        ...defaultValue
      }
    });

    vm.model.$setViewValue(vm.ruleVariables);
    vm.api.parseLogicalExpression();
  }

  //SFE-ACTIONS CONFIGURATION
  vm.addVariable = [
    {
      title: gettextCatalog.getString('+ Add variable'),
      /**
       * @description adds new variable.
       * @function
       */
      fn: addInputParameter,
      color: 'primary'
    }
  ];
}
