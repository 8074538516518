import { DateTime } from 'luxon';

InvoiceDetailController.$inject = [
  '$scope',
  '$state',
  'gettext',
  'AlertingService',
  '$filter',
  'gettextCatalog',
  'ToastService',
  '$rootScope',
  'MetadataService',
  '$timeout',
  'MasterInvoiceModel',
  'TranslationService',
  'detailInvoice'
];

function InvoiceDetailController(
  $scope,
  $state,
  gettext,
  AlertingService,
  $filter,
  gettextCatalog,
  ToastService,
  $rootScope,
  MetadataService,
  $timeout,
  MasterInvoiceModel,
  TranslationService,
  detailInvoice
) {
  const vm = this;
  let priceListDetailName;
  let validPriceListDetailWatcher;
  let leftAmountWatcher;
  let distributeDisabledWatcher;

  vm.$onInit = () => {
    vm.loading = true;
    MetadataService.Loading(true);
    vm.distributeDisabled = false;
    vm.detailInvoiceId = $state.params.invoiceDetailId;
    vm.masterInvoice = $state.params.invoiceId;

    vm.validPriceListDetail = {};

    if (vm.calculatedBookValue == undefined) {
      vm.calculatedBookValue = 0;
    }

    setInvoiceDetailData(detailInvoice[0]);
  };

  function constructPriceListDetailName(detail) {
    let dateString = $filter('date')(detail.validFrom, 'dd.MM.yyyy') + ' - ';
    if (detail.validTo) {
      dateString += $filter('date')(detail.validTo, 'dd.MM.yyyy');
    }
    let nameString = priceListDetailName + ' (' + dateString + ')';
    return nameString;
  }

  function distributeBookValue() {
    MasterInvoiceModel.update(
      {
        id: vm.masterInvoice,
        distribute: true
      },
      {}
    ).then(
      function() {
        ToastService.showToast(
          gettext('Book value was successfully distributed')
        );
        $rootScope.$broadcast('reInit');
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function setInvoiceDetailData(detailInvoiceView) {
    vm.detailInvoiceValues = detailInvoiceView
      ? detailInvoiceView.detailInvoiceValues
      : null;
    if (detailInvoiceView) {
      vm.validPriceListDetail = {
        ...detailInvoiceView._id.priceListDetail
      };
      let detailInvoice = detailInvoiceView._id;
      vm.invoiceStatus = detailInvoice.masterInvoice
        ? detailInvoice.masterInvoice.invoiceStatus
        : false;
      vm.detailInvoiceId = detailInvoice.detailInvoice._id;
      vm.energySourceType = detailInvoice.detailInvoice.energySourceType;
      vm.measuringPoint = detailInvoice.detailInvoice.measuringPoint;
      vm.priceListDetail = detailInvoice.detailInvoice.priceListDetail;
      vm.readDate = detailInvoice.detailInvoice.readDate;

      let currencySymbol;
      if (
        detailInvoice.priceListMaster &&
        detailInvoice.priceListMaster.currency
      ) {
        const currency = TranslationService.GetCollectionById(
          'codelists.currencies',
          detailInvoice.priceListMaster.currency
        );
        if (currency) {
          currencySymbol = currency.symbol;
        }
      }

      vm.currencySymbol = currencySymbol || '';
      vm.calculatedBookValue += detailInvoice.detailInvoice.sum;
      vm.calculatedBookValue = vm.calculatedBookValue.toFixed(2) * 1;
      vm.calculatedBookValue += detailInvoice.masterInvoice.bookValue;
      vm.calculatedBookValue = vm.calculatedBookValue.toFixed(2) * 1;
      vm.serviceDateTo = detailInvoice.detailInvoice.serviceDateTo;

      vm.headerData = getHeaderData(detailInvoice);

      leftAmountWatcher = $scope.$watch(
        'vm.leftAmount',
        leftAmountWatcherFunction
      );
      distributeDisabledWatcher = $scope.$watch(
        'vm.distributeDisabled',
        distributeDisabledWatcherFunction
      );
      validPriceListDetailWatcher = $scope.$watch(
        'vm.validPriceListDetail',
        validPriceListDetailWatcherFunction
      );
      $timeout(() => {
        MetadataService.ChangeMetadata(
          detailInvoice.masterInvoice.number,
          null
        );
      });
    }
    vm.loading = false;
    MetadataService.Loading(false);
  }

  function getHeaderData(detailInvoice) {
    let invoiceType = TranslationService.GetCollectionById(
      'codelists.invoiceTypes',
      detailInvoice.detailInvoice.invoiceType
    );

    let properties = [
      {
        type: 'simple',
        title: gettext('Energy source type'),
        value: detailInvoice.energySourceType
          ? detailInvoice.energySourceType.name
          : gettextCatalog.getString('Unknown'),
        energyIcon: detailInvoice.energySourceType
          ? detailInvoice.energySourceType.icon
          : null
      }
    ];

    if (detailInvoice.measuringPoint != null) {
      properties.push({
        title: gettext('Measuring point'),
        linkTitle: detailInvoice.measuringPoint.name,
        state: 'company-resources-measuring-points-view',
        param: 'id',
        paramValue: detailInvoice.measuringPoint._id,
        type: 'link'
      });
    }

    properties.push({
      title: gettext('Master invoice'),
      linkTitle: detailInvoice.masterInvoice.number,
      state: 'invoices-invoices-view',
      paramObject: {
        invoiceId: detailInvoice.masterInvoice._id
      },
      type: 'link'
    });

    properties.push({
      type: 'simple',
      title: gettext('Invoice Type'),
      value: invoiceType ? invoiceType.name : '/'
    });

    if (detailInvoice.priceListDetail) {
      priceListDetailName = detailInvoice.priceListMaster
        ? detailInvoice.priceListMaster.name
        : '';
      let readableName = constructPriceListDetailName(
        detailInvoice.priceListDetail,
        detailInvoice.priceListMaster ? detailInvoice.priceListMaster.name : ''
      );
      properties.push({
        title: gettext('Price list'),
        linkTitle: readableName,
        state: 'invoices-price-lists-detail-view',
        paramObject: {
          priceListDetailId: detailInvoice.priceListDetail._id,
          priceListId: detailInvoice.priceListDetail.priceListMaster
        },
        type: 'link',
        priceListDetail: true
      });
    } else {
      properties.push({
        type: 'simple',
        title: gettext('Price list'),
        value: gettextCatalog.getString('Unknown')
      });
    }

    let dates = [
      {
        type: 'date',
        value: detailInvoice.detailInvoice.readDate,
        dateFormat: 'dd. MM. yyyy',
        title: gettext('Reading date')
      },
      {
        type: 'date',
        value: detailInvoice.detailInvoice.serviceDateFrom,
        dateFormat: 'dd. MM. yyyy',
        title: gettext('Service Date From')
      },
      {
        type: 'date',
        value: detailInvoice.detailInvoice.serviceDateTo,
        dateFormat: 'dd. MM. yyyy',
        title: gettext('Service Date To')
      },
      {
        type: 'raw',
        title: gettext('Invoice validity'),
        value:
          detailInvoice.detailInvoice.normalizedServiceDateFrom != null &&
          typeof detailInvoice.detailInvoice.normalizedServiceDateFrom ===
            'object' &&
          detailInvoice.detailInvoice.normalizedServiceDateTo != null &&
          typeof detailInvoice.detailInvoice.normalizedServiceDateTo ===
            'object'
            ? `${DateTime.fromObject(
              detailInvoice.detailInvoice.normalizedServiceDateFrom
            )
              .startOf('months')
              .toFormat('dd. MM. yyyy')} - ${DateTime.fromObject(
              detailInvoice.detailInvoice.normalizedServiceDateTo
            )
              .endOf('months')
              .toFormat('dd. MM. yyyy')}`
            : gettextCatalog.getString('None')
      }
    ];
    let amounts = [
      {
        type: 'simple',
        title: gettext('Book value'),
        value: vm.calculatedBookValue
      },
      {
        type: 'simple',
        title: gettext('Sum'),
        value: detailInvoice.detailInvoice.sum
      },
      {
        type: 'simple',
        title: gettext('Amount left')
      },
      {
        type: 'simple',
        title: gettext('Total'),
        value: detailInvoice.detailInvoice.total || '/'
      }
    ];

    let propertySections = [
      {
        title: gettext('Properties'),
        properties: properties,
        priceListDetail: true
      },
      {
        title: gettext('Amounts'),
        properties: amounts
      },
      {
        title: gettext('Dates'),
        properties: dates
      }
    ];
    let actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: vm.detailInvoiceId,
        endpoint: {
          entity: 'detail-invoices',
          method: 'delete'
        },
        redirectState: 'invoices-invoices-view',
        paramName: 'invoiceId',
        paramValue: vm.masterInvoice
      },
      {
        title: gettext('Distribute'),
        script: true,
        fn: distributeBookValue,
        disabledFn: () => {
          return Math.abs(vm.leftAmount) >= 1 || vm.distributeDisabled;
        }
      },
      {
        title: gettext('Update'),
        script: true,
        fn: edit,
        edit: true,
        color: 'success'
      }
    ];
    return {
      metadata: {
        title: detailInvoice.masterInvoice.number,
        definition: gettext('Invoice Detail')
      },
      actions: actions,
      propertySections: propertySections
    };
  }

  function edit() {
    $state.go('invoices-invoices-detail-edit', $state.params);
  }

  $scope.$on('$destroy', function() {
    if (distributeDisabledWatcher) {
      distributeDisabledWatcher();
    }
    if (leftAmountWatcher) {
      leftAmountWatcher();
    }
    if (validPriceListDetailWatcher) {
      validPriceListDetailWatcher();
    }
  });

  function distributeDisabledWatcherFunction() {
    if (vm.headerData.actions[0]) {
      vm.headerData.actions[0].disabled =
        Math.abs(vm.leftAmount) >= 1 ||
        vm.leftAmount < -1 ||
        vm.distributeDisabled;
    }
  }
  function leftAmountWatcherFunction() {
    let section = _.find(vm.headerData.propertySections, {
      title: 'Amounts'
    });

    if (!section) {
      return;
    }
    let property = _.find(section.properties, {
      title: 'Amount left'
    });

    if (property) {
      property.value =
        vm.leftAmount != undefined ? vm.leftAmount.toFixed(2) : undefined;
    }

    let sumProperty = _.find(section.properties, {
      title: 'Sum'
    });

    if (sumProperty) {
      sumProperty.value =
        (vm.calculatedBookValue - vm.leftAmount).toFixed(2) * 1;
    }

    vm.headerData.actions[0].disabled =
      Math.abs(vm.leftAmount) >= 1 ||
      vm.leftAmount < -1 ||
      vm.distributeDisabled;
  }

  // when pricelist detail changes change it in header
  function validPriceListDetailWatcherFunction() {
    if (!vm.validPriceListDetail || !vm.validPriceListDetail._id) {
      return;
    }
    let section = vm.headerData.propertySections.find(
      section => section.priceListDetail
    );
    if (section && section.properties) {
      let property = section.properties.find(
        section => section.priceListDetail
      );

      if (property && property.paramObject) {
        property.linkTitle = constructPriceListDetailName(
          vm.validPriceListDetail
        );
        property.paramObject.priceListDetailId = vm.validPriceListDetail._id;
      }
    }
  }
}
export default InvoiceDetailController;
