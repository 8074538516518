import template from './sfe-autocomplete-adder.directive.html';

export default [
  function sfeAutocompleteAdder() {
    var directive = {
      restrict: 'E',
      template,
      scope: {
        entity: '=',
        selected: '=?',
        configuration: '=',
        parentForm: '=',
        name: '='
      },
      link: linkFn,
      controller: sfeAutocompleteAdderController,
      controllerAs: 'vm',
      bindToController: true
    };
    return directive;

    function linkFn(scope, el, attrs, ctrl) {
      ctrl.looseFocus = looseFocus;
      function looseFocus() {
        var input = el[0].querySelector('#item-id' + ctrl.name);
        input.blur();
        /*************
        MEMORY CLEANUP
        *************/
        input = null;
      }
    }
  }
];

sfeAutocompleteAdderController.$inject = [
  '$scope',
  '$q',
  'EntitiesService',
  'AlertingService',
  '$timeout',
  'ItemSelector',
  'StandardUtils',
  'CrawlerMethods',
  'gettextCatalog'
];

function sfeAutocompleteAdderController(
  $scope,
  $q,
  EntitiesService,
  AlertingService,
  $timeout,
  ItemSelector,
  StandardUtils,
  CrawlerMethods,
  gettextCatalog
) {
  var vm = this;
  var path;
  var method;
  vm.fetchAutocompleteOptions = fetchAutocompleteOptions;
  vm.setAutocompleteDisplayValue = setAutocompleteDisplayValue;
  vm.selectedItemChange = selectedItemChange;
  vm.removeSelected = removeSelected;
  vm.openDialog = openDialog;
  vm.$onInit = init;

  function init() {
    if (vm.configuration && vm.configuration.label) {
      vm.label = vm.configuration.label;
    } else {
      vm.label = gettextCatalog.getString('Select');
    }
    path = EntitiesService.getPath(vm.entity);
    vm.displayFields = EntitiesService.getDisplayFieldsByPath(path) || ['name'];

    var networkModel = EntitiesService.getNetworkModelByEntity(vm.entity);
    if (networkModel) {
      method = CrawlerMethods.getMethod({
        entity: networkModel.entity,
        method: networkModel.read
      });
    } else {
      /*eslint no-console: ["error", { allow: ["error"] }] */
      console.error('There is no network configuration for ' + vm.entity);
    }
    if (vm.parentForm) {
      $scope.ItemForm = vm.parentForm;
    }
  }

  function fetchAutocompleteOptions() {
    var obj = {};
    var param = vm.configuration
      ? vm.configuration.searchParam || 'filter'
      : 'filter';
    obj[param] = vm.searchText;
    return method(obj).then(
      function(res) {
        var data = _.differenceBy(res.data, vm.selected, '_id');
        if (vm.entity === 'users') {
          (data || []).forEach(function(item) {
            item.imageUrl = StandardUtils.getUserImageUrl(item, 'sm');
          });
        }
        return data;
      },
      function(err) {
        AlertingService.Error(err);
        return [];
      }
    );
  }

  function setAutocompleteDisplayValue(item) {
    var deferred = $q.defer();
    var displayString = '';
    if (item) {
      (vm.displayFields || []).forEach(function(displayField, index) {
        if (_.isObject(displayField)) {
          displayString += item[displayField.field1]
            ? item[displayField.field1]
            : '';
          if (displayField.field2 && item[displayField.field2]) {
            displayString += ' ' + item[displayField.field2];
          }
        } else {
          displayString += item[displayField] ? item[displayField] : '';
        }
        if (index < vm.displayFields.length - 1) {
          displayString += ' - ';
        }
      });
    }
    deferred.resolve(displayString);
    return deferred.promise;
  }

  function selectedItemChange() {
    vm.searchText = null;

    if (_.isEmpty(vm.model)) {
      return;
    }
    if (!vm.selected) {
      vm.selected = [];
    }
    var found = _.find(vm.selected, {
      _id: vm.model._id
    });
    if (!found) {
      vm.selected.push(vm.model);
    }
    vm.looseFocus();
    $timeout(function() {
      vm.model = null;
    });
  }

  function removeSelected(index) {
    vm.selected.splice(index, 1);
  }

  function openDialog() {
    ItemSelector.open(
      [vm.entity],
      [
        {
          multiple: true
        }
      ]
    ).then(function(selected) {
      if (!vm.selected) {
        vm.selected = [];
      }
      (selected || []).forEach(function(item) {
        var found = _.find(vm.selected, {
          _id: item._id
        });
        if (!found) {
          vm.selected.push(item);
        }
      });
    });
  }
}
