import './sfe-address-viewer.scss';
import template from './sfe-address-viewer.directive.html';
/**
 * @ngdoc directive
 * @name moduleName.sfeAddressViewer
 * @description directive used for displaying location coordinates, address and satellite picture and map
 * @param {object} model - data about the address
 * @example
 * <sfe-address-viewer
 *   model="vm.obj.location"
 * ></sfe-address-viewer>
 */
export default [
  'NgMap',
  '$timeout',
  'gettextCatalog',
  function(NgMap, $timeout, gettextCatalog) {
    return {
      restrict: 'E',
      template,
      scope: {
        model: '<'
      },
      link: function link(scope) {
        let TIMEOUT = 500;
        scope.randomHash = Math.random()
          .toString(36)
          .substring(2);
        scope.zoom1 = 18;
        scope.zoom2 = 18;
        scope.noneString = gettextCatalog.getString('None');

        let watcher = scope.$watch('model', function() {
          if (scope.model) {
            if (!scope.model.hasAddress && !scope.model.geoLocation) {
              scope.ready = true;
            } else {
              $timeout(function() {
                ConstructLiteralAddress();
                InitMap();
                if (scope.model.geoLocation) {
                  scope.center2 =
                    scope.model.geoLocation[1] +
                    ', ' +
                    scope.model.geoLocation[0];
                  $timeout(() => {
                    scope.center = scope.center2;
                  });
                }
              });
            }
            if (scope.model != null && Array.isArray(scope.model.geoLocation)) {
              scope.model.northSouth =
                scope.model.geoLocation[1] > 0 ? 'N' : 'S';
              scope.model.eastWest = scope.model.geoLocation[0] > 0 ? 'E' : 'W';
            }
            watcher();
          }
        });

        scope.$on('$destroy', function() {
          if (watcher) {
            watcher();
          }
        });
        /**
         * @description initializes the maps
         * @function
         */
        function InitMap() {
          scope.ready = true;
          NgMap.getMap('mapStreet-' + scope.randomHash).then(function(map) {
            scope.mapStreet = map;
          });
          NgMap.getMap('mapSatellite-' + scope.randomHash).then(function(map) {
            scope.mapSatellite = map;
          });
        }

        google.maps.event.addDomListener(window, 'resize', centerMap);
        /**
         * @description centers map on received location
         * @function
         */
        function centerMap() {
          if (scope.model && scope.model.geoLocation) {
            scope.disableMove = true;
            moveMap(
              'mapStreet',
              false,
              scope.model.geoLocation[0],
              scope.model.geoLocation[1]
            );
            scope.disableMove = true;
            moveMap(
              'mapSatellite',
              false,
              scope.model.geoLocation[0],
              scope.model.geoLocation[1]
            );
          }
        }
        /**
         * @description Moves moves map or map center to specific coordinates
         * @function
         * @param {string} mapname - name of the map being used
         * @param {boolean} pan - are we paning the map or setting the center
         * @param {number} lng - new map longitude
         * @param {number} lat - new map latitude
         */
        function moveMap(mapname, pan, lng, lat) {
          NgMap.getMap(mapname + '-' + scope.randomHash).then(function(map) {
            let coor = new google.maps.LatLng(lat, lng);
            if (pan) {
              map.panTo(coor);
            } else {
              map.setCenter(coor);
            }
          });
        }
        /**
         * @description updates all the maps based on changes on the non-satellite map
         * @function
         */
        scope.UpdateScopeFromMap = function() {
          if (scope.mapSatellite) {
            if (!scope.disableMove) {
              scope.disableSatelliteMove = true;
              if (scope.mapStreet && scope.mapStreet.center) {
                moveMap(
                  'mapSatellite',
                  true,
                  scope.mapStreet.center.lng(),
                  scope.mapStreet.center.lat()
                );
              }
            } else {
              $timeout(function() {
                scope.disableMove = false;
              }, TIMEOUT);
            }
          }
        };
        /**
         * @description updates all the maps based on changes on the satellite map
         * @function
         */
        scope.UpdateScopeFromMapSatellite = function() {
          if (scope.mapStreet) {
            if (!scope.disableSatelliteMove) {
              scope.disableMove = true;
              if (scope.mapSatellite && scope.mapSatellite.center) {
                moveMap(
                  'mapStreet',
                  true,
                  scope.mapSatellite.center.lng(),
                  scope.mapSatellite.center.lat()
                );
              }
            } else {
              $timeout(function() {
                scope.disableSatelliteMove = false;
              }, TIMEOUT);
            }
          }
        };
        /**
         * @description zooms satellite map when street map is zoomed
         * @function
         */
        scope.zoomFromStreetMap = function() {
          if (!scope.disableStreetZoom) {
            scope.disableSatelliteZoom = true;
            scope.mapSatellite.setZoom(scope.mapStreet.getZoom());
            moveMap(
              'mapSatellite',
              false,
              scope.mapStreet.center.lng(),
              scope.mapStreet.center.lat()
            );
          } else {
            $timeout(function() {
              scope.disableStreetZoom = false;
            }, TIMEOUT);
          }
        };
        /**
         * @description zooms stree map when sattelite map is zoomed
         * @function
         */
        scope.zoomFrommapSatellite = function() {
          if (!scope.disableSatelliteZoom) {
            scope.disableStreetZoom = true;
            scope.mapStreet.setZoom(scope.mapSatellite.getZoom());
            moveMap(
              'mapStreet',
              false,
              scope.mapSatellite.center.lng(),
              scope.mapSatellite.center.lat()
            );
          } else {
            $timeout(function() {
              scope.disableSatelliteZoom = false;
            }, TIMEOUT);
          }
        };
        /**
         * @description constructs address data  based on coordinates
         * @function
         */
        function ConstructLiteralAddress() {
          if (scope.model.geoLocation) {
            scope.model.geoLocation[0] = scope.model.geoLocation[0].toFixed(6);
            scope.model.geoLocation[1] = scope.model.geoLocation[1].toFixed(6);
          }
          let first = true;
          scope.model.literalAddress = '';
          if (scope.model.hasAddress) {
            if (scope.model.streetName) {
              first = false;
              scope.model.literalAddress += scope.model.streetName;
              if (scope.model.houseNumber) {
                scope.model.literalAddress += ' ' + scope.model.houseNumber;
              }
            }
            if (scope.model.postalNumber) {
              if (first) {
                first = false;
                scope.model.literalAddress += scope.model.postalNumber;
              } else {
                scope.model.literalAddress += ', ' + scope.model.postalNumber;
              }
            }
            if (scope.model.postName) {
              if (scope.model.postalNumber) {
                scope.model.literalAddress += ' ' + scope.model.postName;
              } else {
                if (first) {
                  first = false;
                  scope.model.literalAddress += scope.model.postName;
                } else {
                  scope.model.literalAddress += ', ' + scope.model.postName;
                }
              }
            }
            if (scope.model.country && scope.model.country.name) {
              if (first) {
                first = false;
                scope.model.literalAddress += scope.model.country.name;
              } else {
                scope.model.literalAddress += ', ' + scope.model.country.name;
              }
            }
          }
        }
      }
    };
  }
];
