import template from './analysis-chart-dashboard.component.html';
import './analysis-dashboard-chart.scss';

export default {
  template,
  bindings: {
    analysisId: '<',
    size: '<?',
    refresher: '<',
    cacheInvalidationTime: '<?'
  },
  controller: AnalysisChartDashboardController,
  controllerAs: 'vm',
  bindToController: true
};

/**
 * @ngdoc component
 * @name analytics.analysisChartDashboard
 * @description analysisChart component wrapper to display analysis on dashboards.
 * @param {String} analysisId analysis id
 * @param {Number} size dashbaord card size
 * @param {String} cacheInvalidationTime playlist cache Invalidation Time
 * @example
 * <analysis-chart-dashboard
 * analysisId="analysisId"
 * size="size"
 * refresher="refresher"
 * cacheInvalidationTime="cacheInvalidationTime"
 * ></analysis-chart-dashboard>
 */
AnalysisChartDashboardController.$inject = [
  'analysisQueryBuilder',
  'AlertingService',
  'gettext',
  'periodIntervalConstructor',
  '$timeout',
  'AnalysisExpressionEnrichment',
  'Refreshing',
  'DatasetColumnHelper'
];

function AnalysisChartDashboardController(
  analysisQueryBuilder,
  AlertingService,
  gettext,
  periodIntervalConstructor,
  $timeout,
  AnalysisExpressionEnrichment,
  Refreshing,
  DatasetColumnHelper
) {
  const vm = this;
  let refresherId, refresherFnId;

  /**
   @description Check for changes and trigger init.
   @function
   @param {dataType} binding/paramName
   @return {dataType}
  */
  vm.$onChanges = function(changes) {
    if (changes && changes.analysisId && vm.analysisId) {
      setSingleHeight();
      initAnalysis();
      vm.uniqueId = Math.random()
        .toString(36)
        .substr(2, 10);
    }
    if (vm.refresher) {
      ({ refresherId, refresherFnId } = vm.refresher(() => {
        if (!vm.filtersAreNotValid && !vm.graphDataIsLoading) {
          triggerQueryAndRender();
        }
      }));
    }
  };

  /**
   * @description On destroy, destroy refreshing interval.
   * @function
   */
  vm.$onDestroy = function() {
    if (refresherId) {
      Refreshing.removeFn(refresherId, refresherFnId);
    }
  };

  /**
   @description description.
   @function
   @param {dataType} binding/paramName
   @return {dataType}
  */
  vm.setReportData = function(svg, tableData, tableHeaders) {
    let headersMap = _.reduce(
      tableHeaders || [],
      function(map, element) {
        let entry = {};
        entry[element.sort] = element.name;
        return _.assign({}, map, entry);
      },
      {}
    );
    let headerKeys = _.keys(headersMap);
    let reportData = {
      title: vm.analysis.name,
      graph: svg,
      table: (tableData || []).map(function(data) {
        return _.reduce(
          headerKeys,
          function(object, key) {
            let entry = {};
            entry[key] = data[key];
            return _.assign({}, object, entry);
          },
          {}
        );
      }),
      headers: _.map(tableHeaders, function(header) {
        return {
          name: header.name,
          id: header.sort
        };
      })
    };
    _.assign(vm.reportData, reportData);
  };

  /**
   * @description set dashboard height and width type.
   * @function
   */
  function setSingleHeight() {
    switch (vm.size) {
    case 1:
      vm.singleHeight = true;
      vm.singleWidth = true;
      break;
    case 2:
    case 4:
      vm.singleHeight = true;
      break;
    }
  }
  /**
   * @description maps analysis filters with dataset entity id.
   * @function
   * @param {Object} analysis
   * @return {Array}
   */
  function getFilters(analysis) {
    const analysisFilters = analysis.filterConfigs;
    const datasetColumns = analysis.dataset.columns;
    return analysisFilters.map(function(filter) {
      let datasetColumn = datasetColumns.find(function(datasetColumn) {
        return datasetColumn.rawFieldName === filter.rawFieldName;
      });
      return {
        ...filter,
        entityItems: filter.filterValues,
        entity: datasetColumn.entity
      };
    });
  }

  /**
  @description This function initializes analysis, triggers query and sets data to vm.
  @function
  */
  async function initAnalysis() {
    vm.graphDataIsLoading = true;
    vm.filtersAreNotValid = false;
    try {
      const analysisData = await analysisQueryBuilder.readAnalysis(
        vm.analysisId,
        vm.cacheInvalidationTime
      );
      triggerQueryAndRender(
        analysisData,
        vm.graphDataIsLoading,
        vm.filtersAreNotValid
      );
    } catch (err) {
      AlertingService.Error(err);
      vm.graphDataIsLoading = false;
      vm.filtersAreNotValid = true;
    }
  }

  /**
   * @description Checks analysis and queries values.
   * @function
   * @param {Object} analysis
   * @param {boolean} graphDataIsLoading
   * @param {boolean} filtersAreNotValid
   */
  async function triggerQueryAndRender(
    analysisData,
    graphDataIsLoading,
    filtersAreNotValid
  ) {
    // eslint-disable-next-line no-useless-catch
    try {
      vm.graphDataIsLoading =
        graphDataIsLoading != null ? graphDataIsLoading : true;
      vm.filtersAreNotValid =
        filtersAreNotValid != null ? filtersAreNotValid : false;
      analysisData = analysisData != null ? analysisData : vm.analysis;

      const defaultFilterEntities = [
        2 /*Measuringpoint*/,
        3 /*Location*/,
        4 /*CostCentre*/,
        235 /*TimeSeriesType*/,
        105 /*EntityTag*/
      ];
      const analysisFilters = getFilters(analysisData);
      const validationError = DatasetColumnHelper.validateFilters(
        analysisFilters,
        defaultFilterEntities
      ).error;
      if (!validationError) {
        const analysis = await AnalysisExpressionEnrichment.enrichExpression(
          analysisData,
          vm.cacheInvalidationTime
        );
        vm.analysisSize = vm.size;
        vm.filtersAreNotValid = false;
        vm.analysis = analysis;
        vm.xAxisIsAggregationGroup = analysis.xAxisIsAggregationGroup;
        vm.xAxis = analysis.xAxis;
        vm.groupSliced = angular.copy(analysis.group);
        vm.groupSliced.condition = [];
        vm.sliceEntity = analysis.sliceEntity;
        vm.groupEntity = analysis.groupEntity;
        vm.aggregations = analysis.group.condition;
        analysis.group.condition.forEach(function(item) {
          if (item !== vm.sliceEntity) {
            vm.groupSliced.condition.push(item);
          }
        });
        vm.columns = analysis.dataset.columns;
        vm.datasetCode = analysis.dataset.code;
        vm.showTable = analysis.showTable;
        const filters = angular.copy(analysis.filterConfigs).map(filter => ({
          ...filter,
          entityItemObjects: filter.filterValues
        }));
        const dates = periodIntervalConstructor.getFormObj(
          analysis.periodInterval
        );
        const startDate = dates.startDate;
        const endDate = dates.endDate;
        $timeout(() => {
          const analysisObject = analysisQueryBuilder.getQuery({
            analysis,
            analysisFilters: filters,
            startDate,
            endDate,
            activeComparableColumn: analysis.activeComparableColumn,
            startDateMonth: startDate,
            endDateMonth: endDate
          });
          vm.analysisQueryObject = analysisObject.apiObject;
          vm.seriesNames = analysis.displaySeries;
        });
        vm.graphDataIsLoading = false;
      } else {
        vm.graphDataIsLoading = false;
        vm.filtersAreNotValid = true;
        AlertingService.Error(validationError);
      }
    } catch (err) {
      throw err;
    }
  }
}
