module.exports = {
  entity: 'addresses',
  path: 'addresses',
  name: {
    en: 'Address',
    sl_SI: 'Naslov'
  },
  networkModel: {
    entity: 'addresses',
    read: 'custom.fetchReadableAddress'
  },
  selectedParamName: 'id',
  scope: 'tis_be:addresses',
  displayFields: [
    {
      field1: 'name',
      type: 'string'
    }
  ],
  dialog: {
    title: {
      en: 'Select address',
      sl_SI: 'Izberite naslov'
    },
    listInfo: {
      en: 'Select address from the list below',
      sl_SI: 'Izberite naslov s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      }
    ]
  }
}
