routeConfig.$inject = ['$urlRouterProvider', '$httpProvider'];

function routeConfig($urlRouterProvider, $httpProvider) {
  $httpProvider.interceptors.push(
    'HttpRequestTimeoutInterceptor',
    'GisAuthInterceptor'
  );

  $urlRouterProvider.rule(function($injector, $location) {
    var path = $location.path();
    var hasTrailingSlash = path.length > 1 && path[path.length - 1] === '/';

    if (hasTrailingSlash) {
      // if last character is a slash, return the same url without the slash
      var newPath = path.substr(0, path.length - 1);
      $location.replace().path(newPath);
    }
  });

  // Redirect to 404 when route not found
  $urlRouterProvider.otherwise(function($injector) {
    $injector.get('$state').transitionTo('not-found', null, {
      location: false
    });
  });
}

export default routeConfig;
