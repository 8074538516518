import template from './tango-dynamic-attributes.component.html';
import './tango-dynamic-attributes.scss';
export default {
  template,
  bindings: {
    entity: '<',
    entityId: '<'
  },
  controller: TangoDynamicAttributesController,
  controllerAs: 'vm'
};

TangoDynamicAttributesController.$inject = [
  '$ngRedux',
  'ReduxStoreService',
  'EntitiesService'
];

function TangoDynamicAttributesController(
  $ngRedux,
  ReduxStoreService,
  EntitiesService
) {
  const vm = this;

  vm.$onChanges = () => {
    if (vm.entity && vm.entityId) {
      $ngRedux.connect(mapStateToScope)(vm);
      vm.entityName = EntitiesService.getEntityById(vm.entity);
    }
  };
  function mapStateToScope(state) {
    const reduxStoreConfiguration = getConfiguration(vm.entity);
    if (reduxStoreConfiguration != null) {
      const { value: typeValueObject } = ReduxStoreService.extract(
        state,
        vm.entity,
        vm.entityId,
        reduxStoreConfiguration
      );
      if (typeValueObject != null && typeValueObject._id) {
        return {
          typeId: typeValueObject._id
        };
      }
    }
    return {};
  }

  function getConfiguration(entity) {
    switch (entity) {
    case 234: //time series type
      return {
        value: 'timeSeriesType',
        type: 'stored'
      };
    case 133: // location type
      return {
        value: 'type',
        type: 'stored'
      };
    case 53: // asset type
      return {
        value: 'type',
        type: 'stored'
      };
    case 158: // measuring point type
      return {
        value: 'measuringpointType',
        type: 'stored'
      };
    }
  }
}
