module.exports = {
  entity: 'time-series-manual-input-forms',
  path: 'time-series-manual-input-forms',
  name: {
    en: 'Manual input',
    sl_SI: 'Manual input'
  },
  networkModel: {
    entity: 'time-series-manual-input-forms',
    read: 'read'
  },
  scope: 'tis_be:manual-input',

  selectedParamName: 'id',
  dialog: {
    title: {
      en: 'Select manual input',
      sl_SI: 'Select manual input'
    },
    listInfo: {
      en: 'Select manual input from the list below',
      sl_SI: 'Select manual input from the list below'
    }
  }
}
