import actionTypes from '../action/maintenance-flow.type';

MaintenanceFlowMiddleware.$inject = [
  'MaintenanceFlowModel',
  'AlertingService',
  'CrudToastFactory'
];
function MaintenanceFlowMiddleware(
  MaintenanceFlowModel,
  AlertingService,
  CrudToastFactory
) {
  return () => next => async action => {
    switch (action.type) {
    case actionTypes.MAINTENANCE_FLOW_READ:
      try {
        let result = await MaintenanceFlowModel.read(action.payload);
        action.payload = {
          ...action.payload,
          result
        };
      } catch (err) {
        AlertingService.Error(err);
        action.payload.result = err;
      }
      break;
    case actionTypes.MAINTENANCE_FLOW_UPDATE:
      try {
        action.payload.result = await MaintenanceFlowModel.update(
          action.payload.query,
          action.payload.body
        );
        CrudToastFactory.toast('update');
      } catch (err) {
        AlertingService.Error(err);
        action.payload.result = err;
      }
      break;
    case actionTypes.MAINTENANCE_FLOW_CREATE:
      try {
        action.payload.result = await MaintenanceFlowModel.create(
          action.payload.query || {},
          action.payload.body
        );
      } catch (err) {
        AlertingService.Error(err);
      }
      break;
    }
    next(action);
  };
}

export default MaintenanceFlowMiddleware;
