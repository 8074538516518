module.exports = {
  entity: 'sopTypes',
  path: 'sopTypes',
  name: {
    en: 'SOP Types',
    sl_SI: 'SOP tip'
  },
  codelist: 'sopTypes',
  scope: 'tis_be:sopTypes',
  assignedScope: 'tis_be:sopTypes',
  dialog: {
    title: {
      en: 'Select SOP type',
      sl_SI: 'Izberite SOP tip'
    },
    listInfo: {
      en: 'Select SOP type from the list below',
      sl_SI: 'Izberite SOP tip s spodnjega seznama'
    },
    codelist: 'sopTypes'
  }
}
