import './sfe-location-card.scss';
import template from './sfe-location-card.component.html';

export default {
  restrict: 'E',
  template,
  bindings: {
    element: '<',
    height: '<?',
    size: '=?',
    refresher: '<',
    cacheInvalidationTime: '<?',
    locationId: '<'
  },
  controller: sfeLocationCardController,
  controllerAs: 'vm'
};

sfeLocationCardController.$inject = [
  'AlertingService',
  '$q',
  '$scope',
  'ViewModel',
  'utilService',
  'Refreshing',
  'LocationModel',
  'EnergySourceTypeModel',
  'PictureLocationModel'
];

function sfeLocationCardController(
  AlertingService,
  $q,
  $scope,
  ViewModel,
  utilService,
  Refreshing,
  LocationModel,
  EnergySourceTypeModel,
  PictureLocationModel
) {
  var vm = this;
  var locationId = '';
  vm.fetching = true;

  var refresherId, refresherFnId;
  vm.$onInit = function() {
    if (!vm.height && !vm.size) {
      vm.height = 420;
    }
    $scope.$on('$destroy', function() {
      if (refresherId) {
        Refreshing.removeFn(refresherId, refresherFnId);
      }
    });
  };
  vm.$onChanges = function(changes) {
    if (vm.refresher) {
      ({ refresherId, refresherFnId } = vm.refresher(refreshValues));
    }
    if (changes.locationId) {
      if (!vm.element.preloaded) {
        vm.cardError = false;
        locationId = vm.locationId;
        ReadLocation().then(
          function(location) {
            vm.location = location;
            startInit();
          },
          function(err) {
            vm.fetching = false;
            vm.cardError = err.data.error.message;
            AlertingService.Error(err);
          }
        );
      } else {
        locationId = vm.element.config._id;
        vm.location = vm.element.config;
        startInit();
      }
    }
  };

  function startInit() {
    init().then(
      function() {
        vm.title = vm.location.name;
        vm.fetching = false;
      },
      function(err) {
        vm.fetching = false;
        AlertingService.Error(err);
      }
    );
  }

  function refreshValues() {
    vm.fetching = true;
    GetAnalysis((err, res) => {
      if (err) {
        AlertingService.Error(err);
      } else {
        vm.energyTypes = res;
      }
      vm.fetching = false;
    });
  }

  function init() {
    var pr = $q.defer();
    energySourceTypes().then(function(sourcetypes) {
      let parallel = {
        images: GetPictures
      };
      vm.energySourceTypes = sourcetypes;
      if (Array.isArray(sourcetypes) && sourcetypes.length) {
        parallel.energyTypes = GetAnalysis;
      }
      async.parallel(parallel, function(err, res) {
        if (err) {
          AlertingService.Error(err);
          pr.resolve();
        } else {
          vm.energyTypes = res.energyTypes || [];
          vm.images = res.images;
          pr.resolve();
        }
      });
    });

    return pr.promise;
  }

  function ReadLocation() {
    var pr = $q.defer();
    LocationModel.read(
      {
        id: locationId
      },
      vm.cacheInvalidationTime
    ).then(
      function(res) {
        pr.resolve(res.data);
      },
      function(err) {
        pr.reject(err);
      }
    );
    return pr.promise;
  }

  function energySourceTypes() {
    return new Promise(resolve => {
      EnergySourceTypeModel.read(
        { code: [7, 2, 1] }, //water, electricity, gas
        vm.cacheInvalidationTime
      ).then(
        function(res) {
          resolve(res.data);
        },
        function(err) {
          AlertingService.Error(err);
          resolve([]);
        }
      );
    });
  }

  function GetPictures(callback) {
    PictureLocationModel.read(
      {
        location: locationId
      },
      vm.cacheInvalidationTime
    ).then(
      function(res) {
        var temp = [];
        if (res.data && res.data.length) {
          var image;
          res.data.forEach(function(item, index) {
            image = {
              id: index + 1,
              url:
                utilService.getApiHost + '/pictures/' + item.picture + '/image'
            };
            temp.push(image);
          });
        }
        callback(null, temp);
      },
      function() {
        callback(null, []);
      }
    );
  }

  function GetAnalysis(callback) {
    var parallel = {};
    const energySourceTypeIds = vm.energySourceTypes.map(item => item._id);
    const energySourceTypeObject = vm.energySourceTypes.reduce(
      (obj, item) => ({
        ...obj,
        [item._id]: item
      }),
      {}
    );
    parallel['energySourceType'] = GetEnergySourceAnalysis(
      energySourceTypeIds,
      energySourceTypeObject
    );
    async.parallel(parallel, function(err, res) {
      callback(null, res.energySourceType);
    });
  }

  function GetEnergySourceAnalysis(energySourceTypeIds, energySourceTypes) {
    var d1 = new Date(new Date().getFullYear(), 0, 1).toISOString();
    var d2 = new Date(new Date().getFullYear(), 11, 31).toISOString();
    return function(callback) {
      var obj = {
        value: '{sum}"usageQuantity"',
        accum: 'value',
        arith: 'series1',
        billingDateCompare: '{gte}' + d1 + '{lte}' + d2,
        energySourceType: energySourceTypeIds,
        groupBy: ['billingDateYear', 'location', 'energySourceType'],
        limit: 100,
        location: locationId,
        order: '_id.location',
        series1: 'value'
      };
      var method = ViewModel.custom.getMethod({
        path: 'emanalytics'
      });
      method(obj, vm.cacheInvalidationTime).then(
        function(res) {
          const valuesToReturn = Object.keys(energySourceTypes).map(key => {
            if (res.data && res.data.length > 0) {
              const getEnergySourceData = res.data.find(
                item => item._id.energySourceType === key
              );
              if (getEnergySourceData) {
                return {
                  type: energySourceTypes[key],
                  value: getEnergySourceData.value
                };
              }
            }
            return {
              type: energySourceTypes[key],
              value: 0
            };
          });
          callback(null, valuesToReturn);
        },
        function(err) {
          AlertingService.Error(err);
          const valuesToReturn = Object.keys(energySourceTypes).map(key => ({
            type: energySourceTypes[key],
            value: 0
          }));
          callback(null, valuesToReturn);
        }
      );
    };
  }
}
