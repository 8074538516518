NewCalculationController.$inject = [
  'gettext',
  'analysisCalculation',
  '$stateParams',
  'AlertingService',
  'MetadataService',
  'createOrUpdateService',
  '$scope',
  'SfeHeader',
  'ExpressionModel',
  '$timeout'
];

function NewCalculationController(
  gettext,
  analysisCalculation,
  $stateParams,
  AlertingService,
  MetadataService,
  createOrUpdateService,
  $scope,
  SfeHeader,
  ExpressionModel,
  $timeout
) {
  const vm = this;
  const calculationId = $stateParams.id;
  let variableNames = [];
  vm.cumulativeExpressions = [];
  vm.removeRow = removeRow;
  vm.firstExpression = {
    title: false,
    variables: true
  };
  vm.configureVariableNames = configureVariableNames;

  vm.$onInit = async function init() {
    vm.editMode = !!calculationId;
    vm.createOrUpdate = [
      {
        title: calculationId ? gettext('Update') : gettext('Create'),
        fn: createOrUpdate,
        color: 'primary',
        raised: true,
        disabledFn: createOrUpdateDisabledFn
      }
    ];

    vm.addArithmeticExpression = [
      {
        title: gettext('+ Add Arithmetic Expression'),
        fn: addRow,
        color: 'primary',
        disabledFn: addArithmeticExpressionDisabledFn
      }
    ];

    vm.configuration = {
      PH16: true,
      data: getFormConfiguration()
    };

    vm.cumulativeExpressionsConfiguration = cumulativeExpressionsConfiguration();

    if (vm.editMode) {
      MetadataService.Loading(true);
      vm.configuration.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit calculation'),
        'analytics-calculations-view',
        { id: calculationId }
      );

      vm.configuration.dataObj = {
        ...analysisCalculation
      };

      configureExpressions(analysisCalculation);
    } else {
      vm.configuration.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New calculation'),
        'analytics-calculations-list'
      );
      vm.configuration.dataObj = {};
    }
  };

  async function configureExpressions(calculation) {
    const cumulativeExpressions = [];
    const arithmeticExpressions = [];

    calculation.series.forEach(function(exp) {
      switch (exp.type) {
      case 'Accumulator':
        cumulativeExpressions.push({
          select: exp.value,
          name: exp.name
        });
        break;
      case 'Arithmetic':
        arithmeticExpressions.push({
          value: exp.value,
          name: exp.name
        });
        break;
      }
    });

    // set cumulative expressions
    const promises = cumulativeExpressions.map(async expression => {
      const { data: select } = await ExpressionModel.read({
        id: expression.select
      });
      return {
        ...expression,
        select
      };
    });
    vm.cumulativeExpressions = await Promise.all(promises);

    // set arithmetic expressions
    vm.arithmeticExpressions = arithmeticExpressions;

    configureVariableNames();
    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(
        'Edit ' + calculation.name,
        calculation.description
      );
    });
  }

  function datasetChanged() {
    vm.cumulativeExpressionsConfiguration.disabled = false;
    if (!vm.configuration.dataObj.dataset) {
      vm.cumulativeExpressionsConfiguration.disabled = true;
      vm.cumulativeExpressions = [];
    }
  }

  function configureVariableNames() {
    variableNames = getNamesFromCumulativeExpressions();

    if (vm.arithmeticExpressions) {
      vm.arithmeticExpressions.forEach(expression => {
        expression.variableNames = variableNames;
      });
    }
  }

  function getNamesFromCumulativeExpressions() {
    return vm.cumulativeExpressions.reduce((expressionsArray, expression) => {
      if (expression.name) {
        expressionsArray.push(expression.name);
        return expressionsArray;
      }
    }, []);
  }

  function cumulativeExpressionsConfiguration() {
    return {
      disabled: true,
      addLabel: gettext('+ Add Cumulative Expression'),
      placeholder: gettext('Variable name'),
      required: true,
      onChange: configureVariableNames,
      select: {
        floatingLabel: gettext('Select expression'),
        noDialog: true,
        required: true,
        query: {
          entity: 'expressions',
          method: 'read'
        },
        filterObjectFn: () => ({
          dataset: vm.configuration.dataObj.dataset._id
        }),
        searchParamName: 'filter',
        empty: gettext('There are no Cumulative Expressions.'),
        edit: vm.editMode
      }
    };
  }

  function getFormConfiguration() {
    return [
      {
        placeholder: 'Name',
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: 'Description',
        name: 'description',
        componentType: 'textArea',
        type: 'text',
        maxlength: 500,
        required: false
      },
      {
        configuration: {
          query: {
            entity: 'datasets',
            method: 'read'
          },
          change: datasetChanged,
          entity: 'datasets',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Dataset')
          },
          floatingLabel: gettext('Select Dataset'),
          searchParamName: 'filter',
          required: true,
          createRedirect: {
            state: 'analytics-datasets-new'
          }
        },
        componentType: 'autocompleteDialog',
        edit: vm.editMode,
        name: 'dataset'
      }
    ];
  }

  function addRow() {
    if (!vm.arithmeticExpressions) {
      vm.arithmeticExpressions = [];
    }
    vm.arithmeticExpressions.push({ variableNames });
  }

  function removeRow(index) {
    vm.arithmeticExpressions.splice(index, 1);
  }

  function addArithmeticExpressionDisabledFn() {
    return !(vm.cumulativeExpressions && vm.cumulativeExpressions.length > 0);
  }

  function createOrUpdateDisabledFn() {
    if (
      !$scope.CalculationForm.$valid ||
      vm.sendingRequest ||
      !vm.cumulativeExpressions ||
      vm.cumulativeExpressions.length === 0
    ) {
      return true;
    } else {
      if (vm.arithmeticExpressions) {
        return vm.arithmeticExpressions.some(
          expression =>
            expression.expressionErrors && expression.expressionErrors.length
        );
      }
      return false;
    }
  }

  // create and update functions //
  async function createOrUpdate() {
    vm.sendingRequest = true;

    try {
      await createOrUpdateService.simpleCall(
        {
          entity: 'analysis-calculations',
          method: vm.editMode ? 'update' : 'create'
        },
        vm.editMode,
        calculationId,
        dataToSave(),
        'analytics-calculations-view',
        'id'
      );
      vm.sendingRequest = false;
    } catch (err) {
      vm.sendingRequest = false;
      AlertingService.Error(err);
    }
  }

  function dataToSave() {
    let cumulativeExpressions = [];
    let arithmeticExpressions = [];
    if (vm.cumulativeExpressions && vm.cumulativeExpressions.length) {
      cumulativeExpressions = vm.cumulativeExpressions.map(expression => ({
        type: 'Accumulator',
        value: expression.select._id,
        name: expression.name
      }));
    }
    if (vm.arithmeticExpressions && vm.arithmeticExpressions.length) {
      arithmeticExpressions = vm.arithmeticExpressions.map(expression => ({
        type: 'Arithmetic',
        value: expression.value,
        name: expression.name
      }));
    }
    const series = cumulativeExpressions.concat(arithmeticExpressions);
    return {
      name: vm.configuration.dataObj.name,
      description: vm.configuration.dataObj.description,
      series,
      dataset: vm.configuration.dataObj.dataset._id
    };
  }
}
export default NewCalculationController;
