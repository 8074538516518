PriceListDetailItemsConfiguration.$inject = [
  'gettext',
  '$q',
  'AlertingService',
  'TranslationService',
  'PhysicalCollectionService'
];

function PriceListDetailItemsConfiguration(
  gettext,
  $q,
  AlertingService,
  TranslationService,
  PhysicalCollectionService
) {
  var configuration = {
    entity: {
      singular: gettext('Price list item'),
      plural: gettext('Price list items')
    },
    api: {
      query: {
        entity: 'price-list-items',
        method: 'custom.readList'
      },
      enrich: Enrich
    },
    title: {
      param: 'name'
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'invoices-price-lists-detail-item-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'invoices-price-lists-detail-item-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'priceListItemId'
            },
            {
              valueParam: 'priceListDetailId',
              queryParam: 'priceListDetailId'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          configuration: {
            entity: 'price-list-items',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'order',
        title: gettext('Order')
      },
      {
        title: gettext('Price'),
        param: 'priceString'
      },
      {
        title: gettext('Energy management item'),
        param: 'energyManagementGroupName'
      },
      {
        title: gettext('Item group'),
        param: 'priceListItemName'
      },
      {
        title: gettext('External code'),
        param: 'externalCode',
        type: 'code'
      }
    ],
    badges: [
      {
        title: gettext('Transfer to next month'),
        goodOrBad: true,
        colored: true,
        param: 'transferToNextMonth',
        type: 'goodOrBad',
        good: gettext('Yes'),
        bad: gettext('No')
      }
    ],
    sort: [
      {
        display: 'order ASC',
        params: 'order'
      },
      {
        display: 'order DESC',
        params: '-order'
      },
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function fetchMeasurementUnit(measurementUnit, callback) {
    PhysicalCollectionService.returnMeasurementUnits(measurementUnit).then(
      function(res) {
        callback(null, res);
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function Enrich(item) {
    var deferred = $q.defer();
    var parallel = {
      measurementUnit: async.apply(fetchMeasurementUnit, item.measurementUnit)
    };
    var master = item.priceListDetail
      ? item.priceListDetail.priceListMaster
      : {};
    item.priceListDetailId = item.priceListDetail._id;
    if (
      item.hasOwnProperty('priceListItemGroup') &&
      item.priceListItemGroup !== null
    ) {
      item.priceListItemName = item.priceListItemGroup.name;
    }

    async.parallel(parallel, function(err, res) {
      var measurementUnit = res.measurementUnit;
      item.energyManagementGroupName = 'Unknown';
      if (
        item.hasOwnProperty('energyManagementGroup') &&
        item.energyManagementGroup !== null
      ) {
        item.energyManagementGroupName = item.energyManagementGroup.name;
      }

      var currencySymbol = TranslationService.GetCollectionById(
        'codelists.currencies',
        master.currency
      ).symbol;

      item.priceString = item.price;
      if (master && master.currency && measurementUnit) {
        var metricPrefix = TranslationService.GetCollectionById(
          'codelists.metricPrefixes',
          item.metricPrefix
        );
        item.priceString +=
          ' ' +
          currencySymbol +
          '/' +
          (metricPrefix ? metricPrefix.symbol : '') +
          (measurementUnit.symbol || measurementUnit.name);
      }
      deferred.resolve();
    });
    return deferred.promise;
  }

  return configuration;
}
export default PriceListDetailItemsConfiguration;
