export default [
  {
    name: 'Company Resources',
    _id: '59f31c7a5e12d6001094b638',
    tree: [
      {
        parentName: 'Cost centre',
        childName: 'Cost centre',
        parent: 'petrol_be:cost_centre',
        child: 'petrol_be:cost_centre'
      },
      {
        parentName: 'Location',
        childName: 'Location',
        parent: 'petrol_be:locations',
        child: 'petrol_be:locations'
      },
      {
        parentName: 'Asset',
        childName: 'Asset',
        parent: 'petrol_be:assets​',
        child: 'petrol_be:assets​'
      },
      {
        parentName: 'Location',
        childName: 'Asset',
        parent: 'petrol_be:locations',
        child: 'petrol_be:assets​'
      },
      {
        parentName: 'Measurement',
        childName: 'Measurement',
        parent: 'petrol_be:measurements',
        child: 'petrol_be:measurements'
      },
      {
        parentName: 'Measuring point',
        childName: 'Measuring point',
        parent: 'petrol_be:measuringpoints',
        child: 'petrol_be:measuringpoints'
      },
      {
        parentName: 'Cost centre',
        childName: 'Location',
        parent: 'petrol_be:cost_centre',
        child: 'petrol_be:locations'
      },
      {
        parentName: 'Location',
        childName: 'Asset',
        parent: 'petrol_be:locations',
        child: 'petrol_be:assets​'
      },
      {
        parentName: 'Business partner',
        childName: 'Business partner',
        parent: 'petrol_be:business-partners',
        child: 'petrol_be:business-partners'
      },
      {
        parentName: 'Business partner',
        childName: 'Location',
        parent: 'petrol_be:business-partners',
        child: 'petrol_be:locations'
      },
      {
        parentName: 'Location',
        childName: 'Measuring point',
        parent: 'petrol_be:locations',
        child: 'petrol_be:petrol_be:measuringpoints'
      },
      {
        parentName: 'Asset',
        childName: 'Measurement',
        parent: 'petrol_be:assets',
        child: 'petrol_be:measurements'
      },
      {
        parentName: 'Measuring point',
        childName: 'Measurement',
        parent: 'petrol_be:measuringpoints',
        child: 'petrol_be:measurements'
      }
    ]
  },
  {
    name: 'IoT platform',
    _id: '59f31c835e12d6001094b639',
    tree: [
      {
        parentName: 'Device',
        childName: 'Device',
        parent: 'petrol_be:devices',
        child: 'petrol_be:devices'
      },
      {
        parentName: 'Job',
        childName: 'Job',
        parent: 'petrol_be:jobs',
        child: 'petrol_be:jobs'
      }
    ]
  },
  {
    name: 'Scheduler',
    _id: '59f31c875e12d6001094b63a',
    tree: [
      {
        parentName: 'Scheduler',
        childName: 'Scheduler',
        parent: 'petrol_be:schedulers',
        child: 'petrol_be:schedulers'
      }
    ]
  }
];
