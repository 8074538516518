CompanyStructureController.$inject = [
  'CompanyResourcesViewData',
  '$state',
  'gettext',
  '$timeout',
  'TranslationService',
  '$scope',
  'StorageService',
  'SfeFilterService'
];

function CompanyStructureController(
  CompanyResourcesViewData,
  $state,
  gettext,
  $timeout,
  TranslationService,
  $scope,
  StorageService,
  SfeFilterService
) {
  const vm = this;
  vm.elementSelected = elementSelected;
  vm.title = $state.current.pageTitle;
  vm.state = $state.current.name;
  vm.entity = 'company-structure-nodes';
  vm.listParents = true;
  vm.filters = [
    {
      display: gettext('Name'),
      param: 'name',
      type: 'string'
    },
    {
      display: gettext('Code'),
      param: 'code',
      type: 'string'
    },
    {
      ctrlFn: function() {
        return $timeout(function() {
          let costCentreTypes = TranslationService.GetCollection(
            'codelists.costCentreTypes'
          );
          return costCentreTypes.filter(function(type) {
            return type.id !== 3;
          });
        }, 200);
      },
      singleSelect: true,
      display: gettext('Type'),
      param: 'type',
      valueField: 'id'
    }
  ];

  // set height of filters
  function setContainerHeight() {
    let item = document.querySelector('.tree-container');
    let filter = document.querySelector('.tree-filters');
    if (filter) {
      let filterHeight = $('.tree-filters').height();
      if (filterHeight && item) {
        angular
          .element(item)
          .css('height', 'calc(100% - ' + filterHeight + 'px)');
      }
    }
    /*************
      MEMORY CLEANUP
      *************/
    item = null;
    filter = null;
  }

  $scope.$on('$destroy', function() {
    if (watchRelist) {
      watchRelist();
    }
  });

  const watchRelist = $scope.$watch('vm.relistData', function() {
    if (vm.relistData) {
      configureFilterObject();
    }
  });

  vm.$onInit = async () => {
    setContainerHeight();
    await getStoredFilters();
    vm.relistData = true;
    vm.treeType = 'root';
    $scope.$evalAsync();
  };

  function configureFilterObject() {
    vm.relistData = false;
    vm.queryObject = {};
    vm.filters.forEach(function(filter) {
      if (filter.searchTerm) {
        vm.queryObject[filter.param] = filter.searchTerm;
      }
      if (filter.selected) {
        vm.queryObject[filter.param] = filter.selected;
      }
    });
    StorageService.saveListData(vm.entity, vm.filters);
  }

  /**
   * @description Returns the stored filters for current entity list.
   * @function
   */
  async function getStoredFilters() {
    const filters = StorageService.getFilters(vm.entity);
    await SfeFilterService.assignSelectedFiltersValues(vm.filters, filters);
  }

  function elementSelected(element) {
    vm.selected = element;
  }
}
export default CompanyStructureController;
