module.exports = {
  entity: 'master-invoices',
  path: 'master-invoices',
  entityId: 140,
  name: {
    en: 'Master invoices',
    sl_SI: 'Zbirni računi'
  },
  selectedParamName: '_id',
  networkModel: {
    entity: 'master-invoices',
    read: 'read',
    dialogMethod: {
      entity: 'invoicelist',
      method: 'custom.readDialog'
    },
    privilegeMethod: {
      entity: 'invoicelist',
      method: 'custom.readDialog',
      queryParam: 'masterInvoice'
    }
  },
  scope: 'tis_be:master-invoices',
  displayFields: [
    {
      field1: 'masterInvoiceNumber',
      type: 'string'
    },
    {
      field1: 'location',
      field2: 'name',
      title: {
        en: 'Location',
        sl_SI: 'Lokacija'
      },
      type: 'string'
    },
    {
      field1: 'billingDate',
      type: 'date',
      title: {
        en: 'Billing Date',
        sl_SI: 'Datum obračuna'
      }
    },
    {
      field1: 'masterInvoiceBookValue',
      title: {
        en: 'Invoice Book Number',
        sl_SI: 'Vrednost za knjiženje'
      },
      precision: 3,
      type: 'number'
    },
    {
      field1: 'supplierName',
      title: {
        en: 'Supplier',
        sl_SI: 'Dobavitelj'
      },
      type: 'string'
    },
    {
      field1: 'costCentreName',
      title: {
        en: 'Cost Centre',
        sl_SI: 'Stroškovno mesto'
      },
      type: 'string'
    }
  ],
  dialog: {
    title: {
      en: 'Select Master invoice',
      sl_SI: 'Izberite zbirni račun'
    },
    listInfo: {
      en: 'Select master invoice from the list below',
      sl_SI: 'Izberite zbirni račun s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'masterInvoiceNumber',
        type: 'string'
      },
      {
        field1: 'location',
        field2: 'name',
        title: {
          en: 'Location',
          sl_SI: 'Lokacija'
        },
        type: 'string'
      },
      {
        field1: 'billingDate',
        type: 'date',
        title: {
          en: 'Billing Date',
          sl_SI: 'Datum obračuna'
        }
      },
      {
        field1: 'masterInvoiceBookValue',
        title: {
          en: 'Invoice Book Number',
          sl_SI: 'Vrednost za knjiženje'
        },
        precision: 3,
        type: 'number'
      },
      {
        field1: 'supplierName',
        title: {
          en: 'Supplier',
          sl_SI: 'Dobavitelj'
        },
        type: 'string'
      },
      {
        field1: 'costCentreName',
        title: {
          en: 'Cost Centre',
          sl_SI: 'Stroškovno mesto'
        },
        type: 'string'
      }
    ]
  },
  permissionConfiguration: {
    valueParameterName: 'masterInvoice'
  }
}
