import './notification.scss';
import template from './sfe-notification.component.html';

sfeNotification.$inject = ['$window'];

function sfeNotification() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      user: '='
    },
    link: linkFn,
    controller: sfeNotificationController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  function linkFn(scope, el, attrs, ctrl) {
    ctrl.AssignHeight = AssignHeight;
    ctrl.removeStyle = removeStyle;
    function removeStyle() {
      var notifications = el[0].querySelectorAll('.sfe-notification-content');
      notifications.forEach(function(notificationContent) {
        notificationContent.parentNode.removeAttribute('style');
      });
      /*************
      MEMORY CLEANUP
      *************/
      notifications = null;
    }

    function AssignHeight() {
      var notifications = el[0].querySelectorAll('.sfe-notification-content');
      var height = 0;
      notifications.forEach(function(notificationContent) {
        notificationContent.childNodes.forEach(function(element) {
          height += element.offsetHeight || 0;
        });
        notificationContent.parentNode.setAttribute(
          'style',
          'height:' + height + 'px'
        );
        /*************
        MEMORY CLEANUP
        *************/
        notificationContent = null;
      });
      /*************
      MEMORY CLEANUP
      *************/
      notifications = null;
    }
  }
}

sfeNotificationController.$inject = [
  'AlertingService',
  '$q',
  '$timeout',
  '$interval',
  '$window',
  '$scope',
  'NotificationModel',
  'InteractionModel'
];

function sfeNotificationController(
  AlertingService,
  $q,
  $timeout,
  $interval,
  $window,
  $scope,
  NotificationModel,
  InteractionModel
) {
  var vm = this;
  var CancelFetching;
  var LIMIT = 3;
  var TOTAL = 0;
  var TOTAL_SEEN = 0;
  vm.init = init;
  vm.notifications = [];
  vm.hideNotification = true;
  vm.unseenNotifications = [];
  vm.markAsSeen = markAsSeen;
  vm.toggleSeenNotifications = toggleSeenNotifications;
  vm.displayNotifications = displayNotifications;
  vm.loadMoreNotifications = loadMoreNotifications;
  vm.loadMoreSeenNotifications = loadMoreSeenNotifications;
  vm.$onInit = init;

  function init() {
    fetchUnseenNotifications();
    CancelFetching = $interval(function() {
      fetchUnseenNotifications();
    }, 3600000);
  }

  function displayNotifications(event) {
    vm.hideNotification = !vm.hideNotification;
    vm.displaySeenNotifications = false;
    if (event) {
      event.stopPropagation();
    }
    if (vm.hideNotification) {
      $window.onclick = null;
      init();
    } else {
      $interval.cancel(CancelFetching);
      var pagination = {
        page: 1,
        limit: LIMIT,
        order: '-_id'
      };
      vm.notifications = [];
      vm.seenNotifications = [];
      fetchUnseenNotifications(pagination);
      var path;
      $window.onclick = function(ev) {
        path = ev.path || (ev.composedPath && ev.composedPath());
        var found;
        for (var i = 0; i < path.length && !found; i++) {
          _.each(path[i].classList, function(arg) {
            if (arg === 'sfe-notification-container') {
              found = true;
            }
          });
        }
        if (!found) {
          vm.hideNotification = true;
          $window.onclick = null;
          $scope.$apply();
        }
      };
    }
  }

  function fetchSeenNotification(pagination) {
    vm.fetchingSeen = true;
    var fetchParams = pagination || {
      page: 1,
      limit: LIMIT,
      order: '-_id'
    };
    fetchUserNotifications(true, fetchParams).then(
      function(res) {
        var page = res.pagination;
        if (!vm.seenNotifications) {
          vm.seenNotifications = [];
        }
        // pushNotifications(notifications, vm.seenNotifications);
        vm.seenNotifications = _.unionBy(vm.seenNotifications, res.data, '_id');
        if (page.total > vm.seenNotifications.length) {
          vm.loadMoreSeen = true;
          TOTAL_SEEN = page.total;
        } else {
          vm.loadMoreSeen = false;
        }

        vm.displaySeenNotifications = true;
        $timeout(function() {
          vm.fetchingSeen = false;
        }, 200);
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function removeNotification(notification) {
    _.remove(vm.notifications, {
      notification: notification._id
    });
  }

  function markAsSeen(notification) {
    notification.isUpdating = true;
    NotificationModel.custom
      .markSeen({
        notification: notification._id,
        user: notification.user
      })
      .then(
        function() {
          notification.isUpdating = false;
          removeNotification(notification);
          vm.totalUnseenNotifications--;
          if (vm.displaySeenNotifications) {
            var pagination = {
              page: 1,
              limit: vm.seenNotifications.length,
              order: '-_id'
            };
            fetchSeenNotification(pagination);
          }
          vm.seenNotifications = [];
        },
        function(err) {
          notification.isUpdating = false;
          AlertingService.Error(err);
        }
      );
  }

  function loadMoreNotifications() {
    if (vm.notifications.length < TOTAL) {
      var nextPage = Math.floor(vm.notifications.length / LIMIT) + 1;
      var pagination = {
        page: nextPage,
        limit: LIMIT,
        order: '-_id'
      };
      fetchUnseenNotifications(pagination);
    }
  }

  function fetchUserNotifications(seen, pagination) {
    var deferred = $q.defer();
    var fetchParams = pagination || {};
    fetchParams.seen = seen;
    fetchParams.user = vm.user;
    InteractionModel.custom.readNoCancel(fetchParams).then(
      function(res) {
        deferred.resolve(res);
      },
      function(err) {
        deferred.reject(err);
      }
    );
    return deferred.promise;
  }

  function fetchUnseenNotifications(pagination) {
    var paginationObject = pagination || {
      page: 1,
      limit: LIMIT,
      order: '-_id'
    };
    vm.fetchingUnseen = true;
    fetchUserNotifications(false, paginationObject).then(
      function(res) {
        var notifications = res.data;
        var page = res.pagination;
        if (!vm.notifications) {
          vm.notifications = [];
        }
        vm.totalUnseenNotifications = page.total;
        // pushNotifications(notifications, vm.notifications);
        vm.notifications = _.uniqBy(
          _.concat(vm.notifications, notifications),
          '_id'
        );
        if (page.total > vm.notifications.length) {
          vm.loadMore = true;
          TOTAL = page.total;
        } else {
          vm.loadMore = false;
        }
        vm.fetchingUnseen = false;
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function loadMoreSeenNotifications() {
    if (vm.seenNotifications.length < TOTAL_SEEN) {
      var nextPage = Math.floor(vm.seenNotifications.length / LIMIT) + 1;
      var pagination = {
        page: nextPage,
        limit: LIMIT,
        order: '-_id'
      };
      fetchSeenNotification(pagination);
    }
  }

  function toggleSeenNotifications() {
    if (vm.displaySeenNotifications) {
      $timeout(function() {
        vm.displaySeenNotifications = false;
      }, 300);
    } else {
      fetchSeenNotification();
    }
  }
}

export default sfeNotification;
