NotificationController.$inject = [
  'AlertingService',
  '$state',
  'StandardUtils',
  'MetadataService',
  'UserModel',
  'GroupModel',
  'RealmModel',
  'NotificationModel',
  'notification',
  '$scope'
];

function NotificationController(
  AlertingService,
  $state,
  StandardUtils,
  MetadataService,
  UserModel,
  GroupModel,
  RealmModel,
  NotificationModel,
  notification,
  $scope
) {
  var vm = this;

  init();

  async function init() {
    MetadataService.Loading(true);
    vm.notification = await fetchMetadata(notification);
    MetadataService.Loading(false);
    MetadataService.ChangeMetadata(notification.subject);
    $scope.$applyAsync();
  }

  async function fetchMetadata(notification) {
    let users = [];
    let groups = [];
    let organizations = [];

    const entities = [];

    const promises = [];
    if (notification.users.length > 0) {
      promises.push(
        UserModel.read({
          _id: notification.users
        })
      );
      entities.push('users');
    }
    if (notification.groups.length > 0) {
      promises.push(
        GroupModel.read({
          _id: notification.groups
        })
      );
      entities.push('groups');
    }
    if (notification.organizations.length > 0) {
      promises.push(
        RealmModel.read({
          _id: notification.organizations
        })
      );
      entities.push('organizations');
    }

    const result = await Promise.allSettled(promises);

    entities.forEach((item, index) => {
      let { status, value, reason } = result[index];
      if (status == 'fulfilled') {
        switch (item) {
        case 'users':
          users = value.data.map(user => ({
            ...user,
            imageUrl: StandardUtils.getUserImageUrl(user, 'sm')
          }));
          break;
        case 'groups':
          groups = value.data;
          break;
        case 'organizations':
          organizations = value.data;
          break;
        }
      } else {
        AlertingService.Error(reason);
      }
    });

    return {
      ...notification,
      users,
      groups,
      organizations
    };
  }
}
export default NotificationController;
