GroupController.$inject = [
  'MetadataService',
  '$stateParams',
  'group',
  '$timeout'
];

function GroupController(MetadataService, $stateParams, group, $timeout) {
  MetadataService.Loading(true);
  const vm = this;
  vm.group = group;
  vm.groupId = $stateParams.id;
  MetadataService.Loading(false);
  $timeout(() => {
    MetadataService.ChangeMetadata(group.name, group.description);
  });
}

export default GroupController;
