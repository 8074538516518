module.exports = {
  entity: 'analyticIntervals',
  path: 'analyticIntervals',
  entityId: 225,
  name: {
    en: 'Analytic intervals',
    sl_SI: 'Analitični intervali'
  },
  networkModel: {
    entity: 'analyticIntervals',
    read: 'read'
  },
  codelist: 'analyticIntervals',
  scope: 'tis_be:analyticIntervals',

  dialog: {
    title: {
      en: 'Select analytic interval',
      sl_SI: 'Izberite analitični interval'
    },
    listInfo: {
      en: 'Select Analytic interval from the list below',
      sl_SI: 'Izberite analitični interval s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      }
    ],
    codelist: 'analyticIntervals'
  }
}
