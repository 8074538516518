import { DateTime } from 'luxon';
import './manual-input-form.scss';

ManualInputFormController.$inject = [
  '$scope',
  'gettext',
  'MetadataService',
  '$state',
  'AlertingService',
  'StorageService',
  'TimeSeriesManualInputFormModel',
  'TimeSeriesModel',
  'gettextCatalog',
  'manualInput',
  '$timeout',
  'TimeSeriesProcessingValuesModel',
  'CrudToastFactory',
  'ManualInputFormHelper',
  'SfeForm2DateFromPriorToValidationService',
  'InfoDialog',
  '$mdDialog'
];

function ManualInputFormController(
  $scope,
  gettext,
  MetadataService,
  $state,
  AlertingService,
  StorageService,
  TimeSeriesManualInputFormModel,
  TimeSeriesModel,
  gettextCatalog,
  manualInput,
  $timeout,
  TimeSeriesProcessingValuesModel,
  CrudToastFactory,
  ManualInputFormHelper,
  SfeForm2DateFromPriorToValidationService,
  InfoDialog,
  $mdDialog
) {
  const vm = this;
  vm.showFrom = true;
  vm.formApi = {};
  vm.addDateFormApi = {};

  vm.regularApi = {};
  vm.irregularApi = {};

  vm.saveAllActions = [
    {
      title: gettext('Save All'),
      color: 'success',
      icon: {
        name: 'save',
        type: 2
      },
      fn: () => {
        let values;
        if (vm.irregularSeries) {
          values = vm.irregularApi.getValues();
        } else {
          values = vm.regularApi.getValues();
        }
        if (
          !Array.isArray(values.dates) ||
          values.dates.length < 1 ||
          !Array.isArray(values.timeSeries) ||
          values.timeSeries.length < 1
        ) {
          AlertingService.Error(
            gettextCatalog.getString('There are no data to be saved!')
          );
        } else {
          saveAll(values);
          $scope.$applyAsync();
        }
      }
    }
  ];

  let FROM;
  let TO;

  vm.manualInputId = $state.params.id;
  vm.displayModeVertically = false;
  vm.$onInit = init;
  vm.saveValue = saveValue;

  function init() {
    MetadataService.Loading(true);
    //SET LATER TO USE LOCAL TIME
    later.date.localTime();
    setIntervalSelector();
    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(manualInput.name);
    });
    vm.displayModeVertically = !manualInput.displayHorizontal;
    vm.manualInputName = manualInput.name;
    initTimeSeries();
  }
  /**
   * @description gets manual input timeSeries.
   * @function
   */
  async function initTimeSeries() {
    try {
      const { timeSeries, defaultTimeSeries } = await fetchTimeSeries(
        manualInput
      );
      vm.manualInput = manualInput;
      vm.timeSeries = timeSeries;
      vm.defaultTimeSeries = defaultTimeSeries;
      if (
        Array.isArray(timeSeries) &&
        timeSeries.length > 0 &&
        timeSeries[0].dataSamplingType === 200
      ) {
        vm.irregularSeries = true;
      } else {
        vm.irregularSeries = false;
      }
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  /**
   * @description stores manual input time picker configuration.
   * @function
   * @param {Object} fromTo from to date object
   * @return {dataType}
   */
  function storeManualInputConfiguration(fromTo) {
    let selectedInterval;
    if (typeof vm.formApi.getValue === 'function') {
      selectedInterval = vm.formApi.getValue('selectorType');
    }
    let timeConfiguration = {
      selectedInterval: selectedInterval
    };

    if (fromTo) {
      timeConfiguration = {
        ...timeConfiguration,
        ...fromTo
      };
    } else {
      const storedConfiguration =
        StorageService.get('manualInputConfiguration') || {};
      timeConfiguration = {
        ...timeConfiguration,
        ...storedConfiguration
      };
    }
    StorageService.save('manualInputConfiguration', timeConfiguration);
  }

  /**
   * @description fetches manual input and all connected time series and default values data and constructs display array.
   * @function
   */
  async function triggerGetManualInputValues() {
    let currentManualInput;
    if (vm.irregularSeries) {
      currentManualInput = vm.irregularApi.getValues();
    } else {
      currentManualInput = vm.regularApi.getValues();
    }

    if (
      Array.isArray(currentManualInput.dates) &&
      Array.isArray(currentManualInput.timeSeries) &&
      currentManualInput.dates.length > 0 &&
      currentManualInput.timeSeries.length > 0
    ) {
      const title = gettextCatalog.getString('Reload manual input values');
      const textItem = {
        text: gettextCatalog.getString(
          'Are you sure you want to reload values? All unsaved data will be lost.'
        ),
        type: 'text'
      };
      const actions = [
        {
          title: gettext('No'),
          color: 'primary',
          cancel: true
        },
        {
          title: gettext('Yes'),
          fn: async () => {
            $mdDialog.cancel();
            loadManualInput();
          },
          color: 'success'
        }
      ];

      InfoDialog.open(title, null, [textItem], actions);
    } else {
      loadManualInput();
    }
  }
  /**
   * @description fetches manual input and all connected time series and default values data and constructs display array.
   * @function
   */
  async function loadManualInput() {
    vm.loading = true;
    vm.manualInputIsLoading = true;
    try {
      const { data: manualInput } = await TimeSeriesManualInputFormModel.read({
        id: vm.manualInputId
      });
      vm.manualInputName = manualInput.name;

      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(manualInput.name);

      const range = getDateRange();
      storeManualInputConfiguration({
        FROM: range.from,
        TO: range.to
      });

      const { timeSeries, defaultTimeSeries } = await fetchTimeSeries(
        manualInput
      );
      if (Array.isArray(timeSeries) && timeSeries.length > 0) {
        if (
          timeSeries[0].dataSamplingType == 100 &&
          typeof vm.regularApi.loadValues === 'function'
        ) {
          //REGULAR TIME SERIES
          const {
            foundDifferentTimeZones,
            noTimeSeriesSelected
          } = await vm.regularApi.loadValues({
            range,
            manualInput,
            timeSeries,
            defaultTimeSeries
          });
          vm.constructInputDataCalled = true;

          vm.noTimeSeriesSelected = noTimeSeriesSelected;
          vm.foundDifferentTimeZones = foundDifferentTimeZones;
        } else if (
          timeSeries[0].dataSamplingType == 200 &&
          typeof vm.irregularApi.loadValues === 'function'
        ) {
          //IRREGULAR TIME SERIES
          const { noTimeSeriesSelected } = await vm.irregularApi.loadValues({
            range,
            manualInput,
            timeSeries,
            defaultTimeSeries
          });
          vm.constructInputDataCalled = true;
          vm.noTimeSeriesSelected = noTimeSeriesSelected;
        }
      } else {
        vm.noTimeSeriesSelected = true;
        AlertingService.Error(gettext('There are no time series'));
      }
    } catch (err) {
      AlertingService.Error(err);
    }
    vm.manualInputIsLoading = false;
    vm.loading = false;
    $scope.$applyAsync();
  }

  /**
   * @description fetches manual input time series.
   * @function
   * @param {Object} manualInput
   * @return {Promise}
   */
  async function fetchTimeSeries(manualInput) {
    if (manualInput.groups && manualInput.groups.length) {
      let timeSeriesToFetch = manualInput.groups.reduce((result, group) => {
        const timeSeriesIds = group.timeSeriesItems.reduce((res, item) => {
          res = [...res, item.timeSeries];
          if (item.defaultValueType == 2) {
            //FROM ANOTHER TIME SERIES
            res = [...res, item.defaultValueTimeSeriesId];
          }
          return res;
        }, []);
        result = result.concat(timeSeriesIds);
        return result;
      }, []);
      timeSeriesToFetch = Array.from(new Set(timeSeriesToFetch));

      try {
        const { data } = await TimeSeriesModel.read({
          _id: timeSeriesToFetch,
          populate: 'dataScheduler',
          limit: timeSeriesToFetch.length
        });

        const timeSeries = manualInput.groups.reduce((result, group) => {
          const items = group.timeSeriesItems.map(item =>
            data.find(ts => ts._id === item.timeSeries)
          );

          result = [...result, ...items];
          return result;
        }, []);

        const defaultTimeSeries = manualInput.groups.reduce((result, group) => {
          const defaultItems = group.timeSeriesItems.reduce((res, item) => {
            if (item.defaultValueType == 2) {
              //FROM ANOTHER TIME SERIES
              res = [
                ...res,
                data.find(ts => ts._id === item.defaultValueTimeSeriesId)
              ];
            }
            return res;
          }, []);

          result = [...result, ...defaultItems];
          return result;
        }, []);

        return { timeSeries, defaultTimeSeries };
      } catch (err) {
        AlertingService.Error(err);
      }
      return {};
    }
    return [];
  }

  /**
   * @description returns date filter.
   * @function
   * @return {Object}
   */
  function getDateRange() {
    const values = vm.formApi.getValues();
    const { selectorType } = values;
    const day = values.day[0];
    const month = values.month[0];
    const year = values.year[0];
    const from = values.from[0];
    const to = values.to[0];

    switch (selectorType) {
    case 'day':
      FROM = DateTime.fromJSDate(day)
        .startOf('day')
        .toMillis();
      TO = DateTime.fromJSDate(day)
        .endOf('day')
        .toMillis();
      break;
    case 'month':
      FROM = DateTime.fromJSDate(month)
        .startOf('month')
        .toMillis();
      TO = DateTime.fromJSDate(month)
        .endOf('month')
        .toMillis();

      break;
    case 'year':
      FROM = DateTime.fromJSDate(year)
        .startOf('year')
        .toMillis();
      TO = DateTime.fromJSDate(year)
        .endOf('year')
        .toMillis();

      break;
    case 'custom':
      FROM = from.getTime();
      TO = to.getTime();
      break;
    }

    return {
      from: FROM,
      to: TO
    };
  }

  function setIntervalSelector() {
    const storedConfiguration =
      StorageService.get('manualInputConfiguration') || {};

    const now = new Date();
    (FROM = storedConfiguration.FROM || now.setHours(0, 0, 0, 0)),
    (TO = storedConfiguration.TO || now.setHours(23, 59, 59, 59));
    vm.config = getFormConfiguration(FROM, TO, storedConfiguration);
    vm.addDateConfig = getDateFormConfig();
  }
  /**
   * @description returns add new date form configuration.
   * @function
   * @return {Object}
   */
  function getDateFormConfig() {
    return {
      title: gettext('Select report dates'),
      name: 'recalculateForm',
      fields: [
        {
          id: 'newDate',
          title: gettextCatalog.getString('New Date'),
          type: {
            name: 'date',
            options: {
              enableTime: true,
              enableSeconds: true
            }
          },
          hide: () => {
            return !vm.irregularSeries;
          },
          required: false,
          initialize: () => {
            return null;
          },
          actions: [
            {
              color: 'accent',
              title: gettext('Add date'),
              icon: {
                type: 2,
                name: 'insert_invitation'
              },
              fn: async api => {
                const newDate = api.getValue('newDate');
                if (
                  Array.isArray(newDate) &&
                  newDate[0] != null &&
                  vm.irregularApi != null &&
                  typeof vm.irregularApi.addNewDate === 'function'
                ) {
                  await vm.irregularApi.addNewDate(
                    newDate[0],
                    vm.manualInput,
                    vm.timeSeries,
                    vm.defaultTimeSeries
                  );
                  vm.constructInputDataCalled = true;
                }
                api.setValue('newDate', null);
              },
              disabledFn: api => {
                const newDate = api.getValue('newDate');
                return !(Array.isArray(newDate) && newDate[0] != null);
              }
            }
          ]
        }
      ]
    };
  }
  /**
   * @description returns interval selector form configuration.
   * @function
   * @param {date} from
   * @param {date} to
   * @param {Object} storedConfiguration
   * @return {Object}
   */
  function getFormConfiguration(FROM, TO, storedConfiguration) {
    let fromToValidation = SfeForm2DateFromPriorToValidationService.get(
      'from',
      'to'
    );
    return {
      title: gettext('Select report dates'),
      name: 'recalculateForm',
      actions: [
        {
          title: gettext('get values'),
          color: 'primary',
          icon: {
            type: 5,
            name: 'fa-download',
            color: 'primary'
          },
          fn: () => {
            triggerGetManualInputValues(true);
          },
          disabledFn: api => !api.formValidity() || vm.loading
        }
      ],
      fields: [
        {
          id: 'selectorType',
          type: {
            name: 'radio',
            options: {
              layout: 'row',
              items: [
                {
                  id: 'day',
                  name: gettextCatalog.getString('Day')
                },
                {
                  id: 'month',
                  name: gettextCatalog.getString('Month')
                },
                {
                  id: 'year',
                  name: gettextCatalog.getString('Year')
                },
                {
                  id: 'custom',
                  name: gettextCatalog.getString('Custom')
                }
              ],
              modelProperty: 'id',
              display: data => {
                return data && data.name
                  ? data.name
                  : gettextCatalog.getString('Unknown');
              }
            }
          },
          initialize: () => {
            let selectedInterval = storedConfiguration.selectedInterval;
            if (!selectedInterval) {
              selectedInterval = 'day';
            }
            return selectedInterval;
          },
          required: true
        },
        {
          id: 'day',
          title: gettextCatalog.getString('Select day'),
          type: {
            name: 'date',
            enableTime: true,
            enableSeconds: true
          },
          hide: api => {
            const selectorType = api.getValue('selectorType');
            return selectorType !== 'day';
          },
          required: true,
          initialize: () => {
            return [new Date(FROM)];
          }
        },
        {
          id: 'month',
          title: gettextCatalog.getString('Select month'),
          type: {
            name: 'date',
            options: {
              monthSelector: true
            }
          },
          hide: api => {
            const selectorType = api.getValue('selectorType');
            return selectorType !== 'month';
          },
          required: true,
          initialize: () => {
            return [new Date(FROM)];
          }
        },
        {
          id: 'year',
          title: gettextCatalog.getString('Enter year'),
          type: {
            name: 'date',
            options: {
              yearSelector: true
            }
          },
          hide: api => {
            const selectorType = api.getValue('selectorType');
            return selectorType !== 'year';
          },
          required: true,
          initialize: () => {
            return [new Date(FROM)];
          }
        },
        {
          id: 'from',
          title: gettextCatalog.getString('From'),
          type: {
            name: 'date',
            options: {
              enableTime: true,
              enableSeconds: true
            }
          },
          hide: api => {
            const selectorType = api.getValue('selectorType');
            return selectorType !== 'custom';
          },
          required: true,
          initialize: () => {
            return [new Date(FROM)];
          },
          onChange: async api => {
            if (typeof api.revalidate == 'function') {
              await $timeout();
              api.revalidate();
            }
          },
          validators: {
            minDateCustom: fromToValidation
          }
        },
        {
          id: 'to',
          title: gettextCatalog.getString('To'),
          type: {
            name: 'date',
            options: {
              enableTime: true,
              enableSeconds: true
            }
          },
          hide: api => {
            const selectorType = api.getValue('selectorType');
            return selectorType !== 'custom';
          },
          required: true,
          initialize: () => {
            return [new Date(TO)];
          },
          onChange: async api => {
            if (typeof api.revalidate == 'function') {
              await $timeout();
              api.revalidate();
            }
          },
          validators: {
            minDateCustom: fromToValidation
          }
        }
      ]
    };
  }

  async function saveAll(params) {
    vm.loading = false;

    const { timeSeries, dates } = params;
    const promises = timeSeries.reduce((result, ts) => {
      let newValues = ts.modelValues.reduce((result, valueModel, index) => {
        let apiObject = createApiValueObject(
          ts,
          valueModel,
          dates[index].validAt
        );
        if (apiObject != null) {
          return [
            ...result,
            {
              apiObject,
              index
            }
          ];
        }
        return result;
      }, []);
      if (newValues.length > 0) {
        return [
          ...result,
          {
            params: {
              id: ts._id,
              newValues
            },
            promise: TimeSeriesProcessingValuesModel.create(
              {
                timeSeriesId: ts._id,
                timeliness: 100
              },
              newValues.map(({ apiObject }) => apiObject)
            )
          }
        ];
      }
      return result;
    }, []);
    const result = await Promise.allSettled(
      promises.map(({ promise }) => promise)
    );
    result.forEach(({ value, status }, index) => {
      let currentPromise = promises[index];
      if (status === 'fulfilled') {
        if (value.data.valuesDiscarded > 0) {
          AlertingService.Error(
            gettextCatalog.getString(
              '{{timeSeriesId}} Some values couldn\'t be saved. Values Inserted - {{valuesInserted}}, Values Discarded {{valuesDiscarded}}',
              { timeSeriesId: promises[index].params.id, ...value.data }
            )
          );
        } else {
          let changedTimeSeries = timeSeries.find(
            ts => ts._id === currentPromise.params.id
          );
          //RESET DEFAULT EXCLAMATION MARK
          if (changedTimeSeries != null) {
            currentPromise.params.newValues.forEach(({ index }) => {
              changedTimeSeries.modelValues[index].defaultValue = false;
            });
          }
        }
      } else {
        AlertingService.Error(value);
      }
    });
    vm.loading = false;
    CrudToastFactory.toast('update');
  }

  function createApiValueObject(timeSeries, valueModel, timestamp) {
    if (
      valueModel.value != null &&
      valueModel.originalValue != valueModel.value
    ) {
      let inputValue;
      const timeRegex = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])$/;

      switch (timeSeries.dataType) {
      case 1: //BOOL
        inputValue = String(Boolean(valueModel.value));
        valueModel.value = Boolean(valueModel.value);
        break;
      case 2: //INT
        if (Number.isInteger(Number(valueModel.value))) {
          inputValue = valueModel.value;
        }
        break;
      case 3: //DECIMAL
        if (!Number.isNaN(Number(valueModel.value))) {
          inputValue = valueModel.value;
        }
        break;
      case 4: //STRING
        inputValue = valueModel.value;
        break;
      case 6: //TIME
        if (timeRegex.test(valueModel.value)) {
          const originalTime = DateTime.fromJSDate(
            new Date(valueModel.originalValue)
          ).toFormat('HH:mm:ss');

          inputValue = valueModel.value;
          if (originalTime === inputValue) {
            inputValue = null;
          }
        }
        break;
      case 5: //DATE
        if (new Date(valueModel.date) != 'Invalid Date') {
          const originalDate = DateTime.fromJSDate(
            new Date(valueModel.originalValue)
          ).toFormat('yyyy-MM-dd');
          inputValue = DateTime.fromJSDate(
            new Date(valueModel.date)
          ).toFormat('yyyy-MM-dd');
          if (originalDate === inputValue) {
            inputValue = null;
          }
        }
        break;
      case 7: //DATE TIME
        if (
          new Date(valueModel.date) != 'Invalid Date' &&
            timeRegex.test(valueModel.value)
        ) {
          inputValue =
              DateTime.fromJSDate(new Date(valueModel.date)).toFormat(
                'yyyy-MM-dd '
              ) + valueModel.value;
          let originalDateTime = DateTime.fromJSDate(
            new Date(valueModel.originalValue)
          ).toFormat('yyyy-MM-dd HH:mm:ss');
          if (originalDateTime === inputValue) {
            inputValue = null;
          }
        }
        break;
      }
      if (inputValue != null) {
        return {
          value: inputValue,
          validAt: DateTime.fromMillis(timestamp).toFormat(
            'dd/MM/yyyy HH:mm:ss'
          )
        };
      }
    }
  }

  async function saveValue(timeSeries, dateObject, id, index) {
    let InputValue;
    switch (timeSeries.dataType) {
    case 1: //BOOL
      InputValue = String(Boolean(timeSeries.modelValues[index].value));
      timeSeries.modelValues[index].value = Boolean(
        timeSeries.modelValues[index].value
      );
      break;
    case 2: //INT
    case 3: //DECIMAL
    case 4: //STRING
    case 6: //TIME
      InputValue = timeSeries.modelValues[index].value;
      break;
    case 5: //DATE
      InputValue = DateTime.fromJSDate(
        new Date(timeSeries.modelValues[index].date)
      ).toFormat('yyyy-MM-dd');
      break;
    case 7: //DATE TIME
      InputValue =
          DateTime.fromJSDate(
            new Date(timeSeries.modelValues[index].date)
          ).toFormat('yyyy-MM-dd ') + timeSeries.modelValues[index].value;
      break;
    }

    const apiObject = [
      {
        value: InputValue,
        validAt: DateTime.fromMillis(dateObject.validAt).toFormat(
          'dd/MM/yyyy HH:mm:ss'
        )
      }
    ];

    vm.loading = true;
    try {
      const { data } = await TimeSeriesProcessingValuesModel.create(
        {
          timeSeriesId: timeSeries._id,
          timeliness: 100
        },
        apiObject
      );
      if (data.valuesDiscarded > 0) {
        const error = gettextCatalog.getString(
          '{{timeSeriesId}} Some values couldn\'t be saved. Values Inserted - {{valuesInserted}}, Values Discarded {{valuesDiscarded}}',
          { timeSeriesId: timeSeries._id, ...data }
        );
        throw error;
      }

      if (timeSeries.dataType == 1) {
        ManualInputFormHelper.switchChanged(timeSeries, index);
      }
      vm.loading = false;
      CrudToastFactory.toast('update');
      // HIDE exclamation mark
      timeSeries.modelValues[index].defaultValue = false;
      // if success change value in timeSeries object
      var foundValueObject = timeSeries.values.find(valueObject => {
        return valueObject.validAt === dateObject.validAt;
      });
      if (foundValueObject) {
        foundValueObject.value = InputValue;
      } else {
        timeSeries.values.push({
          validAt: dateObject.validAt,
          value: InputValue
        });
        timeSeries.valuesArray = timeSeries.values;
      }
    } catch (err) {
      vm.loading = false;
      AlertingService.Error(err);
    }
  }
}

export default ManualInputFormController;
