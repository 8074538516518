const TIME_SERIES_READ = 'TIME_SERIES_READ';
const TIME_SERIES_UPDATE = 'TIME_SERIES_UPDATE';
const TIME_SERIES_CREATE = 'TIME_SERIES_CREATE';
const TIME_SERIES_UPDATE_PROPS = 'TIME_SERIES_UPDATE_PROPS';

export default {
  TIME_SERIES_READ,
  TIME_SERIES_UPDATE,
  TIME_SERIES_CREATE,
  TIME_SERIES_UPDATE_PROPS
};
