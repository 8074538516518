module.exports = {
  entity: 'time-series-group-types',
  path: 'time-series-group-types',
  entityId: 238,
  selectedParamName: '_id',
  name: {
    en: 'Time series group types',
    sl_SI: 'Tipi skupine časovne serije'
  },
  networkModel: {
    entity: 'time-series-group-types',
    read: 'read'
  },
  scope: 'tis_be:time-series-group-types',

  dialog: {
    title: {
      en: 'Select time series group type',
      sl_SI: 'Izberite tip skupine časovne serije'
    },
    listInfo: {
      en: 'Select time series type group from the list below',
      sl_SI: 'Izberite tip skupine časovne serije s spodnjega seznama'
    }
  }
}
