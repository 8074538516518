visualizationConfiguration.$inject = ['gettext', '$q'];

function visualizationConfiguration(gettext, $q) {
  var configuration = {
    entity: {
      singular: gettext('Visualization'),
      plural: gettext('Visualizations')
    },
    api: {
      query: {
        entity: 'visualizations',
        method: 'custom.list'
      },
      enrich: Enrich,
      cardFilterObject: {
        select: '_id,name'
      }
    },
    title: {
      param: 'name',
      link: {
        state: 'data-visualization-visualizations-viewer',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    cardView: true,
    buttons: [
      {
        title: gettext('new component'),
        link: {
          state: 'data-visualization-component-editor'
        },
        color: 'accent'
      },
      {
        title: gettext('new visualization'),
        link: {
          state: 'data-visualization-editor'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'data-visualization-visualizations-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'content_copy',
        link: {
          state: 'data-visualization-visualizations-duplicate',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'visualizations',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Location'),
        param: 'locationName',
        link: {
          state: 'company-resources-cost-centres-view',
          params: [
            {
              valueParam: 'locationId',
              queryParam: 'id'
            }
          ]
        },
        type: 'link'
      }
    ],
    badges: [],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      },
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'name DESC',
        params: '-name'
      }
    ]
  };

  /*
   *
   *   ENRICH
   *
   */

  function Enrich(item) {
    var deferred = $q.defer();
    if (item.location) {
      item.locationName = item.location.name;
      item.locationId = item.location._id;
    }
    return deferred.promise;
  }

  return configuration;
}
export default visualizationConfiguration;
