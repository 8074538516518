selectIconController.$inject = [
  '$mdDialog',
  'currentlySelectedIcon',
  'TranslationService',
  'gettext',
  'iconListConstant'
];
export default function selectIconController(
  $mdDialog,
  currentlySelectedIcon,
  TranslationService,
  gettext,
  iconListConstant
) {
  var vm = this;
  var search = '';
  var selectedIcon = {};
  var selectedIndex;
  var iconArray;
  var iconMetaData = TranslationService.GetCollection('codelists.iconTypes');
  var petrolId = iconMetaData[0]['id'] - 1;
  var materialId = iconMetaData[1]['id'] - 1;
  var fontAwesomeId = iconMetaData[2]['id'] - 1;
  var weatherId = iconMetaData[3]['id'] - 1;

  vm.actions = [
    {
      title: gettext('Select icon'),
      fn: () => passIcon(vm.selectedIcon),
      color: 'primary',
      raised: true,
      disabledFn: () =>
        vm.selectedIcon == null ||
        (vm.selectedIcon != null && vm.selectedIcon.name == null)
    }
  ];
  vm.header = {
    title: gettext('Icons'),
    isDialog: true,
    actions: [
      {
        icon: {
          name: 'close',
          type: 2
        },
        fn: cancel
      }
    ]
  };
  selectedIcon = currentlySelectedIcon;
  vm.selectedIndex = selectedIndex;
  vm.search = search;
  vm.selectedIcon = selectedIcon;
  vm.prepareIconSelectionArray = prepareIconSelectionArray;
  vm.petrolIconsArray = vm.prepareIconSelectionArray(
    iconListConstant[petrolId]
  );
  vm.materialIconsArray = vm.prepareIconSelectionArray(
    iconListConstant[fontAwesomeId]
  );
  vm.fontAwesomeArray = vm.prepareIconSelectionArray(
    iconListConstant[materialId]
  );
  vm.weatherIcons = vm.prepareIconSelectionArray(iconListConstant[weatherId]);
  vm.filterOrResetIconsArray = filterOrResetIconsArray;
  vm.iconArray = iconArray;
  vm.changeTab = changeTab;
  vm.selectIcon = selectIcon;
  vm.clearSelectedIcon = clearSelectedIcon;
  vm.iconArray = vm.prepareIconSelectionArray(iconListConstant[petrolId]); // the first icons shown are always petrol's icons
  /**
   * @description sets new selected icons properties.
   * @function
   * @param {number} type icon type codelist id
   * @param {string} name icon name
   * @param {string} color icon color
   */
  function selectIcon(type, name, color) {
    vm.selectedIcon = {
      type,
      name
    };

    if (color === undefined || color === null) {
      vm.selectedIcon.color = '#757575';
    } else {
      vm.selectedIcon.color = color;
    }
  }
  /**
   * @description reset selected icon.
   * @function
   */
  function clearSelectedIcon() {
    vm.selectedIcon = {};
  }
  /**
   * @description creates array of icons.
   * @function
   * @param {array} iconArrays array of icons
   */
  function prepareIconSelectionArray(iconArrays) {
    var tempArray = [];
    var tempSubArray = [];
    iconArrays['icons'].forEach(function(icon) {
      tempSubArray.push(icon);
      if (tempSubArray.length === 3) {
        var tempObject = {
          items: JSON.parse(JSON.stringify(tempSubArray))
        };
        tempArray.push(tempObject);
        tempSubArray = [];
      }
    });
    var tempObject = {
      items: JSON.parse(JSON.stringify(tempSubArray))
    };
    tempArray.push(tempObject);
    return tempArray;
  }

  function changeTab() {
    filterOrResetIconsArray(vm.search);
  }
  /**
   * @description sets selected icons array.
   * @function
   * @param {number} searchParameter icon type codelist id
   */
  function filterOrResetIconsArray(searchParameter) {
    switch (vm.selectedIndex) {
    case 0:
      vm.iconArray = [];
      vm.iconArray = vm.petrolIconsArray.slice();
      break;
    case 1:
      vm.iconArray = [];
      vm.iconArray = vm.materialIconsArray.slice();
      break;
    case 2:
      vm.iconArray = [];
      vm.iconArray = vm.fontAwesomeArray.slice();
      break;
    case 3:
      vm.iconArray = [];
      vm.iconArray = vm.weatherIcons.slice();
      break;
    }

    var tempArray = [];
    var tempSubArray = [];
    vm.iconArray.forEach(function(iconRow) {
      iconRow['items'].forEach(function(icon) {
        if (icon.toLowerCase().indexOf(searchParameter.toLowerCase()) !== -1) {
          tempSubArray.push(icon);
          if (tempSubArray.length === 3) {
            var tempObject = {
              items: JSON.parse(JSON.stringify(tempSubArray))
            };
            tempArray.push(tempObject);
            tempSubArray = [];
          }
        }
      });
    });
    var tempObject = {
      items: JSON.parse(JSON.stringify(tempSubArray))
    };
    tempArray.push(tempObject);
    vm.iconArray = tempArray.slice();
  }
  /**
   * @description closes dialog
   * @function
   */
  function cancel() {
    $mdDialog.cancel();
  }
  /**
   * @description resolves selected icon
   * @function
   * @param {object} selectedIcon selected icon object
   */
  function passIcon(selectedIcon) {
    $mdDialog.hide(selectedIcon);
  }
}
