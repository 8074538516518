import template from './sfe-form-2-color-picker.component.html';

/**
 * @ngdoc component
 * @name common.sfeForm2ColorPicker
 * @description Component for picking color.
 * @param {string} colorPickerTitle - title for input
 * @example
 * <sfe-form-2-color-picker
 *   color-picker-title="vm.config.title"
 * ></sfe-form-2-color-picker>
 */

export default {
  restrict: 'E',
  template,
  bindings: {
    colorPickerTitle: '<'
  },
  require: {
    model: 'ngModel'
  },
  controller: SfeForm2ColorPickerController,
  controllerAs: 'vm',
  bindToController: true
};

SfeForm2ColorPickerController.$inject = ['gettextCatalog', '$scope'];
function SfeForm2ColorPickerController(gettextCatalog, $scope) {
  const vm = this;

  $scope.$on('$destroy', function() {
    if (colorWatcher) {
      colorWatcher();
    }
    if (initialColorWatcher) {
      initialColorWatcher();
    }
  });

  // Update models value when color is changed.
  const colorWatcher = $scope.$watch('vm.color', (newValue, oldValue) => {
    if (newValue != oldValue) {
      vm.model.$setViewValue(vm.color);
    }
  });

  // Set initial color.
  const initialColorWatcher = $scope.$watch(
    () => {
      return vm.model.$modelValue;
    },
    color => {
      vm.color = color;
    }
  );

  vm.$onChanges = changes => {
    if (changes != null) {
      vm.colorPickerTitle = vm.colorPickerTitle
        ? gettextCatalog.getString(vm.colorPickerTitle)
        : gettextCatalog.getString('Color');
    }
  };
}
