DomainAttributeConfigController.$inject = [
  '$timeout',
  'gettext',
  'MetadataService',
  'gettextCatalog',
  'TranslationService',
  'domainAttribute'
];

function DomainAttributeConfigController(
  $timeout,
  gettext,
  MetadataService,
  gettextCatalog,
  TranslationService,
  domainAttribute
) {
  const vm = this;

  const attributeTypes = [
    {
      _id: 1,
      name: gettextCatalog.getString('String'),
      nameToSend: 'string'
    },
    {
      _id: 2,
      name: gettextCatalog.getString('Integer'),
      nameToSend: 'integer'
    },
    {
      _id: 3,
      name: gettextCatalog.getString('Double'),
      nameToSend: 'double'
    },
    {
      _id: 7,
      name: gettextCatalog.getString('Text area'),
      nameToSend: 'textarea'
    },
    {
      _id: 4,
      name: gettextCatalog.getString('Select menu'),
      nameToSend: 'select'
    },
    {
      _id: 5,
      name: gettextCatalog.getString('Check box'),
      nameToSend: 'checkbox'
    }
  ];

  init();

  function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    readDomainAttribute();
  }

  function readDomainAttribute() {
    domainAttribute.readableDisplayNames = '';
    if (domainAttribute.fields) {
      domainAttribute.valueField = domainAttribute.fields.find(function(item) {
        return item._id === domainAttribute.valueField;
      });

      var found;
      domainAttribute.displayFields.forEach(function(field, index) {
        found = domainAttribute.fields.find(function(item) {
          return item._id === field;
        });
        if (found) {
          domainAttribute.readableDisplayNames += found.name;
        }

        if (index !== domainAttribute.displayFields.length - 1) {
          domainAttribute.readableDisplayNames += ' - ';
        }
      });
    }
    vm.headerData = constructHeader();
    vm.loading = false;
    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(
        domainAttribute.name,
        domainAttribute.description
      );
    });
  }

  function constructHeader() {
    var properties = [];
    var format = TranslationService.GetCollectionById(
      'codelists.dataTypes',
      domainAttribute.dataType
    );
    var elementType = attributeTypes.find(
      item => item.nameToSend === domainAttribute.elementType
    );
    properties.push(
      {
        title: gettext('Code'),
        value: domainAttribute.code,
        type: 'code'
      },
      {
        title: gettext('Element type'),
        value: elementType
          ? elementType.name
          : gettextCatalog.getString('Unknown'),
        type: 'simple'
      },
      {
        title: gettext('Format'),
        value: format ? format.name : gettextCatalog.getString('Unknown'),
        type: 'simple'
      }
    );
    if (domainAttribute.domain) {
      properties.push({
        title: gettext('Domain'),
        linkTitle: domainAttribute.domain.name
          ? domainAttribute.domain.name
          : 'Unknown',
        type: 'link',
        state: 'configurations-dynamic-attributes-domains-view',
        param: 'id',
        paramValue: domainAttribute.domain._id
      });
    }

    if (domainAttribute.valueField) {
      properties.push({
        title: gettext('Value field name'),
        value: domainAttribute.valueField.name,
        type: 'simple'
      });
    }

    if (domainAttribute.readableDisplayNames) {
      properties.push({
        title: gettext('Display field name'),
        value: domainAttribute.readableDisplayNames,
        type: 'simple'
      });
    }
    var propertySections = [
      {
        properties: properties,
        title: gettext('properties')
      }
    ];
    var actions = [
      {
        title: gettext('Update'),
        state: 'configurations-dynamic-attributes-dynamic-attributes-list-edit',
        param: 'id',
        paramValue: domainAttribute._id
      }
    ];
    return {
      metadata: {
        definition: gettext('Domain attribute'),
        title: domainAttribute.name,
        description: domainAttribute.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}

export default DomainAttributeConfigController;
