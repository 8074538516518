module.exports = {
  entity: 'measurement-manual-input-forms',
  path: 'measurement-manual-input-forms',
  name: {
    en: 'Manual input groups',
    sl_SI: 'Manual input groups'
  },
  networkModel: {
    entity: 'measurement-manual-input-form-groups',
    read: 'read'
  },
  scope: 'tis_be:measurement-groups',

  selectedParamName: 'id',
  dialog: {
    title: {
      en: 'Select manual input group',
      sl_SI: 'Select manual input group'
    },
    listInfo: {
      en: 'Select manual input group from the list below',
      sl_SI: 'Select manual input group from the list below'
    }
  }
}
