module.exports = {
  entity: 'timeZones',
  path: 'timeZones',
  name: {
    en: 'Time zones',
    sl_SI: 'Časovni pas'
  },
  codelist: 'timeZones',
  scope: 'tis_be:timeZones',

  dialog: {
    title: {
      en: 'Select time zone',
      sl_SI: 'Izberite časovni pas'
    },
    listInfo: {
      en: 'Select time zone from the list below',
      sl_SI: 'Izberite časovni pas s spodnjega seznama'
    },
    displayFields: ['code', 'name'],
    codelist: 'timeZones'
  }
}
