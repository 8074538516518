function AssetsConfigurationController() {
  var vm = this;
  vm.lists = [
    'asset-brands',
    'asset-models',
    'asset-types',
    'asset-statuses',
    'asset-groups',
    'rated-powers'
  ];
}

export default AssetsConfigurationController;
