AddDateController.$inject = [
  'title',
  'customButtonText',
  'initialValues',
  'dateOnly',
  'required',
  'gettext',
  '$mdDialog',
  'Formatting'
];

export default function AddDateController(
  title,
  customButtonText,
  initialValues,
  dateOnly,
  required,
  gettext,
  $mdDialog,
  Formatting
) {
  var vm = this;
  vm.closeDialog = closeDialog;
  vm.addSelectedDateTime = addSelectedDateTime;
  vm.header = {
    title: title,
    isDialog: true,
    actions: [
      {
        icon: {
          name: 'close',
          type: 2
        },
        cancel: true
      }
    ]
  };

  (function init() {
    var labelFrom = gettext('Valid from *');
    var labelTo = required.validTo
      ? gettext('Valid to *')
      : gettext('Valid to');
    vm.title = title;
    vm.customButtonText = customButtonText;
    vm.dataConfig = {
      data: [
        {
          componentType: 'paragraph',
          paragraph: initialValues.name
        },
        {
          componentType: 'datePickerFromTo',
          from: 'validFrom',
          to: 'validTo',
          valToEmpty: true,
          labelFrom: labelFrom,
          labelTo: labelTo,
          required: true,
          onlyStartRequired: !required.validTo,
          idFrom: 'dialogFrom',
          idTo: 'dialogTo'
        }
      ],
      dataObj: {},
      action: {
        ctrlFn: true,
        title: gettext('Add'),
        fn: addSelectedDateTime,
        color: 'primary',
        raised: true
      }
    };

    if (!dateOnly) {
      vm.dataConfig.data.push({
        componentType: 'twoInRow',
        subData: [
          {
            placeholder: gettext('Time from'),
            name: 'timeFrom',
            pattern: new RegExp('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$'),
            patternExample: '8:00',
            required: Boolean(required.timeFrom)
          },
          {
            placeholder: gettext('Time to'),
            name: 'timeTo',
            pattern: new RegExp('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$'),
            patternExample: '16:00',
            required: Boolean(required.timeTo)
          }
        ]
      });
    }

    setInitialDateTime();
  })();

  function setInitialDateTime() {
    let dateFrom, timeFrom, dateTo, timeTo;

    // from
    dateFrom = initialValues.validFrom
      ? new Date(initialValues.validFrom)
      : new Date();
    timeFrom =
      initialValues.timeFrom || Formatting.getHoursAndMinutes(dateFrom);
    vm.dataConfig.dataObj.validFrom = dateFrom;
    vm.dataConfig.dataObj.timeFrom = timeFrom;

    // to

    dateTo = initialValues.validTo ? new Date(initialValues.validTo) : null;
    timeTo =
      initialValues.timeTo ||
      (initialValues.validTo ? Formatting.getHoursAndMinutes(dateTo) : '');
    vm.dataConfig.dataObj.validTo = dateTo;
    vm.dataConfig.dataObj.timeTo = timeTo;
  }

  // action for adding the selected dates to the form
  function addSelectedDateTime() {
    var dataObj = vm.dataConfig.dataObj;
    let selectedDateTime = {};
    if (dateOnly) {
      selectedDateTime.validFrom = Formatting.formatDate(
        dataObj.validFrom,
        '00:00:00'
      );
      selectedDateTime.validTo = Formatting.formatDate(
        dataObj.validTo,
        '23:59:59'
      );
    } else {
      const timeFrom = dataObj.timeFrom
        ? dataObj.timeFrom.split(':').length == 2
          ? dataObj.timeFrom + ':00'
          : dataObj.timeFrom
        : '00:00:00';
      const timeTo = dataObj.timeTo
        ? dataObj.timeTo.split(':').length == 2
          ? dataObj.timeTo + ':59'
          : dataObj.timeTo
        : '23:59:59';
      selectedDateTime.validFrom = Formatting.formatDate(
        dataObj.validFrom,
        timeFrom
      );
      selectedDateTime.validTo = Formatting.formatDate(dataObj.validTo, timeTo);
    }
    if (selectedDateTime.validTo) {
      selectedDateTime.validTo = selectedDateTime.validTo.setMilliseconds(0);
    }
    if (selectedDateTime.validFrom) {
      selectedDateTime.validFrom = selectedDateTime.validFrom.setMilliseconds(
        0
      );
    }
    $mdDialog.hide(selectedDateTime);
  }

  function closeDialog() {
    $mdDialog.hide(null);
  }
}
