UserController.$inject = [
  '$stateParams',
  'MetadataService',
  'user',
  '$timeout'
];

function UserController($stateParams, MetadataService, user, $timeout) {
  const vm = this;
  vm.userId = $stateParams.id;
  let metadataTitle = user.name;
  if (user.family_name) {
    metadataTitle += ' ' + user.family_name;
  }
  vm.user = user;
  $timeout(() => {
    MetadataService.ChangeMetadata(metadataTitle, user.description);
  });
  MetadataService.Loading(false);
}
export default UserController;
