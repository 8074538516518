import template from './tango-properties.component.html';
import './tango-properties.scss';
/**
 * @ngdoc component
 * @name common.tangoProperties
 * @description watches for store changes and updates group of sfe-property-list configurations.
 * @param {number} entity codelist entity id
 * @param {string} entityId item mongo id
 * @param {object} configuration lists configuration
 *                     type <String> dynamic|static|stored
 *                         when dynamic type is chosen configuration must have valueFn function that as argument accepts store and returns array of sfe-property-list configurations
 *                         when static type is chosen configuration must contain array of sfe-property-list configurations under the value key
 *                         when stored type is chosen store of the selected entity should contain array od sfe-property-list configurations under the key that is defined in configuration under value.
 * @example
 * <tango-property
 * entity="vm.entity"
 * entity-id="vm.entityId"
 * configuration="vm.configuration"
 * ></tango-property>
 */
export default {
  template,
  bindings: {
    configuration: '<',
    entity: '<',
    entityId: '<'
  },
  controller: TangoPropertiesController,
  controllerAs: 'vm'
};

TangoPropertiesController.$inject = [
  '$scope',
  '$ngRedux',
  'ReduxStoreService',
  'EntitiesService'
];

function TangoPropertiesController(
  $scope,
  $ngRedux,
  ReduxStoreService,
  EntitiesService
) {
  const vm = this;
  let unsubscribe;
  let currentItem;
  vm.$onChanges = () => {
    //on any change check if all bindings are set
    if (vm.configuration && vm.entityId && vm.entity && unsubscribe == null) {
      //assign state properties and measurement actions to controller scope
      // and listen for changes
      unsubscribe = $ngRedux.connect(mapStateToProps)(vm);
      $scope.$on('$destroy', unsubscribe);
    }
  };
  /**
   * @description assigns store values to controller scope.
   * @function
   * @param {Object} state current state of the store
   * @return {Object}
   */
  function mapStateToProps(state) {
    //propertyConfigurations <Array> - array of sfe-property-list configurations
    let storeName = EntitiesService.getStoreName(vm.entity);
    let item = null;
    if (
      state != null &&
      state[storeName] != null &&
      state[storeName][vm.entityId] != null &&
      state[storeName][vm.entityId].data != null
    ) {
      item = state[storeName][vm.entityId].data;
    }
    if (!_.isEqual(currentItem, item)) {
      currentItem = angular.copy(item);
      const propertyConfigurations = ReduxStoreService.extract(
        state,
        vm.entity,
        vm.entityId,
        vm.configuration
      );
      $scope.$applyAsync();
      return {
        propertyConfigurations
      };
    }
    return {};
  }
}
