import template from './sfe-variable-viewer.component.html';
import './sfe-variable-viewer.component.scss';

export default {
  template,
  bindings: {
    configuration: '<',
    expression: '<',
    expressionVariables: '<'
  },
  controllerAs: 'vm'
};
/**
* @ngdoc component
* @name common.sfe-variable-viewer
* @description component for displaying variables and expression.
* @param {string} expression - 'x + y'
* @param {Array} expressionVariables - ['x', 'y']
* @param {Array} configuration - array of objects
* <sfe-variable-viewer
*   configuration="vm.configuration"
*   expression="vm.expression"
*   expression-variables="vm.expressionVariables"
* ></sfe-variable-viewer>

// Configuration
 vm.configuration = [
    {
      title: 'Time series',
      groupItems: [
        title:
          [
            {
              text: 'Test izhodne točke - TIS-6509 NOVO IME',
              link: 'company-resources-measurements-view({ id: "5e5e0c028abad55310ae3b8a" })',
              lightGrey: false
            }
          ],
          labels: [
            {
            color: 'primary',
            text: 'Triggers error'
            }
          ],
          items: [
            {
              type: 'variable,
              variableName: 'X',
              values: [
                {
                  text: 'Last Value',
                  lightGrey: false
                },
                {
                  text: 'Intelligence hecto Jure',
                  lightGrey: true
                }
              ]
            },
            {
              type: 'title',
              values: [
                {
                  text: 'Test izhodne točke - TIS-6509 NOVO IME',
                  link: 'company-resources-measurements-view({ id: "5e5e0c028abad55310ae3b8a" })',
                  lightGrey: false
                }
              ]
            }
          ]
      ]
    },
    {
      title: 'Attributes',
      itemsGroup: [
          items: [
          type: 'variable',
          variableName: 'B',
          values: [
            {
              text: 'Dobava',
              link: 'configurations-dynamic-attributes-domains-view({id: "5de9003b2586177086402164"})'
            },
            {
              text: 'AssetName',
              link: 'company-resources-assets-view({id: "5e8ae01880a6510d714172f9"})',
              icon: {
                color: '#75448d',
                type: 2,
                name: 'router'
              }
            }
          ]
        ]
      ]
    }
  ];
*/
