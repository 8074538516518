fileConfiguration.$inject = ['gettext', '$q', 'TranslationService'];

function fileConfiguration(gettext, $q, TranslationService) {
  var configuration = {
    listId: 'file',
    entity: {
      singular: gettext('File'),
      plural: gettext('Files')
    },
    api: {
      query: {
        entity: 'files',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'external-datasources-connectors-file-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'external-datasources-connectors-file-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'external-datasources-connectors-file-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            method: 'delete',
            entity: 'files'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'typeName',
        title: gettext('Type')
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Type'),
        collection: TranslationService.GetCollection('codelists.fileTypes'),
        valueField: 'id',
        param: 'type'
      },
      {
        display: gettext('Tango Agent Connection Configuration'),
        query: {
          entity: 'tango-agent-connection-configs',
          method: 'read'
        },
        entity: 'tango-agent-connection-configs',
        param: 'tangoAgentConnectionConfigs._id',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id',
        populate: 'tangoAgentConnectionConfigs'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      },
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'name DESC',
        params: '-name'
      }
    ]
  };

  /*
   *
   *   ENRICH
   *
   */

  function Enrich(item) {
    var deferred = $q.defer();
    var type = TranslationService.GetCollection('codelists.fileTypes').find(
      function(type) {
        return type.id === item.type;
      }
    ).name;
    item.typeName = type;
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default fileConfiguration;
