import routes from './monitoring.routes';

// controllers
import TriggeredAlarmController from './pages/triggered-alarm/triggered-alarm.controller';
import TriggeredAlarmsListController from './pages/triggered-alarms-list/triggered-alarms-list.controller';

// services
import AlarmMonitoringHelper from './services/AlarmMonitoringHelper.service';

// configurations
import AlarmMonitoringConfiguration from './configurations/alarm-monitoring.configuration';
import SmallAlarmCardConfiguration from './configurations/small-alarm-card-list.configuration';

// network models
import TriggeredAlarmModel from './network-models/TriggeredAlarm.model';

export default {
  routes,
  controllers: {
    TriggeredAlarmController,
    TriggeredAlarmsListController
  },
  services: {
    AlarmMonitoringHelper,
    TriggeredAlarmModel
  },
  factories: {
    AlarmMonitoringConfiguration,
    SmallAlarmCardConfiguration
  }
};
