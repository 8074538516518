import template from './sfe-chips.directive.html';

sfeChips.$inject = ['$timeout'];

export default function sfeChips($timeout) {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      readonly: '<',
      removable: '<',
      placeholder: '<',
      required: '<',
      parentForm: '<',
      patternExample: '<',
      pattern: '<',
      items: '='
    },
    controller: sfeChipsController,
    controllerAs: 'vm',
    bindToController: true,
    link: function(scope, el) {
      function setInputContainerWidthToPlaceholderWidth() {
        var input = el[0].querySelector('input');
        var placeholder = input.getAttribute('placeholder');
        angular.element(input).attr('size', placeholder.length);
        /*************
        MEMORY CLEANUP
        *************/
        input = null;
        placeholder = null;
      }
      $timeout(setInputContainerWidthToPlaceholderWidth);
    }
  };
  return directive;
}

sfeChipsController.$inject = ['$scope'];

function sfeChipsController($scope) {
  var vm = this;
  vm.randomHash = Math.random()
    .toString(36)
    .substring(2);
  vm.validatePattern = validatePattern;

  $scope.$on('$destroy', function() {
    if (formWatcher) {
      formWatcher();
    }
  });

  var formWatcher = $scope.$watch('vm.parentForm', function() {
    if (vm.parentForm) {
      $scope.ChipForm = vm.parentForm;
      formWatcher();
    }
  });

  function validatePattern() {
    if (vm.pattern) {
      var oneFalse = false;
      _.each(vm.items, function(item) {
        if (!vm.pattern.test(item)) {
          oneFalse = true;
        }
      });
      if (oneFalse) {
        if ($scope.ChipForm['item_' + vm.randomHash]) {
          $scope.ChipForm['item_' + vm.randomHash].$setValidity(
            'pattern',
            false
          );
        }
      } else {
        if ($scope.ChipForm['item_' + vm.randomHash]) {
          $scope.ChipForm['item_' + vm.randomHash].$setValidity(
            'pattern',
            true
          );
        }
      }
    }
  }
}
