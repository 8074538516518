import { RouteManager, gettext } from '../routes';

import ErrorStateView from './pages/error-state/error-state.view.html';
import RedirectView from './pages/redirect/redirect.view.html';
import HomeView from './pages/home/home.client.view.html';

let CoreRouteManager = new RouteManager();
CoreRouteManager.state('redirect', {
  url: '/redirect',
  template: RedirectView,
  controller: 'RedirectController',
  controllerAs: 'vm',
  pageTitle: gettext('Redirect'),
  theme: 'tango',
  icon: 'home',
  isFullscreen: true,
  allUsers: true,
  doNotSaveState: true
})
  .state('home', {
    url: '/',
    template: HomeView,
    controller: 'HomeController',
    controllerAs: 'vm',
    pageTitle: gettext('Main dashboard'),
    theme: 'tango',
    icon: 'home',
    allUsers: true,
    doNotSaveState: true
  })
  .state('not-found', {
    url: '/not-found',
    template: ErrorStateView,
    controller: 'ErrorStateController',
    controllerAs: 'vm',
    data: {
      ignoreState: true,
      pageTitle: gettext('Not-Found')
    },
    allUsers: true,
    doNotSaveState: true
  })
  .state('bad-request', {
    url: '/bad-request',
    template: ErrorStateView,
    controller: 'ErrorStateController',
    controllerAs: 'vm',
    data: {
      ignoreState: true,
      pageTitle: gettext('Bad-Request')
    },
    allUsers: true,
    doNotSaveState: true
  })
  .state('forbidden', {
    url: '/forbidden',
    template: ErrorStateView,
    controller: 'ErrorStateController',
    controllerAs: 'vm',
    data: {
      ignoreState: true,
      pageTitle: gettext('Forbidden')
    },
    theme: 'tango',
    allUsers: true,
    doNotSaveState: true
  });

export default CoreRouteManager.routes;
