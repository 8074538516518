TimeSeriesGroupSandboxController.$inject = ['$state', 'timeSeriesGroup'];

export default function TimeSeriesGroupSandboxController(
  $state,
  timeSeriesGroup
) {
  const vm = this;
  vm.query = $state.params.query;
  vm.timeSeriesGroup = timeSeriesGroup;
}
