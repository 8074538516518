NewTaskController.$inject = [
  '$stateParams',
  '$state',
  'gettext',
  'MetadataService',
  'LocalizationService',
  'SfeHeader',
  'task',
  '$timeout'
];

function NewTaskController(
  $stateParams,
  $state,
  gettext,
  MetadataService,
  LocalizationService,
  SfeHeader,
  task,
  $timeout
) {
  var vm = this;
  var taskId = $stateParams.id;
  vm.duplicateMode =
    $state.current.name === 'alarms-and-rules-sop-tasks-duplicate';
  var data = [
    {
      placeholder: gettext('Name'),
      name: 'name',
      type: 'text',
      componentType: 'multilanguage',
      required: true
    },
    {
      placeholder: gettext('Task description'),
      name: 'description',
      maxlength: 500,
      type: 'textArea',
      required: false,
      componentType: 'multilanguage'
    }
  ];

  init();

  function init() {
    vm.editMode = !!taskId;
    vm.dataConfig = {};

    if (vm.editMode) {
      MetadataService.Loading(true);
      if (vm.duplicateMode) {
        vm.dataConfig.header = SfeHeader.constructFormHeader(
          'primary',
          gettext('Duplicate task'),
          'alarms-and-rules-sop-tasks-view',
          { id: taskId }
        );
      } else {
        vm.dataConfig.header = SfeHeader.constructFormHeader(
          'primary',
          gettext('Edit task'),
          'alarms-and-rules-sop-tasks-view',
          { id: taskId }
        );
      }
      var selectedLang = LocalizationService.GetSelectedLangNetworkCode();
      vm.dataConfig.dataObj = {
        name: task.name,
        description: task.description
      };
      $timeout(() => {
        MetadataService.Loading(false);
        if (vm.duplicateMode) {
          MetadataService.ChangeMetadata(
            'Duplicate ' + task.name[selectedLang],
            task.description[selectedLang]
          );
        } else {
          MetadataService.ChangeMetadata(
            'Edit ' + task.name[selectedLang],
            task.description[selectedLang]
          );
        }
      });
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New task'),
        'alarms-and-rules-sop-tasks-list'
      );
      vm.dataConfig.dataObj = {};
    }

    vm.dataConfig.edit = vm.editMode;
    vm.dataConfig.data = data;
    vm.dataConfig.action = {
      resource: true,
      configuration: {
        entity: 'tasks',
        method: vm.editMode && !vm.duplicateMode ? 'update' : 'create'
      },
      title:
        vm.editMode && !vm.duplicateMode
          ? gettext('Update')
          : gettext('Create'),
      method: vm.editMode && !vm.duplicateMode ? 'PUT' : 'POST',
      paramName: 'id',
      successMsg: vm.editMode && !vm.duplicateMode ? 'update' : 'create',
      errMsg: gettext('Error at creating new task')
    };
    vm.dataConfig.redirect = {
      param: 'id',
      paramValue: taskId,
      location: 'alarms-and-rules-sop-tasks-view'
    };
  }
}

export default NewTaskController;
