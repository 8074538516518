import './weather-dashboard-item.scss';
import template from './weather-dashboard-item.directive.html';

/**
 * @ngdoc component
 * @name common.weatherDashboardItem
 * @description component to display weather card.
 * @param {String} weatherStation weather station id
 * @param {number} size dashboard card size
 * @param {String} cacheInvalidationTime cache invalidation time
 * @param {Bool} refresher indicates when to refresh data
 * @example
 * <weather-dashboard-item
 * weather-station="vm.weatherStation"
 * size="vm.size"
 * cache-invalidation-time="vm.cacheInvalidationTime"
 * refresher="vm.refresher"
 * ></weather-dashboard-item>
 */
export default [
  function() {
    return {
      restrict: 'E',
      template,
      scope: {
        weatherStation: '<',
        size: '<?',
        refresher: '<?',
        cacheInvalidationTime: '<?',
        uniqueCacheId: '<?'
      },
      link: linkFn,
      controller: WeatherDashboardController,
      controllerAs: 'vm',
      bindToController: true
    };

    function linkFn(scope, el, attrs, ctrl) {
      ctrl.indentAverageElements = indentAverageElements;
      /**
       * @description sets widt to a dom element.
       * @function
       * @param {string} className name of the class
       * @param {number} margin number of pixels for margin
       */
      function indentAverageElements(className, margin) {
        let averageItems = el[0].querySelectorAll(className);
        let maxWidth = 0;
        averageItems.forEach(
          item => (maxWidth = Math.max(maxWidth, item.offsetWidth))
        );
        averageItems.forEach(item =>
          item.setAttribute('style', 'width:' + (maxWidth + margin) + 'px')
        );
        /*************
      MEMORY CLEANUP
      *************/
        averageItems = null;
      }
    }
  }
];

WeatherDashboardController.$inject = [
  'AlertingService',
  '$timeout',
  '$scope',
  'Refreshing',
  'loadAssets',
  'chartTranslationService',
  'gettextCatalog',
  'WeatherDashboardItemService'
];

function WeatherDashboardController(
  AlertingService,
  $timeout,
  $scope,
  Refreshing,
  loadAssets,
  chartTranslationService,
  gettextCatalog,
  WeatherDashboardItemService
) {
  const vm = this;
  const arrayCompass = [
    'n',
    'nne',
    'ne',
    'ene',
    'e',
    'ese',
    'se',
    'sse',
    's',
    'ssw',
    'sw',
    'wsw',
    'w',
    'wnw',
    'nw',
    'nnw'
  ];
  let weatherTypeValuesMap = {};
  let time;
  let weatherStationCode;
  let refresherId, refresherFnId;
  let weatherSources;

  vm.chartApi = {};

  $scope.$on('$destroy', function() {
    if (refresherId) {
      Refreshing.removeFn(refresherId, refresherFnId);
    }
  });

  /**
   * @description when station id changes triggers init function.
   * @function
   * @param {Array} changes array of bindings changes
   */
  vm.$onChanges = async changes => {
    if (changes && changes.weatherStation) {
      if (changes.weatherStation.isFirstChange()) {
        try {
          await loadAssets(['highcharts']);
          if (vm.refresher) {
            ({ refresherId, refresherFnId } = vm.refresher(async () => {
              if (vm.size > 1) {
                vm.chartApi.refresh();
              }
              vm.initialDataLoading = true;
              const {
                results,
                keysArray
              } = await WeatherDashboardItemService.getValues(
                weatherSources,
                vm.size,
                vm.cacheInvalidationTime
              );
              if (results) {
                setForecastValues(results, keysArray);
              }
              vm.initialDataLoading = false;
            }));
          }
          init();
        } catch (err) {
          gettextCatalog.getString('Highcharts initialization failed.');
        }
      } else {
        init();
        if (vm.refresher) {
          ({ refresherId, refresherFnId } = vm.refresher(init));
        }
      }
    }
  };
  /**
   * @description Triggers fetch weather station data function and then fetches measurements and sets view data.
   * @function
   */
  async function init() {
    vm.initialDataLoading = true;
    time = new Date();

    try {
      const {
        valuesConfigurations,
        fetchedCode
      } = await WeatherDashboardItemService.getWeatherStationView(
        vm.weatherStation,
        vm.cacheInvalidationTime
      );

      weatherStationCode = fetchedCode;
      setDefaultDataForLoading();
      weatherSources = valuesConfigurations;
      const {
        results,
        keysArray
      } = await WeatherDashboardItemService.getValues(
        weatherSources,
        vm.size,
        vm.cacheInvalidationTime,
        vm.uniqueCacheId
      );
      if (results) {
        setForecastValues(results, keysArray);
      }
      vm.initialDataLoading = false;
      if (vm.size > 1) {
        vm.forecastGetter = WeatherDashboardItemService.findTemperatureForecast(
          valuesConfigurations,
          vm.cacheInvalidationTime,
          vm.uniqueCacheId
        );
        const height = vm.size == 3 ? 275 : 175;

        vm.chartConfig = WeatherDashboardItemService.constructGraph(
          height,
          vm.forecastGetter
        );
        vm.chartTextTranslations = chartTranslationService();
      }
    } catch (err) {
      AlertingService.Error(err);
      vm.initialDataLoading = false;
    }
  }

  /**
   * @description Sets current weather card placeholder values.
   * @function
   */
  function setDefaultDataForLoading() {
    vm.temperatureNow = '--';
    vm.windSpeedNow = '--';
    vm.averageTemperatures = WeatherDashboardItemService.getAverageTemperaturesPlaceholders();
    $timeout(() => vm.indentAverageElements('.weather-average .day-part', 40));
    vm.forecasts = WeatherDashboardItemService.initForecastPlaceholders(time);
    $timeout(() => vm.indentAverageElements('.weather-forecast .day-part', 16));
  }

  /**
   * @description sets values to display current weather state
   * short time weather forecast, long time weather forecast and chart configuration (large card).
   * @function
   * @param {Array} results array fetched values for every type of measurement that location has
   * @param {Array} keysArray array of measurement types names (same order as results)
   */
  function setForecastValues(results, keysArray) {
    //create keys values map
    weatherTypeValuesMap = keysArray.reduce((accumulator, key, index) => {
      accumulator[key] = results[index].data;
      return accumulator;
    }, weatherTypeValuesMap);

    //SET CURRENT WEATHER STATE
    setValuesForCurrentWeatherState('current_temperature', 'temperatureNow');
    setValuesForCurrentWeatherState('current_description', 'descriptionNow');
    setValuesForCurrentWeatherState('current_icon', 'codeNow');
    setValuesForCurrentWeatherState('wind_speed', 'windSpeedNow');
    setValuesForCurrentWeatherState('wind_direction', 'windDirectionDegrees');

    vm.windSpeedNow = vm.windSpeedNow || '--';
    vm.windDirectionNow =
      vm.windDirectionDegrees != null
        ? convertDegreesToCompass(vm.windDirectionDegrees)
        : undefined;
    //SET SHORT TIME FORECAST VALUES
    vm.averageTemperatures = WeatherDashboardItemService.calculateCurrentAverage(
      time,
      weatherTypeValuesMap,
      weatherStationCode
    );
    //SET LONG TIME FORECAST VALUES
    vm.forecasts = WeatherDashboardItemService.setForecastWeatherConfiguration(
      time,
      weatherTypeValuesMap,
      weatherStationCode
    );

    $timeout(() => {
      vm.indentAverageElements('.weather-average .day-part', 40);
      vm.indentAverageElements('.weather-forecast .day-part', 16);
    });
  }
  /**
   * @description data interval update.
   * @function
   * @param {string} configurationName name of configuration to be updated
   * @param {string} globalConfigurationName global name of configuration (name on the scope) to be updated
   */
  function setValuesForCurrentWeatherState(
    configurationName,
    globalConfigurationName
  ) {
    const weatherConfig = WeatherDashboardItemService.getCurrentWeatherState(
      configurationName,
      globalConfigurationName,
      weatherTypeValuesMap,
      weatherStationCode
    );

    vm[globalConfigurationName] = weatherConfig[globalConfigurationName];
    if (weatherConfig.timeNow) vm.timeNow = weatherConfig.timeNow;
    if (weatherConfig.dateNow) vm.dateNow = weatherConfig.dateNow;
  }

  /**
   * @description Converts wind direction to icon code.
   * @function
   * @param {Number} degree wind direction
   * @return {String}
   */
  function convertDegreesToCompass(degree) {
    const index = Math.floor(degree / 25.5 + 0.5) % 16;
    return arrayCompass[index];
  }
}
