ViewModel.$inject = ['ModelUtilities'];

function ViewModel(ModelUtilities) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'view'
  };

  var networkConfigurationBillingOverview = {
    host: 'view',
    path: 'billingoverview'
  };

  var networkConfigurationInvoicelist = {
    host: 'view',
    path: 'invoicelist'
  };

  function getMethod(configuration, method = 'GET') {
    let cacheConfiguration = {};
    if (method === 'POST') {
      cacheConfiguration = {
        keepPostCache: true
      };
    }
    const config = {
      ...configuration,
      ...networkConfiguration,
      ...cacheConfiguration
    };
    return ModelUtilities.crud(method, config, modelStorage);
  }

  var methods = {
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    custom: {
      getMethod: getMethod,
      readBillingOverview: ModelUtilities.crud(
        'GET',
        networkConfigurationBillingOverview,
        modelStorage
      ),
      readInvoiceList: ModelUtilities.crud(
        'GET',
        networkConfigurationInvoicelist,
        modelStorage
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default ViewModel;
