import template from './sfe-form-2-agent-config-tag.component.html';
import './sfe-form-2-agent-config-tag.scss';
/**
 * @ngdoc component
 * @name moduleName.sfeTagSelector
 * @description component that shows tags that are based on data source and allows adding of tags though a dialog
 * @param {Object} options
 *    @param {function} connectionConfig - should return connectionConfig id to filter tags
 * @param {function} disable - returns boolean
 * @param {Object} api - forms inputs
 * @example
 * <sfe-form-2-agent-config-tag
 *  options="vm.options'"
 *  disable="() => true"
 *  api="vm.api"
 * ></sfe-form-2-agent-config-tag>
 */
export default {
  template,
  bindings: {
    options: '<',
    disable: '<',
    api: '<'
  },
  require: {
    model: 'ngModel',
    form: '^form'
  },
  controller: AgentConfigTagController,
  controllerAs: 'vm'
};

AgentConfigTagController.$inject = [
  '$scope',
  'TagSelector',
  'TagModel',
  'AlertingService',
  'gettext'
];

function AgentConfigTagController(
  $scope,
  TagSelector,
  TagModel,
  AlertingService,
  gettext
) {
  const vm = this;

  $scope.$on('$destroy', stopWatcher);

  let stopWatcher = $scope.$watch(
    function() {
      if (vm.model != null) {
        return vm.model.$modelValue;
      }
    },
    model => {
      if (typeof model == 'string') {
        initializeModelValue(model);
      }
    }
  );

  vm.$onInit = () => {
    vm.actions = [
      {
        title: gettext('Select'),
        fn: openTagSelector,
        disabledFn: vm.disable
      },
      {
        icon: {
          type: 2,
          name: 'close',
          color: 'grey'
        },
        fn: () => {
          vm.model.$setViewValue();
        },
        disabledFn: vm.disable
      }
    ];
  };

  /**
   * @description if model is given as tagId fetches id value and sets it to model.
   * @function
   * @param {String} agentConfigTagId
   */
  async function initializeModelValue(agentConfigTagId) {
    try {
      const { data } = await TagModel.read({ tagId: agentConfigTagId });
      vm.model.$setViewValue(data);
    } catch (err) {
      AlertingService.Error(err);
      vm.model.$setViewValue(null);
    }
  }
  /**
   * @description opens tag selector dialog and sets model to selected item.
   * @function
   */
  const openTagSelector = () => {
    if (typeof vm.options.connectionConfig == 'function') {
      let connectionConfig = vm.options.connectionConfig(vm.api);
      if (connectionConfig != null) {
        TagSelector.open(connectionConfig).then(tag => {
          vm.model.$setViewValue(tag);
        });
      } else {
        AlertingService.Error(gettext('Connection config is missing'));
      }
    } else {
      AlertingService.Error(
        gettext('Function to get connection config is missing')
      );
    }
  };
}
