MeasuringpointCostCentresEntityDateConfiguration.$inject = [
  'gettext',
  '$q',
  'AlertingService',
  'gettextCatalog',
  'TranslationService',
  'CrudToastFactory',
  'Formatting',
  'CostCentreMeasuringpointModel'
];

function MeasuringpointCostCentresEntityDateConfiguration(
  gettext,
  $q,
  AlertingService,
  gettextCatalog,
  TranslationService,
  CrudToastFactory,
  Formatting,
  CostCentreMeasuringpointModel
) {
  var createObject = {
    validFrom: new Date(new Date().setHours(0, 0, 0, 0)),
    validTo: null
  };

  var configuration = {
    listId: 'measuringPointCostCentres',
    type: 'single',
    entity: {
      inactiveTitle: gettext('Inactive cost centres'),
      title: gettext('Main Cost Centre')
    },
    api: {
      query: {
        entity: 'cost-centre-measuringpoints',
        method: 'custom.readCostCentre'
      },
      activeFilterObject: {
        isActive: true,
        isMain: true
      },
      inactiveFilterObject: {
        isActive: false,
        isMain: true
      },
      enrich: Enrich
    },
    create: {
      form: getMeasuringpointBPFormConfig(),
      createObject: angular.copy(createObject),
      createFn: create
    },
    update: update,
    getUpdateObject: getUpdateObject,
    delete: function(item) {
      var deferred = $q.defer();
      CostCentreMeasuringpointModel.delete({
        id: item._id
      }).then(
        function() {
          CrudToastFactory.toast('delete');
          deferred.resolve();
        },
        function(err) {
          AlertingService.Error(err);
          deferred.reject();
        }
      );
      return deferred.promise;
    },
    title: {
      param: 'nameReadable',
      link: {
        state: 'company-resources-cost-centres-view',
        params: [
          {
            valueParam: 'costCentreId',
            queryParam: 'id'
          }
        ]
      }
    },
    shortActions: [
      {
        type: 'edit',
        icon: 'mode_edit'
      },
      {
        icon: 'delete',
        type: 'delete'
      }
    ],
    attributes: [
      {
        title: gettext('Valid from'),
        param: 'validFromReadable',
        type: 'date'
      },
      {
        title: gettext('Valid to'),
        param: 'validToReadable',
        type: 'date'
      },
      {
        title: gettext('Address'),
        param: 'addressReadable',
        type: 'text'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ],
    dateFromLocalStorage: true
  };

  function Enrich(item) {
    var deferred = $q.defer();
    item.fetchingObject['addressReadable'] = {
      fetching: true,
      success: false,
      nodata: false
    };
    item.fetchingObject['validFromReadable'] = {
      fetching: false,
      success: true,
      nodata: false
    };
    item.fetchingObject['validToReadable'] = {
      fetching: false,
      success: true,
      nodata: false
    };

    if (
      item.hasOwnProperty('validFrom') &&
      item.validFrom !== null &&
      item.validFrom !== undefined
    ) {
      item.validFromReadable = item.validFrom;
    } else {
      item.fetchingObject['validFromReadable'].nodata = true;
    }

    if (
      item.hasOwnProperty('validTo') &&
      item.validTo !== null &&
      item.validTo !== undefined
    ) {
      item.validToReadable = item.validTo;
    } else {
      item.fetchingObject['validToReadable'].nodata = true;
    }

    item.nameReadable =
      item.costCentre.name +
      ' (' +
      (item.costCentre.externalCode != null
        ? item.costCentre.externalCode
        : item.costCentre.code != null
          ? item.costCentre.code
          : '/') +
      ')';
    item.partnerType =
      item.type === 1
        ? gettextCatalog.getString('Company')
        : gettextCatalog.getString('Person');
    item.costCentreId =
      item.costCentre && typeof item.costCentre == 'object'
        ? item.costCentre._id
        : null;
    if (
      item.costCentre.address &&
      typeof item.costCentre.address === 'object'
    ) {
      item.fetchingObject['addressReadable'].fetching = false;
      item.fetchingObject['addressReadable'].success = true;
      var address = item.costCentre.address;
      item.addressReadable = address.streetName ? address.streetName : '';
      item.addressReadable += address.houseNumber
        ? ' ' + address.houseNumber
        : '';
      item.addressReadable += address.settlementName
        ? ', ' + address.settlementName
        : '';
      var country = TranslationService.GetCollectionById(
        'codelists.countries',
        address.country
      );
      if (country) {
        item.addressReadable =
          item.addressReadable + (country.name ? ', ' + country.name : '');
      }

      if (item.addressReadable === '') {
        item.fetchingObject['addressReadable'].nodata = true;
      }

      deferred.resolve();
    } else {
      item.fetchingObject['addressReadable'] = {
        fetching: false,
        success: true,
        nodata: true
      };
    }
    return deferred.promise;
  }

  function getMeasuringpointBPFormConfig() {
    return [
      {
        name: 'costCentre',
        empty: gettext('There are no Cost centres to select.'),
        edit: true,
        componentType: 'autocompleteDialog',
        configuration: {
          query: {
            entity: 'cost-centres',
            method: 'read'
          },
          floatingLabel: gettext('Select Cost centre'),
          searchParamName: 'filter',
          entity: 'cost-centres',
          createRedirect: {
            state: 'company-resources-cost-centres-new'
          }
        }
      },
      {
        componentType: 'datePickerFromTo',
        from: 'validFrom',
        to: 'validTo',
        valToEmpty: true,
        labelFrom: gettext('Valid from *'),
        labelTo: gettext('Valid to *'),
        required: true,
        onlyStartRequired: true,
        idFrom: 'from',
        idTo: 'to'
      },
      {
        componentType: 'twoInRow',
        subData: [
          {
            placeholder: gettext('Time from'),
            name: 'timeFrom',
            pattern: new RegExp('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$'),
            patternExample: '8:00'
          },
          {
            placeholder: gettext('Time to'),
            name: 'timeTo',
            pattern: new RegExp('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$'),
            patternExample: '16:00',
            required: false
          }
        ]
      }
    ];
  }

  function create(obj, paramObject) {
    var deferred = $q.defer();
    var apiObj = {
      validFrom: Formatting.formatDate(obj.validFrom, '00:00:00'),
      validTo: Formatting.formatDate(obj.validTo, '23:59:59:999'),
      costCentre: obj.costCentre._id,
      isMain: true
    };
    apiObj = Object.assign(apiObj, paramObject);
    CostCentreMeasuringpointModel.create(apiObj).then(
      function() {
        CrudToastFactory.toast('create');
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );
    return deferred.promise;
  }

  function update(obj, originalItem, paramObject) {
    var deferred = $q.defer();
    var apiObj = {
      validFrom: Formatting.formatDate(obj.validFrom, '00:00:00'),
      validTo: Formatting.formatDate(obj.validTo, '23:59:59:999'),
      costCentre: obj.costCentre._id,
      isMain: true
    };
    apiObj = Object.assign(apiObj, paramObject);
    CostCentreMeasuringpointModel.update(
      {
        id: originalItem._id
      },
      apiObj
    ).then(
      function() {
        CrudToastFactory.toast('update');
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );
    return deferred.promise;
  }

  function getUpdateObject(item) {
    var validFrom = new Date(item.validFrom);
    var validTo =
      item.validTo && new Date(item.validTo) instanceof Date
        ? new Date(item.validTo)
        : undefined;
    return {
      validFrom: validFrom,
      validTo: validTo,
      costCentre: item.costCentre
    };
  }

  return configuration;
}
export default MeasuringpointCostCentresEntityDateConfiguration;
