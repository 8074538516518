import './sfe-display-type-buttons.component.scss';
import template from './sfe-display-type-buttons.component.html';

export default {
  template,
  bindings: {
    config: '<'
  },
  controller: sfeDisplayTypeButtonsController,
  controllerAs: 'vm',
  bindToController: true
};

/**
 * @ngdoc component
 * @name common.sfeDisplayTypeButtons
 * @description Component used for switching between display types on lists
 * @param {Object} config - object containing currently selected display type, function to call when a switch happens and data if the tree view and card button should be visible
 * @example
 * <sfe-display-type-buttons
 *  config="vm.displayButtonsConfig"
 * ></sfe-display-type-buttons>
 */

sfeDisplayTypeButtonsController.$inject = [];

function sfeDisplayTypeButtonsController() {
  const vm = this;
  vm.listOfButtonTypes = [];

  /**
   * @description returns array of display mode buttons according to configuration.
   * @function
   * @param {Object} config
   *       @param {Boolean} config.cardView
   *       @param {Boolean} config.hideModeButtons
   *       @param {Boolean} config.treeView
   *       @param {String} config.configId
   *       @param {String} config.displayMode
   *       @param {Function} config.changeViewMode
   * @return {Array}
   */
  function buildButtonsArray(config) {
    let buttons = [];

    if (config.treeView) {
      buttons = [
        ...buttons,
        {
          buttonTypeName: 'tree',
          name: 'fa-sitemap',
          type: 5
        }
      ];
    }

    if (config.cardView) {
      buttons = [
        ...buttons,
        {
          buttonTypeName: 'card',
          name: 'fa-object-group',
          type: 5
        }
      ];
    }

    //HIDE DISPLAY MODE BUTTONS WHEN IT IS ONLY TABLE/LIST MODE
    if (!config.hideModeButtons) {
      buttons = [
        {
          buttonTypeName: 'list',
          name: 'format_list_bulleted',
          type: 2
        },
        {
          buttonTypeName: 'table',
          name: 'grid_on',
          type: 2
        },
        ...buttons
      ];
    } else {
      //
      if (buttons.length > 0) {
        if (config.displayMode == 'table') {
          buttons = [
            {
              buttonTypeName: 'table',
              name: 'grid_on',
              type: 2
            },
            ...buttons
          ];
        } else {
          //LIST
          buttons = [
            {
              buttonTypeName: 'list',
              name: 'format_list_bulleted',
              type: 2
            },
            ...buttons
          ];
        }
      }
    }
    return buttons;
  }
  vm.switchMode = function(mode) {
    vm.config.displayMode = mode;
    vm.config.changeViewMode(mode);
  };
  vm.$onChanges = changes => {
    if (changes.config && vm.config) {
      vm.listOfButtonTypes = buildButtonsArray(changes.config.currentValue);
    }
  };
}
