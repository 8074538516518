TimeSeriesValuesDashboardCard.$inject = [
  'CrawlerMethods',
  'AlertingService',
  '$q',
  'gettext',
  'SfeFormDialogService',
  'ColorService',
  'gettextCatalog'
];
/**
 * @ngdoc service
 * @name dashboards.TimeSeriesValuesDashboardCard
 * @description dashboard values card helper methods.
 * @property {function} getCardObject
 * @property {function} openCardEditForm
 * @property {function} openCardNewForm
 * @property {function} saveMeasurement
 * @property {function} updateMeasurementDashboardCardOrder
 */
function TimeSeriesValuesDashboardCard(
  CrawlerMethods,
  AlertingService,
  $q,
  gettext,
  SfeFormDialogService,
  ColorService,
  gettextCatalog
) {
  /**
   * @description fetches dashboard by id.
   * @function
   * @param {string} id dashboard id
   * @param {function} callback callback function
   */
  function fetchDashboard(id, callback) {
    if (id) {
      var method = CrawlerMethods.getMethod({
        entity: 'dashboards',
        method: 'read'
      });
      method({
        id: id
      }).then(
        function(res) {
          callback(null, res.data);
        },
        function() {
          callback(null);
        }
      );
    } else {
      callback(null);
    }
  }
  /**
   * @description fetches time series by id.
   * @function
   * @param {string} id time series id
   * @param {function} callback callback function
   */
  function fetchTimeSeries(id, callback) {
    if (id) {
      var method = CrawlerMethods.getMethod({
        entity: 'time-series',
        method: 'read'
      });
      method({
        id: id,
        populate: 'dataScheduler'
      }).then(
        function(res) {
          callback(null, res.data);
        },
        function(err) {
          callback(err);
        }
      );
    } else {
      callback('No time series');
    }
  }

  /**
   * @description fetches all values dashbaord card entity metadata.
   * @function
   * @param {object} item dashboard values card entity
   * @return {Promise}
   */
  function formatObjects(item) {
    var deferred = $q.defer();
    var waterfall = {
      dashboard: async.apply(fetchDashboard, item.linkedDashboard),
      timeSeries: async.apply(fetchTimeSeries, item.entityId)
    };
    async.parallel(waterfall, function(err, res) {
      if (res) {
        var displayObject = {
          linkedDashboard: res.dashboard,
          timeSeries: res.timeSeries,
          apiItem: item,
          order: item.order
        };
        if (item && item.details) {
          displayObject.description = item.details.description;
          displayObject.icon = item.details.icon;
          displayObject.color = item.details.color;
        }
        deferred.resolve(displayObject);
      }
    });
    return deferred.promise;
  }
  /**
   * @description resolves to dashboard card.
   * @function
   * @param {id} id dashboard card id
   * @return {Promise}
   */
  function fetchDashboardCard(id) {
    var deferred = $q.defer();
    var method = CrawlerMethods.getMethod({
      entity: 'dashboard-cards',
      method: 'read'
    });
    method({
      id: id
    }).then(
      function(res) {
        deferred.resolve(res.data);
      },
      function(err) {
        deferred.reject(err);
      }
    );
    return deferred.promise;
  }

  /**
   * @description returns form configuration.
   * @function
   * @return {array}
   */
  function getFormConfigurations() {
    var linkedDashboardAutocomplete = {
      entity: 'dashboards',
      query: {
        entity: 'dashboards',
        method: 'read'
      },
      floatingLabel: gettext('Link to dashboard'),
      searchParamName: 'filter',
      required: false
    };
    var entityAutocomplete = {
      entity: 'time-series',
      query: {
        entity: 'time-series',
        method: 'custom.populateDataScheduler'
      },
      floatingLabel: gettext('Time series'),
      searchParamName: 'filter',
      createRedirect: {
        state: 'data-time-series-new'
      },
      required: true
    };

    return [
      {
        componentType: 'autocompleteDialog',
        edit: false,
        name: 'timeSeries',
        configuration: entityAutocomplete
      },
      {
        placeholder: 'Description',
        name: 'description',
        componentType: 'textField',
        type: 'text',
        required: false
      },
      {
        componentType: 'autocompleteDialog',
        edit: false,
        name: 'linkedDashboard',
        configuration: linkedDashboardAutocomplete
      },
      {
        componentType: 'colorPicker',
        name: 'color',
        label: gettextCatalog.getString('Color')
      },
      {
        componentType: 'iconPicker',
        name: 'icon'
      }
    ];
  }
  /**
   * @description saves dashboard time series card.
   * @function
   * @param {object} originalItem values dashboard card item before edit form changes
   * @param {object} newItem values dashboard card item after edit form changes
   * @return {Promise}
   */
  async function saveMeasurementValueCardChanges(originalItem, newItem, model) {
    const entities = model.reduce((result, layout) => {
      if (Array.isArray(layout)) {
        let layoutItems = layout.map(layoutItem => {
          if (layoutItem.order === originalItem.order) {
            return {
              _id: originalItem.apiItem._id,
              entityId: newItem.timeSeries ? newItem.timeSeries._id : undefined,
              linkedDashboard: newItem.linkedDashboard
                ? newItem.linkedDashboard._id
                : null,
              entityType: 234, //time series
              order: originalItem.order,
              details: {
                description: newItem.description,
                color: newItem.color,
                icon: newItem.icon
              }
            };
          } else {
            return layoutItem.apiItem;
          }
        });
        return [...result, ...layoutItems];
      }
      return result;
    }, []);

    let method = CrawlerMethods.getMethod({
      entity: 'dashboard-cards',
      method: 'update'
    });

    const { data } = await method(
      {
        id: originalItem.dashboardCardId
      },
      { entities }
    );

    return data.entities.find(item => item.order === originalItem.order);
  }
  /**
   * @memberof TimeSeriesValuesDashboardCard.getCardObject
   * @description resolves to selected dashboard values card and its entities.
   * @param {array} cards array of values card entities
   * @param {string} dashboardCard dashboard card id
   * @return {Promise}
   */
  function getCardObject(cards, dashboardCard) {
    let deferred = $q.defer();
    let promises;

    if (dashboardCard) {
      fetchDashboardCard(dashboardCard).then(
        function(res) {
          promises = [];
          res.entities.forEach(function(entity) {
            promises.push(formatObjects(entity));
          });
          $q.all(promises).then(function(results) {
            deferred.resolve(results);
          });
        },
        function(err) {
          deferred.reject(err);
        }
      );
    } else {
      promises = [];
      cards.forEach(function(entity) {
        promises.push(formatObjects(entity));
      });
      $q.all(promises).then(function(results) {
        deferred.resolve(results);
      });
    }
    return deferred.promise;
  }

  /**
   * @description initiates and opens values dashboard card edit form.
   * @function
   * @param {object} item values dashboard card object
   * @return {dataType}
   */
  function initEditForm(item, model) {
    let deferred = $q.defer();
    let FormConfiguration = getFormConfigurations();

    let editObj = {
      timeSeries: item.timeSeries,
      description: item.description,
      color: item.color,
      icon: item.icon,
      linkedDashboard: item.linkedDashboard,
      _preserve_: true,
      testId: 'updateValue'
    };

    let title = gettext('Edit Value Card');

    SfeFormDialogService.openSfeFormDialog(
      true,
      FormConfiguration,
      editObj,
      title
    ).then(
      function(result) {
        if (result) {
          saveMeasurementValueCardChanges(item, result, model).then(
            function(res) {
              deferred.resolve(res);
            },
            function(err) {
              deferred.reject(err);
            }
          );
        } else {
          deferred.resolve(item);
        }
      },
      function() {
        deferred.reject();
      }
    );
    return deferred.promise;
  }
  /**
   * @memberof TimeSeriesValuesDashboardCard.openCardEditForm
   * @description opens card edit form and resolves to updated card values object.
   * @param {object} item values card object
   * @return {Promise}
   */
  function openCardEditForm(item, model) {
    var deferred = $q.defer();
    initEditForm(item, model).then(
      function(res) {
        deferred.resolve(res);
      },
      function(err) {
        deferred.reject(err);
      }
    );
    return deferred.promise;
  }
  /**
   * @memberof TimeSeriesValuesDashboardCard.openCardNewForm
   * @description opens card new form and resolves to create card values object.
   * @return {Promise}
   */
  function openCardNewForm() {
    var deferred = $q.defer();

    var formObject = {
      _preserve_: true,
      color: ColorService.getApplicationColor('primary', 'hue-2'),
      description: '',
      icon: {},
      editMode: true
    };
    var FormConfigurations = getFormConfigurations();

    var customButton = {
      displayDefault: false,
      buttons: [
        {
          title: gettext('Add'),
          callDialogSaveFunction: true,
          color: 'primary'
        }
      ]
    };

    var title = gettext('Add Value Card');

    SfeFormDialogService.openSfeFormDialog(
      false,
      FormConfigurations,
      formObject,
      title,
      customButton
    ).then(
      function(result) {
        deferred.resolve(result);
      },
      function() {
        deferred.reject();
      }
    );

    return deferred.promise;
  }
  /**
   * @description saves dashboard card entity.
   * @function
   * @param {object} postObj object to be saved
   * @param {string} dashboardCardId dashboard card id
   * @param {function} callback callback function
   */
  function saveDashboardCardEntity(postObj, dashboardCardId) {
    const method = CrawlerMethods.getMethod({
      entity: 'dashboard-cards',
      method: 'update'
    });
    return method({ id: dashboardCardId }, postObj);
  }

  /**
   * @memberof TimeSeriesValuesDashboardCard.saveMeasurement
   * @description description.
   * @param {object} entityPostObj values post object
   * @param {string} dashboardCardId id of dashboard card we are updateing
   * @param {function} createDashboardCard cteate dashboard card function
   * @return {Promise}
   */
  async function saveMeasurement(
    entityPostObj,
    dashboardCardId,
    createDashboardCard
  ) {
    if (!dashboardCardId) {
      const result = await createDashboardCard(null, entityPostObj.entities);
      dashboardCardId = result._id;
      return {
        entities: result.entities,
        dashboardCardId
      };
    } else {
      try {
        const { data } = await saveDashboardCardEntity(
          entityPostObj,
          dashboardCardId
        );
        return {
          entities: data.entities,
          dashboardCardId
        };
      } catch (err) {
        AlertingService.Error(err);
      }
    }
    return {};
  }

  /**
   * @memberof TimeSeriesValuesDashboardCard.updateMeasurementDashboardCardOrder
   * @description updates entity order values.
   * @param {array} model array of entities
   * @param {string} dashboardCardId dashboard card id
   * @return {promise}
   */
  async function updateMeasurementDashboardCardOrder(model, dashboardCardId) {
    const entities = model.reduce((result, layout, layoutIndex) => {
      if (Array.isArray(layout)) {
        let layoutItems = layout.map((item, itemIndex) => {
          return {
            ...item.apiItem,
            order: layoutIndex * 3 + itemIndex
          };
        });
        return [...result, ...layoutItems];
      }
      return result;
    }, []);

    await saveDashboardCardEntity({ entities }, dashboardCardId);
  }
  return {
    getCardObject,
    openCardEditForm,
    openCardNewForm,
    saveMeasurement: saveMeasurement,
    updateMeasurementDashboardCardOrder: updateMeasurementDashboardCardOrder
  };
}

export default TimeSeriesValuesDashboardCard;
