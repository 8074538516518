TangoAssetAvailabilityProperties.$inject = [
  'gettextCatalog',
  'DateLocalizationService',
  'TranslationService'
];
/**
 * @ngdoc service
 * @name companyResources.TangoAssetAvailabilityProperties
 * @description returns asset Availability Properties
 * @property {function} create
 * @property {function} TangoAssetAvailabilityProperties
 */
export default function TangoAssetAvailabilityProperties(
  gettextCatalog,
  DateLocalizationService,
  TranslationService
) {
  /**
   * @description returns array of asset availability properties configuration .
   * @function
   * @param {Object} asset
   * @return {Array}
   */
  function getProperties(asset) {
    const { availability } = asset;
    if (availability != null) {
      let status = TranslationService.GetCollectionById(
        'codelists.assetAvailabilityStatuses',
        availability.status
      );

      if (status != null) {
        status = status.name;
      } else {
        status = null;
      }
      return [
        {
          type: 'basic',
          title: gettextCatalog.getString('Description'),
          values: [
            {
              text: availability.description
            }
          ]
        },
        {
          type: 'basic',
          title: gettextCatalog.getString('Start of availability'),
          values: [
            {
              text: DateLocalizationService.LocalizationDateIntervalFn('d')(
                new Date(availability.startOfAvailability),
                false
              )
            }
          ]
        },
        {
          type: 'basic',
          title: gettextCatalog.getString('Status'),
          values: [
            {
              text: status
            }
          ]
        },
        {
          type: 'basic',
          title: gettextCatalog.getString('Time Series'),
          values: [getTimSeriesPropertyValue(availability.timeSeries)]
        },
        {
          type: 'basic',
          title: gettextCatalog.getString('Minimum value'),
          values: [
            {
              text: availability.minAllowedValue
            }
          ]
        },
        {
          type: 'basic',
          title: gettextCatalog.getString('Maximum value'),
          values: [
            {
              text: availability.maxAllowedValue
            }
          ]
        }
      ];
    }
    return [];
  }
  /**
   * @description returns object of time series sfe property values.
   * @function
   * @param {Object} timeSeries
   * @return {Object}
   */
  function getTimSeriesPropertyValue(timeSeries) {
    let values = [];
    if (timeSeries != null || typeof timeSeries === 'object') {
      let tsRedirectParams = JSON.stringify({ id: timeSeries._id });

      values = {
        text: timeSeries.name,
        link: `data-time-series-view(${tsRedirectParams})`
      };
    }
    return values;
  }
  return {
    getProperties
  };
}
