import template from './tango-asset-availability.component.html';
import './tango-asset-availability.scss';

export default {
  template,
  bindings: {
    asset: '<'
  },
  controller: TangoAssetAvailability,
  controllerAs: 'vm'
};
/**
 * @ngdoc component
 * @name companyResources.TangoAssetAvailability
 * @description displays asset availability.
 * @example
 */
TangoAssetAvailability.$inject = [
  'gettextCatalog',
  'TangoAssetAvailabilityProperties',
  'TangoAssetAvailabilityForm',
  'SfeForm2Dialog',
  'AssetAvailabilityEventConfiguration',
  'TangoAssetAvailabilityDataXService',
  'AlertingService',
  'TimeSeriesModel',
  '$scope',
  'CrudToastFactory'
];

function TangoAssetAvailability(
  gettextCatalog,
  TangoAssetAvailabilityProperties,
  TangoAssetAvailabilityForm,
  SfeForm2Dialog,
  AssetAvailabilityEventConfiguration,
  TangoAssetAvailabilityDataXService,
  AlertingService,
  TimeSeriesModel,
  $scope,
  CrudToastFactory
) {
  const vm = this;
  vm.loading = true;
  vm.apiDataX = {};

  /**
   * @description initialize asset availability.
   * @function
   */
  const initData = async () => {
    const { availability } = vm.asset;
    vm.eventParams = {
      assetId: vm.asset._id
    };
    if (availability != null) {
      try {
        const timeSeries = await TimeSeriesModel.custom.readItemView({
          id: vm.asset.availability.timeSeries,
          populate: 'dataScheduler,externalReaderFlows'
        });
        vm.asset.availability.timeSeries = timeSeries.data;
        if (timeSeries.data.dataScheduler != null) {
          vm.eventListConfiguration = await AssetAvailabilityEventConfiguration.get(
            vm.asset,
            vm.apiDataX
          );
        } else {
          vm.eventListConfiguration = null;
        }
        vm.dataXConfig = await TangoAssetAvailabilityDataXService.get(
          availability,
          vm.apiDataX
        );
      } catch (err) {
        AlertingService.Error(err);
        vm.errorLoadingChart = true;
      }
    }
    vm.headerConfig = getViewHeader(vm.asset);
    vm.properties = TangoAssetAvailabilityProperties.getProperties(vm.asset);
    vm.propertiesTitle = gettextCatalog.getString('Properties');
    $scope.$evalAsync();
  };

  vm.$onChanges = async changes => {
    if (changes.asset && vm.asset != null) {
      await initData();
    }
    vm.loading = false;
  };
  /**
   * @description returns top header configuration.
   * @function
   * @param {Object} asset
   * @return {Object}
   */
  function getViewHeader(asset) {
    const headerTitle = gettextCatalog.getString('Asset Availability');
    let buttonTitle = gettextCatalog.getString('Add Asset Availability');
    if (asset && asset.availability) {
      buttonTitle = gettextCatalog.getString('Update Asset Availability');
    }
    return {
      title: headerTitle,
      titleColor: 'primary',
      backgroundColor: 'white',
      actions: [
        {
          title: buttonTitle,
          fn: async () => {
            try {
              const config = TangoAssetAvailabilityForm.getForm(asset);
              const values = await SfeForm2Dialog.open(config);
              if (asset && asset.availability) {
                CrudToastFactory.toast('update');
              } else {
                CrudToastFactory.toast('create');
              }
              if (values != null) {
                vm.asset.availability = values.availability;
                await initData();
              }
            } catch (err) {
              AlertingService.Error(err);
            }
          }
        }
      ]
    };
  }
}
