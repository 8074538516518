module.exports = {
  entity: 'jobs',
  path: 'jobs',
  name: {
    en: 'Job',
    sl_SI: 'Opravilo'
  },
  networkModel: {
    entity: 'jobs',
    read: 'read'
  },
  scope: 'tis_be:job-reasons',

  dialog: {
    title: {
      en: 'Select job',
      sl_SI: 'Izberite opravilo'
    },
    listInfo: {
      en: 'Select job from the list below',
      sl_SI: 'Izberite opravilo s spodnjega seznama'
    }
  }
}
