import './run-dashboard-playlist.scss';

RunDashboardPlaylistController.$inject = [
  '$stateParams',
  'AlertingService',
  'TranslationService',
  '$interval',
  '$timeout',
  '$scope',
  'loadDashboardModules',
  'DashboardPlaylistModel',
  'DashboardCardModel',
  'CachingParams',
  'dashboardPlaylist'
];

function RunDashboardPlaylistController(
  $stateParams,
  AlertingService,
  TranslationService,
  $interval,
  $timeout,
  $scope,
  loadDashboardModules,
  DashboardPlaylistModel,
  DashboardCardModel,
  CachingParams,
  dashboardPlaylist
) {
  var vm = this;
  var availableDashboards;
  var currentIndex = 0;
  var INTERVAL = 60000;
  var TIMEOUT = 20000;
  var intervalPromise;
  var intervalReloadPage;
  vm.playlistId = $stateParams.id;
  $scope.$on('$destroy', function() {
    if (intervalPromise) {
      $interval.cancel(intervalPromise);
      $interval.cancel(intervalReloadPage);
    }
    CachingParams.RefreshAllParams();
  });

  init();

  function init() {
    fetchPlaylistDashboards();
    setUpReloadInterval();
  }

  function setIntervalAndTimeout(intervalObject) {
    INTERVAL = intervalObject.interval * 1000 - TIMEOUT;
  }

  function setTimer() {
    intervalPromise = $interval(function() {
      vm.dashboards.push(Object.assign({}, availableDashboards[currentIndex]));
      fetchDashboardCards(vm.dashboards[1]).then(function() {
        $timeout(function() {
          vm.dashboards[0].hiding = true;
          vm.dashboards[1].active = true;
          $scope.$broadcast('reOrderGridElements');
          $timeout(function() {
            vm.dashboards[0].hiding = false;
            vm.dashboards[0].active = false;
            vm.dashboards.shift();
          }, 1000);
        }, TIMEOUT);
      });
      currentIndex = (currentIndex + 1) % availableDashboards.length;
    }, INTERVAL);
  }

  function fetchDashboardCards(dashboard) {
    return DashboardCardModel.read(
      { dashboard: dashboard._id },
      vm.cacheInvalidationTime
    ).then(
      function(res) {
        res.data.forEach(function(item) {
          item.id = item.entity;
        });
        dashboard.elements = res.data.sort(function(a, b) {
          return a.order - b.order;
        });
        dashboard.isLoading = false;

        loadDashboardModules(dashboard.elements).then(
          function(res) {
            vm[res] = true;
          },
          function(err) {
            AlertingService.Error('Couldn\'t load module' + err);
          },
          function(res) {
            vm[res] = true;
          }
        );
        return;
      },
      function(err) {
        AlertingService.Error(err);
        dashboard.isLoading = false;
        return;
      }
    );
  }

  // this serves as a failsafe against memory crashes
  function setUpReloadInterval() {
    intervalReloadPage = $interval(function() {
      location.reload();
    }, 14400000);
  }

  function fetchPlaylistDashboards() {
    const dashboards = dashboardPlaylist.dashboardArray;
    setIntervalAndTimeout(
      TranslationService.GetCollectionById(
        'codelists.refreshIntervals',
        dashboardPlaylist.refreshInterval
      )
    );
    const intervalObj = TranslationService.GetCollectionById(
      'codelists.cachingIntervals',
      dashboardPlaylist.cachingInterval
    );
    vm.cacheInvalidationTime = (intervalObj.interval - 10) * 1000;
    let sortedDashboards = [];
    dashboardPlaylist.dashboards.forEach(function(dashboardId) {
      dashboards.forEach(function(dash) {
        if (dash._id === dashboardId) {
          sortedDashboards.push(dash);
        }
      });
    });
    availableDashboards = sortedDashboards.map(function(item) {
      let tempObject = Object.assign({}, item);
      tempObject.isLoading = true;
      return tempObject;
    });
    vm.dashboards = [Object.assign({}, availableDashboards[currentIndex])];
    vm.dashboards[currentIndex].active = true;
    fetchDashboardCards(vm.dashboards[currentIndex]).then(function() {
      $scope.$broadcast('reOrderGridElements');
      setTimer();
    });
    currentIndex = (currentIndex + 1) % availableDashboards.length;
  }
}

export default RunDashboardPlaylistController;
