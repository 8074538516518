import ruleAlarmTemplate from '../components/sfe-form/templates/sfe-rule-alarms.template.html';
import multiLanguageTemplate from '../components/sfe-form/templates/multi-language.tempate.html';
import textFieldTemplate from '../components/sfe-form/templates/text-field.template.html';
import twoInRowTemplate from '../components/sfe-form/templates/two-inputs-in-row.template.html';
import textAreaTemplate from '../components/sfe-form/templates/text-area.template.html';
import passwordTemplate from '../components/sfe-form/templates/password.template.html';
import chipListTemplate from '../components/sfe-form/templates/chip-list.template.html';
import colorPickerTemplate from '../components/sfe-form/templates/color-picker.template.html';
import yearPickerFromToTemplate from '../components/sfe-form/templates/year-picker-from-to.template.html';
import datePickerFromToTemplate from '../components/sfe-form/templates/date-picker-from-to.template.html';
import singleDateTemplate from '../components/sfe-form/templates/single-date.template.html';
import autocompleteDialogTemplate from '../components/sfe-form/templates/autocomplete-dialog.template.html';
import autocompleteAdderTemplate from '../components/sfe-form/templates/autocomplete-adder.template.html';
import checkBoxTemplate from '../components/sfe-form/templates/check-box.template.html';
import textInputSelectTemplate from '../components/sfe-form/templates/text-input-select.template.html';
import checkBoxesLinearTemplate from '../components/sfe-form/templates/check-boxes-linear.template.html';
import multiSelectTemplate from '../components/sfe-form/templates/multi-select.template.html';
import multiSelectListTemplate from '../components/sfe-form/templates/sfe-multi-select-list.template.html';
import cronTemplate from '../components/sfe-form/templates/cron.template.html';
import addressTemplate from '../components/sfe-form/templates/address.template.html';
import radio2Template from '../components/sfe-form/templates/radio2.template.html';
import radioTemplate from '../components/sfe-form/templates/radio.template.html';
import iconPickerTemplate from '../components/sfe-form/templates/icon-picker.template.html';
import fourInRowTemplate from '../components/sfe-form/templates/four-in-row.template.html';
import sixInRowTemplate from '../components/sfe-form/templates/six-in-row.template.html';
import twoCheckboxOneMultiSelectTemplate from '../components/sfe-form/templates/two-checkbox-one-multi-select.template.html';
import twoCheckboxesInRowTemplate from '../components/sfe-form/templates/two-checkboxes-in-row.template.html';
import twoSfeSelectsInRowTemplate from '../components/sfe-form/templates/two-sfe-selects-in-row.template.html';
import fileUploadTemplate from '../components/sfe-form/templates/file-upload.template.html';
import autocompleteChipDialogTemplate from '../components/sfe-form/templates/autocomplete-chip-dialog.template.html';
import textAreaEditorTemplate from '../components/sfe-form/templates/text-area-editor.template.html';
import dndOrderedInputTemplate from '../components/sfe-form/templates/dnd-ordered-input.template.html';
import elementSelectorTemplate from '../components/sfe-form/templates/sfe-element-selector.template.html';
import sfeMultiSelect2Template from '../components/sfe-form/templates/sfe-multi-select-2.template.html';
import paragraphTemplate from '../components/sfe-form/templates/paragraph.template.html';
import labelValueTemplate from '../components/sfe-form/templates/label-value.template.html';
import tagSelectorTemplate from '../components/sfe-form/templates/tag-selector.template.html';
import titleTemplate from '../components/sfe-form/templates/title.template.html';
import measurementSelectorTemplate from '../components/sfe-form/templates/measurement-selector.template.html';
import physicalAutocompleteTemplate from '../components/sfe-form/templates/physical-autocomplete.template.html';
import dividerTemplate from '../components/sfe-form/templates/divider.template.html';
import dndAutocompleteTemplate from '../components/sfe-form/templates/drag-and-drop-autocomplete.template.html';
import proxyIframeTemplate from '../components/sfe-form/templates/proxy-iframe.template.html';
import sfePhoneNumberTemplate from '../components/sfe-form/templates/sfe-phone-number.template.html';

export default function FormTemplates() {
  return {
    rulealarm: ruleAlarmTemplate,
    multilanguage: multiLanguageTemplate,
    textField: textFieldTemplate,
    twoInRow: twoInRowTemplate,
    textArea: textAreaTemplate,
    password: passwordTemplate,
    chipList: chipListTemplate,
    colorPicker: colorPickerTemplate,
    yearPickerFromTo: yearPickerFromToTemplate,
    datePickerFromTo: datePickerFromToTemplate,
    singleDate: singleDateTemplate,
    autocompleteDialog: autocompleteDialogTemplate,
    autocompleteAdder: autocompleteAdderTemplate,
    checkBox: checkBoxTemplate,
    textInputSelect: textInputSelectTemplate,
    checkBoxesLinear: checkBoxesLinearTemplate,
    multiSelect: multiSelectTemplate,
    multiSelectList: multiSelectListTemplate,
    cron: cronTemplate,
    address: addressTemplate,
    radio2: radio2Template,
    radio: radioTemplate,
    iconPicker: iconPickerTemplate,
    fourInRow: fourInRowTemplate,
    sixInRow: sixInRowTemplate,
    twoCheckboxOneMultiSelect: twoCheckboxOneMultiSelectTemplate,
    twoCheckboxesInRow: twoCheckboxesInRowTemplate,
    twoSfeSelectsInRow: twoSfeSelectsInRowTemplate,
    fileUpload: fileUploadTemplate,
    autocompleteChipDialog: autocompleteChipDialogTemplate,
    textAreaEditor: textAreaEditorTemplate,
    dndOrderedInput: dndOrderedInputTemplate,
    elementSelector: elementSelectorTemplate,
    sfeMultiSelect2: sfeMultiSelect2Template,
    paragraph: paragraphTemplate,
    labelValue: labelValueTemplate,
    tagSelector: tagSelectorTemplate,
    title: titleTemplate,
    measurementSelector: measurementSelectorTemplate,
    physicalAutocomplete: physicalAutocompleteTemplate,
    divider: dividerTemplate,
    dndAutocomplete: dndAutocompleteTemplate,
    proxyIframe: proxyIframeTemplate,
    sfePhoneNumber: sfePhoneNumberTemplate
  };
}
