ReportModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function ReportModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'reports'
  };

  var crawlerConfiguration = {
    entity: 'reports',
    method: 'read',
    steps: [
      {
        entity: 'datasets',
        valueParams: ['dataset'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'populateArray'
      }
    ]
  };

  var crawlerConfigurationList = {
    entity: 'reports',
    method: 'read',
    populate: 'dataset',
    select: '-stimulsoftReportTemplate'
  };

  var crawlerConfigurationDatasetPeriodIntervals = {
    entity: 'reports',
    method: 'read',
    populate: 'dataset,periodInterval'
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readMetadata: CrawlerMethods.crawler(crawlerConfiguration),
      list: CrawlerMethods.crawler(crawlerConfigurationList),
      readItemViewEditForm: CrawlerMethods.crawler(
        crawlerConfigurationDatasetPeriodIntervals
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default ReportModel;
