import './year-select.scss';
import template from './year-select.directive.html';

export default function yearSelect() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      model: '=',
      maxYear: '=',
      minYear: '=',
      form: '=',
      required: '=',
      label: '='
    },
    link: linkFn,
    controller: YearSelectController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  function linkFn() {}
}

YearSelectController.$inject = ['$scope', 'gettext'];

function YearSelectController($scope, gettext) {
  var vm = this;
  // vm.mdSelectOnOpen = mdSelectOnOpen;
  vm.selectYear = selectYear;
  vm.previousYears = previousYears;
  vm.nextYears = nextYears;
  vm.buttonTitle = gettext('Select year');
  vm.initDates = init;
  if (vm.form) {
    $scope.yearForm = vm.form;
  }

  init();

  function init() {
    vm.options = [[{}, {}, {}], [{}, {}, {}], [{}, {}, {}]];

    if (typeof vm.model !== 'undefined') {
      vm.max = {
        name: vm.model * 1 - 5
      };
      nextYears();

      vm.options[1][1].selected = true;
    } else {
      vm.options = [
        [
          {
            name: 2014
          },
          {
            name: 2015
          },
          {
            name: 2016
          }
        ],
        [
          {
            name: 2017
          },
          {
            name: 2018
          },
          {
            name: 2019
          }
        ],
        [
          {
            name: 2020
          },
          {
            name: 2021
          },
          {
            name: 2022
          }
        ]
      ];
      setMinMax();
    }
  }

  function setMinMax() {
    vm.min = vm.options[0][0];
    var optionsLen = vm.options.length;
    vm.max = vm.options[optionsLen - 1][vm.options[optionsLen - 1].length - 1];
  }

  function previousYears() {
    var firstYear = vm.min.name;
    for (var i = vm.options.length - 1; i >= 0; i--) {
      for (var j = vm.options[i].length - 1; j >= 0; j--) {
        vm.options[i][j].name = --firstYear;
        vm.options[i][j].selected = false;
      }
    }
    setMinMax();
  }

  function nextYears() {
    var lastYear = vm.max.name;
    vm.options.forEach(function(rowOptions) {
      rowOptions.forEach(function(option) {
        option.name = ++lastYear;
        option.selected = false;
      });
    });
    setMinMax();
  }

  function selectYear(item) {
    vm.options.forEach(function(rowOptions) {
      rowOptions.forEach(function(option) {
        if (option.name === item.name) {
          option.selected = true;
          vm.model = option.name;
        } else {
          option.selected = false;
        }
      });
    });
  }
}
