import './dashboard-map.scss';
import template from './sfe-dashboard-location-map.directive.html';

function sfeDashboardLocationMap() {
  function linkFn(scope, el, attrs, ctrl) {
    ctrl.getElementWidth = getElementWidth;
    function getElementWidth() {
      var parent = el.parent();
      return parent[0].clientWidth;
    }
  }
  return {
    template,
    scope: {
      entity: '@',
      entityLinks: '<',
      costCentre: '<',
      size: '<',
      cacheInvalidationTime: '<?'
    },
    controller: SfeDashboardLocationMapController,
    controllerAs: 'vm',
    link: linkFn,
    bindToController: true
  };
}

export default sfeDashboardLocationMap;

SfeDashboardLocationMapController.$inject = [
  'NgMap',
  'AlertingService',
  'CrawlerMethods',
  '$q',
  'loadAssets',
  'ImageModel',
  'GoogleMapsAPI',
  '$scope',
  'Refreshing',
  '$timeout'
];

function SfeDashboardLocationMapController(
  NgMap,
  AlertingService,
  CrawlerMethods,
  $q,
  loadAssets,
  ImageModel,
  GoogleMapsAPI,
  $scope,
  Refreshing,
  $timeout
) {
  var vm = this;
  vm.uniqueId = Math.random()
    .toString(36)
    .substring(2);
  vm.$onInit = init;

  function init() {
    loadAssets(['markerCluster']).then(
      function() {
        vm.clusterDone = true;
        initializeMap();
      },
      function() {
        AlertingService.Error('Couldn\'t initiate clusters');
      }
    );
  }

  function initializeMap() {
    NgMap.getMap({
      id: vm.uniqueId
    }).then(function(map) {
      vm.map = map;
      fetchLocations();
    });
  }

  async function fetchLocations() {
    var apiObj = {
      limit: 1000,
      page: 1,
      select: 'geoLocation,_id,name,address'
    };
    var loadMore = true;
    if (vm.costCentre) {
      apiObj['costCentreLocations.costCentre'] = vm.costCentre;
      apiObj.populate = 'costCentreLocations';
    }
    vm.fetching = true;
    var method = CrawlerMethods.getMethod({
      entity: vm.entity,
      method: 'read'
    });
    let locations = [];
    let fetchedLocations;
    try {
      while (loadMore) {
        fetchedLocations = await method(apiObj, vm.cacheInvalidationTime);
        loadMore =
          fetchedLocations.pagination.page *
            fetchedLocations.pagination['per_page'] <=
          fetchedLocations.pagination.total;
        locations = locations.concat(fetchedLocations.data);
        apiObj.page++;
      }
      $timeout(function() {
        addMarkers(locations);
      });
    } catch (err) {
      AlertingService.Error(err);
      vm.fetching = false;
    }
  }

  function fetchAddress(item, callback) {
    if (!item.address) {
      callback(null, item, null);
    } else {
      var method = CrawlerMethods.getMethod({
        entity: 'addresses',
        method: 'read'
      });
      method(
        {
          id: item.address
        },
        vm.cacheInvalidationTime
      ).then(
        function(res) {
          callback(null, item, res.data);
        },
        function() {
          callback(null, item, null);
        }
      );
    }
  }

  function fetchPictureLocation(item, address, callback) {
    if (vm.entity === 'locations') {
      var method = CrawlerMethods.getMethod({
        entity: 'picture-locations',
        method: 'read'
      });
      method(
        {
          location: item._id,
          limit: 1,
          populate: 'picture'
        },
        vm.cacheInvalidationTime
      ).then(
        function(res) {
          var pictureId =
            Array.isArray(res.data) && res.data[0]
              ? res.data[0].picture._id
              : null;
          callback(null, item, address, pictureId);
        },
        function() {
          callback(null, item, address, null);
        }
      );
    } else {
      callback(null, item, address, null);
    }
  }

  function fetchPicture(item, address, imageId, callback) {
    if (imageId) {
      ImageModel.read({ imageId: imageId }).then(
        function(res) {
          callback(null, item, address, res.data);
        },
        function(err) {
          AlertingService.Error(err);
          callback(null, item, address, null);
        }
      );
    } else {
      callback(null, item, address, null);
    }
  }

  function fetchEntityMetadata(item) {
    var deferred = $q.defer();
    var waterfall = [
      async.apply(fetchAddress, item),
      fetchPictureLocation,
      fetchPicture
    ];
    async.waterfall(waterfall, function(err, item, address, imageUrl) {
      var content, addressObj, link;
      if (!err) {
        switch (vm.entity) {
        case 'locations':
          link = '/company-resources/locations/' + item._id;
          break;
        }
        if (vm.entityLinks) {
          var found = vm.entityLinks.find(function(link) {
            return link.entityId === item._id;
          });
        }
        if (found) {
          link = 'dashboards/dashboards/' + found.linkedDashboard;
        }

        if (
          address &&
          (address.streetName ||
            address.houseNumber ||
            address.communityName ||
            address.postalNumber ||
            address.postName)
        ) {
          const streetName = `${address.streetName || ''} ${
            address.houseNumber == undefined ? '' : address.houseNumber
          }`;
          const communityName = address.communityName || '';
          const postalNumber = `${
            address.postalNumber == undefined ? '' : address.postalNumber
          } ${address.postName || ''}`;
          addressObj = {
            streetName,
            communityName,
            postalNumber
          };
        }
        content = GoogleMapsAPI.createMarkerDOM(
          item.name,
          link,
          imageUrl,
          addressObj
        );
      } else {
        content = 'Unknown';
      }
      deferred.resolve(content);
    });
    return deferred.promise;
  }

  function addMarkers(data) {
    var bounds = new google.maps.LatLngBounds();
    vm.clusterMarkers = [];
    if (data) {
      data.forEach(function(item) {
        if (item.geoLocation) {
          var latLng = new google.maps.LatLng(
            item.geoLocation[1],
            item.geoLocation[0]
          );
          bounds.extend(latLng);

          // tooltip
          var contentString = 'Loading...';
          var infowindow = new google.maps.InfoWindow({
            content: contentString
          });

          // marker
          var newMarker = new google.maps.Marker({
            label: {
              text: item.name || '',
              fontSize: 'sfe-small-text',
              fontWeight: '600',
              fontFamily: '"Roboto", sans-serif'
            },
            icon: {
              url: '/google_maps_markers/ic_place_2x.png',
              labelOrigin: new google.maps.Point(14, -4),
              size: new google.maps.Size(32, 32),
              scaledSize: new google.maps.Size(32, 32)
            },
            color: 'red',
            position: latLng,
            opacity: 0.9,
            id: item._id,
            entityItem: item
          });

          // on click, get address data and show tooltip
          newMarker.addListener('click', function() {
            infowindow.setContent('Loading...');
            infowindow.open(vm.map, newMarker);

            fetchEntityMetadata(item).then(function(content) {
              infowindow.setContent(content);
            });
          });
          vm.clusterMarkers.push(newMarker);
        }
      });
    }

    vm.fetching = false;
    if (vm.cluster) {
      vm.cluster.clearMarkers();
    }

    vm.map.setCenter(bounds.getCenter());
    vm.map.fitBounds(bounds);
    vm.cluster = new MarkerClusterer(vm.map, vm.clusterMarkers, {
      imagePath: '/google_maps_markers/m'
    });

    var clusterInfoWindow = new google.maps.InfoWindow({
      content: 'Loading...'
    });

    google.maps.event.addListener(vm.cluster, 'clusterclick', function(
      cluster
    ) {
      var allAtTheSamePosition = true;
      var markers = angular.copy(cluster.getMarkers());
      var firstMarker = markers.splice(0, 1)[0];
      var marker;
      const clusterMap = cluster.map_;

      for (
        var i = 0;
        allAtTheSamePosition && i < markers.length;
        i++ &&
        clusterMap.zoom !== clusterMap.mapTypes[vm.map.getMapTypeId()].maxZoom
      ) {
        marker = markers[i];
        if (!marker.getPosition().equals(firstMarker.getPosition())) {
          allAtTheSamePosition = false;
        }
      }
      if (allAtTheSamePosition) {
        var promises = [];
        cluster.getMarkers().forEach(function(marker) {
          promises.push(fetchEntityMetadata(marker.entityItem));
        });
        clusterInfoWindow.setPosition(cluster.getCenter());
        clusterInfoWindow.open(vm.map);
        clusterInfoWindow.setContent('Loading ...');
        var temp = document.createElement('DIV');
        temp.className = 'info-window-scroller';
        $q.all(promises).then(function(results) {
          results.forEach(function(res) {
            temp.appendChild(res);
          });
          clusterInfoWindow.setContent(temp);
        });
      }
    });
  }

  var refresherId;
  $scope.$on('$destroy', function() {
    if (refresherId) {
      Refreshing.removeFn(refresherId.id, refresherId.fnId);
    }
    if (refresherWatcher) {
      refresherWatcher();
    }
  });
  var refresherWatcher = $scope.$watch('vm.refresher', function(val) {
    if (val) {
      refresherId = vm.refresher(init);
    }
  });
}
