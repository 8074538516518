NewEnergySourceController.$inject = [
  '$stateParams',
  '$timeout',
  'gettext',
  'AlertingService',
  'MultiLanguage',
  'MetadataService',
  'LocalizationService',
  'createOrUpdateService',
  'SfeHeader',
  'energySourceType'
];

function NewEnergySourceController(
  $stateParams,
  $timeout,
  gettext,
  AlertingService,
  MultiLanguage,
  MetadataService,
  LocalizationService,
  createOrUpdateService,
  SfeHeader,
  energySourceType
) {
  const vm = this;
  const energySourceTypeId = $stateParams.id;
  vm.editMode = !!energySourceTypeId;
  vm.dataConfig = {};

  init();

  function init() {
    const data = [
      {
        placeholder: gettext('Name'),
        name: 'name',
        type: 'text',
        required: true,
        componentType: 'multilanguage'
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        type: 'text',
        required: false,
        componentType: 'multilanguage'
      },
      {
        placeholder: gettext('Code'),
        name: 'code',
        componentType: 'textField',
        type: 'number',
        required: true
      },
      {
        placeholder: gettext('External Code'),
        name: 'externalCode',
        componentType: 'textField',
        type: 'text',
        required: false
      },
      {
        componentType: 'physicalAutocomplete',
        physicalQuantity: 'defaultPhysicalQuantity',
        metricPrefix: 'defaultMetricPrefix',
        measurementUnit: 'defaultMeasurementUnit',
        result: 'physicalData',
        required: true,
        physicalLabel: gettext('Select default physical quantity'),
        unitLabel: gettext('Select default unit of measurement')
      },
      {
        componentType: 'autocompleteDialog',
        name: 'parentId',
        edit: false,
        configuration: {
          query: {
            entity: 'energy-source-types',
            method: 'read'
          },
          entity: 'energy-source-types',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Energy Source Type')
          },
          floatingLabel: gettext('Select Parent Energy Source Type'),
          searchParamName: 'filter',
          required: false,
          createRedirect: {
            state: 'configurations-energy-management-energy-source-types-new'
          }
        }
      },
      {
        componentType: 'textField',
        placeholder: gettext('Transformation To kWh'),
        name: 'transformationToKwh',
        required: false,
        min: 0,
        type: 'numerical'
      },
      {
        componentType: 'textField',
        placeholder: gettext('CO2 per kWh'),
        name: 'co2perKwh',
        required: false,
        min: 0,
        type: 'numerical'
      },
      {
        componentType: 'textField',
        placeholder: gettext('CO per kWh'),
        name: 'coPerKwh',
        required: false,
        min: 0,
        type: 'numerical'
      },
      {
        componentType: 'textField',
        placeholder: gettext('NOx per kWh'),
        name: 'noxPerKwh',
        required: false,
        min: 0,
        type: 'numerical'
      },
      {
        componentType: 'textField',
        placeholder: gettext('SO2 per kWh'),
        name: 'so2perKwh',
        required: false,
        min: 0,
        type: 'numerical'
      },
      {
        componentType: 'textField',
        placeholder: gettext('Parts per kWh'),
        name: 'partsPerKwh',
        required: false,
        min: 0,
        type: 'numerical'
      },
      {
        componentType: 'iconPicker',
        name: 'energySourceIcon'
      }
    ];

    var action = {
      ctrlFn: true,
      title: vm.editMode ? gettext('Update') : gettext('Create'),
      fn: createOrUpdate
    };

    vm.dataConfig.data = data;
    vm.dataConfig.action = action;

    if (vm.editMode) {
      MetadataService.Loading(true);
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit Energy Source Type'),
        'configurations-energy-management-energy-source-types-view',
        $stateParams
      );
      getEnergySourceType();
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New Energy Source Type'),
        'configurations-energy-management-energy-source-types-list'
      );
      vm.dataConfig.dataObj = {
        name: '',
        description: '',
        code: '',
        externalCode: '',
        defaultPhysicalQuantity: null,
        defaultMeasurementUnit: null,
        defaultMetricPrefix: null,
        physicalData: {},
        icon: {},
        parentId: 0,
        transformationToKwh: 0,
        co2perKwh: 0,
        coPerKwh: 0,
        noxPerKwh: 0,
        so2perKwh: 0,
        partsPerKwh: 0
      };
    }
  }

  function getEnergySourceType() {
    const selectedLang = LocalizationService.GetSelectedLangNetworkCode();
    const translatedData = MultiLanguage.translateMultiLanguageValues(
      energySourceType,
      ['parentId.name'],
      selectedLang
    );
    vm.dataConfig.dataObj = {
      name: translatedData.name,
      description: translatedData.description,
      code: translatedData.code,
      defaultPhysicalQuantity: translatedData.defaultPhysicalQuantity,
      defaultMeasurementUnit: translatedData.defaultMeasurementUnit,
      defaultMetricPrefix: translatedData.defaultMetricPrefix,
      physicalData: {},
      externalCode: translatedData.externalCode,
      energySourceIcon: translatedData.icon || {},
      parentId: translatedData.parentId,
      transformationToKwh: translatedData.transformationToKwh,
      co2perKwh: translatedData.co2perKwh,
      coPerKwh: translatedData.coPerKwh,
      noxPerKwh: translatedData.noxPerKwh,
      so2perKwh: translatedData.so2perKwh,
      partsPerKwh: translatedData.partsPerKwh
    };
    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(
        'Edit ' + energySourceType.name
          ? energySourceType.name[selectedLang]
          : null,
        energySourceType.description
          ? energySourceType.description[selectedLang]
          : null
      );
    });
  }

  function createOrUpdate() {
    vm.sendingRequest = true;
    createOrUpdateService
      .simpleCall(
        {
          entity: 'energy-source-types',
          method: vm.editMode ? 'update' : 'create'
        },
        vm.editMode,
        energySourceTypeId,
        dataToSave(),
        'configurations-energy-management-energy-source-types-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function dataToSave() {
    var dataObj = vm.dataConfig.dataObj;

    var energySourceObj = MultiLanguage.constructForm(
      vm.dataConfig.data,
      vm.dataConfig.dataObj
    );
    dataObj.name = energySourceObj.name;
    dataObj.description = energySourceObj.description;

    return {
      name: dataObj.name,
      description: dataObj.description,
      code: dataObj.code,
      externalCode: dataObj.externalCode,
      defaultPhysicalQuantity: dataObj.physicalData.physicalQuantity,
      defaultMetricPrefix: dataObj.physicalData.metricPrefix,
      defaultMeasurementUnit: dataObj.physicalData.measurementUnit,
      icon: {
        name: dataObj.energySourceIcon
          ? dataObj.energySourceIcon.name || null
          : null,
        type: dataObj.energySourceIcon
          ? dataObj.energySourceIcon.type || null
          : null,
        color: dataObj.energySourceIcon
          ? dataObj.energySourceIcon.color || null
          : null
      },
      parentId: dataObj.parentId ? dataObj.parentId._id || null : null,
      transformationToKwh: dataObj.transformationToKwh,
      co2perKwh: dataObj.co2perKwh,
      coPerKwh: dataObj.coPerKwh,
      noxPerKwh: dataObj.noxPerKwh,
      so2perKwh: dataObj.so2perKwh,
      partsPerKwh: dataObj.partsPerKwh
    };
  }
}

export default NewEnergySourceController;
