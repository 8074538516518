MeasuringpointdomainattributeModel.$inject = ['ModelUtilities'];

function MeasuringpointdomainattributeModel(ModelUtilities) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'view',
    path: 'measuringpointdomainattributes'
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default MeasuringpointdomainattributeModel;
