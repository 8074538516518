/**
 * @ngdoc service
 * @name config.DomainCollectionConfigurationService
 * @description description.
 * @property {function} getForm
 */
function DomainCollectionConfigurationService() {
  /**
   * @memberof DomainCollectionConfigurationService.getForm
   * @description returns array of domain fields form configurations.
   * @param {array} domainFileds array of domain field objects
   * @return {array}
   */
  function getForm(domainFileds) {
    var obj;
    var config = domainFileds.map(function(domainFiled) {
      obj = {
        name: domainFiled.rawFieldName
      };

      switch (domainFiled.fieldType) {
      case 'String':
        obj.componentType = 'textField';
        obj.type = 'text';
        obj.placeholder = domainFiled.name;
        break;
      case 'Integer':
        obj.componentType = 'textField';
        obj.type = 'number';
        obj.placeholder = domainFiled.name;
        break;
      case 'Float':
        obj.componentType = 'textField';
        obj.type = 'text';
        obj.pattern = new RegExp('^-?[0-9]+([,.][0-9]+)?$');
        obj.patternExample = 'Value should be integer or float';
        obj.placeholder = domainFiled.name;
        break;
      case 'Boolean':
        obj.label = domainFiled.name;
        obj.componentType = 'checkBox';
        break;
      case 'Date':
        obj.componentType = 'singleDate';
        obj.dateName = domainFiled.rawFieldName;
        obj.timeName = 'time';
        obj.dateLabel = domainFiled.name;
        obj.required = true;
        obj.idDate = domainFiled.rawFieldName;
        obj.noTimeInput = true;
        break;
      }
      return obj;
    });
    return config;
  }

  return {
    getForm: getForm
  };
}

export default DomainCollectionConfigurationService;
