/**
 * @ngdoc service
 * @name common.CryptoService
 * @description Services for creating hash values
 * @property {function} GenerateHash - See GenerateHash method
 * @property {function} GenerateNetworkHash - See GenerateNetworkHashmethod
 */

export default function CryptoService() {
  /**
   * @memberof common.CryptoService
   * @description Function creates a hash based passed data, while ignoring certain specified fields.
   * @param {Object} entity - Object containing that that will be hashed.
   * @param {Array} ignoreFields - Array containing propert names taht should eb ignored when creating a hash.
   * @return {string} hashValue
   */
  var GenerateHash = function(entity, ignoreFields = []) {
    var keys = Object.keys(entity);
    var entityValues = [];
    var hashValue = '';
    // ignore _id and __v by default
    ignoreFields.push('_id', '__v');
    ignoreFields.forEach(function(field) {
      var idIndex = keys.indexOf(field);
      if (idIndex !== -1) {
        keys.splice(idIndex, 1);
      }
    });
    keys.sort();
    keys.forEach(function(key) {
      entityValues.push(entity[key]);
    });
    hashValue = entityValues.join('');
    hashValue = CryptoJS.HmacSHA1(hashValue, 'secret').toString(
      CryptoJS.enc.Hex
    );
    return hashValue;
  };

  /**
   * @memberof common.CryptoService
   * @description Function creates a hash based passed data
   * @param {Object} entity - Object containing data that will be hashed
   * @return {string} hashValue
   */
  function GenerateNetworkHash(entity) {
    var keys = Object.keys(entity);
    keys.sort();
    var entityValues = [];
    var hashValue = '';
    keys.forEach(function(key) {
      entityValues.push(key + '' + entity[key]);
    });
    hashValue = entityValues.join('');
    hashValue = CryptoJS.HmacSHA1(hashValue, 'secret').toString(
      CryptoJS.enc.Hex
    );
    return hashValue;
  }

  function GenerateHashString(inputString) {
    return CryptoJS.SHA1(inputString, 'secret').toString(CryptoJS.enc.Hex);
  }

  return {
    GenerateHashString,
    GenerateHash,
    GenerateNetworkHash
  };
}
