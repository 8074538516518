DownloadCSVService.$inject = [
  'AlertingService',
  'SfeFormDialogService',
  'gettext',
  'CrawlerMethods'
];
/**
 * @ngdoc service
 * @name common.DownloadCSVService
 * @description Function for creating and downloading a csv.
 * @property {function} showDownloadDialog - See method showDownloadDialog.
 */
export default function DownloadCSVService(
  AlertingService,
  SfeFormDialogService,
  gettext,
  CrawlerMethods
) {
  var defaultCSVLimit = 10000;
  var title = 'Download CSV';
  var dataObj = {
    _preserve_: true,
    lastHundred: true //default download option
  };
  var config = [
    {
      componentType: 'checkBox',
      label: gettext('Selected period'),
      name: 'selectInterval',
      action: function() {
        var current = this;
        config.forEach(function(item) {
          if (item.componentType === 'checkBox' && item.name !== current.name) {
            dataObj[item.name] = false;
          }
        });
      }
    },
    {
      componentType: 'datePickerFromTo',
      from: 'validFrom',
      to: 'validTo',
      valToEmpty: true,
      labelFrom: gettext('Valid from *'),
      labelTo: gettext('Valid to *'),
      required: true,
      idFrom: 'from',
      idTo: 'to',
      hide: true,
      showParam: 'selectInterval'
    },
    {
      componentType: 'twoInRow',
      subData: [
        {
          placeholder: gettext('Time from'),
          name: 'timeFrom',
          pattern: new RegExp('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$'),
          patternExample: '8:00'
          // required = true
        },
        {
          placeholder: gettext('Time to'),
          name: 'timeTo',
          pattern: new RegExp('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$'),
          patternExample: '16:00'
          // required = true
        }
      ],
      hide: true,
      showParam: 'selectInterval'
    },
    {
      componentType: 'checkBox',
      label: gettext('Select number of values'),
      name: 'selectLimit',
      action: function() {
        var current = this;
        config.forEach(function(item) {
          if (item.componentType === 'checkBox' && item.name !== current.name) {
            dataObj[item.name] = false;
          }
        });
      }
    },
    {
      componentType: 'textField',
      type: 'number',
      name: 'numberOfValues',
      pattern: new RegExp('^[0-9]+$'),
      patternExample: '16',
      placeholder: gettext('Min 1, Max 10000'),
      min: 1,
      max: 10000,
      hide: true,
      showParam: 'selectLimit',
      required: true
    },
    {
      componentType: 'checkBox',
      label: gettext('Select last 100'),
      name: 'lastHundred',
      action: function() {
        var current = this;
        config.forEach(function(item) {
          if (item.componentType === 'checkBox' && item.name !== current.name) {
            dataObj[item.name] = false;
          }
        });
      }
    }
  ];
  /**
   * @memberof common.DownloadCSVService
   * @description Opens a configuration dialog, creates a csv file and then downloads it.
   * @param {string} name - Name of the itemview where we are downloading from.
   * @param {string} dataType - Name of the dataType (kpi, adp etc.) we are downloading.
   * @param {string} apiEndpoint - Endpoint of the data we are going to turn to a csv.
   * @param {integer} pointId - Id of entity instance of which to download csv.
   */
  function showDownloadDialog(name, dataType, apiEndpoint, timeSeriesId) {
    var dialogButtonsConfiguration = {
      displayDefault: false,
      buttons: [
        {
          title: gettext('Download'),
          script: function(closeDialog, res) {
            if (res) {
              // prepare filename, pass it only the download type (res)
              var filename = generateFilename(res, dataType, name);
              // prepare the request object
              var requestObject = {
                timeSeriesId,
                limit: res.selectLimit
                  ? res.numberOfValues
                  : res.lastHundred
                    ? 100
                    : defaultCSVLimit,
                from: res.selectInterval
                  ? setTime(res.validFrom, res.timeFrom)
                  : undefined,
                to: res.selectInterval
                  ? setTime(res.validTo, res.timeTo)
                  : undefined
              };

              requestObject.view = 'simple';
              var method = CrawlerMethods.getMethod({
                entity: apiEndpoint,
                method: 'custom.downloadCsv'
              });
              method(requestObject).then(
                function(res) {
                  // res returns array of chars (["a", "l", "a", "r", "m", ...])
                  var data;
                  var link;
                  var csv = '';
                  // turn the array of chars into a string
                  for (var key in res) {
                    if (
                      res.hasOwnProperty(key) &&
                      typeof res[key] === 'string'
                    ) {
                      csv = csv + res[key];
                    }
                  }
                  // remove quotation marks from it
                  csv = csv.replace(/['"]/g, '');
                  if (csv == null) return;
                  // add encoding to the start of the data
                  if (!csv.match(/^data:text\/csv/i)) {
                    csv = 'data:text/csv;charset=utf-8,' + csv;
                  }
                  // prepare the download file/link and click it
                  data = encodeURI(csv);
                  link = document.createElement('a');
                  document.body.appendChild(link);
                  link.setAttribute('href', data);
                  link.setAttribute('download', filename);
                  link.click();
                  document.body.removeChild(link);
                },
                function(err) {
                  method(requestObject).then(
                    function(res) {
                      if (res.data && res.data.length === 0) {
                        AlertingService.Error(
                          'Selected interval has no values.'
                        );
                      } else {
                        AlertingService.Error(err);
                      }
                    },
                    function(err) {
                      AlertingService.Error(err);
                    }
                  );
                }
              );
            }
            closeDialog();
          }
        }
      ]
    };
    dataObj.validFrom = null;
    dataObj.validTo = null;
    SfeFormDialogService.openSfeFormDialog(
      false,
      config,
      dataObj,
      title,
      dialogButtonsConfiguration
    );
  }
  /**
   * @description Generates filename for the .csv file you are creating.
   * @function
   * @param {Object} downloadType - Object containing setting about the file we are downloading.
   * @param {string} dataType - Name of the dataType (kpi, adp etc.) we are downloading.
   * @param {string} name - Name of the itemview where we are downloading from.
   * @return {string} Returns file name.
   */
  function generateFilename(downloadType, dataType, name) {
    // prepare download type string
    if (downloadType.lastHundred) {
      downloadType = 'last100';
    }
    if (downloadType.selectInterval) {
      var from =
        new Date(downloadType.validFrom)
          .toLocaleDateString('en-GB')
          .replace(/\//g, '_') + '_';
      if (downloadType.timeTo) {
        from += downloadType.timeFrom.replace(/:/g, '_');
      } else {
        from += '00_00';
      }
      var to =
        new Date(downloadType.validTo)
          .toLocaleDateString('en-GB')
          .replace(/\//g, '_') + '_';
      if (downloadType.timeTo) {
        to += downloadType.timeTo.replace(/:/g, '_');
      } else {
        to += '00_00';
      }
      downloadType = 'from_' + from + '_to_' + to;
    }
    if (downloadType.selectLimit) {
      downloadType = 'limit_' + downloadType.numberOfValues.toString();
    }
    name = name.replace(/ /g, '_');
    return dataType + '_' + name + '_' + downloadType + '.csv';
  }

  function setTime(date, time) {
    var hours;
    var minutes;
    if (time) {
      var hoursAndMinutes = time.split(':');
      hours = Number(hoursAndMinutes[0]) * 60 * 60 * 1000;
      minutes = Number(hoursAndMinutes[1]) * 60 * 1000;
    } else {
      hours = 0;
      minutes = 0;
    }
    var timestamp = new Date(date).getTime() + hours + minutes;
    return timestamp;
  }

  return {
    showDownloadDialog: showDownloadDialog
  };
}
