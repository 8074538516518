StorageService.$inject = ['$q', 'AlertingService', '$state', '$smartAuth'];

/**
 * @ngdoc service
 * @name common.StorageService
 * @description Service for managing appConfig connected to SmartAccount or a similar service.
 * @property {function} getListRowPerPageData - See method getListRowPerPageData
 * @property {function} saveListRowPerPageParameter - See method saveListRowPerPageParameter
 * @property {function} getFilters - See method getFilters
 * @property {function} getListDisplayMode - See method getListDisplayMode
 * @property {function} saveListDisplayMode - See method saveListDisplayMode
 * @property {function} save - See method save
 * @property {function} get - See method get
 * @property {function} saveFilterDisplayStatus - See method saveFilterDisplayStatus
 * @property {function} getFilterDisplayStatus - See method getFilterDisplayStatus
 * @property {function} initiate - See method initiate
 * @property {function} getOrder - See method getOrder
 * @property {bool} initiated - indicates ether service is initiated or not
 * @property {function} checkIfInitiated - See method checkIfInitiated
 * @property {function} initiatedQuickcheck - See method initiatedQuickcheck
 * @property {function} saveListData - See method saveListData
 */

function StorageService($q, AlertingService, $state, $smartAuth) {
  var initiated = false;
  var StoredConfiguration;
  // stored parameter names
  var filterParameter = 'filterConfigurations';
  var orderParameter = 'order';
  var perPageParameter = 'perPage';
  var displayModeParameter = 'displayMode';
  var filterIsDisplayed = 'filterIsDisplayed';

  /*
   *	FLOW:
   *	At initialization, Service is called to get cloudConfig.
   */

  function checkIfInitiated() {
    var deferred = $q.defer();
    if (!initiated) {
      initiate().then(
        function() {
          deferred.resolve();
        },
        function() {
          deferred.resolve();
        }
      );
    } else {
      deferred.resolve();
    }
    return deferred.promise;
  }
  /**
   * @memberof common.initiatedQuickcheck
   * @description returns initiated value.
   * @return {Bool}
   */
  function initiatedQuickcheck() {
    return initiated;
  }
  /**
   * @memberof common.initiate
   * @description initaites service.
   * @return {Promise}
   */
  function initiate() {
    var deferred = $q.defer();
    getLocalStorageConfiguration().then(
      function(reconfigurations) {
        StoredConfiguration = reconfigurations || {};
        initiated = true;
        deferred.resolve();
      },
      function() {
        StoredConfiguration = {};
        initiated = true;
        deferred.resolve();
      }
    );
    return deferred.promise;
  }
  /**
   * @description gets user application configuration from local storage.
   * @function
   * @return {Promise}
   */
  function getLocalStorageConfiguration() {
    var deferred = $q.defer();
    $smartAuth.profile().then(
      function(user) {
        var configuration;
        try {
          configuration = JSON.parse(localStorage[user._id]);
        } catch (err) {
          configuration = {};
        }
        deferred.resolve(configuration);
      },
      function(err) {
        deferred.reject(err);
      }
    );
    return deferred.promise;
  }
  /**
   * @description stores app configuration to local storage.
   * @function
   */
  async function saveToLocalStorage() {
    await $smartAuth.profile().then(
      function(res) {
        var userId = res._id;
        localStorage[userId] = JSON.stringify(StoredConfiguration);
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }
  /**
   * @description checks if storage is initated and saves values.
   * @function
   * @param {String} param name of parameter
   * @param {Anly} data data to be saved
   * @return {dataType}
   */
  function save(param, data) {
    var deferred = $q.defer();
    checkIfInitiated().then(async function() {
      if (param) {
        StoredConfiguration[param] = data;
      }
      if (StoredConfiguration && $state.current.name !== 'unauthenticated') {
        await saveToLocalStorage();
      }
    });
    return deferred.promise;
  }
  /**
   * @description return configuration value by key
   * @function
   * @param {String} param value key
   * @return {Any}
   */
  function get(param) {
    if (Array.isArray(param)) {
      var config = saveConfiguration(param);
      return config;
    } else {
      return StoredConfiguration[param];
    }
  }
  /**
  * @description gets value to configuration. Expects parameter name to be in function context
  * @function
  * @param {String} id list id
=    */
  function generalGet(id) {
    var paramName = this.paramName;
    var defaultValue = this.defaultValue;
    if (paramName) {
      var state = $state.current.name;
      saveConfiguration([state, id]);
      if (!StoredConfiguration[state][id][paramName] && defaultValue) {
        StoredConfiguration[state][id][paramName] = defaultValue;
      }
      return StoredConfiguration[state][id][paramName];
    }
  }

  /**
   * @description sets value to configuration. Expects parameter name to be in function context
   * @function
   * @param {String} id list id
   * @param {Any} value value t0 set
   */
  function generalSet(id, value) {
    var paramName = this.paramName;

    if (paramName) {
      var state = $state.current.name;
      saveConfiguration([state, id]);
      StoredConfiguration[state][id][paramName] = value;
      save();
    }
  }
  /**
   * @description maps filter configurations by parameter name and value.
   * @function
   * @param {Array} filterConfigurations sfe-filter configuration
   * @return {Array}
   */
  function mapFilterConfiguration(filterConfigurations) {
    if (filterConfigurations) {
      var configToSave;
      return filterConfigurations.map(function(config) {
        configToSave = {
          param: config.param
        };
        if (config.uniqId) configToSave.uniqId = config.uniqId;
        switch (config.type) {
        case 'string':
        case 'number':
          configToSave.searchTerm = config.searchTerm;
          configToSave.paramType = config.paramType;
          break;
        case 'dateCompare':
          configToSave.date = config.date;
          configToSave.paramType = config.paramType;

          break;
        case 'customDate':
          configToSave.date = config.date;
          break;
        default:
          //dropdown selector
          configToSave.selected = config.selected;
        }
        return configToSave;
      });
    }
  }
  /**
   * @memberof common.Storage
   * @description assigns list properties (filters, order,limit) to user configuration.
   * @param {String} id list id
   * @param {Array} listFilters array of sfe-filters
   * @param {String} listOrder order parameter
   * @param {Number} listLimit list limit
   */
  function saveListData(id, listFilters, listOrder, listLimit) {
    var state = $state.current.name;
    saveConfiguration([state, id]);
    var configuration = StoredConfiguration[state][id];
    if (listFilters) {
      var parsedFilterConfiguration = mapFilterConfiguration(listFilters);
      configuration[filterParameter] = parsedFilterConfiguration;
    }

    if (listOrder) {
      configuration[orderParameter] = listOrder;
    }

    if (listLimit) {
      configuration[perPageParameter] = listLimit;
    }
    save();
  }
  /**
   * @description if configuration doesn't exist initiates it.
   * @function
   * @param {Array} parameters path to value
   * @return {Object}
   */
  function saveConfiguration(parameters) {
    var config = StoredConfiguration;
    parameters.forEach(function(param) {
      if (!config[param]) {
        config[param] = {};
      }
      config = config[param];
    });
    return config;
  }

  /**
   * @description Get Global cache.
   * @function
   * @return {bool}
   */
  function getGlobalCache() {
    try {
      return get('GLOBAL_CACHE');
    } catch (err) {
      return setGlobalCache(true);
    }
  }

  /**
   * @description Set Global cache.
   * @function
   */
  function setGlobalCache(value) {
    save('GLOBAL_CACHE', value);
    return value;
  }

  return {
    getListRowPerPageData: generalGet.bind({
      paramName: perPageParameter
    }),
    saveListRowPerPageParameter: generalSet.bind({
      paramName: perPageParameter
    }),
    getFilters: generalGet.bind({
      paramName: filterParameter
    }),
    getListDisplayMode: generalGet.bind({
      paramName: displayModeParameter,
      defaultValue: 'table'
    }),
    saveListDisplayMode: generalSet.bind({ paramName: displayModeParameter }),
    saveFilterDisplayStatus: generalSet.bind({
      paramName: filterIsDisplayed
    }),
    getFilterDisplayStatus: generalGet.bind({ paramName: filterIsDisplayed }),
    getOrder: generalGet.bind({ paramName: orderParameter }),
    save,
    get,
    initiate,
    initiated,
    checkIfInitiated,
    initiatedQuickcheck,
    saveListData,
    getGlobalCache,
    setGlobalCache
  };
}

export default StorageService;
