Refreshing.$inject = ['$interval'];

/**
 * @ngdoc service
 * @name data.Refreshing
 * @description Service to create interval refreshing function in an organized manner.
 * @property {function} newRefresher - creates new refresher function
 * @property {function} remove - deletes refresher
 * @property {function} removeFn - removes single function from a refresher
 * @property {function} addFn - adds a function to a refresher
 */
function Refreshing($interval) {
  var refreshers = {};

  /**
   * @description Generates an id for refreshers.
   * @function
   * @return {String}
   */
  function createId() {
    return Math.random()
      .toString()
      .replace(/0\./, '')
      .substr(0, 10);
  }

  /**
   * @description Returns new refresher.
   * @function
   * @param {Number} interval - interval in seconds
   * @param {Array<Function>} fnArray - array of functions to be called every interval
   * @return {String} id
   */
  function newRefresher(interval, fnArray) {
    var id = createId();
    refreshers[id] = createNewRefresherObject(id, interval, fnArray);
    return id;
  }

  /**
   * @description Adds a function to the refresher.
   * @function
   * @param {String} id - refresher id
   * @param {Function} fn - function to be added to refresher
   * @return {Object}
   * {
   *   id: refresherId
   *   fnId: functionId in the refresher
   * }
   */
  function addFn(id, fn) {
    if (typeof refreshers[id] !== 'undefined') {
      var fnId = createId();
      if (typeof fn === 'function') {
        refreshers[id].fnArray[fnId] = fn;
      }
      return {
        refresherId: id,
        refresherFnId: fnId
      };
    }
  }

  /**
   * @description Executes an array of functions in the refresher object.
   * @function
   * @param {Array<Function>} fnArray
   */
  function executeArrayFunctions(fnArray) {
    Object.keys(fnArray).forEach(function(fnKey) {
      fnArray[fnKey]();
    });
  }

  /**
   * @description Creates a new refresher object.
   * @function
   * @param {String} id
   * @param {Number} interval - in seconds
   * @return {Object}
   */
  function createNewRefresherObject(id, interval) {
    var obj = {
      id: id,
      interval: interval,
      fnArray: {}
    };

    obj.$intervalPromise = $interval(function() {
      executeArrayFunctions(obj.fnArray);
    }, interval * 1000);

    return obj;
  }

  /**
   * @description Deletes a refresher.
   * @function
   * @param {String} id - of refresher
   */
  function removeRefresher(id) {
    $interval.cancel(refreshers[id].$intervalPromise);
    delete refreshers[id];
  }

  /**
   * @description Removes a single function from a refresher.
   * @function
   * @param {String} id
   * @param {String} idFn
   */
  function removeFn(id, idFn) {
    if (refreshers[id]) {
      delete refreshers[id].fnArray[idFn];
    }
  }

  return {
    newRefresher,
    removeRefresher,
    removeFn,
    addFn
  };
}

export default Refreshing;
