import './rule-alarms-view.scss';
import template from './rule-alarms-view.component.html';

/**
 * @ngdoc component
 * @name common.ruleAlarmsView
 * @description Forms a list of action buttons
 * @param {Array} actions - Array of specified actions to be displayed
 * @example
 * <rule-alarms-view
 *   actions="vm.actions"
 * ></rule-alarms-view
 */

export default {
  template,
  bindings: {
    rules: '<',
    isPopulated: '<',
    ruleCharacteristics: '<',
    noItemsLabel: '<',
    rulesTitle: '<',
    noStrippedBackground: '<',
    noTitle: '<'
  },
  controllerAs: 'vm',
  controller: RuleAlarmsViewController
};

RuleAlarmsViewController.$inject = ['$scope', 'RuleModel', 'AlarmModel'];

function RuleAlarmsViewController($scope, RuleModel, AlarmModel) {
  const vm = this;

  vm.$onChanges = changes => {
    if (changes.rules && Array.isArray(vm.rules) && vm.rules.length > 0) {
      if (!vm.isPopulated) {
        populateRuleAlarms();
      } else {
        vm.ruleAlarms = vm.rules;
      }
    } else {
      vm.ruleAlarms = [];
    }
  };
  /**
   * @description helper method to filter only unique items
   * @function
   * @return {bool} item exist
   */
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  async function populateRuleAlarms() {
    const ruleIds = vm.rules
      .map(rule => {
        return rule.rule;
      })
      .filter(onlyUnique);

    const alarmIds = vm.rules
      .reduce((result, rule) => {
        const alarms = rule.alarms.map(alarm => alarm.alarm);
        return result.concat(alarms);
      }, [])
      .filter(onlyUnique);

    const { data: fetchedRules } = await RuleModel.read({ _id: ruleIds });
    const { data: fetchedAlarms } = await AlarmModel.read({ _id: alarmIds });
    vm.ruleAlarms = vm.rules.reduce((result, rule) => {
      let alarms = rule.alarms.map(alarm => {
        return {
          ...alarm,
          alarmObject: fetchedAlarms.find(item => item._id == alarm.alarm)
        };
      });

      return result.concat([
        {
          ...rule,
          ruleObject: fetchedRules.find(item => item._id == rule.rule),
          alarms
        }
      ]);
    }, []);
    $scope.$applyAsync();
  }
}
