module.exports = {
  entity: 'x-axis-intervals',
  path: 'x-axis-intervals',
  name: {
    en: 'X axis interval',
    sl_SI: 'interval X osi'
  },
  networkModel: {
    entity: 'x-axis-intervals',
    read: 'read'
  },
  scope: 'tis_be:x-axis-intervals',

  dialog: {
    title: {
      en: 'Select X axis interval',
      sl_SI: 'Izberite interval X osi'
    },
    listInfo: {
      en: 'Select X axis interval from the list below',
      sl_SI: 'Izberite cilj za interval X osi'
    }
  }
}
