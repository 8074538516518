companyStructureNodeConfiguration.$inject = [
  'gettext',
  '$q',
  '$timeout',
  'TranslationService',
  'HierarchyTreeDialog'
];

function companyStructureNodeConfiguration(
  gettext,
  $q,
  $timeout,
  TranslationService,
  HierarchyTreeDialog
) {
  var configuration = {
    entity: {
      singular: gettext('Company Structure Node'),
      plural: gettext('Company Structure Nodes')
    },
    api: {
      query: {
        entity: 'company-structure-nodes',
        method: 'custom.list'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'configurations-company-resources-company-structure-nodes-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    treeView: {
      entity: 'company-structure-nodes'
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'configurations-company-resources-company-structure-nodes-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'info_outline',
        action: function(firstItem) {
          HierarchyTreeDialog.openDialog(firstItem, 'company-structure-nodes');
        }
      },
      {
        icon: 'mode_edit',
        link: {
          state:
            'configurations-company-resources-company-structure-nodes-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'company-structure-nodes',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Description'),
        param: 'description',
        type: 'text'
      },
      {
        param: 'code',
        title: gettext('Code'),
        type: 'code'
      },
      {
        param: 'externalCode',
        title: gettext('External code'),
        type: 'code'
      },
      {
        param: 'type',
        title: gettext('Type')
      },
      {
        param: 'parentNode',
        title: gettext('Parent node')
      }
    ],
    filter: [
      {
        display: gettext('Name'),
        param: 'name',
        type: 'string'
      },
      {
        display: gettext('Code'),
        param: 'code',
        type: 'string'
      },
      {
        display: gettext('External Code'),
        param: 'externalCode',
        type: 'string'
      },
      {
        collection: TranslationService.GetCollection(
          'codelists.costCentreTypes'
        ).filter(item => {
          // Filter costCenterTypes by allowed entities
          // 228 is codelist id of companyStructureNode entity
          return item.allowedEntities.indexOf(228) > -1;
        }),
        display: gettext('Cost Centre Type'),
        param: 'type',
        valueField: 'id'
      },
      {
        query: {
          entity: 'company-structure-nodes',
          method: 'read'
        },
        entity: 'company-structure-nodes',
        display: gettext('Parent'),
        param: 'parentId',
        displayList: ['name', 'code'],
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    item.type = TranslationService.GetCollectionById(
      'codelists.costCentreTypes',
      item.type
    ).name;
    if (item.parentId) {
      item.parentNode = item.parentId.name;
    }
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default companyStructureNodeConfiguration;
