import './sfe-form-2.scss';
import template from './sfe-form-2.component.html';

/**
 * @ngdoc component
 * @name common.sfeForm2
 * @description Component for display and handling of the form.
 * @param {Object} config - configuration for form and its elements
 * @param {Object} api - Will be set to reference the apis of sfeForm2
 * @example
 * <sfe-form-2
 *   config="vm.config"
 *   api="vm.formApi"
 * ></sfe-form-2>
 */

export default {
  template,
  bindings: {
    config: '<',
    api: '<'
  },
  controller: SfeForm2Controller,
  controllerAs: 'vm',
  bindToController: true
};

SfeForm2Controller.$inject = [];
function SfeForm2Controller() {
  let vm = this;

  vm.invalidData = false;
  vm.error = {};
  vm.data = {};
  vm.fields = [];

  vm.$onChanges = () => {
    if (
      vm.config != null &&
      typeof vm.config === 'object' &&
      Array.isArray(vm.config.fields) &&
      vm.config.fields.length > 0
    ) {
      vm.config.name = vm.config.name.replace(' ', '_');
      vm.error.invalidData = false;
      vm.fields = validateFields(vm.config.fields);

      vm.data = initializeData(vm.fields);
    } else {
      vm.fields = [];
      vm.error.invalidData = true;
    }

    if (vm.api != null && typeof vm.api === 'object') {
      setApi(vm.api);
    }
    if (vm.config != null && Array.isArray(vm.config.actions)) {
      vm.actions = getActions(vm.config.actions);
    }
  };

  /**
   * @description Returns an api object.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  const setApi = api => {
    api.setFieldConfiguration = setFieldConfiguration;
    api.setFieldConfigurationProperty = setFieldConfigurationProperty;
    api.getFieldConfiguration = getFieldConfiguration;
    api.getFieldConfigurationProperty = getFieldConfigurationProperty;
    api.getFieldConfigurations = getFieldConfigurations;
    api.setValue = setValue;
    api.setValues = setValues;
    api.getValue = getValue;
    api.getValues = getValues;
    api.validity = validity;
    api.formValidity = formValidity;
    api.revalidate = revalidate;
    api.resetData = resetData;
    api.resetFields = resetFields;
    api.setDirty = setDirty;
  };

  function getActions(actions) {
    if (Array.isArray(actions)) {
      let api = {};
      setApi(api);
      return actions.map(action => {
        let fn;
        let disabledFn;
        if (typeof action.fn == 'function') {
          fn = action.fn.bind(undefined, { ...api });
        }
        if (typeof action.disabledFn == 'function') {
          disabledFn = action.disabledFn.bind(undefined, { ...api });
        }
        return {
          ...action,
          fn,
          disabledFn
        };
      });
    }
    return [];
  }

  /**
   * @description Returns a clean initialized data object. Expects input fields to already be validated.
   * @function
   * @param {object} field
   * @return {object}
   */
  const initializeData = fields => {
    return fields.reduce((initData, field) => {
      return {
        ...initData,
        [field.id]: field.initialize ? field.initialize() : null
      };
    }, {});
  };

  /**
   * @description Validate all fields.
   * @param {array} configurations
   * @function
   */
  const validateFields = configurations => {
    return configurations.reduce((validConfigurations, field) => {
      let valid = true;
      let cleanConfiguration = {};
      let api = {};
      setApi(api);

      // id
      if (field.id != null && typeof field.id == 'string') {
        cleanConfiguration.id = field.id;
      } else {
        valid = false;
      }

      // type
      if (
        field.type != null &&
        typeof field.type == 'object' &&
        typeof field.type.name == 'string'
        /* TODO: check if type name is in templates */
      ) {
        cleanConfiguration.type = angular.copy(field.type);
      } else {
        valid = false;
      }

      // validators
      if (field.validators != null && typeof field.validators == 'object') {
        cleanConfiguration.validators = Object.entries(field.validators).reduce(
          (allValidators, validator) => {
            let validatorName = validator[0];
            let validatorConfig = validator[1];
            return [
              ...allValidators,
              {
                errorMessage: validatorConfig.text,
                fn: bind_trailing_args(validatorConfig.fn, api),
                name: validatorName
              }
            ];
          },
          []
        );
      }
      // actions
      if (Array.isArray(field.actions)) {
        cleanConfiguration.actions = field.actions.map(action => {
          let fn;
          let disabledFn;
          if (typeof action.fn == 'function') {
            fn = action.fn.bind(undefined, { ...api });
          }
          if (typeof action.disabledFn == 'function') {
            disabledFn = action.disabledFn.bind(undefined, { ...api });
          }
          return {
            ...action,
            fn,
            disabledFn
          };
        });
      }
      // init
      if (typeof field.initialize == 'function') {
        cleanConfiguration.initialize = field.initialize.bind(undefined, {
          ...api
        });
      }

      // disable
      if (typeof field.disable == 'function') {
        cleanConfiguration.disable = field.disable.bind(undefined, { ...api });
      }

      // hide
      if (typeof field.hide == 'function') {
        cleanConfiguration.hide = field.hide.bind(undefined, { ...api });
      }

      // hide
      if (typeof field.onChange == 'function') {
        cleanConfiguration.onChange = field.onChange.bind(undefined, {
          ...api
        });
      }
      // width
      if (typeof field.width == 'number') {
        cleanConfiguration.width = Math.min(
          Math.max(Math.floor(field.width), 1),
          12
        );
      } else {
        cleanConfiguration.width = 12;
      }

      // title
      if (typeof field.title == 'string') {
        cleanConfiguration.title = field.title;
      }

      // required
      if (typeof field.required == 'boolean') {
        cleanConfiguration.required = field.required;
      }

      if (valid) {
        return [...validConfigurations, cleanConfiguration];
      } else {
        return validConfigurations;
      }
    }, []);
  };

  /**
   * @description Sets a single field.
   * @function
   * @param {string} fieldId
   * @param {any} value
   * @return {any} - returns the value
   */
  const setFieldConfiguration = (fieldId, value) => {
    let field = findField(vm.fields, fieldId);
    if (field) {
      field = angular.copy(value);
    }
  };

  /**
   * @description Sets a single field.
   * @function
   * @param {string} fieldId
   * @param {string} property property name string separated by dots
   * @param {any} value
   * @return {any} - returns the value
   */
  const setFieldConfigurationProperty = (fieldId, property, value) => {
    let field = findField(vm.fields, fieldId);
    if (field) {
      let properties = property.split('.');
      let propertyObject = field;
      properties.forEach((propertyName, index) => {
        if (index <= properties.length - 2) {
          if (propertyObject != null && typeof propertyObject == 'object') {
            propertyObject = propertyObject[propertyName];
          }
        } else {
          propertyObject[propertyName] = angular.copy(value);
        }
      });
    }
  };

  /**
   * @description Sets a single field.
   * @function
   * @param {string} fieldId
   * @param {string} property
   * @return {any} - returns the value
   */
  const getFieldConfigurationProperty = (fieldId, property) => {
    let field = angular.copy(vm.fields.find(field => fieldId === field.id));
    if (field != null) {
      return field[property];
    }
  };

  /**
   * @description Returns a field configuration.
   * @function
   * @param {string} fieldId
   * @return {object}
   */
  const getFieldConfiguration = fieldId => {
    return angular.copy(vm.fields.find(field => fieldId === field.id));
  };

  /**
   * @description Return all valid field configurations.
   * @function
   * @return {array}
   */
  const getFieldConfigurations = () => {
    return angular.copy(vm.fields);
  };

  /**
   * @description Sets a new value on a field.
   * @function
   * @param {string} fieldId
   * @return {any}
   */
  const setValue = (fieldId, value) => {
    // only set fields that are defined
    let field = findField(vm.fields, fieldId);
    if (field) {
      vm.data[fieldId] = angular.copy(value);
    }
  };

  /**
   * @description Returns a value from data.
   * @function
   * @param {string} fieldId
   * @return {any}
   */
  const getValue = fieldId => {
    return angular.copy(vm.data[fieldId]);
  };

  /**
   * @description Returns all data held in this form.
   * @function
   * @return {object}
   */
  const getValues = () => {
    return angular.copy(vm.data);
  };

  /**
   * @description Revalidates whole form.
   * @function
   */
  const revalidate = () => {
    vm.formScope.$$controls.forEach(control => {
      control.$validate();
    });
  };

  /**
   * @description Returns whether a field data is valid.
   * @function
   * @param {string} fieldId
   * @return {boolean}
   */
  const validity = fieldId => {
    return vm.formScope[fieldId] ? vm.formScope[fieldId].$valid : false;
  };

  /**
   * @description Returns validity of the whole form.
   * @function
   * @return {boolean}
   */
  const formValidity = () => {
    return vm.formScope != null ? vm.formScope.$valid : false;
  };

  /**
   * @description Returns a field if it exists in an array
   * @function
   * @param {array} fields
   * @param {string} id
   * @return {undefined|object}
   */
  const findField = (fields, id) => fields.find(field => field.id == id);

  // Bind arguments starting after however many are passed in.
  function bind_trailing_args(fn, ...bound_args) {
    return function(...args) {
      return fn(...args, ...bound_args);
    };
  }

  /**
   * @description remove properties from vm.data -> {}.
   * @function
   */
  const resetData = () => {
    const keys = Object.keys(vm.data);
    keys.forEach(key => {
      delete vm.data[key];
    });
  };

  /**
   * @description remove fields from vm.fields -> [].
   * @function
   */
  const resetFields = () => {
    vm.fields = [];
  };

  /**
   * @description Set fields value from api.
   * @function
   * @param {Object} data
   */
  const setValues = data => {
    if (data != null) {
      // Convert data object to array and set each fields value by its name.
      Object.entries(data).forEach(([name, value]) => {
        setValue(name, value);
      });
    }
  };
  /**
   * @description Set input to dirty and touched state.
   * @function
   * @param {string} id
   */
  const setDirty = id => {
    if (vm.formScope != null && id != null && vm.formScope[id] != null) {
      vm.formScope[id].$setTouched();
      vm.formScope[id].$setDirty();
    }
  };
}
