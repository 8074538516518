import './sfe-dnd-autocomplete.scss';
import template from './sfe-dnd-autocomplete.component.html';

/**
 * @ngdoc component
 * @name common.sfeDndAutocomplete
 * @description displays autocomplete dialog to select items and adds selected items in dnd ordered list.
 * @param {Object} configuration - autocomplete configuration
 * @param {Array} model - array of selected items (on edit expexts array of items id)
 * @param {bool} edit - indicates edit mode
 * @example
 * <sfe-dnd-autocomplete
 *     configuration="configuration"
 *     edit="edit"
 *     model="model"
 * ></sfe-dnd-autocomplete>
 */

export default {
  template,
  bindings: {
    configuration: '<',
    required: '<',
    edit: '<'
  },
  require: {
    form: '^form',
    model: 'ngModel'
  },
  controllerAs: 'vm',
  controller: sfeDndAutocompleteController
};

sfeDndAutocompleteController.$inject = [
  '$scope',
  'CrawlerMethods',
  'AlertingService',
  'EntitiesService',
  '$timeout'
];

function sfeDndAutocompleteController(
  $scope,
  CrawlerMethods,
  AlertingService,
  EntitiesService,
  $timeout
) {
  var vm = this;

  vm.removeItem = removeItem;

  function setValidity() {
    vm.model.$setViewValue(vm.selectedItems);
    if (vm.required) {
      vm.model.$setValidity('required', vm.selectedItems.length > 0);
    }
  }
  /**
   * @description removes selected items.
   * @function
   * @param {number} index selected item index
   */
  function removeItem(index) {
    vm.selectedItems.splice(index, 1);
    setValidity();
  }
  /**
   * @description Fetches and sets selected item on edit mode.
   * @function
   */
  function fetchSelectedItems() {
    var method = CrawlerMethods.getMethod(vm.configuration.query);
    if (method) {
      method({ _id: vm.selectedItems }).then(
        function(res) {
          var itemsInUse = [];
          //   check in case of be _id filter fails
          var foundItem;
          vm.selectedItems.forEach(function(itemId) {
            foundItem = res.data.find(function(item) {
              return item._id === itemId;
            });
            if (foundItem) {
              itemsInUse.push(foundItem);
            }
          });

          vm.selectedItems = itemsInUse;
          setValidity();
        },
        function(err) {
          AlertingService.Error(err);
        }
      );
    }
  }

  vm.$onInit = () => {
    vm.model.$render = () => {
      vm.selectedItems = vm.model.$viewValue;
    };
  };

  /**
   * @description component binding watcher. Sets autocomplete configuration callback functions and triggers edit items fetching
   * @function
   */
  vm.$onChanges = function(changes) {
    if (changes.edit && vm.edit) {
      if (vm.selectedItems && vm.selectedItems.length) {
        fetchSelectedItems();
      } else {
        if (!watcher) {
          var watcher = $scope.$watch('vm.selectedItems', function() {
            if (vm.selectedItems && vm.selectedItems.length > 0) {
              fetchSelectedItems();
              watcher();
            }
          });
        }
      }
    }
    if (changes.configuration && vm.configuration) {
      var entity =
        vm.configuration && vm.configuration.entity
          ? vm.configuration.entity[0]
          : null;
      if (entity) {
        vm.displayFields = EntitiesService.getDisplayFieldsByPath(entity) || [
          'name'
        ];
      }
      if (vm.configuration.displayFields) {
        vm.displayFields = vm.configuration.displayFields;
      }
      vm.configuration.dialogConfiguration = {
        ...vm.configuration.dialogConfiguration,
        multiple: true
      };
      /**
       * @description filters selected items.
       * @function
       * @param {Array} items fetched items
       * @return {Array} filtered items
       */
      vm.configuration.filterFn = function(items) {
        // inject filter func to hide items that are selected
        if (items && vm.selectedItems) {
          var foundItem;
          items = items.filter(function(item) {
            foundItem = vm.selectedItems.find(function(selectedItem) {
              return selectedItem._id === item._id;
            });
            return !foundItem;
          });
        }
        return items;
      };

      // inject on change function to add selected items to dnd list and reset autocomplete
      /**
       * @description triggers when item is selected and then adds item to dnd list.
       * @function
       * @param {Array} items selected items
       */
      vm.configuration.change = function(items) {
        if (items) {
          if (!vm.selectedItems) {
            vm.selectedItems = [];
          }
          var foundItem;
          vm.selectedItems = vm.selectedItems.concat(
            items.filter(function(item) {
              if (item) {
                foundItem = vm.selectedItems.find(function(selectedItem) {
                  return selectedItem._id === item._id;
                });

                return !foundItem;
              }
            })
          );
          $timeout(function() {
            vm.selectedItem = null;
          });
          setValidity();
        }
      };
    }
  };
}
