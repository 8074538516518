module.exports = {
  entity: 'dataTypes',
  path: 'dataTypes',
  name: {
    en: 'Data type',
    sl_SI: 'Podatkovni tip'
  },
  codelist: 'dataTypes',
  scope: 'tis_be:dataTypes',

  dialog: {
    title: {
      en: 'Select data type',
      sl_SI: 'Izberite podatkovni tip'
    },
    listInfo: {
      en: 'Select data type from the list below',
      sl_SI: 'Izberite podatkovni tip s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      }
    ],
    codelist: 'dataTypes'
  }
}
