import locationView from './pages/location/location.view.html';
import assetView from './pages/asset/asset.view.html';
import measuringPointView from './pages/measuring-point/measuring-point.view.html';
import businessPartner from './pages/business-partner/business-partner.view.html';
import treeStructureView from './pages/tree-structure/tree-structure.view.html';

import { RouteManager, gettext } from '../routes';

import SfeListView from '../common/components/sfe-list/sfe-list.view.html';
import NewFormBasicView from '../common/views/new-form-basic.view.html';
import ItemHeaderView from '../common/views/item-header.view.html';

loadEntity.$inject = ['$transition$', 'StateResolveService'];
function loadEntity($transition$, StateResolveService) {
  return StateResolveService.fetchEntityForView($transition$);
}

loadCharts.$inject = ['loadAssets'];
function loadCharts(loadAssets) {
  return loadAssets(['highcharts']);
}

let CompanyResourcesRouteManager = new RouteManager();
CompanyResourcesRouteManager
  // DASHBOARD for DATA MODULE
  .state('company-resources', {
    url: '/company-resources',
    isMainMenuItem: true,
    pageTitle: gettext('Company Resources'),
    icon: 'business',
    controllerAs: 'vm',
    theme: 'tango',
    redirectTo: 'company-resources-locations-list',
    menuIndex: 9,
    accessScopeFe: 'tis_fe:company-resources-module',
    accessScopeBe: 'tis_fe:company-resources-module'
  })
  // 1. LIST OF COST CENTRES
  .state('company-resources-cost-centres-list', {
    url: '/company-resources/cost-centres/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Cost Centres'),
    icon: 'company-resources_on',
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:cost-centres',
    accessScopeBe: 'tis_be:cost-centres'
  })
  // 2. LIST of PROFIT CENTRES
  .state('company-resources-profit-centres-list', {
    url: '/company-resources/profit-centres/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Profit Centres'),
    icon: 'router',
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:profit-centres',
    accessScopeBe: 'tis_be:profit-centres'
  })
  // 3. LIST OF CONTRACTS
  .state('company-resources-projects-list', {
    url: '/company-resources/projects/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Projects'),
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:projects',
    accessScopeBe: 'tis_be:projects'
  })
  // 4. LIST OF LOCATIONS
  .state('company-resources-locations-list', {
    url: '/company-resources/locations/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Locations'),
    icon: 'location_on',
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:locations',
    accessScopeBe: 'tis_be:locations'
  })
  // 5. LIST OF ASSETS
  .state('company-resources-assets-list', {
    url: '/company-resources/assets/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Assets'),
    icon: 'company-resources_on',
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:assets',
    accessScopeBe: 'tis_be:assets'
  })
  // 6. LIST OF MEASUREMENT POINTS
  .state('company-resources-measuring-points-list', {
    url: '/company-resources/measuring-points/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Measuring Points'),
    icon: 'company-resources_on',
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:measuring-points',
    accessScopeBe: 'tis_be:measuring-points'
  })
  // 8. LIST OF BUSINESS PARTNERS
  .state('company-resources-business-partners-list', {
    url: '/company-resources/business-partners/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Business Partners'),
    icon: 'company-resources_on',
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:business-partners',
    accessScopeBe: 'tis_be:business-partners'
  })
  // 9. COMPANY STRUCTURE
  .state('company-resources-company-structure', {
    url: '/company-resources/company-structure',
    template: treeStructureView,
    controller: 'CompanyStructureController',
    controllerAs: 'vm',
    params: {
      treeRoute: 'company-structure-nodes'
    },
    parentState: 'company-resources',
    isSubMenuItem: true,
    pageTitle: gettext('Company Structure'),
    theme: 'tango',
    accessScopeFe: 'tis_fe:company-structure',
    accessScopeBe: 'tis_be:cost-centres'
  })
  // 10. LOCATIONS OVERVIEW
  .state('company-resources-locations-overview', {
    url: '/company-resources/locations-overview',
    template: treeStructureView,
    controller: 'LocationsOverviewController',
    controllerAs: 'vm',
    parentState: 'company-resources',
    isSubMenuItem: true,
    pageTitle: gettext('Locations Overview'),
    theme: 'tango',
    accessScopeFe: 'tis_fe:locations-overview',
    accessScopeBe: 'tis_be:cost-centres'
  })
  // NEW LOCATION
  .state('company-resources-locations-new', {
    url: '/company-resources/locations/new',
    template: NewFormBasicView,
    controller: 'NewLocationFormController',
    controllerAs: 'vm',
    pageTitle: gettext('New Location'),
    icon: 'radio_button_checked',
    iconMode: 'add',
    parentState: 'company-resources',
    params: {
      item: null
    },
    theme: 'tango',
    accessScopeFe: 'tis_fe:locations',
    accessScopeBe: 'tis_be:locations',
    mode: 'create',
    resolve: {
      location: () => ({})
    }
  })
  // DUPLICATE LOCATION
  .state('company-resources-locations-duplicate', {
    url: '/company-resources/locations/{id}/duplicate',
    template: NewFormBasicView,
    controller: 'NewLocationFormController',
    controllerAs: 'vm',
    pageTitle: gettext('Duplicate Location'),
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:locations',
    accessScopeBe: 'tis_be:locations',
    mode: 'create',
    entity: 'locations',
    resolve: {
      location: loadEntity
    }
  })
  // EDIT LOCATION
  .state('company-resources-locations-edit', {
    url: '/company-resources/locations/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewLocationFormController',
    controllerAs: 'vm',
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:locations',
    accessScopeBe: 'tis_be:locations',
    mode: 'update',
    entity: 'locations',
    resolve: {
      location: loadEntity
    }
  })
  // VIEW LOCATION
  .state('company-resources-locations-view', {
    url: '/company-resources/locations/{id}',
    template: locationView,
    controller: 'LocationController',
    controllerAs: 'vm',
    parentState: 'company-resources',
    pageTitle: gettext('Location'),
    theme: 'tango',
    accessScopeFe: 'tis_fe:locations',
    accessScopeBe: 'tis_be:locations',
    mode: 'read',
    entity: 'locations',
    resolve: {
      location: loadEntity
    }
  })
  // NEW ASSET
  .state('company-resources-assets-new', {
    url: '/company-resources/assets/new',
    template: NewFormBasicView,
    controller: 'NewAssetFormController',
    controllerAs: 'vm',
    pageTitle: gettext('New Asset'),
    icon: 'radio_button_checked',
    iconMode: 'add',
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:assets',
    accessScopeBe: 'tis_be:assets',
    mode: 'create',
    entity: 'assets',
    resolve: {
      asset: () => ({})
    }
  })
  // DUPLICATE ASSET
  .state('company-resources-assets-duplicate', {
    url: '/company-resources/assets/{id}/duplicate',
    template: NewFormBasicView,
    controller: 'NewAssetFormController',
    controllerAs: 'vm',
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:assets',
    accessScopeBe: 'tis_be:assets',
    mode: 'create',
    entity: 'assets',
    resolve: {
      asset: loadEntity
    }
  })
  // EDIT ASSET
  .state('company-resources-assets-edit', {
    url: '/company-resources/assets/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewAssetFormController',
    controllerAs: 'vm',
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:assets',
    accessScopeBe: 'tis_be:assets',
    mode: 'update',
    entity: 'assets',
    resolve: {
      asset: loadEntity
    }
  })
  // VIEW ASSET
  .state('company-resources-assets-view', {
    url: '/company-resources/assets/{id}',
    template: assetView,
    controller: 'AssetController',
    controllerAs: 'vm',
    pageTitle: gettext('Asset'),
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:assets',
    accessScopeBe: 'tis_be:assets',
    mode: 'read',
    entity: 'assets',
    resolve: {
      asset: loadEntity,
      loadCharts
    }
  })
  // NEW MEASURING POINT
  .state('company-resources-measuring-points-new', {
    url: '/company-resources/measuring-points/new?locationId',
    template: NewFormBasicView,
    controller: 'NewMeasuringPointFormController',
    controllerAs: 'vm',
    pageTitle: gettext('New Measuring Point'),
    icon: 'radio_button_checked',
    iconMode: 'add',
    parentState: 'company-resources',
    params: {
      item: null
    },
    theme: 'tango',
    accessScopeFe: 'tis_fe:measuring-points',
    accessScopeBe: 'tis_be:measuring-points',
    mode: 'create',
    entity: 'measuringpoints',
    resolve: {
      measuringPoint: () => ({})
    }
  })
  // EDIT MEASURING POINT
  .state('company-resources-measuring-points-edit', {
    url: '/company-resources/measuring-points/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewMeasuringPointFormController',
    controllerAs: 'vm',
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:measuring-points',
    accessScopeBe: 'tis_be:measuring-points',
    mode: 'update',
    entity: 'measuringpoints',
    resolve: {
      measuringPoint: loadEntity
    }
  })
  // DUPLICATE MEASURING POINT
  .state('company-resources-measuring-points-duplicate', {
    url: '/company-resources/measuring-points/{id}/duplicate',
    template: NewFormBasicView,
    controller: 'NewMeasuringPointFormController',
    controllerAs: 'vm',
    pageTitle: gettext('Duplicate Measuring Point'),
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:measuring-points',
    accessScopeBe: 'tis_be:measuring-points',
    mode: 'create',
    entity: 'measuringpoints',
    resolve: {
      measuringPoint: loadEntity
    }
  })
  // VIEW MEASUREMENT POINT
  .state('company-resources-measuring-points-view', {
    url: '/company-resources/measuring-points/{id}',
    template: measuringPointView,
    controller: 'MeasuringPointController',
    controllerAs: 'vm',
    pageTitle: gettext('Measuring Point'),
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:measuring-points',
    accessScopeBe: 'tis_be:measuring-points',
    mode: 'read',
    entity: 'measuringpoints',
    resolve: {
      measuringPoint: loadEntity
    }
  })
  // EDIT BUSINESS PARNER
  .state('company-resources-business-partners-edit', {
    url: '/company-resources/business-partners/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewBusinessPartnerFormController',
    controllerAs: 'vm',
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:business-partners',
    accessScopeBe: 'tis_be:business-partners',
    mode: 'update',
    entity: 'business-partners',
    resolve: {
      businessPartner: loadEntity
    }
  })
  .state('company-resources-business-partners-duplicate', {
    url: '/company-resources/business-partners/{id}/duplicate',
    template: NewFormBasicView,
    controller: 'NewBusinessPartnerFormController',
    controllerAs: 'vm',
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:business-partners',
    accessScopeBe: 'tis_be:business-partners',
    mode: 'create',
    entity: 'business-partners',
    resolve: {
      businessPartner: loadEntity
    }
  })
  // NEW BUSINESS PARTNER
  .state('company-resources-business-partners-new', {
    url: '/company-resources/business-partners/new',
    template: NewFormBasicView,
    controller: 'NewBusinessPartnerFormController',
    controllerAs: 'vm',
    pageTitle: gettext('New Business Partner'),
    icon: 'radio_button_checked',
    iconMode: 'add',
    parentState: 'company-resources',
    params: {
      item: null
    },
    theme: 'tango',
    accessScopeFe: 'tis_fe:business-partners',
    accessScopeBe: 'tis_be:business-partners',
    mode: 'create',
    entity: 'business-partners',
    resolve: {
      businessPartner: () => ({})
    }
  })
  // VIEW BUSINESS PARTNER
  .state('company-resources-business-partners-view', {
    url: '/company-resources/business-partners/{id}',
    template: businessPartner,
    controller: 'BusinessPartnerController',
    controllerAs: 'vm',
    pageTitle: gettext('Business Partner'),
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:business-partners',
    accessScopeBe: 'tis_be:business-partners',
    mode: 'read',
    entity: 'business-partners',
    resolve: {
      businessPartner: loadEntity
    }
  })
  // EDIT COST CENTRE
  .state('company-resources-cost-centres-edit', {
    url: '/company-resources/cost-centres/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewCostCentreFormController',
    controllerAs: 'vm',
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:cost-centres',
    accessScopeBe: 'tis_be:cost-centres',
    mode: 'update',
    entity: 'cost-centres',
    resolve: {
      costCentre: loadEntity
    }
  })
  // NEW COST CENTRE
  .state('company-resources-cost-centres-new', {
    url: '/company-resources/cost-centres/new',
    template: NewFormBasicView,
    controller: 'NewCostCentreFormController',
    controllerAs: 'vm',
    pageTitle: gettext('New Cost Centre'),
    icon: 'radio_button_checked',
    iconMode: 'add',
    parentState: 'company-resources',
    params: {
      item: null
    },
    theme: 'tango',
    accessScopeFe: 'tis_fe:cost-centres',
    accessScopeBe: 'tis_be:cost-centres',
    mode: 'create',
    entity: 'cost-centres',
    resolve: {
      costCentre: () => ({})
    }
  })
  // VIEW COST CENTRE
  .state('company-resources-cost-centres-view', {
    url: '/company-resources/cost-centres/{id}',
    template: ItemHeaderView,
    controller: 'CostCentreController',
    controllerAs: 'vm',
    parentState: 'company-resources',
    pageTitle: gettext('Cost Centre'),
    theme: 'tango',
    accessScopeFe: 'tis_fe:cost-centres',
    accessScopeBe: 'tis_be:cost-centres',
    mode: 'read',
    entity: 'cost-centres',
    resolve: {
      costCentre: loadEntity
    }
  })
  // EDIT COST CENTRE
  .state('company-resources-profit-centres-edit', {
    url: '/company-resources/profit-centres/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewProfitCentreController',
    controllerAs: 'vm',
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:profit-centres',
    accessScopeBe: 'tis_be:profit-centres',
    mode: 'update',
    entity: 'profit-centres',
    resolve: {
      profitCentre: loadEntity
    }
  })
  // NEW COST CENTRE
  .state('company-resources-profit-centres-new', {
    url: '/company-resources/profit-centres/new',
    template: NewFormBasicView,
    controller: 'NewProfitCentreController',
    controllerAs: 'vm',
    pageTitle: gettext('New Profit Centre'),
    icon: 'radio_button_checked',
    iconMode: 'add',
    parentState: 'company-resources',
    params: {
      item: null
    },
    theme: 'tango',
    accessScopeFe: 'tis_fe:profit-centres',
    accessScopeBe: 'tis_be:profit-centres',
    mode: 'create',
    entity: 'profit-centres',
    resolve: {
      profitCentre: () => ({})
    }
  })
  // VIEW PROFIT CENTRE
  .state('company-resources-profit-centres-view', {
    url: '/company-resources/profit-centres/{id}',
    template: ItemHeaderView,
    controller: 'ProfitCentreController',
    controllerAs: 'vm',
    parentState: 'company-resources',
    pageTitle: gettext('Profit Centre'),
    theme: 'tango',
    accessScopeFe: 'tis_fe:profit-centres',
    accessScopeBe: 'tis_be:profit-centres',
    mode: 'read',
    entity: 'profit-centres',
    resolve: {
      profitCentre: loadEntity
    }
  })
  // NEW CONTRACT
  .state('company-resources-projects-new', {
    url: '/company-resources/projects/new',
    template: NewFormBasicView,
    controller: 'NewProjectFormController',
    controllerAs: 'vm',
    pageTitle: gettext('New Project'),
    icon: 'radio_button_checked',
    iconMode: 'add',
    parentState: 'company-resources',
    params: {
      item: null
    },
    theme: 'tango',
    accessScopeFe: 'tis_fe:projects',
    accessScopeBe: 'tis_be:projects',
    mode: 'create',
    entity: 'projects',
    resolve: {
      project: () => ({})
    }
  })
  // EDIT CONTRACT
  .state('company-resources-projects-edit', {
    url: '/company-resources/projects/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewProjectFormController',
    controllerAs: 'vm',
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:projects',
    accessScopeBe: 'tis_be:projects',
    mode: 'update',
    entity: 'projects',
    resolve: {
      project: loadEntity
    }
  })
  // DUPLICATE CONTRACT
  .state('company-resources-projects-duplicate', {
    url: '/company-resources/projects/{id}/duplicate',
    template: NewFormBasicView,
    controller: 'NewProjectFormController',
    controllerAs: 'vm',
    pageTitle: gettext('Duplicate Project'),
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:projects',
    accessScopeBe: 'tis_be:projects',
    mode: 'create',
    entity: 'projects',
    resolve: {
      project: loadEntity
    }
  })
  // VIEW CONTRACT
  .state('company-resources-projects-view', {
    url: '/company-resources/projects/{id}',
    template: ItemHeaderView,
    controller: 'ProjectController',
    controllerAs: 'vm',
    pageTitle: gettext('Project'),
    parentState: 'company-resources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:projects',
    accessScopeBe: 'tis_be:projects',
    mode: 'read',
    entity: 'projects',
    resolve: {
      project: loadEntity
    }
  });

export default CompanyResourcesRouteManager.routes;
