import template from './regular-manual-input.component.html';
import './regular-manual-input.scss';

export default {
  template,
  bindings: {
    api: '<',
    displayModeVertically: '<',
    saveValue: '<'
  },
  controller: RegularManualInput,
  controllerAs: 'vm'
};

RegularManualInput.$inject = ['ManualInputFormHelper', 'gettextCatalog'];

function RegularManualInput(ManualInputFormHelper, gettextCatalog) {
  const vm = this;
  vm.switchChanged = ManualInputFormHelper.switchChanged;
  vm.dateAutocomplete = ManualInputFormHelper.dateAutocomplete;

  vm.$onChanges = changes => {
    if (changes.api && vm.api != null && typeof vm.api === 'object') {
      vm.api.loadValues = loadValues;
      vm.api.getValues = () => ({
        dates: vm.available,
        timeSeries: vm.globalTimeSeries
      });
    }
  };
  /**
   * @description sets manual input table.
   * @function
   * @param {Object} { range<{from, to}>, manualInput<Object>, timeSeries<Array>, defaultTimeSeries<Array> }
   * @return {dataType}
   */
  async function loadValues(params) {
    let noTimeSeriesSelected = true;
    const { range, manualInput, timeSeries, defaultTimeSeries } = params;
    const allDates = getValidDatesAccordingToScheduler(timeSeries, range);
    if (allDates.length > 100) {
      throw gettextCatalog.getString(
        'There are too many values for selected period try to reduce selected interval'
      );
    }

    const timeSeriesCurrentValues = await ManualInputFormHelper.getTimeSeriesCurrentValues(
      timeSeries,
      range
    );

    const timeSeriesDefaultValue = await ManualInputFormHelper.getTimeSeriesDefaultValue(
      manualInput.groups,
      range,
      defaultTimeSeries
    );

    const lastValueDefaultValue = await ManualInputFormHelper.getLastValueDefaultValue(
      manualInput.groups
    );

    const {
      manualInput: manualInputGroup,
      globalTimeSeries
    } = ManualInputFormHelper.constructManualInputFrom({
      manualInput,
      timeSeries,
      lastValueDefaultValue,
      timeSeriesDefaultValue,
      timeSeriesCurrentValues,
      dates: allDates
    });
    vm.available = allDates;
    vm.manualInput = manualInputGroup;
    vm.globalTimeSeries = globalTimeSeries;
    if (vm.globalTimeSeries.length > 0) {
      noTimeSeriesSelected = false;
    }
    return { noTimeSeriesSelected };
  }
  /**
   * @description combines all time series scheduler and returns final date array.
   * @function
   * @param {Array} timeSeries
   * @param {Object} range {from, to}
   * @return {Array} [{validAt: timestamp}]
   */
  function getValidDatesAccordingToScheduler(timeSeries, { from, to }) {
    const allDates = timeSeries.reduce((result, timeSeriesItem) => {
      const scheduler = later.schedule(
        later.parse.cron(timeSeriesItem.dataScheduler.crontabExpression, true)
      );
      let ranges = getRanges(scheduler, from, to);
      if (Array.isArray(ranges)) {
        ranges = ranges.map(date => date.getTime());
        return [...result, ...ranges];
      }
      return result;
    }, []);

    return Array.from(new Set(allDates))
      .sort((a, b) => a - b)
      .map(timestamp => ({ validAt: timestamp }));
  }

  /**
   * @description returns array od dates between From and TO dates according to scheduler.
   * @function
   * @param {Object} scheduler scheduler object
   * @return {Array}
   */
  function getRanges(scheduler, from, to) {
    const intervalFrom = new Date(from);
    const intervalTo = new Date(to);
    return scheduler.next(-1, intervalFrom, intervalTo);
  }
}
