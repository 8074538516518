StepModel.$inject = ['ModelUtilities', '$q', 'CrawlerMethods'];

function StepModel(ModelUtilities, $q, CrawlerMethods) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'steps'
  };

  const networkConfigurationAllLang = {
    host: 'api',
    path: 'steps',
    allLang: true
  };

  const crawlerConfigurationPopulateArray = {
    entity: 'steps',
    method: 'read',
    populate: 'tasks'
  };
  const crawlerConfigurationForm = {
    entity: 'steps',
    method: 'custom.readAllLang',
    populate: 'tasks'
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readAllLang: ModelUtilities.crud(
        'GET',
        networkConfigurationAllLang,
        modelStorage
      ),
      readForm: CrawlerMethods.crawler(crawlerConfigurationForm),
      readItemView: CrawlerMethods.crawler(crawlerConfigurationPopulateArray)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default StepModel;
