PriceListController.$inject = [
  '$state',
  'gettext',
  '$timeout',
  'MetadataService',
  'PriceListDetailsConfiguration',
  'TranslationService',
  'GenerateSharedPriceListProperties',
  'priceListMaster'
];

function PriceListController(
  $state,
  gettext,
  $timeout,
  MetadataService,
  PriceListDetailsConfiguration,
  TranslationService,
  GenerateSharedPriceListProperties,
  priceListMaster
) {
  const vm = this;
  const priceListId = $state.params.priceListId;

  vm.$onInit = async () => {
    vm.loading = true;
    MetadataService.Loading(true);
    vm.headerData = constructHeader(priceListMaster);
    setPricelistDetailListConfiguration();

    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(
        priceListMaster.name,
        priceListMaster.description
      );
    });
    vm.loading = false;
  };

  function constructHeader(priceList) {
    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];
    var actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: priceListId,
        endpoint: {
          entity: 'price-list-masters',
          method: 'delete'
        },
        redirectState: 'invoices-price-lists'
      },
      {
        title: gettext('Duplicate'),
        state: 'invoices-price-lists-duplicate',
        param: 'priceListId',
        paramValue: priceListId
      },
      {
        title: gettext('Update'),
        state: 'invoices-price-lists-edit',
        param: 'priceListId',
        paramValue: priceListId
      }
    ];
    const currency = TranslationService.GetCollectionById(
      'codelists.currencies',
      priceList.currency
    );
    let currencyName;
    if (currency) {
      currencyName = currency.name;
    }
    var propertySections = [
      {
        title: gettext('Properties'),
        properties: [
          ...GenerateSharedPriceListProperties.generateProperties(
            priceList,
            false
          ),
          {
            title: gettext('Currency'),
            type: 'simple',
            value: currencyName || 'Unknown'
          }
        ]
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'price-list-masters',
        target: priceListId,
        type: 'userAccess'
      }
    ];
    if (priceList.externalCode) {
      propertySections[0].properties.unshift({
        title: gettext('External code'),
        value: priceList.externalCode,
        type: 'code'
      });
    }

    return {
      metadata: {
        definition: gettext('price list'),
        title: priceList.name,
        description: priceList.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }

  function setPricelistDetailListConfiguration() {
    vm.configuration = PriceListDetailsConfiguration;
    const createIndexButton = vm.configuration.buttons.findIndex(
      item => item.link && item.link.state === 'invoices-price-lists-new-detail'
    );
    vm.configuration.buttons[createIndexButton].link.params = [
      {
        queryParam: 'priceListId',
        value: priceListId
      }
    ];
    vm.filterObject = {
      priceListMaster: priceListId
    };
  }
}

export default PriceListController;
