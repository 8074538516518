NewCompanyStructureNodeController.$inject = [
  '$stateParams',
  'MetadataService',
  'AlertingService',
  'gettext',
  'createOrUpdateService',
  'SfeHeader',
  'companyStructureNode',
  '$timeout'
];

function NewCompanyStructureNodeController(
  $stateParams,
  MetadataService,
  AlertingService,
  gettext,
  createOrUpdateService,
  SfeHeader,
  companyStructureNode,
  $timeout
) {
  const vm = this;
  const companyStructureNodeId = $stateParams.id;
  vm.dataConfig = {};
  let currentlySelectedType;

  init();

  function init() {
    vm.editMode = !!companyStructureNodeId;
    var data = [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField'
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textArea',
        maxlength: 500,
        type: 'text',
        required: false
      },
      {
        placeholder: gettext('Code'),
        name: 'code',
        componentType: 'textField',
        required: true
      },
      {
        placeholder: gettext('External Code'),
        name: 'externalCode',
        componentType: 'textField',
        maxLength: 30,
        required: false
      },
      {
        name: 'type',
        edit: vm.editMode,
        componentType: 'autocompleteDialog',
        configuration: {
          filterFn: function(items) {
            return items.filter(function(item) {
              return item.allowedEntities.indexOf(228) >= 0;
            });
          },
          change: selected => {
            if (
              selected &&
              selected.length > 0 &&
              selected[0] &&
              currentlySelectedType !== selected[0].id
            ) {
              currentlySelectedType = selected[0].id;
              let parentConfig = vm.dataConfig.data.find(
                config => config.name == 'parentId'
              );
              parentConfig.configuration.filterObject = {
                type: currentlySelectedType
              };

              if (!selected[0].__prefetched__value__) {
                vm.dataConfig.dataObj.parentId = null;
              }
            }
          },
          codelist: 'costCentreTypes',
          entity: 'costCentreTypes',
          nodialog: true,
          floatingLabel: gettext('Cost Centre Type'),
          searchParamName: 'name',
          required: true
        }
      },
      {
        name: 'parentId',
        edit: false,
        componentType: 'autocompleteDialog',
        configuration: {
          query: {
            entity: 'company-structure-nodes',
            method: 'read'
          },
          floatingLabel: gettext('Select parent hierarchy node'),
          searchParamName: 'name',
          entity: 'company-structure-nodes',
          required: false,
          change: function checkParent() {
            var dataObj = vm.dataConfig.dataObj;
            if (dataObj.parentId && dataObj.parentId._id === dataObj._id) {
              dataObj.parentId = null;
            }
          }
        }
      }
    ];

    vm.dataConfig.data = data;
    vm.dataConfig.action = {
      ctrlFn: true,
      title: vm.editMode ? gettext('Update') : gettext('Create'),
      fn: createOrUpdate
    };

    if (companyStructureNodeId) {
      MetadataService.Loading(true);
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit Company Structure Node'),
        'configurations-company-resources-company-structure-nodes-view',
        {
          id: companyStructureNodeId
        }
      );
      vm.dataConfig.dataObj = companyStructureNode;
      currentlySelectedType = companyStructureNode.type;
      MetadataService.Loading(false);

      $timeout(() => {
        MetadataService.ChangeMetadata(
          'Edit ' + companyStructureNode.name,
          companyStructureNode.description
        );
      });
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New Company Structure Node'),
        'configurations-company-resources-company-structure-nodes-list'
      );
    }
  }

  // create and update functions
  function createOrUpdate() {
    vm.sendingRequest = true;
    createOrUpdateService
      .simpleCall(
        {
          entity: 'company-structure-nodes',
          method: vm.editMode ? 'update' : 'create'
        },
        vm.editMode,
        companyStructureNodeId,
        dataToSave(),
        'configurations-company-resources-company-structure-nodes-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function dataToSave() {
    return {
      name: vm.dataConfig.dataObj.name,
      code: vm.dataConfig.dataObj.code,
      externalCode: vm.dataConfig.dataObj.externalCode,
      description: vm.dataConfig.dataObj.description,
      type: vm.dataConfig.dataObj.type.id,
      parentId: vm.dataConfig.dataObj.parentId
        ? vm.dataConfig.dataObj.parentId._id
        : null
    };
  }
}

export default NewCompanyStructureNodeController;
