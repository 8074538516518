DataProcessingDialogContent.$inject = ['gettextCatalog'];

export default function DataProcessingDialogContent(gettextCatalog) {
  return [
    {
      type: 'operation',
      title: gettextCatalog.getString('Primary'),
      description: gettextCatalog.getString(
        'When reading data (according to read scheduler), system reads current value at the source (OPC, database, …). Timestamp of such data is always time of read (according to read scheduler).'
      )
    },
    {
      type: 'operation',
      title: gettextCatalog.getString('Batch'),
      description: gettextCatalog.getString(
        'When reading data (according to read scheduler), system reads all data between the time of last read data and the current time of reading data. Timestamp of such data is copied from the source data. When reading data for the first time, length of time interval is defined with attribute “First Batch Interval”.'
      )
    },
    {
      type: 'operation',
      title: gettextCatalog.getString('Future'),
      description: gettextCatalog.getString(
        'When reading data (according to read scheduler), system reads all data between the time of read and the time interval defined with attribute “Future interval”. Timestamp of such data is copied from the source data.'
      )
    }
  ];
}
