import template from './sfe-root-access.component.html';
import './root-access.scss';
/**
 * @ngdoc component
 * @name user.sfeRootAccess
 * @description displays user root access items and allows to edit user access permissions.
 * @param {string} treeId treeId
 * @param {string} userId user id
 * @param {string} instanceScope scope identifier
 * @example
 * <sfe-root-access
 * treeId="treeId"
 * userId="userId"
 * instanceScope="instanceScopeIdentifier"
 * ></sfe-root-access>
 */
export default {
  template,
  bindings: {
    treeId: '<',
    userId: '<',
    instanceScope: '<'
  },
  controllerAs: 'vm',
  controller: SfeRootAccessController
};

SfeRootAccessController.$inject = [
  'AuthorizationRootPermissionModel',
  'ToastService',
  'EntitiesService',
  '$q',
  'ItemSelector',
  'gettext',
  'InfoDialog',
  'AlertingService',
  '$mdDialog',
  'gettextCatalog'
];

function SfeRootAccessController(
  AuthorizationRootPermissionModel,
  ToastService,
  EntitiesService,
  $q,
  ItemSelector,
  gettext,
  InfoDialog,
  AlertingService,
  $mdDialog,
  gettextCatalog
) {
  const vm = this;
  let entity;
  let entityValueName;
  vm.query = {
    limit: 5,
    page: 1,
    filters: [],
    filterObject: {}
  };
  /**
   * @description this funtion is triggered when values of the bindings are changed in the parent scope.
   * when component has value ov instance scope initialization of component data is started
   * @function
   */
  vm.$onChanges = function() {
    if (vm.instanceScope) {
      vm.accessItems = [];
      entity = EntitiesService.getEntity(vm.instanceScope);
      vm.listId = entity;
      const permissionConfig = EntitiesService.getPermissionConfiguration(
        entity
      );
      entityValueName =
        permissionConfig && permissionConfig.valueParameterName
          ? permissionConfig.valueParameterName
          : '_id';
      vm.actions = [
        {
          title: gettext('Remove all on page'),
          fn: removeAllRootInstance,
          color: 'warn'
        },
        {
          title: gettext('+ Add'),
          fn: addRootInstance
        }
      ];

      vm.fetchRootAccess = {
        query: function(refreshObj) {
          const deferred = $q.defer();
          let apiObj = refreshObj || {};
          apiObj.owner = vm.userId;
          apiObj.instanceScope = vm.instanceScope;
          AuthorizationRootPermissionModel.read(apiObj).then(
            function(res) {
              vm.actions[0].disabled = res.data.length < 1;
              deferred.resolve(res);
            },
            function(err) {
              deferred.reject(err);
            }
          );
          return {
            $promise: deferred.promise
          };
        }
      };
      vm.query.relistData = true;
    }
  };
  /**
   * @description removes root access permisions.
   * @function
   */
  function removeAllRootInstance() {
    async function remove() {
      $mdDialog.cancel();
      if (vm.accessItems && vm.accessItems.length) {
        const promises = vm.accessItems.map(item => {
          let postObj = {
            owner: vm.userId,
            instanceScope: vm.instanceScope,
            instanceId: item.instanceId,
            access: {
              read: false,
              update: false,
              delete: false,
              list: false
            }
          };
          return postRootPrivilegesWrapper(postObj);
        });

        let result = await Promise.all(promises);
        let isError = result.reduce((res, { err }) => {
          return res || err;
        }, false);
        if (isError) {
          AlertingService.Error(
            gettextCatalog.getString('Some items couldn\'t be removed')
          );
        } else {
          ToastService.showToast(gettext('Configurations updated.'));
        }
        vm.query.relistData = true;
      }
    }
    const title = gettext('Remove all direct access');
    const textItem = {
      text: gettext('Are you sure you want to remove all direct accesses?'),
      type: 'text'
    };
    const actions = [
      {
        title: gettext('Cancel'),
        cancel: true,
        color: 'primary'
      },
      {
        title: gettext('Remove'),
        fn: remove,
        color: 'warn'
      }
    ];
    InfoDialog.open(title, null, [textItem], actions);
  }
  /**
   * @description posts item new root permission object.
   * @function
   * @param {object} postObj new rot permission values
   * @return {Promise}
   */
  async function postRootPrivilegesWrapper(postObj) {
    try {
      return await AuthorizationRootPermissionModel.create(postObj);
    } catch (err) {
      return {
        err
      };
    }
  }
  /**
   * @description opens root permission items selector dialog and adds new item after item is selected and dialog is closed.
   * @function
   */
  async function addRootInstance() {
    try {
      const items = await ItemSelector.open(
        [entity],
        [
          {
            multiple: true
          }
        ]
      );

      if (items && items.length) {
        const promises = items.map(function(item) {
          let postObj = {
            owner: vm.userId,
            instanceScope: vm.instanceScope,
            instanceId: item[entityValueName],
            access: {
              read: false,
              update: false,
              delete: false,
              list: true
            }
          };
          return postRootPrivilegesWrapper(postObj);
        });

        vm.query.loading = true;

        let result = await Promise.all(promises);
        let isError = result.reduce((res, { err }) => {
          return res || err;
        }, false);
        if (isError) {
          AlertingService.Error(
            gettextCatalog.getString('Some items couldn\'t be updated')
          );
        } else {
          ToastService.showToast(gettext('Configuration updated.'));
        }
        vm.query.loading = false;
        vm.query.relistData = true;
      }
    } catch (err) {
      AlertingService.Error(err);
    }
  }
}
