import template from './tango-wizard-dialog.html';
import './tango-wizard-dialog.scss';
/**
 * @ngdoc service
 * @name common.TangoWizardDialog
 * @description Opens wizard dialog
 */

TangoWizardDialog.$inject = ['$mdDialog'];
export default function TangoWizardDialog($mdDialog) {
  async function open(config) {
    return $mdDialog
      .show({
        controller: DialogController,
        controllerAs: 'vm',
        template,
        parent: angular.element(document.body),
        locals: {
          config
        }
      })
      .catch(angular.noop);
  }
  return {
    open
  };
}

DialogController.$inject = ['config'];
function DialogController(config) {
  const vm = this;
  vm.entity = config.entity;
  vm.entityId = config.entityId;
  vm.options = config.options;
  vm.header = {
    isDialog: true,
    actions: [
      {
        icon: {
          name: 'close',
          type: 2
        },
        cancel: true
      }
    ]
  };
}
