LocationController.$inject = [
  '$state',
  'gettext',
  'AlertingService',
  'CrudToastFactory',
  'HierarchyTreeDialog',
  'ItemSelector',
  'utilService',
  'MetadataService',
  'Formatting',
  'UploadFilesConfiguration',
  'TranslationService',
  'LocationModel',
  'MeasuringPointConfiguration',
  'InfoDialog',
  'MeasuringpointModel',
  '$mdDialog',
  'location',
  '$timeout'
];

function LocationController(
  $state,
  gettext,
  AlertingService,
  CrudToastFactory,
  HierarchyTreeDialog,
  ItemSelector,
  utilService,
  MetadataService,
  Formatting,
  UploadFilesConfiguration,
  TranslationService,
  LocationModel,
  MeasuringPointConfiguration,
  InfoDialog,
  MeasuringpointModel,
  $mdDialog,
  location,
  $timeout
) {
  const vm = this;

  vm.locationId = $state.params.id;
  vm.openAddAssetDialog = openAddAssetDialog;
  vm.openAddChildLocationDialog = openAddChildLocationDialog;
  vm.cancelSavingAsset = cancelSavingAsset;
  vm.invalidDates = invalidDates;
  vm.editActiveAsset = editActiveAsset;
  vm.elementSelected = elementSelected;

  vm.$onInit = function init() {
    vm.loading = true;
    MetadataService.Loading(true);

    // tree functions/triggers
    vm.assetRefresh = {};
    vm.locationRefresh = {};

    vm.locationIdFilter = {
      location: vm.locationId
    };

    vm.locationIdMainFilter = {
      isMain: true,
      location: vm.locationId
    };

    vm.locationIdNotMainFilter = {
      isMain: false,
      location: vm.locationId
    };

    vm.entityConfig = initEntityConfig();
    vm.entityConfigMainCostCentre = initMainCostCentreConfig();
    vm.entityConfigOwner = initOwnerConfig();
    vm.entityConfigMeasuringPoint = initMeasuringPointConfig();
    vm.entityConfigNoMain = initNoMainEntityConfig();

    vm.entityConfigFile = initFileConfig(location.documents);

    if (location.parentId) {
      location.parent = location.parentId;
      location.parentId = location.parent._id;
    }

    vm.location = location;

    vm.headerData = constructHeader(location);

    if (vm.location.address) {
      if (vm.location.address.country) {
        vm.location.address.country = TranslationService.GetCollectionById(
          'codelists.countries',
          location.address.country
        );
      }
      vm.address = vm.location.address;
      vm.address.hasAddress = true;
    } else {
      vm.address = Object.assign({}, { hasAddress: false });
    }
    if (vm.location.geoLocation) {
      var tempAddressObject = vm.address || {};
      vm.address = Object.assign({}, tempAddressObject, {
        geoLocation: vm.location.geoLocation
      });
    }
    $timeout(() => {
      vm.loading = false;
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(vm.location.name, vm.location.description);
    });
  };

  function openLocationTree() {
    HierarchyTreeDialog.openDialog(vm.location, 'locations');
  }

  function initOwnerConfig() {
    return {
      entity: {
        inactiveTitle: gettext('Inactive owners'),
        title: gettext('Owner')
      },
      type: 'single',
      updateParam: {
        isMain: true,
        location: vm.locationId
      },
      createParam: {
        isMain: true,
        location: vm.locationId
      }
    };
  }

  function initEntityConfig() {
    return {
      type: 'multiple',
      updateParam: {
        isMain: false,
        location: vm.locationId
      },
      createParam: {
        isMain: false,
        location: vm.locationId
      }
    };
  }

  function initMainCostCentreConfig() {
    return {
      entity: {
        inactiveTitle: gettext('Inactive main cost centes'),
        title: gettext('Main Cost Centre')
      },
      updateParam: {
        isMain: true,
        location: vm.locationId
      },
      createParam: {
        isMain: true,
        location: vm.locationId
      }
    };
  }

  function initNoMainEntityConfig() {
    return {
      type: 'multiple',
      updateParam: {
        location: vm.locationId
      },
      createParam: {
        location: vm.locationId
      }
    };
  }

  function initMeasuringPointConfig() {
    var configuration = angular.copy(MeasuringPointConfiguration);
    // sets parameters for the measuring point creation so that the current location is passed alongside it
    const index = configuration.buttons.findIndex(
      item =>
        item.link &&
        item.link.state === 'company-resources-measuring-points-new'
    );
    configuration.buttons[index].link.params = [
      {
        value: vm.locationId,
        queryParam: 'locationId'
      }
    ];

    let deleteAction = configuration.shortActions.find(
      action => action.type == 'delete'
    );
    deleteAction.query = null;
    deleteAction.action = (item, relist) => {
      var title = gettext('Confirmation');
      var textItem = {
        text: gettext('Are you sure you want to delete this item?'),
        type: 'text'
      };
      var actions = [
        {
          title: gettext('Cancel'),
          cancel: true,
          color: 'primary'
        },
        {
          title: gettext('Delete'),
          fn: async () => {
            try {
              await MeasuringpointModel.update(
                { id: item._id },
                { location: null }
              );
              CrudToastFactory.toast('delete');
              $mdDialog.cancel();
              relist();
            } catch (err) {
              AlertingService.Error(err);
              relist();
            }
          },
          color: 'warn'
        }
      ];
      InfoDialog.open(title, null, [textItem], actions);
    };
    return configuration;
  }

  function initFileConfig(documents) {
    const config = UploadFilesConfiguration;
    config.files = documents;
    config.route = 'locations';
    config.id = vm.locationId;
    config.listId = 'locations' + vm.locationId;
    return config;
  }

  function cancelSavingAsset() {
    vm.addingNewAsset = false;
    vm.editingAsset = false;
  }

  function initAssetForm() {
    vm.currentConfig.data = [
      {
        componentType: 'datePickerFromTo',
        from: 'validFrom',
        to: 'validTo',
        valToEmpty: true,
        labelFrom: gettext('Valid from *'),
        labelTo: gettext('Valid to'),
        required: true,
        onlyStartRequired: true,
        idFrom: 'currentFrom',
        idTo: 'currentTo'
      }
    ];
  }

  //show
  function showAssetDatesToSelect(asset) {
    vm.addingNewAsset = true;
    vm.newAsset = asset;
    vm.currentConfig = {
      dataObj: {
        validFrom: new Date()
      }
    };
    initAssetForm();
  }

  function invalidDates() {
    var selectedFrom;
    var selectedTo;
    if (vm.currentConfig) {
      selectedFrom = vm.currentConfig.dataObj.validFrom
        ? Formatting.formatDate(vm.currentConfig.dataObj.validFrom, '00:00')
        : null;
      selectedTo = vm.currentConfig.dataObj.validTo
        ? Formatting.formatDate(vm.currentConfig.dataObj.validTo, '23:59')
        : null;
    }
    return (selectedTo && selectedFrom > selectedTo) || !selectedFrom;
  }

  function editActiveAsset(asset) {
    vm.editingAsset = true;
    vm.newAsset = asset;
    vm.currentConfig = {};
    initAssetForm();

    var from = new Date(asset.validFrom);
    var to;
    if (asset.validTo) {
      to = new Date(asset.validTo);
    }
    vm.currentConfig.dataObj = {
      validFrom: new Date(from),
      validTo: to || ''
    };
  }

  function openAddAssetDialog() {
    ItemSelector.open(
      ['assets'],
      [
        {
          multiple: false
        }
      ]
    ).then(
      function(items) {
        if (items.length) {
          var child = items[0];
          showAssetDatesToSelect(child);
        }
      },
      () => {}
    );
  }

  function openAddChildLocationDialog() {
    ItemSelector.open(
      ['locations'],
      [
        {
          multiple: false,
          filterObject: {
            parentId: JSON.stringify(null)
          },
          filterFn: items =>
            items.filter(
              item =>
                item._id != vm.locationId && item._id != vm.location.parentId
            )
        }
      ]
    ).then(
      function(items) {
        if (items && items.length) {
          var child = items[0];
          LocationModel.update(
            {
              id: child._id
            },
            {
              parentId: vm.locationId
            }
          ).then(
            function() {
              vm.location.hasChild = true;
              if (typeof vm.locationRefresh === 'function') {
                vm.locationRefresh();
              }
              CrudToastFactory.toast('create');
            },
            function(err) {
              AlertingService.Error(err);
            }
          );
        }
      },
      () => {}
    );
  }

  function elementSelected(element) {
    if (element._id !== vm.location._id) {
      if (element.icon === 'asset') {
        vm.selectedAsset = element;
        vm.isLoadingAsset = true;
      } else if (element.icon === 'location') {
        vm.selected = element;
      }
    }
  }

  function constructHeader(location) {
    var imageItems = [];
    var properties = [];
    var ownership = [];

    if (location.pictureLocation) {
      imageItems = location.pictureLocation.map(function(item, index) {
        return {
          id: index + 1,
          url: utilService.getApiHost + '/pictures/' + item.picture + '/image',
          title: 'not fetched'
        };
      });
    }
    var images = [
      {
        images: imageItems,
        config: {
          noImagesMessage: gettext('No images found for this location.')
        },
        type: 'image'
      }
    ];

    if (vm.location.type) {
      properties.push({
        title: gettext('Location Type'),
        linkTitle: vm.location.type.name,
        state: 'configurations-company-resources-locations-location-types-view',
        param: 'id',
        paramValue: vm.location.type._id,
        type: 'link'
      });
    }

    if (location.status) {
      properties.push({
        type: 'simple',
        title: gettext('Location status'),
        value: vm.location.status ? vm.location.status.name : 'None'
      });
    }

    if (vm.location.parent) {
      properties.push({
        title: gettext('Parent Location'),
        linkTitle: vm.location.parent.name,
        state: 'company-resources-locations-view',
        param: 'id',
        paramValue: vm.location.parent._id,
        type: 'link'
      });
    } else {
      properties.push({
        title: gettext('Superior Location'),
        value: 'None',
        type: 'simple'
      });
    }

    if (vm.location.ownership) {
      ownership.push({
        title: gettext('Location ownership'),
        value: vm.location.ownership.name,
        type: 'simple'
      });
    }

    if (vm.location.locationClassification) {
      properties.push({
        title: gettext('Location classification'),
        value: vm.location.locationClassification.name,
        type: 'simple'
      });
    }

    if (vm.location.weatherStation) {
      properties.push({
        title: gettext('Weather station'),
        linkTitle: vm.location.weatherStation.name,
        state: 'company-resources-locations-view',
        param: 'id',
        paramValue: vm.location.weatherStation._id,
        type: 'link'
      });
    }

    properties.push({
      title: gettext('External'),
      type: 'radio',
      value: vm.location.isExternal,
      customOptions: ['Yes', 'No']
    });

    if (vm.location.code) {
      properties.push({
        title: gettext('Code'),
        value: vm.location.code,
        type: 'code'
      });
    }

    if (vm.location.externalCode) {
      properties.push({
        title: gettext('External code'),
        value: vm.location.externalCode,
        type: 'code'
      });
    }

    if (vm.location.externalId) {
      properties.push({
        title: gettext('Location external ID'),
        value: vm.location.externalId,
        type: 'simple'
      });
    }

    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];

    const tags = [
      {
        type: 'tags',
        paramName: 'name',
        values:
          location && Array.isArray(location.tags)
            ? location.tags.map(function(tag) {
              return tag.systemTag;
            })
            : null
      }
    ];

    var propertySections = [
      {
        title: gettext('Properties'),
        properties: properties
      },
      {
        title: gettext('Ownership'),
        properties: ownership
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'locations',
        target: vm.locationId,
        type: 'userAccess'
      },
      {
        title: gettext('Images'),
        properties: images
      },
      {
        title: gettext('System tags'),
        properties: tags
      }
    ];

    var actions = [
      {
        title: gettext('open location tree'),
        script: true,
        fn: openLocationTree
      },
      {
        title: gettext('delete'),
        delete: true,
        id: vm.locationId,
        endpoint: {
          entity: 'locations',
          method: 'delete'
        },
        redirectState: 'company-resources-locations-list'
      },
      {
        title: gettext('duplicate'),
        state: 'company-resources-locations-duplicate',
        param: 'id',
        paramValue: vm.locationId
      },
      {
        title: gettext('Update'),
        state: 'company-resources-locations-edit',
        param: 'id',
        paramValue: vm.locationId
      }
    ];

    return {
      metadata: {
        definition: gettext('location'),
        title: vm.location.name,
        description: vm.location.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}
export default LocationController;
