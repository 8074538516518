import './sfe-dnd-ordered-input.scss';
import template from './sfe-dnd-ordered-input.component.html';

export default {
  restrict: 'E',
  template,
  bindings: {
    model: '=',
    configuration: '<',
    edit: '<'
  },
  require: {
    form: '^form'
  },
  controller: sfeDndOrderedInputController,
  controllerAs: 'vm',
  bindToController: true
};

sfeDndOrderedInputController.$inject = [];

function sfeDndOrderedInputController() {
  var vm = this;
  vm.addRow = addRow;
  vm.removeItem = removeItem;
  vm.componentNames = [];

  function removeItem(index) {
    vm.model.splice(index, 1);
  }

  function addRow() {
    if (!vm.model) {
      vm.model = [];
    }
    var item = [];
    var componentName = [];
    vm.configuration.autocompleteConfigurations.forEach(function() {
      componentName.push(
        Math.random()
          .toString(36)
          .substring(2)
      );
      item.push(null);
    });
    vm.componentNames.push(componentName);
    vm.model.push(item);
  }
}
