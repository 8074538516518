NetworkModels.$inject = ['$injector'];

export default function NetworkModels($injector) {
  return function(entity) {
    switch (entity) {
    case 'dashboard-users':
      return $injector.get('DashboardUserModel');
    case 'measuringpointcountertypelist':
      return $injector.get('MeasuringpointcountertypelistModel');
    case 'views':
      return $injector.get('ViewModel');
    case 'upload-files':
      return $injector.get('UploadFileModel');
    case 'alarm-rule-target-for-x-axis-intervals':
      return $injector.get('AlarmRuleTargetForXAxisIntervalModel');
    case 'rule-target-for-x-axis-intervals':
      return $injector.get('RuleTargetForXAxisIntervalModel');
    case 'target-for-x-axis-intervals':
      return $injector.get('TargetForXAxisIntervalModel');
    case 'x-axis-intervals':
      return $injector.get('XAxisIntervalModel');
    case 'measuringpoint-splitters':
      return $injector.get('MeasuringpointSplitterModel');
    case 'profiles':
      return $injector.get('ProfileModel');
    case 'dashboard-cards':
      return $injector.get('DashboardCardModel');
    case 'report-generals':
      return $injector.get('ReportGeneralModel');
    case 'gis-maps':
      return $injector.get('GisMapModel');
    case 'app-config':
      return $injector.get('AppConfigModel');
    case 'authorization-list-show-scopes':
      return $injector.get('AuthorizationShowScopeModel');
    case 'authorization-list-permitted':
      return $injector.get('AuthorizationListPermittedModel');
    case 'gis-user-group':
      return $injector.get('GisUserGroupModel');
    case 'modify-group':
      return $injector.get('ModifyGroupModel');
    case 'groups-of-user':
      return $injector.get('GroupsOfUserModel');
    case 'gis-users':
      return $injector.get('GisUserModel');
    case 'users-in-group':
      return $injector.get('UsersInGroup');
    case 'expressions':
      return $injector.get('ExpressionModel');
    case 'reports':
      return $injector.get('ReportModel');
    case 'datasets':
      return $injector.get('DatasetModel');
    case 'period-intervals':
      return $injector.get('PeriodIntervalModel');
    case 'analysis-filter-configs':
      return $injector.get('AnalysisFilterConfigModel');
    case 'aggregation-groups':
      return $injector.get('AggregationGroupModel');
    case 'analyses':
      return $injector.get('AnalysesModel');
    case 'analysis-calculations':
      return $injector.get('AnalysisCalculationModel');
    case 'visualization-images':
      return $injector.get('VisualizationImageModel');
    case 'visualization-elements':
      return $injector.get('VisualizationElementModel');
    case 'visualizations':
      return $injector.get('VisualizationModel');
    case 'location-actions':
      return $injector.get('LocationActionModel');
    case 'authorization-instance-to-scope':
      return $injector.get('AuthorizationInstanceToScopeModel');
    case 'authorization-instance-rules':
      return $injector.get('AuthorizationInstanceRuleModel');
    case 'authorization-tree-path':
      return $injector.get('AuthorizationTreePathModel');
    case 'dashboard-playlists':
      return $injector.get('DashboardPlaylistModel');
    case 'dashboards':
      return $injector.get('DashboardModel');
    case 'authorization-tree-access':
      return $injector.get('AuthorizationTreeAccessModel');
    case 'authorization-root-permissions':
      return $injector.get('AuthorizationRootPermissionModel');
    case 'authorization-trees':
      return $injector.get('AuthorizationTreeModel');
    case 'authorization-static-access':
      return $injector.get('AuthorizationStaticAccessModel');
    case 'mappings':
      return $injector.get('MappingModel');
    case 'work-schedules':
      return $injector.get('WorkScheduleModel');
    case 'realms':
      return $injector.get('RealmModel');
    case 'interactions':
      return $injector.get('InteractionModel');
    case 'notifications':
      return $injector.get('NotificationModel');
    case 'groups':
      return $injector.get('GroupModel');
    case 'time-zones':
      return $injector.get('TimeZoneModel');
    case 'triggered-alarms':
      return $injector.get('TriggeredAlarmModel');
    case 'jobs':
      return $injector.get('JobModel');
    case 'external-datasources-regenerate-credentials':
      return $injector.get('ExternalDatasourceRegenerateCredentialsModel');
    case 'heartbeat-history-last':
      return $injector.get('HeartbeatHistoryLastModel');
    case 'time-series-manual-input-form-group-time-series-items':
      return $injector.get(
        'TimeSeriesManualInputFormGroupTimeSeriesItemModel'
      );
    case 'time-series-manual-input-form-groups':
      return $injector.get('TimeSeriesManualInputFormGroupModel');
    case 'price-list-details':
      return $injector.get('PriceListDetailModel');
    case 'detail-invoice-values':
      return $injector.get('DetailInvoiceValueModel');
    case 'price-list-items':
      return $injector.get('PriceListItemModel');
    case 'detail-invoices':
      return $injector.get('DetailInvoiceModel');
    case 'master-invoices':
      return $injector.get('MasterInvoiceModel');
    case 'invoicelist':
      return $injector.get('InvoicelistModel');
    case 'invoices':
      return $injector.get('InvoiceModel');
    case 'files':
      return $injector.get('FileModel');
    case 'databases':
      return $injector.get('DatabaseModel');
    case 'webservices':
      return $injector.get('WebserviceModel');
    case 'opcs':
      return $injector.get('OpcModel');
    case 'time-series-measuringpoints':
      return $injector.get('TimeSeriesMeasuringpointModel');
    case 'time-series-locations':
      return $injector.get('TimeSeriesLocationModel');
    case 'location-attributes':
      return $injector.get('LocationAttributeModel');
    case 'asset-attributes':
      return $injector.get('AssetAttributeModel');
    case 'measuringpoint-attributes':
      return $injector.get('MeasuringpointAttributeModel');
    case 'time-series-attributes':
      return $injector.get('TimeSeriesAttributeModel');
    case 'measuringpointdomainattributes':
      return $injector.get('MeasuringpointdomainattributeModel');
    case 'timeseriesdomainattributes':
      return $injector.get('TimeseriesdomainattributeModel');
    case 'assetdomainattributes':
      return $injector.get('AssetdomainattributeModel');
    case 'locationdomainattributes':
      return $injector.get('LocationdomainattributeModel');
    case 'domain-collections':
      return $injector.get('DomainCollectionModel');
    case 'maps':
      return $injector.get('MapModel');
    case 'time-series-assets':
      return $injector.get('TimeSeriesAssetModel');
    case 'price-list-masters':
      return $injector.get('PriceListMasterModel');
    case 'tags':
      return $injector.get('TagModel');
    case 'time-series-manual-input-forms':
      return $injector.get('TimeSeriesManualInputFormModel');
    case 'asset-measuringpoints':
      return $injector.get('AssetMeasuringpointModel');
    case 'cost-centre-measuringpoints':
      return $injector.get('CostCentreMeasuringpointModel');
    case 'business-partner-measuringpoints':
      return $injector.get('BusinessPartnerMeasuringpointModel');
    case 'measuringpoint-counter-types':
      return $injector.get('MeasuringpointCounterTypeModel');
    case 'measuringpoints':
      return $injector.get('MeasuringpointModel');
    case 'mappers':
      return $injector.get('MapperModel');
    case 'rules':
      return $injector.get('RuleModel');
    case 'entity-tag-bindings':
      return $injector.get('EntityTagBindingModel');
    case 'cost-centre-assets':
      return $injector.get('CostCentreAssetModel');
    case 'picture-assets':
      return $injector.get('PictureAssetModel');
    case 'assets':
      return $injector.get('AssetModel');
    case 'asset-locations':
      return $injector.get('AssetLocationModel');
    case 'pictures':
      return $injector.get('PictureModel');
    case 'picture-locations':
      return $injector.get('PictureLocationModel');
    case 'business-partner-locations':
      return $injector.get('BusinessPartnerLocationModel');
    case 'cost-centre-locations':
      return $injector.get('CostCentreLocationModel');
    case 'locations':
      return $injector.get('LocationModel');
    case 'business-partners':
      return $injector.get('BusinessPartnerModel');
    case 'projects':
      return $injector.get('ProjectModel');
    case 'profit-centres':
      return $injector.get('ProfitCentreModel');
    case 'entity-tags':
      return $injector.get('EntityTagModel');
    case 'external-datasources':
      return $injector.get('ExternalDatasourceModel');
    case 'company-structure-nodes':
      return $injector.get('CompanyStructureNodeModel');
    case 'addresses':
      return $injector.get('AddressModel');
    case 'cost-centres':
      return $injector.get('CostCentreModel');
    case 'physical-quantities':
      return $injector.get('PhysicalQuantityModel');
    case 'measurement-units':
      return $injector.get('MeasurementUnitModel');
    case 'location-classifications':
      return $injector.get('LocationClassificationModel');
    case 'location-statuses':
      return $injector.get('LocationStatusModel');
    case 'location-types':
      return $injector.get('LocationTypeModel');
    case 'measuringpoint-types':
      return $injector.get('MeasuringpointTypeModel');
    case 'system-tag-entities':
      return $injector.get('SystemTagEntityModel');
    case 'system-tags':
      return $injector.get('SystemTagModel');
    case 'ownerships':
      return $injector.get('OwnershipModel');
    case 'location-action-statuses':
      return $injector.get('LocationActionStatusModel');
    case 'project-fields':
      return $injector.get('ProjectFieldModel');
    case 'project-types':
      return $injector.get('ProjectTypeModel');
    case 'billing-kinds':
      return $injector.get('BillingKindModel');
    case 'billing-types':
      return $injector.get('BillingTypeModel');
    case 'energy-management-groups':
      return $injector.get('EnergyManagementGroupModel');
    case 'price-list-item-groups':
      return $injector.get('PriceListItemGroupModel');
    case 'measuringpoint-type-attributes':
      return $injector.get('MeasuringpointTypeAttributeModel');
    case 'time-series-type-attributes':
      return $injector.get('TimeSeriesTypeAttributeModel');
    case 'location-type-attributes':
      return $injector.get('LocationTypeAttributeModel');
    case 'asset-type-attributes':
      return $injector.get('AssetTypeAttributeModel');
    case 'asset-groups':
      return $injector.get('AssetGroupModel');
    case 'asset-statuses':
      return $injector.get('AssetStatusModel');
    case 'asset-types':
      return $injector.get('AssetTypeModel');
    case 'asset-models':
      return $injector.get('AssetModelModel');
    case 'domain-fields':
      return $injector.get('DomainFieldModel');
    case 'domain-attributes':
      return $injector.get('DomainAttributeModel');
    case 'domains':
      return $injector.get('DomainModel');
    case 'tariffs':
      return $injector.get('TariffModel');
    case 'counter-types':
      return $injector.get('CounterTypeModel');
    case 'energy-source-types':
      return $injector.get('EnergySourceTypeModel');
    case 'document-classifications':
      return $injector.get('DocumentClassificationModel');
    case 'national-holidays':
      return $injector.get('NationalHolidayModel');
    case 'tasks':
      return $injector.get('TaskModel');
    case 'steps':
      return $injector.get('StepModel');
    case 'alarms':
      return $injector.get('AlarmModel');
    case 'sops':
      return $injector.get('SopModel');
    case 'asset-brands':
      return $injector.get('AssetBrandModel');
    case 'severities':
      return $injector.get('SeverityModel');
    case 'alarm-types':
      return $injector.get('AlarmTypeModel');
    case 'schedulers':
      return $injector.get('SchedulerModel');
    case 'response-resolution-times':
      return $injector.get('ResponseResolutionTimeModel');
    case 'users':
      return $injector.get('UserModel');
    case 'images':
      return $injector.get('ImageModel');
    case 'billing-calculation-data':
      return $injector.get('BillingCalculationDataModel');
    case 'detail-invoices-billing-data-by-billing-types':
      return $injector.get('DetailInvoiceModelBillingDataByEmg');
    case 'calculate-detail-invoice-normalized-service-date':
      return $injector.get(
        'CalculatedDetailInvoiceNormalizedServiceDateModel'
      );
    case 'time-series':
      return $injector.get('TimeSeriesModel');
    case 'time-series-groups':
      return $injector.get('TimeSeriesGroupModel');
    case 'time-series-processing-value':
      return $injector.get('TimeSeriesProcessingValuesModel');
    case 'time-series-processing-trigger-calculation':
      return $injector.get('TimeSeriesProcessingTriggerCalculationModel');
    case 'calculated-flows':
      return $injector.get('CalculatedFlowModel');
    case 'external-reader-flows':
      return $injector.get('ExternalReaderFlowModel');
    case 'external-writer-flows':
      return $injector.get('ExternalWriterFlowModel');
    case 'maintenance-flows':
      return $injector.get('MaintenanceFlowModel');
    case 'tango-agent-connection-configs':
      return $injector.get('TangoAgentConnectionConfigModel');
    case 'time-series-configurations':
      return $injector.get('TimeSeriesConfigurationModel');
    case 'time-series-types':
      return $injector.get('TimeSeriesTypeModel');
    case 'time-series-group-types':
      return $injector.get('TimeSeriesGroupTypeModel');
    case 'time-series-processing-values':
      return $injector.get('TimeSeriesProcessingValuesModel');
    case 'Time-series-processing-extended-values-by-timestamp':
      return $injector.get(
        'TimeSeriesProcessingExtendedValuesByTimestampModel'
      );
    case 'mt-analysis-flows':
      return $injector.get('MtAnalysisFlowModel');
    case 'rated-powers':
      return $injector.get('RatedPowerModel');
    case 'asset-availability-events':
      return $injector.get('AssetAvailabilityEventModel');
    }
  };
}
