NewDatasetController.$inject = [
  'gettext',
  '$state',
  'AlertingService',
  'SfeFormDialogService',
  'gettextCatalog',
  'DatasetColumnHelper',
  'TranslationService',
  'MetadataService',
  'createOrUpdateService',
  '$scope',
  'SfeHeader',
  'dataset',
  '$timeout'
];

function NewDatasetController(
  gettext,
  $state,
  AlertingService,
  SfeFormDialogService,
  gettextCatalog,
  DatasetColumnHelper,
  TranslationService,
  MetadataService,
  createOrUpdateService,
  $scope,
  SfeHeader,
  dataset,
  $timeout
) {
  var vm = this;
  var datasetId = $state.params.id;
  vm.columns = [];
  vm.addColumn = [
    {
      title: gettext('+ Add column'),
      fn: addColumn,
      color: 'primary'
    }
  ];
  vm.addComparableColumn = [
    {
      title: gettext('+ Add a comparable column'),
      fn: addComparableColumn,
      color: 'primary'
    }
  ];
  vm.removeColumn = removeColumn;
  vm.removeComaprableColumn = removeComaprableColumn;
  vm.createOrUpdate = [
    {
      title: datasetId ? gettext('Update') : gettext('Create'),
      fn: createOrUpdate,
      color: 'primary',
      raised: true,
      disabledFn: function() {
        return (
          !$scope.DatasetForm.$valid || vm.sendingRequest || !vm.columns.length
        );
      }
    }
  ];
  vm.comparableColumns = [];
  vm.editMode = false;

  init();

  function init() {
    vm.headers = DatasetColumnHelper.headerEdit(vm.columns);
    vm.tableConfig = DatasetColumnHelper.config;
    if (vm.headers && vm.headers[0]) {
      vm.order = vm.headers[0].sort;
    }
    vm.dataConfig = {
      PH16: true,
      dataObj: {}
    };
    if (datasetId) {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit dataset'),
        'analytics-datasets-view',
        { id: datasetId }
      );
      MetadataService.Loading(true);
      vm.editMode = true;
      setDatasetForm();
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New dataset'),
        'analytics-datasets-list'
      );
    }
    vm.dataConfig.data = getFormConfig();
  }

  function setDatasetForm() {
    vm.dataConfig.dataObj = {
      _id: dataset._id,
      name: dataset.name,
      code: dataset.code,
      description: dataset.description
    };
    var types = TranslationService.GetCollection('codelists.dataTypes');
    if (dataset.columns) {
      var entity;
      dataset.columns.forEach(function(column, index) {
        var typeObject = types.find(function(item) {
          return item.id === column.type;
        });
        entity = TranslationService.GetCollectionById(
          'codelists.entities',
          column.entity
        );
        var obj = {
          rawFieldName: column.rawFieldName,
          displayFieldName: column.displayFieldName,
          typeName: typeObject
            ? typeObject.name
            : gettextCatalog.getString('Unknown'),
          type: TranslationService.GetCollectionById(
            'codelists.dataTypes',
            column.type
          ),
          entity: entity,
          dateType: TranslationService.GetCollectionById(
            'codelists.dateTypes',
            column.dateType
          ),
          filterEnabled: column.filterEnabled,
          entityName: DatasetColumnHelper.getEntityName(column.entity),
          showFilter:
            entity &&
            entity.analyticsConfig &&
            entity.analyticsConfig.filterSupported,
          index: index
        };
        vm.columns.push(obj);
      });
    }
    if (dataset.comparableColumns) {
      vm.comparableColumns = dataset.comparableColumns.map(function(column) {
        return {
          model: {
            rawFieldName: column
          },
          configuration: getComparableConfig()
        };
      });
    }
    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(
        'Edit ' + dataset.name,
        dataset.description
      );
    });
  }

  function removeColumn(column) {
    _.remove(vm.columns, function(item) {
      return item.index === column.index;
    });
  }

  function removeComaprableColumn(index) {
    vm.comparableColumns.splice(index, 1);
  }

  function addComparableColumn() {
    if (!vm.comparableColumns) {
      vm.comparableColumns = [];
    }
    vm.comparableColumns.push({
      configuration: getComparableConfig()
    });
  }

  function addColumn() {
    const config = DatasetColumnHelper.getColumnConfig();
    const entityConfig = config.find(function(conf) {
      return conf.name === 'entity';
    });
    const enableFilterConfig = config.find(function(conf) {
      return conf.name === 'filterEnabled';
    });
    if (enableFilterConfig) {
      enableFilterConfig.action = value => {
        if (value) {
          entityConfig.configuration.required = true;
        } else {
          entityConfig.configuration.required = false;
        }
      };
    }

    const apiObj = {
      _preserve_: true
    };
    if (entityConfig && entityConfig.configuration) {
      entityConfig.configuration.change = DatasetColumnHelper.checkEntityFilter.bind(
        {
          obj: apiObj
        }
      );
    }
    const title = gettext('New column');
    const dialogButtonsConfiguration = {
      displayDefault: false,
      buttons: [
        {
          title: gettext('Add'),
          script: function(closeDialog, item) {
            if (item) {
              vm.columns.push(DatasetColumnHelper.constructColumnObject(item));
              vm.columns.forEach(function(item, index) {
                item.index = index;
              });
            }
            closeDialog();
          }
        }
      ]
    };
    SfeFormDialogService.openSfeFormDialog(
      false,
      config,
      apiObj,
      title,
      dialogButtonsConfiguration
    );
  }

  function getComparableConfig() {
    return {
      getDomainValues: rawFieldName => {
        var columns;
        if (rawFieldName) {
          var match = false;
          columns = vm.columns.filter(function(item) {
            if (item.rawFieldName && rawFieldName) {
              if (item.rawFieldName === rawFieldName) {
                match = true;
              }
              return (
                item.rawFieldName
                  .toLowerCase()
                  .indexOf(rawFieldName.toLowerCase()) > -1
              );
            }
          });
          if (!match) {
            columns.unshift({ rawFieldName });
          }
        } else {
          columns = vm.columns;
        }
        return columns;
      },
      setFirstItemOnBlur: true, // on blur sets model to last typed value
      requireMatch: false,
      displayFields: ['rawFieldName'],
      required: true,
      noDialog: true,
      floatingLabel: gettext('Select comparable column'),
      searchParamName: 'rawFieldName',
      entity: 'comparable-column'
    };
  }

  function getFormConfig() {
    return [
      {
        placeholder: 'Name',
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: 'Description',
        name: 'description',
        componentType: 'textArea',
        type: 'text',
        maxlength: 500,
        required: false
      },
      {
        placeholder: 'Code',
        name: 'code',
        componentType: 'textField',
        type: 'text'
      }
    ];
  }

  // create and update functions //

  function createOrUpdate() {
    vm.sendingRequest = true;
    createOrUpdateService
      .simpleCall(
        {
          entity: 'datasets',
          method: vm.editMode ? 'update' : 'create'
        },
        vm.editMode,
        datasetId,
        dataToSave(),
        'analytics-datasets-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function dataToSave() {
    var apiObj = {
      name: vm.dataConfig.dataObj.name,
      code: vm.dataConfig.dataObj.code,
      description: vm.dataConfig.dataObj.description
    };
    apiObj.columns = vm.columns.map(column => {
      return {
        rawFieldName: column.rawFieldName,
        displayFieldName: column.displayFieldName,
        type: column.type.id,
        filterEnabled: column.filterEnabled,
        entity: column.entity ? column.entity.id : undefined,
        dateType: column.dateType ? column.dateType.id : undefined
      };
    });
    if (vm.comparableColumns) {
      apiObj.comparableColumns = vm.comparableColumns.map(function(column) {
        return column.model.rawFieldName;
      });
    }
    return apiObj;
  }
}

export default NewDatasetController;
