MeasuringpointCounterTypeModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function MeasuringpointCounterTypeModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'measuringpoint-counter-types'
  };

  var crawlerConfiguration = {
    entity: 'measuringpoint-counter-types',
    method: 'read',
    populate: 'counterType'
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readCounterType: CrawlerMethods.crawler(crawlerConfiguration)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default MeasuringpointCounterTypeModel;
