import './sfe-multi-language-input.scss';
import template from './sfe-multi-language-input.directive.html';

export default [
  function() {
    var directive = {
      restrict: 'E',
      template,
      scope: {
        model: '=?',
        inputType: '@',
        parentForm: '=',
        fieldName: '@',
        configuration: '='
      },
      link: linkFn,
      controller: SfeMultiLanguageInputController,
      controllerAs: 'vm',
      bindToController: true
    };
    return directive;

    function linkFn() {}
  }
];

SfeMultiLanguageInputController.$inject = ['$scope', 'LocalizationService'];

function SfeMultiLanguageInputController($scope, LocalizationService) {
  var vm = this;
  vm.languageIsMissing = languageIsMissing;
  vm.$onInit = init;

  function init() {
    vm.languages = angular.copy(LocalizationService.languages);
    selectLanguage(vm.languages[0]);
    if (vm.parentForm) {
      $scope.ItemForm = vm.parentForm;
    }
    if (!vm.model) {
      vm.model = {};
      vm.languages.forEach(function(lang) {
        vm.model[lang.networkCode] = '';
      });
    } else {
      vm.languages.forEach(function(lang) {
        if (!vm.model[lang.networkCode]) {
          vm.model[lang.networkCode] = '';
        }
      });
    }
  }

  function languageIsMissing() {
    var missing = false;
    _.each(vm.model, function(item) {
      if (!item) {
        missing = true;
      }
    });
    return missing;
  }

  function selectLanguage() {
    var selected = LocalizationService.GetSelectedLang();
    _.each(vm.languages, function(lang) {
      if (selected && selected.networkCode === lang.networkCode) {
        lang.selectedLanguage = true;
      } else {
        lang.selectedLanguage = false;
      }
    });
  }
}
