import './ui-calendar.scss';

OnDutyController.$inject = [
  'AlertingService',
  'gettext',
  'gettextCatalog',
  'ColorService',
  'MetadataService',
  'WorkScheduleModel',
  'UserModel'
];

function OnDutyController(
  AlertingService,
  gettext,
  gettextCatalog,
  ColorService,
  MetadataService,
  WorkScheduleModel,
  UserModel
) {
  var vm = this;
  vm.loading = true;
  // OnDuty controller logic
  vm.eventSources = [];
  vm.uiConfig = {
    view: {
      name: gettext('Assigned personnel'),
      type: 'on-duty'
    },

    calendar: {
      firstDay: 1,
      timezone: 'local',
      slotEventOverlap: false,
      slotLabelFormat: 'hh:mm',
      timeFormat: 'hh:mm',
      eventBorderColor: '#fff',
      displayEventTime: false,
      views: {
        week: {
          columnFormat: 'ddd D.M.'
        }
      },

      selectable: true,
      editable: false,

      header: {
        right: 'today agendaDay agendaWeek month',
        left: 'prev title next'
      },
      allDayText: gettextCatalog.getString('all day'),
      dayNames: [
        gettextCatalog.getString('Sunday'),
        gettextCatalog.getString('Monday'),
        gettextCatalog.getString('Tuesday'),
        gettextCatalog.getString('Wednesday'),
        gettextCatalog.getString('Thursday'),
        gettextCatalog.getString('Friday'),
        gettextCatalog.getString('Saturday')
      ],
      dayNamesShort: [
        gettextCatalog.getString('Sun'),
        gettextCatalog.getString('Mon'),
        gettextCatalog.getString('Tue'),
        gettextCatalog.getString('Wed'),
        gettextCatalog.getString('Thu'),
        gettextCatalog.getString('Fri'),
        gettextCatalog.getString('Sat')
      ]
    },
    refreshData: function() {
      vm.loading = true;
      init();
    }
  };
  init();

  function init() {
    var today = gettextCatalog.getString('Today');
    var day = gettextCatalog.getString('Day');
    var week = gettextCatalog.getString('Week');
    var month = gettextCatalog.getString('Month');
    MetadataService.Loading(true);
    var waterfall = [
      function getUsers(callback) {
        UserModel.read().then(
          function(res) {
            callback(null, res.data);
          },
          function(err) {
            AlertingService.Error(err);
            callback(true);
          }
        );
      },
      function getSchedules(users, callback) {
        WorkScheduleModel.read({
          type: 1
        }).then(
          function(res) {
            callback(null, users, res.data);
          },
          function(err) {
            AlertingService.Error(err);
            callback(true);
          }
        );
      }
    ];

    async.waterfall(waterfall, function(err, users, schedules) {
      if (!err) {
        var events = [];
        _.map(schedules, function(item) {
          item.dateFrom = new Date(item.dateFrom);
          item.dateTo = new Date(item.dateTo);
          item.start = item.dateFrom;
          item.end = item.dateTo;
          item.userId = item.user;
          var findUser = _.find(users, {
            _id: item.user
          });
          if (findUser) {
            if (findUser.family_name) {
              item.title = findUser.name + ' ' + findUser.family_name;
            } else {
              item.title = findUser.name;
            }
          }
          if (item.userId in events) {
            events[item.userId].push(item);
          } else {
            events[item.userId] = [item];
          }
        });
        while (vm.eventSources.length > 0) {
          vm.eventSources.pop();
        }
        Object.keys(events).forEach(function(key) {
          var color = ColorService.RandomColorFromString(events[key][0].userId);
          var textColor = ColorService.IsColorDark(color) ? 'white' : 'black';
          textColor += '!important';
          vm.eventSources.push({
            color: color,
            textColor: textColor,
            events: events[key]
          });
        });
        vm.loading = false;
      }
      MetadataService.Loading(false);
      $('.fc-today-button').text(today);
      $('.fc-agendaDay-button').text(day);
      $('.fc-agendaWeek-button').text(week);
      $('.fc-month-button').text(month);
      $('.fc-button')
        .removeClass(
          'fc-state-default fc-state-active fc-button fc-corner-left fc-corner-right'
        )
        .addClass('md-primary md-button md-ink-ripple md-tis-theme');
    });
  }
}

export default OnDutyController;
