/**
 * @ngdoc service
 * @name common.CronValidatorService
 * @description description of service.
 * @property {function} dateCronMatch - See dateCronMatch method.
 */

export default function CronValidatorService() {
  /**
   * @memberof common.CronValidatorService
   * @description Looks at the parsedCron
   * @param {Object} parsedCron - object parsed from cron data in human readable entries
   * @param {string} date - a date
   * @return {boolean} returns true
   */
  function dateCronMatch(parsedCron, date) {
    var formatedDate = new Date(date * 1);
    var Month = formatedDate.getMonth() + 1; //js month starts with 0
    var MonthDay = formatedDate.getDate();
    var WeekDay = formatedDate.getDay();
    var Minute = formatedDate.getMinutes();
    var Hour = formatedDate.getHours();
    var Seconds = formatedDate.getSeconds();

    var isMonth = _.find(parsedCron.month, function(el) {
      return el === Month;
    });

    var isMonthDay = _.find(parsedCron.monthday, function(el) {
      return el === MonthDay;
    });

    var isWeekDay = _.find(parsedCron.weekday, function(el) {
      return el === WeekDay;
    });

    var isMinute = _.find(parsedCron.minute, function(el) {
      return el === Minute;
    });

    var isHour = _.find(parsedCron.hour, function(el) {
      return el === Hour;
    });

    var isSecond = _.find(parsedCron.second, function(el) {
      return el === Seconds;
    });

    return (
      typeof isMonth !== 'undefined' &&
      typeof isMonthDay !== 'undefined' &&
      typeof isWeekDay !== 'undefined' &&
      typeof isMinute !== 'undefined' &&
      typeof isHour !== 'undefined' &&
      typeof isSecond !== 'undefined'
    );
  }

  return {
    dateCronMatch: dateCronMatch
  };
}
