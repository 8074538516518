import { DateTime } from 'luxon';

TangoAssetAvailabilityEventForm.$inject = [
  'gettextCatalog',
  '$timeout',
  'StandardUtils',
  '$mdDialog',
  'DateLocalizationService',
  'SfeForm2DateFromPriorToValidationService',
  'AssetAvailabilityEventModel',
  'AlertingService',
  'TranslationService'
];
/**
 * @ngdoc service
 * @name companyResources.TangoAssetAvailabilityEventForm
 * @description returns asset Availability Event Properties
 * @property {function} create
 * @property {function} TangoAssetAvailabilityEventForm
 */
export default function TangoAssetAvailabilityEventForm(
  gettextCatalog,
  $timeout,
  StandardUtils,
  $mdDialog,
  DateLocalizationService,
  SfeForm2DateFromPriorToValidationService,
  AssetAvailabilityEventModel,
  AlertingService,
  TranslationService
) {
  later.date.localTime();

  /**
   * @description crawler params
   * @function
   * @param {string} text
   * @return {Object}
   */
  const crawlerParams = text => {
    let filter = {
      order: '-name'
    };
    if (text != null && text != '') {
      filter = {
        ...filter,
        full_name: text
      };
    }
    return filter;
  };

  /**
   * @description display function for dialog
   * @function
   * @param {Object} item item
   * @return {Object}
   */
  const display = item => {
    let chosenImage = {
      endpoint: StandardUtils.getUserImageUrl(item, 'sm'),
      gravatarId: item._id
    };
    return {
      text: `${
        item != null ? item.name : gettextCatalog.getString('Unknown user')
      } ${item && item.family_name ? item.family_name : ''}`,
      chosenImage
    };
  };

  /**
   * @description returns validation object that validates selected date according to time series' scheduler cron.
   * @function
   * @param {Object} schedule later schedule object
   * @return {Object}
   */
  function getDateCronValidation(schedule) {
    return {
      fn: model => {
        if (Array.isArray(model) && model[0] != null) {
          let res = schedule.isValid(model[0]);
          return res;
        }
        return true;
      },
      text: gettextCatalog.getString('Date does not match cron expression')
    };
  }

  /**
   * @description returns currently selected date or new Date.
   * @function
   * @param {Object} api form api
   * @param {String} dateId field id
   * @return {Date}
   */
  function getCurrentDate(api, dateId) {
    let modelValue = api.getValue(dateId);
    let currentDate;
    if (Array.isArray(modelValue) && modelValue[0] != null) {
      currentDate = modelValue[0];
    } else {
      currentDate = new Date();
    }
    return currentDate;
  }

  /**
   * @description sets next  date.
   * @function
   * @param {Object} schedule later schedule
   * @param {String} dateId field id
   */
  const setNextDate = (schedule, dateId) => api => {
    let currentDate = getCurrentDate(api, dateId);
    let nextDate = schedule.next(2, currentDate)[1];
    api.setValue(dateId, nextDate);
  };

  /**
   * @description sets previous  date.
   * @function
   * @param {Object} schedule later schedule
   * @param {String} dateId field id
   */
  const setPreviousDate = (schedule, dateId) => api => {
    let currentDate = getCurrentDate(api, dateId);
    let nextDate = schedule.prev(2, currentDate)[1];
    api.setValue(dateId, nextDate);
  };

  /**
   * @description construct api object from values.
   * @function
   * @param {Object} values
   * @return {Object}
   */
  const constructApiObject = values => {
    return {
      eventType: values.eventType,
      responsiblePerson: values.responsiblePerson._id,
      validFrom: new Date(values.validFrom[0]).toISOString(),
      validTo: new Date(values.validTo[0]).toISOString(),
      value: values.value
    };
  };

  /**
   * @description returns asset availability form configuration.
   * @function
   * @param {Object} asset
   * @return {Object}
   */
  function getForm(event, asset) {
    const assetAvailability = asset.availability;
    let startOfAvailability = new Date(assetAvailability.startOfAvailability);
    let startOfAvailabilityFormatted = DateLocalizationService.LocalizationDateIntervalFn(
      's'
    )(startOfAvailability);
    const { timeSeries } = assetAvailability;
    let cron = timeSeries.dataScheduler.crontabExpression;
    //cron validation object
    const schedule = later.schedule(later.parse.cron(cron, true));
    let validCron = getDateCronValidation(schedule);
    //Remove hours, minute, seconds from cron to filter values that are visible in calendar
    let cronConfig = later.parse.cron(cron, true);
    const calendarSchedule = later.schedule(cronConfig);
    //disables dates in calender selector
    const disableDates = [
      /**
       * @description returns true when dates shown in calendar should be disabled (CRON).
       * @function
       * @param {Date} date
       * @return {Boolean}
       */
      date => {
        if (date != null) {
          return !calendarSchedule.isValid(date);
        }
        return false;
      }
    ];

    let onlyInteger = false;
    //INTEGER
    if (timeSeries.dataType === 2) {
      onlyInteger = true;
    }

    let configuration = {
      name: 'assetAvailabilityEvent',
      title: gettextCatalog.getString('Asset Availability Event'),
      actions: [
        {
          title: gettextCatalog.getString('Save'),
          /**
           * @description resolves dialog with values
           * @function
           * @param {object} api
           */
          fn: async api => {
            let savedAvailabilityEvent;
            api.revalidate();
            await $timeout();
            if (!api.formValidity()) {
              return;
            }

            try {
              const values = api.getValues();
              const body = constructApiObject(values);
              const { data } = await AssetAvailabilityEventModel.create(
                { assetId: asset._id },
                body
              );
              savedAvailabilityEvent = data;
            } catch (err) {
              AlertingService.Error(err);
            }
            $mdDialog.hide(savedAvailabilityEvent);
          },
          disabledFn: api => {
            if (typeof api.formValidity == 'function') {
              return !api.formValidity();
            }
          }
        }
      ],
      fields: [
        {
          id: 'value',
          title: gettextCatalog.getString('Value'),
          type: {
            name: 'text',
            options: {
              type: 'numerical',
              onlyInteger
            }
          },
          initialize: () => {
            return event != null ? event.value : '';
          },
          validators: {
            validValue: {
              fn: value => {
                if (
                  assetAvailability != null &&
                  assetAvailability.minAllowedValue != null &&
                  assetAvailability.maxAllowedValue != null &&
                  (value < assetAvailability.minAllowedValue ||
                    value > assetAvailability.maxAllowedValue)
                ) {
                  return false;
                }
                return true;
              },
              text: gettextCatalog.getString(
                `Value must be between ${assetAvailability.minAllowedValue} and ${assetAvailability.maxAllowedValue}`
              )
            },
            maxValidValue: {
              fn: value => {
                if (
                  assetAvailability != null &&
                  assetAvailability.minAllowedValue == null &&
                  assetAvailability.maxAllowedValue != null &&
                  value > assetAvailability.maxAllowedValue
                ) {
                  return false;
                }
                return true;
              },
              text: gettextCatalog.getString(
                `Value must be equal or less than ${assetAvailability.maxAllowedValue}`
              )
            },
            minValidValue: {
              fn: value => {
                if (
                  assetAvailability != null &&
                  assetAvailability.minAllowedValue != null &&
                  assetAvailability.maxAllowedValue == null &&
                  value < assetAvailability.minAllowedValue
                ) {
                  return false;
                }
                return true;
              },
              text: gettextCatalog.getString(
                `Value must be equal or greater than ${assetAvailability.minAllowedValue}`
              )
            }
          },
          required: true
        },
        {
          id: 'eventType',
          title: gettextCatalog.getString('Status'),
          type: {
            name: 'radio',
            options: {
              layout: 'row',
              items: TranslationService.GetCollection(
                'codelists.assetAvailabilityEventTypes'
              ),
              modelProperty: 'id',
              display: data => {
                return data && data.name
                  ? data.name
                  : gettextCatalog.getString('Unknown');
              }
            }
          },
          initialize: () => {
            return event != null ? event.eventType : 100;
          },
          onChange: form => {
            if (form != null) {
              form.revalidate();
            }
          },
          required: true
        },
        {
          id: 'validFrom',
          title: gettextCatalog.getString('From'),
          width: 6,
          type: {
            name: 'date',
            options: {
              setNextDate: setNextDate(schedule, 'validFrom'),
              setPreviousDate: setPreviousDate(schedule, 'validFrom'),
              enableTime: true,
              enableSeconds: true,
              customOptions: {
                disable: disableDates
              }
            }
          },
          initialize: form => {
            let newDate = new Date();
            let initDate = schedule.next(
              2,
              newDate.setHours(newDate.getHours() + 2)
            )[1];
            if (form != null) {
              $timeout(() => {
                form.revalidate();
                form.setDirty('validFrom');
              });
            }
            return [initDate];
          },
          required: true,
          //TRIGGER REVALIDATE ON CHANGE
          onChange: async api => {
            if (typeof api.revalidate == 'function') {
              await $timeout();
              api.revalidate();
            }
          },
          validators: {
            maxDateCustom: SfeForm2DateFromPriorToValidationService.get(
              'validFrom',
              'validTo'
            ),
            moreThanStartOfAvailability: {
              fn: model => {
                if (Array.isArray(model) && model[0] != null) {
                  return model[0] >= startOfAvailability;
                }
                return true;
              },
              text: gettextCatalog.getString(
                `Valid from can not be prior to start date of asset availability ${startOfAvailabilityFormatted}`
              )
            },
            planedDate: {
              fn: (model, _, api) => {
                let eventType = api.getValue('eventType');
                //WHEN EVENT IS PLANNED IT CANT BEGIN BEFORE NOW
                if (
                  eventType === 100 &&
                  Array.isArray(model) &&
                  model[0] != null
                ) {
                  return (
                    model[0] >=
                    DateTime.fromJSDate(new Date())
                      .plus({ hour: 2 })
                      .toJSDate()
                  );
                }
                return true;
              },
              text: gettextCatalog.getString(
                'Planned events cannot start prior to two hours from now.'
              )
            },
            validCron
          }
        },
        {
          id: 'validTo',
          title: gettextCatalog.getString('To'),
          width: 6,
          type: {
            name: 'date',
            options: {
              setNextDate: setNextDate(schedule, 'validTo'),
              setPreviousDate: setPreviousDate(schedule, 'validTo'),
              enableTime: true,
              enableSeconds: true,
              customOptions: {
                disable: disableDates
              }
            }
          },
          initialize: () => {
            return null;
          },
          required: true,
          //TRIGGER REVALIDATE ON CHANGE
          onChange: async api => {
            if (typeof api.revalidate == 'function') {
              await $timeout();
              api.revalidate();
            }
          },
          validators: {
            maxDateCustom: SfeForm2DateFromPriorToValidationService.get(
              'validFrom',
              'validTo'
            ),
            validCron
          }
        },
        {
          id: 'responsiblePerson',
          title: gettextCatalog.getString('Responsible person'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'users',
                method: 'read'
              },
              crawlerParams,
              display,
              dialog: {
                entity: 'users'
              }
            }
          },
          initialize: () => {
            if (event != null && event.responsiblePerson != null) {
              return event.systemResponsiblePerson;
            }
            return null;
          },
          required: true
        }
      ]
    };
    return configuration;
  }
  return {
    getForm
  };
}
