TangoWizardConfiguration.$inject = [
  'TimeSeriesWizardConfiguration',
  'codelistsConstant'
];
/**
 * @ngdoc service
 * @name common.TangoWizardConfiguration
 * @description Get configuration for current entity.
 * @property {function} get
 */
export default function TangoWizardConfiguration(
  TimeSeriesWizardConfiguration,
  codelistsConstant
) {
  /**
   * @memberof TangoItemConfiguration.get
   * @description  returns object that contains configurations to build tango item
   * @param {Object} item - timeSeries data, used for editing & duplicating
   * @param {number} entity - codelist entity id
   * @param {string} entityId - entity id
   * @param {Function} onSaveAction - optional function that's executed on save
   * @return {Object} configuration
   */
  async function get(item, entity, entityId, onSaveAction) {
    let entityName;
    if (typeof entity === 'number') {
      let entityCodelist = codelistsConstant.entities.find(
        entityCodelist => entityCodelist.id === entity
      );
      entityName = entityCodelist != null ? entityCodelist.name : null;
    }
    switch (entityName) {
    // Time series
    case 'TimeSeries':
      return await TimeSeriesWizardConfiguration.get(
        item,
        entityName,
        entityId,
        onSaveAction
      );
    default:
      return null;
    }
  }
  return {
    get
  };
}
