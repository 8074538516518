module.exports = {
  entity: 'rules',
  path: 'rules',
  name: {
    en: 'Rules',
    sl_SI: 'Pravila'
  },
  networkModel: {
    entity: 'rules',
    read: 'read'
  },
  scope: 'tis_be:rules',

  dialog: {
    title: {
      en: 'Select rule',
      sl_SI: 'Izberite pravilo'
    },
    listInfo: {
      en: 'Select rule from the list below',
      sl_SI: 'Izberite pravilo s spodnjega seznama'
    }
  }
}
