CalculatedFlowattributesTimeSeriesConfigurations.$inject = [
  'gettext',
  'TranslationService'
];

function CalculatedFlowattributesTimeSeriesConfigurations(
  gettext,
  TranslationService
) {
  /**
   * @description get attribute domain.
   * @function
   * @param {Object} domainAttribute
   * @return {Object} attributeDomain
   */
  function getAttributeDomain(domainAttribute) {
    let attributeDomain = {};
    attributeDomain.text = domainAttribute.name;
    let domainAttributeLink = JSON.stringify({
      id: domainAttribute._id
    });
    attributeDomain.link = `configurations-dynamic-attributes-dynamic-attributes-list-view(${domainAttributeLink})`;
    return attributeDomain;
  }

  /**
   * @description get attribute entity.
   * @function
   * @param {Object} dynamicAttribute
   * @param {Object} attributeType
   * @return {Object} attributeEntity
   */
  function getAttributeEntity(dynamicAttribute, attributeType) {
    let attributeEntity = {};
    attributeEntity.text = dynamicAttribute.attributeObjectId.name;
    let attributeTypeLinkParam = JSON.stringify({
      id: dynamicAttribute.attributeObjectId._id
    });
    // Asset
    if (attributeType.id === 1) {
      attributeEntity.link = `company-resources-assets-view(${attributeTypeLinkParam})`;
      attributeEntity.icon = {
        color: '#75448d',
        type: 2,
        name: 'router'
      };
      // Location
    } else if (attributeType.id === 2) {
      attributeEntity.link = `company-resources-locations-view(${attributeTypeLinkParam})`;
      attributeEntity.icon = {
        color: '#009a96',
        type: 2,
        name: 'location_city'
      };
    }
    return attributeEntity;
  }
  /**
   * @description get attributes configuration.
   * @function
   * @param {Object} data
   * @return {Array} attributes
   */
  function getAttributes(data) {
    let attributes;
    let items = [];
    if (data != null) {
      if (Array.isArray(data.inputDynamicAttributes)) {
        data.inputDynamicAttributes = data.inputDynamicAttributes.filter(
          dynamicAttribute => dynamicAttribute != null
        );
        // Iterate over dynamic attributes and construct attributes configuration
        items = data.inputDynamicAttributes.map(dynamicAttribute => {
          let attribute = {};
          attribute.variableName = dynamicAttribute.name;
          attribute.type = 'variable';
          attribute.values = [];
          if (dynamicAttribute.domainAttribute != null) {
            attribute.values.push(
              getAttributeDomain(dynamicAttribute.domainAttribute)
            );
          }

          let attributeType = TranslationService.GetCollectionById(
            'codelists.attributeObjectTypes',
            dynamicAttribute.attributeObjectType
          );
          if (
            attributeType != null &&
            dynamicAttribute.attributeObjectId != null
          ) {
            attribute.values.push(
              getAttributeEntity(dynamicAttribute, attributeType)
            );
          }
          return { items: [attribute] };
        });
      }
      attributes = {
        title: gettext('Attributes'),
        groupItems: items,
        testId: 'attributes'
      };
      return [attributes];
    }
    return [];
  }
  return {
    getAttributes
  };
}

export default CalculatedFlowattributesTimeSeriesConfigurations;
