TangoItemConfiguration.$inject = [
  'HeaderTimeSeriesConfiguration',
  'ContentTimeSeriesConfiguration',
  'PropertiesTimeSeriesConfiguration',
  'DetailTimeSeriesConfiguration',
  'TopPropertiesTimeSeriesConfiguration',
  'EntitiesService'
];
/**
 * @ngdoc service
 * @name common.TangoItemConfiguration
 * @description opens datasource tag selector dialog.
 * @property {function} open
 */
export default function TangoItemConfiguration(
  HeaderTimeSeriesConfiguration,
  ContentTimeSeriesConfiguration,
  PropertiesTimeSeriesConfiguration,
  DetailTimeSeriesConfiguration,
  TopPropertiesTimeSeriesConfiguration,
  EntitiesService
) {
  /**
   * @memberof TangoItemConfiguration.get
   * @description  returns object that contains configurations to build tango item
   * @param {number} entity code list entity id
   * @param {string} entityId entity id
   * @return {Object}
   */
  function get(entity, entityId) {
    //Use mapped names
    const entityName = EntitiesService.getEntityById(entity);
    switch (entityName) {
    case 'time-series': //time series
      return {
        headerConfiguration: HeaderTimeSeriesConfiguration.get(entityId),
        contentConfiguration: ContentTimeSeriesConfiguration.get(entityId),
        topPropertiesConfiguration: TopPropertiesTimeSeriesConfiguration.get(
          entityId
        ),
        propertiesConfiguration: PropertiesTimeSeriesConfiguration.get(
          entityId
        ),
        detailConfiguration: DetailTimeSeriesConfiguration.get(entityId)
      };
    default:
      return null;
    }
  }
  return {
    get
  };
}
