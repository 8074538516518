MeasuringpointAssetsEntityDateConfiguration.$inject = [
  'gettext',
  '$q',
  'AlertingService',
  'OwnershipModel',
  'gettextCatalog',
  'CrudToastFactory',
  'Formatting',
  'AssetMeasuringpointModel'
];

function MeasuringpointAssetsEntityDateConfiguration(
  gettext,
  $q,
  AlertingService,
  OwnershipModel,
  gettextCatalog,
  CrudToastFactory,
  Formatting,
  AssetMeasuringpointModel
) {
  var createObject = {
    validFrom: new Date(new Date().setHours(0, 0, 0, 0)),
    validTo: null
  };

  var configuration = {
    listId: 'measuringPointAssets',
    type: 'single',
    entity: {
      inactiveTitle: gettext('Inactive Assets'),
      title: gettext('Assets')
    },
    api: {
      query: {
        entity: 'asset-measuringpoints',
        method: 'custom.readAsset'
      },
      activeFilterObject: {
        isActive: true
      },
      inactiveFilterObject: {
        isActive: false
      },
      enrich: Enrich
    },
    create: {
      form: getMeasuringpointAssetFormConfig(),
      createObject: angular.copy(createObject),
      createFn: create
    },
    update: update,
    getUpdateObject: getUpdateObject,
    delete: function(item) {
      var deferred = $q.defer();
      AssetMeasuringpointModel.delete({
        id: item._id
      }).then(
        function() {
          CrudToastFactory.toast('delete');
          deferred.resolve();
        },
        function(err) {
          AlertingService.Error(err);
          deferred.reject();
        }
      );
      return deferred.promise;
    },
    title: {
      param: 'nameReadable',
      link: {
        state: 'company-resources-assets-view',
        params: [
          {
            valueParam: 'assetId',
            queryParam: 'id'
          }
        ]
      }
    },
    shortActions: [
      {
        type: 'edit',
        icon: 'mode_edit'
      },
      {
        icon: 'delete',
        type: 'delete'
      }
    ],
    badges: [
      {
        title: gettext('Asset Status'),
        param: 'statusReadable'
      }
    ],
    attributes: [
      {
        title: gettext('Valid from'),
        param: 'validFromReadable',
        type: 'date-short'
      },
      {
        title: gettext('Valid to'),
        param: 'validToReadable',
        type: 'date-short'
      },
      {
        title: gettext('Type'),
        param: 'typeName',
        paramValue: 'assetTypeId',
        link: {
          state: 'configurations-company-resources-assets-asset-types-view',
          params: [
            {
              valueParam: 'type',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        title: gettext('Brand'),
        param: 'brandName',
        type: 'text'
      },
      {
        title: gettext('Model'),
        param: 'assetModelName',
        type: 'text'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ],
    extended: {
      query: Extend,
      items: [
        {
          title: gettext('Ownership'),
          param: 'ownershipName',
          type: 'text'
        }
      ]
    }
  };

  function Enrich(item) {
    var deferred = $q.defer();
    item.fetchingObject['validFromReadable'] = {
      fetching: false,
      success: true,
      nodata: false
    };
    item.fetchingObject['validToReadable'] = {
      fetching: false,
      success: true,
      nodata: false
    };
    item.fetchingObject['statusReadable'] = {
      fetching: true,
      success: true,
      nodata: false
    };
    item.fetchingObject['typeName'] = {
      fetching: true,
      success: true,
      nodata: false
    };
    item.fetchingObject['brandName'] = {
      fetching: true,
      success: true,
      nodata: false
    };
    item.fetchingObject['assetModelName'] = {
      fetching: true,
      success: true,
      nodata: false
    };

    var asset = item.asset;
    if (asset) {
      item.assetId = asset._id;
      item.nameReadable = asset.name;

      if (
        item.hasOwnProperty('validFrom') &&
        item.validFrom !== null &&
        item.validFrom !== undefined
      ) {
        item.validFromReadable = item.validFrom;
      } else {
        item.fetchingObject['validFromReadable'].nodata = true;
      }

      if (
        item.hasOwnProperty('validTo') &&
        item.validTo !== null &&
        item.validTo !== undefined
      ) {
        item.validToReadable = item.validTo;
      } else {
        item.fetchingObject['validToReadable'].nodata = true;
      }
      setMetadata(item, 'status', 'statusReadable');
      setMetadata(item, 'type', 'typeName');
      setMetadata(item, 'assetModel', 'assetModelName');
      setMetadata(item, 'assetBrand', 'brandName');
    }

    return deferred.promise;
  }

  function setMetadata(item, valueParam, displayParam) {
    item.fetchingObject[displayParam].fetching = false;
    if (item.asset[valueParam] && typeof item.asset[valueParam] === 'object') {
      item[displayParam] = item.asset[valueParam].name;
    } else {
      item.fetchingObject[displayParam].nodata = true;
    }
  }

  function getMeasuringpointAssetFormConfig() {
    return [
      {
        name: 'asset',
        edit: true,
        componentType: 'autocompleteDialog',
        configuration: {
          query: {
            entity: 'assets',
            method: 'read'
          },
          required: true,
          floatingLabel: gettext('Select Asset'),
          searchParamName: 'filter',
          entity: 'assets',
          createRedirect: {
            state: 'company-resources-assets-new'
          }
        }
      },
      {
        componentType: 'datePickerFromTo',
        from: 'validFrom',
        to: 'validTo',
        valToEmpty: true,
        labelFrom: gettext('Valid from *'),
        labelTo: gettext('Valid to'),
        required: true,
        onlyStartRequired: true,
        idFrom: 'assetfrom',
        idTo: 'assetto'
      }
    ];
  }

  function create(obj, paramObject) {
    var deferred = $q.defer();
    var apiObj = {
      validFrom: Formatting.formatDate(obj.validFrom, '00:00:00'),
      validTo: Formatting.formatDate(obj.validTo, '23:59:59'),
      asset: obj.asset._id
    };
    apiObj = Object.assign(apiObj, paramObject);
    AssetMeasuringpointModel.create(apiObj).then(
      function() {
        CrudToastFactory.toast('create');
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );
    return deferred.promise;
  }

  function update(obj, originalItem, paramObject) {
    var deferred = $q.defer();
    var apiObj = {
      validFrom: Formatting.formatDate(obj.validFrom, '00:00:00'),
      validTo: Formatting.formatDate(obj.validTo, '23:59:59') || null,
      asset: obj.asset._id
    };
    apiObj = Object.assign(apiObj, paramObject);
    AssetMeasuringpointModel.update(
      {
        id: originalItem._id
      },
      apiObj
    ).then(
      function() {
        CrudToastFactory.toast('update');
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );
    return deferred.promise;
  }

  function getUpdateObject(item) {
    var validFrom = new Date(item.validFrom);
    var validTo =
      item.validTo && new Date(item.validTo) instanceof Date
        ? new Date(item.validTo)
        : null;
    return {
      validFrom: validFrom,
      validTo: validTo,
      asset: item.asset
    };
  }

  function Extend(item) {
    var deferred = $q.defer();
    var asset = item.asset;
    var parallel = [ReadOwnerShip];

    async.parallel(parallel, function() {
      deferred.resolve();
    });

    return deferred.promise;

    function ReadOwnerShip(callback) {
      OwnershipModel.read({
        id: asset.ownership
      }).then(
        function(res) {
          item.ownershipName = res.data.name;
          callback();
        },
        function(err) {
          AlertingService.Error(err);
          item.ownershipName = gettextCatalog.getString('Unknown');
          callback();
        }
      );
    }
  }

  return configuration;
}
export default MeasuringpointAssetsEntityDateConfiguration;
