module.exports = {
  entity: 'tasks',
  path: 'tasks',
  name: {
    en: 'Task',
    sl_SI: 'Naloga'
  },
  networkModel: {
    entity: 'tasks',
    read: 'read'
  },
  scope: 'tis_be:tasks',

  dialog: {
    title: {
      en: 'Select task',
      sl_SI: 'Izberite nalogo'
    },
    listInfo: {
      en: 'Select task from the list below',
      sl_SI: 'Izberite nalogo s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      }
    ]
  }
}
