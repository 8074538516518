AllowedFunctionsService.$inject = ['$injector', 'gettextCatalog'];

function AllowedFunctionsService($injector, gettextCatalog) {
  const errors = {
    EK001: {
      meaning:
        'Main measuring point {{measuringPointName}} (id: {{measuringPointId}}) has no F allocator and no variable detailInvoiceItem for tariff {{tariffCode}}.',
      lang: {
        en:
          'Main measuring point {{measuringPointName}} (id: {{measuringPointId}}) has no F allocator and no variable detailInvoiceItem for tariff {{tariffCode}}.',
        sl:
          'Glavno merilno mesto {{measuringPointName}} (id: {{measuringPointId}}) nima F (fiksnega) delilnika in ne variabilne postavke na računu za tarifo {{tariffCode}}.',
        hr:
          'Glavno merilno mesto {{measuringPointName}} (id: {{measuringPointId}}) nima F (fiksnega) delilnika in ne variabilne postavke na računu za tarifo {{tariffCode}}.'
      }
    },
    EK002: {
      meaning: 'Tariff has no itemsForTariff.',
      lang: {
        en: 'Tariff {{tariffCode}} has no itemsForTariff.',
        sl:
          'Tarifa {{tariffCode}} nima podatka na postavki za energetsko upravljanje, ki je na postavki na računu.',
        hr:
          'Tarifa {{tariffCode}} nima podatka na postavki za energetsko upravljanje, ki je na postavki na računu.'
      }
    },
    EK003: {
      meaning: 'Tariff missmatch should only happen with ET tariff',
      lang: {
        en: 'Tariff missmatch should only happen with ET tariff',
        sl:
          'Neusklajenost med tarifami se lahko zgodi samo v primeru ET (enotne tarife).',
        hr:
          'Neusklajenost med tarifami se lahko zgodi samo v primeru ET (enotne tarife).'
      }
    },
    EK004: {
      meaning: 'Invalid tariff configuration',
      lang: {
        en: 'Invalid tariff configuration',
        sl:
          'Neveljavna konfiguracija tarif na enem od merilnih mest, ki so zajeti v postopek obračunskega algoritma.',
        hr:
          'Neveljavna konfiguracija tarif na enem od merilnih mest, ki so zajeti v postopek obračunskega algoritma.'
      }
    },
    EK005: {
      meaning: 'Invalid stakeholderref found',
      lang: {
        en: 'Invalid stakeholderref: {{stakeholderRef}}',
        sl:
          'Neveljavna konfiguracija deležnikov na delilniku na enem od merilnih mest, ki so zajeti v postopek obračunskega algoritma.',
        hr:
          'Neveljavna konfiguracija deležnikov na delilniku na enem od merilnih mest, ki so zajeti v postopek obračunskega algoritma.'
      }
    },
    EK006: {
      meaning: 'Invalid billingKind found',
      lang: {
        en: 'Invalid billingKind {{billingKind}}',
        sl:
          'Neveljavna konfiguracija načina obračuna na delilniku na enem od merilnih mest, ki so zajeti v postopek obračunskega algoritma.',
        hr:
          'Neveljavna konfiguracija načina obračuna na delilniku na enem od merilnih mest, ki so zajeti v postopek obračunskega algoritma.'
      }
    },
    EK007: {
      meaning: 'Aggregation for measuring points returned no results',
      lang: {
        en: 'Aggregation for measuring points returned no results',
        sl: 'Agregacija merilnih mest ni vrnila nobenega rezultata.',
        hr: 'Agregacija merilnih mest ni vrnila nobenega rezultata.'
      }
    },
    EK008: {
      meaning: 'Aggregation for tariffs returned no results',
      lang: {
        en: 'Aggregation for tariffs returned no results',
        sl: 'Agregacija tarif ni vrnila nobenega rezultata.',
        hr: 'Agregacija tarif ni vrnila nobenega rezultata.'
      }
    },
    EK009: {
      meaning:
        'Measuringpoint found that has no connected timeSeriesType with readingType=2 (USAGE)',
      lang: {
        en:
          'Measuringpoint {{measuringpointId}} has no connected timeSeriesType with readingType=2 (USAGE)',
        sl:
          'Merilno mesto {{measuringpointId}} nima nobene časovne vrste s tipom časovne vrste PORABA.',
        hr:
          'Merilno mesto {{measuringpointId}} nima nobene časovne vrste s tipom časovne vrste PORABA.'
      }
    },
    EK010: {
      meaning:
        'Measuringpoint found that has multiple connected timeSeriesType with readingType=2 (USAGE)',
      lang: {
        en:
          'Measuringpoint {{measuringpointId}} has multiple connected timeSeriesType with readingType=2 (USAGE)',
        sl:
          'Merilno mesto {{measuringpointId}} ima več časovnih vrst s tipom časovne vrste PORABA.',
        hr:
          'Merilno mesto {{measuringpointId}} ima več časovnih vrst s tipom časovne vrste PORABA.'
      }
    },
    EK011: {
      meaning: 'Found multiple timeSeries that have same timeSeriesType',
      lang: {
        en:
          'Found multiple timeSeries that have timeSeriesType {{tsTypeWithReadingTypeUsageId}}: {{matchingTimeSeries1Id}} and {{matchingTimeSeries2Id}}',
        sl:
          'Najdeni sta bili časovni vrsti ({{matchingTimeSeries1Id}} in {{matchingTimeSeries2Id}}), ki imata isti tip časovne vrste ({{tsTypeWithReadingTypeUsageId}}). Na merilnem mestu za obračun je lahko samo ena časovna vrsta z istim tipom časovne vrste.',
        hr:
          'Najdeni sta bili časovni vrsti ({{matchingTimeSeries1Id}} in {{matchingTimeSeries2Id}}), ki imata isti tip časovne vrste ({{tsTypeWithReadingTypeUsageId}}). Na merilnem mestu za obračun je lahko samo ena časovna vrsta z istim tipom časovne vrste.'
      }
    },
    EK012: {
      meaning: 'Multiple matched timeSeries found for VE, VO or VD',
      lang: {
        en: 'Multiple matched timeSeries for {{billingType}}',
        sl:
          'Najdenih je več postavk računu za kombinacijo {{billingType}} (variabilne postavke in tip obračuna - Energent, Omrežnina in Davki) na računu.',
        hr:
          'Najdenih je več postavk računu za kombinacijo {{billingType}} (variabilne postavke in tip obračuna - Energent, Omrežnina in Davki) na računu.'
      }
    },
    EK013: {
      meaning: 'More than one timeseries per tariff found',
      lang: {
        en: 'More than one timeseries per tariff found',
        sl:
          'Najdenih je več kot ena časovna vrsta na tarifo na merilnem mestu.',
        hr: 'Najdenih je več kot ena časovna vrsta na tarifo na merilnem mestu.'
      }
    },
    EK014: {
      meaning: 'Multiple main measuring points found',
      lang: {
        en: 'Multiple main measuring points found',
        sl: 'Najdenih je več glavnih merilnih mest.',
        hr: 'Najdenih je več glavnih merilnih mest.'
      }
    },
    EK015: {
      meaning: 'No main measuring point found in measuring points',
      lang: {
        en: 'No main measuring point found in measuring points',
        sl: 'Nobeno glavno merilno mesto ni bilo najdeno.',
        hr: 'Nobeno glavno merilno mesto ni bilo najdeno.'
      }
    },
    EK016: {
      meaning: 'None of measuring points has usage',
      lang: {
        en: 'None of measuring points has usage',
        sl: 'Nobeno merilno mesto nima porabe.',
        hr: 'Nobeno merilno mesto nima porabe.'
      }
    },
    EK017: {
      meaning: 'From date is after to date',
      lang: {
        en: 'From date is after to date',
        sl: 'Datum od je za datumom do.',
        hr: 'Datum od je za datumom do.'
      }
    },
    EK018: {
      meaning:
        'Unexpected response from processing for when requesting values for timeSeries: Missing data for values',
      lang: {
        en:
          'Unexpected response from processing for timeSeries {{timeSeriesId}} : Missing data for values',
        sl:
          'Nepričakovana napaka pri obdelavi časovne vrste {{timeSeriesId}}: manjkajo vrednosti za časovno vrsto',
        hr:
          'Nepričakovana napaka pri obdelavi časovne vrste {{timeSeriesId}}: manjkajo vrednosti za časovno vrsto'
      }
    },
    EK019: {
      meaning: 'TimeSeries has to have values if not on main measuring point.',
      lang: {
        en:
          'TimeSeries {{timeSeriesId}} on Measuringpoint {{measuringPointId}} has to have values if not on main measuring point.',
        sl:
          'Na merilnem mestu {{measuringPointId}} morajo imeti časovne vrste vnešene vrednosti, če niso na glavnem merilnem mestu (problematična časovna vrsta {{timeSeriesId}}).',
        hr:
          'Na mjestu mjerenja {{measuringPointId}} trebaju biti unesene vrijednosti vremenskih nizova, ako nisu na glavnom mjestu mjerenja (problematični vremenski niz {{timeSeriesId}})'
      }
    },
    EK020: {
      meaning: 'Measuring point can\'t have allocators if it doesn}t have usage',
      lang: {
        en: 'Measuring point can\'t have allocators if it doesn\'t have usage',
        sl: 'Merilno mesto ne sme imeti delilnikov, če nima porabe.',
        hr: 'Merilno mesto ne sme imeti delilnikov, če nima porabe.'
      }
    },
    EK021: {
      meaning: 'Invalid allocator kind found',
      lang: {
        en: 'Invalid allocator kind: {{allocatorKind}}',
        sl: 'Neveljaven način delitve na delilniku ({{allocatorKind}}).',
        hr: 'Neveljaven način delitve na delilniku ({{allocatorKind}}).'
      }
    },
    EK022: {
      meaning: 'Invalid allocator usageType found',
      lang: {
        en: 'Invalid allocator usageType: {{usageType}}',
        sl: 'Neveljavna poraba na delilniku ({{usageType}}).',
        hr: 'Neveljavna poraba na delilniku ({{usageType}}).'
      }
    },
    EK023: {
      meaning: 'Mixed allocators (use either G or V/F)',
      lang: {
        en: 'Mixed allocators (use either G or V/F)',
        sl: 'Neveljavni delilniki.',
        hr: 'Neveljavni delilniki.'
      }
    },
    EK024: {
      meaning: 'F allocator found on submeter measuring point',
      lang: {
        en: 'F allocator found on submeter measuring point',
        sl: 'F (fiksni) delilnik je bil najden na podrejenem merilnem mestu.',
        hr: 'F (fiksni) delilnik je bil najden na podrejenem merilnem mestu.'
      }
    },
    EK025: {
      meaning: 'Main measuring point should have both V and F allocators',
      lang: {
        en: 'Main measuring point should have both V and F allocators',
        sl: 'Glavno merilno mesto mora imeti V in F delilnik.',
        hr: 'Glavno merilno mesto mora imeti V in F delilnik.'
      }
    },
    EK026: {
      meaning: 'G allocators does not sum up to 100 %',
      lang: {
        en:
          'G allocators does not sum up to 100 % (sum: {{totalGAllocatorValue}})',
        sl:
          'G delilniki se ne seštejejo v 100 % (vsota: {{totalGAllocatorValue}}%).',
        hr:
          'G delilniki se ne seštejejo v 100 % (vsota: {{totalGAllocatorValue}}%).'
      }
    },
    EK027: {
      meaning: 'V allocators does not sum up to 100 %',
      lang: {
        en:
          'V allocators does not sum up to 100 % (sum: {{totalVAllocatorValue}})',
        sl:
          'V delilniki se ne seštejejo v 100 % (vsota: {{totalVAllocatorValue}}%).',
        hr:
          'V delilniki se ne seštejejo v 100 % (vsota: {{totalVAllocatorValue}}%).'
      }
    },
    EK028: {
      meaning: 'F allocators does not sum up to 100 %',
      lang: {
        en:
          'F allocators does not sum up to 100 % (sum: {{totalFAllocatorValue}})',
        sl:
          'F delilniki se ne seštejejo v 100 % (vsota: {{totalFAllocatorValue}}%).',
        hr:
          'F delilniki se ne seštejejo v 100 % (vsota: {{totalFAllocatorValue}}%).'
      }
    },
    EK029: {
      meaning: 'No valid allocators found',
      lang: {
        en:
          'No valid allocators found for measuringpoint with id {{measuringpointId}} and name {{measuringpointName}}',
        sl:
          'Noben veljaven delilnik ni bil najden za merilno mesto z id {{measuringpointId}} in nazivom {{measuringpointName}}',
        hr:
          'Nije pronađen nijedan valjani razdjelnik za mjerno mjesto s ID-om {{measuringpointId}} i nazivom {{measuringpointName}}'
      }
    },
    EK030: {
      meaning: 'Master invoice object is missing property: total',
      lang: {
        en: 'Master invoice object is missing property: total',
        sl: 'Na računu manjka podatek: skupaj.',
        hr: 'Na računu manjka podatek: skupaj.'
      }
    },
    EK031: {
      meaning: 'Invalid tariffCode found',
      lang: {
        en: 'Invalid tariffCode: {{tariffCode}}',
        sl: 'Neveljavna šifra tarife na časovni vrsti: {{tariffCode}}',
        hr: 'Neveljavna šifra tarife na časovni vrsti: {{tariffCode}}'
      }
    },
    EK032: {
      meaning: 'Found all three tariffs',
      lang: {
        en: 'Found all three tariffs',
        sl: 'Najdene so bile vse tri tarife.',
        hr: 'Najdene so bile vse tri tarife.'
      }
    },
    EK033: {
      meaning: 'Mixed tariffs found',
      lang: {
        en: 'Mixed tariffs found',
        sl: 'Najdene so bile mešane tarife.',
        hr: 'Najdene so bile mešane tarife.'
      }
    },
    EK034: {
      meaning:
        'Invoice found that does not have billing type code E and/or O for variable items. Variable part can not be divided',
      lang: {
        en:
          'Invoice {{masterInvoiceNumber}} - does not have billing type code E and/or O for variable items. Variable part can not be divided',
        sl:
          'Račun {{masterInvoiceNumber}} nima šifre tipa obračuna za E in/ali O za variabilne postavke. Variabilne postavke ni mogoče razdeliti.',
        hr:
          'Račun {{masterInvoiceNumber}} nima šifre tipa obračuna za E in/ali O za variabilne postavke. Variabilne postavke ni mogoče razdeliti.'
      }
    },
    EK035: {
      meaning:
        'Same energy management group appears more than once but doesn\'t have the same quantity.',
      lang: {
        en:
          'Same energy management group (V{{billingType}}) appears {{numOfAppearances}} times but doesn\'t have the same quantity.',
        sl:
          'Ista skupina za energetsko upravljanje (V{{billingType}}) se pojavi {{numOfAppearances}}-krat, vendar nima enake količine.',
        hr:
          'Ista skupina za energetsko upravljanje (V{{billingType}}) se pojavi {{numOfAppearances}}-krat, vendar nima enake količine.'
      }
    },
    EK100: {
      meaning:
        'Percentage of allocation exceedes 100 percent on detail invoice.',
      lang: {
        en:
          'Percentage of allocation exceedes 100 percent (detailInvoiceId: \'{{detailInvoiceId}}\').',
        sl:
          'Odstotek delitve presega 100 odstotkov (detailInvoiceId: \'{{detailInvoiceId}}\').',
        hr:
          'Percentage of allocation exceedes 100 percent (detailInvoiceId: \'{{detailInvoiceId}}\').'
      }
    },
    EK101: {
      meaning: 'An unexpected error has occured',
      lang: {
        en: 'An unexpected error has occured',
        sl: 'Zgodila se je nepričakovana napaka',
        hr: 'Zgodila se je nepričakovana napaka'
      }
    },
    EK102: {
      meaning: 'Unable to construct a tree with provided measuring points',
      lang: {
        en: 'Unable to construct a tree with provided measuring points',
        sl: 'Ni mogoče zgraditi drevesa s podanimi merilnimi mesti.',
        hr: 'Ni mogoče zgraditi drevesa s podanimi merilnimi mesti.'
      }
    },
    EK103: {
      meaning: 'Request for values for timeSeries has failed',
      lang: {
        en: 'Request for timeSeries {{timeSeriesId}} failed',
        sl: 'Zahteva za časovno vrsto {{timeSeriesId}} vrste ni uspela.',
        hr: 'Zahteva za časovno vrsto {{timeSeriesId}} vrste ni uspela.'
      }
    },
    EK104: {
      meaning:
        'Unexpected response from processing for values for timeSeries: Missing pagination data',
      lang: {
        en:
          'Unexpected response from processing for timeSeries {{timeSeriesId}} : Missing pagination data',
        sl:
          'Nepričakovana napaka pri obdelavi časovne vrste {{timeSeriesId}}: manjkajo podatki o paginaciji',
        hr:
          'Nepričakovana napaka pri obdelavi časovne vrste {{timeSeriesId}}: manjkajo podatki o paginaciji'
      }
    },
    EK105: {
      meaning:
        'Processing returned more values for timeSeries than allowed limit',
      lang: {
        en:
          'Processing returned more values for timeSeries {{timeSeriesId}} than allowed limit',
        sl:
          'Obdelava časovnih vrst je vrnila več vrednosti za časovno vrsto {{timeSeriesId}} od dovoljene omejitve.',
        hr:
          'Obdelava časovnih vrst je vrnila več vrednosti za časovno vrsto {{timeSeriesId}} od dovoljene omejitve.'
      }
    },
    EK106: {
      meaning: 'Master invoice does not exist',
      lang: {
        en: 'Master invoice does not exist',
        sl: 'Račun ne obstaja.',
        hr: 'Račun ne obstaja.'
      }
    },
    EK107: {
      meaning: 'Invalid allocator usage type',
      lang: {
        en: 'Invalid allocator usage type',
        sl:
          'Neveljavna konfiguracija porabe delilnika na delilniku na enem od merilnih mest, ki so zajeti v postopek obračunskega algoritma.',
        hr:
          'Neveljavna konfiguracija porabe delilnika na delilniku na enem od merilnih mest, ki so zajeti v postopek obračunskega algoritma.'
      }
    }
  };

  const getLocale = (code, params) => {
    const LocalizationService = $injector.get('LocalizationService');
    const locale = LocalizationService.getLocale();
    let errorObject = errors[code];
    const message = errorObject?.lang[locale];
    if (message != null) {
      return gettextCatalog.getString(message, params);
    }
    return '';
  };

  return {
    getLocale
  };
}

export default AllowedFunctionsService;
