ExpressionConfiguration.$inject = [
  'gettext',
  '$timeout',
  '$q',
  'gettextCatalog'
];

function ExpressionConfiguration(gettext, $timeout, $q, gettextCatalog) {
  const aggregationTypeOptions = [
    {
      _id: 'min',
      name: gettextCatalog.getString('Min')
    },
    {
      _id: 'max',
      name: gettextCatalog.getString('Max')
    },
    {
      _id: 'sum',
      name: gettextCatalog.getString('Sum')
    },
    {
      _id: 'avg',
      name: gettextCatalog.getString('Avg')
    }
  ];

  var configuration = {
    listId: 'expressionId',
    entity: {
      singular: gettext('Expression'),
      plural: gettext('Expressions')
    },
    api: {
      query: {
        entity: 'expressions',
        method: 'custom.list'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'analytics-expressions-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'analytics-expressions-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'analytics-expressions-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'expressions',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Dataset'),
        param: 'datasetName',
        type: 'link',
        link: {
          state: 'analytics-datasets-view',
          params: [
            {
              valueParam: 'datasetId',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        title: gettext('Expression'),
        param: 'value',
        type: 'text'
      },
      {
        title: gettext('Aggregation type'),
        param: 'aggregationType',
        type: 'text'
      }
    ],
    badges: [],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        query: {
          entity: 'datasets',
          method: 'read'
        },
        param: 'dataset',
        entity: 'datasets',
        display: gettext('Dataset')
      },
      {
        collection: aggregationTypeOptions,
        param: 'aggregationType',
        display: gettext('Aggregation Type')
      }
    ],
    sort: [
      {
        display: 'name DESC',
        params: '-name'
      },
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    if (item.dataset) {
      item.datasetName = item.dataset.name;
      item.datasetId = item.dataset._id;
    } else {
      item.datasetName = gettextCatalog.getString('Unknown');
    }
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}

export default ExpressionConfiguration;
