TimeSeriesWizardDuplicateProperties.$inject = [
  'TimeSeriesModel',
  'ExternalReaderFlowModel',
  'MaintenanceFlowModel',
  'MtAnalysisFlowModel',
  'CalculatedFlowModel',
  'ExternalWriterFlowModel'
];
/**
 * @ngdoc service
 * @name common.TimeSeriesWizardDuplicateProperties
 * @description Get data for timeSeries wizard duplicate.
 * @property {function} get
 */
export default function TimeSeriesWizardDuplicateProperties(
  TimeSeriesModel,
  ExternalReaderFlowModel,
  MaintenanceFlowModel,
  MtAnalysisFlowModel,
  CalculatedFlowModel,
  ExternalWriterFlowModel
) {
  /**
   * @description get active time series configuration.
   * @function
   * @param {Object} state
   * @param {string} entityId
   * @return {Object} timeSeriesData
   */
  const getTimeSeriesActiveConfigurationFromStore = (state, entityId) => {
    let tmpTimeSeriesConfigurations =
      state.timeSeriesConfigurations.list[entityId];
    let activeTimeSeriesConfig;
    let timeSeriesConfigurations = {};
    if (
      tmpTimeSeriesConfigurations != null &&
      Array.isArray(tmpTimeSeriesConfigurations.data)
    ) {
      activeTimeSeriesConfig = tmpTimeSeriesConfigurations.data.find(
        config => config.validTo == null
      );
      if (activeTimeSeriesConfig != null) {
        let flow = activeTimeSeriesConfig.flow;
        let flowRef = activeTimeSeriesConfig.flowRef;
        let flowData;
        switch (flowRef) {
        // External reader flow
        case 100:
          flowData = state.externalReaderFlow[flow]
            ? state.externalReaderFlow[flow].data
            : null;
          timeSeriesConfigurations.externalReaderFlow = angular.copy(
            flowData
          );
          break;
          // External writer flow
        case 200:
          flowData = state.externalWriterFlow[flow]
            ? state.externalWriterFlow[flow].data
            : null;
          timeSeriesConfigurations.externalWriterFlow = angular.copy(
            flowData
          );
          break;
          // Calculated flow
        case 300:
          flowData = state.calculatedFlow[flow]
            ? state.calculatedFlow[flow].data
            : null;
          timeSeriesConfigurations.calculatedFlow = angular.copy(flowData);
          break;
          // Mt analysis flow
        case 320:
          flowData = state.monitorAndTargetAnalysisFlow[flow]
            ? state.monitorAndTargetAnalysisFlow[flow].data
            : null;
          timeSeriesConfigurations.mtAnalysisFlow = angular.copy(flowData);
          break;
          // Maintenance flow
        case 500:
          flowData = state.maintenanceFlow[flow]
            ? state.maintenanceFlow[flow].data
            : null;
          timeSeriesConfigurations.maintenanceFlow = angular.copy(flowData);
          break;
        }
      }
    }
    return timeSeriesConfigurations;
  };

  /**
   * @description get active time series configuration from model (flow).
   * @function
   * @param {string} entityId
   * @param {Object} activeTimeSeriesConfiguration
   * @return {Object} timeSeriesConfigurations
   */
  const getTimeSeriesActiveConfigurationFromModel = async (
    entityId,
    activeTimeSeriesConfiguration
  ) => {
    let timeSeriesConfigurations;
    if (activeTimeSeriesConfiguration != null) {
      timeSeriesConfigurations = {};
      let flowRef = activeTimeSeriesConfiguration.flowRef;
      let flowId = activeTimeSeriesConfiguration.flow;
      let flowData;
      switch (flowRef) {
      case 'ExternalReaderFlow':
        flowData = await ExternalReaderFlowModel.read({
          timeSeriesId: entityId,
          id: flowId,
          populate:
              'liveIngestionConfig.tangoAgentConfig.tag,liveIngestionConfig.tangoAgentConfig.connectionConfig,historyIngestionConfig.tangoAgentConfig.tag,historyIngestionConfig.tangoAgentConfig.connectionConfig'
        });
        timeSeriesConfigurations.externalReaderFlow = flowData
          ? flowData.data
          : null;
        break;
      case 'ExternalWriterFlow':
        flowData = await ExternalWriterFlowModel.read({
          timeSeriesId: entityId,
          id: flowId
        });
        timeSeriesConfigurations.externalWriterFlow = flowData
          ? flowData.data
          : null;
        break;
      case 'CalculatedFlow':
        flowData = await CalculatedFlowModel.read({
          timeSeriesId: entityId,
          id: flowId
        });
        timeSeriesConfigurations.calculatedFlow = flowData
          ? flowData.data
          : null;
        break;
      case 'MtAnalysisFlow':
        flowData = await MtAnalysisFlowModel.read({
          timeSeriesId: entityId,
          id: flowId
        });
        timeSeriesConfigurations.mtAnalysisFlow = flowData
          ? flowData.data
          : null;
        break;
      case 'MaintenanceFlow':
        flowData = await MaintenanceFlowModel.read({
          timeSeriesId: entityId,
          id: flowId
        });
        timeSeriesConfigurations.maintenanceFlow = flowData
          ? flowData.data
          : null;
        break;
      }
    }
    return timeSeriesConfigurations;
  };

  /**
   * @description get time series data from store.
   * @function
   * @param {Object} state
   * @param {string} entityId
   * @param {Object} options
   * @return {Object} properties
   */
  const getTimeSeriesDataFromStore = (state, entityId, options) => {
    let timeSeries = state.timeSeries[entityId].data;
    let properties = getTimeSeriesProperties(options, timeSeries);
    if (
      options != null &&
      options.duplicateData != null &&
      options.duplicateData.inputTimeSeriesConfigurations &&
      state.timeSeriesConfigurations != null &&
      state.timeSeriesConfigurations.list != null &&
      state.timeSeriesConfigurations.list[entityId] != null
    ) {
      properties = {
        ...properties,
        ...getTimeSeriesActiveConfigurationFromStore(state, entityId)
      };
    }
    return properties;
  };

  /**
   * @description get time series data from model.
   * @function
   * @param {string} entityId
   * @param {Object} options
   * @return {Object} properties
   */
  const getTimeSeriesDataFromModel = async (entityId, options) => {
    let timeSeries = await TimeSeriesModel.read({
      id: entityId,
      populate: 'activeTimeSeriesConfiguration'
    });
    let timeSeriesData = timeSeries ? timeSeries.data : null;
    let activeTimeSeriesConfiguration = timeSeriesData
      ? timeSeriesData.activeTimeSeriesConfiguration
      : null;
    let properties = getTimeSeriesProperties(options, timeSeriesData);
    if (
      options != null &&
      options.duplicateData != null &&
      options.duplicateData.inputTimeSeriesConfigurations
    ) {
      let timeSeriesActiveConfiguration = await getTimeSeriesActiveConfigurationFromModel(
        entityId,
        activeTimeSeriesConfiguration
      );
      properties = {
        ...properties,
        ...timeSeriesActiveConfiguration
      };
    }
    return properties;
  };

  /**
   * @description get time series properties.
   * @function
   * @param {Object} options
   * @param {Object} timeSeries
   * @return {Object} timeSeriesData
   */
  const getTimeSeriesProperties = (options, timeSeries) => {
    let timeSeriesData;
    if (options != null && options.duplicateData != null) {
      timeSeriesData = {};
      if (options.duplicateData.metaData === true) {
        timeSeriesData = { ...timeSeries };
        delete timeSeriesData.rules;
      }
      if (options.duplicateData.ruleAlarms === true) {
        timeSeriesData.rules = timeSeries.rules;
      }
      if (options.duplicateData.useCurrentTimeSeriesAsInputParameter) {
        timeSeriesData.useCurrentTimeSeriesAsInputParameter = timeSeries;
      }
    } else {
      timeSeriesData = timeSeries;
    }
    return timeSeriesData;
  };
  /**
   * @description get time series properties for duplicating.
   * @function
   * @param {Object} state
   * @param {string} entityId
   * @param {Object} options
   * @return {Object} timeSeries data
   */
  const get = async (state, entityId, options) => {
    let timeSeriesProperties;
    if (
      state != null &&
      state.timeSeries != null &&
      state.timeSeries[entityId] != null
    ) {
      timeSeriesProperties = getTimeSeriesDataFromStore(
        state,
        entityId,
        options
      );
    } else {
      timeSeriesProperties = await getTimeSeriesDataFromModel(
        entityId,
        options
      );
    }
    return timeSeriesProperties;
  };

  return {
    get
  };
}
