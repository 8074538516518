AnalysisConfiguration.$inject = ['gettext', '$q', 'gettextCatalog'];

function AnalysisConfiguration(gettext, $q, gettextCatalog) {
  var configuration = {
    listId: 'analysisId',
    entity: {
      singular: gettext('Analysis'),
      plural: gettext('Analyses')
    },
    api: {
      query: {
        entity: 'analyses',
        method: 'custom.readList'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'analytics-analyses-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'analytics-analyses-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'play_arrow',
        link: {
          state: 'analytics-analyses-run',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'mode_edit',
        link: {
          state: 'analytics-analyses-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },

      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'analyses',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Description'),
        param: 'description',
        type: 'text'
      },
      {
        title: gettext('Dataset'),
        param: 'datasetName',
        type: 'link',
        link: {
          state: 'analytics-datasets-view',
          params: [
            {
              valueParam: 'datasetId',
              queryParam: 'id'
            }
          ]
        }
      }
    ],
    badges: [],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        query: {
          entity: 'datasets',
          method: 'read'
        },
        entity: 'datasets',
        display: gettext('Dataset'),
        param: 'dataset',
        displayList: ['name'],
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        collection: [
          {
            _id: 'actual',
            name: gettextCatalog.getString('Actual')
          },
          {
            _id: 'previous_period',
            name: gettextCatalog.getString('Previous period')
          }
        ],
        display: gettext('Analysis Type'),
        param: 'type'
      }
    ],
    sort: [
      {
        display: 'name DESC',
        params: '-name'
      },
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    if (item.dataset) {
      item.datasetName = item.dataset.name;
      item.datasetId = item.dataset._id;
    } else {
      item.datasetName = gettextCatalog.getString('Unknown');
    }
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default AnalysisConfiguration;
