import template from './mt-analysis-dashboard-card.component.html';
import './mt-analysis-dashboard-card.scss';

/**
 * @ngdoc component
 * @name data.mtAnalysisDashboardCard
 * @description Plots MT analysis for dashboard.
 * @param {ObjectId} timeSeriesId - analysis id
 * @param {CodelistId} size - dashboard size
 * @param {String} cacheInvalidationTime - cache invalidation time
 * @example
 * <mt-analysis-dashboard-card
 *   time-series-id="vm.analysis"
 *   size="vm.size"
 *   cache-invalidation-time="vm.cacheInvalidationTime"
 * ></mt-analysis-dashboard-card>
 */
const mtAnalysisDashboardCard = {
  template,
  controller: MTAnalysisDashboardCardController,
  controllerAs: 'vm',
  bindToController: true,
  bindings: {
    timeSeriesId: '<?',
    size: '<?',
    cacheInvalidationTime: '<?',
    uniqueCacheId: '<?',
    refresher: '<?'
  }
};

MTAnalysisDashboardCardController.$inject = [
  '$scope',
  'TimeSeriesModel',
  'MtAnalysisFlowModel',
  'AlertingService',
  'gettextCatalog'
];

function MTAnalysisDashboardCardController(
  $scope,
  TimeSeriesModel,
  MtAnalysisFlowModel,
  AlertingService,
  gettextCatalog
) {
  const vm = this;

  vm.loading = false;
  vm.$onChanges = changes => {
    if (changes.timeSeriesId && vm.timeSeriesId) {
      init(vm.timeSeriesId);
    }
  };

  /**
   * @description Initiates the component.
   * @function
   */
  async function init(timeSeriesId) {
    vm.loading = true;
    vm.dashboardSettings = {
      size: vm.size,
      cacheInvalidationTime: vm.cacheInvalidationTime,
      uniqueCacheId: vm.uniqueCacheId,
      refresher: vm.refresher
    };
    try {
      const { data: timeSeries } = await TimeSeriesModel.read(
        {
          id: timeSeriesId,
          populate: 'activeTimeSeriesConfiguration.timeSeriesConfiguration'
        },
        vm.cacheInvalidationTime
      );
      if (
        timeSeries?.activeTimeSeriesConfiguration?.flowType_ ==
          320 /* MT ANALYSIS FLOW */ &&
        timeSeries?.activeTimeSeriesConfiguration?.timeSeriesConfiguration
          ?.flow != null
      ) {
        const { data: mtFlow } = await MtAnalysisFlowModel.read({
          timeSeriesId,
          id:
            timeSeries.activeTimeSeriesConfiguration.timeSeriesConfiguration
              .flow,
          populate: 'inputTimeSeriesForXAxis,inputTimeSeriesForYAxis'
        });
        vm.mtFlow = {
          ...mtFlow,
          timeSeriesObject: timeSeries
        };
        vm.isError = false;
      } else {
        AlertingService.Error(
          gettextCatalog.getString(
            'Time Series active flow is not mt analysis flow'
          )
        );
        vm.errorMessage = gettextCatalog.getString(
          'Time Series active flow is not mt analysis flow'
        );
        vm.isError = true;
      }
    } catch (err) {
      AlertingService.Error(err);
      vm.isError = true;
      vm.errorMessage = gettextCatalog.getString(
        'Error while fetching M&T analysis'
      );
    }
    vm.loading = false;
    $scope.$evalAsync();
  }
}

export default mtAnalysisDashboardCard;
