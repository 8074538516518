import sopStepTask from './components/sop-step-task/sop-step-task.component';
import sfeDndMappingRules from './components/sfe-dnd-mapping-rules/sfe-dnd-mapping-rules.component';
import ruleVariables from './components/rule-variables/rule-variables.component';
import ruleVariablesViewer from './components/rule-variables-viewer/rule-variables-viewer.component';

import AlarmDefinitionController from './pages/alarm-definition/alarm-definition.controller';
import NewAlarmDefinitionController from './pages/alarm-definition/new-alarm-definition.controller';
import MappingRuleController from './pages/mapping-rule/mapping-rule.controller';
import NewMappingRuleController from './pages/mapping-rule/new-mapping-rule.controller';
import RuleController from './pages/rule/rule.controller';
import NewRuleController from './pages/rule/new-rule.controller';
import SOPDetailsController from './pages/sop/sop-details.controller';
import NewStandardOperatingProcedureController from './pages/sop/new-sop.controller';
import StepDetailsController from './pages/step/step-details.controller';
import NewStepController from './pages/step/new-step.controller';
import TaskDetailsController from './pages/task/task-details.controller';
import NewTaskController from './pages/task/new-task.controller';

//configurations (they fall under factories)
import AlarmDefinitionConfiguration from './configurations/alarm-definition.configuration';
import sopStepConfiguration from './configurations/sop-step.configuration';
import sopTaskConfiguration from './configurations/sop-task.configuration';
import sopConfiguration from './configurations/sop.configuration';

//network models (services)
import AlarmModel from './network-models/Alarm.model';
import MapperModel from './network-models/Mapper.model';
import MappingModel from './network-models/Mapping.model';
import RuleModel from './network-models/Rule.model';
import SopModel from './network-models/Sop.model';
import StepModel from './network-models/Step.model';
import TaskModel from './network-models/Task.model';

import routes from './alarms.routes';

export default {
  routes,
  controllers: {
    AlarmDefinitionController,
    NewAlarmDefinitionController,
    MappingRuleController,
    NewMappingRuleController,
    RuleController,
    NewRuleController,
    SOPDetailsController,
    NewStandardOperatingProcedureController,
    StepDetailsController,
    NewStepController,
    TaskDetailsController,
    NewTaskController
  },
  components: {
    sopStepTask,
    sfeDndMappingRules,
    ruleVariables,
    ruleVariablesViewer
  },
  factories: {
    AlarmDefinitionConfiguration,
    sopStepConfiguration,
    sopTaskConfiguration,
    sopConfiguration
  },
  services: {
    AlarmModel,
    MapperModel,
    MappingModel,
    RuleModel,
    SopModel,
    StepModel,
    TaskModel
  }
};
