TimeSeriesLocationModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function TimeSeriesLocationModel(ModelUtilities, CrawlerMethods) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'time-series-locations'
  };

  const crawlerConfiguration = {
    entity: 'time-series-locations',
    method: 'read',
    populate: 'location',
    steps: [
      {
        entity: 'addresses',
        valueParams: ['location.address'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'populateArray'
      },
      {
        entity: 'cost-centre-locations',
        valueParams: ['location._id'],
        queryParams: ['location'],
        method: 'custom.readWithCostCenter',
        methodType: 'add',
        newParamNames: ['location.mainCostCentre'],
        filters: {
          isActive: true,
          isMain: true
        },
        noMerge: true
      }
    ]
  };

  const crawlerConfigurationReadTimeseries = {
    entity: 'time-series-locations',
    method: 'read',
    populate: 'timeSeries'
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readLocation: CrawlerMethods.crawler(crawlerConfiguration),
      readTimeseries: CrawlerMethods.crawler(crawlerConfigurationReadTimeseries)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default TimeSeriesLocationModel;
