NewExpressionController.$inject = [
  'gettext',
  '$state',
  'gettextCatalog',
  '$timeout',
  'AlertingService',
  'MetadataService',
  'createOrUpdateService',
  '$scope',
  'SfeHeader',
  'expression'
];

function NewExpressionController(
  gettext,
  $state,
  gettextCatalog,
  $timeout,
  AlertingService,
  MetadataService,
  createOrUpdateService,
  $scope,
  SfeHeader,
  expression
) {
  var vm = this;
  var expressionId = $state.params.id;
  var aggregationTypeOptions = [
    {
      _id: 'min',
      name: gettextCatalog.getString('Min')
    },
    {
      _id: 'max',
      name: gettextCatalog.getString('Max')
    },
    {
      _id: 'sum',
      name: gettextCatalog.getString('Sum')
    },
    {
      _id: 'avg',
      name: gettextCatalog.getString('Avg')
    }
  ];
  vm.createOrUpdate = [
    {
      title: expressionId ? gettext('Update') : gettext('Create'),
      fn: createOrUpdate,
      color: 'primary',
      raised: true,
      disabledFn: function() {
        return (
          !$scope.ExpressionForm.$valid ||
          vm.sendingRequest ||
          (vm.expressionErrors && vm.expressionErrors.length > 0)
        );
      }
    }
  ];

  init();

  function init() {
    vm.editMode = !!expressionId;
    vm.dataConfig = {
      PH16: true
    };

    vm.dataConfig.data = getFormConfig();

    if (vm.editMode) {
      MetadataService.Loading(true);
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit expression'),
        'analytics-expressions-view',
        { id: expressionId }
      );
      vm.dataConfig.dataObj = {
        _id: expression._id,
        name: expression.name,
        description: expression.description,
        dataset: expression.dataset._id,
        aggregationType: {
          _id: expression.aggregationType
        }
      };
      vm.expression = expression.value;
      getColumns(expression.dataset.columns);
      MetadataService.Loading(false);
      $timeout(() => {
        MetadataService.ChangeMetadata(
          'Edit ' + expression.name,
          expression.description
        );
      });
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New expression'),
        'analytics-expressions-list'
      );
      vm.dataConfig.dataObj = {
        aggregationType: {}
      };
    }
  }

  function datasetChanged() {
    if (vm.dataConfig.dataObj.dataset) {
      getColumns(vm.dataConfig.dataObj.dataset.columns);
    }
  }

  function getColumns(columns) {
    vm.datasetVariables = [];
    if (columns) {
      columns.forEach(function(column) {
        if (column.type === 2 || column.type === 3) {
          vm.datasetVariables.push(column.rawFieldName);
        }
      });
    }
  }

  function getFormConfig() {
    return [
      {
        placeholder: 'Name',
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: 'Description',
        name: 'description',
        componentType: 'textArea',
        type: 'text',
        maxlength: 500,
        required: false
      },
      {
        configuration: {
          query: {
            entity: 'datasets',
            method: 'read'
          },
          entity: 'datasets',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Dataset')
          },
          floatingLabel: gettext('Select Dataset'),
          searchParamName: 'filter',
          required: true,
          createRedirect: {
            state: 'analytics-datasets-new'
          },
          change: datasetChanged
        },
        componentType: 'autocompleteDialog',
        edit: vm.editMode,
        name: 'dataset'
      },
      {
        componentType: 'multiSelect',
        config: {
          label: gettext('Aggregation type'),
          placeholder: gettext('Select Aggregation Type'),
          ctrlFn: function() {
            return $timeout(function() {
              return aggregationTypeOptions;
            });
          },
          empty: gettext('There are no Aggregation types to select from.'),
          edit: vm.editMode
        },
        name: 'aggregationType'
      }
    ];
  }

  // create and update functions //

  function createOrUpdate() {
    vm.sendingRequest = true;
    createOrUpdateService
      .simpleCall(
        {
          entity: 'expressions',
          method: vm.editMode ? 'update' : 'create'
        },
        vm.editMode,
        expressionId,
        dataToSave(),
        'analytics-expressions-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function dataToSave() {
    return {
      name: vm.dataConfig.dataObj.name,
      description: vm.dataConfig.dataObj.description,
      dataset: vm.dataConfig.dataObj.dataset._id,
      aggregationType: vm.dataConfig.dataObj.aggregationType._id,
      value: vm.expression
    };
  }
}

export default NewExpressionController;
