/* eslint-disable no-unused-vars */
import template from './sfe-expression-preview.component.html';
import './sfe-expression-preview.scss';

/**
 * @ngdoc component
 * @name common.expressionPreviewController
 * @description component for generating math expression
 * @param {function} allowedVariables - returns allowed variables
 * @example
 * <sfe-expression-preview
 *   allowed-variables="vm.config.type.options.allowedVariables"
 *   ng-model="vm.value"
 * ></sfe-expression-preview>
 */

export default {
  template,
  bindings: {
    expression: '<',
    expressionObject: '<',
    allowedVariables: '<',
    allowedFunctions: '<',
    api: '<'
  },
  controller: expressionPreviewController,
  controllerAs: 'vm'
};

expressionPreviewController.$inject = [
  '$element',
  '$timeout',
  'ValidateMathExpression',
  '$scope',
  'AllowedFunctionsService'
];
function expressionPreviewController(
  $element,
  $timeout,
  ValidateMathExpression,
  $scope,
  AllowedFunctionsService
) {
  const Hub = MathJax.Hub;

  let vm = this;
  const allowedFunctions = AllowedFunctionsService.getOnlyOnlyOperators();
  $scope.$on('$destroy', () => {
    vm = null;
  });
  vm.$onChanges = changes => {
    if (changes.expression && vm.expression != null) {
      Hub.Queue(['Typeset', Hub]);

      $timeout(() => {
        initElement(vm.expression);
      });
    }
  };
  //jex element associated with current component instance
  let jexElement;

  /**
   * @description when expression changes if there are no jax element initiates its value and updates its text.
   * @function
   * @param {String} expression new expression string
   */
  function initElement(expression) {
    let domElement = $element.find(`#${vm.expressionObject.id}`)[0];
    if (domElement != null) {
      if (jexElement) {
        setText(expression);
      } else {
        MathJax.Hub.Queue(['Typeset', MathJax.Hub, domElement], function() {
          jexElement = MathJax.Hub.getAllJax(domElement)[0];
          if (jexElement != null) {
            var originalTextFunction = jexElement.Text;
            jexElement.Text = function(text, callback) {
              if (this) {
                var script = this.SourceElement();
                if (script) {
                  originalTextFunction.apply(this, [text, callback]);
                }
              }
            };
            setText(expression);
          }
        });
      }
    }
    /**
     * @description checks that expression can be parsed and sets it value to jax element.
     * @function
     * @param {String} expression
     */
    function setText(expression) {
      try {
        let parseExpression = math
          .parse(expression)
          .transform(parseDisplayExpression);
        Hub.Queue(['Text', jexElement, parseExpression]);
      } catch (err) {
        //when expression is not valid do not typeset it
      }
    }
    /**
     * @description Transform variable node to constant node.. to display variables that contain more than one symbol in their name
     * @function
     * @param {Object} node Node to be checked on how to be returned.
     * @return {Object} Node that is returned
     */
    function parseDisplayExpression(node) {
      let allowedVariables = vm.allowedVariables(vm.api);
      switch (node.type) {
      case 'SymbolNode':
        if (
          !allowedFunctions.includes(node.name) &&
            Array.isArray(allowedVariables) &&
            (allowedVariables.indexOf(node.name) > -1 ||
              (Array.isArray(vm.allowedFunctions) &&
                vm.allowedFunctions.indexOf(node.name) > -1))
        ) {
          return new math.expression.node.ConstantNode(node.name);
        } else {
          return node;
        }
      case 'ConstantNode':
        return ValidateMathExpression.transformNumber(node);
      default:
        return node;
      }
    }
  }
}
