NewStandardOperatingProcedureController.$inject = [
  '$stateParams',
  '$state',
  '$timeout',
  'AlertingService',
  'gettext',
  'TranslationService',
  'MultiLanguage',
  'MetadataService',
  'LocalizationService',
  'createOrUpdateService',
  'SfeHeader',
  'sop'
];

function NewStandardOperatingProcedureController(
  $stateParams,
  $state,
  $timeout,
  AlertingService,
  gettext,
  TranslationService,
  MultiLanguage,
  MetadataService,
  LocalizationService,
  createOrUpdateService,
  SfeHeader,
  sop
) {
  const vm = this;
  const sopId = $stateParams.id;
  vm.duplicateMode = $state.current.name === 'alarms-and-rules-sop-duplicate';
  vm.isValid = IsValid;
  const selectedLang = LocalizationService.GetSelectedLangNetworkCode();

  init();

  function init() {
    vm.editMode = !!sopId;
    vm.types = [];
    vm.steps = [];
    vm.tasks = [];

    vm.dataConfig = {};
    vm.typeMenuConfig = {
      edit: vm.editMode,
      label: gettext('Sop Type'),
      placeholder: gettext('Choose sop type'),
      // addLabel: 'Add task to step',
      ctrlFn: function() {
        return $timeout(function() {
          return TranslationService.GetCollection('codelists.sopTypes');
        });
      },
      valueField: 'id',
      displayOptions: ['name']
    };

    vm.tyepModel = {};

    if (vm.editMode) {
      vm.sendingRequest = true;
      MetadataService.Loading(true);
      if (vm.duplicateMode) {
        vm.dataConfig.header = SfeHeader.constructFormHeader(
          'primary',
          gettext('Duplicating standard operating procedure'),
          'alarms-and-rules-sop-view',
          { id: sopId }
        );
      } else {
        vm.dataConfig.header = SfeHeader.constructFormHeader(
          'primary',
          gettext('Edit standard operating procedure'),
          'alarms-and-rules-sop-view',
          { id: sopId }
        );
      }
      let steps = [];
      if (Array.isArray(sop.steps) && sop.steps.length > 0) {
        steps = sop.steps.map(step => [
          {
            ...step,
            name: step.name[selectedLang]
          }
        ]);
      }
      vm.dataConfig.dataObj = { ...sop, steps };
      if (vm.duplicateMode) {
        $timeout(() => {
          MetadataService.ChangeMetadata(
            'Duplicate ' + sop.name[selectedLang],
            sop.description[selectedLang]
          );
        });
      } else {
        $timeout(() => {
          MetadataService.ChangeMetadata(
            'Edit ' + sop.name[selectedLang],
            sop.description[selectedLang]
          );
        });
      }
      $timeout(() => {
        MetadataService.Loading(false);
        vm.sendingRequest = false;
      });
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New standard operating procedure'),
        'alarms-and-rules-sop-list'
      );
      sop = {
        steps: []
      };
      vm.dataConfig.dataObj = sop;
    }

    let data = [
      {
        placeholder: gettext('Name'),
        name: 'name',
        type: 'text',
        componentType: 'multilanguage',
        required: true
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        maxlength: 500,
        type: 'text',
        required: false,
        componentType: 'multilanguage'
      },
      {
        componentType: 'autocompleteDialog',
        edit: vm.editMode,
        configuration: {
          codelist: 'sopTypes',
          floatingLabel: gettext('Choose sop type'),
          searchParamName: 'name',
          entity: 'sopTypes',
          required: true,
          dialogConfiguration: {
            multiple: false
          },
          selectedParam: 'id'
        },
        name: 'type'
      },
      {
        title: gettext('Steps'),
        componentType: 'title'
      },
      {
        componentType: 'multiSelectList',
        name: 'steps',
        removeTitle: gettext('Remove Step'),
        addLabel: gettext('Add Step'),
        groupLabel: gettext('Step'),
        selectConfigs: [
          {
            componentType: 'autocomplete',
            edit: vm.editMode,
            entity: 'steps',
            searchParamName: 'filter',
            selectedParam: 'id',
            required: true,
            query: {
              entity: 'steps',
              method: 'read'
            },
            placeholder: gettext('Choose Step'),
            floatingLabel: gettext('Step'),
            reset: true,
            createRedirect: {
              state: 'alarms-and-rules-sop-steps-new'
            },
            filterFn: items => {
              if (Array.isArray(items)) {
                let filteredItems = items.filter(item => {
                  let foundItem = vm.dataConfig.dataObj.steps.find(
                    step =>
                      Array.isArray(step) &&
                      step[0] != null &&
                      step[0]._id === item._id
                  );
                  if (!foundItem) {
                    return item;
                  }
                });
                return filteredItems;
              }
              return [];
            }
          }
        ]
      }
    ];

    vm.dataConfig.data = data;
    vm.dataConfig.action = {
      ctrlFn: true,
      title:
        vm.editMode && !vm.duplicateMode
          ? gettext('Update')
          : gettext('Create'),
      fn: createOrUpdate
    };
  }

  function IsValid() {
    return vm.SOPForm.$valid;
  }

  // create or update functions

  function createOrUpdate() {
    vm.sendingRequest = true;
    createOrUpdateService
      .simpleCall(
        {
          entity: 'sops',
          method: vm.editMode && !vm.duplicateMode ? 'update' : 'create'
        },
        vm.editMode,
        vm.duplicateMode ? null : sopId,
        dataToSave(),
        'alarms-and-rules-sop-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function dataToSave() {
    const multilanguageSop = MultiLanguage.constructForm(
      vm.dataConfig.data,
      vm.dataConfig.dataObj
    );

    return {
      name: vm.editMode ? multilanguageSop.name : sop.name,
      description: vm.editMode ? multilanguageSop.description : sop.description,
      type: multilanguageSop.type.id,
      steps: multilanguageSop.steps.map(item => item[0]._id)
    };
  }
}

export default NewStandardOperatingProcedureController;
