BoundariesConfiguration.$inject = ['gettextCatalog'];

function BoundariesConfiguration(gettextCatalog) {
  /**
   * @description get boundaries configuration.
   * @function
   * @param {Object} data - data
   * @return {Object}  configuration
   */
  const get = data => {
    let configuration = {
      name: 'boundaries',
      actions: [],
      title: gettextCatalog.getString('Boundaries'),
      fields: [
        {
          id: 'boundaries',
          title: gettextCatalog.getString('Display type'),
          type: {
            name: 'boundaries',
            options: {}
          },
          initialize: () => {
            if (data != null && Array.isArray(data.boundaries)) {
              return angular.copy(data.boundaries);
            }
            return null;
          },
          required: false
        }
      ]
    };
    return configuration;
  };
  return { get };
}
export default BoundariesConfiguration;
