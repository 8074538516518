import template from './tango-time-series-configurations.component.html';
import './tango-time-series-configurations.scss';
import timeSeriesConfigurationActions from '../../redux/time-series-configurations/action/times-series-configurations.action';
import externalReaderFlowActions from '../../redux/external-reader-flow/action/external-reader-flow.action';
import externalWriterFlowActions from '../../redux/external-writer-flow/action/external-writer-flow.action';
import calculatedFlowActions from '../../redux/calculated-flow/action/calculated-flow.action';
import mtAnalysisFlowActions from '../../redux/mt-analysis-flow/action/mt-analysis-flow.action';
import maintenanceFlowActions from '../../redux/maintenance-flow/action/maintenance-flow.action';
import { DateTime } from 'luxon';

export default {
  template,
  bindings: {
    entityId: '<'
  },
  controller: TangoTimeSeriesConfigurations,
  controllerAs: 'vm'
};

TangoTimeSeriesConfigurations.$inject = [
  '$scope',
  '$ngRedux',
  '$timeout',
  'TranslationService',
  'gettextCatalog',
  'ExternalReaderTangoProperties',
  'ExternalWriterTangoProperties',
  'CalculatedFlowTimeSeriesConfigurations',
  'MaintenanceFlowTimeSeriesConfigurations',
  'MtAnalysisFlowTimeSeriesConfigurations',
  'ExternalWriterFlowFormConfigurations',
  'ExternalReaderFlowFormConfigurations',
  'MtAnalysisFlowFormConfigurations',
  'SfeForm2Dialog',
  'MaintenanceFlowFormConfigurations',
  'CalculatedFlowFormConfigurations',
  'AddFlowConfiguration',
  'DateLocalizationService',
  'SfeForm2DateFromPriorToValidationService',
  'gettext'
];

function TangoTimeSeriesConfigurations(
  $scope,
  $ngRedux,
  $timeout,
  TranslationService,
  gettextCatalog,
  ExternalReaderTangoProperties,
  ExternalWriterTangoProperties,
  CalculatedFlowTimeSeriesConfigurations,
  MaintenanceFlowTimeSeriesConfigurations,
  MtAnalysisFlowTimeSeriesConfigurations,
  ExternalWriterFlowFormConfigurations,
  ExternalReaderFlowFormConfigurations,
  MtAnalysisFlowFormConfigurations,
  SfeForm2Dialog,
  MaintenanceFlowFormConfigurations,
  CalculatedFlowFormConfigurations,
  AddFlowConfiguration,
  DateLocalizationService,
  SfeForm2DateFromPriorToValidationService,
  gettext
) {
  const vm = this;
  let unsubscribe;
  vm.entity = 234;

  vm.$onChanges = () => {
    //on any change check if all bindings are set
    if (vm.entityId && unsubscribe == null) {
      //assign state properties and measurement actions to controller scope
      // and listen for changes
      unsubscribe = $ngRedux.connect(mapStateToProps, {
        ...timeSeriesConfigurationActions,
        ...externalReaderFlowActions,
        ...externalWriterFlowActions,
        ...calculatedFlowActions,
        ...mtAnalysisFlowActions,
        ...maintenanceFlowActions
      })(vm);
      //trigger fetch time series configurations when we have id of time series
      vm.listTimeSeriesConfigurations({ timeSeriesId: vm.entityId });
      $scope.$on('$destroy', unsubscribe);
    }
  };

  function isActive(item) {
    let now = new Date();
    const { validFrom, validTo } = item;
    if (validTo == null) {
      return true;
    }
    return new Date(validFrom) < now && now < new Date(validTo);
  }

  /**
   * @description get valid from & valid to date fields.
   * @function
   * @param {Object} item
   * @return {Object} { validFrom, validTo }
   */
  const getDateFields = (item, previousValidTo, index) => {
    let fromToValidation = SfeForm2DateFromPriorToValidationService.get(
      'validFrom',
      'validTo'
    );
    let prettyPreviousValidTo = '';
    if (previousValidTo != null) {
      prettyPreviousValidTo = DateTime.fromJSDate(
        new Date(previousValidTo)
      ).toFormat('dd. MM. yyyy HH:mm');
    }

    const flowIsActive = isActive(item);

    const validFrom = {
      id: 'validFrom',
      title: gettextCatalog.getString('Valid from'),
      type: {
        name: 'date',
        options: {
          enableTime: true,
          enableSeconds: true,
          useCurrentTime: false
        }
      },
      initialize: () => {
        return item ? new Date(item.validFrom) : null;
      },
      disable: () => !flowIsActive,
      validators: {
        greaterThanValidFrom: fromToValidation,
        fromIntPrevTo: {
          /**
           * @description validates that active flow configuration from wouldn't intersect with previous.
           * @function
           * @param {Array} modelValue
           * @return {boolean}
           */
          fn: modelValue => {
            if (index == 0) {
              return true;
            }

            if (
              modelValue != null &&
              modelValue[0] <= new Date(previousValidTo)
            ) {
              return false;
            }
            return true;
          },
          text: gettextCatalog.getString(
            `Valid from should not intersect with previous valid to ${prettyPreviousValidTo}`
          )
        }
      },
      onChange: form => $timeout(form.revalidate),
      required: true,
      width: 6
    };

    const validTo = {
      id: 'validTo',
      title: gettextCatalog.getString('Valid to'),
      type: {
        name: 'date',
        options: {
          enableTime: true,
          useCurrentTime: true,
          enableSeconds: true
        }
      },
      initialize: () => {
        return item != null && item.validTo != null
          ? new Date(item.validTo)
          : null;
      },
      validators: {
        greaterThanValidFrom: fromToValidation,
        noFutureDates: {
          fn: modelValue => {
            if (modelValue != null && modelValue[0] > new Date()) {
              return false;
            }
            return true;
          },
          text: gettextCatalog.getString('Must be before todays date')
        }
      },
      onChange: form => {
        $timeout(() => {
          form.revalidate();
        });
      },
      disable: () => item != null && item.validTo != null,
      required: false,
      width: 6
    };

    return { validFrom, validTo };
  };

  /**
   * @description get form dialog based on flow type.
   * @function
   * @param {Object} flow
   * @return {Object} config
   */
  async function getFormDialog(flow, duplicateFlow) {
    let entityId;
    let config;
    switch (flow.flowRef) {
    // External reader flow.
    case 100:
      entityId = flow.flow;
      config = await ExternalReaderFlowFormConfigurations.get({
        actionType: duplicateFlow ? 'duplicate' : 'update',
        entityId,
        timeSeriesId: vm.timeSeries._id
      });
      break;
      // External writer flow.
    case 200:
      entityId = flow.flow;
      config = await ExternalWriterFlowFormConfigurations.get({
        actionType: duplicateFlow ? 'duplicate' : 'update',
        entityId,
        timeSeriesId: vm.timeSeries._id
      });
      break;
      //Calculated flow
    case 300:
      entityId = flow.flow;
      config = await CalculatedFlowFormConfigurations.get({
        actionType: duplicateFlow ? 'duplicate' : 'update',
        entityId,
        timeSeriesId: vm.timeSeries._id
      });
      break;
      //M&T Analysis Flow
    case 320:
      entityId = flow.flow;
      config = await MtAnalysisFlowFormConfigurations.get({
        actionType: duplicateFlow ? 'duplicate' : 'update',
        entityId,
        timeSeriesId: vm.timeSeries._id
      });
      break;
      //Maintenance flow
    case 500:
      entityId = flow.flow;
      config = await MaintenanceFlowFormConfigurations.get({
        actionType: duplicateFlow ? 'duplicate' : 'update',
        entityId,
        timeSeriesId: vm.timeSeries._id
      });
      break;
    }
    return config;
  }
  /**
   * @description returns last valid to date of current timeline.
   * @function
   * @return {Date|undefined}
   */
  const getLastValidToDate = () => {
    let lastItemValidToDate;
    if (vm.timeline != null && Array.isArray(vm.timeline.items)) {
      vm.timeline.items.forEach(item => {
        if (lastItemValidToDate == null) {
          lastItemValidToDate = item.validTo;
        } else if (item.validTo > lastItemValidToDate) {
          lastItemValidToDate = item.validTo;
        }
      });
    }
    return lastItemValidToDate;
  };
  /**
   * @description returns duplicate flow action configuration.
   * @function
   * @param {Object} flow
   * @param {Array} items - time series configurations
   * @return {Array}
   */
  const getDuplicateFlowConfiguration = (flow, items) => {
    return [
      {
        color: 'grey',
        icon: {
          type: 2,
          name: 'content_copy'
        },
        fn: async () => {
          let formConfig = await getFormDialog(flow, true);
          const lastItemValidToDate = getLastValidToDate();
          const validFrom = AddFlowConfiguration.getNewValidFrom(
            lastItemValidToDate
          );
          formConfig.fields = [validFrom, ...formConfig.fields];
          SfeForm2Dialog.open(formConfig);
        },
        disabledFn: () => {
          let isActive;
          if (Array.isArray(items)) {
            isActive = items.find(item => item.validTo == null);
          }
          return isActive ? true : false;
        }
      }
    ];
  };

  /**
   * @description dropdwon items for add configuration menu.
   * @function
   * @param {Array} items - time series configurations
   * @return {Array}
   */
  const addItemConfiguration = items => {
    const lastItemValidToDate = getLastValidToDate();

    return [
      {
        title: gettext('Add new configuration'),
        menuWidth: 5,
        // Call addItemFn based on flowTypeRef.
        items: [
          {
            title: gettext('External reader flow'),
            fn: () => {
              AddFlowConfiguration.get(100, vm.entityId, lastItemValidToDate);
            }
          },
          {
            title: gettext('External writer flow'),
            fn: () => {
              AddFlowConfiguration.get(200, vm.entityId, lastItemValidToDate);
            }
          },
          {
            title: gettext('Calculated flow'),
            fn: () => {
              AddFlowConfiguration.get(300, vm.entityId, lastItemValidToDate);
            },
            disabledFn: () => {
              // If data type is 3 - "Decimal".
              if (vm.timeSeries != null && vm.timeSeries.dataType === 3) {
                return false;
              }
              return true;
            }
          },
          {
            title: gettext('Mt analysis flow'),
            fn: () => {
              AddFlowConfiguration.get(320, vm.entityId, lastItemValidToDate);
            },
            disabledFn: () => {
              // If data type is 3 - "Decimal".
              if (vm.timeSeries != null && vm.timeSeries.dataType === 3) {
                return false;
              }
              return true;
            }
          },
          {
            title: gettext('Maintenance flow'),
            fn: () => {
              AddFlowConfiguration.get(500, vm.entityId, lastItemValidToDate);
            }
          }
        ],
        disabledFn: () => {
          let isActive;
          if (Array.isArray(items)) {
            isActive = items.find(item => item.validTo == null);
          }
          return isActive ? true : false;
        }
      }
    ];
  };
  /**
   * @description when previous configuration exists returns its date to value.
   * @function
   * @param {string} current configuration id
   * @return {date | undefined}
   */
  function getPreviousDateTo(currentId) {
    let currentIndex;
    if (vm.timeline != null && Array.isArray(vm.timeline.items)) {
      const sortedItems = vm.timeline.items.sort(
        (a, b) => a.dateRaw.from - b.dateRaw.from
      );
      currentIndex = sortedItems.findIndex(item => item._id == currentId);
      if (currentIndex > 0) {
        return {
          index: currentIndex,
          previousValidTo: sortedItems[currentIndex - 1].dateRaw.to
        };
      }
    }
    return { index: currentIndex };
  }

  /**
   * @description construct timeline with time series configuration.
   * @function
   * @param {Array} timeSeriesConfigs
   * @return {Object}
   */
  function constructTimeLine(timeSeriesConfigs) {
    const items = timeSeriesConfigs
      .map(config => {
        let flowTypeRef = TranslationService.GetCollectionById(
          'codelists.flowTypes',
          config.flowRef
        );
        let flowName = gettextCatalog.getString('Unknown Flow');
        if (flowTypeRef != null) {
          flowName = flowTypeRef.name;
          // Flow type is General Maintenance Flow or Meter Replacement Maintenance Flow
          if (flowTypeRef.id === 500 || flowTypeRef.id === 510) {
            flowName = gettextCatalog.getString('Maintenance Flow');
          }
        }
        /**
         * @description opens edit flow dialog.
         * @function
         */
        const editItemFn = async () => {
          const { previousValidTo, index } = getPreviousDateTo(config._id);

          let { validFrom, validTo } = getDateFields(
            config,
            previousValidTo,
            index
          );
          let formConfig = await getFormDialog(config);

          formConfig.fields = [validFrom, validTo, ...formConfig.fields];
          SfeForm2Dialog.open(formConfig);
        };
        /**
         * @description opens duplicate flow dialog.
         * @function
         */
        const duplicateFlowAction = getDuplicateFlowConfiguration(
          config,
          timeSeriesConfigs
        );

        const fn = () => {
          setTemplate(config);
        };
        return {
          ...config,
          name: flowName,
          date: formatDate(config),
          dateRaw: {
            from: new Date(config.validFrom),
            to: config.validTo ? new Date(config.validTo) : undefined
          },
          editItemFn,
          duplicateFlowAction,
          fn
        };
      })
      .filter(item => item.timeSeries == vm.entityId); //TEMP FILTER
    const addItemFn = () => {
      return addItemConfiguration(timeSeriesConfigs);
    };

    return { items, canEditOnlyLast: false, addItemFn };
  }

  /**
   * @description format from & to date.
   * @function
   * @param {Object} config
   * @return {Object}
   */
  function formatDate(config) {
    let to;
    let from;
    if (config.validFrom != null) {
      let toMilliseconds = new Date(config.validFrom).getTime();
      from = DateLocalizationService.LocalizationDateFn(toMilliseconds);
    }
    if (config.validTo != null) {
      let toMilliseconds = new Date(config.validTo).getTime();
      to = DateLocalizationService.LocalizationDateFn(toMilliseconds);
    }
    return {
      from,
      to
    };
  }

  /**
   * @description set template based on selected flow.
   * @function
   * @param {Object} flow
   */
  function setTemplate(flow) {
    const templatePrefix = 'TangoTimeSeriesConfigurationsTemplate';
    let template;
    let configuration;
    let entity;
    let entityId;
    let action;
    switch (flow.flowRef) {
    // External reader flow.
    case 100:
      template = `timeSeriesConfigsProperties${templatePrefix}`;
      configuration = ExternalReaderTangoProperties.get(flow);
      entity = 240;
      entityId = flow.flow;
      action = 'readExternalReaderFlow';
      break;
      // External writer flow.
    case 200:
      template = `timeSeriesConfigsProperties${templatePrefix}`;
      configuration = ExternalWriterTangoProperties.get(flow);
      entity = 241;
      entityId = flow.flow;
      action = 'readExternalWriterFlow';
      break;
      //Calculated flow
    case 300:
      template = `calculatedTimeSeriesFlow${templatePrefix}`;
      configuration = CalculatedFlowTimeSeriesConfigurations.get(flow);
      entity = 242;
      entityId = flow.flow;
      action = 'readCalculatedFlow';
      break;
      //M&T Analysis Flow
    case 320:
      template = `mtAnalysisTimeSeriesFlow${templatePrefix}`;
      configuration = MtAnalysisFlowTimeSeriesConfigurations.get(flow);
      entity = 244;
      entityId = flow.flow;
      action = 'readMtAnalysisFlow';
      break;
      //Maintenance flow
    case 500:
      template = `timeSeriesConfigsProperties${templatePrefix}`;
      entity = 246;
      configuration = MaintenanceFlowTimeSeriesConfigurations.get(flow);
      entityId = flow.flow;
      action = 'readMaintenanceFlow';
      break;
    }
    vm.template = template;
    vm.configuration = configuration;
    vm.flowEntity = entity;
    vm.flowId = entityId;
    let queryParams = { id: flow.flow, timeSeriesId: vm.entityId };
    triggerSelectedPropertyFetch(action, queryParams);
  }
  /**
   * @description Triggers fetch flow entity on time series configuration tab change.
   * @function
   * @param {string} action name of the action
   * @param {Object} query object of query filter parameters
   */
  function triggerSelectedPropertyFetch(action, query) {
    let method = vm[action];
    if (typeof method === 'function') {
      $timeout(() => {
        method(query);
      }, 10);
    }
  }

  /**
   * @description  Triggered every time state changes and sets
   * actions, header and icon data to scope.
   * @function
   * @param {Object} state store state object
   * @return {Object}
   */
  function mapStateToProps(state) {
    let timeline;
    if (state != null) {
      if (
        state.timeSeriesConfigurations != null &&
        state.timeSeriesConfigurations.list != null &&
        state.timeSeriesConfigurations.list[vm.entityId] != null &&
        Array.isArray(state.timeSeriesConfigurations.list[vm.entityId].data)
      ) {
        let configurations = state.timeSeriesConfigurations.list[
          vm.entityId
        ].data.filter(item => item.timeSeries == vm.entityId); //TEMP FILTER;
        if (
          vm.timeline != null &&
          vm.timeline.items.length == configurations.length
        ) {
          let wasUpdated = configurations.find((config, index) => {
            if (
              config.validFrom != vm.timeline.items[index].validFrom ||
              config.validTo != vm.timeline.items[index].validTo
            ) {
              return config;
            }
          });
          if (wasUpdated == null) {
            return {};
          }
        }
        timeline = constructTimeLine(configurations);
      }
      if (state.timeSeries != null && state.timeSeries[vm.entityId] != null) {
        vm.timeSeries = state.timeSeries[vm.entityId].data;
      }
    }
    return {
      timeline
    };
  }
}
