NewFileFormController.$inject = [
  '$stateParams',
  'gettext',
  'MetadataService',
  'SfeHeader',
  'file',
  '$timeout'
];

function NewFileFormController(
  $stateParams,
  gettext,
  MetadataService,
  SfeHeader,
  file,
  $timeout
) {
  const vm = this;
  const fileId = $stateParams.id;
  const editMode = Boolean(fileId);

  vm.$onInit = function init() {
    vm.dataConfig = {
      action: {
        resource: true,
        title: editMode ? gettext('Update') : gettext('Create'),
        configuration: {
          entity: 'files',
          method: editMode ? 'update' : 'create'
        },
        method: editMode ? 'PUT' : 'POST',
        paramName: 'id',
        successMsg: editMode ? 'update' : 'create',
        errMsg: 'Error at creating new file'
      },
      redirect: {
        param: 'id',
        paramValue: fileId,
        location: 'external-datasources-connectors-file-view'
      },
      data: getFields()
    };

    if (editMode) {
      MetadataService.Loading(true);
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit File'),
        'external-datasources-connectors-file-view',
        $stateParams
      );
      vm.dataConfig.dataObj = {
        type: file.type,
        name: file.name,
        description: file.description,
        tagColumnName: file.tagColumnName,
        path: file.path,
        dateTimeColumnName: file.dateTimeColumnName,
        valueColumnName: file.valueColumnName,
        delimiter: file.delimiter
      };
      $timeout(() => {
        MetadataService.Loading(false);
        MetadataService.ChangeMetadata('Edit ' + file.name, file.description);
      });
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New File'),
        'external-datasources-connectors-list'
      );
      vm.dataConfig.dataObj = {};
    }
  };

  function getFields() {
    const pathRegex = /((^([a-z]|[A-Z]):(?=\\(?![\0-\37<>:"/\\|?*])|\/(?![\0-\37<>:"/\\|?*])|$)|^\\(?=[\\/][^\0-\37<>:"/\\|?*]+)|^(?=(\\|\/)$)|^\.(?=(\\|\/)$)|^\.\.(?=(\\|\/)$)|^(?=(\\|\/)[^\0-\37<>:"/\\|?*]+)|^\.(?=(\\|\/)[^\0-\37<>:"/\\|?*]+)|^\.\.(?=(\\|\/)[^\0-\37<>:"/\\|?*]+))((\\|\/)[^\0-\37<>:"/\\|?*]+|(\\|\/)$)*()$|^\/$|(^(?=\/)|^\.|^\.\.)(\/(?=[^/\0])[^/\0]+)*\/?$)/;

    return [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textArea',
        maxlength: 500,
        type: 'text',
        required: false
      },
      {
        componentType: 'autocompleteDialog',
        edit: editMode,
        configuration: {
          codelist: 'fileTypes',
          floatingLabel: gettext('Choose file type'),
          searchParamName: 'name',
          entity: 'fileTypes',
          filterFn: items => items.filter(item => item.supportedByTangoAgent),
          dialogConfiguration: {
            multiple: false,
            title: gettext('File type')
          },
          required: true
        },
        objToId: true,
        name: 'type'
      },
      {
        placeholder: gettext('Path'),
        name: 'path',
        componentType: 'textField',
        type: 'text',
        pattern: new RegExp(pathRegex),
        patternExample: '/some/path/ C:\\dir1\\'
      },
      {
        placeholder: gettext('Tag column name'),
        name: 'tagColumnName',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Date column name'),
        name: 'dateTimeColumnName',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Value column name'),
        name: 'valueColumnName',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Delimiter'),
        name: 'delimiter',
        componentType: 'textField',
        type: 'text',
        pattern: new RegExp('^.$'),
        patternExample: ', . ;'
      }
    ];
  }
}

export default NewFileFormController;
