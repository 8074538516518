CostCentreModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function CostCentreModel(ModelUtilities, CrawlerMethods) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'cost-centres'
  };

  const crawlerConfiguration = {
    entity: 'cost-centres',
    method: 'read',
    populate: 'profitCentre,companyStructureNode,project',
    steps: [
      {
        entity: 'system-tag-entities',
        valueParams: ['_id'],
        queryParams: ['entityId'],
        method: 'custom.readWithTag',
        methodType: 'add',
        newParamNames: ['tags']
      }
    ]
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readItemView: CrawlerMethods.crawler(crawlerConfiguration)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default CostCentreModel;
