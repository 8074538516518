TriggeredAlarmModel.$inject = [
  'ModelUtilities',
  'CrawlerMethods',
  'TranslationService'
];

function TriggeredAlarmModel(
  ModelUtilities,
  CrawlerMethods,
  TranslationService
) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'triggered-alarms'
  };

  const networkConfigurationView = {
    host: 'view',
    path: 'dashboardcardsmalltriggeredalarms'
  };

  const crawlerConfigurationAlarmCache = {
    entity: 'triggered-alarms',
    method: 'read',
    steps: [
      {
        entity: 'alarms',
        valueParams: ['alarm'],
        queryParams: ['_id'],
        method: 'custom.readSeverity',
        methodType: 'addArray',
        newParamNames: ['alarmObject']
      }
    ]
  };

  const crawlerConfiguration = {
    entity: 'triggered-alarms',
    method: 'read',
    populate: 'alarm,timeSeries',
    steps: [
      {
        entity: 'users',
        valueParams: ['currentAssignee'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'addArray',
        newParamNames: ['currentAssigneeObject']
      },
      {
        entity: 'severities',
        valueParams: ['alarm.severity'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'populateArray'
      }
    ]
  };

  const alarmMetadataConfiguration = [
    {
      entity: 'users',
      valueParams: ['responsiblePerson'],
      queryParams: ['id'],
      method: 'read',
      methodType: 'add',
      newParamNames: ['responsiblePersonObject']
    }
  ];

  const crawlerConfigurationItemView = {
    entity: 'triggered-alarms',
    method: 'read',
    populate:
      'streamEntityId,locationDetails.location,locationDetails.asset,timeSeries,locationDetails.measuringPoint,locationDetails.costCentre',
    steps: [
      {
        entity: 'users',
        valueParams: ['currentAssignee'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'addArray',
        newParamNames: ['currentAssigneeObject']
      },
      {
        entity: 'alarms',
        valueParams: ['alarm'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'populateArray',
        populate: 'severity,resolutionTime'
      }
    ].concat(alarmMetadataConfiguration)
  };

  function readMetadata(alarm) {
    return CrawlerMethods.populate(
      angular.copy(alarm),
      alarmMetadataConfiguration
    );
  }

  const crawlerConfigurationDialog = {
    entity: 'triggered-alarms',
    method: 'read',
    populate: 'alarm,timeSeries',
    select:
      'alarm,_id,timSeries,currentAssignee,alarmStatus,externalCode,triggeredTimestamp',
    steps: [
      {
        entity: 'users',
        valueParams: ['currentAssignee'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'populateArray'
      },
      {
        entity: 'triggered-alarms',
        valueParams: ['alarmStatus'],
        queryParams: ['id'],
        method: 'custom.getAlarmStatus',
        methodType: 'populate',
        noMerge: true
      }
    ]
  };

  async function getAlarmStatus(filter) {
    const entities = TranslationService.GetCollection(
      'codelists.alarmStatuses'
    );
    if (filter && filter.id) {
      const selected = entities.find(item => filter.id == item.id);
      return selected;
    }
  }

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readList: CrawlerMethods.crawler(crawlerConfiguration),
      readMetadata: readMetadata,
      readItemView: CrawlerMethods.crawler(crawlerConfigurationItemView),
      readAlarmCache: CrawlerMethods.crawler(crawlerConfigurationAlarmCache),
      readDialog: CrawlerMethods.crawler(crawlerConfigurationDialog),
      getAlarmStatus,
      readSmallDashboardCard: ModelUtilities.crud(
        'GET',
        networkConfigurationView,
        modelStorage
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default TriggeredAlarmModel;
