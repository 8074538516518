module.exports = {
  entity: 'external-writer-datapoints',
  path: 'external-writer-datapoints',
  name: {
    en: 'External writer datapoints',
    sl_SI: 'Izhodne podatkovne točke'
  },
  networkModel: {
    entity: 'external-writer-datapoints',
    read: 'read'
  },
  scope: 'tis_be:external-writer-datapoints',

  dialog: {
    title: {
      en: 'Select External writer datapoint',
      sl_SI: 'Izberite Izhodno podatkovno točko'
    },
    listInfo: {
      en: 'Select External writer datapoint from the list below',
      sl_SI: 'Izberite Izhodno podatkovno točko s spodnjega seznama'
    }
  }
}
