import { DateTime } from 'luxon';

import template from './irregular-manual-input.component.html';
import './irregular-manual-input.scss';

export default {
  template,
  bindings: {
    api: '<',
    displayModeVertically: '<',
    saveValue: '<'
  },
  controller: RegularManualInput,
  controllerAs: 'vm'
};

RegularManualInput.$inject = [
  '$scope',
  'gettextCatalog',
  'AlertingService',
  'ManualInputFormHelper'
];

function RegularManualInput(
  $scope,
  gettextCatalog,
  AlertingService,
  ManualInputFormHelper
) {
  const vm = this;
  vm.dateAutocomplete = ManualInputFormHelper.dateAutocomplete;
  /**
   * @description assigns trigger functions to api object.
   * @function
   * @param {object} changes
   */
  vm.$onChanges = changes => {
    if (changes.api && vm.api != null && typeof vm.api === 'object') {
      vm.api.loadValues = loadValues;
      vm.api.addNewDate = addNewDate;
      vm.api.getValues = () => ({
        dates: vm.available,
        timeSeries: vm.globalTimeSeries
      });
    }
  };

  vm.switchChanged = ManualInputFormHelper.switchChanged;

  /**
   * @description adds a new row for a new date.
   * @function
   * @param {Date} newDate
   * @param {object} manualInput
   * @param {Array} timeSeries
   * @return {dataType}
   */
  async function addNewDate(
    newDate,
    manualInput,
    timeSeries,
    defaultTimeSeries
  ) {
    vm.addingNewTimeSeries = true;
    const newTimestamp = DateTime.fromJSDate(new Date(newDate)).toMillis();
    let dates = angular.copy(vm.available) || [];
    const timestampExist = dates.findIndex(
      item => item.validAt == newTimestamp
    );
    if (timestampExist < 0) {
      const allTimeSeries = vm.globalTimeSeries;
      vm.globalTimeSeries = [];

      dates.push({ validAt: newTimestamp });
      dates = dates.sort((a, b) => a.validAt - b.validAt);

      const newTimestampIndex = dates.findIndex(
        item => item.validAt == newTimestamp
      );
      //CURRENTLY VALID VALUES
      const timeSeriesCurrentValues = await ManualInputFormHelper.getTimeSeriesCurrentValues(
        timeSeries,
        {
          from: newTimestamp,
          to: newTimestamp
        }
      );
      //DEFAULT VALUES
      const defaultValues = await getDefaultValues(
        manualInput,
        newTimestamp,
        timeSeriesCurrentValues,
        defaultTimeSeries
      );
      //ADD TO EXISTENT TABLE
      if (Array.isArray(vm.available) && vm.available.length > 0) {
        allTimeSeries.forEach(ts => {
          const input = ManualInputFormHelper.getTimeSeriesModelValues(
            { ...ts },
            [{ validAt: newTimestamp }],
            timeSeriesCurrentValues[ts._id],
            defaultValues[ts._id]
          );
          ts.modelValues.splice(newTimestampIndex, 0, input[0]);
        });
        vm.available = dates;
        vm.globalTimeSeries = allTimeSeries;
      } else {
        //INITIATE NEW TABLE
        const {
          manualInput: manualInputGroup,
          globalTimeSeries
        } = ManualInputFormHelper.constructManualInputFrom({
          manualInput,
          timeSeries,
          lastValueDefaultValue: defaultValues,
          timeSeriesDefaultValue: defaultValues,
          timeSeriesCurrentValues,
          dates
        });
        vm.available = dates;

        vm.manualInput = manualInputGroup;
        vm.globalTimeSeries = globalTimeSeries;
      }
    } else {
      AlertingService.Error('Date already exists');
    }
    vm.addingNewTimeSeries = false;
    $scope.$applyAsync();
  }

  /**
   * @description returns default values for a new date input that has missing current values  .
   * @function
   * @param {Object} manualInput
   * @param {Date} newDate
   * @param {Object} timeSeriesCurrentValues {[id]: [values]}
   * @return {Object} {[id]: [values]}
   */
  async function getDefaultValues(
    manualInput,
    newDate,
    timeSeriesCurrentValues,
    defaultTimeSeries
  ) {
    const timeSeriesWithMissingValues = Object.keys(
      timeSeriesCurrentValues
    ).reduce((result, id) => {
      if (timeSeriesCurrentValues[id].length === 0) {
        return [...result, id];
      }
      return result;
    }, []);
    if (timeSeriesWithMissingValues.length > 0) {
      const groups = manualInput.groups.map(group => {
        let timeSeriesItems = group.timeSeriesItems.filter(item =>
          timeSeriesWithMissingValues.includes(item.timeSeries)
        );

        return {
          timeSeriesItems
        };
      });

      const timeSeriesDefaultValue = await ManualInputFormHelper.getTimeSeriesDefaultValue(
        groups,
        {
          from: newDate,
          to: newDate
        },
        defaultTimeSeries
      );
      const lastValueDefaultValue = await ManualInputFormHelper.getLastValueDefaultValue(
        manualInput.groups
      );
      return {
        ...timeSeriesDefaultValue,
        ...lastValueDefaultValue
      };
    }
    return {};
  }

  /**
   * @description loads manual input data.
   * @function
   * @param {Object} params {range: {from, to}, manualInput, timeSeries}
   */
  async function loadValues(params) {
    vm.available = [];
    vm.globalTimeSeries = [];
    let noTimeSeriesSelected = true;

    const { range, manualInput, timeSeries, defaultTimeSeries } = params;
    const timeSeriesCurrentValues = await ManualInputFormHelper.getTimeSeriesCurrentValues(
      timeSeries,
      range
    );

    const dates = constructDates(timeSeriesCurrentValues);
    if (dates.length <= 100) {
      const lastValueDefaultValue = await ManualInputFormHelper.getLastValueDefaultValue(
        manualInput.groups
      );
      const timeSeriesDefaultValue = await ManualInputFormHelper.getTimeSeriesDefaultValue(
        manualInput.groups,
        range,
        defaultTimeSeries
      );
      const {
        manualInput: manualInputGroup,
        globalTimeSeries
      } = ManualInputFormHelper.constructManualInputFrom({
        manualInput,
        timeSeries,
        lastValueDefaultValue,
        timeSeriesDefaultValue,
        timeSeriesCurrentValues,
        dates
      });
      vm.available = dates;
      vm.manualInput = manualInputGroup;
      vm.globalTimeSeries = globalTimeSeries;
      if (vm.globalTimeSeries.length > 0) {
        noTimeSeriesSelected = false;
      }
    } else {
      AlertingService.Error(
        gettextCatalog.getString(
          'There are too many values for selected period try to reduce selected interval'
        )
      );
    }
    $scope.$applyAsync();
    return {
      noTimeSeriesSelected
    };
  }

  /**
   * @description returns all selected dates.
   * @function
   * @param {Array} timeSeriesCurrentValues values for selected interval
   * @return {Array}
   */
  function constructDates(timeSeriesCurrentValues) {
    const dates = Object.values(timeSeriesCurrentValues).reduce(
      (result, timeSeriesCurrentValue) => {
        const dates = timeSeriesCurrentValue.map(item => item.validAt);
        return [...result, ...dates];
      },
      []
    );
    return Array.from(new Set(dates))
      .sort((a, b) => a - b)
      .map(timestamp => ({ validAt: timestamp }));
  }
}
