import { DateTime } from 'luxon';
ExternalDatasourceConfiguration.$inject = [
  'gettext',
  '$q',
  'TranslationService'
];

function ExternalDatasourceConfiguration(gettext, $q, TranslationService) {
  var configuration = {
    listId: 'deviceListId',
    entity: {
      singular: gettext('External Datasource'),
      plural: gettext('External Datasources')
    },
    api: {
      query: {
        entity: 'external-datasources',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'external-datasources-external-datasources-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'external-datasources-external-datasources-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'external-datasources-external-datasources-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'external-datasources',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Type'),
        param: 'typeName',
        type: 'text'
      },
      {
        title: gettext('Version'),
        param: 'externalDatasourceVersion',
        type: 'text'
      }
    ],
    badges: [
      {
        title: gettext('Status'),
        goodOrBad: true,
        colored: true,
        param: 'statusGood',
        type: 'goodOrBad',
        good: gettext('Good'),
        bad: gettext('Bad')
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('External Datasource Version'),
        param: 'externalDatasourceVersion',
        type: 'string'
      },
      {
        collection: TranslationService.GetCollection(
          'codelists.externalDatasourceTypes'
        ).filter(type => type.configurable),
        valueField: 'id',
        display: gettext('External Datasource Type'),
        param: 'externalDatasourceType'
      },
      {
        query: null,
        display: gettext('Maximum Seconds since last Heartbeat'),
        param: 'lastHeartbeat',
        type: 'number',
        transform: function(value) {
          if (typeof value == 'number') {
            return '{lt}now-' + value * 1000;
          }
        }
      },
      {
        display: gettext('Tango Agent Connection Configuration'),
        query: {
          entity: 'tango-agent-connection-configs',
          method: 'read'
        },
        entity: 'tango-agent-connection-configs',
        param: '_id',
        valueField: 'externalDatasource',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: 'externalDatasource'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      },
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'name DESC',
        params: '-name'
      }
    ]
  };

  /*
   *
   *   ENRICH
   *
   */

  function Enrich(item) {
    var deferred = $q.defer();
    if (item.lastHeartbeat != undefined) {
      var diff = DateTime.fromJSDate(new Date(item.lastHeartbeat)).diffNow();
      if (diff && diff.values) {
        diff = Math.abs(diff.values.milliseconds);
        if (diff > item.timeoutInMilliseconds) {
          item.statusGood = false;
        } else {
          item.statusGood = true;
        }
      }
      item.heartbeatAgo = diff;
    } else {
      item.statusGood = false;
    }

    if (
      typeof item.externalDatasourceType != 'undefined' &&
      item.externalDatasourceType !== null
    ) {
      item.typeName = TranslationService.GetCollectionById(
        'codelists.externalDatasourceTypes',
        item.externalDatasourceType
      ).name;
    }
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default ExternalDatasourceConfiguration;
