NewDashboardPlaylistController.$inject = [
  'gettext',
  '$stateParams',
  'AlertingService',
  'MetadataService',
  'createOrUpdateService',
  'SfeHeader',
  '$scope',
  'dashboardPlaylist'
];

function NewDashboardPlaylistController(
  gettext,
  $stateParams,
  AlertingService,
  MetadataService,
  createOrUpdateService,
  SfeHeader,
  $scope,
  dashboardPlaylist
) {
  var vm = this;
  var playlistId = $stateParams.id;
  vm.dataConfig = {};
  vm.editMode = !!playlistId;
  vm.createOrUpdate = createOrUpdate;

  init();

  function init() {
    var data = [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        type: 'text',
        required: true
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textField',
        type: 'text',
        required: false
      },
      {
        configuration: {
          codelist: 'refreshIntervals',
          entity: 'entities',
          noDialog: true,
          floatingLabel: gettext('Select refresh Interval'),
          searchParamName: 'name',
          required: true
        },
        componentType: 'autocompleteDialog',
        edit: vm.editMode,
        name: 'refreshInterval'
      },
      {
        configuration: {
          codelist: 'cachingIntervals',
          entity: 'entities',
          noDialog: true,
          floatingLabel: gettext('Select cache interval'),
          searchParamName: 'name',
          required: true
        },
        componentType: 'autocompleteDialog',
        edit: vm.editMode,
        name: 'cachingInterval'
      },
      {
        componentType: 'dndAutocomplete',
        edit: vm.editMode,
        name: 'dashboardList',
        configuration: {
          query: {
            entity: 'dashboards',
            method: 'read'
          },
          floatingLabel: gettext('Select dashboard *'),
          searchParamName: 'filter',
          entity: 'dashboards',
          required: false
        }
      }
    ];

    if (vm.editMode) {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit dashboard playlist'),
        'dashboards-dashboard-playlists-list'
      );
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New dashboard playlist'),
        'dashboards-dashboard-playlists-list'
      );
    }

    vm.dataConfig.data = data;
    vm.dataConfig.PH16 = true;

    if (vm.editMode) {
      MetadataService.Loading(true);
      setPlaylistForm();
    } else {
      vm.dataConfig.dataObj = {
        name: '',
        description: '',
        dashboardList: []
      };
    }
  }

  /**
   * @description sets dataObj for playlist form.
   * @function
   */
  function setPlaylistForm() {
    vm.dataConfig.dataObj = {
      name: dashboardPlaylist.name,
      description: dashboardPlaylist.description,
      dashboardList: dashboardPlaylist.dashboards,
      refreshInterval: dashboardPlaylist.refreshInterval,
      cachingInterval: dashboardPlaylist.cachingInterval
    };

    MetadataService.Loading(false);
    MetadataService.ChangeMetadata(
      'Edit ' + dashboardPlaylist.name,
      dashboardPlaylist.description
    );
  }

  function createOrUpdate() {
    vm.sendingRequest = true;
    createOrUpdateService
      .simpleCall(
        {
          entity: 'dashboard-playlists',
          method: vm.editMode ? 'update' : 'create'
        },
        vm.editMode,
        playlistId,
        dataToSave(),
        'dashboards-dashboard-playlists-list',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function dataToSave() {
    const dashboards = _.map(vm.dataConfig.dataObj.dashboardList, '_id');
    return {
      name: vm.dataConfig.dataObj.name,
      description: vm.dataConfig.dataObj.description,
      refreshInterval: vm.dataConfig.dataObj.refreshInterval.id,
      cachingInterval: vm.dataConfig.dataObj.cachingInterval.id,
      dashboards
    };
  }

  vm.actions = [
    {
      title: vm.editMode ? gettext('Update') : gettext('Create'),
      fn: vm.createOrUpdate,
      color: 'primary',
      raised: true,
      disabledFn: () => {
        return (
          !$scope.Form.$valid ||
          !vm.dataConfig.dataObj ||
          !vm.dataConfig.dataObj.dashboardList.length ||
          vm.sendingRequest
        );
      }
    }
  ];
}

export default NewDashboardPlaylistController;
