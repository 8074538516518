/**
 * @ngdoc directive
 * @name common.sfeWrapElement
 * @description Function that wraps an anchor tag around the HTML element.
 * @param {string} wrapperElement - element you are using to wrap
 * @example
 * <sfe-actions
 * sfe-wrap-element
 * wrapper-element="div"
 * ></sfe-actions>
 */
export default [
  '$compile',
  '$timeout',
  function($compile, $timeout) {
    return {
      restrict: 'A',
      scope: {
        wrapperElement: '@'
      },
      link: function(scope, element) {
        $timeout(function() {
          if (scope.wrapperElement) {
            const compiledElement = $compile(
              `<${scope.wrapperElement}></${scope.wrapperElement}>`
            )(scope);
            element.wrap(compiledElement);
          }
        });
      }
    };
  }
];
