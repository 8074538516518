TimeSeriesMeasuringPointsEntityDateConfiguration.$inject = [
  'gettext',
  '$q',
  'AlertingService',
  'Formatting',
  'CrudToastFactory',
  'TimeSeriesMeasuringpointModel',
  'MeasuringpointModel'
];

function TimeSeriesMeasuringPointsEntityDateConfiguration(
  gettext,
  $q,
  AlertingService,
  Formatting,
  CrudToastFactory,
  TimeSeriesMeasuringpointModel,
  MeasuringpointModel
) {
  var createObject = {
    validFrom: new Date(new Date().setHours(0, 0, 0, 0)),
    validTo: null
  };
  var configuration = {
    listId: 'measuringPoints',
    type: 'multiple',
    entity: {
      title: gettext('Measuring points'),
      inactiveTitle: gettext('Inactive measuring points')
    },
    api: {
      query: {
        entity: 'time-series-measuringpoints',
        method: 'custom.readMeasuringpoint'
      },
      activeFilterObject: {
        isActive: true
      },
      inactiveFilterObject: {
        isActive: false
      },
      enrich: Enrich
    },
    create: {
      form: getMeasuringpointsFormConfig(),
      createObject: angular.copy(createObject),
      createFn: create
    },
    update: update,
    getUpdateObject: getUpdateObject,
    delete: function(item) {
      var deferred = $q.defer();
      TimeSeriesMeasuringpointModel.delete({
        id: item._id
      }).then(
        function() {
          CrudToastFactory.toast('delete');
          deferred.resolve();
        },
        function(err) {
          AlertingService.Error(err);
          deferred.reject();
        }
      );
      return deferred.promise;
    },
    title: {
      param: 'name',
      link: {
        state: 'company-resources-measuring-points-view',
        params: [
          {
            valueParam: 'measuringpointId',
            queryParam: 'id'
          }
        ]
      }
    },
    shortActions: [
      {
        type: 'edit',
        icon: 'mode_edit'
      },
      {
        icon: 'delete',
        type: 'delete'
      }
    ],
    badges: [
      {
        title: gettext('Code'),
        param: 'code',
        type: 'code'
      },
      {
        title: gettext('Icon'),
        param: 'icon',
        type: 'icon'
      }
    ],
    attributes: [
      {
        title: gettext('Valid from'),
        param: 'validFromReadable',
        type: 'date'
      },
      {
        title: gettext('Valid to'),
        param: 'validToReadable',
        type: 'date'
      },
      {
        title: gettext('Energy source type'),
        param: 'energySourceTypeName',
        type: 'text'
      },
      {
        title: gettext('Location'),
        param: 'locationName',
        type: 'text'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ],
    extended: {
      query: Extend,
      items: [
        {
          title: gettext('Type'),
          param: 'type',
          type: 'text'
        },
        {
          title: gettext('Kind'),
          param: 'kind',
          type: 'text'
        },
        {
          title: gettext('Class'),
          param: 'class',
          type: 'text'
        },
        {
          title: gettext('Distributer'),
          param: 'distributer',
          link: {
            state: 'company-resources-business-partners-view',
            params: [
              {
                valueParam: 'businessPartnerDistributor',
                queryParam: 'id'
              }
            ]
          },
          type: 'link'
        },
        {
          title: gettext('Supplier'),
          param: 'supplier',
          link: {
            state: 'company-resources-business-partners-view',
            params: [
              {
                valueParam: 'businessPartnerSupplier',
                queryParam: 'id'
              }
            ]
          },
          type: 'link'
        }
      ]
    }
  };

  function getMeasuringpointsFormConfig() {
    return [
      {
        name: 'measuringpoint',
        edit: true,
        componentType: 'autocompleteDialog',
        configuration: {
          query: {
            entity: 'measuringpoints',
            method: 'read'
          },
          floatingLabel: gettext('Select measuring point'),
          searchParamName: 'filter',
          entity: 'measuringpoints',
          createRedirect: {
            state: 'company-resources-measuring-points-new'
          },
          required: true
        }
      },
      {
        componentType: 'datePickerFromTo',
        from: 'validFrom',
        to: 'validTo',
        valToEmpty: true,
        labelFrom: gettext('Valid from *'),
        labelTo: gettext('Valid to'),
        required: true,
        onlyStartRequired: true,
        idFrom: 'from',
        idTo: 'to'
      }
    ];
  }

  function create(obj, paramObject) {
    var deferred = $q.defer();
    var apiObj = {
      validFrom: Formatting.formatDate(obj.validFrom, '00:00:00'),
      validTo: Formatting.formatDate(obj.validTo, '23:59:59:999'),
      measuringpoint: obj.measuringpoint._id
    };
    apiObj = Object.assign(apiObj, paramObject);
    TimeSeriesMeasuringpointModel.create(apiObj).then(
      function() {
        CrudToastFactory.toast('create');
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );
    return deferred.promise;
  }

  function update(obj, originalItem, paramObject) {
    var deferred = $q.defer();
    var apiObj = {
      validFrom: Formatting.formatDate(obj.validFrom, '00:00:00'),
      validTo: Formatting.formatDate(obj.validTo, '23:59:59:999') || null,
      measuringpoint: obj.measuringpoint._id
    };
    apiObj = Object.assign(apiObj, paramObject);
    TimeSeriesMeasuringpointModel.update(
      {
        id: originalItem._id
      },
      apiObj
    ).then(
      function() {
        CrudToastFactory.toast('update');
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );
    return deferred.promise;
  }

  function getUpdateObject(item) {
    var validFrom = new Date(item.validFrom);
    var validTo =
      item.validTo && new Date(item.validTo) instanceof Date
        ? new Date(item.validTo)
        : null;
    return {
      validFrom: validFrom,
      validTo: validTo,
      measuringpoint: item.measuringpoint
    };
  }

  function setFetching(item, what) {
    item.fetchingObject[what] = {
      fetching: true,
      success: false,
      nodata: false
    };
  }

  function setNodata(item, what) {
    item.fetchingObject[what] = {
      fetching: false,
      success: true,
      nodata: true
    };
  }

  function setSuccess(item, what) {
    item.fetchingObject[what] = {
      fetching: false,
      success: true,
      nodata: false
    };
  }

  function Enrich(item) {
    var deferred = $q.defer();
    setFetching(item, 'validFromReadable');
    setFetching(item, 'validToReadable');
    setFetching(item, 'name');
    setFetching(item, 'code');
    setFetching(item, 'energySourceTypeName');
    setFetching(item, 'locationName');

    var measuringpoint = item.measuringpoint;
    if (measuringpoint) {
      item.name = measuringpoint.name;
      item.code = measuringpoint.code;
      if (
        measuringpoint.hasOwnProperty('energySourceType') &&
        measuringpoint.energySourceType !== null
      ) {
        item.energySourceTypeName = measuringpoint.energySourceType.name;
        item.icon = measuringpoint.energySourceType.icon;
      } else {
        setNodata(item, 'energySourceTypeName');
        setNodata(item, 'icon');
      }

      if (
        measuringpoint.hasOwnProperty('location') &&
        measuringpoint.location !== null
      ) {
        item.locationName = measuringpoint.location.name;
        item.locationId = measuringpoint.location._id;
      } else {
        setNodata(item, 'locationName');
      }
    } else {
      item.name = gettext('Unknown');
      setNodata(item, 'energySourceTypeName');
      setNodata(item, 'icon');
      setNodata(item, 'locationName');
    }

    if (
      item.hasOwnProperty('validFrom') &&
      item.validFrom !== null &&
      item.validFrom !== undefined
    ) {
      item.validFromReadable = item.validFrom;
    } else {
      setNodata(item, 'validFromReadable');
    }

    if (
      item.hasOwnProperty('validTo') &&
      item.validTo !== null &&
      item.validTo !== undefined
    ) {
      item.validToReadable = item.validTo;
    } else {
      setNodata(item, 'validToReadable');
    }
    setSuccess(item, 'validFromReadable');
    setSuccess(item, 'validToReadable');
    setSuccess(item, 'name');
    setSuccess(item, 'code');
    setSuccess(item, 'energySourceTypeName');
    setSuccess(item, 'locationName');
    if (measuringpoint) {
      item.measuringpointId = measuringpoint._id;
    }
    deferred.resolve();
    return deferred.promise;
  }

  function Extend(item) {
    var deferred = $q.defer();
    MeasuringpointModel.custom.readMetadata(item.measuringpoint).then(
      function(data) {
        item.type = 'Unknown';
        item.kind = 'Unknown';
        item.class = 'Unknown';
        item.distributer = 'Unknown';
        item.supplier = 'Unknown';
        if (
          data.hasOwnProperty('measuringpointType') &&
          data.measuringpointType !== null
        ) {
          item.type = data.measuringpointType.name;
        }

        if (
          data.hasOwnProperty('businessPartnerDistributor') &&
          data.businessPartnerDistributor !== null
        ) {
          item.distributer = data.businessPartnerDistributor.name;
        }
        if (
          data.hasOwnProperty('businessPartnerSupplier') &&
          data.businessPartnerSupplier !== null
        ) {
          item.supplier = data.businessPartnerSupplier.name;
        }
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );

    return deferred.promise;
  }

  return configuration;
}
export default TimeSeriesMeasuringPointsEntityDateConfiguration;
