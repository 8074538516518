/**
 * @ngdoc service
 * @name core.utilService
 * @description Service for backend methods.
 * @property {function} getHost
 * @property {function} getApiHost
 * @property {function} getApiReport
 * @property {function} getApiAccount
 * @property {function} getCatalogId
 * @property {function} getHostNoPrefix
 * @property {object} energySegmentConfiguration
 */
function utilService() {
  var backendConfigurations = {
    api: window.customization.backend,
    esb: window.customization.esb,
    domain: window.customization.domainBackend,
    view: window.customization.viewBackend,
    authorization: window.customization.authorizationBackend,
    authenticator: window.customization.authenticatorBackend,
    identity: window.customization.identityBackend,
    enrichment: window.customization.enrichmentBackend,
    report: window.customization.reportBackend,
    notification: window.customization.notificationBackend,
    account: window.customization.accountBackend,
    gis: window.customization.gisBackend
  };

  const energySegmentConfiguration = window.customization.energySegment;
  const gisSdk = window.customization.gisSdk;
  /**
   * @memberof utilService.getHost
   * @description returns backendC host.
   * @param {string} serviceName service name we want to get host for
   * @return {string}
   */
  var getHost = function(serviceName) {
    var service = backendConfigurations[serviceName];
    return (
      service.protocol +
      '://' +
      service.host +
      ':' +
      service.port +
      '/' +
      service.prefix
    );
  };
  /**
   * @memberof utilService.getHostNoPrefix
   * @description returns backendC host without prefix.
   * @param {string} serviceName service name we want to get host for
   * @return {string}
   */
  function getHostNoPrefix(serviceName) {
    var service = backendConfigurations[serviceName];
    return (
      service.protocol +
      '://' +
      service.host +
      (service.port ? ':' : '') +
      service.port
    );
  }
  /**
   * @memberof utilService.getCatalogId
   * @description returns domain catalog id.
   * @return {string}
   */
  var getCatalogId = function() {
    return window.customization.domainCatalogId;
  };

  const getEnv = () => window.customization.environment;

  return {
    getHost: getHost,
    getApiHost: getHost('api'),
    getApiReport: getHost('report'),
    getApiAccount: getHost('account'),
    getCatalogId,
    getHostNoPrefix,
    energySegmentConfiguration,
    gisSdk,
    getEnv
  };
}

export default utilService;
