ExternalDatasourceModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function ExternalDatasourceModel(ModelUtilities, CrawlerMethods) {
  let modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'external-datasources'
  };

  const crawlerConfigurationItemView = {
    entity: 'external-datasources',
    method: 'read',
    steps: [
      {
        entity: 'tango-agent-connection-configs',
        valueParams: ['_id'],
        queryParams: ['externalDatasource'],
        method: 'read',
        methodType: 'add',
        newParamNames: ['TangoAgentConnectionConfig']
      }
    ]
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readItemView: CrawlerMethods.crawler(crawlerConfigurationItemView)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default ExternalDatasourceModel;
