module.exports = {
  entity: 'triggered-alarms',
  path: 'triggered-alarms',
  name: {
    en: 'Alarm in progres',
    sl_SI: 'Alarm v teku'
  },
  networkModel: {
    entity: 'triggered-alarms',
    read: 'custom.readDialog'
  },
  displayFields: [
    {
      field1: 'alarm',
      field2: 'name',
      type: 'string'
    },
    {
      field1: 'timeSeries',
      field2: 'name',
      type: 'string',
      title: {
        en: 'Time Series',
        sl_SI: 'Časovna serija'
      }
    },
    {
      field1: 'currentAssignee',
      field2: 'name',
      type: 'string',
      title: {
        en: 'Current Assignee',
        sl_SI: 'Trenutno dodeljena oseba'
      }
    },
    {
      field1: 'alarmStatus',
      field2: 'name',
      type: 'string',
      title: {
        en: 'Alarm status',
        sl_SI: 'Status alarma'
      }
    },
    {
      field1: 'externalCode',
      type: 'string',
      title: {
        en: 'External code',
        sl_SI: 'Zunanja šifra'
      }
    },
    {
      field1: 'triggeredTimestamp',
      type: 'date',
      title: {
        en: 'Triggered at',
        sl_SI: 'Sproženo ob'
      }
    }
  ],
  scope: 'tis_be:alarms-in-progress',
  dialog: {
    title: {
      en: 'Select alarm in progress',
      sl_SI: 'Izberite alarm v teku'
    },
    listInfo: {
      en: 'Select alarm in progress from the list below',
      sl_SI: 'Izberite alarm v teku s spodnjega seznama'
    },
    enrichArray: [
      {
        from: 'alarms',
        localField: '_id',
        foreignField: 'alarm',
        as: 'alarmObject'
      }
    ],
    valueFields: '_id'
  }
}
