module.exports = {
  entity: 'location-statuses',
  path: 'location-statuses',
  name: {
    en: 'Location statuses',
    sl_SI: 'Statusi lokacij'
  },
  networkModel: {
    entity: 'location-statuses',
    read: 'read'
  },
  selectedParamName: 'id',
  scope: 'tis_be:location-statuses',

  dialog: {
    title: {
      en: 'Select location status',
      sl_SI: 'Izberite status lokacije'
    },
    listInfo: {
      en: 'Select location status from the list below',
      sl_SI: 'Izberite status lokacije s spodnjega seznama'
    }
  }
}
