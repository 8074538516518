import { DateTime } from 'luxon';
NewPriceListDetailController.$inject = [
  '$state',
  'gettext',
  'priceListDetail',
  'AlertingService',
  'createOrUpdateService',
  'SfeHeader',
  '$timeout',
  'MetadataService'
];

function NewPriceListDetailController(
  $state,
  gettext,
  priceListDetail,
  AlertingService,
  createOrUpdateService,
  SfeHeader,
  $timeout,
  MetadataService
) {
  var vm = this;
  var priceListId = $state.params.priceListId;
  var priceListDetailId = $state.params.priceListDetailId;
  var editMode = priceListDetailId !== undefined;

  init();

  function init() {
    vm.dataConfig = {
      redirect: {
        paramObject: $state.params,
        location: 'invoices-price-lists-detail-view'
      },
      edit: editMode,
      action: {
        ctrlFn: true,
        title: editMode ? gettext('Update') : gettext('Create'),
        fn: createOrUpdate
      },
      data: [
        {
          componentType: 'datePickerFromTo',
          from: 'validFrom',
          to: 'validTo',
          labelFrom: gettext('Valid from *'),
          labelTo: gettext('Valid to'),
          required: true,
          onlyStartRequired: true,
          idFrom: 'from',
          idTo: 'to'
        }
      ]
    };

    if (editMode) {
      vm.sendingRequest = true;
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit Price List'),
        'invoices-price-lists-detail-view',
        {
          priceListId: priceListId,
          priceListDetailId: priceListDetailId
        }
      );
      getPriceListDetail();
      if (priceListDetail != null) {
        $timeout(() => {
          const formatString = 'dd.MM.yyyy';
          const validFrom = DateTime.fromJSDate(
            new Date(priceListDetail.validFrom)
          ).toFormat(formatString);
          let validTo;
          if (priceListDetail.validTo != null) {
            validTo = DateTime.fromJSDate(
              new Date(priceListDetail.validTo)
            ).toFormat(formatString);
          }
          MetadataService.Loading(false);
          MetadataService.ChangeMetadata(
            `Edit price list detail  from ${validFrom} to ${validTo || '- -'}`
          );
        });
      }
    } else {
      var date = new Date();
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New Price List'),
        'invoices-price-lists-view',
        {
          priceListId: priceListId
        }
      );
      date.setHours(12);
      date.setMinutes(0);
      vm.dataConfig.dataObj = {
        validFrom: date,
        validTo: null
      };
      vm.dataConfig.header.button = {
        title: gettext('Cancel'),
        param: 'priceListId',
        paramValue: priceListId,
        location: 'invoices-price-lists-view'
      };
    }
  }

  function getPriceListDetail() {
    var dataObj = {};
    dataObj.minValue = priceListDetail.minValue;
    dataObj.maxValue = priceListDetail.maxValue;
    dataObj.validFrom = new Date(priceListDetail.validFrom);
    if (priceListDetail.validTo) {
      dataObj.validTo = new Date(priceListDetail.validTo);
    } else {
      dataObj.validTo = null;
    }
    vm.dataConfig.dataObj = dataObj;

    vm.sendingRequest = false;
  }

  // create and update functions //
  function createOrUpdate() {
    vm.sendingRequest = true;
    var redirectionData = {
      state: 'invoices-price-lists-detail-view',
      params: {
        priceListId: priceListId,
        priceListDetailId: priceListDetailId
      }
    };
    createOrUpdateService
      .simpleCall(
        {
          entity: 'price-list-details',
          method: editMode ? 'update' : 'create'
        },
        editMode,
        priceListDetailId,
        dataToSave(),
        null,
        null,
        redirectionData
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function dataToSave() {
    var dataObj = vm.dataConfig.dataObj;
    return {
      priceListMaster: priceListId,
      minValue: dataObj.minValue,
      maxValue: dataObj.maxValue,
      validFrom: dataObj.validFrom.setHours(0, 0, 0, 0),
      validTo: dataObj.validTo
        ? dataObj.validTo.setHours(23, 59, 59, 999)
        : dataObj.validTo
    };
  }
}

export default NewPriceListDetailController;
