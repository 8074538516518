import './dashboard.scss';
import './dashboard-type.dialog.scss';
import template from './sfe-dashboard.component.html';
import DashboardTypeDialog from './dashboard-type.dialog.html';

export default {
  template,
  bindings: {
    dashboard: '<'
  },
  controller: sfeDashboardController,
  controllerAs: 'vm'
};

sfeDashboardController.$inject = [
  '$mdDialog',
  'CrawlerMethods',
  'AlertingService',
  'TranslationService',
  'ToastService',
  'gettext',
  '$scope',
  'Refreshing',
  '$state',
  'loadDashboardModules',
  'StorageService',
  'DashboardCardService',
  'gettextCatalog'
];

function sfeDashboardController(
  $mdDialog,
  CrawlerMethods,
  AlertingService,
  TranslationService,
  ToastService,
  gettext,
  $scope,
  Refreshing,
  $state,
  loadDashboardModules,
  StorageService,
  DashboardCardService,
  gettextCatalog
) {
  let refresherId;
  const vm = this;
  const DASHBOARD_CARDS_LIMIT = 18;
  vm.DASHBOARD_CARDS_LIMIT = DASHBOARD_CARDS_LIMIT;
  vm.locked = true;
  vm.loadingDashboard = true;
  vm.selectedDashboard = null;
  vm.dashboardItems = [];
  vm.numberOfCards = 0;
  vm.addElementText;
  vm.functions = {
    removeElement: true,
    saveDashboard,
    addToRefreshing,
    removeDashboardCard,
    EditCard
  };
  vm.openTypePicker = openTypePicker;
  vm.toggleLock = toggleLock;

  vm.$onInit = function() {
    vm.dashboardsAutocomplete = getDashboardsAutocompleteConfig();
  };

  vm.$onChanges = changes => {
    if (changes.dashboard && vm.dashboard != null) {
      setDashboard();
    }
  };

  $scope.$on('$destroy', function() {
    if (refresherId) {
      Refreshing.removeRefresher(refresherId);
    }
  });

  function addToRefreshing(fn) {
    if (vm.dashboard.refreshInterval) {
      return Refreshing.addFn(refresherId, fn);
    } else {
      return false;
    }
  }

  /**
   * @description Sets the data of the given dashboard (based on the params dashboard) and prepares its cards.
   * @function
   */
  function setDashboard() {
    if (vm.dashboard.refreshInterval) {
      const interval = TranslationService.GetCollection(
        'codelists.refreshIntervals'
      ).find(
        codelistInterval => codelistInterval.id === vm.dashboard.refreshInterval
      );
      vm.cacheInvalidationTime = (interval.interval - 10) * 1000; // substract a bit to invalidate before new slide (dashboard) or refreshing is called to prevent racing conditions
      refresherId = Refreshing.newRefresher(interval.interval);
    }
    if (vm.dashboard.dashboardCards && vm.dashboard.dashboardCards.length) {
      vm.elements = vm.dashboard.dashboardCards
        .sort((a, b) => a.order - b.order)
        .map(element => {
          element.id = element.entity;
          return element;
        });

      vm.loadingDashboard = false;
      $scope.$evalAsync();
      loadModules();
      vm.numberOfCards = vm.dashboard.dashboardCards.length;
    } else {
      vm.loadingDashboard = false;
      vm.elements = [];
    }
    vm.addElementText =
      vm.numberOfCards >= DASHBOARD_CARDS_LIMIT
        ? gettextCatalog.getString('Limit reached')
        : gettextCatalog.getString('Add element');
    vm.loadingDashboard = false;
  }

  /**
   * @description Loads mxClient/highcharts.
   * @function
   */
  function loadModules() {
    loadDashboardModules(vm.elements).then(
      function(res) {
        vm[res] = true;
      },
      function(err) {
        AlertingService.Error('Couldn\'t load module' + err);
      },
      function(res) {
        vm[res] = true;
      }
    );
  }

  /**
   * @description description.
   * @function
   * @param {object} entity - data about the element/card we are adding to the component
   */
  async function AddNewCard(entity) {
    try {
      const res = await DashboardCardService.openDialog(
        false,
        entity,
        vm.elements.length
      );

      res.order = vm.elements.length;
      vm.elements.push(res);
      if (entity.id != 17) {
        await saveDashboard(vm.elements, vm.elements.length - 1);
      }
      vm.functions.newElementAdded(res);
      const successMessage = gettextCatalog.getString(
        'Dashboard card was successfully added to the dashboard.'
      );
      vm.numberOfCards += 1;
      vm.addElementText =
        vm.numberOfCards >= DASHBOARD_CARDS_LIMIT
          ? gettextCatalog.getString('Limit reached')
          : gettextCatalog.getString('Add element');
      ToastService.showToast(successMessage);
    } catch (err) {
      if (!err || err.status !== 'closedDialog') {
        vm.elements.pop();
        AlertingService.Error(err);
      }
    }
  }

  /**
   * @description function that is called when we are editing a card on the sfe-grid.
   * @function
   * @param {object} element - data about the element we are editing.
   */
  async function EditCard(element) {
    try {
      const res = await DashboardCardService.openDialog(true, element);

      element.reload = true;
      res.entity = res.id;
      if (res.dashboardCardType !== 17) {
        const obj = {
          queryObj: {
            id: element._id
          },
          bodyObj: res
        };
        if (element.entities && element.entities[0]) {
          obj.entityQueryObj = {
            dashboardCardId: element._id,
            entityId: element.entities[0]._id
          };
          obj.entityBodyObj = res.entities ? res.entities[0] : {};
        }
        try {
          const res = await updateDashboardCard(obj);

          ToastService.showToast(gettext('Dashboard updated'));
          for (let attr in res) {
            element[attr] = res[attr];
          }
          element.linkedDashboard = res.linkedDashboard;
          if (element.entities && element.entities[0]) {
            for (let attr in res.entities) {
              element.entities[0][attr] = res.entities[attr];
            }
          }
          $scope.$evalAsync();
          vm.functions.repairLayout();
          vm.functions.reloadLayout();
          vm.functions.generateLinkProperty();
        } catch (err) {
          AlertingService.Error(err);
        } finally {
          element.reload = false;
        }
      } else {
        for (let attr in res) {
          element[attr] = res[attr];
        }
        element.reload = false;
        vm.functions.repairLayout();
        vm.functions.reloadLayout();
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }

  /**
   * @description function used for updating dashboard cards.
   * @function
   * @param {object} obj - data that we are using to updated a dashboard card.
   * @param {function} callback -callback function
   */
  async function updateDashboardCard({ queryObj, bodyObj }) {
    const method = CrawlerMethods.getMethod({
      entity: 'dashboard-cards',
      method: 'update'
    });
    const { data } = await method(queryObj, bodyObj);
    return data;
  }

  /**
   * @description Configuration for autocomplete that fetches available dashboards.
   * @function
   * @return {Object} Configuration for autocomplete.
   */
  function getDashboardsAutocompleteConfig() {
    return {
      query: {
        entity: 'dashboards',
        method: 'read'
      },
      floatingLabel: gettext('Select Dashboard'),
      searchParamName: 'filter',
      entity: 'dashboards',
      // if user selects a dashboard from the list, go to its state
      change: () => {
        if (vm.selectedDashboard && vm.selectedDashboard._id) {
          $state.go('dashboards-dashboards-view', {
            id: vm.selectedDashboard._id
          });
        }
      }
    };
  }

  /**
   * @description Triggers the opening of the dialog for selecting the type of card to add to the dashboard.
   * @function
   */
  function openTypePicker() {
    $mdDialog
      .show({
        controller: DashboardTypeDialogController,
        template: DashboardTypeDialog,
        parent: angular.element(document.body)
      })
      .catch(angular.noop);

    DashboardTypeDialogController.$inject = ['$scope'];

    function DashboardTypeDialogController($scope) {
      $scope.selectType = type => {
        AddNewCard(type);
        $mdDialog.cancel();
      };
      $scope.header = {
        title: gettext('Select dashboard card type'),
        isDialog: true,
        actions: [
          {
            icon: {
              name: 'close',
              type: 2
            },
            cancel: true
          }
        ]
      };
      $scope.dashboardCardTypes = TranslationService.GetCollection(
        'codelists.dashboardCardTypes'
      ).sort((item, item2) => item.order - item2.order);
    }
  }

  /**
   * @description Saves the dashboard data to the server.
   * @function
   * @param {Array} elements Current cards
   * @param {number} newElementIndex  Index of the newly added element
   */
  async function saveDashboard(elements, newElementIndex) {
    if (typeof newElementIndex !== 'undefined') {
      const res = await saveElementToServer(elements, newElementIndex);
      if (res) {
        elements[newElementIndex]._id = res._id;
        if (res.details && res.details.url) {
          elements[newElementIndex].details.url = res.details.url;
        }
        if (res.entities) {
          elements[newElementIndex].entities = res.entities;
        }
      }
    } else {
      try {
        saveDashboardOrder(elements);
      } catch (err) {
        AlertingService.Error(err);
      }
    }
  }

  /**
   * @description Updates the cards that have changed their index.
   * @function
   * @param {Array} elements Current cards of the dashboard
   */
  async function saveDashboardOrder(elements) {
    const dashboardCardsToUpdate = [];
    elements.forEach(function(el, index) {
      if (!el.oldIndex && el.oldIndex !== 0) el.oldIndex = index;
    });
    // concat with empty array so as to not work on original that is on scope
    elements
      .sort((a, b) => {
        // sort them by index (which can differ from array index)
        if (!a.order && a.order !== 0) return 1;
        else if (!b.order && b.order !== 0) return -1;
        else return a.order - b.order;
      })
      // filter those that updated
      .filter((updatedEl, index) => updatedEl.oldIndex !== index)
      // generate update promises
      .forEach(element => {
        element.oldIndex = element.order;
        dashboardCardsToUpdate.push(
          CrawlerMethods.getMethod({
            entity: 'dashboard-cards',
            method: 'update'
          })(
            {
              id: element._id
            },
            {
              order: element.order
            }
          )
        );
      });
    try {
      await Promise.all(dashboardCardsToUpdate);
      ToastService.showToast(gettext('Dashboard updated'));
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  /**
   * @description Saves the updated element and connects the entities.
   * @function
   * @param {Array} elements Current cards
   * @param {number} newElementIndex Needed to know what to update.
   */
  async function saveElementToServer(elements, newElementIndex) {
    const instance = elements[newElementIndex];

    const queryObj = {
      dashboard: vm.dashboard._id,
      name: instance.name || 'Unnamed',
      order: newElementIndex,
      linkedDashboard: instance.linkedDashboard,
      dashboardCardType: parseInt(instance.dashboardCardType),
      dashboardCardSize: parseInt(instance.dashboardCardSize),
      details: instance.details,
      entities: instance.entities
    };
    const method = CrawlerMethods.getMethod({
      entity: 'dashboard-cards',
      method: 'create'
    });
    //when dashboard card needs to load lazy load modules (charts etc)
    loadModules([queryObj]);
    const { data } = await method(queryObj);
    return data;
  }

  /**
   * @description removes dashboard cards marked for removal.
   * @function
   * @param {object} card - card being removed
   * @return {promise}
   */
  async function removeDashboardCard(card) {
    const method = CrawlerMethods.getMethod({
      entity: 'dashboard-cards',
      method: 'delete'
    });
    await method({
      id: card._id
    });
    vm.numberOfCards -= 1;
    vm.addElementText =
      vm.numberOfCards >= DASHBOARD_CARDS_LIMIT
        ? gettextCatalog.getString('Limit reached')
        : gettextCatalog.getString('Add element');
    return true;
  }

  /**
   * @description Locks/unlocks the ability to edit the dashboard.
   * @function
   */
  function toggleLock() {
    if (vm.functions.toggleLock) {
      vm.locked = !vm.locked;
      vm.functions.toggleLock();
    }
  }
}
