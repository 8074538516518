module.exports = {
  entity: 'measurement-units',
  path: 'measurement-units',
  name: {
    en: 'Measurement units',
    sl_SI: 'Merske enote'
  },
  networkModel: {
    entity: 'measurement-units',
    read: 'read'
  },
  selectedParamName: 'id',
  scope: 'tis_be:measurement-units',

  dialog: {
    title: {
      en: 'Select measurement unit',
      sl_SI: 'Izberite mersko enoto'
    },
    listInfo: {
      en: 'Select measurement unit from the list below',
      sl_SI: 'Izberite mersko enoto s spodnjega seznama'
    }
  }
}
