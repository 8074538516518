SchedulerTangoProperties.$inject = [
  'ColorService',
  'ValidateObjectConfiguration',
  'TranslationService'
];

export default function SchedulerTangoProperties(
  ColorService,
  ValidateObjectConfiguration,
  TranslationService
) {
  const simpleObjectConfiguration = [
    {
      fieldName: '_id',
      type: 'string',
      required: true
    },
    {
      fieldName: 'name',
      type: 'string',
      required: true
    }
  ];
  return {
    /**
     * @description returns sfe-property configuration to display large scheduler property.
     * @function
     * @param {Object}
     *   @param {object} item object that contains scheduler data under key key
     *   @param {string} key key that where scheduler data is stored
     * @return {Object}
     */
    large: ({ item, key }) => {
      const validateScheduler = ValidateObjectConfiguration.validate(
        item[key],
        simpleObjectConfiguration
      );
      if (validateScheduler.validity) {
        const scheduleClassification = TranslationService.GetCollectionById(
          'codelists.scheduleClassifications',
          item[key].scheduleClassification
        );
        let scheduleClassificationSubtext = '';
        if (scheduleClassification != null) {
          scheduleClassificationSubtext = scheduleClassification.name;
        }
        return {
          type: 'large',
          values: [
            {
              text: item[key].name,
              link: `data-schedules-view({id:"${item[key]._id}"})`,
              icon: {
                type: 2,
                name: 'query_builder',
                color: ColorService.getApplicationColor('success')
              },
              subtext: scheduleClassificationSubtext
            }
          ]
        };
      }
    }
  };
}
