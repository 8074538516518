import actionTypes from '../action/external-writer-flow.type';

function externalWriterFlowReducer(state = {}, action) {
  switch (action.type) {
  case actionTypes.EXTERNAL_WRITER_FLOW_READ: {
    return {
      ...state,
      [action.payload.id]: action.payload.result
    };
  }
  case actionTypes.EXTERNAL_WRITER_FLOW_UPDATE: {
    return {
      ...state,
      [action.payload.query.id]: action.payload.result
    };
  }
  case actionTypes.EXTERNAL_WRITER_FLOW_CREATE: {
    return {
      ...state,
      [action.payload.result.data._id]: createExternalWriterFlowStore(
        action.payload
      )
    };
  }
  case actionTypes.EXTERNAL_WRITER_FLOW_ADD: {
    return {
      ...state,
      [action.payload.result.data._id]: createExternalWriterFlowStore(
        action.payload
      )
    };
  }
  default:
    return state;
  }
}

/**
 * @description create externalWriterFlow.
 * @function
 * @param {Object} payload payload object {query, result} must contain TS id in query object and result must contain updated TS under data key
 * @return {dataType}
 */
function createExternalWriterFlowStore(payload) {
  let createdExternalWriterFlow = payload.result.data;
  return {
    data: {
      ...createdExternalWriterFlow
    }
  };
}

export default externalWriterFlowReducer;
