TangoAgentConnectionConfigModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

/**
 * @ngdoc model
 * @name common.TangoAgentConnectionConfigModel
 * @description Model for Time Series.
 */
function TangoAgentConnectionConfigModel(ModelUtilities, CrawlerMethods) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'tango-agent-connection-configs'
  };

  const crawlerConfiguration = {
    entity: 'tango-agent-connection-configs',
    method: 'read',
    populate: 'externalDatasource,connector'
  };
  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readItemView: CrawlerMethods.crawler(crawlerConfiguration),
      readForm: CrawlerMethods.crawler(crawlerConfiguration)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default TangoAgentConnectionConfigModel;
