severityConfiguration.$inject = [
  'gettext',
  '$q',
  'SfeFormDialogService',
  'AlertingService',
  'MultiLanguage',
  'CrudToastFactory',
  'SeverityModel',
  'ListFormService'
];

function severityConfiguration(
  gettext,
  $q,
  SfeFormDialogService,
  AlertingService,
  MultiLanguage,
  CrudToastFactory,
  SeverityModel,
  ListFormService
) {
  const fieldNames = ['name', 'description', 'isSystem', 'importance', 'color'];
  function openCreateDialog(relistData) {
    var config = getForm();
    var title = gettext('New Severity');
    SfeFormDialogService.openSfeFormDialog(false, config, {}, title).then(
      function(object) {
        if (object) {
          create(object, config, relistData);
        }
      }
    );
  }

  function create(obj, config, relistData) {
    var data = MultiLanguage.constructForm(config, obj);
    SeverityModel.create(
      ListFormService.generateBodyObject(data, fieldNames)
    ).then(
      function() {
        CrudToastFactory.toast('create');
        relistData();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function getForm(edit) {
    return [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'multilanguage',
        required: true
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        maxlength: 500,
        required: false,
        componentType: 'multilanguage'
      },
      {
        placeholder: gettext('Priority'),
        name: 'importance',
        componentType: 'textField',
        type: 'number',
        min: 0
      },
      {
        componentType: 'checkBox',
        name: 'isSystem',
        label: gettext('System')
      },
      {
        componentType: 'colorPicker',
        name: 'color',
        label: gettext('Color'),
        edit
      }
    ];
  }

  function update(id, obj, config, relistData) {
    var data = MultiLanguage.constructForm(config, obj);

    SeverityModel.update(
      {
        id: id
      },
      ListFormService.generateBodyObject(data, fieldNames)
    ).then(
      function() {
        CrudToastFactory.toast('update');
        relistData();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function openUpdateDialog(item, relistData) {
    SeverityModel.custom
      .readAllLang({
        id: item._id
      })
      .then(
        function(res) {
          var config = getForm(true);
          var title = gettext('Edit severity');
          SfeFormDialogService.openSfeFormDialog(
            true,
            config,
            res.data,
            title
          ).then(function(object) {
            if (object) {
              update(item._id, object, config, relistData);
            }
          });
        },
        function(err) {
          AlertingService.Error(err);
        }
      );
  }

  var configuration = {
    entity: {
      singular: gettext('Severity'),
      plural: gettext('Severities')
    },
    api: {
      query: {
        entity: 'severities',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name'
    },
    buttons: [
      {
        title: gettext('Create'),
        fn: openCreateDialog,
        relist: true,
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        action: openUpdateDialog
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'severities',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description')
      },
      {
        param: 'type',
        title: gettext('Type')
      },
      {
        param: 'importance',
        title: gettext('Priority')
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    item.type = 'Application';
    if (item.isSystem) {
      item.type = 'System';
    }
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default severityConfiguration;
