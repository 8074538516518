module.exports = {
  entity: 'alarm-types',
  path: 'alarm-types',
  selectedParamName: '_id',
  entityId: 30,
  name: {
    en: 'Alarm in progres',
    sl_SI: 'Tip alarma'
  },
  networkModel: {
    entity: 'alarm-types',
    read: 'read'
  },
  scope: 'tis_be:alarm-types',

  dialog: {
    title: {
      en: 'Select alarm type',
      sl_SI: 'Izberite tip alarma'
    },
    listInfo: {
      en: 'Select alarm type from the list below',
      sl_SI: 'Izberite tip alarma s spodnjega seznama'
    }
  }
}
