locationClassificationConfiguration.$inject = [
  'gettext',
  '$q',
  'SfeFormDialogService',
  'CrudToastFactory',
  'AlertingService',
  'LocationClassificationModel'
];

function locationClassificationConfiguration(
  gettext,
  $q,
  SfeFormDialogService,
  CrudToastFactory,
  AlertingService,
  LocationClassificationModel
) {
  var configuration = {
    entity: {
      singular: gettext('Location Classification'),
      plural: gettext('Location Classifications')
    },
    api: {
      query: {
        entity: 'location-classifications',
        method: 'custom.list'
      },
      enrich: Enrich
    },
    title: {
      param: 'name'
    },
    buttons: [
      {
        title: gettext('Create'),
        fn: openCreateDialog,
        relist: true,
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        action: openUpdateDialog
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'location-classifications',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description')
      },
      {
        param: 'code',
        title: gettext('Code'),
        type: 'code'
      },
      {
        param: 'parentReadable',
        title: gettext('Parent')
      }
    ],
    sort: [
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'name DESC',
        params: '-name'
      },
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Code'),
        param: 'code',
        type: 'string'
      }
    ]
  };

  function getLocationClassificationFormConfiguration() {
    return [
      {
        placeholder: 'Name',
        name: 'name',
        componentType: 'textField',
        type: 'text',
        required: true
      },
      {
        placeholder: 'Description',
        name: 'description',
        componentType: 'textArea',
        type: 'text',
        maxlength: 500,
        required: false
      },
      {
        placeholder: gettext('Code'),
        name: 'code',
        componentType: 'textField',
        type: 'text',
        required: true
      },
      {
        componentType: 'autocompleteDialog',
        edit: true,
        name: 'parentId',
        configuration: {
          query: {
            entity: 'location-classifications',
            method: 'read'
          },
          floatingLabel: gettext('Select location classification'),
          searchParamName: 'filter',
          required: false,
          entity: 'location-classifications',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select location classification'),
            filterObject: {
              order: 'name'
            }
          }
        }
      }
    ];
  }

  function openCreateDialog(relistData) {
    var config = getLocationClassificationFormConfiguration();
    config.forEach(function(configEntry) {
      configEntry.edit = false;
    });
    var title = gettext('New Location Classification');
    var createObject = {};
    SfeFormDialogService.openSfeFormDialog(
      false,
      config,
      createObject,
      title
    ).then(function(object) {
      if (object) {
        create(object, relistData);
      }
    });
  }

  function openUpdateDialog(item, relistData) {
    var config = getLocationClassificationFormConfiguration();
    var title = gettext('Edit Location Classification');
    var updateObject = {
      parentId: item.parentId,
      name: item.name,
      description: item.description,
      code: item.code
    };
    SfeFormDialogService.openSfeFormDialog(
      true,
      config,
      updateObject,
      title
    ).then(function(object) {
      if (object) {
        update(object, item, relistData);
      }
    });
  }

  function create(item, relistData) {
    var apiObj = {
      parentId: item.parentId ? item.parentId._id : null,
      name: item.name,
      description: item.description,
      code: item.code
    };

    LocationClassificationModel.create(apiObj).then(
      function() {
        CrudToastFactory.toast('create');
        relistData();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function update(item, originalItem, relistData) {
    var apiObj = {
      parentId: item.parentId ? item.parentId._id : null,
      name: item.name,
      description: item.description,
      code: item.code
    };
    LocationClassificationModel.update(
      {
        id: originalItem._id
      },
      apiObj
    ).then(
      function() {
        relistData();

        CrudToastFactory.toast('update');
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function Enrich(item) {
    var deferred = $q.defer();
    if (item.parentId) {
      item.parentReadable = item.parentId.name;
    }
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default locationClassificationConfiguration;
