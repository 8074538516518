BusinessPartnerController.$inject = [
  'gettext',
  'gettextCatalog',
  'TranslationService',
  'MetadataService',
  'HierarchyTreeDialog',
  'businessPartner',
  '$timeout'
];

function BusinessPartnerController(
  gettext,
  gettextCatalog,
  TranslationService,
  MetadataService,
  HierarchyTreeDialog,
  businessPartner,
  $timeout
) {
  const vm = this;
  vm.treeElementSelected = treeElementSelected;

  vm.$onInit = function init() {
    vm.loading = true;
    MetadataService.Loading(true);

    var type = TranslationService.GetCollectionById(
      'codelists.businessPartnerTypes',
      businessPartner.type
    );

    if (businessPartner.parentId) {
      businessPartner.parent = businessPartner.parentId;
      businessPartner.parentId = businessPartner.parent._id;
    }

    businessPartner.type = type ? type.name : gettext('Unknown');
    if (businessPartner.address) {
      businessPartner.address.country = TranslationService.GetCollectionById(
        'codelists.countries',
        businessPartner.address.country
      );
    }
    vm.headerData = constructHeader(businessPartner);

    vm.address = {};
    if (businessPartner.address) {
      vm.address = Object.assign({}, businessPartner.address, {
        hasAddress: true
      });
    } else {
      vm.address = Object.assign({}, { hasAddress: false });
    }
    if (businessPartner.geoLocation) {
      var tempAddressObj = vm.address;
      vm.address = Object.assign({}, tempAddressObj, {
        geoLocation: businessPartner.geoLocation
      });
    }
    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(
        businessPartner.name,
        businessPartner.description
      );
      vm.loading = false;
    });
  };

  function treeElementSelected(element) {
    element.properties = [];
    element.state = 'company-resources-business-partners-view';
    element.redirectParams = {
      id: element._id
    };

    if (element.description) {
      element.properties.push({
        title: gettext('Description'),
        value: element.description
      });
    }
    element.properties.push(
      {
        title: gettext('Code'),
        value: element.code,
        code: true
      },
      {
        title: gettext('External'),
        value: element.isExternal
          ? gettextCatalog.getString('Yes')
          : gettextCatalog.getString('No'),
        color: element.isExternal ? 'green' : 'red'
      }
    );
  }

  function constructHeader(businessPartner) {
    var propertiesLeft = [
      {
        title: gettext('Code'),
        value: businessPartner.code,
        type: 'code'
      },
      {
        title: gettext('External code'),
        value: businessPartner.externalCode || '/',
        type: 'code'
      },
      {
        title: gettext('Type'),
        value: businessPartner.type,
        type: 'translate'
      },
      {
        title: gettext('Distributor/Supplier'),
        value: businessPartner.isDistributer,
        type: 'radio',
        customOptions: ['YES', 'NO']
      }
    ];

    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];

    if (businessPartner.parent) {
      propertiesLeft.push({
        title: gettext('Parent'),
        linkTitle:
          businessPartner.parent.name +
          ' (' +
          businessPartner.parent.code +
          ')',
        state: 'company-resources-business-partners-view',
        param: 'id',
        paramValue: businessPartner.parent._id,
        type: 'link'
      });
    }

    var propertiesRight = [
      {
        title: gettext('Tax number'),
        value: businessPartner.taxNumber || '/',
        type: 'code'
      },
      {
        title: gettext('Unique number'),
        value: businessPartner.uniqueNumber || '/',
        type: 'code'
      },
      {
        title: gettext('External'),
        type: 'radio',
        value: businessPartner.isExternal,
        customOptions: ['Yes', 'No']
      }
    ];
    var propertySections = [
      {
        // title: gettext('Properties'),
        properties: propertiesLeft
      },
      {
        // title: gettext('Properties'),
        properties: propertiesRight
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'business-partners',
        target: businessPartner._id,
        type: 'userAccess'
      }
    ];
    var actions = [
      {
        title: gettext('Show Hierarchy'),
        fn: function() {
          HierarchyTreeDialog.openDialog(businessPartner, 'business-partners');
        }
      },
      {
        title: gettext('delete'),
        delete: true,
        id: businessPartner._id,
        endpoint: {
          entity: 'business-partners',
          method: 'delete'
        },
        redirectState: 'company-resources-business-partners-list'
      },
      {
        title: gettext('duplicate'),
        state: 'company-resources-business-partners-duplicate',
        param: 'id',
        paramValue: businessPartner._id
      },
      {
        title: gettext('Update'),
        state: 'company-resources-business-partners-edit',
        param: 'id',
        paramValue: businessPartner._id
      }
    ];

    return {
      metadata: {
        definition: gettext('Business partner'),
        title: businessPartner.name,
        description: businessPartner.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}
export default BusinessPartnerController;
