import actionTypes from '../action/maintenance-flow.type';

function maintenanceFlowReducer(state = {}, action) {
  switch (action.type) {
  case actionTypes.MAINTENANCE_FLOW_READ: {
    return {
      ...state,
      [action.payload.id]: action.payload.result
    };
  }
  case actionTypes.MAINTENANCE_FLOW_UPDATE: {
    return {
      ...state,
      [action.payload.query.id]: updateMaintenanceFlowStore(
        action.payload,
        state
      )
    };
  }
  case actionTypes.MAINTENANCE_FLOW_CREATE: {
    return {
      ...state,
      [action.payload.result.data._id]: createMaintenanceFlowStore(
        action.payload
      )
    };
  }
  case actionTypes.MAINTENANCE_FLOW_ADD: {
    return {
      ...state,
      [action.payload.result.data._id]: createMaintenanceFlowStore(
        action.payload
      )
    };
  }
  default:
    return state;
  }
}

/**
 * @description merges current maintenanceFLow with maintenanceFLow that update query returns.
 * @function
 * @param {Object} payload payload object {query, result} must contain MF id in query object and result must contain updated MF under data key
 * @return {dataType}
 */
function updateMaintenanceFlowStore(payload, state) {
  let id = payload.query.id;
  let maintenanceFlow = state[id].data;
  let updatedMaintenanceFlow = payload.result.data;
  return {
    data: {
      ...maintenanceFlow,
      ...updatedMaintenanceFlow
    }
  };
}

/**
 * @description create maintenanceFLow.
 * @function
 * @param {Object} payload payload object {query, result} must contain TS id in query object and result must contain updated TS under data key
 * @return {dataType}
 */
function createMaintenanceFlowStore(payload) {
  let createdMaintenanceFlow = payload.result.data;
  return {
    data: {
      ...createdMaintenanceFlow
    }
  };
}

export default maintenanceFlowReducer;
