import './sfe-gis-map.scss';
import template from './sfe-gis-map.component.html';

/**
 * @ngdoc component
 * @name common.gisMap
 * @description Displays gis map.
 * @param {Object} gisData - Data for the map
 * @param {Number} scale - scale value
 * @example
 * <gis-map
 * gis-data="vm.gisData"
 * scale="vm.scale"
 * ></gis-map>
 */

export default {
  restrict: 'E',
  template,
  bindings: {
    gisData: '<',
    scale: '<',
    onSelect: '<',
    actions: '<'
  },
  controller: sfeGisMapController,
  controllerAs: 'vm',
  bindToController: true
};

sfeGisMapController.$inject = [
  'utilService',
  '$smartAuth',
  'AlertingService',
  'gettext',
  'gettextCatalog',
  'GisMapModel',
  'loadAssets',
  'SfeFormDialogService',
  'StorageService'
];

function sfeGisMapController(
  utilService,
  $smartAuth,
  AlertingService,
  gettext,
  gettextCatalog,
  GisMapModel,
  loadAssets,
  SfeFormDialogService,
  StorageService
) {
  const vm = this;
  let petrol;
  vm.randomHash = Math.random()
    .toString(36)
    .substring(2);

  let userToken, mapName, selectorType;
  const identifier = 'select';

  //BUTTONS
  const startSelectingButton = {
    title: gettext('Start'),
    fn: startSelecting,
    color: 'primary',
    disabledFn: function() {
      return !vm.gisData.map;
    },
    identifier
  };

  const stopSelectingButton = {
    title: gettext('Stop'),
    fn: startSelecting,
    color: 'primary',
    disabledFn: function() {
      return !vm.gisData.map;
    },
    identifier
  };

  const selectorOptions = [
    {
      _id: 'point',
      name: gettextCatalog.getString('point')
    },
    {
      _id: 'circle',
      name: gettextCatalog.getString('circle')
    },
    {
      _id: 'polygon',
      name: gettextCatalog.getString('polygon')
    },
    {
      _id: 'freehand',
      name: gettextCatalog.getString('freehand')
    }
  ];

  const selectorTypeConfig = {
    label: gettext('Selector type'),
    ctrlFn: async () => selectorOptions,
    empty: gettext('There are no items to select.'),
    edit: true
  };
  /**
   * @description Fires initialization of map on changes.
   * @function
   * @param {Object} changes Changes being made
   */
  vm.$onChanges = async changes => {
    if (changes.gisData && vm.gisData != null) {
      if (changes.gisData.isFirstChange()) {
        try {
          await loadAssets(['gisSdk']);
          initMap();
        } catch (err) {
          AlertingService.Error(
            gettextCatalog.getString('Couldn\'t init GIS sdk')
          );
        }
      } else {
        setMap();
      }
    }

    //ACTIONS
    if (changes.actions && vm.actions != null) {
      vm.gisActions = vm.actions;
    }

    ///ON SELECT
    if (changes.onSelect && vm.onSelect != null) {
      vm.showBufferData = true;
      if (!Array.isArray(vm.gisActions)) {
        vm.gisActions = [];
      }
      initSelectorView();

      const settings = {
        icon: {
          type: 2,
          name: 'settings'
        },
        fn: openPairingSettings,
        color: 'primary',
        label: gettext('Edit settings')
      };

      vm.gisActions = [...vm.gisActions, settings, startSelectingButton];
    }
  };

  /**
   * @description Initializes the map.
   * @function
   */
  async function initMap() {
    try {
      const { data: map } = await GisMapModel.read({
        id: vm.gisData.map
      });
      const user = await $smartAuth.profile();
      if (user.gisUserToken) {
        userToken = user.gisUserToken;
        mapName = map.name;
        setMap();
      } else {
        AlertingService.Error(gettext('No gis token found'));
      }
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  /**
   * @description initializes GIS map.
   * @function
   */
  function setMap() {
    const url = utilService.getHostNoPrefix('gis') + '/maps/' + mapName;
    let gisData;
    if (vm.gisData) {
      gisData = angular.copy(vm.gisData);
      delete gisData.center;

      if (vm.gisData.center) {
        gisData.center = [
          vm.gisData.center.easting,
          vm.gisData.center.northing
        ];
      }
    }

    const mapConfiguration = {
      url: url,
      template: 'link-features',
      scale: vm.scale || 1000,
      token: userToken,
      height: '100%',
      width: '100%',
      markCenter: true,
      ...gisData
    };

    if (vm.gisData.layerId) {
      mapConfiguration.layerID = vm.gisData.layerId;
    }

    if (vm.gisData.featureId) {
      mapConfiguration.featureID = vm.gisData.featureId;
    }
    if (vm.gisData.map) {
      mapConfiguration.map = vm.gisData.map;
    }
    petrol = new gms.Map(vm.randomHash, mapConfiguration);
  }
  /**
   * @description inits gis buffer size and type of selector.
   * @function
   */
  function initSelectorView() {
    let settings = StorageService.get('gisMapSettings');
    if (typeof settings === 'undefined') {
      settings = {
        selectorType: 'point',
        buffer: 20
      };
      StorageService.save('gisMapSettings', settings);
    }

    selectorType = {
      _id: settings.selectorType
    };
    vm.buffer = settings.buffer;
    vm.displaySelectorType = getDisplaySelector(selectorType._id);
  }

  /**
   * @description Opens pairing settings.
   * @function
   */
  function openPairingSettings() {
    const config = [
      {
        componentType: 'multiSelect',
        name: 'selectorType',
        config: selectorTypeConfig
      },
      {
        componentType: 'textField',
        type: 'number',
        name: 'buffer',
        placeholder: gettext('Buffer'),
        max: 50,
        min: 1
      }
    ];
    const settingsObject = {
      selectorType: selectorType,
      buffer: vm.buffer
    };
    SfeFormDialogService.openSfeFormDialog(
      true,
      config,
      settingsObject,
      gettext('Pairing settings')
    ).then(
      function(res) {
        if (res) {
          StorageService.save('gisMapSettings', {
            selectorType: res.selectorType._id,
            buffer: res.buffer
          });
          vm.buffer = res.buffer;
          selectorType._id = res.selectorType._id;
          vm.displaySelectorType = getDisplaySelector(res.selectorType._id);
        }
      },
      function() {}
    );
  }
  /**
   * @description Sets the display selector.
   * @function
   * @param {string} typeId Id of the type
   * @return {Object} Display selector
   */
  function getDisplaySelector(typeId) {
    return selectorOptions.find(function(item) {
      return item._id === typeId;
    });
  }

  /**
   * @description Starts selecting.
   * @function
   */
  function startSelecting() {
    vm.selectingInProgress = !vm.selectingInProgress;
    vm.gisActions = vm.gisActions.map(action => {
      if (action.identifier == identifier) {
        if (vm.selectingInProgress) {
          return stopSelectingButton;
        } else {
          return startSelectingButton;
        }
      }
      return action;
    });
    if (vm.selectingInProgress) {
      petrol.startLinkingFeatures({
        onFeatureLinked: vm.onSelect,
        buffer: vm.buffer,
        queryType: selectorType._id || 'point'
      });
    } else {
      petrol.stopLinkingFeatures();
    }
  }
}
