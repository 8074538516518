documentClassificationsConfiguration.$inject = [
  'gettext',
  '$q',
  'SfeFormDialogService',
  'ListFormService',
  'AlertingService',
  'CrudToastFactory',
  'DocumentClassificationModel'
];

function documentClassificationsConfiguration(
  gettext,
  $q,
  SfeFormDialogService,
  ListFormService,
  AlertingService,
  CrudToastFactory,
  DocumentClassificationModel
) {
  const fieldNames = ['name', 'description', 'parentId', 'code'];
  function openCreateDialog(relistData) {
    autocompleteDocumentClassification.edit = false;
    var config = ListFormService.generateForm(
      ['name', 'code', 'description'],
      false,
      [autocompleteDocumentClassification]
    );
    var title = gettext('New Document Classification');
    SfeFormDialogService.openSfeFormDialog(false, config, {}, title).then(
      function(object) {
        if (object) {
          create(object, relistData);
        }
      }
    );
  }

  function openUpdateDialog(item, relistData) {
    autocompleteDocumentClassification.edit = true;
    DocumentClassificationModel.read({
      id: item._id
    }).then(
      function(res) {
        res.data.documentClassification = res.data.parentId;
        var config = ListFormService.generateForm(
          ['name', 'code', 'description'],
          false,
          [autocompleteDocumentClassification]
        );
        var title = gettext('Edit Document Classification');
        SfeFormDialogService.openSfeFormDialog(
          true,
          config,
          res.data,
          title
        ).then(function(object) {
          if (object) {
            update(item._id, object, relistData);
          }
        });
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function create(obj, relistData) {
    if (obj.documentClassification) {
      obj.parentId = obj.documentClassification._id;
    }
    DocumentClassificationModel.create(
      ListFormService.generateBodyObject(obj, fieldNames)
    ).then(
      function() {
        CrudToastFactory.toast('create');
        relistData();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function update(id, obj, relistData) {
    obj.parentId = obj.documentClassification
      ? obj.documentClassification._id
      : null;
    DocumentClassificationModel.update(
      {
        id: id
      },
      ListFormService.generateBodyObject(obj, fieldNames)
    ).then(
      function() {
        CrudToastFactory.toast('update');
        relistData();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  var autocompleteDocumentClassification = {
    configuration: {
      query: {
        entity: 'document-classifications',
        method: 'read'
      },
      entity: 'document-classifications',
      dialogConfiguration: {
        multiple: false,
        title: gettext('Select Parent Document Classification')
      },
      floatingLabel: gettext('Select Parent Document Classification'),
      searchParamName: 'filter',
      required: false
    },
    componentType: 'autocompleteDialog',
    name: 'documentClassification'
  };

  var configuration = {
    entity: {
      singular: gettext('Document Classification'),
      plural: gettext('Document Classifications')
    },
    api: {
      query: {
        entity: 'document-classifications',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name'
    },
    buttons: [
      {
        title: gettext('Create'),
        fn: openCreateDialog,
        relist: true,
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        action: openUpdateDialog
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'document-classifications',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description')
      },
      {
        param: 'code',
        title: gettext('Code'),
        type: 'code'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Code'),
        type: 'string',
        param: 'code'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    if (item.parentId !== null) {
      DocumentClassificationModel.read({
        id: item.parentId
      }).then(
        function(res) {
          item.documentClassification = res.data.name;
        },
        function(err) {
          AlertingService.Error(err);
        }
      );
    }
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default documentClassificationsConfiguration;
