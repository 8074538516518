import './sop.scss';

SOPDetailsController.$inject = [
  '$state',
  '$stateParams',
  'AlertingService',
  'gettext',
  'MetadataService',
  'StepModel',
  'sop',
  '$timeout'
];

function SOPDetailsController(
  $state,
  $stateParams,
  AlertingService,
  gettext,
  MetadataService,
  StepModel,
  sop,
  $timeout
) {
  var vm = this;
  var sopDetailsId = $stateParams.id;

  init();

  async function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    if (!sopDetailsId) {
      AlertingService.Error('No SOP id provided.');
      $state.go('alarms-and-rules-sop-list');
    }

    vm.stepNum = 0;
    vm.disableNext = true;
    vm.disablePrev = true;
    try {
      if (vm.stepLen === 1) {
        vm.disableNext = true;
        vm.disablePrev = true;
      } else {
        vm.disableNext = false;
        vm.disablePrev = true;
      }
      vm.headerData = constructHeader(sop);
      vm.loading = false;
      $timeout(() => {
        MetadataService.ChangeMetadata(sop.name, sop.description);
      });
      vm.stepsLoading = true;
      vm.steps = await getSopStepsData(sop);
      vm.stepsLoading = false;
    } catch (err) {
      AlertingService.Error(err);
    }
    $timeout(() => {
      MetadataService.Loading(false);
    });
  }
  async function getSopStepsData(sop) {
    if (Array.isArray(sop.steps) && sop.steps.length > 0) {
      try {
        const { data } = await StepModel.read({
          _id: sop.steps,
          populate: 'tasks'
        });
        return data.sort((a, b) => {
          const aIndex = sop.steps.indexOf(a._id);
          const bIndex = sop.steps.indexOf(b._id);
          return aIndex - bIndex;
        });
      } catch (err) {
        AlertingService.Error(err);
      }
    }
    return [];
  }

  function constructHeader(sop) {
    var actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: sop._id,
        endpoint: {
          entity: 'sops',
          method: 'delete'
        },
        redirectState: 'alarms-and-rules-sop-list'
      },
      {
        title: gettext('duplicate'),
        state: 'alarms-and-rules-sop-duplicate',
        param: 'id',
        paramValue: sop._id
      },
      {
        title: gettext('Update'),
        state: 'alarms-and-rules-sop-edit',
        param: 'id',
        paramValue: sop._id
      }
    ];

    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];
    var propertySections = [
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'sops',
        target: sopDetailsId,
        type: 'userAccess'
      }
    ];
    return {
      metadata: {
        definition: gettext('standard operating procedure'),
        title: sop.name,
        description: sop.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}

export default SOPDetailsController;
