/**
 * @ngdoc service
 * @name common.LongQueryHelper
 * @description Helps splitting large queries into smaller ones
 */

LongQueryHelper.$inject = [];
export default function LongQueryHelper() {
  /**
   * @description splits query in to 2 when there are more than 150 items.
   * @function
   * @param {Array} filterItems filter ids
   * @param {function} networkFunction function to execute
   * @param {string} selectedParamName filter key
   * @param {object} additionalFilter
   * @return {Promise}
   */
  const execute = async (
    filterItems,
    networkFunction,
    selectedParamName = '_id',
    additionalFilter = {}
  ) => {
    if (filterItems.length > 150) {
      const half = Math.ceil(filterItems.length / 2);

      const firstHalf = filterItems.splice(0, half);
      const secondHalf = filterItems.splice(-half);
      try {
        const promises = [
          networkFunction({
            [selectedParamName]: firstHalf,
            limit: firstHalf.length,
            ...additionalFilter
          }),
          networkFunction({
            [selectedParamName]: secondHalf,
            limit: secondHalf.length,
            ...additionalFilter
          })
        ];
        const result = await Promise.all(promises);
        return [...result[0].data, ...result[1].data];
      } catch (err) {
        throw err;
      }
    } else {
      try {
        const res = await networkFunction({
          [selectedParamName]: filterItems,
          limit: filterItems.length,
          ...additionalFilter
        });
        return res.data;
      } catch (err) {
        throw err;
      }
    }
  };

  return { execute };
}
