TimeSeriesModel.$inject = [
  'ModelUtilities',
  'CrawlerMethods',
  'AlertingService',
  'EntityTagService'
];

/**
 * @ngdoc model
 * @name common.TimeSeriesModel
 * @description Model for Time Series.
 */
function TimeSeriesModel(
  ModelUtilities,
  CrawlerMethods,
  AlertingService,
  EntityTagService
) {
  const modelStorage = {};

  let networkConfiguration = {
    host: 'api',
    path: 'time-series'
  };

  let networkConfiguration2 = {
    host: 'api',
    path: 'time-series/:timeSeriesId/agent/sync-external-datasource-values'
  };

  async function readItemView(query) {
    let method = CrawlerMethods.getMethod({
      entity: 'time-series',
      method: 'read'
    });
    if (method != null) {
      let result = await method(query);
      let allUsers = [
        result.data.contentResponsiblePerson,
        result.data.systemResponsiblePerson,
        ...result.data.contentNotificationUsers,
        ...result.data.systemNotificationUsers
      ].filter(
        (userId, index, items) =>
          userId != null && items.indexOf(userId) == index
      );
      try {
        //GET ALL USERS
        let getUsers = CrawlerMethods.getMethod({
          entity: 'users',
          method: 'read'
        });
        if (getUsers != null) {
          let { data } = await getUsers({ _id: allUsers });
          result.data = {
            ...result.data,
            contentResponsiblePerson: data.find(
              user => user._id == result.data.contentResponsiblePerson
            ),
            systemResponsiblePerson: data.find(
              user => user._id == result.data.systemResponsiblePerson
            ),
            contentNotificationUsers: result.data.contentNotificationUsers.map(
              userId => data.find(user => user._id == userId)
            ),
            systemNotificationUsers: result.data.systemNotificationUsers.map(
              userId => data.find(user => user._id == userId)
            )
          };
        }
      } catch (err) {
        AlertingService.Error(err);
      }
      try {
        //GET TAGS
        let tags = await EntityTagService.listTags(query.id, true);
        if (Array.isArray(tags)) {
          result.data.tags = tags.map(tag => tag.entityTag);
        }
      } catch (err) {
        AlertingService.Error(err);
      }

      return result;
    }
  }
  const tangoAgentConnectionConfigCrawlerConfiguration = {
    entity: 'time-series',
    method: 'read',
    populate:
      'externalReaderFlows,dataScheduler,timeSeriesType,measurementUnit,physicalQuantity'
  };

  const populateDataSchedulerConfiguration = {
    entity: 'time-series',
    method: 'read',
    populate: 'dataScheduler'
  };

  const listCrawlerConfiguration = {
    entity: 'time-series',
    method: 'read',
    populate: 'dataScheduler,timeSeriesType,measurementUnit,physicalQuantity'
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readItemView,
      readTACC: CrawlerMethods.crawler(
        tangoAgentConnectionConfigCrawlerConfiguration
      ),
      list: CrawlerMethods.crawler(listCrawlerConfiguration),
      syncExternalDatasourceValues: ModelUtilities.crud(
        'POST',
        networkConfiguration2,
        modelStorage
      ),
      populateDataScheduler: CrawlerMethods.crawler(
        populateDataSchedulerConfiguration
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default TimeSeriesModel;
