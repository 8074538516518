StepDetailsController.$inject = [
  '$stateParams',
  'AlertingService',
  'gettext',
  'MetadataService',
  'step',
  '$timeout'
];

function StepDetailsController(
  $stateParams,
  AlertingService,
  gettext,
  MetadataService,
  step,
  $timeout
) {
  var vm = this;
  var stepDetailsId = $stateParams.id;

  init();

  async function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    vm.step = undefined;

    $timeout(() => {
      MetadataService.ChangeMetadata(step.name, step.description);
    });

    if (!stepDetailsId) {
      AlertingService.Error('Missing params: id');
    } else {
      try {
        vm.tasks = step.tasks;
        vm.headerData = constructHeader(step);
        MetadataService.Loading(false);
        vm.loading = false;
      } catch (err) {
        AlertingService.Error(err);
        vm.loading = false;
        MetadataService.Loading(false);
      }
    }
  }
  function constructHeader(step) {
    var actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: step._id,
        endpoint: {
          entity: 'steps',
          method: 'delete'
        },
        redirectState: 'alarms-and-rules-sop-steps-list'
      },
      {
        title: gettext('duplicate'),
        state: 'alarms-and-rules-sop-steps-duplicate',
        param: 'id',
        paramValue: step._id
      },
      {
        title: gettext('Update'),
        state: 'alarms-and-rules-sop-steps-edit',
        param: 'id',
        paramValue: step._id
      }
    ];

    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];

    var propertySections = [
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'steps',
        target: stepDetailsId,
        type: 'userAccess'
      }
    ];
    return {
      metadata: {
        definition: gettext('Standard operational procedure step'),
        title: step.name,
        description: step.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}

export default StepDetailsController;
