TimeSeriesTangoPropertiesConfiguration.$inject = [
  'ValidateObjectConfiguration',
  'ColorService',
  'TranslationService',
  'TangoPropertiesHelper',
  'SfeForm2Dialog',
  'PersonResponsibleTimeSeriesFormConfiguration',
  'RulesAndAlarmsTimeSeriesFormConfiguration',
  'gettextCatalog'
];
/**
 * @ngdoc service
 * @name common.TimeSeriesTangoPropertiesConfiguration
 * @description TODO
 * @property {function} get
 */
export default function TimeSeriesTangoPropertiesConfiguration(
  ValidateObjectConfiguration,
  ColorService,
  TranslationService,
  TangoPropertiesHelper,
  SfeForm2Dialog,
  PersonResponsibleTimeSeriesFormConfiguration,
  RulesAndAlarmsTimeSeriesFormConfiguration,
  gettextCatalog
) {
  //configuration to validate time series metadata
  const propertiesValidation = [
    {
      fieldName: 'timeSeriesStatus',
      required: true,
      type: 'number'
    },
    {
      fieldName: 'timeSeriesType',
      required: true,
      type: 'object'
    },
    {
      fieldName: 'dataInterpretationType',
      required: true,
      type: 'number'
    },
    {
      fieldName: 'dataSamplingType',
      required: true,
      type: 'number'
    },
    {
      fieldName: 'dataType',
      required: true,
      type: 'number'
    },
    {
      fieldName: 'precision',
      type: 'number',
      required: false
    },
    {
      fieldName: 'timeZone',
      type: 'number',
      required: true
    },
    {
      fieldName: 'tariff',
      type: 'object',
      required: true
    },
    {
      fieldName: 'physicalQuantity',
      type: 'object',
      required: true
    },
    {
      fieldName: 'measurementUnit',
      type: 'object',
      required: true
    },
    {
      fieldName: 'description',
      type: 'string',
      required: false
    }
  ];

  /**
   * @description returns sfe-value configuration to display time series status.
   * @function
   * @param {Object} {value} that contains status codelist id under value key
   * @return {Array}
   */
  const getStatus = ({ value }) => {
    const status = TranslationService.GetCollectionById(
      'codelists.timeSeriesStatuses',
      value
    );
    return [
      {
        text: status != null ? status.name : null,
        icon: {
          type: 2,
          name: 'radio_button_checked',
          color: ColorService.getApplicationColor('success')
        } //TODO change icon
      }
    ];
  };

  /**
   * @description returns array of sfe-property-list configurations to display time series properties.
   * @function
   * @param {Object} item time-series item
   * @return {Array}
   */
  function getProperties(item) {
    const validationResult = ValidateObjectConfiguration.validate(
      item,
      propertiesValidation
    );
    //creates array of properties
    return validationResult.configuration.reduce((result, validationConfig) => {
      let testId = validationConfig.fieldName;
      switch (validationConfig.fieldName) {
      case 'timeSeriesStatus':
        result = [
          ...result,
          TangoPropertiesHelper.sfePropertyConfig({
            title: gettextCatalog.getString('Status'),
            type: 'basic',
            testId,
            validationConfig,
            item,
            getSfePropertyValues: getStatus
          })
        ];
        break;
      case 'timeSeriesType':
        result = [
          ...result,
          TangoPropertiesHelper.sfePropertyConfig({
            item,
            type: 'basic',
            validationConfig,
            title: gettextCatalog.getString('Type'),
            testId,
            stateName:
                'configurations-company-resources-time-series-time-series-types-view',
            parameters: [{ name: 'id', value: '_id' }]
          })
        ];
        break;
      case 'dataType':
        result = [
          ...result,
          TangoPropertiesHelper.sfePropertyConfig({
            title: gettextCatalog.getString('Data type'),
            type: 'basic',
            validationConfig,
            testId,
            item,
            getSfePropertyValues: TangoPropertiesHelper.getCodeListValue(
              'dataTypes'
            )
          })
        ];
        break;
      case 'dataInterpretationType':
        result = [
          ...result,
          TangoPropertiesHelper.sfePropertyConfig({
            title: gettextCatalog.getString('Interpretation type'),
            type: 'basic',
            validationConfig,
            testId,
            item,
            getSfePropertyValues: TangoPropertiesHelper.getCodeListValue(
              'dataInterpretationTypes'
            )
          })
        ];
        break;
      case 'dataSamplingType':
        result = [
          ...result,
          TangoPropertiesHelper.sfePropertyConfig({
            title: gettextCatalog.getString('Sampling type'),
            type: 'basic',
            validationConfig,
            testId,
            item,
            getSfePropertyValues: TangoPropertiesHelper.getCodeListValue(
              'dataSamplingTypes'
            )
          })
        ];
        break;
      case 'precision':
        if (item.dataType == 3) {
          result = [
            ...result,
            TangoPropertiesHelper.sfePropertyConfig({
              title: gettextCatalog.getString('Precision'),
              type: 'basic',
              validationConfig,
              testId,
              item,
              getSfePropertyValues: ({ item }) => [{ text: item.precision }]
            })
          ];
        }
        break;
      case 'timeZone':
        result = [
          ...result,
          TangoPropertiesHelper.sfePropertyConfig({
            title: gettextCatalog.getString('Time Zone'),
            type: 'basic',
            validationConfig,
            item,
            testId,
            getSfePropertyValues: TangoPropertiesHelper.getCodeListValue(
              'timeZones'
            )
          })
        ];
        break;
      case 'physicalQuantity':
        result = [
          ...result,
          TangoPropertiesHelper.sfePropertyConfig({
            item,
            type: 'basic',
            validationConfig,
            testId,
            title: gettextCatalog.getString('Physical quantity')
          })
        ];
        break;
      case 'measurementUnit':
        result = [
          ...result,
          {
            ...TangoPropertiesHelper.getCommonPropertyConfiguration({
              entity: 'measurement-units',
              type: 'basic',
              item,
              key: validationConfig.fieldName,
              options: { metricPrefixKey: 'metricPrefix' }
            }),
            testId
          }
        ];
        break;
      case 'tariff':
        result = [
          ...result,
          TangoPropertiesHelper.sfePropertyConfig({
            title: gettextCatalog.getString('Tariff'),
            type: 'basic',
            validationConfig,
            testId,
            item,
            getSfePropertyValues: ({ item }) => [
              {
                text: item.tariff
                  ? `${item.tariff.name} (${item.tariff.code})`
                  : null
              }
            ]
          })
        ];
        break;
      case 'description':
        if (item.description != null) {
          result = [
            ...result,
            {
              type: 'divider'
            },
            TangoPropertiesHelper.sfePropertyConfig({
              type: 'basic',
              validationConfig,
              testId,
              item,
              getSfePropertyValues: ({ item }) => [{ text: item.description }]
            })
          ];
        }
        break;
      }
      return result;
    }, []);
  }
  /**
   * @description returns array of sfe-property-list configurations to display responsible person properties
   * @function
   * @param {Object} item time-series item
   * @return {Array}
   */
  function getResponsiblePersonProperties(item) {
    const validationConfig = [
      {
        type: 'object',
        required: true,
        fieldName: 'contentResponsiblePerson'
      },
      {
        fieldName: 'contentNotificationUsers',
        type: 'array',
        required: false
      },
      {
        type: 'object',
        required: true,
        fieldName: 'systemResponsiblePerson'
      },
      {
        fieldName: 'systemNotificationUsers',
        type: 'array',
        required: false
      }
    ];
    const validationResult = ValidateObjectConfiguration.validate(
      item,
      validationConfig
    );
    //create array of sfe-property configurations
    return validationResult.configuration.reduce((result, config) => {
      let title;
      let values;
      let testId = config.fieldName;
      switch (config.fieldName) {
      case 'contentResponsiblePerson':
      case 'systemResponsiblePerson':
        title =
            config.fieldName == 'contentResponsiblePerson'
              ? gettextCatalog.getString('Content responsible person')
              : gettextCatalog.getString('System responsible person');
        if (config.validity) {
          values = [
            TangoPropertiesHelper.getCommonPropertyConfiguration({
              entity: 'users',
              type: 'basicValue',
              item,
              key: config.fieldName,
              options: { showPicture: true }
            })
          ];
        } else {
          values = [];
        }
        break;
      case 'contentNotificationUsers':
      case 'systemNotificationUsers':
        if (config.validity && !config.warning.noValue) {
          title =
              config.fieldName == 'contentNotificationUsers'
                ? gettextCatalog.getString('Content notification users')
                : gettextCatalog.getString('System notification users');
          //Create sfe-property value for every user in users array
          values = item[config.fieldName].reduce((valuesResult, user) => {
            valuesResult = [
              ...valuesResult,
              TangoPropertiesHelper.getCommonPropertyConfiguration({
                entity: 'users',
                type: 'basicValue',
                item: { user },
                key: 'user',
                options: { showPicture: false }
              })
            ];
            return valuesResult;
          }, []);
        }
        break;
      }
      //Title is not set when sfe-property
      // shouldn\'t be shown
      if (title != null) {
        result = [
          ...result,
          {
            type: 'basic',
            title,
            values,
            testId
          }
        ];
      }
      return result;
    }, []);
  }
  /**
   * @description returns tango-properties configurations.
   * @function
   * @param {string} entityId mongo id f the selected entity
   * @return {Object}
   */
  function get(entityId) {
    return {
      type: 'dynamic',
      /**
       * @description returns array of sfe-property-list configurations.
       * @function
       * @param {Object} store
       * @return {Array}
       */
      valueFn: store => {
        let item;
        if (
          store != null &&
          store.timeSeries != null &&
          store.timeSeries[entityId] != null &&
          store.timeSeries[entityId].data != null
        ) {
          item = store['timeSeries'][entityId].data;
        }
        let properties;
        let responsiblePersonProperties;
        let rulesAlarmsProperties;
        if (item != null) {
          properties = getProperties(item);
          responsiblePersonProperties = getResponsiblePersonProperties(item);
          rulesAlarmsProperties = TangoPropertiesHelper.getRulesAlarmsProperties(
            item.rules,
            true
          );
        }

        let responsiblePersonPropertiesForm = PersonResponsibleTimeSeriesFormConfiguration.get(
          item
        );
        let rulesAndAlarmsForm = RulesAndAlarmsTimeSeriesFormConfiguration.get(
          item
        );
        return [
          {
            canClose: true,
            inCard: true,
            title: gettextCatalog.getString('Properties'),
            properties
          },
          {
            canClose: true,
            inCard: true,
            title: gettextCatalog.getString(
              'Responsible Person and notifications'
            ),
            properties: responsiblePersonProperties,
            actions: [
              {
                icon: {
                  type: 2,
                  name: 'edit'
                },
                fn: () => {
                  SfeForm2Dialog.open(responsiblePersonPropertiesForm);
                },
                color: 'grey'
              }
            ]
          },
          {
            canClose: true,
            inCard: true,
            title: gettextCatalog.getString('Alarms and Rules'),
            properties: rulesAlarmsProperties,
            actions: [
              {
                icon: {
                  type: 2,
                  name: 'edit'
                },
                fn: () => {
                  SfeForm2Dialog.open(rulesAndAlarmsForm);
                },
                color: 'grey'
              }
            ]
          }
        ];
      }
    };
  }

  return {
    get
  };
}
