import template from '../views/gis-timeseries-pair.dialog.html';

GisTimeSeriesPair.$inject = ['$mdDialog'];
/**
 * @ngdoc service
 * @name common.GisTimeSeriesPair
 * @description opens gis measurement selector map.
 * @property {function} open
 */
function GisTimeSeriesPair($mdDialog) {
  /**
   * @memberof GisTimeSeriesPair.open
   * @description opens selected dialog and resolves selected measurement.
   * @param {string} gisData map id
   * @return {Promise}
   */
  function open(gisData) {
    return $mdDialog.show({
      controller: GisTimeSeriesPairDialogController,
      controllerAs: 'vm',
      template,
      parent: angular.element(document.body),
      preserveScope: true,
      locals: {
        gisData: gisData
      }
    });
  }

  return {
    open: open
  };
}

export default GisTimeSeriesPair;

GisTimeSeriesPairDialogController.$inject = ['gisData', '$mdDialog', 'gettext'];

function GisTimeSeriesPairDialogController(gisData, $mdDialog, gettext) {
  var vm = this;
  vm.closeDialog = closeDialog;
  vm.$onInit = init;
  //ON SELECT CALLBACK
  //passed to sfe-gis-map component
  //gets called when gis item is selected
  vm.onSelect = closeDialog;
  //DIALOG HEADER CONFIG
  vm.header = {
    title: gettext('Gis Map'),
    isDialog: true,
    actions: [
      {
        icon: {
          name: 'close',
          type: 2
        },
        cancel: true
      }
    ]
  };

  /**
   * @description initiates gis map.
   * @function
   */
  function init() {
    if (!gisData) {
      $mdDialog.cancel(gettext('There are no map id'));
    } else {
      vm.gisData = {
        ...gisData
      };
    }
  }
  /**
   * @description resolves gis dialog with selected item
   * @function
   */
  function closeDialog(item) {
    $mdDialog.hide(item);
  }
}
