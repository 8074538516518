module.exports = {
  entity: 'measuringPointKinds',
  path: 'measuringPointKinds',
  name: {
    en: 'Measuring point kinds',
    sl_SI: 'Načini merilnih mest'
  },
  codelist: 'measuringPointKinds',
  selectedParamName: 'id',
  scope: 'tis_be:measuringpoint-kinds',

  dialog: {
    codelist: 'measuringPointKinds',
    title: {
      en: 'Select measuring point mode',
      sl_SI: 'Izberite način merilnega mesta'
    },
    listInfo: {
      en: 'Select measuring point mode from the list below',
      sl_SI: 'Izberite način merilnega mesta s spodnjega seznama'
    }
  }
}
