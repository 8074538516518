HomeController.$inject = [
  '$state',
  '$smartAuth',
  'AlertingService',
  'DashboardUserModel',
  '$timeout'
];

function HomeController(
  $state,
  $smartAuth,
  AlertingService,
  DashboardUserModel,
  $timeout
) {
  var vm = this;
  vm.loadingDashboard = true;
  $smartAuth
    .profile()
    .then(function(user) {
      return DashboardUserModel.read({
        user: user._id
      });
    })
    .then(function(res) {
      if (res.data && res.data.length && res.data[0] && res.data[0].dashboard) {
        $state.go('dashboards-dashboards-view', {
          id: res.data[0].dashboard
        });
      }
      // timeout is needed to trigger a digest cycle
      $timeout(function() {
        vm.loadingDashboard = false;
      });
    })
    .catch(function(err) {
      AlertingService.Error(err);
      vm.loadingDashboard = false;
    });
}

export default HomeController;
