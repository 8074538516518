import './sfe-element-selector.scss';
import template from './sfe-element-selector.component.html';

/**
 * @ngdoc component
 * @name common.sfeElementSelector
 * @description Opens a dialog with elements to select.
 * @param {Object} element - Containing data of the selected element.
 * @param {string} entity - Entity to query for a list of possible elements to select.
 * @param {Array} dialogConfigurations - Contains filter/title/whether you can select multiple elements.
 * @param {string} buttonTitle - Title of the button which opens the element adding dialog.
 * @param {Function} addAction - Action which is performed after adding the element.
 * @param {Object} validFrom - 'Valid from' date that's picked.
 * @param {Object} validTo - 'Valid to' date that's picked.
 * @param {Object} createRedirect - Used for the 'add entity' button.
 * @param {boolean} required - Flag that signals whether selecting the element is required in the form.
 * @param {string} name - Name of the element.
 * @param {Object} parentForm - Parent form.
 * @example
 * <sfe-element-selector
 *   element="vm.config.dataObj[data.name]"
 *   entity="::data.entity"
 *   dialog-configurations="::data.dialogConfigurations"
 *   button-title="::data.buttonTitle"
 *   show-reselect="::data.showReselect"
 *   add-action="::data.addAction"
 *   valid-from="vm.config.dataObj[data.name][data.validFrom]"
 *   valid-to="vm.config.dataObj[data.name][data.validTo]"
 *   create-redirect="::data.createRedirect"
 *   required="::data.required"
 *   parent-form="ItemForm"
 *   name="item_{{$index}}"
 * >
 * </sfe-element-selector>
 */

export default {
  restrict: 'E',
  template,
  bindings: {
    element: '=',
    entity: '<',
    dialogConfigurations: '<',
    buttonTitle: '<',
    addAction: '<',
    validFrom: '<',
    validTo: '<',
    createRedirect: '<',
    required: '<',
    name: '@',
    parentForm: '<',
    disableAdding: '<'
  },
  controller: sfeElementSelectorController,
  controllerAs: 'vm',
  bindToController: true
};

sfeElementSelectorController.$inject = [
  'ItemSelector',
  '$timeout',
  'AlertingService',
  '$scope'
];

function sfeElementSelectorController(
  ItemSelector,
  $timeout,
  AlertingService,
  $scope
) {
  var vm = this;

  $scope.$on('$destroy', () => {
    if (stopWatcher) {
      stopWatcher();
    }
  });

  vm.$onChanges = function(changes) {
    if (changes.parentForm && changes.parentForm.currentValue) {
      $scope.ElementSelectorForm = changes.parentForm.currentValue;
      validatePattern();
    }
  };

  // Added element wathcer in case of field is required to trigger validation
  var stopWatcher = $scope.$watch('vm.element', newVal => {
    if (vm.required) {
      if (newVal && newVal._id) {
        validatePattern();
        stopWatcher();
      }
    } else {
      stopWatcher();
    }
  });

  /**
   * @description Removes the selected element and performs validation of the form.
   * @function
   */
  vm.removeElement = function() {
    vm.element = {};
    validatePattern();
  };

  /**
   * @description Triggers the opening of the element selector dialog and performs the configured action after selecting and adding it.
   * @function
   */
  vm.openSelectorDialog = function() {
    ItemSelector.open(vm.entity, vm.dialogConfigurations).then(
      function(selected) {
        $timeout(vm.setDirty);
        if (selected && selected.length) {
          vm.element = selected[0];
          if (vm.addAction) {
            $timeout(function() {
              vm.addAction(vm.element, vm.name);
              validatePattern();
            });
          } else {
            validatePattern();
          }
        }
      },
      function(err) {
        AlertingService.Error(err);
        validatePattern();
      }
    );
  };

  /**
   * @description Function to validate whether element selector form is valid.
   * @function
   */
  function validatePattern() {
    if (!vm.required) {
      $scope.ElementSelectorForm.$setValidity('pattern', true);
    } else {
      if (vm.element && vm.element._id) {
        $scope.ElementSelectorForm.$setValidity('pattern', true);
      } else {
        $scope.ElementSelectorForm.$setValidity('pattern', false);
      }
    }
  }
}
