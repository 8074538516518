TimeSeriesWizardDuplicateConfiguration.$inject = [
  'gettextCatalog',
  'gettext',
  '$mdDialog',
  'TangoWizardDialog'
];
/**
 * @ngdoc service
 * @name common.TimeSeriesWizardDuplicateConfiguration
 * @description Get configuration for time series duplicate dialog.
 * @property {function} get
 */
export default function TimeSeriesWizardDuplicateConfiguration(
  gettextCatalog,
  gettext,
  $mdDialog,
  TangoWizardDialog
) {
  /**
   * @description get time series duplicate configuration.
   * @function
   * @param {string} entityId
   * @param {Function} onSaveAction
   * @return {Object} config
   */
  const get = (entityId, onSaveAction) => {
    let config = {
      name: 'createTimeSeriesFromSelectedTimeSeries',
      actions: [
        {
          //Duplicate selected properties
          title: gettext('Duplicate'),
          fn: async form => {
            let body = form.getValues();
            let timeSeriesProperties = {};
            if (body.timeSeriesProperties != null) {
              timeSeriesProperties = {
                ...timeSeriesProperties,
                ...body.timeSeriesProperties
              };
            }

            if (
              body.timeSeriesPropertiesUseCurrentParams != null &&
              typeof body.timeSeriesPropertiesUseCurrentParams == 'object'
            ) {
              timeSeriesProperties = {
                ...timeSeriesProperties,
                ...body.timeSeriesPropertiesUseCurrentParams
              };
            }

            $mdDialog.hide();
            let config = {
              title: gettextCatalog.getString(
                'Duplicate time series configuration'
              ),
              options: {
                duplicateData: timeSeriesProperties,
                onSaveAction
              },
              entity: 234,
              entityId
            };
            TangoWizardDialog.open(config);
          },
          disabledFn: form => {
            let validForm = form.formValidity();
            return validForm ? false : true;
          },
          color: 'primary'
        }
      ],
      title: gettextCatalog.getString(
        'Duplicate time series data from selected time series properties'
      ),
      fields: [
        {
          id: 'timeSeriesProperties',
          type: {
            name: 'checkbox',
            options: {
              layout: 'column',
              display: item => {
                return item.name;
              },
              // Duplicate options
              items: [
                {
                  _id: 'metaData',
                  name: gettextCatalog.getString('Meta data')
                },
                {
                  _id: 'ruleAlarms',
                  name: gettextCatalog.getString('Rules and alarms')
                },
                {
                  _id: 'inputTimeSeriesConfigurations',
                  name: gettextCatalog.getString(
                    'Input time series configurations'
                  )
                }
              ]
            }
          },
          initialize: () => {
            return {
              metaData: true,
              ruleAlarms: true,
              inputTimeSeriesConfigurations: true
            };
          },
          required: true
        },
        {
          id: 'divider',
          type: {
            name: 'divider'
          }
        },
        {
          id: 'timeSeriesPropertiesUseCurrentParams',
          type: {
            name: 'checkbox',
            options: {
              layout: 'column',
              display: item => {
                return item.name;
              },
              //4  Duplicate option
              items: [
                {
                  _id: 'useCurrentTimeSeriesAsInputParameter',
                  name: gettextCatalog.getString(
                    'Use current time series as input parameter'
                  )
                }
              ]
            }
          },
          required: false
        }
      ]
    };
    return config;
  };

  return {
    get
  };
}
