import './error-state.scss';
ErrorStateController.$inject = ['$state', 'gettextCatalog'];

function ErrorStateController($state, gettextCatalog) {
  const vm = this;

  vm.$onInit = () => {
    let image;
    let titleText;
    let subtitleText;
    switch ($state.current.name) {
    case 'forbidden':
      image = '403.svg';
      titleText = gettextCatalog.getString(
        'Oh no! You do not have permissions to see this item :('
      );
      subtitleText = gettextCatalog.getString(
        'If you think this is an error please contact your administrator.'
      );
      break;
    case 'not-found':
      image = '404.svg';
      titleText = gettextCatalog.getString(
        'Oh no! The page you are trying to reach does not exist :('
      );
      subtitleText = gettextCatalog.getString(
        'Check your URL / link and try again.'
      );
      break;
    default:
      image = '500.svg';
      titleText = gettextCatalog.getString('Oh no! Something went wrong :(');
      break;
    }

    vm.imagePath = `/img/${image}`;
    vm.titleText = titleText;
    vm.subtitleText = subtitleText;
  };
}

export default ErrorStateController;
