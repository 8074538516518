import './sfe-mapping-rule.scss';
import template from './sfe-mapping-rule.component.html';

/**
 * @ngdoc component
 * @name data.sfeMappingRule
 * @description Mapping rule component - configured for regex, format and default.
 * @param {Array} data The set of mapping rules.
 * @param {Object} config Contains info for display of mapping rule component.
 * @param {boolean} edit Signals wheather data should be pre-filled into form or not.
 * @param {number} refreshArgument Signals refreshing.
 * @param {string} type regex/format/default - depending on type the component behaves differently.
 * @example
 * <component-name
 * paramName="'paramValue'"
 * ></component-name>
 */

export default {
  restrict: 'E',
  template,
  bindings: {
    mappingRules: '=',
    config: '<',
    edit: '<',
    type: '@',
    mappingDataType: '<'
  },
  controller: SfeMappingRuleController,
  controllerAs: 'vm',
  bindToController: true
};

SfeMappingRuleController.$inject = [
  'gettext',
  'TranslationService',
  'gettextCatalog'
];

function SfeMappingRuleController(gettext, TranslationService, gettextCatalog) {
  var vm = this;
  var defaultFn = {
    function: {
      id: 8,
      name: 'no change'
    }
  };
  // init watchers
  vm.$onChanges = function onDataChange(bindings) {
    if (bindings.mappingDataType) {
      triggerRefresh(bindings.mappingDataType.currentValue);
    }
    if (vm.mappingRules) {
      vm.mappingRules.forEach(function(mappingRule, index) {
        mappingRule.functionsConfig = angular.copy(
          getFunctionAutocompleteConfig(index)
        );
      });
    }
  };

  // scope type: 'regex', 'format', 'default'
  var functionsList = TranslationService.GetCollection(
    'codelists.dataTypeMappingFunctions'
  );

  // functions autocomplete
  vm.initialFunctionAutocompleteConfig = {
    floatingLabel: gettext('Select function'),
    searchParamName: 'name',
    required: true,
    noDialog: true,
    codelist: 'dataTypeMappingFunctions',
    selectedParam: 'name',
    change: checkForArgument.bind({
      index: 0,
      initial: true
    }),
    filterFn: filterFunctions.bind({
      index: 0
    })
  };
  // alarm autocomplete
  vm.alarmAutocompleteConfig = {
    query: {
      entity: 'alarms',
      method: 'read'
    },
    name: 'alarm',
    required: true,
    floatingLabel: gettext('Select alarm'),
    disabled: false,
    displayFields: ['name'],
    noDialog: false,
    entity: 'alarms',
    searchParamName: 'filter',
    filterObject: {
      order: 'name'
    },
    selectedParam: 'id'
  };
  vm.dateTimePickerFormat = {
    viewDateFormat: 'dd.MM.yyyy',
    dateTimeFormat: 'yyyy-MM-dd HH:mm:ss',
    dateFormat: 'yyyy-MM-dd'
  };
  vm.datePickerFormat = {
    viewDateFormat: 'dd.MM.yyyy',
    dateTimeFormat: 'yyyy-MM-dd',
    dateFormat: 'yyyy-MM-dd'
  };
  vm.dateTimePickerConfig = {
    dateLabel: gettextCatalog.getString('Date'),
    timeLabel: gettextCatalog.getString('Time'),
    required: true
  };
  vm.$onInit = function() {
    // set titles
    switch (vm.type) {
    case 'literal':
      vm.listTitle = gettext('Regular Expression Mapping Rule');
      break;
    case 'format':
      vm.listTitle = gettext('Format mapping rules');
      vm.dataTypeAutocompleteConfig = {
        required: true,
        codelist: 'dataTypes',
        entity: 'entity',
        noDialog: true,
        floatingLabel: gettext('Type'),
        searchParamName: 'name',
        selectedParam: 'id'
      };
      break;
    case 'default':
      vm.listTitle = gettext('Default mapping rule');
      break;
    }
  };

  /**
   * @description Refreshes data when another data type is selected.
   * @function
   */
  function triggerRefresh(currentDataTypeValue) {
    if (currentDataTypeValue) {
      vm.selectFormatDisabled = false;
    } else {
      vm.selectFormatDisabled = true;
      if (!vm.edit) {
        vm.mappingRules = [];
      }
    }
    if (vm.mappingRules) {
      if (vm.mappingRules[0] && vm.mappingRules[0].dataTypeChanged) {
        vm.mappingRules[0].functionsConfig =
          vm.initialFunctionAutocompleteConfig;
        vm.mappingRules[0].outputExpression = defaultFn;
      }
      vm.mappingInitFinished = false;
      vm.mappingRules.forEach(function(element, index) {
        checkForArgument.apply({ index });
      });
      vm.mappingInitFinished = true;
    }
  }

  /**
   * @description Sets regex pattern for elements based on their type.
   * @function
   * @param {number} index Index of the element.
   * @param {Object} element Element which is to be equipped with the regex pattern shield of thousand rules.
   * @return {dataType}
   */
  function setPatternAndType(index, element) {
    const dataType = {
      ...vm.mappingDataType,
      regex:
        vm.mappingDataType.name === 'Decimal'
          ? new RegExp('^[+-]?\\d*(([,.]\\d{3})+)?([,.]\\d+)?([eE][+-]?\\d+)?$')
          : vm.mappingDataType.regex
    };
    if (!dataType) {
      return;
    }
    filterFunctions(functionsList, index);
    var selectedFunction = {};
    const searchId =
      typeof element.outputExpression.function == 'object' &&
      element.outputExpression.function !== null
        ? element.outputExpression.function.id
        : element.outputExpression.function;

    selectedFunction = element.functionOptions.find(function(fn) {
      return fn.id === searchId;
    });
    // checks if the current value was prefetched
    if (
      typeof element.outputExpression.function === 'object' &&
      element.outputExpression.function.__prefetched__value__ !== true
    ) {
      element.outputExpression.args = undefined;
    }
    if (selectedFunction && selectedFunction.hasArguments) {
      if (selectedFunction.arguments[0] === 'value') {
        element.patternExample = gettextCatalog.getString(
          'Expected format: {{ msg }}.',
          {
            msg: dataType.name
          }
        );
        switch (dataType.id) {
        case 1:
          if (!element.outputExpression.args) {
            element.outputExpression.args = 'false';
          }
          break;
        case 2:
          element.argumentType = 'numerical';
          element.onlyInteger = true;
          break;
        case 3:
          element.argumentType = 'numerical';
          element.onlyInteger = false;
          break;
        case 6:
          element.pattern = new RegExp(
            '^([0-9]|0[0-9]|1[0-9]|2[0-3])[:]([0-5][0-9])[:]([0-5][0-9])$'
          );
          element.patternExample = gettext('Expected format hh:mm:ss');
          break;
        default:
          element.pattern = dataType.regex;
        }
      } else if (
        selectedFunction.arguments[0] === 'n' ||
        selectedFunction.arguments[0] === 'numberOfValues'
      ) {
        element.argumentType = 'numerical';
        element.onlyInteger = true;
        element.patternExample = gettextCatalog.getString(
          'Expected format: {{ msg }}.',
          {
            msg: 'Integer'
          }
        );
      }
    } else {
      switch (dataType.id) {
      case 1: // Booolean
        element.outputExpression.args = null;
        break;
      case 5: // Date
        element.outputExpression.args = null;
        break;
      case 7: // DateTime
        element.outputExpression.args = null;
        break;
      }
    }
  }

  /**
   * @description Adds a mapping rule to the current set of already defined/selected mapping rules.
   * @function
   */
  vm.addMappingRule = function addMappingRule() {
    if (!vm.mappingRules) {
      vm.mappingRules = [];
    }
    var mappingRule = {
      errors: [],
      isError: false
    };

    mappingRule.functionsConfig = angular.copy(
      getFunctionAutocompleteConfig(vm.mappingRules.length)
    );
    if (vm.config.format) {
      mappingRule.inputDataType = null;
    }
    vm.mappingRules.push(mappingRule);
  };

  /**
   * @description Configures and returns the autocomplete configuration for function selection.
   * @function
   * @param {number} index Index of the element which needs the autocomplete configuration.
   * @return {Object} Autocomplete config.
   */
  function getFunctionAutocompleteConfig(index) {
    var functionsConfig = {
      floatingLabel: gettext('Select function'),
      searchParamName: 'name',
      required: true,
      noDialog: true,
      codelist: 'dataTypeMappingFunctions',
      selectedParam: 'name'
    };
    functionsConfig.change = checkForArgument.bind({
      index: index
    });

    functionsConfig.filterFn = filterFunctions.bind({
      index: index
    });
    return functionsConfig;
  }

  /**
   * @description Removes the selected element from the set of mapping rules.
   * @function
   * @param {number} index Index of the element which is to be removed.
   */
  vm.removeMappingRule = function removeMappingRule(index) {
    vm.mappingRules.splice(index, 1);
  };

  /**
   * @description filters mapping rule functions depend on input expression type and expected output expression type.
   * @function
   * @param {Array} functions original function list
   * @return {Array} filtered function list
   */
  function filterFunctions(functions, index) {
    if (typeof index != 'number') {
      index = this.index;
    }
    vm.mappingRules[index].functionOptions = [];
    var type;
    // check if selected type exists and if it is object or integer
    if (vm.config.type) {
      type =
        typeof vm.config.type == 'object' ? vm.config.type.id : vm.config.type;
    }

    if (type) {
      vm.mappingRules[index].functionOptions = functions.filter(function(
        functionObject
      ) {
        return functionObject.validOutputDatatypes.indexOf(type) > -1; //check if funciton supports selected datatype as output
      });
      if (vm.type == 'format') {
        if (vm.mappingRules[index].inputDataType === null) {
          vm.mappingRules[index].functionOptions = []; //input format must be selected first
        } else {
          var inputDataType =
            typeof vm.mappingRules[index].inputDataType == 'object'
              ? vm.mappingRules[index].inputDataType.id
              : vm.mappingRules[index].inputDataType;
          vm.mappingRules[index].functionOptions = vm.mappingRules[
            index
          ].functionOptions.filter(function(functionObject) {
            return (
              functionObject.validInputDatatypes.indexOf(inputDataType) > -1
            );
          });
        }
      }
    }
    return vm.mappingRules[index].functionOptions.filter(
      filterMapperTypeAllowed
    );
  }

  /**
   * @description filters functions according to allowed mapper type functions.
   * @function
   * @param {Object} item function codelist item
   * @return {Boolean}
   */
  function filterMapperTypeAllowed(item) {
    const mappers = TranslationService.GetCollection('codelists.mappingTypes');
    let validFunctions = [];
    switch (vm.type) {
    case 'literal':
      validFunctions = mappers.find(mapperItem => mapperItem.id == 2)
        .validFunctions;
      break;
    case 'format':
      validFunctions = mappers.find(mapperItem => mapperItem.id == 3)
        .validFunctions;
      break;
    case 'default':
      validFunctions = mappers.find(mapperItem => mapperItem.id == 1)
        .validFunctions;
      break;
    }

    return validFunctions.indexOf(item.id) > -1;
  }

  /**
   * @description Checks if selected element has an output expression if so, it sets his requirement for arguments.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function checkForArgument() {
    var index = this.index;
    if (
      vm.mappingRules[index] &&
      vm.mappingRules[index].outputExpression.function
    ) {
      var outputExpressionFunction = functionsList.find(function(fn) {
        return fn.id === vm.mappingRules[index].outputExpression.function.id;
      });
      if (outputExpressionFunction) {
        vm.mappingRules[index].requireArgument =
          outputExpressionFunction.hasArguments;
      } else {
        vm.mappingRules[index].requireArgument = false;
      }
      setPatternAndType(index, vm.mappingRules[index]);
    } else if (vm.mappingRules[index]) {
      vm.mappingRules[index].requireArgument = false;
    }
  }
}
