import { DateTime } from 'luxon';

luxonDate.$inject = ['LocalizationService', 'gettext', 'TranslationService'];

/**
 * @ngdoc filter
 * @name common.luxonDate
 * @description Formats date objects / strings via luxon respectful to localization set in the app.
 * @param {String} input
 * @param {String} option - can be 'short' to print a shorter date string
 * @param {number} timeZoneId
 * @example
 * <div>{{ object | luxonDate:'short' }}</div>
 */
function luxonDate(LocalizationService, gettext, TranslationService) {
  return function(input, option, timeZoneId) {
    if (!input) {
      return '/';
    }
    if (input) {
      let formatTimeString;
      // Mongo ID
      if (typeof input === 'string' && input.length === 16) {
        formatTimeString = parseInt(input);
      } else {
        formatTimeString = input;
      }
      let formatTime = DateTime.fromJSDate(new Date(formatTimeString));
      let timeZone;
      if (timeZoneId != null) {
        timeZone = TranslationService.GetCollectionById(
          'codelists.timeZones',
          timeZoneId
        );
      }
      if (timeZone != null) {
        formatTime = formatTime.setZone(timeZone.code);
      } else {
        formatTime = formatTime.setLocale(LocalizationService.getLocale());
      }

      switch (option) {
      case 'short':
        formatTime = formatTime.toLocaleString(DateTime.DATE_MED);
        break;
      case 'date':
        formatTime = formatTime.toFormat('dd. MM. yyyy');
        break;
      case 'time':
        formatTime = formatTime.toFormat('HH:mm:ss');
        break;
      default:
        formatTime = formatTime.toFormat('dd. MM. yyyy HH:mm:ss');
      }

      if (formatTime === 'Invalid DateTime') {
        return gettext('Invalid date');
      } else {
        return formatTime;
      }
    }
  };
}

export default luxonDate;
