ProjectController.$inject = [
  '$stateParams',
  'gettext',
  'MetadataService',
  'project',
  '$timeout'
];

function ProjectController(
  $stateParams,
  gettext,
  MetadataService,
  project,
  $timeout
) {
  const vm = this;
  const projectId = $stateParams.id;

  vm.$onInit = async function init() {
    vm.loading = true;
    MetadataService.Loading(true);

    vm.headerData = constructHeader(project);
    $timeout(() => {
      MetadataService.ChangeMetadata(project.name, project.description);
      MetadataService.Loading(false);
      vm.loading = false;
    });
  };

  function constructHeader(project) {
    let properties = [
      {
        type: 'code',
        title: gettext('Code'),
        value: project.code ? project.code : gettext('Unknown')
      }
    ];

    if (project.externalCode) {
      properties.push({
        type: 'code',
        title: gettext('External code'),
        value: project.externalCode
      });
    }

    if (project.businessPartner) {
      properties.push({
        title: gettext('Business partner'),
        type: 'link',
        linkTitle: project.businessPartner.name,
        state: 'company-resources-business-partners-view',
        param: 'id',
        paramValue: project.businessPartner._id
      });
    }
    let energyManagerValue = gettext('Unknown');
    if (project.energyManager != null) {
      if (project.energyManager.family_name != null) {
        energyManagerValue = `${project.energyManager.name} ${project.energyManager.family_name}`;
      } else {
        energyManagerValue = project.energyManager.name;
      }
    }
    properties.push(
      {
        type: 'simple',
        title: gettext('Project type'),
        value: project.projectType
          ? project.projectType.name
          : gettext('Unknown')
      },
      {
        type: 'simple',
        title: gettext('Project field'),
        value: project.projectField
          ? project.projectField.name
          : gettext('Unknown')
      },
      {
        type: 'simple',
        title: gettext('Depreciation period'),
        value: project.depreciationPeriod
          ? project.depreciationPeriod
          : gettext('Unknown')
      },
      {
        type: 'simple',
        title: gettext('Energy manager'),
        value: energyManagerValue
      }
    );
    let secondColumnProperties = [
      {
        type: 'dateShort',
        title: gettext('Start of project'),
        value: project.startOfProject
          ? project.startOfProject
          : gettext('Unknown')
      }
    ];

    if (project.endOfProject) {
      secondColumnProperties.push({
        type: 'dateShort',
        title: gettext('End of project'),
        value: project.endOfProject
      });
    }

    secondColumnProperties.push({
      type: 'dateShort',
      title: gettext('Start of billing period'),
      value: project.startOfBillingPeriod
        ? project.startOfBillingPeriod
        : gettext('Unknown')
    });

    if (project.endOfBillingPeriod) {
      secondColumnProperties.push({
        type: 'dateShort',
        title: gettext('End of billing period'),
        value: project.endOfBillingPeriod
      });
    }

    const tags = [
      {
        type: 'tags',
        paramName: 'name',
        values:
          project && Array.isArray(project.tags)
            ? project.tags.map(function(tag) {
              return tag.systemTag;
            })
            : null
      }
    ];

    let propertySections = [
      {
        title: gettext('Properties'),
        properties: properties
      },
      {
        noTitle: true,
        properties: secondColumnProperties
      },
      {
        title: gettext('System tags'),
        properties: tags
      }
    ];
    let actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: projectId,
        endpoint: {
          entity: 'projects',
          method: 'delete'
        },
        redirectState: 'company-resources-projects-list'
      },
      {
        title: gettext('duplicate'),
        state: 'company-resources-projects-duplicate',
        param: 'id',
        paramValue: projectId
      },
      {
        title: gettext('Update'),
        state: 'company-resources-projects-edit',
        param: 'id',
        paramValue: projectId
      }
    ];
    return {
      metadata: {
        definition: gettext('Project'),
        title: project.name,
        description: project.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}

export default ProjectController;
