import template from './notification-item.component.html';
/**
 * @ngdoc component
 * @name user.notificationItem
 * @description used to display notification.
 * @param {object} item notification object should contain notification id
 * @param {bool} relistData when it is set to true items relist is triggered in parent scope
 * @param {bool} markAsSeenFn function that is triggered to mark notification as seen
 * @example
 * <notification-item
 * item="notification"
 * mark-as-seen-fn="vm.markAsSeen"
 * relistData="relistData"
 * ></notification-item>
 */
export default {
  template,
  bindings: {
    item: '<',
    relistData: '=',
    markAsSeenFn: '='
  },
  controller: SfeNotificationItem,
  controllerAs: 'vm'
};

SfeNotificationItem.$inject = [
  'AlertingService',
  'CrudToastFactory',
  'gettext',
  'StandardUtils',
  'NotificationModel',
  'UserModel'
];

function SfeNotificationItem(
  AlertingService,
  CrudToastFactory,
  gettext,
  StandardUtils,
  NotificationModel,
  UserModel
) {
  var vm = this;
  vm.$onInit = init;

  vm.markAsSeenAction = [];
  /**
   * @description initiates component.
   * @function
   */
  function init() {
    vm.markAsSeen = markAsSeen;
    fetchNotificationData();
    if (!vm.item.seen) {
      vm.markAsSeenAction.push({
        title: gettext('confirm seen'),
        icon: {
          name: 'done'
        },
        fn: markAsSeen,
        color: 'success',
        disabledFn: () => {
          return vm.notification.isUpdating;
        }
      });
    }
  }

  /**
   * @description fetches notification creator.
   * @function
   * @param {object} notification object should contain owner key
   * @param {function} callback callback function
   */
  function fetchOwner(notification, callback) {
    UserModel.read({
      id: notification.owner
    }).then(
      function(res) {
        callback(null, notification, res.data);
      },
      function(err) {
        AlertingService.Error(err);
        callback(null, notification, null);
      }
    );
  }
  /**
   * @description fetches notification metadata.
   * @function
   * @param {function} callback
   */
  function fetchNotification(callback) {
    NotificationModel.read({
      id: vm.item.notification
    }).then(
      function(res) {
        callback(null, res.data);
      },
      function(err) {
        AlertingService.Error(err);
        callback(err);
      }
    );
  }
  /**
   * @description triggers methods to fetch all needed notification metadata and sets it to scope variables.
   * @function
   */
  function fetchNotificationData() {
    var waterfall = [fetchNotification, fetchOwner];
    async.waterfall(waterfall, function(err, notification, owner) {
      if (!err) {
        notification.user = vm.item.user;
        notification.seen = vm.item.seen;
        notification.ownerObject = owner;
        notification.ownerObject.imageUrl = StandardUtils.getUserImageUrl(
          notification.ownerObject,
          'sm'
        );
        vm.notification = notification;
      } else {
        AlertingService.Error(err);
      }
    });
  }
  /**
   * @description marks notification as seen.
   * @function
   */
  function markAsSeen() {
    if (vm.markAsSeenFn) {
      vm.markAsSeenFn(vm.notification);
    } else {
      NotificationModel.custom
        .markSeen({
          notification: vm.notification._id,
          user: vm.notification.user
        })
        .then(
          function() {
            vm.notification.seen = true;
            vm.relistData = true;
            CrudToastFactory.toast('update');
          },
          function(err) {
            AlertingService.Error(err);
          }
        );
    }
  }
}
