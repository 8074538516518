module.exports = {
  entity: 'cusum-flows',
  path: 'cusum-flows',
  entityId: 245,
  storeKey: 'cumulativeAggregationFlow',
  name: {
    en: 'Maintenance Flows',
    sl_SI: 'Maintenance Flows'
  },
  networkModel: {
    entity: 'cusum-flows',
    read: 'read'
  },
  scope: 'tis_be:cumulative-flow',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select cumulative flow',
      sl_SI: 'Izberite časovno serijo'
    },
    listInfo: {
      en: 'Select cumulative flow from the list below',
      sl_SI: 'Izberite časovno serijo s spodnjega seznama'
    }
  }
}
