module.exports = {
  entity: 'virtual-datapoints',
  path: 'virtual-datapoints',
  name: {
    en: 'Virtual Data point',
    sl_SI: 'Navidezna dodatkovna točka'
  },
  networkModel: {
    entity: 'virtual-datapoints',
    read: 'read'
  },
  scope: 'tis_be:virtual-datapoints',

  dialog: {
    title: {
      en: 'Select virtual data point',
      sl_SI: 'Izberite navidezno podatkovno točko'
    },
    listInfo: {
      en: 'Select virtual data point from the list below',
      sl_SI: 'Izberite navidezno podatkovno točki s spodnjega seznama'
    }
  }
}
