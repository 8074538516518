import { DateTime } from 'luxon';
import actions from '../redux/time-series-processing-values/action/time-series-processing-values.action';

TimeSeriesRecalculateService.$inject = [
  'SfeForm2Dialog',
  'gettext',
  'TranslationService',
  'TimeSeriesProcessingTriggerCalculationModel',
  'ToastService',
  '$mdDialog',
  'AlertingService',
  '$timeout',
  '$ngRedux',
  'SfeForm2DateFromPriorToValidationService'
];

/**
 * @ngdoc service
 * @name data.TimeSeriesRecalculateService
 * @property {function} open - opens recalculate dialog
 */
function TimeSeriesRecalculateService(
  SfeForm2Dialog,
  gettext,
  TranslationService,
  TimeSeriesProcessingTriggerCalculationModel,
  ToastService,
  $mdDialog,
  AlertingService,
  $timeout,
  $ngRedux,
  SfeForm2DateFromPriorToValidationService
) {
  /**
   * @description returns timeStamp in selected timeZone.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function getDate(date, timeZone) {
    if (Array.isArray(date) && date.length > 0 && date[0] != null) {
      if (timeZone != null) {
        date = DateTime.fromJSDate(date[0], { zone: timeZone });
      } else {
        date = DateTime.fromJSDate(date[0]);
      }

      return date.toMillis();
    }
  }
  /**
   * @description opens recalculate dialog and triggers recalculate.
   * @function
   * @param {Object} timeSeries timeSeries object
   */
  function open(timeSeries) {
    let fromToValidation = SfeForm2DateFromPriorToValidationService.get(
      'dateFrom',
      'dateTo'
    );

    let formConfig = {
      title: gettext('Recalculate'),
      name: 'recalculateForm',
      actions: [
        {
          title: gettext('Recalculate'),
          /**
           * @description triggers recalculate query.
           * @function
           * @param {object} api
           */
          fn: async api => {
            let timeZone = TranslationService.GetCollectionById(
              'codelists.timeZones',
              timeSeries.timeZone
            );
            let { dateFrom, dateTo } = api.getValues();
            dateFrom = getDate(dateFrom, timeZone.code);
            dateTo = getDate(dateTo, timeZone.code);
            try {
              await TimeSeriesProcessingTriggerCalculationModel.create(
                {
                  timeSeriesId: timeSeries._id,
                  from: dateFrom,
                  to: dateTo
                },
                {}
              );
              let action = actions.addUpdateValuesTag({
                id: timeSeries._id,
                state: { content: true, detail: true }
              });
              $ngRedux.dispatch(action);
              ToastService.showToast(
                gettext('Time series value was successfully created!')
              );
            } catch (err) {
              AlertingService.Error(err);
            }
            $mdDialog.hide();
          },
          disabledFn: api => {
            if (typeof api.formValidity == 'function') {
              return !api.formValidity();
            }
          }
        }
      ],
      fields: [
        {
          id: 'dateFrom',
          title: gettext('From'),
          width: 6,
          type: {
            name: 'date',
            options: {
              enableTime: true,
              enableSeconds: true
            }
          },
          initialize: () => {
            return [new Date()];
          },
          required: true,
          //TRIGGER REVALIDATE ON CHANGE
          onChange: async api => {
            if (typeof api.revalidate == 'function') {
              await $timeout();
              api.revalidate();
            }
          },
          validators: {
            maxDateCustom: fromToValidation
          }
        },
        {
          id: 'dateTo',
          title: gettext('To'),
          width: 6,
          type: {
            name: 'date',
            options: {
              enableTime: true,
              enableSeconds: true
            }
          },
          initialize: () => {
            return [];
          },
          required: false,
          //TRIGGER REVALIDATE ON CHANGE
          onChange: async api => {
            if (typeof api.revalidate == 'function') {
              await $timeout();
              api.revalidate();
            }
          },
          validators: {
            minDateCustom: fromToValidation
          }
        }
      ]
    };

    SfeForm2Dialog.open(formConfig);
  }

  return {
    open
  };
}
export default TimeSeriesRecalculateService;
