import './sfe-select-tree.scss';
import template from './sfe-select-tree.directive.html';

/**
 * @ngdoc directive
 * @name common.sfeSelectTree
 * @description A directive which is used for selecting an item from a tree view and performing an action on it.
 * @param {Object} query - It can also be a function (check fetchElements). It is used for fetching the data for the selected entity.
 * @param {Array} selectedObjects - Array of selected tree items.
 * @param {Array} displayFields - Array of attributes of the items in the list to be displayed.
 * @param {Boolean} single - Signalling whether only single or multiple selection should be allowed.
 * @param {Object} entity - Selected entity object
 * @example
 * <sfe-select-tree
 *   class="H-100"
 *   query="vm.treeConfig.query"
 *   has-child="vm.treeConfig.hasChild"
 *   selected-objects="vm.selectedItems"
 *   display-fields="vm.treeConfig.displayFields"
 *   single="!vm.config.multiple"
 *   entity="vm.config.entity"
 * >
 * </sfe-select-tree>
 */

export default function sfeSelectTree() {
  const directive = {
    restrict: 'E',
    template,
    scope: {
      query: '=',
      selectedObjects: '=?',
      displayFields: '<',
      single: '<',
      entity: '<'
    },
    link: linkFn,
    controller: sfeSelectTreeController,
    controllerAs: 'vm',
    bindToController: true
  };
  return directive;

  function linkFn(scope, el) {
    scope.vm.element = el;
  }
}

sfeSelectTreeController.$inject = ['AlertingService', 'CrawlerMethods'];

function sfeSelectTreeController(AlertingService, CrawlerMethods) {
  const vm = this;
  let currentPage;
  vm.getElements = getElements;
  vm.close = close;
  vm.selectItem = selectItem;
  vm.uncheckElemen = uncheck;
  vm.$onInit = init;

  /**
   * @description Initializes the map.
   * @function
   */
  function init() {
    getElements();
    if (!vm.selectedObjects) {
      vm.selectedObjects = [];
    }
  }

  /**
   * @description Fetches and initializes the items to be displayed.
   * @function
   * @param {Object} item Item of which the children should be fetched, used in select-tree.node.html
   */
  function getElements(item) {
    let queryObject = {};
    if (item) {
      queryObject.parentId = item._id;
      queryObject.page =
        item.pagination && item.pagination.page ? item.pagination.page + 1 : 1;
      item.loadingChildren = true;
    } else {
      queryObject.parentId = JSON.stringify(null);
      if (currentPage) {
        queryObject.page = currentPage + 1;
      }
      vm.loadingRoot = true;
    }
    fetchElements(queryObject, function(results, pagination) {
      if (results) {
        if (item) {
          if (!Array.isArray(item.children)) {
            item.children = results;
          } else {
            const filteredResults = results.filter(
              result => !item.children.find(child => child._id === result._id)
            );
            item.children = item.children.concat(filteredResults);
          }
          if (pagination.page * pagination.per_page < pagination.total) {
            item.loadMoreItems = true;
          } else {
            item.loadMoreItems = false;
          }
          item.pagination = {
            page: pagination.page
          };
          item.loadingChildren = false;
          item.opened = true;
        } else {
          if (vm.elements && vm.elements.length) {
            vm.elements = _.concat(vm.elements, results);
          } else {
            vm.elements = results;
          }
          if (vm.entity && vm.entity.plural === 'energy source types') {
            addIconToEnergySourceType(vm.elements);
          }
        }
      } else {
        if (item) {
          item.loadingChildren = false;
        }
      }
      // {total: 615, page: 7, per_page: 100}
      if (!item) {
        if (
          pagination &&
          pagination.per_page * pagination.page < pagination.total
        ) {
          vm.loadMoreRoot = true;
          currentPage = pagination.page;
        } else {
          vm.loadMoreRoot = false;
        }
      }
      vm.loadingRoot = false;
    });
  }

  /**
   * @description Function which fetches the elements based on the passed in query object.
   * @function
   * @param {Object} apiObj Query object
   */
  function fetchElements(apiObj, callback) {
    let method;
    if (typeof vm.query === 'object') {
      method = CrawlerMethods.getMethod(vm.query)(apiObj);
    } else {
      method = vm.query.query(apiObj).$promise;
    }
    method.then(
      function(res) {
        callback(res.data, res.pagination);
      },
      function(err) {
        AlertingService.Error(err);
        callback();
      }
    );
  }

  /**
   * @description Function which is triggered after selecting/deselecting an item. It sets the parameters of the vm.selectedObjects, based on the action
   * @function
   * @param {Object} Item, which is selected
   */
  function selectItem(item) {
    item.entity = vm.entity;
    if (vm.single) {
      if (item.isChecked) {
        if (vm.selectedObjects.length) {
          vm.selectedObjects[0].isChecked = false;
        }
        vm.selectedObjects = [];
        pushSelectedItems(item);
      } else {
        removeObj(vm.selectedObjects, item._id);
      }
    } else {
      if (item.isChecked) {
        pushSelectedItems(item);
      } else {
        removeObj(vm.selectedObjects, item._id);
      }

      if (item.isChecked && item.hasChild && !item.children) {
        let apiObj = {};
        apiObj.parentId = item._id;
        item.loadingChildren = true;
        fetchElements(apiObj, function(children) {
          item.children = children;
          item.loadingChildren = false;
          _.each(item.children, function(child) {
            child.isChecked = true;
            selectItem(child);
          });
        });
      } else if (item.isChecked && item.hasChild && item.children) {
        _.each(item.children, function(child) {
          child.isChecked = true;
          selectItem(child);
        });
      } else if (!item.isChecked && item.hasChild && item.children) {
        _.each(item.children, function(child) {
          child.isChecked = false;
          selectItem(child);
        });
      }
    }
  }

  /**
   * @description Adds the selected items to the vm.selectedObjects array.
   * @function
   * @param {Object} item Selected item
   */
  function pushSelectedItems(item) {
    let found = _.find(vm.selectedObjects, {
      _id: item._id
    });
    if (!found) {
      vm.selectedObjects.push(item);
    }
  }

  /**
   * @description uncheck and remove element element
   * @function
   * @param {Object} item Selected item
   */
  function uncheck(item) {
    if (Array.isArray(vm.selectedObjects)) {
      vm.selectedObjects = vm.selectedObjects.reduce((arr, obj) => {
        if (obj._id === item._id) {
          obj.isChecked = false;
        } else {
          arr.push(obj);
        }
        return arr;
      }, []);
    }
  }

  /**
   * @description Removes the item from the given array.
   * @function
   * @param {Array} array Array of items
   * @param {Object} element
   */
  function removeObj(arr, item) {
    for (let i = arr.length; i--; ) {
      if (arr[i]._id === item) {
        arr.splice(i, 1);
      }
    }
  }

  /**
   * @description Closes the children of the item.
   * @function
   * @param {Object} item Item to be closed
   */
  function close(item) {
    item.opened = false;
  }

  /**
   * @description Adds icons energy source types that do not have them.
   * @function
   * @param {Array} treeElements Items to which the icons are to be added.
   */
  function addIconToEnergySourceType(treeElements) {
    treeElements.forEach(function(treeElement) {
      if (!treeElement.icon) {
        treeElement.icon = {
          color: null,
          type: 3,
          name: 'fa-question-circle'
        };
      }
    });
  }
}
