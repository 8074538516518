BusinessPartnerModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function BusinessPartnerModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'business-partners'
  };

  var crawlerConfiguration = {
    entity: 'business-partners',
    method: 'read',
    populate: 'address'
  };

  var crawlerConfigurationItemView = {
    entity: 'business-partners',
    method: 'read',
    populate: 'address,parentId'
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      listWithAddress: CrawlerMethods.crawler(crawlerConfiguration),
      readItemView: CrawlerMethods.crawler(crawlerConfigurationItemView)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default BusinessPartnerModel;
