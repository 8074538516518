import './sfe-address.scss';
import template from './sfe-address.component.html';
/**
 * @ngdoc directive
 * @name common.sfeAddress
 * @description directive that gets address based on coordinates, or gets coordinate from address.
 * @param {object} model - data about the location passed to the directive
 * @param {boolean} noAddress - tells the directive to show address
 * @param {boolean} disable - tells the directive if it should be disabled
 * @param {boolean} coordinatesRequired - tells the directive is coordinates are required. If the value is undefined it defaults to true
 * @param {object} config - directive view configurations
 * @param {boolean} edit - used to determine if you should use default coordinates (if edit == false)
 * @example
 * <sfe-address
 *   model="vm.data.address"
 *   noAddress="vm.noAddress"
 *   disable="vm.isDisabled"
 *   coordinatesRequired=""
 *   config="vm.configuration.address"
 *   edit="vm.editMode"
 * ></sfe-address>
 */
export default {
  restrict: 'E',
  template,
  bindings: {
    model: '=',
    noAddress: '<',
    disable: '<',
    coordinatesRequired: '<',
    config: '<',
    edit: '<'
  },
  controller: SfeAddressController,
  controllerAs: 'vm',
  bindToController: true
};

SfeAddressController.$inject = [
  'gettextCatalog',
  'NgMap',
  'TranslationService',
  'AlertingService',
  '$timeout',
  'AddressAutoCompleteService',
  '$scope',
  'SfeAddressHelper',
  'gettext'
];

function SfeAddressController(
  gettextCatalog,
  NgMap,
  TranslationService,
  AlertingService,
  $timeout,
  AddressAutoCompleteService,
  $scope,
  SfeAddressHelper,
  gettext
) {
  const vm = this;
  vm.randomHash = Math.random()
    .toString(36)
    .substring(2);
  vm.slowInit = false;
  vm.zoomLevel = 8;
  vm.countryOptions = [];
  vm.defaultY = 46.1;
  vm.defaultX = 14.71;
  vm.toggleAddress = toggleAddress;
  vm.search = {};
  vm.initialized = false;
  vm.isGoogleCompleting = false;
  vm.firstLoad = true;
  vm.setAddressTitle = gettextCatalog.getString('set from address');
  vm.center = vm.defaultY + ',' + vm.defaultX;
  const cancelModelWatcher = $scope.$watch('vm.model', () => {
    if (vm.edit) {
      cancelModelWatcher();
    } else {
      if (typeof vm.model == 'object' && vm.model != null) {
        vm.model.geolocationX = vm.model.geolocationX || vm.defaultX;
        vm.model.geolocationY = vm.model.geolocationY || vm.defaultY;
        cancelModelWatcher();
      } else {
        vm.model = {};
      }
    }
  });

  vm.alertMessage = gettextCatalog.getString(
    'Both coordinates must be selected in order to save them.'
  );
  var geolocationXWatcher = $scope.$watch('vm.model.geolocationX', newVal => {
    if (
      (newVal === null && vm.model.geolocationY) ||
      (newVal && !vm.model.geolocationY)
    ) {
      vm.alert = true;
    } else {
      vm.alert = false;
    }
    if (vm.model && vm.model.geolocationY && vm.model.geolocationX) {
      vm.center = vm.model.geolocationY + ',' + vm.model.geolocationX;
    }
  });

  var geolocationYWatcher = $scope.$watch('vm.model.geolocationY', newVal => {
    if (
      (newVal === null && vm.model.geolocationX) ||
      (newVal && !vm.model.geolocationX)
    ) {
      vm.alert = true;
    } else {
      vm.alert = false;
    }
    if (vm.model && vm.model.geolocationY && vm.model.geolocationX) {
      vm.center = vm.model.geolocationY + ',' + vm.model.geolocationX;
    }
  });

  $scope.$on('$destroy', () => {
    if (cancelModelWatcher) {
      cancelModelWatcher();
    }
    if (geolocationXWatcher) {
      geolocationXWatcher();
    }
    if (geolocationYWatcher) {
      geolocationYWatcher();
    }

    google.maps.event.removeListener(resizeListener);
  });

  vm.$onInit = async () => {
    try {
      vm.slowInit = true;
      const map = await NgMap.getMap('map1-' + vm.randomHash);
      vm.map = map;
      if (vm.model.geolocationY && vm.model.geolocationX && vm.map) {
        moveMap(false, vm.model.geolocationY, vm.model.geolocationX);
      }
      vm.actions = createActionArray(vm.config);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  let resizeListener = google.maps.event.addDomListener(
    window,
    'resize',
    () => {
      if (vm.model.geolocationX && vm.model.geolocationY) {
        moveMap(false, vm.model.geolocationY, vm.model.geolocationX);
      }
    }
  );
  /**
   * @description checks if the country where the coordinates are in is in GIS (slovenia)
   * @function
   * @param {boolean} firstInit - tells us if this is the first time directive has been initialized
   */
  vm.checkIfSlovenia = () =>
    vm.model && vm.model.country && vm.model.country.numericCode === '705';

  function checkIfGISCountry(firstInit) {
    if (
      !firstInit &&
      (!vm.model.country ||
        (vm.model.country &&
          !vm.model.country.numericCode === '705' &&
          !vm.model.country.__do__not__reset__))
    ) {
      vm.clear(true, true);
    }
  }

  vm.countryAutocomplete = {
    codelist: 'countries',
    entity: 'countries',
    dialogConfiguration: {
      multiple: false
    },
    floatingLabel: gettextCatalog.getString('Select Country'),
    searchParamName: 'name',
    edit: vm.edit,
    change: () => {
      let firstInit;
      if (vm.model) {
        if (typeof vm.model.country === 'number') {
          firstInit = true;
          vm.model.country = TranslationService.GetCollectionById(
            'codelists.countries',
            vm.model.country
          );
        }
        checkIfGISCountry(firstInit);
      }
    }
  };

  /**
   * @description creates address from passed data (from data acquired using coordinates passed to google api)
   * @function
   * @param {object} address - address data
   */
  function setAddressFromGoogleApi(address) {
    if (address.googleApiCountryShortName) {
      const countries = TranslationService.GetCollection('codelists.countries');

      const country = countries.find(
        country =>
          country.ianaCountryCode
            .toLowerCase()
            .indexOf(address.googleApiCountryShortName.toLowerCase()) === 0
      );
      if (country) {
        vm.model.country = country;
        vm.model.country.__do__not__reset__ = true;
      }
    }

    vm.model.streetName = address.streetName;
    vm.model.postalNumber = address.postalNumber;
    vm.model.postName = address.postName;
    vm.model.houseNumber = address.houseNumber;
    if (address.locality) {
      vm.model.settlementName = address.locality.toUpperCase();
    }
  }
  /**
   * @description function that pans a specific map or sets a new center.
   * @function
   * @param {string} mapName - name of the map being moved (street or satellite map)
   * @param {boolean} pan - tells us if are we panning the map or setting center
   * @param {number} lat - latitude coordinate
   * @param {number} lng -  longitude coordinate
   */
  async function moveMap(pan, lat, lng) {
    const coordinates = new google.maps.LatLng(lat, lng);
    try {
      if (pan) {
        vm.map.panTo(coordinates);
      } else {
        vm.map.setCenter(coordinates);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  /**
   * @description function that changes coordinates when map market is called
   * @function
   * @param {object} e - information about the position of the marker
   */
  vm.dragMarker = e => {
    moveMap(true, e.latLng.lat(), e.latLng.lng());
    vm.model.geolocationX = e.latLng.lng();
    vm.model.geolocationY = e.latLng.lat();
  };

  /**
   * @description function that gets address based on input coordinates
   * @function
   */
  vm.getAddressFromCoordinates = async () => {
    vm.clear(true);
    vm.isGoogleCompleting = true;
    let address;
    let street;
    let settlement;
    let settlementObj;
    let community;
    let streets;
    try {
      const latLng = {
        lat: parseFloat(vm.model.geolocationY),
        lng: parseFloat(vm.model.geolocationX)
      };

      const addressObject = await AddressAutoCompleteService.getAddressByCoordinates(
        latLng
      );
      address = addressObject;
      if (address.locality) {
        settlement = await AddressAutoCompleteService.getSettlements(
          address.locality
        );
      }
      if (settlement && Array.isArray(settlement) && settlement.length > 0) {
        settlementObj = SfeAddressHelper.createSettlementObject(
          settlement,
          address.locality
        );
        if (settlementObj && settlementObj.drz === '705') {
          var country = TranslationService.GetCollection(
            'codelists.countries'
          ).find(item => item.numericCode === '705');
          try {
            streets = await AddressAutoCompleteService.getStreets(
              address.streetName,
              settlementObj.sif1,
              settlementObj.sif2
            );
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
          }
        } else {
          setAddressFromGoogleApi(address);
          vm.isGoogleCompleting = false;
        }
      } else {
        setAddressFromGoogleApi(address);
        vm.isGoogleCompleting = false;
      }
      if (streets && streets.length > 0) {
        street = streets[0];
        try {
          [community] = await AddressAutoCompleteService.getCommunity(
            street.sif1
          );
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
      if (country) {
        vm.model.country = country;
        vm.model.country.__do__not__reset__ = true;
      }
      if (street) {
        vm.model.communityCode = street.sif1;
        vm.model.settlementCode = street.sif2;
        vm.model.streetCode = street.sif3;
        vm.model.streetName = street.naz;
      }
      if (community) {
        vm.model.communityName = community.naz;
      }
      if (settlementObj) {
        vm.model.settlementName = settlementObj.naz;
      }
      if (address) {
        vm.model.houseNumber = address.houseNumber;
        vm.model.postalNumber = address.postalNumber;
        vm.model.postName = address.postName;
      }
    } catch (err) {
      AlertingService.Error(
        gettextCatalog.getString(
          'No address found at this location. Set it manually.'
        )
      );
    } finally {
      $scope.$evalAsync();
      vm.isGoogleCompleting = false;
    }
  };
  /**
   * @description Gets coordinates from currently input address
   * @function
   */
  vm.getCoordinatesFromAddress = async () => {
    var obj = {
      streetName: vm.model.streetName,
      houseNumber: vm.model.houseNumber,
      postalNumber: vm.model.postalNumber,
      postName: vm.model.postName
    };
    try {
      const coor = await AddressAutoCompleteService.getCoordinatesFromAddress(
        obj
      );
      vm.model.geolocationX = coor.x;
      vm.model.geolocationY = coor.y;
      $scope.$evalAsync();
    } catch (err) {
      AlertingService.Error(
        gettextCatalog.getString(
          'No results found for your address. Set coordinates manually.'
        )
      );
    }
  };
  /**
   * @description Auto changes data when municipality is input.
   * @function
   * @param {object} community - municipality information
   */
  vm.fillFromCommunity = community => {
    if (community) {
      if (community.sif1) {
        vm.model.communityCode = community.sif1;
        vm.model.communityName = community.naz;
      }
    } else {
      vm.model.communityCode = undefined;
    }

    if (!vm.isGoogleCompleting) {
      vm.model.settlementName = undefined;
      vm.search.settlementNameSearch = undefined;
      vm.model.settlementCode = undefined;
      vm.model.streetName = undefined;
      vm.search.streetNameSearch = undefined;
      vm.model.streetCode = undefined;
      vm.model.houseNumber = undefined;
      vm.search.houseNumberSearch = undefined;
      vm.model.postalNumber = undefined;
      vm.search.postalNumberSearch = undefined;
      vm.model.postName = undefined;
      vm.search.postNameSearch = undefined;
    }
  };
  /**
   * @description Auto changes data when settlement is input.
   * @function
   * @param {dataType} settlement - settlement information
   */
  vm.fillFromSettlement = settlement => {
    if (settlement) {
      if (settlement.sif2) {
        vm.model.settlementCode = settlement.sif2;
        vm.model.settlementName = settlement.naz;
      }
    } else {
      vm.model.settlementCode = undefined;
    }

    if (!vm.isGoogleCompleting) {
      vm.model.streetName = undefined;
      vm.search.streetNameSearch = undefined;
      vm.model.streetCode = undefined;
      vm.model.houseNumber = undefined;
      vm.search.houseNumberSearch = undefined;
      vm.model.postalNumber = undefined;
      vm.search.postalNumberSearch = undefined;
      vm.model.postName = undefined;
      vm.search.postNameSearch = undefined;
    }
  };
  /**
   * @description Auto changes data when street name is input.
   * @function
   * @param {object} street - settlement information
   */
  vm.fillFromStreet = street => {
    if (street) {
      if (street.sif3) {
        vm.model.streetCode = street.sif3;
        vm.model.streetName = street.naz;
      }
    } else {
      vm.model.streetCode = undefined;
      vm.model.postalNumber = undefined;
      vm.search.postalNumberSearch = undefined;
      vm.model.postName = undefined;
      vm.search.postNameSearch = undefined;
    }
    if (!vm.isGoogleCompleting) {
      vm.model.houseNumber = undefined;
    }
  };
  /**
   * @description Auto changes data when house number is input.
   * @function
   * @param {object} house - house number information
   */
  vm.fillFromHouseNumber = async house => {
    if (house && house.sfy && house.sfx) {
      vm.model.houseNumber = house.naz;
      vm.model.geolocationX = house.sfy;
      vm.model.geolocationY = house.sfx;
      try {
        const post = await AddressAutoCompleteService.getPosts(house.ptt);
        vm.model.postalNumber = post[0].sif1;
        vm.model.postName = post[0].naz;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  };
  /**
   * @description Auto changes data when post number is input.
   * @function
   * @param {object} post - post number information
   */
  vm.fillFromPost = post => {
    if (post && post.naz) {
      vm.model.postName = post.naz;
      vm.model.postalNumber = post.sif1;
    }
  };
  /**
   * @description lists all municipalities that match search parameter
   * @function
   * @param {string} search - search parameter by which municipalities are being searched for.
   * @return {promise}
   */
  vm.listCommunities = search =>
    SfeAddressHelper.listFilteredSfeAddressItem(search, 'getCommunities');
  /**
   * @description lists all settlements that match search parameter
   * @function
   * @param {string} search - search parameter by which settlements are being searched for.
   * @param {string} communityCode - code used to identify community.
   * @return {promise}
   */
  vm.listSettlements = (search, communityCode) =>
    SfeAddressHelper.listFilteredSfeAddressItem(search, 'getSettlements', [
      communityCode
    ]);
  /**
   * @description lists all addresses that match search parameter
   * @function
   * @param {string} search - search parameter by which addresses are being searched for.,
   * @param {string} communityCode - code used to identify community.
   * @param {string} settlementCode - code used to identify the settlement.
   * @return {promise}
   */
  vm.listAddresses = (search, communityCode, settlementCode) =>
    SfeAddressHelper.listFilteredSfeAddressItem(search, 'getStreets', [
      communityCode,
      settlementCode
    ]);
  /**
   * @description lists all house numbers that match search parameter
   * @function
   * @param {string} search - search parameter by which house numbers are being searched for.
   * @param {string} communityCode - code used to identify community.
   * @param {string} settlementCode - code used to identify the settlement.
   * @param {string} streetCode - code used to identify the streetCode.
   * @return {promise}
   */
  vm.listHouseNumbers = (search, communityCode, settlementCode, streetCode) =>
    SfeAddressHelper.listFilteredSfeAddressItem(search, 'getHouseNumbers', [
      communityCode,
      settlementCode,
      streetCode
    ]);
  /**
   * @description lists all house post numbers that match search parameter
   * @function
   * @param {string} search - search parameter by which post numbers are being searched for.
   * @return {promise}
   */
  vm.listPostalNumbers = search =>
    new Promise(async (resolve, reject) => {
      try {
        const posts = await AddressAutoCompleteService.getPosts(search, null);
        resolve(posts.sort((a, b) => a.sif1 - b.sif1));
      } catch (err) {
        reject(err);
      }
    });
  /**
   * @description lists all house post numbers that match search parameter
   * @function
   * @param {string} search - search parameter by which post names are being searched for.
   * @return {promise}
   */
  vm.listPostNames = search =>
    new Promise(async (resolve, reject) => {
      try {
        const posts = await AddressAutoCompleteService.getPosts('', search);
        resolve(posts);
      } catch (err) {
        reject(err);
      }
    });
  /**
   * @description clear selected address/coordinates
   * @function
   * @param {boolean} keepCoordinates - tells us if we want to clear the coordinates
   * @param {boolean} keepCountry - tells us, if we want to clear selected country
   * @return {promise}
   */
  vm.clear = (keepCoordinates, keepCountry) => {
    if (!keepCountry) {
      vm.model.country = null;
    }
    vm.model.communityName = null;
    vm.model.communityCode = null;
    vm.model.settlementName = null;
    vm.model.settlementCode = null;
    vm.model.streetName = null;
    vm.model.streetCode = null;
    vm.model.houseNumber = null;
    vm.model.postName = null;
    vm.model.postalNumber = null;
    vm.search = {};
    if (!keepCoordinates) {
      vm.model.geolocationY = 46.1;
      vm.model.geolocationX = 14.71;
    }
  };

  /**
   * @description toggles showing of address on directive
   * @function
   */
  function toggleAddress() {
    vm.noAddress = !vm.noAddress;
    vm.model.hasAddress = !vm.noAddress;
    vm.setAddressTitle = vm.noAddress
      ? gettextCatalog.getString('set from address')
      : gettextCatalog.getString('hide address');
  }
  /**
   * @description creates an action array containing the buttons that are to appear as actions
   * @function
   * @param {Object} config - object containing special configurations for the component
   * @return {Array} array containing all the actions
   */
  function createActionArray(config) {
    const actions = [];

    actions.push({
      title: gettext('Set coordinates'),
      fn: vm.getCoordinatesFromAddress,
      disabledFn: () =>
        !vm.model.hasAddress ||
        vm.disable ||
        !vm.model.streetName ||
        !vm.model.houseNumber ||
        !vm.model.postalNumber ||
        !vm.model.postName,
      color: 'primary',
      raised: true
    });
    if (!config || (config && !config.noSetAddress)) {
      actions.push({
        title: gettext('Set address'),
        fn: vm.getAddressFromCoordinates,
        disabledFn: () =>
          !vm.model.hasAddress || vm.disable || vm.isGoogleCompleting,
        color: 'primary',
        raised: true
      });
      actions.push({
        title: gettext('Clear'),
        fn: vm.clear,
        disabledFn: () =>
          !vm.model.hasAddress || vm.disable || vm.isGoogleCompleting,
        color: 'primary',
        raised: true
      });
    }
    return actions;
  }
}
