import timeSeriesActions from '../../../redux/time-series/action/times-series.action';

/**
 * @ngdoc service
 * @name common.RulesAndAlarmsTimeSeriesFormConfiguration
 * @description Opens form dialog
 */

RulesAndAlarmsTimeSeriesFormConfiguration.$inject = [
  'gettextCatalog',
  'gettext',
  '$ngRedux',
  '$mdDialog',
  'TimeSeriesModel',
  'AlertingService'
];
export default function RulesAndAlarmsTimeSeriesFormConfiguration(
  gettextCatalog,
  gettext,
  $ngRedux,
  $mdDialog,
  TimeSeriesModel,
  AlertingService
) {
  /**
   * @description returns configuration to open responsible person dialog.
   * @function
   * @param {Object} item item
   * @return {Object}
   */
  function get(item) {
    //CONFIGURATION
    let config = {
      name: 'rulesAndAlarms',
      title: gettextCatalog.getString('Rules and alarms'),
      actions: [
        {
          //SAVE ACTION
          title: gettext('Save'),
          fn: async form => {
            const values = form.getValues();
            let rules = [];
            if (Array.isArray(values.writeCancelRules)) {
              rules = values.writeCancelRules.map(ruleItem => {
                let alarms = [];
                if (Array.isArray(ruleItem.alarms)) {
                  alarms = ruleItem.alarms.map(alarmItem => alarmItem._id);
                }
                return {
                  alarms,
                  integrityValue: ruleItem.integrityValue,
                  rule: ruleItem.rule._id
                };
              });
            }
            let body = {
              rules
            };

            try {
              await TimeSeriesModel.update({ id: item._id }, body);
              const params = {
                query: { id: item._id },
                result: {
                  data: {
                    rules: values.writeCancelRules
                  }
                }
              };

              let action = timeSeriesActions.updateTimeSeriesProps(params);
              $ngRedux.dispatch(action);
              $mdDialog.hide();
            } catch (err) {
              AlertingService.Error(err);
            }
            $mdDialog.hide();
          },
          color: 'primary'
        }
      ],
      fields: [
        {
          id: 'writeCancelRules',
          title: gettextCatalog.getString('Write cancel rules'),
          type: {
            name: 'ruleAlarm',
            options: {
              showIntegrity: true
            }
          },
          initialize: () => {
            return item != null ? item.rules : null;
          },
          required: false
        }
      ]
    };
    return config;
  }
  return {
    get
  };
}
