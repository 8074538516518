/**
 * @ngdoc directive
 * @name common.sfeFocus
 * @description autofocuses html element
 * @param {boolean} trigger - tells us if we should autofocus the element.
 * @example
 * <input
 *   sfe-focus="$index === 0"
 * />
 */
export default function() {
  return {
    scope: {
      trigger: '=sfeFocus'
    },
    link: function(scope, element, attrs) {
      var focusWatcher = scope.$watch(attrs.sfeFocus, function() {
        if (scope.trigger) {
          element[0].focus();
          scope[attrs.sfeFocus] = false;
        }
      });
      scope.$on('$destroy', function() {
        if (focusWatcher) {
          focusWatcher();
        }
      });
    }
  };
}
