/**
 * @ngdoc service
 * @name common.AlertingService
 * @description Service that contains functions for creating error alerts
 * @property {function} Error - see method showStreamDialog
 * @property {function} AssignMessageFn - see method AssignMessageFn
 */

AlertingService.$inject = ['utilService', 'EkErrorService'];
export default function AlertingService(utilService, EkErrorService) {
  let messageFn = null;
  /**
   * @memberof common.AlertingService
   * @description Creates an error massage that is then passed.
   * @param {Object} err - the error object
   */
  function showStreamDialog(err) {
    if (
      !err ||
      (err !== null &&
        typeof err === 'object' &&
        err.config &&
        err.config.timeout &&
        err.config.timeout.isGloballyCancelled) ||
      err.status == 401 ||
      isProcessingError(err) ||
      err.doNotAlert
    ) {
      return;
    } else if (err instanceof Error) {
      // eslint-disable-next-line no-console
      console.error(err);
      return;
    }
    let newErr = {
      description: null,
      errorCode: null,
      message: null,
      type: null,
      path: null,
      method: null,
      methodColor: null,
      dataExpanded: false
    };

    if (typeof err === 'string' || err instanceof String) {
      newErr.description = 'Custom';
      newErr.message = err;
    } else if (err !== null && typeof err === 'object' && !Array.isArray(err)) {
      newErr.path = GetPathFromError(err);
      newErr.message = GetErrorMessage(err);

      if (err.config && err.config.method) {
        newErr.method = err.config.method;
      }
      newErr.description = err.statusText || 'Unknown';

      newErr.errorCode = Number(err.status);
      if (isNaN(newErr.errorCode)) {
        newErr.errorCode = null;
      }

      if (err.data && err.data.error) {
        if (err.data.error.type) {
          newErr.type = err.data.error.type;
        }
      } else if (typeof err.type === 'string') {
        newErr.type = err.type;
      }
    } else if (Array.isArray(err) && typeof messageFn === 'function') {
      err.forEach(value => {
        messageFn(
          value.message,
          value.description || 'Unknown',
          value.errorCode,
          new Date(),
          value.path
        );
      });
    } else {
      newErr.description = 'Unknown';
      newErr.message = 'Unknown malfunction.';
    }

    if (
      typeof messageFn !== 'undefined' &&
      messageFn !== null &&
      !Array.isArray(err)
    ) {
      messageFn(
        newErr.message,
        newErr.description,
        newErr.errorCode,
        new Date(),
        newErr.path
      );
    }
  }
  /**
   * @description indicates if error is a value processing error.
   * @function
   * @param {Object} err
   * @return {Boolean}
   */
  function isProcessingError(err) {
    if (err != null) {
      let { data } = err;
      if (data != null) {
        let { error } = data;
        const errorRegex = /Stream configuration with id=[a-f\d]{24} does not exist!/g;
        return typeof error == 'string' && errorRegex.test(error);
      }
    }
    return false;
  }
  /**
   * @description creates an error message
   * @param {Object} - the error object
   * @return {string}
   */
  function GetErrorMessage(err) {
    let message = 'No message.';
    if (err?.data?.error?.code?.match(/EK\d+/)) {
      message = EkErrorService.getLocale(
        err.data.error.code,
        err.data.error.params
      );
    } else if (
      err.data &&
      err.data.error &&
      err.data.error.message &&
      err.data.error.message.length > 0
    ) {
      message = err.data.error.message;
    } else if (err.data && err.data.message && err.data.message.length > 0) {
      message = err.data.message;
    } else if (err.status === 404) {
      message = 'Item ' + GetItemFromError(err) + ' couldn\'t be found.';
    } else if (typeof err.message === 'string') {
      message = err.message;
    }
    return message;
  }
  /**
   * @description Gets model name of error
   * @param {Object} - the error objects
   * @return {string}
   */
  function GetItemFromError(err) {
    if (err.config && err.config.url) {
      let url = err.config.url;
      return url.substr(
        url.lastIndexOf('/') + 1,
        url.indexOf('?access_token=') - url.lastIndexOf('/') - 1
      );
    } else {
      return null;
    }
  }
  /**
   * @description Gets path of the model on which the error happened.
   * @param {Object} err - error object
   * @return {string}
   */
  function GetPathFromError(err) {
    if (err.config && err.config.url) {
      let url = err.config.url;
      let chopped = url.split('/');
      let firstPart = chopped[chopped.length - 2];
      return (
        firstPart +
        ' / ' +
        url.substr(
          url.lastIndexOf('/') + 1,
          url.indexOf('?access_token=') - url.lastIndexOf('/') - 1
        )
      );
    } else {
      return null;
    }
  }
  /**
   * @memberof common.AlertingService
   * @description Assigns a function to messageFn
   * @param {function} fn - function that is assigned to messageFn
   */
  function AssignMessageFn(fn) {
    messageFn = fn;
  }

  function devWarn(err) {
    const env = utilService.getEnv();
    if (env == 'tis_fe_development') {
      // eslint-disable-next-line no-console
      console.log(`%c ${err}`, 'background: #d41e57; color: #fff');
    }
  }
  return {
    Error: showStreamDialog,
    AssignMessageFn: AssignMessageFn,
    devWarn
  };
}
