import { RouteManager, gettext } from '../routes';

import AlarmDefinitionView from './pages/alarm-definition/alarm-definition.view.html';
import MappingRuleView from './pages/mapping-rule/mapping-rule.view.html';
import NewMappingRuleView from './pages/mapping-rule/new-mapping-rule.view.html';
import RuleView from './pages/rule/rule.view.html';
import NewRuleView from './pages/rule/new-rule.view.html';
import SOPDetailsView from './pages/sop/sop-details.view.html';
import StepDetailsView from './pages/step/step-details.view.html';
import SfeListView from '../common/components/sfe-list/sfe-list.view.html';
import NewFormBasicView from '../common/views/new-form-basic.view.html';
import ItemHeaderView from '../common/views/item-header.view.html';

loadMath.$inject = ['loadAssets'];
function loadMath(loadAssets) {
  return loadAssets(['math']);
}

loadEntity.$inject = ['$transition$', 'StateResolveService'];
function loadEntity($transition$, StateResolveService) {
  return StateResolveService.fetchEntityForView($transition$);
}

let AlarmRouteManager = new RouteManager();

AlarmRouteManager
  /* ***** ALARMS **** */
  // DASHBOARD for ALARM MODULE
  .state('alarms-and-rules', {
    url: '/alarms-and-rules',
    isMainMenuItem: true,
    pageTitle: gettext('Alarms and Rules'),
    icon: 'warning',
    redirectTo: 'alarm-alarm-list',
    menuIndex: 10,
    theme: 'tango',
    accessScopeFe: 'tis_fe:alarm-module',
    accessScopeBe: 'tis_fe:alarm-module'
  })
  /* **** ALARM DEFINITION **** */
  // LIST OF ALARM DEFINITIONS
  .state('alarms-and-rules-alarm-definitions-list', {
    url: '/alarms-and-rules/alarm-definitions/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Alarm Definitions'),
    icon: 'error_outline',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:alarms',
    accessScopeBe: 'tis_be:alarms',
    mode: 'list'
  })
  // CREATE ALARM DEFINITION
  .state('alarms-and-rules-alarm-definitions-new', {
    url: '/alarms-and-rules/alarm-definitions/new',
    template: NewFormBasicView,
    controller: 'NewAlarmDefinitionController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('New Alarm Definition'),
    icon: 'error_outline',
    iconMode: 'add',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:alarms',
    accessScopeBe: 'tis_be:alarms',
    mode: 'create',
    entity: 'alarms',
    resolve: {
      alarm: () => ({})
    }
  })
  // EDIT ALARM DEFINITION
  .state('alarms-and-rules-alarm-definitions-edit', {
    url: '/alarms-and-rules/alarm-definitions/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewAlarmDefinitionController',
    controllerAs: 'vm',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:alarms',
    accessScopeBe: 'tis_be:alarms',
    mode: 'update',
    entity: 'alarms',
    resolve: {
      alarm: loadEntity
    }
  })
  // DUPLICATE ALARM DEFINITION
  .state('alarms-and-rules-alarm-definitions-duplicate', {
    url: '/alarms-and-rules/alarm-definitions/{id}/duplicate',
    template: NewFormBasicView,
    controller: 'NewAlarmDefinitionController',
    controllerAs: 'vm',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:alarms',
    accessScopeBe: 'tis_be:alarms',
    mode: 'create',
    entity: 'alarms',
    resolve: {
      alarm: loadEntity
    }
  })
  // ALARM DEFINITION ITEM
  .state('alarms-and-rules-alarm-definitions-view', {
    url: '/alarms-and-rules/alarm-definitions/{id}',
    template: AlarmDefinitionView,
    controller: 'AlarmDefinitionController',
    controllerAs: 'vm',
    pageTitle: gettext('Alarm Definition'),
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:alarms',
    accessScopeBe: 'tis_be:alarms',
    mode: 'read',
    entity: 'alarms',
    resolve: {
      alarm: loadEntity
    }
  })
  /* **** SOPS **** */
  // LIST OF SOPS
  .state('alarms-and-rules-sop-list', {
    url: '/alarms-and-rules/sop/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Standard Op. Procedures'),
    isSubMenuItem: true,
    icon: 'build',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:sops',
    accessScopeBe: 'tis_be:sops',
    entity: 'sops'
  })
  // CREATE SOP
  .state('alarms-and-rules-sop-new', {
    url: '/alarms-and-rules/sop/new',
    template: NewFormBasicView,
    controller: 'NewStandardOperatingProcedureController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('New SOP'),
    icon: 'build',
    iconMode: 'add',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:sops',
    accessScopeBe: 'tis_be:sops',
    mode: 'create',
    entity: 'sops',
    resolve: {
      sop: () => ({})
    }
  })
  // EDIT SOP
  .state('alarms-and-rules-sop-edit', {
    url: '/alarms-and-rules/sop/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewStandardOperatingProcedureController',
    controllerAs: 'vm',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:sops',
    accessScopeBe: 'tis_be:sops',
    mode: 'update',
    entity: 'sops',
    resolve: {
      sop: loadEntity
    }
  })
  // DUPLICATE SOP
  .state('alarms-and-rules-sop-duplicate', {
    url: '/alarms-and-rules/sop/{id}/duplicate',
    template: NewFormBasicView,
    controller: 'NewStandardOperatingProcedureController',
    controllerAs: 'vm',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:sops',
    accessScopeBe: 'tis_be:sops',
    mode: 'create',
    entity: 'sops',
    resolve: {
      sop: loadEntity
    }
  })
  // SOP ITEM
  .state('alarms-and-rules-sop-view', {
    url: '/alarms-and-rules/sop/{id}',
    template: SOPDetailsView,
    controller: 'SOPDetailsController',
    controllerAs: 'vm',
    pageTitle: gettext('SOP'),
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:sops',
    accessScopeBe: 'tis_be:sops',
    mode: 'read',
    entity: 'sops',
    resolve: {
      sop: loadEntity
    }
  })
  /* **** STEPS **** */
  // LIST OF STEPS
  .state('alarms-and-rules-sop-steps-list', {
    url: '/alarms-and-rules/sop-steps/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('SOP Steps'),
    isSubMenuItem: true,
    icon: 'format_list_numbered',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:steps',
    accessScopeBe: 'tis_be:steps',
    entity: 'steps'
  })
  // CREATE STEP
  .state('alarms-and-rules-sop-steps-new', {
    url: '/alarms-and-rules/sop-steps/new',
    template: NewFormBasicView,
    pageTitle: gettext('New Step'),
    isSubMenuItem: true,
    icon: 'format_list_numbered',
    iconMode: 'add',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    controller: 'NewStepController',
    controllerAs: 'vm',
    accessScopeFe: 'tis_fe:steps',
    accessScopeBe: 'tis_be:steps',
    mode: 'create',
    entity: 'steps',
    resolve: {
      step: () => ({})
    }
  })
  // STEP ITEM
  .state('alarms-and-rules-sop-steps-view', {
    url: '/alarms-and-rules/sop-steps/{id}',
    template: StepDetailsView,
    controller: 'StepDetailsController',
    controllerAs: 'vm',
    pageTitle: gettext('Step Details'),
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:steps',
    accessScopeBe: 'tis_be:steps',
    mode: 'read',
    entity: 'steps',
    resolve: {
      step: loadEntity
    }
  })
  // EDIT STEP
  .state('alarms-and-rules-sop-steps-edit', {
    url: '/alarms-and-rules/sop-steps/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewStepController',
    controllerAs: 'vm',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:steps',
    accessScopeBe: 'tis_be:steps',
    mode: 'update',
    entity: 'steps',
    resolve: {
      step: loadEntity
    }
  })
  // DUPLICATE STEP
  .state('alarms-and-rules-sop-steps-duplicate', {
    url: '/alarms-and-rules/sop-steps/{id}/duplicate',
    template: NewFormBasicView,
    controller: 'NewStepController',
    controllerAs: 'vm',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:steps',
    accessScopeBe: 'tis_be:steps',
    mode: 'create',
    entity: 'steps',
    resolve: {
      step: loadEntity
    }
  })
  /* **** TASKS **** */
  // LIST OF TASKS
  .state('alarms-and-rules-sop-tasks-list', {
    url: '/alarms-and-rules/sop-tasks/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('SOP Tasks'),
    isSubMenuItem: true,
    icon: 'assignment',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:tasks',
    accessScopeBe: 'tis_be:tasks',
    entity: 'tasks'
  })
  // CRETE TASK
  .state('alarms-and-rules-sop-tasks-new', {
    url: '/alarms-and-rules/sop-tasks/new',
    template: NewFormBasicView,
    controller: 'NewTaskController',
    controllerAs: 'vm',
    pageTitle: gettext('New Task'),
    isSubMenuItem: true,
    icon: 'assignment',
    iconMode: 'add',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:tasks',
    accessScopeBe: 'tis_be:tasks',
    mode: 'create',
    entity: 'tasks',
    resolve: {
      task: () => ({})
    }
  })
  // TASK ITEM
  .state('alarms-and-rules-sop-tasks-view', {
    url: '/alarms-and-rules/sop-tasks/{id}',
    template: ItemHeaderView,
    controller: 'TaskDetailsController',
    controllerAs: 'vm',
    pageTitle: gettext('Task'),
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:tasks',
    accessScopeBe: 'tis_be:tasks',
    mode: 'read',
    entity: 'tasks',
    resolve: {
      task: loadEntity
    }
  })
  // EDIT TASK
  .state('alarms-and-rules-sop-tasks-edit', {
    url: '/alarms-and-rules/sop-tasks/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewTaskController',
    controllerAs: 'vm',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:tasks',
    accessScopeBe: 'tis_be:tasks',
    mode: 'update',
    entity: 'tasks',
    resolve: {
      task: loadEntity
    }
  })
  // EDIT TASK
  .state('alarms-and-rules-sop-tasks-duplicate', {
    url: '/alarms-and-rules/sop-tasks/{id}/duplicate',
    template: NewFormBasicView,
    controller: 'NewTaskController',
    controllerAs: 'vm',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:tasks',
    accessScopeBe: 'tis_be:tasks',
    mode: 'create',
    entity: 'tasks',
    resolve: {
      task: loadEntity
    }
  })
  // LIST OF RULES
  .state('alarms-and-rules-rules-list', {
    url: '/alarms-and-rules/rules/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Rules'),
    icon: 'check',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:rules',
    accessScopeBe: 'tis_be:rules',
    entity: 'rules'
  })
  // FORM for NEW RULE
  .state('alarms-and-rules-rules-new', {
    url: '/alarms-and-rules/rules/new',
    isSubMenuItem: true,
    template: NewRuleView,
    controller: 'NewRuleController',
    controllerAs: 'vm',
    pageTitle: gettext('New Rule'),
    icon: 'check',
    iconMode: 'add',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:rules',
    accessScopeBe: 'tis_be:rules',
    mode: 'create',
    entity: 'rules',
    resolve: {
      rule: () => ({}),
      loadScripts: loadMath
    }
  })
  // VIEW OF RULE
  .state('alarms-and-rules-rules-view', {
    url: '/alarms-and-rules/rules/{id}',
    template: RuleView,
    controller: 'RuleController',
    controllerAs: 'vm',
    pageTitle: gettext('Rule Item'),
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:rules',
    accessScopeBe: 'tis_be:rules',
    mode: 'read',
    entity: 'rules',
    resolve: {
      rule: loadEntity,
      loadScripts: loadMath
    }
  })
  // VIEW OF EDIT RULE
  .state('alarms-and-rules-rules-edit', {
    url: '/alarms-and-rules/rules/{id}/edit',
    template: NewRuleView,
    controller: 'NewRuleController',
    controllerAs: 'vm',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:rules',
    accessScopeBe: 'tis_be:rules',
    mode: 'update',
    entity: 'rules',
    resolve: {
      rule: loadEntity,
      loadScripts: loadMath
    }
  })
  // VIEW OF DUPLICATE RULE
  .state('alarms-and-rules-rules-duplicate', {
    url: '/alarms-and-rules/rules/{id}/duplicate',
    template: NewRuleView,
    controller: 'NewRuleController',
    controllerAs: 'vm',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:rules',
    accessScopeBe: 'tis_be:rules',
    mode: 'create',
    entity: 'rules',
    resolve: {
      rule: loadEntity,
      loadScripts: loadMath
    }
  })
  // LIST OF mapping RULES
  .state('alarms-and-rules-mapping-rules-list', {
    url: '/alarms-and-rules/mapping-rules/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Mapping Rules'),
    icon: 'beenhere',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:mapping-rules',
    accessScopeBe: 'tis_be:mappers',
    entity: 'mappers'
  })
  // FORM for NEW RULE
  .state('alarms-and-rules-mapping-rules-new', {
    url: '/alarms-and-rules/mapping-rules/new',
    isSubMenuItem: true,
    template: NewMappingRuleView,
    controller: 'NewMappingRuleController',
    controllerAs: 'vm',
    pageTitle: gettext('New Mapping Rule'),
    icon: 'beenhere',
    iconMode: 'add',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:mapping-rules',
    accessScopeBe: 'tis_be:mappers',
    mode: 'create',
    entity: 'mappers',
    resolve: {
      mapper: () => ({})
    }
  })
  // VIEW OF RULE
  .state('alarms-and-rules-mapping-rules-view', {
    url: '/alarms-and-rules/mapping-rules/{id}',
    template: MappingRuleView,
    controller: 'MappingRuleController',
    controllerAs: 'vm',
    pageTitle: gettext('Mapping Rule Item'),
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:mapping-rules',
    accessScopeBe: 'tis_be:mappers',
    mode: 'read',
    entity: 'mappers',
    resolve: {
      mapper: loadEntity
    }
  })
  // VIEW OF EDIT RULE
  .state('alarms-and-rules-mapping-rules-edit', {
    url: '/alarms-and-rules/mapping-rules/{id}/edit',
    template: NewMappingRuleView,
    controller: 'NewMappingRuleController',
    controllerAs: 'vm',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:mapping-rules',
    accessScopeBe: 'tis_be:mappers',
    mode: 'update',
    entity: 'mappers',
    resolve: {
      mapper: loadEntity
    }
  })
  // VIEW OF DUPLICATE RULE
  .state('alarms-and-rules-mapping-rules-duplicate', {
    url: '/alarms-and-rules/mapping-rules/{id}/duplicate',
    template: NewMappingRuleView,
    controller: 'NewMappingRuleController',
    controllerAs: 'vm',
    parentState: 'alarms-and-rules',
    theme: 'tango',
    accessScopeFe: 'tis_fe:mapping-rules',
    accessScopeBe: 'tis_be:mappers',
    mode: 'create',
    entity: 'mappers',
    resolve: {
      mapper: loadEntity
    }
  });

export default AlarmRouteManager.routes;
