SettingsController.$inject = ['gettext', '$mdDialog', 'settingsConstants'];

function SettingsController(gettext, $mdDialog, settingsConstants) {
  var vm = this;
  vm.settingsRows = [];
  vm.closeDialog = closeDialog;
  vm.header = {
    title: gettext('Settings'),
    isDialog: true,
    actions: [
      {
        icon: {
          name: 'close',
          type: 2
        },
        cancel: true
      }
    ]
  };

  init();

  function init() {
    vm.settingsRows = PrepareArray();
  }

  function PrepareArray() {
    var result = [];
    var temp = [];
    for (var x = 0; x < settingsConstants.length; x++) {
      if (x % 3 === 0 && temp.length > 0) {
        result.push(temp);
        temp = [];
      }
      temp.push(settingsConstants[x]);
    }
    if (temp.length > 0) {
      result.push(temp);
    }
    return result;
  }

  function closeDialog() {
    $mdDialog.hide();
  }
}

export default SettingsController;
