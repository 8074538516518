//TIME SERIES
import timeSeriesTab from '../../../data/tango-item-configurations/time-series/content-templates/time-series-tab.view.html';
import gisTab from '../../../data/tango-item-configurations/time-series/content-templates/gis-tab.view.html';
import companyResourcesTab from '../../../data/tango-item-configurations/time-series/content-templates/company-resources-tab.view.html';
import dynamicAttributesTab from '../../../data/tango-item-configurations/time-series/content-templates/dynamic-attributes-tab.view.html';
import privilegeManagementTab from '../../../data/tango-item-configurations/time-series/content-templates/privilege-management-tab.view.html';
import statisticsTab from '../../../data/tango-item-configurations/time-series/content-templates/statistics-tab.view.html';

export default function FormTemplates() {
  return {
    timeSeriesTab,
    gisTab,
    companyResourcesTab,
    dynamicAttributesTab,
    privilegeManagementTab,
    statisticsTab
  };
}
