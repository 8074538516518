import types from './mt-analysis-flow.type';

function readMtAnalysisFlow(payload) {
  return {
    type: types.MT_ANALYSIS_FLOW_READ,
    payload
  };
}

function updateMtAnalysisFlow(payload) {
  return {
    type: types.MT_ANALYSIS_FLOW_UPDATE,
    payload
  };
}

function createMtAnalysisFlow(payload) {
  return {
    type: types.MT_ANALYSIS_FLOW_CREATE,
    payload
  };
}

function addMtAnalysisFlow(payload) {
  return {
    type: types.MT_ANALYSIS_FLOW_ADD,
    payload
  };
}

export default {
  readMtAnalysisFlow,
  updateMtAnalysisFlow,
  createMtAnalysisFlow,
  addMtAnalysisFlow
};
