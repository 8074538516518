GenerateSharedPriceListProperties.$inject = ['gettext'];
/**
 * @ngdoc service
 * @name invoices.GenerateSharedPriceListProperties
 * @description Generates properties that are shared by both pricelist and pricelist detail headers.
 * @property {function} generateProperties
 */
function GenerateSharedPriceListProperties(gettext) {
  function returnUnknown(title) {
    return {
      title: title,
      value: gettext('Unknown'),
      type: 'simple'
    };
  }
  function generateBusinessPartnerSupplierProperty(masterPriceLIst) {
    if (
      masterPriceLIst &&
      masterPriceLIst.invoiceIssuer &&
      masterPriceLIst.invoiceIssuer._id
    ) {
      return {
        title: gettext('Issuer'),
        type: 'link',
        linkTitle: masterPriceLIst.invoiceIssuer.name,
        state: 'company-resources-business-partners-view',
        param: 'id',
        paramValue: masterPriceLIst.invoiceIssuer._id
      };
    }
    return returnUnknown(gettext('Issuer'));
  }

  function generateBusinessPartnerCustomerProperty(masterPriceList) {
    if (
      masterPriceList &&
      masterPriceList.businessPartnerCustomer &&
      masterPriceList.businessPartnerCustomer._id
    ) {
      return {
        title: gettext('Customer'),
        type: 'link',
        linkTitle: masterPriceList.businessPartnerCustomer.name,
        state: 'company-resources-business-partners-view',
        param: 'id',
        paramValue: masterPriceList.businessPartnerCustomer._id
      };
    }
    return returnUnknown(gettext('Customer'));
  }
  function generateEnergySourceTypeProperty(masterPriceList) {
    if (
      masterPriceList &&
      masterPriceList.energySourceType &&
      masterPriceList.energySourceType._id
    ) {
      return {
        type: 'simple',
        title: gettext('Energy source type'),
        value: masterPriceList.energySourceType.name,
        energyIcon: masterPriceList.energySourceType
          ? masterPriceList.energySourceType.icon
          : null
      };
    }
    return returnUnknown(gettext('Energy source type'));
  }
  function generatePriceListProperty(masterPriceList) {
    if (masterPriceList && masterPriceList._id) {
      return {
        title: gettext('Price list'),
        linkTitle: masterPriceList.name,
        state: 'invoices-price-lists-view',
        param: 'priceListId',
        paramValue: masterPriceList._id,
        type: 'link'
      };
    }
    return returnUnknown(gettext('Price list'));
  }
  function generateProperties(masterPriceList, getPriceListProperty) {
    if (getPriceListProperty) {
      return [
        generateBusinessPartnerSupplierProperty(masterPriceList),
        generateBusinessPartnerCustomerProperty(masterPriceList),
        generateEnergySourceTypeProperty(masterPriceList),
        generatePriceListProperty(masterPriceList)
      ];
    }
    return [
      generateBusinessPartnerSupplierProperty(masterPriceList),
      generateBusinessPartnerCustomerProperty(masterPriceList),
      generateEnergySourceTypeProperty(masterPriceList)
    ];
  }
  return {
    generateProperties
  };
}

export default GenerateSharedPriceListProperties;
