module.exports = {
  entity: 'rated-powers',
  path: 'rated-powers',
  name: {
    en: 'Rated Power Configuration',
    sl_SI: 'Nazivna moč'
  },
  networkModel: {
    entity: 'rated-powers',
    read: 'read'
  },
  scope: 'tis_be:rated-powers',

  dialog: {
    title: {
      en: 'Select reated power',
      sl_SI: 'Izberite Nazivno moč'
    },
    listInfo: {
      en: 'Select rated power from the list below',
      sl_SI: 'Izberite  Nazivno moč s spodnjega seznama'
    }
  }
}
