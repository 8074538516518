import template from './analysis-chart.component.html';
import dashboardTemplate from './analysis-chart-dashboard.component.html';

import './analysis-chart.scss';

/**
 * @ngdoc component
 * @name analytics.analysisChart
 * @description component that fetches analysis view and displays chart and table values.
 * @param {String} analysisId analysis id
 * @param {Object} fetchQuery analysis query object
 * @param {String} datasetCode dataset code
 * @param {String} sliceEntity entity rawFieldName that will be used to slice analysis
 * @param {String} groupEntity entity rawFieldName that will be used to group analysis
 * @param {Array} aggregations array of aggregation conditions
 * @param {String} cacheInvalidationTime invalidation time of the cache
 * @param {Array} seriesNames array of series objects
 * @param {Array} dataset array of dataset columns
 * @param {Array} boundaries array of boundaries
 * @param {bool} xAxisGroup indicates that aggragation group is displayed on x axis
 * @param {string} xAxis parameter name to display continues values
 * @param {Bool} showTable indicates if table is shown
 * @param {Number} dashboardCardSize dashboard card siz
 * @example
 * <analysis-chart
 * analysisId="'analysisId'"
 * fetchQuery="fetchQuery"
 * datasetCode="datasetCode"
 * sliceEntity="sliceEntity"
 * groupEntity="groupEntity"
 * aggregations="aggregations"
 * cacheInvalidationTime="cacheInvalidationTime"
 * seriesNames="seriesNames"
 * dataset="dataset"
 * boundaries="boundaries"
 * axisGroup="axisGroup"
 * axis="axis"
 * showTable="showTable"
 * dashboardCardSize="dashboardCardSize"
 * ></analysis-chart>
 */
export default {
  template: analysisChartTemplate,
  restrict: 'E',
  bindings: {
    analysisId: '<',
    fetchQuery: '<',
    datasetCode: '<',
    sliceEntity: '<',
    groupEntity: '<',
    aggregations: '<',
    cacheInvalidationTime: '<',
    seriesNames: '<',
    dataset: '<',
    boundaries: '<',
    axisGroup: '<',
    axis: '<',
    showTable: '<',
    dashboardCardSize: '<'
  },
  controller: analysisChart,
  controllerAs: 'vm',
  bindToController: true
};

analysisChartTemplate.$inject = ['$attrs'];
/**
 * @description Defines which template to use.
 * @function
 * @return {string} Path to correct template.
 */
function analysisChartTemplate($attrs) {
  return $attrs.dashboard ? dashboardTemplate : template;
}

analysisChart.$inject = [
  '$scope',
  'AlertingService',
  'ViewModel',
  'analysisChartConfiguration',
  'loadAssets',
  '$timeout',
  'gettextCatalog'
];

function analysisChart(
  $scope,
  AlertingService,
  ViewModel,
  analysisChartConfiguration,
  loadAssets,
  $timeout,
  gettextCatalog
) {
  const vm = this;
  let fetchedItems;
  let currentAnalysisPage;
  let AssetsAreLoaded = false;
  vm.previousSlice = previousSlice;
  vm.nextSlice = nextSlice;
  vm.switchDashboardViewMode = switchDashboardViewMode;
  const LIMIT = 10000;
  /**
   * @description triggerrs on component binding chanes. Sets dashboard card height type on card size change. Triggers analysis values fetch on fetchQuery change
   * @function
   * @param {Array} changes array of changed bindings
   */
  vm.$onChanges = function(changes) {
    vm.noQuery = true;
    if (changes.dashboardCardSize && vm.dashboardCardSize) {
      setSingleHeight();
      vm.loadingValues = true;
    }
    if (changes.fetchQuery && vm.fetchQuery) {
      // in case there where other charts destroy them from memory
      destroyAllCharts();
      vm.slicedValues = [];
      vm.tableData = null;
      vm.header = null;
      vm.tableConfig = null;
      // Check if highcharts are loaded and initiates the chart
      if (AssetsAreLoaded) {
        initiateChart();
      } else {
        loadAssets(['highcharts']).then(
          function() {
            initiateChart();
            AssetsAreLoaded = true;
          },
          function() {
            AlertingService.Error('Couldnt init highcharts.');
          }
        );
      }
    }
  };

  $scope.$on('$destroy', function() {
    destroyAllCharts();
  });

  /**
   * @description iterates over array of charts and destroys them.
   * @function
   */
  function destroyAllCharts() {
    if (vm.slicedValues && vm.slicedValues.length) {
      vm.slicedValues.forEach(function(slice) {
        destroyChart(slice);
      });
    }
  }

  /**
   * @description destroys chart.
   * @function
   * @param {Object} slice chart slice object
   */
  function destroyChart(slice) {
    if (slice.chart && slice.chart.destroy) {
      try {
        slice.chart.destroy();
        /*eslint no-empty: "error"*/
      } catch (err) {
        // continue regardless of error
      }
    }
  }

  /**
   * @description checks if chart tag with chart id exists and creates chart configuration.
   * @function
   * @param {String} chartId chart id
   * @param {Object} configuration chart configuration object
   */
  function addChartToScope(chartId, configuration) {
    if (document.getElementById(chartId) !== null) {
      vm.slicedValues[vm.selectedSliceIndex].chart = Highcharts.chart(
        chartId,
        configuration.graph
      );
      vm.graph = configuration.graph;
    }
  }
  /**
   * @description changes showCahrt flag value. If chart is to be displayed sets triggers addChartToScope.
   * @function
   */
  function switchDashboardViewMode() {
    vm.showChart = !vm.showChart;
    if (vm.showChart) {
      var slice = vm.slicedValues[vm.selectedSliceIndex];
      var chartId = 'graph' + vm.slicedValues[vm.selectedSliceIndex].randomId;
      var configuration = slice.configuration;
      $timeout(function() {
        addChartToScope(chartId, configuration);
      });
    } else {
      destroyAllCharts();
    }
  }
  /**
   * @description sets selected slice index to previous slice.
   * @function
   */
  function previousSlice() {
    vm.selectedSliceIndex = vm.selectedSliceIndex - 1;
    $timeout(fetchEntities);
  }
  /**
   * @description sets selected slice index to next slice.
   * @function
   */
  function nextSlice() {
    vm.selectedSliceIndex = vm.selectedSliceIndex + 1;
    $timeout(fetchEntities);
  }
  /**
   * @description sets loading flags, and items array and initiates view fetching.
   * @function
   */
  function initiateChart() {
    vm.loadingValues = true;
    fetchAnalysis();
    fetchedItems = [];
    currentAnalysisPage = 0;
  }
  /**
   * @description sets singleHeght and singWidth flags according to dashboard card size.
   * @function
   */
  function setSingleHeight() {
    switch (vm.dashboardCardSize) {
    case 1:
      vm.singleHeight = true;
      vm.singleWidth = true;
      break;
    case 2:
    case 4:
      vm.singleHeight = true;
      break;
    }
  }
  /**
   * @description check id dataset code exists and fetches analysis view.
   * @function
   */
  function fetchAnalysis() {
    if (!vm.datasetCode) {
      AlertingService.Error('There is no dataset code found.');
    } else {
      const method = ViewModel.custom.getMethod(
        {
          path: vm.datasetCode
        },
        'POST'
      );

      const pagination = {
        page:
          currentAnalysisPage && !vm.noPagination ? currentAnalysisPage + 1 : 1,
        limit: LIMIT
      };
      method(
        {},
        { ...vm.fetchQuery, ...pagination },
        vm.cacheInvalidationTime
      ).then(
        function(res) {
          currentAnalysisPage = res.pagination ? res.pagination.page : null;
          fetchedItems = [...fetchedItems, ...res.data];
          if (
            res.pagination &&
            currentAnalysisPage * res.pagination.per_page < res.pagination.total
          ) {
            if (currentAnalysisPage === res.pagination.page) {
              AlertingService.Error(
                gettextCatalog.getString(
                  'Something went wrong loading analysis data.'
                )
              );
            } else {
              fetchAnalysis();
            }
          } else {
            currentAnalysisPage = 0;
            vm.dataReloaded = true;
            vm.loadingValues = false;
            vm.noQuery = false;
            // set currently selected slice index
            if (fetchedItems.length > 0) {
              vm.selectedSliceIndex = 0;
              prepareValues(angular.copy(fetchedItems));
              $timeout(fetchEntities);
              vm.noData = false;
            } else {
              vm.noData = true;
            }
          }
        },
        function(err) {
          AlertingService.Error(err);
          vm.loadingValues = false;
        }
      );
    }
  }
  /**
   * @description creates slice configuraiont if slice object doesn't have configuration. And triggers rendering chart function
   * @function
   */
  function fetchEntities() {
    var slice = vm.slicedValues[vm.selectedSliceIndex];
    vm.loadingEntities = true;
    if (slice) {
      if (slice.configuration) {
        renderChartConfiguration(slice.configuration);
      } else {
        var values = vm.slicedValues[vm.selectedSliceIndex].data;
        analysisChartConfiguration
          .constructConfiguration(
            values,
            vm.aggregations,
            vm.seriesNames,
            true,
            vm.dataset,
            vm.groupEntity,
            vm.sliceEntity,
            vm.boundaries,
            vm.axisGroup,
            vm.axis,
            vm.cacheInvalidationTime
          )
          .then(function(configuration) {
            slice.configuration = configuration;
            renderChartConfiguration(configuration);
          });
      }
    } else {
      vm.loadingEntities = false;
    }
  }
  /**
   * @description sets table configuration limits according to dashboard card size (if displaye on dashboard).  Sets cahrt and table configuration and triggers addChartToScope.
   * @function
   * @param {Object} configuration chart configuration
   */
  function renderChartConfiguration(configuration) {
    var chartId = 'graph' + vm.slicedValues[vm.selectedSliceIndex].randomId;
    // only create chart if the target div is still there
    if (vm.dashboardCardSize) {
      // DASHBOARD ANALYSIS
      switch (vm.dashboardCardSize) {
      case 1: //"One horizontal unit"
        configuration.tableConfig.limit = 4;
        configuration.tableConfig.rowPerPageOptions = [4];
        break;
      case 2: //"Two horizontal units"
        configuration.tableConfig.limit = 4;
        configuration.tableConfig.rowPerPageOptions = [4];
        break;
      case 3: //"Three horizontal units "
        configuration.tableConfig.limit = 14;
        configuration.tableConfig.rowPerPageOptions = [14];
        break;
      case 4: //"Full width."
        configuration.tableConfig.limit = 4;
        configuration.tableConfig.rowPerPageOptions = [4];
        break;
      case 5: //"Full width with one additional height unit."
        configuration.tableConfig.limit = 14;
        configuration.tableConfig.rowPerPageOptions = [14];
        break;
      case 6: //"Single width with double height."
        configuration.tableConfig.limit = 14;
        configuration.tableConfig.rowPerPageOptions = [14];
        break;
      }
      vm.header = configuration.header;
      vm.tableData = configuration.tableData;
      vm.tableConfig = configuration.tableConfig;
      vm.loadingValues = false;
      vm.loadingEntities = false;
      if (vm.showChart) {
        addChartToScope(chartId, configuration);
      }
    } else {
      // ITEM VIEW ANALYSIS
      if (document.getElementById(chartId) !== null) {
        vm.slicedValues[vm.selectedSliceIndex].chart = Highcharts.chart(
          chartId,
          configuration.graph
        );
        vm.graph = configuration.graph;
        // Tukaj preverimo ali se dajo podatki tabeli
        if (vm.showTable) {
          vm.header = configuration.header;
          vm.tableData = configuration.tableData;
          vm.tableConfig = configuration.tableConfig;
        }
        vm.loadingValues = false;
      }
    }
    vm.loadingEntities = false;
  }
  /**
   * @description creates analysis chart ids. If analysis is sliced sets slice names to be displayed on navigation toolbar.
   * @function
   * @param {Array} values fetched analysis view values
   * @return {dataType}
   */
  function prepareValues(values) {
    var slicedValues = [];
    // if no sliced entity selected
    // create slice array out of all data
    if (!vm.sliceEntity) {
      vm.groupSliced = angular.copy(vm.group);
      if (!values.length) {
        vm.slicedValues = [];
      } else {
        vm.slicedValues.push({
          data: values,
          randomId: Math.random()
            .toString(36)
            .substring(2)
        });
      }
    } else {
      // create slice array
      var index = vm.aggregations.findIndex(function(item) {
        return item === vm.sliceEntity;
      });
      // vm.group is array of aggregation columns
      vm.groupSliced = angular.copy(vm.aggregations);
      vm.groupSliced.splice(index, 1);
      values.forEach(function(value) {
        if (!slicedValues[value._id[vm.sliceEntity]]) {
          slicedValues[value._id[vm.sliceEntity]] = {};
          slicedValues[value._id[vm.sliceEntity]].data = [];
          // if there are entityNames set sliceName to its value
          slicedValues[value._id[vm.sliceEntity]].name = value.entityNames
            ? value.entityNames[vm.sliceEntity]
            : undefined;
          // if sliceName wasn't set, set it to values names value
          if (!slicedValues[value._id[vm.sliceEntity]].name) {
            if (value.names[vm.sliceEntity + 'Name']) {
              slicedValues[value._id[vm.sliceEntity]].name =
                value.names[vm.sliceEntity + 'Name'];
            } else if (value._id[vm.sliceEntity]) {
              // to id value
              slicedValues[value._id[vm.sliceEntity]].name =
                value._id[vm.sliceEntity];
            } else {
              //  to unkonw value
              slicedValues[
                value._id[vm.sliceEntity]
              ].name = gettextCatalog.getString('Unknown');
            }

            if (vm.sliceEntity === 'billingDateMonth') {
              slicedValues[
                value._id[vm.sliceEntity]
              ].name = analysisChartConfiguration.mapMonth(
                value._id[vm.sliceEntity]
              );
            }
          }
        }
        slicedValues[value._id[vm.sliceEntity]].data.push(value);
        slicedValues[value._id[vm.sliceEntity]].dataReloaded = true;
      });
      vm.slicedValues = [];
      Object.keys(slicedValues).forEach(function(key) {
        slicedValues[key].randomId = Math.random()
          .toString(36)
          .substring(2);
        vm.slicedValues.push(slicedValues[key]);
      });
    }
  }
}
