LocationConfiguration.$inject = [
  'gettext',
  'gettextCatalog',
  '$q',
  'AlertingService',
  'HierarchyTreeDialog',
  'OwnershipModel',
  'TranslationService',
  'BusinessPartnerLocationModel'
];

function LocationConfiguration(
  gettext,
  gettextCatalog,
  $q,
  AlertingService,
  HierarchyTreeDialog,
  OwnershipModel,
  TranslationService,
  BusinessPartnerLocationModel
) {
  var configuration = {
    listId: 'locationListId',
    entity: {
      singular: gettext('Location'),
      plural: gettext('Locations')
    },
    api: {
      query: {
        entity: 'locations',
        method: 'custom.readWithCostCenter'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'company-resources-locations-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    cardView: true,
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'company-resources-locations-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'info_outline',
        action: function(item) {
          HierarchyTreeDialog.openDialog(item, 'locations');
        }
      },
      {
        icon: 'mode_edit',
        link: {
          state: 'company-resources-locations-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'locations',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Type'),
        param: 'typeName',
        type: 'text'
      },
      {
        title: gettext('Main Cost Centre'),
        param: 'mainCostCentreName',
        link: {
          state: 'company-resources-cost-centres-view',
          params: [
            {
              valueParam: 'mainCostCentre',
              queryParam: 'id'
            }
          ]
        },
        type: 'link'
      },
      {
        title: gettext('Address'),
        param: 'addressReadable',
        type: 'text'
      },
      {
        title: gettext('Code'),
        param: 'code',
        type: 'code'
      }
    ],
    badges: [
      {
        title: gettext('Status'),
        param: 'statusName'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        param: 'status',
        query: {
          method: 'read',
          entity: 'location-statuses'
        },
        entity: 'location-statuses',
        display: gettext('Location Status'),
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        param: 'ownership',
        query: {
          method: 'read',
          entity: 'ownerships'
        },
        entity: 'ownerships',
        display: gettext('Location Ownership'),
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        param: 'parentId',
        query: {
          method: 'read',
          entity: 'locations'
        },
        entity: 'locations',
        display: gettext('Parent Location'),
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        param: 'businessPartnerLocations.businessPartner',
        query: {
          method: 'read',
          entity: 'business-partners'
        },
        entity: 'business-partners',
        display: gettext('Location Owner'),
        type: 'searchSelect',
        refreshParameterName: 'name',
        displayList: ['code', 'name'],
        refreshObject: {
          order: 'name'
        },
        selectedParamName: '_id',
        populate: 'businessPartnerLocations'
      },
      {
        param: 'address.country',
        entity: 'countries',
        display: gettext('Location Country'),
        valueField: 'id',
        collection: TranslationService.GetCollection('codelists.countries'),
        type: 'searchSelect',
        populate: 'address'
      },
      {
        param: 'costCentreLocations.costCentre',
        query: {
          entity: 'cost-centres',
          method: 'read'
        },
        entity: 'cost-centres',
        display: gettext('Location Cost Centres'),
        displayList: [
          {
            field1: 'name',
            type: 'string'
          },
          {
            field1: 'code',
            type: 'code',
            title: 'Code'
          },
          {
            field1: 'externalCode',
            type: 'code',
            title: 'External Code'
          }
        ],
        selectedParamName: '_id',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        populate: 'costCentreLocations',
        filterParameters: {
          'costCentreLocations.isMain': true,
          'costCentreLocations.isActive': true
        }
      },
      {
        param: 'address.communityName',
        display: gettext('Community Name'),
        type: 'string',
        populate: 'address'
      },
      {
        param: 'type',
        query: {
          entity: 'location-types',
          method: 'read'
        },
        refreshParameterName: 'filter',
        selectedParamName: '_id',
        entity: 'location-types',
        display: gettext('Location Type'),
        type: 'searchSelect'
      },
      {
        param: 'address.settlementName',
        display: gettext('Settlement Name'),
        type: 'string',
        populate: 'address'
      },
      {
        display: gettext('Code'),
        param: 'code',
        type: 'number',
        min: 0,
        step: 1
      },
      {
        display: gettext('External Code'),
        param: 'externalCode',
        type: 'string'
      },
      {
        query: {
          entity: 'system-tags',
          method: 'read'
        },
        entity: 'system-tags',
        selectedParamName: '_id',
        display: gettext('System Tags'),
        param: 'systemTagEntities.systemTag',
        populate: 'systemTagEntities'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ],
    extended: {
      query: Extend,
      items: [
        {
          title: gettext('Ownership'),
          param: 'ownershipName',
          type: 'text'
        },
        {
          title: gettext('Owner'),
          param: 'ownerName',
          link: {
            state: 'company-resources-business-partners-view',
            params: [
              {
                valueParam: 'ownerId',
                queryParam: 'id'
              }
            ]
          },
          type: 'link'
        }
      ]
    }
  };

  /*
   *
   *   ENRICH
   *
   */

  function Enrich(item) {
    var deferred = $q.defer();
    item.fetchingObject['addressReadable'] = {
      fetching: true,
      success: false,
      nodata: false
    };
    item.fetchingObject['mainCostCentreName'] = {
      fetching: true,
      success: false,
      nodata: false
    };
    item.fetchingObject['statusName'] = {
      fetching: true,
      success: false,
      nodata: false
    };
    item.fetchingObject['typeName'] = {
      fetching: true,
      success: false,
      nodata: false
    };

    if (item.address != null) {
      item.addressReadable = item.address.streetName
        ? item.address.streetName
        : '';
      item.addressReadable += item.address.houseNumber
        ? ' ' + item.address.houseNumber
        : '';
      item.addressReadable += item.address.settlementName
        ? ', ' + item.address.settlementName
        : '';
      readCountry();
    } else {
      fetchNoData('addressReadable');
    }

    if (item.type != null) {
      item.typeName = item.type.name;
      fetchSuccess('typeName');
    } else {
      fetchNoData('typeName');
    }

    if (item.status != null) {
      item.statusName = item.status.name;
      fetchSuccess('statusName');
    } else {
      fetchNoData('statusName');
    }

    if (item.costCentre && item.costCentre.costCentre) {
      item.mainCostCentre = item.costCentre.costCentre._id;
  
      item.mainCostCentreName =
        `${item.costCentre.costCentre.name} (${item.costCentre.costCentre.externalCode != null ? item.costCentre.costCentre.externalCode : item.costCentre.costCentre.code != null  ? item.costCentre.costCentre.code : '/'})`;
       
      fetchSuccess('mainCostCentreName');
    } else {
      fetchNoData('mainCostCentreName');
    }

    return deferred.promise;

    function readCountry() {
      if (item.address.country) {
        var country = TranslationService.GetCollectionById(
          'codelists.countries',
          item.address.country
        );
        if (country) {
          item.addressReadable =
            item.addressReadable + (country.name ? ', ' + country.name : '');
        }
        fetchSuccess('addressReadable');
      } else {
        if (item.addressReadable) {
          fetchSuccess('addressReadable');
        } else {
          fetchNoData('addressReadable');
        }
      }
    }

    function fetchSuccess(entity) {
      item.fetchingObject[entity] = {
        fetching: false,
        success: true,
        nodata: false
      };
    }

    function fetchNoData(entity) {
      item.fetchingObject[entity] = {
        fetching: false,
        success: true,
        nodata: true
      };
    }
  }

  function Extend(item) {
    var deferred = $q.defer();
    var parallel = [ReadOwnership, ReadOwner];
    async.parallel(parallel, function() {
      deferred.resolve();
    });

    return deferred.promise;

    function ReadOwnership(callback) {
      OwnershipModel.read({
        id: item.ownership
      }).then(
        function(res) {
          item.ownershipName = res.data.name;
          callback();
        },
        function(err) {
          AlertingService.Error(err);
          item.ownershipName = gettextCatalog.getString('Unknown');
          callback();
        }
      );
    }

    function ReadOwner(callback) {
      BusinessPartnerLocationModel.custom
        .read({
          location: item._id,
          isMain: true,
          isActive: true
        })
        .then(
          function(res) {
            if (res.data && res.data.length && res.data[0].businessPartner) {
              item.ownerId = res.data[0].businessPartner._id;
              item.ownerName = res.data[0].businessPartner.name;
            }
            callback();
          },
          function(err) {
            item.owner = null;
            item.ownerName = gettextCatalog.getString('Unknown');
            callback(err);
          }
        );
    }
  }

  return configuration;
}
export default LocationConfiguration;
