import './sfe-autocomplete.scss';
import template from './sfe-autocomplete.directive.html';

/**
 * @ngdoc directive
 * @name common.sfeAutocomplete
 * @description input component with a search function
 * @param {Object} model - contains data that is selected in the autocomplete
 * @param {Object} configuration - contains configuration information for the autocomplete
 * @param {Object} configuration.query - Contains either an object with specified entity and method or a custom fetching function.
 * @param {String} configuration.codelist -The name  of the codelist collection that we want to autocomplete items from
 * @param {Array} configuration.domainValues - Array of items we want to autocomplete from
 * @param {string} configuration.entity - Name of the entity of which the config used for determining display fields is to be acquired.
 * @param {Array} configuration.displayFields - Array of fields of the fetched instances to be displayed. e.g. [name, code].
 * @param {string} configuration.floatingLabel - Label that will be floating about the autocomplete input.
 * @param {string} configuration.searchParamName - Key by which the input value will be queried. eg. input: 'Yolo', seachParamName: 'name' will make the specified query with arguments containing {name: 'Yolo'}
 * @param {Array} configuration.dialogConfiguration - Configuration for instance selector dialog. Check the item selector directive for more info.
 * @param {boolean} configuration.noDialog - Hides the option for selecting instances with the item selector directive (if set to true).
 * @param {string} configuration.selectedParam - Used for querying of the pre-defined value. Usually it's 'id'. Given this id value the preFetchAutocompleteValue will fill in the value of the instance with the given id.
 * @param {Object} configuration.createRedirect - State used for the + button action, which redirects the user to the state where he can create a new instance of this entity.
 * @param {Object} configuration.filterObject - Pre-defined filter object for the query
 * @param {Function} configuration.filterObjectFn - Returns the current value of defined parameters as an object
 * @param {Object} configuration.disabledFn - Evaluates the given function to a boolean and enables/disables the field accordingly.
 * @param {boolean} configuration.required - If set to true it invalidates the form until the field is filled with a valid value.
 * @param {boolean} edit - tells us if the field should start empty or not
 * @param {boolean} disabled - tells us if the field should be disabled
 * @param {string} name - tha name of the input field
 * @param {string} parentForm - name of the form in which the field is in
 * @param {Array} customValidation - Array (or Object) that contains dcustom validation messages
 * @example
 * <sfe-autocomplete
 * model="vm.data.autocomplete3",
 * configuration="vm.configuration",
 * edit="vm.editMode",
 * disabled="false",
 * name="'autocomplete3'",
 * parentForm="'form1'",
 * custom-validation='vm.customVlidationTypes'
 * ></sfe-autocomplete>
 */

export default [
  function sfeAutocomplete() {
    var directive = {
      restrict: 'E',
      template,
      scope: {
        model: '=?',
        configuration: '=',
        edit: '=',
        disabled: '=',
        name: '=',
        parentForm: '=',
        customValidation: '='
      },
      link: linkFn,
      controller: sfeAutocompleteController,
      controllerAs: 'vm',
      bindToController: true
    };
    return directive;

    function linkFn(scope, el, attrs, ctrl) {
      ctrl.disableNoOptions = disableNoOptions;
      ctrl.enableNoOptions = enableNoOptions;
      /**
       * @description disable select option  when no items option is displayed
       * @function
       */
      function disableNoOptions() {
        var options = document.querySelectorAll(
          '.md-autocomplete-suggestions-container'
        );
        if (options) {
          options.forEach(function(option) {
            angular.element(option).css('pointer-events', 'none');
          });
        }
        /*************
        MEMORY CLEANUP
        *************/
        options = null;
      }
      /**
       * @description applies pointer-events: all to all elements with the class md-autocomplete-suggestions-container
       * @function
       */
      function enableNoOptions() {
        var options = document.querySelectorAll(
          '.md-autocomplete-suggestions-container'
        );
        if (options) {
          options.forEach(function(option) {
            angular.element(option).css('pointer-events', 'all');
          });
        }
        /*************
        MEMORY CLEANUP
        *************/
        options = null;
      }
    }
  }
];

sfeAutocompleteController.$inject = [
  '$q',
  'AlertingService',
  '$timeout',
  'ItemSelector',
  '$scope',
  'EntitiesService',
  'TranslationService',
  'filterService',
  'gettextCatalog',
  'CrawlerMethods'
];

function sfeAutocompleteController(
  $q,
  AlertingService,
  $timeout,
  ItemSelector,
  $scope,
  EntitiesService,
  TranslationService,
  filterService,
  gettextCatalog,
  CrawlerMethods
) {
  var vm = this;
  var optionItems;
  vm.setAutocompleteDisplayValue = setAutocompleteDisplayValue;
  vm.openAutocompleteDialog = openAutocompleteDialog;
  vm.fetchAutocompleteOptions = fetchAutocompleteOptions;
  vm.changed = changed;
  vm.onBlur = onBlur;
  vm.$onInit = init;

  /**
   * @description functiom that runs at initilization of component
   * @function
   */
  function init() {
    if (vm.edit) {
      var stopWatching = $scope.$watch('vm.model', function() {
        if (vm.model) {
          preFetchAutocompleteValue();
          stopWatching();
        }
      });
    }
    $scope.$on('$destroy', function() {
      if (stopWatching) {
        stopWatching();
      }
    });
    if (
      vm.configuration &&
      vm.configuration.entity &&
      !vm.configuration.displayFields
    ) {
      var dialogConfiguration = EntitiesService.getDialog(
        vm.configuration.entity
      );
      vm.configuration.displayFields = dialogConfiguration
        ? dialogConfiguration.displayFields || ['name']
        : ['name'];
      vm.configuration.selectedParam =
        EntitiesService.getSelectedParamName(vm.configuration.entity) ||
        vm.configuration.selectedParam;
      if (typeof vm.configuration.selectedParam === 'undefined') {
        vm.configuration.selectedParam = 'id';
      }
    }
    if (vm.parentForm) {
      $scope.ItemForm = vm.parentForm;
    }
  }
  /**
   * @description function that is called when model has changed.
   * @function
   */
  function changed() {
    // when model id equls null there no items option's selected
    if (vm.model && vm.model.id === null) {
      vm.model = null;
    }
    if (typeof vm.model === 'object' && vm.configuration.change) {
      $timeout(function() {
        vm.configuration.change([vm.model]);
      });
    }
  }
  /**
   * @description function that is called when the input loses focus
   * @function
   */
  function onBlur() {
    if (optionItems && optionItems.length < 1) {
      //if there were no items, enable drop down options
      $timeout(vm.enableNoOptions);
    }
    if (vm.configuration.setFirstItemOnBlur) {
      if (optionItems && optionItems.length) {
        vm.model = optionItems[0];
      }
    }

    if (vm.configuration.onBlur) {
      vm.onBlur();
    }
  }
  /**
   * @description function that prefetches the values for autocomplete based source the of data displayed.
   * @function
   */
  async function preFetchAutocompleteValue() {
    if (!vm.model || typeof vm.model == 'object') {
      return;
    }
    if (
      vm.configuration.codelist ||
      vm.configuration.domainValues ||
      vm.configuration.getDomainValues
    ) {
      const items = vm.configuration.codelist
        ? TranslationService.GetCollection(
          'codelists.' + vm.configuration.codelist
        )
        : vm.configuration.domainValues
          ? vm.configuration.domainValues
          : await vm.configuration.getDomainValues();

      vm.model = findItem(angular.copy(items), vm.model);
      if (typeof vm.model === 'object') {
        vm.model.__prefetched__value__ = true;
      }
      changed();
    } else {
      var obj = {};
      if (vm.model._id) {
        obj[vm.configuration.selectedParam] = vm.model._id;
      } else {
        obj[vm.configuration.selectedParam] = vm.model;
      }

      if (vm.configuration.filterObjectFn) {
        obj = {
          ...obj,
          ...vm.configuration.filterObjectFn(true)
        };
      }

      var method;

      if (
        typeof vm.configuration.query === 'object' &&
        !vm.configuration.query.query
      ) {
        method = CrawlerMethods.getMethod(vm.configuration.query)(obj);
      } else {
        method = vm.configuration.query.query(obj).$promise;
      }
      try {
        const res = await method;
        if (res.data && res.data.length === 1) {
          vm.model = res.data[0];
        } else if (res.data && (res.data._id || res.data.id)) {
          vm.model = res.data;
        }

        if (typeof vm.model === 'object') {
          vm.model.__prefetched__value__ = true;
        }
        $scope.$applyAsync();
      } catch (err) {
        AlertingService.Error(err);
      }
    }
  }
  /**
   * @description Creates a string that is displayed in the autocomplete.
   * @function
   * @param {Object} item - contains data that needs to be properly formatted to be displayed
   * @return {Promise} returns for the value that will be displayed
   */
  function setAutocompleteDisplayValue(item) {
    var deferred = $q.defer();
    var displayString = '';
    if (item && vm.configuration) {
      if (!vm.configuration.displayFields) {
        vm.configuration.displayFields = ['name'];
      }
      _.each(vm.configuration.displayFields, function(displayField, index) {
        if (vm.configuration.displayFields[index].type !== 'icon') {
          if (_.isObject(displayField)) {
            switch (displayField.type) {
            case 'codelist':
              if (displayField.codelist != null) {
                let value = item[displayField.field1];
                if (
                  displayField.field2 != null &&
                    value != null &&
                    typeof value == 'object'
                ) {
                  value = value[displayField.field2];
                }
                let codeListValue = TranslationService.GetCollectionById(
                  `codelists.${displayField.codelist}`,
                  value
                );
                if (codeListValue != null) {
                  displayString += codeListValue.name;
                } else {
                  displayString += ` - ${gettextCatalog.getString(
                    'No value'
                  )}`;
                }
              } else {
                displayString += gettextCatalog.getString('No value');
              }
              break;
            default:
              displayString += item[displayField.field1]
                ? item[displayField.field1]
                : '';
              if (displayField.field2 && item[displayField.field2]) {
                displayString += ' ' + item[displayField.field2];
              }
            }
          } else {
            displayString += item[displayField] ? item[displayField] : '';
          }
          if (
            index < vm.configuration.displayFields.length - 1 &&
            (item[vm.configuration.displayFields[index + 1]] ||
              (vm.configuration.displayFields[index + 1] &&
                vm.configuration.displayFields[index + 1].field1 &&
                item[vm.configuration.displayFields[index + 1].field1]))
          ) {
            displayString += ' - ';
          }
        }
      });
    }
    deferred.resolve(displayString);
    return deferred.promise;
  }
  /**
   * @description opens the autocomplete dialog
   * @function
   */
  function openAutocompleteDialog() {
    var configuration = vm.configuration.dialogConfiguration || {};
    if (vm.configuration && vm.configuration.filterFn) {
      configuration.filterFn = vm.configuration.filterFn;
    }
    if (vm.configuration.filterObjectFn) {
      configuration.filterObjectFn = vm.configuration.filterObjectFn;
    }
    if (vm.configuration.filterObject) {
      configuration.filterObject;
    }
    ItemSelector.open([vm.configuration.entity], [configuration]).then(
      function(selected) {
        if (selected) {
          if (selected.length === 1) {
            vm.model = selected[0];
            vm.model.displayValue = setAutocompleteDisplayValue(vm.model);
          } else if (selected.length > 1) {
            if (vm.configuration.change)
              $timeout(function() {
                vm.configuration.change(selected);
              });
          }
        }
      },
      function() {}
    );
  }

  /**
   * @description Creates an item for when there are no items to display
   * @function
   * @return {object} item
   */
  function getNoItemsItem() {
    var item = {};
    var displayField = vm.configuration.displayFields
      ? vm.configuration.displayFields[0]
      : 'name';
    if (typeof displayField === 'object') {
      item[displayField.field1] = gettextCatalog.getString(
        'There are no items.'
      );
    } else {
      item[displayField] = gettextCatalog.getString('There are no items.');
    }
    item.id = null;
    return item;
  }
  /**
   * @description fetches all available options for the auotcomplete.
   * @function
   * @return {Promise}
   */
  function fetchAutocompleteOptions() {
    var deferred = $q.defer();
    if (vm.configuration.openAction) {
      vm.configuration.openAction(function() {
        fetchItems().then(function(items) {
          optionItems = items;
          if (items.length < 1) {
            $timeout(vm.disableNoOptions);
            var item = getNoItemsItem();
            deferred.resolve([item]);
          } else {
            $timeout(vm.enableNoOptions);
            deferred.resolve(items);
          }
        });
      });
    } else {
      fetchItems().then(function(items) {
        optionItems = items;
        if (items.length < 1) {
          $timeout(vm.disableNoOptions);
          var item = getNoItemsItem();
          deferred.resolve([item]);
        } else {
          $timeout(vm.enableNoOptions);
          deferred.resolve(items);
        }
      });
    }
    return deferred.promise;
  }

  /**
   * @description Fetches all the items that can be displayed.
   * @function
   * @return {Promise}
   */

  async function fetchItems() {
    if (
      vm.configuration.codelist ||
      vm.configuration.domainValues ||
      vm.configuration.getDomainValues
    ) {
      //prepare filter
      var filter = [
        {
          param: vm.configuration.searchParamName || 'name',
          searchTerm: vm.searchText,
          type: 'string'
        }
      ];
      let domainItems = [];
      if (vm.configuration.codelist) {
        domainItems = TranslationService.GetCollection(
          'codelists.' + vm.configuration.codelist
        );
      } else if (Array.isArray(vm.configuration.domainValues)) {
        domainItems = vm.configuration.domainValues;
      } else if (typeof vm.configuration.getDomainValues == 'function') {
        domainItems = await vm.configuration.getDomainValues(vm.searchText);
      }
      let items = filterService.filterDataV1(domainItems, filter);
      if (vm.configuration && vm.configuration.filterFn) {
        items = vm.configuration.filterFn(items);
      }
      return items;
    } else {
      let filter;
      if (typeof vm.configuration.filterObjectFn == 'function') {
        filter = vm.configuration.filterObjectFn();
      }
      const obj = {
        ...vm.configuration.filterObject,
        ...filter
      };

      if (vm.configuration.searchParamName && vm.searchText) {
        obj[vm.configuration.searchParamName] = vm.searchText;
      }

      var method;
      if (
        typeof vm.configuration.query === 'object' &&
        !vm.configuration.query.query
      ) {
        method = CrawlerMethods.getMethod(vm.configuration.query)(obj);
      } else {
        method = vm.configuration.query.query(obj).$promise;
      }
      try {
        const { data: items } = await method;
        if (vm.configuration && vm.configuration.filterFn) {
          return vm.configuration.filterFn(items);
        }
        return items;
      } catch (err) {
        AlertingService.Error(err);
        return [];
      }
    }
  }
  /**
   * @description function that matches a passed id with the ids of all the passed items.
   * @function
   * @param {Object} items - all the items
   * @param {string} id - id of the item we are looking for
   * @return {object} the found item
   */
  function findItem(items, id) {
    for (var i = 0; i < items.length; i++) {
      if (items[i].id == id) {
        return items[i];
      }
    }
  }
}
