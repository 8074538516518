/**
 * @ngdoc directive
 * @name moduleName.sfeMin
 * @description Function that replaces ng-min on non number type inputs. It checks if modelValue is lower than sfeMinValue. It also adds a sfeMin validator to the controller
 * @example
 * <input
 *  type="numerical"
 *  sfe-min="{{vm.input.Min}}"
 * ></input>
 */
sfeMin.$inject = [];
export default function sfeMin() {
  return {
    restrict: 'A',
    require: ['ngModel', '^form'],
    link: function(scope, element, attrs, controllers) {
      const model = controllers[0];
      /**
       * @description function that is passed to the controller validator. It checks, if the value exists and if it is higher than sfe-min
       * @function
       * @param {integer} modelValue - checks if
       * @return {boolean} - true if value is not lower than sfeMin
       */
      const sfeMinValidation = modelValue => {
        const parsedAttr = parseInt(attrs.sfeMin);
        if (typeof modelValue == 'number' && !isNaN(parsedAttr)) {
          if (modelValue < attrs.sfeMin) {
            return false;
          }
        }
        return true;
      };
      model.$validators = {
        ...model.$validators,
        sfeMin: sfeMinValidation
      };
    }
  };
}
