TimeSeriesGroupTypeConfiguration.$inject = [
  'gettext',
  '$q',
  'SfeFormDialogService',
  'ListFormService',
  'AlertingService',
  'TimeSeriesGroupTypeModel',
  'CrudToastFactory'
];

function TimeSeriesGroupTypeConfiguration(
  gettext,
  $q,
  SfeFormDialogService,
  ListFormService,
  AlertingService,
  TimeSeriesGroupTypeModel,
  CrudToastFactory
) {
  const fieldNames = ['name', 'description', 'code', 'externalCode'];
  function openCreateDialog(relistData) {
    var config = ListFormService.generateForm(
      ['name', 'description', 'code', 'externalCode'],
      false
    );
    var title = gettext('New Time Series Group Type');
    SfeFormDialogService.openSfeFormDialog(false, config, {}, title).then(
      function(object) {
        if (object) {
          create(object, relistData);
        }
      }
    );
  }

  function openUpdateDialog(item, relistData) {
    TimeSeriesGroupTypeModel.read({
      id: item._id
    }).then(
      function(res) {
        var config = ListFormService.generateForm(
          ['name', 'description', 'code', 'externalCode'],
          false
        );
        var title = gettext('Edit Time Series Group Type');
        SfeFormDialogService.openSfeFormDialog(
          true,
          config,
          res.data,
          title
        ).then(function(object) {
          if (object) {
            update(item._id, object, relistData);
          }
        });
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function create(obj, relistData) {
    TimeSeriesGroupTypeModel.create(
      ListFormService.generateBodyObject(obj, fieldNames)
    ).then(
      function() {
        CrudToastFactory.toast('create');
        relistData();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function update(id, obj, relistData) {
    TimeSeriesGroupTypeModel.update(
      {
        id: id
      },
      ListFormService.generateBodyObject(obj, fieldNames)
    ).then(
      function() {
        CrudToastFactory.toast('update');
        relistData();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  var configuration = {
    entity: {
      singular: gettext('Time Series Group Type'),
      plural: gettext('Time Series Group Types')
    },
    api: {
      query: {
        entity: 'time-series-group-types',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name'
    },
    buttons: [
      {
        title: gettext('Create'),
        fn: openCreateDialog,
        relist: true,
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        action: openUpdateDialog
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'time-series-group-types',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description')
      },
      {
        param: 'code',
        title: gettext('Code'),
        type: 'code'
      },
      {
        param: 'externalCode',
        title: gettext('External code'),
        type: 'code'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Code'),
        param: 'code',
        type: 'string'
      },
      {
        display: gettext('External Code'),
        param: 'externalCode',
        type: 'string'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      },
      {
        display: 'name DESC',
        params: '-name'
      },
      {
        display: 'name ASC',
        params: 'name'
      }
    ]
  };

  function Enrich() {
    var deferred = $q.defer();
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default TimeSeriesGroupTypeConfiguration;
