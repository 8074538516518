import template from './manual-readings.component.html';
import './manual-readings.scss';

export default {
  template,
  bindings: {
    measuringPointId: '<',
    actionType: '<',
    reloadingValues: '<',
    energySourceType: '<',
    measuringPointKind: '<',
    measuringPointParentId: '<',
    registers: '<'
  },
  controllerAs: 'vm',
  controller: ManualReadingsComponentController
};

ManualReadingsComponentController.$inject = [
  '$scope',
  'MeasuringpointcountertypelistModel',
  'gettext',
  'AlertingService',
  'loadAssets',
  '$rootScope',
  'gettextCatalog',
  'InfoDialog',
  '$mdDialog',
  'ToastService',
  'ManualReadingsHelper',
  '$timeout',
  'StandardUtils'
];

function ManualReadingsComponentController(
  $scope,
  MeasuringpointcountertypelistModel,
  gettext,
  AlertingService,
  loadAssets,
  $rootScope,
  gettextCatalog,
  InfoDialog,
  $mdDialog,
  ToastService,
  ManualReadingsHelper,
  $timeout,
  StandardUtils
) {
  var vm = this;
  let actionsDisabled = true;
  // array of manual readings timeSeries ids
  let timeSeriesIds = [];
  let selectedDate;
  let lastQueriedDate;
  let nextValidDate;
  let billingMeasuringPointId;
  let consumptionDateChangeCalled;

  let selectedScheduler;
  vm.valueChanged = valueChanged;
  vm.displayModeDetailed = false;
  vm.toggleView = toggleView;
  vm.disableConsumption = disableConsumption;

  /**
   * @description sets scope variables.
   * @function
   */
  function initializeScopeVariables() {
    switch (vm.actionType) {
    case 'manualReadings':
      // indicates ether to display new counter start value
      vm.displayChangeCounter = false;
      vm.tableHeaderConfigurations = [
        {
          title: gettextCatalog.getString('Time series name')
        },
        {
          title: gettextCatalog.getString('Counter read out')
        },
        {
          title: gettextCatalog.getString('Previous counter read out')
        },
        {
          title: gettextCatalog.getString('Consumption')
        }
      ];
      break;
    case 'counterChange':
      // indicates ether to display new counter start value
      vm.displayChangeCounter = true;
      vm.tableHeaderConfigurations = [
        {
          title: gettextCatalog.getString('Time series name')
        },
        {
          title: gettextCatalog.getString('Final Counter read out')
        },
        {
          title: gettextCatalog.getString('Previous counter read out')
        },
        {
          title: gettextCatalog.getString('Consumption')
        },
        {
          title: gettextCatalog.getString('Opening counter read out')
        }
      ];
      break;
    }

    vm.actions = [
      {
        title: gettext('save'),
        color: 'primary',
        disabledFn: () => {
          return actionsDisabled || vm.valuesForm.$invalid;
        },
        fn: () => saveManualReadings(0)
      }
    ];
  }
  /**
   * @description consumption gets disabled when there is no previous values defined.
   * when there is no previous value => previous value is defined as string  no values
   * @function
   * @param {Number} previousValue
   * @return {Boolean}
   */
  function disableConsumption(previousValue) {
    return (
      typeof previousValue == 'number' && vm.actionType !== 'counterChange'
    );
  }

  /**
   * @description Toggles between chartViews.
   * @function
   */
  function toggleView() {
    vm.displayModeDetailed = !vm.displayModeDetailed;
  }

  /**
   * @description iterates over manual readings, saves updated values and closes dialog.
   * @function
   */
  async function saveManualReadings(numberOfAttempts = 0) {
    try {
      let results;
      let manualReadings;
      try {
        vm.loadingManualReadings = true;
        setDisabledStatus(true);
        manualReadings = await ManualReadingsHelper.validateManualReadings(
          vm.manualReadings,
          nextValidDate
        );
        results = await ManualReadingsHelper.saveManualReadings(
          vm.manualReadings,
          selectedDate,
          nextValidDate,
          vm.actionType,
          vm.dataConfig.dataObj.readingDate
        );
        vm.reloadingValues = false;
      } catch (err) {
        if (numberOfAttempts < 5) {
          //if there is an error saving we w
          saveManualReadings(numberOfAttempts + 1);
        } else {
          throw err;
        }
      }
      if (results != null && manualReadings != null && results.length > 0) {
        const errorPoints = results.reduce((errors, result) => {
          if (result.uploadStatus === 'fail') {
            errors.push(result.name);
          }
          return errors;
        }, []);

        if (errorPoints.length > 0) {
          var errorMessage = gettextCatalog.getString(
            'Couldn\'t upload values for'
          );
          errorPoints.forEach(function(pointName) {
            errorMessage += ' ' + pointName;
          });
          AlertingService.Error(errorMessage);
          setDisabledStatus(false);
        } else {
          ToastService.showToast(gettext('Values were successfully uploaded'));
          // reset changed flag
          vm.manualReadings.forEach(function(manualReading) {
            manualReading.changed = false;
            manualReading.pairedTimeSeries.changed = false;
          });
          // UPDATE NEXT READINGS CONSUMPTION
          try {
            await ManualReadingsHelper.updateFutureConsumption(
              manualReadings,
              vm.dataConfig.dataObj.readingDate
            );
          } catch (err) {
            AlertingService.Error(
              gettextCatalog.getString(
                'Couldn\'t update next reading consumption try to update it manually.'
              )
            );
          }
        }
        if (
          vm.chartConfig &&
          vm.chartConfig.api &&
          vm.chartConfig.api.refresh
        ) {
          vm.chartConfig.api.refresh();
        }
        if (
          vm.detailedConfig &&
          vm.detailedConfig.api &&
          vm.detailedConfig.api.refresh
        ) {
          vm.detailedConfig.api.refresh();
        }
        if (vm.actionType == 'counterChange') {
          $mdDialog.hide();
        }
      } else {
        ToastService.showToast(gettext('No items were changed to update'));
        setDisabledStatus(false);
      }
      vm.loadingManualReadings = false;
    } catch (err) {
      AlertingService.Error(err);
      vm.loadingManualReadings = false;
      setDisabledStatus(false);
    }
  }

  function readingDateChange() {
    const thisArgs = this;
    const invalidDates =
      $scope.consumptionForm['item_0date'].$invalid ||
      $scope.readingForm['item_0date'].$invalid;
    setDisabledStatus(invalidDates);
    if ($scope.readingForm['item_0date'].$invalid) {
      return;
    }

    // IF CURRENTLY SELECTED CONSUMPTION DATE HASN'T BEEN QUERIED YET
    //  TRIGGER CONSUMPTION DATE CHANGE THAT TRIGGERS VALUES FETCH
    if (lastQueriedDate != thisArgs.dataObj.date.getTime()) {
      consumptionDateChange.apply(thisArgs);
    }
  }

  /**
   * @description date change callback. In case of any data was changed asks user if he wants to reload data
   * @function
   */
  function consumptionDateChange() {
    const invalidDates =
      $scope.consumptionForm['item_0date'].$invalid ||
      $scope.readingForm['item_0date'].$invalid;
    setDisabledStatus(invalidDates);

    if ($scope.consumptionForm['item_0date'].$invalid) {
      return;
    }
    const dataObj = this.dataObj;
    // const manualReadings = this.manualReadings;
    const scheduler = this.scheduler;
    const title = gettext('Warning');
    const changed = vm.manualReadings.reduce(
      (result, manualReading) =>
        result ||
        manualReading.changed ||
        manualReading.pairedTimeSeries.changed,
      false
    );
    /* Because of md-date-picker doesn't always trigger change function
      we have to bind validation and relist data onBlur as well
      so consumptionDateChangeCalled flag is used to indicate 
      ether dialog has already been opened
    */
    if (!consumptionDateChangeCalled) {
      // if input was changed ask user if he wants to relist values
      consumptionDateChangeCalled = true;

      if (changed) {
        var textItem = {
          text: gettext(
            'Are your sure that you want to relist values? All changed date will be lost'
          ),
          type: 'text'
        };
        var actions = [
          {
            title: gettext('Cancel'),
            fn: function() {
              dataObj.date = selectedDate;
              consumptionDateChangeCalled = false;

              $mdDialog.cancel();
            },
            color: 'primary'
          },
          {
            title: gettext('Yes'),
            fn: listValues,
            color: 'warn'
          }
        ];
        InfoDialog.open(title, null, [textItem], actions, null, false, true);
      } else {
        listValues();
      }
    }

    /**
     * @description if selected date is valid relists time series values, sets disable status to the save button and new values to manual readings.
     * @function
     */
    async function listValues() {
      vm.notValidDateError = false;
      selectedDate = dataObj.date;
      if (validateDate(dataObj.date) && timeSeriesIds.length > 0) {
        try {
          lastQueriedDate = selectedDate.getTime();
          vm.loadingManualReadings = true; //values table loader
          const {
            error,
            values
          } = await ManualReadingsHelper.validateMeasuringPointInvoices(
            billingMeasuringPointId,
            selectedDate,
            timeSeriesIds
          );
          if (error) {
            vm.notValidDateError = error;
            vm.loadingManualReadings = false;
            $scope.$applyAsync();
          } else {
            vm.notValidDateError = false;
            nextValidDate = scheduler.next(2, selectedDate)[1];

            vm.reloadingValues = true;

            setDisabledStatus(false);

            vm.manualReadings = ManualReadingsHelper.pairTimeSeriesValuesAndManualReadings(
              values,
              vm.manualReadings,
              dataObj.date
            );
            if (vm.actionType == 'changeCounter') {
              const isValid = validateInputValues();
              setDisabledStatus(!isValid);
            } else {
              setDisabledStatus(true);
            }
            if (changed) {
              $mdDialog.cancel();
            }
            vm.reloadingValues = false;
            $scope.$applyAsync();
          }
        } catch (err) {
          AlertingService.Error(err);
          vm.notValidDateError = gettextCatalog.getString('Server Error');
        }
        vm.loadingManualReadings = false; //values table loader
      } else {
        vm.notValidDateError = gettextCatalog.getString('Date is not valid');
      }
      consumptionDateChangeCalled = false;
    }
  }

  /**
   * @description sets disabled value to the save button.
   * @function
   * @param {bool} disabled disabled flag value
   */
  function setDisabledStatus(disabled) {
    actionsDisabled = disabled;
  }

  /**
   * @description returns single date sfe form configuration.
   * @function
   * @param {boolean} consumption
   * @return {Array} sfe form configuration
   */
  function getFormConfiguration(consumption) {
    const dateTitle = consumption
      ? vm.actionType == 'counterChange'
        ? gettextCatalog.getString('Counter replacement date')
        : gettextCatalog.getString('Consumption date')
      : gettextCatalog.getString('Reading date');
    const filterDates = consumption ? validateDate : undefined;
    return [
      {
        /**
         * @description filters datepicker dates.
         * @function
         * @param {date} date date picker date
         * @return {bool}
         */
        filterDates,
        componentType: 'singleDate',
        dateName: consumption ? 'date' : 'readingDate',
        timeName: consumption ? 'time' : 'readingTime',
        dateLabel: dateTitle,
        required: true,
        idDate: consumption ? 'consumption' : 'reading',
        noTimeInput: true
      }
    ];
  }

  /**
   * @description date cron validation.
   * @function
   * @param {Date} arg new date values
   * @return {Bool}
   */
  function validateDate(date) {
    const jsDate = new Date(date);
    jsDate.setHours(0, 0, 0, 0);
    return selectedScheduler.isValid(jsDate);
  }

  /**
   * @description loads later and highcharts.
   * @function
   * @return {Promise}
   */
  async function loadModules() {
    if (vm.actionType === 'manualReadings') {
      try {
        await loadAssets(['highcharts']);
        later.date.localTime();
      } catch (err) {
        throw 'Couldn\'t load later';
      }
    }
  }

  /**
   * @description calculates consumption .
   * @function
   * @param {Object} manualReading manualReading object
   */
  function calculateConsumption(manualReading) {
    if (
      typeof manualReading.previousValue === 'number' &&
      typeof manualReading.value === 'number'
    ) {
      manualReading.pairedTimeSeries.value = subtract(
        manualReading.value,
        manualReading.previousValue
      );
      manualReading.pairedTimeSeries.changed = true;
    }
  }

  const getDecimalPlaces = num => {
    const numberDecimal = num.toString().split('.');
    if (numberDecimal.length == 2) {
      return numberDecimal[1].length;
    }
    return 0;
  };

  const subtract = (num1, num2) => {
    const num1DecimalPlaces = getDecimalPlaces(num1);
    const num2DecimalPlaces = getDecimalPlaces(num2);
    const decimalPlaces = Math.max(num1DecimalPlaces, num2DecimalPlaces);
    return StandardUtils.round(num1 - num2, decimalPlaces);
  };
  /**
   * @description on change sets changed flag manual reading object.
   * @function
   * @param {Object} changedObject manual reading object
   */
  function valueChanged(changedObject) {
    if (changedObject.pairedTimeSeries) {
      calculateConsumption(changedObject);
    }
    changedObject.changed = true;
    var allValid = true;

    vm.manualReadings.forEach(function(manualReading) {
      if (
        manualReading.value == undefined ||
        manualReading.pairedTimeSeries.value == undefined ||
        (manualReading.openingValue &&
          manualReading.openingValue.value == undefined)
      ) {
        allValid = false;
      }
    });
    setDisabledStatus(!allValid);
  }

  function validateInputValues() {
    return vm.manualReadings.reduce((valid, manualReading) => {
      if (!valid) {
        return valid;
      } else {
        return (
          typeof manualReading.value != 'undefined' ||
          typeof manualReading.pairedTimeSeries.value != 'undefined'
        );
      }
    });
  }

  function validateReadingAndConsumptionDate() {
    const { dataObj } = this;
    let { date, readingDate } = dataObj;
    const valid = ManualReadingsHelper.readingAndConsumptionDatesAreValid(
      date,
      readingDate
    );
    $scope.readingForm['item_0date'].$setValidity(
      'consumptionReadingDateValidation',
      valid
    );
    $scope.consumptionForm['item_0date'].$setValidity(
      'consumptionReadingDateValidation',
      valid
    );
    return valid;
  }
  /**
   * @description loads library modules, fetches, manual readings, fetches manual reading values.
   * @function
   */
  vm.$onInit = async () => {
    initializeScopeVariables();
    try {
      await loadModules();
      vm.loadingManualReadings = true; //values table loader
      billingMeasuringPointId = await ManualReadingsHelper.getMeasuringpoint(
        vm.measuringPointId,
        vm.measuringPointKind,
        vm.measuringPointParentId
      );
      let {
        data: fetchedManualReadings
      } = await MeasuringpointcountertypelistModel.read({
        measuringPointId: vm.measuringPointId
      });

      if (fetchedManualReadings.length) {
        const {
          series,
          detailedSeries,
          timeSeries,
          manualReadings,
          scheduler
        } = await ManualReadingsHelper.constructManualReading(
          fetchedManualReadings,
          vm.registers
        );

        selectedScheduler = scheduler;
        timeSeriesIds = timeSeries;

        selectedDate = scheduler.prev();
        lastQueriedDate = selectedDate.getTime();
        const {
          error,
          values: populatedTimeSeries
        } = await ManualReadingsHelper.validateMeasuringPointInvoices(
          billingMeasuringPointId,
          selectedDate,
          timeSeries
        );

        if (error) {
          vm.notValidDateError = error;
        }
        vm.initialDataLoaded = true;
        nextValidDate = scheduler.next(2, selectedDate)[1];

        if (populatedTimeSeries.length) {
          let pairedManualReadings = ManualReadingsHelper.pairTimeSeriesValuesAndManualReadings(
            populatedTimeSeries,
            manualReadings,
            selectedDate
          );
          switch (vm.actionType) {
          case 'manualReadings':
            vm.chartConfig = await ManualReadingsHelper.getChartConfiguration(
              series,
              $rootScope.activeThemeAccent,
              false // detailedSeries flag
            );
            vm.detailedConfig = await ManualReadingsHelper.getChartConfiguration(
              detailedSeries,
              $rootScope.activeThemeAccent,
              true // detailedSeries flag
            );
            break;
          case 'counterChange':
            pairedManualReadings = pairedManualReadings.map(manualReading => {
              return {
                ...manualReading,
                openingValue: {
                  value: 0
                }
              };
            });
            break;
          }
          const dataObj = {
            _preserve_: true,
            date: selectedDate,
            time: '00:00:00',
            readingTime: '00:00:00',
            readingDate: selectedDate
          };

          const consumptionAndReadingDatesValidation = {
            name: 'consumptionReadingDateValidation',
            errorMessage: gettextCatalog.getString(
              'Manual reading cannot be entered since the difference between the consumption date and reading date should not exceed 1 month.'
            ),
            script: validateReadingAndConsumptionDate.bind({
              dataObj
            })
          };

          // CONSUMPTION DATE
          const formConfigurations = getFormConfiguration(true);

          const cronValidation = {
            script: validateDate,
            errorMessage: gettext('Wrong date format'),
            name: 'cronError'
          };

          formConfigurations[0].customValidation = [
            cronValidation,
            consumptionAndReadingDatesValidation
          ];

          formConfigurations[0].change = consumptionDateChange.bind({
            dataObj: dataObj,
            manualReadings: pairedManualReadings,
            scheduler
          });
          formConfigurations[0].onBlur = arg => {
            if (new Date(arg).getTime() != new Date(selectedDate).getTime()) {
              $timeout(() => {
                consumptionDateChange.apply({
                  dataObj: dataObj,
                  manualReadings: pairedManualReadings,
                  scheduler
                });
              }, 200); //timeout to let all validation functions execute before executing relist data
            }
          };

          // READING DATE
          const readingDateFormConfiguration = getFormConfiguration();
          readingDateFormConfiguration[0].change = readingDateChange.bind({
            dataObj: dataObj,
            manualReadings: pairedManualReadings,
            scheduler
          });

          readingDateFormConfiguration[0].onBlur = () => {
            if (
              new Date(vm.dataConfig.dataObj.date).getTime() !=
              new Date(selectedDate).getTime()
            ) {
              $timeout(() => {
                readingDateChange.apply({
                  dataObj: dataObj,
                  manualReadings: pairedManualReadings,
                  scheduler
                });
              }, 200); //timeout to let all validation functions execute before executing relist data
            }
          };

          const readingDateNowValidation = {
            name: 'readingDateNow',
            errorMessage: gettextCatalog.getString(
              'Manual reading cannot be entered because the reading date cannot be greater than today\'s date - {{date | luxonDate:\'short\'}}',
              {
                date: new Date()
              }
            ),
            script: date => {
              return ManualReadingsHelper.dateIsOneMonthBefore(
                date,
                new Date()
              );
            }
          };
          readingDateFormConfiguration[0].customValidation = [
            readingDateNowValidation,
            consumptionAndReadingDatesValidation
          ];

          vm.dataConfig = {
            data: formConfigurations,
            dataObj: dataObj
          };

          vm.readingFormConfig = {
            data: readingDateFormConfiguration,
            dataObj: dataObj
          };

          vm.manualReadings = pairedManualReadings;
          if (vm.actionType == 'counterChange') {
            var isValid = validateInputValues();
            setDisabledStatus(!isValid);
          }
        }
      } else {
        vm.noManualReading = true;
      }
      vm.initialDataLoaded = true;
      vm.loadingManualReadings = false; //values table loader
      // needed to let angular js know that view has to be updated - in the next digest cycle
      $scope.$applyAsync();
    } catch (err) {
      if (err) {
        AlertingService.Error(err);
      }
      vm.noManualReading = true;
      vm.loadingManualReadings = false; ////values table loader
      vm.initialDataLoaded = true;
    }
  };
}
