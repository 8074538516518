module.exports = {
  entity: 'measurement-types',
  path: 'measurement-types',
  name: {
    en: 'Measurement types',
    sl_SI: 'Tipi meritev'
  },
  networkModel: {
    entity: 'measurement-types',
    read: 'read'
  },
  selectedParamName: 'id',
  scope: 'tis_be:measurement-types',

  dialog: {
    title: {
      en: 'Select measurement type',
      sl_SI: 'Izberite tip meritve'
    },
    listInfo: {
      en: 'Select measurement type from the list below',
      sl_SI: 'Izberite tip meritve s spodnjega seznama'
    }
  }
}
