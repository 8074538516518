partialScheduleClassificationConfiguration.$inject = [
  'TranslationService',
  'gettextCatalog',
  '$timeout'
];
function partialScheduleClassificationConfiguration(
  TranslationService,
  gettextCatalog,
  $timeout
) {
  const disableHistoryInterval = api => {
    let isHistoryTimeRange = api.getValue('historyTimeRange');
    isHistoryTimeRange =
      isHistoryTimeRange != null ? isHistoryTimeRange.isChecked : false;
    return !isHistoryTimeRange;
  };
  const disableFutureInterval = api => {
    let isFutureTimeRange = api.getValue('futureTimeRange');
    isFutureTimeRange =
      isFutureTimeRange != null ? isFutureTimeRange.isChecked : false;
    return !isFutureTimeRange;
  };
  //HELPER FUNCTIONS
  const fieldByFilterType = {
    100: [
      'historyTimeRange',
      'historyNumberOfUnits',
      'historyScheduleClassification',
      'futureTimeRange',
      'futureNumberOfUnits',
      'futureScheduleClassification'
    ],
    200: ['numberOfValues']
  };
  const fieldByTimeRangeType = {
    history: ['historyNumberOfUnits', 'historyScheduleClassification'],
    future: ['futureNumberOfUnits', 'futureScheduleClassification']
  };
  const display = item => ({
    text: item.name
  });
  /**
   * @description sets the required states of the checkboxes historyTimeRange and futureTimeRange
   * @function
   * @param {Function} setFieldValue - function that changes value of a specific config field
   * @param {boolean} value - values to which the field is set to
   */
  const setCheckboxRequiredProperty = (setFieldValue, value) => {
    setFieldValue('historyTimeRange', 'required', value);
    setFieldValue('futureTimeRange', 'required', value);
  };
  /**
   * @description function sets required states based on values of the historyTimeRange and futureTimeRange checkboxes
   * @function
   * @param {Object} api - Reference the apis of sfeForm2
   */
  const TimeRangeCheckboxCheck = api => {
    const setFieldValue = api.setFieldConfigurationProperty;
    const historyKeys = fieldByTimeRangeType.history;
    const futureKeys = fieldByTimeRangeType.future;

    let historyTimeRangeChecked = api.getValue('historyTimeRange');
    let futureTimeRangeChecked = api.getValue('futureTimeRange');
    const historyTimeRange = historyTimeRangeChecked
      ? historyTimeRangeChecked.isChecked
      : null;
    const futureTimeRange = futureTimeRangeChecked
      ? futureTimeRangeChecked.isChecked
      : null;
    //if both checkboxes are unchecked then they are required
    if (!historyTimeRange && !futureTimeRange) {
      historyKeys.forEach(key => setFieldValue(key, 'required', false));
      futureKeys.forEach(key => setFieldValue(key, 'required', false));
      setCheckboxRequiredProperty(setFieldValue, true);
    } else {
      //if history time ranged is checked, then all its fields are required
      if (historyTimeRange) {
        historyKeys.forEach(key => setFieldValue(key, 'required', true));
        setCheckboxRequiredProperty(setFieldValue, false);
      } else {
        //if it is unchecked, they they are not required
        historyKeys.forEach(key => setFieldValue(key, 'required', false));
      }
      //if future time range is checked, then all its fields are required
      if (futureTimeRange) {
        futureKeys.forEach(key => setFieldValue(key, 'required', true));
        setCheckboxRequiredProperty(setFieldValue, false);
      } else {
        //if it is unchecked, then they are not required
        futureKeys.forEach(key => setFieldValue(key, 'required', false));
      }
    }
    api.revalidate();
  };
  /**
   * @description checks if the element should be hidden based on the selected filter type
   * @function
   * @param {Object} api - Reference the apis of sfeForm2
   * @param {integer} filterTypeId - id of the filterType currently selected (radio buttons)
   * @return {dataType}
   */
  const hideFunction = (api, filterTypeId) => {
    const { filterType } = api.getValues();
    if (filterType !== filterTypeId) {
      return true;
    }
    return false;
  };

  // CONFIGURATION
  /**
   * @description default version configuration.
   * @function
   * @param {string} chartSettingGroupId
   * @param {boolean} useYellowBackground
   * @param {Object} item - defaultFilter data
   * @return {Array} configuration
   */
  const defaultVersion = (chartSettingGroupId, useYellowBackground, item) => [
    {
      id: 'scheduleClassification',
      title: gettextCatalog.getString('Scheduler classification'),
      required: true,
      type: {
        name: 'autocomplete',
        options: {
          items: TranslationService.GetCollection(
            'codelists.scheduleClassifications'
          ),
          display
        }
      }
    },
    {
      id: 'defaultSpecifications',
      type: {
        name: 'checkbox',
        options: {
          items: [
            {
              name: gettextCatalog.getString('Default specification')
            }
          ]
        }
      }
    },
    {
      id: 'filterType',
      title: gettextCatalog.getString('Display'),
      type: {
        name: 'radio',
        options: {
          layout: 'row',
          modelProperty: 'id',
          items: TranslationService.GetCollection(
            'codelists.defaultFilterTypes'
          )
        }
      },
      initialize: () => {
        return item ? item.filterType : 200;
      },
      onChange: api => {
        //when radiobutton is changed, we want to delete the values in the hidden fields
        $timeout(() => {
          const filterType = api.getValue('filterType');
          let itemFilterType = item && item.filterType ? item.filterType : null;
          if (filterType === 100 && itemFilterType !== 100) {
            api.setValue('historyTimeRange', { isChecked: false });
            api.setValue('futureTimeRange', { isChecked: false });
            TimeRangeCheckboxCheck(api);
            fieldByFilterType[200].forEach(key => {
              if (key === 'numberOfValues') {
                api.setValue(key, '');
              } else {
                api.setValue(key, null);
              }
            });
          } else if (filterType === 200 && itemFilterType !== 200) {
            fieldByFilterType[100].forEach(key => {
              if (
                key === 'futureNumberOfUnits' ||
                key === 'historyNumberOfUnits'
              ) {
                api.setValue(key, '');
              } else {
                api.setValue(key, null);
              }
            });
          }
        });
      },
      required: true
    },
    {
      id: 'numberOfValues',
      title: gettextCatalog.getString('Number of values'),
      hide: api => hideFunction(api, 200),
      required: true,
      type: {
        name: 'text',
        options: {
          type: 'numerical',
          min: 0,
          max: 36000
        }
      },
      initialize: () => {
        return item != null ? item.numberOfValues : 100;
      }
    },
    {
      id: 'historyTimeRange',
      width: 3,
      hide: api => hideFunction(api, 100),
      type: {
        name: 'checkbox',
        options: {
          items: [
            {
              name: gettextCatalog.getString('Last'),
              _id: 'isChecked'
            }
          ]
        }
      },
      initialize: () => {
        if (item != null && item.historyTimeRange != null) {
          return { isChecked: true };
        }
        return { isChecked: false };
      },
      onChange: api => {
        TimeRangeCheckboxCheck(api);
        let isHistoryTimeRange = api.getValue('historyTimeRange');
        isHistoryTimeRange =
          isHistoryTimeRange != null ? isHistoryTimeRange.isChecked : false;
        if (!isHistoryTimeRange) {
          //REMOVE number of units and history interval
          api.setValue('historyNumberOfUnits', '');
          api.setValue('historyScheduleClassification', null);
        }
      },
      required: true
    },
    {
      id: 'historyNumberOfUnits',
      title: gettextCatalog.getString('N'),
      width: 3,
      hide: api => hideFunction(api, 100),
      type: {
        name: 'text',
        options: {
          type: 'numerical',
          min: 0
        }
      },
      initialize: () => {
        if (item != null && item.historyTimeRange != null) {
          return item.historyTimeRange.numberOfUnits;
        }
        return '';
      },
      disable: disableHistoryInterval
    },
    {
      id: 'historyScheduleClassification',
      title: gettextCatalog.getString('Time Interval'),
      width: 6,
      hide: api => hideFunction(api, 100),
      type: {
        name: 'autocomplete',
        options: {
          items: TranslationService.GetCollection(
            'codelists.scheduleClassifications'
          ),
          display
        }
      },
      initialize: () => {
        if (item != null && item.historyTimeRange != null) {
          return item.historyTimeRange.scheduleClassification;
        }
        return null;
      },
      disable: disableHistoryInterval
    },
    {
      id: 'futureTimeRange',
      width: 3,
      hide: api => hideFunction(api, 100),
      type: {
        name: 'checkbox',
        options: {
          items: [
            {
              name: gettextCatalog.getString('Next'),
              _id: 'isChecked'
            }
          ]
        }
      },
      initialize: () => {
        if (item != null && item.futureTimeRange != null) {
          return { isChecked: true };
        }
        return { isChecked: false };
      },
      onChange: api => {
        TimeRangeCheckboxCheck(api);
        let isFutureTimeRange = api.getValue('futureTimeRange');
        isFutureTimeRange =
          isFutureTimeRange != null ? isFutureTimeRange.isChecked : false;
        if (!isFutureTimeRange) {
          //REMOVE number of units and future interval
          api.setValue('futureNumberOfUnits', '');
          api.setValue('futureScheduleClassification', null);
        }
      },
      required: true
    },
    {
      id: 'futureNumberOfUnits',
      title: gettextCatalog.getString('N'),
      width: 3,
      hide: api => hideFunction(api, 100),
      type: {
        name: 'text',
        options: {
          type: 'numerical',
          min: 0
        }
      },
      initialize: () => {
        if (item != null && item.futureTimeRange != null) {
          return item.futureTimeRange.numberOfUnits;
        }
        return '';
      },
      disable: disableFutureInterval
    },
    {
      id: 'futureScheduleClassification',
      title: gettextCatalog.getString('Time Interval'),
      width: 6,
      hide: api => hideFunction(api, 100),
      type: {
        name: 'autocomplete',
        options: {
          items: TranslationService.GetCollection(
            'codelists.scheduleClassifications'
          ),
          display
        }
      },
      initialize: () => {
        if (item != null && item.futureTimeRange != null) {
          return item.futureTimeRange.scheduleClassification;
        }
        return null;
      },
      disable: disableFutureInterval
    },
    {
      id: 'chartSettings',
      title: gettextCatalog.getString('Series'),
      type: {
        name: 'chartSettings',
        options: {
          groupId: chartSettingGroupId, //this will have to be changed with real data
          yellowBackground: useYellowBackground
        }
      }
    }
  ];
  /**
   * @description creates a configuration that is used for timeseries.
   * @function
   * @param {Object} defaultFilter - timeSeries.dataVisualizationConfig.defaultFilter data
   * @return {Array} form configuration
   */
  const timeSeriesVersion = defaultFilter => {
    const timeSeriesVersionFields = [
      'filterType',
      'd1',
      'numberOfValues',
      'historyTimeRange',
      'historyNumberOfUnits',
      'historyScheduleClassification',
      'd2',
      'futureTimeRange',
      'futureNumberOfUnits',
      'futureScheduleClassification'
    ];
    return defaultVersion('', false, defaultFilter).filter(
      item => timeSeriesVersionFields.indexOf(item.id) > -1
    );
  };
  return {
    timeSeriesVersion,
    defaultVersion
  };
}
export default partialScheduleClassificationConfiguration;
