MtAnalysisFlowTimeSeriesConfigurations.$inject = ['gettextCatalog'];

function MtAnalysisFlowTimeSeriesConfigurations(gettextCatalog) {
  /**
   * @description get input time series for x & y axis.
   * @function
   * @param {Object} item
   * @return {Array} properties
   */
  const getInputTimeSeriesAxis = item => {
    let linkParamsX;
    let linkParamsY;
    if (item != null) {
      if (item.inputTimeSeriesForXAxis != null) {
        linkParamsX = JSON.stringify({ id: item.inputTimeSeriesForXAxis._id });
      }
      if (item.inputTimeSeriesForYAxis != null) {
        linkParamsY = JSON.stringify({ id: item.inputTimeSeriesForYAxis._id });
      }
    }
    let properties = [
      {
        type: 'basic',
        title: gettextCatalog.getString('Input time series for X axis'),
        testId: 'InputTimeSeriesForXAxis',
        values: [
          {
            link: `data-time-series-view(${linkParamsX})`,
            text:
              item != null && item.inputTimeSeriesForXAxis != null
                ? item.inputTimeSeriesForXAxis.name
                : null
          }
        ]
      },
      {
        type: 'basic',
        title: gettextCatalog.getString('Input time series for Y axis'),
        testId: 'InputTimeSeriesForYAxis',
        values: [
          {
            link: `data-time-series-view(${linkParamsY})`,
            text:
              item != null && item.inputTimeSeriesForYAxis != null
                ? item.inputTimeSeriesForYAxis.name
                : null
          }
        ]
      }
    ];
    return properties;
  };
  /**
   * @description returns configuration object to get tango properties configuration for mt analysis flow time series configuration.
   * @function
   * @param {object} flow flow object
   * @return {Object}
   */
  function get(flow) {
    return {
      type: 'dynamic',
      valueFn: store => {
        let item = {};
        if (
          store != null &&
          store.monitorAndTargetAnalysisFlow != null &&
          store.monitorAndTargetAnalysisFlow[flow.flow] != null &&
          store.monitorAndTargetAnalysisFlow[flow.flow].data != null
        ) {
          item = store.monitorAndTargetAnalysisFlow[flow.flow].data;
        }
        return [
          {
            title: gettextCatalog.getPlural(
              3,
              'Input Time Series',
              'Input Time Series',
              {}
            ),
            canClose: false,
            properties: getInputTimeSeriesAxis(item)
          }
        ];
      }
    };
  }
  return {
    get
  };
}

export default MtAnalysisFlowTimeSeriesConfigurations;
