profitCentreConfiguration.$inject = ['gettext', '$q', 'HierarchyTreeDialog'];

function profitCentreConfiguration(gettext, $q, HierarchyTreeDialog) {
  var configuration = {
    entity: {
      singular: gettext('Profit centre'),
      plural: gettext('Profit centres')
    },
    api: {
      query: {
        entity: 'profit-centres',
        method: 'custom.readWithCompanyStructure'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'company-resources-profit-centres-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'company-resources-profit-centres-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'info_outline',
        action: function(item) {
          HierarchyTreeDialog.openDialog(item, 'profit-centres');
        }
      },
      {
        icon: 'mode_edit',
        link: {
          state: 'company-resources-profit-centres-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'profit-centres',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description'),
        type: 'String'
      },
      {
        param: 'code',
        title: gettext('Code'),
        type: 'code'
      },
      {
        param: 'externalCode',
        title: gettext('External code'),
        type: 'code'
      },
      {
        param: 'node',
        title: gettext('Company Structure Node')
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Code'),
        param: 'code',
        type: 'string'
      },
      {
        display: gettext('External Code'),
        param: 'externalCode',
        type: 'string'
      },
      {
        query: {
          entity: 'company-structure-nodes',
          method: 'read'
        },
        entity: 'company-structure-nodes',
        display: gettext('Company Structure Node'),
        param: 'companyStructureNode',
        displayList: ['name', 'code'],
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    if (item.companyStructureNode) {
      item.node = item.companyStructureNode.name;
    }
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default profitCentreConfiguration;
