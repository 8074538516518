module.exports = {
  entity: 'webservice-types',
  path: 'webservice-types',
  name: {
    en: 'Webservice types',
    sl_SI: 'Tip spletnega storitve'
  },
  networkModel: {
    entity: 'webservice-types',
    read: 'read'
  },
  scope: 'tis_be:webservice-types',

  dialog: {
    title: {
      en: 'Select webservice type',
      sl_SI: 'Izberite tip spletnega servisa'
    },
    listInfo: {
      en: 'Select webservice type from the list below',
      sl_SI: 'Izberite tip spletnega servisa s spodnjega seznama'
    }
  }
}
