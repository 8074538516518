module.exports = {
  entity: 'charts',
  path: 'charts',
  name: {
    en: 'Charts',
    sl_SI: 'Grafi'
  },
  networkModel: {
    entity: 'charts',
    read: 'read'
  },
  scope: 'tis_be:charts',

  dialog: {
    title: {
      en: 'Select chart',
      sl_SI: 'Izberite graf'
    },
    listInfo: {
      en: 'Select chart from the list below',
      sl_SI: 'Izberite graf s spodnjega seznama'
    }
  },
  filterGrid: {
    title: {
      en: 'Charts',
      sl_SI: 'Charts'
    },
    dashboardCardType: 3,
    dashboardCardSize: 1,
    type: 'chart',
    preloaded: true,
    entities: [
      {
        entityId: '_id'
      }
    ]
  }
}
