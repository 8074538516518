import template from './sfe-proxy-iframe.component.html';

import './sfe-proxy-iframe.scss';

/**
 * @ngdoc component
 * @name analytics.sfeProxIframe
 * @description component that is used to transform an transform the url that is passed to the iframe
 * @param {string} url = string containing the url
 */
export default {
  template: template,
  restrict: 'E',
  bindings: {
    url: '='
  },
  controller: sfeIframeProxyConverterController,
  controllerAs: 'vm',
  bindToController: true
};

sfeIframeProxyConverterController.$inject = ['TranslationService'];

function sfeIframeProxyConverterController(TranslationService) {
  const vm = this;
  vm.removeUrl = removeUrl;
  vm.changeUrl = changeUrl;

  vm.$onInit = () => {
    vm.host = window.customization.iframeRootUrl;
    const iframeCardCodelist = TranslationService.GetCollectionById(
      'codelists.dashboardCardTypes',
      21
    );
    if (
      iframeCardCodelist &&
      iframeCardCodelist.dashboardCardSchema &&
      iframeCardCodelist.dashboardCardSchema[0]
    ) {
      vm.codelistPattern = iframeCardCodelist.dashboardCardSchema[0].regex;
    }
    changeUrl(vm.url);
  };
  //regex comes from https://gist.github.com/dperini/729294
  const urlRegex = new RegExp(
    '^' +
      // protocol identifier (optional)
      // short syntax // still required
      '(?:(?:(?:https?):)?\\/\\/)' +
      // user:pass BasicAuth (optional)
      '(?:\\S+(?::\\S*)?@)?' +
      '(?:' +
      // IP address exclusion
      // private & local networks
      '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
      '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
      '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
      // IP address dotted notation octets
      // excludes loopback network 0.0.0.0
      // excludes reserved space >= 224.0.0.0
      // excludes network & broadcast addresses
      // (first & last IP address of each class)
      '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
      '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
      '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
      '|' +
      // host & domain names, may end with dot
      // can be replaced by a shortest alternative
      // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
      '(?:' +
      '(?:' +
      '[a-z0-9\\u00a1-\\uffff]' +
      '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
      ')?' +
      '[a-z0-9\\u00a1-\\uffff]\\.' +
      ')+' +
      // TLD identifier name, may end with dot
      '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
      ')' +
      // port number (optional)
      '(?::\\d{2,5})?' +
      // resource path (optional)
      '(?:[/?#]\\S*)?' +
      '$',
    'i'
  );
  /**
   * @description function that transforms the url inside the input into one that can be sent to backend.
   * @function
   * @param {string} model - string containing the url
   */
  function changeUrl(model) {
    if (model) {
      //checks if the model is an URL
      if (model.match(urlRegex)) {
        // removes the host part of the url and assigns it to
        const newString = model.replace(/([^/]*\/){3}/, '/');
        vm.transformedUrl = newString;
      }
      // checks if the transformed url matches the pattern from the codelist and if so assigns it to the url that will be sent to BE.
      if (vm.transformedUrl.match(vm.codelistPattern)) {
        vm.url = vm.transformedUrl;
      }
    }
  }
  /**
   * @description function that clears the current url that is being shown
   * @function
   */
  function removeUrl() {
    vm.transformedUrl = '';
  }
}
