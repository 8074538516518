CrawlerMethods.$inject = [
  '$q',
  'NetworkModels',
  'AlertingService',
  'gettextCatalog'
];
/**
 * @ngdoc service
 * @name common.CrawlerMethods
 * @description constructs query requests.
 * Returns an object with the follow properties
 * @property {function} getMethod - see method getMethod.
 * @property {function} crawler - see method crawler.
 * @property {function} populate - see method populate.
 */
export default function CrawlerMethods(
  $q,
  NetworkModels,
  AlertingService,
  gettextCatalog
) {
  /**
   * @memberof common.CrawlerMethods
   * @description returns resource method
   * @param {object} configuration - entity-method configuration
   * @return {function} resource function
   */
  function getMethod(configuration) {
    if (configuration) {
      try {
        var methods = configuration.method.split('.');
        var method = NetworkModels(configuration.entity);
        methods.forEach(function(methodName) {
          method = method[methodName];
        });
        return method;
      } catch (err) {
        /*eslint no-console: ["error", { allow: ["error"] }] */
        console.error(configuration, err);
        throw err;
      }
    } else {
      throw gettextCatalog.getString('There is no configuration');
    }
  }
  /**
   * @memberof common.CrawlerMethods
   * @description fetches configuration entities
   * @param {Object} queryParamValues query parameters
   * @param {Object} bodyParameters body parameters
   * @param {bool} cache cache flag
   * @return {promise} promise with results
   */
  async function customCrawler(queryParamValues, bodyParameters, cache) {
    var configuration = this.crawlerConfiguration;
    queryParamValues = queryParamValues || {};
    var method = getMethod(configuration);
    if (typeof method != 'function') {
      throw gettextCatalog.getString(
        'Can\'t find method for entity {{entity}} and method {{method}}',
        configuration
      );
    } else {
      if (configuration.populate) {
        if (!queryParamValues.populate) {
          queryParamValues.populate = configuration.populate;
        } else {
          var crawlerPopulates = configuration.populate.split(',');
          var queryParamPopulate = queryParamValues.populate.split(',');
          var foundPopulate;

          crawlerPopulates.forEach(function(crawlerPopulateItem) {
            foundPopulate = queryParamPopulate.find(function(populateItem) {
              return populateItem === crawlerPopulateItem;
            });
            if (!foundPopulate) {
              queryParamPopulate.push(crawlerPopulateItem);
            }
          });
          queryParamValues.populate = queryParamPopulate.join(',');
        }
      }

      if (configuration.select) {
        let select = [...configuration.select.split(',')];
        if (queryParamValues.select) {
          select = select.concat(queryParamValues.select.split(','));
        }
        queryParamValues.select = select.filter(onlyUnique).join(',');
      }
      try {
        const res = await method(queryParamValues, null, cache);
        if (configuration.steps) {
          const result = await populate(res.data, configuration.steps, cache);
          res.data = result;
          return angular.copy(res);
        } else {
          return angular.copy(res);
        }
      } catch (err) {
        throw err;
      }
    }
  }
  /**
   * @memberof common.CrawlerMethods
   * @description custom crawler wrapper that doesn't reject error
   * @param {Object} parameters query parameters
   * @param {Array} crawlerConfiguration array of model crawler configurations
   * @param {bool} cache cache flag
   * @return {promise} promise with results
   */
  async function customCrawlerWrapper(parameters, crawlerConfiguration, cache) {
    var method = customCrawler.bind({
      crawlerConfiguration: crawlerConfiguration
    });
    try {
      const res = await method(parameters, null, cache);
      return angular.copy(res);
    } catch (err) {
      AlertingService.Error(err);
    }
  }
  /**
   * @memberof common.CrawlerMethods
   * @description returns blank promise
   * @return {Promise} blank promise
   */
  function blank() {
    return $q(function(resolve) {
      resolve();
    });
  }
  /**
   * @memberof common.CrawlerMethods
   * @description helper method to filter only uniqe items
   * @return {bool} item exist
   */
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  /**
   * @description returns value of an object by key separated by dots.
   * @function
   * @param {Object} Object any object
   * @param {String} keyString key string separated by dots
   * @return {any}
   */
  function getValueByKey(object, keyString) {
    if (object && keyString) {
      const keys = keyString.split('.');
      var filterValues;
      return keys.reduce(function(result, key) {
        if (result) {
          if (Array.isArray(result)) {
            filterValues = result.reduce(function(newValue, filterItem) {
              if (typeof filterItem == 'object' && filterItem != null) {
                if (filterItem[key]) {
                  return newValue.concat(filterItem[key]);
                }
              }
              return newValue;
            }, []);
            if (filterValues.length) {
              return filterValues.filter(onlyUnique);
            }
          } else {
            return result[key];
          }
        }
      }, angular.copy(object));
    }
  }
  /**
   * @description sets value to object by dot separated string.
   * @function
   * @param {Object} object object to set values to
   * @param {String} keyString key separated by dots
   * @param {any} value value to set
   */
  function getUpdatedObject(object, keyString, value) {
    let resultItem = {
      ...object
    };
    let item = resultItem;
    if (object) {
      var keys = keyString.split('.');
      if (keys) {
        // var item = object;
        keys.forEach(function(key, index) {
          if (index < keys.length - 1) {
            if (typeof item[key] === 'undefined' || item[key] === null) {
              item[key] = {};
            }
            item = item[key];
          } else {
            item[key] = value;
          }
        });
      }
    }
    return resultItem;
  }

  function getArrayOfFilterParameters(configurations, result) {
    return configurations.map(config => {
      let paramValue;
      const parameters = config.valueParams.reduce(
        (resultParameters, param) => {
          if (Array.isArray(result)) {
            return result.reduce((res, item) => {
              paramValue = getValueByKey(item, param);
              if (paramValue) {
                if (Array.isArray(paramValue)) {
                  return [...res, ...paramValue];
                }
                if (paramValue) {
                  return [...res, paramValue];
                }
              }
              return res;
            }, []);
          } else {
            paramValue = getValueByKey(result, param);
            if (Array.isArray(paramValue)) {
              return [...resultParameters, ...paramValue];
            } else if (typeof paramValue != 'undefined') {
              return [...resultParameters, paramValue];
            }
            return resultParameters;
          }
        },
        []
      );
      return {
        parameters: config.noMerge ? parameters : parameters.filter(onlyUnique),
        requestType: config.noMerge ? 'noMerge' : 'merge'
      };
    });
  }

  function constructArrayConfigurationPromises(configurations, result, cache) {
    const configurationParameters = getArrayOfFilterParameters(
      configurations,
      result
    );
    return configurations.map(function(config, index) {
      const parameters = configurationParameters[index].parameters;
      const requestType = configurationParameters[index].requestType;
      let queryParams = config.filters || {};
      if (parameters.length) {
        switch (requestType) {
        case 'merge':
          switch (config.queryParams.length) {
          case 1:
            queryParams[config.queryParams[0]] = parameters;
            break;
          case 2:
            if (parameters.length === 1) {
              queryParams[config.queryParams[0]] = parameters;
            } else {
              queryParams[config.queryParams[1]] = parameters;
            }
            break;
          }
          return customCrawlerWrapper(queryParams, config, cache);
        case 'noMerge':
          // eslint-disable-next-line no-case-declarations
          const noMergeRequestPromises = parameters.map(paramValue => {
            queryParams = {
              ...config.filters,
              [config.queryParams[0]]: paramValue
            };
            return customCrawlerWrapper(queryParams, config, cache);
          });
          return Promise.all(noMergeRequestPromises);
        }
      } else {
        return blank();
      }
    });
  }
  /**
   * @memberof common.CrawlerMethods
   * @description  goes through configuration and populates results
   * @param {Object} item fetched item
   * @param {Array} configurations crawler configuration
   * @param {bool} cache cache flag
   * @return {boolean} returns true of false
   */
  async function populate(item, configurations, cache) {
    var result = angular.copy(item);
    let promises = constructArrayConfigurationPromises(
      configurations,
      result,
      cache
    );
    try {
      let results = await Promise.all(promises);
      results.forEach(function(res, index) {
        var config = configurations[index];
        if (res && res.data) {
          var assignType = '';
          if (config.valueParams.length === 1) {
            assignType += 'one to ';
          } else {
            assignType += 'many to ';
          }

          if (Array.isArray(res.data)) {
            assignType += 'many';
          } else {
            assignType += 'one';
          }

          if (Array.isArray(result)) {
            //populate array
            result.forEach(function(resultItem, index) {
              result[index] = setFetchedValue(
                assignType,
                config,
                res,
                resultItem
              );
            });
          } else {
            result = setFetchedValue(assignType, config, res, result);
          }
        } else if (Array.isArray(res) && config.noMerge) {
          res.forEach(function(item, index) {
            var param;
            switch (config.methodType) {
            case 'add':
              param = config.newParamNames[0];
              break;
            case 'populate':
              param = config.valueParams[0];
              break;
            }
            if (Array.isArray(result)) {
              result[index] = getUpdatedObject(
                result[index],
                param,
                item && item.data ? item.data : item
              );
            } else {
              result = getUpdatedObject(
                result,
                param,
                item && item.data ? item.data : item
              );
            }
          });
        }
      });
      return result;
    } catch (err) {
      throw err;
    }
  }
  /**
   * @description assigns results
   * @param {string} assignType - assign type (one to one, one to many, many to one, many to many)
   * @param {object} config crawler configuration
   * @param {object} fetchedItem parent item
   * @param {object} result fetched results (could be array)
   */
  function setFetchedValue(assignType, config, fetchedItem, result) {
    var foundValue;
    var localField = config.valueParams[0];
    var foreignField = '_id';
    var keyValue;

    if (config && config.mapping) {
      localField = config.mapping.localField;
      foreignField = config.mapping.foreignField;
    }
    var value;
    switch (assignType) {
    case 'one to one':
      value = fetchedItem.data;
      switch (config.methodType) {
      case 'populate':
        parameter = config.valueParams[0];
        break;
      case 'add':
        parameter = config.newParamNames[0];
        break;
      case 'populateArray':
        parameter = config.valueParams[0];
        value = result[localField] === value[foreignField] ? value : null;
        break;
      case 'addArray':
        parameter = config.newParamNames[0];
        value = result[localField] === value[foreignField] ? value : null;
        break;
      }
      return getUpdatedObject(result, parameter, value || null);
    case 'one to many':
      var parameter;
      value = fetchedItem.data;
      switch (config.methodType) {
      case 'populate':
        parameter = config.valueParams[0];
        break;
      case 'add':
        parameter = config.newParamNames[0];
        break;
      case 'populateArray':
        parameter = config.valueParams[0];
        value = fetchedItem.data.find(function(item) {
          keyValue = getValueByKey(result, localField);
          return item[foreignField] === keyValue;
        });
        break;
      case 'addArray':
        parameter = config.newParamNames[0];
        value = fetchedItem.data.find(function(item) {
          keyValue = getValueByKey(result, localField);
          return item[foreignField] === keyValue;
        });
        break;
      }
      return getUpdatedObject(result, parameter, value || null);
    case 'many to one':
      return config.valueParams.reduce(function(
        newResult,
        valueParam,
        index
      ) {
        keyValue = getValueByKey(newResult, valueParam);
        if (keyValue === fetchedItem.data[foreignField]) {
          switch (config.methodType) {
          case 'add':
            return getUpdatedObject(
              newResult,
              config.newParamNames[index],
              fetchedItem.data
            );
          case 'populate':
            return getUpdatedObject(
              newResult,
              valueParam,
              fetchedItem.data
            );
          }
        }
      },
      result);
    case 'many to many':
      return config.valueParams.reduce(function(
        newResult,
        valueParam,
        index
      ) {
        foundValue = fetchedItem.data.find(function(item) {
          keyValue = getValueByKey(result, valueParam);
          return item._id === keyValue;
        });
        if (foundValue) {
          switch (config.methodType) {
          case 'populate':
            return getUpdatedObject(newResult, valueParam, foundValue);
          case 'add':
            return getUpdatedObject(
              newResult,
              config.newParamNames[index],
              foundValue
            );
          }
        } else {
          return newResult;
        }
      },
      result);
    }
  }
  /**
   * @memberof common.CrawlerMethods
   * @description  returns fetch function by crawler configuration
   * @param {Object} crawlerConfiguration - crawler configuration object (should have entity and method)
   * @return {function}
   */
  function crawler(crawlerConfiguration) {
    return async (parameters, cache) => {
      const method = customCrawler.bind({
        crawlerConfiguration
      });
      try {
        const res = await method(parameters, null, cache);
        return angular.copy(res);
      } catch (err) {
        throw err;
      }
    };
  }

  var methods = {
    getMethod: getMethod,
    crawler: crawler,
    populate: populate
  };

  return methods;
}
