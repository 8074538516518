import actionTypes from '../action/times-series.type';

function TimeSeriesReducer(state = {}, action) {
  switch (action.type) {
  case actionTypes.TIME_SERIES_READ: {
    return {
      ...state,
      [action.payload.id]: action.payload.result
    };
  }
  case actionTypes.TIME_SERIES_UPDATE_PROPS:
    return {
      ...state,
      [action.payload.query.id]: updateTimeSeriesStore(action.payload, state)
    };
  case actionTypes.TIME_SERIES_UPDATE:
    return {
      ...state,
      [action.payload.query.id]: action.payload.result
    };
  case actionTypes.TIME_SERIES_CREATE:
    return {
      ...state,
      [action.payload.result.data._id]: createTimeSeriesStore(action.payload)
    };
  default:
    return state;
  }
}
/**
 * @description merges current time series with time series that update query returns.
 * @function
 * @param {Object} payload payload object {query, result} must contain TS id in query object and result must contain updated TS under data key
 * @return {dataType}
 */
function updateTimeSeriesStore(payload, state) {
  let id = payload.query.id;
  let timeSeries = state[id].data;
  let updatedTimeSeries = payload.result.data;
  return {
    data: {
      ...timeSeries,
      ...updatedTimeSeries
    }
  };
}

/**
 * @description create time series.
 * @function
 * @param {Object} payload payload object {query, result} must contain TS id in query object and result must contain updated TS under data key
 * @return {dataType}
 */
function createTimeSeriesStore(payload) {
  let createdTimeSeries = payload.result.data;
  return {
    data: {
      ...createdTimeSeries
    }
  };
}

export default TimeSeriesReducer;
