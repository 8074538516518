TimeSeriesConfiguration.$inject = [
  'gettext',
  'TranslationService',
  'AlertingService',
  'TimeSeriesProcessingValuesModel',
  'Formatting',
  'ColorService',
  'gettextCatalog'
];

function TimeSeriesConfiguration(
  gettext,
  TranslationService,
  AlertingService,
  TimeSeriesProcessingValuesModel,
  Formatting,
  ColorService,
  gettextCatalog
) {
  const badColor = ColorService.getApplicationColor('warn');
  const goodColor = ColorService.getApplicationColor('success');
  const neutral = '#333333';
  later.date.localTime();

  var configuration = {
    entity: {
      singular: gettextCatalog.getPlural(1, 'Time Series', 'Time Series', {}),
      plural: gettextCatalog.getPlural(3, 'Time Series', 'Time Series', {})
    },
    api: {
      query: {
        entity: 'time-series',
        method: 'custom.list'
      },
      populate: 'dataSchedule',
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'data-time-series-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    shortActions: [
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'time-series',
            method: 'delete'
          }
        }
      }
    ],
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'data-time-series-new'
        },
        color: 'accent'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Measurement unit'),
        query: {
          entity: 'measurement-units',
          method: 'read'
        },
        entity: 'measurement-units',
        param: 'measurementUnit',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        display: gettext('Scheduler'),
        query: {
          entity: 'schedulers',
          method: 'read'
        },
        entity: 'schedulers',
        param: 'dataScheduler',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        display: gettext('Time series type'),
        query: {
          entity: 'time-series-types',
          method: 'read'
        },
        entity: 'time-series-types',
        param: 'timeSeriesType',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        display: gettext('Time series status'),
        collection: TranslationService.GetCollection(
          'codelists.timeSeriesStatuses'
        ),
        valueField: 'id',
        displayOptions: ['name'],
        param: 'timeSeriesStatus'
      },
      {
        display: gettext('Physical Quantity'),
        query: {
          entity: 'physical-quantities',
          method: 'read'
        },
        entity: 'physical-quantities',
        param: 'physicalQuantity',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        display: gettext('Data type'),
        collection: TranslationService.GetCollection('codelists.dataTypes'),
        valueField: 'id',
        displayOptions: ['name'],
        param: 'dataType'
      },
      {
        display: gettext('Entity Tags'),
        query: {
          entity: 'entity-tags',
          method: 'read'
        },
        entity: 'entity-tags',
        param: 'entityTagBindings.entityTag',
        populate: 'entityTagBindings',
        displayList: ['value'],
        type: 'searchSelect',
        refreshParameterName: 'value',
        selectedParamName: '_id'
      },
      {
        display: gettext('Measuring point'),
        query: {
          entity: 'measuringpoints',
          method: 'read'
        },
        entity: 'measuringpoints',
        param: 'timeSeriesMeasuringpoints.measuringpoint',
        populate: 'timeSeriesMeasuringpoints',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        param: 'timeSeriesAssets.asset',
        query: {
          entity: 'assets',
          method: 'read'
        },
        entity: 'assets',
        display: gettext('Assets'),
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id',
        populate: 'timeSeriesAssets',
        filterParameters: {
          'timeSeriesAssets.isActive': true
        }
      },
      {
        param: 'timeSeriesLocations.location',
        query: {
          entity: 'locations',
          method: 'read'
        },
        entity: 'locations',
        display: gettext('Locations'),
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id',
        populate: 'timeSeriesLocations',
        filterParameters: {
          'timeSeriesLocations.isActive': true
        }
      },

      {
        param: 'activeTimeSeriesConfiguration.flowType_',
        display: gettext('Flow Type'),
        collection: TranslationService.GetCollection('codelists.flowTypes'),
        valueField: 'id',
        displayOptions: ['name']
      },
      {
        param: 'dataInterpretationType',
        display: gettext('Data Interpolation Type'),
        collection: TranslationService.GetCollection(
          'codelists.dataInterpretationTypes'
        ),
        valueField: 'id',
        displayOptions: ['name']
      },
      {
        param: 'dataSamplingType',
        display: gettext('Data Sampling Type'),
        collection: TranslationService.GetCollection(
          'codelists.dataSamplingTypes'
        ),
        valueField: 'id',
        displayOptions: ['name']
      },
      {
        param: 'activeTimeSeriesConfiguration.externalDatasource_',
        query: {
          entity: 'external-datasources',
          method: 'read'
        },
        entity: 'external-datasources',
        display: gettext('External  datasource'),
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      }
    ],
    badges: [
      {
        title: gettext('Last value'),
        type: 'physical-value',
        param: 'value',
        measurementUnitParam: 'readableMeasurementUnitSymbol'
      },
      {
        title: gettext('Status'),
        colors: true,
        param: 'valueStatus',
        type: 'coloredText',
        icon: true
      }
    ],
    attributes: [
      {
        title: gettext('Schedule'),
        param: 'scheduleName',
        type: 'link',
        link: {
          state: 'data-schedules-view',
          params: [
            {
              valueParam: 'scheduleId',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        title: gettext('Time Series Type'),
        param: 'timeSeriesTypeName'
      },
      {
        title: gettext('Last measurement time'),
        param: 'lastValueTime',
        type: 'date'
      }
    ]
  };

  async function Enrich(item) {
    item.fetchingObject['value'] = {
      fetching: true,
      success: false,
      nodata: false
    };

    item.valueStatus = gettext('bad');
    item.badgeColor = badColor;
    if (item.dataSamplingType == 200) {
      //IRREGULAR TS
      item.badgeColor = neutral;
      item.valueStatus = '/';
    }
    //SCHEDULE
    if (item.dataScheduler != null && typeof item.dataScheduler == 'object') {
      item.scheduleId = item.dataScheduler._id;
      item.scheduleName = item.dataScheduler.name;
    } else {
      item.scheduleName = '/';
    }
    // TIMESERIES TYPE
    if (item.timeSeriesType != null && typeof item.timeSeriesType == 'object') {
      item.timeSeriesTypeName = item.timeSeriesType.name;
    }
    // MEASUREMENT DATA
    if (
      item.measurementUnit != null &&
      typeof item.measurementUnit == 'object' &&
      item.measurementUnit.symbol != null
    ) {
      let metricPrefix = TranslationService.GetCollectionById(
        'codelists.metricPrefixes',
        item.metricPrefix
      );
      item.readableMeasurementUnitSymbol = `${
        metricPrefix != null && metricPrefix.symbol ? metricPrefix.symbol : ''
      } ${item.measurementUnit.symbol}`;
    }
    //VALUES
    try {
      let { data } = await TimeSeriesProcessingValuesModel.read({
        timeSeriesId: item._id,
        limit: 1,
        view: 'simple'
      });
      if (Array.isArray(data) && data.length > 0) {
        //VALUE STATUS
        const now = new Date().getTime();
        let schedule;
        if (
          item != null &&
          item.dataScheduler != null &&
          item.dataScheduler.crontabExpression != null
        ) {
          schedule = later.schedule(
            later.parse.cron(item.dataScheduler.crontabExpression, true)
          );
        }

        let prev;
        if (schedule != null) {
          prev = schedule.prev(1, now);
        }
        if (prev != null) {
          let prevTimestamp = prev.getTime();
          if (prevTimestamp <= data[0].validAt) {
            item.valueStatus = gettext('good');
            item.badgeColor = goodColor;
          }
        }
        item.value = Formatting.formatDatapointDisplay(
          item.dataType,
          data[0].value,
          item.precision
        );
        item.fetchingObject['value'] = {
          fetching: false,
          success: true,
          nodata: false
        };
        item.lastValueTime = data[0].validAt;
      } else {
        item.fetchingObject['value'] = {
          fetching: false,
          success: true,
          nodata: true
        };
      }
    } catch (err) {
      AlertingService.Error(err);
      item.fetchingObject['value'] = {
        fetching: false,
        success: false,
        nodata: true
      };
    }
  }

  return configuration;
}
export default TimeSeriesConfiguration;
