DashboardPlaylistConfiguration.$inject = [
  'gettext',
  '$q',
  'TranslationService'
];

function DashboardPlaylistConfiguration(gettext, $q, TranslationService) {
  var configuration = {
    listId: 'dashboardPlaylistList',
    entity: {
      singular: gettext('Dashboard Playlist'),
      plural: gettext('Dashboard Playlists')
    },
    api: {
      query: {
        entity: 'dashboard-playlists',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'dashboards-dashboard-playlists-run',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'dashboards-dashboard-playlists-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'play_arrow',
        link: {
          state: 'dashboards-dashboard-playlists-run',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'mode_edit',
        link: {
          state: 'dashboards-dashboard-playlists-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'dashboard-playlists',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Description'),
        param: 'description',
        type: 'text'
      },
      {
        title: gettext('Refresh interval'),
        param: 'refreshIntervalName',
        type: 'text'
      }
    ],
    filter: [
      {
        param: 'filter',
        display: gettext('Name'),
        type: 'string'
      },
      {
        query: {
          entity: 'dashboards',
          method: 'read'
        },
        entity: 'dashboards',
        display: gettext('Dashboards'),
        param: 'dashboards',
        displayList: ['name'],
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      }
    ],
    sort: [
      {
        display: gettext('created DESC'),
        params: '-_id'
      },
      {
        display: gettext('created ASC'),
        params: '_id'
      },
      {
        display: gettext('name ASC'),
        params: 'name'
      },
      {
        display: gettext('name DESC'),
        params: '-name'
      }
    ],
    extended: {}
  };

  function Enrich(item) {
    var deferred = $q.defer();
    item.refreshInterval = TranslationService.GetCollectionById(
      'codelists.refreshIntervals',
      item.refreshInterval
    );
    item.refreshIntervalName = item.refreshInterval
      ? item.refreshInterval.name
      : gettext('Unknown');
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default DashboardPlaylistConfiguration;
