CachingParams.$inject = ['$interval', 'CachingControl'];
/**
 * @ngdoc service
 * @name common.CachingParams
 * @description Controls global caching parameters when they need to stay constant even tho they change in between invalidation times
 * @property {function} anonymousFunction
 */
export default function CachingParams($interval, CachingControl) {
  const revalidationTime = 30 * 60 * 1000;
  const params = {};

  /**
   * @description Checks params based on unique id, if there is no entry for said id, it creates new one.
   * @function
   * @param {string|number} id - unique identifier
   * @param {object} newParams - params to be set if enough time has passed or new id
   * @param {number} validationTime - time before item is considered stale
   * @return {object} - stored params
   */
  function CheckParams(id, newParams, validationTime) {
    if (validationTime) {
      if (typeof params[id] === 'undefined') {
        CreateParams(id, newParams);
      }
      if (Date.now() - params[id].timestamp > validationTime) {
        params[id].data = newParams;
      }
      return params[id].data;
    } else {
      return newParams;
    }
  }

  /**
   * @description Creates new params storage object.
   * @function
   * @param {string|number} id
   * @param {object} initalData
   */
  function CreateParams(id, initalData) {
    params[id] = {
      data: initalData,
      timestamp: Date.now()
    };
  }

  /**
   * @description Deletes every item in the params store.
   * @function
   */
  function RefreshAllParams() {
    for (let param in params) {
      delete params[param];
    }
  }

  /**
   * @description Clears cached params that are stale
   * @function
   */
  function StartInvalidatingParams() {
    let expirationTime = CachingControl.GetItemCacheTime();
    const toDelete = [];
    $interval(() => {
      for (let id in params) {
        if (Date.now() - params[id].timestamp > expirationTime) {
          toDelete.push(id);
        }
      }
      for (let id in toDelete) {
        delete params[id];
      }
    }, revalidationTime); // revalidate once every 30 min
  }

  var api = {
    CreateParams,
    CheckParams,
    RefreshAllParams,
    StartInvalidatingParams
  };
  return api;
}
