alarmTypeConfiguration.$inject = [
  'gettext',
  '$q',
  'SfeFormDialogService',
  'ListFormService',
  'AlertingService',
  'CrudToastFactory',
  'AlarmTypeModel',
  'EntityTagService',
  'TranslationService'
];

function alarmTypeConfiguration(
  gettext,
  $q,
  SfeFormDialogService,
  ListFormService,
  AlertingService,
  CrudToastFactory,
  AlarmTypeModel,
  EntityTagService,
  TranslationService
) {
  const fieldNames = ['name', 'description', 'alarmCategory', 'externalCode'];

  function openCreateDialog(relistData) {
    var config = ListFormService.generateForm(
      ['name', 'description', 'externalCode', 'alarmCategory', 'systemTags'],
      false
    );
    var title = gettext('New Alarm Type');
    SfeFormDialogService.openSfeFormDialog(
      false,
      config,
      { systemTags: [] },
      title
    ).then(function(object) {
      if (object) {
        create(object, relistData);
      }
    });
  }

  async function openUpdateDialog(item, relistData) {
    try {
      const { data: alarm } = await AlarmTypeModel.custom.readWithSystemTags({
        id: item._id
      });

      let tags = [];
      if (alarm.tags) {
        tags = alarm.tags.map(tag => {
          return {
            ...tag.systemTag,
            bindingId: tag._id
          };
        });
      }
      alarm.systemTags = tags;
      const config = ListFormService.generateForm(
        ['name', 'description', 'externalCode', 'alarmCategory', 'systemTags'],
        false
      );
      const title = gettext('Edit Alarm Type');
      const formObject = await SfeFormDialogService.openSfeFormDialog(
        true,
        config,
        alarm,
        title
      );
      if (formObject) {
        update(item._id, formObject, tags, relistData);
      }
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  async function create(obj, relistData) {
    let postObject = ListFormService.generateBodyObject(obj, fieldNames);
    postObject = {
      ...postObject,
      alarmCategory: obj.alarmCategory.id
    };
    try {
      const { data: alarmType } = await AlarmTypeModel.create(postObject);

      try {
        await EntityTagService.createSystemTags(
          obj.systemTags,
          [],
          30,
          alarmType._id,
          false
        );
      } catch (err) {
        AlertingService.Error(err);
      }
      CrudToastFactory.toast('create');
      relistData();
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  async function update(id, obj, originalTags, relistData) {
    let putObject = ListFormService.generateBodyObject(obj, fieldNames);
    putObject = {
      ...putObject,
      alarmCategory: obj.alarmCategory.id
    };
    try {
      await AlarmTypeModel.update(
        {
          id: id
        },
        putObject
      );
      try {
        await EntityTagService.createSystemTags(
          obj.systemTags,
          originalTags,
          30,
          id,
          false
        );
      } catch (err) {
        AlertingService.Error(err);
      }

      CrudToastFactory.toast('update');
      relistData();
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  var configuration = {
    entity: {
      singular: gettext('Alarm Type'),
      plural: gettext('Alarm Types')
    },
    api: {
      query: {
        entity: 'alarm-types',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name'
    },
    buttons: [
      {
        title: gettext('Create'),
        fn: openCreateDialog,
        relist: true,
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        action: openUpdateDialog
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'alarm-types',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description')
      },
      {
        param: 'externalCode',
        title: gettext('External code'),
        type: 'code'
      },
      {
        param: 'alarmCategoryName',
        title: gettext('Alarm Category'),
        type: 'text'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('External code'),
        param: 'externalCode',
        type: 'string'
      },
      {
        query: {
          entity: 'system-tags',
          method: 'read'
        },
        entity: 'system-tags',
        display: gettext('System Tags'),
        selectedParamName: '_id',
        param: 'systemTagEntities.systemTag',
        populate: 'systemTagEntities'
      },
      {
        collection: TranslationService.GetCollection(
          'codelists.alarmCategories'
        ),
        param: 'alarmCategory',
        display: gettext('Alarm Category')
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    let alarmCategory = TranslationService.GetCollectionById(
      'codelists.alarmCategories',
      item.alarmCategory
    );
    if (alarmCategory != null) {
      item.alarmCategoryName = alarmCategory.name;
    } else {
      item.fetchingObject.alarmCategoryName = {
        fetching: false,
        success: true,
        nodata: true
      };
    }
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default alarmTypeConfiguration;
