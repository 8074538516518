TimeSeriesGroupsConfiguration.$inject = ['gettext'];

function TimeSeriesGroupsConfiguration(gettext) {
  var configuration = {
    entity: {
      singular: gettext('Time series groups'),
      plural: gettext('Time series groups')
    },
    api: {
      query: {
        entity: 'time-series-groups',
        method: 'custom.list'
      },
      populate: 'timeSeriesGroupType',
      enrich: async item => {
        if (item.timeSeriesGroupType != null) {
          item.timeSeriesGroupTypeName = item.timeSeriesGroupType.name;
        } else {
          item.fetchingObject['timeSeriesGroupTypeName'] = {
            fetching: false,
            success: true,
            nodata: true
          };
        }
      }
    },
    title: {
      param: 'name',
      link: {
        state: 'data-time-series-groups-sandbox',
        params: [
          {
            valueParam: '_id',
            queryParam: 'groupId'
          }
        ]
      }
    },
    shortActions: [
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'time-series-groups',
            method: 'delete'
          }
        }
      }
    ],
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'data-time-series-groups-sandbox'
        },
        color: 'accent'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ],
    attributes: [
      {
        title: gettext('Time Series Group Type'),
        param: 'timeSeriesGroupTypeName'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Time series group type'),
        query: {
          entity: 'time-series-group-types',
          method: 'read'
        },
        entity: 'time-series-group-types',
        param: 'timeSeriesGroupType',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      }
    ]
  };
  return configuration;
}
export default TimeSeriesGroupsConfiguration;
