import calculatedFlowAction from '../../../redux/calculated-flow/action/calculated-flow.action';
import timeSeriesConfigurationsAction from '../../../redux/time-series-configurations/action/times-series-configurations.action';
/**
 * @ngdoc service
 * @name common.CalculatedFlowFormConfigurations
 * @description Opens form dialog
 */

CalculatedFlowFormConfigurations.$inject = [
  'gettextCatalog',
  'gettext',
  '$ngRedux',
  '$mdDialog',
  '$timeout',
  'TimeSeriesConfigurationModel',
  'CalculatedFlowModel',
  'TimeSeriesVariableService',
  'CrudToastFactory',
  'AlertingService',
  'TranslationService',
  'PhysicalCollectionService',
  'TimeSeriesFlowHelper'
];
export default function CalculatedFlowFormConfigurations(
  gettextCatalog,
  gettext,
  $ngRedux,
  $mdDialog,
  $timeout,
  TimeSeriesConfigurationModel,
  CalculatedFlowModel,
  TimeSeriesVariableService,
  CrudToastFactory,
  AlertingService,
  TranslationService,
  PhysicalCollectionService,
  TimeSeriesFlowHelper
) {
  /**
   * @description map body data.
   * @function
   * @param {Object} body
   * @param {Object} bodyData
   */
  const mapBodyData = (body, bodyData) => {
    body.expression = bodyData.expression;
    body.triggerType = bodyData.triggerType;

    const {
      inputTimeSeries,
      inputDynamicAttributes
    } = getFlowVariablesForUpdate(bodyData.flowVariables, bodyData.triggerType);
    body.inputTimeSeries = inputTimeSeries;
    body.inputDynamicAttributes = inputDynamicAttributes;
    return body;
  };

  /**
   * @description get only the needed flow variables properties.
   * @function
   * @param {Array} flowVariables
   * @param {number} triggerType codelist id of selected trigger type
   * @return {Object}  { inputTimeSeries, inputDynamicAttributes }
   */
  function getFlowVariablesForUpdate(flowVariables, triggerType) {
    let inputTimeSeries = constructTimeSeriesApiObject(
      flowVariables[0],
      triggerType
    );
    let inputDynamicAttributes = constructDynamicAttributesApiObject(
      flowVariables[1]
    );

    return { inputTimeSeries, inputDynamicAttributes };
  }
  /**
   * @description returns input time series post/put api Array.
   * @function
   * @param {Array} params time series array
   * @param {number} triggerType codelist id of selected trigger type
   * @return {Array}
   */
  function constructTimeSeriesApiObject(params, triggerType) {
    if (Array.isArray(params)) {
      return params.map(param => {
        return {
          timeSeries: param.timeSeries._id,
          triggersCalculation:
            triggerType == 300 /* CRON TRIGGERED */
              ? false
              : !!param.triggersCalculation,
          timeSeriesParams: getTimeSeriesParam(param.timeSeriesParams)
        };
      });
    }

    return [];
  }
  /**
   * @description returns time series variables post/put array.
   * @function
   * @param {Array} params
   * @return {Array}
   */
  function getTimeSeriesParam(params) {
    if (Array.isArray(params)) {
      return params.map(param => {
        let mappingFunctionArguments = [];
        if (param.mappingFunction.hasArguments) {
          if (param.mappingFunction.arguments[0] === 'type') {
            mappingFunctionArguments = [
              {
                name: 'type',
                value: param.mappingFunctionTypeArgument.id
              }
            ];
          } else if (param.mappingFunction.arguments[0] === 'n') {
            mappingFunctionArguments = [
              {
                name: 'n',
                value: param.mappingFunctionNArgument
              }
            ];
          }
        }
        let streamArchiveProperty = null;
        if (param.streamArchiveProperty != null) {
          streamArchiveProperty = param.streamArchiveProperty.id;
        }
        let exceptionHandler = null;
        if (
          param.exceptionHandlerActionTypeArgument != null &&
          param.exceptionHandlerActionTypeArgument != ''
        ) {
          exceptionHandler = {
            actionType: 200,
            actionArguments: [
              {
                name: 'value',
                value: param.exceptionHandlerActionTypeArgument
              }
            ]
          };
        }

        let calculationTimestampDateTimeFilter;

        if (param.dateTimeFilterType != null) {
          let boundaryType = null;

          if (param.dateTimeFilterType.id == 200) {
            boundaryType = param.includeValueOnTheEdge
              ? 2
              : 1; /* boundaryTypes codelist */
          }

          let timeUnit = null;
          let offsetValue = null;

          if (param.dateTimeFilterType.id == 400) {
            timeUnit = param.timeUnit.id;
            offsetValue = param.offsetValue;
          }

          let fromDateTimeMapper = null;
          let toDateTimeMapper = null;
          let fromBoundaryType = null;
          let toBoundaryType = null;

          if (param.dateTimeFilterType.id == 500) {
            if (param.fromDateTimeMapper != null) {
              const { boundaryType, ...mapper } = param.fromDateTimeMapper;
              fromDateTimeMapper = constructMapper(mapper);
              fromBoundaryType = boundaryType;
            } else {
              fromBoundaryType = 1;
            }

            if (param.toDateTimeMapper != null) {
              const { boundaryType, ...mapper } = param.toDateTimeMapper;
              toDateTimeMapper = constructMapper(mapper);
              toBoundaryType = boundaryType;
            } else {
              toBoundaryType = 1;
            }
          }

          calculationTimestampDateTimeFilter = {
            type: param.dateTimeFilterType.id,
            boundaryType,
            timeUnit,
            offsetValue,
            fromBoundaryType,
            toBoundaryType,
            fromDateTimeMapper,
            toDateTimeMapper
          };
        }

        return {
          mappingFunction: param.mappingFunction.id,
          name: param.variableName,
          mappingFunctionArguments,
          streamArchiveProperty,
          exceptionHandler,
          calculationTimestampDateTimeFilter
        };
      });
    }
    return [];
  }
  /**
   * @description returns post/put date time mapper Array.
   * @function
   * @param {Array} mapper
   * @return {Array}
   */
  function constructMapper(mapper) {
    if (Object.keys(mapper).length === 0) {
      return null;
    }
    return Object.keys(mapper).reduce((result, key) => {
      let { dateTimeMappingType, value } = mapper[key];
      return {
        ...result,
        [key]: {
          dateTimeMappingType,
          value
        }
      };
    }, {});
  }
  /**
   * @description returns post/put dynamic attributes array.
   * @function
   * @param {Array} attributes
   * @return {Array}
   */
  function constructDynamicAttributesApiObject(attributes) {
    if (Array.isArray(attributes) && attributes.length > 0) {
      return attributes.reduce((result, attribute) => {
        let params = attribute.attributeParams.map(param => {
          return {
            domainAttribute: param.attribute._id,
            name: param.name,
            attributeObjectType: attribute.attributeType,
            attributeObjectId: attribute.attribute._id
          };
        });

        return [...result, ...params];
      }, []);
    }
    return null;
  }
  /**
   * @description convert from itemView format to dialog format.
   * @function
   * @param {Object} item
   * @return {Array} inputDynamicAttributes
   */
  function getInputDynamicAttributes(item) {
    let inputDynamicAttributes = [];
    if (item != null && Array.isArray(item.inputDynamicAttributes)) {
      // Group inputDynamicAttributes into Object of Arrays with attributeId as key.
      let entities = item.inputDynamicAttributes.reduce(
        (attributeObj, attribute) => {
          if (attribute.attributeObjectId != null) {
            if (Array.isArray(attributeObj[attribute.attributeObjectId._id])) {
              attributeObj[attribute.attributeObjectId._id].push(attribute);
            } else {
              attributeObj[attribute.attributeObjectId._id] = [];
              attributeObj[attribute.attributeObjectId._id].push(attribute);
            }
          }
          return attributeObj;
        },
        {}
      );
      // Iterate over created Object and convert it to correct format for dialog.
      inputDynamicAttributes = Object.values(entities).reduce(
        (attributesArr, attributes) => {
          let inputDynamicAttribute = {};
          let params = attributes.map(attribute => {
            if (inputDynamicAttribute.attribute == null) {
              // Temporary ._id, should be object once flowVariables is fixed.
              inputDynamicAttribute.attribute = attribute.attributeObjectId;
            }
            if (inputDynamicAttribute.attributeType == null) {
              inputDynamicAttribute.attributeType =
                attribute.attributeObjectType;
            }
            let param = {
              name: attribute.name,
              attribute: attribute.domainAttribute
            };
            return param;
          });
          inputDynamicAttribute.attributeParams = params;
          return [...attributesArr, inputDynamicAttribute];
        },
        []
      );
    }
    return inputDynamicAttributes;
  }

  /**
   * @description get variables from flow variables.
   * @function
   * @param {Array} flowVariables
   * @return {Array} variables
   */
  function getVariablesFromFlowVariables(flowVariables) {
    let variables = [];
    let tempVariables = [];
    if (Array.isArray(flowVariables)) {
      variables = flowVariables.reduce((allVariables, flowVariable) => {
        tempVariables = flowVariable.reduce((collectedVariables, flow) => {
          let someVariables = [];
          if (Array.isArray(flow.timeSeriesParams)) {
            someVariables = flow.timeSeriesParams.map(param => {
              return param.name;
            });
          }
          if (Array.isArray(flow.attributeParams)) {
            someVariables = flow.attributeParams.map(param => {
              return param.name;
            });
          }
          return [...collectedVariables, ...someVariables];
        }, []);
        return [...allVariables, ...tempVariables];
      }, []);
    }
    return variables;
  }

  /**
   * @description gets readable physical data for input time series.
   * @function
   * @param {Object} inputTimeSeries
   * @return {String}
   */
  async function getPhysicalData(inputTimeSeries) {
    try {
      let res = await PhysicalCollectionService.getReadablePhysicalData(
        inputTimeSeries
      );
      if (
        res != null &&
        res.physicalQuantity != null &&
        res.readableMeasurementUnit != null
      ) {
        return `${res.physicalQuantity.name} ${res.readableMeasurementUnit}`;
      }
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  /**
   * @description populates input timeseries with physical data.
   * @function
   * @param {Object} flow
   * @return {Object}
   */
  async function populateInputTimeSeriesPhysicalData(flow) {
    if (
      flow != null &&
      flow.data != null &&
      Array.isArray(flow.data.inputTimeSeries) &&
      flow.data.inputTimeSeries.length > 0
    ) {
      const promises = flow.data.inputTimeSeries.map(inputTimeSeries =>
        getPhysicalData(inputTimeSeries.timeSeries)
      );
      const result = await Promise.all(promises);
      let inputTimeSeries = flow.data.inputTimeSeries.map(
        (inputTimeSeries, index) => {
          if (result[index] != null) {
            return {
              ...inputTimeSeries,
              physicalData: result[index]
            };
          }
          return inputTimeSeries;
        }
      );

      flow.data.inputTimeSeries = inputTimeSeries;
    }
    return flow;
  }

  /**
   * @description get common properties maintenance flow form configuration.
   * @function
   * @param {Object} entityId
   * @param {Array} steps - Array of objects, containing tangoWizard steps.
   * @param {Object} duplicateData - object containing data for duplicating
   * @return {Object} configuration
   */
  async function get(options) {
    let storeState = $ngRedux.getState();

    let commonStep;
    let dataSamplingType;
    const {
      item,
      timeSeries,
      timeSeriesConfiguration
    } = await TimeSeriesFlowHelper.getFlowData({
      ...options,
      entity: 'calculated-flows',
      populate:
        'inputTimeSeries.timeSeries,inputDynamicAttributes.attributeObjectId,inputDynamicAttributes.domainAttribute',
      processResult: TimeSeriesVariableService.populateScheduler,
      preProcessResult: populateInputTimeSeriesPhysicalData
    });
    const { steps, duplicateData, actionType } = options;

    if (Array.isArray(steps)) {
      commonStep = steps.find(
        step => step.formConfiguration.name === 'timeSeries'
      );
      if (
        commonStep != null &&
        commonStep.api != null &&
        typeof commonStep.api.getValue === 'function'
      ) {
        dataSamplingType = commonStep.api.getValue('dataSamplingType');
      }
    } else if (duplicateData != null && duplicateData.dataSampling != null) {
      dataSamplingType = duplicateData.dataSampling;
    } else if (timeSeries != null) {
      dataSamplingType = timeSeries.dataSamplingType;
    } else if (timeSeriesConfiguration != null) {
      //EDIT CONFIG
      let timeSeriesItem =
        storeState.timeSeries[timeSeriesConfiguration.timeSeries].data;
      if (timeSeriesItem != null) {
        dataSamplingType = timeSeriesItem.dataSamplingType;
      }
    }

    let title;
    switch (actionType) {
    case 'update':
      title = gettextCatalog.getString('Update calculated flow');
      break;
    case 'create':
      title = gettextCatalog.getString('Create calculated flow');
      break;
    case 'duplicate':
      title = gettextCatalog.getString('Duplicate calculated flow');
      break;
    default:
      title = gettextCatalog.getString('Calculated flow');
    }

    let inputDynamicAttributes = getInputDynamicAttributes(item);
    let config = {
      name: 'calculatedFlow',
      actions: [
        {
          //SAVE ACTION
          title: gettext('Save'),
          fn: async form => {
            let bodyData = form.getValues();
            let body = mapBodyData({}, bodyData);
            //UPDATE
            if (
              item != null &&
              timeSeriesConfiguration != null &&
              actionType === 'update'
            ) {
              const flowParams = {
                query: { timeSeriesId: item.timeSeries, id: item._id },
                body
              };
              const configurationParams = {
                query: {
                  timeSeriesId: item.timeSeries,
                  id: timeSeriesConfiguration._id
                },
                body: {
                  validFrom: bodyData.validFrom[0],
                  validTo: Array.isArray(bodyData.validTo)
                    ? bodyData.validTo[0]
                    : null
                }
              };
              let actionFlow = calculatedFlowAction.updateCalculatedFlow;
              await $ngRedux.dispatch(actionFlow(flowParams));
              if (timeSeriesConfiguration.validTo == null) {
                let action = timeSeriesConfigurationsAction.updateTimeSeriesConfigurations(
                  configurationParams
                );
                $ngRedux.dispatch(action);
              }
            } else {
              //CREATE
              try {
                let timeSeriesConfigurationBody = {
                  validFrom: bodyData.validFrom[0],
                  flowRef: 300
                };
                let createdFlow = await CalculatedFlowModel.create(
                  { timeSeriesId: timeSeries._id },
                  body
                );
                let actionFlow = calculatedFlowAction.addCalculatedFlow;
                await $ngRedux.dispatch(actionFlow({ result: createdFlow }));
                timeSeriesConfigurationBody.flow = createdFlow.data._id;
                let createdTimeSeriesConfiguration = await TimeSeriesConfigurationModel.create(
                  { timeSeriesId: timeSeries._id },
                  timeSeriesConfigurationBody
                );
                let action =
                  timeSeriesConfigurationsAction.addTimeSeriesConfigurations;
                await $ngRedux.dispatch(
                  action({ result: createdTimeSeriesConfiguration })
                );
                CrudToastFactory.toast('create');
              } catch (err) {
                AlertingService.Error(err);
              }
            }
            $mdDialog.hide();
          },
          color: 'primary',
          disabledFn: form => {
            if (form.formValidity() === true) {
              return false;
            }
            return true;
          }
        }
      ],
      title,
      fields: [
        {
          id: 'triggerType',
          title: gettextCatalog.getString('Trigger type'),
          type: {
            name: 'radio',
            options: {
              layout: 'row',
              items: TranslationService.GetCollection(
                'codelists.triggerTypes'
              ).filter(item => {
                // Data sampling type is irregular
                if (dataSamplingType == 200) {
                  // Retun trigger type - triggered
                  return item.id == 100;
                }
                return item.id !== 100;
              }),
              modelProperty: 'id',
              display: data => {
                return data && data.name
                  ? data.name
                  : gettextCatalog.getString('Unknown trigger type');
              }
            }
          },
          /**
           * @description when trigger type is cron trigged, remove trigger switch on time-series variables.
           * @function
           * @param {object} api
           */
          onChange: api => {
            const triggerType = api.getValue('triggerType');
            api.setFieldConfigurationProperty(
              'flowVariables',
              'type.options.noTrigger',
              triggerType == 300
            );
            api.revalidate();
          },
          disable: () => actionType === 'update',
          initialize: () => {
            // If sampling type is reggular return trigger type 'cron triggered' else return 'triggered'
            let triggerTypeVal = dataSamplingType === 100 ? 200 : 100;
            return item != null && item.triggerType != null
              ? item.triggerType
              : triggerTypeVal;
          },
          validators: {
            dataSampling: {
              /**
               * @description if common properties sampling type === 100 => triggerType == 200.
               * @function
               */
              fn: (_, __, api) => {
                if (commonStep != null && commonStep.api != null) {
                  const dataSamplingType = commonStep.api.getValue(
                    'dataSamplingType'
                  );
                  const triggerType = api.getValue('triggerType');
                  if (dataSamplingType == 200 && triggerType != 100) {
                    return false;
                  }
                }

                return true;
              },
              text: gettextCatalog.getString(
                'Irregular sampling type is not compatible with selected trigger type'
              )
            }
          },
          required: true
        },
        {
          id: 'flowVariables',
          type: {
            name: 'flowVariables',
            options: {
              showDynamicAttributes: true,
              regularInput: dataSamplingType == 100,
              noTrigger: item && item.triggerType == 300,
              timeSeriesId: options ? options.timeSeriesId : null
            }
          },
          initialize: () => {
            let flowVariables = [];
            if (item != null) {
              flowVariables = [item.inputTimeSeries, inputDynamicAttributes];
            }
            if (
              duplicateData != null &&
              duplicateData.useCurrentTimeSeriesAsInputParameter != null
            ) {
              let timeSeriesObj = {
                timeSeries: duplicateData.useCurrentTimeSeriesAsInputParameter,
                triggersCalculation: false,
                timeSeriesParams: [
                  {
                    mappingFunction: null,
                    mappingFunctionArguments: [],
                    name: TimeSeriesVariableService.createVariableName(
                      flowVariables[0],
                      flowVariables[1]
                    )
                  }
                ]
              };
              if (
                Array.isArray(flowVariables) &&
                flowVariables.length > 0 &&
                Array.isArray(flowVariables[0])
              ) {
                flowVariables[0] = [...flowVariables[0], timeSeriesObj];
              } else {
                flowVariables[0] = [timeSeriesObj];
              }
            }
            return flowVariables;
          },
          onChange: form => {
            $timeout(form.revalidate);
          }
        },
        {
          id: 'expressionTitle',
          type: {
            name: 'title',
            options: {
              value: gettextCatalog.getString('Expression'),
              theme: 'primary'
            }
          }
        },
        {
          id: 'expression',
          title: gettextCatalog.getString('Expression'),
          type: {
            name: 'mathExpression',
            options: {
              allowedVariables: form => {
                let flowVariables = form.getValue('flowVariables');
                return getVariablesFromFlowVariables(flowVariables);
              }
            }
          },
          initialize: () => {
            return item ? item.expression : '';
          },
          required: true
        }
      ]
    };
    return config;
  }

  return { get, mapBodyData, getTimeSeriesParam };
}
