ImageModel.$inject = ['ModelUtilities'];

function ImageModel(ModelUtilities) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'pictures/:imageId/image',
    imageArrayBuffer: true
  };

  var networkConfigurationProfileImage = {
    host: 'account',
    path: 'fileup/image/:imageId',
    imageArrayBuffer: true
  };

  var networkConfigurationJdenticon = {
    host: 'identity',
    path: 'gravatar',
    imageArrayBuffer: true
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readProfileImage: ModelUtilities.crud(
        'GET',
        networkConfigurationProfileImage,
        modelStorage
      ),
      jdenticon: ModelUtilities.crud(
        'GET',
        networkConfigurationJdenticon,
        modelStorage
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default ImageModel;
