SopModel.$inject = ['ModelUtilities', '$q', 'CrawlerMethods'];

function SopModel(ModelUtilities, $q, CrawlerMethods) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'sops'
  };

  const networkConfigurationAllLAng = {
    host: 'api',
    path: 'sops',
    allLang: true
  };

  const crawlerConfiguration = {
    entity: 'sops',
    method: 'custom.readAllLang',
    populate: 'steps'
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readAllLang: ModelUtilities.crud(
        'GET',
        networkConfigurationAllLAng,
        modelStorage
      ),
      readWithSteps: CrawlerMethods.crawler(crawlerConfiguration)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default SopModel;
