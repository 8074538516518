module.exports = {
  entity: 'time-series-groups',
  path: 'time-series-groups',
  entityId: 237,
  storeKey: 'timeSeries',
  name: {
    en: 'Time Series Group',
    sl_SI: 'Skupina Časovnih serij'
  },
  networkModel: {
    entity: 'time-series-groups',
    read: 'read'
  },
  scope: 'tis_be:time-series-groups',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select time series group',
      sl_SI: 'Izberite skupino časovnih serij'
    },
    listInfo: {
      en: 'Select time series group from the list below',
      sl_SI: 'Izberite skupino časovnih serij s spodnjega seznama'
    }
  }
}
