/**
 * @ngdoc component
 * @name common.sfeIconPicker
 * @description Is a component that calls a dialog window in which you choose an icon
 * @param {Object} selectedIcon - the icon that was selected.
 * @example
 * <sfe-icon-picker
 *   selected-icon="vm.selectedIcon"
 * ></sfe-icon-picker>
 */
import './sfe-icon-picker.scss';
import template from './sfe-icon-picker.component.html';

export default {
  template,
  controller: sfeIconPickerController,
  controllerAs: 'vm',
  bindings: {
    selectedIcon: '=?'
  }
};

sfeIconPickerController.$inject = ['sfeIconPickerDialogService'];

function sfeIconPickerController(sfeIconPickerDialogService) {
  var vm = this;
  var selectedIcon = {};

  vm.selectedIcon = selectedIcon;
  vm.clearSelectedIcons = clearSelectedIcons;
  vm.openIconPickerDialog = openIconPickerDialog;
  vm.remove = remove;
  /**
   * @description clears the currently selected icon.
   * @function
   */
  function clearSelectedIcons() {
    vm.selectedIcon = {};
  }
  /**
   * @description opens the dialog and sets selectedIcon to the one selected in the dialog.
   * @function
   */
  function openIconPickerDialog() {
    sfeIconPickerDialogService
      .showSelectIconDialog(vm.selectedIcon)
      .then(function(res) {
        vm.selectedIcon = res;
      });
  }
  /**
   * @description setts all values in the vm.selectedIcon to null;
   * @function
   */
  function remove() {
    vm.selectedIcon = { type: null, name: null, color: null };
  }
}
