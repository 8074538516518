/**
 * @ngdoc service
 * @name common.MeasuringpointcountertypelistModel
 * @module common
 * @description
 * MeasuringpointcountertypelistModel
 */
/**
 * @ngdoc method
 * @name common.MeasuringpointcountertypelistModel#
 * @module common
 * @methodOf common.MeasuringpointcountertypelistModel
 * @param {Object} networkParameters request parameters
 * @returns {$resource}
 */
MeasuringpointcountertypelistModel.$inject = ['ModelUtilities'];

function MeasuringpointcountertypelistModel(ModelUtilities) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'view',
    path: 'measuringpointcountertypelist'
  };

  var methods = {
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default MeasuringpointcountertypelistModel;
