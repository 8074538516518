import './sfe-image.scss';
import template from './sfe-image.directive.html';
/**
 * @ngdoc directive
 * @name common.sfeImage
 * @description component for displaying images (they are displayed as background-images).
 * @param {string} - endpoint - url to the image.
 * @param {string} alt - url for the alternative image.
 * @param {string} gravatarId - id of gravatar.
 * @param {Object} imageSize - object containing information about the image size (in bytes) and size using unit prefixes
 * @example
 * <sfe-image
 *   endpoint="vm.endpoint"
 *   alt="vm.altImageLocation"
 *   gravatarId="vm.gravatarId "
 *   image-size="vm.size"
 * ></sfe-image
 */
const sfeImage = {
  template,
  bindings: {
    endpoint: '<',
    alt: '@',
    gravatarId: '<',
    imageSize: '=?'
  },
  controller: sfeImageController,
  controllerAs: 'vm'
};
export default sfeImage;

sfeImageController.$inject = ['xhrService', '$element', 'ImageModel'];

function sfeImageController(xhrService, $element, ImageModel) {
  var vm = this;
  var suffix = ['B', 'KB', 'MB'];

  vm.$onChanges = async function(changes) {
    if (vm.endpoint) {
      if (changes.endpoint) {
        setImage();
      }
    } else if (vm.gravatarId) {
      await setGravatar();
    } else if (vm.alt) {
      setAlt();
    }
  };

  /**
   * @description sets image's background-image.
   * @function
   * @param {string} imageUrl - url to the picture
   */
  async function setUrl(imageUrl) {
    let jdenticon;
    if (vm.gravatarId) {
      jdenticon = await ImageModel.custom
        .jdenticon({ id: vm.gravatarId })
        .catch(() => null);
    }
    let image = $element[0].querySelector('.sfe-image-container__image');
    const imageUrlHtml = `url('${imageUrl}')`;
    const jdenticonHtml =
      jdenticon && jdenticon.data ? `,url('${jdenticon.data}')` : '';
    const ctrlAltHtml = vm.alt ? `,url('${vm.alt}')` : '';
    image.setAttribute(
      'style',
      `background-image: ${imageUrlHtml} ${jdenticonHtml} ${ctrlAltHtml}`
    );
    /*************
      MEMORY CLEANUP
      *************/
    image = null;
  }
  /**
   * @description sets component's background-image gravatar url
   */
  async function setGravatar() {
    if (vm.gravatarId) {
      let jdenticon = await ImageModel.custom
        .jdenticon({ id: vm.gravatarId })
        .catch(() => null);
      if (jdenticon && jdenticon.data) {
        let image = $element[0].querySelector('.sfe-image-container__image');
        image.setAttribute(
          'style',
          `background-image: url( ${jdenticon.data})`
        );
        /*************
          MEMORY CLEANUP
          *************/
        image = null;
      }
    }
  }
  /**
   * @description sets component's alternate background-image url.
   * @function
   */
  function setAlt() {
    let image = $element[0].querySelector('.sfe-image-container__image');
    image.setAttribute('style', `background-image: url(${vm.alt})`);
    /*************
      MEMORY CLEANUP
      *************/
    image = null;
  }

  /**
   * @description fetches image from server and calls function that sets the image.
   * @function
   */
  function setImage() {
    vm.isLoading = true;
    xhrService
      .asyncFetch(vm.endpoint, 'arraybuffer')
      .then(async function(res) {
        var arrayBufferView = new Uint8Array(res.response);
        var blob = new Blob([arrayBufferView], {
          type: 'image/jpeg'
        });
        vm.imageSize = {
          size: blob.size,
          pretty: prettySize(blob.size)
        };
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        await setUrl(imageUrl);
        vm.isLoading = false;
      })
      .catch(async function() {
        await setGravatar();
        vm.isLoading = false;
      });
  }
  /**
   * @description calculates the unit prefix for file (image) size.
   * @function
   * @param {number} - size of the image in bytes
   * @return {string} size of the file with unit prefix
   */
  function prettySize(size) {
    for (var count = 0; !(size < 1000); count++) {
      size /= 1024;
    }
    return size.toFixed(2).toString() + suffix[count];
  }
}
