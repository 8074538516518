import { DateTime } from 'luxon';

SyncExternalDatasourceValues.$inject = [
  'gettextCatalog',
  'gettext',
  '$mdDialog',
  '$timeout',
  'TimeSeriesModel',
  'AlertingService',
  'LocalizationService',
  'ToastService',
  'SfeForm2DateFromPriorToValidationService',
  '$ngRedux'
];

function SyncExternalDatasourceValues(
  gettextCatalog,
  gettext,
  $mdDialog,
  $timeout,
  TimeSeriesModel,
  AlertingService,
  LocalizationService,
  ToastService,
  SfeForm2DateFromPriorToValidationService,
  $ngRedux
) {
  /**
   * @description map body data.
   * @function
   * @param {Object} body
   * @param {Object} bodyData
   */
  const mapBodyData = (body, bodyData) => {
    if (Array.isArray(bodyData.intervalFrom)) {
      body.intervalFrom = DateTime.fromJSDate(
        new Date(bodyData.intervalFrom[0])
      )
        .setLocale(LocalizationService.getLocale())
        .toFormat('dd/MM/yyyy HH:mm:ss');
    }
    if (Array.isArray(bodyData.intervalTo)) {
      body.intervalTo = DateTime.fromJSDate(new Date(bodyData.intervalTo[0]))
        .setLocale(LocalizationService.getLocale())
        .toFormat('dd/MM/yyyy HH:mm:ss');
    }
    return body;
  };
  /**
   * @description returns array of  objects that contain datasource.
   * @function
   * @param {string} entityId timeSeries id
   * @return {Array} [{validFrom, validTo?}]
   */
  function getTimeSeriesExternalReaderConfigurationRanges(entityId) {
    const state = $ngRedux.getState();
    if (
      state != null &&
      state.timeSeriesConfigurations != null &&
      state.timeSeriesConfigurations.list != null &&
      state.timeSeriesConfigurations.list[entityId] != null &&
      Array.isArray(state.timeSeriesConfigurations.list[entityId].data)
    ) {
      return state.timeSeriesConfigurations.list[entityId].data.reduce(
        (result, config) => {
          if (config.flowRef == 100) {
            let flowObject = config.flowObject;
            if (
              state.externalReaderFlow != null &&
              state.externalReaderFlow[config.flow] != null &&
              state.externalReaderFlow[config.flow].data != null
            ) {
              flowObject = state.externalReaderFlow[config.flow].data;
            }
            if (flowObject.externalDatasource != null) {
              const validFrom = DateTime.fromJSDate(new Date(config.validFrom));
              let validTo;
              if (config.validTo != null) {
                validTo = DateTime.fromJSDate(new Date(config.validTo));
              }
              return [
                ...result,
                {
                  validFrom,
                  validTo
                }
              ];
            }
          }
          return result;
        },
        []
      );
    }
    return [];
  }
  /**
   * @description returns array that allows to enable certain dates in the calendar.
   * @function
   * @param {Array} configurations configurations validity
   * @return {Array}
   */
  function getEnableDateFilter(configurations) {
    return [
      date => {
        if (date != null) {
          const luxonDate = DateTime.fromJSDate(new Date(date));
          return configurations.reduce((result, config) => {
            if (config.validTo == null) {
              return (
                result ||
                luxonDate.startOf('day') >= config.validFrom.startOf('day')
              );
            }
            return (
              result ||
              (luxonDate.startOf('day') >= config.validFrom.startOf('day') &&
                luxonDate.endOf('day') <= config.validTo.endOf('day'))
            );
          }, false);
        }
        return false;
      }
    ];
  }
  /**
   * @description determines when date is inside erf limits .
   * @function
   * @param {Array} configurations erf configuration dates
   * @param {Date} date
   * @return {Boolean}
   */
  function dateInsideErf(configurations, date) {
    return configurations.reduce((result, config) => {
      if (config.validTo == null) {
        return result || date >= config.validFrom;
      }
      return result || (date >= config.validFrom && date <= config.validTo);
    }, false);
  }
  /**
   * @description returns validation configuration.
   * @function
   * @param {Array} configurations erf configuration dates
   * @return {Object}
   */
  function getErfFlowValidation(configurations) {
    return {
      /**
       * @description validates when from and to dates are inside of the same erf configuration
       * @function
       * @param {Object} api from api object
       * @return {Boolean}
       */
      fn: (_, __, api) => {
        if (api && typeof api.getValue === 'function') {
          let toValue = api.getValue('intervalTo');
          let fromValue = api.getValue('intervalFrom');
          let luxonFrom;
          let luxonTo;
          if (Array.isArray(fromValue) && fromValue.length > 0) {
            luxonFrom = DateTime.fromJSDate(new Date(fromValue));
          }
          if (Array.isArray(toValue) && toValue.length > 0) {
            luxonTo = DateTime.fromJSDate(new Date(toValue));
          }
          if (luxonTo != null && luxonFrom != null) {
            return configurations.some(config => {
              if (config.validTo == null) {
                return luxonFrom >= config.validFrom;
              }
              return (
                luxonFrom >= config.validFrom &&
                luxonFrom <= config.validTo &&
                luxonTo >= config.validFrom &&
                luxonTo <= config.validTo
              );
            });
          }
        }
        return true;
      },
      text: gettextCatalog.getString(
        'From/to range should be within date limits of external reader flow configuration'
      )
    };
  }
  /**
   * @description returns validation configuration.
   * @function
   * @param {Array} configurations erf configuration dates
   * @param {string} type from, to
   * @return {Object}
   */
  function getSingleDateErfValidation(configurations, type) {
    return {
      /**
       * @description validates when from or to date is inside of an erf configuration
       * @function
       * @param {Object} api from api object
       * @return {Boolean}
       */
      fn: modelValue => {
        if (
          Array.isArray(modelValue) &&
          modelValue.length > 0 &&
          modelValue[0] != null
        ) {
          const dateValue = modelValue[0];

          const luxonDate = DateTime.fromJSDate(new Date(dateValue));
          let fromInsideErf = dateInsideErf(configurations, luxonDate);
          if (fromInsideErf == false) {
            return false;
          }
        }
        return true;
      },
      text: gettextCatalog.getString(
        `${
          type === 'to'
            ? gettextCatalog.getString('To')
            : gettextCatalog.getString('From')
        } date should be within external reader flow date limits`
      )
    };
  }

  /**
   * @description get SyncExternalDatasourceValues configuration.
   * @function
   * @param {string} entityId
   * @return {Object}  configuration
   */
  const get = async entityId => {
    const erfConfigs = getTimeSeriesExternalReaderConfigurationRanges(entityId);
    let fromToValidation = SfeForm2DateFromPriorToValidationService.get(
      'intervalFrom',
      'intervalTo'
    );

    const enable = getEnableDateFilter(erfConfigs);
    const erfFlowsValidationTo = getSingleDateErfValidation(erfConfigs, 'to');
    const erfFlowsValidationFrom = getSingleDateErfValidation(
      erfConfigs,
      'from'
    );
    const erfFlowsValidation = getErfFlowValidation(erfConfigs);
    let configuration = {
      name: 'syncExternalDatasourceValues',
      actions: [
        {
          title: gettext('Save'),
          fn: async form => {
            try {
              let bodyData = form.getValues();
              let body = mapBodyData({}, bodyData);
              await TimeSeriesModel.custom.syncExternalDatasourceValues(
                { timeSeriesId: entityId },
                body
              );
              ToastService.showToast(
                gettextCatalog.getString(
                  'History job request successfully sent'
                )
              );
            } catch (error) {
              AlertingService.Error(error);
            }
            $mdDialog.hide();
          },
          disabledFn: form => {
            let valid = form.formValidity();
            return valid === true ? false : true;
          },
          color: 'primary',
          raised: false
        }
      ],
      title: gettextCatalog.getString('Sync external datasource values'),
      fields: [
        {
          id: 'intervalFrom',
          title: gettextCatalog.getString('Interval from'),
          type: {
            name: 'date',
            options: {
              enableTime: true,
              customOptions: {
                enable,
                allowInvalidPreload: true
              }
            }
          },
          validators: {
            beforeIntervalTo: fromToValidation,
            erfFlowsValidationFrom,
            erfFlowsValidation
          },
          initialize: () => {
            return null;
          },
          onChange: form => {
            $timeout(form.revalidate);
          },
          required: true,
          width: 6
        },
        {
          id: 'intervalTo',
          title: gettextCatalog.getString('Interval to'),
          type: {
            name: 'date',
            options: {
              enableTime: true,
              customOptions: {
                enable,
                allowInvalidPreload: true
              }
            }
          },
          validators: {
            afterIntervalFrom: fromToValidation,
            erfFlowsValidationTo,
            erfFlowsValidation
          },
          initialize: () => {
            return null;
          },
          onChange: form => {
            $timeout(form.revalidate);
          },
          required: true,
          width: 6
        }
      ]
    };
    return configuration;
  };
  return { get };
}
export default SyncExternalDatasourceValues;
