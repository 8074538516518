import actionTypes from '../action/time-series-processing-values.type';

function TimeSeriesConfigurationsReducer(state = {}, action) {
  switch (action.type) {
  case actionTypes.TIME_SERIES_PROCESSING_VALUES_ADD:
    return {
      ...state,
      [action.payload.id]: action.payload.state
    };
  case actionTypes.TIME_SERIES_PROCESSING_VALUES_REMOVE:
    // eslint-disable-next-line no-case-declarations
    const { [action.payload.id]: _, ...newState } = state;
    return newState;
  default:
    return state;
  }
}

export default TimeSeriesConfigurationsReducer;
