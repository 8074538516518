ThemingService.$inject = ['theming', '$rootScope', 'StorageService'];

function ThemingService(theming, $rootScope, StorageService) {
  var self = this;
  var defaultTheme = 'tango';
  var bodyEl = document.getElementById('body-main');

  this.themeDefinedColors = ['primary', 'warn', 'accent', 'success'];

  self.getThemes = function() {
    return theming.themes();
  };

  self.getTheme = function(name) {
    return theming.theme(name);
  };

  self.setModuleTheme = function(module, theme) {
    theming.setModuleTheme(module, theme);
  };

  self.setGlobalTheme = function(theme, saveTolocalStorage) {
    var selectedTheme = theme ? theme : defaultTheme;
    var currentTheme =
      'theme-' +
      ($rootScope.activeTheme ? $rootScope.activeTheme : defaultTheme);
    $rootScope.activeTheme = selectedTheme;
    $rootScope.activeThemeAccent = selectedTheme + '-accent';
    $rootScope.$emit('changeTheme');

    // change class on <body>
    var regx = new RegExp('\\b' + currentTheme + '.*?\\b', 'g');
    bodyEl.className = bodyEl.className.replace(regx, 'theme-' + selectedTheme);

    if (saveTolocalStorage) {
      StorageService.save('selectedTheme', selectedTheme);
    }
  };

  self.getShadeColor = function(paletteName, shadeName, returnValue) {
    if (returnValue && (returnValue !== 'bg' && returnValue !== 'text')) {
      return;
    }

    var paletteColorName = null;
    var returnColor = null;

    if (self.isThemeDefined(paletteName)) {
      var currentTheme = $rootScope.activeTheme;
      var theme = self.getTheme(currentTheme);

      if (theme) {
        paletteColorName = theme[paletteName];
      }
    } else {
      // prepend default prefix
      paletteColorName = paletteName ? paletteName + '-primary' : null;
    }

    // get color
    var shadeColor = theming.getShade(paletteColorName, shadeName);

    if (!shadeColor) {
      return;
    }

    switch (returnValue) {
    case 'bg':
      returnColor = self.hex2Rgb(shadeColor.value);
      break;

    case 'text':
      returnColor = self.hex2Rgb(shadeColor.contrast);
      break;

    default:
      returnColor = {
        bg: self.hex2Rgb(shadeColor.value),
        text: self.hex2Rgb(shadeColor.contrast)
      };
      break;
    }

    return returnColor;
  };

  self.getShadeValues = function() {
    var shades = theming.shades();
    var firstShade = Object.keys(shades)[0];

    if (firstShade) {
      return Object.keys(shades[firstShade]);
    }
  };

  self.hex2Rgb = function(value) {
    return value.length === 3
      ? 'rgb(' + value[0] + ', ' + value[1] + ', ' + value[2] + ')'
      : 'rgba(' +
          value[0] +
          ', ' +
          value[1] +
          ', ' +
          value[2] +
          ', ' +
          value[3] +
          ')';
  };

  self.rgb2Rgba = function(rgbVal, opacity) {
    if (opacity > 0) {
      opacity = opacity / 100;
    }

    var rgba = rgbVal.replace('rgb', 'rgba');
    rgba = rgba.replace(')', ', ' + opacity + ')');

    return rgba;
  };

  // check if colorName is theme defined (primary, warn or accent)
  self.isThemeDefined = function(colorName) {
    return self.themeDefinedColors.indexOf(colorName) !== -1 ? true : false;
  };

  return self;
}

export default ThemingService;
