GetTimeSeriesProcessingByTimestampService.$inject = [
  'AlertingService',
  'TimeSeriesProcessingExtendedValuesByTimestampModel',
  'TimeSeriesProcessingExtendedValuesByTimestampDialogService',
  'TimeSeriesConfigurationModel'
];

export default function GetTimeSeriesProcessingByTimestampService(
  AlertingService,
  TimeSeriesProcessingExtendedValuesByTimestampModel,
  TimeSeriesProcessingExtendedValuesByTimestampDialogService,
  TimeSeriesConfigurationModel
) {
  /**
   * @description gets time series processings based on timestamp
   * @param {Object} params Object containing data from the chart
   * @param {Object} timeSeries
   * @function
   * @return {Function} Redirect state.
   */
  async function openExtendedData(params, timeSeries) {
    const id = params.colDef.field;
    const dateTime = params.data.time.rawDate;
    const formatter = params.formatValue;
    if (dateTime) {
      try {
        const {
          data: configurationData
        } = await TimeSeriesConfigurationModel.read({
          timeSeriesId: id,
          isActive: true
        });
        let isMaintenanceFlow = false;
        if (
          Array.isArray(configurationData) &&
          configurationData.length &&
          configurationData[0].flowRef >= 500 &&
          configurationData[0].flowRef < 600
        ) {
          isMaintenanceFlow = true;
        }
        let dialogData;
        if (isMaintenanceFlow) {
          dialogData = [
            {
              streamArchive: {
                validAt: dateTime,
                value: params.value
              },
              processLog: [],
              isMaintenanceFlow
            }
          ];
        } else {
          const {
            data
          } = await TimeSeriesProcessingExtendedValuesByTimestampModel.read({
            timeSeriesId: id,
            timestamp: dateTime
          });
          dialogData = data;
        }
        await TimeSeriesProcessingExtendedValuesByTimestampDialogService.open(
          dialogData,
          {},
          id,
          formatter,
          timeSeries
        );
      } catch (err) {
        AlertingService.Error(err);
      }
    }
  }

  return {
    openExtendedData
  };
}
