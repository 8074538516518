settingsConstants.$inject = ['gettext', 'gettextCatalog'];
/**
 * @ngdoc service
 * @name core.settingsConstants
 * @description returns array of settings dialog menu configurations.
 */
function settingsConstants(gettext, gettextCatalog) {
  return [
    {
      title: gettext('Alarm configuration'),
      icon: 'warning',
      categories: [
        {
          title: gettext('Alarm types'),
          route: 'configurations-alarm-configuration-alarm-types-list'
        },
        {
          title: gettext('Severities'),
          route: 'configurations-alarm-configuration-severities-list'
        },
        {
          title: gettext('Responses'),
          route: 'configurations-alarm-configuration-responses-list'
        }
      ]
    },
    {
      title: gettext('General'),
      icon: 'settings',
      categories: [
        {
          title: gettext('National holidays'),
          route: 'configurations-general-national-holidays-list'
        },
        {
          title: gettext('Document Classifications'),
          route: 'configurations-general-document-classifications-list'
        }
      ]
    },
    {
      title: gettext('Company resources'),
      icon: 'business',
      categories: [
        {
          title: gettext('Assets'),
          route: 'configurations-company-resources-assets-list'
        },
        {
          title: gettext('Locations'),
          route: 'configurations-company-resources-locations-list'
        },
        {
          title: gettextCatalog.getPlural(3, 'Time Series', 'Time Series', {}),
          route: 'configurations-company-resources-time-series-list'
        },
        {
          title: gettext('Measuring Point Types'),
          route: 'configurations-company-resources-measuring-point-types-list'
        },
        {
          title: gettext('Project'),
          route: 'configurations-company-resources-project-list'
        },
        {
          title: gettext('Ownerships'),
          route: 'configurations-company-resources-ownerships-list'
        },
        {
          title: gettext('Company Structure Nodes'),
          route: 'configurations-company-resources-company-structure-nodes-list'
        }
      ]
    },
    {
      title: gettext('Energy management'),
      icon: 'battery_charging_full',
      categories: [
        {
          title: gettext('Energy Source Types'),
          route: 'configurations-energy-management-energy-source-types-list'
        },
        {
          title: gettext('Counter Types'),
          route: 'configurations-energy-management-counter-types-list'
        },
        {
          title: gettext('Price list item groups'),
          route: 'configurations-energy-management-price-list-item-groups-list'
        },
        {
          title: gettext('Energy Management Items'),
          route: 'configurations-energy-management-energy-management-items-list'
        },
        {
          title: gettext('Tariffs'),
          route: 'configurations-energy-management-tariffs-list'
        },
        {
          title: gettext('Billing Types'),
          route: 'configurations-energy-management-billing-types-list'
        },
        {
          title: gettext('Billing kinds'),
          route: 'configurations-energy-management-billing-kinds-list'
        }
      ]
    },
    {
      title: gettext('IoT'),
      icon: 'devices_other',
      categories: [
        {
          title: gettext('Measurement units'),
          route: 'configurations-iot-units-of-measurement-list'
        }
      ]
    },
    {
      title: gettext('Dynamic attributes'),
      icon: 'web',
      categories: [
        {
          title: gettext('Domains'),
          route: 'configurations-dynamic-attributes-domains-list'
        },
        {
          title: gettext('Dynamic attributes'),
          route: 'configurations-dynamic-attributes-dynamic-attributes-list'
        }
      ]
    }
  ];
}

export default settingsConstants;
