module.exports = {
  entity: 'conflicts',
  path: 'conflicts',
  name: {
    en: 'Conflicts',
    sl_SI: 'Konflikti'
  },
  networkModel: {
    entity: 'conflicts',
    read: 'read'
  },
  scope: 'tis_be:conflicts',

  dialog: {
    title: {
      en: 'Select conflict',
      sl_SI: 'Izberite konflikt'
    },
    listInfo: {
      en: 'Select conflict from the list below',
      sl_SI: 'Izberite konflikt s spodnjega seznama'
    }
  }
}
