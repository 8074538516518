import indirectAccessDialogView from './indirect-access.dialog.html';
IndirectAccessDialog.$inject = ['$mdDialog'];
/**
 * @ngdoc service
 * @name user.IndirectAccessDialog
 * @description opens indirect access entity seelctor dialog and resolves to selected items.
 * @property {function} open
 */
export default function IndirectAccessDialog($mdDialog) {
  /**
   * @memberof IndirectAccessDialog.open
   * @description  opens indirect access entity selector dialog and resolves to selected items..
   * @param {string} treeId privilege management selected tree id
   * @param {string} userId selected user id
   * @param {string} entity entity identifier
   * @param {array} treeConfiguration array that is used to construct indirect access tree
   * @return {Promise}
   */
  function open(treeId, userId, entity, treeConfiguration) {
    return $mdDialog.show({
      controller: IndirectAccessDialogController,
      controllerAs: 'vm',
      template: indirectAccessDialogView,
      parent: angular.element(document.body),
      preserveScope: true,
      locals: {
        treeId: treeId,
        userId: userId,
        entity: entity,
        treeConfiguration: treeConfiguration
      }
    });
  }

  return {
    open: open
  };
}

IndirectAccessDialogController.$inject = [
  'treeId',
  'userId',
  'entity',
  'treeConfiguration',
  '$mdDialog',
  'gettext'
];

function IndirectAccessDialogController(
  treeId,
  userId,
  entity,
  treeConfiguration,
  $mdDialog,
  gettext
) {
  var vm = this;
  vm.isEmpty = isEmpty;
  vm.addItems = addItems;

  init();
  /**
   * @description sets values to scope.
   * @function
   */
  function init() {
    vm.treeId = treeId;
    vm.entity = entity;
    vm.userId = userId;
    vm.treeConfiguration = treeConfiguration;
    vm.header = {
      title: gettext('Indirect Access'),
      isDialog: true,
      actions: [
        {
          icon: {
            name: 'close'
          },
          cancel: true
        }
      ]
    };
  }
  /**
   * @description resolves dialog with selected items.
   * @function
   */
  function addItems() {
    $mdDialog.hide(vm.instances);
  }
  /**
   * @description indicates if vm.instances object is empty.
   * @function
   * @return {bool}
   */
  function isEmpty() {
    return _.isEmpty(vm.instances);
  }
}
