TimeSeriesManualInputConfiguration.$inject = [
  'gettext',
  'AlertingService',
  'TimeSeriesProcessingValuesModel',
  'Formatting',
  'gettextCatalog'
];

function TimeSeriesManualInputConfiguration(
  gettext,
  AlertingService,
  TimeSeriesProcessingValuesModel,
  Formatting,
  gettextCatalog
) {
  var configuration = {
    listId: 'timeSeriesListId',
    entity: {
      singular: gettextCatalog.getPlural(1, 'Time Series', 'Time Series', {}),
      plural: gettextCatalog.getPlural(3, 'Time Series', 'Time Series', {})
    },
    api: {
      query: {
        entity: 'time-series-manual-input-form-group-time-series-items',
        method: 'custom.readItemView'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'data-time-series-view',
        params: [
          {
            valueParam: 'timeSeriesId',
            queryParam: 'id'
          }
        ]
      }
    },
    attributes: [
      {
        title: gettext('Last value at'),
        param: 'lastValueTime',
        type: 'date'
      }
    ],
    badges: [
      {
        title: gettext('Last value'),
        type: 'physical-value',
        param: 'lastValue'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      },
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'name DESC',
        params: '-name'
      }
    ]
  };

  /*
   *
   *   ENRICH
   *
   */

  async function Enrich(item) {
    item.fetchingObject['lastValue'] = {
      fetching: true,
      success: false,
      nodata: false
    };
    item.fetchingObject['lastValueTime'] = {
      fetching: true,
      success: false,
      nodata: false
    };

    if (typeof item.timeSeries == 'object' && item.timeSeries != null) {
      item.timeSeriesId = item.timeSeries._id;
      item.name = item.timeSeries.name;
      item.timeZone = item.timeSeries.timeZone;
      try {
        let { data } = await TimeSeriesProcessingValuesModel.read({
          timeSeriesId: item.timeSeries._id,
          limit: 1,
          view: 'simple'
        });

        if (Array.isArray(data) && data.length > 0) {
          item.lastValue = Formatting.formatDatapointDisplay(
            item.timeSeries.dataType,
            data[0].value,
            item.timeSeries.precision
          );
          item.lastValueTime = data[0].validAt;
          item.fetchingObject['lastValueTime'] = {
            fetching: false,
            success: true,
            nodata: false
          };
          item.fetchingObject['lastValue'] = {
            fetching: false,
            success: true,
            nodata: false
          };
        } else {
          item.fetchingObject['lastValueTime'] = {
            fetching: false,
            success: true,
            nodata: true
          };
          item.fetchingObject['lastValue'] = {
            fetching: false,
            success: true,
            nodata: true
          };
        }
      } catch (err) {
        AlertingService.Error(err);
        item.fetchingObject['lastValueTime'] = {
          fetching: false,
          success: false,
          nodata: true
        };
        item.fetchingObject['lastValue'] = {
          fetching: false,
          success: false,
          nodata: true
        };
      }
    }
  }

  return configuration;
}
export default TimeSeriesManualInputConfiguration;
