NewDashboardController.$inject = [
  'gettext',
  '$smartAuth',
  '$stateParams',
  'AlertingService',
  'TranslationService',
  'MetadataService',
  'SfeHeader',
  'ToastService',
  'DashboardUserModel',
  'ListFormService',
  'CrudToastFactory',
  'CrawlerMethods',
  'dashboard'
];

function NewDashboardController(
  gettext,
  $smartAuth,
  $stateParams,
  AlertingService,
  TranslationService,
  MetadataService,
  SfeHeader,
  ToastService,
  DashboardUserModel,
  ListFormService,
  CrudToastFactory,
  CrawlerMethods,
  dashboard
) {
  var vm = this;
  var dashboardId = $stateParams.id;
  vm.dataConfig = {};
  vm.isEdit = !!dashboardId;
  var dashboardUser;

  init();
  /**
   * @description creates form data Object.
   * @function
   */
  function constructDataObject(dashboard) {
    dashboard.isMain = !!dashboardUser;
    if (typeof dashboard.refreshInterval !== 'undefined') {
      dashboard.refresh = true;
    } else {
      dashboard.refresh = false;
    }
    vm.dataConfig.dataObj = dashboard;
    MetadataService.Loading(false);
    MetadataService.ChangeMetadata(
      'Edit ' + dashboard.name,
      dashboard.description
    );
  }

  async function init() {
    var intervalTypes = TranslationService.GetCollection(
      'codelists.refreshIntervals'
    ).map(function(item) {
      item._id = item.id;
      return item;
    });
    var data = [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        type: 'text',
        required: true
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textField',
        type: 'text',
        required: false
      },
      {
        componentType: 'checkBox',
        label: gettext('Main'),
        name: 'isMain'
      },
      {
        componentType: 'checkBox',
        label: gettext('Refreshing'),
        name: 'refresh'
      },
      {
        componentType: 'radio2',
        label: gettext('Refresh interval'),
        name: 'refreshInterval',
        options: intervalTypes,
        hide: true,
        showParam: 'refresh'
      }
    ];

    var action;

    if (dashboardId) {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit dashboard'),
        'dashboards-dashboards-view',
        $stateParams
      );
      MetadataService.Loading(true);
      action = {
        ctrlFn: true,
        title: gettext('Update'),
        fn: createOrUpdate
      };
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New dashboard'),
        'dashboards-dashboards-list'
      );
      action = {
        ctrlFn: true,
        title: gettext('Create'),
        fn: createOrUpdate
      };
    }

    vm.dataConfig.data = data;
    vm.dataConfig.action = action;

    vm.dataConfig.dataObj = {
      name: '',
      description: '',
      isMain: false,
      refresh: false,
      refreshInterval: undefined
    };
    if (dashboardId) {
      vm.editMode = true;
      try {
        const { data } = await DashboardUserModel.read({
          dashboard: dashboard._id
        });

        if (data && data.length && data[0]) {
          dashboardUser = data[0]._id;
        }
        constructDataObject(dashboard);
      } catch (err) {
        AlertingService.Error(err);
      }
      MetadataService.Loading(false);
    }
  }

  function createOrUpdate() {
    const fieldNames = [
      'name',
      'description',
      'isMain',
      'owner',
      'refreshInterval'
    ];
    $smartAuth.profile().then(
      function(res) {
        vm.dataConfig.dataObj.refreshInterval = vm.dataConfig.dataObj.refresh
          ? vm.dataConfig.dataObj.refreshInterval
          : null;
        vm.dataConfig.dataObj.owner = res._id;
        vm.sendingRequest = true;
        const messageType = vm.editMode ? 'update' : 'create';
        const method = CrawlerMethods.getMethod({
          entity: 'dashboards',
          method: vm.editMode ? 'update' : 'create'
        });
        let methodIdObject = {};
        if (vm.editMode) {
          methodIdObject = {
            id: dashboardId
          };
        }
        method(
          methodIdObject,
          ListFormService.generateBodyObject(vm.dataConfig.dataObj, fieldNames)
        ).then(
          function(res) {
            const { data: dashboard } = res;
            vm.sendingRequest = false;
            var redirectObject = {
              state: 'dashboards-dashboards-view',
              paramName: 'id',
              paramValue: dashboard._id
            };
            var action;
            if (vm.editMode) {
              if (vm.dataConfig.dataObj.isMain && !dashboardUser) {
                action = 'create';
              } else if (!vm.dataConfig.dataObj.isMain && dashboardUser) {
                action = 'delete';
              }
            } else {
              if (vm.dataConfig.dataObj.isMain) {
                action = 'create';
              }
            }
            switch (action) {
            case 'create':
              DashboardUserModel.create({
                user: vm.dataConfig.dataObj.owner,
                dashboard: dashboard._id
              }).then(
                function() {
                  CrudToastFactory.toast(messageType, redirectObject);
                },
                function() {
                  ToastService.showToast(
                    gettext('Couldn\'t set main Dashboard'),
                    redirectObject
                  );
                }
              );
              break;
            case 'delete':
              DashboardUserModel.delete({
                id: dashboardUser
              }).then(
                function() {
                  CrudToastFactory.toast('update', redirectObject);
                },
                function() {
                  ToastService.showToast(
                    gettext('Couldn\'t remove main Dashboard'),
                    redirectObject
                  );
                }
              );
              break;
            default:
              CrudToastFactory.toast(messageType, redirectObject);
            }
          },
          function(err) {
            vm.sendingRequest = false;
            AlertingService.Error(err);
          }
        );
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }
}

export default NewDashboardController;
