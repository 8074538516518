NewWebserviceFormController.$inject = [
  'AlertingService',
  '$stateParams',
  'gettext',
  'MetadataService',
  'createOrUpdateService',
  'SfeHeader',
  'webservice',
  '$timeout'
];

function NewWebserviceFormController(
  AlertingService,
  $stateParams,
  gettext,
  MetadataService,
  createOrUpdateService,
  SfeHeader,
  webservice,
  $timeout
) {
  const vm = this;
  const webserviceId = $stateParams.id;
  const editMode = Boolean(webserviceId);

  vm.$onInit = function init() {
    vm.dataConfig = {
      action: {
        ctrlFn: true,
        title: editMode ? gettext('Update') : gettext('Create'),
        fn: createOrUpdate
      },
      data: getFields()
    };

    if (editMode) {
      MetadataService.Loading(true);
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit Web Service'),
        'external-datasources-connectors-webservice-view',
        $stateParams
      );

      if (webservice.credentials) {
        webservice.username = webservice.credentials.username;
        webservice.password = webservice.credentials.password;
        webservice.useSecurity = true;
      }
      vm.dataConfig.dataObj = webservice;
      $timeout(() => {
        MetadataService.Loading(false);
        MetadataService.ChangeMetadata(
          'Edit ' + webservice.name,
          webservice.description
        );
      });
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New Web Service'),
        'external-datasources-connectors-list'
      );
      vm.dataConfig.dataObj = {};
    }
  };

  function getFields() {
    return [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textArea',
        maxlength: 500,
        type: 'text',
        required: false
      },
      {
        componentType: 'autocompleteDialog',
        edit: editMode,
        configuration: {
          codelist: 'webServiceTypes',
          floatingLabel: gettext('Choose webservice type'),
          searchParamName: 'name',
          entity: 'webServiceTypes',
          required: true,
          noDialog: true
        },
        name: 'type'
      },
      {
        placeholder: gettext('URL'),
        name: 'url',
        componentType: 'textField',
        type: 'text'
      },
      {
        componentType: 'checkBox',
        label: gettext('Use security'),
        name: 'useSecurity'
      },
      {
        placeholder: gettext('Username'),
        componentType: 'textField',
        hide: true,
        name: 'username',
        showParam: 'useSecurity'
      },
      {
        placeholder: gettext('Password'),
        componentType: 'password',
        name: 'password',
        showParam: 'useSecurity',
        hide: true
      }
    ];
  }

  // create and update functions //

  function createOrUpdate() {
    vm.sendingRequest = true;
    createOrUpdateService
      .simpleCall(
        {
          entity: 'webservices',
          method: editMode ? 'update' : 'create'
        },
        editMode,
        webserviceId,
        dataToSave(),
        'external-datasources-connectors-webservice-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function dataToSave() {
    return {
      name: vm.dataConfig.dataObj.name,
      description: vm.dataConfig.dataObj.description,
      url: vm.dataConfig.dataObj.url,
      useSecurity: vm.dataConfig.dataObj.useSecurity,
      type: vm.dataConfig.dataObj.type.id,
      credentials: vm.dataConfig.dataObj.useSecurity
        ? {
          username: vm.dataConfig.dataObj.username,
          password: vm.dataConfig.dataObj.password
        }
        : null
    };
  }
}

export default NewWebserviceFormController;
