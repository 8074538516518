import template from './sfe-codelist-viewer.component.html';

/**
 * @ngdoc component
 * @name common.sfeCodelistViewer
 * @description displays codelist text
 * @param {string} codelist
 * @param {number} value
 * @example
 * <sfe-codelist-viewer
 *   codelist="workScheduleTypes"
 *   value="2"
 * ></sfe-codelist-viewer>
 */

export default {
  template,
  bindings: {
    codelist: '<',
    value: '<'
  },
  controllerAs: 'vm',
  controller: SfeCodelistViewer
};

SfeCodelistViewer.$inject = ['TranslationService', 'gettextCatalog'];

function SfeCodelistViewer(TranslationService, gettextCatalog) {
  const vm = this;
  vm.$onChanges = () => {
    vm.text = gettextCatalog.getString('No value');
    if (vm.codelist != null && vm.value != null) {
      let codeListValue = TranslationService.GetCollectionById(
        `codelists.${vm.codelist}`,
        vm.value
      );
      if (codeListValue != null) {
        vm.text = codeListValue.name;
      } else {
        vm.text = gettextCatalog.getString('Unknown value');
      }
    }
  };
}
