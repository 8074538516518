OPCController.$inject = [
  '$stateParams',
  'gettext',
  'TranslationService',
  'MetadataService',
  'TangoAgentConnectionConfigConfiguration',
  'gettextCatalog',
  'opc',
  '$timeout'
];

function OPCController(
  $stateParams,
  gettext,
  TranslationService,
  MetadataService,
  TangoAgentConnectionConfigConfiguration,
  gettextCatalog,
  opc,
  $timeout
) {
  var vm = this;
  var opcId = $stateParams.id;
  vm.configuration = TangoAgentConnectionConfigConfiguration.getConfigurationWithUpdatedStates();
  vm.entity = 'datasources';

  vm.$onInit = async function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    opc.type = TranslationService.GetCollectionById(
      'codelists.opcTypes',
      opc.type
    );
    vm.dataPointsFilter = {
      connector: opcId
    };
    vm.headerData = constructHeader(opc);
    vm.loading = false;
    $timeout(() => {
      MetadataService.ChangeMetadata(opc.name, opc.description);
      MetadataService.Loading(false);
    });
  };

  function constructHeader(opc) {
    var actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: opcId,
        endpoint: {
          entity: 'opcs',
          method: 'delete'
        },
        redirectState: 'external-datasources-connectors-list'
      },
      {
        title: gettext('Update'),
        state: 'external-datasources-connectors-opc-edit',
        param: 'id',
        paramValue: opcId
      }
    ];
    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];
    var properties = [];
    var propertySections = [];
    if (opc.type) {
      properties.push(
        {
          title: gettext('Connection type'),
          value: `${opc.type.name} (${gettextCatalog.getString(
            opc.type.description
          )})`,
          type: 'simple'
        },
        {
          title: 'URL',
          value: opc.url,
          type: 'simple'
        }
      );
      propertySections.push(
        {
          title: gettext('connection'),
          properties: properties
        },
        {
          title: gettext('Authorized personnel'),
          properties: userAccess,
          identifier: 'opcs',
          target: opc._id,
          type: 'userAccess'
        }
      );
    } else {
      propertySections.push({
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'opcs',
        target: opc._id,
        type: 'userAccess'
      });
    }

    return {
      metadata: {
        definition: gettext('OPC connection configuration'),
        title: opc.name,
        description: opc.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}

export default OPCController;
