import { DateTime } from 'luxon';

NewInvoiceController.$inject = [
  '$state',
  'gettext',
  'AlertingService',
  'CrudToastFactory',
  'gettextCatalog',
  'findPriceListDetailService',
  '$q',
  'MetadataService',
  'CrawlerMethods',
  'SfeHeader',
  '$timeout',
  'DetailInvoiceModel',
  'masterInvoice',
  'TranslationService'
];

function NewInvoiceController(
  $state,
  gettext,
  AlertingService,
  CrudToastFactory,
  gettextCatalog,
  findPriceListDetailService,
  $q,
  MetadataService,
  CrawlerMethods,
  SfeHeader,
  $timeout,
  DetailInvoiceModel,
  masterInvoice,
  TranslationService
) {
  var vm = this;
  var invoiceId = $state.params.invoiceId;
  var customerId = $state.params.customer;
  var invoiceIssuerId = $state.params.invoiceIssuer;
  vm.editMode;
  vm.duplicateMode;
  if (invoiceId) {
    if ($state.current.name === 'invoices-invoices-duplicate') {
      vm.duplicateMode = true;
    } else {
      vm.editMode = true;
    }
  } else {
    vm.newMode = true;
  }
  init();
  const inProgressStatus = TranslationService.GetCollectionById(
    'codelists.invoiceStatuses',
    1
  );

  function init() {
    vm.dataConfig = {
      redirect: {
        location: 'invoices-invoices-view',
        param: 'invoiceId',
        paramValue: invoiceId
      },
      edit: vm.editMode,
      action: {
        ctrlFn: true,
        title: vm.editMode ? gettext('Update') : gettext('Create'),
        fn: createOrUpdate
      },
      data: [
        {
          placeholder: gettext('Invoice number'),
          name: 'number',
          componentType: 'textField',
          type: 'text',
          onChange: () => {
            if (vm.editMode) {
              vm.dataConfig.dataObj.invoiceStatus = inProgressStatus;
            }
          }
        },
        {
          componentType: 'autocompleteDialog',
          edit: customerId != null,
          name: 'businessPartnerCustomer',
          configuration: {
            query: {
              entity: 'business-partners',
              method: 'read'
            },
            floatingLabel: gettext('Select customer'),
            dialogTitle: gettext('Select customer'),
            searchParamName: 'name',
            entity: 'business-partners',
            dialogConfiguration: {
              title: gettext('Select customer')
            },
            createRedirect: {
              state: 'company-resources-business-partners-new'
            },
            filterObject: {
              order: 'name'
            },
            required: true,
            change: () => {
              if (vm.editMode) {
                vm.dataConfig.dataObj.invoiceStatus = inProgressStatus;
              }
            }
          }
        },
        {
          componentType: 'autocompleteDialog',
          edit: invoiceIssuerId != null,
          name: 'invoiceIssuer',
          configuration: {
            entity: 'business-partners',
            change: () => {
              if (vm.editMode) {
                vm.dataConfig.dataObj.invoiceStatus = inProgressStatus;
              }
            },
            dialogConfiguration: {
              title: gettext('Select invoice issuer'),
              filterObject: {
                isDistributer: true,
                order: 'name'
              }
            },
            floatingLabel: gettext('Select invoice issuer'),
            query: {
              entity: 'business-partners',
              method: 'read'
            },
            searchParamName: 'name',
            filterObject: {
              isDistributer: true,
              order: 'name'
            },
            createRedirect: {
              state: 'company-resources-business-partners-new'
            },
            required: true
          }
        },
        {
          placeholder: gettext('Total with VAT'),
          name: 'totalWithVat',
          componentType: 'textField',
          type: 'numerical',
          onChange: () => {
            if (vm.editMode) {
              vm.dataConfig.dataObj.invoiceStatus = inProgressStatus;
            }
          }
        },
        {
          placeholder: gettext('Total without VAT'),
          name: 'total',
          componentType: 'textField',
          type: 'numerical',
          required: true,
          onChange: () => {
            if (vm.editMode) {
              vm.dataConfig.dataObj.invoiceStatus = inProgressStatus;
            }
          }
        },
        {
          placeholder: gettext('External Code'),
          name: 'externalCode',
          componentType: 'textField',
          type: 'text',
          maxlength: 100,
          required: false,
          onChange: () => {
            if (vm.editMode) {
              vm.dataConfig.dataObj.invoiceStatus = inProgressStatus;
            }
          }
        },
        {
          edit: true,
          componentType: 'autocompleteDialog',
          configuration: {
            codelist: 'invoiceStatuses',
            filterFn: statuses => {
              return statuses.filter(status => status.availableToUser);
            },
            floatingLabel: gettext('Invoice status'),
            searchParamName: 'name',
            noDialog: true,
            selectedParam: 'id',
            required: true
          },
          name: 'invoiceStatus'
        },
        {
          placeholder: gettext('URL Document'),
          name: 'urlDocument',
          componentType: 'textField',
          type: 'text',
          maxlength: 500,
          required: false,
          onChange: () => {
            if (vm.editMode) {
              vm.dataConfig.dataObj.invoiceStatus = inProgressStatus;
            }
          }
        },
        {
          componentType: 'singleDate',
          dateName: 'billingDate',
          dateLabel: gettext('Billing date *'),
          required: true,
          idDate: 'billingDate',
          noTimeInput: true,
          change: () => {
            if (vm.editMode) {
              vm.dataConfig.dataObj.invoiceStatus = inProgressStatus;
            }
          }
        }
      ]
    };

    if (invoiceId) {
      if ($state.current.name === 'invoices-invoices-duplicate') {
        vm.duplicateMode = true;
        vm.dataConfig.header = SfeHeader.constructFormHeader(
          'primary',
          gettext('Duplicate master invoice'),
          'invoices-invoices-view',
          $state.params
        );
      } else {
        vm.editMode = true;
        vm.dataConfig.header = SfeHeader.constructFormHeader(
          'primary',
          gettext('Edit master invoice'),
          'invoices-invoices-view',
          $state.params
        );
      }
    } else {
      vm.newMode = true;
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New master invoice'),
        'invoices-invoices'
      );
    }

    if (vm.editMode || vm.duplicateMode) {
      MetadataService.Loading(true);
      setMasterInvoiceForm();
    } else {
      vm.dataConfig.dataObj = {
        number: '',
        total: '',
        totalWithVat: '',
        billingDate: new Date(),
        invoiceStatus: 1
      };
    }

    if (invoiceIssuerId) {
      vm.dataConfig.dataObj.invoiceIssuer = invoiceIssuerId;
    }
    if (customerId) {
      vm.dataConfig.dataObj.businessPartnerCustomer = customerId;
    }
  }

  function setMasterInvoiceForm() {
    let dataObj = {
      ...masterInvoice
    };
    if (vm.duplicateMode) {
      dataObj = { ...dataObj, invoiceStatus: 1 };
      let billingDate;
      try {
        billingDate = DateTime.fromJSDate(new Date(masterInvoice.billingDate))
          .plus({ month: 1 })
          .toJSDate();
      } catch (err) {
        AlertingService.Error(gettext('Couldn\'t duplicate invoice dates'));
      }

      dataObj.billingDate = billingDate || null;
      $timeout(() => {
        MetadataService.ChangeMetadata('Duplicate ' + dataObj.number, null);
      });
    } else {
      $timeout(() => {
        MetadataService.ChangeMetadata('Edit ' + dataObj.number, null);
      });
      dataObj.billingDate = new Date(masterInvoice.billingDate);
    }
    vm.dataConfig.dataObj = dataObj;
    MetadataService.Loading(false);
  }

  // create and update functions //

  function createOrUpdate() {
    vm.sendingRequest = true;
    var redirectObj = {
      state: 'invoices-invoices-view',
      paramName: 'invoiceId'
    };

    const method = CrawlerMethods.getMethod({
      entity: 'master-invoices',
      method: vm.editMode ? 'update' : 'create'
    });
    let methodIdObject = {};
    if (vm.editMode) {
      methodIdObject = {
        id: invoiceId
      };
    }
    method(methodIdObject, dataToSaveMasterInvoice(vm.dataConfig.dataObj)).then(
      function(res) {
        const { data } = res;
        redirectObj.paramValue = data._id;
        if (vm.newMode) {
          vm.sendingRequest = false;
          CrudToastFactory.toast('create', redirectObj);
        } else if (vm.editMode) {
          vm.sendingRequest = false;
          CrudToastFactory.toast('update', redirectObj);
        } else {
          duplicatePriceListDetails(data);
        }
      },
      function(err) {
        vm.sendingRequest = false;
        AlertingService.Error(err);
      }
    );
  }

  function duplicatePriceListDetails(master) {
    var waterfall = [
      async.apply(fetchOriginalMasterInvoiceDetails, master),
      findPriceListDetail
    ];

    async.waterfall(waterfall, function() {
      vm.sendingRequest = false;
      var redirectObj = {
        state: 'invoices-invoices-view',
        paramName: 'invoiceId',
        paramValue: master._id
      };
      CrudToastFactory.toast('create', redirectObj);
    });
  }

  function findPriceListDetail(invoiceListDetails, master, callback) {
    var notCreated = 0;
    async.each(
      invoiceListDetails,
      function(invoiceListDetail, innerCallback) {
        findPriceListDetailService
          .findValidPricelist(
            master._id,
            invoiceListDetail.measuringPoint,
            invoiceListDetail.serviceDateTo
          )
          .then(
            function(priceListId) {
              createInvoiceDetail(
                master,
                invoiceListDetail,
                priceListId,
                invoiceListDetails.length,
                innerCallback
              );
            },
            function() {
              notCreated++;
              innerCallback();
            }
          );
      },
      function() {
        if (notCreated > 0) {
          var errorString = gettextCatalog.getString(
            '{{notCreated}} out of {{invoiceListDetailsNumber}} detail invoices couldn\'t be duplicated.',
            {
              notCreated: notCreated,
              invoiceListDetailsNumber: invoiceListDetails.length
            }
          );
          AlertingService.Error(errorString);
        }
        callback(null);
      }
    );
  }

  function fetchOriginalMasterInvoiceDetails(master, callback) {
    DetailInvoiceModel.read({
      masterInvoice: invoiceId
    }).then(
      function(res) {
        callback(null, res.data, master);
      },
      function(err) {
        AlertingService.Error(err);
        callback(err);
      }
    );
  }

  const addMonthToNormalizedDate = normalizedServiceDate => {
    if (normalizedServiceDate != null) {
      let { month, year } = normalizedServiceDate;
      if (month === 12) {
        month = 1;
        year++;
      } else {
        month++;
      }
      return { year, month };
    }
  };

  function createInvoiceDetail(
    master,
    invoiceListDetail,
    priceListId,
    invoiceListDetailsLength,
    callback
  ) {
    getDetailInvoiceValues(priceListId, invoiceListDetail._id).then(function(
      detailInvoiceValues
    ) {
      const total =
        invoiceListDetailsLength === 1
          ? master.total
          : detailInvoiceValues.reduce((sum, item) => {
            return typeof item.value === 'number' ? sum + item.value : sum;
          }, 0);

      const {
        normalizedServiceDateFrom,
        normalizedServiceDateTo
      } = invoiceListDetail;
      const newNormalizedDateFrom = addMonthToNormalizedDate(
        normalizedServiceDateFrom
      );

      const newNormalizedDateTo = addMonthToNormalizedDate(
        normalizedServiceDateTo
      );

      const apiObj = {
        normalizedServiceDateFrom: newNormalizedDateFrom,
        normalizedServiceDateTo: newNormalizedDateTo,
        masterInvoice: master._id,
        energySourceType: invoiceListDetail.energySourceType,
        priceListDetail: priceListId,
        measuringPoint: invoiceListDetail.measuringPoint,
        readDate: moveDateForOneMonth(invoiceListDetail.readDate, 'startOf'),
        serviceDateFrom: moveDateForOneMonth(
          invoiceListDetail.serviceDateFrom,
          'startOf'
        ),
        serviceDateTo: moveDateForOneMonth(
          invoiceListDetail.serviceDateTo,
          'endOf'
        ),
        detailInvoiceValues: detailInvoiceValues,
        total: total
      };

      DetailInvoiceModel.create(apiObj).then(
        function() {
          callback();
        },
        function(err) {
          AlertingService.Error(err);
          callback();
        }
      );
    });
  }

  function getDetailInvoiceValues(priceListId, invoiceDetail) {
    var deferred = $q.defer();
    DetailInvoiceModel.custom
      .readView({
        detailInvoiceId: invoiceDetail,
        masterInvoiceId: invoiceId
      })
      .then(
        res => {
          const invoiceValues =
            res.data && res.data[0] ? res.data[0].detailInvoiceValues : [];
          const detailInvoiceValues = invoiceValues.reduce(
            (result, invoiceValueObject) => {
              let quantity = 0;
              let value = 0;
              if (
                invoiceValueObject.priceListItem &&
                invoiceValueObject.priceListItem.transferToNextMonth
              ) {
                quantity = invoiceValueObject.detailInvoiceValue.quantity;
                value = invoiceValueObject.detailInvoiceValue.price * quantity;
              }
              if (
                invoiceValueObject.detailInvoiceValue &&
                typeof invoiceValueObject.detailInvoiceValue == 'object'
              ) {
                const postObject = {
                  transferToNextMonth:
                    invoiceValueObject.detailInvoiceValue.transferToNextMonth,
                  priceListItem:
                    invoiceValueObject.detailInvoiceValue.priceListItem,
                  energyManagementGroup:
                    invoiceValueObject.detailInvoiceValue.energyManagementGroup,
                  name: invoiceValueObject.detailInvoiceValue.name,
                  measurementUnit:
                    invoiceValueObject.detailInvoiceValue.measurementUnit,
                  metricPrefix:
                    invoiceValueObject.detailInvoiceValue.metricPrefix,
                  order: invoiceValueObject.detailInvoiceValue.order,
                  physicalQuantity:
                    invoiceValueObject.detailInvoiceValue.physicalQuantity,
                  price: invoiceValueObject.detailInvoiceValue.price,
                  priceListItemGroup:
                    invoiceValueObject.detailInvoiceValue.priceListItemGroup,
                  quantity,
                  value
                };
                return [...result, postObject];
              }
              return result;
            },
            []
          );
          deferred.resolve(detailInvoiceValues);
        },
        err => {
          AlertingService.Error(err);
          deferred.resolve([]);
        }
      );
    return deferred.promise;
  }

  /**
   * @description Takes a date and returns its milliseconds moved by one month in the future.
   * @function
   * @param {Object} date Date to be transformed into ms
   * @param {string} type Whether it should take the start or the end of the day
   * @return {number} Transformed date in milliseconds
   */
  function moveDateForOneMonth(date, type) {
    if (type == 'startOf') {
      return DateTime.fromJSDate(new Date(date))
        .plus({ month: 1 })
        .startOf('day')
        .toMillis();
    } else {
      return DateTime.fromJSDate(new Date(date))
        .plus({ month: 1 })
        .endOf('day')
        .toMillis();
    }
  }

  function dataToSaveMasterInvoice(dataObj) {
    const {
      number,
      total,
      totalWithVat,
      vat,
      isClosed,
      externalCode,
      urlDocument
    } = dataObj;
    return {
      number,
      total,
      totalWithVat,
      vat,
      isClosed,
      externalCode,
      urlDocument,
      invoiceIssuer: dataObj.invoiceIssuer._id,
      businessPartnerCustomer: dataObj.businessPartnerCustomer._id,
      invoiceStatus: dataObj.invoiceStatus ? dataObj.invoiceStatus.id : null,
      billingDate: new Date(dataObj.billingDate).setHours(0, 0, 0, 0)
    };
  }
}

export default NewInvoiceController;
