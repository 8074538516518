module.exports = {
  entity: 'project-fields',
  path: 'project-fields',
  name: {
    en: 'Project fields',
    sl_SI: 'Področja projektov'
  },
  networkModel: {
    entity: 'project-fields',
    read: 'read'
  },
  selectedParamName: 'id',
  scope: 'tis_be:project-fields',

  dialog: {
    title: {
      en: 'Select project field',
      sl_SI: 'Izberite področje projekta'
    },
    listInfo: {
      en: 'Select project field from the list below',
      sl_SI: 'Izberite področje projekta s spodnjega seznama'
    }
  }
}
