// scss
import './styles/common.scss';
import './styles/add-date-to-item.scss';
import './styles/alerting-service.scss';
import './styles/dashboard.scss';
import './styles/datepicker.scss';
import './styles/search-field.scss';

// factories
import entityDateFixedItems from './components/sfe-entity-date/sfe-entity-date-fixed-items.factory';
import AlertingService from './factories/AlertingService.service';
import ColorService from './factories/ColorService.service';
import InputParametersParserService from './factories/InputParametersParser.service';
import CronValidatorService from './factories/CronValidator.service';
import RuleViewerDialog from './factories/RuleViewerDialog.service';
import CronService from './factories/Cron.service';
import DashboardCardService from './factories/DashboardCard.service';
import CryptoService from './factories/CryptoService.service';
import DownloadCSVService from './factories/DownloadCSV.service';
import EntityTagService from './factories/EntityTagService.service';
import Formatting from './factories/Formatting.service';
import GisTimeSeriesPair from './factories/GisTimeSeriesPairDialog.service';
import DateLocalizationService from './factories/DateLocalization.service';
import ItemSelector from './factories/ItemSelectorDialog.service';
import loadAssets from './factories/loadAssets.service';
import loadDashboardModules from './factories/loadDashboardModules.service';
import MultiLanguage from './factories/MultiLanguage.service';
import PhysicalCollectionService from './factories/PhysicalCollection.service';
import SfeFormDialogService from './factories/SfeFormDialog.service';
import HierarchyTreeDialog from './factories/HierarchyTreeDialog.service';
import StandardUtils from './factories/StandardUtils.service';
import TagSelector from './factories/TagSelector.service';
import ToastService from './factories/Toast.service';
import ValidateMathExpression from './factories/validateMathExpression';
import DateTimeDialogService from './factories/DateTimeDialog.service';
import ManualReadings from './pages/manual-readings/manual-readings.service';
import InfoDialog from './factories/info-dialog/InfoDialog.service';
import DataProcessingDialogContent from './factories/info-dialog/content/dataProcessing.dialogContent';
import FormulaInputDialogContent from './factories/info-dialog/content/formulaInput.dialogContent';
import DisplayFields from './factories/DisplayField.service';
import SfeAddressHelper from './factories/SfeAddressHelper.factory';
import ManualReadingsHelper from './components/manual-readings/ManualReadingsHelper.service';
import CrudToastFactory from './factories/CrudToast.factory';
import SfeForm2Dialog from './factories/sfe-form-2-dialog/SfeForm2Dialog.service';
import SfeDataXTranslations from './factories/SfeDataXTranslations.service';
import SfeForm2DateFromPriorToValidationService from './factories/SfeForm2DateFromBeforeToValidation.service';
import EkErrorService from './factories/EkError.service';

// components
import sfeEntityDate from './components/sfe-entity-date/sfe-entity-date.component';
import sfeGisMap from './components/sfe-gis-map/sfe-gis-map.component';
import tangoGisMap from './components/tango-gis-map/tango-gis-map.component';
import logicalExpression from './components/logical-expression/logical-expression.component';
import physicalAutocomplete from './components/physical-autocomplete/physical-autocomplete.component';
import sfePrivilegeGridItem from './components/privilege-grid-item/privilege-grid-item.component';
import sfeActions from './components/sfe-actions/sfe-actions.component';
import sfeList2 from './components/sfe-list/sfe-list-2.component';
import sfeListItem from './components/sfe-list/sfe-list-item.component';
import sfeListTable from './components/sfe-list/sfe-list-table.component';
import sfeDndAutocomplete from './components/sfe-dnd-autocomplete/sfe-dnd-autocomplete.component';
import sfeDndOrderedInput from './components/sfe-dnd-ordered-input/sfe-dnd-ordered-input.component';
import sfeElementSelector from './components/sfe-element-selector/sfe-element-selector.component';
import sfeEntitySelectorList from './components/sfe-entity-selector-list/sfe-entity-selector-list.component';
import sfeErrorMessage from './components/sfe-error-message/sfe-error-message.component';
import sfeFilter from './components/sfe-filter/sfe-filter.component';
import sfeForm from './components/sfe-form/sfe-form.component';
import sfeForm2 from './components/sfe-form-2/sfe-form-2.component';
import sfeForm2Autocomplete from './components/sfe-form-2-autocomplete/sfe-form-2-autocomplete.component';
import sfeForm2AgentConfigTag from './components/sfe-form-2-agent-config-tag/sfe-form-2-agent-config-tag.component';

import sfeForm2Element from './components/sfe-form-2-element/sfe-form-2-element.component';
import sfeForm2Validation from './components/sfe-form-2-element/sfe-form-2-validation.directive';
import sfeSortable from './components/sfe-sortable/sfe-sortable.directive';
import sfeGrid from './components/sfe-grid/sfe-grid.component';
import sfeGridItemElement from './components/sfe-grid-item-element/sfe-grid-item-element.component';
import sfeHeader from './components/sfe-header/sfe-header.component';
import sfeIcon from './components/sfe-icon/sfe-icon.component';
import sfeIconPicker from './components/sfe-icon-picker/sfe-icon-picker.component';
import sfeIntervalBoundarySelector from './components/sfe-interval-boundary-selector/sfe-interval-boundary-selector.component';
import sfeItemHeader from './components/sfe-item-header/sfe-item-header.component';
import sfeLocationCard from './components/sfe-location-card/sfe-location-card.component';
import sfeMultiSelect from './components/sfe-multi-select/sfe-multi-select.component';
import sfeTreeSideview from './components/sfe-tree-sideview/sfe-tree-sideview.component';
import monthSelect from './components/month-select/month-select.component';
import sfeImage from './components/sfe-image/sfe-image.directive';
import sfeAddress from './components/sfe-address/sfe-address.component';
import sfeDisplayTypeButtons from './components/sfe-display-type-buttons/sfe-display-type-buttons.component';
import sfePagination2 from './components/sfe-pagination-2/sfe-pagination-2.component';
import sfeTagSelector from './components/sfe-tag-selector/sfe-tag-selector.component';
import sfeProxyIframe from './components/sfe-iframe-proxy-converter/sfe-proxy-iframe.component';
import sfeProperty from './components/sfe-property/sfe-property.component';
import sfeCodelistViewer from './components/sfe-codelist-viewer/sfe-codelist-viewer.component';
import manualReadings from './components/manual-readings/manual-readings.component';
import ruleAlarmsView from './components/rule-alarms/rule-alarms-view.component';
import alarmCardCountLabel from './components/alarm-card-count-label/alarm-card-count-label.component';
import sfeValue from './components/sfe-value/sfe-value.component';
import sfeForm2Date from './components/sfe-form-2-date/sfe-form-2-date.component';
import sfeDisplayFields from './components/sfe-display-fields/sfe-display-fields.directive';
import sfeForm2DisplayItem from './components/sfe-form-2-display-item/sfe-form-2-display-item.component';
import sfeForm2MathExpression from './components/sfe-form-2-math-expression/sfe-form-2-math-expression.component';
import sfeExpressionPreview from './components/sfe-expression-preview/sfe-expression-preview.component';
import tangoDynamicAttributes from './components/tango-dynamic-attributes/tango-dynamic-attributes.component';
import tangoDataX from './components/tango-data-x/tango-data-x.component';
import tangoItem from './components/tango-item/tango-item.component';
import tangoItemHeader from './components/tango-item-header/tango-item-header.component';
import tangoProperties from './components/tango-properties/tango-properties.component';
import sfePropertyList from './components/sfe-property-list/sfe-property-list.component';
import sfeTimeline from './components/sfe-timeline/sfe-timeline.component';
import tangoHeaderItemDetail from './components/tango-header-item-detail/tango-header-item-detail.component';
import sfeItemContent from './components/sfe-item-content/sfe-item-content.component';
import tangoItemContentChart from './components/tango-item-content-chart/tango-item-content-chart.component';
import sfeForm2ColorPicker from './components/sfe-form-2-color-picker/sfe-form-2-color-picker.component';
import sfeVariableViewer from './components/sfe-variable-viewer/sfe-variable-viewer.component';
import sfeForm2Checkbox from './components/sfe-form-2-checkbox/sfe-form-2-checkbox.component';
import sfeForm2Tags from './components/sfe-form-2-tags/sfe-form-2-tags.component';
import sfeForm2FlowVariables from './components/sfe-form-2-flow-variables/sfe-form-2-flow-variables.component';
import sfeDateTimeIntervalSelector from './components/sfe-date-time-interval-selector/sfe-date-time-interval-selector.component';
import sfeForm2FlowVariablesTimeSeriesCard from './components/sfe-form-2-flow-variables-time-series-card/sfe-form-2-flow-variables-time-series-card.component';
import sfeForm2FlowVariablesMappingFunctions from './components/sfe-form-2-flow-variables-mapping-functions/sfe-form-2-flow-variables-mapping-functions.component';
import sfeForm2ChartSettings from './components/sfe-form-2-chart-settings/sfe-form-2-chart-settings.component';
import tangoWizard from './components/tango-wizard/tango-wizard.component';
import sfeForm2RuleAlarm from './components/sfe-form-2-rule-alarm/sfe-form-2-rule-alarm.component';
import sfeForm2Boundaries from './components/sfe-form-2-boundaries/sfe-form-2-boundaries.component';
import sfeForm2Radio from './components/sfe-form-2-radio/sfe-form-2-radio.component';

// directives
import inputNumberFormat from './components/input-number-format/input-number-format.directive';
import sfeMultiLanguageInput from './components/sfe-multi-language-input/sfe-multi-language-input.directive';
import sfeMultiSelectList from './components/sfe-multi-select-list/sfe-multi-select-list.component';
import ngEnter from './components/ng-enter/ng-enter.directive';
import paintText from './components/paint-text/paint-text.directive';
import sfeCron from './components/sfe-cron/sfe-cron.directive';
import searchSelectInput from './components/search-select-input/search-select-input.directive';
import sfeLink from './components/sfe-link/sfe-link.directive';
import sfeAddressViewer from './components/sfe-address-viewer/sfe-address-viewer.directive';
import sfeAlarmCard from './components/sfe-alarm-card/sfe-alarm-card.directive';
import sfeAutocomplete from './components/sfe-autocomplete/sfe-autocomplete.directive';
import sfeAutocompleteAdder from './components/sfe-autocomplete-adder/sfe-autocomplete-adder.directive';
import sfeListTableItem from './components/sfe-list/sfe-list-table-item.directive';
import sfeCheckboxesLinear from './components/sfe-checkboxes-linear/sfe-checkboxes-linear.component';
import sfeChips from './components/sfe-chips/sfe-chips.directive';
import sfeChipsAutocomplete from './components/sfe-chips-autocomplete/sfe-chips-autocomplete.component';
import sfeDateInput from './components/sfe-date-input/sfe-date-input.directive';
import sfeDragAndDropList from './components/sfe-drag-and-drop-list/sfe-drag-and-drop-list.directive';
import sfeFileUploader from './components/sfe-file-uploader/sfe-file-uploader.directive';
import sfeFixedTable from './components/sfe-fixed-table/sfe-fixed-table.directive';
import sfeFocus from './components/sfe-focus/sfe-focus.directive';
import sfeGallery from './components/sfe-gallery/sfe-gallery.directive';
import sfeItemSelector from './components/sfe-item-selector/sfe-item-selector.directive';
import sfeMathExpression from './components/sfe-math-expression/sfe-math-expression.directive';
import sfeMultiSelect2 from './components/sfe-multi-select-2/sfe-multi-select-2.directive';
import sfePagination from './components/sfe-pagination/sfe-pagination.directive';
import sfeMultiTextFieldSelect from './components/sfe-multi-text-field-select/sfe-multi-text-field-select.directive';
import sfeDynamicAttributes from './components/sfe-dynamic-attributes/sfe-dynamic-attributes.directive';
import sfeSearchSelect from './components/sfe-search-select/sfe-search-select.directive';
import sfeSelectTree from './components/sfe-select-tree/sfe-select-tree.directive';
import sfeShowOperatorsAndFunctions from './components/sfe-show-operators-and-functions/sfe-show-operators-and-functions.directive';
import sfeSimpleMathExpression from './components/sfe-simple-math-expression/sfe-simple-math-expression.directive';
import sfeTable from './components/sfe-table/sfe-table.directive';
import sfeTextAreaEditor from './components/sfe-text-area-editor/sfe-text-area-editor.directive';
import sfeTooltipPosition from './components/sfe-tooltip-position/sfe-tooltip-position.directive';
import placeholder from './components/sfe-translate-placeholder/sfe-translate-placeholder.directive';
import sfeTree from './components/sfe-tree/sfe-tree.directive';
import sfeValidation from './components/sfe-validation/sfe-validation.directive';
import weatherDashboardItem from './components/weather-dashboard-item/weather-dashboard-item.directive';
import yearSelect from './components/year-select/year-select.directive';
import sfeDateTimePicker from './components/sfe-date-time-picker/sfe-date-time-picker.directive';
import sfeWrapElement from './components/sfe-wrap-element/sfe-wrap-element.directive';
import sfePhoneNumber from './components/sfe-phone-number/sfe-phone-number.directive';
import sfeMax from './components/sfe-max/sfe-max.directive';
import sfeMin from './components/sfe-min/sfe-min.directive';
import sfeForm2BoundaryOverlap from './components/sfe-form-2-boundary-overlap/sfe-form-2-boundary-overlap.directive';

// services
import filterService from './services/Filter.service';
import GoogleMapsAPI from './services/GoogleMapsAPI.service';
import ListFormService from './services/ListForm.service';
import ListItemsService from './services/ListItems.service';
import chartTranslationService from './services/ChartTranslation.service';
import createOrUpdateService from './services/createOrUpdate.service';
import DateAutocomplete from './services/DateAutocomplete.service';
import SfeFilterService from './factories/SfeFilter.service';
import ModelUtilities from './services/ModelUtilities.service';
import FormTemplates from './constants/form-templates.constant';
import sfeForm2Templates from './components/sfe-form-2-element/sfe-form-2-element-templates.constant';
import SfeForm2ChartSettingsHelper from './services/SfeForm2ChartSettingsHelper.service';

import SfeContentTemplates from './components/sfe-item-content/sfe-content-templates.constant.js';
import NetworkResource from './services/NetworkResource.service';
import CachingControl from './services/Caching/CachingControl.service';
import CachingParams from './services/Caching/CachingParams.service';
import SfeHeader from './services/SfeHeader.service';
import xhrService from './services/Xhr.service';
import AllowedFunctionsService from './factories/allowed-functions.service';
import AllowedOperatorsService from './factories/allowed-operators.service';
import SfeDynamicAttributesConfigurations from './services/SfeDynamicAttributesConfigurations.service';
import noGroupTemplates from './constants/noGroupTemplates.constant';
import SfeEntityDateConfiguration from './services/SfeEntityDateConfiguration.service';
import SfeItemSelectorConfigurations from './services/SfeItemSelectorConfiguration';
import SfeListConfigurations from './services/SfeListConfigurations.service';
import NetworkModels from './services/NetworkModels.service';
import CrawlerMethods from './services/CrawlerMethods.service';
import sfeIconPickerDialogService from './services/sfeIconPickerDialog.service';
import AssignFilterParameters from './services/AssignFilterParameters.service';
import WeatherDashboardItemService from './services/WeatherDashboardItem.service';
import SfeItemHeaderTemplates from './constants/SfeItemHeaderTemplates.constant';
import SfePropertyTemplates from './constants/sfeProperty.constant';
import GenerateUISrefLinkService from './services/generateUISrefLink.service';
import sfeForm2MathExpressionTemplates from './constants/sfe-form-2-math-expression-templates.constant';
import TangoWizardConfiguration from './components/tango-wizard/TangoWizardConfiguration.service';
import TimeSeriesVariableService from './components/sfe-form-2-flow-variables/time-series-variable.service';
import TimeSeriesWizardConfiguration from './components/tango-wizard/configurations/TimeSeriesWizardConfiguration.service';
import TangoWizardDialog from './components/tango-wizard/dialog/TangoWizardDialog.service';
import TimeSeriesWizardDuplicateConfiguration from './components/tango-wizard/configurations/TimeSeriesWizardDuplicateConfiguration.service';
import GetTimeSeriesProcessingByTimestampService from './services/GetTimeSeriesProcessingByTimestamp.service';
import TimeSeriesWizardDuplicateProperties from './components/tango-wizard/services/tango-wizard-duplicate-properties.service';
import FilterDialog from './components/tango-item-content-chart/dialog/FilterDialog.service';
import SfeDataXMinTickInterval from './factories/SfeDataXMinTickInterval.service';

// controllers
import SfeListItemController from './components/sfe-list/sfe-list-item.controller.js';
import SelectItemController from './pages/itemSelector/itemSelector.controller';
import HierarchyTreeDialogController from './pages/hierarchyTreeDialog/hierarchyTreeDialog.controller';
import TagSelectorController from './pages/tagSelector/tagSelector.controller';
import AddDateController from './pages/validateDates/addDate.controller';
import selectIconController from './pages/iconPicker/selectIcon.controller';
import SfeList2ViewController from './components/sfe-list/sfe-list-2-view.client.controller';
import TangoItemController from './components/tango-item/tango-item.controller';
// filters
import luxonDate from './filters/luxon-format.filter';
import geoLocationDecimalToDeg from './filters/geoLocation-decimal-to-deg-min-sec.filter';
import mapperRegex from './filters/mapper-regex.filter';
import numberFormat from './filters/number-format.filter';

// configurations (they fall under factories)
import noticeConfiguration from './configurations/notices-configuration';
import UploadFilesConfiguration from './configurations/upload-file.configuration';

// network models (services)
import AppConfigModel from './network-models/AppConfig.model';
import AssetAttributeModel from './network-models/AssetAttribute.model';
import AssetdomainattributeModel from './network-models/Assetdomainattribute.model';
import EntityTagModel from './network-models/EntityTag.model';
import EntityTagBindingModel from './network-models/EntityTagBinding.model';
import ImageModel from './network-models/Image.model';
import LocationAttributeModel from './network-models/LocationAttribute.model';
import LocationdomainattributeModel from './network-models/Locationdomainattribute.model';
import TimeSeriesAttributeModel from './network-models/TimeSeriesAttribute.model';
import TimeseriesdomainattributeModel from './network-models/Timeseriesdomainattribute.model';
import MeasuringpointAttributeModel from './network-models/MeasuringpointAttribute.model';
import MeasuringpointcountertypelistModel from './network-models/Measuringpointcountertypelist.model';
import MeasuringpointdomainattributeModel from './network-models/Measuringpointdomainattribute.model';
import SystemTagModel from './network-models/SystemTag.model';
import SystemTagEntityModel from './network-models/SystemTagEntity.model';
import TagModel from './network-models/Tag.model';
import TimeZoneModel from './network-models/TimeZone.model';
import UploadFileModel from './network-models/UploadFile.model';
import ViewModel from './network-models/View.model';

import TangoItemConfiguration from './factories/TangoItemConfiguration.service';
import TangoPropertiesHelper from './factories/TangoPropertiesHelper.service';
import StateResolveService from './factories/StateResolve.service';
import EntityViewMethodService from './factories/EntityViewMethod.service';
import ReduxStoreService from './factories/ReduxStore.service';
import ValidateObjectConfiguration from './factories/ValidateObjectConfiguration.service';
import ImportCsvDialog from './factories/ImportCsvDialog.service';

export default {
  factories: {
    entityDateFixedItems,
    AlertingService,
    ColorService,
    InputParametersParserService,
    RuleViewerDialog,
    CronValidatorService,
    CronService,
    DashboardCardService,
    CryptoService,
    DownloadCSVService,
    EntityTagService,
    Formatting,
    GisTimeSeriesPair,
    DateLocalizationService,
    filterService,
    ItemSelector,
    loadAssets,
    loadDashboardModules,
    MultiLanguage,
    PhysicalCollectionService,
    SfeFormDialogService,
    HierarchyTreeDialog,
    StandardUtils,
    TagSelector,
    ToastService,
    ValidateMathExpression,
    DateTimeDialogService,
    ManualReadings,
    DataProcessingDialogContent,
    FormulaInputDialogContent,
    noticeConfiguration,
    UploadFilesConfiguration,
    DisplayFields,
    SfeAddressHelper,
    CrudToastFactory,
    SfeForm2Dialog,
    FilterDialog,
    SfeDataXTranslations,
    SfeForm2DateFromPriorToValidationService,
    EkErrorService
  },
  services: {
    noGroupTemplates,
    chartTranslationService,
    createOrUpdateService,
    DateAutocomplete,
    SfeFilterService,
    GoogleMapsAPI,
    ListFormService,
    ListItemsService,
    ModelUtilities,
    sfeForm2Templates,
    FormTemplates,
    SfeContentTemplates,
    NetworkResource,
    CachingControl,
    CachingParams,
    SfeHeader,
    xhrService,
    AllowedFunctionsService,
    AllowedOperatorsService,
    InfoDialog,
    SfeDynamicAttributesConfigurations,
    SfeEntityDateConfiguration,
    SfeItemSelectorConfigurations,
    SfeListConfigurations,
    NetworkModels,
    CrawlerMethods,
    sfeIconPickerDialogService,
    AssignFilterParameters,
    WeatherDashboardItemService,
    AppConfigModel,
    AssetAttributeModel,
    AssetdomainattributeModel,
    EntityTagModel,
    EntityTagBindingModel,
    ImageModel,
    LocationAttributeModel,
    LocationdomainattributeModel,
    TimeSeriesAttributeModel,
    TimeseriesdomainattributeModel,
    MeasuringpointAttributeModel,
    MeasuringpointcountertypelistModel,
    MeasuringpointdomainattributeModel,
    SystemTagModel,
    SystemTagEntityModel,
    TagModel,
    TimeZoneModel,
    UploadFileModel,
    ViewModel,
    ManualReadingsHelper,
    SfeItemHeaderTemplates,
    GenerateUISrefLinkService,
    TangoItemConfiguration,
    TangoPropertiesHelper,
    StateResolveService,
    EntityViewMethodService,
    ReduxStoreService,
    SfePropertyTemplates,
    ValidateObjectConfiguration,
    ImportCsvDialog,
    sfeForm2MathExpressionTemplates,
    SfeForm2ChartSettingsHelper,
    TangoWizardConfiguration,
    TimeSeriesVariableService,
    TimeSeriesWizardConfiguration,
    TangoWizardDialog,
    TimeSeriesWizardDuplicateConfiguration,
    GetTimeSeriesProcessingByTimestampService,
    TimeSeriesWizardDuplicateProperties,
    SfeDataXMinTickInterval
  },
  components: {
    sfePagination2,
    sfeEntityDate,
    sfeGisMap,
    tangoGisMap,
    logicalExpression,
    physicalAutocomplete,
    sfePrivilegeGridItem,
    sfeActions,
    sfeList2,
    sfeListItem,
    sfeListTable,
    sfeDndAutocomplete,
    sfeDndOrderedInput,
    sfeElementSelector,
    sfeEntitySelectorList,
    sfeErrorMessage,
    sfeFilter,
    sfeForm,
    sfeForm2,
    sfeForm2Element,
    sfeForm2Autocomplete,
    sfeForm2AgentConfigTag,
    sfeGrid,
    sfeGridItemElement,
    sfeHeader,
    sfeIcon,
    sfeIconPicker,
    sfeIntervalBoundarySelector,
    sfeItemHeader,
    sfeLocationCard,
    sfeMultiSelect,
    sfeTreeSideview,
    monthSelect,
    sfeImage,
    sfeChipsAutocomplete,
    sfeAddress,
    manualReadings,
    sfeMultiSelectList,
    sfeCheckboxesLinear,
    ruleAlarmsView,
    alarmCardCountLabel,
    sfeDisplayTypeButtons,
    sfeTagSelector,
    sfeProxyIframe,
    sfeValue,
    tangoItem,
    tangoItemHeader,
    tangoProperties,
    tangoHeaderItemDetail,
    sfeProperty,
    tangoItemContentChart,
    sfePropertyList,
    sfeTimeline,
    sfeItemContent,
    sfeForm2DisplayItem,
    sfeForm2ColorPicker,
    sfeForm2Tags,
    sfeDisplayFields,
    sfeForm2Checkbox,
    sfeForm2FlowVariables,
    sfeForm2FlowVariablesTimeSeriesCard,
    sfeForm2FlowVariablesMappingFunctions,
    sfeDateTimeIntervalSelector,
    sfeForm2MathExpression,
    sfeVariableViewer,
    tangoDynamicAttributes,
    sfeForm2Date,
    sfeForm2ChartSettings,
    tangoWizard,
    sfeExpressionPreview,
    sfeForm2RuleAlarm,
    sfeCodelistViewer,
    sfeForm2Boundaries,
    tangoDataX,
    sfeForm2Radio
  },
  directives: {
    sfeSortable,
    sfeForm2Validation,
    inputNumberFormat,
    sfeMultiLanguageInput,
    ngEnter,
    paintText,
    sfeCron,
    searchSelectInput,
    sfeLink,
    sfeAddressViewer,
    sfeAlarmCard,
    sfeAutocomplete,
    sfeAutocompleteAdder,
    sfeListTableItem,
    sfeChips,
    sfeDateInput,
    sfeDragAndDropList,
    sfeFileUploader,
    sfeFixedTable,
    sfeFocus,
    sfeGallery,
    sfeItemSelector,
    sfeMathExpression,
    sfeMultiSelect2,
    sfePagination,
    sfeMultiTextFieldSelect,
    sfeDynamicAttributes,
    sfeSearchSelect,
    sfeSelectTree,
    sfeShowOperatorsAndFunctions,
    sfeSimpleMathExpression,
    sfeTable,
    sfeTextAreaEditor,
    sfeTooltipPosition,
    placeholder,
    sfeTree,
    sfeValidation,
    weatherDashboardItem,
    yearSelect,
    sfeDateTimePicker,
    sfeWrapElement,
    sfePhoneNumber,
    sfeMin,
    sfeMax,
    sfeForm2BoundaryOverlap
  },
  controllers: {
    selectIconController,
    SfeListItemController,
    SelectItemController,
    HierarchyTreeDialogController,
    TagSelectorController,
    AddDateController,
    SfeList2ViewController,
    TangoItemController
  },
  filters: {
    luxonDate,
    geoLocationDecimalToDeg,
    mapperRegex,
    numberFormat
  }
};
