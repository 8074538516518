WebserviceController.$inject = [
  '$stateParams',
  'gettext',
  'TranslationService',
  'MetadataService',
  'TangoAgentConnectionConfigConfiguration',
  'gettextCatalog',
  'webservice',
  '$timeout'
];

function WebserviceController(
  $stateParams,
  gettext,
  TranslationService,
  MetadataService,
  TangoAgentConnectionConfigConfiguration,
  gettextCatalog,
  webservice,
  $timeout
) {
  const vm = this;
  const webserviceId = $stateParams.id;
  vm.configuration = TangoAgentConnectionConfigConfiguration.getConfigurationWithUpdatedStates();
  vm.entity = 'datasources';

  vm.$onInit = async function init() {
    vm.loading = true;
    MetadataService.Loading(true);

    webservice.type = TranslationService.GetCollectionById(
      'codelists.webServiceTypes',
      webservice.type
    );
    vm.dataPointsFilter = {
      connector: webserviceId
    };
    vm.dataPointsConfig = {
      shortActions: []
    };
    vm.headerData = constructHeader(webservice);
    vm.loading = false;
    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(webservice.name, webservice.description);
    });
  };

  function constructHeader(webservice) {
    var actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: webserviceId,
        endpoint: {
          entity: 'webservices',
          method: 'delete'
        },
        redirectState: 'external-datasources-connectors-list'
      },
      {
        title: gettext('Update'),
        state: 'external-datasources-connectors-webservice-edit',
        param: 'id',
        paramValue: webservice._id
      }
    ];

    var properties = [
      {
        title: gettext('Protocol'),
        value: format`${webservice.type.name} (${gettextCatalog.getString(
          webservice.type.description
        )})`,
        type: 'simple'
      },
      {
        title: gettext('URL'),
        value: webservice.url,
        type: 'simple'
      }
    ];

    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];

    var propertySections = [
      {
        title: gettext('Connection'),
        properties: properties
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'webservices',
        target: webserviceId,
        type: 'userAccess'
      }
    ];

    return {
      metadata: {
        definition: gettext('Webservice connection configuration'),
        title: webservice.name,
        description: webservice.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }

  function format(strings, ...values) {
    if (values[1]) {
      return strings.reduce(
        (finalString, currentString, i) =>
          finalString + currentString + (values[i] || ''),
        ''
      );
    }
    return values[0];
  }
}

export default WebserviceController;
