import template from './sfe-form-2-radio.component.html';

/**
 * @ngdoc component
 * @name common.sfeForm2Radio
 * @description Component for displaying radio buttons.
 * @param {Object} config - field configuration
 * @example
 * <sfe-form-2-radio
 *   config="vm.config"
 * ></sfe-form-2-radio>
 */
/*
vm.config = {
  // Forms title (label).
  title: gettextCatalog.getString('Test radio'),
  // Configuration for input type.
  type: {
    // Input type options.
    options: {
      // Display content in 'row' or 'column'.
      layout: 'row',
      // Function for displaying item (checkbox label).
      display: item => {
        return item.name;
      },
      modelProperty: 'id', default _id
      // Each item represents a radio button.
      items: [
        {
          // Identifier.
          _id: 'first',
          // Items name used in display.
          name: 'first'
          // Item value
          value: 1
        },
        {
          _id: 'second',
          name: 'second',
          value: 2
        }
      ]
    }
  }
};
*/
export default {
  restrict: 'E',
  template,
  bindings: {
    config: '<',
    onChange: '&'
  },
  require: {
    model: 'ngModel'
  },
  controller: SfeForm2RadioController,
  controllerAs: 'vm',
  bindToController: true
};

SfeForm2RadioController.$inject = ['$scope'];
function SfeForm2RadioController($scope) {
  const vm = this;

  $scope.$on('$destroy', function() {
    if (modelWatcher) {
      modelWatcher();
    }
  });

  // Initialize mode value.
  const modelWatcher = $scope.$watch(
    () => {
      return vm.model.$modelValue;
    },
    model => {
      vm.modelValue = model;
      if (typeof vm.onChange == 'function') {
        vm.onChange();
      }
    }
  );

  /**
   * @description update model value on change.
   * @function
   */
  vm.updateModelValue = () => {
    vm.model.$setViewValue(vm.modelValue);
  };
}
