module.exports = {
  entity: 'entity-tags',
  path: 'entity-tags',
  entityId: 105,
  name: {
    en: 'Entity tags',
    sl_SI: 'Oznake entitet'
  },
  networkModel: {
    entity: 'entity-tags',
    read: 'read'
  },
  scope: 'tis_be:entity-tags',
  displayFields: [
    {
      field1: 'value',
      type: 'string'
    }
  ],
  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select entity tag',
      sl_SI: 'Izberite oznako entitete'
    },
    listInfo: {
      en: 'Select entity tag from the list below',
      sl_SI: 'Izberite oznako entitete s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'value',
        type: 'string'
      }
    ]
  }
}
