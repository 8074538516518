import './sfe-text-area-editor.scss';
import template from './sfe-text-area-editor.directive.html';

export default function sfeTextAreaEditor() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      htmlText: '=',
      placeholder: '<',
      readOnly: '<'
    },
    link: linkFn,
    controller: sfeTextAreaEditorController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  function linkFn() {}
}

sfeTextAreaEditorController.$inject = [];

function sfeTextAreaEditorController() {}
