TimeSeriesTopTangoPropertiesConfiguration.$inject = [
  'ValidateObjectConfiguration',
  'TangoPropertiesHelper'
];
/**
 * @ngdoc service
 * @name common.TimeSeriesTopTangoPropertiesConfiguration
 * @description TODO
 * @property {function} get
 */
export default function TimeSeriesTopTangoPropertiesConfiguration(
  ValidateObjectConfiguration,
  TangoPropertiesHelper
) {
  /**
   * @description return object of tags properties.
   * @function
   * @param {object} item time series object
   * @return {Object}
   */
  function getTagsProperties(tags) {
    return {
      type: 'tags',
      values: tags.map(tag => ({
        text: tag.value
      }))
    };
  }
  /**
   * @memberof TimeSeriesTopTangoPropertiesConfiguration.get
   * @description  returns object that contains configurations to build tango item
   * @param {number} entityId code list entity id
   * @param {boolean} topLevel entity id
   * @return {Object}
   */

  function get(entityId) {
    return {
      type: 'dynamic',
      valueFn: store => {
        let item;
        const tagProperties = [];
        const schedulerProperties = [];
        if (
          store != null &&
          store.timeSeries != null &&
          store.timeSeries[entityId] != null
        ) {
          item = store['timeSeries'][entityId].data;
          const configurationValidation = [
            {
              fieldName: 'tags',
              type: 'array',
              required: true
            },
            {
              fieldName: 'dataScheduler',
              type: 'object',
              required: true
            }
          ];

          const validationResult = ValidateObjectConfiguration.validate(
            item,
            configurationValidation
          );
          let properties;
          validationResult.configuration.forEach(config => {
            let testId = config.fieldName;
            switch (config.fieldName) {
            case 'tags':
              if (config.validity) {
                properties = getTagsProperties(item.tags);
                tagProperties.push({
                  ...properties,
                  testId
                });
              }
              break;
            case 'dataScheduler':
              if (config.validity) {
                properties = TangoPropertiesHelper.getCommonPropertyConfiguration(
                  {
                    entity: 'schedulers',
                    type: 'large',
                    item,
                    key: config.fieldName
                  }
                );
                schedulerProperties.push({
                  ...properties,
                  testId
                });
              }
            }
          });
        }
        const topProperties = [
          {
            properties: tagProperties
          }
        ];
        // If data sampling tpye is regular
        if (item != null && item.dataSamplingType === 100) {
          topProperties.push({
            properties: schedulerProperties,
            inCard: true
          });
        }

        return topProperties;
      }
    };
  }

  return {
    get
  };
}
