HeartbeatHistoryLastModel.$inject = ['ModelUtilities'];

function HeartbeatHistoryLastModel(ModelUtilities) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'heartbeat-histories/last/:externalDatasourceId'
  };

  var methods = {
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default HeartbeatHistoryLastModel;
