CalculatedFlowTimeSeriesConfigurations.$inject = [
  'gettextCatalog',
  'TranslationService'
];

function CalculatedFlowTimeSeriesConfigurations(
  gettextCatalog,
  TranslationService
) {
  /**
   * @description get trigger type configuration.
   * @function
   * @param {Object} item - triggerType
   * @return {Array}
   */
  function getTriggerType(item) {
    let properties = [];
    if (item.triggerType != null) {
      let triggerType = TranslationService.GetCollectionById(
        'codelists.triggerTypes',
        item.triggerType
      );
      if (triggerType != null) {
        properties.push({
          title: gettextCatalog.getString('Trigger type'),
          testId: 'triggerType',
          type: 'basic',
          values: [
            {
              text: triggerType.name
            }
          ]
        });
      }
    }
    return properties;
  }
  /**
   * @description returns configuration object to get tango properties configuration for calculated flow time series configuration.
   * @function
   * @param {object} flow flow object
   * @return {Object}
   */
  function get(flow) {
    return {
      type: 'dynamic',
      valueFn: store => {
        let item = {};
        if (
          store != null &&
          store.calculatedFlow != null &&
          store.calculatedFlow[flow.flow] != null &&
          store.calculatedFlow[flow.flow].data != null
        ) {
          item = store.calculatedFlow[flow.flow].data;
        }
        return [
          {
            canClose: false,
            properties: getTriggerType(item)
          }
        ];
      }
    };
  }
  return {
    get
  };
}

export default CalculatedFlowTimeSeriesConfigurations;
