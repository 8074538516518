NewAnalysisController.$inject = [
  '$state',
  'gettext',
  '$timeout',
  'AlertingService',
  '$q',
  'gettextCatalog',
  'AnalysesModel',
  'analysisSliceService',
  'TranslationService',
  '$scope',
  'MetadataService',
  'createOrUpdateService',
  'SfeHeader',
  'AnalysisFilterConfigModel',
  'analysis'
];

function NewAnalysisController(
  $state,
  gettext,
  $timeout,
  AlertingService,
  $q,
  gettextCatalog,
  AnalysesModel,
  analysisSliceService,
  TranslationService,
  $scope,
  MetadataService,
  createOrUpdateService,
  SfeHeader,
  AnalysisFilterConfigModel,
  analysis
) {
  const vm = this;
  const defaultEntities = [
    2 /*Measuringpoint*/,
    3 /*Location*/,
    4 /*CostCentre*/,
    235 /*TimeSeriesType*/,
    105 /*EntityTag*/,
    20 /* Alarms */,
    187 /* Severity */,
    30 /* alarmType */,
    251 /* alarmStatuses */,
    252 /* EventTypes */,
    248 /* TangoAgentConnectionConfig */,
    247 /* externalDatasource */
  ];

  const analysisId = $state.params.id;
  let comparableColumns = [];
  let sortOptions = [];
  let seriesOptions = [];
  let seriesOptionsLength;
  let originalFilters = [];
  vm.seriesAdded = false;
  vm.toggleAddRow = true;
  vm.dataConfig = {
    PH16: true
  };
  vm.filtersFormConfig = {
    PH16: true
  };
  vm.duplicateMode = $state.current.name === 'analytics-analyses-duplicate';

  init();
  /**
   * @description function called on controler inizilization.
   * @function
   */
  function init() {
    vm.editMode = !!analysisId;
    vm.dataConfig.header = SfeHeader.constructFormHeader(
      'primary',
      gettext('New analysis'),
      'analytics-analyses-list'
    );
    vm.AnalysisForm = {};
    vm.dataTypeConfig = {
      header: {
        title: gettext('Data')
      },
      PH16: true
    };

    vm.filtersFormConfig.header = {
      title: gettext('Filters')
    };

    vm.dataConfig.data = getBasicDataConfig();

    vm.filtersFormConfig.data = getFiltersFormConfig();
    if (!vm.editMode) {
      var dataObj = {
        dataset: {},
        aggregationGroup: {},
        group: {},
        calculation: {},
        periodInterval: {},
        analysisType: {},
        activeComparableColumn: {},
        xAxisIsAggregationGroup: true,
        series: [],
        seriesAddBtnDisabled: true,
        sortAddButtonDisabled: true,
        xAxis: {}
      };
      vm.dataConfig.dataObj = dataObj;
      vm.filtersFormConfig.dataObj = dataObj;
      vm.dataTypeConfig.dataObj = dataObj;

      // default value for country
      var defaultCounty = TranslationService.GetCollection(
        'codelists.countries'
      ).find(function(item) {
        return item.numericCode === '705';
      });
      vm.dataTypeConfig.dataObj.country = defaultCounty;
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit analysis'),
        'analytics-analyses-view',
        { id: analysisId }
      );
      if (vm.duplicateMode) {
        vm.dataConfig.header = SfeHeader.constructFormHeader(
          'primary',
          gettext('Duplicate analysis'),
          'analytics-analyses-view',
          { id: analysisId }
        );
      }
      MetadataService.Loading(true);
      constructDataObject(analysis);
    }

    vm.createOrUpdate = [
      {
        title:
          vm.editMode && !vm.duplicateMode
            ? gettext('Update')
            : gettext('Create'),
        fn: createOrUpdate,
        color: 'primary',
        raised: true,
        disabledFn: function() {
          return (
            $scope.AnalysisForm.$invalid ||
            (vm.dataConfig.dataObj &&
              ((vm.dataConfig.dataObj.sort &&
                vm.dataConfig.dataObj.sort.length === 0) ||
                (vm.dataConfig.dataObj.series &&
                  vm.dataConfig.dataObj.series.length <= 0))) ||
            vm.sendingRequest
          );
        }
      }
    ];
  }
  /**
   * @description reads data from analysis filter config based on analysis id.
   * @function
   * @param {Array} datasetColumns - An array containing all columns associated with a dataset
   */
  function readAnalysisFilterConfig(datasetColumns) {
    AnalysisFilterConfigModel.read({
      analysis: analysisId
    }).then(
      function(res) {
        var foundColumn;
        res.data.forEach(function(filter) {
          foundColumn = datasetColumns.find(function(column) {
            return column.rawFieldName === filter.rawFieldName;
          });
          filter.type = foundColumn ? foundColumn.type : null;
        });
        vm.filtersModel = res.data;
        originalFilters = angular.copy(res.data);
        vm.inputFilters = [...vm.filtersModel];
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }
  /**
   * @description creates a data object from the passed analysis object.
   * @function
   * @param {Object} analysis - object containing analysis data
   * @return {dataType}
   */
  function constructDataObject(analysis) {
    var series = [];

    analysis.displaySeries.forEach(function(item) {
      var chartTypeName = TranslationService.GetCollectionById(
        'codelists.chartTypes',
        item.chartType
      );
      series.push([
        {
          name: item.yAxis,
          _id: item.yAxis
        },
        {
          id: item.chartType,
          name: chartTypeName.name
        }
      ]);
    });
    var dataObj = {
      _id: analysis._id,
      name: analysis.name,
      xAxisIsAggregationGroup: analysis.xAxisIsAggregationGroup,
      description: analysis.description,
      showTable: analysis.showTable,
      analysisType: {
        _id: analysis.type
      },
      group: analysis.group,
      calculation: analysis.calculation,
      periodInterval: analysis.periodInterval,
      dataset: analysis.dataset,
      activeComparableColumn: {
        _id: analysis.activeComparableColumn
      },
      sliceEntity: analysis.sliceEntity
        ? {
          _id: analysis.sliceEntity
        }
        : undefined,
      groupEntity: analysis.groupEntity
        ? {
          _id: analysis.groupEntity
        }
        : undefined,
      showEnergyValues: analysis.showEnergyValues,
      country: analysis.country
    };

    vm.dataConfig.dataObj = dataObj;

    vm.filtersFormConfig.dataObj = dataObj;
    vm.datasetColumns = analysis.dataset.columns;
    // Add default param to display on filter selector
    // to help user find default filters
    vm.datasetColumns.forEach(function(column) {
      if (defaultEntities.includes(column.entity)) {
        column.default = gettextCatalog.getString('(default)');
      }
    });

    initComparableFilterOptions(analysis.dataset.comparableColumns);
    readAnalysisFilterConfig(vm.datasetColumns);
    initSortOptions(analysis.group.condition);
    enableSliceGroupEntities();
    setCalculationFilter();
    setAggregationFilter();
    var foundOption;
    var sortId;
    var foundSortObject;
    vm.dataConfig.dataObj.sort = analysis.sort.map(function(item) {
      foundOption = sortOptions.find(function(option) {
        return option._id === item.name;
      });
      sortId = item.type === 'ASC' ? 1 : 2;
      foundSortObject = TranslationService.GetCollectionById(
        'codelists.sortDirections',
        sortId
      );
      return [
        {
          name: foundOption
            ? foundOption.name
            : gettextCatalog.getString('Unknown'),
          _id: item.name
        },
        {
          name: foundSortObject
            ? foundSortObject.name
            : gettextCatalog.getString('Unknown'),
          id: sortId
        }
      ];
    });

    setSortAddButtonDisabledStatus();

    enableAggregationGroup();
    enableCalculation();
    setSliceGroupSelectOptionsOnEditFormLoad();
    calculationChanged(vm.dataConfig.dataObj.calculation, [
      analysis.calculation
    ]);
    vm.dataConfig.dataObj.xAxis = {
      _id: analysis.xAxis
    };
    showAggregationGroupChanged();
    vm.dataConfig.dataObj.series = series;
    setSeriesAddButtonDisabledStatus();
    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(
        'Edit ' + analysis.name,
        analysis.description
      );
    });
  }

  function setSliceGroupSelectOptionsOnEditFormLoad() {
    vm.dataConfig.data.forEach(function(configuration) {
      if (
        configuration.name === 'sliceEntity' ||
        configuration.name === 'groupEntity'
      ) {
        if (vm.dataConfig.dataObj[configuration.name]) {
          var res = analysisSliceService.mapDatasetName(
            vm.dataConfig.dataObj[configuration.name]._id,
            vm.datasetColumns
          );
          configuration.options = [
            {
              _id: vm.dataConfig.dataObj[configuration.name]._id,
              name: res
            }
          ];
        } else {
          configuration.options = [];
        }
      }
    });
  }

  function enableCalculation() {
    var calculationConfig = _.find(vm.dataConfig.data, {
      name: 'calculation'
    });
    if (calculationConfig) {
      calculationConfig.config.disabledValue = !vm.dataConfig.dataObj.dataset;
    }
  }

  function enableAggregationGroup() {
    var aggregationGroupConfig = _.find(vm.dataConfig.data, {
      name: 'group'
    });
    if (aggregationGroupConfig) {
      aggregationGroupConfig.config.disabledValue = !vm.dataConfig.dataObj
        .dataset._id;
    }
  }

  /**
   * @description sets default filters values when dataset changes.
   * @function
   * @param {Array} columns selected dataset columns
   */
  function setDeafaultFilters(columns) {
    const defaultColumns = columns.filter(function(column) {
      if (defaultEntities.includes(column.entity)) {
        column.default = gettextCatalog.getString('(default)');
        return column.filterEnabled;
      }
    });
    if (defaultColumns && defaultColumns.length > 0) {
      vm.filtersModel = defaultColumns.map(function(column) {
        return {
          rawFieldName: column.rawFieldName
        };
      });
      vm.inputFilters = [...vm.filtersModel];
    } else {
      AlertingService.Error(
        gettext(
          'Dataset doesn\'t have any default filters. Select another dataset'
        )
      );
      vm.dataConfig.dataObj.dataset = {};
    }
  }
  /**
   * @description sets calculation filter.
   * @function
   */
  function setCalculationFilter() {
    var calculationConfig = vm.dataConfig.data.find(
      item => item.name == 'calculation'
    );

    if (calculationConfig) {
      calculationConfig.config.filterObject = {
        dataset: vm.dataConfig.dataObj.dataset._id
      };
      calculationConfig.config.disabledValue = !vm.dataConfig.dataObj.dataset
        ._id;
    }
  }
  /**
   * @description sets aggregation filter.
   * @function
   */
  function setAggregationFilter() {
    var aggregationGroupConfig = vm.dataConfig.data.find(
      item => item.name == 'group'
    );

    if (aggregationGroupConfig) {
      aggregationGroupConfig.config.filterObject = {
        dataset: vm.dataConfig.dataObj.dataset._id
      };
      aggregationGroupConfig.config.disabledValue = !vm.dataConfig.dataObj
        .dataset._id;
    }
  }
  /**
   * @description function called when dataset is changed.
   * @function
   */
  function datasetChanged() {
    vm.dataConfig.dataObj.series = [];
    vm.dataConfig.dataObj.sort = [];
    sortOptions = [];
    seriesOptions = [];

    setSortAddButtonDisabledStatus();
    vm.dataConfig.dataObj.calculation = {};
    vm.dataConfig.dataObj.group = {};

    setCalculationFilter();
    setAggregationFilter();
    var datasetConfig = vm.dataConfig.data.find(item => item.name == 'dataset');
    vm.filtersModel = [];
    if (datasetConfig && datasetConfig.options) {
      var dataset = _.find(datasetConfig.options, {
        _id: vm.dataConfig.dataObj.dataset._id
      });
      if (dataset) {
        vm.datasetColumns = dataset.columns;
        initComparableFilterOptions(dataset.comparableColumns);
        setDeafaultFilters(dataset.columns);
      }
    }

    enableSliceGroupEntities();
    resetSliceGroupValues();
  }
  /**
   * @description initilization sorting of columns.
   * @function
   * @param {Array} columns - an array containing all columns beloning ot the current dataset.
   */
  function initSortOptions(columns) {
    var foundOption;
    if (columns) {
      sortOptions = columns.map(function(column) {
        foundOption = _.find(vm.datasetColumns, {
          rawFieldName: column
        });
        return {
          _id: column,
          name: foundOption ? foundOption.displayFieldName : column
        };
      });
    } else {
      sortOptions = [];
    }
  }
  /**
   * @description creates an array of columns used for comparison.
   * @function
   * @param {Array} columns - an array containing all columns beloning ot the current dataset.
   */
  function initComparableFilterOptions(columns) {
    comparableColumns = _.map(columns, function(column) {
      return {
        _id: column,
        name: column
      };
    });
  }
  /**
   * @description creates basic data configuration array.
   * @function
   * @return {Array} an array containing form configuration data.
   */
  function getBasicDataConfig() {
    return [
      {
        placeholder: 'Name',
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: 'Description',
        name: 'description',
        componentType: 'textArea',
        type: 'text',
        maxlength: 500,
        required: false
      },
      {
        componentType: 'multiSelect',
        config: {
          label: gettext('Type'),
          placeholder: gettext('Select type'),
          ctrlFn: function() {
            return $timeout(function() {
              return [
                {
                  _id: 'actual',
                  name: gettextCatalog.getString('Actual')
                },
                {
                  _id: 'previous_period',
                  name: gettextCatalog.getString('Previous period')
                }
              ];
            }, 0);
          },
          edit: vm.editMode
        },
        name: 'analysisType'
      },
      {
        componentType: 'multiSelect',
        config: {
          label: gettext('Dataset'),
          placeholder: gettext('Select dataset'),
          refreshFn: {
            entity: 'datasets',
            method: 'read'
          },
          edit: vm.editMode,
          onClose: datasetChanged,
          filterFn: function(items) {
            var defaultColumns;
            items = items.filter(function(item) {
              defaultColumns = item.columns.filter(function(column) {
                return (
                  column.filterEnabled &&
                  defaultEntities.includes(column.entity)
                );
              });
              return defaultColumns.length;
            });
            return items;
          }
        },
        name: 'dataset'
      },
      {
        componentType: 'multiSelect',
        config: {
          label: gettext('Aggregation group'),
          placeholder: gettext('Select aggregation group'),
          refreshFn: {
            entity: 'aggregation-groups',
            method: 'read'
          },
          edit: vm.editMode,
          onClose: aggregationChanged,
          disabledValue: true
        },
        name: 'group'
      },
      {
        componentType: 'multiSelectList',
        name: 'sort',
        removeTitle: gettext('Remove Sort'),
        addLabel: gettext('Add Sort'),
        groupLabel: gettext('Sort'),
        disabledAddBtnParam: 'sortAddButtonDisabled',
        onAdd: setSortAddButtonDisabledStatus,
        onRemove: setSortAddButtonDisabledStatus,
        selectConfigs: [
          {
            componentType: 'autocomplete',
            noDialog: true,
            entity: 'sortOptions',
            required: true,
            filterFn: function(items) {
              var selectedItems = vm.dataConfig.dataObj.sort;
              if (selectedItems) {
                var matchItem;
                items = items.filter(function(item) {
                  matchItem = selectedItems.find(function(selectedItem) {
                    if (
                      selectedItem[0] &&
                      selectedItem[0]._id &&
                      selectedItem[0]._id === item._id
                    ) {
                      return true;
                    }
                  });
                  return !matchItem;
                });
              }
              return items;
            },
            getDomainValues: async () => sortOptions,
            label: gettext('Name'),
            placeholder: gettext('Select Raw field name'),
            floatingLabel: gettext('Raw field name')
          },
          {
            componentType: 'autocomplete',
            noDialog: true,
            required: true,
            codelist: 'sortDirections',
            label: gettext('Sort type'),
            placeholder: gettext('Select sort type'),
            floatingLabel: gettext('Sort type')
          }
        ]
      },
      {
        componentType: 'multiSelect',
        config: {
          label: gettext('Calculation'),
          placeholder: gettext('Select calculation'),
          refreshFn: {
            entity: 'analysis-calculations',
            method: 'read'
          },
          empty: gettext('There are no calculations.'),
          edit: vm.editMode,
          disabledValue: true,
          onClose: calculationChanged
        },
        name: 'calculation'
      },
      {
        componentType: 'checkBox',
        label: gettext('Use aggregation group as x axis'),
        name: 'xAxisIsAggregationGroup',
        action: showAggregationGroupChanged
      },
      {
        hide: true,
        showParam: 'showXaxis',
        componentType: 'multiSelect',
        name: 'xAxis',
        config: {
          label: gettext('X axis'),
          placeholder: gettext('Select x axis values'),
          ctrlFn: function() {
            return $timeout(function() {
              return seriesOptions;
            });
          },
          empty: gettext('There are no x axis values.'),
          edit: vm.editMode
        }
      },
      {
        componentType: 'multiSelectList',
        name: 'series',
        disabledAddBtnParam: 'seriesAddBtnDisabled',
        onAdd: setSeriesAddButtonDisabledStatus,
        onRemove: setSeriesAddButtonDisabledStatus,
        uniqueId: 'seriesSelect',
        removeTitle: gettext('Remove series'),
        addLabel: gettext('Add series'),
        groupLabel: gettext('Series'),
        selectConfigs: [
          {
            componentType: 'autocomplete',
            noDialog: true,
            entity: 'yAxis',
            required: true,
            getDomainValues: () => {
              return new Promise(resolve => {
                $timeout(() => {
                  resolve(
                    _.differenceWith(
                      angular.copy(seriesOptions),
                      vm.dataConfig.dataObj.series,
                      function(item1, item2) {
                        return item1._id === (item2[0] ? item2[0]._id : false);
                      }
                    )
                  );
                }, 300);
              });
            },
            filterObject: {
              order: 'name'
            },
            label: gettext('Y axis'),
            placeholder: gettext('Select Y axis value'),
            floatingLabel: gettext('Y axis value'),
            change: function() {},
            reset: true,
            onClose: function() {}
          },
          {
            componentType: 'autocomplete',
            noDialog: true,
            entity: 'chartTypes',
            required: true,
            codelist: 'chartTypes',
            filterObject: {
              order: 'name'
            },
            label: gettext('Chart type'),
            placeholder: gettext('Select chart type'),
            floatingLabel: gettext('Chart type'),
            change: function() {},
            reset: true,
            onClose: function() {}
          }
        ]
      },
      {
        componentType: 'autocompleteDialog',
        edit: vm.editMode,
        configuration: {
          required: true,
          codelist: 'countries',
          entity: 'countries',
          dialogConfiguration: {
            multiple: false
          },
          floatingLabel: gettext('Select Country'),
          searchParamName: 'name'
        },
        name: 'country'
      },
      {
        componentType: 'checkBox',
        name: 'showEnergyValues',
        label: gettext('Show energy values'),
        action: function(value) {
          this.label = value
            ? gettext('Hide energy values')
            : gettext('Show energy values');
        }
      },
      {
        componentType: 'multiSelect',
        config: {
          label: gettext('Slice entity'),
          placeholder: gettext('Select slice entity'),
          ctrlFn: listSliceValues,
          empty: gettext('There are no items.'),
          onClose: sliceValuesChanged,
          required: false,
          disabledValue: true
        },
        name: 'sliceEntity'
      },
      {
        componentType: 'multiSelect',
        config: {
          label: gettext('Group entity'),
          placeholder: gettext('Select group entity'),
          ctrlFn: listSliceValues,
          onClose: groupValuesChanged,
          required: false,
          disabledValue: true
        },
        name: 'groupEntity'
      },
      {
        componentType: 'checkBox',
        label: gettext('Show data table'),
        name: 'showTable'
      }
    ];
  }
  /**
   * @description function that is called when we want to determine if add series button should be enabled or disabled.
   * @function
   */
  function setSeriesAddButtonDisabledStatus() {
    if (
      vm.dataConfig &&
      vm.dataConfig.dataObj &&
      vm.dataConfig.dataObj.series
    ) {
      if (seriesOptionsLength <= vm.dataConfig.dataObj.series.length) {
        vm.dataConfig.dataObj.seriesAddBtnDisabled = true;
      } else {
        vm.dataConfig.dataObj.seriesAddBtnDisabled = false;
      }
    }
  }
  /**
   * @description function that is called when we want to determine if add sort button should be enabled or disabled.
   * @function
   */
  function setSortAddButtonDisabledStatus() {
    if (vm.dataConfig && vm.dataConfig.dataObj && vm.dataConfig.dataObj.sort) {
      if (sortOptions.length <= vm.dataConfig.dataObj.sort.length) {
        vm.dataConfig.dataObj.sortAddButtonDisabled = true;
      } else {
        vm.dataConfig.dataObj.sortAddButtonDisabled = false;
      }
    }
  }

  function showAggregationGroupChanged() {
    vm.dataConfig.dataObj.showXaxis = !vm.dataConfig.dataObj
      .xAxisIsAggregationGroup;
  }
  /**
   * @description function that is called when a calculation is changed.
   * @function
   * @param {Object} value - new selected calculations object
   * @param {Array} options - all avaialable options when calculations change
   * @return {dataType}
   */
  function calculationChanged(value, options) {
    vm.dataConfig.dataObj.series = [[null, null]];
    if (!options || !options[0]) {
      return;
    }
    var selected = options.find(function(option) {
      return option._id === value._id;
    });
    seriesOptions = [];
    if (selected) {
      selected.series.forEach(function(series) {
        seriesOptions.push({
          name: series.name,
          _id: series.name
        });
      });
    }

    seriesOptionsLength = seriesOptions.length;
    setSeriesAddButtonDisabledStatus();
    $scope.$broadcast('seriesSelectReset');
    vm.dataConfig.dataObj.xAxis = {};
  }
  /**
   * @description function called when selected aggregration changes.
   * @function
   */
  function aggregationChanged() {
    enableSliceGroupEntities();

    resetSliceGroupValues();
    vm.dataConfig.dataObj.sort = [[]];

    var selectedAggregation;

    if (vm.dataConfig.dataObj.group._id) {
      vm.dataConfig.data.forEach(function(configuration) {
        if (configuration.name === 'group') {
          var selected = _.find(configuration.options, {
            _id: vm.dataConfig.dataObj.group._id
          });
          if (selected) {
            selectedAggregation = selected.condition;
          }
        }
      });
    }

    if (selectedAggregation) {
      initSortOptions(selectedAggregation);
    } else {
      initSortOptions([]);
    }

    setSortAddButtonDisabledStatus();
  }
  /**
   * @description sets vm.dataConfig.dataObj.groupEntity and vm.dataConfig.dataObj.sliceEntity to {}.
   * @function
   */
  function resetSliceGroupValues() {
    vm.dataConfig.dataObj.groupEntity = {};
    vm.dataConfig.dataObj.sliceEntity = {};
  }
  /**
   * @description sets vm.dataConfig.dataObj.groupEntity {}.
   * @function
   */
  function sliceValuesChanged() {
    if (vm.dataConfig.dataObj.sliceEntity._id) {
      vm.dataConfig.dataObj.groupEntity = {};
    }
  }
  /**
   * @description sets vm.dataConfig.dataObj.sliceEntity to {}.
   * @function
   */
  function groupValuesChanged() {
    if (vm.dataConfig.dataObj.groupEntity._id) {
      vm.dataConfig.dataObj.sliceEntity = {};
    }
  }
  /**
   * @description lists all possible slice values/options.
   * @function
   * @return {Promise}
   */
  function listSliceValues() {
    var sliceOptions = [];
    vm.dataConfig.data.forEach(function(configuration) {
      if (configuration.name === 'group') {
        var selected = configuration.options.find(
          item => item._id === vm.dataConfig.dataObj.group._id
        );
        if (selected) {
          sliceOptions = selected.condition;
        }
      }
    });
    return $timeout(function() {
      return analysisSliceService.listOptions(sliceOptions, vm.datasetColumns);
    });
  }
  /**
   * @description toggles the slice group entity selector
   * @function
   */
  function enableSliceGroupEntities() {
    var isDiasabled =
      !vm.dataConfig.dataObj.dataset._id || !vm.dataConfig.dataObj.group._id;
    vm.dataConfig.data.forEach(function(item) {
      if (item.name === 'sliceEntity' || item.name === 'groupEntity') {
        item.config.disabledValue = isDiasabled;
      }
    });
  }
  /**
   * @description creates a filter form configuration
   * @function
   * @return {Array} - array containing filter for configuration
   */
  function getFiltersFormConfig() {
    return [
      {
        componentType: 'multiSelect',
        config: {
          label: gettext('Period interval'),
          placeholder: gettext('Select period interval'),
          refreshFn: {
            entity: 'period-intervals',
            method: 'read'
          },
          empty: gettext('There are no period intervals.'),
          edit: vm.editMode
        },
        name: 'periodInterval'
      },
      {
        componentType: 'multiSelect',
        config: {
          label: gettext('Comparable column'),
          placeholder: gettext('Select comparable column'),
          ctrlFn: function() {
            return $timeout(function() {
              return comparableColumns;
            });
          },
          edit: vm.editMode
        },
        name: 'activeComparableColumn'
      }
    ];
  }
  /**
   * @description checks if thentity filter is valid.
   * @function
   * @return {boolean} true if the filters are valid, false if they are not.
   */
  function entityFiltersValid() {
    var defaultColumns;
    if (vm.filtersModel && vm.filtersModel.length) {
      var datasetConfig = vm.dataConfig.data.find(function(config) {
        return config.name === 'dataset';
      });
      if (datasetConfig && datasetConfig.options) {
        var dataset = _.find(datasetConfig.options, {
          _id: vm.dataConfig.dataObj.dataset._id
        });
        if (dataset) {
          defaultColumns = dataset.columns.filter(function(column) {
            return (
              column.filterEnabled && defaultEntities.includes(column.entity)
            );
          });
        }
      }

      var defaultColumnSelected = false;
      var isMatch;
      if (defaultColumns) {
        vm.filtersModel.forEach(function(item) {
          isMatch = defaultColumns.find(function(column) {
            return column.rawFieldName === item.rawFieldName;
          });
          if (isMatch) {
            defaultColumnSelected = true;
          }
        });
      }

      return defaultColumnSelected;
    }
    return false;
  }

  /**
   * @description function that checks if we are upading or creating a new analysis and calls a function that sends the data to BE.
   * @function
   */
  function createOrUpdate() {
    vm.sendingRequest = true;
    var apiObj = getApiObject(vm.dataConfig.dataObj);
    var waterfall;
    var message;
    if (entityFiltersValid()) {
      if (vm.editMode && !vm.duplicateMode) {
        waterfall = [
          async.apply(updateAnalysis, apiObj),
          createOrUpdateAnalysisFilterConfig,
          deleteFilters
        ];
        message = 'update';
      } else {
        waterfall = [
          async.apply(createAnalysis, apiObj),
          createOrUpdateAnalysisFilterConfig
        ];
        message = 'create';
      }
      createOrUpdateService
        .simpleWaterfall(waterfall, message, 'analytics-analyses-view', 'id')
        .then(
          function() {
            vm.sendingRequest = false;
          },
          function(err) {
            vm.sendingRequest = false;
            AlertingService.Error(err);
          }
        );
    } else {
      AlertingService.Error(
        gettext('At least one default filter must be selected')
      );
      vm.sendingRequest = false;
    }
  }
  /**
   * @description function that sends apiObj to be used in creation of an analysis.
   * @function
   * @param {Object} apiObj - object containing analysis data.
   * @param {Function} callback
   */
  function createAnalysis(apiObj, callback) {
    AnalysesModel.create(apiObj).then(
      function(res) {
        callback(null, res.data);
      },
      function(err) {
        AlertingService.Error(err);
        callback(err);
      }
    );
  }
  /**
   * @description function that sends apiObj to be used in updating an analysis.
   * @function
   * @param {Object} apiObj - object containing analysis data.
   * @param {Function} callback
   */
  function updateAnalysis(apiObj, callback) {
    AnalysesModel.update(
      {
        id: vm.dataConfig.dataObj._id
      },
      apiObj
    ).then(
      function(res) {
        callback(null, res.data);
      },
      function(err) {
        AlertingService.Error(err);
        callback(err);
      }
    );
  }
  /**
   * @description function that creates or updates an analysisFilterConfig.
   * @function
   * @param {Object} analysis - analysis data
   * @param {Function} callback
   */
  function createOrUpdateAnalysisFilterConfig(analysis, callback) {
    async.each(
      vm.filtersModel,
      function(filter, innerCallback) {
        var apiObj = {
          analysis: analysis._id,
          rawFieldName: filter.rawFieldName
        };
        if (filter.type === 2) {
          //Integer temp
          apiObj.filterValues = [Number(filter.value)];
        } else {
          if (filter.isPreset) {
            filter = {
              ...filter,
              entityItems: []
            };
            if (
              Array.isArray(filter.entityItemObjects) &&
              filter.entityItemObjects.length > 0
            ) {
              filter = {
                ...filter,
                entityItems: filter.entityItemObjects
              };
            } else if (
              //using else if, because filters that have entityItemObject might have filteredValue as well
              //because  it is the property used on BE
              Array.isArray(filter.filterValues) &&
              filter.filterValues.length > 0
            ) {
              filter = {
                ...filter,
                entityItems: filter.filterValues
              };
            }
            apiObj.filterValues = [];
            filter.entityItems.forEach(function(item) {
              if (typeof item == 'string') {
                apiObj.filterValues.push(item);
              } else if (item != null && typeof item == 'object') {
                if (filter.entity == 140) {
                  //Masterinvoices
                  apiObj.filterValues.push(item.masterInvoice);
                } else if (item._id) {
                  apiObj.filterValues.push(item._id);
                } else if (item.id) {
                  apiObj.filterValues.push(item.id);
                }
              }
            });

            if (filter.entity === 104) {
              apiObj.isLocked = filter.isLocked;
            } else {
              apiObj.isLocked = true;
            }
            apiObj.isHiddenOnView =
              filter.entityItems?.length > 0 && filter.isHiddenOnView;
          } else {
            apiObj.filterValues = null;
          }
        }

        if (filter.mongoId && !vm.duplicateMode) {
          AnalysisFilterConfigModel.update(
            {
              id: filter.mongoId
            },
            apiObj
          ).then(
            function() {
              innerCallback();
            },
            function(err) {
              AlertingService.Error(err);
              innerCallback();
            }
          );
        } else {
          //this is done because in case of duplication we don't want to save an old mongo id or _id.
          const { mongoId, _id, ...apiObjWithoutMongoId } = apiObj;
          AnalysisFilterConfigModel.create(apiObjWithoutMongoId).then(
            function() {
              innerCallback(null, analysis);
            },
            function(err) {
              AlertingService.Error(err);
              innerCallback();
            }
          );
        }
      },
      function() {
        callback(null, analysis);
      }
    );
  }
  /**
   * @description Function that deletes filters from BE.
   * @function
   * @param {Object} analysis - analysis for which we'll be deleting filters.
   * @param {Function} callback
   * @return {dataType}
   */
  function deleteFilters(analysis, callback) {
    var itemsToDel = _.differenceBy(originalFilters, vm.filtersModel, '_id');
    var promises = [];
    _.each(itemsToDel, function(item) {
      promises.push(
        AnalysisFilterConfigModel.delete({
          id: item._id
        })
      );
    });
    $q.all(promises).then(
      function() {
        callback(null, analysis);
      },
      function(err) {
        AlertingService.Error(err);
        callback(null, analysis);
      }
    );
  }
  /**
   * @description Takes an object arugment and formats it into another  (api) object.
   * @function
   * @param {Object} obj - the object we'll be formating
   * @return {Object} the created apiObject
   */
  function getApiObject(obj) {
    if (obj.sort) {
      var sort = obj.sort.map(function(sortItem) {
        return {
          name: sortItem[0]._id,
          type: sortItem[1].id === 1 ? 'ASC' : 'DESC'
        };
      });
    }
    var displaySeries = [];
    obj.series.forEach(function(item) {
      displaySeries.push({
        yAxis: item[0]._id,
        chartType: item[1].id,
        regressionType: item[2] ? item[2]._id : null
      });
    });

    return {
      xAxisIsAggregationGroup: obj.xAxisIsAggregationGroup,
      xAxis: !obj.xAxisIsAggregationGroup ? obj.xAxis._id : undefined,
      displaySeries: displaySeries,
      name: obj.name,
      description: obj.description,
      showTable: obj.showTable,
      type: obj.analysisType._id,
      periodInterval: obj.periodInterval._id,
      calculation: obj.calculation._id,
      dataset: obj.dataset._id,
      group: obj.group._id,
      activeComparableColumn: obj.activeComparableColumn._id,
      sort: sort,
      sliceEntity:
        obj.sliceEntity && obj.sliceEntity._id ? obj.sliceEntity._id : null,
      groupEntity:
        obj.groupEntity && obj.groupEntity._id ? obj.groupEntity._id : null,
      country: obj.country.id,
      showEnergyValues: obj.showEnergyValues
    };
  }
}

export default NewAnalysisController;
