/**
* @ngdoc component
* @name moduleName.paintText
* @description Component sets color for the text it is added to.
* @param {string} color - Type of color from the current theme that should be used (primary, accent, success etc.)
  @param {string} shade - shade/hue of the color selected (hue-1, hue-2 etc,)
* @example
* <div paint-text color="'primary'" hue="'hue-2"></div>
*/
import './paint-text.scss';

export default [
  '$theming',
  '$rootScope',
  '$transitions',
  function($theming, $rootScope, $transitions) {
    return {
      restrict: 'A',
      scope: {
        color: '<',
        shade: '<'
      },
      link: function(scope, element) {
        if (!scope.color) {
          return;
        } else {
          paintIt();
        }

        if ($theming.isThemeDefined(scope.color)) {
          // if color theme defined watch for state change and repaint element
          $transitions.onSuccess({}, () => {
            paintIt();
          });
          $rootScope.$on('changeTheme', function() {
            paintIt();
          });
        }
        /**
         * @description adds paint-text- class to specified element
         * @function
         */
        function paintIt() {
          var palettes = ['primary', 'accent', 'warn', 'success'];
          var className = 'paint-text-';
          className +=
            palettes.indexOf(scope.color) === -1
              ? scope.color + '-primary'
              : $rootScope.activeTheme + '-' + scope.color;
          className +=
            typeof scope.shade === 'undefined' ? '-default' : '-' + scope.shade;
          removeClassByPrefix(element[0], 'paint-text');
          element[0].className += ' ' + className;
        }
        /**
         * @description removes class based on prefix
         * @function
         */
        function removeClassByPrefix(el, prefix) {
          var regx = new RegExp('\\b' + prefix + '.*?\\b', 'g');
          el.className = el.className.replace(regx, '');
          return el;
        }
      }
    };
  }
];
