module.exports = {
  entity: 'energy-management-groups',
  path: 'energy-management-groups',
  name: {
    en: 'Energy management groups',
    sl_SI: 'Skupine za energetsko upravljanje'
  },
  networkModel: {
    entity: 'energy-management-groups',
    read: 'read'
  },
  scope: 'tis_be:energy-management-groups',

  dialog: {
    title: {
      en: 'Select energy management group',
      sl_SI: 'Izberite skupino za energetsko upravljanje'
    },
    listInfo: {
      en: 'Select energy management group from the list below',
      sl_SI: 'Izberite skupino za energetsko upravljanje s spodnjega seznama'
    }
  }
}
