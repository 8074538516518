MeasuringPointConfiguration.$inject = [
  'gettext',
  '$q',
  'TranslationService',
  'MeasuringpointTypeModel',
  'BusinessPartnerModel',
  '$state'
];

function MeasuringPointConfiguration(
  gettext,
  $q,
  TranslationService,
  MeasuringpointTypeModel,
  BusinessPartnerModel
) {
  var configuration = {
    listId: 'measuringPointList',
    entity: {
      singular: gettext('Measuring Point'),
      plural: gettext('Measuring Points')
    },
    api: {
      query: {
        entity: 'measuringpoints',
        method: 'custom.list'
      },
      enrich: Enrich
    },
    create: {
      link: {
        state: 'company-resources-measuring-points-new'
      }
    },
    title: {
      param: 'name',
      link: {
        state: 'company-resources-measuring-points-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'company-resources-measuring-points-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'company-resources-measuring-points-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        type: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'measuringpoints',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Energy source type'),
        param: 'energySourceTypeName',
        type: 'text'
      },
      {
        title: gettext('Location'),
        param: 'locationName',
        link: {
          state: 'company-resources-locations-view',
          params: [
            {
              valueParam: 'locationId',
              queryParam: 'id'
            }
          ]
        },
        type: 'link'
      }
    ],
    badges: [
      {
        title: gettext('Code'),
        param: 'code',
        type: 'code'
      },
      {
        title: gettext('Icon'),
        param: 'icon',
        type: 'icon'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        param: 'measuringpointType',
        query: {
          entity: 'measuringpoint-types',
          method: 'read'
        },
        entity: 'measuringpoint-types',
        display: gettext('Measuring Point Type'),
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        param: 'location',
        query: {
          entity: 'locations',
          method: 'read'
        },
        entity: 'locations',
        display: gettext('Location'),
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        collection: TranslationService.GetCollection(
          'codelists.measuringPointClasses'
        ),
        display: gettext('Measuringpoint Class'),
        param: 'measuringpointClass',
        valueField: 'id'
      },
      {
        param: 'costCentreMeasuringpoints.costCentre',
        query: {
          entity: 'cost-centres',
          method: 'read'
        },
        entity: 'cost-centres',
        display: gettext('Main Cost Centre'),
        refreshParameterName: 'filter',
        displayList: [
          {
            field1: 'name',
            type: 'string'
          },
          {
            field1: 'code',
            type: 'code',
            title: 'Code'
          },
          {
            field1: 'externalCode',
            type: 'code',
            title: 'External Code'
          }
        ],
        type: 'searchSelect',
        populate: 'costCentreMeasuringpoints',
        filterParameters: {
          'costCentreMeasuringpoints.isActive': true,
          'costCentreMeasuringpoints.isMain': true
        },
        selectedParamName: '_id'
      },
      {
        query: {
          entity: 'business-partners',
          method: 'read'
        },
        entity: 'business-partners',
        display: gettext('Distributor'),
        param: 'businessPartnerDistributor',
        type: 'searchSelect',
        refreshParameterName: 'name',
        selectedParamName: '_id',
        displayList: ['code', 'name'],
        refreshObject: {
          isDistributer: true,
          order: 'name'
        }
      },
      {
        query: {
          entity: 'energy-source-types',
          method: 'read'
        },
        entity: 'energy-source-types',
        display: gettext('Energy Source Type'),
        param: 'energySourceType',
        refreshObject: {
          order: 'code'
        },
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        query: {
          entity: 'price-list-masters',
          method: 'read'
        },
        entity: 'price-list-masters',
        display: gettext('Price list'),
        param: 'priceListMasterSupplierOrDistributor',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id',
        displayList: ['name']
      },
      {
        query: {
          entity: 'business-partners',
          method: 'read'
        },
        entity: 'business-partners',
        display: gettext('Supplier'),
        param: 'businessPartnerSupplier',
        type: 'searchSelect',
        refreshParameterName: 'name',
        selectedParamName: '_id',
        displayList: ['code', 'name'],
        refreshObject: {
          isDistributer: true,
          order: 'name'
        }
      },
      {
        query: null,
        display: gettext('Code'),
        param: 'code',
        type: 'number',
        min: 0,
        step: 1
      },
      {
        query: null,
        display: gettext('Distributor Code'),
        param: 'distributorCode',
        type: 'string'
      },
      {
        display: gettext('External Code'),
        param: 'externalCode',
        type: 'string'
      },
      {
        display: gettext('Supplier Code'),
        param: 'supplierCode',
        type: 'string'
      },
      {
        param: 'businessPartnerMeasuringpoints.businessPartner',
        query: {
          entity: 'business-partners',
          method: 'read'
        },
        entity: 'business-partners',
        display: gettext('Owner'),
        refreshParameterName: 'name',
        selectedParamName: '_id',
        type: 'searchSelect',
        displayList: ['code', 'name'],
        refreshObject: {
          order: 'name'
        },
        populate: 'businessPartnerMeasuringpoints',
        filterParameters: {
          'businessPartnerMeasuringpoints.isActive': true,
          'businessPartnerMeasuringpoints.isMain': true
        }
      },
      {
        collection: TranslationService.GetCollection(
          'codelists.measuringPointKinds'
        ),
        entity: ['measuringPointKinds'],
        display: gettext('Measuring Point Mode'),
        param: 'measuringpointKind',
        valueField: 'id'
      },
      {
        query: {
          entity: 'system-tags',
          method: 'read'
        },
        selectedParamName: '_id',
        entity: 'system-tags',
        display: gettext('System Tags'),
        param: 'systemTagEntities.systemTag',
        populate: 'systemTagEntities'
      }
    ],
    sort: [
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'name DESC',
        params: '-name'
      },
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ],
    extended: {
      query: Extend,
      items: [
        {
          title: gettext('Type'),
          param: 'type',
          type: 'text'
        },
        {
          title: gettext('Kind'),
          param: 'kind',
          type: 'text'
        },
        {
          title: gettext('Class'),
          param: 'class',
          type: 'text'
        },
        {
          title: gettext('Distributer'),
          param: 'distributer',
          link: {
            state: 'company-resources-business-partners-view',
            params: [
              {
                valueParam: 'businessPartnerDistributor',
                queryParam: 'id'
              }
            ]
          },
          type: 'link'
        },
        {
          title: gettext('Supplier'),
          param: 'supplier',
          link: {
            state: 'company-resources-business-partners-view',
            params: [
              {
                valueParam: 'businessPartnerSupplier',
                queryParam: 'id'
              }
            ]
          },
          type: 'link'
        }
      ]
    }
  };

  /*
   *
   *   ENRICH
   *
   */

  function Enrich(item) {
    var deferred = $q.defer();
    item.energySourceTypeName = 'Unknown';

    if (item.energySourceType != null) {
      item.energySourceTypeName = item.energySourceType.name;
      item.icon = item.energySourceType.icon;
    }
    if (item.location != null) {
      item.locationName = item.location.name;
      item.locationId = item.location._id;
    }

    deferred.resolve();
    return deferred.promise;
  }

  /*
   *
   *   EXTEND
   *
   */

  function Extend(item) {
    var deferred = $q.defer();
    readExtended(item).then(function() {
      item.type = 'Unknown';
      item.kind = 'Unknown';
      item.class = 'Unknown';
      item.distributer = 'Unknown';
      item.supplier = 'Unknown';

      if (item.measuringpointType != null) {
        item.type = item.measuringpointType.name;
      }
      if (item.measuringpointKind != null) {
        item.kind = TranslationService.GetCollectionById(
          'codelists.measuringPointKinds',
          item.measuringpointKind
        );
        if (item.kind) {
          item.kind = item.kind.name;
        }
      }
      if (item.measuringpointClass != null) {
        item.class = TranslationService.GetCollectionById(
          'codelists.measuringPointClasses',
          item.measuringpointClass
        );
        if (item.class) {
          item.class = item.class.name;
        }
      }
      if (item.businessPartnerDistributor != null) {
        item.distributer = item.businessPartnerDistributor.name;
      }
      if (item.businessPartnerSupplier != null) {
        item.supplier = item.businessPartnerSupplier.name;
      }
      deferred.resolve();
    });
    return deferred.promise;
  }

  function readExtended(item) {
    var deferred = $q.defer();
    var parameters = [
      {
        model: MeasuringpointTypeModel,
        method: 'read',
        param: 'measuringpointType'
      },
      {
        model: BusinessPartnerModel,
        method: 'read',
        param: 'businessPartnerDistributor'
      },
      {
        model: BusinessPartnerModel,
        method: 'read',
        param: 'businessPartnerSupplier'
      }
    ];
    var promises = [];

    parameters.forEach(function(param) {
      promises.push(readExtendedItem(param, item));
    });
    $q.all(promises).then(function(results) {
      results.forEach(function(result, index) {
        if (result) {
          item[parameters[index].param] = result;
        }
      });
      deferred.resolve();
    });
    return deferred.promise;
  }

  function readExtendedItem(param, item) {
    var deferred = $q.defer();
    var model = param.model;
    var method = param.method;
    var value = param.param;
    if (item[value]) {
      model[method]({
        id: item[value]
      }).then(
        function(res) {
          deferred.resolve(res.data);
        },
        function() {
          deferred.resolve();
        }
      );
    } else {
      deferred.resolve();
    }
    return deferred.promise;
  }

  return configuration;
}

export default MeasuringPointConfiguration;
