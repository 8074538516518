DashboardConfiguration.$inject = [
  'gettext',
  '$q',
  'AlertingService',
  'CrudToastFactory',
  '$smartAuth',
  'DashboardUserModel'
];

function DashboardConfiguration(
  gettext,
  $q,
  AlertingService,
  CrudToastFactory,
  $smartAuth,
  DashboardUserModel
) {
  var configuration = {
    listId: 'dashboardList',
    entity: {
      singular: gettext('Dashboard'),
      plural: gettext('Dashboards')
    },
    api: {
      query: {
        entity: 'dashboards',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'dashboards-dashboards-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'dashboards-dashboards-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'bookmark',
        title: gettext('Toggle main dashboard'),
        action: function(item, relistItems) {
          var isMain = !item.isMain;

          if (isMain) {
            $smartAuth
              .profile()
              .then(function(user) {
                //  update main dashboard if it is already exist, create otherwise
                if (item.mainDashboardId) {
                  return DashboardUserModel.update(
                    {
                      id: item.mainDashboardId
                    },
                    {
                      dashboard: item._id,
                      user: user._id
                    }
                  );
                } else {
                  return DashboardUserModel.create({
                    user: user._id,
                    dashboard: item._id
                  });
                }
              })
              .then(function() {
                CrudToastFactory.toast('update');
                relistItems();
              })
              .catch(function(err) {
                AlertingService.Error(err);
              });
          } else {
            DashboardUserModel.delete({ id: item.dashboardUser }).then(
              function() {
                CrudToastFactory.toast('update');
                relistItems();
              },
              function(err) {
                AlertingService.Error(err);
              }
            );
          }
        },
        class: 'isMainColor',
        iconType: 'fa'
      },
      {
        icon: 'mode_edit',
        link: {
          state: 'dashboards-dashboards-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          name: 'remove_dashboard',
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'dashboards',
            method: 'delete'
          },
          callback: resetMainDashboardd
        }
      }
    ],
    attributes: [
      {
        title: gettext('Description'),
        param: 'description',
        type: 'text'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      }
    ],
    sort: [
      {
        display: gettext('created DESC'),
        params: '-_id'
      },
      {
        display: gettext('created ASC'),
        params: '_id'
      },
      {
        display: gettext('name ASC'),
        params: 'name'
      },
      {
        display: gettext('name DESC'),
        params: '-name'
      }
    ],
    extended: {}
  };
  /**
   * @description sets main dashboard color classes.
   * @function
   * @param {Object} item list item
   * @param {Object} dashboardUser dashboard user if exists
   */
  function setMainDashboard(item, dashboardUser) {
    if (dashboardUser && dashboardUser.dashboard === item._id) {
      item.dashboardUser = dashboardUser._id;
      item.isMain = true;
      item.isMainColor = 'color-tis';
    } else {
      item.dashboardUser = undefined;
      item.isMain = false;
      item.isMainColor = 'double-grey-color';
    }
  }

  function Enrich(item) {
    var deferred = $q.defer();
    item.isMainColor = 'double-grey-color';
    $smartAuth
      .profile()
      .then(function(user) {
        return DashboardUserModel.read({
          user: user._id
        });
      })
      .then(function(res) {
        setMainDashboard(item, res.data[0]);
        if (res.data && res.data[0]) {
          item.mainDashboardId = res.data[0]._id;
        }
        deferred.resolve();
      })
      .catch(function() {
        setMainDashboard(item);
        deferred.resolve();
      });

    return deferred.promise;
  }

  /**
   * @description Deletes a dashboard based on id.
   * @function
   * @param {Object} Object containing dashboard id.
   * @return {Promise} Deleted dashboard promise.
   */
  function resetMainDashboardd(obj, item) {
    if (item.dashboardUser) {
      DashboardUserModel.delete({
        id: item.dashboardUser
      }).then(
        function() {},
        function() {}
      );
    }
  }

  return configuration;
}
export default DashboardConfiguration;
