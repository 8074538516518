import './sfe-interval-boundary-selector.scss';
import template from './sfe-interval-boundary-selector.component.html';

/**
 * @ngdoc component
 * @name common.sfeIntervalBoundarySelector
 * @description displays interval boundary selector.
 * @param {Array} intervals array of intervals
 * @param {number} intervalChanged interval change indicator
 * @example
 *  <sfe-interval-boundary-selector
 *   interval-changed="vm.intervalChanged"
 *   intervals="vm.intervalRules"
 *  ></sfe-interval-boundary-selector>
 */
export default {
  restrict: 'E',
  template,
  bindings: {
    intervals: '=',
    intervalChanged: '<'
  },
  require: {
    form: '^form'
  },
  controller: SfeIntervalBoundarySelectorController,
  controllerAs: 'vm',
  bindToController: true
};

SfeIntervalBoundarySelectorController.$inject = ['$scope', 'ColorService'];

function SfeIntervalBoundarySelectorController($scope, ColorService) {
  var vm = this;
  vm.selectedIndex = 0;
  vm.addBoundary = addBoundary;
  vm.removeBoundary = removeBoundary;
  vm.colorPickerSelected = colorPickerSelected;
  vm.changeSelectedIndex = changeSelectedIndex;
  var colorIndex = 0;

  var colors = ColorService.ColorArray;

  /**
   * @description watches when selected interval changes and sets selectedIndex and isActive flag.
   * @function
   * @param {array} changes array of bindings changes
   */
  vm.$onChanges = function(changes) {
    if (changes.intervalChanged && vm.intervalChanged) {
      var firstIntervalIndex;
      var activeInterval;
      vm.intervals.forEach(function(interval, index) {
        if (interval.show) {
          if (typeof firstIntervalIndex === 'undefined') {
            firstIntervalIndex = index;
          }
          if (interval.isActive) {
            activeInterval = true;
          }
        } else {
          interval.isActive = false;
        }
      });

      if (!activeInterval && typeof firstIntervalIndex !== 'undefined') {
        changeSelectedIndex(vm.intervals[firstIntervalIndex]);
      }
    }
  };

  var parentFormWatcher = $scope.$watch('vm.parentForm', function() {
    if (vm.parentForm) {
      $scope.tabForm = vm.parentForm;
      parentFormWatcher();
    }
  });

  $scope.$on('$destroy', function() {
    if (parentFormWatcher) {
      parentFormWatcher();
    }
  });
  /**
   * @description compares intervals names and sets selected index and isActive flag.
   * @function
   * @param {Object} interval selected interval
   */
  function changeSelectedIndex(interval) {
    vm.intervals.forEach(function(intervalItem, index) {
      // in this case interval names are uniq
      if (interval.name === intervalItem.name) {
        intervalItem.isActive = true;
        vm.selectedIndex = index;
      } else {
        intervalItem.isActive = false;
      }
    });
  }

  /**
   * @description removes boundary.
   * @function
   * @param {Object} interval interval witch boundary is being removed
   * @param {number} index index of boundary to remove
   */
  function removeBoundary(interval, index) {
    interval.boundaries.splice(index, 1);
  }
  /**
   * @description adds boundary.
   * @function
   * @param {Object} interval interval witch boundary is being removed
   */
  function addBoundary(interval) {
    if (!interval.boundaries) {
      interval.boundaries = [];
    }
    var color;
    if (colors.length > 0) {
      color = colors[colorIndex % colors.length];
      colorIndex++;
    }

    interval.boundaries.push({
      from: undefined,
      to: undefined,
      color: color,
      style: {
        'background-color': color
      }
    });
  }

  /**
   * @description sets style object to boundary.
   * @function
   * @param {Object} boundary boundary object
   */
  function colorPickerSelected(boundary) {
    boundary.style = {
      'background-color': boundary.color
    };
  }
}
