import './sfe-timeline.scss';
import template from './sfe-timeline.component.html';

/**
 * @ngdoc component
 * @name common.sfeTimeline
 * @description creates a timeline for time series
 * @param {Object} configuration  - configuration object for component
 * vm.configuration = {
    // Adds button for adding new items.
    addItemFn: () => {
      console.log('item has been added');
    },
    // If true only last item is editable, otherwise all items are.
    canEditOnlyLast: true,
    // Array of items in timeline.
    items: [
      {
        // Name/title of item (required).
        name: 'Zajem iz zunanjega vira',
        // Icon with type, name and custom color (optional).
        icon: {
          type: 3,
          name: 'fa-reddit',
          color: '#cf324c'
        },
        // Date with from and to properties for display (required).
        date: {
          from: '10.12.2018',
          to: '10.11.2018'
        },
        // Raw date with from and to properties for validation (from & to required, to optional on last item).
        dateRaw: {
          from: new Date('2018-10-10'),
          to: new Date('2018-10-12')
        },
        // Custom function on item (optional).
        fn: () => {
          console.log('valuesHaveBeenCalled');
        },
        // Function for editting item, displays edit button if defined (optional).
        editItemFn: () => {
          console.log('editIem');
        }
      }
    ]
  };
 * @example
 * <sfe-timeline
 *   configuration="vm.configuration"
 * ></sfe-timeline
 */

export default {
  template,
  bindings: {
    configuration: '<'
  },
  controllerAs: 'vm',
  controller: sfeTimelineController
};

sfeTimelineController.$inject = [
  '$element',
  '$timeout',
  '$window',
  'gettextCatalog'
];

function sfeTimelineController($element, $timeout, $window, gettextCatalog) {
  const vm = this;
  const wrapperDiv = $element.find('.sfe-timeline__wrapper');
  const wrapperScroll = $element.find(
    '.sfe-timeline__wrapper__scroll-container'
  );

  vm.scrollHorizontally = scrollHorizontally;
  vm.selectItem = selectItem;
  vm.displayScrollArrow = false;
  vm.items = [];

  /**
   * @description on/off resize check if it should display scroll arrow.
   * @function
   */
  const whenResizeDisplayScrollArrowCheck = () => {
    vm.displayScrollArrow = displayScrollArrowCheck();
  };

  // resize listner
  angular.element($window).on('resize', whenResizeDisplayScrollArrowCheck);
  vm.$onDestroy = () => {
    angular.element($window).off('resize', whenResizeDisplayScrollArrowCheck);
  };

  vm.$onChanges = () => {
    validateAndPrepareTimeline();
    if (vm.items.length > 0) {
      $timeout(render);
    }
  };

  /**
   * @description Renders the component.
   * @function
   */
  function render() {
    // calculate width of the scroll container
    const items = wrapperScroll.children().toArray();
    let scrollWidth = items.reduce((widthSum, item) => {
      let { width } = window.getComputedStyle(item);
      let itemWidth = parseFloat(width);
      return widthSum + Math.ceil(itemWidth);
    }, 0);
    wrapperScroll.width(scrollWidth + 8);

    // scroll to the selected item
    $timeout(() => {
      // first check if arrows need to be displayed
      vm.displayScrollArrow = displayScrollArrowCheck();
      if (vm.displayScrollArrow) {
        // if arrows are rendered and scroll exists, scroll it to the selected element
        let indexToScrollTo = vm.items.reduce((selectedIndex, item, index) => {
          if (item.isSelected) {
            selectedIndex = index;
          }
          return selectedIndex;
        }, 0);
        $timeout(() => {
          scrollToItemAtIndex(indexToScrollTo);
        });
      }
    });
  }

  /**
   * @description scroll timeline horizontally.
   * @function
   * @param {string} direction
   */
  function scrollHorizontally(direction) {
    // based on direction find the first item to have either:
    // offset least negative (left)
    // offset just a bit more than scrollWidth
    const items = wrapperDiv.find('.sfe-timeline__wrapper__item').toArray();
    const currentScrollOffset = wrapperDiv[0].scrollLeft;

    let goTo = 0;
    let found = false;
    let paddingLeft = 64;
    // find item on the left edge
    for (let index = 0; index < items.length; index++) {
      let item = items[index];
      let itemOffset = item.offsetLeft - paddingLeft;
      let offsetDelta = currentScrollOffset - itemOffset;
      // if difference between items scroll position and containers scroll position is negative or zero it means the item is already displayed on screen
      if (direction == 'left' && offsetDelta <= 0) {
        goTo = index - 1;
        found = true;
        break;
      }
      if (direction == 'right' && offsetDelta <= 0) {
        // when delta = 0 it means the item is already displayed so we have to check only for negative delta
        goTo = index + 1;
        found = true;
        break;
      }
    }

    if (found == false) {
      if (direction == 'right') {
        goTo = items.length + 1;
      } else if (direction == 'left') {
        goTo = items.length - 1;
      }
    }

    scrollToItemAtIndex(goTo);
  }

  /**
   * @description Scrolls timeline to item at index n.
   * @function
   * @param {number} index
   */
  const scrollToItemAtIndex = index => {
    const items = wrapperDiv.find('.sfe-timeline__wrapper__item').toArray();
    const nextIndex = index >= items.length ? items.length - 1 : index;
    index = index < 0 ? 0 : nextIndex;
    wrapperDiv.scrollLeft(items[index].offsetLeft - 64);
  };

  /**
   * @description Validates and prepares data for render.
   * @function
   */
  const validateAndPrepareTimeline = () => {
    if (vm.configuration == null) {
      return;
    }

    vm.canEditOnlyLast = vm.configuration.canEditOnlyLast;
    // validate and sort items
    if (
      Array.isArray(vm.configuration.items) &&
      vm.configuration.items.length > 0
    ) {
      vm.items = vm.configuration.items
        .reduce((timelineItems, timelineItem) => {
          if (validateTimelineItem(timelineItem)) {
            return [
              ...timelineItems,
              {
                ...timelineItem,
                // generate subtext for an item
                subtext: `${timelineItem.date.from} - ${timelineItem.date.to ||
                  gettextCatalog.getString('in progress')}`
              }
            ];
          } else {
            return timelineItems;
          }
        }, [])
        // sort by dateRaw.from
        .sort(sortTimelineItems)
        // remove duplicates by dateRaw.from
        .filter(removeDuplicateDateItems)
        // filter any items that intersect with previous item
        .filter(validateTimelineItemInterval)
        // generate edit action configurations
        .map((item, index, items) => {
          return {
            ...item,
            generatedActions: generateConfigForItemAction(
              item,
              index == items.length - 1,
              vm.configuration.canEditOnlyLast,
              index
            )
          };
        });

      // determine isSelected status
      // if multiple items have isSelected: true, than the last one retains it
      let isSelectedIndex = vm.items.length - 1;
      vm.items.forEach((item, index) => {
        if (item.isSelected === true) {
          isSelectedIndex = index;
        }
      });
      selectItem(isSelectedIndex);
      // Call function on selected item.
      if (vm.items[isSelectedIndex].fn != null) {
        vm.items[isSelectedIndex].fn();
      }
    } else {
      vm.items = [];
    }

    // Check if configuration requires addItem button and add it if so.
    if (vm.configuration.addItemFn) {
      vm.addItemAction = vm.configuration.addItemFn();
    }
  };

  /**
   * @description Generates a config for an edit button next to a timeline item.
   * @function
   * @param {object} item
   * @param {boolean} isLast
   * @param {boolean} canEditOnlyLast
   * @return {null|object}
   */
  const generateConfigForItemAction = (
    item,
    isLast,
    canEditOnlyLast,
    index
  ) => {
    let actions = [];
    if (
      Array.isArray(item.duplicateFlowAction) &&
      item.duplicateFlowAction.length > 0
    ) {
      actions = [
        {
          testId: `duplicate_flow_config_${index}`,
          ...item.duplicateFlowAction[0]
        }
      ];
    }
    if (item.editItemFn && (isLast || !canEditOnlyLast)) {
      return [
        ...actions,
        {
          icon: {
            type: 2,
            name: 'edit'
          },
          testId: `edit_flow_config_${index}`,
          color: 'grey',
          fn: event => {
            event.stopPropagation();
            item.editItemFn();
          }
        }
      ];
    } else {
      return actions.length > 0 ? actions : null;
    }
  };

  /**
   * @description filter timeline items that have the same from timestamp as their predecessor
   * @function
   * @param {object} item
   * @param {number} index
   * @param {array} items
   * @return {boolean}
   */
  const removeDuplicateDateItems = (item, index, items) => {
    if (
      index > 0 &&
      item.dateRaw.from.getTime() === items[index - 1].dateRaw.from.getTime()
    ) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * @description validates a timeline item dates based on other items. Items are expected to be sorted by dateRaw.from
   * @function
   * @param {Object} item
   * @param {number} index
   * @param {Array} items
   * @return {boolean}
   */
  const validateTimelineItemInterval = (item, index, items) => {
    return (
      index == 0 ||
      item.dateRaw.from.getTime() > items[index - 1].dateRaw.to.getTime()
    );
  };

  /**
   * @description Validates timeline items.
   * @function
   * @param {object} item
   * @return {boolean}
   */
  const validateTimelineItem = item => {
    let validity = true;
    // name is required
    if (typeof item.name != 'string') {
      validity = false;
    }
    // date is required, it must be a string
    if (item.date == null && item.date.from != 'string') {
      validity = false;
      if (item.date.to && typeof item.date.from != 'string') {
        validity = false;
      }
    }
    if (item.dateRaw == null) {
      validity = false;
    } else {
      // dateRaw from
      if (
        item.dateRaw.from == null ||
        !(item.dateRaw.from instanceof Date && !isNaN(item.dateRaw.from))
      ) {
        validity = false;
      }
      // dateRaw to
      if (
        item.dateRaw.to &&
        !(item.dateRaw.to instanceof Date && !isNaN(item.dateRaw.to))
      ) {
        validity = false;
      }
    }
    // if it has a fn, it has to be a function
    if (item.fn && !angular.isFunction(item.fn)) {
      validity = false;
    }
    // if it has an editFn, it has to be a function
    if (item.editItemFn && !angular.isFunction(item.fn)) {
      validity = false;
    }
    return validity;
  };

  /**
   * @description Returns a sorted array of items by date.
   * @function
   * @param {array} items
   * @return {array}
   */
  const sortTimelineItems = (item, nextItem) => {
    let time1 = new Date(item.validFrom).getTime();
    let time2 = new Date(nextItem.validFrom).getTime();
    return time1 - time2;
  };

  /**
   * @description set isSelected property on true when item is clicked.
   * @function
   * @param {number} selectedIndex - position of selected item in array.
   */
  function selectItem(selectedIndex) {
    vm.items = vm.items.map((item, index) => {
      return { ...item, isSelected: index == selectedIndex };
    });
  }
  /**
   * @description function that checks if the wrapperDIV offset is smaller than the scroll width and if it is displays the scroll arrows
   * @function
   * @return {boolean}
   */
  function displayScrollArrowCheck() {
    return wrapperDiv[0].scrollWidth > wrapperDiv[0].clientWidth;
  }
}
