LocationsOverviewController.$inject = [
  '$state',
  'gettext',
  '$scope',
  'StorageService',
  'SfeFilterService'
];

function LocationsOverviewController(
  $state,
  gettext,
  $scope,
  StorageService,
  SfeFilterService
) {
  const vm = this;
  vm.elementSelected = elementSelected;
  vm.title = $state.current.pageTitle;
  vm.state = $state.current.name;
  vm.entity = 'locations';
  vm.listParents = true;
  vm.filters = [
    {
      display: gettext('Name'),
      param: 'filter',
      type: 'string'
    }
  ];

  // set height of filters
  function setContainerHeight() {
    let item = document.querySelector('.tree-container');
    let filter = document.querySelector('.tree-filters');
    if (filter) {
      let filterHeight = $('.tree-filters').height();
      if (filterHeight && item) {
        angular
          .element(item)
          .css('height', 'calc(100% - ' + filterHeight + 'px)');
      }
    }
    /*************
      MEMORY CLEANUP
      *************/
    item = null;
    filter = null;
  }

  $scope.$on('$destroy', function() {
    if (watchRelist) {
      watchRelist();
    }
  });

  const watchRelist = $scope.$watch('vm.relistData', function() {
    const filter = vm.filters[0];
    if (vm.relistData) {
      vm.queryObject = {};
      if (filter.searchTerm) {
        vm.queryObject = {};
        vm.queryObject[filter.param] = filter.searchTerm;
      }
      vm.relistData = false;
    }
    StorageService.saveListData(vm.entity, [filter]);
  });

  vm.$onInit = async () => {
    setContainerHeight();
    await getStoredFilters();
    vm.relistData = true;
    vm.treeType = 'root';
    $scope.$evalAsync();
  };

  /**
   * @description Returns the stored filters for current entity list.
   * @function
   */
  async function getStoredFilters() {
    const filters = StorageService.getFilters(vm.entity);
    await SfeFilterService.assignSelectedFiltersValues(vm.filters, filters);
  }

  function elementSelected(element) {
    vm.selected = element;
  }
}
export default LocationsOverviewController;
