import { formatLocale } from 'd3-format';
import { DateTime } from 'luxon';

Formatting.$inject = [
  'LocalizationService',
  'gettext',
  'DateLocalizationService'
];
/**
 * @ngdoc factory
 * @name common.Formatting
 * @description Formats input.
 * @property {function} prettyDate - See method prettyDate
 * @property {function} formatDate - See method formatDate
 * @property {function} parseNumber - See method parseNumber
 * @property {function} formatNumber - See method formatNumber
 * @property {function} formatAllNumbers - See method formatAllNumbers
 */
export default function Formatting(
  LocalizationService,
  gettext,
  DateLocalizationService
) {
  /**
   * @memberof common.Formatting
   * @description Returns a number. Parses the decimal part.
   * @param {number} data Data to be parsed.
   * @return {number}
   */
  function parseNumber(data) {
    var dec, whole, result;
    dec = data
      .toString()
      .split(LocalizationService.GetSelectedLang().decimalDelimiter);
    whole = dec[0].split(
      LocalizationService.GetSelectedLang().thousandsDelimiter
    );
    dec.shift();
    if (dec.length > 0) {
      dec = '.' + dec.join('');
    } else {
      dec = '';
    }
    result = Number(whole.join('') + dec);
    return result;
  }

  /**
   * @memberof common.Formatting
   * @description Returns a string, no matter what.
   * @param {number} text Data to be parsed.
   * @return {string}
   */
  function formatAllNumbers(text, decimalPlaces) {
    if (text && text.toString) {
      return text
        .toString()
        .replace(/(^|\s)([+-]?\d+(\.\d+)?(?=$|\s))/g, data =>
          formatNumber(data, decimalPlaces)
        );
    } else {
      return text;
    }
  }

  /**
   * @description returns number decimal places.
   * in number is not valid returns 0
   * @function
   * @param {number} value number than we want to know decimal places of
   * @return {number}
   */
  function countDecimals(value) {
    let result = 0;
    let decimals, exponent, stringified;
    if (!isNaN(value) && value != null && typeof value === 'number') {
      if (Math.abs(value) < 1e-6 && value.toString().indexOf('e') > 0) {
        stringified = Math.abs(value).toString();
        decimals = stringified.split('e-')[0].split('.');
        exponent = stringified.split('e-')[1];
        result =
          (decimals.length > 1 ? decimals[1].length : 0) + Number(exponent);
      } else if (Math.floor(value) !== value) {
        result = value.toString().split('.')[1].length;
      }
    }
    return result;
  }

  /**
   * @memberof common.Formatting
   * @description Returns a number grouped by thousands.
   * @param {number} data Data to be parsed.
   * @return {string}
   */
  function formatNumber(data, decimalPlaces) {
    let result;
    if (data === '' || typeof data === 'undefined') return '';
    else if (data === null) {
      return 'null';
    } else if (['string', 'number'].indexOf(typeof data) === -1) {
      result = 'null';
    } else {
      let hasWhitespace = /^\s/g.test(data);
      let formatFn = formatLocale({
        decimal: LocalizationService.GetSelectedLang().decimalDelimiter,
        thousands: LocalizationService.GetSelectedLang().thousandsDelimiter,
        grouping: [3]
      }).format;
      let numberOfDecimalPlaces = decimalPlaces
        ? decimalPlaces
        : countDecimals(Number(data));
      let format = ',.' + numberOfDecimalPlaces + 'f';
      result = formatFn(format)(Number(data));
      if (hasWhitespace) {
        result = ' ' + result;
      }
    }
    return result.indexOf('NaN') === -1 ? result : data;
  }

  /**
   * @memberof common.Formatting
   * @description Returns the date that's eligible for posting on the database
   * @param {Object} date Date to be parsed.
   * @param {string} time Time to be parsed.
   * @return {Object}
   */
  function formatDate(date, time) {
    if (!date || !time) {
      return;
    }
    var timeArray = time.split(':');
    var hours = timeArray[0];
    var minutes = timeArray[1];
    var seconds = timeArray[2];
    var millisecond = timeArray[3] ?? '000';
    var formatedDate = DateTime.fromJSDate(new Date(date)).set({
      hour: hours,
      minute: minutes,
      second: seconds,
      millisecond: millisecond
    });
    return formatedDate.isValid ? formatedDate.toJSDate() : null;
  }
  /**
   * @memberof common.Formatting
   * @description formats date to hours:minutes string
   * @param {Date} date Date to be parsed.
   * @param {Bool} seconds indicates if seconds must be returned as well
   * @return {String}
   */
  function getHoursAndMinutes(date, seconds) {
    if (date) {
      var formatString = 'HH:mm';
      if (seconds) {
        formatString = 'HH:mm:ss';
      }
      return DateTime.fromJSDate(new Date(date)).toFormat(formatString);
    } else {
      return '';
    }
  }

  /**
   * @memberof common.Formatting
   * @description Returns the date in this format: 15. mar. 2019 23:59:00
   * @param {Object} date Date to be parsed.
   * @return {string}
   */
  function prettyDate(date) {
    var prettyDate = DateTime.fromJSDate(new Date(date))
      .setLocale(LocalizationService.getLocale())
      .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
    if (prettyDate === 'Invalid DateTime') {
      return gettext('Invalid date');
    } else {
      return prettyDate;
    }
  }

  /**
   * @description Format display value of a datapoint based on its type.
   * @function
   * @param {number} type
   * @param {any} value
   * @param {number} precision
   * @return {string|number}
   */
  function formatDatapointDisplay(type, value, precision) {
    let result = value;
    if (value != null && type != null) {
      switch (type) {
      case 3: //decimal
        result = Number(
          Math.round(Number(value) + 'e+' + (precision || 2)) +
              'e-' +
              (precision || 2)
        );
        break;
      case 5: // Date
        result = DateLocalizationService.LocalizationDateIntervalFn('d')(
          Number(value)
        );
        break;
      case 6: // Time
        result = DateLocalizationService.LocalizationDateIntervalFn(
          'timeonly'
        )(Number(value));
        break;
      case 7: // DateTime
        result = DateLocalizationService.LocalizationDateIntervalFn('s')(
          Number(value)
        );
        break;
      default:
        result = String(value);
        break;
      }
    } else {
      result = String(value);
    }
    return result;
  }

  /**
   * @description Cast value to string, remove all non numerical characters and add whitespace after every 3rd character, if value had + add it again due to regex.
   * @function
   * @param {string} phoneNumber
   * @return {string/null} parsed phone number or null
   */
  const formatPhoneNumber = value => {
    if (value != null) {
      let phoneRegex = new RegExp(/(.{3})?(.{2})?(.{3})?(.{3})?(.{0,})/, 'g');
      if (value.length === 13) {
        phoneRegex = new RegExp(/(.{3})?(.{2})?(.{3})?(.{4})?(.{0,})/, 'g');
      }
      let parsedValue = value
        .toString()
        .replace(/[^\d0-9]/g, '')
        .replace(
          phoneRegex,
          (value, countryCode, digits1, digits2, digits3, digits4) => {
            return (
              validatePhoneSequence(countryCode) +
              validatePhoneSequence(digits1) +
              validatePhoneSequence(digits2) +
              validatePhoneSequence(digits3) +
              `${
                digits4 && !(countryCode && digits1 && digits2 && digits3)
                  ? ' ' + digits4
                  : ''
              }`
            );
          }
        )
        .trim();
      // Check if parsed value is not empty and add + at the start, check if all numbers were removed and add +
      if (
        parsedValue.length > 0 ||
        (parsedValue.length === 0 && value.length > 0)
      ) {
        parsedValue = '+' + parsedValue;
      }
      return parsedValue;
    }
    return null;
  };
  /**
   * @description check if phone digits exist.
   * @function
   * @param {string} digits - phone digits
   * @return {string}
   */
  const validatePhoneSequence = digits => {
    return digits ? ' ' + digits : '';
  };

  return {
    prettyDate,
    formatDate,
    parseNumber,
    formatNumber,
    formatAllNumbers,
    getHoursAndMinutes,
    formatDatapointDisplay,
    formatPhoneNumber
  };
}
