import './sfe-form-2-autocomplete.scss';
import template from './sfe-form-2-autocomplete.component.html';

/**
 * @ngdoc component
 * @name common.sfeForm2
 * @description Component for display and handling of the form.
 * @param {String} autocompleteTitle
 * @param {Object} options
 *    @param {Array} items - items that will be displayed in autocomplete selector
 *    @param {String} valueField - indicates name of the value field - default is id 
 *    @param {Object} itemsCrawler
 *        @param {String} entity
 *        @param {String} method
 *    @param {function} crawlerParams - function that as parameter gets string entered in autocomplete input, should return an object
 *    @param {function} filter - function gets items array and should return filtered items array
 *    @param {function} display - function gets an object should return a string representation of that object
 *    @param {object} dialog
 *        @param {String} entity name of the entity to display dialog example: 'locations'
 * @param {Object} api - sfe-form object that contains form api functions
 * @param {Object} model - object that contains model
 * @param {function} disable - function that returns boolean
 * @param {function} change 
 * @example
 * <sfe-form-2-autocompelte
    autocomplete-title="'Label title"
    options="vm.options"
    api="vm.api"
    ng-model="vm.value"
    disable="vm.disable"
    change="vm.change"
 * ></sfe-form-2-autocompelte>
 */

export default {
  template,
  bindings: {
    autocompleteTitle: '<',
    options: '<',
    api: '<',
    disable: '<',
    change: '<',
    validators: '<'
  },
  require: {
    form: '^form',
    model: 'ngModel'
  },
  controller: SfeForm2AutocompleteController,
  controllerAs: 'vm',
  bindToController: true
};

SfeForm2AutocompleteController.$inject = [
  'AlertingService',
  'CrawlerMethods',
  '$scope',
  '$timeout',
  'ItemSelector',
  'filterService'
];
function SfeForm2AutocompleteController(
  AlertingService,
  CrawlerMethods,
  $scope,
  $timeout,
  ItemSelector,
  filterService
) {
  const vm = this;
  vm.randomHash = Math.random()
    .toString(36)
    .substring(2);

  vm.fetchItems = fetchItems;
  vm.selectedItemChanged = selectedItemChanged;

  $scope.$on('$destroy', () => {
    stopWatcher();
  });
  /**
   * @description watches for model data to come and
   * if it is set to a string or a number triggers
   * function that fetches data.
   * @function
   */
  let stopWatcher = $scope.$watch(
    function() {
      if (vm.model != null) {
        return vm.model.$modelValue;
      }
    },
    model => {
      if (typeof model == 'string' || typeof model == 'number') {
        initializeModelValue(model);
      } else if (typeof model == 'object') {
        vm.modelValue = model;
        if (typeof vm.change == 'function') {
          vm.change(vm.api, vm.model.$modelValue, vm.model.$name);
        }
        $scope.$applyAsync();
      }
    }
  );

  vm.$onInit = () => {
    $timeout(() => {
      vm.actions = getActions();
      disableActions();
    });
  };

  vm.$onChanges = changes => {
    //if disable function changes update disable function in actions array
    //if autocomplete is disabled open dialog and empty autocomplete buttons should be disabled
    if (changes.disable) {
      disableActions();
    }
  };

  function disableActions() {
    if (Array.isArray(vm.actions)) {
      vm.actions = vm.actions.map(action => {
        return {
          ...action,
          disabledFn: vm.disable
        };
      });
    }
  }

  /**
   * @description Gets triggered every time value of selected items changes.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function selectedItemChanged() {
    //Trigger custom validation
    //after timeout
    $timeout(() => {
      vm.model.$setViewValue(vm.modelValue);
      if (typeof vm.change == 'function') {
        vm.change(vm.api, vm.model.$modelValue, vm.model.$name);
      }
    });
  }

  /**
   * @description returns array of actions
   * to open select dialog and to empty autocomplete field.
   * @function
   * @return {Array}
   */
  function getActions() {
    let actions = [];
    //Add open dialog button only when dialog entity is defined
    if (
      vm.options != null &&
      vm.options.dialog != null &&
      vm.options.dialog.entity != null
    ) {
      actions.push({
        color: 'grey',
        icon: {
          type: 2,
          name: 'search'
        },
        fn: openAutocompleteDialog,
        disabledFn: vm.disable
      });
    }

    if (!vm.model.$$attr.required && vm.options && !vm.options.noRemoveButton) {
      actions.push({
        color: 'grey',
        icon: {
          type: 2,
          name: 'close'
        },
        fn: () => {
          vm.modelValue = null;
        },
        disabledFn: vm.disable
      });
    }
    return actions;
  }
  /**
   * @description Opens select dialog and if new item selected applies its value to data model.
   * @function
   */
  async function openAutocompleteDialog() {
    const { entity, ...otherDialogConfigs } = vm.options.dialog;

    const configuration = {
      filterFn: vm.options.filter,
      filterObjectFn:
        typeof vm.options.crawlerParams == 'function'
          ? bindSecondArgument(vm.options.crawlerParams, vm.api)
          : null,
      ...otherDialogConfigs
    };
    try {
      let [result] = await ItemSelector.open([entity], [configuration]);

      if (result != null) {
        vm.modelValue = result;
      }
      $scope.$applyAsync();
    } catch (err) {
      // eslint-disable-next-line no-empty
    }
  }

  /**
   * @description if model value comes as a String (Mongo Entity) or a number (codelist id) gets value for this String|Number.
   * @function
   * @param {Number|String} value model value
   */
  async function initializeModelValue(value) {
    let selectedItem = null;
    if (Array.isArray(vm.options.items)) {
      let valueField = vm.options.valueField || 'id';
      selectedItem = vm.options.items.find(item => item[valueField] == value);
    } else if (vm.options.itemsCrawler != null) {
      const method = CrawlerMethods.getMethod(vm.options.itemsCrawler);
      if (method != null) {
        let queryParams = { id: value };
        try {
          const { data } = await method(queryParams);
          selectedItem = data;
        } catch (err) {
          AlertingService.Error(err);
        }
      } else {
        AlertingService.devWarn(
          'Something is wrong with itemsCrawler configuration'
        );
      }
    }

    if (selectedItem != null) {
      selectedItem = {
        ...selectedItem,
        __prefetched__value__: true
      };
    }
    vm.modelValue = selectedItem;
    vm.model.$setViewValue(vm.modelValue);
    if (typeof vm.change == 'function') {
      vm.change(vm.api, vm.model.$modelValue, vm.model.$name);
    }
    $scope.$applyAsync();
  }
  /**
   * @description returns array of items that will be displayed in autocomplete dropdown menu.
   * @function
   * @return {Array}
   */
  async function fetchItems() {
    vm.model.$setViewValue(null);

    let items = [];
    //Array of items
    if (Array.isArray(vm.options.items)) {
      var filter = [
        {
          param: 'name',
          searchTerm: vm.searchText,
          type: 'string'
        }
      ];
      items = vm.options.items;
      items = filterService.filterDataV1(items, filter);
      await $timeout();
      //Crawler Config
    } else if (vm.options.itemsCrawler != null) {
      const method = CrawlerMethods.getMethod(vm.options.itemsCrawler);
      if (method != null) {
        let queryParams = vm.options.crawlerParams(vm.searchText, vm.api);
        try {
          const { data } = await method(queryParams);
          items = data;
        } catch (err) {
          AlertingService.Error(err);
        }
      } else {
        AlertingService.devWarn(
          'Something is wrong with itemsCrawler configuration'
        );
      }
    } else {
      AlertingService.devWarn('Items or items crawler is missing');
    }
    if (typeof vm.options.filter == 'function') {
      items = vm.options.filter(items, vm.api);
    }
    return items;
  }

  /**
   * @description when there are no first argument passes first argument as null and binds the rest of args
   * @function
   * @param {Function} fn
   * @param {Any} bound_args
   * @return {Function}
   */ function bindSecondArgument(fn, ...bound_args) {
    return function(...args) {
      if (args.length == 0) {
        return fn(null, ...bound_args);
      } else {
        return fn(...args, ...bound_args);
      }
    };
  }
}
