ExternalDatasourceDataXConfiguration.$inject = [
  'SfeDataXTranslations',
  'gettextCatalog',
  'gettext',
  'Formatting',
  'DateLocalizationService',
  'HeartbeatHistoryLastModel',
  'AlertingService'
];

function ExternalDatasourceDataXConfiguration(
  SfeDataXTranslations,
  gettextCatalog,
  gettext,
  Formatting,
  DateLocalizationService,
  HeartbeatHistoryLastModel,
  AlertingService
) {
  /**
   * @description returns function that returns chart values.
   * @function
   * @param {string} externalDatasourceId
   * @return {function}
   */
  function getValues(externalDatasourceId) {
    /**
     * @description returns sorted values.
     * @function
     * @param {Object} filter filter object
     * @param {Boolean} manualRefresh indicates when chart refresh is triggered manually
     * @return {Promise} Array
     */
    return async (filter, manualRefresh) => {
      try {
        let { data: modelData } = await HeartbeatHistoryLastModel.read(
          {
            externalDatasourceId
          },
          manualRefresh
        );
        let previous;
        let values = modelData.map(value => {
          let valueObj = {};
          valueObj.validAt = new Date(value.date).getTime();
          if (typeof previous === 'undefined') {
            valueObj.value = 30;
          } else {
            valueObj.value = (previous - valueObj.validAt) / 1000;
          }
          previous = valueObj.validAt;
          return valueObj;
        }, []);
        return values
          .map(function(value) {
            return { x: new Date(value.validAt), y: value.value };
          })
          .sort(function(valueA, valueB) {
            return valueA.x - valueB.x;
          });
      } catch (err) {
        AlertingService.Error(err);
        return [];
      }
    };
  }

  /**
   * @description returns chart configuration
   * @function
   * @param {string} externalDatasourceId
   * @return {Object}
   */
  const get = externalDatasourceId => {
    const translations = SfeDataXTranslations.get();
    const axisY = [
      {
        title: gettextCatalog.getString('device contacts'),
        suffix: 's',
        formatter: Formatting.formatNumber,
        lines: [
          {
            isMin: true,
            min: 0,
            color: '#ff0000'
          }
        ],
        bands: []
      }
    ];

    const axisX = [
      {
        type: 'datetime',
        title: gettextCatalog.getString('Time'),
        formatter: DateLocalizationService.LocalizationDateFn
      }
    ];

    const series = [
      {
        // 247 - external datasource
        id: 247,
        name: gettext('Device heartbeats'),
        query: getValues(externalDatasourceId),
        nonNumerical: false,
        decimalPrecision: 3,
        type: 'spline',
        color: '#12948b'
      }
    ];

    return {
      axis: {
        x: axisX,
        y: axisY
      },
      series,
      chart: {
        legend: false,
        markers: true,
        dataLabels: false,
        axisYTitle: false,
        axisXTitle: false,
        height: 420
      },
      fullscreen: true,
      grid: {
        height: 420
      },
      mode: {
        chart: true,
        grid: true
      },
      callbacks: {},
      translations
    };
  };

  return {
    get
  };
}

export default ExternalDatasourceDataXConfiguration;
