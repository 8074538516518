/**
 * @ngdoc directive
 * @name moduleName.sfeMax
 * @description Function that replaces ng-max on non number type inputs. It checks if modelValue is lower than sfeMaxValue. It also adds a sfeMax validator to the controller
 * @example
 * <input
 *  type="numerical"
 *  sfe-max="{{vm.input.max}}"
 * ></input>
 */
sfeMax.$inject = ['$timeout'];
export default function sfeMax() {
  return {
    restrict: 'A',
    require: ['ngModel', '^form'],
    link: function(scope, element, attrs, controllers) {
      const model = controllers[0];
      /**
       * @description function that is passed to the controller validator. It checks, if the value exists and if it is lower than sfe-max
       * @function
       * @param {integer} modelValue - checks if
       * @return {boolean} - true if value is not higher than sfeMax
       */
      const sfeMaxValidation = modelValue => {
        const parsedAttr = parseInt(attrs.sfeMax);
        if (typeof modelValue == 'number' && !isNaN(parsedAttr)) {
          if (modelValue > attrs.sfeMax) {
            return false;
          }
        }
        return true;
      };
      model.$validators = {
        ...model.$validators,
        sfeMax: sfeMaxValidation
      };
    }
  };
}
