NewDomainCollectionConfigurationFormController.$inject = [
  'DomainFieldModel',
  'AlertingService',
  '$state',
  'gettext',
  'CrudToastFactory',
  'DomainCollectionConfigurationService',
  'DomainCollectionModel',
  'SfeHeader',
  'MetadataService'
];

function NewDomainCollectionConfigurationFormController(
  DomainFieldModel,
  AlertingService,
  $state,
  gettext,
  CrudToastFactory,
  DomainCollectionConfigurationService,
  DomainCollectionModel,
  SfeHeader,
  MetadataService
) {
  var vm = this;
  var domainFiledsDescription;
  var waterfall = [readDomainField];

  init();
  function init() {
    async.waterfall(waterfall, function(err, domainFields) {
      vm.dataConfig.data = DomainCollectionConfigurationService.getForm(
        domainFields
      );
      domainFiledsDescription = domainFields;
      if ($state.params.collectionId) {
        readCollection();
        MetadataService.ChangeMetadata('Edit Domain Collection Item');
      } else {
        vm.dataConfig.dataObj = {};
        MetadataService.ChangeMetadata('Cerate Domain Collection Item');
        _.each(domainFields, function(domainField) {
          if (domainField.fieldType === 'Date') {
            vm.dataConfig.dataObj[domainField.rawFieldName] = new Date();
          } else if (domainField.fieldType === 'Boolean') {
            vm.dataConfig.dataObj[domainField.rawFieldName] = false;
          }
        });
      }
    });

    var title = $state.params.collectionId
      ? gettext('Edit Domain Collection')
      : gettext('New Domain Collection');

    var header;
    if ($state.params.collectionId) {
      // edit mode
      header = SfeHeader.constructFormHeader(
        'primary',
        title,
        'configurations-dynamic-attributes-domains-collections-view',
        {
          domainId: $state.params.domainId,
          collectionId: $state.params.collectionId
        }
      );
    } else {
      // new mode
      header = SfeHeader.constructFormHeader(
        'primary',
        title,
        'configurations-dynamic-attributes-domains-view',
        {
          domainId: $state.params.domainId
        }
      );
    }

    vm.dataConfig = {
      header,
      edit: !!$state.params.collectionId,
      action: {
        ctrlFn: true,
        title: $state.params.collectionId
          ? gettext('Update')
          : gettext('Create'),
        fn: createOrUpdate
      }
    };
  }

  function readDomainField(callback) {
    DomainFieldModel.read({
      domainId: $state.params.domainId
    }).then(
      function(res) {
        callback(null, res.data);
      },
      function(err) {
        AlertingService.Error(err);
        callback();
      }
    );
  }

  function readCollection() {
    DomainCollectionModel.read($state.params).then(
      function(res) {
        constructDataObj(res.data);
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function constructDataObj(collection) {
    vm.dataConfig.dataObj = {};
    _.each(domainFiledsDescription, function(domainField) {
      if (domainField.fieldType === 'Date') {
        vm.dataConfig.dataObj[domainField.rawFieldName] = new Date(
          collection[domainField.rawFieldName]
        );
      } else {
        vm.dataConfig.dataObj[domainField.rawFieldName] =
          collection[domainField.rawFieldName];
      }
    });
  }

  function createOrUpdate(domainCollection) {
    var apiCall = $state.params.collectionId
      ? DomainCollectionModel.update(
        $state.params,
        getAPIObject(domainCollection)
      )
      : DomainCollectionModel.create(
        { domainId: $state.params.domainId },
        getAPIObject(domainCollection)
      );
    var message = $state.params.collectionId ? 'update' : 'create';
    apiCall.then(
      function(res) {
        var collectionId;
        if (res.data) {
          if (res.data.length > 0) {
            collectionId = res.data[0]._id;
          } else {
            collectionId = res.data._id;
          }
        }
        var redirectObj = {
          state: 'configurations-dynamic-attributes-domains-collections-view',
          params: {
            domainId: $state.params.domainId,
            collectionId: collectionId
          }
        };
        CrudToastFactory.toast(message, redirectObj);
      },
      function(err) {
        AlertingService.Error(err);
        vm.sendingRequest = false;
      }
    );
  }

  function getAPIObject() {
    var apiObject = {};
    _.each(domainFiledsDescription, function(domainField) {
      apiObject[domainField.rawFieldName] =
        vm.dataConfig.dataObj[domainField.rawFieldName];
    });
    return apiObject;
  }
}

export default NewDomainCollectionConfigurationFormController;
