module.exports = {
  entity: 'webservices',
  path: 'webservices',
  name: {
    en: 'Webservice',
    sl_SI: 'spletna storitev'
  },
  networkModel: {
    entity: 'webservices',
    read: 'read'
  },
  scope: 'tis_be:webservices',

  dialog: {
    title: {
      en: 'Select webservice',
      sl_SI: 'Izberite spletni servis'
    },
    listInfo: {
      en: 'Select webservice from the list below',
      sl_SI: 'Izberite spletni servis s spodnjega seznama'
    }
  }
}
