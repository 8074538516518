import './time-series-processing-extended-values-by-timestamp.scss';
import template from './time-series-processing-extended-values-by-timestamp.dialog.html';
import { DateTime } from 'luxon';

/**
 * @ngdoc factory
 * @name common. TimeSeriesProcessingExtendedValuesByTimestampDialog
 * @description Used for opening of a dialogs that tells you specific timeseries data based on timestamp
 * @property {function} open - Function that triggers the opening of the dialog
 */

TimeSeriesProcessingExtendedValuesByTimestampDialogService.$inject = [
  '$mdDialog',
  'DateLocalizationService'
];

export default function TimeSeriesProcessingExtendedValuesByTimestampDialogService(
  $mdDialog,
  DateLocalizationService
) {
  /**
   * @description Triggers the opening of the dialog.
   * @function
   * @memberof InfoDialog
   * @param {Object} items All the value for the particular timestamp
   * @param {Array} actions Array of actions
   * @param {string} timeseriesId Id of the timesiers to which the values belong to
   * @param {Function} formatter - format values
   */
  function open(items, actions, timeseriesId, formatter, timeSeriesObject) {
    $mdDialog
      .show({
        template,
        controller: [
          'gettextCatalog',
          'TranslationService',
          'TimeSeriesModel',
          'AlertingService',
          '$scope',
          'ExternalDatasourceModel',
          'TangoAgentConnectionConfigModel',
          'items',
          'actions',
          'timeseriesId',
          'formatter',
          'timeSeriesObject',
          TimeSeriesProcessingExtendedValuesByTimestampController
        ],
        controllerAs: 'vm',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        locals: {
          items,
          actions,
          timeseriesId,
          formatter,
          timeSeriesObject
        }
      })
      .then(
        function() {
          if (actions && actions.confirmFn) {
            actions.confirmFn(actions.confirmParam);
            $mdDialog.cancel();
          }
        },
        function() {
          if (actions && actions.rejectFn) {
            actions.rejectFn();
          }
        }
      );
  }

  const toPreetyDate = ({ text }) => {
    if (text.createdAt) {
      return {
        text: {
          ...text,
          createdAt: `${
            text.createdAt
          } - ${DateLocalizationService.LocalizationDateFn(
            text.createdAt,
            false,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
            false
          )}`
        }
      };
    } else {
      return { text };
    }
  };

  /**
   * @description Dialog controller.
   * @function
   */
  function TimeSeriesProcessingExtendedValuesByTimestampController(
    gettextCatalog,
    TranslationService,
    TimeSeriesModel,
    AlertingService,
    $scope,
    ExternalDatasourceModel,
    TangoAgentConnectionConfigModel,
    items,
    actions,
    timeseriesId,
    formatter,
    timeSeriesObject
  ) {
    const vm = this;
    vm.$onInit = async () => {
      vm.noLogs = false;
      let valueDate = '';
      vm.items = items;
      vm.additionalProperties = [];
      if (Array.isArray(items) && items.length > 0) {
        vm.isMaintenanceFlow = items[0].isMaintenanceFlow;
        //goes through all the events and all the alarms inside the events to add their delays to the timestamp
        let allAlarmTimestamps = [];
        if (
          items[0].streamArchive != null &&
          Array.isArray(items[0].streamArchive.events)
        ) {
          allAlarmTimestamps = items[0].streamArchive.events.reduce(
            (timestampSet, event) => {
              const alarms = event.alarms;
              if (Array.isArray(alarms) && alarms.length > 0) {
                vm.hasAlarms = true;
                for (let i = 0; alarms.length > i; i++) {
                  timestampSet.add(
                    items[0].streamArchive.validAt + alarms[i].delay
                  );
                }
              }
              return timestampSet;
            },
            new Set()
          );
        }
        vm.triggeredAlarmsFilters = {
          timeSeries: timeseriesId,
          triggeredTimestamp: [...allAlarmTimestamps]
        };
        vm.currentTitle = gettextCatalog.getString('Current Version');
        vm.previousTitle = gettextCatalog.getString('Previous Versions');
        let validAtDate;
        let timeZoneCode = timeSeriesObject.timeZone;
        if (typeof timeSeriesObject.timeZone === 'number') {
          let timeZone = TranslationService.GetCollectionById(
            'codelists.timeZones',
            timeSeriesObject.timeZone
          );
          if (timeZone != null) {
            timeZoneCode = timeZone.code;
          }
        }
        if (typeof items[0].streamArchive.validAt == 'number') {
          validAtDate = DateTime.fromMillis(items[0].streamArchive.validAt);
          validAtDate = validAtDate.setZone(timeZoneCode);
        }
        valueDate =
          validAtDate != null
            ? validAtDate.toFormat('dd. MM. yyyy HH:mm:ss')
            : gettextCatalog.getString('No date');
        if (typeof formatter !== 'function') {
          formatter = value => value;
        }
        let timeSeries;
        if (timeseriesId != null && timeSeriesObject == null) {
          try {
            const { data } = await TimeSeriesModel.read({ id: timeseriesId });
            timeSeries = data;
          } catch (err) {
            AlertingService.Error(err);
          }
        } else if (timeSeriesObject != null) {
          timeSeries = angular.copy(timeSeriesObject);
        }
        if (items[0].streamArchive.additionalProperties != null) {
          if (
            typeof items[0].streamArchive.additionalProperties
              .integrationTimestamp === 'number'
          ) {
            let integrationTimeStampDate = DateTime.fromMillis(
              items[0].streamArchive.additionalProperties.integrationTimestamp
            ).toFormat('dd. MM. yyyy HH:mm:ss');
            const integrationTimestampProperty = {
              testId: 'integrationTimestamp',
              type: 'basic',
              title: gettextCatalog.getString('Integration timestamp'),
              values: [
                {
                  text: integrationTimeStampDate
                }
              ]
            };
            vm.additionalProperties.push(integrationTimestampProperty);
          }

          if (
            items[0].streamArchive.additionalProperties.calculatedValue != null
          ) {
            const calculatedValueProperty = {
              testId: 'integrationTimestamp',
              type: 'basic',
              title: gettextCatalog.getString('Calculated value'),
              values: [
                {
                  text:
                    items[0].streamArchive.additionalProperties.calculatedValue
                }
              ]
            };
            vm.additionalProperties.push(calculatedValueProperty);
          }

          if (
            items[0].streamArchive.additionalProperties.connectionConfig != null
          ) {
            const typeCode = TranslationService.GetCollectionById(
              'codelists.externalDatasourceTypes',
              items[0].streamArchive.additionalProperties.connectionConfig.type
            );
            let datasourceText;
            if (
              items[0].streamArchive.additionalProperties.connectionConfig
                .id === window.customization.manualInputExternalDatasource
            ) {
              datasourceText = gettextCatalog.getString('Manual input');
            } else if (
              items[0].streamArchive.additionalProperties.connectionConfig.id !=
              null
            ) {
              try {
                datasourceText =
                  items[0].streamArchive.additionalProperties.connectionConfig
                    .id;
                const { data } = await ExternalDatasourceModel.read({
                  _id:
                    items[0].streamArchive.additionalProperties.connectionConfig
                      .id
                });
                if (Array.isArray(data) && data.length) {
                  datasourceText = data[0].name;
                }
              } catch (err) {
                AlertingService.Error(err);
              }
            }
            let tangoAgentText;
            if (
              items[0].streamArchive.additionalProperties.connectionConfig
                .connectionId != null
            ) {
              try {
                tangoAgentText =
                  items[0].streamArchive.additionalProperties.connectionConfig
                    .connectionId;
                const { data } = await TangoAgentConnectionConfigModel.read({
                  _id:
                    items[0].streamArchive.additionalProperties.connectionConfig
                      .connectionId
                });
                if (Array.isArray(data) && data.length) {
                  tangoAgentText = data[0].name;
                }
              } catch (err) {
                AlertingService.Error(err);
              }
            }
            const connectionConfigurationProperties = [
              {
                testId: 'connectionConfigurationid',
                type: 'basic',
                title: gettextCatalog.getString('Connection configuration id'),
                values: [
                  {
                    text: datasourceText
                  }
                ]
              },
              {
                testId: 'connectionConfigurationType',
                type: 'basic',
                title: gettextCatalog.getString(
                  'Connection configuration type'
                ),
                values: [
                  {
                    text:
                      typeCode != null
                        ? typeCode.name
                        : gettextCatalog.getString('Unknown')
                  }
                ]
              }
            ];
            if (
              items[0].streamArchive.additionalProperties.connectionConfig
                .id !== window.customization.manualInputExternalDatasource
            ) {
              connectionConfigurationProperties.push(
                {
                  testId: 'connectionConfigurationConnectionId',
                  type: 'basic',
                  title: gettextCatalog.getString(
                    'Connection configuration connection id'
                  ),
                  values: [
                    {
                      text: tangoAgentText
                    }
                  ]
                },
                {
                  testId: 'connectionConfigurationTag',
                  type: 'basic',
                  title: gettextCatalog.getString(
                    'Connection configuration tag'
                  ),
                  values: [
                    {
                      text:
                        items[0].streamArchive.additionalProperties
                          .connectionConfig.uniqueIdentifier
                    }
                  ]
                }
              );
            }
            vm.additionalProperties.push(...connectionConfigurationProperties);
          }
        }
        //creates the configuration for the sfe-property-list called longVersion and the shortVersion that is the title of the sfe-property-list
        const allItems = items.reduce((arr, item) => {
          let writtenDate = gettextCatalog.getString('No date');
          const timelinessCode = TranslationService.GetCollectionById(
            'codelists.streamArchiveTimeliness',
            item.streamArchive.timeliness
          );
          const creationTypeCode = TranslationService.GetCollectionById(
            'codelists.streamArchiveValueCreationTypes',
            item.streamArchive.creationType
          );
          if (
            item.streamArchive != null &&
            typeof item.streamArchive.sampledAt === 'number'
          ) {
            writtenDate = DateTime.fromMillis(
              item.streamArchive.sampledAt
            ).toFormat('dd. MM. yyyy HH:mm:ss');
          }
          const recordingDate =
            typeof item.streamArchive.createdAt === 'number'
              ? DateTime.fromMillis(item.streamArchive.createdAt).toFormat(
                'dd. MM. yyyy HH:mm:ss'
              )
              : gettextCatalog.getString('No date');
          let longVersion;
          if (vm.isMaintenanceFlow) {
            longVersion = [
              {
                type: 'basic',
                title: gettextCatalog.getString('Value'),
                testId: 'value',
                values: [
                  {
                    text: formatter(item.streamArchive.value)
                  }
                ]
              },
              {
                type: 'basic',
                title: gettextCatalog.getString('Creation type'),
                testId: 'creationType',
                values: [
                  {
                    text: gettextCatalog.getString('Maintenance flow')
                  }
                ]
              },
              {
                type: 'log',
                testId: 'log',
                values: item.processLog.map(item => ({ text: item }))
              }
            ];
          } else {
            longVersion = [
              {
                type: 'basic',
                title: gettextCatalog.getString('Value'),
                testId: 'value',
                values: [
                  {
                    text: formatter(item.streamArchive.value)
                  }
                ]
              },
              {
                type: 'basic',
                title: gettextCatalog.getString('Date of data recording'),
                testId: 'dateOfDataRecording',
                values: [
                  {
                    text: recordingDate
                  }
                ]
              },
              {
                type: 'basic',
                title: gettextCatalog.getString('Date of data reading'),
                testId: 'dateOfDataReading',
                values: [
                  {
                    text: writtenDate
                  }
                ]
              },
              {
                type: 'basic',
                title: gettextCatalog.getString('Integrity value'),
                testId: 'integrity',
                values: [
                  {
                    text: item.streamArchive.integrity
                  }
                ]
              },
              {
                type: 'basic',
                title: gettextCatalog.getString('Consistency'),
                testId: 'consistency',
                values: [
                  {
                    text: item.streamArchive.consistency
                  }
                ]
              },
              {
                type: 'basic',
                title: gettextCatalog.getString('Effort'),
                testId: 'effort',
                values: [
                  {
                    text: item.streamArchive.effort
                  }
                ]
              },
              {
                type: 'basic',
                title: gettextCatalog.getString('Timeliness'),
                testId: 'timeliness',
                values: [
                  {
                    text:
                      timelinessCode != null
                        ? timelinessCode.name
                        : gettextCatalog.getString('Unknown')
                  }
                ]
              },
              {
                type: 'basic',
                title: gettextCatalog.getString('Creation type'),
                testId: 'creationType',
                values: [
                  {
                    text:
                      creationTypeCode != null
                        ? creationTypeCode.name
                        : gettextCatalog.getString('Unknown')
                  }
                ]
              },
              {
                type: 'log',
                testId: 'log',
                values: item.processLog.map(item => ({ text: item }))
              }
            ];
          }
          if (
            timeSeries != null &&
            timeSeries.dataInterpretationType === 300 &&
            !vm.isMaintenanceFlow
          ) {
            let validFromDate;
            if (typeof item.streamArchive.validFrom === 'number') {
              validFromDate = DateTime.fromMillis(item.streamArchive.validFrom);
              let timeZone;
              if (timeSeries.timeZone != null) {
                timeZone = TranslationService.GetCollectionById(
                  'codelists.timeZones',
                  timeSeries.timeZone
                );
              }
              if (timeZone != null) {
                validFromDate = validFromDate.setZone(timeZone.code);
              }
              validFromDate = validFromDate.toFormat('dd. MM. yyyy HH:mm:ss');
            }
            let validFromProperty = {
              type: 'basic',
              title: gettextCatalog.getString('Valid from'),
              testId: 'validFrom',
              values: [
                {
                  text: validFromDate
                }
              ]
            };
            longVersion.splice(1, 0, validFromProperty);
          }
          const shortVersion =
            gettextCatalog.getString('Value:') +
            ' ' +
            formatter(item.streamArchive.value) +
            '\xa0\xa0\xa0\xa0\xa0\xa0' +
            gettextCatalog.getString('Date of data recording:') +
            ' ' +
            recordingDate;
          arr.push({
            shortVersion,
            longVersion
          });
          return arr;
        }, []);
        //creates the current item and the current items log, which are showed seperatedly
        const [
          currentLogs,
          ...currentItem
        ] = allItems.shift().longVersion.reverse();
        vm.currentLogs = [
          {
            ...currentLogs,
            values: currentLogs.values.map(toPreetyDate)
          }
        ];
        vm.currentItem = currentItem.reverse();
        //all the previous versions of the value at the current timestamp
        vm.oldItems =
          Array.isArray(allItems) && allItems.length > 0
            ? allItems.map(item => ({
              ...item,
              longVersion: item.longVersion.map(itemLV => ({
                ...itemLV,
                values: itemLV.values.map(toPreetyDate)
              }))
            }))
            : [];
      } else {
        vm.noLogs = true;
      }
      vm.actions = actions;
      //dialog header
      vm.header = {
        title: gettextCatalog.getString('Value details ') + valueDate,
        isDialog: true,
        actions: [
          {
            icon: {
              name: 'close',
              type: 2
            },
            fn: function() {
              $mdDialog.cancel();
            }
          }
        ]
      };
      vm.cancel = function() {
        $mdDialog.cancel();
      };
      vm.close = function() {
        $mdDialog.hide();
      };
      $scope.$evalAsync();
    };
  }

  return {
    open: open
  };
}
