module.exports = {
  entity: 'analysis-calculations',
  path: 'analysis-calculations',
  name: {
    en: 'Analysis Calculation',
    sl_SI: 'Analize'
  },
  networkModel: {
    entity: 'analysis-calculations',
    read: 'read'
  },
  scope: 'tis_be:analysis-calculations',

  dialog: {
    title: {
      en: 'Select analysis calculation',
      sl_SI: 'Izberite analizo'
    },
    listInfo: {
      en: 'Select analysis calculation from the list below',
      sl_SI: 'Izberite analizo s spodnjega seznama'
    }
  }
}
