module.exports = {
  entity: 'visualizations',
  path: 'visualizations',
  name: {
    en: 'Visualization',
    sl_SI: 'Vizualizacija'
  },
  networkModel: {
    entity: 'visualizations',
    read: 'read'
  },
  scope: 'tis_be:visualizations',
  dialog: {
    title: {
      en: 'Select visualization',
      sl_SI: 'Izberite vizualizacijo'
    },
    listInfo: {
      en: 'Select visualization from the list below',
      sl_SI: 'Izberite vizualizacijo s spodnjega seznama'
    }
  },
  filterGrid: {
    title: {
      en: 'Visualization cards',
      sl_SI: 'Kartice visualizacij'
    },
    dashboardCardType: 12,
    dashboardCardSize: 1,
    type: 'visualization',
    preloaded: true,
    entities: [
      {
        entityId: '_id'
      }
    ]
  }
}
