AssetTypeConfigController.$inject = [
  '$q',
  '$timeout',
  '$stateParams',
  'gettext',
  'MetadataService',
  'assetType'
];

function AssetTypeConfigController(
  $q,
  $timeout,
  $stateParams,
  gettext,
  MetadataService,
  assetType
) {
  var vm = this;
  var assetTypeId = $stateParams.id;

  init();

  function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    initiateAttributesTable();
    vm.headerData = constructHeader(assetType);
    vm.loading = false;
    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(assetType.name, assetType.description);
    });
  }

  function constructHeader(assetType) {
    var properties = [
      {
        title: gettext('Asset group'),
        type: 'raw',
        value: assetType.assetGroup
          ? assetType.assetGroup.name
          : gettext('unknown')
      },
      {
        title: gettext('Code'),
        type: 'code',
        value: assetType.code || '/'
      },
      {
        title: gettext('External code'),
        type: 'code',
        value: assetType.externalCode || '/'
      }
    ];
    var propertySections = [
      {
        properties: properties
      }
    ];
    var actions = [
      {
        title: gettext('Update'),
        state: 'configurations-company-resources-assets-asset-types-edit',
        param: 'id',
        paramValue: assetTypeId
      }
    ];
    return {
      metadata: {
        definition: gettext('Asset type'),
        title: assetType.name,
        description: assetType.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }

  function initiateAttributesTable() {
    vm.listConfig = {
      entity: {
        plural: gettext('Asset type attributes')
      },
      api: {
        query: {
          entity: 'asset-type-attributes',
          method: 'custom.list'
        },
        enrich: enrich
      },
      title: {
        param: 'name'
      },
      attributes: [
        {
          title: gettext('Description'),
          param: 'description'
        },
        {
          title: gettext('Element type'),
          param: 'elementType'
        }
      ]
    };

    vm.filters = {
      type: assetTypeId
    };
  }

  function enrich(item) {
    var pr = $q.defer();
    if (
      item.hasOwnProperty('domainAttribute') &&
      item.domainAttribute !== null
    ) {
      item.name = item.domainAttribute.name;
      item.description = item.domainAttribute.description;
      item.elementType = item.domainAttribute.elementType;
    }
    pr.resolve();
    return pr.promise;
  }
}

export default AssetTypeConfigController;
