module.exports = {
  entity: 'gis-user-group',
  path: 'gis-user-group',
  gisService: true,
  name: {
    en: 'Gis groups',
    sl_SI: 'Gis skupine'
  },
  networkModel: {
    entity: 'gis-user-group',
    read: 'read'
  },
  dialog: {
    title: {
      en: 'Select gis group',
      sl_SI: 'Izberite gis skupina'
    },
    listInfo: {
      en: 'Select gis group from the list below',
      sl_SI: 'Izberite gis skupino s spodnjega seznama'
    },
    gisService: true,
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      },
      {
        title: 'Title',
        field1: 'title',
        type: 'string'
      }
    ]
  }
}
