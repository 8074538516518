TimeSeriesTypeConfigController.$inject = [
  '$q',
  '$state',
  'gettext',
  'MetadataService',
  'TranslationService',
  'timeSeriesType',
  '$timeout'
];

function TimeSeriesTypeConfigController(
  $q,
  $state,
  gettext,
  MetadataService,
  TranslationService,
  timeSeriesType,
  $timeout
) {
  var vm = this;
  var timeSeriesTypeId = $state.params.id;

  init();

  function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    initAttributesTable();
    vm.headerData = constructHeader(timeSeriesType);
    $timeout(() => {
      MetadataService.ChangeMetadata(
        timeSeriesType.name,
        timeSeriesType.description
      );
    });
    vm.loading = false;
  }

  function constructHeader(timeSeriesType) {
    var systemTags = [];
    if (timeSeriesType.systemTags) {
      systemTags = timeSeriesType.systemTags.map(function(tag) {
        return tag.systemTag;
      });
    }
    var tags = [
      {
        title: gettext('Tags'),
        type: 'tags',
        paramName: 'name',
        values: systemTags
      }
    ];

    var properties = [
      {
        title: gettext('Code'),
        value: timeSeriesType.code,
        type: 'code'
      }
    ];

    if (timeSeriesType.externalCode) {
      properties.push({
        title: gettext('External code'),
        value: timeSeriesType.externalCode,
        type: 'code'
      });
    }

    if (timeSeriesType.readingType) {
      properties.push({
        title: gettext('Reading type'),
        value: TranslationService.GetCollectionById(
          'codelists.readingTypes',
          timeSeriesType.readingType
        ).name,
        type: 'simple'
      });
    }

    if (timeSeriesType.pairedTimeSeriesType) {
      properties.push({
        title: gettext('Paired time series type'),
        linkTitle: timeSeriesType.pairedTimeSeriesType.name,
        type: 'link',
        state:
          'configurations-company-resources-time-series-time-series-types-view',
        param: 'id',
        paramValue: timeSeriesType.pairedTimeSeriesType._id
      });
    }
    var propertySections = [
      {
        title: gettext('Properties'),
        properties: properties
      },
      {
        title: gettext('Tags'),
        properties: tags
      }
    ];
    var actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: timeSeriesTypeId,
        endpoint: {
          entity: 'time-series-types',
          method: 'delete'
        },
        redirectState: 'configurations-company-resources-time-series-list'
      },
      {
        title: gettext('Update'),
        state:
          'configurations-company-resources-time-series-time-series-types-edit',
        param: 'id',
        paramValue: timeSeriesTypeId
      }
    ];

    return {
      metadata: {
        definition: gettext('Time series type'),
        title: timeSeriesType.name,
        description: timeSeriesType.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }

  function initAttributesTable() {
    vm.listConfig = {
      entity: {
        plural: gettext('Time series type attributes')
      },
      api: {
        query: {
          entity: 'time-series-type-attributes',
          method: 'custom.list'
        },
        enrich: enrich
      },
      title: {
        param: 'name'
      },
      attributes: [
        {
          title: gettext('Description'),
          param: 'description'
        },
        {
          title: gettext('Element type'),
          param: 'elementType'
        }
      ]
    };

    vm.filters = {
      type: timeSeriesTypeId
    };

    vm.tableConfig = {
      toolbar: {
        title: gettext('Time series type attributes')
      },
      page: 1,
      limit: 5,
      order: 'name',
      selected: false,
      state: $state.current.name,
      listId: 'AttributesList',
      empty: gettext('There are no time series type attributes yet')
    };
    vm.headers = [
      {
        name: gettext('Name'),
        sort: 'name',
        text: true
      },
      {
        name: gettext('Description'),
        sort: 'description',
        text: true
      },
      {
        name: gettext('Element type'),
        sort: 'elementType',
        text: true
      },
      {
        name: gettext('Required'),
        sort: 'isRequired',
        twoOptions: true,
        iconTrue: 'check',
        colorTrue: 'green'
      }
    ];
  }

  function enrich(item) {
    var pr = $q.defer();
    if (
      item.hasOwnProperty('domainAttribute') &&
      item.domainAttribute !== null
    ) {
      item.name = item.domainAttribute.name;
      item.description = item.domainAttribute.description;
      item.elementType = item.domainAttribute.elementType;
    }
    pr.resolve();
    return pr.promise;
  }
}

export default TimeSeriesTypeConfigController;
