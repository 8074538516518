import text from './templates/text.template.html';
import textArea from './templates/text-area.template.html';
import divider from './templates/divider.template.html';
import space from './templates/space.template.html';
import autocomplete from './templates/autocomplete.template.html';
import tags from './templates/tags.template.html';
import checkbox from './templates/sfe-form-2-checkbox.template.html';
import radio from './templates/sfe-form-2-radio.template.html';
import colorPicker from './templates/sfe-form-2-color-picker.template.html';
import mathExpression from './templates/sfe-form-2-math-expression.template.html';
import date from './templates/date.template.html';
import agentConfigTag from './templates/agent-config-tag.template.html';
import title from './templates/sfe-form-2-title.template.html';
import paragraph from './templates/sfe-form-2-paragraph.template.html';
import ruleAlarm from './templates/rule-alarm.template.html';
import chartSettings from './templates/sfe-form-2-chart-settings.template.html';
import flowVariables from './templates/flow-variables.template.html';
import boundaries from './templates/sfe-form-2-boundaries.template.html';

export default function FormTemplates() {
  return {
    text,
    textArea,
    divider,
    autocomplete,
    tags,
    checkbox,
    radio,
    colorPicker,
    mathExpression,
    date,
    agentConfigTag,
    title,
    ruleAlarm,
    chartSettings,
    flowVariables,
    boundaries,
    space,
    paragraph
  };
}
