NewLocationFormController.$inject = [
  '$scope',
  '$state',
  'gettext',
  'createOrUpdateService',
  'AlertingService',
  'DateTimeDialogService',
  '$q',
  'utilService',
  'MetadataService',
  'SfeHeader',
  'LocationModel',
  'AddressModel',
  'CostCentreLocationModel',
  'BusinessPartnerLocationModel',
  'PictureLocationModel',
  'gettextCatalog',
  'EntityTagService',
  'location',
  '$timeout',
  'AddressHelperService'
];

function NewLocationFormController(
  $scope,
  $state,
  gettext,
  createOrUpdateService,
  AlertingService,
  DateTimeDialogService,
  $q,
  utilService,
  MetadataService,
  SfeHeader,
  LocationModel,
  AddressModel,
  CostCentreLocationModel,
  BusinessPartnerLocationModel,
  PictureLocationModel,
  gettextCatalog,
  EntityTagService,
  location,
  $timeout,
  AddressHelperService
) {
  const vm = this;
  const id = $state.params.id;
  let originalPictures;
  let duplicateMode;
  let editMode;
  let originalTags = [];

  let ownerWatcher = $scope.$watch('vm.dataConfig.dataObj.owner', function(
    owner
  ) {
    processWatcher(owner, 'T');
  });
  $scope.$on('$destroy', function() {
    if (ownerWatcher) {
      ownerWatcher();
    }
  });

  vm.$onInit = function init() {
    let headerTitle;
    let redirectState = 'company-resources-locations-view';
    let redirectParams = {
      id: id
    };
    switch ($state.current.name) {
    case 'company-resources-locations-duplicate':
      duplicateMode = true;
      headerTitle = gettext('Duplicate Location');
      break;
    case 'company-resources-locations-edit':
      headerTitle = gettext('Edit Location');
      editMode = true;
      break;
    case 'company-resources-locations-new':
      headerTitle = gettext('New Location');
      redirectState = 'company-resources-locations-list';
      redirectParams = {};
      break;
    }

    if (editMode || duplicateMode) {
      MetadataService.Loading(true);
    }

    vm.dataConfig = {
      header: SfeHeader.constructFormHeader(
        'primary',
        headerTitle,
        redirectState,
        redirectParams
      ),
      action: {
        ctrlFn: true,
        title: editMode
          ? duplicateMode
            ? gettext('Create')
            : gettext('Update')
          : gettext('Create'),
        fn: createOrUpdate
      }
    };
    InitForm();
  };

  function InitForm() {
    if (editMode || duplicateMode) {
      let pictures = [];
      constructFormConfiguration();
      if (location && location.pictureLocation) {
        location.pictureLocation.forEach(function(picture) {
          if (picture.picture && typeof picture.picture === 'object') {
            picture.duplicate = duplicateMode;
            picture.endpoint =
              utilService.getApiHost +
              '/pictures/' +
              picture.picture._id +
              '/image';
            picture.file = {
              type: 'image/gif',
              name: picture.picture.name
            };
            picture.pictureLocationId = picture._id;
            picture._id = picture.picture._id;
            pictures.push(picture);
          }
        });
      }

      originalPictures = angular.copy(pictures);
      // SYSTEM TAGS
      if (Array.isArray(location.tags)) {
        let item;
        location.systemTags = location.tags.map(function(tag) {
          item = tag.systemTag;
          item.bindingId = tag._id; //tag service uses binding id to update/delete tag
          return item;
        });
      }
      if (editMode) {
        // only save original tags if it's an edit; when duplicating,
        // all tags need to be created, so we leave 'originalTags' empty
        originalTags = angular.copy(location.systemTags);
      }

      if (location.parentId) {
        location.parent = location.parentId;
        location.parentId = location.parent._id;
      }
      vm.dataConfig.dataObj = {
        ...location,
        images: pictures
      };

      let address = {};
      if (location.address) {
        address = location.address;
        address.hasAddress = true;
      } else {
        address.hasAddress = false;
      }
      address.geolocationX = location.geoLocation
        ? location.geoLocation[0]
        : '';
      address.geolocationY = location.geoLocation
        ? location.geoLocation[1]
        : '';
      vm.dataConfig.dataObj.address = address;

      if (duplicateMode) {
        let waterfall = [
          async.apply(GetLocationCostCentre, location),
          getLocationOwner
        ];

        async.waterfall(waterfall, function(err, costCentre, ownerLocation) {
          vm.dataConfig.dataObj.owner = ownerLocation;
          vm.dataConfig.dataObj.mainCostCenter = costCentre || {};
        });
      }
      changeEnabled();
      $timeout(() => {
        MetadataService.Loading(false);
        MetadataService.ChangeMetadata(
          (editMode ? 'Edit ' : 'Duplicate ') + location.name,
          location.description
        );
      });
    } else {
      vm.dataConfig.dataObj = {
        name: '',
        description: '',
        geoLocation: '',
        owner: {},
        mainCostCenter: {},
        externalId: '',
        address: {
          hasAddress: true
        },
        images: [],
        systemTags: []
      };
      constructFormConfiguration();
    }
  }

  function constructFormConfiguration() {
    vm.dataConfig.data = [
      {
        placeholder: 'Name',
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: 'Description',
        name: 'description',
        componentType: 'textArea',
        type: 'text',
        maxlength: 500,
        required: false
      },
      {
        configuration: {
          query: {
            entity: 'location-types',
            method: 'read'
          },
          entity: 'location-types',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Location Type')
          },
          floatingLabel: gettext('Select Location Type'),
          searchParamName: 'filter',
          required: true,
          createRedirect: {
            state:
              'configurations-company-resources-locations-location-types-new'
          }
        },
        name: 'type',
        componentType: 'autocompleteDialog'
      },
      {
        configuration: {
          query: {
            entity: 'location-statuses',
            method: 'read'
          },
          entity: 'location-statuses',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Location Status')
          },
          required: true,
          createRedirect: {
            state: 'configurations-company-resources-locations-list'
          },
          floatingLabel: gettext('Select Location Status'),
          searchParamName: 'filter'
        },
        componentType: 'autocompleteDialog',
        name: 'status'
      },
      {
        configuration: {
          query: {
            entity: 'ownerships',
            method: 'read'
          },
          entity: 'ownerships',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Location Ownership')
          },
          floatingLabel: gettext('Select Location Ownership'),
          searchParamName: 'filter',
          required: true,
          createRedirect: {
            state: 'configurations-company-resources-ownerships-list'
          }
        },
        componentType: 'autocompleteDialog',
        name: 'ownership'
      },
      {
        componentType: 'autocompleteDialog',
        name: 'locationClassification',
        configuration: {
          query: {
            entity: 'location-classifications',
            method: 'read'
          },
          floatingLabel: gettext('Select location classification'),
          searchParamName: 'filter',
          required: false,
          entity: 'location-classifications',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select location classification'),
            filterObject: {
              order: 'name'
            }
          },
          createRedirect: {
            state: 'configurations-company-resources-locations-list'
          }
        }
      },
      {
        componentType: 'autocompleteDialog',
        name: 'weatherStation',
        configuration: {
          query: {
            entity: 'locations',
            method: 'read'
          },
          filterObject: {
            order: '_id',
            populate: 'type',
            'type.code': ['VRWT', 'VRWT2']
          },
          floatingLabel: gettext('Select weather station'),
          searchParamName: 'filter',
          required: false,
          entity: 'locations',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select weather station'),
            filterObject: {
              order: '_id',
              populate: 'type',
              'type.code': ['VRWT', 'VRWT2']
            }
          },
          createRedirect: {
            state: 'company-resources-locations-new'
          }
        }
      },
      {
        title: gettext('External properties'),
        label: gettext('External'),
        name: 'isExternal',
        componentType: 'checkBox'
      },
      {
        placeholder: gettext('External Code'),
        name: 'externalCode',
        componentType: 'textField',
        type: 'text',
        required: false
      }
    ];

    if (!editMode) {
      vm.dataConfig.data.push(
        {
          componentType: 'title',
          title: gettextCatalog.getString('Owner')
        },
        {
          componentType: 'elementSelector',
          entity: ['business-partners'],
          dialogConfigurations: [
            {
              multiple: false,
              title: gettext('Select owner'),
              filterObject: {
                order: 'name'
              }
            }
          ],
          name: 'owner',
          buttonTitle: gettext('+ Add Owner'),
          title: gettext('Select Owner'),
          validFrom: 'validFrom',
          validTo: 'validTo',
          addAction: openTimeDialog.bind({
            name: 'owner',
            title: gettext('Add owner to location')
          }),
          createRedirect: {
            state: 'company-resources-business-partners-new'
          },
          required: false
        }
      );
    }
    vm.dataConfig.data.push({
      name: 'parent',
      componentType: 'autocompleteDialog',
      edit: false,
      configuration: {
        entity: 'locations',
        dialogConfiguration: {
          multiple: false,
          title: gettext('Select parent Location')
        },
        createRedirect: {
          state: 'company-resources-locations-new'
        },
        query: {
          entity: 'locations',
          method: 'read'
        },
        floatingLabel: gettext('Select parent Location'),
        change: function() {
          if (
            !editMode &&
            vm.dataConfig.dataObj.parent &&
            vm.dataConfig.dataObj.parent.geoLocation
          ) {
            if (verifyChange() && !duplicateMode) {
              setAddress(vm.dataConfig.dataObj.parent);
            }
          }
        },
        selectedParam: 'location',
        searchParamName: 'filter',
        required: false
      }
    });

    if (!editMode) {
      vm.dataConfig.data.push(
        {
          componentType: 'title',
          title: gettextCatalog.getString('Cost centre')
        },
        {
          componentType: 'elementSelector',
          dialogConfigurations: [
            {
              multiple: false,
              filterObject: {
                order: 'name'
              }
            }
          ],
          entity: ['cost-centres'],
          buttonTitle: gettext('+ Add Cost centre'),
          title: gettext('Select a cost centre'),
          addAction: openTimeDialog.bind({
            name: 'mainCostCenter',
            title: gettext('Add main cost centre to location')
          }),
          validFrom: 'validFrom',
          validTo: 'validTo',
          name: 'mainCostCenter',
          createRedirect: {
            state: 'company-resources-cost-centres-new'
          },
          required: false
        }
      );
    }

    vm.dataConfig.data.push(
      {
        componentType: 'checkBoxesLinear',
        networkModel: {
          entity: 'system-tags',
          method: 'read'
        },
        name: 'systemTags',
        title: gettext('System Tags'),
        compareFn: function(item1, item2) {
          return item1._id === item2._id;
        }
      },
      {
        componentType: 'address',
        name: 'address',
        edit: editMode || duplicateMode
      },
      {
        componentType: 'fileUpload',
        name: 'images',
        fileUploader: 'fileUploader',
        endpoint: utilService.getApiHost + '/pictures',
        alias: 'file',
        queueLimit: 10,
        filters: [
          {
            name: 'imageFilter',
            fn: function(item) {
              let type =
                '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
              return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
            }
          }
        ]
      }
    );
  }

  function processWatcher(obj, type) {
    if (
      !obj ||
      !obj.geoLocation ||
      obj.geoLocation.length !== 2 ||
      !vm.dataConfig.dataObj.ownership ||
      !vm.dataConfig.dataObj.ownership._id ||
      !vm.dataConfig.dataObj.address.hasAddress ||
      !verifyChange()
    ) {
      return;
    }

    if (vm.dataConfig.dataObj.ownership.code === type) {
      setAddress(obj);
    }
  }

  function setAddress(obj) {
    AddressModel.read({
      id: obj.address
    }).then(function(res) {
      Object.keys(res.data).forEach(function(key) {
        if (key !== '_id' && key !== '__v' && key !== 'uniqueHash') {
          vm.dataConfig.dataObj.address[key] = res.data[key];
        }
      });
      vm.dataConfig.dataObj.address.geolocationX = obj.geoLocation[0];
      vm.dataConfig.dataObj.address.geolocationY = obj.geoLocation[1];
    });
  }

  function verifyChange() {
    for (let key in Object.keys(vm.dataConfig.dataObj.address)) {
      if (
        key !== 'geolocationX' &&
        key !== 'geolocationY' &&
        key !== 'hasAddress' &&
        vm.dataConfig.dataObj.address[key] !== undefined &&
        vm.dataConfig.dataObj.address[key] !== null
      ) {
        return false;
      }
    }
    return true;
  }

  function GetLocationCostCentre(location, callback) {
    let obj = {
      location: location._id,
      isActive: true,
      isMain: true
    };

    CostCentreLocationModel.custom.readWithCostCenter(obj).then(
      function(res) {
        if (res.data.length > 0) {
          let costCentre = res.data[0].costCentre;

          costCentre.validFrom = res.data[0].validFrom;
          costCentre.validTo = res.data[0].validTo;
          callback(null, location, costCentre);
        } else {
          callback(null, location, null);
        }
      },
      function() {
        callback(null, location, null);
      }
    );
  }

  function getLocationOwner(location, costCentre, callback) {
    BusinessPartnerLocationModel.custom
      .read({
        location: id,
        isMain: true,
        isActive: true
      })
      .then(
        function(res) {
          let businessPartner = null;
          if (res.data && res.data.length > 0) {
            businessPartner = res.data[0].businessPartner;
            businessPartner.validFrom = res.data[0].validFrom;
            businessPartner.validTo = res.data[0].validTo;
          }
          callback(null, costCentre, businessPartner);
        },
        function() {
          callback(null, costCentre, null);
        }
      );
  }

  function openTimeDialog(object) {
    let itemName = this.name;
    DateTimeDialogService.openDialog({
      title: this.title,
      dateOnly: true,
      initialValues: object || vm.dataConfig.dataObj[itemName],
      readDateFromLocalStorage: true
    }).then(function(item) {
      if (!item) {
        vm.dataConfig.dataObj[itemName] = {};
      } else {
        vm.dataConfig.dataObj[itemName].validTo = item.validTo;
        vm.dataConfig.dataObj[itemName].validFrom = item.validFrom;
      }
    });
  }

  function changeEnabled() {
    vm.dataConfig.data.forEach(function(config) {
      switch (config.name) {
      case 'name':
      case 'address':
        config.disabled = editMode && vm.dataConfig.dataObj.isExternal;
        break;
      case 'ownership':
        config.disabled = editMode && vm.dataConfig.dataObj.isExternal;
        break;
      default:
        config.disabled = false;
      }
    });
  }

  // create and update functions
  function createOrUpdate() {
    vm.sendingRequest = true;
    let waterfall;
    let message;
    if (editMode && !duplicateMode) {
      waterfall = [
        checkIfAddressExists,
        updateAddress,
        updateLocation,
        createLocationCostCentre,
        createLocationOwner,
        updatePictureLocation,
        createEntityTag
      ];
      message = 'update';
    } else {
      waterfall = [
        checkIfAddressExists,
        createAddress,
        createLocation,
        createLocationCostCentre,
        createLocationOwner,
        uploadPicture,
        createPictureLocation,
        createEntityTag
      ];
      message = 'create';
    }
    createOrUpdateService
      .simpleWaterfall(
        waterfall,
        message,
        'company-resources-locations-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          AlertingService.Error(err);
          vm.sendingRequest = false;
        }
      );
  }

  async function createEntityTag(location, callback) {
    await EntityTagService.createSystemTags(
      vm.dataConfig.dataObj.systemTags,
      originalTags,
      3,
      location._id,
      false
    );
    callback(null, location);
  }

  async function checkIfAddressExists(callback) {
    let addressObj;
    if (vm.dataConfig.dataObj.address.hasAddress) {
      addressObj = createAddressAPIObject();
    }
    try {
      const address = await AddressHelperService.checkIfAddressExists(
        addressObj
      );
      if (address != null) {
        callback(null, address._id);
      } else {
        callback(null, null);
      }
    } catch (err) {
      callback(true);
    }
  }

  function createAddress(addressId, callback) {
    if (!addressId && vm.dataConfig.dataObj.address.hasAddress) {
      let obj = createAddressAPIObject();
      AddressModel.create(obj).then(
        function(res) {
          callback(null, res.data._id);
        },
        function(err) {
          AlertingService.Error(err);
          callback(true);
        }
      );
    } else if (vm.dataConfig.dataObj.address.hasAddress) {
      callback(null, addressId);
    } else {
      callback(null, null);
    }
  }

  function createLocation(address, callback) {
    let obj = createLocationAPIObject(address);
    LocationModel.create(obj).then(
      function(res) {
        callback(null, res.data);
      },
      function(err) {
        callback(true, null);
        AlertingService.Error(err);
      }
    );
  }

  function createLocationCostCentre(location, callback) {
    if (
      vm.dataConfig.dataObj.mainCostCenter &&
      vm.dataConfig.dataObj.mainCostCenter._id
    ) {
      let obj = {
        costCentre: vm.dataConfig.dataObj.mainCostCenter._id,
        location: location._id,
        validFrom: vm.dataConfig.dataObj.mainCostCenter.validFrom,
        validTo: vm.dataConfig.dataObj.mainCostCenter.validTo,
        isMain: true
      };
      CostCentreLocationModel.create(obj).then(
        function() {
          callback(null, location);
        },
        function(err) {
          AlertingService.Error(err);
          callback(null, location);
        }
      );
    } else {
      callback(null, location);
    }
  }

  function createLocationOwner(location, callback) {
    if (vm.dataConfig.dataObj.owner && vm.dataConfig.dataObj.owner._id) {
      let obj = {
        businessPartner: vm.dataConfig.dataObj.owner._id,
        location: location._id,
        validFrom: vm.dataConfig.dataObj.owner.validFrom,
        validTo: vm.dataConfig.dataObj.owner.validTo,
        isMain: true
      };
      BusinessPartnerLocationModel.create(obj).then(
        function() {
          callback(null, location);
        },
        function(err) {
          AlertingService.Error(err);
          callback(null, location);
        }
      );
    } else {
      callback(null, location);
    }
  }

  function uploadPicture(location, callback) {
    let fileUploader = vm.dataConfig.dataObj.fileUploader;
    let images = [];
    if (!fileUploader.queue.length) {
      callback(null, location, []);
      return;
    }
    fileUploader.onCompleteItem = function(item, response) {
      if (response.data) {
        images.push(response.data._id);
      } else if (response.error) {
        AlertingService.Error(response.error);
      }
    };
    fileUploader.onCompleteAll = function() {
      callback(null, location, images);
    };
    fileUploader.uploadAll();
  }

  function createPictureLocation(location, images, callback) {
    let promises = [];
    let apiObj;
    images.forEach(function(item) {
      if (!item.deleted) {
        apiObj = {
          picture: item,
          location: location._id
        };
        promises.push(PictureLocationModel.create(apiObj));
      }
    });

    if (duplicateMode) {
      vm.dataConfig.dataObj.images.forEach(function(image) {
        if (image._id) {
          apiObj = {
            picture: image._id,
            location: location._id
          };
          promises.push(PictureLocationModel.create(apiObj));
        }
      });
    }

    $q.all(promises).then(
      function() {
        callback(null, location);
      },
      function(err) {
        AlertingService.Error(err);
        callback(null, location);
      }
    );
  }

  function updateAddress(addressId, callback) {
    let obj;
    if (addressId && vm.dataConfig.dataObj.address.hasAddress) {
      callback(null, addressId);
    } else if (vm.dataConfig.dataObj.address.hasAddress) {
      obj = createAddressAPIObject();
      AddressModel.create(obj).then(
        function(res) {
          callback(null, res.data._id);
        },
        function(err) {
          AlertingService.Error(err);
          callback(true);
        }
      );
    } else {
      callback(null, null);
    }
  }

  function updateLocation(address, callback) {
    let obj = createLocationAPIObject(address);
    // return
    LocationModel.update(
      {
        id: id
      },
      obj
    ).then(
      function(res) {
        callback(null, res.data);
      },
      function(err) {
        callback(true, null);
        AlertingService.Error(err);
      }
    );
  }

  function updatePictureLocation(location, callback) {
    let obj = vm.dataConfig.dataObj;
    let promises = [];
    uploadPicture(null, function(err, address, images) {
      _.each(images, function(image) {
        promises.push(
          PictureLocationModel.create({
            picture: image,
            location: location._id
          })
        );
      });
      let deletedImages = _.differenceBy(originalPictures, obj.images, '_id');
      _.each(deletedImages, function(image) {
        promises.push(
          PictureLocationModel.delete({
            id: image.pictureLocationId
          })
        );
      });

      if (promises.length > 0) {
        $q.all(promises).then(
          function() {
            callback(null, location);
          },
          function() {
            callback(null, location);
          }
        );
      } else {
        callback(null, location);
      }
    });
  }

  function createAddressAPIObject() {
    return {
      country: vm.dataConfig.dataObj.address.country
        ? vm.dataConfig.dataObj.address.country.id
        : null,
      externalId: vm.dataConfig.dataObj.address.externalId,
      communityCode: vm.dataConfig.dataObj.address.communityCode,
      communityName: vm.dataConfig.dataObj.address.communityName,
      settlementCode: vm.dataConfig.dataObj.address.settlementCode,
      settlementName: vm.dataConfig.dataObj.address.settlementName,
      streetCode: vm.dataConfig.dataObj.address.streetCode,
      streetName: vm.dataConfig.dataObj.address.streetName,
      houseNumber: vm.dataConfig.dataObj.address.houseNumber,
      postalNumber: vm.dataConfig.dataObj.address.postalNumber,
      postName: vm.dataConfig.dataObj.address.postName
    };
  }

  function createLocationAPIObject(address) {
    const geoAddress = vm.dataConfig.dataObj.address;
    let geoLocation = null;
    if (
      typeof geoAddress.geolocationX == 'number' &&
      typeof geoAddress.geolocationY == 'number'
    ) {
      geoLocation = [geoAddress.geolocationX, geoAddress.geolocationY];
    }
    return {
      name: vm.dataConfig.dataObj.name,
      description: vm.dataConfig.dataObj.description,
      type: vm.dataConfig.dataObj.type._id,
      parentId:
        vm.dataConfig.dataObj.parent && vm.dataConfig.dataObj.parent._id
          ? vm.dataConfig.dataObj.parent._id
          : null,
      ownership: vm.dataConfig.dataObj.ownership._id,
      isExternal: vm.dataConfig.dataObj.isExternal,
      status: vm.dataConfig.dataObj.status._id,
      address: address,
      geoLocation,
      locationClassification: vm.dataConfig.dataObj.locationClassification
        ? vm.dataConfig.dataObj.locationClassification._id
        : null,
      weatherStation: vm.dataConfig.dataObj.weatherStation
        ? vm.dataConfig.dataObj.weatherStation._id
        : null,
      externalCode: vm.dataConfig.dataObj.externalCode
        ? vm.dataConfig.dataObj.externalCode
        : null
    };
  }
}
export default NewLocationFormController;
