UserConfiguration.$inject = [
  'gettext',
  '$q',
  'SfeFormDialogService',
  'AlertingService',
  'CrudToastFactory',
  '$window',
  'StandardUtils',
  'UserModel',
  'Formatting'
];

function UserConfiguration(
  gettext,
  $q,
  SfeFormDialogService,
  AlertingService,
  CrudToastFactory,
  $window,
  StandardUtils,
  UserModel,
  Formatting
) {
  var configuration = {
    entity: {
      singular: gettext('User'),
      plural: gettext('Users')
    },
    api: {
      query: {
        entity: 'users',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      imgUrlParam: 'imageUrl',
      fallbackUrl: './img/profile/nopic_192.gif',
      gravatarParam: '_id',
      link: {
        state: 'users-users-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        fn: addEmail,
        relist: true,
        title: gettext('Add User'),
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'users-users-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      }
    ],
    filter: [
      {
        display: gettext('Name or last name'),
        param: 'full_name',
        type: 'string'
      },
      {
        display: gettext('Email'),
        param: 'email',
        type: 'string'
      },
      {
        display: gettext('Phone Number'),
        param: 'phone_number',
        type: 'string'
      }
    ],
    attributes: [
      {
        param: 'username',
        title: gettext('Username'),
        type: 'raw'
      },
      {
        param: 'email',
        title: gettext('E-mail'),
        type: 'email'
      },
      {
        param: 'phone_number',
        title: gettext('Phone'),
        type: 'raw'
      }
    ],
    sort: [
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'name DESC',
        params: '-name'
      }
    ]
  };

  function addEmail() {
    var config = [
      {
        componentType: 'chipList',
        readonly: false,
        removable: true,
        name: 'emails',
        placeholder: gettext('Enter email and press enter or tab'),
        pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        patternExample: 'example@example.com'
      }
    ];
    var createObject = {
      emails: []
    };
    var title = gettext('Add new user emails');
    SfeFormDialogService.openSfeFormDialog(false, config, createObject, title)
      .then(function(obj) {
        if (obj.emails && obj.emails.length) {
          UserModel.custom
            .addUser({
              email: obj.emails,
              link: $window.location.origin
            })
            .then(
              function() {
                CrudToastFactory.toast('create');
              },
              function(err) {
                AlertingService.Error(err);
              }
            );
        }
      })
      .catch(angular.noop);
  }

  function Enrich(item) {
    if (item.family_name) {
      item.name = item.name + ' ' + item.family_name;
    }
    if (item.phone_number != null) {
      item.phone_number = Formatting.formatPhoneNumber(
        item.phone_number
      ).toString();
    }
    var deferred = $q.defer();
    item.imageUrl = StandardUtils.getUserImageUrl(item, 'sm');
    deferred.resolve();
    return deferred.promise;
  }
  return configuration;
}
export default UserConfiguration;
