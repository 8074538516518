/**
 * @ngdoc service
 * @name common.DisplayFields
 * @description Used for showing a toast message.
 * @property {function} getString - returns string constructed out of item object and display configuration
 */
DisplayFields.$inject = ['$filter'];

export default function DisplayFields($filter) {
  /**
   * @memberof common.DisplayFields
   * @function
   * @description returns string constructed out of item object and display configuration.
   * @param {Object} item item we want to display.
   * @param {Array} fields Array of item fields we want to display
   * @return {String}
   */
  function getString(item, fields) {
    if (typeof item != 'object' || !Array.isArray(fields)) {
      return '';
    }
    let value;
    return fields.reduce((displayString, displayField, index) => {
      value = getValue(item, displayField);
      if (typeof displayField == 'object' && displayField != null) {
        switch (displayField.type) {
        case 'date':
          if (new Date(value) != 'Invalid Date') {
            value = $filter('date')(new Date(value), 'dd.MM.yyyy');
          }
          break;
        }
      }
      if (index && displayString && value) {
        displayString += ' - ';
      }
      displayString += value;
      return displayString;
    }, '');
  }

  function getValue(item, displayField) {
    let value = '';
    if (typeof displayField == 'object' && displayField != null) {
      if (displayField.field1 && displayField.field2) {
        if (
          item[displayField.field1] &&
          item[displayField.field1][displayField.field2]
        ) {
          value += item[displayField.field1][displayField.field2];
        }
      } else if (displayField.field1) {
        value += item[displayField.field1] ? item[displayField.field1] : '';
      }
    } else {
      value += item[displayField] ? item[displayField] : '';
    }
    return value;
  }

  return {
    getString: getString
  };
}
