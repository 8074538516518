MeasurementUnitTangoProperties.$inject = [
  'TranslationService',
  'ValidateObjectConfiguration',
  'gettextCatalog'
];

export default function MeasurementUnitTangoProperties(
  TranslationService,
  ValidateObjectConfiguration,
  gettextCatalog
) {
  const validationConfiguration = [
    {
      fieldName: '_id',
      type: 'string',
      required: true
    },
    {
      fieldName: 'name',
      type: 'string',
      required: true
    },
    {
      fieldName: 'symbol',
      type: 'string',
      required: true
    }
  ];
  /**
   * @description returns sfe-property values.
   * @function
   * @param {Object} ref {item, key, options}
   * item that contains under the key measurement unit object
   *  options <object> {title, metricPrefixKey}
   *   title <string>
   *   metricPrefixKey <string>
   * @return {Array}
   */
  function basic({ item, key, options }) {
    const { metricPrefixKey, title } = options;
    const measurementUnitValidation = ValidateObjectConfiguration.validate(
      item[key],
      validationConfiguration
    );
    let values = [{}];
    if (measurementUnitValidation.validity) {
      let metricPrefix;
      if (typeof item[metricPrefixKey] == 'number') {
        metricPrefix = TranslationService.GetCollectionById(
          'codelists.metricPrefixes',
          item[metricPrefixKey]
        );
        let metricPrefixSymbol = '';
        let metricPrefixName = '';
        if (metricPrefix != null) {
          metricPrefixSymbol = metricPrefix.symbol;
          metricPrefixName = metricPrefix.name;
        }
        values = [
          {
            text: `${metricPrefixName} ${item.measurementUnit.name} [${metricPrefixSymbol}${item.measurementUnit.symbol}]`
          }
        ];
      }
    }

    return {
      type: 'basic',
      title: title || gettextCatalog.getString('Measurement unit'),
      values
    };
  }
  return {
    basic
  };
}
