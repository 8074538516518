module.exports = {
  entity: 'cusums',
  path: 'cusums',
  name: {
    en: 'Cusums',
    sl_SI: 'Cusum'
  },
  networkModel: {
    entity: 'cusums',
    read: 'read'
  },
  scope: 'tis_be:cusums',

  dialog: {
    title: {
      en: 'Select cusum',
      sl_SI: 'Izberite cusum'
    },
    listInfo: {
      en: 'Select cusum from the list below',
      sl_SI: 'Izberite cusum s spodnjega seznama'
    }
  }
}
