import './sfe-item-header.scss';
import template from './sfe-item-header.component.html';
import userAccessDialogTemplate from '../../views/userAccess.dialog.html';
/**
 * @ngdoc component
 * @name common.sfeItemHeader
 * @description Component that is used to show basic data on item views.
 * @param {object} data - Data used in constructing the header.
 * @param {Boolean} collapsible - Tells us if we can you hide/collapse the data in the sfe-item-component.
 * @param {Boolean} loading - Tells us, if the data for the item view is still loading
 * @param {Boolean} reloadingRows - Set to true when you want to reset data in header
 * @example
 * <sfe-item-header
 * data="vm.headerData"
 * collapsible="false"
 * loading="vm.loading"
 * reloading-rows="vm.reloadingRows"
 * ></sfe-item-header>
 */

export default {
  template,
  restrict: 'E',
  bindings: {
    data: '<',
    collapsible: '<',
    loading: '<?',
    reloadingRows: '<'
  },
  controller: sfeItemHeaderController,
  controllerAs: 'vm',
  bindToController: true
};

sfeItemHeaderController.$inject = [
  'EntitiesService',
  'AuthorizationListPermittedModel',
  'CrawlerMethods',
  'gettext',
  'CrudToastFactory',
  'AlertingService',
  'InfoDialog',
  '$mdDialog',
  '$scope',
  'EntityTagModel',
  'AuthorizationToEditService',
  'SfeItemHeaderTemplates'
];

function sfeItemHeaderController(
  EntitiesService,
  AuthorizationListPermittedModel,
  CrawlerMethods,
  gettext,
  CrudToastFactory,
  AlertingService,
  InfoDialog,
  $mdDialog,
  $scope,
  EntityTagModel,
  AuthorizationToEditService,
  SfeItemHeaderTemplates
) {
  const vm = this;
  vm.usersBool = null;
  vm.more = more;
  vm.deleteItem = deleteItem;
  vm.listUsers = listUsers;
  vm.returnStateParamObject = returnStateParamObject;
  vm.closeOpenProperties = closeOpenProperties;

  let fetchingUserAccess;
  /**
   * @description function that opens a dialog either for sms receiver, user permissions or group permissions
   * @function
   * @param {boolean} users - boolean that tells us, if we are opening a user or group permission dialog
   * @param {boolean} sms - boolean that tells us, if we are opening sms receivers dialog.
   */
  function more(users, sms) {
    vm.usersBool = users;
    vm.dialogSms = sms;
    const title = sms
      ? gettext('Sms receivers')
      : users
        ? gettext('User access')
        : gettext('Group access');

    vm.header = {
      title: title,
      isDialog: true,
      actions: [
        {
          icon: {
            name: 'close',
            type: 2
          },
          fn: () => {
            $mdDialog.cancel();
          }
        }
      ]
    };

    $mdDialog
      .show({
        controller: MoreController,
        template: userAccessDialogTemplate,
        parent: angular.element(document.body),
        scope: $scope,
        preserveScope: true,
        clickOutsideToClose: true
      })
      .finally(() => {
        if (!vm.dialogSms) {
          vm.listUsers();
        }
      })
      .catch(angular.noop); // if dialog is closed
  }
  /**
   * @description assigns a function for closing dialogs to vm.closeDialog
   * @function
   */
  function MoreController() {
    vm.closeDialog = () => {
      $mdDialog.cancel();
    };
  }

  /**
   * @description function for opening and closing a collapsible header.
   * @function
   */
  function closeOpenProperties() {
    vm.collapsible.show = !vm.collapsible.show;
  }

  /**
   * @description functions that transform an object containing an state parameter names and state parameter values
   * and return an object where property names are state parameter names and values are state parameter values
   * @function
   * @param {object} property - object containing state parameter names and values.
   * @return {Object} parameterObject - an object where state parameter names are object properties and state parameter values are property values.
   */
  function returnStateParamObject(property) {
    let parameterObject = property.paramObject || {};

    if (property.param && property.paramValue) {
      parameterObject[property.param] = property.paramValue;
    }
    return parameterObject;
  }
  /**
   * @description functions that is called when you want to delete the item to which the sfe-iteam header belongs to.
   * @function
   */
  function deleteItem() {
    const endpoint = this.endpoint;
    const method = CrawlerMethods.getMethod(endpoint);

    const redirectState = this.redirectState;
    const paramName = this.paramName;
    const paramValue = this.paramValue;
    const id = this.id;
    const title = gettext('Confirmation');
    const textItem = {
      text: gettext('Are you sure you want to delete this item?'),
      type: 'text'
    };

    const actions = [
      {
        title: gettext('Cancel'),
        cancel: true,
        color: 'primary'
      },
      {
        title: gettext('Delete'),
        fn: Delete,
        color: 'warn'
      }
    ];

    async function Delete() {
      $mdDialog.cancel();
      vm.loading = true;
      try {
        await method({
          id
        });
        let redirectObj = {
          state: redirectState
        };
        if (paramName && paramValue) {
          redirectObj.paramName = paramName;
          redirectObj.paramValue = paramValue;
        }
        CrudToastFactory.toast('delete', redirectObj);
      } catch (err) {
        AlertingService.Error(err);
      }
      vm.loading = false;
      $scope.$applyAsync();
    }

    InfoDialog.open(title, null, [textItem], actions);
  }
  vm.$onChanges = changes => {
    if (vm.data && changes.data) {
      prepareData();
    }
  };
  /**
   * @description Function that prepares data that is to be shown in the header.
   * @function
   */
  function prepareData() {
    if (vm.data) {
      let linkText;
      const isAuthorizedToEdit = AuthorizationToEditService.isAuthorizedToEdit();

      if (vm.data.propertySections) {
        // Create ui-sref strings
        let indexToRemove;
        vm.data.propertySections.forEach((section, index) => {
          if (Array.isArray(section.properties)) {
            section.properties.forEach(property => {
              // set up link values
              if (property.type === 'link') {
                linkText = property.state || 'home';
                if (property.param && property.paramValue) {
                  linkText += `({${property.param}: ${property.paramValue}})`;
                }
                if (property.paramObject) {
                  linkText += `(${JSON.stringify(property.paramObject)})`;
                }
                property.linkText = linkText;
              }

              // configure tags if their data needs to be fetched
              if (property.type === 'tags' && property.fetchData) {
                getTags(property.values);
              }
              property.template = prepareTemplates(property);
            });
          }

          if (section.type === 'userAccess') {
            // if user is authorized to edit, display the authorized personnel section, otherwise remove it from the array
            if (isAuthorizedToEdit) {
              vm.identifier = section.identifier;
              vm.target = section.target;
              if (!fetchingUserAccess && vm.identifier && vm.target) {
                fetchingUserAccess = true;
                listUsers(section);
              }
            } else {
              indexToRemove = index;
            }
          }
        });
        if (indexToRemove != null) {
          vm.data.propertySections.splice(indexToRemove, 1);
        }

        // SORT into odd and even columns
        const propertySections = vm.data.propertySections.reduce(
          (obj, property, index) => {
            if (index % 2 === 0) {
              obj.propertySectionsOdd = [...obj.propertySectionsOdd, property];
            } else {
              obj.propertySectionsEven = [
                ...obj.propertySectionsEven,
                property
              ];
            }
            return obj;
          },
          {
            propertySectionsOdd: [],
            propertySectionsEven: []
          }
        );
        vm.data = {
          ...vm.data,
          sortedProperties: [
            propertySections.propertySectionsOdd,
            propertySections.propertySectionsEven
          ]
        };
      }

      // configure action buttons link
      if (vm.data.actions) {
        vm.actions = vm.data.actions.map(action => {
          if (action.state) {
            linkText = action.state;
            if (action.param && action.paramValue) {
              action.color = 'success';
              linkText +=
                '({"' +
                action.param +
                '":"' +
                action.paramValue.toString() +
                '"})';
            }
            action.link = linkText;
          }
          if (action.delete) {
            action.fn = deleteItem.bind({
              endpoint: action.endpoint,
              redirectState: action.redirectState,
              paramName: action.paramName,
              paramValue: action.paramValue,
              id: action.id
            });
            action.color = 'warn';
          }
          return action;
        });
      }
    }
  }
  /**
   * @description function that calls all the dags that should be shown on the component.
   * @function
   * @param {array} tags - array of tag ids
   * @return {promise} - a promise array for all the tags
   */
  async function getTags(tags) {
    vm.loadingTags = true;
    const promises = tags.map(tag => EntityTagModel.read({ id: tag }));
    try {
      const res = await Promise.all(promises);
      // find the section with the tag property and assign new values to the tag property
      vm.data.propertySections.find(section => {
        let tagProperty = section.properties.find(property => property.tags);
        if (tagProperty) {
          tagProperty.values = res.map(tag => tag.data);
          return section;
        }
      });
      vm.loadingTags = false;
    } catch (err) {
      AlertingService.Error(err);
    }
  }
  /**
   * @description function that lists users. Mostly used when viewing user permissions
   * @function
   * @param {Object} section - section of the header the listed users are in.
   */
  async function listUsers(section) {
    let target = vm.target;
    vm.instanceScope = EntitiesService.getScope(vm.identifier);
    try {
      const { data } = await AuthorizationListPermittedModel.read({
        scope: vm.instanceScope,
        instance: target
      });
      let users = [];
      let groups = [];
      users = data.filter(scopeObj =>
        scopeObj.owner ? scopeObj.owner.user : ''
      );
      groups = data.filter(scopeObj =>
        scopeObj.owner ? scopeObj.owner.group : ''
      );
      vm.users = [];
      vm.usersNum = users.length;
      vm.groups = [];
      vm.groupsNum = groups.length;
      for (let i = 0; i < 2 && i < groups.length; i++) {
        vm.groups.push(groups[i]);
      }
      for (let i = 0; i < 2 && i < users.length; i++) {
        vm.users.push(users[i]);
      }
      vm.authorizerEditPermissions = true;
    } catch (err) {
      // AlertingService.Error(err);
      if (err.status === 403) {
        if (section) {
          section.hideSection = true;
        }
      }
    }
    $scope.$evalAsync();
  }

  function prepareTemplates(headerItem) {
    if (headerItem.type && SfeItemHeaderTemplates[headerItem.type] != null) {
      return headerItem.type + 'Header';
    } else {
      return 'templateError';
    }
  }
}
