chartTranslationService.$inject = ['gettextCatalog'];

/**
 * @ngdoc service
 * @name common.createOrUpdateService
 * @description service that contains translations for charts
 * @property {function} simpleCall - See method simpleCall.
 * @property {function} simpleWaterfall - See method simpleWaterfall.
 */
export default function chartTranslationService(gettextCatalog) {
  /**
   * @memberof common.chartTranslationService
   * @return {promise} returns an object containing translated strings
   */
  return function() {
    return {
      //chart tools modal
      chartSettings: gettextCatalog.getString('Chart settings'),
      color: gettextCatalog.getString('Color'),
      type: gettextCatalog.getString('Type'),
      reduceMethod: gettextCatalog.getString('Reduce method'),
      //filter modal
      allSeries: gettextCatalog.getString('All series'),
      chartFilters: gettextCatalog.getString('Chart filters'),
      selectedSeries: gettextCatalog.getString('Selected series'),
      timeIntervals: gettextCatalog.getString('Time intervals'),
      timeInterval: gettextCatalog.getString('Time Interval'),
      limit: gettextCatalog.getString('Limit'),
      from: gettextCatalog.getString('From'),
      to: gettextCatalog.getString('To'),
      //sfe-chart-table
      rowsPerPage: gettextCatalog.getString('Rows per page'),
      perPage: gettextCatalog.getString('per page'),
      //sfe-chart
      noValueToDisplay: gettextCatalog.getString('No value to display.'),
      //shared
      ok: gettextCatalog.getString('ok'),
      clearFilters: gettextCatalog.getString('Clear filters'),
      noDataToDisplay: gettextCatalog.getString('No data to display.'),
      timeIntervalsList: {
        hour: gettextCatalog.getString('Last hour'),
        day: gettextCatalog.getString('Last day'),
        week: gettextCatalog.getString('Last week'),
        month: gettextCatalog.getString('Last month'),
        year: gettextCatalog.getString('Last year')
      }
    };
  };
}
