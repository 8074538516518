AlarmMonitoringConfiguration.$inject = [
  'gettext',
  '$q',
  'gettextCatalog',
  'TranslationService',
  'TriggeredAlarmModel'
];

function AlarmMonitoringConfiguration(
  gettext,
  $q,
  gettextCatalog,
  TranslationService,
  TriggeredAlarmModel
) {
  var configuration = {
    listId: 'alarmMonitoringConfiguration',
    entity: {
      singular: gettext('Triggered Alarms'),
      plural: gettext('Triggered Alarms')
    },
    api: {
      query: {
        entity: 'triggered-alarms',
        method: 'custom.readList'
      },
      enrich: Enrich
    },
    title: {
      param: 'monitoringTitle',
      link: {
        state: 'monitoring-triggered-alarms-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    shortActions: [],
    attributes: [
      {
        title: gettext('Alarm'),
        param: 'alarmName',
        link: {
          state: 'alarms-and-rules-alarm-definitions-view',
          params: [
            {
              valueParam: 'alarmId',
              queryParam: 'id'
            }
          ]
        },
        type: 'link'
      },
      {
        title: gettext('External code'),
        param: 'externalCode',
        type: 'text'
      },
      {
        title: gettext('Current assignee'),
        param: 'currentAssigneeName',
        link: {
          state: 'users-users-view',
          params: [
            {
              valueParam: 'currentAssignee',
              queryParam: 'id'
            }
          ]
        },
        type: 'link'
      },
      {
        title: gettext('Status'),
        param: 'statusName',
        type: 'text'
      }
    ],
    badges: [
      {
        title: gettext('Severity'),
        param: 'severityName',
        colors: 'true',
        type: 'coloredText'
      },
      {
        title: gettext('Triggered'),
        param: 'triggeredTimestamp',
        type: 'date'
      }
    ],
    filter: [
      {
        display: gettext('External Code'),
        param: 'externalCode',
        type: 'string'
      },
      {
        display: gettext('Severity'),
        query: {
          entity: 'severities',
          method: 'read'
        },
        entity: 'severities',
        param: 'alarm.severity',
        populate: 'alarm',
        selectedParamName: '_id'
      },
      {
        display: gettext('Time series'),
        query: {
          entity: 'time-series',
          method: 'read'
        },
        refreshObject: {
          populate: 'dataScheduler'
        },
        entity: 'time-series',
        param: 'timeSeries',
        uniqId: 'timeSeries',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        collection: TranslationService.GetCollection('codelists.alarmStatuses'),
        display: gettext('Status'),
        displayList: ['name'],
        param: 'alarmStatus',
        valueField: 'id'
      },
      {
        display: gettext('Current Assignee'),
        query: {
          entity: 'users',
          method: 'read'
        },
        entity: 'users',
        displayList: [
          {
            combined: true,
            field1: 'name',
            field2: 'family_name',
            type: 'string'
          }
        ],
        param: 'currentAssignee',
        type: 'searchSelect',
        refreshParameterName: 'full_name',
        selectedParamName: '_id'
      },
      {
        display: gettext('Responsible person'),
        query: {
          entity: 'users',
          method: 'read'
        },
        entity: 'users',
        displayList: [
          {
            combined: true,
            field1: 'name',
            field2: 'family_name',
            type: 'string'
          }
        ],
        param: 'responsiblePerson',
        type: 'searchSelect',
        refreshParameterName: 'full_name',
        selectedParamName: '_id'
      },
      {
        query: {
          entity: 'locations',
          method: 'read'
        },
        entity: 'locations',
        display: gettext('Location'),
        param: 'locationDetails.location',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        query: {
          entity: 'cost-centres',
          method: 'read'
        },
        display: gettext('Cost Centre'),
        param: 'locationDetails.costCentre',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id',
        displayList: [
          {
            field1: 'name',
            type: 'string'
          },
          {
            field1: 'code',
            type: 'code',
            title: 'Code'
          },
          {
            field1: 'externalCode',
            type: 'code',
            title: 'External Code'
          }
        ],
        entity: 'cost-centres'
      },
      {
        display: gettext('Alarms'),
        query: {
          entity: 'alarms',
          method: 'read'
        },
        entity: 'alarms',
        param: 'alarm',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      }
    ],
    sort: [
      {
        display: gettext('occured DESC'),
        params: '-_id'
      },
      {
        display: gettext('occured ASC'),
        params: '_id'
      }
    ],
    extended: {
      query: Extend,
      items: [
        {
          title: gettext('Location'),
          param: 'locationName',
          link: {
            state: 'cr-locations',
            params: [
              {
                valueParam: 'location',
                queryParam: 'id'
              }
            ]
          },
          type: 'link'
        },
        {
          title: gettext('Asset'),
          param: 'assetName',
          link: {
            state: 'cr-assets',
            params: [
              {
                valueParam: 'asset',
                queryParam: 'id'
              }
            ]
          },
          type: 'link'
        },
        {
          title: gettext('Cost centre'),
          param: 'costCentreName',
          link: {
            state: 'company-resources-cost-centres-view',
            params: [
              {
                valueParam: 'costCentre',
                queryParam: 'id'
              }
            ]
          },
          type: 'link'
        },
        {
          title: gettext('Measuring point'),
          param: 'measuringpointName',
          link: {
            state: 'cr-measuring-points',
            params: [
              {
                valueParam: 'measuringpoint',
                queryParam: 'id'
              }
            ]
          },
          type: 'link'
        },
        {
          title: gettext('person Responsible'),
          param: 'responsiblePersonName',
          link: {
            state: 'users-users-view',
            params: [
              {
                valueParam: 'responsiblePerson',
                queryParam: 'id'
              }
            ]
          },
          type: 'link'
        },
        {
          title: gettext('Expression'),
          param: 'expression',
          type: 'text'
        }
      ]
    }
  };

  function Enrich(item) {
    var deferred = $q.defer();

    if (item.alarm != null && item.alarm.severity != null) {
      item.alarmName = item.alarm.name;
      item.alarmId = item.alarm._id;
      if (item.alarm.severity) {
        if (item.alarm.severity.name) {
          item.severityName = item.alarm.severity.name;
        }
        if (item.alarm.severity.color) {
          item.badgeColor = item.alarm.severity.color;
        }
      }
    } else {
      item.fetchingObject['alarmName'] = {
        fetching: false,
        success: true,
        nodata: true
      };

      item.fetchingObject['severityName'] = {
        fetching: false,
        success: true,
        nodata: true
      };
    }

    var statusObject = TranslationService.GetCollectionById(
      'codelists.alarmStatuses',
      item.alarmStatus
    );
    item.statusName = statusObject
      ? statusObject.name
      : gettextCatalog.getString('Unknown');

    var entityObject = TranslationService.GetCollectionById(
      'codelists.entities',
      item.streamEntityType
    );
    item.entityTypeName = entityObject
      ? entityObject.name
      : gettextCatalog.getString('Unknown');
    if (item.currentAssigneeObject) {
      item.currentAssigneeName =
        item.currentAssigneeObject.name +
        ' ' +
        item.currentAssigneeObject.family_name;
    }
    var entityName = gettextCatalog.getString('Unknown');
    if (typeof item.timeSeries == 'object' && item.timeSeries != null) {
      entityName = item.timeSeries.name;
    }

    item.monitoringTitle = gettextCatalog.getString(
      'Triggered at {{entityName}}',
      {
        entityName: entityName
      }
    );
    if (!item.externalCode) {
      item.fetchingObject['externalCode'] = {
        fetching: false,
        success: true,
        nodata: true
      };
    }

    return deferred.promise;
  }

  function Extend(item) {
    var deferred = $q.defer();

    TriggeredAlarmModel.custom.readMetadata(item).then(function(result) {
      if (result.responsiblePersonObject) {
        item.responsiblePersonName = result.responsiblePersonObject.name;
      }

      if (result.locationObject) {
        result.locationName = result.locationObject.name;
      }

      if (result.assetObject) {
        result.assetName = result.assetObject.name;
      }

      if (result.measuringpointObject) {
        result.measuringpointName = result.measuringpointObject.name;
      }
      deferred.resolve();
    });

    return deferred.promise;
  }
  return configuration;
}
export default AlarmMonitoringConfiguration;
