/**
 * @ngdoc service
 * @name common.ScheduleClassificationFormConfiguration
 * @description Opens form dialog
 */

ScheduleClassificationFormConfiguration.$inject = [
  'gettextCatalog',
  'gettext',
  'TranslationService',
  '$mdDialog',
  '$timeout'
];
export default function ScheduleClassificationFormConfiguration(
  gettextCatalog,
  gettext,
  TranslationService,
  $mdDialog,
  $timeout
) {
  const display = data => {
    return `${data.name ? data.name : gettextCatalog.getString('Unknown')}`;
  };
  /**
   * @description get common properties time series form configuration.
   * @function
   * @param {Object} item
   * @return {Object} configuration
   */
  async function get({ selectedScheduleClassifications, previewTimeseries }) {
    const itemsToShow = TranslationService.GetCollection(
      'codelists.scheduleClassifications'
    )
      .filter(item => {
        return !selectedScheduleClassifications.find(
          usedItemId => usedItemId == item.id
        );
      })
      .sort((a, b) => a.order - b.order);
    return {
      name: 'scheduleClassification',
      title: gettextCatalog.getString('Add tab'),
      actions: [
        {
          title: gettext('Add'),
          fn: form => {
            const body = form.getValues();
            $mdDialog.hide(body);
          },
          disabledFn: form => {
            return !form.formValidity();
          }
        }
      ],
      fields: [
        {
          id: 'scheduleClassification',
          title: gettextCatalog.getString('Schedule Classification'),
          type: {
            name: 'radio',
            options: {
              layout: 'row',
              modelProperty: 'id',
              items: itemsToShow,
              display
            }
          },
          initialize: () => {
            return null;
          },
          required: true
        },
        {
          id: 'timeseries',
          title: gettextCatalog.getString(
            'Select time series to add to classification'
          ),
          type: {
            name: 'checkbox',
            options: {
              layout: 'column',
              valueField: '_id',
              display: item => {
                let schedulerClassificationAbbreviation = '';
                if (item != null) {
                  if (
                    item.dataScheduler != null &&
                    item.dataScheduler.scheduleClassification
                  ) {
                    let schedulerClassification = TranslationService.GetCollectionById(
                      'codelists.scheduleClassifications',
                      item.dataScheduler.scheduleClassification
                    );
                    if (
                      schedulerClassification != null &&
                      schedulerClassification.name != ''
                    ) {
                      schedulerClassificationAbbreviation = ` - ${schedulerClassification.name}`;
                    }
                  }
                  return `${item.name} ${schedulerClassificationAbbreviation}`;
                }
                return gettextCatalog.getString('Unknown');
              },
              items: previewTimeseries
            }
          }
        }
      ]
    };
  }
  /**
   * @description returns true when sort chart fields should be hidden.
   * @function
   * @param {Object} api form api
   * @return {Boolean}
   */
  const hideSortChartTypeField = api => {
    const sortType = api.getValue('sortType');
    if (sortType == 10) {
      //SORT BY SUM
      return false;
    }
    return true;
  };
  /**
   * @description returns display type form configuration.
   * @function
   * @param {function} displayTypeChanged function that should be triggered on display type changed
   * @param {Number} displayType codelist of display type to initialize form with
   * @return {Object}
   */
  function getDisplayTypeForm(
    displayTypeChanged,
    sortTypeChanged,
    { displayType, sortDirection, sortType, chartType }
  ) {
    return {
      name: 'displayForm',
      fields: [
        {
          id: 'displayType',
          title: gettextCatalog.getString('Display types'),
          type: {
            name: 'radio',
            options: {
              layout: 'row',
              modelProperty: 'id',
              items: TranslationService.GetCollection(
                'codelists.displayTypes'
              ).filter(
                displayType => displayType.id !== 4 && displayType.id !== 5
              )
            }
          },
          initialize: () => displayType || null,
          onChange: api => {
            $timeout(() => {
              const item = api.getValue('displayType');
              displayTypeChanged(item);
            });
          }
        },
        ...getSortFields(
          { sortDirection, sortType, chartType },
          sortTypeChanged
        )
      ]
    };
  }
  /**
   * @description returns array of sort type and sort chart type configuration.
   * @function
   * @param {Object} values default values {sortType, chartType}
   * @param {function} sortTypeChange function that triggers chart redraw on sort fields change
   * @return {Array}
   */
  function getSortFields(values = {}, sortTypeChanged) {
    let { sortType, chartType } = values;
    let fields = [
      {
        id: 'sortType',
        title: gettextCatalog.getString('Sort type'),
        type: {
          name: 'radio',
          options: {
            layout: 'row',
            modelProperty: 'id',
            items: TranslationService.GetCollection('codelists.sortTypes')
          }
        },
        initialize: () => sortType || 1
      },
      {
        id: 'chartType',
        title: gettextCatalog.getString('Chart Type'),
        type: {
          name: 'radio',
          options: {
            layout: 'row',
            modelProperty: 'id',
            items: TranslationService.GetCollection(
              'codelists.chartTypes'
            ).filter(item => item.id == 10 || item.id == 12)
          }
        },
        initialize: () => chartType || 10,
        hide: hideSortChartTypeField
      }
    ];
    if (typeof sortTypeChanged == 'function') {
      fields = fields.map(item => {
        return {
          ...item,
          onChange: api => {
            $timeout(() => {
              const item = api.getValues();
              sortTypeChanged(item);
            });
          }
        };
      });
    }
    return fields;
  }

  return { get, getDisplayTypeForm, getSortFields };
}
