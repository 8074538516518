/**
 * @ngdoc service
 * @name common.loadAssets
 * @description lazy loads external assets.
 * @property {function} anonymousFunction
 */
loadAssets.$inject = ['$q', '$timeout', 'utilService'];

export default function loadAssets($q, $timeout, utilService) {
  var body = document.body;
  var loadedAssets = {};
  /**
   * @memberof loadAssets.anonymousFunction
   * @description lazy loads external assets.
   * @param {array} array of string values of modules we want to load
   * @return {Promise}
   */
  return function(keys) {
    var filesToLoad = window.lazyLoading;
    var mainDeferred = $q.defer();

    function appendScript(scriptObject) {
      var deferred = $q.defer();
      var tagName;
      var attributes;
      var valueParameter;
      switch (scriptObject.type) {
      case 'js':
        tagName = 'script';
        attributes = {
          type: 'text/javascript'
        };
        valueParameter = 'src';
        break;
      case 'css':
        tagName = 'link';
        attributes = {
          rel: 'stylesheet'
        };
        valueParameter = 'href';
        break;
      case 'innerHTML':
        tagName = 'script';
        valueParameter = 'innerHTML';
        break;
      }
      var script = document.createElement(tagName);
      script.onload = function(res) {
        $timeout(function() {
          deferred.resolve(res);
        });
      };
      script.onerror = function(err) {
        deferred.reject(err);
      };
      if (scriptObject.tagId) {
        script.id = scriptObject.tagId;
      }
      if (attributes) {
        for (var attributeName in attributes) {
          script[attributeName] = attributes[attributeName];
        }
      }
      const scriptName = scriptObject.fromLocal
        ? utilService[scriptObject.name]
        : scriptObject.name;
      script[valueParameter] = scriptName;
      document.body.appendChild(script);
      if (scriptObject.type === 'innerHTML') {
        $timeout(function() {
          deferred.resolve();
        });
      }

      return deferred.promise;
    }

    function loadKeys(key) {
      var promise;
      if (typeof loadedAssets[key] == 'undefined') {
        var deferred = $q.defer();
        async.eachSeries(
          filesToLoad[key],
          function(file, callback) {
            var itemScript;
            if (file.tagId) {
              itemScript = body.querySelector('#' + file.tagId);
            } else {
              itemScript = body.querySelector(
                'script[src=\'' + file.name + '\']'
              );
            }
            if (itemScript) {
              callback();
            } else {
              appendScript(file).then(function() {
                callback();
              });
            }
            /*************
              MEMORY CLEANUP
              *************/
            itemScript = null;
          },
          function() {
            deferred.resolve();
          }
        );
        loadedAssets[key] = deferred.promise;
        promise = deferred.promise;
      } else {
        promise = loadedAssets[key];
      }
      return promise;
    }

    function injectAssets() {
      async.eachSeries(
        keys,
        function(key, callback) {
          loadKeys(key).then(
            function() {
              callback();
            },
            function() {
              callback();
            }
          );
        },
        function() {
          mainDeferred.resolve();
        }
      );
    }

    injectAssets();
    return mainDeferred.promise;
  };
}
