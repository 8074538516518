import './rule.scss';
RuleController.$inject = [
  '$stateParams',
  'gettext',
  'rule',
  'MetadataService',
  '$timeout'
];

function RuleController(
  $stateParams,
  gettext,
  rule,
  MetadataService,
  $timeout
) {
  var vm = this;
  var ruleId = $stateParams.id;

  init();

  function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    vm.headerData = constructHeader(rule);
    $timeout(() => {
      MetadataService.Loading(false);
      vm.loading = false;
      MetadataService.ChangeMetadata(rule.name, rule.description);
    });

    vm.previewExpression = rule.expression
      .replace(/true/g, 'FAIL')
      .replace(/false/g, 'SUCCESS');
    vm.variables = rule.inputParams;
  }

  function constructHeader(rule) {
    var actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: ruleId,
        endpoint: {
          entity: 'rules',
          method: 'delete'
        },
        redirectState: 'alarms-and-rules-rules-list'
      },
      {
        title: gettext('duplicate'),
        state: 'alarms-and-rules-rules-duplicate',
        param: 'id',
        paramValue: rule._id
      },
      {
        title: gettext('Update'),
        state: 'alarms-and-rules-rules-edit',
        param: 'id',
        paramValue: rule._id
      }
    ];

    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];

    var propertySections = [
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'rules',
        target: ruleId,
        type: 'userAccess'
      }
    ];

    return {
      metadata: {
        definition: gettext('rule'),
        title: rule.name,
        description: rule.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}

export default RuleController;
