ProjectModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function ProjectModel(ModelUtilities, CrawlerMethods) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'projects'
  };

  const systemTagStep = {
    entity: 'system-tag-entities',
    valueParams: ['_id'],
    queryParams: ['entityId'],
    method: 'custom.readWithTag',
    methodType: 'add',
    newParamNames: ['tags']
  };

  const crawlerConfiguration = {
    entity: 'projects',
    method: 'read',
    populate: 'businessPartner,projectType,projectField',
    steps: [
      {
        entity: 'users',
        valueParams: ['energyManager'],
        queryParams: ['id'],
        method: 'read',
        methodType: 'populate'
      },
      systemTagStep
    ]
  };

  const crawlerConfigurationForm = {
    entity: 'projects',
    method: 'read',
    populate: 'businessPartner,projectType,projectField',
    steps: [systemTagStep]
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readItemView: CrawlerMethods.crawler(crawlerConfiguration),
      readForm: CrawlerMethods.crawler(crawlerConfigurationForm)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default ProjectModel;
