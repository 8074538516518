import types from './times-series.type';

function readTimeSeries(payload) {
  return {
    type: types.TIME_SERIES_READ,
    payload
  };
}

function updateTimeSeries(payload) {
  return {
    type: types.TIME_SERIES_UPDATE,
    payload
  };
}
function updateTimeSeriesProps(payload) {
  return {
    type: types.TIME_SERIES_UPDATE_PROPS,
    payload
  };
}

function createTimeSeries(payload) {
  return {
    type: types.TIME_SERIES_CREATE,
    payload
  };
}

export default {
  readTimeSeries,
  updateTimeSeries,
  createTimeSeries,
  updateTimeSeriesProps
};
