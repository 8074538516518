nationalHolidayConfiguration.$inject = [
  'gettext',
  '$q',
  'SfeFormDialogService',
  'ListFormService',
  'AlertingService',
  'CrudToastFactory',
  'NationalHolidayModel'
];

function nationalHolidayConfiguration(
  gettext,
  $q,
  SfeFormDialogService,
  ListFormService,
  AlertingService,
  CrudToastFactory,
  NationalHolidayModel
) {
  const fieldNames = ['name', 'date', 'periodic'];
  function openCreateDialog(relistData) {
    var config = ListFormService.generateForm(
      ['name', 'singleDate', 'periodic'],
      false
    );
    var title = gettext('New National Holiday');
    SfeFormDialogService.openSfeFormDialog(
      false,
      config,
      { date: new Date() },
      title
    ).then(function(object) {
      if (object) {
        create(angular.copy(object), relistData);
      }
    });
  }

  function openUpdateDialog(item, relistData) {
    NationalHolidayModel.read({
      id: item._id
    }).then(
      function(res) {
        var date = res.data.date.replace(/ /g, '').split('.');
        var dataObj = res.data;
        dataObj.date = new Date();
        if (date && date.length > 0) {
          date = date.map(function(item) {
            return Number(item);
          });
          dataObj.date.setDate(date[0]);
          dataObj.date.setMonth(date[1] - 1);
          if (!res.data.periodic) {
            dataObj.date.setYear(date[2]);
          }
        }
        var config = ListFormService.generateForm(
          ['name', 'singleDate', 'periodic'],
          false
        );
        var title = gettext('Edit National Holiday');

        SfeFormDialogService.openSfeFormDialog(
          true,
          config,
          dataObj,
          title
        ).then(function(object) {
          if (object) {
            update(item._id, angular.copy(object), relistData);
          }
        });
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function createDate(obj) {
    var date = new Date(obj.date);
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    if (month < 10) {
      month = '0' + month.toString();
    } else {
      month = month.toString();
    }
    if (day < 10) {
      day = '0' + day.toString();
    } else {
      day = day.toString();
    }
    if (obj.periodic) {
      date = day + '. ' + month + '.';
    } else {
      date = day + '. ' + month + '. ' + year;
    }

    return date;
  }

  function create(obj, relistData) {
    obj.date = createDate(obj);
    if (!obj.periodic) {
      obj.periodic = false;
    }
    NationalHolidayModel.create(
      ListFormService.generateBodyObject(obj, fieldNames)
    ).then(
      function() {
        CrudToastFactory.toast('create');
        relistData();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function update(id, obj, relistData) {
    obj.date = createDate(obj);
    if (!obj.periodic) {
      obj.periodic = false;
    }
    NationalHolidayModel.update(
      {
        id: id
      },
      ListFormService.generateBodyObject(obj, fieldNames)
    ).then(
      function() {
        CrudToastFactory.toast('update');
        relistData();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  var configuration = {
    entity: {
      singular: gettext('National Holiday'),
      plural: gettext('National Holidays')
    },
    api: {
      query: {
        entity: 'national-holidays',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name'
    },
    buttons: [
      {
        title: gettext('Create'),
        fn: openCreateDialog,
        relist: true,
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        action: openUpdateDialog
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'national-holidays',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'date',
        title: gettext('Date'),
        type: 'code'
      }
    ],
    badges: [
      {
        type: 'goodOrBad',
        title: gettext('Periodic'),
        param: 'periodic',
        colored: true,
        good: gettext('Periodic'),
        bad: gettext('Not periodic')
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich() {
    var deferred = $q.defer();
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default nationalHolidayConfiguration;
