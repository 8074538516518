import './sfe-form-2-date.scss';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import template from './sfe-form-2-date.component.html';
import flatpickr from 'flatpickr'; //https://flatpickr.js.org/examples/
import { Slovenian } from 'flatpickr/dist/l10n/sl.js';
import { english } from 'flatpickr/dist/l10n/default';
import { Croatian } from 'flatpickr/dist/l10n/hr';

import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

/**
 * @ngdoc component
 * @name common.sfeForm2Date
 * @description Component for selecting a date
 * @param {string} dateTitle - input title
 * @param {Object} options - options for flatpickr,
 *    @param {boolean} useCurrentTime - indicates when we should autocomplete time to current time or 00:00
 *    @param {boolean} enableTime - tells us if we wish to add the options to choose and display time
 *    @param {boolean} enableSeconds - tells us if we wish to add the options to choose and display seconds
 *    @param {string} mode - tells us what kind of mode (range, multiple) we want the datepicker to use, if we just want to select one date we don't add this
 *    @param {Object} customOptions- other custom options for flatpickr, see flatpickr documentation https://flatpickr.js.org/options/
 *    @param {Function} mindate  - function that returns the earliest allowable date
 *    @param {Function} maxdate - function that returns the latest allowable date
 * @param {boolean} disable - tells us if the input should be disabled
 * @param {function} change - function that is called upon value change
 * @example
 * <sfe-form-2-date
 *   date-title="vm.config.title"
 *   options="vm.config.type.options"
 *   disable="vm.config.disable"
 *   change="vm.config.onChange"
 *   name="{{vm.config.id}}"
 *   ng-model="vm.value" //ng-model isn't a binding, but is in required
 *   sfe-form-2-validation="vm.config.validators" //we use this so that we do nto need to pass validation as a binding
 * ></sfe-form-2-date>
 */

export default {
  template,
  bindings: {
    dateTitle: '<',
    options: '<',
    disable: '<',
    change: '<'
  },
  require: {
    model: 'ngModel',
    form: '^form'
  },
  controller: sfeForm2Date,
  controllerAs: 'vm',
  bindToController: true
};

sfeForm2Date.$inject = [
  '$element',
  'DateAutocomplete',
  'LocalizationService',
  '$timeout'
];

function sfeForm2Date(
  $element,
  DateAutocomplete,
  LocalizationService,
  $timeout
) {
  const vm = this;
  let mode = 'single';
  vm.autocompleteDateOnFocus = autocompleteDateOnFocus;
  //LOCALIZATION
  const selectedLocale = LocalizationService.getLocale();
  let locale;
  switch (selectedLocale) {
  case 'sl':
    locale = Slovenian;
    break;
  case 'hr':
    locale = Croatian;
    break;
  default:
    locale = english;
  }
  if (locale != null) {
    locale.firstDayOfWeek = 1;
  }
  //configuration that is going to be used by flatpickr
  let flatpickrConfig = {
    time_24hr: true,
    weekNumbers: true,
    onChange: selectedDates => {
      let enableTime = vm?.options?.enableTime || false;
      if (vm.change != null) {
        vm.change();
      }
      /*
      if time is not enabled we want to set the first date value to midnight 
      and the second one (in case of range) to 23.59
      */
      if (enableTime == false) {
        switch (vm.flatpickr.selectedDates.length) {
        case 1:
          vm.flatpickr.selectedDates[0].setHours(0, 0, 0, 0);
          setDate(vm.flatpickr.selectedDates);
          break;
        case 2:
          vm.flatpickr.selectedDates[0].setHours(0, 0, 0, 0);
          vm.flatpickr.selectedDates[1].setHours(23, 59, 0, 0);
          setDate(vm.flatpickr.selectedDates);
          break;
        case 0:
          setDate(null);
        }
      } else {
        setDate(selectedDates);
      }
    },
    locale,
    parseDate: date => {
      let useCurrentTime = false;
      if (vm.options != null && vm.options.useCurrentTime != null) {
        useCurrentTime = vm.options.useCurrentTime;
      }
      if (typeof date === 'string') {
        if (vm.options != null) {
          if (vm.options.yearSelector) {
            return getDateOutOfYear(date);
          } else if (vm.options.monthSelector) {
            return getDateOutOfMonthAndYear(date);
          }
        }

        return DateAutocomplete.get(date, useCurrentTime);
      }
      return new Date();
    }
  };

  vm.$onInit = () => {
    const el = angular.element($element[0].querySelector('.sfe-form-2-date'));

    vm.flatpickr = flatpickr(el, flatpickrConfig);
    $timeout();
    vm.model.$render = () => {
      if (vm.model != null) {
        setDate(vm.model.$modelValue);
        angular
          .element(el)
          .val(vm.flatpickr.input.value)
          .trigger('input');
      } else if (
        Array.isArray(vm.flatpickr.selectedDates) &&
        vm.flatpickr.selectedDates.length > 0
      ) {
        //this should happen only when there is a default value set
        setDate(vm.flatpickr.selectedDates);
      }
    };
  };

  vm.$onChanges = () => {
    let customOptions = {};
    let enableTime = vm?.options?.enableTime || false;
    let enableSeconds = vm?.options?.enableSeconds || false;
    let dateFormat = 'd. m. Y'; //https://flatpickr.js.org/formatting/
    let additionalConfig = {};

    if (vm.options != null) {
      if (typeof vm.options.maxdate === 'function') {
        vm.model.$validators.maxdate = (modelValue, viewValue) => {
          if (checkDateValidity(viewValue, vm.options.maxdate)) {
            return true;
          } else {
            const max = new Date(vm.options.maxdate());
            if (viewValue.every(item => new Date(item) < max)) {
              return true;
            }
            vm.options.latestDate = max; // options passed to sfe-error-message require latestDate value to properly show the error
            return false;
          }
        };
      }

      if (typeof vm.options.mindate === 'function') {
        vm.model.$validators.mindate = (modelValue, viewValue) => {
          if (checkDateValidity(viewValue, vm.options.mindate)) {
            return true;
          } else {
            const min = new Date(vm.options.mindate());
            if (viewValue.every(item => new Date(item) > min)) {
              return true;
            }
            vm.options.earliestDate = min; // options passsed to sfe-error-message require earliestDate value to properly show the error
            return false;
          }
        };
      }

      if (vm.options.customOptions != null) {
        customOptions = vm.options.customOptions;
      }
      if (enableTime) {
        dateFormat = 'd. m. Y H:i';
      }
      if (enableSeconds) {
        enableTime = true;
        dateFormat = 'd. m. Y H:i:S';
      }
      if (vm.options.mode != null) {
        mode = vm.options.mode;
      }
      if (vm.options.monthSelector) {
        additionalConfig = {
          weekNumbers: false,
          plugins: [
            new monthSelectPlugin({
              shorthand: false,
              dateFormat: 'm. Y',
              altFormat: 'M Y'
            })
          ]
        };
      }
      if (vm.options.yearSelector) {
        additionalConfig = {
          noCalendar: true,
          enableSeconds: false,
          enableTime: false,
          dateFormat: 'Y',
          monthSelectorType: 'none',
          weekNumbers: false
        };
      }
    }
    flatpickrConfig = {
      ...flatpickrConfig,
      mode,
      enableTime,
      enableSeconds,
      dateFormat,
      allowInput: true,
      ...customOptions,
      ...additionalConfig
    };
  };
  vm.$onDestroy = () => {
    vm.flatpickr.destroy();
  };
  /**
   * @description autocomplete date out of month selector .
   * @function
   * @param {string} date date string MM.yyyy
   * @return {Date}
   */
  function getDateOutOfMonthAndYear(date) {
    let newDate = new Date();
    let yearString;
    let month;
    const dateArray = date.replace(/\s/g, '').split('.');
    switch (dateArray.length) {
    case 1:
      month = Number(dateArray[0]);
      if (month >= 1 && month <= 12) {
        newDate.setMonth(month - 1);
      }
      break;
    case 2:
      yearString = dateArray[1];
      newDate = getDateOutOfYear(yearString);
      month = Number(dateArray[0]);

      if (month >= 1 && month <= 12) {
        newDate.setMonth(month - 1);
      }
    }
    return newDate;
  }

  /**
   * @description autocomplete date out of year selector .
   * @function
   * @param {string} date date string yyyy
   * @return {Date}
   */
  function getDateOutOfYear(date) {
    let newDate = new Date();
    let year = newDate.getFullYear();
    switch (date.length) {
    case 4:
      year = Number(date);
      break;
    case 3:
      year = Number(`1${date}`);
      break;
    case 2:
      year = Number(`20${date}`);
      break;
    case 1:
      year = Number(`202${date}`);
      break;
    }
    newDate.setFullYear(year);
    return newDate;
  }

  /**
   * @description function that is called on input focus and autocomplates dates.
   * @function
   */
  function autocompleteDateOnFocus() {
    if (
      Array.isArray(vm.flatpickr.selectedDates) &&
      vm.flatpickr.selectedDates.length == 0
    ) {
      const currentDate = new Date();
      if (vm.options != null && !vm.options.useCurrentTime) {
        currentDate.setHours(0);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        currentDate.setMilliseconds(0);
      }
      setDate(currentDate);
    }
  }
  /**
   * @description function that sets the date for the model and for the  flatpickr.
   * @function
   * @param {Array} date - Can be an Array, date objects or null. It is the date to which we want to set flatpick and model.
   */
  function setDate(date) {
    if (Array.isArray(date) && date.length == 0) {
      date = null;
    }
    vm.flatpickr.setDate(date);

    if (Array.isArray(date) || date === null) {
      vm.model.$setViewValue(date);
      vm.model.$setTouched();
    } else {
      vm.model.$setViewValue([date]);
      vm.model.$setTouched();
    }
    if (typeof vm.change == 'function') {
      vm.change();
    }
  }
  function checkDateValidity(model, getMinOrMax) {
    if (
      Array.isArray(model) &&
      model.length > 0 &&
      model[1] != null &&
      !isNaN(new Date(getMinOrMax()).getTime()) //
    ) {
      return false;
    }
    return true;
  }
}
