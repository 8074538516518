/**
 * @ngdoc service
 * @name common.analysisChartConfiguration
 * @description contains analysis component helper methods.
 * Returns an object with the follow properties
 * @property {function} constructConfiguration - see method constructConfiguration
 * @property {function} mapMonth - see method mapMonth
 */
analysisChartConfiguration.$inject = [
  'gettext',
  '$q',
  '$filter',
  'TranslationService',
  'StandardUtils',
  'gettextCatalog'
];

function analysisChartConfiguration(
  gettext,
  $q,
  $filter,
  TranslationService,
  StandardUtils,
  gettextCatalog
) {
  /**
   * @memberof analysisChartConfiguration.mapMonth
   * @description maps month name by key number.
   * @param {number} month month number
   * @return {string}
   */
  function mapMonth(month) {
    switch (month) {
    case 1:
      return gettext('JAN');
    case 2:
      return gettext('FEB');
    case 3:
      return gettext('MAR');
    case 4:
      return gettext('APR');
    case 5:
      return gettext('MAJ');
    case 6:
      return gettext('JUN');
    case 7:
      return gettext('JUL');
    case 8:
      return gettext('AVG');
    case 9:
      return gettext('SEP');
    case 10:
      return gettext('OKT');
    case 11:
      return gettext('NOV');
    case 12:
      return gettext('DEC');
    default:
      return month;
    }
  }
  /**
   * @description description.
   * @function
   * @param {string} groupEntity name of entity to group by
   * @param {string} stackedType stack type (percent..)
   * @return {dataType}
   */
  function getTooltip(groupEntity, stackedType) {
    if (groupEntity) {
      return {
        formatter: function() {
          return (
            '<b>' +
            this.x.name +
            '</b><br/>' +
            this.series.name +
            ' ' +
            this.series.userOptions.stack +
            ' ' +
            ': ' +
            this.y.toFixed(2) +
            '<br/>' +
            (this.point.stackTotal ? 'Total: ' + this.point.stackTotal : '')
          );
        }
      };
    } else {
      return {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.2f} </b>' +
          (stackedType === 'percent' ? '({point.percentage:.0f}%)</b>' : '') +
          '</td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      };
    }
  }

  /**
   * @memberof common.constructConfiguration
   * @description description.
   * @param {Array} values analysis values
   * @param {Array} groupAggregation array of aggregation group conditions
   * @param {Array} seriesNames array of series object
   * @param {bool} showTable flag that indicates table display status
   * @param {Array} dataset dataset columns
   * @param {string} groupEntity name of entity to group by
   * @param {string} sliceBy name of entity to slice by
   * @param {Array} boundaries array of boundaries
   * @param {bool} xAxisGroup indicates that aggragation group is displayed on x axis
   * @param {string} xAxis parameter name to display continues values
   * @param {bool} useCache cahce usage flag
   */
  function constructConfiguration(
    values,
    groupAggregations,
    seriesNames,
    showTable,
    dataset,
    groupEntity,
    sliceBy,
    boundaries,
    xAxisGroup,
    xAxis
  ) {
    var deferred = $q.defer();
    var categories;
    var categoryIndex;
    var series;
    var seriesObject;
    var groupedSeriesBySeriesName;

    initiateChart();

    function constructgGroupedSeriesAndCategoriesNames() {
      var groupedValues = {};
      var aggregations = [];
      groupAggregations.forEach(function(aggregation) {
        if (aggregation !== groupEntity) {
          aggregations.push(aggregation);
        }
      });
      var groupedSeries = [];
      categories = [];
      categoryIndex = {};
      // var seriesValue = seriesNames[0].yAxis;

      // CONSTRUCT CATEGORISE
      values.forEach(function(value) {
        if (!groupedValues[value.entityNames[groupEntity]]) {
          groupedValues[value.entityNames[groupEntity]] = {};
          groupedValues[value.entityNames[groupEntity]].series = [];
        }
        groupedValues[value.entityNames[groupEntity]].series.push(value);

        value.categoryName = '';
        aggregations.forEach(function(entity, index) {
          if (index < aggregations.length - 1 && entity !== sliceBy) {
            if (value.entityNames[entity]) {
              value.categoryName += value.entityNames[entity] + ' ';
            } else if (value._id[entity]) {
              value.categoryName += value._id[entity] + ' ';
            } else {
              value.categoryName += gettextCatalog.getString('Unknown');
            }
          }

          if (aggregations.length === 1) {
            value.categoryName += value.entityNames[entity] + ' ';
          }
        });
        if (typeof categoryIndex[value.categoryName] === 'undefined') {
          categories.push(value.categoryName);
          var index = categories.length - 1;
          categoryIndex[value.categoryName] = index;
        }
      });
      // CONSTRUCT SERIES
      groupedSeriesBySeriesName = {};
      seriesNames.forEach(function(series) {
        var seriesValue = series.yAxis;
        var chartType = TranslationService.GetCollectionById(
          'codelists.chartTypes',
          series.chartType
        );
        groupedSeries = [];
        Object.keys(groupedValues).forEach(function(key) {
          groupedValues[key].series.forEach(function(value) {
            value.seriesName =
              value.entityNames[aggregations[aggregations.length - 1]] +
              ' (' +
              seriesValue +
              ')';
            var foundSeries = _.find(groupedSeries, function(ser) {
              return ser.stack === key && ser.name === value.seriesName;
            });
            if (!foundSeries) {
              groupedSeries.push({
                stack: key,
                name: value.seriesName,
                data: [],
                type: chartType ? chartType.highChartCode : 'column',
                stacking: chartType ? chartType.stacking : undefined
              });
              foundSeries = groupedSeries[groupedSeries.length - 1];
            }
            var cIndex = categoryIndex[value.categoryName];
            foundSeries.data[cIndex] = value[seriesValue];
          });
        });
        groupedSeriesBySeriesName[seriesValue] = angular.copy(groupedSeries);
      });

      seriesObject = groupedSeriesBySeriesName;
      var graph = constructGraph();
      var tableData = constructTable();
      tableData.graph = graph;
      deferred.resolve(tableData);
    }

    function constructSeriesAndCategoriesNames() {
      categoryIndex = {};
      seriesObject = {};
      categories = [];
      values.forEach(function(value) {
        value.categoryName = '';
        groupAggregations.forEach(function(entity, index) {
          if (index < groupAggregations.length - 1 && entity !== sliceBy) {
            value.categoryName +=
              (value.entityNames[entity] ||
                gettextCatalog.getString('Unknown')) + ' ';
          } else {
            value.seriesName =
              value.entityNames[entity] || gettextCatalog.getString('Unknown');
          }
          if (groupAggregations.length === 1) {
            value.categoryName +=
              (value.entityNames[entity] ||
                gettextCatalog.getString('Unknown')) + ' ';
          }
        });

        if (typeof categoryIndex[value.categoryName] === 'undefined') {
          categories.push(value.categoryName);
          var index = categories.length - 1;
          categoryIndex[value.categoryName] = index;
        }
        if (!series) {
          series = {};
        }
        seriesNames.forEach(function(series) {
          var seriesValue = series.yAxis;
          if (!seriesObject[seriesValue]) {
            seriesObject[seriesValue] = {};
          }
          if (!seriesObject[seriesValue][value.seriesName]) {
            seriesObject[seriesValue][value.seriesName] = {
              chartType: TranslationService.GetCollectionById(
                'codelists.chartTypes',
                series.chartType
              )
            };
          }

          if (!seriesObject[seriesValue][value.seriesName].data) {
            seriesObject[seriesValue][value.seriesName].data = [];
          }
          var cIndex = categoryIndex[value.categoryName];
          seriesObject[seriesValue][value.seriesName].data[cIndex] =
            value[seriesValue];
        });
      });

      var graph = constructGraph();
      var tableData = constructTable();
      tableData.graph = graph;
      deferred.resolve(tableData);
    }

    function constructDecimalXAxisChart() {
      var seriesObject = [];
      seriesNames.forEach(function(series) {
        var yAxis = series.yAxis;
        seriesObject.push({
          name: yAxis,
          type: TranslationService.GetCollectionById(
            'codelists.chartTypes',
            series.chartType
          ).highChartCode,
          data: []
        });

        values.forEach(function(value) {
          seriesObject[seriesObject.length - 1].data.push({
            x: value[xAxis],
            y: value[yAxis],
            tooltipTitle:
              value.entityNames[groupAggregations[groupAggregations.length - 1]]
          });
        });
        seriesObject[seriesObject.length - 1].data = _.sortBy(
          seriesObject[seriesObject.length - 1].data,
          ['x']
        );
      });

      var tableData = constructTable();
      var graph = {
        chart: {
          zoomType: 'x'
        },
        title: {
          text: ''
        },
        xAxis: {
          className: 'highcharts-xAxis-title',
          title: {
            text: xAxis
          }
        },
        tooltip: {
          formatter: function() {
            var s =
              '<b> ' + this.x.toFixed(2) + ':</b> ' + this.point.tooltipTitle;

            return s;
          }
        },
        yAxis: constructYAxis(boundaries),
        legend: {
          enabled: true
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false,
              borderRadius: 2,
              backgroundColor: 'rgba(252, 255, 197, 0.7)',
              borderWidth: 1,
              borderColor: '#AAA',
              y: -10,
              shape: 'callout',
              formatter: function() {
                return (
                  '<b> ' +
                  this.x.toFixed(2) +
                  ':</b> ' +
                  this.point.tooltipTitle
                );
              }
            }
          }
        },
        series: seriesObject,
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        }
      };
      tableData.graph = graph;
      deferred.resolve(tableData);
    }
    /**
     * @description analysis initiate function
     * @function
     */
    function initiateChart() {
      if (!values.length) {
        deferred.resolve({});
        return;
      } else {
        mapAnalysisAggregationGroupEntityNames();
        if (xAxisGroup) {
          if (groupEntity) {
            constructgGroupedSeriesAndCategoriesNames();
          } else {
            constructSeriesAndCategoriesNames();
          }
        } else {
          constructDecimalXAxisChart();
        }
      }
    }
    /**
     * @description maps analysis values aggregation fields/entity items ids to names
     * @function
     */
    function mapAnalysisAggregationGroupEntityNames() {
      var foundBillingKinBillingType;
      values.forEach(function(valueObject) {
        valueObject.entityNames = groupAggregations.reduce(function(
          entityNamesObj,
          entity
        ) {
          switch (entity) {
          case 'location':
          case 'costCentre':
          case 'energySourceType':
          case 'billingKind':
          case 'billingType':
          case 'tariff':
          case 'energyManagementGroup':
            entityNamesObj[entity] = valueObject.names[entity + 'Name'];
            break;
          case 'billingDateMonth':
          case 'serviceDateMonth':
            entityNamesObj[entity] = mapMonth(valueObject._id[entity]);
            break;
          case 'billingDate':
          case 'serviceDate':
            entityNamesObj[entity] = $filter('date')(
              new Date(valueObject._id[entity]),
              'yyyy-MM-dd'
            );
            break;
          case 'billingTypeBillingKind':
            foundBillingKinBillingType = TranslationService.GetCollection(
              'codelists.billingTypeBillingKinds'
            ).find(item => item.id == valueObject._id[entity]);

            if (foundBillingKinBillingType) {
              entityNamesObj[entity] = foundBillingKinBillingType
                ? foundBillingKinBillingType.name
                : valueObject._id[entity];
            }
            break;
          default:
            entityNamesObj[entity] = valueObject._id[entity];
            break;
          }
          return entityNamesObj;
        },
        {});
      });
    }
    /**
     * @description creates and returns table configurations.
     * @function
     * @return {object}
     * {
     *   taleConfiguration,
     *   tableData
     *   header
     * }
     */
    function constructTable() {
      let header = [];
      if (showTable) {
        var foundDatasetItem, headerCategory;
        header = groupAggregations.reduce((accumulator, condition) => {
          foundDatasetItem = dataset.find(
            datasetItem => datasetItem.rawFieldName == condition
          );
          headerCategory = {
            name: foundDatasetItem
              ? foundDatasetItem.displayFieldName
              : condition,
            sort: condition
          };
          // foundDatasetItem.rawFieldName === 'billingDateYear'
          // is a temporary fix to set display number type until we'll hvae more general one
          if (foundDatasetItem) {
            if (
              (foundDatasetItem.dateType === 'year' &&
                foundDatasetItem.entity === 'date') ||
              foundDatasetItem.rawFieldName === 'billingDateYear' ||
              foundDatasetItem.rawFieldName === 'serviceDateYear'
            ) {
              headerCategory.year = true;
            } else if (
              foundDatasetItem.rawFieldName === 'billingDateMonth' ||
              foundDatasetItem.rawFieldName == 'serviceDateMonth'
            ) {
              //month should be custom field to convert numbers to strings
              headerCategory.custom = true;
              headerCategory.customFn = mapMonth;
            } else {
              headerCategory.text = true;
            }
          } else {
            headerCategory.text = true;
          }
          accumulator.push(headerCategory);
          return accumulator;
        }, header);

        header = seriesNames.reduce((accumulator, series) => {
          accumulator.push({
            sort: series.yAxis,
            text: true,
            name: series.yAxis
          });
          return accumulator;
        }, header);

        var tableData = [];
        var tableConfig = {
          empty: gettext('There are no values.'),
          page: 1,
          limit: 5,
          order: groupAggregations[0],
          state: 'analiza',
          locationstId: 'analiza'
        };
        var tableItem;

        //ALL VALUES
        tableData = values.reduce((accumulator, value) => {
          //ADD SERIES VALUE
          tableItem = seriesNames.reduce((accumulator, series) => {
            if (typeof value[series.yAxis] !== 'number') {
              accumulator[series.yAxis] = String(value[series.yAxis]);
            } else {
              accumulator[series.yAxis] = StandardUtils.round(
                value[series.yAxis],
                2
              ).toFixed(2);
            }
            return accumulator;
          }, {});
          //ADD AGGREGATION VALUE
          tableItem = groupAggregations.reduce((accumulator, entity) => {
            if (entity == 'billingDateMonth' || entity == 'serviceDateMonth') {
              accumulator[entity] = convertBillingDateMonthToNumber(
                value.entityNames[entity]
              );
            } else {
              accumulator[entity] = value.entityNames[entity];
            }
            return accumulator;
          }, tableItem);

          accumulator.push(tableItem);
          return accumulator;
        }, tableData);
      }
      return {
        tableConfig,
        tableData,
        header
      };
    }
    /**
     * @description converts billing date month to number to allow to sort in the chart sfe-table.
     * @function
     * @param {String} value month value
     * @return {Number}
     */
    function convertBillingDateMonthToNumber(value) {
      switch (value) {
      case 'JAN':
        return 1;
      case 'FEB':
        return 2;
      case 'MAR':
        return 3;
      case 'APR':
        return 4;
      case 'MAJ':
        return 5;
      case 'JUN':
        return 6;
      case 'JUL':
        return 7;
      case 'AVG':
        return 8;
      case 'SEP':
        return 9;
      case 'OKT':
        return 10;
      case 'NOV':
        return 11;
      case 'DEC':
        return 12;
      default:
        return value;
      }
    }

    /**
     * @description creates yAxis configuration object.
     * @function
     * @param {array} boundaries list of boundary objects
     * @return {object}
     */
    function constructYAxis(boundaries) {
      var el, body;
      var yAxis = {
        min: 0,
        title: {
          text: ''
        }
      };
      var acc = 0;
      if (boundaries) {
        let plotOption;
        yAxis.plotLines = boundaries.reduce(function(accumulator, item) {
          plotOption = {
            value: item.value,
            color: item.color,
            dashStyle: 'shortdash',
            width: 0.5,
            label: {
              text: item.label,
              align: 'right',
              horizontalAlign: 'middle',
              x: -10,
              style: {
                color: item.color,
                fontFamily: 'Roboto',
                fontSize: '13px'
              }
            },
            zIndex: 5
          };
          el = angular.element(
            '<span style="font-family: Roboto; font-size: 13px; position: absolute; visibility: hidden">' +
              item.label +
              '</span>'
          );
          body = angular
            .element(document)
            .find('body')
            .eq(0);
          body.append(el);
          var width = el[0].clientWidth;
          var offset = acc;
          plotOption.label.x -= offset;
          acc = offset + width + 12;
          accumulator.push(plotOption);
          return accumulator;
        }, []);
      }
      /*************
        MEMORY CLEANUP
        *************/
      body = null;
      el = null;
      return yAxis;
    }

    /**
     * @description returns analysis highcharts configuration object.
     * @function
     * @return {object}
     */
    function constructGraph() {
      var seriesOptions = [];
      var categoriesOptions = categories;
      var stackedType; /*= chartType.stacking TEMP*/
      var tooltip;
      var xLabels = {};
      if (groupEntity) {
        var tempCategories = [];
        Object.keys(seriesObject).forEach(function(seriesKey) {
          var seriesArray = groupedSeriesBySeriesName[seriesKey];
          seriesArray.forEach(function(series) {
            categories.forEach(function(category, index) {
              var foundCategory = _.find(tempCategories, {
                name: category
              });
              if (!foundCategory) {
                tempCategories.push({
                  name: category,
                  categories: []
                });
                foundCategory = tempCategories[tempCategories.length - 1];
              }

              if (typeof series.data[index] === 'undefined') {
                series.data[index] = null;
              } else {
                var found = _.find(foundCategory.categories, function(val) {
                  return val === series.stack;
                });

                if (!found) {
                  foundCategory.categories.push(series.stack);
                }
              }
            });
            seriesOptions.push(series);
          });
        });

        // seriesOptions = seriesObject;
        categoriesOptions = tempCategories;
        tooltip = getTooltip(groupEntity || '', stackedType || '');
        xLabels = {
          useHTML: true,
          formatter: function() {
            var string = '';
            this.value.categories.forEach(function(value, index) {
              string += '<div>' + (index + 1) + '. ' + value + '</div>';
            });
            string +=
              '<br/><div style="text-align:center">' +
              this.value.name +
              '</div>';
            return string;
          }
        };
      } else {
        Object.keys(seriesObject).forEach(function(key) {
          Object.keys(seriesObject[key]).forEach(function(seriesKey) {
            categoriesOptions.forEach(function(category, categoryIndex) {
              if (
                typeof seriesObject[key][seriesKey].data[categoryIndex] ===
                'undefined'
              ) {
                seriesObject[key][seriesKey].data[categoryIndex] = null;
              }
            });

            seriesOptions.push({
              name: seriesKey + ' (' + key + ') ',
              data: seriesObject[key][seriesKey].data,
              type: seriesObject[key][seriesKey].chartType.highChartCode,
              stacking: seriesObject[key][seriesKey].chartType.stacking
            });
          });
        });

        tooltip = getTooltip(groupEntity || '', stackedType || '');
      }
      var columnPlotOptions = {
        pointPadding: 0.2,
        borderWidth: 0,
        stacking: stackedType ? stackedType : undefined
      };

      var graph = {
        chart: {
          zoomType: 'x'
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: categoriesOptions,
          crosshair: true,
          labels: xLabels
        },
        tooltip: tooltip,
        yAxis: constructYAxis(boundaries),
        legend: {
          enabled: true,
          maxHeight: 75
        },
        plotOptions: {
          column: columnPlotOptions
        },
        series: seriesOptions,
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        }
      };
      return graph;
    }

    return deferred.promise;
  }
  return {
    constructConfiguration: constructConfiguration,
    mapMonth: mapMonth
  };
}

export default analysisChartConfiguration;
