/**
 * @ngdoc factory
 * @name common.StandardUtils
 * @description Provides functions for various parts of application.
 * @property {function} QueryStringToJSON - See method QueryStringToJSON.
 * @property {function} getLinearFunction - See method getLinearFunction.
 * @property {function} round - See method round.
 * @property {function} getUserImageUrl - See method getUserImageUrl.
 * @property {function} readableTimeObjectSince - See method readableTimeObjectSince.
 * @property {function} objectTimeOutOfSeconds - See method objectTimeOutOfSeconds.
 * @property {function} readableExactTimeOutOfSeconds - See method readableExactTimeOutOfSeconds.
 */

StandardUtils.$inject = [
  'gettext',
  'gettextCatalog',
  'utilService',
  'Formatting'
];
/**
 * @ngdoc service
 * @name common.StandardUtils
 * @description general methods.
 * @property {Function} formatDate - see method formatDate
 */
export default function StandardUtils(
  gettext,
  gettextCatalog,
  utilService,
  Formatting
) {
  /**
   * @description returns hour factor.
   * @function
   * @param {string} type type of time unit
   * @return {number}
   */
  function getFactor(type) {
    switch (type) {
    case 'hour':
      return 1;
    case 'day':
      return 24;
    case 'month':
      return 24 * 31;
    case 'year':
      return 24 * 31 * 12;
    }
  }
  /**
   * @description returns type and value of units.
   * @function
   * @param {number} units number of hours
   * @return {Object}
   */
  function setDisplayLastNHoursConfiguration(units) {
    var yearTest = units / getFactor('year');
    var monthTest = units / getFactor('month');
    var dayTest = units / getFactor('day');
    if (yearTest >= 1 && yearTest % 1 === 0) {
      return {
        units: yearTest,
        type: 'year',
        singular: gettextCatalog.getString('year'),
        plural: gettextCatalog.getString('years')
      };
    } else if (monthTest >= 1 && monthTest % 1 === 0) {
      return {
        units: monthTest,
        type: 'month',
        singular: gettextCatalog.getString('month'),
        plural: gettextCatalog.getString('months')
      };
    } else if (dayTest >= 1 && dayTest % 1 === 0) {
      return {
        units: dayTest,
        type: 'day',
        singular: gettextCatalog.getString('day'),
        plural: gettextCatalog.getString('days')
      };
    }
    return {
      units: units,
      type: 'hour',
      singular: gettextCatalog.getString('hour'),
      plural: gettextCatalog.getString('hours')
    };
  }
  /**
   * @description decodes query string to query object.
   * @function
   * @param {String} queryString query string
   * @return {Object}
   */
  function queryStringToJSON(queryString) {
    var keyValuePairs = queryString.split('&');
    var key, value;
    var queryObject = {};
    keyValuePairs.forEach(function(pair) {
      pair = pair.split('=');
      key = pair[0];
      value = pair[1];
      if (queryObject[key]) {
        if (!Array.isArray(queryObject[key])) {
          queryObject[key] = [queryObject[key]];
        }
        queryObject[key].push(decodeURIComponent(value || ''));
      } else {
        queryObject[key] = decodeURIComponent(value || '');
      }
    });

    return JSON.parse(JSON.stringify(queryObject));
  }

  /**
   * @memberof common.StandardUtils
   * @description Returns a linear function based on coefficient and constant.
   * @param {number} k Coefficient.
   * @param {number} n Constant.
   * @return {string}
   */
  function getLinearFunction(k, n) {
    if (k === 0 && n === 0) {
      return 0;
    }
    const formatedK =
      k !== 0 ? `${Formatting.formatAllNumbers(roundNumber(k))}x` : '';

    let operator = '';
    let formatedN = '';

    if (n < 0) {
      operator = ' - ';
      formatedN = Formatting.formatAllNumbers(Math.abs(n));
    } else if (n !== 0) {
      operator = k !== 0 ? ' + ' : '';
      formatedN = Formatting.formatAllNumbers(n);
    }

    const formatedFunction = `${formatedK}${operator}${formatedN}`;

    return formatedFunction;
  }

  function roundNumber(num) {
    return +(Math.round(num + 'e+2') + 'e-2');
  }

  /**
   * @memberof common.StandardUtils
   * @description Rounds the number to selected decimals.
   * @param {number} number Number to round.
   * @param {number} decimals Decimals to round the number to.
   * @return {number}
   */
  function round(number, decimals) {
    return Number(Math.round(number + 'e' + decimals) + 'e-' + decimals);
  }

  /**
   * @memberof common.StandardUtils
   * @description Returns address to query for user image
   * @param {Object} item Item containing info for address formation.
   * @param {String} size size of the image sm, md, lg or original.
   * @return {string}
   */
  function getUserImageUrl(item, size) {
    if (!_.isObject(item)) {
      return;
    }
    if (item.picture) {
      if (!size) {
        size = 'md';
      }
      return `${utilService.getApiAccount}/fileup/image/${item.picture}?size=${size}`;
    }
  }

  /**
   * @memberof common.StandardUtils
   * @description Returns a readable format of time
   * @param {Object} timeObject Object containing info of the date to be transformed to readable time.
   * @return {string}
   */
  function readableTimeObjectSince(timeObject) {
    // mongo has 1-based month indexes

    var internalTime;
    internalTime = timeObject;

    var time = Math.floor((Date.now() - internalTime) / 1000);
    var weeks = 0;
    var days = 0;
    var hours = 0;
    var minutes = 0;
    var seconds = 0;
    var stringOfTime = '';

    if (Math.floor(time / 604800) > 0) {
      weeks = Math.floor(time / 604800);
      time -= 604800 * weeks;
    }
    if (Math.floor(time / 86400) > 0) {
      days = Math.floor(time / 86400);
      time -= 86400 * days;
    }
    if (Math.floor(time / 3600) > 0) {
      hours = Math.floor(time / 3600);
      time -= 3600 * hours;
    }
    if (Math.floor(time / 60) > 0) {
      minutes = Math.floor(time / 60);
      time -= 60 * minutes;
    }
    if (time > 0) {
      seconds += time;
    }

    stringOfTime =
      stringOfTime +
      (weeks > 0 ? weeks + ' ' + gettext('week') : '') +
      (weeks > 1 ? gettext('s') : '');
    stringOfTime =
      stringOfTime +
      (stringOfTime.length > 0 ? ' ' : '') +
      (days > 0 ? days + ' ' + gettext('day') : '') +
      (days > 1 ? gettext('s') : '');
    if (days < 1 && weeks < 1) {
      stringOfTime =
        stringOfTime +
        (stringOfTime.length > 0 ? ' ' : '') +
        (hours > 0 ? hours + ' ' + gettext('hour') : '') +
        (hours > 1 ? gettext('s') : '');
      stringOfTime =
        stringOfTime +
        (stringOfTime.length > 0 ? ' ' : '') +
        (minutes > 0 ? minutes + ' ' + gettext('minute') : '') +
        (minutes > 1 ? gettext('s') : '');
      if (hours < 1) {
        stringOfTime =
          stringOfTime +
          (stringOfTime.length > 0 ? ' ' : '') +
          (seconds > 0 ? seconds + ' ' + gettext('second') : '') +
          (seconds > 1 ? gettext('s') : '');
      }
    }

    if (stringOfTime === '') {
      stringOfTime = gettext('less than a second');
    }

    return stringOfTime;
  }

  /**
   * @memberof common.StandardUtils
   * @description Returns object containing split info oh how many years, months, etc... a certain amount of seconds represents
   * @param {number} sec_num Number of seconds to be transformed
   * @param {boolean} max_year Should years and months be included?
   * @return {Object}
   */
  function objectTimeOutOfSeconds(sec_num, max_year) {
    var years;
    var months;
    var days;
    var hours;
    var minutes;
    var seconds;
    if (max_year) {
      years = Math.floor(sec_num / 3600 / 24 / 31 / 12) || 0;
      months = Math.floor(sec_num / 3600 / 24 / 31) - years * 12 || 0;
      days =
        Math.floor(sec_num / 3600 / 24) - months * 31 - years * 12 * 31 || 0;
      hours =
        Math.floor(sec_num / 3600) -
          days * 24 -
          months * 31 * 24 -
          years * 12 * 31 * 24 || 0;
      minutes =
        Math.floor(
          (sec_num - hours * 3600) / 60 -
            days * 24 * 60 -
            months * 31 * 24 * 60 -
            years * 12 * 31 * 24 * 60
        ) || 0;
      seconds =
        sec_num -
          hours * 3600 -
          minutes * 60 -
          days * 24 * 3600 -
          months * 31 * 24 * 3600 -
          years * 12 * 31 * 24 * 3600 || 0;
    } else {
      days = Math.floor(sec_num / 3600 / 24) || 0;
      hours = Math.floor(sec_num / 3600) - days * 24 || 0;
      minutes = Math.floor((sec_num - hours * 3600) / 60) - days * 24 * 60 || 0;
      seconds = sec_num - hours * 3600 - minutes * 60 - days * 24 * 3600 || 0;
    }
    return {
      years: years,
      months: months,
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
    };
  }

  /**
   * @memberof common.StandardUtils
   * @description Returns a readable format of time
   * @param {number} seconds Number of seconds to be transformed
   * @param {boolean} max_year Should years and months be included?
   * @return {string}
   */
  function readableExactTimeOutOfSeconds(seconds, max_year) {
    const timeObject = objectTimeOutOfSeconds(seconds, max_year);
    const yearString = timeObject.years
      ? gettextCatalog.getPlural(
        timeObject.years,
        '1 year',
        '{{years}} years',
        { years: timeObject.years }
      )
      : '';
    const monthString = timeObject.months
      ? gettextCatalog.getPlural(
        timeObject.months,
        '1 month',
        '{{months}} months',
        { months: timeObject.months }
      )
      : '';
    const daysString = timeObject.days
      ? gettextCatalog.getPlural(timeObject.days, '1 day', '{{days}} days', {
        days: timeObject.days
      })
      : '';
    const hoursString = timeObject.hours
      ? gettextCatalog.getPlural(
        timeObject.hours,
        '1 hour',
        '{{hours}} hours',
        { hours: timeObject.hours }
      )
      : '';
    const minutesString = timeObject.minutes
      ? gettextCatalog.getPlural(
        timeObject.minutes,
        '1 minute',
        '{{minutes}} minutes',
        { minutes: timeObject.minutes }
      )
      : '';
    const secondsString = timeObject.seconds
      ? gettextCatalog.getPlural(
        timeObject.seconds,
        '1 second',
        '{{seconds}} seconds',
        { seconds: Math.round(timeObject.seconds) }
      )
      : '';

    return `${yearString} ${monthString} ${daysString} ${hoursString} ${minutesString} ${secondsString}`;
  }

  return {
    getFactor: getFactor,
    setDisplayLastNHoursConfiguration: setDisplayLastNHoursConfiguration,
    queryStringToJSON: queryStringToJSON,
    getLinearFunction: getLinearFunction,
    getUserImageUrl: getUserImageUrl,
    round: round,
    readableTimeObjectSince: readableTimeObjectSince,
    objectTimeOutOfSeconds: objectTimeOutOfSeconds,
    readableExactTimeOutOfSeconds: readableExactTimeOutOfSeconds
  };
}
