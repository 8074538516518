module.exports = {
  entity: 'location-action-statuses',
  path: 'location-action-statuses',
  name: {
    en: 'Location action statuses',
    sl_SI: 'Statusi akcij lokacij'
  },
  networkModel: {
    entity: 'location-action-statuses',
    read: 'read'
  },
  scope: 'tis_be:location-action-statuses',

  dialog: {
    title: {
      en: 'Select location action status',
      sl_SI: 'Izberite status akcij lokacije'
    },
    listInfo: {
      en: 'Select location action status from the list below',
      sl_SI: 'Izberite status akcij lokacije s spodnjega seznama'
    }
  }
}
