NewDomainConfigurationController.$inject = [
  'ListFormService',
  '$stateParams',
  'gettext',
  'createOrUpdateService',
  'AlertingService',
  'utilService',
  'SfeHeader',
  'domain',
  '$timeout',
  'MetadataService'
];

function NewDomainConfigurationController(
  ListFormService,
  $stateParams,
  gettext,
  createOrUpdateService,
  AlertingService,
  utilService,
  SfeHeader,
  domain,
  $timeout,
  MetadataService
) {
  const vm = this;
  const domainId = $stateParams.id;
  vm.editMode = domainId ? true : false;
  init();

  function init() {
    vm.sendingRequest = true;
    const title = vm.editMode
      ? gettext('Update domain')
      : gettext('Create domain');

    if (domain != null) {
      $timeout(() => {
        MetadataService.Loading(false);
        MetadataService.ChangeMetadata(
          'Edit ' + domain.name,
          domain.description
        );
      });
    }
    vm.dataConfig = {
      header: createHeader(domainId, title),
      data: ListFormService.generateForm(
        ['name', 'description', 'collectionFriendlyName'],
        false
      ),
      action: {
        ctrlFn: true,
        title: vm.editMode ? gettext('Update') : gettext('Create'),
        fn: createOrUpdate
      }
    };
    const dataObj = getDomainData(domainId);
    vm.dataConfig = {
      ...vm.dataConfig,
      dataObj
    };
    vm.sendingRequest = false;
  }

  function getDomainData() {
    if (domainId) {
      return domain;
    } else {
      return {
        name: '',
        description: '',
        collectionFriendlyName: '',
        catalog: utilService.getCatalogId()
      };
    }
  }

  // create and update functions //
  async function createOrUpdate() {
    vm.sendingRequest = true;
    let dataToSave = {
      ...vm.dataConfig.dataObj
    };
    try {
      await createOrUpdateService.simpleCall(
        {
          entity: 'domains',
          method: vm.editMode ? 'update' : 'create'
        },
        vm.editMode,
        vm.editMode ? domainId : null,
        dataToSave,
        'configurations-dynamic-attributes-domains-view',
        'id'
      );
      vm.sendingRequest = false;
    } catch (err) {
      vm.sendingRequest = false;
      AlertingService.Error(err);
    }
  }
  function createHeader(id, title) {
    if (id) {
      return SfeHeader.constructFormHeader(
        'primary',
        title,
        'configurations-dynamic-attributes-domains-view',
        { id }
      );
    }
    return SfeHeader.constructFormHeader(
      'primary',
      title,
      'configurations-dynamic-attributes-domains-list',
      {}
    );
  }
}
export default NewDomainConfigurationController;
