import routes from './data.routes';

// components
import sfeMappingRule from './components/sfe-mapping-rule/sfe-mapping-rule.component';
import mtAnalysisDashboardCard from './components/mt-analysis-dashboard-card/mt-analysis-dashboard-card.component';
import tangoTimeSeriesConfigurations from './components/tango-time-series-configurations/tango-time-series-configurations.component';
import tangoVariableViewer from './components/tango-variable-viewer/tango-variable-viewer.component';
import tangoMtAnalysisFlowChart from './components/tango-mt-analysis-flow-chart/tango-mt-analysis-flow-chart.component';
import tangoTimeseriesGroupForm from './components/tango-timeseries-group-form/tango-timeseries-group-form.component';
import tangoTimeseriesGroupEditor from './components/tango-timeseries-group-editor/tango-timeseries-group-editor.component';
import tangoTimeseriesGroupSandbox from './components/tango-timeseries-group-sandbox/tango-timeseries-group-sandbox.component';
import tangoTimeseriesGroupFiltration from './components/tango-timeseries-group-filtration/tango-timeseries-group-filtration.component';
import tangoTimeSeriesStatistics from './components/tango-time-series-statistics/tango-time-series-statistics.component';
import sfeMtAnalysis from './components/sfe-mt-analysis/sfe-mt-analysis.component';
import regularManualInput from './components/regular-manual-input/regular-manual-input.component';
import irregularManualInput from './components/irregular-manual-input/irregular-manual-input.component';
// controllers
import SchedulerController from './pages/scheduler/scheduler.controller';
import NewSchedulerFormController from './pages/scheduler/new-scheduler.controller';
import ManualInputController from './pages/manual-input/manual-input.controller';
import ManualInputFormController from './pages/manual-input-form/manual-input-form.controller';
import TimeSeriesGroupSandboxController from './pages/time-series-groups/time-series-group-sandbox.controller';
import NewTimeSeriesController from './pages/time-series/new-time-series.controller';

// directives
import sfeRuleAlarms from './directives/sfe-rule-alarms/sfe-rule-alarms.directive';

// services
import MtValues from './services/mt-values.service';
import Refreshing from './services/Refreshing.service';
import TangoTimeSeriesConfigurationsTemplate from './components/tango-time-series-configurations/tango-time-series-configurations-templates.constant';
import ExternalReaderTangoProperties from './services/TimeSeriesConfigurations/ExternalReaderTangoProperties.service';
import ExternalWriterTangoProperties from './services/TimeSeriesConfigurations/ExternalWriterTangoProperties.service';
import CalculatedFlowTimeSeriesConfigurations from './services/TimeSeriesConfigurations/CalculatedFlowTimeSeriesConfigurations.service';
import CalculatedFlowattributesTimeSeriesConfigurations from './services/TimeSeriesConfigurations/CalculatedFlowAttributesTimeSeriesConfigurations.service';
import MaintenanceFlowTimeSeriesConfigurations from './services/TimeSeriesConfigurations/MaintenanceFlowTimeSeriesConfigurations.service';
import VariableViewerConfigurations from './services/TimeSeriesConfigurations/VariableViewerConfigurations.service';
import ExternalWriterFlowFormConfigurations from './components/tango-time-series-configurations/form-configurations/external-writer-flow-form-configurations.service';
import ExternalReaderFlowFormConfigurations from './components/tango-time-series-configurations/form-configurations/external-reader-flow-form-configurations.service';
import MtAnalysisFlowFormConfigurations from './components/tango-time-series-configurations/form-configurations/mt-analysis-flow-form-configurations.service';
import MaintenanceFlowFormConfigurations from './components/tango-time-series-configurations/form-configurations/maintenance-flow-form-configurations.service';
import CalculatedFlowFormConfigurations from './components/tango-time-series-configurations/form-configurations/calculated-flow-form-configurations.service';
import AddFlowConfiguration from './components/tango-time-series-configurations/form-configurations/add-flow-configuration.service';
import TimeSeriesFlowHelper from './components/tango-time-series-configurations/form-configurations/time-series-flows-form.helper';
import BaseLineTargetFunctionService from './services/base-line-target-function-dialog/base-line-target-function.service';
import NewTimeSeriesValueDialogService from './services/new-time-series-value-dialog/new-time-series-value.service';
import TimeSeriesRecalculateService from './services/time-series-recalculate.service';
import TimeSeriesProcessingExtendedValuesByTimestampDialogService from './services/time-series-processing-extended-values-by-timestamp/time-series-processing-extended-values-by-timestamp.service';
import TangoTimeSeriesDataXHelperService from './services/TangoTimeSeriesDataXHelper.service';
import TimeSeriesLastValueService from './services/time-series-last-value.service';
import MtAnalysisFlowTimeSeriesConfigurations from './services/TimeSeriesConfigurations/MtAnalysisFlowTimeSeriesConfigurations.service';
import ShareTimeSeriesGroupService from './components/tango-timeseries-group-editor/share-time-series-group.service';
import PrivilegeManagementDialog from './components/privilege-management-dialog/PrivilegeManagementDialog.service';
import sfeMtAnalysisService from './components/sfe-mt-analysis/sfe-mt-analysis.service';
import ManualInputFormHelper from './pages/manual-input-form/manualInputForm.helper';

// configurations
import databaseConfiguration from './configurations/database.configuration';
import fileConfiguration from './configurations/file.configuration';
import ManualInputConfiguration from './configurations/manual-input.configuration';
import mappingRuleConfiguration from './configurations/mapping-rule.configuration';
import TimeSeriesManualInputConfiguration from './configurations/time-series-manual-input.configuration';
import opcConfiguration from './configurations/opc.configuration';
import ruleConfiguration from './configurations/rule.configuration';
import schedulerConfiguration from './configurations/scheduler.configuration';
import webserviceConfiguration from './configurations/webservice.configuration';
import BaseLineConfiguration from './configurations/base-line.configuration';
import TimeSeriesConfiguration from './configurations/time-series.configuration';
import partialScheduleClassificationConfiguration from './configurations/partial-configuration/partial-schedule-classification.configuration';
import DataVisualizationConfiguration from './configurations/data-visualization.configuration';
import TimeSeriesGroupConfiguration from './configurations/time-series-groups.configuration';
import BoundariesConfiguration from './configurations/boundaries.configuration';
import SyncExternalDatasourceValues from './configurations/sync-external-datasource-values.configuration';
// network models
import AlarmRuleTargetForXAxisIntervalModel from './network-models/AlarmRuleTargetForXAxisInterval.model';
import MeasurementDatapointModel from './network-models/MeasurementDatapoint.model';
import TimeSeriesManualInputFormModel from './network-models/TimeSeriesManualInputForm.model';
import TimeSeriesManualInputFormGroupModel from './network-models/TimeSeriesManualInputFormGroup.model';
import TimeSeriesManualInputFormGroupTimeSeriesItemModel from './network-models/TimeSeriesManualInputFormGroupTimeSeriesItem.model';
import RuleTargetForXAxisIntervalModel from './network-models/RuleTargetForXAxisInterval.model';
import SchedulerModel from './network-models/Scheduler.model';
import TargetForXAxisIntervalModel from './network-models/TargetForXAxisInterval.model';
import XAxisIntervalModel from './network-models/XAxisInterval.model';
import CalculatedFlowModel from './network-models/CalculatedFlow.model';
import ExternalReaderFlowModel from './network-models/ExternalReaderFlow.model';
import ExternalWriterFlowModel from './network-models/ExternalWriterFlow.model';
import MaintenanceFlowModel from './network-models/MaintenanceFlow.model';
import TimeSeriesModel from './network-models/TimeSeries.model';
import TimeSeriesProcessingValuesModel from './network-models/TimeSeriesProcessingValues.model';
import TimeSeriesGroupModel from './network-models/TimeSeriesGroup.model';
import TimeSeriesConfigurationModel from './network-models/TimeSeriesConfiguration.model';
import MtAnalysisFlowModel from './network-models/MtAnalysisFlow.model';
import TimeSeriesTypeModel from './network-models/TimeSeriesType.model';
import TimeSeriesGroupTypeModel from './network-models/TimeSeriesGroupType.model';
import TimeSeriesProcessingTriggerCalculationModel from './network-models/TimeSeriesProcessingTriggerCalculation.model';
import TimeSeriesProcessingExtendedValuesByTimestampModel from './network-models/TimeSeriesProcessingExtendedValuesByTimestamp.model';
//TANGO ITEM CONFIGURATIONS
import HeaderTimeSeriesConfiguration from './tango-item-configurations/time-series/HeaderTimeSeries.configuration';
import TopPropertiesTimeSeriesConfiguration from './tango-item-configurations/time-series/TopPropertiesTimeSeries.configuration';

import PropertiesTimeSeriesConfiguration from './tango-item-configurations/time-series/PropertiesTimeSeries.configuration';
import ContentTimeSeriesConfiguration from './tango-item-configurations/time-series/ContentTimeSeries.configuration';
import DetailTimeSeriesConfiguration from './tango-item-configurations/time-series/DetailTimeSeries.configuration';

import PersonResponsibleTimeSeriesFormConfiguration from './tango-item-configurations/time-series/form/PersonResponsibleTimeSeriesForm.configuration';
import CommonPropertiesTimeSeriesFormConfiguration from './tango-item-configurations/time-series/form/CommonPropertiesTimeSeriesForm.configuration';
import RulesAndAlarmsTimeSeriesFormConfiguration from './tango-item-configurations/time-series/form/RulesAndAlarmsTimeSeriesForm.configuration';
import ScheduleClassificationFormConfiguration from './components/tango-timeseries-group-sandbox/schedule-classification-form.configuration';

//TANGO PROPERTIES
import SchedulerTangoProperties from './tango-properties/scheduler-tango-properties.service';

//MIDDLEWARE
import TimeSeriesMiddleware from './redux/time-series/middleware/times-series.middleware';
import TimeSeriesConfigurationMiddleware from './redux/time-series-configurations/middleware/times-series-configurations.middleware';
import ExternalReaderFlowMiddleware from './redux/external-reader-flow/middleware/external-reader-flow.middleware';
import ExternalWriterFlowMiddleware from './redux/external-writer-flow/middleware/external-writer-flow.middleware';
import CalculatedFlowMiddleware from './redux/calculated-flow/middleware/calculated-flow.middleware';
import MtAnalysisFlowMiddleware from './redux/mt-analysis-flow/middleware/mt-analysis-flow.middleware';
import MaintenanceFlowMiddleware from './redux/maintenance-flow/middleware/maintenance-flow.middleware';

import TangoTimeseriesGroupSandboxHelperService from './components/tango-timeseries-group-sandbox/tango-timeseries-group-sandbox-helper.service';
import TangoTimeseriesGroupEditorHelperService from './components/tango-timeseries-group-editor/tango-timeseries-group-editor-helper.service';
export default {
  routes,
  components: {
    sfeMappingRule,
    mtAnalysisDashboardCard,
    tangoTimeSeriesConfigurations,
    tangoVariableViewer,
    tangoMtAnalysisFlowChart,
    tangoTimeseriesGroupForm,
    tangoTimeseriesGroupEditor,
    tangoTimeseriesGroupSandbox,
    tangoTimeseriesGroupFiltration,
    tangoTimeSeriesStatistics,
    sfeMtAnalysis,
    regularManualInput,
    irregularManualInput
  },
  controllers: {
    SchedulerController,
    NewSchedulerFormController,
    ManualInputController,
    ManualInputFormController,
    TimeSeriesGroupSandboxController,
    NewTimeSeriesController
  },
  directives: {
    sfeRuleAlarms
  },
  services: {
    TangoTimeSeriesDataXHelperService,
    TimeSeriesLastValueService,
    MtValues,
    Refreshing,
    AlarmRuleTargetForXAxisIntervalModel,
    MeasurementDatapointModel,
    TimeSeriesManualInputFormModel,
    TimeSeriesManualInputFormGroupModel,
    TimeSeriesManualInputFormGroupTimeSeriesItemModel,
    RuleTargetForXAxisIntervalModel,
    SchedulerModel,
    TargetForXAxisIntervalModel,
    XAxisIntervalModel,
    CalculatedFlowModel,
    ExternalReaderFlowModel,
    ExternalWriterFlowModel,
    MaintenanceFlowModel,
    TimeSeriesModel,
    TimeSeriesProcessingValuesModel,
    TimeSeriesGroupModel,
    HeaderTimeSeriesConfiguration,
    TopPropertiesTimeSeriesConfiguration,
    PropertiesTimeSeriesConfiguration,
    ContentTimeSeriesConfiguration,
    DetailTimeSeriesConfiguration,
    SchedulerTangoProperties,
    TimeSeriesConfigurationModel,
    MtAnalysisFlowModel,
    TimeSeriesMiddleware,
    PersonResponsibleTimeSeriesFormConfiguration,
    TimeSeriesConfigurationMiddleware,
    ExternalReaderFlowMiddleware,
    TangoTimeSeriesConfigurationsTemplate,
    ExternalReaderTangoProperties,
    ExternalWriterTangoProperties,
    ExternalWriterFlowMiddleware,
    CalculatedFlowMiddleware,
    CalculatedFlowTimeSeriesConfigurations,
    CalculatedFlowattributesTimeSeriesConfigurations,
    MtAnalysisFlowMiddleware,
    VariableViewerConfigurations,
    MaintenanceFlowMiddleware,
    MaintenanceFlowTimeSeriesConfigurations,
    CommonPropertiesTimeSeriesFormConfiguration,
    TimeSeriesTypeModel,
    TimeSeriesGroupTypeModel,
    TimeSeriesProcessingTriggerCalculationModel,
    ExternalWriterFlowFormConfigurations,
    ExternalReaderFlowFormConfigurations,
    MtAnalysisFlowFormConfigurations,
    MaintenanceFlowFormConfigurations,
    CalculatedFlowFormConfigurations,
    RulesAndAlarmsTimeSeriesFormConfiguration,
    ScheduleClassificationFormConfiguration,
    partialScheduleClassificationConfiguration,
    TangoTimeseriesGroupSandboxHelperService,
    TangoTimeseriesGroupEditorHelperService,
    AddFlowConfiguration,
    TimeSeriesFlowHelper,
    DataVisualizationConfiguration,
    TimeSeriesGroupConfiguration,
    BoundariesConfiguration,
    TimeSeriesProcessingExtendedValuesByTimestampModel,
    SyncExternalDatasourceValues,
    MtAnalysisFlowTimeSeriesConfigurations,
    ShareTimeSeriesGroupService,
    sfeMtAnalysisService,
    ManualInputFormHelper,
    PrivilegeManagementDialog
  },
  factories: {
    databaseConfiguration,
    fileConfiguration,
    ManualInputConfiguration,
    mappingRuleConfiguration,
    TimeSeriesManualInputConfiguration,
    opcConfiguration,
    ruleConfiguration,
    schedulerConfiguration,
    webserviceConfiguration,
    BaseLineConfiguration,
    TimeSeriesConfiguration,
    BaseLineTargetFunctionService,
    NewTimeSeriesValueDialogService,
    TimeSeriesRecalculateService,
    TimeSeriesProcessingExtendedValuesByTimestampDialogService
  }
};
