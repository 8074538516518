LocationTypeConfigController.$inject = [
  '$q',
  'MetadataService',
  '$stateParams',
  'gettext',
  'locationType',
  '$timeout'
];

function LocationTypeConfigController(
  $q,
  MetadataService,
  $stateParams,
  gettext,
  locationType,
  $timeout
) {
  const vm = this;
  const locationTypeId = $stateParams.id;

  vm.$onInit = async function init() {
    vm.loading = true;
    initiateAttributesTable();
    vm.headerData = constructHeader(locationType);
    $timeout(() => {
      MetadataService.ChangeMetadata(
        locationType.name,
        locationType.description
      );
    });
    vm.loading = false;
  };

  function constructHeader(locationType) {
    const properties = [
      {
        title: gettext('Code'),
        value: locationType.code,
        type: 'code'
      },
      {
        title: gettext('External'),
        value: locationType.isExternal,
        type: 'radio',
        customOptions: ['YES', 'NO']
      }
    ];

    if (locationType.externalCode) {
      properties.push({
        title: gettext('External code'),
        value: locationType.externalCode,
        type: 'code'
      });
    }
    const propertySections = [
      {
        properties: properties
      }
    ];
    const actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: locationType._id,
        endpoint: {
          entity: 'location-types',
          method: 'delete'
        },
        redirectState: 'configurations-company-resources-locations-list'
      },
      {
        title: gettext('Update'),
        state: 'configurations-company-resources-locations-location-types-edit',
        param: 'id',
        paramValue: locationTypeId
      }
    ];
    const metadata = {
      definition: gettext('Location type'),
      title: locationType.name,
      description: locationType.description
    };

    return {
      metadata,
      actions,
      propertySections
    };
  }
  /**
   * @description sets sfe-list-2 configuration to display entity attributes.
   * @function
   */
  function initiateAttributesTable() {
    vm.listConfig = {
      entity: {
        plural: gettext('Location type attributes')
      },
      api: {
        query: {
          entity: 'location-type-attributes',
          method: 'custom.list'
        },
        enrich: enrich
      },
      title: {
        param: 'name'
      },
      attributes: [
        {
          title: gettext('Description'),
          param: 'description'
        },
        {
          title: gettext('Element type'),
          param: 'elementType'
        }
      ]
    };

    vm.filters = {
      type: locationTypeId
    };
  }

  function enrich(item) {
    var pr = $q.defer();
    if (
      item.hasOwnProperty('domainAttribute') &&
      item.domainAttribute !== null
    ) {
      item.name = item.domainAttribute.name;
      item.description = item.domainAttribute.description;
      item.elementType = item.domainAttribute.elementType;
    }
    pr.resolve();
    return pr.promise;
  }
}

export default LocationTypeConfigController;
