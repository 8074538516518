import template from '../views/rule-viewer.dialog.html';

/**
 * @ngdoc service
 * @name common.RuleViewer
 * @description Opens rule viewer dialog.
 * @property {anonymousFunction} open - opens dialog
 */
RuleViewerDialog.$inject = ['$mdDialog'];

export default function RuleViewerDialog($mdDialog) {
  /**
   * @memberof common.RuleViewerDialog
   * @function
   * @description Opens rule view dialog.
   */
  function open(rule) {
    return $mdDialog.show({
      controller: RuleViewerController,
      controllerAs: 'vm',
      template,
      parent: angular.element(document.body),
      preserveScope: true,
      locals: {
        rule
      }
    });
  }

  return {
    open
  };
}

RuleViewerController.$inject = ['rule', 'gettextCatalog'];
function RuleViewerController(rule, gettextCatalog) {
  const vm = this;

  vm.$onInit = init;

  function init() {
    vm.header = {
      title: gettextCatalog.getString('Rule - {{ruleName}}', {
        ruleName: rule.name
      }),
      isDialog: true,
      actions: [
        {
          icon: {
            name: 'close',
            type: 2
          },
          cancel: true
        }
      ]
    };

    vm.previewExpression = rule.expression
      .replace(/true/g, 'FAIL')
      .replace(/false/g, 'SUCCESS');
    vm.variables = rule.inputParams;
  }
}
