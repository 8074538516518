import './sfe-actions.scss';
import template from './sfe-actions.component.html';

/**
 * @ngdoc component
 * @name common.sfeActions
 * @description Forms a list of action buttons
 * @param {boolean} condensed  - tells us if the buttons should be bunched up together
 * @param {boolean} usedAsTabs - tells us if button behaves like a tab and highlights it when selected
 * @param {string} anchorTo - tells us if content is aligned to the left or right (right is default)
 * @param {Array} actions - Array of specified actions to be displayed
 * Example actions configuration:
 * {
 *   // if an action has only icon it will render it as an icon button
 *   icon: {
 *      type: 2 // types that sfe-icon accepts from the icons codelist
 *      name: 'close'
 *   }
 *   // text displayed on a button
 *   title: 'A disabled white button with a submenu and a grey border',
 *   // either primary, accent, success, warn, grey or black
 *   color: 'warn',
 *    // makes a button be either white colored or on
 *    // white background depending on raised status
 *   whiteButton: true
 *    // makes a button have white background if not raised
 *   opaqueButton: true
 *    // overrides border color to be grey in all cases but md-icon-button
 *   greyBorder: true,
 *   link: 'data-time-series-list',
 *   disabled: true,
 *   disabledFn: () => { return true; }
 *    // creates a submenu for an action - items in array have the same
 *    //configuration as other actions minus items array - submenu
 *    //items cannot have anothother sumbmenu
 *   menuWidth: 5,
 *   items: [
 *     {
 *       title: 'item1',
 *       fn: () => { ... },
 *       icon: {
 *         name: 'directions',
 *         type: 2
 *       },
 *     },
 *     {
 *       title: 'item2',
 *       fn: () => { ... },
 *       disabledFn: () => { ... }
 *     }
 *   ]
 * }
 *
 * @example
 * <sfe-actions
 *   actions="vm.actions"
 *   condensed="true"
 *   used-as-tabs="false"
 *   anchor-to="left"
 * ></sfe-actions
 */

export default {
  template,
  bindings: {
    actions: '<',
    condensed: '<',
    usedAsTabs: '<',
    anchorTo: '@'
  },
  controllerAs: 'vm',
  controller: sfeActionsController
};

sfeActionsController.$inject = [
  'InfoDialog',
  '$mdDialog',
  'gettext',
  '$window',
  '$element',
  '$scope',
  '$timeout'
];

function sfeActionsController(
  InfoDialog,
  $mdDialog,
  gettext,
  $window,
  $element,
  $scope,
  $timeout
) {
  let vm = this;
  vm.isCondensed = false;
  vm.execute = execute;
  vm.validActions = [];
  vm.validAnchorTo = 'end';
  vm.errorNoActions = true; // dont render anything

  // re-render every change
  vm.$onChanges = () => {
    render();
    $timeout(toggleCondensed);
  };

  // resize listeners
  vm.$onDestroy = () => {
    window.angular.element($window).off('resize', toggleCondensed);
  };
  vm.$onInit = () => {
    window.angular.element($window).on('resize', toggleCondensed);
  };

  /**
   * @description generate test id for data-e2e attribute.
   * @function
   * @param {Object} action
   * @return {string}
   */
  const generateTestId = action => {
    let testId;
    if (action != null) {
      if (action.testId == null || typeof action.testId !== 'string') {
        const iconName = action.icon ? 'icon:' + action.icon.name + ' ' : '';
        const testName = `${iconName}${action.title}`;
        testId = testName.toLowerCase();
      } else {
        testId = action.testId.toLowerCase();
      }
    }
    return testId;
  };

  const render = () => {
    if (vm.actions != null) {
      vm.errorNoActions = false;

      let allowedToBeRaised = null;
      if (vm.usedAsTabs) {
        allowedToBeRaised = vm.actions.find((action, index) => {
          if (action.raised) {
            return index;
          }
        });
        if (allowedToBeRaised == null) {
          allowedToBeRaised = 0;
        }
      }
      if (vm.anchorTo != null && vm.anchorTo == 'left') {
        vm.validAnchorTo = 'start';
      } else {
        vm.validAnchorTo = 'end';
      }
      // validate actions
      vm.validActions = vm.actions.reduce((validActions, action, index) => {
        let subItems = [];
        // check action validity
        if (isActionValid(action)) {
          action.menuWidth =
            typeof action.menuWidth === 'number' ? action.menuWidth : 4;
          if (action.items) {
            // if action has sub actions, check their validity
            subItems = action.items.reduce((subActions, subAction) => {
              if (isActionValid(subAction)) {
                subAction.testId = generateTestId(subAction);
                return [
                  ...subActions,
                  {
                    ...subAction,
                    cssClasses: getActionClass(subAction, action)
                  }
                ];
              } else return subActions;
            }, []);
          }
          action.testId = generateTestId(action);
          // result
          validActions = [
            ...validActions,
            {
              ...action,
              subItems,
              raised: !!action.raised,
              cssClasses: getActionClass(
                action,
                null,
                index,
                allowedToBeRaised,
                vm.validAnchorTo
              )
            }
          ];
        }
        return validActions;
      }, []);
    } else {
      vm.errorNoActions = true;
    }
  };

  /**
   * @description Get all css classes for an action.
   * @function
   * @param {object} action
   * @param {object} parentAction
   * @param {object} index
   * @param {object} allowedToBeRaised - if vm.usedAsTabs == true, this will hold the raised action's index
   * @param {string} position
   * @return {string}
   */
  const getActionClass = (
    action,
    parentAction,
    index,
    allowedToBeRaised,
    position
  ) => {
    let cssClasses = [];

    // ASSIGN THEME COLOR
    if (parentAction != null && action.color == null) {
      cssClasses.push(getActionColor(parentAction.color));
    } else {
      cssClasses.push(getActionColor(action.color));
    }

    // ASSIGN RAISED STATUS - check used as tabs signal: allowedToBeRaised
    if (
      (allowedToBeRaised == null && action.raised != null) ||
      (allowedToBeRaised != null && index == allowedToBeRaised)
    ) {
      cssClasses.push('md-raised');
    }

    // ASSIGN WHITE BACKGROUND
    if (action.whiteButton) {
      cssClasses.push('white-button');
    }

    // ASSIGN WHITE BACKGROUND
    if (action.opaqueButton) {
      cssClasses.push('opaque-button');
    }

    // if actions are not condensed and button only has icon, let it become md-icon-button
    if (!vm.condensed && action.title == null) {
      cssClasses.push('md-icon-button');
    }

    // ASSIGN SUBMENU STATUS
    if (Array.isArray(action.items) && action.items.length > 0) {
      cssClasses.push('--hasSubmenu');
    }

    // ASSIGN ONLY TEXT or ONLY ICON
    if (action.title == null && action.icon != null) {
      cssClasses.push('--onlyIcon');
    } else if (action.title != null && action.icon == null) {
      cssClasses.push('--onlyText');
    }

    if (action.greyBorder === true) {
      cssClasses.push('--grey-border');
    }
    if (position === 'start') {
      cssClasses.push('left-aligned');
    } else {
      cssClasses.push('right-aligned');
    }
    return cssClasses;
  };

  /**
   * @description Get action color class based on theme.
   * @function
   * @param {object} action
   * @param {string} theme
   * @return {string}
   */
  const getActionColor = theme => {
    let color = 'primary';
    const validColors = {
      primary: 'md-primary',
      accent: 'md-accent',
      success: 'md-success',
      warn: 'md-warn',
      grey: 'md-grey',
      black: 'md-black'
    };

    if (theme && validColors[theme] != null) {
      color = validColors[theme];
    }

    return color;
  };

  /**
   * @description Validate a single action object.
   * @function
   * @param {object} action
   * @return {boolean}
   */
  const isActionValid = action => {
    let validity = true;

    if (action.title == null && action.icon == null) {
      validity = false;
    } else if (
      action.fn == null &&
      action.link == null &&
      action.cancel == null &&
      action.items == null &&
      action.confirmation == null
    ) {
      validity = false;
    } else if (action.fn != null && typeof action.fn != 'function') {
      validity = false;
    } else if (action.link != null && typeof action.link != 'string') {
      validity = false;
    }

    return validity;
  };

  /**
   * @description Executes the action. Action can have the cancel attribute set to true - in this case it just closes the dialog. Supports confirmations.
   * @function
   * @param {Object} action Definition of the action to be executed
   */
  function execute(action, $mdMenu, ev, index) {
    if (action.link) return; // if link, it will route it away
    if (action.cancel) {
      // for cancel buttons
      $mdDialog.cancel();
    } else if (action.confirmation) {
      // if action of the button triggers a confirmation dialog
      confirmationAction(action.confirmation);
    } else if (action.fn) {
      // execute function
      action.fn(ev);
      if (vm.usedAsTabs) {
        // toggle md-raised class for selected index and remove from others
        vm.validActions = vm.validActions.map((action, validActionIndex) => {
          let mdRaisedClassIndex = action.cssClasses.indexOf('md-raised');
          if (validActionIndex == index && mdRaisedClassIndex == -1) {
            // raise button clicked
            action.cssClasses.push('md-raised');
          } else if (validActionIndex != index && mdRaisedClassIndex >= 0) {
            // lower button not clicked
            action.cssClasses.splice(mdRaisedClassIndex, 1);
          }
          return action;
        });
      }
    } else if (Array.isArray(action.items) && action.items.length > 0) {
      // if action has no fn, nor link, but has subitems then open dropdown
      $mdMenu.open(ev);
    }
  }

  /**
   * @description Confirmation modal - special action.
   * @function
   * @param {object} action
   * @return {dataType}
   */
  function confirmationAction(action) {
    var title = action.confirmation.title;
    var content = {
      text: action.confirmation.content,
      type: 'text'
    };
    var actions = [
      {
        title: gettext('Cancel'),
        fn: () => {
          $mdDialog.cancel();
        },
        color: 'primary'
      },
      {
        title: gettext('Ok'),
        fn: () => {
          action.fn();
          $mdDialog.cancel();
        },
        color: 'warn'
      }
    ];
    InfoDialog.open(title, null, [content], actions);
  }

  /**
   * @description Checks whether condensed broke into new line and disables it.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function toggleCondensed() {
    vm.isCondensed = vm.condensed && $element[0].clientHeight < 50;
    $scope.$evalAsync();
  }

  /**
   * @description Opens submenu of an action.
   * @function
   * @param {object} action
   * @param {$mdMenu} object
   * @param {object} ev
   */
  vm.openMenu = (action, $mdMenu, ev) => {
    if (
      (action.disabled == null && action.disabledFn == null) ||
      action.link != null ||
      action.fn != null
    ) {
      $mdMenu.open(ev);
    }
  };
}
