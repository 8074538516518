SfeForm2DateFromPriorToValidationService.$inject = ['gettextCatalog'];

function SfeForm2DateFromPriorToValidationService(gettextCatalog) {
  /**
   * @description returns configuration for from <= to date validation.
   * @function
   * @param {String} from from field id
   * @param {String} to to field id
   * @return {Object}
   */
  function get(from, to) {
    return {
      /**
       * @description validates from to date is >= from date .
       * @function
       * @param {Object} api from api object
       * @return {Boolean}
       */
      fn: (_, __, api) => {
        if (api && typeof api.getValue === 'function') {
          let toValue = api.getValue(to);
          let fromValue = api.getValue(from);
          if (
            Array.isArray(toValue) &&
            toValue.length > 0 &&
            Array.isArray(fromValue) &&
            fromValue.length > 0
          ) {
            return toValue[0] >= fromValue[0];
          }
        }
        return true;
      },
      text: gettextCatalog.getString('From date must be prior valid to date')
    };
  }

  return { get };
}
export default SfeForm2DateFromPriorToValidationService;
