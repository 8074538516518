import template from './sfe-multi-text-field-select.directive.html';

export default function sfeMultiTextFieldSelect() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      options: '=?',
      models: '=?',
      configuration: '=?',
      onRemove: '<'
    },
    link: linkFn,
    controller: sfeMultiTextFieldSelectController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  function linkFn() {}
}

sfeMultiTextFieldSelectController.$inject = ['$scope', '$timeout'];

function sfeMultiTextFieldSelectController($scope, $timeout) {
  var vm = this;
  vm.addRow = addRow;
  vm.removeRow = removeRow;
  function addRow() {
    if (!vm.models) {
      vm.models = [];
    }
    vm.configuration.select.edit = false;
    vm.models.push({});
  }

  function removeRow(index) {
    vm.models.splice(index, 1);
    if (typeof vm.onRemove == 'function') {
      vm.onRemove();
    }
  }

  $timeout(function() {});
}
