reportConfiguration.$inject = ['gettext', '$q'];

function reportConfiguration(gettext, $q) {
  var configuration = {
    entity: {
      singular: gettext('Report'),
      plural: gettext('Reports')
    },
    api: {
      query: {
        entity: 'reports',
        method: 'custom.list'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'analytics-reports-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'analytics-reports-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'analytics-reports-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'reports',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Description'),
        param: 'description',
        type: 'text'
      },
      {
        title: gettext('Dataset'),
        param: 'datasetName',
        type: 'link',
        link: {
          state: 'analytics-datasets-view',
          params: [
            {
              valueParam: 'datasetId',
              queryParam: 'id'
            }
          ]
        }
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      }
    ],
    sort: [
      {
        display: gettext('created DESC'),
        params: '-_id'
      },
      {
        display: gettext('created ASC'),
        params: '_id'
      },
      {
        display: gettext('name ASC'),
        params: 'name'
      },
      {
        display: gettext('name DESC'),
        params: '-name'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    if (item.dataset) {
      item.datasetName = item.dataset.name;
      item.datasetId = item.dataset._id;
    } else {
      item.datasetName = gettext('Unknown');
    }
    return deferred.promise;
  }

  return configuration;
}

export default reportConfiguration;
