module.exports = {
  entity: 'aggregation-groups',
  path: 'aggregation-groups',
  name: {
    en: 'Aggregate group',
    sl_SI: 'Agregirana skupina'
  },
  networkModel: {
    entity: 'aggregation-groups',
    read: 'read'
  },
  selectedParamName: 'id',
  scope: 'tis_be:aggregate-groups',

  dialog: {
    title: {
      en: 'Select aggregated group',
      sl_SI: 'Izberite agregacijsko skupino'
    },
    listInfo: {
      en: 'Select aggregated group from the list below',
      sl_SI: 'Izberite agregacijsko skupino s spodnjega seznama'
    }
  }
}
