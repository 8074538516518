PhysicalDataFormConfigurationService.$inject = [
  'gettextCatalog',
  'PhysicalCollectionService',
  'TranslationService',
  'AlertingService'
];

function PhysicalDataFormConfigurationService(
  gettextCatalog,
  PhysicalCollectionService,
  TranslationService,
  AlertingService
) {
  /**
   * @description returns array of 2 sfe-form-fields that allow to select physical quantities and connected to them measurement units and metric prefixes.
   * @function
   * @param {Object} Object that contains selected physical data ids or objects
   *       @param {String|Object} physicalQuantity id or physical quantity object
   *       @param {String|Object} measurementUnit id or physical quantity object
   *       @param {Number} metricPrefix codelist id of selected metricPrefix
   * @return {dataType}
   */
  async function get(item) {
    let physicalQuantities = [];
    let measurementUnits = [];
    try {
      physicalQuantities = await PhysicalCollectionService.returnPhysicalQuantities();
      measurementUnits = await PhysicalCollectionService.returnMeasurementUnits();
    } catch (err) {
      AlertingService.Error(err);
    }
    const metricPrefixes = TranslationService.GetCollection(
      'codelists.metricPrefixes'
    );
    let selectedMeasurementUnit = null;
    let measurementUnitItems = [];
    if (typeof item == 'object' && item != null) {
      //SET SELECTED MEASUREMENT UNITS AND PREFIXES
      if (item.measurementUnit != null) {
        let metricPrefixItem;
        if (item.metricPrefix != null) {
          metricPrefixItem = metricPrefixes.find(
            prefix => prefix.id == item.metricPrefix
          );
        }
        let measurementUnitItem;
        if (typeof item.measurementUnit == 'string') {
          measurementUnitItem = measurementUnits.find(
            unit => unit._id == item.measurementUnit
          );
        } else if (typeof item.measurementUnit == 'object') {
          measurementUnitItem = item.measurementUnit;
        }
        if (measurementUnitItem != null) {
          selectedMeasurementUnit = {
            measurementUnit: measurementUnitItem,
            metricPrefix: metricPrefixItem
          };
        }
      }
      //SET MEASUREMENT UNIT AND METRIC PREFIXES OPTIONS
      if (typeof item.physicalQuantity == 'string') {
        measurementUnitItems = getMeasurementUnitAndMetricPrefixesCombinations(
          item.physicalQuantity,
          measurementUnits,
          metricPrefixes
        );
      } else if (
        typeof item.physicalQuantity == 'object' &&
        item.physicalQuantity != null
      ) {
        measurementUnitItems = getMeasurementUnitAndMetricPrefixesCombinations(
          item.physicalQuantity._id,
          measurementUnits,
          metricPrefixes
        );
      }
    }

    return [
      {
        id: 'physicalQuantity',
        title: gettextCatalog.getString('Physical quantity'),
        type: {
          name: 'autocomplete',
          options: {
            items: physicalQuantities,
            display: item => ({ text: item.name }),
            valueField: '_id'
          }
        },
        initialize: () => {
          return item != null ? item.physicalQuantity : null;
        },
        onChange: form => {
          let physicalQuantity = form.getValue('physicalQuantity');
          if (physicalQuantity == null) {
            form.setValue('measurementData', null);
          } else {
            let newItems = getMeasurementUnitAndMetricPrefixesCombinations(
              physicalQuantity._id,
              measurementUnits,
              metricPrefixes
            );
            form.setFieldConfigurationProperty(
              'measurementData',
              'type.options.items',
              newItems
            );
          }
        },
        required: false
      },
      {
        id: 'measurementData',
        title: gettextCatalog.getString('Measurement unit'),
        type: {
          name: 'autocomplete',
          options: {
            items: measurementUnitItems,
            display: item => {
              let displayString =
                item != null && typeof item.name === 'string' ? item.name : '';
              return { text: displayString };
            }
          }
        },
        disable: form => {
          let physicalQuantity = form.getValue('physicalQuantity');
          return physicalQuantity == null;
        },
        initialize: () => {
          let initMeasurementData;
          let displayItem;
          if (item != null && item.physicalQuantity != null) {
            let physicalQuantity = item.physicalQuantity._id;
            let measurementUnit = selectedMeasurementUnit
              ? [selectedMeasurementUnit.measurementUnit]
              : [];
            let metricPrefix = selectedMeasurementUnit
              ? [selectedMeasurementUnit.metricPrefix]
              : [];
            initMeasurementData = getMeasurementUnitAndMetricPrefixesCombinations(
              physicalQuantity,
              measurementUnit,
              metricPrefix
            );
            if (
              Array.isArray(initMeasurementData) &&
              initMeasurementData.length > 0
            ) {
              displayItem = initMeasurementData[0];
            }
          }
          return displayItem;
        },
        required: false
      }
    ];
  }
  /**
   * @description filters units by physicalQuantity and creates all combinations with metricPrefixes.
   * @function
   * @param {String} physicalQuantity id of physical quantity
   * @param {Array} units array of measurement units
   * @param {Array} prefixes array of metric prefixes
   * @return {dataType}
   */
  function getMeasurementUnitAndMetricPrefixesCombinations(
    physicalQuantity,
    units,
    prefixes
  ) {
    let filteredUnits = units.filter(
      unit => unit.physicalQuantity == physicalQuantity
    );
    return filteredUnits.reduce((result, unit) => {
      let unitsWithPrefixes = prefixes.map(prefix => {
        let name = `${prefix != null ? prefix.name : ''} ${unit.name} (${
          prefix != null ? prefix.symbol : ''
        } ${unit.symbol})`;
        return {
          measurementUnit: unit,
          metricPrefix: prefix,
          name
        };
      });
      return [...result, ...unitsWithPrefixes];
    }, []);
  }
  return {
    get
  };
}
export default PhysicalDataFormConfigurationService;
