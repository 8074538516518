DomainFieldsConfigurationController.$inject = [
  'AlertingService',
  '$state',
  'gettext',
  'gettextCatalog',
  'DomainModel',
  'DomainFieldModel',
  '$timeout',
  'MetadataService'
];

function DomainFieldsConfigurationController(
  AlertingService,
  $state,
  gettext,
  gettextCatalog,
  DomainModel,
  DomainFieldModel,
  $timeout,
  MetadataService
) {
  var vm = this;

  init();

  function init() {
    vm.loading = true;
    var parallel = {
      readDomain: readDomain,
      readDomainField: readDomainField
    };

    async.parallel(parallel, function(err, res) {
      if (!err) {
        var domainField = res.readDomainField;

        if (domainField != null) {
          $timeout(() => {
            MetadataService.Loading(false);
            MetadataService.ChangeMetadata(
              domainField.name,
              domainField.description
            );
          });
        }
        domainField.domain = res.readDomain;
        vm.headerData = constructHeader(domainField);
        vm.loading = false;
      }
    });
  }

  function readDomain(callback) {
    DomainModel.read({
      id: $state.params.domainId
    }).then(
      function(res) {
        callback(null, res.data);
      },
      function(err) {
        AlertingService.Error(err);
        callback();
      }
    );
  }

  function readDomainField(callback) {
    DomainFieldModel.read({
      fieldId: $state.params.fieldId,
      domainId: $state.params.domainId
    }).then(
      function(res) {
        callback(null, res.data);
      },
      function(err) {
        AlertingService.Error(err);
        callback();
      }
    );
  }

  function getfilterType(filterType) {
    switch (filterType) {
    case 'equality':
      return gettextCatalog.getString('Equality');
    case 'text':
      return gettextCatalog.getString('Text');
    default:
      return gettextCatalog.getString('Unknown');
    }
  }

  function constructHeader(domainField) {
    var metadata = [];

    if (domainField.domain && domainField.domain._id) {
      metadata.push({
        title: gettext('Domain'),
        linkTitle: domainField.domain.name,
        state: 'configurations-dynamic-attributes-domains-view',
        param: 'id',
        paramValue: domainField.domain._id,
        type: 'link'
      });
    } else {
      metadata.push({
        type: 'simple',
        title: gettext('Domain'),
        value: gettextCatalog.getString('Unknown')
      });
    }

    metadata.push(
      {
        title: gettext('Filter'),
        value: domainField.isFilter,
        type: 'radio',
        customOptions: ['YES', 'NO']
      },
      {
        title: gettext('Key'),
        value: domainField.isKey,
        type: 'radio',
        customOptions: ['YES', 'NO']
      },
      {
        title: gettext('Sort'),
        value: domainField.isSort,
        type: 'radio',
        customOptions: ['YES', 'NO']
      },
      {
        title: gettext('Raw field name'),
        value: domainField.rawFieldName,
        type: 'simple'
      },
      {
        title: gettext('Field name'),
        value: gettextCatalog.getString(domainField.fieldType),
        type: 'simple'
      },
      {
        title: gettext('Filter type'),
        value: getfilterType(domainField.filterType),
        type: 'simple'
      }
    );

    var propertySections = [
      {
        title: gettext('Properties'),
        properties: metadata
      }
    ];
    var actions = [
      {
        title: gettext('Update'),
        script: true,
        fn: goToEdit
      }
    ];

    return {
      metadata: {
        definition: 'Domain field',
        title: domainField.name,
        description: domainField.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }

  function goToEdit() {
    var params = {
      domainId: $state.params.domainId,
      fieldId: $state.params.fieldId
    };

    $state.go('configurations-dynamic-attributes-domains-fields-edit', params);
  }
}

export default DomainFieldsConfigurationController;
