import { gettext } from '../../../routes';

ContentTimeSeriesConfiguration.$inject = ['AuthorizationToEditService'];
/**
 * @ngdoc service
 * @name common.ContentTimeSeriesConfiguration
 * @description TODO
 * @property {function} get
 */
export default function ContentTimeSeriesConfiguration(
  AuthorizationToEditService
) {
  /**
   * @memberof ContentTimeSeriesConfiguration.get
   * @description  returns object that contains configurations to build tango item
   * @param {string} entityId entity id
   * @return {Object}
   */
  // eslint-disable-next-line no-unused-vars
  function get(entityId) {
    return {
      /**
       * @description returns array of actions to be displayed as content tabs.
       * every action must have fn function that triggers componentScope.onTabChange
       * function with template and bindings
       * @function
       * @param {Object} componentScope scope of the content component
       * @return {Array}
       */
      getActions: componentScope => {
        const onTabChange = componentScope.onTabChange;
        const postFix = 'SfeContentTemplates';
        const editPermissions = AuthorizationToEditService.isAuthorizedToEdit();
        let actions = [
          {
            title: gettext('Time Series'),
            fn: () => {
              const template = `timeSeriesTab${postFix}`;
              onTabChange({
                template
              });
            },
            color: 'black',
            opaqueButton: true,
            raised: true,
            greyBorder: true
          },
          {
            title: gettext('GIS'),
            fn: () => {
              const template = `gisTab${postFix}`;
              onTabChange({
                template
              });
            },
            color: 'black',
            opaqueButton: true,
            greyBorder: true
          },
          {
            title: gettext('Company Resources'),
            fn: () => {
              const template = `companyResourcesTab${postFix}`;
              const filterObject = {
                timeSeries: entityId //entityId
              };
              //demo timeSeries id
              const config = {
                type: 'multiple',
                updateParam: {
                  timeSeries: entityId
                },
                createParam: {
                  timeSeries: entityId
                }
              };
              const entityDateConfigs = [
                {
                  entityName: 'time-series-measuring-points',
                  filterObject,
                  config
                },
                {
                  entityName: 'time-series-assets',
                  filterObject,
                  config
                },
                {
                  entityName: 'time-series-location',
                  filterObject,
                  config
                }
              ];
              onTabChange({
                template,
                bindings: {
                  entityDateConfigs
                }
              });
            },
            color: 'black',
            opaqueButton: true,
            greyBorder: true
          },
          {
            title: gettext('Dynamic Attributes'),
            fn: () => {
              const template = `dynamicAttributesTab${postFix}`;
              onTabChange({
                template
              });
            },
            color: 'black',
            opaqueButton: true,
            greyBorder: true
          }
        ];
        if (editPermissions === true) {
          actions.push({
            title: gettext('Privilege Management'),
            fn: () => {
              const template = `privilegeManagementTab${postFix}`;
              const bindings = {
                identifier: 'tis_be:time-series'
              };
              onTabChange({
                template,
                bindings
              });
            },
            color: 'black',
            opaqueButton: true,
            greyBorder: true
          });
        }

        actions.push({
          title: gettext('Statistics'),
          fn: () => {
            const template = `statisticsTab${postFix}`;
            const bindings = {
              timeSeriesId: entityId
            };
            onTabChange({
              template,
              bindings
            });
          },
          color: 'black',
          opaqueButton: true,
          greyBorder: true
        });
        return actions;
      }
    };
  }
  return {
    get
  };
}
