HttpRequestTimeoutInterceptor.$inject = ['HttpPendingRequestsService'];
/**
 * @ngdoc service
 * @name core.HttpRequestTimeoutInterceptor
 * @description Adds new timeout to http request to allow request cancelation
 * @property {object} anonymousObject interceptor object
 */

function HttpRequestTimeoutInterceptor(HttpPendingRequestsService) {
  /**
   * @description when response has config requestUniqIdentifier removes request from list of pending requests.
   * @function
   * @param {Object} response resource response object
   * @return {Object}
   */
  function removeRequestIdentifier(response) {
    if (response.config && response.config.requestUniqIdentifier != undefined) {
      HttpPendingRequestsService.removeRequest(
        response.config.requestUniqIdentifier
      );
    }
    return response;
  }
  function removeRequestIdentifierError(response) {
    if (response.config && response.config.requestUniqIdentifier != undefined) {
      HttpPendingRequestsService.removeRequest(
        response.config.requestUniqIdentifier
      );
    }
    throw response;
  }
  return {
    request: function(config) {
      config = config || {};
      if (!config.notCancelable && config.smartAuth) {
        const {
          timeout,
          requestUniqIdentifier
        } = HttpPendingRequestsService.newTimeout();
        config.timeout = timeout;
        config.requestUniqIdentifier = requestUniqIdentifier;
      }
      return config;
    },
    requestError: function(rejection) {
      throw rejection;
    },
    response: removeRequestIdentifier,
    responseError: removeRequestIdentifierError
  };
}

export default HttpRequestTimeoutInterceptor;
