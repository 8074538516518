placeholder.$inject = ['gettextCatalog'];

function placeholder(gettextCatalog) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      var setTranslation = function() {
        var translatedPlaceholder = gettextCatalog.getString(attrs.placeholder);
        element.attr('placeholder', translatedPlaceholder);
      };

      scope.$on('gettextLanguageChanged', setTranslation);
      setTranslation();
    }
  };
}

export default placeholder;
