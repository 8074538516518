import template from './tango-item-header.component.html';
import './tango-item-header.scss';
export default {
  template,
  bindings: {
    configuration: '<',
    entity: '<',
    entityId: '<'
  },
  controller: TangoItemHeaderController,
  controllerAs: 'vm'
};

TangoItemHeaderController.$inject = [
  '$scope',
  '$ngRedux',
  'ReduxStoreService',
  'gettext',
  'AlertingService',
  'MetadataService'
];

function TangoItemHeaderController(
  $scope,
  $ngRedux,
  ReduxStoreService,
  gettext,
  AlertingService,
  MetadataService
) {
  const vm = this;
  let unsubscribe;

  vm.invalidIcon = {
    type: 4,
    name: 'wi-na'
  };

  vm.$onChanges = () => {
    //on any change check if all bindings are set
    if (vm.configuration && vm.entityId && vm.entity && unsubscribe == null) {
      //assign state properties and measurement actions to controller scope
      // and listen for changes
      unsubscribe = $ngRedux.connect(mapStateToProps)(vm);
      $scope.$on('$destroy', unsubscribe);
    }
  };
  /**
   * @description validate that all of actions have fn function or valid link state configuration.
   * Invalid configuration will be removed from actions
   * @function
   * @param {Array} actions array of actions
   * @return {Array}
   */
  function validateActions(actions) {
    return actions.reduce((result, action) => {
      if (
        typeof action.fn == 'function' ||
        typeof action.link == 'string' ||
        Array.isArray(action.items)
      ) {
        result.push(action);
      } else {
        AlertingService.devWarn(
          `${action.title} button has invalid configuration. Action function or action link is missing.`
        );
      }
      return result;
    }, []);
  }
  /**
   * @description extracts method from store
   * and sets validation errors.
   * @function
   * @param {Object} state store state object
   * @return {Any}
   */
  function getProperty(state, config) {
    const { value, error, warning } = ReduxStoreService.extract(
      state,
      vm.entity,
      vm.entityId,
      config
    );
    let result;
    if (
      (error == null && warning == null) ||
      (warning &&
        warning.noValue == false &&
        error &&
        error.required == false &&
        error.invalid == false)
    ) {
      result = { value };
    } else {
      if (warning.noValue) {
        result = {
          value: gettext('No value'),
          invalid: true
        };
      } else {
        result = {
          value: gettext('Invalid value'),
          invalid: true
        };
      }
    }
    return result;
  }

  /**
   * @description  Triggered every time state changes and sets
   * actions, header and icon data to scope.
   * @function
   * @param {Object} state store state object
   * @return {Object}
   */
  function mapStateToProps(state) {
    let itemHeaderTitle = getProperty(state, vm.configuration.name);
    //set tab title
    MetadataService.ChangeMetadata(itemHeaderTitle.value);
    let icon = getProperty(state, vm.configuration.icon);
    let actions = getProperty(state, vm.configuration.actions);
    actions.value = validateActions(actions.value);
    return {
      itemHeaderTitle,
      icon,
      actions
    };
  }
}
