//sfe tree template
import sfeTreeNodeTemplate from '../../common/components/sfe-tree/sfe-tree-node.template.html';
//sfe manual input template
import VerticalTemplate from '../../data/pages/manual-input-form/manual-input-form-vertical.template.html';
import HorizontalTemplate from '../../data/pages/manual-input-form/manual-input-form-horizontal.template.html';
//sfe select-tree
import selectTreeNode from '../../common/components/sfe-select-tree/select-tree.node.html';
import analysisFormFilters from '../../analytics/components/analysis-form-filter-repeat/analysis-form-filter.template.html';
//manual input form
import Form from '../../data/pages/manual-input-form/manual-input-value-form.template.html';
import template from '../../common/components/template-error/template-error.html';
import tagSideMenuItem from '../../device/directives/sfe-tag-explorer/tag-side-menu-item.template.html';
RegisterTemplate.$inject = [
  '$templateCache',
  'FormTemplates',
  'sfeForm2Templates',
  'dashboardTemplates',
  'noGroupTemplates',
  'userTemplateConstant',
  'SfeItemHeaderTemplates',
  'SfePropertyTemplates',
  'sfeForm2MathExpressionTemplates',
  'SfeContentTemplates',
  'TangoTimeSeriesConfigurationsTemplate'
];

function RegisterTemplate(
  $templateCache,
  FormTemplates,
  sfeForm2Templates,
  dashboardTemplates,
  noGroupTemplates,
  userTemplateConstant,
  SfeItemHeaderTemplates,
  SfePropertyTemplates,
  sfeForm2MathExpressionTemplates,
  SfeContentTemplates,
  TangoTimeSeriesConfigurationsTemplate
) {
  var templates = {
    Form: FormTemplates,
    sfeForm2: sfeForm2Templates,
    Dashboard: dashboardTemplates,
    NoGroup: noGroupTemplates,
    SfeForm2MathExpression: sfeForm2MathExpressionTemplates,
    User: userTemplateConstant,
    Header: SfeItemHeaderTemplates,
    Tag: { tagSideMenuItem },
    Property: SfePropertyTemplates,
    TreeNode: {
      sfeTreeNodeTemplate
    },
    ManualInput: {
      VerticalTemplate,
      HorizontalTemplate,
      Form
    },
    SelectTreeNode: {
      selectTreeNode
    },
    AnalysisFormFilters: {
      analysisFormFilters
    },
    SfeContentTemplates,
    TangoTimeSeriesConfigurationsTemplate,
    Error: {
      template
    }
  };

  //templates are saved in the caches nameOfTemplateNameOfTemplateGroup (example template from error is called 'templateError', template VerticalTemplate is VerticalTemplateManualInput)
  Object.keys(templates).forEach(postfix => {
    Object.keys(templates[postfix]).forEach(templateKey => {
      $templateCache.put(
        templateKey + postfix,
        templates[postfix][templateKey]
      );
    });
  });
}

export default RegisterTemplate;
