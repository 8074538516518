SfeAddressHelper.$inject = ['AddressAutoCompleteService'];
/**
 * @ngdoc factory
 * @name common.SfeAddressHelper
 * @description factory containing logic that for sfe-address component that is not bound to view.
 * @property {function} listSfeAddressItem - see listSfeAddressItem
 * @property {function} createSettlementObject - see createSettlementObject
 */
export default function SfeAddressHelper(AddressAutoCompleteService) {
  /**
   * @memberof common.SfeAddressHelper
   * @description description.
   * @param {string} search - string by which the address item is filtered
   * @param {string} AddressAutoCompleteFunctionName - name of the function called in the AddressAutoCompleteService service
   * @param {Array} arrayOfFunctionParameters - array containing all parameters that are used by the function we are calling
   * @return {promise} - promise that will resolve into a filtered sfe address item
   */
  const listFilteredSfeAddressItem = (
    search,
    AddressAutoCompleteFunctionName,
    arrayOfFunctionParameters = []
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await AddressAutoCompleteService[
          AddressAutoCompleteFunctionName
        ](search, ...arrayOfFunctionParameters);
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  };
  /**
   * @memberof common.SfeAddressHelper
   * @description returns a settlement object from the array containing settlements
   * @param {Array} settlement - array containing the settlement
   * @param {string} locality - name of the locality to which we can compare the settlement title (naz)
   * @return {dataType}
   */
  const createSettlementObject = (settlement, locality) => {
    if (settlement.length === 1) {
      return settlement[0];
    } else {
      return settlement.find(item => {
        if (item.naz) {
          return item.naz.toLowerCase().indexOf(locality.toLowerCase()) === 0;
        }
        return false;
      });
    }
  };
  return {
    listFilteredSfeAddressItem,
    createSettlementObject
  };
}
