import template from './sfe-tag-selector.component.html';
import './sfe-tag-selector.scss';
/**
 * @ngdoc component
 * @name moduleName.sfeTagSelector
 * @description component that shows tags that are based on data source and allows adding of tags though a dialog
 * @param {string} tangoAgentConnectionConfigId - id of the tangoAgentConnectionConfig
 * @param {boolean} required - tells us if the tag is required.
 * @example
 * <sfe-tag-selector
 *  tango-agent-connection-config-id="vm.tangoAgentConnectionConfigId'"
 *  required="vm.tagRequired"
 * ></sfe-tag-selector>
 */
export default {
  template,
  bindings: {
    tangoAgentConnectionConfigId: '<',
    required: '<'
  },
  require: {
    model: 'ngModel',
    form: '^form'
  },
  controller: sfeTagSelectorController,
  controllerAs: 'vm'
};

sfeTagSelectorController.$inject = ['TagSelector'];

function sfeTagSelectorController(TagSelector) {
  const vm = this;
  vm.$postLink = () => {
    vm.model.$validators.required = tag => {
      // value is valid either if is an object with a non-null id or if its not (vm.)required
      return (
        !vm.required ||
        (typeof tag === 'object' && tag != null && tag._id != null)
      );
    };
  };

  vm.openTagSelector = (tangoAgentConnectionConfigId, ev) => {
    TagSelector.open(tangoAgentConnectionConfigId, ev).then(tag => {
      vm.model.$setViewValue(tag);
    });
  };
}
