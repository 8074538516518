module.exports = {
  entity: 'billing-kinds',
  path: 'billing-kinds',
  entityId: 55,
  name: {
    en: 'Biling kinds',
    sl_SI: 'Vrste obračunov'
  },
  networkModel: {
    entity: 'billing-kinds',
    read: 'read'
  },
  scope: 'tis_be:billing-kinds',

  dialog: {
    title: {
      en: 'Select billing kind',
      sl_SI: 'Izberite vrsto obračuna'
    },
    listInfo: {
      en: 'Select billing kind from the list below',
      sl_SI: 'Izberite vrsto obračuna s spodnjega seznama'
    }
  }
}
