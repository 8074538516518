SfeHeader.$inject = ['gettext'];
/**
 * @ngdoc service
 * @name common.SfeHeader
 * @description Configures the object used in <sfe-header> component.
 * @property {function} constructFormHeader - Function that configures the header object.
 */
export default function SfeHeader(gettext) {
  /**
   * @memberof common.constructFormHeader
   * @description Function that configures the object in <sfe-header> component.
   * @param {string} backgroundColor Accepts primary, accent and warn.
   * @param {string} title Title of the header.
   * @param {string} route Where the cancel button should redirect the user.
   * @param {Object} params Used along with route.
   * @param {Array} customActions If other actions should be used instead of default cancel button.
   * @param {number} fontweight For the title.
   * @return {Object} Configured object
   */
  function constructFormHeader(
    backgroundColor,
    title,
    route,
    params,
    customActions,
    fontweight
  ) {
    if (route || params) {
      return {
        backgroundColor: backgroundColor,
        title: title,
        fw: fontweight,
        actions: [
          {
            title: gettext('Cancel'),
            color: 'primary',
            whiteButton: true,
            raised: true,
            isLink: true,
            link: route + '(' + JSON.stringify(params) + ')'
          }
        ]
      };
    } else {
      if (customActions) {
        return {
          backgroundColor: backgroundColor,
          title: title,
          actions: customActions,
          fw: fontweight
        };
      }
      return {
        backgroundColor: backgroundColor,
        title: title,
        fw: fontweight
      };
    }
  }

  return {
    constructFormHeader: constructFormHeader
  };
}
