/**
 * @ngdoc component
 * @name common.inputNumberFormat
 * @description Function that validates and formats integers when the input type is "numerical". Formatting is based on localization settings (decimal comma or dot)
 * @example
 * <input
 *   input-number-format
 *   type="numerical"
 * ></input>
 */

export default [
  'Formatting',
  'LocalizationService',
  function(Formatting, LocalizationService) {
    function resetValidationError(validatorController, error) {
      ['number', 'integer', 'min', 'max', 'positive', 'trailing-zero'].forEach(
        function(e) {
          validatorController.$setValidity(e, true);
        }
      );
      if (error) {
        validatorController.$setValidity(error, false);
      }
      return !!error;
    }

    var parseNumber;

    function linkFn(scope, element, attrs, ngModelController) {
      let watcher;
      let started;
      scope.$on('$destroy', () => {
        if (watcher) {
          watcher();
        }
      });
      if (attrs.watchType) {
        watcher = scope.$watch('watchType', () => {
          if (attrs.watchType) {
            if (!started) {
              startWatcher();
            }
            watcher();
          }
        });
      }
      function startWatcher() {
        if (attrs.type === 'numerical') {
          started = true;
          parseNumber = function(data) {
            if (data === '') {
              data = null;
            } else {
              var errors = [];

              // NOT A NUMBER AND TRAILING ZERO
              if (
                data.toString()[data.toString().length - 1] ===
                  LocalizationService.GetSelectedLang().decimalDelimiter ||
                data.split(
                  LocalizationService.GetSelectedLang().decimalDelimiter
                ).length > 2 ||
                isNaN(Formatting.parseNumber(data))
              ) {
                errors.push('number');
              } else if (
                data.toString()[data.toString().length - 1] === '0' &&
                data.split(
                  LocalizationService.GetSelectedLang().decimalDelimiter
                ).length === 2
              ) {
                errors.push('trailing-zero');
              }
              data = Formatting.parseNumber(data);

              // NOT INTEGER
              if (
                attrs.onlyIntegers === 'true' &&
                !Number.isInteger(Number(data))
              ) {
                errors.push('integer');
              }

              // NOT POSITIVE
              if (attrs.onlyPositive === 'true' && Number(data) < 0) {
                errors.push('positive');
              }
              // < MIN
              if (
                attrs.min !== '' &&
                !Number.isNaN(Number(attrs.min)) &&
                Number(data) < Number(attrs.min)
              ) {
                errors.push('min');
              }

              // > MAX
              if (
                attrs.max !== '' &&
                !Number.isNaN(Number(attrs.max)) &&
                Number(data) > Number(attrs.max)
              ) {
                errors.push('max');
              }

              //check for errors and revalidate
              if (errors.length === 0) {
                ngModelController.$viewValue = Formatting.formatNumber(data);
                ngModelController.$render();
                resetValidationError(ngModelController);
              } else {
                errors.forEach(e => {
                  resetValidationError(ngModelController, e);
                });
              }
            }
            return data;
          };

          ngModelController.$parsers.unshift(parseNumber);
          ngModelController.$formatters.unshift(Formatting.formatNumber);
        }
      }
      startWatcher();
    }

    return {
      require: 'ngModel',
      link: linkFn
    };
  }
];
