module.exports = {
  entity: 'cusum-intervals',
  path: 'cusum-intervals',
  name: {
    en: 'cusum-intervals',
    sl_SI: 'Države'
  },
  networkModel: {
    entity: 'cusum-intervals',
    read: 'custom.populateCusum'
  },
  scope: 'tis_be:cusum-intervals',
  displayFields: [
    {
      field1: 'cusum',
      field2: 'name',
      type: 'string'
    },
    {
      field1: 'validFrom',
      type: 'date',
      title: 'validFrom'
    },
    {
      field1: 'validTo',
      type: 'date',
      title: 'validTo'
    }
  ],
  dialog: {
    title: {
      en: 'Select cusum interval',
      sl_SI: 'Izberite cusum interval'
    },
    listInfo: {
      en: 'Select cusum interval from the list below',
      sl_SI: 'Izberite cusum intervalo s spodnjega seznama'
    }
  }
}
