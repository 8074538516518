module.exports = {
  entity: 'datasets',
  path: 'datasets',
  name: {
    en: 'Datasets',
    sl_SI: 'Nabor podatkov'
  },
  networkModel: {
    entity: 'datasets',
    read: 'read'
  },
  selectedParamName: 'id',
  scope: 'tis_be:datasets',

  dialog: {
    title: {
      en: 'Select dataset',
      sl_SI: 'Izberite nabor podatkov'
    },
    listInfo: {
      en: 'Select dataset from the list below',
      sl_SI: 'Izberite nabor podatkov s spodnjega seznama'
    }
  }
}
