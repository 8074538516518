import { DateTime } from 'luxon';

MtValues.$inject = [
  'TimeSeriesProcessingValuesModel',
  'gettextCatalog',
  'AlertingService',
  'CachingParams'
];

/**
 * @ngdoc service
 * @name data.MtValues
 * @description Fetches MT values.
 * @property {Function} getValues - return values based on mt analysis id
 */
function MtValues(
  TimeSeriesProcessingValuesModel,
  gettextCatalog,
  AlertingService,
  CachingParams
) {
  const timestampParam = 'validAt';

  /**
   * @description Returns values for analysis.
   * @function
   * @param {Object} analysis - analysis object
   * @param {Number} min
   * @param {Number} max
   * @param {number} cacheInvalidationTime cache invalidation time
   * @param {string|number} cacheUniqueId unique id per dashboard
   * @return {Promise}
   */
  async function getValues(
    analysis,
    min,
    max,
    cacheInvalidationTime,
    cacheUniqueId
  ) {
    try {
      let boundaries = determineBoundaries(analysis, min, max);
      let xId =
        analysis.inputTimeSeriesForXAxis != null &&
        typeof analysis.inputTimeSeriesForXAxis == 'object'
          ? analysis.inputTimeSeriesForXAxis._id
          : null;
      let yId =
        analysis.inputTimeSeriesForYAxis != null &&
        typeof analysis.inputTimeSeriesForYAxis == 'object'
          ? analysis.inputTimeSeriesForYAxis._id
          : null;

      if (xId == null) {
        throw gettextCatalog.getString('Missing inputTimeSeriesForXAxis');
      }
      if (yId == null) {
        throw gettextCatalog.getString('Missing inputTimeSeriesForYAxis');
      }
      let xMeasurementValues = await fetchTimeSeriesValues(
        xId,
        boundaries.minTimestamp,
        boundaries.maxTimestamp,
        cacheInvalidationTime,
        cacheUniqueId
      );
      let yMeasurementValues = await fetchTimeSeriesValues(
        yId,
        boundaries.minTimestamp,
        boundaries.maxTimestamp,
        cacheInvalidationTime,
        cacheUniqueId
      );
      if (min !== max || max !== 0) {
        xMeasurementValues = xMeasurementValues.filter(item => {
          return item.value >= min && item.value <= max;
        });
      }
      const pairs = pairValues(
        xMeasurementValues,
        yMeasurementValues,
        boundaries.minTimestamp,
        boundaries.maxTimestamp
      );
      return pairs;
    } catch (e) {
      const error = typeof e == 'object' && !e.status ? e.toString() : e;
      AlertingService.Error(error);
      return { pairs: [], lastHundred: [] };
    }
  }

  /**
   * @description Determine boundaries for min and max timestamp.
   * @function
   * @param {Object} analysis
   * @param {Number} min
   * @param {Number} max
   * @return {Object}
   */
  function determineBoundaries(analysis, min, max) {
    let minTimestamp, maxTimestamp;
    if (analysis.dateForCalcOfRegressionFuncFrom) {
      minTimestamp = DateTime.fromJSDate(
        new Date(analysis.dateForCalcOfRegressionFuncFrom)
      ).toMillis();
    }

    if (analysis.dateForCalcOfRegressionFuncTo) {
      maxTimestamp = DateTime.fromJSDate(
        new Date(analysis.dateForCalcOfRegressionFuncTo)
      ).toMillis();
    } else {
      maxTimestamp = DateTime.fromJSDate(new Date()).toMillis();
    }

    if (!min && !max) {
      min = analysis.xValuesFrom;
      max = analysis.xValuesTo;
    }

    return { min, max, minTimestamp, maxTimestamp };
  }

  /**
   * @description Fetches measurement values.
   * @function
   * @param {String} id timeseries id
   * @param {Number} minTimestamp
   * @param {Number} maxTimestamp
   * @param {number} cacheInvalidationTime cache invalidation time
   * @param {string|number} cacheUniqueId unique id per dashboard
   * @return {Promise}
   */
  async function fetchTimeSeriesValues(
    id,
    minTimestamp,
    maxTimestamp,
    cacheInvalidationTime,
    cacheUniqueId
  ) {
    let filter = {
      timeSeriesId: id
    };
    if (minTimestamp) {
      filter.from = minTimestamp;
    }

    if (maxTimestamp) {
      filter.to = maxTimestamp;
    }
    filter.view = 'simple';
    try {
      const { data } = await TimeSeriesProcessingValuesModel.read(
        CachingParams.CheckParams(
          (cacheUniqueId ? cacheUniqueId + '/' : '') +
            'time-series-processing-values' +
            '/' +
            id,
          filter,
          cacheInvalidationTime
        ),
        cacheInvalidationTime
      );
      return data;
    } catch (err) {
      // eslint-disable-next-line no-unreachable
      throw err;
    }
  }

  /**
   * @description Metches xAxis and yAxis values.
   * @function
   * @param {Array} XaxisValues
   * @param {Array} YaxisValues
   * @param {String} minTimestamp
   * @param {String} maxTimestamp
   * @return {Object}
   * {
   *   pairs: paired values,
   *   lastHundred: last hundred paired values
   * }
   */
  function pairValues(XaxisValues, YaxisValues, minTimestamp, maxTimestamp) {
    let xValue, foundY, foundYNoTimeLimit;
    let pairs = [];
    let lastHundredValues = [];
    if (!XaxisValues && !YaxisValues) {
      return {
        pairs: [],
        lastHundred: []
      };
    }
    XaxisValues.sort(function(a, b) {
      return b[timestampParam] - a[timestampParam];
    });
    YaxisValues.sort(function(a, b) {
      return b[timestampParam] - a[timestampParam];
    });
    for (let x = 0; x < XaxisValues.length; x++) {
      xValue = XaxisValues[x];
      foundY = YaxisValues.find(item => {
        return (
          item[timestampParam] === xValue[timestampParam] &&
          Number(item[timestampParam]) >= minTimestamp &&
          Number(item[timestampParam]) <= maxTimestamp
        );
      });

      foundYNoTimeLimit = YaxisValues.find(item => {
        return item[timestampParam] === xValue[timestampParam];
      });
      if (foundY) {
        pairs.push([
          Number(xValue.value),
          Number(foundY.value),
          Number(xValue[timestampParam])
        ]);
      }
      if (foundYNoTimeLimit && lastHundredValues.length < 100) {
        lastHundredValues.push([
          Number(xValue.value),
          Number(foundYNoTimeLimit.value),
          Number(xValue[timestampParam])
        ]);
      }
    }

    return {
      pairs,
      lastHundred: lastHundredValues
    };
  }

  return {
    getValues
  };
}

export default MtValues;
