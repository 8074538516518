import './sfe-form-2-flow-variables.scss';
import template from './sfe-form-2-flow-variables.component.html';

/**
* @ngdoc component
* @name common.sfeForm2FlowVariable
* @description Adds Timeseries and dynamic attributes variables .
* @param {Object} options configurations object
*     @param {Boolean} showDynamicAttributes indicates if dynamic attributes variable selector wil be shown
* @example
* <sfe-form-2-flow-variables
* options="vm.options"
  ng-change="vm.config.onChange()"
  ng-required="vm.config.required"
  ng-model="vm.value"
  name="{{vm.config.id}}"
  sfe-form-2-validation="vm.config.validators"  
* ></sfe-form-2-flow-variables>

MODEL STRUCTURE
[
  ///TIMESERIES VARIABLES
            [
              {
                timeSeries, <Object> on init can be timeSeries mongo id
                triggersCalculation: <Boolean>
                timeSeriesParams: <Array> [
                  {
                    mappingFunction <Object> on init can be mapping function codelist id
                    name <String> Variable name,
                     mappingFunctionArguments: <Array>[
                      {
                        value: <Integer> mapping function argument value
                      }
                    ]
                  }
                ]
              }
            ],
  ///DYNAMIC ATTRIBUTES VARIABLE VALUES
            [
              {
                attribute <Object> on init can be attribute mongo id (Location or Asset)
                attributeType: <Integer> codelist attribute type id
                attributeParams: <Array> - selected attribute dynamic attributes variables [ 
                  {
                    mappingFunction: <Object> - on init can be function codelist id
                    attribute <Object>  - on init can be dynamic attribute id
                    name: <String> variable name
                  }
                ]
              }
            ]
          ]
*/

export default {
  template,
  bindings: {
    options: '<',
    onChange: '&'
  },
  require: {
    model: 'ngModel',
    form: '^form'
  },
  controller: sfeForm2FlowVariable,
  controllerAs: 'vm',
  bindToController: true
};

sfeForm2FlowVariable.$inject = [
  '$scope',
  'gettext',
  'TranslationService',
  'TimeSeriesVariableService',
  'ColorService',
  'gettextCatalog',
  'InputParametersParserService'
];

function sfeForm2FlowVariable(
  $scope,
  gettext,
  TranslationService,
  TimeSeriesVariableService,
  ColorService,
  gettextCatalog,
  InputParametersParserService
) {
  let vm = this;
  vm.attributeTypeChanged = attributeTypeChanged;
  vm.updateViewModel = updateViewModel;
  vm.getSelectedItems = getSelectedItems;

  vm.$onInit = () => {
    initiateActions();
    vm.attributeObjectTypes = TranslationService.GetCollection(
      'codelists.attributeObjectTypes'
    );
  };

  $scope.$on('$destroy', () => {
    vm = null;
    stopWatcher();
  });

  vm.noTriggerErrorIcon = {
    type: 2,
    name: 'error_outline',
    color: ColorService.getApplicationColor('warn')
  };

  vm.getNextVariableName = () => {
    return TimeSeriesVariableService.createVariableName(
      vm.timeSeriesModelValue,
      vm.attributesModelValue
    );
  };

  vm.triggerErrorText = gettextCatalog.getString(
    'At least one trigger has to be enabled'
  );

  vm.removeTimeSeriesCard = index => {
    vm.timeSeriesModelValue.splice(index, 1);
    updateViewModel();
  };

  vm.$postLink = () => {
    const noTrigger = value => {
      //when trigger type is 300 (cronScheduled)
      //there is no need for trigger
      if (vm.options && vm.options.noTrigger) {
        return true;
      }
      let timeSeriesModelValue = Array.isArray(value) ? value[0] : null;
      if (Array.isArray(timeSeriesModelValue)) {
        return timeSeriesModelValue.reduce((triggersCalculation, item) => {
          return triggersCalculation || item.triggersCalculation;
        }, false);
      }
      return false;
    };

    // Check input time series have no params
    const noTimeSeriesParams = value => {
      let timeSeriesModelValue =
        Array.isArray(value) && value.length > 0 ? value[0] : [];
      let noParams = timeSeriesModelValue.find(
        value => value.timeSeriesParams.length === 0
      );
      if (noParams != null) {
        return false;
      }
      return true;
    };

    // Check if input dynamic attributes have no params
    const noDynamicAttributesParams = value => {
      let dynamicAttributesModelValue =
        Array.isArray(value) && value.length > 1 ? value[1] : [];
      let noParams = dynamicAttributesModelValue.find(
        value => value.attributeParams.length === 0
      );
      if (noParams != null) {
        return false;
      }
      return true;
    };

    vm.model.$validators = {
      noTrigger,
      noTimeSeriesParams,
      noDynamicAttributesParams,
      ...vm.model.$validators
    };
  };

  function getUniqIdentifier() {
    return Math.random()
      .toString(36)
      .substring(2);
  }

  /**
   * @description creates add attributes and add timeSeries sfe-actions.
   * @function
   */
  function initiateActions() {
    vm.attributesActions = [
      {
        title: gettext('Add attribute'),
        fn: () => {
          if (vm.attributesModelValue == null) {
            vm.attributesModelValue = [];
          }
          let newAttribute = getAttributeConfiguration();
          attributeTypeChanged(newAttribute.attributeType, newAttribute);
          vm.attributesModelValue = [...vm.attributesModelValue, newAttribute];
          updateViewModel();
        }
      }
    ];

    vm.addTimeSeriesCardAction = [
      {
        title: gettext('Add time series'),
        fn: () => {
          addNewTimeSeries();
        }
      }
    ];
  }

  /**
   * @description watches for model data to come and
   * if it is set to a string or a number triggers
   * function that fetches data.
   * @function
   */
  let stopWatcher = $scope.$watch(function() {
    if (vm.model != null) {
      return vm.model.$modelValue;
    }
  }, initiateItems);

  function initiateItems(model) {
    if (Array.isArray(model) && model.length > 0) {
      const timeSeries = processTimeSeries(model[0]);

      vm.timeSeriesModelValue = [...timeSeries];

      const attributes = processAttributes(model[1]);

      vm.attributesModelValue = attributes;

      vm.onChange();
    }
  }

  const removeIcon = {
    type: 2,
    name: 'close',
    color: 'grey'
  };
  /**
   * @description returns Array of already selected time series.
   * @function
   * @return {Array}
   */
  function getSelectedItems() {
    const timeSeriesObjects = vm.model.$modelValue[0];
    if (Array.isArray(timeSeriesObjects)) {
      return timeSeriesObjects.reduce((result, item) => {
        if (
          item != null &&
          item.timeSeries != null &&
          typeof item.timeSeries == 'object'
        ) {
          result = [...result, item.timeSeries._id];
        }
        return result;
      }, []);
    }
    return [];
  }

  /**
   * @description updates ngModel value.
   * @function
   */
  function updateViewModel() {
    vm.model.$setViewValue(
      TimeSeriesVariableService.processModelBeforeUpdate(
        vm.model.$modelValue,
        vm.timeSeriesModelValue,
        vm.attributesModelValue
      )
    );

    vm.onChange();
    vm.model.$validate();
  }
  /**
   * @description gets triggered when any of time series cards get changed.
   * @function
   */
  vm.timeSeriesChanged = () => {
    updateViewModel();
  };

  /**
   * @description when new model value comes checks if any time series needed to be initialized.
   * adds uniq ID to time series item and time series param item, adds configurations for sfe-actions items
   * @function
   * @param {Array} timeSeriesModel
   * @return {Array}
   */
  function processTimeSeries(timeSeriesModel) {
    if (Array.isArray(timeSeriesModel)) {
      let newTimeSeriesItems = timeSeriesModel.map(item => {
        const timeSeriesParams = InputParametersParserService.parseApiToForm(
          item.timeSeriesParams
        );
        return {
          timeSeriesObject: {
            timeSeries: item.timeSeries,
            triggersCalculation: item.triggersCalculation,
            timeSeriesParams
          }
        };
      });
      return newTimeSeriesItems;
    }
  }
  /**
   * @description on model change processes and initializes dynamic attributes .
   * @function
   * @param {Array} attributesModel
   * @return {Array}
   *
   */
  function processAttributes(attributesModel) {
    let attributes = [];
    if (Array.isArray(attributesModel)) {
      attributes = attributesModel.map(item => {
        let attribute = item.attribute;
        // eslint-disable-next-line valid-typeof
        if (typeof attribute == 'object' && attribute != null) {
          attribute = {
            ...attribute,
            __prefetched__value__: true
          };
        }

        let uniqIdentifier = item.uniqIdentifier;
        if (item.uniqIdentifier == null) {
          //ATTRIBUTE UNIQ IDENTIFIER
          uniqIdentifier = getUniqIdentifier();
        }
        //NEW ATTRIBUTE ITEM SFE-ACTION CONFIGURATION
        let addNewAttributeParam = getNewAttributeParamAction(uniqIdentifier);
        // ATTRIBUTE MAPPING FUNCTION AUTOCOMPLETE
        let removeAttributeAction = getRemoveAttributeAction(uniqIdentifier);
        let newAttribute = {
          ...item,
          attribute,
          uniqIdentifier,
          addNewAttributeParam,
          removeAttributeAction
        };
        let attributeParams = item.attributeParams.map(param => {
          //PARAM UNIQ IDENTIFIER
          let paramUniqIdentifier = getUniqIdentifier();
          //REMOVE ATTRIBUTE PARAMETER ACTION
          let removeAttributeParamAction = getRemoveAttributeParamActions(
            uniqIdentifier,
            paramUniqIdentifier
          );
          return {
            ...param,
            uniqIdentifier: paramUniqIdentifier,
            removeAttributeParamAction
          };
        });
        //ENTITY (ASSET OR LOCATION DEPENDING IN ENTITY TYPE) AUTOCOMPLETE CONFIGURATION
        //IT HAS ON CHANGE FUNCTION THAT ON EVERY CHANGE SETS DYNAMIC ATTRIBUTE AUTOCOMPLETE CONFIGURATION
        let selectedEntityTypeAutocomplete = getAttributeEntityTypeAutocomplete(
          newAttribute.attributeType,
          uniqIdentifier
        );
        return {
          ...newAttribute,
          selectedEntityTypeAutocomplete,
          attributeParams
        };
      });
    }
    return attributes;
  }

  /**
   * @description opens dialog to select time series item and adds it to timeSeries items.
   * @function
   */
  function addNewTimeSeries() {
    if (vm.timeSeriesModelValue == null) {
      vm.timeSeriesModelValue = [];
    }
    vm.timeSeriesModelValue = [
      ...vm.timeSeriesModelValue,
      {
        timeSeriesObject: {
          timeSeriesParams: [{ variableName: vm.getNextVariableName() }]
        }
      }
    ];
    updateViewModel();
  }

  /**
   * @description returns new attribute object with
   * attribute autocomplete configuration
   * remove attribute action configuration
   * and data placeholders
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function getAttributeConfiguration() {
    let uniqIdentifier = getUniqIdentifier();
    let addNewAttributeParam = getNewAttributeParamAction(uniqIdentifier);
    return {
      uniqIdentifier,
      attribute: null,
      attributeType: 1,
      attributeParams: [],
      addNewAttributeParam,
      removeAttributeAction: getRemoveAttributeAction(uniqIdentifier)
    };
  }
  /**
   * @description returns remove attribute item action from attribute model array.
   * @function
   * @param {String} uniqIdentifier attribute uniq identifier
   * @return {Array}
   */
  function getRemoveAttributeAction(uniqIdentifier) {
    return [
      {
        icon: removeIcon,
        /**
         * @description removes attribute item bu uniqIdentifier id.
         * @function
         */
        fn: () => {
          const index = vm.attributesModelValue.findIndex(
            item => item.uniqIdentifier == uniqIdentifier
          );
          if (index >= 0) {
            vm.attributesModelValue.splice(index, 1);
            updateViewModel();
          }
        }
      }
    ];
  }

  /**
   * @description gets triggered when attribute type radio buttons change ist value
   * When value changes resets attribute value, updates attribute autocomplete configuration.
   * @function
   * @param {Number} selected codelist id of selected attribute from attributeObjectTypes
   * @param {Object} attributeItem attribute item where changes have been made
   */
  function attributeTypeChanged(selected, attributeItem) {
    attributeItem.attribute = null;
    attributeItem.attributeParams = [];
    attributeItem.selectedEntityTypeAutocomplete = getAttributeEntityTypeAutocomplete(
      selected,
      attributeItem.uniqIdentifier
    );
  }
  /**
   * @description returns attribute autocomplete configuration for selected attribute item.
   * @function
   * @param {Number} selected codelist id of selected attribute from attributeObjectTypes
   * @param {Object} attributeItem attribute item where changes have been made
   * @return {Object}
   */
  function getAttributeEntityTypeAutocomplete(selected, uniqIdentifier) {
    /**
     * @description filters already used entities.
     * @function
     * @param {Array} items
     * @return {Array}
     */
    let filter = items => {
      if (
        Array.isArray(vm.model.$viewValue) &&
        Array.isArray(vm.model.$viewValue[1]) &&
        vm.model.$viewValue[1].length > 0
      ) {
        let attributes = vm.model.$viewValue[1].filter(
          attribute => attribute.attributeType == selected
        );
        return items.filter(item => {
          return !attributes.find(attr => {
            return attr.attribute && attr.attribute._id == item._id;
          });
        });
      }
      return items;
    };
    const selectedEntityTypeAutocomplete = TimeSeriesVariableService.getEntityAutocomplete(
      selected,
      filter
    );
    if (selectedEntityTypeAutocomplete != null) {
      return {
        ...selectedEntityTypeAutocomplete,
        /**
         * @description triggered on every attribute change
         * resets attributeParams array and and updates dynamicAttributeAutocomplete configuration.
         * @function
         */
        onChange: async () => {
          let attributeItem = vm.attributesModelValue.find(
            item => item.uniqIdentifier == uniqIdentifier
          );
          if (attributeItem.attribute != null) {
            //__prefetched__value__ will be set to true if item is loaded first time
            // do not reset attribute parameters first time
            if (!attributeItem.attribute.__prefetched__value__) {
              let uniqParamIdentifier = getUniqIdentifier();
              attributeItem.attributeParams = [];
              let newParam = getNewAttributeParam(
                uniqIdentifier,
                uniqParamIdentifier
              );

              attributeItem.attributeParams = [newParam];
            }
            attributeItem.attributeAutocomplete = await TimeSeriesVariableService.getAttributeAutocomplete(
              attributeItem
            );
            $scope.$applyAsync();
          }
          updateViewModel();
        }
      };
    }
  }
  /**
   * @description returns new attribute param object.
   * @function
   * @param {String} uniqIdentifier
   * @param {String} paramUniqIdentifier
   * @return {Object}
   */
  function getNewAttributeParam(uniqIdentifier, paramUniqIdentifier) {
    let newVariableName = TimeSeriesVariableService.createVariableName(
      vm.timeSeriesModelValue,
      vm.attributesModelValue
    );
    return {
      uniqIdentifier: paramUniqIdentifier,
      attribute: null,
      name: newVariableName,
      removeAttributeParamAction: getRemoveAttributeParamActions(
        uniqIdentifier,
        paramUniqIdentifier
      )
    };
  }
  /**
   * @description returns action configuration that removes attribute param from array of params with paramUniqIdentifier from array of attributes that has uniqIdentifier.
   * @function
   * @param {String} uniqIdentifier
   * @param {String} paramUniqIdentifier
   * @return {dataType}
   */
  function getRemoveAttributeParamActions(uniqIdentifier, paramUniqIdentifier) {
    return [
      {
        icon: removeIcon,
        /**
         * @description removes attribute param by uniqIdentifier and paramUniqIdentifier.
         * @function
         * @param {dataType} binding/paramName
         * @return {dataType}
         */
        fn: () => {
          vm.attributesModelValue = vm.attributesModelValue.map(item => {
            if (item.uniqIdentifier == uniqIdentifier) {
              let paramIndex = item.attributeParams.findIndex(
                item => item.uniqIdentifier == paramUniqIdentifier
              );
              item.attributeParams.splice(paramIndex, 1);
            }
            return item;
          });
          updateViewModel();
        }
      }
    ];
  }
  /**
   * @description returns sfe-actions configuration to add new attribute parameter.
   * @function
   * @param {String} uniqIdentifier
   * @return {Array}
   */
  function getNewAttributeParamAction(uniqIdentifier) {
    return [
      {
        title: gettext('Add new attribute'),
        /**
         * @description disables add attribute button if attribute hasn't been selected.
         * @function
         */
        disabledFn: () => {
          let currentItem = vm.attributesModelValue.find(
            item => item.uniqIdentifier == uniqIdentifier
          );
          return currentItem == null || currentItem.attribute == null;
        },
        /**
         * @description adds to attribute attributeParams  array a new attribute param item with all autocomplete and action configurations.
         * @function
         * @param {dataType} binding/paramName
         * @return {dataType}
         */
        fn: () => {
          let paramUniqIdentifier = getUniqIdentifier();
          vm.attributesModelValue = vm.attributesModelValue.map(item => {
            if (item.uniqIdentifier == uniqIdentifier) {
              let newAttributeParam = getNewAttributeParam(
                uniqIdentifier,
                paramUniqIdentifier
              );
              item.attributeParams = [
                ...item.attributeParams,
                newAttributeParam
              ];
            }
            return item;
          });
          updateViewModel();
        }
      }
    ];
  }
}
