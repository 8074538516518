AssetModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function AssetModel(ModelUtilities, CrawlerMethods) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'assets'
  };

  const systemTagStep = {
    entity: 'system-tag-entities',
    valueParams: ['_id'],
    queryParams: ['entityId'],
    method: 'custom.readWithTag',
    methodType: 'add',
    newParamNames: ['tagsSystem']
  };

  const crawlerConfigurationForm = {
    entity: 'assets',
    method: 'read',
    populate:
      'assetBrand,assetModel,type,status,mainCostCentre,mainLocation,owner,ownership,parentId',
    steps: [systemTagStep]
  };

  const networkConfigurationChildren = {
    host: 'api',
    path: 'assets/:parentId/children'
  };

  const crawlerConfigurationList = {
    entity: 'assets',
    method: 'read',
    populate: 'assetBrand,assetModel,type,status'
  };

  const crawlerConfigurationItemView = {
    entity: 'assets',
    method: 'read',
    populate:
      'assetBrand,assetModel,type,status,mainCostCentre,owner,ownership,parentId',
    steps: [
      {
        entity: 'asset-locations',
        valueParams: ['_id'],
        queryParams: ['asset'],
        method: 'custom.readPopulateLocation',
        methodType: 'add',
        newParamNames: ['mainLocation'],
        filters: {
          isActive: true
        }
      },
      {
        entity: 'picture-assets',
        valueParams: ['_id'],
        queryParams: ['asset'],
        method: 'read',
        methodType: 'add',
        newParamNames: ['pictures']
      },
      systemTagStep
    ]
  };

  const crawlerConfigurationCRviewData = {
    entity: 'assets',
    method: 'read',
    populate: 'type,ownership'
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      list: CrawlerMethods.crawler(crawlerConfigurationList),
      readItemView: CrawlerMethods.crawler(crawlerConfigurationItemView),
      readForm: CrawlerMethods.crawler(crawlerConfigurationForm),
      readChildren: ModelUtilities.crud(
        'GET',
        networkConfigurationChildren,
        modelStorage
      ),
      CRViewData: CrawlerMethods.crawler(crawlerConfigurationCRviewData)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default AssetModel;
