import template from './manual-readings.dialog.html';

import './manual-readings-dialog.scss';
/**
 * @ngdoc factory
 * @name common.ManualReadings
 * @description Used to display and update manual readings.
 * @return {function} opens manual readings dialog
 */
ManualReadings.$inject = ['$mdDialog'];
/**
 * @ngdoc service
 * @name common.ManualReadings
 * @description opens manual reading dialog.
 * @returns {function}
 * @param {string} id measuring point id
 * @param {string} name measuring point name
 * @param {number} measuringPointKind id
 * @param {string} actionType action type could be manualReading or counterChange
 */
export default function ManualReadings($mdDialog) {
  return function(
    id,
    measuringpointName,
    measuringPointKind,
    parentId,
    actionType,
    energySourceType
  ) {
    return $mdDialog.show({
      template,
      controller: ManualReadingsController,
      controllerAs: 'vm',
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      locals: {
        id,
        measuringpointName,
        actionType,
        energySourceType,
        measuringPointKind,
        parentId
      }
    });
  };
}

ManualReadingsController.$inject = [
  'id',
  'measuringpointName',
  'actionType',
  'gettextCatalog',
  'energySourceType',
  'measuringPointKind',
  'parentId',
  'MeasuringpointCounterTypeModel',
  'AlertingService',
  '$scope'
];

function ManualReadingsController(
  id,
  measuringpointName,
  actionType,
  gettextCatalog,
  energySourceType,
  measuringPointKind,
  parentId,
  MeasuringpointCounterTypeModel,
  AlertingService,
  $scope
) {
  var vm = this;

  initiate();
  async function initiate() {
    //get counter type
    //to sort manual reading
    vm.loadingRegisters = true;
    try {
      const { data } = await MeasuringpointCounterTypeModel.read({
        measuringpoint: id,
        isActive: true,
        populate: 'counterType'
      });
      if (data.length == 1) {
        const [mpCounterType] = data;
        const { counterType } = mpCounterType;
        vm.registers = counterType.registers;
      }
    } catch (err) {
      AlertingService.Error('err', err);
    }
    vm.loadingRegisters = false;
    vm.measuringpointId = id;
    vm.actionType = actionType;
    vm.energySourceType = energySourceType;
    vm.measuringPointKind = measuringPointKind;
    vm.parentId = parentId;

    let title = gettextCatalog.getString(
      'Unknown action for {{measuringpointName}}',
      { measuringpointName }
    );
    switch (vm.actionType) {
    case 'manualReadings':
      // indicates ether to display new counter start value
      title = gettextCatalog.getString(
        'Manual readings for {{measuringpointName}}',
        { measuringpointName }
      );
      break;
    case 'counterChange':
      // indicates ether to display new counter start value
      title = gettextCatalog.getString(
        'Change counter for {{measuringpointName}}',
        { measuringpointName }
      );
      break;
    }
    vm.headerConfiguration = {
      title: gettextCatalog.getString(title, {
        measuringpointName
      }),
      isDialog: true,
      actions: [
        {
          icon: {
            name: 'close',
            type: 2
          },
          cancel: true
        }
      ]
    };
    $scope.$evalAsync();
  }
}
