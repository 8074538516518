AddressModel.$inject = ['ModelUtilities', 'TranslationService'];

function AddressModel(ModelUtilities, TranslationService) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'addresses'
  };

  function getReadableAddress(item) {
    let readableAddress = '';
    if (item.streetName) {
      readableAddress += item.streetName;
      if (item.houseNumber != undefined) {
        readableAddress += ' ' + item.houseNumber;
      }
    }

    if (item.settlementName) {
      readableAddress += readableAddress
        ? ', ' + item.settlementName
        : item.settlementName;
    }

    if (item.communityName) {
      readableAddress += readableAddress
        ? ', ' + item.communityName
        : item.communityName;
    }
    if (item.country) {
      const country = TranslationService.GetCollectionById(
        'codelists.countries',
        item.country
      );
      if (country) {
        readableAddress += readableAddress ? ', ' + country.name : country.name;
      }
    }
    return readableAddress;
  }

  async function fetchReadableAddress(queryParameters) {
    try {
      const { data: result } = await methods.read(queryParameters);
      if (Array.isArray(result)) {
        return {
          data: result.map(item => ({
            ...item,
            name: getReadableAddress(item)
          }))
        };
      } else {
        return {
          data: {
            ...result,
            name: getReadableAddress(result)
          }
        };
      }
    } catch (err) {
      throw err;
    }
  }

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      fetchReadableAddress
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default AddressModel;
