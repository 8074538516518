import translate from '../components/sfe-item-header/templates/translate.template.html';
import code from '../components/sfe-item-header/templates/code.template.html';
import raw from '../components/sfe-item-header/templates/raw.template.html';
import simple from '../components/sfe-item-header/templates/simple.template.html';
import date from '../components/sfe-item-header/templates/date.template.html';
import dateShort from '../components/sfe-item-header/templates/date-short.template.html';
import dateTo from '../components/sfe-item-header/templates/date-to.template.html';
import link from '../components/sfe-item-header/templates/link.template.html';
import radio from '../components/sfe-item-header/templates/radio.template.html';
import person from '../components/sfe-item-header/templates/person.template.html';
import userAccess from '../components/sfe-item-header/templates/user-access.template.html';
import tags from '../components/sfe-item-header/templates/tags.template.html';
import showMoreShowLess from '../components/sfe-item-header/templates/show-more-show-less.template.html';
import image from '../components/sfe-item-header/templates/image.template.html';

export default function SfeItemHeaderTemplates() {
  return {
    translate,
    code,
    raw,
    simple,
    date,
    dateShort,
    dateTo,
    link,
    radio,
    person,
    userAccess,
    tags,
    showMoreShowLess,
    image
  };
}
