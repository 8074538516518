import './styles/application-settings.scss';

import routes from './config.routes';

// controllerscompany-reseurces-general.controller';
import CompanyStructureNodeController from './pages/company-structure-node/company-structure-node.controller';
import NewCompanyStructureNodeController from './pages/company-structure-node/new-company-structure-node.controller';
import CounterTypeViewController from './pages/counter-type/counter-type-view.controller';
import NewCounterTypeController from './pages/counter-type/new-counter-type.controller';
import DomainAttributeConfigController from './pages/domain-attribute-configuration/domain-attributes-configuration.controller';
import NewDomainAttributeConfigController from './pages/domain-attribute-configuration/new-domain-attribute-configuration.controller';
import DomainCollectionConfigurationController from './pages/domain-collection-configuration/domain-collection-configuration.controller';
import NewDomainCollectionConfigurationFormController from './pages/domain-collection-configuration/new-domain-collection-configuration.controller';
import DomainConfigurationController from './pages/domain-configuration/domain-configuration.controller';
import NewDomainConfigurationController from './pages/domain-configuration/new-domain-configuration-controller';
import DomainFieldsConfigurationController from './pages/domain-fields-configuration/domain-field-configuration.controller';
import NewDomainFieldsConfigurationFormController from './pages/domain-fields-configuration/new-domain-field-configuration-form.controller';
import EnergySourceViewController from './pages/energy-source/energy-source-view.controller';
import NewEnergySourceController from './pages/energy-source/new-energy-source.controller';
import EnergySourceController from './pages/energy-source-configuration/energy-source.controller';
import NewEntityTypeConfigController from './pages/entity-type-configuration/new-entity-type-configuration.controller';
import ResponseConfigurationController from './pages/response-configuration/response-configuration.controller';
import AssetTypeConfigController from './pages/asset-type-configuration/asset-type-configuration.controller';
import AssetsConfigurationController from './pages/assets-configuration/assets-configuration.controller';
import LocationConfigurationController from './pages/location-configuration/location-configuration.controller';
import LocationTypeConfigController from './pages/location-type-configuration/location-type-configuration.controller';
import TimeSeriesConfigurationController from './pages/time-series-configuration/time-series-configuration.controller';
import TimeSeriesTypeConfigController from './pages/time-series-type-configuration/time-series-type-configuration.controller';
import MeasurementUnitsConfigurationController from './pages/measurement-units-configuration/measurement-units-configuration.controller';
import MeasuringPointTypeConfigController from './pages/measuring-point-type-configuration/measuring-point-type-configuration.controller';
import MeasuringPointsConfigurationController from './pages/measuring-points-configuration/measuring-points-configuration.controller';
import ProjectConfigurationController from './pages/project-configuration/project-configuration.controller';

// factories
import DomainCollectionConfigurationService from './factories/DomainCollectionFormConfiguration.service';
import DomainCollectionListConfigService from './factories/DomainCollectionListConfig.service';
import DomainFieldsListConfigService from './factories/DomainFieldsListConfig.service';
import PhysicalDataFormConfigurationService from './factories/PhysicalDataFormConfiguration.service';

//configurations
import alarmTypeConfiguration from './configurations/alarm-type.configuration';
import assetBrandConfiguration from './configurations/asset-brand.configuration';
import assetGroupConfiguration from './configurations/asset-group.configuration';
import assetModelConfiguration from './configurations/asset-model.configuration';
import assetStatusConfiguration from './configurations/asset-status.configuration';
import assetTypeConfiguration from './configurations/asset-type.configuration';
import billingKindConfiguration from './configurations/billing-kind.configuration';
import billingTypeConfiguration from './configurations/billing-type.configuration';
import companyStructureNodeConfiguration from './configurations/company-structure-node.configuration';
import counterTypeConfiguration from './configurations/counter-type.configuration';
import documentClassificationsConfiguration from './configurations/document-classifications.configuration';
import domainAttributeConfiguration from './configurations/domain-attribute.configuration';
import domainConfiguration from './configurations/domain.configuration';
import energyManagementGroupConfiguration from './configurations/energy-management-group.configuration';
import energySourceTypeConfiguration from './configurations/energy-source-type.configuration';
import locationActionStatusConfiguration from './configurations/location-action-statuses.configuration';
import locationClassificationConfiguration from './configurations/location-classification.configuration';
import locationStatusConfiguration from './configurations/location-status.configuration';
import locationTypeConfiguration from './configurations/location-type.configuration';
import TimeSeriesTypeConfiguration from './configurations/time-series-type.configuration';
import measurementUnitConfiguration from './configurations/measurement-unit.configuration';
import measuringPointTypeConfiguration from './configurations/measuring-point-type.configuration';
import nationalHolidayConfiguration from './configurations/national-holiday.configuration';
import ownershipConfiguration from './configurations/ownership.configuration';
import physicalQuantityConfiguration from './configurations/physical-quantity.configuration';
import priceListItemGroupConfiguration from './configurations/price-list-item-group.configuration';
import profitCentreConfiguration from './configurations/profit-centre.configuration';
import projectFieldConfiguration from './configurations/project-field.configuration';
import projectTypeConfiguration from './configurations/project-type.configuration';
import responseConfiguration from './configurations/response.configuration';
import severityConfiguration from './configurations/severity.configuration';
import systemTagsConfiguration from './configurations/system-tags.configuration';
import tariffConfiguration from './configurations/tariff.configuration';
import TimeSeriesGroupTypeConfiguration from './configurations/time-series-group-types.configuration';
import RatedPowerConfiguration from './configurations/rated-power.configuration';

//network models (services)
import AlarmTypeModel from './network-models/AlarmType.model';
import RatedPowerModel from './network-models/RatedPower.model';
import AssetBrandModel from './network-models/AssetBrand.model';
import AssetGroupModel from './network-models/AssetGroup.model';
import AssetModelModel from './network-models/AssetModel.model';
import AssetStatusModel from './network-models/AssetStatus.model';
import AssetTypeModel from './network-models/AssetType.model';
import AssetTypeAttributeModel from './network-models/AssetTypeAttribute.model';
import BillingKindModel from './network-models/BillingKind.model';
import BillingTypeModel from './network-models/BillingType.model';
import CompanyStructureNodeModel from './network-models/CompanyStructureNode.model';
import CounterTypeModel from './network-models/CounterType.model';
import DocumentClassificationModel from './network-models/DocumentClassification.model';
import DomainModel from './network-models/Domain.model';
import DomainAttributeModel from './network-models/DomainAttribute.model';
import DomainCollectionModel from './network-models/DomainCollection.model';
import DomainFieldModel from './network-models/DomainField.model';
import EnergyManagementGroupModel from './network-models/EnergyManagementGroup.model';
import EnergySourceTypeModel from './network-models/EnergySourceType.model';
import LocationActionStatusModel from './network-models/LocationActionStatus.model';
import LocationClassificationModel from './network-models/LocationClassification.model';
import LocationStatusModel from './network-models/LocationStatus.model';
import LocationTypeModel from './network-models/LocationType.model';
import LocationTypeAttributeModel from './network-models/LocationTypeAttribute.model';
import MeasurementTypeModel from './network-models/MeasurementType.model';
import TimeSeriesTypeAttributeModel from './network-models/TimeSeriesTypeAttribute.model';
import MeasurementUnitModel from './network-models/MeasurementUnit.model';
import MeasuringpointTypeModel from './network-models/MeasuringpointType.model';
import MeasuringpointTypeAttributeModel from './network-models/MeasuringpointTypeAttribute.model';
import NationalHolidayModel from './network-models/NationalHoliday.model';
import OwnershipModel from './network-models/Ownership.model';
import PhysicalQuantityModel from './network-models/PhysicalQuantity.model';
import PriceListItemGroupModel from './network-models/PriceListItemGroup.model';
import ProjectFieldModel from './network-models/ProjectField.model';
import ProjectTypeModel from './network-models/ProjectType.model';
import ResponseResolutionTimeModel from './network-models/ResponseResolutionTime.model';
import SeverityModel from './network-models/Severity.model';
import TariffModel from './network-models/Tariff.model';

//TANGO PROPERTIES
import MeasurementUnitTangoProperties from './tango-properties/measurement-unit-tango-properties.service';

export default {
  controllers: {
    CompanyStructureNodeController,
    NewCompanyStructureNodeController,
    CounterTypeViewController,
    NewCounterTypeController,
    DomainAttributeConfigController,
    NewDomainAttributeConfigController,
    DomainCollectionConfigurationController,
    NewDomainCollectionConfigurationFormController,
    DomainConfigurationController,
    NewDomainConfigurationController,
    DomainFieldsConfigurationController,
    NewDomainFieldsConfigurationFormController,
    EnergySourceViewController,
    NewEnergySourceController,
    EnergySourceController,
    NewEntityTypeConfigController,
    ResponseConfigurationController,
    AssetTypeConfigController,
    AssetsConfigurationController,
    LocationConfigurationController,
    LocationTypeConfigController,
    TimeSeriesConfigurationController,
    TimeSeriesTypeConfigController,
    MeasurementUnitsConfigurationController,
    MeasuringPointTypeConfigController,
    MeasuringPointsConfigurationController,
    ProjectConfigurationController
  },
  factories: {
    DomainCollectionConfigurationService,
    DomainCollectionListConfigService,
    DomainFieldsListConfigService,
    PhysicalDataFormConfigurationService,
    alarmTypeConfiguration,
    assetBrandConfiguration,
    assetGroupConfiguration,
    assetModelConfiguration,
    assetStatusConfiguration,
    assetTypeConfiguration,
    billingKindConfiguration,
    billingTypeConfiguration,
    companyStructureNodeConfiguration,
    counterTypeConfiguration,
    documentClassificationsConfiguration,
    domainAttributeConfiguration,
    domainConfiguration,
    energyManagementGroupConfiguration,
    energySourceTypeConfiguration,
    locationActionStatusConfiguration,
    locationClassificationConfiguration,
    locationStatusConfiguration,
    locationTypeConfiguration,
    TimeSeriesTypeConfiguration,
    measurementUnitConfiguration,
    measuringPointTypeConfiguration,
    nationalHolidayConfiguration,
    ownershipConfiguration,
    physicalQuantityConfiguration,
    priceListItemGroupConfiguration,
    profitCentreConfiguration,
    projectFieldConfiguration,
    projectTypeConfiguration,
    responseConfiguration,
    severityConfiguration,
    systemTagsConfiguration,
    tariffConfiguration,
    MeasurementUnitTangoProperties,
    TimeSeriesGroupTypeConfiguration,
    RatedPowerConfiguration
  },
  services: {
    AlarmTypeModel,
    RatedPowerModel,
    AssetBrandModel,
    AssetGroupModel,
    AssetModelModel,
    AssetStatusModel,
    AssetTypeModel,
    AssetTypeAttributeModel,
    BillingKindModel,
    BillingTypeModel,
    CompanyStructureNodeModel,
    CounterTypeModel,
    DocumentClassificationModel,
    DomainModel,
    DomainAttributeModel,
    DomainCollectionModel,
    DomainFieldModel,
    EnergyManagementGroupModel,
    EnergySourceTypeModel,
    LocationActionStatusModel,
    LocationClassificationModel,
    LocationStatusModel,
    LocationTypeModel,
    LocationTypeAttributeModel,
    MeasurementTypeModel,
    TimeSeriesTypeAttributeModel,
    MeasurementUnitModel,
    MeasuringpointTypeModel,
    MeasuringpointTypeAttributeModel,
    NationalHolidayModel,
    OwnershipModel,
    PhysicalQuantityModel,
    PriceListItemGroupModel,
    ProjectFieldModel,
    ProjectTypeModel,
    ResponseResolutionTimeModel,
    SeverityModel,
    TariffModel
  },
  routes
};
