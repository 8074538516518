ProfitCentreController.$inject = [
  '$stateParams',
  'gettext',
  'MetadataService',
  'HierarchyTreeDialog',
  'profitCentre',
  '$timeout'
];

function ProfitCentreController(
  $stateParams,
  gettext,
  MetadataService,
  HierarchyTreeDialog,
  profitCentre,
  $timeout
) {
  const vm = this;
  const profitCentreId = $stateParams.id;

  vm.$onInit = function init() {
    vm.loading = true;
    MetadataService.Loading(true);

    vm.headerData = constructHeader(profitCentre);
    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(
        profitCentre.name,
        profitCentre.description
      );
      vm.loading = false;
    });
  };

  function constructHeader(profitCentre) {
    const companyStructureNode =
      profitCentre != null ? profitCentre.companyStructureNode : null;
    const companyStructureNodeId =
      companyStructureNode != null ? companyStructureNode._id : null;
    let companyStructureNodeConfig;
    if (companyStructureNode != null) {
      companyStructureNodeConfig = {
        type: 'link',
        title: gettext('Company Structure Node'),
        linkTitle: companyStructureNode.name,
        state: 'configurations-company-resources-company-structure-nodes-view',
        param: 'id',
        paramValue: companyStructureNodeId
      };
    } else {
      companyStructureNodeConfig = {
        type: 'simple',
        title: gettext('Company Structure Node'),
        value: gettext('None')
      };
    }
    let properties = [
      companyStructureNodeConfig,
      {
        type: 'code',
        title: gettext('Code'),
        value: profitCentre.code ? profitCentre.code : gettext('Unknown')
      },
      {
        type: 'code',
        title: gettext('External code'),
        value: profitCentre.externalCode
          ? profitCentre.externalCode
          : gettext('None')
      }
    ];
    let propertySections = [
      {
        title: gettext('Properties'),
        properties: properties
      }
    ];
    let actions = [
      {
        title: gettext('Show Hierarchy'),
        fn: function() {
          HierarchyTreeDialog.openDialog(profitCentre, 'profit-centres');
        }
      },
      {
        title: gettext('delete'),
        delete: true,
        id: profitCentreId,
        endpoint: {
          entity: 'profit-centres',
          method: 'delete'
        },
        redirectState: 'company-resources-profit-centres-list'
      },
      {
        title: gettext('Update'),
        state: 'company-resources-profit-centres-edit',
        param: 'id',
        paramValue: profitCentreId
      }
    ];
    return {
      metadata: {
        title: profitCentre.name,
        description: profitCentre.description,
        definition: gettext('Profit Centre')
      },
      propertySections: propertySections,
      actions: actions
    };
  }
}

export default ProfitCentreController;
