module.exports = {
  entity: 'fileTypes',
  path: 'fileTypes',
  name: {
    en: 'File type',
    sl_SI: 'Tip datoteke'
  },
  codelist: 'fileTypes',
  scope: 'tis_be:fileTypes',

  dialog: {
    title: {
      en: 'Select file type',
      sl_SI: 'Izberite tip datoteke'
    },
    listInfo: {
      en: 'Select file type from the list below',
      sl_SI: 'Izberite tip datoteke s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      }
    ],
    codelist: 'fileTypes'
  }
}
