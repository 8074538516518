assetTypeConfiguration.$inject = ['gettext', '$q'];

function assetTypeConfiguration(gettext, $q) {
  let configuration = {
    entity: {
      singular: gettext('Asset Type'),
      plural: gettext('Asset Types')
    },
    listId: 'asset-types',
    api: {
      query: {
        entity: 'asset-types',
        method: 'custom.list'
      },
      enrich: Enrich
    },
    create: {
      link: {
        state: 'configurations-company-resources-assets-asset-types-new'
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'configurations-company-resources-assets-asset-types-new'
        },
        color: 'accent'
      }
    ],
    title: {
      param: 'name',
      link: {
        state: 'configurations-company-resources-assets-asset-types-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'configurations-company-resources-assets-asset-types-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'asset-types',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description'),
        type: 'raw'
      },
      {
        param: 'assetGroupName',
        title: gettext('Asset group'),
        type: 'raw'
      },
      {
        param: 'code',
        title: gettext('Code'),
        type: 'code'
      },
      {
        param: 'externalCode',
        title: gettext('External Code'),
        type: 'code'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Code'),
        param: 'code',
        type: 'string'
      },
      {
        display: gettext('External Code'),
        param: 'externalCode',
        type: 'string'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    let deferred = $q.defer();
    deferred.resolve();
    item.assetGroupName = gettext('Unknown');
    if (item.hasOwnProperty('assetGroup') && item.assetGroup !== null) {
      item.assetGroupName = item.assetGroup.name;
    }
    return deferred.promise;
  }

  return configuration;
}
export default assetTypeConfiguration;
