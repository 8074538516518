measurementUnitConfiguration.$inject = [
  'gettext',
  '$q',
  'MultiLanguage',
  'SfeFormDialogService',
  'CrudToastFactory',
  'AlertingService',
  'MeasurementUnitModel',
  'LocalizationService',
  'PhysicalCollectionService',
  'ListFormService',
  'gettextCatalog'
];

function measurementUnitConfiguration(
  gettext,
  $q,
  MultiLanguage,
  SfeFormDialogService,
  CrudToastFactory,
  AlertingService,
  MeasurementUnitModel,
  LocalizationService,
  PhysicalCollectionService,
  ListFormService,
  gettextCatalog
) {
  const fieldNames = [
    'name',
    'description',
    'isSystem',
    'externalCode',
    'symbol',
    'physicalQuantity'
  ];

  function openCreateDialog(relistData) {
    var config = getForm();
    var title = gettext('New Measurement Unit');
    SfeFormDialogService.openSfeFormDialog(
      false,
      config,
      {
        _preserve_: true
      },
      title
    ).then(function(object) {
      if (object) {
        create(object, config, relistData);
      }
    });
  }

  function openUpdateDialog(item, relistData) {
    MeasurementUnitModel.custom
      .readAllLang({
        id: item._id,
        populate: 'physicalQuantity'
      })
      .then(
        function(res) {
          const selectedLang = LocalizationService.GetSelectedLangNetworkCode();
          const translatedData = MultiLanguage.translateMultiLanguageValues(
            res.data,
            ['physicalQuantity.name'],
            selectedLang
          );
          var config = getForm();
          var title = gettext('Edit Measurement Unit');
          SfeFormDialogService.openSfeFormDialog(
            true,
            config,
            translatedData,
            title
          ).then(function(object) {
            if (object) {
              update(item._id, object, config, relistData);
            }
          });
        },
        function(err) {
          AlertingService.Error(err);
        }
      );
  }

  async function create(obj, config, relistData) {
    var data = MultiLanguage.constructForm(config, obj);
    data.physicalQuantity = data.physicalQuantity._id;

    try {
      await PhysicalCollectionService.createOrUpdateItem(
        null,
        ListFormService.generateBodyObject(data, fieldNames),
        'measurement-units'
      );
      CrudToastFactory.toast('create');
      relistData();
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  async function update(id, obj, config, relistData) {
    var data = MultiLanguage.constructForm(config, obj);
    data.physicalQuantity = data.physicalQuantity._id;
    try {
      await PhysicalCollectionService.createOrUpdateItem(
        id,
        ListFormService.generateBodyObject(data, fieldNames),
        'measurement-units'
      );
      CrudToastFactory.toast('update');
      relistData();
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  function getForm() {
    return [
      {
        placeholder: gettext('Name'),
        name: 'name',
        text: true,
        required: true,
        componentType: 'multilanguage'
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        textArea: 'true',
        maxlength: 500,
        required: false,
        componentType: 'multilanguage'
      },
      {
        placeholder: gettext('Symbol'),
        name: 'symbol',
        componentType: 'textField',
        required: true
      },
      {
        placeholder: gettext('External Code'),
        name: 'externalCode',
        componentType: 'textField',
        maxLength: 30,
        required: false
      },
      {
        configuration: {
          query: {
            entity: 'physical-quantities',
            method: 'read'
          },
          entity: 'physical-quantities',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select physical quantity')
          },
          floatingLabel: gettext('Select Physical Quantity'),
          searchParamName: 'filter',
          required: true
        },
        componentType: 'autocompleteDialog',
        edit: false,
        name: 'physicalQuantity'
      },
      {
        componentType: 'checkBox',
        label: gettext('System'),
        name: 'isSystem'
      }
    ];
  }

  var configuration = {
    entity: {
      singular: gettext('Measurement Unit'),
      plural: gettext('Measurement Units')
    },
    api: {
      query: {
        entity: 'measurement-units',
        method: 'custom.list'
      },
      enrich: Enrich
    },
    title: {
      param: 'name'
    },
    buttons: [
      {
        title: gettext('Create'),
        fn: openCreateDialog,
        relist: true,
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        action: openUpdateDialog
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'measurement-units',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description')
      },
      {
        param: 'type',
        title: gettext('Type')
      },
      {
        param: 'physicalQuantityName',
        title: gettext('Physical Quantity')
      },
      {
        param: 'symbol',
        title: gettext('Symbol'),
        type: 'raw'
      },
      {
        param: 'externalCode',
        title: gettext('External code'),
        type: 'code'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('External Code'),
        param: 'externalCode',
        type: 'string'
      },
      {
        display: gettext('Physical Quantity'),
        query: {
          entity: 'physical-quantities',
          method: 'read'
        },
        entity: 'physical-quantities',
        param: 'physicalQuantity',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    item.type = gettextCatalog.getString('Application');
    if (item.isSystem) {
      item.type = gettextCatalog.getString('System');
    }
    if (item.physicalQuantity !== null && item.physicalQuantity) {
      item.physicalQuantityName = item.physicalQuantity.name;
    }
    deferred.resolve();
    return deferred.promise;
  }
  return configuration;
}
export default measurementUnitConfiguration;
