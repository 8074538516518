import template from './tango-timeseries-group-form.component.html';
import './tango-timeseries-group-form.scss';

export default {
  template,
  bindings: {
    api: '<',
    dndListId: '<',
    id: '<',
    filtersAreShown: '<'
  },
  controller: TangTimeseriesGroupFormController,
  controllerAs: 'vm'
};

TangTimeseriesGroupFormController.$inject = [
  'gettextCatalog',
  'gettext',
  'TranslationService',
  'partialScheduleClassificationConfiguration',
  '$timeout',
  '$element',
  'TimeSeriesGroupModel',
  '$state',
  'AlertingService',
  'ColorService',
  'CrudToastFactory',
  'ScheduleClassificationFormConfiguration'
];

function TangTimeseriesGroupFormController(
  gettextCatalog,
  gettext,
  TranslationService,
  partialScheduleClassificationConfiguration,
  $timeout,
  $element,
  TimeSeriesGroupModel,
  $state,
  AlertingService,
  ColorService,
  CrudToastFactory,
  ScheduleClassificationFormConfiguration
) {
  let vm = this;
  let savingGroup = false;
  initializeForm();
  initializeActions();
  vm.scheduleClassification = [];
  vm.removeClassificationTittle = gettextCatalog.getString(
    'Remove classification'
  );
  vm.removeClassification = index => {
    vm.scheduleClassification.splice(index, 1);
  };

  vm.onlyOneMainErrorIcon = {
    type: 2,
    name: 'error_outline',
    color: ColorService.getApplicationColor('warn')
  };

  vm.onlyOneMainErrorText = gettextCatalog.getString(
    'Only one main schedule classification is allowed'
  );
  vm.$onChanges = () => {
    if (vm.api != null && typeof vm.api == 'object') {
      setApiFunction(vm.api);
    }
  };
  /**
   * @description sets api functions to api object.
   * @function
   * @param {Object} api
   */
  function setApiFunction(api) {
    api.setValues = setValues;
    api.getValues = getValues;
    api.formValidity = formValidity;
    api.revalidate = revalidate;
  }
  /**
   * @description sets values for every model.
   * @function
   * @param {Object} metadata new metadata values
   * @param {Array} scheduleClassifications array of objects new schedule classification data
   */
  async function setValues({ metadata, scheduleClassifications }) {
    if (Array.isArray(scheduleClassifications)) {
      vm.scheduleClassification = scheduleClassifications.map(value => {
        return {
          formApi: {},
          formConfig: getScheduleClassificationFormConfiguration(value)
        };
      });
      await $timeout();
      scheduleClassifications.forEach((item, index) => {
        Object.keys(item).forEach(key => {
          vm.scheduleClassification[index].formApi.setValue(key, item[key]);
        });
      });
    }
    Object.keys(metadata).forEach(key => {
      vm.formApi.setValue(key, metadata[key]);
    });
  }
  /**
   * @description returns form values in object {metadata, scheduleClassifications}.
   * @function
   * @return {Object}
   */
  function getValues() {
    let metadata = vm.formApi.getValues();
    let scheduleClassifications = vm.scheduleClassification.map(item => {
      return item.formApi.getValues();
    });
    return {
      metadata,
      scheduleClassifications
    };
  }
  /**
   * @description returns validity status.
   * @function
   * @return {Boolean}
   */
  function formValidity() {
    let invalidClassifications = vm.scheduleClassification.some(item => {
      if (typeof item.formApi.formValidity == 'function') {
        return item.formApi.formValidity() == false;
      }
      return false;
    });
    if (typeof vm.formApi.formValidity == 'function') {
      return vm.formApi.formValidity() && !invalidClassifications;
    }
    return !invalidClassifications;
  }
  /**
   * @description triggers revalidation function for every form used in component.
   * @function
   */
  function revalidate() {
    vm.formApi.revalidate();
    vm.scheduleClassification.forEach(item => {
      item.formApi.revalidate();
    });
  }
  /**
   * @description description.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  async function addNewClassification(initData) {
    vm.scheduleClassification.push({
      formApi: {},
      formConfig: getScheduleClassificationFormConfiguration(initData)
    });
    //SCROLL TO THE BOTTOM WHEN NEW CLASSIFICATION IS ADDED
    await $timeout();
    let groupContainer = $element.find('.group-container');
    if (groupContainer && groupContainer[0]) {
      groupContainer[0].scrollTop = groupContainer[0].scrollHeight;
    }
  }
  /**
   * @description creates new classification form configuration.
   * @function
   * @return {Object}
   */
  function getScheduleClassificationFormConfiguration(initData) {
    let chartSettings;
    let defaultFilter;
    if (
      initData != null &&
      Array.isArray(initData.chartSettings) &&
      initData.chartSettings.length > 0
    ) {
      chartSettings = initData.chartSettings[0];
      if (initData.filterType != null) {
        // filter type === time range
        if (initData.filterType === 100) {
          defaultFilter = {
            futureTimeRange: {
              numberOfUnits: initData.futureNumberOfUnits,
              scheduleClassification: initData.futureScheduleClassification
            },
            historyTimeRange: {
              numberOfUnits: initData.historyNumberOfUnits,
              scheduleClassification: initData.historyScheduleClassification
            }
          };
          // filter type === number of values
        } else if (initData.filterType === 200) {
          defaultFilter = {
            numberOfValues: initData.numberOfValues
          };
        }
        defaultFilter.filterType = initData.filterType;
      } else if (
        chartSettings != null &&
        chartSettings.dataVisualizationConfig != null
      ) {
        defaultFilter = chartSettings.dataVisualizationConfig.defaultFilter;
      }
    }
    let fields = [
      {
        id: 'scheduleClassification',
        title: gettextCatalog.getString('Schedule classification'),
        required: true,
        type: {
          name: 'autocomplete',
          options: {
            items: TranslationService.GetCollection(
              'codelists.scheduleClassifications'
            ),
            filter: items => {
              let allSelectedItems = vm.scheduleClassification.reduce(
                (result, item) => {
                  let selected = item.formApi.getValue(
                    'scheduleClassification'
                  );
                  if (selected != null) {
                    result = [...result, selected];
                  }
                  return result;
                },
                []
              );
              return items.filter(
                item =>
                  !allSelectedItems.find(selected => selected.id == item.id)
              );
            },
            valueParam: 'id',
            crawlerParams: searchTerm => ({
              param: 'name',
              searchTerm,
              type: 'string'
            }),
            display: item => ({ text: item.name })
          }
        },
        initialize: () => {
          if (initData != null) {
            return initData.scheduleClassification;
          }
          return null;
        }
      },
      {
        id: 'defaultSpecifications',
        type: {
          name: 'checkbox',
          options: {
            items: [
              {
                name: gettextCatalog.getString('Default specification'),
                _id: 'isMain'
              }
            ]
          }
        },
        onChange: () => {
          let numberOfMains = vm.scheduleClassification.reduce(
            (result, item) => {
              let defaultSpecifications = item.formApi.getValue(
                'defaultSpecifications'
              );

              return defaultSpecifications && defaultSpecifications.isMain
                ? result + 1
                : result;
            },
            0
          );
          vm.scheduleClassification.forEach(item => {
            item.formApi.setFieldConfigurationProperty(
              'defaultSpecifications',
              'required',
              numberOfMains > 0 ? false : true
            );
          });
          vm.numberOfMains = numberOfMains;
        }
      },
      ...partialScheduleClassificationConfiguration.timeSeriesVersion(
        defaultFilter
      ),
      {
        id: 'chartSettings',
        title: gettextCatalog.getString('Series'),
        type: {
          name: 'chartSettings',
          options: {
            groupId: vm.dndListId || 'dndList',
            yellowBackground: false,
            moveCallback: addTimeSeriesToScheduleClassifications,
            getClassificationModel: setMoveTimeseriesFormValues
          }
        },
        initialize: () => {
          if (initData != null) {
            return initData.chartSettings;
          }
          return [];
        }
      }
    ];
    return {
      name: Math.random()
        .toString(36)
        .substring(2),
      fields
    };
  }
  /**
   * @description adds/removes timeseries to selected/deselected schedule classifications.
   * @function
   * @param {Object} ref selectedSchedulers
   * @param {Object} timeseries timeseries to move
   */
  async function addTimeSeriesToScheduleClassifications(
    { selectedSchedulers },
    timeseries
  ) {
    if (selectedSchedulers != null && typeof selectedSchedulers == 'object') {
      vm.scheduleClassification.forEach(classification => {
        let classificationTimeSeries = classification.formApi.getValue(
          'chartSettings'
        );
        let selectedClassification = classification.formApi.getValue(
          'scheduleClassification'
        );
        if (
          selectedClassification != null &&
          typeof selectedClassification == 'object'
        ) {
          let timeSeriesInSelectedClassification = classificationTimeSeries.find(
            item => item._id == timeseries._id
          );
          if (selectedSchedulers[selectedClassification.id]) {
            if (timeSeriesInSelectedClassification == null) {
              //ADD TIME SERIES TO SCHEDULE CLASSIFICATION
              let newValue = [
                ...classificationTimeSeries,
                angular.copy(timeseries)
              ];
              classification.formApi.setValue('chartSettings', newValue);
            }
          } else {
            if (timeSeriesInSelectedClassification != null) {
              //REMOVE TIMESERIES OUT OF SCHEDULE CLASSIFICATION
              let newValue = classificationTimeSeries.filter(
                item => item._id != timeseries._id
              );
              classification.formApi.setValue('chartSettings', newValue);
            }
          }
          delete selectedSchedulers[selectedClassification.id];
        }
      });

      //add new scheduler classification forms
      if (Object.keys(selectedSchedulers).length > 0) {
        Object.keys(selectedSchedulers).forEach(classificationKey => {
          if (selectedSchedulers[classificationKey]) {
            addNewClassification({
              scheduleClassification: classificationKey,
              chartSettings: [angular.copy(timeseries)]
            });
          }
        });
        //if new schedule classification were added sort schedule forms
        await $timeout();
        vm.scheduleClassification = vm.scheduleClassification.sort((a, b) => {
          let aSelectedClassification = a.formApi.getValue(
            'scheduleClassification'
          );
          let bSelectedClassification = b.formApi.getValue(
            'scheduleClassification'
          );
          if (
            aSelectedClassification != null &&
            bSelectedClassification != null
          ) {
            return (
              aSelectedClassification.order - bSelectedClassification.order
            );
          }
          return 0;
        });
      }
    }
  }
  /**
   * @description returns model object for move time series from dialog.
   * @function
   * @param {String} timeseries timeseries id that should be moved
   * @return {Object}
   */
  function setMoveTimeseriesFormValues(timeseries) {
    return vm.scheduleClassification.reduce((result, classification) => {
      let classificationTimeSeries = classification.formApi.getValue(
        'chartSettings'
      );
      if (Array.isArray(classificationTimeSeries)) {
        let selectedTimeSeriesExist = classificationTimeSeries.find(
          item => item._id == timeseries
        );
        if (selectedTimeSeriesExist != null) {
          let selectedClassification = classification.formApi.getValue(
            'scheduleClassification'
          );
          if (
            selectedClassification != null &&
            typeof selectedClassification == 'object'
          ) {
            result = {
              ...result,
              [selectedClassification.id]: true
            };
          }
        }
      }
      return result;
    }, {});
  }
  /**
   * @description sets form configuration for timeseries group main part.
   * @function
   */
  function initializeForm() {
    let displayTypes = TranslationService.GetCollection(
      'codelists.displayTypes'
    );
    // Remove 'gauge' and 'gauge and table' from displayTypes
    let filteredDisplayTypes = displayTypes.filter(
      displayType => displayType.id !== 4 && displayType.id !== 5
    );
    vm.formConfig = {
      name: 'metadata',
      fields: [
        {
          id: 'name',
          title: gettextCatalog.getString('Name'),
          type: {
            name: 'text',
            options: {
              type: 'text'
            }
          },
          required: true
        },
        {
          id: 'description',
          title: gettextCatalog.getString('Description'),
          type: {
            name: 'textArea',
            options: {
              maxLength: 200
            }
          },
          required: false
        },
        {
          id: 'timeSeriesGroupType',
          title: gettextCatalog.getString('Time Series Group Type'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'time-series-group-types',
                method: 'read'
              },
              crawlerParams: text => {
                if (text != null && text != '') {
                  return { filter: text };
                }
                return { order: 'name' };
              },
              display: item => ({ text: item.name }),
              dialog: {
                entity: 'time-series-group-types'
              }
            }
          },
          required: true
        },
        {
          id: 'displayType',
          title: gettextCatalog.getString('Display types'),
          type: {
            name: 'radio',
            options: {
              layout: 'row',
              modelProperty: 'id',
              items: filteredDisplayTypes
            }
          },
          initialize: () => 1,
          required: true
        },
        ...ScheduleClassificationFormConfiguration.getSortFields()
      ]
    };
    vm.formApi = {};
  }
  /**
   * @description creates actions array.
   * @function
   */
  async function initializeActions() {
    await $timeout();
    vm.actions = [
      {
        title: gettext('Add schedule classification'),
        fn: addNewClassification
      },
      {
        title: gettext('Save time series group'),
        fn: async () => {
          savingGroup = true;
          let mainValues = vm.formApi.getValues();

          let scheduleClassifications = vm.scheduleClassification.map(item => {
            let values = item.formApi.getValues();
            let historyTimeRange =
              values.filterType == 100 && values.historyTimeRange.isChecked
                ? {
                  scheduleClassification:
                      values.historyScheduleClassification != null
                        ? values.historyScheduleClassification.id
                        : null,
                  numberOfUnits: values.historyNumberOfUnits
                }
                : null;
            let futureTimeRange =
              values.filterType == 100 && values.futureTimeRange.isChecked
                ? {
                  scheduleClassification:
                      values.futureScheduleClassification != null
                        ? values.futureScheduleClassification.id
                        : null,
                  numberOfUnits: values.futureNumberOfUnits
                }
                : null;
            let series = Array.isArray(values.chartSettings)
              ? values.chartSettings.map(item => {
                return {
                  timeSeries: item._id,
                  dataVisualizationConfig: {
                    chartType: item.chartType,
                    color: item.color
                  }
                };
              })
              : [];
            return {
              scheduleClassification: values.scheduleClassification.id,
              isMain: values.defaultSpecifications
                ? values.defaultSpecifications.isMain
                : false,
              defaultFilter: {
                filterType: values.filterType,
                numberOfValues:
                  values.filterType == 200 ? values.numberOfValues : null,
                historyTimeRange,
                futureTimeRange
              },
              series
            };
          });

          let displaySettings = {
            sortedBySumOfValues: false,
            defaultChartType: 10 //STACKED COLUMN
          };

          if (mainValues.sortType == 10) {
            //SORT BY SUM
            displaySettings = {
              ...displaySettings,
              defaultChartType: mainValues.chartType || 10, //STACKED COLUMN
              sortedBySumOfValues: true
            };
          }
          let apiObject = {
            name: mainValues.name,
            description: mainValues.description,
            timeSeriesGroupType:
              mainValues.timeSeriesGroupType != null
                ? mainValues.timeSeriesGroupType._id
                : null,
            dataVisualizationConfig: {
              displayType: mainValues.displayType,
              ...displaySettings
            },
            scheduleClassifications
          };
          let groupId;
          let action;
          try {
            if (vm.id) {
              let { data } = await TimeSeriesGroupModel.update(
                { id: vm.id },
                apiObject
              );
              groupId = data._id;
              action = 'update';
            } else {
              let { data } = await TimeSeriesGroupModel.create(apiObject);
              groupId = data._id;
              action = 'create';
            }

            CrudToastFactory.toast(action);

            $state.go(
              'data-time-series-groups-sandbox',
              { groupId, query: '' },
              { reload: true }
            );
          } catch (err) {
            AlertingService.Error(err);
          }
          savingGroup = false;
        },
        raised: true,
        disabledFn: () => {
          let valid = formValidity();
          //button is disabled when any of forms are invalid or
          //when number of main classifications is > 1
          return !valid || vm.numberOfMains > 1 || savingGroup;
        }
      }
    ];
  }
}
