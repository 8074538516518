VariableViewerConfigurations.$inject = [
  'gettextCatalog',
  'TranslationService',
  'CalculatedFlowattributesTimeSeriesConfigurations',
  'ColorService'
];

function VariableViewerConfigurations(
  gettextCatalog,
  TranslationService,
  CalculatedFlowattributesTimeSeriesConfigurations,
  ColorService
) {
  /**
   * @description get time series value, create object with text and link.
   * @function
   * @param {Object} inputTimeSeries
   * @return {Object} timeSeriesValue
   */
  function getTimeSeriesValue(inputTimeSeries) {
    let timeSeriesValueLinkParams = JSON.stringify({
      id: inputTimeSeries._id
    });
    let timeSeriesValue;
    let link;
    if (inputTimeSeries.name) {
      if (timeSeriesValueLinkParams != null) {
        link = `data-time-series-view(${timeSeriesValueLinkParams})`;
      }
      timeSeriesValue = {
        type: 'title',
        values: [
          {
            text: inputTimeSeries.name,
            link
          }
        ]
      };
    }
    return timeSeriesValue;
  }

  /**
   * @description get mapping value and construct mappingFunctionArguments view function.
   * @function
   * @param {number} mappingFunctionId
   * @param {Array} mappingFunctionArguments
   * @param {string} entityName
   * @return {string} mappingValue
   */
  function getMappingValue(mappingFunctionId, mappingFunctionArguments) {
    let mappingValue;
    let mappingArgs = '';
    let mappingFunction;
    mappingFunction = TranslationService.GetCollectionById(
      'codelists.calculatedFlowMappingFunctions',
      mappingFunctionId
    );
    if (Array.isArray(mappingFunctionArguments)) {
      // Go over mapping function args and create function for view.
      mappingArgs = mappingFunctionArguments.reduce(
        (result, mappingFunctionArg, index) => {
          if (mappingFunctionArg.name === 'n') {
            result += mappingFunctionArg.value;
          } else if (mappingFunctionArg.name === 'type') {
            let typeArgument = TranslationService.GetCollectionById(
              'codelists.integrationMethods',
              mappingFunctionArg.value
            );
            if (typeArgument != null) {
              result += typeArgument.name;
            }
          }
          if (index !== mappingFunctionArguments.length - 1) {
            result += ', ';
          }
          return result;
        },
        ''
      );
      if (mappingArgs != '') {
        mappingArgs = `( ${mappingArgs} )`;
      }
    }
    if (mappingFunction != null) {
      mappingValue = {
        text: mappingFunction.name + mappingArgs,
        title: gettextCatalog.getString('Mapping Function')
      };
    } else {
      mappingValue = 'Invalid mapping function';
    }
    return mappingValue;
  }

  function getCalculationTimestampDateTimeFilterConfigurations(
    calculationTimestampDateTimeFilter
  ) {
    let filterTypeConfig;
    let boundaryTypeConfig;
    let timeOffsetConfig;
    let fromConfig;
    let toConfig;
    if (calculationTimestampDateTimeFilter != null) {
      if (calculationTimestampDateTimeFilter != null) {
        let timeFilterType = TranslationService.GetCollectionById(
          'codelists.dateTimeFilterTypes',
          calculationTimestampDateTimeFilter.type
        );
        if (timeFilterType != null) {
          filterTypeConfig = {
            testId: 'timeFilterType',
            title: gettextCatalog.getString('Date Time Filter Type'),
            value: timeFilterType.name
          };
        }
      }

      let boundaryType;

      let timeUnit;
      let timeUnitName;

      let fromBoundaryType;
      let toBoundaryType;

      let fromBoundaryTypeName;
      let toBoundaryTypeName;

      let fromMapper;
      let toMapper;

      let fromIcon = {
        type: 2,
        name: 'radio_button_unchecked',
        color: ColorService.getApplicationColor('success')
      };
      let toIcon = {
        type: 2,
        name: 'radio_button_unchecked',
        color: ColorService.getApplicationColor('success')
      };
      switch (calculationTimestampDateTimeFilter.type) {
      case 200:
        boundaryType = TranslationService.GetCollectionById(
          'codelists.boundaryTypes',
          calculationTimestampDateTimeFilter.boundaryType
        );
        if (boundaryType != null) {
          let icon = {
            type: 2,
            name: 'radio_button_unchecked',
            color: ColorService.getApplicationColor('success')
          };
          if (boundaryType.id == 2) {
            icon = {
              type: 2,
              name: 'radio_button_checked',
              color: ColorService.getApplicationColor('success')
            };
          }
          boundaryTypeConfig = {
            testId: 'boundaryType',
            title: gettextCatalog.getString('Boundary Type'),
            value: boundaryType.name,
            icon
          };
        }
        break;
      case 400:
        timeUnit = TranslationService.GetCollectionById(
          'codelists.timeUnits',
          calculationTimestampDateTimeFilter.timeUnit
        );
        if (timeUnit != null) {
          timeUnitName = timeUnit.name;
          timeOffsetConfig = {
            testId: 'timeOffset',
            title: gettextCatalog.getString('Time offset'),
            value: `${calculationTimestampDateTimeFilter.offsetValue} ${timeUnitName}`
          };
        }
        break;
      case 500:
        fromMapper = constructDateTimeMapper(
          calculationTimestampDateTimeFilter.fromDateTimeMapper
        );
        toMapper = constructDateTimeMapper(
          calculationTimestampDateTimeFilter.toDateTimeMapper
        );
        fromBoundaryType = TranslationService.GetCollectionById(
          'codelists.boundaryTypes',
          calculationTimestampDateTimeFilter.fromBoundaryType
        );
        toBoundaryType = TranslationService.GetCollectionById(
          'codelists.boundaryTypes',
          calculationTimestampDateTimeFilter.toBoundaryType
        );

        if (fromBoundaryType != null) {
          fromBoundaryTypeName = fromBoundaryType.name;
          if (fromBoundaryType.id == 2) {
            fromIcon = {
              type: 2,
              name: 'radio_button_checked',
              color: ColorService.getApplicationColor('success')
            };
          }
        }
        if (toBoundaryType != null) {
          toBoundaryTypeName = toBoundaryType.name;
          if (toBoundaryType.id == 2) {
            toIcon = {
              type: 2,
              name: 'radio_button_checked',
              color: ColorService.getApplicationColor('success')
            };
          }
        }
        fromConfig = {
          title: gettextCatalog.getString('From'),
          mapper: fromMapper,
          value: fromBoundaryTypeName,
          icon: fromIcon
        };
        toConfig = {
          title: gettextCatalog.getString('To'),
          mapper: toMapper,
          value: toBoundaryTypeName,
          icon: toIcon
        };
      }
    }
    return {
      filterTypeConfig,
      boundaryTypeConfig,
      timeOffsetConfig,
      fromConfig,
      toConfig
    };
  }

  /**
   * @description create variable configuration from input data.
   * @function
   * @param {Array} data
   * @return {Array} configuration
   */
  function getVariableConfiguration(data) {
    let configuration = {
      title: gettextCatalog.getPlural(
        3,
        'Input time series',
        'Input time series',
        {}
      ),
      testId: 'inputTimeSeries',
      items: []
    };
    // Go over input time series and construct values for configuration based on input data.
    let items = data.inputTimeSeries.reduce((result, inputTimeSeries) => {
      let timeSeriesValue;
      if (inputTimeSeries.timeSeries != null) {
        timeSeriesValue = getTimeSeriesValue(inputTimeSeries.timeSeries);
        if (
          timeSeriesValue != null &&
          inputTimeSeries.triggersCalculation === true
        ) {
          timeSeriesValue.labels = [
            {
              color: 'primary',
              text: gettextCatalog.getString('Triggers calculation')
            }
          ];
        }
      }
      // Then go over time series params and construct items for configuration, put previously constructed values into said item.
      let timeSeriesVariables = inputTimeSeries.timeSeriesParams.map(
        timeSeriesParam => {
          // STREAM ARCHIVE PROPERTY
          let streamArchiveProperty = TranslationService.GetCollectionById(
            'codelists.streamArchiveProperties',
            timeSeriesParam.streamArchiveProperty
          );
          let streamArchivePropertyConfig;
          if (streamArchiveProperty != null) {
            streamArchivePropertyConfig = {
              testId: 'streamArchiveProperty',
              title: gettextCatalog.getString('Stream Archive Property'),
              value: streamArchiveProperty.name
            };
          }

          const {
            filterTypeConfig,
            boundaryTypeConfig,
            timeOffsetConfig,
            fromConfig,
            toConfig
          } = getCalculationTimestampDateTimeFilterConfigurations(
            timeSeriesParam.calculationTimestampDateTimeFilter
          );

          let exceptionHandlerConfig;
          if (timeSeriesParam.exceptionHandler != null) {
            let exceptionHandlerType = TranslationService.GetCollectionById(
              'codelists.mappingExceptionActionTypes',
              timeSeriesParam.exceptionHandler.actionType
            );
            if (exceptionHandlerType != null) {
              let value;
              if (exceptionHandlerType.id == 200) {
                //VALUE
                value =
                  timeSeriesParam.exceptionHandler.actionArguments[0].value;
              } else {
                value = `${exceptionHandlerType.name}`;
                if (
                  Array.isArray(
                    timeSeriesParam.exceptionHandler.actionArguments
                  ) &&
                  timeSeriesParam.exceptionHandler.actionArguments.length > 0
                ) {
                  value = `${exceptionHandlerType.name}(${timeSeriesParam.exceptionHandler.actionArguments[0].value})`;
                }
              }
              exceptionHandlerConfig = {
                testId: 'exceptionHandler',
                title: gettextCatalog.getString('Exception Handler'),
                value
              };
            }
          }

          let rows = [];
          if (streamArchivePropertyConfig != null) {
            rows.push(streamArchivePropertyConfig);
          }

          if (filterTypeConfig != null) {
            rows.push(filterTypeConfig);
            if (boundaryTypeConfig != null) {
              rows.push(boundaryTypeConfig);
            }

            if (timeOffsetConfig != null) {
              rows.push(timeOffsetConfig);
            }

            if (fromConfig != null) {
              rows.push(fromConfig);
            }
            if (toConfig != null) {
              rows.push(toConfig);
            }
          }

          if (exceptionHandlerConfig != null) {
            rows.push(exceptionHandlerConfig);
          }

          let item = {
            type: 'variable',
            variableName: timeSeriesParam.name,
            values: [],
            rows
          };

          let mappingValue = getMappingValue(
            timeSeriesParam.mappingFunction,
            timeSeriesParam.mappingFunctionArguments
          );

          item.values.push(mappingValue);

          return item;
        }
      );

      if (timeSeriesValue == null) {
        timeSeriesValue = {};
      }
      let group = {
        title: timeSeriesValue,
        items: timeSeriesVariables
      };

      return [...result, group];
    }, []);

    configuration.groupItems = items;
    return [configuration];
  }

  function constructDateTimeMapper(mapper) {
    if (mapper != null) {
      return Object.keys(mapper).map(mapperKey => {
        let id = getTimeUnitId(mapperKey);
        let timeUnit = TranslationService.GetCollectionById(
          'codelists.timeUnits',
          id
        );
        let timeUnitName = '';
        if (timeUnit != null) {
          timeUnitName = timeUnit.name;
        }
        let dateTimeMappingType = TranslationService.GetCollectionById(
          'codelists.dateTimeMappingTypes',
          mapper[mapperKey].dateTimeMappingType
        );
        let dateTimeMappingTypeName;
        if (dateTimeMappingType != null) {
          dateTimeMappingTypeName = dateTimeMappingType.name;
        }

        return {
          timeUnitName,
          dateTimeMappingTypeName,
          value: mapper[mapperKey].value
        };
      });
    }
  }

  function getTimeUnitId(key) {
    switch (key) {
    case 'seconds':
      return 1;
    case 'minutes':
      return 2;
    case 'hours':
      return 3;
    case 'days':
      return 4;
    case 'weeks':
      return 5;
    case 'months':
      return 6;
    case 'years':
      return 7;
    }
    return 0;
  }
  /**
   * @description get current entity.
   * @function
   * @param {Object} state
   * @param {string} entityId
   * @param {number} entity
   * @return {Object}
   */
  function getEntity(state, entityId, entity) {
    switch (entity) {
    // Calculated flow
    case 242:
      if (
        state.calculatedFlow != null &&
          state.calculatedFlow[entityId] != null &&
          state.calculatedFlow[entityId].data != null &&
          typeof state.calculatedFlow[entityId].data === 'object'
      ) {
        return {
          data: state.calculatedFlow[entityId].data,
          entityName: 'calculatedFlow'
        };
      }
      break;
    }
    return {};
  }

  /**
   * @description get variable viewer configuration, expression & expression variables.
   * @function
   * @param {Object} state
   * @param {string} entityId
   * @param {number} entity
   * @return {Object}
   */
  function getVariableViewerConfiguration(state, entityId, entity) {
    let expression;
    let expressionVariables;
    let configuration = [];
    let { data } = getEntity(state, entityId, entity);
    if (data != null) {
      expression = data.expression;
      let variables = data.inputTimeSeries.reduce((result, inputTimeSeries) => {
        let timeSeriesVariables = inputTimeSeries.timeSeriesParams.reduce(
          (timeSeriesVariable, timeSeriesParam) => {
            timeSeriesVariable.push(timeSeriesParam.name);
            return timeSeriesVariable;
          },
          []
        );
        return [...result, ...timeSeriesVariables];
      }, []);

      let attributeVariables = [];
      if (Array.isArray(data.inputDynamicAttributes)) {
        attributeVariables = data.inputDynamicAttributes.map(item => item.name);
      }
      expressionVariables = () => [...variables, ...attributeVariables];
      configuration = [
        ...getVariableConfiguration(data),
        ...CalculatedFlowattributesTimeSeriesConfigurations.getAttributes(data)
      ];
    }

    return { configuration, expression, expressionVariables };
  }
  return {
    getVariableViewerConfiguration
  };
}

export default VariableViewerConfigurations;
