TangoItemController.$inject = ['$state'];

function TangoItemController($state) {
  const vm = this;
  switch ($state.current.name) {
  case 'data-time-series-view':
    vm.entity = 234; // time series code list id
    vm.entityId = $state.params.id;
    break;
  }
}

export default TangoItemController;
