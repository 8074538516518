import routes from './visualization.routes';
// controllers
import VisualizationEditorController from './pages/visualization-editor/visualization-editor.controller';
import VisualizationViewerController from './pages/visualization-viewer/visualization-viewer.controller';
import ComponentEditorController from './pages/component-editor/component-editor.controller';

// configurations
import visualizationConfiguration from './configurations/visualization.configuration';

// network models
import VisualizationModel from './network-models/Visualization.model';
import VisualizationElementModel from './network-models/VisualizationElement.model';
import VisualizationImageModel from './network-models/VisualizationImage.model';

export default {
  routes,
  controllers: {
    VisualizationEditorController,
    VisualizationViewerController,
    ComponentEditorController
  },
  factories: {
    visualizationConfiguration
  },
  services: {
    VisualizationModel,
    VisualizationElementModel,
    VisualizationImageModel
  }
};
