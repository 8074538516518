import routes from './device.routes';

// controllers
import ConnectorsListController from './pages/connectors-list/connectors.list.controller';
import TangoAgentConnectionConfigController from './pages/tango-agent-connection-config/tango-agent-connection-config.controller';
import NewTangoAgentConnectionConfigController from './pages/tango-agent-connection-config/new-tango-agent-connection-config.controller';
import ExternalDatasourceController from './pages/external-datasource/external-datasource.controller';
import NewExternalDatasourceFormController from './pages/external-datasource/new-external-datasource.controller';
import JobResultsController from './pages/job-results/job-results.controller';
import DatabaseController from './pages/database/database.controller';
import NewDatabaseFormController from './pages/database/new-database.controller';
import FileController from './pages/file/file.controller';
import NewFileFormController from './pages/file/new-file.controller';
import OPCController from './pages/opc/opc.controller';
import NewOPCFormController from './pages/opc/new-opc.controller';
import WebserviceController from './pages/webservice/webservice.controller';
import NewWebserviceFormController from './pages/webservice/new-webservice.controller';

// components
import tagSelector from './directives/sfe-tag-explorer/sfe-tag-explorer.component';
// configurations
import TimeseriesTangoAgentConnectionConfigConfiguration from './configurations/time-series-tango-agent-connection-config.configuration';
import ExternalDatasourceConfiguration from './configurations/external-datasource.configuration';
import jobConfiguration from './configurations/jobs.configuration';
import TangoAgentConnectionConfigConfiguration from './configurations/tango-agent-connection-config.configuration';

// services
import ExternalDatasourceDataXConfiguration from './services/ExternalDatasourceDataXConfiguration.service';

// network models
import DatabaseModel from './network-models/Database.model';
import TangoAgentConnectionConfigModel from './network-models/TangoAgentConnectionConfig.model';
import ExternalDatasourceModel from './network-models/ExternalDatasource.model';
import ExternalDatasourceRegenerateCredentialsModel from './network-models/ExternalDatasourceRegenerateCredentials.model';
import FileModel from './network-models/File.model';
import HeartbeatHistoryLastModel from './network-models/HeartbeatHistoryLast.model';
import JobModel from './network-models/Job.model';
import OpcModel from './network-models/Opc.model';
import WebserviceModel from './network-models/Webservice.model';

export default {
  routes,
  controllers: {
    ConnectorsListController,
    TangoAgentConnectionConfigController,
    NewTangoAgentConnectionConfigController,
    ExternalDatasourceController,
    NewExternalDatasourceFormController,
    JobResultsController,
    DatabaseController,
    NewDatabaseFormController,
    FileController,
    NewFileFormController,
    OPCController,
    NewOPCFormController,
    WebserviceController,
    NewWebserviceFormController
  },
  components: {
    tagSelector
  },
  factories: {
    jobConfiguration,
    ExternalDatasourceConfiguration,
    TimeseriesTangoAgentConnectionConfigConfiguration,
    TangoAgentConnectionConfigConfiguration
  },
  services: {
    DatabaseModel,
    TangoAgentConnectionConfigModel,
    ExternalDatasourceModel,
    ExternalDatasourceRegenerateCredentialsModel,
    FileModel,
    HeartbeatHistoryLastModel,
    JobModel,
    OpcModel,
    WebserviceModel,
    ExternalDatasourceDataXConfiguration
  }
};
