import cachingModels from './CachingModels.constant';

CachingControl.$inject = [
  '$state',
  'StorageService',
  'NetworkModels',
  '$interval'
];
/**
 * @ngdoc service
 * @name common.CachingControl
 * @description Controls global caching
 * @property {function} anonymousFunction
 */
export default function CachingControl(
  $state,
  StorageService,
  NetworkModels,
  $interval
) {
  let globalCacheOn;
  const globalCacheTime = 10 * 60 * 1000; // 10 minutes
  const revalidationTime = 30 * 60 * 1000;

  /**
   * @description Toggle the value of global cache.
   * @function
   */
  function ToggleCache() {
    globalCacheOn = !globalCacheOn;
    StorageService.setGlobalCache(globalCacheOn);
  }

  /**
   * @description Set global cache value.
   * @function
   * @param {bool} value
   */
  function SetCache(value) {
    globalCacheOn = value;
    StorageService.setGlobalCache(globalCacheOn);
  }

  /**
   * @description Refresh all caches.
   * @param {bool} reload - reload after refresh
   * @function
   */
  function RefreshCache(reload) {
    let model;
    cachingModels.forEach(modelName => {
      // go over all models and call refreshModelStorage to reset its data
      model = NetworkModels(modelName);
      if (model && model.refreshModelStorage) {
        model.refreshModelStorage();
      } else {
        // eslint-disable-next-line no-console
        console.warn(
          'Model "' + modelName + '" has no method for cache invalidation.'
        );
      }
    });
    if (reload) $state.reload();
  }

  /**
   * @description Return whether globalCache flag value.
   * @function
   */
  function GlobalCacheOn() {
    return globalCacheOn;
  }

  /**
   * @description Return global cache revalidation time.
   * @function
   * @return {number}
   */
  function GetItemCacheTime() {
    return globalCacheTime;
  }

  /**
   * @description Triggers app-wide re-validatation of cache.
   * @function
   */
  function StartInvalidatingCache() {
    $interval(() => {
      let model;
      cachingModels.forEach(modelName => {
        // go over all models and call refreshModelStorage to reset its data
        model = NetworkModels(modelName);
        if (model.revalidateModelStorage) {
          model.revalidateModelStorage();
        } else {
          // eslint-disable-next-line no-console
          console.warn(
            'Model "' + modelName + '" has no method for cache revalidation.'
          );
        }
      });
    }, revalidationTime); // invalidate once every 30 min
  }

  var api = {
    ToggleCache,
    SetCache,
    RefreshCache,
    GlobalCacheOn,
    GetItemCacheTime,
    StartInvalidatingCache
  };
  return api;
}
