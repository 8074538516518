import tangoDataXGroup from './templates/tangoDataX-group.template.html';
import analysisTemplate from './templates/analysis.template.html';
import weatherTemplate from './templates/weather.template.html';
import tangoDataX from './templates/tangoDataX.template.html';
import locationCardTemplate from './templates/location-card.template.html';
import visualizationTemplate from './templates/visualization.template.html';
import alarmsTemplate from './templates/alarms.template.html';
import invoiceTemplate from './templates/invoice.template.html';
import mtanalysisTemplate from './templates/mt-analysis.template.html';
import imageTemplate from './templates/image.template.html';
import valuesTemplate from './templates/values.template.html';
import locationsMapTemplate from './templates/locations-map.template.html';
import gisTemplate from './templates/gis.template.html';
import privilegeManagementTemplate from './templates/privilege-management.template.html';
import iframeTemplate from './templates/iframe.template.html';

function dashboardTemplates() {
  // comments for caching
  return {
    3: tangoDataXGroup, // timeseries group
    4: analysisTemplate, // analysis
    5: weatherTemplate, // weather
    6: tangoDataX, // timeseries
    10: locationCardTemplate, // done
    12: visualizationTemplate,
    13: alarmsTemplate, // done
    14: invoiceTemplate, // done (looks done, can't test it)
    15: mtanalysisTemplate, // ? none works
    16: imageTemplate,
    17: valuesTemplate, // done
    18: locationsMapTemplate, // done
    19: locationsMapTemplate, // done
    20: gisTemplate,
    21: iframeTemplate,
    'privilege-management': privilegeManagementTemplate
  };
}

export default dashboardTemplates;
