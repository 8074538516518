module.exports = {
  entity: 'asset-models',
  path: 'asset-models',
  name: {
    en: 'Asset models',
    sl_SI: 'Modeli sredstev'
  },
  networkModel: {
    entity: 'asset-models',
    read: 'read'
  },
  scope: 'tis_be:asset-models',

  dialog: {
    title: {
      en: 'Select asset model',
      sl_SI: 'Izberite model sredstva'
    },
    listInfo: {
      en: 'Select asset model from the list below',
      sl_SI: 'Izberite model sredstva s spodnjega seznama'
    }
  }
}
