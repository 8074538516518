/**
 * @ngdoc directive
 * @name common.sfePhoneNumber
 * @description Directive for formatting, parsing and validating phone numbers.
 * @example
 *  <input
 *    sfe-phone-number
 * ></input>
 */

function sfePhoneNumber(Formatting) {
  const directive = {
    restrict: 'A',
    require: 'ngModel',
    controllerAs: 'vm',
    bindToController: true,
    link: (scope, element, attrs, ngModel) => {
      /**
       * @description remove white spaces from phone number.
       * @function
       * @param {string} phoneNumber
       * @return {string/null} parsed phone number or null
       */
      const removeSpaces = value => {
        if (value != null) {
          let parsedValue = value.toString().replace(/\s/g, '');
          return parsedValue;
        }
        return null;
      };
      /**
       * @description check if phone number is valid.
       * @function
       * @param {string} phoneNumber
       * @return {boolean}
       */
      const phoneNumberValidation = modelValue => {
        // Slovenian numbers only
        if (
          modelValue != null &&
          modelValue.length === 12 &&
          modelValue[0] === '+'
        ) {
          return true;
        }
        return false;
      };
      /**
       * @description remove white spaces from phone number, actual model value.
       * @function
       * @param {string} value
       * @return {string} value with removed whitespaces.
       */
      const phoneNumberParser = viewValue => {
        if (viewValue != null) {
          let caretPosition = element[0].selectionStart;
          const value = Formatting.formatPhoneNumber(viewValue);
          ngModel.$viewValue = value;
          ngModel.$render();
          let previusCharacter = value.charAt(caretPosition - 1);
          if (
            (viewValue.length < value.length && previusCharacter === ' ') ||
            previusCharacter === '+'
          ) {
            caretPosition += 1;
          }
          element[0].setSelectionRange(caretPosition, caretPosition);
          ngModel.$commitViewValue();
          return removeSpaces(value);
        }
        return null;
      };
      /**
       * @description format phone number, add white spaces and convert to string, displayed value in view.
       * @function
       * @param {string} value
       * @return {string} value with added whitespaces for view
       */
      const phoneNumberFormatter = modelValue => {
        if (modelValue != null) {
          const value = removeSpaces(modelValue);
          ngModel.$modelValue = value;
          return Formatting.formatPhoneNumber(value).toString();
        }
        return null;
      };
      ngModel.$validators.phoneNumber = phoneNumberValidation;
      ngModel.$parsers.push(phoneNumberParser);
      ngModel.$formatters.push(phoneNumberFormatter);
    }
  };
  return directive;
}

export default ['Formatting', sfePhoneNumber];
