TaskModel.$inject = ['ModelUtilities'];

function TaskModel(ModelUtilities) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'tasks'
  };

  const networkConfigurationAllLang = {
    host: 'api',
    path: 'tasks',
    allLang: true
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readAllLang: ModelUtilities.crud(
        'GET',
        networkConfigurationAllLang,
        modelStorage
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default TaskModel;
