DetailTimeSeriesConfiguration.$inject = [
  'ValidateObjectConfiguration',
  'TranslationService',
  'TimeSeriesProcessingValuesModel',
  'AlertingService',
  '$filter',
  'DateLocalizationService'
];
/**
 * @ngdoc service
 * @name common.DetailTimeSeriesConfiguration
 * @description TODO
 * @property {function} get
 */
export default function DetailTimeSeriesConfiguration(
  ValidateObjectConfiguration,
  TranslationService,
  TimeSeriesProcessingValuesModel,
  AlertingService,
  $filter,
  DateLocalizationService
) {
  //Configuration is used to validate time series object
  const validateMinMaxAndBoundaries = [
    {
      fieldName: 'boundaries',
      type: 'array',
      required: false
    },
    {
      //data visualization config is valid
      //when both minimumValue and maximumValue
      //exist and both are valid
      fieldName: 'dataVisualizationConfig',
      type: 'object',
      required: true,
      configuration: [
        {
          fieldName: 'minimumValue',
          type: 'number',
          required: true
        },
        {
          fieldName: 'maximumValue',
          type: 'number',
          required: true
        }
      ]
    },
    {
      fieldName: 'dataType',
      required: true,
      type: 'number'
    },
    {
      fieldName: 'measurementUnit',
      required: true,
      type: 'object',
      configuration: [
        {
          fieldName: 'symbol',
          required: true,
          type: 'string'
        }
      ]
    }
  ];

  /**
   * @description checks that field validation is successful.
   * @function
   * @param {Object} config validation result field configuration
   * @return {Boolean}
   */
  const configIsValid = config =>
    config.warning.noValue == false &&
    config.error.invalid == false &&
    config.error.required == false;

  /**
   * @description returns value configuration for no value or invalid value.
   * @function
   * @param {object} valueObject
   * @param {Object}
   * @param      {Boolean} noValue
   * @param      {Boolean} invalid
   * @return {Object}
   */
  const setValueError = (valueObject, { noValue, invalid }) => ({
    ...valueObject,
    error: {
      noValue,
      invalid
    }
  });
  /**
   * @description depending on time series data type returns validation object.
   * @function
   * @param {number} dataType dataType codelist id
   * @return {Object}
   */
  const getLastValueValidationConfig = dataType => {
    let type;
    switch (dataType) {
    case 1:
      type = 'boolean';
      break;
    case 2:
    case 3:
      type = 'number';
      break;
    case 4:
      type = 'string';
      break;
    case 5:
    case 6:
    case 7:
      type = 'date';
    }
    return [
      {
        fieldName: 'value',
        type,
        required: true
      }
    ];
  };
  /**
   * @description depending on data type formats last value.
   * @function
   * @param {number} dataType dataType codelist id
   * @param {object} value {value}
   * @return {Object}
   */
  function formatValue(dataType, value, precision, timeZone) {
    let valueObject;
    const validation = getLastValueValidationConfig(dataType);
    let configuration;
    if (value != null && value.value === null) {
      configuration = [
        {
          error: { required: false, invalid: false },
          fieldName: 'value',
          validity: true,
          warning: { noValue: false }
        }
      ];
    } else {
      let validatedConfiguration = ValidateObjectConfiguration.validate(
        value,
        validation
      );
      configuration = validatedConfiguration
        ? validatedConfiguration.configuration
        : [];
    }
    if (Array.isArray(configuration) && configuration.length > 0) {
      if (configuration[0].warning.noValue) {
        //NO VALUE
        valueObject = setValueError(valueObject, {
          noValue: true
        });
      } else if (configuration[0].error.invalid) {
        // INVALID VALUE
        valueObject = setValueError(valueObject, {
          invalid: true
        });
      } else {
        if (value.value === null) {
          valueObject = {
            value: String(value.value)
          };
        } else {
          //FORMAT VALUE
          switch (dataType) {
          case 1: //BOOLEAN
            valueObject = {
              value: String(value.value)
            };

            break;
          case 2: //INTEGER
          case 3: //DECIMAL
            valueObject = {
              value: $filter('numberFormat')(value.value, precision)
            };
            break;
          case 4: //STRING
            valueObject = {
              value: value.value
            };
            break;
          case 5: //DATE
            valueObject = {
              value: DateLocalizationService.LocalizationDateIntervalFn('d')(
                value.value,
                false
              )
            };
            break;
          case 6: //TIME
            valueObject = {
              value: DateLocalizationService.LocalizationDateIntervalFn(
                'timeonly'
              )(value.value, false)
            };
            break;
          case 7: //DATETIME
            valueObject = {
              value: DateLocalizationService.LocalizationDateIntervalFn('s')(
                value.value,
                false
              )
            };
            break;
          default:
            //INVALID TYPE
            valueObject = setValueError(valueObject, { invalid: true });
          }
        }
      }
      valueObject = {
        ...valueObject,
        time: DateLocalizationService.LocalizationDateFn(
          value.validAt,
          false,
          timeZone
        )
      };
    } else {
      valueObject = setValueError(valueObject, {
        invalid: true
      });
    }
    return valueObject;
  }
  /**
   * @description returns entity object from the store if exists.
   * @function
   * @param {object} store
   * @param {string} entityId mongo id of the entity. Used to find it in the store
   * @return {Object}
   */
  function getStoredItem(store, entityId) {
    if (
      store != null &&
      store.timeSeries != null &&
      store.timeSeries[entityId] != null &&
      store.timeSeries[entityId].data != null &&
      typeof store.timeSeries[entityId].data == 'object'
    ) {
      return store.timeSeries[entityId].data;
    }
  }
  function fetchValue(entityId) {
    /**
     * @description used to fetch time series last value
     * @function
     * @param {object} store
     * @return {Promise} that resolves to object
     */
    return async store => {
      let valueObject = {};
      let rawValue;
      const item = getStoredItem(store, entityId);
      if (item != null) {
        try {
          const { data } = await TimeSeriesProcessingValuesModel.read({
            timeSeriesId: item._id,
            limit: 1
          });
          if (Array.isArray(data) && data.length > 0) {
            const lastValue = data[0];

            if (item.dataType == null) {
              valueObject = setValueError(valueObject, { invalid: true });
            } else {
              let timeZone = TranslationService.GetCollectionById(
                'codelists.timeZones',
                item.timeZone
              );
              if (timeZone != null) {
                timeZone = timeZone.code;
              }
              valueObject = formatValue(
                item.dataType,
                lastValue,
                item.precision,
                timeZone
              );
              rawValue = lastValue.value;
            }
          } else {
            //NO VALUE
            valueObject = setValueError(valueObject, { noValue: true });
          }
        } catch (err) {
          //NO VALUE
          AlertingService.Error(err);
          valueObject = setValueError(valueObject, { noValue: true });
        }
      }
      return { value: valueObject, rawValue };
    };
  }
  function valueFn(entityId) {
    /**
     * @description returns time series formatted metadata to display last value.
     * @function
     * @param {object} store
     * @return {Object}
     */
    return store => {
      const timeSeries = getStoredItem(store, entityId);
      if (timeSeries != null) {
        //Validate time series fields
        const validationResult = ValidateObjectConfiguration.validate(
          timeSeries,
          validateMinMaxAndBoundaries
        );
        // last value configuration contains sfe-item bindings
        let lastValueConfiguration = validationResult.configuration.reduce(
          (result, config) => {
            if (configIsValid(config)) {
              switch (config.fieldName) {
              case 'boundaries':
                result = {
                  ...result,
                  boundaries: timeSeries.boundaries
                };
                break;
              case 'dataVisualizationConfig':
                result = {
                  ...result,
                  minMax: {
                    min: timeSeries[config.fieldName].minimumValue,
                    max: timeSeries[config.fieldName].maximumValue
                  }
                };

                break;
              case 'dataType':
                result = {
                  ...result,
                  valueType:
                      timeSeries.dataType == 3 || timeSeries.dataType == 2
                        ? 'number'
                        : 'string'
                };
                break;
              case 'measurementUnit':
                // eslint-disable-next-line no-case-declarations
                let metricPrefix = TranslationService.GetCollectionById(
                  'codelists.metricPrefixes',
                  timeSeries.metricPrefix
                );
                  // eslint-disable-next-line no-case-declarations
                let prefixSymbol = '';
                if (metricPrefix != null) {
                  prefixSymbol = metricPrefix.symbol;
                }
                result = {
                  ...result,
                  valueUnit: `${prefixSymbol}${timeSeries.measurementUnit.symbol}`
                };
                break;
              }
            }
            return result;
          },
          {}
        );
        return {
          ...lastValueConfiguration
        };
      } else {
        // no item in the store
        return {};
      }
    };
  }

  /**
   * @memberof DetailTimeSeriesConfiguration.get
   * @description  returns object that contains configurations to build tango item
   * @param {number} entityId code list entity id
   * @return {Object}
   */
  function get(entityId) {
    return {
      type: 'dynamic',
      displayType: 'value',
      fetchValue: fetchValue(entityId),
      getStoredItem,
      valueFn: valueFn(entityId),
      intervalFrequency: 3000
    };
  }
  return {
    get
  };
}
