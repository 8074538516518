module.exports = {
  entity: 'gis-users',
  path: 'user',
  gisService: true,
  name: {
    en: 'Gis users',
    sl_SI: 'Gis uporabniki'
  },
  networkModel: {
    entity: 'gis-users',
    read: 'read'
  },
  dialog: {
    title: {
      en: 'Select gis user',
      sl_SI: 'Izberite gis uporabnika'
    },
    listInfo: {
      en: 'Select gis user from the list below',
      sl_SI: 'Izberite gis uporabnika s spodnjega seznama'
    },
    gisService: true,
    displayFields: [
      {
        combined: true,
        field1: 'first_name',
        field2: 'last_name',
        type: 'string'
      },
      {
        title: 'E mail',
        field1: 'email',
        type: 'string'
      },
      {
        title: 'Title',
        field1: 'title',
        type: 'string'
      }
    ]
  }
}
