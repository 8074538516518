SfeFilterService.$inject = ['CrawlerMethods', 'AlertingService', '$timeout'];
/**
 * @ngdoc service
 * @name common.SfeFilterService
 * @description prefetches saved filter values.
 * @property {function} assignSelectedFiltersValues
 */
export default function SfeFilterService(
  CrawlerMethods,
  AlertingService,
  $timeout
) {
  /**
   * @memberof SfeFilterService.assignSelectedFiltersValues
   * @description prefetches selected filter options.
   * @param {array} filters array of filters, that are displayed in sfe-list
   * @param {array} storedFilters array of filter configurations  containing stored filter values
   * (strings or select menu selected item ids) used by user before
   */
  async function assignSelectedFiltersValues(filters, storedFilters) {
    if (Array.isArray(filters)) {
      if (Array.isArray(storedFilters)) {
        //assign stored filter values to the actual filters
        storedFilters.forEach(storedFilter => {
          let foundFilter = filters.find(function(filter) {
            if (storedFilter.uniqId) {
              return filter.uniqId === storedFilter.uniqId;
            } else {
              return filter.param === storedFilter.param;
            }
          });

          if (foundFilter) {
            switch (foundFilter.type) {
            case 'string':
            case 'number':
              foundFilter.searchTerm = storedFilter.searchTerm;
              break;
            case 'dateCompare':
              if (storedFilter.date) {
                foundFilter.date = new Date(storedFilter.date);
              }
              break;

            case 'customDate':
              if (
                Array.isArray(storedFilter.date) &&
                  storedFilter.date[0] != null
              ) {
                foundFilter.date = [new Date(storedFilter.date[0])];
              }
              break;
            default:
              //dropdown selector
              foundFilter.selected = storedFilter.selected;
            }
          }
        });
      }
      //fetch selected filter items and assign them to filter options
      await asyncForEach(filters, async filter => {
        if (filter.selected && filter.selected.length > 0) {
          if (filter.collection) {
            filter.options = filter.collection;
          } else {
            filter.options = await fetchFilterOptions(filter);
          }
        }
      });
      $timeout();
    }
  }
  /**
   * @description allows to execute async functions in the loop.
   * @function
   * @param {Array} array array over which we iterate and execute async function on every item
   * @param {Function} callback async function that shouldn't throw an error
   */
  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  /**
   * @description gets filter as parameter, constructs filter items and fetches filter options.
   *               If request fails, function displays alert and returns empty array
   * @function
   * @param {Object} filter sfe-list filter object
   * @return {Array}
   */
  async function fetchFilterOptions(filter) {
    const paramName = filter.selectedParamName
      ? filter.selectedParamName
      : '_id';
    const filterObject = {
      [paramName]: filter.selected
    };
    const method = filter.query
      ? CrawlerMethods.getMethod(filter.query)
      : filter.ctrlFn;
    try {
      const { data: items } = await method(filterObject);
      return items;
    } catch (err) {
      if (err) {
        AlertingService.Error(err);
      }
      return [];
    }
  }
  return {
    assignSelectedFiltersValues: assignSelectedFiltersValues
  };
}
