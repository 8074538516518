NewAggregationController.$inject = [
  '$state',
  'gettext',
  'aggregationGroup',
  'AlertingService',
  'MetadataService',
  'createOrUpdateService',
  '$scope',
  'SfeHeader',
  '$timeout'
];

function NewAggregationController(
  $state,
  gettext,
  aggregationGroup,
  AlertingService,
  MetadataService,
  createOrUpdateService,
  $scope,
  SfeHeader,
  $timeout
) {
  var vm = this;
  var aggregationId = $state.params.id;
  let domainValues = [];

  init();

  function init() {
    vm.editMode = !!aggregationId;
    vm.dataConfig = {};

    vm.dataConfig.data = getFormConfig();

    if (vm.editMode) {
      MetadataService.Loading(true);
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit aggregation group'),
        'analytics-aggregation-groups-view',
        { id: aggregationId }
      );
      readAggregation();
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New aggregation group'),
        'analytics-aggregation-groups-list'
      );
      vm.dataConfig.dataObj = {
        aggregationType: {},
        conditions: []
      };
    }

    vm.dataConfig.action = {
      ctrlFn: true,
      title: vm.editMode ? gettext('Update') : gettext('Create'),
      fn: createOrUpdate
    };

    vm.createOrUpdate = [
      {
        title: aggregationId ? gettext('Update') : gettext('Create'),
        fn: createOrUpdate,
        color: 'primary',
        raised: true,
        disabledFn: function() {
          return (
            !$scope.AggregationForm.$valid ||
            !checkSelectedItemsLength() ||
            vm.sendingRequest
          );
        }
      }
    ];
  }

  function checkSelectedItemsLength() {
    if (vm.lists && vm.lists.selected && vm.lists.selected.length) {
      return true;
    }
    return false;
  }

  function getFormConfig() {
    return [
      {
        placeholder: 'Name',
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: 'Description',
        name: 'description',
        componentType: 'textArea',
        type: 'text',
        maxlength: 500,
        required: false
      },
      {
        configuration: {
          query: {
            entity: 'datasets',
            method: 'read'
          },
          entity: 'datasets',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Dataset')
          },
          floatingLabel: gettext('Select Dataset'),
          searchParamName: 'filter',
          required: true,
          createRedirect: {
            state: 'analytics-datasets-new'
          },
          change: datasetChanged
        },
        componentType: 'autocompleteDialog',
        name: 'dataset'
      },
      {
        hide: true,
        showParam: 'dataset',
        componentType: 'dndAutocomplete',
        name: 'conditions',
        required: true,
        displayFields: ['displayFieldName'],
        configuration: {
          domainValues,
          floatingLabel: gettext('Select column'),
          searchParamName: 'displayFieldName',
          required: false,
          displayFields: ['displayFieldName'],
          dialogConfiguration: {
            multiple: true,
            domainValues,
            title: gettext('Select column'),
            displayFields: ['displayFieldName']
          }
        }
      }
    ];
  }

  function datasetChanged() {
    if (vm.dataConfig.dataObj.dataset && vm.dataConfig.dataObj.dataset._id) {
      vm.columnsAvailable = vm.dataConfig.dataObj.dataset.columns;
      domainValues.length = 0;
      domainValues.push.apply(domainValues, vm.columnsAvailable);
      if (!vm.dataConfig.dataObj.dataset.__prefetched__value__) {
        vm.dataConfig.dataObj.conditions.length = 0;
      }
      vm.dataConfig.data[3].configuration.dialogConfiguration.domainValues = domainValues;
    }
  }

  function readAggregation() {
    let conditions = [];
    if (
      typeof aggregationGroup.dataset == 'object' &&
      aggregationGroup.dataset != null
    ) {
      aggregationGroup.dataset.__prefetched__value__ = true;
      if (
        Array.isArray(aggregationGroup.condition) &&
        Array.isArray(aggregationGroup.dataset.columns)
      ) {
        let foundAggregation;
        conditions = aggregationGroup.condition.reduce((result, item) => {
          foundAggregation = aggregationGroup.dataset.columns.find(
            column => column.rawFieldName == item
          );
          if (foundAggregation) {
            return [...result, foundAggregation];
          }
          return result;
        }, []);
      }
    }
    vm.dataConfig.dataObj = {
      _id: aggregationGroup._id,
      name: aggregationGroup.name,
      description: aggregationGroup.description,
      dataset: aggregationGroup.dataset,
      conditions
    };
    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(
        'Edit ' + aggregationGroup.name,
        aggregationGroup.description
      );
    });
  }

  // create and update functions
  function createOrUpdate() {
    vm.sendingRequest = true;
    createOrUpdateService
      .simpleCall(
        {
          entity: 'aggregation-groups',
          method: vm.editMode ? 'update' : 'create'
        },
        vm.editMode,
        aggregationId,
        dataToSave(),
        'analytics-aggregation-groups-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function dataToSave() {
    return {
      name: vm.dataConfig.dataObj.name,
      description: vm.dataConfig.dataObj.description,
      dataset: vm.dataConfig.dataObj.dataset._id,
      condition: vm.dataConfig.dataObj.conditions.map(item => item.rawFieldName)
    };
  }
}

export default NewAggregationController;
