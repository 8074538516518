SfeDynamicAttributesConfigurations.$inject = ['gettext'];

export default function SfeDynamicAttributesConfigurations(gettext) {
  var configurations = {};

  configurations['locations'] = {
    entityViewValueParam: 'locationAttribute',
    viewEntityRoute: 'locationdomainattributes',
    typeAtrributes: 'location-type-attributes',
    entityAtrributes: 'location-attributes',
    attributes: 'location-attributes',
    entityParam: 'location',
    emptyData: gettext('Make sure location type is selected.')
  };

  configurations['assets'] = {
    entityViewValueParam: 'assetAttribute',
    viewEntityRoute: 'assetdomainattributes',
    typeAtrributes: 'asset-type-attributes',
    entityAtrributes: 'asset-attributes',
    attributes: 'asset-attributes',
    entityParam: 'asset',
    emptyData: gettext('Make sure asset type is selected.')
  };

  configurations['time-series'] = {
    entityViewValueParam: 'timeSeriesAttribute',
    viewEntityRoute: 'timeseriesdomainattributes',
    typeAtrributes: 'time-series-type-attributes',
    entityAtrributes: 'time-series-attributes',
    attributes: 'time-series-attributes',
    entityParam: 'timeSeries',
    emptyData: gettext('Make sure time series type is selected.')
  };

  configurations['measuringpoints'] = {
    entityViewValueParam: 'measuringPointAttribute',
    viewEntityRoute: 'measuringpointdomainattributes',
    typeAtrributes: 'measuringpoint-type-attributes',
    entityAtrributes: 'measuringpoint-attributes',
    attributes: 'measuringpoint-attributes',
    entityParam: 'measuringpoint',
    emptyData: gettext('Make sure measuringpoint type is selected.')
  };

  return {
    configurations: configurations
  };
}
