UnauthenticatedController.$inject = [
  '$scope',
  '$smartAuth',
  '$state',
  '$timeout'
];

function UnauthenticatedController($scope, $smartAuth, $state, $timeout) {
  // CEU MIN MA NA DVA NARJEN
  $scope.login = function() {
    $timeout(function() {
      if (!$state.params.logout) {
        if ($smartAuth.loggedIn()) {
          $smartAuth.logout();
        }
        $smartAuth.login(window.realm);
      } else {
        $smartAuth.login(window.realm, true);
      }
    });
  };

  $scope.login();
}
export default UnauthenticatedController;
