module.exports = {
  entity: 'national-holidays',
  path: 'national-holidays',
  name: {
    en: 'National Holidays',
    sl_SI: 'Državni prazniki'
  },
  networkModel: {
    entity: 'national-holidays',
    read: 'read'
  },
  scope: 'tis_be:national-holidays',

  dialog: {
    title: {
      en: 'Select national holiday',
      sl_SI: 'Izberite državni praznik'
    },
    listInfo: {
      en: 'Select national holiday from the list below',
      sl_SI: 'Izberite državni praznik s spodnjega seznama'
    }
  }
}
