import './alarm-card-count-label.scss';
import template from './alarm-card-count-label.component.html';

/**
 * @ngdoc component
 * @name common.alarmCardCountLAbel
 * @description Displays gis map.
 * @param {Number} count - number of alarms
 * @param {Boolean} loading loading status
 * @param {string} userId parameter for redirect link
 * @example
 * <alarm-card-count-label
 * count="6"
 * loading="false"
 * user-id="vm.userId"
 * ></alarm-card-count-label>
 */

export default {
  restrict: 'E',
  template,
  bindings: {
    count: '<',
    loading: '<',
    userId: '<'
  },
  controller: AlarmCardCountLabelController,
  controllerAs: 'vm',
  bindToController: true
};
AlarmCardCountLabelController.$inject = ['$element'];
function AlarmCardCountLabelController($element) {
  const vm = this;

  vm.$onChanges = changes => {
    if (changes.count && typeof vm.count != 'undefined') {
      if (vm.count == 0) {
        $element.css('height', '100%');
      } else {
        $element.css('height', 'auto');
      }
    }
  };
}
