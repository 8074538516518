WorkScheduleModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function WorkScheduleModel(ModelUtilities, CrawlerMethods) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'work-schedules'
  };

  const crawlerConfigurationList = {
    entity: 'work-schedules',
    method: 'read',
    steps: [
      {
        entity: 'users',
        valueParams: ['user'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'populateArray'
      },
      {
        entity: 'users',
        valueParams: ['substituteUser'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'populateArray'
      }
    ]
  };

  const crawlerConfigurationRead = {
    entity: 'work-schedules',
    method: 'read',
    steps: [
      {
        entity: 'users',
        valueParams: ['user'],
        queryParams: ['id'],
        method: 'read',
        methodType: 'populate'
      },
      {
        entity: 'users',
        valueParams: ['substituteUser'],
        queryParams: ['id'],
        method: 'read',
        methodType: 'populate'
      }
    ]
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      listWithUsers: CrawlerMethods.crawler(crawlerConfigurationList),
      readWithUsers: CrawlerMethods.crawler(crawlerConfigurationRead)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default WorkScheduleModel;
