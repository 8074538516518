NewDatabaseFormController.$inject = [
  '$stateParams',
  'AlertingService',
  'gettext',
  'MetadataService',
  'createOrUpdateService',
  'SfeHeader',
  'database',
  '$timeout'
];

function NewDatabaseFormController(
  $stateParams,
  AlertingService,
  gettext,
  MetadataService,
  createOrUpdateService,
  SfeHeader,
  database,
  $timeout
) {
  const vm = this;
  const databaseId = $stateParams.id;
  const editMode = Boolean(databaseId);

  vm.$onInit = function init() {
    vm.dataConfig = {
      action: {
        ctrlFn: true,
        title: editMode ? gettext('Update') : gettext('Create'),
        fn: createOrUpdate
      },
      data: getFields()
    };

    if (editMode) {
      MetadataService.Loading(true);
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit Database'),
        'external-datasources-connectors-database-view',
        $stateParams
      );

      if (database.credentials) {
        database.useSecurity = true;
        database.username = database.credentials.username;
        database.password = database.credentials.password;
      }

      vm.dataConfig.dataObj = database;
      $timeout(() => {
        MetadataService.Loading(false);
        MetadataService.ChangeMetadata(
          'Edit ' + database.name,
          database.description
        );
      });
    } else {
      // NEW Database
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New Database'),
        'external-datasources-connectors-list'
      );
      vm.dataConfig.dataObj = {};
    }
  };

  function getFields() {
    return [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textArea',
        maxlength: 500,
        type: 'text',
        required: false
      },
      {
        componentType: 'autocompleteDialog',
        edit: editMode,
        configuration: {
          codelist: 'databaseTypes',
          floatingLabel: gettext('Choose database type'),
          searchParamName: 'name',
          entity: 'databaseTypes',
          required: true,
          noDialog: true
        },
        name: 'type'
      },
      {
        placeholder: gettext('URL'),
        name: 'url',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Database name'),
        name: 'database',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Read Query'),
        name: 'query',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Write Query'),
        name: 'writeQuery',
        componentType: 'textField',
        type: 'text',
        required: false
      },
      {
        placeholder: gettext('Browse Query'),
        name: 'browseQuery',
        componentType: 'textField',
        type: 'text',
        required: true
      },
      {
        componentType: 'checkBox',
        label: gettext('Use security'),
        name: 'useSecurity'
      },
      {
        placeholder: gettext('Username'),
        componentType: 'textField',
        hide: true,
        name: 'username',
        showParam: 'useSecurity'
      },
      {
        placeholder: gettext('Password'),
        componentType: 'password',
        name: 'password',
        showParam: 'useSecurity',
        hide: true
      }
    ];
  }

  // create and update functions //

  function createOrUpdate() {
    vm.sendingRequest = true;
    createOrUpdateService
      .simpleCall(
        {
          entity: 'databases',
          method: editMode ? 'update' : 'create'
        },
        editMode,
        databaseId,
        dataToSave(),
        'external-datasources-connectors-database-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function dataToSave() {
    return {
      name: vm.dataConfig.dataObj.name,
      description: vm.dataConfig.dataObj.description,
      useSecurity: vm.dataConfig.dataObj.useSecurity,
      url: vm.dataConfig.dataObj.url,
      database: vm.dataConfig.dataObj.database,
      query: vm.dataConfig.dataObj.query,
      browseQuery: vm.dataConfig.dataObj.browseQuery,
      writeQuery: vm.dataConfig.dataObj.writeQuery,
      type: vm.dataConfig.dataObj.type.id,
      credentials: vm.dataConfig.dataObj.useSecurity
        ? {
          username: vm.dataConfig.dataObj.username,
          password: vm.dataConfig.dataObj.password
        }
        : null
    };
  }
}

export default NewDatabaseFormController;
