/*
    var crawlerConfiguration = {
    entity: 'entityName',
    method: 'methodName',
    steps: [
      {
        entity: '',
        valueParams: [],
        queryParams: [],
        method: '',
        methodType: '' (populate, add, populateArray, addArray),
        newParamNames: [] only for add method types
      }]
  */
AssetTypeAttributeModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function AssetTypeAttributeModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'asset-type-attributes'
  };

  var networkConfigurationV1 = {
    host: 'api',
    path: 'v1/asset-type-attributes'
  };

  var crawlerConfiguration = {
    entity: 'asset-type-attributes',
    method: 'read',
    steps: [
      {
        entity: 'domain-attributes',
        valueParams: ['domainAttribute'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'populateArray'
      }
    ]
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      list: CrawlerMethods.crawler(crawlerConfiguration),
      createV1: ModelUtilities.crud(
        'POST',
        networkConfigurationV1,
        modelStorage
      ),
      updateV1: ModelUtilities.crud('PUT', networkConfigurationV1, modelStorage)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default AssetTypeAttributeModel;
