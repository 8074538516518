/**
 * @ngdoc filter
 * @name common.geoLocationDecimalToDeg
 * @description Converts geolocational decimal coordinates input to degree based output.
 * @param {dataType} input
 * @example
 * <div>{{ object | geoLocationDecimalToDeg }}</div>
 */
function geoLocationDecimalToDeg() {
  return function(input) {
    var absValue = Math.abs(input);
    var deg = Math.floor(absValue);
    var min = Math.floor((absValue - deg) * 60);
    var decimalSec = absValue - deg - min / 60;
    var sec = (decimalSec * 3600).toFixed(0);
    return deg + '°' + min + '\'' + sec + '"';
  };
}

export default geoLocationDecimalToDeg;
