/*
    var crawlerConfiguration = {
    entity: 'entityName',
    method: 'methodName',
    steps: [
      {
        entity: '',
        valueParams: [],
        queryParams: [],
        method: '',
        methodType: '' (populate, add, populateArray, addArray),
        newParamNames: [] only for add method types
      }]
  */
RuleModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function RuleModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'rules'
  };
  var crawlerConfiguration = {
    entity: 'rules',
    method: 'read',
    steps: [
      {
        entity: 'alarm-rule-target-for-x-axis-intervals',
        valueParams: ['_id'],
        queryParams: ['rule'],
        method: 'custom.readAlarm',
        methodType: 'addArray',
        newParamNames: ['alarmRuleTargetForXAxisInterval'],
        mapping: {
          localField: '_id',
          foreignField: 'rule'
        }
      }
    ]
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readRuleAlarmTarget: CrawlerMethods.crawler(crawlerConfiguration)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default RuleModel;
