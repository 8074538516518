NotificationListController.$inject = [
  '$smartAuth',
  '$state',
  'AlertingService',
  '$scope'
];

function NotificationListController(
  $smartAuth,
  $state,
  AlertingService,
  $scope
) {
  const vm = this;
  vm.entity =
    $state.current.name === 'users-received-notifications-list'
      ? 'my-notification'
      : 'notification';

  init();

  function init() {
    fetchUser();
  }

  async function fetchUser() {
    try {
      const userProfile = await $smartAuth.profile();
      const filterName =
        $state.current.name === 'users-received-notifications-list'
          ? 'user'
          : 'owner';
      vm.params = {
        [filterName]: userProfile._id
      };
    } catch (err) {
      AlertingService.Error(err);
    }
    $scope.$applyAsync();
  }
}

export default NotificationListController;
