GisAuth.$inject = ['$smartAuth', 'CrawlerMethods', '$q'];
/**
 * @ngdoc service
 * @name core.GisAuth
 * @description helper methods to create/update/get gis authentication tokens.
 * @property {function} init
 * @property {function} getToken
 * @property {function} resetToken
 * @property {function} clearToken
 */
function GisAuth($smartAuth, CrawlerMethods, $q) {
  var token;
  /**
   * @memberof GisAuth.init
   * @description fetches and saves  gis token.
   */
  function init() {
    $smartAuth.profile().then(function(user) {
      token = 'Bearer ' + user.gisUserToken;
    });
  }
  /**
   * @memberof GisAuth.getToken
   * @description returns gis token.
   * @return {string}
   */
  function getToken() {
    return token;
  }
  /**
   * @memberof GisAuth.resetToken
   * @description resets invalid gis token.
   * @return {Promise}
   */
  function resetToken() {
    var deferred = $q.defer();
    var waterfall = [fetchUser, fetchNewToken, updateUserToken];

    async.waterfall(waterfall, function(err, gisToken) {
      if (!err) {
        token = gisToken;
        deferred.resolve();
      } else {
        deferred.reject();
      }
    });
    return deferred.promise;
  }

  /**
   * @description gets currently logedin user profile.
   * @function
   * @param {function} callback callback function
   */
  function fetchUser(callback) {
    $smartAuth.profile().then(
      function(user) {
        callback(null, user);
      },
      function(err) {
        callback(err);
      }
    );
  }
  /**
   * @description creates new user token.
   * @function
   * @param {object} user user object should contain gisUserId property
   * @param {function} callback callback function
   */
  function fetchNewToken(user, callback) {
    var method = CrawlerMethods.getMethod({
      entity: 'gis-users',
      method: 'custom.createToken'
    });
    method(
      {
        userId: user.gisUserId
      },
      {
        scopes: ['api-all'],
        name: 'TIS' + user.gisUserId
      }
    ).then(
      function(res) {
        callback(null, user, res.data.token);
      },
      function(err) {
        callback(err);
      }
    );
  }
  /**
   * @description updates gis token value at user profile.
   * @function
   * @param {object} user user object
   * @param {string} token gis token
   * @param {function} callback callback function
   */
  function updateUserToken(user, token, callback) {
    var method = CrawlerMethods.getMethod({
      entity: 'users',
      method: 'create'
    });
    method(
      {
        id: user._id
      },
      {
        gisUserToken: token
      }
    ).then(
      function() {
        callback(null, token);
      },
      function(err) {
        callback(err);
      }
    );
  }
  /**
   * @memberof GisAuth.clearToken
   * @description resets token.
   */
  function clearToken() {
    token = undefined;
  }

  return {
    init: init,
    getToken: getToken,
    resetToken: resetToken,
    clearToken: clearToken
  };
}

export default GisAuth;
