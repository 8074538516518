import './sfe-form-2-display-item.scss';
import template from './sfe-form-2-display-item.component.html';
/**
 * @ngdoc component
 * @name common.sfeForm2DisplayItem
 * @description component for generating item content based on display function and item.
 * @param {Object} item - id, name
 * @param {Function} display - (item) => ({ item.name })
 * @example
 * <sfe-form-2-display-item
 *   item="item"
 *   display="vm.config.type.options.display"
 * ></sfe-form-2-display-item>
 */
/*
vm.item = {
  _id: 'morje',
  value: 'Morje'
}
vm.display = (item) => {
  return item.name;
}
*/
export default {
  restrict: 'E',
  template,
  bindings: {
    item: '<',
    display: '<'
  },
  controller: SfeForm2DisplayItem,
  controllerAs: 'vm',
  bindToController: true
};

SfeForm2DisplayItem.$inject = ['gettextCatalog'];
function SfeForm2DisplayItem(gettextCatalog) {
  const vm = this;
  vm.$onChanges = () => {
    vm.itemError = false;
    let validatedDisplay = validateDisplayFunction(vm.display);
    // Generate itemContent based on provided diplay function and item.
    let itemContent = validatedDisplay(vm.item);
    vm.validatedItemContent = validateItemContent(itemContent);
  };
  /**
   * @description Validate display function, generate display function if it doesnt exist.
   * @function
   * @param {Function} display
   * @return {Function}
   */
  const validateDisplayFunction = display => {
    if (display == null || typeof display !== 'function') {
      display = item => item.name;
    }
    return display;
  };
  /**
   * @description Validate item content, generate item content if it doesnt exist.
   * @function
   * @param {string} itemContent
   * @return {string}
   */
  const validateItemContent = itemContent => {
    if (itemContent == null || itemContent === '') {
      itemContent = gettextCatalog.getString('Missing item content.');
      vm.itemError = true;
    }
    return itemContent;
  };
}
