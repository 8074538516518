TimeSeriesTypeModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

/**
 * @ngdoc model
 * @name common.TimeSeriesTypeModel
 * @description Model for Time Series Type.
 */
function TimeSeriesTypeModel(ModelUtilities, CrawlerMethods) {
  const modelStorage = {};

  let networkConfiguration = {
    host: 'api',
    path: 'time-series-types'
  };

  let networkConfigurationAllLang = {
    host: 'api',
    path: 'time-series-types',
    allLang: true
  };

  let networkConfigurationV1 = {
    host: 'api',
    path: 'v1/time-series-types'
  };

  let crawlerConfiguration = {
    entity: 'time-series-types',
    method: 'read',
    populate: 'pairedTimeSeriesType',
    steps: [
      {
        entity: 'system-tag-entities',
        valueParams: ['_id'],
        queryParams: ['entityId'],
        method: 'custom.readWithTag',
        methodType: 'add',
        newParamNames: ['systemTags']
      }
    ]
  };

  var TimeSeriesTypeSteps = [
    {
      entity: 'time-series-types',
      valueParams: ['timeSeriesType'],
      queryParams: ['_id'],
      method: 'read',
      methodType: 'populateArray'
    }
  ];

  async function populateWithTimeSeriesTypes(items) {
    return await CrawlerMethods.populate(items, TimeSeriesTypeSteps);
  }

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readAllLang: ModelUtilities.crud(
        'GET',
        networkConfigurationAllLang,
        modelStorage
      ),
      readItemView: CrawlerMethods.crawler(crawlerConfiguration),
      createV1: ModelUtilities.crud(
        'POST',
        networkConfigurationV1,
        modelStorage
      ),
      updateV1: ModelUtilities.crud(
        'PUT',
        networkConfigurationV1,
        modelStorage
      ),
      populateWithTimeSeriesTypes
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default TimeSeriesTypeModel;
