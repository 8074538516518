import template from './privilege-management-dialog.html';
import './privilege-management-dialog.scss';
/**
 * @ngdoc service
 * @name common.PrivilegeManagementDialog
 * @description Opens wizard dialog
 */

PrivilegeManagementDialog.$inject = ['$mdDialog', 'gettextCatalog'];
export default function PrivilegeManagementDialog($mdDialog, gettextCatalog) {
  async function open(config) {
    return $mdDialog
      .show({
        controller: DialogController,
        controllerAs: 'vm',
        template,
        parent: angular.element(document.body),
        locals: {
          config,
          gettextCatalog
        }
      })
      .catch(angular.noop);
  }
  return {
    open
  };
}

DialogController.$inject = ['config', 'gettextCatalog'];
function DialogController(config, gettextCatalog) {
  const vm = this;
  vm.entityId = config.entityId;
  vm.identifier = config.identifier;
  vm.header = {
    isDialog: true,
    title: gettextCatalog.getString('Privilege management'),
    actions: [
      {
        icon: {
          name: 'close',
          type: 2
        },
        cancel: true
      }
    ]
  };
}
