/*
    var crawlerConfiguration = {
    entity: 'entityName',
    method: 'methodName',
    steps: [
      {
        entity: '',
        valueParams: [],
        queryParams: [],
        method: '',
        methodType: '' (populate, add, populateArray, addArray),
        newParamNames: [] only for add method types
      }]
  */
SystemTagModel.$inject = ['ModelUtilities'];

function SystemTagModel(ModelUtilities) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'system-tags'
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default SystemTagModel;
