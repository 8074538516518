RatedPowerModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function RatedPowerModel(ModelUtilities, CrawlerMethods) {
  const modelStorage = {};
  const networkConfiguration = {
    host: 'api',
    path: 'rated-powers'
  };

  const crawlerConfigurationSystemTags = {
    entity: 'rated-powers',
    method: 'read',
    populate: 'energySourceType'
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readEnergySourceType: CrawlerMethods.crawler(
        crawlerConfigurationSystemTags
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default RatedPowerModel;
