/* eslint-disable no-unused-vars */
import './sfe-form-2-math-expression.scss';
import template from './sfe-form-2-math-expression.component.html';
import logicalExpressionTemplate from './sfe-form-2-math-expression.template.html';

/**
 * @ngdoc component
 * @name common.SfeForm2MathExpression
 * @description component for generating math expression
 * @param {function} allowedVariables - returns allowed variables
 * @example
 * <sfe-form-2-math-expression
 *   allowed-variables="vm.config.type.options.allowedVariables"
 *   ng-model="vm.value"
 * ></sfe-form-2-math-expression>
 */

export default {
  template,
  bindings: {
    allowedVariables: '<',
    api: '<'
  },
  require: {
    form: '?^form',
    model: 'ngModel'
  },
  controller: SfeForm2MathExpression,
  controllerAs: 'vm'
};

SfeForm2MathExpression.$inject = [
  '$scope',
  'AllowedFunctionsService',
  'AllowedOperatorsService',
  'ValidateMathExpression',
  'gettextCatalog'
];
function SfeForm2MathExpression(
  $scope,
  AllowedFunctionsService,
  AllowedOperatorsService,
  ValidateMathExpression,
  gettextCatalog
) {
  let vm = this;

  const allowedFunctions = AllowedFunctionsService.getConstants();
  const allowedOperators = AllowedOperatorsService.getConstants();
  vm.allowedFunctions = allowedFunctions;
  var variableWatcher;
  vm.logicalExpressionTemplate = logicalExpressionTemplate;
  vm.toggleIfExpression = toggleIfExpression;
  vm.expressionConstructor = expressionConstructor;
  vm.errors = [];
  vm.actions = [];

  let modelValue;
  vm.expressionObject = {
    isRootItem: true,
    id:
      'expression-preview' +
      Math.random()
        .toString(36)
        .substring(2)
  };

  $scope.$on('$destroy', function() {
    vm = null;
    if (variableWatcher) {
      variableWatcher();
    }

    if (stopWatching) {
      stopWatching();
    }
  });

  vm.$onInit = () => {
    vm.model.$validators = {
      ...vm.model.$validators,
      //function is triggered on every model change or sfe-form-2 revalidate trigger
      //triggers function that traverses over all expressions and assigns variable error msgs id needed
      variablesAreNotValid: () => {
        if (typeof vm.allowedVariables == 'function') {
          validateVariableNames(vm.expressionObject);
          const numberOfUsedVariables = getNumberOfUsedVariables();
          validateNumberOfVariables(numberOfUsedVariables);
        }
        return true;
      }
    };
  };

  let stopWatching = $scope.$watch(
    function() {
      return vm.model.$modelValue;
    },
    function(model) {
      if (model != null && model != '') {
        modelValue = model.replace(/\|\|/gi, 'or').replace(/&&/gi, 'and');
        vm.expressionObject.isRootItem = false;
        initExpression(modelValue);
        if (vm.form != null) {
          stopWatching();
        }
      }
    }
  );
  /**
   * @description validates variable names.
   * @function
   * @param {Object} expressionObject expression tree object that contains true in false expressions
   */
  function validateVariableNames(expressionObject) {
    const allowedVariables = vm.allowedVariables(vm.api);
    try {
      const mathObject = math.parse(expressionObject.expression);
      expressionObject.errors = ValidateMathExpression.getErrors(
        mathObject,
        ['Main expression'],
        [],
        allowedFunctions,
        allowedOperators,
        vm.allowedVariables(vm.api)
      );
      validateFormInput(expressionObject);
    } catch (err) {
      //error parsing expression
    }
    if (expressionObject.trueExpression) {
      validateVariableNames(expressionObject.trueExpression);
    }
    if (expressionObject.falseExpression) {
      validateVariableNames(expressionObject.falseExpression);
    }
  }

  /**
   * @description Parses logical expression.
   * @function
   * @param {Object} expressionObject Object containing information about the expression
   */
  function parseLogicalExpression(expressionObject, isRoot) {
    expressionConstructor(expressionObject);
    if (expressionObject.trueExpression) {
      parseLogicalExpression(expressionObject.trueExpression);
    }
    if (expressionObject.falseExpression) {
      parseLogicalExpression(expressionObject.falseExpression);
    }
    if (isRoot) {
      vm.model.$setViewValue(constructOutputExpression());
      // var numberOfUsedVariables = getNumberOfUsedVariables();
      // validateNumberOfVariables(numberOfUsedVariables);
    }
  }

  /**
   * @description Validates the number of variables.
   * @function
   * @param {number} numberOfUsedVariables Number of used variables.
   */
  function validateNumberOfVariables(numberOfUsedVariables) {
    if (
      angular.isNumber(numberOfUsedVariables) &&
      numberOfUsedVariables === 0
    ) {
      if (!vm.expressionObject.errors) {
        vm.expressionObject.errors = [];
      }
      var noVariablesError = vm.expressionObject.errors.find(function(item) {
        return item.noVariablesError;
      });
      if (!noVariablesError) {
        vm.expressionObject.errors.push({
          error: gettextCatalog.getString(
            'You have to use at least one variable'
          ),
          noVariablesError: true
        });
      }
    } else if (vm.expressionObject.errors) {
      vm.expressionObject.errors = vm.expressionObject.errors.filter(function(
        error
      ) {
        return !error.noVariablesError;
      });
    }
    validateFormInput(vm.expressionObject);
  }

  /**
   * @description Returns the number of used variables in the expression.
   * @function
   * @param {Object} expressionObject Object containing information about the expression
   * @return {number} Number of used variables.
   */
  function getNumberOfUsedVariables(expressionObject) {
    var mathObj;
    if (!expressionObject) {
      expressionObject = vm.expressionObject;
    }
    try {
      mathObj = math.parse(expressionObject.expression);
      var numberOfUsedVariables = ValidateMathExpression.getNumberOfUsedVariables(
        mathObj
      );
      if (expressionObject.trueExpression) {
        numberOfUsedVariables += getNumberOfUsedVariables(
          expressionObject.trueExpression
        );
      }
      if (expressionObject.falseExpression) {
        numberOfUsedVariables += getNumberOfUsedVariables(
          expressionObject.falseExpression
        );
      }
      return numberOfUsedVariables;
    } catch (err) {
      // error is handled in expressoin constructor
    }
  }

  /**
   * @description Constructs the expression.
   * @function
   * @param {Object} expressionObject Object containing information about the expression
   */
  function expressionConstructor(expressionObject) {
    if (vm.form) {
      var mathObj = null;
      var initialError = false;
      expressionObject.errors = [];
      try {
        expressionObject.expression = expressionObject.expression || '';
        mathObj = math.parse(expressionObject.expression);
      } catch (err) {
        initialError = {
          error: err.message
        };
      }

      if (initialError) {
        expressionObject.errors.push(initialError);
      } else {
        expressionObject.errors = ValidateMathExpression.getErrors(
          mathObj,
          ['Main expression'],
          [],
          allowedFunctions,
          allowedOperators,
          vm.allowedVariables(vm.api)
        );
      }

      if (!expressionObject.errors.length) {
        if (expressionObject.isRootItem) {
          expressionObject.isRootItem = false;
        }
      }

      validateFormInput(expressionObject);
      let newValue = constructOutputExpression();
      vm.model.$setViewValue(newValue);
    }
    var numberOfUsedVariables = getNumberOfUsedVariables();
    validateNumberOfVariables(numberOfUsedVariables);
  }

  /**
   * @description Constructs the output expression.
   * @function
   * @param {Object} expressionObject Object containing information about the expression
   */
  function constructOutputExpression(expressionObject) {
    let result = '';
    if (!expressionObject) {
      expressionObject = vm.expressionObject;
    }
    if (!expressionObject.ifEnabled) {
      if (!expressionObject.expression) {
        expressionObject.expression = '';
      }

      return expressionObject.expression
        .replace(/\bor\b/gi, '||')
        .replace(/\band\b/gi, '&&');
    } else {
      result += 'if (';
      result += expressionObject.expression;
      result += ',';
      result += expressionObject.trueExpression
        ? constructOutputExpression(expressionObject.trueExpression)
        : '';
      result += ',';
      result += expressionObject.falseExpression
        ? constructOutputExpression(expressionObject.falseExpression)
        : '';
      result += ')';
      return result.replace(/\bor\b/gi, '||').replace(/\band\b/gi, '&&');
    }
  }
  /**
   * @description calls methods to parse expression string.
   * @function
   * @param {String} modelValue expression string
   */
  function initExpression(modelValue) {
    try {
      var nodesObject = math.parse(modelValue);
      parseNodes(nodesObject);
      parseLogicalExpression(vm.expressionObject, true);
    } catch (err) {
      //entered expression is not valid do not parse it
    }
  }

  /**
   * @description Parses nodes.
   * @function
   * @param {Object} nodesObject Nodes to be parsed
   * @param {Object} resultExpressionObject Object to be inited with math jax
   */
  function parseNodes(nodesObject, resultExpressionObject) {
    var isRoot;
    if (!resultExpressionObject) {
      isRoot = true;
      resultExpressionObject = vm.expressionObject;
    }
    if (nodesObject.type === 'FunctionNode') {
      var functionName = nodesObject.fn.name;
      if (functionName === 'if') {
        for (var index in nodesObject.args) {
          var obj = nodesObject.args[index];
          resultExpressionObject.ifEnabled = true;
          switch (Number(index)) {
          case 0:
            resultExpressionObject.expression = obj
              .transform(ValidateMathExpression.transformNumber)
              .toString()
              .replace(/"/g, '');

            if (!isRoot) {
              resultExpressionObject.id =
                  'expression-preview' +
                  Math.random()
                    .toString(36)
                    .substring(2);
            }
            break;
          case 1:
            resultExpressionObject.trueExpression = {};
            parseNodes(obj, resultExpressionObject.trueExpression);

            break;
          case 2:
            resultExpressionObject.falseExpression = {};
            parseNodes(obj, resultExpressionObject.falseExpression);
            break;
          }
        }
      } else {
        resultExpressionObject.expression = nodesObject.toString();
        resultExpressionObject.id =
          'expression-preview' +
          Math.random()
            .toString(36)
            .substring(2);
      }
    } else {
      resultExpressionObject.expression = nodesObject
        .transform(ValidateMathExpression.transformNumber)
        .toString()
        .replace(/"/g, '');

      resultExpressionObject.id =
        'expression-preview' +
        Math.random()
          .toString(36)
          .substring(2);
    }
  }

  /**
   * @description Toggles if expression.
   * @function
   * @param {Object} expressionObject Containing info on whether if is enabled or disabled
   */
  function toggleIfExpression(expressionObject) {
    expressionObject.ifEnabled = !expressionObject.ifEnabled;
    if (expressionObject.ifEnabled) {
      expressionObject.trueExpression = {
        id:
          'expression-preview' +
          Math.random()
            .toString(36)
            .substring(2)
      };
      expressionObject.falseExpression = {
        id:
          'expression-preview' +
          Math.random()
            .toString(36)
            .substring(2)
      };
    } else {
      expressionObject.trueExpression = null;
      expressionObject.falseExpression = null;
      parseLogicalExpression(expressionObject);
    }
  }

  /**
   * @description Validates the form input.
   * @function
   * @param {Object} item Item to be checked for containing errors
   */
  function validateFormInput(item) {
    if (vm.form && vm.form[item.id]) {
      if (Array.isArray(item.errors) && item.errors.length > 0) {
        vm.form[item.id].$setValidity('parseError', false);
      } else {
        vm.form[item.id].$setValidity('parseError', true);
      }
    }
  }
}
