import template from '../pages/navigation-dialog/navigation-dialog.view.html';
NavigationMenuService.$inject = [
  '$smartAuth',
  '$state',
  'AuthorizationShowScopeModel',
  'gettextCatalog',
  '$mdDialog'
];

/**
 * @ngdoc service
 * @name common.NavigationMenuService
 * @description Service for managing appConfig connected to SmartAccount or a similar service.
 */

function NavigationMenuService(
  $smartAuth,
  $state,
  AuthorizationShowScopeModel,
  gettextCatalog,
  $mdDialog
) {
  async function initiateMenu() {
    await $smartAuth.profile();
    const states = fixTranslations(_.clone($state.get()));
    const activeStateObj = $state.current;
    const { data: accessScopes } = await AuthorizationShowScopeModel.read();
    let res = buildMenu(states, activeStateObj, accessScopes);
    return {
      ...res,
      states,
      accessScopes
    };
  }

  function fixTranslations(states) {
    return states.map(state => {
      if (state.name == 'data-time-series-list') {
        //Override side menu title - because we cannot use angularjs modules in route files
        state.pageTitle = gettextCatalog.getPlural(
          3,
          'Time Series',
          'Time Series',
          {}
        );
      }
      return state;
    });
  }

  function buildMenu(states, activeStateObj, accessScopes) {
    let selectedModule;

    let preparedModules = states
      .reduce((result, state) => {
        if (state.isMainMenuItem && state.mode !== 'create') {
          if (
            (accessScopes &&
              accessScopes[state.accessScopeFe] &&
              accessScopes[state.accessScopeFe].show) ||
            state.allUsers
          ) {
            let childStates = states.filter(item => {
              return (
                item.isSubMenuItem &&
                item.parentState == state.name &&
                ((accessScopes[item.accessScopeFe] &&
                  accessScopes[item.accessScopeFe].show) ||
                  item.allUsers) &&
                item.mode !== 'create'
              );
            });

            const sfeIcon =
              state.icon != null
                ? {
                  type: 2,
                  name: state.icon
                }
                : undefined;
            let moduleObject = {
              root: { ...state, sfeIcon },
              rootName: state.name,
              role: state.role,
              subitems: childStates
            };

            if (state.name === activeStateObj.parentState) {
              selectedModule = moduleObject;
            }

            result = [...result, moduleObject];
          }
        }
        return result;
      }, [])
      .sort(function(a, b) {
        let textA = a.rootName.toLowerCase();
        let textB = b.rootName.toLowerCase();
        let indexA =
          typeof a.root.menuIndex === 'undefined' ? -1 : a.root.menuIndex;
        let indexB =
          typeof b.root.menuIndex === 'undefined' ? -1 : b.root.menuIndex;
        return indexB - indexA || (textA < textB ? -1 : textA > textB ? 1 : 0);
      })
      .map((module, index) => ({
        ...module,
        moduleIndex: index
      }));

    return {
      preparedModules,
      selectedModule
    };
  }

  function splitAtLastDash(text) {
    var parts = text.split('-');
    if (
      parts[parts.length - 1] === 'details' ||
      parts[parts.length - 1] === 'view'
    ) {
      // if the word after last dash is 'details', change it to 'list'
      parts[parts.length - 1] = 'list';
    } else if (parts[parts.length - 1] === 'list') {
      // if last word is 'list', remove it
      parts.pop();
    }
    return parts.join('-');
  }

  async function openNavigationDialog(accessScopes) {
    const states = fixTranslations(_.clone($state.get()));
    const activeStateObj = $state.current;
    let res = buildMenu(states, activeStateObj, accessScopes);
    $mdDialog.show({
      clickOutsideToClose: true,
      template,
      controller: 'NavigationDialogController',
      controllerAs: 'vm',
      locals: {
        modules: res.preparedModules,
        selectedModule: res.selectedModule,
        activeStateObj: activeStateObj
      }
    });
  }

  function getFindSubItemIndex(currentSubmodule) {
    return subItem => {
      return (
        subItem.name === currentSubmodule ||
        subItem.name === splitAtLastDash(currentSubmodule) ||
        splitAtLastDash(subItem.name) === currentSubmodule ||
        splitAtLastDash(subItem.name) === currentSubmodule + 's'
      );
    };
  }

  return {
    initiateMenu,
    buildMenu,
    openNavigationDialog,
    getFindSubItemIndex
  };
}

export default NavigationMenuService;
