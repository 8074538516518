TangoAssetAvailabilityForm.$inject = [
  'gettextCatalog',
  '$timeout',
  'TranslationService',
  '$mdDialog',
  'AlertingService',
  'AssetModel'
];
/**
 * @ngdoc service
 * @name companyResources.TangoAssetAvailabilityForm
 * @description returns asset Availability Properties
 * @property {function} create
 * @property {function} TangoAssetAvailabilityForm
 */
export default function TangoAssetAvailabilityForm(
  gettextCatalog,
  $timeout,
  TranslationService,
  $mdDialog,
  AlertingService,
  AssetModel
) {
  /**
   * @description returns time series autocomplete display field configuration.
   * @function
   * @param {Object} item time series item
   * @return {Object}
   */
  let displayTimeSeries = item => {
    let schedulerClassificationAbbreviation = '';
    if (item != null) {
      if (
        item.dataScheduler != null &&
        item.dataScheduler.scheduleClassification
      ) {
        let schedulerClassification = TranslationService.GetCollectionById(
          'codelists.scheduleClassifications',
          item.dataScheduler.scheduleClassification
        );
        if (
          schedulerClassification != null &&
          schedulerClassification.name != null
        ) {
          schedulerClassificationAbbreviation = ` - ${schedulerClassification.name}`;
        }
      }
      return {
        text: `${item.name} ${schedulerClassificationAbbreviation}`
      };
    }
    return { text: gettextCatalog.getString('Unknown') };
  };
  /**
   * @description returns asset availability form configuration.
   * @function
   * @param {Object} asset
   * @return {Object}
   */
  function getForm(asset) {
    let assetAvailability;
    if (asset != null) {
      assetAvailability = asset.availability;
    }

    let configuration = {
      name: 'assetAvailability',
      title: gettextCatalog.getString('Asset Availability'),
      actions: [
        {
          title: gettextCatalog.getString('Save'),
          /**
           * @description resolves dialog with values
           * @function
           * @param {object} api
           */
          fn: async api => {
            let savedAsset;
            try {
              const values = api.getValues();
              if (values != null) {
                const availability = { ...values };
                availability.timeSeries = values.timeSeries
                  ? values.timeSeries._id
                  : null;
                let startDate =
                  Array.isArray(values.startOfAvailability) &&
                  values.startOfAvailability.length
                    ? values.startOfAvailability[0]
                    : null;
                availability.startOfAvailability =
                  startDate != null ? new Date(startDate).toISOString() : null;
                const { data } = await AssetModel.update(
                  {
                    id: asset._id
                  },
                  {
                    availability
                  }
                );
                savedAsset = data;
              }
            } catch (err) {
              AlertingService.Error(err);
            }
            $mdDialog.hide(savedAsset);
          },
          disabledFn: api => {
            if (typeof api.formValidity == 'function') {
              return !api.formValidity();
            }
          }
        }
      ],
      fields: [
        {
          id: 'description',
          title: gettextCatalog.getString('Description'),
          type: {
            name: 'text',
            options: {}
          },
          initialize: () => {
            return assetAvailability != null
              ? assetAvailability.description
              : '';
          },
          required: false
        },
        {
          id: 'status',
          title: gettextCatalog.getString('Status'),
          type: {
            name: 'radio',
            options: {
              layout: 'row',
              items: TranslationService.GetCollection(
                'codelists.assetAvailabilityStatuses'
              ),
              modelProperty: 'id',
              display: data => {
                return data && data.name
                  ? data.name
                  : gettextCatalog.getString('Unknown');
              }
            }
          },
          initialize: () => {
            return assetAvailability != null ? assetAvailability.status : 100;
          },
          required: true
        },
        {
          id: 'timeSeries',
          title: gettextCatalog.getString('Time series'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'time-series',
                method: 'read'
              },
              valueParam: '_id',
              crawlerParams: text => {
                let params = {
                  order: '-name',
                  populate: 'dataScheduler',
                  // Regular
                  dataSamplingType: 100,
                  // Decimal
                  dataType: 3,
                  'activeTimeSeriesConfiguration.flowType_': 100
                };
                if (text != null && text != '') {
                  return {
                    ...params,
                    filter: text
                  };
                }
                return params;
              },
              filter: items => {
                // Find items where active flow is ERF & external data source is null
                return items.filter(
                  item =>
                    item?.activeTimeSeriesConfiguration?.externalDatasource_ ==
                    null
                );
              },
              display: displayTimeSeries,
              dialog: {
                entity: 'time-series'
              }
            }
          },
          initialize: () => {
            if (assetAvailability != null) {
              return assetAvailability.timeSeries;
            }
          },
          required: true
        },
        {
          id: 'minAllowedValue',
          title: gettextCatalog.getString('Minimum allowed value'),
          type: {
            name: 'text',
            options: {
              type: 'numerical'
            }
          },
          initialize: () => {
            return assetAvailability != null
              ? assetAvailability.minAllowedValue
              : '';
          },
          validators: {
            maximumValueOfMin: {
              fn: (modelValue, viewValue, api) => {
                let maximumValue = api.getValue('maxAllowedValue');
                if (
                  modelValue != null &&
                  modelValue !== '' &&
                  maximumValue != null &&
                  maximumValue !== '' &&
                  modelValue >= maximumValue
                ) {
                  return false;
                }
                return true;
              },
              text: gettextCatalog.getString('Must be less than maximum value')
            }
          },
          onChange: form => {
            $timeout(() => {
              form.revalidate();
            });
          },
          required: false,
          width: 6
        },
        {
          id: 'maxAllowedValue',
          title: gettextCatalog.getString('Maximum allowed value'),
          type: {
            name: 'text',
            options: {
              type: 'numerical'
            }
          },
          initialize: () => {
            return assetAvailability != null
              ? assetAvailability.maxAllowedValue
              : '';
          },
          validators: {
            minimumValueOfMax: {
              fn: (modelValue, viewValue, api) => {
                let minimumValue = api.getValue('minAllowedValue');
                if (
                  modelValue != null &&
                  modelValue !== '' &&
                  minimumValue != null &&
                  minimumValue !== '' &&
                  modelValue <= minimumValue
                ) {
                  return false;
                }
                return true;
              },
              text: gettextCatalog.getString(
                'Must be greater than minimum value'
              )
            }
          },
          onChange: form => {
            $timeout(() => {
              form.revalidate();
            });
          },
          required: false,
          width: 6
        },
        {
          id: 'startOfAvailability',
          title: gettextCatalog.getString('Start of availability'),
          type: {
            name: 'date',
            options: {}
          },
          initialize: () => {
            return assetAvailability != null
              ? new Date(assetAvailability.startOfAvailability)
              : null;
          },
          required: true
        }
      ]
    };
    return configuration;
  }
  return {
    getForm
  };
}
