myNotificationConfiguration.$inject = [
  'gettext',
  '$q',
  'AlertingService',
  'UserModel',
  'NotificationModel'
];

function myNotificationConfiguration(
  gettext,
  $q,
  AlertingService,
  UserModel,
  NotificationModel
) {
  var configuration = {
    entity: {
      singular: gettext('Received Notifications'),
      plural: gettext('Received Notifications')
    },
    api: {
      query: {
        entity: 'interactions',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'users-received-notifications-view',
        params: [
          {
            valueParam: 'notificationId',
            queryParam: 'id'
          }
        ]
      }
    },
    attributes: [
      {
        param: 'userCreated',
        title: gettext('Author'),
        type: 'link',
        link: {
          state: 'users-users-view',
          params: [
            {
              valueParam: 'userCreatedId',
              queryParam: 'id'
            }
          ]
        }
      }
    ],
    badges: [
      {
        param: 'seen',
        title: gettext('Seen'),
        goodOrBad: true,
        colored: true,
        type: 'goodOrBad',
        good: gettext('Seen'),
        bad: gettext('Unseen')
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      },
      {
        display: gettext('unseen first'),
        params: 'seen'
      },
      {
        display: gettext('seen first'),
        params: '-seen'
      }
    ]
  };

  function Enrich(item) {
    item.fetchingObject['name'] = {
      fetching: true,
      success: false,
      nodata: false
    };
    item.fetchingObject['userCreated'] = {
      fetching: true,
      success: false,
      nodata: false
    };
    var deferred = $q.defer();
    fetchNotificationData();
    return deferred.promise;

    function fetchOwner(notification, callback) {
      UserModel.read({
        id: notification.owner
      }).then(
        function(res) {
          callback(null, notification, res.data);
        },
        function(err) {
          AlertingService.Error(err);
          callback(null, notification, null);
        }
      );
    }

    function fetchNotification(callback) {
      NotificationModel.read({
        id: item.notification
      }).then(
        function(res) {
          callback(null, res.data);
        },
        function(err) {
          AlertingService.Error(err);
          callback(err);
        }
      );
    }

    function fetchNotificationData() {
      var waterfall = [fetchNotification, fetchOwner];
      async.waterfall(waterfall, function(err, notification, owner) {
        item.fetchingObject['name'].fetching = false;
        item.fetchingObject['userCreated'].fetching = false;
        if (!err) {
          item.name = notification.subject;
          item.notificationId = notification._id;
          item.userCreated = owner.name + ' ' + owner.family_name;
          item.userCreatedId = owner._id;
          item.fetchingObject['name'].success = true;
          item.fetchingObject['userCreated'].success = true;
        } else {
          item.fetchingObject['name'].success = false;
          item.fetchingObject['userCreated'].success = false;
        }
        deferred.resolve();
      });
    }
  }

  return configuration;
}
export default myNotificationConfiguration;
