import './sfe-gallery.scss';
import template from './sfe-gallery.directive.html';

export default function sfeGallery() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      images: '=',
      config: '='
    },
    link: linkFn
  };

  function linkFn(scope) {
    scope._Index = 0;

    scope.isActive = function(index) {
      return scope._Index === index;
    };

    scope.showPrev = function() {
      scope._Index =
        scope._Index > 0 ? --scope._Index : scope.photos.length - 1;
    };

    scope.showNext = function() {
      scope._Index =
        scope._Index < scope.photos.length - 1 ? ++scope._Index : 0;
    };

    scope.showPhoto = function(index) {
      scope._Index = index;
    };
  }

  return directive;
}
