import newAnalysisView from './pages/analysis/new-analysis.view.html';
import anaylsisView from './pages/analysis/analysis.view.html';
import newDatasetView from './pages/dataset/new-dataset.view.html';
import datasetView from './pages/dataset/dataset.view.html';
import newExpressionView from './pages/expression/new-expression.view.html';
import expressionView from './pages/expression/expression.view.html';
import aggregationView from './pages/aggregation/aggregation-group.view.html';
import newCalculationView from './pages/calculation/new-calculation.view.html';
import calculationView from './pages/calculation/calculation.view.html';
import newReportView from './pages/report/new-report.view.html';
import reportView from './pages/report/report.view.html';
import reportingEditorView from './pages/report/reporting-editor.view.html';
import reportingViewer from './pages/report/reporting-viewer.view.html';
import SfeListView from '../common/components/sfe-list/sfe-list.view.html';
import NewFormBasicView from '../common/views/new-form-basic.view.html';
import ItemHeaderView from '../common/views/item-header.view.html';

import { RouteManager, gettext } from '../routes';

loadMath.$inject = ['loadAssets'];
function loadMath(loadAssets) {
  return loadAssets(['math']);
}

loadReports.$inject = ['loadAssets'];
function loadReports(loadAssets) {
  return loadAssets(['stimulsoft']);
}

loadCharts.$inject = ['loadAssets'];
function loadCharts(loadAssets) {
  return loadAssets(['highcharts']);
}

loadEntity.$inject = ['$transition$', 'StateResolveService'];
function loadEntity($transition$, StateResolveService) {
  return StateResolveService.fetchEntityForView($transition$);
}

let AnalyticsRouteManager = new RouteManager();
AnalyticsRouteManager.state('analytics', {
  url: '/analytics',
  isMainMenuItem: true,
  icon: 'event_note',
  menuIndex: 3,
  pageTitle: gettext('Analytics'),
  theme: 'tango',
  accessScopeFe: 'tis_fe:em-analysis-module',
  accessScopeBe: 'tis_fe:em-analysis-module'
})

  .state('analytics-analyses-list', {
    url: '/analytics/analyses/list',
    isSubMenuItem: true,
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Analyses'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:analysis',
    accessScopeBe: 'tis_be:analysis',
    entity: 'analyses'
  })

  .state('analytics-analyses-new', {
    url: '/analytics/analyses/new',
    template: newAnalysisView,
    controller: 'NewAnalysisController',
    controllerAs: 'vm',
    pageTitle: gettext('New Analysis'),
    parentState: 'analytics',
    theme: 'tango',
    iconMode: 'add',
    accessScopeFe: 'tis_fe:analysis',
    accessScopeBe: 'tis_be:analysis',
    mode: 'create',
    entity: 'analyses',
    resolve: {
      analysis: () => ({})
    }
  })

  .state('analytics-analyses-edit', {
    url: '/analytics/analyses/{id}/edit',
    template: newAnalysisView,
    controller: 'NewAnalysisController',
    controllerAs: 'vm',
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:analysis',
    accessScopeBe: 'tis_be:analysis',
    mode: 'update',
    entity: 'analyses',
    resolve: {
      analysis: loadEntity
    }
  })

  .state('analytics-analyses-duplicate', {
    url: '/analytics/analyses/{id}/duplicate',
    template: newAnalysisView,
    controller: 'NewAnalysisController',
    controllerAs: 'vm',
    pageTitle: gettext('Duplicate Analysis'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:analysis',
    accessScopeBe: 'tis_be:analysis',
    mode: 'create',
    entity: 'analyses',
    resolve: {
      analysis: loadEntity
    }
  })

  .state('analytics-analyses-run', {
    url: '/analytics/analyses/{id}/run',
    template: anaylsisView,
    controller: 'AnalysisController',
    controllerAs: 'vm',
    pageTitle: gettext('Run Analysis'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:analysis',
    accessScopeBe: 'tis_be:analysis',
    mode: 'read',
    entity: 'analyses',
    resolve: {
      analysis: loadEntity
    }
  })

  .state('analytics-analyses-view', {
    url: '/analytics/analyses/{id}',
    template: anaylsisView,
    controller: 'AnalysisController',
    controllerAs: 'vm',
    pageTitle: gettext('Analysis'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:analysis',
    accessScopeBe: 'tis_be:analysis',
    mode: 'read',
    entity: 'analyses',
    resolve: {
      analysis: loadEntity,
      loadScripts: loadCharts
    }
  })
  //INTERVAL FILTERS
  .state('analytics-time-interval-filters-list', {
    url: '/analytics/time-interval-filters/list',
    isSubMenuItem: true,
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Time Interval Filters'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:period-intervals',
    accessScopeBe: 'tis_be:period-intervals',
    entity: 'period-intervals'
  })

  .state('analytics-time-interval-filters-new', {
    url: '/analytics/time-interval-filters/new',
    template: NewFormBasicView,
    controller: 'NewTimeIntervalFilterController',
    controllerAs: 'vm',
    pageTitle: gettext('New Time Interval Filter'),
    parentState: 'analytics',
    theme: 'tango',
    iconMode: 'add',
    accessScopeFe: 'tis_fe:period-intervals',
    accessScopeBe: 'tis_be:period-intervals',
    mode: 'create',
    entity: 'period-intervals',
    resolve: {
      periodInterval: () => ({})
    }
  })

  .state('analytics-time-interval-filters-edit', {
    url: '/analytics/time-interval-filters/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewTimeIntervalFilterController',
    controllerAs: 'vm',
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:period-intervals',
    accessScopeBe: 'tis_be:period-intervals',
    mode: 'update',
    entity: 'period-intervals',
    resolve: {
      periodInterval: loadEntity
    }
  })

  .state('analytics-time-interval-filters-view', {
    url: '/analytics/time-interval-filters/{id}',
    template: ItemHeaderView,
    controller: 'TimeIntervalFilterController',
    controllerAs: 'vm',
    pageTitle: gettext('Time Interval Filter'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:period-intervals',
    accessScopeBe: 'tis_be:period-intervals',
    mode: 'read',
    entity: 'period-intervals',
    resolve: {
      periodInterval: loadEntity
    }
  })
  // ///////////////////DATASET
  .state('analytics-datasets-list', {
    url: '/analytics/datasets/list',
    isSubMenuItem: true,
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Datasets'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:datasets',
    accessScopeBe: 'tis_be:datasets',
    entity: 'datasets'
  })

  .state('analytics-datasets-new', {
    url: '/analytics/datasets/new',
    template: newDatasetView,
    controller: 'NewDatasetController',
    controllerAs: 'vm',
    pageTitle: gettext('New Dataset'),
    parentState: 'analytics',
    theme: 'tango',
    iconMode: 'add',
    accessScopeFe: 'tis_fe:datasets',
    accessScopeBe: 'tis_be:datasets',
    mode: 'create',
    entity: 'datasets',
    resolve: {
      dataset: () => ({})
    }
  })

  .state('analytics-datasets-edit', {
    url: '/analytics/datasets/{id}/edit',
    template: newDatasetView,
    controller: 'NewDatasetController',
    controllerAs: 'vm',
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:datasets',
    accessScopeBe: 'tis_be:datasets',
    mode: 'update',
    entity: 'datasets',
    resolve: {
      dataset: loadEntity
    }
  })

  .state('analytics-datasets-view', {
    url: '/analytics/datasets/{id}',
    template: datasetView,
    controller: 'DatasetController',
    controllerAs: 'vm',
    pageTitle: gettext('Dataset'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:datasets',
    accessScopeBe: 'tis_be:datasets',
    mode: 'read',
    entity: 'datasets',
    resolve: {
      dataset: loadEntity
    }
  })

  // EXPRESSION
  .state('analytics-expressions-list', {
    url: '/analytics/expressions/list',
    isSubMenuItem: true,
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Expressions'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:expressions',
    accessScopeBe: 'tis_be:expressions',
    entity: 'expressions'
  })

  .state('analytics-expressions-new', {
    url: '/analytics/expressions/new',
    template: newExpressionView,
    controller: 'NewExpressionController',
    controllerAs: 'vm',
    pageTitle: gettext('New Expression'),
    parentState: 'analytics',
    theme: 'tango',
    iconMode: 'add',
    accessScopeFe: 'tis_fe:expressions',
    accessScopeBe: 'tis_be:expressions',
    mode: 'create',
    entity: 'expressions',
    resolve: {
      loadScripts: loadMath,
      expression: loadEntity
    }
  })

  .state('analytics-expressions-edit', {
    url: '/analytics/expressions/{id}/edit',
    template: newExpressionView,
    controller: 'NewExpressionController',
    controllerAs: 'vm',
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:expressions',
    accessScopeBe: 'tis_be:expressions',
    mode: 'update',
    entity: 'expressions',
    resolve: {
      loadScripts: loadMath,
      expression: loadEntity
    }
  })
  .state('analytics-expressions-view', {
    url: '/analytics/expressions/{id}',
    template: expressionView,
    controller: 'ExpressionController',
    controllerAs: 'vm',
    pageTitle: gettext('Expression'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:expressions',
    accessScopeBe: 'tis_be:expressions',
    mode: 'read',
    entity: 'expressions',
    resolve: {
      loadScripts: loadMath,
      expression: loadEntity
    }
  })
  // AGGREGATION GROUP
  .state('analytics-aggregation-groups-list', {
    url: '/analytics/aggregation-groups/list',
    isSubMenuItem: true,
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Aggregation Groups'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:aggregate-groups',
    accessScopeBe: 'tis_be:aggregate-groups',
    entity: 'aggregation-groups'
  })

  .state('analytics-aggregation-groups-new', {
    url: '/analytics/aggregation-groups/new',
    template: NewFormBasicView,
    controller: 'NewAggregationController',
    controllerAs: 'vm',
    pageTitle: gettext('New Aggregation'),
    parentState: 'analytics',
    theme: 'tango',
    iconMode: 'add',
    accessScopeFe: 'tis_fe:aggregate-groups',
    accessScopeBe: 'tis_be:aggregate-groups',
    mode: 'create',
    entity: 'aggregation-groups',
    resolve: {
      aggregationGroup: () => ({})
    }
  })

  .state('analytics-aggregation-groups-edit', {
    url: '/analytics/aggregation-groups/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewAggregationController',
    controllerAs: 'vm',
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:aggregate-groups',
    accessScopeBe: 'tis_be:aggregate-groups',
    mode: 'update',
    entity: 'aggregation-groups',
    resolve: {
      aggregationGroup: loadEntity
    }
  })

  .state('analytics-aggregation-groups-view', {
    url: '/analytics/aggregation-groups/{id}',
    template: aggregationView,
    controller: 'AggregationController',
    controllerAs: 'vm',
    pageTitle: gettext('Aggregation'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:aggregate-groups',
    accessScopeBe: 'tis_be:aggregate-groups',
    mode: 'read',
    entity: 'aggregation-groups',
    resolve: {
      aggregationGroup: loadEntity
    }
  })

  // CALCULATION
  .state('analytics-calculations-list', {
    url: '/analytics/calculations/list',
    isSubMenuItem: true,
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Calculations'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:analysis-calculation',
    accessScopeBe: 'tis_be:analysis-calculations',
    entity: 'analysis-calculations'
  })

  .state('analytics-calculations-new', {
    url: '/analytics/calculations/new',
    template: newCalculationView,
    controller: 'NewCalculationController',
    controllerAs: 'vm',
    pageTitle: gettext('New Calculation'),
    parentState: 'analytics',
    theme: 'tango',
    iconMode: 'add',
    accessScopeFe: 'tis_fe:analysis-calculation',
    accessScopeBe: 'tis_be:analysis-calculations',
    mode: 'create',
    entity: 'analysis-calculations',
    resolve: {
      loadScripts: loadMath,
      analysisCalculation: () => ({})
    }
  })

  .state('analytics-calculations-edit', {
    url: '/analytics/calculations/{id}/edit',
    template: newCalculationView,
    controller: 'NewCalculationController',
    controllerAs: 'vm',
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:analysis-calculation',
    accessScopeBe: 'tis_be:analysis-calculations',
    mode: 'update',
    entity: 'analysis-calculations',
    resolve: {
      loadScripts: loadMath,
      analysisCalculation: loadEntity
    }
  })

  .state('analytics-calculations-view', {
    url: '/analytics/calculations/{id}',
    template: calculationView,
    controller: 'CalculationController',
    controllerAs: 'vm',
    pageTitle: gettext('Calculation'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:analysis-calculation',
    accessScopeBe: 'tis_be:analysis-calculations',
    mode: 'read',
    entity: 'analysis-calculations',
    resolve: {
      loadScripts: loadMath,
      analysisCalculation: loadEntity
    }
  })
  // REPORTING
  .state('analytics-reports-template-edit', {
    url: '/analytics/reports/template/{id}/edit',
    template: reportingEditorView,
    controller: 'ReportingEditorController',
    resolve: {
      report: loadEntity,
      loadScripts: loadReports
    },
    controllerAs: 'vm',
    pageTitle: gettext('Demo Editor'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:reports',
    accessScopeBe: 'tis_be:reports',
    entity: 'reports'
  })

  .state('analytics-viewer-report-generic', {
    url: '/analytics/report-viewer/{id}?query',
    template: reportingViewer,
    controller: 'ReportingReportViewerController',
    controllerAs: 'vm',
    pageTitle: gettext('Demo Preview'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:reports',
    accessScopeBe: 'tis_be:reports',
    mode: 'read',
    entity: 'reports',
    resolve: {
      loadScripts: loadReports
    }
  })

  // REPORT
  .state('analytics-reports-list', {
    url: '/analytics/reports/list',
    isSubMenuItem: true,
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Reports'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:reports',
    accessScopeBe: 'tis_be:reports',
    entity: 'reports'
  })

  .state('analytics-reports-new', {
    url: '/analytics/reports/new',
    template: newReportView,
    controller: 'NewReportController',
    controllerAs: 'vm',
    pageTitle: gettext('New Report'),
    parentState: 'analytics',
    theme: 'tango',
    iconMode: 'add',
    accessScopeFe: 'tis_fe:reports',
    accessScopeBe: 'tis_be:reports',
    mode: 'create',
    entity: 'reports',
    resolve: {
      report: () => ({})
    }
  })

  .state('analytics-reports-edit', {
    url: '/analytics/reports/{id}/edit',
    template: newReportView,
    controller: 'NewReportController',
    controllerAs: 'vm',
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:reports',
    accessScopeBe: 'tis_be:reports',
    mode: 'update',
    entity: 'reports',
    resolve: {
      report: loadEntity
    }
  })

  .state('analytics-reports-duplicate', {
    url: '/analytics/reports/{id}/duplicate',
    template: newReportView,
    controller: 'NewReportController',
    controllerAs: 'vm',
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:reports',
    accessScopeBe: 'tis_be:reports',
    mode: 'create',
    entity: 'reports',
    resolve: {
      report: loadEntity
    }
  })

  .state('analytics-reports-view', {
    url: '/analytics/reports/{id}',
    template: reportView,
    controller: 'ReportController',
    controllerAs: 'vm',
    pageTitle: gettext('Report'),
    parentState: 'analytics',
    theme: 'tango',
    accessScopeFe: 'tis_fe:reports',
    accessScopeBe: 'tis_be:reports',
    mode: 'read',
    entity: 'reports',
    resolve: {
      report: loadEntity
    }
  });

export default AnalyticsRouteManager.routes;
