import { DateTime } from 'luxon';

NewInvoiceDetailController.$inject = [
  '$scope',
  'gettext',
  '$state',
  'CrudToastFactory',
  'AlertingService',
  'SfeHeader',
  'DetailInvoiceModel',
  'gettextCatalog',
  'detailInvoice',
  'InvoiceDetailFormService',
  'MasterInvoiceModel'
];

function NewInvoiceDetailController(
  $scope,
  gettext,
  $state,
  CrudToastFactory,
  AlertingService,
  SfeHeader,
  DetailInvoiceModel,
  gettextCatalog,
  detailInvoice,
  InvoiceDetailFormService,
  MasterInvoiceModel
) {
  const vm = this;
  const editMode = !!$state.params.invoiceDetailId || false;
  /**Used to identify priceList change on edit
   * to update detail values
   */
  let priceListDetailId = null;
  let masterInvoiceId;

  vm.api = {};
  init();

  async function init() {
    let priceList;
    masterInvoiceId = $state.params.invoiceId;

    if (editMode) {
      priceListDetailId = detailInvoice.priceListDetail;

      priceList = await InvoiceDetailFormService.getPriceList({
        priceListId: detailInvoice.priceListDetail
      });
    }
    const masterInvoice = await InvoiceDetailFormService.getInvoiceData({
      invoiceId: masterInvoiceId
    });

    vm.formConfig = {
      name: 'detailInvoiceForm1',
      actions: [
        {
          title: editMode ? gettext('Update') : gettext('Create'),
          fn: editMode ? update : create,
          disabledFn: api =>
            !api.formValidity() || !api.getValue('priceListItem')
        }
      ],
      fields: InvoiceDetailFormService.getFormFields({
        detailInvoice,
        priceList,
        editMode,
        masterInvoice,
        api: vm.api
      })
    };

    if (editMode) {
      vm.formHeader = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit Invoice Detail'),
        'invoices-invoices-detail-view',
        $state.params
      );
    } else {
      vm.formHeader = SfeHeader.constructFormHeader(
        'primary',
        gettext('New Invoice Detail'),
        'invoices-invoices-view',
        $state.params
      );
    }
    $scope.$applyAsync();
  }

  function dataToSave(values) {
    const {
      readDate,
      serviceDateFrom,
      serviceDateTo,
      normalizedServiceDateTo: to,
      normalizedServiceDateFrom: from
    } = values;
    const nTo = DateTime.fromJSDate(new Date(to[0]));
    const normalizedServiceDateTo = {
      year: nTo.year,
      month: nTo.month
    };
    const nFrom = DateTime.fromJSDate(new Date(from[0]));
    const normalizedServiceDateFrom = {
      year: nFrom.year,
      month: nFrom.month
    };
    return {
      masterInvoice: masterInvoiceId,
      energySourceType: values.energySourceType._id,
      priceListDetail: values.priceListItem._id,
      measuringPoint: values.measuringPoint._id,
      invoiceType: values.invoiceType.id,
      total: values.total,
      readDate:
        values.readDate != null
          ? new Date(readDate[0]).setHours(0, 0, 0, 0)
          : null,
      serviceDateFrom: new Date(serviceDateFrom[0]).setHours(0, 0, 0, 0),
      serviceDateTo: new Date(serviceDateTo[0]).setHours(23, 59, 59, 999),
      normalizedServiceDateTo,
      normalizedServiceDateFrom
    };
  }

  async function updateMasterInvoice() {
    try {
      await MasterInvoiceModel.update(
        { id: masterInvoiceId },
        { invoiceStatus: 1 }
      );
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  async function create(api) {
    const values = api.getValues();
    const detailInvoiceValues = await InvoiceDetailFormService.getDetailInvoiceValues(
      values.priceListItem._id
    );
    let queryObject = dataToSave(values);
    queryObject = {
      ...queryObject,
      detailInvoiceValues
    };
    try {
      const { data } = await DetailInvoiceModel.create(queryObject);
      await updateMasterInvoice();
      const redirect = {
        state: 'invoices-invoices-detail-view',
        params: {
          invoiceDetailId: data._id,
          invoiceId: masterInvoiceId
        }
      };
      CrudToastFactory.toast('create', redirect);
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  async function update(api) {
    const values = api.getValues();
    const updatedObj = dataToSave(values);

    if (!values.priceListItem._id) {
      AlertingService.Error(
        gettextCatalog.getString(
          'Can\t update detail invoice because there is no detail price list id'
        )
      );
    } else {
      if (values.priceListItem._id !== priceListDetailId) {
        const detailInvoiceValues = await InvoiceDetailFormService.getDetailInvoiceValues(
          values.priceListItem._id
        );
        updatedObj.detailInvoiceValues = detailInvoiceValues;
      }

      try {
        await DetailInvoiceModel.update(
          {
            id: $state.params.invoiceDetailId
          },
          updatedObj
        );
        await updateMasterInvoice();
        const redirect = {
          state: 'invoices-invoices-detail-view',
          params: $state.params
        };
        CrudToastFactory.toast('update', redirect);
      } catch (err) {
        AlertingService.Error(err);
      }
    }
  }
}

export default NewInvoiceDetailController;
