AssetsMeasuringpointEntityDateConfiguration.$inject = [
  'gettext',
  '$q',
  'AlertingService',
  'CrudToastFactory',
  'Formatting',
  'AssetMeasuringpointModel',
  'MeasuringpointModel',
  'TranslationService'
];

function AssetsMeasuringpointEntityDateConfiguration(
  gettext,
  $q,
  AlertingService,
  CrudToastFactory,
  Formatting,
  AssetMeasuringpointModel,
  MeasuringpointModel,
  TranslationService
) {
  var createObject = {
    validFrom: new Date(new Date().setHours(0, 0, 0, 0)),
    validTo: null
  };

  var configuration = {
    listId: 'assetsMeasuringpoint',
    type: 'single',
    entity: {
      inactiveTitle: gettext('Inactive measuringpoints'),
      title: gettext('Measuringpoint')
    },
    api: {
      query: {
        entity: 'asset-measuringpoints',
        method: 'custom.readMeasuringPoint'
      },
      activeFilterObject: {
        isActive: true
      },
      inactiveFilterObject: {
        isActive: false
      },
      enrich: Enrich
    },
    create: {
      form: getMeasuringpointAssetFormConfig(),
      createObject: angular.copy(createObject),
      createFn: create
    },
    update: update,
    getUpdateObject: getUpdateObject,
    delete: function(item) {
      var deferred = $q.defer();
      AssetMeasuringpointModel.delete({
        id: item._id
      }).then(
        function() {
          CrudToastFactory.toast('delete');
          deferred.resolve();
        },
        function(err) {
          AlertingService.Error(err);
          deferred.reject();
        }
      );
      return deferred.promise;
    },
    title: {
      param: 'nameReadable',
      link: {
        state: 'company-resources-measuring-points-view',
        params: [
          {
            valueParam: 'measuringpointId',
            queryParam: 'id'
          }
        ]
      }
    },
    shortActions: [
      {
        icon: 'mode_edit',
        type: 'edit'
      },
      {
        icon: 'delete',
        type: 'delete'
      }
    ],
    attributes: [
      {
        title: gettext('Energy source type'),
        param: 'energySourceTypeName',
        type: 'text'
      },
      {
        title: gettext('Location'),
        param: 'locationName',
        link: {
          state: 'company-resources-locations-view',
          params: [
            {
              valueParam: 'locationId',
              queryParam: 'id'
            }
          ]
        },
        type: 'link'
      },
      {
        title: gettext('Valid from'),
        param: 'validFromReadable',
        type: 'date-short'
      },
      {
        title: gettext('Valid to'),
        param: 'validToReadable',
        type: 'date-short'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ],
    extended: {
      query: Extend,
      items: [
        {
          title: gettext('Type'),
          param: 'type',
          type: 'text'
        },
        {
          title: gettext('Kind'),
          param: 'kind',
          type: 'text'
        },
        {
          title: gettext('Class'),
          param: 'class',
          type: 'text'
        },
        {
          title: gettext('Distributer'),
          param: 'distributer',
          link: {
            state: 'company-resources-business-partners-view',
            params: [
              {
                valueParam: 'businessPartnerDistributor',
                queryParam: 'id'
              }
            ]
          },
          type: 'link'
        },
        {
          title: gettext('Supplier'),
          param: 'supplier',
          link: {
            state: 'company-resources-business-partners-view',
            params: [
              {
                valueParam: 'businessPartnerSupplier',
                queryParam: 'id'
              }
            ]
          },
          type: 'link'
        }
      ]
    }
  };
  function Enrich(item) {
    var deferred = $q.defer();

    var measuringpoint = item.measuringpoint;
    item.nameReadable = measuringpoint.name;
    item.locationName = 'Unknown';
    item.energySourceTypeName = 'Unknown';
    if (measuringpoint) {
      item.measuringpointId = measuringpoint._id;
    }
    if (
      measuringpoint.hasOwnProperty('energySourceType') &&
      measuringpoint.energySourceType !== null
    ) {
      item.energySourceTypeName = measuringpoint.energySourceType.name;
      item.icon = measuringpoint.energySourceType.icon;
    }
    if (
      measuringpoint.hasOwnProperty('location') &&
      measuringpoint.location !== null
    ) {
      item.locationName = measuringpoint.location.name;
    }

    if (measuringpoint && measuringpoint.location) {
      item.locationId = measuringpoint._id;
      item.locationName = measuringpoint.name;
    }
    if (measuringpoint.hasOwnProperty('code') && measuringpoint.code !== null) {
      item.code = measuringpoint.code;
    }

    item.fetchingObject['validFromReadable'] = {
      fetching: false,
      success: true,
      nodata: false
    };
    item.fetchingObject['validToReadable'] = {
      fetching: false,
      success: true,
      nodata: false
    };
    item.fetchingObject['statusReadable'] = {
      fetching: true,
      success: true,
      nodata: false
    };
    if (
      item.hasOwnProperty('validFrom') &&
      item.validFrom !== null &&
      item.validFrom !== undefined
    ) {
      item.validFromReadable = item.validFrom;
    } else {
      item.fetchingObject['validFromReadable'].nodata = true;
    }

    if (
      item.hasOwnProperty('validTo') &&
      item.validTo !== null &&
      item.validTo !== undefined
    ) {
      item.validToReadable = item.validTo;
    } else {
      item.fetchingObject['validToReadable'].nodata = true;
    }
    return deferred.promise;
  }
  //configuration for editing the value
  function getMeasuringpointAssetFormConfig() {
    return [
      {
        name: 'measuringpoint',
        edit: true,
        componentType: 'autocompleteDialog',
        configuration: {
          query: {
            entity: 'measuringpoints',
            method: 'read'
          },
          required: true,
          floatingLabel: gettext('Select measuringpoint'),
          searchParamName: 'filter',
          entity: 'measuringpoints',
          createRedirect: {
            state: 'company-resources-measuringpoints-new'
          }
        }
      },
      {
        componentType: 'datePickerFromTo',
        from: 'validFrom',
        to: 'validTo',
        valToEmpty: true,
        labelFrom: gettext('Valid from *'),
        labelTo: gettext('Valid to'),
        required: true,
        onlyStartRequired: true,
        idFrom: 'from',
        idTo: 'to'
      }
    ];
  }

  function create(obj, paramObject) {
    var deferred = $q.defer();
    var apiObj = {
      validFrom: Formatting.formatDate(obj.validFrom, '00:00:00'),
      validTo: Formatting.formatDate(obj.validTo, '23:59:59'),
      measuringpoint: obj.measuringpoint._id
    };
    apiObj = Object.assign(apiObj, paramObject);
    AssetMeasuringpointModel.create(apiObj).then(
      function() {
        CrudToastFactory.toast('create');
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );
    return deferred.promise;
  }

  function update(obj, originalItem, paramObject) {
    var deferred = $q.defer();
    var apiObj = {
      validFrom: Formatting.formatDate(obj.validFrom, '00:00:00'),
      validTo: Formatting.formatDate(obj.validTo, '23:59:59') || null,
      measuringpoint: obj.measuringpoint._id
    };
    apiObj = Object.assign(apiObj, paramObject);
    AssetMeasuringpointModel.update(
      {
        id: originalItem._id
      },
      apiObj
    ).then(
      function() {
        CrudToastFactory.toast('update');
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );
    return deferred.promise;
  }
  //function that fills the fields when you update the value
  function getUpdateObject(item) {
    var validFrom = new Date(item.validFrom);
    var validTo =
      item.validTo && new Date(item.validTo) instanceof Date
        ? new Date(item.validTo)
        : null;
    return {
      validFrom: validFrom,
      validTo: validTo,
      measuringpoint: item.measuringpoint
    };
  }
  //function that gets the data when you click
  function Extend(item) {
    var deferred = $q.defer();
    MeasuringpointModel.custom.readMetadata(item.measuringpoint).then(
      function(data) {
        item.type = 'Unknown';
        item.kind = 'Unknown';
        item.class = 'Unknown';
        item.distributer = 'Unknown';
        item.supplier = 'Unknown';
        if (
          data.hasOwnProperty('measuringpointType') &&
          data.measuringpointType !== null
        ) {
          item.type = data.measuringpointType.name;
        }
        if (
          data.hasOwnProperty('measuringpointKind') &&
          data.measuringpointKind !== null
        ) {
          item.kind = TranslationService.GetCollectionById(
            'codelists.measuringPointKinds',
            data.measuringpointKind
          );
          if (item.kind) {
            item.kind = item.kind.name;
          }
        }
        if (
          data.hasOwnProperty('measuringpointClass') &&
          data.measuringpointClass !== null
        ) {
          item.class = TranslationService.GetCollectionById(
            'codelists.measuringPointClasses',
            data.measuringpointClass
          );
          if (item.class) {
            item.class = item.class.name;
          }
        }
        if (
          data.hasOwnProperty('businessPartnerDistributor') &&
          data.businessPartnerDistributor !== null
        ) {
          item.distributer = data.businessPartnerDistributor.name;
        }
        if (
          data.hasOwnProperty('businessPartnerSupplier') &&
          data.businessPartnerSupplier !== null
        ) {
          item.supplier = data.businessPartnerSupplier.name;
        }
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );

    return deferred.promise;
  }

  return configuration;
}
export default AssetsMeasuringpointEntityDateConfiguration;
