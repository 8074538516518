/**
 * @ngdoc service
 * @name common.MultiLanguage
 * @description helper function to construct multilanguage form post/put objects. It sets empty fields to default language input field value.
 * @property {function} constructForm
 */
export default function MultiLanguage() {
  /**
   * @memberof MultiLanguage.constructForm
   * @description Sets empty fields that have multiLanguage flag set to true to default language input field value..
   * @param {array} configurations array of form configurations
   * @param {object} formObject form object
   * @return {dataType}
   */
  function constructForm(configurations, formObject) {
    var missingLanguages = [];
    var filledLang;
    configurations.forEach(function(el) {
      if (el.componentType === 'multilanguage') {
        missingLanguages = [];
        if (formObject != null && typeof formObject[el.name] == 'object') {
          let item;
          for (let key in formObject[el.name]) {
            item = formObject[el.name][key];
            if (!item) {
              missingLanguages.push(key);
            } else {
              filledLang = key;
            }
          }
        } else {
          /*eslint no-console: ["error", { allow: ["error"] }] */
          console.error('Form object for ' + el.name + ' is not an object');
        }
        if (missingLanguages.length) {
          missingLanguages.forEach(function(key) {
            formObject[el.name][key] = formObject[el.name][filledLang];
          });
        }
      }
    });
    return formObject;
  }
  /**
   * @memberof MultiLanguage.translateMultiLanguageValue
   * @description When using populate and sending "allLang: true" in the header, populated values are returned that way.
   * This function takes the currently selected language code, traverses the object and changes the property value
   * so that instead of an object containing all the translation the property value is only the currently selected language
   * @param {Array} pathsToValuesThatNeedToBeTranslated - Array of strings that contains paths to the object values that require translation (example 'parentId.name')
   * @param {Object} data - the object containing the data that needs to be translated
   * @param {string} selectedLanguage - the code of the language being used (at the time of writing this documentation, en, si or hr)
   * @return {Object} a new object containing all translated value
   */
  function translateMultiLanguageValues(
    data,
    pathsToValuesThatNeedToBeTranslated,
    selectedLanguageCode
  ) {
    return pathsToValuesThatNeedToBeTranslated.reduce(
      (objectWithTranslatedValues, path) => {
        const pathArray = path.split('.');
        return selectLanguagePropertyByPath(
          objectWithTranslatedValues,
          pathArray,
          selectedLanguageCode
        );
      },
      data
    );
  }
  /**
   * @description creates a new object in which the property value on the specified path
   * changes from an object containing translated values, to the translation of the currently selected language
   * @function
   * @param {Object} sourceObject - Object in which we'll be updating the value
   * @param {Array} path - an array containing each part of the path to the value we are updating as a separate string
   * @param {string} selectedLanguageCode - the language code of the currently selected language
   * @return {Object} a new object with translated value
   */
  const selectLanguagePropertyByPath = function(
    sourceObject,
    path,
    selectedLanguageCode
  ) {
    const updatedObject = { ...sourceObject };
    const [propertyName, ...restOfThePath] = path;
    if (updatedObject[propertyName] && restOfThePath.length > 0) {
      updatedObject[propertyName] = selectLanguagePropertyByPath(
        updatedObject[propertyName],
        restOfThePath,
        selectedLanguageCode
      );
    } else if (
      updatedObject[propertyName] &&
      updatedObject[propertyName][selectedLanguageCode]
    ) {
      updatedObject[propertyName] =
        updatedObject[propertyName][selectedLanguageCode];
    }
    return updatedObject;
  };
  return {
    constructForm,
    translateMultiLanguageValues
  };
}
