MeasuringpointAllocatorEntityDateConfiguration.$inject = [
  'gettext',
  '$q',
  'AlertingService',
  'MeasuringpointModel',
  'gettextCatalog',
  'Formatting',
  'TranslationService',
  'CostCentreModel',
  'BusinessPartnerModel',
  'CrudToastFactory'
];

function MeasuringpointAllocatorEntityDateConfiguration(
  gettext,
  $q,
  AlertingService,
  MeasuringpointModel,
  gettextCatalog,
  Formatting,
  TranslationService,
  CostCentreModel,
  BusinessPartnerModel,
  CrudToastFactory
) {
  function allocatorTypeChanged(value, object) {
    var costCentreFilterObject = this.costCentreFilterObject;
    switch (value) {
    case 1:
      object.showBusinessPartner = false;
      object.showCostCentre = true;
      costCentreFilterObject.costCentreType = 1;
      break;
    case 2:
      object.showBusinessPartner = false;
      object.showCostCentre = true;
      costCentreFilterObject.costCentreType = 3;
      break;
    case 3:
      object.showCostCentre = false;
      object.showBusinessPartner = true;
      break;
    }
  }

  var createObject = {
    allocatorType: 1,
    // Fixed and Variable costs
    usageType: 3,
    allocatorKind: 1,
    showCostCentre: true,
    validFrom: new Date(new Date().setHours(0, 0, 0, 0)),
    validTo: null
  };
  var configuration = {
    listId: 'measuringPointSplitter',
    type: 'multiple',
    fixedItems: true,
    entity: {
      inactiveTitle: gettext('Inactive Measuring Point Allocators'),
      title: gettext('Measuring Point Allocators')
    },
    api: {
      enrich: Enrich
    },
    create: {
      _preserve_form_: true,
      formFn: getMeasuringpointSplitterFormConfig,
      createObject: createObject,
      createFn: create
    },
    update: update,
    getUpdateObject: getUpdateObject,
    delete: function(itemToDelete, params, items) {
      var deferred = $q.defer();
      var allocators = [];
      if (items) {
        items.forEach(function(item) {
          if (item._id !== itemToDelete._id) {
            allocators.push(getAllocateItem(item, false));
          }
        });
      }

      updateMeasuringPoint(
        allocators,
        params.measuringpoint,
        'delete',
        deferred
      );

      return deferred.promise;
    },
    title: {
      param: 'name',
      link: {
        stateName: 'titleState',
        params: [
          {
            queryParamName: 'titleParamName',
            valueParam: 'titleId'
          }
        ]
      }
    },
    shortActions: [
      {
        type: 'edit',
        icon: 'mode_edit'
      },
      {
        icon: 'delete',
        type: 'delete'
      }
    ],
    attributes: [
      {
        title: gettext('Valid from'),
        param: 'validFromReadable',
        type: 'date-short'
      },
      {
        title: gettext('Valid to'),
        param: 'validToReadable',
        type: 'date-short'
      },
      {
        title: gettext('Allocator value'),
        param: 'allocatorValue',
        type: 'text'
      },
      {
        title: gettext('Allocator Kind'),
        param: 'allocatorKindName',
        type: 'text'
      },
      {
        title: gettext('Allocator Type'),
        param: 'allocatorTypeName',
        type: 'text'
      },
      {
        title: gettext('Usage Type'),
        param: 'usageTypeName',
        type: 'text'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    item.fetchingObject['allocatorType'] = {
      fetching: false,
      success: true,
      nodata: false
    };
    item.fetchingObject['allocatorKind'] = {
      fetching: false,
      success: true,
      nodata: false
    };
    item.fetchingObject['usageType'] = {
      fetching: false,
      success: true,
      nodata: false
    };
    item.fetchingObject['validFromReadable'] = {
      fetching: false,
      success: true,
      nodata: false
    };
    item.fetchingObject['validToReadable'] = {
      fetching: false,
      success: true,
      nodata: false
    };
    var allocatorType;
    var allocatorKind;
    var usageType;
    if (item.allocatorType) {
      allocatorType = TranslationService.GetCollectionById(
        'codelists.allocatorTypes',
        item.allocatorType
      );
    }

    if (item.allocatorKind) {
      allocatorKind = TranslationService.GetCollectionById(
        'codelists.allocatorKinds',
        item.allocatorKind
      );
    }

    if (item.usageType) {
      usageType = TranslationService.GetCollectionById(
        'codelists.usageTypes',
        item.usageType
      );
    }
    if (usageType) {
      item.usageTypeName = usageType.name;
      item.fetchingObject['usageType'] = {
        fetching: false,
        success: true,
        nodata: false
      };
    } else {
      item.fetchingObject['usageType'] = {
        fetching: false,
        success: false,
        nodata: false
      };
    }

    if (allocatorType) {
      item.allocatorTypeName = allocatorType.name;
      item.fetchingObject['allocatorType'] = {
        fetching: false,
        success: true,
        nodata: false
      };
    } else {
      item.fetchingObject['allocatorType'] = {
        fetching: false,
        success: false,
        nodata: false
      };
    }

    if (allocatorKind) {
      item.allocatorKindName = allocatorKind.name;
      item.fetchingObject['allocatorKind'] = {
        fetching: false,
        success: true,
        nodata: false
      };
    } else {
      item.fetchingObject['allocatorKind'] = {
        fetching: false,
        success: false,
        nodata: false
      };
    }

    if (item.validFrom != null) {
      item.validFromReadable = item.validFrom;
    } else {
      item.fetchingObject['validFromReadable'].nodata = true;
    }

    if (item.validTo != null) {
      item.validToReadable = item.validTo;
    } else {
      item.fetchingObject['validToReadable'].nodata = true;
    }

    fetchStakeholder(item).then(
      function(stakeholder) {
        item.name = stakeholder.name;
        item.titleParamName = 'id';
        item.titleState = getStakeholderState(item);
        item.titleId = item.stakeholder;
      },
      function(err) {
        AlertingService.Error(err);
        item.name = gettextCatalog.getString('Unknown');
      }
    );

    return deferred.promise;
  }

  function getStakeholderState(item) {
    switch (item.allocatorType) {
    case 1:
    case 2:
      return 'company-resources-cost-centres-view';
    case 3:
      return 'company-resources-business-partners-view';
    }
  }

  function fetchStakeholder(item) {
    var deferred = $q.defer();
    var endpoint;
    switch (item.allocatorType) {
    case 1:
    case 2:
      endpoint = CostCentreModel.read;
      break;
    case 3:
      endpoint = BusinessPartnerModel.read;
      break;
    }
    if (endpoint) {
      endpoint({
        id: item.stakeholder
      }).then(
        function(res) {
          deferred.resolve(res.data);
        },
        function(err) {
          deferred.reject(err);
        }
      );
    } else {
      deferred.reject(gettext('Unknown allocator type'));
    }
    return deferred.promise;
  }

  function getMeasuringpointSplitterFormConfig(measuringPoint) {
    const { measuringpointKind } = measuringPoint;

    const costCentreFilterObject = {
      order: 'name',
      costCentreType: 1
    };
    const allocatorTypeConfiguration = {
      componentType: 'radio2',
      label: gettext('Select Allocator Type'),
      options: TranslationService.GetCollection('codelists.allocatorTypes'),
      name: 'allocatorType',
      valueField: 'id'
    };

    const allocatorKindConfiguration = {
      componentType: 'radio2',
      label: gettext('Select Allocator Kind'),
      options: TranslationService.GetCollection('codelists.allocatorKinds'),
      name: 'allocatorKind',
      valueField: 'id'
    };
    const usageTypeConfiguration = {
      componentType: 'radio2',
      label: gettext('Select Usage type'),
      options: TranslationService.GetCollection('codelists.usageTypes').filter(
        item =>
          (measuringpointKind == 2 /* submeter */ &&
            item.id != 1) /* fixed costs */ ||
          measuringpointKind != 2 /* submeter */
      ),
      name: 'usageType',
      valueField: 'id'
    };
    const costCentreAutocompleteConfiguration = {
      componentType: 'autocompleteDialog',
      edit: true,
      hide: true,
      showParam: 'showCostCentre',
      name: 'costCentre',
      configuration: {
        floatingLabel: gettext('Select cost centre'),
        required: true,
        query: {
          entity: 'cost-centres',
          method: 'read'
        },
        searchParamName: 'filter',
        filterObject: costCentreFilterObject,
        entity: 'cost-centres',
        dialogConfiguration: {
          filterObject: costCentreFilterObject
        },
        createRedirect: {
          state: 'company-resources-cost-centres-new'
        }
      }
    };
    const allocatorValueConfiguration = {
      placeholder: gettext('Allocator value'),
      name: 'allocatorValue',
      componentType: 'textField',
      type: 'numerical',
      required: true
    };
    const formConfig = [
      allocatorTypeConfiguration,
      {
        componentType: 'title',
        title: gettext('Stakeholder')
      },
      costCentreAutocompleteConfiguration,
      {
        hide: true,
        showParam: 'showBusinessPartner',
        name: 'businessPartner',
        edit: true,
        componentType: 'autocompleteDialog',
        configuration: {
          query: {
            entity: 'business-partners',
            method: 'read'
          },
          floatingLabel: gettext('Select Business Partner'),
          searchParamName: 'name',
          entity: 'business-partners',
          createRedirect: {
            state: 'company-resources-business-partners-new'
          }
        }
      },
      usageTypeConfiguration,
      allocatorKindConfiguration,
      allocatorValueConfiguration,
      {
        componentType: 'datePickerFromTo',
        from: 'validFrom',
        to: 'validTo',
        valToEmpty: true,
        labelFrom: gettext('Valid from *'),
        labelTo: gettext('Valid to'),
        required: true,
        onlyStartRequired: true,
        idFrom: 'from',
        idTo: 'to'
      }
    ];
    // BIND cost centreconfiguration to change filter parameters on allocator type change
    allocatorTypeConfiguration.changeFn = allocatorTypeChanged.bind({
      costCentreFilterObject: costCentreFilterObject
    });
    return formConfig;
  }

  function create(obj, paramObject, fixedItems) {
    var deferred = $q.defer();
    var allocators = [];
    if (fixedItems) {
      fixedItems.forEach(function(item) {
        allocators.push(getAllocateItem(item, false));
      });
    }
    allocators.push(getAllocateItem(obj, true));
    updateMeasuringPoint(
      allocators,
      paramObject.measuringpoint,
      'create',
      deferred
    );
    return deferred.promise;
  }

  function updateMeasuringPoint(allocators, id, toastMsg, deferred) {
    MeasuringpointModel.update(
      {
        id: id
      },
      {
        allocators: allocators
      }
    ).then(
      function(res) {
        CrudToastFactory.toast(toastMsg);
        deferred.resolve(res.data.allocators);
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject(err);
      }
    );
  }

  function getAllocateItem(item, changed) {
    return {
      allocatorKind: item.allocatorKind,
      allocatorType: item.allocatorType,
      allocatorValue: item.allocatorValue,
      stakeholder: !changed
        ? item.stakeholder
        : item.allocatorType === 3
          ? item.businessPartner._id
          : item.costCentre._id,
      usageType: item.usageType,
      validFrom: !changed
        ? item.validFrom
        : Formatting.formatDate(item.validFrom, '00:00:00'),
      validTo: !changed
        ? item.validTo
        : item.validTo != null
          ? new Date(item.validTo).setHours(23, 59, 59, 999)
          : null,
      _id: item._id
    };
  }

  function update(obj, originalItem, paramObject, fixedItems) {
    var deferred = $q.defer();
    var allocators = [];
    if (fixedItems) {
      fixedItems.forEach(function(item) {
        if (item._id === obj._id) {
          allocators.push(getAllocateItem(obj, true));
        } else {
          allocators.push(getAllocateItem(item, false));
        }
      });
    }
    updateMeasuringPoint(
      allocators,
      paramObject.measuringpoint,
      'update',
      deferred
    );
    return deferred.promise;
  }

  function getUpdateObject(item) {
    var validFrom = new Date(item.validFrom);
    var validTo =
      item.validTo && new Date(item.validTo) instanceof Date
        ? new Date(item.validTo)
        : null;
    var showCostCentre = item.allocatorType !== 3;

    return {
      _id: item._id,
      allocatorType: item.allocatorType,
      allocatorKind: item.allocatorKind,
      allocatorValue: item.allocatorValue,
      usageType: item.usageType,
      showCostCentre: showCostCentre,
      showBusinessPartner: !showCostCentre,
      validFrom: validFrom,
      validTo: validTo,
      costCentre: showCostCentre ? item.stakeholder : null,
      businessPartner: !showCostCentre ? item.stakeholder : null
    };
  }

  return configuration;
}
export default MeasuringpointAllocatorEntityDateConfiguration;
