module.exports = {
  entity: 'billing-periods',
  path: 'billing-periods',
  name: {
    en: 'Billing periods',
    sl_SI: 'Pregled obračunov'
  },
  networkModel: {
    entity: 'billing-periods',
    read: 'read'
  },
  scope: 'tis_be:billing-periods',

  dialog: {
    title: {
      en: 'Select billing period',
      sl_SI: 'Izberite obračunsko obdobje'
    },
    listInfo: {
      en: 'Select billing period from the list below',
      sl_SI: 'Izberite obračunsko obdobje s spodnjega seznama'
    }
  }
}
