import template from './sfe-multi-select-2.directive.html';

export default function sfeMultiSelect2() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      options: '=?',
      models: '=?',
      configuration: '<'
    },
    link: linkFn,
    controller: sfeMultiSelect2Controller,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  function linkFn() {}
}

sfeMultiSelect2Controller.$inject = [];

function sfeMultiSelect2Controller() {
  var vm = this;
  vm.addRow = addRow;
  vm.removeRow = removeRow;
  function addRow() {
    if (!vm.models) {
      vm.models = [];
    }
    if (!vm.options) {
      vm.options = [];
    }
    vm.models.push({});
    vm.options.push([]);
  }

  function removeRow(index) {
    vm.models.splice(index, 1);
  }
}
