TimeSeriesManualInputFormGroupTimeSeriesItemModel.$inject = [
  'ModelUtilities',
  'CrawlerMethods'
];

function TimeSeriesManualInputFormGroupTimeSeriesItemModel(
  ModelUtilities,
  CrawlerMethods
) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path:
      'time-series-manual-input-forms/:timeSeriesManualInputFormId/groups/:groupId/time-series-items/:timeSeriesItemId'
  };

  var crawlerConfiguration = {
    entity: 'time-series-manual-input-form-group-time-series-items',
    method: 'read',
    steps: [
      {
        entity: 'time-series',
        populate: 'dataScheduler',
        valueParams: ['timeSeries'],
        queryParams: ['id'],
        method: 'read',
        methodType: 'populate',
        noMerge: true
      }
    ]
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readItemView: CrawlerMethods.crawler(crawlerConfiguration)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default TimeSeriesManualInputFormGroupTimeSeriesItemModel;
