import template from './sfe-list-table-item.directive.html';

sfeListTableItem.$inject = [];

export default function sfeListTableItem() {
  var directive = {
    restrict: 'A',
    template,
    scope: {
      entity: '@',
      values: '<',
      listConfig: '<',
      objectManipulation: '<',
      paginationLimit: '<',
      paginationPage: '<',
      index: '<',
      loadingStatus: '='
    },
    controller: 'SfeListItemController',
    controllerAs: 'vm',
    bindToController: true
  };
  return directive;
}
