import './counter-type.scss';

CounterTypeViewController.$inject = [
  '$timeout',
  'MetadataService',
  'gettext',
  'gettextCatalog',
  'counterType'
];

function CounterTypeViewController(
  $timeout,
  MetadataService,
  gettext,
  gettextCatalog,
  counterType
) {
  const vm = this;

  function init() {
    MetadataService.Loading(true);
    vm.loading = true;
    vm.counterType = counterType;

    $timeout(() => {
      MetadataService.ChangeMetadata(
        vm.counterType.name,
        vm.counterType.description
      );
    });
    MetadataService.Loading(false);
    vm.loading = false;
    vm.headerData = constructHeader(vm.counterType);
  }

  function constructHeader(value) {
    const actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: value._id,
        endpoint: {
          entity: 'counter-types',
          method: 'delete'
        },
        redirectState: 'configurations-energy-management-counter-types-list'
      },
      {
        title: gettext('Update'),
        state: 'configurations-energy-management-counter-types-edit',
        param: 'id',
        paramValue: value._id
      }
    ];

    const propertySections = [
      {
        title: gettext('Properties'),
        properties: [
          {
            title: gettext('Code'),
            value: value.code,
            type: 'code'
          },
          {
            title: gettext('Energy Source Type'),
            value:
              value.energySourceType && value.energySourceType.name
                ? value.energySourceType.name
                : gettextCatalog.getString('None'),
            energyIcon: value.energySourceType
              ? value.energySourceType.icon
              : null,
            type: 'simple'
          },
          {
            title: gettext('Number of tariffs'),
            value: value.numberOfTarifs,
            type: 'code'
          }
        ]
      }
    ];

    return {
      metadata: {
        definition: gettext('Counter types'),
        title: value.name,
        description: value.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }

  init();
}

export default CounterTypeViewController;
