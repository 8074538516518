TimeSeriesVariableService.$inject = [
  'gettextCatalog',
  'TranslationService',
  'AlertingService',
  'AssetTypeAttributeModel',
  'LocationTypeAttributeModel',
  'DomainAttributeModel',
  'SchedulerModel',
  'LocalizationService'
];
export default function TimeSeriesVariableService(
  gettextCatalog,
  TranslationService,
  AlertingService,
  AssetTypeAttributeModel,
  LocationTypeAttributeModel,
  DomainAttributeModel,
  SchedulerModel,
  LocalizationService
) {
  /**
   * @description returns timeSeries mapping function autocomplete configuration.
   * @function
   * @param {boolean} regularInput indicates input type
   * @return {Object}
   */
  function getMappingFunctionAutocomplete(options) {
    const currentLanguage = LocalizationService.getLocale();
    const collator = new Intl.Collator(currentLanguage || 'en');
    const { regularInput } = options;
    let mappingFunctionsCodelist = 'calculatedFlowMappingFunctions';
    if (options.mappingFunctionsCodelist != null) {
      mappingFunctionsCodelist = options.mappingFunctionsCodelist;
    }
    let mappingFunctions = TranslationService.GetCollection(
      `codelists.${mappingFunctionsCodelist}`
    );
    if (!regularInput) {
      mappingFunctions = mappingFunctions.filter(
        item => !item.onlyAllowRegularInputs
      );
    }
    //Sort mapping functions by priority level and by name
    mappingFunctions = mappingFunctions.sort((a, b) => {
      if (a.priorityLevel === b.priorityLevel) {
        return collator.compare(a.name, b.name);
      } else {
        return a.priorityLevel - b.priorityLevel;
      }
    });

    return {
      title: gettextCatalog.getString('Mapping functions'),
      options: {
        items: mappingFunctions,
        valueField: 'id',
        crawlerParams: searchTerm => ({
          param: 'name',
          searchTerm,
          type: 'string'
        }),
        display: item => ({
          text: item != null ? item.name : gettextCatalog.getString('Unknown')
        })
      }
    };
  }
  /**
   * @description returns attributes integration method argument autocomplete configuration.
   * @function
   * @return {Object}
   */
  function getIntegrationMethodsAutocomplete() {
    return {
      title: gettextCatalog.getString('Integration Methods'),
      options: {
        items: TranslationService.GetCollection('codelists.integrationMethods'),
        valueField: 'id',
        crawlerParams: searchTerm => ({
          param: 'name',
          searchTerm,
          type: 'string'
        }),
        display: item => ({
          text: item != null ? item.name : gettextCatalog.getString('Unknown')
        })
      }
    };
  }

  /**
   * @description returns new variable name.
   * @function
   * @param {Array} timeSeriesModelValue
   * @param {Array} attributeModelValue
   * @return {String}
   */
  function createVariableName(timeSeriesModelValue, attributeModelValue) {
    let timeSeriesVariables = [];
    if (Array.isArray(timeSeriesModelValue)) {
      timeSeriesVariables = timeSeriesModelValue.reduce((variables, item) => {
        let timeSeriesParams = [];
        if (
          item.timeSeriesObject != null &&
          Array.isArray(item.timeSeriesObject.timeSeriesParams)
        ) {
          timeSeriesParams = item.timeSeriesObject.timeSeriesParams;
        } else if (Array.isArray(item.timeSeriesParams)) {
          timeSeriesParams = item.timeSeriesParams;
        }
        variables = [
          ...variables,
          ...timeSeriesParams.map(param => param.variableName)
        ];
        return variables;
      }, []);
    }
    let attributeVariables = [];
    if (Array.isArray(attributeModelValue)) {
      attributeVariables = attributeModelValue.reduce((variables, item) => {
        if (Array.isArray(item.attributeParams)) {
          variables = [
            ...variables,
            ...item.attributeParams.map(param => param.name)
          ];
        }
        return variables;
      }, []);
    }
    const usedNames = [...timeSeriesVariables, ...attributeVariables];
    let counter = usedNames.length;

    var variableName = nextVariableName(counter);
    // using for instead of while to escape accidental forever loop
    for (var x = 0; x < usedNames.length + 1; x++) {
      if (
        usedNames.indexOf(variableName) === -1 &&
        variableName !== 'or' &&
        variableName !== 'and'
      ) {
        // var is ok
        break;
      } else {
        // get next var name
        counter++;
        variableName = nextVariableName(counter);
      }
    }
    return variableName;
  }
  /**
   * @description returns String by counter number.
   * @function
   * @param {Number} counter number of already used variables
   * @return {String}
   */
  function nextVariableName(counter) {
    var repeats = Math.floor(counter / 26);
    var result = '';
    if (repeats > 0) {
      result += String.fromCharCode(97 + repeats - 1);
    }
    return result + String.fromCharCode(97 + (counter - repeats * 26));
  }

  /**
   * @description depending on selected attribute type codelist id returns attribute autocomplete configuration.
   * @function
   * @param {number} selected selected attribute type
   * @return {Object}
   */
  function getEntityAutocomplete(selected, filter) {
    let crawlerParams = text => {
      let params = {};
      if (text != null && text != '') {
        return {
          ...params,
          filter: text
        };
      }
      return params;
    };
    let itemsCrawler;
    let title;
    let dialog;
    switch (selected) {
    case 1: //asset
      itemsCrawler = {
        entity: 'assets',
        method: 'read'
      };
      title = gettextCatalog.getString('Asset');
      dialog = {
        entity: 'assets'
      };
      break;
    case 2: //location
      title = gettextCatalog.getString('Location');

      itemsCrawler = {
        entity: 'locations',
        method: 'read'
      };
      dialog = {
        entity: 'locations'
      };
      break;
    default:
      AlertingService.Error(
        gettextCatalog.getString('Unknown attribute type')
      );
      return;
    }
    return {
      title,
      options: {
        itemsCrawler,
        valueField: '_id',
        filter,
        crawlerParams,
        display: item => ({
          text: item != null ? item.name : gettextCatalog.getString('Unknown')
        }),
        dialog
      }
    };
  }
  /**
   * @description depending on selected item returns dynamic attributes array for attributes autocomplete configuration.
   * @function
   * @param {Object} item must contain attributeType <Number> - codelist id of selected attribute type (Asset or Location), attribute - selected attribute must contain type
   * @return {Array}
   */
  async function fetchAttributes(item) {
    let items = [];
    if (
      item != null &&
      item.attributeType != null &&
      item.attribute != null &&
      typeof item.attribute == 'object'
    ) {
      try {
        let getType =
          item.attributeType == 1
            ? AssetTypeAttributeModel
            : LocationTypeAttributeModel;
        /* If item changed using dialog type will be an object  */
        const attributeType =
          typeof item.attribute.type === 'object'
            ? item.attribute.type._id
            : item.attribute.type;
        let { data } = await getType.read({
          type: attributeType
        });
        if (Array.isArray(data) && data.length > 0) {
          const filter = {
            _id: data.map(item => item.domainAttribute)
          };
          let { data: domainItems } = await DomainAttributeModel.read(filter);
          items = domainItems;
        }
      } catch (err) {
        AlertingService.Error(err);
      }
    }
    return items;
  }
  /**
   * @description returns dynamic attribute autocomplete.
   * @function
   * @param {Object} attributeItem must contain attributeType <Number> - codelist id of selected attribute type (Asset or Location), attribute - selected attribute must contain type
   * @return {Object}
   */
  async function getAttributeAutocomplete(attributeItem) {
    let crawlerParams = searchTerm => ({
      param: 'name',
      searchTerm,
      type: 'string'
    });
    return {
      title: gettextCatalog.getString('Attribute'),
      options: {
        items: await fetchAttributes(attributeItem),
        valueField: '_id',
        crawlerParams,
        display: item => ({
          text: item != null ? item.name : gettextCatalog.getString('Unknown')
        })
      }
    };
  }

  /**
   * @description before updating ngModel removes all configuration items.
   * @function
   * @param {Array} timeSeries
   * @param {Array} attributes
   * @return {Array}
   */
  function processModelBeforeUpdate(model, timeSeries, attributes) {
    if (!Array.isArray(model)) {
      model = [];
    }
    let cleanTimeseries = [];
    if (Array.isArray(timeSeries)) {
      cleanTimeseries = timeSeries.map(({ timeSeriesObject }) => {
        let timeSeriesParams = [];
        if (
          timeSeriesObject != null &&
          Array.isArray(timeSeriesObject.timeSeriesParams)
        ) {
          timeSeriesParams = timeSeriesObject.timeSeriesParams.map(
            ({ paramObject, variableName }) => {
              return {
                ...paramObject,
                name: variableName
              };
            }
          );
        }
        return {
          timeSeries:
            timeSeriesObject != null ? timeSeriesObject.timeSeries : null,
          timeSeriesParams,
          triggersCalculation:
            timeSeriesObject != null
              ? timeSeriesObject.triggersCalculation
              : false
        };
      });
    }
    let cleanAttributes = [];
    if (Array.isArray(attributes)) {
      cleanAttributes = attributes.map(item => {
        let {
          uniqIdentifier,
          attributeType,
          attribute,
          attributeParams = []
        } = item;
        attributeParams = attributeParams.map(param => {
          let { uniqIdentifier, mappingFunction, attribute, name } = param;
          return {
            uniqIdentifier,
            mappingFunction,
            attribute,
            name
          };
        });

        return {
          uniqIdentifier,
          attributeType,
          attribute,
          attributeParams
        };
      });
    }
    model[0] = cleanTimeseries;
    model[1] = cleanAttributes;
    return model;
  }

  /**
   * @description populates input timeseries with dataSchedulers.
   * @function
   * @param {Object} item calcualted flow item
   * @return {Object}
   */
  async function populateScheduler(item) {
    if (item != null) {
      let { inputTimeSeries } = item;
      let schedulers = inputTimeSeries
        .map(tsItem => {
          if (
            tsItem.timeSeries != null &&
            typeof tsItem.timeSeries == 'object'
          ) {
            return tsItem.timeSeries.dataScheduler;
          }
        })
        .filter(scheduler => scheduler != '' && typeof scheduler === 'string')
        .filter((value, index, allItems) => allItems.indexOf(value) === index);

      if (schedulers.length > 0) {
        try {
          let { data } = await SchedulerModel.read({ _id: schedulers });
          let populatedInputTimeSeries = inputTimeSeries.map(tsItem => {
            if (
              tsItem.timeSeries != null &&
              typeof tsItem.timeSeries == 'object'
            ) {
              let foundScheduler = data.find(
                scheduler => scheduler._id === tsItem.timeSeries.dataScheduler
              );
              if (foundScheduler != null) {
                tsItem.timeSeries.dataScheduler = foundScheduler;
              }
            }

            return tsItem;
          });
          item = {
            ...item,
            inputTimeSeries: populatedInputTimeSeries
          };
        } catch (err) {
          AlertingService.Error(err);
        }
      }
    }
    return item;
  }

  return {
    getMappingFunctionAutocomplete,
    createVariableName,
    getEntityAutocomplete,
    getAttributeAutocomplete,
    processModelBeforeUpdate,
    populateScheduler,
    getIntegrationMethodsAutocomplete
  };
}
