function LocationConfigurationController() {
  var vm = this;
  vm.lists = [
    'location-types',
    'location-statuses',
    'location-action-statuses',
    'location-classifications'
  ];
}

export default LocationConfigurationController;
