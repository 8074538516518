domainConfiguration.$inject = ['gettext', '$q'];

function domainConfiguration(gettext, $q) {
  var configuration = {
    entity: {
      singular: gettext('Domain'),
      plural: gettext('Domains')
    },
    api: {
      query: {
        entity: 'domains',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'configurations-dynamic-attributes-domains-view',
        params: [
          {
            queryParam: 'id',
            valueParam: '_id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'configurations-dynamic-attributes-domains-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'configurations-dynamic-attributes-domains-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'domains',
            method: 'delete'
          }
        }
      }
    ],
    badges: [],
    attributes: [
      {
        param: 'description',
        title: gettext('Description')
      },
      {
        param: 'collectionFriendlyName',
        title: gettext('Friendly name')
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Friendly Name'),
        param: 'collectionFriendlyName',
        type: 'string'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich() {
    var deferred = $q.defer();
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default domainConfiguration;
