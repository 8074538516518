import './sfe-entity-date.scss';
import template from './sfe-entity-date.component.html';

/**
 * @ngdoc components
 * @name common.sfeEntityDate
 * @description functions used to connect entities with validity dates
 * @param {string} entity - name of the entity used by the component
 * @param {object} items - stores values created by the component
 * @param {object} filterObject - Object containing identifying infromation about the tntity used to edit proper data
 * @param {object} entityConfiguration - Configuration for the entity that might differ from the global configuration for that entity
 * @param {boolean} preserve - false if we can't to do a copy of entity configuration, true if we want to mutate the existign objects.
 * @example
 * <sfe-entity-date
 * entity="'asset-time-series'"
 * items="vm.items"
 * filterObject="vm.assetEntityFilterObject"
 * entityConfiguration="vm.assetEntityConfig"
 * preserve="false"
 * ></sfe-entity-date>
 */
export default {
  restrict: 'E',
  template,
  bindings: {
    entity: '@',
    items: '<',
    filterObject: '<',
    entityConfiguration: '<',
    preserve: '<',
    relistData: '=?'
  },
  controller: entityDateController,
  controllerAs: 'vm',
  bindToController: true
};

entityDateController.$inject = [
  'SfeEntityDateConfiguration',
  'gettext',
  'AlertingService',
  'InfoDialog',
  'entityDateFixedItems',
  '$mdDialog',
  'CrawlerMethods',
  'StorageService',
  '$scope',
  'CrudToastFactory'
];

function entityDateController(
  SfeEntityDateConfiguration,
  gettext,
  AlertingService,
  InfoDialog,
  entityDateFixedItems,
  $mdDialog,
  CrawlerMethods,
  StorageService,
  $scope,
  CrudToastFactory
) {
  var vm = this;
  vm.$onInit = init;
  vm.actions = [];

  vm.$onDestroy = () => {
    window.removeEventListener('resize', resizeEventListener);
  };

  vm.$onChanges = function(changes) {
    if (changes.items && vm.items) {
      vm.data = entityDateFixedItems.list(vm.items, true);
    }
  };
  /**
   * @description function that is called on component initilization
   * @function
   */
  function init() {
    var mainConfiguration = vm.preserve
      ? SfeEntityDateConfiguration.configurations[vm.entity]
      : angular.copy(SfeEntityDateConfiguration.configurations[vm.entity]);
    var configuration = Object.assign(
      mainConfiguration,
      vm.entityConfiguration || {}
    );
    var editAction = _.find(configuration.shortActions, {
      type: 'edit'
    });
    var deleteAction = _.find(configuration.shortActions, {
      type: 'delete'
    });

    vm.activeFilterObject = Object.assign(
      configuration.api.activeFilterObject || {},
      vm.filterObject || {}
    );
    vm.inactiveFilterObject = Object.assign(
      configuration.api.inactiveFilterObject || {},
      vm.filterObject || {}
    );
    vm.configuration = configuration;
    vm.displayMode = StorageService.getListDisplayMode(vm.configuration.listId);

    if (vm.configuration.create) {
      vm.actions.push({
        label: gettext('Create'),
        icon: {
          type: 2,
          name: 'add'
        },
        color: 'primary',
        fn: openCreateForm
      });
    }

    vm.actions.push({
      label: gettext('Hide history'),
      icon: {
        type: 2,
        name: 'restore'
      },
      color: 'primary',
      fn: toggleShowInactive
    });
    if (editAction) {
      editAction.action = openEditForm;
    }
    if (deleteAction) {
      deleteAction.action = deleteItem;
    }

    if (vm.configuration.type === 'single') {
      readMainItem();
    }
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );

    setScreenDependingProperties(vw);
  }

  window.addEventListener('resize', resizeEventListener);

  function resizeEventListener() {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    setScreenDependingProperties(vw);
  }

  function setScreenDependingProperties(width) {
    if (width > 720) {
      vm.displayMode = vm.displayMode || 'table';

      vm.sfeDisplayTypeButtonsConfig = {
        displayMode: vm.displayMode,
        configId: vm.configuration.listId,
        changeViewMode
      };
    } else {
      vm.displayMode = 'list';
      vm.sfeDisplayTypeButtonsConfig = {
        hideModeButtons: true,
        displayMode: vm.displayMode,
        configId: vm.configuration.listId,
        changeViewMode
      };
    }
  }

  function changeViewMode(mode) {
    vm.displayMode = mode;
    StorageService.saveListDisplayMode(vm.configuration.listId, vm.displayMode);
  }
  /**
   * @description Function that adds missing time when a date is selected
   * @function
   */
  function addValidToOnChangeAction() {
    var datePickerConfig = _.find(vm.formConfiguration.data, {
      componentType: 'datePickerFromTo'
    });
    if (datePickerConfig) {
      datePickerConfig.onChangeTo = function(value) {
        var timeToConfig = _.find(vm.formConfiguration.data, function(config) {
          if (config.componentType === 'datePickerFromTo') {
            return config;
          }
        });
        if (timeToConfig) {
          if (value.validTo) {
            if (!vm.formConfiguration.dataObj['timeTo']) {
              vm.formConfiguration.dataObj['timeTo'] = '23:59';
            }
          } else {
            if (timeToConfig.subData && timeToConfig.subData[1]) {
              timeToConfig.subData[1].required = false;
            }
            vm.formConfiguration.dataObj['timeTo'] = null;
          }
        }
      };
    }
  }
  /**
   * @description Function that toggles showning currently inactive values.
   * @function
   */
  function toggleShowInactive() {
    vm.showInactive = !vm.showInactive;
    if (vm.configuration.fixedItems && vm.showInactive) {
      vm.inactiveData = entityDateFixedItems.list(vm.items, false);
    }
  }

  function getFormConfig() {
    let form = vm.configuration.create._preserve_form_
      ? vm.configuration.create.form
      : angular.copy(vm.configuration.create.form);

    if (typeof vm.configuration.create.formFn === 'function') {
      form = vm.configuration.create.formFn(vm.entityConfiguration.entityItem);
    }
    return form;
  }
  /**
   * @description Function that opens a form for creating new values that are shown by the entity-date component.
   * @function
   */
  function openCreateForm() {
    if (vm.showForm !== true) {
      vm.formConfiguration = new Object();
      vm.showForm = true;
      const form = getFormConfig();
      vm.formConfiguration = {
        data: form,
        dataObj: angular.copy(vm.configuration.create.createObject) || {},
        action: {
          ctrlFn: true,
          title: gettext('Save'),
          fn: create
        },
        cancel: {
          fn: function() {
            vm.showForm = false;
            resetForm();
          },
          title: gettext('cancel')
        }
      };
      //if we open a create form we want edit to be set to false.
      if (
        vm.formConfiguration.data &&
        Array.isArray(vm.formConfiguration.data)
      ) {
        vm.formConfiguration.data = vm.formConfiguration.data.map(config => {
          let edit = false;
          //specific case where the edit is resued inside the component
          if (config.componentType === 'multiSelectList') {
            if (config['selectConfigs'] != null) {
              return {
                ...config,
                selectConfigs: config.selectConfigs.map(item => {
                  if (item['edit']) {
                    return {
                      ...item,
                      edit
                    };
                  }
                  return {
                    ...item
                  };
                })
              };
            }
          } else if (config['edit']) {
            return {
              ...config,
              edit
            };
          }
          return config;
        });
      }
      addValidToOnChangeAction();
    }
  }
  /**
   * @description resets the form being created with openCreateForm()  or updated by the openEditForm() function.
   * @function
   */
  function resetForm() {
    vm.formConfiguration = {};
  }
  /**
   * @description creates a new entry that is shown in the component.
   * @function
   * @param {object} item - value being saved
   */
  function create(item) {
    vm.configuration.create
      .createFn(
        item,
        vm.configuration.createParam || {},
        vm.configuration.fixedItems ? vm.items : null
      )
      .then(
        function(items) {
          if (vm.configuration.fixedItems) {
            vm.items = items;
            vm.data = entityDateFixedItems.list(vm.items, true);
            vm.inactiveData = entityDateFixedItems.list(vm.items, false);
            vm.showForm = false;
            resetForm();
          } else {
            vm.relistData = true;
            vm.relistInactiveData = true;
            vm.showForm = false;
            resetForm();
            if (vm.configuration.type === 'single') {
              readMainItem();
            }
          }
          CrudToastFactory.toast('create');
        },
        function() {
          vm.showForm = false;
          resetForm();
        }
      );
  }
  /**
   * @description updates an existing entry that is shown in the component.
   * @function
   * @param {object} item - value being saved
   */
  function update() {
    vm.configuration
      .update(
        this.updated,
        this.original,
        vm.configuration.updateParam || {},
        vm.items
      )
      .then(
        function(items) {
          if (vm.configuration.fixedItems) {
            vm.showForm = false;
            vm.items = items;
            vm.data = entityDateFixedItems.list(vm.items, true);
            vm.inactiveData = entityDateFixedItems.list(vm.items, false);
            resetForm();
          } else {
            vm.showForm = false;
            vm.relistData = true;
            vm.relistInactiveData = true;
            resetForm();
            if (vm.configuration.type === 'single') {
              readMainItem();
            }
          }
          CrudToastFactory.toast('update');
        },
        function() {
          vm.showForm = false;
          resetForm();
        }
      );
  }
  /**
   * @description Function that opens a form for updating existing values that are shown by the entity-date component.
   * @param {object} item - the item being edited
   * @function
   */
  function openEditForm(item) {
    vm.formConfiguration = new Object();
    const object = vm.configuration.getUpdateObject(item);
    vm.showForm = true;
    const variables = {
      original: item,
      updated: object
    };

    const form = getFormConfig();

    vm.formConfiguration = {
      data: form,
      dataObj: object || {},
      action: {
        ctrlFn: true,
        title: gettext('Save'),
        fn: function() {
          update.call(variables);
        }
      },
      cancel: {
        fn: function() {
          vm.showForm = false;
          resetForm();
        },
        title: gettext('cancel')
      }
    };
    addValidToOnChangeAction();
  }
  /**
   * @description function that is called when deleting a value from the component.
   * @function
   * @param {object} item - the item being deleted
   */
  function deleteItem(item) {
    var title = gettext('Confirmation');
    var textItem = {
      text: gettext('Are you sure you want to delete this item?'),
      type: 'text'
    };

    var actions = [
      {
        title: gettext('Cancel'),
        cancel: true,
        color: 'primary'
      },
      {
        title: gettext('Delete'),
        fn: Delete,
        color: 'warn'
      }
    ];

    InfoDialog.open(title, null, [textItem], actions);

    function Delete() {
      $mdDialog.hide();
      vm.showForm = false;
      vm.configuration
        .delete(item, vm.configuration.updateParam || {}, vm.items)
        .then(function(items) {
          if (vm.configuration.fixedItems) {
            vm.items = items;
            vm.data = entityDateFixedItems.list(vm.items, true);
            vm.inactiveData = entityDateFixedItems.list(vm.items, false);
          } else {
            var active = _.find(vm.data, {
              _id: item._id
            });
            if (active) {
              vm.relistData = true;
            } else {
              vm.relistInactiveData = true;
              if (vm.configuration.type === 'single') {
                readMainItem();
              }
            }
          }
          CrudToastFactory.toast('delete');
        });
    }
  }
  /**
   * @description gets the data for the currently active value.
   * @function
   */
  function readMainItem() {
    vm.mainItem = null;
    var method = CrawlerMethods.getMethod(vm.configuration.api.query);
    method(vm.activeFilterObject).then(
      function(res) {
        if (res.data) {
          vm.mainItem = res.data[0];
        } else {
          vm.mainItem = null;
        }
        $scope.$evalAsync();
      },
      function(err) {
        AlertingService.Error(err);
        vm.mainItem = null;
        $scope.$evalAsync();
      }
    );
  }
}
