import { RouteManager, gettext } from '../routes';

import ComponentEditorView from './pages/component-editor/component-editor.view.html';
import VisualizationViewerView from './pages/visualization-viewer/visualization-viewer.view.html';
import VisualizationEditorView from './pages/visualization-editor/visualization-editor.view.html';
import SfeListView from '../common/components/sfe-list/sfe-list.view.html';

loadCharts.$inject = ['loadAssets'];
function loadCharts(loadAssets) {
  return loadAssets(['highcharts']);
}

loadMxClient.$inject = ['loadAssets'];
function loadMxClient(loadAssets) {
  return loadAssets(['mxClient', 'math']);
}
loadEntity.$inject = ['$transition$', 'StateResolveService'];
function loadEntity($transition$, StateResolveService) {
  return StateResolveService.fetchEntityForView($transition$);
}
let VisualizationRouteManager = new RouteManager();
VisualizationRouteManager.state('data-visualization', {
  url: '/data-visualization',
  isFullscreen: true,
  isMainMenuItem: true,
  icon: 'insert_chart',
  menuIndex: 5,
  // isMainMenuItem: true,
  pageTitle: gettext('Data Visualization'),
  redirectTo: 'data-visualization-visualizations-list',
  accessScopeFe: 'tis_fe:visualizations-module',
  accessScopeBe: 'tis_fe:visualizations-module'
})
  .state('data-visualization-visualizations-list', {
    url: '/data-visualization/visualizations/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Visualizations'),
    icon: 'show_chart',
    parentState: 'data-visualization',
    theme: 'tango',
    accessScopeFe: 'tis_fe:visualizations',
    accessScopeBe: 'tis_be:visualizations',
    resolve: {
      loadScripts: loadMxClient
    }
  })
  .state('data-visualization-visualizations-viewer', {
    url: '/data-visualization/visualizations/{id}',
    template: VisualizationViewerView,
    controller: 'VisualizationViewerController',
    controllerAs: 'vm',
    resolve: {
      loadScripts: loadMxClient,
      visualization: loadEntity
    },
    isSubMenuItem: false,
    pageTitle: gettext('Viewer'),
    icon: 'important_devices',
    parentState: 'data-visualization',
    accessScopeFe: 'tis_fe:visualizations',
    accessScopeBe: 'tis_be:visualizations',
    mode: 'read',
    entity: 'visualizations'
  })
  .state('data-visualization-visualizations-edit', {
    url: '/data-visualization/visualizations/{id}/edit',
    template: VisualizationEditorView,
    controller: 'VisualizationEditorController',
    controllerAs: 'vm',
    resolve: {
      loadScripts: loadMxClient,
      visualization: loadEntity
    },
    isSubMenuItem: false,
    pageTitle: gettext('Edit visualization'),
    icon: 'important_devices',
    parentState: 'data-visualization',
    accessScopeFe: 'tis_fe:visualizations',
    accessScopeBe: 'tis_be:visualizations',
    mode: 'update',
    entity: 'visualizations'
  })
  .state('data-visualization-visualizations-duplicate', {
    url: '/data-visualization/visualizations/{id}/duplicate',
    template: VisualizationEditorView,
    controller: 'VisualizationEditorController',
    controllerAs: 'vm',
    resolve: {
      loadScripts: loadMxClient,
      visualization: loadEntity
    },
    mode: 'create',
    isSubMenuItem: false,
    pageTitle: gettext('Edit visualization'),
    icon: 'important_devices',
    parentState: 'data-visualization',
    accessScopeFe: 'tis_fe:visualizations',
    accessScopeBe: 'tis_be:visualizations',
    entity: 'visualizations'
  })
  .state('data-visualization-editor', {
    url: '/data-visualization/editor',
    template: VisualizationEditorView,
    controller: 'VisualizationEditorController',
    controllerAs: 'vm',
    resolve: {
      loadScripts: loadMxClient,
      visualization: () => ({})
    },
    mode: 'create',
    isSubMenuItem: false,
    pageTitle: gettext('Editor'),
    icon: 'important_devices',
    parentState: 'data-visualization',
    accessScopeFe: 'tis_fe:visualizations',
    accessScopeBe: 'tis_be:visualizations',
    entity: 'visualizations'
  })
  .state('data-visualization-component-editor', {
    url: '/data-visualization/component-editor',
    template: ComponentEditorView,
    controller: 'ComponentEditorController',
    controllerAs: 'vm',
    resolve: {
      loadScripts: loadMxClient
    },
    mode: 'create',
    isSubMenuItem: false,
    pageTitle: gettext('Component creator'),
    icon: 'important_devices',
    parentState: 'data-visualization',
    accessScopeFe: 'tis_fe:visualizations',
    accessScopeBe: 'tis_be:visualizations'
  });

export default VisualizationRouteManager.routes;
