import './sfe-header.scss';
import template from './sfe-header.component.html';
/**
 * @ngdoc component
 * @name common.SfeHeader
 * @description Component for creating headers on items. Mostly used in dialogs.
 * @param {Object} config - contains component configuration
 * @example
 * <sfe-header
 *   config="vm.obj.headerConfig"
 * ></sfe-header>
 */
export default {
  template,
  bindings: {
    config: '<',
    isLoading: '<'
  },
  controllerAs: 'vm',
  controller: sfeHeaderController
};

sfeHeaderController.$inject = ['$mdDialog'];

function sfeHeaderController($mdDialog) {
  const vm = this;
  vm.cancel = function() {
    $mdDialog.cancel();
  };
}
