VisualizationEditorController.$inject = [
  'ItemSelector',
  'TimeSeriesLastValueService',
  '$state',
  'AlertingService',
  'CrudToastFactory',
  'visualization',
  '$timeout',
  'MetadataService'
];

function VisualizationEditorController(
  ItemSelector,
  TimeSeriesLastValueService,
  $state,
  AlertingService,
  CrudToastFactory,
  visualization,
  $timeout,
  MetadataService
) {
  var vm = this;
  vm.loaded = true;

  vm.locationSelector = openLocationDialog;
  vm.showAlert = showAlert;
  vm.locationGetter = function() {};
  vm.measurementSelector = function() {
    return ItemSelector.open(['time-series']);
  };
  vm.measurementGetter = TimeSeriesLastValueService.get;
  vm.isDuplicate =
    $state.current.name === 'data-visualization-visualizations-duplicate';
  vm.visualizationId = $state.params.id;
  if (visualization != null) {
    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(
        `${vm.isDuplicate ? 'Duplicate' : 'Edit'}  ${visualization.name}`
      );
    });
  }
  function showAlert(err) {
    return AlertingService.Error(err);
  }
  function openLocationDialog() {
    return ItemSelector.open(['locations']);
  }

  vm.visualizationWasSaved = data => {
    var singleIdRedirect = {
      state: 'data-visualization-visualizations-viewer',
      paramName: 'id',
      paramValue: data._id
    };
    CrudToastFactory.toast('create', singleIdRedirect);
  };

  if ($state.current.name != 'data-visualization-editor') {
    vm.visualizationGetter = async () => visualization;
  }
}

export default VisualizationEditorController;
