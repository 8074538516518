module.exports = {
  entity: 'entities',
  path: 'entities',
  name: {
    en: 'Entities',
    sl_SI: 'Entities'
  },
  codelist: 'countries',
  scope: 'tis_be:entities',

  dialog: {
    title: {
      en: 'Select entitiy',
      sl_SI: 'Izberite entiteto'
    },
    listInfo: {
      en: 'Select entity from the list below',
      sl_SI: 'Izberite entiteto s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      }
    ],
    codelist: 'entities'
  }
}
