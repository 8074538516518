module.exports = {
  entity: 'asset-brands',
  path: 'asset-brands',
  name: {
    en: 'Asset Brands',
    sl_SI: 'Blagovne znamke sredstva'
  },
  networkModel: {
    entity: 'asset-brands',
    read: 'read'
  },
  scope: 'tis_be:asset-brands',

  dialog: {
    title: {
      en: 'Select asset brand',
      sl_SI: 'Izberite blagovno znamko sredstva'
    },
    listInfo: {
      en: 'Select asset brand from the list below',
      sl_SI: 'Izberite blagovno znamko sredstva s spodnjega seznama'
    }
  }
}
