/**
 * @ngdoc directive
 * @name common.ngEnter
 * @description run function assigned to directive ng-enter when the element containing the directive is focused
 * and the enter key is pressed
 * @example
 * <input
 *    ng-enter="vm.listFiltered()"
 * />
 */
export default function() {
  return function(scope, element, attrs) {
    element.bind('keydown keypress', function(event) {
      if (event.which === 13) {
        scope.$apply(function() {
          scope.$eval(attrs.ngEnter);
        });

        event.preventDefault();
        event.stopPropagation();
      }
    });
  };
}
