module.exports = {
  entity: 'time-series-types',
  path: 'time-series-types',
  scope: 'tis_be:time-series-types',
  entityId: 235,
  name: {
    en: 'Time series types',
    sl_SI: 'Tipi  časovne serije'
  },
  networkModel: {
    entity: 'time-series-types',
    read: 'read'
  },
  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select time series type',
      sl_SI: 'Izberite tip časovne serije'
    },
    listInfo: {
      en: 'Select time series type from the list below',
      sl_SI: 'Izberite tip časovne serije s spodnjega seznama'
    }
  }
}
