import indirectPermissionTreeItem from '../../components/sfe-entity-indirect-permission-tree/sfe-entity-indirect-permission-tree-item.template.html';
import moduleTreeTemplate from '../../components/sfe-user-modules/user-module-tree-item.template.html';

//userTemplateConstant
export default () => ({
  indirectPermissionTreeItem,
  moduleTreeTemplate
});

//constant for ng-include are
//- indirectPermissionTreeItemUser
//- moduleTreeTemplateUser
