import './sfe-form-2-flow-variables-time-series-card.scss';
import template from './sfe-form-2-flow-variables-time-series-card.component.html';

/**
* @ngdoc component
* @name common.sfeForm2FlowVariable
* @description Adds Timeseries and dynamic attributes variables .
* @param {Object} options configurations object
*     @param {Boolean} showDynamicAttributes indicates if dynamic attributes variable selector wil be shown
* @param {function} remove remove time series card function
* @param {function} getNextVariableName 
* @example
* <sfe-form-2-flow-variables-time-series-card
* options="vm.options"
  ng-change="vm.config.onChange()"
  remove="vm.remove()"
  ng-required="vm.config.required"
  ng-model="vm.value"
  name="{{vm.config.id}}"
  get-next-variable-name="vm.getNextVariableName"
  sfe-form-2-validation="vm.config.validators"  
* ></sfe-form-2-flow-variables-time-series-card>
**/

export default {
  template,
  bindings: {
    options: '<',
    onChange: '&',
    getSelectedItems: '<',
    getNextVariableName: '<',
    remove: '&'
  },
  require: {
    model: 'ngModel',
    form: '^form'
  },
  controller: sfeForm2FlowVariable,
  controllerAs: 'vm',
  bindToController: true
};

sfeForm2FlowVariable.$inject = [
  '$scope',
  'gettext',
  'TranslationService',
  'gettextCatalog',
  'AlertingService'
];

function sfeForm2FlowVariable(
  $scope,
  gettext,
  TranslationService,
  gettextCatalog,
  AlertingService
) {
  let vm = this;

  $scope.$on('$destroy', () => {
    vm = null;
    stopWatcher();
  });

  vm.timeSeriesTriggerInfo = gettextCatalog.getString(
    'When time series is a trigger, expression below will be evaluated when a new time series value arrives.'
  );

  /**
   * @description updates view on ts change.
   */

  vm.timeSeriesChanged = () => {
    if (
      vm.options != null &&
      vm.timeSeries != null &&
      vm.options.timeSeriesId === vm.timeSeries._id
    ) {
      vm.triggersCalculation = false;
    }
    vm.updateView();
    if (typeof vm.onChange == 'function') {
      vm.onChange();
    }
  };
  /**
   * @description removes time series variable parameter.
   * @function
   */
  vm.removeParam = index => {
    vm.timeSeriesParams.splice(index, 1);
    if (vm.timeSeriesParams.length === 0) {
      addTimeSeriesParams();
    }
    vm.updateView();
  };
  /**
   * @description updates view on ts change.
   */

  vm.updateView = () => {
    vm.model.$setViewValue({
      timeSeries: vm.timeSeries,
      timeSeriesParams: vm.timeSeriesParams,
      triggersCalculation: vm.triggersCalculation
    });
    if (typeof vm.onChange === 'function') {
      vm.onChange(vm.model.$modelValue);
    }
  };
  //remove time series sfe-action configuration
  vm.removeTimeSeries = [
    {
      icon: {
        type: 2,
        name: 'close',
        color: 'grey'
      },
      fn: () => {
        if (typeof vm.remove === 'function') {
          vm.remove();
        }
      }
    }
  ];

  /**
   * @description watches for model data to come and
   * if it is set to a string or a number triggers
   * function that fetches data.
   * @function
   */
  let stopWatcher = $scope.$watch(function() {
    if (vm.model != null) {
      return vm.model.$modelValue;
    }
  }, initiateItems);
  /**
   * @description updates scope when model changed.
   * @function
   * @param {Object} model
   */
  function initiateItems(model) {
    if (model != null) {
      vm.timeSeries = model.timeSeries;
      vm.timeSeriesParams = model.timeSeriesParams;
      vm.triggersCalculation = model.triggersCalculation;
    }
  }

  vm.timeSeriesAutocomplete = {
    title: gettextCatalog.getPlural(3, 'Time Series', 'Time Series', {}),
    options: {
      itemsCrawler: {
        entity: 'time-series',
        method: 'read'
      },
      valueField: 'id',
      crawlerParams: text => {
        let params = {
          order: '-name',
          populate: 'dataScheduler'
        };
        if (text != null && text != '') {
          return {
            ...params,
            filter: text
          };
        }
        return params;
      },
      /**
       * @description filters out selected time series.
       * @function
       * @param {Array} items time series items
       * @return {Array} filtered items
       */
      filter: items => {
        if (typeof vm.getSelectedItems == 'function') {
          const selected = vm.getSelectedItems();
          return items.filter(
            item => !selected.find(selectedId => item._id == selectedId)
          );
        }
        return items;
      },
      display: item => {
        let schedulerClassificationAbbreviation = '';
        if (item != null) {
          if (
            item.dataScheduler != null &&
            item.dataScheduler.scheduleClassification
          ) {
            let schedulerClassification = TranslationService.GetCollectionById(
              'codelists.scheduleClassifications',
              item.dataScheduler.scheduleClassification
            );
            if (
              schedulerClassification != null &&
              schedulerClassification.name != ''
            ) {
              schedulerClassificationAbbreviation = ` - ${schedulerClassification.name}`;
            }
          }
          return {
            text: `${item.name} ${schedulerClassificationAbbreviation}`
          };
        }
        return { text: gettextCatalog.getString('Unknown') };
      },
      dialog: {
        entity: 'time-series'
      }
    }
  };
  /**
   * @description adds new parameter to array of parameters.
   * @function
   */
  function addTimeSeriesParams() {
    if (typeof vm.getNextVariableName === 'function') {
      const nextVariableName = vm.getNextVariableName();
      if (!Array.isArray(vm.timeSeriesParams)) {
        vm.timeSeriesParams = [];
      }
      vm.timeSeriesParams.push({
        variableName: nextVariableName,
        paramObject: {}
      });

      vm.updateView();
    } else {
      AlertingService.Error('Cant get Variable configuration');
    }
  }
  //ADD TIME SERIES CARD SFE-ACTION CONFIG
  vm.addTimeseriesParamActions = [
    {
      title: gettext('Add variable'),
      fn: addTimeSeriesParams
    }
  ];
}
