NewOPCFormController.$inject = [
  'AlertingService',
  '$stateParams',
  'gettext',
  'MetadataService',
  'createOrUpdateService',
  'SfeHeader',
  'opc',
  '$timeout'
];

function NewOPCFormController(
  AlertingService,
  $stateParams,
  gettext,
  MetadataService,
  createOrUpdateService,
  SfeHeader,
  opc,
  $timeout
) {
  const vm = this;
  const opcId = $stateParams.id;
  const editMode = Boolean(opcId);

  vm.$onInit = function init() {
    vm.dataConfig = {
      action: {
        ctrlFn: true,
        title: editMode ? gettext('Update') : gettext('Create'),
        fn: createOrUpdate
      },
      data: getFields()
    };

    if (editMode) {
      MetadataService.Loading(true);
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit OPC'),
        'external-datasources-connectors-opc-view',
        $stateParams
      );

      vm.dataConfig.dataObj = opc;
      $timeout(() => {
        MetadataService.Loading(false);
        MetadataService.ChangeMetadata('Edit ' + opc.name, opc.description);
      });
    } else {
      MetadataService.Loading(false);
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New OPC'),
        'external-datasources-connectors-list'
      );
      vm.dataConfig.dataObj = {
        type: null
      };
    }
  };

  function getFields() {
    return [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textArea',
        maxlength: 500,
        type: 'text',
        required: false
      },
      {
        componentType: 'autocompleteDialog',
        edit: editMode,
        configuration: {
          codelist: 'opcTypes',
          floatingLabel: gettext('Choose opc type'),
          searchParamName: 'name',
          entity: 'opcTypes',
          required: true,
          dialogConfiguration: {
            multiple: false
          }
        },
        name: 'type'
      },
      {
        placeholder: gettext('URL'),
        name: 'url',
        componentType: 'textField',
        type: 'text'
      }
    ];
  }

  // create and update functions

  function createOrUpdate() {
    vm.sendingRequest = true;
    createOrUpdateService
      .simpleCall(
        {
          entity: 'opcs',
          method: editMode ? 'update' : 'create'
        },
        editMode,
        opcId,
        {
          name: vm.dataConfig.dataObj.name,
          description: vm.dataConfig.dataObj.description,
          url: vm.dataConfig.dataObj.url,
          useSecurity: vm.dataConfig.dataObj.useSecurity,
          type: vm.dataConfig.dataObj.type.id
        },
        'external-datasources-connectors-opc-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }
}

export default NewOPCFormController;
