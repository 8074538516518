NewCostCentreFormController.$inject = [
  '$stateParams',
  'AlertingService',
  'gettext',
  'CrudToastFactory',
  'MetadataService',
  'SfeHeader',
  'costCentre',
  '$timeout',
  'EntityTagService',
  'CostCentreModel'
];

function NewCostCentreFormController(
  $stateParams,
  AlertingService,
  gettext,
  CrudToastFactory,
  MetadataService,
  SfeHeader,
  costCentre,
  $timeout,
  EntityTagService,
  CostCentreModel
) {
  const vm = this;
  const costCentreId = $stateParams.id;
  const editMode = Boolean(costCentreId);
  let originalTags = [];

  vm.$onInit = function init() {
    vm.dataConfig = {
      action: {
        ctrlFn: true,
        title: editMode ? gettext('Update') : gettext('Create'),
        fn: createOrUpdate
      },
      data: getFields(),
      dataObj: {
        systemTags: []
      }
    };

    if (editMode) {
      MetadataService.Loading(true);
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit Cost Centre'),
        'company-resources-cost-centres-view',
        $stateParams
      );

      if (Array.isArray(costCentre.tags)) {
        let item;
        costCentre.systemTags = costCentre.tags.map(function(tag) {
          item = tag.systemTag;
          item.bindingId = tag._id; //tag service uses binding id to update/delete tag
          return item;
        });
      }
      originalTags = angular.copy(costCentre.systemTags);

      vm.dataConfig.dataObj = costCentre;

      $timeout(() => {
        MetadataService.Loading(false);
        MetadataService.ChangeMetadata(
          'Edit ' + costCentre.name,
          costCentre.description
        );
      });
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New Cost Centre'),
        'company-resources-cost-centres-list'
      );
    }
  };

  function getFields() {
    return [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textArea',
        maxlength: 500,
        type: 'text',
        required: false
      },
      {
        label: gettext('External'),
        name: 'isExternal',
        componentType: 'checkBox'
      },
      {
        placeholder: gettext('Code'),
        name: 'code',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('External Code'),
        name: 'externalCode',
        componentType: 'textField',
        type: 'text',
        required: false
      },
      {
        componentType: 'autocompleteDialog',
        edit: editMode,
        name: 'costCentreType',
        configuration: {
          filterFn: function(items) {
            return items.filter(item => item.allowedEntities.indexOf(4) >= 0);
          },
          codelist: 'costCentreTypes',
          entity: 'costCentreTypes',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Cost Centre Type')
          },
          floatingLabel: gettext('Cost Centre Type'),
          searchParamName: 'name',
          required: true,
          change: costCentreTypeChange
        }
      },
      {
        hide: true,
        showParam: 'showProject',
        componentType: 'autocompleteDialog',
        name: 'project',
        configuration: {
          entity: 'projects',
          nodialog: true,
          title: gettext('Project'),
          floatingLabel: gettext('Select Project'),
          query: {
            entity: 'projects',
            method: 'read'
          },
          searchParamName: 'filter',
          filterObject: {
            order: 'name'
          },
          required: false,
          createRedirect: {
            state: 'company-resources-projects-new'
          }
        }
      },
      {
        componentType: 'autocompleteDialog',
        name: 'companyStructureNode',
        configuration: {
          entity: 'company-structure-nodes',
          dialogConfiguration: {
            title: gettext('Select parent company structure node')
          },
          title: gettext('Parent company structure node'),
          floatingLabel: gettext('Select parent company structure node'),
          query: {
            entity: 'company-structure-nodes',
            method: 'read'
          },
          searchParamName: 'name',
          required: false,
          createRedirect: {
            state:
              'configurations-company-resources-company-structure-nodes-new'
          }
        }
      },
      {
        componentType: 'autocompleteDialog',
        name: 'profitCentre',
        configuration: {
          query: {
            entity: 'profit-centres',
            method: 'read'
          },
          floatingLabel: gettext('Select Profit Centre'),
          searchParamName: 'filter',
          entity: 'profit-centres',
          createRedirect: {
            state: 'company-resources-profit-centres-new'
          }
        }
      },
      {
        componentType: 'checkBoxesLinear',
        networkModel: {
          entity: 'system-tags',
          method: 'read'
        },
        name: 'systemTags',
        title: gettext('System Tags'),
        compareFn: function(item1, item2) {
          return item1._id === item2._id;
        }
      }
    ];
  }

  // Helper functions
  function costCentreTypeChange() {
    vm.dataConfig.dataObj.showProject =
      vm.dataConfig.dataObj.costCentreType &&
      vm.dataConfig.dataObj.costCentreType.id === 3;
  }

  // create and update functions
  async function createOrUpdate() {
    vm.sendingRequest = true;
    const apiObject = dataToSave();

    //CREATE/UPDATE COST CENTER
    let entityId;
    try {
      if (editMode) {
        const { data } = await CostCentreModel.update(
          { id: costCentreId },
          apiObject
        );
        entityId = data._id;
      } else {
        const { data } = await CostCentreModel.create(apiObject);
        entityId = data._id;
      }

      if (entityId != null) {
        await EntityTagService.createSystemTags(
          vm.dataConfig.dataObj.systemTags,
          originalTags,
          4, //cost centre entity id
          entityId,
          false
        );
      }

      const singleIdRedirect = {
        state: 'company-resources-cost-centres-view',
        paramName: 'id',
        paramValue: entityId
      };
      const message = editMode ? 'update' : 'create';
      CrudToastFactory.toast(message, singleIdRedirect);
    } catch (err) {
      AlertingService.Error(err);
      vm.sendingRequest = false;
    }
  }

  function dataToSave() {
    return {
      name: vm.dataConfig.dataObj.name,

      description: vm.dataConfig.dataObj.description,
      code: vm.dataConfig.dataObj.code,
      hasChild: vm.dataConfig.dataObj.hasChild,
      isExternal: vm.dataConfig.dataObj.isExternal,
      isEnabled: vm.dataConfig.dataObj.isEnabled,
      externalCode: vm.dataConfig.dataObj.externalCode,
      costCentreType: vm.dataConfig.dataObj.costCentreType.id,
      companyStructureNode: vm.dataConfig.dataObj.companyStructureNode
        ? vm.dataConfig.dataObj.companyStructureNode._id
        : null,
      project:
        vm.dataConfig.dataObj.project &&
        vm.dataConfig.dataObj.costCentreType.id === 3
          ? vm.dataConfig.dataObj.project._id
          : null,
      profitCentre: vm.dataConfig.dataObj.profitCentre
        ? vm.dataConfig.dataObj.profitCentre._id
        : null
    };
  }
}

export default NewCostCentreFormController;
