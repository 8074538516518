energySourceTypeConfiguration.$inject = ['gettext', '$q'];

function energySourceTypeConfiguration(gettext, $q) {
  var configuration = {
    entity: {
      singular: gettext('Energy Source Type'),
      plural: gettext('Energy Source Types')
    },
    api: {
      query: {
        entity: 'energy-source-types',
        method: 'custom.readItemView'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'configurations-energy-management-energy-source-types-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    treeView: {
      entity: 'energy-source-types'
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'configurations-energy-management-energy-source-types-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'configurations-energy-management-energy-source-types-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'energy-source-types',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description'),
        type: 'raw'
      },
      {
        param: 'code',
        title: gettext('Code'),
        type: 'code'
      },
      {
        param: 'externalCode',
        title: gettext('External code'),
        type: 'code'
      },
      {
        type: 'link',
        param: 'parentName',
        title: gettext('Parent'),
        link: {
          state: 'configurations-energy-management-energy-source-types-view',
          params: [
            {
              valueParam: 'parentId',
              queryParam: 'id'
            }
          ]
        }
      }
    ],
    badges: [
      {
        type: 'icon',
        param: 'icon',
        title: gettext('Icon')
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Code'),
        param: 'code',
        type: 'string'
      },
      {
        display: gettext('External Code'),
        param: 'externalCode',
        type: 'string'
      },
      {
        param: 'parentId',
        query: {
          entity: 'energy-source-types',
          method: 'read'
        },
        entity: 'energy-source-types',
        display: gettext('Parent'),
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id',
        refreshObject: {
          order: 'code'
        }
      }
    ],
    sort: [
      {
        display: 'Code ASC',
        params: 'code'
      },
      {
        display: 'Code DESC',
        params: '-code'
      },
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'name DESC',
        params: '-name'
      },
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    item.parentName = gettext('None');
    if (item.parentId) {
      item.parentName = item.parent.name;
    }
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default energySourceTypeConfiguration;
