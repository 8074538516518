module.exports = {
  entity: 'currencies',
  path: 'currencies',
  entityId: 77,
  name: {
    en: 'Currency',
    sl_SI: 'Valuta'
  },
  codelist: 'currencies',
  scope: 'tis_be:currencies',
  dialog: {
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      },
      {
        field1: 'symbol',
        title: 'Symbol',
        type: 'string'
      }
    ],
    title: {
      en: 'Select currency',
      sl_SI: 'Izberite valuto'
    },
    listInfo: {
      en: 'Select currency from the list below',
      sl_SI: 'Izberite valuto s spodnjega seznama'
    },
    codelist: 'currencies'
  }
}
