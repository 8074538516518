import { DateTime } from 'luxon';

periodIntervalConstructor.$inject = [];
/**
 * @ngdoc service
 * @name analytics.periodIntervalConstructor
 * @description according to period interval object returns sfeForm form object.
 * @property {function} getFormObj
 */
function periodIntervalConstructor() {
  /**
   * @description sets sfeForm object from/to dates when periodInterval type is dynamic.
   * @function
   * @param {object} periodInterval periodInterval object recieved from api
   * @return {object}
   */
  function constructPeriodIntervalDates(periodInterval) {
    var isMonth = periodInterval.periodType === 'month';
    var luxonNowDate = DateTime.fromJSDate(new Date());
    var subtractObject = {};
    subtractObject[periodInterval.periodType] = periodInterval.value;
    var luxonStartDate = luxonNowDate.minus(subtractObject);

    var includeFirst = false;
    var includeLast = false;
    var dateObj = {};
    switch (periodInterval.inclusionType) {
    case 'opened':
      includeFirst = true;
      includeLast = true;
      break;
    case 'closed':
      break;
    case 'closed_right':
      includeFirst = true;
      break;
    case 'closed_left':
      includeLast = true;
      break;
    }

    if (includeFirst) {
      if (isMonth) {
        luxonStartDate = luxonStartDate.startOf('month');
      } else {
        luxonStartDate = luxonStartDate.startOf('year');
      }
    } else {
      luxonStartDate = luxonStartDate.startOf('day');
    }

    if (includeLast) {
      if (isMonth) {
        luxonNowDate = luxonNowDate.endOf('month');
      } else {
        luxonNowDate = luxonNowDate.endOf('year');
      }
    } else {
      luxonNowDate = luxonNowDate.endOf('day');
    }
    dateObj.startDate = luxonStartDate.toJSDate();
    dateObj.endDate = luxonNowDate.toJSDate();
    if (periodInterval.periodType === 'year') {
      dateObj.startYear = luxonStartDate.year;
      dateObj.endYear = luxonNowDate.year;
      dateObj.showYearPicker = true;
      dateObj.showDatePicker = false;
      dateObj.maxYear = luxonNowDate.year + 2;
    } else {
      dateObj.showYearPicker = false;
      dateObj.showDatePicker = true;
    }
    return dateObj;
  }
  /**
   * @description sets sfeForm object from/to dates
   * @function
   * @param {object} periodInterval periodInterval object received from api
   * @return {object}
   */
  function getFormObj(periodInterval) {
    var dateObj = {};
    if (periodInterval) {
      if (periodInterval.periodType) {
        var dates = constructPeriodIntervalDates(periodInterval);
        Object.assign(dateObj, dates);
      } else {
        dateObj = {
          startDate: new Date(periodInterval.startDate),
          endDate: new Date(periodInterval.endDate),
          showYearPicker: false,
          showDatePicker: true
        };
      }
    } else {
      dateObj = {
        showDatePicker: true
      };
    }
    return dateObj;
  }

  return {
    getFormObj: getFormObj
  };
}

export default periodIntervalConstructor;
