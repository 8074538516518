import './sfe-form-2-chart-settings.scss';
import template from './sfe-form-2-chart-settings.component.html';

/**
 * @ngdoc component
 * @name moduleName.componentName
 * @description component used for selecting timseries for the creation of timeseries groups
 * @param {string} chartSettingsTitle - title of the component shown in component view
 * @param {Object} options - component options
 *    @param {string} groupId - id of the timeseries group
 *    @param {boolean} yellowBackground- tells us if the component should use a yellow background
 * @param {Function} disable - function that returns boolean
 * @example
 * <sfe-form-2-chart-settings
 *  options="vm.config.type.options"
 *  chart-settings-title="vm.config.title"
 *  ng-model="vm.value"
 *  ng-required="vm.config.required"
 *  disable="vm.config.disable"
 * ></sfe-form-2-chart-settings>
 */
export default {
  template,
  bindings: {
    chartSettingsTitle: '<',
    options: '<',
    disable: '<'
  },
  require: {
    form: '^form',
    model: 'ngModel'
  },
  controller: SfeForm2ChartSettingsController,
  controllerAs: 'vm',
  bindToController: true
};

SfeForm2ChartSettingsController.$inject = [
  'ColorService',
  'TranslationService',
  'SfeForm2Dialog',
  '$scope',
  'SfeForm2ChartSettingsHelper',
  'TimeSeriesWizardDuplicateConfiguration',
  'gettextCatalog',
  '$mdDialog'
];
function SfeForm2ChartSettingsController(
  ColorService,
  TranslationService,
  SfeForm2Dialog,
  $scope,
  SfeForm2ChartSettingsHelper,
  TimeSeriesWizardDuplicateConfiguration,
  gettextCatalog,
  $mdDialog
) {
  const vm = this;

  vm.noItemsAddedTitle = gettextCatalog.getString(
    'Drag a time series into this field or add it manually to begin with arranging'
  );

  vm.changeSchedulerClassifications = changeSchedulerClassifications;
  vm.createTimeseriesFromExistingTimeseries = createTimeseriesFromExistingTimeseries;
  vm.updateModel = updateModel;
  const chartTypesOptions = TranslationService.GetCollection(
    'codelists.chartTypes'
  );
  vm.icons = {
    chart: {
      type: 3,
      name: 'fa-signal',
      color: ColorService.getApplicationColor('warn')
    },
    transferTimeSeries: {
      type: 2,
      name: 'swap_horiz'
    },
    createNewTimeseries: {
      name: 'filter_none'
    },
    remove: {
      name: 'close',
      type: 2
    },
    drag: {
      name: 'drag_indicator',
      type: 2
    }
  };

  $scope.$on('$destroy', () => {
    stopWatcher();
  });
  /**
   * @description watches for model data to come and
   * enriches it if neccessary
   * @function
   */
  let stopWatcher = $scope.$watch(
    function() {
      if (vm.model != null) {
        return vm.model.$modelValue;
      }
    },
    async model => {
      const data = await SfeForm2ChartSettingsHelper.enrichTimeseries(model);
      vm.modelValue = data.map(item => {
        const chartTypes = chartTypesOptions.filter(chartType => {
          if (
            Array.isArray(
              chartType.supportedTimeSeriesDataInterpretationTypes
            ) &&
            item.dataInterpretationType != null
          ) {
            return (
              chartType.supportedTimeSeriesDataInterpretationTypes.indexOf(
                item.dataInterpretationType
              ) > -1
            );
          }
          return false;
        });
        return {
          ...item,
          chartTypes
        };
      });
      $scope.$applyAsync();
    }
  );
  vm.$onChanges = changes => {
    if (!Array.isArray(vm.modelValue)) {
      vm.modelValue = [];
    }
    if (changes.options && vm.options != null) {
      const groupId =
        vm.options != null && vm.options.groupId != null
          ? vm.options.groupId
          : 'demoGroupId';
      vm.sortConfig = {
        handle: '.my-handle',
        group: {
          name: groupId,
          pull: true,
          put: true
        },
        animation: 100,
        models: [],
        onAdd,
        onUpdate
      };
    }
  };

  vm.displayTitle = item => {
    let schedulerClassificationAbbreviation = '';
    if (item != null) {
      if (
        item.dataScheduler != null &&
        item.dataScheduler.scheduleClassification
      ) {
        let schedulerClassification = TranslationService.GetCollectionById(
          'codelists.scheduleClassifications',
          item.dataScheduler.scheduleClassification
        );
        if (
          schedulerClassification != null &&
          schedulerClassification.name != ''
        ) {
          schedulerClassificationAbbreviation = ` - ${schedulerClassification.name}`;
        }
      }
      return {
        text: `${item.name} ${schedulerClassificationAbbreviation}`
      };
    }
    return { text: gettextCatalog.getString('Unknown') };
  };
  /**
   * @description function that is called when an item is dragged into the component.
   * @function
   * @param {Object} event - values and information of the element dragged in.
   * @return {dataType}
   */
  function onAdd(event) {
    const { model } = event;

    if (model != null) {
      if (model.color == null) {
        model.color = ColorService.RandomColor();
      }

      if (model.chartType == null) {
        if (model.dataInterpretationType != null) {
          const interpretationType = TranslationService.GetCollectionById(
            'codelists.dataInterpretationTypes',
            model.dataInterpretationType
          );
          if (interpretationType != null) {
            model.chartType = interpretationType.defaultChartType;
          }
        }
      }
    }
    if (model != null) {
      updateModel();
    }
  }
  /**
   * @description function that is called when we change sorting within list.
   * @function
   */
  function onUpdate() {
    updateModel();
  }
  /**
   * @description function called when we want to update ng-model.
   * @function
   */
  function updateModel() {
    vm.model.$setViewValue([...vm.modelValue]);
  }
  vm.remove = index => {
    vm.modelValue.splice(index, 1);
    updateModel();
  };
  /**
   * @description opens the dialog for changing scheduler classifications
   * @function
   * @param {string} timeseriesName - names of the time series we want to change the scheduler classifications for
   * @return {dataType}
   */
  async function changeSchedulerClassifications(timeseries) {
    const scheduleClassifications = TranslationService.GetCollection(
      'codelists.scheduleClassifications'
    );

    const config = SfeForm2ChartSettingsHelper.getSchedulerClassificationsConfiguration(
      scheduleClassifications,
      timeseries,
      vm.options.getClassificationModel,
      vm.options.moveCallback
    );
    try {
      // eslint-disable-next-line no-unused-vars
      const selectedValues = await SfeForm2Dialog.open(config);
    } catch (err) {
      return [];
    }
  }
  /**
   * @description opens a dialog for creating new time series from existing time series.
   * @function
   */
  async function createTimeseriesFromExistingTimeseries(item) {
    let entityId;
    if (item != null) {
      entityId = item._id;
    }
    const onSaveAction = timeSeries => {
      $mdDialog.hide();
      timeSeries.color = ColorService.RandomColor();
      timeSeries.chartType = 1;
      vm.modelValue.push(timeSeries);
      updateModel();
    };
    let config = TimeSeriesWizardDuplicateConfiguration.get(
      entityId,
      onSaveAction
    );
    SfeForm2Dialog.open(config);
  }
}
