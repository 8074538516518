import './sfe-value.scss';
import template from './sfe-value.component.html';
/**
 * @ngdoc component
 * @name common.sfeValue
 * @description Defining graph properties.
 * @param {Object} value - Object containing value, time & error (noValue, invalid).
 * @param {string} valueType - Type of value: Number/String.
 * @param {string} unit - Unit type.
 * @param {Array} boundaries - Array of objects containing from & to values, if value is between set custom color.
 * @param {Object} minMax - Contains minValue & maxValue.
 * @param {number/string} rawValue 
 * @param {boolean} lightBackground - light or dark background
 * @param {string} color - gauge color
 * @example
 * <sfe-value
  value="value.value"
  value-type="value.valueType"
  unit="value.unit"
  boundaries="value.boundaries"
  min-max="value.minMax"
  raw-value="value.rawValue"
  light-background="true"
  color="color"
 * ></sfe-value>
 */
export default {
  restrict: 'E',
  template,
  bindings: {
    value: '<',
    valueType: '<',
    unit: '<',
    boundaries: '<',
    minMax: '<',
    rawValue: '<',
    lightBackground: '<',
    color: '<'
  },
  controller: sfeValueController,
  controllerAs: 'vm',
  bindToController: true
};

sfeValueController.$inject = [];

function sfeValueController() {
  const vm = this;
  vm.gaugeColumns = new Array(15).fill({
    colored: false
  });
  vm.displayTime;

  // on every change re-render gauge
  vm.$onChanges = () => {
    if (vm.value == null) {
      vm.errorNoValue = true;
    } else {
      vm.errorNoValue = false;
      vm.errorInvalidData = false;
      if (vm.value.error) {
        vm.errorNoValue = vm.value.error.noValue;
        vm.errorInvalidData =
          vm.value.error.invalid ||
          validateInput(vm.valueType, vm.value.value, vm.rawValue) == false;
      }
      vm.nonNumerical = vm.valueType != 'number';
      vm.invalidUnit = typeof vm.unit != 'string';
      vm.invalidTime = typeof vm.value.time != 'string';
      if (!vm.nonNumerical) {
        vm.gaugeColor = getGaugeColor(
          vm.rawValue,
          vm.boundaries,
          vm.minMax,
          vm.lightBackground
        );
      }
      vm.gaugeColumns = assignColorsToGaugeColumns(
        vm.gaugeColumns,
        vm.rawValue,
        vm.minMax
      );
    }
  };

  /**
   * @description Assigns whether a column in the gauge is colored or not depending on min/max and value.
   * @function
   * @param {array} columns
   * @param {number} value
   * @param {object} minMax
   * @return {object}
   */
  const assignColorsToGaugeColumns = (columns, value, minMax) => {
    const numOfColumns = 15;
    return columns.map((column, index) => {
      let colored = false;
      if (index == 0) {
        colored = true;
      } else {
        if (minMax == null && index < 8) {
          colored = true;
        } else if (minMax != null) {
          const columnMinMaxFraction = (minMax.max - minMax.min) / numOfColumns;
          colored = columnMinMaxFraction * index + minMax.min < value;
        }
      }
      return { colored };
    });
  };

  /**
   * @description Returns color of the gauge based on input.
   * @function
   * @param {number} rawValue
   * @param {array} boundaries
   * @param {object} minMax
   * @return {object}
   */
  const getGaugeColor = (rawValue, boundaries, minMax, lightBackground) => {
    let warning = false;
    let color = null;

    if (minMax != null && (rawValue <= minMax.min || rawValue >= minMax.max)) {
      // check minMax
      warning = true;
    } else {
      // get gauge color
      color = getColor(rawValue, boundaries, lightBackground);
    }

    return {
      warning,
      ...color
    };
  };

  /**
   * @description Check boundaries and return a color if a value fits into a boundary else set to chart default color.
   * @function
   * @param {number} value
   * @param {boundaries} array
   * @return {string|null}
   */
  const getColor = (value, boundaries, lightBackground) => {
    let backgroundColor = null;
    let borderColor = null;
    const mixFactor = {
      background: lightBackground ? 0.76 : 0.76,
      border: lightBackground ? 0.32 : 0.44
    };
    if (Array.isArray(boundaries) && boundaries.length > 0) {
      let foundBoundary = boundaries.find(boundary => {
        return boundary.from <= value && boundary.to >= value;
      });
      if (
        foundBoundary &&
        foundBoundary.color &&
        colorIsValidHex(foundBoundary.color)
      ) {
        backgroundColor = colorMixer(
          [255, 255, 255],
          hexToArray(foundBoundary.color),
          mixFactor.background
        );
        borderColor = colorMixer(
          [255, 255, 255],
          hexToArray(foundBoundary.color),
          mixFactor.border
        );
      }
    }
    if (
      backgroundColor == null &&
      borderColor == null &&
      colorIsValidHex(vm.color)
    ) {
      backgroundColor = colorMixer(
        [255, 255, 255],
        hexToArray(vm.color),
        mixFactor.background
      );
      borderColor = colorMixer(
        [255, 255, 255],
        hexToArray(vm.color),
        mixFactor.border
      );
    }
    return { backgroundColor, borderColor };
  };

  /**
   * @description Validates inputs and returns whether they are valid or not.
   * @function
   * @param {string} valueType // either string or number
   * @param {string} value
   * @param {number} rawValue
   * @return {dataType}
   */
  const validateInput = (valueType, value, rawValue) => {
    let validity = false;
    switch (valueType) {
    case 'String':
      if (typeof value == 'string') {
        validity = true;
      }
      break;
    case 'Number':
      if (typeof value == 'string' && typeof rawValue == 'number') {
        validity = true;
      }
      break;
    }
    return validity;
  };

  /**
   * @description transform hex string to an array
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  const hexToArray = hex => {
    let result = [hexToRgb(hex).r, hexToRgb(hex).g, hexToRgb(hex).b];
    return result;
  };

  /**
   * @description Returns true or false if a hex is a valid hex string.
   * @function
   * @param {string} hex
   * @return {dataType}
   */
  const colorIsValidHex = hex => {
    return (
      hex != null && typeof hex == 'string' && hex.match(/^#[0-9A-F]{6}$/i)
    );
  };

  /**
   * @description splits hex into channels
   * @function
   * @param {string} hex
   * @return {object}
   */
  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
      : null;
  }

  /**
   * @description Mixes two color channels.
   * @function
   * @param {number} colorChannelA
   * @param {number} colorChannelB
   * @param {number} amountToMix
   * @return {number}
   */
  const colorChannelMixer = (colorChannelA, colorChannelB, amountToMix) => {
    var channelA = colorChannelA * amountToMix;
    var channelB = colorChannelB * (1 - amountToMix);
    return parseInt(channelA + channelB);
  };

  /**
   * @description Mixes two colors together, rgbA and rgbB are arrays, amountToMix ranges from 0.0 to 1.0
   * @function
   * @param {array} rgbA // example (red): rgbA = [255,0,0]
   * @param {array} rgbB // example (red): rgbB = [255,0,0]
   * @return {string}
   */
  const colorMixer = (rgbA, rgbB, amountToMix) => {
    var r = colorChannelMixer(rgbA[0], rgbB[0], amountToMix);
    var g = colorChannelMixer(rgbA[1], rgbB[1], amountToMix);
    var b = colorChannelMixer(rgbA[2], rgbB[2], amountToMix);
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  };
}
