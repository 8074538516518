module.exports = {
  entity: 'billing-types',
  path: 'billing-types',
  entityId: 58,
  name: {
    en: 'Billing types',
    sl_SI: 'Tipi obračunov'
  },
  networkModel: {
    entity: 'billing-types',
    read: 'read'
  },
  scope: 'tis_be:billing-types',

  dialog: {
    title: {
      en: 'Select billing type',
      sl_SI: 'Izberite tip obračuna'
    },
    listInfo: {
      en: 'Select billing type from the list below',
      sl_SI: 'Izberite tip obračuna s spodnjega seznama'
    }
  }
}
