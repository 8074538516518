/**
 * @ngdoc factory
 * @name common.PhysicalCollectionService
 * @description Used for returning different kinds of physical collections
 * @property {function} getReadablePhysicalData - See method getReadablePhysicalData
 * @property {function} returnEverything - See method returnEverything
 * @property {function} returnPhysicalQuantities - See method returnPhysicalQuantities
 * @property {function} returnMeasurementUnits - See method returnMeasurementUnits
 * @property {function} returnMetricPrefixes - See method returnMetricPrefixes
 */

PhysicalCollectionService.$inject = [
  '$q',
  'TranslationService',
  '$rootScope',
  'AlertingService',
  'MeasurementUnitModel',
  'PhysicalQuantityModel',
  'gettextCatalog'
];

/**
 * @ngdoc service
 * @name common.PhysicalCollectionService
 * @description Physical metadata service.
 */
export default function PhysicalCollectionService(
  $q,
  TranslationService,
  $rootScope,
  AlertingService,
  MeasurementUnitModel,
  PhysicalQuantityModel,
  gettextCatalog
) {
  var physicalQuantities;
  var measurementUnits;
  var metricPrefixes;
  var voidPhysicalMetadata = {
    _id: 0,
    name: 'Missing',
    symbol: ''
  };

  $rootScope.$on('retranslate', function() {
    physicalQuantities = undefined;
    measurementUnits = undefined;
    metricPrefixes = undefined;
  });
  /**
   * @memberof PhysicalCollectionService.createOrUpdateItem
   * @description creates/updates new item and pushes it to an existing collection.
   * @param {string} id item id
   * @param {Object} bodyObject updated object
   * @param {string} type type to update
   * @return {Promise}
   */
  async function createOrUpdateItem(id, bodyObject, type) {
    let model;
    let arrayToUpdate;
    switch (type) {
    case 'measurement-units':
      model = MeasurementUnitModel;
      arrayToUpdate = measurementUnits;
      break;
    case 'physical-quantities':
      model = PhysicalQuantityModel;
      arrayToUpdate = physicalQuantities;
      break;
    }
    const method = id ? model.update : model.create;
    const queryObject = id ? { id: id } : {};
    try {
      const { data: newItem } = await method(queryObject, bodyObject);
      if (arrayToUpdate) {
        if (id) {
          const index = arrayToUpdate.findIndex(item => item._id === id);
          if (index != undefined) {
            arrayToUpdate[index] = newItem;
          }
        } else {
          arrayToUpdate.push(newItem);
        }
      }
      return newItem;
    } catch (err) {
      throw err;
    }
  }
  /**
   * @description Recursively fetches all items.
   * @function
   * @param {Object} model entity model
   * @param {Object} apiObject filter parameters object
   * @param {Object} deferred deferred constructor
   * @param {Array} result array of previously fetched items
   * @return {Promise}
   */
  function fetchAllItems(model, apiObject, deferred, result) {
    if (!deferred) {
      deferred = $q.defer();
    }
    model.read(apiObject).then(
      function(res) {
        result = result.concat(res.data);
        if (res.pagination) {
          if (
            res.pagination.total >
            res.pagination['per_page'] * res.pagination.page
          ) {
            apiObject.page = res.pagination.page + 1;
            apiObject.limit = res.pagination['per_page'];
            fetchAllItems(model, apiObject, deferred, result);
          } else {
            deferred.resolve(result);
          }
        } else {
          deferred.resolve(result);
        }
      },
      function(err) {
        deferred.reject(err);
      }
    );
    return deferred.promise;
  }

  /**
   * @function
   * @description Fetches all Physical Quantities.
   * @return {promise}
   */
  function getPhysicalQuantities() {
    var pr = $q.defer();
    if (!physicalQuantities) {
      fetchAllItems(PhysicalQuantityModel, { limit: 200 }, null, []).then(
        function(res) {
          physicalQuantities = res;
          pr.resolve();
        },
        function(err) {
          AlertingService.Error(err);
          pr.reject(err);
        }
      );
    } else {
      pr.resolve();
    }
    return pr.promise;
  }

  /**
   * @function
   * @description Fetches all Measurement Units.
   * @return {promise}
   */
  function getMeasurementUnits() {
    var pr = $q.defer();
    if (!measurementUnits) {
      fetchAllItems(MeasurementUnitModel, { limit: 200 }, null, []).then(
        function(res) {
          measurementUnits = res;
          pr.resolve();
        },
        function(err) {
          AlertingService.Error(err);
          pr.reject();
        }
      );
    } else {
      pr.resolve();
    }
    return pr.promise;
  }

  /**
   * @function
   * @description Fetches all Metric Prefixes.
   * @return {promise}
   */
  function getMetricPrefixes() {
    var pr = $q.defer();
    if (!metricPrefixes) {
      metricPrefixes = TranslationService.GetCollection(
        'codelists.metricPrefixes'
      );
      pr.resolve();
    } else {
      pr.resolve();
    }
    return pr.promise;
  }

  /**
   * @function
   * @description Returns all readable metadata info on metricPrefix and measurement unit.
   * @return {promise}
   */
  function constructReadableMeasurementUnit(metricPrefix, measurementUnit) {
    const readable = [];
    let readableMeasurementUnit;
    let readableMeasurementUnitName;
    let readableMeasurementUnitSymbol;

    let metricPrefixName = metricPrefix.symbol ? metricPrefix.name : '';
    let measurementUnitName = measurementUnit.symbol
      ? measurementUnit.name
      : '';

    let readableMeasurementUnitItemView =
      measurementUnit.symbol || metricPrefix.symbol
        ? `${metricPrefixName} ${measurementUnitName} (${metricPrefix.symbol}${measurementUnit.symbol})`
        : gettextCatalog.getString('None');

    if (
      metricPrefix.name !== 'undefined' &&
      metricPrefix.name !== 'None' &&
      metricPrefix.name !== 'Missing'
    ) {
      if (
        measurementUnit.name !== 'undefined' &&
        measurementUnit.name !== 'None'
      ) {
        readableMeasurementUnit = `${metricPrefix.name} ${measurementUnit.name} (${metricPrefix.symbol}${measurementUnit.symbol})`;
        readableMeasurementUnitName = `${metricPrefix.name} ${measurementUnit.name}`;
        readableMeasurementUnitSymbol = `${metricPrefix.symbol} ${measurementUnit.symbol}`;
      } else {
        readableMeasurementUnit = `${metricPrefix.name} (${metricPrefix.symbol})`;
        readableMeasurementUnitName = metricPrefix.name;
        readableMeasurementUnitSymbol = metricPrefix.symbol;
      }
    } else {
      if (
        measurementUnit.name !== 'undefined' &&
        measurementUnit.name !== 'None' &&
        measurementUnit.name !== 'Missing'
      ) {
        readableMeasurementUnit = `${measurementUnit.name} (${measurementUnit.symbol})`;
        readableMeasurementUnitName = measurementUnit.name;
        readableMeasurementUnitSymbol = measurementUnit.symbol;
      } else {
        readableMeasurementUnit = 'None';
        readableMeasurementUnitName = 'None';
        readableMeasurementUnitSymbol = '';
      }
    }

    return {
      ...readable,
      readableMeasurementUnit,
      readableMeasurementUnitName,
      readableMeasurementUnitSymbol,
      readableMeasurementUnitItemView
    };
  }

  /**
   * @memberof common.PhysicalCollectionService
   * @function
   * @description returns simple metadata - name of quantity and unit
   * @param {ObjectId} physicalQuantity
   * @param {ObjectId} metricPrefix
   * @param {CodelistId} measurementUnit
   * @return {promise} returns object with name and combined unit
   */
  function physicalMetadata(physicalQuantity, metricPrefix, measurementUnit) {
    var pr = $q.defer();
    var promises = [
      getPhysicalQuantities(),
      getMeasurementUnits(),
      getMetricPrefixes()
    ];
    $q.all(promises).then(
      function() {
        var physicalQuantityFull =
          physicalQuantities.find(function(physicalMetadata) {
            return physicalMetadata._id === physicalQuantity;
          }) || Object.assign({}, voidPhysicalMetadata);

        var metricPrefixFull =
          TranslationService.GetCollection('codelists.metricPrefixes').find(
            function(physicalMetadata) {
              return physicalMetadata.id === metricPrefix;
            }
          ) || Object.assign({}, voidPhysicalMetadata);

        var measurementUnitFull =
          measurementUnits.find(function(physicalMetadata) {
            return physicalMetadata._id === measurementUnit;
          }) || Object.assign({}, voidPhysicalMetadata);

        pr.resolve({
          name: physicalQuantityFull.name,
          unit:
            metricPrefixFull.symbol +
            (metricPrefixFull.symbol && measurementUnitFull.symbol ? ' ' : '') +
            measurementUnitFull.symbol
        });
      },
      function(err) {
        pr.reject(err);
      }
    );
    return pr.promise;
  }

  /**
   * @memberof common.PhysicalCollectionService
   * @function
   * @description returns simple metadata - name of quantity and unit
   * @param {object} obj Should contain keys for physicalQuantity, metricPrefix, measurementUnit
   * @return {promise} returns object with physicalQuantity, metricPrefix and measurementUnit extended info as well as readable metric prefix plus measurement unit
   */
  function getReadablePhysicalData(obj) {
    var pr = $q.defer();
    var promises = [
      getPhysicalQuantities(),
      getMeasurementUnits(),
      getMetricPrefixes()
    ];
    $q.all(promises).then(
      function() {
        var result = {};
        if (obj.physicalQuantity) {
          var search = _.find(physicalQuantities, {
            _id: obj.physicalQuantity
          });
          if (typeof search === 'undefined') {
            result.physicalQuantity = {
              _id: 0,
              name: '',
              symbol: ''
            };
          } else {
            result.physicalQuantity = search;
          }
        }
        if (obj.metricPrefix) {
          var search2 = _.find(
            TranslationService.GetCollection('codelists.metricPrefixes'),
            { id: obj.metricPrefix }
          );
          if (typeof search2 === 'undefined') {
            search2 = { ...voidPhysicalMetadata };
          }
          result.metricPrefix = search2;
        }
        if (obj.measurementUnit) {
          var search3 = _.find(measurementUnits, {
            _id: obj.measurementUnit
          });
          if (typeof search3 === 'undefined') {
            result.measurementUnit = { ...voidPhysicalMetadata };
          } else {
            result.measurementUnit = search3;
          }
        }
        if (result.measurementUnit && result.metricPrefix) {
          var readable = constructReadableMeasurementUnit(
            result.metricPrefix,
            result.measurementUnit
          );
        }
        pr.resolve({
          ...result,
          ...readable
        });
      },
      function(err) {
        pr.reject(err);
      }
    );
    return pr.promise;
  }

  /**
   * @memberof common.PhysicalCollectionService
   * @function
   * @description returns simple metadata - name of quantity and unit
   * @param {object} obj Should contain keys for physicalQuantities, metricPrefixes, measurementUnits
   * @return {promise} returns object with physicalQuantity, metricPrefix and measurementUnit extended info as well as readable metric prefix plus measurement unit
   */
  function returnEverything(obj) {
    var pr = $q.defer();
    var promises = [getPhysicalQuantities(), getMeasurementUnits()];
    $q.all(promises).then(
      function() {
        var results = {};
        if (obj.physicalQuantities) {
          results.physicalQuantities = physicalQuantities;
        }
        if (obj.measurementUnits) {
          results.measurementUnits = measurementUnits;
        }
        results.metricPrefixes = TranslationService.GetCollection(
          'codelists.metricPrefixes'
        );
        pr.resolve(results);
      },
      function(err) {
        pr.reject(err);
      }
    );
    return pr.promise;
  }

  /**
   * @memberof common.PhysicalCollectionService
   * @function
   * @description Returns all Physical Quantities.
   * @return {promise}
   */
  function returnPhysicalQuantities(physicalQuantity) {
    var pr = $q.defer();
    if (physicalQuantities) {
      if (physicalQuantity) {
        pr.resolve(
          physicalQuantities.find(function(item) {
            return item._id === physicalQuantity;
          })
        );
      } else {
        pr.resolve(physicalQuantities);
      }
    } else {
      getPhysicalQuantities().then(
        function() {
          if (physicalQuantity) {
            pr.resolve(
              physicalQuantities.find(function(item) {
                return item._id === physicalQuantity;
              })
            );
          } else {
            pr.resolve(physicalQuantities);
          }
        },
        function(err) {
          pr.reject(err);
        }
      );
    }
    return pr.promise;
  }

  /**
   * @memberof common.PhysicalCollectionService
   * @function
   * @description Returns all Measurement Units.
   * @return {promise}
   */
  function returnMeasurementUnits(unit) {
    var pr = $q.defer();
    if (measurementUnits) {
      if (unit) {
        pr.resolve(
          measurementUnits.find(function(item) {
            return item._id === unit;
          })
        );
      } else {
        pr.resolve(measurementUnits);
      }
    } else {
      getMeasurementUnits().then(
        function() {
          if (unit) {
            pr.resolve(
              measurementUnits.find(function(item) {
                return item._id === unit;
              })
            );
          } else {
            pr.resolve(measurementUnits);
          }
        },
        function(err) {
          pr.reject(err);
        }
      );
    }
    return pr.promise;
  }

  /**
   * @memberof common.PhysicalCollectionService
   * @function
   * @description Returns all Metric Prefixes.
   * @return {promise}
   */
  function returnMetricPrefixes() {
    var pr = $q.defer();
    if (metricPrefixes) {
      pr.resolve(metricPrefixes);
    } else {
      getMetricPrefixes().then(
        function() {
          pr.resolve(metricPrefixes);
        },
        function(err) {
          pr.reject(err);
        }
      );
    }
    return pr.promise;
  }

  /**
   * @description creates an measurment data object that contains the metric prefixId, measurmentId and a human readable combination of the two
   * @function
   * @param {object} metricPrefix - the metricPrefix object
   * @param {object} measurementUnit - measurement unit object
   * @return {object} object containing metricPrefixId, measurmentUnitId and a displayName
   */
  function setMeasurementDataObject(metricPrefix, measurementUnit) {
    let measurementUnitId;
    let measurementUnitName = '';
    let measurementUnitSymbol = '';
    if (measurementUnit) {
      measurementUnitId = measurementUnit._id;
      measurementUnitName = measurementUnit.name;
      measurementUnitSymbol = measurementUnit.symbol;
    }
    return {
      metricPrefixId: metricPrefix.id,
      measurementUnitId: measurementUnitId,
      displayName: `${metricPrefix.name} ${measurementUnitName} (${metricPrefix.symbol}${measurementUnitSymbol})`
    };
  }

  return {
    getReadablePhysicalData: getReadablePhysicalData,
    returnEverything: returnEverything,
    returnPhysicalQuantities: returnPhysicalQuantities,
    returnMeasurementUnits: returnMeasurementUnits,
    returnMetricPrefixes: returnMetricPrefixes,
    physicalMetadata: physicalMetadata,
    createOrUpdateItem,
    setMeasurementDataObject
  };
}
