module.exports = {
  entity: 'event-types',
  path: 'event-types',
  entityId: 252,
  name: {
    en: 'Event type',
    sl_SI: 'Type dogodka'
  },
  codelist: 'eventTypes',
  scope: 'tis_be:eventtypes',
  selectedParamName: 'id',
  dialog: {
    title: {
      en: 'Select event type',
      sl_SI: 'Izberite tip dogodka'
    },
    listInfo: {
      en: 'Select event type from the list below',
      sl_SI: 'Izberite tip dogodka s spodnjega seznama'
    }
  }
}
