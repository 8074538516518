import themes from '../../../config/tis/themes.json';

ColorService.$inject = ['$rootScope'];
/**
 * @ngdoc service
 * @name common.ColorService
 * @description Service used to create a pick a random color from a list, determening if the color is dark,
 * returning the color list or returning goodColor and BadColor.
 * Returns an object with the follow properties
 * @property {function} RandomColor - see method RandomColor.
 * @property {function} RandomColorFromString - see method RandomColorFromString.
 * @property {function} specificHuesFromAllThemeColorIntentions - See method specificHuesFromAllThemeColorIntentions.
 * @property {Array} ColorArray - Array of hexcode strings.
 * @property {function} IsColorDark - See method IsColorDark.
 * @property {string} badColor - Returns badColor color hexcode.
 * @property {string} goodColor - Returns goodColor color hexcode.
 */
export default function ColorService($rootScope) {
  var huesForEachColorIntention = [];
  var lastSavedTheme = $rootScope.activeTheme;
  var ColorArray = [
    '#0e5f72',
    '#429c21',
    '#9c2186',
    '#219c97',
    '#719c21',
    '#0757bd',
    '#9a07bd',
    '#bda007',
    '#041F99',
    '#C09904',
    '#F86817',
    '#7DE8A0',
    '#4567B5',
    '#2BA4B3',
    '#E74814',
    '#C0D28E',
    '#A0C996',
    '#AEC058',
    '#6740CF',
    '#4A6828',
    '#DE8A11',
    '#9C255B',
    '#C0980E',
    '#076582',
    '#4CDB5F',
    '#32EBD0',
    '#C7CFD2',
    '#A93424',
    '#23B983',
    '#BC0975',
    '#9B8775',
    '#74C43A',
    '#7D3A15',
    '#F48AD0',
    '#E9DE25',
    '#4B0C38',
    '#2BCF0F',
    '#97DC2E',
    '#213BC0',
    '#CA999C',
    '#ED7FAB',
    '#7C64C6',
    '#35E020',
    '#F422FF',
    '#3B9D53',
    '#94113B',
    '#CB82F4',
    '#93A73C',
    '#831A61',
    '#A9D462',
    '#70691A',
    '#5E6D15',
    '#2A8C1C',
    '#9AFA55',
    '#BFF835',
    '#BA5147',
    '#C96277',
    '#792064',
    '#D0ECA3',
    '#2521E6',
    '#790DB5',
    '#57EB95',
    '#31F526',
    '#9F67C0',
    '#BE6E04',
    '#47E559',
    '#AA185A',
    '#E8CDEE',
    '#37DAF9',
    '#AB8198',
    '#6E0435',
    '#ED0F66',
    '#94F62D',
    '#464204',
    '#CBF4C9',
    '#D27E7B',
    '#35944F',
    '#AE4947',
    '#69DBBD',
    '#088FD5',
    '#9A719E',
    '#CC4518',
    '#5B694B',
    '#0B4D70',
    '#B78475',
    '#900119',
    '#0E6438',
    '#D83428',
    '#F2330A',
    '#4C2C09',
    '#2A93CB',
    '#DC941C'
  ];
  /**
   * @memberof common.ColorService
   * @description Picks a random color from a list of colors
   * @return {string} random color hexcode
   */
  var RandomColor = function() {
    return ColorArray[Math.floor(Math.random() * ColorArray.length)];
  };
  /**
   * @memberof common.ColorService
   * @description gets a color based on modulus the sum of the charCodes of the letters in the string
   * @param {string} string - any string
   * @return {string} color hexcode
   */
  var RandomColorFromString = function(string) {
    if (typeof string === 'string' || string instanceof String) {
      var sum = 0;
      for (var i = 0; i < string.length; ++i) {
        sum += string
          .charAt(i)
          .toLowerCase()
          .charCodeAt(0);
      }
      return ColorArray[sum % ColorArray.length];
    } else {
      return '#b8b8b8';
    }
  };
  /**
   * @memberof common.ColorService
   * @description Determines if the color is is dark
   * @param {string} c - color hexcode
   * @return {boolean} returns true of false
   */
  var IsColorDark = function(c) {
    c = c.substring(1); // strip #
    if (c.length === 3) {
      //v primeru da je barva #fff
      c += c;
    }
    var rgb = parseInt(c, 16); // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff; // extract red
    var g = (rgb >> 8) & 0xff; // extract green
    var b = (rgb >> 0) & 0xff; // extract blue

    var hsp = Math.sqrt(
      // HSP equation from http://alienryderflex.com/hsp.html
      0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)
    );

    //var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    if (hsp < 127.5) {
      return true;
    } else {
      return false;
    }
  };
  /**
   * @description Creates an array of colors from all the color intentions (accent, primary etc.) for specified hues.
   * @param {array} hues - Array containing the color hues we wish to use (example [50, 500, A100]). First hues in the array is the one that will be show first
   * @return {array} returns an array of color hex codes.
   */
  var getAllHexForSpecificHues = function(hues) {
    var palletNumbers = [];
    if (!Array.isArray(hues)) {
      palletNumbers = [500, 200, 800];
    } else {
      palletNumbers = [].concat(hues);
    }
    var currentColorTheme = themes.find(function(theme) {
      return $rootScope.activeTheme === theme.name;
    });
    var colorIntentionKeys = Object.keys(currentColorTheme);
    var colorArray = palletNumbers
      .map(function(hue) {
        var colorIntetionsForEachHue = colorIntentionKeys.reduce(function(
          array,
          key
        ) {
          if (key === 'name' || key === '$$hashKey') {
            return array;
          }
          return [].concat(array, [
            currentColorTheme[key].palette[hue].value.hex
          ]);
        },
        []);
        return colorIntetionsForEachHue;
      })
      .join()
      .split(',');
    return colorArray;
  };
  /**
   * @memberof common.ColorService
   * @description Gets a specific color from the huesForEachColorIntention array, which uses default hues.
   * @param {integer} - index of the color we are trying to get
   * @return {string} color hex code
   */
  var getApplicationColorByIndex = function(index) {
    if (
      huesForEachColorIntention.length > 0 &&
      lastSavedTheme === $rootScope.activeTheme
    ) {
      lastSavedTheme = $rootScope.activeTheme;
      return huesForEachColorIntention[index];
    }
    huesForEachColorIntention = getAllHexForSpecificHues();
    return huesForEachColorIntention[index];
  };

  /**
   * @description Returns app color via hue and shade.
   * @function
   * @param {String} hue - either: primary, secondary, success, warn
   * @param {String} shade - either: normal, hue-1, hue-2, hue-3
   * @return {String} hex of the color
   */
  var getApplicationColor = function(hue, shade) {
    var currentColorTheme = themes.find(function(theme) {
      return $rootScope.activeTheme === theme.name;
    });
    var rawShade = 500;
    if (typeof hue === 'undefined') {
      hue = 'primary';
    }
    if (typeof shade === 'undefined') {
      shade = 'normal';
    }
    switch (shade) {
    case 'hue-3':
      rawShade = 300;
      break;
    case 'hue-1':
      rawShade = 400;
      break;
    case 'hue-2':
      rawShade = 800;
      break;
    }
    return currentColorTheme[hue].palette[rawShade].value.hex;
  };
  var api = {
    RandomColor: RandomColor,
    RandomColorFromString: RandomColorFromString,
    getApplicationColorByIndex: getApplicationColorByIndex,
    getApplicationColor: getApplicationColor,
    ColorArray: ColorArray,
    IsColorDark: IsColorDark,
    badColor: '#f15a24',
    goodColor: '#149666'
  };

  return api;
}
