module.exports = [
  require('./entities/addresses.js'),
  require('./entities/analyticalFlow.js'),
  require('./entities/aggregateGroups.js'),
  require('./entities/alarmTypes.js'),
  require('./entities/alarms.js'),
  require('./entities/alarmStatuses.js'),
  require('./entities/alarmsInProgress.js'),
  require('./entities/analyses.js'),
  require('./entities/analysisCalculations.js'),
  require('./entities/analyticalDatapoints.js'),
  require('./entities/analyticIntervals.js'),
  require('./entities/archiveTypes.js'),
  require('./entities/assetBrands.js'),
  require('./entities/assetGroups.js'),
  require('./entities/assetModels.js'),
  require('./entities/assetStatuses.js'),
  require('./entities/assetTypes.js'),
  require('./entities/assets.js'),
  require('./entities/billingKinds.js'),
  require('./entities/billingOverviews.js'),
  require('./entities/billingPeriods.js'),
  require('./entities/billingTypes.js'),
  require('./entities/billingTypeBillingKinds.js'),
  require('./entities/businessPartners.js'),
  require('./entities/charts.js'),
  require('./entities/conflicts.js'),
  require('./entities/projectFields.js'),
  require('./entities/projects.js'),
  require('./entities/projectTypes.js'),
  require('./entities/ratedPowers.js'),
  require('./entities/costCentres.js'),
  require('./entities/counterTypes.js'),
  require('./entities/countries.js'),
  require('./entities/currency.js'),
  require('./entities/cusumIntervals.js'),
  require('./entities/cusums.js'),
  require('./entities/cumulativeFlow'),
  require('./entities/dashboards.js'),
  require('./entities/dashboardPlaylist.js'),
  require('./entities/databases.js'),
  require('./entities/dataSinks.js'),
  require('./entities/datapoints.js'),
  require('./entities/datasets.js'),
  require('./entities/dataTypes.js'),
  require('./entities/fileTypes.js'),
  require('./entities/detailInvoices.js'),
  require('./entities/deviceTypes.js'),
  require('./entities/documentClassifications.js'),
  require('./entities/domain.js'),
  require('./entities/domainAttributes.js'),
  require('./entities/gis-user.js'),
  require('./entities/gis-group.js'),
  require('./entities/gis-map.js'),
  require('./entities/groups.js'),
  require('./entities/energyManagementGroups.js'),
  require('./entities/energySourceTypes.js'),
  require('./entities/entities.js'),
  require('./entities/entityTags.js'),
  require('./entities/eventTypes.js'),
  require('./entities/expressions.js'),
  require('./entities/externalWriterDatapoints.js'),
  require('./entities/externalReaderFlows.js'),
  require('./entities/externalWriterFlows.js'),
  require('./entities/calculatedFlow.js'),
  require('./entities/files.js'),
  require('./entities/companyStructureNode.js'),
  require('./entities/interaction.js'),
  require('./entities/jobs.js'),
  require('./entities/scheduleClassifications.js'),
  require('./entities/locationActions.js'),
  require('./entities/locationActionStatuses.js'),
  require('./entities/locationClassifications.js'),
  require('./entities/locationStatuses.js'),
  require('./entities/locationTypes.js'),
  require('./entities/locations.js'),
  require('./entities/manual-input-groups.js'),
  require('./entities/manual-inputs.js'),
  require('./entities/mappers.js'),
  require('./entities/masterInvoices.js'),
  require('./entities/measurementStatuses.js'),
  require('./entities/measurementTypes.js'),
  require('./entities/measurementUnits.js'),
  require('./entities/measurements.js'),
  require('./entities/measuringpointClasses.js'),
  require('./entities/measuringpointKinds.js'),
  require('./entities/costCentreTypes.js'),
  require('./entities/measuringpointTypes.js'),
  require('./entities/measuringpoints.js'),
  require('./entities/maintenanceFlow.js'),
  require('./entities/mtAnalyses.js'),
  require('./entities/mtAnalysisFlow.js'),
  require('./entities/nationalHolidays.js'),
  require('./entities/notification.js'),
  require('./entities/opcs.js'),
  require('./entities/opcTypes.js'),
  require('./entities/ownerships.js'),
  require('./entities/periodIntervals.js'),
  require('./entities/physicalQuantities.js'),
  require('./entities/priceListDetails.js'),
  require('./entities/priceListItemGroups.js'),
  require('./entities/priceListItem.js'),
  require('./entities/priceListMasters.js'),
  require('./entities/profitCentre.js'),
  require('./entities/readingTypes.js'),
  require('./entities/realm.js'),
  require('./entities/reports.js'),
  require('./entities/responseResolutionTimes.js'),
  require('./entities/ruleAnalyticalDatapoints.js'),
  require('./entities/ruleCusumIntervals.js'),
  require('./entities/ruleKpis.js'),
  require('./entities/rules.js'),
  require('./entities/ruleForTargetForXAxisIntervals'),
  require('./entities/schedulerDataSourceDatapoints.js'),
  require('./entities/schedulers.js'),
  require('./entities/severities.js'),
  require('./entities/sops.js'),
  require('./entities/sopTypes.js'),
  require('./entities/steps.js'),
  require('./entities/targetForXAxisIntervals.js'),
  require('./entities/tariffs.js'),
  require('./entities/tasks.js'),
  require('./entities/timeSeries.js'),
  require('./entities/timeSeriesGroups.js'),
  require('./entities/timeZones.js'),
  require('./entities/users.js'),
  require('./entities/virtualDatapoints.js'),
  require('./entities/visualizationElements.js'),
  require('./entities/visualizationImages.js'),
  require('./entities/visualizations.js'),
  require('./entities/weatherMeasurementTypes.js'),
  require('./entities/weatherMeasurements.js'),
  require('./entities/weatherStations.js'),
  require('./entities/webserviceTypes.js'),
  require('./entities/webservices.js'),
  require('./entities/workSchedules.js'),
  require('./entities/writeDataPointTimeoutActions.js'),
  require('./entities/xAxisIntervals.js'),
  require('./entities/systemTags.js'),
  require('./entities/timeSeriesTypes.js'),
  require('./entities/externalDatasources.js'),
  require('./entities/tangoAgentConnectionConfigs'),
  require('./entities/timeSeriesGroupTypes.js')
]
