NewAssetFormController.$inject = [
  '$state',
  'gettext',
  '$timeout',
  'CrudToastFactory',
  'AlertingService',
  'EntityTagService',
  'AssetLocationModel',
  'DateTimeDialogService',
  'utilService',
  'MetadataService',
  'SfeHeader',
  'AssetModel',
  'PictureAssetModel',
  'CostCentreAssetModel',
  '$scope',
  'asset'
];

function NewAssetFormController(
  $state,
  gettext,
  $timeout,
  CrudToastFactory,
  AlertingService,
  EntityTagService,
  AssetLocationModel,
  DateTimeDialogService,
  utilService,
  MetadataService,
  SfeHeader,
  AssetModel,
  PictureAssetModel,
  CostCentreAssetModel,
  $scope,
  asset
) {
  const vm = this;
  let id = $state.params.id;
  let originalTags = [];
  let pictureAsset;
  let originalImages;
  let originalSystemTags = [];

  const editMode = $state.current.name === 'company-resources-assets-edit';
  const duplicateMode =
    $state.current.name === 'company-resources-assets-duplicate';

  vm.asset = {};
  vm.types = null;
  vm.statuses = null;

  vm.$onInit = () => {
    vm.dataConfig = {
      action: {
        ctrlFn: true,
        title: editMode ? gettext('Update') : gettext('Create'),
        fn: createOrUpdate
      }
    };
    if (editMode || duplicateMode) {
      MetadataService.Loading(true);
      let title = editMode ? gettext('Edit asset') : gettext('Duplicate asset');
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        title,
        'company-resources-assets-view',
        { id: id }
      );

      if (Array.isArray(asset.tagsSystem)) {
        let item;
        asset.systemTags = asset.tagsSystem.map(function(tag) {
          item = tag.systemTag;
          item.bindingId = tag._id; //tag service uses binding id to update/delete tag
          return item;
        });
      }
      if (editMode) {
        originalSystemTags = angular.copy(asset.systemTags);
      }

      readAsset();
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New asset'),
        'company-resources-assets-list'
      );
      vm.dataConfig.dataObj = {
        tags: [],
        location: {},
        costCentre: {},
        serialNumber: '',
        images: [],
        systemTags: []
      };
    }
    initiateForm();
  };
  /**
   * @description sets asset dataObject.
   * @function
   */
  function readAsset() {
    vm.dataConfig.dataObj = {
      ...vm.dataConfig.dataObj,
      ...asset
    };
    vm.dataConfig.dataObj.tags = [];

    vm.dataConfig.dataObj.parent = asset.parentId;
    if (asset.geoLocation) {
      vm.dataConfig.dataObj.address = {
        geolocationX: asset.geoLocation[0],
        geolocationY: asset.geoLocation[1]
      };
    } else {
      vm.dataConfig.dataObj.address = {
        geolocationX: null,
        geolocationY: null
      };
    }

    if (vm.dataConfig.dataObj.address) {
      vm.dataConfig.dataObj.address.hasAddress = true;
    }

    vm.doneLoadingData = true;

    listAssetTags();
    if (!editMode) {
      readActiveAssetLocation();
      readActiveAssetCostCentre();
    }
    readAssetPicture();
    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(
        (editMode ? 'Edit ' : 'Duplicate ') + asset.name,
        asset.description
      );
    });
  }

  /**
   * @description setts active asset location in dataObje
   * @function
   */
  function readActiveAssetLocation() {
    let obj = {
      asset: id,
      isActive: true
    };
    AssetLocationModel.custom.readLocation(obj).then(
      function(res) {
        let assetLocation = res.data[0];

        if (assetLocation && assetLocation.location) {
          vm.dataConfig.dataObj.location = {
            _id: assetLocation.location._id,
            assetLocation: assetLocation._id,
            validFrom: assetLocation.validFrom,
            validTo: assetLocation.validTo ? assetLocation.validTo : '',
            name: assetLocation.location.name,
            description: assetLocation.location.description
          };
          $scope.$applyAsync();
        }
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }
  /**
   * @description sets Images.
   * @function
   */
  function readAssetPicture() {
    PictureAssetModel.custom
      .read({
        asset: id
      })
      .then(
        function(res) {
          pictureAsset = res.data;
          if (pictureAsset.length > 0) {
            let images = [];
            let picture;
            pictureAsset.forEach(function(item) {
              picture = {};
              if (item.picture) {
                picture.duplicate = duplicateMode;
                picture.endpoint =
                  utilService.getApiHost +
                  '/pictures/' +
                  item.picture._id +
                  '/image';
                picture.file = {
                  type: 'image/gif',
                  name: item.picture.name
                };
                picture.pictureAssetId = item._id;
                picture._id = item.picture._id;
                images.push(picture);
              }
            });
            originalImages = angular.copy(images);
            vm.dataConfig.dataObj.images = images;
          }
        },
        function(err) {
          AlertingService.Error(err);
        }
      );
  }
  /**
   * @description sets active cost centre.
   * @function
   */
  function readActiveAssetCostCentre() {
    let obj = {
      asset: id,
      isActive: true
    };
    CostCentreAssetModel.custom.readCostCentre(obj).then(
      function(res) {
        let assetCostCentre = res.data[0];
        if (assetCostCentre && assetCostCentre.costCentre) {
          vm.dataConfig.dataObj.costCentre = {
            _id: assetCostCentre.costCentre._id,
            costCentreLocation: assetCostCentre._id,
            validFrom: assetCostCentre.validFrom,
            validTo: assetCostCentre.validTo ? res.data.validTo : '',
            description: assetCostCentre.costCentre.description,
            name: assetCostCentre.costCentre.name
          };
        }
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }
  /**
   * @description sets entity tags.
   * @function
   */
  function listAssetTags() {
    EntityTagService.listTags(id, true).then(
      function(res) {
        const tags = res.map(tag => tag.entityTag);
        if (!duplicateMode) {
          originalTags = angular.copy(tags);
        }
        vm.dataConfig.dataObj.tags = tags;
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }
  /**
   * @description sets form fields.
   * @function
   */
  function initiateForm() {
    let data = [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textArea',
        maxlength: 500,
        type: 'text',
        required: false
      },
      {
        configuration: {
          query: {
            entity: 'asset-types',
            method: 'read'
          },
          entity: 'asset-types',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Asset Type')
          },
          floatingLabel: gettext('Select Asset Type'),
          searchParamName: 'filter',
          required: true,
          createRedirect: {
            state: 'configurations-company-resources-assets-asset-types-new'
          }
        },
        componentType: 'autocompleteDialog',
        name: 'type'
      },
      {
        configuration: {
          query: {
            entity: 'asset-statuses',
            method: 'read'
          },
          entity: 'asset-statuses',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Asset Status')
          },
          floatingLabel: gettext('Select Asset Status'),
          searchParamName: 'filter',
          required: true,
          createRedirect: {
            state: 'configurations-company-resources-assets-list'
          }
        },
        componentType: 'autocompleteDialog',
        name: 'status'
      },
      {
        componentType: 'autocompleteDialog',
        name: 'parent',
        configuration: {
          query: {
            entity: 'assets',
            method: 'read'
          },
          entity: 'assets',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select parent asset')
          },
          floatingLabel: gettext('Select parent asset'),
          searchParamName: 'filter',
          required: false,
          createRedirect: {
            state: 'company-resources-assets-new'
          }
        }
      },
      {
        placeholder: gettext('External Code'),
        name: 'externalCode',
        componentType: 'textField',
        type: 'text',
        required: false
      }
    ];

    if (!editMode) {
      data.push(
        {
          componentType: 'title',
          title: gettext('Location')
        },
        {
          componentType: 'elementSelector',
          entity: ['locations'],
          dialogConfigurations: [
            {
              multiple: false
            }
          ],
          name: 'location',
          buttonTitle: gettext('+ Add Location'),
          addAction: openTimeDialog.bind({
            name: 'location',
            title: gettext('Add location to asset')
          }),
          validFrom: 'validFrom',
          validTo: 'validTo',
          createRedirect: {
            state: 'company-resources-locations-new'
          },
          required: false
        }
      );
    }
    data.push(
      {
        componentType: 'autocompleteDialog',
        name: 'owner',
        configuration: {
          query: {
            entity: 'business-partners',
            method: 'read'
          },
          filterObject: {
            order: 'name'
          },
          floatingLabel: gettext('Select owner'),
          searchParamName: 'name',
          createRedirect: {
            state: 'company-resources-business-partners-new'
          },
          required: false,
          entity: 'business-partners',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select owner'),
            filterObject: {
              order: 'name'
            }
          }
        }
      },
      {
        configuration: {
          query: {
            entity: 'ownerships',
            method: 'read'
          },
          entity: 'ownerships',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select an Ownership Type')
          },
          floatingLabel: gettext('Select an Ownership Type'),
          searchParamName: 'filter',
          createRedirect: {
            state: 'configurations-company-resources-ownerships-list'
          },
          required: true
        },
        componentType: 'autocompleteDialog',
        name: 'ownership'
      }
    );

    if (!editMode) {
      data.push(
        {
          componentType: 'title',
          title: gettext('Cost centre')
        },
        {
          componentType: 'elementSelector',
          entity: ['cost-centres'],
          buttonTitle: gettext('+ Add Cost centre'),
          addAction: openTimeDialog.bind({
            name: 'costCentre',
            title: gettext('Add cost centre to asset')
          }),
          validFrom: 'validFrom',
          validTo: 'validTo',
          name: 'costCentre',
          createRedirect: {
            state: 'company-resources-cost-centres-new'
          },
          required: false
        }
      );
    }
    data.push(
      {
        configuration: {
          query: {
            entity: 'asset-brands',
            method: 'read'
          },
          entity: 'asset-brands',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select a Brand')
          },
          floatingLabel: gettext('Select a Brand'),
          searchParamName: 'filter',
          change: assetBrandChanged,
          required: true,
          createRedirect: {
            state: 'configurations-company-resources-assets-list'
          }
        },
        componentType: 'autocompleteDialog',
        name: 'assetBrand'
      },
      {
        configuration: {
          query: {
            entity: 'asset-models',
            method: 'read'
          },
          entity: 'asset-models',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select a Model')
          },
          floatingLabel: gettext('Select a Model'),
          searchParamName: 'filter',
          required: true,
          createRedirect: {
            state: 'configurations-company-resources-assets-list'
          }
        },
        componentType: 'autocompleteDialog',
        edit: false,
        name: 'assetModel'
      },
      {
        placeholder: gettext('Model number'),
        name: 'modelNumber',
        componentType: 'textField',
        type: 'text',
        required: false
      },
      {
        placeholder: gettext('Serial number'),
        name: 'serialNumber',
        componentType: 'textField',
        type: 'text',
        required: false
      },
      {
        name: 'tags',
        empty: gettext('There are no tags to select.'),
        edit: true,
        componentType: 'autocompleteChipDialog',
        entity: 'entity-tags',
        searchParam: 'value',
        required: false,
        networkModel: {
          list: {
            entity: 'entity-tags',
            method: 'read'
          },
          create: {
            entity: 'entity-tags',
            method: 'create'
          }
        },
        filterObject: {
          order: 'value'
        },
        floatingLabel: gettext('Select or enter a new tag'),
        displayFields: ['value'],
        createMode: true
      },
      {
        componentType: 'address',
        name: 'address',
        noAddress: true,
        coordinatesRequired: false,
        config: {
          noSetAddress: true,
          locationFromAddress: true
        }
      },
      {
        componentType: 'fileUpload',
        name: 'images',
        fileUploader: 'fileUploader',
        endpoint: utilService.getApiHost + '/pictures',
        alias: 'file',
        queueLimit: 10,
        filters: [
          {
            name: 'imageFilter',
            fn: function(item) {
              let type =
                '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
              return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
            }
          }
        ]
      },
      {
        componentType: 'checkBoxesLinear',
        networkModel: {
          entity: 'system-tags',
          method: 'read'
        },
        name: 'systemTags',
        title: gettext('System Tags'),
        compareFn: function(item1, item2) {
          return item1._id === item2._id;
        }
      }
    );
    vm.dataConfig.data = data;
  }
  /**
   * @description triggered on brand change
   * sets brand filter to asset model autocomplete.
   * @function
   */
  function assetBrandChanged() {
    if (
      vm.assetBrandId &&
      vm.dataConfig.dataObj.assetBrand &&
      vm.dataConfig.dataObj.assetBrand._id !== vm.assetBrandId &&
      vm.dataConfig.dataObj.assetBrand
    ) {
      vm.dataConfig.dataObj.assetModel = null;
    }

    if (
      vm.dataConfig.dataObj.assetBrand &&
      vm.dataConfig.dataObj.assetBrand._id
    ) {
      vm.assetBrandId = vm.dataConfig.dataObj.assetBrand._id;
    }

    let modelConfig = _.find(vm.dataConfig.data, {
      name: 'assetModel'
    });
    if (
      modelConfig &&
      vm.dataConfig.dataObj.assetBrand &&
      vm.dataConfig.dataObj.assetBrand._id
    ) {
      modelConfig.configuration.disabled = false;
      modelConfig.configuration.filterObject = {
        assetBrand: vm.dataConfig.dataObj.assetBrand._id
      };
    }
  }
  /**
   * @description sets asset and locations valid from valid to dates.
   * triggers reset location if needed
   * @function
   * @param {Object} selected costCentre or location
   */
  function openTimeDialog(object) {
    let itemName = this.name;
    DateTimeDialogService.openDialog({
      title: this.title,
      dateOnly: true,
      initialValues: object || vm.dataConfig.dataObj[itemName],
      readDateFromLocalStorage: true
    }).then(function(item) {
      if (!item) {
        vm.dataConfig.dataObj[itemName] = {};
      } else {
        vm.dataConfig.dataObj[itemName].validTo = item.validTo;
        vm.dataConfig.dataObj[itemName].validFrom = item.validFrom;
      }
      // set coordinates from picked location
      if (itemName === 'location') {
        setAssetAddressFromLocation(object);
      }
    });
  }
  /**
   * @description sets asset address to selected location address.
   * @function
   * @param {Object} location
   */
  function setAssetAddressFromLocation(location) {
    if (location.geoLocation) {
      vm.dataConfig.dataObj.address = {
        geolocationX: location.geoLocation[0],
        geolocationY: location.geoLocation[1]
      };
    }
  }

  /**
   * @description uploads images.
   * @function
   * @return {Array} uploaded images
   */
  function uploadPicture() {
    return new Promise(resolve => {
      const fileUploader = vm.dataConfig.dataObj.fileUploader;

      if (!fileUploader.queue.length) {
        resolve([]);
      }
      let images = [];

      fileUploader.onCompleteItem = (_, response) => {
        if (response && response.data) {
          images.push(response.data._id);
        }
      };
      fileUploader.onCompleteAll = () => {
        resolve(images);
      };
      fileUploader.uploadAll();
    });
  }
  /**
   * @description connects asset and images.
   * @function
   * @param {Object} asset
   * @param {Array} images
   * @return {Promise}
   */
  function createPictureAsset(asset, images) {
    let promises = [];
    if (Array.isArray(images) && images.length > 0) {
      promises = images.reduce((result, image) => {
        if (image.deleted) {
          return result;
        }
        let apiObj = {
          picture: image,
          asset: asset._id
        };
        return [...result, PictureAssetModel.create(apiObj)];
      }, []);
    }

    if (duplicateMode && Array.isArray(vm.dataConfig.dataObj.images)) {
      const duplicatePromises = vm.dataConfig.dataObj.images.reduce(
        (result, image) => {
          if (image._id) {
            let apiObj = {
              asset: asset._id,
              picture: image._id
            };

            return [...result, PictureAssetModel.create(apiObj)];
          }
          return result;
        },
        []
      );

      promises = [...promises, ...duplicatePromises];
    }

    if (editMode) {
      const deletedImages = _.differenceBy(
        originalImages,
        vm.dataConfig.dataObj.images,
        '_id'
      );
      if (Array.isArray(deletedImages)) {
        const deletePromises = deletedImages.map(image => {
          return PictureAssetModel.delete({
            id: image.pictureAssetId
          });
        });

        promises = [...promises, ...deletePromises];
      }
    }

    return Promise.allSettled(promises);
  }
  /**
   * @description connects location/costCentre and asset.
   * @function
   * @param {Object} asset
   * @param {String} type costCentre/location
   * @param {Object} model network model object
   * @return {Promise}
   */
  async function createConnectedEntity(asset, type, model) {
    const connectedItem = vm.dataConfig.dataObj[type];
    if (!connectedItem || !connectedItem._id) {
      return;
    }
    const obj = {
      asset,
      [type]: connectedItem._id,
      validFrom: connectedItem.validFrom,
      validTo: connectedItem.validTo
    };
    try {
      model.create(obj);
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  /**
   * @description creates new asset.
   * @function
   */
  async function createOrUpdate() {
    vm.sendingRequest = true;

    const assetBody = CreateAssetAPIObject();
    try {
      let asset;
      if (editMode) {
        const { data } = await AssetModel.update(
          {
            id: id
          },
          assetBody
        );
        asset = data;
      } else {
        const { data } = await AssetModel.create(assetBody);
        asset = data;
      }
      const images = await uploadPicture();
      await createPictureAsset(asset, images);

      let promises = [
        EntityTagService.createSystemTags(
          vm.dataConfig.dataObj.systemTags,
          originalSystemTags,
          43, //asset entity id
          asset._id,
          false
        ),
        EntityTagService.createSystemTags(
          vm.dataConfig.dataObj.tags,
          originalTags,
          43, //asset entity id
          asset._id,
          true
        )
      ];
      if (!editMode) {
        promises = [
          ...promises,
          createConnectedEntity(asset._id, 'costCentre', CostCentreAssetModel),
          createConnectedEntity(asset._id, 'location', AssetLocationModel)
        ];
      }

      await Promise.allSettled(promises);

      vm.sendingRequest = false;

      const redirectObj = {
        paramName: 'id',
        paramValue: asset._id,
        state: 'company-resources-assets-view'
      };

      CrudToastFactory.toast(editMode ? 'update' : 'create', redirectObj);
    } catch (err) {
      AlertingService.Error(err);
      vm.sendingRequest = false;
    }
  }

  /**
   * @description returns post/put body object.
   * @function
   * @return {Object}
   */
  function CreateAssetAPIObject() {
    let geoLocation;
    if (
      !vm.dataConfig.dataObj.address.geolocationX ||
      !vm.dataConfig.dataObj.address.geolocationY
    ) {
      geoLocation = null;
    } else {
      geoLocation = [
        vm.dataConfig.dataObj.address.geolocationX,
        vm.dataConfig.dataObj.address.geolocationY
      ];
    }
    let obj = {
      name: vm.dataConfig.dataObj.name,
      description: vm.dataConfig.dataObj.description,
      type: vm.dataConfig.dataObj.type._id,
      status: vm.dataConfig.dataObj.status._id,
      ownership: vm.dataConfig.dataObj.ownership._id,
      assetBrand: vm.dataConfig.dataObj.assetBrand._id,
      assetModel: vm.dataConfig.dataObj.assetModel._id,
      serialNumber: vm.dataConfig.dataObj.serialNumber,
      modelNumber: vm.dataConfig.dataObj.modelNumber,
      parentId: vm.dataConfig.dataObj.parent
        ? vm.dataConfig.dataObj.parent._id
        : null,
      owner: vm.dataConfig.dataObj.owner
        ? vm.dataConfig.dataObj.owner._id
        : null,
      externalCode: vm.dataConfig.dataObj.externalCode,
      geoLocation: geoLocation
    };
    return obj;
  }
}
export default NewAssetFormController;
