ExpressionController.$inject = [
  '$timeout',
  '$stateParams',
  'gettext',
  'MetadataService',
  'expression'
];

function ExpressionController(
  $timeout,
  $stateParams,
  gettext,
  MetadataService,
  expression
) {
  var vm = this;
  var expressionId = $stateParams.id;

  init();

  function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    vm.headerData = constructItemHeader(expression);
    vm.previewExpression = expression.value;
    vm.loading = false;
    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(expression.name, expression.description);
    });
  }

  function constructItemHeader(expression) {
    var properties = [];
    var actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: expressionId,
        endpoint: {
          entity: 'expressions',
          method: 'delete'
        },
        redirectState: 'analytics-expressions-list'
      },
      {
        title: gettext('Update'),
        state: 'analytics-expressions-edit',
        param: 'id',
        paramValue: expression._id
      }
    ];

    if (expression.dataset._id) {
      properties.push({
        title: gettext('Dataset'),
        linkTitle: expression.dataset.name,
        state: 'analytics-datasets-view',
        param: 'id',
        paramValue: expression.dataset._id,
        type: 'link'
      });
    }

    properties.push({
      type: 'simple',
      title: gettext('Aggregation type'),
      value: expression.aggregationType
    });

    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];
    var propertySections = [
      {
        title: gettext('properties'),
        properties: properties
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'expressions',
        target: expression._id,
        type: 'userAccess'
      }
    ];
    return {
      metadata: {
        definition: gettext('Expression'),
        title: expression.name,
        description: expression.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}

export default ExpressionController;
