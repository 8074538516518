export default [
  {
    name: 'petrolIcons',
    enum: 1,
    icons: [
      'picon_gas-pump_negative',
      'picon_gas-pump',
      'picon_biomass_negative',
      'picon_biomass',
      'picon_electricity_negative',
      'picon_electricity',
      'picon_energy-solutions_negative',
      'picon_energy-soutions',
      'picon_heating-oil_negative',
      'picon_heating-oil',
      'picon_env-solutions_negative',
      'picon_env-solutions',
      'picon_klub_negative',
      'picon_klub',
      'picon_LPG_negative',
      'picon_LPG',
      'picon_natural-gas_negative',
      'picon_nautral-gas'
    ]
  },
  {
    name: 'fontAwesome',
    enum: 2,
    icons: [
      'fa-glass',
      'fa-500px',
      'fa-adjust',
      'fa-adn',
      'fa-align-center',
      'fa-align-justify',
      'fa-align-left',
      'fa-align-right',
      'fa-amazon',
      'fa-ambulance',
      'fa-anchor',
      'fa-android',
      'fa-angellist',
      'fa-angle-double-down',
      'fa-angle-double-left',
      'fa-angle-double-right',
      'fa-angle-double-up',
      'fa-angle-down',
      'fa-angle-left',
      'fa-angle-right',
      'fa-angle-up',
      'fa-apple',
      'fa-archive',
      'fa-area-chart',
      'fa-arrow-circle-down',
      'fa-arrow-circle-left',
      'fa-arrow-circle-o-down',
      'fa-arrow-circle-o-left',
      'fa-arrow-circle-o-right',
      'fa-arrow-circle-o-up',
      'fa-arrow-circle-right',
      'fa-arrow-circle-up',
      'fa-arrow-down',
      'fa-arrow-left',
      'fa-arrow-right',
      'fa-arrow-up',
      'fa-arrows',
      'fa-arrows-alt',
      'fa-arrows-h',
      'fa-arrows-v',
      'fa-asterisk',
      'fa-at',
      'fa-automobile',
      'fa-backward',
      'fa-balance-scale',
      'fa-ban',
      'fa-bank',
      'fa-bar-chart',
      'fa-bar-chart-o',
      'fa-barcode',
      'fa-bars',
      'fa-battery-0',
      'fa-battery-1',
      'fa-battery-2',
      'fa-battery-3',
      'fa-battery-4',
      'fa-battery-empty',
      'fa-battery-full',
      'fa-battery-half',
      'fa-battery-quarter',
      'fa-battery-three-quarters',
      'fa-bed',
      'fa-beer',
      'fa-behance',
      'fa-behance-square',
      'fa-bell',
      'fa-bell-o',
      'fa-bell-slash',
      'fa-bell-slash-o',
      'fa-bicycle',
      'fa-binoculars',
      'fa-birthday-cake',
      'fa-bitbucket',
      'fa-bitbucket-square',
      'fa-bitcoin',
      'fa-black-tie',
      'fa-bold',
      'fa-bolt',
      'fa-bomb',
      'fa-book',
      'fa-bookmark',
      'fa-bookmark-o',
      'fa-briefcase',
      'fa-btc',
      'fa-bug',
      'fa-building',
      'fa-building-o',
      'fa-bullhorn',
      'fa-bullseye',
      'fa-bus',
      'fa-buysellads',
      'fa-cab',
      'fa-calculator',
      'fa-calendar',
      'fa-calendar-check-o',
      'fa-calendar-minus-o',
      'fa-calendar-o',
      'fa-calendar-plus-o',
      'fa-calendar-times-o',
      'fa-camera',
      'fa-camera-retro',
      'fa-car',
      'fa-caret-down',
      'fa-caret-left',
      'fa-caret-right',
      'fa-caret-square-o-down',
      'fa-caret-square-o-left',
      'fa-caret-square-o-right',
      'fa-caret-square-o-up',
      'fa-caret-up',
      'fa-cart-arrow-down',
      'fa-cart-plus',
      'fa-cc',
      'fa-cc-amex',
      'fa-cc-diners-club',
      'fa-cc-discover',
      'fa-cc-jcb',
      'fa-cc-mastercard',
      'fa-cc-paypal',
      'fa-cc-stripe',
      'fa-cc-visa',
      'fa-certificate',
      'fa-chain-broken',
      'fa-chain',
      'fa-check',
      'fa-check-circle',
      'fa-check-circle-o',
      'fa-check-square',
      'fa-check-square-o',
      'fa-chevron-circle-down',
      'fa-chevron-circle-left',
      'fa-chevron-circle-right',
      'fa-chevron-circle-up',
      'fa-chevron-down',
      'fa-chevron-left',
      'fa-chevron-right',
      'fa-chevron-up',
      'fa-child',
      'fa-chrome',
      'fa-circle',
      'fa-circle-o',
      'fa-circle-o-notch',
      'fa-circle-thin',
      'fa-clipboard',
      'fa-clock-o',
      'fa-clone',
      'fa-close',
      'fa-cloud',
      'fa-cloud-download',
      'fa-cloud-upload',
      'fa-cny',
      'fa-code',
      'fa-code-fork',
      'fa-codepen',
      'fa-coffee',
      'fa-cog',
      'fa-cogs',
      'fa-columns',
      'fa-comment',
      'fa-comment-o',
      'fa-commenting',
      'fa-commenting-o',
      'fa-comments',
      'fa-comments-o',
      'fa-compass',
      'fa-compress',
      'fa-connectdevelop',
      'fa-contao',
      'fa-copy',
      'fa-copyright',
      'fa-creative-commons',
      'fa-credit-card',
      'fa-crop',
      'fa-crosshairs',
      'fa-css3',
      'fa-cube',
      'fa-cubes',
      'fa-cut',
      'fa-cutlery',
      'fa-dashboard',
      'fa-dashcube',
      'fa-database',
      'fa-dedent',
      'fa-delicious',
      'fa-desktop',
      'fa-deviantart',
      'fa-diamond',
      'fa-digg',
      'fa-dollar',
      'fa-dot-circle-o',
      'fa-download',
      'fa-dribbble',
      'fa-dropbox',
      'fa-drupal',
      'fa-edit',
      'fa-eject',
      'fa-ellipsis-h',
      'fa-ellipsis-v',
      'fa-empire',
      'fa-envelope',
      'fa-envelope-o',
      'fa-envelope-square',
      'fa-eraser',
      'fa-eur',
      'fa-euro',
      'fa-exchange',
      'fa-exclamation',
      'fa-exclamation-circle',
      'fa-exclamation-triangle',
      'fa-expand',
      'fa-expeditedssl',
      'fa-external-link',
      'fa-external-link-square',
      'fa-eye',
      'fa-eye-slash',
      'fa-eyedropper',
      'fa-facebook',
      'fa-facebook-f',
      'fa-facebook-official',
      'fa-facebook-square',
      'fa-fast-backward',
      'fa-fast-forward',
      'fa-fax',
      'fa-feed',
      'fa-female',
      'fa-fighter-jet',
      'fa-file',
      'fa-file-archive-o',
      'fa-file-audio-o',
      'fa-file-code-o',
      'fa-file-excel-o',
      'fa-file-image-o',
      'fa-file-movie-o',
      'fa-file-o',
      'fa-file-pdf-o',
      'fa-file-photo-o',
      'fa-file-picture-o',
      'fa-file-powerpoint-o',
      'fa-file-sound-o',
      'fa-file-text',
      'fa-file-text-o',
      'fa-file-video-o',
      'fa-file-word-o',
      'fa-file-zip-o',
      'fa-files-o',
      'fa-film',
      'fa-filter',
      'fa-fire',
      'fa-fire-extinguisher',
      'fa-firefox',
      'fa-flag',
      'fa-flag-checkered',
      'fa-flag-o',
      'fa-flash',
      'fa-flask',
      'fa-flickr',
      'fa-floppy-o',
      'fa-folder',
      'fa-folder-o',
      'fa-folder-open',
      'fa-folder-open-o',
      'fa-font',
      'fa-fonticons',
      'fa-forumbee',
      'fa-forward',
      'fa-foursquare',
      'fa-frown-o',
      'fa-futbol-o',
      'fa-gamepad',
      'fa-gavel',
      'fa-gbp',
      'fa-ge',
      'fa-gear',
      'fa-gears',
      'fa-genderless',
      'fa-get-pocket',
      'fa-gg',
      'fa-gg-circle',
      'fa-gift',
      'fa-git',
      'fa-git-square',
      'fa-github',
      'fa-github-alt',
      'fa-github-square',
      'fa-gittip',
      'fa-globe',
      'fa-google',
      'fa-google-plus',
      'fa-google-plus-square',
      'fa-google-wallet',
      'fa-graduation-cap',
      'fa-gratipay',
      'fa-group',
      'fa-h-square',
      'fa-hacker-news',
      'fa-hand-grab-o',
      'fa-hand-lizard-o',
      'fa-hand-o-down',
      'fa-hand-o-left',
      'fa-hand-o-right',
      'fa-hand-o-up',
      'fa-hand-paper-o',
      'fa-hand-peace-o',
      'fa-hand-pointer-o',
      'fa-hand-rock-o',
      'fa-hand-scissors-o',
      'fa-hand-spock-o',
      'fa-hand-stop-o',
      'fa-hdd-o',
      'fa-header',
      'fa-headphones',
      'fa-heart',
      'fa-heart-o',
      'fa-heartbeat',
      'fa-history',
      'fa-home',
      'fa-hospital-o',
      'fa-hotel',
      'fa-hourglass',
      'fa-hourglass-1',
      'fa-hourglass-2',
      'fa-hourglass-3',
      'fa-hourglass-end',
      'fa-hourglass-half',
      'fa-hourglass-o',
      'fa-hourglass-start',
      'fa-houzz',
      'fa-html5',
      'fa-i-cursor',
      'fa-ils',
      'fa-image',
      'fa-inbox',
      'fa-indent',
      'fa-industry',
      'fa-info',
      'fa-info-circle',
      'fa-inr',
      'fa-instagram',
      'fa-institution',
      'fa-internet-explorer',
      'fa-intersex',
      'fa-ioxhost',
      'fa-italic',
      'fa-joomla',
      'fa-jpy',
      'fa-jsfiddle',
      'fa-key',
      'fa-keyboard-o',
      'fa-krw',
      'fa-language',
      'fa-laptop',
      'fa-lastfm',
      'fa-lastfm-square',
      'fa-leaf',
      'fa-leanpub',
      'fa-legal',
      'fa-lemon-o',
      'fa-level-down',
      'fa-level-up',
      'fa-life-bouy',
      'fa-life-buoy',
      'fa-life-ring',
      'fa-life-saver',
      'fa-lightbulb-o',
      'fa-line-chart',
      'fa-link',
      'fa-linkedin',
      'fa-linkedin-square',
      'fa-linux',
      'fa-list',
      'fa-list-alt',
      'fa-list-ol',
      'fa-list-ul',
      'fa-location-arrow',
      'fa-lock',
      'fa-long-arrow-down',
      'fa-long-arrow-left',
      'fa-long-arrow-right',
      'fa-long-arrow-up',
      'fa-magic',
      'fa-magnet',
      'fa-mail-forward',
      'fa-mail-reply-all',
      'fa-mail-reply',
      'fa-male',
      'fa-map',
      'fa-map-marker',
      'fa-map-o',
      'fa-map-pin',
      'fa-map-signs',
      'fa-mars',
      'fa-mars-double',
      'fa-mars-stroke',
      'fa-mars-stroke-h',
      'fa-mars-stroke-v',
      'fa-maxcdn',
      'fa-meanpath',
      'fa-medium',
      'fa-medkit',
      'fa-meh-o',
      'fa-mercury',
      'fa-microphone',
      'fa-microphone-slash',
      'fa-minus',
      'fa-minus-circle',
      'fa-minus-square',
      'fa-minus-square-o',
      'fa-mobile',
      'fa-mobile-phone',
      'fa-money',
      'fa-moon-o',
      'fa-mortar-board',
      'fa-motorcycle',
      'fa-mouse-pointer',
      'fa-music',
      'fa-navicon',
      'fa-neuter',
      'fa-newspaper-o',
      'fa-object-group',
      'fa-object-ungroup',
      'fa-odnoklassniki',
      'fa-odnoklassniki-square',
      'fa-opencart',
      'fa-openid',
      'fa-opera',
      'fa-optin-monster',
      'fa-outdent',
      'fa-pagelines',
      'fa-paint-brush',
      'fa-paper-plane',
      'fa-paper-plane-o',
      'fa-paperclip',
      'fa-paragraph',
      'fa-paste',
      'fa-pause',
      'fa-paw',
      'fa-paypal',
      'fa-pencil',
      'fa-pencil-square',
      'fa-pencil-square-o',
      'fa-phone',
      'fa-phone-square',
      'fa-photo',
      'fa-picture-o',
      'fa-pie-chart',
      'fa-pied-piper',
      'fa-pied-piper-alt',
      'fa-pinterest',
      'fa-pinterest-p',
      'fa-pinterest-square',
      'fa-plane',
      'fa-play',
      'fa-play-circle',
      'fa-play-circle-o',
      'fa-plug',
      'fa-plus',
      'fa-plus-circle',
      'fa-plus-square',
      'fa-plus-square-o',
      'fa-power-off',
      'fa-print',
      'fa-puzzle-piece',
      'fa-qq',
      'fa-qrcode',
      'fa-question',
      'fa-question-circle',
      'fa-quote-left',
      'fa-quote-right',
      'fa-ra',
      'fa-random',
      'fa-rebel',
      'fa-recycle',
      'fa-reddit',
      'fa-reddit-square',
      'fa-refresh',
      'fa-registered',
      'fa-remove',
      'fa-renren',
      'fa-reorder',
      'fa-repeat',
      'fa-reply',
      'fa-reply-all',
      'fa-retweet',
      'fa-rmb',
      'fa-road',
      'fa-rocket',
      'fa-rotate-left',
      'fa-rotate-right',
      'fa-rouble',
      'fa-rss',
      'fa-rss-square',
      'fa-rub',
      'fa-ruble',
      'fa-rupee',
      'fa-safari',
      'fa-save',
      'fa-scissors',
      'fa-search',
      'fa-search-minus',
      'fa-search-plus',
      'fa-sellsy',
      'fa-send-o',
      'fa-send',
      'fa-server',
      'fa-share',
      'fa-share-alt',
      'fa-share-alt-square',
      'fa-share-square',
      'fa-share-square-o',
      'fa-shekel',
      'fa-sheqel',
      'fa-shield',
      'fa-ship',
      'fa-shirtsinbulk',
      'fa-shopping-cart',
      'fa-sign-in',
      'fa-sign-out',
      'fa-signal',
      'fa-simplybuilt',
      'fa-sitemap',
      'fa-skyatlas',
      'fa-skype',
      'fa-slack',
      'fa-sliders',
      'fa-slideshare',
      'fa-smile-o',
      'fa-soccer-ball-o',
      'fa-sort',
      'fa-sort-alpha-asc',
      'fa-sort-alpha-desc',
      'fa-sort-amount-asc',
      'fa-sort-amount-desc',
      'fa-sort-asc',
      'fa-sort-desc',
      'fa-sort-down',
      'fa-sort-numeric-asc',
      'fa-sort-numeric-desc',
      'fa-sort-up',
      'fa-soundcloud',
      'fa-space-shuttle',
      'fa-spinner',
      'fa-spoon',
      'fa-spotify',
      'fa-square',
      'fa-square-o',
      'fa-stack-exchange',
      'fa-stack-overflow',
      'fa-star',
      'fa-star-half',
      'fa-star-half-empty',
      'fa-star-half-full',
      'fa-star-half-o',
      'fa-star-o',
      'fa-steam',
      'fa-steam-square',
      'fa-step-backward',
      'fa-step-forward',
      'fa-stethoscope',
      'fa-sticky-note',
      'fa-sticky-note-o',
      'fa-stop',
      'fa-street-view',
      'fa-strikethrough',
      'fa-stumbleupon',
      'fa-stumbleupon-circle',
      'fa-subscript',
      'fa-subway',
      'fa-suitcase',
      'fa-sun-o',
      'fa-superscript',
      'fa-support',
      'fa-table',
      'fa-tablet',
      'fa-tachometer',
      'fa-tag',
      'fa-tags',
      'fa-tasks',
      'fa-taxi',
      'fa-television',
      'fa-tencent-weibo',
      'fa-terminal',
      'fa-text-height',
      'fa-text-width',
      'fa-th',
      'fa-th-large',
      'fa-th-list',
      'fa-thumb-tack',
      'fa-thumbs-down',
      'fa-thumbs-o-down',
      'fa-thumbs-o-up',
      'fa-thumbs-up',
      'fa-ticket',
      'fa-times',
      'fa-times-circle',
      'fa-times-circle-o',
      'fa-tint',
      'fa-toggle-down',
      'fa-toggle-left',
      'fa-toggle-off',
      'fa-toggle-on',
      'fa-toggle-right',
      'fa-toggle-up',
      'fa-trademark',
      'fa-train',
      'fa-transgender',
      'fa-transgender-alt',
      'fa-trash',
      'fa-trash-o',
      'fa-tree',
      'fa-trello',
      'fa-tripadvisor',
      'fa-trophy',
      'fa-truck',
      'fa-try',
      'fa-tty',
      'fa-tumblr',
      'fa-tumblr-square',
      'fa-turkish-lira',
      'fa-tv',
      'fa-twitch',
      'fa-twitter',
      'fa-twitter-square',
      'fa-umbrella',
      'fa-underline',
      'fa-undo',
      'fa-university',
      'fa-unlink',
      'fa-unlock',
      'fa-unlock-alt',
      'fa-unsorted',
      'fa-upload',
      'fa-usd',
      'fa-user',
      'fa-user-md',
      'fa-user-plus',
      'fa-user-secret',
      'fa-user-times',
      'fa-users',
      'fa-venus',
      'fa-venus-double',
      'fa-venus-mars',
      'fa-viacoin',
      'fa-video-camera',
      'fa-vimeo',
      'fa-vimeo-square',
      'fa-vine',
      'fa-vk',
      'fa-volume-down',
      'fa-volume-off',
      'fa-volume-up',
      'fa-warning',
      'fa-wechat',
      'fa-weibo',
      'fa-weixin',
      'fa-whatsapp',
      'fa-wheelchair',
      'fa-wifi',
      'fa-wikipedia-w',
      'fa-windows',
      'fa-won',
      'fa-wordpress',
      'fa-wrench',
      'fa-xing',
      'fa-xing-square',
      'fa-y-combinator',
      'fa-y-combinator-square',
      'fa-yahoo',
      'fa-yc-square',
      'fa-yc',
      'fa-yelp',
      'fa-yen',
      'fa-youtube',
      'fa-youtube-play',
      'fa-youtube-square'
    ]
  },
  {
    name: 'materialIcons',
    enum: 3,
    icons: [
      '3d_rotation',
      'ac_unit',
      'access_alarm',
      'access_alarms',
      'access_time',
      'accessibility',
      'accessible',
      'account_balance',
      'account_balance_wallet',
      'account_box',
      'account_circle',
      'adb',
      'add',
      'add_a_photo',
      'add_alarm',
      'add_alert',
      'add_box',
      'add_circle',
      'add_circle_outline',
      'add_location',
      'add_shopping_cart',
      'add_to_photos',
      'add_to_queue',
      'adjust',
      'airline_seat_flat',
      'airline_seat_flat_angled',
      'airline_seat_individual_suite',
      'airline_seat_legroom_extra',
      'airline_seat_legroom_normal',
      'airline_seat_legroom_reduced',
      'airline_seat_recline_extra',
      'airline_seat_recline_normal',
      'airplanemode_active',
      'airplanemode_inactive',
      'airplay',
      'airport_shuttle',
      'alarm',
      'alarm_add',
      'alarm_off',
      'alarm_on',
      'album',
      'all_inclusive',
      'all_out',
      'android',
      'announcement',
      'apps',
      'archive',
      'arrow_back',
      'arrow_downward',
      'arrow_drop_down',
      'arrow_drop_down_circle',
      'arrow_drop_up',
      'arrow_forward',
      'arrow_upward',
      'art_track',
      'aspect_ratio',
      'assessment',
      'assignment',
      'assignment_ind',
      'assignment_late',
      'assignment_return',
      'assignment_returned',
      'assignment_turned_in',
      'assistant',
      'assistant_photo',
      'attach_file',
      'attach_money',
      'attachment',
      'audiotrack',
      'autorenew',
      'av_timer',
      'backspace',
      'backup',
      'battery_alert',
      'battery_charging_full',
      'battery_full',
      'battery_std',
      'battery_unknown',
      'beach_access',
      'beenhere',
      'block',
      'bluetooth',
      'bluetooth_audio',
      'bluetooth_connected',
      'bluetooth_disabled',
      'bluetooth_searching',
      'blur_circular',
      'blur_linear',
      'blur_off',
      'blur_on',
      'book',
      'bookmark',
      'bookmark_border',
      'border_all',
      'border_bottom',
      'border_clear',
      'border_color',
      'border_horizontal',
      'border_inner',
      'border_left',
      'border_outer',
      'border_right',
      'border_style',
      'border_top',
      'border_vertical',
      'branding_watermark',
      'brightness_1',
      'brightness_2',
      'brightness_3',
      'brightness_4',
      'brightness_5',
      'brightness_6',
      'brightness_7',
      'brightness_auto',
      'brightness_high',
      'brightness_low',
      'brightness_medium',
      'broken_image',
      'brush',
      'bubble_chart',
      'bug_report',
      'build',
      'burst_mode',
      'business',
      'business_center',
      'cached',
      'cake',
      'call',
      'call_end',
      'call_made',
      'call_merge',
      'call_missed',
      'call_missed_outgoing',
      'call_received',
      'call_split',
      'call_to_action',
      'camera',
      'camera_alt',
      'camera_enhance',
      'camera_front',
      'camera_rear',
      'camera_roll',
      'cancel',
      'card_giftcard',
      'card_membership',
      'card_travel',
      'casino',
      'cast',
      'cast_connected',
      'center_focus_strong',
      'center_focus_weak',
      'change_history',
      'chat',
      'chat_bubble',
      'chat_bubble_outline',
      'check',
      'check_box',
      'check_box_outline_blank',
      'check_circle',
      'chevron_left',
      'chevron_right',
      'child_care',
      'child_friendly',
      'chrome_reader_mode',
      'class',
      'clear',
      'clear_all',
      'close',
      'closed_caption',
      'cloud',
      'cloud_circle',
      'cloud_done',
      'cloud_download',
      'cloud_off',
      'cloud_queue',
      'cloud_upload',
      'code',
      'collections',
      'collections_bookmark',
      'color_lens',
      'colorize',
      'comment',
      'compare',
      'compare_arrows',
      'computer',
      'confirmation_number',
      'contact_mail',
      'contact_phone',
      'contacts',
      'content_copy',
      'content_cut',
      'content_paste',
      'control_point',
      'control_point_duplicate',
      'copyright',
      'create',
      'create_new_folder',
      'credit_card',
      'crop',
      'crop_16_9',
      'crop_3_2',
      'crop_5_4',
      'crop_7_5',
      'crop_din',
      'crop_free',
      'crop_landscape',
      'crop_original',
      'crop_portrait',
      'crop_rotate',
      'crop_square',
      'dashboard',
      'data_usage',
      'date_range',
      'dehaze',
      'delete',
      'delete_forever',
      'delete_sweep',
      'description',
      'desktop_mac',
      'desktop_windows',
      'details',
      'developer_board',
      'developer_mode',
      'device_hub',
      'devices',
      'devices_other',
      'dialer_sip',
      'dialpad',
      'directions',
      'directions_bike',
      'directions_boat',
      'directions_bus',
      'directions_car',
      'directions_railway',
      'directions_run',
      'directions_subway',
      'directions_transit',
      'directions_walk',
      'disc_full',
      'dns',
      'do_not_disturb',
      'do_not_disturb_alt',
      'do_not_disturb_off',
      'do_not_disturb_on',
      'dock',
      'domain',
      'done',
      'done_all',
      'donut_large',
      'donut_small',
      'drafts',
      'drag_handle',
      'drive_eta',
      'dvr',
      'edit',
      'edit_location',
      'eject',
      'email',
      'enhanced_encryption',
      'equalizer',
      'error',
      'error_outline',
      'euro_symbol',
      'ev_station',
      'event',
      'event_available',
      'event_busy',
      'event_note',
      'event_seat',
      'exit_to_app',
      'expand_less',
      'expand_more',
      'explicit',
      'explore',
      'exposure',
      'exposure_neg_1',
      'exposure_neg_2',
      'exposure_plus_1',
      'exposure_plus_2',
      'exposure_zero',
      'extension',
      'face',
      'fast_forward',
      'fast_rewind',
      'favorite',
      'favorite_border',
      'featured_play_list',
      'featured_video',
      'feedback',
      'fiber_dvr',
      'fiber_manual_record',
      'fiber_new',
      'fiber_pin',
      'fiber_smart_record',
      'file_download',
      'file_upload',
      'filter',
      'filter_1',
      'filter_2',
      'filter_3',
      'filter_4',
      'filter_5',
      'filter_6',
      'filter_7',
      'filter_8',
      'filter_9',
      'filter_9_plus',
      'filter_b_and_w',
      'filter_center_focus',
      'filter_drama',
      'filter_frames',
      'filter_hdr',
      'filter_list',
      'filter_none',
      'filter_tilt_shift',
      'filter_vintage',
      'find_in_page',
      'find_replace',
      'fingerprint',
      'first_page',
      'fitness_center',
      'flag',
      'flare',
      'flash_auto',
      'flash_off',
      'flash_on',
      'flight',
      'flight_land',
      'flight_takeoff',
      'flip',
      'flip_to_back',
      'flip_to_front',
      'folder',
      'folder_open',
      'folder_shared',
      'folder_special',
      'font_download',
      'format_align_center',
      'format_align_justify',
      'format_align_left',
      'format_align_right',
      'format_bold',
      'format_clear',
      'format_color_fill',
      'format_color_reset',
      'format_color_text',
      'format_indent_decrease',
      'format_indent_increase',
      'format_italic',
      'format_line_spacing',
      'format_list_bulleted',
      'format_list_numbered',
      'format_paint',
      'format_quote',
      'format_shapes',
      'format_size',
      'format_strikethrough',
      'format_textdirection_l_to_r',
      'format_textdirection_r_to_l',
      'format_underlined',
      'forum',
      'forward',
      'forward_10',
      'forward_30',
      'forward_5',
      'free_breakfast',
      'fullscreen',
      'fullscreen_exit',
      'functions',
      'g_translate',
      'gamepad',
      'games',
      'gavel',
      'gesture',
      'get_app',
      'gif',
      'golf_course',
      'gps_fixed',
      'gps_not_fixed',
      'gps_off',
      'grade',
      'gradient',
      'grain',
      'graphic_eq',
      'grid_off',
      'grid_on',
      'group',
      'group_add',
      'group_work',
      'hd',
      'hdr_off',
      'hdr_on',
      'hdr_strong',
      'hdr_weak',
      'headset',
      'headset_mic',
      'healing',
      'hearing',
      'help',
      'help_outline',
      'high_quality',
      'highlight',
      'highlight_off',
      'history',
      'home',
      'hot_tub',
      'hotel',
      'hourglass_empty',
      'hourglass_full',
      'http',
      'https',
      'image',
      'image_aspect_ratio',
      'import_contacts',
      'import_export',
      'important_devices',
      'inbox',
      'indeterminate_check_box',
      'info',
      'info_outline',
      'input',
      'insert_chart',
      'insert_comment',
      'insert_drive_file',
      'insert_emoticon',
      'insert_invitation',
      'insert_link',
      'insert_photo',
      'invert_colors',
      'invert_colors_off',
      'iso',
      'keyboard',
      'keyboard_arrow_down',
      'keyboard_arrow_left',
      'keyboard_arrow_right',
      'keyboard_arrow_up',
      'keyboard_backspace',
      'keyboard_capslock',
      'keyboard_hide',
      'keyboard_return',
      'keyboard_tab',
      'keyboard_voice',
      'kitchen',
      'label',
      'label_outline',
      'landscape',
      'language',
      'laptop',
      'laptop_chromebook',
      'laptop_mac',
      'laptop_windows',
      'last_page',
      'launch',
      'layers',
      'layers_clear',
      'leak_add',
      'leak_remove',
      'lens',
      'library_add',
      'library_books',
      'library_music',
      'lightbulb_outline',
      'line_style',
      'line_weight',
      'linear_scale',
      'link',
      'linked_camera',
      'list',
      'live_help',
      'live_tv',
      'local_activity',
      'local_airport',
      'local_atm',
      'local_bar',
      'local_cafe',
      'local_car_wash',
      'local_convenience_store',
      'local_dining',
      'local_drink',
      'local_florist',
      'local_gas_station',
      'local_grocery_store',
      'local_hospital',
      'local_hotel',
      'local_laundry_service',
      'local_library',
      'local_mall',
      'local_movies',
      'local_offer',
      'local_parking',
      'local_pharmacy',
      'local_phone',
      'local_pizza',
      'local_play',
      'local_post_office',
      'local_printshop',
      'local_see',
      'local_shipping',
      'local_taxi',
      'location_city',
      'location_disabled',
      'location_off',
      'location_on',
      'location_searching',
      'lock',
      'lock_open',
      'lock_outline',
      'looks',
      'looks_3',
      'looks_4',
      'looks_5',
      'looks_6',
      'looks_one',
      'looks_two',
      'loop',
      'loupe',
      'low_priority',
      'loyalty',
      'mail',
      'mail_outline',
      'map',
      'markunread',
      'markunread_mailbox',
      'memory',
      'menu',
      'merge_type',
      'message',
      'mic',
      'mic_none',
      'mic_off',
      'mms',
      'mode_comment',
      'mode_edit',
      'monetization_on',
      'money_off',
      'monochrome_photos',
      'mood',
      'mood_bad',
      'more',
      'more_horiz',
      'more_vert',
      'motorcycle',
      'mouse',
      'move_to_inbox',
      'movie',
      'movie_creation',
      'movie_filter',
      'multiline_chart',
      'music_note',
      'music_video',
      'my_location',
      'nature',
      'nature_people',
      'navigate_before',
      'navigate_next',
      'navigation',
      'near_me',
      'network_cell',
      'network_check',
      'network_locked',
      'network_wifi',
      'new_releases',
      'next_week',
      'nfc',
      'no_encryption',
      'no_sim',
      'not_interested',
      'note',
      'note_add',
      'notifications',
      'notifications_active',
      'notifications_none',
      'notifications_off',
      'notifications_paused',
      'offline_pin',
      'ondemand_video',
      'opacity',
      'open_in_browser',
      'open_in_new',
      'open_with',
      'pages',
      'pageview',
      'palette',
      'pan_tool',
      'panorama',
      'panorama_fish_eye',
      'panorama_horizontal',
      'panorama_vertical',
      'panorama_wide_angle',
      'party_mode',
      'pause',
      'pause_circle_filled',
      'pause_circle_outline',
      'payment',
      'people',
      'people_outline',
      'perm_camera_mic',
      'perm_contact_calendar',
      'perm_data_setting',
      'perm_device_information',
      'perm_identity',
      'perm_media',
      'perm_phone_msg',
      'perm_scan_wifi',
      'person',
      'person_add',
      'person_outline',
      'person_pin',
      'person_pin_circle',
      'personal_video',
      'pets',
      'phone',
      'phone_android',
      'phone_bluetooth_speaker',
      'phone_forwarded',
      'phone_in_talk',
      'phone_iphone',
      'phone_locked',
      'phone_missed',
      'phone_paused',
      'phonelink',
      'phonelink_erase',
      'phonelink_lock',
      'phonelink_off',
      'phonelink_ring',
      'phonelink_setup',
      'photo',
      'photo_album',
      'photo_camera',
      'photo_filter',
      'photo_library',
      'photo_size_select_actual',
      'photo_size_select_large',
      'photo_size_select_small',
      'picture_as_pdf',
      'picture_in_picture',
      'picture_in_picture_alt',
      'pie_chart',
      'pie_chart_outlined',
      'pin_drop',
      'place',
      'play_arrow',
      'play_circle_filled',
      'play_circle_outline',
      'play_for_work',
      'playlist_add',
      'playlist_add_check',
      'playlist_play',
      'plus_one',
      'poll',
      'polymer',
      'pool',
      'portable_wifi_off',
      'portrait',
      'power',
      'power_input',
      'power_settings_new',
      'pregnant_woman',
      'present_to_all',
      'print',
      'priority_high',
      'public',
      'publish',
      'query_builder',
      'question_answer',
      'queue',
      'queue_music',
      'queue_play_next',
      'radio',
      'radio_button_checked',
      'radio_button_unchecked',
      'rate_review',
      'receipt',
      'recent_actors',
      'record_voice_over',
      'redeem',
      'redo',
      'refresh',
      'remove',
      'remove_circle',
      'remove_circle_outline',
      'remove_from_queue',
      'remove_red_eye',
      'remove_shopping_cart',
      'reorder',
      'repeat',
      'repeat_one',
      'replay',
      'replay_10',
      'replay_30',
      'replay_5',
      'reply',
      'reply_all',
      'report',
      'report_problem',
      'restaurant',
      'restaurant_menu',
      'restore',
      'restore_page',
      'ring_volume',
      'room',
      'room_service',
      'rotate_90_degrees_ccw',
      'rotate_left',
      'rotate_right',
      'rounded_corner',
      'router',
      'rowing',
      'rss_feed',
      'rv_hookup',
      'satellite',
      'save',
      'scanner',
      'schedule',
      'school',
      'screen_lock_landscape',
      'screen_lock_portrait',
      'screen_lock_rotation',
      'screen_rotation',
      'screen_share',
      'sd_card',
      'sd_storage',
      'search',
      'security',
      'select_all',
      'send',
      'sentiment_dissatisfied',
      'sentiment_neutral',
      'sentiment_satisfied',
      'sentiment_very_dissatisfied',
      'sentiment_very_satisfied',
      'settings',
      'settings_applications',
      'settings_backup_restore',
      'settings_bluetooth',
      'settings_brightness',
      'settings_cell',
      'settings_ethernet',
      'settings_input_antenna',
      'settings_input_component',
      'settings_input_composite',
      'settings_input_hdmi',
      'settings_input_svideo',
      'settings_overscan',
      'settings_phone',
      'settings_power',
      'settings_remote',
      'settings_system_daydream',
      'settings_voice',
      'share',
      'shop',
      'shop_two',
      'shopping_basket',
      'shopping_cart',
      'short_text',
      'show_chart',
      'shuffle',
      'signal_cellular_4_bar',
      'signal_cellular_connected_no_internet_4_bar',
      'signal_cellular_no_sim',
      'signal_cellular_null',
      'signal_cellular_off',
      'signal_wifi_4_bar',
      'signal_wifi_4_bar_lock',
      'signal_wifi_off',
      'sim_card',
      'sim_card_alert',
      'skip_next',
      'skip_previous',
      'slideshow',
      'slow_motion_video',
      'smartphone',
      'smoke_free',
      'smoking_rooms',
      'sms',
      'sms_failed',
      'snooze',
      'sort',
      'sort_by_alpha',
      'spa',
      'space_bar',
      'speaker',
      'speaker_group',
      'speaker_notes',
      'speaker_notes_off',
      'speaker_phone',
      'spellcheck',
      'star',
      'star_border',
      'star_half',
      'stars',
      'stay_current_landscape',
      'stay_current_portrait',
      'stay_primary_landscape',
      'stay_primary_portrait',
      'stop',
      'stop_screen_share',
      'storage',
      'store',
      'store_mall_directory',
      'straighten',
      'streetview',
      'strikethrough_s',
      'style',
      'subdirectory_arrow_left',
      'subdirectory_arrow_right',
      'subject',
      'subscriptions',
      'subtitles',
      'subway',
      'supervisor_account',
      'surround_sound',
      'swap_calls',
      'swap_horiz',
      'swap_vert',
      'swap_vertical_circle',
      'switch_camera',
      'switch_video',
      'sync',
      'sync_disabled',
      'sync_problem',
      'system_update',
      'system_update_alt',
      'tab',
      'tab_unselected',
      'tablet',
      'tablet_android',
      'tablet_mac',
      'tag_faces',
      'tap_and_play',
      'terrain',
      'text_fields',
      'text_format',
      'textsms',
      'texture',
      'theaters',
      'thumb_down',
      'thumb_up',
      'thumbs_up_down',
      'time_to_leave',
      'timelapse',
      'timeline',
      'timer',
      'timer_10',
      'timer_3',
      'timer_off',
      'title',
      'toc',
      'today',
      'toll',
      'tonality',
      'touch_app',
      'toys',
      'track_changes',
      'traffic',
      'train',
      'tram',
      'transfer_within_a_station',
      'transform',
      'translate',
      'trending_down',
      'trending_flat',
      'trending_up',
      'tune',
      'turned_in',
      'turned_in_not',
      'tv',
      'unarchive',
      'undo',
      'unfold_less',
      'unfold_more',
      'update',
      'usb',
      'verified_user',
      'vertical_align_bottom',
      'vertical_align_center',
      'vertical_align_top',
      'vibration',
      'video_call',
      'video_label',
      'video_library',
      'videocam',
      'videocam_off',
      'videogame_asset',
      'view_agenda',
      'view_array',
      'view_carousel',
      'view_column',
      'view_comfy',
      'view_compact',
      'view_day',
      'view_headline',
      'view_list',
      'view_module',
      'view_quilt',
      'view_stream',
      'view_week',
      'vignette',
      'visibility',
      'visibility_off',
      'voice_chat',
      'voicemail',
      'volume_down',
      'volume_mute',
      'volume_off',
      'volume_up',
      'vpn_key',
      'vpn_lock',
      'wallpaper',
      'warning',
      'watch',
      'watch_later',
      'wb_auto',
      'wb_cloudy',
      'wb_incandescent',
      'wb_iridescent',
      'wb_sunny',
      'wc',
      'web',
      'web_asset',
      'weekend',
      'whatshot',
      'widgets',
      'wifi',
      'wifi_lock',
      'wifi_tethering',
      'work',
      'wrap_text',
      'youtube_searched_for',
      'zoom_in',
      'zoom_out',
      'zoom_out_map'
    ]
  },
  {
    name: 'weatherIcons',
    enum: 4,
    icons: [
      'wi-barometer',
      'wi-celsius',
      'wi-cloud',
      'wi-cloud-down',
      'wi-cloud-refresh',
      'wi-cloud-up',
      'wi-cloudy',
      'wi-cloudy-gusts',
      'wi-cloudy-windy',
      'wi-day-cloudy',
      'wi-day-cloudy-gusts',
      'wi-day-cloudy-high',
      'wi-day-cloudy-windy',
      'wi-day-fog',
      'wi-day-hail',
      'wi-day-haze',
      'wi-day-light-wind',
      'wi-day-lightning',
      'wi-day-rain',
      'wi-day-rain-mix',
      'wi-day-rain-wind',
      'wi-day-showers',
      'wi-day-sleet',
      'wi-day-sleet-storm',
      'wi-day-snow',
      'wi-day-snow-thunderstorm',
      'wi-day-snow-wind',
      'wi-day-sprinkle',
      'wi-day-storm-showers',
      'wi-day-sunny',
      'wi-day-sunny-overcast',
      'wi-day-thunderstorm',
      'wi-day-windy',
      'wi-degrees',
      'wi-direction-down',
      'wi-direction-down-left',
      'wi-direction-down-right',
      'wi-direction-left',
      'wi-direction-right',
      'wi-direction-up',
      'wi-direction-up-left',
      'wi-direction-up-right',
      'wi-dust',
      'wi-earthquake',
      'wi-fahrenheit',
      'wi-fire',
      'wi-flood',
      'wi-fog',
      'wi-forecast-io-clear-day',
      'wi-forecast-io-clear-night',
      'wi-forecast-io-cloudy',
      'wi-forecast-io-fog',
      'wi-forecast-io-hail',
      'wi-forecast-io-partly-cloudy-day',
      'wi-forecast-io-partly-cloudy-night',
      'wi-forecast-io-rain',
      'wi-forecast-io-sleet',
      'wi-forecast-io-snow',
      'wi-forecast-io-thunderstorm',
      'wi-forecast-io-tornado',
      'wi-forecast-io-wind',
      'wi-gale-warning',
      'wi-hail',
      'wi-horizon',
      'wi-horizon-alt',
      'wi-hot',
      'wi-humidity',
      'wi-hurricane',
      'wi-hurricane-warning',
      'wi-lightning',
      'wi-lunar-eclipse',
      'wi-meteor',
      'wi-moon-0',
      'wi-moon-1',
      'wi-moon-2',
      'wi-moon-3',
      'wi-moon-4',
      'wi-moon-5',
      'wi-moon-6',
      'wi-moon-7',
      'wi-moon-8',
      'wi-moon-9',
      'wi-moon-10',
      'wi-moon-11',
      'wi-moon-12',
      'wi-moon-13',
      'wi-moon-14',
      'wi-moon-15',
      'wi-moon-16',
      'wi-moon-17',
      'wi-moon-18',
      'wi-moon-19',
      'wi-moon-20',
      'wi-moon-21',
      'wi-moon-22',
      'wi-moon-23',
      'wi-moon-24',
      'wi-moon-25',
      'wi-moon-26',
      'wi-moon-27',
      'wi-moon-alt-first-quarter',
      'wi-moon-alt-full',
      'wi-moon-alt-new',
      'wi-moon-alt-third-quarter',
      'wi-moon-alt-waning-crescent-1',
      'wi-moon-alt-waning-crescent-2',
      'wi-moon-alt-waning-crescent-3',
      'wi-moon-alt-waning-crescent-4',
      'wi-moon-alt-waning-crescent-5',
      'wi-moon-alt-waning-crescent-6',
      'wi-moon-alt-waning-gibbous-1',
      'wi-moon-alt-waning-gibbous-2',
      'wi-moon-alt-waning-gibbous-3',
      'wi-moon-alt-waning-gibbous-4',
      'wi-moon-alt-waning-gibbous-5',
      'wi-moon-alt-waning-gibbous-6',
      'wi-moon-alt-waxing-crescent-1',
      'wi-moon-alt-waxing-crescent-2',
      'wi-moon-alt-waxing-crescent-3',
      'wi-moon-alt-waxing-crescent-4',
      'wi-moon-alt-waxing-crescent-5',
      'wi-moon-alt-waxing-crescent-6',
      'wi-moon-alt-waxing-gibbous-1',
      'wi-moon-alt-waxing-gibbous-2',
      'wi-moon-alt-waxing-gibbous-3',
      'wi-moon-alt-waxing-gibbous-4',
      'wi-moon-alt-waxing-gibbous-5',
      'wi-moon-alt-waxing-gibbous-6',
      'wi-moon-first-quarter',
      'wi-moon-full',
      'wi-moon-new',
      'wi-moon-third-quarter',
      'wi-moon-waning-crescent-1',
      'wi-moon-waning-crescent-2',
      'wi-moon-waning-crescent-3',
      'wi-moon-waning-crescent-4',
      'wi-moon-waning-crescent-5',
      'wi-moon-waning-crescent-6',
      'wi-moon-waning-gibbous-1',
      'wi-moon-waning-gibbous-2',
      'wi-moon-waning-gibbous-3',
      'wi-moon-waning-gibbous-4',
      'wi-moon-waning-gibbous-5',
      'wi-moon-waning-gibbous-6',
      'wi-moon-waxing-crescent-1',
      'wi-moon-waxing-crescent-2',
      'wi-moon-waxing-crescent-3',
      'wi-moon-waxing-crescent-4',
      'wi-moon-waxing-crescent-5',
      'wi-moon-waxing-crescent-6',
      'wi-moon-waxing-gibbous-1',
      'wi-moon-waxing-gibbous-2',
      'wi-moon-waxing-gibbous-3',
      'wi-moon-waxing-gibbous-4',
      'wi-moon-waxing-gibbous-5',
      'wi-moon-waxing-gibbous-6',
      'wi-moonrise',
      'wi-moonset',
      'wi-alien',
      'wi-na',
      'wi-night-alt-cloudy',
      'wi-night-alt-cloudy-gusts',
      //'wi-night-alt-cloudy-high', (icon not working, might be added in the future)
      'wi-night-alt-cloudy-windy',
      'wi-night-alt-hail',
      'wi-night-alt-lightning',
      'wi-night-alt-partly-cloudy',
      'wi-night-alt-rain',
      'wi-night-alt-rain-mix',
      'wi-night-alt-rain-wind',
      'wi-night-alt-showers',
      'wi-night-alt-sleet',
      'wi-night-alt-sleet-storm',
      'wi-night-alt-snow',
      'wi-night-alt-snow-thunderstorm',
      'wi-night-alt-snow-wind',
      'wi-night-alt-sprinkle',
      'wi-night-alt-storm-showers',
      'wi-night-alt-thunderstorm',
      'wi-night-clear',
      'wi-night-cloudy',
      'wi-night-cloudy-gusts',
      'wi-night-cloudy-high',
      'wi-night-cloudy-windy',
      'wi-night-fog',
      'wi-night-hail',
      'wi-night-lightning',
      'wi-night-partly-cloudy',
      'wi-night-rain',
      'wi-night-rain-mix',
      'wi-night-rain-wind',
      'wi-night-showers',
      'wi-night-sleet',
      'wi-night-sleet-storm',
      'wi-night-snow',
      'wi-night-snow-thunderstorm',
      'wi-night-snow-wind',
      'wi-night-sprinkle',
      'wi-night-storm-showers',
      'wi-night-thunderstorm',
      'wi-owm-200',
      'wi-owm-201',
      'wi-owm-202',
      'wi-owm-210',
      'wi-owm-211',
      'wi-owm-212',
      'wi-owm-221',
      'wi-owm-230',
      'wi-owm-231',
      'wi-owm-232',
      'wi-owm-300',
      'wi-owm-301',
      'wi-owm-302',
      'wi-owm-310',
      'wi-owm-311',
      'wi-owm-312',
      'wi-owm-313',
      'wi-owm-314',
      'wi-owm-321',
      'wi-owm-500',
      'wi-owm-501',
      'wi-owm-502',
      'wi-owm-503',
      'wi-owm-504',
      'wi-owm-511',
      'wi-owm-520',
      'wi-owm-521',
      'wi-owm-522',
      'wi-owm-531',
      'wi-owm-600',
      'wi-owm-601',
      'wi-owm-602',
      'wi-owm-611',
      'wi-owm-612',
      'wi-owm-615',
      'wi-owm-616',
      'wi-owm-620',
      'wi-owm-621',
      'wi-owm-622',
      'wi-owm-701',
      'wi-owm-711',
      'wi-owm-721',
      'wi-owm-731',
      'wi-owm-741',
      'wi-owm-761',
      'wi-owm-762',
      'wi-owm-771',
      'wi-owm-781',
      'wi-owm-800',
      'wi-owm-801',
      'wi-owm-802',
      'wi-owm-803',
      'wi-owm-804',
      'wi-owm-900',
      'wi-owm-901',
      'wi-owm-902',
      'wi-owm-903',
      'wi-owm-904',
      'wi-owm-905',
      'wi-owm-906',
      'wi-owm-957',
      'wi-owm-day-200',
      'wi-owm-day-201',
      'wi-owm-day-202',
      'wi-owm-day-210',
      'wi-owm-day-211',
      'wi-owm-day-212',
      'wi-owm-day-221',
      'wi-owm-day-230',
      'wi-owm-day-231',
      'wi-owm-day-232',
      'wi-owm-day-300',
      'wi-owm-day-301',
      'wi-owm-day-302',
      'wi-owm-day-310',
      'wi-owm-day-311',
      'wi-owm-day-312',
      'wi-owm-day-313',
      'wi-owm-day-314',
      'wi-owm-day-321',
      'wi-owm-day-500',
      'wi-owm-day-501',
      'wi-owm-day-502',
      'wi-owm-day-503',
      'wi-owm-day-504',
      'wi-owm-day-511',
      'wi-owm-day-520',
      'wi-owm-day-521',
      'wi-owm-day-522',
      'wi-owm-day-531',
      'wi-owm-day-600',
      'wi-owm-day-601',
      'wi-owm-day-602',
      'wi-owm-day-611',
      'wi-owm-day-612',
      'wi-owm-day-615',
      'wi-owm-day-616',
      'wi-owm-day-620',
      'wi-owm-day-621',
      'wi-owm-day-622',
      'wi-owm-day-701',
      'wi-owm-day-711',
      'wi-owm-day-721',
      'wi-owm-day-731',
      'wi-owm-day-741',
      'wi-owm-day-761',
      'wi-owm-day-762',
      'wi-owm-day-781',
      'wi-owm-day-800',
      'wi-owm-day-801',
      'wi-owm-day-802',
      'wi-owm-day-803',
      'wi-owm-day-804',
      'wi-owm-day-900',
      'wi-owm-day-902',
      'wi-owm-day-903',
      'wi-owm-day-904',
      'wi-owm-day-906',
      'wi-owm-day-957',
      'wi-owm-night-200',
      'wi-owm-night-201',
      'wi-owm-night-202',
      'wi-owm-night-210',
      'wi-owm-night-211',
      'wi-owm-night-212',
      'wi-owm-night-221',
      'wi-owm-night-230',
      'wi-owm-night-231',
      'wi-owm-night-232',
      'wi-owm-night-300',
      'wi-owm-night-301',
      'wi-owm-night-302',
      'wi-owm-night-310',
      'wi-owm-night-311',
      'wi-owm-night-312',
      'wi-owm-night-313',
      'wi-owm-night-314',
      'wi-owm-night-321',
      'wi-owm-night-500',
      'wi-owm-night-501',
      'wi-owm-night-502',
      'wi-owm-night-503',
      'wi-owm-night-504',
      'wi-owm-night-511',
      'wi-owm-night-520',
      'wi-owm-night-521',
      'wi-owm-night-522',
      'wi-owm-night-531',
      'wi-owm-night-600',
      'wi-owm-night-601',
      'wi-owm-night-602',
      'wi-owm-night-611',
      'wi-owm-night-612',
      'wi-owm-night-615',
      'wi-owm-night-616',
      'wi-owm-night-620',
      'wi-owm-night-621',
      'wi-owm-night-622',
      'wi-owm-night-701',
      'wi-owm-night-711',
      'wi-owm-night-721',
      'wi-owm-night-731',
      'wi-owm-night-741',
      'wi-owm-night-761',
      'wi-owm-night-762',
      'wi-owm-night-781',
      'wi-owm-night-800',
      'wi-owm-night-801',
      'wi-owm-night-802',
      'wi-owm-night-803',
      'wi-owm-night-804',
      'wi-owm-night-900',
      'wi-owm-night-902',
      'wi-owm-night-903',
      'wi-owm-night-904',
      'wi-owm-night-906',
      'wi-owm-night-957',
      'wi-rain',
      'wi-rain-mix',
      'wi-rain-wind',
      'wi-raindrop',
      'wi-raindrops',
      'wi-refresh',
      'wi-refresh-alt',
      'wi-sandstorm',
      'wi-showers',
      'wi-sleet',
      'wi-small-craft-advisory',
      'wi-smog',
      'wi-smoke',
      'wi-snow',
      'wi-snow',
      'wi-snow-wind',
      'wi-snowflake-cold',
      'wi-solar-eclipse',
      'wi-sprinkle',
      'wi-stars',
      'wi-storm-showers',
      'wi-storm-showers',
      'wi-storm-warning',
      'wi-strong-wind',
      'wi-sunrise',
      'wi-sunset',
      'wi-thermometer',
      'wi-thermometer-exterior',
      'wi-thermometer-internal',
      'wi-thunderstorm',
      'wi-thunderstorm',
      'wi-time-1',
      'wi-time-2',
      'wi-time-3',
      'wi-time-4',
      'wi-time-5',
      'wi-time-6',
      'wi-time-7',
      'wi-time-8',
      'wi-time-9',
      'wi-time-10',
      'wi-time-11',
      'wi-time-12',
      'wi-tornado',
      'wi-train',
      'wi-tsunami',
      'wi-umbrella',
      'wi-volcano',
      'wi-wind-beaufort-0',
      'wi-wind-beaufort-1',
      'wi-wind-beaufort-2',
      'wi-wind-beaufort-3',
      'wi-wind-beaufort-4',
      'wi-wind-beaufort-5',
      'wi-wind-beaufort-6',
      'wi-wind-beaufort-7',
      'wi-wind-beaufort-8',
      'wi-wind-beaufort-9',
      'wi-wind-beaufort-10',
      'wi-wind-beaufort-11',
      'wi-wind-beaufort-12',
      'wi-wind-direction',
      'wi-windy',
      'wi-wmo4680-0',
      'wi-wmo4680-00',
      'wi-wmo4680-01',
      'wi-wmo4680-02',
      'wi-wmo4680-03',
      'wi-wmo4680-04',
      'wi-wmo4680-05',
      'wi-wmo4680-1',
      'wi-wmo4680-2',
      'wi-wmo4680-3',
      'wi-wmo4680-4',
      'wi-wmo4680-5',
      'wi-wmo4680-10',
      'wi-wmo4680-11',
      'wi-wmo4680-12',
      'wi-wmo4680-18',
      'wi-wmo4680-20',
      'wi-wmo4680-21',
      'wi-wmo4680-22',
      'wi-wmo4680-23',
      'wi-wmo4680-24',
      'wi-wmo4680-25',
      'wi-wmo4680-26',
      'wi-wmo4680-27',
      'wi-wmo4680-28',
      'wi-wmo4680-29',
      'wi-wmo4680-30',
      'wi-wmo4680-31',
      'wi-wmo4680-32',
      'wi-wmo4680-33',
      'wi-wmo4680-34',
      'wi-wmo4680-35',
      'wi-wmo4680-40',
      'wi-wmo4680-41',
      'wi-wmo4680-42',
      'wi-wmo4680-43',
      'wi-wmo4680-44',
      'wi-wmo4680-45',
      'wi-wmo4680-46',
      'wi-wmo4680-47',
      'wi-wmo4680-48',
      'wi-wmo4680-50',
      'wi-wmo4680-51',
      'wi-wmo4680-52',
      'wi-wmo4680-53',
      'wi-wmo4680-54',
      'wi-wmo4680-55',
      'wi-wmo4680-56',
      'wi-wmo4680-57',
      'wi-wmo4680-58',
      'wi-wmo4680-60',
      'wi-wmo4680-61',
      'wi-wmo4680-62',
      'wi-wmo4680-63',
      'wi-wmo4680-64',
      'wi-wmo4680-65',
      'wi-wmo4680-66',
      'wi-wmo4680-67',
      'wi-wmo4680-68',
      'wi-wmo4680-70',
      'wi-wmo4680-71',
      'wi-wmo4680-72',
      'wi-wmo4680-73',
      'wi-wmo4680-74',
      'wi-wmo4680-75',
      'wi-wmo4680-76',
      'wi-wmo4680-77',
      'wi-wmo4680-78',
      'wi-wmo4680-80',
      'wi-wmo4680-81',
      'wi-wmo4680-82',
      'wi-wmo4680-83',
      'wi-wmo4680-84',
      'wi-wmo4680-85',
      'wi-wmo4680-86',
      'wi-wmo4680-87',
      'wi-wmo4680-89',
      'wi-wmo4680-90',
      'wi-wmo4680-91',
      'wi-wmo4680-92',
      'wi-wmo4680-93',
      'wi-wmo4680-94',
      'wi-wmo4680-95',
      'wi-wmo4680-96',
      'wi-wmo4680-99',
      'wi-wu-chanceflurries',
      'wi-wu-chancerain',
      'wi-wu-chancesleat',
      'wi-wu-chancesnow',
      'wi-wu-chancetstorms',
      'wi-wu-clear',
      'wi-wu-cloudy',
      'wi-wu-flurries',
      'wi-wu-hazy',
      'wi-wu-mostlycloudy',
      'wi-wu-mostlysunny',
      'wi-wu-partlycloudy',
      'wi-wu-partlysunny',
      'wi-wu-rain',
      'wi-wu-sleat',
      'wi-wu-snow',
      'wi-wu-sunny',
      'wi-wu-tstorms',
      'wi-wu-unknown',
      'wi-yahoo-0',
      'wi-yahoo-1',
      'wi-yahoo-2',
      'wi-yahoo-3',
      'wi-yahoo-4',
      'wi-yahoo-5',
      'wi-yahoo-6',
      'wi-yahoo-7',
      'wi-yahoo-8',
      'wi-yahoo-9',
      'wi-yahoo-10',
      'wi-yahoo-11',
      'wi-yahoo-12',
      'wi-yahoo-13',
      'wi-yahoo-14',
      'wi-yahoo-15',
      'wi-yahoo-16',
      'wi-yahoo-17',
      'wi-yahoo-18',
      'wi-yahoo-19',
      'wi-yahoo-20',
      'wi-yahoo-21',
      'wi-yahoo-22',
      'wi-yahoo-23',
      'wi-yahoo-24',
      'wi-yahoo-25',
      'wi-yahoo-26',
      'wi-yahoo-27',
      'wi-yahoo-28',
      'wi-yahoo-29',
      'wi-yahoo-30',
      'wi-yahoo-31',
      'wi-yahoo-32',
      'wi-yahoo-33',
      'wi-yahoo-34',
      'wi-yahoo-35',
      'wi-yahoo-36',
      'wi-yahoo-37',
      'wi-yahoo-38',
      'wi-yahoo-39',
      'wi-yahoo-40',
      'wi-yahoo-41',
      'wi-yahoo-42',
      'wi-yahoo-43',
      'wi-yahoo-44',
      'wi-yahoo-45',
      'wi-yahoo-46',
      'wi-yahoo-47',
      'wi-yahoo-3200'
    ]
  }
];
