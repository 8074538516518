export default [
  '$timeout',
  function($timeout) {
    return {
      scope: {
        sfeFixedTableLoading: '=?',
        sfeLoadingDone: '='
      },
      restrict: 'A',
      link: function link(scope, el, attrs) {
        const isFirefox =
          navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

        $timeout(function() {
          applyScrollingTransform(attrs.sfeFixedTable);
        });

        scope.$on('$destroy', function() {
          el.unbind('scroll');
        });

        /**
         * @description Applies element binding on scroll to fix headers in place.
         * @function
         * @param {string} orientation - either 'vertical' or 'horizontal'
         */
        function applyScrollingTransform(orientation) {
          const header = angular.element(el[0].querySelectorAll('thead')[0]);
          const body = angular.element(el[0].querySelectorAll('tbody')[0]);
          const bodyRows = angular.element(body[0].querySelectorAll('tr'));
          const headerColumns = angular.element(
            header[0].querySelectorAll('th')
          );
          el.bind('scroll', function() {
            let left;
            if (isFirefox) {
              angular
                .element(header)
                .css(
                  'transform',
                  'translate3d(' + '0,' + el.scrollTop() + 'px,0)'
                );
            }
            let top;
            angular.forEach(headerColumns, function(headerColumn) {
              // translate header cells only when not using Firefox
              // or if using Firefox only first cells to update horizontal scroll
              const transform =
                (isFirefox &&
                  headerColumn.classList.contains('first-column-header')) ||
                !isFirefox;
              if (transform) {
                if (headerColumn.classList.contains('first-column-header')) {
                  left = el.scrollLeft();
                } else {
                  left = 0;
                }

                if (isFirefox) {
                  top = 0;
                } else {
                  top = el.scrollTop();
                }

                angular
                  .element(headerColumn)
                  .css(
                    'transform',
                    'translate3d(' + left + 'px,' + top + 'px,0)'
                  );
              }
            });
            angular.forEach(bodyRows, function(row) {
              let bodyColumns = row.querySelectorAll('td');
              if (
                orientation === 'horizontal' &&
                bodyColumns[1].classList.contains('group-name')
              ) {
                bodyColumns = [bodyColumns[0], bodyColumns[1]];
              } else {
                bodyColumns = bodyColumns[0];
              }
              angular
                .element(bodyColumns)
                .css('transform', 'translate3d(' + el.scrollLeft() + 'px,0,0)');
            });
          });
        }
      }
    };
  }
];
