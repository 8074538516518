GoogleMapsAPI.$inject = [];

export default function GoogleMapsAPI() {
  function createMarkerDOM(title, link, imageUrl, addressObj) {
    var basisDOM,
      imgDOM,
      addressDOM,
      metaDOM,
      bodyDOM,
      linkDOM,
      titleDOM,
      tempDOM;
    basisDOM = document.createElement('DIV');
    basisDOM.className = 'info-window';
    metaDOM = document.createElement('DIV');
    metaDOM.className = 'right-float';
    bodyDOM = document.createElement('DIV');
    bodyDOM.className = 'info-window-body';
    titleDOM = document.createElement('P');
    titleDOM.innerHTML = title;
    if (link) {
      linkDOM = document.createElement('A');
      linkDOM.setAttribute('href', link);
      linkDOM.appendChild(titleDOM);
      bodyDOM.appendChild(linkDOM);
    } else {
      bodyDOM.appendChild(titleDOM);
    }
    if (imageUrl) {
      imgDOM = document.createElement('IMG');
      imgDOM.setAttribute('src', imageUrl);
      imgDOM.className = 'info-window-image';
      basisDOM.appendChild(imgDOM);
    }
    if (addressObj) {
      addressDOM = document.createElement('DIV');
      addressDOM.className = 'info-window-address';
      Object.keys(addressObj).forEach(function(key) {
        tempDOM = document.createElement('P');
        tempDOM.innerHTML = addressObj[key];
        addressDOM.appendChild(tempDOM);
      });
      bodyDOM.appendChild(addressDOM);
    }
    basisDOM.appendChild(bodyDOM);
    return basisDOM;
  }

  return {
    createMarkerDOM: createMarkerDOM
  };
}
