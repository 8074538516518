import types from './times-series-configurations.type';

function listTimeSeriesConfigurations(payload) {
  return {
    type: types.TIME_SERIES_CONFIGURATIONS_LIST,
    payload
  };
}

function addTimeSeriesConfigurations(payload) {
  return {
    type: types.TIME_SERIES_CONFIGURATIONS_ADD,
    payload
  };
}

function updateTimeSeriesConfigurations(payload) {
  return {
    type: types.TIME_SERIES_CONFIGURATIONS_UPDATE,
    payload
  };
}

export default {
  listTimeSeriesConfigurations,
  addTimeSeriesConfigurations,
  updateTimeSeriesConfigurations
};
