responseConfiguration.$inject = [
  'gettext',
  '$q',
  'SfeFormDialogService',
  'CrudToastFactory',
  'AlertingService',
  'ResponseResolutionTimeModel'
];

function responseConfiguration(
  gettext,
  $q,
  SfeFormDialogService,
  CrudToastFactory,
  AlertingService,
  ResponseResolutionTimeModel
) {
  var createObject = {
    name: '',
    description: '',
    days: 0,
    hours: 0,
    minutes: 10,
    seconds: 0
  };

  function getForm() {
    return [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        showCond: true
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textArea',
        maxlength: 500,
        required: false
      },
      {
        componentType: 'fourInRow',
        subData: [
          {
            placeholder: 'Days',
            name: 'days',
            pattern: new RegExp('^([0-9])*$'),
            patternExample: '14',
            type: 'number',
            min: 0,
            required: true
          },
          {
            placeholder: 'Hours',
            name: 'hours',
            type: 'number',
            min: 0,
            max: 23,
            required: true
          },
          {
            placeholder: 'Minutes',
            name: 'minutes',
            type: 'number',
            min: 0,
            max: 59,
            required: true
          },
          {
            placeholder: 'Seconds',
            name: 'seconds',
            type: 'number',
            min: 0,
            max: 59,
            required: true
          }
        ]
      }
    ];
  }

  function openCreateDialog(relistData) {
    var config = getForm();
    var title = gettext('New Response Interval');

    SfeFormDialogService.openSfeFormDialog(
      false,
      config,
      createObject,
      title
    ).then(
      function(object) {
        if (object) {
          var totalSeconds = 0;
          var response = {};
          totalSeconds =
            object.seconds +
            object.minutes * 60 +
            object.hours * 60 * 60 +
            object.days * 60 * 60 * 24;
          response.name = object.name;
          response.description = object.description;
          response.milliseconds = totalSeconds * 1000;
          createResponse(response, relistData);
        }
      },
      function() {}
    );
  }
  function createResponse(response, relistData) {
    ResponseResolutionTimeModel.create(response).then(
      function() {
        var redirectObj = {
          state: 'configurations-alarm-configuration-responses-list'
        };
        relistData();
        CrudToastFactory.toast('create', redirectObj);
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function openUpdateDialog(item, relistData) {
    var config = getForm();
    var title = gettext('Edit Response Interval');

    var seconds = parseInt(item.milliseconds, 10) / 1000;
    var days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 24 * 3600;
    var hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    var minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    var updateObject = {
      name: item.name,
      description: item.description,
      seconds: seconds,
      minutes: minutes,
      hours: hours,
      days: days
    };
    config.forEach(function(item) {
      item.edit = true;
    });

    SfeFormDialogService.openSfeFormDialog(
      true,
      config,
      updateObject,
      title
    ).then(
      function(object) {
        if (object) {
          var totalSeconds = 0;
          var response = {};
          totalSeconds =
            object.seconds +
            object.minutes * 60 +
            object.hours * 60 * 60 +
            object.days * 60 * 60 * 24;
          response.name = object.name;
          response.description = object.description;
          response.milliseconds = totalSeconds * 1000;
          updateResponse(response, item, relistData);
        }
      },
      function() {}
    );
  }

  function updateResponse(response, originalItem, relistData) {
    ResponseResolutionTimeModel.update(
      {
        id: originalItem._id
      },
      response
    ).then(
      function() {
        relistData();
        CrudToastFactory.toast('update');
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  var configuration = {
    entity: {
      singular: gettext('Response'),
      plural: gettext('Responses')
    },
    api: {
      query: {
        entity: 'response-resolution-times',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name'
    },
    buttons: [
      {
        title: gettext('Create'),
        fn: openCreateDialog,
        relist: true,
        color: 'accent'
      }
    ],
    shortActions: [
      {
        action: openUpdateDialog,
        icon: 'mode_edit',
        title: gettext('Update')
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'response-resolution-times',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description')
      },
      {
        param: 'responseTime',
        title: gettext('Response Time')
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    var secondsTotal = Math.floor(item.milliseconds / 1000);

    var day;
    var hour;
    var minutes;
    var seconds;

    day = Math.floor(secondsTotal / 3600 / 24) || 0;
    hour = Math.floor(secondsTotal / 3600) - day * 24 || 0;
    minutes =
      Math.floor((secondsTotal - hour * 3600) / 60 - day * 24 * 60) || 0;
    seconds = secondsTotal - hour * 3600 - minutes * 60 - day * 24 * 3600 || 0;

    item.time = {
      day: day,
      hours: hour,
      minutes: minutes,
      seconds: seconds
    };

    var dayString = gettext('days');
    var hourString = gettext('hours');
    var minuteString = gettext('min');
    var secondString = gettext('sec');

    item.responseTime =
      '' +
      (day ? day + ' ' + dayString + ' ' : '') +
      ' ' +
      (hour ? hour + ' ' + hourString + ' ' : '') +
      ' ' +
      (minutes ? minutes + ' ' + minuteString + ' ' : '') +
      ' ' +
      (seconds ? seconds + ' ' + secondString : '');

    deferred.resolve();
    return deferred.promise;
  }
  return configuration;
}
export default responseConfiguration;
