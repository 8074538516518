const EXTERNAL_WRITER_FLOW_READ = 'EXTERNAL_WRITER_FLOW_READ';
const EXTERNAL_WRITER_FLOW_UPDATE = 'EXTERNAL_WRITER_FLOW_UPDATE';
const EXTERNAL_WRITER_FLOW_CREATE = 'EXTERNAL_WRITER_FLOW_CREATE';
const EXTERNAL_WRITER_FLOW_ADD = 'EXTERNAL_WRITER_FLOW_ADD';

export default {
  EXTERNAL_WRITER_FLOW_READ,
  EXTERNAL_WRITER_FLOW_UPDATE,
  EXTERNAL_WRITER_FLOW_CREATE,
  EXTERNAL_WRITER_FLOW_ADD
};
