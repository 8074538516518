import './invoice-detail-values.scss';
import template from './invoice-detail-values.directive.html';
import { DateTime } from 'luxon';
/**
 * @ngdoc component
 * @name invoice.invoiceDetailValues
 * @description used to display and update detail invoice values.
 * @param {number} leftSum calculated by component invoice left amount
 * @param {number} calculatedBookValue calculated by component curremt book value
 * @param {Object} validPriceListDetail currently valid pricelist detail object
 * @param {bool} changingData indicates if we are currently changing/saving detail invoice values
 * @param {string} currencySymbol
 * @param {Array} values detail invoice values array
 * @param {string} energySourceType energySourceType id
 * @param {string} masterInvoice masterInvoice id
 * @param {string} measuringPoint measuringPoint id
 * @param {string} priceListDetail priceListDetail id
 * @param {Date} readDate master invoice reading date
 * @param {string} detailInvoiceId detail invoice id
 * @param {Date} serviceDateTo master invoice service date to
 * @example
 * <invoice-detail-values
 *   currency-symbol="::vm.currencySymbol"
 *   values="vm.detailInvoiceValues"
 *   energy-source-type="::vm.energySourceType"
 *   master-invoice="::vm.masterInvoice"
 *   measuring-point="::vm.measuringPoint"
 *   price-list-detail="::vm.priceListDetail"
 *   read-date="::vm.readDate"
 *   calculated-book-value="vm.calculatedBookValue"
 *   left-sum="vm.leftAmount"
 *   detail-invoice-id="vm.detailInvoiceId"
 *   service-date-to="vm.serviceDateTo"
 *   changing-data="vm.distributeDisabled"
 *   measuring-point-id="vm.measuringPointId"
 *   valid-price-list-detail="vm.validPriceListDetail"
 * ></invoice-detail-values>
 */
export default function invoiceDetailValues() {
  return {
    template,
    scope: {
      leftSum: '=',
      calculatedBookValue: '=',
      validPriceListDetail: '=',
      changingData: '=',
      currencySymbol: '<',
      values: '<',
      energySourceType: '<',
      masterInvoice: '<',
      measuringPoint: '<',
      priceListDetail: '<',
      readDate: '<',
      detailInvoiceId: '<',
      serviceDateTo: '<'
    },
    restrict: 'E',
    controller: InvoiceDetailValuesController,
    controllerAs: 'vm',
    bindToController: true
  };
}

InvoiceDetailValuesController.$inject = [
  '$scope',
  '$q',
  'gettext',
  'AlertingService',
  'ToastService',
  'DateTimeDialogService',
  'findPriceListDetailService',
  'StandardUtils',
  'TranslationService',
  'DetailInvoiceValueModel',
  'DetailInvoiceModel',
  'PriceListDetailModel',
  'PriceListItemModel',
  '$state',
  'gettextCatalog',
  'SfeFormDialogService',
  '$filter',
  'InfoDialog',
  '$mdDialog',
  'PhysicalCollectionService',
  'CrudToastFactory',
  'MasterInvoiceModel'
];
function InvoiceDetailValuesController(
  $scope,
  $q,
  gettext,
  AlertingService,
  ToastService,
  DateTimeDialogService,
  findPriceListDetailService,
  StandardUtils,
  TranslationService,
  DetailInvoiceValueModel,
  DetailInvoiceModel,
  PriceListDetailModel,
  PriceListItemModel,
  $state,
  gettextCatalog,
  SfeFormDialogService,
  $filter,
  InfoDialog,
  $mdDialog,
  PhysicalCollectionService,
  CrudToastFactory,
  MasterInvoiceModel
) {
  var vm = this;
  var originalDetailInvoiceItems;
  var newPriceListItem;
  var isSavingEnabled;
  /**
   * @description function is triggered every time when bindings are changed. It triggers init function when values are available
   * @function
   * @param {array} changes array of changes
   */
  vm.$onChanges = function(changes) {
    if (changes.values && Array.isArray(vm.values)) {
      constructTableValues();
      vm.valueActions = getDetailValuesToolbar();
    }
  };

  vm.sum = 0;
  vm.orderIsLocked = true;

  vm.tableConfig = {
    data: [],
    options: {
      order: 'order'
    }
  };
  // TOOLBAR action button configuration array
  vm.valueActions = getDetailValuesToolbar();

  vm.onQuantityChange = onQuantityChange;
  isSavingEnabled = getInvoiceDetailValuesSum() <= vm.calculatedBookValue;
  vm.cancelChangingPriceList = cancelChangingPriceList;
  vm.openCreateEditDialog = openCreateEditDialog;
  vm.deleteDetailInvoiceValue = deleteDetailInvoiceValue;

  /**
   * @description wathces for calculated book value to change and then triggers function that calculates amount left.
   * @function
   */
  var calculatedBookValueWatcher = $scope.$watch(
    'vm.calculatedBookValue',
    function() {
      if (typeof vm.calculatedBookValue !== 'undefined') {
        calculateSumLeft();
      }
    }
  );

  function getDetailValuesToolbar() {
    return [
      {
        icon: { name: 'unfold_more', type: 2 },
        title: vm.orderIsLocked ? gettext('Reorder') : gettext('Save order'),
        color: 'primary',
        fn: toggleOrderLock,
        label: gettext('Edit order'),
        identifier: 'order'
      },
      {
        title: vm.editingItemPrice
          ? gettext('Cancel')
          : gettext('Change Price List'),
        label:
          angular.equals(vm.validPriceListDetail, {}) ||
          !vm.validPriceListDetail
            ? gettextCatalog.getString('Invoice has no valid price list detail')
            : '',
        disabledFn: () => {
          return (
            !(
              vm.tableConfig &&
              vm.tableConfig.data &&
              vm.tableConfig.data.length > 0
            ) || angular.equals(vm.validPriceListDetail, {})
          );
        },
        fn: () => {
          if (vm.editingItemPrice) {
            cancelChangingPriceList();
            vm.valueActions[1].title = vm.editingItemPrice
              ? gettext('Cancel')
              : gettext('Change Price List');
          } else {
            changePriceList();
          }
        },
        identifier: 'changePriceList'
      },
      {
        title: gettext('Save'),
        fn: saveChanges,
        icon: { name: 'save', type: 2 },
        disabledFn: () => {
          return (
            !isSavingEnabled ||
            !(
              vm.tableConfig &&
              vm.tableConfig.data &&
              vm.tableConfig.data.length > 0
            )
          );
        },
        identifier: 'save'
      },
      {
        title: gettext('create'),
        fn: () => openCreateEditDialog(),
        color: 'accent',
        identifier: 'create'
      }
    ];
  }

  $scope.$on('$destroy', function() {
    if (calculatedBookValueWatcher) {
      calculatedBookValueWatcher();
    }
  });

  // re init values after redistribute is triggered
  $scope.$on('reInit', function() {
    init();
  });

  /**
   * @description calculates detail invoice values sum .
   * @function
   * @return {bool}
   */
  function validateOnPriceChange() {
    var invoiceValueId = this.invoiceValueId;
    var formObj = this.formObj;
    return getNewAmountLeft(invoiceValueId, formObj) > -1;
  }
  /**
   * @description calculates detail invoice values sum .
   * @function
   * @param {number} invoiceValueId invoice value id
   * @param {Object} formObj form object
   * @return {bool}
   */
  function getNewAmountLeft(invoiceValueId, formObj) {
    let sum = 0;
    if (vm.tableConfig.data && Array.isArray(vm.tableConfig.data)) {
      var value;
      sum = vm.tableConfig.data.reduce((sum, item) => {
        value = item.detailInvoiceValue ? item.detailInvoiceValue.value : 0;
        if (item.detailInvoiceValue._id === invoiceValueId) {
          return sum + formObj.quantity * formObj.price;
        }
        return sum + value;
      }, 0);
      if (!invoiceValueId) {
        sum += formObj.quantity * formObj.price;
      }
    }
    return vm.calculatedBookValue - sum;
  }

  function getEnergyGroupFilter() {
    return {
      energySourceType: vm.energySourceType
    };
  }
  /**
   * @description returns detail invoice edit/new form.
   * @function
   * @param {bool} isEdit indicates edit mode
   * @return {Array}
   */
  function getDetailInvoiceFormConfiguration(isEdit) {
    return [
      {
        placeholder: 'Name',
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: 'Quantity',
        name: 'quantity',
        componentType: 'textField',
        type: 'numerical',
        required: true
      },
      {
        placeholder: 'Price',
        name: 'price',
        componentType: 'textField',
        type: 'numerical',
        required: true
      },
      {
        componentType: 'paragraph',
        paragraph: 'Test'
      },
      {
        placeholder: gettext('External Code'),
        name: 'externalCode',
        componentType: 'textField',
        maxLength: 30,
        required: false
      },
      {
        componentType: 'title',
        title: gettext('Physical information')
      },
      {
        componentType: 'physicalAutocomplete',
        physicalQuantity: 'physicalQuantity',
        metricPrefix: 'metricPrefix',
        measurementUnit: 'measurementUnit',
        result: 'physicalData',
        required: true
      },
      {
        componentType: 'autocompleteDialog',
        edit: isEdit,
        configuration: {
          query: {
            entity: 'energy-management-groups',
            method: 'read'
          },
          floatingLabel: gettext('Energy management groups'),
          searchParamName: 'filter',
          entity: 'energy-management-groups',
          createRedirect: {
            state:
              'configurations-energy-management-energy-management-items-list'
          },
          filterObjectFn: getEnergyGroupFilter,
          dialogConfiguration: {
            filterObjectFn: getEnergyGroupFilter
          }
        },
        name: 'energyManagementGroup'
      },
      {
        componentType: 'autocompleteDialog',
        edit: isEdit,
        configuration: {
          query: {
            entity: 'price-list-item-groups',
            method: 'read'
          },
          floatingLabel: gettext('Price list item groups'),
          searchParamName: 'filter',
          entity: 'price-list-item-groups',
          createRedirect: {
            state:
              'configurations-energy-management-price-list-item-groups-list'
          }
        },
        name: 'priceListItemGroup'
      }
    ];
  }
  /**
   * @description opens confirmation dialog and deletes detail invoice value at index.
   * @function
   * @param {number} index detail invoice value index
   */
  function deleteDetailInvoiceValue(index) {
    var textItem = {
      text: gettext('Are you sure you want to delete this item?'),
      type: 'text'
    };

    var actions = [
      {
        title: gettext('Cancel'),
        cancel: true,
        color: 'primary'
      },
      {
        title: gettext('Delete'),
        fn: function() {
          var itemToDelete = vm.tableConfig.data[index];
          if (itemToDelete) {
            DetailInvoiceValueModel.delete({
              id: itemToDelete.detailInvoiceValue._id
            }).then(
              function() {
                CrudToastFactory.toast('delete');
                $mdDialog.hide();
                init();
              },
              function(err) {
                AlertingService.Error(err);
                $mdDialog.hide();
              }
            );
          } else {
            $mdDialog.hide();
          }
        },
        color: 'warn'
      }
    ];

    InfoDialog.open(gettext('Confirmation'), null, [textItem], actions);
  }
  /**
   * @description edit form dialog on price change method. Sets bellow price paragraph total value
   * @function
   */
  function setLabelOnPriceOrCountChange() {
    var formObj = this.formObj;
    var configuration = this.configuration;
    var invoiceValueId = this.invoiceValueId;
    var amountLeft = StandardUtils.round(
      getNewAmountLeft(invoiceValueId, formObj),
      2
    );

    const total = StandardUtils.round(formObj.quantity * formObj.price, 6);
    if (
      typeof formObj.quantity == 'number' &&
      typeof formObj.price == 'number' &&
      String(total) != 'NaN'
    ) {
      configuration.paragraph = gettextCatalog.getString(
        'Total {{total}}{{currency}}. Total amount left {{amountLeft}}{{currency}}',
        {
          total: $filter('numberFormat')(StandardUtils.round(total, 6)),
          currency: vm.currencySymbol,
          amountLeft
        }
      );
    } else {
      configuration.paragraph = gettext('Could not calculate the input.');
    }
  }
  /**
   * @description indicates if order was changed.
   * @function
   * @return {bool}
   */
  function orderIsChanged() {
    return vm.tableConfig.data.reduce((changed, item, index) => {
      return item.detailInvoiceValue.order !== index || changed;
    }, false);
  }

  function priceListEditingStateChanged(isEditing) {
    vm.valueActions = vm.valueActions.map(actionConfig => {
      switch (actionConfig.identifier) {
      case 'order':
        return {
          ...actionConfig,
          disabled: isEditing
        };
      case 'changePriceList':
        return {
          ...actionConfig,
          title: isEditing ? gettext('Cancel') : gettext('Change Price List')
        };
      case 'save':
        return actionConfig;
      case 'create':
        return {
          ...actionConfig,
          disabled: isEditing
        };
      default:
        return actionConfig;
      }
    });
  }

  /**
   * @description locks and unlock order editing .
   * @function
   */
  function toggleOrderLock() {
    if (!vm.orderIsLocked && orderIsChanged()) {
      updateInvoiceDetailPromise().then(
        async () => {
          await updateMasterInvoice();
          ToastService.showToast(
            gettext('Detail invoice values order was successfully updated')
          );
        },
        err => {
          AlertingService.Error(err);
          init();
        }
      );
    }
    vm.orderIsLocked = !vm.orderIsLocked;
    vm.valueActions[0].title = vm.orderIsLocked
      ? gettext('Reorder')
      : gettext('Save order');
  }
  /**
   * @description opens edit/create detail invoice value dialog and updates/creates detail invoice value.
   * @function
   * @param {number} index detail invoice value index when update form
   */
  function createEditItemValue(index) {
    var dataObj = {
      physicalData: {},
      quantity: 0,
      price: 0,
      value: 0,
      _preserve_: true
    };
    var isEdit = typeof index == 'number' && !isNaN(index);
    var config = getDetailInvoiceFormConfiguration(isEdit);
    var priceConfiguration = config.find(item => item.name == 'price');
    var quantityConfiguration = config.find(item => item.name == 'quantity');
    if (isEdit) {
      var itemToEdit = vm.tableConfig.data[index];
      dataObj = {
        ...dataObj,
        name: itemToEdit.detailInvoiceValue.name,
        externalCode: itemToEdit.detailInvoiceValue.externalCode,
        energyManagementGroup:
          itemToEdit.detailInvoiceValue.energyManagementGroup,
        priceListItemGroup: itemToEdit.detailInvoiceValue.priceListItemGroup,
        physicalQuantity: itemToEdit.detailInvoiceValue.physicalQuantity,
        measurementUnit: itemToEdit.detailInvoiceValue.measurementUnit,
        metricPrefix: itemToEdit.detailInvoiceValue.metricPrefix,
        price: itemToEdit.detailInvoiceValue.price,
        quantity: itemToEdit.detailInvoiceValue.quantity,
        value: itemToEdit.detailInvoiceValue.value
      };
    }

    var customValidation = [
      {
        script: validateOnPriceChange.bind({
          invoiceValueId: isEdit ? itemToEdit.detailInvoiceValue._id : null,
          formObj: dataObj
        }),
        name: 'totalError',
        errorMessage: gettext(
          'Detail invoice values sum must be less than detail invoice book value.'
        )
      }
    ];

    priceConfiguration.customValidation = customValidation;
    quantityConfiguration.customValidation = customValidation;

    let onChange = setLabelOnPriceOrCountChange.bind({
      invoiceValueId: isEdit ? itemToEdit.detailInvoiceValue._id : null,
      formObj: dataObj,
      configuration: config.find(item => item.componentType == 'paragraph')
    });

    priceConfiguration.onChange = onChange;
    quantityConfiguration.onChange = onChange;
    onChange();

    SfeFormDialogService.openSfeFormDialog(
      isEdit,
      config,
      dataObj,
      isEdit
        ? gettext('Edit invoice detail value')
        : gettext('New invoice detail value')
    ).then(
      function(object) {
        if (object) {
          var postObject = {
            externalCode: object.externalCode,
            name: object.name,
            order: object.order,
            price: object.price,
            quantity: object.quantity,
            detailInvoice: vm.detailInvoiceId,
            physicalQuantity: object.physicalData.physicalQuantity,
            measurementUnit: object.physicalData.measurementUnit,
            metricPrefix: object.physicalData.metricPrefix,
            value: object.quantity * object.price,
            energyManagementGroup: object.energyManagementGroup
              ? object.energyManagementGroup._id
              : null,
            priceListItemGroup: object.priceListItemGroup
              ? object.priceListItemGroup._id
              : null
          };

          var promise;
          if (isEdit) {
            postObject = {
              ...postObject,
              order: itemToEdit.detailInvoiceValue.order,
              priceListItem: itemToEdit.detailInvoiceValue.priceListItem
            };

            promise = DetailInvoiceValueModel.update(
              { id: itemToEdit.detailInvoiceValue._id },
              postObject
            );
          } else {
            postObject = {
              ...postObject,
              order: vm.tableConfig.data.length
            };

            promise = DetailInvoiceValueModel.create(postObject);
          }
          promise.then(
            async function() {
              await updateMasterInvoice();
              ToastService.showToast(
                gettext('Invoice detail values successfully saved!')
              );
              init();
            },
            function(err) {
              AlertingService.Error(err);
            }
          );
        }
      },
      function() {}
    );
  }

  async function updateMasterInvoice() {
    try {
      await MasterInvoiceModel.update(
        { id: vm.masterInvoice },
        { invoiceStatus: 1 }
      );
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  function openCreateEditDialog(index) {
    if (vm.changingData) {
      var title =
        index == null
          ? gettextCatalog.getString('Create value')
          : gettextCatalog.getString('Update value');
      var textItem = {
        text: gettextCatalog.getString(
          'Are you sure you want to continue? All unsaved data will be lost.'
        ),
        type: 'text'
      };
      var actions = [
        {
          title: gettext('Cancel'),
          cancel: true,
          color: 'primary'
        },
        {
          title: gettext('Yes'),
          fn: () => {
            originalValues.forEach((item, index) => {
              vm.tableConfig.data[index].detailInvoiceValue.quantity =
                item.detailInvoiceValue.quantity;
              onQuantityChange(vm.tableConfig.data[index]);
            });
            $mdDialog.hide();
            createEditItemValue(index);
          },
          color: 'success'
        }
      ];
      InfoDialog.open(title, null, [textItem], actions);
    } else {
      createEditItemValue(index);
    }
  }
  /**
   * @description validate that values have required items.
   * @function
   * @param {Array} values - An array of values that need to be validated
   * @return {Array} an array of valid values
   */
  function validateValues(values) {
    return values.filter(function(value) {
      if (value && value.detailInvoiceValue) {
        return true;
      }
    });
  }
  let originalValues;
  /**
   * @description constructs values table.
   * calculates sum left
   * sets loading flags
   * @function
   */
  function constructTableValues() {
    var values = validateValues(vm.values);
    PhysicalCollectionService.returnMeasurementUnits().then(
      measurementUnits => {
        const detailInvoiceValues = values.map(detailInvoiceValue => {
          const measurementUnitObject = measurementUnits.find(item => {
            return (
              item._id === detailInvoiceValue.detailInvoiceValue.measurementUnit
            );
          });
          const measurementUnit = measurementUnitObject
            ? measurementUnitObject.symbol
            : '';
          const metricPrefix =
            TranslationService.GetCollectionById(
              'codelists.metricPrefixes',
              detailInvoiceValue.detailInvoiceValue.metricPrefix
            ) || {};
          const measurementUnitString =
            (metricPrefix.symbol || '') + measurementUnit;
          const currencyMeasurementUnitsString =
            (vm.currencySymbol || gettextCatalog.getString('unknown')) +
            '/' +
            measurementUnitString;
          detailInvoiceValue.detailInvoiceValue.value = StandardUtils.round(
            detailInvoiceValue.detailInvoiceValue.value,
            6
          );
          return {
            ...detailInvoiceValue,
            measurementUnitString,
            currencyMeasurementUnitsString
          };
        });

        vm.tableConfig.data = detailInvoiceValues.sort(function(a, b) {
          return a.detailInvoiceValue.order - b.detailInvoiceValue.order;
        });
        originalValues = angular.copy(vm.tableConfig.data);
        calculateSumLeft();
        vm.asyncIsExecuting = false;
        vm.changingData = false;
      }
    );
  }
  /**
   * @description init function fetches detail invoice values.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function init() {
    DetailInvoiceModel.custom
      .readView({
        detailInvoiceId: $state.params.invoiceDetailId,
        masterInvoiceId: $state.params.invoiceId
      })
      .then(function(res) {
        if (Array.isArray(res.data) && res.data[0]) {
          vm.values = res.data[0].detailInvoiceValues;
          constructTableValues();
        }
      });
  }

  /**
   * @description subtracts invoice detail values sum from invoice book value.
   * @function
   * @return {number}
   */
  function calculateSumLeft() {
    const valueSum = StandardUtils.round(getInvoiceDetailValuesSum(), 2);
    const result = vm.calculatedBookValue - valueSum;

    if (result != undefined && !isNaN(result)) {
      vm.leftSum = result;
    }
  }

  /**
   * @description calculates new value when quantity changes.
   * @function
   * @param {Object} invoiceDetailValue invoice detail value object
   */
  function onQuantityChange(invoiceDetailValue) {
    vm.changingData = true;
    var quantity = invoiceDetailValue.detailInvoiceValue.quantity;
    if (!quantity) {
      invoiceDetailValue.detailInvoiceValue.value = 0;
    } else {
      invoiceDetailValue.detailInvoiceValue.value = StandardUtils.round(
        quantity * invoiceDetailValue.detailInvoiceValue.price,
        6
      );
    }

    const sumOfCountersExist = vm.tableConfig.data.some(item => {
      if (
        item.priceListItemGroup &&
        item.priceListItemGroup.priceListItemGroupType === 2
      ) {
        return true;
      }
    });
    vm.tableConfig.data = vm.tableConfig.data.map(item => {
      const detailInvoiceValue = { ...item.detailInvoiceValue };
      if (
        detailInvoiceValue._id !== invoiceDetailValue.detailInvoiceValue._id
      ) {
        // set new quantity to detail invoice values that have same priceListItemGroup
        if (
          item.priceListItemGroup._id != null &&
          item.priceListItemGroup._id ===
            invoiceDetailValue.priceListItemGroup._id
        ) {
          detailInvoiceValue.quantity = quantity;
          detailInvoiceValue.value = quantity * detailInvoiceValue.price;
          return {
            ...item,
            detailInvoiceValue: {
              ...detailInvoiceValue,
              quantity,
              value: StandardUtils.round(quantity * detailInvoiceValue.price, 6)
            }
          };
        }
        return {
          ...item,
          detailInvoiceValue: {
            ...detailInvoiceValue,
            value: StandardUtils.round(
              detailInvoiceValue.quantity * detailInvoiceValue.price,
              6
            )
          }
        };
      }
      return {
        ...item
      };
    });

    if (
      invoiceDetailValue.priceListItemGroup &&
      invoiceDetailValue.priceListItemGroup.priceListItemGroupType === 1 &&
      sumOfCountersExist
    ) {
      const filtered = vm.tableConfig.data.filter(onlyUnique);
      const sumOfCounters = filtered.reduce((sum, item) => {
        if (
          item.priceListItemGroup &&
          item.priceListItemGroup.priceListItemGroupType === 1
        ) {
          return sum + item.detailInvoiceValue.quantity;
        }
        return sum;
      }, 0);

      if (sumOfCounters != undefined) {
        vm.tableConfig.data = vm.tableConfig.data.map(item => {
          if (
            item.priceListItemGroup &&
            item.priceListItemGroup.priceListItemGroupType === 2
          ) {
            return {
              ...item,
              detailInvoiceValue: {
                ...item.detailInvoiceValue,
                quantity: sumOfCounters,
                value: sumOfCounters * item.detailInvoiceValue.price
              }
            };
          }
          return { ...item };
        });
      }
    }

    var invoiceDetailsSum = StandardUtils.round(getInvoiceDetailValuesSum(), 2);
    vm.leftSum = StandardUtils.round(
      vm.calculatedBookValue - invoiceDetailsSum,
      2
    );
    isSavingEnabled = vm.leftSum > -1;
  }
  /**
   * @description uniq filter helper function.
   * @function
   * @param {Object} value test value
   * @param {number} index test value index
   * @param {Array} self array of objects we are testing
   * @return {bool}
   */
  function onlyUnique(value, index, self) {
    var itemIndex = self.findIndex(item => {
      if (item.priceListItemGroup && value.priceListItemGroup) {
        return item.priceListItemGroup._id === value.priceListItemGroup._id;
      }
    });
    return itemIndex == index;
  }

  /**
   * @description returns sum of values of invoiceDetailValues.
   * @function
   * @return {Number}
   */
  function getInvoiceDetailValuesSum() {
    if (vm.tableConfig.data && vm.tableConfig.data.length) {
      var value;
      return vm.tableConfig.data.reduce((sum, item) => {
        value = item.detailInvoiceValue ? item.detailInvoiceValue.value : 0;
        return sum + value;
      }, 0);
    }
  }
  /**
   * @description cancels editing new pricelist and restores original detail invoice values.
   * @function
   */
  function cancelChangingPriceList() {
    vm.editingItemPrice = false;
    priceListEditingStateChanged(vm.editingItemPrice);
    vm.tableConfig.data = originalDetailInvoiceItems;
  }

  /**
   * @description triggered by clicking on list save button. Updates invoice-list-items or creates new detail price list
   * @function
   */
  function saveChanges() {
    if (vm.editingItemPrice) {
      updatePriceListPrice();
      vm.editingItemPrice = false;
      priceListEditingStateChanged(vm.editingItemPrice);
    } else {
      // updates detail invoice value quantities according to table changes.
      updateInvoiceDetailPromise().then(
        async () => {
          await updateMasterInvoice();
          ToastService.showToast(
            gettext('Invoice detail values successfully saved!')
          );
          originalValues = angular.copy(vm.tableConfig.data);
          vm.changingData = false;
          isSavingEnabled = false;
        },
        err => {
          AlertingService.Error(err);
          // if saving new quantities fails list original quantities
          init();
          isSavingEnabled = false;
        }
      );
    }
  }
  /**
   * @description updates detail invoice with detail invoice values.
   * @function
   * @return {Promise}
   */
  function updateInvoiceDetailPromise() {
    const detailInvoiceValues = vm.tableConfig.data.map(
      (invoiceDetailValue, index) => ({
        transferToNextMonth:
          invoiceDetailValue.detailInvoiceValue.transferToNextMonth,
        energyManagementGroup:
          invoiceDetailValue.detailInvoiceValue.energyManagementGroup,
        name: invoiceDetailValue.detailInvoiceValue.name,
        measurementUnit: invoiceDetailValue.detailInvoiceValue.measurementUnit,
        metricPrefix: invoiceDetailValue.detailInvoiceValue.metricPrefix,
        physicalQuantity:
          invoiceDetailValue.detailInvoiceValue.physicalQuantity,
        price: invoiceDetailValue.detailInvoiceValue.price,
        priceListItemGroup:
          invoiceDetailValue.detailInvoiceValue.priceListItemGroup,
        quantity: invoiceDetailValue.detailInvoiceValue.quantity,
        value: invoiceDetailValue.detailInvoiceValue.value,
        priceListItem: invoiceDetailValue.priceListItem
          ? invoiceDetailValue.priceListItem._id
          : undefined,
        order: index
      })
    );
    return new Promise((resolve, reject) => {
      var apiObject = {
        energySourceType: vm.energySourceType,
        masterInvoice: vm.masterInvoice,
        measuringPoint: vm.measuringPoint,
        priceListDetail: vm.priceListDetail,
        readDate: vm.readDate,
        detailInvoiceValues
      };
      DetailInvoiceModel.update(
        {
          id: vm.detailInvoiceId
        },
        apiObject
      ).then(
        function(res) {
          resolve(res);
        },
        function(err) {
          reject(err);
        }
      );
    });
  }
  /**
   * @description fetches price list detail.
   * @function
   * @return {Promise}
   */
  function getPriceListDetail(priceListId) {
    var deferred = $q.defer();
    PriceListDetailModel.read({
      id: priceListId
    }).then(
      function(res) {
        deferred.resolve(res.data);
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );

    return deferred.promise;
  }

  /**
   * @description updates current invoice detail values accordin to values in the table.
   * @function
   * @param {Function} callback callback function
   */
  function updateInvoiceDetailPriceListValues(newPricelistItems, callback) {
    async.each(
      vm.tableConfig.data,
      function(item, innerCallback) {
        var updateObject = {
          priceListItem: item.priceListItem
            ? item.priceListItem._id
            : undefined,
          detailInvoice: vm.detailInvoiceId,
          quantity: item.detailInvoiceValue.quantity,
          price: item.detailInvoiceValue.price,
          value: item.detailInvoiceValue.value
        };

        if (newPricelistItems && item.priceListItem) {
          var foundItem = newPricelistItems.find(newPricelistItem => {
            return (
              newPricelistItem.originalPricelistItemId ===
              item.priceListItem._id
            );
          });
          updateObject = {
            ...updateObject,
            priceListItem: foundItem._id
          };
        }
        DetailInvoiceValueModel.update(
          {
            id: item.detailInvoiceValue._id
          },
          updateObject
        ).then(
          function() {
            innerCallback();
          },
          function(err) {
            AlertingService.Error(err);
            innerCallback();
          }
        );
      },
      function() {
        callback();
      }
    );
  }

  /**
   * @description updates current invoice detail with new pricelist detail id.
   * @function
   * @param {string} priceListDetailId new price list detail id
   * @param {function} callback callback function
   */
  function updateInvoiceDetail(priceListDetailId, newPricelistItems, callback) {
    var apiObj = {
      priceListDetail: priceListDetailId
    };
    DetailInvoiceModel.update(
      {
        id: vm.detailInvoiceId
      },
      apiObj
    ).then(
      function() {
        callback(null, newPricelistItems);
      },
      function(err) {
        AlertingService.Error(err);
        callback(null, newPricelistItems);
      }
    );
  }
  /**
   * @description finds valid pricelist detail by masterInvoice id in measuring point id.
   * fetches pricelist details by found id
   * Sets valid pricelist detail to new values
   * Updates invoice detail with the new price list detail id
   * @function
   * @param {function} callback
   */
  function createInvoiceDetailPriceListItem(newPricelistItems, callback) {
    findPriceListDetailService
      .findValidPricelist(vm.masterInvoice, vm.measuringPoint, vm.serviceDateTo)
      .then(
        function(priceListId) {
          if (vm.validPriceListDetail._id !== priceListId) {
            getPriceListDetail(priceListId).then(function(priceList) {
              vm.validPriceListDetail = priceList;
              vm.priceListDetail = priceList._id;
            });
            updateInvoiceDetail(priceListId, newPricelistItems, callback);
          } else {
            callback(null, null);
          }
        },
        function(err) {
          callback(err);
        }
      );
  }

  /**
   * @description cteates new pricelist itens.
   * @function
   * @param {Object} priceListDetailItem contains new pricelsit detail value values
   * @param {String} priceListDetailId pricelist detail id
   * @return {Promise}
   */
  function createPriceListItem(
    originalPricelistItem,
    priceListDetailItem,
    priceListDetailId
  ) {
    var deferred = $q.defer();
    var apiObject;
    if (priceListDetailItem) {
      apiObject = {
        priceListDetail: priceListDetailId,
        name: priceListDetailItem.detailInvoiceValue.name,
        description: priceListDetailItem.priceListItem.description,
        order: priceListDetailItem.priceListItem.order,
        price: priceListDetailItem.detailInvoiceValue.price,
        energyManagementGroup:
          priceListDetailItem.detailInvoiceValue.energyManagementGroup,
        priceListItemGroup:
          priceListDetailItem.detailInvoiceValue.priceListItemGroup,
        transferToNextMonth:
          priceListDetailItem.priceListItem.transferToNextMonth,
        physicalQuantity:
          priceListDetailItem.detailInvoiceValue.physicalQuantity,
        measurementUnit: priceListDetailItem.detailInvoiceValue.measurementUnit,
        metricPrefix: priceListDetailItem.detailInvoiceValue.metricPrefix
      };
    } else if (originalPricelistItem) {
      apiObject = {
        priceListDetail: originalPricelistItem.priceListDetail,
        name: originalPricelistItem.name,
        description: originalPricelistItem.description,
        order: originalPricelistItem.order,
        price: originalPricelistItem.price,
        energyManagementGroup: originalPricelistItem.energyManagementGroup,
        priceListItemGroup: originalPricelistItem.priceListItemGroup,
        transferToNextMonth: originalPricelistItem.transferToNextMonth,
        physicalQuantity: originalPricelistItem.physicalQuantity,
        measurementUnit: originalPricelistItem.measurementUnit,
        metricPrefix: originalPricelistItem.metricPrefix
      };
    }

    if (apiObject) {
      PriceListItemModel.create(apiObject).then(
        function(res) {
          // add original pricelist item id to find correct item when detail invoice valuse will be updated
          var result = {
            ...res.data,
            originalPricelistItemId:
              priceListDetailItem && priceListDetailItem.priceListItem
                ? priceListDetailItem.priceListItem._id
                : undefined
          };
          deferred.resolve(result);
        },
        function(err) {
          AlertingService.Error(err);
          deferred.reject();
        }
      );
    } else {
      deferred.resolve();
    }
    return deferred.promise;
  }

  /**
   * @description creates new pricelist items.
   * @function
   * @param {String} priceListDetailId pricelist detail id
   * @param {function} callback
   */
  function createNewPriceListItems(priceListDetailId, callback) {
    fetchCurrentPricelistDetailItems(vm.priceListDetail).then(
      pricelistDetailItems => {
        var foundItem;
        const promises = pricelistDetailItems.map(pricelistDetailItem => {
          foundItem = vm.tableConfig.data.find(item => {
            return (
              item.detailInvoiceValue.priceListItem === pricelistDetailItem._id
            );
          });
          return createPriceListItem(
            pricelistDetailItem,
            foundItem,
            priceListDetailId
          );
        });
        Promise.all(promises).then(newPriceListItems => {
          callback(null, newPriceListItems);
        });
      }
    );
  }
  /**
   * @description fetches pricelist detail items.
   * @function
   * @param {string} priceListDetailId pricelist detail id
   * @return {Promise}
   */
  function fetchCurrentPricelistDetailItems(priceListDetailId) {
    var deferred = $q.defer();
    PriceListItemModel.read({ priceListDetail: priceListDetailId }).then(
      res => {
        deferred.resolve(res.data);
      },
      err => {
        deferred.reject(err);
      }
    );
    return deferred.promise;
  }

  /**
   * @description creates new pricelist detail.
   * @function
   * @param {function} callback function
   */
  function createPriceListDetail(callback) {
    // set valid from to old pricelist detail validTo + 1 day
    var validFrom = new Date(
      newPriceListItem.validTo.setDate(newPriceListItem.validTo.getDate() + 1)
    );
    PriceListDetailModel.create({
      priceListMaster: newPriceListItem.priceListMaster,
      validFrom: validFrom.setHours(0, 0, 0, 0)
    }).then(
      function(res) {
        callback(null, res.data._id);
      },
      function(err) {
        AlertingService.Error(err);
        callback(err);
      }
    );
  }
  /**
   * @description update current pricelist detail dates.
   * @function
   * @param {function} callback function
   */
  function updateCurrentPriceListDetail(callback) {
    const validFrom = new Date(newPriceListItem.validFrom);
    const validTo = new Date(newPriceListItem.validTo);
    if (validFrom === 'Invalid Date' || validTo === 'Invalid Date') {
      AlertingService.Error('Failed to update pricelist dates');
      callback(true);
    } else {
      newPriceListItem.validFrom = validFrom;
      newPriceListItem.validTo = validTo;
      PriceListDetailModel.update(
        {
          id: vm.priceListDetail
        },
        {
          priceListMaster: newPriceListItem.priceListMaster,
          validFrom: newPriceListItem.validFrom.setHours(0, 0, 0, 0),
          validTo: newPriceListItem.validTo.setHours(23, 59, 59, 999)
        }
      ).then(
        function() {
          callback();
        },
        function(err) {
          AlertingService.Error(err);
          callback(err);
        }
      );
    }
  }

  /**
   * @description updates pricelist prices
   * 1. updates current pricelist detail with the new dates
   * 2. creates new pricelist detail with validFrom = currentPriceListDetail.validTo + 1 day
   * 3. creates new pricelist items
   * 4. update detailInvoice with new priceListDetail
   * 5. updates detail invoice with new pricelist detail invoice values
   * 6. triggers re init
   * @function
   */
  function updatePriceListPrice() {
    if (!newPriceListItem) {
      return;
    }

    var waterfall = [
      updateCurrentPriceListDetail,
      createPriceListDetail,
      createNewPriceListItems,
      createInvoiceDetailPriceListItem,
      updateInvoiceDetailPriceListValues
    ];
    vm.asyncIsExecuting = true;
    async.waterfall(waterfall, function() {
      vm.editingItemPrice = false;
      isSavingEnabled = false;
      priceListEditingStateChanged(vm.editingItemPrice);
      init();
      vm.asyncIsExecuting = false;
    });
  }

  /**
   * @description triggered by clicking change pricelist button on the list.
   * opens date selector
   * enables changing price list prices
   * when save is clicked creates new pricelist detail
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function changePriceList() {
    getPriceListDetail(vm.priceListDetail).then(function(priceListDetail) {
      var validTo = priceListDetail.validTo
        ? DateTime.fromJSDate(new Date(priceListDetail.validTo)).endOf('day')
        : DateTime.fromJSDate(new Date(vm.readDate))
          .minus({ day: 1 })
          .endOf('day');
      var validFrom = DateTime.fromJSDate(new Date(priceListDetail.validFrom));
      var duration = validTo.diff(validFrom);
      // check if dateTo < dateFrom set dateTo to dateFrom + 1 day
      if (duration.isValid && duration.values.milliseconds < 0) {
        validTo = validFrom.plus({ day: 1 }).endOf('day');
      }

      DateTimeDialogService.openDialog({
        title: gettext('Select new valid dates'),
        dateOnly: true,
        initialValues: {
          validFrom: priceListDetail.validFrom,
          validTo: validTo.toJSDate()
        },
        required: {
          validFrom: true,
          validTo: true
        }
      }).then(function(returnedTime) {
        if (!returnedTime) {
          return;
        }
        originalDetailInvoiceItems = angular.copy(vm.tableConfig.data);
        returnedTime.priceListMaster = priceListDetail.priceListMaster;
        newPriceListItem = returnedTime;
        vm.editingItemPrice = true;
        priceListEditingStateChanged(vm.editingItemPrice);
      });
    });
  }
}
