/**
 * @ngdoc model
 * @name common.CounterTypeModel
 * @description Model for counter types.
 * @property {Function} custom.readItemView - returns a data for a single view
 * @property {Function} custom.readAllLang - returns data, but where all the multilanguage options return everything
 */
CounterTypeModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function CounterTypeModel(ModelUtilities, CrawlerMethods) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'counter-types'
  };

  const networkConfigurationAllLang = {
    host: 'api',
    path: 'counter-types',
    allLang: true
  };

  const crawlerConfiguration = {
    entity: 'counter-types',
    method: 'read',
    populate: 'energySourceType,registers.tariff,registers.timeSeriesType'
  };
  const formConfiguration = {
    entity: 'counter-types',
    method: 'custom.readAllLang',
    populate: 'energySourceType,registers.tariff,registers.timeSeriesType'
  };

  const crawlerConfigurationList = {
    entity: 'counter-types',
    method: 'read',
    populate: 'energySourceType'
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readItemView: CrawlerMethods.crawler(crawlerConfiguration),
      list: CrawlerMethods.crawler(crawlerConfigurationList),
      readForm: CrawlerMethods.crawler(formConfiguration),
      readAllLang: ModelUtilities.crud(
        'GET',
        networkConfigurationAllLang,
        modelStorage
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default CounterTypeModel;
