/* eslint-disable indent */

CostCentreConfiguration.$inject = [
  'gettext',
  '$q',
  'TranslationService',
  'HierarchyTreeDialog'
];

function CostCentreConfiguration(
  gettext,
  $q,
  TranslationService,
  HierarchyTreeDialog
) {
  var configuration = {
    listId: 'costCentreListId',
    entity: {
      singular: gettext('Cost Centre'),
      plural: gettext('Cost Centres')
    },
    api: {
      query: {
        entity: 'cost-centres',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      title: gettext('Name'),
      link: {
        state: 'company-resources-cost-centres-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'company-resources-cost-centres-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'info_outline',
        action: function(item) {
          HierarchyTreeDialog.openDialog(item, 'cost-centres');
        }
      },
      {
        icon: 'mode_edit',
        link: {
          state: 'company-resources-cost-centres-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'cost-centres',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Description'),
        param: 'description',
        type: 'text'
      },
      {
        title: gettext('Code'),
        param: 'code',
        type: 'code'
      },
      {
        title: gettext('External code'),
        param: 'externalCode',
        type: 'code'
      },
      {
        title: gettext('Cost Centre Type'),
        param: 'costCentreType',
        type: 'text'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        query: {
          entity: 'company-structure-nodes',
          method: 'read'
        },
        entity: 'company-structure-nodes',
        display: gettext('Company Structure Node Parent'),
        param: 'companyStructureNode',
        displayList: ['name', 'code'],
        type: 'searchSelect',
        refreshParameterName: 'name',
        selectedParamName: '_id'
      },
      {
        query: null,
        display: gettext('Code'),
        param: 'code',
        type: 'string'
      },
      {
        display: gettext('External Code'),
        param: 'externalCode',
        type: 'string'
      },
      {
        filterFn: items =>
          items.filter(item => {
            if (item.allowedEntities) {
              return item.allowedEntities.indexOf(4) >= 0;
            }
          }),
        collection: TranslationService.GetCollection(
          'codelists.costCentreTypes'
        ),
        display: gettext('Cost Centre Type'),
        param: 'costCentreType',
        valueField: 'id'
      }
    ],
    sort: [
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'name DESC',
        params: '-name'
      },
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      },
      {
        display: 'code DESC',
        params: '-code'
      },
      {
        display: 'code ASC',
        params: 'code'
      }
    ]
  };

  /*
   *
   *   ENRICH
   *
   */

  function Enrich(item) {
    var deferred = $q.defer();
    if (item.costCentreType) {
      item.costCentreType = TranslationService.GetCollectionById(
        'codelists.costCentreTypes',
        item.costCentreType
      );
      item.costCentreType =
        item.costCentreType && item.costCentreType.name
          ? item.costCentreType.name
          : '';
      deferred.resolve();
    }
    return deferred.promise;
  }

  return configuration;
}
export default CostCentreConfiguration;
