TangoAssetAvailabilityDataXService.$inject = [
  'SfeDataXTranslations',
  'TangoTimeSeriesDataXHelperService',
  'GetTimeSeriesProcessingByTimestampService',
  'FilterDialog',
  'gettextCatalog',
  'DateLocalizationService'
];
/**
 * @ngdoc service
 * @name companyResources.TangoAssetAvailabilityDataXService
 * @description returns asset Availability Properties
 * @property {function} create
 * @property {function} TangoAssetAvailabilityDataXService
 */
export default function TangoAssetAvailabilityDataXService(
  SfeDataXTranslations,
  TangoTimeSeriesDataXHelperService,
  GetTimeSeriesProcessingByTimestampService,
  FilterDialog,
  gettextCatalog,
  DateLocalizationService
) {
  /**
   * @description returns sfe-data-x configuration to display asset availability ts chart.
   * @function
   * @param {Object} assetAvailability
   * @param {Object} apiDataX chart api object
   * @return {Object}
   */
  async function get(assetAvailability, apiDataX) {
    let { series } = await TangoTimeSeriesDataXHelperService.get({
      timeseriesId: assetAvailability.timeSeries._id
    });
    const dataVisualizationConfig =
      assetAvailability.timeSeries.dataVisualizationConfig;
    let timeZone = series
      .map(item => item.timeZone)
      .filter((value, index, items) => items.indexOf(value) == index);
    if (timeZone.length == 1 && timeZone[0] != null) {
      timeZone = timeZone[0];
    } else {
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    const filter = {
      from: new Date(assetAvailability.startOfAvailability).getTime(),
      limit: 36000
    };
    return buildDataXConfiguration({
      series,
      timeZone,
      dataVisualizationConfig,
      filter,
      apiDataX
    });
  }
  /**
   * @description returns configuration to validate filter from date.
   * @function
   * @param {Date} minFrom the earliest from date possible
   * @return {Object}
   */
  function getFilterFromValidator(minFrom) {
    let formattedMinDate = DateLocalizationService.LocalizationDateFn(minFrom);
    return {
      from: {
        minFrom: {
          fn: modelValue => {
            if (Array.isArray(modelValue) && modelValue.length > 0) {
              return modelValue[0] >= new Date(minFrom);
            }
            return true;
          },
          text: gettextCatalog.getString(
            `From date can not be prior to start date of asset availability (${formattedMinDate})`
          )
        }
      }
    };
  }
  /**
   * @description builds data-x chart configuration.
   * @function
   * @param {Object} config
   * @return {Object}
   */
  const buildDataXConfiguration = ({
    series,
    timeZone,
    dataVisualizationConfig,
    filter,
    apiDataX
  }) => {
    let sortSettings = {};
    //TIME SERIES GROUP HAS DATA VISUALIZATION CONFIG THAT IS USED TO SET SORT BY SUM PROPS
    if (dataVisualizationConfig) {
      let sortType = 1; //NONE
      if (dataVisualizationConfig.sortedBySumOfValues) {
        sortType = 10; //SORT BY SUM
      }
      sortSettings = TangoTimeSeriesDataXHelperService.getChartSortBySumSettings(
        { sortType, chartType: dataVisualizationConfig.defaultChartType }
      );
    }
    const axis = TangoTimeSeriesDataXHelperService.processAxes(
      { series },
      sortSettings
    );
    const processedSeries = TangoTimeSeriesDataXHelperService.processSeries(
      series,
      axis,
      false,
      { step: 'left' }
    );
    const validators = getFilterFromValidator(filter.from);
    const configuration = {
      axis,
      series: processedSeries,
      mode: { chart: true, grid: true },
      timeZone: timeZone,
      chart: {
        legend: series.length > 1,
        legendAlign: 'center',
        markers: true,
        dataLabels: false,
        axisYTitle: true,
        axisXTitle: true,
        height: 373
      },
      grid: {
        height: 373,
        extraColumns: [],
        actions: [],
        seriesAction: {
          icon: 'info_outline',
          fn: params => {
            const id = params.colDef.field;
            let selectedSeries = series.find(item => item.id === id);
            if (selectedSeries != null && typeof selectedSeries == 'object') {
              GetTimeSeriesProcessingByTimestampService.openExtendedData(
                params,
                selectedSeries
              );
            }
          }
        }
      },
      translations: SfeDataXTranslations.get(),
      callbacks: {
        filter: () => {
          FilterDialog.open(apiDataX, null, validators);
        },
        export: TangoTimeSeriesDataXHelperService.getExportDataFunction(
          apiDataX
        )
      }
    };

    configuration.filter = () => filter;

    return configuration;
  };

  return {
    get
  };
}
