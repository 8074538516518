module.exports = {
  entity: 'analyses',
  path: 'analyses',
  name: {
    en: 'Analyses',
    sl_SI: 'Analize'
  },
  networkModel: {
    entity: 'analyses',
    read: 'read'
  },
  scope: 'tis_be:analysis',

  dialog: {
    title: {
      en: 'Select analysis',
      sl_SI: 'Izberite analizo'
    },
    listInfo: {
      en: 'Select analysis from the list below',
      sl_SI: 'Izberite analizo s spodnjega seznama'
    }
  }
}
