import template from './sfe-tree-sideview.html';

export default {
  template,
  bindings: {
    selected: '<',
    isLoading: '<'
  },
  controller: sfeTreeSideviewController,
  controllerAs: 'vm'
};

sfeTreeSideviewController.$inject = [
  '$mdDialog',
  '$stateParams',
  'CompanyResourcesViewData',
  '$scope',
  'AlertingService'
];

function sfeTreeSideviewController(
  $mdDialog,
  $stateParams,
  CompanyResourcesViewData,
  $scope,
  AlertingService
) {
  var vm = this;

  /**
   * @description Returns the object used for state redirection.
   * @function
   * @param {Object} property One of the properties to be displayed in displayfields (the one that has link: true)
   * @return {Object}
   */
  function returnParamText(property) {
    var parameterObject = property.paramObject || {};
    if (property.param && property.paramValue) {
      parameterObject[property.param] = property.paramValue;
    }
    return parameterObject;
  }

  var formWatcher = $scope.$watch('vm.selected', async () => {
    vm.link = undefined;
    if (vm.selected) {
      vm.isLoading = true;
      var typeParamName;
      if (vm.selected.icon != undefined) {
        typeParamName = 'icon';
      } else {
        typeParamName = 'route';
      }
      try {
        const properties = await CompanyResourcesViewData.getProperties(
          vm.selected,
          typeParamName
        );
        vm.selected.properties = properties.map(function(property) {
          let params;
          if (property.link) {
            params = returnParamText(property);
          }
          return {
            ...property,
            params
          };
        });
      } catch (err) {
        AlertingService.Error(err);
      }
      vm.isLoading = false;
      let queryObj = {};
      if (vm.selected.param) {
        queryObj[vm.selected.param] = vm.selected._id;
      } else {
        queryObj.id = vm.selected._id;
      }
      const showLink = !Object.keys(queryObj).every(
        key => queryObj[key] === $stateParams[key]
      );
      if (showLink) {
        const queryString = JSON.stringify(queryObj);
        vm.link = `${vm.selected.state}(${queryString})`;
      }
      if (vm.selected && vm.selected.properties) {
        vm.isLoading = false;
      }
      $scope.$apply();
    }
  });

  $scope.$on('$destroy', function() {
    if (formWatcher) {
      formWatcher();
    }
  });

  vm.closeDialog = function() {
    $mdDialog.cancel();
  };
}
