import './sfe-pagination-2.scss';
import template from './sfe-pagination-2.component.html';
/**
 * @ngdoc component
 * @name common.sfePagination2
 * @description barebones pagination component
 * @param {Object} paginationConfiguration - object containing page, limit, total and sort type.
 * @param {Object} paginationApi - object two which we pass functions from this component to parent component
 * @param {boolean} loading - Boolean telling us if we are loading data
 * @example
 * <sfe-pagination-2
 *   pagination-configuration="vm.paginationCofiguration"
 *   pagination-api="vm.paginationApi"
 *   loading="vm.dataIsLoading"
 * ></sfe-pagination-2>
 */
export default {
  restrict: 'E',
  template,
  bindings: {
    paginationConfiguration: '<',
    paginationApi: '=',
    loading: '<'
  },
  controller: sfePagination2Controller,
  controllerAs: 'vm'
};

sfePagination2Controller.$inject = ['$scope'];

function sfePagination2Controller() {
  var vm = this;
  vm.next = next;
  vm.previous = previous;
  vm.changePageOrLimit = changePageOrLimit;
  vm.limitOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];
  vm.$onChanges = change => {
    if (change.loading.isFirstChange()) {
      vm.paginationApi.startPaginationReset = startPaginationReset;
      vm.paginationApi.endPaginationReset = endPaginationReset;
    }
    if (change.loading === false) {
      resetMinAndMaxValue();
    }
  };
  /**
   * @description function called when pagination is being reset back to page 1.
   * @function
   */
  function startPaginationReset() {
    vm.paginationConfiguration.page = 1;
  }
  /**
   * @description function called when data has been loaded and we want to recalculate pagination values.
   * @function
   * @param {boolean} binding/fetchFailed - tells us if the fetch has failed (and as such we don't want to recalculate pagination values)
   * @param {integer} paginationTotal - new pagination total
   * @return {dataType}
   */
  function endPaginationReset(fetchFailed, paginationTotal) {
    if (!fetchFailed) {
      resetMinAndMaxValue(paginationTotal);
    }
  }
  /**
   * @description function called when we move the pagination 1 page forwards.
   * @function
   */
  function next() {
    vm.paginationConfiguration.page++;
    resetMinAndMaxValue();
    vm.paginationApi.paginationChange();
  }
  function previous() {
    vm.paginationConfiguration.page--;
    vm.paginationApi.paginationChange();
    vm.min =
      (vm.paginationConfiguration.page - 1) * vm.paginationConfiguration.limit +
      1;
    const max =
      vm.paginationConfiguration.page * vm.paginationConfiguration.limit;
    vm.max = max;
    vm.disableMax = false;
  }
  /**
   * @description function called when we change the page
   * @function
   */
  function pageChange() {
    //page cannot be set bellow 1
    if (vm.paginationConfiguration.page < 1) {
      vm.paginationConfiguration.page = 1;
    }
    //page cannot be set above the maximum number of pages
    if (
      vm.paginationConfiguration.total <
      vm.paginationConfiguration.page * vm.paginationConfiguration.limit
    ) {
      vm.paginationConfiguration.page = Math.floor(
        vm.paginationConfiguration.total / vm.paginationConfiguration.limit
      );
    }
  }
  /**
   * @description function called when we want to recalculate values visible in the component (from to number od total)
   * @function
   * @param {integer} paginationTotal - new pagination total
   */
  function resetMinAndMaxValue(paginationTotal) {
    if (Number.isInteger(paginationTotal)) {
      vm.paginationConfiguration.total = paginationTotal;
    }
    vm.min =
      (vm.paginationConfiguration.page - 1) * vm.paginationConfiguration.limit +
      1;
    const max =
      vm.paginationConfiguration.page * vm.paginationConfiguration.limit;
    vm.max =
      max < vm.paginationConfiguration.total
        ? max
        : vm.paginationConfiguration.total;
    vm.disableNext = max >= vm.paginationConfiguration.total ? true : false;
  }
  /**
   * @description function called when we change the pagination page or number of items we want to display.
   * @function
   * @param {boolean} limitChanged - tells us, if what we are changing is the limit (opposed to just the page)
   */
  function changePageOrLimit(limitChanged) {
    if (limitChanged) {
      startPaginationReset();
    } else {
      pageChange();
    }
    resetMinAndMaxValue();
    vm.paginationApi.paginationChange();
  }
}
