export class RouteManager {
  constructor() {
    this.routes = [];
  }

  state(name, config) {
    this.routes.push({
      name,
      config
    });
    return this;
  }
}

export function gettext(text) {
  return text;
}

export function GenerateRouteConfig(routes) {
  RouteConfig.$inject = [
    '$stateProvider',
    '$mdThemingProvider',
    'themingProvider'
  ];

  function RouteConfig($stateProvider, $mdThemingProvider, themingProvider) {
    routes.forEach(({ name, config }) => {
      $stateProvider.state(name, config);
    });

    /* Theming */
    var palettes = themingProvider.$get().palettes();
    _.each(palettes, function(palleteProp, name) {
      $mdThemingProvider.definePalette(name, palleteProp);
    });
    var themes = themingProvider.$get().themes();
    _.each(themes, function(themeProp, name) {
      $mdThemingProvider
        .theme(name)
        .primaryPalette(themeProp.primary)
        .warnPalette(themeProp.warn)
        .accentPalette(themeProp.accent);
    });
    $mdThemingProvider.alwaysWatchTheme(true);
    $mdThemingProvider.setDefaultTheme(themingProvider.defaultTheme);
    themingProvider.setTheme('tango', themingProvider.defaultTheme);
  }
  return RouteConfig;
}
