import './privilege-grid-item.scss';
import template from './privilege-grid-item.component.html';

export default {
  template,
  bindings: {
    item: '='
  },
  controllerAs: 'vm',
  controller: SfePrivilegeGridItemController
};

SfePrivilegeGridItemController.$inject = [
  'CrudToastFactory',
  'gettext',
  'AlertingService'
];

function SfePrivilegeGridItemController(
  CrudToastFactory,
  gettext,
  AlertingService
) {
  const vm = this;
  vm.toggleAccess = toggleAccess;
  vm.selectAllInTreeEntityChanged = selectAllInTreeEntityChanged;

  vm.accessKeys = [
    gettext('create'),
    gettext('read'),
    gettext('update'),
    gettext('delete'),
    gettext('list')
  ];

  vm.crudFilter = crudFilter;

  function crudFilter(item) {
    var order;
    switch (item) {
    case 'create':
      order = 1;
      break;
    case 'read':
      order = 2;
      break;
    case 'update':
      order = 3;
      break;
    case 'delete':
      order = 4;
      break;
    case 'list':
      order = 5;
      break;
    }

    return order;
  }

  async function toggleAccess() {
    let notTrue;
    vm.accessKeys.forEach(function(element) {
      if (!vm.item.access[element]) {
        notTrue = true;
      }
    });

    if (vm.item.updateStaticAccess) {
      try {
        await vm.item.updateStaticAccess();
        CrudToastFactory.toast('update');
      } catch (err) {
        AlertingService.Error(err);
      }
    }

    vm.item.allAccess = !notTrue;
  }

  async function selectAllInTreeEntityChanged() {
    vm.accessKeys.forEach(function(element) {
      vm.item.access[element] = vm.item.allAccess;
    });

    if (vm.item.updateStaticAccess) {
      try {
        await vm.item.updateStaticAccess();
        CrudToastFactory.toast('update');
      } catch (err) {
        AlertingService.Error(err);
      }
    }
  }
}
