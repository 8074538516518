export default function() {
  return {
    restrict: 'A',
    controllerAs: '$selectSearchInput',
    bindToController: {},
    controller: searchSelectInputController
  };
}

searchSelectInputController.$inject = ['$element', '$scope', '$timeout'];

function searchSelectInputController($element, $scope, $timeout) {
  var topOption = null;
  var target, prevIsHeader, menu;

  this.$postLink = function $postLink() {
    menu = $element.closest('.md-select-menu-container');
    var focusSearchWatcher = $scope.$watch(
      function focusSearchCheck() {
        return menu.hasClass('md-active') ? menu[0].id : undefined;
      },
      function focusSearch(activeMenuId) {
        if (activeMenuId) {
          $element.focus();
        }
      }
    );

    var collectionWatcher = $scope.$watchCollection(
      function() {
        return menu
          .find('md-option')
          .toArray()
          .map(function(option) {
            return option.id;
          });
      },
      function(ids) {
        if (ids.length > 0 && menu.hasClass('md-active')) {
          var jTop = menu.find('md-option').eq(0);
          if (topOption && topOption !== jTop[0]) {
            angular.element(topOption).off('keydown', upEventListener);
          }
          topOption = jTop[0];
          jTop.on('keydown', upEventListener);
          $timeout(function() {
            $element.focus();
          }, 1000);
          $scope.$on('$destroy', function() {
            jTop.off('keydown');
            jTop = null;
          });
        }
      }
    );

    $scope.$on('$destroy', function() {
      if (focusSearchWatcher) {
        focusSearchWatcher();
      }
      if (collectionWatcher) {
        collectionWatcher();
      }
      $element.off('keydown');
      menu = null;
    });

    var upEventListener = function onOptionNavigate(event) {
      var KEYCODE_UP = 38;
      if (event.keyCode === KEYCODE_UP) {
        target = angular.element(event.target);
        prevIsHeader = target.prev().is('md-select-header');
        if (!prevIsHeader) {
          prevIsHeader =
            target.prev()[0] == null &&
            target.parent().is('.search-select-options') &&
            target
              .parent()
              .prev()
              .is('md-select-header');
        }
        if (prevIsHeader) {
          $element.focus();
          // DISABLES SCROLL UP
          event.stopPropagation();
        }
        event.preventDefault();
      }
      /*************
      MEMORY CLEANUP
      *************/
      target = null;
      prevIsHeader = null;
    };

    $element.on('keydown', function onSearchNavigate(event) {
      var KEYCODE_DOWN = 40;
      if (event.keyCode === KEYCODE_DOWN) {
        if (menu.hasClass('md-active')) {
          menu
            .find('md-option')
            .eq(0)
            .focus();
          // DISABLES SCROLL DOWN
          event.preventDefault();
        }
      }
      event.stopPropagation();
    });
  };
}
