import './styles/all.scss';
import routes from './core.routes';
import RouteConfig from './configs/route.config';
import mdDialogConfig from './configs/mdDialog.config';
import EntityForestConstant from './constants/entity-forest.constant';
import FaviconConstant from './constants/favicon.constant';
import IconListConstant from './constants/icon-list.constant';
import SettingsConstant from './services/settings.constant';
import ErrorStateController from './pages/error-state/error-state.controller';
import RedirectController from './pages/redirect/redirect.controller';
import HeaderController from './pages/header/header.client.controller';
import HomeController from './pages/home/home.client.controller';
import MessagesController from './pages/messages/messages.controller';
import SettingsController from './pages/settings/settings.controller';
import NavigationDialogController from './pages/navigation-dialog/navigation-dialog.controller';
import SfeNotification from './directives/sfe-notification/sfe-notification.component';
import SfeSideMenuComponent from './directives/sfe-side-menu/sfe-side-menu.component';
import MainThemeDirective from './directives/main-theme/main-theme.directive';
import placeholderDirective from './directives/placeholder/placeholder.client.directive';
import StorageService from './factories/Storage.service';
import NavigationMenuService from './factories/navigation-menu.service';
import GisAuthInterceptor from './factories/gis-auth.interceptor';
import HttpRequestTimeoutInterceptor from './factories/http-request-timout-interceptor.client.service';
import HeaderDirective from './pages/header/header.directive';
import ThemingService from './services/theming.service';
import UtilService from './services/utilService.service';
import TranslationService from './services/Translation.service';
import MetadataService from './services/Metadata.service';
import LocalizationService from './services/Localization.service';
import AuthorizationToEditService from './services/AuthorizationToEdit.service';
import HttpPendingRequestsService from './services/HttpPendingRequests.service';
import GisAuth from './services/GisAuth.service';
import EntitiesService from './services/Entities.service';
import ThemingProvider from './providers/theming.provider';
import CoreRun from './runs/core.run';
import RegisterTemplate from './runs/registerTemplate.run';
import translations from '@smartis/tis-translations';
import codelists from '@smartis/tis-codelist';

export default {
  routes,
  configs: {
    RouteConfig,
    mdDialogConfig
  },
  constants: {
    codelistsConstant: codelists,
    entityForestConstant: EntityForestConstant,
    faviconConstant: FaviconConstant,
    iconListConstant: IconListConstant,
    translationsConstant: translations
  },
  controllers: {
    ErrorStateController,
    RedirectController,
    HeaderController,
    HomeController,
    MessagesController,
    SettingsController,
    NavigationDialogController
  },
  directives: {
    mainTheme: MainThemeDirective,
    placeholder: placeholderDirective,
    sfeNotification: SfeNotification,
    sfeSideMenu: SfeSideMenuComponent,
    sfeApplicationHeader: HeaderDirective
  },
  factories: {
    StorageService,
    GisAuthInterceptor,
    HttpRequestTimeoutInterceptor,
    NavigationMenuService
  },
  services: {
    $theming: ThemingService,
    settingsConstants: SettingsConstant,
    utilService: UtilService,
    TranslationService,
    MetadataService,
    LocalizationService,
    AuthorizationToEditService,
    HttpPendingRequestsService,
    GisAuth,
    EntitiesService
  },
  providers: {
    theming: ThemingProvider
  },
  runs: {
    CoreRun,
    RegisterTemplate
  }
};
