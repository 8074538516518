CronService.$inject = ['gettext', 'gettextCatalog'];
/**
 * @ngdoc service
 * @name common.CronService
 * @description service that validates and creates strings out of cron expressions
 * @property {function} GetCronLiteral - See GetCronLiteral method.
 * @property {function} IsEveryTimeCron - See IsEveryTimeCron method.
 * @property {function} IsIntervalCron - See IsIntervalCron method
 * @property {function} ValidateCron - See ValidateCron method.
 */
export default function CronService(gettext, gettextCatalog) {
  /**
   * @memberof common.CronService
   * @description Function that creates a human readable string from a cron expression
   * @param {string} cron - a cron expression
   * @return {string} human readable string
   */
  function GetCronLiteral(cron) {
    if (!cron) {
      return '';
    }
    var literal = 'Schedule';
    var cronArray = cron.split(' ');
    var cronTypes = ['second', 'minute', 'hour', 'day', 'month', 'weekday'];
    var splitCron;
    var cronAddition = [1, 1, 1, 0, 0, 0];

    for (var x = 0; x < 6; x++) {
      literal += ' ';
      if (x === 4) {
        literal += GetMonthString(cronArray[x]);
      } else if (x === 5) {
        literal += GetDaysString(cronArray[x]);
      } else if (IsEveryTimeCron(cronArray[x])) {
        literal += 'on every ' + cronTypes[x];
      } else if (IsIntervalCron(cronArray[x])) {
        splitCron = cronArray[x].replace('*/', '');
        literal += 'on every ' + splitCron + ' ' + cronTypes[x];
        if (splitCron !== '1') {
          literal += 's';
        }
      } else {
        var splitIndices = cronArray[x].split(',');
        literal += 'on ' + cronTypes[x];
        if (splitIndices.length > 1) {
          literal += 's';
        }
        literal += ' ';
        for (var y = 0; y < splitIndices.length; y++) {
          if (y > 0) {
            literal += ', ';
          }
          literal += parseInt(parseInt(splitIndices[y]) + cronAddition[y]);
        }
      }
    }
    literal += '.';
    return literal;
  }

  function GetDaysString(cronBit) {
    var days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    var daysString = '';
    if (
      IsEveryTimeCron(cronBit) ||
      (IsIntervalCron(cronBit) && cronBit.replace('/', '') === '1')
    ) {
      daysString += 'regardless of weekday';
    } else if (IsIntervalCron(cronBit)) {
      daysString += 'on every ' + cronBit.replace('*/', '') + ' days';
    } else {
      daysString += 'on ';
      var array = cronBit.split(',');
      var daysTrue = [];
      for (var x = 0; x < 7; x++) {
        if (array.indexOf(x.toString()) > -1) {
          daysTrue.push(days[x]);
        }
      }
      daysString += daysTrue.join(', ');
    }
    return daysString;
  }
  /**
   * @description Description of function.
   * @param {string} cronValue pertaining to months
   * @return {string} returns in which months the schedule fires
   */
  function GetMonthString(cronBit) {
    var monthsString = '';
    var months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    if (
      IsEveryTimeCron(cronBit) ||
      (IsIntervalCron(cronBit) && cronBit.replace('/', '') === '1')
    ) {
      monthsString += 'in every month';
    } else if (IsIntervalCron(cronBit)) {
      monthsString += 'in every ' + cronBit.replace('*/', '') + ' months';
    } else {
      monthsString += 'in ';
      var array = cronBit.split(',');
      var monthsTrue = [];
      for (var x = 1; x < 13; x++) {
        if (array.indexOf(x.toString()) > -1) {
          monthsTrue.push(months[x - 1]);
        }
      }
      monthsString += monthsTrue.join(', ');
    }
    return monthsString;
  }
  /**
   * @memberof common.CronService
   * @description checks if value is a standard cron * character
   * @param {cronBit} string -  Part of the cron expression passed to function.
   * @return {boolean} Returns true if value is * and false if it is not.
   */
  function IsEveryTimeCron(cronBit) {
    if (!cronBit) {
      return false;
    } else if (cronBit.length === 1 && cronBit[0] === '*') {
      return true;
    } else {
      return false;
    }
  }
  /**
   * @memberof common.CronService
   * @description checks if value is a standard cron interval
   * @param {cronBit} string -  Part of the cron expression passed to function.
   * @return {boolean} Returns true if value is a cron interval.
   */
  function IsIntervalCron(cronBit) {
    if (!cronBit) {
      return false;
    } else if (cronBit[0] === '*' && cronBit[1] === '/') {
      return true;
    } else {
      return false;
    }
  }
  /**
   * @memberof common.CronService
   * @description Function that determines if a a crom epxression is valid.
   * @param {string} cronString string containing a crons expression (example 0 0 * * 10 1)
   * @return {Object} Object containing, containing validity of the cron expression and the error message, in the case it is not valid.
   */
  function ValidateCron(cronString) {
    var cronBit, cronBitClean, cronCommaDelimited, tempValidation, x, y;
    var cronArray = cronString.split(' ');
    var validation = {
      valid: true,
      errors: []
    };

    if (cronArray.length !== 6) {
      validation.valid = false;
      validation.errors.push(
        gettext('There should only be 6 sections of cron.')
      );
    }

    for (x = 0; x < 6; x++) {
      cronBit = cronArray[x];
      if (IsIntervalCron(cronBit)) {
        cronBitClean = parseInt(cronBit.replace('*/', ''));

        if (!isNaN(cronBitClean)) {
          tempValidation = ValidateCronInterval(x, cronBitClean);
          if (!tempValidation.valid) {
            validation.valid = false;
            validation.errors.push(tempValidation.error);
          }
        } else {
          validation.valid = false;
          validation.error = gettextCatalog.getString(
            'Invalid formatting at position {{x}}.',
            {
              x: x
            }
          );
        }
      } else if (!IsEveryTimeCron(cronBit)) {
        cronCommaDelimited = cronBit.split(',');
        for (y = 0; y < cronCommaDelimited.length; y++) {
          cronBitClean = parseInt(cronCommaDelimited[y]);
          if (!isNaN(cronBitClean)) {
            tempValidation = ValidateCronInterval(x, cronBitClean);
            if (!tempValidation.valid) {
              validation.valid = false;
              validation.errors.push(tempValidation.error);
            }
          } else {
            validation.valid = false;
            validation.error = gettextCatalog.getString(
              'Invalid formatting at position {{x}}.',
              {
                x: x
              }
            );
          }
        }
      }
    }
    return validation;
  }
  /**
   * @description Function that checks if an interval in a cron expression, is valid.
   * @param {integer} cronPosition - Position of the potential interval in the crone expression.
   * @param {integer} cronBitClean - Value of the potential cron interval.
   * @return {Object} Object containing, containing validity of the cron expression and the error message, in the case it is not valid.
   */
  function ValidateCronInterval(cronPosition, cronBitClean) {
    var valid = true;
    var error = null;
    var cronSectionString = [
      'Seconds',
      'Minutes',
      'Hours',
      'Days',
      'Months',
      'Weekdays'
    ];
    var result = {};

    if (cronPosition < 2 && (cronBitClean < 0 || cronBitClean > 59)) {
      // seconds, minutes

      valid = false;
      error = gettextCatalog.getString(
        '{{pos}} should be on interval [0 - 59].',
        {
          pos: cronSectionString[cronPosition]
        }
      );
    } else if (cronPosition === 2 && (cronBitClean < 0 || cronBitClean > 23)) {
      valid = false;
      error = gettextCatalog.getString(
        '{{pos}} should be on interval [0 - 23].',
        {
          pos: cronSectionString[cronPosition]
        }
      );
    } else if (cronPosition === 3 && (cronBitClean < 1 || cronBitClean > 31)) {
      valid = false;
      error = gettextCatalog.getString(
        '{{pos}} should be on interval [0 - 31].',
        {
          pos: cronSectionString[cronPosition]
        }
      );
    } else if (cronPosition === 4 && (cronBitClean < 1 || cronBitClean > 12)) {
      valid = false;
      error = gettextCatalog.getString(
        '{{pos}} should be on interval [1 - 12].',
        {
          pos: cronSectionString[cronPosition]
        }
      );
    } else if (cronPosition === 5 && (cronBitClean < 0 || cronBitClean > 7)) {
      valid = false;
      error = gettextCatalog.getString(
        '{{pos}} should be on interval [0 - 7].',
        {
          pos: cronSectionString[cronPosition]
        }
      );
    }

    result = {
      valid: valid,
      error: error
    };

    return result;
  }

  return {
    GetCronLiteral: GetCronLiteral,
    IsEveryTimeCron: IsEveryTimeCron,
    IsIntervalCron: IsIntervalCron,
    ValidateCron: ValidateCron
  };
}
