module.exports = {
  entity: 'domains',
  path: 'domains',
  name: {
    en: 'Domain',
    sl_SI: 'Domena'
  },
  networkModel: {
    entity: 'domains',
    read: 'read'
  },
  scope: 'tis_be:domains',

  dialog: {
    title: {
      en: 'Select domain',
      sl_SI: 'Izberite domeno'
    },
    listInfo: {
      en: 'Select domains from the list below',
      sl_SI: 'Izberite domeno iz spodnjega seznama'
    }
  }
}
