module.exports = {
  entity: 'projects',
  path: 'projects',
  name: {
    en: 'Project',
    sl_SI: 'Pogodba'
  },
  networkModel: {
    entity: 'projects',
    read: 'read'
  },
  scope: 'tis_be:projects',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select project',
      sl_SI: 'Izberite pogodbo'
    },
    listInfo: {
      en: 'Select project from the list below',
      sl_SI: 'Izberite pogodbo s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      },
      {
        field1: 'code',
        type: 'code',
        title: 'Code'
      }
    ]
  }
}
