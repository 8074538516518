SfeEntityDateConfiguration.$inject = [
  'MeasuringpointAllocatorEntityDateConfiguration',
  'MeasuringpointBusinessPartnerEntityDateConfiguration',
  'MeasuringpointCostCentresEntityDateConfiguration',
  'MeasuringpointAssetsEntityDateConfiguration',
  'MeasuringpointCounterTypesEntityDateConfiguration',
  'MeasuringpointTimeSeriesEntityDateConfiguration',
  'LocationBusinessPartnerEntityDateConfiguration',
  'LocationCostCentresEntityDateConfiguration',
  'AssetTimeSeriesEntityDateConfiguration',
  'AssetCostCentresEntityDateConfiguration',
  'AssetLocationsEntityDateConfiguration',
  'TimeSeriesAssetsEntityDateConfiguration',
  'TimeSeriesMeasuringPointsEntityDateConfiguration',
  'TimeSeriesLocationEntityDateConfiguration',
  'LocationTimeSeriesEntityDateConfiguration',
  'AssetsMeasuringpointEntityDateConfiguration',
  'LocationAssetsEntityDateConfiguration'
];

export default function SfeEntityDateConfiguration(
  MeasuringpointAllocatorEntityDateConfiguration,
  MeasuringpointBusinessPartnerEntityDateConfiguration,
  MeasuringpointCostCentresEntityDateConfiguration,
  MeasuringpointAssetsEntityDateConfiguration,
  MeasuringpointCounterTypesEntityDateConfiguration,
  MeasuringpointTimeSeriesEntityDateConfiguration,
  LocationBusinessPartnerEntityDateConfiguration,
  LocationCostCentresEntityDateConfiguration,
  AssetTimeSeriesEntityDateConfiguration,
  AssetCostCentresEntityDateConfiguration,
  AssetLocationsEntityDateConfiguration,
  TimeSeriesAssetsEntityDateConfiguration,
  TimeSeriesMeasuringPointsEntityDateConfiguration,
  TimeSeriesLocationEntityDateConfiguration,
  LocationTimeSeriesEntityDateConfiguration,
  AssetsMeasuringpointEntityDateConfiguration,
  LocationAssetsEntityDateConfiguration
) {
  var configurations = {};

  configurations[
    'assets-measuringpoint'
  ] = AssetsMeasuringpointEntityDateConfiguration;
  configurations[
    'measuringpoint-allocators'
  ] = MeasuringpointAllocatorEntityDateConfiguration;
  configurations[
    'measuringpoint-business-partners'
  ] = MeasuringpointBusinessPartnerEntityDateConfiguration;
  configurations[
    'measuringpoint-cost-centres'
  ] = MeasuringpointCostCentresEntityDateConfiguration;
  configurations[
    'measuringpoint-assets'
  ] = MeasuringpointAssetsEntityDateConfiguration;
  configurations[
    'measuringpoint-counter-types'
  ] = MeasuringpointCounterTypesEntityDateConfiguration;
  configurations[
    'measuringpoint-time-series'
  ] = MeasuringpointTimeSeriesEntityDateConfiguration;
  configurations[
    'location-business-partners'
  ] = LocationBusinessPartnerEntityDateConfiguration;
  configurations['location-assets'] = LocationAssetsEntityDateConfiguration;
  configurations[
    'location-cost-centres'
  ] = LocationCostCentresEntityDateConfiguration;
  configurations[
    'location-time-series'
  ] = LocationTimeSeriesEntityDateConfiguration;
  configurations['asset-time-series'] = AssetTimeSeriesEntityDateConfiguration;
  configurations[
    'asset-cost-centres'
  ] = AssetCostCentresEntityDateConfiguration;
  configurations['asset-locations'] = AssetLocationsEntityDateConfiguration;
  configurations[
    'time-series-assets'
  ] = TimeSeriesAssetsEntityDateConfiguration;
  configurations[
    'time-series-location'
  ] = TimeSeriesLocationEntityDateConfiguration;
  configurations[
    'time-series-measuring-points'
  ] = TimeSeriesMeasuringPointsEntityDateConfiguration;
  return {
    configurations: configurations
  };
}
