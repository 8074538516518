AggregationController.$inject = [
  '$stateParams',
  'gettext',
  'DatasetColumnHelper',
  '$q',
  'MetadataService',
  'aggregationGroup',
  '$timeout'
];

function AggregationController(
  $stateParams,
  gettext,
  DatasetColumnHelper,
  $q,
  MetadataService,
  aggregationGroup,
  $timeout
) {
  var vm = this;
  var deferred = $q.defer();
  var aggregationId = $stateParams.id;
  init();

  function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    tableConfig();
    vm.headerData = constructHeader(aggregationGroup);
    vm.loading = false;
    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(
        aggregationGroup.name,
        aggregationGroup.description
      );
    });
    deferred.resolve({
      available: aggregationGroup.dataset.columns,
      selected: aggregationGroup.condition
    });
  }

  function setConditions(pagination) {
    var pr = $q.defer();
    deferred.promise.then(function(data) {
      var selectedArray = [];
      var selected = data.selected;
      var available = data.available;
      var start = pagination.page * pagination.limit - pagination.limit;
      var end = pagination.page * pagination.limit - 1;
      _.each(selected, function(rawFieldName, index) {
        var temp = _.find(available, {
          rawFieldName: rawFieldName
        });
        if (temp) {
          temp.order = index + 1;
          selectedArray.push(temp);
        }
      });

      vm.selected = _.map(selectedArray, function(column) {
        return {
          rawFieldName: column.rawFieldName,
          displayFieldName: column.displayFieldName,
          filterEnabled: column.filterEnabled,
          typeName: DatasetColumnHelper.getTypeName(column.type),
          entityName:
            column.entity === 'date'
              ? DatasetColumnHelper.getDateTypeName(column.dateType)
              : DatasetColumnHelper.getEntityName(column.entity),
          order: column.order
        };
      });

      var filtered = vm.selected.filter(function(item, index) {
        return index >= start && index <= end;
      });
      pr.resolve({
        data: filtered,
        pagination: {
          total: vm.selected.length,
          page: pagination.page,
          per_page: pagination.limit
        }
      });
    });

    return { $promise: pr.promise };
  }

  function tableConfig() {
    vm.config = {
      entity: {
        plural: gettext('Conditions')
      },
      api: {
        query: {
          query: setConditions
        }
      },
      title: {
        title: gettext('Raw field'),
        param: 'rawFieldName'
      },
      attributes: [
        {
          title: gettext('Display field'),
          param: 'displayFieldName'
        },
        {
          title: gettext('Type'),
          param: 'typeName'
        },
        {
          title: gettext('Entity'),
          param: 'entityName'
        },
        {
          title: gettext('Order'),
          param: 'order'
        }
      ],
      badges: [
        {
          title: gettext('Filter enabled'),
          type: 'goodOrBad',
          param: 'filterEnabled',
          good: gettext('Yes'),
          bad: gettext('No')
        }
      ]
    };
  }

  function constructHeader(aggregationGroup) {
    var properties = [];
    if (aggregationGroup.dataset._id) {
      properties.push({
        title: gettext('Dataset'),
        linkTitle: aggregationGroup.dataset.name,
        state: 'analytics-datasets-view',
        param: 'id',
        paramValue: aggregationGroup.dataset._id,
        type: 'link'
      });
    }

    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];

    var propertySections = [
      {
        title: gettext('properties'),
        properties: properties
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'aggregation-groups',
        target: aggregationId,
        type: 'userAccess'
      }
    ];

    var actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: aggregationId,
        endpoint: {
          entity: 'aggregation-groups',
          method: 'delete'
        },
        redirectState: 'analytics-aggregation-groups-list'
      },
      {
        title: gettext('Update'),
        state: 'analytics-aggregation-groups-edit',
        param: 'id',
        paramValue: aggregationGroup._id
      }
    ];
    var metadata = {
      title: aggregationGroup.name,
      description: aggregationGroup.description,
      definition: 'Aggregation group'
    };
    return {
      metadata: metadata,
      actions: actions,
      propertySections: propertySections,
      userAccess: userAccess
    };
  }
}

export default AggregationController;
