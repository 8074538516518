CompanyResourcesViewData.$inject = [
  'gettext',
  'gettextCatalog',
  'TranslationService',
  'CrawlerMethods',
  'LocationModel',
  'MeasuringpointTypeModel',
  'AssetModel'
];
/**
 * @ngdoc service
 * @name companyResources.CompanyResourcesViewData
 * @description generates default entity item view of tree selected item..
 * @property {function} getProperties
 */
export default function CompanyResourcesViewData(
  gettext,
  gettextCatalog,
  TranslationService,
  CrawlerMethods,
  LocationModel,
  MeasuringpointTypeModel,
  AssetModel
) {
  /**
   * @memberof CompanyResourcesViewData.getProperties
   * @description resolves to item view configuration array.
   * @param {object} element object that we want to generate view for
   * @param {string} typeParamName name of parameter where element type is stored
   * @return {Promise}
   */
  function getProperties(element, typeParamName) {
    return new Promise((resolve, reject) => {
      setRedirectStateAndParams(element, typeParamName).then(
        configuration => {
          resolve(configuration);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  /**
   * @description check item entity type and triggers configuration get finctions according to type.
   * @function
   * @param {object} element object that we want to generate view for
   * @param {string} typeParamName name of parameter where element type is stored
   * @return {Promise}
   */
  async function setRedirectStateAndParams(element, typeParamName) {
    if (element) {
      // eslint-disable-next-line no-useless-catch
      try {
        switch (element[typeParamName]) {
        case 'costCentre':
          // state and param are used for being able to click on the item
          element.state = 'company-resources-cost-centres-view';
          return await getCostCentrePreview(element);
        case 'asset':
          element.state = 'company-resources-assets-view';
          return await setAssetPreview(element);
        case 'location':
          element.state = 'company-resources-locations-view';
          return await getLocationPreview(element);
        case 'business-partner':
          element.state = 'company-resources-business-partners-view';
          return setBusinessPartnerPreview(element);
        case 'company-structure-node':
          element.state =
              'configurations-company-resources-company-structure-nodes-view';
          return await getCompanyStructureNodePreview(element);
        case 'profit-centre':
          element.state = 'company-resources-profit-centres-view';
          return getProfitCentrePreview(element);
        case 'energy-source-types':
          element.state =
              'configurations-energy-management-energy-source-types-view';
          return getEnergySourceTypePreview(element);
        case 'group':
          element.state = 'users-groups-view';
          element.param = 'id';
          return getGroupPreview(element);
        case 'measuringpoint':
          element.state = 'company-resources-measuring-points-view';
          return getMeasuringPointPreview(element);
        default:
          throw gettextCatalog.getString(
            '{{elementType}} unknown parameter',
            { elementType: element[typeParamName] }
          );
        }
      } catch (err) {
        throw err;
      }
    } else {
      throw gettextCatalog.getString('There is no selected item');
    }
  }

  /**
   * @description resolves to preview configuration array
   * @function
   * @param {object} element item object we want to fetch preview for
   * @returns {Promise}
   */
  function getCostCentrePreview(element) {
    let properties = [
      {
        title: gettext('External'),
        value: element.isExternal,
        radio: true,
        customOptions: ['YES', 'NO']
      }
    ];

    if (element.code) {
      properties.push({
        title: gettext('Code'),
        value: element.code,
        code: true
      });
    }

    if (element.externalCode) {
      properties.push({
        title: gettext('External code'),
        value: element.externalCode,
        code: true
      });
    }

    return properties;
  }
  /**
   * @description resolves to preview configuration array
   * @function
   * @param {object} element item object we want to fetch preview for
   * @returns {Promise}
   */
  function getProfitCentrePreview(element) {
    var properties = [];
    if (element.node) {
      properties.push({
        title: gettext('Parent Node'),
        value: element.node,
        simple: true
      });
    }

    if (element.code) {
      properties.push({
        title: gettext('Code'),
        value: element.code,
        code: true
      });
    }

    if (element.externalCode) {
      properties.push({
        title: gettext('External Code'),
        value: element.externalCode,
        code: true
      });
    }
    return properties;
  }
  /**
   * @description resolves to preview configuration array
   * @function
   * @param {object} element item object we want to fetch preview for
   * @returns {Promise}
   */
  function getEnergySourceTypePreview(element) {
    var properties = [];
    if (element.description) {
      properties.push({
        title: gettext('Description'),
        value: element.description,
        simple: true
      });
    }

    if (element.code) {
      properties.push({
        title: gettext('Code'),
        value: element.code,
        code: true
      });
    }

    if (element.externalCode) {
      properties.push({
        title: gettext('External Code'),
        value: element.externalCode,
        code: true
      });
    }
    return properties;
  }
  /**
   * @description resolves to preview configuration array
   * @function
   * @param {object} element item object we want to fetch preview for
   * @returns {Promise}
   */
  function getGroupPreview(element) {
    var properties = [];
    if (element.description) {
      properties.push({
        title: gettext('Description'),
        value: element.description,
        simple: true
      });
    }

    if (element.code) {
      properties.push({
        title: gettext('Code'),
        value: element.code,
        code: true
      });
    }

    if (element.externalCode) {
      properties.push({
        title: gettext('External Code'),
        value: element.externalCode,
        code: true
      });
    }
    return properties;
  }
  /**
   * @description resolves to preview configuration array
   * @function
   * @param {object} element item object we want to fetch preview for
   * @returns {Promise}
   */
  function getMeasuringPointPreview(element) {
    return new Promise((resolve, reject) => {
      var properties = [];
      var measuringPointClass = TranslationService.GetCollectionById(
        'codelists.measuringPointClasses',
        element.measuringpointClass
      );
      var measuringPointKind = TranslationService.GetCollectionById(
        'codelists.measuringPointKinds',
        element.measuringpointKind
      );
      properties = [
        {
          title: gettext('Code'),
          value: element.code,
          code: true
        },
        {
          code: true,
          title: gettext('External code'),
          value: element.externalCode
            ? element.externalCode
            : gettext('Unknown')
        },
        {
          title: gettext('Measuring point kind'),
          value: measuringPointKind ? measuringPointKind.name : 'Unknown',
          simple: true
        },
        {
          title: gettext('Measuring point class'),
          value: measuringPointClass ? measuringPointClass.name : 'Unknown',
          simple: true
        }
      ];

      if (element.measuringpointType) {
        MeasuringpointTypeModel.read({
          id: element.measuringpointType
        }).then(
          function(res) {
            properties.push({
              title: gettext('Measuring point type'),
              value: res.data.name,
              simple: true
            });
            resolve(properties);
          },
          function(err) {
            reject(err);
          }
        );
      } else {
        resolve(properties);
      }
    });
  }
  /**
   * @description resolves to preview configuration array
   * @function
   * @param {object} element item object we want to fetch preview for
   * @returns {Promise}
   */
  function getCompanyStructureNodePreview(element) {
    return new Promise(resolve => {
      var properties = [];
      async.waterfall(
        [async.apply(readHierarchyNode, element._id), readParentNode],
        function(err, companyStructureNode) {
          properties.push(
            {
              title: gettext('Type'),
              value:
                TranslationService.GetCollectionById(
                  'codelists.costCentreTypes',
                  companyStructureNode?.type
                )?.name ?? '/',
              simple: true
            },
            {
              title: gettext('Code'),
              value: companyStructureNode?.code ?? '/',
              code: true
            }
          );
          if (companyStructureNode?.externalCode) {
            properties.push({
              title: gettext('External Code'),
              value: companyStructureNode.externalCode,
              code: true
            });
          }
          if (companyStructureNode?.parentNode) {
            properties.push({
              title: gettext('Parent Company Structure Node'),
              linkTitle: companyStructureNode.parentNode.name,
              state:
                'configurations-company-resources-company-structure-nodes-view',
              param: 'id',
              paramValue: companyStructureNode.parentNode._id,
              link: true
            });
          }
          resolve(properties);
        }
      );
    });
  }
  /**
   * @description fetches hierarchyNode item.
   * @function
   * @param {string} id hierarchyNodeId
   * @param {function} callback callback function
   */
  function readHierarchyNode(id, callback) {
    var method = CrawlerMethods.getMethod({
      entity: 'company-structure-nodes',
      method: 'read'
    });
    method({
      id: id
    }).then(
      function(res) {
        callback(null, res.data);
      },
      function(err) {
        callback(err);
      }
    );
  }
  /**
   * @description fetches companyStructureNode item.
   * @function
   * @param {string} companyStructureNode companyStructureNode id
   * @param {function} callback callback function
   */
  function readParentNode(companyStructureNode, callback) {
    if (companyStructureNode.parentId) {
      var method = CrawlerMethods.getMethod({
        entity: 'company-structure-nodes',
        method: 'read'
      });
      method({
        id: companyStructureNode.parentId
      }).then(
        function(res) {
          companyStructureNode.parentNode = res.data;
          callback(null, companyStructureNode);
        },
        function(err) {
          callback(err, companyStructureNode);
        }
      );
    } else {
      callback(null, companyStructureNode);
    }
  }
  /**
   * @description resolves to preview configuration array
   * @function
   * @param {object} element item object we want to fetch preview for
   * @returns {Promise}
   */
  function getLocationPreview(element) {
    return new Promise((resolve, reject) => {
      LocationModel.custom
        .CRViewData({
          id: element._id
        })
        .then(
          ({ data: location }) => {
            var properties = [];

            if (location.status || element.statusName) {
              properties.push({
                title: gettext('Status'),
                value: location.status
                  ? location.status.name
                  : element.statusName,
                simple: true
              });
            }

            if (location.type || element.typeName) {
              properties.push({
                title: gettext('Type'),
                value: location.type ? location.type.name : element.typeName,
                simple: true
              });
            }
            if (location.ownership) {
              properties.push({
                title: gettext('Ownership'),
                value: location.ownership.name,
                simple: true
              });
            }

            properties.push({
              title: gettext('External'),
              value: element.isExternal,
              radio: true,
              customOptions: ['YES', 'NO']
            });

            if (element.code) {
              properties.push({
                title: gettext('Code'),
                value: element.code,
                code: true
              });
            }
            resolve(properties);
          },
          err => reject(err)
        );
    });
  }
  /**
   * @description resolves to preview configuration array
   * @function
   * @param {object} element item object we want to fetch preview for
   * @returns {Promise}
   */
  function setBusinessPartnerPreview(element) {
    var properties = [];
    if (element.code) {
      properties.push({
        title: gettext('Code'),
        value: element.code,
        code: true
      });
    }
    if (element.type) {
      var type;
      if (typeof element.type === 'string') {
        type = element.type;
      } else {
        type = TranslationService.GetCollection(
          'codelists.businessPartnerTypes'
        ).find(function(item) {
          return item.id === element.type;
        }).name;
      }
      properties.push({
        title: gettext('Type'),
        value: type || gettextCatalog.getString('Unknown'),
        simple: true
      });
    }

    if (element.isDistributer !== null) {
      properties.push({
        title: gettext('Distributor'),
        value: element.isDistributer,
        radio: true,
        customOptions: ['YES', 'NO']
      });
    }

    if (element.tax) {
      properties.push({
        title: gettext('Tax Number'),
        value: element.tax,
        simple: true
      });
    }

    if (element.uniqueNumber) {
      properties.push({
        title: gettext('Unique Number'),
        value: element.uniqueNumber,
        simple: true
      });
    }

    if (element.isExternal !== null) {
      properties.push({
        title: gettext('External'),
        value: element.isExternal,
        radio: true,
        customOptions: ['YES', 'NO']
      });
    }
    return properties;
  }

  /**
   * @description generates asset preview configuration.
   * @function
   * @param {object} element entity element
   * @return {Promise}
   */
  function setAssetPreview(element) {
    return new Promise(resolve => {
      AssetModel.custom
        .CRViewData({
          id: element._id
        })
        .then(({ data: asset }) => {
          var properties = [];

          if (asset.type) {
            properties.push({
              title: gettext('Type'),
              value: asset.type.name,
              simple: true
            });
          }
          if (asset.ownership) {
            properties.push({
              title: gettext('Ownership'),
              value: asset.ownership.name,
              simple: true
            });
          }

          properties.push({
            title: gettext('External'),
            value: element.isExternal,
            radio: true,
            customOptions: ['YES', 'NO']
          });

          if (element.code) {
            properties.push({
              title: gettext('Code'),
              value: element.code,
              code: true
            });
          }
          resolve(properties);
        });
    });
  }

  return {
    getProperties: getProperties
  };
}
