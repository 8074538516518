module.exports = {
  entity: 'business-partners',
  path: 'business-partners',
  name: {
    en: 'Business Partner',
    sl_SI: 'Poslovni partner'
  },
  networkModel: {
    entity: 'business-partners',
    read: 'read'
  },
  scope: 'tis_be:business-partners',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select business partner',
      sl_SI: 'Izberite poslovnega partnerja'
    },
    listInfo: {
      en: 'Select business partner from the list below',
      sl_SI: 'Izberite poslovnega partnerja s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      },
      {
        field1: 'code',
        type: 'code',
        title: 'Code'
      }
    ]
  },
  sfeTree: {
    showConnectedEntities: false,
    route: 'business-partners',
    parentEntity: 'business-partners',
    parentKey: 'parentId',
    icon: 'business-partner',
    childrenConfiguration: [
      {
        type: 'simple',
        usedIn: [
          'company-resources-business-partners-list',
          'company-resources-business-partners-view'
        ],
        existenceParam: 'hasChild',
        route: 'business-partners',
        childKey: 'parentId',
        icon: 'business-partner'
      }
    ]
  }
}
