DomainConfigurationController.$inject = [
  '$q',
  '$state',
  'gettext',
  'DomainFieldsListConfigService',
  'DomainCollectionListConfigService',
  'gettextCatalog',
  '$filter',
  'MetadataService',
  'domain',
  '$timeout'
];

function DomainConfigurationController(
  $q,
  $state,
  gettext,
  DomainFieldsListConfigService,
  DomainCollectionListConfigService,
  gettextCatalog,
  $filter,
  MetadataService,
  domain,
  $timeout
) {
  const vm = this;
  let fieldDescription;
  const domainId = $state.params.id;

  init();

  function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    // var waterfall = [readDomains];

    fieldDescription = domain.fields;
    // init collection table
    vm.collectionListConfig = DomainCollectionListConfigService.getConfig(
      $state.params.id
    );
    vm.collectionListConfig.api.enrich = enrich;
    var fields = DomainCollectionListConfigService.getHeaders(domain);
    if (fields.title.hasOwnProperty('param')) {
      vm.collectionListConfig.title = {
        param: fields.title.param
      };
    } else {
      var field = fields.attributes.splice(0, 1);
      if (field && field.length) {
        field[0].domainId = domainId;
        vm.collectionListConfig.title = {
          title: field[0].title,
          param: field[0].param,
          link: {
            state: 'configurations-dynamic-attributes-domains-collections-view',
            params: [
              {
                valueParam: '_id',
                queryParam: 'collectionId'
              },
              {
                value: domainId,
                queryParam: 'domainId'
              }
            ]
          }
        };
      }
    }
    vm.collectionListConfig.attributes = fields.attributes;

    vm.headerData = constructHeader(domain);
    vm.loading = false;
    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(domain.name, domain.description);
    });

    vm.listConfig = DomainFieldsListConfigService.getConfig(domainId);
    vm.filters = {
      domainId: domainId
    };
  }

  function enrich(item) {
    var pr = $q.defer();
    _.each(fieldDescription, function(domainField) {
      if (domainField.fieldType === 'Date') {
        item[domainField.rawFieldName] = $filter('date')(
          new Date(item[domainField.rawFieldName]),
          'dd.MM.yyyy'
        );
      } else if (domainField.fieldType === 'Boolean') {
        item[domainField.rawFieldName] = item[domainField.rawFieldName]
          ? gettextCatalog.getString('Yes')
          : gettextCatalog.getString('No');
      }
    });
    pr.resolve();
    return pr.promise;
  }

  function constructHeader(domain) {
    const metadata = [
      {
        title: gettext('Collection Friendly Name'),
        value: domain.collectionFriendlyName,
        type: 'simple'
      }
    ];
    const propertySections = [
      {
        title: gettext('Properties'),
        properties: metadata
      }
    ];
    const actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: domain._id,
        endpoint: {
          entity: 'domains',
          method: 'delete'
        },
        redirectState: 'configurations-dynamic-attributes-domains-list'
      },
      {
        title: gettext('Update'),
        state: 'configurations-dynamic-attributes-domains-edit',
        param: 'id',
        paramValue: domain._id
      }
    ];
    return {
      metadata: {
        definition: 'Domain',
        title: domain.name,
        description: domain.description
      },
      propertySections,
      actions
    };
  }
}

export default DomainConfigurationController;
