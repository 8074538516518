import { DateTime } from 'luxon';

ManualReadingsHelper.$inject = [
  'TranslationService',
  'ColorService',
  'gettextCatalog',
  'DateLocalizationService',
  'Formatting',
  'MasterInvoiceModel',
  'MeasuringpointModel',
  'chartTranslationService',
  'TimeSeriesProcessingValuesModel'
];
/**
 * @ngdoc service
 * @name common.ManualReadingsHelper
 * @description Service used to fetch and construct metadata to display manual readings
 * @property {function} constructManualReading
 * @property {function} readTimeSeriesValues
 * @property {function} readTimeSeriesArrayValues
 * @property {function} pairTimeSeriesValuesAndManualReadings
 * @property {function} getChartConfiguration,
 * @property {function} saveManualReadings
 */
export default function ManualReadingsHelper(
  TranslationService,
  ColorService,
  gettextCatalog,
  DateLocalizationService,
  Formatting,
  MasterInvoiceModel,
  MeasuringpointModel,
  chartTranslationService,
  TimeSeriesProcessingValuesModel
) {
  /**
   * @description fetches datapoint values.
   * @function
   * @param {Object} filter sfe-chart filter
   * @return {Promise}
   */
  async function readTimeSeriesValues(filter, detailed, manualRefreshTime) {
    if (!detailed) filter.view = 'simple';
    try {
      const res = await TimeSeriesProcessingValuesModel.read(
        filter,
        manualRefreshTime
      );
      return res.data;
    } catch (err) {
      return [];
    }
  }
  /**
   * @description gets values for array of datapoints uses dateTo filter
   * @function
   * @param {Array} points array of datapoint ids
   * @param {date} date date limit
   * @return {Promise}
   */
  async function readTimeSeriesArrayValues(points, dateTo) {
    const promises = points.map(function(point) {
      return readTimeSeriesValues({
        timeSeriesId: point.id,
        limit: 2,
        to: dateTo
      });
    });

    const results = await Promise.all(promises);
    return results.map(function(res, index) {
      return {
        ...points[index],
        values: res
      };
    });
  }
  /**
   * @description returns time series color.
   * @function
   * @param {String} tariffCode tariff code
   * @param {Number} timeSeriesTypeReadingType codelist id
   * @return {String} color string
   */
  function getColor(tariffCode, timeSeriesTypeReadingType) {
    var hue = 'primary';
    var shade = 'normal';
    switch (tariffCode) {
    case 'VT':
      hue = 'accent';
      break;
    case 'MT':
      hue = 'primary';
      break;
    case 'ET':
      hue = 'success';
      break;
    }
    shade = timeSeriesTypeReadingType === 1 ? 'hue-2' : 'hue-1';
    return ColorService.getApplicationColor(hue, shade);
  }
  /**
   * @description sfe-graph list values wrapper.
   * @function
   * @param {Object} filter sfe-chart filter
   * @return {Promise}
   */
  async function readGraphValues(defaultFilter, defaultManualRefresh) {
    const { timeSeriesId, readDateTime, customQueryFilter } = this;
    let manualRefreshTime =
      this.manualRefresh || defaultManualRefresh ? 0 : undefined;
    let filter = {
      timeSeriesId
    };
    if (defaultFilter && defaultFilter.from) {
      filter.from = defaultFilter.from;
    } else if (customQueryFilter && customQueryFilter.from) {
      filter.from = customQueryFilter.from;
    } else {
      filter.from = Date.now() - 3600 * 24 * 31 * 12 * 1000;
    }
    if (defaultFilter && defaultFilter.to) {
      filter.to = defaultFilter.to;
    } else if (customQueryFilter && customQueryFilter.to) {
      filter.to = customQueryFilter.to;
    }
    const values = await readTimeSeriesValues(
      filter,
      readDateTime,
      manualRefreshTime
    );
    return values
      .map(function(value) {
        let val = [new Date(value.validAt), value.value];
        if (readDateTime) {
          val.push(value.sampledAt);
        }
        return val;
      })
      .sort(function(valueA, valueB) {
        return valueA[0] - valueB[0];
      })
      .slice(-filter.limit);
  }

  /**
   * @description adds asyncQuery, and color to series configuration.
   * @function
   * @param {Object} manualReading manual reading object
   * @return {Object}
   */
  function constructSeries(manualReading, reduceMethod) {
    const {
      tariff,
      timeSeriesType,
      measurementUniSymbol,
      metricPrefix
    } = manualReading;
    let suffix = measurementUniSymbol;

    const prefix = TranslationService.GetCollectionById(
      'codelists.metricPrefixes',
      metricPrefix
    );

    if (prefix != null) {
      suffix = `${prefix.symbol}${suffix}`;
    }
    return {
      name: manualReading.timeSeriesTypeName + ' ' + manualReading.tariffCode,
      asyncQuery: readGraphValues.bind({ timeSeriesId: manualReading._id }),
      type: 'column',
      decimalPrecision: 2,
      physicalQuantity:
        typeof manualReading.physicalQuantity == 'object' &&
        manualReading.physicalQuantity != null
          ? manualReading.physicalQuantity
          : {},
      color: getColor(
        manualReading.tariffCode,
        manualReading.timeSeriesTypeReadingType
      ),
      suffix,
      reduceMethod,
      order: {
        tariff,
        timeSeriesType
      }
    };
  }
  /**
   * @description reduces array of values to an object where key is validAt value and value is values array.
   * @function
   * @param {Array} resArray array of fetched datapoint values
   * @param {number} modResult 1 or 0 to use only odd or even idnexes
   * @return {Object}
   */
  function reduceTimeSeries(resArray, modResult) {
    return resArray.reduce((result, item, index) => {
      let res;
      if (index % 2 == modResult) {
        res = item.reduce((acc, val) => {
          if (!acc[val[0]] && val[2]) {
            acc[val[0]] = [
              val[0],
              DateLocalizationService.LocalizationDateIntervalFn('d')(val[2])
            ];
          }
          return acc;
        }, {});
      }
      return {
        ...result,
        ...res
      };
    }, {});
  }

  function constructSpecialTimeSeries(ids) {
    return {
      name: gettextCatalog.getString('Reading date'),
      asyncQuery: async function(filter, manualRefresh) {
        const promises = ids.map(set => {
          return readGraphValues.bind({
            timeSeriesId: set,
            readDateTime: true,
            manualRefresh,
            customQueryFilter: filter
          })();
        });
        const resArray = await Promise.all(promises);
        const obj = {
          // even index for consumption time series
          ...reduceTimeSeries(resArray, 1),
          //  odd index for reading time series
          ...reduceTimeSeries(resArray, 0)
        };
        return Object.values(obj);
      },
      nonNumerical: true
    };
  }

  /**
   * @memberof ManualReadingsHelper.constructManualReadings
   * @description returns object with arrays of series
   *  manual readings with added schedulers and time series unit symbol and scheduler function.
   * @param {array} binding/paramName
   * @return {Object}
   */
  async function constructManualReading(fetchedManualReadings, registers) {
    if (fetchedManualReadings && fetchedManualReadings.length > 0) {
      let metricPrefix, readableSymbol;
      let config = fetchedManualReadings.reduce(
        (result, manualReading) => {
          metricPrefix = TranslationService.GetCollectionById(
            'codelists.metricPrefixes',
            manualReading.pairedTimeSeries.metricPrefix
          );
          readableSymbol = metricPrefix ? metricPrefix.symbol : '';
          readableSymbol += manualReading.pairedTimeSeries.measurementUniSymbol;
          return {
            scheduler: later.schedule(
              later.parse.cron(manualReading.pairedTimeSeries.cron, true)
            ),
            series: [
              ...result.series,
              constructSeries(manualReading, 'last'),
              constructSeries(manualReading.pairedTimeSeries, 'sum')
            ],
            detailedSeries: result.detailedSeries.concat([
              manualReading._id,
              manualReading.pairedTimeSeries._id
            ]),
            timeSeries: [
              ...result.timeSeries,
              {
                id: manualReading._id
              },
              {
                id: manualReading.pairedTimeSeries._id
              }
            ],
            manualReadings: [
              ...result.manualReadings,
              {
                ...manualReading,
                scheduler: later.schedule(
                  later.parse.cron(manualReading.cron, true)
                ),
                pairedTimeSeries: {
                  ...manualReading.pairedTimeSeries,
                  scheduler: later.schedule(
                    later.parse.cron(manualReading.pairedTimeSeries.cron, true)
                  ),
                  readableSymbol
                }
              }
            ]
          };
        },
        {
          detailedSeries: [],
          series: [],
          timeSeries: [],
          manualReadings: []
        }
      );
      //sort series
      config.series = config.series.sort((a, b) => {
        const aIndex = registers.findIndex(
          register =>
            register.timeSeriesType == a.order.timeSeriesType &&
            register.tariff == a.order.tariff
        );
        const bIndex = registers.findIndex(
          register =>
            register.timeSeriesType == b.order.timeSeriesType &&
            register.tariff == b.order.tariff
        );
        return aIndex - bIndex;
      });
      //sort manual readings
      config.manualReadings = config.manualReadings.sort((a, b) => {
        const aIndex = registers.findIndex(
          register =>
            register.timeSeriesType == a.timeSeriesType &&
            register.tariff == a.tariff
        );
        const bIndex = registers.findIndex(
          register =>
            register.timeSeriesType == b.timeSeriesType &&
            register.tariff == b.tariff
        );
        return aIndex - bIndex;
      });
      config.detailedSeries = [
        constructSpecialTimeSeries(config.detailedSeries),
        ...config.series
      ];
      return config;
    }
  }

  /**
   * @memberof ManualReadingsHelper.pairTimeSeriesValuesAndManualReadings
   * @description returns array of manual readings with paired datapoint values
   * @function
   * @param {Array} values fetched datapoint values array
   * @param {Array} manualReadings manual readings array
   * @param {Date} selectedDate selected date
   */
  function pairTimeSeriesValuesAndManualReadings(
    values,
    manualReadings,
    selectedDate
  ) {
    let value;
    let finalReading;
    return manualReadings.map(manualReading => {
      finalReading = {
        ...manualReading,
        value: undefined,
        previousValue: gettextCatalog.getString('There are no values'),
        previousValueDate: undefined,
        pairedTimeSeries: {
          ...manualReading.pairedTimeSeries,
          value: undefined
        }
      };
      value = values.find(function(item) {
        return item.id === manualReading._id;
      });
      let lastValueReading;
      if (value) {
        lastValueReading = Array.isArray(value.values)
          ? value.values[0]
          : false;
        // in case of fetched last value date matches selected date
        // set last and previous values
        if (lastValueReading) {
          finalReading.changed = false;
          finalReading.pairedTimeSeries.changed = false;
          if (lastValueReading.validAt === selectedDate.getTime()) {
            finalReading.value = lastValueReading.value;
            if (value.values[1]) {
              finalReading.previousValue = value.values[1].value;
              finalReading.previousValueDate = value.values[1].validAt;
            }
          } else {
            // set only previous value to the last fetched value
            finalReading.value = undefined;
            finalReading.previousValue = lastValueReading.value;
            finalReading.previousValueDate = lastValueReading.validAt;
          }
        }
      }

      value = values.find(function(item) {
        return item.id === manualReading.pairedTimeSeries._id;
      });
      if (value) {
        lastValueReading = Array.isArray(value.values)
          ? value.values[0]
          : false;
        if (
          lastValueReading &&
          lastValueReading.validAt === selectedDate.getTime()
        ) {
          finalReading.pairedTimeSeries.value = lastValueReading.value;
        }
      }
      return finalReading;
    });
  }

  /**
   * @memberof ManualReadingsHelper.getChartConfiguration
   * @description returns chart configuration.
   * @function
   * @param {Array} series series array
   * @param {Array} detailedSeries series for detailed view
   * @param {string} theme theme name
   * @param {Object} energySourceType energy source type which name, measurementUnit and Metric prefix will be displayed on the chart
   * @param {boolean} detailedMode detailed mode (no reducing of data)
   */
  async function getChartConfiguration(series, theme, detailedMode) {
    const yAxes = series.reduce((axes, seriesItem) => {
      if (!seriesItem.nonNumerical) {
        const axisIndex = axes.findIndex(
          item =>
            item.suffix === seriesItem.suffix &&
            item.physicalQuantityId === seriesItem.physicalQuantity._id
        );
        if (axisIndex >= 0) {
          seriesItem.yAxis = axisIndex;
        } else {
          let axis = {
            title: seriesItem.physicalQuantity.name,
            suffix: seriesItem.suffix,
            physicalQuantityId: seriesItem.physicalQuantity._id,
            alternateGridColor: '#FAFAFA'
          };
          axes = [...axes, axis];
          seriesItem.yAxis = axes.length - 1;
        }
      }
      return axes;
    }, []);

    const chartConfig = {
      chartTitle: gettextCatalog.getString('Manual readings'),
      axisY: yAxes,
      axisX: [
        {
          title: gettextCatalog.getString('Consumption date'),
          suffix: false
        }
      ],
      translations: chartTranslationService(),
      theme: theme,
      series: series,
      mode: {
        chart: true,
        table: false
      },
      alternativeMode: {
        chart: false,
        table: true
      },
      titleDisplay: {
        chart: false,
        fullscreen: false
      },
      format: {
        labelFormatter: (y, p, x) => {
          if (detailedMode) {
            return `${DateTime.fromJSDate(new Date(x)).toFormat(
              'dd. MMM.'
            )} </br>
                ${Formatting.formatNumber(y)}
            `;
          } else {
            return Formatting.formatNumber(y);
          }
        },
        value: Formatting.formatNumber
      },
      filter: {
        from: DateTime.fromJSDate(new Date())
          .minus({ year: 1 })
          .toMillis()
      },
      height: 334,
      tableHeight: 334,
      chartDisplay: {
        legend: true,
        legendAlign: 'center',
        markers: true,
        dataLabels: true,
        axisYTitle: true,
        axisXTitle: true
      },
      display: {
        limit: false,
        date: true,
        refresh: true,
        tools: false,
        fullscreen: false,
        mode: true,
        export: false
      },
      tableDisplay: {
        tableSizes: [1, 12, 24],
        selectedTableSize: 12
      },
      api: {}
    };
    if (detailedMode) {
      chartConfig.format.date = DateLocalizationService.LocalizationTimeCategoryFn(
        'm'
      );

      chartConfig.plotOptions = {
        series: {
          dataLabels: {
            enabled: true,
            formatter: function() {
              return new Date(this.x);
            }
          }
        }
      };
    } else {
      chartConfig.axisX[0].timeCategory = 'month';
      chartConfig.axisX[0].type = 'categories';
      chartConfig.format.timeCategory = {
        month: DateLocalizationService.LocalizationTimeCategoryFn('m')
      };
    }

    return chartConfig;
  }
  async function updateFutureConsumption(manualReadings, readingDate) {
    let updatedConsumption;
    const promises = manualReadings.reduce((result, manualReading) => {
      if (manualReading.nextValue) {
        updatedConsumption =
          manualReading.nextValue.value - manualReading.value;
      } else {
        updatedConsumption = false;
      }
      const sampledAt = DateTime.fromJSDate(new Date(readingDate)).toFormat(
        'dd/MM/yyyy HH:mm:ss'
      );
      if (
        updatedConsumption !== false &&
        manualReading.pairedTimeSeries.nextValue &&
        updatedConsumption != manualReading.pairedTimeSeries.nextValue.value &&
        manualReading.pairedTimeSeries.nextValue.value != 0
      ) {
        result.push(
          TimeSeriesProcessingValuesModel.create(
            {
              timeSeriesId: manualReading.pairedTimeSeries._id
            },
            [
              {
                value: updatedConsumption,
                validAt: DateTime.fromJSDate(
                  new Date(manualReading.pairedTimeSeries.nextValue.validAt)
                ).toFormat('dd/MM/yyyy HH:mm:ss'),
                sampledAt
              }
            ]
          )
        );
      }
      return result;
    }, []);
    return await Promise.all(promises);
  }
  /**
   * @description throws an error if manual readings that user has entered are not valid.
   * @function
   * @param {Array} manualReadings array of manual readings including entered data
   * @param {Date} nextValidDate next valid date
   * @return {Array} returns manual readings array with the next values that have to be adjusted
   */
  async function validateManualReadings(manualReadings, nextValidDate) {
    if (Array.isArray(manualReadings)) {
      const nextReadingsPromises = manualReadings.reduce(
        (result, manualReading) => {
          result.push(
            readTimeSeriesValues({
              timeSeriesId: manualReading._id,
              from: nextValidDate.getTime(),
              limit: 1,
              reverseOrder: true
            }),
            readTimeSeriesValues({
              timeSeriesId: manualReading.pairedTimeSeries._id,
              from: nextValidDate.getTime(),
              limit: 1,
              reverseOrder: true
            })
          );
          return result;
        },
        []
      );

      const nextReadings = await Promise.all(nextReadingsPromises);
      let testReadings;
      manualReadings.forEach((manualReading, index) => {
        testReadings = nextReadings[index * 2][0];

        if (
          isNaN(Number(manualReading.previousValue)) ||
          manualReading.value >= manualReading.previousValue
        ) {
          if (testReadings && manualReading.value > testReadings.value) {
            // WHEN NEXT < CURRENT
            // CHECK IF NEXT CONSUMPTION == 0 (COUNTER CHANGE)
            const nextConsumptionValue = nextReadings[index * 2 + 1][0];
            if (!nextConsumptionValue || nextConsumptionValue.value != 0) {
              // ERROR #8
              throw gettextCatalog.getString(
                'Manual reading cannot be entered because the value of the entered reading is greater than the next reading.'
              );
            }
          }
        } else {
          // ERROR #7
          throw gettextCatalog.getString(
            'Manual reading cannot be entered because the value of the entered reading is less than the previous reading.'
          );
        }
      });

      return manualReadings.map((manualReading, index) => {
        return {
          ...manualReading,
          nextValue: nextReadings[index * 2][0],
          pairedTimeSeries: {
            ...manualReading.pairedTimeSeries,
            nextValue: nextReadings[index * 2 + 1][0]
          }
        };
      });
    } else {
      throw gettextCatalog.getString('Manual readings cannot be saved.');
    }
  }

  /**
   * @memberof ManualReadingsHelper.saveManualReadings
   * @description saves new data point values.
   * @function
   * @param {Array} manualReadings array of manual readings
   * @param {Date} selectedDate
   * @param {Date} nextValidDate
   * @param {string} action action type string
   * @return {Promise}
   */
  async function saveManualReadings(
    manualReadings = [],
    selectedDate,
    nextValidDate,
    actionType,
    readingDate
  ) {
    if (manualReadings) {
      if (!(selectedDate instanceof Date)) {
        throw gettextCatalog.getString('Wrong selected date format');
      } else if (
        actionType == 'counterChange' &&
        !(nextValidDate instanceof Date)
      ) {
        throw gettextCatalog.getString('Wrong next date format');
      } else {
        const promises = manualReadings.reduce((result, manualReading) => {
          if (
            manualReading.changed ||
            manualReading.openingValue != undefined
          ) {
            result.push(
              saveDatapointValues(
                manualReading,
                false,
                selectedDate,
                nextValidDate,
                actionType,
                readingDate
              )
            );
          }
          if (
            manualReading.pairedTimeSeries &&
            (manualReading.pairedTimeSeries.changed ||
              manualReading.openingValue != undefined)
          ) {
            result.push(
              saveDatapointValues(
                manualReading.pairedTimeSeries,
                Boolean(manualReading.openingValue),
                selectedDate,
                nextValidDate,
                actionType,
                readingDate
              )
            );
          }
          return result;
        }, []);

        return await Promise.all(promises);
      }
    }
  }

  /**
   * @description saves new data point values.
   * @function
   * @param {Object} manualReading manual reading object
   * @param {Bool} openingValue indicates that there is a counter opening value to save (could be true only when pairedTimeSeries is updated)
   * @param {Date} selectedDate
   * @param {Date} nextValidDate
   * @param {string} action action type string
   * @return {Promise}
   */
  async function saveDatapointValues(
    manualReading,
    openingValue,
    selectedDate,
    nextValidDate,
    actionType,
    readingDate
  ) {
    const dateToSave = DateTime.fromJSDate(selectedDate).toFormat(
      'dd/MM/yyyy HH:mm:ss'
    );
    const sampledAt = DateTime.fromJSDate(new Date(readingDate)).toFormat(
      'dd/MM/yyyy HH:mm:ss'
    );
    var valuesToCreate = [
      {
        value: manualReading.value,
        validAt: dateToSave,
        sampledAt
      }
    ];
    if (actionType == 'counterChange' && manualReading.openingValue) {
      valuesToCreate.push({
        value: manualReading.openingValue.value,
        validAt: DateTime.fromJSDate(nextValidDate).toFormat(
          'dd/MM/yyyy HH:mm:ss'
        ),
        sampledAt
      });
    }

    if (openingValue) {
      valuesToCreate.push({
        value: 0,
        validAt: DateTime.fromJSDate(nextValidDate).toFormat(
          'dd/MM/yyyy HH:mm:ss'
        ),
        sampledAt
      });
    }
    try {
      await TimeSeriesProcessingValuesModel.create(
        {
          timeSeriesId: manualReading._id,
          timeliness: 100
        },
        valuesToCreate
      );
      manualReading.uploadStatus = 'success';
      return manualReading;
    } catch (err) {
      manualReading.uploadStatus = 'fail';
      manualReading.error = err;
      return manualReading;
    }
  }

  /**
   * @description difference between consumption and reading date should be less than a month .
   * @function
   * @param {Date} consumption consumption date
   * @param {Date} reading reading date
   * @return {Bool}
   */
  function readingAndConsumptionDatesAreValid(consumption, reading) {
    const consumptionDate = DateTime.fromJSDate(new Date(consumption));
    const readingDate = DateTime.fromJSDate(new Date(reading));
    if (!consumptionDate.invalid && !readingDate.invalid) {
      const { values: diffResult } = consumptionDate.diff(readingDate, 'month');
      return Math.abs(diffResult.months) <= 1;
    }
    return false;
  }
  /**
   * @description reading date should be one month max before now.
   * @function
   * @param {Date} date reading date
   * @param {Date} nowDate reading date
   * @return {Bool}
   */
  function dateIsOneMonthBefore(date, nowDate) {
    const luxonDate = DateTime.fromJSDate(new Date(date)).startOf('day');
    const luxonNow = DateTime.fromJSDate(new Date(nowDate)).startOf('day');
    const { values: diffResult } = luxonDate.diff(luxonNow, 'month');
    return diffResult.months <= 0;
  }

  /**
   * @description checks if user is allowed to edit manual readings for selected date and selected measuring point.
   * @function
   * @param {String} measuringpointId
   * @param {Date} date selected date
   * @param {Array} points array of datapoints for measuring point manual readings
   * @return {dataType}
   */
  async function validateMeasuringPointInvoices(
    measuringpointId,
    date,
    points
  ) {
    const currentDate = DateTime.fromJSDate(date);
    const startDate = currentDate
      .startOf('month')
      .toFormat('dd.MM.yyyy HH:mm:ss');
    const luxonEndDate = currentDate.plus({ month: 1 });
    const endDate = luxonEndDate.endOf('month').toFormat('dd.MM.yyyy HH:mm:ss');

    const { data: masterInvoices } = await MasterInvoiceModel.read({
      'detailInvoices.measuringPoint': measuringpointId,
      populate: 'detailInvoices',
      billingDate: '{gte}' + startDate + '{lte}' + endDate
    });
    // CHECK ARE THERE ANY READINGS (POINT VALUES)
    const { isFirst, values } = await isFirstInvoiceValue(
      points,
      measuringpointId,
      date
    );
    const result = {
      currentMonth: currentDate.toLocaleString({ month: 'long' }),
      nextMonth: luxonEndDate.toLocaleString({ month: 'long' }),
      current: false,
      nextIsClosed: false,
      nextExist: false,
      values
    };

    if (Array.isArray(masterInvoices)) {
      const currentMonth = currentDate.get('month');
      const endMonth = luxonEndDate.get('month');

      masterInvoices.forEach(masterInvoice => {
        if (
          DateTime.fromJSDate(masterInvoice.billingDate)
            .toLocal()
            .get('month') == currentMonth
        ) {
          if (masterInvoice.invoiceStatus == 21) {
            result.current = true;
          }
        } else if (
          DateTime.fromJSDate(masterInvoice.billingDate)
            .toLocal()
            .get('month') == endMonth
        ) {
          result.nextExist = true;
          //DateTime.fromJSDate(masterInvoice.billingDate).get('month') >currentMonth
          if (masterInvoice.invoiceStatus == 21) {
            result.nextIsClosed = true;
          }
        }
      });
    }
    let error;
    // IS THERE A CURRENT MONTH INVOICE THAT HAS STATUS == 21
    if (result.current) {
      //YES
      error = gettextCatalog.getString(
        'Manual reading cannot be entered because the invoice is already closed for the current billing period {{month}}.',
        { month: result.currentMonth }
      );
      result.error = error;
      // END
    } else {
      // IS THERE A NEXT MONTH INVOICE
      if (result.nextExist) {
        // IS THAT INVOICE HAS STATUS == 21
        if (result.nextIsClosed) {
          if (!isFirst) {
            error = gettextCatalog.getString(
              'Invoice for {{month}} is closed. Reopen invoice in order to edit values',
              { month: result.nextMonth }
            );
            result.error = error;
          }
        }
      } else {
        // CHECK ARE THERE ANY INVOICE IN THE FUTURE THAT HAS STATUS 21
        const { data: futureClosedInvoice } = await MasterInvoiceModel.read({
          'detailInvoices.measuringPoint': measuringpointId,
          populate: 'detailInvoices',
          billingDate: '{gte}' + endDate,
          limit: 1,
          invoiceStatus: 21 //WHEN INVOICE STATUS IS 21 INVOICE IS CLOSED
        });
        if (
          Array.isArray(futureClosedInvoice) &&
          futureClosedInvoice.length > 0
        ) {
          const billingDate = DateTime.fromJSDate(
            new Date(futureClosedInvoice[0].billingDate)
          );

          result.error = gettextCatalog.getString(
            'Manual reading cannot be entered because there is already a closed invoice for {{month}} {{year}}.',
            {
              month: billingDate.toLocaleString({ month: 'long' }),
              year: billingDate.get('year')
            }
          );
        }
      }
    }
    return {
      error: result.error,
      values: result.values
    };
  }
  /**
   * @description returns object with the flag is first and values array of datapoint values for current date and date before current date.
   * @function
   * @param {Array} points array of datapoint ids
   * @param {String} id of measuring point
   * @param {Date} date current date
   * @return {dataType}
   */
  async function isFirstInvoiceValue(points, measuringpointId, date) {
    const dateTimestamp = DateTime.fromJSDate(date).toMillis();
    const dataPointValues = await readTimeSeriesArrayValues(
      points,
      dateTimestamp
    );
    const valuesExist = dataPointValues.reduce((result, dataPointValue) => {
      return result || dataPointValue.values.length > 0;
    }, false);
    return {
      isFirst: !valuesExist,
      values: dataPointValues
    };
  }
  /**
   * @description returns first measuring point that has measuringKind == 1 (billing) in measuring point hierarchy.
   * @function
   * @param {String} measuringPointId id of measuring point
   * @param {Number} kind measuring point kind id
   * @param {String} parentId measuringpoint parent id
   * @return {String} measuring point id
   */
  async function getMeasuringpoint(measuringPointId, kind, parentId) {
    if (kind == 1) {
      return measuringPointId;
    } else {
      const error = gettextCatalog.getString(
        'Manual reading cannot be entered because there is no main billing measuring point at the current measuring point.'
      );
      if (parentId) {
        const { data: measuringPoint } = await MeasuringpointModel.read({
          id: parentId
        });
        if (measuringPoint) {
          return await getMeasuringpoint(
            measuringPoint._id,
            measuringPoint.measuringpointKind,
            measuringPoint.parentId
          );
        } else {
          throw error;
        }
      } else {
        // ERROR #1
        throw error;
      }
    }
  }

  return {
    updateFutureConsumption,
    validateManualReadings,
    getMeasuringpoint,
    validateMeasuringPointInvoices,
    dateIsOneMonthBefore,
    readingAndConsumptionDatesAreValid,
    constructManualReading,
    pairTimeSeriesValuesAndManualReadings,
    getChartConfiguration,
    saveManualReadings
  };
}
