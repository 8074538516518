HierarchyTreeDialogController.$inject = [
  'firstElement',
  'entity',
  'StorageService',
  'EntitiesService',
  'gettext',
  '$state'
];

export default function HierarchyTreeDialogController(
  firstElement,
  entity,
  StorageService,
  EntitiesService,
  gettext,
  $state
) {
  var vm = this;
  vm.modeChanged = modeChanged;
  vm.elementSelected = elementSelected;
  vm.returnParamText = returnParamText;
  vm.state = $state.current.name;

  init();
  /**
   * @description initiate dialog.
   * @function
   */
  function init() {
    vm.entity = entity;
    var entityTreeConfiguration = EntitiesService.getHierarchyTree(entity);
    vm.showConnectedEntities = entityTreeConfiguration.showConnectedEntities;
    vm.firstElement = firstElement;
    vm.treeType = 'hierarchy';
    var title;

    switch (entityTreeConfiguration.route) {
    case 'cost-centres':
      title = gettext('Cost Centre Tree View');
      break;
    case 'business-partners':
      title = gettext('Business Partner Tree View');
      break;
    case 'locations':
      title = gettext('Location Tree View');
      break;
    case 'company-structure-nodes':
      title = gettext('Company Structure Node Tree View');
      break;
    case 'profit-centres':
      title = gettext('Profit Centre Tree View');
      break;
    }

    vm.header = {
      title: title,
      isDialog: true,
      actions: [
        {
          icon: {
            name: 'close',
            type: 2
          },
          cancel: true
        }
      ]
    };

    vm.showConnectedToggle = StorageService.get('showConnectedToggle');
  }
  /**
   * @description saves to local storage  showConnectedToggle flag value.
   * @function
   */
  function modeChanged() {
    StorageService.save('showConnectedToggle', vm.showConnectedToggle);
  }

  /**
   * @description returns redirect parameters.
   * @function
   * @param {object} property redirect configurations
   * @return {object}
   */
  function returnParamText(property) {
    var parameterObject = property.paramObject || {};

    if (property.param && property.paramValue) {
      parameterObject[property.param] = property.paramValue;
    }
    return parameterObject;
  }

  /**
   * @description set new selected element.
   * @function
   * @param {object} element new selected element
   */
  function elementSelected(element) {
    vm.selected = element;
  }
}
