import './sfe-list-item.scss';
import template from './sfe-list-item.component.html';

export default {
  template,
  bindings: {
    entity: '@',
    values: '<',
    objectManipulation: '<',
    listConfig: '<',
    paginationLimit: '<?',
    paginationTotal: '<?',
    paginationPage: '<?',
    index: '<?',
    loadingStatus: '='
  },
  controller: 'SfeListItemController',
  controllerAs: 'vm'
};
