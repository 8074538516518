module.exports = {
  entity: 'measuringpoints',
  path: 'measuringpoints',
  entityId: 2,
  name: {
    en: 'Measuring point',
    sl_SI: 'Merilno mesto'
  },
  networkModel: {
    entity: 'measuringpoints',
    read: 'read'
  },
  scope: 'tis_be:measuring-points',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select measuring point',
      sl_SI: 'Izberite merilno mesto'
    },
    listInfo: {
      en: 'Select measuring point from the list below',
      sl_SI: 'Izberite merilno mesto s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      },
      {
        field1: 'code',
        type: 'code',
        title: 'Code'
      }
    ]
  },
  sfeTree: {
    // parent config se ne uporablja, tako da ga bo treba uredit takrat, ko se bo.
    showConnectedEntities: false,
    route: 'measuringpoints',
    parentEntity: 'measuringpoints',
    parentKey: 'parentId',
    childKey: 'parentId',
    icon: 'measuringpoint',
    childrenTreeConfiguration: [
      {
        type: 'simple',
        route: 'measuringpoints',
        icon: 'measuringpoint'
      }
    ],
    childrenConfiguration: [
      {
        type: 'simple',
        usedIn: ['everywhere'],
        route: 'measuringpoints',
        childKey: 'parentId',
        icon: 'measuringpoint',
        existenceParam: 'hasChild'
      }
    ]
  }
}
