export default [
  '$timeout',
  function($timeout) {
    return {
      link: function(scope, element) {
        $timeout(function() {
          // make sure tooltip is not drawn over window borders
          var windowHeight = window.innerHeight;
          var elementHeight =
            element[0].clientHeight + element[0].getBoundingClientRect().top;
          if (windowHeight < elementHeight) {
            var heightDiff = -(elementHeight - windowHeight + 20);
            element.css('margin-top', heightDiff + 'px');

            // move tooltip arrow
            var arrowHeightDiff = -heightDiff - 10;
            var elId = element[0].id;
            var arrow = document.head.appendChild(
              document.createElement('style')
            );
            arrow.innerHTML =
              '#' + elId + '::after {margin-top:' + arrowHeightDiff + 'px;}';
          }
        }, 500);
      }
    };
  }
];
