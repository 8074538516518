/**
 * @ngdoc service
 * @name common.TangoTimeseriesGroupSandboxHelperService
 * @description Opens form dialog
 */

TangoTimeseriesGroupSandboxHelperService.$inject = ['TranslationService'];
export default function TangoTimeseriesGroupSandboxHelperService(
  TranslationService
) {
  /**
   * @description creates new schedule classification model and preview array on move to another schedule timeseries actions.
   * @function
   * @param {Array} previewTimeseries array of timeseries objects
   * @param {Object} classificationsModel sandbox classification model
   * @param {Object} selectedSchedulers selected schedulers to add/remove timeseries
   * @param {Object} timeseries
   * @param {Number| null} activeKey codelist id of schedule classification. Indicates what schedule triggered the event
   * @return {Object} {previewTimeseries, classificationModel}
   */
  function getPreviewAndModelOnMove(
    previewTimeseries,
    classificationsModel,
    selectedSchedulers,
    timeseries,
    activeKey
  ) {
    classificationsModel = Object.keys(selectedSchedulers).reduce(
      (model, classificationId) => {
        if (selectedSchedulers[classificationId]) {
          //ADD SERIES
          if (model[classificationId]) {
            let foundSeries = model[classificationId].series.find(
              item => item._id == timeseries._id
            );
            if (!foundSeries) {
              //ADD TO EXISTING
              model[classificationId].series.push(angular.copy(timeseries));
            }
          } else {
            //CREATE CLASSIFICATION AND ADD NEW SERIES
            model = {
              ...model,
              [classificationId]: {
                scheduleClassification: TranslationService.GetCollectionById(
                  'codelists.scheduleClassifications',
                  Number(classificationId)
                ),
                series: [timeseries]
              }
            };
          }
        } else {
          //REMOVE SERIES
          let foundSeriesIndex = model[classificationId].series.findIndex(
            item => item._id == timeseries._id
          );
          if (foundSeriesIndex > -1) {
            model[classificationId].series.splice(foundSeriesIndex, 1);
          }
        }
        return model;
      },
      classificationsModel
    );
    //Remove item from preview
    if (activeKey == null) {
      let indexToRemove = previewTimeseries.findIndex(
        item => item._id == timeseries._id
      );
      if (indexToRemove >= 0) {
        previewTimeseries.splice(indexToRemove, 1);
      }
    }
    return {
      previewTimeseries,
      classificationsModel
    };
  }

  /**
   * @description adds new schedule classifications to sandbox model.
   * @function
   * @param {Object} scheduleClassification selected schedule classification object
   * @param {Object} timeseries indicates what timeseries should be moved to new classification {[timeseriesId]: Bool}
   * @param {Object} classificationsModel current classification model
   * @param {Array} previewTimeseries Array of preview timeseries. Used to match timeseries ids with timeseries objects
   * @return {Object} classificationsModel
   */
  function addConfigurationToModel(
    scheduleClassification,
    timeseries,
    classificationsModel,
    previewTimeseries
  ) {
    let series = [];

    if (typeof timeseries == 'object' && timeseries != null) {
      series = Object.keys(timeseries).reduce((result, timeseriesId) => {
        if (timeseries[timeseriesId]) {
          result = [
            ...result,
            previewTimeseries.find(item => item._id == timeseriesId)
          ];
        }
        return result;
      }, []);
    }
    return {
      ...classificationsModel,
      [scheduleClassification.id]: {
        scheduleClassification,
        series
      }
    };
  }

  return {
    getPreviewAndModelOnMove,
    addConfigurationToModel
  };
}
