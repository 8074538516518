module.exports = {
  entity: 'measuringPointClasses',
  path: 'measuringPointClasses',
  name: {
    en: 'Measuring point classes',
    sl_SI: 'Razredi merilnih mest'
  },
  codelist: 'measuringPointClasses',
  selectedParamName: 'id',
  scope: 'tis_be:measuringpoint-classes',

  dialog: {
    codelist: 'measuringPointClasses',
    title: {
      en: 'Select measuring point class',
      sl_SI: 'Izberite razred merilnega mesta'
    },
    listInfo: {
      en: 'Select measuring point class from the list below',
      sl_SI: 'Izberite razred merilnega mesta s spodnjega seznama'
    }
  }
}
