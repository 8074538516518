import './report-view.scss';

ReportController.$inject = [
  'gettext',
  '$state',
  'AlertingService',
  'DatasetColumnHelper',
  'MetadataService',
  '$timeout',
  'analysisQueryBuilder',
  'periodIntervalConstructor',
  '$httpParamSerializer',
  'TranslationService',
  'AnalysisFilterBuilder',
  'gettextCatalog',
  'SfeForm2DateFromPriorToValidationService',
  'report'
];

function ReportController(
  gettext,
  $state,
  AlertingService,
  DatasetColumnHelper,
  MetadataService,
  $timeout,
  analysisQueryBuilder,
  periodIntervalConstructor,
  $httpParamSerializer,
  TranslationService,
  AnalysisFilterBuilder,
  gettextCatalog,
  SfeForm2DateFromPriorToValidationService,
  report
) {
  const vm = this;
  const reportId = $state.params.id;
  const defaultFilterEntities = [
    234 /*TimeSeries*/,
    2 /*Measuringpoint*/,
    3 /*Location*/,
    4 /*CostCentre*/,
    235 /*TimeSeriesType*/,
    105 /*EntityTag*/,
    20 /* Alarms */,
    187 /* Severity */,
    30 /* alarmType */,
    251 /* alarmStatuses */,
    252 /* EventTypes */,
    248 /* TangoAgentConnectionConfig */,
    247 /* externalDatasource */
  ];
  let comparableColumns = [];

  vm.filterFormIsShown = false;

  /**
   * @description initilization function.
   * @function
   */
  init();
  function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    readReport();
  }
  /**
   * @description the main function, in which all the functions that enrich data are called.
   * @function
   */
  async function readReport() {
    try {
      vm.headerData = constructHeader(report);
      vm.reportFilters = await AnalysisFilterBuilder.buildFilters(
        report.filters,
        report.dataset.columns
      );
      vm.loading = false;
      vm.timeFilterForm = constructDateFiltersConfig(report);
      vm.timeFilterApi = {};
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(report.name, null);
    } catch (err) {
      vm.loading = false;
      MetadataService.Loading(false);
      AlertingService.Error(err);
    }
  }
  /**
   * @description creates a configuration for the report filter (not to be mixed up with filters config for analysis-filters component)
   * @function
   * @param {Object} analysis - data about the analysis used on this
   * @return {Object} returns a configuration file
   */
  function constructDateFiltersConfig(report) {
    const { dataset } = report;

    const interval = periodIntervalConstructor.getFormObj(
      report.periodInterval
    );

    if (dataset.comparableColumns) {
      comparableColumns = dataset.comparableColumns.map(function(column) {
        return {
          _id: column,
          name: column
        };
      });
    }

    let fromToValidation = SfeForm2DateFromPriorToValidationService.get(
      'from',
      'to'
    );
    let fromToMonthValidation = SfeForm2DateFromPriorToValidationService.get(
      'fromMonth',
      'toMonth'
    );

    return {
      name: 'timeFilter',
      fields: [
        {
          id: 'activeComparableColumn',
          title: gettextCatalog.getString('Comparable column'),
          type: {
            name: 'autocomplete',
            options: {
              items: comparableColumns,
              display: item => ({ text: item.name })
            }
          },
          required: true,
          initialize: () =>
            comparableColumns.find(
              column => column._id === report.activeComparableColumn
            )
        },
        {
          id: 'from',
          title: gettextCatalog.getString('From'),
          type: {
            name: 'date',
            options: {
              enableTime: true,
              enableSeconds: true
            }
          },
          onChange: form => {
            $timeout(form.revalidate);
          },
          initialize: () => interval.startDate,
          required: true,
          width: 4,
          validators: {
            maxDate: fromToValidation
          },
          hide: api => {
            const { activeComparableColumn } = api.getValues();
            return activeComparableColumn?._id === 'validityDateCompare';
          }
        },
        {
          id: 'to',
          title: gettextCatalog.getString('To'),
          type: {
            name: 'date',
            options: {
              enableTime: true,
              enableSeconds: true
            }
          },
          onChange: form => {
            $timeout(form.revalidate);
          },
          initialize: () => interval.endDate,
          width: 4,
          validators: {
            minDate: fromToValidation
          },
          hide: api => {
            const { activeComparableColumn } = api.getValues();
            return activeComparableColumn?._id === 'validityDateCompare';
          },
          required: true
        },
        {
          id: 'fromMonth',
          title: gettextCatalog.getString('From'),
          type: {
            name: 'date',
            options: {
              monthSelector: true
            }
          },
          onChange: form => {
            $timeout(form.revalidate);
          },
          initialize: () => interval.startDate,
          required: true,
          width: 4,
          validators: {
            maxDate: fromToMonthValidation
          },
          hide: api => {
            const { activeComparableColumn } = api.getValues();
            return activeComparableColumn?._id !== 'validityDateCompare';
          }
        },
        {
          id: 'toMonth',
          title: gettextCatalog.getString('To'),
          type: {
            name: 'date',
            options: {
              monthSelector: true
            }
          },
          onChange: form => {
            $timeout(form.revalidate);
          },
          initialize: () => interval.endDate,
          width: 4,
          validators: {
            minDate: fromToMonthValidation
          },
          hide: api => {
            const { activeComparableColumn } = api.getValues();
            return activeComparableColumn?._id !== 'validityDateCompare';
          },
          required: true
        }
      ]
    };
  }
  /**
   * @description prepares data for generating the report and moves the user to the report viewing state..
   * @function
   */
  function runReport() {
    const report = this.report;

    const {
      from,
      to,
      fromMonth,
      toMonth,
      activeComparableColumn
    } = vm.timeFilterApi.getValues();
    const startDate = from[0];
    const endDate = to[0];
    const startDateMonth = fromMonth[0];
    const endDateMonth = toMonth[0];

    var validationError;

    var filtersToValidate = vm.reportFilters.map(function(filter) {
      return {
        entityItems: filter.entityItemObjects,
        entity: filter.entity
      };
    });
    validationError = DatasetColumnHelper.validateFilters(
      filtersToValidate,
      defaultFilterEntities
    ).error;

    if (!validationError) {
      vm.fetchingReportPreview = true;

      const reportObject = analysisQueryBuilder.getQuery({
        analysis: report,
        analysisFilters: vm.reportFilters,
        startDate,
        endDate,
        activeComparableColumn: activeComparableColumn
          ? activeComparableColumn._id
          : report.activeComparableColumn,
        startDateMonth,
        endDateMonth
      });
      const apiObj = reportObject.apiObject;
      const qs = $httpParamSerializer(apiObj);
      $state.go('analytics-viewer-report-generic', {
        id: report._id,
        query: qs
      });
    } else {
      AlertingService.Error(validationError);
    }
  }
  /**
   * @description creates an object containing header data.
   * @function
   * @param {Object} report - data about the report used on this page.
   * @return {Object} Object containing header data
   */
  function constructHeader(report) {
    const actions = [
      {
        title: gettext('Run'),
        fn: runReport.bind({
          report: report
        })
      },
      {
        title: gettext('delete'),
        delete: true,
        id: report._id,
        endpoint: {
          entity: 'reports',
          method: 'delete'
        },
        redirectState: 'analytics-reports-list'
      },
      {
        title: gettext('Duplicate'),
        state: 'analytics-reports-duplicate',
        param: 'id',
        paramValue: report._id
      },
      {
        title: gettext('Update'),
        state: 'analytics-reports-edit',
        param: 'id',
        paramValue: report._id
      }
    ];

    var properties = [];

    if (report.dataset && report.dataset._id) {
      properties.push({
        title: gettext('Dataset'),
        linkTitle: report.dataset.name,
        state: 'analytics-datasets-view',
        param: 'id',
        paramValue: report.dataset._id,
        type: 'link'
      });
    }
    if (report.periodInterval && report.periodInterval._id) {
      properties.push({
        title: gettext('Period interval'),
        linkTitle: report.periodInterval.name,
        state: 'analytics-time-interval-filters-view',
        param: 'id',
        paramValue: report.periodInterval._id,
        type: 'link'
      });
    }

    properties.push(
      {
        type: 'simple',
        value: report.activeComparableColumn,
        title: gettext('Active Comparable column')
      },
      {
        title: gettext('Allowed entities'),
        type: 'tags',
        paramName: 'name',
        values: report.allowedEntities
          ? report.allowedEntities.map(function(item) {
            return TranslationService.GetCollectionById(
              'codelists.entities',
              item
            );
          })
          : []
      }
    );
    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];

    var propertySections = [
      {
        title: gettext('properties'),
        properties: properties
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'reports',
        target: reportId,
        type: 'userAccess'
      }
    ];
    return {
      metadata: {
        definition: gettext('Report'),
        title: report.name,
        description: report.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}
export default ReportController;
