import template from '../pages/hierarchyTreeDialog/tree-view.dialog.html';
HierarchyTreeDialog.$inject = ['$mdDialog'];
/**
 * @ngdoc service
 * @name common.HierarchyTreeDialog
 * @description opens dialog that displays hierarchy tree structure.
 * @property {function} openDialog
 */
export default function HierarchyTreeDialog($mdDialog) {
  /**
   * @memberof HierarchyTreeDialog.openDialog
   * @description opens dialog.
   * @param {object} firstElement  Element based on which the tree is built, it is also initially displayed in the sideview
   * @param {Number} entity entity codelist id
   * @return {dataType}
   */
  function openDialog(firstElement, entity) {
    return $mdDialog.show({
      controller: 'HierarchyTreeDialogController',
      controllerAs: 'vm',
      template,
      parent: angular.element(document.body),
      locals: {
        firstElement: firstElement,
        entity: entity
      }
    });
  }
  return {
    openDialog: openDialog
  };
}
