import template from './tango-mt-analysis-flow-chart.component.html';
import './tango-mt-analysis-flow-chart.scss';
export default {
  template,
  bindings: {
    entityId: '<'
  },
  controller: TangoMtAnalysisFlowChart,
  controllerAs: 'vm'
};
/**
 * @ngdoc component
 * @name data.tangoMtAnalysisFlowChart
 * @description displays mt analysis charts and baselines.
 * @param {String} entityId id of mt analysis flow
 * @example
 */
TangoMtAnalysisFlowChart.$inject = ['$ngRedux', 'ReduxStoreService'];

function TangoMtAnalysisFlowChart($ngRedux, ReduxStoreService) {
  const vm = this;
  let unsubscribe;

  vm.valuesApi = {};
  vm.patternApi = {};
  const mtAnalysisFlowEntityId = 244;

  vm.$onChanges = () => {
    //on any change check if all bindings are set
    if (vm.entityId && unsubscribe == null) {
      unsubscribe = $ngRedux.connect(mapStateToProps)(vm);
    }
  };

  vm.$onDestroy = () => {
    if (unsubscribe != null) {
      unsubscribe();
    }
  };

  let previousMtFlow;
  /**
   * @description  Triggered every time state changes and sets
   * actions, header and icon data to scope.
   * @function
   * @param {Object} state store state object
   * @return {Object}
   */
  function mapStateToProps(state) {
    let { value: item } = ReduxStoreService.extract(
      state,
      mtAnalysisFlowEntityId,
      vm.entityId,
      { type: 'stored' }
    );
    if (
      typeof item == 'object' &&
      item != null &&
      Object.keys(item).length > 0 &&
      !_.isEqual(previousMtFlow, item) //reload values only when mtFlow  changes
    ) {
      previousMtFlow = angular.copy(item);

      let { value: timeSeries } = ReduxStoreService.extract(
        state,
        234, //TIME SERIES CODELIST ID
        item.timeSeries,
        { type: 'stored' }
      );
      vm.mtAnalysis = {
        ...item,
        timeSeriesObject: timeSeries
      };
    }
    return {};
  }
}
