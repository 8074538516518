module.exports = {
  entity: 'work-schedules',
  path: 'work-schedules',
  name: {
    en: 'Work schedule',
    sl_SI: 'Urnik dela'
  },
  networkModel: {
    entity: 'work-schedules',
    read: 'custom.readWithUsers',
    dialogMethod: {
      entity: 'work-schedules',
      method: 'custom.listWithUsers'
    }
  },
  scope: 'tis_be:work-schedules',

  dialog: {
    title: {
      en: 'Select work schedule',
      sl_SI: 'Izberite urnik dela'
    },
    listInfo: {
      en: 'Select work schedule from the list below',
      sl_SI: 'Izberite urnik dela s spodnjega seznama'
    }
  },
  displayFields: [
    {
      field1: 'type',
      codelist: 'workScheduleTypes',
      type: 'codelist',
      title: {
        en: 'Type',
        sl_SI: 'Tip'
      }
    },
    {
      field1: 'dateFrom',
      type: 'date',
      title: {
        en: 'Date from',
        sl_SI: 'Datum od'
      }
    },
    {
      field1: 'dateTo',
      type: 'date',
      title: {
        en: 'Date to',
        sl_SI: 'Datum do'
      }
    },
    {
      field1: 'user',
      field2: 'name',
      type: 'string',
      title: {
        en: 'User',
        sl_SI: 'Uporabnik'
      }
    },
    {
      field1: 'substituteUser',
      field2: 'name',
      type: 'string',
      title: {
        en: 'Substitute user',
        sl_SI: 'Nadomestni uporabnik'
      }
    }
  ]
}
