NewMeasuringPointFormController.$inject = [
  '$state',
  'gettext',
  'DateTimeDialogService',
  'AlertingService',
  'CrudToastFactory',
  'MetadataService',
  '$q',
  'EntityTagService',
  'SfeHeader',
  'MeasuringpointCounterTypeModel',
  'BusinessPartnerMeasuringpointModel',
  'CostCentreMeasuringpointModel',
  'AssetMeasuringpointModel',
  'MeasuringpointModel',
  'BusinessPartnerLocationModel',
  'CostCentreLocationModel',
  'CrawlerMethods',
  'measuringPoint',
  '$timeout'
];

function NewMeasuringPointFormController(
  $state,
  gettext,
  DateTimeDialogService,
  AlertingService,
  CrudToastFactory,
  MetadataService,
  $q,
  EntityTagService,
  SfeHeader,
  MeasuringpointCounterTypeModel,
  BusinessPartnerMeasuringpointModel,
  CostCentreMeasuringpointModel,
  AssetMeasuringpointModel,
  MeasuringpointModel,
  BusinessPartnerLocationModel,
  CostCentreLocationModel,
  CrawlerMethods,
  measuringPoint,
  $timeout
) {
  var vm = this;
  var measuringPointId = $state.params.id;
  var hasAddress = false;
  var currentlyActiveCounterType;
  var currentlyActiveOwner;
  var currentlyactiveAsset;
  var currentlyActiveCostCentre;
  var originalTags;
  var headerConfig;

  init();

  function init() {
    switch ($state.current.name) {
    case 'company-resources-measuring-points-duplicate':
      MetadataService.Loading(true);
      vm.InitiatingForm = true;
      headerConfig = SfeHeader.constructFormHeader(
        'primary',
        gettext('Duplicate measuring point'),
        'company-resources-measuring-points-view',
        {
          id: measuringPointId
        }
      );
      vm.duplicateMode = true;
      break;
    case 'company-resources-measuring-points-edit':
      MetadataService.Loading(true);
      headerConfig = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit measuring point'),
        'company-resources-measuring-points-view',
        {
          id: measuringPointId
        }
      );
      vm.editMode = true;
      break;
    default:
      headerConfig = SfeHeader.constructFormHeader(
        'primary',
        gettext('New measuring point'),
        'company-resources-measuring-points-list'
      );
    }

    vm.dataConfig = {
      data: initForm(),
      header: headerConfig,
      edit: vm.editMode || vm.duplicateMode,
      action: {
        ctrlFn: true,
        title: vm.editMode
          ? vm.duplicateMode
            ? gettext('Create')
            : gettext('Update')
          : gettext('Create'),
        fn: createOrUpdate
      },
      redirect: {
        param: 'id',
        paramValue: measuringPointId,
        location: 'company-resources-measuring-points-view'
      }
    };

    if (vm.editMode || vm.duplicateMode) {
      vm.InitiatingForm = true;
      const measuringpoint = {
        ...measuringPoint,
        location: {
          ...measuringPoint.location,
          __prefetched__value__: true
        },
        energySourceType: {
          ...measuringPoint.energySourceType,
          __prefetched__value__: true
        }
      };
      if (measuringpoint.businessPartnerDistributor) {
        measuringpoint.businessPartnerDistributor.__prefetched__value__ = true;
      }
      if (measuringpoint.businessPartnerSupplier) {
        measuringpoint.businessPartnerSupplier.__prefetched__value__ = true;
      }
      var tags = [];
      if (measuringpoint.tags) {
        var item;
        tags = measuringpoint.tags.map(function(tag) {
          item = tag.systemTag;
          item.bindingId = tag._id; //tag service uses binding id to update/delete tag
          return item;
        });
      }
      if (vm.editMode && !vm.duplicateMode) {
        // only save original tags if it's an edit; when duplicating,
        // all tags need to be created, so we leave 'originalTags' empty
        originalTags = angular.copy(tags);
      }
      measuringpoint.systemTags = tags;
      constructFormData(measuringpoint);

      if (measuringpoint.counterType && measuringpoint.counterType.length) {
        currentlyActiveCounterType = measuringpoint.counterType[0];
        currentlyActiveCounterType.name =
          currentlyActiveCounterType.counterType.name;
        currentlyActiveCounterType.description =
          currentlyActiveCounterType.counterType.description;
        currentlyActiveCounterType._id =
          currentlyActiveCounterType.counterType._id;
        vm.dataConfig.dataObj.counterType = currentlyActiveCounterType;
        vm.dataConfig.dataObj.counterType.isIniting = true;
      }

      if (measuringpoint.owner && measuringpoint.owner.length) {
        currentlyActiveOwner = measuringpoint.owner[0];
        currentlyActiveOwner.name = currentlyActiveOwner.businessPartner.name;
        currentlyActiveOwner.description =
          currentlyActiveOwner.businessPartner.description;
        currentlyActiveOwner._id = currentlyActiveOwner.businessPartner._id;
        vm.dataConfig.dataObj.owner = currentlyActiveOwner;
        vm.dataConfig.dataObj.owner.isIniting = true;
      }

      if (measuringpoint.asset && measuringpoint.asset.length) {
        currentlyactiveAsset = measuringpoint.asset[0];
        currentlyactiveAsset.name = currentlyactiveAsset.asset.name;
        currentlyactiveAsset.description =
          currentlyactiveAsset.asset.description;
        currentlyactiveAsset._id = currentlyactiveAsset.asset._id;
        vm.dataConfig.dataObj.asset = currentlyactiveAsset;
        vm.dataConfig.dataObj.asset.isIniting = true;
      }

      if (
        measuringpoint.mainCostCentre &&
        measuringpoint.mainCostCentre.length
      ) {
        currentlyActiveCostCentre = measuringpoint.mainCostCentre[0];
        currentlyActiveCostCentre.name =
          currentlyActiveCostCentre.costCentre.name;
        currentlyActiveCostCentre.description =
          currentlyActiveCostCentre.costCentre.description;
        currentlyActiveCostCentre._id =
          currentlyActiveCostCentre.costCentre._id;
        vm.dataConfig.dataObj.mainCostCentre = currentlyActiveCostCentre;
        vm.dataConfig.dataObj.mainCostCentre.isIniting = true;
      }

      vm.InitiatingForm = false;
    } else {
      vm.dataConfig.dataObj = {
        priceListMasterSupplier: null,
        priceListMasterDistributor: null,
        businessPartner: {},
        asset: {},
        location: $state.params.locationId || null,
        hasUsage: true,
        mainCostCentre: {},
        owner: {},
        systemTags: [],
        maximumCapacity: null,
        physicalQuantity: null,
        measurementUnit: null,
        metricPrefix: null,
        triggerMaximumCapacity: false,
        physicalData: {}
      };
    }
  }

  function initForm() {
    var data = [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textArea',
        maxlength: 500,
        type: 'text',
        required: false
      },
      {
        placeholder: gettext('External Code'),
        name: 'externalCode',
        componentType: 'textField',
        type: 'text',
        required: false
      },
      {
        componentType: 'autocompleteDialog',
        name: 'location',
        edit: Boolean($state.params.locationId), // when creating MP out of location set edit to true
        configuration: {
          entity: 'locations',
          query: {
            entity: 'locations',
            method: 'read'
          },
          floatingLabel: gettext('Select Location'),
          change: function() {
            if (
              !vm.InitiatingForm &&
              vm.dataConfig.dataObj.location &&
              (!vm.dataConfig.dataObj.location.__prefetched__value__ ||
                $state.params.locationId != null)
            ) {
              if (vm.dataConfig.dataObj.location) {
                setOwnerMainCostCentre();
                setMeasuringpointCoordinatesOnLocationChange();
              }
            }

            if (vm.dataConfig.dataObj.location === null) {
              if (vm.dataConfig.dataObj.owner._id) {
                vm.dataConfig.dataObj.owner = {};
              }
              if (vm.dataConfig.dataObj.mainCostCentre._id) {
                vm.dataConfig.dataObj.mainCostCentre = {};
              }
            }
          },
          searchParamName: 'filter',
          createRedirect: {
            state: 'company-resources-locations-new'
          },
          required: true
        }
      },
      {
        componentType: 'autocompleteDialog',
        name: 'measuringpointType',
        configuration: {
          query: {
            entity: 'measuringpoint-types',
            method: 'read'
          },
          entity: 'measuringpoint-types',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Measuring Point Type')
          },
          floatingLabel: gettext('Measuring Point Type'),
          searchParamName: 'filter',
          required: true,
          createRedirect: {
            state: 'configurations-company-resources-measuring-point-types-new'
          }
        }
      },
      {
        componentType: 'autocompleteDialog',
        edit: vm.editMode || vm.duplicateMode,
        name: 'measuringpointKind',
        configuration: {
          codelist: 'measuringPointKinds',
          entity: 'measuringPointKinds',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Measuring Point Kind')
          },
          floatingLabel: gettext('Measuring Point Kind'),
          searchParamName: 'name',
          required: true,
          change: function() {
            checkOmEomCode();
          }
        }
      },
      {
        componentType: 'autocompleteDialog',
        edit: vm.editMode || vm.duplicateMode,
        name: 'measuringpointClass',
        configuration: {
          codelist: 'measuringPointClasses',
          entity: 'measuringPointClasses',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Measuring Point Class')
          },
          floatingLabel: gettext('Measuring Point Class'),
          searchParamName: 'name',
          required: true,
          change: function() {
            checkOmEomCode();
          }
        }
      },
      {
        componentType: 'autocompleteDialog',
        name: 'parent',
        configuration: {
          entity: 'measuringpoints',
          dialogConfiguration: {
            title: gettext('Select parent measuring point')
          },
          query: {
            entity: 'measuringpoints',
            method: 'read'
          },
          floatingLabel: gettext('Select Parent'),
          required: false,
          searchParamName: 'filter',
          createRedirect: {
            state: 'company-resources-measuring-points-new'
          }
        }
      },
      {
        componentType: 'autocompleteDialog',
        name: 'energySourceType',
        configuration: {
          required: true,
          entity: 'energy-source-types',
          floatingLabel: gettext('Select Energy Source Type'),
          selectedParam: 'energySourceType',
          query: {
            entity: 'energy-source-types',
            method: 'read'
          },
          searchParamName: 'filter',
          change: setCounterTypeFilter,
          createRedirect: {
            state: 'configurations-energy-management-energy-source-types-new'
          }
        }
      }
    ];

    if (!vm.editMode || vm.duplicateMode) {
      data.push(
        {
          componentType: 'title',
          title: gettext('Owner')
        },
        {
          componentType: 'elementSelector',
          entity: ['business-partners'],
          dialogConfigurations: [
            {
              title: gettext('Select owner'),
              filterObject: {
                order: 'name'
              }
            }
          ],
          title: gettext('Select owner'),
          name: 'owner',
          buttonTitle: gettext('+ Add Owner'),
          validFrom: 'validFrom',
          validTo: 'validTo',
          addAction: showDatePickerDialog.bind({
            name: 'owner',
            title: gettext('Add owner to measuring point')
          }),
          displayOptions: ['name', 'code'],
          valueField: '_id',
          createRedirect: {
            state: 'company-resources-business-partners-new'
          },
          required: true
        },
        {
          componentType: 'title',
          title: gettext('Main cost centre')
        },
        {
          componentType: 'elementSelector',
          entity: ['cost-centres'],
          dialogConfigurations: [
            {
              filterObject: {
                order: 'name'
              }
            }
          ],
          buttonTitle: gettext('+ Add Cost centre'),
          title: gettext('Select main cost centre'),
          addAction: showDatePickerDialog.bind({
            name: 'mainCostCentre',
            title: gettext('Add cost centre to measuring point')
          }),
          validFrom: 'validFrom',
          validTo: 'validTo',
          name: 'mainCostCentre',
          createRedirect: {
            state: 'company-resources-cost-centres-new'
          },
          required: false
        },
        {
          componentType: 'title',
          title: gettext('Asset')
        },
        {
          componentType: 'elementSelector',
          entity: ['assets'],
          name: 'asset',
          buttonTitle: gettext('+ Add Asset'),
          title: gettext('Select an asset'),
          addAction: showDatePickerDialog.bind({
            name: 'asset',
            title: gettext('Add asset to measuring point')
          }),
          validFrom: 'validFrom',
          validTo: 'validTo',
          createRedirect: {
            state: 'company-resources-assets-new'
          },
          required: false
        }
      );
    }

    data.push(
      {
        componentType: 'title',
        title: gettext('Distributor')
      },
      {
        name: 'businessPartnerDistributor',
        componentType: 'autocompleteDialog',
        configuration: {
          entity: 'business-partners',
          dialogConfiguration: {
            filterObject: {
              order: 'name',
              isDistributer: true
            },
            title: gettext('Select distributor')
          },
          searchParamName: 'name',
          floatingLabel: gettext('Select distributor'),
          query: {
            entity: 'business-partners',
            method: 'read'
          },
          change: setDistributerPriceListRefreshObject,
          filterObject: {
            isDistributer: true,
            order: 'name'
          },
          required: true,
          createRedirect: {
            state: 'company-resources-business-partners-new'
          }
        }
      },
      {
        placeholder: gettext('Distributor code'),
        name: 'distributorCode',
        componentType: 'textField',
        type: 'text'
      },
      {
        componentType: 'autocompleteDialog',
        configuration: {
          entity: 'price-list-masters',
          floatingLabel: gettext('Distributer price list'),
          query: {
            entity: 'price-list-masters',
            method: 'read'
          },
          searchParamName: 'filter',
          noDialog: true
        },
        name: 'priceListMasterDistributor',
        entity: 'price-list-masters'
      },
      {
        componentType: 'title',
        title: gettext('Supplier')
      },
      {
        componentType: 'autocompleteDialog',
        name: 'businessPartnerSupplier',
        configuration: {
          entity: 'business-partners',
          dialogConfiguration: {
            filterObject: {
              order: 'name',
              isDistributer: true
            },
            title: gettext('Select supplier')
          },
          searchParamName: 'name',
          floatingLabel: gettext('Select supplier'),
          query: {
            entity: 'business-partners',
            method: 'read'
          },
          change: setSupplierPriceListRefreshObject,
          filterObject: {
            isDistributer: true,
            order: 'name'
          },
          required: true,
          createRedirect: {
            state: 'company-resources-business-partners-new'
          }
        }
      },
      {
        placeholder: gettext('Supplier code'),
        name: 'supplierCode',
        componentType: 'textField',
        type: 'text'
      },
      {
        componentType: 'autocompleteDialog',
        configuration: {
          entity: 'price-list-masters',
          floatingLabel: gettext('Supplier price list'),
          query: {
            entity: 'price-list-masters',
            method: 'read'
          },
          searchParamName: 'filter',
          noDialog: true
        },
        name: 'priceListMasterSupplier',
        entity: 'price-list-masters'
      },
      {
        label: gettext('External'),
        name: 'isExternal',
        componentType: 'checkBox'
      },
      {
        placeholder: gettext('EOM Code'),
        name: 'eomCode',
        componentType: 'textField',
        required: false,
        hide: true,
        showParam: 'showEomCode'
      },
      {
        placeholder: gettext('Installation Number'),
        name: 'installationNumber',
        componentType: 'textField',
        required: false
      },
      {
        label: gettext('Has usage'),
        name: 'hasUsage',
        componentType: 'checkBox'
      }
    );

    if (!vm.editMode || vm.duplicateMode) {
      data.push(
        {
          componentType: 'title',
          title: gettext('Counter Type')
        },
        {
          componentType: 'elementSelector',
          entity: ['counter-types'],
          dialogConfigurations: [
            {
              filterObject: {
                energySourceType: ''
              }
            }
          ],
          title: gettext('Select counter type'),
          name: 'counterType',
          validFrom: 'validFrom',
          validTo: 'validTo',
          buttonTitle: gettext('+ Add Counter Type'),
          addAction: showDatePickerDialog.bind({
            name: 'counterType',
            title: gettext('Add counter type to measuring point')
          }),
          required: false,
          createRedirect: {
            state: 'configurations-energy-management-counter-types-new'
          },
          disabledFn: () => {
            return !(
              vm.dataConfig &&
              vm.dataConfig.dataObj &&
              vm.dataConfig.dataObj.energySourceType
            );
          }
        }
      );
    }

    data.push(
      {
        label: gettext('Maximum capacity'),
        name: 'triggerMaximumCapacity',
        componentType: 'checkBox',
        action: triggerMaximumCapacity
      },
      {
        componentType: 'textField',
        type: 'numerical',
        name: 'maximumCapacity',
        placeholder: gettext('Maximum Capacity'),
        required: false,
        hide: true,
        showParam: 'triggerMaximumCapacity'
      },
      {
        name: 'physicalQuantity',
        componentType: 'physicalAutocomplete',
        physicalQuantity: 'physicalQuantity',
        metricPrefix: 'metricPrefix',
        measurementUnit: 'measurementUnit',
        result: 'physicalData',
        required: false,
        hide: true,
        showParam: 'triggerMaximumCapacity'
      },
      {
        componentType: 'address',
        name: 'addressObject',
        noAddress: true,
        config: {
          noSetAddress: true,
          locationFromAddress: true
        }
      },
      {
        componentType: 'checkBoxesLinear',
        networkModel: {
          entity: 'system-tags',
          method: 'read'
        },
        name: 'systemTags',
        title: gettext('System Tags'),
        compareFn: function(item1, item2) {
          return item1._id === item2._id;
        }
      }
    );

    return data;
  }

  function triggerMaximumCapacity() {
    setConfigurationProperties(
      'physicalQuantity',
      ['required'],
      vm.dataConfig.dataObj.triggerMaximumCapacity
    );
    setConfigurationProperties(
      'maximumCapacity',
      ['required'],
      vm.dataConfig.dataObj.triggerMaximumCapacity
    );
    if (
      vm.dataConfig &&
      vm.dataConfig.dataObj &&
      vm.dataConfig.dataObj.maximumCapacity === null
    ) {
      vm.dataConfig.dataObj.maximumCapacity = 0;
    }
  }

  function setDistributerPriceListRefreshObject() {
    var refreshObject = {
      invoiceIssuer: vm.dataConfig.dataObj.businessPartnerDistributor
        ? vm.dataConfig.dataObj.businessPartnerDistributor._id
        : undefined,
      energySourceType: vm.dataConfig.dataObj.energySourceType
        ? vm.dataConfig.dataObj.energySourceType._id
        : undefined
    };
    setAutocompleteFilters('priceListMasterDistributor', refreshObject);
    if (
      !vm.dataConfig.dataObj.businessPartnerDistributor ||
      (vm.dataConfig.dataObj.businessPartnerDistributor &&
        !vm.dataConfig.dataObj.businessPartnerDistributor.__prefetched__value__)
    ) {
      vm.dataConfig.dataObj.priceListMasterDistributor = null;
    }
  }

  function setSupplierPriceListRefreshObject() {
    var refreshObject = {
      invoiceIssuer: vm.dataConfig.dataObj.businessPartnerSupplier
        ? vm.dataConfig.dataObj.businessPartnerSupplier._id
        : undefined,
      energySourceType: vm.dataConfig.dataObj.energySourceType
        ? vm.dataConfig.dataObj.energySourceType._id
        : undefined
    };
    setAutocompleteFilters('priceListMasterSupplier', refreshObject);
    if (
      !vm.dataConfig.dataObj.businessPartnerSupplier ||
      (vm.dataConfig.dataObj.businessPartnerSupplier &&
        !vm.dataConfig.dataObj.businessPartnerSupplier.__prefetched__value__)
    ) {
      vm.dataConfig.dataObj.priceListMasterSupplier = null;
    }
  }

  function setDistributerAndSupplierPriceListRefreshObject() {
    setDistributerPriceListRefreshObject();
    setSupplierPriceListRefreshObject();
  }

  function setCounterTypeFilter(selectedItems) {
    const selectedItem = selectedItems[0];
    if (
      !selectedItem &&
      ((vm.dataConfig.dataObj.energySourceType &&
        !vm.dataConfig.dataObj.energySourceType.__prefetched__value__) ||
        vm.dataConfig.dataObj.energySourceType === null)
    ) {
      vm.dataConfig.dataObj.counterType = null;
    }
    var counterConfig = _.find(vm.dataConfig.data, {
      name: 'counterType'
    });
    if (counterConfig && vm.dataConfig.dataObj.energySourceType) {
      if (
        // counter type is dependant on energy source type.
        // Should the energy source type change, we want the counter type to be set to null
        vm.dataConfig.dataObj.energySourceType._id !==
        counterConfig.dialogConfigurations[0].filterObject.energySourceType
      ) {
        vm.dataConfig.dataObj.counterType = null;
      }
      counterConfig.dialogConfigurations[0].filterObject = {
        energySourceType: vm.dataConfig.dataObj.energySourceType._id
      };
    }
    setDistributerAndSupplierPriceListRefreshObject();
  }

  function constructFormData(point) {
    let dataObj = {
      name: point.name,
      description: point.description,
      externalCode: point.externalCode,
      measuringpointType: point.measuringpointType,
      measuringpointKind: point.measuringpointKind,
      measuringpointClass: point.measuringpointClass,
      parent: point.parentId,
      energySourceType: point.energySourceType,
      businessPartnerDistributor: point.businessPartnerDistributor,
      businessPartnerSupplier: point.businessPartnerSupplier,
      priceListMasterDistributor: point.priceListMasterDistributor,
      priceListMasterSupplier: point.priceListMasterSupplier,
      location: point.location,
      asset: {},
      mainCostCentre: {},
      owner: {},
      triggerMaximumCapacity: !!point.maximumCapacity,
      maximumCapacity: point.maximumCapacity
        ? point.maximumCapacity.maximumCapacity
        : 0,
      physicalQuantity: point.maximumCapacity
        ? point.maximumCapacity.physicalQuantity
        : null,
      measurementUnit: point.maximumCapacity
        ? point.maximumCapacity.measurementUnit
        : null,
      metricPrefix: point.maximumCapacity
        ? point.maximumCapacity.metricPrefix
        : null,
      physicalData: {},
      distributorCode: point.distributorCode,
      supplierCode: point.supplierCode,
      eomCode: point.eomCode,
      installationNumber: point.installationNumber,
      hasUsage: point.hasUsage,
      isExternal: point.isExternal,
      systemTags: point.systemTags || []
    };
    let addressObject = {};
    if (point.geoLocation) {
      addressObject = {
        geolocationX: point.geoLocation[0],
        geolocationY: point.geoLocation[1],
        hasAddress: true
      };
    }
    dataObj = {
      ...dataObj,
      addressObject
    };

    vm.dataConfig.dataObj = dataObj;

    setDistributerPriceListRefreshObject();
    setSupplierPriceListRefreshObject();
    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(
        (vm.duplicateMode ? 'Duplicate ' : 'Edit ') + point.name,
        point.description
      );
    });
  }

  function showDatePickerDialog(object) {
    var itemName = this.name;
    DateTimeDialogService.openDialog({
      title: this.title,
      dateOnly: true,
      initialValues: object || vm.dataConfig.dataObj[itemName],
      readDateFromLocalStorage: true
    }).then(function(item) {
      if (!item && !object) {
        vm.dataConfig.dataObj[itemName] = {};
      } else if (item != null) {
        vm.dataConfig.dataObj[itemName].validTo = item.validTo;
        vm.dataConfig.dataObj[itemName].validFrom = item.validFrom;
      } else {
        vm.dataConfig.dataObj[itemName] = {};
      }
    });
  }

  function setMeasuringpointCoordinatesOnLocationChange() {
    var locationGeolocation = vm.dataConfig.dataObj.location
      ? vm.dataConfig.dataObj.location.geoLocation
      : null;
    if (locationGeolocation) {
      if (!vm.dataConfig.dataObj.addressObject) {
        vm.dataConfig.dataObj.addressObject = {};
      }
      if (hasAddress !== true) {
        vm.dataConfig.dataObj.addressObject.geolocationX =
          locationGeolocation[0];
        vm.dataConfig.dataObj.addressObject.geolocationY =
          locationGeolocation[1];
      }
    }
  }

  function getBusinessPartnerLocations(callback) {
    var location = vm.dataConfig.dataObj.location._id;
    BusinessPartnerLocationModel.custom
      .read({
        location: location,
        isActive: true,
        isMain: true
      })
      .then(
        function(res) {
          var owner = null;
          if (res.data && res.data.length) {
            owner = res.data[0];
          }
          callback(null, location, owner);
        },
        function(err) {
          AlertingService.Error(err);
          callback(null, location, null);
        }
      );
  }

  function getCostCentreLocations(location, owner, callback) {
    CostCentreLocationModel.custom
      .readWithCostCenter({
        location: location,
        isActive: true,
        isMain: true
      })
      .then(
        function(res) {
          var mainCostCentre = null;

          if (res.data && res.data.length) {
            mainCostCentre = res.data[0];
          }
          callback(null, owner, mainCostCentre);
        },
        function(err) {
          AlertingService.Error(err);
          callback(null, owner, null);
        }
      );
  }

  // get Owner and MainCostCentre based on Location id
  function setOwnerMainCostCentre() {
    var waterfall = [getBusinessPartnerLocations, getCostCentreLocations];
    if (vm.dataConfig.dataObj.location && !vm.editMode) {
      async.waterfall(waterfall, function(err, owner, mainCostCentre) {
        if (!err) {
          if (!vm.InitiatingForm) {
            if (owner) {
              vm.dataConfig.dataObj.owner = owner.businessPartner;
              vm.dataConfig.dataObj.owner.validFrom = owner.validFrom;
              vm.dataConfig.dataObj.owner.validTo = owner.validTo;
            } else {
              vm.dataConfig.dataObj.owner = {};
            }
            if (mainCostCentre) {
              vm.dataConfig.dataObj.mainCostCentre = mainCostCentre.costCentre;
              vm.dataConfig.dataObj.mainCostCentre.validFrom =
                mainCostCentre.validFrom;
              vm.dataConfig.dataObj.mainCostCentre.validTo =
                mainCostCentre.validTo;
            } else {
              vm.dataConfig.dataObj.mainCostCentre = {};
            }
          }
        } else {
          AlertingService.Error(err);
        }
      });
    }
  }

  function setAutocompleteFilters(name, filters) {
    var config = vm.dataConfig.data.find(function(config) {
      return config.name === name;
    });

    if (config && config.configuration) {
      if (!config.configuration.filterObject) {
        config.configuration.filterObject = {};
      }
      for (var key in filters) {
        config.configuration.filterObject[key] = filters[key];
      }

      if (config.configuration.dialogConfiguration) {
        config.configuration.dialogConfiguration.filterObject =
          config.configuration.filterObject;
      }
    }
  }

  function setConfigurationProperties(name, propertyNames, value) {
    var config = vm.dataConfig.data.find(function(config) {
      return config.name === name;
    });

    if (config) {
      propertyNames.forEach(function(itemName, index) {
        if (index === propertyNames.length - 1) {
          config[itemName] = value;
        } else {
          config = config[itemName];
        }
      });
    }
  }

  function checkOmEomCode() {
    var apiObject = vm.dataConfig.dataObj;
    if (apiObject) {
      var measuringpointClass = apiObject.measuringpointClass;
      var measuringpointKind = apiObject.measuringpointKind;

      var code = measuringpointClass ? measuringpointClass.code : null;
      if (code === '02') {
        apiObject.showEomCode = false;
      } else if (code === '013') {
        apiObject.showEomCode = true;
      } else {
        apiObject.showEomCode = false;
      }
      if (
        measuringpointKind &&
        measuringpointKind.id === 1 &&
        measuringpointClass &&
        measuringpointClass.id === 1
      ) {
        setConfigurationProperties(
          'businessPartnerDistributor',
          ['configuration', 'required'],
          true
        );
        setConfigurationProperties(
          'businessPartnerSupplier',
          ['configuration', 'required'],
          true
        );
        setConfigurationProperties('supplierCode', ['required'], true);
        setConfigurationProperties('distributorCode', ['required'], true);
        setConfigurationProperties(
          'priceListMasterSupplier',
          ['configuration', 'required'],
          true
        );
        setConfigurationProperties(
          'priceListMasterDistributor',
          ['configuration', 'required'],
          true
        );
      } else if (
        measuringpointKind &&
        measuringpointKind.id === 1 &&
        measuringpointClass &&
        measuringpointClass.id === 2
      ) {
        setConfigurationProperties(
          'businessPartnerDistributor',
          ['configuration', 'required'],
          true
        );
        setConfigurationProperties(
          'businessPartnerDistributor',
          ['configuration', 'required'],
          true
        );
        setConfigurationProperties(
          'businessPartnerSupplier',
          ['configuration', 'required'],
          true
        );
        setConfigurationProperties('supplierCode', ['required'], true);
        setConfigurationProperties('distributorCode', ['required'], true);
        setConfigurationProperties(
          'priceListMasterSupplier',
          ['configuration', 'required'],
          false
        );
        setConfigurationProperties(
          'priceListMasterDistributor',
          ['configuration', 'required'],
          false
        );
      } else {
        setConfigurationProperties(
          'businessPartnerDistributor',
          ['configuration', 'required'],
          false
        );
        setConfigurationProperties(
          'businessPartnerDistributor',
          ['configuration', 'required'],
          false
        );
        setConfigurationProperties(
          'businessPartnerSupplier',
          ['configuration', 'required'],
          false
        );
        setConfigurationProperties('supplierCode', ['required'], false);
        setConfigurationProperties('distributorCode', ['required'], false);
        setConfigurationProperties(
          'priceListMasterSupplier',
          ['configuration', 'required'],
          false
        );
        setConfigurationProperties(
          'priceListMasterDistributor',
          ['configuration', 'required'],
          false
        );
      }
    }
  }

  // create and update functions

  function createOrUpdate() {
    vm.sendingRequest = true;
    var editMode = vm.editMode && !vm.duplicateMode;
    const method = CrawlerMethods.getMethod({
      entity: 'measuringpoints',
      method: editMode ? 'update' : 'create'
    });
    let methodIdObject = {};
    if (editMode) {
      methodIdObject = {
        id: measuringPointId
      };
    }
    method(methodIdObject, dataToSave(vm.dataConfig.dataObj))
      .then(function(res) {
        const { data } = res;
        vm.dataConfig.dataObj._id = data._id;
        return EntityTagService.createSystemTags(
          vm.dataConfig.dataObj.systemTags,
          originalTags,
          2,
          data._id,
          false
        );
      })
      .then(function() {
        var toastMsg = vm.editMode && !vm.duplicateMode ? 'update' : 'create';
        var redirectObj = {
          state: 'company-resources-measuring-points-view',
          paramName: 'id',
          paramValue: vm.dataConfig.dataObj._id
        };
        if (!editMode || vm.duplicateMode) {
          createTimeDependentData().then(
            function() {
              vm.sendingRequest = false;
              CrudToastFactory.toast(toastMsg, redirectObj);
            },
            function(err) {
              vm.sendingRequest = false;
              AlertingService.Error(err);
              CrudToastFactory.toast(toastMsg, redirectObj);
            }
          );
        } else {
          CrudToastFactory.toast(toastMsg, redirectObj);
        }
      })
      .catch(function(err) {
        vm.sendingRequest = false;
        AlertingService.Error(err);
      });
  }

  function createMeasuringPointCounterType(callback) {
    if (
      vm.dataConfig.dataObj.counterType &&
      vm.dataConfig.dataObj.counterType._id
    ) {
      var postObject = {
        measuringpoint: vm.dataConfig.dataObj._id,
        counterType: vm.dataConfig.dataObj.counterType._id,
        validFrom: vm.dataConfig.dataObj.counterType.validFrom,
        validTo: vm.dataConfig.dataObj.counterType.validTo
      };
      MeasuringpointCounterTypeModel.create(postObject).then(
        function() {
          callback();
        },
        function(err) {
          AlertingService.Error(err);
          callback();
        }
      );
    } else {
      callback();
    }
  }

  function createOwnerMeasuringPoint(callback) {
    if (vm.dataConfig.dataObj.owner && vm.dataConfig.dataObj.owner._id) {
      const validTo =
        vm.dataConfig.dataObj.owner.validTo != null
          ? new Date(vm.dataConfig.dataObj.owner.validTo).setHours(
            23,
            59,
            59,
            999
          )
          : null;
      var postObj = {
        measuringpoint: vm.dataConfig.dataObj._id,
        businessPartner: vm.dataConfig.dataObj.owner._id,
        validFrom: vm.dataConfig.dataObj.owner.validFrom,
        validTo: validTo,
        isMain: true
      };
      BusinessPartnerMeasuringpointModel.create(postObj).then(
        function() {
          callback();
        },
        function(err) {
          AlertingService.Error(err);
          callback();
        }
      );
    } else {
      callback();
    }
  }

  function createCostCentreMeasuringPoint(callback) {
    if (
      vm.dataConfig.dataObj.mainCostCentre &&
      vm.dataConfig.dataObj.mainCostCentre._id
    ) {
      const validTo =
        vm.dataConfig.dataObj.mainCostCentre.validTo != null
          ? new Date(vm.dataConfig.dataObj.mainCostCentre.validTo).setHours(
            23,
            59,
            59,
            999
          )
          : null;
      var postObj = {
        measuringpoint: vm.dataConfig.dataObj._id,
        costCentre: vm.dataConfig.dataObj.mainCostCentre._id,
        validFrom: vm.dataConfig.dataObj.mainCostCentre.validFrom,
        validTo: validTo,
        isMain: true
      };

      CostCentreMeasuringpointModel.create(postObj).then(
        function() {
          callback();
        },
        function(err) {
          AlertingService.Error(err);
          callback();
        }
      );
    } else {
      callback();
    }
  }

  function createAssetMeasuringPoint(callback) {
    if (vm.dataConfig.dataObj.asset && vm.dataConfig.dataObj.asset._id) {
      var postObj = {
        measuringpoint: vm.dataConfig.dataObj._id,
        asset: vm.dataConfig.dataObj.asset._id,
        validFrom: vm.dataConfig.dataObj.asset.validFrom,
        validTo: vm.dataConfig.dataObj.asset.validTo
      };

      AssetMeasuringpointModel.create(postObj).then(
        function() {
          callback();
        },
        function(err) {
          AlertingService.Error(err);
          callback();
        }
      );
    } else {
      callback();
    }
  }

  function createTimeDependentData() {
    var deferred = $q.defer();
    var parallel = [
      createMeasuringPointCounterType,
      createOwnerMeasuringPoint,
      createCostCentreMeasuringPoint,
      createAssetMeasuringPoint
    ];

    async.parallel(parallel, function(err) {
      if (!err) {
        deferred.resolve();
      } else {
        deferred.reject(err);
      }
    });

    return deferred.promise;
  }

  function dataToSave(point) {
    return {
      name: point.name,
      description: point.description,
      externalCode:
        point.externalCode != null && point.externalCode !== ''
          ? point.externalCode
          : null,
      distributorCode: point.distributorCode || null,
      supplierCode: point.supplierCode || null,
      eomCode:
        vm.dataConfig.dataObj.showEomCode && point.eomCode !== ''
          ? point.eomCode
          : null,
      businessPartnerDistributor: point.businessPartnerDistributor
        ? point.businessPartnerDistributor._id
        : null,
      businessPartnerSupplier: point.businessPartnerSupplier
        ? point.businessPartnerSupplier._id
        : null,
      priceListMasterSupplier: point.priceListMasterSupplier
        ? point.priceListMasterSupplier._id
        : null,
      priceListMasterDistributor: point.priceListMasterDistributor
        ? point.priceListMasterDistributor._id
        : null,
      energySourceType: point.energySourceType
        ? point.energySourceType._id
        : null,
      measuringpointClass: point.measuringpointClass.id,
      measuringpointKind: point.measuringpointKind.id,
      measuringpointType: point.measuringpointType._id,
      isExternal: point.isExternal,
      hasUsage: point.hasUsage,
      installationNumber: point.installationNumber,
      maximumCapacity: point.triggerMaximumCapacity
        ? {
          maximumCapacity: point.maximumCapacity,
          physicalQuantity: point.physicalData.physicalQuantity,
          metricPrefix: point.physicalData.metricPrefix,
          measurementUnit: point.physicalData.measurementUnit
        }
        : null,
      geoLocation: [
        point.addressObject.geolocationX,
        point.addressObject.geolocationY
      ],
      parentId: point.parent ? point.parent._id : null,
      location: point.location ? point.location._id : null
    };
  }
}
export default NewMeasuringPointFormController;
