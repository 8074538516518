import template from './tango-wizard.component.html';
import './tango-wizard.scss';
/**
 * @ngdoc component
 * @name common.tangoWizard
 * @description Generates form data, fields and buttons for every step.
 * @param {number} entity
 * @param {string} entityId
 * @param {Object} options
 * @example
 * <tango-wizard
 * entity="vm.entity"
 * entity-id="vm.entityId"
 * options="vm.options"
 * ></tango-wizard>
 */
export default {
  template,
  bindings: {
    entity: '<',
    entityId: '<',
    options: '<?'
  },
  controller: TangoWizardController,
  controllerAs: 'vm'
};

TangoWizardController.$inject = [
  'TangoWizardConfiguration',
  '$scope',
  '$timeout',
  '$ngRedux',
  'TimeSeriesWizardDuplicateProperties',
  'gettext'
];

function TangoWizardController(
  TangoWizardConfiguration,
  $scope,
  $timeout,
  $ngRedux,
  TimeSeriesWizardDuplicateProperties,
  gettext
) {
  const vm = this;
  vm.currentStep = {};
  vm.data = {};
  vm.indexOfCurrentStep = 0;
  vm.timeSeriesData;
  vm.menuWidth = 4;
  vm.$onChanges = async () => {
    if (vm.entityId != null) {
      let state = { ...$ngRedux.getState() };
      await getDuplicateData(state);
    }
    if (vm.entity != null) {
      initializeWizard();
    }
  };

  $scope.$on('$destroy', () => {
    watchSteps();
  });

  let watchSteps = $scope.$watchCollection(
    () => {
      if (vm.configuration != null && Array.isArray(vm.configuration.steps)) {
        return vm.configuration.steps;
      }
    },
    (newValue, oldValue) => {
      if (
        Array.isArray(newValue) &&
        Array.isArray(oldValue) &&
        newValue.length > oldValue.length
      ) {
        $timeout(() => {
          vm.moveToStep(vm.configuration.steps.length - 1);
        });
      }
    }
  );

  /**
   * @description  get duplicate data for entities.
   * @function
   * @param {Object} state store state object
   */
  const getDuplicateData = async state => {
    vm.timeSeriesData = await TimeSeriesWizardDuplicateProperties.get(
      state,
      vm.entityId,
      vm.options
    );
  };

  /**
   * @description initialize wizard form.
   * @function
   */
  const initializeWizard = async () => {
    let item;
    if (vm.timeSeriesData != null) {
      item = vm.timeSeriesData;
    }
    let onSaveAction;
    if (vm.options != null && typeof vm.options.onSaveAction === 'function') {
      onSaveAction = vm.options.onSaveAction;
    }
    vm.configuration = await TangoWizardConfiguration.get(
      item,
      vm.entity,
      vm.entityId,
      onSaveAction
    );
    let formButtons = getFormButtons();
    if (vm.configuration != null) {
      vm.menuWidth = vm.configuration.addConfiguration
        ? vm.configuration.addConfiguration.menuWidth
        : 4;
      vm.actions = [
        ...formButtons,
        vm.configuration.addConfiguration,
        ...vm.configuration.actions
      ];
      vm.currentStep = { ...vm.configuration.steps[0] };
    }
    $scope.$evalAsync();
    if (vm.timeSeriesData != null) {
      $timeout(() => {
        revalidateWizard();
      });
    }
  };

  const revalidateWizard = () => {
    vm.configuration.steps.forEach(step => {
      if (typeof step.api.getValues == 'function') {
        vm.data[step.stepId] = step.api.getValues();
      }
    });
  };

  /**
   * @description create form buttons for wizard (back, next).
   * @function
   * @return {Array} backButton, nextButton (Objects)
   */
  const getFormButtons = () => {
    let backButton = {
      title: gettext('Back'),
      fn: () => {
        if (vm.indexOfCurrentStep > 0) {
          vm.data[vm.currentStep.stepId] = vm.currentStep.api.getValues();
          vm.indexOfCurrentStep -= 1;
          vm.currentStep = { ...vm.configuration.steps[vm.indexOfCurrentStep] };
        }
      },
      disabledFn: () => {
        if (vm.indexOfCurrentStep === 0) {
          return true;
        }
        return false;
      },
      color: 'primary'
    };
    let nextButton = {
      title: gettext('Next step'),
      fn: () => {
        if (vm.indexOfCurrentStep < vm.configuration.steps.length - 1) {
          vm.data[vm.currentStep.stepId] = vm.currentStep.api.getValues();
          vm.indexOfCurrentStep += 1;
          vm.currentStep = { ...vm.configuration.steps[vm.indexOfCurrentStep] };
        }
      },
      disabledFn: () => {
        if (vm.indexOfCurrentStep === vm.configuration.steps.length - 1) {
          return true;
        }
        return false;
      },
      color: 'primary'
    };
    return [backButton, nextButton];
  };

  /**
   * @description move to selected step based on index.
   * @function
   * @param {number} index
   */
  vm.moveToStep = index => {
    let toStep = { ...vm.configuration.steps[index] };
    // If selected step is current step do nothing.
    if (toStep.stepId !== vm.currentStep.stepId) {
      vm.data[vm.currentStep.stepId] = vm.currentStep.api.getValues();
      vm.indexOfCurrentStep = index;
      vm.currentStep = { ...vm.configuration.steps[index] };
      vm.data[vm.currentStep.stepId] = vm.currentStep.api.getValues();
    }
  };

  /**
   * @description remove step from configuration
   * @function
   * @param {Object} stepToRemove
   * @param {number} indexOfStep
   */
  vm.removeStep = (stepToRemove, indexOfStep) => {
    if (vm.indexOfCurrentStep === indexOfStep) {
      delete vm.data[vm.currentStep.stepId];
      if (vm.indexOfCurrentStep + 1 === vm.configuration.steps.length) {
        vm.indexOfCurrentStep -= 1;
        vm.configuration.steps.splice(vm.indexOfCurrentStep + 1, 1);
      } else {
        vm.configuration.steps.splice(vm.indexOfCurrentStep, 1);
      }
      vm.currentStep = { ...vm.configuration.steps[vm.indexOfCurrentStep] };
    } else {
      delete vm.data[stepToRemove.stepId];
      vm.configuration.steps.splice(indexOfStep, 1);
    }
  };

  /**
   * @description add step to configuration and if current step is last move to newly added step
   * @function
   * @param {Object} item - selected step
   */
  vm.addStep = item => {
    if (item != null && typeof item.fn === 'function') {
      item.fn();
    }
  };
}
