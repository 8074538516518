//import scss
import './styles/analysis-chart.scss';
import './styles/analysis-filter.scss';
import './styles/location-properties.scss';
import './styles/report.scss';

//controllers
import AnalysisController from './pages/analysis/analysis.controller.js';
import NewAnalysisController from './pages/analysis/new-analysis.controller.js';
import TimeIntervalFilterController from './pages/time-interval-filter/time-interval-filter.controller';
import NewTimeIntervalFilterController from './pages/time-interval-filter/new-time-interval-filter.controller';
import NewDatasetController from './pages/dataset/new-dataset.controller';
import DatasetController from './pages/dataset/dataset.controller';
import NewExpressionController from './pages/expression/new-expression.controller';
import ExpressionController from './pages/expression/expression.controller';
import NewAggregationController from './pages/aggregation/new-aggregation.controller';
import AggregationController from './pages/aggregation/aggregation.controller';
import NewCalculationController from './pages/calculation/new-calculation.controller';
import CalculationController from './pages/calculation/calculation.controller';
import NewReportController from './pages/report/new-report.controller';
import ReportController from './pages/report/report.controller';
import ReportingEditorController from './pages/report/reporting-editor.controller';
import ReportingReportViewerController from './pages/report/reporting-report-viewer.controller';
//components
import analysisChart from './components/analysis-chart/analysis-chart.component';
import analysisFormFilterRepeat from './components/analysis-form-filter-repeat/analysis-form-filter-repeat.component';
import analysisChartDashboard from './components/analysis-chart-dashboard/analysis-chart-dashboard.component';
//directives
import analysisFilters from './directives/analysis-filters/analysis-filters.directive';
//factories
import analysisChartConfiguration from './factories/analysis-chart-configuration/analysis-chart-configuration.factory';
import analysisQueryBuilder from './factories/analysis-query-builder/analysis-query-builder.factory';
import analysisSliceService from './factories/analysis-slice/analysis-slice.factory';
import DatasetColumnHelper from './factories/dataset-column-helper/dataset-column-helper.factory';
import generateEntityReport from './factories/generateEntityReport/generateEntityReport.factory';
import periodIntervalConstructor from './factories/period-interval-constructor/period-interval-constructor.service';
import AnalysisFilterBuilder from './factories/analysis-filter-builder/analysis-filter-builder.factory';
import AnalysisExpressionEnrichment from './factories/analysis-expression-enrichment/analysis-expression-enrichement.factory';
//configurations (they fall under factories)
import AggregationGroupConfiguration from './configurations/aggregation-group.configuration';
import AnalysisConfiguration from './configurations/analysis.configuration';
import CalculationConfiguration from './configurations/calculation.configuration';
import DatasetConfiguration from './configurations/dataset.configuration';
import ExpressionConfiguration from './configurations/expression.configuration';
import reportConfiguration from './configurations/reports.configuration';
import TimeIntervalFilterConfiguration from './configurations/time-interval-filter.configuration';
//network models (services)
import AggregationGroupModel from './network-models/AggregationGroup.model';
import AnalysesModel from './network-models/Analyses.model';
import AnalysisCalculationModel from './network-models/AnalysisCalculation.model';
import AnalysisFilterConfigModel from './network-models/AnalysisFilterConfig.model';
import DatasetModel from './network-models/Dataset.model';
import ExpressionModel from './network-models/Expression.model';
import PeriodIntervalModel from './network-models/PeriodInterval.model';
import ReportModel from './network-models/Report.model';
import ReportGeneralModel from './network-models/ReportGeneral.model';
// services
import LongQueryHelper from './factories/long-query.helper';

import routes from './analytics.routes';

export default {
  routes,
  controllers: {
    AnalysisController,
    NewAnalysisController,
    TimeIntervalFilterController,
    NewTimeIntervalFilterController,
    NewDatasetController,
    DatasetController,
    NewExpressionController,
    ExpressionController,
    NewAggregationController,
    AggregationController,
    NewCalculationController,
    CalculationController,
    NewReportController,
    ReportController,
    ReportingEditorController,
    ReportingReportViewerController
  },
  components: {
    analysisChart,
    analysisFormFilterRepeat,
    analysisChartDashboard
  },
  directives: {
    analysisFilters
  },
  services: {
    AggregationGroupModel,
    AnalysesModel,
    AnalysisCalculationModel,
    AnalysisFilterConfigModel,
    DatasetModel,
    ExpressionModel,
    PeriodIntervalModel,
    ReportModel,
    ReportGeneralModel,
    LongQueryHelper
  },
  factories: {
    analysisChartConfiguration,
    analysisQueryBuilder,
    analysisSliceService,
    DatasetColumnHelper,
    generateEntityReport,
    periodIntervalConstructor,
    AggregationGroupConfiguration,
    AnalysisConfiguration,
    TimeIntervalFilterConfiguration,
    CalculationConfiguration,
    DatasetConfiguration,
    ExpressionConfiguration,
    reportConfiguration,
    AnalysisFilterBuilder,
    AnalysisExpressionEnrichment
  }
};
