module.exports = {
  entity: 'monitor-and-target-analysis-flow',
  path: 'monitor-and-target-analysis-flow',
  entityId: 244,
  storeKey: 'monitorAndTargetAnalysisFlow',
  name: {
    en: 'Monitor and target analysis flow',
    sl_SI: 'Monitor and target analysis flow'
  },
  networkModel: {
    entity: 'monitor-and-target-analysis-flow',
    read: 'read'
  },
  scope: 'tis_be:mt-analysis-flow',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select monitor-and-target-analysis-flow',
      sl_SI: 'Izberite monitor-and-target-analysis-flow'
    },
    listInfo: {
      en: 'Select monitor-and-target-analysis-flow from the list below',
      sl_SI: 'Izberite monitor-and-target-analysis-flow s spodnjega seznama'
    }
  }
}
