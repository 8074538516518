CostCentreController.$inject = [
  'MetadataService',
  '$state',
  'gettext',
  'gettextCatalog',
  'TranslationService',
  'HierarchyTreeDialog',
  'costCentre',
  '$timeout'
];

function CostCentreController(
  MetadataService,
  $state,
  gettext,
  gettextCatalog,
  TranslationService,
  HierarchyTreeDialog,
  costCentre,
  $timeout
) {
  const vm = this;
  const costCentreId = $state.params.id;
  vm.costCentreFilter = {
    costCentre: costCentreId
  };
  vm.listCostCentres = {
    entity: 'cost-centres',
    method: 'read'
  };

  vm.treeElementSelected = treeElementSelected;
  vm.$onInit = function init() {
    vm.loading = true;
    MetadataService.Loading(true);

    vm.costCentreType = TranslationService.GetCollectionById(
      'codelists.costCentreTypes',
      costCentre.costCentreType
    );
    vm.headerData = constructHeader(costCentre);
    $timeout(() => {
      vm.loading = false;
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(costCentre.name, costCentre.description);
    });
  };

  function treeElementSelected(element) {
    element.properties = [];
    element.state = 'company-resources-cost-centres-view';
    element.redirectParams = {
      id: element._id
    };

    if (element.description) {
      element.properties.push({
        title: gettext('Description'),
        value: element.description
      });
    }
    element.properties.push(
      {
        title: gettext('Code'),
        value: element.code,
        code: true
      },
      {
        title: gettext('External'),
        value: element.isExternal
          ? gettextCatalog.getString('Yes')
          : gettextCatalog.getString('No'),
        color: element.isExternal ? 'green' : 'red'
      }
    );
  }

  function constructHeader(costCentre) {
    var properties = [
      {
        type: 'radio',
        title: gettext('External'),
        value: costCentre.isExternal,
        customOptions: ['YES', 'NO']
      },
      {
        title: gettext('Code'),
        value: costCentre.code,
        type: 'code'
      },
      {
        title: gettext('Cost centre type'),
        value: vm.costCentreType.name,
        type: 'simple'
      }
    ];
    if (costCentre.project) {
      properties.push({
        title: gettext('Project'),
        linkTitle: costCentre.project.name,
        type: 'link',
        state: 'company-resources-projects-view',
        param: 'id',
        paramValue: costCentre.project._id
      });
    }

    if (costCentre.companyStructureNode) {
      properties.push({
        title: gettext('Parent company structure node'),
        linkTitle: `${costCentre.companyStructureNode.name} (${costCentre.companyStructureNode.code})`,
        type: 'link',
        state: 'configurations-company-resources-company-structure-nodes-view',
        param: 'id',
        paramValue: costCentre.companyStructureNode._id
      });
    }

    if (costCentre.profitCentre) {
      properties.push({
        title: gettext('Profit Centre'),
        linkTitle: `${costCentre.profitCentre.name} (${costCentre.profitCentre.code})`,
        type: 'link',
        state: 'company-resources-profit-centres-view',
        param: 'id',
        paramValue: costCentre.profitCentre._id
      });
    }

    if (costCentre.externalCode) {
      properties.push({
        title: gettext('External Code'),
        value: costCentre.externalCode,
        type: 'code'
      });
    }

    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];

    const tags = [
      {
        type: 'tags',
        paramName: 'name',
        values:
          costCentre && Array.isArray(costCentre.tags)
            ? costCentre.tags.map(function(tag) {
              return tag.systemTag;
            })
            : null
      }
    ];

    var propertySections = [
      {
        title: gettext('Properties'),
        properties: properties
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'cost-centres',
        target: costCentreId,
        type: 'userAccess'
      },
      {
        title: gettext('System tags'),
        properties: tags
      }
    ];

    var actions = [
      {
        title: gettext('Show Hierarchy'),
        fn: function() {
          HierarchyTreeDialog.openDialog(costCentre, 'cost-centres');
        }
      },
      {
        title: gettext('delete'),
        delete: true,
        id: costCentre._id,
        endpoint: {
          entity: 'cost-centres',
          method: 'delete'
        },
        redirectState: 'company-resources-cost-centres-list'
      },
      {
        title: gettext('Update'),
        state: 'company-resources-cost-centres-edit',
        param: 'id',
        paramValue: costCentre._id
      }
    ];
    return {
      metadata: {
        definition: gettext('cost centre'),
        title: costCentre.name,
        description: costCentre.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}

export default CostCentreController;
