import './physical-autocomplete.scss';
import template from './physical-autocomplete.html';
/**
 * @ngdoc component
 * @name common.physicalAutocomplete
 * @description Two autocompletes, the first one used for selecting the physical quantity, the second use for determening unit of measurment (including unit prefix)
 * @param {string} physicalQuantity - id of pyhsical quantity
 * @param {number} metricPrefix - id of the metrix prefix (acquired from a codelist)
 * @param {string} measurementUnit - id of the measurment unit
 * @param {object} result - object containing the metrix prefix and unit of measurment selected
 * @param {boolean} required - tells us if the values are required
 * @param {string} physicalLabel - label shown in the autocomplete for physical quantities
 * @param {string} unitLabel - label shown in the autocomplete for unit of measurment
 * @example
 * <physical-autocomplete
 * physicalQuantity="vm.pyhsicalQuantityId"
 * metricPrefix="vm.metricprefix"
 * measurementUnit="measurementUnit"
 * result="vm.result"
 * required="true"
 * physicalLabel="'default physical quantity'"
 * unitLabel="'default unit of measurement'"
 * ></physical-autocomplete>
 */
export default {
  template,
  bindings: {
    physicalQuantity: '<',
    metricPrefix: '<',
    measurementUnit: '<',
    result: '=',
    required: '<',
    physicalLabel: '<',
    unitLabel: '<'
  },
  require: {
    parentForm: '^^form'
  },
  controller: PhysicalAutocompleteController,
  controllerAs: 'vm'
};

PhysicalAutocompleteController.$inject = [
  'gettext',
  'AlertingService',
  'TranslationService',
  '$q',
  '$scope',
  'PhysicalCollectionService'
];

function PhysicalAutocompleteController(
  gettext,
  AlertingService,
  TranslationService,
  $q,
  $scope,
  PhysicalCollectionService
) {
  var vm = this;
  var measurementUnits = [];
  var metricPrefixes = [];

  vm.$onInit = initiate;
  vm.measurementDataIsdisabled = true;

  vm.$onChanges = function(changes) {
    if (changes.physicalQuantity && vm.physicalQuantity) {
      if (!vm.model) {
        vm.model = {};
      }
      setPhysicalQuantity();
    }

    if (changes.measurementUnit && vm.measurementUnit) {
      if (!vm.model) {
        vm.model = {};
      }
      setMeasurementUnitAndMetricPrefix();
    }

    if (changes.required) {
      vm.displayItems = true;
      vm.physicalQuantityConfiguration.required = vm.required;
      vm.measurementDataConfiguration.required = vm.required;
    }
    if (changes.physicalLabel && vm.physicalLabel) {
      vm.physicalQuantityConfiguration.floatingLabel = vm.physicalLabel;
      vm.physicalQuantityConfiguration.dialogConfiguration.title =
        vm.physicalLabel;
    }
    if (changes.unitLabel && vm.unitLabel) {
      vm.measurementDataConfiguration.floatingLabel = vm.unitLabel;
      vm.measurementDataConfiguration.dialogConfiguration.title = vm.unitLabel;
    }
  };

  vm.physicalQuantityConfiguration = {
    query: {
      /**
       * @description fetches cached physical quantities and filters them.
       * @function
       * @param {Object} filterObject name filter
       * @return {Promise}
       */
      query: function(filterObject) {
        var deferred = $q.defer();
        PhysicalCollectionService.returnPhysicalQuantities().then(
          function(res) {
            var items = [];
            if (res) {
              if (filterObject && filterObject.filter) {
                items = res.filter(function(physicalQuantity) {
                  if (physicalQuantity.name) {
                    return (
                      physicalQuantity.name
                        .toLowerCase()
                        .indexOf(filterObject.filter.toLowerCase()) > -1
                    );
                  } else {
                    return false;
                  }
                });
              } else {
                items = res;
              }
            }
            deferred.resolve({ data: items });
          },
          function(err) {
            deferred.reject(err);
          }
        );
        return {
          $promise: deferred.promise
        };
      }
    },
    createRedirect: {
      state: 'configurations-iot-units-of-measurement-list'
    },
    entity: 'physical-quantities',
    dialogConfiguration: {
      multiple: false,
      title: gettext('Select physical quantity')
    },
    floatingLabel: gettext('Select physical quantity'),
    searchParamName: 'filter',
    required: vm.required,
    change: function() {
      vm.measurementDataIsdisabled = !(
        vm.model.physicalQuantity && vm.model.physicalQuantity._id
      );
      if (vm.measurementDataIsdisabled) {
        vm.model.measurementData = null;
        vm.result.physicalQuantity = null;
        vm.result.measurementUnit = null;
        vm.metricPrefix = null;
      } else {
        vm.result.physicalQuantity = vm.model.physicalQuantity._id;
      }
    }
  };

  vm.measurementDataConfiguration = {
    query: {
      /**
       * @description function that creates a list of items we see in the second autocomplete.
       * @function
       * @param {object} filter - contains inforamtion about the values we want to filter.
       * @return {promise}
       */
      query: function(filter) {
        var deferred = $q.defer();
        if (vm.model.physicalQuantity) {
          var allowedMeasurementUnits = measurementUnits.filter(function(item) {
            return item.physicalQuantity === vm.model.physicalQuantity._id;
          });
          var allowedItems = [];
          allowedMeasurementUnits.forEach(function(measurementUnit) {
            metricPrefixes.forEach(function(metricPrefix) {
              allowedItems.push(
                setMeasurementDataObject(metricPrefix, measurementUnit)
              );
            });
          });

          if (filter.searchString) {
            allowedItems = allowedItems.filter(function(item) {
              return (
                item.displayName
                  .toLowerCase()
                  .indexOf(filter.searchString.toLowerCase()) > -1
              );
            });
          }
          deferred.resolve({
            data: allowedItems
          });
        } else {
          deferred.resolve({
            data: []
          });
        }
        return {
          $promise: deferred.promise
        };
      }
    },
    displayFields: ['displayName'],
    noDialog: true,
    entity: 'measurement-units',
    dialogConfiguration: {
      multiple: false,
      title: gettext('Select unit of measurement')
    },
    filterObject: {
      order: 'name'
    },
    floatingLabel: gettext('Select unit of measurement'),
    searchParamName: 'searchString',
    required: vm.required,
    change: function() {
      if (vm.model && vm.model.measurementData) {
        vm.result.metricPrefix = vm.model.measurementData.metricPrefixId;
        vm.result.measurementUnit = vm.model.measurementData.measurementUnitId;
      } else {
        vm.result.metricPrefix = null;
        vm.result.measurementUnit = null;
      }
    }
  };
  /**
   * @description sets the physical quantity when called
   * @function
   */
  function setPhysicalQuantity() {
    if (vm.physicalQuantity.name) {
      vm.model.physicalQuantity = vm.physicalQuantity;
      vm.result.physicalQuantity = vm.physicalQuantity;
      vm.measurementDataIsdisabled = false;
    } else {
      PhysicalCollectionService.returnPhysicalQuantities(
        vm.physicalQuantity
      ).then(
        function(physicalQuantityItem) {
          vm.model.physicalQuantity = physicalQuantityItem;
          vm.result.physicalQuantity = vm.physicalQuantity;
          vm.measurementDataIsdisabled = false;
        },
        function(err) {
          AlertingService.Error(err);
        }
      );
    }
  }
  /**
   * @description sets the measurment unit and unit prefix when called.
   * @function
   */
  function setMeasurementUnitAndMetricPrefix() {
    var selectedMetrikPrefix = TranslationService.GetCollectionById(
      'codelists.metricPrefixes',
      vm.metricPrefix
    );
    if (vm.measurementUnit && vm.measurementUnit._id) {
      vm.model.measurementData = setMeasurementDataObject(
        selectedMetrikPrefix,
        vm.measurementUnit
      );
    } else {
      if (measurementUnits.length > 0) {
        var selectedMeaurementUnit = measurementUnits.find(function(item) {
          return item._id === vm.measurementUnit;
        });
        if (selectedMeaurementUnit) {
          vm.model.measurementData = setMeasurementDataObject(
            selectedMetrikPrefix,
            selectedMeaurementUnit
          );
        }
      } else {
        PhysicalCollectionService.returnMeasurementUnits(
          vm.measurementUnit
        ).then(
          function(measurementUnit) {
            if (measurementUnit) {
              vm.model.measurementData = setMeasurementDataObject(
                selectedMetrikPrefix,
                measurementUnit
              );
            }
            vm.result.metricPrefix = vm.metricPrefix;
            vm.result.measurementUnit = vm.measurementUnit;
          },
          function(err) {
            AlertingService.Error(err);
          }
        );
      }
    }
  }
  /**
   * @description creates an measurment data object that contains the metric prefixId, measurmentId and a human readable combination of the two
   * @function
   * @param {object} metricPrefix - the metricPrefix object
   * @param {object} measurementUnit - measurement unit object
   * @return {object} object containing metricPrefixId, measurmentUnitId and a displayName
   */
  function setMeasurementDataObject(metricPrefix, measurementUnit) {
    var measurementUnitId;
    var measurementUnitName = '';
    var measurementUnitSymbol = '';
    if (measurementUnit) {
      measurementUnitId = measurementUnit._id;
      measurementUnitName = measurementUnit.name;
      measurementUnitSymbol = measurementUnit.symbol;
    }
    return {
      metricPrefixId: metricPrefix.id,
      measurementUnitId: measurementUnitId,
      displayName: `${metricPrefix.name} ${measurementUnitName} (${metricPrefix.symbol}${measurementUnitSymbol})`
    };
  }
  /**
   * @description function called on component initilization.
   * @function
   */
  function initiate() {
    if (vm.parentForm) {
      $scope.ItemForm = vm.parentForm;
    }
    metricPrefixes = TranslationService.GetCollection(
      'codelists.metricPrefixes'
    );
    PhysicalCollectionService.returnMeasurementUnits().then(
      function(measurementUnitItems) {
        measurementUnits = measurementUnitItems;
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }
}
