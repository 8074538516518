module.exports = {
  entity: 'readingTypes',
  path: 'readingTypes',
  name: {
    en: 'Reading types',
    sl_SI: 'Vrste odčitka'
  },
  codelist: 'readingTypes',
  selectedParamName: 'id',
  scope: 'tis_be:reading-types',

  dialog: {
    codelist: 'readingTypes',
    title: {
      en: 'Select reading type',
      sl_SI: 'Izberite vrsto odčitka'
    },
    listInfo: {
      en: 'Select reading type from the list below',
      sl_SI: 'Izberite vrsto odčitka s spodnjega seznama'
    }
  }
}
