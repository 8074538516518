TimeIntervalFilterController.$inject = [
  '$stateParams',
  'gettext',
  'gettextCatalog',
  'MetadataService',
  'periodInterval',
  '$timeout'
];

function TimeIntervalFilterController(
  $stateParams,
  gettext,
  gettextCatalog,
  MetadataService,
  periodInterval,
  $timeout
) {
  var vm = this;
  var timeIntervalFilterId = $stateParams.id;

  init();

  function init() {
    vm.loading = true;
    vm.headerData = constructHeader(periodInterval);
    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(
        periodInterval.name,
        periodInterval.description
      );
    });
    vm.loading = false;
  }

  function constructHeader(interval) {
    var properties = [];
    var propertySections = [];
    if (interval.startDate && interval.endDate) {
      properties = properties.concat([
        {
          type: 'date',
          title: gettext('Start date'),
          value: interval.startDate,
          dateFormat: 'dd. MM. yyyy'
        },
        {
          type: 'date',
          title: gettext('End date'),
          value: interval.endDate,
          dateFormat: 'dd. MM. yyyy'
        }
      ]);
      propertySections = propertySections.concat([
        {
          title: gettext('Type'),
          properties: [
            {
              type: 'simple',
              title: gettext('Filter type:'),
              value: gettextCatalog.getString('Fixed')
            }
          ]
        },
        {
          title: gettextCatalog.getString('Time period'),
          properties: properties
        }
      ]);
    } else {
      if (interval.periodType === 'month') {
        properties = properties.concat([
          {
            type: 'simple',
            title: gettext('Last N months'),
            value: interval.value
          }
        ]);
      } else {
        properties = properties.concat([
          {
            type: 'simple',
            title: gettext('Last N years'),
            value: interval.value
          }
        ]);
      }
      var includeStart = false;
      var includeEnd = false;
      if (interval.inclusionType === 'opened') {
        includeStart = true;
        includeEnd = true;
      } else if (interval.inclusionType === 'closed_left') {
        includeEnd = true;
      } else if (interval.inclusionType === 'closed_right') {
        includeStart = true;
      }
      propertySections = propertySections.concat([
        {
          title: gettext('Type'),
          properties: [
            {
              type: 'simple',
              title: gettext('Filter type:'),
              value: gettextCatalog.getString('Dynamic')
            }
          ]
        },
        {
          title: gettext('Inclusion type'),
          properties: [
            {
              title: gettext('Include start'),
              value: includeStart,
              type: 'radio',
              customOptions: ['YES', 'NO']
            },
            {
              title: gettext('Include end'),
              value: includeEnd,
              type: 'radio',
              customOptions: ['YES', 'NO']
            }
          ]
        },
        {
          title: gettextCatalog.getString('Time period'),
          properties: properties
        }
      ]);
    }

    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];
    propertySections = propertySections.concat([
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'period-intervals',
        target: interval._id,
        type: 'userAccess'
      }
    ]);

    var actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: timeIntervalFilterId,
        endpoint: {
          entity: 'period-intervals',
          method: 'delete'
        },
        redirectState: 'analytics-time-interval-filters-list'
      },
      {
        title: gettext('Update'),
        state: 'analytics-time-interval-filters-edit',
        param: 'id',
        paramValue: interval._id
      }
    ];
    return {
      metadata: {
        definition: gettext('Time interval filter'),
        title: interval.name,
        description: interval.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}

export default TimeIntervalFilterController;
