module.exports = {
  entity: 'project-types',
  path: 'project-types',
  name: {
    en: 'Project types',
    sl_SI: 'Tipi projektov'
  },
  networkModel: {
    entity: 'project-types',
    read: 'read'
  },
  selectedParamName: 'id',
  scope: 'tis_be:project-types',

  dialog: {
    title: {
      en: 'Select project type',
      sl_SI: 'Izberite tip projekta'
    },
    listInfo: {
      en: 'Select project type from the list below',
      sl_SI: 'Izberite tip projekta s spodnjega seznama'
    }
  }
}
