module.exports = {
  entity: 'rule-cusum-intervals',
  path: 'rule-cusum-intervals',
  name: {
    en: 'Rule cusum intervals',
    sl_SI: 'Pravila cusum intervalov'
  },
  networkModel: {
    entity: 'rule-cusum-intervals',
    read: 'custom.readRulesAndIntervals'
  },
  scope: 'tis_be:rule-cusum-intervals',
  displayFields: [
    {
      field1: 'rule',
      field2: 'name',
      type: 'string'
    },
    {
      field1: 'cusumInterval',
      field2: 'validFrom',
      title: {
        en: 'Valid from',
        sl_SI: 'Veljavno od'
      },
      type: 'date'
    }
  ],
  dialog: {
    title: {
      en: 'Select cusum interval rule',
      sl_SI: 'Izberite pravilo cusum intervala'
    },
    listInfo: {
      en: 'Select cusum interval rule from the list below',
      sl_SI: 'Izberite pravilo cusum intervala s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'rule',
        field2: 'name',
        type: 'string'
      },
      {
        field1: 'cusumInterval',
        field2: 'validFrom',
        title: {
          en: 'Valid from',
          sl_SI: 'Veljavno od'
        },
        type: 'date'
      }
    ]
  }
}
