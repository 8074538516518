import { RouteManager, gettext } from '../routes';

import SfeListView from '../common/components/sfe-list/sfe-list.view.html';
import NewFormBasicView from '../common/views/new-form-basic.view.html';
import newUserForm from './pages/user/new-user.view.html';
import AbsenceView from './pages/absent-personnel/absence.view.html';
import OnDutyView from './pages/assigned-personnel/on-duty.view.html';
import GroupView from './pages/group/group.view.html';
import NotificationlistView from './pages/notification/notification-list.view.html';
import NotificationView from './pages/notification/notification.view.html';
import unauthenticatedView from './pages/unauthenticated/unauthenticated.view.html';
import UserView from './pages/user/user.view.html';
import UserPrivilegeView from './pages/user-privilege/user-privilege.view.html';

loadEntity.$inject = ['$transition$', 'StateResolveService'];
function loadEntity($transition$, StateResolveService) {
  return StateResolveService.fetchEntityForView($transition$);
}

let UserRouteManager = new RouteManager();
UserRouteManager
  // DASHBOARD for DATA MODULE
  .state('users', {
    url: '/users',
    isMainMenuItem: true,
    menuIndex: 0,
    pageTitle: 'Users',
    icon: 'people',
    redirectTo: 'users-users-list',
    theme: 'tango',
    accessScopeFe: 'tis_fe:users-module',
    accessScopeBe: 'tis_fe:users-module'
  })
  // 1. LIST of GROUPS
  .state('users-groups-list', {
    url: '/users/groups/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    icon: 'people',
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'tis_fe:groups',
    accessScopeBe: 'tis_fe:groups',
    pageTitle: gettext('Groups')
  })
  // 2. LIST of USERS
  .state('users-users-list', {
    url: '/users/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Users'),
    icon: 'person',
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'tis_fe:users',
    accessScopeBe: 'tis_fe:users'
  })
  // 3. LIST OF NOTIFICATIONS
  .state('users-created-notifications-list', {
    url: '/users/created-notifications/list',
    template: NotificationlistView,
    controller: 'NotificationListController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Created Notifications'),
    icon: 'mail_outline',
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'tis_fe:notifications-list',
    accessScopeBe: 'tis_fe:notifications-list'
  })
  // 4. LIST OF MY NOTIFICATIONS
  .state('users-received-notifications-list', {
    url: '/users/received-notifications/list',
    template: NotificationlistView,
    controller: 'NotificationListController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Received Notifications'),
    icon: 'mail_outline',
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'tis_fe:my-notifications-list',
    accessScopeBe: 'tis_fe:my-notifications-list'
  })
  // 5. ASSIGNED PERSONNEL
  .state('users-assigned-personnel', {
    url: '/users/assigned-personnel',
    template: OnDutyView,
    controller: 'OnDutyController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Assigned Personnel'),
    icon: 'assignment',
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'tis_fe:assigned-personnel',
    accessScopeBe: 'tis_be:work-schedules'
  })
  // 6. ABSENT PERSONNEL
  .state('users-absent-personnel', {
    url: '/users/absent-personnel',
    template: AbsenceView,
    controller: 'AbsenceController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Absent Personnel'),
    icon: 'local_hotel',
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'tis_fe:absent-personnel',
    accessScopeBe: 'tis_be:work-schedules'
  })
  // USER VIEW
  .state('users-users-view', {
    url: '/users/users/{id}',
    template: UserView,
    controller: 'UserController',
    controllerAs: 'vm',
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'tis_fe:show-profile',
    accessScopeBe: 'tis_fe:show-profile',
    mode: 'read',
    pageTitle: gettext('User'),
    entity: 'users',
    resolve: {
      user: loadEntity
    }
  })
  // FORM for EDIT USER
  .state('users-users-edit', {
    url: '/users/users/{id}/edit',
    template: newUserForm,
    controller: 'NewUserFormController',
    controllerAs: 'vm',
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'tis_fe:show-profile',
    accessScopeBe: 'tis_fe:show-profile',
    mode: 'update',
    entity: 'users',
    resolve: {
      user: loadEntity
    }
  })
  // FORM for NEW GROUP
  .state('users-groups-new', {
    url: '/users/groups/new',
    template: NewFormBasicView,
    controller: 'NewGroupFormController',
    controllerAs: 'vm',
    icon: 'people',
    iconMode: 'add',
    pageTitle: gettext('New Group'),
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'identity:group',
    accessScopeBe: 'identity:group',
    mode: 'create',
    resolve: {
      group: () => ({})
    }
  })
  // VIEW of GROUPS
  .state('users-groups-view', {
    url: '/users/groups/{id}',
    template: GroupView,
    controller: 'GroupController',
    controllerAs: 'vm',
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'tis_fe:groups',
    accessScopeBe: 'tis_fe:groups',
    mode: 'read',
    pageTitle: gettext('Group'),
    entity: 'groups',
    resolve: {
      group: loadEntity
    }
  })
  // FORM for EDIT GROUP
  .state('users-groups-edit', {
    url: '/users/groups/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewGroupFormController',
    controllerAs: 'vm',
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'tis_fe:groups',
    accessScopeBe: 'tis_fe:groups',
    mode: 'update',
    entity: 'groups',
    resolve: {
      group: loadEntity
    }
  })
  .state('users-created-notifications-new', {
    url: '/users/created-notifications/new',
    template: NewFormBasicView,
    controller: 'NewNotificationController',
    controllerAs: 'vm',
    parentState: 'users',
    pageTitle: gettext('New Notification'),
    isSubMenuItem: true,
    icon: 'mail_outline',
    iconMode: 'add',
    theme: 'tango',
    accessScopeFe: 'tis_fe:notifications-list',
    accessScopeBe: 'notificator:notification',
    mode: 'create'
  })
  .state('users-created-notifications-view', {
    url: '/users/created-notifications/{id}',
    template: NotificationView,
    controller: 'NotificationController',
    controllerAs: 'vm',
    pageTitle: gettext('Notification Details'),
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'tis_fe:notifications-list',
    accessScopeBe: 'tis_fe:notifications-list',
    mode: 'read',
    entity: 'notifications',
    resolve: {
      notification: loadEntity
    }
  })
  .state('users-received-notifications-view', {
    url: '/users/received-notifications/{id}',
    template: NotificationView,
    controller: 'NotificationController',
    controllerAs: 'vm',
    pageTitle: gettext('Notification Details'),
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'tis_fe:notifications-list',
    accessScopeBe: 'tis_fe:notifications-list',
    mode: 'read',
    entity: 'notifications',
    resolve: {
      notification: loadEntity
    }
  })
  // PRIVILEGE MANAGEMENT GROUP
  .state('user-group-privilege', {
    url: '/users/groups/{id}/privilege-management?entity',
    reloadOnSearch: false,
    template: UserPrivilegeView,
    controller: 'UserPrivilegeController',
    controllerAs: 'vm',
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'authorizer:edit_permissions',
    accessScopeBe: 'tis_fe:groups',
    mode: 'update',
    pageTitle: gettext('Privilege Management'),
    entity: 'privilege-management',
    resolve: {
      notification: loadEntity
    }
  })
  // GROUP PRIVILEGE ITEM
  .state('user-group-privilege-item', {
    url: '/users/groups/{id}/privilege-management-item?entity&entityType',
    reloadOnSearch: false,
    template: UserPrivilegeView,
    controller: 'UserPrivilegeController',
    controllerAs: 'vm',
    parentState: 'users',
    theme: 'tis',
    accessScopeFe: 'authorizer:edit_permissions',
    accessScopeBe: 'tis_fe:groups',
    mode: 'update',
    pageTitle: gettext('Group Privilege Management'),
    entity: 'privilege-management',
    resolve: {
      notification: loadEntity
    }
  })
  // PRIVILEGE MANAGEMENT GROUP
  .state('user-group-entities', {
    url: '/users/groups/{id}/entities-management?entity',
    reloadOnSearch: false,
    template: UserPrivilegeView,
    controller: 'UserPrivilegeController',
    controllerAs: 'vm',
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'authorizer:edit_permissions',
    accessScopeBe: 'tis_fe:groups',
    mode: 'update',
    pageTitle: gettext('Privilege Management'),
    entity: 'privilege-management',
    resolve: {
      notification: loadEntity
    }
  })
  // PRIVILEGE MANAGEMENT
  .state('user-privilege', {
    url: '/users/{id}/privilege-management?entity',
    reloadOnSearch: false,
    template: UserPrivilegeView,
    controller: 'UserPrivilegeController',
    controllerAs: 'vm',
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'authorizer:edit_permissions',
    accessScopeBe: 'tis_fe:users',
    mode: 'update',
    pageTitle: gettext('User Privilege Management'),
    entity: 'privilege-management',
    resolve: {
      notification: loadEntity
    }
  })
  //USER PRIVILEGE ITEM
  .state('user-privilege-item', {
    url: '/users/{id}/privilege-management-item?entity&entityType',
    reloadOnSearch: false,
    template: UserPrivilegeView,
    controller: 'UserPrivilegeController',
    controllerAs: 'vm',
    parentState: 'users',
    theme: 'tis',
    accessScopeFe: 'authorizer:edit_permissions',
    accessScopeBe: 'tis_fe:users',
    mode: 'update',
    pageTitle: gettext('User Privilege Management'),
    entity: 'privilege-management',
    resolve: {
      notification: loadEntity
    }
  })
  .state('user-entities', {
    url: '/users/{id}/entities-management?entity',
    template: UserPrivilegeView,
    reloadOnSearch: false,
    controller: 'UserPrivilegeController',
    controllerAs: 'vm',
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'authorizer:edit_permissions',
    accessScopeBe: 'tis_fe:users',
    mode: 'update',
    pageTitle: gettext('User Entities'),
    entity: 'privilege-management',
    resolve: {
      notification: loadEntity
    }
  })
  .state('user-group-modules', {
    url: '/users/groups/{id}/modules',
    template: UserPrivilegeView,
    controller: 'UserPrivilegeController',
    controllerAs: 'vm',
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'authorizer:edit_permissions',
    accessScopeBe: 'tis_fe:groups',
    mode: 'update',
    pageTitle: gettext('User Group Modules'),
    entity: 'privilege-management',
    resolve: {
      notification: loadEntity
    }
  })
  .state('user-modules', {
    url: '/users/{id}/modules',
    template: UserPrivilegeView,
    controller: 'UserPrivilegeController',
    controllerAs: 'vm',
    parentState: 'users',
    theme: 'tango',
    accessScopeFe: 'authorizer:edit_permissions',
    accessScopeBe: 'tis_fe:users',
    mode: 'update',
    pageTitle: gettext('User Modules'),
    entity: 'privilege-management',
    resolve: {
      notification: loadEntity
    }
  })
  .state('unauthenticated', {
    url: '/unauthenticated',
    controller: 'UnauthenticatedController',
    controllerAs: 'vm',
    params: {
      logout: null
    },
    template: unauthenticatedView,
    theme: 'tango',
    isFullscreen: true,
    doNotSaveState: true,
    allUsers: true
  });

export default UserRouteManager.routes;
