import { DateTime } from 'luxon';

InvoiceDetailFormService.$inject = [
  'SfeForm2DateFromPriorToValidationService',
  'gettextCatalog',
  'CalculatedDetailInvoiceNormalizedServiceDateModel',
  'AlertingService',
  '$timeout',
  '$filter',
  'findPriceListDetailService',
  'PriceListDetailModel',
  'MeasuringPointConfiguration',
  'TranslationService',
  'MasterInvoiceModel',
  'PriceListItemModel',
  'MeasuringpointModel'
];

export default function InvoiceDetailFormService(
  SfeForm2DateFromPriorToValidationService,
  gettextCatalog,
  CalculatedDetailInvoiceNormalizedServiceDateModel,
  AlertingService,
  $timeout,
  $filter,
  findPriceListDetailService,
  PriceListDetailModel,
  MeasuringPointConfiguration,
  TranslationService,
  MasterInvoiceModel,
  PriceListItemModel,
  MeasuringpointModel
) {
  /** FORM VALIDATIOn */
  const fromToValidation = SfeForm2DateFromPriorToValidationService.get(
    'serviceDateFrom',
    'serviceDateTo'
  );
  const fromToValidationNorm = SfeForm2DateFromPriorToValidationService.get(
    'normalizedServiceDateFrom',
    'normalizedServiceDateTo'
  );
  /**Remove Energy source and cost centre filters from measuring point dialog */
  const filters = angular
    .copy(MeasuringPointConfiguration.filter)
    .filter(function(item) {
      return (
        item.param != 'energySourceType' &&
        item.param != 'costCentreMeasuringpoints.costCentre'
      );
    });

  const setPriceListToNone = ({ api }) => {
    api.setValue('priceList', gettextCatalog.getString('None'));
    api.setValue('priceListItem', null);
    api.setFieldConfigurationProperty(
      'priceList',
      'type.options.theme',
      'warn'
    );
  };
  /**Returns price list item and price list string to display on the form */
  const getPriceList = async ({ priceListId }) => {
    try {
      const { data: priceList } = await PriceListDetailModel.custom.readMaster({
        id: priceListId
      });

      let dateString =
        $filter('date')(priceList.validFrom, 'dd.MM.yyyy') + ' - ';
      if (priceList.validTo) {
        dateString += $filter('date')(priceList.validTo, 'dd.MM.yyyy');
      }

      return {
        priceListString: `${priceList.priceListMaster.name} (${dateString})`,
        priceList
      };
    } catch (err) {
      AlertingService.Error(err);
    }
  };

  /**
   * Function is triggered on service date from/to change and MP change
   * finds new valid price list
   */
  async function updatePriceValidList({ api, measuringPoint, serviceDateTo }) {
    const { masterInvoice } = api.getValues();

    if (
      !measuringPoint ||
      !measuringPoint._id ||
      !serviceDateTo ||
      masterInvoice == null
    ) {
      setPriceListToNone({ api });
      return;
    } else {
      try {
        const priceListId = await findPriceListDetailService.findValidPricelist(
          masterInvoice._id,
          measuringPoint._id,
          serviceDateTo
        );

        const { priceList, priceListString } = await getPriceList({
          priceListId,
          api
        });

        api.setValue('priceList', priceListString);
        api.setValue('priceListItem', priceList);

        api.setFieldConfigurationProperty(
          'priceList',
          'type.options.theme',
          ''
        );
      } catch (err) {
        AlertingService.Error(err);
        setPriceListToNone({ api });
      }
      $timeout();
    }
  }

  const onServiceDateClose = api => {
    const {
      normalizedServiceDateFrom,
      normalizedServiceDateTo
    } = api.getValues();
    if (
      !normalizedServiceDateFrom ||
      normalizedServiceDateFrom.length < 1 ||
      !normalizedServiceDateTo ||
      normalizedServiceDateTo.length < 1
    ) {
      generateNormalizedDate(api);
    }
  };
  /**Sets new normalized dates according to service date from/to */
  const generateNormalizedDate = async api => {
    api.revalidate();
    const { serviceDateTo, serviceDateFrom } = api.getValues();

    const serviceDateToValid = api.validity('serviceDateTo');

    const serviceDateFromValid = api.validity('serviceDateFrom');

    if (
      serviceDateTo &&
      serviceDateFrom &&
      serviceDateToValid &&
      serviceDateFromValid
    ) {
      try {
        const from = DateTime.fromJSDate(new Date(serviceDateFrom))
          .startOf('day')
          .toMillis();
        const to = DateTime.fromJSDate(new Date(serviceDateTo))
          .endOf('day')
          .toMillis();
        const {
          normalizedServiceDateFrom,
          normalizedServiceDateTo
        } = await CalculatedDetailInvoiceNormalizedServiceDateModel.create({
          from,
          to
        });
        const normalizedFrom = DateTime.fromObject({
          year: normalizedServiceDateFrom.year,
          month: normalizedServiceDateFrom.month
        })
          .startOf('months')
          .toJSDate();
        const normalizedTo = DateTime.fromObject({
          year: normalizedServiceDateTo.year,
          month: normalizedServiceDateTo.month
        })
          .endOf('months')
          .toJSDate();

        api.setValue('normalizedServiceDateFrom', normalizedFrom);
        api.setValue('normalizedServiceDateTo', normalizedTo);
        $timeout();
      } catch (err) {
        AlertingService.Error(err);
      }
    }
  };

  /**Returns detail invoice form fields */
  const getFormFields = ({
    detailInvoice,
    priceList,
    editMode,
    masterInvoice,
    api
  }) => {
    let initFrom = false;
    let initTo = false;

    /**Service date from/to on change handler */
    const fromToChange = async (api, initialLoad) => {
      const values = api.getValues();
      const {
        serviceDateTo,
        measuringPoint,
        energySourceType,
        masterInvoice
      } = values;
      // validate measuring point after service date interval change
      if (!initialLoad) {
        if (Array.isArray(serviceDateTo) && serviceDateTo.length > 0) {
          var { data: measuringPoints } = await MeasuringpointModel.read({
            populate: 'businessPartnerMeasuringpoints',
            'businessPartnerMeasuringpoints.isMain': true,
            energySourceType: energySourceType?._id || null,
            'businessPartnerMeasuringpoints.businessPartner':
              masterInvoice?.businessPartnerCustomer || undefined
          });

          var foundSelectedMeasuringPoint = measuringPoints
            .filter(item => {
              return item.businessPartnerMeasuringpoints.find(bpm => {
                const validFrom = new Date(bpm.validFrom).getTime();
                const validTo =
                  bpm.validTo != null ? new Date(bpm.validTo).getTime() : null;
                if (validTo == null) {
                  return validFrom <= serviceDateTo[0];
                } else {
                  return (
                    validFrom <= serviceDateTo[0] && validTo >= serviceDateTo[0]
                  );
                }
              });
            })
            .find(mp => mp._id === measuringPoint._id);
          if (!foundSelectedMeasuringPoint) {
            api.setValue('measuringPoint', null);
          }
        }
      }

      updatePriceValidList({
        api,
        measuringPoint: values.measuringPoint,
        serviceDateTo
      });
      api.revalidate();
    };

    const fromChange = api => {
      const { masterInvoice } = api.getValues();
      if (initFrom || !masterInvoice?._id) {
        fromToChange(api, initFrom);
      } else {
        initFrom = true;
      }
    };

    const toChange = api => {
      const { masterInvoice } = api.getValues();
      if (initTo || !masterInvoice?._id) {
        fromToChange(api, initTo);
      } else {
        initTo = true;
      }
    };

    return [
      {
        id: 'serviceDateFrom',
        title: gettextCatalog.getString('Service date from'),
        type: {
          name: 'date',
          options: {
            enableTime: false,
            customOptions: {
              onClose: () => {
                const serviceDateToValid = api.validity('serviceDateTo');
                if (serviceDateToValid) {
                  $timeout(() => {
                    onServiceDateClose(api);
                  });
                }
              }
            }
          }
        },
        onChange: fromChange,

        initialize: () =>
          detailInvoice.serviceDateFrom
            ? [new Date(detailInvoice.serviceDateFrom)]
            : null,
        required: true,
        width: 6,
        validators: {
          maxDate: fromToValidation
        }
      },
      {
        id: 'serviceDateTo',
        title: gettextCatalog.getString('Service date to'),
        type: {
          name: 'date',
          options: {
            enableTime: false,
            customOptions: {
              onClose: () => {
                const serviceDateFromValid = api.validity('serviceDateFrom');
                if (serviceDateFromValid) {
                  $timeout(() => {
                    onServiceDateClose(api);
                  });
                }
              }
            }
          }
        },
        onChange: toChange,
        initialize: () =>
          detailInvoice.serviceDateTo
            ? [new Date(detailInvoice.serviceDateTo)]
            : null,
        required: true,
        width: 6,
        validators: {
          maxDate: fromToValidation
        }
      },
      {
        id: 'actions',
        type: {
          name: 'title',
          options: {
            value: gettextCatalog.getString('Regenerate normalized dates'),
            theme: 'primary'
          }
        },
        actions: [
          {
            title: gettextCatalog.getString('Set validity interval'),
            fn: generateNormalizedDate,
            disabledFn: api =>
              !api.validity('serviceDateTo') || !api.validity('serviceDateFrom')
          }
        ]
      },
      {
        id: 'normalizedServiceDateFrom',
        title: gettextCatalog.getString('Normalized Service date from'),
        type: {
          name: 'date',
          options: {
            monthSelector: true
          }
        },
        initialize: () =>
          detailInvoice.normalizedServiceDateFrom
            ? [
              initNormalizedObject(detailInvoice.normalizedServiceDateFrom)
                .startOf('months')
                .toJSDate()
            ]
            : null,
        required: true,
        width: 6,
        validators: {
          maxDate: fromToValidationNorm
        }
      },
      {
        id: 'normalizedServiceDateTo',
        title: gettextCatalog.getString('Normalized Service date to'),
        type: {
          name: 'date',
          options: {
            monthSelector: true
          }
        },
        initialize: () =>
          detailInvoice.normalizedServiceDateTo
            ? [
              initNormalizedObject(detailInvoice.normalizedServiceDateTo)
                .endOf('months')
                .toJSDate()
            ]
            : null,
        required: true,
        width: 6,
        validators: {
          maxDate: fromToValidationNorm
        }
      },
      {
        id: 'energySourceType',
        title: gettextCatalog.getString('Energy source type'),
        type: {
          name: 'autocomplete',
          options: {
            itemsCrawler: {
              entity: 'energy-source-types',
              method: 'read'
            },
            crawlerParams: text => ({ filter: text }),
            display: item => ({
              text: `${
                item != null
                  ? item.name
                  : gettextCatalog.getString('Unknown energy source type')
              } ${item && item.family_name ? item.family_name : ''}`
            }),
            dialog: {
              entity: 'energy-source-types'
            }
          }
        },
        initialize: () => detailInvoice.energySourceType || null,
        required: true,
        onChange: api => {
          const { energySourceType } = api.getValues();
          if (!(energySourceType && energySourceType.__prefetched__value__)) {
            api.setValue('measuringPoint', null);

            setPriceListToNone({ api });
          }
        }
      },
      {
        id: 'measuringPoint',
        title: gettextCatalog.getString('Measuring point'),
        type: {
          name: 'autocomplete',
          options: {
            itemsCrawler: {
              entity: 'measuringpoints',
              method: 'read'
            },
            crawlerParams: (text, api) => {
              const { energySourceType, masterInvoice } = api.getValues();

              return {
                filter: text,
                populate: 'businessPartnerMeasuringpoints',
                'businessPartnerMeasuringpoints.isMain': true,
                energySourceType: energySourceType?._id || null,
                'businessPartnerMeasuringpoints.businessPartner':
                  masterInvoice?.businessPartnerCustomer || undefined
              };
            },
            filter: (items, api) => {
              const values = api.getValues();
              if (
                Array.isArray(values.serviceDateTo) &&
                values.serviceDateTo.length > 0
              ) {
                const serviceDateTo = values.serviceDateTo[0];
                return items.filter(item => {
                  return item.businessPartnerMeasuringpoints.find(bpm => {
                    const validFrom = new Date(bpm.validFrom).getTime();
                    const validTo =
                      bpm.validTo != null
                        ? new Date(bpm.validTo).getTime()
                        : null;
                    if (validTo == null) {
                      return validFrom <= serviceDateTo;
                    } else {
                      return (
                        validFrom <= serviceDateTo && validTo >= serviceDateTo
                      );
                    }
                  });
                });
              }
              return [];
            },
            display: item => ({
              text: `${
                item != null
                  ? item.name
                  : gettextCatalog.getString('Unknown measuring point')
              } ${item && item.code ? item.code : ''}`
            }),
            dialog: {
              entity: 'measuringpoints',
              filters
            }
          }
        },
        onChange: async api => {
          const { measuringPoint, serviceDateTo } = api.getValues();
          updatePriceValidList({ measuringPoint, serviceDateTo, api });
        },
        initialize: () => detailInvoice.measuringPoint || null,
        disable: api => {
          const { energySourceType } = api.getValues();
          return energySourceType == null;
        },
        required: true
      },
      {
        id: 'priceListTitle',
        type: {
          name: 'title',
          options: {
            value: gettextCatalog.getString('Price List'),
            theme: 'primary'
          }
        }
      },
      {
        id: 'priceList',
        title: 'Price list',
        initialize: () => {
          if (editMode && priceList) {
            return priceList.priceListString;
          } else {
            return gettextCatalog.getString('None');
          }
        },

        type: {
          name: 'paragraph',
          options: {
            theme: 'warn'
          }
        }
      },
      {
        id: 'readDate',
        title: gettextCatalog.getString('Reading date'),
        type: {
          name: 'date',
          options: {
            enableTime: false
          }
        },
        initialize: () =>
          detailInvoice.readDate
            ? [new Date(detailInvoice.readDate)]
            : [new Date().setHours(0, 0, 0, 0)],
        required: false
      },
      {
        id: 'invoiceType',
        title: gettextCatalog.getString('Invoice Type'),
        type: {
          name: 'autocomplete',
          options: {
            items: TranslationService.GetCollection('codelists.invoiceTypes'),
            valueParam: 'id',
            crawlerParams: searchTerm => ({
              param: 'name',
              searchTerm,
              type: 'string'
            }),
            display: item => ({
              text: `${
                item != null
                  ? item.name
                  : gettextCatalog.getString('Unknown invoice type')
              }`
            })
          }
        },

        initialize: () => detailInvoice.invoiceType || 1,
        required: true
      },
      {
        id: 'total',
        title: gettextCatalog.getString('Total'),
        required: false,
        type: {
          name: 'text',
          options: {
            placeholder: gettextCatalog.getString('Total'),
            type: 'numerical',
            max: masterInvoice.total
          }
        },
        initialize: () => {
          if (detailInvoice && detailInvoice.total != null) {
            return detailInvoice.total;
          } else if (masterInvoice) {
            return masterInvoice.total;
          }
        }
      },
      {
        id: 'masterInvoice',
        type: {
          name: 'paragraph'
        },
        hide: () => true,
        initialize: () => masterInvoice
      },
      {
        id: 'priceListItem',
        type: {
          name: 'paragraph'
        },
        hide: () => true,
        initialize: () => {
          if (priceList) {
            return priceList.priceList;
          }
          return null;
        }
      }
    ];
  };
  /**Converts normalized date api object to Luxon initial object */
  const initNormalizedObject = dateObject => {
    return DateTime.fromObject({
      months: dateObject.month,
      years: dateObject.year
    });
  };

  /**Returns master invoice */
  const getInvoiceData = async ({ invoiceId }) => {
    try {
      const { data: masterInvoice } = await MasterInvoiceModel.read({
        id: invoiceId
      });
      return masterInvoice;
    } catch (err) {
      AlertingService.Error(err);
    }
  };

  /** Retrieves price list detail values and maps it to detail invoice values object */
  const getDetailInvoiceValues = async priceListDetail => {
    let detailInvoiceValues = [];
    try {
      const { data } = await PriceListItemModel.read({
        priceListDetail: priceListDetail
      });
      detailInvoiceValues = data.map(function(priceListItem) {
        return {
          priceListItem: priceListItem._id,
          energyManagementGroup: priceListItem.energyManagementGroup,
          name: priceListItem.name,
          measurementUnit: priceListItem.measurementUnit,
          metricPrefix: priceListItem.metricPrefix,
          order: priceListItem.order,
          physicalQuantity: priceListItem.physicalQuantity,
          price: priceListItem.price,
          priceListItemGroup: priceListItem.priceListItemGroup,
          externalCode: priceListItem.externalCode,
          quantity: 0,
          value: 0
        };
      });
    } catch (err) {
      AlertingService.Error(err);
    }
    return detailInvoiceValues;
  };

  return {
    getFormFields,
    getPriceList,
    getInvoiceData,
    getDetailInvoiceValues,
    initNormalizedObject
  };
}
