NewProjectFormController.$inject = [
  '$state',
  'gettext',
  'ProjectModel',
  'AlertingService',
  'MetadataService',
  '$smartAuth',
  'SfeHeader',
  'ListFormService',
  'project',
  '$timeout',
  'CrudToastFactory',
  'EntityTagService'
];

function NewProjectFormController(
  $state,
  gettext,
  ProjectModel,
  AlertingService,
  MetadataService,
  $smartAuth,
  SfeHeader,
  ListFormService,
  project,
  $timeout,
  CrudToastFactory,
  EntityTagService
) {
  const vm = this;
  const projectId = $state.params.id;
  const editMode = $state.current.name == 'company-resources-projects-edit';
  const duplicateMode =
    $state.current.name == 'company-resources-projects-duplicate';

  let headerTitle;

  let originalTags = [];
  vm.project = {};

  vm.$onInit = async function init() {
    vm.dataConfig = {
      action: {
        ctrlFn: true,
        title: editMode ? gettext('Update') : gettext('Create'),
        fn: createOrUpdate
      },
      data: getFields()
    };

    let dataObj = {
      name: '',
      description: '',
      code: '',
      externalCode: null,
      projectFrom: 0,
      projectTo: 0,
      billingFrom: 0,
      billingTo: 0,
      depreciationPeriod: '',
      energyManager: null,
      startOfProject: new Date(new Date().setHours(0, 0, 0, 0)),
      endOfProject: null,
      startOfBillingPeriod: new Date(new Date().setHours(0, 0, 0, 0)),
      endOfBillingPeriod: null,
      systemTags: []
    };

    if (editMode || duplicateMode) {
      if (Array.isArray(project.tags)) {
        let item;
        project.systemTags = project.tags.map(function(tag) {
          item = tag.systemTag;
          item.bindingId = tag._id; //tag service uses binding id to update/delete tag
          return item;
        });
      } else {
        project.systemTags = [];
      }
      if (editMode) {
        originalTags = angular.copy(project.systemTags);
      }

      vm.dataConfig.dataObj = {
        ...project,
        startOfBillingPeriod:
          project.startOfBillingPeriod != null
            ? new Date(project.startOfBillingPeriod)
            : null,
        startOfProject:
          project.startOfProject != null
            ? new Date(project.startOfProject)
            : null,
        endOfProject:
          project.endOfProject != null ? new Date(project.endOfProject) : null,
        endOfBillingPeriod:
          project.endOfBillingPeriod != null
            ? new Date(project.endOfBillingPeriod)
            : null
      };
      MetadataService.Loading(false);
      $timeout(() => {
        MetadataService.ChangeMetadata(
          (editMode ? 'Edit ' : 'Duplicate ') + project.name,
          project.description
        );
      });

      MetadataService.Loading(true);
      headerTitle = gettext('Edit Project');
      if ($state.current.name === 'company-resources-projects-duplicate') {
        headerTitle = gettext('Duplicate Project');
      }
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        headerTitle,
        'company-resources-projects-view',
        { id: projectId }
      );
    } else {
      try {
        const energyManager = await $smartAuth.profile();

        vm.dataConfig = {
          ...vm.dataConfig,
          dataObj: {
            ...dataObj,
            energyManager
          }
        };
      } catch (err) {
        AlertingService.Error(err);
      }

      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New Project'),
        'company-resources-projects-list'
      );
    }
  };

  function getFields() {
    return [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textArea',
        type: 'text',
        maxlength: 500,
        required: false
      },
      {
        placeholder: gettext('Code'),
        name: 'code',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('External Code'),
        name: 'externalCode',
        componentType: 'textField',
        type: 'text',
        required: false
      },
      {
        componentType: 'autocompleteDialog',
        name: 'businessPartner',
        configuration: {
          entity: 'business-partners',
          dialogConfiguration: {
            title: gettext('Select business partner'),
            filterObject: {
              order: 'name'
            }
          },
          searchParamName: 'name',
          floatingLabel: gettext('Select business partner'),
          query: {
            entity: 'business-partners',
            method: 'read'
          },
          filterObject: {
            order: 'name'
          },
          createRedirect: {
            state: 'company-resources-business-partners-new'
          }
        }
      },
      {
        placeholder: gettext('Phase'),
        name: 'projectPhase',
        componentType: 'textField',
        maxlength: 30,
        type: 'text',
        required: false
      },
      {
        componentType: 'autocompleteDialog',
        name: 'projectType',
        configuration: {
          entity: 'project-types',
          dialogConfiguration: {
            title: gettext('Select project type')
          },
          searchParamName: 'filter',
          floatingLabel: gettext('Select project type'),
          query: {
            entity: 'project-types',
            method: 'read'
          },
          required: true,
          createRedirect: {
            state: 'configurations-company-resources-project-list'
          }
        }
      },
      {
        componentType: 'autocompleteDialog',
        name: 'projectField',
        configuration: {
          entity: 'project-fields',
          dialogConfiguration: {
            title: gettext('Select project field')
          },
          searchParamName: 'filter',
          floatingLabel: gettext('Select project field'),
          query: {
            entity: 'project-fields',
            method: 'read'
          },
          required: true,
          createRedirect: {
            state: 'configurations-company-resources-project-list'
          }
        }
      },
      {
        componentType: 'datePickerFromTo',
        from: 'startOfProject',
        to: 'endOfProject',
        labelFrom: gettext('Start of Project *'),
        labelTo: gettext('End of Project'),
        fromIsAfterTo: gettext('Start date must be before an end date.'),
        onlyStartRequired: true,
        idFrom: 'projectFrom',
        idTo: 'projectTo'
      },
      {
        componentType: 'datePickerFromTo',
        from: 'startOfBillingPeriod',
        to: 'endOfBillingPeriod',
        labelFrom: gettext('Start of Billing Period *'),
        labelTo: gettext('End of Billing Period'),
        fromIsAfterTo: gettext('Start date must be before an end date.'),
        onlyStartRequired: true,
        idFrom: 'billingFrom',
        idTo: 'billingTo'
      },
      {
        placeholder: gettext('Depreciation Period'),
        name: 'depreciationPeriod',
        componentType: 'textField',
        type: 'numerical'
      },
      {
        componentType: 'title',
        title: gettext('Energy Manager')
      },
      {
        componentType: 'autocompleteDialog',
        configuration: {
          query: {
            entity: 'users',
            method: 'read'
          },
          floatingLabel: gettext('Select content person'),
          searchParamName: 'full_name',
          entity: 'users',
          required: true
        },
        edit: true,
        name: 'energyManager'
      },
      {
        componentType: 'checkBoxesLinear',
        networkModel: {
          entity: 'system-tags',
          method: 'read'
        },
        name: 'systemTags',
        title: gettext('System Tags'),
        compareFn: function(item1, item2) {
          return item1._id === item2._id;
        }
      }
    ];
  }

  // create and update functions
  async function createOrUpdate() {
    vm.sendingRequest = true;

    const apiObject = dataToSave();

    //CREATE/UPDATE COST CENTER
    let entityId;
    try {
      if (editMode) {
        const { data } = await ProjectModel.update(
          { id: projectId },
          apiObject
        );
        entityId = data._id;
      } else {
        const { data } = await ProjectModel.create(apiObject);
        entityId = data._id;
      }

      if (entityId != null) {
        await EntityTagService.createSystemTags(
          vm.dataConfig.dataObj.systemTags,
          originalTags,
          232, //project entity id
          entityId,
          false
        );
      }

      const singleIdRedirect = {
        state: 'company-resources-projects-view',
        paramName: 'id',
        paramValue: entityId
      };
      const message = editMode ? 'update' : 'create';
      CrudToastFactory.toast(message, singleIdRedirect);
    } catch (err) {
      AlertingService.Error(err);
      vm.sendingRequest = false;
    }
  }

  function dataToSave() {
    const fields = [
      'energyManager',
      'name',
      'description',
      'code',
      'externalCode',
      'businessPartner',
      'projectPhase',
      'projectType',
      'projectField',
      'startOfProject',
      'endOfProject',
      'startOfBillingPeriod',
      'endOfBillingPeriod',
      'depreciationPeriod'
    ];
    const apiObj = {
      ...vm.dataConfig.dataObj,
      businessPartner: vm.dataConfig.dataObj.businessPartner
        ? vm.dataConfig.dataObj.businessPartner._id
        : null,
      projectType: vm.dataConfig.dataObj.projectType._id,
      projectField: vm.dataConfig.dataObj.projectField._id,
      energyManager: vm.dataConfig.dataObj.energyManager._id,
      externalCode: vm.dataConfig.dataObj.externalCode
        ? vm.dataConfig.dataObj.externalCode
        : null
    };

    return ListFormService.generateBodyObject(apiObj, fields);
  }
}

export default NewProjectFormController;
