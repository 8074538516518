CalculatedFlowModel.$inject = ['ModelUtilities'];

/**
 * @ngdoc model
 * @name common.CalculatedFlowModel
 * @description Model for calculated flow.
 */
function CalculatedFlowModel(ModelUtilities) {
  const modelStorage = {};

  let networkConfiguration = {
    host: 'api',
    path: 'time-series/:timeSeriesId/calculated-flows'
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default CalculatedFlowModel;
