import { IANAZone } from 'luxon';

/* eslint-disable no-case-declarations */
TangoTimeSeriesDataXHelperService.$inject = [
  'TimeSeriesModel',
  'AlertingService',
  'TranslationService',
  'ColorService',
  'TimeSeriesGroupModel',
  'PhysicalCollectionService',
  'DateLocalizationService',
  'TimeSeriesProcessingValuesModel',
  'Formatting',
  'GetTimeSeriesProcessingByTimestampService',
  '$state',
  'LocalizationService',
  'SfeDataXTranslations',
  'generateEntityReport',
  'SfeDataXMinTickInterval',
  'DownloadCSVService',
  'gettextCatalog',
  'SchedulerModel',
  'TimeSeriesConfigurationModel',
  'codelistsConstant'
];

/**
 * @ngdoc service
 * @name data.TangoTimeSeriesDataXHelperService
 * @property {function} open - opens recalculate dialog
 */
function TangoTimeSeriesDataXHelperService(
  TimeSeriesModel,
  AlertingService,
  TranslationService,
  ColorService,
  TimeSeriesGroupModel,
  PhysicalCollectionService,
  DateLocalizationService,
  TimeSeriesProcessingValuesModel,
  Formatting,
  GetTimeSeriesProcessingByTimestampService,
  $state,
  LocalizationService,
  SfeDataXTranslations,
  generateEntityReport,
  SfeDataXMinTickInterval,
  DownloadCSVService,
  gettextCatalog,
  SchedulerModel,
  TimeSeriesConfigurationModel,
  codelistsConstant
) {
  const defaultValuesLimit = 100;
  /**
   * @description fetches time series and returns timeseries configuration.
   * @function
   * @param {String} timeSeriesId
   * @return {Object}
   */
  async function fetchTimeSeries(timeSeriesId) {
    let [{ data }, { data: timeSeriesConfiguration }] = await Promise.all([
      TimeSeriesModel.read({
        id: timeSeriesId,
        populate: 'measurementUnit,physicalQuantity,dataScheduler'
      }),
      TimeSeriesConfigurationModel.read({
        timeSeriesId
      })
    ]);
    let timeSeriesResult = createTimeSeriesObject(
      data,
      timeSeriesConfiguration
    );
    return {
      series: [timeSeriesResult],
      scheduleClassifications: [],
      timeSeries: [data]
    };
  }
  /**
   * @description creates timeSeries suffix.
   * @function
   * @param {Object} timeSeries
   * @return {String}
   */
  function createSuffix(timeSeries) {
    let suffix = '';
    if (
      typeof timeSeries.measurementUnit == 'object' &&
      timeSeries.measurementUnit != null
    ) {
      let metricPrefix = TranslationService.GetCollectionById(
        'codelists.metricPrefixes',
        timeSeries.metricPrefix
      );
      suffix = `${metricPrefix != null ? metricPrefix.symbol : ''} ${
        timeSeries.measurementUnit.symbol
      }`;
    }
    return suffix;
  }
  /**
   * @description returns timeseries metadata config.
   * @function
   * @param {Object} timeSeries timeseries object
   * @return {Object}
   */
  function createTimeSeriesObject(timeSeries, timeSeriesConfiguration) {
    let minTickInterval;
    let suffix = createSuffix(timeSeries);

    const chartTypes = TranslationService.GetCollection('codelists.chartTypes');
    const displayTypes = TranslationService.GetCollection(
      'codelists.displayTypes'
    );
    let {
      dataVisualizationConfig,
      dataInterpretationType,
      dataSamplingType,
      dataScheduler
    } = timeSeries;

    let color = ColorService.getApplicationColor();
    let displayType;
    let chartType;
    let filter = {
      type: 'limit',
      numberOfUnits: 100
    };
    if (dataVisualizationConfig != null) {
      color = dataVisualizationConfig.color;
      chartType = chartTypes.find(
        item => item.id == dataVisualizationConfig.chartType
      );
      displayType = displayTypes.find(
        item => item.id == dataVisualizationConfig.displayType
      );
      filter = getFilter(dataVisualizationConfig.defaultFilter);
    }
    if (dataScheduler != null) {
      minTickInterval = SfeDataXMinTickInterval.getTickInterval(
        dataScheduler.scheduleClassification
      );
    }
    let timeZone = TranslationService.GetCollectionById(
      'codelists.timeZones',
      timeSeries.timeZone
    );
    if (timeZone != null) {
      timeZone = timeZone.code;
    }
    const isRegularAggregateTimeset =
      dataInterpretationType == 300 && dataSamplingType == 100;
    const scheduleSmallerThanDaily =
      dataScheduler != null &&
      [1, 6, 7, 9].indexOf(dataScheduler.scheduleClassification) >= 0;

    const isColumnChart = chartType && [7, 10, 11].includes(chartType.id);
    const isBarChart = chartType && [9].includes(chartType.id);

    return {
      id: timeSeries._id,
      name: timeSeries.name,
      physicalQuantity: timeSeries.physicalQuantity,
      suffix,
      nonNumerical: timeSeries.dataType != 2 && timeSeries.dataType != 3,
      dataType: timeSeries.dataType,
      precision: timeSeries.precision,
      color,
      chartType: chartType || chartTypes[0],
      displayType: displayType || displayTypes[0],
      filter,
      boundaries: timeSeries.boundaries,
      timeZone,
      dataScheduler: dataScheduler,
      dataInterpretationType,
      dataSamplingType,
      minTickInterval,
      timeSeriesConfiguration,
      shiftValuesToMinInterval:
        isRegularAggregateTimeset &&
        !scheduleSmallerThanDaily &&
        (isColumnChart || isBarChart),
      min: timeSeries.dataVisualizationConfig
        ? timeSeries.dataVisualizationConfig.minimumValue
        : null,
      max: timeSeries.dataVisualizationConfig
        ? timeSeries.dataVisualizationConfig.maximumValue
        : null
    };
  }
  /**
   * @description returns number of seconds for every schedule classification.
   * @function
   * @param {Number} scheduleClassification scheduleClassifications codelist id
   * @return {Number}
   */
  function getSeconds(scheduleClassification) {
    switch (scheduleClassification) {
    case 1: //HOUR
      return 60 * 60;
    case 2: //DAILY
      return 24 * 60 * 60;
    case 3: //WEEKLY
      return 7 * 24 * 60 * 60;
    case 4: //Monthly
      return 31 * 24 * 60 * 60;
    case 5: //Yearly
      return 12 * 31 * 24 * 60 * 60;
    case 6: //15 minutes
      return 15 * 60;
    case 7: //30 minutes
      return 30 * 60;
    case 8: //6 month
      return 6 * 31 * 24 * 60 * 60;
    default:
      return 0;
    }
  }
  /**
   * @description creates filter from default fitler.
   * @function
   * @param {Object} defaultFilter
   * @return {Object}
   */
  function getFilter(defaultFilter) {
    switch (defaultFilter.filterType) {
    case 100:
      // eslint-disable-next-line no-case-declarations
      let futureTimeRange = 0;
      // eslint-disable-next-line no-case-declarations
      let historyTimeRange = 0;
      if (defaultFilter.futureTimeRange != null) {
        futureTimeRange =
            getSeconds(defaultFilter.futureTimeRange.scheduleClassification) *
            defaultFilter.futureTimeRange.numberOfUnits;
      }
      if (defaultFilter.historyTimeRange != null) {
        historyTimeRange =
            getSeconds(defaultFilter.historyTimeRange.scheduleClassification) *
            defaultFilter.historyTimeRange.numberOfUnits;
      }
      return {
        type: 'date',
        futureTimeRange,
        historyTimeRange
      };
    case 200:
      return {
        type: 'limit',
        numberOfUnits: defaultFilter.numberOfValues || 100
      };

    default:
      return {
        type: 'limit',
        numberOfUnits: 100
      };
    }
  }
  /**
   * @description creates query filter.
   * @function
   * @param {Object} filter
   * @return {Object}
   */
  function constructQueryFilter(filter) {
    if (filter != null && typeof filter == 'object') {
      switch (filter.type) {
      case 'date':
        let now = new Date().getTime();
        let from;
        let to;
        let limit = 36000;
        if (filter.futureTimeRange != null) {
          to = now + filter.futureTimeRange * 1000;
        }
        if (filter.historyTimeRange != null) {
          from = now - filter.historyTimeRange * 1000;
        }
        return {
          from,
          to,
          limit
        };
      case 'limit':
        return {
          limit: filter.numberOfUnits
        };
      default:
        return {
          limit: 300
        };
      }
    }
  }

  /**
   * @description populates schedule classification with physical data.
   * @function
   * @param {Array} classifications
   * @return {Array}
   */
  async function populatePhysicalData(classifications) {
    const physicalQuantities = await PhysicalCollectionService.returnPhysicalQuantities();
    const measurementUnits = await PhysicalCollectionService.returnMeasurementUnits();
    return classifications.map(item => {
      let series = item.series.map(seriesItem => {
        let timeSeries = {
          ...seriesItem.timeSeries,
          physicalQuantity: physicalQuantities.find(
            physicalQuantityItem =>
              physicalQuantityItem._id == seriesItem.timeSeries.physicalQuantity
          ),
          measurementUnit: measurementUnits.find(
            measurementUnitItem =>
              measurementUnitItem._id == seriesItem.timeSeries.measurementUnit
          )
        };
        return {
          ...seriesItem,
          timeSeries
        };
      });
      return {
        ...item,
        series
      };
    });
  }
  /**
   * @description returns time series group schedule configuration.
   * @function
   * @param {Array} scheduleClassifications
   * @return {Array}
   */
  function constructScheduleClassifications(scheduleClassifications) {
    return scheduleClassifications.map(classification => {
      let scheduleClassification = TranslationService.GetCollectionById(
        'codelists.scheduleClassifications',
        classification.scheduleClassification
      );
      let abbreviation =
        scheduleClassification != null
          ? scheduleClassification.abbreviation
          : '';

      const chartTypes = TranslationService.GetCollection(
        'codelists.chartTypes'
      );
      let color = ColorService.getApplicationColor();
      let series = classification.series.map(item => {
        let chartType;

        if (item.dataVisualizationConfig != null) {
          color = item.dataVisualizationConfig.color;
          chartType = chartTypes.find(
            chartType => chartType.id == item.dataVisualizationConfig.chartType
          );
        }
        let stacking = null;
        //handles stacked area and stacked column
        switch (chartType.id) {
        case 10:
        case 12:
          stacking = 'normal';
          break;
        case 11:
        case 14:
          stacking = 'percent';
          break;
        }
        return {
          id: item.timeSeries._id,
          color,
          type: chartType.highChartCode || chartTypes[0].highChartCode,
          stacking
        };
      });
      let defaultFilter = getFilter(classification.defaultFilter);
      return {
        id: classification._id,
        abbreviation,
        selected: classification.isMain,
        filter: () => constructQueryFilter(defaultFilter),
        series,
        minTickInterval: SfeDataXMinTickInterval.getTickInterval(
          classification.scheduleClassification
        )
      };
    });
  }
  /**
   * @description function that calls all the timeseries configuration for the specified timeseries and adds it to the timeseries
   * @function
   * @param {Array} timeSeries - An array of timeseries
   * @return {Array} an array of all the timeseries, each including their own timeseries configuration
   */
  async function fetchAndAddTimeSeriesConfigurations(timeSeries) {
    const timeSeriesConfigurationsUnrefined = await Promise.all(
      timeSeries.map(item =>
        TimeSeriesConfigurationModel.read({ timeSeriesId: item.id })
      )
    );
    const timeSeriesConfigurations = timeSeriesConfigurationsUnrefined.map(
      item => item.data
    );
    return timeSeries.map((item, index) => ({
      ...item,
      timeZone:
        typeof item.timeZone === 'object' ? item.timeZone.code : item.timeZone,
      minTickInterval:
        item.dataScheduler != null
          ? SfeDataXMinTickInterval.getTickInterval(
            item.dataScheduler.scheduleClassification
          )
          : undefined,
      timeSeriesConfiguration: timeSeriesConfigurations[index] || []
    }));
  }
  /**
   * @description returns timeseries group data to display chart.
   * @function
   * @param {String} groupId time series group id
   * @return {Object}
   */
  async function fetchTimeSeriesGroup(groupId) {
    const { data } = await TimeSeriesGroupModel.read({
      id: groupId,
      populate:
        'scheduleClassifications.series.timeSeries,scheduleClassifications.series.timeSeries.physicalQuantity'
    });
    return await constructConfigurationOutOfGroup(data);
  }
  /**
   * @description returns metadata for tang-data-x component.
   * @function
   * @param {Object} group
   * @return {Object}
   */
  async function constructConfigurationOutOfGroup(group) {
    group.scheduleClassifications = await populatePhysicalData(
      group.scheduleClassifications
    );

    //POPULATE GROUP TIMESERIES DATA SCHEDULER
    const allSeries = group.scheduleClassifications.reduce(
      (uniqueSeries, scheduleClassification) => {
        return [
          ...uniqueSeries,
          ...scheduleClassification.series.reduce(
            (scheduleClassificationSeries, set) => [
              ...scheduleClassificationSeries,
              ...(uniqueSeries.find(({ _id }) => _id == set.timeSeries._id) ==
              null
                ? [set.timeSeries]
                : [])
            ],
            []
          )
        ];
      },
      []
    );
    const schedulers = await fetchTimeseriesGroupSchedulers(allSeries);

    let timeseries = group.scheduleClassifications
      .reduce((result, scheduleClassification) => {
        let series = scheduleClassification.series.map(seriesItem => {
          seriesItem.timeSeries.dataScheduler = schedulers.find(
            scheduler => scheduler._id === seriesItem.timeSeries.dataScheduler
          );

          let config = createTimeSeriesObject(seriesItem.timeSeries);
          config.boundaries = [];
          config.min = null;
          config.max = null;
          const chartTypes = TranslationService.GetCollection(
            'codelists.chartTypes'
          );
          let stacking = null;
          let { dataVisualizationConfig } = seriesItem;
          let color = ColorService.getApplicationColor();
          let chartType;

          if (dataVisualizationConfig != null) {
            color = dataVisualizationConfig.color;
            chartType = chartTypes.find(
              item => item.id == dataVisualizationConfig.chartType
            );
          }
          //handles stacked area and stacked column
          switch (chartType.id) {
          case 10:
          case 12:
            stacking = 'normal';
            break;
          case 11:
          case 14:
            stacking = 'percent';
            break;
          }
          return {
            ...config,
            color,
            chartType,
            stacking
          };
        });
        return [...result, ...series];
      }, [])
      .filter(
        (value, index, items) =>
          index == items.findIndex(item => item.id == value.id)
      );
    const timeSeriesWithConfigurations = await fetchAndAddTimeSeriesConfigurations(
      timeseries
    );
    const scheduleClassifications = constructScheduleClassifications(
      group.scheduleClassifications
    );
    const { dataVisualizationConfig } = group;
    return {
      scheduleClassifications,
      series: timeSeriesWithConfigurations,
      dataVisualizationConfig
    };
  }
  /**
   * @description returns array of schedulers for given series.
   * @function
   * @param {Array} series Time Series array
   * @return {Array} schedulers array
   */
  async function fetchTimeseriesGroupSchedulers(series) {
    const schedulersSet = series.reduce((result, seriesItem) => {
      if (
        seriesItem.dataScheduler != null &&
        typeof seriesItem.dataScheduler == 'string'
      ) {
        result.add(seriesItem.dataScheduler);
      }
      return result;
    }, new Set());
    const schedulers = Array.from(schedulersSet);
    try {
      const { data } = await SchedulerModel.read({ _id: schedulers });
      return data;
    } catch (err) {
      AlertingService.Error(err);
      return [];
    }
  }

  /**
   * @description populates series array with physical data and sets color and chart type.
   * @function
   * @param {Array} series
   * @return {Array}
   */
  async function populateSandBoxSeries(series) {
    //PHYSICAL DATA
    const physicalQuantities = await PhysicalCollectionService.returnPhysicalQuantities();
    const measurementUnits = await PhysicalCollectionService.returnMeasurementUnits();
    const chartTypes = TranslationService.GetCollection('codelists.chartTypes');

    const schedulers = await fetchTimeseriesGroupSchedulers(series);

    return series.map(item => {
      let measurementUnit =
        typeof item.measurementUnit == 'object' && item.measurementUnit != null
          ? item.measurementUnit
          : measurementUnits.find(
            measurementUnitItem =>
              measurementUnitItem._id == item.measurementUnit
          );
      let suffix = '';
      if (typeof measurementUnit == 'object' && measurementUnit != null) {
        let metricPrefix = TranslationService.GetCollectionById(
          'codelists.metricPrefixes',
          item.metricPrefix
        );
        suffix = `${metricPrefix != null ? metricPrefix.symbol : ''} ${
          measurementUnit.symbol
        }`;
      }
      let chartType = chartTypes.find(
        chartItem => chartItem.id == item.chartType
      );
      if (chartType == null) {
        chartType = chartTypes[0];
      }
      let timeZone = TranslationService.GetCollectionById(
        'codelists.timeZones',
        item.timeZone
      );
      let step = undefined;
      if (item.dataInterpretationType === 200) {
        step = 'left';
      } else if (
        item.dataInterpretationType === 300 &&
        chartType.id != 1 &&
        chartType.id != 5
      ) {
        step = 'right';
      }

      let dataScheduler = {};
      if (
        item.dataScheduler != null &&
        typeof item.dataScheduler === 'string'
      ) {
        dataScheduler = schedulers.find(
          scheduler => scheduler._id === item.dataScheduler
        );
      } else if (
        typeof item.dataScheduler === 'object' &&
        item.dataScheduler != null
      ) {
        dataScheduler = item.dataScheduler;
      }
      return {
        id: item._id,
        name: item.name,
        timeZone,
        physicalQuantity:
          typeof item.physicalQuantity == 'object' &&
          item.physicalQuantity != null
            ? item.physicalQuantity
            : physicalQuantities.find(
              physicalQuantityItem =>
                physicalQuantityItem._id == item.physicalQuantity
            ),
        suffix,
        chartType: chartType,
        boundaries: [],
        dataSamplingType: item.dataSamplingType,
        dataInterpretationType: item.dataInterpretationType,
        dataScheduler,
        min: item.min,
        max: item.max,
        color: item.color,
        dataType: item.dataType,
        precision: item.precision,
        stacking: chartType.stacking,
        step
      };
    });
  }
  /**
   * @description returns chart series configuration to display in sandbox chart.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  async function constructConfigurationOutOfSandBoxModel(
    activeSeries,
    displayType,
    defaultFilter
  ) {
    const displayTypes = TranslationService.GetCollection(
      'codelists.displayTypes'
    );
    let currentDisplayType = displayTypes.find(item => item.id == displayType);
    if (Array.isArray(activeSeries)) {
      let series = await populateSandBoxSeries(activeSeries);
      currentDisplayType = currentDisplayType || displayTypes[0];
      let filter = getFilter(defaultFilter || {});

      return {
        series,
        filter,
        displayType: currentDisplayType
      };
    }
  }
  /**
   * @description returns timeseries or time series group data .
   * @function
   * @param {Object} ref {timeSeriesId, groupId}
   * @return {Object}
   */
  async function get(config) {
    let {
      timeseriesId,
      timeseriesGroupId,
      series,
      displayType,
      defaultFilter,
      sortType,
      chartType
    } = config;
    if (timeseriesId != null) {
      return await fetchTimeSeries(timeseriesId);
    } else if (timeseriesGroupId) {
      return await fetchTimeSeriesGroup(timeseriesGroupId);
    } else if (series) {
      const {
        series: processedSeries,
        filter,
        displayType: processedDisplayType
      } = await constructConfigurationOutOfSandBoxModel(
        series,
        displayType,
        defaultFilter
      );
      const processedSeriesWithTimeSeriesConfiguration = await fetchAndAddTimeSeriesConfigurations(
        processedSeries
      );
      return await buildDataXConfiguration({
        series: processedSeriesWithTimeSeriesConfiguration,
        defaultFilter: filter,
        displayType: processedDisplayType,
        sortType,
        chartType
      });
    } else {
      return {};
    }
  }
  /**
   * @description compares series stack values.
   * @function
   * @param {String} stackA
   * @param {String} stackB
   * @return {Boolean}
   */
  function compareStacking(stackA, stackB) {
    if (stackA == 'percent' && stackB == 'percent') {
      return true;
    } else if (
      (stackA == 'normal' || stackA == null) &&
      (stackB == 'normal' || stackB == null)
    ) {
      return true;
    }
    return false;
  }

  /**
   * @description Processes input axes into highcharts object.
   * @function
   * @param {object} metadata
   * @return {array}
   */
  const processAxes = ({ series }, sortSettings = {}) => {
    const includeGuides = Array.isArray(series) && series.length == 1;
    const yAxes = series.reduce((rawAxes, set) => {
      let {
        physicalQuantity,
        id,
        suffix,
        boundaries,
        min,
        max,
        dataType,
        stacking
      } = set;
      let existingAxisIndex = -1;
      if (physicalQuantity) {
        existingAxisIndex = rawAxes.findIndex(
          axis =>
            axis.physicalQuantityId == physicalQuantity._id &&
            axis.suffix == suffix &&
            compareStacking(axis.stacking, stacking)
        );
      } else {
        existingAxisIndex = rawAxes.findIndex(axis => {
          return (
            compareStacking(axis.stacking, stacking) &&
            axis.physicalQuantityId === ''
          );
        });
      }
      if (existingAxisIndex >= 0) {
        rawAxes[existingAxisIndex].bands = [
          ...rawAxes[existingAxisIndex].bands,
          ...processBoundaries(boundaries)
        ];

        rawAxes[existingAxisIndex].lines = [
          ...rawAxes[existingAxisIndex].lines,
          ...processMinMax(min, max)
        ];

        rawAxes[existingAxisIndex].seriesIncluded.push(id);
      } else {
        const newAxis = {
          title: physicalQuantity ? physicalQuantity.name : '',
          suffix: suffix,
          bands: [],
          lines: [],
          formatter: formatChartValues(dataType),
          seriesIncluded: [id],
          stacking,
          physicalQuantityId:
            physicalQuantity != null ? physicalQuantity._id : ''
        };
        newAxis.stackLabels = {
          enabled: true,
          formatter: function() {
            const {
              decimalDelimiter,
              thousandsDelimiter
            } = LocalizationService.GetSelectedLang();
            return `${Highcharts.numberFormat(
              this.total,
              1,
              decimalDelimiter,
              thousandsDelimiter
            )} ${newAxis.suffix}`;
          }
        };
        if (includeGuides) {
          newAxis.bands = processBoundaries(boundaries);
          newAxis.lines = processMinMax(min, max);
        }

        rawAxes = [...rawAxes, newAxis];
      }
      return rawAxes;
    }, []);

    // xAxis
    const {
      formatter,
      minTickInterval
    } = getXAxisMinTimeIntervalsAndFormatter({ series });

    const xAxes = [
      {
        id: 'basic',
        title: gettextCatalog.getString('Time'),
        type: 'datetime',
        formatter,
        minTickInterval,
        ...sortSettings
      }
    ];

    return {
      x: xAxes,
      y: yAxes
    };
  };

  const processBoundaries = boundaries => {
    if (Array.isArray(boundaries)) {
      return boundaries.map(({ from, to, color }) => {
        return { from, to, color };
      });
    }
    return [];
  };

  const processMinMax = (min, max) => {
    const lines = [];
    if (min != null) {
      lines.push({
        value: min,
        color: '#ff0000',
        isMin: true
      });
    }
    if (max != null) {
      lines.push({
        value: max,
        color: '#ff0000',
        isMax: true
      });
    }
    return lines;
  };

  /**
   * @description returns object that formats chart date and value.
   * @function
   * @param {Number} dataType codelist id of time series data type
   * @return {Object} {
   *  value <function>
   *  data <function>
   *  }
   */
  const formatChartValues = dataType => {
    let fn;
    switch (dataType) {
    case 2:
    case 3:
      fn = Formatting.formatNumber;
      break;
    default:
      fn = formatValue(dataType);
    }
    return fn;
  };

  /**
   * @description Processes series for dataX configuration.
   * @function
   * @param {array} series
   * @param {object} axis {y : Array, x: Array}
   * @return {array}
   */
  const processSeries = (series, { y }, transformToUTC) => {
    return series.map(
      ({
        id,
        name,
        nonNumerical,
        precision,
        color,
        chartType,
        stacking,
        timeZone,
        step,
        timeSeriesConfiguration = [],
        dataInterpretationType,
        dataSamplingType,
        dataScheduler,
        minTickInterval
      }) => {
        const yAxisIndex = y.reduce((currentIndex, axis, axisIndex) => {
          return axis.seriesIncluded.indexOf(id) >= 0
            ? axisIndex
            : currentIndex;
        }, 0);
        let params = JSON.stringify({ id });

        const isRegularAggregateTimeset =
          dataInterpretationType == 300 && dataSamplingType == 100;
        // dont include intervals lower than daily
        const scheduleSmallerThanDaily =
          dataScheduler != null &&
          [1, 6, 7, 9].indexOf(dataScheduler.scheduleClassification) >= 0;
        const isColumnChart = [7, 10, 11].includes(chartType.id);
        const isAreaChart = [4, 12, 14].includes(chartType.id);
        const isAreaSpline = chartType.id == 5;
        const isBarChart = [9].includes(chartType.id);
        const { xFormatter } = getSeriesFormatter({
          dataScheduler,
          isRegularAggregateTimeset
        });

        return {
          id,
          name,
          query: getValues({
            id,
            transformToUTC,
            timeZone,
            timeSeriesMaintenanceFlows: timeSeriesConfiguration.filter(
              item => item.flowRef == 500 || item.flowRef == 510
            ),
            isRegularAggregateTimeset,
            ignoreAggregateSamplingShift: scheduleSmallerThanDaily,
            isAreaChart,
            isAreaSpline,
            dataInterpretationType,
            color,
            chartTypeCode: chartType.highChartCode
          }),
          x: 0,
          y: yAxisIndex,
          decimalPrecision: precision,
          minTickInterval,
          xFormatter: series.length > 0 ? xFormatter : undefined,
          formatXWithRegardsToValidity:
            isRegularAggregateTimeset && !scheduleSmallerThanDaily,
          shiftValuesToMinInterval:
            isRegularAggregateTimeset &&
            !scheduleSmallerThanDaily &&
            (isColumnChart || isBarChart),
          insertMinIntervalNullGaps:
            isRegularAggregateTimeset &&
            !scheduleSmallerThanDaily &&
            isAreaChart,
          color,
          stateName: `data-time-series-view(${params})`.replace(/"/g, '\''),
          type: chartType.highChartCode || 'line',
          nonNumerical,
          stack: yAxisIndex,
          stacking,
          connectNulls: stacking ? true : false,
          step
        };
      }
    );
  };

  /**
   * @description returns function that returns chart values.
   * @function
   * @param {string} timeSeriesId
   * @param {number} dataType codelist id of time series data type
   * @return {function}
   */
  // eslint-disable-next-line no-unused-vars
  function getValues({
    id,
    timeZone,
    transformToUTC,
    timeSeriesMaintenanceFlows = [],
    isRegularAggregateTimeset,
    isAreaChart,
    isAreaSpline,
    dataInterpretationType,
    ignoreAggregateSamplingShift,
    chartTypeCode
  }) {
    /**
     * @description returns sorted values.
     * @function
     * @param {Object} filter filter object
     * @param {Boolean} manualRefresh indicates when chart refresh is triggered manually
     * @return {Promise} Array
     */
    return async (filter, manualRefresh) => {
      let apiObject = {};
      let offset = 0;
      const invalidationTime = manualRefresh ? 0 : 100;

      apiObject.from =
        filter.from == null || filter.from === '' ? null : filter.from;

      apiObject.to = filter.to == null || filter.to === '' ? null : filter.to;

      if (filter.pointInTime) {
        apiObject.pointInTime = filter.pointInTime;
      }

      apiObject.limit = filter.limit || defaultValuesLimit;
      apiObject.view = 'simple';

      try {
        const { data: values } = await TimeSeriesProcessingValuesModel.read(
          {
            timeSeriesId: id,
            ...apiObject
          },
          invalidationTime
        );
        const IANAtimezone = new IANAZone(timeZone);
        const maintenanceFlows = getMaintenanceIntervals({
          values,
          maintenanceValues: timeSeriesMaintenanceFlows.filter(
            flow => flow.flowRef == 500 || flow.flowRef == 510
          ),
          isRegularAggregateTimeset
        });

        if (values.length > 0) {
          let sortedValues = values
            .concat(maintenanceFlows)
            .sort((valueA, valueB) => {
              return valueA.validAt - valueB.validAt;
            });
          //When we transform date to utc (sandbox) we must determine last active value before transformation
          let firstFutureItemIndex = -1;
          const currentTime = new Date().getTime();
          if (sortedValues[sortedValues.length - 1].validAt < currentTime) {
            firstFutureItemIndex = sortedValues.length - 1;
          } else {
            firstFutureItemIndex =
              sortedValues.findIndex(item => item.validAt > currentTime) - 1;
            sortedValues[firstFutureItemIndex];
          }

          const applyAggregateSamplingShift =
            isRegularAggregateTimeset &&
            !ignoreAggregateSamplingShift &&
            !isAreaChart;

          let resultValues = sortedValues.map((valueItem, index) => {
            const { validAt, validFrom, creationType, value } = valueItem;
            if (
              transformToUTC &&
              timeZone &&
              IANAtimezone.valid &&
              validAt != null
            ) {
              offset = IANAtimezone.offset(validAt);
            }
            let lastItemStyleSet = {};
            if (index === firstFutureItemIndex) {
              lastItemStyleSet = {
                lastActive: true
              };
            }
            const isInterpolated = creationType === 210 || creationType === 220;
            const marker = isInterpolated
              ? {
                fillColor: '#FFFFFF'
              }
              : {};
            const invertColumnColors =
              isInterpolated &&
              typeof chartTypeCode === 'string' &&
              isTypeColumnOrBar(chartTypeCode)
                ? {
                  invertColumnColors: true
                }
                : {};
            const x = applyAggregateSamplingShift
              ? validFrom + (validAt - validFrom) / 2
              : validAt;

            return {
              x: new Date(x + offset * 60000),
              y: value,
              validAt,
              validFrom,
              marker,
              ...invertColumnColors,
              ...lastItemStyleSet
            };
          });

          //PUSH EMPTY POINT WHEN TS INTERPRETATION TYPE IS AGGREGATE INTERVAL
          // AND NOT COLUMN CHART TYPE (ADDS LINE IN THE BEGGINING)
          if (
            isRegularAggregateTimeset &&
            resultValues.length > 0 &&
            (isAreaChart || (isAreaSpline && dataInterpretationType != 300))
          ) {
            resultValues.unshift({
              ...resultValues[0],
              x: new Date(resultValues[0].validFrom),
              showOnlyInChart: true,
              marker: {
                enabled: false
              }
            });
          }
          return resultValues;
        }
        return [];
      } catch (err) {
        AlertingService.Error(err);
        return [];
      }
    };
  }

  /**
   * @description Get maintenence intervals values based on values (do not include maintenece outside the timeline of values)
   * @function
   * @param {Array} values
   * @param {Array} maintenanceValues
   * @param {boolean} isRegularAggregateTimeset
   * @return {Array}
   */
  function getMaintenanceIntervals({
    values,
    maintenanceValues,
    isRegularAggregateTimeset
  }) {
    const valuesValidity = values.reduce(
      ({ min, max }, { validFrom, validAt }) => {
        const minComparator = isRegularAggregateTimeset ? validFrom : validAt;
        return {
          min: min == null || min > minComparator ? minComparator : min,
          max: max == null || max < validAt ? validAt : max
        };
      },
      { min: null, max: null }
    );
    const flowValue = {
      value: undefined,
      creationType: 100,
      showOnlyInChart: true
    };

    return maintenanceValues.reduce((flowValues, { validFrom, validTo }) => {
      const validFromDate = new Date(validFrom);
      const validToDate = new Date(validTo);
      if (
        validFromDate != null &&
        validTo != null &&
        validFromDate.getTime() > valuesValidity.min &&
        validToDate.getTime() < valuesValidity.max
      ) {
        flowValues.push({
          validAt: validFromDate + 1,
          ...flowValue
        });
        flowValues.push({
          ...flowValue,
          validAt: validToDate - 1
        });
      }
      return flowValues;
    }, []);
  }

  /**
   * @description depending od data type returns  formatted value
   * @function
   * @param {Number} dataType codelist id of time series data type
   * @return {dataType}
   */
  function formatValue(dataType) {
    return value => {
      let formatIdentifier;
      switch (dataType) {
      case 5: // Date
        formatIdentifier = 'd';
        break;
      case 6: // Time
        formatIdentifier = 'timeonly';
        break;
      case 7: // DateTime
        formatIdentifier = 's';
        break;
      }
      if (formatIdentifier && value != null) {
        return DateLocalizationService.LocalizationDateIntervalFn(
          formatIdentifier
        )(value);
      } else {
        return value;
      }
    };
  }
  /**
   * @description returns display mode object.
   * @function
   * @param {Object} displayType codelist display type object
   * @return {Object}
   */
  function getDisplayType(displayType) {
    switch (displayType.componentCode) {
    case 'chart':
      return {
        chart: true,
        grid: false
      };
    case 'table':
      return {
        chart: false,
        grid: true
      };
    case 'chartTable':
      return {
        chart: true,
        grid: true
      };
    }
    return {
      chart: true,
      grid: true
    };
  }

  function getChartSortBySumSettings(visualizationConfig) {
    let { sortType, chartType } = visualizationConfig;
    let sortSettings = {};
    if (sortType == 10) {
      sortSettings.axisDisplayType = 'sortBySum';
    }

    let chartTypeObject = TranslationService.GetCollectionById(
      'codelists.chartTypes',
      chartType
    );
    if (chartTypeObject != null) {
      sortSettings.sortingChartType = chartTypeObject.highChartCode;
    }

    sortSettings.sortDirection = 'DESC';

    return sortSettings;
  }

  /**
   * @description returns minimal minTickIntervals and formatter on axes
   * @function
   * @param {Array} series array of series
   * @return {Object} { formatter: function, minTickInterval: number }
   */
  const getXAxisMinTimeIntervalsAndFormatter = ({ series }) => {
    const defaultMinTimeAndFormatter = {
      formatter: DateLocalizationService.LocalizationDateFn,
      minTickInterval: null
    };
    const seriesIncludeOtherThanRegularAggregate = series.find(
      ({ dataInterpretationType, dataSamplingType }) => {
        return dataInterpretationType != 300 || dataSamplingType != 100;
      }
    );
    if (seriesIncludeOtherThanRegularAggregate) {
      const minTick = series.reduce((min, { minTickInterval }) => {
        if (minTickInterval && (min == null || min > minTickInterval))
          return minTickInterval;
        else return min;
      }, null);
      return {
        ...defaultMinTimeAndFormatter,
        minTickInterval: minTick
      };
    } else {
      return series.reduce(
        (minimalValueYet, set) => {
          const {
            minTickInterval,
            dataScheduler,
            dataInterpretationType,
            dataSamplingType
          } = set;
          const isRegularAggregateTimeset =
            dataInterpretationType == 300 && dataSamplingType == 100;

          const { xFormatter } = getSeriesFormatter({
            dataScheduler,
            isRegularAggregateTimeset
          });

          return minTickInterval < minimalValueYet.minTickInterval ||
            minimalValueYet.minTickInterval == null
            ? {
              minTickInterval,
              formatter:
                  xFormatter || DateLocalizationService.LocalizationDateFn
            }
            : minimalValueYet;
        },
        { ...defaultMinTimeAndFormatter }
      );
    }
  };

  /**
   * @description returns sfe-data-x configuration.
   * @function
   * @param {Object} series
   * @return {Object} { minTickInterval: number, formatter: Function }
   */
  const getSeriesFormatter = ({ dataScheduler, isRegularAggregateTimeset }) => {
    let schedule = null;
    if (dataScheduler != null) {
      schedule = codelistsConstant['scheduleClassifications'].find(
        ({ id }) => id === dataScheduler.scheduleClassification
      );
    }
    const scheduleSmallerThanDaily =
      schedule != null && [1, 6, 7, 9].indexOf(schedule.id) >= 0;
    const xFormatter =
      schedule && isRegularAggregateTimeset && !scheduleSmallerThanDaily
        ? DateLocalizationService.intervalCachedFns[
          schedule.abbreviation.toLowerCase()
        ]
        : null;
    return {
      xFormatter
    };
  };

  /**
   * @description returns sfe-data-x configuration.
   * @function
   * @param {Object} ref { series<Array>, displayType<Object>, defaultFilter<Object> }
   * @return {dataType}
   */
  const buildDataXConfiguration = config => {
    let {
      series,
      displayType,
      defaultFilter,
      sortType,
      chartType,
      transformToUTC,
      timeSeriesConfiguration
    } = config;

    let sortSettings = getChartSortBySumSettings({ sortType, chartType });

    const axis = processAxes({ series }, sortSettings);
    let displayTypeObj = {};
    if (typeof displayType == 'number' && !isNaN(displayType)) {
      displayTypeObj = TranslationService.GetCollectionById(
        'codelists.displayTypes',
        displayType
      );
    } else if (displayType != null && displayType.componentCode != null) {
      displayTypeObj = { ...displayType };
    }
    const mode = getDisplayType(displayTypeObj);
    const configuration = {
      axis,
      timeSeriesConfiguration,
      filter: () => constructQueryFilter(defaultFilter || {}),
      series: processSeries(series, axis, transformToUTC),
      mode,
      alternativeMode: null,
      chart: {
        legend: series.length > 1,
        legendAlign: 'center',
        markers: true,
        markerPlotOptions: {
          lineColor: null, // inherit from series
          lineWidth: 2
        },
        dataLabels: false,
        axisYTitle: true,
        axisXTitle: true,
        height: 320
      },
      grid: {
        height: 320,
        extraColumns: [],
        actions: [],
        seriesAction: {
          icon: 'info_outline',
          fn: params => {
            const id = params.colDef.field;
            let selectedSeries = series.find(item => item.id === id);
            if (selectedSeries != null && typeof selectedSeries == 'object') {
              GetTimeSeriesProcessingByTimestampService.openExtendedData(
                params,
                selectedSeries
              );
            }
          }
        }
      },
      translations: SfeDataXTranslations.get()
    };
    return configuration;
  };
  /**
   * @description Get model from chart api and find min and max date.
   * @function
   * @param {Object} element chart dashboard element that contains chart api object
   * @return {Object}
   */
  function getDisplayedTime(api) {
    if (typeof api.getModel == 'function') {
      let chartModel = api.getModel();
      if (chartModel != null && Array.isArray(Object.keys(chartModel.series))) {
        let dates = Object.keys(chartModel.series).map(key => {
          let item = chartModel.series[key];
          if (Array.isArray(item.data) && item.data.length > 0) {
            if (item.data[0].date != null) {
              const sortedItems = item.data.sort((a, b) => a.date - b.date);
              return {
                first: sortedItems[0].date,
                last: sortedItems[sortedItems.length - 1].date
              };
            }
            return {
              first: item.data[0].x,
              last: item.data[item.data.length - 1].x
            };
          }
          return {};
        });

        let startDate = dates.reduce(
          (minDate, date) => (date.first < minDate ? date.first : minDate),
          dates[0].first
        );
        let endDate = dates.reduce(
          (maxDate, date) => (date.last > maxDate ? date.last : maxDate),
          dates[0].last
        );
        if (startDate != null && endDate != null) {
          if (startDate == endDate) {
            //when start and end dates are the same add 1 minute to an end date
            endDate = new Date(endDate.getTime() + 60000);
          }
          return {
            startDate,
            endDate
          };
        }
      }
    }
  }
  /**
   * @description gets shown dates and opens select date and report dialog.
   * @function
   * @param {Object} api dataX api
   * @param {Array} ids time series ids
   */
  async function generateTimeSeriesReport(api, ids) {
    try {
      let filterDates = getDisplayedTime(api);
      const redirectParams = await generateEntityReport(234, ids, filterDates);

      if (redirectParams != null) {
        $state.go('analytics-viewer-report-generic', redirectParams);
      }
    } catch (err) {
      AlertingService.Error(err);
    }
  }
  /**
   * @description returns function that opens export values dialog.
   * @function
   * @param {Object} dataX api
   * @return {Function}
   */
  function getExportDataFunction(apiDataX) {
    return () => {
      const model = apiDataX.getModel();
      if (model != null) {
        const { series } = model;
        if (series != null) {
          const tsId = Object.keys(series)[0];
          const { configuration } = series[tsId];
          if (configuration != null) {
            DownloadCSVService.showDownloadDialog(
              configuration.name,
              'Time series',
              'time-series-processing-value',
              tsId
            );
          }
        }
      }
    };
  }
  function isTypeColumnOrBar(type) {
    return ['bar', 'column'].includes(type.toLowerCase());
  }
  return {
    get,
    getFilter,
    constructQueryFilter,
    getDisplayType,
    generateTimeSeriesReport,
    processAxes,
    getChartSortBySumSettings,
    createSuffix,
    processSeries,
    getExportDataFunction,
    isTypeColumnOrBar,
    getSeriesFormatter,
    getXAxisMinTimeIntervalsAndFormatter,
    getMaintenanceIntervals
  };
}
export default TangoTimeSeriesDataXHelperService;
