import actionTypes from '../action/times-series.type';

TimeSeriesMiddleware.$inject = [
  'TimeSeriesModel',
  'AlertingService',
  'CrudToastFactory'
];
function TimeSeriesMiddleware(
  TimeSeriesModel,
  AlertingService,
  CrudToastFactory
) {
  return () => next => async action => {
    switch (action.type) {
    case actionTypes.TIME_SERIES_UPDATE:
      try {
        await TimeSeriesModel.update(
          action.payload.query,
          action.payload.body
        );
        CrudToastFactory.toast('update');
      } catch (err) {
        AlertingService.Error(err);
      }
      try {
        action.payload.result = await TimeSeriesModel.custom.readItemView({
          ...action.payload.query,
          populate:
              'timeSeriesType,dataScheduler,rules.rule,physicalQuantity,measurementUnit,tariff'
        });
      } catch (err) {
        AlertingService.Error(err);
        action.payload.result = err;
      }
      break;
    case actionTypes.TIME_SERIES_CREATE:
      try {
        action.payload.result = await TimeSeriesModel.create(
          action.payload.query || {},
          action.payload.body
        );
      } catch (err) {
        AlertingService.Error(err);
      }
      break;
    }
    next(action);
  };
}

export default TimeSeriesMiddleware;
