ExternalReaderTangoProperties.$inject = [
  'gettextCatalog',
  'ValidateObjectConfiguration',
  'TangoPropertiesHelper',
  'StandardUtils',
  'TranslationService'
];

function ExternalReaderTangoProperties(
  gettextCatalog,
  ValidateObjectConfiguration,
  TangoPropertiesHelper,
  StandardUtils,
  TranslationService
) {
  const nameIdObjectConfiguration = [
    {
      fieldName: 'name',
      type: 'string',
      required: true
    },
    {
      fieldName: '_id',
      type: 'string',
      required: true
    }
  ];

  let ingestionFlowValidationConfig = [
    {
      fieldName: 'mapper',
      type: 'object',
      configuration: nameIdObjectConfiguration,
      required: true
    },
    {
      fieldName: 'tangoAgentConfig',
      type: 'object',
      required: true,
      configuration: [
        {
          fieldName: 'connectionConfig',
          type: 'object',
          required: true,
          configuration: nameIdObjectConfiguration
        },
        {
          fieldName: 'tag',
          type: 'object',
          required: true,
          configuration: nameIdObjectConfiguration
        },
        {
          fieldName: 'readType',
          type: 'number',
          required: true
        },
        {
          fieldName: 'initialBatchIntervalInMilliseconds',
          type: 'number',
          required: false
        },
        {
          fieldName: 'futureIntervalInMilliseconds',
          type: 'number',
          required: false
        }
      ]
    }
  ];
  /**
   * @description returns list sfe-property configurations to display timeout properties.
   * @function
   * @param {Object} item entity item
   * @return {Array}
   */
  function getTimeLimitProperties(item) {
    const validationConfig = [
      {
        fieldName: 'maxTimeoutInMilliseconds',
        type: 'number',
        required: true
      },
      {
        fieldName: 'action',
        type: 'number',
        required: true
      },
      {
        fieldName: 'alarm',
        type: 'object',
        required: true,
        configuration: nameIdObjectConfiguration
      }
    ];
    if (item.timeout != null && typeof item.timeout == 'object') {
      const { configuration } = ValidateObjectConfiguration.validate(
        item.timeout,
        validationConfig
      );

      return configuration.reduce((result, config) => {
        if (config.validity) {
          let testId = config.fieldName;
          switch (config.fieldName) {
          case 'maxTimeoutInMilliseconds':
            result = [
              ...result,
              TangoPropertiesHelper.sfePropertyConfig({
                title: gettextCatalog.getString('Timeout'),
                type: 'basic',
                validationConfig: config,
                item: item.timeout,
                testId,
                getSfePropertyValues: ({ value }) => {
                  return [
                    {
                      text: `${
                        value == 0
                          ? '0 s'
                          : StandardUtils.readableExactTimeOutOfSeconds(
                            value / 1000
                          )
                      }`
                    }
                  ];
                }
              })
            ];
            break;
          case 'action':
            result = [
              ...result,
              TangoPropertiesHelper.sfePropertyConfig({
                title: gettextCatalog.getString('Timeout action'),
                testId,
                type: 'basic',
                validationConfig: config,
                item: item.timeout,
                getSfePropertyValues: ({ item }) => {
                  let text;
                  if (item != null) {
                    const timeoutAction = TranslationService.GetCollectionById(
                      'codelists.externalReaderFlowTimeoutActions',
                      item.action
                    );
                    const timeoutActionName =
                        timeoutAction != null
                          ? timeoutAction.name
                          : gettextCatalog.getString('Unknown');
                    let timeoutArgumentName = '';
                    if (
                      Array.isArray(item.actionArguments) &&
                        item.actionArguments.length
                    ) {
                      const timeUnit = TranslationService.GetCollectionById(
                        'codelists.timeUnits',
                        item.actionArguments[0].value
                      );
                      timeoutArgumentName =
                          timeUnit != null ? `( ${timeUnit.name} )` : '';
                    }
                    text = `${timeoutActionName}${timeoutArgumentName}`;
                  }
                  return [{ text }];
                }
              })
            ];
            break;
          case 'alarm':
            result = [
              ...result,
              TangoPropertiesHelper.sfePropertyConfig({
                item: item.timeout,
                type: 'basic',
                validationConfig: config,
                title: gettextCatalog.getString('Timeout Alarm'),
                testId,
                stateName: 'alarms-and-rules-alarm-definitions-view',
                parameters: [{ name: 'id', value: '_id' }]
              })
            ];
            break;
          }
        }
        return result;
      }, []);
    }
  }

  /**
   * @description returns array of sfe-property configurations to display history flow.
   * @function
   * @param {Object} item entity item
   * @return {Array}
   */
  function getLiveFlow(item) {
    if (
      item.liveIngestionConfig != null &&
      typeof item.liveIngestionConfig == 'object'
    ) {
      const { configuration } = ValidateObjectConfiguration.validate(
        item.liveIngestionConfig,
        ingestionFlowValidationConfig
      );
      return configuration.reduce((result, config) => {
        if (config.validity) {
          switch (config.fieldName) {
          case 'mapper':
            // eslint-disable-next-line no-case-declarations
            let mappingRuleConfig = getMappingRuleConfig(
              item.liveIngestionConfig,
              config,
              'live'
            );
            result = [...result, ...mappingRuleConfig];
            break;
          case 'tangoAgentConfig':
            // eslint-disable-next-line no-case-declarations
            let tangoAgentConfigProperties = getTangoAgentConfig(
              config.configuration,
              item.liveIngestionConfig.tangoAgentConfig
            );
            result = [...result, ...tangoAgentConfigProperties];
            break;
          }
        }
        return result;
      }, []);
    }
    return [];
  }

  function getMappingRuleConfig(item, config, type) {
    return [
      TangoPropertiesHelper.sfePropertyConfig({
        item: item,
        type: 'basic',
        validationConfig: config,
        title: gettextCatalog.getString('Mapping rule'),
        stateName: 'alarms-and-rules-mapping-rules-view',
        parameters: [{ name: 'id', value: '_id' }],
        testId: `${type}MappingRule`
      })
    ];
  }

  /**
   * @description returns tango agent config properties configurations.
   * @function
   * @param {Array} configurations Array of tango agent config object validation results
   * @param {Object} item tangoAgentConfig item
   * @return {Array}
   */
  function getTangoAgentConfig(configurations, item) {
    return configurations.reduce((result, config) => {
      if (config.validity) {
        let testId = config.fieldName;
        switch (config.fieldName) {
        case 'connectionConfig':
          result = [
            ...result,
            TangoPropertiesHelper.sfePropertyConfig({
              item: item,
              type: 'basic',
              testId,
              validationConfig: config,
              title: gettextCatalog.getString('Tango agent config'),
              stateName:
                  'external-datasources-tango-agent-connection-config-view',
              parameters: [{ name: 'id', value: '_id' }]
            })
          ];
          break;
        case 'tag':
          result = [
            ...result,
            TangoPropertiesHelper.sfePropertyConfig({
              item: item,
              type: 'basic',
              testId,
              validationConfig: config,
              title: gettextCatalog.getString('Tags')
            })
          ];
          break;
        case 'readType':
          result = [
            ...result,
            TangoPropertiesHelper.sfePropertyConfig({
              title: gettextCatalog.getString('Read type'),
              type: 'basic',
              testId,
              validationConfig: config,
              item: item,
              getSfePropertyValues: TangoPropertiesHelper.getCodeListValue(
                'readTypes'
              )
            })
          ];
          break;
        case 'initialBatchIntervalInMilliseconds':
          // readType === batch
          if (item != null && item.readType === 20) {
            result = [
              ...result,
              TangoPropertiesHelper.sfePropertyConfig({
                title: gettextCatalog.getString('Initial batch interval'),
                type: 'basic',
                testId,
                validationConfig: config,
                item: item,
                getSfePropertyValues: ({ value }) => {
                  return [
                    {
                      text: `${
                        value == 0
                          ? '0 s'
                          : StandardUtils.readableExactTimeOutOfSeconds(
                            value / 1000
                          )
                      }`
                    }
                  ];
                }
              })
            ];
          }
          break;
        case 'futureIntervalInMilliseconds':
          // readType === forecast
          if (item != null && item.readType === 30) {
            result = [
              ...result,
              TangoPropertiesHelper.sfePropertyConfig({
                title: gettextCatalog.getString('Future interval'),
                type: 'basic',
                testId,
                validationConfig: config,
                item: item,
                getSfePropertyValues: ({ value }) => {
                  return [
                    {
                      text: `${
                        value == 0
                          ? '0 s'
                          : StandardUtils.readableExactTimeOutOfSeconds(
                            value / 1000
                          )
                      }`
                    }
                  ];
                }
              })
            ];
          }
          break;
        }
      }
      return result;
    }, []);
  }
  /**
   * @description returns array of sfe-property configurations to display history flow.
   * @function
   * @param {Object} item entity item
   * @return {Array}
   */
  function getHistoryFlow(item) {
    if (item.historyIngestionConfig != null && typeof item == 'object') {
      const { configuration } = ValidateObjectConfiguration.validate(
        item.historyIngestionConfig,
        ingestionFlowValidationConfig
      );
      return configuration.reduce((result, config) => {
        if (config.validity) {
          switch (config.fieldName) {
          case 'mapper':
            // eslint-disable-next-line no-case-declarations
            let mappingRuleConfig = getMappingRuleConfig(
              item.historyIngestionConfig,
              config,
              'history'
            );
            result = [...result, ...mappingRuleConfig];
            break;
          case 'tangoAgentConfig':
            // eslint-disable-next-line no-case-declarations
            let tangoAgentConfigProperties = getTangoAgentConfig(
              config.configuration,
              item.historyIngestionConfig.tangoAgentConfig
            );
            result = [...result, ...tangoAgentConfigProperties];
          }
        }
        return result;
      }, []);
    }
    return [];
  }

  /**
   * @description returns ingestion type, dataSource and reading scheduler config.
   * @function
   * @param {Object} item entity item
   * @return {Object}
   */
  function getIngestionType(item) {
    let ingestionTypeValue = {
      text: null
    };

    let readSchedulerValue = {
      text: null
    };
    let externalDatasourceValue = {
      text: null
    };
    if (item != null) {
      // 100 = MQTT client
      let mqttClient = TranslationService.GetCollectionById(
        'codelists.ingestionTypes',
        100
      );
      // 200 = Manual input
      let manualInput = TranslationService.GetCollectionById(
        'codelists.ingestionTypes',
        200
      );
      ingestionTypeValue.text = item.externalDatasource
        ? mqttClient.name
        : manualInput.name;

      if (item.readScheduler != null) {
        let schedulerParams = JSON.stringify({ id: item.readScheduler._id });
        readSchedulerValue = {
          text: item.readScheduler.name,
          link: `data-schedules-view(${schedulerParams})`
        };
      }
    }
    let ingestionType = {
      type: 'basic',
      title: gettextCatalog.getString('Ingestion type'),
      testId: 'ingestionType',
      values: [ingestionTypeValue]
    };

    let readScheduler = {
      type: 'basic',
      testId: 'readScheduler',
      title: gettextCatalog.getString('Read Scheduler'),
      values: [readSchedulerValue]
    };

    let configuration = [ingestionType];
    if (item.externalDatasource != null) {
      let externalDatasourceParams = JSON.stringify({
        id: item.externalDatasource._id
      });
      externalDatasourceValue = {
        text: item.externalDatasource.name,
        link: `external-datasources-external-datasources-view(${externalDatasourceParams})`
      };
      let externalDatasource = {
        title: gettextCatalog.getString('External datasource'),
        type: 'basic',
        testId: 'externalDatasource',
        values: [externalDatasourceValue]
      };

      configuration.push(externalDatasource);
    }
    configuration.push(readScheduler);
    return configuration;
  }
  /**
   * @description returns sfe-property configuration to display interpolation or extropolation config.
   * @function
   * @param {Object} dataApproximation
   * @param {string} type extrapolation or interpolation type
   * @return {Object}
   */
  function getApproximationProps(dataApproximation, type) {
    let value = [];
    let functionTitle = gettextCatalog.getString('Extrapolation');
    if (type === 'interpolation') {
      functionTitle = gettextCatalog.getString('Interpolation');
    }
    if (dataApproximation[type] != null) {
      let selectedFunction = TranslationService.GetCollectionById(
        `codelists.${type}Functions`,
        dataApproximation[type].function
      );
      if (selectedFunction != null) {
        let args = '';
        if (
          Array.isArray(dataApproximation[type].functionArguments) &&
          dataApproximation[type].functionArguments.length > 0
        ) {
          const selectedArg = TranslationService.GetCollectionById(
            'codelists.timeUnits',
            dataApproximation[type].functionArguments[0].value
          );
          if (selectedArg != null) {
            args = `( ${selectedArg.name} )`;
          }
        }
        value = [{ text: `${selectedFunction.name} ${args}` }];
      }
    }
    return {
      title: functionTitle,
      type: 'basic',
      testId: type,
      values: value
    };
  }
  /**
   * @description returns data approximation sfe-properties.
   * @function
   * @param {Object} dataApproximation
   * @return {Array}
   */
  function getDataApproximationProperties(dataApproximation) {
    let interpolation = getApproximationProps(
      dataApproximation,
      'interpolation'
    );
    let extrapolation = getApproximationProps(
      dataApproximation,
      'extrapolation'
    );

    return [extrapolation, interpolation];
  }
  /**
   * @description returns configuration object to get tango properties configuration for external reader flow.
   * @function
   * @param {object} flow flow object
   * @return {Object}
   */
  function get(flow) {
    return {
      type: 'dynamic',
      valueFn: store => {
        let item = {};
        let timeSeries;
        let dataSamplingType;
        if (
          store != null &&
          store.externalReaderFlow != null &&
          store.externalReaderFlow[flow.flow] != null &&
          store.externalReaderFlow[flow.flow].data != null
        ) {
          item = store.externalReaderFlow[flow.flow].data;
        }

        let props = [
          {
            canClose: false,
            properties: getIngestionType(item)
          }
        ];

        if (
          item.dataApproximation != null &&
          (item.dataApproximation.interpolation != null ||
            item.dataApproximation.extrapolation != null)
        ) {
          props.push({
            canClose: false,
            title: gettextCatalog.getString('Data Approximation'),
            properties: getDataApproximationProperties(item.dataApproximation)
          });
        }
        if (
          store != null &&
          store.timeSeries != null &&
          flow != null &&
          flow.timeSeries != null &&
          store.timeSeries[flow.timeSeries] != null &&
          store.timeSeries[flow.timeSeries].data
        ) {
          timeSeries = store.timeSeries[flow.timeSeries].data;
          dataSamplingType = timeSeries.dataSamplingType;
        }
        if (dataSamplingType !== 200 && item.timeout != null) {
          props.push({
            canClose: false,
            title: gettextCatalog.getString('Timeout'),
            properties: getTimeLimitProperties(item)
          });
        }
        props.push(
          {
            canClose: false,
            title: gettextCatalog.getString('Live Flow'),
            properties: getLiveFlow(item)
          },
          {
            canClose: false,
            title: gettextCatalog.getString('History flow'),
            properties: getHistoryFlow(item)
          }
        );
        return props;
      }
    };
  }

  return {
    get
  };
}

export default ExternalReaderTangoProperties;
