entityDateFixedItems.$inject = [];

export default function entityDateFixedItems() {
  function list(items, valid) {
    var FROM;
    var TO;
    var NOW = new Date();
    var validItems = [];
    var invalidItems = [];
    items.forEach(function(item) {
      FROM = new Date(item.validFrom);
      TO = item.validTo ? new Date(item.validTo) : null;
      if (TO) {
        if (FROM <= NOW && TO >= NOW) {
          validItems.push(item);
        } else {
          invalidItems.push(item);
        }
      } else {
        if (FROM <= NOW) {
          validItems.push(item);
        } else {
          invalidItems.push(item);
        }
      }
    });
    return valid ? validItems : invalidItems;
  }
  return {
    list: list
  };
}
