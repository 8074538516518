module.exports = {
  entity: 'gis-map',
  path: 'map',
  gisService: true,
  name: {
    en: 'Gis maps',
    sl_SI: 'Gis zemljevidi'
  },
  networkModel: {
    entity: 'gis-maps',
    read: 'read'
  },
  dialog: {
    title: {
      en: 'Select gis map',
      sl_SI: 'Izberite gis zemljevid'
    },
    listInfo: {
      en: 'Select gis map from the list below',
      sl_SI: 'Izberite gis zemljevid s spodnjega seznama'
    },
    gisService: true,
    displayFields: [
      {
        field1: 'title',
        type: 'string'
      }
    ]
  }
}
