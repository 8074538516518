import './sfe-date-time-picker.scss';
import template from './sfe-date-time-picker.directive.html';
import { DateTime } from 'luxon';

/**
 * @ngdoc component
 * @name moduleName.sfeDateTimePicker
 * @description component that is used to display both date an time and output them in any format you wish.
 * @param {Object} config - data from the form in which the directive is in
 * @param {string} model - data passed to and from the directive.
 * @param {Object} parentForm - data from the form in which the directive is in.
 * @param {Object} dateTimeFormat - object containing the input and output formats for luxon
 * @param {boolean} noTimeInput - boolean value that tells us if we should show the time input or not
 * @example
 * <sfe-date-time-picker
 *   config: '>',
 *   mode: '=',
 *   parentForm: '='
 *   date-time-format: '<'
 *   no-time-input: '<'
 * ></sfe-date-time-picker>
 */
sfeDateTimePicker.$inject = [];

export default function sfeDateTimePicker() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      config: '<',
      model: '=',
      parentForm: '=',
      dateTimeFormat: '<',
      noTimeInput: '<'
    },
    link: linkFn,
    controller: sfeDateTimePickerController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  function linkFn(scope, el, attrs, ctrl) {
    ctrl.el = el;
    if (ctrl.parentForm) {
      scope.dateForm = ctrl.parentForm;
    }
  }
}

sfeDateTimePickerController.$inject = ['DateAutocomplete'];

function sfeDateTimePickerController(DateAutocomplete) {
  var vm = this;
  vm.dateTime = {
    date: null,
    time: null
  };
  vm.$onInit = init;

  vm.dateTimeChange = dateTimeChange;
  vm.autocompleteDate = autocompleteDate;

  function init() {
    if (vm.model) {
      vm.dateTime.date = DateTime.fromFormat(
        vm.model,
        vm.dateTimeFormat.dateTimeFormat
      ).toJSDate();
      if (!vm.config.noTimeInput) {
        vm.dateTime.time = DateTime.fromFormat(
          vm.model,
          vm.dateTimeFormat.dateTimeFormat
        ).toFormat('HH:mm:ss');
      }
    } else {
      vm.dateTime = {
        date: null,
        time: null
      };
    }
  }
  /**
   * @description description.
   * @function
   * @param {boolean} onFocus - tells us if we are trying to autocomplete on clicking into the input field or clicking out of it.
   */
  function autocompleteDate(onFocus, onBlur) {
    if (onFocus && !vm.dateTime.date) {
      vm.dateTime.date = DateTime.local();
    }
    if (onBlur) {
      const incompleteDate = vm.el[0].querySelector('.md-datepicker-input')
        .value;
      vm.dateTime.date = DateAutocomplete.get(incompleteDate);
    }
    dateTimeChange();
  }
  /**
   * @description Function that is called when either the time or date are changed and transforms modele to a specific format.
   * @function
   */
  function dateTimeChange() {
    const date = DateTime.fromJSDate(vm.dateTime.date).toFormat(
      vm.dateTimeFormat.dateFormat
    );
    if (!vm.noTimeInput) {
      vm.model = date + ' ' + vm.dateTime.time;
    } else {
      vm.model = date;
    }
  }
}
