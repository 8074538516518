import template from './filter-dialog.html';
import './filter-dialog.scss';
import { DateTime } from 'luxon';

/**
 * @ngdoc service
 * @name common.FilterDialog
 * @description Opens filter dialog
 */

FilterDialog.$inject = [
  '$mdDialog',
  'gettextCatalog',
  'SfeForm2DateFromPriorToValidationService',
  '$timeout',
  'gettext'
];
export default function FilterDialog(
  $mdDialog,
  gettextCatalog,
  SfeForm2DateFromPriorToValidationService,
  $timeout,
  gettext
) {
  /**
   * @description Opens the filtration dialog for datax.
   * @function
   * @param {Object} apiDataX
   * @return {dataType}
   */
  async function open(apiDataX, timeZoneCode, validators = {}, skipRefresh) {
    let activeFilterFn = apiDataX.getModel().getActiveFilter();

    let fromToValidation = SfeForm2DateFromPriorToValidationService.get(
      'from',
      'to'
    );

    const validatorsFrom = {
      ...validators.from,
      fromToValidation
    };
    const validatorsTo = {
      ...validators.to,
      fromToValidation
    };
    let configuration = {
      name: 'filter',
      actions: [
        {
          //RESET ACTION
          title: gettext('Reset'),
          fn: form => {
            form.setValue('from', null);
            form.setValue('to', null);
            form.setValue('limit', null);
            form.setValue('pointInTime', null);
          },
          color: 'warn'
        },
        {
          //SAVE ACTION
          title: gettext('Save'),
          fn: form => {
            let filterForm = form.getValues();
            let dateFrom =
              Array.isArray(filterForm.from) && filterForm.from.length > 0
                ? filterForm.from[0].getTime()
                : null;
            let dateTo =
              Array.isArray(filterForm.to) && filterForm.to.length > 0
                ? filterForm.to[0].getTime()
                : null;
            /* TODO CHANGE POINT_IN_TIME FIELD NAMES*/
            let pointInTime =
              Array.isArray(filterForm.pointInTime) &&
              filterForm.pointInTime.length > 0
                ? filterForm.pointInTime[0].getTime()
                : null;
            // Set date from local to timezone millis
            if (timeZoneCode != null) {
              if (dateFrom != null) {
                let dateFromObj = DateTime.fromMillis(dateFrom).toObject();
                dateFrom = DateTime.fromObject({
                  ...dateFromObj,
                  zone: timeZoneCode
                }).toMillis();
              }
              if (dateTo != null) {
                let dateToObj = DateTime.fromMillis(dateTo).toObject();
                dateTo = DateTime.fromObject({
                  ...dateToObj,
                  zone: timeZoneCode
                }).toMillis();
              }
              if (pointInTime != null) {
                let pointInTimeObj = DateTime.fromMillis(
                  pointInTime
                ).toObject();
                pointInTime = DateTime.fromObject({
                  ...pointInTimeObj,
                  zone: timeZoneCode
                }).toMillis();
              }
            }
            filterForm.from = dateFrom;
            filterForm.to = dateTo;
            filterForm.pointInTime = pointInTime;
            if (!skipRefresh) {
              apiDataX.updateFilter(() => ({ ...filterForm }), true);
            }
            $mdDialog.hide(filterForm);
          },
          color: 'primary',
          disabledFn: form => {
            if (form.formValidity() === true) {
              return false;
            }
            return true;
          }
        }
      ],
      title: gettextCatalog.getString('Filter'),
      fields: [
        {
          id: 'from',
          title: gettextCatalog.getString('From'),
          type: {
            name: 'date',
            options: {
              enableTime: true
            }
          },
          onChange: form => {
            $timeout(form.revalidate);
          },
          initialize: () => {
            const { from } = activeFilterFn();
            let dateFrom = null;
            // Set timezone millis to local millis so that user sees the actual time.
            if (from != null) {
              let dateFromObj = DateTime.fromMillis(from)
                .setZone(timeZoneCode)
                .toObject();
              dateFrom = DateTime.fromObject({ ...dateFromObj })
                .setLocale()
                .toJSDate();
            }
            return dateFrom;
          },
          width: 4,
          validators: validatorsFrom
        },
        {
          id: 'to',
          title: gettextCatalog.getString('To'),
          type: {
            name: 'date',
            options: {
              enableTime: true
            }
          },
          onChange: form => {
            $timeout(form.revalidate);
          },
          initialize: () => {
            const { to } = activeFilterFn();
            let dateTo = null;
            // Set timezone millis to local millis so that user sees the actual time.
            if (to != null) {
              let dateToObj = DateTime.fromMillis(to)
                .setZone(timeZoneCode)
                .toObject();
              dateTo = DateTime.fromObject({ ...dateToObj })
                .setLocale()
                .toJSDate();
            }
            return dateTo;
          },
          width: 4,
          validators: validatorsTo
        },
        {
          id: 'limit',
          title: gettextCatalog.getString('Limit'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0,
              max: 36000
            }
          },
          initialize: () => {
            const { limit } = activeFilterFn();
            return limit ? limit : null;
          },
          width: 4
        },
        {
          id: 'pointInTime',
          title: gettextCatalog.getString('As it was stored on the date'),
          type: {
            name: 'date',
            options: {
              enableTime: true
            }
          },
          onChange: form => {
            $timeout(form.revalidate);
          },
          initialize: () => {
            const { pointInTime } = activeFilterFn();
            let dateFrom = null;
            // Set timezone millis to local millis so that user sees the actual time.
            if (pointInTime != null) {
              let dateFromObj = DateTime.fromMillis(pointInTime)
                .setZone(timeZoneCode)
                .toObject();
              dateFrom = DateTime.fromObject({ ...dateFromObj })
                .setLocale()
                .toJSDate();
            }
            return dateFrom;
          },
          width: 12
        }
      ]
    };
    let actions = [
      {
        title: gettext('Last year'),
        fn: form => {
          let from = DateTime.fromJSDate(new Date())
            .minus({ year: 1 })
            .toJSDate();

          form.setValue('from', from);
          form.setValue('to', null);
        },
        color: 'primary'
      },
      {
        title: gettext('Last half a year'),
        fn: form => {
          let from = DateTime.fromJSDate(new Date())
            .minus({ month: 6 })
            .toJSDate();

          form.setValue('from', from);
          form.setValue('to', null);
        },
        color: 'primary'
      },
      {
        title: gettext('Last 3 months'),
        fn: form => {
          let from = DateTime.fromJSDate(new Date())
            .minus({ month: 3 })
            .toJSDate();

          form.setValue('from', from);
          form.setValue('to', null);
        },
        color: 'primary'
      },
      {
        title: gettext('Last week'),
        fn: form => {
          let from = DateTime.fromJSDate(new Date())
            .minus({ week: 1 })
            .toJSDate();
          form.setValue('from', from);
          form.setValue('to', null);
        },
        color: 'primary'
      },
      {
        title: gettext('Last day'),
        fn: form => {
          let from = DateTime.fromJSDate(new Date())
            .minus({ day: 1 })
            .toJSDate();

          form.setValue('from', from);
          form.setValue('to', null);
        },
        color: 'primary'
      },
      {
        title: gettext('Last hour'),
        fn: form => {
          let from = DateTime.fromJSDate(new Date())
            .minus({ hour: 1 })
            .toJSDate();

          form.setValue('from', from);
          form.setValue('to', null);
        },
        color: 'primary'
      }
    ];
    return $mdDialog
      .show({
        controller: DialogController,
        controllerAs: 'vm',
        template,
        parent: angular.element(document.body),
        locals: {
          configuration,
          actions
        }
      })
      .catch(angular.noop);
  }
  return {
    open
  };
}

DialogController.$inject = ['configuration', 'actions', '$timeout'];
function DialogController(configuration, actions, $timeout) {
  const vm = this;
  vm.api = {};
  vm.config = configuration;
  vm.header = {
    title: configuration.title,
    isDialog: true,
    actions: [
      {
        icon: {
          name: 'close',
          type: 2
        },
        cancel: true
      }
    ]
  };
  $timeout(() => {
    vm.actions = getActions(actions);
  });

  function getActions(actions) {
    if (Array.isArray(actions)) {
      return actions.map(action => {
        let fn;
        let disabledFn;
        if (typeof action.fn == 'function') {
          fn = action.fn.bind(undefined, { ...vm.api });
        }
        if (typeof action.disabledFn == 'function') {
          disabledFn = action.disabledFn.bind(undefined, { ...vm.api });
        }
        return {
          ...action,
          fn,
          disabledFn
        };
      });
    }
    return [];
  }
}
