import './sfe-chips-autocomplete.scss';
import template from './sfe-chips-autocomplete.component.html';

const sfeChipsAutocomplete = {
  template,
  bindings: {
    query: '<',
    selected: '=',
    displayFields: '<',
    placeholder: '<',
    entity: '<',
    searchParam: '<',
    fallbackUrl: '<',
    valueParam: '<',
    parentForm: '<',
    required: '<',
    createMode: '<?',
    networkModel: '<?',
    filterObject: '<',
    filterFn: '<'
  },
  controllerAs: 'vm',
  controller: sfeChipAutocompleteController
};

sfeChipAutocompleteController.$inject = [
  'ItemSelector',
  '$scope',
  'EntitiesService',
  'CrawlerMethods',
  'AlertingService',
  'gettextCatalog',
  'DisplayFields'
];

function sfeChipAutocompleteController(
  ItemSelector,
  $scope,
  EntitiesService,
  CrawlerMethods,
  AlertingService,
  gettextCatalog,
  DisplayFields
) {
  var vm = this;
  vm.querySearch = querySearch;
  vm.setAutocompleteDisplayValue = setAutocompleteDisplayValue;
  vm.transformChip = transformChip;
  vm.onChipAdded = onChipAdded;
  vm.openSelectDialog = openSelectDialog;
  vm.itemName = Math.random()
    .toString(36)
    .substring(2);
  vm.minLength = 0;

  var searchParams = {};
  async function onChipAdded(chip) {
    vm.selectedItem = null;
    vm.searchText = '';
    vm.minLength = 0;
    if (chip._id == '__new__' && vm.createMode) {
      if (vm.networkModel && vm.networkModel.create) {
        let createObject;
        if (vm.networkModel.create.getFormObject) {
          createObject = vm.networkModel.create.getFormObject(chip);
        } else {
          createObject = {
            [vm.displayFields[0]]: chip[vm.displayFields[0]]
          };
        }
        const method = CrawlerMethods.getMethod(vm.networkModel.create);
        try {
          vm.dataIsLoading = true;
          const { data: res } = await method(createObject);
          chip._id = res._id;
        } catch (err) {
          AlertingService.Error(err);
        }
        vm.dataIsLoading = false;
      } else {
        throw gettextCatalog.getString(
          'Configuration to create new tag is missing.'
        );
      }
    }
  }

  function transformChip(chip) {
    if (angular.isObject(chip)) {
      return chip;
    }
    return {
      name: chip,
      type: 'new'
    };
  }
  let fetching;
  async function querySearch() {
    if (fetching) {
      return [];
    } else {
      fetching = true;
    }
    if (!vm.selected) {
      vm.selected = [];
    }
    let method;
    if (vm.networkModel) {
      if (vm.networkModel.list) {
        const filter = {
          ...vm.filterObject
        };
        if (vm.searchText) {
          filter[vm.searchParam] = vm.searchText;
        }
        method = CrawlerMethods.getMethod(vm.networkModel.list);
        try {
          let { data: items } = await method(filter);
          items = prepareItems(items);
          fetching = false;
          return items;
        } catch (err) {
          AlertingService.Error(err);
          return [];
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('There is no list function defined');
        return [];
      }
    } else {
      if (vm.searchText) {
        searchParams[vm.searchParam] = vm.searchText;
      }
      const results = await vm.query(searchParams);
      fetching = false;
      return prepareItems(results);
    }
  }

  function prepareItems(items) {
    let filteredItems = [...items];
    const valueDoesntExist = filteredItems.find(item => {
      if (item) {
        return item[vm.displayFields[0]] == vm.searchText;
      }
    });
    filteredItems = filteredItems.filter(item => {
      if (item) {
        return !vm.selected.find(
          selectedItem => selectedItem[vm.valueParam] == item[vm.valueParam]
        );
      }
    });

    if (vm.createMode && vm.searchText) {
      if (!valueDoesntExist) {
        filteredItems.unshift({
          _id: '__new__',
          [vm.displayFields[0]]: vm.searchText
        });
      }
    }
    if (vm.filterFn) {
      filteredItems = vm.filterFn(filteredItems);
    }
    return filteredItems;
  }

  function openSelectDialog() {
    ItemSelector.open(
      [vm.entity],
      [
        {
          multiple: true
        }
      ]
    ).then(function(items) {
      if (items) {
        vm.selected = _.uniqBy(_.concat(vm.selected, items), vm.valueParam);
      }
    });
  }

  async function setAutocompleteDisplayValue(item) {
    return DisplayFields.getString(item, vm.displayFields);
  }

  vm.$onInit = function() {
    if (vm.valueParam == undefined) {
      vm.valueParam = '_id';
    }
    var path = EntitiesService.getPath(vm.entity);
    vm.displayFields = vm.displayFields ||
      EntitiesService.getDisplayFieldsByPath(path) || ['name'];
    if (vm.parentForm) {
      $scope.ItemForm = vm.parentForm;
    }
  };
}
export default sfeChipsAutocomplete;
