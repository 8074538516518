NewProfitCentreController.$inject = [
  '$state',
  'gettext',
  'createOrUpdateService',
  'AlertingService',
  'MetadataService',
  'SfeHeader',
  'profitCentre',
  '$timeout'
];

function NewProfitCentreController(
  $state,
  gettext,
  createOrUpdateService,
  AlertingService,
  MetadataService,
  SfeHeader,
  profitCentre,
  $timeout
) {
  const vm = this;
  const profitCentreId = $state.params.id;
  const editMode = Boolean(profitCentreId);
  vm.profitCentre = {};

  vm.$onInit = async function init() {
    vm.dataConfig = {
      action: {
        ctrlFn: true,
        title: editMode ? gettext('Update') : gettext('Create'),
        fn: createOrUpdate
      },
      data: getFields()
    };

    if (editMode) {
      MetadataService.Loading(true);
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit Profit Centre'),
        'company-resources-profit-centres-view',
        { id: profitCentreId }
      );
      vm.dataConfig.dataObj = profitCentre;
      $timeout(() => {
        MetadataService.Loading(false);
        MetadataService.ChangeMetadata(
          'Edit ' + profitCentre.name,
          profitCentre.description
        );
      });
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New Profit Centre'),
        'company-resources-profit-centres-list'
      );
      vm.dataConfig.dataObj = {
        name: '',
        code: '',
        externalCode: null
      };
    }
  };

  function getFields() {
    return [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        maxLength: 500
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textArea',
        maxlength: 500,
        type: 'text',
        required: false
      },
      {
        placeholder: gettext('Code'),
        name: 'code',
        componentType: 'textField',
        required: true,
        maxLength: 50
      },
      {
        placeholder: gettext('External Code'),
        name: 'externalCode',
        componentType: 'textField',
        maxLength: 50,
        required: false
      },
      {
        componentType: 'autocompleteDialog',
        name: 'companyStructureNode',
        configuration: {
          query: {
            entity: 'company-structure-nodes',
            method: 'read'
          },
          entity: 'company-structure-nodes',
          floatingLabel: gettext('Select company structure node'),
          searchParamName: 'name',
          required: false,
          createRedirect: {
            state:
              'configurations-company-resources-company-structure-nodes-new'
          }
        }
      }
    ];
  }

  // create and update functions

  function createOrUpdate() {
    vm.sendingRequest = true;
    const dataToSave = {
      name: vm.dataConfig.dataObj.name,
      description: vm.dataConfig.dataObj.description,
      externalCode: vm.dataConfig.dataObj.externalCode
        ? vm.dataConfig.dataObj.externalCode
        : null,
      companyStructureNode: vm.dataConfig.dataObj.companyStructureNode
        ? vm.dataConfig.dataObj.companyStructureNode._id
        : null,
      code: vm.dataConfig.dataObj.code
    };

    createOrUpdateService
      .simpleCall(
        {
          entity: 'profit-centres',
          method: editMode ? 'update' : 'create'
        },
        editMode,
        profitCentreId,
        dataToSave,
        'company-resources-profit-centres-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }
}

export default NewProfitCentreController;
