NewBusinessPartnerFormController.$inject = [
  '$state',
  'gettext',
  'AlertingService',
  'createOrUpdateService',
  'BusinessPartnerModel',
  'MetadataService',
  'SfeHeader',
  'TranslationService',
  'AddressModel',
  'businessPartner',
  '$timeout',
  'AddressHelperService'
];

function NewBusinessPartnerFormController(
  $state,
  gettext,
  AlertingService,
  createOrUpdateService,
  BusinessPartnerModel,
  MetadataService,
  SfeHeader,
  TranslationService,
  AddressModel,
  businessPartner,
  $timeout,
  AddressHelperService
) {
  const vm = this;
  const businessPartnerId = $state.params.id;
  const editMode = Boolean(businessPartnerId);

  vm.$onInit = function init() {
    vm.dataConfig = {
      action: {
        ctrlFn: true,
        title:
          $state.current.name == 'company-resources-business-partners-edit'
            ? gettext('Update')
            : gettext('Create'),
        fn: createOrUpdate
      },
      data: getFields()
    };

    let title, cancelState;
    switch ($state.current.name) {
    case 'company-resources-business-partners-edit':
      title = gettext('Edit Business partner');
      cancelState = 'company-resources-business-partners-view';
      break;
    case 'company-resources-business-partners-duplicate':
      title = gettext('Duplicate Business partner');
      cancelState = 'company-resources-business-partners-view';
      break;
    case 'company-resources-business-partners-new':
      title = gettext('New Business partner');
      cancelState = 'company-resources-business-partners-list';
      break;
    }

    vm.dataConfig.header = SfeHeader.constructFormHeader(
      'primary',
      title,
      cancelState,
      $state.params
    );
    if (editMode) {
      MetadataService.Loading(true);
      const type = TranslationService.GetCollectionById(
        'codelists.businessPartnerTypes',
        businessPartner.type
      );
      vm.dataConfig.dataObj = businessPartner;
      vm.dataConfig.dataObj.type = type.id;
      vm.dataConfig.dataObj.parent = businessPartner.parentId;

      if (vm.dataConfig.dataObj.address) {
        vm.dataConfig.dataObj.address.hasAddress = true;
      } else {
        vm.dataConfig.dataObj.address = {};
        vm.dataConfig.dataObj.address.hasAddress = false;
      }
      if (businessPartner.geoLocation) {
        vm.dataConfig.dataObj.address.geolocationX =
          businessPartner.geoLocation[0];
        vm.dataConfig.dataObj.address.geolocationY =
          businessPartner.geoLocation[1];
      }

      $timeout(() => {
        MetadataService.Loading(false);
        title =
          $state.current.name == 'company-resources-business-partners-edit'
            ? 'Edit '
            : 'Duplicate ';
        MetadataService.ChangeMetadata(
          title + businessPartner.name,
          businessPartner.description
        );
      });
    } else {
      vm.dataConfig.dataObj = {
        name: '',
        description: '',
        taxNumber: null,
        externalId: '',
        isDistributer: false,
        address: {
          hasAddress: true
        }
      };
    }
  };

  function getFields() {
    return [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textArea',
        type: 'text',
        maxlength: 500,
        required: false
      },
      {
        componentType: 'autocompleteDialog',
        edit: editMode,
        configuration: {
          required: true,
          codelist: 'businessPartnerTypes',
          entity: 'bohpomahej',
          noDialog: true,
          floatingLabel: gettext('Type'),
          searchParamName: 'name',
          selectedParam: 'id'
        },
        name: 'type'
      },
      {
        placeholder: gettext('Unique Number'),
        name: 'uniqueNumber',
        componentType: 'textField',
        type: 'number',
        required: false,
        min: 0
      },
      {
        placeholder: gettext('Tax Number'),
        name: 'taxNumber',
        componentType: 'textField',
        type: 'text',
        required: false
      },
      {
        placeholder: gettext('External Code'),
        name: 'externalCode',
        componentType: 'textField',
        type: 'text',
        required: false
      },
      {
        label: gettext('External'),
        componentType: 'checkBox',
        name: 'isExternal'
      },
      {
        label: gettext('Distributer/Dobavitelj'),
        componentType: 'checkBox',
        name: 'isDistributer'
      },
      {
        componentType: 'autocompleteDialog',
        name: 'parent',
        configuration: {
          entity: 'business-partners',
          dialogConfiguration: {
            title: gettext('Select parent'),
            filterObject: {
              order: 'name'
            }
          },
          searchParamName: 'name',
          floatingLabel: gettext('Select parent'),
          query: {
            entity: 'business-partners',
            method: 'read'
          },
          filterObject: {
            order: 'name'
          },
          required: false,
          createRedirect: {
            state: 'company-resources-business-partners-new'
          }
        }
      },
      {
        componentType: 'address',
        name: 'address',
        coordinatesRequired: false,
        edit: editMode
      }
    ];
  }

  // create and update functions

  function createOrUpdate() {
    let message;
    let waterfall;
    if ($state.current.name == 'company-resources-business-partners-edit') {
      message = 'update';
      waterfall = [checkIfAddressExists, updateAddress, updateBusinessPartner];
    } else {
      message = 'create';
      waterfall = [checkIfAddressExists, createAddress, createBusinessPartner];
    }

    createOrUpdateService
      .simpleWaterfall(
        waterfall,
        message,
        'company-resources-business-partners-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          AlertingService.Error(err);
          vm.sendingRequest = false;
        }
      );
  }

  async function checkIfAddressExists(callback) {
    let addressObj;
    if (vm.dataConfig.dataObj.address.hasAddress) {
      addressObj = getAddressObject();
    }
    try {
      const address = await AddressHelperService.checkIfAddressExists(
        addressObj
      );
      if (address != null) {
        callback(null, address._id);
      } else {
        callback(null, null);
      }
    } catch (err) {
      callback(true);
    }
  }

  function updateAddress(addressId, callback) {
    if (addressId && vm.dataConfig.dataObj.address.hasAddress) {
      callback(null, addressId);
    } else if (vm.dataConfig.dataObj.address.hasAddress) {
      AddressModel.create(getAddressObject()).then(
        function(res) {
          callback(null, res.data._id);
        },
        function(err) {
          callback(err);
        }
      );
    } else {
      callback(null, null);
    }
  }

  function updateBusinessPartner(address, callback) {
    BusinessPartnerModel.update(
      {
        id: businessPartnerId
      },
      getBusinessPartnerObject(address)
    ).then(
      function(res) {
        callback(null, res.data);
      },
      function(err) {
        callback(err);
      }
    );
  }

  function createAddress(addressId, callback) {
    if (vm.dataConfig.dataObj.address.hasAddress) {
      if (addressId) {
        callback(null, addressId);
      } else {
        AddressModel.create(getAddressObject()).then(
          function(res) {
            callback(null, res.data._id);
          },
          function(err) {
            callback(err);
          }
        );
      }
    } else {
      callback(null, null);
    }
  }

  function createBusinessPartner(address, callback) {
    BusinessPartnerModel.create(getBusinessPartnerObject(address)).then(
      function(res) {
        callback(null, res.data);
      },
      function(err) {
        callback(err);
      }
    );
  }

  function getAddressObject() {
    return {
      country: vm.dataConfig.dataObj.address.country
        ? vm.dataConfig.dataObj.address.country.id
        : null,
      externalId: vm.dataConfig.dataObj.address.externalId,
      communityCode: vm.dataConfig.dataObj.address.communityCode,
      communityName: vm.dataConfig.dataObj.address.communityName,
      settlemenentCode: vm.dataConfig.dataObj.address.settlemenentCode,
      settlementName: vm.dataConfig.dataObj.address.settlementName,
      streetCode: vm.dataConfig.dataObj.address.streetCode,
      streetName: vm.dataConfig.dataObj.address.streetName,
      houseNumber: vm.dataConfig.dataObj.address.houseNumber,
      postalNumber: vm.dataConfig.dataObj.address.postalNumber,
      postName: vm.dataConfig.dataObj.address.postName
    };
  }

  function getBusinessPartnerObject(addressId) {
    return {
      name: vm.dataConfig.dataObj.name,
      description: vm.dataConfig.dataObj.description,
      uniqueNumber: vm.dataConfig.dataObj.uniqueNumber,
      isDistributer: vm.dataConfig.dataObj.isDistributer,
      taxNumber: vm.dataConfig.dataObj.taxNumber
        ? vm.dataConfig.dataObj.taxNumber
        : null,
      externalCode: vm.dataConfig.dataObj.externalCode
        ? vm.dataConfig.dataObj.externalCode
        : null,
      type: vm.dataConfig.dataObj.type.id,
      geoLocation: [
        vm.dataConfig.dataObj.address.geolocationX,
        vm.dataConfig.dataObj.address.geolocationY
      ],
      address: addressId,
      isExternal: vm.dataConfig.dataObj.isExternal,
      parentId: vm.dataConfig.dataObj.parent
        ? vm.dataConfig.dataObj.parent._id
        : null
    };
  }
}
export default NewBusinessPartnerFormController;
