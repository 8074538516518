import { DateTime } from 'luxon';

DateLocalizationService.$inject = ['LocalizationService'];

export default function DateLocalizationService(LocalizationService) {
  const intervalsAbbrs = ['s', 'h', 'd', 'w', 'm', 'y', '6m'];
  const intervalCachedFns = intervalsAbbrs.reduce(
    (intervals, abbr) => ({
      ...intervals,
      [abbr]: LocalizationDateIntervalFn(abbr)
    }),
    {}
  );

  /**
   * Localization function for Date labels and table dates
   * @param {Date} date
   * @param {boolean} dateIsUTC
   * @param {string} timeZone
   * @param {boolean} useLineBreaks
   */
  function LocalizationDateFn(date, dateIsUTC, timeZone, useLineBreaks) {
    return LocalizationDateIntervalFn('s')(
      date,
      dateIsUTC,
      timeZone,
      useLineBreaks
    );
  }

  /**
   * Localization function for Date labels and table dates
   * @param {Date} date
   * @param {boolean} dateIsUTC
   * @param {string} timeZone
   * @param {boolean} useLineBreaks
   * @param {Array<string>} periods
   */
  function LocalizationMultiDateFn(date, dateIsUTC, timeZone, periods) {
    return periods.reduce((combined, period) => {
      const periodFormattedDate = LocalizationDateIntervalFn(period)(
        date,
        dateIsUTC,
        timeZone,
        false
      );
      return `${combined}<br/>${periodFormattedDate}`;
    }, '');
  }

  /**
   * Localization function for Date labels and table dates - forces UTC
   * @param {Date} date
   * @param {boolean} dateIsUTC
   * @param {string} timeZone
   * @param {boolean} useLineBreaks
   */
  function LocalizationDateToUTCFn(date, dateIsUTC, timeZone, useLineBreaks) {
    return LocalizationDateIntervalFn('s')(date, true, timeZone, useLineBreaks);
  }

  /**
   * Localization based on aggregation interval (weekly, daily, etc.)
   * @param {string} period - h, d, w, m ,y
   */
  function LocalizationDateIntervalFn(period) {
    return function(date, dateIsUTC, timeZone, useLineBreaks) {
      if (!date || angular.equals(date, {})) {
        return '';
      }

      let dateObj;
      let format;
      if (date instanceof Date) {
        dateObj = DateTime.fromJSDate(date);
      } else {
        dateObj = DateTime.fromMillis(date);
      }

      if (dateIsUTC) {
        dateObj = dateObj.setZone('utc');
      } else if (timeZone != null) {
        dateObj = dateObj.setZone(timeZone);
      }

      switch (period) {
      case 's':
      case 'h':
        format = 'dd. LL. yyyy, HH:mm:ss';
        break;
      case 'd':
        format = 'dd. LL. yyyy';
        break;
      case 'w':
        format = 'WW. yyyy';
        break;
      case 'm':
        format = 'LLL yyyy';
        break;
      case 'y':
        format = 'yyyy';
        break;
      case 'timeonly':
        format = 'HH:mm:ss';
        break;
      default:
        format = 'dd. LL. yyyy, HH:mm:ss';
        break;
      }
      let formattedResult = dateObj.toFormat(format);
      if (period == '6m') {
        // special formatting for 6 months period
        formattedResult = `${Math.ceil(dateObj.month / 6)}/${dateObj.toFormat(
          'yyyy'
        )}`;
      } else if (useLineBreaks) {
        if (['s', 'h'].indexOf(period) >= 0) {
          let formattedResultSplit = formattedResult.split(',');
          formattedResult = `
            ${formattedResultSplit[0]}
            <br/>
            <span style="font-size: .9em">${formattedResultSplit[1]}</span>
          `;
        } else if (period == 'd') {
          const split = formattedResult.split('.');
          formattedResult = `${split[0]}. ${split[1]}.<br/>${split[2]}`;
        }
      }
      return formattedResult;
    };
  }

  /**
   * @description Converts timestamp into a time category identifier.
   * @function
   * @param {String} timeCategory - ex. 'm' for month
   * @return {Function} function accepts timestamp as input and returns time category identifier.
   */
  function LocalizationTimeCategoryFn(timeCategory) {
    return date => {
      let timeCategoryIdentifier;
      switch (timeCategory) {
      case 'm':
        timeCategoryIdentifier = new Date(date).toLocaleString(
          LocalizationService.getLocale(),
          {
            month: 'short',
            year: 'numeric'
          }
        );
        break;
      default:
        timeCategoryIdentifier = date;
      }
      return timeCategoryIdentifier;
    };
  }

  var api = {
    LocalizationTimeCategoryFn,
    LocalizationDateFn,
    LocalizationDateToUTCFn,
    LocalizationDateIntervalFn,
    LocalizationMultiDateFn,
    intervalCachedFns
  };

  return api;
}
