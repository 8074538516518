sfeValidation.$inject = ['$timeout'];
export default function sfeValidation($timeout) {
  return {
    restrict: 'A',
    require: ['ngModel', '^form'],
    link: function(scope, element, attrs, controllers) {
      const model = controllers[0];
      function sfeValidate(sfeValidations) {
        return value => {
          $timeout(() => {
            let globalValid = true;
            let errorName;
            let valid = true;
            let validationObject;
            for (let i = 0; i < sfeValidations.length && globalValid; i++) {
              validationObject = sfeValidations[i];
              if (validationObject.script) {
                valid = validationObject.script(value);
              }
              if (!valid) {
                globalValid = false;
                errorName = validationObject.name;
              }
            }

            if (globalValid) {
              sfeValidations.forEach(function(validationObject) {
                model.$setValidity(validationObject.name, true);
              });
            } else {
              model.$setValidity(errorName, globalValid);
            }
          });
          return value;
        };
      }
      return scope.$watch(attrs.sfeValidation, function(sfeValidations) {
        if (sfeValidations) {
          const validate = sfeValidate(sfeValidations);
          model.$parsers.unshift(validate);
          model.$formatters.unshift(validate);
        }
      });
    }
  };
}
