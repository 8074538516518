import './sfe-dynamic-attributes.scss';
import template from './sfe-dynamic-attributes.directive.html';
/**
 * @ngdoc directive
 * @name common.sfeDynamicAttributes
 * @description Shows current views entities dynamic attributes
 * @param {string} entity - name of the entity for which we are tying to get dynamic attributes
 * @param {string} entityId - id of the entity for which we are trying to get dynamic attributes
 * @param {object} entityType - type used by the entity
 * @example
 * <sfe-dynamic-attributes
 *  entity="assets"
 *  entity-id="::vm.asset._id"
 *  entity-type="::vm.asset.type"
 * ></sfe-dynamic-attributes>
 */
export default [
  function() {
    var directive = {
      restrict: 'E',
      template,
      scope: {
        entity: '@',
        entityId: '<',
        entityType: '<'
      },
      link: linkFn,
      controller: sfeDynamicAttributesController,
      controllerAs: 'vm',
      bindToController: true
    };
    return directive;

    function linkFn(scope, el, attrs, ctrl) {
      ctrl.indentValues = indentValues;
      /**
       * @description functions that adds a 48px indetation to specified html elements
       * @function
       */
      function indentValues() {
        var titles = el[0].querySelectorAll('.title');
        var maxWidth = 0;
        titles.forEach(function(title) {
          maxWidth = Math.max(maxWidth, title.offsetWidth);
        });

        titles.forEach(function(title) {
          title.setAttribute('style', 'width:' + (maxWidth + 48) + 'px');
        });
        /*************
        MEMORY CLEANUP
        *************/
        titles = null;
      }
    }
  }
];

sfeDynamicAttributesController.$inject = [
  'SfeDynamicAttributesConfigurations',
  'gettext',
  'AlertingService',
  'InfoDialog',
  'CrawlerMethods',
  'DomainModel',
  '$timeout',
  '$q',
  '$mdDialog',
  'Formatting',
  'gettextCatalog'
];

function sfeDynamicAttributesController(
  SfeDynamicAttributesConfigurations,
  gettext,
  AlertingService,
  InfoDialog,
  CrawlerMethods,
  DomainModel,
  $timeout,
  $q,
  $mdDialog,
  Formatting,
  gettextCatalog
) {
  var vm = this;
  var configuration;
  vm.loading = true;
  vm.formData = {};
  vm.domainAttributes = {};
  vm.formConfig = [];
  vm.openEditForm = openEditForm;
  vm.openNewForm = openNewForm;
  vm.createOrUpdate = createOrUpdate;
  vm.closeForm = closeForm;
  vm.toggleEditForm = toggleEditForm;
  vm.showHistory = showHistory;
  vm.editHistoryItem = editHistoryItem;
  vm.removeItem = removeItem;
  vm.saveHistoryItem = saveHistoryItem;
  vm.cancelEditingHistory = cancelEditingHistory;
  vm.historyValidToChanged = historyValidToChanged;
  vm.isUndefined = isUndefined;

  vm.$onChanges = function(changes) {
    if (changes.entityType && vm.entityType) {
      configuration =
        SfeDynamicAttributesConfigurations.configurations[vm.entity];
      vm.entityViewValueParam = configuration.entityViewValueParam;
      init();
    }
  };
  /**
   * @description initalization function
   * @function
   */
  function init() {
    readDomainAttributes();
  }
  /**
   * @description checks if passed values is undefined
   * @function
   * @param {any} binding/paramName
   * @return {boolean} returns true if passed value is undefined
   */
  function isUndefined(value) {
    return typeof value === 'undefined';
  }
  /**
   * @description gets all dynamic attributes belonging to the current view and formats the data.
   * @function
   * @param {function} callback - callback function
   */
  function readEntityDynamicAttributeViews(callback) {
    var obj = {};
    obj[configuration.entityParam] = vm.entityId;
    var method = CrawlerMethods.getMethod({
      entity: configuration.viewEntityRoute,
      method: 'read'
    });
    method(obj).then(
      function(res) {
        vm.loading = false;

        if (!res.data.length) {
          vm.noAttributes = true;
        }
        vm.attributes = res.data;

        vm.attributes.forEach(function(attribute) {
          attribute.dateFromLocalStorage = true;
          if (attribute[configuration.entityViewValueParam]) {
            attribute.entityAttributeId =
              attribute[configuration.entityViewValueParam]._id;
          }
          //SELECT
          if (attribute.elementType === 'select') {
            attribute.readingPending = true;
            if (
              attribute[vm.entityViewValueParam] &&
              attribute[vm.entityViewValueParam].value
            ) {
              attribute.readableValueIsLoading = true;
            }
          } else if (
            attribute.elementType === 'double' &&
            attribute[vm.entityViewValueParam]
          ) {
            attribute[
              vm.entityViewValueParam
            ].readableValue = Formatting.formatNumber(
              String(attribute[vm.entityViewValueParam].value)
            );
          } else if (attribute[vm.entityViewValueParam]) {
            attribute[vm.entityViewValueParam].readableValue =
              attribute[vm.entityViewValueParam].value;
          }
        });

        $timeout(function() {
          vm.indentValues();
        });

        callback(null, res.data);
      },
      function(err) {
        AlertingService.Error(err);
        callback(null, null);
      }
    );
  }
  /**
   * @description reads the domain attributes belonging to the entity.
   * @function
   */
  function readDomainAttributes() {
    var waterfall = [readEntityDynamicAttributeViews, fetchDomains];
    async.waterfall(waterfall, function() {
      if (vm.attributes) {
        vm.attributes.forEach(function(attribute, index) {
          ConstructAttributeConfig(attribute, index);
        });
      }
    });
  }
  /**
   * @description GETs domain attributes based on passed id
   * @function
   * @param {array} attributes - arrays of containg domain attribute objects
   * @param {function} callback - callback function
   * @return {dataType}
   */
  function fetchDomains(attributes, callback) {
    async.eachOf(
      attributes,
      function(attr, index, innerCallback) {
        if (attr.elementType === 'select') {
          DomainModel.read({
            id: attr.domain
          }).then(
            function(res) {
              attr.domain = res.data;
              innerCallback();
            },
            function() {
              innerCallback();
            }
          );
        } else {
          innerCallback();
        }
      },
      function() {
        callback(null, attributes);
      }
    );
  }
  /**
   * @description refreshes data based from the data passed to it.
   * @function
   * @param {object} item - item for which from which we want to get refreshed data.
   * @return {object} editRefreshObject
   */
  function getSelectRefreshObject(item) {
    var editRefreshObject = {};
    editRefreshObject[item.config.valueField] = item[vm.entityViewValueParam]
      ? item[vm.entityViewValueParam].value
      : undefined;
    return editRefreshObject;
  }
  /**
   * @description constructs a value in item that is human readable
   * @function
   * @param {object} item - the iteam in which we want to create the human readable value
   * @param {object} value - the value we want to make human readable
   */
  function constructReadableValue(item, value) {
    var selectConfig = item.config;
    item[vm.entityViewValueParam].readableValue = '';
    selectConfig.displayOptions.forEach(function(field, index) {
      item[vm.entityViewValueParam].readableValue += value[field];
      if (index < selectConfig.displayOptions.length - 1) {
        item[vm.entityViewValueParam].readableValue += ' - ';
      }
    });
  }
  /**
   * @description gets selected value for dynamic attributes
   * @function
   * @param {object} item - object to which the selected value will be assigned
   * @return {promise}
   */
  function getSelectValue(item) {
    var deferred = $q.defer();
    var selectConfig = item.config;
    var apiFunction = selectConfig.refreshFn;
    // set refresh object to fetch data on form
    var editRefreshObject = getSelectRefreshObject(item);
    var filterObject = angular.copy(selectConfig.filterObject) || {};
    item.config.editRefreshObject = editRefreshObject;
    item.editRefreshObject = editRefreshObject;
    filterObject = Object.assign(filterObject, editRefreshObject);
    item.readableValueIsLoading = true;

    var method;

    if (typeof apiFunction === 'object' && !apiFunction.query) {
      method = CrawlerMethods.getMethod(apiFunction)(filterObject);
    } else {
      method = apiFunction.query(filterObject).$promise;
    }
    method.then(
      function(res) {
        item.readableValueIsLoading = false;
        item.readableValueError = false;
        if (res.data.length === 1 && item[vm.entityViewValueParam]) {
          constructReadableValue(item, res.data[0]);
          deferred.resolve(item[vm.entityViewValueParam].readableValue);
        } else if (res.data.length > 0) {
          var value = _.find(res.data, function(attribute) {
            return (
              item[vm.entityViewValueParam] &&
              attribute[item.config.valueField] ===
                item[vm.entityViewValueParam].value
            );
          });

          if (value) {
            constructReadableValue(item, value);
          }

          deferred.resolve(
            item[vm.entityViewValueParam]
              ? item[vm.entityViewValueParam].readableValue
              : undefined
          );
        } else {
          deferred.resolve();
        }
      },
      function(err) {
        item.readableValueIsLoading = false;
        item.readableValueError = true;
        deferred.reject(err);
      }
    );
    return deferred.promise;
  }
  /**
   * @description contructs configuration for a dynamic attribute
   * @function
   * @param {object} element - object containg information about the dynamic attribute we are constructing configuration for
   */
  function ConstructAttributeConfig(element) {
    var el = {
      name: 'value'
    };
    switch (element.elementType) {
    case 'checkbox':
      el.componentType = 'checkBox';
      el.label = element.name;
      break;
    case 'select':
      var displayNames = [];

      _.each(element.displayFields, function(filedId) {
        var temp = _.find(element.domain.fields, {
          _id: filedId
        });
        if (temp && temp.rawFieldName) {
          displayNames.push(temp.rawFieldName);
        }
      });

      var valueField = _.find(element.domain.fields, {
        _id: element.valueField
      });
      if (valueField) {
        valueField = valueField.rawFieldName;
      }

      el.select = true;
      el.componentType = 'multiSelect';
      element.config = {
        placeholder: element.domain.name,
        refreshFn: {
          entity: 'domain-collections',
          method: 'custom.list'
        },
        filterObject: {
          domainId: element.domain._id
        },
        displayOptions: displayNames,
        valueField: valueField,
        edit: true,
        refreshObjectRequired: true
      };
      // init dataObj object
      getSelectValue(element).then(
        function() {},
        function(err) {
          AlertingService.Error(err);
        }
      );
      break;
    case 'string':
      el.componentType = 'textField';
      el.placeholder = element.name;
      el.type = 'text';
      el.required = element.isRequired;
      break;
    case 'integer':
      el.componentType = 'textField';
      el.placeholder = element.name;
      el.type = 'number';
      el.required = element.isRequired;
      el.pattern = new RegExp('^[0-9]+$');
      el.patternExample = gettextCatalog.getString(
        'Value must be an integer'
      );
      break;
    case 'double':
      el.componentType = 'textField';
      el.placeholder = element.name;
      el.type = 'numerical';
      el.required = element.isRequired;
      // el.pattern = new RegExp('^[0-9]+(\\,[0-9]+)?$');
      // el.patternExample = '21,2';
      break;
    case 'textarea':
      el.placeholder = element.name;
      el.componentType = 'textArea';
      el.type = 'text';
      el.maxlength = 500;
      el.required = element.isRequired;
      break;
    default:
      break;
    }
    // temp
    el.required = true;
    element.formConfig = el;
  }
  /**
   * @description creates configuration for the date input fields when creating or editing a new dynamic attribute value
   * @function
   */
  function getTimeConfig() {
    return [
      {
        componentType: 'datePickerFromTo',
        from: 'validFrom',
        to: 'validTo',
        valToEmpty: true,
        labelFrom: gettext('Valid from *'),
        labelTo: gettext('Valid to'),
        required: true,
        onlyStartRequired: true,
        idFrom: 'from',
        idTo: 'to',
        onChangeTo: function(data) {
          if (!data.timeTo && data.validTo) {
            data.timeTo = '23:59';
          }
        },
        onChangeFrom: function(data) {
          // in case of existing attribute doesn't have validTo date set it to validFrom date of new one
          if (!data.validToEDIT && data.validFrom) {
            var date = Formatting.formatDate(data.validFrom, data.timeFrom);
            date.setDate(date.getDate() - 1);
            data.validToEDIT = date;
            data.timeToEDIT = '23:59';
          }
        }
      },
      {
        componentType: 'twoInRow',
        subData: [
          {
            placeholder: gettext('Time from'),
            name: 'timeFrom',
            pattern: new RegExp('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$'),
            patternExample: '8:00'
          },
          {
            placeholder: gettext('Time to'),
            name: 'timeTo',
            pattern: new RegExp('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$'),
            patternExample: '16:00',
            required: false
          }
        ]
      }
    ];
  }
  /**
   * @description gets historical data for a specific domain attribute.
   * @function
   * @param {object} item - item to which we'll be assigning the history data
   * @param {function} callback - callback function
   * @return {dataType}
   */
  function fetchHistory(item, callback) {
    var obj = {
      domainAttribute: item.domainAttribute
    };
    obj[configuration.entityParam] = vm.entityId;
    var method = CrawlerMethods.getMethod({
      entity: configuration.entityAtrributes,
      method: 'read'
    });
    method(obj).then(
      function(res) {
        item.history = res.data;
        callback(null, item.history, null);
      },
      function(err) {
        AlertingService.Error(err);
        callback(null, null, null);
      }
    );
  }
  /**
   * @description functions that toggles domain attribute historical data and formats said data after it is acuired.
   * @function
   * @param {object} item - object for wwhich we want to show history.
   * @param {boolean} value - boolean that tells us if there is a value to be shown
   * @return {dataType}
   */
  function showHistory(item, value) {
    if (item.showHistory && !value) {
      item.showHistory = false;
      return;
    }
    item.showHistory = true;

    var waterfall = [async.apply(fetchHistory, item)];

    item.historyIsLoading = true;
    async.waterfall(waterfall, function() {
      item.historyIsLoading = false;
      item.history.forEach(function(attribute, index) {
        attribute.entityAttributeId = attribute._id;
        attribute.config = item.config;
        attribute[vm.entityViewValueParam] = {
          _id: attribute._id,
          validFrom: attribute.validFrom,
          validTo: attribute.validTo,
          value: attribute.value
        };
        attribute.valueField = item.valueField;
        attribute.displayFields = item.displayFields;
        attribute.name = item.name;
        attribute.description = item.description;
        attribute.domain = item.domain;
        attribute.formConfig = item.formConfig;
        attribute.elementType = item.elementType;
        attribute[configuration.entityParam] = item[configuration.entityParam];
        if (attribute.elementType === 'select') {
          attribute.config.editRefreshObject = getSelectRefreshObject(
            attribute
          );
        } else if (
          attribute.elementType === 'double' &&
          attribute[vm.entityViewValueParam]
        ) {
          attribute[
            vm.entityViewValueParam
          ].readableValue = Formatting.formatNumber(
            String(attribute[vm.entityViewValueParam].value)
          );
        } else if (attribute[vm.entityViewValueParam]) {
          attribute[vm.entityViewValueParam].readableValue =
            attribute[vm.entityViewValueParam].value;
        }
        if (
          attribute[vm.entityViewValueParam] &&
          item[vm.entityViewValueParam] &&
          attribute[vm.entityViewValueParam]._id ===
            item[vm.entityViewValueParam]._id
        ) {
          attribute.isActive = true;
        }
        ConstructAttributeConfig(attribute, index);
      });
    });
  }
  /**
   * @description opens (or closes) a form editing and prepares data for editing for the current dynamic attribute value
   * @function
   * @param {object} item - object containg data that will be shown in the edit input.
   */
  function openEditForm(item) {
    if (!item.formConfig) {
      return;
    }
    item.edit = true;
    item.editingExisting = true;
    var timeConfiguration = getTimeConfig();
    if (item.formConfig.select) {
      item.formConfig.config = item.config;
    }
    var data = [item.formConfig];
    data = data.concat(timeConfiguration);
    let value = item[vm.entityViewValueParam].value;
    switch (item.elementType) {
    case 'checkbox':
      if (item[vm.entityViewValueParam].value === true) {
        value = true;
      } else {
        value = false;
      }
      break;
    case 'select':
      value = {
        _id: item[vm.entityViewValueParam].value
      };
    }

    var dataObj = {
      validFrom: new Date(item[vm.entityViewValueParam].validFrom),
      validTo: item[vm.entityViewValueParam].validTo
        ? new Date(item[vm.entityViewValueParam].validTo)
        : null,
      timeFrom: Formatting.getHoursAndMinutes(
        item[vm.entityViewValueParam].validFrom
      ),
      timeTo: Formatting.getHoursAndMinutes(
        item[vm.entityViewValueParam].validTo
      ),
      value
    };

    item.configuration = {
      data: data,
      dataObj: dataObj
    };
  }
  /**
   * @description opens a form (or closes) for inputting a dynamic attribute value and formats the shown data properly.
   * @function
   * @param {object} item - object to which we added the new value
   */
  function openNewForm(item) {
    if (!item.formConfig) {
      return;
    }
    item.edit = true;
    if (item.formConfig.select) {
      item.formConfig.config = item.config;
    }
    var data = [];
    var timeConfiguration = getTimeConfig();
    data.push(item.formConfig);
    data = data.concat(timeConfiguration);

    var dataObj = {
      validFrom: new Date(),
      timeFrom: '00:00',
      validTo: null
    };

    if (item[vm.entityViewValueParam] && item[vm.entityViewValueParam].value) {
      item.switchingValue = true;
      var editData = angular.copy(data);
      editData.forEach(function(config) {
        config.hide = true;
        config.showParam = 'showCurrent';
        if (config.componentType === 'datePickerFromTo') {
          config.idFrom = config.idFrom + 'EDIT';
          config.idTo = config.idTo + 'EDIT';
          config.from = config.from + 'EDIT';
          config.to = config.to + 'EDIT';
          config.onChangeTo = function(data) {
            if (!data.timeToEDIT) {
              data.timeToEDIT = '23:59';
            }
          };
        } else if (config.componentType === 'twoInRow') {
          config.subData[0].name = config.subData[0].name + 'EDIT';
          config.subData[1].name = config.subData[1].name + 'EDIT';
        } else {
          config.name = config.name + 'EDIT';
          config.edit = true;
        }
      });
      editData = [
        {
          hide: true,
          showParam: 'showCurrent',
          title: gettext('Current item'),
          componentType: 'title'
        }
      ].concat(editData);
      data = [
        {
          title: gettext('New item'),
          componentType: 'title'
        }
      ].concat(data);

      data = editData.concat(data);
      dataObj.showCurrent = true;
      item.showCurrent = true;
      dataObj.validFromEDIT = new Date(item[vm.entityViewValueParam].validFrom);
      dataObj.validToEDIT = item[vm.entityViewValueParam].validTo
        ? new Date(item[vm.entityViewValueParam].validTo)
        : null;
      dataObj.timeFromEDIT = Formatting.getHoursAndMinutes(
        item[vm.entityViewValueParam].validFrom
      );
      dataObj.timeToEDIT = Formatting.getHoursAndMinutes(
        item[vm.entityViewValueParam].validTo
      );

      dataObj.valueEDIT = item.formConfig.select
        ? {
          _id: item[vm.entityViewValueParam].value
        }
        : item[vm.entityViewValueParam].value;
    }

    item.configuration = {
      data: data,
      dataObj: dataObj
    };
  }
  /**
   * @description creates an api compliant object and returns it.
   * @function
   * @param {object} item - object containing values that will used in making the api complaint object
   * @return {object} obj The api compliant object
   */
  function getApiObject(item) {
    var dataObj = item.configuration.dataObj;
    var validTo = Formatting.formatDate(dataObj.validTo, dataObj.timeTo);
    var obj = {
      value: item.formConfig.select ? dataObj.value._id : dataObj.value,
      validFrom: Formatting.formatDate(dataObj.validFrom, dataObj.timeFrom),
      validTo: validTo || null,
      domainAttribute: item.domainAttribute
    };
    obj[configuration.entityParam] = vm.entityId;
    return obj;
  }
  /**
   * @description creates a history api compliant object and returns it.
   * @function
   * @param {object} item - object containing values that will used in making the api complaint object
   * @return {object} obj The api compliant object
   */
  function getHistoryApiObject(item) {
    var validTo = Formatting.formatDate(item.editValidTo, item.editTimeTo);
    var obj = {
      value: item.formConfig.select ? item.editValue._id : item.editValue,
      validFrom: Formatting.formatDate(item.editValidFrom, item.editTimeFrom),
      validTo: validTo ? validTo : null,
      domainAttribute: item.domainAttribute
    };
    obj[configuration.entityParam] = vm.entityId;
    return obj;
  }
  /**
   * @description creates a api compliant object and returns it.
   * @function
   * @param {object} item - object containing values that will used in making the api complaint object
   * @return {object} obj The api compliant object
   */
  function getEDITApiObject(item) {
    var dataObj = item.configuration.dataObj;
    var obj = {
      value: item.formConfig.select ? dataObj.valueEDIT._id : dataObj.valueEDIT,
      validFrom: Formatting.formatDate(
        dataObj.validFromEDIT,
        dataObj.timeFromEDIT
      ),
      validTo: Formatting.formatDate(dataObj.validToEDIT, dataObj.timeToEDIT),
      domainAttribute: item.domainAttribute
    };
    obj[configuration.entityParam] = vm.entityId;
    return obj;
  }
  /**
   * @description gets and formats the currently active dynamic attribute value
   * @function
   * @param {object} item - object to which we will added the formated dynamic attribute value
   */
  function getItemActiveAttribute(item) {
    var obj = {
      isActive: true,
      domainAttribute: item.domainAttribute
    };
    obj[configuration.entityParam] = vm.entityId;
    var method = CrawlerMethods.getMethod({
      entity: configuration.entityAtrributes,
      method: 'read'
    });
    method(obj).then(
      function(res) {
        item.readableValueIsLoading = false;
        if (res.data.length === 1) {
          if (!item[vm.entityViewValueParam]) {
            item[vm.entityViewValueParam] = {};
          }
          item[vm.entityViewValueParam].value = res.data[0].value;
          item[vm.entityViewValueParam].validFrom = res.data[0].validFrom;
          item[vm.entityViewValueParam].validTo = res.data[0].validTo;
          item[vm.entityViewValueParam]._id = res.data[0]._id;
          item.entityAttributeId = res.data[0]._id;

          if (item.elementType === 'select') {
            getSelectValue(item).then(
              function() {},
              function(err) {
                AlertingService.Error(err);
              }
            );
          } else if (
            item.elementType === 'double' &&
            item[vm.entityViewValueParam]
          ) {
            item[
              vm.entityViewValueParam
            ].readableValue = Formatting.formatNumber(
              String(item[vm.entityViewValueParam].value)
            );
          } else if (item[vm.entityViewValueParam]) {
            item[vm.entityViewValueParam].readableValue =
              item[vm.entityViewValueParam].value;
          }
        } else {
          resetItem(item);
        }
        item.edit = false;
        item.switchingValue = false;
        item.editingExisting = false;
        closeForm(item);
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }
  /**
   * @description function that determines if we are editing or creating new dynamic attribute values
   * and calls appropirate functions for creating or updating data
   * @function
   * @param {object} item - object containg the values that will be added/edited
   */
  function createOrUpdate(item) {
    var apiObj = getApiObject(item);
    item.readableValueIsLoading = true;
    if (!item[vm.entityViewValueParam]) {
      create(apiObj, item).then(function() {
        getItemActiveAttribute(item);
        if (item.showHistory) {
          showHistory(item, true);
        }
      });
    } else {
      if (item.editingExisting) {
        update(apiObj, item).then(function(created) {
          if (created) {
            getItemActiveAttribute(item);
            if (item.showHistory) {
              showHistory(item, true);
            }
          } else {
            item.readableValueIsLoading = false;
          }
        });
      } else {
        if (item.showCurrent) {
          var editAPIObject = getEDITApiObject(item);
          update(editAPIObject, item).then(function() {
            create(apiObj, item).then(function() {
              getItemActiveAttribute(item);
              if (item.showHistory) {
                showHistory(item, true);
              } else {
                item.readableValueIsLoading = false;
              }
            });
          });
        } else {
          create(apiObj, item).then(function() {
            getItemActiveAttribute(item);
            if (item.showHistory) {
              showHistory(item, true);
            } else {
              item.readableValueIsLoading = false;
            }
          });
        }
      }
    }
  }
  /**
   * @description function that creates a new dynamic attributes value.
   * @function
   * @param {object} apiObj - the data being passed to the server.
   * @return {promise}
   */
  function create(apiObj) {
    var deferred = $q.defer();
    var method = CrawlerMethods.getMethod({
      entity: configuration.entityAtrributes,
      method: 'create'
    });
    method(apiObj).then(
      function(res) {
        deferred.resolve(res.data);
      },
      function(err) {
        AlertingService.Error(err);
        deferred.resolve();
      }
    );
    return deferred.promise;
  }
  /**
   * @description function that updates an existing dynamic attributes value.
   * @function
   * @param {object} apiObj - the data being passed to the server.
   * @param {object} item - object containg information required to know what is being updated.
   * @return {promise}
   */
  function update(apiObj, item) {
    var deferred = $q.defer();
    var method = CrawlerMethods.getMethod({
      entity: configuration.entityAtrributes,
      method: 'update'
    });
    method(
      {
        id: item.entityAttributeId
      },
      apiObj
    ).then(
      function(res) {
        deferred.resolve(res.data);
      },
      function(err) {
        AlertingService.Error(err);
        deferred.resolve();
      }
    );
    return deferred.promise;
  }
  /**
   * @description toggles the edit from for active dynamic attribute.
   * @function
   * @param {object} item - object containg information if the form should be visible or not.
   */
  function toggleEditForm(item) {
    item.showCurrent = !item.showCurrent;
    item.configuration.dataObj.showCurrent = item.showCurrent;
  }
  /**
   * @description closes opened form
   * @function
   * @param {object} item - object containg information on the form we are closing
   */
  function closeForm(item) {
    item.edit = false;
    item.configuration = null;
  }
  /**
   * @description opens form for editing a historical dynamic attribute entry
   * @function
   * @param {object} item - object containing information about the entry we are editing
   */
  function editHistoryItem(item) {
    item.edit = true;
    if (item.formConfig.select) {
      item.formConfig.config = item.config;
      item.editValue = {
        _id: item.value
      };
    } else {
      item.editValue = angular.copy(item[vm.entityViewValueParam].value);
    }
    item.editValidFrom = new Date(item[vm.entityViewValueParam].validFrom);
    item.editValidTo =
      item[vm.entityViewValueParam] && item[vm.entityViewValueParam].validTo
        ? new Date(item[vm.entityViewValueParam].validTo)
        : undefined;
    item.editTimeFrom = Formatting.getHoursAndMinutes(
      item[vm.entityViewValueParam].validFrom
    );
    item.editTimeTo = Formatting.getHoursAndMinutes(
      item[vm.entityViewValueParam].validTo
    );
  }
  /**
   * @description resets values dynamic attribute values in passed object
   * @function
   * @param {object} item - the object in which we are reseting data.
   */
  function resetItem(item) {
    if (item[vm.entityViewValueParam]) {
      item[vm.entityViewValueParam].readableValue = undefined;
      item[vm.entityViewValueParam].value = undefined;
      item[vm.entityViewValueParam].validFrom = undefined;
      item[vm.entityViewValueParam].validTo = undefined;
      item[vm.entityViewValueParam].entityAttributeId = undefined;
    }
  }
  /**
   * @description used for removing values from the dynamic attribute history list.
   * @function
   * @param {object} item - the item being removed
   * @param {array} history - array of object each containing a single dynamic attribute entry.
   * @param {number} index - index of the item being deleted
   * @param {object} parentItem - parent object of item and history parameters
   * @return {dataType}
   */
  function removeItem(item, history, index, parentItem) {
    var name = item[vm.entityViewValueParam]
      ? item.elementType === 'select'
        ? item[vm.entityViewValueParam].readableValue
        : item[vm.entityViewValueParam].value
      : undefined;
    var title = gettext('Remove role.');
    var textItem = {
      text: gettext('Would you like to remove value ') + name + '?',
      type: 'text'
    };
    var actions = [
      {
        title: gettext('Cancel'),
        cancel: true,
        color: 'primary'
      },
      {
        title: gettext('Remove'),
        fn: remove,
        color: 'warn'
      }
    ];

    InfoDialog.open(title, null, [textItem], actions);

    function remove() {
      var method = CrawlerMethods.getMethod({
        entity: configuration.entityAtrributes,
        method: 'delete'
      });
      method({
        id: item.entityAttributeId
      }).then(
        function() {
          $mdDialog.cancel();
          history.splice(history.indexOf(item), 1);
          // history.splice(index, 1);
          if (item.isActive) {
            resetItem(parentItem);
          }
        },
        function(err) {
          AlertingService.Error(err);
        }
      );
    }
  }
  /**
   * @description checks if dynamic attribute if value based on the valid to and valid form dates.
   * @function
   * @param {object} item - the value being checked
   * @return {boolean} true of the item is active, false if it isn't.
   */
  function isItemActive(item) {
    var now = new Date();
    return (
      now >= new Date(item.validFrom) &&
      (!item.validTo || now <= new Date(item.validTo))
    );
  }
  /**
   * @description prepares and calls function that updates dynamic attribute historical values
   * @function
   * @param {object} item - the object that was edited
   * @param {object} parentItem - part of the item that is being edited
   */
  function saveHistoryItem(item, parentItem) {
    var apiObj = getHistoryApiObject(item);
    update(apiObj, item).then(function(updatedItem) {
      if (updatedItem) {
        var itemWasActive = item.isActive;
        item.isActive = isItemActive(updatedItem);
        item[vm.entityViewValueParam].validFrom = updatedItem.validFrom;
        item[vm.entityViewValueParam].validTo = updatedItem.validTo;
        item[vm.entityViewValueParam].value = updatedItem.value;
        item.value = updatedItem.value;
        if (item.isActive) {
          // (if is active || if item becomes active) => update main item
          parentItem[vm.entityViewValueParam] = {};
          parentItem[vm.entityViewValueParam].validFrom = updatedItem.validFrom;
          parentItem[vm.entityViewValueParam].validTo = updatedItem.validTo;
          parentItem[vm.entityViewValueParam].value = updatedItem.value;
          parentItem[vm.entityViewValueParam]._id = updatedItem._id;
        } else if (itemWasActive && !item.isActive) {
          // if item becomes inactive
          resetItem(parentItem);
        }
        if (item.elementType === 'select') {
          getSelectValue(item).then(
            function() {
              if (item.isActive) {
                parentItem[vm.entityViewValueParam].readableValue =
                  item[vm.entityViewValueParam].readableValue;
                parentItem.config.editRefreshObject = getSelectRefreshObject(
                  parentItem
                );
              }
            },
            function(err) {
              AlertingService.Error(err);
            }
          );
        } else if (item.elementType === 'double') {
          if (item.isActive) {
            parentItem[
              vm.entityViewValueParam
            ].readableValue = Formatting.formatNumber(
              String(item[vm.entityViewValueParam].value)
            );
          }
          item[vm.entityViewValueParam].readableValue =
            item[vm.entityViewValueParam].value;
        } else {
          if (item.isActive) {
            parentItem[vm.entityViewValueParam].readableValue =
              item[vm.entityViewValueParam].value;
          }
          item[vm.entityViewValueParam].readableValue =
            item[vm.entityViewValueParam].value;
        }
      }
      item.edit = false;
    });
  }
  /**
   * @description closes the form for editing historical dynamic attributes
   * @function
   * @param {object} item - the item for which the edit form was opened
   */
  function cancelEditingHistory(item) {
    item.edit = false;
  }
  /**
   * @description function that defaults editTimeTo to 23.59 whenever the date is changed for the item and there is no existing value.
   * @function
   * @param {object} item - the object of which we are changing the editTimeTo property.
   */
  function historyValidToChanged(item) {
    if (!item.editTimeTo && item.editValidTo) {
      item.editTimeTo = '23:59';
    }
  }
}
