import './sfe-tag-explorer.scss';
import template from './sfe-tag-explorer.component.html';

export default {
  restrict: 'E',
  template,
  bindings: {
    tangoAgentConnectionConfig: '<',
    job: '<',
    selectedTag: '=?',
    activePointType: '<',
    isDialog: '<'
  },
  controller: SfeTagSelectorController,
  controllerAs: 'vm',
  bindToController: true
};

SfeTagSelectorController.$inject = [
  'TagModel',
  'AlertingService',
  '$scope',
  '$element',
  '$timeout',
  'gettext'
];
function SfeTagSelectorController(
  TagModel,
  AlertingService,
  $scope,
  $element,
  $timeout,
  gettext
) {
  const vm = this;

  const LIMIT = 1000;
  const ORDER = 'name';

  vm.relistData = false;
  vm.getSideMenuChildren = getSideMenuChildren;
  vm.getContentTags = getContentTags;
  vm.filterContent = filterContent;
  vm.selectContentTag = selectContentTag;
  vm.paginationChange = paginationChange;
  vm.loadMoreSideMenuTags = loadMoreSideMenuTags;
  vm.paginationApi = {
    paginationChange
  };
  vm.paginationConfiguration = {
    limit: 5,
    page: 1,
    total: 0,
    orderOptions: [
      {
        display: gettext('created DESC'),
        params: '-_id'
      },
      {
        display: gettext('created ASC'),
        params: '_id'
      }
    ],
    sort: ORDER
  };
  vm.rootTag = {
    id: 'root',
    _id: 'root',
    isRoot: true,
    hasChildren: true
  };

  const selectedClass = 'selected-side-menu-tag';

  let contentFilter;
  let selectedSideMenuTagId;

  vm.$onChanges = changes => {
    if (
      (changes.tangoAgentConnectionConfig && vm.tangoAgentConnectionConfig) ||
      (changes.job && vm.job)
    ) {
      vm.getContentTags(vm.rootTag);
      fetchRootSideMenuTags();
    }
  };
  /**
   * @description gets root side menu tags.
   * @function
   */
  async function fetchRootSideMenuTags() {
    const sideMenuFilter = {
      tangoAgentConnectionConfig: vm.tangoAgentConnectionConfig,
      job: vm.job,
      isItem: false,
      parent: 'root',
      limit: LIMIT,
      order: ORDER
    };

    try {
      const sideMenuResult = await TagModel.read(sideMenuFilter);
      vm.rootTag.pagination = sideMenuResult.pagination;
      if (sideMenuResult.data.length < 1) {
        vm.rootTag.hasChildren = false;
      } else {
        vm.rootTag.childTags = sideMenuResult.data;
        vm.rootTag.childrenAreFetched = true;
      }
      const { pagination } = vm.rootTag;
      vm.rootTag.loadMore =
        pagination.total > pagination.per_page * pagination.page;
    } catch (err) {
      AlertingService.Error(err);
    }
    $scope.$applyAsync();
  }
  /**
   * @description gets tag children and adds then to childTags array. If there are no side menu child tags calls getContentTags method
   * @function
   * @param {Object} tag tag object
   */
  async function getSideMenuChildren(tag) {
    tag.isOpened = !tag.isOpened;
    if (tag.isOpened) {
      if (!tag.childrenAreFetched) {
        vm.dataIsLoading = true;
        let tagPagination = {
          limit: LIMIT
        };
        if (tag.pagination != null) {
          tagPagination = {
            limit: tag.pagination.per_page,
            page: tag.pagination.page + 1
          };
        }
        const filter = {
          tangoAgentConnectionConfig: vm.tangoAgentConnectionConfig,
          job: vm.job,
          isItem: false,
          parent: tag.id,
          order: ORDER,
          ...tagPagination
        };
        try {
          const { pagination, data: tags } = await TagModel.read(filter);

          if (tags.length < 1) {
            tag.hasChildren = false;
            getContentTags(tag);
          } else {
            tag.childTags = tags;
            tag.childrenAreFetched = true;
            tag.pagination = pagination;
          }
          tag.loadMore =
            pagination.total > pagination.per_page * pagination.page;
        } catch (err) {
          AlertingService.Error(err);
        }
        vm.dataIsLoading = false;
        $scope.$applyAsync();
      } else {
        //when menu is reopened we should highlight selected item
        checkIfSelectedSideMenuTagIsHighlighted();
      }
    }
  }
  /**
   * @description fetches tags that have flag isItem=false.
   * @function
   * @param {Object} tag
   */
  async function getContentTags(tag) {
    if (vm.paginationApi.startPaginationReset) {
      vm.paginationApi.startPaginationReset();
    }
    vm.dataIsLoading = true;
    //reset tag search
    vm.tagSearch = {};
    try {
      contentFilter = {
        page: vm.paginationConfiguration.page - 1,
        limit: vm.paginationConfiguration.limit,
        order: vm.paginationConfiguration.sort,
        tangoAgentConnectionConfig: vm.tangoAgentConnectionConfig,
        job: vm.job,
        isItem: true,
        parent: tag.id,
        itemName: vm.tagSearch != null ? vm.tagSearch.itemName : null,
        name: vm.tagSearch != null ? vm.tagSearch.name : null,
        id: vm.tagSearch != null ? vm.tagSearch.id : null
      };
      const { pagination, data: tags } = await TagModel.read(contentFilter);
      if (vm.paginationApi.endPaginationReset) {
        vm.paginationApi.endPaginationReset(false, pagination.total);
      }
      tag.contentTags = tags;
      vm.contentTags = tags;
      highlightNewSelectedItem(tag);
      $scope.$applyAsync();
    } catch (err) {
      $scope.$emit('endPaginationReset', { failed: true });
      if (vm.paginationApi.endPaginationReset) {
        vm.paginationApi.endPaginationReset(true);
      }
      AlertingService.Error(err);
    }
    vm.dataIsLoading = false;
  }
  /**
   * @description sets selected class to a new selected item.
   * @function
   * @param {Object} tag
   */
  function highlightNewSelectedItem(tag) {
    const selectedTag = $element.find('.' + selectedClass);
    let newlySelectedTag;
    if (tag) {
      newlySelectedTag = $element.find('.tag' + tag._id);
      selectedSideMenuTagId = tag._id;
    }
    if (selectedTag) {
      selectedTag.removeClass(selectedClass);
      if (newlySelectedTag) {
        newlySelectedTag.addClass(selectedClass);
      }
    }
  }
  /**
   * @description is called when side menu is reopened. Highlights selected side menu item.
   * @function
   */
  function checkIfSelectedSideMenuTagIsHighlighted() {
    if (selectedSideMenuTagId) {
      let selectedTag = $element[0].querySelector('.' + selectedClass);
      if (!selectedTag) {
        $timeout(() => {
          selectedTag = $element[0].querySelector(
            '.tag' + selectedSideMenuTagId
          );
          if (selectedTag) {
            angular.element(selectedTag).addClass(selectedClass);
          }
        });
      }
    }
  }
  /**
   * @description gets content tags using filters.
   * @function
   */
  async function filterContent() {
    vm.paginationApi.startPaginationReset();
    vm.dataIsLoading = true;
    contentFilter = {
      limit: vm.paginationConfiguration.limit,
      page: vm.paginationConfiguration.page,
      order: vm.paginationConfiguration.sort,
      tangoAgentConnectionConfig: vm.tangoAgentConnectionConfig,
      job: vm.job,
      isItem: true,
      itemName: vm.tagSearch != null ? vm.tagSearch.itemName : null,
      name: vm.tagSearch != null ? vm.tagSearch.name : null,
      id: vm.tagSearch != null ? vm.tagSearch.id : null
    };
    selectedSideMenuTagId = false;
    try {
      const { pagination, data: tags } = await TagModel.read(contentFilter);
      if (vm.paginationApi.endPaginationReset) {
        vm.paginationApi.endPaginationReset(false, pagination.total);
      }
      vm.contentTags = tags;
      highlightNewSelectedItem();
      vm.dataIsLoading = false;
      $scope.$applyAsync();
    } catch (err) {
      vm.paginationApi.endPaginationReset(true);
      vm.dataIsLoading = false;
      AlertingService.Error(err);
    }
  }
  /**
   * @description sets selected tag.
   * @function
   * @param {Object} tag
   */
  function selectContentTag(tag) {
    vm.selectedTag = tag;
  }
  /**
   * @description loads more content items.
   * @function
   */
  async function paginationChange() {
    try {
      vm.dataIsLoading = true;
      contentFilter = {
        ...contentFilter,
        limit: vm.paginationConfiguration.limit,
        page: vm.paginationConfiguration.page,
        order: vm.paginationConfiguration.sort
      };
      const { pagination, data: tags } = await TagModel.read(contentFilter);
      vm.contentTags = tags;
      vm.paginationApi.endPaginationReset(false, pagination.total);
      vm.dataIsLoading = false;
      $scope.$applyAsync();
    } catch (err) {
      vm.paginationApi.endPaginationReset(true);
      AlertingService.Error(err);
    }
  }
  /**
   * @description load more content items.
   * @function
   * @param {Object} tag
   */
  async function loadMoreSideMenuTags(tag) {
    tag.loadingMore = true;
    let tagPagination = {
      limit: vm.paginationConfiguration.limit,
      page: vm.paginationConfiguration.page
    };
    if (tag.pagination != null) {
      tagPagination = {
        limit: tag.pagination.per_page,
        page: tag.pagination.page + 1
      };
    }
    vm.paginationConfiguration.page = 1;
    try {
      const filter = {
        tangoAgentConnectionConfig: vm.tangoAgentConnectionConfig,
        job: vm.job,
        isItem: false,
        parent: tag.id,
        order: vm.paginationConfiguration.sort,
        ...tagPagination
      };

      const { pagination, data: tags } = await TagModel.read(filter);
      vm.paginationConfiguration.total = pagination.total;
      tag.childTags = tag.childTags.concat(tags);
      tag.pagination = pagination;
      tag.loadMore = pagination.total > pagination.per_page * pagination.page;
    } catch (err) {
      AlertingService.Error(err);
    }
    tag.loadingMore = false;
    $scope.$applyAsync();
  }
}
