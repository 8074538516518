ModelUtilities.$inject = [
  'NetworkResource',
  'CryptoService',
  '$timeout',
  'CachingControl'
];
/**
 * @ngdoc service
 * @name common.ModelUtilities
 * @description Network models helper.
 * @property {function} crud
 */
export default function ModelUtilities(
  NetworkResource,
  CryptoService,
  $timeout,
  CachingControl
) {
  // saving requests if multiple same requests are made
  var requestStorage = {};
  /**
   * @description sets value to the storage.
   * @function
   * @param {array} keys array of property name keys
   * @param {any} value value to be set
   */
  function setStorage(modelStorage, hash, value) {
    value.__cachedTime = Date.now();
    modelStorage[hash] = angular.copy(value);
  }

  /**
   * @description clears storage.
   * @function
   * @param {array} hash key in model storage
   * @param {object} modelStorage model data
   */
  function invalidate(hash, modelStorage) {
    delete modelStorage[hash];
  }

  function validate(item, expirationTime) {
    return item && Date.now() - item.__cachedTime > expirationTime;
  }

  /**
   * @memberof ModelUtilities.getStorage
   * @description returns value of storage.
   * @param {object} modelStorage model's storage object
   * @param {array} has model object's key
   * @param {number} invalidationTime time in milliseconds used to invalidate item in cache
   * @param {boolean} _preserve_ whether to return reference (true) or a copy (false)
   * @return {any}
   */
  function getStorage(modelStorage, hash, invalidationTime, _preserve_) {
    // if item is expired, invalidate it
    if (
      validate(
        modelStorage[hash],
        typeof invalidationTime !== 'undefined' && invalidationTime >= 0
          ? invalidationTime
          : CachingControl.GetItemCacheTime()
      )
    ) {
      invalidate(hash, modelStorage);
    }
    return _preserve_ ? modelStorage[hash] : angular.copy(modelStorage[hash]);
  }
  /**
   * @memberof ModelUtilities.crud
   * @description generates network request .
   * @param {string} method PSOT, PUT, GET ...
   * @param {object} entityConfiguration object of entity configuration
   * @param {object} modelStorage model storage object
   * @return {function}
   */
  function crud(method, entityConfiguration, modelStorage) {
    /**
     * @description executes network request.
     * @function
     * @param {object} params query parameters
     * @param {object} payload request payload
     * @param {bool}
     * @return {Promise}
     */
    return function(params, payload, invalidationTime) {
      if (method === 'GET' && arguments.length === 2) {
        invalidationTime = payload; //in case of GET request cache is 2nd argument
      }
      const networkConfiguration = {
        host: entityConfiguration.host,
        path: entityConfiguration.path,
        allLang: entityConfiguration.allLang,
        headers: entityConfiguration.headers,
        method: method,
        data: entityConfiguration.data
      };

      const requestConfigurations = {};
      if (entityConfiguration.imageArrayBuffer) {
        requestConfigurations.imageArrayBuffer = true;
      }
      if (entityConfiguration.notCancelable) {
        requestConfigurations.notCancelable = true;
      }

      let hashParams = { ...params };
      if (method === 'POST' && entityConfiguration.keepPostCache) {
        hashParams = {
          ...hashParams,
          ...payload
        };
      }

      // create headers hash
      const hash = CryptoService.GenerateNetworkHash({
        ...networkConfiguration,
        ...hashParams,
        ...entityConfiguration.headers
      });

      var stored;
      if (method === 'GET') {
        stored = getStorage(requestStorage, hash, null, true);
      }
      if (stored) {
        return stored.promise;
      } else {
        var cachedItem;

        // if custom cache is not ON and global Cache is ON
        if (CachingControl.GlobalCacheOn()) {
          cachedItem = getStorage(modelStorage, hash, invalidationTime, false);
        }

        if (cachedItem) {
          return $timeout(function() {
            return cachedItem;
          });
        } else {
          networkConfiguration.queryParameterOverride =
            entityConfiguration.queryParameterOverride;
          var resource = NetworkResource(
            networkConfiguration,
            requestConfigurations
          );
          var requestObject;
          if (method === 'POST' && arguments.length === 1) {
            requestObject = {
              promise: resource.method({}, params).$promise
            };
          } else {
            requestObject = {
              promise: resource.method(params, payload).$promise
            };
          }

          requestStorage[hash] = requestObject;

          // cache
          requestObject.promise.then(
            res => {
              if (
                method !== 'GET' &&
                !(method === 'POST' && entityConfiguration.keepPostCache)
              ) {
                CachingControl.RefreshCache(); // refresh model storage on DELETE, PUT and POST
              } else if (CachingControl.GlobalCacheOn()) {
                setStorage(modelStorage, hash, angular.copy(res)); // set model storage on GET
              }
              invalidate(hash, requestStorage);
            },
            () => {
              invalidate(hash, requestStorage);
            }
          );
          return requestStorage[hash].promise;
        }
      }
    };
  }

  /**
   * @description Revalidates items, deletes if cache is stale.
   * @function
   * @param {object} modelStorage - storage from a model
   * @return {boolean}
   */
  function RevalidateModelStorage(modelStorage) {
    return () => {
      let expirationTime = CachingControl.GetItemCacheTime();
      for (var key in modelStorage) {
        if (validate(modelStorage[key], expirationTime)) {
          delete modelStorage[key];
        }
      }
    };
  }

  /**
   * @description description.
   * @function
   * @param {object} modelStorage - storage from a model
   */
  function RefreshModelStorage(modelStorage) {
    return () => {
      for (var key in modelStorage) {
        delete modelStorage[key];
      }
    };
  }

  return {
    crud,
    RefreshModelStorage,
    RevalidateModelStorage
  };
}
