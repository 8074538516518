module.exports = {
  entity: 'device-types',
  path: 'device-types',
  name: {
    en: 'device-types',
    sl_SI: 'Tipi Naprav'
  },
  networkModel: {
    entity: 'device-types',
    read: 'read'
  },
  scope: 'tis_be:device-types',

  dialog: {
    title: {
      en: 'Select device type',
      sl_SI: 'Izberite tip naprave'
    },
    listInfo: {
      en: 'Select device type from the list below',
      sl_SI: 'Izberite tip naprave s spodnjega seznama'
    }
  }
}
