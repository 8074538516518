module.exports = {
  entity: 'response-resolution-times',
  path: 'response-resolution-times',
  name: {
    en: 'Response resolution times',
    sl_SI: 'Čas odzivov in rešitev '
  },
  networkModel: {
    entity: 'response-resolution-times',
    read: 'read'
  },
  scope: 'tis_be:response-resolution-times',

  dialog: {
    title: {
      en: 'Select response resolution time',
      sl_SI: 'Izberite čas odziva in rešitve'
    },
    listInfo: {
      en: 'Select response resolution time from the list below',
      sl_SI: 'Izberite čas odziva in rešitve s spodnjega seznama'
    }
  }
}
