module.exports = {
  entity: 'writerDataPointTimeoutActions',
  path: 'writerDataPointTimeoutActions',
  name: {
    en: 'Writer datapoint timeout action',
    sl_SI: 'Nastavitve časovne omejitve'
  },
  codelist: 'measuringPointKinds',
  selectedParamName: 'id',
  scope: 'tis_be:writer-data-point-timeout-actions',

  dialog: {
    codelist: 'writerDataPointTimeoutActions',
    title: {
      en: 'Select Writer datapoint timeout action',
      sl_SI: 'Izberite Nastavitve časovne omejitve'
    },
    listInfo: {
      en: 'Select Writer datapoint timeout action from the list below',
      sl_SI: 'Izberite Nastavitve časovne omejitve s spodnjega seznama'
    }
  }
}
