module.exports = {
  entity: 'target-for-x-axis-intervals',
  path: 'target-for-x-axis-intervals',
  name: {
    en: 'Target for x axis intervals',
    sl_SI: 'Cilji za intervale na X oseh'
  },
  networkModel: {
    entity: 'target-for-x-axis-intervals',
    read: 'read'
  },
  scope: 'tis_be:target-for-x-axis-intervals',

  dialog: {
    title: {
      en: 'Select Target for X axis interval',
      sl_SI: 'Izberite cilj za interval na osi X'
    },
    listInfo: {
      en: 'Select Target for X axis interval from the list below',
      sl_SI: 'Izberite cilj za interval na osi X s spodnjega seznama'
    }
  }
}
