import template from './indirect-access.component.html';
import './sfe-entity-indirect-permission-tree-item.scss';

export default {
  template,
  bindings: {
    treeId: '<',
    userId: '<',
    entity: '<',
    treeConfiguration: '<'
  },
  controllerAs: 'vm',
  controller: indirectAccessController
};

indirectAccessController.$inject = [
  'IndirectAccessDialog',
  'AlertingService',
  '$q',
  'gettext',
  'InfoDialog',
  'ToastService',
  'CrawlerMethods',
  '$mdDialog',
  'gettextCatalog',
  'CrudToastFactory'
];
/**
 * @ngdoc component
 * @name user.indirectAccess
 * @description displays entity items that user has access to.
 * @param {string} treeId id of privilege access tree
 * @param {string} userId id of the selected user
 * @param {string} entity account entity identifier
 * @param {array} treeConfiguration array of configurations to construct indirect access tree
 * @example
 * <indirect-access
 * treeId="id"
 * userId="userId"
 * entity="tis_be:time-series"
 * treeConfiguration="treeConfiguration"
 * ></indirect-access>
 */
function indirectAccessController(
  IndirectAccessDialog,
  AlertingService,
  $q,
  gettext,
  InfoDialog,
  ToastService,
  CrawlerMethods,
  $mdDialog,
  gettextCatalog,
  CrudToastFactory
) {
  var vm = this;

  vm.actions = [
    {
      title: gettext('Remove all on page'),
      fn: removeAllAccessInstance,
      confirmationText: gettext('Are you sure you want to remove all items?'),
      color: 'warn'
    },
    {
      title: gettext('+ Add'),
      fn: openAddEntityAccessDialog,
      color: 'primary'
    }
  ];

  vm.query = {
    page: 1,
    limit: 5,
    filters: [],
    filterObject: {}
  };
  /**
   * @description function that is triggered when bindings are changed.
   * when entity binding is set fetch initial tree data
   * @function
   */
  vm.$onChanges = function() {
    if (vm.entity) {
      vm.listId = vm.entity + '_indirect_access';
      vm.fetchIndirectAccess = {
        query: function(apiObject) {
          var deferred = $q.defer();
          vm.accessesAreLoading = true;
          apiObject.owner = vm.userId;
          apiObject.tree = vm.treeId;
          apiObject.target = vm.entity;
          var method = CrawlerMethods.getMethod({
            entity: 'authorization-tree-path',
            method: 'read'
          });
          method(apiObject).then(
            function(res) {
              vm.actions[0].disabled = res.data.length < 1;
              deferred.resolve(res);
            },
            function(err) {
              deferred.reject(err);
            }
          );
          return {
            $promise: deferred.promise
          };
        }
      };

      vm.query.relistData = true;
    }
  };
  /**
   * @description triggered when user wants to remove all visible indirect access data.
   * @function
   */
  function removeAllAccessInstance() {
    function remove() {
      var deleteObject = {};
      if (vm.instanceAccessItems) {
        vm.instanceAccessItems.forEach(function(item) {
          var path = item.targetScope;
          if (item.treePath) {
            item.treePath.forEach(function(arg) {
              path += arg.identifier;
            });
            path += item.instanceScope;
            if (!deleteObject[path]) {
              deleteObject[path] = {
                owner: vm.userId,
                tree: vm.treeId,
                targetScope: vm.entity,
                instanceId: [],
                treePath: _.map(item.treePath, 'identifier'),
                instanceScope: item.instanceScope,
                access: {
                  create: false,
                  read: false,
                  update: false,
                  delete: false,
                  list: false
                }
              };
            }
          }
          deleteObject[path].instanceId.push(item.instanceId);
        });
      }

      var promises = [];
      var method = CrawlerMethods.getMethod({
        entity: 'authorization-tree-path',
        method: 'create'
      });
      if (deleteObject) {
        for (var obj in deleteObject) {
          promises.push(method(deleteObject[obj]));
        }
      }
      $q.all(promises).then(
        function() {
          vm.instanceAccessItems = [];
          vm.actions[0].disabled = true;
          CrudToastFactory.toast('update');
          $mdDialog.cancel();
        },
        function(err) {
          AlertingService.Error(err);
          $mdDialog.cancel();
        }
      );
    }
    var title = gettext('Remove indirect access');
    var textItem = {
      text: gettextCatalog.getString('Remove all items from indirect access?'),
      type: 'text'
    };
    var actions = [
      {
        title: gettext('Cancel'),
        cancel: true,
        color: 'primary'
      },
      {
        title: gettext('Yes'),
        fn: remove,
        color: 'warn'
      }
    ];
    InfoDialog.open(title, null, [textItem], actions);
  }
  /**
   * @description opens indirect access tree dialog that allows to add new access items and when dialog is closed saves new items.
   * @function
   */
  function openAddEntityAccessDialog() {
    IndirectAccessDialog.open(
      vm.treeId,
      vm.userId,
      vm.entity,
      vm.treeConfiguration
    ).then(function(results) {
      if (results) {
        var promises = [];
        var apiObject;
        var instanceScope;
        var result;
        var method = CrawlerMethods.getMethod({
          entity: 'authorization-tree-path',
          method: 'create'
        });
        for (var key in results) {
          result = results[key];
          instanceScope = result.path.splice(result.path.length - 1)[0].scope;

          apiObject = {
            owner: vm.userId,
            tree: vm.treeId,
            targetScope: vm.entity,
            instanceId: _.map(result.selectedInstances, result.entityValueName),
            treePath: _.reverse(_.map(result.path, 'scope')),
            instanceScope: instanceScope,
            access: {
              create: false,
              read: false,
              update: false,
              delete: false,
              list: true
            }
          };

          promises.push(method(apiObject));
        }
        vm.query.loading = true;
        $q.all(promises).then(
          function() {
            vm.query.loading = false;
            vm.query.relistData = true;
            ToastService.showToast(
              gettext('Indirect access items were successfully added.')
            );
          },
          function(err) {
            vm.query.loading = false;
            AlertingService.Error(err);
          }
        );
      }
    });
  }
}
