import template from './new-time-series-value.dialog.html';
import './new-time-series-value.scss';
import { DateTime } from 'luxon';
import actions from '../../redux/time-series-processing-values/action/time-series-processing-values.action';

NewTimeSeriesValueDialogService.$inject = ['$mdDialog'];
/**
 * @ngdoc service
 * @name data.NewTimeSeriesValueDialogService
 * @description
 * @property {function} open - opens mt analysis flow baseline target form
 */
function NewTimeSeriesValueDialogService($mdDialog) {
  /**
   * @description opens dialog with form to edit.
   * @function
   * @param {Object} mtFlow that should contain new/updated baseline
   * @param {Object} baseline baseline to update
   * @return {Object} contains new baseline object
   */
  async function open(timeSeries, flows) {
    return $mdDialog
      .show({
        controller: DialogController,
        controllerAs: 'vm',
        template,
        parent: angular.element(document.body),
        locals: {
          timeSeries,
          flows
        }
      })
      .catch(angular.noop);
  }
  return {
    open
  };
}
DialogController.$inject = [
  'timeSeries',
  'flows',
  'gettext',
  'gettextCatalog',
  'CronValidatorService',
  'AlertingService',
  'TimeSeriesProcessingValuesModel',
  '$mdDialog',
  'ToastService',
  '$ngRedux',
  '$timeout'
];
function DialogController(
  timeSeries,
  flows,
  gettext,
  gettextCatalog,
  CronValidatorService,
  AlertingService,
  TimeSeriesProcessingValuesModel,
  $mdDialog,
  ToastService,
  $ngRedux,
  $timeout
) {
  let vm = this;
  later.date.localTime();

  vm.dateFormConfig = getDateForm();
  vm.dateFormApi = {};

  vm.valueFormConfig = getValueForm();
  vm.valueFormApi = {};
  //FORM HEADER
  vm.header = {
    title: gettext('New Time Series Value'),
    isDialog: true,
    actions: [
      {
        icon: {
          name: 'close',
          type: 2
        },
        cancel: true
      }
    ]
  };

  vm.hasRegularTimeInterval = timeSeries.dataSamplingType !== 200;
  /**
   * @description sets date to previous valid date.
   * @function
   */
  vm.previousDate = () => {
    let currentDate = vm.dateFormApi.getValue('date');
    if (Array.isArray(currentDate)) {
      let nextDate = getDate(currentDate[0], 'prev');
      vm.dateFormApi.setValue('date', [nextDate]);
    }
  };
  /**
   * @description sets date to next valid date.
   * @function
   */
  vm.nextDate = () => {
    let currentDate = vm.dateFormApi.getValue('date');
    if (Array.isArray(currentDate)) {
      let nextDate = getDate(currentDate[0], 'next');
      vm.dateFormApi.setValue('date', [nextDate]);
    }
  };
  /**
   * @description returns sfe-form2 form configuration with date field.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function getValueForm() {
    return {
      name: 'dateForm',
      fields: [getValueField(timeSeries.dataType)]
    };
  }
  //SAVE
  vm.saveActions = [
    {
      title: gettext('Save'),
      /**
       * @description parses value depending on dataType and posts it to time series values.
       * @function
       */
      fn: async () => {
        let value = vm.valueFormApi.getValue('value');
        if (timeSeries.dataType) {
          switch (timeSeries.dataType) {
          case 1:
            //when point type is bool stringify value
            value = String(!!value.isSelected);
            break;
          case 5: //DATE
            value = DateTime.fromJSDate(new Date(value[0])).toFormat(
              'yyyy-MM-dd'
            );
            break;
          case 6: //TIME
            value = DateTime.fromJSDate(new Date(value[0])).toFormat(
              'HH:mm:ss'
            );
            break;
          case 7: //DATE TIME
            value = DateTime.fromJSDate(value[0]).toFormat(
              'yyyy-MM-dd HH:mm:ss'
            );
            break;
          }
        }
        let date = vm.dateFormApi.getValue('date');
        date = DateTime.fromJSDate(date[0]).toFormat('dd/MM/yyyy HH:mm:ss');

        const postObject = [
          {
            validAt: date,
            value
          }
        ];

        try {
          await TimeSeriesProcessingValuesModel.create(
            { timeSeriesId: timeSeries._id, timeliness: 100 },
            postObject
          );
          //notify store that for current timeSeries new value was added in order to update chart and header detail
          let action = actions.addUpdateValuesTag({
            id: timeSeries._id,
            state: { content: true, detail: true }
          });
          $ngRedux.dispatch(action);
          ToastService.showToast(
            gettext('Time series value was successfully created!')
          );
          $mdDialog.hide();
        } catch (err) {
          AlertingService.Error(err);
        }
      },
      disabledFn: () => {
        if (
          typeof vm.dateFormApi.formValidity == 'function' &&
          typeof vm.valueFormApi.formValidity == 'function'
        ) {
          return (
            !vm.dateFormApi.formValidity() || !vm.valueFormApi.formValidity()
          );
        }
        return vm.date;
      }
    }
  ];

  /**
   * @description returns next valid date .
   * @function
   * @param {Date} date currently valid date
   * @param {String} methodName method name prev or next
   * @return {Date}
   */
  function getDate(date, methodName) {
    let currentDate = new Date(date);
    if (timeSeries.dataScheduler != null) {
      const scheduler = later.schedule(
        later.parse.cron(timeSeries.dataScheduler.crontabExpression, true)
      );
      const nextDate = scheduler[methodName](2, currentDate);
      if (nextDate[0].getTime() === currentDate.getTime()) {
        return nextDate[1];
      }
      return nextDate[0];
    }
    return currentDate;
  }
  /**
   * @description returns values field depending on data type.
   * @function
   * @param {Number} dataType codelist id of time series data type
   * @return {Object}
   */
  function getValueField(dataType) {
    switch (dataType) {
    case 1: //BOOLEAN
      return {
        id: 'value',
        type: {
          name: 'checkbox',
          options: {
            layout: 'column',
            display: () => {
              return gettextCatalog.getString('Value');
            },
            items: [
              {
                _id: 'isSelected'
              }
            ]
          }
        },
        initialize: () => {
          return { isSelected: false };
        }
      };
    case 2: //INTEGER
      return {
        id: 'value',
        title: gettext('Value'),
        required: true,
        type: {
          name: 'text',
          options: {
            type: 'numerical',
            onlyInteger: true
          }
        },
        initialize: () => ''
      };
    case 3: //DECIMAL
      return {
        id: 'value',
        title: gettext('Value'),
        required: true,
        type: {
          name: 'text',
          options: {
            type: 'numerical'
          }
        },
        initialize: () => ''
      };
    case 4:
      return {
        id: 'value',
        title: gettext('Value'),
        required: true,
        type: {
          name: 'text',
          options: {
            type: 'text'
          }
        },
        initialize: () => ''
      };

    case 5: //DATE
      return {
        id: 'value',
        title: gettext('Date'),
        type: {
          name: 'date',
          options: {
            customOptions: {
              dateFormat: 'd.m.Y'
            }
          }
        },
        initialize: () => {
          return [new Date()];
        }
      };
    case 6: //TIME
      return {
        id: 'value',
        title: gettext('Value'),
        type: {
          name: 'date',
          options: {
            enableTime: true,
            enableSeconds: true,
            customOptions: {
              noCalendar: true,
              dateFormat: 'H:i:S'
            }
          }
        },
        initialize: () => {
          return [new Date()];
        }
      };
    case 7: //DATETIME
      return {
        id: 'value',
        title: gettext('Date'),
        type: {
          name: 'date',
          options: {
            enableTime: true,
            enableSeconds: true
          }
        },
        initialize: () => {
          return [new Date()];
        }
      };
    }
  }

  /**
   * @description check if date fits flow configuration.
   * @function
   * @param {date} date
   * @return {boolean}
   */
  function validDate(date) {
    let valid = false;
    if (Array.isArray(flows) && date != null) {
      const readDate = new Date(date);
      flows.forEach(flow => {
        if (flow.validFrom != null && flow.validTo == null) {
          let formattedValidFrom = new Date(flow.validFrom);
          formattedValidFrom.setSeconds(0);
          formattedValidFrom.setMinutes(0);
          formattedValidFrom.setHours(0);
          if (readDate >= formattedValidFrom) {
            valid = true;
          }
        } else if (flow.validFrom != null && flow.validTo != null) {
          let formattedValidFrom = new Date(flow.validFrom);
          formattedValidFrom.setSeconds(0);
          formattedValidFrom.setMinutes(0);
          formattedValidFrom.setHours(0);
          let formattedValidTo = new Date(flow.validTo);
          formattedValidTo.setSeconds(59);
          formattedValidTo.setMinutes(59);
          formattedValidTo.setHours(23);
          if (readDate >= formattedValidFrom && readDate <= formattedValidTo) {
            valid = true;
          }
        }
      });
    }
    return valid;
  }

  function getDateForm() {
    let latestValidFrom;
    if (Array.isArray(flows)) {
      flows.forEach(flow => {
        const validFrom = new Date(flow.validFrom);
        if (latestValidFrom == null) {
          latestValidFrom = validFrom;
        }
        if (validFrom >= latestValidFrom) {
          if (
            flow.validTo == null ||
            (flow.validTo != null && new Date(flow.validTo) > new Date())
          ) {
            latestValidFrom = new Date();
          } else {
            latestValidFrom = validFrom;
          }
        }
      });
    }
    return {
      name: 'dateForm',
      fields: [
        {
          id: 'date',
          title: gettext('Date'),
          type: {
            name: 'date',
            options: {
              enableTime: true,
              enableSeconds: true,
              customOptions: {
                enable: [validDate],
                allowInvalidPreload: true
              }
            }
          },
          initialize: api => {
            latestValidFrom = latestValidFrom ? latestValidFrom : new Date();
            if (api != null && typeof api.setDirty === 'function') {
              $timeout(() => {
                api.revalidate();
                api.setDirty('date');
              });
            }
            return [getDate(latestValidFrom, 'next')];
          },
          required: true,
          validators: {
            wrongCron: {
              /**
               * @description validates if selected date and time series scheduler cron.
               * @function
               * @param {Array} date Array with date items
               * @return {Boolean}
               */
              fn: date => {
                if (Array.isArray(date) && date.length > 0) {
                  if (timeSeries.dataSamplingType == 200) {
                    return true;
                  } else if (timeSeries.dataScheduler != null) {
                    return CronValidatorService.dateCronMatch(
                      timeSeries.dataScheduler.crontabParsed,
                      new Date(date[0]).getTime()
                    );
                  } else {
                    AlertingService.Error(
                      gettext(
                        'Error on date initialization: Time Series is missing a data scheduler.'
                      )
                    );
                    return false;
                  }
                }
                return true;
              },
              text: gettextCatalog.getString('Wrong cron format.')
            },
            invalidValue: {
              fn: date => {
                let valid = false;
                if (
                  Array.isArray(flows) &&
                  Array.isArray(date) &&
                  date.length > 0
                ) {
                  const readDate = new Date(date[0]);
                  flows.forEach(flow => {
                    if (
                      flow.validFrom != null &&
                      flow.validTo == null &&
                      readDate >= new Date(flow.validFrom)
                    ) {
                      valid = true;
                    } else if (
                      flow.validFrom != null &&
                      flow.validTo != null &&
                      readDate >= new Date(flow.validFrom) &&
                      readDate <= new Date(flow.validTo)
                    ) {
                      valid = true;
                    }
                  });
                  return valid;
                }
              },
              text: gettextCatalog.getString(
                'Selected date does not correspond to valid External Reader Flow Configuration.'
              )
            }
          }
        }
      ]
    };
  }
}

export default NewTimeSeriesValueDialogService;
