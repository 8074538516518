module.exports = {
  entity: 'visualization-images',
  path: 'visualization-images',
  name: {
    en: 'Visualization image',
    sl_SI: 'Vizualizacijska slika'
  },
  networkModel: {
    entity: 'visualization-images',
    read: 'read'
  },
  scope: 'tis_be:visualization-images',

  dialog: {
    title: {
      en: 'Select visualization image',
      sl_SI: 'Izberite vizualizacijsko sliko'
    },
    listInfo: {
      en: 'Select visualization image from the list below',
      sl_SI: 'Izberite vizualizacijsko sliko s spodnjega seznama'
    }
  }
}
