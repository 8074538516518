module.exports = {
  entity: 'external-reader-flows',
  path: 'external-reader-flows',
  entityId: 240,
  storeKey: 'externalReaderFlow',
  name: {
    en: 'External reader Flows',
    sl_SI: 'External reader Flows'
  },
  networkModel: {
    entity: 'external-reader-flows',
    read: 'read'
  },
  scope: 'tis_be:external-reader-flow',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select external reader flow',
      sl_SI: 'Izberite časovno serijo'
    },
    listInfo: {
      en: 'Select external reader flow from the list below',
      sl_SI: 'Izberite časovno serijo s spodnjega seznama'
    }
  }
}
