sfeForm2Validation.$inject = [];
export default function sfeForm2Validation() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
      return scope.$watch(attrs.sfeForm2Validation, function(validators) {
        if (Array.isArray(validators) && validators.length > 0) {
          ngModel.$validators = validators.reduce(
            (allValidators, validator) => {
              return {
                ...allValidators,
                [validator.name]: validator.fn
              };
            },
            ngModel.$validators
          );
        }
      });
    }
  };
}
