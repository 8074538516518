AssetRatedPowerConfiguration.$inject = [
  '$timeout',
  'gettext',
  'SfeFormDialogService',
  'PhysicalCollectionService',
  'AlertingService',
  'AssetModel',
  'TranslationService',
  'InfoDialog',
  '$mdDialog',
  'CrudToastFactory'
];

function AssetRatedPowerConfiguration(
  $timeout,
  gettext,
  SfeFormDialogService,
  PhysicalCollectionService,
  AlertingService,
  AssetModel,
  TranslationService,
  InfoDialog,
  $mdDialog,
  CrudToastFactory
) {
  /**
   * @description returns configuration for rated power form.
   * @function
   * @param {Object} asset
   * @param {Boolean} edit indicates edit mode
   * @param {Object} dataObj reference to form data object
   * @return {Array}
   */
  function getFormConfiguration(asset, edit, dataObj) {
    return [
      {
        configuration: {
          query: {
            entity: 'rated-powers',
            method: 'custom.readEnergySourceType'
          },
          entity: 'rated-powers',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Rated Power')
          },
          //FILTER SELECTED RATED POWERS
          filterFn: items => {
            let selectedRatedPowers = asset.ratedPower || [];
            selectedRatedPowers = selectedRatedPowers.map(
              item => item.ratedPower._id
            );
            return items.filter(
              item => selectedRatedPowers.indexOf(item._id) === -1
            );
          },
          /**
           * @description rated power autocomplete on select sets default measurement unit.
           * @function
           * @param {Array} selected selected item
           */
          change: async selected => {
            if (
              Array.isArray(selected) &&
              selected.length > 0 &&
              selected[0] != null &&
              typeof selected[0] == 'object'
            ) {
              if (
                !selected[0].__prefetched__value__ &&
                selected[0].energySourceType != null
              ) {
                const {
                  defaultPhysicalQuantity: physicalQuantity,
                  defaultMetricPrefix: metricPrefix,
                  defaultMeasurementUnit: measurementUnit
                } = selected[0].energySourceType;
                const physicalData = await PhysicalCollectionService.getReadablePhysicalData(
                  { physicalQuantity, metricPrefix, measurementUnit }
                );

                if (physicalData != null) {
                  if (physicalData.physicalQuantity != null) {
                    dataObj.physicalQuantity = {
                      __prefetched__value__: true,
                      ...physicalData.physicalQuantity
                    };
                  }
                  if (
                    physicalData.measurementUnit != null &&
                    physicalData.metricPrefix != null
                  ) {
                    const measurementUnit = await PhysicalCollectionService.setMeasurementDataObject(
                      physicalData.metricPrefix,
                      physicalData.measurementUnit
                    );
                    dataObj.measurementData = measurementUnit;
                  } else {
                    dataObj.measurementData = null;
                  }
                }
              }
              //WHEN __prefetched__value__ IS TRUE DO NOT RESET MEASUREMENT DATA
            } else {
              dataObj.measurementData = null;
            }
            $timeout();
          },
          floatingLabel: gettext('Select Rated Power'),
          searchParamName: 'filter',
          required: true
        },
        componentType: 'autocompleteDialog',
        edit: edit,
        name: 'ratedPower'
      },
      {
        placeholder: gettext('Quantity'),
        name: 'quantity',
        componentType: 'textField',
        type: 'numerical',
        required: true
      },
      {
        configuration: {
          query: {
            entity: 'physical-quantities',
            method: 'read'
          },
          entity: 'physical-quantities',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select physical quantity')
          },
          /**
           * @description rated power autocomplete on select sets default measurement unit.
           * @function
           * @param {Array} selected selected item
           */
          change: selected => {
            if (
              !Array.isArray(selected) ||
              selected[0] == null ||
              !selected[0].__prefetched__value__
            ) {
              dataObj.measurementData = null;
            }
          },
          floatingLabel: gettext('Select Physical quantity'),
          searchParamName: 'filter',
          required: true
        },
        componentType: 'autocompleteDialog',
        edit: edit,
        name: 'physicalQuantity'
      },
      {
        configuration: {
          query: {
            /**
             * @description returns combined measurement units and metric prefixes filtered by selected physical quantity.
             * @function
             * @param {dataType} binding/paramName
             * @return {dataType}
             */
            query: filter => {
              return {
                $promise: new Promise(async resolve => {
                  if (
                    dataObj != null &&
                    dataObj.physicalQuantity != null &&
                    dataObj.physicalQuantity._id != null
                  ) {
                    try {
                      //GET MEASUREMENT UNIT AND METRIC PREFIX
                      const physicalQuantity = dataObj.physicalQuantity._id;
                      let measurementUnits = await PhysicalCollectionService.returnMeasurementUnits();
                      if (Array.isArray(measurementUnits)) {
                        measurementUnits = measurementUnits.filter(
                          item => item.physicalQuantity === physicalQuantity
                        );
                      }
                      const metricPrefixes = TranslationService.GetCollection(
                        'codelists.metricPrefixes'
                      );
                      //COMBINE
                      let items = measurementUnits.reduce((result, unit) => {
                        result = [
                          ...result,
                          ...metricPrefixes.map(prefix => {
                            return PhysicalCollectionService.setMeasurementDataObject(
                              prefix,
                              unit
                            );
                          })
                        ];
                        return result;
                      }, []);
                      // FILTER
                      if (filter.searchString) {
                        items = items.filter(function(item) {
                          return (
                            item.displayName
                              .toLowerCase()
                              .indexOf(filter.searchString.toLowerCase()) > -1
                          );
                        });
                      }

                      resolve({ data: items });
                    } catch (err) {
                      AlertingService.Error(err);
                      resolve({ data: [] });
                    }
                  } else {
                    resolve({ data: [] });
                  }
                })
              };
            }
          },
          displayFields: ['displayName'],
          disabledFn: () => dataObj == null || dataObj.physicalQuantity == null,
          floatingLabel: gettext('Select Measurement Unit'),
          searchParamName: 'searchString',
          required: true,
          noDialog: true
        },
        componentType: 'autocompleteDialog',
        edit: edit,
        name: 'measurementData'
      },
      {
        placeholder: gettext('Operational Support Price'),
        name: 'operationalSupportPrice',
        componentType: 'textField',
        type: 'numerical',
        required: false
      },
      {
        placeholder: gettext('Guaranteed Price'),
        name: 'guaranteedPrice',
        componentType: 'textField',
        type: 'numerical',
        required: false
      },
      {
        componentType: 'autocompleteDialog',
        edit,
        name: 'currency',
        configuration: {
          entity: 'currencies',
          dialogTitle: gettext('Select Currency'),
          floatingLabel: gettext('Select Currency'),
          codelist: 'currencies',
          empty: gettext('There is no currency to select.'),
          required: false
        }
      }
    ];
  }

  /**
   * @description returns rated power object to post out of list item.
   * @function
   * @param {Object} ratedPower
   * @return {Object}
   */
  function createRatedPowerItem(ratedPower) {
    return {
      _id: ratedPower._id,
      currency: ratedPower.currency,
      ratedPower: ratedPower.ratedPower._id,
      quantity: ratedPower.quantity,
      metricPrefix: ratedPower.metricPrefix,
      measurementUnit: ratedPower.measurementUnit._id,
      operationalSupportPrice: ratedPower.operationalSupportPrice,
      guaranteedPrice: ratedPower.guaranteedPrice
    };
  }
  /**
   * @description transforms api rated power object to list item.
   * @function
   * @param {Object} item
   */
  async function Enrich(item) {
    if (typeof item.ratedPower == 'object' && item.ratedPower != null) {
      item.ratedPowerName = item.ratedPower.name;
      const metricPrefix = TranslationService.GetCollectionById(
        'codelists.metricPrefixes',
        item.metricPrefix
      );
      const measurementUnit = await PhysicalCollectionService.setMeasurementDataObject(
        metricPrefix,
        item.measurementUnit
      );
      item.readableMeasurementUnitSymbol = measurementUnit.displayName;
      const currency = TranslationService.GetCollectionById(
        'codelists.currencies',
        item.currency
      );
      if (currency != null) {
        item.currencyName = currency.name;
      } else {
        item.fetchingObject['currencyName'] = {
          fetching: false,
          success: false,
          nodata: true
        };
      }

      if (item.operationalSupportPrice == null) {
        item.fetchingObject['operationalSupportPrice'] = {
          fetching: false,
          success: false,
          nodata: true
        };
      }
      if (item.guaranteedPrice == null) {
        item.fetchingObject['guaranteedPrice'] = {
          fetching: false,
          success: false,
          nodata: true
        };
      }
    } else {
      item.fetchingObject['ratedPowerName'] = {
        fetching: false,
        success: false,
        nodata: true
      };
      item.fetchingObject['currencyName'] = {
        fetching: false,
        success: false,
        nodata: true
      };
    }
  }

  /**
   * @description creates asset PUT request.
   * @function
   * @param {string} id asset id
   * @param {Array} ratedPower
   * @param {function} relist triggers sfe-list reload
   * @param {string} type
   */
  async function updateAsset(id, ratedPower, relist, type) {
    try {
      await AssetModel.update({ id }, { ratedPower });
      CrudToastFactory.toast(type);
      $timeout(relist);
    } catch (err) {
      AlertingService.Error(err);
    }
  }
  /**
   * @description opens create rated power object dialog and triggers updateAsset method.
   * @function
   * @param {Object} asset
   * @param {function} relist triggers sfe-list reload
   */
  async function openCreateDialog(asset, relist) {
    const dataObj = {
      measurementData: null,
      _preserve_: true,
      testId: 'create_rated_power'
    };
    const formConfig = getFormConfiguration(asset, false, dataObj);
    const formResult = await SfeFormDialogService.openSfeFormDialog(
      false,
      formConfig,
      dataObj,
      gettext('Add Rated Power')
    );
    if (formResult != null) {
      const newRatedPower = createRatedPowerItemOutOfForm(formResult);

      let ratedPower = asset.ratedPower || [];
      ratedPower = ratedPower.map(item => createRatedPowerItem(item));
      ratedPower = [...ratedPower, newRatedPower];
      updateAsset(asset._id, ratedPower, relist, 'create');
    }
  }
  /**
   * @description transforms rated power item received form sfeFormDialog to post object.
   * @function
   * @param {Object} formResult
   * @return {Object}
   */
  function createRatedPowerItemOutOfForm(formResult) {
    return {
      _id: formResult._id,
      ratedPower: formResult.ratedPower._id,
      quantity: formResult.quantity,
      metricPrefix: formResult.measurementData.metricPrefixId,
      measurementUnit: formResult.measurementData.measurementUnitId,
      operationalSupportPrice: formResult.operationalSupportPrice,
      guaranteedPrice: formResult.guaranteedPrice,
      currency: formResult.currency != null ? formResult.currency.id : null
    };
  }
  /**
   * @description opens update rated power item dialog and triggers update method.
   * @function
   * @param {Object} asset
   * @param {Object} itemToUpdate
   * @param {function} relist triggers sfe-list reload
   */
  async function openUpdateDialog(asset, itemToUpdate, relist) {
    let assetItem = angular.copy(asset);
    assetItem.ratedPower = assetItem.ratedPower.filter(
      item => item._id !== itemToUpdate._id
    );
    let metricPrefix = TranslationService.GetCollectionById(
      'codelists.metricPrefixes',
      itemToUpdate.metricPrefix
    );
    let measurementData = await PhysicalCollectionService.setMeasurementDataObject(
      metricPrefix,
      itemToUpdate.measurementUnit
    );
    let physicalQuantity;
    if (itemToUpdate.measurementUnit.physicalQuantity) {
      physicalQuantity = await PhysicalCollectionService.returnPhysicalQuantities(
        itemToUpdate.measurementUnit.physicalQuantity
      );
    }

    let dataObj = {
      _preserve_: true,
      _id: itemToUpdate._id,
      testId: 'update_rated_power',
      metricPrefix: itemToUpdate.metricPrefix,
      quantity: itemToUpdate.quantity,
      currency: itemToUpdate.currency,
      operationalSupportPrice: itemToUpdate.operationalSupportPrice,
      guaranteedPrice: itemToUpdate.guaranteedPrice,
      ratedPower: itemToUpdate.ratedPower._id,
      measurementData,
      physicalQuantity
    };

    const formConfig = getFormConfiguration(assetItem, true, dataObj);
    const formResult = await SfeFormDialogService.openSfeFormDialog(
      true,
      formConfig,
      dataObj,
      gettext('Update Rated Power')
    );
    if (formResult != null) {
      const ratedPower = asset.ratedPower.map(item => {
        if (item._id === itemToUpdate._id) {
          return createRatedPowerItemOutOfForm(formResult);
        } else {
          return createRatedPowerItem(item);
        }
      });
      updateAsset(asset._id, ratedPower, relist, 'update');
    }
  }
  /**
   * @description removes rated power item.
   * @function
   * @param {Object} asset
   * @param {Object} itemToUpdate
   * @param {function} relist triggers sfe-list reload
   */
  async function removeItem(asset, itemToRemove, relist) {
    const title = gettext('Confirmation');
    const textItem = {
      text: gettext('Are you sure you want to delete this item?'),
      type: 'text'
    };
    const actions = [
      {
        title: gettext('Cancel'),
        cancel: true,
        color: 'primary'
      },
      {
        title: gettext('Delete'),
        fn: () => {
          let ratedPower = asset.ratedPower.reduce((result, item) => {
            if (itemToRemove._id !== item._id) {
              result = [...result, createRatedPowerItem(item)];
            }
            return result;
          }, []);
          updateAsset(asset._id, ratedPower, relist, 'delete');
          $mdDialog.cancel();
        },
        color: 'warn'
      }
    ];
    InfoDialog.open(title, null, [textItem], actions);
  }
  /**
   * @description returns rated power sfe-list-2 configuration.
   * @function
   * @param {Object} asset
   * @return {Object}
   */
  function get(asset) {
    const configuration = {
      listId: 'ratedPower',
      entity: {
        singular: gettext('Rated Power'),
        plural: gettext('Rated Power')
      },
      api: {
        query: {
          /**
           * @description queries asset and returns its ratedPower array.
           * @function
           */
          query: () => {
            return {
              $promise: new Promise(async resolve => {
                try {
                  const { data: assetItem } = await AssetModel.read({
                    id: asset._id,
                    select: '_id,ratedPower',
                    populate: 'ratedPower.ratedPower,ratedPower.measurementUnit'
                  });
                  asset.ratedPower = assetItem.ratedPower;
                  resolve(
                    Array.isArray(asset.ratedPower)
                      ? { data: asset.ratedPower }
                      : { data: [] }
                  );
                } catch (err) {
                  resolve([]);
                }
              })
            };
          }
        },
        enrich: Enrich
      },
      title: {
        param: 'ratedPowerName'
      },
      buttons: [
        {
          title: gettext('Create'),
          fn: relist => openCreateDialog(asset, relist),
          relist: true,
          color: 'accent',
          /**
           * @description Disable create button when asset has 5 rated power objects.
           * @function
           * @return {Boolean}
           */
          disabledFn: () => {
            if (
              Array.isArray(asset.ratedPower) &&
              asset.ratedPower.length >= 5
            ) {
              return true;
            }
          }
        }
      ],
      shortActions: [
        {
          icon: 'mode_edit',
          action: (itemToRemove, relist) =>
            openUpdateDialog(asset, itemToRemove, relist)
        },
        {
          icon: 'delete',
          action: (itemToRemove, relist) =>
            removeItem(asset, itemToRemove, relist)
        }
      ],
      attributes: [
        {
          title: gettext('Quantity'),
          param: 'quantity',
          type: 'number'
        },
        {
          title: gettext('Operational Support Price'),
          param: 'operationalSupportPrice',
          type: 'number'
        },
        {
          title: gettext('Guaranteed Price'),
          param: 'guaranteedPrice',
          type: 'number'
        },
        {
          title: gettext('Currency'),
          param: 'currencyName',
          type: 'text'
        }
      ],
      badges: [
        {
          title: gettext('Measurement Unit'),
          type: 'text',
          param: 'readableMeasurementUnitSymbol'
        }
      ],
      limitOptions: [5]
    };

    return configuration;
  }

  return { get };
}
export default AssetRatedPowerConfiguration;
