module.exports = {
  entity: 'realms',
  path: 'realms',
  identityService: true,
  name: {
    en: 'Detail invoices',
    sl_SI: 'Računi'
  },
  networkModel: {
    entity: 'realms',
    read: 'read'
  },
  scope: 'identity:realm',

  dialog: {
    title: {
      en: 'Select organization',
      sl_SI: 'Izberite organizacijo'
    },
    listInfo: {
      en: 'Select organization from the list below',
      sl_SI: 'Izberite organizacijo s spodnjega seznama'
    },
    identityService: true
  }
}
