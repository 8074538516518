BillingCalculationDataModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function BillingCalculationDataModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'billing-calculation-data'
  };

  var crawlerConfiguration = {
    entity: 'billing-calculation-data',
    method: 'read',
    populate:
      'mainMeasuringPoint,submeterMeasuringPoint,costCentre,businessPartner,energyManagementGroup,billingKind,billingType,tariff,energySourceType,measurementUnit,physicalQuantity'
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readWithPopulate: CrawlerMethods.crawler(crawlerConfiguration)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default BillingCalculationDataModel;
