/**
 * @ngdoc service
 * @name common.AnalysisExpressionEnrichment
 * @description Takes analysis data and enriches every each series where the series type is Accumulator
 * and then merges analysis with the enriched information
 * @property {function} constructConfiguration - see enrichExpression constructConfiguration
 */
AnalysisExpressionEnrichment.$inject = [
  'ExpressionModel',
  'AlertingService',
  'TranslationService'
];

function AnalysisExpressionEnrichment(
  ExpressionModel,
  AlertingService,
  TranslationService
) {
  /**
   * @description Creates an array that contains a call for the expression endpoint, which will be used to enrich accumulator series type.
   * @function
   * @param {Object} analysis - data about the analysis used on this page
   * @return {Array} An array containing where each value is either a promise or false
   */
  function createExpressionsPromiseArray(analysis, cacheInvalidationTime) {
    if (analysis.calculation && Array.isArray(analysis.calculation.series)) {
      return analysis.calculation.series.map(series => {
        const seriesId =
          typeof series.value === 'string' ? series.value : series.value._id;
        if (series.type === 'Accumulator') {
          return ExpressionModel.read(
            {
              id: seriesId
            },
            cacheInvalidationTime
          ).then(
            res => res,
            err => {
              AlertingService.Error(err);
              return false;
            }
          );
        }
        return false;
      });
    }
    return [];
  }
  /**
   * @description Adds the values from the expression Array to the analysis object and returns a new analysis object.
   *
   * @function
   * @param {Object} analysis - data about the analysis used on this page.
   * @param {Array} expressions - An array that contains a value we've gotten from expression endpoint or the value false.
   * @return {Object} An object that contains that contains series data enriched by data from the expression endpoint and enriched country information
   */
  function mergeAnalysisWithSeries(analysis, expressions) {
    let series = [];
    if (analysis.calculation && Array.isArray(analysis.calculation.series)) {
      series = analysis.calculation.series.map((item, index) => {
        if (expressions[index]) {
          return {
            ...item,
            value: expressions[index].data
          };
        }
        return item;
      });
    }
    return {
      ...analysis,
      calculation: {
        ...analysis.calculation,
        series
      },
      country: TranslationService.GetCollectionById(
        'codelists.countries',
        analysis.country
      )
    };
  }
  /**
   * @memberof AnalysisExpressionEnrichment.enrichExpression
   * @description function that calls the function that gets the enrichment data and the function that merges that data with analysis
   * and then returns the enriched data.
   * @param {Array} analysis - analysis data
   * @param {number} cacheInvalidationTime - cache invalidation time
   * @return {Array} enriched analysis data
   */
  async function enrichExpression(analysis, cacheInvalidationTime) {
    try {
      const expressions = await Promise.all(
        createExpressionsPromiseArray(analysis, cacheInvalidationTime)
      );
      return mergeAnalysisWithSeries(analysis, expressions);
    } catch (err) {
      throw err;
    }
  }
  return {
    enrichExpression
  };
}

export default AnalysisExpressionEnrichment;
