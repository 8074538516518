numberFormat.$inject = ['Formatting'];
/**
 * @ngdoc filter
 * @name common.numberFormat
 * @description Formats numbers in respect to locatization set in app.
 * @param {dataType} input
 * @example
 * <div>{{ object | numberFormat }}</div>
 */
function numberFormat(Formatting) {
  return function(input, decimalPlaces) {
    return Formatting.formatAllNumbers(input, decimalPlaces);
  };
}

export default numberFormat;
