PrivilegeHelperMethods.$inject = [
  'CrawlerMethods',
  'AlertingService',
  'entityForestConstant',
  '$q'
];
/**
 * @ngdoc service
 * @name user.PrivilegeHelperMethods
 * @description methods that help to construct privilege management view
 * @property {function} getEntitiesAndPrivilegesByTree
 * @property {function} createInstanceConfig
 */
export default function PrivilegeHelperMethods(
  CrawlerMethods,
  AlertingService,
  entityForestConstant,
  $q
) {
  return {
    getEntitiesAndPrivilegesByTree: getEntitiesAndPrivilegesByTree,
    createInstanceConfig: createInstanceConfig
  };
  /**
   * @memberof PrivilegeHelperMethods.getEntitiesAndPrivilegesByTree
   * @description resolves to privilege management configuration.
   * @param {string} treeId tree id
   * @param {string} userId user id
   * @return {Promise}
   */
  function getEntitiesAndPrivilegesByTree(treeId, userId) {
    var deferred = $q.defer();
    var result = {};
    entityForestConstant.forEach(function(tree) {
      result[tree._id] = tree;
    });
    fetchAccessByTree(treeId, userId).then(
      function(res) {
        var result = {};
        var elem;
        var treeConfiguration = res.tree;
        // create root items
        res.access.forEach(function(accessItem) {
          result[accessItem.scope] = {
            config: accessItem,
            parents: [],
            instanceAccess: [],
            rootAccess: []
          };
        });
        // get instance access options
        res.tree.forEach(function(treeItem) {
          result[treeItem.child].parents.push(treeItem);
        });
        Object.keys(result).forEach(function(key) {
          for (var x = 0; x < result[key].parents.length; x++) {
            if (
              result[key].parents[x].parent === result[key].parents[x].child
            ) {
              if (x !== 0) {
                elem = result[key].parents.splice(x, 1)[0];
                result[key].parents.splice(0, 0, elem);
              }
              break;
            }
          }
        });

        deferred.resolve({
          result: result,
          tree: treeConfiguration
        });
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject(err);
      }
    );
    return deferred.promise;
  }
  /**
   * @description resolves to tree access items.
   * @function
   * @param {string} treeId tree id
   * @param {string} userId user id
   * @return {Promise}
   */
  function fetchAccessByTree(treeId, userId) {
    var deferred = $q.defer();
    var method = CrawlerMethods.getMethod({
      entity: 'authorization-tree-access',
      method: 'read'
    });
    method({
      owner: userId,
      tree: treeId
    }).then(
      function(res) {
        deferred.resolve(res.data);
      },
      function(err) {
        deferred.reject(err);
      }
    );
    return deferred.promise;
  }

  /**
   * @memberof PrivilegeHelperMethods.createInstanceConfig
   * @description returns instance configuration.
   * @param {string} userId user id
   * @param {object} parentConfig parent configuration
   * @param {object} obj access object
   * @return {object}
   */
  function createInstanceConfig(userId, parentConfig, obj) {
    return {
      owner: userId,
      instanceId: obj._id,
      instanceScope: parentConfig.parent,
      childScope: parentConfig.child,
      inherits: obj.inherits,
      access: obj.access
    };
  }
}
