import template from '../pages/iconPicker/sfe-icon-picker.dialog.html';

sfeIconPickerDialogService.$inject = ['$mdDialog'];
/**
 * @ngdoc service
 * @name common.sfeIconPickerDialogService
 * @description opens icon selector dialog.
 * @property {function} showSelectIconDialog
 */
export default function sfeIconPickerDialogService($mdDialog) {
  /**
   * @memberof sfeIconPickerDialogService.showSelectIconDialog
   * @description opens icon selector dialog and resolves selected icon.
   * @param {object} currentlySelectedIcon selected icon obejct
   * @return {dataType}
   */
  function showSelectIconDialog(currentlySelectedIcon) {
    return $mdDialog.show({
      locals: { currentlySelectedIcon: currentlySelectedIcon },
      template,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      controller: 'selectIconController',
      controllerAs: 'vm'
    });
  }
  return { showSelectIconDialog: showSelectIconDialog };
}
