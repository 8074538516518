AssetAvailabilityEventConfiguration.$inject = [
  '$timeout',
  'gettext',
  'AlertingService',
  'TranslationService',
  'UserModel',
  'TangoAssetAvailabilityEventForm',
  'SfeForm2Dialog',
  'CrudToastFactory'
];

function AssetAvailabilityEventConfiguration(
  $timeout,
  gettext,
  AlertingService,
  TranslationService,
  UserModel,
  TangoAssetAvailabilityEventForm,
  SfeForm2Dialog,
  CrudToastFactory
) {
  /**
   * @description transforms api asset events to list item.
   * @function
   * @param {Object} item
   */
  async function Enrich(item) {
    await getUserName(item, 'responsiblePersonName', item.responsiblePerson);
    let eventType = TranslationService.GetCollectionById(
      'codelists.assetAvailabilityEventTypes',
      item.eventType
    );

    if (eventType != null) {
      item.eventTypeName = eventType.name;
    } else {
      item.fetchingObject['eventTypeName'] = {
        fetching: false,
        success: false,
        nodata: true
      };
    }
  }
  /**
   * @description gets user and sets list fetching object.
   * @function
   * @param {Object} item list row item
   * @param {String} fieldName
   * @param {String} id user id
   */
  async function getUserName(item, fieldName, id) {
    try {
      let { data } = await UserModel.read({ id });
      item[fieldName] = `${data.name || ''} ${data.family_name || ''}`;
      let idFieldName = fieldName.replace('Name', 'Id');
      item[idFieldName] = id;
    } catch (err) {
      item.fetchingObject[fieldName] = {
        fetching: false,
        success: false,
        nodata: true
      };
      AlertingService.Error(err);
    }
  }

  /**
   * @description opens create rated power object dialog and triggers updateAsset method.
   * @function
   * @param {Object} asset
   * @param {Object} chartApi
   * @param {function} relist triggers sfe-list reload
   */
  async function openCreateDialog(asset, chartApi, relist) {
    try {
      let config = TangoAssetAvailabilityEventForm.getForm(null, asset);
      const values = await SfeForm2Dialog.open(config);
      if (values != null) {
        CrudToastFactory.toast('create');
        $timeout(() => {
          relist();
          if (chartApi && typeof chartApi.refresh == 'function') {
            chartApi.refresh();
          }
        });
      }
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  /**
   * @description returns rated power sfe-list-2 configuration.
   * @function
   * @param {Object} asset
   * @param {Object} chartApi
   * @return {Object}
   */
  async function get(asset, chartApi) {
    const configuration = {
      listId: 'assetAvailabilityEvent',
      entity: {
        singular: gettext('Asset Availability Event'),
        plural: gettext('Asset Availability Event')
      },
      api: {
        query: {
          entity: 'asset-availability-events',
          method: 'list'
        },
        enrich: Enrich
      },
      buttons: [
        {
          title: gettext('Create'),
          fn: relist => openCreateDialog(asset, chartApi, relist),
          relist: true,
          color: 'accent'
        }
      ],
      attributes: [
        {
          title: gettext('Valid From'),
          param: 'validFrom',
          type: 'date'
        },
        {
          title: gettext('Valid To'),
          param: 'validTo',
          type: 'date'
        },
        {
          title: gettext('Value'),
          param: 'value',
          type: 'number'
        },
        {
          title: gettext('Event type'),
          param: 'eventTypeName'
        },
        {
          title: gettext('Responsible person'),
          param: 'responsiblePersonName',
          type: 'link',
          link: {
            state: 'users-users-view',
            params: [
              {
                valueParam: 'responsiblePersonId',
                queryParam: 'id'
              }
            ]
          }
        }
      ]
    };

    return configuration;
  }

  return { get };
}
export default AssetAvailabilityEventConfiguration;
