module.exports = {
  entity: 'tariffs',
  path: 'tariffs',
  name: {
    en: 'tariff',
    sl_SI: 'tarifa'
  },
  networkModel: {
    entity: 'tariffs',
    read: 'read'
  },
  scope: 'tis_be:tariffs',

  dialog: {
    title: {
      en: 'Select tariff',
      sl_SI: 'Izberite tarifo'
    },
    listInfo: {
      en: 'Select tariff from the list below',
      sl_SI: 'Izberite tarifo s spodnjega seznama'
    }
  }
}
