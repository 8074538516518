DatasetConfiguration.$inject = ['gettext', '$q'];

function DatasetConfiguration(gettext, $q) {
  var configuration = {
    listId: 'datasetId',
    entity: {
      singular: gettext('Dataset'),
      plural: gettext('Datasets')
    },
    api: {
      query: {
        entity: 'datasets',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'analytics-datasets-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'analytics-datasets-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'analytics-datasets-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'datasets',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Columns number'),
        param: 'columnNumber',
        type: 'text'
      }
    ],
    badges: [],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      }
    ],
    sort: [
      {
        display: 'name DESC',
        params: '-name'
      },
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    item.columnNumber = item.columns.length;

    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default DatasetConfiguration;
