import types from './calculated-flow.type';

function readCalculatedFlow(payload) {
  return {
    type: types.CALCULATED_FLOW_READ,
    payload
  };
}

function updateCalculatedFlow(payload) {
  return {
    type: types.CALCULATED_FLOW_UPDATE,
    payload
  };
}

function createCalculatedFlow(payload) {
  return {
    type: types.CALCULATED_FLOW_CREATE,
    payload
  };
}

function addCalculatedFlow(payload) {
  return {
    type: types.CALCULATED_FLOW_ADD,
    payload
  };
}

export default {
  readCalculatedFlow,
  updateCalculatedFlow,
  createCalculatedFlow,
  addCalculatedFlow
};
