module.exports = {
  entity: 'domain-attributes',
  path: 'domain-attributes',
  name: {
    en: 'Dynamic attributes',
    sl_SI: 'Dinamični atributi'
  },
  networkModel: {
    entity: 'domain-attributes',
    read: 'read'
  },
  scope: 'tis_be:domain-attributes',

  dialog: {
    title: {
      en: 'Select dynamic attribute',
      sl_SI: 'Izberite dinamični atribut'
    },
    listInfo: {
      en: 'Select dynamic attribute from the list below',
      sl_SI: 'Izberite dinamični atribut s spodnjega seznama'
    }
  }
}
