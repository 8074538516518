TimeSeriesLocationEntityDateConfiguration.$inject = [
  'gettext',
  '$q',
  'AlertingService',
  'Formatting',
  'CrudToastFactory',
  'TimeSeriesLocationModel',
  'TranslationService'
];

function TimeSeriesLocationEntityDateConfiguration(
  gettext,
  $q,
  AlertingService,
  Formatting,
  CrudToastFactory,
  TimeSeriesLocationModel,
  TranslationService
) {
  var createObject = {
    validFrom: new Date(new Date().setHours(0, 0, 0, 0)),
    validTo: null
  };

  var configuration = {
    listId: 'locations',
    type: 'multiple',
    entity: {
      title: gettext('Locations'),
      inactiveTitle: gettext('Inactive locations')
    },
    api: {
      query: {
        entity: 'time-series-locations',
        method: 'custom.readLocation'
      },
      activeFilterObject: {
        isActive: true
      },
      inactiveFilterObject: {
        isActive: false
      },
      enrich: Enrich
    },
    create: {
      form: getLocationFormConfig(),
      createObject: angular.copy(createObject),
      createFn: create
    },
    update: update,
    getUpdateObject: getUpdateObject,
    delete: function(item) {
      var deferred = $q.defer();
      TimeSeriesLocationModel.delete({
        id: item._id
      }).then(
        function() {
          CrudToastFactory.toast('delete');
          deferred.resolve();
        },
        function(err) {
          AlertingService.Error(err);
          deferred.reject();
        }
      );
      return deferred.promise;
    },
    title: {
      param: 'name',
      link: {
        state: 'company-resources-locations-view',
        params: [
          {
            valueParam: 'locationId',
            queryParam: 'id'
          }
        ]
      }
    },
    shortActions: [
      {
        type: 'edit',
        icon: 'mode_edit'
      },
      {
        icon: 'delete',
        type: 'delete'
      }
    ],
    badges: [
      {
        title: gettext('Code'),
        param: 'code',
        type: 'code'
      }
    ],
    attributes: [
      {
        title: gettext('Valid from'),
        param: 'validFromReadable',
        type: 'date-short'
      },
      {
        title: gettext('Valid to'),
        param: 'validToReadable',
        type: 'date-short'
      },
      {
        title: gettext('Main Cost Centre'),
        param: 'mainCostCentreName',
        link: {
          state: 'company-resources-cost-centres-view',
          params: [
            {
              valueParam: 'mainCostCentreId',
              queryParam: 'id'
            }
          ]
        },
        type: 'link'
      },
      {
        title: gettext('Address'),
        param: 'addressReadable',
        type: 'text'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function getLocationFormConfig() {
    return [
      {
        name: 'location',
        edit: true,
        componentType: 'autocompleteDialog',
        configuration: {
          query: {
            entity: 'locations',
            method: 'read'
          },
          floatingLabel: gettext('Select location'),
          searchParamName: 'filter',
          entity: 'locations',
          createRedirect: {
            state: 'company-resources-locations-new'
          },
          required: true
        }
      },
      {
        componentType: 'datePickerFromTo',
        from: 'validFrom',
        to: 'validTo',
        valToEmpty: true,
        labelFrom: gettext('Valid from *'),
        labelTo: gettext('Valid to'),
        required: true,
        onlyStartRequired: true,
        idFrom: 'from',
        idTo: 'to'
      }
    ];
  }

  function create(obj, paramObject) {
    var deferred = $q.defer();
    var apiObj = {
      validFrom: Formatting.formatDate(obj.validFrom, '00:00:00'),
      validTo: Formatting.formatDate(obj.validTo, '23:59:59'),
      location: obj.location._id
    };
    apiObj = Object.assign(apiObj, paramObject);
    TimeSeriesLocationModel.create(apiObj).then(
      function() {
        CrudToastFactory.toast('create');
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );
    return deferred.promise;
  }

  function update(obj, originalItem, paramObject) {
    var deferred = $q.defer();
    var apiObj = {
      validFrom: Formatting.formatDate(obj.validFrom, '00:00:00'),
      validTo: Formatting.formatDate(obj.validTo, '23:59:59') || null,
      location: obj.location._id
    };
    apiObj = Object.assign(apiObj, paramObject);
    TimeSeriesLocationModel.update(
      {
        id: originalItem._id
      },
      apiObj
    ).then(
      function() {
        CrudToastFactory.toast('update');
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );
    return deferred.promise;
  }

  function getUpdateObject(item) {
    var validFrom = new Date(item.validFrom);
    var validTo =
      item.validTo && new Date(item.validTo) instanceof Date
        ? new Date(item.validTo)
        : null;
    return {
      validFrom: validFrom,
      validTo: validTo,
      location: item.location
    };
  }

  function setFetching(item, what) {
    item.fetchingObject[what] = {
      fetching: true,
      success: false,
      nodata: false
    };
  }

  function setNodata(item, what) {
    item.fetchingObject[what] = {
      fetching: false,
      success: true,
      nodata: true
    };
  }

  function setSuccess(item, what) {
    item.fetchingObject[what] = {
      fetching: false,
      success: true,
      nodata: false
    };
  }

  function Enrich(item) {
    var deferred = $q.defer();
    setFetching(item, 'validFromReadable');
    setFetching(item, 'validToReadable');
    setFetching(item, 'name');
    setFetching(item, 'code');
    setFetching(item, 'mainCostCentre');
    setFetching(item, 'addressReadable');

    if (item.location) {
      var location = item.location;
      item.locationId = item.location._id;
      if (
        item.hasOwnProperty('validFrom') &&
        item.validFrom !== null &&
        item.validFrom !== undefined
      ) {
        item.validFromReadable = item.validFrom;
      } else {
        setNodata(item, 'validFromReadable');
      }

      if (
        item.hasOwnProperty('validTo') &&
        item.validTo !== null &&
        item.validTo !== undefined
      ) {
        item.validToReadable = item.validTo;
      } else {
        setNodata(item, 'validToReadable');
      }
      item.name = location.name;
      item.code = location.code;
      setSuccess(item, 'validFromReadable');
      setSuccess(item, 'validToReadable');
      setSuccess(item, 'name');
      setSuccess(item, 'code');
      if (location.hasOwnProperty('address') && location.address !== null) {
        item.addressReadable = location.address.streetName
          ? location.address.streetName
          : '';
        item.addressReadable += location.address.houseNumber
          ? ' ' + location.address.houseNumber
          : '';
        item.addressReadable += location.address.settlementName
          ? ', ' + location.address.settlementName
          : '';
        readCountry(item);
      } else {
        setNodata(item, 'addressReadable');
      }

      if (
        location.mainCostCentre &&
        location.mainCostCentre[0] &&
        location.mainCostCentre[0].costCentre
      ) {
        item.mainCostCentreId = location.mainCostCentre[0].costCentre._id;
        item.mainCostCentreName =
          location.mainCostCentre[0].costCentre.name +
          ' (' +
          location.mainCostCentre[0].costCentre.code +
          ')';
        setSuccess(item, 'mainCostCentreName');
      } else {
        setNodata(item, 'mainCostCentreName');
      }
    } else {
      setNodata(item, 'mainCostCentreName');
      setNodata(item, 'addressReadable');
      setNodata(item, 'validFromReadable');
      setNodata(item, 'validToReadable');
      setNodata(item, 'name');
      setNodata(item, 'code');
    }

    return deferred.promise;
  }

  function readCountry(item) {
    if (item.location.address.country) {
      var country = TranslationService.GetCollectionById(
        'codelists.countries',
        item.location.address.country
      );
      if (country) {
        item.location.addressReadable =
          item.location.addressReadable +
          (country.name ? ', ' + country.name : '');
      }
      setSuccess(item, 'addressReadable');
    } else {
      if (item.addressReadable) {
        setSuccess(item, 'addressReadable');
      } else {
        setNodata(item, 'addressReadable');
      }
    }
  }

  return configuration;
}
export default TimeSeriesLocationEntityDateConfiguration;
