/**
 * @ngdoc service
 * @name common.filterService
 * @description Service that filters arrays based on array of filters.
 * @property {function} filterData - see filterData method
 * @property {function} filterDataV1 - see  filterDataV1 method
 */
export default function filterService() {
  /**
   * @memberof common.filterService
   * @description Filters an array of string so that only the terms found in the filters property can be returned.
   * @param {array} items - Items that need to be filtered.
   * @param {array} filters - Array of values by which the items will be filtered.
   * @return {array} A filtered array.
   */
  function filterData(items, filters) {
    var list = items;
    //array of search terms
    var searchTerms = [];
    var termFound;
    var filteredArray;
    var entryValue;
    var entryKeys;
    var i;
    if (filters) {
      for (i = 0; i < filters.length; i++) {
        if (filters[i].searchTerm) {
          searchTerms.push(filters[i]);
        }
      }
    }
    if (searchTerms.length) {
      for (i = 0; i < searchTerms.length; i++) {
        filteredArray = list.filter(function(entry) {
          termFound = false;
          entryKeys = Object.keys(entry);
          for (var j = 0; j < entryKeys.length; j++) {
            if (entryKeys[j] === searchTerms[i].param) {
              entryValue = entry[entryKeys[j]];
              if (
                entryValue &&
                entryValue
                  .toLowerCase()
                  .indexOf(searchTerms[i].searchTerm.toLowerCase()) > -1
              ) {
                termFound = true;
                break;
              }
            }
          }
          return termFound;
        });
        list = filteredArray;
      }
    }
    return list;
  }
  /**
   * @memberof common.filterService
   * @description Filters an array of string so that only the terms found in the filters property can be returned
   * and then checks if the values are unique based on a special criterion
   * @param {array} items - Items that need to be filtered.
   * @param {array} filters - Array of values by which the items will be filtered.
   * @param {string} uniqParam - Criterion by which uniqueness is computed
   * @return {Array} A filtered array.
   */
  function filterDataV1(items, filters, uniqParam) {
    var filteredItems = [];
    var oneFilterSet;
    if (filters) {
      filters.forEach(function(filter) {
        if (typeof filter.searchTerm !== 'undefined' && filter.searchTerm) {
          var res = items.filter(function(item) {
            if (item && item[filter.param]) {
              return (
                item[filter.param]
                  .toLowerCase()
                  .indexOf(filter.searchTerm.toLowerCase()) > -1
              );
            } else {
              return false;
            }
          });
          filteredItems = filteredItems.concat(res);
          oneFilterSet = true;
        }
      });
      if (!oneFilterSet) {
        return items;
      } else {
        return _.uniqBy(filteredItems, uniqParam);
      }
    } else {
      return items;
    }
  }
  return {
    filterData: filterData,
    filterDataV1: filterDataV1
  };
}
