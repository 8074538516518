DatabaseController.$inject = [
  '$stateParams',
  'gettext',
  'MetadataService',
  'TangoAgentConnectionConfigConfiguration',
  'gettextCatalog',
  'TranslationService',
  'database',
  '$timeout'
];

function DatabaseController(
  $stateParams,
  gettext,
  MetadataService,
  TangoAgentConnectionConfigConfiguration,
  gettextCatalog,
  TranslationService,
  database,
  $timeout
) {
  var vm = this;
  var databaseId = $stateParams.id;
  vm.configuration = TangoAgentConnectionConfigConfiguration.getConfigurationWithUpdatedStates();
  vm.entity = 'datasources';

  vm.$onInit = function init() {
    MetadataService.Loading(true);
    vm.loading = true;
    vm.dataPointsFilter = {
      connector: databaseId
    };
    database.type = TranslationService.GetCollectionById(
      'codelists.databaseTypes',
      database.type
    );
    vm.headerData = constructHeader(database);
    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(database.name, database.description);
      vm.loading = false;
    });
  };

  function constructHeader(database) {
    var properties = [
      {
        title: gettext('Database type'),
        value: format`${database.type.name} (${gettextCatalog.getString(
          database.type.description
        )})`,
        type: 'simple'
      },
      {
        title: gettext('URL'),
        value: database.url,
        type: 'raw'
      }
    ];
    var databaseProperties = [
      {
        title: gettext('Database name'),
        value: database.database,
        type: 'raw'
      },
      {
        title: gettext('Read Query'),
        value: database.query,
        type: 'raw'
      },
      {
        title: gettext('Write Query'),
        value: database.writeQuery || gettextCatalog.getString('None'),
        type: 'raw'
      },
      {
        title: gettext('Browse Query'),
        value: database.browseQuery || gettextCatalog.getString('None'),
        type: 'raw'
      }
    ];
    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];
    var actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: databaseId,
        endpoint: {
          entity: 'databases',
          method: 'delete'
        },
        redirectState: 'external-datasources-connectors-list'
      },
      {
        title: gettext('Update'),
        state: 'external-datasources-connectors-database-edit',
        param: 'id',
        paramValue: database._id
      }
    ];

    var propertySections = [
      {
        title: gettext('connection'),
        properties: properties
      },
      {
        title: gettext('database'),
        properties: databaseProperties
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'databases',
        target: databaseId,
        type: 'userAccess'
      }
    ];

    return {
      metadata: {
        definition: gettext('Database connection configuration'),
        title: database.name,
        description: database.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }

  function format(strings, ...values) {
    if (values[1]) {
      return strings.reduce(
        (finalString, currentString, i) =>
          finalString + currentString + (values[i] || ''),
        ''
      );
    }
    return values[0];
  }
}

export default DatabaseController;
