import './billingOverview.dialog.scss';
import template from './billingOverview.dialog.html';

/**
 * @ngdoc factory
 * @name common.billingOverview
 * @description Used for opening of a dialog which mostly just displays critical info. Can have custom functions.
 * @property {function} open - Function that triggers the opening of the dialog
 */

BillingOverviewDialog.$inject = ['$mdDialog'];

export default function BillingOverviewDialog($mdDialog) {
  /**
   * @description Triggers the opening of the dialog.
   * @function
   * @memberof billingOverview
   */
  function open(billingItemDetail) {
    $mdDialog
      .show({
        template,
        controller: [
          'gettext',
          '$state',
          'billingItemDetail',
          billingOverviewController
        ],
        controllerAs: 'vm',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        locals: {
          billingItemDetail
        }
      })
      .then(
        function() {},
        function() {}
      );
  }
  /**
   * @description Dialog controller.
   * @function
   */
  function billingOverviewController(gettext, $state, billingItemDetail) {
    var vm = this;
    vm.data = billingItemDetail;
    vm.dialogHeader = {
      title: gettext('Business partner billing item detail'),
      isDialog: true,
      actions: [
        {
          icon: {
            name: 'close',
            type: 2
          },
          fn: function() {
            $mdDialog.cancel();
          }
        }
      ]
    };
    vm.getStakeholderUrl = () => {
      return $state.href(billingItemDetail.stakeholderName, {
        id: billingItemDetail.stakeholderId
      });
    };
    vm.cancel = function() {
      $mdDialog.cancel();
    };
    vm.close = function() {
      $mdDialog.hide();
    };
  }
  return {
    open: open
  };
}
