locationTypeConfiguration.$inject = ['gettext', '$q'];

function locationTypeConfiguration(gettext, $q) {
  var configuration = {
    entity: {
      singular: gettext('Location Type'),
      plural: gettext('Location Types')
    },
    api: {
      query: {
        entity: 'location-types',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'configurations-company-resources-locations-location-types-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'configurations-company-resources-locations-location-types-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state:
            'configurations-company-resources-locations-location-types-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'location-types',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description')
      },
      {
        param: 'code',
        title: gettext('Code'),
        type: 'code'
      },
      {
        param: 'externalCode',
        title: gettext('External code'),
        type: 'code'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Code'),
        param: 'code',
        type: 'string'
      },
      {
        display: gettext('External Code'),
        param: 'externalCode',
        type: 'string'
      }
    ],
    badges: [
      {
        param: 'isExternal',
        title: gettext('External'),
        type: 'goodOrBad',
        good: gettext('External'),
        bad: gettext('Internal')
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich() {
    var deferred = $q.defer();
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default locationTypeConfiguration;
