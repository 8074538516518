AddressAutoCompleteService.$inject = [
  '$q',
  'DomainModel',
  'DomainCollectionModel',
  'gettextCatalog'
];
/**
 * @ngdoc service
 * @name companyResources.AddressAutoCompleteService
 * @description address autocomplete helper methods.
 * @property {function} getCommunities
 * @property {function} getCommunity
 * @property {function} getSettlements
 * @property {function} getSettlement
 * @property {function} getStreets
 * @property {function} getHouseNumbers
 * @property {function} getPosts
 * @property {function} getAddressByCoordinates
 * @property {function} getCoordinatesFromAddress
 */
export default function AddressAutoCompleteService(
  $q,
  DomainModel,
  DomainCollectionModel,
  gettextCatalog
) {
  var domain;
  /**
   * @description return s domain with the name GURS Address .
   * @function
   * @return {Promise}
   */
  function getDomain() {
    var pr = $q.defer();
    if (domain) {
      pr.resolve(domain);
    } else {
      DomainModel.read().then(function(domains) {
        domain = _.find(domains.data, ['name', 'GURS Address']);
        pr.resolve(domain);
      });
    }
    return pr.promise;
  }
  /**
   * @description executes fetch domain and domain collection query.
   * @function
   * @param {object} filters object of filters
   * @return {Promise}
   */
  function query(filters) {
    var pr = $q.defer();
    getDomain().then(function(domain) {
      if (domain) {
        var queryFilter = {
          domainId: domain._id,
          limit: 'all',
          order: 'naz'
        };
        if (filters) {
          queryFilter = Object.assign(queryFilter, filters);
        }
        DomainCollectionModel.custom.list(queryFilter).then(
          function(entries) {
            pr.resolve(entries.data);
          },
          function(err) {
            pr.reject(err);
          }
        );
      } else {
        pr.resolve([]);
      }
    });
    return pr.promise;
  }
  /**
   * @memberof companyResources.getCommunities
   * @description resolves to filtered comunity items.
   * @param {string} search search string
   * @return {Promise}
   */
  function getCommunities(search) {
    var filters = { tip: 'OBČ' };
    if (search) {
      filters.naz = '{start}' + search;
    }
    return query(filters);
  }
  /**
   * @memberof companyResources.getCommunity
   * @description resolves to filtered comunity items.
   * @param {string} sif1 search string
   * @return {Promise}
   */
  function getCommunity(sif1) {
    var filters = {
      tip: 'OBČ',
      sif1: '{start, cs}' + sif1
    };
    return query(filters);
  }
  /**
   * @memberof companyResources.getSettlements
   * @description resolves to filtered settlement items.
   * @param {string} search search string
   * @param {string} communityCode communityCode
   * @return {Promise}
   */
  function getSettlements(search, communityCode) {
    var filters = {
      tip: 'NAS'
    };
    if (search) {
      filters.naz = '{start}' + search;
    }
    if (communityCode) {
      filters.sif1 = '{start, cs}' + communityCode;
    }
    return query(filters);
  }
  /**
   * @memberof companyResources.getSettlement
   * @description resolves to filtered settlement items.
   * @param {string} sif1 search code string
   * @param {string} sif2 search code string
   * @return {Promise}
   */
  function getSettlement(sif1, sif2) {
    var filter = {
      tip: 'NAS',
      sif1: sif1,
      sif2: sif2
    };
    return query(filter);
  }
  /**
   * @memberof companyResources.getStreets
   * @description resolves to filtered street items.
   * @param {string} search search code string
   * @param {string} communityCode communityCode string
   * @param {string} settlementCode settlementCode string
   * @return {Promise}
   */
  function getStreets(search, communityCode, settlementCode) {
    var filters = {
      tip: 'ULC'
    };
    if (search) {
      filters.naz = '{start}' + search;
    }
    if (communityCode) {
      filters.sif1 = '{start, cs}' + communityCode;
    }
    if (settlementCode) {
      filters.sif2 = '{start, cs}' + settlementCode;
    }
    return query(filters);
  }
  /**
   * @memberof companyResources.getHouseNumbers
   * @description resolves to available according to address house numbers.
   * @param {string} search search code string
   * @param {string} sif1 search string
   * @param {string} sif2 search string
   * @param {string} sif3 search string
   * @return {Promise}
   */
  function getHouseNumbers(search, sif1, sif2, sif3) {
    var filters = {
      tip: 'HŠT'
    };
    if (search) {
      filters.naz = '{start}' + search;
    }
    if (sif1) {
      filters.sif1 = '{start, cs}' + sif1;
    }
    if (sif2) {
      filters.sif2 = '{start, cs}' + sif2;
    }
    if (sif3) {
      filters.sif3 = '{start, cs}' + sif3;
    }
    return query(filters);
  }
  /**
   * @memberof companyResources.getPosts
   * @description resolves to filtered settlement items.
   * @param {string} postnumber postnumber
   * @param {string} postname postname
   * @return {Promise}
   */
  function getPosts(postnumber, postname) {
    var filters = {
      tip: 'ptt'
    };
    if (postnumber) {
      filters.sif1 = '{start, cs}' + postnumber;
    }
    if (postname) {
      filters.naz = '{start}' + postname;
    }
    return query(filters);
  }

  function constructGoogleApiAddress(addressResult) {
    const endAddress = addressResult.reduce((result, address) => {
      if (
        Array.isArray(address.address_components) &&
        address.address_components.length > 0
      ) {
        const addressFromComponent = address.address_components.reduce(
          (addressObject, addressComponent) => {
            if (Array.isArray(addressComponent.types)) {
              const addressFromTypes = addressComponent.types.reduce(
                (addressFromTypeObject, addressType) => {
                  var parameterName;
                  switch (addressType) {
                  case 'street_number':
                    parameterName = 'streetNumber';
                    break;
                  case 'route':
                    parameterName = 'route';
                    break;
                  case 'postal_town':
                    parameterName = 'postalTown';
                    break;
                  case 'postal_code':
                    parameterName = 'postalCode';
                    break;
                  case 'locality':
                    parameterName = 'locality';
                    break;
                  case 'country':
                    return {
                      ...addressFromTypeObject,
                      googleApiCountry: addressComponent.long_name,
                      googleApiCountryShortName: addressComponent.short_name
                    };
                  }

                  if (parameterName && addressComponent.long_name) {
                    return {
                      [parameterName]: addressComponent.long_name,
                      ...addressFromTypeObject
                    };
                  } else {
                    return addressFromTypeObject;
                  }
                },
                {}
              );
              return {
                ...addressObject,
                ...addressFromTypes
              };
            } else {
              return addressObject;
            }
          },
          {}
        );
        return {
          ...addressFromComponent,
          ...result
        };
      } else {
        return result;
      }
    }, {});
    const addressNullObject = {
      streetNumber: null,
      route: null,
      postalTown: null,
      postalCode: null,
      locality: null
    };
    return {
      ...addressNullObject,
      ...endAddress
    };
  }

  async function getAddressByCoordinates(latLng) {
    try {
      const addresses = await geocodeAddress({ location: latLng });
      if (Array.isArray(addresses)) {
        const addressCandidate = constructGoogleApiAddress(addresses);
        let address = {};
        if (addressCandidate.route) {
          address.streetName = addressCandidate.route.toUpperCase();
        }
        if (addressCandidate.streetNumber) {
          address.houseNumber = addressCandidate.streetNumber;
        }

        if (addressCandidate.postalTown) {
          address.postName = addressCandidate.postalTown.toUpperCase();
        } else if (addressCandidate.locality) {
          address.postName = addressCandidate.locality.toUpperCase();
        }

        if (addressCandidate.postalCode) {
          address.postalNumber = addressCandidate.postalCode;
        }

        if (addressCandidate.locality) {
          address.locality = addressCandidate.locality;
        }

        address.googleApiCountry = addressCandidate.googleApiCountry;
        address.googleApiCountryShortName =
          addressCandidate.googleApiCountryShortName;

        return address;
      } else {
        throw gettextCatalog.getString('No results');
      }
    } catch (err) {
      throw err;
    }
  }
  /**
   * @memberof AddressAutoCompleteService.getCoordinatesFromAddress
   * @description Gets coodinates from  address.
   * @param {object} address address object
   * @return {Promise}
   */

  async function getCoordinatesFromAddress(obj) {
    let address;
    if (obj.postalNumber) {
      address =
        obj.streetName +
        ' ' +
        obj.houseNumber +
        ', ' +
        obj.postalNumber +
        ' ' +
        obj.postName;
    } else {
      address = obj.streetName + ' ' + obj.houseNumber + ', ' + obj.postName;
    }
    try {
      const results = await geocodeAddress({ address });
      let geolocation;
      if (Array.isArray(results)) {
        geolocation = {
          x: results[0].geometry.location.lng(),
          y: results[0].geometry.location.lat()
        };
      }
      return geolocation;
    } catch (err) {
      throw err;
    }
  }

  function geocodeAddress(params) {
    const Geocoder = new google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      Geocoder.geocode(params, (result, status) => {
        if (status == 'OK') {
          resolve(result);
        } else {
          reject(
            gettextCatalog.getString(
              'There was an error for the following reason: {{status}}',
              { status }
            )
          );
        }
      });
    });
  }

  return {
    getCommunities: getCommunities,
    getCommunity: getCommunity,
    getSettlements: getSettlements,
    getSettlement: getSettlement,
    getStreets: getStreets,
    getHouseNumbers: getHouseNumbers,
    getPosts: getPosts,
    getAddressByCoordinates: getAddressByCoordinates,
    getCoordinatesFromAddress
  };
}
