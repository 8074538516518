GisAuthInterceptor.$inject = ['$q', '$injector', 'gettext'];
/**
 * @ngdoc service
 * @name core.GisAuthInterceptor
 * @description Interceptor that adds the stored gis token to requests.
 * @property {object} anonymousObject interceptor object
 */

function GisAuthInterceptor($q, $injector, gettext) {
  return {
    request: function(config) {
      var GisAuth = $injector.get('GisAuth');
      config = config || {};
      if (config.gisAuth) {
        var token = GisAuth.getToken();
        config.headers.Authorization = token;
        config.headers.language = undefined;
      }
      return config;
    },
    responseError: function(rejection) {
      var GisAuth = $injector.get('GisAuth');
      if (
        rejection.config &&
        rejection.config.gisAuth &&
        GisAuth.getToken() &&
        rejection.status === 401
      ) {
        GisAuth.clearToken();
        GisAuth.resetToken().then(
          function() {
            var $http = $injector.get('$http');
            return $http(rejection.config);
          },
          function() {
            var AlertingService = $injector.get('AlertingService');
            AlertingService.Error(gettext('Couldn\'t reset gis token'));
          }
        );
      }
      return $q.reject(rejection);
    }
  };
}

export default GisAuthInterceptor;
