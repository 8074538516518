mdDialogConfig.$inject = ['$provide'];

function mdDialogConfig($provide) {
  decorator.$inject = ['$delegate'];

  function decorator($delegate) {
    // Get a handle of the show method
    var methodHandle = $delegate.show;
    function decorateDialogShow() {
      var args = angular.extend({}, arguments[0], { multiple: true });
      return methodHandle(args);
    }
    $delegate.show = decorateDialogShow;
    return $delegate;
  }

  $provide.decorator('$mdDialog', decorator);
}

export default mdDialogConfig;
