EnergySourceTypeModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function EnergySourceTypeModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'energy-source-types'
  };

  const networkConfigurationAllLang = {
    host: 'api',
    path: 'energy-source-types',
    allLang: 'true'
  };

  const formConfiguration = {
    entity: 'energy-source-types',
    method: 'custom.readAllLang',
    populate: 'parentId'
  };

  const crawlerConfiguration = {
    entity: 'energy-source-types',
    method: 'read',
    steps: [
      {
        entity: 'energy-source-types',
        valueParams: ['parentId'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'addArray',
        newParamNames: ['parent']
      }
    ]
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readAllLang: ModelUtilities.crud(
        'GET',
        networkConfigurationAllLang,
        modelStorage
      ),
      readItemView: CrawlerMethods.crawler(crawlerConfiguration),
      readForm: CrawlerMethods.crawler(formConfiguration)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default EnergySourceTypeModel;
