import './sfe-dnd-mapping-rules.scss';
import template from './sfe-dnd-mapping-rules.component.html';
import { DateTime } from 'luxon';

/**
 * @ngdoc component
 * @name alarms-and-rules.sfeDndMappingRules
 * @description Displays mapping rule and enables their editing on the item view form.
 * @param {string} mapperId - Used for updating
 * @param {string} mapperType - literal(regex), formal, default
 * @param {Object} mapperDataType - Containing info about the chosen data type of the mapper (Decimal, integer, date...)
 * @param {Array} mappingRules - Array of mapping rules of a certain mapperType
 * @example
 * <md-card class="MB-24 P-16">
 *   <sfe-dnd-mapping-rules
 *     mapper-id="vm.mapper._id"
 *     mapper-type="literal"
 *     mapper-data-type="vm.mapperDataType"
 *     mapping-rules="vm.mapper.literalMappings"
 *   >
 *   </sfe-dnd-mapping-rules>
 * </md-card>
 */
export default {
  restrict: 'E',
  template,
  bindings: {
    mapperId: '<',
    mapperType: '@',
    mapperDataType: '<',
    mappingRules: '='
  },
  controller: sfeDndMappingRulesController,
  controllerAs: 'vm',
  bindToController: true
};

sfeDndMappingRulesController.$inject = [
  'TranslationService',
  '$timeout',
  'MappingModel',
  'AlarmModel',
  'AlertingService',
  'CrudToastFactory',
  'InfoDialog',
  '$q',
  'gettextCatalog',
  'gettext',
  '$filter',
  '$mdDialog'
];

function sfeDndMappingRulesController(
  TranslationService,
  $timeout,
  MappingModel,
  AlarmModel,
  AlertingService,
  CrudToastFactory,
  InfoDialog,
  $q,
  gettextCatalog,
  gettext,
  $filter,
  $mdDialog
) {
  var vm = this;
  vm.checkForArgument = checkForArgument;
  vm.moveRule = moveRule;
  vm.dateLabel = gettextCatalog.getString('Date');
  var dataTypeMappingFunctions = TranslationService.GetCollection(
    'codelists.dataTypeMappingFunctions'
  );
  // 1: boolean, 2: integer, 3: decimal, 4: string, 5: date
  var dataTypes = TranslationService.GetCollection('codelists.dataTypes');

  /**
   * @description Initializes the data.
   * @function
   */
  vm.$onInit = function init() {
    vm.alarmMenuConfig = {
      label: gettextCatalog.getString('Alarm'),
      query: {
        entity: 'alarms',
        method: 'read'
      },
      name: 'alarm',
      required: true,
      floatingLabel: gettextCatalog.getString('Select alarm'),
      disabled: false,
      displayFields: ['name'],
      noDialog: false,
      entity: 'alarms',
      searchParamName: 'filter',
      filterObject: {
        order: 'name'
      },
      selectedParam: 'alarms'
    };
    vm.dateTimePickerFormat = {
      viewDateFormat: 'dd.MM.yyyy',
      dateTimeFormat: 'yyyy-MM-dd HH:mm:ss',
      dateFormat: 'yyyy-MM-dd'
    };
    vm.datePickerFormat = {
      viewDateFormat: 'dd.MM.yyyy',
      dateTimeFormat: 'yyyy-MM-dd',
      dateFormat: 'yyyy-MM-dd'
    };
    vm.dateTimePickerConfig = {
      dateLabel: gettextCatalog.getString('Date'),
      timeLabel: gettextCatalog.getString('Time'),
      required: true
    };
    switch (vm.mapperType) {
    case 'literal':
      vm.listTitle = gettextCatalog.getString(
        'Regular Expression Mapping Rule'
      );
      break;
    case 'format':
      vm.listTitle = gettextCatalog.getString('Format mapping rules');
      vm.formatMenuConfig = {
        placeholder: gettextCatalog.getString('Data point format'),
        single: true,
        ctrlFn: function() {
          return $timeout(function() {
            return dataTypes;
          });
        },
        valueField: 'id',
        displayOptions: ['name'],
        empty: gettextCatalog.getString('There are no formats to select.')
      };
      break;
    case 'default':
      vm.listTitle = gettextCatalog.getString('Default mapping rule');
      break;
    default:
      vm.listTitle = gettextCatalog.getString('Rules');
    }

    setMetaData();
  };

  /**
   * @description Configures the functions options to be displayed at a certain index of mapping rules.
   * @function
   * @param {number} index Index to where the function options should be configured
   */
  vm.reloadFun = function reloadFun(index) {
    return $timeout(function() {
      vm.mappingRules[index].functionOptions = [];
      dataTypeMappingFunctions.forEach(function(mappingFunction) {
        if (
          mappingFunction.validOutputDatatypes.includes(vm.mapperDataType.id) &&
          (vm.mappingRules[index].inputDataTypeObject
            ? mappingFunction.validInputDatatypes.indexOf(
              vm.mappingRules[index].inputDataTypeObject._id
            ) > -1
            : true)
        ) {
          vm.mappingRules[index].functionOptions.push(mappingFunction);
        }
      });

      vm.mappingRules[index].functionOptions = vm.mappingRules[
        index
      ].functionOptions.filter(filterMapperTypeAllowed);
    });
  };
  /**
   * @description filters functions according to allowed mapper type functions.
   * @function
   * @param {Object} item function codelist item
   * @return {Boolean}
   */
  function filterMapperTypeAllowed(item) {
    const mappers = TranslationService.GetCollection('codelists.mappingTypes');
    let validFunctions = [];

    switch (vm.mapperType) {
    case 'literal':
      validFunctions = mappers.find(mapperItem => mapperItem.id == 2)
        .validFunctions;
      break;
    case 'format':
      validFunctions = mappers.find(mapperItem => mapperItem.id == 3)
        .validFunctions;
      break;
    case 'default':
      validFunctions = mappers.find(mapperItem => mapperItem.id == 1)
        .validFunctions;
      break;
    }
    return validFunctions.indexOf(item.id) > -1;
  }

  /**
   * @description Sets the value of the expression to be displayed.
   * @function
   * @param {Object} expression Expression to be parsed
   */
  vm.parseExpression = function parseExpression(expression) {
    var selectedFunction = dataTypeMappingFunctions.find(function(
      mappingFunction
    ) {
      return mappingFunction.id == expression.function;
    });
    if (selectedFunction) {
      if (
        selectedFunction.hasArguments &&
        selectedFunction.arguments[0] != 'value'
      ) {
        return (
          selectedFunction.name +
          '(' +
          (expression.args && expression.args[0]
            ? expression.args[0].value
            : '') +
          ')'
        );
      } else if (
        selectedFunction.hasArguments &&
        selectedFunction.arguments[0] == 'value'
      ) {
        var value;
        if (expression.args && expression.args[0]) {
          // if data type is Date
          switch (vm.mapperDataType.id) {
          case 7: //DATE TIME
            value = DateTime.fromJSDate(
              new Date(expression.args[0].value)
            ).toFormat('dd. MM. yyyy HH:mm:ss');
            break;
          case 5: //DATE
            value = DateTime.fromJSDate(
              new Date(expression.args[0].value)
            ).toFormat('dd. MM. yyyy');
            break;
          case 2: //INTEGER
          case 3: //DECIMAL
            value = $filter('numberFormat')(expression.args[0].value);
            break;
          default:
            value = expression.args[0].value;
          }
        }
        return value;
      } else {
        return selectedFunction.name + '()';
      }
    }
  };

  /**
   * @description Deletes the item from the list of mapping rules.
   * @function
   * @param {number} index Index of the item to be removed
   */
  vm.removeItem = function removeItem(index) {
    var title = gettextCatalog.getString('Remove mapping rule');
    var textItem = {
      text: gettextCatalog.getString(
        'Are you sure you want to remove this mapping rule?'
      ),
      type: 'text'
    };
    var actions = [
      {
        title: gettext('Cancel'),
        cancel: true,
        color: 'primary'
      },
      {
        title: gettext('Yes'),
        fn: remove,
        color: 'success'
      }
    ];
    InfoDialog.open(title, null, [textItem], actions);

    function remove() {
      $mdDialog.cancel();
      if (vm.mappingRules[index]._id) {
        MappingModel.delete({
          id: vm.mappingRules[index]._id
        }).then(
          function() {
            CrudToastFactory.toast('delete');
            if (index !== vm.mappingRules.length - 1) {
              moveRule();
            }
            vm.mappingRules.splice(index, 1);
          },
          function(err) {
            AlertingService.Error(err);
          }
        );
      } else {
        if (index !== vm.mappingRules.length - 1) {
          moveRule();
        }
        vm.mappingRules.splice(index, 1);
      }
    }
  };

  /**
   * @description Displays the form for adding a mapping rule.
   * @function
   */
  vm.addRule = function addRule() {
    if (!vm.mappingRules) {
      vm.mappingRules = [];
    }
    vm.mappingRules.push({
      editMode: true,
      isManualIntervention: false,
      mapperType: vm.mapperType,
      outputExpression: {
        args: []
      }
    });
  };

  /**
   * @description Triggers the editing mode of the selected item.
   * @function
   * @param {number} index Index of the selected item.
   */
  vm.edit = function edit(index) {
    vm.mappingRules[index].editMode = !vm.mappingRules[index].editMode;
    vm.mappingRules[index].functionOptions = dataTypeMappingFunctions;
    vm.mappingRules[index].formats = dataTypes;
    vm.mappingRules[index].objectCopy = angular.copy(vm.mappingRules[index]); // this is done because we do not wish to overwrite data if we cancel the edit
    if (!vm.mappingRules[index].triggersAlarm) {
      vm.mappingRules[index].alarm = null;
      vm.mappingRules[index].alarmObject = null;
      vm.mappingRules[index].alarmName = null;
    }
    const args = vm.mappingRules[index].outputExpression.args;
    if (!Array.isArray(args) || (Array.isArray(args) && args.length === 0)) {
      vm.mappingRules[index].outputExpression.args = [{}];
    }
    vm.mappingRules[index].inputExpression = $filter('mapperRegex')(
      vm.mappingRules[index].inputExpression
    );
    checkForArgument(index, true);
  };

  /**
   * @description Stops the editing mode of the selected item.
   * @function
   * @param {number} index Index of the selected item.
   */
  vm.cancelEditing = function cancelEditing(index) {
    vm.mappingRules[index] = vm.mappingRules[index].objectCopy;
    vm.mappingRules[index].editMode = false;
  };

  /**
   * @description Saves the edited form of selected item.
   * @function
   * @param {number} index Index of the selected item.
   */
  vm.save = function save(index) {
    var ruleObj = getApiObject(index);
    if (vm.mappingRules[index]._id) {
      updateMappingRule(index, ruleObj);
    } else {
      MappingModel.create(ruleObj).then(
        function(res) {
          CrudToastFactory.toast('create');
          refreshMappingRule(index, res.data);
        },
        function(err) {
          AlertingService.Error(err);
        }
      );
    }
  };

  /**
   * @description Reorders the rules.
   * @function
   */
  function moveRule() {
    var promises = [];
    var apiObj;
    $timeout(function() {
      _.each(vm.mappingRules, function(rule, index) {
        if (rule._id) {
          apiObj = getApiObject(index);
          promises.push(
            MappingModel.update(
              {
                id: vm.mappingRules[index]._id
              },
              apiObj
            )
          );
        }
      });
      $q.all(promises).then(function() {
        CrudToastFactory.toast('update');
      });
    });
  }

  /**
   * @description Sets metadata of the mapping rule (alarms, arguments, format mappings).
   * @function
   */
  function setMetaData() {
    if (vm.mappingRules) {
      vm.mappingRules.forEach(function(rule) {
        if (rule.alarm && rule.triggersAlarm) {
          fetchAlarm(rule);
        } else {
          // if previously the rule had an alarm
          rule.alarm = null;
        }
        if (vm.mapperType == 'format') {
          constructFormatMappingRules(rule);
        }
        rule.mapperType = vm.mapperType;
        setArgumentName(rule);
      });
    }
  }

  /**
   * @description Fetches the alarm for the mapping rule (used in setMetaData) and sets the fetched data.
   * @function
   * @param {Object} rule Contains info of the alarm to be fetched and is used for metadata setting
   */
  function fetchAlarm(rule) {
    AlarmModel.read({
      id: rule.alarm
    }).then(
      function(res) {
        rule.alarmName = res.data.name;
        rule.alarmObject = res.data;
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  /**
   * @description Used for saving edited/new data.
   * @function
   * @param {number} index Index of the item to be updated
   */
  function getApiObject(index) {
    var value =
      vm.mappingRules[index].outputExpression.args &&
      vm.mappingRules[index].outputExpression.args.length
        ? vm.mappingRules[index].outputExpression.args[0].value
        : null;
    if (
      vm.mappingRules[index].outputExpression.function === 2 &&
      vm.mapperDataType.name == 'Name'
    ) {
      value = DateTime.fromFormat(value, 'dd.MM.yyyy').toMillis();
    }
    let ruleObj = {
      mapper: vm.mapperId,
      outputExpression: {
        function: vm.mappingRules[index].outputExpression.function + '',
        args: vm.mappingRules[index].hasArguments
          ? [
            {
              name: vm.mappingRules[index].argumentName,
              value: transformOutputExpressionBasedOnType(
                vm.mapperDataType.id,
                value
              )
            }
          ]
          : null
      },
      isManualIntervention:
        typeof vm.mappingRules[index].isManualIntervention != 'undefined'
          ? vm.mappingRules[index].isManualIntervention
          : false,
      triggersAlarm: vm.mappingRules[index].triggersAlarm
        ? vm.mappingRules[index].triggersAlarm
        : false,
      alarm: vm.mappingRules[index].triggersAlarm
        ? vm.mappingRules[index].alarmObject._id
        : undefined,
      isError: vm.mappingRules[index].isError
        ? vm.mappingRules[index].isError
        : false,
      order: index
    };
    switch (vm.mapperType) {
    case 'default':
      ruleObj.type = 1;
      break;
    case 'literal':
      var inputExpression =
          '^' +
          $filter('mapperRegex')(vm.mappingRules[index].inputExpression) +
          '$';
      ruleObj.inputExpression = inputExpression;
      ruleObj.type = 2;
      break;
    case 'format':
      ruleObj.inputDataType = vm.mappingRules[index].inputDataTypeObject._id;
      ruleObj.type = 3;
      break;
    }
    return ruleObj;
  }

  /**
   * @description Updates the mapping rule.
   * @function
   * @param {number} index Index of the item to be updated
   * @param {Object} ruleObj Object of the rule to be updated
   * @return {dataType}
   */
  function updateMappingRule(index, ruleObj) {
    MappingModel.update(
      {
        id: vm.mappingRules[index]._id
      },
      ruleObj
    ).then(
      function(res) {
        CrudToastFactory.toast('update');
        refreshMappingRule(index, res.data);
      },
      function(err) {
        vm.mappingRules[index] = vm.mappingRules[index].objectCopy;
        AlertingService.Error(err);
        vm.mappingRules[index].editMode = false;
      }
    );
  }

  /**
   * @description Displays the updated mapping rule after it being updated.
   * @function
   * @param {number} index Index of the rule to be refreshed
   * @param {Object} updatedRule Object of the updated rule to be displayed
   * @return {dataType}
   */
  function refreshMappingRule(index, updatedRule) {
    vm.mappingRules[index] = updatedRule;
    fetchAlarm(vm.mappingRules[index]);
    if (vm.mapperType == 'format') {
      constructFormatMappingRules(vm.mappingRules[index]);
    }
    updatedRule.mapperType = vm.mapperType;
    vm.mappingRules[index].editMode = false;
  }

  /**
   * @description Used for updating the mapping rule.
   * @function
   * @param {Object} mappingRule Which rule to set the argument name to.
   */
  function setArgumentName(mappingRule) {
    if (
      mappingRule.outputExpression &&
      Array.isArray(mappingRule.outputExpression.args) &&
      mappingRule.outputExpression.args[0] != null
    ) {
      mappingRule.argumentName = mappingRule.outputExpression.args[0].name;
      mappingRule.hasArguments = true;
    }
  }

  /**
   * @description Sets metadata for format mapping rules.
   * @function
   * @param {Object} mappingRule Which rule to set metadata to.
   */
  function constructFormatMappingRules(mappingRule) {
    var dataType = _.find(dataTypes, {
      id: mappingRule.inputDataType
    });
    if (dataType) {
      mappingRule.dataTypeName = dataType.name;
      mappingRule.dataTypeRegex = dataType.regex;
    } else {
      mappingRule.dataTypeName = 'unknown';
    }
    mappingRule.inputDataTypeObject = {
      _id: mappingRule.inputDataType
    };
  }

  /**
   * @description Check which arguments type should be used for selected function.
   * @function
   * @param {number} index Index of the item with the selected function.
   * @param {boolean} calledThoughEdit tells us, if the check for argument function was called by the edit function
   */
  function checkForArgument(index, calledByEdit) {
    var functionObject = vm.mappingRules[index].functionOptions.find(function(
      mappingFunction
    ) {
      return (
        mappingFunction.id == vm.mappingRules[index].outputExpression.function
      );
    });
    vm.mappingRules[index].hasArguments =
      functionObject && functionObject.hasArguments;
    vm.mappingRules[index].comment = functionObject
      ? functionObject.comment
      : '';
    if (!calledByEdit) {
      //we do not wish to remove values if function was called by the edit function
      // RESET ARGUMENTS VALUE ON FUNCTION CHANGE
      vm.mappingRules[index].outputExpression.args = [];
      // SET INTEGER TO FALSE ON FUNCTION SET
      vm.mappingRules[index].onlyInteger = false;
    }
    if (functionObject) {
      if (
        functionObject.hasArguments &&
        functionObject.arguments[0] == 'value'
      ) {
        vm.mappingRules[index].patternExample = gettextCatalog.getString(
          'Expected format: {{ msg }}.',
          {
            msg: vm.mapperDataType.dataTypeExample
          }
        );
        switch (vm.mapperDataType.id) {
        case 1:
          if (
            Array.isArray(vm.mappingRules[index].outputExpression.args) &&
              vm.mappingRules[index].outputExpression.args.length === 0
          ) {
            vm.mappingRules[index].outputExpression.args[0] = {
              value: 'false',
              name: 'value'
            };
          }
          break;
        case 2:
          vm.mappingRules[index].argumentType = 'numerical';
          vm.mappingRules[index].onlyInteger = true;
          break;
        case 3:
          vm.mappingRules[index].argumentType = 'numerical';
          break;
        case 4:
          vm.mappingRules[index].regex = new RegExp(
            '^(0?[1-9]|[12][0-9]|3[01])[.](0?[1-9]|1[012])[.](\\d{4})$'
          );
          vm.mappingRules[index].patternExample = gettextCatalog.getString(
            'Expected format dd.mm.yyyy'
          );
          break;
        default:
          vm.mappingRules[index].regex = vm.mapperDataType.regex;
        }

        vm.mappingRules[index].argumentName = functionObject.arguments[0];
      } else if (
        functionObject.hasArguments &&
        (functionObject.arguments[0] === 'n' ||
          functionObject.arguments[0] === 'numberOfValues')
      ) {
        vm.mappingRules[index].argumentType = 'numerical';
        vm.mappingRules[index].onlyInteger = true;
        vm.mappingRules[index].argumentName = functionObject.arguments[0];
      } else {
        vm.mappingRules[index].argumentName = undefined;
      }
    }
  }
  function transformOutputExpressionBasedOnType(typeId, data) {
    if (typeId === 5) {
      return DateTime.fromJSDate(new Date(data)).toFormat('yyyy-MM-dd');
    }
    return data;
  }
}
