opcConfiguration.$inject = ['gettext', '$q', 'TranslationService'];

function opcConfiguration(gettext, $q, TranslationService) {
  var configuration = {
    listId: 'opc',
    entity: {
      singular: gettext('OPC'),
      plural: gettext('OPCs')
    },
    api: {
      query: {
        entity: 'opcs',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'external-datasources-connectors-opc-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'external-datasources-connectors-opc-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'external-datasources-connectors-opc-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            method: 'delete',
            entity: 'opcs'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'typeName',
        title: gettext('Type')
      }
    ],
    badges: [],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Type'),
        collection: TranslationService.GetCollection('codelists.opcTypes'),
        valueField: 'id',
        param: 'type'
      },
      {
        display: gettext('Tango Agent Connection Configuration'),
        query: {
          entity: 'tango-agent-connection-configs',
          method: 'read'
        },
        entity: 'tango-agent-connection-configs',
        param: 'tangoAgentConnectionConfigs._id',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id',
        populate: 'tangoAgentConnectionConfigs'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      },
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'name DESC',
        params: '-name'
      }
    ]
  };

  /*
   *
   *   ENRICH
   *
   */

  function Enrich(item) {
    var deferred = $q.defer();
    item.typeName = gettext('Undefined');
    var type = TranslationService.GetCollectionById(
      'codelists.opcTypes',
      item.type
    );
    if (type) {
      item.typeName = type.name;
    }

    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default opcConfiguration;
