import timeSeriesActions from '../../../redux/time-series/action/times-series.action';
import valuesActions from '../../../redux/time-series-processing-values/action/time-series-processing-values.action';

/**
 * @ngdoc service
 * @name common.CommonPropertiesTimeSeriesFormConfiguration
 * @description Opens form dialog
 */

CommonPropertiesTimeSeriesFormConfiguration.$inject = [
  'gettextCatalog',
  'gettext',
  'TranslationService',
  '$ngRedux',
  '$mdDialog',
  'PhysicalDataFormConfigurationService',
  '$timeout',
  'TimeSeriesModel',
  'AlertingService',
  'EntityTagService'
];
export default function CommonPropertiesTimeSeriesFormConfiguration(
  gettextCatalog,
  gettext,
  TranslationService,
  $ngRedux,
  $mdDialog,
  PhysicalDataFormConfigurationService,
  $timeout,
  TimeSeriesModel,
  AlertingService,
  EntityTagService
) {
  /**
   * @description set timeout based on selected schedule classification
   * @function
   * @param {number} scheduleClassificationId
   * @param {Object} api
   */
  const setTimeoutByScheduleClassification = (
    scheduleClassificationId,
    api
  ) => {
    let {
      timeoutMaxTimeoutInMinutes,
      timeoutMaxTimeoutInHours,
      timeoutMaxTimeoutInDays
    } = api.getValues();
    if (
      timeoutMaxTimeoutInMinutes === 0 &&
      timeoutMaxTimeoutInHours === 0 &&
      timeoutMaxTimeoutInDays === 0
    ) {
      switch (scheduleClassificationId) {
      // Hourly
      case 1:
        api.setValue('timeoutMaxTimeoutInHours', 1);
        break;
        // Daily
      case 2:
        api.setValue('timeoutMaxTimeoutInDays', 1);
        break;
        // Weekly
      case 3:
        api.setValue('timeoutMaxTimeoutInDays', 7);
        break;
        // Monthly
      case 4:
        api.setValue('timeoutMaxTimeoutInDays', 31);
        break;
        // Yearly
      case 5:
        api.setValue('timeoutMaxTimeoutInDays', 365);
        break;
        // 15 minutes
      case 6:
        api.setValue('timeoutMaxTimeoutInMinutes', 15);
        break;
        // 30 minutes
      case 7:
        api.setValue('timeoutMaxTimeoutInMinutes', 30);
        break;
        // 6 months
      case 8:
        api.setValue('timeoutMaxTimeoutInDays', 183);
        break;
      }
    }
  };
  /**
   * @description get common properties time series form configuration.
   * @function
   * @param {Object} item
   * @param {Array} steps - Array of objects, containing tangoWizard steps.
   * @return {Object} configuration
   */
  async function get(item, steps) {
    let physicalQuantityParams;
    if (item != null) {
      physicalQuantityParams = {
        physicalQuantity: item.physicalQuantity,
        measurementUnit: item.measurementUnit,
        metricPrefix: item.metricPrefix
      };
    }
    let physicalDataFields = await PhysicalDataFormConfigurationService.get(
      physicalQuantityParams
    );
    let timeSeriesStatuses = TranslationService.GetCollection(
      'codelists.timeSeriesStatuses'
    );
    let filteredStatuses = timeSeriesStatuses.map(status => {
      let filteredStatus = {
        id: status.id,
        value: status.id,
        name: status.name
      };
      return filteredStatus;
    });

    let selectedDataInterpretationType;
    let selectedSamplingType;

    if (item != null) {
      selectedDataInterpretationType = TranslationService.GetCollectionById(
        'codelists.dataInterpretationTypes',
        item.dataInterpretationType
      );
      selectedSamplingType = TranslationService.GetCollectionById(
        'codelists.dataSamplingTypes',
        item.dataSamplingType
      );
    }

    let config = {
      name: 'timeSeries',
      actions: [
        {
          //SAVE ACTION
          title: gettext('Save'),
          fn: async form => {
            let values = form.getValues();
            let physicalData = {
              metricPrefix: null,
              measurementUnit: null
            };
            values.measurementUnit = null;
            values.metricPrefix = null;
            if (values.measurementData != null) {
              physicalData.metricPrefix =
                values.measurementData.metricPrefix != null
                  ? values.measurementData.metricPrefix.id
                  : null;
              physicalData.measurementUnit =
                values.measurementData.measurementUnit != null
                  ? values.measurementData.measurementUnit._id
                  : null;
              //update object that will update store value
              values.measurementUnit = values.measurementData.measurementUnit;
              values.metricPrefix = values.measurementData.metricPrefix;
              delete values.measurementData;
            }

            let body = {
              ...physicalData,
              name: values.name,
              description: values.description,
              timeSeriesStatus: values.timeSeriesStatus,
              precision:
                values.dataType != null && values.dataType.id === 3
                  ? values.precision
                  : null,
              tariff: values.tariff != null ? values.tariff._id : null,
              timeSeriesType:
                values.timeSeriesType != null
                  ? values.timeSeriesType._id
                  : null,
              physicalQuantity:
                values.physicalQuantity != null
                  ? values.physicalQuantity._id
                  : null
            };

            try {
              await TimeSeriesModel.update({ id: item._id }, body);
              let tags = [];
              try {
                tags = values.tags || [];
                let originalTags = item.tags || [];
                await EntityTagService.createSystemTags(
                  tags,
                  originalTags,
                  234,
                  item._id,
                  true
                );
              } catch (err) {
                tags = item.tags;
                AlertingService.Error(err);
              }
              //object that will update store
              values = {
                ...values,
                dataScheduler:
                  values.scheduler != null ? values.scheduler : null,
                dataType: values.dataType
                  ? values.dataType.id
                  : values.dataType,
                timeSeriesStatus: values.timeSeriesStatus,
                timeZone: values.timeZone
                  ? values.timeZone.id
                  : values.timeZone,
                metricPrefix: values.metricPrefix
                  ? values.metricPrefix.id
                  : values.metricPrefix,
                tags: values.tags
              };
              const params = {
                query: { id: item._id },
                result: {
                  data: values
                }
              };
              let action = timeSeriesActions.updateTimeSeriesProps(params);
              await $ngRedux.dispatch(action);

              action = valuesActions.addUpdateValuesTag({
                id: item._id,
                state: { content: true, detail: true }
              });
              $ngRedux.dispatch(action);
            } catch (err) {
              AlertingService.Error(err);
            }
            $mdDialog.hide();
          },
          color: 'primary'
        }
      ],
      title: gettextCatalog.getString('Time series'),
      fields: [
        {
          id: 'name',
          title: gettextCatalog.getString('Name'),
          type: {
            name: 'text',
            options: {}
          },
          initialize: () => {
            return item != null ? item.name : '';
          },
          required: true
        },
        {
          id: 'description',
          title: gettextCatalog.getString('Description'),
          type: {
            name: 'text',
            options: {}
          },
          initialize: () => {
            return item != null ? item.description : '';
          },
          required: false
        },
        {
          id: 'timeSeriesType',
          title: gettextCatalog.getString('Time series type'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'time-series-types',
                method: 'read'
              },
              crawlerParams: text => {
                let filterBy = {
                  order: '-_id'
                };
                if (text != null && text != '') {
                  filterBy.filter = text;
                }
                return filterBy;
              },
              display: data => {
                return {
                  text: `${
                    data.name
                      ? data.name
                      : gettextCatalog.getString('Unknown type')
                  }`
                };
              },
              dialog: {
                entity: 'time-series-types'
              }
            }
          },
          initialize: () => {
            return item != null ? item.timeSeriesType : null;
          },
          required: true
        },
        {
          id: 'dataInterpretationType',
          title: gettextCatalog.getString('Interpretation type'),
          type: {
            name: 'radio',
            options: {
              layout: 'row',
              items: TranslationService.GetCollection(
                'codelists.dataInterpretationTypes'
              ),
              modelProperty: 'id',
              display: data => {
                return data && data.name
                  ? data.name
                  : gettextCatalog.getString('Unknown interpretation type');
              }
            }
          },
          disable: () => steps == null,
          initialize: () => {
            return item != null && selectedDataInterpretationType != null
              ? selectedDataInterpretationType.id
              : 100;
          },
          onChange: api => {
            const dataInterpretationType = api.getValue(
              'dataInterpretationType'
            );
            if (Array.isArray(steps)) {
              const dataVisualizationStep = steps.find(
                step => step.stepId === 'timeSeriesDataVisualization'
              );
              if (
                dataVisualizationStep != null &&
                dataVisualizationStep.api != null &&
                typeof dataVisualizationStep.api.getValue === 'function'
              ) {
                const selectedChart = dataVisualizationStep.api.getValue(
                  'chartType'
                );
                if (
                  selectedChart != null &&
                  Array.isArray(
                    selectedChart.supportedTimeSeriesDataInterpretationTypes
                  ) &&
                  selectedChart.supportedTimeSeriesDataInterpretationTypes.indexOf(
                    dataInterpretationType
                  ) === -1
                ) {
                  const interpretationType = TranslationService.GetCollectionById(
                    'codelists.dataInterpretationTypes',
                    dataInterpretationType
                  );
                  if (
                    interpretationType != null &&
                    interpretationType.defaultChartType != null
                  ) {
                    dataVisualizationStep.api.setValue(
                      'chartType',
                      interpretationType.defaultChartType
                    );
                  } else {
                    dataVisualizationStep.api.setValue('chartType', 7);
                  }
                }
              }
            }
          },
          required: true
        },
        {
          id: 'dataSamplingType',
          title: gettextCatalog.getString('Sampling type'),
          type: {
            name: 'radio',
            options: {
              layout: 'row',
              items: TranslationService.GetCollection(
                'codelists.dataSamplingTypes'
              ),
              modelProperty: 'id',
              display: data => {
                return data && data.name
                  ? data.name
                  : gettextCatalog.getString('Unknown sampling type');
              }
            }
          },
          disable: () => steps == null,
          initialize: () => {
            return item != null && selectedSamplingType != null
              ? selectedSamplingType.id
              : 100;
          },
          /**
           * @description when calculated flow is present in wizard reset trigger types.
           * @function
           * @param {dataType} binding/paramName
           * @return {dataType}
           */
          onChange: api => {
            if (Array.isArray(steps)) {
              let calculatedFlowStep = steps.find(
                step => step.entity === 'CalculatedFlow'
              );
              let ExternalReaderFlowSteps = steps.find(
                step => step.entity === 'ExternalReaderFlow'
              );
              let dataSamplingType = api.getValue('dataSamplingType');
              // Data sampling type is irregular
              if (
                dataSamplingType == 200 &&
                ExternalReaderFlowSteps != null &&
                ExternalReaderFlowSteps.api != null &&
                typeof ExternalReaderFlowSteps.api.setValues == 'function'
              ) {
                const valuesSetToNull = {
                  extrapolationFunction: null,
                  extrapolationArgument: null,
                  interpolationFunction: null,
                  interpolationArgument: null,
                  timeoutTitle: null,
                  timeoutMaxTimeoutInDays: 0,
                  timeoutMaxTimeoutInHours: 0,
                  timeoutMaxTimeoutInMinutes: 0,
                  timeoutMaxTimeoutInSeconds: 0,
                  timeoutAction: null,
                  timeoutAlarm: null
                };
                ExternalReaderFlowSteps.api.setValues(valuesSetToNull);
              }
              if (
                calculatedFlowStep != null &&
                calculatedFlowStep.api != null
              ) {
                const items = TranslationService.GetCollection(
                  'codelists.triggerTypes'
                ).filter(item => {
                  // If data sampling type is irregular
                  if (dataSamplingType == 200) {
                    // Return trigger type - triggered
                    return item.id == 100;
                  }
                  return item.id !== 100;
                });
                calculatedFlowStep.api.setFieldConfigurationProperty(
                  'triggerType',
                  'type.options.items',
                  items
                );
                let triggerTypeValue = calculatedFlowStep.api.getValue(
                  'triggerType'
                );
                // If data sampling type is irregular
                if (dataSamplingType == 200) {
                  // Set value to trigger type - triggered
                  calculatedFlowStep.api.setValue('triggerType', 100);
                  // Data sampling type is regular and trigger type is triggered
                } else if (
                  dataSamplingType === 100 &&
                  triggerTypeValue === 100
                ) {
                  // Set valut to trigger type - cron triggered
                  calculatedFlowStep.api.setValue('triggerType', 200);
                }
                const flowVariableTypeConfig = calculatedFlowStep.api.getFieldConfigurationProperty(
                  'flowVariables',
                  'type'
                );
                if (flowVariableTypeConfig != null) {
                  const { options } = flowVariableTypeConfig;
                  if (options != null) {
                    calculatedFlowStep.api.setFieldConfigurationProperty(
                      'flowVariables',
                      'type.options',
                      {
                        ...options,
                        regularInput: dataSamplingType === 100
                      }
                    );
                  }
                }
                $timeout(() => {
                  calculatedFlowStep.api.revalidate();
                });
              }
            }
          },
          required: true
        },
        {
          id: 'scheduler',
          title: gettextCatalog.getString('Scheduler'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'schedulers',
                method: 'read'
              },
              crawlerParams: text => {
                let filter = {
                  order: '-name'
                };
                if (text != null && text != '') {
                  filter = {
                    ...filter,
                    filter: text
                  };
                }
                return filter;
              },
              display: data => {
                return {
                  text: `${
                    data.name
                      ? data.name
                      : gettextCatalog.getString('Unknown scheduler')
                  }`
                };
              },
              dialog: {
                entity: 'schedulers'
              }
            }
          },
          hide: api => {
            return api.getValue('dataSamplingType') != 100;
          },
          disable: () => steps == null,
          initialize: () => {
            if (item != null) {
              if (typeof item.dataScheduler === 'object') {
                item.dataScheduler = {
                  ...item.dataScheduler,
                  __prefetched__value__: true
                };
              }
              return item.dataScheduler;
            }
            return null;
          },
          onChange: form => {
            if (Array.isArray(steps)) {
              let stepWithReadScheduler = steps.find(step => {
                if (
                  step.api != null &&
                  typeof step.api.getValue === 'function'
                ) {
                  return (
                    step.api.getFieldConfiguration('readScheduler') != null
                  );
                }
              });
              let externalWriterFlowStep = steps.find(
                step => step.entity === 'ExternalWriterFlow'
              );
              if (externalWriterFlowStep != null) {
                let scheduler = form.getValue('scheduler');
                if (scheduler != null) {
                  setTimeoutByScheduleClassification(
                    scheduler.scheduleClassification,
                    externalWriterFlowStep.api
                  );
                }
              }
              if (stepWithReadScheduler != null) {
                let dataScheduler = form.getValue('scheduler');
                if (dataScheduler && !dataScheduler.__prefetched__value__) {
                  stepWithReadScheduler.api.setValue(
                    'readScheduler',
                    dataScheduler
                  );
                }
              }
            }
          },
          required: true
        },
        {
          id: 'timeSeriesStatus',
          title: gettextCatalog.getString('Time series status'),
          type: {
            name: 'radio',
            options: {
              layout: 'row',
              items: filteredStatuses,
              display: data => {
                return data && data.name
                  ? data.name
                  : gettextCatalog.getString('Unknown time series status');
              }
            }
          },
          initialize: () => {
            return item != null
              ? TranslationService.GetCollectionById(
                'codelists.timeSeriesStatuses',
                item.timeSeriesStatus
              ).id
              : 100;
          },
          required: true
        },
        {
          id: 'timeZone',
          title: gettextCatalog.getString('Time zone'),
          type: {
            name: 'autocomplete',
            options: {
              items: TranslationService.GetCollection('codelists.timeZones'),
              display: data => {
                return {
                  text: `${
                    data.name
                      ? data.name
                      : gettextCatalog.getString('Unknown time zone')
                  }`
                };
              },
              dialog: {
                entity: 'timeZones'
              }
            }
          },
          initialize: () => {
            let timeZone = null;
            if (item != null) {
              timeZone = TranslationService.GetCollectionById(
                'codelists.timeZones',
                item.timeZone
              );
            } else {
              let currentTimeZone = Intl.DateTimeFormat().resolvedOptions()
                .timeZone;
              let timeZones = TranslationService.GetCollection(
                'codelists.timeZones'
              );
              timeZone = timeZones.find(item => item.code === currentTimeZone);
            }
            return timeZone;
          },
          disable: () => steps == null,
          required: true
        },
        {
          id: 'tags',
          title: gettextCatalog.getString('Tags'),
          type: {
            name: 'tags',
            options: {
              create: {
                searchParamName: 'value',
                crawler: {
                  entity: 'entity-tags',
                  method: 'create'
                }
              },
              itemsCrawler: {
                entity: 'entity-tags',
                method: 'read'
              },
              valueParam: '_id',
              crawlerParams: text => {
                let params = {
                  order: '-value'
                };
                if (text != null && text != '') {
                  return {
                    ...params,
                    value: text
                  };
                }
                return params;
              },
              display: data => {
                return {
                  text: `${
                    data.value
                      ? data.value
                      : gettextCatalog.getString('Unknown tag')
                  }`
                };
              },
              dialog: {
                entity: 'entity-tags'
              }
            }
          },
          initialize: () => {
            return item != null ? angular.copy(item.tags) : [];
          },
          required: true
        },
        {
          id: 'dataType',
          title: gettextCatalog.getString('Data type'),
          type: {
            name: 'autocomplete',
            options: {
              items: TranslationService.GetCollection('codelists.dataTypes'),
              display: data => {
                return {
                  text: `${
                    data.name
                      ? data.name
                      : gettextCatalog.getString('Unknown data type')
                  }`
                };
              },
              dialog: {
                entity: 'dataTypes'
              }
            }
          },
          initialize: () => {
            return item != null
              ? TranslationService.GetCollectionById(
                'codelists.dataTypes',
                item.dataType
              )
              : null;
          },
          onChange: form => {
            $timeout(() => {
              let dataType = form.getValue('dataType');
              if (Array.isArray(steps)) {
                steps.forEach(step => {
                  if (step.api != null) {
                    if (step.api.getFieldConfiguration('displayType') != null) {
                      let displayType = step.api.getValue('displayType');
                      if (
                        dataType != null &&
                        (item == null ||
                          (item != null &&
                            (displayType == null ||
                              (displayType != null &&
                                item.dataType !== dataType.id))))
                      ) {
                        // Integer or decimal
                        if (dataType.id === 2 || dataType.id === 3) {
                          if (displayType == null) {
                            // Chart and table
                            step.api.setValue('displayType', 3);
                          }
                        } else {
                          // Table
                          step.api.setValue('displayType', 2);
                        }
                      }
                    }
                    if (
                      step.api.getFieldConfiguration('historyFlowMapper') !=
                      null
                    ) {
                      let historyFlowMapper = step.api.getValue(
                        'historyFlowMapper'
                      );
                      if (
                        historyFlowMapper != null &&
                        dataType != null &&
                        historyFlowMapper.dataType !== dataType.id
                      ) {
                        step.api.setValue('historyFlowMapper', null);
                      }
                    }
                    if (
                      step.api.getFieldConfiguration('liveFlowMapper') != null
                    ) {
                      let liveFlowMapper = step.api.getValue('liveFlowMapper');
                      if (
                        liveFlowMapper != null &&
                        dataType != null &&
                        liveFlowMapper.dataType !== dataType.id
                      ) {
                        step.api.setValue('liveFlowMapper', null);
                      }
                    }
                    if (
                      step.api.getFieldConfiguration('inputTimeSeries') != null
                    ) {
                      let inputTimeSeries = step.api.getValue(
                        'inputTimeSeries'
                      );
                      if (
                        inputTimeSeries != null &&
                        dataType != null &&
                        inputTimeSeries.dataType !== dataType.id
                      ) {
                        step.api.setValue('inputTimeSeries', null);
                      }
                    }
                  }
                });
                //RESET MAINTENANCE FLOW
                const maintenanceFlowStep = steps.find(
                  step => step.entity === 'MaintenanceFlow'
                );
                if (
                  maintenanceFlowStep != null &&
                  maintenanceFlowStep.api != null &&
                  typeof maintenanceFlowStep.api.getValue === 'function'
                ) {
                  let maintenanceFlowType = maintenanceFlowStep.api.getValue(
                    'maintenanceFlowType'
                  );

                  if (
                    dataType != null &&
                    dataType.id != 2 &&
                    dataType.id != 3 &&
                    maintenanceFlowType != null &&
                    maintenanceFlowType.id == 510 /** METER CHANGE */
                  ) {
                    maintenanceFlowStep.api.setValue(
                      'maintenanceFlowType',
                      500
                    ); /* GENERAL MAINTENANCE FLOW */
                  }
                }
              }
            });
          },
          disable: () => steps == null,
          required: true
        },
        {
          id: 'precision',
          title: gettextCatalog.getString('Precision'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              max: 12,
              min: 0
            }
          },
          initialize: () => {
            return item != null && item.precision != null ? item.precision : 2;
          },
          hide: api => {
            let dataType = api.getValue('dataType');
            if (dataType != null && dataType.id === 3) {
              return false;
            }
            return true;
          },
          required: true
        },
        {
          id: 'tariff',
          title: gettextCatalog.getString('Tariff'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'tariffs',
                method: 'read'
              },
              crawlerParams: text => {
                let filter = {};
                if (text != null && text != '') {
                  filter = {
                    ...filter,
                    filter: text
                  };
                }
                return filter;
              },
              display: data => {
                return {
                  text: `${
                    data.name
                      ? data.name
                      : gettextCatalog.getString('Unknown Tariff')
                  }`
                };
              },
              dialog: {
                entity: 'tariffs'
              }
            }
          },
          initialize: () => {
            return item != null ? angular.copy(item.tariff) : null;
          }
        },
        ...physicalDataFields
      ]
    };
    return config;
  }

  return { get };
}
