InvoicelistModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function InvoicelistModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};
  var networkConfiguration = {
    host: 'view',
    path: 'invoicelist'
  };

  var networkConfigurationMeasuringpointInvoice = {
    host: 'view',
    path: 'invoicelistfrommeasuringpoints',
    headers: { 'S-Response-Type': 'List' }
  };
  var networkConfigurationList = {
    host: 'view',
    path: 'invoicelist',
    headers: { 'S-Response-Type': 'List' }
  };
  var networkConfigurationTotal = {
    host: 'view',
    path: 'invoicelist',
    headers: { 'S-Response-Type': 'Count' }
  };

  var networkConfigurationMeasuringpointInvoiceTotal = {
    host: 'view',
    path: 'invoicelistfrommeasuringpoints',
    headers: { 'S-Response-Type': 'Count' }
  };
  var crawlerConfiguration = {
    entity: 'invoicelist',
    method: 'read',
    steps: [
      {
        entity: 'locations',
        valueParams: ['location'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'populateArray'
      }
    ]
  };
  var methods = {
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    custom: {
      readDialog: CrawlerMethods.crawler(crawlerConfiguration),
      readList: ModelUtilities.crud(
        'GET',
        networkConfigurationList,
        modelStorage
      ),
      readTotal: ModelUtilities.crud(
        'GET',
        networkConfigurationTotal,
        modelStorage
      ),
      readMeasuringPointInvoice: ModelUtilities.crud(
        'GET',
        networkConfigurationMeasuringpointInvoice,
        modelStorage
      ),
      readMeasuringPointInvoiceTotal: ModelUtilities.crud(
        'GET',
        networkConfigurationMeasuringpointInvoiceTotal,
        modelStorage
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default InvoicelistModel;
