module.exports = {
  entity: 'company-structure-nodes',
  path: 'company-structure-nodes',
  name: {
    en: 'Hieararchy node',
    sl_SI: 'Vozlišče'
  },
  networkModel: {
    entity: 'company-structure-nodes',
    read: 'read'
  },
  scope: 'tis_be:company-structure-nodes',

  selectedParamName: 'id',
  dialog: {
    title: {
      en: 'Select company structure node',
      sl_SI: 'Izberite vozlišče'
    },
    listInfo: {
      en: 'Select company structure node from the list below',
      sl_SI: 'Izberite vozlišče s spodnjega seznama'
    }
  },
  sfeTree: {
    showConnectedEntities: false,
    route: 'company-structure-nodes',
    parentEntity: 'company-structure-nodes',
    parentKey: 'parentId',
    icon: 'company-structure-node',
    childrenConfiguration: [
      {
        type: 'simple',
        usedIn: ['everywhere'],
        route: 'company-structure-nodes',
        childKey: 'parentId',
        existenceParam: 'hasChild',
        icon: 'company-structure-node'
      },
      {
        type: 'simple',
        usedIn: [
          'company-resources-company-structure',
          'company-resources-cost-centres-list',
          'data-analytical-data-points-new',
          'data-analytical-data-points-edit',
          'data-analytical-data-points-duplicate'
        ],
        route: 'cost-centres',
        childKey: 'companyStructureNode',
        existenceParam: 'possible',
        icon: 'costCentre'
      },
      {
        type: 'simple',
        usedIn: [
          'company-resources-company-structure',
          'company-resources-profit-centres-list'
        ],
        route: 'profit-centres',
        childKey: 'companyStructureNode',
        existenceParam: 'possible',
        icon: 'profit-centre'
      }
    ]
  }
}
