DomainCollectionConfigurationController.$inject = [
  'AlertingService',
  '$state',
  'gettext',
  'DomainCollectionModel',
  'DomainModel',
  'MetadataService'
];

function DomainCollectionConfigurationController(
  AlertingService,
  $state,
  gettext,
  DomainCollectionModel,
  DomainModel,
  MetadataService
) {
  var vm = this;
  init();

  function init() {
    vm.loading = true;
    var parallel = {
      readCollection: readCollection,
      readDomain: readDomain
    };
    async.parallel(parallel, function(err, res) {
      if (!err) {
        vm.headerData = constructHeader(res.readCollection, res.readDomain);
        vm.loading = false;
      }
    });
  }

  function readCollection(callback) {
    DomainCollectionModel.read($state.params).then(
      function(res) {
        callback(null, res.data);
      },
      function(err) {
        AlertingService.Error(err);
        callback();
      }
    );
  }

  function readDomain(callback) {
    DomainModel.read({
      id: $state.params.domainId
    }).then(
      function(res) {
        callback(null, res.data);
      },
      function(err) {
        AlertingService.Error(err);
        callback();
      }
    );
  }

  function constructHeader(collection, domain) {
    MetadataService.Loading(false);
    MetadataService.ChangeMetadata(`Domain collection of ${domain.name}`);
    var properties = [
      {
        title: gettext('Domain'),
        linkTitle: domain.name,
        state: 'configurations-dynamic-attributes-domains-view',
        param: 'id',
        paramValue: domain._id,
        type: 'link'
      }
    ];
    var obj;
    var domainFields = domain.fields.map(domainField => {
      obj = {
        title: domainField.name,
        value: collection[domainField.rawFieldName]
      };
      switch (domainField.fieldType) {
      case 'String':
      case 'Integer':
      case 'Float':
        obj.type = 'simple';
        break;
      case 'Boolean':
        obj.type = 'radio';
        obj.checkBox = true;
        obj.customOptions = ['YES', 'NO'];
        break;
      case 'Date':
        obj.type = 'date';
        break;
      }
      return obj;
    });
    var concatedProperties = [].concat(properties, domainFields);
    var propertySections = [
      {
        title: gettext('properties'),
        properties: concatedProperties
      }
    ];
    var actions = [
      {
        title: gettext('Update'),
        script: true,
        fn: function() {
          $state.go(
            'configurations-dynamic-attributes-domains-collections-edit',
            $state.params
          );
        }
      }
    ];
    return {
      metadata: {
        definition: 'Domain Collection'
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}

export default DomainCollectionConfigurationController;
