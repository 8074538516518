NewReportController.$inject = [
  'gettext',
  '$state',
  '$timeout',
  'AlertingService',
  'ReportModel',
  'MetadataService',
  'createOrUpdateService',
  'TranslationService',
  'SfeHeader',
  '$scope',
  'gettextCatalog',
  'DatasetColumnHelper',
  'report'
];

function NewReportController(
  gettext,
  $state,
  $timeout,
  AlertingService,
  ReportModel,
  MetadataService,
  createOrUpdateService,
  TranslationService,
  SfeHeader,
  $scope,
  gettextCatalog,
  DatasetColumnHelper,
  report
) {
  const vm = this;
  const defaultEntities = [
    234 /*TimeSeries*/,
    2 /*Measuringpoint*/,
    3 /*Location*/,
    4 /*CostCentre*/,
    235 /*TimeSeriesType*/,
    105 /*EntityTag*/,
    20 /* Alarms */,
    187 /* Severity */,
    30 /* alarmType */,
    251 /* alarmStatuses */,
    252 /* EventTypes */,
    248 /* TangoAgentConnectionConfig */,
    247 /* externalDatasource */
  ];
  const reportId = $state.params.id;

  let comparableColumns = [];
  let stimulsoftReportTemplate;

  init();

  function init() {
    //CREATE UPDATE ACTIONS CONFIGURATION
    let createUpdateButtonTitle;
    switch ($state.current.name) {
    case 'analytics-reports-duplicate':
    case 'analytics-reports-new':
      createUpdateButtonTitle = gettext('Create');
      break;
    case 'analytics-reports-edit':
      createUpdateButtonTitle = gettext('Update');
      break;
    }

    vm.createOrUpdate = [
      {
        title: gettext('Edit template'),
        fn: createOrUpdate.bind({
          edit: true
        }),
        color: 'primary',
        disabledFn: function() {
          return $scope.Form.$invalid || vm.sendingRequest;
        }
      },
      {
        title: createUpdateButtonTitle,
        fn: createOrUpdate,
        color: 'primary',
        raised: true,
        disabledFn: function() {
          return $scope.Form.$invalid || vm.sendingRequest;
        }
      }
    ];
    //SET STATE CREATE OR UPDATE
    if ($state.current.name === 'analytics-reports-duplicate') {
      vm.duplicateMode = true;
    } else if (reportId) {
      vm.editMode = true;
    }
    //SET REPORT FILTERS TITLE
    vm.filtersHeader = SfeHeader.constructFormHeader(
      'primary',
      gettext('Filters')
    );
    //SET FORMS CONFIGURATIONS
    vm.dataConfig = {
      PH16: true,
      data: getReportForm()
    };
    vm.filtersFormConfig = {
      PH16: true,
      data: getFiltersConfig()
    };
    if (vm.editMode || vm.duplicateMode) {
      MetadataService.Loading(true);
      vm.sendingRequest = true;
      if (vm.editMode) {
        vm.dataConfig.header = SfeHeader.constructFormHeader(
          'primary',
          gettext('Edit report'),
          'analytics-reports-view',
          { id: reportId }
        );
        $timeout(() => {
          MetadataService.ChangeMetadata(
            'Edit ' + report.name,
            report.description
          );
        });
      } else {
        vm.dataConfig.header = SfeHeader.constructFormHeader(
          'primary',
          gettext('Duplicate report'),
          'analytics-reports-view',
          { id: reportId }
        );
        $timeout(() => {
          MetadataService.ChangeMetadata(
            'Duplicate ' + report.name,
            report.description
          );
        });
      }
      readReport();
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New report'),
        'analytics-reports-list'
      );
      vm.dataConfig.dataObj = {
        activeComparableColumn: {},
        useReportTemplate: true,
        allowedEntities: []
      };
      vm.filtersFormConfig.dataObj = vm.dataConfig.dataObj;
    }
    vm.sendingRequest = false;
  }

  function readReport() {
    vm.dataConfig.dataObj = {
      name: report.name,
      description: report.description,
      dataset: report.dataset,
      periodInterval: report.periodInterval,
      activeComparableColumn: {
        _id: report.activeComparableColumn
      },
      allowedEntities: report.allowedEntities
        ? report.allowedEntities.map(function(item) {
          return TranslationService.GetCollectionById(
            'codelists.entities',
            item
          );
        })
        : []
    };
    vm.filtersFormConfig.dataObj = vm.dataConfig.dataObj;
    vm.columns = report.dataset.columns;
    // add default string to default filters
    vm.columns.forEach(function(column) {
      if (defaultEntities.includes(column.entity)) {
        column.default = gettextCatalog.getString('(default)');
      }
    });
    // set __prefetched__value__ flag to process
    // new dataset value and set comparable columns
    // dropdown options
    report.dataset.__prefetched__value__ = true;
    datasetChanged([report.dataset]);

    vm.inputFilters = report.filters || [];

    if (vm.duplicateMode) {
      stimulsoftReportTemplate = report.stimulsoftReportTemplate;
    }

    MetadataService.Loading(false);
  }

  function getReportForm() {
    var data = [
      {
        placeholder: 'Name',
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: 'Description',
        name: 'description',
        componentType: 'textArea',
        type: 'text',
        maxlength: 500,
        required: false
      },
      {
        configuration: {
          query: {
            entity: 'datasets',
            method: 'read'
          },
          entity: 'datasets',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Dataset')
          },
          floatingLabel: gettext('Select Dataset'),
          searchParamName: 'filter',
          required: true,
          filterFn: function(items) {
            var defaultColumns;
            items = items.filter(function(item) {
              defaultColumns = item.columns.filter(function(column) {
                return (
                  column.filterEnabled &&
                  defaultEntities.includes(column.entity)
                );
              });
              return defaultColumns.length;
            });
            return items;
          },
          change: datasetChanged,
          createRedirect: {
            state: 'analytics-datasets-new'
          }
        },
        componentType: 'autocompleteDialog',
        edit: false,
        name: 'dataset'
      },
      {
        componentType: 'multiSelect',
        config: {
          label: gettext('Comparable column'),
          placeholder: gettext('Select comparable column'),
          ctrlFn: function() {
            return $timeout(function() {
              return comparableColumns;
            });
          },
          empty: gettext('There are no comparable columns.'),
          edit: vm.editMode || vm.duplicateMode
        },
        name: 'activeComparableColumn'
      },
      {
        name: 'allowedEntities',
        hide: true,
        showParam: 'showAllowedEntities',
        componentType: 'autocompleteChipDialog',
        searchParam: 'name',
        required: false,
        query: param => {
          var available = vm.dataConfig.dataObj.dataset.columns;
          var allowedColumns = available.filter(function(item) {
            return item.filterEnabled;
          });
          allowedColumns = _.uniqBy(allowedColumns, 'entity');
          allowedColumns = allowedColumns.map(function(item) {
            return TranslationService.GetCollectionById(
              'codelists.entities',
              item.entity
            );
          });
          if (param.filter) {
            allowedColumns = allowedColumns.filter(function(item) {
              var index = item.name
                .toLowerCase()
                .indexOf(param.filter.toLowerCase());
              return index > -1;
            });
          }
          return allowedColumns;
        },
        floatingLabel: gettext('Select entity'),
        displayFields: ['name'],
        valueParam: 'id',
        createMode: false
      }
    ];
    return data;
  }

  function datasetChanged(item) {
    if (item && item[0]) {
      if (!item[0].__prefetched__value__) {
        vm.dataConfig.dataObj.activeComparableColumn = {};
        vm.filters = [];
        if (vm.dataConfig.dataObj.dataset) {
          vm.columns = vm.dataConfig.dataObj.dataset.columns;
          setDefaultFilters(vm.columns);
        }
        vm.dataConfig.dataObj.allowedEntities = [];
      }

      if (
        vm.dataConfig.dataObj.dataset &&
        vm.dataConfig.dataObj.dataset.comparableColumns
      ) {
        initComparebleFilterOptions(
          vm.dataConfig.dataObj.dataset.comparableColumns
        );
        vm.dataConfig.dataObj.showAllowedEntities = true;
      } else {
        vm.dataConfig.dataObj.showAllowedEntities = false;
      }
    } else {
      vm.dataConfig.dataObj.activeComparableColumn = {};
      vm.filters = [];
    }
  }

  function getFiltersConfig() {
    return [
      {
        configuration: {
          query: {
            entity: 'period-intervals',
            method: 'read'
          },
          entity: 'period-intervals',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Time Interval')
          },
          floatingLabel: gettext('Select Time Interval'),
          searchParamName: 'filter',
          required: true
        },
        componentType: 'autocompleteDialog',
        edit: false,
        name: 'periodInterval'
      }
    ];
  }

  function initComparebleFilterOptions(columns) {
    if (columns && columns.length) {
      comparableColumns = _.map(columns, function(column) {
        return {
          _id: column,
          name: column
        };
      });
    } else {
      comparableColumns = [];
    }
    var comparebleConfiguration = vm.dataConfig.data.find(function(config) {
      return config.name === 'activeComparableColumn';
    });
    comparebleConfiguration.options = comparableColumns;
  }

  /**
   * @description sets default filters values when dataset changes.
   * @function
   * @param {Array} columns selected dataset columns
   */
  function setDefaultFilters(columns) {
    const defaultColumns = columns.filter(function(column) {
      if (defaultEntities.includes(column.entity)) {
        column.default = gettextCatalog.getString('(default)');
        return column.filterEnabled;
      }
    });
    if (defaultColumns && defaultColumns.length > 0) {
      vm.inputFilters = defaultColumns.map(function(column) {
        return {
          rawFieldName: column.rawFieldName
        };
      });
    } else {
      AlertingService.Error(
        gettext(
          'Dataset doesn\'t have any default filters. Select another dataset'
        )
      );
      vm.dataConfig.dataObj.dataset = null;
    }
  }

  async function createOrUpdate() {
    const toEditTemplate = this.edit;
    vm.filters = vm.filters.map(function(filter) {
      let selectedFilters;
      if (
        Array.isArray(filter.filterValues) &&
        filter.filterValues.length > 0
      ) {
        selectedFilters = filter.filterValues;
      }
      if (
        Array.isArray(filter.entityItemObjects) &&
        filter.entityItemObjects.length > 0
      ) {
        selectedFilters = filter.entityItemObjects;
      }
      return {
        ...filter,
        entity: filter.entity,
        entityItems: selectedFilters
      };
    });
    const filtersToValidate = vm.filters.map(function(filter) {
      return {
        entity: filter.entity,
        entityItems: filter.entityItems
      };
    });

    const validationResult = DatasetColumnHelper.validateFilters(
      filtersToValidate,
      defaultEntities
    );

    if (validationResult.error && toEditTemplate) {
      AlertingService.Error(validationResult.error);
    } else {
      vm.sendingRequest = true;
      var state = toEditTemplate
        ? 'analytics-reports-template-edit'
        : 'analytics-reports-view';
      try {
        await createOrUpdateService.simpleCall(
          {
            entity: 'reports',
            method: vm.editMode ? 'update' : 'create'
          },
          vm.editMode,
          reportId,
          dataToSave(),
          state,
          'id'
        );
      } catch (err) {
        AlertingService.Error(err);
      }
      vm.sendingRequest = false;
    }
  }

  function dataToSave() {
    const obj = vm.dataConfig.dataObj;

    let apiFilters = [];
    if (vm.filters && vm.filters.length) {
      apiFilters = vm.filters.map(function(filter) {
        let apiObj = {
          rawFieldName: filter.rawFieldName
        };

        if (filter.filterType === 'numberFilter') {
          apiObj.filterValues = [Number(filter.value)];
        } else if (filter.isPreset) {
          apiObj.filterValues = [];
          if (filter.entityItems) {
            apiObj.filterValues = filter.entityItems.reduce((result, item) => {
              if (typeof item == 'string') {
                result = [...result, item];
              } else if (typeof item == 'object' && item != null) {
                if (filter.entity == 140) {
                  ///Master Invoice
                  result = [...result, item.masterInvoice];
                } else if (item._id) {
                  result = [...result, item._id];
                } else if (item.id) {
                  result = [...result, item.id];
                }
              }
              return result;
            }, []);
          }
        }
        if (apiObj.filterValues && apiObj.filterValues.length) {
          apiObj.isLocked = true;
        } else {
          apiObj.filterValues = null;
        }
        return apiObj;
      });
    }
    return {
      name: obj.name,
      description: obj.description,
      dataset: obj.dataset ? obj.dataset._id : undefined,
      periodInterval: obj.periodInterval ? obj.periodInterval._id : undefined,
      activeComparableColumn: obj.activeComparableColumn
        ? obj.activeComparableColumn._id
        : undefined,
      allowedEntities: obj.allowedEntities
        ? obj.allowedEntities.map(function(item) {
          return item.id;
        })
        : [],
      stimulsoftReportTemplate: stimulsoftReportTemplate,
      filters: apiFilters
    };
  }
}
export default NewReportController;
