module.exports = {
  entity: 'notifications',
  path: 'notifications',
  name: {
    en: 'Notifications',
    sl_SI: 'Sporocila'
  },
  networkModel: {
    entity: 'notifications',
    read: 'read'
  },
  scope: 'notificator:notification',

  displayFields: ['subject'],
  dialog: {
    title: {
      en: 'Select notification',
      sl_SI: 'Izberite sporocilo'
    },
    listInfo: {
      en: 'Select notification from the list below',
      sl_SI: 'Izberite sporocilo s spodnjega seznama'
    }
  }
}
