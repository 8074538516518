import './sfe-filter.scss';
import template from './sfe-filter.component.html';
/**
 * @description components that creates filter inputs based on a configuration file.
 * @function
 * @param {object} filters - filter configuration object
 * @param {boolean} relistData - value that tells is if data should be relisted (watcher in other components watch this value)
 * @param {boolean} oneColumn - tells us, if the filters should be displayed in a single column
 * @param {boolean} isDialog - tells us, if the filters are in a dialog
 * @param {boolean} loadingStatus - tells us, if the data is currently loading
 */
export default {
  restrict: 'E',
  template,
  bindings: {
    filters: '<',
    relistData: '=',
    oneColumn: '<?',
    isDialog: '<?',
    loadingStatus: '<',
    darkBackground: '<'
  },
  controller: SfeFilterController,
  controllerAs: 'vm'
};

SfeFilterController.$inject = [
  'AlertingService',
  '$scope',
  'EntitiesService',
  'gettext',
  'CrawlerMethods'
];

function SfeFilterController(
  AlertingService,
  $scope,
  EntitiesService,
  gettext,
  CrawlerMethods
) {
  const vm = this;

  /**
   * @description function that is run on component initilization
   * @function
   */
  vm.$onInit = () => {
    vm.actions = getActions(vm.darkBackground);
    vm.reloadFilterOptions = reloadFilterOptions;
    vm.listFiltered = listFiltered;
    vm.clearFilterAtIndex = clearFilterAtIndex;
    configureFilters();
  };

  function getActions(darkBackground) {
    if (darkBackground) {
      return [
        {
          title: gettext('Clear'),
          icon: {
            name: 'close',
            type: 2
          },
          fn: cancelAllFilters,
          color: 'primary',
          whiteButton: true,
          raised: true,
          disabledFn: () => vm.loadingStatus
        },
        {
          title: gettext('Search'),
          icon: {
            name: 'search',
            type: 2
          },
          fn: listFiltered,
          color: 'primary',
          raised: true,
          disabledFn: () => vm.loadingStatus
        }
      ];
    }
    return [
      {
        title: gettext('Clear'),
        icon: {
          name: 'close',
          type: 2
        },
        fn: cancelAllFilters,
        color: 'warn',
        disabledFn: () => vm.loadingStatus
      },
      {
        title: gettext('Search'),
        icon: {
          name: 'search',
          type: 2
        },
        fn: listFiltered,
        color: 'success',
        disabledFn: () => vm.loadingStatus
      }
    ];
  }

  function configureFilters() {
    if (vm.filters) {
      vm.filters = vm.filters.map(filter => {
        filter.displayList =
          filter.displayList || getFilterDisplayList(filter.entity);
        filter.valueField = filter.valueField || '_id';
        if (filter.type === 'dateCompare' && !filter.date) {
          filter.date = '';
        } else if (filter.type === 'customDate' && !filter.date) {
          filter.date = [];
        }
        return filter;
      });
    }
  }

  function getFilterDisplayList(entity) {
    if (entity) {
      const displayFields = EntitiesService.getDisplayFieldsByPath(entity);
      if (displayFields) {
        return displayFields;
      }
    }
    return ['name'];
  }

  /**
   * @description function that changes vm.relistData to true (which is watched and makes a request for data based on the input filters)
   * @function
   */
  function listFiltered() {
    vm.relistData = true;
    if (vm.isDialog) {
      $scope.$emit('filterUpdated', true);
    }
  }
  /**
   * @description clears specific filter input based on index
   * @function
   * @param {number} index - index of the filter input we want to clear
   */
  function clearFilterAtIndex(index) {
    switch (vm.filters[index].type) {
    case 'searchSelect':
      vm.filters[index].selected = [];
      break;
    case 'dateCompare':
      vm.filters[index].date = '';
      break;
    case 'string':
    case 'number':
      vm.filters[index].searchTerm = '';
      break;
    case 'customDate':
      vm.filters[index].date = null;
      break;
    default:
      // if type is not defined
      vm.filters[index].selected = [];
    }
  }
  /**
   * @description reloads the values you get in selector type filters
   * @function
   * @param {number} index - index of the filter we are reloading the options for
   * @return {promise}
   */
  async function reloadFilterOptions(index) {
    const filter = vm.filters[index];
    let refreshObj = filter.refreshObject || {};

    if (filter.dependOnFilter) {
      const dependedOnFilter = vm.filters.find(
        item => item.param === filter.dependOnFilter
      );
      refreshObj[filter.dependParamName] = JSON.stringify(
        dependedOnFilter.selected
      );
      if (
        dependedOnFilter &&
        dependedOnFilter.selected &&
        dependedOnFilter.selected.length === 0
      ) {
        refreshObj = {};
      }
    }

    if (filter.collection) {
      if (filter.filterFn) {
        filter.options = filter.filterFn(filter.collection);
      } else {
        filter.options = angular.copy(filter.collection);
      }
    } else {
      const fn = filter.query;
      const fnCtrl = filter.ctrlFn;
      if (fn) {
        let method;
        if (typeof fn === 'object') {
          method = CrawlerMethods.getMethod(fn)(refreshObj);
        } else {
          method = fn.query(refreshObj).$promise;
        }
        try {
          const { data } = await method;
          if (filter.filterFn) {
            filter.options = filter.filterFn(data);
          } else {
            filter.options = data;
          }
        } catch (err) {
          AlertingService.Error(err);
        }
      }

      if (fnCtrl) {
        try {
          const data = await fnCtrl();
          if (filter.filterFn) {
            filter.options = filter.filterFn(data);
          } else {
            filter.options = data;
          }
        } catch (err) {
          AlertingService.Error(err);
        }
      }
    }
  }
  /**
   * @description clears all filter inputs of values
   * @function
   */
  function cancelAllFilters() {
    (vm.filters || []).forEach(function(filter, index) {
      clearFilterAtIndex(index);
    });
    vm.relistData = true;
  }
}
