import template from '../views/sfe-form.dialog.html';

/**
 * @ngdoc service
 * @name common.SfeFormDialogService
 * @description Used for opening the sfe form in a dialog.
 * @property {function} openSfeFormDialog - See openSfeFormDialog method below.
 */

SfeFormDialogService.$inject = ['$mdDialog'];

export default function SfeFormDialogService($mdDialog) {
  /**
   * @memberof common.SfeDialogController
   * @description Opens sfe form in a dialog
   * @param {boolean} edit Determines whether entity is being edited or created (used for filling in the inputs)
   * @param {Array} config Array of components (fields) to be displayed
   * @param {boolean} item To store data
   * @param {boolean} title Title of the form
   * @param {Array} customButtons Can use for customization of the form buttons
   */
  function openSfeFormDialog(edit, config, item, title, customButtons) {
    return $mdDialog
      .show({
        controller: [
          'edit',
          'config',
          'item',
          'title',
          'customButtons',
          '$scope',
          'gettext',
          SfeDialogController
        ],
        controllerAs: 'vm',
        template,
        parent: angular.element(document.body),
        locals: {
          edit: edit,
          config: config,
          item: item,
          title: title,
          customButtons: customButtons
        }
      })
      .catch(angular.noop);
  }

  // SfeFormDialogService.$inject = ['edit', 'config', 'item', 'title', 'customButtons'];
  function SfeDialogController(
    editMode,
    config,
    item,
    title,
    customButtons,
    $scope,
    gettext
  ) {
    const vm = this;
    vm.displayDefault = true;
    vm.$onInit = init;
    vm.header = {
      title: title,
      isDialog: true,
      actions: [
        {
          icon: {
            name: 'close',
            type: 2
          },
          cancel: true
        }
      ]
    };

    function init() {
      vm.title = title;
      let dataObj;
      let testId;
      if (item && item._preserve_) {
        //do not copy, need to remain binded
        dataObj = item ? item : {};
      } else {
        dataObj = item ? angular.copy(item) : {};
      }
      if (item != null) {
        testId = item.testId;
      }
      vm.dataConfig = {
        data: config,
        dataObj: dataObj
      };
      let actionButtons = [];
      if (customButtons) {
        vm.displayDefault = customButtons.displayDefault;
        vm.customButtons = customButtons.buttons;
        let disableFunction;
        actionButtons = customButtons.buttons.map(button => {
          disableFunction = button.disabledFn;
          if (button.callDialogSaveFunction) {
            button.fn = saveForm;
          } else if (button.script) {
            button.fn = () => button.script(hideDialog, vm.dataConfig.dataObj);
          }
          button.disabledFn = () => {
            if (typeof disableFunction == 'function') {
              return (
                $scope.dialogForm.$invalid ||
                vm.sendingRequest ||
                disableFunction()
              );
            }
            return $scope.dialogForm.$invalid || vm.sendingRequest;
          };
          return button;
        });
      }

      if (vm.displayDefault) {
        actionButtons.push({
          title: editMode ? gettext('Update') : gettext('Create'),
          fn: saveForm,
          color: 'primary',
          disabledFn: () => {
            return $scope.dialogForm.$invalid || vm.sendingRequest;
          },
          testId
        });
      }

      vm.dialogActions = actionButtons;
    }

    function hideDialog(item) {
      $mdDialog.hide(item);
    }

    function saveForm() {
      vm.sendingRequest = true;
      $mdDialog.hide(vm.dataConfig.dataObj);
    }
  }

  return {
    openSfeFormDialog: openSfeFormDialog
  };
}
