module.exports = {
  entity: 'expressions',
  path: 'expressions',
  name: {
    en: 'Energy management groups',
    sl_SI: 'Izrazi'
  },
  networkModel: {
    entity: 'expressions',
    read: 'read'
  },
  scope: 'tis_be:expressions',

  dialog: {
    title: {
      en: 'Select expression',
      sl_SI: 'Izberite izraz'
    },
    listInfo: {
      en: 'Select expression from the list below',
      sl_SI: 'Izberite izraz s spodnjega seznama'
    }
  }
}
