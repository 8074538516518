import { DateTime } from 'luxon';

NewTimeIntervalFilterController.$inject = [
  'gettext',
  '$state',
  'AlertingService',
  'createOrUpdateService',
  'MetadataService',
  'SfeHeader',
  'periodInterval',
  '$timeout'
];

function NewTimeIntervalFilterController(
  gettext,
  $state,
  AlertingService,
  createOrUpdateService,
  MetadataService,
  SfeHeader,
  periodInterval,
  $timeout
) {
  var vm = this;
  var timeIntervalFilterId = $state.params.id;

  var periodTypeOptions = [
    {
      _id: 'month',
      name: gettext('Monthly')
    },
    {
      _id: 'year',
      name: gettext('Yearly')
    }
  ];
  var dynamicFixedOptions = [
    {
      _id: 'dynamic',
      name: gettext('Dynamic')
    },
    {
      _id: 'fixed',
      name: gettext('Fixed Filter')
    }
  ];
  var fixedIntervalOptions = [
    {
      _id: 'last',
      name: gettext('Last N month')
    },
    {
      _id: 'exact',
      name: gettext('Exact Interval')
    }
  ];
  vm.editMode = false;

  init();

  function init() {
    vm.dataConfig = {
      data: getFormConfig()
    };
    if (timeIntervalFilterId) {
      MetadataService.Loading(true);
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit time interval filter'),
        'analytics-time-interval-filters-view',
        { id: timeIntervalFilterId }
      );
      vm.editMode = true;
      constructDataObject(periodInterval);
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New time interval filter'),
        'analytics-time-interval-filters-list'
      );
      vm.dataConfig.dataObj = {
        periodType: periodTypeOptions[0],
        timeIntervalFilterType: dynamicFixedOptions[0],
        fixedTimeIntervalFilterType: fixedIntervalOptions[0],
        endDate: null,
        startDate: null
      };
      periodTypeChanged();
      timeIntervalFilterTypeChanged();
    }

    vm.dataConfig.action = {
      ctrlFn: true,
      title: vm.editMode ? gettext('Update') : gettext('Create'),
      fn: createOrUpdate
    };
  }

  function constructDataObject(periodInterval) {
    vm.dataConfig.dataObj = {
      name: periodInterval.name,
      description: periodInterval.description,
      value: periodInterval.value
    };

    if (periodInterval.startDate && periodInterval.endDate) {
      vm.dataConfig.dataObj.timeIntervalFilterType = dynamicFixedOptions[1];
      vm.dataConfig.dataObj.startDate = new Date(periodInterval.startDate);
      vm.dataConfig.dataObj.endDate = new Date(periodInterval.endDate);
    } else {
      vm.dataConfig.dataObj.timeIntervalFilterType = dynamicFixedOptions[0];
    }

    if (periodInterval.periodType === 'month') {
      vm.dataConfig.dataObj.periodType = periodTypeOptions[0];
    } else {
      vm.dataConfig.dataObj.periodType = periodTypeOptions[1];
    }

    vm.dataConfig.dataObj.includeFirstMonth = false;
    vm.dataConfig.dataObj.includeLastMonth = false;

    if (periodInterval.inclusionType === 'opened') {
      vm.dataConfig.dataObj.includeFirstMonth = true;
      vm.dataConfig.dataObj.includeLastMonth = true;
    } else if (periodInterval.inclusionType === 'closed_left') {
      vm.dataConfig.dataObj.includeLastMonth = true;
    } else if (periodInterval.inclusionType === 'closed_right') {
      vm.dataConfig.dataObj.includeFirstMonth = true;
    }

    vm.dataConfig.dataObj._id = periodInterval._id;
    periodTypeChanged();
    timeIntervalFilterTypeChanged();
    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(
        'Edit ' + periodInterval.name,
        periodInterval.description
      );
    });
  }

  function periodTypeChanged() {
    var valueConfig = _.find(vm.dataConfig.data, {
      name: 'value'
    });
    if (valueConfig && vm.dataConfig.dataObj.periodType) {
      valueConfig.placeholder =
        vm.dataConfig.dataObj.periodType._id === 'month'
          ? gettext('last N months')
          : gettext('last N years');
    }
    var lastMonthConfig = _.find(vm.dataConfig.data, {
      name: 'includeLastMonth'
    });
    var firstMonthConfig = _.find(vm.dataConfig.data, {
      name: 'includeFirstMonth'
    });
    if (lastMonthConfig) {
      lastMonthConfig.label =
        vm.dataConfig.dataObj.periodType._id === 'month'
          ? gettext('Include last month')
          : gettext('Include last year');
    }
    if (firstMonthConfig) {
      firstMonthConfig.label =
        vm.dataConfig.dataObj.periodType._id === 'month'
          ? gettext('Include first month')
          : gettext('Include first year');
    }
  }

  function timeIntervalFilterTypeChanged() {
    if (vm.dataConfig.dataObj.timeIntervalFilterType._id === 'fixed') {
      vm.dataConfig.dataObj.showFixed = true;
      vm.dataConfig.dataObj.showDynamic = false;
      if (!vm.dataConfig.dataObj.startDate) {
        vm.dataConfig.dataObj.startDate = null;
      }
      if (!vm.dataConfig.dataObj.endDate) {
        vm.dataConfig.dataObj.endDate = null;
      }
    } else {
      vm.dataConfig.dataObj.showFixed = false;
      vm.dataConfig.dataObj.showDynamic = true;
    }
  }

  function getFormConfig() {
    return [
      {
        placeholder: 'Name',
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: 'Description',
        name: 'description',
        componentType: 'textArea',
        type: 'text',
        maxlength: 500,
        required: false
      },
      {
        componentType: 'radio',
        label: gettext('Time interval filter type'),
        options: dynamicFixedOptions,
        name: 'timeIntervalFilterType',
        changeFn: timeIntervalFilterTypeChanged
      },
      {
        componentType: 'radio',
        label: gettext('Period type'),
        options: periodTypeOptions,
        name: 'periodType',
        hide: true,
        showParam: 'showDynamic',
        changeFn: periodTypeChanged
      },
      {
        hide: true,
        showParam: 'showDynamic',
        placeholder: gettext('Last N month'),
        name: 'value',
        componentType: 'textField',
        type: 'numerical',
        pattern: new RegExp('^([0-9])*$'),
        patternExample: '9'
      },
      {
        componentType: 'checkBox',
        name: 'includeFirstMonth',
        label: gettext('Include first month'),
        hide: true,
        showParam: 'showDynamic'
      },
      {
        componentType: 'checkBox',
        name: 'includeLastMonth',
        label: gettext('Include last month'),
        hide: true,
        showParam: 'showDynamic'
      },
      {
        hide: true,
        showParam: 'showFixed',
        componentType: 'datePickerFromTo',
        from: 'startDate',
        to: 'endDate',
        labelFrom: gettext('Start date:'),
        labelTo: gettext('End date:'),
        fromIsAfterTo: gettext('Start date must be before End date.'),
        required: true,
        idFrom: 'from',
        idTo: 'to'
      }
    ];
  }

  // create or update functions

  function createOrUpdate() {
    vm.sendingRequest = true;
    createOrUpdateService
      .simpleCall(
        {
          entity: 'period-intervals',
          method: vm.editMode ? 'update' : 'create'
        },
        vm.editMode,
        timeIntervalFilterId,
        dataToSave(),
        'analytics-time-interval-filters-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function dataToSave() {
    var obj = vm.dataConfig.dataObj;
    var apiObj = {
      name: obj.name,
      description: obj.description
    };
    if (obj.timeIntervalFilterType._id === 'fixed') {
      apiObj.startDate = DateTime.fromJSDate(obj.startDate)
        .startOf('day')
        .toJSDate();
      apiObj.endDate = DateTime.fromJSDate(obj.endDate)
        .endOf('day')
        .toJSDate();
      apiObj.periodType = null;
      apiObj.value = null;
      apiObj.inclusionType = null;
    } else if (obj.timeIntervalFilterType._id === 'dynamic') {
      apiObj.startDate = null;
      apiObj.endDate = null;

      apiObj.periodType = obj.periodType._id;
      apiObj.value = obj.value;
      apiObj.inclusionType =
        obj.includeFirstMonth && obj.includeLastMonth
          ? 'opened'
          : !obj.includeFirstMonth && !obj.includeLastMonth
            ? 'closed'
            : obj.includeFirstMonth
              ? 'closed_right'
              : 'closed_left';
    } else {
      AlertingService.Error(gettext('Filter type should be selected'));
      return;
    }
    return apiObj;
  }
}

export default NewTimeIntervalFilterController;
