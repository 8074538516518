import './entity-privilege-management.scss';
import template from './entity-privilege-management.component.html';

// THIS HAS INCLUDE
/**
 * @ngdoc component
 * @name user.SfeEntityPrivilegeManagement
 * @description displays and interacts with user static permissions.
 * @param {string} treeId permission tree id
 * @param {string} userId user id
 * @param {array} items tree items
 * @example
 * <sfe-entity-privilege-management
 *   user-id="vm.userd"
 *   tree-id="vm.selectedEntityTreeId"
 *   items="vm.items"
 * >
 * </sfe-entity-privilege-management>
 */
export default {
  template,
  bindings: {
    treeId: '<',
    userId: '<',
    items: '=?'
  },
  controllerAs: 'vm',
  controller: SfeEntityPrivilegeManagementController
};

SfeEntityPrivilegeManagementController.$inject = [
  'AlertingService',
  'PrivilegeHelperMethods',
  '$q',
  'AuthorizationStaticAccessModel',
  '$state',
  'ToastService',
  'gettext',
  '$scope'
];

function SfeEntityPrivilegeManagementController(
  AlertingService,
  PrivilegeHelperMethods,
  $q,
  AuthorizationStaticAccessModel,
  $state,
  ToastService,
  gettext,
  $scope
) {
  var vm = this;
  vm.selectAllPrivilegesOfKind = selectAllPrivilegesOfKind;
  /**
   * @description when treeId is set triggeres init function.
   * @function
   */
  vm.$onChanges = function() {
    if (vm.treeId) {
      getConfig();
    }
  };
  // static permissions
  vm.treeGlobalStaticPrivileges = [
    {
      name: 'create',
      value: false,
      order: 1
    },
    {
      name: 'read',
      value: false,
      order: 2
    },
    {
      name: 'update',
      value: false,
      order: 3
    },
    {
      name: 'delete',
      value: false,
      order: 4
    },
    {
      name: 'list',
      value: false,
      order: 5
    }
  ];
  /**
   * @description updates static permission value of the same kind to all entities in the tree .
   * @function
   * @param {object} kind permission object must contain name and value property
   */
  async function selectAllPrivilegesOfKind(kind) {
    const promises = vm.items.map(item => {
      item.access[kind.name] = kind.value;
      return updateStaticAccess(item);
    });
    const result = await Promise.allSettled(promises);
    let anyRejected = false;
    result.forEach(res => {
      if (res.status === 'rejected') {
        AlertingService.Error(res.reason);
        anyRejected = true;
      }
    });
    if (!anyRejected) {
      ToastService.showToast(gettext('Permissions were successfully updated.'));
    }
    setSelectAllInTreeValue();
    $scope.$applyAsync();
  }
  /**
   * @description updates static permissions.
   * @function
   * @param {object} item entity item we want to update
   * @return {Promise}
   */
  async function updateStaticAccess(item) {
    let checkAll;
    if (!item) {
      item = this.item;
      checkAll = true;
    }
    item.dataIsLoading = true;
    const postObject = {
      owner: vm.userId,
      scope: item.scope,
      scopeName: item.scopeName,
      show: item.show,
      access: item.access
    };
    try {
      await AuthorizationStaticAccessModel.create(postObject);
      item.dataIsLoading = false;
      item.originalAccess = angular.copy(item.access);
      if (checkAll) {
        setSelectAllInTreeValue();
      }
    } catch (err) {
      item.dataIsLoading = false;
      item.access = angular.copy(item.originalAccess);

      if (checkAll) {
        setSelectAllInTreeValue();
      }
      throw err;
    }
  }
  /**
   * @description checks if all entities static access are true and sets value to "all" toggle and "apply to all entities" checkboxes .
   * @function
   */
  function setSelectAllInTreeValue() {
    var allTrue = {
      create: true,
      read: true,
      update: true,
      delete: true,
      list: true
    };
    var allItemAccessTrue;
    vm.items.forEach(function(item) {
      allItemAccessTrue = true;
      for (var access in allTrue) {
        if (!item.access[access]) {
          allTrue[access] = false;
          allItemAccessTrue = false;
        }
        item.allAccess = allItemAccessTrue;
      }
    });
    vm.treeGlobalStaticPrivileges.forEach(function(item) {
      item.value = allTrue[item.name];
    });
  }
  /**
   * @description gets privilege tree configuraiton.
   * @function
   */
  function getConfig() {
    vm.itemsAreLoading = true;
    PrivilegeHelperMethods.getEntitiesAndPrivilegesByTree(
      vm.treeId,
      vm.userId
    ).then(
      function(res) {
        vm.itemsAreLoading = false;
        vm.items = [];
        var allTrue = {
          create: true,
          read: true,
          update: true,
          delete: true,
          list: true
        };
        var allItemAccessTrue;
        var resultParams;
        var resultParamsString;
        for (var key in res.result) {
          allItemAccessTrue = true;
          res.result[key].config.dashboardCardType = 'privilege-management';
          res.result[key].config.titleObject = {
            title: res.result[key].config.scopeName
          };
          for (var access in allTrue) {
            if (!res.result[key].config.access[access]) {
              allTrue[access] = false;
              allItemAccessTrue = false;
            }
          }
          res.result[key].config.allAccess = allItemAccessTrue;
          res.result[key].config.updateStaticAccess = updateStaticAccess.bind({
            item: res.result[key].config
          });
          res.result[key].config.originalAccess = angular.copy(
            res.result[key].config.access
          );

          resultParams = {
            id: vm.userId,
            entityType: res.result[key].config.scope,
            entity: $state.params.entity
          };
          resultParamsString = '(' + JSON.stringify(resultParams) + ')';
          res.result[key].config.titleObject.state =
            $state.current.name + '-item' + resultParamsString;
          vm.items.push(res.result[key].config);
        }

        vm.treeGlobalStaticPrivileges.forEach(function(item) {
          item.value = allTrue[item.name];
        });
      },
      function(err) {
        vm.itemsAreLoading = false;
        AlertingService.Error(err);
      }
    );
  }
}
