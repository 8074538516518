module.exports = {
  entity: 'measurement-statuses',
  path: 'measurement-statuses',
  name: {
    en: 'Measurement statuses',
    sl_SI: 'Statusi meritev'
  },
  networkModel: {
    entity: 'measurement-statuses',
    read: 'read'
  },
  selectedParamName: 'id',
  scope: 'tis_be:measurement-statuses',

  dialog: {
    title: {
      en: 'Select Measurement status',
      sl_SI: 'Izberite status meritve'
    },
    listInfo: {
      en: 'Select measurement status from the list below',
      sl_SI: 'Izberite status meritve s spodnjega seznama'
    }
  }
}
