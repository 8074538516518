AnalysisFilterBuilder.$inject = [
  'DatasetColumnHelper',
  'CrawlerMethods',
  'TranslationService',
  'gettextCatalog'
];

/**
 * @ngdoc factory
 * @name common.AnalysisFilterBuilder
 * @description function that creates a filter configuration array that is used by the analysis-filter component.
 * @property {function} buildFilters
 */
function AnalysisFilterBuilder(
  DatasetColumnHelper,
  CrawlerMethods,
  TranslationService,
  gettextCatalog
) {
  /**
   * @memberof analysis.AnalysisFilterBuilder
   * @description Creates an array of filter configurations.
   * @function
   * @param {Array} filters - An array containing columns that have been selected as filters
   * @param {Array} columns - An array containing all available columns
   * @return {Array} An array containing configurations for the selected filters.
   */
  const buildFilters = async (filters, columns) => {
    const configs = filters.map(filter => {
      const column = columns.find(
        column => column.rawFieldName === filter.rawFieldName
      );
      if (!column) {
        return false;
      }
      if (column.entity && column.type !== 2) {
        return DatasetColumnHelper.setFilterTreeConfig(filter, column.entity);
      }
      if (column.entity) {
        //used to delete entity without mutating column.
        const { entity, ...everythingElse } = column;
        return everythingElse;
      }
      return column;
    });

    const promiseArray = filters.map((filter, index) => {
      //since configs was created by mapping filters array, index still match up
      const config = configs[index];
      if (config && config.filterType === 'dnd') {
        if (config.networkModelConfiguration) {
          return CrawlerMethods.getMethod(config.networkModelConfiguration)();
        }
        return {
          data: TranslationService.GetCollection(config.codelistPath)
        };
      }
      return false;
    });
    const result = await Promise.all(promiseArray);
    return filters.map((filter, index) => {
      //since configs was created by mapping filters array, index still match up
      const config = configs[index];
      let filterType;
      if (config && config.type === 2) {
        filterType = 'numberFilter';
      } else if (config && config.entity) {
        filterType = 'entityFilter';
      }
      switch (filterType) {
      case 'entityFilter':
        //creates data only for DnD (because only DnD has result[index] value)
        if (result[index]) {
          const {
            available,
            entityItemObjects
          } = separatedSelectedAndAvailableItems(
            result[index].data,
            config.filterValues,
            config.valueField
          );
          return {
            ...filter,
            ...config,
            uniqueId: config.entity + String(index),
            available,
            entityItemObjects
          };
        }
        return {
          ...filter,
          ...config,
          uniqueId: config.entity + String(index)
        };
      case 'numberFilter':
        return {
          ...filter,
          filterType,
          type: config.type,
          uniqueId: config.rawFieldName,
          filterTitle: config.displayFieldName,
          displayType: 'integerInput',
          value: filter.filterValues ? filter.filterValues[0] : ''
        };
        //invalid filter
      default:
        return {
          ...filter,
          filterTitle: gettextCatalog.getString(
            'Invalid filter configuration'
          )
        };
      }
    });
  };
  /**
   * @description Function that reduces the selectedIds array into an object that contains two properties,
   * one being an array os selected items, the other being all remaining items
   * @function
   * @param {dataType} allItems - Array of all the items
   * @param {Array} selectedIds - Array of item ids that have been selected
   * @param {string} valueField - property name of the id (_id for database values and id for codelist values)
   * @return {Object} Object with the properties available,
   * which contains all the items that haven't been selected and entityItemObjects that contains all selected items
   */
  function separatedSelectedAndAvailableItems(
    allItems = [],
    selectedIds = [],
    valueField
  ) {
    return selectedIds.reduce(
      (obj, id) => {
        return {
          available: obj.available.filter(item => item[valueField] != id),
          entityItemObjects: [
            ...obj.entityItemObjects,
            ...obj.available.filter(item => item[valueField] == id)
          ]
        };
      },
      {
        available: allItems,
        entityItemObjects: []
      }
    );
  }
  return {
    buildFilters
  };
}

export default AnalysisFilterBuilder;
