InteractionModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function InteractionModel(ModelUtilities, CrawlerMethods) {
  let modelStorage = {};

  const networkConfiguration = {
    host: 'notification',
    path: 'interactions'
  };

  const crawlerConfigurationList = {
    entity: 'interactions',
    method: 'read',
    steps: [
      {
        entity: 'notifications',
        valueParams: ['notification'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'populateArray'
      }
    ]
  };

  const crawlerConfigurationRead = {
    entity: 'interactions',
    method: 'read',
    steps: [
      {
        entity: 'notifications',
        valueParams: ['notification'],
        queryParams: ['id'],
        method: 'read',
        methodType: 'populate'
      }
    ]
  };

  const methods = {
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    custom: {
      readNoCancel: ModelUtilities.crud(
        'GET',
        {
          ...networkConfiguration,
          notCancelable: true
        },
        modelStorage
      ),
      readWithNotifications: CrawlerMethods.crawler(crawlerConfigurationRead),
      listWithNotifications: CrawlerMethods.crawler(crawlerConfigurationList)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default InteractionModel;
