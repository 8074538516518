ProjectConfiguration.$inject = ['gettext', '$q'];

function ProjectConfiguration(gettext, $q) {
  let configuration = {
    listId: 'projectId',
    entity: {
      singular: gettext('Project'),
      plural: gettext('Projects')
    },
    api: {
      query: {
        entity: 'projects',
        method: 'read'
      },
      enrich: Enrich
    },
    create: {
      link: {
        state: 'company-resources-projects-new'
      }
    },
    title: {
      param: 'name',
      link: {
        state: 'company-resources-projects-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'company-resources-projects-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'company-resources-projects-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'projects',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Code'),
        param: 'code',
        type: 'code'
      },
      {
        title: gettext('External code'),
        param: 'externalCode',
        type: 'code'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Internal Order'),
        param: 'costCentres._id',
        query: {
          entity: 'cost-centres',
          method: 'read'
        },
        refreshObject: {
          costCentreType: 3
        },
        displayList: [
          {
            field1: 'name',
            type: 'string'
          },
          {
            field1: 'code',
            type: 'code',
            title: 'Code'
          },
          {
            field1: 'externalCode',
            type: 'code',
            title: 'External Code'
          }
        ],
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id',
        populate: 'costCentres'
      },
      {
        display: gettext('Code'),
        param: 'code',
        type: 'string'
      },
      {
        display: gettext('External Code'),
        param: 'externalCode',
        type: 'string'
      },
      {
        query: {
          entity: 'business-partners',
          method: 'read'
        },
        entity: 'business-partners',
        display: gettext('Business Partner'),
        param: 'businessPartner',
        type: 'searchSelect',
        refreshParameterName: 'name',
        selectedParamName: '_id'
      },
      {
        query: {
          entity: 'project-types',
          method: 'read'
        },
        entity: 'project-types',
        display: gettext('Project Type'),
        param: 'projectType',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        query: {
          entity: 'project-fields',
          method: 'read'
        },
        entity: 'project-fields',
        display: gettext('Project Field'),
        param: 'projectField',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        display: gettext('Energy Manager'),
        param: 'energyManager',
        query: {
          entity: 'users',
          method: 'read'
        },
        type: 'searchSelect',
        refreshParameterName: 'full_name',
        selectedParamName: '_id',
        displayList: [
          {
            type: 'string',
            combined: true,
            field1: 'name',
            field2: 'family_name'
          }
        ]
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  /*
   *
   *   ENRICH
   *
   */

  function Enrich() {
    let deferred = $q.defer();

    deferred.resolve();
    return deferred.promise;
  }
  return configuration;
}
export default ProjectConfiguration;
