MapHelperService.$inject = [
  'LocationModel',
  'utilService',
  'AlertingService',
  'xhrService',
  'GoogleMapsAPI'
];

function MapHelperService(
  LocationModel,
  utilService,
  AlertingService,
  xhrService,
  GoogleMapsAPI
) {
  /**
   * @description Fetches the address picture metadata of the marked item used for metadata.
   * @function
   * @param {Object} item The marker on the map
   * @return {function} callback
   */
  async function fetchAddressPictureMetadata(item) {
    let method;
    if (typeof item.address == 'object' && item.address != null) {
      item.addressObject = item.address;
      method = LocationModel.custom.fetchPictureMetadata;
    } else {
      method = LocationModel.custom.fetchAddressPictureMetadata;
    }
    try {
      const res = await method(item);
      return res;
    } catch (err) {
      AlertingService.Error(err);
      return item;
    }
  }

  /**
   * @description Creates url for the marker
   * @function
   * @param {Object} item The marker on the map
   * @return {function} callback
   */
  async function createMarkerItemUrl(addressPictureMetadata) {
    return new Promise(resolve => {
      let imageUrl;
      if (
        addressPictureMetadata &&
        addressPictureMetadata.pictureLocation &&
        addressPictureMetadata.pictureLocation.length &&
        addressPictureMetadata.pictureLocation[0].picture
      ) {
        imageUrl =
          utilService.getApiHost +
          '/pictures/' +
          addressPictureMetadata.pictureLocation[0].picture._id +
          '/image';
      }
      if (imageUrl) {
        xhrService.asyncFetch(imageUrl, 'arraybuffer').then(res => {
          const arrayBufferView = new Uint8Array(res.response);
          const blob = new Blob([arrayBufferView], {
            type: 'image/jpeg'
          });

          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(blob);
          resolve(imageUrl);
        });
      }
      resolve(null);
    });
  }

  const fetchLocationInfo = async location => {
    const addressPic = await fetchAddressPictureMetadata(location);
    const imageUrl = await createMarkerItemUrl(addressPic);
    const link = '/company-resources/locations/' + location._id;

    let addressObj;
    if (
      addressPic.addressObject &&
      (addressPic.addressObject.streetName ||
        addressPic.addressObject.houseNumber ||
        addressPic.addressObject.communityName ||
        addressPic.addressObject.postalNumber ||
        addressPic.addressObject.postName)
    ) {
      const streetName = `${addressPic.addressObject.streetName || ''} ${
        addressPic.addressObject.houseNumber == undefined
          ? ''
          : addressPic.addressObject.houseNumber
      }`;
      const communityName = addressPic.addressObject.communityName || '';
      const postalNumber = `${
        addressPic.addressObject.postalNumber == undefined
          ? ''
          : addressPic.addressObject.postalNumber
      } ${addressPic.addressObject.postName || ''}`;
      addressObj = {
        streetName,
        communityName,
        postalNumber
      };
    }

    return GoogleMapsAPI.createMarkerDOM(
      location.name,
      link,
      imageUrl,
      addressObj
    );
  };
  return { fetchLocationInfo };
}

export default MapHelperService;
