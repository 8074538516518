import template from './sfe-search-select.directive.html';
import './sfe-search-select.scss';

export default function sfeSearchSelect() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      refreshResource: '=',
      paramName: '=',
      modelArray: '=',
      label: '=',
      value: '@',
      enterAction: '=',
      displayFieldList: '=',
      options: '=',
      refreshObject: '=',
      entity: '=',
      showDialogButton: '=',
      collection: '<'
    },
    link: linkFn,
    controller: sfeSearchSelectController,
    controllerAs: 'vm',
    bindToController: true
  };
  return directive;
  function linkFn() {}
}

sfeSearchSelectController.$inject = [
  'AlertingService',
  '$scope',
  'ItemSelector',
  'CrawlerMethods',
  'filterService'
];

function sfeSearchSelectController(
  AlertingService,
  $scope,
  ItemSelector,
  CrawlerMethods,
  filterService
) {
  const vm = this;
  vm.filterObject = {};
  vm.fetchOption = fetchOption;
  vm.clearSearchTerm = clearSearchTerm;
  vm.clearSelected = clearSelected;
  vm.openDialog = openDialog;
  var dialogConfiguration = {
    multiple: true
  };

  const optionsWatcher = $scope.$watch('vm.options', function() {
    if (vm.options) {
      vm.filterObject.options = vm.options;
    }
  });

  $scope.$on('$destroy', function() {
    if (optionsWatcher) {
      optionsWatcher();
    }
  });

  vm.$onInit = async () => {
    if (!vm.value) {
      vm.value = '_id';
    }
  };

  function clearSelected() {
    vm.modelArray = [];
  }

  function clearSearchTerm() {
    vm.filterObject.searchTerm = '';
  }

  function openDialog() {
    if (vm.refreshObject) {
      Object.assign(dialogConfiguration, {
        filterObject: vm.refreshObject
      });
    }

    ItemSelector.open([vm.entity], [dialogConfiguration]).then(function(
      selectedItems
    ) {
      if (selectedItems && selectedItems.length) {
        // set _id & number values because data is fetched from different route (item view)
        if (vm.entity === 'master-invoices') {
          selectedItems = selectedItems.map(item => ({
            ...item,
            _id: item.masterInvoice,
            number: item.masterInvoiceNumber
          }));
        }
        vm.modelArray = selectedItems.map(function(selectedItem) {
          return selectedItem[vm.value];
        });
        vm.options = selectedItems;
      }
    });
  }

  async function fetchOption() {
    if (vm.collection) {
      const filter = vm.filterObject.searchTerm
        ? [
          {
            param: vm.paramName || 'name',
            searchTerm: vm.filterObject.searchTerm,
            type: 'string'
          }
        ]
        : [];
      vm.filterObject.options = filterService.filterDataV1(
        vm.collection,
        filter
      );
    } else {
      var obj = vm.refreshObject ? angular.copy(vm.refreshObject) : {};
      if (vm.filterObject.searchTerm) {
        obj[vm.paramName] = vm.filterObject.searchTerm;
      }
      vm.newOptionsLoading = true;
      let modelOptions = [];
      if (vm.modelArray && vm.modelArray.length && vm.filterObject.options) {
        modelOptions = vm.modelArray.reduce(
          (acc, item) => [
            ...acc,
            vm.filterObject.options.find(option => item === option[vm.value])
          ],
          []
        );
      }
      var method;
      if (
        typeof vm.refreshResource === 'object' &&
        vm.refreshResource.method &&
        vm.refreshResource.entity
      ) {
        method = CrawlerMethods.getMethod(vm.refreshResource)(obj);
      } else {
        method = vm.refreshResource.query(obj).$promise;
      }

      try {
        const { data: items } = await method;
        vm.filterObject.options = _.uniqBy(
          modelOptions.concat(items),
          vm.value
        );
        vm.newOptionsLoading = false;
      } catch (err) {
        AlertingService.Error(err);
        vm.newOptionsLoading = false;
      }
    }
    $scope.$applyAsync();
  }
}
