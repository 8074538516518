import './rule-variables-viewer.scss';
import template from './rule-variables-viewer.component.html';

/**
 * @ngdoc component
 * @name alarms-and-rules.ruleVariables
 * @description adds rule variables
 * @param {Function} onChange
 **/
export default {
  restrict: 'E',
  template,
  bindings: {
    variables: '<',
    hideDateTimeFilter: '<'
  },
  controller: ruleVariablesViewerController,
  controllerAs: 'vm',
  bindToController: true
};

ruleVariablesViewerController.$inject = ['InputParametersParserService'];

function ruleVariablesViewerController(InputParametersParserService) {
  const vm = this;
  vm.$onChanges = changes => {
    if (changes.variables && vm.variables != null) {
      vm.inputParamConfigs = InputParametersParserService.parseApiToItemView(
        vm.variables,
        vm.hideDateTimeFilter
      );
    }
  };
}
