import './invoice-overview-item.scss';
import template from './invoice-overview-item.directive.html';
import { DateTime } from 'luxon';

invoiceOverviewItem.$inject = [];
/**
 * @ngdoc directive
 * @name invoice.invoiceOverviewItem
 * @description used to display single invoice at invoice overview page.
 * @param {Object} invoiceItem invoice object
 * @param {number} year selected year
 * @example
 * <invoice-overview-item
 *   invoice-item="invoiceItem"
 *   year="2018"
 * >
 */
function invoiceOverviewItem() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      invoiceItem: '=',
      year: '@'
    },
    controller: invoiceOverviewItemController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;
}

invoiceOverviewItemController.$inject = [
  'gettextCatalog',
  'EnergySourceTypeModel',
  'AlertingService',
  '$timeout'
];

function invoiceOverviewItemController(
  gettextCatalog,
  EnergySourceTypeModel,
  AlertingService,
  $timeout
) {
  var vm = this;
  var energyIcon;
  vm.energyIcon = energyIcon;
  vm.months = [
    gettextCatalog.getString('Jan'),
    gettextCatalog.getString('Feb'),
    gettextCatalog.getString('Mar'),
    gettextCatalog.getString('Apr'),
    gettextCatalog.getString('Maj'),
    gettextCatalog.getString('Jun'),
    gettextCatalog.getString('Jul'),
    gettextCatalog.getString('Avg'),
    gettextCatalog.getString('Sep'),
    gettextCatalog.getString('Oct'),
    gettextCatalog.getString('Nov'),
    gettextCatalog.getString('Dec')
  ];

  vm.openTooltip = openTooltip;

  /**
   * @description sets display values
   * @function
   */
  function init() {
    if (vm.invoiceItem.detailInvoices.length) {
      vm.invoiceItem.location = vm.invoiceItem.detailInvoices[0].location;
      vm.invoiceItem.locationName =
        vm.invoiceItem.detailInvoices[0].locationName;
      vm.invoiceItem.costCentre = vm.invoiceItem.detailInvoices[0].costCentre;
      vm.invoiceItem.costCentreName =
        vm.invoiceItem.detailInvoices[0].costCentreName;
      vm.invoiceItem.measuringPoint =
        vm.invoiceItem.detailInvoices[0].measuringPoint;
      vm.invoiceItem.measuringPointName =
        vm.invoiceItem.detailInvoices[0].measuringPointName;
      vm.invoiceItem.invoiceIssuer =
        vm.invoiceItem.detailInvoices[0].invoiceIssuer;
      vm.invoiceItem.invoiceIssuerName =
        vm.invoiceItem.detailInvoices[0].invoiceIssuerName;
      vm.invoiceItem.energySourceType =
        vm.invoiceItem.detailInvoices[0].energySourceType;
      vm.invoiceItem.energySourceTypeName =
        vm.invoiceItem.detailInvoices[0].energySourceTypeName;
      vm.invoiceItem.customer = vm.invoiceItem.detailInvoices[0].customer;
      vm.invoiceItem.customerName =
        vm.invoiceItem.detailInvoices[0].customerName;
    }
    getEnergySourceTypeIcon();
    createDetailInvoiceItems();
    vm.waterfallIsLoaded = true;
  }

  function onlyUnique(value, index, self) {
    var itemIndex = self.findIndex(item => {
      return item.detailInvoiceId === value.detailInvoiceId;
    });
    return itemIndex === index;
  }

  const findParentIsMonthSquareContainer = element => {
    if (element.classList != null) {
      if (element.classList.contains('month-square-container')) {
        return element;
      }
    }
    if (element.parentNode == null) {
      return null;
    }
    return findParentIsMonthSquareContainer(element.parentNode);
  };

  const clickOutsideEventListener = event => {
    const monthSquareElement = findParentIsMonthSquareContainer(event.target);
    if (monthSquareElement != null) {
      const invoiceId = monthSquareElement.getAttribute('measuring-point-id');
      const monthIndex = monthSquareElement.getAttribute('month-index');
      if (invoiceId === vm.invoiceItem.measuringPoint) {
        vm.invoices.forEach((item, index) => {
          if (index != monthIndex) {
            item.show = false;
          }
        });
      } else {
        vm.invoices.forEach(item => {
          item.show = false;
        });
      }
    } else {
      vm.invoices.forEach(item => {
        item.show = false;
      });
    }
    $timeout();
  };
  window.addEventListener('click', clickOutsideEventListener);
  vm.$onDestroy = () => {
    window.removeEventListener('resize', clickOutsideEventListener);
  };
  async function openTooltip(event, invoice) {
    vm.invoices.forEach(invoiceItem => (invoiceItem.show = false));
    invoice.show = true;
    if (event != null) {
      let parentElement = angular.element(event.currentTarget)[0];
      await $timeout();
      const arrowElement = parentElement.getElementsByClassName(
        'show-redirect-invoices-tooltip__arrow'
      )[0];

      const right = parentElement.getBoundingClientRect().right;
      const left = parentElement.getBoundingClientRect().left;
      arrowElement.style.left = (right - left) / 2 + 'px';
    }
  }
  /**
   * @description sets color and redirect parameters to master invoice detail invoice badges.
   * @function
   */
  function createDetailInvoiceItems() {
    const uniqMPInvoices = vm.invoiceItem.detailInvoices.filter(onlyUnique);
    var invoice;
    var existingInvoice;
    var currentDate = new Date();
    vm.invoices = vm.months.map(function(_, index) {
      existingInvoice = uniqMPInvoices.find(invoice => {
        const { normalizedServiceDateFrom, normalizedServiceDateTo } = invoice;
        const month = index + 1;
        const from = DateTime.fromObject({
          months: normalizedServiceDateFrom.month,
          years: normalizedServiceDateFrom.year
        }).startOf('month');
        const to = DateTime.fromObject({
          months: normalizedServiceDateTo.month,
          years: normalizedServiceDateTo.year
        }).endOf('months');
        const current = DateTime.fromObject({ month: month, years: vm.year });
        return from <= current && to >= current;
      });
      if (existingInvoice) {
        const currentMonthInvoices = uniqMPInvoices.filter(invoice => {
          const {
            normalizedServiceDateFrom,
            normalizedServiceDateTo
          } = invoice;
          const month = index + 1;
          const from = DateTime.fromObject({
            months: normalizedServiceDateFrom.month,
            years: normalizedServiceDateFrom.year
          }).startOf('month');
          const to = DateTime.fromObject({
            months: normalizedServiceDateTo.month,
            years: normalizedServiceDateTo.year
          }).endOf('months');
          const current = DateTime.fromObject({
            month: month,
            years: vm.year
          });
          return from <= current && to >= current;
        });
        const currentMonthSupplierInvoices = currentMonthInvoices.filter(
          invoice => {
            return (
              invoice.invoiceIssuer === existingInvoice.measuringPointSupplierId
            );
          }
        );
        const currentMonthDistributerInvoices = currentMonthInvoices.filter(
          invoice => {
            return (
              invoice.invoiceIssuer ===
              existingInvoice.measuringPointDistributorId
            );
          }
        );

        if (
          currentMonthSupplierInvoices.length > 0 &&
          currentMonthDistributerInvoices.length > 0
        ) {
          invoice = {
            color: 'green',
            redirectParams: currentMonthInvoices.map(invoice => ({
              redirectParam: {
                invoiceId: invoice.masterInvoice
              },
              invoiceName: invoice.masterInvoiceNumber
            }))
          };
        } else {
          invoice = {
            color: 'orange',
            redirectParams: currentMonthInvoices.map(invoice => ({
              redirectParam: {
                invoiceId: invoice.masterInvoice
              },
              invoiceName: invoice.masterInvoiceNumber
            }))
          };
        }
      } else {
        var currentIndexDate = new Date(vm.year, index, 1);
        if (currentIndexDate < currentDate) {
          invoice = {
            color: 'red'
          };
        } else {
          invoice = {
            color: 'primary',
            whiteButton: true,
            raised: true,
            afterCurrentMonth: true
          };
        }
      }
      return invoice;
    });
  }
  /**
   * @description fetches energy source type and setc icon value.
   * @function
   */
  function getEnergySourceTypeIcon() {
    EnergySourceTypeModel.read({
      id: vm.invoiceItem.detailInvoices[0].energySourceType
    }).then(
      res => {
        vm.energyIcon = res.data.icon;
      },
      err => {
        AlertingService.Error(err);
      }
    );
  }

  this.$onInit = init;
}

export default invoiceOverviewItem;
