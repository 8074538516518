import template from './sfe-file-uploader.directive.html';

sfeFileUploader.$inject = [];

export default function sfeFileUploader() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      fileArray: '=',
      single: '<',
      endpoint: '<',
      type: '<',
      filters: '<',
      queueLimit: '<',
      alias: '<',
      fileUploader: '='
    },
    link: linkFn,
    controller: sfeFileUploaderController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  function linkFn() {}
}

sfeFileUploaderController.$inject = ['FileUploader', '$smartAuth', '$q'];

function sfeFileUploaderController(FileUploader, $smartAuth, $q) {
  var vm = this;
  var token = $smartAuth.getToken();
  var allowedImages = ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml'];
  var suffix = ['B', 'KB', 'MB'];
  vm.isImage = isImage;
  vm.addToQueue = addToQueue;
  vm.removeFromQueue = removeFromQueue;
  vm.$onInit = init;

  function init() {
    vm.fileUploader = new FileUploader({
      url: vm.endpoint,
      headers: {
        Authorization: 'Bearer ' + token
      },
      withCredentials: true,
      queueLimit: vm.queueLimit || 1,
      alias: vm.alias || 'file'
    });

    vm.fileUploader.onAfterAddingFile = function(item) {
      item.file.prettySize = prettySize(item.file.size);
      if (!item.file.type) {
        var type = iconTypesMissing[getType(item.file)];
        item.file.type = type ? type : '';
      }
      if (!vm.fileArray) {
        vm.fileArray = [];
      }
      // if picture, encode it to base64 for thumbnail
      if (isImage(item.file.type)) {
        prepareDataUrl(item._file)
          .then(function(file) {
            item.dataUrl = file;
          })
          .catch(function() {});
      }
      if (vm.single) {
        vm.fileUploader.queue = [item];
        vm.fileArray = [item];
      } else {
        vm.fileArray.push(item);
      }
    };
  }

  function prettySize(size) {
    for (var count = 0; !(size < 1000); count++) {
      size /= 1024;
    }
    return size.toFixed(2).toString() + suffix[count];
  }

  function isImage(imageType) {
    return allowedImages.indexOf(imageType) > -1;
  }

  function prepareDataUrl(image) {
    var deferred = $q.defer();
    var reader = new FileReader();
    reader.onload = function() {
      deferred.resolve(reader.result);
    };
    // expects raw image File
    reader.readAsDataURL(image);
    return deferred.promise;
  }

  vm.iconTypes = {
    'video/x-msvideo': 'fa-file-video-o',
    'video/mpeg': 'fa-file-video-o',
    'video/ogg': 'fa-file-video-o',
    'video/avi': 'fa-file-video-o',
    'video/webm': 'fa-file-video-o',
    'audio/midi': 'fa-file-audio-o',
    'audio/wav': 'fa-file-audio-o',
    'audio/mp3': 'fa-file-audio-o',
    'application/octet-stream': 'fa-code',
    'text/css': 'fa-code',
    'text/csv': 'fa-file-excel-o',
    'text/html': 'fa-code',
    'application/json': 'fa-code',
    'application/xhtml+xml': 'fa-code',
    'application/x-bzip': 'fa-file-archive-o',
    'application/x-bzip2': 'fa-file-archive-o',
    'application/java-archive': 'fa-file-archive-o',
    'application/x-rar-compressed': 'fa-file-archive-o',
    'application/x-tar': 'fa-file-archive-o',
    'application/zip': 'fa-file-archive-o',
    'application/x-7z-compressed': 'fa-file-archive-o',
    'application/x-zip-compressed': 'fa-file-archive-o',
    'application/msword': 'fa-file-text-o',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'fa-file-text-o',
    'application/epub+zip': 'fa-file-text-o',
    'application/vnd.oasis.opendocument.text': 'fa-file-text-o',
    'application/pdf': 'fa-file-pdf-o',
    'application/rtf': 'fa-file-text-o',
    'application/vnd.ms-excel': 'fa-file-text-o',
    'text/plain': 'fa-file-text-o',
    'application/vnd.ms-powerpoint': 'fa-file-powerpoint-o',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      'fa-file-powerpoint-o'
  };

  var iconTypesMissing = {
    rtf: 'application/rtf',
    doc: 'application/msword',
    docx: 'application/msword',
    rar: 'application/x-rar-compressed',
    '7z': 'application/x-7z-compressed'
  };

  function getType(file) {
    return file.name.split('.').pop();
  }

  function removeFromQueue(index) {
    var item = vm.fileArray[index];
    vm.fileArray.splice(index, 1);
    if (item.headers) {
      _.remove(vm.fileUploader.queue, function(fileItem) {
        return item.file.name === fileItem.file.name;
      });
    }
  }

  function addToQueue() {
    angular.element(document.querySelector('#file-upload-input')).click();
  }
}
