import template from './sfe-error-message.component.html';
/**
 * @description component that displays an error messages based on the parameters that it received
 * @function
 * @param {array} customValidation - array containg objects with custom validation ifnroamtion
 * @param {dataType} name - name of the error type
 * @param {dataType} configuration - specific error configuration (for example specific character patern that needs to be followed)
 */
export default {
  template,
  bindings: {
    customValidation: '<',
    name: '<',
    configuration: '<'
  },
  require: {
    form: '^^form'
  },
  controller: ErrorMessageController,
  controllerAs: 'vm'
};

ErrorMessageController.$inject = [];

function ErrorMessageController() {
  let vm = this;
  vm.$onChanges = () => {};
}
