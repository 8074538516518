/**
 * @ngdoc service
 * @name common.AddFlowConfiguration
 * @description Opens create form dialog
 */

AddFlowConfiguration.$inject = [
  'gettextCatalog',
  'SfeForm2Dialog',
  'ExternalReaderFlowFormConfigurations',
  'ExternalWriterFlowFormConfigurations',
  'CalculatedFlowFormConfigurations',
  'MtAnalysisFlowFormConfigurations',
  'MaintenanceFlowFormConfigurations',
  'DateLocalizationService'
];
export default function AddFlowConfiguration(
  gettextCatalog,
  SfeForm2Dialog,
  ExternalReaderFlowFormConfigurations,
  ExternalWriterFlowFormConfigurations,
  CalculatedFlowFormConfigurations,
  MtAnalysisFlowFormConfigurations,
  MaintenanceFlowFormConfigurations,
  DateLocalizationService
) {
  /**
   * @description get form dialog based on flow type.
   * called when click on addFlow button on item view
   * @function
   * @param {Object} flow
   * @param {string} timeSeriesId
   * @param {date} lastItemValidToDate
   * @return {Object} config
   */
  async function getFormDialog(flowTypeRef, timeSeriesId, lastItemValidToDate) {
    let config;
    const validFrom = getNewValidFrom(lastItemValidToDate);
    switch (flowTypeRef) {
    // External reader flow.
    case 100:
      config = await ExternalReaderFlowFormConfigurations.get({
        timeSeriesId,
        actionType: 'create'
      });
      break;
      // External writer flow.
    case 200:
      config = await ExternalWriterFlowFormConfigurations.get({
        timeSeriesId,
        actionType: 'create'
      });
      break;
      //Calculated flow
    case 300:
      config = await CalculatedFlowFormConfigurations.get({
        timeSeriesId,
        actionType: 'create'
      });
      break;
      //M&T Analysis Flow
    case 320:
      config = await MtAnalysisFlowFormConfigurations.get({
        timeSeriesId,
        actionType: 'create'
      });
      break;
      //Maintenance flow
    case 500:
      config = await MaintenanceFlowFormConfigurations.get({
        timeSeriesId,
        actionType: 'create'
      });
      break;
    }
    if (config != null && Array.isArray(config.fields)) {
      config.fields = [validFrom, ...config.fields];
    }

    return config;
  }
  /**
   * @description returns validFrom field configuration for a new flow.
   * @function
   * @param {Date} lastItemValidToDate
   * @return {Object}
   */
  const getNewValidFrom = lastItemValidToDate => {
    let toMilliseconds = new Date(lastItemValidToDate).getTime();
    let validTo = DateLocalizationService.LocalizationDateFn(toMilliseconds);
    const validFrom = {
      id: 'validFrom',
      title: gettextCatalog.getString('Valid from'),
      type: {
        name: 'date',
        options: {
          enableTime: true,
          enableSeconds: true
        }
      },
      initialize: () => {
        return null;
      },
      validators: {
        noFutureDates: {
          fn: modelValue => {
            if (modelValue != null && modelValue[0] > new Date()) {
              return false;
            }
            return true;
          },
          text: gettextCatalog.getString('Must be before or todays date')
        },
        greaterThanLastItem: {
          fn: modelValue => {
            if (
              modelValue != null &&
              lastItemValidToDate != null &&
              modelValue[0] <= new Date(lastItemValidToDate)
            ) {
              return false;
            }
            return true;
          },
          text: gettextCatalog.getString(
            `Must be after previous date "${validTo}"`
          )
        }
      },
      required: true,
      width: 12
    };
    return validFrom;
  };

  /**
   * @description open form dialog.
   * @function
   * @param {Object} flow
   * @param {string} timeSeriesId
   * @param {date} lastItemValidToDate
   */
  const get = async (flowTypeRef, timeSeriesId, lastItemValidToDate) => {
    let formConfiguration = await getFormDialog(
      flowTypeRef,
      timeSeriesId,
      lastItemValidToDate
    );
    SfeForm2Dialog.open(formConfiguration);
  };

  return { get, getNewValidFrom };
}
