InvoiceController.$inject = [
  '$state',
  'gettext',
  'AlertingService',
  'ToastService',
  'gettextCatalog',
  'InvoiceDetailConfiguration',
  'MetadataService',
  'MasterInvoiceModel',
  'TranslationService',
  'masterInvoice',
  '$timeout'
];

function InvoiceController(
  $state,
  gettext,
  AlertingService,
  ToastService,
  gettextCatalog,
  InvoiceDetailConfiguration,
  MetadataService,
  MasterInvoiceModel,
  TranslationService,
  masterInvoice,
  $timeout
) {
  var vm = this;
  var id = $state.params.invoiceId;
  const invoicesStatusConfiguration = {
    type: 'code',
    title: gettext('Invoice status')
  };
  const invoiceInputSource = {
    title: gettext('Input Source'),
    type: 'simple'
  };
  vm.loading = true;
  vm.filter = {
    masterInvoice: $state.params.invoiceId
  };
  // function that updates invoiceId in the invoice detail configuration
  InvoiceDetailConfiguration.update();

  init();
  /**
   * @description function run initialization. Gets invoice data and passes it to the construct header function
   * @function
   */
  async function init() {
    MetadataService.Loading(false);
    vm.headerData = constructHeader(masterInvoice);

    $timeout(() => {
      MetadataService.ChangeMetadata(
        masterInvoice.number,
        vm.headerData.metadata.description
      );
    });
    vm.loading = false;
  }

  /**
   * @description constructs the invoice header.
   * @function
   * @param {Object} invoice - invoice data acquired from BE
   * @return {Object} - an object containing all item header properties.
   */
  function constructHeader(invoice) {
    let { propertySections, actions } = setTotal(invoice);
    var propertySectionArray = [].concat(
      setProperties(invoice),
      setDate(invoice),
      propertySections
    );
    return {
      metadata: {
        definition: 'Master invoice',
        title: invoice.number,
        description: gettextCatalog.getString('Book value: {{bookValue}}', {
          bookValue: invoice.bookValue
        })
      },
      actions: actions,
      propertySections: propertySectionArray
    };
  }
  /**
   * @description Function that creates header properties .
   * @function
   * @param {Object} invoice - Object containing invoice data
   * @return {Array} an array containing all header properties
   */
  function setProperties(invoice) {
    var properties = [];
    if (invoice.invoiceIssuer) {
      properties.push({
        linkTitle: invoice.invoiceIssuer.name,
        state: 'company-resources-business-partners-view',
        param: 'id',
        paramValue: invoice.invoiceIssuer._id,
        type: 'link',
        title: gettext('Invoice issuer')
      });
    }
    var userAccess = [];

    userAccess.push(
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    );

    if (invoice.businessPartnerCustomer) {
      properties.push({
        linkTitle: invoice.businessPartnerCustomer.name,
        state: 'company-resources-business-partners-view',
        param: 'id',
        paramValue: invoice.businessPartnerCustomer._id,
        type: 'link',
        title: gettext('Customer')
      });
    }

    if (invoice.externalCode) {
      properties.push({
        type: 'code',
        title: gettext('External code'),
        value: invoice.externalCode
      });
    }

    if (invoice.invoiceStatus) {
      const value = TranslationService.GetCollectionById(
        'codelists.invoiceStatuses',
        invoice.invoiceStatus
      );
      invoicesStatusConfiguration.value = value
        ? value.name
        : gettext('unknown');
      properties.push(invoicesStatusConfiguration);
    }
    if (invoice.inputSource) {
      const value = TranslationService.GetCollectionById(
        'codelists.invoiceInputSources',
        invoice.inputSource
      );
      invoiceInputSource.value = value ? value.name : gettext('unknown');
      properties.push(invoiceInputSource);
    }
    if (invoice.urlDocument) {
      properties.push({
        type: 'simple',
        title: gettext('URL document'),
        value: invoice.urlDocument
      });
    }

    if (properties.length) {
      return [
        {
          title: gettext('Properties'),
          properties: properties
        },
        {
          title: gettext('Authorized personnel'),
          properties: userAccess,
          identifier: 'master-invoices',
          target: invoice._id,
          type: 'userAccess'
        }
      ];
    }
    return [];
  }
  /**
   * @description uses invoice data to create an object containing propertySection
   * and action arrays that are used in constructing a header.
   * @function
   * @param {Object} invoice - Object containing invoice data
   * @return {Object} object containing the enriched values
   */
  function setTotal(invoice) {
    var propertySections = [];
    var actions = [];
    if (!invoice.total) {
      invoice.total = 0;
    }

    if (!invoice.totalWithVat) {
      invoice.totalWithVat = 0;
    }
    propertySections.push({
      title: gettext('Total'),
      properties: [
        {
          type: 'simple',
          title: gettext('Total with VAT:'),
          value: invoice.totalWithVat
        },
        {
          type: 'simple',
          title: gettext('Total without VAT:'),
          value: invoice.total
        }
      ]
    });

    const changeInvoiceStatus = {
      title:
        invoice.invoiceStatus == 21
          ? gettext('Open invoice')
          : gettext('Finalize invoice'),
      __identifier__: 'changeInvoiceStatus',
      script: true,
      fn: async () => {
        try {
          const { data: updatedInvoice } = await MasterInvoiceModel.update(
            { id: id },
            { invoiceStatus: invoice.invoiceStatus == 21 ? 1 : 21 }
          );
          invoice.invoiceStatus = updatedInvoice.invoiceStatus;

          const value = TranslationService.GetCollectionById(
            'codelists.invoiceStatuses',
            updatedInvoice.invoiceStatus
          );
          invoicesStatusConfiguration.value = value
            ? value.name
            : gettext('unknown');
          //reset header after metadata changing
          vm.headerData = constructHeader(masterInvoice);

          ToastService.showToast(
            updatedInvoice.invoiceStatus == 21
              ? gettextCatalog.getString(
                'Invoice has been successfully finalized'
              )
              : gettextCatalog.getString('Invoice has been successfully opened')
          );
        } catch (err) {
          AlertingService.Error(err);
        }
      },
      disabledFn: () => invoice.bookValue != 0
    };
    actions.push(
      changeInvoiceStatus,
      {
        title: gettext('Distribute'),
        script: true,
        fn: distributeBookValue,
        disabledFn: () => Math.abs(invoice.bookValue) >= 1
      },
      {
        title: gettext('delete'),
        delete: true,
        id: invoice._id,
        endpoint: {
          entity: 'master-invoices',
          method: 'delete'
        },
        redirectState: 'invoices-invoices'
      },
      {
        title: gettext('Duplicate'),
        state: 'invoices-invoices-duplicate',
        param: 'invoiceId',
        paramValue: invoice._id
      },
      {
        title: gettext('Update'),
        state: 'invoices-invoices-edit',
        param: 'invoiceId',
        paramValue: invoice._id
      }
    );
    return {
      actions: actions,
      propertySections: propertySections
    };
  }
  /**
   * @description function called when we want to distribute book value (if the numbers don't slightly add up).
   * @function
   */
  async function distributeBookValue() {
    try {
      const { data } = await MasterInvoiceModel.update(
        {
          id: id,
          distribute: true
        },
        {}
      );
      masterInvoice.bookValue = data.bookValue;
      ToastService.showToast(
        gettext('Book value was successfully distributed')
      );
      vm.relistDetailInvoices = true;
      init();
    } catch (err) {
      AlertingService.Error(err);
    }
  }
  /**
   * @description creates a date from bullingDate property from invoice object.
   * @function
   * @param {Object} invoice - Object containing invoice data
   * @return {Array} enriched billing config for header.
   */
  function setDate(invoice) {
    if (invoice.billingDate) {
      return [
        {
          title: gettext('Dates'),
          properties: [
            {
              type: 'date',
              title: gettext('Billing date:'),
              value: invoice.billingDate,
              dateFormat: 'dd. MM. yyyy'
            }
          ]
        }
      ];
    }
    return [];
  }
}

export default InvoiceController;
