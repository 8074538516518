import './sfe-form-2-element.scss';
import template from './sfe-form-2-element.component.html';

/**
 * @ngdoc component
 * @name common.sfeForm2Element
 * @description Component for display and handling of the form. It is to be used only in tandem with sfe-form-2.
 * @param {Object} config - field configuration
 * @param {Object} data - data Object
 * @example
 * <sfe-form-2
 *   config="vm.config"
 *   data="vm.data"
 * ></sfe-form-2>
 */

export default {
  template,
  bindings: {
    config: '<',
    value: '=',
    api: '<'
  },
  controllerAs: 'vm',
  bindToController: true,
  require: {
    form: '^form'
  }
};
