import './map.scss';
import './map-show.scss';

GisMapController.$inject = [
  '$stateParams',
  'utilService',
  '$smartAuth',
  'AlertingService',
  'gettext',
  'GisMapModel'
];

function GisMapController(
  $stateParams,
  utilService,
  $smartAuth,
  AlertingService,
  gettext,
  GisMapModel
) {
  var gisMapId = $stateParams.id;
  init();

  function init() {
    var waterfall = [fetchMap, fetchUserToken];

    async.waterfall(waterfall, function(err, map, user) {
      if (err) {
        AlertingService.Error(err);
      } else {
        if (user.gisUserToken) {
          var mapConfiguration = {
            url: utilService.getHostNoPrefix('gis') + '/maps/' + map.name,
            scale: 10000,
            token: user.gisUserToken,
            height: '100%',
            width: '100%',
            template: 'app'
          };
          new gms.Map('gismap', mapConfiguration);
        } else {
          AlertingService.Error(gettext('No gis token found'));
        }
      }
    });
  }

  function fetchUserToken(map, callback) {
    $smartAuth.profile().then(
      function(user) {
        callback(null, map, user);
      },
      function(err) {
        callback(err);
      }
    );
  }

  function fetchMap(callback) {
    GisMapModel.read({
      id: gisMapId
    }).then(
      function(res) {
        callback(null, res.data);
      },
      function(err) {
        callback(err);
      }
    );
  }
}

export default GisMapController;
