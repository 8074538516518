import './new-mapping-rule.scss';

NewMappingRuleController.$inject = [
  'AlertingService',
  'mapper',
  'gettext',
  '$stateParams',
  '$state',
  'TranslationService',
  'MetadataService',
  'createOrUpdateService',
  'SfeHeader',
  '$scope',
  '$timeout'
];

function NewMappingRuleController(
  AlertingService,
  mapper,
  gettext,
  $stateParams,
  $state,
  TranslationService,
  MetadataService,
  createOrUpdateService,
  SfeHeader,
  $scope,
  $timeout
) {
  const vm = this;
  let mappingRuleId = $stateParams.id;
  let noChangeFunction = TranslationService.GetCollectionById(
    'codelists.dataTypeMappingFunctions',
    8
  );
  let nullFunction = TranslationService.GetCollectionById(
    'codelists.dataTypeMappingFunctions',
    1
  );
  // set triggerRefreshFn for binding
  vm.duplicateMode =
    $state.current.name === 'alarms-and-rules-mapping-rules-duplicate';
  vm.mapper = null;
  vm.title = 'New ';
  vm.dataConfig = {
    PH16: true
  };
  vm.refreshArgument = 0;
  vm.createOrUpdate = [
    {
      title:
        mappingRuleId && !vm.duplicateMode
          ? gettext('Update')
          : gettext('Create'),
      fn: createOrUpdate,
      color: 'primary',
      raised: true,
      disabledFn: function() {
        let hasMappingRules = false;
        if (
          Array.isArray(vm.formats) &&
          Array.isArray(vm.defaults) &&
          vm.formats.length > 0 &&
          vm.defaults.length > 0
        ) {
          hasMappingRules = true;
        }
        return (
          $scope.MappingRuleForm.$invalid ||
          (hasMappingRules === false && mappingRuleId == null) ||
          vm.sendingRequest
        );
      }
    }
  ];
  vm.literalsConfig = {
    format: false,
    default: false,
    operators: true,
    description: gettext(
      'Input value represents a value received. Select a function to transform it to the desired output. If this should trigger an error, alarm or signal a manual check, flag it so.'
    )
  };
  vm.formatsConfig = {
    format: true,
    default: false,
    operators: false,
    description: gettext(
      'If the format of received value is not appropriate, specify the appropriate format to remap it using a predefined function. If this should trigger an error, alarm or signal a manual check, flag it so.'
    )
  };
  vm.defaultsConfig = {
    format: false,
    default: true,
    operators: false,
    description: gettext(
      'When the input value is not in the specified data point format and  none of the above rules apply, use this to remap it to a custom value. If this should trigger an error, alarm or signal a manual check, flag it so.'
    )
  };

  function resetDataTypes() {
    vm.literalsConfig.type = vm.dataConfig.dataObj.dataType;
    vm.formatsConfig.type = vm.dataConfig.dataObj.dataType;
    vm.defaultsConfig.type = vm.dataConfig.dataObj.dataType;
  }

  // init
  vm.$onInit = () => {
    vm.editMode = !!mappingRuleId;
    vm.dataConfig.data = getData();

    if (vm.editMode) {
      getMapper();

      MetadataService.Loading(true);
      if (vm.duplicateMode) {
        vm.dataConfig.header = SfeHeader.constructFormHeader(
          'primary',
          gettext('Duplicate mapping rule'),
          'alarms-and-rules-mapping-rules-view',
          { id: mappingRuleId }
        );

        $timeout(() => {
          MetadataService.ChangeMetadata(
            'Duplicate ' + mapper.name,
            mapper.description
          );
          MetadataService.Loading(false);
        });

        constructMappingRules(mapper);
      } else {
        vm.dataConfig.header = SfeHeader.constructFormHeader(
          'primary',
          gettext('Edit mapping rule'),
          'alarms-and-rules-mapping-rules-view',
          { id: mappingRuleId }
        );
        $timeout(() => {
          MetadataService.ChangeMetadata(
            'Edit ' + mapper.name,
            mapper.description
          );
          MetadataService.Loading(false);
        });
      }
      vm.title = vm.duplicateMode ? 'Duplicate ' : 'Update ';
    } else {
      vm.newMode = true;
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New mapping rule'),
        'alarms-and-rules-mapping-rules-list'
      );
      vm.dataConfig.dataObj = {
        name: '',
        description: ''
      };
      vm.literals = [];
      vm.defaults = [];
    }
  };

  function getMapper() {
    vm.dataConfig.dataObj = {
      name: mapper.name,
      description: mapper.description,
      dataType: mapper.dataType
    };
    if (
      mapper.literalMappings.length ||
      mapper.formatMappings.length ||
      mapper.defaultMappings.length
    ) {
      let typeConfig = _.find(vm.dataConfig.data, {
        name: 'dataType'
      });
      if (typeConfig && typeConfig.config) {
        typeConfig.config.disabledValue = true;
      }
    }
    MetadataService.Loading(false);
  }

  function constructMappingRules(mapper) {
    let literals = [];
    let outputExpression;
    vm.formatsConfig.type = mapper.dataType;
    vm.literalsConfig.type = mapper.dataType;
    vm.defaultsConfig.type = mapper.dataType;
    if (mapper.literalMappings) {
      mapper.literalMappings.forEach(function(mappingRule) {
        outputExpression = mappingRule.outputExpression;
        if (
          outputExpression.args &&
          outputExpression.args.length &&
          outputExpression.args[0]
        ) {
          outputExpression.args = outputExpression.args[0].value;
        }
        literals.push({
          ...mappingRule,
          inputDataType: mapper.dataType,
          inputExpression: mappingRule.inputExpression
            .replace('^', '')
            .replace('$', '')
        });
      });
    }
    vm.literals = literals;

    let formats = [];
    if (mapper.formatMappings) {
      mapper.formatMappings.forEach(function(mappingRule) {
        outputExpression = mappingRule.outputExpression;
        if (
          outputExpression.args &&
          outputExpression.args.length &&
          outputExpression.args[0]
        ) {
          outputExpression.args = outputExpression.args[0].value;
        }
        formats.push({
          ...mappingRule,
          inputExpression: mappingRule.inputDataType,
          requireArgument: !!mappingRule.outputExpression.args
        });
      });
    }
    vm.formats = formats;

    let defaultMappings = [];
    if (mapper.defaultMappings) {
      mapper.defaultMappings.forEach(function(mappingRule) {
        outputExpression = mappingRule.outputExpression;
        if (
          outputExpression.args &&
          outputExpression.args.length &&
          outputExpression.args[0]
        ) {
          outputExpression.args = outputExpression.args[0].value;
        }
        defaultMappings.push({
          ...mappingRule,
          inputDataType: mapper.dataType,
          requireArgument: !!mappingRule.outputExpression.args
        });
      });
    }
    vm.defaults = defaultMappings;
    vm.refreshArgument += 1;
  }

  // helper functions
  function resetFn(arrayToReset) {
    if (arrayToReset) {
      arrayToReset.forEach(function(element) {
        element['function'] = '';
        element.requireArgument = false;
        element.argument = '';
      });
    }
  }

  function resetFunctions() {
    resetFn(vm.literals);
    resetFn(vm.formats);
    resetFn(vm.defaults);
  }

  function getData() {
    return [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textArea',
        maxlength: 500,
        type: 'text',
        required: false
      },
      {
        title: gettext('Data type'),
        componentType: 'title'
      },
      {
        componentType: 'autocompleteDialog',
        edit: vm.editMode,
        configuration: {
          required: true,
          disabled: vm.editMode,
          change: function() {
            if (
              vm.dataConfig.dataObj.dataType &&
              vm.dataConfig.dataObj.dataType.__prefetched__value__
            ) {
              return;
            }
            vm.literals = [];
            vm.defaults = [
              {
                outputExpression: {
                  function: nullFunction
                },
                requireArgument: false,
                inputDataType: vm.dataConfig.dataObj.dataType,
                dataTypeChanged: true
              }
            ];
            vm.formats = [
              {
                outputExpression: {
                  function: noChangeFunction
                },
                requireArgument: false,
                inputDataType: vm.dataConfig.dataObj.dataType,
                dataTypeChanged: true
              }
            ];
            resetDataTypes();
            resetFunctions();
          },
          codelist: 'dataTypes',
          entity: 'entity',
          noDialog: true,
          floatingLabel: gettext('Type'),
          searchParamName: 'name',
          selectedParam: 'id'
        },
        name: 'dataType'
      }
    ];
  }

  // create and update functions //
  function createOrUpdate() {
    vm.sendingRequest = true;
    let dataToSave =
      vm.editMode && !vm.duplicateMode ? dataToPut() : dataToPost();
    createOrUpdateService
      .simpleCall(
        {
          entity: 'mappers',
          method:
            vm.editMode && !vm.duplicateMode ? 'update' : 'custom.createV1'
        },
        vm.editMode,
        vm.duplicateMode ? null : mappingRuleId,
        dataToSave,
        'alarms-and-rules-mapping-rules-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function dataToPut() {
    return {
      name: vm.dataConfig.dataObj.name,
      description: vm.dataConfig.dataObj.description,
      dataType: vm.dataConfig.dataObj.dataType.id
    };
  }

  function dataToPost() {
    let data = dataToPut();
    let litMappings = [];
    let formatMappings = [];
    let defaultMappings = [];
    let argName;
    if (vm.literals) {
      vm.literals.forEach(function(element) {
        argName = getArgName(element);
        litMappings.push({
          inputExpression: '^' + (element.inputExpression || '') + '$',
          outputExpression: {
            function: element.outputExpression.function.id,
            args: argName
              ? [
                {
                  value: element.outputExpression.args,
                  name: argName
                }
              ]
              : null
          },
          isManualIntervention: Boolean(element.isManualIntervention),
          triggersAlarm: Boolean(element.triggersAlarm),
          alarm: element.triggersAlarm ? element.alarm._id : undefined,
          isError: Boolean(element.isError)
        });
      });
    }
    if (vm.formats) {
      const formats = angular.copy(vm.formats);
      formats.forEach(function(element) {
        argName = getArgName(element);
        formatMappings.push({
          inputDataType: element.inputDataType.id,
          inputExpression: element.inputDataType.id,
          outputExpression: {
            function: element.outputExpression.function.id,
            args: argName
              ? [
                {
                  value: element.outputExpression.args,
                  name: argName
                }
              ]
              : null
          },
          isManualIntervention: Boolean(element.isManualIntervention),
          triggersAlarm: Boolean(element.triggersAlarm),
          alarm: element.triggersAlarm ? element.alarm._id : undefined,
          isError: Boolean(element.isError)
        });
      });
    }

    if (vm.defaults) {
      vm.defaults.forEach(function(element) {
        argName = getArgName(element);
        defaultMappings.push({
          outputExpression: {
            function: element.outputExpression.function.id,
            args: argName
              ? [
                {
                  value: element.outputExpression.args,
                  name: argName
                }
              ]
              : null
          },
          isManualIntervention: Boolean(element.isManualIntervention),
          triggersAlarm: Boolean(element.triggersAlarm),
          alarm: element.triggersAlarm ? element.alarm._id : undefined,
          isError: Boolean(element.isError)
        });
      });
    }

    data.literalMappings = litMappings;
    data.formatMappings = formatMappings;
    data.defaultMappings = defaultMappings;

    return data;
  }

  function getArgName(element) {
    let functionLIst = TranslationService.GetCollection(
      'codelists.dataTypeMappingFunctions'
    );
    let selectedFunction = functionLIst.find(function(fn) {
      return fn.id === element.outputExpression.function.id;
    });
    let argName;
    if (selectedFunction && selectedFunction.arguments) {
      argName = selectedFunction.arguments[0];
    }
    return argName;
  }
}

export default NewMappingRuleController;
