TriggeredAlarmsListController.$inject = [
  '$state',
  'AlarmMonitoringConfiguration'
];

function TriggeredAlarmsListController($state, AlarmMonitoringConfiguration) {
  const vm = this;
  vm.$onInit = init;

  function init() {
    let override = false;
    const filters = angular.copy(AlarmMonitoringConfiguration.filter);
    if (Array.isArray(filters)) {
      if ($state.params.severity) {
        const severityFilter = filters.find(
          filter => filter.param == 'alarm.severity'
        );
        if (severityFilter) {
          override = true;
          severityFilter.selected = [$state.params.severity];
        }
      }
      if ($state.params.responsible) {
        const personResponsibleFilter = filters.find(
          filter => filter.param == 'responsiblePerson'
        );
        if (personResponsibleFilter) {
          override = true;
          personResponsibleFilter.selected = [$state.params.responsible];
        }
      }
      if ($state.params.assignee) {
        const assigneeFilter = filters.find(
          filter => filter.param == 'currentAssignee'
        );
        if (assigneeFilter) {
          override = true;
          assigneeFilter.selected = [$state.params.assignee];
        }
      }
    }
    if (override) {
      vm.filterValuesOverride = filters;
    }
  }
}

export default TriggeredAlarmsListController;
