import template from './sfe-mt-analysis.component.html';
import './sfe-mt-analysis.component.scss';
import { DateTime } from 'luxon';
export default {
  template,
  bindings: {
    mtAnalysis: '<',
    dashboardSettings: '<'
  },
  controller: sfeMtAnalysisController,
  controllerAs: 'vm'
};
/**
 * @ngdoc component
 * @name data.sfeMtAnalysisController
 * @description displays mt analysis charts and baselines.
 * @param {Object} mtAnalysis mt analysis flow object must contain timeSeriesObject
 * @param {Object} dashboardSettings dashboard setting object {refresh, cacheInvalidationTime, uniqueCacheId}
 * @example
 */
sfeMtAnalysisController.$inject = [
  'AlertingService',
  'sfeMtAnalysisService',
  'gettextCatalog',
  'BaseLineConfiguration',
  'FilterDialog',
  'Refreshing'
];

function sfeMtAnalysisController(
  AlertingService,
  sfeMtAnalysisService,
  gettextCatalog,
  BaseLineConfiguration,
  FilterDialog,
  Refreshing
) {
  const vm = this;
  let activeMtAnalysis = null;

  vm.valuesApiDataX = {};
  vm.patternApiDataX = {};

  let refresherId, refresherFnId;

  vm.$onChanges = changes => {
    if (
      changes.mtAnalysis &&
      vm.mtAnalysis != null &&
      typeof vm.mtAnalysis == 'object'
    ) {
      let { timeSeriesObject } = vm.mtAnalysis;
      if (timeSeriesObject == null || typeof timeSeriesObject != 'object') {
        AlertingService.Error(
          'Time Series is missing to construct mt analysis'
        );
        return;
      } else {
        initializeMtAnalysisConfiguration(vm.mtAnalysis);
        if (!vm.dashboardSettings) {
          initBaselineConfiguration(vm.mtAnalysis);
        }
      }
    }
    //SET REFRESHING
    if (
      changes.dashboardSettings &&
      vm.dashboardSettings != null &&
      vm.dashboardSettings.refresher != null &&
      refresherId == null &&
      refresherFnId == null
    ) {
      ({ refresherId, refresherFnId } = vm.dashboardSettings.refresher(() => {
        vm.valuesApiDataX.refresh();
      }));
    }
  };
  //REMOVE REFRESHING
  vm.$onDestroy = () => {
    if (refresherId) {
      Refreshing.removeFn(refresherId, refresherFnId);
    }
  };

  /**
   * @description Callback called after baseline is created and baseline list is re-listed. Adds series to a chart
   * @function
   */
  function baseLineCreateCallback(baseLine) {
    if (baseLine) {
      let newMtAnalysis = {
        ...activeMtAnalysis,
        targetFunctions: [...activeMtAnalysis.targetFunctions, baseLine]
      };

      initializeMtAnalysisConfiguration(newMtAnalysis);
    }
  }

  /**
   * @description Deletes baseline from the chart after it is deleted.
   * @function
   * @param {Object} baseLine - baseLine object of the line deleted
   */
  function baseLineDeleteCallback(_, baseLine) {
    if (baseLine != null) {
      let baselineIndex = activeMtAnalysis.targetFunctions.findIndex(
        item => item._id == baseLine._id
      );
      if (baselineIndex >= 0) {
        activeMtAnalysis.targetFunctions.splice(baselineIndex, 1);
        initializeMtAnalysisConfiguration({
          ...activeMtAnalysis
        });
      }
    }
  }
  /**
   * @description function is passed to baseline list configuration and triggered after baseline's been updated.
   * @function
   * @param {Object} baseLine baseline that's been updated
   */
  function baseLineUpdateCallback(baseLine) {
    if (baseLine != null) {
      let baselineIndex = activeMtAnalysis.targetFunctions.findIndex(
        item => item._id == baseLine._id
      );
      if (baselineIndex >= 0) {
        let targetFunctions = activeMtAnalysis.targetFunctions.map(
          (item, index) => {
            if (index == baselineIndex) {
              return baseLine;
            }
            return item;
          }
        );
        initializeMtAnalysisConfiguration({
          ...activeMtAnalysis,
          targetFunctions
        });
      }
    }
  }
  /**
   * @description sets configuration got baseline table.
   * @function
   * @param {Object} mtAnalysisFlow
   */
  function initBaselineConfiguration(mtAnalysisFlow) {
    vm.baseLineConfiguration = BaseLineConfiguration.getConfiguration(
      mtAnalysisFlow,
      baseLineCreateCallback,
      baseLineDeleteCallback,
      baseLineUpdateCallback
    );
  }
  /**
   * @description returns function that opens chart filter dialog.
   * @function
   * @param {String} timeZoneCode
   * @return {function}
   */
  const filterDialog = timeZoneCode => {
    return () => {
      FilterDialog.open(vm.valuesApiDataX, timeZoneCode);
    };
  };

  /**
   * @description sets mt-analysis values and pattern configuration.
   * @function
   * @param {Object} mtAnalysisFlow
   */
  async function initializeMtAnalysisConfiguration(mtAnalysisFlow) {
    activeMtAnalysis = angular.copy(mtAnalysisFlow);
    let { timeSeriesObject: timeSeries } = mtAnalysisFlow;
    //PATTERN TITLE
    if (!vm.dashboardSettings) {
      let from = null;
      let to = null;
      if (mtAnalysisFlow.dateForCalcOfRegressionFuncTo == null) {
        AlertingService.Error(
          gettextCatalog.getString(
            'MT timeSeries is missing date for calculation to value'
          )
        );
      } else {
        to = new Date(mtAnalysisFlow.dateForCalcOfRegressionFuncTo);
      }
      if (mtAnalysisFlow.dateForCalcOfRegressionFuncFrom == null) {
        AlertingService.Error(
          gettextCatalog.getString(
            'MT timeSeries is missing date for calculation from value'
          )
        );
      } else {
        from = new Date(mtAnalysisFlow.dateForCalcOfRegressionFuncFrom);
      }
      vm.patternTitle = gettextCatalog.getString(
        'Pattern set ({{from}} - {{to}})',
        {
          from:
            from != 'Invalid Date'
              ? DateTime.fromJSDate(from).toFormat('dd. MM. yyyy')
              : '',
          to:
            to != 'Invalid Date'
              ? DateTime.fromJSDate(to).toFormat('dd. MM. yyyy')
              : ''
        }
      );
    }
    //MAIN VALUES
    let mtAnalysisConfiguration = await sfeMtAnalysisService.getValuesChartConfiguration(
      mtAnalysisFlow,
      timeSeries,
      false,
      vm.dashboardSettings
    );
    let callbacks = {};
    if (
      mtAnalysisConfiguration.callbacks != null &&
      typeof mtAnalysisConfiguration.callbacks == 'object'
    ) {
      callbacks = mtAnalysisConfiguration.callbacks;
    }
    callbacks = {
      ...callbacks,
      filter: filterDialog(mtAnalysisConfiguration.timeZone)
    };
    mtAnalysisConfiguration.callbacks = callbacks;

    if (!vm.valuesConfigurationDataX) {
      vm.valuesConfigurationDataX = mtAnalysisConfiguration;
    } else {
      vm.valuesApiDataX.rebuild(mtAnalysisConfiguration);
    }

    //PATTERN
    if (!vm.dashboardSettings) {
      let patternConfiguration = await sfeMtAnalysisService.getValuesChartConfiguration(
        mtAnalysisFlow,
        timeSeries,
        true
      );
      if (!vm.patternConfigurationDataX) {
        vm.patternConfigurationDataX = patternConfiguration;
      } else {
        vm.patternApiDataX.rebuild(patternConfiguration);
      }
    }
  }
}
