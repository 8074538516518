/**
 * @ngdoc directive
 * @name common.sfeLink
 * @description Function that wraps an anchor tag around the HTML element.
 * @param {boolean} isLink - Tells us, if the HTML element is a link
 * @param {string} link - URL string value
 * @example
 * <sfe-actions
 * sfe-link
 * isLink="true"
 * link="'www.google.com'"
 * ></sfe-actions>
 */
export default [
  function() {
    return {
      restrict: 'A',
      scope: {
        link: '<'
      },
      controller: sfeLinkController,
      controllerAs: 'vm',
      bindToController: true
    };
  }
];

sfeLinkController.$inject = ['$element', '$scope', '$compile'];

function sfeLinkController($element, $scope, $compile) {
  const vm = this;
  vm.$onChanges = () => {
    if (vm.link != null) {
      createLink();
    }
  };
  /**
   * @description Create link tag around element and check if element is wrapped in old link and remove it.
   * @function
   */
  const createLink = () => {
    //Check if element is wrapped in previous elements link tag and replace it with selected element.
    if ($element.parent().get(0).tagName === 'A') {
      $element.parent().replaceWith($element);
    }
    //Check if element has link property and wrap it in link tag.
    if (vm.link) {
      var compiledLink = $compile(
        `<a data-e2e='${vm.link}' ui-sref='${vm.link}'>`
      )($scope);
      $element.wrap(compiledLink);
    }
  };
}
