AssetTypeModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function AssetTypeModel(ModelUtilities, CrawlerMethods) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'asset-types'
  };

  const networkConfigurationV1 = {
    host: 'api',
    path: 'v1/asset-types'
  };

  const networkConfigurationAllLang = {
    host: 'api',
    path: 'asset-types',
    allLang: true
  };

  const crawlerConfiguration = {
    entity: 'asset-types',
    method: 'read',
    populate: 'assetGroup'
  };

  const formConfiguration = {
    entity: 'asset-types',
    method: 'custom.readAllLang',
    populate: 'assetGroup'
  };

  const crawlerConfigurationList = {
    entity: 'asset-types',
    method: 'read',
    populate: 'assetGroup'
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      read: CrawlerMethods.crawler(crawlerConfiguration),
      readForm: CrawlerMethods.crawler(formConfiguration),
      list: CrawlerMethods.crawler(crawlerConfigurationList),
      readAllLang: ModelUtilities.crud(
        'GET',
        networkConfigurationAllLang,
        modelStorage
      ),
      createV1: ModelUtilities.crud(
        'POST',
        networkConfigurationV1,
        modelStorage
      ),
      updateV1: ModelUtilities.crud('PUT', networkConfigurationV1, modelStorage)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default AssetTypeModel;
