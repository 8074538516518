import './navigation-dialog.scss';

NavigationDialogController.$inject = [
  'modules',
  'selectedModule',
  '$element',
  'gettextCatalog',
  'activeStateObj',
  'NavigationMenuService',
  '$timeout'
];

function NavigationDialogController(
  modules,
  selectedModule,
  $element,
  gettextCatalog,
  activeStateObj,
  NavigationMenuService,
  $timeout
) {
  const vm = this;

  vm.$onInit = async () => {
    vm.modules = modules;
    vm.selectedModuleIndex = modules.findIndex(
      item => item.rootName == selectedModule.rootName
    );
    if (vm.selectedModuleIndex > -1) {
      vm.selectedSubItemIndex = modules[
        vm.selectedModuleIndex
      ].subitems.findIndex(
        NavigationMenuService.getFindSubItemIndex(activeStateObj.name)
      );
    }
    vm.selectedModule = selectedModule;
    await $timeout();
    scrollToItemAtIndex(vm.selectedModuleIndex);
    vm.header = {
      title: gettextCatalog.getString('Navigation'),
      isDialog: true,
      actions: [
        {
          icon: {
            name: 'close',
            type: 2
          },
          cancel: true
        }
      ]
    };
  };

  vm.selectModule = index => {
    if (vm.selectedModuleIndex == index) {
      vm.selectedModuleIndex = null;
    } else {
      vm.selectedModuleIndex = index;
    }
    vm.selectedSubItemIndex = null;
  };

  /**
   * @description Scrolls timeline to item at index n.
   * @function
   * @param {number} index
   */
  const scrollToItemAtIndex = index => {
    const scrollContainer = $element.find('.navigation-dialog__modules');
    if (scrollContainer) {
      const items = scrollContainer
        .find('.navigation-dialog__modules__item')
        .toArray();
      const scrollPosition = items.reduce(
        (scrollPositionSum, item, itemIndex) => {
          if (itemIndex < index) {
            scrollPositionSum += angular.element(item)[0].clientHeight;
          }
          return scrollPositionSum;
        },
        0
      );
      scrollContainer.scrollTop(scrollPosition);
    }
  };
}

export default NavigationDialogController;
