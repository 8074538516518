const MT_ANALYSIS_FLOW_READ = 'MT_ANALYSIS_FLOW_READ';
const MT_ANALYSIS_FLOW_UPDATE = 'MT_ANALYSIS_FLOW_UPDATE';
const MT_ANALYSIS_FLOW_CREATE = 'MT_ANALYSIS_FLOW_CREATE';
const MT_ANALYSIS_FLOW_ADD = 'MT_ANALYSIS_FLOW_ADD';

export default {
  MT_ANALYSIS_FLOW_READ,
  MT_ANALYSIS_FLOW_UPDATE,
  MT_ANALYSIS_FLOW_CREATE,
  MT_ANALYSIS_FLOW_ADD
};
