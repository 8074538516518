module.exports = {
  entity: 'mappers',
  path: 'mappers',
  name: {
    en: 'Mappers',
    sl_SI: 'Zemljevidi'
  },
  networkModel: {
    entity: 'mappers',
    read: 'read'
  },
  scope: 'tis_be:mappers',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select mapper',
      sl_SI: 'Izberite zemljevid'
    },
    listInfo: {
      en: 'Select mapper from the list below',
      sl_SI: 'Izberite zemljevid s spodnjega seznama'
    }
  }
}
