import './tango-gis-map.scss';
import template from './tango-gis-map.component.html';
import timeSeriesActions from '../../../data/redux/time-series/action/times-series.action';
/**
 * @ngdoc component
 * @name common.gisMap
 * @description Displays gis map.
 * @param {Object} entityId - Data for the map
 * @example
 */

export default {
  restrict: 'E',
  template,
  bindings: {
    entityId: '<'
  },
  controller: tangoGisMapController,
  controllerAs: 'vm',
  bindToController: true
};

tangoGisMapController.$inject = [
  '$scope',
  '$ngRedux',
  'gettext',
  'MapModel',
  'AlertingService',
  'GisTimeSeriesPair',
  'ItemSelector',
  '$mdDialog',
  'InfoDialog',
  'TimeSeriesModel'
];

function tangoGisMapController(
  $scope,
  $ngRedux,
  gettext,
  MapModel,
  AlertingService,
  GisTimeSeriesPair,
  ItemSelector,
  $mdDialog,
  InfoDialog,
  TimeSeriesModel
) {
  const vm = this;
  let unsubscribe;
  let mainItem;

  vm.$onChanges = changes => {
    if (changes.entityId && vm.entityId != null && unsubscribe == null) {
      unsubscribe = $ngRedux.connect(mapStateToProps, timeSeriesActions)(vm);
      $scope.$on('$destroy', unsubscribe);
    }
  };

  /**
   * @description depending on ether gis configuration exists
   * returns action configuration.
   * @function
   * @param {Object} gisData gis data object
   * @return {Array}
   */
  function constructActions(gisData) {
    if (gisData == null) {
      return [
        {
          title: gettext('Pair'),
          color: 'primary',
          fn: pairTimeSeries
        }
      ];
    } else {
      return [
        {
          title: gettext('Update'),
          color: 'primary',
          fn: () => {
            openTimeSeriesPairDialog(gisData);
          }
        },
        {
          title: gettext('Unpair'),
          color: 'warn',
          fn: unpairTimeSeries
        }
      ];
    }
  }
  /**
   * @description opens unpair confirmation dialog.
   * @function
   */
  function unpairTimeSeries() {
    const textItem = {
      text: gettext(
        'Are you sure you want to unpair measurement and GIS object?'
      ),
      type: 'text'
    };
    const actions = [
      {
        title: gettext('No'),
        /**
         * @description closes dialog.
         * @function
         */
        fn: function() {
          $mdDialog.cancel();
        },
        color: 'primary'
      },
      {
        title: gettext('Yes'),
        /**
         * @description calls method that unpairs gis item from time series item and closes dialog.
         * @function
         */
        fn: async () => {
          updateGisData();
          $mdDialog.cancel();
        },
        color: 'warn'
      }
    ];

    InfoDialog.open(gettext('Warning'), null, [textItem], actions);
  }
  /**
* @description fetches gis maps when user has only one map
opens pair dialog with that map otherwise opens map selector dialog.
* @function
*/
  async function pairTimeSeries() {
    try {
      const { data: maps } = await MapModel.read();
      switch (maps.length) {
      case 0:
        AlertingService.Error('You have no maps');
        break;
      case 1:
        openTimeSeriesPairDialog({
          map: maps[0].id,
          markCenter: false
        });
        break;
      default:
        // eslint-disable-next-line no-case-declarations
        const items = await ItemSelector.open(['gis-map']);

        if (items && items.length) {
          const mapId = items[0].id;
          openTimeSeriesPairDialog({
            map: mapId,
            markCenter: false
          });
        }
      }
    } catch (err) {
      AlertingService.Error(err);
    }
  }
  /**
   * @description opens gis pair item dialog.
   * @function
   * @param {Object} gisData {map, markCenter ....}
   */
  async function openTimeSeriesPairDialog(gisData) {
    try {
      const data = await GisTimeSeriesPair.open(gisData);
      updateGisData({
        ...data,
        mapId: gisData.map
      });
    } catch (err) {
      AlertingService.Error(err);
    }
  }
  /**
   * @description constructs payload and calls updateTimeSeries method.
   * @function
   * @param {Object} gisData
   * @return {dataType}
   */
  async function updateGisData(gisData) {
    let gisPayload = null;
    if (gisData != null) {
      gisPayload = {
        featureId: gisData.feature_id,
        layerId: gisData.layer_id,
        center: gisData.center,
        map: gisData.mapId
      };
    }
    let body = {
      gisData: gisPayload
    };

    try {
      let { data } = await TimeSeriesModel.update({ id: vm.entityId }, body);
      const payload = {
        query: {
          id: vm.entityId
        },
        result: {
          data: {
            gisData: data.gisData
          }
        }
      };
      vm.updateTimeSeriesProps(payload);
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  function mapStateToProps(state) {
    let gisData;
    if (
      state != null &&
      state.timeSeries != null &&
      state.timeSeries[vm.entityId] != null &&
      state.timeSeries[vm.entityId].data != null
    ) {
      mainItem = state.timeSeries[vm.entityId].data;
      gisData = mainItem.gisData;
    } else {
      mainItem = null;
      gisData = null;
    }
    const actions = constructActions(gisData);
    return {
      actions,
      gisData
    };
  }
}
