FileController.$inject = [
  '$stateParams',
  'gettext',
  'TranslationService',
  'MetadataService',
  'TangoAgentConnectionConfigConfiguration',
  'gettextCatalog',
  'file',
  '$timeout'
];

function FileController(
  $stateParams,
  gettext,
  TranslationService,
  MetadataService,
  TangoAgentConnectionConfigConfiguration,
  gettextCatalog,
  file,
  $timeout
) {
  const vm = this;
  const fileId = $stateParams.id;
  vm.configuration = TangoAgentConnectionConfigConfiguration.getConfigurationWithUpdatedStates();
  vm.entity = 'datasources';

  vm.$onInit = function init() {
    MetadataService.Loading(true);
    vm.loading = true;
    file.type = TranslationService.GetCollectionById(
      'codelists.fileTypes',
      file.type
    );
    vm.dataPointsFilter = {
      connector: fileId
    };
    vm.headerData = constructHeader(file);
    vm.loading = false;
    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(file.name, file.description);
    });
  };

  function constructHeader(file) {
    var actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: fileId,
        endpoint: {
          entity: 'files',
          method: 'delete'
        },
        redirectState: 'external-datasources-connectors-list'
      },
      {
        title: 'edit',
        state: 'external-datasources-connectors-file-edit',
        param: 'id',
        paramValue: file._id
      }
    ];

    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];
    var properties = [
      {
        title: gettext('File type'),
        value: `${file.type.name} (${gettextCatalog.getString(
          file.type.description
        )})`,
        type: 'simple'
      },
      {
        title: gettext('Path'),
        value: file.path,
        type: 'simple'
      }
    ];
    var fileProperties = [
      {
        title: gettext('Tag column'),
        value: file.tagColumnName,
        type: 'code'
      },
      {
        title: gettext('Date column'),
        value: file.dateTimeColumnName,
        type: 'code'
      },
      {
        title: gettext('Value column'),
        value: file.valueColumnName,
        type: 'code'
      },
      {
        title: gettext('Delimiter'),
        value: file.delimiter,
        type: 'code'
      }
    ];
    var propertySections = [
      {
        title: gettext('connection'),
        properties: properties
      },
      {
        title: gettext('file settings'),
        properties: fileProperties
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'files',
        target: fileId,
        type: 'userAccess'
      }
    ];

    return {
      metadata: {
        definition: gettext('File connection configuration'),
        title: file.name,
        description: file.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}

export default FileController;
