/**
 * @ngdoc service
 * @name common.DashboardCardService
 * @description Service for creating/editing dashboard cards.
 * @property {function} openDialog - See openDialogNew method.
 */

import themes from '../../../config/tis/themes.json';

DashboardCardService.$inject = [
  'SfeFormDialogService',
  'gettext',
  'AlertingService',
  '$state',
  'TranslationService',
  '$timeout',
  '$q',
  '$smartAuth',
  'utilService',
  'LocationConfiguration',
  'InfoDialog',
  'gettextCatalog',
  'ToastService',
  'DashboardCardModel',
  '$mdDialog',
  '$rootScope',
  'GisMapModel'
];

export default function DashboardCardService(
  SfeFormDialogService,
  gettext,
  AlertingService,
  $state,
  TranslationService,
  $timeout,
  $q,
  $smartAuth,
  utilService,
  LocationConfiguration,
  InfoDialog,
  gettextCatalog,
  ToastService,
  DashboardCardModel,
  $mdDialog,
  $rootScope,
  GisMapModel
) {
  var vm = this;
  var previousSize;
  var FormConfiguration;

  init();
  /**
   * @description gets current users id,
   * @function
   */
  function init() {
    $smartAuth.profile().then(function(res) {
      vm.userId = res._id;
    });
    vm.allowedDataVisualizationDisplayTypes = [];
  }
  /**
   * @description Gets configuration for specified entity type.
   * @function
   * @param {string} api - Entity name.
   * @param {boolean} edit - False if creating new card, true if editing.
   * @return {Object} Configuration object for an entity type.
   */
  function getEntityConfig(api, edit, filterObj, method, onChange) {
    if (filterObj == null) {
      filterObj = {};
    }
    return {
      componentType: 'autocompleteDialog',
      edit: edit,
      name: 'displayElement',
      configuration: {
        entity: api,
        dialogConfiguration: {
          title: gettext('Select element'),
          filterObject: {
            order: 'name'
          }
        },
        searchParamName: 'filter',
        selectedParam: 'id',
        floatingLabel: gettext('Select element'),
        query: {
          entity: api,
          method: method || 'read'
        },
        filterObject: {
          order: 'name',
          ...filterObj
        },
        required: true,
        change: function() {
          if (
            vm.itemObject.displayElement &&
            !vm.itemObject.displayElement.__prefetched__value__
          ) {
            vm.oldDisplayElementId = vm.itemObject.displayElement._id;
            vm.itemObject.name = vm.itemObject.displayElement.name;
          }
          if (typeof onChange === 'function') {
            onChange();
          }
        }
      }
    };
  }
  /**
   * @description Creates a config for a name text field.
   * @function
   * @return {Object} Configuration for a text field.
   */
  function getNameConfig() {
    return {
      placeholder: 'Name',
      name: 'name',
      componentType: 'textField',
      type: 'text',
      required: true
    };
  }
  /**
   * @description Creates a config object for an autocomplete containing display types.
   * @function
   * @param {boolean} edit - False if creating new card, true if editing.
   * @return {Object} - Autocomplete configuration object for selecting default display type.
   */
  function getDisplayTypeConfig(edit) {
    return {
      name: 'displayType',
      componentType: 'multiSelect',
      options: TranslationService.GetCollection('codelists.displayTypes'),
      config: {
        single: true,
        required: true,
        edit: edit,
        label: gettext('Default display type'),
        placeholder: gettext('Select display type'),
        valueField: 'id',
        displayOptions: ['name'],
        onClose: function(selectedDisplayType) {
          // gselectedDisplayTypeuge and gaugeTable - don't show chart types
          // If display type is table
          if (selectedDisplayType._id !== 1 && selectedDisplayType._id !== 3) {
            vm.itemObject.showChartTypes = false;
            vm.itemObject.showColorPicker = false;
          } else {
            vm.itemObject.showChartTypes = true;
            vm.itemObject.showColorPicker = true;
          }
          vm.itemObject.showRadioSelector =
            selectedDisplayType._id === 3 ||
            vm.itemObject.displayType._id === 5;
        },
        ctrlFn: function() {
          return $timeout(function() {
            // filter options according to allowed visualization types
            var allowedDataVisualizationDisplayTypes;
            if (vm.itemObject) {
              if (vm.itemObject.cardSize) {
                allowedDataVisualizationDisplayTypes =
                  vm.itemObject.cardSize.allowedDataVisualizationDisplayTypes;
              }
              if (vm.itemObject.cardType && vm.itemObject.cardType === 3) {
                allowedDataVisualizationDisplayTypes = allowedDataVisualizationDisplayTypes.filter(
                  function(allowedType) {
                    return allowedType < 4;
                  }
                );
              }
            }
            if (!allowedDataVisualizationDisplayTypes) {
              allowedDataVisualizationDisplayTypes =
                vm.allowedDataVisualizationDisplayTypes;
            }
            return TranslationService.GetCollection(
              'codelists.displayTypes'
            ).filter(function(el) {
              return allowedDataVisualizationDisplayTypes.includes(el.id);
            });
          });
        }
      }
    };
  }

  function getRationConfiguration() {
    return {
      componentType: 'radio',
      name: 'chartAndTableDisplayRatio',
      label: gettext('Chart To Table Ratio'),
      row: true,
      hide: true,
      showParam: 'showRadioSelector',
      options: TranslationService.GetCollection(
        'codelists.chartAndTableDisplayRatios'
      )
    };
  }
  /**
   * @description Creates a config for a color picker.
   * @function
   * @return {Object} Configuration for a color picker.
   */
  function getColorConfig() {
    return {
      componentType: 'colorPicker',
      name: 'color',
      label: gettext('Select chart color'),
      hide: true,
      showParam: 'showColorPicker'
    };
  }

  /**
   * @description Creates a config object for an autocomplete containing chart types.
   * @function
   * @param {boolean} edit - False if creating new card, true if editing.
   * @return {Object} - Autocomplete configuration object for selecting default chart type.
   */
  function getChartTypeConfig(edit) {
    return {
      name: 'chartType',
      componentType: 'multiSelect',
      options: TranslationService.GetCollection('codelists.chartTypes'),
      hide: true,
      showParam: 'showChartTypes',
      config: {
        required: true,
        edit: edit,
        label: gettext('Select default chart type'),
        placeholder: gettext('Select chart type'),
        valueField: 'id',
        displayOptions: ['name'],
        ctrlFn: function() {
          return $timeout(function() {
            return TranslationService.GetCollection(
              'codelists.chartTypes'
            ).filter(function(item) {
              if (
                vm.itemObject != null &&
                vm.itemObject.displayElement != null &&
                vm.itemObject.displayElement.dataInterpretationType &&
                Array.isArray(item.supportedTimeSeriesDataInterpretationTypes)
              ) {
                return (
                  item.dataStreamVisualizationSupport &&
                  item.supportedTimeSeriesDataInterpretationTypes.indexOf(
                    vm.itemObject.displayElement.dataInterpretationType
                  ) > -1
                );
              }
            });
          });
        }
      }
    };
  }

  /**
   * @description Sets certain parameters when cost centre on the Add Locations, By Cost Centre Map or cost centre map is changed
   * @function
   */
  function mapCostCentreChanged() {
    var formLocationConfiguration = _.find(FormConfiguration, {
      name: 'linkedEntities'
    });
    if (!vm.itemObject.initEditing) {
      vm.itemObject.linkedEntities = [];
      if (vm.itemObject.costCentre && vm.itemObject.costCentre._id) {
        vm.itemObject.disableAddLocation = false;
        if (formLocationConfiguration) {
          formLocationConfiguration.selectConfigs[0].filterObject[
            'costCentreLocations.costCentre'
          ] = vm.itemObject.costCentre._id;
          formLocationConfiguration.selectConfigs[0].filterObject.populate =
            'costCentreLocations';
          formLocationConfiguration.selectConfigs[0].dialogConfiguration.filterObject[
            'costCentreLocations.costCentre'
          ] = vm.itemObject.costCentre._id;
          formLocationConfiguration.selectConfigs[0].dialogConfiguration.filterObject.populate =
            'costCentreLocations';
        }
      } else {
        if (formLocationConfiguration) {
          formLocationConfiguration.selectConfigs[0].filterObject.costCentre = undefined;
          formLocationConfiguration.selectConfigs[0].dialogConfiguration.filterObject.costCentre = undefined;
        }
        vm.itemObject.disableAddLocation = false;
      }
    } else {
      vm.itemObject.disableAddLocation = false;
      vm.itemObject.initEditing = false;
      formLocationConfiguration.selectConfigs[0].filterObject.populate =
        'costCentreLocations';
      formLocationConfiguration.selectConfigs[0].filterObject[
        'costCentreLocations.costCentre'
      ] = vm.itemObject.costCentre._id;
      formLocationConfiguration.selectConfigs[0].dialogConfiguration.filterObject.populate =
        'costCentreLocations';
      formLocationConfiguration.selectConfigs[0].dialogConfiguration.filterObject[
        'costCentreLocations.costCentre'
      ] = vm.itemObject.costCentre._id;
    }
  }
  /**
   * @description Creates part of the configuration object for all cards containing maps
   * (Locations By Cost Centre Map, Locations Map, Cost Centre Map)
   * @function
   * @param {integer} cardType - id of card type.
   * @param {boolean} edit - False if creating new card, true if editing.
   * @return {Object} PArt of the configuration object for Add Locations By Cost Centre Map card and Add Locations Map or Add Cost Centre Map
   */
  function getMapsConfigurations(cardType, edit) {
    var config = [];
    if (cardType === 19) {
      // costCentreMap
      config.push({
        componentType: 'autocompleteDialog',
        edit: edit,
        name: 'costCentre',
        configuration: {
          entity: 'cost-centres',
          dialogConfiguration: {
            title: gettext('Select cost centre'),
            filterObject: {
              order: 'name'
            }
          },
          searchParamName: 'filter',
          selectedParam: 'id',
          floatingLabel: gettext('Select Cost Centre'),
          query: {
            entity: 'cost-centres',
            method: 'read'
          },
          filterObject: {
            order: 'name'
          },
          required: true,
          change: mapCostCentreChanged
        }
      });
    }

    if (cardType === 18) {
      config.push({
        title: gettext('Location links'),
        componentType: 'title'
      });
    }

    if (cardType === 18 || cardType === 19) {
      var locationDialogConfiguration = LocationConfiguration;
      var title = gettextCatalog.getString('Select Location');
      var dialogConfiguration = {
        title: title,
        filterObject: {
          order: 'name'
        }
      };

      if (cardType === 19) {
        var filter = angular.copy(locationDialogConfiguration.filter);
        _.remove(filter, {
          param: 'costCentreLocations.costCentre'
        });
        dialogConfiguration.filter = filter;
      }
      var removeTitle = gettextCatalog.getString('Remove Location');
      var addTitle = gettextCatalog.getString('Add Location');
      var linkTitle = gettextCatalog.getString('Location link');
      var noDataMessage = gettextCatalog.getString(
        'No location is currently selected'
      );
      var linkEntity = 'locations';
      var queryEndpoint = 'locations';

      config.push({
        componentType: 'multiSelectList',
        name: 'linkedEntities',
        disabledAddParam: 'disableAddLocation',
        onAdd: function() {},
        onRemove: function() {},
        uniqueId: 'seriesSelect',
        removeTitle: removeTitle,
        addLabel: addTitle,
        groupLabel: linkTitle,
        isRequiredToCreate: false,
        noDataMessage: noDataMessage,
        selectConfigs: [
          {
            componentType: 'autocomplete',
            entity: linkEntity,
            dialogConfiguration,
            searchParamName: 'filter',
            selectedParam: 'id',
            floatingLabel: title,
            query: {
              entity: queryEndpoint,
              method: 'read'
            },
            filterObject: {
              order: 'name'
            },
            filterFn: items => {
              return items.filter(item => {
                return !vm.itemObject.linkedEntities.find(selectedItem => {
                  return (
                    Array.isArray(selectedItem) &&
                    selectedItem[0] &&
                    selectedItem[0]._id == item._id
                  );
                });
              });
            },
            required: true,
            change: function() {},
            edit: edit,
            reset: true,
            onClose: function() {}
          },
          {
            componentType: 'autocomplete',
            entity: 'dashboards',
            noDialog: true,
            searchParamName: 'filter',
            selectedParam: 'id',
            floatingLabel: gettext('Links to dashboard'),
            query: {
              entity: 'dashboards',
              method: 'read'
            },
            filterObject: {
              order: 'name'
            },
            required: false,
            filterFn: function(items) {
              return items.filter(function(item) {
                return item._id != $state.params.id;
              });
            },
            edit: edit,
            onClose: function() {}
          }
        ]
      });
    }
    return config;
  }

  async function getGisMapConfiguration(edit) {
    const mapsConfiguration = {
      componentType: 'autocompleteDialog',
      edit: edit,
      name: 'gisMap',
      configuration: {
        entity: 'gis-map',
        noDialog: true,
        searchParamName: 'name',
        selectedParam: 'id',
        floatingLabel: gettext('Gis map'),
        filterObject: {
          order: 'name'
        },
        required: true,
        change: item => {
          if (item[0] && !item[0].__prefetched__value__) {
            vm.itemObject.name = item[0].title;
          }
        }
      }
    };
    try {
      const { data: maps } = await GisMapModel.read();
      mapsConfiguration.configuration.domainValues = maps;
    } catch (err) {
      AlertingService.Error(err);
      mapsConfiguration.configuration.query = {
        entity: 'gis-maps',
        method: 'read'
      };
    }
    return [
      mapsConfiguration,
      getNameConfig(),
      {
        componentType: 'title',
        title: gettext('Center')
      },
      {
        componentType: 'twoInRow',
        subData: [
          {
            placeholder: gettext('X Coordinate'),
            name: 'xCoordinate',
            type: 'numerical',
            required: false
          },
          {
            placeholder: gettext('Y Coordinate'),
            name: 'yCoordinate',
            type: 'numerical',
            required: false
          }
        ]
      },
      {
        componentType: 'textField',
        name: 'scale',
        required: false,
        type: 'numerical',
        placeholder: gettextCatalog.getString('Scale'),
        onlyInteger: true
      }
    ];
  }
  /**
   * @description Creates a configuration file for Add Image card.
   * @function
   * @param {dataType} binding/paramName
   * @return {Object} configuration file for Add Image card.
   */
  function getFileUploaderConfig() {
    return {
      componentType: 'fileUpload',
      name: 'picture',
      fileUploader: 'fileUploader',
      endpoint: utilService.getApiHost + '/pictures',
      required: true,
      filters: [
        {
          name: 'imageFilter',
          fn: function(item) {
            var type =
              '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
            return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
          }
        }
      ]
    };
  }
  /**
   * @description Creates configuration for the Add Values card.
   * @function
   * @param {boolean} edit - False if creating new card, true if editing.
   * @return {Object} Configuration object for add Values card.
   */
  function getValuesCardConfiguration(edit) {
    return [
      getNameConfig(),
      {
        componentType: 'measurementSelector',
        name: 'measurementCard',
        edit: edit,
        configuration: {
          addLabel: gettext('+ Add layout'),
          addItemLabel: gettext('+ Add time series'),
          maxLayoutNumber: 1,
          maxItemsPerLayout: 3,
          entity: 'time-series',
          entityAutocomplete: {
            floatingLabel: gettext('Select time series'),
            searchParamName: 'filter',
            createRedirect: {
              state: 'data-time-series-new'
            },
            required: false
          }
        }
      },
      {
        componentType: 'colorPicker',
        name: 'color',
        label: gettextCatalog.getString('Select card background color')
      }
    ];
  }
  /**
   * @description Creates config for the Links to dashboard input field.
   * @function
   * @return {Object} Configuration object for the linkedDashboard autocompleteDialog
   */
  function getLinkedDashboardConfig(edit) {
    return {
      componentType: 'autocompleteDialog',
      edit: edit,
      name: 'linkedDashboard',
      configuration: {
        entity: 'dashboards',
        noDialog: true,
        searchParamName: 'filter',
        selectedParam: 'id',
        floatingLabel: gettext('Links to dashboard'),
        query: {
          entity: 'dashboards',
          method: 'read'
        },
        filterObject: {
          order: 'name'
        },
        required: false,
        filterFn: function(items) {
          return items.filter(function(item) {
            return item._id != $state.params.id;
          });
        }
      }
    };
  }
  /**
   * @description Creates config for the card size input
   * @function
   * @return {Object} COnfiguration object for the cardSize input
   */
  function getCardSizeConfig() {
    return {
      componentType: 'radio',
      name: 'cardSize',
      label: gettext('Size'),
      row: true,
      changeFn: function(selectedEl) {
        // when user changes card size we need to update options for displayType
        vm.allowedDataVisualizationDisplayTypes =
          selectedEl.allowedDataVisualizationDisplayTypes;
        // check if current displayValue is not still available and reset it
        if (
          vm.itemObject.displayType &&
          !vm.allowedDataVisualizationDisplayTypes.includes(
            vm.itemObject.displayType._id
          )
        ) {
          vm.itemObject.displayType = undefined;
        }

        vm.itemObject.showRadioSelector =
          vm.itemObject.displayType &&
          (vm.itemObject.displayType._id === 3 ||
            vm.itemObject.displayType._id === 5);
      }
    };
  }
  /**
   * @description gets triggered when time-series get changed and sets chart type according to interpretation type.
   * @function
   */
  function timeSeriesChanged() {
    if (
      vm.itemObject != null &&
      vm.itemObject.displayElement != null &&
      vm.itemObject.displayElement.dataInterpretationType != null &&
      !vm.itemObject.displayElement.__prefetched__value__ &&
      vm.itemObject.chartType != null &&
      vm.itemObject.chartType._id != null
    ) {
      const chartType = TranslationService.GetCollectionById(
        'codelists.chartTypes',
        vm.itemObject.chartType._id
      );
      const interpretationType = TranslationService.GetCollectionById(
        'codelists.dataInterpretationTypes',
        vm.itemObject.displayElement.dataInterpretationType
      );
      if (
        interpretationType != null &&
        chartType != null &&
        chartType.supportedTimeSeriesDataInterpretationTypes.indexOf(
          interpretationType
        ) == -1
      ) {
        vm.itemObject.chartType = {
          _id: interpretationType.defaultChartType || 7
        };
        const chartType = FormConfiguration.find(
          item => item.name === 'chartType'
        );
        if (chartType != null) {
          chartType.options = TranslationService.GetCollection(
            'codelists.chartTypes'
          );
        }
      }
    }
  }

  /**
   * @description gets called when element field is changed in Add chart card. Transforms selected data.
   * @function
   */
  function timeSeriesGroupChanged() {
    const obj = this.obj;
    if (obj && obj.displayElement) {
      if (obj.initEditing) {
        obj.initEditing = false;
      } else {
        obj.name = obj.displayElement.name;
        if (
          obj.cardSize != null &&
          Array.isArray(obj.cardSize.allowedDataVisualizationDisplayTypes) &&
          obj.cardSize.allowedDataVisualizationDisplayTypes.indexOf(
            obj.displayElement.displayType
          ) > -1
        ) {
          obj.displayType = {
            _id: obj.displayElement.displayType
          };
          //SET CHART TABLE RATIO VISIBILITY
          vm.itemObject.showRadioSelector =
            vm.itemObject.displayType && vm.itemObject.displayType._id == 3;
        }
      }
    }
  }
  /**
   * @memberof common.DashboardCardService
   * @description Opens a dialog windows for existing cards / creating new cards
   * @param {boolean} edit - False if creating new card, true if editing.
   * @param {Object} entity - Object containing data about selected card
   * @param {integer} index - Index of selected card / future index of card you are creating
   * @return {promise} returns a promise for the card being created/edited
   */
  async function openDialogNew(edit, entity, index) {
    var defaultColorTheme = themes.find(function(theme) {
      return $rootScope.activeTheme === theme.name;
    });
    var dashboardId = this.dashboardId;
    var deferred = $q.defer();
    FormConfiguration = [];
    var cardSizes = TranslationService.GetCollection(
      'codelists.dashboardCardSizes'
    );
    var cardType =
      entity && entity.dashboardCardType ? entity.dashboardCardType : entity.id;
    var selectedCardSize = cardSizes.find(function(size) {
      return size.id === entity.dashboardCardSize;
    });

    vm.itemObject = {
      _preserve_: true,
      name: edit ? entity.name : '',
      cardSize: edit ? selectedCardSize : cardSizes[0],
      disableAddLocation: cardType === 19,
      initEditing: edit,
      order: index,
      dashboardId: dashboardId,
      _id: entity._id,
      showChartTypes: true,
      showColorPicker: true,
      cardType: cardType
    };

    if (edit) {
      vm.itemObject.linkedDashboard = entity.linkedDashboard;
      if (entity.entities && entity.entities.length > 0) {
        vm.itemObject.displayElement = entity.entities[0].entityId;
        // check if color exists in chart entities and save it
        if (entity.entities[0].details && entity.entities[0].details.color) {
          vm.itemObject.color = entity.entities[0].details.color;
        }
      }
    }
    //HACK keeping current card size for values card in case when user changes card size and closes confirmation dialog
    previousSize = selectedCardSize;

    var cardTypeObject = TranslationService.GetCollectionById(
      'codelists.dashboardCardTypes',
      cardType
    );
    var allowedSizes = cardSizes.filter(function(item) {
      if (
        cardTypeObject &&
        cardTypeObject.allowedSizes &&
        cardTypeObject.allowedSizes.length > 0
      ) {
        return cardTypeObject.allowedSizes.find(function(size) {
          return size === item.id;
        });
      }
    });
    var title = edit
      ? gettextCatalog.getString('Edit card')
      : gettextCatalog.getString('Add card');
    var cardSizeConfiguration = getCardSizeConfig();
    cardSizeConfiguration.options = allowedSizes;

    // set allowed  visualization display types for current size selection
    vm.allowedDataVisualizationDisplayTypes =
      allowedSizes[0].allowedDataVisualizationDisplayTypes;

    var tempConfigs;
    var chartConfig;
    var dialogButtonsConfiguration;
    switch (cardType) {
    case 3: //TIME SERIES GROUP
      title = edit
        ? gettextCatalog.getString('Edit time series group')
        : gettextCatalog.getString('Add time series group');
      tempConfigs = getEntityConfig('time-series-groups', edit, {
        order: '-_id'
      });
      tempConfigs.configuration.change = timeSeriesGroupChanged.bind({
        obj: vm.itemObject
      });
      FormConfiguration.push(tempConfigs);
      FormConfiguration.push(getNameConfig());
      FormConfiguration.push(getDisplayTypeConfig());
      FormConfiguration.push(getRationConfiguration());
      if (edit) {
        if (
          entity &&
            entity.entities &&
            entity.entities[0] &&
            entity.entities[0].details
        ) {
          vm.itemObject.initEditing = true;
          if (entity.entities[0].details.displayType) {
            vm.itemObject.displayType = {
              _id: entity.entities[0].details.displayType
            };
          }

          vm.itemObject.chartAndTableDisplayRatio = TranslationService.GetCollectionById(
            'codelists.chartAndTableDisplayRatios',
            entity.entities[0].details.chartAndTableDisplayRatio
          );
        }
      } else {
        // set default display type
        vm.itemObject.displayType = {
          _id: 1
        };
        vm.itemObject.chartAndTableDisplayRatio = TranslationService.GetCollection(
          'codelists.chartAndTableDisplayRatios'
        )[0];
      }
      vm.itemObject.showRadioSelector = vm.itemObject.displayType._id == 3;
      break;
    case 4: //ENERGY MANAGEMENT ANALYSIS
      title = edit
        ? gettextCatalog.getString('Edit analysis')
        : gettextCatalog.getString('Add analysis');
      FormConfiguration.push(getEntityConfig('analyses', edit));
      FormConfiguration.push(getNameConfig());
      break;
    case 5: //WEATHER
      // eslint-disable-next-line no-case-declarations
      const filterObject = {
        order: 'name',
        populate: 'type',
        'type.code': ['VRWT', 'VRWT2']
      };
      title = edit
        ? gettextCatalog.getString('Edit weather')
        : gettextCatalog.getString('Add weather');
      tempConfigs = getEntityConfig('locations', edit);
      //ADD WEATHER LOCATION FILTER
      tempConfigs.configuration.filterObject = filterObject;
      tempConfigs.configuration.dialogConfiguration.filterObject = filterObject;

      FormConfiguration.push(tempConfigs);
      FormConfiguration.push(getNameConfig());
      break;
    case 6: //TIME SERIES
      title = edit
        ? gettextCatalog.getString('Edit Time Series')
        : gettextCatalog.getString('Add Times Series');
      tempConfigs = getEntityConfig(
        'time-series',
        edit,
        {},
        'custom.populateDataScheduler',
        timeSeriesChanged
      );

      FormConfiguration.push(tempConfigs);
      FormConfiguration.push(getNameConfig());

      chartConfig = getChartTypeConfig();
      FormConfiguration.push(getDisplayTypeConfig());
      FormConfiguration.push(getRationConfiguration());
      FormConfiguration.push(chartConfig);
      FormConfiguration.push(getColorConfig());
      if (edit) {
        if (
          (entity.entities && entity.entities.length > 0,
          entity.entities[0] && entity.entities[0].details)
        ) {
          vm.itemObject.chartType = {
            _id: entity.entities[0].details.chartType
          };
          if (entity.entities[0].details.displayType) {
            vm.itemObject.displayType = {
              _id: entity.entities[0].details.displayType
            };
          }

          vm.itemObject.chartAndTableDisplayRatio = TranslationService.GetCollectionById(
            'codelists.chartAndTableDisplayRatios',
            entity.entities[0].details.chartAndTableDisplayRatio
          );
        }
      } else {
        vm.itemObject.chartAndTableDisplayRatio = TranslationService.GetCollection(
          'codelists.chartAndTableDisplayRatios'
        )[0];
        vm.itemObject.displayType = {
          _id: 1
        };
        vm.itemObject.chartType = {
          _id: 5
        };
        vm.itemObject.color = defaultColorTheme
          ? defaultColorTheme.accent.palette['500'].value.hex
          : '#4572a7';
      }

      vm.itemObject.showRadioSelector =
          vm.itemObject.displayType._id == 3 ||
          vm.itemObject.displayType._id == 5;

      break;
    case 10: //LOCATION CARD
      title = edit
        ? gettextCatalog.getString('Edit location')
        : gettextCatalog.getString('Add location');
      FormConfiguration.push(getEntityConfig('locations', edit));
      FormConfiguration.push(getNameConfig());
      break;
    case 12: //VISUALIZATION
      title = edit
        ? gettextCatalog.getString('Edit visualization')
        : gettextCatalog.getString('Add visualization');
      FormConfiguration.push(getEntityConfig('visualizations', edit));
      FormConfiguration.push(getNameConfig());
      break;
    case 13: //ALARMS
      title = edit
        ? gettextCatalog.getString('Edit alarms')
        : gettextCatalog.getString('Add alarms');
      break;
    case 14: //INVOICE OVERVIEW
      title = edit
        ? gettextCatalog.getString('Edit invoice overview')
        : gettextCatalog.getString('Add invoice overview');
      break;
    case 15: //M&T ANALYSIS
      title = edit
        ? gettextCatalog.getString('Edit M & T Analysis')
        : gettextCatalog.getString('Add M & T Analysis');
      FormConfiguration.push(
        getEntityConfig('time-series', edit, {
          'activeTimeSeriesConfiguration.flowType_': 320 //MT Analyses flow type
        })
      );
      FormConfiguration.push(getNameConfig());
      break;
    case 16: //IMAGE
      title = edit
        ? gettextCatalog.getString('Edit image')
        : gettextCatalog.getString('Add image');
      FormConfiguration.push(getNameConfig());
      FormConfiguration.push(getFileUploaderConfig());
      break;
    case 17: //VALUES
      title = edit
        ? gettextCatalog.getString('Edit values')
        : gettextCatalog.getString('Add values');
      tempConfigs = getValuesCardConfiguration(edit);

      if (!edit || !vm.itemObject.name) {
        vm.itemObject.name = gettextCatalog.getString('Time series card');
      }
      tempConfigs.forEach(function(config) {
        if (config.name === 'measurementCard') {
          config.createDashboardCard = saveValuesDashboardCard.bind({
            item: vm.itemObject
          });
          config.getDashboardCard = () => vm.itemObject;
          config.changeOrderCallback = changeOrderCallback.bind({
            item: vm.itemObject
          });
          if (edit) {
            config.dashboardCardId = entity._id;
          }
        }
        FormConfiguration.push(config);
      });
      cardSizeConfiguration.disabledParam = 'editingOrder';
      cardSizeConfiguration.changeFn = function(value, dataObj) {
        var measurementsConfig = FormConfiguration.find(function(item) {
          return item.name === 'measurementCard';
        });

        function cancel() {
          dataObj.cardSize = previousSize;
          setMeasurementCardLayoutOnCardSizeChange(previousSize);
          $mdDialog.hide();
        }

        async function yes() {
          previousSize = value;
          const entities = dataObj[measurementsConfig.name][0].map(
            item => item.apiItem
          );
          try {
            await DashboardCardModel.update(
              {
                id: dataObj._id
              },
              { entities }
            );
            dataObj[measurementsConfig.name].splice(1);
            ToastService.showToast(
              gettext('Layout was successfully removed')
            );
          } catch (err) {
            AlertingService.Error(err);
          }
          setMeasurementCardLayoutOnCardSizeChange(value);
          $mdDialog.hide();
        }
        if (value.id === 1 && dataObj[measurementsConfig.name].length > 1) {
          const title = gettextCatalog.getString('Card size changed');
          const content = {
            text: gettext(
              'Are you sure that you want to change dashboard card size?'
            ),
            type: 'text'
          };

          const actions = [
            {
              title: gettext('Cancel'),
              fn: cancel,
              color: 'primary'
            },
            {
              title: gettext('Yes'),
              fn: yes,
              color: 'warn'
            }
          ];
          InfoDialog.open(title, null, [content], actions).then(
            () => {},
            () => {
              //handle confirmation dialog close button
              dataObj.cardSize = previousSize;
              setMeasurementCardLayoutOnCardSizeChange(previousSize);
            }
          );
        } else {
          previousSize = value;
          setMeasurementCardLayoutOnCardSizeChange(value);
        }
      };
      if (!edit) {
        vm.itemObject.color = '#fff';
        vm.itemObject.measurementCard = [[]];
      } else {
        vm.itemObject.measurementCard = entity.entities;
        vm.itemObject.color = entity.details.bgColor;
      }

      setMeasurementCardLayoutOnCardSizeChange(vm.itemObject.cardSize);

      dialogButtonsConfiguration = {
        displayDefault: false,
        buttons: [
          {
            title: edit ? gettext('Update') : gettext('Create'),
            disabledFn: function() {
              if (
                !vm.itemObject.measurementCard ||
                  !vm.itemObject.measurementCard.length ||
                  vm.itemObject.editingOrder
              ) {
                return true;
              } else if (vm.itemObject.measurementCard.length > 0) {
                var measurementAddedToForm = false;
                vm.itemObject.measurementCard.forEach(function(
                  measurementLayout
                ) {
                  if (
                    Array.isArray(measurementLayout) &&
                      measurementLayout.length > 0
                  ) {
                    measurementAddedToForm = true;
                  }
                });
                return !measurementAddedToForm;
              }
            },
            script: function(closeDialog, item) {
              saveValuesDashboardCard(item).then(
                function(dashboardCard) {
                  var cardConfig = FormConfiguration.find(function(config) {
                    return config.name === 'measurementCard';
                  });
                  if (cardConfig) {
                    cardConfig.dashboardCardId = dashboardCard._id;
                  }

                  closeDialog(dashboardCard);
                  ToastService.showToast(
                    gettextCatalog.getString(
                      'Dashboard card successfully saved.'
                    )
                  );
                },
                function(err) {
                  deferred.reject(err);
                }
              );
            }
          }
        ]
      };
      break;
    case 18: //LOCATION MAP
    case 19: //LOCATION BY COST CENTRE MAP
      if (cardType === 18) {
        title = edit
          ? gettextCatalog.getString('Edit locations map')
          : gettextCatalog.getString('Add locations map');
      }
      if (cardType === 19) {
        title = edit
          ? gettextCatalog.getString('Edit locations by cost centre map')
          : gettextCatalog.getString('Add locations by cost centre map');
      }
      tempConfigs = getMapsConfigurations(cardType, edit);

      (tempConfigs || []).forEach(function(config) {
        FormConfiguration.push(config);
      });
      vm.itemObject.linkedEntities = [];
      if (entity.details && entity.details.linkedEntities) {
        entity.details.linkedEntities.forEach(function(linkedItem) {
          vm.itemObject.linkedEntities.push([
            linkedItem.entityId,
            linkedItem.linkedDashboard
          ]);
        });
      }
      if (entity.entities && entity.entities[0]) {
        vm.itemObject.costCentre = entity.entities[0].entityId;
      }
      break;
    case 20:
      FormConfiguration = await getGisMapConfiguration(edit);
      if (edit && entity && entity.details) {
        vm.itemObject.xCoordinate = entity.details.xCoordinate;
        vm.itemObject.yCoordinate = entity.details.yCoordinate;
        vm.itemObject.scale = entity.details.scale;
        vm.itemObject.gisMap = entity.details.gisMapId;
      }
      break;
    case 21:
      title = edit
        ? gettextCatalog.getString('Edit iframe')
        : gettextCatalog.getString('Add iframe');
      FormConfiguration.push(
        {
          placeholder: 'Name',
          name: 'name',
          componentType: 'textField',
          type: 'text',
          required: true
        },
        {
          placeholder: 'Link',
          name: 'url',
          componentType: 'proxyIframe'
        }
      );
      if (edit) {
        vm.itemObject.url = entity.details.url;
      }
      break;
    }
    FormConfiguration.push(getLinkedDashboardConfig(edit));
    FormConfiguration.unshift(cardSizeConfiguration);
    if (!edit) {
      vm.itemObject.cardSize = cardSizes[0];
    }
    if (
      edit &&
      vm.itemObject.displayType &&
      vm.itemObject.displayType._id !== 3 &&
      vm.itemObject.displayType._id !== 1
    ) {
      vm.itemObject.showChartTypes = false;
      vm.itemObject.showColorPicker = false;
    } else {
      vm.itemObject.showChartTypes = true;
      vm.itemObject.showColorPicker = true;
    }
    SfeFormDialogService.openSfeFormDialog(
      edit,
      FormConfiguration,
      vm.itemObject,
      title,
      dialogButtonsConfiguration
    ).then(
      function(result) {
        if (result) {
          if (cardType === 17) {
            deferred.resolve(result);
          } else if (result && result.fileUploader) {
            uploadPictures(result.fileUploader).then(function(picture) {
              result.picture = picture;
              deferred.resolve(prepareData(cardType, result));
            });
          } else {
            deferred.resolve(prepareData(cardType, result));
          }
        } else if (cardType === 17 && edit) {
          // values card needs to be reloaded after dialog is closed
          // because of values card entities are updated when form is still opened
          DashboardCardModel.read({ id: vm.itemObject._id }).then(
            function(res) {
              deferred.resolve(res.data);
            },
            function(err) {
              AlertingService.Error(err);
              deferred.resolve(vm.itemObject);
            }
          );
        } else {
          //closed dialog
          deferred.reject({ status: 'closedDialog' });
        }
      },
      function(err) {
        deferred.reject(err);
      }
    );
    return deferred.promise;
  }

  function changeOrderCallback(status) {
    let item = this.item;
    if (item) {
      item.editingOrder = status;
    }
  }
  /**
   * @description Saves values dashboard data card.
   * @function
   * @param {Object} item - Contains Values card data.
   * @return {promise} Returns a promise for the add value dashboard card,
   */
  function saveValuesDashboardCard(item, entities) {
    const deferred = $q.defer();
    if (!item) {
      item = this.item;
    }
    const obj = {
      name: item.name,
      order: item.order,
      dashboard: $state.params.id,
      linkedDashboard: item.linkedDashboard ? item.linkedDashboard._id : null,
      dashboardCardType: 17, //hard coded measurement values card type
      dashboardCardSize: item.cardSize ? item.cardSize.id : 1,
      details: {
        bgColor: item.color
      },
      entities
    };
    let apiFunction;
    if (item._id) {
      apiFunction = DashboardCardModel.update(
        {
          id: item._id
        },
        obj
      );
    } else {
      apiFunction = DashboardCardModel.create(obj);
    }
    apiFunction.then(
      function(res) {
        item.displayItem = res.data;
        item._id = res.data._id;
        deferred.resolve(res.data);
      },
      function(err) {
        deferred.reject(err);
      }
    );

    return deferred.promise;
  }
  /**
   * @description Sets layout of the values dashboard card.
   * @function
   * @param {dataType} value - contains data about the value dashboard card.
   */
  function setMeasurementCardLayoutOnCardSizeChange(value) {
    var measurementsConfig = FormConfiguration.find(function(item) {
      return item.name === 'measurementCard';
    });
    switch (value.id) {
    case 1:
      measurementsConfig.configuration.maxLayoutNumber = 1;
      break;
    case 2:
      measurementsConfig.configuration.maxLayoutNumber = 2;
      break;
    }
  }
  /**
   * @description Uploads picture added to dashboard card.
   * @function
   * @param {Object}  fileUploader - Contains data about the uploaded file and functions associated with it
   * @return {promise} Returns promise for the uploaded pictures.
   */
  function uploadPictures(fileUploader) {
    var deferred = $q.defer();
    if (!fileUploader.queue.length) {
      deferred.resolve();
    }
    fileUploader.onCompleteItem = function(item, response) {
      deferred.resolve(response.data._id);
    };
    fileUploader.uploadAll();
    return deferred.promise;
  }
  /**
   * @description Prepares card for saving.
   * @function
   * @param {integer} instanceType - Dashboard card type.
   * @param {Object} result - Contains card data and information.
   * @return {Object} Prepared data.
   */
  function prepareData(instanceType, result) {
    var sendObj = {
      linkedDashboard: result.linkedDashboard
        ? result.linkedDashboard._id
        : null,
      dashboardCardSize: result.cardSize ? result.cardSize.id : 1, //default small,
      dashboardCardType: parseInt(instanceType),
      name: result.name || gettextCatalog.getString('Unknown')
    };
    switch (instanceType) {
    case 3: //CHART
      sendObj.entities = [
        {
          order: 0,
          entityId: result.displayElement._id,
          entityType: 237, //TIME SERIES GROUPS
          details: {
            displayType: result.displayType._id,
            chartAndTableDisplayRatio: result.chartAndTableDisplayRatio
              ? result.chartAndTableDisplayRatio.id
              : null
          }
        }
      ];
      break;
    case 4: //ENERGY MANAGEMENT ANALYSIS
      sendObj.entities = [
        {
          order: 0,
          entityId: result.displayElement._id,
          entityType: 8
        }
      ];
      break;
    case 5: //WEATHER
      sendObj.entities = [
        {
          order: 0,
          entityId: result.displayElement._id,
          entityType: 9
        }
      ];
      break;
    case 6: //TIME SERIES
      sendObj.entities = [
        {
          order: 0,
          entityId: result.displayElement._id,
          entityType: 234,
          details: {
            chartType: result.chartType._id,
            displayType: result.displayType._id,
            color: result.color,
            chartAndTableDisplayRatio: result.chartAndTableDisplayRatio
              ? result.chartAndTableDisplayRatio.id
              : null
          }
        }
      ];
      break;
    case 10: //LOCATION CARD
      sendObj.entities = [
        {
          order: 0,
          entityId: result.displayElement._id,
          entityType: 3
        }
      ];
      break;
    case 12: //VISUALIZATION
      sendObj.entities = [
        {
          order: 0,
          entityId: result.displayElement._id,
          entityType: 14
        }
      ];
      break;
    case 13: //ALARMS
      break;
    case 14: //INVOICE OVERVIEW
      break;
    case 15: //M&T ANALYSIS
      sendObj.entities = [
        {
          order: 0,
          entityId: result.displayElement._id,
          entityType: 234 //TIME SERIES
        }
      ];
      break;
    case 16: //IMAGE
      sendObj.entities = [
        {
          order: 0,
          entityId: result.picture,
          entityType: 16
        }
      ];
      break;
    case 17: //VALUES
      // Values dashboard card creating/updating is happening in saveValuesDashboardCard function in TimeSeriesValuesDashboardCard service
      break;
    case 18: //LOCATION MAP
      sendObj.details = {
        linkedEntities: []
      };
      result.linkedEntities.forEach(function(linkedItem) {
        sendObj.details.linkedEntities.push({
          entityId: linkedItem[0]._id,
          entityType: 3,
          linkedDashboard: linkedItem[1] ? linkedItem[1]._id : undefined
        });
      });
      break;
    case 19: //LOCATION BY COST CENTRE MAP
      sendObj.entities = [
        {
          order: 0,
          entityId: result.costCentre._id,
          entityType: 4
        }
      ];
      sendObj.details = {
        linkedEntities: []
      };
      result.linkedEntities.forEach(function(linkedItem) {
        sendObj.details.linkedEntities.push({
          entityId: linkedItem[0]._id,
          entityType: 3,
          linkedDashboard: linkedItem[1] ? linkedItem[1]._id : undefined
        });
      });
      break;
    case 20:
      sendObj.details = {
        gisMapId: result.gisMap.id,
        xCoordinate: result.xCoordinate,
        yCoordinate: result.yCoordinate,
        scale: result.scale
      };
      break;
    case 21:
      sendObj.details = {
        url: result.url
      };
    }
    return sendObj;
  }

  return {
    openDialog: openDialogNew
  };
}
