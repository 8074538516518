module.exports = {
  entity: 'data-sinks',
  path: 'data-sinks',
  name: {
    en: 'Data sinks',
    sl_SI: 'Podatkovni ponor'
  },
  scope: 'tis_be:data-sinks',

  dialog: {
    title: {
      en: 'Select data sink',
      sl_SI: 'Izberite podatkovni ponor'
    },
    listInfo: {
      en: 'Select data sink from the list below',
      sl_SI: 'Izberite podatkovni ponor s spodnjega seznama'
    }
  }
}
