/**
 * @ngdoc service
 * @name common.SfeDataXTranslations
 * @description Used for getting sfeDataX translations
 * @property {function} get - returns translations
 */
SfeDataXTranslations.$inject = ['gettextCatalog'];

export default function SfeDataXTranslations(gettextCatalog) {
  /**
   * @description get translations
   * @function
   * @return {Object} translations
   */
  const get = () => {
    const translations = {
      equals: gettextCatalog.getString('Equals'),
      notEqual: gettextCatalog.getString('Not equal'),
      lessThan: gettextCatalog.getString('Less than'),
      lessThanOrEqual: gettextCatalog.getString('Less than or equals'),
      greaterThan: gettextCatalog.getString('Greater than'),
      greaterThanOrEqual: gettextCatalog.getString('Greater than or equals'),
      inRange: gettextCatalog.getString('In range'),
      refreshingValues: gettextCatalog.getString('Refreshing values'),
      noValues: gettextCatalog.getString('No values'),
      loading: gettextCatalog.getString('Loading values'),
      noDataToDisplay: gettextCatalog.getString('No value to display'),
      zoomToLast: gettextCatalog.getString('Zoom to last:'),
      year: gettextCatalog.getString('Year'),
      month: gettextCatalog.getString('Month'),
      week: gettextCatalog.getString('Week'),
      day: gettextCatalog.getString('Day'),
      hour: gettextCatalog.getString('Hour'),
      resetZoom: gettextCatalog.getString('Reset zoom'),
      order: gettextCatalog.getString('Order')
    };
    return translations;
  };

  return {
    get
  };
}
