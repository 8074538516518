import './sfe-entity-selector-list.scss';
import template from './sfe-entity-selector-list.component.html';

export default {
  template,
  bindings: {
    items: '=?',
    entity: '<'
  },
  controllerAs: 'vm',
  controller: sfeEntitySelectorListController
};

sfeEntitySelectorListController.$inject = [
  '$scope',
  'ItemSelector',
  'EntitiesService',
  'AlertingService',
  'CrawlerMethods',
  'LongQueryHelper'
];

function sfeEntitySelectorListController(
  $scope,
  ItemSelector,
  EntitiesService,
  AlertingService,
  CrawlerMethods,
  LongQueryHelper
) {
  const vm = this;
  vm.openSearchDialog = openSearchDialog;
  vm.removeItem = removeItem;
  vm.removeAll = removeAll;
  let entity;

  vm.$onInit = function() {
    entity = EntitiesService.getEntityById(vm.entity);
    vm.displayFields = EntitiesService.getDisplayFieldsByPath(entity) || [
      'name'
    ];
  };

  vm.$onChanges = function() {
    if (vm.items && vm.items.length) {
      if (!entity) {
        entity = EntitiesService.getEntityById(vm.entity);
        vm.displayFields = EntitiesService.getDisplayFieldsByPath(entity) || [
          'name'
        ];
      }
      fetchItems();
    }
  };

  async function fetchItem(method, item) {
    try {
      const { data } = await method({
        id: item
      });
      return data;
    } catch (err) {
      AlertingService.Error(err);
      return null;
    }
  }

  async function fetchItems() {
    vm.loadingItems = true;
    let networkModel = EntitiesService.getNetworkModelByEntity(entity);

    if (networkModel.dialogMethod != null) {
      networkModel = {
        entity: networkModel.dialogMethod.entity,
        read: networkModel.dialogMethod.method
      };
    }

    const method = CrawlerMethods.getMethod({
      entity: networkModel.entity,
      method: networkModel.read
    });

    let selectedParamName = EntitiesService.getSelectedParamName(entity);
    if (entity == 'master-invoices') {
      //Master invoice custom view
      selectedParamName = 'masterInvoice';
    }

    if (
      Array.isArray(vm.items) &&
      vm.items.length > 0 &&
      (typeof vm.items[0] === 'string' || typeof vm.items[0] === 'number')
    ) {
      if (selectedParamName && selectedParamName !== 'id') {
        let apiObj = {};
        apiObj[selectedParamName] = vm.items;
        try {
          const items = await LongQueryHelper.execute(
            vm.items,
            method,
            selectedParamName
          );
          vm.items = items;
        } catch (err) {
          AlertingService.Error(err);
        }
      } else {
        const promises = [];
        vm.items.forEach(function(item) {
          promises.push(fetchItem(method, item));
        });
        try {
          const results = await Promise.all(promises);
          vm.items = vm.items.map(itemId => {
            let foundItem = results.find(result => result._id === itemId);
            if (foundItem != null) {
              return foundItem;
            }
            return itemId;
          });
        } catch (err) {
          AlertingService.Error(err);
        }
      }
    }
    vm.loadingItems = false;
    $scope.$applyAsync();
  }

  function openSearchDialog() {
    entity = EntitiesService.getEntityById(vm.entity);
    vm.displayFields = EntitiesService.getDisplayFieldsByPath(entity) || [
      'name'
    ];
    ItemSelector.open(
      [entity],
      [
        {
          multiple: true
        }
      ]
    ).then(
      function(selected) {
        if (selected && selected.length > 0) {
          if (!vm.items) {
            vm.items = [];
          }
          let uniqKey = '_id';
          if (entity === 'master-invoices') {
            uniqKey = 'masterInvoice';
          }
          vm.items = vm.items.concat(selected);
          vm.items = _.uniqBy(vm.items, uniqKey);
        }
      },
      function() {}
    );
  }

  function removeItem(index) {
    vm.items.splice(index, 1);
  }

  function removeAll() {
    if (vm.items) {
      vm.items.length = 0;
    }
  }
}
