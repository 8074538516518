schedulerConfiguration.$inject = ['gettext', '$q', 'TranslationService'];

function schedulerConfiguration(gettext, $q, TranslationService) {
  var configuration = {
    entity: {
      singular: gettext('Schedule'),
      plural: gettext('Schedules')
    },
    api: {
      query: {
        entity: 'schedulers',
        method: 'read'
      },
      enrich: Enrich
    },
    create: {
      link: {
        state: 'data-schedules-new'
      }
    },
    title: {
      param: 'name',
      link: {
        state: 'data-schedules-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'data-schedules-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'data-schedules-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'schedulers',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'created',
        title: gettext('Created'),
        type: 'date'
      },
      {
        title: gettext('Schedule classification'),
        param: 'scheduleClassificationName',
        type: 'text'
      }
    ],
    filter: [
      {
        param: 'filter',
        display: gettext('Name'),
        type: 'string'
      },
      {
        param: 'scheduleClassification',
        entity: 'scheduleClassifications',
        display: gettext('Schedule Classification'),
        valueField: 'id',
        collection: TranslationService.GetCollection(
          'codelists.scheduleClassifications'
        )
      }
    ],
    sort: [
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'name DESC',
        params: '-name'
      },
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  /*
   *
   *   ENRICH
   *
   */

  function Enrich(item) {
    var deferred = $q.defer();
    if (item.alwaysValid) {
      item.validNow = true;
    } else {
      if (isActive(item.validFromDate, item.validToDate)) {
        item.validNow = true;
      } else {
        item.validNow = false;
      }
    }

    let scheduleClassification = TranslationService.GetCollectionById(
      'codelists.scheduleClassifications',
      item.scheduleClassification
    );
    if (
      scheduleClassification != null &&
      typeof scheduleClassification == 'object'
    ) {
      item.scheduleClassificationName = scheduleClassification.name;
    } else {
      item.fetchingObject.scheduleClassificationName = {
        fetching: false,
        success: true,
        nodata: true
      };
    }
    deferred.resolve();
    return deferred.promise;

    function isActive(from, to) {
      from = new Date(from);
      to = new Date(to);
      var now = new Date();
      if (from <= now && to >= now) {
        return true;
      } else {
        return false;
      }
    }
  }

  return configuration;
}
export default schedulerConfiguration;
