AllowedOperatorsService.$inject = ['gettextCatalog'];

function AllowedOperatorsService(gettextCatalog) {
  const OperatorConstants = [
    {
      type: 'operation',
      title: gettextCatalog.getString('Parenthesis'),
      description: gettextCatalog.getString('Indicates order of execution.'),
      symbol: '[ ( ) ]'
    },
    {
      type: 'operation',
      title: gettextCatalog.getString('Addition'),
      description: gettextCatalog.getString('Adds neighbouring expressions'),
      value: '+'
    },
    {
      type: 'operation',
      title: gettextCatalog.getString('Substraction'),
      description: gettextCatalog.getString(
        'Substracts neighbouring expressions'
      ),
      value: '-'
    },
    {
      type: 'operation',
      title: gettextCatalog.getString('Multiplication'),
      description: gettextCatalog.getString(
        'Multiplies neighbouring expressions'
      ),
      value: '*'
    },
    {
      type: 'operation',
      title: gettextCatalog.getString('Division'),
      description: gettextCatalog.getString('Divides neighbouring expressions'),
      value: '/'
    },
    {
      type: 'operation',
      title: gettextCatalog.getString('Power'),
      description: gettextCatalog.getString(
        'Apply power of right-side neighbouring number (or expression) to the left side neighbouring number (or expression).'
      ),
      value: '^'
    },
    {
      type: 'operation',
      title: gettextCatalog.getString('Modulo'),
      description: gettextCatalog.getString(
        'Returns the remaining whole number after division.'
      ),
      value: '%'
    }
  ];

  const LogicalOperatorsConstants = [
    {
      type: 'operation',
      title: gettextCatalog.getString('And'),
      description: gettextCatalog.getString(
        'Performs a logical AND of the two operands.'
      ),
      value: 'and'
    },
    {
      type: 'operation',
      title: gettextCatalog.getString('Or'),
      description: gettextCatalog.getString(
        'Performs a logical OR of the two operands.'
      ),
      value: 'or'
    }
  ];
  const CompareOperatorsConstants = [
    {
      type: 'operation',
      title: gettextCatalog.getString('Less than'),
      description: gettextCatalog.getString(
        'Returns true if the value on the left is less than the value on the right.'
      ),
      value: '<'
    },
    {
      type: 'operation',
      title: gettextCatalog.getString('Greater than'),
      description: gettextCatalog.getString(
        'Returns true if the value on the left is greater than the value on the right.'
      ),
      value: '>'
    },
    {
      type: 'operation',
      title: gettextCatalog.getString('Less than or equal'),
      description: gettextCatalog.getString(
        'returns true if the left operand is less than or equal to the right operand.'
      ),
      value: '<='
    },
    {
      type: 'operation',
      title: gettextCatalog.getString('Greater than or equal'),
      description: gettextCatalog.getString(
        'Returns true if the left operand is greater than or equal to the right operand.'
      ),
      value: '>='
    },
    {
      type: 'operation',
      title: gettextCatalog.getString('Equal to'),
      description: gettextCatalog.getString(
        'Returns true if both operands have the same value.'
      ),
      value: '=='
    },
    {
      type: 'operation',
      title: gettextCatalog.getString('Not equal to'),
      description: gettextCatalog.getString(
        'Returns true if the operands don\'t have the same value.'
      ),
      value: '!='
    }
  ];
  /**
   * @description sets symbol of operator to operator value.
   * @function
   * @param {Object} operator
   * @return {Object}
   */
  function setSymbol(operator) {
    if (operator.value) {
      operator.symbol = `[ ${operator.value} ]`;
    }
    return operator;
  }
  /**
   * @description returns value of operator.
   * @function
   * @param {Object} operator
   * @return {String}
   */
  function getValue(operator) {
    return operator.value;
  }
  /**
   * @description returns array of operators configurations.
   * @function
   * @return {Array}
   */
  function getDialogConfig() {
    const operators = OperatorConstants.map(setSymbol);
    const logicalOperators = LogicalOperatorsConstants.map(setSymbol);
    const compareOperators = CompareOperatorsConstants.map(setSymbol);
    return [
      {
        type: 'subtitle',
        text: gettextCatalog.getString('Operations')
      },
      ...operators,
      {
        type: 'subtitle',
        text: gettextCatalog.getString('Logical Operations')
      },
      ...logicalOperators,
      {
        type: 'subtitle',
        text: gettextCatalog.getString('Compare Operations')
      },
      ...compareOperators
    ];
  }
  /**
   * @description returns array of allowed operators.
   * @function
   * @return {Array}
   */
  function getConstants() {
    const operators = OperatorConstants.filter(getValue).map(getValue);
    const logicalOperators = LogicalOperatorsConstants.map(getValue);
    const compareOperators = CompareOperatorsConstants.map(getValue);

    return [...operators, ...logicalOperators, ...compareOperators];
  }

  return {
    getConstants,
    getDialogConfig
  };
}

export default AllowedOperatorsService;
