assetStatusConfiguration.$inject = [
  'gettext',
  '$q',
  'SfeFormDialogService',
  'ListFormService',
  'AlertingService',
  'CrudToastFactory',
  'AssetStatusModel',
  'MultiLanguage'
];

function assetStatusConfiguration(
  gettext,
  $q,
  SfeFormDialogService,
  ListFormService,
  AlertingService,
  CrudToastFactory,
  AssetStatusModel,
  MultiLanguage
) {
  const fieldNames = ['name', 'description', 'code', 'externalCode'];

  function openCreateDialog(relistData) {
    var config = ListFormService.generateForm(
      ['name', 'description', 'code', 'externalCode'],
      true
    );
    var title = gettext('New Asset Status');
    SfeFormDialogService.openSfeFormDialog(false, config, {}, title).then(
      function(object) {
        if (object) {
          MultiLanguage.constructForm(config, object);
          create(object, relistData);
        }
      }
    );
  }

  function openUpdateDialog(item, relistData) {
    AssetStatusModel.custom
      .readAllLang({
        id: item._id
      })
      .then(
        function(res) {
          var config = ListFormService.generateForm(
            ['name', 'description', 'code', 'externalCode'],
            true
          );
          var title = gettext('Edit Asset Status');
          SfeFormDialogService.openSfeFormDialog(
            true,
            config,
            res.data,
            title
          ).then(function(object) {
            if (object) {
              MultiLanguage.constructForm(config, object);
              update(item._id, object, relistData);
            }
          });
        },
        function(err) {
          AlertingService.Error(err);
        }
      );
  }

  function create(obj, relistData) {
    AssetStatusModel.create(
      ListFormService.generateBodyObject(obj, fieldNames)
    ).then(
      function() {
        CrudToastFactory.toast('create');
        relistData();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function update(id, obj, relistData) {
    AssetStatusModel.update(
      {
        id: id
      },
      ListFormService.generateBodyObject(obj, fieldNames)
    ).then(
      function() {
        CrudToastFactory.toast('update');
        relistData();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  var configuration = {
    entity: {
      singular: gettext('Asset Status'),
      plural: gettext('Asset Statuses')
    },
    listId: 'asset-status',
    api: {
      query: {
        entity: 'asset-statuses',
        method: 'read'
      },
      enrich: Enrich
    },
    buttons: [
      {
        title: gettext('Create'),
        fn: openCreateDialog,
        relist: true,
        color: 'accent'
      }
    ],
    title: {
      param: 'name'
    },
    shortActions: [
      {
        icon: 'mode_edit',
        action: openUpdateDialog
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'asset-statuses',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description')
      },
      {
        param: 'code',
        title: gettext('Code'),
        type: 'code'
      },
      {
        param: 'externalCode',
        title: gettext('External code'),
        type: 'code'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Code'),
        param: 'code',
        type: 'string'
      },
      {
        display: gettext('External Code'),
        param: 'externalCode',
        type: 'string'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich() {
    var deferred = $q.defer();
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default assetStatusConfiguration;
