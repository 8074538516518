import { RouteManager, gettext } from '../routes';

import ConnectorsListView from './pages/connectors-list/connectors-list.view.html';
import TangoAgentConnectionConfigView from './pages/tango-agent-connection-config/tango-agent-connection-config.view.html';
import ExternalDatasourceView from './pages/external-datasource/external-datasource.view.html';
import JobResultsView from './pages/job-results/job-results.view.html';
import ConnectorView from './pages/connector/connector.view.html';
import SfeListView from '../common/components/sfe-list/sfe-list.view.html';
import NewFormBasicView from '../common/views/new-form-basic.view.html';

loadCharts.$inject = ['loadAssets'];
function loadCharts(loadAssets) {
  return loadAssets(['highcharts']);
}

loadEntity.$inject = ['$transition$', 'StateResolveService'];
function loadEntity($transition$, StateResolveService) {
  return StateResolveService.fetchEntityForView($transition$);
}

let DeviceRouteManager = new RouteManager();
DeviceRouteManager.state('external-datasources', {
  url: '/external-datasources',
  isMainMenuItem: true,
  menuIndex: 4,
  pageTitle: gettext('External Datasources'),
  icon: 'devices_other',
  redirectTo: 'external-datasources-external-datasources-list',
  theme: 'tango',
  accessScopeFe: 'tis_fe:external-datasources',
  accessScopeBe: 'tis_be:external-datasources'
})
  // LIST of EXTERNAL DATASOURCE ITEMS
  .state('external-datasources-external-datasources-list', {
    url: '/external-datasources/external-datasources/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('External Datasources'),
    icon: 'router',
    parentState: 'external-datasources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:external-datasources',
    accessScopeBe: 'tis_be:external-datasources'
  })
  // FORM for NEW EXTERNAL DATASOURCE
  .state('external-datasources-external-datasources-new', {
    url: '/external-datasources/external-datasources/new',
    isSubMenuItem: true,
    template: NewFormBasicView,
    controller: 'NewExternalDatasourceFormController',
    controllerAs: 'vm',
    pageTitle: gettext('New External Datasource'),
    icon: 'router',
    iconMode: 'add',
    parentState: 'external-datasources',
    params: {
      item: null
    },
    theme: 'tango',
    accessScopeFe: 'tis_fe:external-datasources',
    accessScopeBe: 'tis_be:external-datasources',
    mode: 'create',
    resolve: {
      externalDatasource: () => ({})
    }
  })
  // VIEW of EXTERNAL DATASOURCE
  .state('external-datasources-external-datasources-view', {
    url: '/external-datasources/external-datasources/{id}',
    template: ExternalDatasourceView,
    controller: 'ExternalDatasourceController',
    controllerAs: 'vm',
    pageTitle: gettext('External Datasources'),
    parentState: 'external-datasources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:external-datasources',
    accessScopeBe: 'tis_be:external-datasources',
    mode: 'read',
    entity: 'external-datasources',
    resolve: {
      loadScripts: loadCharts,
      externalDatasource: loadEntity
    }
  })
  // FORM for EDIT EXTERNAL DATASOURCE
  .state('external-datasources-external-datasources-edit', {
    url: '/external-datasources/external-datasources/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewExternalDatasourceFormController',
    controllerAs: 'vm',
    parentState: 'external-datasources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:external-datasources',
    accessScopeBe: 'tis_be:external-datasources',
    entity: 'external-datasources',
    mode: 'update',
    resolve: {
      externalDatasource: loadEntity
    }
  })

  // VIEW of JOB RESULTS
  .state('external-datasources-job-results-view', {
    url: '/external-datasources/job-results/{id}',
    template: JobResultsView,
    controller: 'JobResultsController',
    controllerAs: 'vm',
    pageTitle: gettext('Job Results'),
    parentState: 'external-datasources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:external-datasources',
    accessScopeBe: 'tis_be:tango-agent-connection-configs',
    mode: 'read'
  })
  // LIST of DATA SOURCES
  .state('external-datasources-tango-agent-connection-config-list', {
    url: '/external-datasources/tango-agent-connection-configuration/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Tango Agent Connection'),
    icon: 'cast',
    parentState: 'external-datasources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:tango-agent-connection-configs',
    accessScopeBe: 'tis_be:tango-agent-connection-configs'
  })
  // LIST of CONNECTORS
  .state('external-datasources-connectors-list', {
    url: '/external-datasources/connectors/list',
    template: ConnectorsListView,
    controller: 'ConnectorsListController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Connectors'),
    icon: 'cast',
    parentState: 'external-datasources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:connectors',
    accessScopeBe: 'tis_be:tango-agent-connection-configs'
  })
  // FORM for NEW WEBSERVICE
  .state('external-datasources-connectors-webservice-new', {
    url: '/external-datasources/connectors/webservice/new',
    isSubMenuItem: true,
    template: NewFormBasicView,
    controller: 'NewWebserviceFormController',
    controllerAs: 'vm',
    pageTitle: gettext('New Web Service'),
    parentState: 'external-datasources',
    params: {
      item: null
    },
    theme: 'tango',
    accessScopeFe: 'tis_fe:connectors',
    accessScopeBe: 'tis_be:tango-agent-connection-configs',
    mode: 'create',
    resolve: {
      webservice: () => ({})
    }
  })
  // FORM for NEW OPC
  .state('external-datasources-connectors-opc-new', {
    url: '/external-datasources/connectors/opc/new',
    template: NewFormBasicView,
    controller: 'NewOPCFormController',
    controllerAs: 'vm',
    pageTitle: gettext('New OPC'),
    parentState: 'external-datasources',
    params: {
      item: null
    },
    theme: 'tango',
    accessScopeFe: 'tis_fe:connectors',
    accessScopeBe: 'tis_be:tango-agent-connection-configs',
    mode: 'create',
    resolve: {
      opc: () => ({})
    }
  })
  // OPC VIEW
  .state('external-datasources-connectors-opc-view', {
    url: '/external-datasources/connectors/opc/{id}',
    template: ConnectorView,
    controller: 'OPCController',
    controllerAs: 'vm',
    pageTitle: gettext('OPC'),
    icon: 'router',
    parentState: 'external-datasources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:connectors',
    accessScopeBe: 'tis_be:tango-agent-connection-configs',
    mode: 'read',
    entity: 'opcs',
    resolve: {
      opc: loadEntity
    }
  })
  // FORM for EDIT OPC
  .state('external-datasources-connectors-opc-edit', {
    url: '/external-datasources/connectors/opc/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewOPCFormController',
    controllerAs: 'vm',
    parentState: 'external-datasources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:connectors',
    accessScopeBe: 'tis_be:tango-agent-connection-configs',
    mode: 'update',
    entity: 'opcs',
    resolve: {
      opc: loadEntity
    }
  })
  // WEBSERVICE VIEW
  .state('external-datasources-connectors-webservice-view', {
    url: '/external-datasources/connectors/webservice/{id}',
    template: ConnectorView,
    controller: 'WebserviceController',
    controllerAs: 'vm',
    icon: 'router',
    pageTitle: gettext('Web Service'),
    parentState: 'external-datasources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:connectors',
    accessScopeBe: 'tis_be:tango-agent-connection-configs',
    mode: 'read',
    entity: 'webservices',
    resolve: {
      webservice: loadEntity
    }
  })
  // FORM for EDIT WEBSERVICE
  .state('external-datasources-connectors-webservice-edit', {
    url: '/external-datasources/connectors/webservice/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewWebserviceFormController',
    controllerAs: 'vm',
    parentState: 'external-datasources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:connectors',
    accessScopeBe: 'tis_be:tango-agent-connection-configs',
    mode: 'update',
    entity: 'webservices',
    resolve: {
      webservice: loadEntity
    }
  })
  // FORM for NEW DATABASE
  .state('external-datasources-connectors-database-new', {
    url: '/external-datasources/connectors/database/new',
    isSubMenuItem: true,
    template: NewFormBasicView,
    controller: 'NewDatabaseFormController',
    controllerAs: 'vm',
    pageTitle: gettext('New Database'),
    parentState: 'external-datasources',
    params: {
      item: null
    },
    theme: 'tango',
    accessScopeFe: 'tis_fe:connectors',
    accessScopeBe: 'tis_be:tango-agent-connection-configs',
    mode: 'create',
    resolve: {
      database: () => ({})
    }
  })
  // DATABASE VIEW
  .state('external-datasources-connectors-database-view', {
    url: '/external-datasources/connectors/database/{id}',
    template: ConnectorView,
    controller: 'DatabaseController',
    controllerAs: 'vm',
    pageTitle: gettext('Database'),
    icon: 'router',
    parentState: 'external-datasources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:connectors',
    accessScopeBe: 'tis_be:tango-agent-connection-configs',
    mode: 'read',
    entity: 'databases',
    resolve: {
      database: loadEntity
    }
  })
  // FORM for EDIT DATABASE
  .state('external-datasources-connectors-database-edit', {
    url: '/external-datasources/connectors/database/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewDatabaseFormController',
    controllerAs: 'vm',
    parentState: 'external-datasources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:connectors',
    accessScopeBe: 'tis_be:tango-agent-connection-configs',
    mode: 'update',
    entity: 'databases',
    resolve: {
      database: loadEntity
    }
  })
  // FORM for NEW FILE
  .state('external-datasources-connectors-file-new', {
    url: '/external-datasources/connectors/file/new',
    isSubMenuItem: true,
    template: NewFormBasicView,
    controller: 'NewFileFormController',
    controllerAs: 'vm',
    pageTitle: gettext('New File'),
    parentState: 'external-datasources',
    params: {
      item: null
    },
    theme: 'tango',
    accessScopeFe: 'tis_fe:connectors',
    accessScopeBe: 'tis_be:tango-agent-connection-configs',
    mode: 'create',
    resolve: {
      file: () => ({})
    }
  })
  // FILE VIEW
  .state('external-datasources-connectors-file-view', {
    url: '/external-datasources/connectors/file/{id}',
    template: ConnectorView,
    controller: 'FileController',
    controllerAs: 'vm',
    pageTitle: gettext('File'),
    icon: 'router',
    parentState: 'external-datasources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:connectors',
    accessScopeBe: 'tis_be:tango-agent-connection-configs',
    mode: 'read',
    entity: 'files',
    resolve: {
      file: loadEntity
    }
  })
  // FORM for EDIT FILE
  .state('external-datasources-connectors-file-edit', {
    url: '/external-datasources/connectors/file/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewFileFormController',
    controllerAs: 'vm',
    parentState: 'external-datasources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:connectors',
    accessScopeBe: 'tis_be:tango-agent-connection-configs',
    mode: 'update',
    entity: 'files',
    resolve: {
      file: loadEntity
    }
  })
  // FORM for NEW DATA SOURCE
  .state('external-datasources-tango-agent-connection-config-new', {
    url:
      '/external-datasources/tango-agent-connection-configuration/new?opcId?externalDatasourceId?webserviceId?databaseId?fileId',
    isSubMenuItem: true,
    template: NewFormBasicView,
    controller: 'NewTangoAgentConnectionConfigController',
    controllerAs: 'vm',
    pageTitle: gettext('New Tango Agent Connection Configuration'),
    icon: 'cast',
    iconMode: 'add',
    parentState: 'external-datasources',
    params: {
      item: null
    },
    theme: 'tango',
    accessScopeFe: 'tis_fe:tango-agent-connection-configs',
    accessScopeBe: 'tis_be:tango-agent-connection-configs',
    mode: 'create',
    resolve: {
      tangoAgentConnectionConfig: () => ({})
    }
  })
  // LIST of DATA SOURCES
  .state('external-datasources-tango-agent-connection-config-view', {
    url: '/external-datasources/tango-agent-connection-configuration/{id}',
    template: TangoAgentConnectionConfigView,
    controller: 'TangoAgentConnectionConfigController',
    controllerAs: 'vm',
    pageTitle: gettext('Tango Agent Connection Configurations'),
    icon: 'router',
    parentState: 'external-datasources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:tango-agent-connection-configs',
    accessScopeBe: 'tis_be:tango-agent-connection-configs',
    mode: 'read',
    entity: 'tango-agent-connection-configs',
    resolve: {
      tangoAgentConnectionConfig: loadEntity
    }
  })
  // FORM for EDIT DATA SOURCE
  .state('external-datasources-tango-agent-connection-config-edit', {
    url: '/external-datasources/tango-agent-connection-configuration/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewTangoAgentConnectionConfigController',
    controllerAs: 'vm',
    parentState: 'external-datasources',
    theme: 'tango',
    accessScopeFe: 'tis_fe:tango-agent-connection-configs',
    accessScopeBe: 'tis_be:tango-agent-connection-configs',
    mode: 'update',
    entity: 'tango-agent-connection-configs',
    resolve: {
      tangoAgentConnectionConfig: loadEntity
    }
  });

export default DeviceRouteManager.routes;
