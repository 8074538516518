NewDomainFieldsConfigurationFormController.$inject = [
  'DomainFieldModel',
  'AlertingService',
  '$state',
  'gettext',
  '$timeout',
  'gettextCatalog',
  'CrudToastFactory',
  'MetadataService'
];

function NewDomainFieldsConfigurationFormController(
  DomainFieldModel,
  AlertingService,
  $state,
  gettext,
  $timeout,
  gettextCatalog,
  CrudToastFactory,
  MetadataService
) {
  var vm = this;
  var filterTypes = [
    {
      _id: 'equality',
      name: gettextCatalog.getString('Equality')
    },
    {
      _id: 'text',
      name: gettextCatalog.getString('Text')
    }
  ];
  var fieldTypes = [
    {
      _id: 'String',
      name: gettextCatalog.getString('String')
    },
    {
      _id: 'Integer',
      name: gettextCatalog.getString('Integer')
    },
    {
      _id: 'Float',
      name: gettextCatalog.getString('Float')
    },
    {
      _id: 'Boolean',
      name: gettextCatalog.getString('Boolean')
    },
    {
      _id: 'Date',
      name: gettextCatalog.getString('Date')
    }
  ];
  var filterTypeMenuConfig = {
    label: gettext('Filter type'),
    placeholder: gettext('Select Filter type'),
    ctrlFn: function() {
      return $timeout(function() {
        return filterTypes;
      }, 0);
    },
    edit: true
  };
  var fieldTypeMenuConfig = {
    label: gettext('Field type'),
    placeholder: gettext('Select Field type'),
    ctrlFn: function() {
      return $timeout(function() {
        return fieldTypes;
      }, 0);
    },
    edit: true
  };
  init();

  function init() {
    var header = {
      backgroundColor: 'primary',
      title: $state.params.fieldId
        ? gettext('Edit Domain Field')
        : gettext('New Domain Field'),
      actions: [
        {
          title: gettext('Cancel'),
          color: 'primary',
          whiteButton: true,
          raised: true,
          isLink: true,
          link:
            'configurations-dynamic-attributes-domains-view' +
            '(' +
            JSON.stringify({
              id: $state.params.domainId
            }) +
            ')'
        }
      ]
    };

    vm.dataConfig = {
      data: initForm(),
      header: header,
      edit: !!$state.params.fieldId,
      action: {
        ctrlFn: true,
        title: $state.params.fieldId ? gettext('Update') : gettext('Create'),
        fn: createOrUpdate
      }
    };

    if ($state.params.fieldId) {
      vm.editMode = true;
      readDomainField();
    } else {
      vm.dataConfig.dataObj = {
        name: '',
        description: '',
        rawFieldName: '',
        isKey: false,
        isFilter: false,
        isSort: false,
        fieldType: {},
        filterType: {}
      };
    }
  }

  function initForm() {
    return [
      {
        placeholder: 'Name',
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: 'Description',
        name: 'description',
        componentType: 'textArea',
        type: 'text',
        maxlength: 500,
        required: false
      },
      {
        placeholder: 'Raw field name',
        name: 'rawFieldName',
        componentType: 'textField',
        type: 'text'
      },
      {
        componentType: 'checkBox',
        label: 'Key',
        name: 'isKey'
      },
      {
        componentType: 'checkBox',
        label: 'Filter',
        name: 'isFilter'
      },
      {
        componentType: 'checkBox',
        label: 'Sort',
        name: 'isSort'
      },
      {
        componentType: 'multiSelect',
        config: fieldTypeMenuConfig,
        options: fieldTypes,
        name: 'fieldType'
      },
      {
        componentType: 'multiSelect',
        config: filterTypeMenuConfig,
        options: filterTypes,
        name: 'filterType'
      }
    ];
  }

  function readDomainField() {
    DomainFieldModel.read({
      id: $state.params.fieldId,
      domainId: $state.params.domainId
    }).then(
      function(res) {
        vm.dataConfig.dataObj = {
          name: res.data.name,
          description: res.data.description,
          rawFieldName: res.data.rawFieldName,
          isKey: res.data.isKey,
          isFilter: res.data.isFilter,
          isSort: res.data.isSort,
          fieldType: {
            _id: res.data.fieldType
          },
          filterType: {
            _id: res.data.filterType
          }
        };
        MetadataService.Loading(false);
        MetadataService.ChangeMetadata(
          'Edit ' + res.data.name,
          res.data.description
        );
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  // create and update functions

  function createOrUpdate() {
    vm.sendingRequest = true;
    var apiCall;
    var message;
    var redirectObj;
    if (vm.editMode) {
      apiCall = DomainFieldModel.update($state.params, dataToSave());
      message = 'update';
    } else {
      apiCall = DomainFieldModel.create(
        { domainId: $state.params.domainId },
        dataToSave()
      );
      message = 'create';
    }
    apiCall.then(
      function(res) {
        vm.sendingRequest = false;
        redirectObj = {
          params: {
            fieldId: res.data._id,
            domainId: $state.params.domainId
          },
          state: 'configurations-dynamic-attributes-domains-fields-view'
        };
        CrudToastFactory.toast(message, redirectObj);
      },
      function(err) {
        vm.sendingRequest = false;
        AlertingService.Error(err);
      }
    );
  }

  function dataToSave() {
    return {
      name: vm.dataConfig.dataObj.name,
      description: vm.dataConfig.dataObj.description,
      rawFieldName: vm.dataConfig.dataObj.rawFieldName,
      isKey: vm.dataConfig.dataObj.isKey,
      isFilter: vm.dataConfig.dataObj.isFilter,
      isSort: vm.dataConfig.dataObj.isSort,
      fieldType: vm.dataConfig.dataObj.fieldType._id,
      filterType: vm.dataConfig.dataObj.filterType._id,
      domainId: $state.params.domainId
    };
  }
}

export default NewDomainFieldsConfigurationFormController;
