TimeSeriesAssetModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function TimeSeriesAssetModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'time-series-assets'
  };

  var crawlerConfiguration = {
    entity: 'time-series-assets',
    method: 'read',
    steps: [
      {
        entity: 'assets',
        valueParams: ['asset'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'populateArray',
        populate: 'assetBrand,type,assetModel,status'
      }
    ]
  };

  var crawlerConfigurationEntityDate = {
    entity: 'time-series-assets',
    method: 'read',
    steps: [
      {
        entity: 'assets',
        valueParams: ['asset'],
        queryParams: ['_id'],
        method: 'custom.list',
        methodType: 'populateArray'
      }
    ]
  };

  var crawlerConfigurationMeasurement = {
    entity: 'time-series-assets',
    method: 'read',
    populate: 'timeSeries'
  };
  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readAsset: CrawlerMethods.crawler(crawlerConfiguration),
      readAssetEntityDate: CrawlerMethods.crawler(
        crawlerConfigurationEntityDate
      ),
      readMeasurement: CrawlerMethods.crawler(crawlerConfigurationMeasurement)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default TimeSeriesAssetModel;
