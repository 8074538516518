/**
 * @ngdoc service
 * @name common.SfeDataXMinTickInterval
 * @description function that transforms scheduler id to amount of miliseconds that scheduler represents
 * @property {number} schedulerId - Id of the scheduler classification
 */

SfeDataXMinTickInterval.$inject = [];
export default function SfeDataXMinTickInterval() {
  const getTickInterval = schedulerId => {
    switch (schedulerId) {
    case 1: //hourly
      return 1000 * 60 * 60;
    case 2: //Daily
      return 1000 * 60 * 60 * 24;
    case 3: // Weekly
      return 1000 * 60 * 60 * 24 * 7;
    case 4: //Monthly
      return 1000 * 60 * 60 * 24 * 28;
    case 5: //Yearly
      return 1000 * 60 * 60 * 24 * 365;
    case 6: //15 minutes
      return 1000 * 60 * 15;
    case 7: // 30 minutes
      return 1000 * 60 * 30;
    case 8: // 6 months
      return 1000 * 60 * 60 * 24 * 182.5;
    case 9: // 1 minute
      return 1000 * 60;
    default:
      return undefined;
    }
  };
  return {
    getTickInterval
  };
}
