import AlarmModule from './alarms/alarms.module';
import AnalyticsModule from './analytics/analytics.module';
import CommonModule from './common/common.module';
import CompanyResourcesModule from './company-resources/company-resources.module';
import ConfigModule from './config/config.module';
import CoreModule from './core/core.module';
import DashboardModule from './dashboard/dashboard.module';
import DataModule from './data/data.module';
import DeviceModule from './device/device.module';
import InvoicesModule from './invoices/invoices.module';
import MapsModule from './maps/maps.module';
import MonitoringModule from './monitoring/monitoring.module';
import UserModule from './user/user.module';
import VisualizationModule from './visualization/visualization.module';

import '../config/tis/translations';

var applicationModuleName = 'tis';

var service = {
  applicationModuleName: applicationModuleName,
  applicationModuleVendorDependencies: [
    'ngResource',
    'ngAnimate',
    'ngRoute',
    'ngMessages',
    'ui.router',
    'angularFileUpload',
    'ngMaterial',
    'md.data.table',
    'smartisOauth',
    'ui.calendar',
    'gettext',
    'smartVisualization',
    'ngMap',
    'dndLists',
    'ngQuill',
    'ngSanitize',
    'smartis-frontend-components',
    'angularjs-gauge',
    'angular-cache',
    'ngRedux'
  ],
  modules: [
    AlarmModule,
    AnalyticsModule,
    CommonModule,
    CompanyResourcesModule,
    ConfigModule,
    CoreModule,
    DashboardModule,
    DataModule,
    DeviceModule,
    InvoicesModule,
    MapsModule,
    MonitoringModule,
    UserModule,
    VisualizationModule
  ]
};
window.initLoading_UpdateProgress();

export default service;
