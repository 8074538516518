import types from './external-reader-flow.type';

function readExternalReaderFlow(payload) {
  return {
    type: types.EXTERNAL_READER_FLOW_READ,
    payload
  };
}

function updateExternalReaderFlow(payload) {
  return {
    type: types.EXTERNAL_READER_FLOW_UPDATE,
    payload
  };
}

function createExternalReaderFlow(payload) {
  return {
    type: types.EXTERNAL_READER_FLOW_CREATE,
    payload
  };
}

function addExternalReaderFlow(payload) {
  return {
    type: types.EXTERNAL_READER_FLOW_ADD,
    payload
  };
}

export default {
  readExternalReaderFlow,
  updateExternalReaderFlow,
  createExternalReaderFlow,
  addExternalReaderFlow
};
