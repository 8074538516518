SchedulerController.$inject = [
  '$state',
  'CronService',
  'gettext',
  'MetadataService',
  'TranslationService',
  'gettextCatalog',
  'scheduler',
  '$timeout'
];

function SchedulerController(
  $state,
  CronService,
  gettext,
  MetadataService,
  TranslationService,
  gettextCatalog,
  scheduler,
  $timeout
) {
  var vm = this;
  const schedulerId = $state.params.id;

  vm.$onInit = function init() {
    vm.loading = true;
    MetadataService.Loading(true);

    vm.loading = false;
    vm.headerData = constructHeader(scheduler);
    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(scheduler.name, scheduler.description);
    });
  };

  function constructHeader(scheduler) {
    var actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: schedulerId,
        endpoint: {
          entity: 'schedulers',
          method: 'delete'
        },
        redirectState: 'data-schedules-list'
      },
      {
        title: gettext('Update'),
        state: 'data-schedules-edit',
        param: 'id',
        paramValue: scheduler._id
      }
    ];
    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];

    var properties = [
      {
        title: gettext('Created'),
        value: scheduler.created,
        type: 'date'
      },
      {
        title: gettext('Always valid'),
        value: scheduler.alwaysValid,
        customOptions: [gettext('Yes'), gettext('No')],
        type: 'radio'
      }
    ];

    if (!scheduler.alwaysValid) {
      properties.push(
        {
          title: gettext('Schedule from'),
          value: scheduler.validFromDate,
          type: 'date',
          format: 'dd. MM. yyyy, HH:mm:ss'
        },
        {
          title: gettext('Schedule to'),
          value: scheduler.validToDate,
          type: 'date',
          format: 'dd. MM. yyyy, HH:mm:ss'
        }
      );
    }

    properties.push(
      {
        title: gettext('Cron expression literal'),
        value: CronService.GetCronLiteral(scheduler.crontabExpression),
        type: 'simple'
      },
      {
        title: gettext('Cron expression'),
        value: scheduler.crontabExpression,
        type: 'simple'
      }
    );
    let scheduleClassificationCodeList = TranslationService.GetCollectionById(
      'codelists.scheduleClassifications',
      scheduler.scheduleClassification
    );
    let scheduleClassificationValue = gettextCatalog.getString(
      'Unknown schedule classification'
    );
    if (scheduleClassificationCodeList != null) {
      scheduleClassificationValue = scheduleClassificationCodeList.name;
    }
    properties.push({
      title: gettext('Schedule classification'),
      value: scheduleClassificationValue,
      type: 'simple'
    });

    properties.push({
      title: gettext('Is standard'),
      value: scheduler.isStandard,
      customOptions: [gettext('Yes'), gettext('No')],
      type: 'radio'
    });

    var propertySections = [
      {
        title: gettext('properties'),
        properties: properties
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'schedulers',
        target: schedulerId,
        type: 'userAccess'
      }
    ];
    return {
      metadata: {
        definition: gettext('Scheduler'),
        title: scheduler.name,
        description: scheduler.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}

export default SchedulerController;
