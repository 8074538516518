MappingRuleController.$inject = [
  '$stateParams',
  'gettext',
  'TranslationService',
  'MetadataService',
  'mapper',
  '$timeout'
];

function MappingRuleController(
  $stateParams,
  gettext,
  TranslationService,
  MetadataService,
  mapper,
  $timeout
) {
  var vm = this;
  var mappingRuleId = $stateParams.id;
  vm.$onInit = init;

  function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    vm.mapper = mapper;
    vm.mapper.literalMappings.sort(function(a, b) {
      return a.order - b.order;
    });
    vm.mapper.formatMappings.sort(function(a, b) {
      return a.order - b.order;
    });

    var dataTypes = TranslationService.GetCollection('codelists.dataTypes');
    vm.mapperDataType = dataTypes.find(function(dataType) {
      return dataType.id == vm.mapper.dataType;
    });

    vm.headerData = constructHeader();
    $timeout(() => {
      vm.loading = false;
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(vm.mapper.name, vm.mapper.description);
    });
  }

  function constructHeader() {
    var actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: mappingRuleId,
        endpoint: {
          entity: 'mappers',
          method: 'delete'
        },
        redirectState: 'alarms-and-rules-mapping-rules-list'
      },
      {
        title: gettext('duplicate'),
        state: 'alarms-and-rules-mapping-rules-duplicate',
        param: 'id',
        paramValue: vm.mapper._id
      },
      {
        title: 'edit',
        state: 'alarms-and-rules-mapping-rules-edit',
        param: 'id',
        paramValue: vm.mapper._id
      }
    ];

    var properties = [
      {
        title: gettext('Data type'),
        value: vm.mapperDataType ? vm.mapperDataType.name : 'unknown',
        type: 'simple'
      }
    ];

    var authorizedPersonnel = [
      {
        title: gettext('Users'),
        users: true,
        type: 'userAccess'
      },
      {
        title: gettext('Groups'),
        users: false,
        type: 'userAccess'
      }
    ];

    var propertySections = [
      {
        properties: properties,
        title: 'properties'
      },
      {
        title: gettext('Authorized personnel'),
        properties: authorizedPersonnel,
        identifier: 'mappers',
        target: mappingRuleId,
        type: 'userAccess'
      }
    ];

    return {
      metadata: {
        definition: gettext('mapping rule'),
        title: vm.mapper.name,
        description: vm.mapper.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}

export default MappingRuleController;
