/**
 * @ngdoc directive
 * @name common.sfe-tree
 * @description Used for displaying tree structures with parent - child relations
 * @param {Object} firstElement Element based on which the tree is built, it is also initially displayed in the sideview
 * @param {Function} selectAction used for displaying the clicked item in the sideview
 * @param {Function} showConnectedToggle toggle to display/hide connected entities
 * @param {Function} entity initial entity based on which the tree is built
 * @param {Function} treeType root: lists all parentless items, hierarchy: lists consecutive parents of the firstElement, childrenTree: displays all children (and children's children...) and/or connected entities of the selected element
 * @param {Object} childEntity used in childTree mode - to know which children/connected entities to display
 * @param {Function} queryObject if filters are used it adds the filter input to the query
 * @param {Function} state current state, which is needed to check which entities to display
 * @param {Boolean} refresh used for refreshing the tree view if a child is added to the entity
 * @param {Boolean} editFunction true if the user wants to edit the element (currently used only at location, but should be generalized)
 * @example
 * <sfe-tree
 * flex
 * first-element="vm.location"
 * select-action="vm.elementSelected"
 * tree-type="vm.treeType"
 * entity="vm.entity"
 * edit-function="vm.editActiveAsset"
 * child-entity="vm.connectedEntity"
 * refresh="vm.refresh"
 * ></sfe-tree>
 */

import './sfe-tree.scss';
import './cr-tree-overview.scss';
import template from './sfe-tree.directive.html';

export default function sfeTree() {
  return {
    restrict: 'E',
    template,
    scope: {
      firstElement: '<',
      selectAction: '=',
      showConnectedToggle: '<',
      entity: '<',
      treeType: '<',
      childEntity: '<',
      queryObject: '<',
      treeFormObject: '<',
      state: '<',
      refresh: '=',
      editFunction: '<'
    },
    controller: sfeTreeController,
    controllerAs: 'vm',
    bindToController: true
  };
}

sfeTreeController.$inject = [
  'AlertingService',
  '$timeout',
  'EntitiesService',
  'MetadataService',
  'CrawlerMethods',
  'gettextCatalog',
  '$element',
  '$scope'
];

function sfeTreeController(
  AlertingService,
  $timeout,
  EntitiesService,
  MetadataService,
  CrawlerMethods,
  gettextCatalog,
  $element,
  $scope
) {
  const vm = this;
  let rootPagination;
  vm.listChildren = listChildren;
  vm.collapseElement = collapseElement;
  vm.loadMore = loadMore;
  vm.showDetails = showDetails;
  vm.init = init;

  let treeFilterObject = {
    limit: 100
  };

  vm.$onChanges = function() {
    vm.entityTreeConfiguration = EntitiesService.getHierarchyTree(vm.entity);
    if (vm.refresh) {
      vm.refresh = init;
    }
    init();
  };

  /**
   * @memberof sfe-tree
   * @description Initializes the tree view along with the side view via configuration of the firstElement
   */
  function init() {
    MetadataService.Loading(true);
    vm.loadingData = true;
    vm.showConnectedEntities = vm.entityTreeConfiguration.showConnectedEntities;
    const parentConfig = EntitiesService.getHierarchyTree(
      vm.entityTreeConfiguration.parentEntity
    );
    const parentKey = vm.entityTreeConfiguration.parentKey;
    if (vm.firstElement) {
      // element to be displayed on the right side of the dialog by default
      vm.firstElement = configureElement(
        vm.firstElement,
        vm.entityTreeConfiguration
      );
      vm.firstElement.firstElement = true;
      vm.selectAction(vm.firstElement);
    }

    switch (vm.treeType) {
    case 'root': // lists parentless items
      getRootElements(parentConfig);
      break;
    case 'hierarchy': // shows current item's level of hierarchy
      if (vm.firstElement[parentKey]) {
        getParent(vm.firstElement, parentConfig, parentKey);
      } else {
        $timeout(function() {
          vm.treeFormObject = vm.firstElement;
          vm.loadingData = false;
          MetadataService.Loading(false);
        });
      }
      break;
    case 'childrenTree': // lists all children of the item (and children's children, etc...)
      var childTreeConfiguration = getChildTreeConfiguration();
      if (childTreeConfiguration.type === 'simple') {
        getChildrenTree(
          childTreeConfiguration,
          vm.entity,
          vm.firstElement._id
        );
      } else {
        //type connected
        getConnectedEntityChildrenTree(childTreeConfiguration);
      }
      break;
    }
  }

  // QUERY FUNCTIONS //

  /**
   * @memberof sfe-tree
   * @description Fetches the children tree of the chosen entity and configures its elements for display
   * @param {object} childTreeConfiguration - Configuration of the tree which is to be built
   * @param {string} entity - used to define which entity to query
   * @param {string} id - id of the selected entity item
   * @param {object} element - needed if there are multiple elements on which to query for their children trees (in getConnectedEntityChildrenTree function)
   */
  async function getChildrenTree(childTreeConfiguration, entity, id, element) {
    vm.loadingData = true;
    const method = CrawlerMethods.getMethod({
      entity: entity,
      method: 'read'
    });
    let treeFilter = {
      parentId: id
    };

    if (!element) {
      treeFilter = {
        ...treeFilter,
        ...treeFilterObject
      };
    }
    try {
      const { data, pagination } = await method(treeFilter);

      if (element) {
        element.children = configureChildrenTree(data, childTreeConfiguration);
      } else {
        treeFilterObject = {
          ...treeFilterObject,
          page: pagination.page + 1
        };

        if (!Array.isArray(vm.rootElements)) {
          vm.rootElements = [];
        }
        const rootItems = configureChildrenTree(data, childTreeConfiguration);
        vm.rootElements = vm.rootElements.concat(rootItems);

        // display the load more button if there are more items to load
        vm.displayLoadMore =
          pagination.total > pagination.page * pagination.per_page;
      }

      vm.loadingData = false;
      updateView();
    } catch (err) {
      AlertingService.Error(err);
      vm.loadingData = false;
    }
  }

  /**
   * @memberof sfe-tree
   * @description Fetches the connected entities children tree of the chosen entity and configures its elements for display
   * @param {string} childTreeConfiguration - Configuration of the tree which is to be built
   */
  async function getConnectedEntityChildrenTree(childTreeConfiguration) {
    // initial item's connected entities
    const method = CrawlerMethods.getMethod({
      entity: vm.entity,
      method: 'custom.readConnectedEntities'
    });
    const queryObj = childTreeConfiguration.defaultQueryObject || {};
    queryObj[childTreeConfiguration.queryParam] = vm.firstElement._id;
    try {
      const { data } = await method(queryObj);
      data.forEach(function(rootElement) {
        for (let attrname in rootElement.child) {
          if (attrname === '_id') {
            rootElement.linkerId = rootElement._id;
          }
          rootElement[attrname] = rootElement.child[attrname];
        }
      });
      vm.rootElements = configureChildrenTree(
        data,
        childTreeConfiguration,
        true
      );
      vm.loadingData = false;

      // get children of all connected entities (rootElements)
      vm.rootElements.forEach(function(element) {
        getChildrenTree(
          childTreeConfiguration,
          element.route,
          element._id,
          element
        );
      });
      updateView();
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  /**
   * @memberof sfe-tree
   * @description  Fetches children and/or connected entities of the chosen item and configures its elements for display
   * @param {string} item - Selected item of which children should be displayedy
   * @param {boolean} hideButton - Used in hierarchy treeType; after refreshing an element, hide its refresh button
   */
  async function listChildren(item, hideButton, nextPage) {
    if (item.childrenLoaded && !hideButton && !nextPage) {
      item.opened = true;
      item.hideRefreshButton = false;
      item.couldHaveOtherChildren = true;
    } else {
      item.loadingChildren = true;
      item.childrenLoaded = true;
      vm.hideRefreshButton = true;
      if (!item.children) {
        item.children = [];
      }
      if (hideButton) {
        item.hideRefreshButton = true;
        item.page = 1;
      }

      // determine child fetching config based on clicked item's route
      var childrenConfig = EntitiesService.getHierarchyTree(item.route)
        .childrenConfiguration;

      const promises = generateChildrenFetchingCalls(
        item,
        childrenConfig,
        nextPage
      );
      try {
        await Promise.all(promises);
        vm.loadingData = false;
        item.loadingChildren = false;
        if (!item.children.length) {
          item.hasNoChildren = gettextCatalog.getString(
            'Item has no connected entities for display.'
          );
          item.hasChild = false;
        }
        updateView();
      } catch (err) {
        AlertingService.Error(err);
      }
    }
  }

  /**
   * @memberof sfe-tree
   * @description Gets direct children of the selected parent
   * @param {object} parent - Parent object to which fetched children are added
   * @param {object} childrenConfiguration - Configuration of the children based on which the query for children is built
   */
  async function getChildren(parent, childrenConfiguration, nextPage) {
    // construct query object to find children in the same entity
    const obj = {};
    obj[childrenConfiguration.childKey] = parent._id;
    if (nextPage) {
      obj.page = ++parent.page;
    }
    const method = CrawlerMethods.getMethod({
      entity: childrenConfiguration.route,
      method: 'read'
    });
    try {
      const { data, pagination } = await method(obj);
      parent.opened = true;
      parent.children = showConnectedEntities(nextPage) ? parent.children : [];
      parent.children = configureChildren(
        parent.children,
        data,
        childrenConfiguration
      );
      // display the load more button if there are more items to load
      parent.displayLoadMore =
        pagination.total > pagination.page * pagination.per_page;
      parent.page = parent.page || 1;
      updateView();
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  /**
   * @memberof sfe-tree
   * @description Gets connected entities of the selected parent (e.g. adding child assets to parent location). It's a two step process - 1. fetch the linker array, 2. query for found connected entities
   * @param {object} parent - Parent object to which fetched connected entities are added
   * @param {object} childrenConfiguration - Configuration of the children based on which the query for children is built
   */
  async function getConnectedEntityChildren(parent, childrenConfiguration) {
    try {
      const linkerData = await getLinkerData(parent, childrenConfiguration);
      const [currentStep, childrenData] = await getLinkedChildren(
        linkerData,
        childrenConfiguration
      );
      if (childrenData) {
        parent.opened = true;
        parent.children = configureChildren(
          parent.children,
          childrenData,
          currentStep
        );
      }
      updateView();
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  /**
   * @memberof sfe-tree
   * @description Used in getConnectedEntityChildren to get the linker array
   * @param {object} parent - Parent object to which fetched connected entities added in the next step (getLinkedChildren)
   * @param {object} childrenConfiguration - Configuration of the children based on which the query for children is built
   */
  async function getLinkerData(parent, childrenConfiguration) {
    const obj = {};
    const currentStep = childrenConfiguration.steps[0];
    obj[currentStep.childKey] = parent._id;
    const method = CrawlerMethods.getMethod({
      entity: currentStep.route,
      method: 'read'
    });
    const { data } = await method(obj);
    return data;
  }

  /**
   * @memberof sfe-tree
   * @description Used in getConnectedEntityChildren to get the linked children array
   * @param {object} parent - Parent object to which fetched connected entities are added
   * @param {object} childrenConfiguration - Configuration of the children based on which the query for children is built
   * @param {object} linkerData - array with containing ids to be queried
   */
  async function getLinkedChildren(linkerData, childrenConfiguration) {
    const currentStep = childrenConfiguration.steps[1];
    if (!linkerData || !linkerData.length) {
      return [currentStep, null];
    }

    // linkerData is an array of objects with ids that are found by the parentKey
    const ids = JSON.stringify(_.map(linkerData, currentStep.parentKey));
    const method = CrawlerMethods.getMethod({
      entity: currentStep.route,
      method: 'read'
    });
    const { data } = await method({
      _id: ids
    });
    return [currentStep, data];
  }

  /**
   * @memberof sfe-tree
   * @description Fetch the parent based on child's id
   * @param {object} child - object containing the id which is needed to query for its parent
   * @param {object} parentConfig - Configuration of the parent based on which the query for child's parent is built
   * @param {string} parentKey - value of the key to be queried in order to retrieve the parent of the selected child
   */
  async function getParent(child, parentConfig, parentKey) {
    if (typeof child[parentKey] == 'object') {
      child[parentKey] = child[parentKey]._id;
    }
    const method = CrawlerMethods.getMethod({
      entity: parentConfig.route,
      method: 'read'
    });
    try {
      const { data } = await method({
        id: child[parentKey]
      });

      const parent = configureElement(data, parentConfig, child);
      // check if thing will start looping
      // should be deleted once BE fixes it
      const loopFollows = checkForLoop(parent, parentConfig.parentKey);

      // if current element has a parent, fetch it
      if (parent[parentConfig.parentKey] && !loopFollows) {
        getParent(parent, parentConfig, parentConfig.parentKey);
      } else {
        $timeout(function() {
          MetadataService.Loading(false);
          vm.treeFormObject = parent;
          vm.loadingData = false;
        });
      }
      updateView();
    } catch (err) {
      MetadataService.Loading(false);
      AlertingService.Error(err);
      vm.loadingData = false;
    }
  }

  /**
   * @memberof sfe-tree
   * @description Get and configure all parentless elements
   * @param {object} parentConfig - Configuration of the parent of the selected entity based on which the query for parentless elements is built
   * @param {boolean} nextPage - needed for listing elements that have not yet been displayed (from the next page)
   */
  async function getRootElements(parentConfig, nextPage) {
    vm.rootElements = vm.rootElements ? vm.rootElements : [];
    vm.loadingData = true;
    vm.queryObject = vm.queryObject || {};
    vm.queryObject[parentConfig.parentKey] = JSON.stringify(null);

    if (angular.equals(vm.queryObject, {})) {
      rootPagination = null;
    }

    if (rootPagination && nextPage) {
      vm.queryObject.limit = rootPagination.per_page;
      vm.queryObject.page = rootPagination.page + 1;
    }
    const method = CrawlerMethods.getMethod({
      entity: parentConfig.route,
      method: 'read'
    });

    try {
      const { data, pagination } = await method(vm.queryObject);
      rootPagination = pagination;

      // če filtriramo, na novo nastavimo vse root elemente
      if (
        !angular.equals(vm.queryObject, {}) &&
        !(vm.queryObject && vm.queryObject.page)
      ) {
        vm.rootElements = [];
      }

      // display the load more button if there are more items to load
      vm.displayLoadMore =
        rootPagination.total > rootPagination.page * rootPagination.per_page;

      vm.rootElements = data.reduce((rootElements, parent) => {
        rootElements.push(configureElement(parent, parentConfig));
        return rootElements;
      }, vm.rootElements);

      vm.loadingData = false;
      MetadataService.Loading(false);
      updateView();
    } catch (err) {
      AlertingService.Error(err);
      MetadataService.Loading(false);
      vm.loadingData = false;
    }
  }

  // HELPER FUNCTIONS //
  /**
   * @memberof sfe-tree
   * @description Checks if current element's parent key value has already been fetched (returns true if loop follows)
   * @param {object} parent - Contains ids of its children
   * @param {boolean} parentKey - key of the paret, based on which the array of children ids is compared with parent's parent id
   * @return {boolean} - Returns true if loop follows
   */
  const checkForLoop = (parent, parentKey) =>
    getArrayOfIds(parent).includes(parent[parentKey]);

  /**
   * @memberof sfe-tree
   * @description Constructs an array of children's ids
   * @param {object} parent - Contains ids of its children
   * @return {array} - Returns array of all children's ids
   */
  const getArrayOfIds = ({ children = [] }, ids = []) =>
    children.reduce((acc, child) => {
      acc.push(child._id);
      if (child.children) {
        getArrayOfIds(child, ids);
      }
      return acc;
    }, ids);

  /**
   * @memberof sfe-tree
   * @description Generates calls based on parent's childrenconfiguration steps in order to retrieve children and/or connected entities of the selected parent
   * @param {object} parent - Contains info to its children configuration
   * @return {array} - Returns array of calls
   */
  function generateChildrenFetchingCalls(parent, childrenConfig, nextPage) {
    return childrenConfig.reduce((promises, step) => {
      switch (step.type) {
      case 'simple':
        if (usedIn(step.usedIn)) {
          promises.push(getChildren(parent, step, nextPage));
        }
        break;
      case 'connected':
        if (usedIn(step.usedIn) && showConnectedEntities()) {
          promises.push(getConnectedEntityChildren(parent, step));
        }
        break;
      }
      return promises;
    }, []);
  }

  /**
   * @memberof sfe-tree
   * @description Determines whether connected entities should be displayed (used at locations)
   * @return {boolean} - If connected entities should be displayed, returns true, else false
   */
  const showConnectedEntities = loadMore =>
    vm.showConnectedEntities
      ? Boolean(vm.showConnectedToggle) || loadMore
      : true;

  /**
   * @memberof sfe-tree
   * @description Enriches the element with values that are needed for furhter fetching its proper display in the tree and sideview
   * @param {object} element - Element to be enriched
   * @param {object} configuration - Contains data for enrichment of the element
   * @param {object} child - Adds the child to the parent
   * @return {object} - Returns the enriched object
   */
  function configureElement(element, configuration, child) {
    const childrenConfig = EntitiesService.getHierarchyTree(configuration.route)
      .childrenConfiguration;
    if (child) {
      element.children = [child];
      element.couldHaveOtherChildren = true;
      element.opened = true;
    }
    element.icon = configuration.icon;
    element.route = configuration.route;
    if (vm.treeType !== 'childrenTree') {
      element.hasChild = determineChildExistenceParams(element, childrenConfig);
    }
    return element;
  }

  /**
   * @memberof sfe-tree
   * @description On listing items, check their child config for params that determine which children should be listed (existenceParam - hasAsset, hasChild...) depending on the state (usedIn)
   * @param {object} item - Item for which child existence should be determined (hasChild)
   * @return {boolean} - Returns whether item has children/connected entities
   */
  const determineChildExistenceParams = (item, childrenConfig) =>
    childrenConfig.reduce(
      (acc, childConfig) =>
        (usedIn(childConfig.usedIn) &&
          (item[childConfig.existenceParam] ||
            childConfig.existenceParam === 'possible')) ||
        acc,
      false
    );

  /**
   * @memberof sfe-tree
   * @description Checks if current children fetching step should be included in the current state
   * @param {array} stepStates - Array of states where specific children/connected entities should be fetched
   * @return {boolean} - Returns whether current step should be included in children fetching calls
   */
  const usedIn = stepStates =>
    stepStates.reduce(
      (acc, state) => state == vm.state || state == 'everywhere' || acc,
      false
    );

  /**
   * @memberof sfe-tree
   * @description Adds newly fetched children to the children array and filters out duplicates
   * @param {array} oldChildren - Current children that the specific parent has
   * @param {array} newChildren - Newly fetched children
   * @param {object} childConfiguration - Needed to configure newly fetched children
   * @return {array} - Returns the array of unique children
   */
  function configureChildren(
    oldChildren,
    newChildren = [],
    childConfiguration
  ) {
    if (newChildren.length) {
      newChildren = newChildren.map(child =>
        configureElement(child, childConfiguration)
      );
    }
    const oldAndNew = oldChildren.concat(newChildren);
    const distinctIds = Array.from(new Set(oldAndNew.map(child => child._id)));
    // add children and check for duplicates
    return distinctIds.map(id => oldAndNew.find(child => child._id == id));
  }

  /**
   * @memberof sfe-tree
   * @description Finds the children tree configuration
   * @return {object} - Returns the children tree configuration
   */
  const getChildTreeConfiguration = () =>
    vm.entityTreeConfiguration.childrenTreeConfiguration.find(
      configObj => configObj.route === vm.childEntity
    );

  /**
   * @memberof sfe-tree
   * @description Configures whole children tree that is fetched if treeType is 'childrenTree'
   * @param {array} children - Children of the selected element
   * @param {object} childConfiguration - Needed to configure children
   * @param {boolean} isRoot - Needed to determine where to allow the possible edit function
   * @return {array} - Returns the array of enriched children
   */
  function configureChildrenTree(children = [], childConfig, isRoot) {
    return children.map(child => {
      child = configureElement(child, childConfig);
      if (child.children) {
        child.children = configureChildrenTree(child.children, childConfig);
      }
      child.editFunction = isRoot && childConfig.editFunction;
      return child;
    });
  }

  // element manipulations

  /**
   * @memberof sfe-tree
   * @description Colapses the currently opened element
   * @param {object} element - Element to be collapsed
   */
  function collapseElement(element) {
    element.opened = false;
  }

  /**
   * @memberof sfe-tree
   * @description Triggers the loading of more elements
   */
  function loadMore() {
    const parentConfig = EntitiesService.getHierarchyTree(
      vm.entityTreeConfiguration.parentEntity
    );

    switch (vm.treeType) {
    case 'root': // lists parentless items
      getRootElements(parentConfig);
      break;

    case 'childrenTree': // lists all children of the item (and children's children, etc...)
      var childTreeConfiguration = getChildTreeConfiguration();
      if (childTreeConfiguration.type === 'simple') {
        getChildrenTree(
          childTreeConfiguration,
          vm.entity,
          vm.firstElement._id
        );
      }
      break;
    }
  }

  /**
   * @memberof sfe-tree
   * @description Triggers display of selected item in the side view
   * @param {object} item - Item of which the properties are to be displayed
   */
  function showDetails(item) {
    if (
      (vm.selectedElement && item && vm.selectedElement._id !== item._id) ||
      !vm.selectedElement
    ) {
      $element
        .find('.tree-item.selected-text-parent-tree-view')
        .removeClass('selected-text-parent-tree-view');
      $element
        .find('.first-element.selected-text-parent-tree-view')
        .removeClass('selected-text-parent-tree-view');
      $element
        .find('#' + item._id + '' + item.depth)
        .addClass('selected-text-parent-tree-view');

      vm.selectedElement = item;

      if (vm.selectAction) {
        $timeout(function() {
          vm.selectAction(item);
        });
      }
    }
  }

  function updateView() {
    $scope.$evalAsync();
  }
}
