const CALCULATED_FLOW_READ = 'CALCULATED_FLOW_READ';
const CALCULATED_FLOW_UPDATE = 'CALCULATED_FLOW_UPDATE';
const CALCULATED_FLOW_CREATE = 'CALCULATED_FLOW_CREATE';
const CALCULATED_FLOW_ADD = 'CALCULATED_FLOW_ADD';

export default {
  CALCULATED_FLOW_READ,
  CALCULATED_FLOW_UPDATE,
  CALCULATED_FLOW_CREATE,
  CALCULATED_FLOW_ADD
};
