PriceListConfiguration.$inject = ['gettext', '$q'];

function PriceListConfiguration(gettext, $q) {
  var configuration = {
    listId: 'priceListListId',
    entity: {
      singular: gettext('Price List'),
      plural: gettext('Price Lists')
    },
    api: {
      query: {
        entity: 'price-list-masters',
        method: 'custom.readListView'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'invoices-price-lists-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'priceListId'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'invoices-price-lists-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'invoices-price-lists-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'priceListId'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'price-list-masters',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Energy source type'),
        param: 'energySourceType',
        type: 'text'
      },
      {
        title: gettext('Issuer'),
        param: 'supplierName',
        link: {
          state: 'company-resources-business-partners-view',
          params: [
            {
              valueParam: 'businessPartnerSupplierId',
              queryParam: 'id'
            }
          ]
        },
        type: 'link'
      },
      {
        title: gettext('Customer'),
        param: 'customerName',
        link: {
          state: 'company-resources-business-partners-view',
          params: [
            {
              valueParam: 'businessPartnerCustomerId',
              queryParam: 'id'
            }
          ]
        },
        type: 'link'
      }
    ],
    badges: [
      {
        title: gettext('Icon'),
        param: 'icon',
        type: 'icon'
      }
    ],
    filter: [
      {
        param: 'filter',
        display: gettext('Name'),
        type: 'string'
      },
      {
        query: {
          entity: 'energy-source-types',
          method: 'read'
        },
        entity: 'energy-source-types',
        display: gettext('Energy Source Type'),
        param: 'energySourceType',
        displayList: ['name'],
        refreshObject: {
          order: 'code'
        },
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        query: {
          entity: 'business-partners',
          method: 'read'
        },
        entity: 'business-partners',
        display: gettext('Issuer'),
        param: 'invoiceIssuer',
        displayList: ['code', 'name'],
        refreshObject: {
          isDistributer: true,
          order: 'name'
        },
        type: 'searchSelect',
        refreshParameterName: 'name',
        selectedParamName: '_id'
      },
      {
        query: {
          entity: 'business-partners',
          method: 'read'
        },
        entity: 'business-partners',
        display: gettext('Customer'),
        param: 'businessPartnerCustomer',
        displayList: ['code', 'name'],
        refreshObject: {
          order: 'name'
        },
        type: 'searchSelect',
        refreshParameterName: 'name',
        selectedParamName: '_id'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  /*
   *
   *   ENRICH
   *
   */

  function Enrich(item) {
    var deferred = $q.defer();
    if (item.invoiceIssuer) {
      item.supplierName = item.invoiceIssuer.name;
      item.businessPartnerSupplierId = item.invoiceIssuer._id;
    }
    if (
      item.hasOwnProperty('energySourceType') &&
      item.energySourceType !== null
    ) {
      item.energySourceTypeName = item.energySourceType.name;
      item.icon = item.energySourceType.icon;
    }
    if (item.businessPartnerCustomer) {
      item.customerName = item.businessPartnerCustomer.name;
      item.businessPartnerCustomerId = item.businessPartnerCustomer._id;
    }
    if (item.energySourceType) {
      item.energySourceType = item.energySourceType.name;
    }
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default PriceListConfiguration;
