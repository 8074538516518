/**
 * @ngdoc service
 * @name common.ToastService
 * @description Used for showing a toast message.
 * @property {anonymousFunction} showToast - Displays a toast with a message. Can redirect afterwards.
 */
ToastService.$inject = ['$mdToast', '$state', 'gettextCatalog'];

export default function ToastService($mdToast, $state, gettextCatalog) {
  /**
   * @memberof common.ToastService
   * @function
   * @description Function that triggers the display of the toast.
   * @param {string} message Mesage to be displayed in the toast.
   * @param {Object} redirectObj Used for state redirection. Can have defined 'params' for multiple params or 'paramName' and 'paramValue' for single param redirection.
   * @param {Object} transitionOptions Used in case there are any specific transition options we want to ui-router https://ui-router.github.io/ng1/docs/latest/interfaces/transition.transitionoptions.html
   * @return {dataType}
   */
  function showToast(message, redirectObj, transitionOptions = {}) {
    $mdToast.show(
      $mdToast
        .simple()
        .textContent(gettextCatalog.getString(message))
        .position('bottom right')
        .hideDelay(3000)
    );
    if (typeof redirectObj !== 'undefined') {
      // if multiple params
      if (redirectObj.params) {
        $state.go(redirectObj.state, redirectObj.params, transitionOptions);
      } else {
        // if single param
        var obj = {};
        obj[redirectObj.paramName] = redirectObj.paramValue;
        $state.go(redirectObj.state, obj, transitionOptions);
      }
    }
  }

  return {
    showToast: showToast
  };
}
