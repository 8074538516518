import template from './tango-time-series-statistics.component.html';
import './tango-time-series-statistics.scss';
export default {
  template,
  bindings: {
    entityId: '<'
  },
  controller: TangoTimeSeriesStatistics,
  controllerAs: 'vm'
};
/**
 * @ngdoc component
 * @name data.tango-time-series-statistics
 * @description component for displaying tango time-series statistics
 * @param {string} entityId
 * @example
 * <tango-time-series-statistics
 *   entity-id="vm.entityId"
 * ></tango-time-series-statistics>
 */

TangoTimeSeriesStatistics.$inject = [
  '$scope',
  '$ngRedux',
  'TangoTimeSeriesDataXHelperService',
  'TranslationService',
  'ColorService',
  'SfeDataXTranslations',
  'AlertingService',
  'FilterDialog',
  'TimeSeriesProcessingValuesModel',
  'gettextCatalog'
];

function TangoTimeSeriesStatistics(
  $scope,
  $ngRedux,
  TangoTimeSeriesDataXHelperService,
  TranslationService,
  ColorService,
  SfeDataXTranslations,
  AlertingService,
  FilterDialog,
  TimeSeriesProcessingValuesModel,
  gettextCatalog
) {
  const vm = this;
  let unsubscribe;

  vm.apiDataX = {};

  //METRIC PROPERTIES
  vm.metricProperties = [
    [
      {
        title: gettextCatalog.getString('Total count'),
        type: 'basic',
        field: 'all',
        values: []
      },
      {
        title: gettextCatalog.getString('Count with events'),
        type: 'basic',
        field: 'withEvents',
        values: []
      }
    ],
    [
      {
        title: gettextCatalog.getString('Real time count'),
        type: 'basic',
        field: 'live',
        values: []
      },
      {
        title: gettextCatalog.getString('History time count'),
        type: 'basic',
        field: 'historic',
        values: []
      }
    ],
    [
      {
        title: gettextCatalog.getString('Read count'),
        type: 'basic',
        field: 'read',
        values: []
      },
      {
        title: gettextCatalog.getString('Calculated count'),
        type: 'basic',
        field: 'calculated',
        values: []
      }
    ],
    [
      {
        title: gettextCatalog.getString('Extrapolated count'),
        type: 'basic',
        field: 'calculatedExtrapolation',
        values: []
      },
      {
        title: gettextCatalog.getString('Interpolated count'),
        type: 'basic',
        field: 'calculatedInterpolation',
        values: []
      }
    ]
  ];
  // Metrics
  vm.metricHeader = {
    title: gettextCatalog.getString('Metrics'),
    titleColor: 'grey',
    backgroundColor: 'white'
  };

  //GAUGE
  vm.gaugeStructure = [
    {
      type: 'integrity',
      header: {
        title: gettextCatalog.getString('Integrity'),
        titleColor: 'grey',
        backgroundColor: 'white'
      }
    },
    {
      type: 'consistency',
      header: {
        title: gettextCatalog.getString('Consistency'),
        titleColor: 'grey',
        backgroundColor: 'white'
      }
    },
    {
      type: 'effort',
      header: {
        title: gettextCatalog.getString('Effort'),
        titleColor: 'grey',
        backgroundColor: 'white'
      }
    }
  ];
  const gaugeConfigurations = {
    integrity: [
      {
        field: 'integrity',
        subField: 'min',
        suffix: gettextCatalog.getString('min'),
        api: {}
      },
      {
        field: 'integrity',
        subField: 'max',
        suffix: gettextCatalog.getString('max'),
        api: {}
      },
      {
        field: 'integrity',
        subField: 'avg',
        suffix: gettextCatalog.getString('avg'),
        api: {}
      }
    ],
    consistency: [
      {
        field: 'consistency',
        subField: 'min',
        suffix: gettextCatalog.getString('min'),
        api: {}
      },
      {
        field: 'consistency',
        subField: 'max',
        suffix: gettextCatalog.getString('max'),
        api: {}
      },
      {
        field: 'consistency',
        subField: 'avg',
        suffix: gettextCatalog.getString('avg'),
        api: {}
      }
    ],
    effort: [
      {
        field: 'effort',
        subField: 'min',
        suffix: gettextCatalog.getString('min'),
        api: {}
      },
      {
        field: 'effort',
        subField: 'max',
        suffix: gettextCatalog.getString('max'),
        api: {}
      },
      {
        field: 'effort',
        subField: 'avg',
        suffix: gettextCatalog.getString('avg'),
        api: {}
      }
    ]
  };

  vm.$onChanges = changes => {
    if (changes.entityId && vm.entityId != null && unsubscribe == null) {
      unsubscribe = $ngRedux.connect(mapStateToProps)(vm);
      $scope.$on('$destroy', unsubscribe);
    }
  };
  /**
   * @description sets integrity/consistency/effort chart configuration and triggers creation of gauge configuration.
   * @function
   * @param {object} timeSeries
   */
  async function createChartConfiguration(timeSeries) {
    const {
      precision,
      dataType,
      timeZone,
      dataVisualizationConfig,
      dataScheduler
    } = timeSeries;

    const chartType = 'spline';

    ///GAUGES
    setGaugeConfigurations({ dataType, precision, dataScheduler });

    let timeZoneCode;
    let timeZoneObject = TranslationService.GetCollectionById(
      'codelists.timeZones',
      timeZone
    );
    if (timeZoneObject != null) {
      timeZoneCode = timeZoneObject.code;
    } else {
      timeZoneCode = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    const series = constructSeries({
      precision,
      chartType,
      dataType,
      dataVisualizationConfig,
      dataScheduler,
      timeZone: timeZoneCode
    });

    const mode = {
      chart: true,
      grid: true
    };

    const sizes = {
      grid: 300,
      chart: 300
    };

    const axis = TangoTimeSeriesDataXHelperService.processAxes({ series }, {});

    vm.chartConfig = {
      axis,
      series,
      mode: mode,
      alternativeMode: null,
      timeZone: timeZoneCode,
      chart: {
        legend: series.length > 1,
        legendAlign: 'center',
        markers: true,
        dataLabels: false,
        axisYTitle: true,
        axisXTitle: true,
        height: sizes.chart
      },
      grid: {
        height: sizes.grid,
        extraColumns: [],
        actions: []
      },
      translations: SfeDataXTranslations.get(),
      callbacks: {
        /**
         * @description opens filter dialog and then triggers reload of all gauges and our chart at once.
         * @function
         */
        filter: async () => {
          let filter = await FilterDialog.open(vm.apiDataX, null, {}, true);
          vm.apiDataX.updateFilter(() => ({ ...filter }), true);
          vm.gaugeStructure.forEach(({ type }) => {
            vm.gaugeConfigurations[type].forEach(config => {
              config.api.updateFilter(() => ({ ...filter }), true);
            });
          });
        }
      }
    };
  }
  /**
   * @description returns integrity, consistency and effort series configurations.
   * @function
   * @param {Object}
   *     @param {number} precision
   *     @param {string} chartType highcharts chart code
   *     @param {dataType} dataType codelist id of timeseries data type
   * @return {Array}
   */
  function constructSeries({ precision, chartType, dataType, dataScheduler }) {
    return [
      {
        id: `${vm.entityId}_integrity`,
        name: gettextCatalog.getString('Integrity'),
        query: getValues(vm.entityId, 'integrity'),
        nonNumerical: dataType != 2 && dataType != 3,
        dataType,
        dataScheduler,
        decimalPrecision: typeof precision != undefined ? precision : 2,
        type: chartType,
        color: ColorService.getApplicationColor('primary'),
        boundaries: [],
        suffix: ''
      },
      {
        id: `${vm.entityId}_consistency`,
        name: gettextCatalog.getString('Consistency'),
        query: getValues(vm.entityId, 'consistency'),
        nonNumerical: dataType != 2 && dataType != 3,
        dataType,
        dataScheduler,
        decimalPrecision: typeof precision != undefined ? precision : 2,
        type: chartType,
        color: ColorService.getApplicationColor('accent'),
        boundaries: [],
        suffix: ''
      },
      {
        id: `${vm.entityId}_effort`,
        name: gettextCatalog.getString('Effort'),
        query: getValues(vm.entityId, 'effort'),
        nonNumerical: dataType != 2 && dataType != 3,
        dataType,
        dataScheduler,
        decimalPrecision: typeof precision != undefined ? precision : 2,
        type: chartType,
        color: ColorService.getApplicationColor('warn'),
        boundaries: [],
        suffix: ''
      }
    ];
  }
  /**
   * @description function is triggered when chart values are received
   * sets metric values.
   * @function
   * @param {Object} statistics
   */
  function setMetricProperties(statistics) {
    if (statistics != null && statistics.count != null) {
      vm.metricProperties = vm.metricProperties.map(properties => {
        return properties.map(property => {
          return {
            ...property,
            values: [{ text: statistics.count[property.field] }]
          };
        });
      });
    }
  }

  const boundaries = [
    {
      from: 0,
      to: 20,
      color: '#cf111a'
    },
    {
      from: 20,
      to: 80,
      color: '#faae4b'
    },
    {
      from: 80,
      to: 95,
      color: '#b8eda8'
    },
    {
      from: 95,
      to: 100,
      color: '#238705'
    }
  ];
  const boundariesEffort = [
    {
      from: 0,
      to: 2,
      color: '#238705'
    },
    {
      from: 2,
      to: 5,
      color: '#faae4b'
    },
    {
      from: 5,
      to: 30,
      color: '#cf111a'
    }
  ];
  /**
   * @description create 9 dataX gauge configurations.
   * @function
   * @param {Object}
   *     @param {number} precision
   *     @param {dataType} dataType codelist id of timeseries data type  * @return {dataType}
   */
  function setGaugeConfigurations({ dataType, precision }) {
    const types = ['integrity', 'consistency', 'effort'];
    const min = {
      integrity: 0,
      consistency: 0,
      effort: -5
    };
    const max = {
      integrity: 100,
      consistency: 100,
      effort: 30
    };
    vm.gaugeConfigurations = types.reduce((result, type) => {
      let configs = gaugeConfigurations[type].map(config => {
        const series = [
          {
            id: `${vm.entityId}_gauge`,
            name: config.title,
            query: getValues(vm.entityId, 'gauge', config),
            nonNumerical: dataType != 2 && dataType != 3,
            dataType,
            decimalPrecision: typeof precision != undefined ? precision : 2,
            type: 'line',
            color: ColorService.getApplicationColor('primary'),
            suffix: '',
            physicalQuantity: { name: config.suffix },
            min: min[type],
            max: max[type],
            boundaries: type === 'effort' ? boundariesEffort : boundaries
          }
        ];

        const axis = TangoTimeSeriesDataXHelperService.processAxes(
          { series },
          {} //sort settings
        );
        return {
          ...config,
          dataXConfig: {
            mode: {
              gauge: true
            },
            alternativeMode: null,
            gauge: {
              height: 200
            },
            series,
            axis,
            translations: SfeDataXTranslations.get()
          }
        };
      });
      return {
        ...result,
        [type]: configs
      };
    }, {});
  }
  /**
   * @description General method to get statistic dataX values.
   * @function
   * @param {string} entityId is used to create values query
   * @param {enum} type integrity|consistency|effort or gauge used to determine which field to use as y value
   * @param {object} config is set only when type is gauge
   * @return {function}
   */
  function getValues(entityId, type, config) {
    /**
     * @description returns sorted values.
     * @function
     * @param {Object} filter filter object
     * @param {Boolean} manualRefresh indicates when chart refresh is triggered manually
     * @return {Promise} Array
     */
    return async (filter, manualRefresh) => {
      let apiObject = {};
      const invalidationTime = manualRefresh ? 0 : 1000000000000;

      apiObject.from =
        filter.from == null || filter.from === '' ? null : filter.from;

      apiObject.to = filter.to == null || filter.to === '' ? null : filter.to;
      apiObject.limit = filter.limit || 30000;
      try {
        let {
          data: values,
          metadata
        } = await TimeSeriesProcessingValuesModel.read(
          {
            timeSeriesId: entityId,
            ...apiObject,
            statistics: true
          },
          invalidationTime
        );

        if (type === 'gauge') {
          if (
            metadata != null &&
            metadata.statistics != null &&
            metadata.statistics[config.field] != null
          ) {
            // function triggered only once
            if (config.field === 'consistency') {
              setMetricProperties(metadata.statistics);
            }

            return [
              {
                y: metadata.statistics[config.field][config.subField]
              }
            ];
          }
          return [];
        }

        return values
          .map(function(value) {
            return { x: new Date(value.validAt), y: value[type] };
          })
          .sort(function(valueA, valueB) {
            return valueA.x - valueB.x;
          });
      } catch (err) {
        AlertingService.Error(err);
        return [];
      }
    };
  }

  function mapStateToProps(state) {
    let timeSeries;

    if (
      state != null &&
      state.timeSeries != null &&
      state.timeSeries[vm.entityId] != null &&
      state.timeSeries[vm.entityId].data != null
    ) {
      timeSeries = state.timeSeries[vm.entityId].data;
      createChartConfiguration(timeSeries);
    }
    return {
      timeSeries
    };
  }
}
