import { RouteManager, gettext } from '../routes';

import CounterTypeView from './pages/counter-type/counter-type.view.html';
import DomainConfigurationView from './pages/domain-configuration/domain-configuration.view.html';
import EnergySourceConfigurationView from './pages/energy-source-configuration/energy-sources-configuration.view.html';
import EntityTypeConfigurationView from './pages/entity-type-configuration/entity-type-configuration.view.html';
import NewEntityTypeConfigurationView from './pages/entity-type-configuration/new-entity-type-configuration.view.html';
import MultipleListsView from './pages/multiple-lists/multiple-lists.view.html';
import ResponseConfigurationView from './pages/response-configuration/response-configuration.view.html';
import SfeListView from '../common/components/sfe-list/sfe-list.view.html';
import NewFormBasicView from '../common/views/new-form-basic.view.html';
import ItemHeaderView from '../common/views/item-header.view.html';

let ConfigRouteManager = new RouteManager();

loadEntity.$inject = ['$transition$', 'StateResolveService'];
function loadEntity($transition$, StateResolveService) {
  return StateResolveService.fetchEntityForView($transition$);
}

// alarm configuration
ConfigRouteManager.state(
  'configurations-alarm-configuration-alarm-types-list',
  {
    url: '/configurations/alarm-configuration/alarm-types/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Alarm Types'),
    icon: 'settings',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  }
)
  .state('configurations-alarm-configuration-severities-list', {
    url: '/configurations/alarm-configuration/severities/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Severities'),
    icon: 'settings',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  })
  .state('configurations-alarm-configuration-responses-list', {
    url: '/configurations/alarm-configuration/responses/list',
    template: ResponseConfigurationView,
    controller: 'ResponseConfigurationController',
    controllerAs: 'vm',
    pageTitle: gettext('Responses'),
    icon: 'settings',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  })
  // company resources
  .state('configurations-company-resources-assets-list', {
    url: '/configurations/company-resources/assets/list',
    template: MultipleListsView,
    controller: 'AssetsConfigurationController',
    controllerAs: 'vm',
    pageTitle: gettext('Assets configuration'),
    icon: 'settings',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'list',
    entity: 'asset-types'
  })
  .state('configurations-company-resources-assets-asset-types-edit', {
    url: '/configurations/company-resources/assets/asset-types/{id}/edit',
    template: NewEntityTypeConfigurationView,
    controller: 'NewEntityTypeConfigController',
    controllerAs: 'vm',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'update',
    entity: 'asset-types',
    resolve: {
      entityTypeObject: loadEntity
    }
  })
  .state('configurations-company-resources-assets-asset-types-new', {
    url: '/configurations/company-resources/assets/asset-types/new',
    template: NewEntityTypeConfigurationView,
    controller: 'NewEntityTypeConfigController',
    controllerAs: 'vm',
    pageTitle: gettext('New Asset Type'),
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'create',
    entity: 'asset-types',
    resolve: {
      entityTypeObject: () => ({})
    }
  })
  .state('configurations-company-resources-assets-asset-types-view', {
    url: '/configurations/company-resources/assets/asset-types/{id}',
    template: EntityTypeConfigurationView,
    controller: 'AssetTypeConfigController',
    pageTitle: gettext('Asset Type'),
    controllerAs: 'vm',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'read',
    entity: 'asset-types',
    resolve: {
      assetType: loadEntity
    }
  })
  .state('configurations-company-resources-locations-list', {
    url: '/configurations/company-resources/locations/list',
    template: MultipleListsView,
    controller: 'LocationConfigurationController',
    controllerAs: 'vm',
    pageTitle: gettext('Locations'),
    icon: 'settings',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    entity: 'location-types'
  })
  .state('configurations-company-resources-locations-location-types-edit', {
    url: '/configurations/company-resources/locations/location-types/{id}/edit',
    template: NewEntityTypeConfigurationView,
    controller: 'NewEntityTypeConfigController',
    controllerAs: 'vm',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'update',
    entity: 'location-types',
    resolve: {
      entityTypeObject: loadEntity
    }
  })
  .state('configurations-company-resources-locations-location-types-new', {
    url: '/configurations/company-resources/locations/location-types/new',
    template: NewEntityTypeConfigurationView,
    controller: 'NewEntityTypeConfigController',
    controllerAs: 'vm',
    pageTitle: gettext('New Location Type'),
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'create',
    entity: 'location-types',
    resolve: {
      entityTypeObject: loadEntity
    }
  })
  .state('configurations-company-resources-locations-location-types-view', {
    url: '/configurations/company-resources/locations/location-types/{id}',
    template: EntityTypeConfigurationView,
    controller: 'LocationTypeConfigController',
    controllerAs: 'vm',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'read',
    entity: 'location-types',
    resolve: {
      locationType: loadEntity
    }
  })
  .state('configurations-company-resources-time-series-list', {
    url: '/configurations/company-resources/time-series/list',
    template: MultipleListsView,
    controller: 'TimeSeriesConfigurationController',
    controllerAs: 'vm',
    pageTitle: gettext('Time Series'),
    icon: 'settings',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'list',
    entity: 'time-series-types'
  })
  .state(
    'configurations-company-resources-time-series-time-series-types-edit',
    {
      url:
        '/configurations/company-resources/time-series/time-series-types/{id}/edit',
      template: NewEntityTypeConfigurationView,
      controller: 'NewEntityTypeConfigController',
      controllerAs: 'vm',
      parentState: 'config',
      theme: 'tango',
      accessScopeBe: 'tis_fe:settings',
      accessScopeFe: 'tis_fe:settings',
      mode: 'update',
      entity: 'time-series-types',
      resolve: {
        entityTypeObject: loadEntity
      }
    }
  )
  .state('configurations-company-resources-time-series-time-series-types-new', {
    url: '/configurations/company-resources/time-series/time-series-types/new',
    template: NewEntityTypeConfigurationView,
    controller: 'NewEntityTypeConfigController',
    controllerAs: 'vm',
    pageTitle: gettext('New Time Series Type'),
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'create',
    entity: 'time-series-types',
    resolve: {
      entityTypeObject: loadEntity
    }
  })
  .state(
    'configurations-company-resources-time-series-time-series-types-view',
    {
      url:
        '/configurations/company-resources/time-series/time-series-types/{id}',
      template: EntityTypeConfigurationView,
      controller: 'TimeSeriesTypeConfigController',
      pageTitle: gettext('Time Series Type'),
      controllerAs: 'vm',
      parentState: 'config',
      theme: 'tango',
      accessScopeBe: 'tis_fe:settings',
      accessScopeFe: 'tis_fe:settings',
      mode: 'read',
      entity: 'time-series-types',
      resolve: {
        timeSeriesType: loadEntity
      }
    }
  )
  .state('configurations-company-resources-measuring-point-types-list', {
    url: '/configurations/company-resources/measuring-point-types/list',
    template: MultipleListsView,
    controller: 'MeasuringPointsConfigurationController',
    controllerAs: 'vm',
    pageTitle: gettext('Measuring Points'),
    icon: 'settings',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'list',
    entity: 'measuringpoint-types',
    resolve: {
      entityTypeObject: loadEntity
    }
  })
  .state('configurations-company-resources-measuring-point-types-edit', {
    url: '/configurations/company-resources/measuring-point-types/{id}/edit',
    template: NewEntityTypeConfigurationView,
    controller: 'NewEntityTypeConfigController',
    controllerAs: 'vm',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'update',
    entity: 'measuringpoint-types',
    resolve: {
      entityTypeObject: loadEntity
    }
  })
  .state('configurations-company-resources-measuring-point-types-new', {
    url: '/configurations/company-resources/measuring-point-types/new',
    template: NewEntityTypeConfigurationView,
    controller: 'NewEntityTypeConfigController',
    controllerAs: 'vm',
    pageTitle: gettext('New Measuring Point Type'),
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'create',
    entity: 'measuringpoint-types',
    resolve: {
      entityTypeObject: () => ({})
    }
  })
  .state('configurations-company-resources-measuring-point-types-view', {
    url: '/configurations/company-resources/measuring-point-types/{id}',
    template: EntityTypeConfigurationView,
    controller: 'MeasuringPointTypeConfigController',
    pageTitle: gettext('Measuring Point Type'),
    controllerAs: 'vm',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'read',
    entity: 'measuringpoint-types',
    resolve: {
      measuringPointType: loadEntity
    }
  })
  .state('configurations-company-resources-project-list', {
    url: '/configurations/company-resources/project/list',
    template: MultipleListsView,
    controller: 'ProjectConfigurationController',
    controllerAs: 'vm',
    pageTitle: gettext('Project'),
    icon: 'settings',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  })
  .state('configurations-company-resources-ownerships-list', {
    url: '/configurations/company-resources/ownerships/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Ownerships'),
    icon: 'settings',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  })
  .state('configurations-company-resources-company-structure-nodes-list', {
    url: '/configurations/company-resources/company-structure-nodes/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Company Structure Nodes'),
    icon: 'settings',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'list',
    entity: 'company-structure-nodes'
  })
  .state('configurations-company-resources-company-structure-nodes-new', {
    url: '/configurations/company-resources/company-structure-nodes/new',
    template: NewFormBasicView,
    controller: 'NewCompanyStructureNodeController',
    controllerAs: 'vm',
    pageTitle: gettext('New Company Structure Node'),
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'create',
    entity: 'company-structure-nodes',
    resolve: {
      companyStructureNode: () => ({})
    }
  })
  .state('configurations-company-resources-company-structure-nodes-edit', {
    url: '/configurations/company-resources/company-structure-nodes/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewCompanyStructureNodeController',
    controllerAs: 'vm',
    pageTitle: gettext('Edit Company Structure Node'),
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'update',
    entity: 'company-structure-nodes',
    resolve: {
      companyStructureNode: loadEntity
    }
  })
  .state('configurations-company-resources-company-structure-nodes-view', {
    url: '/configurations/company-resources/company-structure-nodes/{id}',
    template: ItemHeaderView,
    controller: 'CompanyStructureNodeController',
    controllerAs: 'vm',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'read',
    entity: 'company-structure-nodes',
    resolve: {
      companyStructureNode: loadEntity
    }
  })
  // iot
  .state('configurations-iot-units-of-measurement-list', {
    url: '/configurations/iot/units-of-measurement/list',
    template: MultipleListsView,
    controller: 'MeasurementUnitsConfigurationController',
    controllerAs: 'vm',
    pageTitle: gettext('Measurement Units'),
    icon: 'settings',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  })
  // general
  .state('configurations-general-national-holidays-list', {
    url: '/configurations/general/national-holidays/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('National holidays'),
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  })
  .state('configurations-general-document-classifications-list', {
    url: '/configurations/general/document-classifications/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Document Classifications'),
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  })
  // dynamic attributes
  .state('configurations-dynamic-attributes-dynamic-attributes-list', {
    url: '/configurations/dynamic-attributes/dynamic-attributes/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Dynamic Attributes'),
    icon: 'perm_data_settings',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'list',
    entity: 'domain-attributes'
  })
  .state('configurations-dynamic-attributes-dynamic-attributes-list-new', {
    url: '/configurations/dynamic-attributes/dynamic-attributes/new',
    template: NewFormBasicView,
    controller: 'NewDomainAttributeConfigController',
    controllerAs: 'vm',
    pageTitle: gettext('New Domain Attribute'),
    icon: 'perm_data_settings',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'create',
    entity: 'domain-attributes',
    resolve: {
      domainAttribute: () => ({})
    }
  })
  .state('configurations-dynamic-attributes-dynamic-attributes-list-edit', {
    url: '/configurations/dynamic-attributes/dynamic-attributes/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewDomainAttributeConfigController',
    controllerAs: 'vm',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'update',
    entity: 'domain-attributes',
    resolve: {
      domainAttribute: loadEntity
    }
  })
  .state('configurations-dynamic-attributes-dynamic-attributes-list-view', {
    url: '/configurations/dynamic-attributes/dynamic-attributes/{id}',
    template: ItemHeaderView,
    controller: 'DomainAttributeConfigController',
    pageTitle: gettext('Dynamic Attribute'),
    controllerAs: 'vm',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'read',
    entity: 'domain-attributes',
    resolve: {
      domainAttribute: loadEntity
    }
  })
  .state('configurations-dynamic-attributes-domains-list', {
    url: '/configurations/dynamic-attributes/domains/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Domain Configurations'),
    icon: 'perm_data_settings',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'list',
    entity: 'domains'
  })
  .state('configurations-dynamic-attributes-domains-new', {
    url: '/configurations/dynamic-attributes/domains/new',
    template: NewFormBasicView,
    pageTitle: gettext('New Domain'),
    controller: 'NewDomainConfigurationController',
    controllerAs: 'vm',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'create',
    entity: 'domains',
    resolve: {
      domain: () => ({})
    }
  })
  .state('configurations-dynamic-attributes-domains-edit', {
    url: '/configurations/dynamic-attributes/domains/{id}/edit',
    template: NewFormBasicView,
    pageTitle: gettext('Edit Domain'),
    controller: 'NewDomainConfigurationController',
    controllerAs: 'vm',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'update',
    entity: 'domains',
    resolve: {
      domain: loadEntity
    }
  })
  .state('configurations-dynamic-attributes-domains-view', {
    url: '/configurations/dynamic-attributes/domains/{id}',
    template: DomainConfigurationView,
    pageTitle: gettext('Domain'),
    controller: 'DomainConfigurationController',
    controllerAs: 'vm',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'read',
    entity: 'domains',
    resolve: {
      domain: loadEntity
    }
  })
  .state('configurations-dynamic-attributes-domains-fields-new', {
    url: '/configurations/dynamic-attributes/domains/{domainId}/fields/new',
    template: NewFormBasicView,
    controller: 'NewDomainFieldsConfigurationFormController',
    controllerAs: 'vm',
    pageTitle: gettext('Domain Fields Configuration Form'),
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  })
  .state('configurations-dynamic-attributes-domains-fields-edit', {
    url:
      '/configurations/dynamic-attributes/domains/{domainId}/fields/{fieldId}/edit',
    template: NewFormBasicView,
    controller: 'NewDomainFieldsConfigurationFormController',
    controllerAs: 'vm',
    pageTitle: gettext('Domain Fields Configuration Form'),
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  })
  .state('configurations-dynamic-attributes-domains-fields-view', {
    url:
      '/configurations/dynamic-attributes/domains/{domainId}/fields/{fieldId}',
    template: ItemHeaderView,
    controller: 'DomainFieldsConfigurationController',
    controllerAs: 'vm',
    pageTitle: gettext('Domain Fields Configuration'),
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  })
  .state('configurations-dynamic-attributes-domains-collections-new', {
    url:
      '/configurations/dynamic-attributes/domains/{domainId}/collections/new',
    template: NewFormBasicView,
    controller: 'NewDomainCollectionConfigurationFormController',
    controllerAs: 'vm',
    pageTitle: gettext('Domain Configuration Form'),
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  })
  .state('configurations-dynamic-attributes-domains-collections-edit', {
    url:
      '/configurations/dynamic-attributes/domains/{domainId}/collections/{collectionId}/edit',
    template: NewFormBasicView,
    controller: 'NewDomainCollectionConfigurationFormController',
    controllerAs: 'vm',
    pageTitle: gettext('Domain Configuration Form'),
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  })
  .state('configurations-dynamic-attributes-domains-collections-view', {
    url:
      '/configurations/dynamic-attributes/domains/{domainId}/collections/{collectionId}',
    template: ItemHeaderView,
    controller: 'DomainCollectionConfigurationController',
    controllerAs: 'vm',
    pageTitle: gettext('Domain Configuration Form'),
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  })
  // energy management
  .state('configurations-energy-management-energy-source-types-list', {
    url: '/configurations/energy-management/energy-source-types/list',
    template: EnergySourceConfigurationView,
    controller: 'EnergySourceController',
    controllerAs: 'vm',
    pageTitle: gettext('Energy Sources Types Configuration'),
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    entity: 'energy-source-types'
  })
  .state('configurations-energy-management-energy-source-types-new', {
    url: '/configurations/energy-management/energy-source-types/new',
    template: NewFormBasicView,
    controller: 'NewEnergySourceController',
    controllerAs: 'vm',
    pageTitle: gettext('New Energy Source Type'),
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    entity: 'energy-source-types',
    resolve: {
      energySourceType: () => ({})
    }
  })
  .state('configurations-energy-management-energy-source-types-view', {
    url: '/configurations/energy-management/energy-source-types/{id}',
    template: ItemHeaderView,
    controller: 'EnergySourceViewController',
    pageTitle: gettext('Energy Source Types'),
    controllerAs: 'vm',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'read',
    entity: 'energy-source-types',
    resolve: {
      energySourceType: loadEntity
    }
  })
  .state('configurations-energy-management-energy-source-types-edit', {
    url: '/configurations/energy-management/energy-source-types/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewEnergySourceController',
    controllerAs: 'vm',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'update',
    entity: 'energy-source-types',
    resolve: {
      energySourceType: loadEntity
    }
  })
  .state('configurations-energy-management-counter-types-list', {
    url: '/configurations/energy-management/counter-types/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Counter Type Configuration'),
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    entity: 'counter-types'
  })
  .state('configurations-energy-management-counter-types-new', {
    url: '/configurations/energy-management/counter-types/new',
    template: NewFormBasicView,
    controller: 'NewCounterTypeController',
    controllerAs: 'vm',
    pageTitle: gettext('New Counter Type'),
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    entity: 'counter-types',
    resolve: {
      counterType: () => ({})
    }
  })
  .state('configurations-energy-management-counter-types-edit', {
    url: '/configurations/energy-management/counter-types/:id/edit',
    template: NewFormBasicView,
    controller: 'NewCounterTypeController',
    controllerAs: 'vm',
    pageTitle: gettext('Edit Counter Type'),
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'update',
    entity: 'counter-types',
    resolve: {
      counterType: loadEntity
    }
  })
  .state('configurations-energy-management-counter-types-view', {
    url: '/configurations/energy-management/counter-types/:id',
    template: CounterTypeView,
    controller: 'CounterTypeViewController',
    controllerAs: 'vm',
    pageTitle: gettext('View Counter Type'),
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings',
    mode: 'read',
    entity: 'counter-types',
    resolve: {
      counterType: loadEntity
    }
  })
  .state('configurations-energy-management-price-list-item-groups-list', {
    url: '/configurations/energy-management/price-list-item-groups-list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Price List Item Group Configuration'),
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  })
  .state('configurations-energy-management-energy-management-items-list', {
    url: '/configurations/energy-management/energy-management-items/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Energy Management Group Configuration'),
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  })
  .state('configurations-energy-management-tariffs-list', {
    url: '/configurations/energy-management/tariffs/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Tariff Configurations'),
    icon: 'perm_data_settings',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  })
  .state('configurations-energy-management-billing-types-list', {
    url: '/configurations/energy-management/billing-types/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Billing Type Configurations'),
    icon: 'perm_data_settings',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  })
  .state('configurations-energy-management-billing-kinds-list', {
    url: '/configurations/energy-management/billing-kinds/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Billing Kind Configurations'),
    icon: 'perm_data_settings',
    parentState: 'config',
    theme: 'tango',
    accessScopeBe: 'tis_fe:settings',
    accessScopeFe: 'tis_fe:settings'
  });

export default ConfigRouteManager.routes;
