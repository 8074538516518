import { DateTime } from 'luxon';

MasterInvoiceListHelper.$inject = ['gettext'];
/**
 * @ngdoc service
 * @name invoices.MasterInvocieListHelper
 * @description Generates properties that are shared by both pricelist and pricelist detail headers.
 * @property {function} generateProperties
 */
function MasterInvoiceListHelper(gettext) {
  const validateValidityIntervalFilter = filters => {
    let result = {};
    if (Array.isArray(filters)) {
      const { date: validityIntervalFrom } = filters.find(
        item => item.uniqId === 'validityIntervalFrom'
      );
      const { date: validityIntervalTo } = filters.find(
        item => item.uniqId === 'validityIntervalTo'
      );

      if (
        validityIntervalFrom?.length > 0 &&
        (!validityIntervalTo || validityIntervalTo?.length < 1)
      ) {
        result = { error: gettext('Missing validity to date.') };
      }

      if (
        validityIntervalTo?.length > 0 &&
        (!validityIntervalFrom || validityIntervalFrom?.length < 1)
      ) {
        result = { error: gettext('Missing validity from date.') };
      }

      if (
        validityIntervalFrom?.length > 0 &&
        validityIntervalTo?.length > 0 &&
        DateTime.fromJSDate(new Date(validityIntervalFrom[0])).startOf(
          'months'
        ) > DateTime.fromJSDate(new Date(validityIntervalTo[0])).endOf('months')
      ) {
        result = {
          error: gettext(
            'Validity date from should be prior to validity date to.'
          )
        };
      }
    }
    return result;
  };

  return { validateValidityIntervalFilter };
}

export default MasterInvoiceListHelper;
