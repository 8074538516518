analysisSliceService.$inject = [];
/**
 * @ngdoc service
 * @name analytics.analysisSliceService
 * @description analysis display aggregation columns helper methods.
 * @property {function} listOptions
 * @property {function} mapDatasetName
 */
function analysisSliceService() {
  /**
   * @memberof analysisSliceService.mapDatasetName
   * @description returns dataset displayFieldName according to aggregation group name.
   * @param {string} item dataset rawField value
   * @param {array} dataset array of dataset column objects
   * @return {string}
   */
  function mapDatasetName(item, dataset) {
    var found = dataset.find(datasetItem => datasetItem.rawFieldName == item);
    return found ? found.displayFieldName : item;
  }
  /**
   * @memberof analysisSliceService.listOptions
   * @description returns entity namaes available for slice or group action.
   * @param {array} aggregation array of aggregation strings
   * @param {array} datasetColumns array of dataset column objects
   * @return {Promise}
   */
  function listOptions(aggregation, datasetColumns) {
    var options = [];
    return new Promise(resolve => {
      if (aggregation.length === 2) {
        options = [aggregation[1]];
      } else if (aggregation.length > 2) {
        options = angular.copy(aggregation).splice(aggregation.length - 2, 2);
      }
      if (options.length) {
        resolve(
          options.map(function(item) {
            return {
              _id: item,
              name: mapDatasetName(item, datasetColumns)
            };
          })
        );
      } else {
        resolve();
      }
    });
  }
  return {
    listOptions: listOptions,
    mapDatasetName: mapDatasetName
  };
}
export default analysisSliceService;
