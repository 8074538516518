HttpPendingRequestsService.$inject = ['$q'];
/**
 * @ngdoc service
 * @name core.HttpPendingRequestsService
 * @description contains methods to cancel all pending http requests.
 * @property {function} newTimeout
 * @property {function} cancelAll
 */
function HttpPendingRequestsService($q) {
  let lastUsedUniqId = 0;
  let cancelPromises = {};

  /**
   * @memberof HttpPendingRequestsService.newTimeout
   * @description creates new timeout and adds  it to array of pending requests.
   * @return {Promise}
   */
  function newTimeout() {
    var cancelPromise = $q.defer();
    // reset counter
    if (lastUsedUniqId == Number.MAX_SAFE_INTEGER) {
      lastUsedUniqId = 0;
    }
    cancelPromises[lastUsedUniqId] = cancelPromise;
    return {
      timeout: cancelPromise.promise,
      requestUniqIdentifier: lastUsedUniqId++
    };
  }
  /**
   * @memberof HttpPendingRequestsService.cancelAll
   * @description cancels all pending requests
   * @return {Promise}
   */
  function cancelAll() {
    for (let key in cancelPromises) {
      cancelPromises[key].promise.isGloballyCancelled = true;
      cancelPromises[key].resolve();
    }
    cancelPromises = {};
  }
  /**
   * @memberof HttpPendingRequestsService.removeRequest
   * @description removes request promise from object.
   * @param {number} identifier request identifier
   */
  function removeRequest(identifier) {
    delete cancelPromises[identifier];
  }

  return {
    newTimeout: newTimeout,
    cancelAll: cancelAll,
    removeRequest: removeRequest
  };
}

export default HttpPendingRequestsService;
