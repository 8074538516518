NewPriceListItemController.$inject = [
  '$state',
  'gettext',
  'AlertingService',
  'MetadataService',
  'createOrUpdateService',
  'PriceListItemModel',
  'PriceListDetailModel',
  'priceListItem',
  '$timeout'
];

function NewPriceListItemController(
  $state,
  gettext,
  AlertingService,
  MetadataService,
  createOrUpdateService,
  PriceListItemModel,
  PriceListDetailModel,
  priceListItem,
  $timeout
) {
  var vm = this;
  var priceListDetailId = $state.params.priceListDetailId;
  var priceListItemId = $state.params.priceListItemId;
  var editMode = priceListItemId !== undefined;
  var redirectionData = {
    state: 'invoices-price-lists-detail-view',
    params: {
      priceListDetailId: priceListDetailId
    }
  };
  let masterPriceListEnergySourceType;

  init();

  function init() {
    vm.dataConfig = {
      edit: editMode,
      action: {
        ctrlFn: true,
        title: editMode ? gettext('Update') : gettext('Create'),
        fn: createOrUpdate
      },
      dataObj: {
        name: '',
        description: '',
        order: '',
        price: '',
        externalCode: '',
        physicalQuantity: null,
        measurementUnit: null,
        metricPrefix: null,
        physicalData: {}
      },
      data: [
        {
          placeholder: 'Name',
          name: 'name',
          componentType: 'textField',
          type: 'text',
          required: true
        },
        {
          placeholder: 'Description',
          name: 'description',
          componentType: 'textField',
          type: 'text',
          required: false
        },
        {
          placeholder: gettext('External Code'),
          name: 'externalCode',
          componentType: 'textField',
          maxLength: 30,
          required: false
        },
        {
          label: gettext('Transfer To Next Month'),
          componentType: 'checkBox',
          name: 'transferToNextMonth'
        },
        {
          placeholder: 'Order',
          name: 'order',
          componentType: 'textField',
          type: 'numerical',
          required: true
        },
        {
          placeholder: 'Price',
          name: 'price',
          componentType: 'textField',
          type: 'numerical',
          required: true
        },
        {
          title: gettext('Physical information'),
          componentType: 'title'
        },
        {
          componentType: 'physicalAutocomplete',
          physicalQuantity: 'physicalQuantity',
          metricPrefix: 'metricPrefix',
          measurementUnit: 'measurementUnit',
          result: 'physicalData',
          required: true
        },
        {
          componentType: 'autocompleteDialog',
          edit: false,
          configuration: {
            query: {
              entity: 'energy-management-groups',
              method: 'read'
            },
            floatingLabel: gettext('Energy managment groups'),
            searchParamName: 'filter',
            entity: 'energy-management-groups',
            createRedirect: {
              state:
                'configurations-energy-management-energy-management-items-list'
            },
            filterObjectFn: getEnergyGroupFilter,
            dialogConfiguration: {
              filterObjectFn: getEnergyGroupFilter
            }
          },
          name: 'energyManagementGroup'
        },
        {
          componentType: 'autocompleteDialog',
          edit: false,
          configuration: {
            query: {
              entity: 'price-list-item-groups',
              method: 'read'
            },
            floatingLabel: gettext('Price list item groups'),
            searchParamName: 'filter',
            entity: 'price-list-item-groups',
            createRedirect: {
              state:
                'configurations-energy-management-price-list-item-groups-list'
            }
          },
          name: 'priceListItemGroup'
        }
      ],
      header: {
        backgroundColor: 'primary',
        title: editMode
          ? gettext('Edit price list item')
          : gettext('New price list item'),
        actions: [
          {
            title: gettext('Cancel'),
            color: 'primary',
            whiteButton: true,
            raised: true,
            isLink: true,
            link:
              'invoices-price-lists-detail-view' +
              '(' +
              JSON.stringify($state.params) +
              ')'
          }
        ]
      }
    };

    getMaterPriceListEnergySourceType();

    if (editMode) {
      const dataObj = {
        name: priceListItem.name,
        description: priceListItem.description,
        transferToNextMonth: priceListItem.transferToNextMonth,
        order: priceListItem.order,
        externalCode: priceListItem.externalCode,
        price: priceListItem.price,
        energyManagementGroup: priceListItem.energyManagementGroup,
        priceListItemGroup: priceListItem.priceListItemGroup,
        physicalQuantity: priceListItem.physicalQuantity,
        measurementUnit: priceListItem.measurementUnit,
        metricPrefix: priceListItem.metricPrefix,
        physicalData: {}
      };
      vm.dataConfig.dataObj = dataObj;
      vm.sendingRequest = false;
      $timeout(() => {
        MetadataService.Loading(false);
        MetadataService.ChangeMetadata(
          'Edit ' + priceListItem.name,
          priceListItem.description
        );
      });
    } else {
      listPriceListItems();
    }
  }

  function getMaterPriceListEnergySourceType() {
    PriceListDetailModel.custom
      .readMaster({
        id: priceListDetailId
      })
      .then(
        function(res) {
          var master = res.data.priceListMaster;
          var params = {
            priceListDetailId: priceListDetailId,
            priceListId: master._id
          };

          masterPriceListEnergySourceType = master.energySourceType;

          vm.dataConfig.header.actions = [
            {
              title: gettext('Cancel'),
              color: 'primary',
              whiteButton: true,
              raised: true,
              isLink: true,
              link:
                'invoices-price-lists-detail-view' +
                '(' +
                JSON.stringify(params) +
                ')'
            }
          ];
          redirectionData.params.priceListId = master._id;
        },
        function(err) {
          AlertingService.Error(err);
        }
      );
  }

  function getEnergyGroupFilter() {
    return {
      energySourceType: masterPriceListEnergySourceType
    };
  }

  function listPriceListItems() {
    if (!priceListDetailId) {
      return;
    }
    PriceListItemModel.read({
      priceListDetail: priceListDetailId
    }).then(
      function(res) {
        var maxOrderItem = _.maxBy(res.data, 'order');
        if (maxOrderItem) {
          vm.dataConfig.dataObj.order = maxOrderItem.order + 1;
        } else {
          vm.dataConfig.dataObj.order = 1;
        }
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function dataToSave() {
    var dataObj = vm.dataConfig.dataObj;
    return {
      name: dataObj.name,
      description: dataObj.description,
      order: dataObj.order,
      price: dataObj.price,
      externalCode: dataObj.externalCode,
      priceListDetail: priceListDetailId,
      energyManagementGroup: dataObj.energyManagementGroup
        ? dataObj.energyManagementGroup._id
        : null,
      priceListItemGroup: dataObj.priceListItemGroup
        ? dataObj.priceListItemGroup._id
        : null,
      physicalQuantity: dataObj.physicalData.physicalQuantity,
      metricPrefix: dataObj.physicalData.metricPrefix,
      measurementUnit: dataObj.physicalData.measurementUnit,
      transferToNextMonth: Boolean(dataObj.transferToNextMonth)
    };
  }

  // create and update functions
  function createOrUpdate() {
    vm.sendingRequest = true;
    createOrUpdateService
      .simpleCall(
        {
          entity: 'price-list-items',
          method: editMode ? 'update' : 'create'
        },
        editMode,
        priceListItemId,
        dataToSave(),
        null,
        null,
        redirectionData
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }
}

export default NewPriceListItemController;
