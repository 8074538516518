BaseLineConfiguration.$inject = [
  'gettext',
  'gettextCatalog',
  'MtAnalysisFlowModel',
  'CrudToastFactory',
  'AlertingService',
  'sfeMtAnalysisService',
  '$q',
  'MetadataService',
  'InfoDialog',
  '$mdDialog',
  'BaseLineTargetFunctionService'
];

function BaseLineConfiguration(
  gettext,
  gettextCatalog,
  MtAnalysisFlowModel,
  CrudToastFactory,
  AlertingService,
  sfeMtAnalysisService,
  $q,
  MetadataService,
  InfoDialog,
  $mdDialog,
  BaseLineTargetFunctionService
) {
  function getConfiguration(
    timeseries,
    createCallback,
    deleteCallback,
    updateCallback
  ) {
    let originalTimeSeries = angular.copy(timeseries);
    const getBaselines = {
      query: () => {
        var deferred = $q.defer();
        MetadataService.Loading(true);
        MtAnalysisFlowModel.read({
          id: timeseries._id,
          timeSeriesId: timeseries.timeSeries,
          populate: 'inputTimeSeriesForXAxis,inputTimeSeriesForYAxis'
        }).then(
          res => {
            timeseries = res.data;
            originalTimeSeries = angular.copy(res.data);
            deferred.resolve({ data: res.data.targetFunctions || [] });
          },
          err => {
            AlertingService.Error(err);
            timeseries.targetFunctions = [];
            deferred.resolve({ data: [] });
          }
        );
        // createCallback();
        return {
          $promise: deferred.promise
        };
      }
    };
    const configuration = {
      entity: {
        singular: gettextCatalog.getString('Baseline'),
        plural: gettextCatalog.getString('Baselines')
      },
      api: {
        query: getBaselines,
        enrich: Enrich
      },
      buttons: [
        {
          title: gettext('Create'),
          fn: openCreateDialog,
          relist: true,
          color: 'accent'
        }
      ],
      //ONLY TABLE MODE
      modeConfiguration: {
        displayMode: 'table',
        hideModeButtons: true
      },
      shortActions: [
        {
          icon: 'mode_edit',
          action: updateBaseLine
        },
        {
          icon: 'delete',
          action: deletePrompt
        }
      ],
      attributes: [
        {
          title: gettextCatalog.getString('Values from'),
          param: 'xFrom'
        },
        {
          title: gettextCatalog.getString('Values to'),
          param: 'xTo'
        },
        {
          title: gettextCatalog.getString('Baseline function'),
          param: 'baselineFunction',
          type: 'text'
        },
        {
          title: gettextCatalog.getString('Target function'),
          param: 'targetFunction',
          type: 'text'
        }
      ]
    };

    function deletePrompt(baseLineToDelete, relistData) {
      let title = gettext('Confirmation');
      let text = gettext('Are you sure you want to delete this item?');
      if (
        Array.isArray(originalTimeSeries.targetFunctions) &&
        originalTimeSeries.targetFunctions.length === 1
      ) {
        title = gettext('Notice');
        text = gettext('You can\'t delete last baseline');
      }
      const textItem = {
        text,
        type: 'text'
      };

      const actions = [
        {
          title: gettext('Cancel'),
          cancel: true,
          color: 'primary'
        },
        {
          title: gettext('Delete'),
          fn: async () => {
            const targetFunctions = originalTimeSeries.targetFunctions.filter(
              item => item._id != baseLineToDelete._id
            );
            try {
              let { data } = await MtAnalysisFlowModel.update(
                { id: timeseries._id, timeSeriesId: timeseries.timeSeries },
                { targetFunctions }
              );
              deleteCallback({}, baseLineToDelete);
              timeseries = data;
              relistData();
            } catch (err) {
              AlertingService.Error(err);
            }
            relistData();
            $mdDialog.hide();
          },
          disabledFn: () => originalTimeSeries.targetFunctions.length === 1,
          color: 'warn'
        }
      ];

      InfoDialog.open(title, null, [textItem], actions);
    }

    async function updateBaseLine(baseLine, relistData) {
      const updatedBaseLine = await BaseLineTargetFunctionService.open(
        timeseries,
        baseLine
      );
      if (updatedBaseLine != null) {
        const targetFunctions = originalTimeSeries.targetFunctions.map(item => {
          if (item._id == baseLine._id) {
            return {
              _id: item._id,
              ...updatedBaseLine
            };
          }
          return item;
        });
        try {
          await MtAnalysisFlowModel.update(
            { id: timeseries._id, timeSeriesId: timeseries.timeSeries },
            { targetFunctions }
          );
          updateCallback({
            _id: baseLine._id,
            ...updatedBaseLine
          });
          relistData();
          CrudToastFactory.toast('create');
        } catch (err) {
          AlertingService.Error(err);
        }
      }
    }

    /**
     * @description Opens a dialog to define a new baseline.
     * @function
     */
    async function openCreateDialog(relist) {
      let newBaseLine = await BaseLineTargetFunctionService.open(timeseries);
      if (newBaseLine != null) {
        try {
          let updateParams = {
            targetFunctions: [
              ...originalTimeSeries.targetFunctions,
              newBaseLine
            ]
          };
          let { data } = await MtAnalysisFlowModel.update(
            { id: timeseries._id, timeSeriesId: timeseries.timeSeries },
            updateParams
          );
          CrudToastFactory.toast('create');
          relist();
          let createdBaseLine = data.targetFunctions.find(item => {
            return (
              item.xFrom == newBaseLine.xFrom && item.xTo == newBaseLine.xTo
            );
          });
          if (createdBaseLine != null) {
            createCallback(createdBaseLine);
          }
          // return baseLine;
        } catch (err) {
          AlertingService.Error(err);
        }
      }
    }

    async function Enrich(item) {
      if (item.baseLineK != null && item.baseLineN != null) {
        item.baselineFunction = sfeMtAnalysisService.getEquationString(
          item.baseLineK,
          item.baseLineN
        );
      } else {
        item.fetchingObject['baselineFunction'] = {
          fetching: false,
          success: true,
          nodata: true
        };
      }

      if (item.targetK != null && item.targetN != null) {
        item.fetchingObject['targetFunction'] = {
          fetching: false,
          success: true,
          nodata: false
        };
        item.targetFunction = sfeMtAnalysisService.getEquationString(
          item.targetK,
          item.targetN
        );
      } else {
        item.fetchingObject['targetFunction'] = {
          fetching: false,
          success: true,
          nodata: true
        };
      }
    }
    return configuration;
  }

  return { getConfiguration };
}
export default BaseLineConfiguration;
