domainAttributeConfiguration.$inject = ['gettext', '$q'];

function domainAttributeConfiguration(gettext, $q) {
  var configuration = {
    entity: {
      singular: gettext('Dynamic Attribute'),
      plural: gettext('Dynamic Attributes')
    },
    api: {
      query: {
        entity: 'domain-attributes',
        method: 'custom.readWithDomains'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'configurations-dynamic-attributes-dynamic-attributes-list-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'configurations-dynamic-attributes-dynamic-attributes-list-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state:
            'configurations-dynamic-attributes-dynamic-attributes-list-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'domain-attributes',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description')
      },
      {
        param: 'code',
        title: gettext('Code'),
        type: 'code'
      },
      {
        param: 'elementType',
        title: gettext('Element type')
      },
      {
        param: 'domainName',
        title: gettext('Domain')
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Code'),
        param: 'code',
        type: 'string'
      },
      {
        display: gettext('Domain'),
        param: 'domain',
        query: {
          entity: 'domains',
          method: 'read'
        },
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    item.fetchingObject['domainName'] = {
      fetching: true,
      success: false,
      nodata: false
    };
    if (
      item.hasOwnProperty('domain') &&
      typeof item.domain === 'object' &&
      item.domain !== null
    ) {
      item.fetchingObject['domainName'] = {
        fetching: false,
        success: true,
        nodata: false
      };
      item.domainName = item.domain.name;
    } else {
      item.fetchingObject['domainName'] = {
        fetching: false,
        success: true,
        nodata: true
      };
    }
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default domainAttributeConfiguration;
