module.exports = {
  entity: 'counter-types',
  path: 'counter-types',
  name: {
    en: 'Counter types',
    sl_SI: 'Tip merilnika'
  },
  networkModel: {
    entity: 'counter-types',
    read: 'read'
  },
  scope: 'tis_be:counter-types',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select counter type',
      sl_SI: 'Izberite tip merilnika'
    },
    listInfo: {
      en: 'Select counter type from the list below',
      sl_SI: 'Izberite tip merilnika s spodnjega seznama'
    }
  }
}
