module.exports = {
  entity: 'severities',
  path: 'severities',
  entityId: 187,
  name: {
    en: 'Severity',
    sl_SI: 'Pomebnost'
  },
  networkModel: {
    entity: 'severities',
    read: 'read'
  },
  selectedParamName: '_id',
  scope: 'tis_be:severities',

  dialog: {
    title: {
      en: 'Select severity',
      sl_SI: 'Izberite pomebnost'
    },
    listInfo: {
      en: 'Select severity from the list below',
      sl_SI: 'Izberite pomebnost s spodnjega seznama'
    }
  }
}
