TangoAgentConnectionConfigController.$inject = [
  '$stateParams',
  'gettext',
  'MetadataService',
  'TimeseriesTangoAgentConnectionConfigConfiguration',
  'gettextCatalog',
  'tangoAgentConnectionConfig',
  'TranslationService',
  '$timeout'
];

function TangoAgentConnectionConfigController(
  $stateParams,
  gettext,
  MetadataService,
  TimeseriesTangoAgentConnectionConfigConfiguration,
  gettextCatalog,
  tangoAgentConnectionConfig,
  TranslationService,
  $timeout
) {
  const vm = this;
  vm.tangoAgentConnectionConfigId = $stateParams.id;
  vm.datasourceObj = true;
  vm.datasource = {};
  vm.arrayType = [];
  vm.arrayTypeObj = [];
  vm.filterObject = {
    'externalReaderFlows.liveIngestionConfig.tangoAgentConfig.connectionConfig':
      vm.tangoAgentConnectionConfigId
  };

  vm.$onInit = () => {
    MetadataService.Loading(true);
    vm.headerData = constructHeader(false, tangoAgentConnectionConfig);
    vm.timeSeriesLists = initTimeSeriesList();
    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(
        tangoAgentConnectionConfig.name,
        tangoAgentConnectionConfig.description
      );
    });
    vm.loading = false;
  };
  /**
   * @description create configuration to display LIVE and HISTORY time series.
   * @function
   * @return {Array}
   */
  function initTimeSeriesList() {
    var configuration = angular.copy(
      TimeseriesTangoAgentConnectionConfigConfiguration
    );
    // set new form redirect parameters
    configuration.buttons = [
      {
        title: gettext('Create'),
        className: 'accent-color',
        link: {
          state: 'data-time-series-new'
        },
        color: 'accent'
      }
    ];

    let configs = [
      {
        title: gettextCatalog.getPlural(
          3,
          'Time Series - Live',
          'Time Series - Live',
          {}
        ),
        filterObject: {
          'externalReaderFlows.liveIngestionConfig.tangoAgentConfig.connectionConfig':
            vm.tangoAgentConnectionConfigId
        }
      },
      {
        title: gettextCatalog.getPlural(
          3,
          'Time Series - History',
          'Time Series History',
          {}
        ),
        filterObject: {
          'externalReaderFlows.historyIngestionConfig.tangoAgentConfig.connectionConfig':
            vm.tangoAgentConnectionConfigId
        }
      },
      {
        title: gettextCatalog.getPlural(
          3,
          'Time Series - External Writer Flows',
          'Time Series - External Writer Flows',
          {}
        ),
        filterObject: {
          'externalWriterFlows.tangoAgentConfig.connectionConfig':
            vm.tangoAgentConnectionConfigId,
          populate: 'externalWriterFlows'
        }
      }
    ];

    return configs.map(config => ({
      config: {
        ...configuration,
        entity: {
          ...configuration.entity,
          plural: config.title,
          singular: config.title
        },
        shortActions: []
      },
      filterObject: config.filterObject
    }));
  }

  function constructHeader(isError, tangoAgentConnectionConfig) {
    if (isError) {
      return {
        metadata: {
          definition: gettext('tango agent connection configurations'),
          title: gettext('Error loading')
        }
      };
    }
    const actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: vm.tangoAgentConnectionConfigId,
        endpoint: {
          entity: 'tango-agent-connection-configs',
          method: 'delete'
        },
        redirectState: 'external-datasources-tango-agent-connection-config-list'
      },
      {
        title: gettext('Update'),
        state: 'external-datasources-tango-agent-connection-config-edit',
        param: 'id',
        paramValue: tangoAgentConnectionConfig._id
      }
    ];
    const properties = [];
    // device data
    if (tangoAgentConnectionConfig.externalDatasource != null) {
      const deviceData = tangoAgentConnectionConfig.externalDatasource;
      properties.push({
        title: gettext('Connected external datasources'),
        linkTitle: deviceData.name,
        type: 'link',
        state: 'external-datasources-external-datasources-view',
        param: 'id',
        paramValue: deviceData._id
      });
    }
    let propertySections = [
      {
        title: gettext('properties'),
        properties: properties
      }
    ];

    let connectionSettings;
    let tangoAgentConfigType = TranslationService.GetCollectionById(
      'codelists.tangoAgentConfigTypes',
      tangoAgentConnectionConfig.tangoAgentConfigType
    );
    if (tangoAgentConfigType != null) {
      tangoAgentConfigType = tangoAgentConfigType.name;
    } else {
      tangoAgentConfigType = gettextCatalog.getString('Unknown');
    }

    switch (tangoAgentConnectionConfig.tangoAgentConfigType) {
    case 1:
      connectionSettings = configureConnectionSection(
        tangoAgentConnectionConfig.connector,
        'external-datasources-connectors-opc-view',
        tangoAgentConfigType,
        gettext('URL')
      );
      break;
    case 2:
      connectionSettings = configureConnectionSection(
        tangoAgentConnectionConfig.connector,
        'external-datasources-connectors-webservice-view',
        tangoAgentConfigType,
        gettext('URL')
      );
      break;
    case 3:
      connectionSettings = configureConnectionSection(
        tangoAgentConnectionConfig.connector,
        'external-datasources-connectors-database-view',
        tangoAgentConfigType,
        gettext('URL')
      );
      break;
    case 4:
      connectionSettings = configureConnectionSection(
        tangoAgentConnectionConfig.connector,
        'external-datasources-connectors-file-view',
        tangoAgentConfigType,
        gettext('Path')
      );
      break;
    }

    propertySections.push({
      title: gettext('connection'),
      properties: connectionSettings
    });

    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];
    propertySections.push({
      title: gettext('Authorized personnel'),
      properties: userAccess,
      identifier: 'tango-agent-connection-configs',
      target: vm.tangoAgentConnectionConfigId,
      type: 'userAccess'
    });

    return {
      metadata: {
        definition: gettext('Tango Agent Connection Configuration'),
        title: tangoAgentConnectionConfig.name,
        description: tangoAgentConnectionConfig.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }

  function configureConnectionSection(connection, state, title, pathOrUrl) {
    return [
      {
        title: gettext('Connection'),
        linkTitle: connection.name,
        type: 'link',
        state: state,
        param: 'id',
        paramValue: connection._id
      },
      {
        title: gettext('Type'),
        value: format`${gettextCatalog.getString(title)} (${
          connection.description
        })`,
        type: 'simple'
      },
      {
        title: pathOrUrl,
        value: connection.url || connection.path,
        type: 'simple'
      }
    ];
  }

  // tagged template literal, used in formatting of the description of the type of connection
  // if the type has a description (this is values[1]), enclose it in parentheses, otherwise just return the name of the type (values [0])
  function format(strings, ...values) {
    if (values[1]) {
      return strings.reduce(
        (finalString, currentString, i) =>
          finalString + currentString + (values[i] || ''),
        ''
      );
    }
    return values[0];
  }
}

export default TangoAgentConnectionConfigController;
