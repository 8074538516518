import calculatedFlowAction from '../../../redux/calculated-flow/action/calculated-flow.action';
import externalReaderFlowAction from '../../../redux/external-reader-flow/action/external-reader-flow.action';
import externalWriterFlowAction from '../../../redux/external-writer-flow/action/external-writer-flow.action';
import maintenanceFlowAction from '../../../redux/maintenance-flow/action/maintenance-flow.action';
import mtAnalysisFlowAction from '../../../redux/mt-analysis-flow/action/mt-analysis-flow.action';

/**
 * @ngdoc service
 * @name common.TimeSeriesFlowHelper
 * @description Helps to fetch initial data to display flow forms
 */

TimeSeriesFlowHelper.$inject = [
  '$ngRedux',
  'AlertingService',
  'CrawlerMethods'
];
export default function TimeSeriesFlowHelper(
  $ngRedux,
  AlertingService,
  CrawlerMethods
) {
  /**
   * @description returns store action that adds fetched flow entity to the store.
   * @function
   * @param {string} entity
   * @return {fucntion}
   */
  const getAction = entity => {
    switch (entity) {
    case 'external-reader-flows':
      return externalReaderFlowAction.addExternalReaderFlow;
    case 'mt-analysis-flows':
      return mtAnalysisFlowAction.addMtAnalysisFlow;
    case 'calculated-flows':
      return calculatedFlowAction.addCalculatedFlow;
    case 'external-writer-flows':
      return externalWriterFlowAction.addExternalWriterFlow;
    case 'maintenance-flows':
      return maintenanceFlowAction.addMaintenanceFlow;
    }
  };
  /**
   * @description returns store name.
   * @function
   * @param {string} entity
   * @return {string}
   */
  const getStoreName = entity => {
    switch (entity) {
    case 'external-reader-flows':
      return 'externalReaderFlow';
    case 'mt-analysis-flows':
      return 'monitorAndTargetAnalysisFlow';
    case 'calculated-flows':
      return 'calculatedFlow';
    case 'external-writer-flows':
      return 'externalWriterFlow';
    case 'maintenance-flows':
      return 'maintenanceFlow';
    }
  };
  /**
   * @description returns key that keeps flow data when duplicating time series.
   * @function
   * @param {string} entity
   * @return {string}
   */
  const getDuplicateKey = entity => {
    switch (entity) {
    case 'external-reader-flows':
      return 'externalReaderFlow';
    case 'mt-analysis-flows':
      return 'mtAnalysisFlow';
    case 'calculated-flows':
      return 'calculatedFlow';
    case 'external-writer-flows':
      return 'externalWriterFlow';
    case 'maintenance-flows':
      return 'maintenanceFlow';
    }
  };

  /**
   * @description returns flow entity.
   * @function
   * @param {string} entityId
   * @param {string} timeSeriesId
   * @param {string} populate used to fetch data using network model
   * @param {function} preProcessResult if you need to add something to flow before adding it to the store
   * @param {string} entity flow entity name
   * @return {Object|undefined}
   */
  const getFlow = async (
    entityId,
    timeSeriesId,
    populate,
    preProcessResult,
    entity
  ) => {
    const storeState = $ngRedux.getState();
    const storeName = getStoreName(entity);
    if (
      storeState[storeName] &&
      storeState.timeSeriesConfigurations != null &&
      storeState.timeSeriesConfigurations.list != null &&
      entityId != null
    ) {
      if (storeState[storeName][entityId] == null && timeSeriesId != null) {
        try {
          const method = CrawlerMethods.getMethod({
            entity,
            method: 'read'
          });

          let result;
          if (typeof method === 'function') {
            result = await method({
              id: entityId,
              timeSeriesId,
              populate
            });
          }
          if (typeof preProcessResult === 'function') {
            result = await preProcessResult(result);
          }
          if (result != null) {
            let addFlow = getAction(entity);

            $ngRedux.dispatch(addFlow({ result }));
            return result.data;
          }
        } catch (err) {
          AlertingService.Error(err);
        }
      } else if (storeState[storeName][entityId] != null) {
        return storeState[storeName][entityId].data;
      }
    }
  };

  /**
   * @description returns flow, flowConfiguration and timeSeries data.
   * @function
   * @param {enum} actionType create|update|duplicate
   * @param {string} entityId flow id
   * @param {object} duplicateData used to duplicate timeseries
   * @param {string} timeSeriesId
   * @param {string} populate used to fetch data using network model
   * @param {function} preProcessResult if you need to add something to flow before adding it to the store
   * @param {string} entity flow entity name
   * @param {string} processResult if you need to run any function before returning flow
   * @return {Object}
   */
  const getFlowData = async ({
    actionType,
    entityId,
    duplicateData,
    timeSeriesId,
    populate,
    processResult,
    preProcessResult,
    entity
  }) => {
    const storeState = $ngRedux.getState();

    let item;
    let timeSeries;
    let timeSeriesConfiguration;

    if (storeState != null) {
      switch (actionType) {
      case 'create':
        if (timeSeriesId != null) {
          if (
            storeState.timeSeries != null &&
              storeState.timeSeries[timeSeriesId] != null
          ) {
            timeSeries = storeState.timeSeries[timeSeriesId].data;
          }
        }

        if (duplicateData != null) {
          const duplicateFlowKey = getDuplicateKey(entity);
          item = duplicateData[duplicateFlowKey];
        }

        break;
      case 'update':
      case 'duplicate':
        item = await getFlow(
          entityId,
          timeSeriesId,
          populate,
          preProcessResult,
          entity
        );
        if (item != null && item.timeSeries != null) {
          if (
            storeState.timeSeries != null &&
              storeState.timeSeries[item.timeSeries] != null
          ) {
            timeSeries = storeState.timeSeries[item.timeSeries].data;
          }
        }

        if (
          item != null &&
            item.timeSeries != null &&
            Array.isArray(
              storeState.timeSeriesConfigurations.list[item.timeSeries].data
            )
        ) {
          timeSeriesConfiguration = storeState.timeSeriesConfigurations.list[
            item.timeSeries
          ].data.find(config => config.flow === item._id);
        }
        break;
      default:
        AlertingService.devWarn('Flow dialog action type is missing');
      }
    }
    if (typeof preProcessResult === 'function' && item != null) {
      item = await processResult(item);
    }
    return {
      item,
      timeSeries,
      timeSeriesConfiguration
    };
  };

  return { getFlowData };
}
