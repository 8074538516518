import template from './sfe-rule-alarms.directive.html';

export default [
  function() {
    return {
      restrict: 'E',
      template,
      scope: {
        rules: '=?',
        edit: '<',
        parentForm: '=',
        additionalProperties: '<'
      },
      controller: sfeRuleAlarmsController,
      controllerAs: 'vm',
      bindToController: true
    };
  }
];

sfeRuleAlarmsController.$inject = ['$scope', 'gettext', 'gettextCatalog'];

function sfeRuleAlarmsController($scope, gettext, gettextCatalog) {
  const vm = this;

  vm.properties = [
    {
      label: gettextCatalog.getString('Triggers an error'),
      name: 'isError'
    },
    {
      label: gettextCatalog.getString('Manual intervention'),
      name: 'isManualIntervention'
    }
  ];
  var rulesWatcher;
  var ruleConfiguration = {
    query: {
      entity: 'rules',
      method: 'read'
    },
    required: true,
    floatingLabel: gettext('Select rule'),
    label: gettextCatalog.getString('Rule'),
    searchParamName: 'filter',
    entity: 'rules',
    dialogConfiguration: {
      title: gettext('Select rule')
    },
    filterObject: {
      order: 'name'
    },
    selectedParam: 'id'
  };
  var alarmConfiguration = {
    selectedParam: 'id',
    query: {
      entity: 'alarms',
      method: 'read'
    },
    required: true,
    floatingLabel: gettext('Select alarm'),
    label: gettextCatalog.getString('Alarm'),
    searchParamName: 'filter',
    entity: 'alarms',
    dialogConfiguration: {
      title: gettext('Select alarm')
    },
    filterObject: {
      order: 'name'
    }
  };
  vm.addRule = addRule;
  vm.addAlarm = addAlarm;
  vm.removeRule = removeRule;
  vm.removeAlarm = removeAlarm;
  vm.$onInit = init;

  vm.$onChanges = changes => {
    if (
      changes.additionalProperties &&
      Array.isArray(vm.additionalProperties)
    ) {
      vm.additionalProperties.forEach(property => {
        vm.properties.push(property);
      });
    }
  };
  $scope.$on('$destroy', function() {
    if (rulesWatcher) {
      rulesWatcher();
    }
  });

  function init() {
    if (vm.edit) {
      rulesWatcher = $scope.$watch('vm.rules', function() {
        if (vm.rules) {
          vm.rules = _.sortBy(vm.rules, 'order');
          setRuleAndAlarmObject();
          rulesWatcher();
        }
      });
    }
    if (vm.parentForm) {
      $scope.ItemForm = vm.parentForm;
    }
  }

  function removeRule(index) {
    vm.rules.splice(index, 1);
    setRuleLabels();
  }

  function removeAlarm(ruleIndex, alarmIndex) {
    vm.rules[ruleIndex].alarms.splice(alarmIndex, 1);
    setAlarmLabels(ruleIndex);
  }

  function addRule() {
    if (!vm.rules) {
      vm.rules = [];
    }
    (ruleConfiguration.label =
      gettextCatalog.getString('Rule') +
      ' ' +
      (vm.rules.length + 1).toString()),
    vm.rules.push({
      alarms: [],
      configuration: angular.copy(ruleConfiguration)
    });
  }

  function addAlarm(ruleIndex) {
    var rule = vm.rules[ruleIndex];
    if (!rule.alarms) {
      rule.alarms = [];
    }
    rule.alarms.push({
      configuration: angular.copy(alarmConfiguration)
    });
  }

  function setAlarmLabels(ruleIndex) {
    vm.rules[ruleIndex].alarms.forEach(function(alarm, index) {
      alarm.configuration.label =
        gettextCatalog.getString('Alarm ') + (index + 1);
    });
  }

  function setRuleLabels() {
    vm.rules.forEach(function(rule, index) {
      rule.configuration.label =
        gettextCatalog.getString('Rule') + ' ' + (index + 1);
    });
  }

  function setRuleAndAlarmObject() {
    vm.rules.forEach(function(rule, ruleIndex) {
      rule.alarms = _.sortBy(rule.alarms, 'order');
      rule.alarms.forEach(function(alarm) {
        alarm.configuration = angular.copy(alarmConfiguration);
        if (typeof alarm.alarm == 'object' && alarm.alarm != null) {
          alarm.alarmObject = {
            _id: alarm._id,
            name: alarm.name
          };
        } else {
          alarm.alarmObject = alarm.alarm;
          alarm.editMode = true;
        }
      });
      rule.configuration = angular.copy(ruleConfiguration);
      rule.configuration.label += ' ' + (ruleIndex + 1);
      if (!rule.rule == 'object' && rule.rule != null) {
        rule.ruleObject = {
          _id: rule.rule._id,
          name: rule.rule.name
        };
      } else {
        rule.ruleObject = rule.rule;
        rule.editMode = true;
      }
    });
  }
}
