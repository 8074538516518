NewSchedulerFormController.$inject = [
  '$state',
  'AlertingService',
  'gettext',
  'MultiLanguage',
  '$timeout',
  'MetadataService',
  'LocalizationService',
  'createOrUpdateService',
  'SfeHeader',
  'Formatting',
  'scheduler'
];

function NewSchedulerFormController(
  $state,
  AlertingService,
  gettext,
  MultiLanguage,
  $timeout,
  MetadataService,
  LocalizationService,
  createOrUpdateService,
  SfeHeader,
  Formatting,
  scheduler
) {
  var vm = this;
  var schedulerId;

  init();

  function init() {
    vm.editMode = false;
    vm.title = gettext('New Scheduler');
    schedulerId = $state.params.id;
    if ($state.params.id != null) {
      vm.editMode = true;
    }
    vm.scheduler = {};
    vm.dataConfig = {};
    vm.dataConfig.data = [
      {
        placeholder: gettext('Name'),
        name: 'name',
        showCond: true,
        type: 'text',
        componentType: 'multilanguage',
        required: true
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        maxlength: 500,
        showCond: true,
        type: 'text',
        required: false,
        componentType: 'multilanguage'
      },
      {
        componentType: 'autocompleteDialog',
        edit: vm.editMode,
        configuration: {
          codelist: 'scheduleClassifications',
          floatingLabel: gettext('Schedule classification'),
          searchParamName: 'name',
          required: true,
          selectedParam: 'id',
          noDialog: true
        },
        name: 'scheduleClassification'
      },
      {
        label: gettext('Is standard'),
        name: 'isStandard',
        componentType: 'checkBox'
      }
    ];

    var cronConfig = {
      componentType: 'cron',
      name: 'cron'
    };

    if (schedulerId) {
      MetadataService.Loading(true);
      cronConfig.mode = 'update';
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit Scheduler'),
        'data-schedules-view',
        $state.params
      );
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New Scheduler'),
        'data-schedules-list'
      );
    }
    vm.dataConfig.data.push(cronConfig);

    vm.dataConfig.edit = !!schedulerId;

    vm.dataConfig.action = {
      ctrlFn: true,
      title: schedulerId ? gettext('Update') : gettext('Create'),
      fn: createOrUpdate,
      invalidFn: function() {
        if (
          vm.dataConfig.dataObj &&
          vm.dataConfig.dataObj.cron &&
          vm.dataConfig.dataObj.cron.validation
        ) {
          return !vm.dataConfig.dataObj.cron.validation.valid;
        } else {
          return false;
        }
      }
    };

    vm.dataConfig.redirect = {
      param: 'id',
      paramValue: schedulerId,
      location: 'data-schedules-view'
    };

    // initialization of first scheduler
    if (schedulerId) {
      vm.editMode = true;
      vm.title = gettext('Edit Scheduler');
      getScheduler();
    } else {
      vm.scheduler = {
        name: '',
        description: '',
        enabled: true,
        alwaysOn: false,
        sample: '',
        sampleInterval: 10,
        validFromDate: '',
        validToDate: '',
        validFromHour: '',
        validToHour: '',
        archiveType: {},
        scheduleClassification: null,
        isStandard: false
      };
      vm.dataConfig.dataObj = vm.scheduler;
    }
  }

  function getScheduler() {
    const validFromHour =
      Formatting.getHoursAndMinutes(scheduler.validFromDate, true) ||
      '00:00:00';
    const validToHour =
      Formatting.getHoursAndMinutes(scheduler.validToDate, true) || '23:59:59';
    scheduler = {
      ...scheduler,
      cron: {
        cronExpression: scheduler.crontabExpression,
        validFromDate: scheduler.validFromDate,
        validToDate: scheduler.validToDate,
        fromTime: validFromHour,
        toTime: validToHour,
        initialize: true,
        alwaysValid: scheduler.alwaysValid
      }
    };
    var selectedLang = LocalizationService.GetSelectedLangNetworkCode();
    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(
        'Edit ' + scheduler.name[selectedLang],
        scheduler.description[selectedLang]
      );
    });
    vm.dataConfig.dataObj = scheduler;
  }

  // create and update functions //

  function createOrUpdate() {
    vm.sendingRequest = true;
    createOrUpdateService
      .simpleCall(
        {
          entity: 'schedulers',
          method: vm.editMode ? 'update' : 'create'
        },
        vm.editMode,
        schedulerId,
        dataToSave(),
        'data-schedules-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function dataToSave() {
    var schedulerData = vm.dataConfig.dataObj;
    var schedulerObj = MultiLanguage.constructForm(
      vm.dataConfig.data,
      vm.dataConfig.dataObj
    );
    var data = {
      name: schedulerObj.name,
      description: schedulerObj.description,
      crontabExpression: schedulerData.cron.cronExpression,
      alwaysValid: schedulerData.cron.alwaysValid,
      scheduleClassification: schedulerData.scheduleClassification
        ? schedulerData.scheduleClassification.id
        : null,
      isStandard: schedulerData.isStandard
    };
    if (!schedulerData.cron.alwaysValid) {
      data.validFromDate = Formatting.formatDate(
        schedulerData.cron.validFromDate,
        schedulerData.cron.fromTime
      );
      data.validToDate = Formatting.formatDate(
        schedulerData.cron.validToDate,
        schedulerData.cron.toTime
      );
    }
    return data;
  }
}

export default NewSchedulerFormController;
