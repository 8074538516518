/**
 * @ngdoc service
 * @name common.DashboardUserModel
 * @module common
 * @description
 * DashboardUserModel
 */
/**
 * @ngdoc method
 * @name common.DashboardUserModel#
 * @module common
 * @methodOf common.DashboardUserModel
 * @param {Object} networkParameters request parameters
 * @returns {$resource}
 */
DashboardUserModel.$inject = ['ModelUtilities'];

function DashboardUserModel(ModelUtilities) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'dashboard-users'
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default DashboardUserModel;
