AlarmDefinitionConfiguration.$inject = ['gettext', '$q'];

function AlarmDefinitionConfiguration(gettext, $q) {
  var configuration = {
    listId: 'alarmList',
    entity: {
      singular: gettext('Alarm Definition'),
      plural: gettext('Alarm Definitions')
    },
    api: {
      query: {
        entity: 'alarms',
        method: 'custom.list'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'alarms-and-rules-alarm-definitions-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'alarms-and-rules-alarm-definitions-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'alarms-and-rules-alarm-definitions-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'alarms',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Severity'),
        param: 'severityName',
        type: 'text'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        query: {
          entity: 'alarm-types',
          method: 'read'
        },
        entity: 'alarm-types',
        display: gettext('Type'),
        param: 'type'
      },
      {
        query: {
          entity: 'severities',
          method: 'read'
        },
        entity: 'severities',
        display: gettext('Severity'),
        param: 'severity'
      }
    ],
    sort: [
      {
        display: gettext('created DESC'),
        params: '-_id'
      },
      {
        display: gettext('created ASC'),
        params: '_id'
      },
      {
        display: gettext('name ASC'),
        params: 'name'
      },
      {
        display: gettext('name DESC'),
        params: '-name'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    item.fetchingObject['severityName'] = {
      fetching: false,
      success: false,
      nodata: false
    };

    if (item.severity && typeof item.severity === 'object') {
      item.fetchingObject['severityName'].success = true;
      item.severityName = item.severity.name;
    } else {
      item.fetchingObject['severityName'].nodata = true;
    }
    return deferred.promise;
  }

  return configuration;
}

export default AlarmDefinitionConfiguration;
