MainTheme.$inject = ['$rootScope'];

function MainTheme($rootScope) {
  return {
    restrict: 'A',
    replace: false,
    transclude: true,
    template: '<div md-theme="{{ themeName }}" ng-transclude></div>',
    scope: {
      themeName: '@'
    },
    link: function link(scope) {
      scope.themeName = $rootScope.activeTheme;

      $rootScope.$on('changeTheme', function() {
        scope.themeName = $rootScope.activeTheme;
        // scope.$apply();
      });
    }
  };
}

export default MainTheme;
