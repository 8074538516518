PriceListDetailController.$inject = [
  '$state',
  'gettext',
  'MetadataService',
  'PriceListDetailItemsConfiguration',
  'GenerateSharedPriceListProperties',
  'priceListDetail',
  '$timeout'
];

function PriceListDetailController(
  $state,
  gettext,
  MetadataService,
  PriceListDetailItemsConfiguration,
  GenerateSharedPriceListProperties,
  priceListDetail,
  $timeout
) {
  const vm = this;
  vm.priceListDetail = {
    id: $state.params.priceListDetailId,
    master: $state.params.priceListId,
    data: null,
    isFetching: false
  };

  vm.$onInit = () => {
    vm.loading = true;
    MetadataService.Loading(true);
    vm.priceListDetail.data = {
      validFrom: priceListDetail.validFrom,
      validTo: priceListDetail.validTo,
      master: {
        name: priceListDetail.priceListMaster.name,
        invoiceIssuer: priceListDetail.priceListMaster.invoiceIssuer,
        businessPartnerCustomer:
          priceListDetail.priceListMaster.businessPartnerCustomer,
        energySourceType: priceListDetail.priceListMaster.energySourceType,
        _id: priceListDetail.priceListMaster._id
      }
    };
    vm.headerData = constructHeader(vm.priceListDetail.data);
    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(
        vm.priceListDetail.data.master.name,
        vm.priceListDetail.data.master.description
      );
    });
    vm.loading = false;
    setPriceListItemListConfiguration();
  };

  function constructHeader(priceListDetail) {
    let propertySections = [
      {
        title: gettext('Properties'),
        properties: GenerateSharedPriceListProperties.generateProperties(
          priceListDetail.master,
          true
        )
      }
    ];

    let dates = [
      {
        title: gettext('Valid from'),
        type: 'date',
        value: priceListDetail.validFrom
      }
    ];

    if (priceListDetail.validTo) {
      dates.push({
        title: gettext('Valid to'),
        type: 'date',
        value: priceListDetail.validTo
      });
    }

    propertySections.push({
      properties: dates
    });
    const actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: vm.priceListDetail.id,
        endpoint: {
          entity: 'price-list-details',
          method: 'delete'
        },
        redirectState: 'invoices-price-lists-view',
        paramName: 'priceListId',
        paramValue: vm.priceListDetail.master
      },
      {
        title: gettext('Update'),
        script: true,
        fn: edit
      }
    ];
    return {
      metadata: {
        definition: 'price list',
        title: priceListDetail.master.name
          ? priceListDetail.master.name
          : vm.priceListDetail.id
      },
      actions: actions,
      propertySections: propertySections
    };
  }
  function setPriceListItemListConfiguration() {
    let configuration = {
      ...PriceListDetailItemsConfiguration
    };
    const buttonIndex = configuration.buttons.findIndex(
      item =>
        item.link && item.link.state === 'invoices-price-lists-detail-item-new'
    );
    configuration.buttons[buttonIndex].link.params = [
      {
        queryParam: 'priceListDetailId',
        value: vm.priceListDetail.id
      }
    ];
    vm.configuration = configuration;

    vm.filter = {
      priceListDetail: vm.priceListDetail.id
    };
  }
  function edit() {
    $state.go('invoices-price-lists-detail-edit', {
      priceListId: vm.priceListDetail.master,
      priceListDetailId: vm.priceListDetail.id
    });
  }
}

export default PriceListDetailController;
