module.exports = {
  entity: 'weather-measurements',
  path: 'weather-measurements',
  name: {
    en: 'Weather measurement',
    sl_SI: 'Vremenska meritev'
  },
  networkModel: {
    entity: 'weather-measurements',
    read: 'read'
  },
  scope: 'tis_be:weather-measurements',
  dialog: {
    title: {
      en: 'Select weather measurement',
      sl_SI: 'Izberite vremensko meritev'
    },
    listInfo: {
      en: 'Select weather measurement from the list below',
      sl_SI: 'Izberite vremensko meritev s spodnjega seznama'
    }
  }
}
