module.exports = {
  entity: 'costCentreTypes',
  path: 'costCentreTypes',
  name: {
    en: 'Cost centre types',
    sl_SI: 'Vrste stroškovnih mest'
  },
  codelist: 'costCentreTypes',
  selectedParamName: 'id',
  scope: 'tis_be:cost-centre-types',

  dialog: {
    codelist: 'costCentreTypes',
    title: {
      en: 'Select cost centre type',
      sl_SI: 'Izberite vrsto stroškovnega mesta'
    },
    listInfo: {
      en: 'Select cost centre type from the list below',
      sl_SI: 'Izberite vrsto stroškovnega mesta s spodnjega seznama'
    }
  }
}
