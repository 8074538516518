import template from './work-schedule.directive.html';

workScheduleDirective.$inject = [
  '$mdDialog',
  'CrudToastFactory',
  'AlertingService',
  'gettext',
  'TranslationService',
  'UserModel',
  'StandardUtils',
  'InfoDialog',
  'Formatting',
  'SfeFormDialogService',
  'createOrUpdateService',
  '$state',
  'WorkScheduleModel',
  'DateLocalizationService'
];

function workScheduleDirective(
  $mdDialog,
  CrudToastFactory,
  AlertingService,
  gettext,
  TranslationService,
  UserModel,
  StandardUtils,
  InfoDialog,
  Formatting,
  SfeFormDialogService,
  createOrUpdateService,
  $state,
  WorkScheduleModel,
  DateLocalizationService
) {
  return {
    restrict: 'E',
    template,
    scope: {
      config: '=',
      events: '=',
      fetching: '='
    },
    link: function postLink(scope) {
      // var users;
      scope.config.calendar.eventClick = inspectEvent;
      scope.config.calendar.select = selectDay;
      $('.fc-button')
        .removeClass(
          'fc-state-default fc-state-active fc-button fc-corner-left fc-corner-right'
        )
        .addClass('md-primary md-button md-ink-ripple md-tis-theme');

      function getForm(edit) {
        const userLabel =
          $state.current.name === 'users-absent-personnel'
            ? gettext('Select absent user')
            : gettext('Select user');
        var form = [
          {
            componentType: 'autocompleteDialog',
            edit: edit,
            configuration: {
              query: {
                entity: 'users',
                method: 'read'
              },
              floatingLabel: userLabel,
              searchParamName: 'full_name',
              entity: 'users',
              required: true
            },
            name: 'user'
          },
          {
            title: gettext('Date Range'),
            componentType: 'title'
          },
          {
            componentType: 'datePickerFromTo',
            from: 'dateFrom',
            to: 'dateTo',
            idFrom: 'dutyFrom',
            idTo: 'dutyTo',
            labelFrom: gettext('Range from *'),
            labelTo: gettext('Range to *'),
            fromIsAfterTo: gettext(
              'Valid from date must be before valid to date.'
            ),
            required: true
          }
        ];
        if ($state.current.name === 'users-absent-personnel') {
          form.splice(1, 0, {
            componentType: 'autocompleteDialog',
            edit: edit,
            configuration: {
              query: {
                entity: 'users',
                method: 'read'
              },
              floatingLabel: gettext('Select substitute user'),
              searchParamName: 'full_name',
              entity: 'users',
              required: true
            },
            name: 'substituteUser'
          });
        }
        return form;
      }

      function filterSubstituteUser(items) {
        var formObject = this.formObject;
        if (items && formObject.user) {
          return items.filter(function(item) {
            return item._id !== formObject.user._id;
          });
        } else {
          return items;
        }
      }

      function absentUserChange() {
        var formObject = this.formObject;
        var userConfig = this.userConfig;
        if (formObject && userConfig) {
          if (
            formObject.user &&
            formObject.substituteUser &&
            formObject.user._id === formObject.substituteUser._id
          ) {
            formObject.substituteUser = null;
          }
        }
      }

      function openEventDialog(eventId, start, end) {
        $mdDialog.hide();
        if (start) {
          start.setHours(start.getHours() + start.getTimezoneOffset() / 60);
        }
        if (end) {
          end.setHours(end.getHours() + end.getTimezoneOffset() / 60);
          end = new Date(end - 1000);
        }
        var dataObj = {
          dateFrom: start || new Date(),
          dateTo: end || new Date(),
          type: null,
          user: '',
          timeFrom: '0:00',
          timeTo: '23:59',
          _preserve_: true
        };
        var type;
        var title;
        if ($state.current.name === 'users-absent-personnel') {
          title = eventId
            ? gettext('Update an absence')
            : gettext('Create an absence');
          type = 2;
        } else {
          title = eventId
            ? gettext('Update an assignment')
            : gettext('Create an assignment');
          type = 1;
        }
        var config = getForm(!!eventId);
        if (eventId) {
          WorkScheduleModel.read({
            id: eventId
          }).then(
            function(res) {
              dataObj = {
                user: res.data.user,
                substituteUser: res.data.substituteUser,
                dateFrom: new Date(res.data.dateFrom),
                dateTo: new Date(res.data.dateTo),
                timeFrom: '0:00',
                timeTo: '23:59',
                _preserve_: true
              };
              var substituteUserConfig = config.find(function(item) {
                return item.name === 'substituteUser';
              });

              if (substituteUserConfig) {
                substituteUserConfig.configuration.filterFn = filterSubstituteUser.bind(
                  {
                    formObject: dataObj
                  }
                );
              }
              SfeFormDialogService.openSfeFormDialog(
                true,
                config,
                dataObj,
                title
              ).then(function(dataObject) {
                if (dataObject) {
                  createOrUpdate(eventId, dataObject, type);
                }
              });
            },
            function(err) {
              AlertingService.Error(err);
            }
          );
        } else {
          var substituteUserConfig = config.find(function(item) {
            return item.name === 'substituteUser';
          });

          var userConfig = config.find(function(item) {
            return item.name === 'user';
          });
          if (userConfig) {
            userConfig.configuration.change = absentUserChange.bind({
              formObject: dataObj,
              userConfig: userConfig
            });
          }

          if (substituteUserConfig) {
            substituteUserConfig.configuration.filterFn = filterSubstituteUser.bind(
              {
                formObject: dataObj
              }
            );
          }

          SfeFormDialogService.openSfeFormDialog(
            false,
            config,
            dataObj,
            title
          ).then(function(dataObject) {
            if (dataObject) {
              createOrUpdate(eventId, dataObject, type);
            }
          });
        }
      }

      function createOrUpdate(eventId, dataObject, type) {
        createOrUpdateService
          .simpleCall(
            {
              entity: 'work-schedules',
              method: eventId ? 'update' : 'create'
            },
            eventId ? true : false,
            eventId,
            dataToSave(dataObject, type)
          )
          .then(
            function() {
              scope.config.refreshData();
            },
            function(err) {
              AlertingService.Error(err);
            }
          );
      }

      function dataToSave(dataObject, type) {
        var dataObjectToSave = {
          user: dataObject.user._id,
          substituteUser: dataObject.substituteUser
            ? dataObject.substituteUser._id
            : null,
          type: type,
          dateFrom: Formatting.formatDate(
            dataObject.dateFrom,
            dataObject.timeFrom
          ),
          dateTo: Formatting.formatDate(dataObject.dateTo, dataObject.timeTo)
        };
        return dataObjectToSave;
      }

      function inspectEventDialog(event) {
        let toMillisecondsStart = new Date(event.start).getTime();
        let toMillisecondsEnd = new Date(event.end).getTime();
        event.startTime = DateLocalizationService.LocalizationDateFn(
          toMillisecondsStart
        );
        event.endTime = DateLocalizationService.LocalizationDateFn(
          toMillisecondsEnd
        );
        event.title = TranslationService.GetCollectionById(
          'codelists.workScheduleTypes',
          event.type
        ).name;
        var items = [
          {
            text: event.startTime + ' - ' + event.endTime,
            type: 'text'
          }
        ];
        var actions = [
          {
            title: gettext('Remove'),
            fn: remove,
            color: 'warn'
          },
          {
            title: gettext('Update'),
            fn: edit,
            color: 'accent'
          }
        ];
        var header =
          scope.config.view.type === 'absence'
            ? gettext('Absence')
            : gettext('On duty');

        (function fetchUser() {
          UserModel.read({
            id: event.substituteUser
          }).then(function(res) {
            event.substituteUserName = res.data.name;
            event.substituteUserFamilyName = res.data.family_name;
            event.picture = res.data.picture;
            event.substituteUserImageUrl = StandardUtils.getUserImageUrl(
              event,
              'sm'
            );
            items.push({
              type: 'substituteUser',
              substituteUser: event.substituteUser,
              substituteUserName:
                event.substituteUserName +
                ' ' +
                (event.substituteUserFamilyName || ''),
              substituteUserImageUrl: event.substituteUserImageUrl
            });
            InfoDialog.open(header, null, items, actions);
          });
        })();

        function edit() {
          openEventDialog(event._id, null, null);
        }

        function remove() {
          $mdDialog.cancel();
          var items = [
            {
              text:
                scope.config.view.type === 'absence'
                  ? gettext('Do you want to delete absence?')
                  : gettext('Do you want to delete assignment?'),
              type: 'text'
            }
          ];
          var actions = [
            {
              title: gettext('Yes'),
              fn: removeWorkSchedule,
              color: 'warn'
            },
            {
              title: gettext('No'),
              cancel: true,
              color: 'primary'
            }
          ];
          var header =
            scope.config.view.type === 'absence'
              ? gettext('Delete absence')
              : gettext('Delete assignment');

          InfoDialog.open(header, null, items, actions);
        }

        function removeWorkSchedule() {
          WorkScheduleModel.delete({
            id: event._id
          }).then(
            function() {
              CrudToastFactory.toast('delete');
              scope.config.refreshData();
              $mdDialog.cancel();
            },
            function(err) {
              AlertingService.Error(err);
              $mdDialog.cancel();
            }
          );
        }
      }

      function inspectEvent(event) {
        inspectEventDialog(event);
      }

      function selectDay(start, end) {
        openEventDialog(null, start._d, end._d);
      }

      scope.create = function() {
        openEventDialog();
      };
    }
  };
}

export default workScheduleDirective;
