module.exports = {
  entity: 'price-list-item-groups',
  path: 'price-list-item-groups',
  name: {
    en: 'Price list details',
    sl_SI: 'Skupine postavk na ceniku'
  },
  networkModel: {
    entity: 'price-list-item-groups',
    read: 'read'
  },
  scope: 'tis_be:price-list-item-groups',

  dialog: {
    title: {
      en: 'Select price list item group',
      sl_SI: 'Izberite skupino postavk na ceniku'
    },
    listInfo: {
      en: 'Select price list item group from the list below',
      sl_SI: 'Izberite skupino postavk na ceniku s spodnjega seznama'
    }
  }
}
