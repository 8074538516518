import './sfe-date-input-filters.scss';
import template from './sfe-date-input.directive.html';

/**
 * @ngdoc directive
 * @name moduleName.sfeDateInput
 * @description description.
 * @param {object} parentForm - data from the form in which the directive is in
 * @param {string} uniqueId - unique id used for md-datepicker element.
 * @param {object} date - the selected date
 * @param {booelan} required - tells us if the value is required
 * @param {string} label - label used used by the directive
 * @param {object} min - lowest allowed date that can be selected by the directive
 * @param {object} max - highest allowed date that can be selected by the directive
 * @param {boolean} displayHours - Tells us if the directive can display time alongside the date
 * @param {function} onEnter - function that is called when you focus the input in the directive
 * @example
 * <sfe-date-input
 *   parent-form=""
 *   unique-id=""
 *   date=""
 *   required=""
 *   label=""
 *   min="vm.data.minDate"
 *   max="vm.data.maxDate"
 *   display-hours=""
 *   on-enter=""
 * ></sfe-date-input>
 */
sfeDateInput.$inject = [];

export default function sfeDateInput() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      parentForm: '=',
      uniqueId: '=',
      date: '=',
      required: '=',
      label: '=',
      min: '=',
      max: '=',
      displayHours: '<',
      onEnter: '<'
    },
    link: linkFn,
    controller: sfeDateInputController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  function linkFn(scope, el, attrs, ctrl) {
    ctrl.el = el;
    if (ctrl.parentForm) {
      scope.dateForm = ctrl.parentForm;
    }
  }
}

sfeDateInputController.$inject = ['DateAutocomplete', '$filter', '$timeout'];

function sfeDateInputController(DateAutocomplete, $filter, $timeout) {
  var vm = this;
  var inputtedDate;
  vm.setDate = setDate;
  vm.callAutoComplete = callAutoComplete;
  vm.timeChanged = timeChanged;
  vm.$onInit = init;
  /**
   * @description initilizes the directive
   * @function
   */
  function init() {
    if (vm.date) {
      if (vm.displayHours) {
        vm.time = formatTime(vm.date);
        $timeout(timeChanged);
      }
    }
  }
  /**
   * @description function that is called when you change the time
   * @function
   */
  function timeChanged() {
    if (vm.time) {
      var timeArray = vm.time.split(':');
      switch (timeArray.length) {
      case 2:
        vm.date.setHours(timeArray[0], timeArray[1], 0);
        break;
      case 3:
        vm.date.setHours(timeArray[0], timeArray[1], timeArray[2]);
      }
    }
  }
  /**
   * @description formats the date object to only show hour and minute.
   * @function
   * @param {object} binding/paramName
   */
  function formatTime(date) {
    return $filter('date')(date, 'HH:mm');
  }
  /**
  * @description sets the date, trying to autocomplete it.
  * @function
  * @param {string} inputtedDate - the string input in the input field

  */
  function setDate(inputtedDate) {
    // try to autocomplete date based on input
    vm.date = DateAutocomplete.get(inputtedDate);
  }
  /**
   * @description acquires the values from the directive input field and passes it to setDate function.
   * @function
   */
  function callAutoComplete() {
    //vm.date doesn't pass invalid value, which we need for autocomplete of dates
    //we get vm.el from linkFn
    inputtedDate = vm.el.find('input')[0].value;

    // only try to autocomplete date when field is not empty
    if (inputtedDate.length > 0) {
      setDate(inputtedDate);
    }
  }
}
