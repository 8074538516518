import './time-series-card.scss';
import template from './sfe-dashboard-values-card.directive.html';

function sfeDashboardValuesCard() {
  function linkFn(scope, el, attrs, ctrl) {
    ctrl.setSizes = setSizes;
    ctrl.resetStyle = resetStyle;
    function resetStyle() {
      var containers = el[0].querySelectorAll('.icon-value-container');
      var itemContainer;
      var item;
      (containers || []).forEach(function(container) {
        itemContainer = angular.element(container);
        item = angular.element(
          itemContainer[0].querySelector('.measurement-value')
        );
        if (item && item[0]) {
          item[0].removeAttribute('style');
          item[0].removeAttribute('title');
        }
      });
      /*************
      MEMORY CLEANUP
      *************/
      item = null;
      containers = null;
      itemContainer = null;
    }

    function setMaxHeight(item, height) {
      if (item && item[0]) {
        var style = item[0].getAttribute('style');
        if (style) {
          item[0].setAttribute(
            'style',
            style.replace(/max-height:(.*?);/g, '') +
              'max-height: ' +
              height +
              ';'
          );
        }
      }
    }

    function setTimeSeriesSize(item, maxHeight, fontSize) {
      if (item && item[0]) {
        const style = item[0].getAttribute('style');
        if (style) {
          item[0].setAttribute(
            'style',
            style.replace(/font-size:(.*?);/g, '') +
              'font-size: ' +
              fontSize +
              'px;'
          );
        }

        if (item[0].offsetHeight > maxHeight) {
          let newHeight;
          switch (fontSize) {
          case 56:
            newHeight = 50;
            break;
          case 50:
            newHeight = 40;
            break;
          case 40:
            newHeight = 36;
            break;
          case 36:
            newHeight = 28;
            break;
          case 28:
            newHeight = 20;
            break;
          case 20:
            newHeight = 18;
            break;
          case 18:
            newHeight = 14;
            break;
          case 14:
            newHeight = 12;
            break;
          }
          if (newHeight) {
            setTimeSeriesSize(item, maxHeight, newHeight);
          }
        }
      }
    }

    function setSizes(layoutIndex, itemIndex) {
      let layoutItem = ctrl.layouts[layoutIndex][itemIndex];
      let layoutItemContainer = el[0].querySelector(
        '.item-' + itemIndex + '.layout-' + layoutIndex
      );
      let icon = layoutItemContainer
        ? angular.element(layoutItemContainer.querySelector('sfe-icon'))
        : null;
      let description = layoutItemContainer
        ? angular.element(
          layoutItemContainer.querySelector('.measurement-card-description')
        )
        : null;
      let timeSeriesItem = layoutItemContainer
        ? angular.element(
          layoutItemContainer.querySelector('.measurement-value')
        )
        : null;
      let containerWidth = layoutItemContainer
        ? layoutItemContainer.offsetWidth
        : 0;
      let timeSeriesTextLength;
      if (layoutItem.lastValue) {
        let value =
          layoutItem.lastValue.value +
          ' ' +
          layoutItem.timeSeriesObject.readableMeasurementData;
        timeSeriesTextLength = value.length;
      }
      let layoutState;
      let displayIcon = layoutItem.details && layoutItem.details.icon;
      let displayDescription =
        layoutItem.details && layoutItem.details.description;
      if (displayIcon && !layoutItem.entityId && !displayDescription) {
        layoutState = 'OnlyIcon';
      } else if (displayIcon && layoutItem.entityId && !displayDescription) {
        layoutState = 'IconAndTimeSeries';
      } else if (displayIcon && !layoutItem.entityId && displayDescription) {
        layoutState = 'IconAndDescription';
      } else if (!displayIcon && layoutItem.entityId && displayDescription) {
        layoutState = 'TimeSeriesAndDescription';
      } else if (displayIcon && layoutItem.entityId && displayDescription) {
        layoutState = 'All';
      } else if (!displayIcon && layoutItem.entityId && !displayDescription) {
        layoutState = 'OnlyTimeSeries';
      } else {
        //samo description
        layoutState = 'OnlyDescription';
      }

      if (ctrl.size === 2 && ctrl.layouts.length === 1) {
        //ŠIROKA KARTICA, 1 STOLPEC
        switch (ctrl.layouts[layoutIndex].length) {
        case 1:
          switch (layoutState) {
          case 'All':
            checkIcon(icon, 'FS-Icon-Temp-4', containerWidth);
            setTimeSeriesSize(timeSeriesItem, 150, 56);
            break;
          case 'OnlyIcon':
            checkIcon(icon, 'FS-Icon-Temp-7', containerWidth);
            break;
          case 'OnlyTimeSeries':
            setTimeSeriesSize(timeSeriesItem, 300, 56);
            break;
          case 'OnlyDescription':
            setMaxHeight(description, '18em');
            break;
          case 'TimeSeriesAndDescription':
            setTimeSeriesSize(timeSeriesItem, 150, 56);
            setMaxHeight(description, '9.6em');
            break;
          case 'IconAndDescription':
            setMaxHeight(description, '9.6em');
            break;
          case 'IconAndTimeSeries':
            checkIcon(icon, 'FS-Icon-Temp-4', containerWidth);
            setTimeSeriesSize(timeSeriesItem, 300, 56);
            break;
          }

          break;
        case 2:
          switch (layoutState) {
          case 'All':
            checkIcon(icon, 'FS-Icon-Temp-2-4', containerWidth);
            setTimeSeriesSize(timeSeriesItem, 60, 28);
            break;
          case 'OnlyIcon':
            checkIcon(icon, 'FS-Icon-Temp-3', containerWidth);
            break;
          case 'OnlyTimeSeries':
            setTimeSeriesSize(timeSeriesItem, 150, 40);
            break;
          case 'OnlyDescription':
            setMaxHeight(description, '9.6em');
            break;
          case 'TimeSeriesAndDescription':
            break;
          case 'IconAndDescription':
            break;
          case 'IconAndTimeSeries':
            setTimeSeriesSize(timeSeriesItem, 150, 40);
            checkIcon(icon, 'FS-Icon-Temp-2-4', containerWidth);
            break;
          }
          break;
        case 3:
          switch (layoutState) {
          case 'All':
            if (containerWidth < 150 && timeSeriesTextLength > 8) {
              checkIcon(icon, 'FS-Icon-Temp-1', containerWidth);
            } else {
              checkIcon(icon, 'FS-Icon-Temp-2-4', containerWidth);
            }
            setTimeSeriesSize(timeSeriesItem, 40, 20);
            break;
          case 'OnlyIcon':
            checkIcon(icon, 'FS-Icon-Temp-3', containerWidth);
            break;
          case 'OnlyTimeSeries':
            setTimeSeriesSize(timeSeriesItem, 100, 28);
            break;
          case 'OnlyDescription':
            setMaxHeight(description, '6em');
            break;
          case 'TimeSeriesAndDescription':
            break;
          case 'IconAndDescription':
            break;
          case 'IconAndTimeSeries':
            checkIcon(icon, 'FS-Icon-Temp-2-4', containerWidth);
            setTimeSeriesSize(timeSeriesItem, 100, 28);
            break;
          }
          break;
        }
      } else if (ctrl.size === 2 && ctrl.layouts.length === 2) {
        //ŠIROKA KARTICA 2 STOLPCA
        switch (ctrl.layouts[layoutIndex].length) {
        case 1:
          switch (layoutState) {
          case 'All':
            checkIcon(icon, 'FS-Icon-Temp-4', containerWidth);
            setTimeSeriesSize(timeSeriesItem, 150, 56);
            break;
          case 'OnlyIcon':
            checkIcon(icon, 'FS-Icon-Temp-7', containerWidth);
            break;
          case 'OnlyTimeSeries':
            setTimeSeriesSize(timeSeriesItem, 300, 56);
            break;
          case 'OnlyDescription':
            setMaxHeight(description, '18em');
            break;
          case 'TimeSeriesAndDescription':
            setMaxHeight(description, '9.6em');
            break;
          case 'IconAndDescription':
            setMaxHeight(description, '9.6em');
            break;
          case 'IconAndTimeSeries':
            checkIcon(icon, 'FS-Icon-Temp-4', containerWidth);
            setTimeSeriesSize(timeSeriesItem, 300, 56);
            break;
          }

          break;
        case 2:
          switch (layoutState) {
          case 'All':
            checkIcon(icon, 'FS-Icon-Temp-2-4', containerWidth);
            setTimeSeriesSize(timeSeriesItem, 60, 28);
            break;
          case 'OnlyIcon':
            checkIcon(icon, 'FS-Icon-Temp-3', containerWidth);
            break;
          case 'OnlyTimeSeries':
            setTimeSeriesSize(timeSeriesItem, 150, 40);
            break;
          case 'OnlyDescription':
            setMaxHeight(description, '9.6em');
            break;
          case 'TimeSeriesAndDescription':
            break;
          case 'IconAndDescription':
            break;
          case 'IconAndTimeSeries':
            setTimeSeriesSize(timeSeriesItem, 150, 40);
            checkIcon(icon, 'FS-Icon-Temp-2-4', containerWidth);
            break;
          }
          break;
        case 3:
          switch (layoutState) {
          case 'All':
            if (containerWidth < 150 && timeSeriesTextLength > 8) {
              checkIcon(icon, 'FS-Icon-Temp-1', containerWidth);
            } else {
              checkIcon(icon, 'FS-Icon-Temp-2-4', containerWidth);
            }
            setTimeSeriesSize(timeSeriesItem, 40, 18);
            break;
          case 'OnlyIcon':
            checkIcon(icon, 'FS-Icon-Temp-3', containerWidth);
            break;
          case 'OnlyTimeSeries':
            setTimeSeriesSize(timeSeriesItem, 100, 28);
            break;
          case 'OnlyDescription':
            break;
          case 'TimeSeriesAndDescription':
            break;
          case 'IconAndDescription':
            break;
          case 'IconAndTimeSeries':
            if (containerWidth < 200 && timeSeriesTextLength > 8) {
              checkIcon(icon, 'FS-Icon-Temp-1', containerWidth);
              setTimeSeriesSize(timeSeriesItem, 90, 18);
            } else {
              checkIcon(icon, 'FS-Icon-Temp-2-4', containerWidth);
              setTimeSeriesSize(timeSeriesItem, 90, 20);
            }
            break;
          }
          break;
        }
      } else {
        //MALA KARTICA
        switch (ctrl.layouts[layoutIndex].length) {
        case 1:
          switch (layoutState) {
          case 'All':
            checkIcon(icon, 'FS-Icon-Temp-4', containerWidth);
            setTimeSeriesSize(timeSeriesItem, 150, 56);
            break;
          case 'OnlyIcon':
            checkIcon(icon, 'FS-Icon-Temp-7', containerWidth);
            break;
          case 'OnlyTimeSeries':
            setTimeSeriesSize(timeSeriesItem, 300, 56);
            break;
          case 'OnlyDescription':
            setMaxHeight(description, '18em');
            break;
          case 'TimeSeriesAndDescription':
            setMaxHeight(description, '9.6em');
            break;
          case 'IconAndDescription':
            checkIcon(icon, 'FS-Icon-Temp-4', containerWidth);
            setMaxHeight(description, '9.6em');
            break;
          case 'IconAndTimeSeries':
            checkIcon(icon, 'FS-Icon-Temp-4', containerWidth);
            setTimeSeriesSize(timeSeriesItem, 300, 56);
            break;
          }
          break;
        case 2:
          switch (layoutState) {
          case 'All':
            checkIcon(icon, 'FS-Icon-Temp-2-4', containerWidth);
            setTimeSeriesSize(timeSeriesItem, 60, 28);
            break;
          case 'OnlyIcon':
            checkIcon(icon, 'FS-Icon-Temp-3', containerWidth);
            break;
          case 'OnlyTimeSeries':
            setTimeSeriesSize(timeSeriesItem, 150, 40);
            break;
          case 'OnlyDescription':
            setMaxHeight(description, '9.6em');
            break;
          case 'TimeSeriesAndDescription':
            break;
          case 'IconAndDescription':
            setMaxHeight(description, '9.6em');
            break;
          case 'IconAndTimeSeries':
            setTimeSeriesSize(timeSeriesItem, 150, 40);
            checkIcon(icon, 'FS-Icon-Temp-2-4', containerWidth);
            break;
          }

          break;
        case 3:
          switch (layoutState) {
          case 'All':
            checkIcon(icon, 'FS-Icon-Temp-1', containerWidth);
            setTimeSeriesSize(timeSeriesItem, 40, 18);
            break;
          case 'OnlyIcon':
            checkIcon(icon, 'FS-Icon-Temp-3', containerWidth);
            break;
          case 'OnlyTimeSeries':
            setTimeSeriesSize(timeSeriesItem, 100, 28);
            break;
          case 'OnlyDescription':
            break;
          case 'TimeSeriesAndDescription':
            setTimeSeriesSize(timeSeriesItem, 40, 18);
            break;
          case 'IconAndDescription':
            break;
          case 'IconAndTimeSeries':
            if (containerWidth < 200 && timeSeriesTextLength > 8) {
              checkIcon(icon, 'FS-Icon-Temp-1', containerWidth);
              setTimeSeriesSize(timeSeriesItem, 90, 18);
            } else {
              checkIcon(icon, 'FS-Icon-Temp-2-4', containerWidth);
              setTimeSeriesSize(timeSeriesItem, 90, 20);
            }
            break;
          }

          break;
        }
      }
      /*************
      MEMORY CLEANUP
      *************/
      layoutItemContainer = null;
      icon = null;
      description = null;
      timeSeriesItem = null;
    }

    function checkIcon(item, size, parentWidth) {
      item.removeClass('FS-Icon-1');
      switch (size) {
      case 'FS-Icon-Temp-7':
        item.addClass('FS-Icon-7');
        if (parentWidth < 140) {
          item.removeClass('FS-Icon-7');
          item.addClass('FS-Icon-4');
          checkIcon(item, 'FS-Icon-Temp-4', parentWidth);
        }
        break;
      case 'FS-Icon-Temp-4':
        item.addClass('FS-Icon-4');
        if (parentWidth < 80) {
          item.removeClass('FS-Icon-4');
          item.addClass('FS-Icon-3');
          checkIcon(item, 'FS-Icon-Temp-3', parentWidth);
        }
        break;
      case 'FS-Icon-Temp-3':
        item.addClass('FS-Icon-3');
        if (parentWidth < 50) {
          item.removeClass('FS-Icon-3');
          item.addClass('FS-Icon-2-4');
          checkIcon(item, 'FS-Icon-Temp-2-4', parentWidth);
        }
        break;
      case 'FS-Icon-Temp-2-4':
        item.addClass('FS-Icon-2-4');
        if (parentWidth < 36) {
          item.removeClass('FS-Icon-2-4');
          item.addClass('FS-Icon-1');
        }
        break;
      default:
        item.addClass('FS-Icon-1');
      }
      return;
    }
  }
  return {
    template,
    scope: {
      items: '<',
      bgColor: '<',
      size: '<',
      cacheInvalidationTime: '<?',
      refresher: '<?',
      name: '<?'
    },
    controller: SfeDashboardTimeSeriesController,
    controllerAs: 'vm',
    link: linkFn,
    bindToController: true
  };
}

SfeDashboardTimeSeriesController.$inject = [
  '$scope',
  'AlertingService',
  'TimeSeriesProcessingValuesModel',
  'ColorService',
  '$timeout',
  '$window',
  'Refreshing',
  'Formatting',
  'TimeSeriesModel',
  'TranslationService'
];

function SfeDashboardTimeSeriesController(
  $scope,
  AlertingService,
  TimeSeriesProcessingValuesModel,
  ColorService,
  $timeout,
  $window,
  Refreshing,
  Formatting,
  TimeSeriesModel,
  TranslationService
) {
  const vm = this;
  let refresherId, refresherFnId;

  angular.element($window).on('resize', assignStyles);

  function cleanUp() {
    angular.element($window).off('resize', assignStyles);
  }

  vm.$onChanges = function(changed) {
    if (changed.items) {
      setItemsToLayouts();
    }
    if (vm.refresher) {
      ({ refresherId, refresherFnId } = vm.refresher(setItemsToLayouts));
    }
  };

  $scope.$on('$destroy', function() {
    cleanUp();
    if (refresherId) {
      Refreshing.removeFn(refresherId, refresherFnId);
    }
  });

  async function setItemsToLayouts() {
    vm.noLayouts = vm.items.length < 1;
    vm.isDarkBackground = ColorService.IsColorDark(vm.bgColor);
    vm.items.sort(function(a, b) {
      return a.order - b.order;
    });
    let layouts = [];

    let timeSeriesIds = [];
    let layoutItems = vm.items.filter(function(item) {
      item.uniqId = Math.random()
        .toString(36)
        .substring(2);
      timeSeriesIds.push({
        entityId: item.entityId,
        uniqId: item.uniqId
      });
      return item.order === 0 || item.order === 1 || item.order === 2;
    });
    if (layoutItems && layoutItems.length > 0) {
      layouts.push(layoutItems);
    }

    if (vm.size === 2) {
      layoutItems = vm.items.filter(function(item) {
        return item.order === 3 || item.order === 4 || item.order === 5;
      });
      if (layoutItems && layoutItems.length > 0) {
        layouts.push(layoutItems);
      }
    }

    vm.layouts = layouts;
    if (timeSeriesIds.length) {
      const timeSeries = await fetchTimeSeriesValues(timeSeriesIds);
      assignTimeSeries(timeSeries);
      assignStyles();
    } else {
      // if there are no dashboard-card-entities display empty card
      vm.displayLayouts = true;
    }
  }

  async function fetchTimeSeriesValue(timeSeriesId) {
    try {
      let { data } = await TimeSeriesModel.read(
        { id: timeSeriesId, populate: 'measurementUnit' },
        vm.cacheInvalidationTime
      );
      let metricPrefix = TranslationService.GetCollectionById(
        'codelists.metricPrefixes',
        data.metricPrefix
      );
      let readableMeasurementData = '';
      if (
        data.measurementUnit != null &&
        typeof data.measurementUnit == 'object'
      ) {
        readableMeasurementData = `${
          metricPrefix != null ? metricPrefix.symbol : ''
        } ${data.measurementUnit.symbol}`;
      }
      let { data: values } = await TimeSeriesProcessingValuesModel.read(
        {
          timeSeriesId,
          limit: 1,
          view: 'simple'
        },
        vm.cacheInvalidationTime
      );
      let value;
      if (Array.isArray(values) && values.length > 0 && values[0] != null) {
        value = Formatting.formatDatapointDisplay(
          data.dataType,
          values[0].value,
          data.precision
        );
        return {
          readableMeasurementData,
          value
        };
      }
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  async function fetchTimeSeriesValues(items) {
    let indexes = items.map(item => item.uniqId);
    const promises = items.map(function(item) {
      return fetchTimeSeriesValue(item.entityId);
    });
    try {
      const timeSeriesValues = await Promise.all(promises);
      return timeSeriesValues.reduce((result, timeSeriesValue, index) => {
        return {
          ...result,
          [indexes[index]]: timeSeriesValue
        };
      }, {});
    } catch (err) {
      AlertingService.Error(err);
      return {};
    }
  }

  function assignStyles() {
    let isBlack = ColorService.IsColorDark(vm.bgColor);
    let defaultColor;
    if (isBlack) {
      defaultColor = '#fff';
    } else {
      defaultColor = '#3b3b3b';
    }
    if (vm.layouts && vm.layouts[0] && vm.layouts[0].length === 0) {
      vm.layouts.splice(0, 1);
    }
    vm.layouts.forEach(function(layout) {
      layout.forEach(function(item) {
        item.textColor = item.details.color ? item.details.color : defaultColor;
        item.timeSeriesStyle = {
          color: item.textColor || defaultColor
        };
        item.descriptionStyle = {
          color: defaultColor
        };
        item.unitStyle = {
          color: item.textColor || defaultColor
        };
      });
    });

    vm.displayLayouts = true;
    $timeout(callAlign);
  }

  function callAlign() {
    vm.layouts.forEach(function(layout, layoutIndex) {
      layout.forEach(function(item, index) {
        $timeout(() => {
          vm.setSizes(layoutIndex, index);
        });
      });
    });
  }

  function assignTimeSeries(timeSeriesObject) {
    let lastValue;
    vm.layouts.forEach(function(layout) {
      layout.forEach(function(item) {
        item.style = {
          color: item.textColor
        };
        if (timeSeriesObject && timeSeriesObject[item.uniqId] != null) {
          item.readableMeasurementData =
            timeSeriesObject[item.uniqId].readableMeasurementData;
          lastValue =
            timeSeriesObject[item.uniqId].value != null
              ? { value: timeSeriesObject[item.uniqId].value }
              : { value: '--' };
        } else {
          lastValue = { value: '--' };
        }
        item.lastValueObject = lastValue;
      });
    });
  }
}

export default sfeDashboardValuesCard;
