loadDashboardModules.$inject = ['$q', 'loadAssets'];
/**
 * @ngdoc service
 * @name common.loadDashboardModules
 * @description checks dashboard card type and loads assets if needed.
 * @property {function} loadModules
 */

export default function loadDashboardModules($q, loadAssets) {
  /**
   * @description checks dashboard card type and loads assets if needed.
   * @function
   * @param {array} elements array of dashboardCards
   * @return {Promise}
   */
  function loadModules(elements) {
    var deferred = $q.defer();
    var mxClientIsLoading;
    var HighchartsLoading;
    var promises = {};
    var mxClient = 'mxClient';
    var highcharts = 'highcharts';

    elements.forEach(function(element) {
      switch (element.dashboardCardType) {
      case 12:
        if (!mxClientIsLoading) {
          mxClientIsLoading = true;
          promises[mxClient] = true;
          loadAssets([mxClient]).then(
            function() {
              delete promises[mxClient];
              if (!Object.keys(promises).length) {
                deferred.resolve(mxClient);
              } else {
                deferred.notify(mxClient);
              }
            },
            function() {
              delete promises[mxClient];
              if (!Object.keys(promises).length) {
                deferred.reject(mxClient);
              }
            }
          );
        }
        break;
      case 1:
      case 2:
      case 3:
      case 4:
      case 6:
      case 7:
      case 8:
      case 9:
      case 11:
      case 15:
        if (!HighchartsLoading) {
          HighchartsLoading = true;
          promises[highcharts] = true;
          loadAssets([highcharts]).then(
            function() {
              delete promises[highcharts];
              if (!Object.keys(promises).length) {
                deferred.resolve(highcharts);
              } else {
                deferred.notify(highcharts);
              }
            },
            function() {
              delete promises[mxClient];
              if (!Object.keys(promises).length) {
                deferred.reject(highcharts);
              }
            }
          );
        }
        break;
      }
    });
    return deferred.promise;
  }

  return loadModules;
}
