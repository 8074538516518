import { RouteManager, gettext } from '../routes';

import BasicMapView from './pages/basic-map/basic-map.view.html';
import GisMapView from './pages/gis-map/gis-map.view.html';
import SfeListView from '../common/components/sfe-list/sfe-list.view.html';

loadMarkerCluster.$inject = ['loadAssets'];
function loadMarkerCluster(loadAssets) {
  return loadAssets(['markerCluster']);
}

loadGisMaps.$inject = ['loadAssets'];
function loadGisMaps(loadAssets) {
  return loadAssets(['gisSdk']);
}

let MapsRouteManager = new RouteManager();
MapsRouteManager.state('maps', {
  url: '/gis',
  isMainMenuItem: true,
  menuIndex: 8,
  pageTitle: gettext('Maps'),
  icon: 'place',
  redirectTo: 'maps-gis-maps',
  theme: 'tango',
  accessScopeFe: 'tis_fe:maps-module',
  accessScopeBe: 'tis_fe:maps-module'
})
  // MAP of company-resources
  .state('maps-basic-maps', {
    url: '/maps/basic-maps',
    template: BasicMapView,
    controller: 'BasicMapController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Basic Map'),
    icon: 'location_on',
    parentState: 'maps',
    theme: 'tango',
    accessScopeFe: 'tis_fe:basic-maps',
    accessScopeBe: 'tis_be:locations',
    resolve: {
      loadScripts: loadMarkerCluster
    }
  })
  .state('maps-gis-maps', {
    url: '/maps/gis-maps',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('GIS Maps'),
    parentState: 'maps',
    theme: 'tango',
    accessScopeFe: 'tis_fe:gis-maps',
    accessScopeBe: 'tis_fe:gis-maps'
  })
  .state('maps-gis-maps-view', {
    url: '/maps/gis-maps/{id}',
    template: GisMapView,
    controller: 'GisMapController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('GIS Maps'),
    parentState: 'maps',
    theme: 'tango',
    accessScopeFe: 'tis_fe:gis-maps',
    accessScopeBe: 'tis_fe:gis-maps',
    mode: 'create',
    resolve: {
      loadScripts: loadGisMaps
    }
  });

export default MapsRouteManager.routes;
