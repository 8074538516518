import template from './sfe-dashboard-measurement-card-selector.directive.html';
import './sfe-dashboard-measurement-card-selector.scss';
function sfeMeasurementCardSelector() {
  function linkFn(scope, el, attrs, ctrl) {
    ctrl.alignTitles = alignTitles;
    ctrl.alignVertically = alignVertically;

    function alignTitles() {
      var titles = el[0].querySelectorAll(
        '.value-card > div > span:first-child'
      );
      if (titles && titles.length) {
        var maxWidth = 0;
        titles.forEach(function(title) {
          maxWidth = Math.max(title.clientWidth, maxWidth);
        });
        titles.forEach(function(title) {
          angular.element(title).css('min-width', maxWidth + 'px');
        });
      }
      /*************
      MEMORY CLEANUP
      *************/
      titles = null;
    }

    function alignVertically() {
      var layouts = el[0].querySelectorAll(
        'sfe-measurement-card-selector .layout-container'
      );
      if (layouts && layouts.length === 2) {
        var leftCards = layouts[0].querySelectorAll('.value-card');
        var rightCards = layouts[1].querySelectorAll('.value-card');
        if (leftCards && leftCards.length && rightCards) {
          leftCards.forEach(function(card, index) {
            if (rightCards[index]) {
              if (card.clientHeight > rightCards[index].clientHeight) {
                angular
                  .element(rightCards[index])
                  .css('height', card.clientHeight + 'px');
                angular.element(card).css('height', card.clientHeight + 'px');
              } else if (card.clientHeight < rightCards[index].clientHeight) {
                angular
                  .element(card)
                  .css('height', rightCards[index].clientHeight + 'px');
                angular
                  .element(rightCards[index])
                  .css('height', rightCards[index].clientHeight + 'px');
              }
            }
          });
        }
        /*************
        MEMORY CLEANUP
        *************/
        leftCards = null;
        rightCards = null;
      }
      /*************
      MEMORY CLEANUP
      *************/
      layouts = null;
    }
  }
  return {
    template,
    scope: {
      model: '=?',
      configuration: '<',
      dashboardCardId: '<?',
      createDashboardCard: '<',
      edit: '<?',
      itemForm: '<',
      changeOrderCallback: '<',
      getDashboardCard: '<'
    },
    controller: sfeDashboardController,
    controllerAs: 'vm',
    link: linkFn,
    bindToController: true
  };
}

sfeDashboardController.$inject = [
  'DashboardCardModel',
  'gettext',
  'AlertingService',
  '$timeout',
  '$q',
  'TimeSeriesValuesDashboardCard',
  'ToastService',
  '$scope',
  'InfoDialog',
  '$mdDialog'
];

function sfeDashboardController(
  DashboardCardModel,
  gettext,
  AlertingService,
  $timeout,
  $q,
  TimeSeriesValuesDashboardCard,
  ToastService,
  $scope,
  InfoDialog,
  $mdDialog
) {
  var vm = this;
  vm.formItemName = Math.random()
    .toString(36)
    .substring(2);
  vm.addRow = addRow;
  vm.addItem = addItem;
  vm.removeLayout = removeLayout;
  vm.dragoverCallback = dragoverCallback;
  vm.removeItem = removeItem;
  vm.startEditingItem = startEditingItem;
  vm.reorderItems = reorderItems;
  vm.dragging = dragging;
  var draggedNow;

  vm.$onInit = function() {
    vm.displayItems = false;
    if (vm.ItemForm) {
      $scope.valuesForm = vm.ItemForm;
    }
    if (vm.edit) {
      TimeSeriesValuesDashboardCard.getCardObject(
        vm.model,
        vm.dashboardCardId
      ).then(
        function(entities) {
          vm.model = [[]];

          if (entities) {
            entities.sort(function(entity1, entity2) {
              return entity1.order > entity2.order;
            });
            entities.forEach(function(entity) {
              if (entity.order < 3) {
                vm.model[0].push(entity);
              } else {
                if (!vm.model[1]) {
                  vm.model.push([]);
                }
                vm.model[1].push(entity);
              }
            });
            vm.displayItems = true;
            alignItems();
          }
        },
        function(err) {
          AlertingService.Error(err);
        }
      );
    } else {
      vm.displayItems = true;
    }
  };

  function alignItems() {
    $timeout(vm.alignTitles);
    if (vm.model.length === 2) {
      $timeout(vm.alignVertically);
    }
  }

  function dragging(item) {
    draggedNow = item;
  }

  async function reorderItems() {
    vm.reorderingItems = !vm.reorderingItems;
    if ($scope.valuesForm) {
      $scope.valuesForm.$setValidity(vm.formItemName, !vm.reorderingItems);
    }
    if (vm.changeOrderCallback) {
      vm.changeOrderCallback(vm.reorderingItems);
    }
    // set form validity to false when editing items order
    vm.itemForm.$setValidity('reorderingItems', !vm.reorderingItems);
    if (!vm.reorderingItems) {
      if (vm.dashboardCardId) {
        try {
          await TimeSeriesValuesDashboardCard.updateMeasurementDashboardCardOrder(
            vm.model,
            vm.dashboardCardId
          );
          ToastService.showToast('Items were successfully reordered');
        } catch (err) {
          AlertingService.Error(err);
        }
      } else {
        AlertingService.Error('No card ID');
      }
    }
  }

  function startEditingItem(item) {
    item.copy = angular.copy(item);
    item.dashboardCardId = vm.dashboardCardId;
    TimeSeriesValuesDashboardCard.openCardEditForm(item, vm.model).then(
      function(changedItem) {
        ToastService.showToast(gettext('Value card was successfully updated'));
        TimeSeriesValuesDashboardCard.getCardObject([changedItem]).then(
          function(displayObject) {
            vm.model.forEach(function(model, layoutIndex) {
              model.forEach(function(valueItem, itemIndex) {
                if (
                  valueItem.apiItem &&
                  valueItem.apiItem._id === changedItem._id
                ) {
                  vm.model[layoutIndex][itemIndex] = displayObject[0];
                }
              });
            });
            alignItems();
          }
        );
      },
      function(err) {
        if (err) {
          AlertingService.Error(err);
        }
      }
    );
  }

  function dragoverCallback(index, model) {
    if (draggedNow && draggedNow.apiItem) {
      var found = model.find(function(item) {
        return item.apiItem._id === draggedNow.apiItem._id;
      });
      if (found) {
        return true;
      }
    }
    return model.length < vm.configuration.maxItemsPerLayout;
  }

  function removeLayout(layoutIndex) {
    const entities = vm.model.reduce((result, layout, index) => {
      if (index != layoutIndex) {
        return layout.map((layoutItem, layoutIndex) => ({
          ...layoutItem.apiItem,
          order: layoutIndex
        }));
      }
      return result;
    }, []);

    async function remove() {
      try {
        await DashboardCardModel.update(
          {
            id: vm.dashboardCardId
          },
          { entities }
        );
        vm.model.splice(layoutIndex, 1);
        ToastService.showToast('Items were successfully reordered');
      } catch (err) {
        AlertingService.Error(err);
      }
      $mdDialog.hide();
    }
    var title = gettext('Remove layout');
    var textItem = {
      text: gettext('Are you sure that you want to remove layout?'),
      type: 'text'
    };
    var actions = [
      {
        title: gettext('Cancel'),
        cancel: true,
        color: 'primary'
      },
      {
        title: gettext('Yes'),
        fn: remove,
        color: 'warn'
      }
    ];

    InfoDialog.open(title, null, [textItem], actions);
  }
  /**
   * @description Calls delete to dashboard-card-entity endpoint.
   * @function
   * @param {Object} apiObject delete parameters
   * @return {Promise}
   */
  function removeEntity(id, apiObj) {
    var deferred = $q.defer();
    DashboardCardModel.update({ id }, apiObj).then(
      function() {
        deferred.resolve();
      },
      function(err) {
        deferred.reject(err);
      }
    );
    return deferred.promise;
  }

  /**
   * @description flats layout model array and maps it to api model.
   * @function
   * @return {Array}
   */
  function getEntities() {
    let entities = [];
    if (Array.isArray(vm.model[0])) {
      entities = [...vm.model[0]];
    }
    if (Array.isArray(vm.model[1])) {
      entities = [...entities, ...vm.model[1]];
    }
    entities = entities.map(item => item.apiItem);
    return entities;
  }
  /**
   * @description Opens prompt dialog to delete item and if approved deletes item.
   * @function
   */
  function removeItem(layout, itemIndex) {
    const idToDelete = layout[itemIndex].apiItem._id;
    const entities = vm.model.reduce((result, layout, layoutIndex) => {
      if (layout) {
        const layoutItems = layout.reduce((layoutResult, item, itemIndex) => {
          if (item.apiItem._id === idToDelete) {
            return layoutResult;
          }
          return [
            ...layoutResult,
            {
              ...item.apiItem,
              order: layoutIndex * 3 + itemIndex
            }
          ];
        }, []);
        return [...result, ...layoutItems];
      }
      return result;
    }, []);

    const mainCardApiObject = getMainCard();

    function remove() {
      removeEntity(vm.dashboardCardId, {
        ...mainCardApiObject,
        entities
      })
        .then(
          function() {
            ToastService.showToast(gettext('Item was successfully removed.'));
            layout.splice(itemIndex, 1);
          },
          function(err) {
            AlertingService.Error(err);
          }
        )
        .then(function() {
          $mdDialog.hide();
        });
    }
    var title = gettext('Remove item');
    var textItem = {
      text: gettext('Are you sure you want to remove the item?'),
      type: 'text'
    };
    var actions = [
      {
        title: gettext('Cancel'),
        cancel: true,
        color: 'primary'
      },
      {
        title: gettext('Yes'),
        fn: remove,
        color: 'warn'
      }
    ];

    InfoDialog.open(title, null, [textItem], actions);
  }

  function addRow() {
    if (!vm.model) {
      vm.model = [];
    }
    vm.model.push([]);
  }

  function pushValueItem(layoutIndex, item) {
    TimeSeriesValuesDashboardCard.getCardObject([item]).then(function(
      displayObject
    ) {
      vm.model[layoutIndex].push(displayObject[0]);
      alignItems();
    });
  }
  /**
   * @description constructs api value for dashboard card (size, main linked dashboard etc).
   * @function
   * @return {object}
   */
  function getMainCard() {
    let mainCardApiObject = {};
    if (typeof vm.getDashboardCard === 'function') {
      const mainCard = vm.getDashboardCard();
      if (mainCard != null) {
        mainCardApiObject = {
          dashboardCardSize: mainCard.cardSize?.id,
          linkedDashboard: mainCard.linkedDashboard,
          details: {
            bgColor: mainCard.color
          }
        };
      }
    }
    return mainCardApiObject;
  }

  function addItem(index) {
    TimeSeriesValuesDashboardCard.openCardNewForm().then(
      function(item) {
        if (typeof item == 'object' && item != null) {
          var postObj = {
            details: {
              description: item.description,
              color: item.color,
              icon: item.icon
            },
            linkedDashboard: item.linkedDashboard
              ? item.linkedDashboard._id
              : null,
            entityId: item.timeSeries ? item.timeSeries._id : null,
            entityType: 234, //time series
            order: index * 3 + vm.model[index].length
          };

          let entities = getEntities();
          entities = [...entities, postObj].sort(
            (item1, item2) => item1.order - item2.order
          );
          const mainCardApiObject = getMainCard();

          TimeSeriesValuesDashboardCard.saveMeasurement(
            {
              entities,
              ...mainCardApiObject
            },
            vm.dashboardCardId,
            vm.createDashboardCard
          ).then(
            function(result) {
              const valueCards = result.entities;
              const dashboardCardId = result.dashboardCardId;
              if (dashboardCardId) {
                vm.dashboardCardId = dashboardCardId;
              }

              const card = valueCards.find(item => item.order == postObj.order);
              pushValueItem(index, card);
            },
            function(err) {
              AlertingService.Error(err);
            }
          );
        }
      },
      function(err) {
        if (err) {
          AlertingService.Error(err);
        }
      }
    );
  }
}

export default sfeMeasurementCardSelector;
