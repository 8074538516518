/**
 * @ngdoc directive
 * @name moduleName.sfeForm2BoundaryOverlap
 * @description function that checks, if the input value overlaps with other input values.
 * @param {Object} - the boundary passed to the directive
 * @param {Array} -the array of boundaries
 * @example
 * <input
 *  type="numerical"
 *  sfeForm2BoundaryOverlap
 *  boundary=boundary.to
 *  boundaries="vm.modelValues"
 * ></input>
 */
sfeForm2BoundaryOverlap.$inject = ['$timeout'];
export default function sfeForm2BoundaryOverlap() {
  return {
    restrict: 'A',
    require: ['ngModel', '^form'],
    scope: {
      boundary: '<',
      boundaries: '<'
    },
    link: function(scope, element, attrs, controllers) {
      const model = controllers[0];
      /**
       * @description function that is passed to the controller validator. It checks, if the current value overlaps with its neighbours
       * @function
       * @param {Object} boundary - the boundary passed to the directive
       * @param {Array} boundaries - the array of boundaries
       * @return {boolean} returns true if there is no overlap or false if overlap exists
       */
      const overlapValidation = () => {
        const boundary = angular.copy(scope.boundary);
        const boundaries = angular.copy(scope.boundaries).sort((a, b) => {
          //we want all values where from is null or '' to be placed last
          if (a.from === '' || a.from == null) {
            return 1;
          }
          if (b.from === '' || b.from == null) {
            return -1;
          }
          return a.from - b.from;
        });
        const indexOfCurrentBoundary = boundaries.findIndex(
          item =>
            item.from === boundary.from &&
            item.to === boundary.to &&
            item.color === boundary.color
        );
        if (indexOfCurrentBoundary < 0) {
          return true;
        }
        if (attrs.ngModel === 'boundary.from') {
          if (indexOfCurrentBoundary === 0) {
            return true;
          }
          if (
            typeof model.$modelValue == 'number' &&
            typeof boundaries[indexOfCurrentBoundary - 1].to == 'number'
          ) {
            if (model.$modelValue > boundaries[indexOfCurrentBoundary - 1].to) {
              return true;
            }
            return false;
          }
        }
        if (attrs.ngModel === 'boundary.to') {
          if (indexOfCurrentBoundary === boundaries.length - 1) {
            return true;
          }
          if (
            typeof model.$modelValue == 'number' &&
            typeof boundaries[indexOfCurrentBoundary + 1].from == 'number'
          ) {
            if (
              model.$modelValue < boundaries[indexOfCurrentBoundary + 1].from
            ) {
              return true;
            }
            return false;
          }
        }
        return true;
      };
      model.$validators = {
        ...model.$validators,
        overlap: overlapValidation
      };
    }
  };
}
