import { DateTime } from 'luxon';

WeatherDashboardItemService.$inject = [
  'AlertingService',
  'CrawlerMethods',
  'gettext',
  '$filter',
  'TranslationService',
  'StandardUtils',
  'gettextCatalog',
  '$rootScope',
  'LocationModel',
  'CachingParams'
];
/**
 * @ngdoc service
 * @name common.WeatherDashboardItemService
 * @description Helper for the weather dashboard item controller.
 * @property {function} findTemperatureForecast,
 * @property {function} configureValuesNetworkModels,
 * @property {function} setForecastWeatherConfiguration,
 * @property {function} calculateCurrentAverage,
 * @property {function} constructGraph,
 * @property {function} getValues,
 * @property {function} getWeatherStationView,
 * @property {function} initForecastPlaceholders,
 * @property {function} getAverageTemperaturesPlaceholders,
 * @property {function} getCurrentWeatherState
 */
export default function WeatherDashboardItemService(
  AlertingService,
  CrawlerMethods,
  gettext,
  $filter,
  TranslationService,
  StandardUtils,
  gettextCatalog,
  $rootScope,
  LocationModel,
  CachingParams
) {
  /**
   * @memberof common.WeatherDashboardItemService
   * @name findTemperatureForecast
   * @description Prepares the method to get values of forecast for chart component.
   * @function
   * @param {Object} sources - sources from which to get forecast one for later values fetching
   * @return {Function}
   */
  function findTemperatureForecast(sources, cacheInvalidationTime, uniqueId) {
    const forecastSource = sources['long_forecast_temperature'];
    if (forecastSource) {
      const pointType = 'normal';
      const now = DateTime.fromJSDate(new Date());
      const filterObject = {
        timeSeriesId: forecastSource.pointId,
        view: 'simple',
        from: now.toMillis(),
        to: now
          .plus({ day: 5 })
          .endOf('day')
          .toMillis()
      };
      const method = CrawlerMethods.getMethod({
        entity: forecastSource.entity,
        method: 'read'
      });
      return (filter, manualRefresh) => {
        const invalidationTime = manualRefresh ? 0 : cacheInvalidationTime;
        return new Promise(async resolve => {
          try {
            const { data } = await method(
              CachingParams.CheckParams(
                (uniqueId ? uniqueId + '/' : '') +
                  pointType +
                  '/' +
                  forecastSource.pointId,
                filterObject,
                invalidationTime
              ),
              invalidationTime
            );
            resolve(prepareValues(data));
          } catch (err) {
            AlertingService.Error(err);
            resolve([]);
          }
        });
      };
    } else {
      return () => {
        return new Promise(resolve => {
          resolve([]);
        });
      };
    }
  }

  function prepareValues(data) {
    let values = data.reduce((acc, item) => {
      acc.push([
        getTimestampOf(new Date(parseInt(item.validAt))),
        parseFloat(item.value)
      ]);
      return acc;
    }, []);

    const currentTimestamp = getTimestampOf(new Date());
    if (values.length) {
      values.sort((a, b) => (a[0] > b[0] ? 1 : -1));
      let c = 2;
      for (let i = values.length - 1; i >= 0; i--) {
        if (values[i][0] < currentTimestamp) {
          c--;
          if (c === -1) {
            c = i + 1;
            break;
          }
        }
      }
      values = values.map(value => [new Date(value[0]), value[1]]);
      values = values.splice(c, values.length - c);
    }
    return values;
  }

  function getTimestampOf(value) {
    return Date.UTC(
      value.getFullYear(),
      value.getMonth(),
      value.getDate(),
      value.getHours(),
      value.getMinutes(),
      value.getSeconds()
    );
  }

  /**
   * @memberof common.WeatherDashboardItemService
   * @name configureValuesNetworkModels
   * @description Configures the object with datapoint info.
   * @function
   * @param {Array} weatherTimeSeries
   * @return {Object} Containing data about the datapoint at the code key
   */
  function configureValuesNetworkModels(weatherTimeSeries) {
    let pointId;
    let entity;
    let code;
    return weatherTimeSeries.reduce(
      (valuesConfigurations, weatherTimeSeries) => {
        entity = 'time-series-processing-value';
        pointId = weatherTimeSeries.timeseries;
        if (pointId != null) {
          switch (weatherTimeSeries.timeSeriesTypeCode) {
          case 'WD_rt_t':
            code = 'current_temperature';
            break;
          case 'WD_sf_t':
            code = 'short_forecast_temperature';
            break;
          case 'WD_lf_t':
            code = 'long_forecast_temperature';
            break;
          case 'WD_rt_cltxt':
            code = 'current_description';
            break;
          case 'WD_sf_cltxt':
            code = 'short_forecast_description';
            break;
          case 'WD_rt_clph_icn':
            code = 'current_icon';
            break;
          case 'WD_sf_clph_icn':
            code = 'short_forecast_icon';
            break;
          case 'WD_lf_clph_icn':
            code = 'long_forecast_icon';
            break;
          case 'WD_rt_wd': //WIND DIRECTION
            code = 'wind_direction';
            break;
          case 'WD_rt_ws': //WIND SPEED
            code = 'wind_speed';
            break;
          default:
            code = null;
          }

          if (code != null) {
            valuesConfigurations = {
              ...valuesConfigurations,
              [code]: {
                entity,
                pointId
              }
            };
          }
          return valuesConfigurations;
        }
      },
      {}
    );
  }
  /**
   * @memberof common.WeatherDashboardItemService
   * @name setForecastWeatherConfiguration
   * @description sets forecast values.
   * @function
   */
  function setForecastWeatherConfiguration(
    time,
    promisesIndex,
    weatherStationCode
  ) {
    const forecasts = [];

    for (let i = 1; i <= 5; i++) {
      const morningFrom = getNextDay(time, i, 5, 0, 0);
      const morningTo = getNextDay(time, i, 12, 0, 0);
      const morningValues = getWeatherValues(
        promisesIndex,
        'long_forecast_temperature',
        morningFrom,
        morningTo
      );
      const morningCodes = getWeatherValues(
        promisesIndex,
        'long_forecast_icon',
        morningFrom,
        morningTo
      );
      const morningCode = getMostFrequentIcon(
        morningCodes,
        'morning',
        weatherStationCode
      );
      const obj = {
        weekDay: formatDay(morningTo),
        averages: []
      };
      obj.averages.push({
        dayPart: gettext('Morning'),
        temperature:
          morningValues && morningValues.length
            ? calculateAverage(morningValues) + ' °C'
            : '- °C',
        code: morningCode
      });
      const afternoonFrom = getNextDay(time, i, 12, 0, 0);
      const afternoonTo = getNextDay(time, i, 17, 0, 0);
      const afternoonValues = getWeatherValues(
        promisesIndex,
        'long_forecast_temperature',
        afternoonFrom,
        afternoonTo
      );
      const afternoonCodes = getWeatherValues(
        promisesIndex,
        'long_forecast_icon',
        afternoonFrom,
        afternoonTo
      );
      const afternoonCode = getMostFrequentIcon(
        afternoonCodes,
        'afternoon',
        weatherStationCode
      );
      obj.averages.push({
        dayPart: gettext('Morning'),
        temperature:
          afternoonValues && afternoonValues.length
            ? calculateAverage(afternoonValues) + ' °C'
            : '- °C',
        code: afternoonCode
      });
      forecasts.push(obj);
    }
    return forecasts;
  }

  /**
   * @description adds number of dates and sets hours, minutes, seconds to a date.
   * @function
   * @param {Number} additionDay number of dates to add
   * @param {Number} hours hours to set
   * @param {Number} minutes minutes to set
   * @param {Number} seconds seconds to set
   * @return {Date}
   */
  function getNextDay(time, additionDay, hours, minutes, seconds) {
    return new Date(
      time.getFullYear(),
      time.getMonth(),
      time.getDate() + additionDay,
      hours,
      minutes,
      seconds
    );
  }
  function getWeatherValues(
    promisesIndex,
    tableName,
    fromTimestamp,
    toTimestamp
  ) {
    let weatherValues = [];
    let forecastValues = [];

    if (promisesIndex[tableName]) {
      weatherValues = promisesIndex[tableName].reduce((acc, value) => {
        if (value.validAt >= fromTimestamp && value.validAt <= toTimestamp) {
          acc.push(value);
        }
        return acc;
      }, []);
    }

    return weatherValues.concat(forecastValues);
  }

  function getMostFrequentIcon(array, dayPart, weatherStationCode) {
    const item = mostFrequentCode(array);
    if (item) {
      const code = getIconCode(item.value, weatherStationCode);
      switch (dayPart) {
      case 'morning':
      case 'afternoon':
        return code ? code.codeDay : '';
      case 'evening':
      case 'night':
        return code ? code.codeNight : '';
      }
    }
  }

  function mostFrequentCode(array) {
    const map = array.map(a => array.filter(b => a.code === b.code).length);
    return array[map.indexOf(Math.max.apply(null, map))];
  }
  /**
   * @description matches omv/arso icon codes by icon code and returns icon .
   * @function
   * @param {String} iconeCode icon code
   * @return {String}
   */
  function getIconCode(iconCode, weatherStationCode) {
    let codelistName;
    let paramName;
    switch (weatherStationCode) {
    case 'VRWT':
      codelistName = 'weatherCodeARSO';
      paramName = 'arsoCode';
      break;
    case 'VRWT2':
      codelistName = 'weatherCodeOWM';
      paramName = 'owmCode';
      break;
    default:
      return '';
    }
    const icons = TranslationService.GetCollection('codelists.' + codelistName);

    if (Array.isArray(icons)) {
      const icon = icons.find(
        item => String(item[paramName]) == String(iconCode)
      );

      if (icon != null) {
        return TranslationService.GetCollectionById(
          'codelists.weatherIcons',
          icon.weatherIcon
        );
      }
    }
  }
  /**
   * @description formats date to week day format.
   * @function
   * @param {Date} date date
   * @return {String}
   */
  function formatDay(date) {
    return $filter('date')(date, 'EEEE');
  }

  function calculateAverage(values) {
    const sum = values.reduce((acc, value) => acc + value.value * 1, 0);
    return values.length
      ? StandardUtils.round(sum / values.length, 1)
      : undefined;
  }

  /**
   * @memberof common.WeatherDashboardItemService
   * @name calculateCurrentAverage
   * @description calculates average if data is a number. Returns most frequent item for code data
   * @function
   */
  function calculateCurrentAverage(time, promisesIndex, weatherStationCode) {
    const fromMorning = getDateTime(time, 5, 0, 0);
    const toMorning = getDateTime(time, 12, 0, 0);
    const valuesMorning = getWeatherValues(
      promisesIndex,
      'short_forecast_temperature',
      fromMorning,
      toMorning
    );
    const codesMorning = getWeatherValues(
      promisesIndex,
      'short_forecast_icon',
      fromMorning,
      toMorning
    );
    const averageMorning = calculateAverage(valuesMorning);
    const morningCode = getMostFrequentIcon(
      codesMorning,
      'morning',
      weatherStationCode
    );
    const descriptionsMorning = getWeatherValues(
      promisesIndex,
      'short_forecast_description',
      fromMorning,
      toMorning
    );
    const morningDescription = mostFrequentCode(descriptionsMorning);

    const fromAfternoon = getDateTime(time, 12, 0, 0);
    const toAfternoon = getDateTime(time, 17, 0, 0);
    const valuesAfternoon = getWeatherValues(
      promisesIndex,
      'short_forecast_temperature',
      fromAfternoon,
      toAfternoon
    );
    const averageAfternoon = calculateAverage(valuesAfternoon);
    const codesAfternoon = getWeatherValues(
      promisesIndex,
      'short_forecast_icon',
      fromAfternoon,
      toAfternoon
    );
    const afternoonCode = getMostFrequentIcon(
      codesAfternoon,
      'afternoon',
      weatherStationCode
    );
    const descriptionsAfternoon = getWeatherValues(
      promisesIndex,
      'short_forecast_description',
      fromAfternoon,
      toAfternoon
    );
    const afternoonDescription = mostFrequentCode(descriptionsAfternoon);

    const fromEvening = getDateTime(time, 17, 0, 0);
    const toEvening = getDateTime(time, 21, 0, 0);
    const valuesEvening = getWeatherValues(
      promisesIndex,
      'short_forecast_temperature',
      fromEvening,
      toEvening
    );
    const averageEvening = calculateAverage(valuesEvening);
    const codesEvening = getWeatherValues(
      promisesIndex,
      'short_forecast_icon',
      fromEvening,
      toEvening
    );
    const eveningCode = getMostFrequentIcon(
      codesEvening,
      'evening',
      weatherStationCode
    );
    const descriptionsEvening = getWeatherValues(
      promisesIndex,
      'short_forecast_description',
      fromEvening,
      toEvening
    );
    const eveningDescription = mostFrequentCode(descriptionsEvening);

    const fromNight = getDateTime(time, 21, 0, 0);
    const toNight = new Date(
      time.getFullYear(),
      time.getMonth(),
      time.getDate() + 1,
      5,
      0,
      0
    ).getTime();
    const valuesNight = getWeatherValues(
      promisesIndex,
      'short_forecast_temperature',
      fromNight,
      toNight
    );
    const averageNight = calculateAverage(valuesNight);
    const codesNight = getWeatherValues(
      promisesIndex,
      'short_forecast_icon',
      fromEvening,
      toEvening
    );
    const nightCode = getMostFrequentIcon(
      codesNight,
      'night',
      weatherStationCode
    );
    const descriptionsNight = getWeatherValues(
      promisesIndex,
      'short_forecast_description',
      fromNight,
      toNight
    );
    const nightDescription = mostFrequentCode(descriptionsNight);

    const morningAvg = getAvgTemp(
      gettext('Morning'),
      averageMorning,
      morningCode,
      morningDescription
    );
    const afternoonAvg = getAvgTemp(
      gettext('Afternoon'),
      averageAfternoon,
      afternoonCode,
      afternoonDescription
    );
    const eveningAvg = getAvgTemp(
      gettext('Evening'),
      averageEvening,
      eveningCode,
      eveningDescription
    );
    const nightAvg = getAvgTemp(
      gettext('Night'),
      averageNight,
      nightCode,
      nightDescription
    );

    function getAvgTemp(dayPart, temperature, code, description) {
      return {
        dayPart,
        temperature,
        temperatureReadable:
          temperature != null ? temperature + ' °C' : '-- °C',
        code,
        description: description ? description.value : undefined,
        temperatureWarnColor: !temperature,
        descriptionWarnColor: !description
      };
    }

    return [morningAvg, afternoonAvg, eveningAvg, nightAvg];
  }

  function getDateTime(time, hours, minutes, seconds) {
    return new Date(
      time.getFullYear(),
      time.getMonth(),
      time.getDate(),
      hours,
      minutes,
      seconds
    ).getTime();
  }

  /**
   * @memberof common.WeatherDashboardItemService
   * @name constructGraph
   * @function
   * @description Configures and returns the graph config object
   * @param {number} height Height of the graph
   * @param {function} asyncQuery Data fetching query
   * @return graph config object
   */
  function constructGraph(height, asyncQuery) {
    return {
      chartTitle: 'Chart title',
      xAxis: [
        {
          type: 'datetime',
          title: gettextCatalog.getString('Time')
        }
      ],
      yAxis: [
        {
          title: gettextCatalog.getString('Temperature'),
          min: 0,
          suffix: '°C'
        }
      ],
      series: [
        {
          id: 'heartbeats',
          name: gettext('Forecast temperature'),
          asyncQuery,
          type: 'spline',
          color: '#12948b'
        }
      ],
      mode: {
        chart: true
      },
      alternativeMode: {},
      filter: {},
      titleDisplay: {
        chart: false,
        fullscreen: true
      },
      display: {
        limit: true,
        date: true,
        refresh: false,
        tools: false,
        fullscreen: true,
        series: false,
        timeIntervals: false,
        aggregationIntervals: false,
        mode: false,
        export: false,
        toolbar: false
      },
      chartDisplay: {
        legend: false,
        legendAlign: 'left',
        markers: true,
        dataLabels: false,
        axisYTitle: true,
        axisXTitle: false
      },
      theme: $rootScope.activeTheme,
      height
    };
  }

  /**
   * @memberof common.WeatherDashboardItemService
   * @name getValues
   * @description fetches values.
   * @function
   * @param {Object} dataPointsToFetchValues object of point ids and point types
   */
  function getValues(
    dataPointsToFetchValues,
    size,
    cacheInvalidationTime,
    uniqueId
  ) {
    return new Promise(async resolve => {
      const now = DateTime.fromJSDate(new Date());
      const promisesArray = [];
      const keysArray = [];
      for (let key in dataPointsToFetchValues) {
        let filterObject = {
          timeSeriesId: dataPointsToFetchValues[key].pointId,
          view: 'simple'
        };
        const method = CrawlerMethods.getMethod({
          entity: dataPointsToFetchValues[key].entity,
          method: 'read'
        });
        const pointType = 'normal';
        switch (key) {
        case 'short_forecast_temperature':
        case 'short_forecast_icon':
        case 'short_forecast_description':
          filterObject = {
            ...filterObject,
            from: now.startOf('day').toMillis(),
            to: now.endOf('day').toMillis()
          };
          break;
        case 'long_forecast_temperature':
        case 'long_forecast_icon':
          filterObject.from = now.toMillis();
          if (size > 1) {
            filterObject.to = now
              .plus({ day: 5 })
              .endOf('day')
              .toMillis();
          }
        }
        keysArray.push(key);
        promisesArray.push(
          method(
            CachingParams.CheckParams(
              (uniqueId ? uniqueId + '/' : '') +
                pointType +
                '/' +
                dataPointsToFetchValues[key].pointId,
              filterObject,
              cacheInvalidationTime
            ),
            cacheInvalidationTime
          )
        );
      }
      try {
        const results = await Promise.all(promisesArray);
        resolve({ results, keysArray });
      } catch (err) {
        AlertingService.Error(err);
        resolve({});
      }
    });
  }
  /**
   * @memberof common.WeatherDashboardItemService
   * @name getWeatherStationView
   * @description fetches weather station view and constructs filter to fetch values.
   * @function
   * @return {dataType}
   */
  async function getWeatherStationView(
    weatherStationId,
    cacheInvalidationTime
  ) {
    try {
      const { data: results } = await LocationModel.custom.readWeatherView(
        {
          locationId: weatherStationId
        },
        cacheInvalidationTime
      );

      if (Array.isArray(results) && results.length > 0) {
        const [{ location: weatherItem }] = results;
        const { weatherTimeSeries, ...location } = weatherItem;
        if (Array.isArray(weatherTimeSeries) && weatherTimeSeries.length > 0) {
          let fetchedCode;
          if (
            location.locationTypeCode == 'VRWT' ||
            location.locationTypeCode == 'VRWT2'
          ) {
            fetchedCode = location.locationTypeCode;
          } else {
            AlertingService.Error(gettext('Weather station code is missing'));
          }

          const valuesConfigurations = configureValuesNetworkModels(
            weatherTimeSeries
          );
          return { valuesConfigurations, fetchedCode };
        } else {
          return { valuesConfigurations: [] };
        }
      } else {
        return { valuesConfigurations: [] };
      }
    } catch (err) {
      throw err;
    }
  }
  /**
   * @memberof common.WeatherDashboardItemService
   * @name initForecastPlaceholders
   * @description Sets forecast weather card placeholder values.
   * @function
   */
  function initForecastPlaceholders(time) {
    const forecasts = [];
    for (let i = 1; i <= 5; i++) {
      const morningTo = getNextDay(time, i, 12, 0, 0);
      forecasts.push({
        weekDay: $filter('date')(morningTo, 'EEEE'),
        temperatureReadable: '-- °C',
        description: '--'
      });
    }
    return forecasts;
  }

  /**
   * @memberof common.WeatherDashboardItemService
   * @name getAverageTemperaturesPlaceholders
   * @description Returns average temperatures.
   * @function
   */
  function getAverageTemperaturesPlaceholders() {
    return [
      {
        dayPart: gettext('Morning'),
        temperatureReadable: '-- °C',
        description: '--'
      },
      {
        dayPart: gettext('Afternoon'),
        temperatureReadable: '-- °C',
        description: '--'
      },
      {
        dayPart: gettext('Evening'),
        temperatureReadable: '-- °C',
        description: '--'
      },
      {
        dayPart: gettext('Night'),
        temperatureReadable: '-- °C',
        description: '--'
      }
    ];
  }
  /**
   * @memberof common.WeatherDashboardItemService
   * @name getAverageTemperaturesPlaceholders
   * @description sets fetched current weather data.
   * @function
   */
  function getCurrentWeatherState(
    configurationName,
    globalConfigurationName,
    promisesIndex,
    weatherStationCode
  ) {
    const values = promisesIndex[configurationName];
    let timeNow;
    let dateNow;
    let globalConfigurationNameValue;
    let now = Date.now();
    if (values && values.length) {
      const closestValue = values.reduce((finalValue, value) => {
        if (
          Math.abs(finalValue.validAt - now) < Math.abs(value.validAt - now)
        ) {
          return finalValue;
        }
        return value;
      });

      const currentConfiguration = closestValue.value;
      const timestamp = closestValue.validAt;
      if (configurationName === 'current_temperature' && timestamp) {
        timeNow = formatTime(timestamp);
        dateNow = formatDate(timestamp);
      }
      if (currentConfiguration != null) {
        switch (globalConfigurationName) {
        case 'codeNow':
          // eslint-disable-next-line no-case-declarations
          let code = getIconCode(currentConfiguration, weatherStationCode);
          if (code != null) {
            let nowHours = new Date(now).getHours();
            globalConfigurationNameValue =
                nowHours > 20 || nowHours < 5 ? code.codeNight : code.codeDay;
          }
          break;
        case 'temperatureNow':
        case 'descriptionNow':
        case 'windSpeedNow':
        case 'windDirectionDegrees':
          globalConfigurationNameValue = currentConfiguration;
        }
      }
    }
    return {
      timeNow,
      dateNow,
      [globalConfigurationName]: globalConfigurationNameValue
    };
  }
  /**
   * @description formats date to date month name format format.
   * @function
   * @param {Date} date date
   * @return {String}
   */
  function formatDate(date) {
    return $filter('date')(date, 'dd. MMMM');
  }
  /**
   * @description formats date to HH:mm format.
   * @function
   * @param {Date} date date
   * @return {String}
   */
  function formatTime(date) {
    return $filter('date')(date, 'HH:mm');
  }

  return {
    findTemperatureForecast,
    setForecastWeatherConfiguration,
    calculateCurrentAverage,
    constructGraph,
    getValues,
    getWeatherStationView,
    initForecastPlaceholders,
    getAverageTemperaturesPlaceholders,
    getCurrentWeatherState
  };
}
