counterTypeConfiguration.$inject = ['gettext', '$q'];

function counterTypeConfiguration(gettext, $q) {
  var configuration = {
    entity: {
      singular: gettext('Counter Type'),
      plural: gettext('Counter Types')
    },
    api: {
      query: {
        entity: 'counter-types',
        method: 'custom.list'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'configurations-energy-management-counter-types-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'configurations-energy-management-counter-types-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'configurations-energy-management-counter-types-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'counter-types',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description')
      },
      {
        param: 'energySourceTypeName',
        title: gettext('Energy source type')
      },
      {
        param: 'numberOfTarifs',
        title: gettext('Number of tariffs')
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Code'),
        param: 'code',
        type: 'string'
      },
      {
        query: {
          entity: 'energy-source-types',
          method: 'read'
        },
        entity: 'energy-source-types',
        display: gettext('Energy Source Type'),
        param: 'energySourceType',
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id',
        refreshObject: {
          order: 'code'
        }
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    item.energySourceTypeName = 'Unknown';
    if (
      typeof item.energySourceType === 'object' &&
      item.energySourceType != null
    ) {
      item.energySourceTypeName = item.energySourceType.name;
    }
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default counterTypeConfiguration;
