DetailInvoiceModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function DetailInvoiceModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'detail-invoices'
  };

  var networkConfigurationView = {
    host: 'view',
    path: 'detailinvoice'
  };

  var crawlerConfiguration = {
    entity: 'detail-invoices',
    method: 'read',
    populate: 'energySourceType',
    steps: [
      {
        entity: 'measuringpoints',
        valueParams: ['measuringPoint'],
        queryParams: ['_id'],
        method: 'custom.readLocation',
        methodType: 'populateArray'
      }
    ]
  };

  var crawlerConfigurationReadItemView = {
    entity: 'detail-invoices',
    method: 'read',
    populate: 'measuringPoint,energySourceType,masterInvoice',
    steps: [
      {
        entity: 'price-list-details',
        valueParams: ['priceListDetail'],
        queryParams: ['id'],
        method: 'custom.readMaster',
        methodType: 'populate'
      }
    ]
  };

  var crawlerConfigurationForm = {
    entity: 'detail-invoices',
    method: 'read',
    steps: [
      {
        entity: 'price-list-details',
        valueParams: ['priceListDetail'],
        queryParams: ['id'],
        method: 'custom.readMaster',
        methodType: 'populate'
      },
      {
        entity: 'measuringpoints',
        valueParams: ['measuringPoint'],
        queryParams: ['id'],
        method: 'read',
        methodType: 'populate'
      },
      {
        entity: 'energy-source-types',
        valueParams: ['energySourceType'],
        queryParams: ['id'],
        method: 'read',
        methodType: 'populate'
      },
      {
        entity: 'master-invoices',
        valueParams: ['masterInvoice'],
        queryParams: ['id'],
        method: 'read',
        methodType: 'populate'
      }
    ]
  };

  var crawlerConfigurationMaster = {
    entity: 'detail-invoices',
    method: 'read',
    steps: [
      {
        entity: 'master-invoices',
        valueParams: ['masterInvoice'],
        queryParams: ['id'],
        method: 'read',
        methodType: 'populate'
      }
    ]
  };

  var crawlerConfigurationMasterPopulate = {
    entity: 'detail-invoices',
    method: 'read',
    populate: 'masterInvoice'
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readDetailInvoice: CrawlerMethods.crawler(crawlerConfiguration),
      readItemView: CrawlerMethods.crawler(crawlerConfigurationReadItemView),
      readForm: CrawlerMethods.crawler(crawlerConfigurationForm),
      readView: ModelUtilities.crud(
        'GET',
        networkConfigurationView,
        modelStorage
      ),
      readMaster: CrawlerMethods.crawler(crawlerConfigurationMaster),
      readMasterPopulate: CrawlerMethods.crawler(
        crawlerConfigurationMasterPopulate
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default DetailInvoiceModel;
