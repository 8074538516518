module.exports = {
  entity: 'energy-source-types',
  path: 'energy-source-types',
  entityId: 104,
  name: {
    en: 'Energy source type',
    sl_SI: 'Tip energenta'
  },
  networkModel: {
    entity: 'energy-source-types',
    read: 'read'
  },
  scope: 'tis_be:energy-source-types',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select energy source type',
      sl_SI: 'Izberite tip energenta'
    },
    listInfo: {
      en: 'Select energy source type from the list below',
      sl_SI: 'Izberite top energenta s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      },
      {
        field1: 'code',
        type: 'code',
        title: 'Code'
      }
    ]
  },
  displayFields: [
    {
      field1: 'name',
      type: 'string'
    },
    {
      field1: 'code',
      type: 'code',
      title: 'Code'
    }
  ],
  selectTree: {
    parent: 'parentId',
    displayFields: ['code', 'name'],
    selectedParamName: '_id'
  },
  sfeTree: {
    showConnectedEntities: false,
    route: 'energy-source-types',
    parentEntity: 'energy-source-types',
    parentKey: 'parentId',
    childrenConfiguration: [
      {
        type: 'simple',
        usedIn: ['everywhere'],
        route: 'energy-source-types',
        childKey: 'parentId',
        existenceParam: 'hasChild'
      }
    ]
  }
}
