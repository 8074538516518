module.exports = {
  entity: 'external-datasources',
  path: 'external-datasources',
  entityId: 247,
  storeKey: 'externalDatasources',
  name: {
    en: 'External datasource',
    sl_SI: 'Zunanji podatkovni vir'
  },
  networkModel: {
    entity: 'external-datasources',
    read: 'read'
  },
  scope: 'tis_be:external-datasources',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select external datasources',
      sl_SI: 'Izberite zunanje podatkovne vire'
    },
    listInfo: {
      en: 'Select external datasource from the list below',
      sl_SI: 'Izberite zunanji podatkovni vir s spodnjega seznama'
    }
  }
}
