TimeSeriesProcessingValuesModel.$inject = ['ModelUtilities'];

/**
 * @ngdoc model
 * @name common.TimeSeriesProcessingValuesModel
 * @description TimeSeriesProcessingValuesModel model.
 */
function TimeSeriesProcessingValuesModel(ModelUtilities) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'time-series/:timeSeriesId/processing/values'
  };

  const networkConfigurationCsv = {
    host: 'api',
    path: 'time-series/:timeSeriesId/processing/values',
    headers: { 'content-type': 'text/csv' },
    data: false
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      downloadCsv: ModelUtilities.crud(
        'GET',
        networkConfigurationCsv,
        modelStorage
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default TimeSeriesProcessingValuesModel;
