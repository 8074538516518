module.exports = {
  entity: 'archive-types',
  path: 'archive-types',
  name: {
    en: 'Archive type',
    sl_SI: 'Tip arhiva'
  },
  networkModel: {
    entity: 'archive-types',
    read: 'read'
  },
  scope: 'tis_be:archive-types',

  dialog: {
    title: {
      en: 'Select archive type',
      sl_SI: 'Izberite tip arhiva'
    },
    listInfo: {
      en: 'Select archive type from the list below',
      sl_SI: 'Izberitetip arhiva s spodnjega seznama'
    }
  }
}
