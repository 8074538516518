InvoiceOverviewController.$inject = ['gettext'];

function InvoiceOverviewController(gettext) {
  var vm = this;
  vm.yearPlus = yearPlus;
  vm.yearMinus = yearMinus;
  initiate();
  function initiate() {
    vm.query = {
      orderOptions: [
        {
          display: 'created DESC',
          params: '-_id'
        },
        {
          display: 'created ASC',
          params: '_id'
        }
      ],
      filterObject: {
        groupBy: 'measuringPoint',
        validityYear: new Date().getFullYear()
      },
      filters: [
        {
          query: {
            entity: 'business-partners',
            method: 'read'
          },
          display: gettext('Customer'),
          param: 'customer',
          type: 'searchSelect',
          refreshParameterName: 'name',
          selectedParamName: '_id',
          displayList: ['code', 'name'],
          refreshObject: {
            order: 'name'
          },
          entity: 'business-partners'
        },
        {
          query: {
            entity: 'measuringpoints',
            method: 'read'
          },
          display: gettext('Measuringpoint'),
          param: 'measuringPoint',
          type: 'searchSelect',
          refreshParameterName: 'filter',
          selectedParamName: '_id',
          entity: 'measuringpoints'
        },
        {
          query: {
            entity: 'business-partners',
            method: 'read'
          },
          display: gettext('Invoice Issuer'),
          param: 'invoiceIssuer',
          type: 'searchSelect',
          refreshParameterName: 'name',
          selectedParamName: '_id',
          displayList: ['code', 'name'],
          refreshObject: {
            order: 'name'
          },
          entity: 'business-partners'
        },
        {
          query: {
            entity: 'locations',
            method: 'read'
          },
          display: gettext('Location'),
          param: 'location',
          type: 'searchSelect',
          refreshParameterName: 'filter',
          selectedParamName: '_id',
          entity: 'locations'
        },
        {
          query: {
            entity: 'energy-source-types',
            method: 'read'
          },
          display: gettext('Energy Source Type'),
          param: 'energySourceType',
          refreshObject: {
            order: 'code'
          },
          entity: 'energy-source-types'
        },
        {
          query: {
            entity: 'cost-centres',
            method: 'read'
          },
          display: gettext('Cost Centre'),
          param: 'costCentre',
          type: 'searchSelect',
          refreshParameterName: 'filter',
          selectedParamName: '_id',
          entity: 'cost-centres',
          displayList: [
            {
              field1: 'name',
              type: 'string'
            },
            {
              field1: 'code',
              type: 'code',
              title: 'Code'
            },
            {
              field1: 'externalCode',
              type: 'code',
              title: 'External Code'
            }
          ]
        }
      ]
    };

    vm.listPoints = {
      entity: 'views',
      method: 'custom.readBillingOverview'
    };
  }

  function yearPlus() {
    vm.query.filterObject.validityYear++;
    vm.query.relistData = true;
  }

  function yearMinus() {
    vm.query.filterObject.validityYear--;
    vm.query.relistData = true;
  }
}

export default InvoiceOverviewController;
