import template from './tango-timeseries-group-sandbox.component.html';
import './tango-timeseries-group-sandbox.scss';

export default {
  template,
  bindings: {
    group: '<',
    api: '<',
    dndListId: '<'
  },
  controller: TangoVariableViewer,
  controllerAs: 'vm'
};
/**
 * @ngdoc component
 * @name data.tango-timeseries-group-sandbox
 * @description
 * @param {Object} group timeseries group
 * @param {Object} api empty object that will be filled with api functions. Api contains getValues function that returns classification model
 * @param {String} dndListId dnd list group name
 * @example
 * <tango-timeseries-group-sandbox
 * ></tango-timeseries-group-sandbox>
 * Classification model example <Object>
 * {
 *    [scheduleClassification.id]: {
 *        scheduleClassification <Object>
 *        timeseries <Array> [{
 *          _id
 *          name
 *          ... the rest of timeseries values
 *          color <String>
 *          chartType <Object|String>
 *        }]
 *    }
 *
 * }
 */

TangoVariableViewer.$inject = [
  '$scope',
  'gettext',
  'TangoTimeseriesGroupSandboxHelperService',
  'SfeForm2Dialog',
  'TranslationService',
  '$timeout',
  'AlertingService',
  'ScheduleClassificationFormConfiguration',
  'InfoDialog',
  '$mdDialog',
  'TangoTimeSeriesDataXHelperService',
  'FilterDialog',
  'gettextCatalog',
  'ColorService'
];

function TangoVariableViewer(
  $scope,
  gettext,
  TangoTimeseriesGroupSandboxHelperService,
  SfeForm2Dialog,
  TranslationService,
  $timeout,
  AlertingService,
  ScheduleClassificationFormConfiguration,
  InfoDialog,
  $mdDialog,
  TangoTimeSeriesDataXHelperService,
  FilterDialog,
  gettextCatalog,
  ColorService
) {
  let vm = this;
  vm.formApi = {};
  vm.displayFormTypeApi = {};

  let PreviewTimeseries = [];

  let ClassificationsModel = {};
  let currentlyActiveChartConfig;

  let setChartPromise;
  let previouslyValidKey;
  vm.apiDataX = {};

  $scope.$on('$destroy', () => {
    vm = null;
  });

  vm.dndConfiguration = {
    ghostClass: 'chart-drop-area',
    sort: false,
    handle: '.class-that-doesnt-exist',
    group: {
      name: vm.groupId,
      pull: false,
      put: true
    },
    forwardDropFn: draggedItem => {
      if (draggedItem != null && typeof vm.api.getShownValues == 'function') {
        let chartType = 7;
        if (draggedItem.dataInterpretationType != null) {
          const interpretationType = TranslationService.GetCollectionById(
            'codelists.dataInterpretationTypes',
            draggedItem.dataInterpretationType
          );
          if (interpretationType != null) {
            chartType = interpretationType.defaultChartType;
          }
        }

        draggedItem = {
          ...draggedItem,
          color: ColorService.RandomColor(),
          chartType
        };
        let currentlyDisplayed = vm.api.getShownValues();
        if (Array.isArray(currentlyDisplayed)) {
          let isAlreadyDisplayed = currentlyDisplayed.find(
            item => item._id == draggedItem._id
          );
          if (
            !isAlreadyDisplayed &&
            typeof vm.api.setShownValues == 'function'
          ) {
            vm.api.setShownValues(null, [...currentlyDisplayed, draggedItem]);
          }
        }
      }
    }
  };

  vm.$onChanges = changes => {
    //WHEN dnd list  dndListId IS PRESENT INITIALIZE DND CONFIG
    if (changes.dndListId && vm.dndListId != null) {
      vm.formConfig = getFormConfiguration(vm.dndListId);
      vm.displayTypeFormConfig = ScheduleClassificationFormConfiguration.getDisplayTypeForm(
        displayTypeChanged,
        sortTypeChanged,
        { displayType: 3 }
      );
    }
    //WHEN id IS PRESENT INITIALIZE FORM DATA
    if (changes.group && vm.group != null) {
      $timeout(initializeFormData);
    }

    if (changes.api && vm.api != null) {
      vm.api.getValue = () => ClassificationsModel;
      vm.api.getShownValues = () => {
        if (vm.activeKey != null) {
          return ClassificationsModel[vm.activeKey].series;
        } else {
          return PreviewTimeseries;
        }
      };
      //TRIGGERED ON FILTER ADD BUTTON
      vm.api.setShownValues = (_, values) => {
        if (Array.isArray(values)) {
          if (vm.activeKey) {
            ClassificationsModel[vm.activeKey].series = values;
          } else {
            PreviewTimeseries = values;
          }
          setFormData();
          setChartConfig();
        } else {
          AlertingService.Error(gettext('Wrong data format'));
        }
      };

      vm.api.getDisplayChartSettings = () => {
        return vm.displayFormTypeApi.getValues();
      };

      vm.api.setDisplayChartSettings = settings => {
        Object.keys(settings).forEach(key => {
          vm.displayFormTypeApi.setValue(key, settings[key]);
        });
      };

      vm.api.setModel = (model, activeKey, previewItems, displaySettings) => {
        if (displaySettings != null) {
          vm.api.setDisplayChartSettings(displaySettings);
        }
        PreviewTimeseries = previewItems || [];

        ClassificationsModel = model;
        initializeClassificationActionsOutOfModel(activeKey);
      };
      vm.api.getPreview = () => PreviewTimeseries;
      vm.api.setPreview = values => {
        PreviewTimeseries = values;
      };
    }
  };

  /**
   * @description when group id is present gets group data, parses it to be displayed in sandbox viewer.
   * @function
   */
  async function initializeFormData() {
    // CONSTRUCT GROUPS TIMESERIES
    let items = vm.group.scheduleClassifications.map(item => ({
      ...item,
      scheduleClassification: TranslationService.GetCollectionById(
        'codelists.scheduleClassifications',
        item.scheduleClassification
      )
    }));

    //CONSTRUCT CLASSIFICATION MODEL
    ClassificationsModel = items.reduce((result, item) => {
      return {
        ...result,
        [item.scheduleClassification.id]: {
          ...item,
          series: item.series.map(timeseriesItem => ({
            ...timeseriesItem.timeSeries,
            ...timeseriesItem.dataVisualizationConfig
          }))
        }
      };
    }, {});
    let activeItem = items.find(item => item.isMain);

    let activeKey =
      activeItem != null ? activeItem.scheduleClassification.id : null;
    initializeClassificationActionsOutOfModel(activeKey);
  }
  /**
   * @description initializes view out of model.
   * @function
   * @param {Number} activeKey codelist id of currently active schedule classification
   */
  function initializeClassificationActionsOutOfModel(activeKey) {
    let items = Object.keys(ClassificationsModel).map(
      key => ClassificationsModel[key]
    );
    //CONSTRUCT ACTIONS
    let actions = getClassificationActions();
    actions = items.reduce((result, item) => {
      return addScheduleClassification(result, item);
    }, actions);
    //GET ACTIVE ACTION INDEX
    let activeIndex = actions.findIndex(
      action => action.scheduleClassificationId == activeKey
    );
    if (activeIndex < 0) {
      activeIndex = 0;
    }

    //SET ACTION BUTTON COLORS
    vm.actions = actions.map((action, index) => ({
      ...action,
      color: activeIndex == index ? 'primary' : 'grey'
    }));
    //CONSTRUCT FORM
    vm.activeKey = activeKey;
    setFormData();
    triggerChartReset();
  }
  /**
   * @description creates chart configuration.
   * @function
   */
  async function setChartConfig() {
    let activeClassification = ClassificationsModel[vm.activeKey];
    let seriesToRender = PreviewTimeseries;
    let defaultFilter = {};
    if (activeClassification != null) {
      seriesToRender = activeClassification.series;
      defaultFilter = activeClassification.defaultFilter;
    }
    const displaySettings = vm.api.getDisplayChartSettings();

    let config = await TangoTimeSeriesDataXHelperService.get({
      series: seriesToRender,
      defaultFilter,
      ...displaySettings
    });
    config.doNotMarkLastValue = true;
    config.callbacks = {
      filter: () => {
        FilterDialog.open(vm.apiDataX);
      },
      report: api => {
        let shownTimeSeries = vm.api.getShownValues();
        if (Array.isArray(shownTimeSeries) && shownTimeSeries.length > 0) {
          let timeSeriesIds = shownTimeSeries.map(item => item._id);
          TangoTimeSeriesDataXHelperService.generateTimeSeriesReport(
            api,
            timeSeriesIds
          );
        } else {
          AlertingService.Error(
            gettextCatalog.getString(
              'There is no time series to generate report'
            )
          );
        }
      }
    };
    if (config != null && config.chart != null && config.chart.height < 420) {
      config.chart.height = 420;
    }
    if (!vm.configurationDataX) {
      currentlyActiveChartConfig = config;
      vm.configurationDataX = currentlyActiveChartConfig;
    } else {
      let equalConfigs = configsAreEqual(currentlyActiveChartConfig, config);
      if (!equalConfigs && typeof vm.apiDataX.rebuild == 'function') {
        setCurrentlyActiveFilter(config);
        vm.apiDataX.rebuild(config);
        currentlyActiveChartConfig = config;
      }
    }
  }
  /**
   * @description compares 2 chart configurations.
   * @function
   * @param {Object} oldConfig
   * @param {Object} newConfig
   * @return {Boolean}
   */
  function configsAreEqual(oldConfig, newConfig) {
    return !Object.keys(oldConfig).some(key => {
      let oldFilter;
      let newFilter;
      switch (key) {
      case 'series':
        if (oldConfig[key].length != newConfig[key].length) {
          return true;
        } else {
          return !oldConfig[key].every((item, index) => {
            return seriesAreEqual(item, newConfig[key][index]);
          });
        }
      case 'mode':
        return !_.isEqual(oldConfig[key], newConfig[[key]]);
      case 'filter':
        oldFilter = oldConfig.filter();
        newFilter = newConfig.filter();
        return !_.isEqual(oldFilter, newFilter);
      }
    });
  }
  /**
   * @description compares 2 series configurations.
   * @function
   * @param {Object} oldSeries
   * @param {Object} newSeries
   * @return {Boolean}
   */
  function seriesAreEqual(oldSeries, newSeries) {
    return Object.keys(oldSeries).every(key => {
      switch (key) {
      case 'color':
        return newSeries[key].toLowerCase() == oldSeries[key].toLowerCase();
      case 'decimalPrecision':
      case 'link':
      case 'name':
      case 'nonNumerical':
      case 'type':
      case 'stacking':
      case 'id':
        return newSeries[key] == oldSeries[key];
      default:
        return true;
      }
    });
  }
  /**
   * @description method is triggered on display type change rebuilds chart configuration.
   * @function
   * @param {Number} displayType codelist id of display type
   */
  function displayTypeChanged(displayType) {
    if (
      currentlyActiveChartConfig != null &&
      typeof displayType == 'number' &&
      !isNaN(displayType)
    ) {
      let displayTypeObj;
      if (typeof displayType == 'number' && !isNaN(displayType)) {
        displayTypeObj = TranslationService.GetCollectionById(
          'codelists.displayTypes',
          displayType
        );
      } else if (displayType != null && displayType.componentCode != null) {
        displayTypeObj = { ...displayType };
      }
      let mode = TangoTimeSeriesDataXHelperService.getDisplayType(
        displayTypeObj
      );
      if (!_.isEqual(mode, currentlyActiveChartConfig.mode)) {
        currentlyActiveChartConfig = {
          ...currentlyActiveChartConfig,
          mode
        };

        setCurrentlyActiveFilter(currentlyActiveChartConfig);
        vm.apiDataX.rebuild(currentlyActiveChartConfig);
      }
    }
  }
  /**
   * @description sets currently active filter to existing configuration
   * @function
   * @param {Object} chartConfigurations
   */
  function setCurrentlyActiveFilter(chartConfigurations) {
    const model = vm.apiDataX.getModel();
    if (model != null) {
      const getCurrentlyActiveFilter = model.getActiveFilter();
      const activeFilter = getCurrentlyActiveFilter();
      if (
        previouslyValidKey != null &&
        ClassificationsModel[previouslyValidKey] != null
      ) {
        ClassificationsModel[previouslyValidKey].lastValidFilter = activeFilter;
      }

      if (
        ClassificationsModel[vm.activeKey] != null &&
        ClassificationsModel[vm.activeKey].lastValidFilter != null
      ) {
        let lastValidFilter =
          ClassificationsModel[vm.activeKey].lastValidFilter;
        chartConfigurations.filter = () => lastValidFilter;
      }
    }
    previouslyValidKey = vm.activeKey;
  }
  /**
   * @description triggers on sort fields change to redraw data x chart.
   * @function
   * @param {Object} _ref {sortType<Number>, chartType<Number>}
   */
  function sortTypeChanged({ sortType, chartType }) {
    if (currentlyActiveChartConfig != null) {
      setCurrentlyActiveFilter(currentlyActiveChartConfig);
      let { axis } = currentlyActiveChartConfig;
      if (axis != null) {
        let { x } = axis;
        if (Array.isArray(x) && x.length > 0) {
          currentlyActiveChartConfig.axis.x.map(xConfig => {
            if (xConfig != null) {
              let sortingChartType = TranslationService.GetCollectionById(
                'codelists.chartTypes',
                chartType
              );

              if (
                sortType == 10 &&
                (xConfig.axisDisplayType != 'sortBySum' ||
                  (sortingChartType != null &&
                    xConfig.sortingChartType != sortingChartType.highChartCode))
              ) {
                xConfig.axisDisplayType = 'sortBySum';
                xConfig.sortDirection = 'DESC';
                if (sortingChartType != null) {
                  xConfig.sortingChartType = sortingChartType.highChartCode;
                }
              } else if (
                sortType == 1 &&
                xConfig.axisDisplayType == 'sortBySum'
              ) {
                delete xConfig.axisDisplayType;
                delete xConfig.sortingChartType;
                delete xConfig.sortDirection;
              }
              return xConfig;
            }
          });
          vm.apiDataX.rebuild(currentlyActiveChartConfig);
        }
      }
    }
  }

  /**
   * @description creates actions.
   * @function
   */
  function createActions() {
    vm.actions = getClassificationActions();

    vm.removeAction = [
      {
        title: 'Remove',
        color: 'warn',
        /**
         * @description opens remove classification confirmation dialog and and triggers function that removes selected classification.
         * @function
         */
        fn: () => {
          const title = gettext('Remove schedule classification');
          const textItem = {
            text: gettext(
              'Are you sure you want to remove this classification?'
            ),
            type: 'text'
          };
          const actions = [
            {
              title: gettext('Cancel'),
              cancel: true,
              color: 'primary'
            },
            {
              title: gettext('Yes'),
              fn: removeClassification,
              color: 'success'
            }
          ];
          InfoDialog.open(title, null, [textItem], actions);
        },
        disabledFn: () => !vm.activeKey
      }
    ];
  }
  /**
   * @description removes selected classification - classification that has id that is equal to vm.activeKey.
   * @function
   */
  function removeClassification() {
    delete ClassificationsModel[vm.activeKey];
    //reset selected classifications
    initializeClassificationActionsOutOfModel(null);
    $mdDialog.cancel();
  }

  /**
   * @description returns starting configuration for classification tabs (Preview and Add tab).
   * @function
   * @return {Array}
   */
  function getClassificationActions() {
    return [
      {
        title: gettext('Preview'),
        fn: () => getAddClassificationAction(0),
        color: 'primary'
      },
      {
        title: '+',
        color: 'grey',
        fn: async () => {
          const configuration = await ScheduleClassificationFormConfiguration.get(
            {
              selectedScheduleClassifications: Object.keys(
                ClassificationsModel
              ),
              previewTimeseries: PreviewTimeseries
            }
          );
          const formResult = await SfeForm2Dialog.open(configuration);
          if (formResult != null) {
            let scheduleClassification = TranslationService.GetCollectionById(
              'codelists.scheduleClassifications',
              formResult.scheduleClassification
            );
            if (scheduleClassification != null) {
              formResult.scheduleClassification = scheduleClassification;
            }
            vm.actions = addScheduleClassification(vm.actions, formResult);
            vm.activeKey = formResult.scheduleClassification.id;
            ClassificationsModel = TangoTimeseriesGroupSandboxHelperService.addConfigurationToModel(
              formResult.scheduleClassification,
              formResult.timeseries,
              ClassificationsModel,
              PreviewTimeseries
            );
            //REMOVE ADDED TIMESERIES FROM PREVIEW
            if (
              formResult.timeseries != null &&
              typeof formResult.timeseries == 'object'
            ) {
              let timeseriesToRemove = Object.keys(
                formResult.timeseries
              ).reduce((results, key) => {
                if (formResult.timeseries[key]) {
                  results = [...results, key];
                }
                return results;
              }, []);
              PreviewTimeseries = PreviewTimeseries.filter(
                item =>
                  !timeseriesToRemove.find(
                    timeseriesItem => timeseriesItem == item._id
                  )
              );
            }
            setFormData();
            triggerChartReset();
            $scope.$evalAsync();
          }
        }
      }
    ];
  }
  /**
   * @description creates new action array with given classification added.
   * @function
   * @param {Array} current state of action array
   *     @param {Object} scheduleClassifications selected scheduled classification
   *     @param {Object} timeseries Object of timeseries that should be added to new configuration {[timeseriesId]: add <Boolean>}
   * @return {Array} new actions
   */
  function addScheduleClassification(
    actions,
    { scheduleClassification: classification }
  ) {
    //CHECK IF NEW CLASSIFICATION IS ALREADY IN THE MODEL
    let selectedScheduleClassifications = [];
    if (!ClassificationsModel[classification.id]) {
      selectedScheduleClassifications = [classification];
    }
    selectedScheduleClassifications = Object.keys(ClassificationsModel)
      .reduce((result, key) => {
        return [...result, ClassificationsModel[key].scheduleClassification];
      }, selectedScheduleClassifications)
      .sort((a, b) => {
        return a.order - b.order;
      });

    return [
      {
        //first preview action
        ...actions[0],
        fn: getAddClassificationAction(0),
        color: 'grey'
      },
      //classification actions
      ...selectedScheduleClassifications.map(
        (scheduleClassification, index) => {
          return {
            title: scheduleClassification.name,
            scheduleClassificationId: scheduleClassification.id,
            //set primary color to newly added classification
            color:
              classification.id == scheduleClassification.id
                ? 'primary'
                : 'grey',
            fn: getAddClassificationAction(index + 1)
          };
        }
      ),
      actions[actions.length - 1] //last add action
    ];
  }

  /**
   * @description returns function that gets triggered when we click on classification/preview tab
   * changes current model and updates actions.
   * @function
   * @param {number} actionIndex action index in array of actions
   * @return {function}
   */
  function getAddClassificationAction(actionIndex) {
    return () => {
      vm.actions = vm.actions.map((item, index) => {
        if (index == actionIndex) {
          return {
            ...item,
            color: 'primary'
          };
        }
        return {
          ...item,
          color: 'grey'
        };
      });

      if (actionIndex != 0) {
        vm.activeKey = vm.actions[actionIndex].scheduleClassificationId;
      } else {
        vm.activeKey = null;
      }
      setFormData();
      triggerChartReset();
    };
  }
  /**
   * @description triggers chart rebuild if it is not in progress yet .
   * @function
   */
  async function triggerChartReset() {
    if (setChartPromise == null) {
      setChartPromise = true;
      await setChartConfig();
      await $timeout();
      setChartPromise = null;
    }
  }

  /**
   * @description when index changes reset dnd form data.
   * @function
   */
  function setFormData() {
    if (vm.activeKey == null || vm.activeKey == 0) {
      vm.formApi.setValue('chartSettings', PreviewTimeseries);
    } else {
      vm.formApi.setValue(
        'chartSettings',
        ClassificationsModel[vm.activeKey].series
      );
    }
  }
  /**
   * @description returns dnd form configuration.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function getFormConfiguration(dndListId) {
    return {
      name: 'sandboxForm',
      fields: [
        {
          id: 'chartSettings',
          title: gettextCatalog.getString('Series'),
          type: {
            name: 'chartSettings',
            options: {
              groupId: dndListId,
              yellowBackground: true,
              moveCallback: moveTimeseries,
              getClassificationModel: timeseries => {
                if (ClassificationsModel != null) {
                  return Object.keys(ClassificationsModel).reduce(
                    (selectedClassification, key) => {
                      let { series } = ClassificationsModel[key];
                      if (Array.isArray(series)) {
                        selectedClassification = {
                          ...selectedClassification,
                          [key]: !!series.find(item => item._id == timeseries)
                        };
                      }
                      return selectedClassification;
                    },
                    {}
                  );
                }
              }
            }
          },
          onChange: updateClassificationModel
        }
      ]
    };
  }
  /**
   * @description moves timeseries from preview or one classification to another. If its been moved out of preview item will be removed from preview
   * @function
   * @param {ref in Object} selectedSchedulers object of scheduler that indicates where timeseries should be moved {[_id]: Boolean}
   * @return {dataType}
   */
  function moveTimeseries({ selectedSchedulers }, timeseries) {
    let result = TangoTimeseriesGroupSandboxHelperService.getPreviewAndModelOnMove(
      PreviewTimeseries,
      ClassificationsModel,
      selectedSchedulers,
      timeseries,
      vm.activeIndex
    );
    ClassificationsModel = result.classificationsModel;
    PreviewTimeseries = result.previewTimeseries;
    initializeClassificationActionsOutOfModel(vm.activeKey);
  }
  /**
   * @description when something is changes in form update model data.
   * @function
   * @param {Object} api form api that contains functions tu update form data
   */
  async function updateClassificationModel(api) {
    await $timeout();
    let newValue = api.getValue('chartSettings');
    if (vm.activeKey) {
      ClassificationsModel[vm.activeKey] = {
        ...ClassificationsModel[vm.activeKey],
        series: newValue
      };
    } else {
      PreviewTimeseries = newValue;
    }
    triggerChartReset();
  }

  createActions();
}
