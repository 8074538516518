import './styles/company-structure.scss';
//services
import AddressAutoCompleteService from './services/AddressAutoCompleteService/AddressAutoCompleteService.service';
import AddressHelperService from './services/AddressHelper.service';
//factories
import CompanyResourcesViewData from './factories/CompanyResourcesViewData/CompanyResourcesViewData.factory';
import TimeSeriesOutOfMeasuringpoint from './factories/TimeSeriesOutOfMeasuringpoint.service';
import TangoAssetAvailabilityProperties from './factories/TangoAssetAvailabilityProperties.service';
import TangoAssetAvailabilityDataXService from './factories/TangoAssetAvailabilityDataX.service';
import TangoAssetAvailabilityEventForm from './factories/TangoAssetAvailabilityEventForm.service';
import TangoAssetAvailabilityForm from './factories/TangoAssetAvailabilityForm.service';

import routes from './company-resources.routes';
//controllers
import NewCostCentreFormController from './pages/cost-centre/new-cost-centre.controller';
import CostCentreController from './pages/cost-centre/cost-centre.controller';
import NewProjectFormController from './pages/project/new-project.controller';
import ProjectController from './pages/project/project.controller';
import NewProfitCentreController from './pages/profit-centre/new-profit-centre.controller';
import ProfitCentreController from './pages/profit-centre/profit-centre.controller';
import NewLocationFormController from './pages/location/new-location.controller';
import LocationController from './pages/location/location.controller';
import NewAssetFormController from './pages/asset/new-asset.controller';
import AssetController from './pages/asset/asset.controller';
import NewMeasuringPointFormController from './pages/measuring-point/new-measuring-point.controller';
import MeasuringPointController from './pages/measuring-point/measuring-point.controller';
import NewBusinessPartnerFormController from './pages/business-partner/new-business-partner.controller';
import BusinessPartnerController from './pages/business-partner/business-partner.controller';
import CompanyStructureController from './pages/company-structure/company-structure.controller';
import LocationsOverviewController from './pages/location-overview/locations-overview.controller';
//configurations
import ActionConfiguration from './configurations/action.configuration';
import AssetConfiguration from './configurations/asset.configuration';
import BusinessPartnerConfiguration from './configurations/business-partner.configuration';
import CostCentreConfiguration from './configurations/cost-centre.configuration';
import LocationConfiguration from './configurations/location.configuration';
import MeasuringPointConfiguration from './configurations/measuring-point.configuration';
import ProjectConfiguration from './configurations/project.configuration';
import AssetRatedPowerConfiguration from './configurations/asset-rated-power.configuration';
import AssetAvailabilityEventConfiguration from './configurations/asset-availability-event.configuration';

//entity date configurations
import AssetCostCentresEntityDateConfiguration from './configurations/entity-date/asset/asset-cost-centres.configuration';
import AssetLocationsEntityDateConfiguration from './configurations/entity-date/asset/asset-locations.configuration';
import AssetTimeSeriesEntityDateConfiguration from './configurations/entity-date/asset/asset-time-series.configuration';
import AssetsMeasuringpointEntityDateConfiguration from './configurations/entity-date/asset/asset-measuringpoint.configuration';

import LocationAssetsEntityDateConfiguration from './configurations/entity-date/location/location-assets.configuration';
import LocationBusinessPartnerEntityDateConfiguration from './configurations/entity-date/location/location-business-partners.configuration';
import LocationCostCentresEntityDateConfiguration from './configurations/entity-date/location/location-cost-centres.configuration';
import LocationTimeSeriesEntityDateConfiguration from './configurations/entity-date/location/location-time-series.configuration';

import TimeSeriesAssetsEntityDateConfiguration from './configurations/entity-date/time-series/time-series-assets.configuration';
import TimeSeriesLocationEntityDateConfiguration from './configurations/entity-date/time-series/time-series-location.configuration';
import TimeSeriesMeasuringPointsEntityDateConfiguration from './configurations/entity-date/time-series/time-series-measuring-point.configuration';

import MeasuringpointAssetsEntityDateConfiguration from './configurations/entity-date/measuring-point/measuringpoint-assets.configuration';
import MeasuringpointBusinessPartnerEntityDateConfiguration from './configurations/entity-date/measuring-point/measuringpoint-business-partner.configuration';
import MeasuringpointCostCentresEntityDateConfiguration from './configurations/entity-date/measuring-point/measuringpoint-cost-centres.configuration';
import MeasuringpointCounterTypesEntityDateConfiguration from './configurations/entity-date/measuring-point/measuringpoint-counter-type.configuration';
import MeasuringpointTimeSeriesEntityDateConfiguration from './configurations/entity-date/measuring-point/measuringpoint-time-series.configuration';
import MeasuringpointAllocatorEntityDateConfiguration from './configurations/entity-date/measuring-point/measuringpoint-allocator.configuration';

//network models (services)
import AddressModel from './network-models/Address.model';
import AssetModel from './network-models/Asset.model';
import AssetLocationModel from './network-models/AssetLocation.model';
import AssetMeasuringpointModel from './network-models/AssetMeasuringpoint.model';
import BusinessPartnerModel from './network-models/BusinessPartner.model';
import BusinessPartnerLocationModel from './network-models/BusinessPartnerLocation.model';
import BusinessPartnerMeasuringpointModel from './network-models/BusinessPartnerMeasuringpoint.model';
import CostCentreModel from './network-models/CostCentre.model';
import CostCentreAssetModel from './network-models/CostCentreAsset.model';
import CostCentreLocationModel from './network-models/CostCentreLocation.model';
import CostCentreMeasuringpointModel from './network-models/CostCentreMeasuringpoint.model';
import LocationModel from './network-models/Location.model';
import LocationActionModel from './network-models/LocationAction.model';
import TimeSeriesAssetModel from './network-models/TimeSeriesAsset.model';
import TimeSeriesLocationModel from './network-models/TimeSeriesLocation.model';
import TimeSeriesMeasuringpointModel from './network-models/TimeSeriesMeasuringpoint.model';
import MeasuringpointModel from './network-models/Measuringpoint.model';
import MeasuringpointCounterTypeModel from './network-models/MeasuringpointCounterType.model';
import MeasuringpointSplitterModel from './network-models/MeasuringpointSplitter.model';
import PictureModel from './network-models/Picture.model';
import PictureAssetModel from './network-models/PictureAsset.model';
import PictureLocationModel from './network-models/PictureLocation.model';
import ProfitCentreModel from './network-models/ProfitCentre.model';
import ProjectModel from './network-models/Project.model';
import AssetAvailabilityEventModel from './network-models/AssetAvailabilityEvent.model';

import tangoAssetAvailability from './components/asset-availability/tango-asset-availability.component';

export default {
  routes,
  components: {
    tangoAssetAvailability
  },
  services: {
    AddressAutoCompleteService,
    AddressModel,
    AssetModel,
    AssetLocationModel,
    AssetMeasuringpointModel,
    BusinessPartnerModel,
    BusinessPartnerLocationModel,
    BusinessPartnerMeasuringpointModel,
    CostCentreModel,
    CostCentreAssetModel,
    CostCentreLocationModel,
    CostCentreMeasuringpointModel,
    LocationModel,
    LocationActionModel,
    TimeSeriesAssetModel,
    TimeSeriesLocationModel,
    TimeSeriesMeasuringpointModel,
    MeasuringpointModel,
    MeasuringpointCounterTypeModel,
    MeasuringpointSplitterModel,
    PictureModel,
    PictureAssetModel,
    PictureLocationModel,
    ProfitCentreModel,
    ProjectModel,
    AssetAvailabilityEventModel,
    AddressHelperService
  },
  factories: {
    CompanyResourcesViewData,
    TimeSeriesOutOfMeasuringpoint,
    CostCentreConfiguration,
    BusinessPartnerConfiguration,
    MeasuringPointConfiguration,
    AssetConfiguration,
    LocationConfiguration,
    ActionConfiguration,
    ProjectConfiguration,
    AssetRatedPowerConfiguration,
    AssetAvailabilityEventConfiguration,
    AssetCostCentresEntityDateConfiguration,
    AssetLocationsEntityDateConfiguration,
    AssetTimeSeriesEntityDateConfiguration,
    AssetsMeasuringpointEntityDateConfiguration,
    LocationAssetsEntityDateConfiguration,
    LocationBusinessPartnerEntityDateConfiguration,
    LocationCostCentresEntityDateConfiguration,
    LocationTimeSeriesEntityDateConfiguration,
    TimeSeriesAssetsEntityDateConfiguration,
    TimeSeriesLocationEntityDateConfiguration,
    TimeSeriesMeasuringPointsEntityDateConfiguration,
    MeasuringpointAssetsEntityDateConfiguration,
    MeasuringpointBusinessPartnerEntityDateConfiguration,
    MeasuringpointCostCentresEntityDateConfiguration,
    MeasuringpointCounterTypesEntityDateConfiguration,
    MeasuringpointTimeSeriesEntityDateConfiguration,
    MeasuringpointAllocatorEntityDateConfiguration,
    TangoAssetAvailabilityProperties,
    TangoAssetAvailabilityDataXService,
    TangoAssetAvailabilityForm,
    TangoAssetAvailabilityEventForm
  },
  controllers: {
    NewCostCentreFormController,
    CostCentreController,
    NewProjectFormController,
    ProjectController,
    NewProfitCentreController,
    ProfitCentreController,
    NewLocationFormController,
    LocationController,
    NewAssetFormController,
    AssetController,
    NewMeasuringPointFormController,
    MeasuringPointController,
    NewBusinessPartnerFormController,
    BusinessPartnerController,
    CompanyStructureController,
    LocationsOverviewController
  }
};
