sfeMtAnalysisService.$inject = [
  'DateLocalizationService',
  'Formatting',
  'TranslationService',
  'SfeDataXTranslations',
  'TimeSeriesProcessingValuesModel',
  'AlertingService',
  'gettextCatalog',
  '$state',
  'PhysicalCollectionService',
  'StandardUtils',
  'CachingParams'
];
/**
 * @ngdoc service
 * @name dashboards.sfeMtAnalysisService
 * @description sfe-mt-analysis component helper methods.
 */
function sfeMtAnalysisService(
  DateLocalizationService,
  Formatting,
  TranslationService,
  SfeDataXTranslations,
  TimeSeriesProcessingValuesModel,
  AlertingService,
  gettextCatalog,
  $state,
  PhysicalCollectionService,
  StandardUtils,
  CachingParams
) {
  const baseLineColors = [
    '#dbb696',
    '#eaae8c',
    '#f6e191',
    '#bce97a',
    '#5d9d3b',
    '#80cde5',
    '#ec9b6e',
    '#c372b7',
    '#9bacfd',
    '#82cdc8',
    '#12bd81',
    '#62872d',
    '#a75a23',
    '#855376',
    '#703739',
    '#d180dc',
    '#a26464',
    '#efb6dd',
    '#f69e64',
    '#a4c96c',
    '#66f7b7',
    '#a8d0dc',
    '#9ea4ce',
    '#c5a2c9'
  ];

  /**
   * @description returns method to get mt analysis chart values.
   * @function
   * @param {Object} flow, mtFlow object
   * @return {Function}
   */
  function getValues(
    flow,
    isPattern,
    dashboardSettings = { cacheInvalidationTime: 0 }
  ) {
    /**
     * @description returns sorted values.
     * @function
     * @param {Object} filter filter object
     * @param {Boolean} manualRefresh indicates when chart refresh is triggered manually
     * @return {Promise} Array
     */
    return async (filter, manualRefresh) => {
      const invalidationTime = manualRefresh
        ? 0
        : dashboardSettings.cacheInvalidationTime;

      let filterObj = { ...filter };

      const cachedFilterX = CachingParams.CheckParams(
        (dashboardSettings.uniqueCacheId
          ? dashboardSettings.uniqueCacheId + '/'
          : '') +
          'time-series-processing-values' +
          '/' +
          flow.inputTimeSeriesForXAxis._id,
        filterObj,
        invalidationTime
      );
      const cachedFilterY = CachingParams.CheckParams(
        (dashboardSettings.uniqueCacheId
          ? dashboardSettings.uniqueCacheId + '/'
          : '') +
          'time-series-processing-values' +
          '/' +
          flow.inputTimeSeriesForYAxis._id,
        filterObj,
        invalidationTime
      );

      try {
        const { data: valuesX } = await TimeSeriesProcessingValuesModel.read({
          timeSeriesId: flow.inputTimeSeriesForXAxis._id,
          ...cachedFilterX
        });
        const { data: valuesY } = await TimeSeriesProcessingValuesModel.read({
          timeSeriesId: flow.inputTimeSeriesForYAxis._id,
          ...cachedFilterY
        });

        let mtValues = [];

        if (!isPattern) {
          const cachedFilterMT = CachingParams.CheckParams(
            (dashboardSettings.uniqueCacheId
              ? dashboardSettings.uniqueCacheId + '/'
              : '') +
              'time-series-processing-values' +
              '/' +
              flow.timeSeries,
            {
              ...filterObj,
              view: 'full'
            },
            invalidationTime
          );
          try {
            let { data } = await TimeSeriesProcessingValuesModel.read({
              timeSeriesId: flow.timeSeries,
              ...cachedFilterMT
            });
            mtValues = data;
          } catch (err) {
            AlertingService.Error(err);
          }
        }
        let lastValueIsSet = false;
        let newValues = valuesX
          .reduce((result, valueX) => {
            let valueY = valuesY.find(
              valueItem => valueItem.validAt == valueX.validAt
            );
            let mtValue = mtValues.find(
              valueItem => valueItem.validAt == valueX.validAt
            );
            let marker;
            let color;
            if (!isPattern && !lastValueIsSet) {
              //LAST VALUE MARKER
              marker = {
                radius: 5
              };
              color = '#c02c22'; //RED
              lastValueIsSet = true;
            }
            if (valueY) {
              result = [
                ...result,

                {
                  x: valueX.value,
                  y: valueY.value,
                  calculatedValue:
                    mtValue && mtValue.additionalProperties
                      ? mtValue.additionalProperties.calculatedValue
                      : undefined,
                  delta: mtValue ? mtValue.value : undefined,
                  marker,
                  color,
                  date: valueX.validAt
                }
              ];
            }

            return result;
          }, [])
          .sort((a, b) => a.x - b.x);
        return newValues;
      } catch (err) {
        AlertingService.Error(err);
      }
      return [];
    };
  }
  /**
   * @description returns suffix for x and y time series.
   * @function
   * @param {Object} _ref {inputTimeSeriesForXAxis, inputTimeSeriesForYAxis}
   * @return {dataType}
   */
  async function getPhysicalData(timeSeries) {
    try {
      let {
        readableMeasurementUnitSymbol
      } = await PhysicalCollectionService.getReadablePhysicalData(timeSeries);
      return readableMeasurementUnitSymbol;
    } catch (err) {
      AlertingService.Error(err);
    }
    return '';
  }
  /**
   * @description returns string that represents linear equation with given k and n.
   * @function
   * @param {Number} k
   * @param {Number} n
   * @return {String}
   */
  function getEquationString(k, n) {
    return `y = ${k ? StandardUtils.round(k, 3) + 'x' : ''} ${
      n >= 0 ? (k !== 0 ? '+' : '') : '-'
    } ${StandardUtils.round(Math.abs(n), 3)}`;
  }

  /**
   * @description returns dataX series for baseline and target functions.
   * @function
   * @param {Array} targetFunctions
   * @param {number} decimalPrecision
   * @return {Array}
   */
  function buildTargetFunctionsSeries(targetFunctions, decimalPrecision) {
    if (Array.isArray(targetFunctions) && targetFunctions.length > 0) {
      let series = targetFunctions.reduce((result, baseLine, index) => {
        let color = baseLineColors[index % baseLineColors.length];

        let { targetN } = baseLine;
        let { targetK } = baseLine;
        let targetConfiguration = {
          id: baseLine._id + 'target',
          name: gettextCatalog.getString('Target {{equation}}', {
            equation: getEquationString(targetK, targetN)
          }),
          color: color,
          type: 'line',
          dashStyle: 'longdash',
          marker: {
            symbol: 'triangle'
          },
          decimalPrecision,
          nonNumerical: false,
          hideOnGrid: true,
          query: async () => {
            return [
              { x: baseLine.xFrom, y: targetK * baseLine.xFrom + targetN },
              { x: baseLine.xTo, y: targetK * baseLine.xTo + targetN }
            ];
          }
        };
        result = [targetConfiguration, ...result];

        let equation = getEquationString(
          baseLine.baseLineK,
          baseLine.baseLineN
        );

        return [
          ...result,
          {
            id: baseLine._id,
            name: gettextCatalog.getString('Baseline {{equation}}', {
              equation
            }),
            color: color,
            type: 'line',
            marker: {
              symbol: 'square'
            },
            decimalPrecision,
            nonNumerical: false,
            hideOnGrid: true,
            query: async () => {
              return [
                {
                  x: baseLine.xFrom,
                  y: baseLine.baseLineK * baseLine.xFrom + baseLine.baseLineN
                },
                {
                  x: baseLine.xTo,
                  y: baseLine.baseLineK * baseLine.xTo + baseLine.baseLineN
                }
              ];
            }
          }
        ];
      }, []);
      return series;
    }
    return [];
  }
  /**
   * @description returns mode, alternative mode and size setting depending on dashboard card size.
   * @function
   * @param {Object} dashboardSettings
   * @param {Boolean} isPattern pattern chart doesn't have table
   * @return {Object}
   */
  function getModeAndSizes(dashboardSettings = {}, isPattern) {
    switch (dashboardSettings.size) {
    case 1: // small
    case 2: // medium
    case 4: // fullWidth
      return {
        mode: {
          chart: true,
          grid: false,
          gauge: false
        },
        sizes: {
          grid: 331,
          chart: 331
        },
        alternativeMode: {
          chart: false,
          grid: true
        }
      };
    case 3: // large
    case 5: // fullWidthDoubleHeight
    case 6: // singleWidthDoubleHeight
      return {
        mode: {
          chart: true,
          grid: true,
          gauge: false
        },
        sizes: {
          grid: 376,
          chart: 374
        },
        alternativeMode: null
      };
    default:
      return {
        mode: {
          chart: true,
          grid: !isPattern,
          gauge: false
        },
        sizes: {
          grid: 420,
          chart: 480
        },
        alternativeMode: null
      };
    }
  }

  /**
   * @description returns DataXChart configuration.
   * @function
   * @param {Object} mtAnalysisFlow
   * @param {Object} timeSeries mt analysis main timeseries
   * @return {Object}
   */
  async function getValuesChartConfiguration(
    mtAnalysisFlow,
    timeSeries,
    isPattern,
    dashboardSettings
  ) {
    let modeAndSizes = getModeAndSizes(dashboardSettings, isPattern);
    //TRANSLATIONS
    const translations = SfeDataXTranslations.get();
    // /TIMEZONE
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (timeSeries != null && timeSeries.timeZone != null) {
      let timeZoneObject = TranslationService.GetCollectionById(
        'codelists.timeZones',
        timeSeries.timeZone
      );
      if (timeZoneObject != null) {
        timeZone = timeZoneObject.code;
      }
    }

    const decimalPrecision = timeSeries.decimalPrecision || 2;

    const xSuffix = await getPhysicalData(
      mtAnalysisFlow.inputTimeSeriesForXAxis
    );
    const ySuffix = await getPhysicalData(
      mtAnalysisFlow.inputTimeSeriesForYAxis
    );

    let extraColumns = [
      {
        name: 'Date',
        field: 'date',
        formatter: DateLocalizationService.LocalizationDateFn,
        order: -190,
        nonNumerical: true
      }
    ];

    if (!isPattern) {
      extraColumns.push(
        {
          name: gettextCatalog.getString('Calculated Value'),
          field: 'calculatedValue',
          formatter: value => {
            return Formatting.formatNumber(value, decimalPrecision);
          },
          order: -90,
          nonNumerical: false
        },
        {
          name: gettextCatalog.getString('Delta'),
          field: 'delta',
          formatter: value => {
            return Formatting.formatNumber(value, decimalPrecision);
          },
          order: -90,
          nonNumerical: false
        }
      );
    }

    return {
      axis: {
        x: [
          {
            type: 'linear',
            title: mtAnalysisFlow.inputTimeSeriesForXAxis.name,
            formatter: Formatting.formatNumber,
            link: $state.href('data-time-series-view', {
              id: mtAnalysisFlow.inputTimeSeriesForXAxis._id
            }),
            suffix: xSuffix || ''
          }
        ],
        y: [
          {
            title: mtAnalysisFlow.inputTimeSeriesForYAxis.name,
            formatter: Formatting.formatNumber,
            suffix: ySuffix || ''
          }
        ]
      },
      chart: {
        legend: true,
        markers: true,
        dataLabels: false,
        axisYTitle: true,
        axisXTitle: true,
        height: modeAndSizes.sizes.chart
      },
      /**
       * @description returns mt analysis default filter.
       * @function
       * @return {Object}
       */
      filter: () => {
        let limit = 3000;
        if (isPattern) {
          let {
            dateForCalcOfRegressionFuncFrom,
            dateForCalcOfRegressionFuncTo
          } = mtAnalysisFlow;

          let from = new Date(dateForCalcOfRegressionFuncFrom).getTime();
          let to = new Date(dateForCalcOfRegressionFuncTo).getTime();
          return {
            from,
            to,
            limit
          };
        }
        return { limit };
      },
      grid: {
        allowMultipleXValues: true,
        height: modeAndSizes.sizes.grid,
        extraColumns
      },
      alternativeMode: modeAndSizes.alternativeMode,
      mode: modeAndSizes.mode,
      timeZone: timeZone,
      fullscreen: true,
      translations,
      series: [
        {
          id: mtAnalysisFlow._id,
          name: `${mtAnalysisFlow.inputTimeSeriesForYAxis.name}`,
          link: $state.href('data-time-series-view', {
            id: mtAnalysisFlow.inputTimeSeriesForYAxis._id
          }),
          query: getValues(mtAnalysisFlow, isPattern, dashboardSettings),
          nonNumerical: false,
          decimalPrecision,
          type: 'scatter',
          color: '#000000'
        },
        ...buildTargetFunctionsSeries(
          mtAnalysisFlow.targetFunctions,
          decimalPrecision
        )
      ]
    };
  }
  return {
    getValuesChartConfiguration,
    getEquationString
  };
}
export default sfeMtAnalysisService;
