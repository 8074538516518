import './sfe-multi-select.scss';
import template from './sfe-multi-select.component.html';

/**
 * @ngdoc component
 * @name common.sfeMultiSelect
 * @description drop down select component.
 * @param {Array} options array of select menu options
 * @param {Object} model model {_id: value}
 * @param {Object} config configuration object
 * @param {Bool} isDisabled dropdown disabled value
 * @param {string} entity entity string
 * @example
 * <sfe-multi-select
 * options="options"
 * model="'model'"
 * config="'config'"
 * isDisabled="'isDisabled'"
 * entity="'entity'"
 * ></sfe-multi-select>
 */

export default {
  restrict: 'E',
  transclude: true,
  template,
  bindings: {
    options: '=?',
    model: '=',
    config: '<',
    isDisabled: '=?',
    entity: '<',
    multiple: '<'
  },
  controller: SelectController,
  controllerAs: 'vm',
  bindToController: true
};

SelectController.$inject = [
  'EntitiesService',
  '$q',
  'CrawlerMethods',
  'AlertingService',
  '$scope'
];
function SelectController(
  EntitiesService,
  $q,
  CrawlerMethods,
  AlertingService,
  $scope
) {
  var vm = this;
  var selectedParamName;
  vm.fetchOptions = fetchOptions;
  vm.onClose = onClose;
  vm.removeId = removeId;

  var modelWatcher;

  $scope.$on('$destroy', function() {
    if (modelWatcher) {
      modelWatcher();
    }
  });
  /**
   * @description whatches binding changes and initiates component.
   * @function
   * @param {Array} changes array of changed bindings
   */
  vm.$onChanges = function(changes) {
    if (changes.config && vm.config) {
      initiate();
    }
    if (changes.entity && vm.entity) {
      initiateDisplayFields();
      initiateSelectedParameters();
    }
  };
  /**
   * @description initiate display fields.
   * @function
   */
  function initiateDisplayFields() {
    if (vm.entity) {
      var dialogConfiguration = EntitiesService.getDialog(vm.entity[0]);
      if (dialogConfiguration && dialogConfiguration.displayFields) {
        vm.displayFields = dialogConfiguration.displayFields;
      } else {
        vm.displayFields = vm.config.displayOptions || ['name'];
      }
    } else {
      vm.displayFields = vm.config.displayOptions || ['name'];
    }
  }
  /**
   * @description initiates selected parameters.
   * @function
   */
  function initiateSelectedParameters() {
    var entity = vm.entity[0];
    selectedParamName =
      EntitiesService.getSelectedParamName(entity) || vm.config.selectedParam;
  }
  /**
   * @description initiates main properties and sets model watcher on edit form.
   * @function
   */
  function initiate() {
    initiateDisplayFields();

    // default required value is true
    if (typeof vm.config.required === 'undefined') {
      vm.config.required = true;
    }

    if (typeof vm.config.disabledValue === 'undefined') {
      vm.config.disabledValue = false;
    }

    if (vm.config.edit) {
      if (!modelWatcher) {
        modelWatcher = $scope.$watch('vm.model', function() {
          if (vm.model && vm.model._id) {
            prefetchOptions();
            modelWatcher();
          }
        });
      }
    }
  }
  /**
   * @description constructs filter and fetches options on edit.
   * @function
   */
  function prefetchOptions() {
    var obj = {};
    if (vm.config.refreshObjectRequired && vm.config.filterObject) {
      obj = Object.assign(obj, vm.config.filterObject);
    }
    if (vm.config.refreshParam) {
      obj[vm.config.refreshParam.name] = vm.config.refreshParam.value._id;
    }

    if (vm.config.refreshParamValue) {
      obj[vm.config.refreshParamValue.name] = vm.config.refreshParamValue.val;
    }
    if (selectedParamName) {
      obj[selectedParamName] = vm.model._id;
    }
    fetchItems(obj).then(
      function(res) {
        if (res.data) {
          vm.options = res.data;
        } else {
          vm.options = res;
        }
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }
  /**
   * @description fetches items.
   * @function
   * @param {Object} apiObject filter object
   * @return {Promise} promise that  returns items
   */
  function fetchItems(apiObject) {
    var deferred = $q.defer();
    var method;
    if (vm.config.ctrlFn) {
      method = vm.config.ctrlFn;
    } else if (vm.config.refreshFn) {
      method = CrawlerMethods.getMethod(vm.config.refreshFn);
    }
    if (method) {
      method(apiObject).then(
        function(res) {
          deferred.resolve(res);
        },
        function(err) {
          deferred.reject(err);
        }
      );
    } else {
      deferred.resolve(vm.options || []);
    }

    return deferred.promise;
  }

  /**
   * @description fetches option items and triggers all after request functions.
   * @function
   * @param {any} paramValue filter value
   * @return {Promise}
   */
  function fetchOptions(paramValue) {
    var deferred = $q.defer();
    var obj = angular.copy(vm.config.filterObject) || {};

    if (paramValue && vm.config.searchParams) {
      vm.config.searchParams.forEach(function(param) {
        obj[param] = paramValue;
      });
    }

    if (vm.config.refreshParamValues) {
      vm.config.refreshParamValues.forEach(function(paramValue) {
        obj[paramValue.name] = paramValue.value;
      });
    }
    if (vm.config.openAction) {
      vm.config.openAction(function() {
        fetchOptionsHelper(obj, deferred);
      });
    } else {
      fetchOptionsHelper(obj, deferred);
    }
    return deferred.promise;
  }

  /**
   * @description fetches option items and triggers all after request functions.
   * @function
   * @param {any} paramValue filter value
   * @return {Promise}
   */
  function fetchOptionsHelper(obj, deferred) {
    fetchItems(obj).then(
      function(res) {
        // SORTING OF DISPLAYED ITEMS
        var items = [];

        if (res && res.data) {
          items = res.data;
        } else {
          items = res;
        }

        if (vm.config.refreshAction) {
          vm.config.refreshAction(items, res);
        }

        if (vm.config.filterFn) {
          items = vm.config.filterFn(items);
        }

        vm.options = items;
        vm.newOptionsLoading = false;
        deferred.resolve(vm.options);
      },
      function(err) {
        vm.options = [];
        AlertingService.Error(err);
        deferred.reject();
        vm.newOptionsLoading = false;
      }
    );
  }

  /**
   * @description resets search field and triggers on close function.
   * @function
   */
  function onClose() {
    vm.config.searchTerm = '';
    if (vm.config.onClose) {
      vm.config.onClose(vm.model, vm.options);
    }
  }
  /**
   * @description sets model id to undefined.
   * @function
   */
  function removeId() {
    vm.model._id = undefined;
  }
}
