/**
 * @ngdoc component
 * @name invoices.sfe-billing-data-overview
 * @description show a table of billing data for a specific invoice detail and calculates a summary based on energy management group
 * @param {dataType} invoiceDetailId - id of the invoice detail we want billing data overview for
 * @param {dataType} invoiceStatus - Integer that is used to determine if the invoice has been closed.
 * @example
 * <component-name
 * paramName="'paramValue'"
 * ></component-name>
 */
import template from './sfe-billing-data-overview.component.html';
import './sfe-billing-data-overview.component.scss';

export default {
  template,
  bindings: {
    invoiceDetailId: '<',
    invoiceStatus: '<'
  },
  controller: SfeBillingDataOverviewController,
  controllerAs: 'vm'
};

SfeBillingDataOverviewController.$inject = [
  '$scope',
  'BillingOverviewDialog',
  'gettext',
  'MetadataService',
  'AlertingService',
  'BillingOverviewFactory',
  'BillingCalculationDataModel',
  'DetailInvoiceModelBillingDataByEmg'
];

function SfeBillingDataOverviewController(
  $scope,
  BillingOverviewDialog,
  gettext,
  MetadataService,
  AlertingService,
  BillingOverviewFactory,
  BillingCalculationDataModel,
  DetailInvoiceModelBillingDataByEmg
) {
  var vm = this;
  vm.groupBy = 'MeasuringPoint';
  vm.numberOfColumns = 6; //sets how many table columns should be grouped into one in total row

  vm.groupByChanged = groupByChanged;
  vm.filterColumns = filterColumns;
  vm.$onChanges = function() {
    if (vm.invoiceStatus === 21 && vm.invoiceDetailId) {
      prepareBillingOverviewData(vm.invoiceDetailId);
    }
  };
  vm.showBillingCalculationData = false;
  vm.BillingCalculationHeader = [
    {
      name: gettext('Energy management group'),
      order: 'energyManagementGroupName',
      sort: 'energyManagementGroupName',
      text: true
    },
    {
      name: gettext('Measuring Point'),
      order: gettext('submeterMeasuringPointName'),
      sort: 'submeterMeasuringPointName',
      link: true,
      paramStateName: 'measuringPointState',
      paramName: 'id',
      paramValueName: 'submeterMeasuringPointId',
      groupByParam: 'MeasuringPoint'
    },
    {
      name: gettext('Stakeholder'),
      order: gettext('stakeholderName'),
      sort: 'stakeholderName',
      link: true,
      paramStateName: 'stakeholderState',
      paramName: 'id',
      paramValueName: 'stakeholderId'
    },
    {
      name: gettext('Allocation'),
      sort: 'percentageOfAllocation',
      text: true,
      symbol: 'percent',
      alignRight: true,
      numeric: true,
      decimalPlaces: 2
    },
    {
      name: gettext('Quantity'),
      sort: 'quantity',
      text: true,
      symbol: 'prefixAndUnit',
      noOrder: true,
      alignRight: true,
      numeric: true,
      decimalPlaces: 2
    },
    {
      name: gettext('Value'),
      order: 'value',
      sort: 'value',
      text: true,
      symbol: 'currencySymbol',
      alignRight: true,
      numeric: true,
      decimalPlaces: 2
    },
    {
      shortActions: [
        {
          icon: 'info_outline',
          fn: item => {
            BillingOverviewDialog.open(item);
          }
        }
      ],
      groupByParam: 'MeasuringPoint'
    }
  ];

  function filterColumns(column) {
    return !column.groupByParam || column.groupByParam == vm.groupBy;
  }
  /**
   * @description called when group by value changes. Triggers function to fetch billing overview data
   * @function
   * @param {String} value group by value (Stakeholders or MeasuringPoint)
   * @return {dataType}
   */
  function groupByChanged(value) {
    vm.groupBy = value;
    switch (vm.groupBy) {
    case 'MeasuringPoint':
      prepareBillingOverviewData(vm.invoiceDetailId);
      break;
    case 'Stakeholders':
      prepareBillingOverviewDataGroupedByStakeholder();
      break;
    }
  }
  /**
   * @description Fetches billing data grouped by stakeholde.
   * @function
   */
  async function prepareBillingOverviewDataGroupedByStakeholder() {
    vm.loading = true;
    vm.numberOfColumns = 5; //sets how many table columns should be grouped into one in total row
    // eslint-disable-next-line no-case-declarations
    const { data } = await DetailInvoiceModelBillingDataByEmg.read({
      detailInvoiceId: vm.invoiceDetailId
    });
    vm.rows = await BillingOverviewFactory.createTableColumnsGroupedByStakeholders(
      data
    );
    vm.loading = false;
    $scope.$evalAsync();
  }
  /**
   * @description function that is run to get prepare billing
   * @function
   */
  async function prepareBillingOverviewData(invoiceDetailId) {
    vm.loading = true;
    vm.showBillingCalculationData = true;
    vm.numberOfColumns = 6; //sets how many table columns should be grouped into one in total row
    try {
      const {
        data: billingData
      } = await BillingCalculationDataModel.custom.readWithPopulate({
        detailInvoice: invoiceDetailId,
        limit: 1000,
        order: 'order'
      });

      if (billingData && Array.isArray(billingData) && billingData.length > 0) {
        vm.rows = BillingOverviewFactory.createBillingDataOverviewTableRow(
          billingData
        );
        vm.currency = vm.rows.find(item => item.currencySymbol).currencySymbol;
      } else {
        vm.rows = [];
      }
    } catch (err) {
      MetadataService.Loading(false);
      AlertingService.Error(err);
    } finally {
      vm.loading = false;
      $scope.$evalAsync();
    }
  }
}
