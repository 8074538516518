import actionTypes from '../action/times-series-configurations.type';

TimeSeriesConfigurationMiddleware.$inject = [
  'TimeSeriesConfigurationModel',
  'AlertingService'
];
function TimeSeriesConfigurationMiddleware(
  TimeSeriesConfigurationModel,
  AlertingService
) {
  return () => next => async action => {
    switch (action.type) {
    case actionTypes.TIME_SERIES_CONFIGURATIONS_LIST:
      try {
        const result = await TimeSeriesConfigurationModel.read({
          ...action.payload,
          populate: 'flow'
        });

        if (Array.isArray(result.data)) {
          result.data = result.data.map(item => {
            if (typeof item.flow === 'object' && item.flow != null) {
              return {
                ...item,
                flow: item.flow._id,
                flowObject: item.flow
              };
            }
            return item;
          });
        }
        action.payload = {
          ...action.payload,
          result
        };
      } catch (err) {
        AlertingService.Error(err);
      }
      break;
    case actionTypes.TIME_SERIES_CONFIGURATIONS_UPDATE:
      try {
        action.payload.result = await TimeSeriesConfigurationModel.update(
          action.payload.query,
          action.payload.body
        );
      } catch (err) {
        AlertingService.Error(err);
        action.payload.result = err;
      }
      break;
    }
    next(action);
  };
}

export default TimeSeriesConfigurationMiddleware;
