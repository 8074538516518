import basicMapFilterView from './dialog/basic-map-filter/basic-map-filters.view.html';

BasicMapController.$inject = [
  'NgMap',
  '$mdDialog',
  'AlertingService',
  '$timeout',
  'ListItemsService',
  'MapHelperService',
  '$scope'
];

function BasicMapController(
  NgMap,
  $mdDialog,
  AlertingService,
  $timeout,
  ListItemsService,
  MapHelperService,
  $scope
) {
  var vm = this;
  var noResultsInfowindow;
  vm.clusterMarkers = [];
  vm.cluster = null;
  vm.map;
  vm.images = [];
  vm.bottomSheetOpen = false;
  vm.query = {
    limit: 1000,
    order: '_id',
    page: 1,
    select: 'geoLocation,_id,name,address'
  };
  vm.loading = true;

  vm.$onInit = async function init() {
    try {
      const locations = await ListItemsService.fetchAllItems(
        {
          entity: 'locations',
          method: 'read'
        },
        vm.sfeFilters,
        vm.query
      );
      if (locations.length) {
        $timeout(() => {
          addMarkers(locations);
        });
      } else {
        vm.loading = false;
      }
    } catch (err) {
      AlertingService.Error(err);
      vm.loading = false;
    }
  };

  $scope.$on('$destroy', function() {
    google.maps.event.clearListeners(vm.map, 'click');
    google.maps.event.clearListeners(vm.map, 'clusterclick');
  });

  /**
   * @description Adds markers on the map.
   * @function
   * @param {Array} markers Array of markers
   */
  function addMarkers(markers) {
    var bounds = new google.maps.LatLngBounds();
    vm.clusterMarkers = [];

    markers.forEach(function(item) {
      if (item.geoLocation) {
        var latLng = new google.maps.LatLng(
          item.geoLocation[1],
          item.geoLocation[0]
        );
        bounds.extend(latLng);

        // tooltip
        var contentString = 'IME';
        var infowindow = new google.maps.InfoWindow({
          content: contentString
        });

        // marker
        var newMarker = new google.maps.Marker({
          label: {
            text: item.name,
            fontSize: 'sfe-small-text',
            fontWeight: '600',
            fontFamily: '"Roboto", sans-serif'
          },
          icon: {
            url: '/google_maps_markers/ic_place_2x.png',
            labelOrigin: new google.maps.Point(14, -4),
            size: new google.maps.Size(32, 32),
            scaledSize: new google.maps.Size(32, 32)
          },
          position: latLng,
          opacity: 0.9,
          id: item._id,
          item: item
        });

        // on click, get address data and show tooltip
        newMarker.addListener('click', async function() {
          infowindow.setContent('Loading...');
          infowindow.open(vm.map, newMarker);

          const content = await MapHelperService.fetchLocationInfo(item);

          infowindow.setContent(content);
          $scope.$applyAsync();
        });

        vm.clusterMarkers.push(newMarker);
      }
    });

    if (vm.cluster) {
      vm.cluster.clearMarkers();
    }

    vm.loading = false;
    setUpMap(bounds.getCenter(), bounds);
  }

  /**
   * @description Initializes, centres and zooms the map.
   * @function
   * @param {Object} centre Where to centre the map
   * @param {Object} bounds Bounds of map display
   */
  function setUpMap(center, bounds) {
    NgMap.getMap({
      id: 'mapLocations'
    }).then(function(map) {
      vm.map = map;
      map.setCenter(center);
      map.fitBounds(bounds);
      vm.cluster = new MarkerClusterer(map, vm.clusterMarkers, {
        imagePath: '/google_maps_markers/m'
      });
      var clusterInfoWindow = new google.maps.InfoWindow({
        content: 'Loading...'
      });
      google.maps.event.addListener(
        vm.cluster,
        'clusterclick',
        async cluster => {
          const markers = cluster.getMarkers();

          if (Array.isArray(markers)) {
            const clusterMap = cluster.map_;

            const lat = markers[0].position.lat();
            const lng = markers[0].position.lng();
            const samePosition =
              !markers.some(
                marker =>
                  marker.position.lat() != lat || marker.position.lng() != lng
              ) ||
              clusterMap.zoom ===
                clusterMap.mapTypes[vm.map.getMapTypeId()].maxZoom;
            if (samePosition) {
              const promises = markers.map(marker => {
                return MapHelperService.fetchLocationInfo(marker.item);
              });

              clusterInfoWindow.setPosition(cluster.getCenter());
              clusterInfoWindow.open(map);
              clusterInfoWindow.setContent('Loading ...');
              const contentDiv = document.createElement('DIV');
              contentDiv.className = 'info-window-scroller';
              const results = await Promise.all(promises);
              results.forEach(content => {
                contentDiv.append(content);
              });
              clusterInfoWindow.setContent(contentDiv);
              $scope.$applyAsync();
            }
          }
        }
      );
    });
  }

  /**
   * @description Trigges the opening of 'no locations found' message on query with no returned results.
   * @function
   */
  function noResultsMessage() {
    var center = new google.maps.LatLng(46.0569, 14.5058);
    NgMap.getMap({
      id: 'mapLocations'
    }).then(function(map) {
      map.setCenter(center);
      noResultsInfowindow = new google.maps.InfoWindow({
        content: 'No locations found'
      });
      noResultsInfowindow.setPosition({ lat: 46.0569, lng: 14.5058 });
      noResultsInfowindow.open(map);
    });
  }

  function fetchingData(fetching) {
    vm.fetchingData = fetching;
  }

  /**
   * @description Trigges the opening of filters bottom sheet.
   * @function
   */
  vm.toggleFilters = function() {
    if (vm.bottomSheetOpen) {
      vm.bottomSheetOpen = false;
      $mdDialog.cancel();
    } else {
      vm.bottomSheetOpen = true;

      vm.query.page = 1;
      $mdDialog
        .show({
          template: basicMapFilterView,
          controller: 'BasicMapFiltersController',
          controllerAs: 'vm',
          theme: 'tango',
          locals: {
            filterObject: vm.query,
            fetchCallback: fetchingData
          }
        })
        .then(function(markers) {
          vm.bottomSheetOpen = false;
          if (markers && markers.length > 0) {
            if (noResultsInfowindow) {
              noResultsInfowindow.close();
            }
            addMarkers(markers);
          } else {
            vm.cluster.clearMarkers();
            noResultsMessage();
          }
        })
        .catch(function() {
          vm.bottomSheetOpen = false;
        });
    }
  };
}

export default BasicMapController;
