module.exports = {
  entity: 'cost-centres',
  path: 'cost-centres',
  entityId: 4,
  name: {
    en: 'Cost centre',
    sl_SI: 'Stroškovno mesto'
  },
  networkModel: {
    entity: 'cost-centres',
    read: 'read'
  },
  scope: 'tis_be:cost-centres',
  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select cost centre',
      sl_SI: 'Izberite stroškovno mesto'
    },
    listInfo: {
      en: 'Select cost centre from the list below',
      sl_SI: 'Izberite stroškovno mesto s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      },
      {
        field1: 'code',
        type: 'code',
        title: 'Code'
      },
      {
        field1: 'externalCode',
        type: 'code',
        title: 'External Code'
      }
    ]
  },
  displayFields: [
    {
      field1: 'name',
      type: 'string'
    },
    {
      field1: 'code',
      type: 'code',
      title: 'Code'
    },
    {
      field1: 'externalCode',
      type: 'code',
      title: 'External Code'
    }
  ],
  sfeTree: {
    showConnectedEntities: false,
    route: 'cost-centres',
    parentEntity: 'company-structure-nodes',
    parentKey: 'companyStructureNode',
    icon: 'costCentre', // mora se fixat da bo profit-centre, pol se pa se mora popravit v company resources view data servicu
    childrenConfiguration: [
      {
        type: 'simple',
        usedIn: [
          'company-resources-company-structure',
          'company-resources-cost-centres-list'
        ],
        route: 'cost-centres',
        childKey: 'parentId',
        existenceParam: 'hasChild',
        icon: 'costCentre'
      },
      {
        type: 'simple',
        usedIn: ['company-resources-company-structure'],
        route: 'locations',
        childKey: 'costCentre',
        existenceParam: 'hasLocation',
        icon: 'location'
      }
    ]
  }
}
