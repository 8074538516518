import { DateTime } from 'luxon';

ManualInputFormHelper.$inject = [
  'gettextCatalog',
  'TimeSeriesProcessingValuesModel',
  'TranslationService',
  'AlertingService',
  '$document',
  'DateAutocomplete'
];
function ManualInputFormHelper(
  gettextCatalog,
  TimeSeriesProcessingValuesModel,
  TranslationService,
  AlertingService,
  $document,
  DateAutocomplete
) {
  /**
   * @description determines disabled status.
   * @function
   * @param {Object} valueObject {validAt}
   * @param {Object} timeSeries
   * @param {Date} now
   * @return {Boolean}
   */
  function checkFutureInputAllowed({ validAt }, timeSeries, now) {
    //REGULAR SERIES
    if (
      timeSeries.dataScheduler != null &&
      !timeSeries.dataScheduler.isValid(new Date(validAt))
    ) {
      return false;
    }
    //DISABLE HISTORY INPUTS
    if (
      timeSeries.limitHistoryTime &&
      validAt < now - timeSeries.limitHistoryTime
    ) {
      return false;
    }
    //DISABLE FUTURE INPUTS
    if (!timeSeries.allowFutureInput && validAt > now) {
      return false;
    }
    return true;
  }

  /**
   * @description returns time series mapped to input with current values.
   * @function
   * @param {Array} timeSeries array of timeSeries
   * @param {Array} values
   * @return {Array}
   */
  function getInputAttributes(dataType) {
    let pattern = '';
    let placeholder = null;
    switch (dataType) {
    case 1:
      placeholder = gettextCatalog.getString('Boolean');
      break;
    case 2:
      placeholder = gettextCatalog.getString('Enter integer');
      break;
    case 3:
      placeholder = gettextCatalog.getString('Enter decimal.');
      break;
    case 4:
      placeholder = gettextCatalog.getString('Enter any string');
      break;
    case 5:
      placeholder = gettextCatalog.getString('yyyy-mm-dd');
      break;
    case 6:
    case 7:
      placeholder = gettextCatalog.getString('hh:mm:ss');
      pattern = /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
      break;
    }

    return {
      placeholder,
      pattern
    };
  }

  /**
   * @description sets switch display value on change.
   * @function
   * @param {Object} timeSeries timeSeries object
   * @param {number} index date index
   */
  function switchChanged(timeSeries, index) {
    const value = timeSeries.modelValues[index].value;
    timeSeries.modelValues[index].displayValue =
      value == undefined
        ? gettextCatalog.getString('Unknown')
        : String(value)
          .charAt(0)
          .toUpperCase() + String(value).slice(1);
  }

  /**
   * @description creates link to redirect to time series item view.
   * @function
   * @param {string} id time series
   * @return {string}
   */
  function constructLink(id) {
    const param = JSON.stringify({ id });
    return `data-time-series-view(${param})`;
  }

  /**
   * @description convert local date to time series timezone.
   * @function
   * @param {timestamp} date
   * @param {string} timeZoneCode  * @return {dataType}
   * @returns {timestamp}
   */
  function constructFilterDays(date, timeZoneCode) {
    let valueDate = DateTime.fromMillis(date).toObject();

    return DateTime.fromObject({
      ...valueDate,
      zone: timeZoneCode
    }).toMillis();
  }

  /**
   * @description returns currently valid time series values for selected dates.
   * @function
   * @param {Array} timeSeries
   * @param {Object} range {from, to}
   * @return {Object}
   */
  async function getTimeSeriesCurrentValues(timeSeries, range) {
    const filterObject = {
      from: range.from,
      to: range.to,
      view: 'simple'
    };
    const promises = timeSeries.map(item => {
      if (item.timeZone != null) {
        const timeZone = TranslationService.GetCollectionById(
          'codelists.timeZones',
          item.timeZone
        );
        if (timeZone != null) {
          const from = constructFilterDays(range.from, timeZone.code);
          const to = constructFilterDays(range.to, timeZone.code);

          filterObject.from = from;
          filterObject.to = to;
        }
      }

      return TimeSeriesProcessingValuesModel.read({
        ...filterObject,
        timeSeriesId: item._id
      });
    });

    try {
      const valuesResult = await Promise.allSettled(promises);
      return valuesResult.reduce((result, item, index) => {
        if (
          item.status === 'fulfilled' &&
          item.value != null &&
          Array.isArray(item.value.data)
        ) {
          const values = parseValuesDates(
            item.value.data,
            timeSeries[index].timeZone
          );
          return {
            ...result,
            [timeSeries[index]._id]: values
          };
        }
        return result;
      }, {});
    } catch (err) {
      AlertingService.Error(err);
      return {};
    }
  }

  /**
   * @description sets values valid at to local time zone.
   * @function
   * @param {Array} values
   * @param {number} timeZone time series time zone codelist id
   * @return {Array}
   */
  function parseValuesDates(values, timeZone) {
    const timeZoneObject = TranslationService.GetCollectionById(
      'codelists.timeZones',
      timeZone
    );
    if (timeZoneObject != null) {
      return values.map(value => {
        const validAt = convertTimestampsWitTimeZone(
          value.validAt,
          timeZoneObject.code
        );
        return {
          ...value,
          validAt
        };
      });
    }
    return values;
  }
  /**
   * @description convert timestamp to the local timestamp.
   * @function
   * @param {timestamp} date
   * @param {string} timeZoneCode
   * @return {timestamp}
   */
  function convertTimestampsWitTimeZone(date, timeZoneCode) {
    let valueDate = DateTime.fromMillis(date)
      .setZone(timeZoneCode)
      .toObject();
    return DateTime.fromObject({ ...valueDate }).toMillis();
  }

  /**
   * @description returns last default value.
   * @function
   * @param {Array} manualInputGroups
   * @return {Array}
   */
  async function getLastValueDefaultValue(manualInputGroups) {
    const allIds = manualInputGroups.reduce((result, group) => {
      const ids = group.timeSeriesItems.reduce(
        (timeSeriesIds, timeSeriesItem) => {
          if (timeSeriesItem.defaultValueType == 1) {
            //LAST VALUE
            return [...timeSeriesIds, timeSeriesItem.timeSeries];
          }
          return timeSeriesIds;
        },
        []
      );
      return [...result, ...ids];
    }, []);
    const promises = allIds.map(id => {
      return TimeSeriesProcessingValuesModel.read({
        limit: 1,
        timeSeriesId: id
      });
    });
    const valuesResult = await Promise.allSettled(promises);
    return valuesResult.reduce((result, item, index) => {
      if (
        item.status === 'fulfilled' &&
        item.value != null &&
        Array.isArray(item.value.data)
      ) {
        return {
          ...result,
          [allIds[index]]: item.value.data
        };
      }
      return result;
    }, {});
  }

  /**
   * @description returns default values out of time series.
   * @function
   * @param {Array} manualInputGroups
   * @param {Object} range {from, to}
   * @return {Promise}
   */
  async function getTimeSeriesDefaultValue(
    manualInputGroups,
    range,
    defaultTimeSeries = []
  ) {
    const allIds = manualInputGroups.reduce((result, group) => {
      const ids = group.timeSeriesItems.reduce(
        (timeSeriesIds, timeSeriesItem) => {
          if (timeSeriesItem.defaultValueType == 2) {
            const defaultTimeSeriesItem = defaultTimeSeries.find(
              item => item._id === timeSeriesItem.defaultValueTimeSeriesId
            );
            //LAST VALUE
            return [
              ...timeSeriesIds,
              {
                defaultValueTimeSeriesId:
                  timeSeriesItem.defaultValueTimeSeriesId,
                id: timeSeriesItem.timeSeries,
                timeZone: defaultTimeSeriesItem.timeZone
              }
            ];
          }
          return timeSeriesIds;
        },
        []
      );
      return [...result, ...ids];
    }, []);
    const promises = allIds.map(item => {
      let from = range.from;
      let to = range.to;
      const timeZone = TranslationService.GetCollectionById(
        'codelists.timeZones',
        item.timeZone
      );

      if (timeZone != null) {
        from = constructFilterDays(from, timeZone.code);
        to = constructFilterDays(to, timeZone.code);
      }
      return TimeSeriesProcessingValuesModel.read({
        limit: 30000,
        timeSeriesId: item.defaultValueTimeSeriesId,
        from,
        to
      });
    });

    const valuesResult = await Promise.allSettled(promises);
    return valuesResult.reduce((result, item, index) => {
      if (
        item.status === 'fulfilled' &&
        item.value != null &&
        Array.isArray(item.value.data)
      ) {
        let values = item.value.data;
        if (allIds[index].timeZone != null) {
          values = parseValuesDates(values, allIds[index].timeZone);
        }
        return {
          ...result,
          [allIds[index].id]: values
        };
      }
      return result;
    }, {});
  }

  /**
   * @description assigns manual input values to scope.
   * @function
   * @param {Object} params
   */
  function constructManualInputFrom(params) {
    const {
      manualInput,
      timeSeries,
      lastValueDefaultValue,
      timeSeriesDefaultValue,
      dates,
      timeSeriesCurrentValues
    } = params;

    const allTimeSeries = manualInput.groups.reduce((result, group) => {
      let mappedTimeSeries = group.timeSeriesItems.map((item, index) => {
        let timeSeriesItem = timeSeries.find(ts => ts._id === item.timeSeries);

        const timeSeriesInputObject = createSingleInput({
          lastValueDefaultValue,
          timeSeriesDefaultValue,
          manualInputTimeSeries: item,
          timeSeries: timeSeriesItem,
          dates,
          timeSeriesCurrentValues
        });

        return {
          ...timeSeriesInputObject,
          group: {
            name: group.name,
            numberOfTimeSeries: group.timeSeriesItems.length,
            index
          }
        };
      });
      return [...result, ...mappedTimeSeries];
    }, []);

    const groups = manualInput.groups.map(group => {
      return {
        name: group.name,
        numberOfTimeSeries: group.timeSeriesItems.length
      };
    });

    return {
      manualInput: { groups },
      globalTimeSeries: allTimeSeries
    };
  }

  /**
   * @description returns configuration for single time series values input.
   * @function
   * @param {Object} params
   * @return {Object}
   */
  function createSingleInput(params) {
    const {
      lastValueDefaultValue,
      timeSeriesDefaultValue,
      manualInputTimeSeries,
      timeSeries,
      dates,
      timeSeriesCurrentValues
    } = params;
    let defaultValues = [];
    switch (manualInputTimeSeries.defaultValueType) {
    case 1:
      defaultValues = lastValueDefaultValue[manualInputTimeSeries.timeSeries];
      defaultValues = lastValueDefaultValue[manualInputTimeSeries.timeSeries];
      break;
    case 2:
      defaultValues =
          timeSeriesDefaultValue[manualInputTimeSeries.timeSeries];
    }
    //REGULAR SERIES
    let dataScheduler;
    if (timeSeries.dataScheduler != null) {
      dataScheduler = later.schedule(
        later.parse.cron(timeSeries.dataScheduler.crontabExpression, true)
      );
    }
    const modelValues = getTimeSeriesModelValues(
      {
        ...manualInputTimeSeries,
        dataType: timeSeries.dataType,
        dataScheduler
      },
      dates,
      timeSeriesCurrentValues[manualInputTimeSeries.timeSeries],
      defaultValues
    );
    const inputAttributes = getInputAttributes(timeSeries.dataType);
    const link = constructLink(timeSeries._id);
    return {
      ...manualInputTimeSeries,
      ...inputAttributes,
      link,
      _id: timeSeries._id,
      name: timeSeries.name,
      modelValues,
      dataType: timeSeries.dataType,
      inputType:
        timeSeries.dataType == 3 || timeSeries.dataType == 2
          ? 'numerical'
          : undefined,
      onlyInteger: timeSeries.dataType == 2,
      values: timeSeriesCurrentValues[manualInputTimeSeries.timeSeries]
    };
  }

  /**
   * @description returns array of values .
   * @function
   * @param {Object} timeSeries  manual input time series
   * @param {Array} dates array of available dates
   * @param {Array} currentValues time series currently valid values
   * @param {Array} defaultValues default values
   * @return {Array}
   */
  function getTimeSeriesModelValues(
    timeSeries,
    dates,
    currentValues,
    defaultValues = []
  ) {
    return dates.map(({ validAt }) => {
      let currentValue = currentValues.find(value => value.validAt == validAt);
      let originalValue;
      if (currentValue != null) {
        originalValue = currentValue.value;
      }
      const inputIsAllowed = checkFutureInputAllowed(
        { validAt },
        timeSeries,
        Date.now()
      );
      let value;
      let defaultValue = false;
      if (currentValue != null) {
        value = currentValue.value;
      } else if (inputIsAllowed) {
        switch (timeSeries.defaultValueType) {
        case 1: ///lastValue
          if (defaultValues.length > 0) {
            value = defaultValues[0].value;
            defaultValue = true;
          }
          break;
        case 2: //times series
          // eslint-disable-next-line no-case-declarations
          let tsDefaultValue = defaultValues.find(
            value => value.validAt == validAt
          );
          if (tsDefaultValue != null) {
            value = tsDefaultValue.value;
            defaultValue = true;
          }
          break;
        case 3: //literal
          value = timeSeries.defaultValue;
          defaultValue = true;
          break;
        }
      }
      let date;
      //DATE DATETIME
      if (
        (timeSeries.dataType == 5 || timeSeries.dataType == 7) &&
        value != null
      ) {
        date = new Date(Number(value));
      }
      if (
        (timeSeries.dataType == 6 || timeSeries.dataType == 7) &&
        value != null
      ) {
        //TIME DATETIME
        value = DateTime.fromJSDate(new Date(Number(value))).toFormat(
          'hh:mm:ss'
        );
      }
      // BOOLEAN DISPLAY VALUE
      let displayValue;

      if (timeSeries.dataType == 1) {
        /* Boolean */
        displayValue =
          value == null
            ? gettextCatalog.getString('Unknown')
            : String(value)
              .charAt(0)
              .toUpperCase() + String(value).slice(1);
      }

      return {
        displayValue,
        validAt,
        validCron: true,
        inputIsAllowed,
        value,
        defaultValue,
        date,
        originalValue
      };
    });
  }

  function dateAutocomplete(index, id, modelObject) {
    const htmlClass = `.form${index}${id}`;
    const inputDateValue = $document[0]
      .querySelectorAll(htmlClass)[0]
      .querySelectorAll('input')[0].value;
    const parsedValue = DateAutocomplete.get(inputDateValue);
    modelObject.date = parsedValue;
  }

  return {
    constructLink,
    checkFutureInputAllowed,
    getInputAttributes,
    switchChanged,
    getTimeSeriesCurrentValues,
    getLastValueDefaultValue,
    getTimeSeriesDefaultValue,
    constructManualInputFrom,
    getTimeSeriesModelValues,
    dateAutocomplete
  };
}
export default ManualInputFormHelper;
