SfeList2ViewController.$inject = ['$state'];

function SfeList2ViewController($state) {
  var vm = this;
  var stateName = $state.current.name;
  var stateNameEntityName = [
    // alarms
    {
      state: 'alarms-and-rules-sop-steps-list',
      entity: 'sop-steps'
    },
    {
      state: 'alarms-and-rules-alarm-definitions-list',
      entity: 'alarm-definitions'
    },
    {
      state: 'alarms-and-rules-sop-list',
      entity: 'sops'
    },
    {
      state: 'alarms-and-rules-sop-tasks-list',
      entity: 'sop-tasks'
    },
    // company resources
    {
      state: 'company-resources-assets-list',
      entity: 'assets'
    },
    {
      state: 'company-resources-business-partners-list',
      entity: 'business-partners'
    },
    {
      state: 'company-resources-cost-centres-list',
      entity: 'cost-centres'
    },
    {
      state: 'company-resources-locations-list',
      entity: 'locations'
    },
    {
      state: 'company-resources-measuring-points-list',
      entity: 'measuringpoints'
    },
    {
      state: 'company-resources-projects-list',
      entity: 'projects'
    },
    // config
    {
      state: 'configurations-dynamic-attributes-domains-list',
      entity: 'domains'
    },
    {
      state: 'configurations-dynamic-attributes-dynamic-attributes-list',
      entity: 'domain-attributes'
    },
    {
      state: 'dashboards-dashboard-playlists-list',
      entity: 'dashboard-playlists'
    },
    // data
    {
      state: 'data-time-series-list',
      entity: 'time-series'
    },
    {
      state: 'data-time-series-group-list',
      entity: 'time-series-groups'
    },
    {
      state: 'alarms-and-rules-mapping-rules-list',
      entity: 'mapping-rules'
    },
    {
      state: 'alarms-and-rules-rules-list',
      entity: 'rules'
    },
    {
      state: 'data-schedules-list',
      entity: 'schedulers'
    },
    // devices
    {
      state: 'device-application-list',
      entity: 'applications'
    },
    {
      state: 'external-datasources-external-datasources-list',
      entity: 'external-datasources'
    },
    {
      state: 'external-datasources-tango-agent-connection-config-list',
      entity: 'tango-agent-connection-configs'
    },
    // energy management
    {
      state: 'analytics-aggregation-groups-list',
      entity: 'aggregation-groups'
    },
    {
      state: 'analytics-analyses-list',
      entity: 'analyses'
    },
    {
      state: 'analytics-calculations-list',
      entity: 'calculations'
    },
    {
      state: 'analytics-datasets-list',
      entity: 'datasets'
    },
    {
      state: 'analytics-expressions-list',
      entity: 'expressions'
    },
    {
      state: 'analytics-time-interval-filters-list',
      entity: 'time-interval-filters'
    },
    {
      entity: 'master-invoices',
      state: 'invoices-invoices'
    },
    {
      state: 'invoices-price-lists',
      entity: 'price-lists'
    },
    // reporting
    {
      state: 'analytics-reports-list',
      entity: 'reports'
    },
    // user
    {
      state: 'users-groups-list',
      entity: 'groups'
    },
    {
      state: 'users-users-list',
      entity: 'users'
    },
    {
      state: 'company-resources-profit-centres-list',
      entity: 'profit-centres'
    },
    // configurations
    {
      state: 'configurations-energy-management-tariffs-list',
      entity: 'tariffs'
    },
    {
      state: 'configurations-alarm-configuration-alarm-types-list',
      entity: 'alarm-types'
    },
    {
      state: 'configurations-energy-management-billing-types-list',
      entity: 'billing-types'
    },
    {
      state: 'configurations-energy-management-billing-kinds-list',
      entity: 'billing-kinds'
    },
    {
      state: 'configurations-energy-management-counter-types-list',
      entity: 'counter-types'
    },
    {
      state: 'configurations-company-resources-company-structure-nodes-list',
      entity: 'company-structure-nodes'
    },
    {
      state: 'configurations-company-resources-ownerships-list',
      entity: 'ownerships'
    },
    // general
    {
      state: 'configurations-general-national-holidays-list',
      entity: 'national-holidays'
    },
    {
      state: 'configurations-general-document-classifications-list',
      entity: 'document-classifications'
    },
    {
      state: 'configurations-energy-management-energy-management-items-list',
      entity: 'energy-management-groups'
    },
    {
      state: 'configurations-energy-management-price-list-item-groups-list',
      entity: 'price-list-item-groups'
    },
    {
      state: 'configurations-alarm-configuration-severities-list',
      entity: 'severities'
    },
    {
      state: 'data-visualization-visualizations-list',
      entity: 'visualizations'
    },
    {
      state: 'data-mat-list',
      entity: 'mt-analyses'
    },
    {
      state: 'data-manual-input-list',
      entity: 'manual-inputs'
    },
    {
      state: 'maps-gis-maps',
      entity: 'gis-map'
    },
    {
      state: 'dashboards-dashboards-list',
      entity: 'dashboards'
    }
  ];

  stateNameEntityName.forEach(function(item) {
    if (item.state === stateName) {
      vm.entityName = item.entity;
    }
  });
}

export default SfeList2ViewController;
