import template from './sfe-item-selector.directive.html';
import './sfe-select.scss';

/**
 * @ngdoc directive
 * @name common.sfeItemSelector
 * @description A directive used for selecting items.
 * @param {Object} configuration Configuration of the entity.
 * @param {Array} selectedItems Selected items.
 * @param {Array} idsToExclude Array of ids of items to not be included in the list.
 * @param {Function} prefetchFunction Used for prefetching items for the list.
 * @example
 * <sfe-item-selector
 * configuration="vm.configuration"
 * selectedItems="vm.obj.selectedItems"
 * idsToExclude="vm.excludeItems"
 * prefetchFunction="vm.prefetchFunction"
 * ></sfe-item-selector>
 */

export default function sfeItemSelector() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      configuration: '<',
      selectedItems: '=',
      idsToExclude: '<',
      prefetchFunction: '<'
    },
    link: linkFn,
    controller: sfeItemSelectorController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  function linkFn(scope, el, attrs, ctrl) {
    let watchPath;
    let watchRelist;
    let watchDomainValues;
    scope.$on('$destroy', function() {
      if (watchPath) {
        watchPath();
      }
      if (watchRelist) {
        watchRelist();
      }
      if (watchDomainValues) {
        watchDomainValues();
      }
    });
    watchPath = scope.$watch('vm.configuration.path', function() {
      if (ctrl.configuration && ctrl.configuration.path) {
        ctrl.init();
      }
    });

    watchDomainValues = scope.$watch(
      'vm.configuration.domainValues',
      function() {
        if (ctrl.configuration && ctrl.configuration.domainValues) {
          ctrl.init();
        }
      }
    );
    watchRelist = scope.$watch('vm.relistData', function() {
      if (ctrl.relistData) {
        ctrl.selectedItems = [];
        ctrl.filterData();
      }
    });
  }
}

sfeItemSelectorController.$inject = [
  'AlertingService',
  'ListItemsService',
  'StorageService',
  'SfeFilterService',
  'EntitiesService',
  'StandardUtils',
  'TranslationService',
  'filterService',
  '$timeout'
];

function sfeItemSelectorController(
  AlertingService,
  ListItemsService,
  StorageService,
  SfeFilterService,
  EntitiesService,
  StandardUtils,
  TranslationService,
  filterService,
  $timeout
) {
  const vm = this;
  let currentPage;
  let limit;
  let fetchItemsPromiseToken;
  vm.fetchItems = fetchItems;
  vm.filterData = filterData;
  vm.toggle = toggle;
  vm.isObject = isObject;
  vm.init = init;

  /**
   * @description Initializes the starting parameters and fetches items for the list.
   * @function
   */
  function init() {
    currentPage = undefined;
    limit = undefined;
    vm.data = [];
    const filters = StorageService.getFilters(vm.configuration.path);

    SfeFilterService.assignSelectedFiltersValues(
      vm.configuration.filter,
      filters
    );
    fetchItems();
  }

  /**
   * @description Checks the checkbox of the item with the given index and pushes it into the data array if it is checked, else it unchecks it and removes it from the data array.
   * @function
   * @param {number} index Index of the item to be checked.
   */
  function toggle(index) {
    if (vm.configuration.multiple) {
      if (!vm.selectedItems) {
        vm.selectedItems = [];
      }
      if (vm.data[index].selected) {
        vm.selectedItems.push(vm.data[index]);
      } else {
        _.remove(vm.selectedItems, {
          _id: vm.data[index]._id
        });
      }
    } else {
      _.each(vm.data, function(element, i) {
        if (i !== index) {
          element.selected = false;
        }
      });
      vm.selectedItems = [];
      if (vm.data[index].selected) {
        vm.data[index].entity = vm.configuration.entity;
        vm.selectedItems.push(vm.data[index]);
      }
    }
  }

  /**
   * @description Resets the data parameters and triggers fetching of the items.
   * @function
   */
  function filterData() {
    currentPage = undefined;
    vm.data = [];
    vm.relistData = false;
    vm.loadMore = false;
    fetchItems(true);
  }

  /**
   * @description Checks if selected item is an object or null.
   * @function
   * @param {dataType} item Item to be checked whether it is an object or null.
   * @return {Boolean} Returns the evaluation of the expression whether the item that is being checked is an object or null.
   */
  function isObject(item) {
    return typeof item === 'object';
  }

  /**
   * @description allows to cancel async/await promise
   * if another one is triggered while the first one is still executing.
   * @function
   * @param {Object} token will contain cancel promise trigger
   * @param {Object} endpointService Containing endpoint and method that needs to be used.
   * @param {Object} apiObj Object with parameters by which the query should ask the server about its data.
   * @return {Promise}
   */
  async function fetchData(token, endpointService, apiObj) {
    let cancelled = false;

    /**
     * @description when cancelled flag is set to true we reject async promise.
     * @function
     */
    token.cancel = () => {
      cancelled = true;
    };

    const res = await wrapWithCancel(ListItemsService.fetchItems)(
      endpointService,
      vm.configuration.filter,
      apiObj
    );
    /**
     * we'll just fall through to this point, without calling any of
     * actual functions. We also can't reject by ourselves, since
     * we don't have control over returned promise.
     */
    if (cancelled) {
      throw { reason: 'cancelled' };
    }

    return res;
    /**
     * @description prevents function from being triggered if promise was canceled
     * @function
     * @param {function} promiseFunction we want ti trigger
     * @return {function} that accepts promiseFunctions params
     */
    function wrapWithCancel(promiseFunction) {
      return (...args) => {
        if (!cancelled) {
          return promiseFunction(...args);
        }
      };
    }
  }

  /**
   * @description Lists the fetched items.
   * @function
   * @param {Object} endpointService Containing endpoint and method that needs to be used.
   * @param {Object} apiObj Object with parameters by which the query should ask the server about its data.
   */
  async function listItemsWrapper(endpointService, apiObj, loadMore) {
    //reset cancel token
    fetchItemsPromiseToken = {};
    try {
      const res = await fetchData(
        fetchItemsPromiseToken,
        endpointService,
        apiObj
      );
      if (vm.data && vm.data.length && loadMore) {
        vm.data = _.concat(vm.data, res.data);
      } else {
        vm.data = res.data;
      }
      if (vm.configuration.path === 'users') {
        vm.data.forEach(function(item) {
          item.imageUrl = StandardUtils.getUserImageUrl(item, 'sm');
          item.showImage = true;
        });
      }

      if (res.pagination) {
        currentPage = res.pagination.page;
        limit = res.pagination.per_page;
        var numberOfExcludedItems = vm.idsToExclude
          ? vm.idsToExclude.length
          : 0;
        if (
          currentPage * res.pagination.per_page - numberOfExcludedItems <
          res.pagination.total
        ) {
          vm.loadMore = true;
        } else {
          vm.loadMore = false;
        }
      }
      // vm.idsToExclude is an array of ids of items that should not be included in the list
      if (vm.idsToExclude) {
        vm.idsToExclude.forEach(function(idsToExcludeId) {
          vm.data = vm.data.filter(function(item) {
            return item._id !== idsToExcludeId;
          });
        });
      }

      if (vm.configuration.filterFn) {
        vm.data = vm.configuration.filterFn(vm.data);
      }
    } catch (err) {
      if (err.reason !== 'cancelled') {
        AlertingService.Error(err);
      }
    }
    $timeout(() => {
      vm.dataIsLoading = false;
    });
  }

  /**
   * @description Fetches items.
   * @function
   * @param {boolean} saveFilters Save filters only if relist is triggered from filter component.
   */
  function fetchItems(saveFilters, loadMore) {
    if (
      vm.dataIsLoading &&
      fetchItemsPromiseToken &&
      typeof fetchItemsPromiseToken.cancel === 'function'
    ) {
      fetchItemsPromiseToken.cancel();
    }
    vm.dataIsLoading = true;
    var apiObj = angular.copy(vm.configuration.filterObject) || {};
    if (vm.configuration.filterObjectFn) {
      apiObj = {
        ...apiObj,
        ...vm.configuration.filterObjectFn()
      };
    }
    if (typeof currentPage !== 'undefined') {
      apiObj.page = currentPage + 1;
      apiObj.limit = limit;
    }

    vm.displayFields = vm.configuration.displayFields ||
      EntitiesService.getDisplayFieldsByPath(vm.configuration.path) || ['name'];
    if (saveFilters) {
      //save filters only if relist triggered from filter component
      StorageService.saveListData(
        vm.configuration.path,
        vm.configuration.filter
      );
    }
    if (vm.configuration.codelist) {
      vm.data = filterService.filterData(
        TranslationService.GetCollection(
          'codelists.' + vm.configuration.codelist
        ),
        vm.configuration.filter
      );
      if (vm.configuration.filterFn) {
        vm.data = vm.configuration.filterFn(vm.data);
      }
      vm.dataIsLoading = false;
    } else if (vm.configuration.domainValues) {
      let filteredDomainItems = filterService.filterData(
        vm.configuration.domainValues,
        vm.configuration.filter
      );
      if (vm.configuration.filterFn) {
        vm.data = vm.configuration.filterFn(filteredDomainItems);
      } else {
        vm.data = filteredDomainItems;
      }
      vm.dataIsLoading = false;
    } else {
      var networkModel = EntitiesService.getNetworkModelByEntity(
        vm.configuration.entity
      );
      var endpointService;
      if (!networkModel) {
        /*eslint no-console: ["error", { allow: ["error"] }] */
        console.error(
          'There is no network configuration for ' + vm.configuration.path
        );
      } else {
        //checks if there is a custom dialog method in the entity (example master-invoices)
        if (networkModel.dialogMethod) {
          endpointService = networkModel.dialogMethod;
        } else {
          endpointService = {
            entity: networkModel.entity,
            method: networkModel.read
          };
        }
      }

      if (vm.prefetchFunction) {
        var result = vm.prefetchFunction(vm.configuration.filter, !saveFilters);
        // check if prefetch method is async
        // when function resolves items fetch is triggered
        if (result && result.promise) {
          result.then(
            function() {
              listItemsWrapper(endpointService, apiObj, loadMore);
            },
            function(err) {
              if (err) {
                vm.dataIsLoading = false;
                AlertingService.Error(err);
              }
            }
          );
        } else {
          // if prefetch method is sync
          // in case of error result must be an object that contains error key
          if (result.error) {
            AlertingService.Error(result.error);
            vm.dataIsLoading = false;
          } else {
            listItemsWrapper(endpointService, apiObj, loadMore);
          }
        }
      } else {
        listItemsWrapper(endpointService, apiObj, loadMore);
      }
    }
  }
}
