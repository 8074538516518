import './sfe-list-table.scss';
import template from './sfe-list-table.component.html';

export default {
  template,
  bindings: {
    entity: '@',
    values: '<',
    listConfig: '<',
    objectManipulation: '<',
    paginationLimit: '<',
    paginationPage: '<',
    loadingStatus: '='
  },
  controller: SfeListTableController,
  controllerAs: 'vm'
};

SfeListTableController.$inject = ['SfeListConfigurations'];

function SfeListTableController(SfeListConfigurations) {
  var vm = this;

  this.$onInit = function() {
    if (vm.listConfig) {
      vm.config = vm.listConfig;
    } else {
      vm.config = angular.copy(SfeListConfigurations.configuration[vm.entity]);
    }
  };
}
