NotificationModel.$inject = ['ModelUtilities'];

function NotificationModel(ModelUtilities) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'notification',
    path: 'notifications'
  };

  const networkConfiguration3 = {
    host: 'notification',
    path: 'seen'
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      markSeen: ModelUtilities.crud('POST', networkConfiguration3, modelStorage)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default NotificationModel;
