/**
 * @ngdoc service
 * @name common.AssignFilterParameters
 * @description functions that assigns filter parameters.
 * @property {function} assignFilterParameters - See method assignFilterParameters.
 */

/**
 * @memberof common.assignFilterParameters
 * @function
 * @description helper method to filter only unique items
 * @return {bool} item exist
 */
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

/**
 * @description creates new object out of two inputs, if object has the same keys instead of overriding it creates array that contains both values.
 * @function
 * @param {Object} currentFilterParameters current filter object
 * @param {Object} newFilterParameters object of additional filter parameters
 * @return {Object}
 */
function AssignFilterParameters() {
  function mergeExistingAndNewFilters(
    currentFilterParameters,
    newFilterParameters
  ) {
    var filter = Object.assign({}, currentFilterParameters);
    var newFilter = Object.assign({}, newFilterParameters);
    if (newFilterParameters) {
      Object.keys(newFilter).forEach(function(key) {
        if (filter[key]) {
          if (Array.isArray(newFilter[key]) && Array.isArray(filter[key])) {
            newFilter[key] = newFilter[key].concat(filter[key]);
          } else if (
            Array.isArray(newFilter[key]) &&
            !Array.isArray(filter[key])
          ) {
            newFilter[key] = newFilter[key].push(filter[key]);
          } else if (
            !Array.isArray(newFilter[key]) &&
            Array.isArray(filter[key])
          ) {
            newFilter[key] = filter[key].push(newFilter[key]);
          } else {
            newFilter[key] = [newFilter[key], filter[key]];
          }
          newFilter[key] = newFilter[key].filter(onlyUnique);
        }
      });
      filter = Object.assign(filter, newFilter);
    }
    return filter;
  }
  return {
    mergeExistingAndNewFilters: mergeExistingAndNewFilters
  };
}

export default AssignFilterParameters;
