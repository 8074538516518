module.exports = {
  entity: 'billingTypeBillingKinds',
  path: 'billingTypeBillingKinds',
  entityId: 224,
  name: {
    en: 'Unified Energy Management Group',
    sl_SI: 'Petrol obračun'
  },

  codelist: 'dataTypes',
  scope: 'tis_be:dataTypes',

  dialog: {
    title: {
      en: 'Select Unified Energy Management Group',
      sl_SI: 'Izberite Petrol obračun'
    },
    listInfo: {
      en: 'Select Unified Energy Management Group from the list below',
      sl_SI: 'IzberitePetrol obračun s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      }
    ],
    codelist: 'billingTypeBillingKinds'
  }
}
