jobConfiguration.$inject = ['gettext', '$q', 'TranslationService'];

function jobConfiguration(gettext, $q, TranslationService) {
  var configuration = {
    entity: {
      singular: gettext('Job'),
      plural: gettext('Jobs')
    },
    api: {
      query: {
        entity: 'jobs',
        method: 'custom.readList'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'external-datasources-job-results-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    attributes: [
      {
        title: gettext('External Datasource'),
        param: 'externalDatasourceName',
        type: 'link',
        link: {
          state: 'external-datasources-external-datasources-view',
          params: [
            {
              valueParam: 'externalDatasourceId',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        title: gettext('Tango Agent Connection Config'),
        param: 'tangoAgentConnectionConfigName',
        type: 'text'
      },
      {
        title: gettext('Type'),
        param: 'typeName',
        type: 'text'
      },
      {
        title: gettext('Status'),
        param: 'statusName',
        type: 'text'
      },
      {
        title: gettext('Started'),
        param: 'started',
        type: 'date'
      },
      {
        title: gettext('Ended'),
        param: 'completed',
        type: 'date'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('External Datasource'),
        query: {
          entity: 'external-datasources',
          method: 'read'
        },
        entity: 'external-datasources',
        param: 'externalDatasource',
        selectedParamName: '_id',
        type: 'searchSelect',
        refreshParameterName: 'filter'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      },
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'name DESC',
        params: '-name'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    var find = {};
    if (
      item.hasOwnProperty('tangoAgentConnectionConfig') &&
      item.tangoAgentConnectionConfig !== null
    ) {
      item.tangoAgentConnectionConfigName =
        item.tangoAgentConnectionConfig.name;
    }

    if (
      item.hasOwnProperty('externalDatasource') &&
      item.externalDatasource !== null
    ) {
      item.externalDatasourceName = item.externalDatasource.name;
      item.externalDatasourceId = item.externalDatasource._id;
    }
    if (item.hasOwnProperty('type') && item.type) {
      find = TranslationService.GetCollectionById(
        'codelists.jobTypes',
        item.type
      );
      item.typeName = find.name;
    }

    if (item.hasOwnProperty('status') && item.status) {
      find = TranslationService.GetCollectionById(
        'codelists.jobStatuses',
        item.status
      );
      item.statusName = find.name;
    }
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default jobConfiguration;
