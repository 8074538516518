UserTangoProperties.$inject = ['StandardUtils', 'ValidateObjectConfiguration'];

export default function UserTangoProperties(
  StandardUtils,
  ValidateObjectConfiguration
) {
  const userValidation = [
    {
      fieldName: '_id',
      type: 'string',
      required: true
    },
    {
      fieldName: 'name',
      type: 'string',
      required: true
    },
    {
      fieldName: 'family_name',
      type: 'string',
      required: false
    },
    {
      fieldName: 'picture',
      type: 'string',
      required: false
    }
  ];
  /**
   * @description returns object that can be used in values array for sfe-property.
   * @function
   * @param {Object} ref {item, key, options}
   * item that contains under the key key user object
   * options is an object that contains showPicture flag
   * @return {Object}
   */
  function getUserValueConfiguration({ item, key, options }) {
    const { showPicture } = options;
    const user = item[key];
    const validationConfig = ValidateObjectConfiguration.validate(
      user,
      userValidation
    );
    if (validationConfig.validity) {
      return validationConfig.configuration.reduce((result, fieldConfig) => {
        switch (fieldConfig.fieldName) {
        case '_id':
          result = {
            ...result,
            link: `users-users-view({id:"${user._id}"})`
          };
          break;
        case 'name':
          result = {
            ...result,
            text: user.name
          };
          break;
        case 'family_name':
          if (!fieldConfig.warning.noValue) {
            result = {
              ...result,
              text: `${result.text} ${user['family_name']}`
            };
          }
          break;
        case 'picture':
          if (showPicture && !fieldConfig.warning.noValue) {
            result = {
              ...result,
              image: {
                name: StandardUtils.getUserImageUrl(user, 'sm'),
                gravatarId: user._id
              }
            };
          }
          break;
        }
        return result;
      }, {});
    }
  }
  return {
    basicValue: getUserValueConfiguration
  };
}
