findPriceListDetailService.$inject = [
  'gettextCatalog',
  'MasterInvoiceModel',
  'MeasuringpointModel',
  'PriceListDetailModel'
];
/**
 * @ngdoc service
 * @name invoices.findPriceListDetailService
 * @description Contains helper function that finds valid pricelist detail.
 * @property {function} findValidPricelist
 */
function findPriceListDetailService(
  gettextCatalog,
  MasterInvoiceModel,
  MeasuringpointModel,
  PriceListDetailModel
) {
  /**
   * @description recursively compares measuringpoint supplier and disptrbuter and masterinvoice supplier and
   * pricelist and masterinvoice dates to find valid priclist detail.
   * @function
   * @param {object} measuringPoint measuring point object
   * @param {object} masterInvoice masterinvoice object
   * @param {bool} isDistributer indicates should we use measuringpoint distributer or supplier
   * @param {date} serviceDateTo detail invoice end servie data
   * @return {dataType}
   */
  async function compareDates(
    measuringPoint,
    masterInvoice,
    isDistributer,
    serviceDateTo
  ) {
    const partnerId = isDistributer
      ? measuringPoint.businessPartnerDistributor
      : measuringPoint.businessPartnerSupplier;
    const masterPriceListId = isDistributer
      ? measuringPoint.priceListMasterDistributor
      : measuringPoint.priceListMasterSupplier;

    if (masterInvoice.invoiceIssuer === partnerId) {
      let detailPriceLists = await getPriceListDetailsByMasterPriceList(
        masterPriceListId
      );

      var pricelist = detailPriceLists.find(priceList => {
        var priceListValidFrom = new Date(priceList.validFrom).setHours(
          0,
          0,
          0,
          0
        );
        var priceListValidTo;
        if (priceList.validTo) {
          priceListValidTo = new Date(priceList.validTo).setHours(
            23,
            59,
            59,
            59
          );
        }
        var masterInvoiceServiceDate = new Date(serviceDateTo).setHours(
          23,
          59,
          59,
          59
        );
        return (
          priceListValidFrom <= masterInvoiceServiceDate &&
          (!priceListValidTo || priceListValidTo >= masterInvoiceServiceDate)
        );
      });

      if (pricelist) {
        return pricelist._id;
      } else {
        if (isDistributer) {
          throw gettextCatalog.getString(
            'Price list doesn\'t exist for this issuer, billing date and measuring point.'
          );
        } else {
          if (measuringPoint.priceListMasterDistributor) {
            return await compareDates(
              measuringPoint,
              masterInvoice,
              true,
              serviceDateTo
            );
          } else {
            throw gettextCatalog.getString(
              'Price list doesn\'t exist for this issuer, billing date and measuring point.'
            );
          }
        }
      }
    } else {
      if (!isDistributer && measuringPoint.priceListMasterDistributor) {
        return await compareDates(
          measuringPoint,
          masterInvoice,
          true,
          serviceDateTo
        );
      } else {
        throw gettextCatalog.getString(
          'Price list doesn\'t exist for this issuer, billing date and measuring point.'
        );
      }
    }
  }
  /**
   * @description resolves to array of pricelist master details.
   * @function
   * @param {string} masterId pricelist master id
   * @return {Promise}
   */
  function getPriceListDetailsByMasterPriceList(masterId) {
    return new Promise(resolve => {
      PriceListDetailModel.read({
        priceListMaster: masterId
      }).then(
        function(res) {
          resolve(res.data);
        },
        function() {
          resolve([]);
        }
      );
    });
  }
  /**
   * @memberof findPriceListDetailService.findValidPricelist
   * @description resolves to valid pricelistDetail object or throws an error.
   * @param {string} masterInvoiceId masterInvoice id
   * @param {string} measuringPointId measuring point id
   * @param {date} serviceDateTo end date of service
   * @return {Promise}
   */
  async function findValidPricelist(
    masterInvoiceId,
    measuringPointId,
    serviceDateTo
  ) {
    try {
      const masterInvoice = (
        await MasterInvoiceModel.read({
          id: masterInvoiceId
        })
      ).data;

      const measuringPoint = (
        await MeasuringpointModel.read({
          id: measuringPointId
        })
      ).data;
      if (measuringPoint && measuringPoint.priceListMasterSupplier) {
        return await compareDates(
          measuringPoint,
          masterInvoice,
          false,
          serviceDateTo
        );
      } else if (measuringPoint && measuringPoint.priceListMasterDistributor) {
        return await compareDates(
          measuringPoint,
          masterInvoice,
          true,
          serviceDateTo
        );
      } else {
        throw gettextCatalog.getString(
          'Price list doesn\'t exist for this supplier, billing date and measuring point.'
        );
      }
    } catch (err) {
      throw err;
    }
  }
  return {
    findValidPricelist
  };
}

export default findPriceListDetailService;
