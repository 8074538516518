import template from '../views/sfe-form.dialog.html';

DateTimeDialogService.$inject = ['$mdDialog'];

// required only needs validTo, timeFrom and timeTo params, beacuse validFrom is required by default
/**
 * @ngdoc service
 * @name common.DateTimeDialogService
 * @description opens from to date dialog form.
 * @property {function} openDialog
 */
export default function DateTimeDialogService($mdDialog) {
  /**
   * @memberof DateTimeDialogService.openDialog
   * @description opens from to date dialog form and resolves new date values
   * @param {sting} title dialog title
   * @param {string} customButtonText text that will be displayed on the button
   * @param {object} initialValues object of initial values  {dateFrom, timeFrom, dateTo, timeTo}
   * @param {bool} dateOnly indicates either displa time inputs or not
   * @param {Object} required indicates if fields are required {validTo: bool, validFrom: bool}
   * @param {bool} readDateFromLocalStorage indicates if initail date values should be taken from local storage
   * @return {Promise}
   */
  function openDialog({
    title,
    customButtonText,
    initialValues = {},
    required = {},
    readDateFromLocalStorage,
    dateOnly
  }) {
    return $mdDialog
      .show({
        controller: 'AddDateController',
        controllerAs: 'vm',
        template,
        parent: angular.element(document.body),
        preserveScope: true,
        locals: {
          title,
          customButtonText,
          initialValues,
          dateOnly,
          required,
          readDateFromLocalStorage
        }
      })
      .catch(angular.noop);
  }

  return {
    openDialog
  };
}
