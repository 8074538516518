module.exports = {
  entity: 'profit-centres',
  path: 'profit-centres',
  name: {
    en: 'Profit centre',
    sl_SI: 'Profitni center'
  },
  networkModel: {
    entity: 'profit-centres',
    read: 'read'
  },
  scope: 'tis_be:profit-centres',

  selectedParamName: 'id',
  dialog: {
    title: {
      en: 'Select profit centre',
      sl_SI: 'Izberite Profitni center'
    },
    listInfo: {
      en: 'Select profit centre from the list below',
      sl_SI: 'Izberite profit center s spodnjega seznama'
    }
  },
  sfeTree: {
    showConnectedEntities: false,
    route: 'profit-centres',
    parentEntity: 'company-structure-nodes',
    parentKey: 'companyStructureNode',
    icon: 'profit-centre',
    childrenConfiguration: []
  }
}
