AlarmModel.$inject = ['CrawlerMethods', '$q', 'ModelUtilities'];

function AlarmModel(CrawlerMethods, $q, ModelUtilities) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'alarms'
  };

  const networkConfigurationAllLang = {
    host: 'api',
    path: 'alarms',
    allLang: true
  };

  const crawlerConfigurationSeverity = {
    entity: 'alarms',
    method: 'read',
    populate: 'severity'
  };

  const crawlerConfigurationSeverityResolutionTime = {
    entity: 'alarms',
    method: 'read',
    populate: 'severity,resolutionTime'
  };

  const crawlerConfiguration = {
    entity: 'alarms',
    method: 'read',
    populate: 'severity,type,responseTime,resolutionTime,sops'
  };
  const crawlerConfigurationEdit = {
    entity: 'alarms',
    method: 'custom.readAllLang',
    populate: 'sops,type'
  };

  const crawlerConfigurationList = {
    entity: 'alarms',
    method: 'read',
    populate: 'severity'
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      list: CrawlerMethods.crawler(crawlerConfigurationList),
      readAllLang: ModelUtilities.crud(
        'GET',
        networkConfigurationAllLang,
        modelStorage
      ),
      readItemView: CrawlerMethods.crawler(crawlerConfiguration),
      readItemForm: CrawlerMethods.crawler(crawlerConfigurationEdit),
      readSeverity: CrawlerMethods.crawler(crawlerConfigurationSeverity),
      readSeverityResolutionTime: CrawlerMethods.crawler(
        crawlerConfigurationSeverityResolutionTime
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default AlarmModel;
