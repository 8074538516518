import './sfe-cron.scss';
import template from './sfe-cron.directive.html';

/**
 * @ngdoc component
 * @name common.sfeCron
 * @description Directive used for configuring the schedule at schedules entity.
 * @param {Object} cronData Data that is being set for the schedule
 * @param {string} mode If it's 'update', wait for the cronData to be initialized, then initialize the component, else just initialize
 * @example
 * <sfe-cron
 *   cron-data="vm.config.dataObj[data.name]"
 *   mode="{{::data.mode}}"
 * ></sfe-cron>
 */

sfeCron.$inject = [];

export default function sfeCron() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      cronData: '=',
      mode: '@'
    },
    require: '^form',
    link: linkFn,
    controller: SfeCronController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  function linkFn(scope, el, attrs, formController) {
    scope.vm.form = formController;

    // link
    scope.vm.ValidateChip = ValidateChip;
    scope.vm.ToggleDay = ToggleDay;
    scope.vm.ToggleMonth = ToggleMonth;
    scope.vm.ToggleAllDays = ToggleAllDays;
    scope.vm.ToggleAllMonths = ToggleAllMonths;
    scope.vm.el = el;

    /**
     * @description Evaluates the validity of the chip.
     * @function
     * @param {Array} chipList List of chips.
     * @param {number} min Min allowed value.
     * @param {number} max Max allowed value.
     */
    function ValidateChip(chipList, min, max) {
      var lastChip = chipList.slice(-1)[0];
      chipList.pop();
      if (lastChip.match(/^[+-]?\d+$/i)) {
        lastChip = parseInt(lastChip);
        if (chipList.indexOf(lastChip) === -1) {
          if (lastChip >= min && lastChip <= max) {
            chipList.push(lastChip);
          }
        }
      }
      scope.vm.ResetOutput();
    }

    /**
     * @description Checks/unchecks the day checkbox.
     * @function
     * @param {number} index Index of the checkbox to be checked/unchecked.
     */
    function ToggleDay(index) {
      scope.vm.cronData.days[index] = !scope.vm.cronData.days[index];
      // if all days are selected
      if (scope.vm.cronData.days.indexOf(false) < 0) {
        scope.vm.cronData.everyDay = true;
      }
      scope.vm.ResetOutput();
    }

    /**
     * @description Checks/unchecks the month checkbox.
     * @function
     * @param {number} index Index of the checkbox to be checked/unchecked.
     */
    function ToggleMonth(index) {
      scope.vm.cronData.months[index] = !scope.vm.cronData.months[index];
      // if all months are selected
      if (scope.vm.cronData.months.indexOf(false) < 0) {
        scope.vm.cronData.everyMonth = true;
      }
      scope.vm.ResetOutput();
    }

    /**
     * @description Checks/unchecks all months checkboxes.
     * @function
     */
    function ToggleAllMonths() {
      for (var x = 0; x < 12; x++) {
        scope.vm.cronData.months[x] = scope.vm.cronData.everyMonth;
      }
      scope.vm.ResetOutput();
    }

    /**
     * @description Checks/unchecks all day checkboxes.
     * @function
     */
    function ToggleAllDays() {
      for (var x = 0; x < 7; x++) {
        scope.vm.cronData.days[x] = scope.vm.cronData.everyDay;
      }
      scope.vm.ResetOutput();
    }
  }
}

SfeCronController.$inject = [
  '$scope',
  'gettext',
  'CronService',
  'DateAutocomplete'
];

function SfeCronController($scope, gettext, CronService, DateAutocomplete) {
  var vm = this;
  // controller
  vm.ResetOutput = ResetOutput;
  vm.ChangeCronType = ChangeCronType;
  vm.DayIsSelected = DayIsSelected;
  vm.MonthIsSelected = MonthIsSelected;
  vm.ManualCronInput = ManualCronInput;
  vm.callAutoCompleteDate = callAutoCompleteDate;
  var watcher;

  this.$onInit = function() {
    if (vm.mode === 'update') {
      watcher = $scope.$watch('vm.cronData', function(newVal) {
        if (typeof newVal !== 'undefined') {
          watcher();
          Initialize(newVal);
        }
      });
    } else {
      Initialize();
    }
  };

  $scope.$on('$destroy', function() {
    if (watcher) {
      watcher();
    }
  });

  /**
   * @description Initializes the starting data.
   * @function
   * @param {Object} rawData Data that was passed in from the item being updated.
   */
  function Initialize(rawData) {
    var tempData = {};
    tempData.days = [true, true, true, true, true, true, true];
    tempData.months = [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true
    ];
    tempData.cronType = 'interval';
    tempData.intervalQuantity = 1;
    tempData.intervalUnit = 'hours';

    tempData.validFromDate = new Date();
    tempData.validToDate = new Date();
    tempData.fromTime = '00:00:00';
    tempData.toTime = '23:59:59';

    tempData.everyMonth = true;
    tempData.everyDay = true;
    tempData.manualCron = false;
    tempData.dailyHours = [];
    tempData.dailyMinutes = [];

    // custom
    tempData.customSecondsExact = [];
    tempData.customMinutesExact = [];
    tempData.customHoursExact = [];
    tempData.customDaysExact = [];
    tempData.customMonthsExact = [];
    tempData.customWeekdaysExact = [];

    tempData.customSecondsMode = 'first';
    tempData.customMinutesMode = 'first';
    tempData.customHoursMode = 'first';
    tempData.customDaysMode = 'every';
    tempData.customMonthsMode = 'every';
    tempData.customWeekdaysMode = 'every';

    tempData.cronExpression = '0 0 */1 * * *';

    if (typeof rawData !== 'undefined') {
      if (rawData.validFromDate && rawData.validToDate) {
        rawData.validFromDate = new Date(rawData.validFromDate);
        rawData.validToDate = new Date(rawData.validToDate);
      }
      Object.keys(rawData).forEach(function(key) {
        tempData[key] = rawData[key];
      });
      vm.cronData = tempData;
    }

    vm.cronData = tempData;
    SetScopeFromCron(vm.cronData.cronExpression);
    ResetOutput();
  }

  /**
   * @description Sets cron data based on the input.
   * @function
   */
  function ManualCronInput() {
    try {
      var cron = vm.cronData.cronExpression;
      SetScopeFromCron(cron);
      var validation = ValidateInput();
      if (validation.valid) {
        // vm.cronData.literal = ProduceLiteralCron();
      }
      vm.cronData.validation = validation;
    } catch (e) {
      vm.cronData.validation.valid = false;
    }
  }

  /**
   * @description Resets the output of the cron expression.
   * @function
   */
  function ResetOutput() {
    var validation = ValidateInput();
    if (validation.valid) {
      vm.cronData.cronExpression = BuildCronString();
    }
    vm.cronData.validation = validation;
  }

  /**
   * @description Called on changing the cron type. Resets the data.
   * @function
   */
  function ChangeCronType() {
    ResetDaysAndMonths();
    ResetOutput();
  }

  /**
   * @description Used for determining if a month checkbox should be checked.
   * @function
   * @param {number} index Index of the clicked month checkbox.
   * @return {boolean} Returns whether cronData month is checked(true) or not(false)
   */
  function MonthIsSelected(index) {
    if (vm.cronData) {
      return vm.cronData.months[index];
    } else {
      return false;
    }
  }

  /**
   * @description Used for determining if a day checkbox should be checked.
   * @function
   * @param {number} index Index of the clicked day checkbox.
   * @return {boolean} Returns whether cronData day is checked(true) or not(false)
   */
  function DayIsSelected(index) {
    if (vm.cronData) {
      return vm.cronData.days[index];
    } else {
      return false;
    }
  }

  /**
   * @description Checks all the days and months and triggers the every month and every day of the week.
   * @function
   */
  function ResetDaysAndMonths() {
    vm.cronData.days = [true, true, true, true, true, true, true];
    vm.cronData.months = [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true
    ];
    vm.cronData.everyMonth = true;
    vm.cronData.everyDay = true;
  }

  /**
   * @description Validates the input.
   * @function
   * @return {Object} Contains error data.
   */
  function ValidateInput() {
    var intRegex = new RegExp('^[0-9]+$');
    var errorObject = {
      valid: true,
      errors: []
    };

    // conditions based on type of cron
    switch (vm.cronData.cronType) {
    case 'interval':
      if (!vm.cronData.intervalUnit) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Interval unit missing.'));
      }
      if (!vm.cronData.intervalQuantity) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Interval quantity missing.'));
      }
      if (
        (vm.cronData.intervalUnit === 'seconds' ||
            vm.cronData.intervalUnit === 'minutes') &&
          60 % vm.cronData.intervalQuantity !== 0
      ) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Provide a different interval'));
      }
      if (
        vm.cronData.intervalUnit === 'hours' &&
          24 % vm.cronData.intervalQuantity !== 0
      ) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Provide a different interval'));
      }
      break;

    case 'daily':
      if (vm.cronData.dailyHours.length === 0) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Provide at least one hour choice.'));
      }
      if (vm.cronData.dailyMinutes.length === 0) {
        errorObject.valid = false;
        errorObject.errors.push(
          gettext('Provide at least one minute choice.')
        );
      }
      break;

    case 'month':
      if (!vm.cronData.monthlyTime) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Provide time in day.'));
      } else {
        var split = vm.cronData.monthlyTime.split(':');
        if (
          split.length !== 2 ||
            !intRegex.test(vm.cronData.monthlyTime[0]) ||
            !intRegex.test(vm.cronData.monthlyTime[1])
        ) {
          errorObject.valid = false;
          errorObject.errors.push(gettext('Hour format is not right'));
        }
      }

      if (!vm.cronData.monthlyDay) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Provide day in month.'));
      } else if (!intRegex.test(vm.cronData.monthlyDay)) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Day format is not right.'));
      }

      break;

    case 'custom':
      // SECONDS
      if (
        vm.cronData.customSecondsMode === 'interval' &&
          !vm.cronData.customSecondsEvery
      ) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Second interval quantity missing.'));
      }
      if (
        vm.cronData.customSecondsMode === 'exact' &&
          vm.cronData.customSecondsExact.length === 0
      ) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Provide at least one second.'));
      }
      // MINUTES
      if (
        vm.cronData.customMinutesMode === 'interval' &&
          !vm.cronData.customMinutesEvery
      ) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Minute interval quantity missing.'));
      }
      if (
        vm.cronData.customMinutesMode === 'exact' &&
          vm.cronData.customMinutesExact.length === 0
      ) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Provide at least one minute.'));
      }
      // HOURS
      if (
        vm.cronData.customHoursMode === 'interval' &&
          !vm.cronData.customHoursEvery
      ) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Hour interval quantity missing.'));
      }
      if (
        vm.cronData.customHoursMode === 'exact' &&
          vm.cronData.customHoursExact.length === 0
      ) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Provide at least one hour.'));
      }
      // DAYS
      if (
        vm.cronData.customDaysMode === 'interval' &&
          !vm.cronData.customDaysEvery
      ) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Day interval quantity missing.'));
      }
      if (
        vm.cronData.customDaysMode === 'exact' &&
          vm.cronData.customDaysExact.length === 0
      ) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Provide at least one day.'));
      }
      // MONTHS
      if (
        vm.cronData.customMonthsMode === 'interval' &&
          !vm.cronData.customMonthsEvery
      ) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Month interval quantity missing.'));
      }
      if (
        vm.cronData.customMonthsMode === 'exact' &&
          vm.cronData.customMonthsExact.length === 0
      ) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Provide at least one month.'));
      }
      // WEEKDAYS
      if (
        vm.cronData.customWeekdaysMode === 'interval' &&
          !vm.cronData.customWeekdaysEvery
      ) {
        errorObject.valid = false;
        errorObject.errors.push(
          gettext('Weekday interval quantity missing.')
        );
      }
      if (
        vm.cronData.customWeekdaysMode === 'exact' &&
          vm.cronData.customWeekdaysExact.length === 0
      ) {
        errorObject.valid = false;
        errorObject.errors.push(gettext('Provide at least one weekday.'));
      }

      break;
    }

    if (['interval', 'daily'].indexOf(vm.cronData.cronType) > -1) {
      if (!vm.cronData.everyDay && vm.cronData.days.indexOf(true) === -1) {
        errorObject.valid = false;
        errorObject.errors.push(
          gettext('Provide at least one day in the week.')
        );
      }
    }

    if (!vm.cronData.everyMonth && vm.cronData.months.indexOf(true) === -1) {
      errorObject.valid = false;
      errorObject.errors.push(gettext('Provide at least one month.'));
    }

    if (errorObject.valid) {
      var tempExpression = BuildCronString();
      var preCrudeValidation = CronService.ValidateCron(tempExpression);
      if (!preCrudeValidation.valid) {
        errorObject.valid = false;
        errorObject.errors = errorObject.errors.concat(
          preCrudeValidation.errors
        );
      }
    } else if (vm.cronData.cronExpression) {
      var crudeValidation = CronService.ValidateCron(
        vm.cronData.cronExpression
      );
      if (!crudeValidation.valid) {
        errorObject.valid = false;
        errorObject.errors = errorObject.errors.concat(crudeValidation.errors);
      }
    }

    return errorObject;
  }

  /**
   * @description Determines the cron type
   * @function
   * @param {string} cron The current cron expression.
   * @return {string} Returns the cron type.
   */
  function GetCronType(cron) {
    var type = '';
    var cronArray = cron.split(' ');
    var intervals = []; // starts with /
    var everyTimes = []; // is a * char
    var numberOfIntervals = 0;
    var numberOfEverytimes = 0;
    for (var x = 0; x < cronArray.length; x++) {
      if (IsIntervalCron(cronArray[x])) {
        intervals.push(true);
        numberOfIntervals++;
      } else {
        intervals.push(false);
      }
      if (IsEveryTimeCron(cronArray[x])) {
        everyTimes.push(true);
        numberOfEverytimes++;
      } else {
        everyTimes.push(false);
      }
    }

    if (
      numberOfIntervals === 1 &&
      intervals.indexOf(true) < 3 &&
      IsEveryTimeCron(cronArray[3])
    ) {
      type = 'interval';
    } else if (
      numberOfIntervals === 0 &&
      cronArray[0] === '0' &&
      !IsEveryTimeCron(cronArray[1]) &&
      !IsEveryTimeCron(cronArray[2]) &&
      IsEveryTimeCron(cronArray[3]) &&
      numberOfEverytimes <= 3
    ) {
      type = 'daily';
    } else if (
      numberOfIntervals === 0 &&
      cronArray[0] === '0' &&
      !IsEveryTimeCron(cronArray[1]) &&
      !IsEveryTimeCron(cronArray[2]) &&
      !IsEveryTimeCron(cronArray[3]) &&
      IsEveryTimeCron(cronArray[5]) &&
      numberOfEverytimes <= 3
    ) {
      type = 'month';
    } else {
      type = 'custom';
    }

    return type;
  }

  /**
   * @description checks if value is a standard cron * character
   * @param {cronBit} string Part of the cron expression passed to function.
   * @return {boolean} Returns true if value is * and false if it is not.
   */
  function IsEveryTimeCron(cronBit) {
    return CronService.IsEveryTimeCron(cronBit);
  }

  /**
   * @description checks if value is a standard cron interval
   * @param {cronBit} string Part of the cron expression passed to function.
   * @return {boolean} Returns true if value is a cron interval.
   */
  function IsIntervalCron(cronBit) {
    return CronService.IsIntervalCron(cronBit);
  }

  /**
   * @description Evaluates the passed in value of day and time.
   * @function
   * @param {string} value Day or time.
   * @return {number} Returns the integer value of the passed in value.
   */
  function parseTimeData(value) {
    if (value) {
      return parseInt(value) > 9 ? value : '0' + value;
    }
    return value;
  }

  /**
   * @description Sets the data to be displayed based on the cron input.
   * @function
   * @param {string} cron Cron input based on which the data to be displayed it set.
   */
  function SetScopeFromCron(cron) {
    var cronArray = cron.split(' ');
    var type = GetCronType(cron);
    var x;

    if (type === 'interval') {
      for (x = 0; x < 3; x++) {
        if (IsIntervalCron(cronArray[x])) {
          if (x === 0) {
            vm.cronData.intervalUnit = 'seconds';
          } else if (x === 1) {
            vm.cronData.intervalUnit = 'minutes';
          } else if (x === 2) {
            vm.cronData.intervalUnit = 'hours';
          }
          vm.cronData.intervalQuantity = cronArray[x].replace('*/', '');
          break;
        }
      }

      if (!IsEveryTimeCron(cronArray[4])) {
        vm.cronData.months = ParseMonthsFromCronbit(cronArray[4]);
      }
      if (!IsEveryTimeCron(cronArray[5])) {
        vm.cronData.days = ParseDaysFromCronbit(cronArray[5]);
      }
    } else if (type === 'daily') {
      vm.cronData.dailyMinutes = cronArray[1].split(',');
      vm.cronData.dailyHours = cronArray[2].split(',');
      if (!IsEveryTimeCron(cronArray[4])) {
        vm.cronData.months = ParseMonthsFromCronbit(cronArray[4]);
      }
      if (!IsEveryTimeCron(cronArray[5])) {
        vm.cronData.days = ParseDaysFromCronbit(cronArray[5]);
      }
    } else if (type === 'month') {
      vm.cronData.monthlyDay = parseInt(cronArray[3]);
      vm.cronData.monthlyTime =
        parseTimeData(cronArray[2]) + ':' + parseTimeData(cronArray[1]);
      if (!IsEveryTimeCron(cronArray[4])) {
        vm.cronData.months = ParseMonthsFromCronbit(cronArray[4]);
      }
    } else {
      // CUSTOM CRON

      // SECONDS
      if (IsIntervalCron(cronArray[0])) {
        vm.cronData.customSecondsMode = 'interval';
        vm.cronData.customSecondsEvery = parseInt(
          cronArray[0].replace('*/', '')
        );
      } else if (IsEveryTimeCron(cronArray[0])) {
        vm.cronData.customSecondsMode = 'every';
      } else if (cronArray[0] === '0') {
        vm.cronData.customSecondsMode = 'first';
      } else {
        vm.cronData.customSecondsMode = 'exact';
        vm.cronData.customSecondsExact = cronArray[0].split(',');
      }

      // MINUTES
      if (IsIntervalCron(cronArray[1])) {
        vm.cronData.customMinutesMode = 'interval';
        vm.cronData.customMinutesEvery = parseInt(
          cronArray[1].replace('*/', '')
        );
      } else if (IsEveryTimeCron(cronArray[1])) {
        vm.cronData.customMinutesMode = 'every';
      } else if (cronArray[1] === '0') {
        vm.cronData.customMinutesMode = 'first';
      } else {
        vm.cronData.customMinutesMode = 'exact';
        vm.cronData.customMinutesExact = cronArray[1].split(',');
      }

      // HOURS
      if (IsIntervalCron(cronArray[2])) {
        vm.cronData.customHoursMode = 'interval';
        vm.cronData.customHoursEvery = parseInt(cronArray[2].replace('*/', ''));
      } else if (IsEveryTimeCron(cronArray[2])) {
        vm.cronData.customHoursMode = 'every';
      } else if (cronArray[2] === '0') {
        vm.cronData.customHoursMode = 'first';
      } else {
        vm.cronData.customHoursMode = 'exact';
        vm.cronData.customHoursExact = cronArray[2].split(',');
      }

      // DAYS
      if (IsIntervalCron(cronArray[3])) {
        vm.cronData.customDaysMode = 'interval';
        vm.cronData.customDaysEvery = parseInt(cronArray[3].replace('*/', ''));
      } else if (IsEveryTimeCron(cronArray[3])) {
        vm.cronData.customDaysMode = 'every';
      } else if (cronArray[3] === '0') {
        vm.cronData.customDaysMode = 'first';
      } else {
        vm.cronData.customDaysMode = 'exact';
        vm.cronData.customDaysExact = cronArray[3].split(',');
      }

      // MONTHS
      if (IsIntervalCron(cronArray[4])) {
        vm.cronData.customMonthsMode = 'interval';
        vm.cronData.customMonthsEvery = parseInt(
          cronArray[4].replace('*/', '')
        );
      } else if (IsEveryTimeCron(cronArray[4])) {
        vm.cronData.customMonthsMode = 'every';
      } else if (cronArray[4] === '0') {
        vm.cronData.customMonthsMode = 'first';
      } else {
        vm.cronData.customMonthsMode = 'exact';
        vm.cronData.customMonthsExact = cronArray[4].split(',');
      }

      // WEEKDAYS
      if (IsIntervalCron(cronArray[5])) {
        vm.cronData.customWeekdaysMode = 'interval';
        vm.cronData.customWeekdaysEvery = parseInt(
          cronArray[5].replace('*/', '')
        );
      } else if (IsEveryTimeCron(cronArray[5])) {
        vm.cronData.customWeekdaysMode = 'every';
      } else if (cronArray[5] === '0') {
        vm.cronData.customWeekdaysMode = 'first';
      } else {
        vm.cronData.customWeekdaysMode = 'exact';
        vm.cronData.customWeekdaysExact = cronArray[5].split(',');
      }
    }

    vm.cronData.everyDay = IsEveryTimeCron(cronArray[5]);
    vm.cronData.everyMonth = IsEveryTimeCron(cronArray[4]);
    vm.cronData.cronType = type;
  }

  /**
   * @description Sets the data to be displayed for months based on the cron bit input.
   * @function
   * @param {string} cron Cron bit based on which the data to be displayed for months it set.
   * @return {Array} Array of values with inidication whether month checkboxes need to be checked.
   */
  function ParseMonthsFromCronbit(cronbit) {
    var tempArr = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false
    ];
    cronbit.split(',').forEach(function(index) {
      tempArr[index - 1] = true;
    });
    return tempArr;
  }

  /**
   * @description Sets the data to be displayed for days based on the cron bit input.
   * @function
   * @param {string} cron Cron bit based on which the data to be displayed for days it set.
   * @return {Array} Array of values with inidication whether day checkboxes need to be checked.
   */
  function ParseDaysFromCronbit(cronbit) {
    var tempArr = [false, false, false, false, false, false, false];
    cronbit.split(',').forEach(function(index) {
      tempArr[index] = true;
    });
    return tempArr;
  }

  /**
   * @description Builds the cron string based on vm.cronData.
   * @function
   * @return {string} The built cron string.
   */
  function BuildCronString() {
    /*
			By index in array:
			0 - seconds
			1 - minutes
			2 - hours
			3 - days
			4 - months
			5 - day of the week
			*/

    var cronArray = ['*', '*', '*', '*', '*', '*'];
    var x, minutes, hours, days, months;

    switch (vm.cronData.cronType) {
    case 'interval':
      switch (vm.cronData.intervalUnit) {
      case 'seconds':
        cronArray[0] = '*/' + vm.cronData.intervalQuantity;
        break;

      case 'minutes':
        cronArray[0] = '0';
        cronArray[1] = '*/' + vm.cronData.intervalQuantity;
        break;

      case 'hours':
        cronArray[0] = '0';
        cronArray[1] = '0';
        cronArray[2] = '*/' + vm.cronData.intervalQuantity;
        break;

      default:
        return false;
      }
      break;

    case 'daily':
      minutes = '';
      for (x = 0; x < vm.cronData.dailyMinutes.length; x++) {
        if (x > 0) {
          minutes += ',';
        }
        minutes += vm.cronData.dailyMinutes[x];
      }
      hours = '';
      for (x = 0; x < vm.cronData.dailyHours.length; x++) {
        if (x > 0) {
          hours += ',';
        }
        hours += vm.cronData.dailyHours[x];
      }

      cronArray[0] = '0';
      cronArray[1] = minutes;
      cronArray[2] = hours;
      break;

    case 'month':
      minutes = vm.cronData.monthlyTime.split(':');
      if (minutes[0][0] === '0') {
        minutes[0] = minutes[0][1]; // cut 0 if there is a zero in front
      }
      if (minutes[1][0] === '0') {
        minutes[1] = minutes[1][1]; // cut 0 if there is a zero in front
      }

      cronArray[0] = '0';
      cronArray[1] = minutes[1];
      cronArray[2] = minutes[0];
      cronArray[3] = vm.cronData.monthlyDay;
      break;

    case 'custom':
      break;
    default:
      return false;
    }

    if (vm.cronData.cronType !== 'custom') {
      // set months cron
      if (!vm.cronData.everyMonth) {
        months = [];
        for (x = 0; x < 12; x++) {
          if (vm.cronData.months[x]) {
            months.push(x + 1);
          }
        }
        cronArray[4] = months.join(',');
      }

      // set weekdays cron
      if (!vm.cronData.everyDay) {
        days = [];
        for (x = 0; x < 7; x++) {
          if (vm.cronData.days[x]) {
            days.push(x);
          }
        }
        cronArray[5] = days.join(',');
      }
    } else {
      // CUSTOM
      switch (vm.cronData.customSecondsMode) {
      case 'first':
        cronArray[0] = '0';
        break;
      case 'every':
        cronArray[0] = '*';
        break;
      case 'interval':
        cronArray[0] = '*/' + vm.cronData.customSecondsEvery;
        break;
      case 'exact':
        cronArray[0] = '';
        for (x = 0; x < vm.cronData.customSecondsExact.length; x++) {
          if (x > 0) {
            cronArray[0] += ',';
          }
          cronArray[0] += vm.cronData.customSecondsExact[x];
        }
        break;
      }
      switch (vm.cronData.customMinutesMode) {
      case 'first':
        cronArray[1] = '0';
        break;
      case 'every':
        cronArray[1] = '*';
        break;
      case 'interval':
        cronArray[1] = '*/' + vm.cronData.customMinutesEvery;
        break;
      case 'exact':
        cronArray[1] = '';
        for (x = 0; x < vm.cronData.customMinutesExact.length; x++) {
          if (x > 0) {
            cronArray[1] += ',';
          }
          cronArray[1] += vm.cronData.customMinutesExact[x];
        }
        break;
      }
      switch (vm.cronData.customHoursMode) {
      case 'first':
        cronArray[2] = '0';
        break;
      case 'every':
        cronArray[2] = '*';
        break;
      case 'interval':
        cronArray[2] = '*/' + vm.cronData.customHoursEvery;
        break;
      case 'exact':
        cronArray[2] = '';
        for (x = 0; x < vm.cronData.customHoursExact.length; x++) {
          if (x > 0) {
            cronArray[2] += ',';
          }
          cronArray[2] += vm.cronData.customHoursExact[x];
        }
        break;
      }
      switch (vm.cronData.customDaysMode) {
      case 'first':
        cronArray[3] = '0';
        break;
      case 'every':
        cronArray[3] = '*';
        break;
      case 'interval':
        cronArray[3] = '*/' + vm.cronData.customDaysEvery;
        break;
      case 'exact':
        cronArray[3] = '';
        for (x = 0; x < vm.cronData.customDaysExact.length; x++) {
          if (x > 0) {
            cronArray[3] += ',';
          }
          cronArray[3] += vm.cronData.customDaysExact[x];
        }
        break;
      }
      switch (vm.cronData.customMonthsMode) {
      case 'first':
        cronArray[4] = '0';
        break;
      case 'every':
        cronArray[4] = '*';
        break;
      case 'interval':
        cronArray[4] = '*/' + vm.cronData.customMonthsEvery;
        break;
      case 'exact':
        cronArray[4] = '';
        for (x = 0; x < vm.cronData.customMonthsExact.length; x++) {
          if (x > 0) {
            cronArray[4] += ',';
          }
          cronArray[4] += vm.cronData.customMonthsExact[x];
        }
        break;
      }
      switch (vm.cronData.customWeekdaysMode) {
      case 'first':
        cronArray[5] = '0';
        break;
      case 'every':
        cronArray[5] = '*';
        break;
      case 'interval':
        cronArray[5] = '*/' + vm.cronData.customWeekdaysEvery;
        break;
      case 'exact':
        cronArray[5] = '';
        for (x = 0; x < vm.cronData.customWeekdaysExact.length; x++) {
          if (x > 0) {
            cronArray[5] += ',';
          }
          cronArray[5] += vm.cronData.customWeekdaysExact[x];
        }
        break;
      }
    }
    return cronArray.join(' ');
  }

  /**
   * @description Called when datepicker has lost focus and sets the value of its respective element.
   * @function
   * @param {number} index Index of the element.
   * @param {string} partialNgModelname Partial name of the model.
   */
  function callAutoCompleteDate(index, partialNgModelname) {
    var htmlClass = '.autoCompleteClass' + index;
    var inputtedDate = vm.el[0].querySelector(htmlClass).querySelector('input')
      .value;

    vm.cronData[partialNgModelname] = DateAutocomplete.get(inputtedDate);
  }
}
