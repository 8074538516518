import './sfe-form-2-rule-alarm.scss';
import template from './sfe-form-2-rule-alarm.component.html';

/**
 * @ngdoc component
 * @name common.sfeForm2RuleAlarm
 * @description Component for displaying alarm rules
 * @param {Array} properties - contains properties of the component, which are displayed under every rule as a checkbox. For now the two properties supprted by BE are isError and isManualIntervention;
 *    @param {Object}
 *      @param {string} name - name under which the value is sent to BE
 *      @param {string} label - label that is shown to inside the component
 *      @param {boolean} showIntegrity - indicates when to display integrity field
 * @example
 * <sfe-form-2-rule-alarm
 *   properties="vm.properties"
 * ></sfe-form-2-rule-alarm>
 */
/*
 */
export default {
  restrict: 'E',
  template,
  bindings: {
    showIntegrity: '<',
    properties: '<',
    api: '<'
  },
  require: {
    model: 'ngModel'
  },
  controller: sfeForm2RuleAlarmController,
  controllerAs: 'vm',
  bindToController: true
};

sfeForm2RuleAlarmController.$inject = ['$scope', 'gettextCatalog', '$timeout'];
function sfeForm2RuleAlarmController($scope, gettextCatalog, $timeout) {
  const vm = this;

  vm.modelValue = [];
  vm.addRule = addRule;
  vm.removeRule = removeRule;
  vm.addAlarm = addAlarm;
  vm.removeAlarm = removeAlarm;
  vm.alarmAutocompleteChange = alarmAutocompleteChange;
  vm.ruleAutocompleteChange = ruleAutocompleteChange;
  vm.updateModel = updateModel;
  vm.disableAddRuleButton = false;
  vm.disableAddAlarmButton = [];

  vm.ruleAutocompleteTitle = gettextCatalog.getString('select rule');
  vm.ruleAutocompleteOptions = {
    itemsCrawler: {
      entity: 'rules',
      method: 'read'
    },
    crawlerParams: text => {
      if (text != null && text != '') {
        return { filter: text };
      }
      return {};
    },
    display: item => ({ text: item.name }),
    dialog: {
      entity: 'rules'
    }
  };
  /**
   * @description returns min value for integrity.
   * @function
   * @param {number} index
   * @return {number}
   */
  vm.setMinValue = index => {
    if (index === 0) {
      return 0;
    }

    return vm.modelValue[index - 1].integrityValue;
  };
  /**
   * @description returns max value for integrity.
   * @function
   * @param {number} index
   * @return {number}
   */
  vm.setMaxValue = index => {
    let nextValue = 100;
    if (
      vm.modelValue.length > index + 1 &&
      vm.modelValue[index + 1].integrityValue != null
    ) {
      nextValue = vm.modelValue[index + 1].integrityValue;
    }
    return nextValue;
  };
  /**
   * @description triggers revalidate api to validate integrity values correctly.
   * @function
   */
  vm.changeIntegrityValue = () => {
    if (vm.api && typeof vm.api.revalidate === 'function') {
      $timeout(vm.api.revalidate);
    }
  };

  vm.alarmAutocompleteTitle = gettextCatalog.getString('select alarm');
  vm.alarmAutocompleteOptions = {
    itemsCrawler: {
      entity: 'alarms',
      method: 'read'
    },
    crawlerParams: text => {
      if (text != null && text != '') {
        return { filter: text };
      }
      return {};
    },
    display: item => ({ text: item.name }),
    filter: (items, values) => {
      const alarms = items.filter(alarm => {
        let foundAlarm;
        if (values != null && Array.isArray(values.alarms)) {
          foundAlarm = values.alarms.find(addedAlarm => {
            if (alarm != null && addedAlarm != null) {
              return alarm._id === addedAlarm._id;
            }
          });
        }
        if (foundAlarm == null) {
          return alarm;
        }
      });
      return alarms;
    },
    dialog: {
      entity: 'alarms'
    }
  };

  $scope.$on('$destroy', function() {
    if (modelWatcher) {
      modelWatcher();
    }
  });

  // Initialize mode value.
  const modelWatcher = $scope.$watch(
    () => {
      return vm.model.$modelValue;
    },
    model => {
      vm.modelValue = model;
    }
  );

  function updateModel() {
    vm.model.$setViewValue([...vm.modelValue]);
  }
  /**
   * @description function that adds a new object(rule) into the model
   * @function
   */
  function addRule() {
    vm.disableAddRuleButton = true;
    if (!Array.isArray(vm.modelValue)) {
      vm.modelValue = [];
    }
    vm.modelValue.push({
      alarms: []
    });
    updateModel();
  }
  /**
   * @description Function that is called when a rule is removed
   * @function
   * @param {number} ruleIndex index of the rule removed
   */
  function removeRule(ruleIndex) {
    vm.modelValue = vm.modelValue.filter((rule, index) => ruleIndex !== index);
    vm.model.$setViewValue(vm.modelValue);
    vm.disableAddAlarmButton = vm.disableAddAlarmButton.filter(
      (disable, index) => ruleIndex != index
    );
    vm.ruleAutocompleteChange(vm.modelValue);
  }
  /**
   * @description Function that is called when an alarm is added
   * @function
   * @param {number} ruleIndex index of the rule the alarm is being added to
   */
  function addAlarm(ruleIndex) {
    //disabled the addition of a new alarm until the new one is valid
    vm.disableAddAlarmButton[ruleIndex] = true;
    if (Array.isArray(vm.modelValue[ruleIndex].alarms)) {
      vm.modelValue[ruleIndex].alarms.push(null);
    } else {
      vm.modelValue[ruleIndex].alarms = [null];
    }
    updateModel();
  }
  /**
   * @description Function that is called when an alarm is added
   * @function
   * @param {number} ruleIndex index of the rule the alarm is being added to
   * @param {number} alarm index of the alarm that is being removed
   */
  function removeAlarm(ruleIndex, alarmIndex) {
    //removes the alarms
    vm.modelValue[ruleIndex].alarms = vm.modelValue[ruleIndex].alarms.filter(
      (alarm, index) => alarmIndex != index
    );
    //we call it to recheck if the add alarm button should be enabled
    alarmAutocompleteChange({
      alarms: vm.modelValue[ruleIndex].alarms,
      ruleIndex
    });
    updateModel();
  }
  /**
   * @description Function that is called when the alarm autocomplete changes
   * @function
   * @param {object} alarms contains the array of alarms and index of the ruley the belong to
   *  @param {Array} alarm list of alarms that belong to the rule
   *  @param {number} ruleIndex index of the rule the alarms belong to
   */
  function alarmAutocompleteChange(alarms) {
    if (alarms && Array.isArray(alarms.alarms)) {
      vm.disableAddAlarmButton[alarms.ruleIndex] = alarms.alarms.some(
        alarm => !alarm || !alarm._id
      );
    } else {
      vm.disableAddAlarmButton[alarms.ruleIndex] = true;
    }
    updateModel();
  }
  /**
   * @description Function that is called when the rule autocomplete changes
   * @function
   * @param {Array} rules Array of all the rules
   */
  function ruleAutocompleteChange(rules) {
    if (Array.isArray(rules)) {
      vm.disableAddRuleButton = rules.some(
        rule => !rule.rule || !rule.rule._id
      );
    } else {
      vm.disableAddRuleButton = true;
    }
    updateModel();
  }
}
