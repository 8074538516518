import routes from './maps.routes';
import BasicMapController from './pages/basic-map/basic-map.controller';
import GisMapController from './pages/gis-map/gis-map.controller';
import MapHelperService from './pages/basic-map/MapHelper.service';
import BasicMapFiltersController from './pages/basic-map/dialog/basic-map-filter/basic-map-filters.controller';

// configurations
import gisConfiguration from './configurations/gis.configuration';

// network models
import GisMapModel from './network-models/GisMap.model';
import MapModel from './network-models/Map.model';

export default {
  routes,
  controllers: {
    BasicMapController,
    GisMapController,
    BasicMapFiltersController
  },
  factories: {
    gisConfiguration
  },
  services: {
    GisMapModel,
    MapModel,
    MapHelperService
  }
};
