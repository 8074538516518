PriceListItemModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function PriceListItemModel(ModelUtilities, CrawlerMethods) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'price-list-items'
  };

  const crawlerConfiguration = {
    entity: 'price-list-items',
    method: 'read',
    populate: 'energyManagementGroup,priceListItemGroup,priceListDetail',
    steps: [
      {
        entity: 'price-list-masters',
        valueParams: ['priceListDetail.priceListMaster'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'populateArray'
      }
    ]
  };

  const crawlerConfigurationReadItem = {
    entity: 'price-list-items',
    method: 'read',
    populate: 'energyManagementGroup,priceListItemGroup'
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readList: CrawlerMethods.crawler(crawlerConfiguration),
      readItem: CrawlerMethods.crawler(crawlerConfigurationReadItem)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default PriceListItemModel;
