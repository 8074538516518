module.exports = {
  entity: 'price-list-details',
  path: 'price-list-details',
  name: {
    en: 'Price list details',
    sl_SI: 'Podrobnosti cenika'
  },
  networkModel: {
    entity: 'price-list-details',
    read: 'custom.list'
  },
  displayFields: [
    {
      field1: 'priceListMaster',
      field2: 'name',
      type: 'string'
    },
    {
      field1: 'validFrom',
      type: 'date',
      title: 'Valid from'
    },
    {
      field1: 'validTo',
      type: 'date',
      title: 'Valid to'
    }
  ],
  enrichArray: [
    {
      from: 'price-list-masters',
      localField: '_id',
      foreignField: 'priceListMaster',
      as: 'priceListMasterObject'
    }
  ],
  scope: 'tis_be:price-list-details',

  dialog: {
    title: {
      en: 'Select price list detail',
      sl_SI: 'Izberite podrobnost cenika'
    },
    listInfo: {
      en: 'Select price list detail from the list below',
      sl_SI: 'Izberite podrobnost cenika s spodnjega seznama'
    }
  }
}
