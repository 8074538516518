HeaderTimeSeriesConfiguration.$inject = [
  'gettext',
  'utilService',
  'ImportCsvDialog',
  'ToastService',
  'AlertingService',
  'CommonPropertiesTimeSeriesFormConfiguration',
  'SfeForm2Dialog',
  'NewTimeSeriesValueDialogService',
  'TimeSeriesWizardDuplicateConfiguration',
  'TimeSeriesRecalculateService',
  'SyncExternalDatasourceValues',
  'InfoDialog',
  'TimeSeriesModel',
  'CrudToastFactory',
  '$mdDialog'
];
/**
 * @ngdoc service
 * @name common.HeaderTimeSeriesConfiguration
 * @description actions for time series
 * @property {function} get
 */
export default function HeaderTimeSeriesConfiguration(
  gettext,
  utilService,
  ImportCsvDialog,
  ToastService,
  AlertingService,
  CommonPropertiesTimeSeriesFormConfiguration,
  SfeForm2Dialog,
  NewTimeSeriesValueDialogService,
  TimeSeriesWizardDuplicateConfiguration,
  TimeSeriesRecalculateService,
  SyncExternalDatasourceValues,
  InfoDialog,
  TimeSeriesModel,
  CrudToastFactory,
  $mdDialog
) {
  /**
   * @memberof HeaderTimeSeriesConfiguration.get
   * @description  returns object that contains configurations to build tango item
   * @param {string} entityId entity id
   * @return {Object}
   */
  function get() {
    return {
      icon: {
        type: 'static',
        value: {
          name: 'settings_input_antenna',
          type: 2
        }
      },
      name: {
        type: 'stored',
        value: 'name',
        validation: [
          {
            fieldName: 'name',
            type: 'String',
            required: true
          }
        ]
      },
      actions: {
        type: 'dynamic',
        valueFn: (store, entity, entityId) => {
          let item;
          let configurations = [];
          if (store != null) {
            if (store[entity] != null && store[entity][entityId] != null) {
              item = store[entity][entityId].data;
            }

            if (
              store.timeSeriesConfigurations != null &&
              store.timeSeriesConfigurations.list &&
              store.timeSeriesConfigurations.list[entityId] != null &&
              Array.isArray(store.timeSeriesConfigurations.list[entityId].data)
            ) {
              configurations = store.timeSeriesConfigurations.list[
                entityId
              ].data.map(config => {
                if (
                  config.flowRef == 100 &&
                  store.externalReaderFlow != null &&
                  store.externalReaderFlow[config.flow] != null &&
                  store.externalReaderFlow[config.flow].data != null
                ) {
                  return {
                    ...config,
                    flowObject: store.externalReaderFlow[config.flow].data
                  };
                }
                return {
                  ...config
                };
              });
            }
          }

          let externalReaderFlowExists = configurations.some(
            config => config.flowRef == 100
          );

          let externalWriterFlowExists = configurations.some(
            config => config.flowRef == 200
          );
          let calculatedFlowExists = configurations.some(
            config => config.flowRef == 300
          );
          let mtAnalysisFlowExists = configurations.some(
            config => config.flowRef == 320
          );
          let externalReaderFlows = configurations.filter(
            config => config.flowRef === 100
          );

          let dropdownActions = [];
          if (externalReaderFlowExists) {
            dropdownActions.push(
              {
                title: gettext('Import CSV'),
                fn: async () => {
                  const endpoint =
                    utilService.getApiHost +
                    '/time-series/' +
                    entityId +
                    '/processing/values';
                  try {
                    await ImportCsvDialog.open(endpoint);
                    ToastService.showToast(
                      gettext('CSV was successfully uploaded')
                    );
                  } catch (err) {
                    AlertingService.Error(err);
                  }
                }
              },
              {
                title: gettext('New value'),
                fn: () => {
                  NewTimeSeriesValueDialogService.open(
                    item,
                    externalReaderFlows
                  );
                }
              }
            );
          }

          if (
            externalWriterFlowExists ||
            calculatedFlowExists ||
            mtAnalysisFlowExists
          ) {
            dropdownActions.push({
              title: gettext('Recalculate'),
              customButtonText: gettext('Recalculate'),
              fn: () => {
                TimeSeriesRecalculateService.open(item);
              },
              disabledFn: () =>
                item.dataSamplingType == 200 /*Irregular*/ &&
                calculatedFlowExists
            });
          }
          if (externalReaderFlowExists) {
            dropdownActions.push({
              title: gettext('History data job'),
              customButtonText: gettext('History data job'),
              disabledFn: () => {
                let validErf = configurations.filter(
                  config =>
                    config.flowRef == 100 &&
                    config.flowObject != null &&
                    config.flowObject.externalDatasource != null
                );
                return !validErf.length;
              },
              fn: async () => {
                try {
                  let config = await SyncExternalDatasourceValues.get(entityId);
                  SfeForm2Dialog.open(config);
                  // eslint-disable-next-line no-empty
                } catch (err) {}
              }
            });
          }

          const value = [
            {
              title: gettext('More actions'),
              opaqueButton: true,
              color: 'black',
              greyBorder: true,
              items: dropdownActions,
              disabledFn: () => dropdownActions.length < 1
            },

            {
              title: gettext('Delete'),
              /**
               * @description removes timeseries and if successful redirects user ti time series list.
               * @function
               */
              fn: () => {
                var title = gettext('Confirmation');
                var textItem = {
                  text: gettext('Are you sure you want to delete this item?'),
                  type: 'text'
                };
                var actions = [
                  {
                    title: gettext('Cancel'),
                    cancel: true,
                    color: 'primary'
                  },
                  {
                    title: gettext('Delete'),
                    fn: async () => {
                      $mdDialog.hide();
                      try {
                        await TimeSeriesModel.delete({ id: entityId });
                        const redirectObj = {
                          state: 'data-time-series-list'
                        };
                        CrudToastFactory.toast('delete', redirectObj);
                      } catch (err) {
                        AlertingService.Error(err);
                      }
                    },
                    color: 'warn'
                  }
                ];
                InfoDialog.open(title, null, [textItem], actions);
              },
              color: 'warn',
              opaqueButton: true,
              greyBorder: true
            },
            {
              title: gettext('Duplicate'),
              fn: () => {
                let config = TimeSeriesWizardDuplicateConfiguration.get(
                  entityId
                );
                SfeForm2Dialog.open(config);
              },
              color: 'success',
              opaqueButton: true,
              greyBorder: true
            },
            {
              title: gettext('Update'),
              fn: () => {
                let config = CommonPropertiesTimeSeriesFormConfiguration.get(
                  item
                );
                SfeForm2Dialog.open(config);
              },
              color: 'success',
              opaqueButton: true,
              greyBorder: true
            }
          ];
          return { value };
        }
      }
    };
  }

  return {
    get
  };
}
