MeasuringpointModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function MeasuringpointModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'measuringpoints'
  };

  var networkConfigurationChildren = {
    host: 'api',
    path: 'measuringpoints/:parentId/children'
  };

  var crawlerConfiguration = {
    entity: 'measuringpoints',
    method: 'read',
    populate: 'energySourceType,location'
  };

  var crawlerConfigurationReadLocation = {
    entity: 'measuringpoints',
    method: 'read',
    populate: 'location'
  };

  var crawlerConfigurationEditForm = {
    entity: 'measuringpoints',
    method: 'read',
    populate:
      'energySourceType,priceListMasterDistributor,priceListMasterSupplier,location,parentId,businessPartnerDistributor,businessPartnerSupplier,measuringpointType',
    steps: [
      {
        entity: 'system-tag-entities',
        valueParams: ['_id'],
        queryParams: ['entityId'],
        method: 'custom.readWithTag',
        methodType: 'add',
        newParamNames: ['tags']
      }
    ]
  };

  var ownerStep = {
    entity: 'business-partner-measuringpoints',
    valueParams: ['_id'],
    queryParams: ['measuringpoint'],
    method: 'read',
    methodType: 'add',
    newParamNames: ['owner'],
    filters: {
      isActive: true,
      isMain: true
    },
    mappings: {
      localField: '_id',
      foreignField: 'measuringpoint'
    },
    populate: 'businessPartner'
  };
  var mainCostCentreStep = {
    entity: 'cost-centre-measuringpoints',
    valueParams: ['_id'],
    queryParams: ['measuringpoint'],
    method: 'read',
    methodType: 'add',
    newParamNames: ['mainCostCentre'],
    filters: {
      isActive: true,
      isMain: true
    },
    mappings: {
      localField: '_id',
      foreignField: 'measuringpoint'
    },
    populate: 'costCentre'
  };
  var tagStep = {
    entity: 'system-tag-entities',
    valueParams: ['_id'],
    queryParams: ['entityId'],
    method: 'custom.readWithTag',
    methodType: 'add',
    newParamNames: ['tags']
  };

  var measuringpointTypeStep = {
    entity: 'measuringpoint-types',
    method: 'read',
    methodType: 'populate',
    valueParams: ['measuringpointType'],
    queryParams: ['id']
  };

  var configurations = [
    measuringpointTypeStep,
    {
      entity: 'business-partners',
      method: 'read',
      methodType: 'populate',
      valueParams: ['businessPartnerDistributor'],
      queryParams: ['id']
    },
    {
      entity: 'business-partners',
      method: 'read',
      methodType: 'populate',
      valueParams: ['businessPartnerSupplier'],
      queryParams: ['id']
    }
  ];

  var crawlerConfigurationDuplicateForm = {
    entity: 'measuringpoints',
    method: 'read',
    populate:
      'energySourceType,priceListMasterDistributor,priceListMasterSupplier,location,parentId,businessPartnerDistributor,businessPartnerSupplier,measuringpointType',
    steps: [
      tagStep,
      {
        entity: 'measuringpoint-counter-types',
        valueParams: ['_id'],
        queryParams: ['measuringpoint'],
        method: 'read',
        methodType: 'add',
        newParamNames: ['counterType'],
        filters: {
          isActive: true
        },
        mappings: {
          localField: '_id',
          foreignField: 'measuringpoint'
        },
        steps: [
          {
            entity: 'counter-types',
            valueParams: ['counterType'],
            queryParams: ['id'],
            method: 'read',
            methodType: 'populate'
          }
        ]
      },
      ownerStep,
      {
        entity: 'asset-measuringpoints',
        valueParams: ['_id'],
        queryParams: ['measuringpoint'],
        method: 'read',
        methodType: 'add',
        newParamNames: ['asset'],
        filters: {
          isActive: true
        },
        mappings: {
          localField: '_id',
          foreignField: 'measuringpoint'
        },
        steps: [
          {
            entity: 'assets',
            valueParams: ['asset'],
            queryParams: ['id'],
            method: 'read',
            methodType: 'populate'
          }
        ]
      },
      mainCostCentreStep
    ]
  };

  var crawlerConfigurationItemView = {
    entity: 'measuringpoints',
    method: 'read',
    populate:
      'energySourceType,priceListMasterDistributor,priceListMasterSupplier,location,parentId,businessPartnerDistributor,businessPartnerSupplier,measuringpointType',
    steps: [].concat([ownerStep, mainCostCentreStep, tagStep])
  };

  function getMeasuringPointType(measuringpoint) {
    return CrawlerMethods.populate(measuringpoint, [measuringpointTypeStep]);
  }
  function readMetadata(measuringpoint) {
    return CrawlerMethods.populate(measuringpoint, configurations);
  }

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      list: CrawlerMethods.crawler(crawlerConfiguration),
      readItemView: CrawlerMethods.crawler(crawlerConfigurationItemView),
      readEditForm: CrawlerMethods.crawler(crawlerConfigurationEditForm),
      readChildren: ModelUtilities.crud(
        'GET',
        networkConfigurationChildren,
        modelStorage
      ),
      readDuplicateForm: CrawlerMethods.crawler(
        crawlerConfigurationDuplicateForm
      ),
      readMetadata: readMetadata,
      readLocation: CrawlerMethods.crawler(crawlerConfigurationReadLocation),
      getMeasuringPointType: getMeasuringPointType
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default MeasuringpointModel;
