import template from './user-view.component.html';
/**
 * @ngdoc component
 * @name user.userView
 * @description displays users that have root access to the certain entity and allows to change accesses.
 * @param {string} identifier scope identifier
 * @param {string} target entity id
 * @param {bool} user indicates if users or groups permissions will be displayed
 * @example
 * <user-view
 * identifier="vm.instanceScope"
 * target="vm.target"
 * user="false"
 * ></user-view>
 */
export default {
  template,
  bindings: {
    identifier: '=',
    target: '=',
    user: '='
  },
  controller: userAddTableController,
  controllerAs: 'vm'
};

userAddTableController.$inject = [
  'AlertingService',
  'gettext',
  'ToastService',
  'StandardUtils',
  'AuthorizationToEditService',
  'AuthorizationListPermittedModel',
  'AuthorizationRootPermissionModel',
  'gettextCatalog'
];

function userAddTableController(
  AlertingService,
  gettext,
  ToastService,
  StandardUtils,
  AuthorizationToEditService,
  AuthorizationListPermittedModel,
  AuthorizationRootPermissionModel,
  gettextCatalog
) {
  var vm = this;
  var identifier;
  var target;
  vm.saveUser = saveUser;
  vm.removeUser = removeUser;
  vm.selectModel = {};
  vm.selectOptions = [];
  vm.editScopesPermission = false;
  vm.$onInit = init;

  vm.paginationLabels = {
    of: gettextCatalog.getString('of'),
    rowsPerPage: gettextCatalog.getString('Per page'),
    page: gettextCatalog.getString('page')
  };
  /**
   * @description component initialization method.
   * @function
   */
  function init() {
    identifier = vm.identifier;
    target = vm.target;
    fetchUsers();
    initTableConfiguration();

    vm.editScopesPermission = AuthorizationToEditService.isAuthorizedToEdit();
    vm.dataConfig = {
      data: [
        {
          componentType: 'autocompleteDialog',
          configuration: {
            query: {
              entity: vm.user ? 'users' : 'groups',
              method: 'read'
            },
            floatingLabel: vm.user
              ? gettext('Select a person')
              : gettext('Select a group'),
            searchParamName: vm.user ? 'full_name' : 'name',
            entity: vm.user ? 'users' : 'groups',
            required: false
          },
          name: 'user'
        }
      ],
      dataObj: {}
    };
  }
  /**
   * @description fetches list of permitted users.
   * @function
   */
  function fetchUsers() {
    vm.dataIsLoading = true;
    AuthorizationListPermittedModel.read({
      instance: target,
      scope: identifier
    }).then(
      function(res) {
        vm.selected = _.filter(res.data, function(user) {
          if (user.owner) {
            if (vm.user) {
              user.owner.imageUrl = StandardUtils.getUserImageUrl(
                user.owner,
                'sm'
              );
            }
            return vm.user ? user.owner.user : user.owner.group;
          }
        });
        vm.dataIsLoading = false;
      },
      function(err) {
        vm.dataIsLoading = false;
        AlertingService.Error(err);
      }
    );
  }
  /**
   * @description initialized user.group table configurations.
   * @function
   */
  function initTableConfiguration() {
    vm.tableConfig = {
      page: 1,
      limit: 5,
      order: 'name',
      selected: [],
      multiple: false
    };

    vm.headers = [
      {
        name: gettext('Name'),
        sort: 'displayName'
      },
      {
        name: gettext('List'),
        sort: 'list'
      },
      {
        name: gettext('Read'),
        sort: 'read'
      },
      {
        name: gettext('Update'),
        sort: 'update'
      },
      {
        name: gettext('Delete'),
        sort: 'delete'
      },
      {
        name: 'Link'
      }
    ];
  }
  /**
   * @description removes user/group from permitted list.
   * @function
   * @param {object} user object of permitted item configuration
   */
  function removeUser(user) {
    var temp = angular.copy(user);
    temp.access = {
      read: false,
      update: false,
      create: false,
      list: false
    };
    saveUser(temp);
  }
  /**
   * @description updates new user access permission values.
   * @function
   * @param {object} user object of permitted item configuration
   */
  function saveUser(user) {
    if (user) {
      user.isSaving = true;
    }
    var apiObj;
    if (user) {
      apiObj = {
        owner: user.owner._id,
        instanceId: target,
        instanceScope: identifier,
        access: user.access
      };
    } else if (vm.dataConfig.dataObj.user._id) {
      apiObj = {
        owner: vm.dataConfig.dataObj.user._id,
        instanceId: target,
        instanceScope: identifier,
        access: {
          read: false,
          update: false,
          create: false,
          list: true
        }
      };
    }

    AuthorizationRootPermissionModel.create(apiObj).then(
      function(res) {
        fetchUsers();
        vm.dataConfig.dataObj.user = null;
        ToastService.showToast(gettext('Changes were successfully changed'));
        if (user) {
          user.isSaving = false;
        }
        if (res.mesasge === 'Success') {
          fetchUsers();
        }
      },
      function(err) {
        AlertingService.Error(err);
        vm.dataConfig.dataObj.user = null;
        if (user) {
          user.isSaving = false;
        }
      }
    );
  }
}
