import template from './tango-item.component.html';
import './tango-item.scss';
export default {
  template,
  bindings: {
    entity: '<',
    entityId: '<'
  },
  controller: TangoItemController,
  controllerAs: 'vm'
};

TangoItemController.$inject = ['TangoItemConfiguration'];

function TangoItemController(TangoItemConfiguration) {
  const vm = this;
  //used to construct grid layout
  vm.noTopProperties = false;

  vm.$onChanges = () => {
    if (vm.entity && vm.entityId) {
      const {
        headerConfiguration,
        contentConfiguration,
        topPropertiesConfiguration,
        propertiesConfiguration,
        detailConfiguration
      } = TangoItemConfiguration.get(vm.entity, vm.entityId);

      vm.headerConfiguration = headerConfiguration;
      vm.contentConfiguration = contentConfiguration;
      vm.topPropertiesConfiguration = topPropertiesConfiguration;
      vm.propertiesConfiguration = propertiesConfiguration;
      vm.detailConfiguration = detailConfiguration;
    }
  };
}
