GroupConfiguration.$inject = ['gettext', '$q'];

function GroupConfiguration(gettext, $q) {
  var configuration = {
    entity: {
      singular: gettext('Group'),
      plural: gettext('Groups')
    },
    api: {
      query: {
        entity: 'groups',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      imgUrlParam: 'imageUrl',
      fallbackUrl: './img/profile/nopic_192.gif',
      gravatarParam: '_id',
      link: {
        state: 'users-groups-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    treeView: {
      entity: 'groups'
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'users-groups-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'users-groups-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'groups',
            method: 'delete'
          }
        }
      }
    ],
    sort: [
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'name DESC',
        params: '-name'
      }
    ],
    filter: [
      {
        display: gettext('Name'),
        param: 'name',
        type: 'string'
      },
      {
        param: 'parent',
        query: {
          entity: 'groups',
          method: 'read'
        },
        entity: 'groups',
        display: gettext('Group Parent'),
        type: 'searchSelect',
        refreshParameterName: 'name',
        selectedParamName: '_id'
      }
    ]
  };

  function Enrich() {
    var deferred = $q.defer();
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default GroupConfiguration;
