AlarmMonitoringHelper.$inject = ['CrawlerMethods', '$q'];
/**
 * @ngdoc service
 * @name monitoring.AlarmMonitoringHelper
 * @description contains helper methods to fetch triggered alarm connected entities or events.
 * @property {function} fetchEvent
 */

function AlarmMonitoringHelper(CrawlerMethods, $q) {
  /**
   * @memberof AlarmMonitoringHelper.fetchEvent
   * @description checks type of event alarm was triggered by and resolves event item.
   * @param {object} alarm triggered alarm object contains eventType number and eventId
   * @return {Promise}
   */
  function fetchEvent(alarm) {
    var deferred = $q.defer();
    var method;
    switch (alarm.eventType) {
    case 1: //rule
      method = CrawlerMethods.getMethod({
        entity: 'rules',
        method: 'read'
      });
      break;
    case 2: //mapping-rule
      method = CrawlerMethods.getMethod({
        entity: 'mappings',
        method: 'read'
      });
      break;
    }

    if (method && alarm.eventId) {
      method({
        id: alarm.eventId
      }).then(
        function(res) {
          deferred.resolve(res.data);
        },
        function(err) {
          deferred.reject(err);
        }
      );
    } else {
      deferred.resolve();
    }
    return deferred.promise;
  }

  return {
    fetchEvent: fetchEvent
  };
}

export default AlarmMonitoringHelper;
