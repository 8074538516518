import template from './sfe-form-2-dialog.template.html';
import './sfe-form-2-dialog.scss';
/**
 * @ngdoc service
 * @name common.SfeForm2Dialog
 * @description Opens form dialog
 */

SfeForm2Dialog.$inject = ['$mdDialog'];
export default function SfeForm2Dialog($mdDialog) {
  async function open(config) {
    return $mdDialog
      .show({
        controller: DialogController,
        controllerAs: 'vm',
        template,
        parent: angular.element(document.body),
        locals: {
          config
        }
      })
      .catch(angular.noop);
  }
  return {
    open
  };
}

DialogController.$inject = ['config'];
function DialogController(config) {
  const vm = this;
  vm.config = config;
  vm.api = {};
  vm.header = {
    title: config.title,
    isDialog: true,
    actions: [
      {
        icon: {
          name: 'close',
          type: 2
        },
        cancel: true
      }
    ]
  };
}
