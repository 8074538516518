import './sfe-date-time-interval-selector.scss';
import template from './sfe-date-time-interval-selector.component.html';

/**
* @ngdoc component
* @name common.sfeForm2FlowVariable
* @description Adds Timeseries and dynamic attributes variables .
* @param {Object} options configurations object
*     @param {Boolean} showDynamicAttributes indicates if dynamic attributes variable selector wil be shown
* @example
* <sfe-date-time-interval-selector
* options="vm.options"
  ng-change="vm.config.onChange()"
  ng-required="vm.config.required"
  ng-model="vm.value"
  name="{{vm.config.id}}"
  sfe-form-2-validation="vm.config.validators"  
* ></sfe-date-time-interval-selector>
**/

export default {
  template,
  bindings: {
    itemTitle: '<'
  },
  require: {
    model: 'ngModel',
    form: '^form'
  },
  controller: sfeForm2FlowVariable,
  controllerAs: 'vm',
  bindToController: true
};

sfeForm2FlowVariable.$inject = [
  'gettext',
  'SfeForm2Dialog',
  'gettextCatalog',
  'TranslationService',
  '$timeout',
  '$mdDialog',
  '$scope',
  'AlertingService'
];

function sfeForm2FlowVariable(
  gettext,
  SfeForm2Dialog,
  gettextCatalog,
  TranslationService,
  $timeout,
  $mdDialog,
  $scope,
  AlertingService
) {
  const vm = this;

  $scope.$on('$destroy', () => {
    stopWatcher();
  });
  /**
   * @description watches for model data to come and
   * if it is set to a string or a number triggers
   * function that fetches data.
   * @function
   */
  let stopWatcher = $scope.$watch(function() {
    if (vm.model != null) {
      return vm.model.$modelValue;
    }
  }, initiateItems);
  /**
   * @description sets viewValue and includeValueOnTheEdge status using updated modelValue.
   * @function
   * @param {Object} model
   */
  function initiateItems(model) {
    if (model != null) {
      const { boundaryType, ...viewValues } = model;
      vm.viewValues = Object.keys(viewValues)
        .map(key => validateAndConstructModelValues(key, viewValues[key]))
        .sort((a, b) => getTimeUnitOrder(a) - getTimeUnitOrder(b));
      vm.includeValueOnTheEdge = boundaryType == 2; //Codelist boundaryTypes
    } else {
      vm.viewValues = [];
      vm.includeValueOnTheEdge = true;
    }
  }
  /**
   * @description returns timeUnitName.
   * @function
   * @param {number} id codelist timeUnit id
   * @return {string}
   */
  function getTimeUnitName(id) {
    let timeUnit = TranslationService.GetCollectionById(
      'codelists.timeUnits',
      id
    );
    if (timeUnit != null) {
      return timeUnit.name;
    }
    return '';
  }
  /**
   * @description returns timeUnit key string order number.
   * @function
   * @param {string} key timeUnit key secondes, minutes...
   * @return {number}
   */
  function getTimeUnitOrder(key) {
    switch (key) {
    case 'seconds':
      return 1;
    case 'minutes':
      return 2;
    case 'hours':
      return 3;
    case 'days':
      return 4;
    case 'weeks':
      return 5;
    case 'months':
      return 6;
    case 'years':
      return 7;
    }
    return 0;
  }
  /**
   * @description checks if model value is missing timeUnitName or mappingTypeName.
   * @function
   * @param {string} key timeUnit key secondes, minutes...
   * @param {object} value
   * @return {Object}
   */
  function validateAndConstructModelValues(key, value) {
    if (value.timeUnitName != null && value.mappingTypeName != null) {
      return value;
    }
    let timeUnitName = '';
    let mappingType = TranslationService.GetCollectionById(
      'codelists.dateTimeMappingTypes',
      value.dateTimeMappingType
    );
    let mappingTypeName;
    if (mappingType != null) {
      mappingTypeName = mappingType.name;
    }
    switch (key) {
    case 'seconds':
      timeUnitName = getTimeUnitName(1);
      break;
    case 'minutes':
      timeUnitName = getTimeUnitName(2);
      break;
    case 'hours':
      timeUnitName = getTimeUnitName(3);
      break;
    case 'days':
      timeUnitName = getTimeUnitName(4);
      break;
    case 'weeks':
      timeUnitName = getTimeUnitName(5);
      break;
    case 'months':
      timeUnitName = getTimeUnitName(6);
      break;
    case 'years':
      timeUnitName = getTimeUnitName(7);
      break;
    default:
      AlertingService.devWarn(
        'Unknown time unit time in sfe-date-time-interval-selector'
      );
    }
    return {
      ...value,
      timeUnitName,
      mappingTypeName
    };
  }
  /**
   * @description updates model value.
   * @function
   * @param {Object} value new value
   */
  function updateModel(value) {
    vm.model.$setViewValue(value);
  }
  /**
   * @description returns validation error for value depending on a selected unitType.
   * @function
   * @param {number} type unitType codelist id
   * @return {String}
   */
  function getSetErrorText(type) {
    switch (type) {
    case 1: //SECONDS
    case 2: //MINUTES
      return gettext('Value must be between 0 and 59');
    case 3: //HOURS
      return gettext('Value must be between 0 and 23');
    case 4: //DAYS
      return gettext('Value must be between 1 and 31');
    case 5: //WEEKS
      return gettext('Value must be between 0 and 52');
    case 6: //MONTHS
      return gettext('Value must be between 1 and 12');
    case 7: //YEARS
      return gettext('Value must be between 1970 and 2099');
    }
  }
  /**
   * @description returns a row for date time mapper type form dialog.
   * @function
   * @param {Object} timeUnit codelist timeUnit object
   * @param {Object} model? current value
   * @return {Array}
   */
  function createTimeUnitFields(timeUnit, model) {
    let required = false;
    if (
      model != null &&
      (model.value != null || model.value != '' || model.mappingType != null)
    ) {
      required = true;
    }
    return [
      {
        id: `title_${timeUnit.id}`,
        type: {
          name: 'title',
          options: {
            value: timeUnit.name,
            theme: 'primary'
          }
        }
      },
      {
        id: `mappingType_${timeUnit.id}`,
        title: gettextCatalog.getString('Mapping Type'),
        type: {
          name: 'autocomplete',
          options: {
            items: TranslationService.GetCollection(
              'codelists.dateTimeMappingTypes'
            ),
            valueField: 'id',
            crawlerParams: searchTerm => ({
              param: 'name',
              searchTerm,
              type: 'string'
            }),
            display: item => ({
              text:
                item != null ? item.name : gettextCatalog.getString('Unknown')
            }),
            noRemoveButton: true
          }
        },
        initialize: () =>
          model != null && model.dateTimeMappingType != null
            ? model.dateTimeMappingType
            : null,
        /**
         * @description reset value required status.
         * @function
         * @param {Object} api
         */
        onChange: api => {
          if (api != null) {
            const selectedMappingType = api.getValue(
              `mappingType_${timeUnit.id}`
            );
            api.setFieldConfigurationProperty(
              `mappingValue_${timeUnit.id}`,
              'required',
              selectedMappingType != null &&
                typeof selectedMappingType == 'object'
            );
            $timeout(api.revalidate);
          }
        },
        required,
        width: 6
      },
      {
        id: `mappingValue_${timeUnit.id}`,
        title: gettextCatalog.getString('Value'),
        type: {
          name: 'text',
          options: {
            type: 'number', //cant use numerical because we don't want to format year as 1.999
            onlyInteger: true
          }
        },
        initialize: () =>
          model != null && model.value != null ? model.value : '',
        /**
         * @description reset mapping type required status.
         * @function
         * @param {Object} api
         */
        onChange: api => {
          if (api != null) {
            $timeout(() => {
              const selectedMappingValue = api.getValue(
                `mappingValue_${timeUnit.id}`
              );
              api.setFieldConfigurationProperty(
                `mappingType_${timeUnit.id}`,
                'required',
                selectedMappingValue != null && selectedMappingValue !== ''
              );
              $timeout(api.revalidate);
            });
          }
        },
        validators: {
          minMaxSetValidation: {
            fn: (model, _, api) => {
              if (api != null && model != null && model !== '') {
                const selectedMappingType = api.getValue(
                  `mappingType_${timeUnit.id}`
                );
                if (
                  selectedMappingType != null &&
                  selectedMappingType.id === 1
                ) {
                  //SET
                  switch (timeUnit.id) {
                  case 1: //SECONDS
                  case 2: //MINUTES
                    return model >= 0 && model <= 59;
                  case 3: //HOURS
                    return model >= 0 && model <= 23;
                  case 4: //DAYS
                    return model >= 1 && model <= 31;
                  case 5: //WEEKS
                    return model >= 0 && model <= 52;
                  case 6: //MONTHS
                    return model >= 1 && model <= 12;
                  case 7: //YEARS
                    return model >= 1970 && model <= 2099;
                  }
                }
              }
              return true;
            },
            text: getSetErrorText(timeUnit.id)
          },
          minMaxOffsetValidation: {
            fn: (model, _, api) => {
              if (api != null && model != null && model !== '') {
                const selectedMappingType = api.getValue(
                  `mappingType_${timeUnit.id}`
                );
                if (
                  selectedMappingType != null &&
                  selectedMappingType.id === 2
                ) {
                  //SET
                  return model >= -100 && model <= 100;
                }
              }
              return true;
            },
            text: gettext('Value must be between -100 and 100')
          },
          noDecimals: {
            fn: model => {
              if (model != null && model !== '') {
                return Number.isInteger(model);
              }
              return true;
            },
            text: gettext('Number should be an integer')
          }
        },
        required,
        width: 6
      }
    ];
  }
  /**
   * @description sets boundary model type on open status change.
   * @function
   */
  vm.openStatusChanged = () => {
    const model = vm.model.$modelValue;
    updateModel({
      ...model,
      /*  BoundaryTypes codelist */
      boundaryType: vm.includeValueOnTheEdge ? 2 /* closed */ : 1 /* opened */
    });
  };
  /**
   * @description returns dateTime mapper form configuration.
   * @function
   * @param {Object} model
   * @return {object}
   */
  function getFormConfiguration(model) {
    if (!model) {
      model = {};
    }
    const timeUnits = TranslationService.GetCollection('codelists.timeUnits');
    const fields = timeUnits.reduce((result, timeUnit) => {
      const modelKey = getKey(timeUnit.id);
      let field = createTimeUnitFields(timeUnit, model[modelKey]);
      return [...result, ...field];
    }, []);
    return {
      title: gettext('Set date time interval'),
      name: 'dateTimeInterval',
      actions: [
        {
          title: gettext('Ok'),
          /**
           * @description triggers recalculate query.
           * @function
           * @param {object} api
           */
          fn: async api => {
            let values = api.getValues();
            $mdDialog.hide(values);
          },
          disabledFn: api => {
            if (typeof api.formValidity == 'function') {
              return !api.formValidity();
            }
          }
        }
      ],
      fields
    };
  }
  /**
   * @description returns not translated key for timeUnit ids.
   * @function
   * @param {Number} type time unit type codelist id
   * @return {string}
   */
  function getKey(type) {
    switch (type) {
    case 1: //SECONDS
      return 'seconds';
    case 2: //MINUTES
      return 'minutes';
    case 3: //HOURS
      return 'hours';
    case 4: //DAYS
      return 'days';
    case 5: //WEEKS
      return 'weeks';
    case 6: //MONTHS
      return 'months';
    case 7: //YEARS
      return 'years';
    }
  }
  /**
   * @description creates modelValue object and viewVale array.
   * @function
   * @param {Object} formResult {[month]: {mapperType, mapperValue}}
   * @return {dataType}
   */
  function constructModelAndView(formResult) {
    const parsedValues = Object.keys(formResult).reduce((result, key) => {
      if (formResult[key] != null && formResult[key] !== '') {
        ///MAPPING TYPE
        const mappingType = key.split('mappingType_');
        let timeUnit;
        if (mappingType.length > 1) {
          timeUnit = Number(mappingType[1]);
          if (timeUnit != null) {
            const modelKey = getKey(timeUnit);
            const timeUnitObject = TranslationService.GetCollectionById(
              'codelists.timeUnits',
              timeUnit
            );
            const timeUnitName = timeUnitObject.name;
            return {
              ...result,
              [modelKey]: {
                ...result[modelKey],
                timeUnitName,
                dateTimeMappingType: formResult[key].id,
                mappingTypeName: formResult[key].name
              }
            };
          }
        }
        //MAPPING VALUES
        const mappingValue = key.split('mappingValue_');
        if (mappingValue.length > 1) {
          timeUnit = Number(mappingValue[1]);
          if (timeUnit != null) {
            const modelKey = getKey(timeUnit);
            return {
              ...result,
              [modelKey]: {
                ...result[modelKey],
                value: formResult[key]
              }
            };
          }
        }
      }

      return result;
    }, {});

    const viewValues = Object.keys(parsedValues).map(key => parsedValues[key]);

    return {
      viewValues,
      parsedValues: {
        ...parsedValues,
        boundaryType: vm.includeValueOnTheEdge ? 2 /* closed */ : 1 /* opened */
      }
    };
  }

  vm.actions = [
    {
      title: gettext('Select'),
      /**
       * @description opens dateTimeMapperType selector form.
       * @function
       */
      fn: async () => {
        let config = getFormConfiguration(vm.model.$modelValue);
        const result = await SfeForm2Dialog.open(config);
        if (result != null) {
          const { viewValues, parsedValues } = constructModelAndView(result);
          vm.viewValues = viewValues;
          updateModel(parsedValues);
          $scope.$applyAsync();
        }
      }
    }
  ];
}
