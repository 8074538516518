import template from './sfe-form-2-boundaries.component.html';
import './sfe-form-2-boundaries.scss';
/**
 * @ngdoc component
 * @name moduleName.sfeForm2Boundaries
 * @description component used for setting integer boundaries
 * @example
 * <sfe-form-2-boundaries
 *    ng-model="vm.value"
 *    ng-change="vm.config.onChange()""
 *    name="{{vm.config.id}}"
 *    ng-model-options="{allowInvalid: true}" <-- this ensures that the component being invalid doesn't delete the model
 * ></sfe-form-2-boundaries>
 */
export default {
  template,
  bindings: {
    api: '<'
  },
  require: {
    model: 'ngModel',
    form: '^form'
  },
  controller: sfeForm2Boundaries,
  controllerAs: 'vm'
};

sfeForm2Boundaries.$inject = [
  '$scope',
  'ColorService',
  'gettextCatalog',
  '$timeout'
];

function sfeForm2Boundaries($scope, ColorService, gettextCatalog, $timeout) {
  const vm = this;
  let initial = true;
  vm.addBoundary = addBoundary;
  vm.removeBoundary = removeBoundary;
  vm.updateModel = updateModel;
  vm.onChange = onChange;

  vm.disableAddBoundaryButton = false;
  vm.min = [];
  vm.max = [];
  vm.errorConfig = [];
  $scope.$on('$destroy', function() {
    if (modelWatcher) {
      modelWatcher();
    }
  });
  // Initialize mode value.
  const modelWatcher = $scope.$watch(
    () => {
      return vm.model.$modelValue;
    },
    model => {
      vm.name = vm.model.$$attr.name;
      vm.modelValue = model;
      $timeout(() => {
        if (initial === true) {
          vm.api.revalidate();
          initial = false;
        }
      });
    }
  );
  /**
   * @description function that updates the model.
   * @function
   */
  function updateModel() {
    if (Array.isArray(vm.modelValue)) {
      vm.model.$setViewValue([...vm.modelValue]);
    }
    $timeout(() => {
      vm.api.revalidate();
    });
  }
  /**
   * @description adds a new boundary.
   * @function
   */
  function addBoundary() {
    vm.disableAddBoundaryButton = true;
    if (Array.isArray(vm.modelValue)) {
      vm.modelValue.push({
        from: '',
        to: '',
        color: ColorService.RandomColor()
      });
    } else {
      vm.modelValue = [
        {
          from: '',
          to: '',
          color: ColorService.RandomColor()
        }
      ];
    }
    updateModel();
  }
  /**
   * @description removes a boundary based on its index.
   * @function
   * @param {integer} index - index of the boundary removed
   */
  function removeBoundary(index) {
    vm.modelValue.splice(index, 1);
    addButtonDisable();
    updateModel();
  }
  /**
   * @description function that is called on changed of one of the inputs.
   * @function
   * @return {dataType}
   */
  function onChange() {
    addButtonDisable();
    updateModel();
  }
  /**
   * @description determines if the add button should be disabled or not.
   * @function
   */
  function addButtonDisable() {
    if (Array.isArray(vm.modelValue) && vm.modelValue.length > 0) {
      vm.disableAddBoundaryButton = vm.modelValue.some(
        boundary => boundary.from == null || boundary.to == null
      );
    } else {
      vm.disableAddBoundaryButton = false;
    }
  }
}
