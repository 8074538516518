import './visualization.scss';

VisualizationViewerController.$inject = [
  'ItemSelector',
  'TimeSeriesLastValueService',
  '$stateParams',
  'visualization',
  '$timeout',
  'MetadataService'
];

function VisualizationViewerController(
  ItemSelector,
  TimeSeriesLastValueService,
  $stateParams,
  visualization,
  $timeout,
  MetadataService
) {
  var vm = this;
  vm.loaded = true;
  vm.measurementSelector = function() {
    return ItemSelector.open(['time-series']);
  };
  vm.measurementGetter = TimeSeriesLastValueService.get;
  vm.visualizationId = $stateParams.id;
  vm.visualizationGetter = async () => ({ data: visualization });
  if (visualization != null) {
    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(visualization.name);
    });
  }
}

export default VisualizationViewerController;
