import { RouteManager, gettext } from '../routes';

import DashboardView from './pages/dashboard/dashboard.view.html';
import NewDashboardPlaylistView from './pages/dashboard-playlist/new-dashboard-playlist.view.html';
import RunDahsboardPlaylistView from './pages/dashboard-playlist/run-dashboard-playlist.view.html';
import EnergySegmentsDashboardView from './pages/energy-segments-dashboard/energy-segments-dashboard.view.html';
import SfeListView from '../common/components/sfe-list/sfe-list.view.html';
import NewFormBasicView from '../common/views/new-form-basic.view.html';

loadEntity.$inject = ['$transition$', 'StateResolveService'];
function loadEntity($transition$, StateResolveService) {
  return StateResolveService.fetchEntityForView($transition$);
}

let DashboardRouteManager = new RouteManager();
DashboardRouteManager.state('dashboard', {
  url: '/dashboards',
  pageTitle: gettext('Dashboards'),
  isMainMenuItem: true,
  icon: 'dashboard',
  redirectTo: 'dashboards-dashboards-list',
  menuIndex: 13,
  accessScopeFe: 'tis_fe:dashboards-module',
  accessScopeBe: 'tis_fe:dashboards-module'
})
  .state('smartcity', {
    url: '/smartcity',
    pageTitle: gettext('Smart City'),
    template: EnergySegmentsDashboardView,
    controller: 'EnergySegmentsController',
    accessScopeFe: 'tis_fe:dashboards-module',
    accessScopeBe: 'tis_fe:dashboards-module'
  })
  .state('dashboards-dashboards-list', {
    url: '/dashboards/dashboards/list',
    pageTitle: gettext('Dashboards'),
    template: SfeListView,
    controller: 'SfeList2ViewController',
    isSubMenuItem: true,
    controllerAs: 'vm',
    parentState: 'dashboard',
    theme: 'tango',
    accessScopeFe: 'tis_fe:dashboards',
    accessScopeBe: 'tis_be:dashboards',
    mode: 'list',
    entity: 'dashboards'
  })
  .state('dashboards-dashboards-new', {
    url: '/dashboards/dashboards/new',
    pageTitle: gettext('New Dashboard'),
    template: NewFormBasicView,
    controller: 'NewDashboardController',
    controllerAs: 'vm',
    parentState: 'dashboard',
    theme: 'tango',
    accessScopeFe: 'tis_fe:dashboards',
    accessScopeBe: 'tis_be:dashboards',
    mode: 'create',
    entity: 'dashboards',
    resolve: {
      dashboard: () => ({})
    }
  })
  .state('dashboards-dashboards-edit', {
    url: '/dashboards/dashboards/:id/edit',
    template: NewFormBasicView,
    controller: 'NewDashboardController',
    controllerAs: 'vm',
    parentState: 'dashboard',
    theme: 'tango',
    accessScopeFe: 'tis_fe:dashboards',
    accessScopeBe: 'tis_be:dashboards',
    mode: 'update',
    entity: 'dashboards',
    resolve: {
      dashboard: loadEntity
    }
  })
  .state('dashboards-dashboards-view', {
    url: '/dashboards/dashboards/:id',
    pageTitle: gettext('Dashboard'),
    template: DashboardView,
    controller: 'DashboardController',
    controllerAs: 'vm',
    parentState: 'dashboard',
    theme: 'tango',
    accessScopeFe: 'tis_fe:dashboards',
    accessScopeBe: 'tis_be:dashboards',
    mode: 'read',
    entity: 'dashboards',
    resolve: {
      dashboard: loadEntity
    }
  })
  .state('dashboards-dashboard-playlists-list', {
    url: '/dashboards/dashboard-playlists/list',
    isSubMenuItem: true,
    pageTitle: gettext('Dashboard Playlists'),
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    parentState: 'dashboard',
    theme: 'tango',
    accessScopeFe: 'tis_fe:dashboard-playlists',
    accessScopeBe: 'tis_be:dashboard-playlists',
    entity: 'dashboard-playlists'
  })
  .state('dashboards-dashboard-playlists-new', {
    url: '/dashboards/dashboard-playlists/new',
    pageTitle: gettext('New Playlist'),
    template: NewDashboardPlaylistView,
    controller: 'NewDashboardPlaylistController',
    controllerAs: 'vm',
    parentState: 'dashboard',
    theme: 'tango',
    accessScopeFe: 'tis_fe:dashboard-playlists',
    accessScopeBe: 'tis_be:dashboard-playlists',
    mode: 'create',
    entity: 'dashboard-playlists',
    resolve: {
      dashboardPlaylist: () => ({})
    }
  })
  .state('dashboards-dashboard-playlists-edit', {
    url: '/dashboards/dashboard-playlists/:id/edit',
    template: NewDashboardPlaylistView,
    controller: 'NewDashboardPlaylistController',
    controllerAs: 'vm',
    parentState: 'dashboard',
    theme: 'tango',
    accessScopeFe: 'tis_fe:dashboard-playlists',
    accessScopeBe: 'tis_be:dashboard-playlists',
    mode: 'update',
    entity: 'dashboard-playlists',
    resolve: {
      dashboardPlaylist: loadEntity
    }
  })
  .state('dashboards-dashboard-playlists-run', {
    url: '/dashboards/dashboard-playlists/:id/run',
    pageTitle: gettext('Run Playlist'),
    template: RunDahsboardPlaylistView,
    controller: 'RunDashboardPlaylistController',
    controllerAs: 'vm',
    parentState: 'dashboard',
    theme: 'tango',
    allUsers: true,
    mode: 'read',
    entity: 'dashboard-playlists',
    resolve: {
      dashboardPlaylist: loadEntity
    }
  });

export default DashboardRouteManager.routes;
