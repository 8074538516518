import './sfe-pagination.scss';
import template from './sfe-pagination.directive.html';
/**
 * @ngdoc directive
 * @name common.sfePagination
 * @description displays pagianation information and executes fetch query.
 * @param {String} paginationType - when paginationType is equal to countTotal component expects pagination to have hasNext flag instead of total number.
 * @param {Bool} sfeLoadingData - shared value that indicates that data is loading
 * @param {Number} sfeLimit - item limit
 * @param {Array} sfeLimitOptions - Array of numbers of limit options [5, 10, 15]
 * @param {Number} sfePage - current page
 * @param {Number} sfeTotal - total number if items
 * @param {Object} resourceFn - Crawler method object or angular resource function
 * @param {Array} sfeFilters - array of filters
 * @param {Object} sfeFilterObject - object of filter parameters that will be added to fetch request
 * @param {String} sfeListId - list identifier helps to save list settings in local storage
 * @param {Array} sfeOrderOptions - order options
 * @param {String} sfeOrder - selected order
 * @param {Array} sfeData - fetched items
 * @param {Bool} relistData - when set to true sfePagination triggers fetch function
 * @param {function} prefetchAction - any function that must be triggered before main fetch function
 * @param {Array} filterValuesOverride - array of filters that overrides stored filter values
 * @example
 * <sfe-pagination
 *    paginationType="'paginationType'"
 *    sfeLoadingData="'sfeLoadingData'"
 *    sfeLimit="'sfeLimit'"
 *    sfeLimitOptions="'sfeLimitOptions'"
 *    sfePage="'sfePage'"
 *    sfeTotal="'sfeTotal'"
 *    resourceFn="'resourceFn'"
 *    sfeFilters="'sfeFilters'"
 *    sfeFilterObject="'sfeFilterObject'"
 *    sfeListId="'sfeListId'"
 *    sfeOrderOptions="'sfeOrderOptions'"
 *    sfeOrder="'sfeOrder'"
 *    sfeData="'sfeData'"
 *    relistData="'relistData'"
 *    prefetchAction="'prefetchAction'"
 * ></sfe-pagination>
 */
export default function sfePagination() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      paginationType: '<',
      sfeLoadingData: '=',
      sfeLimit: '=?',
      sfeLimitOptions: '=?',
      currentPage: '=?',
      totalResults: '=?',
      resourceFn: '=',
      sfeFilters: '=',
      sfeFilterObject: '=',
      sfeListId: '@',
      sfeOrderOptions: '=?',
      sfeOrder: '=?',
      sfeData: '=',
      relistData: '=',
      prefetchAction: '<',
      filterValuesOverride: '<'
    },
    link: linkFn,
    controller: sfePaginationController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  function linkFn(scope, el, attrs, ctrl) {
    /**
     * @description whatches relistData value to be set to true and triggers fetch function.
     * @function
     */
    var relistDataWatcher = scope.$watch('vm.relistData', function() {
      if (ctrl.relistData) {
        ctrl.relistData = false;
        ctrl.getCollection(false, true);
      }
    });
    // cleanup
    scope.$on('$destroy', function() {
      if (relistDataWatcher) {
        relistDataWatcher();
      }
    });
  }
}

sfePaginationController.$inject = [
  'StorageService',
  'AlertingService',
  '$timeout',
  'SfeFilterService',
  'MetadataService',
  'CrawlerMethods',
  '$scope',
  'ListItemsService'
];
function sfePaginationController(
  StorageService,
  AlertingService,
  $timeout,
  SfeFilterService,
  MetadataService,
  CrawlerMethods,
  $scope,
  ListItemsService
) {
  const vm = this;
  let typingTimeout;
  var numberOfPages;
  var paginationHasNext;
  var currentFilterObject = {};
  var previousOrderValue;
  vm.min = 0;
  vm.max = 0;
  vm.hasNext = hasNext;
  vm.hasPrevious = hasPrevious;
  vm.next = next;
  vm.previous = previous;
  vm.getCollection = getCollection;
  vm.onPaginationChange = onPaginationChange;
  vm.changeFromNumberInput = changeFromNumberInput;
  vm.fetchPaginationTotal = fetchPaginationTotal;
  /**
   * @description set initial data (selected order, current page, get saved filters and selected sort and then trigger data fetch)
   * @function
   */
  vm.$onInit = function init() {
    vm.sfeOrder = vm.sfeOrder || setOrder();
    vm.currentPage = 1;
    vm.sfeLimitOptions =
      Array.isArray(vm.sfeLimitOptions) && vm.sfeLimitOptions.length > 0
        ? vm.sfeLimitOptions
        : initLimitOptions([]);
    // get previously saved selected limit options
    let savedLimit = StorageService.getListRowPerPageData(vm.sfeListId);
    vm.sfeLimit =
      vm.sfeLimitOptions.indexOf(savedLimit) > -1
        ? savedLimit
        : vm.sfeLimitOptions[0];
    getStoredFilters();
    getCollection(true);
  };

  /**
   * @description checks if prefetch method is defined, triggers it and if method succeeded triggers items fetch.
   * @function
   */
  async function getCollection(initialLoad, reset) {
    if (reset) vm.currentPage = 1;
    vm.sfeLoadingData = true;
    const filterObject = angular.copy(vm.sfeFilterObject) || {};
    filterObject.limit = filterObject.limit || vm.sfeLimit;
    filterObject.order = vm.sfeOrder;
    filterObject.page = vm.currentPage;

    if (vm.resourceFn) {
      if (vm.prefetchAction) {
        const result = vm.prefetchAction(vm.sfeFilters, initialLoad);
        if (result && result.error) {
          AlertingService.Error(result.error);
          MetadataService.Loading(false);
          vm.sfeLoadingData = false;
          // check if prefetch method is async
          // when function resolves items fetch is triggered
        } else if (result && result.promise) {
          try {
            await result;
            fetchItems(filterObject);
          } catch (err) {
            AlertingService.Error(err);
          }
        } else {
          fetchItems(filterObject);
        }
      } else {
        fetchItems(filterObject);
      }
    }
  }

  /**
   * @description Tries to fetch the items and handles the response.
   * @function
   * @param {Object} filterObject Object with query params.
   */
  async function fetchItems(filterObject) {
    try {
      const { pagination, data } = await ListItemsService.fetchItems(
        vm.resourceFn,
        vm.sfeFilters,
        filterObject,
        null,
        saveFiltersToLocalStorage
      );
      handleResponse(pagination, data);
    } catch (err) {
      handleError(err);
    }
  }

  /**
   * @description Takes care of display of pages and availability of the next pages.
   * @function
   * @param {Object} res
   * @return {dataType}
   */
  function handleResponse(pagination, data) {
    MetadataService.Loading(false);
    vm.sfeLoadingData = false;

    // sets display data
    vm.sfeData = data;

    // handles pagination
    switch (vm.paginationType) {
    case 'countTotal':
      paginationHasNext = pagination.hasNext;
      pagination.total = vm.totalResults;
      setFromTo(pagination, data.length);
      break;
    default:
      if (pagination) {
        setTotalPages(pagination);
      } else if (data) {
        vm.totalResults = data.length;
        calculateMinMax();
      }
    }
    $scope.$evalAsync();
  }

  /**
   * @description sets new current page values according to new pagination object
   * @function
   */
  function setTotalPages(paginationObject) {
    vm.totalResults = paginationObject.total;
    numberOfPages = setNumberOfPages();
    onPaginationChange();
    calculateMinMax();
  }

  /**
   * @description calculates and sets items from (min) and to (max) indexes. (min - max from total)
   * @function
   */
  function calculateMinMax() {
    vm.min = isPositive(vm.totalResults)
      ? vm.currentPage * vm.sfeLimit - vm.sfeLimit + 1
      : 0;
    vm.max = hasNext() ? vm.currentPage * vm.sfeLimit : vm.totalResults;
  }

  /**
   * @description calculates and sets items from (min) and to (max) indexes. (min - max from total)
   * @function
   */
  function setFromTo(pagination, length) {
    var itemsLength = length;
    vm.currentPage = pagination.page;
    vm.min = (pagination.page - 1) * pagination['per_page'] + 1;
    vm.max = vm.min + (itemsLength - 1);
    if (vm.max < vm.min) {
      vm.max = vm.min = 0;
    }
  }

  /**
   * @description get previously saved list filters
   * @function
   */
  async function getStoredFilters() {
    const filters = Array.isArray(vm.filterValuesOverride)
      ? vm.filterValuesOverride
      : StorageService.getFilters(vm.sfeListId);
    await SfeFilterService.assignSelectedFiltersValues(vm.sfeFilters, filters);
    $scope.$evalAsync();
  }

  /**
   * @description get previously saved selected sort option
   * @function
   */
  function setOrder() {
    const sort = StorageService.getOrder(vm.sfeListId);
    if (sort && vm.sfeOrderOptions) {
      const foundSort = vm.sfeOrderOptions.find(item => item.params === sort);
      if (foundSort) {
        return sort;
      }
    } else if (vm.sfeOrderOptions) {
      return vm.sfeOrderOptions[0].params;
    }
    return '_id';
  }

  /**
   * @description initiate array of limit options
   * @function
   */
  function initLimitOptions(limitOptions) {
    var i = 5;
    while (i <= 50) {
      limitOptions.push(i);
      i += 5;
    }
    return limitOptions;
  }

  /**
   * @description Displays error.
   * @function
   * @param {string/Object} err Error to be displayed.
   */
  function handleError(err) {
    if (
      !(
        err &&
        err.config &&
        err.config.timeout &&
        err.config.timeout.$$state &&
        err.config.timeout.$$state.value === 'cancelled'
      )
    ) {
      AlertingService.Error(err);
    }
    MetadataService.Loading(false);
    vm.sfeLoadingData = false;
  }

  /**
   * @description Compare current and previous filter and save new filters to local storage if they change.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function saveFiltersToLocalStorage(currentFilter) {
    const current = { ...currentFilter };
    currentFilterObject = currentFilter; // used later to get the 'total results in the fetchPaginationTotal function
    const currentOrder = current.order;
    delete current.page;
    delete current.order;
    let equalityOfFilters = true;
    if (vm.last) {
      equalityOfFilters = compareFilterObjects(current, vm.last);
    }

    if (
      !equalityOfFilters ||
      (previousOrderValue &&
        previousOrderValue !==
          currentOrder) /* Check if order changed or filter changed */
    ) {
      //do note store configuration on initial list
      storeListMetadata();
    }

    if (!equalityOfFilters) {
      vm.currentPage = 1;
      vm.totalResults = null;
    }

    vm.last = { ...current };
    previousOrderValue = currentOrder;
  }

  /**
   * @description save current list settings to local storage
   * @function
   */
  function storeListMetadata() {
    StorageService.saveListData(
      vm.sfeListId,
      vm.sfeFilters,
      vm.sfeOrder,
      vm.sfeLimit
    );
  }

  /**
   * @description indicates if number is positive.
   * @function
   * @param {number} number number
   * @return {bool}
   */
  function isPositive(number) {
    return parseInt(number, 10) > 0;
  }

  /**
   * @description changes current page and triggers data fetch.
   * @function
   */
  function next() {
    if (hasNext()) {
      vm.currentPage++;
      getCollection();
    }
  }
  /**
   * @description changes current page and triggers data fetch.
   * @function
   */
  function previous() {
    if (hasPrevious()) {
      vm.currentPage--;
      getCollection();
    }
  }

  /**
   * @description indicates if there is another page to fetch.
   * @function
   * @return {Bool}
   */
  function hasNext() {
    switch (vm.paginationType) {
    case 'countTotal':
      return paginationHasNext;
    default:
      return vm.currentPage * vm.sfeLimit < vm.totalResults;
    }
  }
  /**
   * @description indicates if there is previous page to fetch.
   * @function
   * @return {Bool}
   */
  function hasPrevious() {
    return vm.currentPage > 1;
  }
  /**
   * @description calculates total number of pages
   * @function
   */
  function setNumberOfPages() {
    return isPositive(vm.totalResults)
      ? Math.ceil(vm.totalResults / (isPositive(vm.sfeLimit) ? vm.sfeLimit : 1))
      : 1;
  }

  function changeFromNumberInput() {
    $timeout(changeFromNumberInputTimeout, 600);
  }
  /**
   * @description triggers fetch items on page change.
   * @function
   */
  function changeFromNumberInputTimeout() {
    if (typingTimeout) {
      $timeout.cancel(typingTimeout);
    }
    switch (vm.paginationType) {
    case 'countTotal':
      if (vm.totalResults) {
        checkInputPagesAndList();
      } else {
        if (angular.isNumber(vm.currentPage) && vm.currentPage > 0) {
          typingTimeout = $timeout(getCollection, 500);
        }
      }
      break;
    default:
      checkInputPagesAndList();
    }
  }
  /**
   * @description validates page input value and triggers fetch items.
   * @function
   */
  function checkInputPagesAndList() {
    if (angular.isNumber(vm.currentPage)) {
      if (!(vm.currentPage <= numberOfPages) || !(vm.currentPage > 1)) {
        vm.currentPage = vm.currentPage <= 1 ? 1 : numberOfPages;
      }
      typingTimeout = $timeout(getCollection, 500);
    }
  }
  /**
   * @description validates page and triggers fetch items.
   * @function
   */
  function onPaginationChange(list, page) {
    // Check if there are no visible items on the page go one page back
    if (
      vm.currentPage > 1 &&
      (vm.currentPage - 1) * vm.sfeLimit > vm.totalResults
    ) {
      vm.currentPage--;
    }
    if (list) {
      if (typeof page !== 'undefined') {
        vm.currentPage = page;
      }
      getCollection();
    }
  }
  /**
   * @description compares objects.
   * @function
   * @param {object} newObject new object
   * @param {object} oldObject  old object
   * @return {Bool}
   */
  function compareFilterObjects(newObject, oldObject) {
    return angular.equals(newObject, oldObject);
  }
  /**
   * @description method is triggered when paginationType is countTotal, sends request to fetch total number of items and sets total label.
   * @function
   */
  function fetchPaginationTotal() {
    var method = CrawlerMethods.getMethod({
      entity: vm.resourceFn.entity,
      method: vm.resourceFn.methodTotal
    });
    vm.totalIsLoading = true;
    method(currentFilterObject).then(
      function(res) {
        if (angular.isNumber(res.data)) {
          vm.totalResults = res.data;
        } else {
          vm.totalResults = null;
        }
        vm.totalIsLoading = false;
        numberOfPages = setNumberOfPages();
      },
      function(err) {
        AlertingService.Error(err);
        vm.totalResults = null;
        vm.totalIsLoading = false;
      }
    );
  }
}
