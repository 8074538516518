import basic from '../components/sfe-property/templates/property-basic.html';
import large from '../components/sfe-property/templates/property-large.html';
import mathExpression from '../components/sfe-property/templates/property-math-expression.html';
import tags from '../components/sfe-property/templates/property-tags.html';
import divider from '../components/sfe-property/templates/property-divider.html';
import log from '../components/sfe-property/templates/property-log.html';

export default function SfePropertyTemplates() {
  return {
    basic,
    large,
    mathExpression,
    tags,
    divider,
    log
  };
}
