module.exports = {
  entity: 'weather-stations',
  path: 'weather-stations',
  name: {
    en: 'Weather station',
    sl_SI: 'Vremenska postaja'
  },
  networkModel: {
    entity: 'weather-stations',
    read: 'read'
  },
  scope: 'tis_be:weather-stations',
  selectedParamName: 'id',
  dialog: {
    title: {
      en: 'Select weather station',
      sl_SI: 'Izberite vremensko postajo'
    },
    listInfo: {
      en: 'Select weather station from the list below',
      sl_SI: 'Izberite vremensko postajo s spodnjega seznama'
    }
  }
}
