SelectItemController.$inject = [
  'idsToExclude',
  'entities',
  'configurations',
  '$mdDialog',
  'SfeItemSelectorConfigurations',
  'gettext',
  'EntitiesService',
  'AlertingService'
];

export default function SelectItemController(
  idsToExclude,
  entities,
  configurations,
  $mdDialog,
  SfeItemSelectorConfigurations,
  gettext,
  EntitiesService,
  AlertingService
) {
  var vm = this;
  vm.addSelected = addSelected;
  vm.selectedItems = [];
  vm.treeViewChanged = treeViewChanged;
  vm.selectActiveConfig = selectActiveConfig;

  init();
  /**
   * @description initialize selector dialog configuration
   * @function
   */
  function init() {
    vm.entities = entities;
    vm.idsToExclude = idsToExclude;
    vm.configurations = [];
    if (Array.isArray(vm.entities)) {
      vm.entities.forEach(function(entity, index) {
        var conf = EntitiesService.getDialog(entity);

        if (!conf) {
          if (configurations[index].domainValues) {
            conf = {};
          } else {
            AlertingService.Error(
              'There is no dialog configuration for entity ' + entity
            );
            return;
          }
        }
        conf.treeView = EntitiesService.getTreeViewConfiguration(entity);
        // temp filters
        var listConfiguration = SfeItemSelectorConfigurations.configuration(
          entity
        );
        // prefetch action that is defined in sfe-list configuration
        vm.sfeListPrefetchAction =
          listConfiguration && listConfiguration.api
            ? listConfiguration.api.prefetchAction
            : null;

        conf.filter = angular.copy(listConfiguration.filter);
        conf.filterObject = listConfiguration.filterObject || {};
        conf.filterObject.order = listConfiguration.sort
          ? listConfiguration.sort[0].params || '_id'
          : null;
        // end temp
        var config = angular.copy(
          Object.assign(angular.copy(conf), configurations[index] || {})
        );
        config.entity = entity;
        vm.configurations.push(config);
        vm.configurations[index].activeConfig = false;
      });
    }

    vm.title =
      vm.configurations.length === 1
        ? vm.configurations[0].title
        : gettext('Select item');
    vm.header = {
      title: vm.title,
      isDialog: true,
      actions: [
        {
          icon: {
            name: 'close',
            type: 2
          },
          cancel: true
        }
      ]
    };
    if (vm.configurations.length > 0) {
      if (vm.configurations[0].preFetchAction) {
        vm.configurations[0].preFetchAction(function() {
          vm.configurations[0] = angular.copy(
            Object.assign(vm.configurations[0], configurations[0] || {})
          );
          initializeConfiguration(0);
        });
      } else {
        initializeConfiguration(0);
      }
    }
  }
  /**
   * @description when view mode changes reset selected items.
   * @function
   */
  function treeViewChanged() {
    vm.selectedItems = [];
  }

  /**
   * @description resolves selected items.
   * @function
   * @return {Promise}
   */
  function addSelected() {
    if (vm.selectedItems) {
      vm.selectedItems.forEach(function(item) {
        item.selectedEntity = vm.config.path;
      });
    }
    $mdDialog.hide(vm.selectedItems);
  }
  /**
   * @description sets activeConfig flag and ini.
   * @function
   * @param {number} index active index
   */
  function selectActiveConfig(index) {
    if (vm.configurations) {
      vm.configurations.forEach((config, confIndex) => {
        if (index === confIndex) {
          if (!config.activeConfig) {
            initializeConfiguration(confIndex);
          }
        } else {
          config.activeConfig = false;
        }
      });
    }
  }
  /**
   * @description initializes select configuration at index.
   * @function
   * @param {number} index selected index
   */
  function initializeConfiguration(index) {
    vm.config = vm.configurations[index];
    vm.configurations[index].activeConfig = true;
    vm.treeView = {};
    if (vm.config.treeView) {
      vm.treeConfig = {
        query: {
          entity: vm.config.path,
          method: 'read'
        },
        displayFields: vm.config.treeView.displayFields
      };
    }
  }
}
