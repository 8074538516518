import template from './tango-header-item-detail.component.html';
import './tango-header-item-detail.scss';
import valuesAction from '../../../data/redux/time-series-processing-values/action/time-series-processing-values.action';

export default {
  template,
  bindings: {
    entity: '<',
    entityId: '<',
    configuration: '<'
  },
  controller: TangoHeaderItemDetailController,
  controllerAs: 'vm'
};
/**
 * @ngdoc component
 * @name common.TangoHeaderItemDetail
 * @description used to display entity last value.
 * @param {number} entity entity codelist name
 * @param {string} entityId entity mongo Id
 * @param {object} configuration
 * CONFIGURATION EXAMPLE
 *{
 *   type <string> Allowed values - value|stored|dynamic
 *     when type is value, configuration must contain all needed props under the value key
 *     when type is stored, all needed props will be saved in store[entityStore][entityId]
 *     when type is dynamic, configuration must contain valueFn that will return all needed props
 *   displayType <string> - Allowed value - value
 *   valueFn <function> - is used only when type is dynamic
 *     function accepts store as an argument and returns props that will be bind to display header detail
 *   fetchValue <function> - async function that is used to fetch tango-detail data,
 *       that we couldn't fetch in main request (e.g. last value)
 *   getStoredItem <function> - is required when fetchValue function exists
 *       store and entityId are passed as function arguments. Should return stored Entity.
 *   intervalFrequency <number> number of ms how often fetchValue function should be triggered. minValue 10minutes
 *
 *}
 *
 * @example
 * <tango-header-item
 * entity="vm.entity"
 * entity-id="vm.entityId"
 * configuration="vm.configuration"
 * ></tango-header-item>
 */

TangoHeaderItemDetailController.$inject = [
  '$scope',
  '$interval',
  '$ngRedux',
  'ReduxStoreService',
  '$window',
  '$timeout'
];

function TangoHeaderItemDetailController(
  $scope,
  $interval,
  $ngRedux,
  ReduxStoreService,
  $window,
  $timeout
) {
  const vm = this;
  let unsubscribe;
  let stopInterval;
  let lastExecutionTimestamp;
  //INTERVAL LIMIT
  const tenMinutes = 60 * 1000 * 10;
  //destroy store listener
  $scope.$on('$destroy', onScopeDestroy);

  /**
   * @description Removes listeners from scope.
   * @function
   */
  function onScopeDestroy() {
    if (unsubscribe != null) {
      unsubscribe();
    }

    angular.element($window).unbind('resize', setBackgroundColor);
  }
  //Bind lister to window, to set correct background binding to sfe-value
  angular.element($window).bind('resize', setBackgroundColor);

  /**
   * @description sets the isLightBackground value to true
   * for medium size screens.
   * @function
   */
  function setBackgroundColor() {
    if ($window.innerWidth >= 1048 && $window.innerWidth < 1400) {
      vm.isLightBackground = true;
    } else {
      vm.isLightBackground = false;
    }
    $scope.$evalAsync();
  }

  vm.$onChanges = () => {
    //starts store watching when all bindings are set
    if (
      vm.entity != null &&
      vm.entityId != null &&
      vm.configuration != null &&
      unsubscribe == null
    ) {
      unsubscribe = $ngRedux.connect(mapStateToProps)(vm);
      setBackgroundColor();
    }
  };

  /**
   * @description method that triggers last value async method.
   * @function
   * @param {Object} store
   */
  async function fetchValue(store) {
    if (typeof vm.configuration.fetchValue == 'function') {
      //when configuration has fetchValue function defined
      // it must have getStoredItem function defined
      //that fetchValue function would be triggered only after main item is fetched
      const storedItem = vm.configuration.getStoredItem(store, vm.entityId);
      // limit value fetch on 10  minutes
      //because fetchValue function is getting
      //triggered on every store update
      const sinceLastRequest = lastExecutionTimestamp
        ? Date.now() - lastExecutionTimestamp
        : tenMinutes;

      if (storedItem != null && sinceLastRequest >= tenMinutes) {
        lastExecutionTimestamp = Date.now();
        vm.displayValue = await vm.configuration.fetchValue(store);
        if (
          typeof vm.configuration.intervalFrequency == 'number' &&
          vm.configuration.intervalFrequency > tenMinutes &&
          stopInterval == null
        ) {
          startLastValueInterval(store);
        }
      }
    }
  }
  /**
   * @description starts interval that refreshes last value.
   * @function
   * @param {Object} store
   */
  function startLastValueInterval(store) {
    stopInterval = $interval(
      fetchValue,
      vm.configuration.intervalFrequency,
      0,
      false,
      store //argument that will be passed to fetchValue function
    );
  }

  /**
   * @description checks if entity id is in the update processing values object then refresh chart item removed from store in tango-chart-component
   * @function
   * @param {Object} store
   */
  async function updateChartValues(store) {
    if (
      store != null &&
      store.timeSeriesProcessingValues != null &&
      store.timeSeriesProcessingValues[vm.entityId] != null &&
      store.timeSeriesProcessingValues[vm.entityId].detail == true
    ) {
      vm.displayValue = await vm.configuration.fetchValue(store);
      await $timeout();
      let action = valuesAction.removeUpdateValuesTag({ id: vm.entityId });
      $ngRedux.dispatch(action);
    }
  }

  /**
   * @description triggered every time when store is changed.
   * all object keys/vales that will be returned wll be assigned to scope
   * @function
   * @param {object} state
   * @return {object}
   */
  function mapStateToProps(state) {
    updateChartValues(state);

    const tangoItem = ReduxStoreService.extract(
      state,
      vm.entity,
      vm.entityId,
      vm.configuration
    );
    //trigger fetch value function
    fetchValue(state);
    if (
      state != null &&
      vm.entityId != null &&
      state.timeSeries != null &&
      state.timeSeries[vm.entityId] != null
    ) {
      vm.timeSeries = state.timeSeries[vm.entityId].data;
      if (
        vm.timeSeries != null &&
        vm.timeSeries.dataVisualizationConfig != null
      ) {
        vm.color = vm.timeSeries.dataVisualizationConfig.color;
      }
    }

    //cancel watch for state change
    //because we are only interacted when main entity arrives
    return { tangoItem };
  }
  /**
   * @description unsubscribes from store and stops interval.
   * @function
   */
  function destroy() {
    if (unsubscribe != null) {
      unsubscribe();
    }
    if (stopInterval != null) {
      $interval.cancel(stopInterval);
    }
  }
  //assign state properties and measurement actions to controller scope
  // and listen for changes
  $scope.$on('$destroy', destroy);
}
