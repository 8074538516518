import './user-privilege.scss';

UserPrivilegeController.$inject = [
  'CrawlerMethods',
  'AlertingService',
  'StorageService',
  '$state',
  '$scope',
  '$timeout',
  'StandardUtils',
  'CrudToastFactory'
];

function UserPrivilegeController(
  CrawlerMethods,
  AlertingService,
  StorageService,
  $state,
  $scope,
  $timeout,
  StandardUtils,
  CrudToastFactory
) {
  var vm = this;

  var selectedEntity = $state.params.entity;
  vm.userId = $state.params.id;
  vm.entityType = $state.params.entityType;
  vm.SetEntityTree = SetEntityTree;
  vm.viewEntityType = viewEntityType;
  vm.entityTypes = [];
  if (
    $state.current.name === 'user-group-privilege' ||
    $state.current.name === 'user-group-privilege-item' ||
    $state.current.name === 'user-group-modules'
  ) {
    vm.isGroup = true;
    vm.mode = $state.current.name.replace('-group', '');
  } else {
    vm.mode = $state.current.name;
  }

  vm.interfaceLoading = true;
  vm.userDataLoading = true;
  vm.privilegeEntities = [];
  vm.changeEditPermission = changeEditPermission;

  Initiate();
  function Initiate() {
    setEditPermissions();
    vm.interfaceLoading = true;
    vm.userDataLoading = true;
    var parallel = [FetchUser, FetchEntityForest];
    async.parallel(parallel, function() {
      vm.userDataLoading = false;
      vm.user.imageUrl = StandardUtils.getUserImageUrl(vm.user, 'md');
      if (selectedEntity) {
        var selectedEntityTree = vm.entityForest.find(function(tree) {
          return tree.identifier === selectedEntity;
        });
        vm.selectedEntityTree = selectedEntityTree || vm.entityForest[0];
        if (!selectedEntityTree) {
          $state.go(
            $state.current.name,
            {
              entity: vm.selectedEntityTree.identifier
            },
            {
              notify: false
            }
          );
        }
        StorageService.save(
          'selectedEntityOnManagement',
          vm.selectedEntityTree._id
        );
      } else {
        var selected = StorageService.get('selectedEntityOnManagement');
        if (selected) {
          vm.selectedEntityTree =
            vm.entityForest.find(function(tree) {
              return tree._id === selected;
            }) || vm.entityForest[0];
        } else {
          vm.selectedEntityTree = vm.entityForest[0];
          StorageService.save(
            'selectedEntityOnManagement',
            vm.entityForest[0]._id
          );
        }
        $state.go(
          $state.current.name,
          {
            entity: vm.selectedEntityTree.identifier
          },
          {
            notify: false
          }
        );
      }
      switch (vm.mode) {
      case 'user-privilege':
        InitiatePrivilegeManagement();
        break;
      case 'user-modules':
        $timeout(InitiateUserModules);
        break;
      }
    });
  }

  function setEditPermissions() {
    var method = CrawlerMethods.getMethod({
      entity: 'authorization-static-access',
      method: 'read'
    });
    method({
      owner: vm.userId
    }).then(
      function(res) {
        var editPermissions = _.find(res.data, {
          scope: 'authorizer:edit_permissions'
        });
        vm.editPermissions =
          editPermissions &&
          editPermissions.access &&
          editPermissions.access.create;
      },
      function(err) {
        AlertingService.Error(err);
        vm.editPermissions = !vm.editPermissions;
      }
    );
  }

  function changeEditPermission() {
    var method = CrawlerMethods.getMethod({
      entity: 'authorization-static-access',
      method: 'create'
    });
    method({
      owner: vm.userId,
      scope: 'authorizer:edit_permissions',
      scopeName: 'Edit permissions',
      access: {
        create: vm.editPermissions
      }
    }).then(
      function() {
        CrudToastFactory.toast('update');
      },
      function(err) {
        AlertingService.Error(err);
        vm.editPermissions = !vm.editPermissions;
      }
    );
  }

  function InitiateUserModules() {
    if (vm.selectedEntityTree._id) {
      $scope.$broadcast('user-module-changed', {
        treeId: vm.selectedEntityTree._id
      });
    }
  }

  function InitiatePrivilegeManagement() {
    vm.privilegeEntities = [];
  }

  function FetchUser(callback) {
    var apiEndpoint = vm.isGroup
      ? CrawlerMethods.getMethod({
        entity: 'groups',
        method: 'read'
      })
      : CrawlerMethods.getMethod({
        entity: 'users',
        method: 'read'
      });
    apiEndpoint({
      id: vm.userId
    }).then(
      function(res) {
        vm.user = res.data;
        callback();
      },
      function(err) {
        AlertingService.Error(err);
        callback();
      }
    );
  }

  // fetch entity trees
  function FetchEntityForest(callback) {
    var method = CrawlerMethods.getMethod({
      entity: 'authorization-trees',
      method: 'read'
    });
    method().then(
      function(res) {
        vm.entityForest = res.data.sort(function(entity1, entity2) {
          return entity1.name >= entity2.name ? 1 : -1;
        });
        vm.entityForest = _.filter(vm.entityForest, function(item) {
          switch (vm.mode) {
          case 'user-privilege':
          case 'user-privilege-item':
            return item.privilegeManagement;
          case 'user-modules':
            return item.moduleConfig;
          }
        });
        callback();
      },
      function(err) {
        AlertingService.Error(err);
        callback();
      }
    );
  }

  function viewEntityType(entityType) {
    $state.go($state.current.name, {
      userId: vm.userId,
      entity: $state.params.entity,
      entityType: entityType.scope
    });
    vm.entityType = entityType.scope;
  }

  function SetEntityTree(tree) {
    if (
      tree !== vm.selectedEntityTree ||
      ($state.params && $state.params.entityType)
    ) {
      vm.selectedEntityTree = tree;

      switch (vm.mode) {
      case 'user-privilege':
        $state.go($state.current.name, {
          entity: vm.selectedEntityTree.identifier,
          id: $state.params.id
        });
        InitiatePrivilegeManagement();
        break;
      case 'user-modules':
        InitiateUserModules();
        break;
      case 'user-privilege-item':
        var state = $state.current.name.replace('-item', '');
        $state.go(state, {
          id: vm.userId,
          entity: vm.selectedEntityTree.identifier
        });
        break;
      }
      StorageService.save('selectedEntityOnManagement', tree._id);
    }
  }
}
export default UserPrivilegeController;
