import template from './sfe-entity-privilege-management-item.component.html';
import './sfe-entity-privilege-management.scss';
/**
 * @ngdoc component
 * @name user. SfeEntityPrivilegeManagementItem
 * @description displays and allows to edit selected entity permissions.
 * @param {string} treeId permission tree id
 * @param {string} userId user id
 * @param {array} items tree items filled in the component and displayed in parent scope
 * @param {string} entityType selected entity identifier
 * @example
 * <sfe-entity-privilege-management-item
 *   user-id="vm.userid"
 *   tree-id="vm.selectedEntityTreeId"
 *   entity-type="vm.entityType"
 *   items="vm.items"
 * >
 * </sfe-entity-privilege-management-item>
 */
export default {
  template,
  bindings: {
    treeId: '<',
    userId: '<',
    entityType: '<',
    items: '='
  },
  controllerAs: 'vm',
  controller: SfeEntityPrivilegeManagementItemController
};
SfeEntityPrivilegeManagementItemController.$inject = [
  'PrivilegeHelperMethods',
  'AlertingService',
  'CrawlerMethods',
  'ToastService',
  'gettext',
  '$timeout',
  '$anchorScroll',
  '$stateParams',
  '$scope'
];

function SfeEntityPrivilegeManagementItemController(
  PrivilegeHelperMethods,
  AlertingService,
  CrawlerMethods,
  ToastService,
  gettext,
  $timeout,
  $anchorScroll,
  $stateParams,
  $scope
) {
  const vm = this;
  /**
   * @description when treeId is available trigger init.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  vm.$onChanges = function() {
    if (vm.treeId) {
      GetTreeConfig();
      $anchorScroll();
    }
  };

  // static permissions configuration
  vm.treeGlobalStaticPrivileges = [
    {
      name: 'create',
      value: false,
      order: 1
    },
    {
      name: 'read',
      value: false,
      order: 2
    },
    {
      name: 'update',
      value: false,
      order: 3
    },
    {
      name: 'delete',
      value: false,
      order: 4
    },
    {
      name: 'list',
      value: false,
      order: 5
    }
  ];

  vm.getKeys = Object.keys;
  vm.crudFilter = crudFilter;
  vm.toggleAccess = toggleAccess;
  vm.entityForest = {};
  vm.selectAllInTreeEntityChanged = selectAllInTreeEntityChanged;

  vm.userId = $stateParams.id;
  /**
   * @description crud items filter function.
   * @function
   * @param {string} item crud string
   * @return {number}
   */
  function crudFilter(item) {
    switch (item) {
    case 'create':
      return 1;
    case 'read':
      return 2;
    case 'update':
      return 3;
    case 'delete':
      return 4;
    case 'list':
      return 5;
    }
  }

  /**
   * @description updates all entity static permissions to the same value.
   * @function
   * @param {object} entityItem entity configuration
   */
  async function selectAllInTreeEntityChanged(entityItem) {
    entityItem.dataIsLoading = true;
    const postObject = {
      owner: vm.userId,
      scope: entityItem.config.scope,
      scopeName: entityItem.config.scopeName,
      show: entityItem.config.show,
      access: {
        create: entityItem.selectAllInTreeEntity,
        delete: entityItem.selectAllInTreeEntity,
        list: entityItem.selectAllInTreeEntity,
        read: entityItem.selectAllInTreeEntity,
        update: entityItem.selectAllInTreeEntity
      }
    };

    const method = CrawlerMethods.getMethod({
      entity: 'authorization-static-access',
      method: 'create'
    });
    try {
      await method(postObject);
      entityItem.config.access = postObject.access;
      entityItem.dataIsLoading = false;
      setSelectAllInTreeValue();
      vm.treeGlobalStaticPrivileges.forEach(function(globalKind) {
        // check if all are checked globally
        checkIfAllInTreeAreChecked(globalKind.name);
      });
    } catch (err) {
      AlertingService.Error(err);
      entityItem.selectAllInTreeEntity = !entityItem.selectAllInTreeEntity;
      entityItem.dataIsLoading = false;
    }

    $scope.$applyAsync();
  }

  /**
   * @description checks if all static permissions are set to the sam value and updates all toggle.
   * @function
   * @param {string} kind access string
   * @return {dataType}
   */
  function checkIfAllInTreeAreChecked(kind) {
    $timeout(function() {
      let allOfKindAreChecked = true;
      let accessObj;
      let entityItem;
      Object.keys(vm.entityForest[vm.treeId]).forEach(function(entityItemKey) {
        entityItem = vm.entityForest[vm.treeId][entityItemKey];
        Object.keys(entityItem.config.access).forEach(function(accessKey) {
          accessObj = entityItem.config.access[accessKey];
          if (accessKey === kind && !accessObj) {
            allOfKindAreChecked = false;
          }
        });
      });
      vm.treeGlobalStaticPrivileges.forEach(function(globalKind) {
        if (globalKind.name === kind) {
          globalKind.value = allOfKindAreChecked;
        }
      });
    });
  }
  /**
   * @description iterates over all tree entities and setts all toggle
   * @function
   */
  function setSelectAllInTreeValue() {
    $timeout(function() {
      Object.keys(vm.entityForest[vm.treeId]).forEach(function(treeItemKey) {
        setAllForEntity(vm.entityForest[vm.treeId][treeItemKey]);
      });
    });
  }

  /**
   * @description setts all toggle for selected entity.
   * @function
   * @param {object} entity entity configuration
   */
  function setAllForEntity(entity) {
    let allTrue = true;
    Object.keys(entity.config.access).forEach(function(accessItemValueKey) {
      if (!entity.config.access[accessItemValueKey]) {
        allTrue = false;
      }
    });
    entity.selectAllInTreeEntity = allTrue;
    return allTrue;
  }

  /**
   * @description fetches privilege management configuraitons on init.
   * @function
   */
  async function GetTreeConfig() {
    vm.entityForest = {};
    vm.initIsLoading = true;
    let accessObj;
    let configValue;

    try {
      const treeConfig = await PrivilegeHelperMethods.getEntitiesAndPrivilegesByTree(
        vm.treeId,
        vm.userId
      );

      vm.entityForest[vm.treeId] = treeConfig.result;

      vm.treeGlobalStaticPrivileges.forEach(function(kind) {
        kind.value = true;
        Object.keys(vm.entityForest[vm.treeId]).forEach(function(accessKey) {
          accessObj = vm.entityForest[vm.treeId][accessKey];

          Object.keys(accessObj.config.access).forEach(function(configKey) {
            configValue = accessObj.config.access[configKey];
            if (configKey === kind.name && !configValue) {
              kind.value = false;
            }
          });
        });
      });
      vm.items = Object.keys(vm.entityForest[vm.treeId])
        .reduce((previous, key) => {
          return [
            ...previous,
            {
              scope: vm.entityForest[vm.treeId][key].config.scope,
              scopeName: vm.entityForest[vm.treeId][key].config.scopeName
            }
          ];
        }, [])
        .sort((a, b) => {
          return a.scopeName.localeCompare(b.scopeName);
        });

      vm.item = vm.entityForest[vm.treeId][vm.entityType];
      vm.treeConfiguration = treeConfig.tree;
      setSelectAllInTreeValue();
    } catch (err) {
      AlertingService.Error(err);
    }
    vm.initIsLoading = false;
  }
  /**
   * @description updates ui and posts to api new permision values.
   * @function
   * @param {object} obj permission object
   * @param {string} key key that has been updated
   * @param {object} element authorization tree entity configuraion
   */
  function toggleAccess(obj, key, element) {
    obj[key] = !obj[key];
    element.dataIsLoading = true;
    const method = CrawlerMethods.getMethod({
      entity: 'authorization-static-access',
      method: 'create'
    });
    method({
      owner: vm.userId,
      scope: element.config.scope,
      scopeName: element.config.scopeName,
      show: element.config.show,
      access: element.config.access
    })
      .then(
        function() {
          ToastService.showToast(gettext('Configuration updated.'));
          setSelectAllInTreeValue();
          element.dataIsLoading = false;
        },
        function(err) {
          AlertingService.Error(err);
          element.dataIsLoading = false;
          obj[key] = !obj[key];
        }
      )
      .then(function() {
        checkIfAllInTreeAreChecked(key);
      });
  }
}
