import template from './sfe-simple-math-expression.directive.html';

export default function sfeSimpleMathExpression() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      variables: '=',
      expression: '=',
      hideProperties: '=',
      uniqueId: '=',
      series: '=',
      removeRow: '=',
      errors: '=?'
    },
    link: linkFn,
    controller: sfeSimpleMathExpressionController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  function linkFn() {}
}

sfeSimpleMathExpressionController.$inject = [
  '$timeout',
  '$scope',
  'gettext',
  'gettextCatalog'
];

function sfeSimpleMathExpressionController(
  $timeout,
  $scope,
  gettext,
  gettextCatalog
) {
  var vm = this;
  vm.expressionConstructor = expressionConstructor;
  var QUEUE = MathJax.Hub.queue;
  MathJax.Hub.Queue([
    'Typeset',
    MathJax.Hub,
    'previewExpression' + vm.uniqueId
  ]);
  vm.allowedOperators = ['+', '-', '*', '/', '^', '%'];

  var expressionWatcher = $scope.$watch('vm.expression', function() {
    MathJax.Hub.Queue(function() {
      vm.expressionConstructor();
    });
  });

  var variablesWatcher = $scope.$watch('vm.variables', function() {
    vm.expressionConstructor();
  });

  $scope.$on('$destroy', function() {
    if (expressionWatcher) {
      expressionWatcher();
    }
    if (variablesWatcher) {
      variablesWatcher();
    }
  });

  function validateMathParseObject(mathObj, motherFunctionArray, errorObject) {
    var positionString = '';
    var isMotherObject = motherFunctionArray.length === 1;
    var existsAsVariable;
    for (var index in motherFunctionArray) {
      if (index !== 0) {
        positionString += ' in function ';
      } else if (index === motherFunctionArray.length - 1) {
        positionString += ' in ';
      }
      positionString +=
        motherFunctionArray[motherFunctionArray.length - 1 - index];
    }
    positionString += '.';

    switch (mathObj.type) {
    case 'AssignmentNode':
      errorObject.push({
        error: gettextCatalog.getString('Assignment is not allowed'),
        position: positionString
      });
      break;

      // PARENTHESIS
    case 'ParenthesisNode':
      var motherFunctionArrayNext = motherFunctionArray.concat([
        'Parenthesis'
      ]);
      var obj = mathObj.content;
      validateMathParseObject(obj, motherFunctionArrayNext, errorObject);
      break;

      // FUNCTIONS
    case 'FunctionNode':
      var functionName = mathObj.fn.name;
      errorObject.push({
        error: gettextCatalog.getString(
          '{{functionName}} is not a valid function.',
          {
            functionName
          }
        ),
        position: positionString
      });

      break;

      // OPERATORS
    case 'OperatorNode':
      functionName = mathObj.fn;
      var implicit = mathObj.implicit;
      var operator = mathObj.op;
      if (implicit) {
        errorObject.push({
          error: gettextCatalog.getString(
            'Implicit operation {{functionName}} is not allowed.',
            {
              functionName
            }
          ),
          position: positionString
        });
      }

      if (vm.allowedOperators.indexOf(operator) === -1) {
        errorObject.push({
          error: gettextCatalog.getString(
            'Operation {{functionName}} is not allowed.',
            {
              functionName
            }
          ),
          position: positionString
        });
      }

      motherFunctionArrayNext = motherFunctionArray.concat([
        'operator ' + functionName
      ]);
      for (var index2 in mathObj.args) {
        var obj2 = mathObj.args[index2];
        validateMathParseObject(obj2, motherFunctionArrayNext, errorObject);
      }

      break;
      // VARIABLES
      // when variable name is in quotes it is parsed as a constant node
      // otherwise as a symbol node
    case 'ConstantNode':
      var value = mathObj.value;
      // if constatnt node is a string
      // check varibale names
      if (typeof value === 'string') {
        existsAsVariable = false;
        vm.variables.forEach(function(variable) {
          if (variable === value) {
            existsAsVariable = true;
            vm.numberOfVarialblesUsed++;
          }
        });

        if (!existsAsVariable) {
          errorObject.push({
            error: gettextCatalog.getString(
              'Variable {{value}} is not defined',
              {
                value
              }
            ),
            position: positionString
          });
        }
      }
      break;
    case 'SymbolNode':
      var name = mathObj.name;
      errorObject.push({
        error: gettextCatalog.getString(
          'Variable names must be surrounded by double quotes {{name}}.',
          { name }
        ),
        position: positionString
      });
      break;
    }

    if (isMotherObject) {
      _.sortBy(errorObject, function(o) {
        return o.indexOfExpression;
      });
    }
  }

  function expressionConstructor() {
    if (typeof vm.expression === 'undefined' || vm.expression === '') {
      return;
    }

    var mathObj = null;
    var initialError = false;
    vm.errors = [];

    try {
      // we must replace single quotes with double once in order to render expression
      vm.expression = vm.expression.replace('\'', '"');
      mathObj = math.parse(vm.expression);
    } catch (err) {
      vm.errors.push({
        error: gettextCatalog.getString(
          'Unexpected end of expression (character at position {{char}}).',
          {
            char: err.char
          }
        ),
        position: 'Main expression'
      });
      initialError = true;
    }

    if (!initialError) {
      vm.numberOfVarialblesUsed = 0;

      validateMathParseObject(mathObj, ['Main expression'], vm.errors);
      if (vm.numberOfVarialblesUsed === 0) {
        var variableErrorExist = vm.errors.find(function(errorObject) {
          return errorObject.noVariables;
        });

        if (!variableErrorExist) {
          vm.errors.push({
            noVariables: true,
            error: gettext(
              'Math expression must contain at least one valid variable name.'
            )
          });
        }
      }
    }
    var element = MathJax.Hub.getAllJax('previewExpression' + vm.uniqueId)[0];

    if (element) {
      $timeout(function() {
        // Before adding element to queue override original text function
        //  To avoid math jax error failure Do not call original Text function wheen script tag doesn't exist
        var originalTextFunction = element.Text;
        element.Text = function(text, callback) {
          if (this) {
            var script = this.SourceElement();
            if (script) {
              originalTextFunction.apply(this, [text, callback]);
            }
          }
        };
        QUEUE.Push(['Text', element, vm.expression]);
      }, 500);
    }
  }
}
