CompanyStructureNodeController.$inject = [
  'TranslationService',
  '$stateParams',
  'gettext',
  'MetadataService',
  'HierarchyTreeDialog',
  'SfeFormDialogService',
  'CrudToastFactory',
  'AlertingService',
  'CompanyStructureNodeModel',
  'companyStructureNode',
  '$timeout'
];

function CompanyStructureNodeController(
  TranslationService,
  $stateParams,
  gettext,
  MetadataService,
  HierarchyTreeDialog,
  SfeFormDialogService,
  CrudToastFactory,
  AlertingService,
  CompanyStructureNodeModel,
  companyStructureNode,
  $timeout
) {
  const vm = this;
  const companyStructureNodeId = $stateParams.id;
  vm.$onInit = init;

  function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    vm.headerData = constructHeader(companyStructureNode);
    vm.loading = false;
    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(
        companyStructureNode.name,
        companyStructureNode.description
      );
    });
  }

  function addChildNode() {
    let editMode = true;
    let dataObj = {};
    if (this.type === undefined) {
      editMode = false;
    } else {
      dataObj = {
        type: this.type
      };
    }
    const config = getForm(editMode);
    const title = gettext('Add Child Node');
    SfeFormDialogService.openSfeFormDialog(false, config, dataObj, title).then(
      function(object) {
        if (object) {
          create(object);
        }
      }
    );
  }

  function create(obj) {
    obj.type = obj.type.id;
    obj.parentId = companyStructureNodeId;
    CompanyStructureNodeModel.create(obj).then(
      function() {
        CrudToastFactory.toast('create');
        init();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function getForm(editMode) {
    return [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField'
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        maxlength: 500,
        required: false,
        componentType: 'textArea'
      },
      {
        placeholder: gettext('Code'),
        name: 'code',
        componentType: 'textField',
        required: true
      },
      {
        placeholder: gettext('External Code'),
        name: 'externalCode',
        componentType: 'textField',
        maxLength: 30,
        required: false
      },
      {
        name: 'type',
        edit: editMode,
        componentType: 'autocompleteDialog',
        configuration: {
          codelist: 'costCentreTypes',
          entity: 'costCentreTypes',
          nodialog: true,
          floatingLabel: gettext('Cost Centre Type'),
          searchParamName: 'name',
          required: true
        }
      }
    ];
  }

  function constructHeader(companyStructureNode) {
    const actions = getActions(companyStructureNode);
    const properties = getProperties(companyStructureNode);
    const propertySections = [
      {
        properties,
        title: gettext('properties')
      }
    ];
    return {
      metadata: {
        definition: gettext('Company Structure Node'),
        title: companyStructureNode.name,
        description: companyStructureNode.description
      },
      actions,
      propertySections
    };
  }

  function getProperties(companyStructureNode) {
    const properties = [
      {
        title: gettext('Type'),
        value: TranslationService.GetCollectionById(
          'codelists.costCentreTypes',
          companyStructureNode.type
        ).name,
        type: 'simple'
      },
      {
        title: gettext('Code'),
        value: companyStructureNode.code,
        type: 'code'
      }
    ];
    if (companyStructureNode.externalCode) {
      properties.push({
        title: gettext('External Code'),
        value: companyStructureNode.externalCode,
        type: 'code'
      });
    }
    if (companyStructureNode.parentId) {
      properties.push({
        title: gettext('Parent Company Structure Node'),
        linkTitle: companyStructureNode.parentId.name,
        state: 'configurations-company-resources-company-structure-nodes-view',
        param: 'id',
        paramValue: companyStructureNode.parentId._id,
        type: 'link'
      });
    }
    return properties;
  }

  function getActions(companyStructureNode) {
    return [
      {
        title: gettext('Show Hierarchy'),
        fn: async function() {
          let nodeItem = angular.copy(companyStructureNode);
          try {
            const {
              data: node
            } = await CompanyStructureNodeModel.custom.readItem({
              id: companyStructureNodeId
            });
            nodeItem = node;
          } catch (err) {
            AlertingService.Error(err);
          }
          HierarchyTreeDialog.openDialog(nodeItem, 'company-structure-nodes');
        }
      },
      {
        title: gettext('Add Child Node'),
        fn: addChildNode.bind(companyStructureNode)
      },
      {
        title: gettext('delete'),
        delete: true,
        id: companyStructureNodeId,
        endpoint: {
          entity: 'company-structure-nodes',
          method: 'delete'
        },
        redirectState:
          'configurations-company-resources-company-structure-nodes-list'
      },
      {
        title: gettext('Update'),
        state: 'configurations-company-resources-company-structure-nodes-edit',
        param: 'id',
        paramValue: companyStructureNode._id
      }
    ];
  }
}

export default CompanyStructureNodeController;
