import actionTypes from '../action/external-reader-flow.type';

ExternalReaderFlowMiddleware.$inject = [
  'ExternalReaderFlowModel',
  'AlertingService',
  'CrudToastFactory'
];
function ExternalReaderFlowMiddleware(
  ExternalReaderFlowModel,
  AlertingService,
  CrudToastFactory
) {
  return () => next => async action => {
    switch (action.type) {
    case actionTypes.EXTERNAL_READER_FLOW_READ:
      try {
        let result = await ExternalReaderFlowModel.read({
          ...action.payload,
          populate:
              'timeout.alarm,externalDatasource,readScheduler,liveIngestionConfig.mapper,liveIngestionConfig.tangoAgentConfig.connectionConfig,liveIngestionConfig.tangoAgentConfig.tag,historyIngestionConfig.mapper,historyIngestionConfig.tangoAgentConfig.connectionConfig,historyIngestionConfig.tangoAgentConfig.tag'
        });
        action.payload = {
          ...action.payload,
          result
        };
      } catch (err) {
        AlertingService.Error(err);
        action.payload.result = err;
      }
      break;
    case actionTypes.EXTERNAL_READER_FLOW_UPDATE:
      try {
        await ExternalReaderFlowModel.update(
          action.payload.query,
          action.payload.body
        );

        CrudToastFactory.toast('update');
      } catch (err) {
        AlertingService.Error(err);
      }
      try {
        action.payload.result = await ExternalReaderFlowModel.read({
          ...action.payload.query,
          populate:
              'timeout.alarm,externalDatasource,readScheduler,liveIngestionConfig.mapper,liveIngestionConfig.tangoAgentConfig.connectionConfig,liveIngestionConfig.tangoAgentConfig.tag,historyIngestionConfig.mapper,historyIngestionConfig.tangoAgentConfig.connectionConfig,historyIngestionConfig.tangoAgentConfig.tag'
        });
      } catch (err) {
        AlertingService.Error(err);
        action.payload.result = err;
      }
      break;
    case actionTypes.EXTERNAL_READER_FLOW_CREATE:
      try {
        action.payload.result = await ExternalReaderFlowModel.create(
          action.payload.query || {},
          action.payload.body
        );
      } catch (err) {
        AlertingService.Error(err);
      }
      break;
    }
    next(action);
  };
}

export default ExternalReaderFlowMiddleware;
