TimeSeriesLastValueService.$inject = [
  'TimeSeriesModel',
  'TimeSeriesProcessingValuesModel',
  'Formatting',
  'AlertingService',
  'TranslationService'
];

/**
 * @ngdoc service
 * @name data.TimeSeriesLastValueService
 * @description Service to create interval refreshing function in an organized manner.
 * @property {function} newRefresher - creates new refresher function
 * @property {function} remove - deletes refresher
 * @property {function} removeFn - removes single function from a refresher
 * @property {function} addFn - adds a function to a refresher
 */
function TimeSeriesLastValueService(
  TimeSeriesModel,
  TimeSeriesProcessingValuesModel,
  Formatting,
  AlertingService,
  TranslationService
) {
  function getCacheWrapper(cacheInvalidationTime) {
    return id => get(id, cacheInvalidationTime);
  }
  //TODO TEST VISUALIZATIONS
  async function get(id, cacheInvalidationTime) {
    try {
      let { data } = await TimeSeriesModel.read(
        { id, populate: 'measurementUnit' },
        cacheInvalidationTime
      );
      let { data: values } = await TimeSeriesProcessingValuesModel.read(
        { timeSeriesId: id, limit: 1 },
        cacheInvalidationTime
      );
      if (data.metricPrefix != null) {
        data.measurementMetricPrefix = TranslationService.GetCollectionById(
          'codelists.metricPrefixes',
          data.metricPrefix
        );
      }
      data.measurementMeasurementUnit = data.measurementUnit;
      let lastValue = values[0];
      if (Array.isArray(values) && values.length > 0) {
        lastValue.value = Formatting.formatDatapointDisplay(
          data.dataType,
          lastValue.value,
          data.precision
        );
      }
      data.lastValue = lastValue;
      return data;
    } catch (err) {
      AlertingService.Error(err);
      return {};
    }
  }
  return {
    get,
    getCacheWrapper
  };
}

export default TimeSeriesLastValueService;
