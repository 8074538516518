SfeItemSelectorConfigurations.$inject = [
  'SfeListConfigurations',
  'gettext',
  'TranslationService'
];

export default function SfeItemSelectorConfigurations(
  SfeListConfigurations,
  gettext,
  TranslationService
) {
  function configuration(entity) {
    var config =
      angular.copy(SfeListConfigurations.configuration[entity]) || {};
    config.filterObject = config.filterObject || {};
    if (config.sort && config.sort[0].params) {
      config.filterObject.order = config.sort[0].params;
    }
    config.entity = entity;
    switch (entity) {
    case 'addresses':
      config.filter = [
        {
          param: 'country',
          entity: ['countries'],
          display: gettext('Country'),
          valueField: 'id',
          collection: TranslationService.GetCollection('codelists.countries'),
          type: 'searchSelect',
          populate: 'address'
        },
        {
          display: gettext('Settlement'),
          param: 'settlementName',
          type: 'string'
        },
        {
          display: gettext('Street'),
          param: 'streetName',
          type: 'string'
        }
      ];
      break;
    case 'countries':
      config.filter = [
        {
          display: gettext('Name or Description'),
          param: 'name',
          type: 'string'
        }
      ];
      break;
    case 'currencies':
      config.filter = [
        {
          display: gettext('Name'),
          param: 'name',
          type: 'string'
        }
      ];
      break;
    case 'locations':
      config.showTree = true;
      config.title = gettext('Select Location');
      config.apiRoute = 'locations';
      config.listInfo = gettext('Select location from the list below');
      config.hasChild = 'hasChild';
      config.name = 'locations';
      config.displayFields = ['name'];
      config.selectedParamName = '_id';
      break;
    case 'cost-centres':
      config.showTree = true;
      config.title = gettext('Select Cost Centre');
      config.apiRoute = 'cost-centres';
      config.listInfo = gettext('Select cost centre from the list below');
      config.hasChild = 'hasChild';
      config.name = 'costCentres';
      config.displayFields = ['code', 'name'];
      config.selectedParamName = '_id';
      break;
    case 'energy-source-types':
    case 'energySourceType':
      config.showTree = true;
      config.title = gettext('Select energy source type');
      config.listInfo = gettext('Select energy source from the list below');
      config.apiRoute = 'energy-source-types';
      config.hasChild = 'hasChild';
      config.name = 'energySourceTypes';
      config.displayFields = ['code', 'name'];
      config.selectedParamName = '_id';
      break;
    case 'assets':
      config.showTree = true;
      config.title = gettext('Select asset');
      config.listInfo = gettext('Select asset from the list below');
      config.apiRoute = 'assets';
      config.hasChild = 'hasChild';
      config.name = 'assets';
      config.displayFields = ['name'];
      config.selectedParamName = '_id';
      break;
    case 'entity-tags':
      config.title = gettext('Select tag');
      config.listInfo = gettext('Select tag from the list below');
      config.apiRoute = 'entity-tags';
      config.displayFields = ['value'];
      config.filter = [
        {
          display: gettext('Name'),
          param: 'value',
          type: 'string'
        }
      ];
      break;
    case 'analyses':
      config.title = gettext('Select analyses');
      config.listInfo = gettext('Select analyses from the list below');
      config.apiRoute = 'analyses';
      config.displayFields = ['name'];
      break;
    case 'measuringpoints':
      config.title = gettext('Select measuring point');
      config.listInfo = gettext('Select measuring point from the list below');
      config.apiRoute = 'measuringpoints';
      config.displayFields = ['name'];
      break;
    case 'business-partners':
      config.title = gettext('Select business partner');
      config.listInfo = gettext(
        'Select business partner from the list below'
      );
      config.apiRoute = 'business-partners';
      config.displayFields = ['code', 'name'];
      config.filter = config.filter.concat([
        {
          display: gettext('Parent business partner'),
          param: 'parentId',
          query: {
            entity: 'business-partners',
            method: 'read'
          },
          refreshObject: {
            order: 'name'
          },
          type: 'searchSelect',
          refreshParameterName: 'name',
          selectedParamName: '_id'
        }
      ]);
      break;
    case 'counter-types':
      config.title = gettext('Select counter type');
      config.listInfo = gettext('Select counter type from the list below');
      config.apiRoute = 'counter-types';
      config.filter = [
        {
          display: gettext('Name or Description'),
          param: 'filter',
          type: 'string'
        }
      ];
      config.displayFields = ['code', 'name'];
      break;
    case 'location-classifications':
      config.title = gettext('Select location classification');
      config.listInfo = gettext(
        'Select location classification from the list below'
      );
      config.apiRoute = 'location-classifications';
      config.displayFields = ['code', 'name'];
      break;
    case 'alarms':
    case 'alarm-definitions':
      config.title = gettext('Select alarms');
      config.listInfo = gettext('Select alarms from the list below');
      config.apiRoute = 'alarms';
      config.displayFields = ['name'];
      break;
    case 'visualizations':
      config.title = gettext('Select visualization');
      config.listInfo = gettext('Select visualization from the list below');
      config.apiRoute = 'visualizations';
      config.displayFields = ['name'];
      config.filter = [
        {
          display: gettext('Name or Description'),
          param: 'filter',
          type: 'string'
        }
      ];
      break;
    case 'gis-users':
      config.filter = [
        {
          display: gettext('Name'),
          param: 'first_name',
          type: 'string'
        },
        {
          display: gettext('lastname'),
          param: 'last_name',
          type: 'string'
        },
        {
          display: gettext('username'),
          param: 'username',
          type: 'string'
        }
      ];
      break;
    case 'price-list-details':
      config.filter = [
        ...config.filter,
        {
          query: {
            entity: 'price-list-masters',
            method: 'read'
          },
          entity: 'price-list-masters',
          display: gettext('Master Price List'),
          param: 'priceListMaster',
          displayList: ['name'],
          type: 'searchSelect',
          refreshParameterName: 'filter',
          selectedParamName: '_id'
        }
      ];
      break;
    case 'timeZones':
      config.filter = [
        {
          display: gettext('Code'),
          param: 'code',
          type: 'string'
        }
      ];
      break;
    }

    return config;
  }
  return {
    configuration: configuration
  };
}
