import './logical-expression.component.scss';
import template from './logical-expression.component.html';
import logicalExpressionTemplate from './logical-expression.template.html';
import logicalExpressionPreviewTemplate from './logical-expression-preview.template.html';

export default {
  template,
  bindings: {
    inputExpression: '<',
    outputExpression: '=?',
    api: '<'
  },
  require: {
    parentForm: '?^form'
  },
  controller: LogicalExpressionController,
  controllerAs: 'vm'
};

/**
 * @ngdoc component
 * @name common.logicalExpression
 * @description Component for display and setting of logical expressions.
 * @param {string} inputExpression - Input expression
 * @param {string} outputExpression - Output expression
 * @param {Array} allowedVariables - Array of allowed variables
 * @example
 * <logical-expression
 * input-expression="'a + 1000'"
 * output-expression="vm.outputExpression"
 * ></logical-expression>
 */

LogicalExpressionController.$inject = [
  '$scope',
  '$timeout',
  '$q',
  '$interval',
  'AllowedFunctionsService',
  'AllowedOperatorsService',
  'ValidateMathExpression',
  'gettextCatalog'
];

function LogicalExpressionController(
  $scope,
  $timeout,
  $q,
  $interval,
  AllowedFunctionsService,
  AllowedOperatorsService,
  ValidateMathExpression,
  gettextCatalog
) {
  var vm = this;
  var QUEUE = MathJax.Hub.queue;
  var allowedFunctions = AllowedFunctionsService.getConstants();
  var allowedOperators = AllowedOperatorsService.getConstants();
  var itemsToInit = [];
  var itemsToInitWatcher;
  var variableWatcher;
  vm.logicalExpressionTemplate = logicalExpressionTemplate;
  vm.logicalExpressionPreviewTemplate = logicalExpressionPreviewTemplate;
  vm.toggleIfExpression = toggleIfExpression;
  vm.onSelectClose = onSelectClose;
  vm.expressionConstructor = expressionConstructor;
  vm.errors = [];
  vm.actions = [];

  const generateRandomId = () =>
    `expression-preview-${Math.random()
      .toString(36)
      .substring(2)}`;

  vm.expressionObject = {
    isRootItem: true, //v primeru da je new forma da se expression prvic typeSet-a treba poklicati MathJax.Hub.Queue(['Typeset', MathJax.Hub]);, za edit formo to nastavimo na false
    id: generateRandomId(),
    ifEnabled: true,
    trueExpression: {
      id: generateRandomId()
    },
    falseExpression: {
      id: generateRandomId()
    }
  };

  $scope.$on('$destroy', function() {
    if (variableWatcher) {
      variableWatcher();
    }

    if (itemsToInitWatcher) {
      $interval.cancel(itemsToInitWatcher);
    }
  });
  vm.$onInit = function() {
    if (vm.parentForm) {
      $scope.ItemForm = vm.parentForm;
    }
    if (vm.api != null) {
      vm.api.parseLogicalExpression = parseLogicalExpression;
    }
  };

  vm.$onChanges = function(changes) {
    if (changes.inputExpression && vm.inputExpression) {
      vm.inputExpression = vm.inputExpression
        .replace(/\|\|/gi, 'or')
        .replace(/&&/gi, 'and');

      vm.expressionObject.isRootItem = false;
      initExpression();
    }
  };

  /**
   * @description Parses logical expression.
   * @function
   * @param {Object} expressionObject Object containing information about the expression
   */
  function parseLogicalExpression(expressionObject) {
    var isRoot;
    if (!expressionObject) {
      expressionObject = vm.expressionObject;
      isRoot = true;
    }
    expressionConstructor(expressionObject);
    if (expressionObject.trueExpression) {
      parseLogicalExpression(expressionObject.trueExpression);
    }
    if (expressionObject.falseExpression) {
      parseLogicalExpression(expressionObject.falseExpression);
    }
    if (isRoot) {
      vm.outputExpression = constructOutputExpression();
      MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
      initAllJax();
      var numberOfUsedVariables = getNumberOfUsedVariables();
      validateNumberOfVariables(numberOfUsedVariables);
    }
  }

  /**
   * @description Validates the number of variables.
   * @function
   * @param {number} numberOfUsedVariables Number of used variables.
   */
  function validateNumberOfVariables(numberOfUsedVariables) {
    if (
      angular.isNumber(numberOfUsedVariables) &&
      numberOfUsedVariables === 0
    ) {
      if (!vm.expressionObject.errors) {
        vm.expressionObject.errors = [];
      }
      var noVariablesError = vm.expressionObject.errors.find(function(item) {
        return item.noVariablesError;
      });
      if (!noVariablesError) {
        vm.expressionObject.errors.push({
          error: gettextCatalog.getString(
            'You have to use at least one variable'
          ),
          noVariablesError: true
        });
      }
    } else if (vm.expressionObject.errors) {
      vm.expressionObject.errors = vm.expressionObject.errors.filter(function(
        error
      ) {
        return !error.noVariablesError;
      });
    }
    validateFormInput(vm.expressionObject);
  }

  /**
   * @description Returns the number of used variables in the expression.
   * @function
   * @param {Object} expressionObject Object containing information about the expression
   * @return {number} Number of used variables.
   */
  function getNumberOfUsedVariables(expressionObject) {
    var mathObj;
    if (!expressionObject) {
      expressionObject = vm.expressionObject;
    }
    try {
      mathObj = math.parse(expressionObject.expression);
      var numberOfUsedVariables = ValidateMathExpression.getNumberOfUsedVariables(
        mathObj
      );
      if (expressionObject.trueExpression) {
        numberOfUsedVariables += getNumberOfUsedVariables(
          expressionObject.trueExpression
        );
      }
      if (expressionObject.falseExpression) {
        numberOfUsedVariables += getNumberOfUsedVariables(
          expressionObject.falseExpression
        );
      }
      return numberOfUsedVariables;
    } catch (err) {
      // error is handled in expressoin constructor
    }
  }

  /**
   * @description Constructs the expression.
   * @function
   * @param {Object} expressionObject Object containing information about the expression
   */
  function expressionConstructor(expressionObject) {
    if (vm.parentForm) {
      if (!expressionObject) {
        expressionObject = vm.expressionObject;
      }
      var mathObj = null;
      var initialError = false;
      expressionObject.errors = [];
      try {
        expressionObject.expression = expressionObject.expression || '';
        mathObj = math.parse(expressionObject.expression);
      } catch (err) {
        initialError = {
          error: err.message
        };
      }

      if (initialError) {
        expressionObject.errors.push(initialError);
      } else {
        let allowedVariables = [];
        if (vm.api != null && typeof vm.api.getVariables === 'function') {
          allowedVariables = vm.api.getVariables();
        }

        expressionObject.errors = ValidateMathExpression.getErrors(
          mathObj,
          ['Main expression'],
          [],
          allowedFunctions,
          allowedOperators,
          allowedVariables
        );
      }

      if (!expressionObject.errors.length) {
        if (expressionObject.isRootItem) {
          MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
          expressionObject.isRootItem = false;
        }
        initMathJaxElement(expressionObject);
      }

      validateFormInput(expressionObject);

      vm.outputExpression = constructOutputExpression();
    }
    var numberOfUsedVariables = getNumberOfUsedVariables();
    validateNumberOfVariables(numberOfUsedVariables);
  }

  /**
   * @description Constructs the output expression.
   * @function
   * @param {Object} expressionObject Object containing information about the expression
   */
  function constructOutputExpression(expressionObject) {
    var result = '';
    if (!expressionObject) {
      expressionObject = vm.expressionObject;
    }

    if (!expressionObject.ifEnabled) {
      if (!expressionObject.expression) {
        expressionObject.expression = '';
      }
      if (expressionObject.operationStatus == 0) {
        return 'FAIL';
      }
      if (expressionObject.operationStatus == 1) {
        return 'SUCCESS';
      }

      return expressionObject.expression
        .replace(/or/gi, '||')
        .replace(/and/gi, '&&');
    } else {
      let trueExpression = '';
      if (expressionObject.trueExpression) {
        trueExpression = constructOutputExpression(
          expressionObject.trueExpression
        );
      }
      let falseExpression = '';
      if (expressionObject.falseExpression) {
        falseExpression = constructOutputExpression(
          expressionObject.falseExpression
        );
      }
      result += `if (${expressionObject.expression},${trueExpression},${falseExpression})`;

      return result.replace(/or/gi, '||').replace(/and/gi, '&&');
    }
  }

  function initExpression() {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    var nodesObject = math.parse(vm.inputExpression);
    parseNodes(nodesObject);
    $timeout(parseLogicalExpression);
  }

  /**
   * @description Initializes math jax elements.
   * @function
   * @param {Object} expressionObject Object containing information about the expression
   */
  function initAllJax(expressionObject) {
    if (!expressionObject) {
      expressionObject = vm.expressionObject;
    }
    initMathJaxElement(expressionObject);
    if (expressionObject.trueExpression) {
      initAllJax(expressionObject.trueExpression);
    }
    if (expressionObject.falseExpression) {
      initAllJax(expressionObject.falseExpression);
    }
  }

  /**
   * @description Transform variable node to constant node.. to display variables that contain more than one symbol in their name
   * @function
   * @param {Object} node Node to be checked on how to be returned.
   * @return {Object} Node that is returned
   */
  function parseDisplayExpression(node) {
    let allowedVariables = [];
    if (vm.api != null && typeof vm.api.getVariables === 'function') {
      allowedVariables = vm.api.getVariables();
    }
    switch (node.type) {
    case 'SymbolNode':
      if (allowedVariables && allowedVariables.indexOf(node.name) > -1) {
        return new math.expression.node.ConstantNode(node.name);
      } else {
        return node;
      }
    case 'ConstantNode':
      return transformNumber(node);
    default:
      return node;
    }
  }
  /**
   * @description parses number to bigNumber and then signifies it to avoid displaying e notation.
   * After node is stringified quotes must be removed
   * @function
   * @param {Object} node mathjs node
   * @return {Object} mathjs node
   */
  function transformNumber(node) {
    if (typeof node.value == 'number' && node.value != 0) {
      node.value = math.bignumber(node.value).toString();
    }
    return node;
  }

  /**
   * @description Parses nodes.
   * @function
   * @param {Object} nodesObject Nodes to be parsed
   * @param {Object} resultExpressionObject Object to be inited with math jax
   */
  function parseNodes(nodesObject, resultExpressionObject) {
    var isRoot;
    if (!resultExpressionObject) {
      isRoot = true;
      resultExpressionObject = vm.expressionObject;
    }
    if (nodesObject.type === 'FunctionNode') {
      var functionName = nodesObject.fn.name;
      if (functionName === 'if') {
        for (var index in nodesObject.args) {
          var obj = nodesObject.args[index];
          resultExpressionObject.ifEnabled = true;
          switch (Number(index)) {
          case 0:
            resultExpressionObject.expression = obj
              .transform(transformNumber)
              .toString()
              .replace(/"/g, '');

            if (!isRoot) {
              resultExpressionObject.id = generateRandomId();
            }
            initMathJaxElement(resultExpressionObject);
            break;
          case 1:
            resultExpressionObject.trueExpression = {};
            parseNodes(obj, resultExpressionObject.trueExpression);

            break;
          case 2:
            resultExpressionObject.falseExpression = {};
            parseNodes(obj, resultExpressionObject.falseExpression);
            break;
          }
        }
      } else {
        resultExpressionObject.expression = nodesObject.toString();
        resultExpressionObject.id = generateRandomId();
        initMathJaxElement(resultExpressionObject);
      }
    } else {
      resultExpressionObject.id = generateRandomId();
      initMathJaxElement(resultExpressionObject);

      switch (nodesObject.toString()) {
      case 'SUCCESS':
        resultExpressionObject.expression = '';
        resultExpressionObject.operationStatus = 1;
        break;
      case 'FAIL':
        resultExpressionObject.expression = '';
        resultExpressionObject.operationStatus = 0;
        break;
      default:
        resultExpressionObject.expression = nodesObject
          .transform(transformNumber)
          .toString()
          .replace(/"/g, '');
      }
    }
  }

  /**
   * @description Initializes the expression object.
   * @function
   * @param {Object} expressionObject Object to be initialized.
   * @return {dataType}
   */
  function initMathJaxElement(expressionObject) {
    var deferred = $q.defer();
    $timeout(function() {
      var element = MathJax.Hub.getAllJax(expressionObject.id)[0];
      if (!element) {
        var itemIsOnTheList = itemsToInit.find(function(itemToInit) {
          return itemToInit.id === expressionObject.id;
        });
        if (!itemIsOnTheList) {
          itemsToInit.push(expressionObject);
          watchForItemsToInit();
        }
        deferred.resolve({
          error: expressionObject
        });
      } else {
        var foundItem = QUEUE.queue.find(function(el) {
          return el.object ? el.object.inputID === element.inputID : false;
        });
        if (!foundItem) {
          // Before adding element to queue override original text function
          //  To avoid math jax error failure Do not call original Text function wheen script tag doesn't exist
          var originalTextFunction = element.Text;
          element.Text = function(text, callback) {
            if (this) {
              var script = this.SourceElement();
              if (script) {
                originalTextFunction.apply(this, [text, callback]);
              }
            }
          };
          if (expressionObject.expression) {
            expressionObject.previewExpression = math
              .parse(expressionObject.expression)
              .transform(parseDisplayExpression);
            $timeout(function() {
              MathJax.Hub.Queue([
                'Text',
                element,
                expressionObject.previewExpression
              ]);
            });
          }
        }
        deferred.resolve({
          success: expressionObject
        });
      }
    });
    return deferred.promise;
  }

  /**
   * @description Watches for items to be initialized with math jax.
   * @function
   */
  function watchForItemsToInit() {
    if (!itemsToInitWatcher) {
      vm.renderingMathJax = true;
      itemsToInitWatcher = $interval(function() {
        if (itemsToInit.length < 1 && itemsToInitWatcher) {
          $interval.cancel(itemsToInitWatcher);
          MathJax.Hub.Queue(renderingFinished);
        } else {
          var promises = [];
          itemsToInit.forEach(function(item) {
            promises.push(initMathJaxElement(item));
          });
          $q.all(promises).then(function(results) {
            var idsToRemove = results.map(function(el) {
              if (el.success) {
                return el.success.id;
              } else {
                return null;
              }
            });

            idsToRemove = idsToRemove.filter(function(item) {
              return item;
            });

            itemsToInit = itemsToInit.filter(function(item) {
              var itemIsntInited = idsToRemove.find(function(id) {
                return id === item.id;
              });
              return !itemIsntInited;
            });
            if (itemsToInit.length < 1 && itemsToInitWatcher) {
              $interval.cancel(itemsToInitWatcher);

              MathJax.Hub.Queue(renderingFinished); //push to queue to indicate the end
            }
          });
        }
      });
    }
  }

  /**
   * @description Set expression to fail expression.
   * @function
   * @param {Object} expressionObject Containing info on whether if is enabled or disabled
   */
  function onSelectClose() {
    vm.outputExpression = constructOutputExpression();
  }

  /**
   * @description Toggles if expression.
   * @function
   * @param {Object} expressionObject Containing info on whether if is enabled or disabled
   */
  function toggleIfExpression(expressionObject) {
    expressionObject.ifEnabled = !expressionObject.ifEnabled;
    if (expressionObject.ifEnabled) {
      expressionObject.trueExpression = {
        id: generateRandomId(),
        operationStatus: 0
      };
      expressionObject.falseExpression = {
        id: generateRandomId(),
        operationStatus: 1
      };
      $timeout(function() {
        MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
      });
    } else {
      expressionObject.trueExpression = null;
      expressionObject.falseExpression = null;
      parseLogicalExpression(expressionObject);
    }
  }

  /**
   * @description Indicates the rendering has finished.
   * @function
   */
  function renderingFinished() {
    vm.renderingMathJax = false;
    $scope.$apply();
  }

  /**
   * @description Validates the form input.
   * @function
   * @param {Object} item Item to be checked for containing errors
   */
  function validateFormInput(item) {
    if ($scope.ItemForm && $scope.ItemForm[item.id]) {
      if (Array.isArray(item.errors) && item.errors.length > 0) {
        $scope.ItemForm[item.id].$setValidity('parseError', false);
      } else {
        $scope.ItemForm[item.id].$setValidity('parseError', true);
      }
    }
  }
}
