EntityTagService.$inject = [
  '$q',
  'AlertingService',
  '$timeout',
  'EntityTagBindingModel',
  'SystemTagEntityModel',
  'SystemTagModel'
];
/**
 * @ngdoc service
 * @name description.EntityTagService
 * @description Manages entity tags.
 * @property {function} createSystemTags - See createSystemTags method.
 * @property {function} listTags - See listTags method.
 */
export default function EntityTagService(
  $q,
  AlertingService,
  $timeout,
  EntityTagBindingModel,
  SystemTagEntityModel,
  SystemTagModel
) {
  /**
   * @memberof description.EntityTagService
   * @description Called on create or updated, determines new tags and tags that have been remove.
   * @param {Array} newTags - Tags that have been added
   * @param {Array} originalTags - Tags that were there upon loading of page
   * @param {integer} entityType - Id of the entity type the tags were added to.
   * @param {string} entityId - Id of entity to which the tags were added to.
   * @param {boolean} v1 - True if the request url is a v1, false if it is not.
   * @return {promise} Promise about the added/deleted tags.
   */
  function createSystemTags(newTags, originalTags, entityType, entityId, v1) {
    var def = $q.defer();
    var tagsToAdd = [];
    var tagsToDelete = [];
    var createAPIObject;
    var deleteAPIObject;
    // calculate differences only if original tags existed - else only add new tags
    if (originalTags && originalTags.length > 0) {
      tagsToAdd = _.differenceBy(newTags, originalTags, '_id');
      tagsToDelete = _.differenceBy(originalTags, newTags, '_id');
      tagsToAdd = tagsToAdd.map(function(tag) {
        return tag._id;
      });
    } else if (Array.isArray(newTags)) {
      tagsToAdd = newTags.map(function(tag) {
        return tag._id;
      });
    }

    // prepare objects for tag creation based on v1
    if (v1) {
      createAPIObject = {
        entityId: entityId,
        entityTag: JSON.stringify(tagsToAdd),
        entityType: entityType
      };
    } else {
      createAPIObject = {
        entityId: entityId,
        tagsToAdd: tagsToAdd,
        entityType: entityType
      };
    }

    deleteAPIObject = {
      entityId: entityId,
      tagsToDelete: tagsToDelete
    };

    // if tags need to be deleted
    if (tagsToDelete.length > 0) {
      deleteTags(deleteAPIObject, v1).then(function() {
        if (tagsToAdd.length < 1 || !entityType) {
          def.resolve();
          return;
        }
        createTags(createAPIObject, v1).then(function() {
          def.resolve();
        });
      });
    }
    // if tags need to be only added
    else {
      $timeout(function() {
        if (tagsToAdd.length < 1 || !entityType) {
          def.resolve();
          return;
        }
        createTags(createAPIObject, v1).then(function() {
          def.resolve();
        });
      }, 0);
    }
    return def.promise;
  }

  // list tags
  /**
   * @memberof description.EntityTagService
   * @description List all tasks for a specific entity/item
   * @param {string} entityId - Id of the entity by which we query the tags.
   * @param {boolean} v1 - True if the request url is a v1, false if it is not.
   * @return {promise} Promise that the queried tags will be shown.
   */
  async function listTags(entityId, entityTags) {
    var def = $q.defer();
    if (entityTags) {
      EntityTagBindingModel.custom
        .populateEntityTag({
          entityId: entityId
        })
        .then(
          res => {
            const entityTagsData = res.data;
            const uniqueTags = entityTagsData.map(tag => ({
              ...tag,
              entityTag: {
                ...tag.entityTag,
                bindingId: tag._id
              }
            }));
            def.resolve(uniqueTags);
          },
          err => {
            AlertingService.Error(err);
            def.resolve([]);
          }
        );
    } else {
      var waterfall = [fetchEntitySystemTags, fetchSystemTags];
      async.waterfall(waterfall, function(err, tags, bindings) {
        // add bindingID (id of the tag) to resolved ids - deletion is based on this bindingId
        tags.forEach(function(tag) {
          bindings.forEach(function(binding) {
            if (tag._id === binding.systemTag) {
              tag.bindingId = binding._id;
            }
          });
        });
        if (!err) {
          def.resolve({
            tags: tags
          });
        } else {
          AlertingService.Error(err);
          def.reject();
        }
      });
    }

    // ------- SYSTEM TAGS --------- //
    // get system tag bindings of the entity
    function fetchEntitySystemTags(callback) {
      SystemTagEntityModel.read({
        entityId: entityId
      }).then(
        function(res) {
          callback(null, res.data);
        },
        function(err) {
          AlertingService.Error(err);
          callback(null, []);
        }
      );
    }

    // get system tag info based on bindings
    function fetchSystemTags(bindings, callback) {
      if (!bindings || !bindings.length) {
        callback(null, []);
        return;
      }
      SystemTagModel.read({
        _id: bindings.map(function(tag) {
          return tag.systemTag;
        })
      }).then(
        function(res) {
          callback(null, res.data, bindings);
        },
        function(err) {
          AlertingService.Error(err);
          callback(null, []);
        }
      );
    }
    return def.promise;
  }
  /**
   * @description Binds tag to specified entity.
   * @function
   * @param {Object} apiObj - Contains id of entity, id of tag and type of entity.
   * @param {boolean} entityTag - True if the request url is an entity tag, false if it is not.
   * @return {promise}
   */
  // create
  function createTags(apiObj, entityTag) {
    var def = $q.defer();
    if (entityTag) {
      EntityTagBindingModel.create(apiObj).then(
        function() {
          def.resolve();
        },
        function(err) {
          AlertingService.Error(err);
          def.resolve();
        }
      );
    } else {
      async.every(
        apiObj.tagsToAdd,
        function(tag, callback) {
          SystemTagEntityModel.create({
            entityType: apiObj.entityType,
            entityId: apiObj.entityId,
            systemTag: tag
          }).then(
            function() {
              callback(null);
            },
            function(err) {
              AlertingService.Error(err);
              callback(null);
            }
          );
        },
        function(err) {
          AlertingService.Error(err);
          def.resolve();
        }
      );
    }
    return def.promise;
  }

  /**
   * @description Delets the binding between entity and tag.
   * @function
   * @param {Object} apiObj - Contains id of entity, id of tag and type of entity.
   * @param {boolean} v1 -  v1 - True if the request url is a v1, false if it is not.
   * @return {promise}
   */
  function deleteTags(apiObj, v1) {
    var def = $q.defer();
    var errors = [];
    var request;
    if (v1) {
      request = EntityTagBindingModel;
    } else {
      request = SystemTagEntityModel;
    }
    if (apiObj.length < 1) {
      def.resolve();
      return;
    }
    async.every(
      apiObj.tagsToDelete,
      function(tag, callback) {
        request
          .delete({
            id: tag.bindingId
          })
          .then(
            function() {
              callback(null);
            },
            function(err) {
              errors.push(err);
              callback(null);
            }
          );
      },
      function(err) {
        if (err) {
          AlertingService.Error(err);
        } else if (errors.length > 0) {
          AlertingService.Error(err);
        }
        def.resolve();
      }
    );
    return def.promise;
  }
  return {
    createSystemTags: createSystemTags,
    listTags: listTags
  };
}
