ReportingReportViewerController.$inject = [
  '$state',
  'AlertingService',
  'ReportModel',
  'ReportGeneralModel',
  'StandardUtils',
  'MetadataService'
];

function ReportingReportViewerController(
  $state,
  AlertingService,
  ReportModel,
  ReportGeneralModel,
  StandardUtils,
  MetadataService
) {
  const vm = this;
  const reportId = $state.params.id;
  vm.loaded = true;

  init();

  function init() {
    loadingToggle(true);
    vm.loadingReport = true;
    var parallel = {
      report: fetchReport,
      schema: fetchReportSchema,
      data: fetchReportData
    };
    async.parallel(parallel, function(err, result) {
      if (err) {
        loadingToggle(false);
        AlertingService.Error(err);
      } else {
        renderReport(result.report ? result.report : {}, result.data);
        loadingToggle(false);
      }
    });
  }
  function loadingToggle(loading) {
    vm.fetchingReportPreview = loading;
    MetadataService.Loading(loading);
    vm.loadingReport = loading;
  }
  function fetchReport(callback) {
    ReportModel.custom
      .readItemViewEditForm({
        id: reportId
      })
      .then(
        function(res) {
          callback(null, res.data);
        },
        function(err) {
          callback(err);
        }
      );
  }

  function fetchReportSchema(callback) {
    ReportGeneralModel.custom
      .getReportSchema({
        id: reportId
      })
      .then(
        function(res) {
          callback(null, res);
        },
        function(err) {
          callback(err);
        }
      );
  }

  async function fetchReportData(callback) {
    const postObject =
      StandardUtils.queryStringToJSON($state.params.query) || {};
    try {
      const res = await ReportGeneralModel.custom.getReportData(
        { reportId },
        postObject
      );
      callback(null, res);
    } catch (err) {
      callback(err);
    }
  }

  function renderReport(reportItem, reportData) {
    const report = new Stimulsoft.Report.StiReport();
    const templateEntity = reportItem.dataset
      ? reportItem.dataset.name
      : 'demo';
    report.load(reportItem.stimulsoftReportTemplate);
    const dataSet = new Stimulsoft.System.Data.DataSet(templateEntity);
    dataSet.readJson(JSON.stringify(reportData));
    report.regData(templateEntity, '', dataSet);

    const viewer = new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
    viewer.report = report;
    vm.loadingReport = false;
    viewer.renderHtml('viewer');
  }
}

export default ReportingReportViewerController;
