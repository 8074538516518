module.exports = {
  entity: 'price-list-items',
  path: 'price-list-items',
  name: {
    en: 'Price list details',
    sl_SI: 'Skupine postavk na ceniku'
  },
  networkModel: {
    entity: 'price-list-items',
    read: 'read'
  },
  scope: 'tis_be:price-list-items',

  dialog: {
    title: {
      en: 'Select price list item',
      sl_SI: 'Izberite postavko na ceniku'
    },
    listInfo: {
      en: 'Select price list item  from the list below',
      sl_SI: 'Izberite postavko na ceniku s spodnjega seznama'
    }
  }
}
