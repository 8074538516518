JobResultsController.$inject = [
  '$stateParams',
  'AlertingService',
  'gettext',
  'gettextCatalog',
  'TranslationService',
  'MetadataService',
  'JobModel'
];

function JobResultsController(
  $stateParams,
  AlertingService,
  gettext,
  gettextCatalog,
  TranslationService,
  MetadataService,
  JobModel
) {
  var vm = this;
  var jobId = $stateParams.id;
  vm.job = null;
  init();

  function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    JobModel.custom
      .readJob({
        id: jobId
      })
      .then(
        function(res) {
          var job = res.data;
          if (typeof job.completed === 'undefined') {
            job.completed = 'Not yet completed';
          }
          vm.job = job;
          vm.datasource = job.tangAgentConnectionConfig;
          vm.jobType = TranslationService.GetCollection(
            'codelists.jobTypes'
          ).find(function(item) {
            return item.id === job.type;
          });
          vm.jobStatus = TranslationService.GetCollection(
            'codelists.jobStatuses'
          ).find(function(item) {
            return item.id === job.status;
          });
          vm.tagsHeader = {
            title: gettextCatalog.getString('Tag explorer')
          };
          vm.device = job.externalDatasource;
          vm.tags = job.tags;
          vm.headerData = constructHeader(job);
          MetadataService.Loading(false);
          MetadataService.ChangeMetadata(job.name, job.description);
          vm.loading = false;
        },
        function(err) {
          vm.loading = false;
          MetadataService.Loading(false);
          AlertingService.Error(err);
        }
      );
  }
  function constructHeader(job) {
    var deviceProperties = [];
    var actions = [];

    var properties = [
      {
        title: gettext('Status'),
        value: vm.jobStatus
          ? vm.jobStatus.name
          : gettextCatalog.getString('None'),
        type: 'simple'
      },
      {
        title: gettext('Type'),
        value: vm.jobType.name,
        type: 'simple'
      },
      {
        title: gettext('Started'),
        value: job.started,
        type: 'date'
      },
      {
        title: gettext('Completed'),
        value: job.completed || 'Never',
        type: 'date'
      }
    ];

    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];

    var propertySections = [
      {
        title: gettext('properties'),
        properties: properties
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'jobs',
        target: vm.job._id,
        type: 'userAccess'
      }
    ];

    if (job.externalDatasource) {
      deviceProperties.push({
        type: 'link',
        title: gettext('Name'),
        linkTitle: job.externalDatasource.name,
        state: 'external-datasources-external-datasources-view',
        param: 'id',
        paramValue: job.externalDatasource._id
      });

      if (
        typeof job.externalDatasource.externalDatasourceType == 'object' &&
        job.externalDatasource.externalDatasourceType != null
      ) {
        deviceProperties.push({
          title: gettext('External datasource Type'),
          value: `${job.externalDatasource.externalDatasourceType.name} (${job.externalDatasource.externalDatasourceType.description})`,
          type: 'simple'
        });
      }
      deviceProperties.push({
        type: 'link',
        title: gettext('Tango Agent Connection Config'),
        linkTitle: job.tangoAgentConnectionConfig.name,
        state: 'external-datasources-tango-agent-connection-config-view',
        param: 'id',
        paramValue: job.tangoAgentConnectionConfig._id
      });

      propertySections.push({
        title: gettext('external datasource'),
        properties: deviceProperties
      });
    }
    return {
      metadata: {
        title: job.name,
        definition: gettext('External datasource job')
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}

export default JobResultsController;
