module.exports = {
  entity: 'schedulers',
  path: 'schedulers',
  entityId: 185,
  storeKey: 'schedulers',
  name: {
    en: 'Scheduler',
    sl_SI: 'Urnik'
  },
  networkModel: {
    entity: 'schedulers',
    read: 'read'
  },
  scope: 'tis_be:schedulers',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select scheduler',
      sl_SI: 'Izberite urnik'
    },
    listInfo: {
      en: 'Select scheduler from the list below',
      sl_SI: 'Izberite urnik s spodnjega seznama'
    }
  }
}
