NewGroupFormController.$inject = [
  'gettext',
  '$state',
  'AlertingService',
  'createOrUpdateService',
  'MetadataService',
  '$smartAuth',
  'SfeHeader',
  'GroupModel',
  'CrudToastFactory',
  'group',
  '$timeout'
];

function NewGroupFormController(
  gettext,
  $state,
  AlertingService,
  createOrUpdateService,
  MetadataService,
  $smartAuth,
  SfeHeader,
  GroupModel,
  CrudToastFactory,
  group,
  $timeout
) {
  const vm = this;
  vm.createOrUpdate = createOrUpdate;

  init();

  function init() {
    vm.dataConfig = {};
    vm.groupId = $state.params.id;
    const data = [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        configuration: {
          query: {
            entity: 'groups',
            method: 'read'
          },
          entity: 'groups',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select parent')
          },
          floatingLabel: gettext('Select parent'),
          searchParamName: 'name',
          required: false
        },
        componentType: 'autocompleteDialog',
        edit: vm.groupId,
        name: 'parent'
      }
    ];

    if (vm.groupId) {
      vm.editMode = true;
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit group'),
        'users-groups-view',
        { id: vm.groupId }
      );
      MetadataService.Loading(true);
      vm.dataConfig.dataObj = {
        name: group.name,
        parent: group.parent
      };
      $timeout(() => {
        MetadataService.Loading(false);
        MetadataService.ChangeMetadata('Edit ' + group.name, null);
      });
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New group'),
        'users-groups-list'
      );
      vm.dataConfig.dataObj = {
        name: ''
      };
    }

    vm.dataConfig.data = data;

    vm.dataConfig.edit = !!vm.groupId;
    vm.dataConfig.data = data;
    vm.dataConfig.action = {
      title: vm.groupId ? gettext('Update') : gettext('Create'),
      ctrlFn: true,
      fn: vm.createOrUpdate
    };
  }

  function getUserProfile(apiObj, callback) {
    $smartAuth.profile().then(
      function(user) {
        if (user.realm) {
          apiObj.realm = user.realm;
          callback(null, apiObj);
        } else {
          callback(gettext('Couldn\'t fetch organization'));
        }
      },
      function(err) {
        callback(err);
      }
    );
  }

  function createOrUpdate(item) {
    var obj = {
      name: item.name,
      parent: item.parent ? item.parent._id || null : null
    };
    var message = vm.editMode ? 'update' : 'create';
    var waterfall = [async.apply(getUserProfile, obj), createOrUpdateGroup];

    createOrUpdateService.simpleWaterfall(waterfall).then(
      function(res) {
        var id = $state.params.id;
        if (res && res._id) {
          id = res._id;
        }
        var redirectConfiguration = {
          state: 'users-groups-view',
          paramName: 'id',
          paramValue: id
        };
        CrudToastFactory.toast(message, redirectConfiguration);
        vm.sendingRequest = false;
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function createOrUpdateGroup(apiObj, callback) {
    var apiCall;
    if (vm.groupId) {
      apiCall = GroupModel.update(
        {
          id: vm.groupId
        },
        apiObj
      );
    } else {
      apiCall = GroupModel.create(apiObj);
    }
    apiCall.then(
      function(res) {
        callback(null, res.data);
      },
      function(err) {
        callback(err);
      }
    );
  }
}

export default NewGroupFormController;
