module.exports = {
  entity: 'asset-groups',
  path: 'asset-groups',
  name: {
    en: 'Asset group',
    sl_SI: 'Skupina sredstev'
  },
  networkModel: {
    entity: 'asset-groups',
    read: 'read'
  },
  selectedParamName: 'id',
  scope: 'tis_be:asset-groups',

  dialog: {
    title: {
      en: 'Select asset group',
      sl_SI: 'Izberite skupino sredstev'
    },
    listInfo: {
      en: 'Select asset group from the list below',
      sl_SI: 'Izberite skupino sredstev s spodnjega seznama'
    }
  }
}
