import './sfe-property-list.scss';
import template from './sfe-property-list.component.html';
/**
 * @ngdoc component
 * @name common.sfePropertyList
 * @description Component that is used to show basic data on item views.
 * @param {Array} properties - sfe-property objects
 * @param {string} propertyListTitle - sfe-property object title
 * @param {Boolean} canClose - if component can be closed
 * @param {Boolean} inCard - if content is displayed in card
 * @param {Array} actions - list of configurations for actions
 * @example
 * <sfe-property-list
 * properties="vm.data"
 * property-list-title="vm.headerData"
 * canClose="true"
 * inCard="true"
 * actions="vm.actionData"
 * ></sfe-property-list>
 */

export default {
  template,
  restrict: 'E',
  bindings: {
    properties: '<',
    propertyListTitle: '<?',
    canClose: '<',
    inCard: '<',
    actions: '<?',
    startClosed: '<?'
  },
  controller: sfePropertyListController,
  controllerAs: 'vm',
  bindToController: true
};

sfePropertyListController.$inject = [];

function sfePropertyListController() {
  const vm = this;
  vm.formattedActions = [];
  vm.showProperties = true;
  vm.$onChanges = changes => {
    const closeButton = {
      icon: {
        type: 2,
        name: 'keyboard_arrow_down'
      },
      fn: vm.toggleProperty,
      color: 'grey'
    };
    if (
      changes.properties != null &&
      changes.properties.currentValue != null &&
      !Array.isArray(changes.properties.currentValue)
    ) {
      vm.invalidConfiguration = true;
    }
    //Check if action exists
    if (
      changes.actions != null &&
      Array.isArray(vm.actions) &&
      vm.actions.length > 0
    ) {
      vm.formattedActions = [...vm.actions];
      //check if action can close should be added
      if (vm.canClose) {
        vm.formattedActions.push(closeButton);
        if (vm.startClosed) {
          vm.showProperties = false;
        }
      }
    } else if (changes.canClose != null && vm.canClose) {
      vm.formattedActions.push(closeButton);
      if (vm.startClosed) {
        vm.showProperties = false;
      }
    }
  };
  /**
   * @description show/hide property list content (properties)
   * @function
   */
  vm.toggleProperty = () => {
    vm.showProperties = !vm.showProperties;
    const lastItem = vm.formattedActions.length - 1;
    if (vm.showProperties) {
      vm.formattedActions[lastItem].icon = 'keyboard_arrow_down';
    } else {
      vm.formattedActions[lastItem].icon = 'keyboard_arrow_up';
    }
  };
}
