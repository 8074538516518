export default function GenerateUISrefLinkService() {
  /**
   * @description Returns the state redirect string.
   * @param {Object} linkConfigObject Object containing data for redirect string.
   * @function
   * @return {string} Redirect state.
   */
  function generateUrl(linkConfigObject) {
    var params = linkConfigObject.params;
    var resultParams = {};
    var resultParamsString = '';
    if (params && params.length > 0) {
      params.forEach(function(param) {
        resultParams[param.queryParam] = param.value;
      });
      resultParamsString = '(' + JSON.stringify(resultParams) + ')';
    }
    return linkConfigObject.state + resultParamsString;
  }

  return {
    generateUrl
  };
}
