ListFormService.$inject = ['gettext'];
/**
 * @ngdoc service
 * @name common.ListFormService
 * @description Helper service to simplify creating list dialog create/update forms..
 * @property {function} generateForm
 */
export default function ListFormService(gettext) {
  /**
   * @memberof ListFormService.generateForm
   * @description generates form configuration according to list configuration.
   * @param {array} list array of strings with the names of needed configurations
   * @param {bool} multilanguage multilanguage indicator
   * @param {array} extraList array of additional list configurations
   * @return {Array}
   */
  function generateForm(list, multilanguage, extraList) {
    var form = [];
    var formItems = [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: multilanguage ? 'multilanguage' : 'textField',
        required: true
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: multilanguage ? 'multilanguage' : 'textArea',
        maxlength: 500,
        required: false
      },
      {
        placeholder: gettext('Collection friendly name'),
        name: 'collectionFriendlyName',
        componentType: multilanguage ? 'multilanguage' : 'textArea',
        maxlength: 100,
        required: true
      },
      {
        componentType: 'checkBox',
        label: gettext('System'),
        name: 'isSystem'
      },
      {
        componentType: 'checkBox',
        label: gettext('Periodic'),
        name: 'periodic'
      },
      {
        placeholder: 'Order',
        name: 'order',
        componentType: 'textField',
        type: 'numerical',
        required: true,
        min: 0
      },
      {
        placeholder: 'Code',
        name: 'code',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('External Code'),
        name: 'externalCode',
        componentType: 'textField',
        maxLength: 30,
        required: false
      },
      {
        componentType: 'checkBox',
        label: gettext('Enabled'),
        name: 'enabled'
      },
      {
        componentType: 'singleDate',
        name: 'singleDate',
        dateName: 'date',
        idDate: 'unique',
        noTimeInput: true,
        required: true,
        type: 'date'
      },
      {
        componentType: 'multiSelect',
        config: {
          required: false,
          label: gettext('Document Classification'),
          placeholder: gettext('Select a document classification'),
          refreshFn: {
            entity: 'document-classifications',
            method: 'read'
          },
          searchParams: ['filter'],
          edit: true
        },
        name: 'documentClassification'
      },
      {
        componentType: 'checkBoxesLinear',
        networkModel: {
          entity: 'system-tags',
          method: 'read'
        },
        name: 'systemTags',
        title: gettext('System Tags'),
        compareFn: function(item1, item2) {
          return item1._id === item2._id;
        }
      },
      {
        componentType: 'autocompleteDialog',
        edit: true,
        configuration: {
          required: true,
          codelist: 'alarmCategories',
          entity: 'entity',
          noDialog: true,
          floatingLabel: gettext('Alarm Category'),
          searchParamName: 'name',
          selectedParam: 'id'
        },
        name: 'alarmCategory'
      }
    ];

    list.forEach(function(itemName) {
      form.push(
        formItems.filter(function(formItem) {
          return formItem.name === itemName;
        })[0]
      );
    });

    if (extraList) {
      form = form.concat(extraList);
    }
    return form;
  }
  /**
   * @description returns a new object that has only fields in filed names array.
   * @function
   * @param {Object} object original object
   * @param {Array} fieldNames allowed fielded names
   * @return {Object}
   */
  function generateBodyObject(obj, fieldNames) {
    return fieldNames.reduce((result, fieldName) => {
      return {
        ...result,
        [fieldName]: obj[fieldName]
      };
    }, {});
  }

  return {
    generateForm,
    generateBodyObject
  };
}
