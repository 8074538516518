import { IANAZone } from 'luxon';
import './tango-data-x.scss';
import template from './tango-data-x.component.html';
/**
 * @ngdoc component
 * @name common.tangoDataX
 * @description Displays tango data x
 * @param {Object} entity - Data for the map
 * @param {Object} entityId - Data for the map
 * @param {Object} entityId - Data for the map
 * @example
 * {
 *  mode: { chart: true, grid: false },
 *  alternativeMode: { chart: false, grid: true }
 * }
 */

export default {
  restrict: 'E',
  template,
  bindings: {
    timeseriesId: '<',
    timeseriesGroupId: '<',
    parameters: '<',
    api: '=?',
    uniqueCacheId: '<?',
    cacheInvalidationTime: '<?',
    refresher: '<'
  },
  controller: tangoDataX,
  controllerAs: 'vm',
  bindToController: true
};

tangoDataX.$inject = [
  '$timeout',
  'AlertingService',
  'TangoTimeSeriesDataXHelperService',
  'TimeSeriesProcessingValuesModel',
  'TranslationService',
  'FilterDialog',
  'GetTimeSeriesProcessingByTimestampService',
  'CachingParams',
  'Refreshing',
  '$scope',
  'SfeDataXTranslations',
  'gettextCatalog',
  'SfeDataXMinTickInterval'
];

function tangoDataX(
  $timeout,
  AlertingService,
  TangoTimeSeriesDataXHelperService,
  TimeSeriesProcessingValuesModel,
  TranslationService,
  FilterDialog,
  GetTimeSeriesProcessingByTimestampService,
  CachingParams,
  Refreshing,
  $scope,
  SfeDataXTranslations,
  gettextCatalog,
  SfeDataXMinTickInterval
) {
  const vm = this;
  let defaultValuesLimit = 100;
  let refresherId, refresherFnId;
  vm.apiDataX = {};
  vm.configurationDataX = null;
  vm.sizes = {
    chart: 300,
    grid: 300
  };
  vm.mode = {
    chart: true,
    grid: true
  };
  vm.alternativeMode = null;

  vm.$onInit = () => {
    vm.api = vm.apiDataX;
    if (vm.refresher) {
      ({ refresherId, refresherFnId } = vm.refresher(() => {
        if (!vm.filtersAreNotValid && !vm.graphDataIsLoading) {
          vm.apiDataX.refresh();
        }
      }));
    }
  };

  vm.$onChanges = changes => {
    const timeSeriesChanged = changes.timeseriesId && vm.timeseriesId != null;
    const timeSeriesGroupChanged =
      changes.timeseriesGroupId && vm.timeseriesGroupId != null;
    const sandboxInputChanged = changes.sandboxModel && vm.sandboxModel != null;
    if (timeSeriesChanged || timeSeriesGroupChanged || sandboxInputChanged) {
      build();
    }
  };

  $scope.$on('$destroy', function() {
    if (refresherId) {
      Refreshing.removeFn(refresherId, refresherFnId);
    }
  });

  const build = async () => {
    if (vm.apiDataX && vm.apiDataX.destroy) {
      vm.apiDataX.destroy();
      vm.apiData;
    }
    vm.configurationDataX = null;
    vm.errorMessage = false;
    vm.loading = true;
    await $timeout();
    try {
      let {
        series,
        scheduleClassifications,
        dataVisualizationConfig,
        timeSeries
      } = await TangoTimeSeriesDataXHelperService.get({
        timeseriesId: vm.timeseriesId,
        timeseriesGroupId: vm.timeseriesGroupId
      });
      let timeZone = series
        .map(item => item.timeZone)
        .filter((value, index, items) => items.indexOf(value) == index);
      if (timeZone.length == 1 && timeZone[0] != null) {
        timeZone = timeZone[0];
      } else {
        timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      defaultValuesLimit =
        Array.isArray(scheduleClassifications) &&
        scheduleClassifications.length > 0
          ? 100
          : 3000;
      vm.configurationDataX = buildDataXConfiguration({
        series,
        scheduleClassifications,
        timeZone,
        dataVisualizationConfig,
        timeSeries
      });
    } catch (err) {
      AlertingService.Error(err);
      vm.errorMessage = gettextCatalog.getString(
        'Error while fetching chart data'
      );
    }
    vm.loading = false;
    $scope.$evalAsync();
  };

  vm.apiDataX.originalBuild = build;

  const buildDataXConfiguration = ({
    series,
    scheduleClassifications,
    timeZone,
    dataVisualizationConfig,
    timeSeries
  }) => {
    const modesAndSizes = chartModesAndSizes(vm.parameters);
    let sortSettings = {};
    //TIME SERIES GROUP HAS DATA VISUALIZATION CONFIG THAT IS USED TO SET SORT BY SUM PROPS
    if (dataVisualizationConfig) {
      let sortType = 1; //NONE
      if (dataVisualizationConfig.sortedBySumOfValues) {
        sortType = 10; //SORT BY SUM
      }
      sortSettings = TangoTimeSeriesDataXHelperService.getChartSortBySumSettings(
        { sortType, chartType: dataVisualizationConfig.defaultChartType }
      );
    }
    // prepare series
    series = processChartType(series, axis);
    const axis = TangoTimeSeriesDataXHelperService.processAxes(
      { series },
      sortSettings
    );
    const processedSeries = processSeries(series, axis, false);
    const configuration = {
      axis,
      series: processedSeries,
      mode: modesAndSizes.modes.mode,
      alternativeMode: modesAndSizes.modes.alternativeMode,
      timeZone: timeZone,
      chart: {
        legend: series.length > 1,
        legendAlign: 'center',
        markers: true,
        markerPlotOptions: {
          lineColor: null, // inherit from series
          lineWidth: 2
        },
        dataLabels: false,
        axisYTitle: true,
        axisXTitle: true,
        height: modesAndSizes.sizes.chart
      },
      grid: {
        height: modesAndSizes.sizes.grid,
        extraColumns: [],
        actions: [],
        seriesAction: {
          icon: 'info_outline',
          fn: params => {
            let selectedSeries;
            let timeSeriesData = timeSeries;
            if (vm.timeseriesGroupId != null) {
              const id = params.colDef.field;
              selectedSeries = series.find(item => item.id === id);
              timeSeriesData = selectedSeries;
            }
            GetTimeSeriesProcessingByTimestampService.openExtendedData(
              params,
              timeSeriesData
            );
          }
        }
      },
      doNotMarkLastValue: vm.timeseriesGroupId ? true : false,
      gauge: {
        height: modesAndSizes.sizes.gauge
      },
      // katere stvari se na samem highchartu prikazujejo
      translations: SfeDataXTranslations.get(),
      datasets: Array.isArray(scheduleClassifications)
        ? processDatasets(
          scheduleClassifications,
          processedSeries,
          series,
          axis.y
        )
        : [],
      callbacks: {
        export:
          vm.timeseriesId != null
            ? TangoTimeSeriesDataXHelperService.getExportDataFunction(
              vm.apiDataX
            )
            : undefined,
        filter: () => {
          FilterDialog.open(vm.apiDataX);
        },
        report: api => {
          if (vm.timeseriesId != null) {
            TangoTimeSeriesDataXHelperService.generateTimeSeriesReport(api, [
              vm.timeseriesId
            ]);
          } else if (vm.timeseriesGroupId) {
            let model = api.getModel();
            if (
              model != null &&
              model.activeDataset != null &&
              Array.isArray(model.activeDataset.series) &&
              model.activeDataset.series.length > 0
            ) {
              let timeSeriesIds = model.activeDataset.series.map(
                item => item.id
              );
              TangoTimeSeriesDataXHelperService.generateTimeSeriesReport(
                api,
                timeSeriesIds
              );
            } else {
              AlertingService.Error(
                'There is no time series to generate report'
              );
            }
          } else {
            AlertingService.Error('There is no time series to generate report');
          }
        }
      }
    };

    if (vm.timeseriesId) {
      let defaultFilter =
        Array.isArray(series) && series[0] != null ? series[0].filter : {};

      configuration.filter = () =>
        TangoTimeSeriesDataXHelperService.constructQueryFilter(
          defaultFilter || {}
        );
    }

    return configuration;
  };

  const processDatasets = (datasets, processedSeries, inputSeries, yAxes) => {
    // process special series that use percentage stacking
    const axesSettings = {};
    datasets.forEach(dataset => {
      const datasetId = dataset.id;
      const datasetSeries = dataset.series;
      processedSeries.forEach(({ stacking, y }) => {
        const { suffix } = yAxes[y];
        if (axesSettings[datasetId] == null) {
          axesSettings[datasetId] = {
            [y]: { suffix }
          };
        }
        if (stacking) {
          axesSettings[datasetId][y] = {
            suffix: suffix
          };
        }
      });
      datasetSeries.forEach(({ stacking, id: setId }) => {
        const datasetSet = processedSeries.find(({ id }) => setId == id);
        if (stacking != null) {
          axesSettings[datasetId][datasetSet.y] = {
            ...axesSettings[datasetId][datasetSet.y],
            stacking
          };
        }
      });
      const datasetChartSeries = Object.values(
        datasetSeries.map(({ id: setId }) =>
          inputSeries.find(({ id }) => setId == id)
        )
      );
      const xFormatter =
        datasetChartSeries != null && datasetChartSeries.length > 0
          ? TangoTimeSeriesDataXHelperService.getXAxisMinTimeIntervalsAndFormatter(
            { series: datasetChartSeries }
          ).formatter
          : undefined;
      axesSettings[datasetId].xFormatter = xFormatter;
    });

    return datasets.map(
      ({ id, abbreviation, selected, filter, series, minTickInterval }) => {
        const axisSettings = axesSettings[id];
        return {
          id,
          name,
          selected,
          abbreviation,
          filter,
          series,
          axis: {
            y: Object.entries(axisSettings).map(([index, axisSetting]) => {
              return { index, ...axisSetting };
            }),
            x: {
              xFormatter: axisSettings.xFormatter,
              minTickInterval
            }
          }
        };
      }
    );
  };

  const processChartType = series =>
    series.map(set => {
      let type;
      const chartType = set.chartType;
      if (vm.parameters && vm.parameters.singleTimeseriesChartType) {
        const hctype = TranslationService.GetCollectionById(
          'codelists.chartTypes',
          vm.parameters.singleTimeseriesChartType
        );
        type = hctype ? hctype.highChartCode : 'line';
      } else {
        type = chartType.highChartCode || 'line';
      }
      return {
        ...set,
        chartTypeCode: type,
        chartType
      };
    });

  /**
   * @description Processes series for dataX configuration.
   * @function
   * @param {array} series
   * @param {series} axis
   * @return {array}
   */
  const processSeries = (series, { y }, transformToUTC) => {
    return series.map(
      ({
        id,
        name,
        nonNumerical,
        precision,
        color,
        chartType,
        timeZone,
        dataInterpretationType,
        dataScheduler,
        dataSamplingType,
        timeSeriesConfiguration,
        chartTypeCode
      }) => {
        let stacking = {};
        const isColumnChart = [7, 10, 11].includes(chartType.id);
        const isBarChart = [9].includes(chartType.id);
        const isAreaChart = [4, 12, 14].includes(chartType.id);
        const isAreaSpline = chartType.id == 5;

        const isRegularAggregateTimeset =
          dataInterpretationType == 300 && dataSamplingType == 100;
        // dont include intervals lower than daily
        const scheduleSmallerThanDaily =
          dataScheduler != null &&
          [1, 6, 7, 9].indexOf(dataScheduler.scheduleClassification) >= 0;
        const minTickInterval =
          dataScheduler != null
            ? SfeDataXMinTickInterval.getTickInterval(
              dataScheduler.scheduleClassification
            )
            : undefined;
        const yAxisIndex = y.reduce((currentIndex, axis, axisIndex) => {
          return axis.seriesIncluded.indexOf(id) >= 0
            ? axisIndex
            : currentIndex;
        }, 0);
        const {
          xFormatter
        } = TangoTimeSeriesDataXHelperService.getSeriesFormatter({
          isRegularAggregateTimeset,
          dataScheduler
        });

        let chartColor = color;
        if (
          vm.parameters &&
          vm.parameters.singleTimeseriesColor &&
          vm.parameters.displayType !== 4 &&
          vm.parameters.displayType !== 5
        ) {
          chartColor = vm.parameters.singleTimeseriesColor;
        }
        let chartCode = 'column';
        if (vm.parameters && vm.parameters.singleTimeseriesChartType) {
          const hctype = TranslationService.GetCollectionById(
            'codelists.chartTypes',
            vm.parameters.singleTimeseriesChartType
          );
          if (hctype != null) {
            chartCode = hctype.highChartCode;
            if (hctype.stacking) {
              stacking = {
                stacking: hctype.stacking
              };
            }
          }
        } else {
          if (chartType.stacking != null) {
            stacking = {
              stacking: chartType.stacking
            };
          }
          chartCode = chartType.highChartCode;
        }
        let params = JSON.stringify({ id });
        let step = undefined;
        if (dataInterpretationType === 200) {
          step = 'left';
        } else if (
          dataInterpretationType === 300 &&
          chartCode !== 'areaspline' &&
          chartCode !== 'spline'
        ) {
          step = 'right';
        }
        return {
          id,
          name,
          stateName: `data-time-series-view(${params})`.replace(/"/g, '\''),
          minTickInterval,
          xFormatter: series.length > 0 ? xFormatter : undefined,
          formatXWithRegardsToValidity:
            isRegularAggregateTimeset && !scheduleSmallerThanDaily,
          shiftValuesToMinInterval:
            isRegularAggregateTimeset &&
            !scheduleSmallerThanDaily &&
            (isColumnChart || isBarChart),
          insertMinIntervalNullGaps:
            isRegularAggregateTimeset &&
            !scheduleSmallerThanDaily &&
            isAreaChart,
          query: getValues({
            id,
            timeZone,
            transformToUTC,
            isAreaChart,
            isAreaSpline,
            dataInterpretationType,
            isRegularAggregateTimeset,
            scheduleSmallerThanDaily,
            chartTypeCode,
            color,
            timeSeriesMaintenanceFlows: timeSeriesConfiguration.filter(
              item => item.flowRef === 500 || item.flowRef === 510
            )
          }),
          x: 0,
          y: yAxisIndex,
          decimalPrecision: precision,
          color: chartColor,
          type: chartCode,
          nonNumerical,
          timeSeriesConfiguration,
          connectNulls: stacking.stacking != null ? true : false,
          stack: yAxisIndex,
          ...stacking,
          step
        };
      }
    );
  };

  /**
   * @description returns function that returns chart values.
   * @function
   * @param {string} entityId
   * @param {number} dataType codelist id of time series data type
   * @return {function}
   */
  function getValues({
    id,
    timeZone,
    transformToUTC,
    isAreaChart,
    isAreaSpline,
    dataInterpretationType,
    isRegularAggregateTimeset,
    scheduleSmallerThanDaily,
    timeSeriesMaintenanceFlows = [],
    chartTypeCode
  }) {
    /**
     * @description returns sorted values.
     * @function
     * @param {Object} filter filter object
     * @param {Boolean} manualRefresh indicate s when chart refresh is triggered manually
     * @return {Promise} Array
     */
    return async (filter, manualRefresh) => {
      let apiObject = {};
      let offset = 0;

      apiObject.from =
        filter.from == null || filter.from === '' ? null : filter.from;

      apiObject.to = filter.to == null || filter.to === '' ? null : filter.to;
      apiObject.limit = filter.limit || defaultValuesLimit;
      /* TODO CHANGE POINT_IN_TIME FIELD NAMES*/
      if (filter.pointInTime) {
        apiObject.pointInTime = filter.pointInTime;
      }

      // eslint-disable-next-line no-unused-vars
      const invalidationTime = manualRefresh ? 0 : vm.cacheInvalidationTime;

      apiObject.view = 'simple';
      apiObject.timeSeriesId = id;
      try {
        //CREATE CACHE PARAMS
        let cachedParams = CachingParams.CheckParams(
          'time-series-values' +
            (vm.uniqueCacheId ? vm.uniqueCacheId + '/' : '') +
            '/' +
            id,
          apiObject,
          invalidationTime
        );
        const IANAtimezone = new IANAZone(timeZone);
        const { data: values } = await TimeSeriesProcessingValuesModel.read(
          cachedParams,
          invalidationTime
        );
        const maintenanceFlows = TangoTimeSeriesDataXHelperService.getMaintenanceIntervals(
          {
            values,
            maintenanceValues: timeSeriesMaintenanceFlows,
            isRegularAggregateTimeset
          }
        );
        let sortedValues = values
          .concat(maintenanceFlows)
          .sort((valueA, valueB) => {
            return valueA.validAt - valueB.validAt;
          });

        //When we transform date to utc (sandbox) we must determine last active value before transformation
        let firstFutureItemIndex = -1;
        if (transformToUTC && sortedValues.length > 0) {
          const currentTime = new Date().getTime();
          if (sortedValues[sortedValues.length - 1].validAt < currentTime) {
            firstFutureItemIndex = sortedValues.length - 1;
          } else {
            firstFutureItemIndex =
              sortedValues.findIndex(item => item.validAt > currentTime) - 1;
            sortedValues[firstFutureItemIndex];
          }
        }

        const applyAggregateSamplingShift =
          isRegularAggregateTimeset &&
          !scheduleSmallerThanDaily &&
          !isAreaChart;
        sortedValues = sortedValues
          .map(function(tsValue, index) {
            if (
              transformToUTC &&
              timeZone &&
              tsValue.validAt &&
              IANAtimezone.valid
            ) {
              offset = IANAtimezone.offset(tsValue.validAt);
            } else {
              offset = 0;
            }

            const {
              value,
              validAt,
              validFrom,
              creationType,
              showOnlyInChart
            } = tsValue;

            let x = applyAggregateSamplingShift
              ? validFrom + (validAt - validFrom) / 2
              : validAt;
            x = new Date(x + offset * 60000);

            let lastItemStyleSet = {};
            if (index === firstFutureItemIndex) {
              lastItemStyleSet = {
                lastActive: true
              };
            }
            const isInterpolated = creationType === 210 || creationType === 220;
            const marker = isInterpolated
              ? {
                fillColor: '#FFFFFF'
              }
              : {};
            const invertColumnColors =
              isInterpolated &&
              typeof chartTypeCode === 'string' &&
              TangoTimeSeriesDataXHelperService.isTypeColumnOrBar(chartTypeCode)
                ? {
                  invertColumnColors: true
                }
                : {};
            return {
              x,
              y: value,
              validAt,
              validFrom,
              showOnlyInChart,
              marker,
              ...invertColumnColors,
              ...lastItemStyleSet
            };
          })
          .sort(function(valueA, valueB) {
            return valueA.x - valueB.x;
          });
        if (
          isRegularAggregateTimeset &&
          values.length >= 0 &&
          (isAreaChart || (isAreaSpline && dataInterpretationType != 300))
        ) {
          sortedValues.unshift({
            ...sortedValues[0],
            x: new Date(sortedValues[0].validFrom),
            showOnlyInChart: true,
            marker: {
              enabled: false
            }
          });
        }
        return sortedValues;
      } catch (err) {
        AlertingService.Error(err);
        return [];
      }
    };
  }

  /**
   * @description returns preconfigured sizes for chart and grid.
   * @function
   * @param {object} parameters
   * @return {object}
   */
  const chartModesAndSizes = parameters => {
    const modes = {
      mode: {
        chart: true,
        grid: true
      },
      alternativeMode: null
    };
    const sizes = {
      grid: 300,
      chart: 300
    };
    if (parameters != null) {
      const { size, displayType, chartAndTableDisplayRatio } = parameters;
      switch (displayType) {
      case 1:
      case 2:
        // just chart or just grid
        switch (size) {
        case 1: // small
        case 2: // medium
        case 4: // fullWidth
          sizes.grid = 331;
          sizes.chart = 331;
          break;
        case 3: // large
        case 5: // fullWidthDoubleHeight
        case 6: // singleWidthDoubleHeight
          sizes.grid = 751;
          sizes.chart = 751;
        }
        if (displayType == 1) {
          // chart first
          modes.mode = {
            chart: true,
            grid: false
          };
          modes.alternativeMode = {
            chart: false,
            grid: true
          };
        } else {
          // grid first
          modes.mode = {
            chart: false,
            grid: true
          };
          modes.alternativeMode = {
            chart: true,
            grid: false
          };
        }
        break;
      case 3:
        switch (chartAndTableDisplayRatio) {
        case 2: //CHART 66%
          sizes.grid = 253;
          sizes.chart = 492;
          break;
        case 3: //table 66%
          sizes.grid = 492;
          sizes.chart = 253;
          break;
        default:
          //50% 50%
          sizes.grid = 373;
          sizes.chart = 372;
        }
        break;
      case 4:
        modes.mode = {
          gauge: true,
          grid: false
        };
        modes.alternativeMode = {
          gauge: false,
          grid: true
        };
        sizes.grid = 331;
        sizes.gauge = 331;
        break;
      case 5:
        modes.mode = {
          gauge: true,
          grid: true
        };
        sizes.grid = 445;
        sizes.gauge = 331;
        switch (chartAndTableDisplayRatio) {
        case 2: //CHART 66%
          sizes.grid = 253;
          sizes.gauge = 492;
          break;
        case 3: //table 66%
          sizes.grid = 492;
          sizes.gauge = 253;
          break;
        default:
          //50% 50%
          sizes.grid = 373;
          sizes.gauge = 372;
        }
        break;
      default:
        switch (size) {
        case 1: // small
        case 2: // medium
        case 4: // fullWidth
          sizes.grid = 165;
          sizes.chart = 165;
          break;
        case 3: // large
        case 5: // fullWidthDoubleHeight
        case 6: // singleWidthDoubleHeight
          sizes.grid = 373;
          sizes.chart = 372;
          break;
        }
      }
    }
    return { sizes, modes };
  };
}
