import './sfe-form-2-flow-variables-mapping-functions.scss';
import template from './sfe-form-2-flow-variables-mapping-functions.component.html';

/**
* @ngdoc component
* @name common.sfeForm2FlowVariable
* @description Adds Timeseries and dynamic attributes variables .
* @param {Object} options configurations object
*     @param {Boolean} showDynamicAttributes indicates if dynamic attributes variable selector wil be shown
* @param {function} remove remove parameter function
* @example
* <sfe-form-2-flow-variables-mapping-functions
* options="vm.options"
  ng-change="vm.config.onChange()"
  ng-required="vm.config.required"
  ng-model="vm.value"
  name="{{vm.config.id}}"
  remove="remove()"
  sfe-form-2-validation="vm.config.validators"  
* ></sfe-form-2-flow-variables-mapping-functions>
*/

export default {
  template,
  bindings: {
    options: '<',
    onChange: '&',
    variableName: '<',
    hideFilters: '<',
    remove: '&'
  },
  require: {
    model: 'ngModel',
    form: '^form'
  },
  controller: sfeForm2FlowVariable,
  controllerAs: 'vm',
  bindToController: true
};

sfeForm2FlowVariable.$inject = [
  '$scope',
  'TranslationService',
  'TimeSeriesVariableService',
  'gettextCatalog'
];

function sfeForm2FlowVariable(
  $scope,
  TranslationService,
  TimeSeriesVariableService,
  gettextCatalog
) {
  let vm = this;

  const valueField = 'id';
  const crawlerParams = searchTerm => ({
    param: 'name',
    searchTerm,
    type: 'string'
  });
  const display = item => ({
    text: item != null ? item.name : gettextCatalog.getString('Unknown')
  });

  $scope.$on('$destroy', () => {
    vm = null;
    stopWatcher();
  });
  /**
   * @description watches for model data to come and
   * if it is set to a string or a number triggers
   * function that fetches data.
   * @function
   */
  let stopWatcher = $scope.$watch(function() {
    if (vm.model != null) {
      return vm.model.$modelValue;
    }
  }, initiateItems);
  /**
   * @description applies model values to scope.
   * @function
   * @param {Object} model
   */
  function initiateItems(model) {
    if (model != null) {
      vm.mappingFunction = model.mappingFunction || null;
      vm.mappingFunctionTypeArgument = model.mappingFunctionTypeArgument;
      vm.mappingFunctionNArgument = model.mappingFunctionNArgument;
      vm.streamArchiveProperty = model.streamArchiveProperty || null;
      vm.exceptionHandlerActionTypeArgument =
        model.exceptionHandlerActionTypeArgument;
      vm.dateTimeFilterType = model.dateTimeFilterType || null;
      vm.timeUnit = model.timeUnit;
      vm.offsetValue = model.offsetValue;
      vm.includeValueOnTheEdge =
        model.includeValueOnTheEdge != null
          ? model.includeValueOnTheEdge
          : true;
      vm.fromDateTimeMapper = model.fromDateTimeMapper;
      vm.toDateTimeMapper = model.toDateTimeMapper;
    }
  }

  /**
   * @description when option binding changes update mapping functions and integration methods autocomplete configurations.
   * @function
   * @param {Object} changes
   */
  vm.$onChanges = changes => {
    if (changes.options && vm.options) {
      vm.mappingFunctionsAutocomplete = TimeSeriesVariableService.getMappingFunctionAutocomplete(
        vm.options
      );
      vm.integrationMethodsAutocomplete = TimeSeriesVariableService.getIntegrationMethodsAutocomplete(
        vm.options
      );
    }
  };
  /**
   * @description when scope data changes apply new data to model.
   * @function
   */
  vm.updateViewModel = () => {
    vm.model.$setViewValue({
      variableName: vm.variableName,
      mappingFunction: vm.mappingFunction,
      mappingFunctionTypeArgument: vm.mappingFunctionTypeArgument,
      mappingFunctionNArgument: vm.mappingFunctionNArgument,
      streamArchiveProperty: vm.streamArchiveProperty,
      /*  MappingExceptionActionTypes codelist */
      exceptionHandlerActionType:
        vm.exceptionHandlerActionTypeArgument != null &&
        vm.exceptionHandlerActionTypeArgument !== ''
          ? 200 /* value */
          : 100 /* none */,
      exceptionHandlerActionTypeArgument: vm.exceptionHandlerActionTypeArgument,
      dateTimeFilterType: vm.dateTimeFilterType,
      timeUnit: vm.timeUnit,
      offsetValue: vm.offsetValue,
      includeValueOnTheEdge: vm.includeValueOnTheEdge,
      fromDateTimeMapper: vm.fromDateTimeMapper,
      toDateTimeMapper: vm.toDateTimeMapper
    });
  };
  /* Remove parameter action configuration */
  vm.removeParamsAction = [
    {
      icon: {
        type: 2,
        name: 'close',
        color: 'grey'
      },
      fn: () => {
        if (typeof vm.remove === 'function') {
          vm.remove();
        }
      }
    }
  ];

  //MAPPING FUNCTION VALIDATOR
  vm.mappingFunctionValidators = [
    {
      errorMessage: gettextCatalog.getString(
        'Irregular sampling type is not compatible with selected trigger type'
      ),
      fn: modelValue => {
        if (
          modelValue != null &&
          !vm.options.regularInput &&
          modelValue.onlyAllowRegularInputs
        ) {
          return false;
        }
        return true;
      },
      name: 'regularValidation'
    }
  ];

  //TIME UNITS
  vm.timeUnitsAutocomplete = {
    title: gettextCatalog.getString('Time unit'),
    options: {
      items: TranslationService.GetCollection('codelists.timeUnits'),
      valueField,
      crawlerParams,
      display,
      noRemoveButton: true
    }
  };
  /**
   * @description when mapping function changes, we should update stream archive property autocomplete.
   * @function
   * @param {Object} model changed value
   */
  vm.mappingFunctionUpdated = (_, model) => {
    if (model != null && !model.__prefetched__value__) {
      vm.streamArchiveProperty = 100;

      //reset default filter type when model changes
      if (model != null) {
        vm.dateTimeFilterType = model.defaultDateTimeFilter || null;
      }
    }
    vm.updateViewModel();
    if (
      model &&
      Array.isArray(model.supportedArchiveProperties) &&
      model.supportedArchiveProperties.length > 0
    ) {
      vm.streamArchivePropertyAutocomplete = {
        title: gettextCatalog.getString('Stream Archive property'),
        options: {
          items: TranslationService.GetCollection(
            'codelists.streamArchiveProperties'
          ).filter(item =>
            model.supportedArchiveProperties.find(id => id == item.id)
          ),
          valueField,
          crawlerParams,
          display
        }
      };
    }

    if (
      model &&
      Array.isArray(model.supportedDateTimeFilters) &&
      model.supportedDateTimeFilters.length > 0
    ) {
      //DATE TIME FILTER TYPE
      vm.dateTimeFilterTypeAutocomplete = {
        title: gettextCatalog.getString('Date Filter Type'),
        options: {
          items: TranslationService.GetCollection(
            'codelists.dateTimeFilterTypes'
          ).filter(item =>
            model.supportedDateTimeFilters.find(id => id == item.id)
          ),
          valueField,
          crawlerParams,
          display,
          noRemoveButton: true
        }
      };
    }
  };
}
