MessagesController.$inject = ['AlertingService', '$scope'];

function MessagesController(AlertingService, $scope) {
  $scope.messageFn = {};
  var watcher = $scope.$watch('messageFn', function(newVal) {
    if (newVal && typeof newVal === 'function') {
      AlertingService.AssignMessageFn(newVal);
      watcher();
    }
  });
  $scope.$on('$destroy', function() {
    if (watcher) {
      watcher();
    }
  });
}

export default MessagesController;
