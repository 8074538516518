JobModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function JobModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'jobs'
  };

  var crawlerConfiguration = {
    entity: 'jobs',
    method: 'read',
    populate: 'externalDatasource,tangoAgentConnectionConfig'
  };

  var crawlerConfiguration2 = {
    entity: 'jobs',
    method: 'read',
    populate: 'externalDatasource,tangoAgentConnectionConfig'
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readList: CrawlerMethods.crawler(crawlerConfiguration),
      readJob: CrawlerMethods.crawler(crawlerConfiguration2)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default JobModel;
