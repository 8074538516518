EntityViewMethodService.$inject = [
  'CrawlerMethods',
  'AuthorizationToEditService'
];
/**
 * @ngdoc service
 * @name common.EntityViewMethodService
 * @description contains entity/view get function, parameters mapper
 * @property {function} getMethod
 * @property {function} getParams
 */
export default function EntityViewMethodService(
  CrawlerMethods,
  AuthorizationToEditService
) {
  /**
   * @description returns crawler method for entity view.
   * @function
   * @param {Object} transition
   * @return {Function}
   */
  function getMethod(transition) {
    let { entity, mode } = transition.to();

    let method;
    switch (entity) {
    //DATA
    case 'time-series':
    case 'visualizations':
      method = CrawlerMethods.getMethod({
        entity,
        method: 'custom.readItemView'
      });
      break;
    case 'time-series-groups':
      if (transition.params().groupId) {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'read'
        });
      } else {
        method = () => ({});
      }
      break;
    case 'schedulers':
    case 'tasks':
    case 'location-types':
    case 'measuringpoint-types':
      if (mode == 'read') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'read'
        });
        break;
      } else if (mode == 'update' || mode == 'create') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readAllLang'
        });
      }
      break;
    case 'external-datasources':
      if (mode == 'read') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readItemView'
        });
      } else if (mode == 'update') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'read'
        });
      }
      break;
    case 'locations':
    case 'assets':
    case 'projects':
    case 'tango-agent-connection-configs':
    case 'energy-source-types':
    case 'counter-types':
      if (mode == 'read') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readItemView'
        });
      } else if (mode == 'update' || mode == 'create') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readForm'
        });
      }
      break;
    case 'business-partners':
    case 'cost-centres':
    case 'triggered-alarms':
    case 'price-list-masters':
    case 'master-invoices':
    case 'expressions':
    case 'aggregation-groups':
      method = CrawlerMethods.getMethod({
        entity,
        method: 'custom.readItemView'
      });
      break;
    case 'price-list-items':
    case 'company-structure-nodes':
      method = CrawlerMethods.getMethod({
        entity,
        method: 'custom.readItem'
      });
      break;
    case 'price-list-details':
    case 'analysis-calculations':
      if (mode == 'read') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readItemView'
        });
      } else if (mode == 'update' || mode == 'create') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'read'
        });
      }
      break;
    case 'detail-invoices':
      if (mode == 'read') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readView'
        });
      } else if (mode == 'update') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'read'
        });
      }
      break;
    case 'profit-centres':
      method = CrawlerMethods.getMethod({
        entity,
        method: 'custom.readWithCompanyStructure'
      });
      break;
    case 'measuringpoints':
      if (mode == 'read') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readItemView'
        });
      } else if (mode == 'update') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readEditForm'
        });
      } else if (mode == 'create') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readDuplicateForm'
        });
      }
      break;
    case 'alarms':
      if (mode == 'read') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readItemView'
        });
      } else if (mode == 'update' || mode == 'create') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readItemForm'
        });
      }
      break;
    case 'sops':
      if (mode == 'read') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'read'
        });
      } else if (mode == 'update' || mode == 'create') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readWithSteps'
        });
      }
      break;
    case 'steps':
      if (mode == 'read') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readItemView'
        });
      } else if (mode == 'update' || mode == 'create') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readForm'
        });
      }
      break;
    case 'mappers':
      method = CrawlerMethods.getMethod({
        entity,
        method: 'custom.readV1'
      });
      break;
      // ANALYTICS
    case 'analyses':
      method = CrawlerMethods.getMethod({
        entity,
        method: 'custom.readItemViewFillEditForm'
      });
      break;
    case 'reports':
      method = CrawlerMethods.getMethod({
        entity,
        method: 'custom.readItemViewEditForm'
      });
      break;
    case 'dashboards':
      if (mode == 'read') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readDashboardCards'
        });
      } else if (mode == 'update' || mode == 'create') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'read'
        });
      }
      break;
    case 'dashboard-playlists':
      if (mode == 'read') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readPlaylistDashboards'
        });
      } else if (mode == 'update' || mode == 'create') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'read'
        });
      }
      break;
    case 'asset-types':
      if (mode == 'read') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.read'
        });
        break;
      } else if (mode == 'update' || mode == 'create') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readForm'
        });
        break;
      }
      return {};

    case 'time-series-types':
    case 'domain-attributes':
      if (mode == 'read') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readItemView'
        });
      } else if (mode == 'update' || mode == 'create') {
        method = CrawlerMethods.getMethod({
          entity,
          method: 'custom.readAllLang'
        });
      }
      break;
      //SIMPLE
    case 'opcs':
    case 'webservices':
    case 'files':
    case 'databases':
    case 'time-series-manual-input-forms':
    case 'rules':
    case 'users':
    case 'groups':
    case 'period-intervals':
    case 'datasets':
    case 'domains':
    case 'notifications':
      method = CrawlerMethods.getMethod({
        entity,
        method: 'read'
      });
      break;
    case 'privilege-management':
      return () => {
        const authorized = AuthorizationToEditService.isAuthorizedToEdit();
        if (!authorized) {
          throw {
            status: 403,
            doNotAlert: true
          };
        }
      };
    }
    return method;
  }

  /**
   * @description returns filter params method for entity view.
   * @function
   * @param {Object} transition
   * @return {Object}
   */
  function getParams(transition) {
    let { entity, mode } = transition.to();
    let id;
    let filterObject;
    switch (entity) {
    //DATA
    case 'time-series':
      id = transition.params().id;
      filterObject = {
        id,
        populate:
            'timeSeriesType,dataScheduler,rules.rule,rules.alarms,physicalQuantity,measurementUnit,tariff'
      };
      break;
    case 'time-series-groups':
      id = transition.params().groupId;
      filterObject = {
        id,
        populate:
            'scheduleClassifications.series.timeSeries,timeSeriesGroupType'
      };
      break;
    case 'detail-invoices':
      if (mode == 'read') {
        filterObject = {
          detailInvoiceId: transition.params().invoiceDetailId,
          masterInvoiceId: transition.params().invoiceId
        };
      } else if (mode == 'update') {
        id = transition.params().invoiceDetailId;
        filterObject = {
          id
        };
      }
      break;
    case 'price-list-details':
      id = transition.params().priceListDetailId;
      filterObject = {
        id
      };
      break;
    case 'price-list-masters':
      id = transition.params().priceListId;
      filterObject = {
        id
      };
      break;
    case 'master-invoices':
      id = transition.params().invoiceId;
      filterObject = {
        id
      };
      break;
    case 'price-list-items':
      id = transition.params().priceListItemId;
      filterObject = {
        id
      };
      break;
    case 'schedulers':
    case 'tasks':
    case 'external-datasources':
    case 'tango-agent-connection-configs':
    case 'locations':
    case 'assets':
    case 'projects':
    case 'business-partners':
    case 'cost-centres':
    case 'triggered-alarms':
    case 'profit-centres':
    case 'measuringpoints':
    case 'alarms':
    case 'sops':
    case 'steps':
    case 'mappers':
    case 'analyses':
    case 'expressions':
    case 'aggregation-groups':
    case 'analysis-calculations':
    case 'reports':
    case 'dashboards':
    case 'dashboard-playlists':
    case 'energy-source-types':
    case 'counter-types':
    case 'asset-types':
    case 'location-types':
    case 'measuringpoint-types':
    case 'company-structure-nodes':
    case 'time-series-types':
    case 'domain-attributes':
    case 'opcs':
    case 'webservices':
    case 'files':
    case 'databases':
    case 'time-series-manual-input-forms':
    case 'rules':
    case 'users':
    case 'groups':
    case 'period-intervals':
    case 'datasets':
    case 'domains':
    case 'visualizations':
    case 'notifications':
      id = transition.params().id;
      filterObject = {
        id
      };
      break;
    }
    return filterObject;
  }

  return {
    getMethod,
    getParams
  };
}
