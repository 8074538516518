import './sfe-property.scss';
import template from './sfe-property.component.html';
/**
 * @ngdoc component
 * @name common.sfeProperty
 * @description Component that is used to show a single line of data on sfe-property-list.
 * @param {string} title - title of item.
 * @param {string} type - type of item.
 * @param {Array} values - array of values that is shown
 * @example
 * <sfe-property
 * title="vm.title"
 * type="text"
 * values="vm.values"
 * ></sfe-property>
 */

export default {
  template,
  restrict: 'E',
  bindings: {
    propertyTitle: '<',
    type: '<',
    values: '<',
    actions: '<'
  },
  controller: sfePropertyController,
  controllerAs: 'vm',
  bindToController: true
};

sfePropertyController.$inject = [];

function sfePropertyController() {
  const vm = this;
  vm.uniqueId = Math.random()
    .toString(36)
    .substring(2);
  const propertyTypes = [
    'basic',
    'divider',
    'large',
    'mathExpression',
    'tags',
    'log'
  ];
  vm.$onChanges = () => {
    if (vm.type != null) {
      vm.typeValid = isTypeValid(vm.type, propertyTypes);
    } else {
      vm.typeValid = false;
    }
  };
  /**
   * @description Check if property type is valid.
   * @function
   * @param {string} type - 'number'
   * @param {Array} propertyTypes - all valid property types
   * @return {boolean}
   */
  const isTypeValid = (type, propertyTypes) => {
    const foundType = propertyTypes.find(propertyType => {
      if (type === propertyType) {
        return true;
      }
      return false;
    });
    if (foundType != null) {
      return true;
    }
    return false;
  };
}
