export default [
  'dashboard-users',
  'measuringpointcountertypelist',
  'views',
  'upload-files',
  'alarm-rule-target-for-x-axis-intervals',
  'rule-target-for-x-axis-intervals',
  'target-for-x-axis-intervals',
  'x-axis-intervals',
  'measuringpoint-splitters',
  'profiles',
  'dashboard-cards',
  'report-generals',
  'gis-maps',
  'app-config',
  'authorization-list-show-scopes',
  'authorization-list-permitted',
  'gis-user-group',
  'modify-group',
  'groups-of-user',
  'gis-users',
  'users-in-group',
  'expressions',
  'reports',
  'datasets',
  'period-intervals',
  'analysis-filter-configs',
  'aggregation-groups',
  'analyses',
  'analysis-calculations',
  'visualization-images',
  'visualization-elements',
  'visualizations',
  'location-actions',
  'authorization-instance-to-scope',
  'authorization-instance-rules',
  'authorization-tree-path',
  'dashboard-playlists',
  'dashboards',
  'authorization-tree-access',
  'authorization-root-permissions',
  'authorization-trees',
  'authorization-static-access',
  'mappings',
  'work-schedules',
  'realms',
  'interactions',
  'notifications',
  'groups',
  'time-zones',
  'triggered-alarms',
  'jobs',
  'external-datasources-regenerate-credentials',
  'heartbeat-history-last',
  'time-series-manual-input-form-group-time-series-items',
  'time-series-manual-input-form-groups',
  'price-list-details',
  'detail-invoice-values',
  'price-list-items',
  'detail-invoices',
  'master-invoices',
  'invoicelist',
  'invoices',
  'files',
  'databases',
  'webservices',
  'opcs',
  'time-series-measuringpoints',
  'time-series-locations',
  'location-attributes',
  'asset-attributes',
  'measuringpoint-attributes',
  'time-series-attributes',
  'measuringpointdomainattributes',
  'timeseriesdomainattributes',
  'assetdomainattributes',
  'locationdomainattributes',
  'domain-collections',
  'maps',
  'time-series-assets',
  'price-list-masters',
  'tags',
  'time-series-manual-input-forms',
  'asset-measuringpoints',
  'cost-centre-measuringpoints',
  'business-partner-measuringpoints',
  'measuringpoint-counter-types',
  'measuringpoints',
  'mappers',
  'rules',
  'entity-tag-bindings',
  'cost-centre-assets',
  'picture-assets',
  'assets',
  'asset-locations',
  'pictures',
  'picture-locations',
  'business-partner-locations',
  'cost-centre-locations',
  'locations',
  'business-partners',
  'projects',
  'profit-centres',
  'entity-tags',
  'external-datasources',
  'company-structure-nodes',
  'addresses',
  'cost-centres',
  'physical-quantities',
  'measurement-units',
  'location-classifications',
  'location-statuses',
  'location-types',
  'measuringpoint-types',
  'system-tag-entities',
  'system-tags',
  'ownerships',
  'location-action-statuses',
  'project-fields',
  'project-types',
  'billing-kinds',
  'billing-types',
  'energy-management-groups',
  'price-list-item-groups',
  'measuringpoint-type-attributes',
  'time-series-type-attributes',
  'location-type-attributes',
  'asset-type-attributes',
  'asset-groups',
  'asset-statuses',
  'asset-types',
  'asset-models',
  'domain-fields',
  'domain-attributes',
  'domains',
  'tariffs',
  'counter-types',
  'energy-source-types',
  'document-classifications',
  'national-holidays',
  'tasks',
  'steps',
  'alarms',
  'sops',
  'asset-brands',
  'severities',
  'alarm-types',
  'schedulers',
  'response-resolution-times',
  'users',
  'images',
  'billing-calculation-data',
  'detail-invoices-billing-data-by-billing-types',
  'time-series',
  'time-series-groups',
  'time-series-processing-value',
  'time-series-processing-trigger-calculation',
  'calculated-flows',
  'external-reader-flows',
  'external-writer-flows',
  'maintenance-flows',
  'tango-agent-connection-configs',
  'time-series-configurations',
  'time-series-types',
  'time-series-group-types',
  'time-series-processing-values',
  'Time-series-processing-extended-values-by-timestamp',
  'mt-analysis-flows',
  'asset-availability-events',
  'calculate-detail-invoice-normalized-service-date'
];
