import timeSeriesActions from '../../../redux/time-series/action/times-series.action';

/**
 * @ngdoc service
 * @name common.PersonResponsibleTimeSeriesFormConfiguration
 * @description Opens form dialog
 */

PersonResponsibleTimeSeriesFormConfiguration.$inject = [
  'gettextCatalog',
  'StandardUtils',
  'gettext',
  '$ngRedux',
  '$mdDialog',
  'TimeSeriesModel',
  'AlertingService',
  '$smartAuth'
];
export default function PersonResponsibleTimeSeriesFormConfiguration(
  gettextCatalog,
  StandardUtils,
  gettext,
  $ngRedux,
  $mdDialog,
  TimeSeriesModel,
  AlertingService,
  $smartAuth
) {
  /**
   * @description get person responislbe time series form configuration
   * @function
   * @param {Object} item item
   * @param {Object} user
   * @return {Object} configuration
   */
  const getConfiguration = (item, user) => {
    return {
      name: 'Person_responsible_form',
      title: gettextCatalog.getString('Responsible personnel'),
      actions: [
        {
          //SAVE ACTION
          title: gettext('Save'),
          fn: async form => {
            let values = form.getValues();
            let body = {
              contentResponsiblePerson: values.contentResponsiblePerson._id,
              systemResponsiblePerson: values.systemResponsiblePerson._id,
              contentNotificationUsers: Array.isArray(
                values.contentNotificationUsers
              )
                ? values.contentNotificationUsers.map(user => user._id)
                : [],
              systemNotificationUsers: Array.isArray(
                values.systemNotificationUsers
              )
                ? values.systemNotificationUsers.map(user => user._id)
                : []
            };

            try {
              await TimeSeriesModel.update({ id: item._id }, body);
              const params = {
                query: { id: item._id },
                result: {
                  data: values
                }
              };

              let action = timeSeriesActions.updateTimeSeriesProps(params);
              $ngRedux.dispatch(action);
              $mdDialog.hide();
            } catch (err) {
              AlertingService.Error(err);
            }
          },
          color: 'primary'
        }
      ],
      fields: [
        {
          id: 'contentResponsiblePerson',
          title: gettextCatalog.getString('Content responsible person'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'users',
                method: 'read'
              },
              crawlerParams,
              display,
              dialog: {
                entity: 'users'
              }
            }
          },
          initialize: () => {
            if (item != null && item.contentResponsiblePerson != null) {
              return item.contentResponsiblePerson;
            }
            return user ? user : null;
          },
          required: true
        },
        {
          id: 'contentNotificationUsers',
          title: gettextCatalog.getString('Content notification users'),
          type: {
            name: 'tags',
            options: {
              itemsCrawler: {
                entity: 'users',
                method: 'read'
              },
              crawlerParams,
              display,
              dialog: {
                entity: 'users'
              },
              valueParam: '_id'
            }
          },
          initialize: () => {
            return item != null ? item.contentNotificationUsers : [];
          },
          required: true
        },
        {
          id: 'systemResponsiblePerson',
          title: gettextCatalog.getString('System responsible person'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'users',
                method: 'read'
              },
              crawlerParams,
              display,
              dialog: {
                entity: 'users'
              }
            }
          },
          initialize: () => {
            if (item != null && item.systemResponsiblePerson != null) {
              return item.systemResponsiblePerson;
            }
            return user ? user : null;
          },
          required: true
        },
        {
          id: 'systemNotificationUsers',
          title: gettextCatalog.getString('System notification users'),
          type: {
            name: 'tags',
            options: {
              itemsCrawler: {
                entity: 'users',
                method: 'read'
              },
              crawlerParams,
              display,
              dialog: {
                entity: 'users'
              },
              valueParam: '_id'
            }
          },
          initialize: () => {
            return item != null ? item.systemNotificationUsers : [];
          },
          required: true
        }
      ]
    };
  };

  /**
   * @description crawler params
   * @function
   * @param {string} text
   * @return {Object}
   */
  const crawlerParams = text => {
    let filter = {
      order: '-name'
    };
    if (text != null && text != '') {
      filter = {
        ...filter,
        full_name: text
      };
    }
    return filter;
  };

  /**
   * @description display function for dialog
   * @function
   * @param {Object} item item
   * @return {Object}
   */
  const display = item => {
    let chosenImage = {
      endpoint: StandardUtils.getUserImageUrl(item, 'sm'),
      gravatarId: item._id
    };
    return {
      text: `${
        item != null ? item.name : gettextCatalog.getString('Unknown user')
      } ${item && item.family_name ? item.family_name : ''}`,
      chosenImage
    };
  };

  /**
   * @description async function that returns configuration to open responsible person dialog.
   * @function
   * @param {Object} item item
   * @return {Object}
   */
  const asyncGet = async item => {
    const user = await $smartAuth.profile();
    //CONFIGURATION
    return getConfiguration(item, user);
  };

  /**
   * @description function that returns configuration to open responsible person dialog.
   * @function
   * @param {Object} item item
   * @return {Object}
   */
  const get = item => {
    return getConfiguration(item, {});
  };
  return {
    asyncGet,
    get
  };
}
