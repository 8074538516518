import './energy-segments.scss';
import template from './energy-segments.directive.html';

energySegments.$inject = ['utilService', '$location'];

function energySegments(utilService, $location) {
  return {
    restrict: 'E',
    template,
    link: function link(scope) {
      var energySegment = utilService.energySegmentConfiguration;
      var redirectUrl = $location.$$absUrl.replace(
        $location.$$url,
        energySegment.redirectUri
      );
      scope.hydroPlant = redirectUrl + '/' + energySegment.hydroPlant;
      scope.thermoPlant = redirectUrl + '/' + energySegment.thermoPlant;
      scope.solarPlant = redirectUrl + '/' + energySegment.solarPlant;
      scope.windPlant = redirectUrl + '/' + energySegment.windPlant;
      scope.mobility = redirectUrl + '/' + energySegment.mobility;
      scope.smartCity = redirectUrl + '/' + energySegment.smartCity;
      scope.smartHome = redirectUrl + '/' + energySegment.smartHome;
      scope.factory = redirectUrl + '/' + energySegment.factory;
      scope.lightbulb = redirectUrl + '/' + energySegment.lightbulb;
    }
  };
}

export default energySegments;
