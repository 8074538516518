NetworkResource.$inject = [
  '$resource',
  'utilService',
  'AlertingService',
  'LocalizationService',
  'gettextCatalog'
];
/**
 * @ngdoc service
 * @name common.NetworkResource
 * @description wrapper around angular resource.
 * @property {function} anonymousFunction
 */
export default function NetworkResource(
  $resource,
  utilService,
  AlertingService,
  LocalizationService,
  gettextCatalog
) {
  /**
   * @memberof NetworkResource.anonymousFunction
   * @description description.
   * @param {object} networkParameters network parameters object
   * {
   *   host, (string)
   *   path, (string)
   *   method, (POST, PUT, GET, DELETE ...)
   *   headers, (object)
   *   allLang, (bool)
   *   defaultParameters, (object)
   *   data (any)
   *   queryParameterOverride (string) comes from model configuration overrides default id parameter in case we want to filter by id (.../tags?id=CLA)
   * }
   * @param {object} configuration request configuration object
   * {
   *   cache, (bool),
   * imageArrayBuffer (bool)
   * }
   * @return {function}
   */
  return function(networkParameters, configuration) {
    var apiHost;
    var resource = null;
    if (networkParameters.host && networkParameters.path) {
      apiHost = utilService.getHost(networkParameters.host);

      var requestConfiguration = {
        method: networkParameters.method
      };
      var headers = networkParameters.headers || {};
      if (networkParameters.host !== 'gis') {
        //temp smartAuth adds token in request interceptor
        requestConfiguration.smartAuth = true;
        headers.language = networkParameters.allLang
          ? 'all'
          : LocalizationService.GetSelectedLangNetworkCode();
      } else {
        requestConfiguration.gisAuth = true;
      }

      requestConfiguration.headers = headers;

      /* imageBuffer */
      if (configuration && configuration.imageArrayBuffer) {
        requestConfiguration.responseType = 'arraybuffer';
        requestConfiguration.transformResponse = function(res) {
          var arrayBufferView = new Uint8Array(res);
          var blob = new Blob([arrayBufferView], {
            type: 'image/jpeg'
          });
          var urlCreator = window.URL || window.webkitURL;
          var imageUrl = urlCreator.createObjectURL(blob);
          return { data: imageUrl };
        };
      }

      requestConfiguration.notCancelable = configuration.notCancelable;

      var defaultParameters = networkParameters.defaultParameters || {};
      if (networkParameters.host === 'domain') {
        defaultParameters.catalog = utilService.getCatalogId();
      }

      if (typeof networkParameters.data !== 'undefined') {
        requestConfiguration.data = networkParameters.data;
      }
      const queryParameter =
        '/:' + (networkParameters.queryParameterOverride || 'id');

      resource = $resource(
        apiHost + '/' + networkParameters.path + queryParameter,
        defaultParameters,
        {
          method: requestConfiguration
        }
      );
    } else {
      AlertingService.Error(gettextCatalog.getString('No path found.'));
    }
    return resource;
  };
}
