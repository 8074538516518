import externalReaderFlowAction from '../../../redux/external-reader-flow/action/external-reader-flow.action';
import timeSeriesConfigurationsAction from '../../../redux/time-series-configurations/action/times-series-configurations.action';

/**
 * @ngdoc service
 * @name common.ExternalReaderFlowFormConfigurations
 * @description Opens form dialog
 */

ExternalReaderFlowFormConfigurations.$inject = [
  'gettextCatalog',
  'gettext',
  'TranslationService',
  '$ngRedux',
  '$mdDialog',
  'TimeSeriesConfigurationModel',
  'ExternalReaderFlowModel',
  'CrudToastFactory',
  'AlertingService',
  'TimeSeriesFlowHelper'
];
export default function ExternalReaderFlowFormConfigurations(
  gettextCatalog,
  gettext,
  TranslationService,
  $ngRedux,
  $mdDialog,
  TimeSeriesConfigurationModel,
  ExternalReaderFlowModel,
  CrudToastFactory,
  AlertingService,
  TimeSeriesFlowHelper
) {
  /**
   * @description map body data.
   * @function
   * @param {Object} body
   * @param {Object} bodyData
   */
  const mapBodyData = (body, bodyData) => {
    let maxTimeoutInMilliseconds = getMilliseconds(
      bodyData.timeoutMaxTimeoutInDays,
      bodyData.timeoutMaxTimeoutInHours,
      bodyData.timeoutMaxTimeoutInMinutes,
      bodyData.timeoutMaxTimeoutInSeconds
    );
    let batchIntervalInMilliseconds = getMilliseconds(
      bodyData.batchIntervalInDays,
      bodyData.batchIntervalInHours,
      bodyData.batchIntervalInMinutes,
      bodyData.batchIntervalInSeconds
    );
    let futureIntervalInMilliseconds = getMilliseconds(
      bodyData.futureIntervalInDays,
      bodyData.futureIntervalInHours,
      bodyData.futureIntervalInMinutes,
      bodyData.futureIntervalInSeconds
    );
    body.externalDatasource =
      bodyData.externalDatasource != null
        ? bodyData.externalDatasource._id
        : null;
    body.readScheduler =
      bodyData.readScheduler != null ? bodyData.readScheduler._id : null;
    let actionArguments = null;
    if (
      bodyData.timeoutAction != null &&
      bodyData.timeoutAction.hasArguments === true &&
      Array.isArray(bodyData.timeoutAction.arguments) &&
      bodyData.timeoutAction.arguments.length
    ) {
      actionArguments = [
        {
          name: bodyData.timeoutAction.arguments[0],
          value:
            bodyData.timeoutArgumentValue != null
              ? bodyData.timeoutArgumentValue.id
              : null
        }
      ];
    }
    if (bodyData.dataSamplingType !== 200) {
      body.timeout = null;
      if (bodyData.useTimeout != null && bodyData.useTimeout.checked === true) {
        body.timeout = {
          alarm:
            bodyData.timeoutAlarm != null ? bodyData.timeoutAlarm._id : null,
          action:
            bodyData.timeoutAction != null ? bodyData.timeoutAction.id : null,
          maxTimeoutInMilliseconds,
          actionArguments
        };
      }
    }
    body.liveIngestionConfig = {};
    body.liveIngestionConfig.mapper = bodyData.liveFlowMapper
      ? bodyData.liveFlowMapper._id
      : null;
    body.historyIngestionConfig = {};
    body.historyIngestionConfig.mapper = bodyData.historyFlowMapper
      ? bodyData.historyFlowMapper._id
      : null;
    // If external datasource type is 100 - "Tango Processing Agent".
    if (
      bodyData.externalDatasource != null &&
      bodyData.externalDatasource.externalDatasourceType === 100
    ) {
      body.liveIngestionConfig.tangoAgentConfig = {
        connectionConfig:
          bodyData.liveFlowTangoAgentConnectionConfiguration != null
            ? bodyData.liveFlowTangoAgentConnectionConfiguration._id
            : null,
        readType: bodyData.liveFlowReadType
          ? bodyData.liveFlowReadType.id
          : null
      };
      // If read type is 20 - "Batch".
      if (body.liveIngestionConfig.tangoAgentConfig.readType === 20) {
        body.liveIngestionConfig.tangoAgentConfig.initialBatchIntervalInMilliseconds = batchIntervalInMilliseconds;
      } else {
        body.liveIngestionConfig.tangoAgentConfig.initialBatchIntervalInMilliseconds = null;
      }
      // If read type is 30 - "Forecast"
      if (body.liveIngestionConfig.tangoAgentConfig.readType === 30) {
        body.liveIngestionConfig.tangoAgentConfig.futureIntervalInMilliseconds = futureIntervalInMilliseconds;
      } else {
        body.liveIngestionConfig.tangoAgentConfig.futureIntervalInMilliseconds = null;
      }
      body.liveIngestionConfig.tangoAgentConfig.tag =
        bodyData.liveFlowTag != null ? bodyData.liveFlowTag._id : null;
      body.historyIngestionConfig.tangoAgentConfig = {
        connectionConfig:
          bodyData.historyFlowTangoAgentConnectionConfiguration != null
            ? bodyData.historyFlowTangoAgentConnectionConfiguration._id
            : null,
        tag:
          bodyData.historyFlowTag != null ? bodyData.historyFlowTag._id : null
      };
    }

    if (!configHasKeys(body.liveIngestionConfig)) {
      body.liveIngestionConfig = null;
    }
    if (!configHasKeys(body.historyIngestionConfig)) {
      body.historyIngestionConfig = null;
    }

    const extrapolation = getApproximationParams(bodyData, 'extrapolation');
    const interpolation = getApproximationParams(bodyData, 'interpolation');
    if (extrapolation != null || interpolation != null) {
      body.dataApproximation = {
        extrapolation,
        interpolation
      };
    } else {
      body.dataApproximation = null;
    }
    return body;
  };

  function getApproximationParams(bodyData, type) {
    const functionParam = `${type}Function`;
    const argumentParam = `${type}Argument`;

    if (bodyData[functionParam] != null) {
      let functionArguments = null;
      if (
        bodyData[functionParam].hasArguments &&
        Array.isArray(bodyData[functionParam].arguments) &&
        bodyData[functionParam].arguments.length > 0
      ) {
        functionArguments = [
          {
            name: bodyData[functionParam].arguments[0],
            value: bodyData[argumentParam].id
          }
        ];
      }
      return {
        function: bodyData[functionParam].id,
        functionArguments
      };
    }
    return null;
  }
  function isSamplingTypeIrregular({ steps, timeSeries }) {
    //we have two options because steps is used in creation of a new and duplication of an existing timeSeries
    //while api is used on editing a specific ERF configuration on an existing timeseries
    if (steps && steps[0] && steps[0].api && steps[0].api.getValue) {
      const dataSamplingType = steps[0].api.getValue('dataSamplingType');
      if (dataSamplingType === 200) {
        //if datasampling type is irregular
        return true;
      }
      return false;
    }
    if (timeSeries && timeSeries.dataSamplingType === 200) {
      //if datasampling type is irregular
      return true;
    }
    return false;
  }
  function configHasKeys(config) {
    if (Object.keys(config).length > 0) {
      let isAllNull = Object.keys(config).every(key => config[key] == null);
      if (isAllNull) {
        return false;
      }
      return true;
    }
  }

  /**
   * @description Convert milliseconds to days, hours, minutes and seconds.
   * @function
   * @param {number} milliseconds
   * @return {Object}
   */
  function getMillisecondsInOtherDateFormats(milliseconds) {
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    if (typeof milliseconds === 'number') {
      let millisecondsToCarryOver = milliseconds;
      days = Math.floor(millisecondsToCarryOver / (1000 * 60 * 60 * 24));
      millisecondsToCarryOver = millisecondsToCarryOver % (1000 * 60 * 60 * 24);
      hours = Math.floor(millisecondsToCarryOver / (1000 * 60 * 60));
      millisecondsToCarryOver = millisecondsToCarryOver % (1000 * 60 * 60);
      minutes = Math.floor(millisecondsToCarryOver / (1000 * 60));
      millisecondsToCarryOver = millisecondsToCarryOver % (1000 * 60);
      seconds = Math.floor(millisecondsToCarryOver / 1000);
    }
    return {
      days,
      hours,
      minutes,
      seconds
    };
  }

  /**
   * @description calculate milliseconds from days, hours, minutes and seconds.
   * @function
   * @param {number} days
   * @param {number} hours
   * @param {number} minutes
   * @param {number} seconds
   * @return {number}
   */
  function getMilliseconds(days, hours, minutes, seconds) {
    let milliseconds = 0;
    if (typeof days === 'number') {
      milliseconds += days * 1000 * 60 * 60 * 24;
    }
    if (typeof hours === 'number') {
      milliseconds += hours * 1000 * 60 * 60;
    }
    if (typeof minutes === 'number') {
      milliseconds += minutes * 1000 * 60;
    }
    if (typeof seconds === 'number') {
      milliseconds += seconds * 1000;
    }
    return milliseconds;
  }

  /**
   * @description generate display object for autocomplete.
   * @function
   * @param {Object} data
   * @return {Object}
   */
  function displayName(data) {
    return {
      text: `${data ? data.name : gettextCatalog.getString('Unknown')}`
    };
  }

  /**
   * @description set timeout based on selected schedule classification
   * @function
   * @param {number} scheduleClassificationId
   * @param {Object} api
   */
  const setTimeoutByScheduleClassification = (
    scheduleClassificationId,
    api
  ) => {
    let {
      timeoutMaxTimeoutInMinutes,
      timeoutMaxTimeoutInHours,
      timeoutMaxTimeoutInDays,
      batchIntervalInMinutes,
      batchIntervalInHours,
      batchIntervalInDays,
      futureIntervalInMinutes,
      futureIntervalInHours,
      futureIntervalInDays
    } = api.getValues();
    let hasTimeout = true;
    let hasBatch = true;
    let hasFuture = true;
    if (
      timeoutMaxTimeoutInMinutes === 0 &&
      timeoutMaxTimeoutInHours === 0 &&
      timeoutMaxTimeoutInDays === 0
    ) {
      hasTimeout = false;
    }
    if (
      batchIntervalInMinutes === 0 &&
      batchIntervalInHours === 0 &&
      batchIntervalInDays === 0
    ) {
      hasBatch = false;
    }
    if (
      futureIntervalInMinutes === 0 &&
      futureIntervalInHours === 0 &&
      futureIntervalInDays === 0
    ) {
      hasFuture = false;
    }
    switch (scheduleClassificationId) {
    // Hourly
    case 1:
      if (!hasTimeout) {
        api.setValue('timeoutMaxTimeoutInHours', 1);
      }
      if (!hasBatch) {
        api.setValue('batchIntervalInHours', 1);
      }
      if (!hasFuture) {
        api.setValue('futureIntervalInHours', 1);
      }
      break;
      // Daily
    case 2:
      if (!hasTimeout) {
        api.setValue('timeoutMaxTimeoutInDays', 1);
      }
      if (!hasBatch) {
        api.setValue('batchIntervalInDays', 1);
      }
      if (!hasFuture) {
        api.setValue('futureIntervalInDays', 1);
      }
      break;
      // Weekly
    case 3:
      if (!hasTimeout) {
        api.setValue('timeoutMaxTimeoutInDays', 7);
      }
      if (!hasBatch) {
        api.setValue('batchIntervalInDays', 7);
      }
      if (!hasFuture) {
        api.setValue('futureIntervalInDays', 7);
      }
      break;
      // Monthly
    case 4:
      if (!hasTimeout) {
        api.setValue('timeoutMaxTimeoutInDays', 31);
      }
      if (!hasBatch) {
        api.setValue('batchIntervalInDays', 31);
      }
      if (!hasFuture) {
        api.setValue('futureIntervalInDays', 31);
      }
      break;
      // Yearly
    case 5:
      if (!hasTimeout) {
        api.setValue('timeoutMaxTimeoutInDays', 365);
      }
      if (!hasBatch) {
        api.setValue('batchIntervalInDays', 365);
      }
      if (!hasFuture) {
        api.setValue('futureIntervalInDays', 365);
      }
      break;
      // 15 minutes
    case 6:
      if (!hasTimeout) {
        api.setValue('timeoutMaxTimeoutInMinutes', 15);
      }
      if (!hasBatch) {
        api.setValue('batchIntervalInMinutes', 15);
      }
      if (!hasFuture) {
        api.setValue('futureIntervalInMinutes', 15);
      }
      break;
      // 30 minutes
    case 7:
      if (!hasTimeout) {
        api.setValue('timeoutMaxTimeoutInMinutes', 30);
      }
      if (!hasBatch) {
        api.setValue('batchIntervalInMinutes', 30);
      }
      if (!hasFuture) {
        api.setValue('futureIntervalInMinutes', 30);
      }
      break;
      // 6 months
    case 8:
      if (!hasTimeout) {
        api.setValue('timeoutMaxTimeoutInDays', 183);
      }
      if (!hasBatch) {
        api.setValue('batchIntervalInDays', 183);
      }
      if (!hasFuture) {
        api.setValue('futureIntervalInDays', 183);
      }
      break;
    }
  };

  function getApproximationArgumentField(functionField, initField, item, name) {
    return {
      id: name,
      title: gettextCatalog.getString('Select time unit'),
      type: {
        name: 'autocomplete',
        options: {
          items: TranslationService.GetCollection('codelists.timeUnits'),
          display: displayName
        }
      },
      required: true,
      initialize: () => {
        if (
          item != null &&
          item.dataApproximation != null &&
          item.dataApproximation[initField] != null &&
          Array.isArray(item.dataApproximation[initField].functionArguments) &&
          item.dataApproximation[initField].functionArguments.length
        ) {
          return item.dataApproximation[initField].functionArguments[0].value;
        }
      },
      hide: api => {
        let functionValue = api.getValue(functionField);
        if (functionValue != null && functionValue.hasArguments) {
          return false;
        }
        return true;
      }
    };
  }

  /**
   * @description check if live flow is same as history flow.
   * @function
   * @param {Object} form
   * @return {boolean}
   */
  const liveEqualsHistory = form => {
    let isEqual = false;
    if (form != null) {
      let liveFlowTangoAgentConnectionConfiguration = form.getValue(
        'liveFlowTangoAgentConnectionConfiguration'
      );
      let liveFlowTag = form.getValue('liveFlowTag');
      let historyFlowTangoAgentConnectionConfiguration = form.getValue(
        'historyFlowTangoAgentConnectionConfiguration'
      );
      let historyFlowTag = form.getValue('historyFlowTag');
      if (
        liveFlowTangoAgentConnectionConfiguration != null &&
        liveFlowTag != null &&
        historyFlowTangoAgentConnectionConfiguration != null &&
        historyFlowTag != null &&
        liveFlowTangoAgentConnectionConfiguration._id ===
          historyFlowTangoAgentConnectionConfiguration._id &&
        liveFlowTag._id === historyFlowTag._id
      ) {
        isEqual = true;
      }
    }
    return isEqual;
  };

  const useTimeoutChecked = form => {
    let useTimeout;
    if (form != null && typeof form.getValue === 'function') {
      const useTimeoutBox = form.getValue('useTimeout');
      useTimeout = useTimeoutBox != null ? useTimeoutBox.checked : false;
    }
    return useTimeout;
  };

  /**
   * @description get common properties maintenance flow form configuration.
   * @function
   * @param {Object} entityId
   * @param {Array} steps - Array of objects, containing tangoWizard steps.
   * @param {Object} duplicateData - object containing data for duplicating
   * @return {Object} configuration
   */
  async function get(options) {
    const {
      item,
      timeSeries,
      timeSeriesConfiguration
    } = await TimeSeriesFlowHelper.getFlowData({
      ...options,
      entity: 'external-reader-flows',
      populate:
        'timeout.alarm,externalDatasource,readScheduler,liveIngestionConfig.mapper,liveIngestionConfig.tangoAgentConfig.connectionConfig,liveIngestionConfig.tangoAgentConfig.tag,historyIngestionConfig.mapper,historyIngestionConfig.tangoAgentConfig.connectionConfig,historyIngestionConfig.tangoAgentConfig.tag'
    });
    const { actionType, steps } = options;

    let timeoutDateFormats;
    let batchIntervalDateFormats;
    let futureIntervalDateFormats;
    if (item != null) {
      if (item.timeout != null) {
        timeoutDateFormats = getMillisecondsInOtherDateFormats(
          item.timeout.maxTimeoutInMilliseconds
        );
      }
      if (
        item.liveIngestionConfig != null &&
        item.liveIngestionConfig.tangoAgentConfig != null
      ) {
        batchIntervalDateFormats = getMillisecondsInOtherDateFormats(
          item.liveIngestionConfig.tangoAgentConfig
            .initialBatchIntervalInMilliseconds
        );
        futureIntervalDateFormats = getMillisecondsInOtherDateFormats(
          item.liveIngestionConfig.tangoAgentConfig.futureIntervalInMilliseconds
        );
      }
    }
    let ingestionTypes = TranslationService.GetCollection(
      'codelists.ingestionTypes'
    );
    let filteredIngestionTypes = ingestionTypes.map(ingestionType => {
      let filteredIngestionType = {
        id: ingestionType.id,
        value: ingestionType.id,
        name: ingestionType.name
      };
      return filteredIngestionType;
    });

    let title;
    switch (actionType) {
    case 'update':
      title = gettextCatalog.getString('Update external reader flow');
      break;
    case 'create':
      title = gettextCatalog.getString('Create external reader flow');
      break;
    case 'duplicate':
      title = gettextCatalog.getString('Duplicate external reader flow');
      break;
    default:
      title = gettextCatalog.getString('External Reader flow');
    }
    let config = {
      name: 'externalReaderFlow',
      actions: [
        {
          //SAVE ACTION
          title: gettext('Save'),
          fn: async form => {
            let bodyData = form.getValues();

            bodyData.dataSamplingType = isSamplingTypeIrregular({
              steps,
              timeSeries
            })
              ? 200
              : 100; // irregular has id of 200, regular of 100
            let body = mapBodyData({}, bodyData);
            //UPDATE
            if (
              item != null &&
              timeSeriesConfiguration != null &&
              actionType === 'update'
            ) {
              //remove readScheduler from edit request
              delete body.readScheduler;
              const flowParams = {
                query: { timeSeriesId: item.timeSeries, id: item._id },
                body
              };
              const configurationParams = {
                query: {
                  timeSeriesId: item.timeSeries,
                  id: timeSeriesConfiguration._id
                },
                body: {
                  validFrom: bodyData.validFrom[0],
                  validTo: Array.isArray(bodyData.validTo)
                    ? bodyData.validTo[0]
                    : null
                }
              };

              let actionFlow = externalReaderFlowAction.updateExternalReaderFlow(
                flowParams
              );

              $ngRedux.dispatch(actionFlow);
              if (timeSeriesConfiguration.validTo == null) {
                let action = timeSeriesConfigurationsAction.updateTimeSeriesConfigurations(
                  configurationParams
                );
                $ngRedux.dispatch(action);
              }
            } else {
              // CREATE DUPLICATE
              let timeSeriesConfigurationBody = {
                validFrom: bodyData.validFrom[0],
                flowRef: 100
              };
              try {
                let createdFlow = await ExternalReaderFlowModel.create(
                  { timeSeriesId: timeSeries._id },
                  body
                );
                let actionFlow = externalReaderFlowAction.addExternalReaderFlow;
                await $ngRedux.dispatch(actionFlow({ result: createdFlow }));
                timeSeriesConfigurationBody.flow = createdFlow.data._id;
                let createdTimeSeriesConfiguration = await TimeSeriesConfigurationModel.create(
                  { timeSeriesId: timeSeries._id },
                  timeSeriesConfigurationBody
                );
                let action =
                  timeSeriesConfigurationsAction.addTimeSeriesConfigurations;
                await $ngRedux.dispatch(
                  action({ result: createdTimeSeriesConfiguration })
                );
                CrudToastFactory.toast('create');
              } catch (err) {
                AlertingService.Error(err);
              }
            }
            $mdDialog.hide();
          },
          color: 'primary',
          disabledFn: form => {
            if (form.formValidity() === true) {
              return false;
            }
            return true;
          }
        }
      ],
      title,
      fields: [
        {
          id: 'ingestionType',
          title: gettextCatalog.getString('Ingestion type'),
          type: {
            name: 'radio',
            options: {
              layout: 'row',
              items: filteredIngestionTypes,
              display: data => {
                return data && data.name
                  ? data.name
                  : gettextCatalog.getString('Unknown');
              }
            }
          },
          initialize: () => {
            let ingestionType = 100;
            if (item != null) {
              ingestionType = item.externalDatasource == null ? 200 : 100;
            }
            return TranslationService.GetCollectionById(
              'codelists.ingestionTypes',
              ingestionType
            ).id;
          },
          onChange: form => {
            let ingestionType = form.getValue('ingestionType');
            if (ingestionType == null || ingestionType === 200) {
              form.setValue('externalDatasource', null);
            }
          },
          required: true
        },
        {
          id: 'externalDatasource',
          title: gettextCatalog.getString('External datasource'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'external-datasources',
                method: 'read'
              },
              valueParam: '_id',
              crawlerParams: text => {
                let params = {
                  order: '-_id'
                };
                if (text != null && text != '') {
                  return {
                    ...params,
                    filter: text
                  };
                }
                return params;
              },
              display: data => displayName(data),
              dialog: {
                entity: 'external-datasources'
              }
            }
          },
          initialize: () => {
            return item ? item.externalDatasource : null;
          },
          hide: form => {
            let ingestionType = form.getValue('ingestionType');
            if (ingestionType === 100) {
              return false;
            }
            return true;
          },
          onChange: form => {
            let externalDatasource = form.getValue('externalDatasource');
            let liveFlowTangoAgentConnectionConfiguration = form.getValue(
              'liveFlowTangoAgentConnectionConfiguration'
            );
            let historyFlowTangoAgentConnectionConfiguration = form.getValue(
              'historyFlowTangoAgentConnectionConfiguration'
            );
            if (externalDatasource != null) {
              if (
                liveFlowTangoAgentConnectionConfiguration != null &&
                liveFlowTangoAgentConnectionConfiguration.externalDatasource !=
                  externalDatasource._id
              ) {
                form.setValue(
                  'liveFlowTangoAgentConnectionConfiguration',
                  null
                );
              }
              if (
                historyFlowTangoAgentConnectionConfiguration != null &&
                historyFlowTangoAgentConnectionConfiguration.externalDatasource !=
                  externalDatasource._id
              ) {
                form.setValue(
                  'historyFlowTangoAgentConnectionConfiguration',
                  null
                );
              }
            }
          },
          required: true
        },
        {
          id: 'readScheduler',
          title: gettextCatalog.getString('Reading scheduler'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'schedulers',
                method: 'read'
              },
              crawlerParams: text => {
                let params = {
                  order: '-name'
                };
                if (text != null && text != '') {
                  return {
                    ...params,
                    filter: text
                  };
                }
                return params;
              },
              display: data => {
                return {
                  text: `${
                    data.name
                      ? data.name
                      : gettextCatalog.getString('Unknown scheduler')
                  }`
                };
              }
            }
          },
          initialize: () => {
            if (item != null) {
              if (typeof item.readScheduler === 'object') {
                item.readScheduler = {
                  ...item.readScheduler,
                  __prefetched__value__: true
                };
              }

              return item.readScheduler;
            }
            if (Array.isArray(steps)) {
              let stepWithDataScheduler = steps.find(step => {
                if (
                  step.api != null &&
                  typeof step.api.getValue === 'function'
                ) {
                  return step.api.getFieldConfiguration('scheduler') != null;
                }
              });
              if (stepWithDataScheduler != null) {
                let dataScheduler = stepWithDataScheduler.api.getValue(
                  'scheduler'
                );
                return dataScheduler;
              }
            }
            return null;
          },
          onChange: form => {
            let readScheduler = form.getValue('readScheduler');
            if (readScheduler != null && !readScheduler.__prefetched__value__) {
              setTimeoutByScheduleClassification(
                readScheduler.scheduleClassification,
                form
              );
            }
          },
          disable: () => actionType === 'update',
          required: true
        },
        {
          id: 'approximationTitle',
          type: {
            name: 'title',
            options: {
              value: gettextCatalog.getString('Data approximation'),
              theme: 'primary'
            }
          },
          hide: () => {
            return isSamplingTypeIrregular({ steps, timeSeries });
          }
        },
        {
          id: 'extrapolationFunction',
          title: gettextCatalog.getString('Extrapolation Functions'),
          type: {
            name: 'autocomplete',
            options: {
              items: TranslationService.GetCollection(
                'codelists.extrapolationFunctions'
              ),
              display: data => displayName(data)
            }
          },
          initialize: () => {
            if (
              item != null &&
              item.dataApproximation != null &&
              item.dataApproximation.extrapolation != null
            ) {
              return TranslationService.GetCollectionById(
                'codelists.extrapolationFunctions',
                item.dataApproximation.extrapolation.function
              );
            }
            return null;
          },
          hide: () => {
            return isSamplingTypeIrregular({ steps, timeSeries });
          },
          required: false
        },
        getApproximationArgumentField(
          'extrapolationFunction',
          'extrapolation',
          item,
          'extrapolationArgument'
        ),
        {
          id: 'interpolationFunction',
          title: gettextCatalog.getString('Interpolation Functions'),
          type: {
            name: 'autocomplete',
            options: {
              items: TranslationService.GetCollection(
                'codelists.interpolationFunctions'
              ),
              display: data => displayName(data)
            }
          },
          initialize: () => {
            if (
              item != null &&
              item.dataApproximation != null &&
              item.dataApproximation.interpolation != null
            ) {
              return TranslationService.GetCollectionById(
                'codelists.interpolationFunctions',
                item.dataApproximation.interpolation.function
              );
            }
            return null;
          },
          hide: () => {
            return isSamplingTypeIrregular({ steps, timeSeries });
          },
          required: false
        },
        getApproximationArgumentField(
          'interpolationFunction',
          'interpolation',
          item,
          'interpolationArgument'
        ),
        {
          id: 'timeoutTitle',
          type: {
            name: 'title',
            options: {
              value: gettextCatalog.getString('Timeout'),
              theme: 'primary'
            }
          },
          hide: () => {
            return isSamplingTypeIrregular({ steps, timeSeries });
          }
        },
        {
          id: 'useTimeout',
          type: {
            name: 'checkbox',
            options: {
              layout: 'row',
              display: item => {
                return item.name;
              },
              valueField: 'id',
              items: [
                {
                  id: 'checked',
                  name: gettextCatalog.getString('Use timeout')
                }
              ]
            }
          },
          initialize: () => {
            return item != null && item.timeout != null
              ? { checked: true }
              : { checked: false };
          },
          hide: () => {
            return isSamplingTypeIrregular({ steps, timeSeries });
          }
        },
        {
          id: 'timeoutMaxTimeoutInDays',
          title: gettextCatalog.getString('Days'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0
            }
          },
          initialize: () => {
            return timeoutDateFormats != null ? timeoutDateFormats.days : 0;
          },
          hide: form => {
            let useTimeout = useTimeoutChecked(form);
            return (
              isSamplingTypeIrregular({ steps, timeSeries }) || !useTimeout
            );
          },
          width: 3,
          required: true
        },
        {
          id: 'timeoutMaxTimeoutInHours',
          title: gettextCatalog.getString('Hours'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0,
              max: 23
            }
          },
          initialize: () => {
            return timeoutDateFormats != null ? timeoutDateFormats.hours : 0;
          },
          hide: form => {
            let useTimeout = useTimeoutChecked(form);
            return (
              isSamplingTypeIrregular({ steps, timeSeries }) || !useTimeout
            );
          },
          width: 3,
          required: true
        },
        {
          id: 'timeoutMaxTimeoutInMinutes',
          title: gettextCatalog.getString('Minutes'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0,
              max: 59
            }
          },
          initialize: () => {
            return timeoutDateFormats != null ? timeoutDateFormats.minutes : 0;
          },
          hide: form => {
            let useTimeout = useTimeoutChecked(form);
            return (
              isSamplingTypeIrregular({ steps, timeSeries }) || !useTimeout
            );
          },
          width: 3,
          required: true
        },
        {
          id: 'timeoutMaxTimeoutInSeconds',
          title: gettextCatalog.getString('Seconds'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0,
              max: 59
            }
          },
          initialize: () => {
            return timeoutDateFormats != null ? timeoutDateFormats.seconds : 0;
          },
          hide: form => {
            let useTimeout = useTimeoutChecked(form);
            return (
              isSamplingTypeIrregular({ steps, timeSeries }) || !useTimeout
            );
          },
          width: 3,
          required: true
        },
        {
          id: 'timeoutAction',
          title: gettextCatalog.getString('Timeout action'),
          type: {
            name: 'autocomplete',
            options: {
              items: TranslationService.GetCollection(
                'codelists.externalReaderFlowTimeoutActions'
              ),
              display: data => displayName(data)
            }
          },
          initialize: () => {
            if (item != null && item.timeout != null) {
              return TranslationService.GetCollectionById(
                'codelists.externalReaderFlowTimeoutActions',
                item.timeout.action
              );
            }
            return null;
          },
          onChange: form => {
            const timeoutAction = form.getValue('timeoutAction');
            if (timeoutAction != null && timeoutAction.hasArguments === true) {
              form.setValue('timeoutArgumentName', timeoutAction.arguments[0]);
              form.setFieldConfigurationProperty(
                'timeoutArgumentValue',
                'title',
                gettextCatalog.getString('Time unit')
              );
              form.setFieldConfigurationProperty('timeoutAction', 'width', 8);
            } else {
              form.setFieldConfigurationProperty('timeoutAction', 'width', 12);
            }
          },
          hide: form => {
            let useTimeout = useTimeoutChecked(form);
            return (
              isSamplingTypeIrregular({ steps, timeSeries }) || !useTimeout
            );
          },
          width: 12,
          required: true
        },
        {
          id: 'timeoutArgumentValue',
          title: gettextCatalog.getString('Argument value'),
          type: {
            name: 'autocomplete',
            options: {
              items: TranslationService.GetCollection('codelists.timeUnits'),
              display: data => displayName(data)
            }
          },
          initialize: () => {
            if (
              item != null &&
              item.timeout != null &&
              Array.isArray(item.timeout.actionArguments) &&
              item.timeout.actionArguments.length
            ) {
              return item.timeout.actionArguments[0].value;
            }
            return null;
          },
          hide: form => {
            let useTimeout = useTimeoutChecked(form);
            const timeoutAction = form.getValue('timeoutAction');
            if (
              timeoutAction != null &&
              timeoutAction.hasArguments === true &&
              useTimeout === true
            ) {
              return false;
            }
            return true;
          },
          width: 4,
          required: true
        },
        {
          id: 'timeoutAlarm',
          title: gettextCatalog.getString('Timeout alarm'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'alarms',
                method: 'read'
              },
              valueParam: '_id',
              crawlerParams: text => {
                let params = {
                  order: '-name'
                };
                if (text != null && text != '') {
                  return {
                    ...params,
                    filter: text
                  };
                }
                return params;
              },
              display: data => displayName(data),
              dialog: {
                entity: 'alarms'
              }
            }
          },
          initialize: () => {
            if (item != null && item.timeout != null) {
              return item.timeout.alarm;
            }
            return null;
          },
          hide: form => {
            let useTimeout = useTimeoutChecked(form);
            return (
              isSamplingTypeIrregular({ steps, timeSeries }) || !useTimeout
            );
          },
          required: false
        },
        {
          id: 'liveFlowTitle',
          type: {
            name: 'title',
            options: {
              value: gettextCatalog.getString('Live flow'),
              theme: 'primary'
            }
          },
          actions: [
            {
              title: gettextCatalog.getString('Copy live flow to history flow'),
              fn: form => {
                let bodyData = form.getValues();
                if (bodyData != null) {
                  let copyData = {
                    historyFlowMapper: bodyData.liveFlowMapper
                      ? bodyData.liveFlowMapper
                      : null,
                    historyFlowTag: bodyData.liveFlowTag
                      ? bodyData.liveFlowTag
                      : null,
                    historyFlowTangoAgentConnectionConfiguration: bodyData.liveFlowTangoAgentConnectionConfiguration
                      ? bodyData.liveFlowTangoAgentConnectionConfiguration
                      : null
                  };
                  form.setValues(copyData);
                }
              },
              color: 'primary'
            }
          ]
        },
        {
          id: 'liveFlowMapper',
          title: gettextCatalog.getString('Live flow mapping rule'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'mappers',
                method: 'read'
              },
              valueParam: '_id',
              crawlerParams: text => {
                let stepWithDataType;
                if (Array.isArray(steps)) {
                  stepWithDataType = steps.find(step => {
                    return (
                      step.api.getFieldConfiguration('dataType').id != null
                    );
                  });
                }
                let dataType;
                if (timeSeries != null) {
                  dataType = timeSeries.dataType;
                }
                if (stepWithDataType != null) {
                  dataType = stepWithDataType.api.getValue('dataType');
                  dataType = dataType ? dataType.id : null;
                }
                let params = {
                  order: '-name',
                  dataType
                };
                if (text != null && text != '') {
                  return {
                    ...params,
                    filter: text
                  };
                }
                return params;
              },
              display: data => displayName(data),
              dialog: {
                entity: 'mappers'
              }
            }
          },
          initialize: () => {
            if (item != null && item.liveIngestionConfig != null) {
              return item.liveIngestionConfig.mapper;
            }
            return null;
          },
          onChange: form => {
            let externalDatasource = form.getValue('externalDatasource');
            let liveFlowMapper = form.getValue('liveFlowMapper');
            let ingestionType = form.getValue('ingestionType');
            if (
              (externalDatasource != null &&
                externalDatasource.externalDatasourceType !== 100) ||
              (externalDatasource == null && ingestionType === 200)
            ) {
              form.setValue('historyFlowMapper', liveFlowMapper);
            }
          },
          required: false
        },
        {
          id: 'liveFlowTangoAgentConnectionConfiguration',
          title: gettextCatalog.getString(
            'Tango agent connection configuration'
          ),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'tango-agent-connection-configs',
                method: 'read'
              },
              valueParam: '_id',
              crawlerParams: (text, api) => {
                let externalDatasource = api.getValue('externalDatasource');
                let params = {
                  order: '-name',
                  externalDatasource: externalDatasource
                    ? externalDatasource._id
                    : null
                };
                if (text != null && text != '') {
                  return {
                    ...params,
                    filter: text
                  };
                }
                return params;
              },
              display: data => displayName(data),
              dialog: {
                entity: 'tango-agent-connection-configs'
              }
            }
          },
          initialize: () => {
            if (
              item != null &&
              item.liveIngestionConfig != null &&
              item.liveIngestionConfig.tangoAgentConfig != null
            ) {
              return item.liveIngestionConfig.tangoAgentConfig.connectionConfig;
            }
            return null;
          },
          hide: form => {
            let externalDatasource = form.getValue('externalDatasource');
            if (
              externalDatasource != null &&
              externalDatasource.externalDatasourceType === 100
            ) {
              return false;
            }
            return true;
          },
          onChange: form => {
            let connectionConfig = form.getValue(
              'liveFlowTangoAgentConnectionConfiguration'
            );
            let configTag = form.getValue('liveFlowTag');
            if (connectionConfig == null) {
              form.setValue('liveFlowTag', null);
            }
            if (
              connectionConfig != null &&
              configTag != null &&
              connectionConfig._id !== configTag.tangoAgentConnectionConfig
            ) {
              form.setValue('liveFlowTag', null);
            }
          },
          required: true
        },
        {
          id: 'liveFlowTag',
          title: gettextCatalog.getString('Live flow tag'),
          type: {
            name: 'agentConfigTag',
            options: {
              connectionConfig: form => {
                let liveFlowTangoAgentConnectionConfiguration = form.getValue(
                  'liveFlowTangoAgentConnectionConfiguration'
                );
                return liveFlowTangoAgentConnectionConfiguration != null
                  ? liveFlowTangoAgentConnectionConfiguration._id
                  : null;
              }
            }
          },
          initialize: () => {
            if (
              item != null &&
              item.liveIngestionConfig != null &&
              item.liveIngestionConfig.tangoAgentConfig != null
            ) {
              return item.liveIngestionConfig.tangoAgentConfig.tag;
            }
            return null;
          },
          hide: form => {
            let externalDatasource = form.getValue('externalDatasource');
            if (
              externalDatasource != null &&
              externalDatasource.externalDatasourceType === 100
            ) {
              return false;
            }
            return true;
          },
          disable: form => {
            let liveFlowTangoAgentConnectionConfiguration = form.getValue(
              'liveFlowTangoAgentConnectionConfiguration'
            );
            if (liveFlowTangoAgentConnectionConfiguration == null) {
              return true;
            }
            return false;
          },
          required: true
        },
        {
          id: 'liveFlowReadType',
          title: gettextCatalog.getString('Live flow read type'),
          type: {
            name: 'autocomplete',
            options: {
              items: TranslationService.GetCollection('codelists.readTypes'),
              display: data => displayName(data)
            }
          },
          initialize: () => {
            if (
              item != null &&
              item.liveIngestionConfig != null &&
              item.liveIngestionConfig.tangoAgentConfig != null
            ) {
              return TranslationService.GetCollectionById(
                'codelists.readTypes',
                item.liveIngestionConfig.tangoAgentConfig.readType
              );
            }
            return null;
          },
          hide: form => {
            let externalDatasource = form.getValue('externalDatasource');
            if (
              externalDatasource != null &&
              externalDatasource.externalDatasourceType === 100
            ) {
              return false;
            }
            return true;
          },
          required: true
        },
        {
          id: 'batchIntervalInDays',
          title: gettextCatalog.getString('Batch interval in days'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0
            }
          },
          initialize: () => {
            return batchIntervalDateFormats != null
              ? batchIntervalDateFormats.days
              : 0;
          },
          width: 3,
          hide: form => {
            let externalDatasource = form.getValue('externalDatasource');
            let liveFlowReadType = form.getValue('liveFlowReadType');
            if (
              externalDatasource != null &&
              externalDatasource.externalDatasourceType === 100 &&
              liveFlowReadType != null &&
              liveFlowReadType.id === 20
            ) {
              return false;
            }
            return true;
          },
          required: true
        },
        {
          id: 'batchIntervalInHours',
          title: gettextCatalog.getString('Batch interval in hours'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0,
              max: 23
            }
          },
          initialize: () => {
            return batchIntervalDateFormats != null
              ? batchIntervalDateFormats.hours
              : 0;
          },
          width: 3,
          hide: form => {
            let externalDatasource = form.getValue('externalDatasource');
            let liveFlowReadType = form.getValue('liveFlowReadType');
            if (
              externalDatasource != null &&
              externalDatasource.externalDatasourceType === 100 &&
              liveFlowReadType != null &&
              liveFlowReadType.id === 20
            ) {
              return false;
            }
            return true;
          },
          required: true
        },
        {
          id: 'batchIntervalInMinutes',
          title: gettextCatalog.getString('Batch interval in minutes'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0,
              max: 59
            }
          },
          initialize: () => {
            return batchIntervalDateFormats != null
              ? batchIntervalDateFormats.minutes
              : 0;
          },
          width: 3,
          hide: form => {
            let externalDatasource = form.getValue('externalDatasource');
            let liveFlowReadType = form.getValue('liveFlowReadType');
            if (
              externalDatasource != null &&
              externalDatasource.externalDatasourceType === 100 &&
              liveFlowReadType != null &&
              liveFlowReadType.id === 20
            ) {
              return false;
            }
            return true;
          },
          required: true
        },
        {
          id: 'batchIntervalInSeconds',
          title: gettextCatalog.getString('Batch interval in seconds'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0,
              max: 59
            }
          },
          initialize: () => {
            return batchIntervalDateFormats != null
              ? batchIntervalDateFormats.seconds
              : 0;
          },
          width: 3,
          hide: form => {
            let externalDatasource = form.getValue('externalDatasource');
            let liveFlowReadType = form.getValue('liveFlowReadType');
            if (
              externalDatasource != null &&
              externalDatasource.externalDatasourceType === 100 &&
              liveFlowReadType != null &&
              liveFlowReadType.id === 20
            ) {
              return false;
            }
            return true;
          },
          required: true
        },
        {
          id: 'futureIntervalInDays',
          title: gettextCatalog.getString('Future interval in days'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0
            }
          },
          initialize: () => {
            return futureIntervalDateFormats != null
              ? futureIntervalDateFormats.days
              : 0;
          },
          width: 3,
          hide: form => {
            let externalDatasource = form.getValue('externalDatasource');
            let liveFlowReadType = form.getValue('liveFlowReadType');
            if (
              externalDatasource != null &&
              externalDatasource.externalDatasourceType === 100 &&
              liveFlowReadType != null &&
              liveFlowReadType.id === 30
            ) {
              return false;
            }
            return true;
          },
          required: true
        },
        {
          id: 'futureIntervalInHours',
          title: gettextCatalog.getString('Future interval in hours'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0,
              max: 23
            }
          },
          initialize: () => {
            return futureIntervalDateFormats != null
              ? futureIntervalDateFormats.hours
              : 0;
          },
          width: 3,
          hide: form => {
            let externalDatasource = form.getValue('externalDatasource');
            let liveFlowReadType = form.getValue('liveFlowReadType');
            if (
              externalDatasource != null &&
              externalDatasource.externalDatasourceType === 100 &&
              liveFlowReadType != null &&
              liveFlowReadType.id === 30
            ) {
              return false;
            }
            return true;
          },
          required: true
        },
        {
          id: 'futureIntervalInMinutes',
          title: gettextCatalog.getString('Future interval in minutes'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0,
              max: 59
            }
          },
          initialize: () => {
            return futureIntervalDateFormats != null
              ? futureIntervalDateFormats.minutes
              : 0;
          },
          width: 3,
          hide: form => {
            let externalDatasource = form.getValue('externalDatasource');
            let liveFlowReadType = form.getValue('liveFlowReadType');
            if (
              externalDatasource != null &&
              externalDatasource.externalDatasourceType === 100 &&
              liveFlowReadType != null &&
              liveFlowReadType.id === 30
            ) {
              return false;
            }
            return true;
          },
          required: true
        },
        {
          id: 'futureIntervalInSeconds',
          title: gettextCatalog.getString('Future interval in seconds'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0,
              max: 59
            }
          },
          initialize: () => {
            return futureIntervalDateFormats != null
              ? futureIntervalDateFormats.seconds
              : 0;
          },
          width: 3,
          hide: form => {
            let externalDatasource = form.getValue('externalDatasource');
            let liveFlowReadType = form.getValue('liveFlowReadType');
            if (
              externalDatasource != null &&
              externalDatasource.externalDatasourceType === 100 &&
              liveFlowReadType != null &&
              liveFlowReadType.id === 30
            ) {
              return false;
            }
            return true;
          },
          required: true
        },
        {
          id: 'historyFlowTitle',
          type: {
            name: 'title',
            options: {
              value: gettextCatalog.getString('History flow'),
              theme: 'primary'
            }
          },
          actions: [
            {
              title: gettextCatalog.getString('Copy history flow to live flow'),
              fn: form => {
                let bodyData = form.getValues();
                if (bodyData != null) {
                  let copyData = {
                    liveFlowMapper: bodyData.historyFlowMapper
                      ? bodyData.historyFlowMapper
                      : null,
                    liveFlowTag: bodyData.historyFlowTag
                      ? bodyData.historyFlowTag
                      : null,
                    liveFlowTangoAgentConnectionConfiguration: bodyData.historyFlowTangoAgentConnectionConfiguration
                      ? bodyData.historyFlowTangoAgentConnectionConfiguration
                      : null
                  };
                  form.setValues(copyData);
                }
              },
              color: 'primary'
            }
          ]
        },
        {
          id: 'historyFlowMapper',
          title: gettextCatalog.getString('History flow mapping rule'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'mappers',
                method: 'read'
              },
              valueParam: '_id',
              crawlerParams: text => {
                let stepWithDataType;
                if (Array.isArray(steps)) {
                  stepWithDataType = steps.find(step => {
                    return (
                      step.api.getFieldConfiguration('dataType').id != null
                    );
                  });
                }
                let dataType;
                if (timeSeries != null) {
                  dataType = timeSeries.dataType;
                }
                if (stepWithDataType != null) {
                  dataType = stepWithDataType.api.getValue('dataType');
                  dataType = dataType ? dataType.id : null;
                }
                let params = {
                  order: '-name',
                  dataType
                };
                if (text != null && text != '') {
                  return {
                    ...params,
                    filter: text
                  };
                }
                return params;
              },
              display: data => displayName(data),
              dialog: {
                entity: 'mappers'
              }
            }
          },
          initialize: () => {
            if (item != null && item.historyIngestionConfig != null) {
              return item.historyIngestionConfig.mapper;
            }
            return null;
          },
          disable: form => {
            let disabled = true;
            let externalDatasource = form.getValue('externalDatasource');
            let liveFlowMapper = form.getValue('liveFlowMapper');
            let historyFlowMapper = form.getValue('historyFlowMapper');
            let ingestionType = form.getValue('ingestionType');
            if (ingestionType === 100 && externalDatasource == null) {
              disabled = false;
            }
            if (
              externalDatasource != null &&
              externalDatasource.externalDatasourceType === 100
            ) {
              if (liveEqualsHistory(form) === false) {
                disabled = false;
              } else {
                if (
                  liveFlowMapper == null ||
                  historyFlowMapper == null ||
                  (liveFlowMapper != null &&
                    historyFlowMapper != null &&
                    liveFlowMapper._id !== historyFlowMapper._id)
                ) {
                  form.setValue('historyFlowMapper', liveFlowMapper);
                }
              }
            } else if (
              (externalDatasource != null &&
                externalDatasource.externalDatasourceType !== 100) ||
              (externalDatasource == null && ingestionType === 200)
            ) {
              if (
                liveFlowMapper == null ||
                historyFlowMapper == null ||
                (liveFlowMapper != null &&
                  historyFlowMapper != null &&
                  liveFlowMapper._id !== historyFlowMapper._id)
              ) {
                form.setValue('historyFlowMapper', liveFlowMapper);
              }
            }
            return disabled;
          },
          required: false
        },
        {
          id: 'historyFlowTangoAgentConnectionConfiguration',
          title: gettextCatalog.getString(
            'History flow tango agent connection configuration'
          ),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'tango-agent-connection-configs',
                method: 'read'
              },
              valueParam: '_id',
              crawlerParams: (text, api) => {
                let externalDatasource = api.getValue('externalDatasource');
                let params = {
                  order: '-name',
                  externalDatasource: externalDatasource
                    ? externalDatasource._id
                    : null
                };
                if (text != null && text != '') {
                  return {
                    ...params,
                    filter: text
                  };
                }
                return params;
              },
              display: data => displayName(data),
              dialog: {
                entity: 'tango-agent-connection-configs'
              }
            }
          },
          initialize: () => {
            if (
              item != null &&
              item.historyIngestionConfig != null &&
              item.historyIngestionConfig.tangoAgentConfig != null
            ) {
              return item.historyIngestionConfig.tangoAgentConfig
                .connectionConfig;
            }
            return null;
          },
          hide: form => {
            let externalDatasource = form.getValue('externalDatasource');
            if (
              externalDatasource != null &&
              externalDatasource.externalDatasourceType === 100
            ) {
              return false;
            }
            return true;
          },
          onChange: form => {
            let connectionConfig = form.getValue(
              'historyFlowTangoAgentConnectionConfiguration'
            );
            let configTag = form.getValue('historyFlowTag');
            if (connectionConfig == null) {
              form.setValue('historyFlowTag', null);
            }
            if (
              connectionConfig != null &&
              configTag != null &&
              connectionConfig._id !== configTag.tangoAgentConnectionConfig
            ) {
              form.setValue('historyFlowTag', null);
            }
          },
          required: true
        },
        {
          id: 'historyFlowTag',
          title: gettextCatalog.getString('History flow tag'),
          type: {
            name: 'agentConfigTag',
            options: {
              connectionConfig: form => {
                let historyFlowTangoAgentConnectionConfiguration = form.getValue(
                  'historyFlowTangoAgentConnectionConfiguration'
                );
                return historyFlowTangoAgentConnectionConfiguration != null
                  ? historyFlowTangoAgentConnectionConfiguration._id
                  : null;
              }
            }
          },
          initialize: () => {
            if (
              item != null &&
              item.historyIngestionConfig != null &&
              item.historyIngestionConfig.tangoAgentConfig != null
            ) {
              return item.historyIngestionConfig.tangoAgentConfig.tag;
            }
            return null;
          },
          hide: form => {
            let externalDatasource = form.getValue('externalDatasource');
            if (
              externalDatasource != null &&
              externalDatasource.externalDatasourceType === 100
            ) {
              return false;
            }
            return true;
          },
          disable: form => {
            let historyFlowTangoAgentConnectionConfiguration = form.getValue(
              'historyFlowTangoAgentConnectionConfiguration'
            );
            if (historyFlowTangoAgentConnectionConfiguration == null) {
              return true;
            }
            return false;
          },
          required: true
        }
      ]
    };
    return config;
  }

  return { get, mapBodyData };
}
