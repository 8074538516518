ListItemsService.$inject = [
  'AlertingService',
  '$filter',
  'CrawlerMethods',
  'AssignFilterParameters'
];
/**
 * @ngdoc serviceListItemsService
 * @name common.ListItemsService
 * @description helper service to fetch items according to selected filters.
 * @property {function} fetch
 */
export default function ListItemsService(
  AlertingService,
  $filter,
  CrawlerMethods,
  AssignFilterParameters
) {
  /**
   * @description lists all items with selected filters (without pagination).
   * @function
   * @param {object} apiCall could be either model object {entity, method} or {query: function} or angualr resource function
   * @param {array} filters array of filter objects
   * @param {object} filterObject object of filters
   * @param {number} cacheInvalidationTime time that cache is valid
   * @param {boolean} indicates of filters should be saved
   * @return {Array}
   */
  async function fetchAllItems(
    apiCall,
    filters,
    filterObject,
    cacheInvalidationTime,
    saveFilters
  ) {
    let loadMore = true;
    let items = [];
    while (loadMore) {
      let fetchedLocations = await fetchItems(
        apiCall,
        filters,
        filterObject,
        cacheInvalidationTime,
        saveFilters
      );
      loadMore =
        fetchedLocations.pagination.page *
          fetchedLocations.pagination['per_page'] <=
        fetchedLocations.pagination.total;
      items = items.concat(fetchedLocations.data);
      filterObject.page++;
    }
    return items;
  }
  /**
   * @description Helper method to construct populate filters.
   * @function
   * @param {array} currentArray current array of populate values
   * @param {string} populate populate values
   * @return {Array}
   */
  function addPopulate(currentArray, populate) {
    var populateArray = angular.copy(currentArray);
    var populateItemFound;
    if (populate) {
      populateItemFound = populateArray.find(function(item) {
        return item === populate;
      });
      if (!populateItemFound) {
        populateArray.push(populate);
      }
    }
    return populateArray;
  }

  /**
   * @description constructs filter object out of filter array and filter object and triggers fetch function.
   * @function
   * @param {array} filters array of filter objects
   * @param {object} filterObject filter object
   * @return {Promise}
   */
  async function initFilters(filters, filterObject) {
    var midRequestArray = [];
    var obj = angular.copy(filterObject) || {};
    var populateArray = [];
    if (Array.isArray(filters)) {
      obj = filters.reduce(function(accum, el) {
        switch (el.type) {
        case 'string':
        case 'number':
          if (el.searchTerm || el.searchTerm === 0) {
            const filterValue =
                el.transform != null
                  ? el.transform(el.searchTerm)
                  : el.searchTerm;
            if (el.paramType) {
              if (accum[el.param] == null) {
                accum[el.param] = '';
              }
              accum[el.param] += '{' + el.paramType + '}' + filterValue;
            } else {
              accum[el.param] = filterValue;
            }
            // set populate fields
            populateArray = addPopulate(populateArray, el.populate);
            // assign filter parameters only if filter is set
            accum = AssignFilterParameters.mergeExistingAndNewFilters(
              accum,
              el.filterParameters
            );
          }
          break;
        case 'customDate':
          // eslint-disable-next-line no-case-declarations
          const filterValue = el.getFilterValue(el);
          accum = {
            ...accum,
            ...filterValue
          };
          break;
        case 'dateCompare':
          if (
            el.date !== 'Invalid Date' &&
              el.date !== '' &&
              typeof el.date !== 'undefined'
          ) {
            if (!accum[el.param]) {
              accum[el.param] = '';
            }
            switch (el.formatType) {
            case 'string':
              accum[el.param] +=
                    '{' +
                    el.paramType +
                    '}' +
                    $filter('date')(el.date, 'dd.MM.yyyy');
              break;

            case 'isoString':
              accum[el.param] += '{' + el.paramType + '}' + el.date;
              break;
            }

            // set populate fields
            populateArray = addPopulate(populateArray, el.populate);
            // assign filter parameters only if filter is set
            accum = AssignFilterParameters.mergeExistingAndNewFilters(
              accum,
              el.filterParameters
            );
          }
          break;

        default:
          if (el.selected && el.selected.length) {
            if (el.midQuery) {
              midRequestArray.push(el);
            } else {
              accum[el.param] = el.selected;
              // set populate fields
              populateArray = addPopulate(populateArray, el.populate);
              // assign filter parameters only if filter is set
              accum = AssignFilterParameters.mergeExistingAndNewFilters(
                accum,
                el.filterParameters
              );
            }
          }
        }
        return accum;
      }, obj);
    }
    if (populateArray.length > 0) {
      obj = {
        ...obj,
        populate: populateArray.join(',')
      };
    }
    if (!midRequestArray.length) {
      return obj;
    }
    var results = await Promise.all(fetchMidQueryFilterValues);
    return results.reduce((reduceObject, resultObject) => {
      return Object.assign(reduceObject, resultObject);
    }, obj);
  }
  /**
   * @description fetches mid query filter parameters.
   * @function
   * @param {object} requeslElement object that contains filter configuration
   * @return {dataType}
   */
  async function fetchMidQueryFilterValues(requeslElement) {
    var filterObject = {};
    var midParams = requeslElement.midParam;

    midParams[requeslElement.midParamName] = JSON.stringify(
      requeslElement.selected
    );

    var method;
    if (typeof requeslElement.midQuery === 'object') {
      method = CrawlerMethods.getMethod(requeslElement.midQuery)(midParams);
    } else {
      method = requeslElement.midQuery.query(midParams).$promise;
    }

    method.then(
      function(res) {
        filterObject[requeslElement.param] = JSON.stringify(
          res.data.map(item => item[requeslElement.midValue])
        );
        return filterObject;
      },
      function(err) {
        AlertingService.Error(err);
        return filterObject;
      }
    );
  }

  /**
   * @description constructs and triggers network fetch method.
   * @function
   * @param {Object} apiCall could be either model object {entity, method} or {query: function} or angualr resource function
   * @param {Object} filterObject filter object
   * @param {Object} cacheInvalidationTime cache invalidation time
   * @return {Promise}
   */
  function listResource(apiCall, filterObject, cacheInvalidationTime) {
    let promise;
    if (typeof apiCall === 'object' && !apiCall.query) {
      promise = CrawlerMethods.getMethod(apiCall)(
        filterObject,
        cacheInvalidationTime
      );
    } else {
      promise = apiCall.query(filterObject, cacheInvalidationTime).$promise;
    }
    return promise;
  }
  /**
   * @memberof ListItemsService.fetchItems
   * @description fetches items.
   * @param {object} apiCall could be either model object {entity, method} or {query: function} or angualr resource function
   * @param {array} filters array of filter objects
   * @param {object} filterObject object of filters
   * @return {Promise}
   */
  async function fetchItems(
    apiCall,
    filters,
    filterObject,
    cacheInvalidationTime,
    saveFilters
  ) {
    var newFilter = await initFilters(filters, filterObject);
    if (saveFilters) saveFilters(newFilter); // check if it exists
    return await listResource(apiCall, newFilter, cacheInvalidationTime);
  }
  return {
    fetchItems,
    fetchAllItems
  };
}
