MeasurementTypeModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function MeasurementTypeModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'measurement-types'
  };

  var networkConfigurationAllLang = {
    host: 'api',
    path: 'measurement-types',
    allLang: true
  };

  var networkConfigurationV1 = {
    host: 'api',
    path: 'v1/measurement-types'
  };

  var crawlerConfiguration = {
    entity: 'measurement-types',
    method: 'read',
    steps: [
      {
        entity: 'measurement-types',
        valueParams: ['pairedMeasurementType'],
        queryParams: ['id'],
        method: 'read',
        methodType: 'populate'
      },
      {
        entity: 'system-tag-entities',
        valueParams: ['_id'],
        queryParams: ['entityId'],
        method: 'custom.readWithTag',
        methodType: 'add',
        newParamNames: ['systemTags']
      }
    ]
  };

  var measurementTypeSteps = [
    {
      entity: 'measurement-types',
      valueParams: ['measurementType'],
      queryParams: ['_id'],
      method: 'read',
      methodType: 'populateArray'
    }
  ];

  async function populateWithMeasurementTypes(items) {
    return await CrawlerMethods.populate(items, measurementTypeSteps);
  }

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readAllLang: ModelUtilities.crud(
        'GET',
        networkConfigurationAllLang,
        modelStorage
      ),
      createV1: ModelUtilities.crud(
        'POST',
        networkConfigurationV1,
        modelStorage
      ),
      updateV1: ModelUtilities.crud(
        'PUT',
        networkConfigurationV1,
        modelStorage
      ),
      readItemView: CrawlerMethods.crawler(crawlerConfiguration),
      populateWithMeasurementTypes
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default MeasurementTypeModel;
