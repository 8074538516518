/**
 * @ngdoc service
 * @name core.LocalizationService
 * @description Determines and sets language.
 * @property {function} getLocale - See method getLocale
 * @property {function} ChangeLang - See method ChangeLang
 * @property {function} GetSelectedLang - See method GetSelectedLang
 * @property {function} GetSelectedLangNetworkCode - See method GetSelectedLangNetworkCode
 * @property {function} Initiate - See method Initiate
 * @property {function} FindLanguageByCode - See method FindLanguageByCode
 */
LocalizationService.$inject = [
  'gettextCatalog',
  '$injector',
  '$rootScope',
  '$smartAuth',
  'CrawlerMethods',
  'AlertingService',
  '$q'
];

function LocalizationService(
  gettextCatalog,
  $injector,
  $rootScope,
  $smartAuth,
  CrawlerMethods,
  AlertingService,
  $q
) {
  var languages = [
    {
      name: 'English',
      cssCode: 'gb',
      code: 'en',
      networkCode: 'en',
      iso6391: 'en',
      decimalDelimiter: '.',
      thousandsDelimiter: ',',
      _id: 'en'
    },
    {
      name: 'Slovenian',
      cssCode: 'si',
      code: 'sl_SI',
      networkCode: 'si',
      iso6391: 'sl',
      decimalDelimiter: ',',
      thousandsDelimiter: '.',
      _id: 'sl_SI'
    },
    {
      name: 'Croatian',
      cssCode: 'hr',
      code: 'hr',
      networkCode: 'hr',
      iso6391: 'hr',
      decimalDelimiter: ',',
      thousandsDelimiter: '.',
      _id: 'hr'
    }
  ];

  var selectedLang = languages[0];

  /**
   * @memberof core.LocalizationService
   * @description Changes the selected language.
   * @param {Object} lang Object of the language to be changed to
   * @param {boolean} init If lanugage has already been initializes, don't initialize again
   */
  var ChangeLang = function(lang, init) {
    if (typeof lang === 'undefined') {
      lang = languages[0];
    }
    gettextCatalog.setCurrentLanguage(lang.code);
    selectedLang = lang;

    // inject to prevent circular dependency
    if (!init) {
      var TranslationService = $injector.get('TranslationService');
      TranslationService.Initiate();
      $rootScope.$broadcast('retranslate', {
        language: lang.code
      });
    }
    if (window.Highcharts !== undefined) {
      Highcharts.setOptions({
        lang: {
          decimalPoint: lang.decimalDelimiter,
          thousandsSep: lang.thousandsDelimiter
        }
      });
    }
  };

  /**
   * @memberof core.LocalizationService
   * @description Returns the iso6391 tag of language.
   * @return {string}
   */
  var getLocale = function() {
    return GetSelectedLang().iso6391;
  };

  /**
   * @memberof core.LocalizationService
   * @description Returns the current selected language object.
   * @return {Object}
   */
  var GetSelectedLang = function() {
    return selectedLang;
  };

  /**
   * @memberof core.LocalizationService
   * @description Returns the current selected language network code.
   * @return {string}
   */
  var GetSelectedLangNetworkCode = function() {
    return selectedLang.networkCode;
  };

  /**
   * @memberof core.LocalizationService
   * @description Returns the language object based on the given language code.
   * @return {Object}
   */
  var FindLanguageByCode = function(code) {
    return languages.find(function(langObj) {
      return langObj.code === code;
    });
  };

  function getUserProfileId(callback) {
    $smartAuth.profile().then(
      function(userProfile) {
        callback(null, userProfile._id);
      },
      function(err) {
        AlertingService.Error(err);
        callback(err);
      }
    );
  }
  function getUserProfile(userProfileId, callback) {
    var method = CrawlerMethods.getMethod({
      entity: 'users',
      method: 'read'
    });
    method({
      id: userProfileId
    }).then(
      function(res) {
        callback(null, res.data.language);
      },
      function(err) {
        AlertingService.Error(err);
        callback(err);
      }
    );
  }

  /**
   * @memberof core.LocalizationService
   * @description Initiates the currently selected language.
   * @return {Object}
   */
  var Initiate = function() {
    var deferred = $q.defer();
    var waterfall = [getUserProfileId, getUserProfile];

    async.waterfall(waterfall, function(err, languageCode) {
      if (err) {
        AlertingService.Error(err);
        deferred.reject(err);
      } else {
        var lang = FindLanguageByCode(languageCode, true);
        ChangeLang(lang, true);
        deferred.resolve();
      }
    });
    return deferred.promise;
  };

  $rootScope.GetSelectedLang = GetSelectedLang;
  $rootScope.GetSelectedLangNetworkCode = GetSelectedLangNetworkCode;

  return {
    getLocale: getLocale,
    ChangeLang: ChangeLang,
    GetSelectedLang: GetSelectedLang,
    GetSelectedLangNetworkCode: GetSelectedLangNetworkCode,
    Initiate: Initiate,
    languages: languages,
    selectedLang: selectedLang,
    FindLanguageByCode: FindLanguageByCode
  };
}

export default LocalizationService;
