import template from './tango-item-content-chart.component.html';
import './tango-item-content-chart.scss';
import timeSeriesActions from '../../../data/redux/time-series/action/times-series.action';
import valuesAction from '../../../data/redux/time-series-processing-values/action/time-series-processing-values.action';

export default {
  template,
  bindings: {
    entity: '<',
    entityId: '<'
  },
  controller: TangoItemContentChartController,
  controllerAs: 'vm'
};

TangoItemContentChartController.$inject = [
  '$ngRedux',
  '$scope',
  'ReduxStoreService',
  'TranslationService',
  'gettextCatalog',
  'gettext',
  'ColorService',
  'TimeSeriesProcessingValuesModel',
  'AlertingService',
  'DownloadCSVService',
  'Formatting',
  'DateLocalizationService',
  '$timeout',
  'GetTimeSeriesProcessingByTimestampService',
  'SfeForm2Dialog',
  'DataVisualizationConfiguration',
  'BoundariesConfiguration',
  '$mdDialog',
  'FilterDialog',
  'TangoTimeSeriesDataXHelperService',
  'SfeDataXTranslations',
  'SfeDataXMinTickInterval',
  'codelistsConstant'
];

function TangoItemContentChartController(
  $ngRedux,
  $scope,
  ReduxStoreService,
  TranslationService,
  gettextCatalog,
  gettext,
  ColorService,
  TimeSeriesProcessingValuesModel,
  AlertingService,
  DownloadCSVService,
  Formatting,
  DateLocalizationService,
  $timeout,
  GetTimeSeriesProcessingByTimestampService,
  SfeForm2Dialog,
  DataVisualizationConfiguration,
  BoundariesConfiguration,
  $mdDialog,
  FilterDialog,
  TangoTimeSeriesDataXHelperService,
  SfeDataXTranslations,
  SfeDataXMinTickInterval,
  codelistsConstant
) {
  const vm = this;
  let unsubscribe;
  let chartSettingsUpdated = false;
  let timeZoneCode;
  const defaultValuesLimit = 3000;
  const defaultColor = ColorService.getApplicationColorByIndex(1);
  //CREATE CONFIGURATION ARRAY MAP
  const configurationMap = [
    'measurementUnit',
    'metricPrefix',
    'physicalQuantity',
    'dataVisualizationConfig',
    'name',
    'precision',
    'dataType',
    'boundaries',
    'timeZone',
    'dataScheduler',
    'dataInterpretationType',
    'dataSamplingType'
  ].reduce((res, key, index) => {
    return {
      ...res,
      [key]: {
        type: 'stored',
        value: key,
        index
      }
    };
  }, {});

  vm.apiDataX = {};

  $scope.$on('$destroy', () => {
    if (typeof unsubscribe == 'function') {
      unsubscribe();
    }
  });

  /**
   * @description when all bindings come subscribes to store.
   * @function
   */
  vm.$onChanges = () => {
    //on any change check if all bindings are set
    if (vm.entityId && vm.entity && unsubscribe == null) {
      //assign state properties and measurement actions to controller scope
      // and listen for changes
      unsubscribe = $ngRedux.connect(mapStateToProps, timeSeriesActions)(vm);
    }
  };

  /**
   * @description depending in time series data type
   * get chart mode.
   * @function
   * @param {Object} displayType
   * @return {Object}
   */
  const getDisplayMode = displayType => {
    switch (displayType) {
    case 1:
      return { chart: true, grid: false, gauge: false };
    case 2:
      return { chart: false, grid: true, gauge: false };
    case 3:
      return { chart: true, grid: true, gauge: false };
    case 4:
      return { chart: false, grid: false, gauge: true };
    case 5:
      return { chart: false, grid: true, gauge: true };
    default:
      return { chart: true, grid: true };
    }
  };

  /**
   * @description returns object that formats chart date and value.
   * @function
   * @param {Number} dataType codelist id of time series data type
   * @return {Object} {
   *  value <function>
   *  data <function>
   *  }
   */
  const formatChartValues = (dataType, timeZone) => {
    let fn;
    switch (dataType) {
    case 2:
    case 3:
      fn = Formatting.formatNumber;
      break;
    default:
      fn = formatValue(dataType, timeZone);
    }
    return fn;
  };

  /**
   * @description depending od data type returns  formatted value
   * @function
   * @param {Number} dataType codelist id of time series data type
   * @return {dataType}
   */
  function formatValue(dataType, timeZone) {
    return value => {
      let formatIdentifier;
      switch (dataType) {
      case 5: // Date
        formatIdentifier = 'd';
        break;
      case 6: // Time
        formatIdentifier = 'timeonly';
        break;
      case 7: // DateTime
        formatIdentifier = 's';
        break;
      }
      if (formatIdentifier && value != null) {
        return DateLocalizationService.LocalizationDateIntervalFn(
          formatIdentifier,
          timeZone
        )(value);
      } else {
        return value;
      }
    };
  }

  /**
   * @description map chart settings body
   * @function
   * @param {Object} body
   * @param {Object} bodyData
   * @return {Object} body
   */
  const mapBodyData = (body, bodyData) => {
    body.dataVisualizationConfig = {
      displayType: bodyData.displayType != null ? bodyData.displayType : null,
      chartType: bodyData.chartType != null ? bodyData.chartType.id : null,
      color: bodyData.color,
      minimumValue: bodyData.minimumValue,
      maximumValue: bodyData.maximumValue
    };
    let defaultFilter = {
      filterType: bodyData.filterType,
      numberOfValues: null,
      futureTimeRange: null,
      historyTimeRange: null
    };

    switch (bodyData.filterType) {
    case 100:
      //HISTORY
      if (
        bodyData.historyScheduleClassification != null &&
          bodyData.historyNumberOfUnits != null
      ) {
        defaultFilter.historyTimeRange = {
          scheduleClassification:
              bodyData.historyScheduleClassification != null
                ? bodyData.historyScheduleClassification.id
                : null,
          numberOfUnits: bodyData.historyNumberOfUnits
        };
      }
      //FUTURE
      if (
        bodyData.futureScheduleClassification != null &&
          bodyData.futureNumberOfUnits != null
      ) {
        defaultFilter.futureTimeRange = {
          scheduleClassification: bodyData.futureScheduleClassification.id,
          numberOfUnits: bodyData.futureNumberOfUnits
        };
      }
      break;
    case 200:
      defaultFilter.numberOfValues = bodyData.numberOfValues;
    }
    body.dataVisualizationConfig.defaultFilter = defaultFilter;

    body.boundaries = bodyData.boundaries;
    return body;
  };

  /**
   * @description Callback function called when a set is changed, either color or chart type. Handles further database updates.
   * @function
   */
  const settingsDialog = () => {
    let dataVisualizationFormConfig = DataVisualizationConfiguration.get(
      vm.timeSeries
    );
    let boundariesFormConfig = BoundariesConfiguration.get(vm.timeSeries);
    let configuration = {
      name: 'chartSettings',
      actions: [
        {
          //SAVE ACTION
          title: gettext('Save'),
          fn: async form => {
            let bodyData = form.getValues();
            let body = mapBodyData({}, bodyData);
            const params = {
              query: { id: vm.entityId },
              body
            };

            chartSettingsUpdated = true;
            await vm.updateTimeSeries(params);

            $mdDialog.hide();
          },
          color: 'primary',
          disabledFn: form => {
            if (form.formValidity() === true) {
              return false;
            }
            return true;
          }
        }
      ],
      title: gettextCatalog.getString('Chart settings'),
      fields: [
        ...dataVisualizationFormConfig.fields,
        ...boundariesFormConfig.fields
      ]
    };
    SfeForm2Dialog.open(configuration);
  };

  const filterDialog = () => {
    FilterDialog.open(vm.apiDataX, timeZoneCode);
  };

  /**
   * @description returns function that fetches CSV values.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function exportData({ name }) {
    return () => {
      DownloadCSVService.showDownloadDialog(
        name,
        'Time series',
        'time-series-processing-value',
        vm.entityId
      );
    };
  }

  /**
   * @description returns function that returns chart values.
   * @function
   * @param {string} entityId
   * @param {number} dataInterpretationType - id
   * @param {number} dataScheduler - id
   * @return {function}
   */
  function getValues({
    entityId,
    isAreaChart,
    isAreaSpline,
    dataInterpretationType,
    isRegularAggregateTimeset,
    ignoreAggregateSamplingShift,
    chartTypeCode
  }) {
    /**
     * @description returns sorted values.
     * @function
     * @param {Object} filter filter object
     * @param {Boolean} manualRefresh indicates when chart refresh is triggered manually
     * @return {Promise} Array
     */
    return async (filter, manualRefresh) => {
      //  TRIGGER DETAIL RELOAD WHEN MANUAL REFRESH IS TRIGGERED
      if (manualRefresh) {
        const action = valuesAction.addUpdateValuesTag({
          id: vm.entityId,
          state: { content: false, detail: true }
        });
        $ngRedux.dispatch(action);
      }

      let apiObject = {};
      const invalidationTime = manualRefresh ? 0 : 1000000000000;

      apiObject.from =
        filter.from == null || filter.from === '' ? null : filter.from;

      apiObject.to = filter.to == null || filter.to === '' ? null : filter.to;
      apiObject.limit = filter.limit || defaultValuesLimit;

      /* TODO CHANGE POINT_IN_TIME FIELD NAMES*/
      if (filter.pointInTime) {
        apiObject.pointInTime = filter.pointInTime;
      }
      apiObject.view = 'simple';
      try {
        let { data: values } = await TimeSeriesProcessingValuesModel.read(
          {
            timeSeriesId: entityId,
            ...apiObject
          },
          invalidationTime
        );
        if (values.length > 0) {
          let timeSeriesConfigurations = [];
          if (
            $ngRedux.getState().timeSeriesConfigurations &&
            $ngRedux.getState().timeSeriesConfigurations.list &&
            $ngRedux.getState().timeSeriesConfigurations.list[entityId] &&
            $ngRedux.getState().timeSeriesConfigurations.list[entityId].data
          ) {
            timeSeriesConfigurations = $ngRedux.getState()
              .timeSeriesConfigurations.list[entityId].data;
          }
          const maintenanceFlows = TangoTimeSeriesDataXHelperService.getMaintenanceIntervals(
            {
              values,
              maintenanceValues: timeSeriesConfigurations.filter(
                ({ flowRef }) => flowRef === 500 || flowRef === 510
              ),
              isRegularAggregateTimeset
            }
          );

          const applyAggregateSamplingShift =
            isRegularAggregateTimeset &&
            !ignoreAggregateSamplingShift &&
            !isAreaChart;

          let valuesResult = values
            .concat(maintenanceFlows)
            .map(function(tsValue) {
              const {
                value,
                validAt,
                validFrom,
                creationType,
                showOnlyInChart
              } = tsValue;

              const x = new Date(
                applyAggregateSamplingShift
                  ? validFrom + (validAt - validFrom) / 2
                  : validAt
              );
              const isInterpolated =
                creationType === 210 || creationType === 220;
              let marker = isInterpolated
                ? {
                  fillColor: '#FFFFFF'
                }
                : {};
              const invertColumnColors =
                isInterpolated &&
                typeof chartTypeCode === 'string' &&
                TangoTimeSeriesDataXHelperService.isTypeColumnOrBar(
                  chartTypeCode
                )
                  ? {
                    invertColumnColors: true
                  }
                  : {};
              return {
                x,
                y: value,
                validAt,
                validFrom,
                marker,
                showOnlyInChart: !!showOnlyInChart,
                ...invertColumnColors
              };
            })
            .sort(function(valueA, valueB) {
              return valueA.x - valueB.x;
            });
          if (
            isRegularAggregateTimeset &&
            values.length >= 0 &&
            (isAreaChart || (isAreaSpline && dataInterpretationType != 300))
          ) {
            valuesResult.unshift({
              ...valuesResult[0],
              x: new Date(valuesResult[0].validFrom),
              showOnlyInChart: true,
              marker: {
                enabled: false
              }
            });
          }
          return valuesResult;
        }
        return [];
      } catch (err) {
        AlertingService.Error(err);
        return [];
      }
    };
  }
  /**
   * @description returns Array of series to be displayed.
   * @function
   * @param {Object}
   * @param {String} name name od the entity
   * @param {Number} precision number of decimal places to be displayed
   * @param {Object} chartType
   * @param {String} color hex of the selected color
   * @param {number} dataType codelist id of time series data type
   * @param {Object} dataVisualizationConfig entity visualization config (Used to update color or chart type)
   * @return {dataType}
   */
  function constructSeries({
    name,
    precision,
    chartType,
    color,
    dataType,
    dataInterpretationType,
    dataSamplingType,
    dataScheduler,
    minTickInterval
  }) {
    const isColumnChart =
      chartType != null && [7, 10, 11].includes(chartType.id);
    const isAreaChart = chartType != null && [4, 12, 14].includes(chartType.id);
    const isAreaSpline = chartType != null && chartType.id == 5;
    const isBarChart = chartType != null && [9].includes(chartType.id);
    const isRegularAggregateTimeset =
      dataInterpretationType == 300 && dataSamplingType == 100;
    // dont include intervals lower than daily
    const scheduleSmallerThanDaily =
      dataScheduler != null &&
      [1, 6, 7, 9].includes(dataScheduler.scheduleClassification);
    let step = undefined;
    if (dataInterpretationType === 200) {
      step = 'left';
      // if it is null it defaults to areaspline or which is the same is chartype.id = 5
    } else if (
      dataInterpretationType === 300 &&
      (chartType == null || (chartType.id != 1 && chartType.id != 5))
    ) {
      step = 'right';
    }
    return [
      {
        id: vm.entityId,
        name: name,
        // column charts get tick labels on the columns
        formatXWithRegardsToValidity:
          isRegularAggregateTimeset && !scheduleSmallerThanDaily,
        shiftValuesToMinInterval:
          isRegularAggregateTimeset &&
          !scheduleSmallerThanDaily &&
          (isColumnChart || isBarChart),
        insertMinIntervalNullGaps:
          isRegularAggregateTimeset && !scheduleSmallerThanDaily && isAreaChart,
        query: getValues({
          entityId: vm.entityId,
          isAreaChart,
          isAreaSpline,
          dataInterpretationType,
          isRegularAggregateTimeset: isRegularAggregateTimeset,
          ignoreAggregateSamplingShift: scheduleSmallerThanDaily,
          chartTypeCode: chartType.highChartCode
        }),
        nonNumerical: dataType != 2 && dataType != 3,
        decimalPrecision: vm.masterPrecision || precision,
        minTickInterval,
        type: chartType != null ? chartType.highChartCode : 'areaspline',
        color: color || defaultColor,
        step
      }
    ];
  }
  /**
   * @description returns chart configuration.
   * @function
   * @param {Object}
   * @param {String} suffix measurement Unit and metric prefix symbol combination
   * @param {Object} physicalQuantity physical quantity Object
   * @param {String} name name od the entity
   * @param {Number} precision number of decimal places to be displayed
   * @param {Object} chartType
   * @param {String} color hex of the selected color
   * @param {number} dataType codelist id of time series data type
   * @param {Object} dataVisualizationConfig entity visualization config (Used to update color or chart type)  * @return {dataType}
   * @param {Object} boundaries
   * @param {Object} translations
   * @returns {Object}
   */
  function getChartConfiguration({
    suffix,
    physicalQuantity,
    name,
    precision,
    chartType = {},
    color,
    dataType,
    dataVisualizationConfig,
    boundaries,
    translations,
    timeZone,
    dataScheduler,
    dataInterpretationType,
    dataSamplingType
  }) {
    const isRegularAggregateTimeseries =
      dataInterpretationType == 300 && dataSamplingType == 100;
    let timeZoneObject = TranslationService.GetCollectionById(
      'codelists.timeZones',
      timeZone
    );
    const dataSchedulerInfo =
      dataScheduler != null && dataScheduler.scheduleClassification != null
        ? codelistsConstant['scheduleClassifications'].find(
          item => item.id === dataScheduler.scheduleClassification
        )
        : {};

    if (timeZoneObject != null) {
      timeZoneCode = timeZoneObject.code;
    } else {
      timeZoneCode = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    let displayType = {};
    if (
      dataVisualizationConfig != null &&
      typeof dataVisualizationConfig == 'object'
    ) {
      displayType = dataVisualizationConfig.displayType;
    }
    const axisY = [
      {
        title: `${physicalQuantity ? physicalQuantity.name : ''}`,
        suffix,
        formatter: formatChartValues(dataType, timeZoneCode),
        lines: [],
        bands: []
      }
    ];
    if (dataVisualizationConfig != null) {
      const { minimumValue, maximumValue } = dataVisualizationConfig;
      if (minimumValue != null) {
        axisY[0].lines.push({
          isMin: true,
          value: minimumValue,
          color: '#ff0000'
        });
      }
      if (maximumValue != null) {
        axisY[0].lines.push({
          isMax: true,
          value: maximumValue,
          color: '#ff0000'
        });
      }
    }

    if (Array.isArray(boundaries)) {
      //ADD ALPHA TO BOUNDARY COLOR
      boundaries = boundaries.map(item => ({
        ...item,
        color: item.color
      }));
      axisY[0].bands = boundaries;
    }
    const minTickInterval = SfeDataXMinTickInterval.getTickInterval(
      dataSchedulerInfo.id
    );
    const axisX = [
      {
        type: 'datetime',
        title: gettextCatalog.getString('Time'),
        minTickInterval,
        formatter: isRegularAggregateTimeseries
          ? function(date, dateIsUTC, timeZone, useLineBreaks) {
            return DateLocalizationService.LocalizationDateIntervalFn(
              dataSchedulerInfo.abbreviation.toLowerCase()
            )(date, dateIsUTC, timeZone, useLineBreaks);
          }
          : DateLocalizationService.LocalizationDateFn
      }
    ];

    const series = constructSeries({
      name,
      precision,
      chartType: chartType,
      color,
      dataType,
      dataVisualizationConfig,
      dataInterpretationType,
      dataSamplingType,
      minTickInterval,
      dataScheduler
    });
    let defaultFilter =
      dataVisualizationConfig != null &&
      dataVisualizationConfig.defaultFilter != null
        ? dataVisualizationConfig.defaultFilter
        : {};
    let filterConfig = TangoTimeSeriesDataXHelperService.getFilter(
      defaultFilter
    );
    return {
      axis: {
        x: axisX,
        y: axisY
      },
      filter: () =>
        TangoTimeSeriesDataXHelperService.constructQueryFilter(filterConfig),
      series,
      timeZone: timeZoneCode,
      chart: {
        legend: false,
        markers: true,
        markerPlotOptions: {
          lineColor: null, // inherit from series
          lineWidth: 2
        },
        dataLabels: false,
        axisYTitle: false,
        axisXTitle: false,
        height: 420
      },
      fullscreen: true,
      grid: {
        seriesAction: {
          icon: 'info_outline',
          fn: params => {
            return GetTimeSeriesProcessingByTimestampService.openExtendedData(
              params,
              vm.timeSeries
            );
          }
        },
        height: 420
      },
      mode: getDisplayMode(displayType),
      callbacks: {
        export: exportData({ name }),
        settings: settingsDialog,
        filter: filterDialog,
        report: async api => {
          vm.showLoader = true;
          await TangoTimeSeriesDataXHelperService.generateTimeSeriesReport(
            api,
            [vm.entityId]
          );
          vm.showLoader = false;
          $scope.$applyAsync();
        }
      },
      translations
    };
  }
  /**
   * @description returns measurement unit and metric prefix symbol combination.
   * @function
   * @param {Array} results Array of result from store
   * @return {Object}
   */
  function getPhysicalData(results) {
    let { index } = configurationMap['measurementUnit'];
    let measurementUnitSymbol =
      results[index] != null && results[index].value
        ? results[index].value.symbol
        : '';

    index = configurationMap['metricPrefix'].index;
    let metricPrefixCodeListId = results[index].value;
    let metricPrefix = TranslationService.GetCollectionById(
      'codelists.metricPrefixes',
      metricPrefixCodeListId
    );
    let metricPrefixSymbol = metricPrefix != null ? metricPrefix.symbol : '';

    return {
      suffix: `${metricPrefixSymbol}${measurementUnitSymbol}`
    };
  }

  /**
   * @description creates object of values out of array of result from ReduxStoreService extraction.
   * @function
   * @param {Array} results
   * @return {Object}
   */
  function constructProperties(results) {
    return Object.keys(configurationMap).reduce((result, configKey) => {
      let index = configurationMap[configKey].index;
      let value = results[index].value;
      if (value != null) {
        switch (configKey) {
        case 'dataInterpretationType':
        case 'dataSamplingType':
        case 'physicalQuantity':
        case 'precision':
        case 'name':
        case 'boundaries':
        case 'timeZone':
        case 'dataType':
          result = {
            ...result,
            [configKey]: value
          };
          break;
        case 'dataVisualizationConfig':
          result = {
            ...result,
            color: value.color,
            [configKey]: value,
            chartType:
                TranslationService.GetCollectionById(
                  'codelists.chartTypes',
                  value.chartType
                ) || {}
          };
          break;
        case 'dataScheduler':
          result = {
            ...result,
            [configKey]: value
          };
          break;
        }
      }
      return result;
    }, {});
  }
  /**
   * @description checks if entity id is in the update processing values object then refresh chart and remove id from the list.
   * @function
   * @param {Object} timeSeriesProcessingValues timeSeriesProcessingValues item from store
   */
  async function updateChartValues(state) {
    if (
      state != null &&
      state[vm.entityId] != null &&
      state[vm.entityId].content == true
    ) {
      const newConfig = generateChartConfig($ngRedux.getState());
      vm.apiDataX.rebuild(newConfig);

      await $timeout();
      let action = valuesAction.removeUpdateValuesTag({ id: vm.entityId });
      $ngRedux.dispatch(action);
    }
  }

  const generateChartConfig = state => {
    //CREATE ARRAY OF CONFIGURATIONS TO EXTRACT ITEMS FROM STORE
    const timeSeries = Object.keys(configurationMap)
      .reduce((result, configKey) => {
        return [...result, configurationMap[configKey]];
      }, [])
      .map(config => {
        return ReduxStoreService.extract(state, vm.entity, vm.entityId, config);
      });

    const physicalData = getPhysicalData(timeSeries);
    const props = constructProperties(timeSeries);
    const translations = SfeDataXTranslations.get();
    const chartConfig = getChartConfiguration({
      ...physicalData,
      ...props,
      translations
    });
    return chartConfig;
  };

  /**
   * @description assigns store values to controller scope.
   * @function
   * @param {Object} state current state of the store
   * @return {Object}
   */
  function mapStateToProps(state) {
    if (
      state != null &&
      state.timeSeries != null &&
      state.timeSeries[vm.entityId] != null
    ) {
      vm.timeSeries = state.timeSeries[vm.entityId].data;
    }

    // INIT
    updateChartValues(state.timeSeriesProcessingValues);
    if (vm.chartConfig == null) {
      return { chartConfig: generateChartConfig(state) };
    } else if (chartSettingsUpdated === true) {
      $timeout(() => {
        const newConfig = generateChartConfig($ngRedux.getState());
        vm.apiDataX.rebuild(newConfig);
        chartSettingsUpdated = false;
      });
    }
    return {};
  }
}
