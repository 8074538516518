FormulaInputDialogContent.$inject = ['gettextCatalog'];

export default function FormulaInputDialogContent(gettextCatalog) {
  return [
    {
      type: 'subtitle',
      text: gettextCatalog.getString('Functions')
    },
    {
      type: 'operation',
      title: 'last',
      description: gettextCatalog.getString('Returns the last measurement.'),
      symbol: '( )'
    },
    {
      type: 'operation',
      title: 'avg',
      description: gettextCatalog.getString(
        'Returns the avarage of last number of measurements.'
      ),
      symbol: '( number )'
    },
    {
      type: 'operation',
      title: 'floor',
      description: gettextCatalog.getString(
        'Rounds down to nearest natural number.'
      ),
      symbol: '( )'
    },
    {
      type: 'operation',
      title: 'ceil',
      description: gettextCatalog.getString(
        'Rounds down to nearest natural number.'
      ),
      symbol: '( )'
    },
    {
      type: 'operation',
      title: 'round',
      description: gettextCatalog.getString(
        'Rounds down to nearest natural number.'
      ),
      symbol: '( )'
    },
    {
      type: 'operation',
      title: 'null',
      description: gettextCatalog.getString('No value.')
    }
  ];
}
