NewCounterTypeController.$inject = [
  '$state',
  'gettext',
  'SfeHeader',
  'AlertingService',
  'MultiLanguage',
  'createOrUpdateService',
  'LocalizationService',
  'counterType',
  '$timeout',
  'MetadataService'
];

function NewCounterTypeController(
  $state,
  gettext,
  SfeHeader,
  AlertingService,
  MultiLanguage,
  createOrUpdateService,
  LocalizationService,
  counterType,
  $timeout,
  MetadataService
) {
  var vm = this;
  var editMode;
  var id = $state.params.id;
  var redirectState = id
    ? 'configurations-energy-management-counter-types-view'
    : 'configurations-energy-management-counter-types-list';
  function initiate() {
    var headerTitle = gettext('New Counter Type');
    var headerConfig = SfeHeader.constructFormHeader(
      'primary',
      headerTitle,
      redirectState,
      { id: id }
    );

    vm.dataConfig = {
      header: headerConfig
    };

    if (
      $state.current.name ===
      'configurations-energy-management-counter-types-edit'
    ) {
      editMode = true;
      headerTitle = gettext('Edit Counter Type');
      headerConfig.title = headerTitle;
      let formData = setFormData();
      vm.dataConfig.dataObj = {
        name: formData.name,
        description: formData.description,
        energySourceType: formData.energySourceType,
        code: formData.code,
        numberOfTarifs: formData.numberOfTarifs,
        registers: formData.registers
      };

      vm.dataConfig.data = getFormConfiguration();
    } else {
      vm.dataConfig.data = getFormConfiguration();
    }
    vm.dataConfig.edit = editMode;
    vm.dataConfig.action = {
      ctrlFn: true,
      title: editMode ? gettext('Update') : gettext('Create'),
      fn: createOrUpdate
    };
  }

  function setFormData() {
    let createRegistersTranslationPaths = [];
    if (counterType.registers && Array.isArray(counterType.registers)) {
      createRegistersTranslationPaths = counterType.registers.map(
        (item, index) => [
          `registers.${index}.timeSeriesType.name`,
          `registers.${index}.tariff.name`
        ]
      );
    }
    const selectedLang = LocalizationService.GetSelectedLangNetworkCode();
    const translatedData = MultiLanguage.translateMultiLanguageValues(
      counterType,
      ['energySourceType.name'].concat(...createRegistersTranslationPaths),
      selectedLang
    );

    translatedData.registers = formatRegisters(translatedData.registers);

    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(
        'Edit ' +
          counterType.name[selectedLang] +
          ' ' +
          counterType.description[selectedLang],
        null
      );
    });

    return translatedData;
  }
  function formatRegisters(registers) {
    const returnValues = Object.keys(registers).map(key => [
      registers[key].timeSeriesType,
      registers[key].tariff
    ]);
    return returnValues;
  }
  function createApiObject() {
    var registers = [];
    if (vm.dataConfig.dataObj.registers) {
      vm.dataConfig.dataObj.registers.forEach(function(register) {
        registers.push({
          timeSeriesType: register[0]._id,
          tariff: register[1]._id
        });
      });
    }
    var apiObj = {
      name: vm.dataConfig.dataObj.name,
      description: vm.dataConfig.dataObj.description,
      code: vm.dataConfig.dataObj.code,
      numberOfTarifs: vm.dataConfig.dataObj.numberOfTarifs,
      energySourceType: vm.dataConfig.dataObj.energySourceType._id,
      registers: registers
    };
    return apiObj;
  }

  function createOrUpdate() {
    var apiObj = createApiObject();
    var data = MultiLanguage.constructForm(vm.dataConfig.data, apiObj);

    var endpoint = {
      entity: 'counter-types'
    };
    if (editMode) {
      endpoint.method = 'update';
    } else {
      endpoint.method = 'create';
    }

    createOrUpdateService
      .simpleCall(
        endpoint,
        editMode,
        id,
        data,
        'configurations-energy-management-counter-types-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }

  function getFormConfiguration() {
    var configuration = [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'multilanguage',
        required: true
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        maxlength: 500,
        required: false,
        componentType: 'multilanguage'
      },
      {
        placeholder: gettext('Code'),
        name: 'code',
        componentType: 'textField',
        required: true
      },
      {
        placeholder: gettext('Number of Tariffs'),
        name: 'numberOfTarifs',
        componentType: 'textField',
        required: true,
        min: 0,
        type: 'numerical'
      },
      {
        componentType: 'autocompleteDialog',
        edit: false,
        name: 'energySourceType',
        configuration: {
          query: {
            entity: 'energy-source-types',
            method: 'read'
          },
          entity: 'energy-source-types',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Energy Source Type')
          },
          floatingLabel: gettext('Select Energy Source Type'),
          searchParamName: 'filter',
          required: true,
          createRedirect: {
            state: 'configurations-energy-management-energy-source-types-new'
          }
        }
      },
      {
        componentType: 'dndOrderedInput',
        name: 'registers',
        edit: false,
        configuration: {
          addLabel: gettext('+ Add register'),
          title: gettext('Registers'),
          autocompleteConfigurations: [
            {
              query: {
                entity: 'time-series-types',
                method: 'read'
              },
              entity: 'time-series-types',
              floatingLabel: gettext('Select time series type'),
              searchParamName: 'filter',
              required: true
            },
            {
              query: {
                entity: 'tariffs',
                method: 'read'
              },
              entity: 'tariffs',
              floatingLabel: gettext('Select tariff'),
              searchParamName: 'filter',
              required: true
            }
          ]
        }
      }
    ];
    return configuration;
  }
  initiate();
}

export default NewCounterTypeController;
