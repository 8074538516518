ReduxStoreService.$inject = ['EntitiesService', 'ValidateObjectConfiguration'];
/**
 * @ngdoc service
 * @name common.ReduxStoreService
 * @description opens datasource tag selector dialog.
 * @property {function} open
 */
export default function ReduxStoreService(
  EntitiesService,
  ValidateObjectConfiguration
) {
  /**
   * @memberof ReduxStoreService.get
   * @description  returns object that contains configurations to build tango item
   * @param {Object} state store state object
   * @param {Number} entity codelist entity id
   * @param {string} entityId entity id
   * @param {Object} configuration configuration object {type, value, valueFn}
   * @param {Object} componentScope scope of the component
   * @return {Any}
   */
  function extract(state, entity, entityId, configuration, componentScope) {
    const storeName = EntitiesService.getStoreName(entity);
    switch (configuration.type) {
    // returns value defined in configuration under the value key
    case 'static':
      return { value: configuration.value };
    case 'stored':
      //returns value that is stored in the store

      // eslint-disable-next-line no-case-declarations
      let entityStore = state[storeName];
      // eslint-disable-next-line no-case-declarations
      let entityObject = {};
      if (
        typeof entityStore == 'object' &&
          entityStore != null &&
          state[storeName][entityId] != null
      ) {
        entityObject = state[storeName][entityId].data;
      }
      // eslint-disable-next-line no-case-declarations
      let result =
          configuration.value != null
            ? {
              value: entityObject[configuration.value]
            }
            : {
              value: entityObject
            };
        //When configuration has defined validation config
        //Run validation and apply validation results
      if (configuration.validation) {
        const {
          validity,
          configuration: validationConfiguration
        } = ValidateObjectConfiguration.validate(
          entityObject,
          configuration.validation
        );
        if (!validity) {
          result = {
            ...result,
            warning: validationConfiguration[0].warning,
            error: validationConfiguration[0].error
          };
        }
      }
      return result;
    case 'dynamic':
      return configuration.valueFn(
        state,
        storeName,
        entityId,
        componentScope
      );
    }
  }

  return {
    extract
  };
}
