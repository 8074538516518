AssetdomainattributeModel.$inject = ['ModelUtilities'];

function AssetdomainattributeModel(ModelUtilities) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'view',
    path: 'assetdomainattributes'
  };

  var methods = {
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default AssetdomainattributeModel;
