import template from './tango-variable-viewer.component.html';

export default {
  template,
  bindings: {
    entityId: '<',
    entity: '<'
  },
  controller: TangoVariableViewer,
  controllerAs: 'vm'
};
/**
 * @ngdoc component
 * @name data.tango-variable-viewer
 * @description component for displaying tango variable viewer
 * @param {string} entityId
 * @param {number} entity
 * @example
 * <tango-variable-viewer
 *   entity-id="vm.entityId"
 *   entity="vm.entity"
 * ></tango-variable-viewer>
 */

TangoVariableViewer.$inject = [
  '$scope',
  '$ngRedux',
  'VariableViewerConfigurations'
];

function TangoVariableViewer($scope, $ngRedux, VariableViewerConfigurations) {
  const vm = this;
  let unsubscribe;
  // Chechk if entity & entityId exist, then subscribe state scopes.
  vm.$onChanges = () => {
    if (vm.entity != null && vm.entityId != null && unsubscribe == null) {
      unsubscribe = $ngRedux.connect(mapStateToProps)(vm);
      $scope.$on('$destroy', unsubscribe);
    }
  };

  /**
   * @description set scopes on state.
   * @function
   * @param {Object} state
   * @return {Object} scopes
   */
  function mapStateToProps(state) {
    return VariableViewerConfigurations.getVariableViewerConfiguration(
      state,
      vm.entityId,
      vm.entity
    );
  }
}
