MeasuringPointTypeConfigController.$inject = [
  '$q',
  '$timeout',
  '$stateParams',
  'gettext',
  'MetadataService',
  'measuringPointType'
];

function MeasuringPointTypeConfigController(
  $q,
  $timeout,
  $stateParams,
  gettext,
  MetadataService,
  measuringPointType
) {
  var vm = this;
  var measuringPointTypeId = $stateParams.id;

  init();

  function init() {
    MetadataService.Loading(true);
    initattributesTable();

    vm.headerData = constructHeader(measuringPointType);
    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(
        measuringPointType.name,
        measuringPointType.description
      );
    });
  }

  function constructHeader(measuringPointType) {
    var actions = [
      {
        title: gettext('Update'),
        state: 'configurations-company-resources-measuring-point-types-edit',
        param: 'id',
        paramValue: measuringPointTypeId
      }
    ];
    var propertySections = [
      {
        title: gettext('Properties'),
        properties: [
          {
            title: gettext('Code'),
            value: measuringPointType.code,
            type: 'code'
          }
        ]
      }
    ];

    if (measuringPointType.externalCode) {
      propertySections[0].properties.push({
        title: gettext('External code'),
        value: measuringPointType.externalCode,
        type: 'code'
      });
    }

    return {
      metadata: {
        definition: gettext('Measuring point type'),
        title: measuringPointType.name,
        description: measuringPointType.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }

  function initattributesTable() {
    vm.listConfig = {
      entity: {
        plural: gettext('Measuring point type attributes')
      },
      api: {
        query: {
          entity: 'measuringpoint-type-attributes',
          method: 'custom.list'
        },
        enrich: enrich
      },
      title: {
        param: 'name'
      },
      attributes: [
        {
          title: gettext('Description'),
          param: 'description'
        },
        {
          title: gettext('Element type'),
          param: 'elementType'
        }
      ]
    };

    vm.filters = {
      type: measuringPointTypeId
    };
  }

  function enrich(item) {
    var pr = $q.defer();
    if (item.domainAttribute != null) {
      item.name = item.domainAttribute.name;
      item.description = item.domainAttribute.description;
      item.elementType = item.domainAttribute.elementType;
    }
    pr.resolve();
    return pr.promise;
  }
}

export default MeasuringPointTypeConfigController;
