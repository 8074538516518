module.exports = {
  entity: 'locations',
  path: 'locations',
  entityId: 3,
  name: {
    en: 'Location',
    sl_SI: 'Lokacija'
  },
  networkModel: {
    entity: 'locations',
    read: 'read',
    dialogMethod: {
      entity: 'locations',
      method: 'custom.readWithCostCenter'
    }
  },
  scope: 'tis_be:locations',

  filterGrid: {
    title: {
      en: 'Location cards',
      sl_SI: 'Kartice lokacij'
    },
    dashboardCardType: 10,
    dashboardCardSize: 1,
    type: 'location',
    preloaded: true,
    entities: [
      {
        entityId: '_id'
      }
    ]
  },
  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select location',
      sl_SI: 'Izberite lokacijo'
    },
    listInfo: {
      en: 'Select location from the list below',
      sl_SI: 'Izberite lokacijo s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      },
      {
        field1: 'code',
        type: 'code',
        title: 'Code'
      }
    ]
  },
  selectTree: {
    parent: 'parentId',
    displayFields: ['name', 'code'],
    selectedParamName: '_id'
  },
  sfeTree: {
    showConnectedEntities: true,
    route: 'locations',
    icon: 'location',
    parentKey: 'parentId',
    parentEntity: 'locations',
    childrenTreeConfiguration: [
      {
        type: 'simple',
        route: 'locations',
        icon: 'location'
      },
      {
        type: 'connected',
        route: 'assets',
        icon: 'asset',
        queryParam: 'location',
        defaultQueryObject: {
          isActive: true
        },
        editFunction: true
      }
    ],
    childrenConfiguration: [
      {
        type: 'simple',
        usedIn: ['everywhere'],
        route: 'locations',
        childKey: 'parentId',
        existenceParam: 'hasChild',
        icon: 'location'
      },
      {
        type: 'connected',
        usedIn: [
          'company-resources-locations-list',
          'company-resources-locations-view',
          'company-resources-company-structure'
        ],
        existenceParam: 'hasAsset',
        steps: [
          {
            route: 'asset-locations',
            childKey: 'location'
          },
          {
            route: 'assets',
            parentKey: 'asset',
            icon: 'asset'
          }
        ]
      }
    ]
  }
}
