import { DateTime } from 'luxon';

TimeIntervalFilterConfiguration.$inject = ['gettext', '$q', 'gettextCatalog'];

function TimeIntervalFilterConfiguration(gettext, $q, gettextCatalog) {
  var configuration = {
    listId: 'timeIntervalFilterId',
    entity: {
      singular: gettext('Time Interval Filter'),
      plural: gettext('Time Interval Filters')
    },
    api: {
      query: {
        entity: 'period-intervals',
        method: 'read'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'analytics-time-interval-filters-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'analytics-time-interval-filters-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'analytics-time-interval-filters-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'period-intervals',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Type'),
        param: 'filterType',
        type: 'text'
      },
      {
        title: gettext('Interval'),
        param: 'interval',
        type: 'raw'
      }
    ],
    badges: [],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  /*
   *
   *   ENRICH
   *
   */

  function Enrich(item) {
    var deferred = $q.defer();
    if (item.startDate && item.endDate) {
      item.filterType = gettextCatalog.getString('Fixed');
      var startDate = DateTime.fromJSDate(new Date(item.startDate));
      var endDate = DateTime.fromJSDate(new Date(item.endDate));
      item.interval =
        startDate.toFormat('dd. MM. yyyy') +
        ' - ' +
        endDate.toFormat('dd. MM. yyyy');
    } else {
      item.filterType = gettextCatalog.getString('Dynamic');

      if (item.periodType === 'month') {
        item.interval = gettextCatalog.getString('{{value}} last months', {
          value: item.value
        });
      } else {
        item.interval = gettextCatalog.getString('{{value}} last years', {
          value: item.value
        });
      }
    }

    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default TimeIntervalFilterConfiguration;
