NewUserFormController.$inject = [
  'gettext',
  '$state',
  'AlertingService',
  'CrudToastFactory',
  'utilService',
  'MetadataService',
  'LocalizationService',
  '$smartAuth',
  'SfeHeader',
  'ProfileModel',
  '$rootScope',
  'user',
  '$timeout'
];

function NewUserFormController(
  gettext,
  $state,
  AlertingService,
  CrudToastFactory,
  utilService,
  MetadataService,
  LocalizationService,
  $smartAuth,
  SfeHeader,
  ProfileModel,
  $rootScope,
  user,
  $timeout
) {
  var vm = this;
  vm.showForm = true;
  var selectedLang = LocalizationService.GetSelectedLang;
  init();

  function init() {
    vm.dataConfig = {};
    vm.userId = $state.params.id;

    var data = [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Surname'),
        name: 'family_name',
        componentType: 'textField',
        required: true,
        type: 'text'
      },
      {
        placeholder: gettext('E-mail'),
        name: 'email',
        componentType: 'textField',
        pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        patternExample: 'example@email.com',
        type: 'text',
        required: true
      },
      {
        placeholder: gettext('Company'),
        name: 'company',
        componentType: 'textField',
        type: 'text',
        required: false
      },
      {
        placeholder: gettext('Role'),
        name: 'company_role',
        componentType: 'textField',
        type: 'text',
        required: false
      },
      {
        componentType: 'autocompleteDialog',
        edit: true,
        configuration: {
          codelist: 'countries',
          floatingLabel: gettext('Select country'),
          searchParamName: 'name',
          required: false,
          entity: 'countries',
          dialogConfiguration: {
            multiple: false
          },
          selectedParam: 'id'
        },
        name: 'country'
      },
      {
        componentType: 'autocompleteDialog',
        edit: true,
        name: 'language',
        hide: true,
        showParam: 'selfUser',
        configuration: {
          getDomainValues: filter => {
            const options = LocalizationService.languages;
            return options.filter(item => {
              if (filter) {
                return item.name
                  .trim()
                  .toLowerCase()
                  .includes(filter.toLowerCase().trim());
              }
              return true;
            });
          },
          entity: 'languages',
          floatingLabel: gettext('Select language'),
          noDialog: true,
          required: true,
          searchParamName: 'name',
          selectedParam: 'id'
        }
      },
      {
        placeholder: gettext('Phone Number'),
        name: 'phone_number',
        required: true,
        componentType: 'textField',
        type: 'text',
        edit: true
      },
      {
        // type - string
        componentType: 'fileUpload',
        name: 'files',
        fileUploader: 'fileUploader',
        endpoint: utilService.getApiAccount + '/fileup/image',
        alias: 'image',
        single: true, //false
        required: false,
        queueLimit: 1,
        filters: [
          {
            name: 'imageFilter',
            fn: function(item) {
              var type =
                '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
              return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
            }
          }
        ]
      }
    ];
    if (vm.userId) {
      MetadataService.Loading(true);
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit user'),
        'users-users-view',
        { id: vm.userId }
      );

      $smartAuth.profile().then(
        function(userProfile) {
          vm.dataConfig.dataObj.selfUser = userProfile._id === vm.userId;
        },
        function(err) {
          AlertingService.Error(err);
        }
      );
      vm.dataConfig.dataObj =
        typeof vm.dataConfig.dataObj !== 'undefined'
          ? vm.dataConfig.dataObj
          : {};
      vm.dataConfig.dataObj.selfUser = user._id === vm.userId;
      vm.dataConfig.dataObj.name = user.name;
      vm.dataConfig.dataObj.family_name = user.family_name;
      vm.dataConfig.dataObj.email = user.email;
      vm.dataConfig.dataObj.language = LocalizationService.FindLanguageByCode(
        user.language
      );
      vm.dataConfig.dataObj.country = user.address
        ? user.address.country
        : null;
      vm.dataConfig.dataObj.phone_number = user.phone_number;
      vm.dataConfig.dataObj.company = user.company;
      vm.dataConfig.dataObj.company_role = user.company_role;
      vm.dataConfig.dataObj.picture = user.picture ? user.picture : '';
      if (user.picture) {
        vm.dataConfig.dataObj.files = [
          {
            duplicate: undefined,
            endpoint:
              utilService.getApiAccount + '/fileup/image/' + user.picture,
            file: { type: 'image/gif', name: 'Profile image' },
            id: vm.dataConfig.dataObj.picture
          }
        ];
      }

      var countryConfig = _.find(data, {
        name: 'country'
      });
      if (countryConfig) {
        countryConfig.edit = vm.dataConfig.dataObj.country;
      }
      vm.dataConfig.data = data;
      $timeout(() => {
        MetadataService.Loading(false);
        MetadataService.ChangeMetadata(
          'Edit ' + user.name + ' ' + user.family_name,
          null
        );
      });
    } else {
      vm.dataConfig.dataObj = {
        name: '',
        parent: {}
      };
      vm.dataConfig.data = data;
    }

    vm.dataConfig.edit = !!vm.userId;
    vm.dataConfig.action = {
      title: vm.userId ? gettext('Update') : gettext('Create'),
      ctrlFn: true,
      fn: update
    };
  }

  function update(item) {
    if (
      item.fileUploader &&
      item.files &&
      item.files.length > 0 &&
      item.files[0].id !== item.picture
    ) {
      item.fileUploader.uploadAll();
      item.fileUploader.onCompleteItem = function(picture, response, status) {
        if (status === 200) {
          updateAll(item, response.data);
        } else {
          AlertingService.Error(response.error);
          updateAll(item);
          vm.sendingRequest = false;
        }
      };
    } else {
      updateAll(item);
    }
  }

  async function updateAll(item, picture) {
    var currentLanguage = selectedLang();
    var obj = {
      name: item.name,
      family_name: item.family_name,
      email: item.email,
      phone_number: item.phone_number,
      address: {
        country: item.country ? item.country.id.toString() : null
      },
      picture: picture
        ? picture._id
        : item.files && item.files.length
          ? item.picture
          : '',
      company: item.company,
      company_role: item.company_role
    };
    if (item.language) {
      obj.language = item.language._id;
    }
    if (vm.userId) {
      const transitionOptions = {};

      obj.user = vm.userId;
      try {
        await ProfileModel.create(obj);
        var redirectObj = {
          state: 'users-users-view',
          paramName: 'id',
          paramValue: vm.userId
        };
        vm.showForm = false;
        transitionOptions.reload = await changeLanguage(currentLanguage, item);
        if (vm.dataConfig.dataObj.selfUser) {
          $rootScope.$broadcast('userChanged', obj);
        }
        CrudToastFactory.toast('update', redirectObj, transitionOptions);
        vm.sendingRequest = false;
        obj._id = vm.userId;
      } catch (err) {
        AlertingService.Error(err);
        vm.sendingRequest = false;
      }
    }
  }

  async function changeLanguage(currentLanguage, item) {
    let languageChanged = false;
    try {
      let userProfile = await $smartAuth.profile();

      if (
        item.language &&
        currentLanguage._id !== item.language._id &&
        userProfile._id === vm.userId
      ) {
        var changeLanguageTo = LocalizationService.FindLanguageByCode(
          item.language._id
        );
        languageChanged = true;
        LocalizationService.ChangeLang(changeLanguageTo);
      }
    } catch (err) {
      AlertingService.Error(err);
    }
    return languageChanged;
  }
}

export default NewUserFormController;
