import reportLoaderTemplate from '../../analytics/pages/report/template/report-loader.template.html';
import logicalExpressionTemplate from '../components/logical-expression/logical-expression.template.html';
import logicalExpressionPreviewTemplate from '../components/logical-expression/logical-expression-preview.template.html';

export default function noGroupTemplates() {
  return {
    reportLoaderTemplate,
    logicalExpressionTemplate,
    logicalExpressionPreviewTemplate
  };
}
