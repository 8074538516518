ImportCsvDialog.$inject = ['SfeFormDialogService', 'gettext'];
/**
 * @ngdoc service
 * @name common.ImportCsvDialog
 * @description opens upload csv dialog.
 * @property {function} open
 */
export default function ImportCsvDialog(SfeFormDialogService, gettext) {
  /**
   * @memberof ImportCsvDialog.open
   * @description opens upload csv dialog.
   * @param {String} endpoint
   * @return {Promise}
   */
  function open(endpoint) {
    return new Promise((resolve, reject) => {
      const config = [
        {
          componentType: 'fileUpload',
          name: 'file',
          fileUploader: 'fileUploader',
          endpoint,
          type: '.csv',
          single: true
        }
      ];

      SfeFormDialogService.openSfeFormDialog(
        false,
        config,
        {},
        gettext('Upload CSV file')
      ).then(function(res) {
        if (res && res.fileUploader) {
          res.fileUploader.onCompleteItem = function(item, response, status) {
            if (status === 200) {
              resolve(response);
            } else {
              let error = response.error;
              if (status === 0) {
                //when request is failed
                error = gettext('Request failed');
              }
              reject(error);
            }
          };
          res.fileUploader.uploadAll();
        }
      });
    });
  }

  return {
    open: open
  };
}
