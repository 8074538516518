generateEntityReport.$inject = [
  'ItemSelector',
  'PeriodIntervalModel',
  '$timeout',
  '$q',
  'periodIntervalConstructor',
  'analysisQueryBuilder',
  '$httpParamSerializer',
  'DatasetModel',
  'gettext',
  '$mdDialog',
  'SfeForm2Dialog',
  'SfeForm2DateFromPriorToValidationService'
];
/**
 * @ngdoc service
 * @name analytics.generateEntityReport
 * @description generates report fetch query.
 * @property {function} anonymousFunction
 */
function generateEntityReport(
  ItemSelector,
  PeriodIntervalModel,
  $timeout,
  $q,
  periodIntervalConstructor,
  analysisQueryBuilder,
  $httpParamSerializer,
  DatasetModel,
  gettext,
  $mdDialog,
  SfeForm2Dialog,
  SfeForm2DateFromPriorToValidationService
) {
  /**
   * @description fetches period interval.
   * @function
   * @param {string} periodInterval periodInterval id
   * @return {Promise}
   */
  function fetchPeriodInterval(periodInterval) {
    var deferred = $q.defer();
    PeriodIntervalModel.read({
      id: periodInterval
    }).then(
      function(res) {
        deferred.resolve(res.data);
      },
      function(err) {
        deferred.reject(err);
      }
    );
    return deferred.promise;
  }
  /**
   * @description fetcehs period interval.
   * @function
   * @param {string} dataset dataset id
   * @return {Promise}
   */
  function fetchDataset(dataset) {
    var deferred = $q.defer();
    DatasetModel.read({
      id: dataset
    }).then(
      function(res) {
        deferred.resolve(res.data);
      },
      function(err) {
        deferred.reject(err);
      }
    );
    return deferred.promise;
  }
  /**
   * @description opens report dialog selector and resolves to report view query string.
   * @function
   * @param {number} codelistEntityId entity codelist id that will be used to filter report list
   * @param {array} items array of selected entity items ids
   * @return {Promise}
   */
  async function generateReport(codelistEntityId, items, filterDates) {
    // eslint-disable-next-line no-useless-catch
    try {
      let selectedReport;

      let dates = await openDateDialog(filterDates);
      if (dates != null) {
        let selected = await ItemSelector.open(
          ['reports'],
          [
            {
              filterObject: {
                allowedEntities: codelistEntityId
              }
            }
          ]
        );

        if (selected && selected.length === 1) {
          selectedReport = selected[0];
          let periodInterval = await fetchPeriodInterval(
            selectedReport.periodInterval
          );
          let periodIntervalForm = await periodIntervalConstructor.getFormObj(
            periodInterval
          );
          selectedReport.timeObject = periodIntervalForm;
          const dataset = await fetchDataset(selectedReport.dataset);
          let selectedColumn = dataset.columns.find(function(item) {
            return item.filterEnabled && item.entity === codelistEntityId;
          });

          let { apiObject } = analysisQueryBuilder.getQuery({
            analysis: {},
            analysisFilters: [],
            startDate: dates.dateFrom,
            endDate: dates.dateTo,
            activeComparableColumn: selectedReport.activeComparableColumn,
            startDateMonth: dates.dateFrom,
            endDateMonth: dates.dateTo
          });
          if (selectedReport.filters) {
            selectedReport.filters.forEach(function(filter) {
              apiObject[filter.rawFieldName] = filter.filterValues;
            });
          }

          if (apiObject[selectedColumn.rawFieldName]) {
            items.forEach(function(itemId) {
              apiObject[selectedColumn.rawFieldName].push(itemId);
            });
          } else {
            apiObject[selectedColumn.rawFieldName] = items;
          }
          let qs = $httpParamSerializer(apiObject);
          let redirectParameters = {
            id: selectedReport._id,
            query: qs
          };
          return redirectParameters;
        }
      }
    } catch (err) {
      throw err;
    }
  }
  /**
   * @description opens date selector dialog and returns selected dates.
   * @function
   * @return {Promise}
   */
  function openDateDialog(filterDates) {
    let fromToValidation = SfeForm2DateFromPriorToValidationService.get(
      'dateFrom',
      'dateTo'
    );

    let config = {
      title: gettext('Select report dates'),
      name: 'recalculateForm',
      actions: [
        {
          title: gettext('Ok'),
          /**
           * @description triggers recalculate query.
           * @function
           * @param {object} api
           */
          fn: async api => {
            let { dateFrom, dateTo } = api.getValues();

            $mdDialog.hide({ dateFrom: dateFrom[0], dateTo: dateTo[0] });
          },
          disabledFn: api => {
            if (typeof api.formValidity == 'function') {
              return !api.formValidity();
            }
          }
        }
      ],
      fields: [
        {
          id: 'dateFrom',
          title: gettext('From'),
          width: 6,
          type: {
            name: 'date',
            options: {
              enableTime: true,
              enableSeconds: true
            }
          },
          required: true,
          //TRIGGER REVALIDATE ON CHANGE
          onChange: async api => {
            if (typeof api.revalidate == 'function') {
              await $timeout();
              api.revalidate();
            }
          },
          validators: {
            maxDateCustom: fromToValidation
          },
          initialize: () => {
            if (filterDates != null && filterDates.startDate != null) {
              return [filterDates.startDate];
            } else {
              return null;
            }
          }
        },
        {
          id: 'dateTo',
          title: gettext('To'),
          width: 6,
          type: {
            name: 'date',
            options: {
              enableTime: true,
              enableSeconds: true
            }
          },

          required: true,
          //TRIGGER REVALIDATE ON CHANGE
          onChange: async api => {
            if (typeof api.revalidate == 'function') {
              await $timeout();
              api.revalidate();
            }
          },
          validators: {
            minDateCustom: fromToValidation
          },
          initialize: () => {
            if (filterDates != null && filterDates.endDate != null) {
              return [filterDates.endDate];
            } else {
              return null;
            }
          }
        }
      ]
    };
    return SfeForm2Dialog.open(config);
  }

  return generateReport;
}

export default generateEntityReport;
