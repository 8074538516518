DomainCollectionModel.$inject = ['ModelUtilities'];

function DomainCollectionModel(ModelUtilities) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'domain',
    path: 'domains/:domainId/collection/:collectionId'
  };
  var networkConfigurationList = {
    host: 'domain',
    path: 'domains/:domainId/collection?filterField=value'
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      list: ModelUtilities.crud('GET', networkConfigurationList, modelStorage)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default DomainCollectionModel;
