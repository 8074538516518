import actionTypes from '../action/calculated-flow.type';

function calculatedFlowReducer(state = {}, action) {
  switch (action.type) {
  case actionTypes.CALCULATED_FLOW_READ: {
    return {
      ...state,
      [action.payload.id]: action.payload.result
    };
  }
  case actionTypes.CALCULATED_FLOW_UPDATE: {
    return {
      ...state,
      [action.payload.query.id]: action.payload.result
    };
  }
  case actionTypes.CALCULATED_FLOW_CREATE: {
    return {
      ...state,
      [action.payload.result.data._id]: createCalculatedFlowStore(
        action.payload
      )
    };
  }
  case actionTypes.CALCULATED_FLOW_ADD: {
    return {
      ...state,
      [action.payload.result.data._id]: createCalculatedFlowStore(
        action.payload
      )
    };
  }
  default:
    return state;
  }
}

/**
 * @description create calculatedFlow.
 * @function
 * @param {Object} payload payload object {query, result} must contain TS id in query object and result must contain updated TS under data key
 * @return {dataType}
 */
function createCalculatedFlowStore(payload) {
  let createdCalculatedFlow = payload.result.data;
  return {
    data: {
      ...createdCalculatedFlow
    }
  };
}

export default calculatedFlowReducer;
