import './sfe-form-2-tags.scss';
import template from './sfe-form-2-tags.component.html';

/**
 * @ngdoc component
 * @name common.sfeForm2Tags
 * @description Component for display and handling of the form.
 * @param tagTitle {String} String will be displayed in tag selector autocomplete placeholder
 * @param options {Object}
 *    @param items {Array} array of items to be displayed
 *    @param itemsCrawler {Object} contains items fetch configuration {entity<String>, method<String>}
 *    @param crawlerParams {function} as parameter accepts string entered in search autocomplete returns object. When used to filter items should return object in shape {param<String>,searchTerm<String>,type<String>}
 *    @param valueParam {String} name of parameter that contains uniq id of tag
 *    @param display {Function} gets object as parameter returns Object {text<String>, chosenIcon<Object>, chosenImage<Object>} (display fields documentation)
 *    @param dialog {Object} dialog configuration must contain entity
 *    @param create {Object} configuration for create tag mode
 *        @param crawler {Object} crawler configuration {entity<String>, method<String>}
 *        @param params {Function} gets new tag as parameter returns object that will be send in body of post request
 *        @param searchParamName {String} parameter that is used to filter search results
 *    @param filter {Function} filter function get array returns filtered array
 * @param api {Object} api object passed from form
 * @param required {Boolean}
 * @param disable {Function} returns Boolean to indicate disable status
 * @param change {Function} gets triggered on model change
 * @example
 * <sfe-form-2-tags
 *   tag-title="'Entity tags'"
 *   options="vm.options"
 *   api="vm.api"
 *   required="true"
 *   disable="vm.disable"
 *   change="vm.change"
 * ></sfe-form-2-tags>
 */

export default {
  template,
  bindings: {
    tagTitle: '<',
    options: '<',
    api: '<',
    required: '<',
    validators: '<',
    disable: '<',
    change: '<'
  },
  require: {
    model: 'ngModel'
  },
  controller: SfeForm2TagsController,
  controllerAs: 'vm',
  bindToController: true
};

SfeForm2TagsController.$inject = [
  'filterService',
  'CrawlerMethods',
  'AlertingService',
  'gettextCatalog',
  '$scope',
  'ItemSelector'
];
function SfeForm2TagsController(
  filterService,
  CrawlerMethods,
  AlertingService,
  gettextCatalog,
  $scope,
  ItemSelector
) {
  const vm = this;
  //used for md-tags name
  const randomHash = Math.random()
    .toString(36)
    .substring(2);

  vm.fetchItems = fetchItems;
  vm.transformChip = transformChip;
  vm.onTagAdded = onTagAdded;
  vm.onTagRemove = onTagRemove;

  vm.modelValues = [];
  //when options are set creates action configuration
  vm.$onChanges = changes => {
    if (changes.options) {
      vm.actions = getActions();
    }
  };

  $scope.$on('$destroy', () => {
    stopWatcher();
  });

  /**
   * @description watches for model data to come and
   * if it is set to a string or a number triggers
   * function that fetches data.
   * @function
   */
  let stopWatcher = $scope.$watch(function() {
    if (vm.model != null) {
      return vm.model.$modelValue;
    }
  }, initiateItems);

  vm.$postLink = () => {
    //set a custom name to autocomplete
    //shouldn't be the same as name of out component
    vm.inputName = vm.model.$name + randomHash;

    //custom required validator
    //when field is required it should contain
    //at least one selected tag
    const maxItems = () => {
      if (typeof vm.options.maxItems == 'number') {
        return vm.modelValues.length <= vm.options.maxItems;
      }
      return true;
    };

    const minItems = () => {
      if (typeof vm.options.minItems == 'number') {
        return vm.modelValues.length >= vm.options.minItems;
      }
      return true;
    };

    vm.model.$validators = {
      maxItems,
      minItems,
      ...vm.model.$validators
    };
  };
  /**
   * @description triggered when ngModel model value is changed.
   * if model contains only ids of items fetches and sets values
   * @function
   * @param {Array} model ngModel modelView values
   */
  async function initiateItems(model) {
    if (Array.isArray(model)) {
      const itemsToFetch = model
        .filter(item => {
          if (typeof item == 'string' || typeof item == 'number') {
            return item;
          }
        })
        .filter(onlyUnique);
      if (itemsToFetch.length > 0) {
        const fetchedItems = await getItemsOnInit(itemsToFetch);
        vm.modelValues = model.reduce((result, item) => {
          if (typeof item == 'string' || typeof item == 'number') {
            let fetchedItem = fetchedItems.find(
              fetchedItem => fetchedItem[vm.options.valueParam] == item
            );
            if (fetchedItem) {
              result = [...result, fetchedItem];
            }
          } else if (item != null) {
            result = [...result, item];
          }
          return result;
        }, []);
        vm.model.$setViewValue([...vm.modelValues]);
        $scope.$evalAsync();
        if (typeof vm.change == 'function') {
          vm.change();
        }
      } else {
        vm.modelValues = model;
      }
    }
  }

  /**
   * @memberof common.assignFilterParameters
   * @function
   * @description helper method to filter only unique items
   * @return {bool} item exist
   */
  function onlyUnique(value, index, allItems) {
    return allItems.indexOf(value) === index;
  }
  /**
   * @description fetches items.
   * @function
   * @param {Array} items array of ngModel modelValues ids
   * @return {Array}
   */
  async function getItemsOnInit(items) {
    let fetchedItems = [];
    if (Array.isArray(vm.options.items)) {
      fetchedItems = vm.options.items.filter(item => {
        return items.filter(itemId => itemId == item[vm.options.valueParam]);
      });
      //Crawler Config
    } else if (vm.options.itemsCrawler != null) {
      const method = CrawlerMethods.getMethod(vm.options.itemsCrawler);
      if (method != null) {
        let queryParams = { [vm.options.valueParam]: items };
        try {
          const { data } = await method(queryParams);
          fetchedItems = data;
        } catch (err) {
          AlertingService.Error(err);
        }
      } else {
        AlertingService.devWarn(
          'Something is wrong with itemsCrawler configuration'
        );
      }
    } else {
      AlertingService.devWarn('Items or items crawler is missing');
    }
    return fetchedItems;
  }

  /**
   * @description creates chip object.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function transformChip(tag) {
    if (angular.isObject(tag)) {
      return tag;
    }
    return {
      name: tag,
      type: 'new'
    };
  }
  /**
   * @description triggered when tag is removed
   * triggers ngModel validation and change function if exists.
   * @function
   */
  function onTagRemove() {
    vm.model.$validate();
    if (typeof vm.change == 'function') {
      vm.change();
    }
  }
  /**
   * @description triggered when a new tag is added
   * when tag has __new__ id creates a new tag id crawler configuration is given.
   * sets ngModel viewValue to a new value
   * @function
   * @param {Object} tag new tag object
   */
  async function onTagAdded(tag) {
    vm.selectedItem = null;
    vm.searchText = '';

    if (tag._id == '__new__' && vm.options.create != null) {
      if (vm.options != null && vm.options.create != null) {
        let createObject;
        if (vm.options.create.params) {
          createObject = vm.options.create.params(tag);
        } else {
          createObject = {
            [vm.options.create.searchParamName]:
              tag[vm.options.create.searchParamName]
          };
        }
        const method = CrawlerMethods.getMethod(vm.options.create.crawler);
        try {
          vm.dataIsLoading = true;
          const { data: res } = await method(createObject);
          tag._id = res._id;
        } catch (err) {
          vm.modelValues = vm.modelValues.filter(item => {
            return item._id != '__new__';
          });
          AlertingService.Error(err);
        }

        vm.dataIsLoading = false;
      } else {
        throw gettextCatalog.getString(
          'Configuration to create new tag is missing.'
        );
      }
    }
    if (typeof vm.change == 'function') {
      vm.change(vm.api);
    }
    vm.model.$setViewValue([...vm.modelValues]);
    vm.model.$validate();
  }

  /**
   * @description returns array of actions
   * to open select dialog.
   * @function
   * @return {Array}
   */
  function getActions() {
    let actions = [];
    //Add open dialog button only when dialog entity is defined
    if (
      vm.options != null &&
      vm.options.dialog != null &&
      vm.options.dialog.entity != null
    ) {
      actions.push({
        color: 'grey',
        icon: {
          type: 2,
          name: 'search'
        },
        fn: openAutocompleteDialog,
        disabledFn: vm.disable
      });
    }
    return actions;
  }

  /**
   * @description Opens select dialog and if new item selected applies its value to data model.
   * @function
   */
  async function openAutocompleteDialog() {
    const configuration = {
      filterFn: filterDialogItems,
      filterObjectFn:
        typeof vm.options.crawlerParams == 'function'
          ? bindSecondArgument(vm.options.crawlerParams, vm.api)
          : null,
      multiple: true
    };
    try {
      let results = await ItemSelector.open(
        [vm.options.dialog.entity],
        [configuration]
      );
      vm.modelValues = [...vm.modelValues, ...results];
      vm.model.$setViewValue([...vm.modelValues]);
      $scope.$evalAsync();
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }
  /**
   * @description dialog filter function.
   * first removes duplicates after that if filter function is defined in options triggers it
   * @function
   * @param {Array} items
   * @return {Array}
   */
  function filterDialogItems(items) {
    let filtered = prepareItems(items);
    if (typeof vm.options.filter == 'function') {
      filtered = vm.options.filter(filtered);
    }
    return filtered;
  }

  /**
   * @description returns array of items that will be displayed in autocomplete dropdown menu.
   * @function
   * @return {Array}
   */
  async function fetchItems() {
    vm.model.$setTouched();
    let items = [];
    //Array of items
    if (Array.isArray(vm.options.items)) {
      ///TODO naj  crawlerParams funkcija vraca paramsi za filter
      var filter = [
        {
          param: 'name',
          searchTerm: vm.searchText,
          type: 'string'
        }
      ];
      items = vm.options.items;
      items = filterService.filterDataV1(items, filter);
      //Crawler Config
    } else if (vm.options.itemsCrawler != null) {
      const method = CrawlerMethods.getMethod(vm.options.itemsCrawler);
      if (method != null) {
        let queryParams = vm.options.crawlerParams(vm.searchText, vm.api);
        try {
          const { data } = await method(queryParams);
          items = data;
        } catch (err) {
          AlertingService.Error(err);
        }
      } else {
        AlertingService.devWarn(
          'Something is wrong with itemsCrawler configuration'
        );
      }
    } else {
      AlertingService.devWarn('Items or items crawler is missing');
    }
    if (typeof vm.options.filter == 'function') {
      items = vm.options.filter(items, vm.api);
    }
    return prepareItems(items);
  }
  /**
   * @description removes duplicates and if tag selector is in create mode adds a new tag with _id = __new__.
   * @function
   * @param {Array} items original items
   * @return {Array}
   */
  function prepareItems(items) {
    let filteredItems = [...items];

    //ADD ITEM WITH _new_ id that indicated that when this item is added it should be added to DB
    if (vm.options.create != null && vm.searchText) {
      const valueDoesntExist = filteredItems.find(item => {
        if (item) {
          return item[vm.options.create.searchParamName] == vm.searchText;
        }
      });
      if (!valueDoesntExist) {
        filteredItems.unshift({
          _id: '__new__',
          [vm.options.create.searchParamName]: vm.searchText
        });
      }
    }
    //FILTER DUPLICATES THAT IS ALREADY IN THE MODEL
    if (Array.isArray(vm.modelValues)) {
      let valueParam = vm.options.valueParam || '_id';
      filteredItems = filteredItems.filter(item => {
        if (item) {
          return !vm.modelValues.find(
            selectedItem => selectedItem[valueParam] == item[valueParam]
          );
        }
      });
    }
    return filteredItems;
  }

  /**
   * @description when there are no first argument passes first argument as null and binds the rest of args
   * @function
   * @param {Function} fn
   * @param {Any} bound_args
   * @return {Function}
   */ function bindSecondArgument(fn, ...bound_args) {
    return function(...args) {
      if (args.length == 0) {
        return fn(null, ...bound_args);
      } else {
        return fn(...args, ...bound_args);
      }
    };
  }
}
