module.exports = {
  entity: 'period-intervals',
  path: 'period-intervals',
  name: {
    en: 'Period intervals',
    sl_SI: 'Časovni intervali'
  },
  networkModel: {
    entity: 'period-intervals',
    read: 'read'
  },
  selectedParamName: 'id',
  scope: 'tis_be:period-intervals',

  dialog: {
    title: {
      en: 'Select period interval',
      sl_SI: 'Izberite časovni interval'
    },
    listInfo: {
      en: 'Select period interval from the list below',
      sl_SI: 'Izberite čaosvni interval s spodnjega seznama'
    }
  }
}
