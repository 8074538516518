module.exports = {
  entity: 'billingoverview',
  path: 'billingoverview',
  name: {
    en: 'Biling overviews',
    sl_SI: 'Pregled obračunov'
  },
  networkModel: {
    entity: 'billingoverview',
    read: 'read'
  },
  scope: 'tis_be:billing-overviews',

  dialog: {
    title: {
      en: 'Select billing overview',
      sl_SI: 'Izberite pregled obračuna'
    },
    listInfo: {
      en: 'Select billing overview from the list below',
      sl_SI: 'Izberite billing overview s spodnjega seznama'
    },
    viewService: true,
    displayFields: [
      {
        field1: 'locationName',
        title: 'Location'
      },
      {
        field1: 'costCentreName',
        title: 'Cost Centre'
      }
    ]
  }
}
