LocationModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function LocationModel(ModelUtilities, CrawlerMethods) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'locations'
  };

  const networkConfigurationChildren = {
    host: 'api',
    path: 'locations/:parentId/children'
  };

  const networkConfigurationWeatherStationView = {
    host: 'view',
    path: 'dashboardcardweatherstation'
  };

  const crawlerConfiguration = {
    entity: 'locations',
    method: 'read',
    populate: 'address,status,type,costCentreLocations',
    steps: [
      {
        entity: 'cost-centre-locations',
        valueParams: ['_id'],
        queryParams: ['location'],
        method: 'custom.readWithCostCenter',
        methodType: 'addArray',
        newParamNames: ['costCentre'],
        populate: 'costCentre',
        filters: {
          isMain: true,
          isActive: true
        },
        mapping: {
          localField: '_id',
          foreignField: 'location'
        }
      }
    ]
  };

  const addressPictureMetadata = [
    {
      entity: 'addresses',
      valueParams: ['address'],
      queryParams: ['id'],
      method: 'read',
      methodType: 'add',
      newParamNames: ['addressObject']
    },
    {
      entity: 'picture-locations',
      valueParams: ['_id'],
      queryParams: ['location'],
      populate: 'picture',
      method: 'read',
      methodType: 'add',
      mapping: {
        localField: '_id',
        foreignField: 'location'
      },
      newParamNames: ['pictureLocation']
    }
  ];

  const crawlerConfigurationCRviewData = {
    entity: 'locations',
    method: 'read',
    populate: 'status,type,ownership'
  };

  const crawlerConfigurationForm = {
    entity: 'locations',
    method: 'read',
    populate:
      'address,status,type,ownership,weatherStation,locationClassification,parentId',
    steps: [
      {
        entity: 'picture-locations',
        valueParams: ['_id'],
        queryParams: ['location'],
        populate: 'picture',
        method: 'read',
        methodType: 'add',
        mapping: {
          localField: '_id',
          foreignField: 'location'
        },
        newParamNames: ['pictureLocation']
      },
      {
        entity: 'system-tag-entities',
        valueParams: ['_id'],
        queryParams: ['entityId'],
        method: 'custom.readWithTag',
        methodType: 'add',
        newParamNames: ['tags']
      }
    ]
  };

  const crawlerConfigurationItemView = {
    entity: 'locations',
    method: 'read',
    populate:
      'address,status,type,ownership,weatherStation,locationClassification,parentId',
    steps: [
      {
        entity: 'picture-locations',
        valueParams: ['_id'],
        queryParams: ['location'],
        method: 'read',
        methodType: 'add',
        mapping: {
          localField: '_id',
          foreignField: 'location'
        },
        newParamNames: ['pictureLocation']
      },
      {
        entity: 'system-tag-entities',
        valueParams: ['_id'],
        queryParams: ['entityId'],
        method: 'custom.readWithTag',
        methodType: 'add',
        newParamNames: ['tags']
      }
    ]
  };

  const crawlerConfigurationEntityDate = {
    entity: 'locations',
    method: 'read',
    steps: [
      {
        entity: 'addresses',
        valueParams: ['address'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'populateArray'
      },
      {
        entity: 'cost-centre-locations',
        valueParams: ['_id'],
        queryParams: ['location'],
        method: 'custom.readWithCostCenter',
        methodType: 'addArray',
        newParamNames: ['costCentre'],
        filters: {
          isMain: true,
          isActive: true
        },
        mapping: {
          localField: '_id',
          foreignField: 'location'
        }
      }
    ]
  };

  const crawlerConfigurationConnectedEntities = {
    entity: 'asset-locations',
    method: 'read',
    steps: [
      {
        entity: 'assets',
        valueParams: ['asset'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'addArray',
        newParamNames: ['child']
      }
    ]
  };

  function fetchAddressPictureMetadata(location) {
    return CrawlerMethods.populate(location, addressPictureMetadata);
  }

  function fetchPictureMetadata(location) {
    return CrawlerMethods.populate(location, [addressPictureMetadata[1]]);
  }

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readWithCostCenter: CrawlerMethods.crawler(crawlerConfiguration),
      readItemView: CrawlerMethods.crawler(crawlerConfigurationItemView),
      readForm: CrawlerMethods.crawler(crawlerConfigurationForm),
      readChildren: ModelUtilities.crud(
        'GET',
        networkConfigurationChildren,
        modelStorage
      ),
      readConnectedEntities: CrawlerMethods.crawler(
        crawlerConfigurationConnectedEntities
      ),
      readEntityDate: CrawlerMethods.crawler(crawlerConfigurationEntityDate),
      fetchAddressPictureMetadata: fetchAddressPictureMetadata,
      fetchPictureMetadata: fetchPictureMetadata,
      CRViewData: CrawlerMethods.crawler(crawlerConfigurationCRviewData),
      readWeatherView: ModelUtilities.crud(
        'GET',
        networkConfigurationWeatherStationView,
        modelStorage
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default LocationModel;
