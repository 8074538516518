module.exports = {
  entity: 'asset-types',
  path: 'asset-types',
  entityId: 53,
  name: {
    en: 'Asset types',
    sl_SI: 'Tipi sredstev'
  },
  networkModel: {
    entity: 'asset-types',
    read: 'read'
  },
  selectedParamName: '_id',
  scope: 'tis_be:asset-types',

  dialog: {
    title: {
      en: 'Select asset type',
      sl_SI: 'Izberite tip sredstvo'
    },
    listInfo: {
      en: 'Select asset type from the list below',
      sl_SI: 'Izberite tip sredstva s spodnjega seznama'
    }
  }
}
