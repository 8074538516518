import './reporting-editor.scss';

ReportingEditorController.$inject = [
  '$state',
  'AlertingService',
  'ReportGeneralModel',
  'MetadataService',
  'CrudToastFactory',
  'analysisQueryBuilder',
  'periodIntervalConstructor',
  'ReportModel',
  'report'
];

function ReportingEditorController(
  $state,
  AlertingService,
  ReportGeneralModel,
  MetadataService,
  CrudToastFactory,
  analysisQueryBuilder,
  periodIntervalConstructor,
  ReportModel,
  report
) {
  const vm = this;
  const reportEditorId = $state.params.id;
  let designer = {};
  vm.isPreview = true;
  vm.loaded = true;
  vm.$onInit = init;
  let REPORT_DATA;

  async function getReportData() {
    try {
      const periodInterval = periodIntervalConstructor.getFormObj(
        report.periodInterval
      );
      const result = analysisQueryBuilder.getQuery({
        analysis: report,
        analysisFilters: [],
        startDate: periodInterval.startDate,
        endDate: periodInterval.endDate,
        activeComparableColumn: report.activeComparableColumn,
        startDateMonth: periodInterval.startDate,
        endDateMonth: periodInterval.endDate
      });
      const filters = constructFilters(report.filters, report.dataset.columns);

      const postObject = { ...result.apiObject, ...filters };
      const res = await ReportGeneralModel.custom.getReportData(
        { reportId: reportEditorId },
        postObject
      );
      REPORT_DATA = res;
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  async function init() {
    getReportData();
    loadingToggle(true);
    try {
      const reportConfiguration = await ReportGeneralModel.custom.getReportSchema(
        {
          id: reportEditorId
        }
      );
      setReport(reportConfiguration, report || {});
    } catch (err) {
      AlertingService.Error(err);
    }
    loadingToggle(false);
  }

  function constructFilters(filters, datasetColumns) {
    return filters.reduce((result, filter) => {
      if (filter.filterValues && filter.filterValues.length > 0) {
        const selectedColumn = datasetColumns.find(
          set => set.rawFieldName === filter.rawFieldName
        );
        if (selectedColumn?.type == 2 /* number filter */) {
          result = {
            ...result,
            [filter.rawFieldName]: Array.isArray(filter.filterValues)
              ? filter.filterValues[0]
              : filter.filterValues
          };
        } else {
          result = {
            ...result,
            [filter.rawFieldName]: filter.filterValues
          };
        }
      }
      return result;
    }, {});
  }
  function loadingToggle(loading) {
    vm.fetchingReportPreview = loading;
    MetadataService.Loading(loading);
  }
  function setReport(reportData, reportItem) {
    report = new Stimulsoft.Report.StiReport();
    if (reportItem.stimulsoftReportTemplate) {
      report.load(reportItem.stimulsoftReportTemplate);
    } else {
      report.load(JSON.stringify(reportData));
    }
    let options = new Stimulsoft.Designer.StiDesignerOptions();
    options.height = '100%';
    options.showFileMenu = false;
    // dictionary data read only. You can still create new relations
    const permission =
      Stimulsoft.Designer.StiDesignerPermissions.View +
      Stimulsoft.Designer.StiDesignerPermissions.Create +
      Stimulsoft.Designer.StiDesignerPermissions.Modify +
      Stimulsoft.Designer.StiDesignerPermissions.Delete;
    options.dictionary.dataSourcesPermissions = permission;
    options.dictionary.dataColumnsPermissions = permission;
    options.dictionary.businessObjectsPermissions = permission;
    options.dictionary.dataConnectionsPermissions = permission;

    designer = new Stimulsoft.Designer.StiDesigner(
      options,
      'StiDesigner',
      false
    );
    designer.report = report;
    designer.onSaveReport = saveReport;
    designer.renderHtml('editor');
    designer.onPreviewReport = loadPreviewData;
  }

  async function loadPreviewData(ev) {
    ev.report.regData('', '', REPORT_DATA);
  }

  function saveReport(e) {
    const jsonReport = e.report.saveToJsonString();
    const apiObj = {
      stimulsoftReportTemplate: jsonReport
    };
    ReportModel.update(
      {
        id: reportEditorId
      },
      apiObj
    ).then(
      function() {
        const redirectObj = {
          state: 'analytics-reports-view',
          paramName: 'id',
          paramValue: reportEditorId
        };
        CrudToastFactory.toast('update', redirectObj);
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }
}

export default ReportingEditorController;
