module.exports = {
  entity: 'location-actions',
  path: 'location-actions',
  name: {
    en: 'Location action statuses',
    sl_SI: 'Statusi akcij lokacij'
  },
  networkModel: {
    entity: 'location-actions',
    read: 'read'
  },
  scope: 'tis_be:location-actions',

  dialog: {
    title: {
      en: 'Select location action',
      sl_SI: 'Izberite akcijo lokacije'
    },
    listInfo: {
      en: 'Select location action from the list below',
      sl_SI: 'Izberite akcijo lokacije s spodnjega seznama'
    }
  }
}
