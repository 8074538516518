module.exports = {
  entity: 'price-list-masters',
  path: 'price-list-masters',
  name: {
    en: 'Price list masters',
    sl_SI: 'Glavni ceniki'
  },
  networkModel: {
    entity: 'price-list-masters',
    read: 'read'
  },
  scope: 'tis_be:price-list-masters',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select master price list',
      sl_SI: 'Izberite glavni cenik'
    },
    listInfo: {
      en: 'Select master price list from the list below',
      sl_SI: 'Izberite glavni cenik s spodnjega seznama'
    }
  }
}
