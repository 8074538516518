SfeForm2ChartSettingsHelper.$inject = [
  'CrawlerMethods',
  'AlertingService',
  'gettextCatalog',
  'gettext',
  '$mdDialog',
  'TranslationService'
];
/**
 * @ngdoc service
 * @name common.SfeForm2ChartSettingsHelper
 * @description Configures the object used in <sfe-header> component.
 * @property {function} constructFormHeader - Function that configures the header object.
 */
export default function SfeForm2ChartSettingsHelper(
  CrawlerMethods,
  AlertingService,
  gettextCatalog,
  gettext,
  $mdDialog,
  TranslationService
) {
  /**
   * @memberof common.SfeForm2ChartSettingsHelper
   * @description Function that checks if values need enriching and enriches them if necessary.
   * @return {Object} Configured object
   */

  const enrichTimeseries = async values => {
    if (Array.isArray(values) && values.length > 0) {
      if (typeof values[0] === 'string') {
        try {
          const { data } = await CrawlerMethods.getMethod({
            method: 'read',
            entity: 'measurements' // WARNING WARNING THIS SHOULD BE CHANGED WHEN BACKEND CHANGES
          })({ _id: values });
          //creates an object of objects  where the item id is the key
          const dataObject = data.reduce(
            (obj, item) => ({
              ...obj,
              [item._id]: item
            }),
            {}
          );
          return values.map(id => dataObject[id]);
        } catch (err) {
          AlertingService.Error(err);
          return [];
        }
      }
      return values;
    }
    return [];
  };
  /**
   * @memberof common.SfeForm2ChartSettingsHelper
   * @description returns a configuration object for getSchedulerClassificationsConfiguration dialog
   * @param {Array} scheduleClassifications - array of all scheduler classifications
   * @param {Object} timeseries - time series
   * @return {Object}
   */
  const getSchedulerClassificationsConfiguration = (
    scheduleClassifications,
    timeseries,
    getClassificationModel,
    callback
  ) => {
    let initialValues = {};
    if (typeof getClassificationModel == 'function') {
      initialValues = getClassificationModel(timeseries._id);
    }
    let schedulerClassificationAbbreviation = '';

    if (
      timeseries.dataScheduler != null &&
      timeseries.dataScheduler.scheduleClassification
    ) {
      let schedulerClassification = TranslationService.GetCollectionById(
        'codelists.scheduleClassifications',
        timeseries.dataScheduler.scheduleClassification
      );
      if (
        schedulerClassification != null &&
        schedulerClassification.name != ''
      ) {
        schedulerClassificationAbbreviation = ` - ${schedulerClassification.name}`;
      }
    }
    let timeSeriesTitle = `${timeseries.name} ${schedulerClassificationAbbreviation}`;
    return {
      name: ' changeSchedulerForm',
      title: gettextCatalog.getString('Select schedule classification'),
      actions: [
        {
          title: gettext('Save'),
          fn: form => {
            let values = form.getValues();
            $mdDialog.hide(values);
            if (typeof callback == 'function') {
              callback(values, timeseries);
            } else {
              AlertingService.devWarn('Callback is missing');
            }
          }
        }
      ],
      fields: [
        {
          id: 'selectedSchedulers',
          title: timeSeriesTitle,
          type: {
            name: 'checkbox',
            options: {
              layout: 'row',
              display: item => {
                return item.abbreviation;
              },
              valueField: 'id',
              items: scheduleClassifications.sort((a, b) => {
                return a.order - b.order;
              })
            }
          },
          onChange: () => {},
          initialize: () => initialValues,
          disable: () => {
            return false;
          },
          validators: {
            manualRequired: {
              fn: value => {
                if (value != null) {
                  return Object.values(value).some(value => value === true);
                }
                return false;
              },
              text: gettextCatalog.getString('Select at least one')
            }
          }
        }
      ]
    };
  };
  return {
    enrichTimeseries,
    getSchedulerClassificationsConfiguration
  };
}
