/**
 * @ngdoc service
 * @name common.TangoTimeseriesGroupEditorHelperService
 * @description Opens form dialog
 */

TangoTimeseriesGroupEditorHelperService.$inject = [
  'gettext',
  'SfeForm2Dialog',
  'TranslationService',
  '$mdDialog',
  'TimeSeriesModel'
];
export default function TangoTimeseriesGroupEditorHelperService(
  gettext,
  SfeForm2Dialog,
  TranslationService,
  $mdDialog,
  TimeSeriesModel
) {
  /**
   * @description gets time series group and creates metadata model and schedule classification model.
   * @function
   * @param {string} id time series group id
   * @return {Object}
   */
  function createModelFromGroupId(group) {
    let metadata = angular.copy(group);
    let formModel = group.scheduleClassifications.map(classification => {
      let futureTimeRange = classification.defaultFilter.futureTimeRange;
      let historyTimeRange = classification.defaultFilter.historyTimeRange;
      return {
        scheduleClassification: TranslationService.GetCollectionById(
          'codelists.scheduleClassifications',
          classification.scheduleClassification
        ),
        defaultSpecifications: { isMain: classification.isMain },
        filterType: classification.defaultFilter.filterType,
        numberOfValues: classification.defaultFilter.numberOfValues,
        historyTimeRange: {
          isChecked: historyTimeRange != null
        },
        futureTimeRange: {
          isChecked: futureTimeRange != null
        },
        historyNumberOfUnits:
          historyTimeRange != null ? historyTimeRange.numberOfUnits : null,
        historyScheduleClassification:
          historyTimeRange != null
            ? historyTimeRange.scheduleClassification
            : null,
        futureNumberOfUnits:
          futureTimeRange != null ? futureTimeRange.numberOfUnits : null,
        futureScheduleClassification:
          futureTimeRange != null
            ? futureTimeRange.scheduleClassification
            : null
      };
    });
    return {
      group,
      metadata,
      formModel
    };
  }
  /**
   * @description opens dialog that allows to move the rest of time series from preview to schedule classifications.
   * @function
   * @param {Array} previewItems array of preview time series objects
   * @param {Object} model schedule classification model object {[scheduleClassificationId]: {scheduleClassification<Object>, series<Array>}}
   * @return {Object|undefined} returns updated model or null if dialog was closed
   */
  async function openMovePreviewItemsDialog(previewItems, model = {}) {
    let fields = previewItems.reduce((result, timeseries) => {
      let timeseriesIsUsedInClassification = Object.keys(model).some(key =>
        model[key].series.find(item => item._id == timeseries._id)
      );
      if (!timeseriesIsUsedInClassification) {
        result = [
          ...result,
          {
            id: 'timeseriesTitle',
            type: {
              name: 'title',
              options: {
                value: timeseries.name,
                theme: 'primary'
              }
            }
          },
          {
            id: timeseries._id,
            type: {
              name: 'checkbox',
              options: {
                layout: 'row',
                valueField: 'id',
                display: item => item.name,
                items: TranslationService.GetCollection(
                  'codelists.scheduleClassifications'
                ).sort((a, b) => a.order - b.order)
              }
            }
          }
        ];
      }
      return result;
    }, []);

    if (fields.length < 1) {
      return model;
    }

    let formConfig = {
      name: 'scheduleClassification',
      title: gettext(
        'Move time series from preview to schedule classifications'
      ),
      fields,
      actions: [
        {
          title: gettext('Next step'),
          fn: api => {
            $mdDialog.hide({ model: api.getValues() });
          }
        }
      ]
    };

    let result = await SfeForm2Dialog.open(formConfig);
    if (result !== null && typeof result == 'object' && result.model) {
      Object.keys(result.model).forEach(timeseriesId => {
        //find time series object from preview items
        let timeseriesItem = previewItems.find(
          item => item._id == timeseriesId
        );

        if (result.model[timeseriesId] != null) {
          //find schedule classifications to move time series to
          Object.keys(result.model[timeseriesId]).forEach(classificationId => {
            if (result.model[timeseriesId][classificationId] == true) {
              //when classification exists add time series
              if (model[classificationId] != null) {
                model[classificationId].series.push(timeseriesItem);
              } else {
                // when classifications doesn't exist in the model
                //add ne classification
                model = {
                  ...model,
                  [classificationId]: {
                    scheduleClassification: TranslationService.GetCollectionById(
                      'codelists.scheduleClassifications',
                      Number(classificationId)
                    ),
                    series: [timeseriesItem]
                  }
                };
              }
            }
          });
        }
      });
      return model;
    } else {
      //Dialog was canceled
      return;
    }
  }
  /**
   * @description transforms group form model object to sandbox model object.
   * @function
   * @param {Array} model array of schedule classification objects [{scheduleClassification<Object>, chartSettings<Array>}]
   * @return {Object}
   */
  function constructSandboxModelOutOfGroupFormModel(model) {
    let result = {};
    if (Array.isArray(model)) {
      result = model.reduce((result, item) => {
        let isMain =
          item.defaultSpecifications != null
            ? item.defaultSpecifications.isMain
            : false;
        let historyTimeRange =
          item.filterType == 100 && item.historyTimeRange.isChecked
            ? {
              scheduleClassification:
                  item.historyScheduleClassification != null
                    ? item.historyScheduleClassification.id
                    : null,
              numberOfUnits: item.historyNumberOfUnits
            }
            : null;
        let futureTimeRange =
          item.filterType == 100 && item.futureTimeRange.isChecked
            ? {
              scheduleClassification:
                  item.futureScheduleClassification != null
                    ? item.futureScheduleClassification.id
                    : null,
              numberOfUnits: item.futureNumberOfUnits
            }
            : null;
        return {
          ...result,
          [item.scheduleClassification.id]: {
            scheduleClassification: item.scheduleClassification,
            series: item.chartSettings,
            isMain,
            defaultFilter: {
              filterType: item.filterType,
              numberOfValues:
                item.filterType == 200 ? item.numberOfValues : null,
              historyTimeRange,
              futureTimeRange
            }
          }
        };
      }, {});
    }
    return result;
  }
  /**
   * @description returns id of default classification. if there is no default classification returns 0.
   * @function
   * @param {Array} formModel array of time series group classification models
   * @return {Number}
   */
  function getActiveClassificationFromModel(formModel = []) {
    let defaultClassification = formModel.find(
      item =>
        item.defaultSpecifications != null && item.defaultSpecifications.isMain
    );

    return defaultClassification != null &&
      defaultClassification.scheduleClassification != null
      ? defaultClassification.scheduleClassification.id
      : 0;
  }
  /**
   * @description populates preview and model timeseries and adds them to time series group classification.
   * @function
   * @param {Object} model {1:{timeSeries, color, chartType}}
   * @param {Object} previewItems [{timeSeries, color, chartType}]
   * @param {Array} formModel time series group form model
   * @return {Object}
   */
  async function constructChartSettings(model, previewItems, formModel = []) {
    let timeseriesIds = [];
    if (Array.isArray(previewItems) && previewItems.length > 0) {
      timeseriesIds = previewItems.map(item => item.timeSeries);
    }

    //GET ALL TIMESERIES
    timeseriesIds = Object.keys(model).reduce((result, classificationKey) => {
      return [
        ...result,
        ...model[classificationKey].map(item => item.timeSeries)
      ];
    }, timeseriesIds);
    //only uniq items
    timeseriesIds = timeseriesIds.filter((value, index, items) => {
      return items.indexOf(value) === index;
    });
    if (timeseriesIds.length > 0) {
      try {
        let { data } = await TimeSeriesModel.read({
          _id: timeseriesIds,
          populate: 'dataScheduler'
        });
        let previewTimeseries = constructTimeseries(previewItems, data);

        let classificationsModel = Object.keys(model).reduce(
          (result, classificationKey) => {
            let series = constructTimeseries(model[classificationKey], data);
            return {
              ...result,
              [classificationKey]: {
                scheduleClassification: TranslationService.GetCollectionById(
                  'codelists.scheduleClassifications',
                  Number(classificationKey)
                ),
                series
              }
            };
          },
          {}
        );

        formModel = formModel.map(item => {
          let chartSettings = [];
          if (
            item.scheduleClassification != null &&
            classificationsModel[item.scheduleClassification.id] != null &&
            Array.isArray(
              classificationsModel[item.scheduleClassification.id].series
            )
          ) {
            chartSettings =
              classificationsModel[item.scheduleClassification.id].series;
          }
          return {
            ...item,
            chartSettings
          };
        });
        return {
          previewTimeseries,
          classificationsModel,
          formModel
        };
      } catch (err) {
        throw err;
      }
    }
  }

  /**
   * @description matches time series ids and fetched time series.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function constructTimeseries(items, data) {
    return items.map(item => {
      let timeSeries = data.find(
        fetchedItem => fetchedItem._id == item.timeSeries
      );
      return {
        ...timeSeries,
        color: item.color,
        chartType: item.chartType
      };
    });
  }

  return {
    openMovePreviewItemsDialog,
    constructSandboxModelOutOfGroupFormModel,
    createModelFromGroupId,
    getActiveClassificationFromModel,
    constructChartSettings
  };
}
