AnalysisFilterConfigModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function AnalysisFilterConfigModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'analysis-filter-configs'
  };

  var crawlerConfiguration = {
    entity: 'analysis-filter-configs',
    method: 'read',
    steps: [
      {
        entity: 'analyses',
        valueParams: ['analysis'],
        queryParams: ['id'],
        method: 'read',
        methodType: 'add',
        newParamNames: ['analysisObject']
      }
    ]
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readAnalysis: CrawlerMethods.crawler(crawlerConfiguration)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default AnalysisFilterConfigModel;
