module.exports = {
  entity: 'opcs',
  path: 'opcs',
  name: {
    en: 'OPCS',
    sl_SI: 'OPC-ji'
  },
  networkModel: {
    entity: 'opcs',
    read: 'read'
  },
  scope: 'tis_be:opcs',

  dialog: {
    title: {
      en: 'Select OPC',
      sl_SI: 'Izberite OPC'
    },
    listInfo: {
      en: 'Select OPC from the list below',
      sl_SI: 'Izberite OPC s spodnjega seznama'
    }
  }
}
