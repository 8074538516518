LocationTimeSeriesEntityDateConfiguration.$inject = [
  'gettext',
  '$q',
  'AlertingService',
  'CrudToastFactory',
  '$state',
  'Formatting',
  'TimeSeriesLocationModel',
  'TranslationService',
  'TimeSeriesProcessingValuesModel',
  'PhysicalCollectionService',
  'gettextCatalog',
  'SchedulerModel'
];

function LocationTimeSeriesEntityDateConfiguration(
  gettext,
  $q,
  AlertingService,
  CrudToastFactory,
  $state,
  Formatting,
  TimeSeriesLocationModel,
  TranslationService,
  TimeSeriesProcessingValuesModel,
  PhysicalCollectionService,
  gettextCatalog,
  SchedulerModel
) {
  let createObject = {
    validFrom: new Date(new Date().setHours(0, 0, 0, 0)),
    validTo: null
  };

  let configuration = {
    listId: 'locationTimeseries',
    type: 'multiple',
    entity: {
      inactiveTitle: gettextCatalog.getPlural(
        3,
        'Inactive Time Series',
        'Inactive Time Series',
        {}
      ),
      title: gettextCatalog.getPlural(3, 'Time Series', 'Time Series', {})
    },
    api: {
      query: {
        entity: 'time-series-locations',
        method: 'custom.readTimeseries'
      },
      activeFilterObject: {
        isActive: true
      },
      inactiveFilterObject: {
        isActive: false
      },
      enrich: Enrich
    },
    create: {
      form: getLocationTimeSeriesFormConfig(),
      createObject: angular.copy(createObject),
      createFn: create
    },
    update: update,
    getUpdateObject: getUpdateObject,
    delete: function(item) {
      var deferred = $q.defer();
      TimeSeriesLocationModel.delete({
        id: item._id
      }).then(
        function() {
          CrudToastFactory.toast('delete');
          deferred.resolve();
        },
        function(err) {
          AlertingService.Error(err);
          deferred.reject();
        }
      );
      return deferred.promise;
    },
    title: {
      param: 'nameReadable',
      link: {
        state: 'data-time-series-view',
        params: [
          {
            valueParam: 'timeSeriesId',
            queryParam: 'id'
          }
        ]
      }
    },
    shortActions: [
      {
        type: 'edit',
        icon: 'mode_edit'
      },
      {
        icon: 'delete',
        type: 'delete'
      }
    ],
    badges: [
      {
        title: gettext('Last value'),
        type: 'physical-value',
        param: 'lastValue'
      }
    ],
    attributes: [
      {
        title: gettext('Schedule classification'),
        param: 'scheduleClassificationReadable',
        type: 'text'
      },
      {
        title: gettext('Valid from'),
        param: 'validFromReadable',
        type: 'date-short'
      },
      {
        title: gettext('Valid to'),
        param: 'validToReadable',
        type: 'date-short'
      },
      {
        title: gettext('Status'),
        param: 'timeSeriesStatusReadable',
        type: 'text'
      },
      {
        title: 'Last value at',
        param: 'lastValueTime',
        type: 'date'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  async function Enrich(item) {
    item.fetchingObject['lastValue'] = {
      fetching: true,
      success: false,
      nodata: false
    };
    item.fetchingObject['lastValueTime'] = {
      fetching: true,
      success: false,
      nodata: false
    };
    item.fetchingObject['scheduleClassificationReadable'] = {
      fetching: false,
      success: true,
      nodata: false
    };
    item.fetchingObject['validFromReadable'] = {
      fetching: false,
      success: true,
      nodata: false
    };
    item.fetchingObject['validToReadable'] = {
      fetching: false,
      success: true,
      nodata: false
    };
    item.fetchingObject['timeSeriesStatusReadable'] = {
      fetching: false,
      success: true,
      nodata: false
    };
    if (typeof item.timeSeries == 'object' && item.timeSeries != null) {
      item.nameReadable = item.timeSeries.name;
      item.timeSeriesId = item.timeSeries._id;
    } else {
      item.nameReadable = gettextCatalog.getString('Unknown');
    }

    if (
      item != null &&
      item.timeSeries != null &&
      item.timeSeries.dataScheduler != null
    ) {
      try {
        let schedulerData = await SchedulerModel.read({
          id: item.timeSeries.dataScheduler
        });
        let scheduler = schedulerData != null ? schedulerData.data : null;
        let scheduleClassification;
        if (scheduler != null) {
          scheduleClassification = TranslationService.GetCollectionById(
            'codelists.scheduleClassifications',
            scheduler.scheduleClassification
          );
        }
        item.scheduleClassificationReadable = scheduleClassification
          ? scheduleClassification.name
          : null;
        if (item != null && item.scheduleClassificationReadable == null) {
          item.fetchingObject['scheduleClassificationReadable'].nodata = true;
        }
      } catch (error) {
        AlertingService.Error(error);
      }
    } else {
      item.fetchingObject['scheduleClassificationReadable'].nodata = true;
    }

    if (
      item.hasOwnProperty('validFrom') &&
      item.validFrom !== null &&
      item.validFrom !== undefined
    ) {
      item.validFromReadable = item.validFrom;
    } else {
      item.fetchingObject['validFromReadable'].nodata = true;
    }

    if (
      item.hasOwnProperty('validTo') &&
      item.validTo !== null &&
      item.validTo !== undefined
    ) {
      item.validToReadable = item.validTo;
    } else {
      item.fetchingObject['validToReadable'].nodata = true;
    }

    if (
      item != null &&
      item.timeSeries != null &&
      item.timeSeries.timeSeriesStatus != null
    ) {
      let timeSeriesStatus = TranslationService.GetCollectionById(
        'codelists.timeSeriesStatuses',
        item.timeSeries.timeSeriesStatus
      );
      item.timeSeriesStatusReadable =
        timeSeriesStatus != null ? timeSeriesStatus.name : null;
    } else {
      item.fetchingObject['timeSeriesStatusReadable'].nodata = true;
    }

    let physicalCollection;
    let lastValueObject;
    let decimalPrecision = 0;
    if (item != null && item.timeSeries != null) {
      try {
        physicalCollection = await PhysicalCollectionService.getReadablePhysicalData(
          item.timeSeries
        );
      } catch (error) {
        AlertingService.Error(error);
      }
      try {
        lastValueObject = await TimeSeriesProcessingValuesModel.read({
          timeSeriesId: item.timeSeries._id,
          limit: 1
        });
      } catch (error) {
        AlertingService.Error(error);
      }
      if (item.timeSeries.precision != null) {
        decimalPrecision = item.timeSeries.precision;
      }
    }
    item.fetchingObject['lastValue'].fetching = false;
    item.fetchingObject['lastValueTime'].fetching = false;
    if (
      lastValueObject != null &&
      Array.isArray(lastValueObject.data) &&
      lastValueObject.data.length === 1
    ) {
      item.fetchingObject['lastValue'].success = true;
      item.fetchingObject['lastValueTime'].success = true;
      item.lastValue =
        lastValueObject.data[0].value != null
          ? Formatting.formatNumber(
            lastValueObject.data[0].value,
            decimalPrecision
          )
          : 'null';
      if (
        physicalCollection != null &&
        physicalCollection.readableMeasurementUnitItemView != null
      ) {
        item.lastValue += physicalCollection.readableMeasurementUnitItemView;
      }
      item.lastValueTime = lastValueObject.data[0].validAt;
      if (
        item != null &&
        item.timeSeries != null &&
        item.timeSeries.timeZone != null
      ) {
        item.timeZone = item.timeSeries.timeZone;
      }
    } else {
      item.fetchingObject['lastValue'].nodata = true;
      item.fetchingObject['lastValueTime'].nodata = true;
    }
  }

  function getLocationTimeSeriesFormConfig() {
    return [
      {
        name: 'timeSeries',
        edit: true,
        componentType: 'autocompleteDialog',
        configuration: {
          required: true,
          query: {
            entity: 'time-series',
            method: 'custom.populateDataScheduler'
          },
          floatingLabel: gettext('Select Time Series'),
          searchParamName: 'filter',
          entity: 'time-series',
          createRedirect: {
            state: 'data-time-series-new'
          }
        }
      },
      {
        componentType: 'datePickerFromTo',
        from: 'validFrom',
        to: 'validTo',
        valToEmpty: true,
        labelFrom: gettext('Valid from *'),
        labelTo: gettext('Valid to'),
        required: true,
        onlyStartRequired: true,
        idFrom: 'from',
        idTo: 'to'
      }
    ];
  }

  function create(obj, paramObject) {
    let deferred = $q.defer();
    let apiObj = {
      location: $state.params.id,
      validFrom: Formatting.formatDate(obj.validFrom, '00:00:00'),
      validTo: Formatting.formatDate(obj.validTo, '23:59:59'),
      timeSeries: obj.timeSeries._id
    };
    apiObj = Object.assign(apiObj, paramObject);
    TimeSeriesLocationModel.create(apiObj).then(
      function() {
        CrudToastFactory.toast(gettext('Time series successfully created!'));
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );
    return deferred.promise;
  }

  function update(obj, originalItem, paramObject) {
    let deferred = $q.defer();
    let apiObj = {
      location: $state.params.id,
      validFrom: Formatting.formatDate(obj.validFrom, '00:00:00'),
      validTo: Formatting.formatDate(obj.validTo, '23:59:59') || null,
      timeSeries: obj.timeSeries._id
    };
    apiObj = Object.assign(apiObj, paramObject);
    TimeSeriesLocationModel.update(
      {
        id: originalItem._id
      },
      apiObj
    ).then(
      function() {
        CrudToastFactory.toast(gettext('Time series successfully updated!'));
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );
    return deferred.promise;
  }

  function getUpdateObject(item) {
    let validFrom = new Date(item.validFrom);
    let validTo =
      item.validTo && new Date(item.validTo) instanceof Date
        ? new Date(item.validTo)
        : null;
    return {
      validFrom: validFrom,
      validTo: validTo,
      timeSeries: item.timeSeries._id
    };
  }

  return configuration;
}
export default LocationTimeSeriesEntityDateConfiguration;
