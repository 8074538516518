import { RouteManager, gettext } from '../routes';

import ManualInputView from './pages/manual-input/manual-input.view.html';
import ManualInputFormView from './pages/manual-input-form/manual-input-form.view.html';
import SfeListView from '../common/components/sfe-list/sfe-list.view.html';
import NewFormBasicView from '../common/views/new-form-basic.view.html';
import ItemHeaderView from '../common/views/item-header.view.html';
import TangoItemView from '../common/components/tango-item/tango-item.view.html';
import TimeSeriesGroupSandboxView from './pages/time-series-groups/time-series-group-sandbox.view.html';
import NewTimeSeriesTemplate from './pages/time-series/new-time-series.view.html';

loadEntity.$inject = ['$transition$', 'StateResolveService'];
function loadEntity($transition$, StateResolveService) {
  return StateResolveService.fetchEntityForView($transition$);
}

loadCharts.$inject = ['loadAssets'];
function loadCharts(loadAssets) {
  return loadAssets(['highcharts']);
}

loadMathCharts.$inject = ['loadAssets'];

function loadMathCharts(loadAssets) {
  return loadAssets(['highcharts', 'math']);
}
loadMath.$inject = ['loadAssets'];

function loadMath(loadAssets) {
  return loadAssets(['math']);
}

let DataRouteManager = new RouteManager();
DataRouteManager
  // DASHBOARD for DATA MODULE
  .state('data', {
    url: '/data',
    isMainMenuItem: true,
    icon: 'timeline',
    menuIndex: 7,
    pageTitle: gettext('Data'),
    templateUrl: 'modules/data/views/data-dashboard.view.html',
    controller: 'DataDashboardController',
    controllerAs: 'vm',
    theme: 'tango',
    redirectTo: 'data-data-points-list',
    accessScopeFe: 'tis_fe:data-module',
    accessScopeBe: 'tis_fe:data-module'
  })
  .state('data-time-series-list', {
    url: '/data/time-series/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Time Series'),
    icon: 'radio_button_checked',
    parentState: 'data',
    theme: 'tango',
    role: 'petrol_be:time-series/list',
    accessScopeFe: 'tis_fe:time-series',
    accessScopeBe: 'tis_be:time-series'
  })
  .state('data-time-series-group-list', {
    url: '/data/time-series-group/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Time Series Groups'),
    icon: 'radio_button_checked',
    parentState: 'data',
    theme: 'tango',
    role: 'petrol_be:time-series-groups/list',
    accessScopeFe: 'tis_fe:time-series-groups',
    accessScopeBe: 'tis_be:time-series-groups'
  })
  // 7. MANUAL INPUTS
  .state('data-manual-input-list', {
    url: '/data/manual-input/list',
    template: SfeListView,
    isSubMenuItem: true,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Manual Input'),
    icon: 'people',
    parentState: 'data',
    theme: 'tango',
    accessScopeFe: 'tis_fe:manual-input'
  })
  // 8. SCHEDULES
  .state('data-schedules-list', {
    url: '/data/schedules/list',
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    isSubMenuItem: true,
    pageTitle: gettext('Schedules'),
    icon: 'schedule',
    parentState: 'data',
    theme: 'tango',
    accessScopeFe: 'tis_fe:schedulers',
    accessScopeBe: 'tis_be:schedulers'
  })

  // SANDBOX
  .state('data-time-series-groups-sandbox', {
    url: '/data/time-series-groups/sandbox?groupId?query',
    template: TimeSeriesGroupSandboxView,
    controller: 'TimeSeriesGroupSandboxController',
    controllerAs: 'vm',
    pageTitle: gettext('Time Series Group Sandbox'),
    icon: 'radio_button_checked',
    iconMode: 'add',
    parentState: 'data',
    theme: 'tango',
    role: 'petrol_be:time-series-groups/create',
    accessScopeBe: 'tis_be:time-series-groups',
    accessScopeFe: 'tis_fe:time-series-groups',
    mode: 'read',
    entity: 'time-series-groups',
    resolve: {
      timeSeriesGroup: loadEntity,
      loadScripts: loadMathCharts
    }
  })
  .state('data-time-series-view', {
    url: '/data/time-series/{id}',
    template: TangoItemView,
    controller: 'TangoItemController',
    controllerAs: 'vm',
    pageTitle: gettext('Time Series'),
    parentState: 'data',
    theme: 'tango',
    accessScopeFe: 'tis_fe:time-series',
    accessScopeBe: 'tis_be:time-series',
    mode: 'read',
    entity: 'time-series',
    resolve: {
      loadEntity,
      loadScripts: loadMathCharts
    }
  })

  .state('data-time-series-new', {
    url: '/data/time-series/new',
    isSubMenuItem: true,
    template: NewTimeSeriesTemplate,
    controller: 'NewTimeSeriesController',
    controllerAs: 'vm',
    pageTitle: gettext('New Time Series'),
    icon: 'timer',
    iconMode: 'add',
    parentState: 'data',
    params: {
      item: null
    },
    theme: 'tango',
    accessScopeFe: 'tis_fe:time-series',
    accessScopeBe: 'tis_be:time-series',
    mode: 'create',
    resolve: {
      loadScripts: loadMath
    }
  })
  // FORM for NEW SCHEDULER
  .state('data-schedules-new', {
    url: '/data/schedules/new',
    isSubMenuItem: true,
    template: NewFormBasicView,
    controller: 'NewSchedulerFormController',
    controllerAs: 'vm',
    pageTitle: gettext('New Schedule'),
    icon: 'timer',
    iconMode: 'add',
    parentState: 'data',
    params: {
      item: null
    },
    theme: 'tango',
    accessScopeFe: 'tis_fe:schedulers',
    accessScopeBe: 'tis_be:schedulers',
    mode: 'create',
    resolve: {
      scheduler: () => ({})
    }
  })
  // VIEW of SCHEDULER
  .state('data-schedules-view', {
    url: '/data/schedules/{id}',
    template: ItemHeaderView,
    controller: 'SchedulerController',
    controllerAs: 'vm',
    pageTitle: gettext('Schedule'),
    parentState: 'data',
    theme: 'tango',
    accessScopeFe: 'tis_fe:schedulers',
    accessScopeBe: 'tis_be:schedulers',
    mode: 'read',
    entity: 'schedulers',
    resolve: {
      scheduler: loadEntity
    }
  })
  // FORM for EDIT SCHEDULER
  .state('data-schedules-edit', {
    url: '/data/schedules/{id}/edit',
    template: NewFormBasicView,
    controller: 'NewSchedulerFormController',
    controllerAs: 'vm',
    parentState: 'data',
    theme: 'tango',
    accessScopeFe: 'tis_fe:schedulers',
    accessScopeBe: 'tis_be:schedulers',
    mode: 'update',
    entity: 'schedulers',
    resolve: {
      scheduler: loadEntity
    }
  })

  .state('data-manual-input-view', {
    url: '/data/manual-input/{id}',
    template: ManualInputView,
    controller: 'ManualInputController',
    controllerAs: 'vm',
    parentState: 'data',
    theme: 'tango',
    accessScopeFe: 'tis_fe:manual-input',
    mode: 'read',
    entity: 'time-series-manual-input-forms',
    resolve: {
      manualInput: loadEntity
    }
  })
  .state('data-manual-input-form', {
    url: '/data/manual-input-form/{id}',
    template: ManualInputFormView,
    controller: 'ManualInputFormController',
    controllerAs: 'vm',
    parentState: 'data',
    theme: 'tango',
    accessScopeFe: 'tis_fe:manual-input',
    mode: 'read',
    resolve: {
      manualInput: loadEntity
    },
    entity: 'time-series-manual-input-forms'
  });

export default DataRouteManager.routes;
