DomainAttributeModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function DomainAttributeModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'domain-attributes'
  };

  var networkConfigurationAllLang = {
    host: 'api',
    path: 'domain-attributes',
    allLang: true
  };

  var crawlerConfiguration = {
    entity: 'domain-attributes',
    method: 'read',
    steps: [
      {
        entity: 'domains',
        method: 'read',
        valueParams: ['domain'],
        queryParams: ['_id'],
        methodType: 'populateArray'
      }
    ]
  };

  var itemViewCrawler = {
    entity: 'domain-attributes',
    method: 'read',
    steps: [
      {
        entity: 'domains',
        method: 'read',
        valueParams: ['domain'],
        queryParams: ['_id'],
        methodType: 'populateArray'
      },
      {
        entity: 'domain-fields',
        method: 'read',
        valueParams: ['domain'],
        queryParams: ['domainId'],
        methodType: 'add',
        newParamNames: ['fields']
      }
    ]
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readWithDomains: CrawlerMethods.crawler(crawlerConfiguration),
      readItemView: CrawlerMethods.crawler(itemViewCrawler),
      readAllLang: ModelUtilities.crud(
        'GET',
        networkConfigurationAllLang,
        modelStorage
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default DomainAttributeModel;
