PriceListItemsController.$inject = [
  '$state',
  'PriceListDetailItemsConfiguration'
];

function PriceListItemsController($state, PriceListDetailItemsConfiguration) {
  var vm = this;
  this.$onInit = function() {
    var configuration = {
      ...PriceListDetailItemsConfiguration
    };
    const createIndexButton = vm.configuration.buttons.findIndex(
      item =>
        item.link && item.link.state === 'invoices-price-lists-detail-item-new'
    );
    configuration.buttons[createIndexButton].link = {
      state: 'invoices-price-lists-detail-item-new',
      params: [
        {
          queryParam: 'priceListDetailId',
          value: vm.priceListDetailId
        }
      ]
    };
    vm.configuration = configuration;

    vm.filter = {
      priceListDetail: vm.priceListDetailId
    };
  };
}

export default PriceListItemsController;
