module.exports = {
  entity: 'opcTypes',
  path: 'opcTypes',
  name: {
    en: 'OPC types',
    sl_SI: 'Tipi OPC'
  },
  codelist: 'opcTypes',
  scope: 'tis_be:opcTypes',
  dialog: {
    title: {
      en: 'Select OPC types',
      sl_SI: 'Izberite tip OPC'
    },
    listInfo: {
      en: 'Select OPC type from the list below',
      sl_SI: 'Izberite tip OPC s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      },
      {
        field1: 'description',
        type: 'string'
      }
    ],
    codelist: 'opcTypes'
  }
}
