TimeSeriesMeasuringpointModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function TimeSeriesMeasuringpointModel(ModelUtilities, CrawlerMethods) {
  const modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'time-series-measuringpoints'
  };

  const crawlerConfiguration = {
    entity: 'time-series-measuringpoints',
    method: 'read',
    steps: [
      {
        entity: 'measuringpoints',
        method: 'custom.list',
        valueParams: ['measuringpoint'],
        queryParams: ['_id'],
        methodType: 'populateArray'
      }
    ]
  };

  const crawlerConfigurationReadTimeseries = {
    entity: 'time-series-measuringpoints',
    method: 'read',
    populate: 'timeSeries'
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readMeasuringpoint: CrawlerMethods.crawler(crawlerConfiguration),
      readTimeseries: CrawlerMethods.crawler(crawlerConfigurationReadTimeseries)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default TimeSeriesMeasuringpointModel;
