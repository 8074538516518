module.exports = {
  entity: 'measurements',
  path: 'measurements',
  entityId: 1,
  name: {
    en: 'Measurements',
    sl_SI: 'Meritve'
  },
  networkModel: {
    entity: 'measurements',
    read: 'read'
  },
  scope: 'tis_be:measurements',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select measurement',
      sl_SI: 'Izberite meritev'
    },
    listInfo: {
      en: 'Select measurement from the list below',
      sl_SI: 'Izberite meritev s spodnjega seznama'
    }
  },
  sfeTree: {
    // parent config se ne uporablja, tako da ga bo treba uredit takrat, ko se bo.
    showConnectedEntities: false,
    route: 'measurements',
    parentEntity: 'assets',
    parentKey: 'parentId',
    childKey: 'parentId',
    icon: 'measurement',
    childrenConfiguration: []
  }
}
