module.exports = {
  entity: 'asset-statuses',
  path: 'asset-statuses',
  name: {
    en: 'Asset statuses',
    sl_SI: 'Statusi Sredstev'
  },
  networkModel: {
    entity: 'asset-statuses',
    read: 'read'
  },
  selectedParamName: 'id',
  scope: 'tis_be:asset-statuses',

  dialog: {
    title: {
      en: 'Select asset status',
      sl_SI: 'Izberite status sredstvo'
    },
    listInfo: {
      en: 'Select asset status from the list below',
      sl_SI: 'Izberite status sredstva s spodnjega seznama'
    }
  }
}
