module.exports = {
  entity: 'assets',
  path: 'assets',
  entityId: 43,
  name: {
    en: 'Assets',
    sl_SI: 'Sredstva'
  },
  networkModel: {
    entity: 'assets',
    read: 'read'
  },
  scope: 'tis_be:assets',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select asset',
      sl_SI: 'Izberite sredstvo'
    },
    listInfo: {
      en: 'Select asset from the list below',
      sl_SI: 'Izberite sredstvo s spodnjega seznama'
    }
  },
  selectTree: {
    parent: 'parentId',
    displayFields: ['name'],
    selectedParamName: '_id'
  },
  sfeTree: {
    // parent config se ne uporablja, tako da ga bo treba uredit takrat, ko se bo.
    showConnectedEntities: false,
    route: 'assets',
    parentEntity: 'assets',
    parentKey: 'parentId',
    childKey: 'parentId',
    icon: 'asset',
    childrenTreeConfiguration: [
      {
        type: 'simple',
        route: 'assets',
        icon: 'asset'
      }
    ],
    childrenConfiguration: [
      {
        type: 'simple',
        usedIn: ['everywhere'],
        route: 'assets',
        childKey: 'parentId',
        icon: 'asset',
        existenceParam: 'hasChild'
      }
    ]
  }
}
