/*
  var crawlerConfiguration = {
  entity: 'entityName',
  method: 'methodName',
  steps: [
    {
      entity: '',
      valueParams: [],
      queryParams: [],
      method: '',
      methodType: '' (populate, add, populateArray, addArray),
      newParamNames: [] only for add method types
    }]
*/
AssetLocationModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function AssetLocationModel(ModelUtilities, CrawlerMethods) {
  let modelStorage = {};

  const networkConfiguration = {
    host: 'api',
    path: 'asset-locations'
  };

  const crawlerConfiguration = {
    entity: 'asset-locations',
    method: 'read',
    steps: [
      {
        entity: 'locations',
        valueParams: ['location'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'addArray',
        newParamNames: ['location']
      }
    ]
  };

  const crawlerConfigurationEntityDate = {
    entity: 'asset-locations',
    method: 'read',
    populate: 'location',
    steps: [
      {
        entity: 'addresses',
        valueParams: ['location.address'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'populateArray'
      }
    ]
  };
  const crawlerConfigurationPopulateAsset = {
    entity: 'asset-locations',
    method: 'read',
    steps: [
      {
        entity: 'assets',
        valueParams: ['asset'],
        queryParams: ['_id'],
        method: 'custom.list',
        methodType: 'populateArray'
      }
    ]
  };

  const crawlerConfigurationPopulateLocation = {
    entity: 'asset-locations',
    method: 'read',
    populate: 'location'
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readLocation: CrawlerMethods.crawler(crawlerConfiguration),
      readLocationAddressCostCentre: CrawlerMethods.crawler(
        crawlerConfigurationEntityDate
      ),
      readPopulateAsset: CrawlerMethods.crawler(
        crawlerConfigurationPopulateAsset
      ),
      readPopulateLocation: CrawlerMethods.crawler(
        crawlerConfigurationPopulateLocation
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default AssetLocationModel;
