import './sfe-drag-and-drop-list.scss';
import template from './sfe-drag-and-drop-list.directive.html';

export default function sfeDragAndDropList() {
  var directive = {
    restrict: 'E',
    template,
    scope: {
      available: '=?',
      selected: '=?',
      displayFields: '='
    },
    link: linkFn,
    controller: sfeDragAndDropListController,
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;

  function linkFn() {}
}

sfeDragAndDropListController.$inject = [];

function sfeDragAndDropListController() {}
