/**
 * @ngdoc service
 * @name core.TranslationService
 * @description Translates strings to selected language.
 * @property {function} Initiate - See method Initiate
 * @property {function} GetCollection - See method GetCollection
 * @property {function} GetCollectionById - See method GetCollectionById
 */
TranslationService.$inject = [
  'LocalizationService',
  'translationsConstant',
  'codelistsConstant'
];
function TranslationService(
  LocalizationService,
  translationsConstant,
  codelistsConstant
) {
  var translationBook;
  /**
   * @memberof core.TranslationService
   * @description Initializes the translationBook.
   */
  function Initiate() {
    translationBook = {};
    translationBook.codelists = CodelistTranslator(
      translationsConstant.codelists
    );
  }
  /**
   * @memberof core.TranslationService
   * @description Returns the collection based on its entry.
   * @param {string} entry Name of entity to search for.
   * @return {Array} Array of translations.
   */
  function GetCollection(entry) {
    if (typeof entry !== 'undefined') {
      var pathArray = entry.split('.');
      var result = translationBook;
      if (!result) {
        Initiate();
        GetCollection(entry);
      } else {
        pathArray.forEach(function(pathBit) {
          if (result[pathBit]) {
            result = result[pathBit];
          } else {
            result = [];
          }
        });
        return result;
      }
    } else {
      return translationBook;
    }
  }

  /**
   * @memberof core.TranslationService
   * @description Returns translation object based on its entry and entry's id.
   * @param {string} entry Name of entity to search for.
   * @param {string} id Element's id.
   * @return {Object} Translated element.
   */
  function GetCollectionById(entry, id) {
    var collection = GetCollection(entry);
    if (collection) {
      return collection.find(function(item) {
        return item.id === id;
      });
    } else {
      return null;
    }
  }

  /**
   * @description Returns translation of the entry.
   * @function
   * @param {string} entry Entry to be translated.
   * @return {string} Translated entry.
   */
  function TranslateEntry(entry) {
    var result = JSON.parse(JSON.stringify(translationsConstant.translations));
    var entryPath = entry.split('.');
    entryPath.forEach(function(pathBit) {
      if (result && result[pathBit]) {
        result = result[pathBit];
      } else {
        result = null;
      }
    });
    if (result && typeof result === 'object' && result.lang) {
      result = result.lang[LocalizationService.GetSelectedLang().code];
    } else {
      result = null;
    }
    return result;
  }

  /**
   * @description Returns array of translations.
   * @function
   * @param {Object} codelistsBindings Object containing bindings of elements to be translated.
   * @return {Array} Array of translations.
   */
  function CodelistTranslator(codelistsBindings) {
    var codelists = JSON.parse(JSON.stringify(codelistsConstant));
    var translationAnalog;
    Object.keys(codelists).forEach(function(codelistKey) {
      // skip untranslated codelists
      if (codelistsBindings[codelistKey]) {
        codelists[codelistKey].forEach(function(entry) {
          translationAnalog = codelistsBindings[codelistKey].find(function(
            binding
          ) {
            return binding.id === entry.id;
          });
          if (translationAnalog) {
            Object.keys(entry).forEach(function(entryKey) {
              if (entryKey !== 'id' && translationAnalog[entryKey]) {
                entry[entryKey] = TranslateEntry(translationAnalog[entryKey]);
              }
            });
          }
        });
      }
    });
    return codelists;
  }

  return {
    Initiate: Initiate,
    GetCollection: GetCollection,
    GetCollectionById: GetCollectionById
  };
}

export default TranslationService;
