EnergyManagementGroupModel.$inject = ['ModelUtilities', 'CrawlerMethods'];

function EnergyManagementGroupModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'energy-management-groups'
  };

  var networkConfigurationAllLang = {
    host: 'api',
    path: 'energy-management-groups',
    allLang: true
  };

  var crawlerConfiguration = {
    entity: 'energy-management-groups',
    method: 'read',
    steps: [
      {
        entity: 'energy-source-types',
        valueParams: ['energySourceType'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'populateArray'
      }
    ]
  };

  var crawlerConfigurationList = {
    entity: 'energy-management-groups',
    method: 'read',
    populate: 'energySourceType'
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readAllLang: ModelUtilities.crud(
        'GET',
        networkConfigurationAllLang,
        modelStorage
      ),
      readItemView: CrawlerMethods.crawler(crawlerConfiguration),
      list: CrawlerMethods.crawler(crawlerConfigurationList)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default EnergyManagementGroupModel;
