import actionTypes from '../action/times-series-configurations.type';

function TimeSeriesConfigurationsReducer(state = {}, action) {
  switch (action.type) {
  case actionTypes.TIME_SERIES_CONFIGURATIONS_LIST: {
    return {
      ...state,
      list: {
        ...state.list,
        [action.payload.timeSeriesId]: action.payload.result
      }
    };
  }
  case actionTypes.TIME_SERIES_CONFIGURATIONS_ADD: {
    let list = { ...state.list };
    let timeSeriesConfigurationData = convertFlowType(
      action.payload.result.data
    );
    list[action.payload.result.data.timeSeries].data.push(
      timeSeriesConfigurationData
    );
    return {
      ...state,
      list
    };
  }
  case actionTypes.TIME_SERIES_CONFIGURATIONS_UPDATE: {
    let id = action.payload.query.id;
    let list = { ...state.list };
    let timeSeriesId = action.payload.query.timeSeriesId;
    let timeSeriesConfigurationData = convertFlowType(
      action.payload.result.data
    );
    list[timeSeriesId].data.forEach((config, index, data) => {
      if (config._id === id) {
        data[index] = { ...config, ...timeSeriesConfigurationData };
        return;
      }
    });
    return {
      ...state,
      list
    };
  }
  default:
    return state;
  }
}

/**
 * @description temporary converter from flowType name to flowType id.
 * @function
 * @param {Object} data
 * @return {Object} data
 */
const convertFlowType = data => {
  if (typeof data.flowRef === 'string') {
    switch (data.flowRef) {
    case 'ExternalReaderFlow':
      data.flowRef = 100;
      break;
    case 'ExternalWriterFlow':
      data.flowRef = 200;
      break;
    case 'CalculatedFlow':
      data.flowRef = 300;
      break;
    case 'MtAnalysisFlow':
      data.flowRef = 320;
      break;
    case 'MaintenanceFlow':
      data.flowRef = 500;
      break;
    }
  }
  return data;
};

export default TimeSeriesConfigurationsReducer;
