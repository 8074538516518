import template from '../pages/tagSelector/sfe-tag-picker.dialog.html';

TagSelector.$inject = ['$mdDialog'];
/**
 * @ngdoc service
 * @name common.TagSelector
 * @description opens datasource tag selector dialog.
 * @property {function} open
 */
export default function TagSelector($mdDialog) {
  /**
   * @memberof TagSelector.open
   * @description  opens tangoAgentConnectionConfig tag selector dialog and resolves to selected tag.
   * @param {string} tangoAgentConnectionConfigId id of tangoAgentConnectionConfig to filter tags
   * @param {event} ev event
   * @return {Promise}
   */
  function open(tangoAgentConnectionConfigId, ev) {
    return $mdDialog.show({
      controller: 'TagSelectorController',
      controllerAs: 'vm',
      template,
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      locals: {
        tangoAgentConnectionConfigId: tangoAgentConnectionConfigId
      }
    });
  }
  return {
    open: open
  };
}
