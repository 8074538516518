import template from './base-line-target-function.view.html';
import './base-line-target-function.scss';
BaseLineTargetFunctionService.$inject = ['$mdDialog'];
/**
 * @ngdoc service
 * @name data.BaseLineTargetFunctionService
 * @description
 * @property {function} open - opens mt analysis flow baseline target form
 */
function BaseLineTargetFunctionService($mdDialog) {
  /**
   * @description opens dialog with form to edit.
   * @function
   * @param {Object} mtFlow that should contain new/updated baseline
   * @param {Object} baseline baseline to update
   * @return {Object} contains new baseline object
   */
  async function open(mtFlow, baseline) {
    return $mdDialog
      .show({
        controller: DialogController,
        controllerAs: 'vm',
        template,
        parent: angular.element(document.body),
        locals: {
          mtFlow,
          baseline
        }
      })
      .catch(angular.noop);
  }
  return {
    open
  };
}
DialogController.$inject = [
  'mtFlow',
  'baseline',
  'gettext',
  '$timeout',
  'MtValues',
  'sfeMtAnalysisService',
  '$scope',
  '$mdDialog',
  'AlertingService',
  'StandardUtils',
  'gettextCatalog'
];
function DialogController(
  mtFlow,
  baseline,
  gettext,
  $timeout,
  MtValues,
  sfeMtAnalysisService,
  $scope,
  $mdDialog,
  AlertingService,
  StandardUtils,
  gettextCatalog
) {
  let vm = this;

  //FORM APIS
  vm.baseLineApi = {};
  vm.targetApi = {};

  //FORM HEADER
  vm.header = {
    title: gettext('Create new base line target function'),
    isDialog: true,
    actions: [
      {
        icon: {
          name: 'close',
          type: 2
        },
        cancel: true
      }
    ]
  };

  //FROM CONFIGURATIONS
  vm.baseLineConfig = getBaseLineFormConfiguration(baseline);
  vm.targetConfig = getTargetFromConfiguration(baseline);

  $timeout(() => {
    vm.baseLineApi.revalidate();
  });
  //CONSTRUCT BASELINE K IN N USING FROM AND TO DATA
  vm.baselineActions = [
    {
      title: gettext('Calculate baseline'),
      /**
       * @description creates baseline equation out of from/to data.
       * @function
       */
      fn: async () => {
        const { xFrom, xTo } = vm.baseLineApi.getValues();

        const { pairs: values } = await MtValues.getValues(mtFlow, 0, 0);

        const filteredValues = filterValues(values, xFrom, xTo);
        try {
          const regression = await getRegression(filteredValues);
          vm.baselineEquation = regression.string;

          const baseLineK = regression.equation[0];
          const baseLineN = regression.equation[1];

          vm.targetApi.setValue('baseLineK', baseLineK);
          vm.targetApi.setValue('baseLineN', baseLineN);

          $scope.$applyAsync();
        } catch (err) {
          AlertingService.Error(err);
        }
      },
      /**
       * @description disables button when baseline form (FROM/TO) is not valid.
       * @function
       */
      disabledFn: () => {
        if (typeof vm.baseLineApi.validity == 'function') {
          return !vm.baseLineApi.formValidity();
        }
      }
    }
  ];
  //CONFIGURATIONS FOR ADD LIMIT BUTTONS
  const addUpperLimit = [
    {
      title: gettextCatalog.getString('Add Upper Limit'),
      /**
       * @description adds new limit configuration.
       * @function
       */
      fn: () => {
        vm.limits.upper.limits.push({
          alarms: [],
          api: {},
          config: getLimitForm(true)
        });
      }
    }
  ];

  const addLowerLimit = [
    {
      title: gettextCatalog.getString('Add Lower Limit'),
      /**
       * @description adds new limit configuration.
       * @function
       */
      fn: () => {
        vm.limits.lower.limits.push({
          alarms: [],
          api: {},
          config: getLimitForm(false)
        });
      }
    }
  ];

  //INITIALIZE CREATE FORM WHEN BASELINE DOESN'T EXIST
  if (baseline == null) {
    vm.baselineEquation = sfeMtAnalysisService.getEquationString(0, 0);
    vm.targetEquation = sfeMtAnalysisService.getEquationString(0, 0);

    vm.limits = {
      upper: {
        title: gettext('Upper Limits'),
        isUpper: true,
        limits: [],
        addLimitAction: addUpperLimit
      },
      lower: {
        title: gettext('Lower Limits'),
        isUpper: false,
        limits: [],
        addLimitAction: addLowerLimit
      }
    };
  } else {
    //INITIALIZE EDIT FORM
    vm.baselineEquation = sfeMtAnalysisService.getEquationString(
      baseline.baseLineK,
      baseline.baseLineN
    );
    vm.targetEquation = sfeMtAnalysisService.getEquationString(
      baseline.targetK,
      baseline.targetN
    );

    let upperLimits = constructUpdateFormLimitAlarms(
      true,
      baseline.upperLimits
    );
    let lowerLimits = constructUpdateFormLimitAlarms(
      false,
      baseline.lowerLimits
    );
    vm.limits = {
      upper: {
        title: gettext('Upper Limits'),
        isUpper: true,
        limits: upperLimits,
        addLimitAction: addUpperLimit
      },
      lower: {
        title: gettext('Lower Limits'),
        isUpper: false,
        limits: lowerLimits,
        addLimitAction: addLowerLimit
      }
    };
  }

  vm.limitKeys = Object.keys(vm.limits);
  /**
   * @description adds new alarm configuration to selected limit configuration.
   * @function
   * @param {Boolean} isUpper defines what kind configuration should be added Upper/Lower limit
   * @param {Object} limit selected limit configuration
   */
  vm.addAlarm = (isUpper, limit) => {
    limit.alarms.push({
      config: getAlarmForm(isUpper),
      api: {}
    });
  };
  /**
   * @description removes alarm from selected limit configuration.
   * @function
   * @param {Object} limit selected limit configuration
   * @param {Number} index alarm index
   */
  vm.removeAlarm = (limit, index) => {
    limit.alarms.splice(index, 1);
  };
  /**
   * @description removes limit from selected limits array.
   * @function
   * @param {Array} limits selected limit array
   * @param {Number} index limit index
   */
  vm.removeLimit = (limits, index) => {
    limits.splice(index, 1);
  };
  /**
   * @description triggered on baseline K/N change sets new equation value.
   * @function
   * @param {Object} api form api
   */
  const resetBaseLineEquation = async api => {
    await $timeout();
    const { baseLineK, baseLineN } = api.getValues();
    vm.baselineEquation = sfeMtAnalysisService.getEquationString(
      baseLineK,
      baseLineN
    );
    resetTargetEquation(api);
    $scope.$applyAsync();
  };
  /**
   * @description triggered on target or baseline K/N change sets new target equation value.
   * @function
   * @param {Object} api form api
   */
  const resetTargetEquation = async api => {
    await $timeout();
    const { factorK, factorN, baseLineK, baseLineN } = api.getValues();

    const targetK = baseLineK * factorK;

    const targetN = baseLineN * factorN;
    vm.targetEquation = sfeMtAnalysisService.getEquationString(
      targetK,
      targetN
    );
    $scope.$applyAsync();
  };
  /**
   * @description creates update form limit alarm configuration.
   * @function
   * @param {Boolean} isUpper indicates what limit is created upper/lower
   * @param {Array} limits limits received from BE [{limit: <Number>, Alarms: [<String>]}]
   * @return {Object}
   */
  function constructUpdateFormLimitAlarms(isUpper, limits) {
    if (Array.isArray(limits)) {
      return limits.map(item => {
        let alarms = [];
        if (Array.isArray(item.alarms)) {
          alarms = item.alarms.map(alarmId => {
            return {
              config: getAlarmForm(isUpper, alarmId),
              api: {}
            };
          });
        }
        return {
          config: getLimitForm(isUpper, item),
          api: {},
          alarms
        };
      });
    }
    return [];
  }
  /**
   * @description returns target form configuration.
   * baseLineK
   * baseLineN
   * factorK
   * factorN
   * @function
   * @param {Object} baseline passed when update from is being created ti initialize start values
   * @return {Object}
   */
  function getTargetFromConfiguration(baseline) {
    return {
      name: 'targetForm',
      actions: [],
      fields: [
        {
          id: 'baselinetitle',
          type: {
            name: 'title',
            options: {
              value: gettextCatalog.getString('Base line'),
              theme: 'primary'
            }
          }
        },
        {
          id: 'baseLineK',
          title: gettext('K'),
          type: {
            name: 'text',
            options: {
              type: 'numerical'
            }
          },
          initialize: () => (baseline != null ? baseline.baseLineK : 0),
          width: 6,
          required: true,
          onChange: api => {
            resetBaseLineEquation(api);
          }
        },
        {
          id: 'baseLineN',
          title: gettext('N'),
          type: {
            name: 'text',
            options: {
              type: 'numerical'
            }
          },
          initialize: () => (baseline != null ? baseline.baseLineN : 0),
          width: 6,
          onChange: api => {
            resetBaseLineEquation(api);
          },
          required: true
        },
        {
          id: 'targetTitle',
          type: {
            name: 'title',
            options: {
              value: gettextCatalog.getString('Target'),
              theme: 'primary'
            }
          }
        },
        {
          id: 'factorK',
          title: gettext('Factor K'),
          type: {
            name: 'text',
            options: {
              type: 'numerical'
            }
          },
          initialize: () => {
            return baseline != null && baseline.baseLineK != 0
              ? StandardUtils.round(baseline.targetK / baseline.baseLineK, 4)
              : 1;
          },
          width: 6,
          required: true,
          onChange: api => {
            resetTargetEquation(api);
          }
        },
        {
          id: 'factorN',
          title: gettext('Factor N'),
          type: {
            name: 'text',
            options: {
              type: 'numerical'
            }
          },
          initialize: () =>
            baseline != null && baseline.baseLineN != 0
              ? StandardUtils.round(baseline.targetN / baseline.baseLineN, 4)
              : 1,
          width: 6,
          onChange: api => {
            resetTargetEquation(api);
          },
          required: true
        }
      ]
    };
  }
  /**
   * @description validates input interval and existing intervals .
   * @function
   * @param {Object}api from api
   * @return {Boolean}
   */
  function validateIntervalOverlap(_, __, api) {
    const values = api.getValues();
    if (Array.isArray(mtFlow.targetFunctions)) {
      let sortedTargetFunctions = mtFlow.targetFunctions.sort((a, b) => {
        return a.xFrom - b.xFrom;
      });
      if (typeof values == 'object' && values != null) {
        const { xFrom, xTo } = values;
        if (xFrom != null && xTo != null && xFrom !== '' && xTo !== '') {
          let overlap = sortedTargetFunctions.some(border => {
            if (
              baseline == null ||
              (baseline != null && baseline._id != border._id)
            ) {
              if (
                (border.xFrom <= xFrom && border.xTo >= xFrom) ||
                (border.xFrom <= xTo && border.xTo >= xTo) ||
                (xFrom <= border.xFrom && xTo >= border.xTo)
              ) {
                return true;
              }
            }
          });
          return !overlap;
        }
      }
    }
    return true;
  }

  /**
   * @description returns baseline form configuration.
   * xFrom
   * xTo
   * @function
   * @param {Object} baseline passed when update from is being created ti initialize start values
   * @return {Object}
   */
  function getBaseLineFormConfiguration(baseline) {
    return {
      name: 'baselineForm',
      actions: [],
      fields: [
        {
          id: 'xFrom',
          title: gettext('From'),
          type: {
            name: 'text',
            options: {
              type: 'numerical'
            }
          },
          initialize: () => (baseline != null ? baseline.xFrom : ''),
          width: 6,
          required: true,
          validators: {
            maxValue: {
              fn: (modelValue, _, api) => {
                let xTo = api.getValue('xTo');
                if (xTo != null) {
                  return xTo > modelValue;
                }
                return true;
              },
              text: gettextCatalog.getString(
                'To value should me less than Value from'
              )
            },
            borderOverlap: {
              fn: validateIntervalOverlap,
              text: gettextCatalog.getString('Interval is already taken')
            }
          },
          onChange: api => {
            $timeout(api.revalidate);
          }
        },
        {
          id: 'xTo',
          title: gettext('To'),
          type: {
            name: 'text',
            options: {
              type: 'numerical'
            }
          },
          initialize: () => (baseline != null ? baseline.xTo : ''),
          width: 6,
          validators: {
            minValue: {
              fn: (modelValue, _, api) => {
                let xFrom = api.getValue('xFrom');
                if (xFrom != null) {
                  return xFrom < modelValue;
                }
                return true;
              },
              text: gettextCatalog.getString(
                'To value should me less than Value from'
              )
            },
            borderOverlap: {
              fn: validateIntervalOverlap,
              text: gettextCatalog.getString('Interval is already taken')
            }
          },
          onChange: api => {
            $timeout(api.revalidate);
          },
          required: true
        }
      ]
    };
  }

  /**
   * @description Linear regression for values.
   * @function
   * @param {Array} pairs - measurement pairs
   */
  function getRegression(values) {
    return new Promise((resolve, reject) => {
      var myWorker = new Worker('scripts/regression-worker.js');
      if (values.length > 0) {
        values.sort(function(a, b) {
          return a[0] - b[0];
        });
        myWorker.postMessage(values);
        myWorker.onmessage = function(res) {
          resolve(res.data);
        };
      } else {
        reject(gettext('No values on this interval'));
      }
    });
  }
  /**
   * @description filter analysis values according to from to values.
   * @function
   * @param {Array} values
   * @param {Number} from
   * @param {Number} 🦷
   * @return {dataType}
   */
  function filterValues(values, from, to) {
    let filteredValues;
    if (from === 0 && to === 0) {
      filteredValues = values;
    } else if (from === 0) {
      filteredValues = values.filter(function(value) {
        return from < value[0];
      });
    } else {
      filteredValues = values.filter(function(value) {
        return from <= value[0] && value[0] <= to;
      });
    }
    return filteredValues.map(item => {
      //   remove timestamp
      let result = [...item];
      result.splice(2, 1);
      return result;
    });
  }
  /**
   * @description returns limit from configuration.
   * @function
   * @param {Boolean} isUpper indicates what limit is created upper/lower
   * @param {Object} item limit configuration passed to initialize edit form starting value
   * @return {Object}
   */
  function getLimitForm(isUpper, item) {
    const id = Math.random()
      .toString(36)
      .substring(2);
    return {
      name: `${isUpper ? 'upperLimitForm' : 'lowerLimitForm'}_${id}`,
      fields: [
        {
          id: isUpper ? 'upperLimit' : 'lowerLimit',
          title: isUpper ? gettext('Upper Limit %') : gettext('Lower Limit %'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0,
              max: 100
            }
          },
          required: true,
          initialize: () => (item != null ? item.limit : 0)
        }
      ]
    };
  }
  /**
   * @description returns alarm from configuration.
   * @function
   * @param {Boolean} isUpper indicates what limit is created upper/lower
   * @param {String} alarmId alarm id passed to initialize edit form starting value
   * @return {Object}
   */
  function getAlarmForm(isUpper, alarmId) {
    const id = Math.random()
      .toString(36)
      .substring(2);
    return {
      name: `${isUpper ? 'upperLimitAlarmForm' : 'lowerLimitAlarmForm'}_${id}`,
      fields: [
        {
          id: isUpper ? 'upperLimitAlarm' : 'lowerLimitAlarm',
          title: isUpper
            ? gettext('Upper Limit Alarm')
            : gettext('Lower Limit Alarm'),
          type: {
            name: 'autocomplete',
            options: {
              type: 'number',
              min: 0,
              max: 100,
              itemsCrawler: {
                entity: 'alarms',
                method: 'read'
              },
              crawlerParams: text => {
                if (text != null && text != '') {
                  return { filter: text };
                }
                return { order: 'name' };
              },
              display: item => ({ text: item.name }),
              dialog: {
                entity: 'alarms'
              }
            }
          },
          required: true,
          initialize: () => alarmId || null
        }
      ]
    };
  }
  /**
   * @description returns form values for limit/alarms.
   * @function
   * @param {Boolean} isUpper indicates what limit is created upper/lower
   * @return {Array}
   */
  function getLimits(isUpper) {
    let selectedLimits = vm.limits.lower.limits;
    let limitKey = 'lowerLimit';
    let alarmKey = 'lowerLimitAlarm';
    if (isUpper) {
      selectedLimits = vm.limits.upper.limits;
      limitKey = 'upperLimit';
      alarmKey = 'upperLimitAlarm';
    }
    return selectedLimits.map(limitItem => {
      let limit = limitItem.api.getValue(limitKey);
      let alarms = limitItem.alarms.map(alarmItem => {
        let alarm = alarmItem.api.getValue(alarmKey);
        return alarm._id;
      });
      return {
        limit,
        alarms
      };
    });
  }
  //SAVE BUTTON CONFIGURATION
  vm.saveActions = [
    {
      title: gettext('Save'),
      /**
       * @description gets values out of all forms ad constructs it to baseline object ready to send to api.
       * @function
       */
      fn: () => {
        const { xFrom, xTo } = vm.baseLineApi.getValues();
        const {
          factorK,
          factorN,
          baseLineK,
          baseLineN
        } = vm.targetApi.getValues();

        const upperLimits = getLimits(true);
        const lowerLimits = getLimits();

        $mdDialog.hide({
          xFrom: xFrom,
          xTo: xTo,
          baseLineK: baseLineK,
          baseLineN: baseLineN,
          targetK: factorK * baseLineK,
          targetN: factorN * baseLineN,
          upperLimits,
          lowerLimits
        });
      },
      /**
       * @description disables save button if any of forms are invalid.
       * @function
       * @return {Boolean}
       */
      disabledFn: () => {
        //VALIDATE LIMIT FORM
        let invalidLimits = vm.limitKeys.some(key => {
          let item = vm.limits[key];
          //LIMITS
          let invalid = item.limits.some(limit => {
            let invalidLimit = false;
            if (typeof limit.api.formValidity == 'function') {
              invalidLimit = !limit.api.formValidity();
            }
            //ALARMS
            let invalidAlarms = limit.alarms.some(alarm => {
              let invalidAlarm = false;
              if (typeof alarm.api.formValidity == 'function') {
                invalidAlarm = !alarm.api.formValidity();
              }
              return invalidAlarm;
            });
            return invalidLimit || invalidAlarms;
          });
          return invalid;
        });
        // /BASELINE FORM
        let invalidBaseline = false;
        if (typeof vm.baseLineApi.formValidity == 'function') {
          invalidBaseline = !vm.baseLineApi.formValidity();
        }
        //TARGET FORM
        let invalidTarget = false;
        if (typeof vm.targetApi.formValidity == 'function') {
          invalidTarget = !vm.targetApi.formValidity();
        }
        return invalidLimits || invalidBaseline || invalidTarget;
      }
    }
  ];
}

export default BaseLineTargetFunctionService;
