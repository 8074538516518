BillingKindModel.$inject = ['ModelUtilities'];

function BillingKindModel(ModelUtilities) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'billing-kinds'
  };

  var networkConfigurationAllLang = {
    host: 'api',
    path: 'billing-kinds',
    allLang: true
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readAllLang: ModelUtilities.crud(
        'GET',
        networkConfigurationAllLang,
        modelStorage
      )
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default BillingKindModel;
