AlarmRuleTargetForXAxisIntervalModel.$inject = [
  'ModelUtilities',
  'CrawlerMethods'
];

function AlarmRuleTargetForXAxisIntervalModel(ModelUtilities, CrawlerMethods) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'api',
    path: 'alarm-rule-target-for-x-axis-intervals'
  };

  var crawlerConfiguration = {
    entity: 'alarm-rule-target-for-x-axis-intervals',
    method: 'read',
    steps: [
      {
        entity: 'alarms',
        valueParams: ['alarm'],
        queryParams: ['_id'],
        method: 'read',
        methodType: 'populateArray'
      }
    ]
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    update: ModelUtilities.crud('PUT', networkConfiguration, modelStorage),
    delete: ModelUtilities.crud('DELETE', networkConfiguration, modelStorage),
    custom: {
      readAlarm: CrawlerMethods.crawler(crawlerConfiguration)
    },
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default AlarmRuleTargetForXAxisIntervalModel;
