BillingOverviewFactory.$inject = [
  'TranslationService',
  'gettext',
  'PhysicalCollectionService',
  'CrawlerMethods',
  'AlertingService',
  'gettextCatalog'
];
/**
 * @ngdoc service
 * @name invoices.BillingOverviewFactory;
 * @description function containing function used by the sfe-billing-data-overview component
 * @property {function} createBillingDataOverviewTableRow
 */
export default function BillingOverviewFactory(
  TranslationService,
  gettext,
  PhysicalCollectionService,
  CrawlerMethods,
  AlertingService,
  gettextCatalog
) {
  /**
   * @description function groups all values by billing type and calculates total values for each billing type and the total values of all billing types
   * @param {Array} Array - containing billing data
   * @return {Object} an object containing billing data group by billing type and total values
   */
  const groupByBillingTypeAndCalculateTotalValues = billingData => {
    return billingData
      .sort(
        (a, b) => a.energyManagementGroup.order - b.energyManagementGroup.order
      )
      .reduce(
        (obj, item) => {
          const value =
            item.value && typeof item.value == 'number' ? item.value : 0;
          if (obj[item.billingType._id]) {
            return {
              ...obj,
              [item.billingType._id]: {
                billingItems: [...obj[item.billingType._id].billingItems, item],
                typeTotal: {
                  ...obj[item.billingType._id].typeTotal,
                  value: obj[item.billingType._id].typeTotal.value + value
                }
              },
              total: obj.total + value
            };
          }
          return {
            ...obj,
            [item.billingType._id]: {
              billingItems: [item],
              typeTotal: {
                type: item.billingType.name,
                value: value
              }
            },
            total: obj.total + value,
            orderedKeys: [...obj.orderedKeys, item.billingType._id]
          };
        },
        { total: 0, orderedKeys: [] }
      );
  };
  /**
   * @memberof invoices.BillingOverviewFactory
   * @description  creates the row of the billing overview data table
   * @param {Object} binding/paramName
   * @return {Array} An array containing the table rows.
   */
  const createBillingDataOverviewTableRow = billingData => {
    const tableColumns = createTableColumns(billingData);
    const {
      total,
      orderedKeys, //we want to ensure that
      ...billingDataByGroup
    } = groupByBillingTypeAndCalculateTotalValues(tableColumns);
    //concat flatten the array (since flat() is not supported by babel)
    const billingDataArray = [].concat(
      ...orderedKeys.map(key => {
        return [
          ...billingDataByGroup[key].billingItems,
          { typeTotal: billingDataByGroup[key].typeTotal }
        ];
      })
    );
    return [...billingDataArray, { total }];
  };
  /**
   * @description function that takes billing data and eriches it, so that it can be used as data for sfe-table component.
   * @function
   * @param {Array} billingData - billingData for the specific detailed invoice
   * @return {Array} enriched data
   */
  const createTableColumns = billingData => {
    return billingData.map(item => {
      const noValue = gettext('unknown');
      const { symbol: currencySymbol = '' } = item.currency
        ? TranslationService.GetCollectionById(
          'codelists.currencies',
          item.currency
        )
        : '';
      let stakeholderName = noValue;
      let energyManagementGroupName = noValue;
      let submeterMeasuringPointName = noValue;
      let stakeholderId = '';
      let submeterMeasuringPointId = '';
      let stakeholderState;
      let measuringPointState;

      const symbol =
        item.measurementUnit && item.measurementUnit.symbol
          ? item.measurementUnit.symbol
          : '';
      const { symbol: metricPrefix = '' } = item.metricPrefix
        ? TranslationService.GetCollectionById(
          'codelists.metricPrefixes',
          item.metricPrefix
        )
        : '';
      const prefixAndUnit = ` ${metricPrefix}${symbol}`;
      if (item.costCentre && item.costCentre.name) {
        stakeholderName = item.costCentre.name;
        stakeholderId = item.costCentre._id;
        stakeholderState = 'company-resources-cost-centres-view';
      } else if (item.businessPartner && item.businessPartner.name) {
        stakeholderName = item.businessPartner.name;
        stakeholderId = item.businessPartner._id;
        stakeholderState = 'company-resources-business-partners-view';
      }
      if (item.energyManagementGroup && item.energyManagementGroup.name) {
        energyManagementGroupName = item.energyManagementGroup.name;
      }
      if (item.submeterMeasuringPoint && item.submeterMeasuringPoint.name) {
        submeterMeasuringPointName = item.submeterMeasuringPoint.name;
        submeterMeasuringPointId = item.submeterMeasuringPoint._id;
        measuringPointState = 'company-resources-measuring-points-view';
      }

      return {
        ...item,
        value: parseFloat(item.value.toFixed(7)),
        quantity: parseFloat(item.quantity.toFixed(2)),
        percentageOfAllocation: parseFloat(
          item.percentageOfAllocation.toFixed(2)
        ),
        energyManagementGroupName,
        submeterMeasuringPointName,
        stakeholderName,
        submeterMeasuringPointId,
        stakeholderId,
        stakeholderState,
        measuringPointState,
        currencySymbol,
        prefixAndUnit,
        percent: '%'
      };
    });
  };
  /**
   * @description constructs array of stakeholders to be displayed in billing data table.
   * @function
   * @param {Array} stakeholders array of stakeholders
   * @param {String} energyManagementGroupName name of energy management group name
   * @param {Array} measurementUnits array of measuring units
   * @return {Array}
   */
  const getStakeholderRows = (
    stakeholders,
    energyManagementGroupName,
    measurementUnits
  ) => {
    return stakeholders.map(stakeholder => {
      let billingCalculationDataItem = stakeholder.billingCalculationData[0];
      // CURRENCY
      const symbolObject = {
        symbol: ''
      };
      const currencyObject = billingCalculationDataItem.currency
        ? TranslationService.GetCollectionById(
          'codelists.currencies',
          billingCalculationDataItem.currency
        )
        : symbolObject;
      const { symbol: currencySymbol } = currencyObject
        ? currencyObject
        : symbolObject;
      // METRIC PREFIX
      const metricPrefixObject = billingCalculationDataItem.metricPrefix
        ? TranslationService.GetCollectionById(
          'codelists.metricPrefixes',
          billingCalculationDataItem.metricPrefix
        )
        : symbolObject;
      const { symbol: metricPrefix } = metricPrefixObject
        ? metricPrefixObject
        : symbolObject;
      // MEASUREMENT UNIT
      const measurementUnitObject = billingCalculationDataItem.measurementUnit
        ? measurementUnits.find(
          unit => unit._id == billingCalculationDataItem.measurementUnit
        )
        : symbolObject;
      const { symbol: measurementUnit } = measurementUnitObject
        ? measurementUnitObject
        : symbolObject;

      const prefixAndUnit = metricPrefix + measurementUnit;
      // STAKEHOLDER
      let stakeholderName, stakeholderId, stakeholderState;
      if (stakeholder.costCentre) {
        stakeholderName =
          stakeholder.costCentreObject && stakeholder.costCentreObject.name
            ? stakeholder.costCentreObject.name
            : gettextCatalog.getString('Unknown');
        stakeholderId = stakeholder.costCentre;
        stakeholderState = 'company-resources-cost-centres-view';
      } else if (stakeholder.businessPartner) {
        stakeholderName =
          stakeholder.businessPartnerObject &&
          stakeholder.businessPartnerObject.name
            ? stakeholder.businessPartnerObject.name
            : gettextCatalog.getString('Unknown');
        stakeholderId = stakeholder.businessPartner;
        stakeholderState = 'company-resources-business-partners-view';
      }
      return {
        ...stakeholder,
        energyManagementGroupName,
        stakeholderName,
        stakeholderId,
        stakeholderState,
        currencySymbol,
        prefixAndUnit,
        percent: '%',
        value: stakeholder.sumOfValues,
        quantity: stakeholder.sumOfQuantities,
        percentageOfAllocation: stakeholder.percentageOfQuantities,
        _id: true //attribute that indicates that item is a row
      };
    });
  };

  /**
   * @description returns array of table rows.
   * @function
   * @param {Object} billingData billing data object
   * @return {Array}
   */
  const createTableColumnsGroupedByStakeholders = async billingData => {
    const populatedEnergyManagementGroups = await populateEnergyManagementGroups(
      billingData.energyManagementGroups
    );
    const measurementUnits = await PhysicalCollectionService.returnMeasurementUnits();
    const billingItems = populatedEnergyManagementGroups.reduce(
      (rows, energyManagementGroup) => {
        const energyManagementGroupName =
          energyManagementGroup.energyManagementGroupObject &&
          energyManagementGroup.energyManagementGroupObject.name
            ? energyManagementGroup.energyManagementGroupObject.name
            : gettextCatalog.getString('Unknown');
        let items = getStakeholderRows(
          energyManagementGroup.stakeholders,
          energyManagementGroupName,
          measurementUnits
        );

        return rows.concat(items).concat([
          {
            typeTotal: {
              columns: [
                {
                  name: 'type'
                },
                {
                  emptyColumn: true
                },
                {
                  name: 'percentage',
                  alignRight: true,
                  isNumber: true,
                  percent: true
                },
                {
                  name: 'quantity',
                  alignRight: true,
                  prefixAndUnit: items[0].prefixAndUnit,
                  isNumber: true
                },
                {
                  name: 'value',
                  alignRight: true,
                  currency: true,
                  isNumber: true
                }
              ],
              separateColumns: true,
              type:
                energyManagementGroup.energyManagementGroupObject &&
                energyManagementGroup.energyManagementGroupObject.name
                  ? energyManagementGroup.energyManagementGroupObject.name
                  : gettextCatalog.getString('Unknown'),
              value: energyManagementGroup.sumOfValues,
              quantity: energyManagementGroup.sumOfQuantities,
              percentage: energyManagementGroup.percentageOfQuantities
            }
          }
        ]);
      },
      []
    );
    billingItems.push({
      total: billingData.sumOfValues
    });
    return billingItems;
  };
  /**
   * @description populates energy management group, billing type and stakeholder.
   * @function
   * @param {Array} billingTypes array of billing types
   * @return {Array}
   */
  const populateEnergyManagementGroups = async energyManagementGroups => {
    const itemsToPopulate = {
      energyManagementGroups: [],
      costCentres: [],
      businessPartners: []
    };
    energyManagementGroups.forEach(energyManagementGroup => {
      energyManagementGroup.stakeholders.forEach(stakeholder => {
        if (stakeholder.costCentre) {
          itemsToPopulate.costCentres.push(stakeholder.costCentre);
        }
        if (stakeholder.businessPartner) {
          itemsToPopulate.businessPartners.push(stakeholder.businessPartner);
        }
      });
      itemsToPopulate.energyManagementGroups.push(
        energyManagementGroup.energyManagementGroup
      );
    });
    const filter = { select: '_id,name' };
    let fetchConfigurations = [
      {
        entity: 'energy-management-groups',
        filter: { ...filter /* _id: itemsToPopulate.energyManagementGroups */ }
      },
      {
        entity: 'cost-centres',
        filter: { ...filter, _id: itemsToPopulate.costCentres }
      },
      {
        entity: 'business-partners',
        filter: { ...filter, _id: itemsToPopulate.businessPartners }
      }
    ];

    const promises = fetchConfigurations.map(configuration => {
      return fetchItems(configuration);
    });
    const results = await Promise.all(promises);
    return energyManagementGroups.map(energyManagementGroup => {
      let stakeholders = energyManagementGroup.stakeholders.map(stakeholder => {
        let costCentreObject, businessPartnerObject;
        if (stakeholder.costCentre) {
          costCentreObject =
            results[1].find(item => item._id == stakeholder.costCentre) || {};
        }
        if (stakeholder.businessPartner) {
          businessPartnerObject =
            results[2].find(item => item._id == stakeholder.businessPartner) ||
            {};
        }
        return {
          ...stakeholder,
          costCentreObject,
          businessPartnerObject
        };
      });
      return {
        ...energyManagementGroup,
        stakeholders,
        energyManagementGroupObject: results[0].find(item => {
          return item._id == energyManagementGroup.energyManagementGroup;
        })
      };
    });
  };
  /**
   * @description lists items according to configuration.
   * @function
   * @param {Object} configuration request configuration should contain entity and filter parameter
   * @return {Array}
   */
  const fetchItems = async configuration => {
    // TEMP CC BP condition until BE filters are fixed
    if (
      ((configuration.entity == 'cost-centres' ||
        configuration.entity == 'business-partners') &&
        configuration.filter._id.length > 0) ||
      !configuration.filter._id
    ) {
      const fetchEnergyManagementGroups = CrawlerMethods.getMethod({
        entity: configuration.entity,
        method: 'read'
      });
      try {
        let { data } = await fetchEnergyManagementGroups(configuration.filter);
        return data;
      } catch (err) {
        AlertingService.Error(err);
        return [];
      }
    } else {
      return [];
    }
  };

  return {
    createBillingDataOverviewTableRow,
    createTableColumnsGroupedByStakeholders
  };
}
