import { RouteManager, gettext } from '../routes';

import InvoiceView from './pages/invoice/invoice.view.html';
import InvoiceDetailView from './pages/invoice-detail/invoice-detail.view.html';
import InvoiceOverviewView from './pages/invoice-overview/invoice-overview.view.html';
import PriceListView from './pages/price-list/price-list.view.html';
import PriceListDetailView from './pages/price-list-detail/price-list-detail.view.html';
import SfeListView from '../common/components/sfe-list/sfe-list.view.html';
import NewFormBasicView from '../common/views/new-form-basic.view.html';
import InvoiceDetailForm from './pages/invoice-detail/new-invoice-detail.view.html';

loadEntity.$inject = ['$transition$', 'StateResolveService'];
function loadEntity($transition$, StateResolveService) {
  return StateResolveService.fetchEntityForView($transition$);
}

let InvoicesRouteManager = new RouteManager();
InvoicesRouteManager.state('invoices', {
  url: '/invoices',
  isMainMenuItem: true,
  icon: 'receipt',
  menuIndex: 2,
  pageTitle: gettext('Invoices'),
  theme: 'tango',
  accessScopeFe: 'tis_fe:energy-management-module',
  accessScopeBe: 'tis_fe:energy-management-module'
})

  .state('invoices-price-lists', {
    url: '/invoices/price-lists',
    isSubMenuItem: true,
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Price Lists'),
    parentState: 'invoices',
    theme: 'tango',
    accessScopeFe: 'tis_fe:price-lists',
    accessScopeBe: 'tis_be:price-list-masters',
    entity: 'price-list-masters'
  })

  .state('invoices-price-lists-new', {
    url: '/invoices/price-lists/new',
    template: NewFormBasicView,
    controller: 'NewPriceListController',
    controllerAs: 'vm',
    pageTitle: gettext('New Price List'),
    parentState: 'invoices',
    theme: 'tango',
    iconMode: 'add',
    accessScopeFe: 'tis_fe:price-lists',
    accessScopeBe: 'tis_be:price-list-masters',
    mode: 'create',
    entity: 'price-list-masters',
    resolve: {
      priceListMaster: () => ({})
    }
  })

  .state('invoices-price-lists-edit', {
    url: '/invoices/price-lists/{priceListId}/edit',
    template: NewFormBasicView,
    controller: 'NewPriceListController',
    controllerAs: 'vm',
    parentState: 'invoices',
    theme: 'tango',
    accessScopeFe: 'tis_fe:price-lists',
    accessScopeBe: 'tis_be:price-list-masters',
    mode: 'update',
    entity: 'price-list-masters',
    resolve: {
      priceListMaster: loadEntity
    }
  })

  .state('invoices-price-lists-duplicate', {
    url: '/invoices/price-lists/{priceListId}/duplicate',
    template: NewFormBasicView,
    controller: 'NewPriceListController',
    controllerAs: 'vm',
    pageTitle: gettext('Duplicate Price List'),
    parentState: 'invoices',
    theme: 'tango',
    accessScopeFe: 'tis_fe:price-lists',
    accessScopeBe: 'tis_be:price-list-masters',
    mode: 'create',
    entity: 'price-list-masters',
    resolve: {
      priceListMaster: loadEntity
    }
  })

  .state('invoices-price-lists-view', {
    url: '/invoices/price-lists/{priceListId}',
    template: PriceListView,
    controller: 'PriceListController',
    controllerAs: 'vm',
    pageTitle: gettext('Price List'),
    parentState: 'invoices',
    theme: 'tango',
    accessScopeFe: 'tis_fe:price-lists',
    accessScopeBe: 'tis_be:price-list-masters',
    mode: 'read',
    entity: 'price-list-masters',
    resolve: {
      priceListMaster: loadEntity
    }
  })

  .state('invoices-price-lists-new-detail', {
    url: '/invoices/price-lists/{priceListId}/price-list-details/new',
    template: NewFormBasicView,
    controller: 'NewPriceListDetailController',
    controllerAs: 'vm',
    pageTitle: gettext('New Price List'),
    parentState: 'invoices',
    theme: 'tango',
    accessScopeFe: 'tis_fe:price-lists',
    accessScopeBe: 'tis_be:price-list-masters',
    mode: 'create',
    entity: 'price-list-details',
    resolve: {
      priceListDetail: () => ({})
    }
  })

  .state('invoices-price-lists-detail-edit', {
    url:
      '/invoices/price-lists/{priceListId}/price-list-details/{priceListDetailId}/edit',
    template: NewFormBasicView,
    controller: 'NewPriceListDetailController',
    controllerAs: 'vm',
    parentState: 'invoices',
    theme: 'tango',
    accessScopeFe: 'tis_fe:price-lists',
    accessScopeBe: 'tis_be:price-list-masters',
    mode: 'update',
    entity: 'price-list-details',
    resolve: {
      priceListDetail: loadEntity
    }
  })

  .state('invoices-price-lists-detail-view', {
    url:
      '/invoices/price-lists/{priceListId}/price-list-details/{priceListDetailId}',
    template: PriceListDetailView,
    controller: 'PriceListDetailController',
    controllerAs: 'vm',
    pageTitle: gettext('Price List'),
    parentState: 'invoices',
    theme: 'tango',
    accessScopeFe: 'tis_fe:price-lists',
    accessScopeBe: 'tis_be:price-list-masters',
    mode: 'read',
    entity: 'price-list-details',
    resolve: {
      priceListDetail: loadEntity
    }
  })

  .state('invoices-price-lists-detail-item-new', {
    url: '/invoices/price-list-details/{priceListDetailId}/price-list-item/new',
    template: NewFormBasicView,
    controller: 'NewPriceListItemController',
    controllerAs: 'vm',
    pageTitle: gettext('New Price List Item'),
    parentState: 'invoices',
    theme: 'tango',
    accessScopeFe: 'tis_fe:price-lists',
    accessScopeBe: 'tis_be:price-list-masters',
    mode: 'create',
    entity: 'price-list-items',
    resolve: {
      priceListItem: () => ({})
    }
  })

  .state('invoices-price-lists-detail-item-edit', {
    url:
      '/invoices/price-list-details/{priceListDetailId}/price-list-item/{priceListItemId}/edit',
    template: NewFormBasicView,
    controller: 'NewPriceListItemController',
    controllerAs: 'vm',
    pageTitle: gettext('Edit Price List Item'),
    parentState: 'invoices',
    theme: 'tango',
    accessScopeFe: 'tis_fe:price-lists',
    accessScopeBe: 'tis_be:price-list-masters',
    mode: 'update',
    entity: 'price-list-items',
    resolve: {
      priceListItem: loadEntity
    }
  })

  .state('invoices-invoices', {
    url: '/invoices/invoices/list',
    isSubMenuItem: true,
    template: SfeListView,
    controller: 'SfeList2ViewController',
    controllerAs: 'vm',
    pageTitle: gettext('Invoices'),
    parentState: 'invoices',
    theme: 'tango',
    accessScopeFe: 'tis_fe:invoices',
    accessScopeBe: 'tis_be:master-invoices',
    entity: 'master-invoices'
  })

  .state('invoices-invoices-duplicate', {
    url: '/invoices/invoices/{invoiceId}/duplicate',
    template: NewFormBasicView,
    controller: 'NewInvoiceController',
    controllerAs: 'vm',
    pageTitle: gettext('Duplicate Invoice'),
    parentState: 'invoices',
    theme: 'tango',
    accessScopeFe: 'tis_fe:invoices',
    accessScopeBe: 'tis_be:master-invoices',
    mode: 'create',
    entity: 'master-invoices',
    resolve: {
      masterInvoice: loadEntity
    }
  })

  .state('invoices-invoices-edit', {
    url: '/invoices/invoices/{invoiceId}/edit',
    template: NewFormBasicView,
    controller: 'NewInvoiceController',
    controllerAs: 'vm',
    parentState: 'invoices',
    theme: 'tango',
    accessScopeFe: 'tis_fe:invoices',
    accessScopeBe: 'tis_be:master-invoices',
    mode: 'update',
    entity: 'master-invoices',
    resolve: {
      masterInvoice: loadEntity
    }
  })

  .state('invoices-invoices-new', {
    url: '/invoices/invoices/new?invoiceIssuer?customer',
    template: NewFormBasicView,
    controller: 'NewInvoiceController',
    controllerAs: 'vm',
    pageTitle: gettext('New Invoice'),
    parentState: 'invoices',
    theme: 'tango',
    iconMode: 'add',
    accessScopeFe: 'tis_fe:invoices',
    accessScopeBe: 'tis_be:master-invoices',
    mode: 'create',
    params: {
      supplier: null,
      customer: null
    },
    entity: 'master-invoices',
    resolve: {
      masterInvoice: () => ({})
    }
  })

  .state('invoices-invoices-view', {
    url: '/invoices/invoices/{invoiceId}',
    template: InvoiceView,
    controller: 'InvoiceController',
    controllerAs: 'vm',
    pageTitle: gettext('Invoice'),
    parentState: 'invoices',
    theme: 'tango',
    accessScopeFe: 'tis_fe:invoices',
    accessScopeBe: 'tis_be:master-invoices',
    mode: 'read',
    entity: 'master-invoices',
    resolve: {
      masterInvoice: loadEntity
    }
  })

  .state('invoices-invoices-detail-new', {
    url: '/invoices/invoices/{invoiceId}/invoice-detail/new',
    template: InvoiceDetailForm,
    controller: 'NewInvoiceDetailController',
    controllerAs: 'vm',
    pageTitle: gettext('New Invoice Detail'),
    parentState: 'invoices',
    theme: 'tango',
    accessScopeFe: 'tis_fe:invoices',
    accessScopeBe: 'tis_be:master-invoices',
    mode: 'create',
    entity: 'detail-invoices',
    resolve: {
      detailInvoice: () => ({})
    }
  })

  .state('invoices-invoices-detail-edit', {
    url: '/invoices/invoices/{invoiceId}/invoice-detail/{invoiceDetailId}/edit',
    template: InvoiceDetailForm,
    controller: 'NewInvoiceDetailController',
    controllerAs: 'vm',
    pageTitle: gettext('Edit Invoice Detail'),
    parentState: 'invoices',
    theme: 'tango',
    accessScopeFe: 'tis_fe:invoices',
    accessScopeBe: 'tis_be:master-invoices',
    mode: 'update',
    entity: 'detail-invoices',
    resolve: {
      detailInvoice: loadEntity
    }
  })

  .state('invoices-invoices-detail-view', {
    url: '/invoices/invoices/{invoiceId}/invoice-detail/{invoiceDetailId}',
    template: InvoiceDetailView,
    controller: 'InvoiceDetailController',
    controllerAs: 'vm',
    pageTitle: gettext('Invoice Detail'),
    parentState: 'invoices',
    theme: 'tango',
    accessScopeFe: 'tis_fe:invoices',
    accessScopeBe: 'tis_be:master-invoices',
    mode: 'read',
    entity: 'detail-invoices',
    resolve: {
      detailInvoice: loadEntity
    }
  })
  .state('invoices-invoices-overview', {
    url: '/invoices/invoices-overview',
    template: InvoiceOverviewView,
    controller: 'InvoiceOverviewController',
    controllerAs: 'vm',
    pageTitle: gettext('Invoices Overview'),
    parentState: 'invoices',
    theme: 'tango',
    isSubMenuItem: true,
    accessScopeFe: 'tis_fe:invoices-overview',
    accessScopeBe: 'tis_fe:invoices-overview'
  });

export default InvoicesRouteManager.routes;
