AllowedFunctionsService.$inject = ['gettextCatalog'];

function AllowedFunctionsService(gettextCatalog) {
  const FunctionConstants = [
    {
      type: 'operation',
      title: 'sqrt',
      useOperatorSymbol: true,
      description: gettextCatalog.getString(
        'Returns the square root of number.'
      ),
      symbol: '( number )'
    },
    {
      type: 'operation',
      title: 'min',
      description: gettextCatalog.getString(
        'Returns the smallest number in arguments.'
      ),
      symbol: '( number, number )'
    },
    {
      type: 'operation',
      title: 'max',
      description: gettextCatalog.getString(
        'Returns the largest number in arguments.'
      ),
      symbol: '( number, number )'
    },
    {
      type: 'operation',
      title: 'abs',
      useOperatorSymbol: true,
      description: gettextCatalog.getString(
        'Returns absolute value of input parameter.'
      ),
      symbol: '( number )'
    },
    {
      type: 'operation',
      title: 'floor',
      useOperatorSymbol: true,
      description: gettextCatalog.getString(
        'Rounds down to nearest natural number.'
      ),
      symbol: '( number )'
    },
    {
      type: 'operation',
      title: 'ceil',
      useOperatorSymbol: true,
      description: gettextCatalog.getString(
        'Rounds up to nearest natural number.'
      ),
      symbol: '( number )'
    },
    {
      type: 'operation',
      title: 'sin',
      description: gettextCatalog.getString('Sine function.'),
      symbol: '( radians )'
    },
    {
      type: 'operation',
      title: 'cos',
      description: gettextCatalog.getString('Cosine function.'),
      symbol: '( radians )'
    },
    {
      type: 'operation',
      title: 'tan',
      description: gettextCatalog.getString('Tangent function.'),
      symbol: '( radians )'
    },
    {
      type: 'operation',
      title: 'rad',
      description: gettextCatalog.getString('Returns radians.'),
      symbol: '( degrees )'
    },
    {
      type: 'operation',
      title: 'deg',
      description: gettextCatalog.getString('Returns degree.'),
      symbol: '( radians )'
    },
    {
      type: 'operation',
      title: 'pow',
      description: gettextCatalog.getString('Computes the power of a number.'),
      symbol: '( number, number )'
    },
    {
      type: 'operation',
      title: 'round',
      description: gettextCatalog.getString(
        'Returns the value of a number rounded to the nearest integer.'
      ),
      symbol: '( number )'
    },
    {
      type: 'operation',
      title: 'exp',
      description: gettextCatalog.getString(
        'Returns the natural exponential of a number.'
      ),
      symbol: '( number )'
    },
    {
      type: 'operation',
      title: 'ln',
      description: gettextCatalog.getString(
        'Returns the natural logarithm of a number to the base e (e = 2.71828).'
      ),
      symbol: '( number )'
    },
    {
      type: 'operation',
      title: 'log',
      description: gettextCatalog.getString(
        'Returns the natural logarithm of a number to the base 10.'
      ),
      symbol: '( number )'
    },
    {
      type: 'operation',
      title: 'sinh',
      description: gettextCatalog.getString('Hyperbolic sine function.'),
      symbol: '( number )'
    },
    {
      type: 'operation',
      title: 'cosh',
      description: gettextCatalog.getString('Hyperbolic cosine function.'),
      symbol: '( number )'
    },
    {
      type: 'operation',
      title: 'tanh',
      description: gettextCatalog.getString('Hyperbolic tangent function.'),
      symbol: '( number )'
    },
    {
      type: 'operation',
      title: 'asin',
      description: gettextCatalog.getString('Inverse sine (arcsine) function.'),
      symbol: '( number )'
    },
    {
      type: 'operation',
      title: 'acos',
      description: gettextCatalog.getString(
        'Inverse cosine (arccosine) function.'
      ),
      symbol: '( number )'
    },
    {
      type: 'operation',
      title: 'atan',
      description: gettextCatalog.getString(
        'Inverse tangent (arctan) function.'
      ),
      symbol: '( number )'
    },
    {
      type: 'operation',
      title: 'atan2',
      description: gettextCatalog.getString(
        'Returns the arc tangent of the two numbers x and y.'
      ),
      symbol: '( x, y )'
    },
    {
      type: 'operation',
      title: 'second',
      description: gettextCatalog.getString('Returns seconds.'),
      symbol: '( timestamp )'
    },
    {
      type: 'operation',
      title: 'minute',
      description: gettextCatalog.getString('Returns minutes.'),
      symbol: '( timestamp )'
    },
    {
      type: 'operation',
      title: 'hour',
      description: gettextCatalog.getString('Returns hours.'),
      symbol: '( timestamp )'
    },
    {
      type: 'operation',
      title: 'dow',
      description: gettextCatalog.getString('Returns day of week.'),
      symbol: '( timestamp )'
    },
    {
      type: 'operation',
      title: 'dom',
      description: gettextCatalog.getString('Returns day of month.'),
      symbol: '( timestamp )'
    },
    {
      type: 'operation',
      title: 'doy',
      description: gettextCatalog.getString('Returns day of year.'),
      symbol: '( timestamp )'
    },
    {
      type: 'operation',
      title: 'month',
      description: gettextCatalog.getString('Returns month.'),
      symbol: '( timestamp )'
    },
    {
      type: 'operation',
      title: 'year',
      description: gettextCatalog.getString('Returns year.'),
      symbol: '( timestamp )'
    }
  ];
  /**
   * @description returns array of function names configurations.
   * @function
   * @return {Array}
   */
  function getDialogConfig() {
    return [
      {
        type: 'subtitle',
        text: gettextCatalog.getString('Functions')
      },
      ...FunctionConstants
    ];
  }
  /**
   * @description returns array of allowed functions.
   * @function
   * @return {Array}
   */
  function getConstants() {
    return FunctionConstants.map(item => item.title);
  }
  /**
   * @description returns array of functions that should be displayed as operator e.g. ceil -> ⌈x⌉.
   * @function
   * @return {Array}
   */
  function getOnlyOnlyOperators() {
    return FunctionConstants.filter(a => a.useOperatorSymbol).map(
      item => item.title
    );
  }

  return {
    getConstants,
    getDialogConfig,
    getOnlyOnlyOperators
  };
}

export default AllowedFunctionsService;
