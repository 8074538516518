import types from './maintenance-flow.type';

function readMaintenanceFlow(payload) {
  return {
    type: types.MAINTENANCE_FLOW_READ,
    payload
  };
}

function updateMaintenanceFlow(payload) {
  return {
    type: types.MAINTENANCE_FLOW_UPDATE,
    payload
  };
}

function createMaintenanceFlow(payload) {
  return {
    type: types.MAINTENANCE_FLOW_CREATE,
    payload
  };
}

function addMaintenanceFlow(payload) {
  return {
    type: types.MAINTENANCE_FLOW_ADD,
    payload
  };
}

export default {
  readMaintenanceFlow,
  updateMaintenanceFlow,
  createMaintenanceFlow,
  addMaintenanceFlow
};
