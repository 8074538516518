NewRuleController.$inject = [
  '$stateParams',
  '$state',
  'MetadataService',
  'AlertingService',
  'gettext',
  'rule',
  'createOrUpdateService',
  'SfeHeader',
  '$scope',
  'CalculatedFlowFormConfigurations',
  'InputParametersParserService'
];

function NewRuleController(
  $stateParams,
  $state,
  MetadataService,
  AlertingService,
  gettext,
  rule,
  createOrUpdateService,
  SfeHeader,
  $scope,
  CalculatedFlowFormConfigurations,
  InputParametersParserService
) {
  var vm = this;
  var ruleId = $stateParams.id;
  vm.duplicateMode = $state.current.name === 'alarms-and-rules-rules-duplicate';
  vm.errors = [];
  vm.alarms = [];
  vm.dataConfig = {
    PH16: true
  };

  init();
  vm.api = {
    getVariables: () => {
      if (Array.isArray(vm.variables)) {
        return vm.variables.map(item => item.variableName);
      }
      return [];
    }
  };

  function init() {
    vm.editMode = !!ruleId;
    vm.createOrUpdate = [
      {
        title:
          ruleId && !vm.duplicateMode ? gettext('Update') : gettext('Create'),
        fn: createOrUpdate,
        color: 'primary',
        raised: true,
        disabledFn: function() {
          return (
            !$scope.RuleForm.$valid || vm.errors.length > 0 || vm.sendingRequest
          );
        }
      }
    ];

    vm.dataConfig.data = [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textArea',
        maxlength: 500,
        type: 'text',
        required: false
      }
    ];

    vm.dataConfig.dataObj = {
      name: '',
      description: ''
    };

    if (ruleId) {
      MetadataService.Loading(true);
      vm.ruleId = ruleId;
      if (vm.duplicateMode) {
        vm.dataConfig.header = SfeHeader.constructFormHeader(
          'primary',
          gettext('Duplicate Rule'),
          'alarms-and-rules-rules-view',
          { id: ruleId }
        );

        MetadataService.ChangeMetadata(
          'Duplicate ' + rule.name,
          rule.description
        );
      } else {
        vm.dataConfig.header = SfeHeader.constructFormHeader(
          'primary',
          gettext('Edit Rule'),
          'alarms-and-rules-rules-view',
          { id: ruleId }
        );

        MetadataService.ChangeMetadata('Edit ' + rule.name, rule.description);
      }

      vm.inputExpression = rule.expression
        .replace(/true/g, 'FAIL')
        .replace(/false/g, 'SUCCESS');

      vm.variables = InputParametersParserService.parseApiToForm(
        rule.inputParams
      );

      const { name, description } = rule;

      vm.dataConfig.dataObj = {
        name,
        description
      };
      MetadataService.Loading(false);
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New Rule'),
        'alarms-and-rules-rules-list'
      );
      vm.newMode = true;
      //DEFAULT VARIABLES VALUES
      vm.variables = [
        {
          variableName: 'a',
          paramObject: {
            mappingFunction: 2,
            mappingFunctionNArgument: 0,
            streamArchiveProperty: 100,
            dateTimeFilterType: 200,
            includeValueOnTheEdge: true //boundary type 2
          }
        }
      ];
    }
  }

  // create and update functions
  function createOrUpdate() {
    vm.sendingRequest = true;
    createOrUpdateService
      .simpleCall(
        {
          entity: 'rules',
          method: vm.editMode && !vm.duplicateMode ? 'update' : 'create'
        },
        vm.editMode,
        vm.duplicateMode ? null : ruleId,
        dataToSave(),
        'alarms-and-rules-rules-view',
        'id'
      )
      .then(
        function() {
          vm.sendingRequest = false;
        },
        function(err) {
          vm.sendingRequest = false;
          AlertingService.Error(err);
        }
      );
  }
  /**
   * @description maps input parameters.
   * @function
   * @param {Array} params
   * @return {Array}
   */
  function constructInputParams(params) {
    let reducedParams = params.map(({ paramObject }) => paramObject);
    reducedParams = CalculatedFlowFormConfigurations.getTimeSeriesParam(
      reducedParams
    );
    let calculationTimestampDateTimeFilter = {
      type: 200,
      boundaryType: 2
    };
    if (Array.isArray(reducedParams)) {
      return reducedParams.map(param => ({
        ...param,
        calculationTimestampDateTimeFilter
      }));
    }
    return [];
  }
  /**
   * @description returns post/put object.
   * @function
   * @return {Object}
   */
  function dataToSave() {
    const { name, description } = vm.dataConfig.dataObj;
    const expression = vm.outputExpression
      .replace(/FAIL/g, true)
      .replace(/SUCCESS/g, false);
    const inputParams = constructInputParams(vm.variables);
    return {
      name,
      description,
      expression,
      inputParams
    };
  }
}

export default NewRuleController;
