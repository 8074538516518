AssetAvailabilityEvent.$inject = ['ModelUtilities'];

/**
 * @ngdoc model
 * @name common.AssetAvailabilityEvent
 * @description Model for AssetAvailabilityEvent.
 */
function AssetAvailabilityEvent(ModelUtilities) {
  const modelStorage = {};

  let networkConfiguration = {
    host: 'api',
    path: 'assets/:assetId/availability-events'
  };

  let networkConfigurationRead = {
    host: 'api',
    path: 'assets/:assetId/availability-events/:id'
  };

  const methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    list: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    read: ModelUtilities.crud('GET', networkConfigurationRead, modelStorage),
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default AssetAvailabilityEvent;
