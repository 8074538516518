import './sfe-side-menu.scss';
import template from './sfe-side-menu.component.html';

sfeSideMenu.$inject = [
  '$state',
  '$timeout',
  '$rootScope',
  'AuthorizationShowScopeModel',
  'AlertingService',
  '$q',
  '$transitions',
  'NavigationMenuService'
];

function sfeSideMenu(
  $state,
  $timeout,
  $rootScope,
  AuthorizationShowScopeModel,
  AlertingService,
  $q,
  $transitions,
  NavigationMenuService
) {
  var directive = {
    restrict: 'E',
    template,
    link: link
  };
  return directive;

  function link(scope) {
    let allStates = [];

    let activeStateObj;
    var menuBuilt;
    var accessScopes = {};
    var showTooltipClass = 'side-menu__wrapper__item__title__tooltip__arrow';
    var activeTooltip;
    scope.compactMenu = false;
    scope.showFullTooltip = true;
    scope.moduleActions = [];
    scope.modules = [];
    scope.selectedModule = null;
    scope.selectedMenuIndex = -1;
    scope.activeLinkIndex = -1;
    scope.activeMenuIndex = -1;
    scope.activeMenuIndexForCompactMenu = -1;
    scope.activeMenuHeight = 0;
    scope.activeActionIndex = -1;
    scope.toggle = toggle;
    scope.link = link;
    scope.linkAction = linkAction;
    scope.initFinished = initFinished;
    scope.openTooltip = openTooltip;
    scope.closeSideMenuToolTip = closeSideMenuToolTip;

    var initiatedModules = {};
    $timeout(initiation);

    async function initiation() {
      try {
        const {
          preparedModules,
          selectedModule,
          states,
          accessScopes: fetchedAccessScopes
        } = await NavigationMenuService.initiateMenu();
        allStates = states;
        scope.modules = preparedModules;
        scope.selectedModule = selectedModule;
        activeStateObj = $state.current;
        scope.menuPermissionsFetched = true;
        accessScopes = fetchedAccessScopes;
        scope.activeMenuIndex = scope.modules.findIndex(
          module => module.rootName == activeStateObj.parentState
        );
      } catch (err) {
        AlertingService.Error(err);
      }

      setOnSideNavEvent();
      setTransitionSuccessEvent();
    }

    function setOnSideNavEvent() {
      $rootScope.$on('sidenav', function(event, sideMenuMode) {
        // TODO: handle a fullscreen case --> hide side menu
        scope.compactMenu = sideMenuMode === 1;
        scope.activeMenuIndex = -1;
      });
      $rootScope.$on('openNavigationDialog', () => {
        NavigationMenuService.openNavigationDialog(accessScopes);
      });
    }

    function setTransitionSuccessEvent() {
      $transitions.onSuccess({}, () => {
        scope.activeMenuIndexForCompactMenu = -1;
        activeStateObj = $state.current;
        let moduleIndex = -1;
        let submoduleIndex = -1;

        if (activeStateObj.parentState && activeStateObj.name) {
          const currentModule = activeStateObj.parentState;
          const currentSubmodule = activeStateObj.name;

          const res = scope.modules.reduce(
            (result, item, index) => {
              if (item.rootName === currentModule) {
                result = {
                  ...result,
                  moduleIndex: index,
                  submoduleIndex: item.subitems.findIndex(
                    NavigationMenuService.getFindSubItemIndex(currentSubmodule)
                  )
                };
              }
              return result;
            },
            {
              moduleIndex: -1,
              submoduleIndex: -1
            }
          );

          moduleIndex = res.moduleIndex;
          submoduleIndex = res.submoduleIndex;
        }

        if (scope.activeMenuIndex == moduleIndex) {
          if (submoduleIndex === -1) {
            // if module index stayed the same and we did not find a matching
            // submodule index, assume we stayed in the same submodule;
            // TODO: in future, we could use levenshtein distance to find the
            // closest match
          } else {
            scope.activeLinkIndex = submoduleIndex;
          }
        } else {
          // redraw module (so the height is correct)
          const title = GetTitleFromIndex(moduleIndex);
          toggle(title, moduleIndex, true);
          scope.activeLinkIndex = submoduleIndex;
          scope.selectedMenuIndex = moduleIndex;
        }

        if (!_.isEmpty(accessScopes)) {
          if (isForbidden()) {
            $state.go('forbidden');
          }
        } else {
          getTisFeScopes().then(
            function(access) {
              accessScopes = access;
              BuildMenu();
              if (isForbidden()) {
                $state.go('forbidden');
              }
            },
            function(err) {
              AlertingService.Error(err);
            }
          );
        }
        if (!menuBuilt) {
          BuildMenu();
        }
        RespawnActions();
      });
    }

    function isForbidden() {
      return (
        !activeStateObj.allUsers &&
        (!accessScopes[activeStateObj.accessScopeFe] ||
          (!accessScopes[activeStateObj.accessScopeFe].show &&
            activeStateObj.mode !== 'create') ||
          (activeStateObj.mode === 'create' &&
            (!accessScopes[activeStateObj.accessScopeBe] ||
              (!accessScopes[activeStateObj.accessScopeBe] &&
                !accessScopes[activeStateObj.accessScopeBe].create))))
      );
    }

    function getTisFeScopes() {
      var deferred = $q.defer();
      AuthorizationShowScopeModel.read().then(
        function(res) {
          scope.menuPermissionsFetched = true;
          deferred.resolve(res.data);
        },
        function(err) {
          scope.menuPermissionsFetched = true;
          deferred.reject(err);
        }
      );
      return deferred.promise;
    }

    function initFinished(mode, index) {
      if (!(index in initiatedModules)) {
        initiatedModules[index] = {};
      }
      initiatedModules[index][mode] = true;
      if (initiatedModules[index].main && initiatedModules[index].sub) {
        OpenNavigation();
      }
    }

    function OpenNavigation() {
      let subitem;
      let action;
      let module;
      for (let index in scope.modules) {
        module = scope.modules[index];
        if (module.rootName === activeStateObj.parentState) {
          scope.selectedMenuIndex = parseInt(index);
          toggle(GetTitleFromIndex(index), parseInt(index), true);
          if (activeStateObj.iconMode) {
            // is an action
            for (var index2 in scope.selectedModule.actions) {
              action = scope.selectedModule.actions[index2];
              if (action.name === activeStateObj.name) {
                scope.activeActionIndex = parseInt(index2);
              }
            }
          } else {
            // is a subitem
            for (var index3 in module.subitems) {
              subitem = module.subitems[index3];
              if (subitem.name === activeStateObj.name) {
                scope.activeLinkIndex = parseInt(index3);
              }
            }
          }
          break;
        }
      }
    }

    function GetTitleFromIndex(index) {
      return angular.element('.side-menu__wrapper__item__title').eq(index);
    }

    function RespawnActions() {
      for (var index in scope.modules) {
        var module = scope.modules[index];
        if (module.rootName === activeStateObj.parentState) {
          scope.selectedModule = module;
        }
      }
    }

    function link(index, parentIndex) {
      scope.selectedMenuIndex = parentIndex;
      scope.activeLinkIndex = index;
      scope.activeActionIndex = -1;
    }

    function linkAction(index, parentIndex) {
      scope.selectedMenuIndex = parentIndex;
      scope.activeActionIndex = index;
      scope.activeLinkIndex = -1;
    }

    function hideActiveTooltip(el) {
      el.classList.remove(showTooltipClass);
      activeTooltip = null;
      scope.activeMenuIndex = -1;
      scope.activeMenuHeight = 0;
    }

    function openTooltip(compactMenu, index, event) {
      if (compactMenu) {
        let parentElement = angular.element(event.currentTarget)[0];
        let element = document.getElementById('sidemenu-tooltip-' + index);
        let arrowElement = element.getElementsByClassName(showTooltipClass)[0];
        const offset = parentElement.getBoundingClientRect().top - 50; // 50 px for header offset
        const windowOffset = element.getBoundingClientRect().top - 50; // 50 px for header offset
        const windowHeight = angular.element(window).height(); // 50 px for header offset
        const sideMenuTooltipHeight = element.clientHeight;
        if (offset + sideMenuTooltipHeight > windowHeight - 50 - 10) {
          element.style.bottom = '10px';
          element.style.top = '';
          arrowElement.style.top = offset - windowOffset + 26 + 'px';
        } else {
          element.style.bottom = '';
          element.style.top = offset + 'px';
          arrowElement.style.top = '26px';
        }
        /*************
        MEMORY CLEANUP
        *************/
        arrowElement = null;
        parentElement = null;
        element = null;
      }
    }

    function toggle(event, index, init) {
      // toggle tooltip if using compact menu
      var el, newContainer, tempChild;
      if (scope.compactMenu) {
        // hide any open tooltip
        if (activeTooltip && activeTooltip !== el) {
          hideActiveTooltip(activeTooltip);
        }
        scope.activeMenuIndexForCompactMenu = index;
        scope.activeMenuIndex = index;
        scope.activeMenuHeight = 0;
      } else {
        el = init ? event : angular.element(event.target);
        if (el.prop('tagName') === 'SPAN' || el.prop('tagName') === 'MD-ICON') {
          el = el.parent();
        }

        if (init || index !== scope.activeMenuIndex) {
          var height = 0;
          newContainer = el.next();
          tempChild;
          angular.forEach(newContainer.find('dd'), function(child) {
            tempChild = angular.element(child);
            height += tempChild.prop('offsetHeight');
          });
          scope.activeMenuIndex = index;
          scope.activeMenuHeight = height;
        } else {
          scope.activeMenuIndex = -1;
          scope.activeMenuHeight = 0;
        }
      }
      /*************
      MEMORY CLEANUP
      *************/
      el = null;
      newContainer = null;
      tempChild = null;
    }

    function closeSideMenuToolTip() {
      scope.activeMenuIndexForCompactMenu = -1;
      scope.activeMenuIndex = -1;
    }

    function BuildMenu() {
      const activeStateObj = $state.current;
      NavigationMenuService.buildMenu(allStates, activeStateObj, accessScopes);
      scope.activeMenuIndex = scope.modules.findIndex(
        module => module.rootName == activeStateObj.parentState
      );
    }
  }
}

export default sfeSideMenu;
