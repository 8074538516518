/**
 * @ngdoc filter
 * @name common.mapperRegex
 * @description Removes regex delimiters.
 * @param {dataType} input
 * @example
 * <div>{{ string | mapperRegex }}</div>
 */
function mapperRegex() {
  return function(input) {
    if (!input) {
      return '';
    }
    if (input[0] === '^') {
      input = input.slice(1);
    }
    if (input[input.length - 1] === '$') {
      input = input.substring(0, input.length - 1);
    }
    return input;
  };
}

export default mapperRegex;
