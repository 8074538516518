import template from './sfe-root-access-item.component.html';
import './root-access-item.scss';
/**
 * @ngdoc component
 * @name user.sfeRootAccessItem
 * @description component is used to display and interact with root access item.
 * @param {object} item item to be displayed
 * @param {string} instanceScope scope identifier
 * @param {bool} relistData when value is set to true items relist is triggered in parent component
 * @param {bool} isLoading indicates if data is loading
 * @example
 *  <sfe-root-access-item
 *   is-loading="vm.loading"
 *   item="item"
 *   instance-scope="vm.instanceScope"
 *   relist-data="vm.relistData"
 * ></sfe-root-access-item>
 */
export default {
  template,
  bindings: {
    item: '<',
    instanceScope: '<',
    relistData: '=',
    isLoading: '='
  },
  controllerAs: 'vm',
  controller: SfeRootAccessItemController
};

SfeRootAccessItemController.$inject = [
  'gettext',
  'AlertingService',
  'EntitiesService',
  'ToastService',
  'CrawlerMethods',
  'InfoDialog',
  '$mdDialog',
  'gettextCatalog',
  '$scope'
];

function SfeRootAccessItemController(
  gettext,
  AlertingService,
  EntitiesService,
  ToastService,
  CrawlerMethods,
  InfoDialog,
  $mdDialog,
  gettextCatalog,
  $scope
) {
  const vm = this;
  vm.accessKeys = [
    gettext('read'),
    gettext('update'),
    gettext('delete'),
    gettext('list')
  ];

  vm.toggleRootAccess = toggleRootAccess;
  /**
   * @description triggered when component bindings are initialized. Triggers data initiating.
   * @function
   */
  vm.$onInit = async () => {
    vm.displayFields = EntitiesService.getDisplayFields(vm.instanceScope) || [
      'name'
    ];
    await fetchRootAccessEntities();
    $scope.$evalAsync();
  };
  /**
   * @description opens confirmation dialog and removes item from the permission access list.
   * @function
   */
  vm.removeInstance = function() {
    let title = gettext('Remove selected direct access');
    let textItem = {
      text: gettext('Are you sure you want to remove this direct access?'),
      type: 'text'
    };
    let actions = [
      {
        title: gettext('Cancel'),
        cancel: true,
        color: 'primary'
      },
      {
        title: gettext('Remove'),
        fn: remove,
        color: 'warn'
      }
    ];

    InfoDialog.open(title, null, [textItem], actions);
    /**
     * @description removes iten by setting all its permission to false.
     * @function
     */
    async function remove() {
      let apiObj = {
        owner: vm.item.owner,
        instanceScope: vm.item.instanceScope,
        instanceId: vm.item.instanceId,
        access: {
          read: false,
          update: false,
          list: false,
          delete: false
        }
      };
      vm.isLoading = true;
      try {
        await updateAccess(apiObj);
        $mdDialog.cancel();
        ToastService.showToast(gettext('Configurations updated.'));
        vm.relistData = true;
        vm.isLoading = false;
      } catch (err) {
        $mdDialog.cancel();
        AlertingService.Error(err);
        vm.isLoading = false;
      }
    }
  };
  /**
   * @description fetches item metadata.
   * @function
   */
  async function fetchRootAccessEntities() {
    const networkModel = EntitiesService.getNetworkModel(vm.instanceScope);
    let method;
    let queryParam = 'id';
    if (networkModel) {
      if (networkModel.privilegeMethod) {
        queryParam = networkModel.privilegeMethod.queryParam;
        method = CrawlerMethods.getMethod(networkModel.privilegeMethod);
      } else {
        method = CrawlerMethods.getMethod({
          entity: networkModel.entity,
          method: networkModel.read
        });
      }
    } else {
      /*eslint no-console: ["error", { allow: ["error"] }] */
      console.error(
        'There is no network configuration for ' + vm.instanceScope
      );
    }
    try {
      const { data } = await method({
        [queryParam]: vm.item.instanceId
      });

      vm.item.instanceInfo =
        Array.isArray(data) && data.length > 0 ? data[0] : data;
    } catch (err) {
      AlertingService.Error(err);
      vm.error = gettextCatalog.getString('No entity found');
    }
    $scope.$applyAsync();
  }
  /**
   * @description updates root permission access.
   * @function
   * @param {object} apiObj object that contains new permission values
   * @return {Promise}
   */
  async function updateAccess(apiObj) {
    const method = CrawlerMethods.getMethod({
      entity: 'authorization-root-permissions',
      method: 'create'
    });
    try {
      await method(apiObj);
    } catch (err) {
      AlertingService.Error(err);
    }
  }
  /**
   * @description triggered when permission value is toggled. Saves new values
   * @function
   * @param {string} bkey changed permission key. If set to falsey value then removes all permissions
   */
  async function toggleRootAccess(key) {
    let originalItem = angular.copy(vm.item);
    if (!key) {
      for (let permission in vm.item.access) {
        vm.item.access[permission] = false;
      }
    } else {
      vm.item.access[key] = !vm.item.access[key];
    }
    vm.isLoading = true;
    vm.editMode = true;
    let apiObj = {
      owner: vm.item.owner,
      instanceScope: vm.item.instanceScope,
      instanceId: vm.item.instanceId,
      access: vm.item.access
    };
    try {
      await updateAccess(apiObj);
      vm.isLoading = false;
      vm.editMode = false;
      ToastService.showToast(gettext('Configuration updated.'));
      let thereIsAccessLeft = false;
      vm.accessKeys.forEach(function(accessKey) {
        if (vm.item.access[accessKey]) {
          thereIsAccessLeft = true;
        }
      });
      if (!thereIsAccessLeft) {
        vm.relistData = true;
      }
    } catch (err) {
      vm.isLoading = false;
      vm.editMode = false;
      AlertingService.Error(err);
      vm.item = originalItem;
    }
  }
}
