SmallAlarmCardConfiguration.$inject = ['gettextCatalog'];

function SmallAlarmCardConfiguration(gettextCatalog) {
  function get(userId) {
    return {
      listId: 'smallAlarmCard',
      entity: {
        singular: gettextCatalog.getString('Triggered Alarms'),
        plural: gettextCatalog.getString('Triggered Alarms')
      },
      api: {
        query: {
          entity: 'triggered-alarms',
          method: 'custom.readSmallDashboardCard'
        },
        enrich: async item => {
          item.assigneeId = userId;
        }
      },
      //ONLY TABLE MODE
      modeConfiguration: {
        displayMode: 'table',
        hideModeButtons: true
      },
      noToolbar: true,
      limitOptions: [3],
      title: {
        param: 'severityName',
        colorParam: 'severityColor',
        link: {
          state: 'monitoring-triggered-alarms-list',
          params: [
            {
              valueParam: 'severity',
              queryParam: 'severity'
            },
            {
              valueParam: 'assigneeId',
              queryParam: 'assignee'
            }
          ]
        }
      },
      attributes: [
        {
          title: gettextCatalog.getString('Count'),
          param: 'count',
          type: 'numerical'
        }
      ]
    };
  }
  return {
    get
  };
}
export default SmallAlarmCardConfiguration;
