module.exports = {
  entity: 'system-tags',
  path: 'system-tags',
  entityId: 195,
  name: {
    en: 'System tag',
    sl_SI: 'Sistemska značka'
  },
  networkModel: {
    entity: 'system-tags',
    read: 'read'
  },
  scope: 'tis_be:system-tags',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select system tag',
      sl_SI: 'Izberite sistemsko značko'
    },
    listInfo: {
      en: 'Select system tag from the list below',
      sl_SI: 'Izberite sistemsko značko s spodnjega seznama'
    },
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      }
    ]
  }
}
