energyManagementGroupConfiguration.$inject = [
  'gettext',
  '$q',
  'MultiLanguage',
  'SfeFormDialogService',
  'CrudToastFactory',
  'AlertingService',
  'EnergyManagementGroupModel',
  'LocalizationService',
  'InfoDialog',
  'gettextCatalog',
  'ListFormService'
];

function energyManagementGroupConfiguration(
  gettext,
  $q,
  MultiLanguage,
  SfeFormDialogService,
  CrudToastFactory,
  AlertingService,
  EnergyManagementGroupModel,
  LocalizationService,
  InfoDialog,
  gettextCatalog,
  ListFormService
) {
  const fieldNames = [
    'name',
    'description',
    'code',
    'billingKind',
    'billingType',
    'energySourceType',
    'timeSeriesType',
    'order',
    'tariff'
  ];

  function openCreateDialog(relistData) {
    var config = getForm();
    config.forEach(function(item) {
      item.edit = false;
    });
    var title = gettext('New Energy Management Item');
    var obj = {
      _preserve_: true
    };
    var energySourceType = config.find(
      conf => conf.name === 'energySourceType'
    );
    var order = config.find(conf => conf.name === 'order');

    order.disabledFn = orderDisabledFn.bind({ obj });
    order.customValidation[0].script = orderValidationFn.bind({ obj });
    order.actions[0].fn = openHelperDialog.bind({ obj });

    energySourceType.configuration.change = energySourceTypeChangeFn.bind({
      obj
    });
    SfeFormDialogService.openSfeFormDialog(
      false,
      angular.copy(config),
      obj,
      title
    ).then(function(object) {
      if (object) {
        create(object, config, relistData);
      }
    });
  }

  function orderDisabledFn() {
    var obj = this.obj;
    return !(obj && obj.energySourceType);
  }

  function create(obj, config, relistData) {
    var data = MultiLanguage.constructForm(config, obj);
    data.energySourceType = data.energySourceType._id;
    data.billingKind = data.billingKind._id;
    data.billingType = data.billingType._id;
    data.tariff = data.tariff._id;

    data.timeSeriesType = data.timeSeriesType ? data.timeSeriesType._id : null;
    EnergyManagementGroupModel.create(
      ListFormService.generateBodyObject(data, fieldNames)
    ).then(
      function() {
        CrudToastFactory.toast('create');
        relistData();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function update(id, obj, config, relistData) {
    var data = MultiLanguage.constructForm(config, obj);
    data.energySourceType = data.energySourceType._id;
    data.billingKind = data.billingKind._id;
    data.billingType = data.billingType._id;
    data.tariff = data.tariff._id;
    data.timeSeriesType = data.timeSeriesType ? data.timeSeriesType._id : null;
    EnergyManagementGroupModel.update(
      {
        id: id
      },
      ListFormService.generateBodyObject(data, fieldNames)
    ).then(
      function() {
        CrudToastFactory.toast('update');
        relistData();
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function openUpdateDialog(item, relistData) {
    EnergyManagementGroupModel.custom
      .readAllLang({
        id: item._id,
        populate:
          'billingKind,billingType,energySourceType,timeSeriesType,tariff'
      })
      .then(
        async function(res) {
          const selectedLang = LocalizationService.GetSelectedLangNetworkCode();
          const translatedData = MultiLanguage.translateMultiLanguageValues(
            res.data,
            [
              'billingKind.name',
              'billingType.name',
              'energySourceType.name',
              'timeSeriesType.name',
              'tariff.name'
            ],
            selectedLang
          );
          translatedData._preserve_ = true;
          var config = getForm();
          var title = gettext('Edit Energy Management Item');
          try {
            const { data } = await EnergyManagementGroupModel.read({
              energySourceType: translatedData.energySourceType._id
            });

            translatedData.takenOrders = data
              .reduce((acc, type) => {
                if (type.order)
                  acc.push({
                    order: type.order,
                    name: type.name,
                    code: type.code
                  });
                return acc;
              }, [])
              .sort((a, b) => a.order - b.order);
            translatedData.originalOrder = translatedData.order;

            /* if (!translatedData.order) {
              translatedData.order = findFirstAvailableOrder(
                translatedData.takenOrders
              );
            } */

            var energySourceType = config.find(
              conf => conf.name === 'energySourceType'
            );
            var order = config.find(conf => conf.name === 'order');
            order.disabledFn = orderDisabledFn.bind({ obj: translatedData });
            order.customValidation[0].script = orderValidationFn.bind({
              obj: translatedData
            });
            order.actions[0].fn = openHelperDialog.bind({
              obj: translatedData
            });

            energySourceType.configuration.change = energySourceTypeChangeFn.bind(
              {
                obj: translatedData
              }
            );

            SfeFormDialogService.openSfeFormDialog(
              true,
              config,
              translatedData,
              title
            ).then(function(object) {
              if (object) {
                update(item._id, object, config, relistData);
              }
            });
          } catch (err) {
            AlertingService.Error(err);
          }
        },
        function(err) {
          AlertingService.Error(err);
        }
      );
  }

  /* 
  // functionality for automatically filling the order field in the form with the first available order value
  function findFirstAvailableOrder(orders) {
    if (orders.length) {
      const maxOrder = orders[orders.length - 1].order;
      for (let i = 1; i <= maxOrder + 1; i++) {
        if (!orders.find(order => order.order == i)) return i;
      }
    }
    return 1;
  } */

  async function energySourceTypeChangeFn() {
    var obj = this.obj;
    if (obj.energySourceType && obj.energySourceType._id) {
      try {
        const { data } = await EnergyManagementGroupModel.read({
          energySourceType: obj.energySourceType._id
        });

        obj.takenOrders = data
          .reduce((acc, type) => {
            if (type.order)
              acc.push({ order: type.order, name: type.name, code: type.code });
            return acc;
          }, [])
          .sort((a, b) => a.order - b.order);
        // $timeout(() => (obj.order = findFirstAvailableOrder(obj.takenOrders)));
      } catch (err) {
        AlertingService.Error(err);
        obj.takenOrders = [];
      }
    } else {
      obj.takenOrders = [];
      obj.order = '';
    }
  }

  function orderValidationFn() {
    var obj = this.obj;
    if (obj && obj.takenOrders) {
      if (obj.originalOrder && obj.order && obj.originalOrder === obj.order)
        return true;
      return !obj.takenOrders.find(order => obj.order === order.order);
    }
    return true;
  }

  function getForm() {
    return [
      {
        placeholder: gettext('Name'),
        name: 'name',
        required: true,
        componentType: 'multilanguage'
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        maxlength: 500,
        required: false,
        componentType: 'multilanguage'
      },
      {
        placeholder: gettext('Code'),
        name: 'code',
        componentType: 'textField',
        required: true
      },
      {
        configuration: {
          query: {
            entity: 'energy-source-types',
            method: 'read'
          },
          entity: 'energy-source-types',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Energy Source Type')
          },
          floatingLabel: gettext('Select Energy Source Type'),
          searchParamName: 'filter',
          required: true,
          createRedirect: {
            state: 'configurations-energy-management-energy-source-types-new'
          }
        },
        componentType: 'autocompleteDialog',
        edit: false,
        name: 'energySourceType'
      },
      {
        title: gettext(
          'Please select energy source type before selecting order'
        ),
        componentType: 'title'
      },
      {
        placeholder: gettext('Order'),
        name: 'order',
        type: 'numerical',
        componentType: 'textField',
        pattern: new RegExp('^([0-9])*$'),
        patternExample: '9',
        required: true,
        customValidation: [
          {
            errorMessage: gettext(
              'This value is already taken for the specified energy source type. Please select a different value.'
            ),
            name: 'orderError'
          }
        ],
        actions: [
          {
            fn: openHelperDialog,
            icon: {
              name: 'info_outline',
              type: 2
            },
            color: 'grey'
          }
        ]
      },
      {
        configuration: {
          query: {
            entity: 'billing-types',
            method: 'read'
          },
          entity: 'billing-types',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Billing Type')
          },
          selectedParam: 'id',
          floatingLabel: gettext('Select Billing Type'),
          searchParamName: 'filter',
          required: true,
          createRedirect: {
            state: 'configurations-energy-management-billing-types-list'
          }
        },
        componentType: 'autocompleteDialog',
        edit: false,
        name: 'billingType'
      },
      {
        configuration: {
          query: {
            entity: 'billing-kinds',
            method: 'read'
          },
          entity: 'billing-kinds',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Billing Kind')
          },
          selectedParam: 'id',
          floatingLabel: gettext('Select Billing Kind'),
          searchParamName: 'filter',
          required: true,
          createRedirect: {
            state: 'configurations-energy-management-billing-kinds-list'
          }
        },
        componentType: 'autocompleteDialog',
        edit: false,
        name: 'billingKind'
      },
      {
        configuration: {
          query: {
            entity: 'tariffs',
            method: 'read'
          },
          entity: 'tariffs',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Tariff')
          },
          selectedParam: 'id',
          floatingLabel: gettext('Select Tariff'),
          searchParamName: 'filter',
          required: true,
          createRedirect: {
            state: 'configurations-energy-management-tariffs-list'
          }
        },
        componentType: 'autocompleteDialog',
        edit: false,
        name: 'tariff'
      },
      {
        configuration: {
          query: {
            entity: 'time-series-types',
            method: 'read'
          },
          entity: 'time-series-types',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select Time Series Type')
          },
          floatingLabel: gettext('Select Time Series Type'),
          searchParamName: 'filter',
          createRedirect: {
            state:
              'configurations-company-resources-time-series-time-series-types-new'
          }
        },
        componentType: 'autocompleteDialog',
        edit: false,
        name: 'timeSeriesType'
      }
    ];
  }

  function openHelperDialog() {
    const takenOrders =
      this.obj.takenOrders && this.obj.takenOrders.length
        ? this.obj.takenOrders.sort((a, b) => a.order - b.order)
        : gettext(
          'There are currently no order values selected for this Energy Source Type.'
        );
    const name = this.obj.energySourceType
      ? this.obj.energySourceType.name
      : gettext('Energy Source Type');
    const infoData = [];
    let header;

    if (this.obj.energySourceType) {
      header = gettextCatalog.getString('Used order values for {{name}}', {
        name
      });
      if (takenOrders.length && typeof takenOrders == 'object') {
        const formattedTakenOrders = takenOrders.map(
          ({ order, name, code }) => ({
            type: 'order',
            order,
            name,
            code
          })
        );
        infoData.push(...formattedTakenOrders);
      } else {
        infoData.push({
          type: 'text',
          text: takenOrders
        });
      }
    } else {
      header = gettext('Used order values');
      infoData.push({
        type: 'subtitle',
        text: gettext(
          'Select an Energy Source Type to display used order values.'
        )
      });
    }

    InfoDialog.open(header, null, infoData);
  }

  var configuration = {
    entity: {
      singular: gettext('Energy Management Item'),
      plural: gettext('Energy Management Items')
    },
    api: {
      query: {
        entity: 'energy-management-groups',
        method: 'custom.list'
      },
      enrich: Enrich
    },
    title: {
      param: 'name'
    },
    buttons: [
      {
        title: gettext('Create'),
        fn: openCreateDialog,
        relist: true,
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        action: openUpdateDialog
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'energy-management-groups',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description')
      },
      {
        param: 'code',
        title: gettext('Code'),
        type: 'code'
      },
      {
        param: 'energySourceTypeName',
        title: gettext('Energy source type')
      },
      {
        param: 'order',
        title: gettext('Order'),
        type: 'code'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        query: null,
        display: gettext('Code'),
        param: 'code',
        type: 'string'
      },
      {
        param: 'energySourceType',
        query: {
          entity: 'energy-source-types',
          method: 'read'
        },
        entity: 'energy-source-types',
        display: gettext('Energy Source Type'),
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id',
        refreshObject: {
          order: 'code'
        }
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    item.energySourceTypeName = gettext('None');
    if (
      item.hasOwnProperty('energySourceType') &&
      item.energySourceType !== null
    ) {
      item.energySourceTypeName = item.energySourceType.name;
    }
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default energyManagementGroupConfiguration;
