import './sop-step-task.scss';
import template from './sop-step-task.component.html';

SopStepTaskController.$inject = [
  '$scope',
  'AlertingService',
  'gettext',
  'SopModel',
  'StepModel'
];
/**
 * @ngdoc component
 * @name alarms-and-rules.sopStepTask
 * @description Displays  sop step tasks.
 * @param {Object} sop sop id
 * @param {bool} extendValue indicates if view is extended
 * @example
 * <sop-step-task
 * sop="'sop'"
 * extendValue="'extendValue'"
 * ></sop-step-task>
 */
function SopStepTaskController(
  $scope,
  AlertingService,
  gettext,
  SopModel,
  StepModel
) {
  var vm = this;
  vm.extend = extend;
  vm.$onChanges = async changes => {
    if (changes.sop && vm.sop != null && typeof vm.sop === 'object') {
      vm.steps = await readSteps();
    }
    if (changes.extendValue && vm.extendValue) {
      vm.extended = vm.extendValue;
    }
  };

  vm.next = [
    {
      title: gettext('Next step'),
      fn: next,
      color: 'primary'
    }
  ];

  vm.previous = [
    {
      title: gettext('Previous'),
      fn: previous,
      color: 'primary'
    }
  ];

  function extend() {
    vm.extended = !vm.extended;
  }

  /**
   * @description fetches sop data by id and triggers get steps function.
   * @function
   */
  async function readSteps() {
    if (Array.isArray(vm.sop.steps) && vm.sop.steps.length > 0) {
      try {
        const { data } = await StepModel.read({
          _id: vm.sop.steps,
          populate: 'tasks'
        });
        getCurrentStep();
        return data;
      } catch (err) {
        AlertingService.Error(err);
      }
    }
    return [];
  }
  /**
   * @description sets current index to 0.
   * @function
   */
  function getCurrentStep() {
    vm.currentStepIndex = 0;
  }
  /**
   * @description next step.
   * @function
   */
  function next() {
    if (vm.sop.steps.length - 1 > vm.currentStepIndex) {
      vm.currentStepIndex++;
    }
  }
  /**
   * @description previous step.
   * @function
   */
  function previous() {
    if (vm.currentStepIndex > 0) {
      vm.currentStepIndex--;
    }
  }
}

export default {
  restrict: 'E',
  template,
  bindings: {
    sop: '<',
    extendValue: '<'
  },
  controller: SopStepTaskController,
  controllerAs: 'vm',
  bindToController: true
};
