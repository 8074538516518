/**
 * @ngdoc service
 * @name common.ValidateMathExpression
 * @description math expression helper methods.
 * @property {Function} getErrors
 * @property {Function} getNumberOfUsedVariables
 */

ValidateMathExpression.$inject = ['gettextCatalog'];

export default function ValidateMathExpression(gettextCatalog) {
  /**
   * @description returns expression errors.
   * @function
   * @param {Object} mathObj mathjs parsed object
   * @param {Array} motherFunctionArray
   * @param {Array} errorArray array of errors
   * @param {Array} allowedFunctions
   * @param {Array} allowedOperators
   * @param {Array} allowedVariables
   * @return {Array} array of errors
   */
  function validateMathParseObject(
    mathObj,
    motherFunctionArray,
    errorArray,
    allowedFunctions,
    allowedOperators,
    allowedVariables
  ) {
    var positionString = '';

    for (var index in motherFunctionArray) {
      if (index !== 0) {
        positionString += ' in function ';
      } else if (index === motherFunctionArray.length - 1) {
        positionString += ' in ';
      }
      positionString +=
        motherFunctionArray[motherFunctionArray.length - 1 - index];
    }
    positionString += '.';

    switch (mathObj.type) {
    case 'AssignmentNode':
      errorArray.push({
        error: gettextCatalog.getString('Assignment is not allowed'),
        position: positionString
      });
      break;

      // PARENTHESIS
    case 'ParenthesisNode':
      var motherFunctionArrayNext = motherFunctionArray.concat([
        'Parenthesis'
      ]);
      var obj = mathObj.content;
      validateMathParseObject(
        obj,
        motherFunctionArrayNext,
        errorArray,
        allowedFunctions,
        allowedOperators,
        allowedVariables
      );
      break;

      // FUNCTIONS
    case 'FunctionNode':
      var functionName = mathObj.fn.name;
      if (allowedFunctions.indexOf(functionName) == -1) {
        errorArray.push({
          error: gettextCatalog.getString(
            '{{functionName}} is not a valid function.',
            {
              functionName
            }
          ),
          position: positionString
        });
      }

      motherFunctionArrayNext = motherFunctionArray.concat([
        'function  ' + functionName
      ]);

      for (var index2 in mathObj.args) {
        var obj2 = mathObj.args[index2];
        validateMathParseObject(
          obj2,
          motherFunctionArrayNext,
          errorArray,
          allowedFunctions,
          allowedOperators,
          allowedVariables
        );
      }

      break;

      // OPERATORS
    case 'OperatorNode':
      var functionName2 = mathObj.fn;
      var implicit = mathObj.implicit;
      var operator = mathObj.op;
      if (implicit) {
        errorArray.push({
          error: gettextCatalog.getString(
            'Implicit operation {{functionName2}} is not allowed.',
            {
              functionName2
            }
          ),
          position: positionString
        });
      }

      if (allowedOperators.indexOf(operator) == -1) {
        errorArray.push({
          error: gettextCatalog.getString(
            'Operation {{functionName2}} is not allowed.',
            {
              functionName2
            }
          ),
          position: positionString
        });
      }

      motherFunctionArrayNext = motherFunctionArray.concat([
        'operator ' + functionName2
      ]);

      for (var index3 in mathObj.args) {
        var obj3 = mathObj.args[index3];
        validateMathParseObject(
          obj3,
          motherFunctionArrayNext,
          errorArray,
          allowedFunctions,
          allowedOperators,
          allowedVariables
        );
      }
      break;

      // VARIABLES
    case 'SymbolNode':
      var name = mathObj.name;
      var existsAsVariable = false;

      if (allowedVariables) {
        allowedVariables.forEach(function(variableName) {
          if (variableName === name) {
            existsAsVariable = true;
          }
        });
      }
      if (!existsAsVariable) {
        errorArray.push({
          error: gettextCatalog.getString(
            'Variable {{name}} is not defined',
            {
              name
            }
          ),
          position: positionString
        });
      }
      break;
    }

    return errorArray;
  }
  /**
   * @description when  variable names are not on the list of variables returns error otherwise returns nothing.
   * @function
   * @param {Object} mathObj parsed expression string
   * @return {String|null}
   */
  function validateVariableNames(mathObj, allowedVariables) {
    const name = mathObj.name;
    let existsAsVariable = false;
    switch (mathObj.type) {
    case 'SymbolNode':
      if (Array.isArray(allowedVariables)) {
        existsAsVariable = allowedVariables.indexOf(name) > -1;
      }
      if (!existsAsVariable) {
        return gettextCatalog.getString('Variable {{name}} is not defined', {
          name
        });
      }
      break;
    }
  }
  /**
   * @memberof common.getNumberOfUsedVariables
   * @description returns number of used variables.
   * @param {Object} mathObj mathjs parsed object
   * @param {Number} numberOfUsedVariables number of used variables
   * @return {Number}
   */
  function getNumberOfUsedVariables(mathObj, numberOfUsedVariables) {
    if (typeof numberOfUsedVariables === 'undefined') {
      numberOfUsedVariables = {
        number: 0
      };
    }

    switch (mathObj.type) {
    case 'ParenthesisNode':
      var obj = mathObj.content;
      getNumberOfUsedVariables(obj, numberOfUsedVariables);
      break;

      // FUNCTIONS
    case 'FunctionNode':
      for (var index2 in mathObj.args) {
        var obj2 = mathObj.args[index2];
        getNumberOfUsedVariables(obj2, numberOfUsedVariables);
      }
      break;

      // OPERATORS
    case 'OperatorNode':
      for (var index3 in mathObj.args) {
        var obj3 = mathObj.args[index3];
        getNumberOfUsedVariables(obj3, numberOfUsedVariables);
      }
      break;

      // VARIABLES
    case 'SymbolNode':
      numberOfUsedVariables.number++;
      break;
    }
    return numberOfUsedVariables.number;
  }
  /**
   * @description parses number to bigNumber and then signifies it to avoid displaying e notation.
   * After node is stringified quotes must be removed
   * @function
   * @param {Object} node mathjs node
   * @return {Object} mathjs node
   */
  function transformNumber(node) {
    if (typeof node.value == 'number' && node.value != 0) {
      node.value = math.bignumber(node.value).toString();
    }
    return node;
  }
  return {
    getErrors: validateMathParseObject,
    getNumberOfUsedVariables,
    transformNumber,
    validateVariableNames
  };
}
