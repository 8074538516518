module.exports = {
  entity: 'ownerships',
  path: 'ownerships',
  name: {
    en: 'Ownerships',
    sl_SI: 'lastništva'
  },
  networkModel: {
    entity: 'ownerships',
    read: 'read'
  },
  scope: 'tis_be:ownerships',

  selectedParamName: 'id',
  dialog: {
    title: {
      en: 'Select ownership',
      sl_SI: 'Izberite lastništvo'
    },
    listInfo: {
      en: 'Select ownership from the list below',
      sl_SI: 'Izberite lastništvo s spodnjega seznama'
    }
  }
}
