import types from './external-writer-flow.type';

function readExternalWriterFlow(payload) {
  return {
    type: types.EXTERNAL_WRITER_FLOW_READ,
    payload
  };
}

function updateExternalWriterFlow(payload) {
  return {
    type: types.EXTERNAL_WRITER_FLOW_UPDATE,
    payload
  };
}

function createExternalWriterFlow(payload) {
  return {
    type: types.EXTERNAL_WRITER_FLOW_CREATE,
    payload
  };
}

function addExternalWriterFlow(payload) {
  return {
    type: types.EXTERNAL_WRITER_FLOW_ADD,
    payload
  };
}

export default {
  readExternalWriterFlow,
  updateExternalWriterFlow,
  createExternalWriterFlow,
  addExternalWriterFlow
};
