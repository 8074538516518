module.exports = {
  entity: 'rule-target-for-x-axis-intervals',
  path: 'rule-target-for-x-axis-intervals',
  name: {
    en: 'Rule target for x axis interval',
    sl_SI: 'Pravilo za ciljno funkcijo'
  },
  networkModel: {
    entity: 'rule-target-for-x-axis-intervals',
    read: 'read'
  },
  scope: 'tis_be:rule-target-for-x-axis-intervals',
  assigned: 'tis_be:rule-target-for-x-axis-intervals',
  dialog: {
    title: {
      en: 'Select rule target for x axis interval',
      sl_SI: 'Izberite  pravilo za ciljno funkcijo'
    },
    listInfo: {
      en: 'Select rule target for x axis interval from the list below',
      sl_SI: 'Izberite pravilo za ciljno funkcijo s spodnjega seznama'
    }
  }
}
