import './manual-input.scss';
import { DateTime } from 'luxon';

ManualInputController.$inject = [
  '$stateParams',
  'gettext',
  'CrudToastFactory',
  'SfeListConfigurations',
  'SfeFormDialogService',
  '$timeout',
  'AlertingService',
  'InfoDialog',
  'TranslationService',
  'MetadataService',
  'TimeSeriesManualInputFormGroupModel',
  'TimeSeriesManualInputFormGroupTimeSeriesItemModel',
  '$mdDialog',
  'gettextCatalog',
  '$scope',
  'manualInput'
];

function ManualInputController(
  $stateParams,
  gettext,
  CrudToastFactory,
  SfeListConfigurations,
  SfeFormDialogService,
  $timeout,
  AlertingService,
  InfoDialog,
  TranslationService,
  MetadataService,
  TimeSeriesManualInputFormGroupModel,
  TimeSeriesManualInputFormGroupTimeSeriesItemModel,
  $mdDialog,
  gettextCatalog,
  $scope,
  manualInput
) {
  const vm = this;

  vm.manualInputId = $stateParams.id;
  vm.addGroup = addGroup;
  vm.editGroup = editGroup;
  vm.removeGroup = removeGroup;
  vm.saveGroup = saveGroup;

  /**
   * @description Fetches the manual input and configures its groups data.
   * @function
   */
  vm.$onInit = function init() {
    MetadataService.Loading(true);
    vm.manualInputName = manualInput.name;
    if (manualInput.groups) {
      vm.groups = configureGroups(manualInput.groups);
    }
    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(vm.manualInputName);
    });
  };

  // ACTIONS
  /**
   * @description If no groups exist, it initializes the array with its initial object,
   * otherwise it just pushes the initial object into the array of groups.
   * @function
   */
  function addGroup() {
    if (!vm.groups) {
      vm.groups = [
        {
          edit: true
        }
      ];
    } else {
      vm.groups.push({
        edit: true
      });
    }
  }

  /**
   * @description Initializes the edition of groups by setting the edit param of the chosen group to true.
   * @function
   * @param {number} index Index of the group to be edited.
   */
  function editGroup(index) {
    vm.groups[index].edit = true;
  }

  /**
   * @description Initializes the removal of the chosen group by setting up the data for the confirmation dialog and triggering the dialog.
   * @function
   * @param {number} index Index of the group to be removed.
   */
  function removeGroup(index) {
    if (vm.groups[index] && vm.groups[index]._id) {
      const { title, promptMessage, actions } = getRemoveGroupDialogConfig(
        index
      );
      InfoDialog.open(title, null, [promptMessage], actions);
    } else {
      vm.groups.splice(index, 1);
    }
  }

  /**
   * @description Saves the changed data of the group and updates the view based on the saved data.
   * @function
   * @param {number} index Index of the group to be saved.
   */
  async function saveGroup(index) {
    try {
      const type = vm.groups[index]._id ? 'update' : 'create';
      const { data: group } = await createOrUpdateGroup(vm.groups[index]);

      vm.groups[index].edit = false;
      vm.groups[index]._id = group._id;
      vm.groups[index] = configureGroup(vm.groups[index]);
      CrudToastFactory.toast(type);
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  /**
   * @description Opens the form for adding a manual input time series to the group and handles the
   * continuation of the process.
   * @function
   * @param {Function} relistData Refreshes the view on adding the manual input time series.
   */
  async function addManualInputTimeSeries(relistData) {
    const groupId = this.groupId;
    const obj = {
      days: 0,
      hours: 0,
      minutes: 15,
      seconds: 0,
      _preserve_: true
    };
    const formFieldsConfig = getFormFieldsConfig(obj, false);
    try {
      const formData = await SfeFormDialogService.openSfeFormDialog(
        false,
        formFieldsConfig,
        obj,
        gettext('Add Manual Input Time Series')
      );
      if (formData) {
        let postObject = getManualInputObject(formData);
        try {
          await TimeSeriesManualInputFormGroupTimeSeriesItemModel.create(
            {
              timeSeriesManualInputFormId: vm.manualInputId,
              groupId
            },
            postObject
          );
          if (relistData) {
            relistData();
          }
          CrudToastFactory.toast('create');
        } catch (err) {
          AlertingService.Error(err);
        }
      }
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  function setDefaultValueToEditObject(obj) {
    switch (obj.mainTimeSeries.dataType) {
    case 1:
      obj.defaultCheckbox = obj.defaultValue;
      break;
    case 5:
      obj.defaultDate = new Date(obj.defaultValue);
      break;
    case 6:
      obj.defaultValue = DateTime.fromJSDate(
        new Date(obj.defaultValue)
      ).toFormat('HH:mm:ss');
      break;
    case 7:
      obj.defaultDate = new Date(obj.defaultValue);
      obj.defaultTime = DateTime.fromJSDate(obj.defaultDate).toFormat(
        'HH:mm:ss'
      );
      break;
    default:
      obj.defaultCheckbox = obj.defaultValue;
    }
  }

  /**
   * @description Opens the form for editing a manual input time series and handles the continuation of the process.
   * @function
   * @param {Object} item Selected item that is to be edited.
   * @param {Function} relistData Refreshes the view on adding the manual input time series.
   */
  async function editManualInputTimeSeries(item, relistData) {
    const currentlySelectedMainTimeSeries = item.timeSeriesId;
    const updateParams = this;
    let obj = await configureObjToEdit(item);

    const formFieldsConfig = getFormFieldsConfig(
      obj,
      true,
      currentlySelectedMainTimeSeries
    );
    obj['_preserve_'] = true;

    if (obj.showDefaultValue) {
      setDefaultValueInputs(obj, formFieldsConfig);
      setDefaultValueToEditObject(obj);
    }

    try {
      const formData = await SfeFormDialogService.openSfeFormDialog(
        true,
        formFieldsConfig,
        obj,
        gettext('Edit Manual Input Time Series')
      );
      if (formData) {
        const putObject = getManualInputObject(formData);
        try {
          await TimeSeriesManualInputFormGroupTimeSeriesItemModel.update(
            {
              timeSeriesItemId: item._id,
              timeSeriesManualInputFormId: updateParams.manualInputId,
              groupId: updateParams.groupId
            },
            putObject
          );
          relistData();
          CrudToastFactory.toast('update');
        } catch (err) {
          AlertingService.Error(err);
        }
      }
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  /**
   * @description Opens the form for removing a manual input time series and handles the continuation of the process.
   * @function
   * @param {Object} item Selected item that is to be removed.
   * @param {Function} relistData Refreshes the view on adding the manual input time series.
   */
  function removeManualInputTimeSeries(item, relistData) {
    let deleteParams = this;
    let title = gettextCatalog.getString('Confirmation');
    let textItem = {
      text: gettextCatalog.getString(
        'Are you sure you want to delete Manual Input Time Series?'
      ),
      type: 'text'
    };
    let actions = [
      {
        title: gettext('Cancel'),
        cancel: true,
        color: 'primary'
      },
      {
        title: gettext('Delete'),
        fn: async function() {
          try {
            await TimeSeriesManualInputFormGroupTimeSeriesItemModel.delete({
              timeSeriesItemId: item._id,
              timeSeriesManualInputFormId: deleteParams.manualInputId,
              groupId: deleteParams.groupId
            });
            CrudToastFactory.toast('delete');
            relistData();
            $mdDialog.cancel();
          } catch (err) {
            AlertingService.Error(err);
            $mdDialog.cancel();
          }
        },
        color: 'warn'
      }
    ];
    InfoDialog.open(title, null, [textItem], actions);
  }

  // BUSINESS LOGIC

  /**
   * @description Configures all the fetched groups. Calls configureGroup on mapping, which sets up the list config for all the groups.
   * @function
   * @param {Array} groups Groups that need to be configured.
   * @return {Array} Configured groups.
   */
  function configureGroups(groups) {
    return groups.map(group => configureGroup(group));
  }

  /**
   * @description Adds list configuration with all the actions for the selected group.
   * @function
   * @param {Object} group Group that needs to get the list config with the filter object.
   * @return {Object} Configured group.
   */
  function configureGroup(group) {
    let config =
      SfeListConfigurations.configuration['time-series-manual-input'];
    group.filterObject = {
      timeSeriesManualInputFormId: vm.manualInputId,
      groupId: group._id
    };
    group.listConfig = angular.copy(config);
    Object.assign(
      group.listConfig,
      getManualInputGroupItemsListConfig(group._id)
    );
    return group;
  }

  /**
   * @description Configures the actions for the given group.
   * @function
   * @param {string} groupId Id of the group.
   * @return {Object} List configuration of the group.
   */
  function getManualInputGroupItemsListConfig(groupId) {
    const group = {
      groupId,
      manualInputId: vm.manualInputId
    };
    const config = {
      filter: null,
      create: undefined,
      buttons: [
        {
          title: gettext('+ add time series'),
          fn: addManualInputTimeSeries.bind(group),
          relist: true
        }
      ],
      shortActions: [
        {
          icon: 'mode_edit',
          action: editManualInputTimeSeries.bind(group)
        },
        {
          icon: 'delete',
          action: removeManualInputTimeSeries.bind(group),
          group: group
        }
      ]
    };
    return angular.copy(config);
  }

  /**
   * @description Returns the needed config values for the info dialog which is displayed when the user wants to remove a group.
   * @function
   * @param {number} index Index of the group to be removed.
   * @return {Object} Configuration.
   */
  function getRemoveGroupDialogConfig(index) {
    return {
      title: gettext('Remove group'),
      promptMessage: {
        text: gettext('Are you sure you want to remove this group?'),
        type: 'text'
      },
      actions: [
        {
          title: gettext('Cancel'),
          cancel: true,
          color: 'primary'
        },
        {
          title: gettext('Yes'),
          fn: removeFn.bind({
            index
          }),
          color: 'warn'
        }
      ]
    };
  }
  /**
   * @description Configures and returns either update or create function that needs to be executed on creating/updating a group.
   * @function
   * @param {Object} group Contains the name and the id of the group to be updated/created.
   * @return {Function} Create or update function for the group.
   */
  function createOrUpdateGroup(group) {
    let postObject = {
      name: group.name
    };
    if (group._id) {
      return TimeSeriesManualInputFormGroupModel.update(
        {
          manualInputId: vm.manualInputId,
          groupId: group._id
        },
        postObject
      );
    }
    return TimeSeriesManualInputFormGroupModel.create(
      {
        manualInputId: vm.manualInputId
      },
      postObject
    );
  }

  /**
   * @description Deletes the group from the base and updates the view model of groups.
   * @function
   */
  async function removeFn() {
    const index = this.index;
    $mdDialog.cancel();
    try {
      await TimeSeriesManualInputFormGroupModel.delete({
        manualInputId: vm.manualInputId,
        groupId: vm.groups[index]._id
      });
      vm.groups.splice(index, 1);
      CrudToastFactory.toast('delete');
      $scope.$evalAsync();
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  function getCustomValidation(obj) {
    return [
      {
        script: () => {
          if (obj.mainTimeSeries.dataType == 2) {
            return String(obj.defaultValue).match(/^([0-9])*$/);
          }
          return true;
        },
        errorMessage: gettext('Value should be an integer.'),
        name: 'orderError'
      }
    ];
  }

  /**
   * @description sets properties to hide default inputs.
   * @function
   * @param {Object} obj for object
   */
  function hideAllDefaultInputs(obj) {
    obj.literalCheckbox = false;
    obj.literalInput = false;
    obj.literalDateTime = false;
    obj.literalNumerical = false;
    obj.literalTime = false;
  }

  function defaultValueChanged(value) {
    const { obj, config } = this;
    if (!value) {
      obj.showDefaultValue = false;
      obj.timeSeriesSelected = false;
      hideAllDefaultInputs(obj);
    } else {
      // re-init default inputs
      if (obj.defaultValueType) {
        defaultValueEnumChanged.apply({ obj, config }, [obj.defaultValueType]);
      }
    }
  }

  function defaultValueEnumChanged(model) {
    const obj = this.obj;
    const configurations = this.config;
    if (model) {
      switch (model._id) {
      case 1: //LAST VALUE
        obj.showDefaultValue = false;
        obj.timeSeriesSelected = false;
        hideAllDefaultInputs(obj);
        break;
      case 2: //TIME SERIES
        obj.showDefaultValue = false;
        obj.timeSeriesSelected = true;
        hideAllDefaultInputs(obj);
        break;
      case 3: //LITERAL VALUE
        obj.showDefaultValue = true;
        obj.timeSeriesSelected = false;
        break;
      }
      if (obj.showDefaultValue && Array.isArray(configurations)) {
        setDefaultValueInputs(obj, configurations);
      }
    }
  }

  /**
   * @description called when type of default value is literal.
   * on main time-series change or type of default value
   * sets default values inputs according to time-series data type
   * @function
   * @param {Object} obj form object
   * @param {Array} configurations array of configurations
   */
  function setDefaultValueInputs(obj, configurations) {
    hideAllDefaultInputs(obj);
    if (typeof obj.mainTimeSeries == 'object' && obj.mainTimeSeries != null) {
      let config;
      switch (obj.mainTimeSeries.dataType) {
      case 1: //Boolean
        obj.literalCheckbox = true;
        break;
      case 2: //Int
        obj.literalNumerical = true;
        break;
      case 3: //DECIMAL
        obj.literalNumerical = true;
        break;
      case 4: //TEXT
        obj.literalInput = true;
        break;
      case 5: //DATE
        config = configurations.find(
          config => config.name == 'defaultDateTime'
        );
        obj.literalDateTime = true;
        config.noTimeInput = true;
        break;
      case 6: //TIME
        obj.literalTime = true;
        break;
      case 7: //DATE_TIME
        config = configurations.find(
          config => config.name == 'defaultDateTime'
        );
        obj.literalDateTime = true;
        config.noTimeInput = false;
        break;
      default:
      }
    }
  }

  function validateDefaultTimeSeries(obj) {
    if (
      typeof obj.defaultValueTimeSeries == 'object' &&
      obj.defaultValueTimeSeries != null &&
      obj.mainTimeSeries != null &&
      obj.mainTimeSeries.dataSamplingType == 100 /** REGULAR */
    ) {
      const { dataScheduler } = obj.defaultValueTimeSeries;
      if (dataScheduler) {
        if (
          dataScheduler.crontabExpression !==
          obj.mainScheduler.crontabExpression
        ) {
          AlertingService.Error('Schedulers have different intervals');
          obj.defaultValueTimeSeries = null;
        } else {
          obj.defaultScheduler = dataScheduler;
        }
      } else {
        obj.defaultValueTimeSeries = null;
      }
    } else {
      if (obj.mainTimeSeries.dataSamplingType == 100 /** REGULAR */) {
        obj.defaultScheduler = null;
      }
    }
  }

  /**
   * @description Configures the fields for the add/edit manual input time series form.
   * @function
   * @param {Object} obj Data object
   * @return {Array} Array of fields
   */
  function getFormFieldsConfig(obj, editMode, currentlySelectedMainTimeSeries) {
    const configurationData = [
      {
        name: 'mainTimeSeries',
        edit: editMode,
        componentType: 'autocompleteDialog',
        configuration: getTimeSeriesConfiguration(
          {
            populate: 'dataScheduler',
            'activeTimeSeriesConfiguration.flowType_': 100 //EXTERNAL READER
          },
          obj,
          true,
          currentlySelectedMainTimeSeries
        )
      },
      {
        componentType: 'checkBox',
        name: 'isDefaultValue',
        label: gettext('Default value'),
        action: defaultValueSwitchTrigger.bind({
          obj
        })
      },
      {
        hide: true,
        showParam: 'isDefaultValue',
        componentType: 'multiSelect',
        name: 'defaultValueType',
        config: {
          label: gettext('Default Value Type'),
          placeholder: gettext('Select Default Value Type'),
          ctrlFn: function() {
            return $timeout(function() {
              return TranslationService.GetCollection(
                'codelists.defaultValueTypes'
              );
            });
          },
          valueField: 'id',
          edit: editMode
        }
      },
      // STRING DEFAULT INPUT
      {
        hide: true,
        showParam: 'literalInput',
        placeholder: gettext('Default value'),
        name: 'defaultValue',
        componentType: 'textField',
        type: 'text'
      },
      // INTEGER DECIMAL DEFAULT INPUT
      {
        hide: true,
        showParam: 'literalNumerical',
        placeholder: gettext('Default value'),
        name: 'defaultValue',
        componentType: 'textField',
        type: 'numerical'
      },
      // TIME DEFAULT INPUT
      {
        hide: true,
        showParam: 'literalTime',
        placeholder: gettext('Enter time (hh:mm:ss)'),
        pattern: /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/,
        patternExample: gettextCatalog.getString(
          'value format must be hh:mm:ss (23:59:59)'
        ),
        name: 'defaultValue',
        componentType: 'textField',
        type: 'text'
      },
      // BOOLEAN DEFAULT SWITCH INPUT
      {
        hide: true,
        showParam: 'literalCheckbox',
        label: gettextCatalog.getString('Default value'),
        componentType: 'checkBox',
        name: 'defaultCheckbox'
      },
      {
        hide: true,
        showParam: 'literalDateTime',
        componentType: 'singleDate',
        dateName: 'defaultDate',
        timeName: 'defaultTime',
        dateLabel: gettextCatalog.getString('Date'),
        timeLabel: gettextCatalog.getString('Time'),
        required: true,
        idDate: 'unique',
        noTimeInput: true,
        name: 'defaultDateTime'
      },
      {
        hide: true,
        showParam: 'timeSeriesSelected',
        name: 'defaultValueTimeSeries',
        edit: editMode,
        componentType: 'autocompleteDialog'
      },
      {
        componentType: 'checkBox',
        name: 'allowFutureInput',
        label: gettext('Future input')
      },
      {
        componentType: 'checkBox',
        name: 'limitedHistory',
        label: gettext('Limit history')
      },
      {
        componentType: 'fourInRow',
        hide: true,
        showParam: 'limitedHistory',
        subData: [
          {
            placeholder: 'Days',
            name: 'days',
            pattern: new RegExp('^([0-9])*$'),
            patternExample: '14',
            type: 'number',
            required: true
          },
          {
            placeholder: 'Hours',
            name: 'hours',
            type: 'number',
            min: 0,
            max: 23,
            required: true
          },
          {
            placeholder: 'Minutes',
            name: 'minutes',
            type: 'number',
            min: 0,
            max: 59,
            required: true
          },
          {
            placeholder: 'Seconds',
            name: 'seconds',
            type: 'number',
            min: 0,
            max: 59,
            required: true
          }
        ]
      }
    ];

    const timeSeriesConfiguration = getTimeSeriesConfiguration({});
    timeSeriesConfiguration.filterObjectFn = () => {
      return {
        dataType: obj.mainTimeSeries ? obj.mainTimeSeries.dataType : undefined,
        populate: 'dataScheduler'
      };
    };
    timeSeriesConfiguration.change = function() {
      validateDefaultTimeSeries(obj);
    };
    timeSeriesConfiguration.filterFn = items => {
      let timeZone = obj.mainTimeSeries.timeZone;
      return items.filter(item => {
        if (obj.mainTimeSeries != null) {
          if (obj.mainTimeSeries.dataSamplingType == 100 /*REGULAR */) {
            if (
              obj.mainTimeSeries._id == item._id ||
              (timeZone != null && item.timeZone !== timeZone)
            ) {
              return false;
            }
          } else if (
            obj.mainTimeSeries.dataSamplingType == 200 /*IRREGULAR */ &&
            obj.mainTimeSeries._id == item._id
          ) {
            return false;
          }
        }
        return true;
      });
    };

    configurationData.forEach(function(config) {
      switch (config.name) {
      case 'mainTimeSeries':
        config.configuration.change = validateMainTimeSeriesScheduler.bind({
          obj,
          configurations: configurationData
        });
        break;
      case 'defaultValue':
        config.customValidation = getCustomValidation(obj);
        break;
      case 'isDefaultValue':
        config.action = defaultValueChanged.bind({
          obj: obj,
          config: configurationData
        });
        break;
      case 'defaultValueType':
        config.config.onClose = defaultValueEnumChanged.bind({
          obj: obj,
          config: configurationData
        });
        break;
      case 'defaultValueTimeSeries':
        config.configuration = {
          ...timeSeriesConfiguration,
          disabledFn: () => {
            return !obj || !obj.mainTimeSeries;
          }
        };
        break;
      }
    });
    return configurationData;
  }

  /**
   * @description Configures the autocomplete for main or default time series.
   * @function
   * @param {Array} allowedKinds 1 for main time series and 1, 3 for default time series
   * @param {Object} obj Data object needed to be bound to the scheduler validation function
   * @param {boolean} main Used for defining the scheduler validation function
   * @param {string} currentlySelectedMainTimeSeries when it is main time series edit form selected id is needed to filter time series items
   * @return {Object} timeSeriesConfiguration - autocomplete config
   */
  function getTimeSeriesConfiguration(
    filterObj,
    obj,
    main,
    currentlySelectedMainTimeSeries
  ) {
    const timeSeriesFilters = angular.copy(
      SfeListConfigurations.configuration['time-series'].filter
    );
    const timeSeriesConfiguration = {
      query: {
        entity: 'time-series',
        method: 'read'
      },
      floatingLabel: gettext('Select time series'),
      searchParamName: 'filter',
      entity: 'time-series',
      dialogConfiguration: {
        multiple: false,
        filter: timeSeriesFilters,
        filterObject: {
          populate: 'dataScheduler',
          ...filterObj
        },
        displayFields: [
          {
            field1: 'name',
            type: 'string'
          },
          {
            field1: 'dataScheduler',
            field2: 'scheduleClassification',
            codelist: 'scheduleClassifications',
            type: 'codelist'
          },
          {
            field1: 'timeZone',
            codelist: 'timeZones',
            type: 'codelist'
          }
        ]
      },
      filterObject: {
        populate: 'dataScheduler',
        ...filterObj
      },

      createRedirect: {
        state: 'data-time-series-new'
      },
      selectedParam: 'id',
      required: true,
      displayFields: [
        {
          field1: 'name',
          type: 'string'
        },
        {
          field1: 'dataScheduler',
          field2: 'scheduleClassification',
          codelist: 'scheduleClassifications',
          type: 'codelist'
        },
        {
          field1: 'timeZone',
          codelist: 'timeZones',
          type: 'codelist'
        }
      ]
    };
    let timeZone;
    let dataSamplingType;
    if (
      Array.isArray(vm.groups) &&
      vm.groups.length > 0 &&
      Array.isArray(vm.groups[0].timeSeries) &&
      vm.groups[0].timeSeries.length > 0
    ) {
      timeZone = vm.groups[0].timeSeries[0].timeZone;
      if (vm.groups[0].timeSeries[0].timeSeries != null) {
        dataSamplingType =
          vm.groups[0].timeSeries[0].timeSeries.dataSamplingType;
      }
    }
    if (!main) {
      timeSeriesConfiguration.change = validateDefaultTimeSeriesScheduler.bind({
        obj
      });
    } else {
      timeSeriesConfiguration.filterFn = items =>
        items.filter(item => {
          if (
            currentlySelectedMainTimeSeries != null &&
            vm.groups.length === 1 &&
            vm.groups[0].timeSeries.length === 1
          ) {
            return true;
          } else {
            if (
              currentlySelectedMainTimeSeries == item._id ||
              (!isTimeSeriesAdded(item._id) &&
                (dataSamplingType == null ||
                  item.dataSamplingType == dataSamplingType))
            ) {
              if (item.dataSamplingType == 200) {
                /*IRREGULAR*/

                return true;
              } else {
                return timeZone == null || item.timeZone === timeZone;
              }
            }
          }
        });
    }
    return timeSeriesConfiguration;
  }

  /**
   * @description If it's deselected, it will hide the default value.
   * @function
   * @param {boolean} value true - selected / false - deselected
   */
  function defaultValueSwitchTrigger(value) {
    let obj = this.obj;
    if (!value) {
      obj.showDefaultValue = false;
      obj.showDefaultValueTimeSeries = false;
    }
  }

  /**
   * @description A function for final config of the input data that is going to be sent upon creation of the manual input time series.
   * @function
   * @param {Object} formData Object containing the data that was input into the form
   * @return {Object} Final form of the object of the manual input time series that is going to be created.
   */
  function getManualInputObject(formResult) {
    let hour, minute, second;
    const limitHistoryTime =
      (formResult.seconds * 1 +
        formResult.minutes * 60 +
        formResult.hours * 60 * 60 +
        formResult.days * 60 * 60 * 24) *
      1000;
    let createUpdateObject = {
      allowFutureInput: formResult.allowFutureInput,
      limitHistoryTime: formResult.limitedHistory ? limitHistoryTime : null,
      inputFieldType: 1, //formResult.inputFieldType._id, hardcodirano dokler ne bo be narejen
      timeSeries: formResult.mainTimeSeries._id
    };

    if (formResult.isDefaultValue) {
      createUpdateObject.defaultValueType = formResult.defaultValueType._id;
      switch (formResult.defaultValueType._id) {
      case 1:
        createUpdateObject.defaultValueTimeSeriesId = null;
        createUpdateObject.defaultValue = null;
        break;
      case 2:
        createUpdateObject.defaultValueTimeSeriesId =
            formResult.defaultValueTimeSeries._id;

        createUpdateObject.defaultValue = null;
        break;
      case 3:
        switch (formResult.mainTimeSeries.dataType) {
        case 1:
          createUpdateObject.defaultValue = Boolean(
            formResult.defaultCheckbox
          );
          break;
        case 2:
        case 3:
        case 4:
          createUpdateObject.defaultValue = formResult.defaultValue;
          break;
        case 5:
          createUpdateObject.defaultValue = DateTime.fromJSDate(
            new Date(formResult.defaultDate)
          ).toMillis();
          break;
        case 6:
          // eslint-disable-next-line no-case-declarations
          [hour, minute, second] = formResult.defaultValue.split(':');
          createUpdateObject.defaultValue = DateTime.fromObject({
            hour,
            minute,
            second
          }).toMillis();
          break;
        case 7:
          // eslint-disable-next-line no-case-declarations
          [hour, minute, second] = formResult.defaultTime.split(':');
          createUpdateObject.defaultValue = DateTime.fromJSDate(
            new Date(formResult.defaultDate)
          )
            .set({ hour, minute, second })
            .toMillis();
          break;
        }

        createUpdateObject.defaultValueTimeSeriesId = null;
        break;
      default:
        createUpdateObject.defaultValueTimeSeriesId = null;
        createUpdateObject.defaultValue = null;
      }
    } else {
      createUpdateObject.defaultValueTimeSeriesId = null;
      createUpdateObject.defaultValue = null;
    }
    return createUpdateObject;
  }

  /**
   * @description Sets up the item that is going to be edited for proper display of data.
   * @function
   * @param {Object} item Item to be configured for editing.
   * @return {Object} Configured item.
   */
  async function configureObjToEdit(item) {
    let obj = angular.copy(item);
    let sec_num = obj.limitHistoryTime / 1000;
    obj.days = Math.floor(sec_num / 3600 / 24) || 0;
    obj.hours = Math.floor(sec_num / 3600) - obj.days * 24 || 0;
    obj.minutes = Math.floor(
      (sec_num - obj.hours * 3600) / 60 - obj.days * 24 * 60 || 0
    );
    obj.seconds =
      sec_num - obj.hours * 3600 - obj.minutes * 60 - obj.days * 24 * 3600 || 0;
    obj.inputFieldType = {
      _id: obj.inputFieldType
    };
    if (typeof obj.limitHistoryTime !== 'undefined') {
      obj.limitedHistory = true;
    }

    obj.mainTimeSeries = obj.timeSeries;

    if (typeof obj.defaultValueType !== 'undefined') {
      switch (obj.defaultValueType) {
      case 1:
        obj.showDefaultValue = false;
        obj.timeSeriesSelected = false;
        obj.showDefaultValueTimeSeries = false;
        break;
      case 2:
        obj.showDefaultValue = false;
        obj.timeSeriesSelected = true;
        obj.showDefaultValueTimeSeries = true;
        obj.defaultValueTimeSeries = obj.defaultValueTimeSeriesId;
        break;
      case 3:
        obj.showDefaultValue = true;
        obj.timeSeriesSelected = false;
        obj.showDefaultValueTimeSeries = false;
        break;
      }
      obj.defaultValueType = {
        _id: obj.defaultValueType
      };
      obj.isDefaultValue = true;
    }

    try {
      const { dataScheduler } = obj.timeSeries;
      // assign the fetched scheduler to the data object and compare its cron to the default scheduler cron
      obj.mainScheduler = dataScheduler;
    } catch (err) {
      AlertingService.Error(err);
    }
    return obj;
  }

  /**
   * @description Fetches the scheduler of the main time series and compares it to the scheduler of the default time series.
   * @function
   */
  async function validateMainTimeSeriesScheduler() {
    const obj = this.obj;
    const configurations = this.configurations;
    if (obj.mainTimeSeries && obj.mainTimeSeries._id) {
      try {
        const { dataScheduler } = obj.mainTimeSeries;
        // assign the fetched scheduler to the data object and compare its cron to the default scheduler cron
        obj.mainScheduler = dataScheduler;

        if (!schedulersAreEqual(obj)) {
          AlertingService.Error('Schedulers have different intervals');
          obj.mainTimeSeries = null;
        } else if (
          typeof obj.defaultValueTimeSeries == 'object' &&
          obj.defaultValueTimeSeries != null &&
          obj.mainTimeSeries._id == obj.defaultValueTimeSeries._id
        ) {
          //RESET DEFAULT TIME SERIES IF IT'S THE SAME AS MAIN
          obj.defaultValueTimeSeries = null;
        }
        if (obj.defaultValueType && obj.defaultValueType._id == 3) {
          //LITERAL
          setDefaultValueInputs(obj, configurations);
        }

        $scope.$evalAsync();
      } catch (err) {
        AlertingService.Error(err);
        obj.mainTimeSeries = null;
      }
    } else {
      obj.defaultValueTimeSeries = null;
      obj.mainScheduler = null;
    }
  }

  /**
   * @description Fetches the scheduler of the default time-series and compares it to the scheduler of the main time-series.
   * @function
   */
  async function validateDefaultTimeSeriesScheduler() {
    const obj = this.obj;
    if (obj.defaultValueTimeSeries && obj.defaultValueTimeSeries._id) {
      try {
        const { dataScheduler } = obj.defaultValueTimeSeries;
        if (dataScheduler) {
          obj.defaultScheduler = dataScheduler;
          if (!schedulersAreEqual(obj)) {
            AlertingService.Error('Schedulers have different intervals');
            delete obj.defaultScheduler;
            obj.defaultValueTimeSeries = null;
          }
          obj.showKpiInterval = false;
        }
      } catch (err) {
        AlertingService.Error(err);
        obj.defaultValueTimeSeries = null;
        obj.showKpiInterval = false;
      }
    }
  }

  /**
   * @description Compares the schedulers of both time series.
   * @function
   * @param {Object} dataObj Containing info of default and main time-series.
   * @return {boolean} It returns true if both schedulers match or if one of them (or neither) is defined. Returns false only if both are defined and they don't match.
   */
  function schedulersAreEqual(dataObj) {
    return dataObj.defaultScheduler && dataObj.mainScheduler
      ? dataObj.defaultScheduler.crontabExpression ===
          dataObj.mainScheduler.crontabExpression
      : true;
  }

  /**
   * @description checks if timeSeries is already in manual input group tables.
   * @function
   * @param {string} timeSeriesId Self explanatory.
   * @return {Bool}
   */
  function isTimeSeriesAdded(timeSeriesId) {
    return vm.groups.some(group => {
      if (Array.isArray(group.timeSeries)) {
        return group.timeSeries.find(
          timeSeriesItem =>
            timeSeriesItem.timeSeries &&
            timeSeriesItem.timeSeries._id === timeSeriesId
        );
      }
    });
  }
}

export default ManualInputController;
