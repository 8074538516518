AssetCostCentresEntityDateConfiguration.$inject = [
  'gettext',
  '$q',
  'AlertingService',
  'CrudToastFactory',
  'Formatting',
  'CostCentreAssetModel'
];

function AssetCostCentresEntityDateConfiguration(
  gettext,
  $q,
  AlertingService,
  CrudToastFactory,
  Formatting,
  CostCentreAssetModel
) {
  var createObject = {
    validFrom: new Date(new Date().setHours(0, 0, 0, 0)),
    validTo: null
  };

  var configuration = {
    listId: 'AssetCostCentres',
    type: 'single',
    entity: {
      inactiveTitle: gettext('Inactive cost centres'),
      title: gettext('Cost centres')
    },
    api: {
      query: {
        entity: 'cost-centre-assets',
        method: 'custom.readCostCentre'
      },
      activeFilterObject: {
        isActive: true
      },
      inactiveFilterObject: {
        isActive: false
      },
      enrich: Enrich
    },
    create: {
      form: getCostCentreLocationForm(),
      createObject: angular.copy(createObject),
      createFn: create
    },
    update: update,
    getUpdateObject: getUpdateObject,
    delete: function(item) {
      var deferred = $q.defer();
      CostCentreAssetModel.delete({
        id: item._id
      }).then(
        function() {
          CrudToastFactory.toast('delete');
          deferred.resolve();
        },
        function(err) {
          AlertingService.Error(err);
          deferred.reject();
        }
      );
      return deferred.promise;
    },
    title: {
      param: 'nameReadable',
      link: {
        state: 'company-resources-cost-centres-view',
        params: [
          {
            valueParam: 'costCentreId',
            queryParam: 'id'
          }
        ]
      }
    },
    shortActions: [
      {
        type: 'edit',
        icon: 'mode_edit'
      },
      {
        icon: 'delete',
        type: 'delete'
      }
    ],
    attributes: [
      {
        title: gettext('Valid from'),
        param: 'validFromReadable',
        type: 'date-short'
      },
      {
        title: gettext('Valid to'),
        param: 'validToReadable',
        type: 'date-short'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function Enrich(item) {
    var deferred = $q.defer();
    item.fetchingObject['validFromReadable'] = {
      fetching: false,
      success: true,
      nodata: false
    };

    item.fetchingObject['validToReadable'] = {
      fetching: false,
      success: true,
      nodata: false
    };

    if (
      item.hasOwnProperty('validFrom') &&
      item.validFrom !== null &&
      item.validFrom !== undefined
    ) {
      item.validFromReadable = item.validFrom;
    } else {
      item.fetchingObject['validFromReadable'].nodata = true;
    }

    if (
      item.hasOwnProperty('validTo') &&
      item.validTo !== null &&
      item.validTo !== undefined
    ) {
      item.validToReadable = item.validTo;
    } else {
      item.fetchingObject['validToReadable'].nodata = true;
    }

    if (!item.costCentre) {
      item.costCentre = '';
    } else {
      item.nameReadable =
      item.costCentre.name + ' (' + (item.costCentre.externalCode != null ? item.costCentre.externalCode : item.costCentre.code!= null ? item.costCentre.code : '/') + ')';
      item.costCentreId = item.costCentre._id;
    }
    deferred.resolve();
    return deferred.promise;
  }

  function getCostCentreLocationForm() {
    return [
      {
        name: 'costCentre',
        edit: true,
        componentType: 'autocompleteDialog',
        configuration: {
          query: {
            entity: 'cost-centres',
            method: 'read'
          },
          required: true,
          floatingLabel: gettext('Select Cost centre'),
          searchParamName: 'filter',
          entity: 'cost-centres',
          createRedirect: {
            state: 'company-resources-cost-centres-new'
          }
        }
      },
      {
        componentType: 'datePickerFromTo',
        from: 'validFrom',
        to: 'validTo',
        valToEmpty: true,
        labelFrom: gettext('Valid from *'),
        labelTo: gettext('Valid to'),
        required: true,
        onlyStartRequired: true,
        idFrom: 'from',
        idTo: 'to'
      }
    ];
  }

  function create(obj, paramObject) {
    var deferred = $q.defer();
    var apiObj = {
      validFrom: Formatting.formatDate(obj.validFrom, '00:00:00'),
      validTo: Formatting.formatDate(obj.validTo, '23:59:59'),
      costCentre: obj.costCentre._id
    };
    apiObj = Object.assign(apiObj, paramObject);
    CostCentreAssetModel.create(apiObj).then(
      function() {
        CrudToastFactory.toast('create');
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );
    return deferred.promise;
  }

  function update(obj, originalItem, paramObject) {
    var deferred = $q.defer();
    var apiObj = {
      validFrom: Formatting.formatDate(obj.validFrom, '00:00:00'),
      validTo: Formatting.formatDate(obj.validTo, '23:59:59') || null,
      costCentre: obj.costCentre._id
    };
    apiObj = Object.assign(apiObj, paramObject);
    CostCentreAssetModel.update(
      {
        id: originalItem._id
      },
      apiObj
    ).then(
      function() {
        CrudToastFactory.toast('update');
        deferred.resolve();
      },
      function(err) {
        AlertingService.Error(err);
        deferred.reject();
      }
    );
    return deferred.promise;
  }

  function getUpdateObject(item) {
    var validFrom = new Date(item.validFrom);
    var validTo =
      item.validTo && new Date(item.validTo) instanceof Date
        ? new Date(item.validTo)
        : null;
    return {
      validFrom: validFrom,
      validTo: validTo,
      costCentre: item.costCentre
    };
  }

  return configuration;
}
export default AssetCostCentresEntityDateConfiguration;
