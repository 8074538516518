import { DateTime } from 'luxon';

PriceListDetailsConfiguration.$inject = [
  'gettext',
  '$q',
  'AlertingService',
  'PriceListItemModel',
  'PriceListDetailModel',
  'LocalizationService',
  'DateTimeDialogService',
  'Formatting'
];

function PriceListDetailsConfiguration(
  gettext,
  $q,
  AlertingService,
  PriceListItemModel,
  PriceListDetailModel,
  LocalizationService,
  DateTimeDialogService,
  Formatting
) {
  var configuration = {
    listId: 'priceListListId',
    entity: {
      singular: gettext('Price List Detail'),
      plural: gettext('Price List Details')
    },
    api: {
      query: {
        entity: 'price-list-details',
        method: 'custom.list'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'invoices-price-lists-detail-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'priceListDetailId'
          },
          {
            valueParam: 'priceListMasterId',
            queryParam: 'priceListId'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'invoices-price-lists-new-detail',
          params: [
            {
              queryParam: 'priceListId'
            }
          ]
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'invoices-price-lists-detail-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'priceListDetailId'
            },
            {
              valueParam: 'priceListMasterId',
              queryParam: 'priceListId'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'price-list-details',
            method: 'delete'
          }
        }
      },
      {
        title: gettext('Close interval'),
        icon: 'fullscreen',
        action: function(item, relist) {
          DateTimeDialogService.openDialog({
            title: gettext('Close interval'),
            initialValues: {
              validTo: item.validTo,
              validFrom: item.validFrom
            },
            required: {
              validTo: true
            },
            dateOnly: true
          }).then(function(res) {
            if (res) {
              let obj = angular.copy(item);
              obj.validFrom = new Date(res.validFrom).setHours(0, 0, 0, 0);
              obj.validTo = new Date(res.validTo).setHours(0, 0, 0, 0);
              save(obj, relist);
            }
          });
        }
      }
    ],
    filter: [],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  function save(priceListDetail, relist) {
    async.waterfall(
      [
        async.apply(updateDetail, priceListDetail),
        getItems,
        createDetail,
        createItems
      ],
      function(err) {
        if (err) {
          AlertingService.Error(err);
        } else {
          relist();
        }
      }
    );
  }

  function updateDetail(priceListDetail, callback) {
    PriceListDetailModel.update(
      {
        id: priceListDetail._id
      },
      {
        validFrom:
          priceListDetail.validFrom != null
            ? Formatting.formatDate(
              priceListDetail.validFrom,
              '00:00:00'
            ).getTime()
            : null,
        validTo:
          priceListDetail.validTo != null
            ? Formatting.formatDate(
              new Date(priceListDetail.validTo),
              '23:59:59:999'
            ).getTime()
            : null
      }
    ).then(
      function(res) {
        callback(null, res.data);
      },
      function(err) {
        callback(err);
      }
    );
  }

  function getItems(priceListDetail, callback) {
    PriceListItemModel.read({
      priceListDetail: priceListDetail._id
    }).then(
      function(res) {
        callback(null, priceListDetail, res.data);
      },
      function(err) {
        callback(err);
      }
    );
  }

  function createDetail(priceListDetail, items, callback) {
    var date = new Date(priceListDetail.validTo);
    date.setDate(date.getDate() + 1);

    PriceListDetailModel.create({
      priceListMaster: priceListDetail.priceListMaster,
      validFrom:
        date != null ? Formatting.formatDate(date, '00:00:00').getTime() : null,
      validTo: null
    }).then(
      function(res) {
        callback(null, items, res.data);
      },
      function(err) {
        callback(err);
      }
    );
  }

  function createItems(items, detail, callback) {
    var promises = [];
    _.each(items, function(item) {
      promises.push(
        PriceListItemModel.create({
          priceListDetail: detail._id,
          priceListItemGroup: item.priceListItemGroup,
          energyManagementGroup: item.energyManagementGroup,
          name: item.name,
          description: item.description,
          order: item.order,
          price: item.price,
          transferToNextMonth: item.transferToNextMonth,
          physicalQuantity: item.physicalQuantity,
          measurementUnit: item.measurementUnit,
          metricPrefix: item.metricPrefix
        })
      );
    });
    $q.all(promises).then(
      function() {
        callback(null);
      },
      function(err) {
        callback(err);
      }
    );
  }

  /*
   *
   *   ENRICH
   *
   */

  function Enrich(item) {
    var deferred = $q.defer();
    var dateFrom = DateTime.fromJSDate(new Date(item.validFrom))
      .setLocale(LocalizationService.getLocale())
      .toFormat('dd.MM.yyyy');
    var dateTo = item.validTo
      ? DateTime.fromJSDate(new Date(item.validTo))
        .setLocale(LocalizationService.getLocale())
        .toFormat('dd.MM.yyyy')
      : '';
    item.name =
      (item.priceListMaster ? item.priceListMaster.name : '') +
      ' (' +
      dateFrom +
      ' - ' +
      dateTo +
      ')';
    item.priceListMasterId = item.priceListMaster._id;
    deferred.resolve();
    return deferred.promise;
  }

  return configuration;
}
export default PriceListDetailsConfiguration;
