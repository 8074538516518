import routes from './invoices.routes';

// components
import sfeBillingDataOverview from './components/billing-data-overview/sfe-billing-data-overview.component';

// controllers
import InvoiceController from './pages/invoice/invoice.controller';
import NewInvoiceController from './pages/invoice/new-invoice.controller';
import InvoiceDetailController from './pages/invoice-detail/invoice-detail.controller';
import NewInvoiceDetailController from './pages/invoice-detail/new-invoice-detail.controller';
import InvoiceOverviewController from './pages/invoice-overview/invoice-overview.controller';
import PriceListController from './pages/price-list/price-list.controller';
import NewPriceListController from './pages/price-list/new-price-list.controller';
import PriceListDetailController from './pages/price-list-detail/price-list-detail.controller';
import NewPriceListDetailController from './pages/price-list-detail/new-price-list-detail.controller';
import PriceListItemsController from './pages/price-list-item/price-list-items.controller';
import NewPriceListItemController from './pages/price-list-item/new-price-list-item.controller';

// directives
import invoiceDetailValues from './directives/invoice-detail-values/invoice-detail-values.directive';
import invoiceOverviewItem from './directives/invoice-overview-item/invoice-overview-item.directive';

// factories
import findPriceListDetailService from './factories/find-price-list-detail.service';
import BillingOverviewDialog from './factories/billingOverviewDialog/BillingOverview.dialog';
import BillingOverviewFactory from './factories/billingOverviewFactory/BillingOverview.factory';
import GenerateSharedPriceListProperties from './factories/GenerateSharedPriceListProperties.factory';
import MasterInvoiceListHelper from './factories/MasterInvoiceListHelper.service';
import InvoiceDetailFormService from './pages/invoice-detail/InvoiceDetailForm.service';

// configurations
import InvoiceDetailConfiguration from './configurations/invoice-details.configuration';
import MasterInvoiceConfiguration from './configurations/master-invoice.configuration';
import PriceListDetailsConfiguration from './configurations/price-list-details.configuration';
import PriceListConfiguration from './configurations/price-list.configuration';
import PriceListDetailItemsConfiguration from './configurations/price-list-detail-items.configuration';
// network models
import DetailInvoiceModel from './network-models/DetailInvoice.model';
import DetailInvoiceValueModel from './network-models/DetailInvoiceValue.model';
import InvoiceModel from './network-models/Invoice.model';
import InvoicelistModel from './network-models/Invoicelist.model';
import MasterInvoiceModel from './network-models/MasterInvoice.model';
import PriceListDetailModel from './network-models/PriceListDetail.model';
import PriceListItemModel from './network-models/PriceListItem.model';
import PriceListMasterModel from './network-models/PriceListMaster.model';
import BillingCalculationDataModel from './network-models/BillingCalculationData.model';
import DetailInvoiceModelBillingDataByEmg from './network-models/DetailInvoiceBillingDataByEmg.model';
import CalculatedDetailInvoiceNormalizedServiceDateModel from './network-models/CalculatedDetailInvoiceNormalizedServiceDate.model';
export default {
  components: {
    sfeBillingDataOverview
  },
  controllers: {
    InvoiceController,
    NewInvoiceController,
    InvoiceDetailController,
    NewInvoiceDetailController,
    InvoiceOverviewController,
    PriceListController,
    NewPriceListController,
    PriceListDetailController,
    NewPriceListDetailController,
    PriceListItemsController,
    NewPriceListItemController
  },
  directives: {
    invoiceDetailValues,
    invoiceOverviewItem
  },
  factories: {
    findPriceListDetailService,
    InvoiceDetailConfiguration,
    MasterInvoiceConfiguration,
    PriceListDetailsConfiguration,
    PriceListConfiguration,
    BillingOverviewDialog,
    BillingOverviewFactory,
    InvoiceDetailFormService,
    GenerateSharedPriceListProperties
  },
  services: {
    DetailInvoiceModel,
    DetailInvoiceValueModel,
    InvoiceModel,
    InvoicelistModel,
    MasterInvoiceModel,
    PriceListDetailModel,
    PriceListItemModel,
    PriceListMasterModel,
    BillingCalculationDataModel,
    PriceListDetailItemsConfiguration,
    DetailInvoiceModelBillingDataByEmg,
    CalculatedDetailInvoiceNormalizedServiceDateModel,
    MasterInvoiceListHelper
  },
  routes
};
