module.exports = {
  entity: 'location-types',
  path: 'location-types',
  entityId: 134,
  name: {
    en: 'Location types',
    sl_SI: 'Tipi lokacij'
  },
  networkModel: {
    entity: 'location-types',
    read: 'read'
  },
  selectedParamName: '_id',
  scope: 'tis_be:location-types',

  dialog: {
    title: {
      en: 'Select location type',
      sl_SI: 'Izberite tip lokacije'
    },
    listInfo: {
      en: 'Select location type from the list below',
      sl_SI: 'Izberite tip lokacije s spodnjega seznama'
    }
  }
}
