NewTangoAgentConnectionConfigController.$inject = [
  '$stateParams',
  'AlertingService',
  '$q',
  'CrudToastFactory',
  'gettext',
  'TranslationService',
  'MetadataService',
  'SfeHeader',
  'TangoAgentConnectionConfigModel',
  'OpcModel',
  'FileModel',
  'WebserviceModel',
  'DatabaseModel',
  'tangoAgentConnectionConfig',
  '$timeout'
];

function NewTangoAgentConnectionConfigController(
  $stateParams,
  AlertingService,
  $q,
  CrudToastFactory,
  gettext,
  TranslationService,
  MetadataService,
  SfeHeader,
  TangoAgentConnectionConfigModel,
  OpcModel,
  FileModel,
  WebserviceModel,
  DatabaseModel,
  tangoAgentConnectionConfig,
  $timeout
) {
  const vm = this;
  const tangoAgentConnectionConfigId = $stateParams.id;
  const editMode = Boolean(tangoAgentConnectionConfigId);
  const tangoAgentConfigTypes = TranslationService.GetCollection(
    'codelists.tangoAgentConfigTypes'
  );
  let passedParams = false;

  vm.$onInit = () => {
    vm.dataConfig = {
      action: {
        ctrlFn: true,
        title: tangoAgentConnectionConfigId
          ? gettext('Update')
          : gettext('Create'),
        fn: createOrUpdate
      }
    };

    if (tangoAgentConnectionConfigId) {
      MetadataService.Loading(true);
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit Tango Agent Connection Config'),
        'external-datasources-tango-agent-connection-config-view',
        $stateParams
      );

      tangoAgentConnectionConfig.tangoAgentConfigType = tangoAgentConfigTypes.find(
        type => type.id == tangoAgentConnectionConfig.tangoAgentConfigType
      );

      vm.dataConfig.dataObj = tangoAgentConnectionConfig;
      $timeout(() => {
        MetadataService.Loading(false);
        MetadataService.ChangeMetadata(
          'Edit ' + tangoAgentConnectionConfig.name,
          tangoAgentConnectionConfig.description
        );
      });
    } else {
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New Tango Agent Connection Config'),
        'external-datasources-tango-agent-connection-config-list'
      );

      vm.dataConfig.dataObj = {
        name: undefined,
        description: undefined,
        connectionType: {},
        connector: null,
        tangoAgentConfigType: tangoAgentConfigTypes[0]
      };

      // if params are passed into data source creation from another state
      if ($stateParams.externalDatasourceId) {
        vm.dataConfig.dataObj.externalDatasource =
          $stateParams.externalDatasourceId;
      }
      if ($stateParams.opcId) {
        vm.dataConfig.dataObj.tangoAgentConfigType = tangoAgentConfigTypes.find(
          type => type.id == 1
        );
        vm.dataConfig.dataObj.connector = $stateParams.opcId;
        passedParams = true;
      }
      if ($stateParams.webserviceId) {
        vm.dataConfig.dataObj.tangoAgentConfigType = tangoAgentConfigTypes.find(
          type => type.id == 2
        );
        vm.dataConfig.dataObj.connector = $stateParams.webserviceId;
        passedParams = true;
      }
      if ($stateParams.databaseId) {
        vm.dataConfig.dataObj.tangoAgentConfigType = tangoAgentConfigTypes.find(
          type => type.id == 3
        );
        vm.dataConfig.dataObj.connector = $stateParams.databaseId;
        passedParams = true;
      }
      if ($stateParams.fileId) {
        vm.dataConfig.dataObj.tangoAgentConfigType = tangoAgentConfigTypes.find(
          type => type.id == 4
        );
        vm.dataConfig.dataObj.connector = $stateParams.fileId;
        passedParams = true;
      }
    }

    vm.dataConfig.data = getDataConfig(passedParams);
  };
  // create and update functions //
  function createOrUpdate() {
    vm.sendingRequest = true;
    if (editMode) {
      update();
    } else {
      create();
    }
  }

  async function create() {
    vm.sendingRequest = true;
    /* Create new datasource */
    try {
      var {
        data: tangoAgentConnectionConfig
      } = await TangoAgentConnectionConfigModel.create(dataToSave());
    } catch (err) {
      AlertingService.Error(err);
      vm.sendingRequest = false;
    }

    // create connection between device and datasource
    try {
      var redirectObj = {
        state: 'external-datasources-tango-agent-connection-config-view',
        paramName: 'id',
        paramValue: tangoAgentConnectionConfig._id
      };
      CrudToastFactory.toast('create', redirectObj);
    } catch (err) {
      AlertingService.Error(err);
      vm.sendingRequest = false;
    }
  }

  async function update() {
    vm.sendingRequest = true;
    try {
      await TangoAgentConnectionConfigModel.update(
        {
          id: tangoAgentConnectionConfigId
        },
        dataToSave()
      );
      var redirectObj = {
        state: 'external-datasources-tango-agent-connection-config-view',
        paramName: 'id',
        paramValue: tangoAgentConnectionConfigId
      };
      CrudToastFactory.toast('update', redirectObj);
    } catch (err) {
      AlertingService.Error(err);
      vm.sendingRequest = false;
    }
  }

  /* helper functions */
  function getDataConfig(passedParams) {
    return [
      {
        placeholder: 'Name',
        name: 'name',
        componentType: 'textField',
        type: 'text'
      },
      {
        placeholder: 'Description',
        name: 'description',
        componentType: 'textArea',
        type: 'text',
        maxlength: 500,
        required: false
      },
      {
        title: gettext('External Datasource'),
        componentType: 'title'
      },
      {
        configuration: {
          floatingLabel: gettext('Select connected external datasource'),
          query: {
            entity: 'external-datasources',
            method: 'read'
          },
          filterObjectFn: () => ({ externalDatasourceType: 100 }),
          entity: 'external-datasources',
          searchParamName: 'filter',
          required: true,
          createRedirect: {
            state: 'external-datasources-external-datasources-new'
          }
        },
        componentType: 'autocompleteDialog',
        edit:
          !!tangoAgentConnectionConfigId || $stateParams.externalDatasourceId,
        name: 'externalDatasource'
      },
      {
        title: gettext('Connection settings'),
        componentType: 'title'
      },
      {
        componentType: 'radio',
        options: tangoAgentConfigTypes,
        name: 'tangoAgentConfigType',
        changeFn() {
          vm.dataConfig.dataObj.connector = null;
        },
        label: gettext('Type')
      },
      {
        componentType: 'autocompleteDialog',
        required: true,
        edit: editMode || passedParams,
        name: 'connector',
        configuration: {
          entity: 'connections',
          noDialog: true,
          floatingLabel: gettext('Select Connection'),
          searchParamName: 'filter',
          required: true,
          query: {
            query: function(param) {
              var deferred = $q.defer();
              let method;
              switch (vm.dataConfig.dataObj.tangoAgentConfigType.id) {
              case 1:
                method = OpcModel.read(param);
                break;
              case 2:
                method = WebserviceModel.read(param);
                break;
              case 3:
                method = DatabaseModel.read(param);
                break;
              case 4:
                method = FileModel.read(param);
                break;
              }

              method.then().then(
                function(res) {
                  deferred.resolve({ data: res.data });
                },
                function(err) {
                  deferred.resolve({ data: [] });
                  AlertingService.Error(err);
                }
              );

              return {
                $promise: deferred.promise
              };
            }
          }
        }
      }
    ];
  }

  function dataToSave() {
    const dataObj = vm.dataConfig.dataObj;
    const data = {
      name: dataObj.name,
      description: dataObj.description,
      tangoAgentConfigType: dataObj.tangoAgentConfigType.id,
      externalDatasource: dataObj.externalDatasource._id,
      connector: dataObj.connector._id
    };
    return data;
  }
}

export default NewTangoAgentConnectionConfigController;
