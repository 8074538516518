import './sfe-form-2-checkbox.scss';
import template from './sfe-form-2-checkbox.component.html';

/**
 * @ngdoc component
 * @name common.sfeForm2Checkbox
 * @description Component for displaying checkboxes.
 * @param {Object} config - field configuration
 * @example
 * <sfe-form-2-checkbox
 *   config="vm.config"
 * ></sfe-form-2-checkbox>
 */
/*
vm.config = {
  // Forms title (label).
  title: gettextCatalog.getString('Test checkboxes'),
  // Configuration for input type.
  type: {
    // Input type options.
    options: {
      // Display content in 'row' or 'column'.
      layout: 'row',
      // Function for displaying item (checkbox label).
      display: item => {
        return item.name;
      },
      valueField: 'id', default _id
      // Each item represents a checkbox.
      items: [
        {
          // Identifier.
          _id: 'first',
          // Items name used in display.
          name: 'first'
        },
        {
          _id: 'second',
          name: 'second'
        }
      ]
    }
  }
};
*/
export default {
  restrict: 'E',
  template,
  bindings: {
    config: '<',
    onChange: '&'
  },
  require: {
    model: 'ngModel'
  },
  controller: SfeForm2CheckboxController,
  controllerAs: 'vm',
  bindToController: true
};

SfeForm2CheckboxController.$inject = ['$scope'];
function SfeForm2CheckboxController($scope) {
  const vm = this;

  vm.$postLink = () => {
    vm.model.$validators = {
      ...vm.model.$validators,
      required: value => {
        if (vm.model.$$attr.required) {
          return typeof value == 'object' && value != null
            ? Object.keys(value).some(key => value[key])
            : false;
        }
        return true;
      }
    };
  };

  vm.valueField = '_id';

  vm.$onChanges = () => {
    if (
      vm.config != null &&
      vm.config.type != null &&
      vm.config.type.options != null &&
      vm.config.type.options.valueField != null
    ) {
      vm.valueField = vm.config.type.options.valueField;
    }
  };

  $scope.$on('$destroy', function() {
    if (modelWatcher) {
      modelWatcher();
    }
  });

  // Initialize mode value.
  const modelWatcher = $scope.$watch(
    () => {
      return vm.model.$modelValue;
    },
    model => {
      vm.modelValue = model;
      if (typeof vm.onChange == 'function') {
        vm.onChange();
      }
    }
  );

  /**
   * @description update model value on change.
   * @function
   */
  vm.updateModelValue = () => {
    vm.model.$setViewValue({ ...vm.modelValue });
  };
}
