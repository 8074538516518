module.exports = {
  entity: 'analytical-flows',
  path: 'analytical-flows',
  entityId: 243,
  storeKey: 'analyticalFlow',
  name: {
    en: 'External writer Flows',
    sl_SI: 'External writer Flows'
  },
  networkModel: {
    entity: 'analytical-flows',
    read: 'read'
  },
  scope: 'tis_be:analytical-flow',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select analytical flow',
      sl_SI: 'Izberite časovno serijo'
    },
    listInfo: {
      en: 'Select analytical flow from the list below',
      sl_SI: 'Izberite časovno serijo s spodnjega seznama'
    }
  }
}
