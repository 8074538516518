import actionTypes from '../action/external-writer-flow.type';

ExternalWriterFlowMiddleware.$inject = [
  'ExternalWriterFlowModel',
  'AlertingService',
  'CrudToastFactory'
];
function ExternalWriterFlowMiddleware(
  ExternalWriterFlowModel,
  AlertingService,
  CrudToastFactory
) {
  return () => next => async action => {
    switch (action.type) {
    case actionTypes.EXTERNAL_WRITER_FLOW_READ:
      try {
        let result = await ExternalWriterFlowModel.read({
          ...action.payload,
          populate:
              'inputTimeSeries,timeout.alarm,externalDatasource,tangoAgentConfig.connectionConfig,tangoAgentConfig.tag,writeCancelRules.rule,writeCancelRules.alarms'
        });
        action.payload = {
          ...action.payload,
          result
        };
      } catch (err) {
        AlertingService.Error(err);
        action.payload.result = err;
      }
      break;
    case actionTypes.EXTERNAL_WRITER_FLOW_UPDATE:
      try {
        await ExternalWriterFlowModel.update(
          action.payload.query,
          action.payload.body
        );

        CrudToastFactory.toast('update');
      } catch (err) {
        AlertingService.Error(err);
      }
      try {
        action.payload.result = await ExternalWriterFlowModel.read({
          ...action.payload.query,
          populate:
              'inputTimeSeries,timeout.alarm,externalDatasource,tangoAgentConfig.connectionConfig,tangoAgentConfig.tag,writeCancelRules.rule,writeCancelRules.alarms'
        });
      } catch (err) {
        AlertingService.Error(err);
        action.payload.result = err;
      }
      break;
    case actionTypes.EXTERNAL_WRITER_FLOW_CREATE:
      try {
        action.payload.result = await ExternalWriterFlowModel.create(
          action.payload.query || {},
          action.payload.body
        );
      } catch (err) {
        AlertingService.Error(err);
      }
      break;
    }
    next(action);
  };
}

export default ExternalWriterFlowMiddleware;
