import template from './sfe-entity-indirect-permission-tree.component.html';
import './sfe-entity-indirect-permission-tree.scss';
import './sfe-entity-indirect-permission-tree-item.scss';

// THIS HAS INCLUDE
/**
 * @ngdoc component
 * @name user.sfeEntityIndirectPermissionTree
 * @description description.
 * @param {string} treeId treeId
 * @param {string} entity selected entity identifier
 * @param {string} userId user id
 * @param {array} treConfiguration array of indirect permission tree configurations
 * @param {array} selectedInstances array of selected instances
 * @example
 *  <sfe-entity-indirect-permission-tree
 *   entity="vm.entity"
 *   tree-id="vm.treeId"
 *   user-id="vm.userId"
 *   tree-configuration="vm.treeConfiguration"
 *   selected-instances="vm.instances"
 *  >
 *  </sfe-entity-indirect-permission-tree>
 */
export default {
  template,
  bindings: {
    treeId: '<',
    userId: '<',
    entity: '<',
    treeConfiguration: '<',
    selectedInstances: '=?'
  },
  controllerAs: 'vm',
  controller: SfeEntityIndirectPermissionTreeController
};

SfeEntityIndirectPermissionTreeController.$inject = [
  '$scope',
  'AuthorizationTreeAccessModel',
  'AlertingService',
  'EntitiesService',
  'ItemSelector'
];

function SfeEntityIndirectPermissionTreeController(
  $scope,
  AuthorizationTreeAccessModel,
  AlertingService,
  EntitiesService,
  ItemSelector
) {
  var vm = this;
  vm.openSelectEntityInstanceDialog = openSelectEntityInstanceDialog;
  vm.hoverParent = hoverParent;
  vm.removeSelected = removeSelected;
  vm.isEmpty = isEmpty;
  /**
   * @description whatches when entity binding is set and triggers int function.
   * @function
   */
  var entityWatcher = $scope.$watch('entity', function() {
    if (vm.entity) {
      init();
      entityWatcher();
    }
  });

  $scope.$on('$destroy', function() {
    if (entityWatcher) {
      entityWatcher();
    }
  });
  /**
   * @description initialization function. If tree configuration exists constructs access tree otherwise fetches it first.
   * @function
   */
  function init() {
    vm.tree = [];
    if (vm.treeConfiguration) {
      constructTree(vm.treeConfiguration, vm.entity, vm.tree, 0);
    } else {
      fetchTreeAccess();
    }
  }
  /**
   * @description recursevely searches for all item parents.
   * @function
   * @param {string} parentPath scope identifier of selected item parent
   * @param {array} items array of tree configurations
   * @return {object}
   */
  function find(parentPath, items) {
    var i = 0;
    var found;
    for (; i < items.length; i++) {
      var path = items[i].path;
      if (path === parentPath) {
        return items[i];
      } else if (_.isArray(items[i].parents)) {
        found = find(parentPath, items[i].parents);
        if (found) {
          return found;
        }
      }
    }
  }
  /**
   * @description recursevely sets hovered value to value to all parent items.
   * @function
   * @param {string} parentPath scope identifier of selected item parent
   * @param {bool} value
   */
  function hoverParent(parentPath, value) {
    var parent = find(parentPath, vm.tree);
    if (parent) {
      parent.hovered = value;
      hoverParent(parent.parentPath, value);
    }
  }

  function getPath(item, path) {
    if (!path) {
      path = [item];
    }
    var nextItem = find(item.parentPath, vm.tree);
    if (nextItem) {
      path.push(nextItem);
      getPath(nextItem, path);
    }
    return path;
  }
  /**
   * @description opens dialog to select entity items and ads then to selected entities list after dialog is closed.
   * @function
   * @param {object} item object that contains selected item scope and target path
   */
  function openSelectEntityInstanceDialog(item) {
    var pathArray = _.reverse(getPath(item));
    var path = '';
    (pathArray || []).forEach(function(item) {
      path += item.scope;
    });
    if (!vm.selectedPaths) {
      vm.selectedPaths = [];
    }
    if (!vm.selectedInstances) {
      vm.selectedInstances = {};
    }
    var entityName = EntitiesService.getEntity(item.scope);
    var dialogConfiguration = EntitiesService.getDialog(entityName);
    var displayFields = dialogConfiguration
      ? dialogConfiguration.displayFields || ['name']
      : ['name'];
    var permissionConfig = EntitiesService.getPermissionConfiguration(
      entityName
    );
    var entityValueName =
      permissionConfig && permissionConfig.valueParameterName
        ? permissionConfig.valueParameterName
        : '_id';
    ItemSelector.open(
      [entityName],
      [
        {
          multiple: true
        }
      ]
    ).then(function(selected) {
      // vm.selectedInstances = selected;
      if (selected) {
        var pathExists = _.find(vm.selectedPaths, function(item) {
          return item === path;
        });

        if (!pathExists) {
          vm.selectedPaths.push(path);
          vm.selectedInstances[path] = {
            path: pathArray,
            selectedInstances: [],
            selectedDisplayFields: displayFields,
            entityValueName: entityValueName
          };
        }
        vm.selectedInstances[path].selectedInstances = _.concat(
          vm.selectedInstances[path].selectedInstances,
          selected
        );
      }
    });
  }
  /**
   * @description constructs side menu indirect tree access.
   * @function
   * @param {array} treConfiguration array of indirect access configurations
   * @param {string} entity entity scope identifier
   * @param {array} parents array that will be filled with entity parents configurations
   */
  function constructTree(treConfiguration, entity, parents) {
    var scopeObject;
    setRootEntityScopeName(treConfiguration);
    treConfiguration.forEach(function(item) {
      if (item.child === entity) {
        scopeObject = {
          scope: item.parent,
          scopeName: item.parentName
        };
        scopeObject.parents = [];
        parents.push(scopeObject);
      }
    });

    (vm.tree || []).forEach(function(item) {
      getParents(treConfiguration, item.scope, item.parents);
    });
  }
  /**
   * @description iterates over configuration array and adds entity parent to entity parent array.
   * @function
   * @param {array} treConfiguration array of indirect access configurations
   * @param {string} entity entity scope identifier
   * @param {array} parents array that will be filled with entity parents configurations
   */
  function getParents(treConfiguration, entity, parents) {
    var scopeObject;
    treConfiguration.forEach(function(item) {
      if (item.child === entity) {
        scopeObject = {
          scope: item.parent,
          scopeName: item.parentName
        };
        if (item.child !== item.parent) {
          scopeObject.parents = [];
          parents.push(scopeObject);
          getParents(treConfiguration, scopeObject.scope, scopeObject.parents);
        }
      }
    });
  }
  /**
   * @description fetches tree configuraiton and triggers  construct tree fucntion.
   * @function
   */
  function fetchTreeAccess() {
    AuthorizationTreeAccessModel.read({
      owner: vm.userId,
      tree: vm.treeId
    }).then(
      function(res) {
        constructTree(res.data.tree, vm.entity, vm.tree, 0);
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }
  /**
   * @description sets main entity name.
   * @function
   * @param {array} trees array of tree configuraiton
   */
  function setRootEntityScopeName(trees) {
    var rootName;
    for (var i = 0; i < trees.length && !rootName; i++) {
      if (trees[i].child === vm.entity) {
        rootName = trees[i].childName;
      } else if (trees[i].parent === vm.entity) {
        rootName = trees[i].parentName;
      }
    }
    vm.rootName = rootName;
  }
  /**
   * @description removes entity from selected array.
   * @function
   * @param {string} path indicates entity that contains item that we want to remove
   * @param {number} index index of item
   */
  function removeSelected(path, index) {
    vm.selectedInstances[path].selectedInstances.splice(index, 1);
    if (!vm.selectedInstances[path].selectedInstances.length) {
      delete vm.selectedInstances[path];
      vm.paths = _.remove(vm.selectedPaths, function(item) {
        return item === path;
      });
    }
  }
  /**
   * @description tests if object is empty.
   * @function
   * @param {object} object tested object
   * @return {bool}
   */
  function isEmpty(object) {
    return _.isEmpty(object);
  }
}
