import HeaderView from './header.client.view.html';

function HeaderDirective() {
  return {
    restrict: 'A',
    template: HeaderView
  };
}

export default HeaderDirective;
