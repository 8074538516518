/**
 * @ngdoc service
 * @name core.AuthorizationToEditService
 * @description Gets info whether the logged in user is authorized to edit permissions and keeps it for later use through isAuthorizedToEdit function.
 */
AuthorizationToEditService.$inject = ['$smartAuth', 'CrawlerMethods'];

function AuthorizationToEditService($smartAuth, CrawlerMethods) {
  let isAuthorizedToEditValue = false;

  // returns whether user is authorized to edit
  function isAuthorizedToEdit() {
    return isAuthorizedToEditValue;
  }

  async function initiate() {
    // get user id
    try {
      // get permissions
      const method = CrawlerMethods.getMethod({
        entity: 'authorization-list-show-scopes',
        method: 'read'
      });
      const permissions = await method();

      // find the permission to edit
      let editPermissions;
      if (permissions != null) {
        editPermissions = permissions.data['authorizer:edit_permissions'];
      }

      // determine whether person is permitted to edit
      if (editPermissions != null && editPermissions.create === true) {
        isAuthorizedToEditValue = true;
      }
    } catch (err) {
      return err;
    }
  }

  return {
    isAuthorizedToEdit,
    initiate
  };
}

export default AuthorizationToEditService;
