MeasuringPointController.$inject = [
  '$stateParams',
  'gettext',
  'AlertingService',
  'gettextCatalog',
  'SfeListConfigurations',
  'MetadataService',
  'TranslationService',
  'PhysicalCollectionService',
  'ManualReadings',
  'TimeSeriesOutOfMeasuringpoint',
  '$scope',
  'MeasuringpointCounterTypesEntityDateConfiguration',
  'ToastService',
  'measuringPoint',
  'MasterInvoiceListHelper'
];
function MeasuringPointController(
  $stateParams,
  gettext,
  AlertingService,
  gettextCatalog,
  SfeListConfigurations,
  MetadataService,
  TranslationService,
  PhysicalCollectionService,
  ManualReadings,
  TimeSeriesOutOfMeasuringpoint,
  $scope,
  MeasuringpointCounterTypesEntityDateConfiguration,
  ToastService,
  measuringPoint,
  MasterInvoiceListHelper
) {
  const vm = this;
  const id = $stateParams.id;
  let firstLoad = true;
  vm.isLoading = false;
  vm.treeType = 'childrenTree';
  vm.entity = 'measuringpoints';
  vm.childEntity = 'measuringpoints';
  vm.elementSelected = elementSelected;
  vm.measuringPointObject = {
    measuringpoint: id
  };

  init();

  function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    vm.id = id;
    readMeasuringPoint();
    vm.entityConfig = {
      updateParam: vm.measuringPointObject,
      createParam: vm.measuringPointObject,
      entityItem: measuringPoint
    };
  }

  $scope.$onDestroy = () => {
    if (watchTrigger) {
      watchTrigger();
    }
  };

  const watchTrigger = $scope.$watch('vm.triggerReload', async value => {
    if (value === true) {
      let timeSeriesConfig = await TimeSeriesOutOfMeasuringpoint.getCreateTimeSeriesActionConfiguration(
        vm.measuringpoint
      );
      if (typeof timeSeriesConfig === 'function') {
        vm.headerData = {
          ...vm.headerData,
          actions: [
            getCreateTimeSeriesActionConfig(timeSeriesConfig),
            ...vm.headerData.actions
          ]
        };
        $scope.$evalAsync();
      }
    }
  });

  /**
   * @description get configuration for Create time series action.
   * @function
   * @param {function} createTimeSeriesConfiguration
   * @return {Object}
   */
  const getCreateTimeSeriesActionConfig = createTimeSeriesConfiguration => {
    return {
      title: gettextCatalog.getPlural(
        3,
        'Create time series',
        'Create time series',
        {}
      ),
      script: true,
      disabledFn: () => vm.creatingTimeSeries,
      fn: async () => {
        try {
          vm.headerData.actions[0].disabled = true;
          vm.creatingTimeSeries = true;

          await createTimeSeriesConfiguration();
          vm.creatingTimeSeries = false;
          vm.headerData.actions.splice(0, 1);
          vm.triggerReload = true;
          vm.headerData = {
            ...vm.headerData
          };
          ToastService.showToast(
            gettext('Time Series were successfully created')
          );
        } catch (err) {
          vm.creatingTimeSeries = false;
          AlertingService.Error(err);
          createTimeSeriesConfiguration = await TimeSeriesOutOfMeasuringpoint.getCreateTimeSeriesActionConfiguration(
            vm.measuringpoint
          );
          if (!createTimeSeriesConfiguration) {
            vm.headerData.actions.splice(0, 1);
            vm.headerData = {
              ...vm.headerData
            };
            vm.triggerReload = true;
          } else {
            vm.headerData.actions[0].disabled = false;
          }
        }
        $scope.$applyAsync();
      }
    };
  };

  /**
   * @description adds energy source type  filter to counter type configuration.
   * @function
   * @param {string} energySourceType id of energy source type
   */
  function initiateCounterTypeList(energySourceType) {
    const filterObject = {
      energySourceType
    };
    let createForm =
      MeasuringpointCounterTypesEntityDateConfiguration.create.form;
    let dialogConfiguration = {
      filterObject
    };
    createForm[0].configuration.filterObject = filterObject;
    createForm[0].configuration.dialogConfiguration = dialogConfiguration;

    let configuration = {
      ...MeasuringpointCounterTypesEntityDateConfiguration,
      create: {
        ...MeasuringpointCounterTypesEntityDateConfiguration.create,
        form: createForm
      }
    };
    vm.counterTypeEntityConfig = {
      ...configuration,
      updateParam: vm.measuringPointObject,
      createParam: vm.measuringPointObject
    };
  }
  /**
   * @description returns measuringpoint invoice list configuration.
   * @function
   * @return {Object}
   */
  function getInvoiceConfiguration() {
    let eInvoiceEntityConfig = angular.copy(
      SfeListConfigurations.configuration['master-invoices']
    );

    eInvoiceEntityConfig.api = {
      ...eInvoiceEntityConfig.api,
      query: {
        ...eInvoiceEntityConfig.api.query,
        method: 'custom.readMeasuringPointInvoice',
        methodTotal: 'custom.readMeasuringPointInvoiceTotal'
      },
      prefetchAction: MasterInvoiceListHelper.validateValidityIntervalFilter
    };

    eInvoiceEntityConfig.filter = eInvoiceEntityConfig.filter.filter(function(
      item
    ) {
      return item.param !== 'measuringPoint';
    });
    eInvoiceEntityConfig.listId = 'measuringpointMasterInvoiceListId';
    return eInvoiceEntityConfig;
  }

  async function readMeasuringPoint() {
    try {
      vm.measuringpoint = measuringPoint;
      vm.measuringpointType = vm.measuringpoint.measuringpointType;
      vm.measuringPointAllocators = vm.measuringpoint.allocators;
      initiateCounterTypeList(
        measuringPoint.energySourceType
          ? measuringPoint.energySourceType._id
          : null
      );
      if (vm.measuringpoint.maximumCapacity) {
        try {
          const physicalData = await setPhysicalData(vm.measuringpoint);
          vm.measuringpoint = {
            ...measuringPoint,
            ...physicalData
          };
        } catch (err) {
          AlertingService.Error(err);
        }
      }
      const timeSeriesConfig = await TimeSeriesOutOfMeasuringpoint.getCreateTimeSeriesActionConfiguration(
        vm.measuringpoint
      );
      vm.headerData = constructHeader(vm.measuringpoint, timeSeriesConfig);
      vm.loading = false;
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(
        vm.measuringpoint.name,
        vm.measuringpoint.description
      );

      vm.measuringpointIsLoaded = true;

      vm.eInvoiceRefreshObject = {
        measuringPoint: id,
        eInvoice: true
      };
      vm.eInvoiceEntityConfig = getInvoiceConfiguration();
    } catch (err) {
      vm.loading = false;
      MetadataService.Loading(false);
      AlertingService.Error(err);
      vm.measuringpointIsLoaded = true;
    }
  }

  async function setPhysicalData(measuringpoint) {
    const res = await PhysicalCollectionService.getReadablePhysicalData({
      physicalQuantity: measuringpoint.maximumCapacity.physicalQuantity,
      measurementUnit: measuringpoint.maximumCapacity.measurementUnit,
      metricPrefix: measuringpoint.maximumCapacity.metricPrefix
    });
    return {
      maximumCapacityValue: measuringpoint.maximumCapacity.maximumCapacity,
      physicalQuantityName: res.physicalQuantity.name,
      measurementUnit: res.measurementUnit,
      metricPrefix: res.metricPrefix,
      readableMeasurementUnit: res.readableMeasurementUnitItemView
    };
  }

  function constructHeader(point, createTimeSeriesConfiguration) {
    let actions = [
      {
        title: gettext('Change counter'),
        script: true,
        fn: () => {
          ManualReadings(
            id,
            vm.measuringpoint.name,
            measuringPoint.measuringpointKind,
            measuringPoint.parentId ? measuringPoint.parentId._id : false,
            'counterChange'
          );
        }
      },
      {
        title: gettext('Manual readings'),
        script: true,
        fn: () => {
          ManualReadings(
            id,
            vm.measuringpoint.name,
            measuringPoint.measuringpointKind,
            measuringPoint.parentId ? measuringPoint.parentId._id : false,
            'manualReadings',
            measuringPoint.energySourceType
          );
        }
      },
      {
        title: gettext('delete'),
        delete: true,
        id: id,
        endpoint: {
          entity: 'measuringpoints',
          method: 'delete'
        },
        redirectState: 'company-resources-measuring-points-list'
      },
      {
        title: gettext('duplicate'),
        state: 'company-resources-measuring-points-duplicate',
        param: 'id',
        paramValue: id
      },
      {
        title: gettext('Update'),
        state: 'company-resources-measuring-points-edit',
        param: 'id',
        paramValue: id
      }
    ];

    if (typeof createTimeSeriesConfiguration == 'function') {
      actions = [
        getCreateTimeSeriesActionConfig(createTimeSeriesConfiguration),
        ...actions
      ];
    }

    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];

    var measuringPointClass = TranslationService.GetCollectionById(
      'codelists.measuringPointClasses',
      measuringPoint.measuringpointClass
    );
    var measuringPointKind = TranslationService.GetCollectionById(
      'codelists.measuringPointKinds',
      point.measuringpointKind
    );
    var properties = [
      {
        title: gettext('Code'),
        value: point.code,
        type: 'code'
      },
      {
        title: gettext('Energy Source Type'),
        value:
          point.energySourceType && point.energySourceType.name
            ? point.energySourceType.name
            : gettextCatalog.getString('None'),
        energyIcon: point.energySourceType ? point.energySourceType.icon : null,
        type: 'simple'
      },
      {
        title: gettext('Measuring Point Type'),
        value:
          point.measuringpointType && point.measuringpointType.name
            ? point.measuringpointType.name
            : gettextCatalog.getString('Unknown'),
        type: 'simple'
      },
      {
        title: gettext('Measuring Point Kind'),
        value: measuringPointKind ? measuringPointKind.name : 'unknown',
        type: 'simple'
      },
      {
        title: gettext('Measuring Point Class'),
        value: measuringPointClass ? measuringPointClass.name : 'unknown',
        type: 'simple'
      },
      {
        title: gettext('Installation number'),
        value: point.installationNumber ? point.installationNumber : 'unknown',
        type: 'simple'
      },
      {
        title: gettext('Has usage'),
        value: point.hasUsage,
        type: 'radio',
        customOptions: ['YES', 'NO']
      }
    ];
    var integration = [
      {
        title: gettext('External'),
        value: point.isExternal,
        type: 'radio',
        customOptions: ['YES', 'NO']
      }
    ];

    if (point.externalCode) {
      integration.push({
        title: gettext('External code'),
        value: measuringPoint.externalCode,
        type: 'code'
      });
    }

    if (measuringPointClass && measuringPointClass.code === '013') {
      integration.push({
        title: gettext('EOM Code'),
        value: point.eomCode || '/',
        type: 'code'
      });
    }

    var bps = [];

    if (point.parentId && point.parentId._id) {
      bps.push({
        title: gettext('Parent'),
        linkTitle: point.parentId.name + ' (' + point.parentId.code + ')',
        state: 'company-resources-measuring-points-view',
        param: 'id',
        paramValue: point.parentId._id,
        type: 'link'
      });
    }
    if (point.owner && point.owner.length && point.owner[0].businessPartner) {
      bps.push({
        title: gettext('Owner'),
        linkTitle:
          point.owner[0].businessPartner.name +
          ' (' +
          point.owner[0].businessPartner.code +
          ')',
        state: 'company-resources-business-partners-view',
        param: 'id',
        paramValue: point.owner[0].businessPartner._id,
        type: 'link'
      });
    }

    if (
      point.mainCostCentre &&
      point.mainCostCentre.length &&
      point.mainCostCentre[0].costCentre
    ) {
      bps.push({
        title: gettext('Main Cost Centre'),
        linkTitle:
          point.mainCostCentre[0].costCentre.name +
          ' (' +
          point.mainCostCentre[0].costCentre.code +
          ')',
        state: 'company-resources-cost-centres-view',
        param: 'id',
        paramValue: point.mainCostCentre[0].costCentre._id,
        type: 'link'
      });
    }

    if (
      point.businessPartnerDistributor &&
      point.businessPartnerDistributor._id
    ) {
      bps.push(
        {
          title: gettext('Distributer'),
          linkTitle: point.businessPartnerDistributor.name,
          state: 'company-resources-business-partners-view',
          param: 'id',
          paramValue: point.businessPartnerDistributor._id,
          type: 'link'
        },
        {
          type: 'code',
          value: point.distributorCode,
          title: gettext('Distributor Code')
        }
      );
    }

    if (point.priceListMasterDistributor) {
      bps.push({
        type: 'link',
        title: gettext('Distributer price list'),
        linkTitle: point.priceListMasterDistributor.name,
        state: 'invoices-price-lists-view',
        param: 'priceListId',
        paramValue: point.priceListMasterDistributor._id
      });
    }

    if (point.businessPartnerSupplier && point.businessPartnerSupplier._id) {
      bps.push(
        {
          title: gettext('Supplier'),
          linkTitle: point.businessPartnerSupplier.name,
          state: 'company-resources-business-partners-view',
          param: 'id',
          paramValue: point.businessPartnerSupplier._id,
          type: 'link'
        },
        {
          type: 'code',
          value: point.supplierCode,
          title: gettext('Supplier Code')
        }
      );
    }

    if (point.priceListMasterSupplier) {
      bps.push({
        type: 'link',
        title: gettext('Supplier price list'),
        linkTitle: point.priceListMasterSupplier.name,
        state: 'invoices-price-lists-view',
        param: 'priceListId',
        paramValue: point.priceListMasterSupplier._id
      });
    }

    if (point.location && point.location._id) {
      bps.push({
        title: gettext('Location'),
        linkTitle: point.location.name,
        state: 'company-resources-locations-view',
        param: 'id',
        paramValue: point.location._id,
        type: 'link'
      });
    }

    var tags = [
      {
        type: 'tags',
        paramName: 'name',
        values:
          point && point.tags
            ? point.tags.map(function(tag) {
              return tag.systemTag;
            })
            : null
      }
    ];

    var physicalInfo = [
      {
        title: gettext('Maximum capacity'),
        value: point.maximumCapacityValue
          ? point.maximumCapacityValue
          : gettextCatalog.getString('None'),
        type: 'simple'
      },
      {
        title: gettext('Physical quantity'),
        value: point.physicalQuantityName
          ? point.physicalQuantityName
          : gettextCatalog.getString('None'),
        type: 'simple'
      },
      {
        title: gettext('Measurement unit'),
        value: point.readableMeasurementUnit
          ? point.readableMeasurementUnit
          : gettextCatalog.getString('None'),
        type: 'simple'
      }
    ];
    var propertySections = [
      {
        title: gettext('Properties'),
        properties: properties
      },
      {
        properties: bps
      },
      {
        title: gettext('Integration'),
        properties: integration
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'measuringpoints',
        target: id,
        type: 'userAccess'
      }
    ];

    if (point.physicalQuantityName && point.readableMeasurementUnit) {
      propertySections.push({
        title: gettext('Physical information'),
        properties: physicalInfo
      });
    }

    propertySections.push({ title: gettext('System tags'), properties: tags });
    return {
      address: {
        geoLocation: point.geoLocation
      },
      metadata: {
        title: point.name,
        description: point.description,
        definition: gettext('Measuring point')
      },
      actions: actions,
      userAccess: userAccess,
      propertySections: propertySections
    };
  }

  function elementSelected(element) {
    if (!firstLoad) {
      vm.selected = element;
    }
    firstLoad = false;
  }
}
export default MeasuringPointController;
