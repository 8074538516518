createOrUpdateService.$inject = ['CrudToastFactory', '$q', 'CrawlerMethods'];

/**
 * @ngdoc service
 * @name common.createOrUpdateService
 * @description description of service.
 * @property {function} simpleCall - See method simpleCall.
 * @property {function} simpleWaterfall - See method simpleWaterfall.
 */
export default function createOrUpdateService(
  CrudToastFactory,
  $q,
  CrawlerMethods
) {
  /**
   * @memberof common.createOrUpdateService
   * @description a handles a single create/update to BE.
   * @param {Object} endpoint - Configuration containing  entity endpoint and method to be used
   * @param {boolean} editMode - if true - update, if falsey - create
   * @param {string} id - id of item being updated.
   * @param {Object} dataToSave - Object containing data being sent in the request
   * @param {string} state - redirect state on request completion (e.g. 'alarms-and-rules-sop-steps-view')
   * @param {string} paramName - to match the id (e.g. 'IdCusum', mostly 'id')
   * @param {Object} multipleIdsRedirect - if multiple ids are needed on redirection
   * @return {promise} returns a promise for the data sent
   */
  function simpleCall(
    endpoint,
    editMode,
    id,
    dataToSave,
    state,
    paramName,
    multipleIdsRedirect
  ) {
    var deferred = $q.defer();
    var apiCall;
    var singleIdRedirect;
    var method = CrawlerMethods.getMethod(endpoint);
    if (editMode) {
      apiCall = {
        $promise: method(
          {
            id: id
          },
          dataToSave
        )
      };
    } else {
      apiCall = {
        $promise: method({}, dataToSave)
      };
    }
    apiCall.$promise.then(
      function(res) {
        const messageType = editMode ? 'update' : 'create';
        if (multipleIdsRedirect) {
          if (!editMode) {
            for (var key in multipleIdsRedirect.params) {
              if (multipleIdsRedirect.params[key] === undefined) {
                multipleIdsRedirect.params[key] = res.data._id;
              }
            }
          }
          CrudToastFactory.toast(messageType, multipleIdsRedirect);
        }
        if (state) {
          singleIdRedirect = {
            state: state
          };
          if (paramName) {
            singleIdRedirect.paramName = paramName;
            singleIdRedirect.paramValue = res.data._id || null;
          }
          CrudToastFactory.toast(messageType, singleIdRedirect);
        }
        if (!state && !multipleIdsRedirect) {
          CrudToastFactory.toast(messageType);
        }
        deferred.resolve(res.data);
      },
      function(err) {
        deferred.reject(err);
      }
    );
    return deferred.promise;
  }
  /**
   * @memberof common.createOrUpdateService
   * @description Function to be used when creating or updating entities with a waterfall
   * @param {array} waterfall - array of calls that need to be made
   * @param {string} message - used for toast message on create/update (e.g. 'Rule was successfully updated')
   * @param {string} state - redirect state on request completion (e.g. 'alarms-and-rules-sop-steps-view')
   * @param {string} paramName - to match the id (e.g. 'groupId', mostly 'id')
   * @return {promise} returns a promise for the data sent
   */
  function simpleWaterfall(waterfall, message, state, paramName) {
    var deferred = $q.defer();
    async.waterfall(waterfall, function(err, entity) {
      if (!err) {
        deferred.resolve(entity);
        if (message && state && paramName) {
          var singleIdRedirect = {
            state: state,
            paramName: paramName,
            paramValue: entity._id
          };
          CrudToastFactory.toast(message, singleIdRedirect);
        }
      } else {
        deferred.reject(err);
      }
    });
    return deferred.promise;
  }

  return {
    simpleCall: simpleCall,
    simpleWaterfall: simpleWaterfall
  };
}
