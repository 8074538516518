ModifyGroupModel.$inject = ['ModelUtilities'];

function ModifyGroupModel(ModelUtilities) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'identity',
    path: 'modify-group'
  };

  var methods = {
    create: ModelUtilities.crud('POST', networkConfiguration, modelStorage),
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default ModifyGroupModel;
