CalculationController.$inject = [
  'ExpressionModel',
  'AlertingService',
  '$stateParams',
  'gettext',
  'MetadataService',
  'analysisCalculation',
  '$timeout'
];

function CalculationController(
  ExpressionModel,
  AlertingService,
  $stateParams,
  gettext,
  MetadataService,
  analysisCalculation,
  $timeout
) {
  var vm = this;
  var calculationId = $stateParams.id;

  init();

  function init() {
    MetadataService.Loading(true);
    vm.loading = true;
    vm.accumulators = [];
    vm.arithmetic = [];

    vm.headerData = constructItemHeader(analysisCalculation);
    vm.loading = false;
    $timeout(() => {
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(
        analysisCalculation.name,
        analysisCalculation.description
      );
    });
  }

  function readExpression(exp) {
    ExpressionModel.read({
      id: exp.value
    }).then(
      function(res) {
        exp.preview = res.data.value;
        exp.readableName = res.data.name;
        vm.accumulators.push(exp);
      },
      function(err) {
        AlertingService.Error(err);
      }
    );
  }

  function getExpressions(series) {
    series.forEach(function(exp) {
      switch (exp.type) {
      case 'Accumulator':
        readExpression(exp);
        break;
      case 'Arithmetic':
        if (exp.value) {
          exp.preview = exp.value;
          vm.arithmetic.push(exp);
        }
        break;
      }
    });
  }

  function constructItemHeader(calculation) {
    getExpressions(calculation.series);
    vm.previewExpression = calculation.value;

    var properties = [];

    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];

    if (calculation.dataset._id) {
      properties.push({
        title: gettext('Dataset'),
        linkTitle: calculation.dataset.name,
        state: 'analytics-datasets-view',
        param: 'id',
        paramValue: calculation.dataset._id,
        type: 'link'
      });
    }
    var propertySections = [
      {
        title: gettext('properties'),
        properties: properties
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'analysis-calculations',
        target: calculationId,
        type: 'userAccess'
      }
    ];
    var actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: calculationId,
        endpoint: {
          entity: 'analysis-calculations',
          method: 'delete'
        },
        redirectState: 'analytics-calculations-list'
      },
      {
        title: gettext('Update'),
        state: 'analytics-calculations-edit',
        param: 'id',
        paramValue: calculation._id
      }
    ];

    return {
      metadata: {
        definition: gettext('Calculation'),
        title: calculation.name,
        description: calculation.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
}

export default CalculationController;
