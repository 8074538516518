/**
* @ngdoc component
* @name common.sfeIcon
* @description Show icons passed to it.
* @param {Object} chosenIcon - Icon configuration, contains color, name and type. Type tells us the icon library we are using.
Types are 1 = petrol icons, type 2 = material icons, type 3 = font awesome, type 4 = weather-icons
* @param {string} iconSize - contains icon size class
* @example
* <sfe-icon
*   chosen-icon="{color: 'red', type: 1, name: picon_energy-soutions}"
* >
* </sfe-icon>
*/
import './sfe-icon.scss';
import template from './sfe-icon.component.html';

export default {
  template,
  controller: sfeIcon,
  controllerAs: 'vm',
  bindings: {
    chosenIcon: '<',
    iconSize: '@'
  }
};

function sfeIcon() {
  let vm = this;
  vm.$onChanges = () => {
    if (vm.chosenIcon != null && vm.chosenIcon.type == null) {
      vm.chosenIcon.type = 2; // material icons by default
    }
  };
}
