/* eslint-disable no-unused-vars */
AssetController.$inject = [
  '$stateParams',
  'gettext',
  'EntityTagService',
  'utilService',
  'MetadataService',
  'UploadFilesConfiguration',
  'asset',
  'AssetRatedPowerConfiguration',
  'AssetGroupModel',
  'AlertingService'
];

function AssetController(
  $stateParams,
  gettext,
  EntityTagService,
  utilService,
  MetadataService,
  UploadFilesConfiguration,
  asset,
  AssetRatedPowerConfiguration,
  AssetGroupModel,
  AlertingService
) {
  const vm = this;
  const id = $stateParams.id;
  vm.treeType = 'childrenTree';
  vm.entity = 'assets';
  vm.childEntity = 'assets';
  vm.elementSelected = elementSelected;
  vm.isLoading = false;
  let firstLoad = true;

  vm.$onInit = async () => {
    vm.loading = true;
    MetadataService.Loading(true);
    vm.ratedPowerConfiguration = AssetRatedPowerConfiguration.get(asset);
    vm.filterObject = {
      asset: id
    };
    vm.entityConfig = {
      updateParam: {
        asset: id
      },
      createParam: {
        asset: id
      }
    };
    asset.parent = asset.parentId;
    let configuration = UploadFilesConfiguration;
    configuration.files = asset.documents || [];
    configuration.route = 'assets';
    configuration.id = id;
    configuration.listId = 'assets' + id;
    vm.config = configuration;
    vm.address = asset.geoLocation
      ? {
        geoLocation: asset.geoLocation
      }
      : {};

    const entityTags = await EntityTagService.listTags(id, true);
    asset.tags = entityTags.map(item => item.entityTag);
    if (asset.type != null && asset.type.assetGroup != null) {
      try {
        const { data } = await AssetGroupModel.read({
          id: asset.type.assetGroup
        });
        asset.type.assetGroupObj = { ...data };
      } catch (err) {
        AlertingService.Error(err);
      }
    }
    vm.headerData = constructHeader(asset);
    vm.loading = false;
    vm.asset = asset;
  };

  function constructHeader(asset) {
    let properties = [
      {
        type: 'simple',
        title: gettext('Status'),
        value: asset.status ? asset.status.name : 'Unknown'
      },
      {
        type: 'simple',
        title: gettext('Group'),
        value:
          asset.type && asset.type.assetGroupObj
            ? asset.type.assetGroupObj.name
            : gettext('Unknown')
      }
    ];

    if (asset.type) {
      properties.push({
        title: gettext('Type'),
        linkTitle: asset.type.name,
        state: 'configurations-company-resources-assets-asset-types-view',
        param: 'id',
        paramValue: asset.type._id,
        type: 'link'
      });
    } else {
      properties.push({
        type: 'simple',
        title: gettext('Type'),
        value: 'Unknown'
      });
    }
    if (asset.parent) {
      properties.push({
        title: gettext('Parent'),
        linkTitle: asset.parent.name,
        state: 'company-resources-assets-view',
        param: 'id',
        paramValue: asset.parent._id,
        type: 'link'
      });
    } else {
      properties.push({
        type: 'simple',
        title: gettext('Parent'),
        value: 'Unknown'
      });
    }
    properties.push({
      type: 'simple',
      title: gettext('Brand'),
      value: asset.assetBrand ? asset.assetBrand.name : 'Unknown'
    });
    properties.push({
      type: 'raw',
      title: gettext('Model'),
      value: asset.assetModel ? asset.assetModel.name : 'Unknown'
    });
    properties.push({
      type: 'code',
      title: gettext('Code'),
      value: asset.code ? asset.code : 'Unknown'
    });

    if (asset.externalCode) {
      properties.push({
        title: gettext('External code'),
        value: asset.externalCode,
        type: 'code'
      });
    }

    if (asset.owner) {
      properties.push({
        title: gettext('Asset owner'),
        linkTitle: asset.owner.name,
        state: 'company-resources-business-partners-view',
        param: 'id',
        paramValue: asset.owner._id,
        type: 'link'
      });
    }

    if (asset.ownership) {
      properties.push({
        title: gettext('Asset ownership'),
        value: asset.ownership.name,
        type: 'simple'
      });
    }
    if (asset.mainCostCentre && asset.mainCostCentre._id) {
      properties.push({
        title: gettext('Main cost centre'),
        linkTitle: asset.mainCostCentre.name,
        state: 'company-resources-cost-centres-view',
        param: 'id',
        paramValue: asset.mainCostCentre._id,
        type: 'link'
      });
    }

    if (
      Array.isArray(asset.mainLocation) &&
      asset.mainLocation[0] &&
      asset.mainLocation[0].location
    ) {
      const mainLocation = asset.mainLocation[0].location;
      properties.push({
        title: gettext('Location'),
        linkTitle: mainLocation.name,
        state: 'company-resources-locations-view',
        param: 'id',
        paramValue: mainLocation._id,
        type: 'link'
      });
    }

    if (asset.modelNumber) {
      properties.push({
        title: gettext('Model number'),
        value: asset.modelNumber,
        type: 'code'
      });
    }
    if (asset.serialNumber) {
      properties.push({
        title: gettext('Serial number'),
        value: asset.serialNumber,
        type: 'code'
      });
    }

    properties.push({
      title: gettext('Tags'),
      type: 'tags',
      paramName: 'value',
      values: asset.tags
    });

    let userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];

    let images = [];
    if (asset.pictures) {
      let image = {};
      images = asset.pictures.map(function(item, index) {
        image = {
          id: index + 1,
          url: utilService.getApiHost + '/pictures/' + item.picture + '/image',
          title: 'not fetched'
        };
        return image;
      });
    }

    let imgageItem = [
      {
        images,
        config: {
          noImagesMessage: gettext('No images found for this asset.')
        },
        type: 'image'
      }
    ];

    const tags = [
      {
        type: 'tags',
        paramName: 'name',
        values:
          asset && Array.isArray(asset.tagsSystem)
            ? asset.tagsSystem.map(function(tag) {
              return tag.systemTag;
            })
            : null
      }
    ];

    let propertySections = [
      {
        title: gettext('Properties'),
        properties: properties
      },
      {
        title: gettext('Images'),
        properties: imgageItem
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'assets',
        target: id,
        type: 'userAccess'
      },
      {
        title: gettext('System tags'),
        properties: tags
      }
    ];
    let actions = [
      {
        title: gettext('delete'),
        delete: true,
        id: id,
        endpoint: {
          entity: 'assets',
          method: 'delete'
        },
        redirectState: 'company-resources-assets-list'
      },
      {
        title: gettext('duplicate'),
        state: 'company-resources-assets-duplicate',
        param: 'id',
        paramValue: id
      },
      {
        title: gettext('Update'),
        state: 'company-resources-assets-edit',
        param: 'id',
        paramValue: id
      }
    ];
    MetadataService.ChangeMetadata(asset.name, asset.description);
    MetadataService.Loading(false);

    return {
      metadata: {
        definition: gettext('Asset'),
        title: asset.name,
        description: asset.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }

  function elementSelected(element) {
    if (!firstLoad) {
      vm.selected = element;
    } else {
      firstLoad = false;
    }
  }
}
export default AssetController;
