import './sfe-display-fields.scss';
import template from './sfe-display-fields.directive.html';
/**
 * @ngdoc directive
 * @name moduleName.sfeDisplayFields
 * @description directive displayed values in specific field types, based on the data type of the values
 * @param {array} fields - field types you wished displayed
 * @param {object} item - values you wished displayed in said fields
 * @param {function} displayFn - returns object that has text - String - to bi displayed, chosenIcon - Object - icon configuration, chosen image - Object - imag configuration, that contains endpoint and gravatarId
 * @example
 * <sfe-display-fields
 *   field="vm.fields"
 *   item="vm.item"
 *  displayFn="vm.displayFn"
 * ></sfe-display-fields>
 */

export default {
  template,
  bindings: {
    fields: '<?',
    item: '<',
    displayFn: '<'
  },
  controller: sfeDisplayFieldsController,
  controllerAs: 'vm',
  bindToController: true
};

sfeDisplayFieldsController.$inject = [];

function sfeDisplayFieldsController() {
  var vm = this;

  vm.$onChanges = changes => {
    if (changes.item && vm.item != null && typeof vm.displayFn == 'function') {
      vm.fields = null;
      vm.displayItem = vm.displayFn(vm.item);
    } else if (vm.fields) {
      if (vm.fields.includes('name', 1) && !vm.item.ianaCountryCode) {
        vm.fields.splice(vm.fields.indexOf('name'), 1);
        vm.fields.unshift('name');
      }
    } else {
      vm.fields = ['name'];
    }
  };
}
