module.exports = {
  entity: 'time-series',
  path: 'time-series',
  entityId: 234,
  storeKey: 'timeSeries',
  name: {
    en: 'Time Series',
    sl_SI: 'Časovna serija'
  },
  networkModel: {
    entity: 'time-series',
    read: 'custom.populateDataScheduler'
  },
  scope: 'tis_be:time-series',

  selectedParamName: '_id',
  displayFields: [
    {
      field1: 'name',
      type: 'string'
    },
    {
      field1: 'dataScheduler',
      field2: 'scheduleClassification',
      codelist: 'scheduleClassifications',
      type: 'codelist'
    }
  ],
  dialog: {
    displayFields: [
      {
        field1: 'name',
        type: 'string'
      },
      {
        field1: 'dataScheduler',
        field2: 'scheduleClassification',
        codelist: 'scheduleClassifications',
        type: 'codelist'
      }
    ],
    title: {
      en: 'Select time series',
      sl_SI: 'Izberite časovno serijo'
    },
    listInfo: {
      en: 'Select time series from the list below',
      sl_SI: 'Izberite časovno serijo s spodnjega seznama'
    }
  }
}
