AddressHelperService.$inject = ['AlertingService', 'AddressModel'];
/**
 * @ngdoc service
 * @name companyResources.AddressHelperService
 * @description address helper service, check if address exists
 * @property {function} checkIfAddressExists
 */
export default function AddressHelperService(AlertingService, AddressModel) {
  /**
   * @description check if address already exists.
   * @function
   * @param {Object} address
   */
  const checkIfAddressExists = async address => {
    let foundAddres;
    if (address != null) {
      const queryParams = Object.entries(address).reduce(
        (paramObj, [key, value]) => {
          if (value == null) {
            paramObj[key] = '';
          } else {
            paramObj[key] = value;
          }
          return paramObj;
        },
        {}
      );
      try {
        const { data } = await AddressModel.read(queryParams);
        if (Array.isArray(data) && data.length > 0) {
          foundAddres = data[0];
        }
      } catch (err) {
        AlertingService.Error(err);
        // Rethrow error for waterfall callback
        throw err;
      }
    }
    return foundAddres;
  };

  return { checkIfAddressExists };
}
