DomainFieldsListConfigService.$inject = ['gettext', '$q'];

function DomainFieldsListConfigService(gettext, $q) {
  function getConfig(domainId) {
    return {
      entity: {
        singular: gettext('Domain Field'),
        plural: gettext('Domain Fields')
      },
      api: {
        query: {
          entity: 'domain-fields',
          method: 'read'
        },
        enrich: enrich
      },
      title: {
        param: 'name',
        link: {
          state: 'configurations-dynamic-attributes-domains-fields-view',
          params: [
            {
              valueParam: '_id',
              queryParam: 'fieldId'
            },
            {
              value: domainId,
              queryParam: 'domainId'
            }
          ]
        }
      },
      buttons: [
        {
          title: gettext('Create'),
          link: {
            state: 'configurations-dynamic-attributes-domains-fields-new',
            params: [
              {
                value: domainId,
                queryParam: 'domainId'
              }
            ]
          },
          color: 'accent'
        }
      ],
      shortActions: [
        {
          icon: 'mode_edit',
          link: {
            state: 'configurations-dynamic-attributes-domains-fields-edit',
            params: [
              {
                valueParam: '_id',
                queryParam: 'fieldId'
              },
              {
                value: domainId,
                queryParam: 'domainId'
              }
            ]
          }
        },
        {
          icon: 'delete',
          query: {
            queryParam: 'fieldId',
            valueParam: '_id',
            method: 'delete',
            configuration: {
              entity: 'domain-fields',
              method: 'delete',
              params: {
                domainId: domainId
              }
            }
          }
        }
      ],
      attributes: [
        {
          title: gettext('Description'),
          param: 'description'
        },
        {
          title: gettext('Type'),
          param: 'fieldType'
        }
      ],
      sort: [
        {
          display: 'created DESC',
          params: '-_id'
        },
        {
          display: 'created ASC',
          params: '_id'
        }
      ]
    };
  }

  function enrich() {
    var pr = $q.defer();
    pr.resolve();
    return pr.promise;
  }

  return {
    getConfig: getConfig
  };
}

export default DomainFieldsListConfigService;
