import timeSeriesAction from '../../data/redux/time-series/action/times-series.action';

StateResolveService.$inject = [
  '$ngRedux',
  'AlertingService',
  'MetadataService',
  'EntityViewMethodService'
];
/**
 * @ngdoc service
 * @name common.StateResolveService
 * @description opens datasource tag selector dialog.
 * @property {function} open
 */
export default function StateResolveService(
  $ngRedux,
  AlertingService,
  MetadataService,
  EntityViewMethodService
) {
  /**
   * @memberof StateResolveService.get
   * @description  returns object that contains configurations to build tango item
   * @param {number} entity code list entity id
   * @param {string} entityId entity id
   * @return {Object}
   */
  async function fetchEntityForView(transition) {
    const { entity } = transition.to();
    const method = EntityViewMethodService.getMethod(transition);
    const filterObject = EntityViewMethodService.getParams(transition);
    MetadataService.stateChangeHappening(true);

    if (method) {
      try {
        let res = await method(filterObject);
        let action = getAction(entity);
        MetadataService.stateChangeHappening(false);
        if (action != null) {
          $ngRedux.dispatch(
            action({
              ...filterObject,
              result: res
            })
          );
        } else {
          return res?.data;
        }
        return true;
      } catch (err) {
        AlertingService.Error(err);
        MetadataService.stateChangeHappening(false);
        const state = transition.router.stateService;
        transition.abort();
        if (err.status == 403) {
          state.go('forbidden');
        } else if (err.status == 404) {
          state.go('not-found');
        } else {
          state.go('bad-request');
        }
      }
    } else {
      MetadataService.stateChangeHappening(false);
    }
  }
  /**
   * @description returns store action method.
   * @function
   * @param {String} entity
   * @return {function}
   */
  function getAction(entity) {
    switch (entity) {
    case 'time-series':
      return timeSeriesAction.readTimeSeries;
    }
  }

  return {
    fetchEntityForView
  };
}
