import actionTypes from '../action/calculated-flow.type';

CalculatedFlowMiddleware.$inject = [
  'CalculatedFlowModel',
  'AlertingService',
  'CrudToastFactory',
  'PhysicalCollectionService'
];
function CalculatedFlowMiddleware(
  CalculatedFlowModel,
  AlertingService,
  CrudToastFactory,
  PhysicalCollectionService
) {
  /**
   * @description gets readable physical data for input time series.
   * @function
   * @param {Object} inputTimeSeries
   * @return {String}
   */
  async function getPhysicalData(inputTimeSeries) {
    try {
      let res = await PhysicalCollectionService.getReadablePhysicalData(
        inputTimeSeries
      );
      if (
        res != null &&
        res.physicalQuantity != null &&
        res.readableMeasurementUnit != null
      ) {
        return `${res.physicalQuantity.name} ${res.readableMeasurementUnit}`;
      }
    } catch (err) {
      AlertingService.Error(err);
    }
  }
  /**
   * @description populates input timeseries with physical data.
   * @function
   * @param {Object} flow
   * @return {Object}
   */
  async function populateInputTimeSeriesPhysicalData(flow) {
    if (
      flow != null &&
      flow.data != null &&
      Array.isArray(flow.data.inputTimeSeries) &&
      flow.data.inputTimeSeries.length > 0
    ) {
      const promises = flow.data.inputTimeSeries.map(inputTimeSeries =>
        getPhysicalData(inputTimeSeries.timeSeries)
      );
      const result = await Promise.all(promises);
      let inputTimeSeries = flow.data.inputTimeSeries.map(
        (inputTimeSeries, index) => {
          if (result[index] != null) {
            return {
              ...inputTimeSeries,
              physicalData: result[index]
            };
          }
          return inputTimeSeries;
        }
      );

      flow.data.inputTimeSeries = inputTimeSeries;
    }
    return flow;
  }
  return () => next => async action => {
    switch (action.type) {
    case actionTypes.CALCULATED_FLOW_READ:
      try {
        let result = await CalculatedFlowModel.read({
          ...action.payload,
          populate:
              'inputTimeSeries.timeSeries,inputDynamicAttributes.attributeObjectId,inputDynamicAttributes.domainAttribute'
        });
        result = await populateInputTimeSeriesPhysicalData(result);
        action.payload = {
          ...action.payload,
          result
        };
      } catch (err) {
        AlertingService.Error(err);
        action.payload.result = err;
      }
      break;
    case actionTypes.CALCULATED_FLOW_UPDATE:
      try {
        await CalculatedFlowModel.update(
          action.payload.query,
          action.payload.body
        );
        CrudToastFactory.toast('update');
      } catch (err) {
        AlertingService.Error(err);
      }

      try {
        let result = await CalculatedFlowModel.read({
          ...action.payload.query,
          populate:
              'inputTimeSeries.timeSeries,inputDynamicAttributes.attributeObjectId,inputDynamicAttributes.domainAttribute'
        });
        result = await populateInputTimeSeriesPhysicalData(result);
        action.payload.result = result;
      } catch (err) {
        AlertingService.Error(err);
        action.payload.result = err;
      }
      break;
    case actionTypes.CALCULATED_FLOW_CREATE:
      try {
        action.payload.result = await CalculatedFlowModel.create(
          action.payload.query || {},
          action.payload.body
        );
      } catch (err) {
        AlertingService.Error(err);
      }
      break;
    }
    next(action);
  };
}

export default CalculatedFlowMiddleware;
