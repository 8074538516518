// styles
import './styles/user.scss';

// pages
import AbsenceController from './pages/absent-personnel/absence.controller';
import OnDutyController from './pages/assigned-personnel/on-duty.controller';
import GroupController from './pages/group/group.controller';
import NewGroupFormController from './pages/group/new-group-form.controller';
import NewNotificationController from './pages/notification/new-notification.controller';
import NotificationListController from './pages/notification/notification-list.controller';
import NotificationController from './pages/notification/notification.controller';
import UnauthenticatedController from './pages/unauthenticated/unauthenticated.controller';
import NewUserFormController from './pages/user/new-user-form.controller';
import UserController from './pages/user/user.controller';
import UserPrivilegeController from './pages/user-privilege/user-privilege.controller';

// directives
import workSchedule from './directives/work-schedule/work-schedule.directive';

// services
import IndirectAccessDialog from './services/indirect-access-dialog/IndirectAccessDialog.service';
import PrivilegeHelperMethods from './services/privilege-helper-method/PrivilegeHelperMethods.service';
import userTemplateConstant from './services/userTemplate-constant/userTemplate.constant';

// components
import indirectAccess from './components/indirect-access/indirect-access.component';
import indirectAccessItem from './components/indirect-access-item/indirect-access-item.component';
import notificationItem from './components/notification-item/notification-item.component';
import sfeRootAccess from './components/root-access/sfe-root-access.component';
import sfeRootAccessItem from './components/root-access-item/sfe-root-access-item.component';
import sfeEntityIndirectPermissionTree from './components/sfe-entity-indirect-permission-tree/sfe-entity-indirect-permission-tree.component';
import sfeEntityPrivilegeManagement from './components/sfe-entity-privilege-management/sfe-entity-privilege-management.component';
import sfeEntityPrivilegeManagementItem from './components/sfe-entity-privilege-management-item/sfe-entity-privilege-management-item.component';
import sfeThemeSelector from './components/sfe-theme-selector/sfe-theme-selector.component';
import sfeUserCard from './components/sfe-user-card/sfe-user-card.component';
import sfeUserModules from './components/sfe-user-modules/sfe-user-modules.component';
import userView from './components/user-view/user-view.component';

// configurations
import GroupConfiguration from './configurations/group.configuration';
import myNotificationConfiguration from './configurations/my-notifications.configuration';
import UserConfiguration from './configurations/user.configuration';

// network models
import UsersInGroup from './network-models/UsersInGroup.model';
import GroupModel from './network-models/Group.model';
import GroupsOfUserModel from './network-models/GroupsOfUsers.model';
import InteractionModel from './network-models/Interaction.model';
import ModifyGroupModel from './network-models/ModifyGroup.model';
import NotificationModel from './network-models/Notification.model';
import ProfileModel from './network-models/Profile.model';
import RealmModel from './network-models/Realm.model';
import UserModel from './network-models/User.model';
import WorkScheduleModel from './network-models/WorkSchedule.model';
import GisUserModel from './network-models/GisUser.model';
import GisUserGroupModel from './network-models/GisUserGroup.model';
import AuthorizationInstanceRuleModel from './network-models/AuthorizationInstanceRule.model';
import AuthorizationInstanceToScopeModel from './network-models/AuthorizationInstanceToScope.model';
import AuthorizationListPermittedModel from './network-models/AuthorizationListPermitted.model';
import AuthorizationRootPermissionModel from './network-models/AuthorizationRootPermission.model';
import AuthorizationShowScopeModel from './network-models/AuthorizationShowScope.model';
import AuthorizationStaticAccessModel from './network-models/AuthorizationStaticAccess.model';
import AuthorizationTreeModel from './network-models/AuthorizationTree.model';
import AuthorizationTreeAccessModel from './network-models/AuthorizationTreeAccess.model';
import AuthorizationTreePathModel from './network-models/AuthorizationTreePath.model';

//TANGO ITEM PROPERTIES
import UserTangoProperties from './tango-properties/user-tango-properties.service';

import routes from './user.routes';

export default {
  routes,
  controllers: {
    AbsenceController,
    OnDutyController,
    GroupController,
    NewGroupFormController,
    NewNotificationController,
    NotificationListController,
    NotificationController,
    UnauthenticatedController,
    NewUserFormController,
    UserController,
    UserPrivilegeController
  },
  directives: {
    workSchedule
  },
  services: {
    IndirectAccessDialog,
    PrivilegeHelperMethods,
    userTemplateConstant,
    UsersInGroup,
    GroupModel,
    GroupsOfUserModel,
    InteractionModel,
    ModifyGroupModel,
    NotificationModel,
    ProfileModel,
    RealmModel,
    UserModel,
    WorkScheduleModel,
    GisUserModel,
    GisUserGroupModel,
    AuthorizationInstanceRuleModel,
    AuthorizationInstanceToScopeModel,
    AuthorizationListPermittedModel,
    AuthorizationRootPermissionModel,
    AuthorizationShowScopeModel,
    AuthorizationStaticAccessModel,
    AuthorizationTreeModel,
    AuthorizationTreeAccessModel,
    AuthorizationTreePathModel
  },
  components: {
    indirectAccess,
    indirectAccessItem,
    notificationItem,
    sfeRootAccess,
    sfeRootAccessItem,
    sfeEntityIndirectPermissionTree,
    sfeEntityPrivilegeManagement,
    sfeEntityPrivilegeManagementItem,
    sfeThemeSelector,
    sfeUserCard,
    sfeUserModules,
    userView
  },
  factories: {
    GroupConfiguration,
    myNotificationConfiguration,
    UserConfiguration,
    UserTangoProperties
  }
};
