DataVisualizationConfiguration.$inject = [
  'gettextCatalog',
  'TranslationService',
  'partialScheduleClassificationConfiguration',
  '$timeout',
  'ColorService',
  'codelistsConstant'
];

function DataVisualizationConfiguration(
  gettextCatalog,
  TranslationService,
  partialScheduleClassificationConfiguration,
  $timeout,
  ColorService,
  codelistsConstant
) {
  /**
   * @description get codelist name by codelist type and id.
   * @function
   * @param {number} id
   * @param {string} codelistType
   * @return {string} name
   */
  const getCodelistName = (codelistType, id) => {
    let codelist = codelistsConstant[codelistType].find(item => item.id === id);
    return codelist != null ? codelist.name : null;
  };
  /**
   * @description returns interpretation type if exists.
   * @function
   * @param {Array} steps
   * @param {Object} data timeSeries
   * @return {Number} id of interpretation type
   */
  function getInterpretationType(steps, data) {
    let dataInterpretationType;
    if (Array.isArray(steps)) {
      const generalStep = steps.find(
        step => step.stepId === 'timeSeriesCommonProperties'
      );
      if (
        generalStep != null &&
        generalStep.api &&
        typeof generalStep.api.getValue === 'function'
      ) {
        dataInterpretationType = generalStep.api.getValue(
          'dataInterpretationType'
        );
      }
    } else if (data != null && data.dataInterpretationType != null) {
      dataInterpretationType = data.dataInterpretationType;
    }

    return dataInterpretationType;
  }

  /**
   * @description get data visualization configuration.
   * @function
   * @param {Object} data - configuration data
   * @param {Array} steps - Array of objects, containing tangoWizard steps.
   * @return {Object}  configuration
   */
  const get = (data, steps) => {
    let dataVisualizationConfig;
    let defaultFilter;
    if (data != null && data.dataVisualizationConfig != null) {
      dataVisualizationConfig = data.dataVisualizationConfig;
      defaultFilter = dataVisualizationConfig.defaultFilter;
    }
    let partialConfig = partialScheduleClassificationConfiguration.timeSeriesVersion(
      defaultFilter
    );
    let chartTypes = TranslationService.GetCollection('codelists.chartTypes');
    let filteredChartTypes = chartTypes.filter(
      chartType => chartType.dataStreamVisualizationSupport === true
    );
    let displayTypes = TranslationService.GetCollection(
      'codelists.displayTypes'
    );
    // Remove 'gauge' and 'gauge and table' from displayTypes
    let filteredDisplayTypes = displayTypes.reduce(
      (filteredTypes, displayType) => {
        if (displayType.id !== 4 && displayType.id !== 5) {
          let filteredType = {
            id: displayType.id,
            value: displayType.id,
            name: displayType.name
          };
          filteredTypes.push(filteredType);
        }
        return filteredTypes;
      },
      []
    );
    let configuration = {
      name: 'dataVisualizationConfig',
      actions: [],
      title: gettextCatalog.getString('Data visualization'),
      fields: [
        {
          id: 'displayType',
          title: gettextCatalog.getString('Display types'),
          type: {
            name: 'radio',
            options: {
              layout: 'row',
              items: filteredDisplayTypes,
              display: data => {
                return `${
                  data && data.name
                    ? data.name
                    : gettextCatalog.getString('Unknown')
                }`;
              }
            }
          },
          initialize: () => {
            return dataVisualizationConfig != null
              ? dataVisualizationConfig.displayType
              : null;
          },
          onChange: form => {
            $timeout(() => {
              let displayType = form.getValue('displayType');
              let displayTypeName;
              if (displayType != null) {
                displayTypeName = getCodelistName('displayTypes', displayType);
              }
              // Initialize chartType and color if displayType is chart or chart and table, set to null otherwise.
              if (
                displayTypeName === 'Chart' ||
                displayTypeName === 'Chart and table'
              ) {
                let chartType = 7;
                const dataInterpretationType = getInterpretationType(
                  steps,
                  data
                );
                if (dataInterpretationType != null) {
                  const interpretationType = TranslationService.GetCollectionById(
                    'codelists.dataInterpretationTypes',
                    dataInterpretationType
                  );
                  if (
                    interpretationType != null &&
                    interpretationType.defaultChartType
                  ) {
                    chartType = interpretationType.defaultChartType;
                  }
                }

                let tmpColor = ColorService.getApplicationColor('accent');
                if (data != null && data.dataVisualizationConfig != null) {
                  if (data.dataVisualizationConfig.chartType != null) {
                    chartType = data.dataVisualizationConfig.chartType;
                  }
                  if (data.dataVisualizationConfig.color != null) {
                    tmpColor = data.dataVisualizationConfig.color;
                  }
                }
                form.setValue('chartType', chartType);
                form.setValue('color', tmpColor);
              } else {
                form.setValue('chartType', null);
                form.setValue('color', null);
              }
            });
          },
          validators: {
            dataType: {
              fn: modelValue => {
                let stepWithDataType;
                let dataType;
                let dataTypeName;
                let displayTypeName;
                if (Array.isArray(steps)) {
                  stepWithDataType = steps.find(step =>
                    step.api.getValue('dataType')
                  );
                }
                if (data != null) {
                  dataType = { id: data.dataType };
                }
                if (stepWithDataType != null) {
                  dataType = stepWithDataType.api.getValue('dataType');
                }
                if (dataType != null && modelValue != null) {
                  dataTypeName = getCodelistName('dataTypes', dataType.id);
                  displayTypeName = getCodelistName('displayTypes', modelValue);
                  if (
                    dataTypeName === 'Integer' ||
                    dataTypeName === 'Decimal'
                  ) {
                    return true;
                  }
                  if (
                    (dataTypeName !== 'Integer' &&
                      displayTypeName !== 'Table') ||
                    (dataTypeName !== 'Decimal' && displayTypeName !== 'Table')
                  ) {
                    return false;
                  }
                }
                return true;
              },
              text: gettextCatalog.getString('Incompatible data type')
            }
          },
          required: true
        },
        {
          id: 'chartType',
          title: gettextCatalog.getString('Chart type'),
          type: {
            name: 'autocomplete',
            options: {
              items: filteredChartTypes,
              filter: items => {
                const dataInterpretationType = getInterpretationType(
                  steps,
                  data
                );

                if (dataInterpretationType != null) {
                  return items.filter(item => {
                    if (
                      Array.isArray(
                        item.supportedTimeSeriesDataInterpretationTypes
                      )
                    ) {
                      return item.supportedTimeSeriesDataInterpretationTypes.find(
                        type => type == dataInterpretationType
                      );
                    }
                  });
                }

                return items;
              },
              display: data => {
                return {
                  text: `${
                    data.name ? data.name : gettextCatalog.getString('Unknown')
                  }`
                };
              }
            }
          },
          initialize: () => {
            return dataVisualizationConfig != null
              ? dataVisualizationConfig.chartType
              : null;
          },
          hide: form => {
            let displayType = form.getValue('displayType');
            let displayTypeName;
            if (displayType != null) {
              displayTypeName = getCodelistName('displayTypes', displayType);
            }
            // Hide chartType if displayType is not chart or chart and table
            if (
              displayTypeName === 'Chart' ||
              displayTypeName === 'Chart and table'
            ) {
              return false;
            }
            return true;
          },
          required: true
        },
        {
          id: 'color',
          title: gettextCatalog.getString('Color'),
          type: {
            name: 'colorPicker',
            options: {}
          },
          initialize: () => {
            return dataVisualizationConfig != null
              ? dataVisualizationConfig.color
              : null;
          },
          hide: form => {
            let displayType = form.getValue('displayType');
            let displayTypeName;
            if (displayType != null) {
              displayTypeName = getCodelistName('displayTypes', displayType);
            }
            // Hide color if displayType is not chart or chart and table
            if (
              displayTypeName === 'Chart' ||
              displayTypeName === 'Chart and table'
            ) {
              return false;
            }
            return true;
          },
          required: false
        },
        {
          id: 'minimumValue',
          title: gettextCatalog.getString('Minimum value'),
          type: {
            name: 'text',
            options: {
              type: 'numerical'
            }
          },
          initialize: () => {
            return dataVisualizationConfig != null &&
              dataVisualizationConfig.minimumValue != null
              ? dataVisualizationConfig.minimumValue
              : '';
          },
          validators: {
            maximumValueOfMin: {
              fn: (modelValue, viewValue, api) => {
                let maximumValue = api.getValue('maximumValue');
                if (
                  modelValue != null &&
                  modelValue !== '' &&
                  maximumValue != null &&
                  maximumValue !== '' &&
                  modelValue >= maximumValue
                ) {
                  return false;
                }
                return true;
              },
              text: gettextCatalog.getString('Must be less than maximum value')
            }
          },
          onChange: form => {
            $timeout(() => {
              form.revalidate();
            });
          },
          required: false,
          width: 6
        },
        {
          id: 'maximumValue',
          title: gettextCatalog.getString('Maximum value'),
          type: {
            name: 'text',
            options: {
              type: 'numerical'
            }
          },
          initialize: () => {
            return dataVisualizationConfig != null &&
              dataVisualizationConfig.maximumValue != null
              ? dataVisualizationConfig.maximumValue
              : '';
          },
          validators: {
            minimumValueOfMax: {
              fn: (modelValue, viewValue, api) => {
                let minimumValue = api.getValue('minimumValue');
                if (
                  modelValue != null &&
                  modelValue !== '' &&
                  minimumValue != null &&
                  minimumValue !== '' &&
                  modelValue <= minimumValue
                ) {
                  return false;
                }
                return true;
              },
              text: gettextCatalog.getString(
                'Must be greater than minimum value'
              )
            }
          },
          onChange: form => {
            $timeout(() => {
              form.revalidate();
            });
          },
          required: false,
          width: 6
        },
        ...partialConfig
      ]
    };
    return configuration;
  };
  return { get };
}
export default DataVisualizationConfiguration;
