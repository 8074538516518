ManualInputConfiguration.$inject = [
  'gettext',
  '$q',
  'SfeFormDialogService',
  'AlertingService',
  'CrudToastFactory',
  'TimeSeriesManualInputFormModel',
  'ListFormService'
];

function ManualInputConfiguration(
  gettext,
  $q,
  SfeFormDialogService,
  AlertingService,
  CrudToastFactory,
  TimeSeriesManualInputFormModel,
  ListFormService
) {
  const fieldNames = ['name', 'description', 'displayHorizontal'];
  function getManualInputConfiguration() {
    return [
      {
        placeholder: gettext('Name'),
        name: 'name',
        componentType: 'textField',
        showCond: true
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        componentType: 'textArea',
        maxlength: 500,
        required: false
      },
      {
        componentType: 'checkBox',
        label: gettext('Display horizontally'),
        name: 'displayHorizontal'
      }
    ];
  }

  function openCreateDialog(relistData) {
    var config = getManualInputConfiguration();
    var title = gettext('New manual input');
    var createObject = {};
    SfeFormDialogService.openSfeFormDialog(
      false,
      config,
      createObject,
      title
    ).then(
      function(object) {
        if (object) {
          TimeSeriesManualInputFormModel.create(
            ListFormService.generateBodyObject(object, fieldNames)
          ).then(
            function() {
              CrudToastFactory.toast('create');
              relistData();
            },
            function(err) {
              AlertingService.Error(err);
            }
          );
        }
      },
      function() {}
    );
  }

  function openUpdateDialog(item, relistData) {
    var config = getManualInputConfiguration();
    var title = gettext('Edit manual input');
    SfeFormDialogService.openSfeFormDialog(true, config, item, title).then(
      function(object) {
        if (object) {
          TimeSeriesManualInputFormModel.update(
            {
              id: item._id
            },
            ListFormService.generateBodyObject(object, fieldNames)
          ).then(
            function() {
              CrudToastFactory.toast('update');
              relistData();
            },
            function(err) {
              AlertingService.Error(err);
            }
          );
        }
      }
    );
  }

  var configuration = {
    listId: 'id',
    entity: {
      singular: gettext('Manual Input'),
      plural: gettext('Manual Input')
    },
    api: {
      query: {
        entity: 'time-series-manual-input-forms',
        method: 'read'
      },
      enrich: Enrich
    },
    buttons: [
      {
        title: gettext('Create'),
        fn: openCreateDialog,
        relist: true,
        color: 'accent'
      }
    ],
    title: {
      param: 'name',
      link: {
        state: 'data-manual-input-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    shortActions: [
      {
        icon: 'mode_edit',
        action: openUpdateDialog
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'time-series-manual-input-forms',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Description'),
        param: 'description'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      }
    ],
    sort: [
      {
        display: gettext('created DESC'),
        params: '-_id'
      },
      {
        display: gettext('created ASC'),
        params: '_id'
      },
      {
        display: gettext('name ASC'),
        params: 'name'
      },
      {
        display: gettext('name DESC'),
        params: '-name'
      }
    ]
  };

  function Enrich() {
    return $q.resolve();
  }

  return configuration;
}
export default ManualInputConfiguration;
