RatedPowerConfiguration.$inject = [
  'gettext',
  'SfeFormDialogService',
  'ListFormService',
  'AlertingService',
  'CrudToastFactory',
  'RatedPowerModel'
];

function RatedPowerConfiguration(
  gettext,
  SfeFormDialogService,
  ListFormService,
  AlertingService,
  CrudToastFactory,
  RatedPowerModel
) {
  const fieldNames = ['name', 'description', 'code', 'externalCode'];
  //ENERGY SOURCE TYPE AUTOCOMPLETE
  const energySourceAutocompleteConfiguration = {
    configuration: {
      query: {
        entity: 'energy-source-types',
        method: 'read'
      },
      entity: 'energy-source-types',
      dialogConfiguration: {
        multiple: false,
        title: gettext('Select Energy Source Type')
      },
      floatingLabel: gettext('Select Energy Source Type'),
      searchParamName: 'filter',
      required: true
    },
    componentType: 'autocompleteDialog',
    name: 'energySourceType'
  };
  /**
   * @description opens create rated power dialog.
   * @function
   * @param {function} relistData
   */
  async function openCreateDialog(relistData) {
    energySourceAutocompleteConfiguration.edit = false;
    const config = ListFormService.generateForm(fieldNames, false, [
      energySourceAutocompleteConfiguration
    ]);
    const title = gettext('New Rated Power');
    const formResult = await SfeFormDialogService.openSfeFormDialog(
      false,
      config,
      {},
      title
    );
    if (formResult) {
      create(formResult, relistData);
    }
  }
  /**
   * @description opens update rated power dialog.
   * @function
   * @param {Object} item item to update
   * @param {function} relistData
   */
  async function openUpdateDialog(item, relistData) {
    energySourceAutocompleteConfiguration.edit = true;
    try {
      const { data } = await RatedPowerModel.read({
        id: item._id
      });
      const config = ListFormService.generateForm(fieldNames, false, [
        energySourceAutocompleteConfiguration
      ]);
      const title = gettext('Edit Rated Power');
      const formResult = await SfeFormDialogService.openSfeFormDialog(
        true,
        config,
        data,
        title
      );

      if (formResult) {
        update(item._id, formResult, relistData);
      }
    } catch (err) {
      AlertingService.Error(err);
    }
  }
  /**
   * @description triggers create rated power method.
   * @function
   * @param {Object} obj
   * @param {function} relistData
   */
  async function create(obj, relistData) {
    let postObj = ListFormService.generateBodyObject(obj, fieldNames);
    if (obj.energySourceType) {
      postObj.energySourceType = obj.energySourceType._id;
    }

    try {
      await RatedPowerModel.create(postObj);
      CrudToastFactory.toast('create');
      relistData();
    } catch (err) {
      AlertingService.Error(err);
    }
  }
  /**
   * @description triggers update rated power method.
   * @function
   * @param {string} id
   * @param {Object} obj
   * @param {function} relistData
   */
  async function update(id, obj, relistData) {
    const updateObject = ListFormService.generateBodyObject(obj, fieldNames);
    updateObject.energySourceType = obj.energySourceType
      ? obj.energySourceType._id
      : null;
    try {
      await RatedPowerModel.update(
        {
          id: id
        },
        updateObject
      );
      CrudToastFactory.toast('update');
      relistData();
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  const configuration = {
    entity: {
      singular: gettext('Rated power'),
      plural: gettext('Rated powers')
    },
    api: {
      query: {
        entity: 'rated-powers',
        method: 'custom.readEnergySourceType'
      },
      enrich: Enrich
    },
    title: {
      param: 'name'
    },
    buttons: [
      {
        title: gettext('Create'),
        fn: openCreateDialog,
        relist: true,
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        action: openUpdateDialog
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'rated-powers',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        param: 'description',
        title: gettext('Description')
      },
      {
        param: 'code',
        title: gettext('Code'),
        type: 'code'
      },
      {
        param: 'externalCode',
        title: gettext('External Code'),
        type: 'code'
      },
      {
        param: 'energySourceTypeName',
        title: gettext('Energy source type')
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Code'),
        type: 'string',
        param: 'code'
      },
      {
        display: gettext('External Code'),
        type: 'string',
        param: 'externalCode'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ]
  };

  async function Enrich(item) {
    if (
      item.energySourceType != null &&
      typeof item.energySourceType == 'object'
    ) {
      item.energySourceTypeName = item.energySourceType.name;
    } else {
      item.fetchingObject['energySourceTypeName'] = {
        fetching: false,
        success: false,
        nodata: true
      };
    }
  }

  return configuration;
}
export default RatedPowerConfiguration;
