/**
 * @ngdoc service
 * @name common.ShareTimeSeriesGroupService
 * @description Opens form dialog
 */

ShareTimeSeriesGroupService.$inject = [
  'InfoDialog',
  '$mdDialog',
  'gettext',
  'StandardUtils',
  'TranslationService'
];
export default function ShareTimeSeriesGroupService(
  InfoDialog,
  $mdDialog,
  gettext,
  StandardUtils,
  TranslationService
) {
  /**
   * @description opens share dialog with url and copies url to a clipboard.
   * @function
   * @param {String} url
   */
  function openShareDialog(url) {
    let items = [
      {
        text: url,
        type: 'text'
      }
    ];

    var action = [
      {
        title: gettext('Copy link to clipboard and close'),
        fn: function() {
          $mdDialog.cancel();

          let tempElement = document.createElement('input');
          document.body.appendChild(tempElement);
          tempElement.setAttribute('value', url);
          tempElement.select();
          document.execCommand('copy');
          document.body.removeChild(tempElement);
        },
        color: 'accent'
      }
    ];
    let header = gettext('Share time series group');

    InfoDialog.open(header, null, items, action);
  }
  /**
   * @description maps series model to array that looks like [{timeSeries<String>, chartType: <Number>, color: <String>}].
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function mapSeries(series) {
    return series.map(seriesItem => ({
      timeSeries: seriesItem._id,
      chartType: seriesItem.chartType,
      color: seriesItem.color
    }));
  }
  function getClassificationConfig(classificationId, formModel) {
    if (Array.isArray(formModel)) {
      let selectedClassification = formModel.find(
        item => item.scheduleClassification.id == classificationId
      );

      let futureS = null;
      if (selectedClassification != null) {
        if (
          selectedClassification.futureScheduleClassification != null &&
          typeof selectedClassification.futureScheduleClassification == 'object'
        ) {
          futureS = selectedClassification.futureScheduleClassification.id;
        } else if (
          typeof selectedClassification.futureScheduleClassification ==
            'number' &&
          !isNaN(selectedClassification.futureScheduleClassification)
        ) {
          futureS = selectedClassification.futureScheduleClassification;
        }
        let historyS = null;
        if (
          selectedClassification.historyScheduleClassification != null &&
          typeof selectedClassification.historyScheduleClassification ==
            'object'
        ) {
          historyS = selectedClassification.historyScheduleClassification.id;
        } else if (
          typeof selectedClassification.historyScheduleClassification ==
            'number' &&
          !isNaN(selectedClassification.historyScheduleClassification)
        ) {
          historyS = selectedClassification.historyScheduleClassification;
        }
        return {
          main:
            selectedClassification.defaultSpecifications &&
            selectedClassification.defaultSpecifications.isMain
              ? true
              : false,
          futureN: selectedClassification.futureNumberOfUnits,
          historyN: selectedClassification.historyNumberOfUnits,
          n: selectedClassification.numberOfValues,
          futureS,
          historyS
        };
      }
    }
    return {};
  }
  /**
   * @description creates share link out of current configuration.
   * @function
   * @return {String}
   */
  function createQueryStringOutOfModel(
    classificationsModel,
    previewTimeseries,
    FormModel,
    displaySettings = {}
  ) {
    //TRANSFORM SERIES AND CLASSIFICATION SETTINGS TO
    // {series: [timeSeries, chartType, color], ...settingsKeys}
    let query = Object.keys(classificationsModel).reduce((result, key) => {
      return {
        ...result,
        [key]: {
          series: mapSeries(classificationsModel[key].series),
          ...getClassificationConfig(key, FormModel)
        }
      };
    }, {});
    //PREVIEW ITEMS
    let previewItems = mapSeries(previewTimeseries);

    let previewQuery = serializeArray(previewItems);
    //TRANSFORM SERIES  {[classificationId]: {series: timeseriesQueryString, ...settings}}
    query = Object.keys(query).reduce((result, key) => {
      let serializedArray = serializeArray(query[key].series);

      return {
        ...result,
        [key]: {
          ...query[key],
          series: serializedArray
        }
      };
    }, {});
    //TRANSFORM TO
    // {[classificationId]: seriesAndSettingsQueryString}
    query = Object.keys(query).reduce((result, key) => {
      return {
        ...result,
        [key]: serialize(query[key])
      };
    }, {});

    //FINAL CLASSIFICATION QUERY STRING
    let queryString = Object.keys(query)
      .reduce((result, key) => {
        let res = `${encodeURIComponent('classification' + key)}=${query[key]}`;
        return [...result, res];
      }, [])
      .join('&');
    //ADD PREVIEW
    if (previewTimeseries.length > 0) {
      queryString = `${queryString}&preview=${previewQuery}`;
    }

    //ADD DISPLAY TYPE

    //USE SORT CHART TYPE KEY
    displaySettings = {
      ...displaySettings,
      sortChartType: displaySettings.chartType
    };

    delete displaySettings.chartType;

    let displaySettingsQuery = serialize(displaySettings);
    if (displaySettingsQuery != null && displaySettings != '') {
      queryString = `${queryString}&${displaySettingsQuery}`;
    }
    return queryString;
  }

  /**
   * @description creates string query params out oF array.
   * @function
   * @param {Array} array schedule classification array value
   * @return {Sting}
   */
  function serializeArray(array) {
    return array
      .reduce((result, item) => {
        return [...result, serialize(item)];
      }, [])
      .join(',');
  }
  /**
   * @description creates query string out of object.
   * @function
   * @param {Object} obj
   * @return {String}
   */
  function serialize(obj) {
    return Object.keys(obj)
      .reduce((result, key) => {
        if (key == 'series') {
          return [...result, encodeURIComponent(key) + '=' + obj[key]];
        } else if (obj[key] != null) {
          return [
            ...result,
            encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
          ];
        }
        return result;
      }, [])
      .join('&');
  }
  /**
   * @description returns settings (sortType, sortChartType). codelist id
   * @function
   * @param {Array} query sort configuration query
   * @param {Number} defaultValue default codelist id
   * @return {Number}
   */
  function getSettingsConfig(query, defaultValue) {
    if (query != null && query[0] != null) {
      let splitQuery = query[0].split('=');
      if (splitQuery.length == 2) {
        let codelistId = Number(splitQuery[1]);
        if (typeof codelistId == 'number' && !isNaN(codelistId)) {
          return codelistId;
        }
      }
    }
    return defaultValue;
  }

  /**
   * @description creates object out of url param string.
   * @function
   * @param {String} query query string
   * @return {Object}
   */
  function parseQueryIntoParams(query) {
    //GET DISPLAY TYPE

    let displayType = getSettingsConfig(query.match(/&displayType=\d+/), 3);
    let sortType = getSettingsConfig(query.match(/&sortType=\d+/), 1);
    let chartType = getSettingsConfig(query.match(/&sortChartType=\d+/), 10);

    //ASSUMING THAT DISPLAY TYPE IS THE FIRST IN QUERY OUT OF ALL SETTINGS
    let displayTypeSeparated = query.split(/&displayType=\d/);
    if (displayTypeSeparated.length == 2) {
      query = displayTypeSeparated[0];
    }

    //GET PREVIEW
    let previewSeparated = query.split('&preview=');

    let previewQuery;
    let previewItems = [];
    if (previewSeparated.length == 2) {
      previewQuery = previewSeparated[1];
      previewItems = queryStringToArray(previewQuery);
      query = previewSeparated[0];
    }

    let parsed = {};
    //GET CLASSIFICATIONS CONFIGS KEYS - CODELIST IDS
    let keys = query.match(/&{0,1}classification\d{1,2}=/g);
    if (Array.isArray(keys) && keys.length > 0) {
      let classificationStrings = query
        .split(/&{0,1}classification\d{1,2}=/g)
        .splice(1);
      parsed = keys.reduce(
        (result, item, index) => {
          let classificationId = item.match(/\d/);
          //SETTINGS (DEFAULT FILTER)
          //SERIES (id, color, chart type)
          let {
            classification,
            settingResults
          } = splitClassificationSettingsAndSeries(
            classificationStrings[index]
          );
          //REMOVE QUERY SERIES KEY
          let removedSeries = classification.split('series=');
          //CONSTRUCT CLASSIFICATION SETTINGS FROM STRING
          let settings = constructFormModel(
            settingResults,
            classificationId[0]
          );
          if (removedSeries.length == 2) {
            classification = removedSeries[1];
          }

          return {
            series: {
              ...result.series,
              [classificationId]: queryStringToArray(classification)
            },
            formModel: [...result.formModel, settings]
          };
        },
        { series: {}, formModel: [] }
      );
    }
    return {
      model: parsed.series || {},
      formModel: parsed.formModel,
      previewItems,
      displayType,
      sortType,
      chartType
    };
  }

  /**
   * @description constructs time series group classification from.
   * @function
   * @param {Object} settings configuration received from query
   * @param {Number} classificationId codelist classification id
   * @return {dataType}
   */
  function constructFormModel(settings, classificationId) {
    let scheduleClassification = TranslationService.GetCollectionById(
      'codelists.scheduleClassifications',
      Number(classificationId)
    );
    let defaultSpecifications = { isMain: false };
    if (settings.main == 'true') {
      defaultSpecifications = { isMain: true };
    }

    let historyNumberOfUnits = null;
    let historyScheduleClassification = null;
    let historyTimeRange = { isChecked: false };
    let futureNumberOfUnits = null;
    let futureScheduleClassification = null;
    let futureTimeRange = { isChecked: false };
    let numberOfValues = 100;
    let filterType = 200;
    if (
      (settings.futureN != null && settings.futureS != null) ||
      (settings.historyN != null && settings.historyS != null)
    ) {
      filterType = 100;
      historyNumberOfUnits = Number(settings.historyN);
      historyScheduleClassification = TranslationService.GetCollectionById(
        'codelists.scheduleClassifications',
        Number(settings.historyS)
      );
      if (
        historyNumberOfUnits != null &&
        historyScheduleClassification != null
      ) {
        historyTimeRange = { isChecked: true };
      }

      futureNumberOfUnits = Number(settings.futureN);
      futureScheduleClassification = TranslationService.GetCollectionById(
        'codelists.scheduleClassifications',
        Number(settings.futureS)
      );
      if (futureNumberOfUnits != null && futureScheduleClassification != null) {
        futureTimeRange = { isChecked: true };
      }
    } else if (settings.n != null) {
      numberOfValues = Number(settings.n);
    }

    return {
      historyNumberOfUnits,
      historyScheduleClassification,
      historyTimeRange,
      futureNumberOfUnits,
      futureScheduleClassification,
      futureTimeRange,
      numberOfValues,
      filterType,
      scheduleClassification,
      defaultSpecifications
    };
  }
  /**
   * @description gets classification settings values.
   * @function
   * @param {String} classification classification query string
   * @return {Object} {classification<string>, settings<Object>}
   */
  function splitClassificationSettingsAndSeries(classification) {
    //ORDER IS IMPORTANT SHOULD BE THE REVERSE AS IN getClassificationConfig function
    let settingsKeys = [
      'main',
      'futureN',
      'historyN',
      'n',
      'futureS',
      'historyS'
    ].reverse();

    let settingResults = settingsKeys.reduce((result, key) => {
      let splitKey = `&${key}=`;
      let partClassification = classification.split(splitKey);
      if (partClassification.length == 2) {
        classification = partClassification[0];
        result = {
          ...result,
          [key]: partClassification[1]
        };
      }
      return result;
    }, {});

    return {
      classification,
      settingResults
    };
  }

  /**
   * @description creates array of values out of string.
   * @function
   * @param {String} queryString
   * @return {Array}
   */
  function queryStringToArray(queryString) {
    let array = queryString.split(',');
    return array.map(item => {
      return StandardUtils.queryStringToJSON(item);
    });
  }

  /**
   * @description adds new schedule classifications to sandbox model.
   * @function
   * @param {Object} scheduleClassification selected schedule classification object
   * @param {Object} timeseries indicates what timeseries should be moved to new classification {[timeseriesId]: Bool}
   * @param {Object} classificationsModel current classification model
   * @param {Array} previewTimeseries Array of preview timeseries. Used to match timeseries ids with timeseries objects
   * @return {Object} classificationsModel
   */
  function addConfigurationToModel(
    scheduleClassification,
    timeseries,
    classificationsModel,
    previewTimeseries
  ) {
    let series = [];

    if (typeof timeseries == 'object' && timeseries != null) {
      series = Object.keys(timeseries).reduce((result, timeseriesId) => {
        if (timeseries[timeseriesId]) {
          result = [
            ...result,
            previewTimeseries.find(item => item._id == timeseriesId)
          ];
        }
        return result;
      }, []);
    }
    return {
      ...classificationsModel,
      [scheduleClassification.id]: {
        scheduleClassification,
        series
      }
    };
  }

  return {
    createQueryStringOutOfModel,
    openShareDialog,
    parseQueryIntoParams,
    addConfigurationToModel
  };
}
