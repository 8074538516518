import template from './sfe-math-expression.directive.html';
/**
 * @ngdoc directive
 * @name common.sfeMathExpression
 * @description Directive for creating and showing mathematical expressions
 * @param {string} rule - the text being shown in expression preview
 * @param {boolean} upsertMode - tells us if the component is in upsert mode, which shows us inputs for adding/changing data.
 * @param {Array} expressions - Array of ob objects, where each object is one part of the expression
 * @param {Array} errors -  A list of possible errors that might be shown in the directive.
 * @param {number} uniqueId - a unique id for an expression
 * @param {boolean} hidePreviewText - used for hiding title when upsertMode is false
 * @example
 * <sfe-math-expression
 *  rule="vm.expressionRule"
 *  upsertMode="false"
 *  expressions="vm.expressions"
 *  errors="vm.listOfErrors"
 *  uniqueId="$index"
 * ></sfe-math-expression>
 */
export default [
  '$timeout',
  'gettext',
  'gettextCatalog',
  function($timeout, gettext, gettextCatalog) {
    return {
      restrict: 'E',
      template,
      scope: {
        rule: '=',
        upsertMode: '=',
        expressions: '=',
        errors: '=',
        uniqueId: '=',
        hidePreviewText: '<'
      },
      link: function link(scope) {
        var QUEUE = MathJax.Hub.queue; // shorthand for the queue
        var splits = [];
        var elements = [];
        var orIndex = [];
        var andIndex = [];
        var expr;
        var last = 0;
        var logicalIndexMap = [];
        scope.expressionHelper = gettextCatalog.getString(
          'When expression below will evaluate to true, this rule will trigger notices and / or alarms.'
        );
        var watcher = scope.$watch('rule', function(val) {
          if (typeof val !== 'undefined') {
            if (scope.upsertMode) {
              scope.previewExpression = scope.rule;
              if (MathJax.Hub.getAllJax()[0]) {
                splitExpression();
              } else {
                $timeout(function() {
                  splitExpression();
                }, 100);
              }
            } else {
              constructViewMode();
            }

            watcher();
          }
        });
        scope.$on('$destroy', function() {
          if (watcher) {
            watcher();
          }
        });
        scope.expressions;
        scope.previewExpressionIsShown = previewExpressionIsShown;
        scope.addExpression = addExpression;
        scope.expressionConstructor = expressionConstructor;
        scope.removeExpression = removeExpression;

        init();
        /**
         * @description directive initialization function
         * @function
         */
        function init() {
          scope.logicalOperators = [
            {
              name: 'AND',
              value: '&&'
            },
            {
              name: 'OR',
              value: '||'
            }
          ];

          scope.arithmeticOperators = [
            {
              name: gettext('EQUALS'),
              value: '=='
            },
            {
              name: gettext('does not EQUAL'),
              value: '!='
            },
            {
              name: gettext('is LESS than'),
              value: '<'
            },
            {
              name: gettext('is LESS than OR equals'),
              value: '<='
            },
            {
              name: gettext('is MORE than'),
              value: '>'
            },
            {
              name: gettext('is MORE than OR equals'),
              value: '>='
            }
          ];

          scope.allowedFunctions = [
            'sqrt',
            'min',
            'max',
            'abs',
            'floor',
            'ceil',
            'sin',
            'cos',
            'tan',
            'rad',
            'deg',
            'add',
            'subtract',
            'multiply',
            'divide',
            'pow'
          ];

          scope.allowedOperators = ['+', '-', '*', '/', '^', '%'];
        }
        $timeout(function() {
          MathJax.Hub.Queue([
            'Typeset',
            MathJax.Hub,
            'previewExpression' + scope.uniqueId
          ]);
          var jaxElement = MathJax.Hub.getAllJax(
            'previewExpression' + scope.uniqueId
          )[0];
          if (jaxElement) {
            // override original text function
            //  To avoid math jax error failure Do not call original Text function wheen script tag doesn't exist
            var originalTextFunction = jaxElement.Text;
            jaxElement.Text = function(text, callback) {
              if (this) {
                var script = this.SourceElement();
                if (script) {
                  originalTextFunction.apply(this, [text, callback]);
                }
              }
            };
          }
        });
        /**
         * @description generates a human readable rule
         * @function
         */
        function constructViewMode() {
          var expressionString = '';
          scope.rule = scope.rule.replace(/text{ }/g, '');
          var temp = scope.rule.split('&&');
          _.each(temp, function(element, index) {
            if (index != 0) {
              expressionString += 'text{ }&&text{ }' + element;
            } else {
              expressionString += element;
            }
          });

          temp = expressionString.split('||');

          expressionString = '';

          _.each(temp, function(element, index) {
            if (index != 0) {
              expressionString += 'text{ }||text{ }' + element;
            } else {
              expressionString += element;
            }
          });

          scope.rule = expressionString;
          // scope.previewExpression = expressionString;
          $timeout(function() {
            MathJax.Hub.Queue([
              'Typeset',
              MathJax.Hub,
              'previewExpression' + scope.uniqueId
            ]);
          }, 0);
          if (typeof scope.rule != 'undefined') {
            scope.show = true;
          }
        }
        /**
         * @description splits the rule string into an array of objects, where one object is one rule seperated by either && or ||
         * @function
         */
        function splitExpression() {
          // expression
          var str = scope.rule;

          var orSplit = str.split('||');
          for (var i = 0; i < orSplit.length; i++) {
            var andSplit = orSplit[i].split('&&');
            if (andSplit.length > 1) {
              for (var ind = 0; ind < andSplit.length - 1; ind++) {
                splits.push(andSplit[ind]);
                andIndex.push(last);
                logicalIndexMap.push('&&');
                last++;
              }
              orIndex.push(last);
              logicalIndexMap.push('||');
              last++;
            } else if (i < orSplit.length - 1) {
              orIndex.push(last);
              logicalIndexMap.push('||');
              last++;
            }
            splits.push(andSplit[andSplit.length - 1]);
          }

          _.each(splits, function(element, i) {
            var node = math.parse(element);
            expr = {
              arithmeticOperator: '',
              logicalOperator: '',
              expressions: ['', '']
            };
            if (i < splits.length - 1) {
              expr.logicalOperator = logicalIndexMap[i];
            }
            parseExpression(node);
            elements.push(expr);
          });
          scope.expressions = elements;
          expressionConstructor();
          setTimeout(function() {
            MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
          }, 0);
        }
        /**
         * @description checks if the nodes has a valid comparison operator.
         * @function
         * @param {object} node - object containing data about the comparison operator
         * @return {boolean} true if operator is valid, false if it is not
         */
        function validateArithmetic(node) {
          if (
            node.op === '>=' ||
            node.op === '>' ||
            node.op === '<' ||
            node.op === '<=' ||
            node.op === '==' ||
            node.op === '!='
          ) {
            return true;
          } else {
            return false;
          }
        }
        /**
        * @description Function to parses expression into array of objects
        * @function
        * @param {Object} node - Object that a single part of a math.js node
        * @param {Object} parent - comparison operator object
        * @param {string} path - location of the value in node
        * @param {number} expIndex  - tells us how deep in the expression tree the function is.

        */
        //
        function parseExpression(node, parent, path, expIndex) {
          if (typeof expIndex === 'undefined') {
            expIndex = 0;
          }
          var fn = false;
          if (typeof parent !== 'undefined' && parent.type === 'FunctionNode') {
            if (path === 'args[1]') {
              expr.expressions[expIndex] += ', ';
            }
          }
          if (typeof parent !== 'undefined' && parent.fn === 'unaryMinus') {
            expr.expressions[expIndex] += '-';
          }
          if (node.content) {
            expr.expressions[expIndex] += ' (';
          }
          switch (node.type) {
          case 'OperatorNode':
            if (validateArithmetic(node)) {
              expr.arithmeticOperator += node.op;
            }
            break;
          case 'ConstantNode':
            expr.expressions[expIndex] += node.value;
            break;
          case 'SymbolNode':
            //SYMBOL CANT BE NAME OF A FUNCTION
            if (scope.allowedFunctions.indexOf(node.name) < 0) {
              expr.expressions[expIndex] += node.name;
            }
            break;
          case 'FunctionNode':
            expr.expressions[expIndex] += node.fn + '(';
            fn = true;
            break;
          }
          node.forEach(function(n, p, parent) {
            if (validateArithmetic(parent)) {
              if (p === 'args[0]') {
                expIndex = 0;
              } else {
                expIndex = 1;
              }
            }
            parseExpression(n, parent, p, expIndex);
          });
          if (fn) {
            expr.expressions[expIndex] += ')';
            fn = false;
          }
          if (node.content) {
            expr.expressions[expIndex] += ') ';
          }
          if (
            typeof parent !== 'undefined' &&
            parent.implicit === false &&
            path === 'args[0]' &&
            !validateArithmetic(parent) &&
            parent.fn !== 'unaryMinus'
          ) {
            expr.expressions[expIndex] += parent.op;
          }
        }

        /**
         * @description Expression cosntructor for MATHJAX
         * @function
         */
        function expressionConstructor() {
          var result = '';
          var expressionsLenght = scope.expressions.length;

          for (var index in scope.expressions) {
            var expressionObj = scope.expressions[index];
            if (expressionObj && expressionObj.logicalOperator == '') {
              expressionObj.logicalOperator = '&&';
            }
            // VALIDATE LEFT SIDE
            if (
              typeof expressionObj.expressions[0] !== 'undefined' &&
              expressionObj.expressions[0].length > 0
            ) {
              validateExpression(
                expressionObj.expressions[0],
                parseInt(index),
                0,
                scope.errors
              );
            }

            // VALIDATE RIGHT SIDE
            if (
              typeof expressionObj.expressions[1] !== 'undefined' &&
              expressionObj.expressions[1].length > 0
            ) {
              validateExpression(
                expressionObj.expressions[1],
                parseInt(index),
                1,
                scope.errors
              );
            }

            result +=
              expressionObj.expressions[0] +
              expressionObj.arithmeticOperator +
              expressionObj.expressions[1];

            if (index != expressionsLenght - 1) {
              result +=
                ' text{ } ' + expressionObj.logicalOperator + ' text{ }';
            }
          }
          scope.previewExpression = result;

          $timeout(function() {
            QUEUE.Push([
              'Text',
              MathJax.Hub.getAllJax()[0],
              scope.previewExpression
            ]);
          }, 200);
        }
        /**
         * @description function that validates the expression
         * @function
         * @param {string} expression - expression being validated
         * @param {number} indexInExpression - index where the error occurred inside the expression
         * @param {number} indexOfExpression - index where the expression is.
         */
        function validateExpression(
          expression,
          indexInExpression,
          indexOfExpression
        ) {
          if (typeof expression === 'undefined') {
            expression = '';
          }

          var mathObj = null;
          var initialError = false;

          // var errors = JSON.parse(JSON.stringify(scope.errors));
          if (scope.errors) {
            scope.errors = scope.errors.filter(function(el) {
              return !(
                el.indexInExpression === indexInExpression &&
                el.indexOfExpression === indexOfExpression
              );
            });
          }

          try {
            mathObj = math.parse(expression);
          } catch (err) {
            scope.errors.push({
              error: gettextCatalog.getString(
                'Unexpected end of expression (character at position {{char}}).',
                {
                  char: err.char
                }
              ),
              position: 'Main expression',
              indexInExpression: indexInExpression,
              indexOfExpression: indexOfExpression
            });
            initialError = true;
          }

          if (!initialError) {
            validateMathParseObject(
              mathObj,
              ['Main expression'],
              indexInExpression,
              indexOfExpression,
              scope.errors
            );
          }
        }
        /**
         * @description validates math parse object
         * @function
         * @param {Object} mathObj - a mathJs node.
         * @param {Array} motherFunctionArray - array that contains current position of the parseObject.
         * @param {number} indexInExpression - index where the current MathObj is.
         * @param {number} indexOfExpression - index where the currentMathObj is.
         * @param {Object} errorObject
         */
        function validateMathParseObject(
          mathObj,
          motherFunctionArray,
          indexInExpression,
          indexOfExpression,
          errorObject
        ) {
          var positionString = '';
          var isMotherObject = motherFunctionArray.length === 1;

          for (var index in motherFunctionArray) {
            if (index !== 0) {
              positionString += ' in function ';
            } else if (index === motherFunctionArray.length - 1) {
              positionString += ' in ';
            }
            positionString +=
              motherFunctionArray[motherFunctionArray.length - 1 - index];
          }
          positionString += '.';

          switch (mathObj.type) {
          case 'AssignmentNode':
            errorObject.push({
              error: gettextCatalog.getString('Assignment is not allowed.'),
              position: positionString,
              indexInExpression: indexInExpression,
              indexOfExpression: indexOfExpression
            });
            break;

            // PARENTHESIS
          case 'ParenthesisNode':
            var motherFunctionArrayNext = motherFunctionArray.concat([
              'Parenthesis'
            ]);
            var obj = mathObj.content;
            validateMathParseObject(
              obj,
              motherFunctionArrayNext,
              indexInExpression,
              indexOfExpression,
              errorObject
            );
            break;

            // FUNCTIONS
          case 'FunctionNode':
            var functionName = mathObj.fn.name;
            if (scope.allowedFunctions.indexOf(functionName) == -1) {
              errorObject.push({
                error: gettextCatalog.getString(
                  '{{functionName}} is not a valid function.',
                  {
                    functionName
                  }
                ),
                position: positionString,
                indexInExpression: indexInExpression,
                indexOfExpression: indexOfExpression
              });
            }

            motherFunctionArrayNext = motherFunctionArray.concat([
              functionName
            ]);
            for (var index2 in mathObj.args) {
              var obj2 = mathObj.args[index2];
              validateMathParseObject(
                obj2,
                motherFunctionArrayNext,
                indexInExpression,
                indexOfExpression,
                errorObject
              );
            }

            break;

            // OPERATORS
          case 'OperatorNode':
            functionName = mathObj.fn;
            var implicit = mathObj.implicit;
            var operator = mathObj.op;
            if (implicit) {
              errorObject.push({
                error: gettextCatalog.getString(
                  'Implicit operation {{functionName}} is not allowed.',
                  {
                    functionName
                  }
                ),
                position: positionString,
                indexInExpression: indexInExpression,
                indexOfExpression: indexOfExpression
              });
            }

            if (scope.allowedOperators.indexOf(operator) == -1) {
              errorObject.push({
                error: gettextCatalog.getString(
                  'Operation {{functionName}} is not allowed.',
                  {
                    functionName
                  }
                ),
                position: positionString,
                indexInExpression: indexInExpression,
                indexOfExpression: indexOfExpression
              });
            }

            motherFunctionArrayNext = motherFunctionArray.concat([
              'operator ' + functionName
            ]);
            for (var index3 in mathObj.args) {
              var obj3 = mathObj.args[index3];
              validateMathParseObject(
                obj3,
                motherFunctionArrayNext,
                indexInExpression,
                indexOfExpression,
                errorObject
              );
            }

            break;

            // NUMBERS AND STRINGS
          case 'ConstantNode':
            var value = mathObj.value;
            var valueType = mathObj.valueType;
            if (valueType == 'string') {
              errorObject.push({
                error: gettextCatalog.getString(
                  'Strings are not allowed in expressions. (Regarding {{value}})',
                  {
                    value
                  }
                ),
                position: positionString,
                indexInExpression: indexInExpression,
                indexOfExpression: indexOfExpression
              });
            }
            break;

            // VARIABLES
          case 'SymbolNode':
            var name = mathObj.name;
            if (name !== 'd') {
              errorObject.push({
                error: gettextCatalog.getString(
                  'Variable {{name}} is not defined.',
                  {
                    name
                  }
                ),
                position: positionString,
                indexInExpression: indexInExpression,
                indexOfExpression: indexOfExpression
              });
            }

            break;
          }

          if (isMotherObject) {
            _.sortBy(errorObject, function(o) {
              return o.indexOfExpression;
            });
          }
        }
        /**
         * @description function that refreshes the expression and revalidates it (if it is valid)
         * @function
         */
        function refreshExpressionAndValidation() {
          expressionConstructor();

          setTimeout(function() {
            MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
          }, 0);
        }
        /**
         * @description creates a new arithmetic expression.
         * @function
         */
        function addExpression() {
          var expression = {
            arithmeticOperator: scope.arithmeticOperators[0].value,
            logicalOperator: scope.logicalOperators[0].value,
            expressions: ['', '']
          };
          scope.expressions.push(expression);
          refreshExpressionAndValidation();
        }

        // IF THERE IS NO OR INCOMPLETE EXPRESSION, DONT SHOW IT YET
        /**
         * @description checks the expression can be shown and if it can be, return true.
         * @function
         * @return {boolean}
         */
        function previewExpressionIsShown() {
          var expressions = scope.expressions;
          var expressionsCounter = 0;
          if (expressions) {
            for (var x = 0; x < expressions.length; x++) {
              if (
                typeof expressions[x].expressions[0] == 'string' &&
                expressions[x].expressions[0] != '' &&
                typeof expressions[x].expressions[1] == 'string' &&
                expressions[x].expressions[1] != ''
              ) {
                expressionsCounter++;
              }
            }
            return expressionsCounter == expressions.length;
          }
        }
        /**
         * @description removes expression.
         * @function
         * @param {number} index - index of the expression we are removing
         */
        function removeExpression(index) {
          if (scope.expressions.length > 1) {
            scope.expressions.splice(index, 1);
          }
          refreshExpressionAndValidation();
        }
      }
    };
  }
];
