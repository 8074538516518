module.exports = {
  entity: 'datapoints',
  path: 'datapoints',
  name: {
    en: 'Data points',
    sl_SI: 'Podatkovne Točke'
  },
  networkModel: {
    entity: 'datapoints',
    read: 'read'
  },
  scope: 'tis_be:datapoints',

  dialog: {
    title: {
      en: 'Select data point',
      sl_SI: 'Izberite podatkovno točko'
    },
    listInfo: {
      en: 'Select data point from the list below',
      sl_SI: 'Izberite podatkovno točko s spodnjega seznama'
    }
  }
}
