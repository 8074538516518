module.exports = {
  entity: 'sops',
  path: 'sops',
  name: {
    en: 'sop',
    sl_SI: 'sop'
  },
  networkModel: {
    entity: 'sops',
    read: 'read'
  },
  scope: 'tis_be:sops',

  dialog: {
    title: {
      en: 'Select SOP',
      sl_SI: 'Izberite SOP'
    },
    listInfo: {
      en: 'Select SOP from the list below',
      sl_SI: 'Izberite SOP s spodnjega seznama'
    }
  }
}
