module.exports = {
  entity: 'files',
  path: 'files',
  name: {
    en: 'Files',
    sl_SI: 'datoteke'
  },
  networkModel: {
    entity: 'files',
    read: 'read'
  },
  scope: 'tis_be:files',

  dialog: {
    title: {
      en: 'Select file',
      sl_SI: 'Izberite datoteko'
    },
    listInfo: {
      en: 'Select file from the list below',
      sl_SI: 'Izberite datoteko s spodnjega seznama'
    }
  }
}
