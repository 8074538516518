import { Sortable, MultiDrag } from 'sortablejs';

Sortable.mount(new MultiDrag());
const getSource = 'get-source';

sfeSortable.$inject = ['$parse'];
export default function sfeSortable($parse) {
  return {
    restrict: 'A',
    compile: function(element) {
      function getNgRepeatExpression(node) {
        if (node != null) {
          return node.getAttribute('ng-repeat');
        }
      }
      //GET NG-REPEAT DOM ELEMENT
      const ngRepeat = [].filter.call(element[0].childNodes, function(node) {
        return (
          node.nodeType === Node.ELEMENT_NODE && getNgRepeatExpression(node)
        );
      })[0];
      let ngRepeatAttribute = getNgRepeatExpression(ngRepeat);
      //MATCHES ng-repeat FROM DOM ELEMENT
      let match;
      if (ngRepeatAttribute) {
        match = ngRepeatAttribute.match(
          /^\s*([\s\S]+?)\s+in\s+([\s\S]+?)(?:\s+as\s+([\s\S]+?))?(?:\s+track\s+by\s+([\s\S]+?))?\s*$/
        );
      }

      if (!match) {
        // return;
      }

      const repeatValue = match != null ? match[2] : '';

      return {
        post: function(scope, element, attrs) {
          let forwardDropFn;
          //SET NO DUPLICATES PARAMETER
          let duplicateValue = false;
          if (attrs.noDuplicates == 'true') {
            duplicateValue = attrs.duplicateValue;
          }
          const el = element[0];
          const getModel = $parse(repeatValue);
          let sortable;

          /**
           * @description returns ng-repeat  model array value.
           * @function
           * @return {Array}
           */
          function getSourceFn() {
            return getModel(scope) || [];
          }

          //PASSED CONFIGURATION
          let getConfig = $parse(attrs.sfeSortable);

          const stopWatch = scope.$watch(
            () => {
              let config = getConfig(scope);
              return config;
            },
            config => {
              if (config != null) {
                initList(config);
                stopWatch();
              }
            }
          );
          /**
           * @description init configuration.
           * @function
           * @param {Objet} config sortable configuration
           * https://github.com/SortableJS/Sortable
           */
          function initList(config) {
            //forward dropdown function is called on item add and passes moved item as arg
            forwardDropFn = config.forwardDropFn;
            sortable = Sortable.create(el, {
              ...config,
              onAdd,
              setData,
              onStart,
              onEnd,
              onUpdate,
              onRemove,
              onSort
            });
            //ADD GET SOURCE MODEL FUNCTION TO AN ELEMENT
            el[getSource] = getSourceFn;
          }

          //BEFORE EVENTS ARE TRIGGERED ADD MODEL AND MOVED ITEM TO THEM
          function onAdd(evt) {
            let movedItem = getItems(evt);
            triggerOriginalEvent(evt, movedItem);
            scope.$apply();
          }

          function onStart(evt) {
            triggerOriginalEvent(evt);
            scope.$apply();
          }

          function onEnd(evt) {
            let items = evt.to[getSource]();
            triggerOriginalEvent(evt, items[evt.newIndex]);
            scope.$apply();
          }

          function onUpdate(evt) {
            let updatedItem = getItems(evt);
            triggerOriginalEvent(evt, updatedItem);
          }

          function onRemove(evt) {
            let items = evt.to[getSource]();
            triggerOriginalEvent(evt, items[evt.newIndex]);
          }
          function onSort(evt) {
            let items = evt.to[getSource]();
            triggerOriginalEvent(evt, items[evt.newIndex]);
          }

          function setData(
            /** DataTransfer */ dataTransfer,
            /** HTMLElement*/ dragEl
          ) {
            dataTransfer.setData('Text', dragEl.textContent); // `dataTransfer` object of HTML5 DragEvent
          }
          /**
           * @description triggers original event and adds moved item and model to it.
           * @function
           * @param {Object} evt original evt
           * @param {Object} item !Optional moved item
           */
          function triggerOriginalEvent(evt, item) {
            const name =
              'on' + evt.type.charAt(0).toUpperCase() + evt.type.substr(1);

            var source = getSourceFn();

            let config = getConfig(scope);

            /* jshint expr:true */
            config[name] &&
              config[name]({
                model: item || source[evt.newIndex],
                models: source,
                oldIndex: evt.oldIndex,
                newIndex: evt.newIndex,
                originalEvent: evt
              });
          }
          /**
           * @description find moved item in the original model by indexes of moved items and moves/clones item to dest model.
           * @function
           * @param {Object} evt moved event
           * @return {Object} returns moved item
           */
          function getItems(evt) {
            let items = getSourceFn();

            let oldIndex = evt.oldIndex;
            let newIndex = evt.newIndex;
            let nextSibling;
            if (evt.item != null && evt.from === evt.item.parentNode) {
              nextSibling = evt.item.nextSibling;
            } else if (evt.clone != null) {
              nextSibling = evt.clone.nextSibling;
            }

            let movedItem;

            if (el !== evt.from) {
              var prevItems = evt.from[getSource]();

              movedItem = angular.copy(prevItems[oldIndex]);
              //IF DUPLICATES ARE NOT ALLOWED
              if (duplicateValue) {
                //CHECK IF ITEM IS ALREADY IN THE LIST
                let foundValue = items.find(
                  item => item[duplicateValue] == movedItem[duplicateValue]
                );
                if (foundValue != null) {
                  //REMOVE THE ITEM
                  if (evt.to.contains(evt.item)) {
                    evt.to.removeChild(evt.item);
                  }
                  return null;
                }
              }
              if (typeof forwardDropFn == 'function') {
                forwardDropFn(movedItem);
              }
              if (evt.pullMode == 'clone') {
                let index = Sortable.utils.index(
                  evt.clone,
                  sortable.options.draggable
                );
                let movedClone = prevItems.splice(oldIndex, 1)[0];
                if (evt.from.contains(evt.clone)) {
                  evt.from.removeChild(evt.clone);
                }
                prevItems.splice(index, 0, movedClone);
              } else {
                prevItems.splice(oldIndex, 1);
              }

              items.splice(newIndex, 0, movedItem);

              evt.from.insertBefore(evt.item, nextSibling); // revert element
            } else {
              items.splice(newIndex, 0, items.splice(oldIndex, 1)[0]);

              if (nextSibling && nextSibling.nodeType === Node.COMMENT_NODE) {
                evt.from.insertBefore(nextSibling, evt.item.nextSibling);
              }
            }
            scope.$apply();
            return movedItem;
          }
        }
      };
    }
  };
}
