import './header.scss';
import SettingsDialog from '../settings/settings.dialog.html';
import pck from '../../../../package.json';

HeaderController.$inject = [
  '$scope',
  '$state',
  '$smartAuth',
  '$mdDialog',
  '$rootScope',
  'LocalizationService',
  'AuthorizationShowScopeModel',
  '$location',
  'StandardUtils',
  'AlertingService',
  'CachingControl',
  '$transitions',
  '$timeout',
  'MetadataService'
];

function HeaderController(
  $scope,
  $state,
  $smartAuth,
  $mdDialog,
  $rootScope,
  LocalizationService,
  AuthorizationShowScopeModel,
  $location,
  StandardUtils,
  AlertingService,
  CachingControl,
  $transitions,
  $timeout,
  MetadataService
) {
  var SettingsIdentifier = 'tis_fe:settings';
  // 0 = fulscreen, 1 = compact side menu, 2 = extended side menu
  var sideMenuMode = 0;
  // save previous setting
  var previousSideMenuMode = 0;
  $scope.languages = LocalizationService.languages;
  $scope.selectedLang = LocalizationService.GetSelectedLang;
  $scope.changeLang = LocalizationService.ChangeLang;
  $scope.openNavigationMenu = openNavigationMenu;

  MetadataService.register(
    () => {
      $scope.isResolvingRoute = true;
    },
    () => {
      $scope.isResolvingRoute = false;
    }
  );

  const cacheWatcher = $scope.$watch(
    () => {
      return CachingControl.GlobalCacheOn();
    },
    newVal => {
      if (typeof newVal !== 'undefined') {
        $scope.isCached = newVal;
        cacheWatcher();
      }
    }
  );

  $scope.toggleCache = function() {
    CachingControl.ToggleCache();
    $scope.isCached = CachingControl.GlobalCacheOn();
  };

  $scope.refreshCache = function() {
    CachingControl.RefreshCache(true);
  };

  init();

  function init() {
    getUserProfile();
    if ($location.search().fullscreen === 'true') {
      sideMenuMode = 0;
    } else {
      sideMenuMode = 2;
      previousSideMenuMode = 2;
    }
    sideMenuToggle();
  }

  function getUserProfile() {
    $smartAuth.profile().then(
      function(userProfile) {
        userProfile.imageUrl = StandardUtils.getUserImageUrl(userProfile, 'sm');
        $scope.loggedinUser = userProfile;
        getTisFeScopes();
      },
      function() {}
    );
  }

  $scope.loggedIn = function() {
    return $smartAuth.loggedIn();
  };

  function getTisFeScopes() {
    AuthorizationShowScopeModel.read().then(
      res => {
        $scope.settingsAuthorization = res.data[SettingsIdentifier];
      },
      err => {
        AlertingService.Error(err);
      }
    );
  }

  $rootScope.$on('userChanged', function(ev, newUserInfo) {
    newUserInfo.imageUrl = StandardUtils.getUserImageUrl(newUserInfo, 'sm');
    $scope.loggedinUser = newUserInfo;
  });

  $transitions.onSuccess({}, () => {
    var locationSearch = $location.search().fullscreen;
    if (locationSearch === 'true') {
      previousSideMenuMode = sideMenuMode;
      sideMenuMode = 0;
      sideMenuToggle();
    } else if (locationSearch === 'false') {
      sideMenuMode = previousSideMenuMode;
      sideMenuToggle();
    }
  });
  $scope.toggleMenu = function() {
    sideMenuMode = sideMenuMode === 1 ? 2 : 1;
    previousSideMenuMode = sideMenuMode;
    sideMenuToggle();
  };

  $scope.login = function() {
    $smartAuth.login(window.realm).then(function() {
      $state.go('home');
    });
  };

  $scope.logout = function() {
    $smartAuth.logout();

    $state.go('unauthenticated', {
      logout: true
    });
  };

  $scope.menuIsToggling = false;

  function sideMenuToggle() {
    $scope.menuIsToggling = true;
    if (sideMenuMode === 0) {
      // fullscreen mode
      angular.element('body').removeClass('sidenav-compact');
      angular.element('body').removeClass('sidenav-open');
      angular
        .element(document.querySelector('.sidenav-left'))
        .addClass('display-none');
    } else if (sideMenuMode === 1) {
      // compact mode
      angular.element('body').removeClass('sidenav-open');
      angular.element('body').addClass('sidenav-compact');
      angular
        .element(document.querySelector('.sidenav-left'))
        .removeClass('display-none');
    } else if (sideMenuMode === 2) {
      // extended mode
      angular.element('body').removeClass('sidenav-compact');
      angular.element('body').addClass('sidenav-open');
      angular
        .element(document.querySelector('.sidenav-left'))
        .removeClass('display-none');
    }

    $timeout(() => {
      window.dispatchEvent(new Event('resize'));

      $rootScope.$broadcast('sidenav', sideMenuMode);
      $scope.menuIsToggling = false;
    }, 180); //0.18s lasts side menu close/open transition
  }

  function openNavigationMenu() {
    $rootScope.$broadcast('openNavigationDialog');
  }

  var splitVersion = pck.version.split('.');
  $scope.version =
    splitVersion[0] + '.' + splitVersion[1] + '.' + splitVersion[2];
  angular.element('body').addClass('sidenav-open');

  $scope.openSettingsDialog = function() {
    $mdDialog.show({
      clickOutsideToClose: true,
      template: SettingsDialog,
      controller: 'SettingsController'
    });
  };
}

export default HeaderController;
