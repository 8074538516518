module.exports = {
  entity: 'analytical-datapoints',
  path: 'analytical-datapoints',
  name: {
    en: 'Analytical data point',
    sl_SI: 'Analitična podatkovna točka'
  },
  networkModel: {
    entity: 'analytical-datapoints',
    read: 'read'
  },
  scope: 'tis_be:analytical-datapoints',

  dialog: {
    title: {
      en: 'Select Analytical data point',
      sl_SI: 'Izberite analitično podatkovno točko'
    },
    listInfo: {
      en: 'Select analytical data point from the list below',
      sl_SI: 'Izberite analitično podatkovno točko s spodnjega seznama'
    }
  }
}
