/**
 * @ngdoc service
 * @name common.ValidateObjectConfiguration
 * @description Helps to validate object configuration
 * @property {Function} validate
 */

ValidateObjectConfiguration.$inject = [];

export default function ValidateObjectConfiguration() {
  /**
   * @description validates object field type and requiring according to object configuration .
   * @function
   * @param {Object} item object to validate
   * @param {Array} configurations array ob objects that describe every item field configuration
   * @return {object} returns object {validity, configuration}, validity indicates if object is valid according to configuration
   * configuration is array that describes every object key validity state {fieldName, validity, warning, error}
   * warning {Object}
   * warning.noValue: Boolean
   * error {Object}
   * error.required {Boolean}
   * error.invalid {Boolean}
   *
   * Configuration Example
   * [{
   *   fieldName,
   *   required, (Boolean)
   *   type, (String) [string, number, object, array, function, boolean, date]
   *   configuration (Array) Is used to describe fields of the nested object
   * }]
   */
  const validate = (item, configurations) => {
    if (
      Array.isArray(configurations) &&
      item != null &&
      typeof item == 'object'
    ) {
      //   general object validity status
      // if at least one field is not valid should be false
      let validityStatus = true;
      const configurationValidity = configurations.map(
        ({ fieldName, type, required, configuration }) => {
          type = type != null ? type.toLowerCase() : type;
          let validationObject = {
            validity: true
          };
          //default error and warning
          let warning = {
            noValue: false
          };

          let error = {
            required: false,
            invalid: false
          };
          if (item[fieldName] == null) {
            warning = {
              ...warning,
              noValue: true
            };
            if (required) {
              validityStatus = false;
              validationObject = {
                validity: false
              };
              error = {
                ...error,
                required: true
              };
            }
          } else {
            switch (type) {
            case 'array':
              if (!Array.isArray(item[fieldName])) {
                validityStatus = false;
                error = {
                  ...error,
                  invalid: true
                };
                validationObject = {
                  ...validationObject,
                  validity: false
                };
              }
              break;
            case 'object':
              if (typeof item[fieldName] == 'object') {
                if (configuration) {
                  let {
                    validity,
                    configuration: nestedConfiguration
                  } = validate(item[fieldName], configuration);
                  validationObject = {
                    ...validationObject,
                    configuration: nestedConfiguration
                  };
                  validityStatus =
                      validity === false ? validity : validityStatus;
                }
              } else {
                validityStatus = false;
                error = {
                  ...error,
                  invalid: true
                };
                validationObject = {
                  ...validationObject,
                  validity: false
                };
              }
              break;
            case 'date':
              if (new Date(item[fieldName]) == 'Invalid Date') {
                validityStatus = false;
                error = {
                  ...error,
                  invalid: true
                };
                validationObject = {
                  ...validationObject,
                  validity: false
                };
              }
              break;
            default:
              // eslint-disable-next-line no-case-declarations
              let validTypes = ['function', 'boolean', 'number', 'string'];
              if (validTypes.indexOf(type) > -1) {
                if (typeof item[fieldName] !== type) {
                  validityStatus = false;
                  error = {
                    ...error,
                    invalid: true
                  };
                  validationObject = {
                    ...validationObject,
                    validity: false
                  };
                }
              } else {
                //UNKNOWN TYPE
                validityStatus = false;
                error = {
                  ...error,
                  invalid: true
                };
                validationObject = {
                  ...validationObject,
                  validity: false
                };
              }
            }
          }
          return {
            fieldName,
            ...validationObject,
            warning,
            error
          };
        }
      );
      return {
        validity: validityStatus,
        configuration: configurationValidity
      };
    } else {
      return {
        validity: false
      };
    }
  };
  return {
    validate
  };
}
