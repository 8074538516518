module.exports = {
  entity: 'rule-analytical-datapoints',
  path: 'rule-analytical-datapoints',
  name: {
    en: 'Rule analytical data points',
    sl_SI: 'Pravila analitičnih podatkovnih točk'
  },
  networkModel: {
    entity: 'rule-analytical-datapoints',
    read: 'read'
  },
  scope: 'tis_be:rule-analytical-datapoints',

  dialog: {
    title: {
      en: 'Select analytical data point rule',
      sl_SI: 'Izberite pravilo analitične podatkovne točke'
    },
    listInfo: {
      en: 'Select analytical data point rule from the list below',
      sl_SI: 'Izberite pravilo analitične podatkovne točke s spodnjega seznama'
    }
  }
}
