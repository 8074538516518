module.exports = {
  entity: 'tango-agent-connection-configs',
  path: 'tango-agent-connection-configs',
  entityId: 248,
  storeKey: 'tangoAgentConnectionConfig',
  name: {
    en: 'Tango agent connection config',
    sl_SI: 'Konfiguracija konektorja tango agenta'
  },
  networkModel: {
    entity: 'tango-agent-connection-configs',
    read: 'read'
  },
  scope: 'tis_be:tango-agent-connection-configs',

  selectedParamName: '_id',
  dialog: {
    title: {
      en: 'Select tango agent connection config',
      sl_SI: 'Izberite konfiguracijo konektorja tango agenta'
    },
    listInfo: {
      en: 'Select tango agent connection config from the list below',
      sl_SI:
        'Izberite konfiguracijo konektorja tango agenta s spodnjega seznama'
    }
  }
}
