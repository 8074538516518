AssetConfiguration.$inject = [
  'gettext',
  'gettextCatalog',
  '$q',
  'AlertingService',
  'OwnershipModel',
  'AssetLocationModel',
  'BusinessPartnerLocationModel'
];

function AssetConfiguration(
  gettext,
  gettextCatalog,
  $q,
  AlertingService,
  OwnershipModel,
  AssetLocationModel,
  BusinessPartnerLocationModel
) {
  let configuration = {
    listId: 'assetListId',
    entity: {
      singular: gettext('Asset'),
      plural: gettext('Assets')
    },
    api: {
      query: {
        entity: 'assets',
        method: 'custom.list'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'company-resources-assets-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'company-resources-assets-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'mode_edit',
        link: {
          state: 'company-resources-assets-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'assets',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Code'),
        param: 'code',
        type: 'code'
      },
      {
        title: gettext('Type'),
        param: 'typeName',
        paramValue: 'assetTypeId',
        link: {
          state: 'configurations-company-resources-assets-asset-types-view',
          params: [
            {
              valueParam: 'type',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        title: gettext('Brand'),
        param: 'brandName',
        type: 'raw'
      },
      {
        title: gettext('Model'),
        param: 'assetModelName',
        type: 'raw'
      }
    ],
    badges: [
      {
        title: gettext('Asset Status'),
        param: 'statusReadable'
      }
    ],
    filter: [
      {
        display: gettext('Name or Description'),
        param: 'filter',
        type: 'string'
      },
      {
        display: gettext('Code'),
        param: 'code',
        type: 'number'
      },
      {
        display: gettext('External Code'),
        param: 'externalCode',
        type: 'string'
      },
      {
        param: 'parentId',
        query: {
          entity: 'assets',
          method: 'read'
        },
        entity: 'assets',
        display: gettext('Asset Parent'),
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        param: 'type',
        query: {
          entity: 'asset-types',
          method: 'read'
        },
        entity: 'asset-types',
        display: gettext('Asset Types'),
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      },
      {
        param: 'assetLocations.location',
        query: {
          entity: 'locations',
          method: 'read'
        },
        entity: 'locations',
        display: gettext('Locations'),
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id',
        populate: 'assetLocations'
      },
      {
        param: 'status',
        query: {
          entity: 'asset-statuses',
          method: 'read'
        },
        entity: 'asset-statuses',
        display: gettext('Asset Statuses')
      },
      {
        param: 'assetBrand',
        query: {
          entity: 'asset-brands',
          method: 'read'
        },
        entity: 'asset-brands',
        display: gettext('Asset Brands')
      },
      {
        param: 'assetModel',
        query: {
          entity: 'asset-models',
          method: 'read'
        },
        entity: 'asset-models',
        display: gettext('Asset Model'),
        dependOnFilter: 'assetBrand',
        dependParamName: 'assetBrand'
      },
      {
        display: gettext('Entity Tags'),
        query: {
          entity: 'entity-tags',
          method: 'read'
        },
        entity: 'entity-tags',
        param: 'entityTagBindings.entityTag',
        populate: 'entityTagBindings',
        displayList: ['value'],
        type: 'searchSelect',
        refreshParameterName: 'value',
        selectedParamName: '_id'
      },
      {
        param: 'type.assetGroup',
        populate: 'type',
        query: {
          entity: 'asset-groups',
          method: 'read'
        },
        entity: 'asset-groups',
        display: gettext('Asset Groups'),
        type: 'searchSelect',
        refreshParameterName: 'filter',
        selectedParamName: '_id'
      }
    ],
    sort: [
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'created ASC',
        params: '_id'
      }
    ],
    extended: {
      query: Extend,
      items: [
        {
          title: gettext('Ownership'),
          param: 'ownershipName',
          type: 'text'
        },
        {
          title: gettext('Owner'),
          param: 'ownerName',
          link: {
            state: 'company-resources-business-partners-view',
            params: [
              {
                valueParam: 'owner',
                queryParam: 'id'
              }
            ]
          },
          type: 'link'
        },
        {
          title: gettext('Location'),
          param: 'mainLocationName',
          link: {
            state: 'company-resources-locations-view',
            params: [
              {
                valueParam: 'mainLocation',
                queryParam: 'id'
              }
            ]
          },
          type: 'link'
        }
      ]
    }
  };

  /*
   *
   *   ENRICH
   *
   */

  function Enrich(item) {
    let deferred = $q.defer();
    if (item.status != null) {
      item.statusReadable = item.status.name;
    }
    if (item.type != null) {
      item.typeName = item.type.name;
    }
    if (item.assetModel != null) {
      item.assetModelName = item.assetModel.name;
    }
    if (item.assetBrand != null) {
      item.brandName = item.assetBrand.name;
    }
    return deferred.promise;
  }

  /*
   *
   *   EXTEND
   *
   */

  function Extend(item) {
    let deferred = $q.defer();
    let parallel = [ReadLocation, ReadOwnerShip];

    async.parallel(parallel, function() {
      deferred.resolve();
    });

    return deferred.promise;

    function ReadOwnerShip(callback) {
      OwnershipModel.read({
        id: item.ownership
      }).then(
        function(res) {
          item.ownershipName = res.data.name;
          callback();
        },
        function(err) {
          AlertingService.Error(err);
          item.ownershipName = gettextCatalog.getString('Unknown');
          callback();
        }
      );
    }

    function ReadLocation(callback) {
      let waterfall = [GetAssetLocation, GetBusinessPartnerLocation];

      async.waterfall(waterfall, function() {
        callback();
      });

      function GetAssetLocation(callback) {
        AssetLocationModel.custom
          .readLocation({
            asset: item._id,
            isActive: true
          })
          .then(
            function(res) {
              if (res.data && res.data.length > 0) {
                item.mainLocation = res.data[0].location._id;
                item.mainLocationName = res.data[0].location
                  ? res.data[0].location.name
                  : gettextCatalog.getString('Unknown');
                callback();
              } else {
                item.mainLocationName = gettextCatalog.getString('Unknown');
                item.mainLocation = null;
                callback(true);
              }
            },
            function(err) {
              item.mainLocationName = gettextCatalog.getString('Unknown');
              item.mainLocation = null;
              AlertingService.Error(err);
              callback(true);
            }
          );
      }

      function GetBusinessPartnerLocation(callback) {
        if (item.mainLocation) {
          BusinessPartnerLocationModel.custom
            .read({
              location: item.mainLocation,
              isMain: true
            })
            .then(
              function(res) {
                if (res.data && res.data.length > 0) {
                  item.owner = res.data[0].businessPartner._id;
                  item.ownerName = res.data[0].businessPartner.name;
                  callback();
                } else {
                  item.owner = null;
                  item.ownerName = gettextCatalog.getString('Unknown');
                  callback(true);
                }
              },
              function(err) {
                item.owner = null;
                item.ownerName = gettextCatalog.getString('Unknown');
                callback(err);
              }
            );
        } else {
          item.owner = null;
          item.ownerName = gettextCatalog.getString('Unknown');
          callback();
        }
      }
    }
  }

  return configuration;
}

export default AssetConfiguration;
