BusinessPartnerConfiguration.$inject = [
  'gettext',
  '$q',
  '$timeout',
  'TranslationService',
  'HierarchyTreeDialog'
];

function BusinessPartnerConfiguration(
  gettext,
  $q,
  $timeout,
  TranslationService,
  HierarchyTreeDialog
) {
  var configuration = {
    listId: 'businessPartnerList',
    entity: {
      singular: gettext('Business Partner'),
      plural: gettext('Business Partners')
    },
    api: {
      query: {
        entity: 'business-partners',
        method: 'custom.listWithAddress'
      },
      enrich: Enrich
    },
    title: {
      param: 'name',
      link: {
        state: 'company-resources-business-partners-view',
        params: [
          {
            valueParam: '_id',
            queryParam: 'id'
          }
        ]
      }
    },
    buttons: [
      {
        title: gettext('Create'),
        link: {
          state: 'company-resources-business-partners-new'
        },
        color: 'accent'
      }
    ],
    shortActions: [
      {
        icon: 'info_outline',
        action: function(item) {
          HierarchyTreeDialog.openDialog(item, 'business-partners');
        }
      },
      {
        icon: 'mode_edit',
        link: {
          state: 'company-resources-business-partners-edit',
          params: [
            {
              valueParam: '_id',
              queryParam: 'id'
            }
          ]
        }
      },
      {
        icon: 'delete',
        query: {
          queryParam: 'id',
          valueParam: '_id',
          method: 'delete',
          configuration: {
            entity: 'business-partners',
            method: 'delete'
          }
        }
      }
    ],
    attributes: [
      {
        title: gettext('Address'),
        param: 'addressReadable',
        type: 'text'
      },
      {
        title: gettext('Code'),
        param: 'code',
        type: 'code'
      },
      {
        title: gettext('External code'),
        param: 'externalCode',
        type: 'code'
      }
    ],
    badges: [
      {
        title: gettext('Type'),
        param: 'partnerType'
      }
    ],
    filter: [
      {
        display: gettext('Name'),
        param: 'name',
        type: 'string'
      },
      {
        display: gettext('Unique Number'),
        param: 'uniqueNumber',
        type: 'string'
      },
      {
        display: gettext('Tax Number'),
        param: 'taxNumber',
        type: 'string'
      },
      {
        collection: TranslationService.GetCollection(
          'codelists.businessPartnerTypes'
        ),
        display: gettext('Type'),
        param: 'type',
        valueField: 'id'
      },
      {
        display: gettext('Code'),
        param: 'code',
        type: 'string'
      },
      {
        display: gettext('Parent Business Partner'),
        param: 'parentId',
        query: {
          entity: 'business-partners',
          method: 'read'
        },
        entity: 'business-partners',
        refreshObject: {
          order: 'name'
        },
        displayList: ['name', 'code'],
        type: 'searchSelect',
        refreshParameterName: 'name',
        selectedParamName: '_id'
      },
      {
        display: gettext('External Code'),
        param: 'externalCode',
        type: 'string'
      }
    ],
    sort: [
      {
        display: 'name ASC',
        params: 'name'
      },
      {
        display: 'name DESC',
        params: '-name'
      },
      {
        display: 'created ASC',
        params: '_id'
      },
      {
        display: 'created DESC',
        params: '-_id'
      },
      {
        display: 'code ASC',
        params: 'code'
      },
      {
        display: 'code DESC',
        params: '-code'
      }
    ]
  };

  /*
   *
   *   ENRICH
   *
   */

  function Enrich(item) {
    var deferred = $q.defer();
    item.fetchingObject['addressReadable'] = {
      fetching: true,
      success: false,
      nodata: false
    };

    var partnerType = TranslationService.GetCollection(
      'codelists.businessPartnerTypes'
    ).find(function(type) {
      return type.id === item.type;
    });
    item.partnerType = partnerType ? partnerType.name : gettext('Unknown');

    if (item.hasOwnProperty('address') && item.address !== null) {
      item.addressReadable = item.address.streetName
        ? item.address.streetName
        : '';
      item.addressReadable += item.address.houseNumber
        ? ' ' + item.address.houseNumber
        : '';
      item.addressReadable += item.address.settlementName
        ? ', ' + item.address.settlementName
        : '';
      readCountry();
    } else {
      item.fetchingObject['addressReadable'] = {
        fetching: false,
        success: true,
        nodata: true
      };
    }

    function readCountry() {
      if (item.address.country) {
        var country = TranslationService.GetCollectionById(
          'codelists.countries',
          item.address.country
        );
        if (country) {
          item.addressReadable =
            item.addressReadable + (country.name ? ', ' + country.name : '');
        }
        item.fetchingObject['addressReadable'].fetching = false;
        item.fetchingObject['addressReadable'].success = true;
      } else {
        if (item.addressReadable) {
          item.fetchingObject['addressReadable'] = {
            fetching: false,
            success: true,
            nodata: false
          };
        } else {
          item.fetchingObject['addressReadable'] = {
            fetching: false,
            success: true,
            nodata: true
          };
        }
      }
    }

    return deferred.promise;
  }
  return configuration;
}

export default BusinessPartnerConfiguration;
