import './sfe-grid.scss';
import template from './sfe-grid.component.html';
/**
 * @ngdoc component
 * @name common.sfeGrid
 * @description component that is used to house smaller dragable elements.
 * @param {array} elements - list of elements we want to display in the component
 * @param {object} functions - object containing functions passed from parent to component
 * @param {number} gridLimit - limit of items per row in grid
 * @param {boolean} hideEdit - tells us if items on component can be edited
 * @param {boolean} loadMoreItems - tells us if we can load more items to from the component
 * @param {function} loadMoreItemsFunction - the function that is called when we can load more items
 * @param {boolean} loadingStatus - tells us if values are still loading
 * @param {boolean} locked - tells us, if items on the component are locked (can moive, can't change etc.)
 * @param {number} moreLeft - number of items that are yet to be loaded
 * @param {boolean} mxClientIsLoaded - tells us if the mxClieant has loaded
 * @param {boolean} highchartsIsLoaded - tells us if highcharts has loaded
 * @param {boolean} prohibitDrag - tells us, if we can we can drag around items on the component.
 * @param {boolean} prohibitErase - tells us if we can delete items on the component.
 * @param {string} cacheInvalidationTime - cache invalidation time
 * @param {string} state - state name of parent
 * @example
 * <sfe-grid
 *   elements="vm.elements"
 *   functions="vm.functions"
 *   gridLimit="5"
 *   hideEdit="vm.hideEdit"
 *   loadMoreItems="vm.loadMoreItems"
 *   loadMoreItemsFunction="vm.loadMoreItemsFunction"
 *   loadingStatus="vm.loading"
 *   locked="true"
 *   moreLeft="vm.MoreLeft"
 *   mxClientIsLoaded="vm.mxClientLoaded"
 *   highchartsIsLoad="vm.hideChartsLoaded"
 *   prohibitDrag="vm.disableDrag"
 *   prohibitErase="vm.disableErase"
 *   cache-invalidation-time="vm.cacheInvalidationTime"
 *   state="'cr-location-cards'"
 * ></sfe-grid>
 */
export default {
  template,
  bindings: {
    elements: '<',
    functions: '<',
    gridLimit: '<',
    hideEdit: '<',
    loadMoreItems: '<',
    loadMoreItemsFunction: '<',
    loadingStatus: '<',
    locked: '<',
    moreLeft: '<',
    mxClientIsLoaded: '<',
    highchartsIsLoaded: '<',
    prohibitDrag: '<',
    prohibitErase: '<',
    cacheInvalidationTime: '<?',
    state: '='
  },
  controller: SfeGridController,
  controllerAs: 'vm'
};

SfeGridController.$inject = [
  '$scope',
  '$timeout',
  'TranslationService',
  'InfoDialog',
  'gettext',
  'gettextCatalog',
  'AlertingService',
  '$mdDialog',
  'loadAssets'
];

function SfeGridController(
  $scope,
  $timeout,
  TranslationService,
  InfoDialog,
  gettext,
  gettextCatalog,
  AlertingService,
  $mdDialog,
  loadAssets
) {
  const vm = this;
  const DOMels = [];
  const draggies = [];
  let packery;
  vm.randomHash = Math.random()
    .toString(36)
    .substring(2);
  vm.showDrag = true;

  // GUI fns
  vm.eraseElement = eraseElement;
  vm.loadNewElements = loadNewElements;

  vm.$onInit = async function() {
    try {
      // load packery and draggabilly
      await loadAssets(['packery', 'draggabilly']);

      if (vm.state === 'cr-location-cards') {
        vm.showDrag = false;
      }

      // expose functions to parent controller
      if (vm.functions) {
        // create these connections
        vm.functions.toggleLock = toggleLock;
        vm.functions.reOrderElements = reOrderElements;
        vm.functions.newElementAdded = newElementAdded;
        vm.functions.repairLayout = repairLayout;
        vm.functions.reloadLayout = reloadLayout;
        vm.functions.generateLinkProperty = generateLinkProperty;
      }
      generateLinkProperty();
      $timeout(init);
    } catch (err) {
      AlertingService.Error('Couldn\'t initiate clusters');
    }
  };

  // used with playlists to rearrange newly created dashboards
  $scope.$on('reOrderGridElements', () => {
    $timeout(init);
  });

  $scope.$on('$destroy', function() {
    destroyNodes();
    destroyPackery();
  });
  /**
   * @description initalization function
   * @function
   * @param {boolean} reload - used when we want to reload the component.
   */
  function init() {
    if (vm.elements) {
      vm.elements.forEach(el => {
        el.initComplete = true;
      });
      destroyPackery();
      createPackery();
      reOrderElements(true);
      reloadLayout();
      toggleLock(vm.locked);
      generateLinkProperty();
    }
  }

  /**
   * @description Creates the packery for grid functionalities.
   * @function
   */
  function createPackery() {
    packery = new Packery('.grid.grid' + vm.randomHash, {
      columnWidth: '.grid-sizer',
      itemSelector: '.grid-item',
      percentPosition: true,
      gutter: 0
    });
    packery.on('dragItemPositioned', () => {
      reOrderElements(null, true);
    });
  }

  /**
   * @description removes the packer functionallity.
   * @function
   */
  function destroyPackery() {
    if (packery != undefined) {
      draggies.forEach(function(draggie) {
        packery.unbindDraggabillyEvents(draggie);
        draggie = null;
      });
      packery.unbindResize();
      try {
        packery.destroy();
      } catch (e) {
        AlertingService.Error(e);
      } finally {
        packery = null;
      }
    }
  }
  /**
   * @description deletes all values from an array that contains DOM nodes.
   * @function
   */
  function destroyNodes() {
    DOMels.length = 0;
  }
  /**
   * @description toggles all items in the draggies array to either lock or unlock.
   * @function
   * @param {boolean} - tells the value is currently locked or unlocked
   */
  function toggleLock(mode) {
    vm.locked = mode || !vm.locked;
    draggies.forEach(draggie => draggie[vm.locked ? 'disable' : 'enable']());
  }

  /**
   * @description Lays out all item elements. Layout is useful when an item has changed size, and all items need to be laid out again.
   * @function
   */
  function repairLayout() {
    $timeout(function() {
      if (packery && packery.items) {
        packery.layout();
      }
    }, 200);
  }
  /**
   * @description Recollects all item elements and lays out all item elements
   * @function
   */
  function reloadLayout() {
    $timeout(function() {
      if (packery && packery.items) {
        packery.reloadItems();
        packery.layout();
      }
    });
  }
  /**
   * @description function that reorders elements on the grid and saves them if necessary.
   * @function
   * @param {boolean} assignDrag - tells us, if we want to make elements in packery dragable.
   * @param {boolean} saveOrder - tells us if we want to save the element order
   * @param {number} saveOrderIndex - tells us the index of the element we want to save, if undefined it saves all elements
   */
  function reOrderElements(reassignDrag, saveOrder, saveOrderIndex) {
    // if saveOrderIndex it will only save the element on index in vm.elements
    if (reassignDrag && !vm.prohibitDrag) {
      draggies.length = 0;
    }
    if (packery && packery.items) {
      packery.getItemElements().forEach((itemElem, index) => {
        if (reassignDrag && !vm.prohibitDrag) {
          const draggie = new Draggabilly(itemElem, {
            handle: '.grid-item-header .drag-handle'
          });
          packery.bindDraggabillyEvents(draggie);
          draggie.on('dragEnd', repairLayout);
          draggies.push(draggie);
        }
        if (vm.elements) {
          const dataElement = vm.elements.find(
            el => el._id === itemElem.getAttribute('data-element')
          );
          if (dataElement) {
            dataElement.order = index;
          }
        }
      });
    }
    if (saveOrder) {
      vm.functions.saveDashboard(vm.elements, saveOrderIndex);
    }
  }
  /**
   * @description loads new new element and adds draggable property.
   * @function
   */
  function loadNewElements() {
    if (vm.loadMoreItemsFunction) {
      vm.loadMoreItemsFunction().then(function() {
        reloadLayout();
      });
    } else {
      reloadLayout();
    }
  }

  function newElementAdded(el) {
    vm.elements.push(el);
    generateLinkProperty();
    $timeout(function() {
      const gridItems = document.querySelectorAll('.grid-item');
      const newDOMel = gridItems[gridItems.length - 1];
      packery.appended(newDOMel);
      DOMels.push(newDOMel);
      if (!vm.prohibitDrag) {
        const draggie = new Draggabilly(newDOMel, {
          handle: '.grid-item-header .drag-handle'
        });
        packery.bindDraggabillyEvents(draggie);
        draggie.on('dragEnd', repairLayout);
        draggies.push(draggie);
        if (vm.locked) {
          draggie['disable']();
        }
      }
      packery.shiftLayout(); // needs some time to init element
    });
    $timeout(() => {
      el.initComplete = true;
    }, 1000);
    reOrderElements(true, false, vm.elements.length - 1);
    repairLayout();
  }

  /**
   * @description erases an element from the SFE-grid.
   * @function
   * @param {object} element - element being removed.
   */
  function eraseElement(element) {
    if (vm.prohibitErase) {
      return;
    }
    const type = getType();
    const title = gettext('Remove item');
    const textItem = getTextItem(type);
    const actions = getActions();

    InfoDialog.open(title, null, [textItem], actions);

    function getType() {
      return (
        TranslationService.GetCollectionById(
          'codelists.dashboardCardTypes',
          element.dashboardCardType
        ) || {
          name: 'Element'
        }
      );
    }

    function getTextItem(type) {
      return {
        text: gettextCatalog.getString(
          'Are you sure you want to remove this {{name}}?',
          { name: type.name }
        ),
        type: 'text'
      };
    }

    function getActions() {
      return [
        {
          title: gettext('Cancel'),
          cancel: true,
          color: 'primary'
        },
        {
          title: gettext('Remove'),
          fn: remove,
          color: 'warn'
        }
      ];
    }

    async function remove() {
      let index = vm.elements.findIndex(gridEl => gridEl._id === element._id);
      $mdDialog.cancel();
      if (vm.functions.removeElement) {
        try {
          await vm.functions.removeDashboardCard(element);
          packery.shiftLayout();
          // remove item form elements array
          vm.elements.splice(index, 1);
          const packeryItems = packery.getItemElements();
          const itemToRemove = packeryItems.find(
            packeryElement =>
              packeryElement.getAttribute('data-element') == element._id
          );
          if (itemToRemove) {
            // remove item from packery
            packery.remove(itemToRemove);
          }
          reOrderElements(false, vm.elements);
          repairLayout();
        } catch (err) {
          AlertingService.Error(err);
        }
      } else {
        vm.elements.splice(index, 1);
        reOrderElements(false, vm.elements);
        repairLayout();
      }
    }
  }
  /**
   * @description generates state name for the specific card-type.
   * @function
   * @param {number} cardType - number telling us the card type
   * @return {string} string containing the state of the card.
   */
  function generateStateName(cardType) {
    switch (cardType) {
    case 1:
      break;
    case 3:
      return 'data-time-series-groups-sandbox';
    case 4:
      return 'analytics-analyses-view';
    case 5:
      return 'company-resources-locations-view';
    case 6:
      return 'data-time-series-view';
    case 10:
      return 'company-resources-locations-view';
    case 11:
      return 'data-cusums-view';
    case 12:
      return 'data-visualization-visualizations-viewer';
    case 15:
      return 'data-time-series-view';
    case 20:
      return 'maps-gis-maps-view';
    }
  }
  /**
   * @description generates .
   * @function
   * @param {number} cardType - number telling us the card type
   * @param {string} id - id of the item we are creating a param for
   * @return {object} Object where the object propery is the param name and id is the property value
   */
  function generateParams(cardType, id) {
    let param;
    const resultParams = {};
    switch (cardType) {
    case 1:
      break;
    case 3:
      param = 'groupId';
      break;
    case 4:
    case 5:
    case 6:
    case 10:
    case 12:
    case 15:
    case 20:
      param = 'id';
      break;
    case 11:
      param = 'IdCusum';
      break;
    }
    resultParams[param] = id;
    return resultParams;
  }
  /**
   * @description generates a URL link for all the cards on the component..
   * @function
   */
  function generateLinkProperty() {
    if (vm.elements) {
      vm.elements = vm.elements.map(element => {
        switch (element.dashboardCardType) {
        case 20:
          element.parameters = generateParams(
            element.dashboardCardType,
            element.details.gisMapId
          );
          element.state = generateStateName(element.dashboardCardType);
          break;
        default:
          if (
            element.entities &&
              element.entities[0] &&
              element.entities[0].entityId
          ) {
            element.parameters = generateParams(
              element.dashboardCardType,
              element.entities[0].entityId
            );
          }
          element.state = generateStateName(element.dashboardCardType);
          element.link = `${element.state}(${JSON.stringify(
            element.parameters
          )})`;
        }
        return element;
      });
    }
  }
}
