NewDomainAttributeConfigController.$inject = [
  '$stateParams',
  'gettext',
  'AlertingService',
  'MetadataService',
  '$timeout',
  'createOrUpdateService',
  'SfeHeader',
  'MultiLanguage',
  'LocalizationService',
  'gettextCatalog',
  'DomainFieldModel',
  'domainAttribute'
];

function NewDomainAttributeConfigController(
  $stateParams,
  gettext,
  AlertingService,
  MetadataService,
  $timeout,
  createOrUpdateService,
  SfeHeader,
  MultiLanguage,
  LocalizationService,
  gettextCatalog,
  DomainFieldModel,
  domainAttribute
) {
  const vm = this;
  let firstLoad;
  let attributeTypes = [
    {
      _id: 1,
      name: gettextCatalog.getString('String'),
      nameToSend: 'string'
    },
    {
      _id: 2,
      name: gettextCatalog.getString('Integer'),
      nameToSend: 'integer'
    },
    {
      _id: 3,
      name: gettextCatalog.getString('Double'),
      nameToSend: 'double'
    },
    {
      _id: 7,
      name: gettextCatalog.getString('Text area'),
      nameToSend: 'textarea'
    },
    {
      _id: 4,
      name: gettextCatalog.getString('Select menu'),
      nameToSend: 'select'
    },
    {
      _id: 5,
      name: gettextCatalog.getString('Check box'),
      nameToSend: 'checkbox'
    }
    // {_id:6, name:'Radio button', nameToSend: 'radio'}
  ];

  init();

  function init() {
    vm.dataConfig = {};
    firstLoad = true;
    vm.dynamicAttributeId = $stateParams.id;

    if (vm.dynamicAttributeId) {
      MetadataService.Loading(true);
      vm.editMode = true;
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('Edit dynamic attribute'),
        'configurations-dynamic-attributes-dynamic-attributes-list-view',
        { id: vm.dynamicAttributeId }
      );
      setDomainAttributeForm();
    } else {
      vm.editMode = false;
      vm.dataConfig.header = SfeHeader.constructFormHeader(
        'primary',
        gettext('New dynamic attribute'),
        'configurations-dynamic-attributes-dynamic-attributes-list'
      );
      vm.dataConfig.dataObj = {
        name: '',
        description: '',
        code: '',
        displayFields: [],
        domainTypeNotPicked: true
      };
    }

    vm.dataConfig.data = [
      {
        placeholder: gettext('Name'),
        name: 'name',
        type: 'text',
        componentType: 'multilanguage'
      },
      {
        placeholder: gettext('Description'),
        name: 'description',
        maxlength: 500,
        type: 'text',
        required: false,
        componentType: 'multilanguage'
      },
      {
        placeholder: gettext('Code'),
        name: 'code',
        componentType: 'textField',
        type: 'text'
      },
      {
        componentType: 'autocompleteDialog',
        edit: !!vm.dynamicAttributeId,
        configuration: {
          codelist: 'dataTypes',
          floatingLabel: gettext('Select Format'),
          searchParamName: 'name',
          required: true,
          entity: 'dataTypes',
          dialogConfiguration: {
            multiple: false,
            title: gettext('Select format')
          },
          selectedParam: 'id'
        },
        name: 'dataType'
      },
      {
        componentType: 'autocompleteDialog',
        edit: !!vm.dynamicAttributeId,
        name: 'type',
        configuration: {
          floatingLabel: gettext('Choose an element type'),
          required: true,
          searchParamName: 'name',
          noDialog: true,
          query: {
            query: function(searchObj) {
              return {
                $promise: new Promise(resolve => {
                  let data;
                  if (searchObj && searchObj.name) {
                    data = attributeTypes.filter(type =>
                      type.name
                        .toLowerCase()
                        .includes(searchObj.name.toLowerCase())
                    );
                  } else {
                    data = attributeTypes;
                  }
                  resolve({
                    data
                  });
                })
              };
            }
          }
        }
      },
      {
        componentType: 'autocompleteDialog',
        hide: true,
        showParam: 'typeFour',
        edit: !!vm.dynamicAttributeId,
        name: 'domainId',
        configuration: {
          floatingLabel: gettext('Select domain type'),
          required: true,
          query: {
            entity: 'domains',
            method: 'read'
          },
          selectedParam: 'id',
          change: resetDisplayAndValue,
          searchParamName: 'filter',
          entity: 'domains'
        }
      },
      {
        componentType: 'paragraph',
        classes: 'sfe-small-text grey-color',
        paragraph: gettext(
          'Prior to choosing value field name and display field name, make sure to select a domain type'
        ),
        showFn: isSelect
      },
      {
        componentType: 'autocompleteDialog',
        hide: true,
        showParam: 'hasDomainId',
        edit: !!vm.dynamicAttributeId,
        name: 'valueField',
        configuration: {
          disabledFn: () => !vm.dataConfig.dataObj.domainId,
          floatingLabel: gettext('Select value field'),
          required: true,
          noDialog: true,
          searchParamName: 'name',
          selectedParam: 'fieldId',
          filterObjectFn: prefetch => {
            let domainId =
              typeof vm.dataConfig.dataObj.domainId == 'object' &&
              vm.dataConfig.dataObj.domainId != null
                ? vm.dataConfig.dataObj.domainId._id
                : vm.dataConfig.dataObj.domainId;
            let fieldId = prefetch
              ? typeof vm.dataConfig.dataObj.valueField == 'object' &&
                vm.dataConfig.dataObj.valueField != null
                ? vm.dataConfig.dataObj.valueField._id
                : vm.dataConfig.dataObj.valueField
              : '';
            return {
              domainId,
              fieldId
            };
          },
          query: {
            entity: 'domain-fields',
            method: 'read'
          },
          refreshObjectRequired: true
        }
      },
      {
        title: gettext('Display field'),
        showFn: isSelect,
        componentType: 'title'
      },
      {
        componentType: 'multiSelectList',
        name: 'displayFields',
        hide: true,
        showParam: 'hasDomainId',
        disabledAddBtnParam: 'domainTypeNotPicked',
        removeTitle: gettext('Remove display field'),
        addLabel: gettext('+ Add display field'),
        groupLabel: gettext('Display field'),
        selectConfigs: [
          {
            componentType: 'autocomplete',
            searchParamName: 'name',
            selectedParam: 'name',
            required: true,
            entity: 'entities',
            noDialog: true,
            query: {
              entity: 'domain-fields',
              method: 'read'
            },
            filterObjectFn: () => ({
              domainId: vm.dataConfig.dataObj.domainId._id
            }),
            placeholder: gettext('Choose display field'),
            floatingLabel: gettext('Display field'),
            reset: true
          }
        ]
      }
    ];

    vm.dataConfig.action = {
      ctrlFn: true,
      title: vm.dynamicAttributeId ? gettext('Update') : gettext('Create'),
      fn: createOrUpdate
    };
  }

  function resetDisplayAndValue() {
    if (!firstLoad) {
      if (vm.dataConfig.dataObj.valueField)
        vm.dataConfig.dataObj.valueField = null;
      vm.dataConfig.dataObj.displayFields = [];
    } else {
      firstLoad = false;
    }
    domainChanged(vm.dataConfig.dataObj.domainId);
  }

  function domainChanged(value) {
    if (value && vm.dataConfig.data) {
      vm.dataConfig.dataObj.domainTypeNotPicked = false;
      if (Array.isArray(vm.dataConfig.data)) {
        vm.dataConfig.data.forEach(function(config) {
          switch (config.name) {
          case 'valueField':
            config.configuration.filterObject = {
              domainId: value._id
            };
            break;
          }
        });
      }
    } else {
      vm.dataConfig.dataObj.domainId = null;
      vm.dataConfig.dataObj.domainTypeNotPicked = true;
    }
  }
  //This works in such a way, that isSelect, that hides a portion of the form, also sets the hide params for the things that rely
  //on boolean params for hiding
  function isSelect() {
    if (vm.dataConfig && vm.dataConfig.dataObj && vm.dataConfig.dataObj.type) {
      if (vm.dataConfig.dataObj.type._id === 4) {
        vm.dataConfig.dataObj.typeFour = true;
        if (vm.dataConfig.dataObj.domainId != null) {
          vm.dataConfig.dataObj.hasDomainId = true;
          return true;
        } else {
          vm.dataConfig.dataObj.hasDomainId = false;
          return false;
        }
      }
      vm.dataConfig.dataObj.hasDomainId = false;
      vm.dataConfig.dataObj.typeFour = false;
      return false;
    }

    if (vm.dataConfig.dataObj) {
      vm.dataConfig.dataObj.hasDomainId = false;
      vm.dataConfig.dataObj.typeFour = false;
    }
    return false;
  }

  function setDomainAttributeForm() {
    vm.dataConfig.dataObj = {
      name: domainAttribute.name,
      description: domainAttribute.description,
      code: domainAttribute.code,
      valueField: domainAttribute.valueField
    };
    if (domainAttribute.domain) {
      domainChanged({
        _id: domainAttribute.domain
      });
    }

    let type = _.find(attributeTypes, {
      nameToSend: domainAttribute.elementType
    });

    vm.dataConfig.dataObj.type = type;

    vm.dataConfig.dataObj.domainId = domainAttribute.domain;
    vm.dataConfig.dataObj.dataType = domainAttribute.dataType;

    getFields(domainAttribute.displayFields, domainAttribute.domain);

    const currentLanguage = LocalizationService.GetSelectedLang();

    MetadataService.Loading(false);
    $timeout(() => {
      MetadataService.ChangeMetadata(
        'Edit ' + domainAttribute.name[currentLanguage.networkCode],
        domainAttribute.description[currentLanguage.networkCode]
      );
    });
  }

  async function getFields(displayFields, domainId) {
    const promises = displayFields.map(fieldId =>
      DomainFieldModel.read({
        domainId,
        fieldId
      })
    );
    try {
      const displayFields = await Promise.all(promises);
      vm.dataConfig.dataObj.displayFields = displayFields.map(field => ({
        0: field.data
      }));
    } catch (err) {
      AlertingService.Error(err);
    }
  }

  // create and update functions
  async function createOrUpdate() {
    vm.sendingRequest = true;
    try {
      await createOrUpdateService.simpleCall(
        {
          entity: 'domain-attributes',
          method: vm.editMode ? 'update' : 'create'
        },
        vm.editMode,
        vm.dynamicAttributeId,
        dataToSave(),
        'configurations-dynamic-attributes-dynamic-attributes-list-view',
        'id'
      );
      vm.sendingRequest = false;
    } catch (err) {
      vm.sendingRequest = false;
      AlertingService.Error(err);
    }
  }

  function dataToSave() {
    MultiLanguage.constructForm(vm.dataConfig.data, vm.dataConfig.dataObj);
    let obj = {
      name: vm.dataConfig.dataObj.name,
      description: vm.dataConfig.dataObj.description,
      code: vm.dataConfig.dataObj.code,
      dataType: vm.dataConfig.dataObj.dataType.id
    };
    let type = _.find(attributeTypes, {
      _id: vm.dataConfig.dataObj.type._id
    }).nameToSend;

    obj.elementType = type;

    if (type === 'select') {
      obj.domain = vm.dataConfig.dataObj.domainId._id;
      obj.valueField = vm.dataConfig.dataObj.valueField._id;
      obj.displayFields = vm.dataConfig.dataObj.displayFields.map(
        field => field[0]._id
      );
    } else {
      obj.domain = null;
      obj.valueField = null;
      obj.displayFields = null;
    }

    return obj;
  }
}

export default NewDomainAttributeConfigController;
