import externalWriterFlowAction from '../../../redux/external-writer-flow/action/external-writer-flow.action';
import timeSeriesConfigurationsAction from '../../../redux/time-series-configurations/action/times-series-configurations.action';

/**
 * @ngdoc service
 * @name common.ExternalWriterFlowFormConfigurations
 * @description Opens form dialog
 */

ExternalWriterFlowFormConfigurations.$inject = [
  'gettextCatalog',
  'gettext',
  'TranslationService',
  '$ngRedux',
  '$mdDialog',
  'TimeSeriesConfigurationModel',
  'ExternalWriterFlowModel',
  'AlertingService',
  'CrudToastFactory',
  'SchedulerModel',
  'TimeSeriesFlowHelper'
];
export default function ExternalWriterFlowFormConfigurations(
  gettextCatalog,
  gettext,
  TranslationService,
  $ngRedux,
  $mdDialog,
  TimeSeriesConfigurationModel,
  ExternalWriterFlowModel,
  AlertingService,
  CrudToastFactory,
  SchedulerModel,
  TimeSeriesFlowHelper
) {
  /**
   * @description map body data.
   * @function
   * @param {Object} body
   * @param {Object} bodyData
   */
  const mapBodyData = (
    body,
    {
      inputTimeSeries,
      timeoutMaxTimeoutInDays,
      timeoutMaxTimeoutInHours,
      timeoutMaxTimeoutInMinutes,
      timeoutMaxTimeoutInSeconds,
      timeoutAction,
      timeoutAlarm,
      externalDatasource,
      tangoAgentConnectionConfiguration,
      tangoAgentConfigTag,
      writeCancelRules
    }
  ) => {
    const rules = Array.isArray(writeCancelRules)
      ? writeCancelRules.map(ruleObj => {
        return {
          rule: ruleObj.rule ? ruleObj.rule._id : null,
          alarms: Array.isArray(ruleObj.alarms)
            ? ruleObj.alarms.map(alarm => alarm._id)
            : []
        };
      })
      : null;
    return {
      ...body,
      inputTimeSeries: inputTimeSeries ? inputTimeSeries._id : null,
      writeCancelRules: rules,
      externalDatasource: externalDatasource ? externalDatasource._id : null,
      tangoAgentConfig: {
        connectionConfig: tangoAgentConnectionConfiguration
          ? tangoAgentConnectionConfiguration._id
          : null,
        tag: tangoAgentConfigTag ? tangoAgentConfigTag._id : null
      },
      timeout: {
        action: timeoutAction ? timeoutAction.id : null,
        alarm: timeoutAlarm ? timeoutAlarm._id : null,
        maxTimeoutInMilliseconds: getMilliseconds(
          timeoutMaxTimeoutInDays,
          timeoutMaxTimeoutInHours,
          timeoutMaxTimeoutInMinutes,
          timeoutMaxTimeoutInSeconds
        )
      }
    };
  };

  /**
   * @description Convert milliseconds to days, hours, minutes and seconds.
   * @function
   * @param {number} milliseconds
   * @return {Object}
   */
  function getMillisecondsInOtherDateFormats(milliseconds) {
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    if (typeof milliseconds === 'number') {
      let millisecondsToCarryOver = milliseconds;
      days = Math.floor(millisecondsToCarryOver / (1000 * 60 * 60 * 24));
      millisecondsToCarryOver = millisecondsToCarryOver % (1000 * 60 * 60 * 24);
      hours = Math.floor(millisecondsToCarryOver / (1000 * 60 * 60));
      millisecondsToCarryOver = millisecondsToCarryOver % (1000 * 60 * 60);
      minutes = Math.floor(millisecondsToCarryOver / (1000 * 60));
      millisecondsToCarryOver = millisecondsToCarryOver % (1000 * 60);
      seconds = Math.floor(millisecondsToCarryOver / 1000);
    }
    return {
      days,
      hours,
      minutes,
      seconds
    };
  }

  /**
   * @description calculate milliseconds from days, hours, minutes and seconds.
   * @function
   * @param {number} days
   * @param {number} hours
   * @param {number} minutes
   * @param {number} seconds
   * @return {number}
   */
  function getMilliseconds(days, hours, minutes, seconds) {
    let milliseconds = 0;
    if (typeof days === 'number') {
      milliseconds += days * 1000 * 60 * 60 * 24;
    }
    if (typeof hours === 'number') {
      milliseconds += hours * 1000 * 60 * 60;
    }
    if (typeof minutes === 'number') {
      milliseconds += minutes * 1000 * 60;
    }
    if (typeof seconds === 'number') {
      milliseconds += seconds * 1000;
    }
    return milliseconds;
  }

  /**
   * @description generate display object for autocomplete.
   * @function
   * @param {string} name
   * @param {string} unknown
   * @return {Object}
   */
  function displayName(data) {
    return {
      text: `${data ? data.name : gettextCatalog.getString('Unknown')}`
    };
  }
  /**
   * @description populates ts scheduler.
   * @function
   * @param {Object} item
   * @return {dataType}
   */
  async function populateScheduler(item) {
    if (
      item != null &&
      item.inputTimeSeries != null &&
      typeof item.inputTimeSeries == 'object' &&
      item.inputTimeSeries.dataScheduler != null
    ) {
      try {
        let { data } = await SchedulerModel.read({
          id: item.inputTimeSeries.dataScheduler
        });
        item.inputTimeSeries.dataScheduler = data;
      } catch (err) {
        AlertingService.Error(err);
      }
    }
    return item;
  }

  /**
   * @description get timeout based on current time series schedule classification
   * @function
   * @param {number} scheduleClassificationId
   * @param {Object} api
   */
  const getTimeoutByScheduleClassification = scheduleClassificationId => {
    let timeout = 0;
    switch (scheduleClassificationId) {
    // Hourly
    case 1:
      timeout = 1000 * 60 * 60;
      break;
      // Daily
    case 2:
      timeout = 1000 * 60 * 60 * 24;
      break;
      // Weekly
    case 3:
      timeout = 1000 * 60 * 60 * 24 * 7;
      break;
      // Monthly
    case 4:
      timeout = 1000 * 60 * 60 * 24 * 31;
      break;
      // Yearly
    case 5:
      timeout = 1000 * 60 * 60 * 24 * 365;
      break;
      // 15 minutes
    case 6:
      timeout = 1000 * 60 * 15;
      break;
      // 30 minutes
    case 7:
      timeout = 1000 * 60 * 30;
      break;
      // 6 months
    case 8:
      timeout = 1000 * 60 * 60 * 24 * 183;
      break;
    }
    return timeout;
  };

  /**
   * @description get data type from step or params.
   * @function
   * @param {Object} timeSeries
   * @param {Array} steps
   * @return {number}
   */
  const getDataType = (timeSeries, steps) => {
    let dataType;
    if (timeSeries != null) {
      dataType = timeSeries.dataType;
    } else if (Array.isArray(steps)) {
      let foundStep = steps.find(step => {
        if (step.api != null && typeof step.api.getValue === 'function') {
          return step.api.getFieldConfiguration('dataType') != null;
        }
      });
      if (foundStep) {
        let dataTypeVal = foundStep.api.getValue('dataType');
        dataType = dataTypeVal ? dataTypeVal.id : dataTypeVal;
      }
    }
    return dataType;
  };

  /**
   * @description get common properties maintenance flow form configuration.
   * @function
   * @param {Object} entityId
   * @param {Array} steps - Array of objects, containing tangoWizard steps.
   * @param {Object} duplicateData - object containing data for duplicating
   * @return {Object} configuration
   */
  async function get(options) {
    const {
      item,
      timeSeries,
      timeSeriesConfiguration
    } = await TimeSeriesFlowHelper.getFlowData({
      ...options,
      entity: 'external-writer-flows',
      populate:
        'inputTimeSeries,timeout.alarm,externalDatasource,tangoAgentConfig.connectionConfig,tangoAgentConfig.tag,writeCancelRules.rule,writeCancelRules.alarms',
      processResult: populateScheduler
    });

    const { actionType, steps, duplicateData } = options;

    let timeoutDateFormats;
    let timeout = 0;
    if (Array.isArray(steps)) {
      let stepWithScheduler = steps.find(step => {
        if (step.api != null && typeof step.api.getValue === 'function') {
          return step.api.getFieldConfiguration('scheduler') != null;
        }
      });
      if (stepWithScheduler != null) {
        let scheduler = stepWithScheduler.api.getValue('scheduler');
        if (scheduler != null) {
          timeout = getTimeoutByScheduleClassification(
            scheduler.scheduleClassification
          );
        }
      }
    }
    if (timeSeries != null && timeSeries.dataScheduler != null) {
      timeout = getTimeoutByScheduleClassification(
        timeSeries.dataScheduler.scheduleClassification
      );
    }
    if (item != null && item.timeout != null) {
      timeout = item.timeout.maxTimeoutInMilliseconds;
    }
    timeoutDateFormats = getMillisecondsInOtherDateFormats(timeout);
    let title;
    switch (actionType) {
    case 'update':
      title = gettextCatalog.getString('Update external writer flow');
      break;
    case 'create':
      title = gettextCatalog.getString('Create external writer flow');
      break;
    case 'duplicate':
      title = gettextCatalog.getString('Duplicate external writer flow');
      break;
    default:
      title = gettextCatalog.getString('External writer flow');
    }
    let config = {
      name: 'externalWriterFlow',
      actions: [
        {
          //SAVE ACTION
          title: gettext('Save'),
          fn: async form => {
            let bodyData = form.getValues();
            let body = mapBodyData({}, bodyData);
            //UPDATE
            if (
              item != null &&
              timeSeriesConfiguration != null &&
              actionType === 'update'
            ) {
              const flowParams = {
                query: { timeSeriesId: item.timeSeries, id: item._id },
                body
              };
              const configurationParams = {
                query: {
                  timeSeriesId: item.timeSeries,
                  id: timeSeriesConfiguration._id
                },
                body: {
                  validFrom: bodyData.validFrom[0],
                  validTo: Array.isArray(bodyData.validTo)
                    ? bodyData.validTo[0]
                    : null
                }
              };
              let actionFlow =
                externalWriterFlowAction.updateExternalWriterFlow;
              await $ngRedux.dispatch(actionFlow(flowParams));

              if (timeSeriesConfiguration.validTo == null) {
                let action = timeSeriesConfigurationsAction.updateTimeSeriesConfigurations(
                  configurationParams
                );
                $ngRedux.dispatch(action);
              }
            } else {
              //CREATE
              try {
                let timeSeriesConfigurationBody = {
                  validFrom: bodyData.validFrom[0],
                  flowRef: 200
                };
                let createdFlow = await ExternalWriterFlowModel.create(
                  { timeSeriesId: timeSeries._id },
                  body
                );
                let actionFlow = externalWriterFlowAction.addExternalWriterFlow;
                await $ngRedux.dispatch(actionFlow({ result: createdFlow }));
                timeSeriesConfigurationBody.flow = createdFlow.data._id;
                let createdTimeSeriesConfiguration = await TimeSeriesConfigurationModel.create(
                  { timeSeriesId: timeSeries._id },
                  timeSeriesConfigurationBody
                );
                let action =
                  timeSeriesConfigurationsAction.addTimeSeriesConfigurations;
                await $ngRedux.dispatch(
                  action({ result: createdTimeSeriesConfiguration })
                );
                CrudToastFactory.toast('create');
              } catch (err) {
                AlertingService.Error(err);
              }
            }
            $mdDialog.hide();
          },
          color: 'primary',
          disabledFn: form => {
            if (form.formValidity() === true) {
              return false;
            }
            return true;
          }
        }
      ],
      title,
      fields: [
        {
          id: 'inputTimeSeriesTitle',
          type: {
            name: 'title',
            options: {
              value: gettextCatalog.getString('Input time series'),
              theme: 'primary'
            }
          }
        },
        {
          id: 'inputTimeSeries',
          title: gettextCatalog.getString('Input time series'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'time-series',
                method: 'read'
              },
              valueParam: '_id',
              crawlerParams: text => {
                let dataType = getDataType(timeSeries, steps);
                let params = {
                  order: '-name',
                  populate: 'dataScheduler',
                  dataType
                };
                if (text != null && text != '') {
                  return {
                    ...params,
                    filter: text
                  };
                }
                return params;
              },
              display: item => {
                let schedulerClassificationAbbreviation = '';
                if (item != null) {
                  if (
                    item.dataScheduler != null &&
                    item.dataScheduler.scheduleClassification
                  ) {
                    let schedulerClassification = TranslationService.GetCollectionById(
                      'codelists.scheduleClassifications',
                      item.dataScheduler.scheduleClassification
                    );
                    if (
                      schedulerClassification != null &&
                      schedulerClassification.name != ''
                    ) {
                      schedulerClassificationAbbreviation = ` - ${schedulerClassification.name}`;
                    }
                  }
                  return {
                    text: `${item.name}${schedulerClassificationAbbreviation}`
                  };
                }
                return { text: gettextCatalog.getString('Unknown') };
              },
              dialog: {
                entity: 'time-series'
              }
            }
          },
          initialize: () => {
            let timeSeries = null;
            if (item != null && item.inputTimeSeries != null) {
              timeSeries = item.inputTimeSeries;
            }
            if (
              duplicateData != null &&
              duplicateData.useCurrentTimeSeriesAsInputParameter != null
            ) {
              timeSeries =
                duplicateData.useCurrentTimeSeriesAsInputParameter._id;
            }
            return timeSeries;
          },
          required: true
        },
        {
          id: 'timeoutTitle',
          type: {
            name: 'title',
            options: {
              value: gettextCatalog.getString('Timeout'),
              theme: 'primary'
            }
          }
        },
        {
          id: 'timeoutMaxTimeoutInDays',
          title: gettextCatalog.getString('Days'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0
            }
          },
          initialize: () => {
            return timeoutDateFormats != null ? timeoutDateFormats.days : 0;
          },
          width: 3,
          required: true
        },
        {
          id: 'timeoutMaxTimeoutInHours',
          title: gettextCatalog.getString('Hours'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0,
              max: 23
            }
          },
          initialize: () => {
            return timeoutDateFormats != null ? timeoutDateFormats.hours : 0;
          },
          width: 3,
          required: true
        },
        {
          id: 'timeoutMaxTimeoutInMinutes',
          title: gettextCatalog.getString('Minutes'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0,
              max: 59
            }
          },
          initialize: () => {
            return timeoutDateFormats != null ? timeoutDateFormats.minutes : 0;
          },
          width: 3,
          required: true
        },
        {
          id: 'timeoutMaxTimeoutInSeconds',
          title: gettextCatalog.getString('Seconds'),
          type: {
            name: 'text',
            options: {
              type: 'number',
              min: 0,
              max: 59
            }
          },
          initialize: () => {
            return timeoutDateFormats != null ? timeoutDateFormats.seconds : 0;
          },
          width: 3,
          required: true
        },
        {
          id: 'timeoutAction',
          title: gettextCatalog.getString('Timeout action'),
          type: {
            name: 'autocomplete',
            options: {
              items: TranslationService.GetCollection(
                'codelists.externalWriterFlowTimeoutActions'
              ),
              display: data => displayName(data)
            }
          },
          initialize: () => {
            if (item != null && item.timeout != null) {
              return TranslationService.GetCollectionById(
                'codelists.externalWriterFlowTimeoutActions',
                item.timeout.action
              );
            }
            return null;
          },
          required: true
        },
        {
          id: 'timeoutAlarm',
          title: gettextCatalog.getString('Timeout alarm'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'alarms',
                method: 'read'
              },
              valueParam: '_id',
              crawlerParams: text => {
                let params = {
                  order: '-name'
                };
                if (text != null && text != '') {
                  return {
                    ...params,
                    filter: text
                  };
                }
                return params;
              },
              display: data => displayName(data),
              dialog: {
                entity: 'alarms'
              }
            }
          },
          initialize: () => {
            if (item != null && item.timeout != null) {
              return item.timeout.alarm;
            }
            return null;
          },
          required: true
        },
        {
          id: 'externalDatasource',
          title: gettextCatalog.getString('External datasource'),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'external-datasources',
                method: 'read'
              },
              valueParam: '_id',
              crawlerParams: text => {
                let params = {
                  order: '-_id',
                  externalDatasourceType: 100
                };
                if (text != null && text != '') {
                  return {
                    ...params,
                    filter: text
                  };
                }
                return params;
              },
              display: data => displayName(data),
              dialog: {
                entity: 'external-datasources'
              }
            }
          },
          initialize: () => {
            return item != null ? item.externalDatasource : null;
          },
          onChange: form => {
            let externalDatasource = form.getValue('externalDatasource');
            let tangoAgent = form.getValue('tangoAgentConnectionConfiguration');
            if (externalDatasource != null && tangoAgent != null) {
              if (tangoAgent.externalDatasource != externalDatasource._id) {
                form.setValue('tangoAgentConnectionConfiguration', null);
              }
            } else {
              form.setValue('tangoAgentConnectionConfiguration', null);
            }
          },
          required: true
        },
        {
          id: 'tangoAgentConnectionConfiguration',
          title: gettextCatalog.getString(
            'Tango agent connection configuration'
          ),
          type: {
            name: 'autocomplete',
            options: {
              itemsCrawler: {
                entity: 'tango-agent-connection-configs',
                method: 'read'
              },
              valueParam: '_id',
              crawlerParams: (text, api) => {
                let externalDatasource = api.getValue('externalDatasource');
                let params = {
                  order: '-name',
                  externalDatasource: externalDatasource
                    ? externalDatasource._id
                    : null
                };

                if (text != null && text != '') {
                  return {
                    ...params,
                    filter: text
                  };
                }
                return params;
              },
              display: data => displayName(data),
              dialog: {
                entity: 'tango-agent-connection-configs'
              }
            }
          },
          initialize: () => {
            if (item != null && item.tangoAgentConfig != null) {
              return item.tangoAgentConfig.connectionConfig;
            }
            return null;
          },
          onChange: form => {
            let connectionConfig = form.getValue(
              'tangoAgentConnectionConfiguration'
            );
            let configTag = form.getValue('tangoAgentConfigTag');
            if (connectionConfig == null) {
              form.setValue('tangoAgentConfigTag', null);
            }
            if (
              connectionConfig != null &&
              configTag != null &&
              connectionConfig._id !== configTag.tangoAgentConnectionConfig
            ) {
              form.setValue('tangoAgentConfigTag', null);
            }
          },
          required: true
        },
        {
          id: 'tangoAgentConfigTag',
          title: gettextCatalog.getString('Tag on source'),
          type: {
            name: 'agentConfigTag',
            options: {
              connectionConfig: form => {
                let tangoAgentConnectionConfiguration = form.getValue(
                  'tangoAgentConnectionConfiguration'
                );
                return tangoAgentConnectionConfiguration != null
                  ? tangoAgentConnectionConfiguration._id
                  : null;
              }
            }
          },
          initialize: () => {
            if (item != null && item.tangoAgentConfig != null) {
              return item.tangoAgentConfig.tag;
            }
            return null;
          },
          disable: form => {
            let tangoAgentConnectionConfiguration = form.getValue(
              'tangoAgentConnectionConfiguration'
            );
            if (tangoAgentConnectionConfiguration == null) {
              return true;
            }
            return false;
          },
          required: true
        },
        {
          id: 'writeCancelRules',
          title: gettextCatalog.getString('Write cancel rules'),
          type: {
            name: 'ruleAlarm',
            options: {}
          },
          initialize: () => {
            return item != null ? item.writeCancelRules : null;
          },
          required: false
        }
      ]
    };
    return config;
  }

  return { get, mapBodyData };
}
