AuthorizationShowScopeModel.$inject = ['ModelUtilities'];

function AuthorizationShowScopeModel(ModelUtilities) {
  var modelStorage = {};

  var networkConfiguration = {
    host: 'authorization',
    path: 'show-scopes',
    notCancelable: true
  };

  var methods = {
    read: ModelUtilities.crud('GET', networkConfiguration, modelStorage),
    refreshModelStorage: ModelUtilities.RefreshModelStorage(modelStorage),
    revalidateModelStorage: ModelUtilities.RevalidateModelStorage(modelStorage)
  };

  return methods;
}

export default AuthorizationShowScopeModel;
