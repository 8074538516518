module.exports = {
  entity: 'measuringpoint-types',
  path: 'measuringpoint-types',
  entityId: 158,
  name: {
    en: 'Measuring point types',
    sl_SI: 'Tipi merilnih mest'
  },
  networkModel: {
    entity: 'measuringpoint-types',
    read: 'read'
  },
  selectedParamName: '_id',
  scope: 'tis_be:measuringpoint-types',

  dialog: {
    title: {
      en: 'Select measuring point type',
      sl_SI: 'Izberite tip merilnega mesta'
    },
    listInfo: {
      en: 'Select measuring point type from the list below',
      sl_SI: 'Izberite tip merilnega mesta s spodnjega seznama'
    }
  }
}
