module.exports = {
  entity: 'steps',
  path: 'steps',
  name: {
    en: 'step',
    sl_SI: 'korak'
  },
  networkModel: {
    entity: 'steps',
    read: 'read'
  },
  scope: 'tis_be:steps',

  dialog: {
    title: {
      en: 'Select step',
      sl_SI: 'Izberite korak'
    },
    listInfo: {
      en: 'Select step from the list below',
      sl_SI: 'Izberite korak s spodnjega seznama'
    }
  }
}
