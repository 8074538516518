coreRun.$inject = [
  '$rootScope',
  '$state',
  'StandardUtils',
  'HttpPendingRequestsService',
  '$transitions'
];

function coreRun(
  $rootScope,
  $state,
  StandardUtils,
  HttpPendingRequestsService,
  $transitions
) {
  $rootScope.StandardUtils = StandardUtils;
  $transitions.onStart({}, transition => {
    const stateTo = transition.to();
    if (stateTo.redirectTo) {
      transition.abort();
      $state.go(stateTo.redirectTo, transition.params(), {
        location: 'replace'
      });
    }
  });

  $transitions.onSuccess({}, transition => {
    const toState = transition.to();
    const fromState = transition.from();
    if (toState.name !== fromState.name && fromState.name !== '') {
      HttpPendingRequestsService.cancelAll();
    }
  });
}

export default coreRun;
