import { DateTime } from 'luxon';

AnalysisController.$inject = [
  'AlertingService',
  '$state',
  'gettext',
  'gettextCatalog',
  'DatasetColumnHelper',
  '$timeout',
  'analysisQueryBuilder',
  'analysisSliceService',
  'TranslationService',
  '$scope',
  'MetadataService',
  'periodIntervalConstructor',
  'AnalysisFilterConfigModel',
  'AnalysisFilterBuilder',
  'AnalysisExpressionEnrichment',
  'analysis'
];

function AnalysisController(
  AlertingService,
  $state,
  gettext,
  gettextCatalog,
  DatasetColumnHelper,
  $timeout,
  analysisQueryBuilder,
  analysisSliceService,
  TranslationService,
  $scope,
  MetadataService,
  periodIntervalConstructor,
  AnalysisFilterConfigModel,
  AnalysisFilterBuilder,
  AnalysisExpressionEnrichment,
  analysis
) {
  const vm = this;
  const analysisId = $state.params.id;
  vm.analysisId = analysisId;
  let countryObject;
  let seriesOptions = [];
  let sortOptions = [];
  let seriesOptionsLength;
  vm.showFiltersForm = showFiltersForm;
  vm.showTable = false;

  const defaultFilterEntities = [
    2 /*Measuringpoint*/,
    3 /*Location*/,
    4 /*CostCentre*/,
    235 /*TimeSeriesType*/,
    105 /*EntityTag*/,
    20 /* Alarms */,
    187 /* Severity */,
    30 /* alarmType */,
    251 /* alarmStatuses */,
    252 /* EventTypes */,
    248 /* TangoAgentConnectionConfig */,
    247 /* externalDatasource */
  ];
  init();
  /**
   * @description initialization function
   * @function
   */
  function init() {
    vm.loading = true;
    MetadataService.Loading(true);
    if ($state.current.name === 'analytics-analyses-run') {
      vm.collapsible = {
        show: false
      };
    } else {
      vm.collapsible = {
        show: false
      };
    }
    readAnalysis();
  }
  /**
   * @description the main function, in which all the functions that enrich data are called.
   * @function
   */
  async function readAnalysis() {
    try {
      const analysisData = await AnalysisExpressionEnrichment.enrichExpression(
        analysis
      );
      vm.headerData = constructHeader(analysisData);
      vm.loading = false;
      MetadataService.Loading(false);
      MetadataService.ChangeMetadata(analysis.name, analysis.description);
      const { data: filterConfigs } = await AnalysisFilterConfigModel.read({
        analysis: analysisId
      });
      vm.analysisFilters = await AnalysisFilterBuilder.buildFilters(
        filterConfigs,
        analysis.dataset.columns
      );

      vm.columns = analysisData.dataset.columns;
      vm.config = createTimeFormConfiguration(analysisData);
      vm.group = analysisData.group;
      vm.showTable = analysisData.showTable;
      vm.seriesNames = analysisData.displaySeries;
      vm.xAxisIsAggregationGroup = analysisData.xAxisIsAggregationGroup;
      vm.xAxis = analysisData.xAxis;
      // trigger sort validation
      setSortAddButtonDisabledStatus();
      if ($state.current.name === 'analytics-analyses-run') {
        runAnalysis.apply({ analysis: analysisData }, [true]);
      }
    } catch (err) {
      vm.loading = false;
      MetadataService.Loading(false);
      AlertingService.Error(err);
    }
  }
  /**
   * @description creates an object containing header data.
   * @function
   * @param {Object} analysis - data about the analysis used on this page.
   * @return {Object} Object containing header data
   */
  function constructHeader(analysis) {
    var properties1 = [];
    var properties = [];

    if (analysis.dataset && analysis.dataset._id) {
      properties1.push({
        title: gettext('Dataset'),
        linkTitle: analysis.dataset.name,
        state: 'analytics-datasets-view',
        param: 'id',
        paramValue: analysis.dataset._id,
        type: 'link'
      });
    }

    if (analysis.group && analysis.group._id) {
      properties1.push({
        title: gettext('Group'),
        linkTitle: analysis.group.name,
        state: 'analytics-aggregation-groups-view',
        param: 'id',
        paramValue: analysis.group._id,
        type: 'link'
      });
    }

    if (analysis.calculation && analysis.calculation._id) {
      properties1.push({
        title: gettext('Calculation'),
        linkTitle: analysis.calculation.name,
        state: 'analytics-calculations-view',
        param: 'id',
        paramValue: analysis.calculation._id,
        type: 'link'
      });
    }

    var chartData = [
      {
        title: gettext('Show data table'),
        value: analysis.showTable,
        type: 'radio',
        customOptions: ['YES', 'NO']
      }
    ];

    if (analysis.periodInterval && analysis.periodInterval._id) {
      properties.push({
        title: gettext('Period inerval'),
        linkTitle: analysis.periodInterval.name,
        state: 'analytics-time-interval-filters-view',
        param: 'id',
        paramValue: analysis.periodInterval._id,
        type: 'link'
      });
    }
    if (analysis.country) {
      properties.push({
        title: gettext('Country'),
        value: analysis.country.name,
        type: 'simple'
      });
    }

    properties.push({
      title: gettext('Group'),
      value:
        analysis.analysisType === 'actual'
          ? gettextCatalog.getString('Actual')
          : gettextCatalog.getString('Previous period'),
      type: 'simple'
    });

    var userAccess = [
      {
        type: 'userAccess',
        title: gettext('Users'),
        users: true
      },
      {
        type: 'userAccess',
        title: gettext('Groups'),
        users: false
      }
    ];
    var actions = [
      {
        title: gettext('Run'),
        fn: runAnalysis.bind({ analysis }, false),
        disabledFn: function() {
          return vm.FilterForm.$invalid;
        }
      },
      {
        title: gettext('delete'),
        delete: true,
        id: analysisId,
        endpoint: {
          entity: 'analyses',
          method: 'delete'
        },
        redirectState: 'analytics-analyses-list'
      },
      {
        title: gettext('Duplicate'),
        state: 'analytics-analyses-duplicate',
        param: 'id',
        paramValue: analysis._id
      },
      {
        title: gettext('Update'),
        state: 'analytics-analyses-edit',
        param: 'id',
        paramValue: analysis._id
      }
    ];

    var propertySections = [
      {
        properties: properties
      },
      {
        properties: properties1
      },
      {
        properties: chartData
      },
      {
        title: gettext('Authorized personnel'),
        properties: userAccess,
        identifier: 'analyses',
        target: analysisId,
        type: 'userAccess'
      }
    ];

    return {
      metadata: {
        definition: gettext('Analysis'),
        title: analysis.name,
        description: analysis.description
      },
      actions: actions,
      propertySections: propertySections
    };
  }
  /**
   * @description creates the api object that will be sent with the request to get data for the analysis graph
   * @function
   * @param {Boolean} initialRun - tells us, if the function was called automatically on page open.
   * @param {Object} analysis - data about the analysis used on this page.
   * @return {Object} object that will be sent with the request
   */
  function getApiObject(initialRun, analysis) {
    var startDate = vm.config.dataObj.startDate;
    var endDate = vm.config.dataObj.endDate;
    if (
      analysis.periodInterval &&
      analysis.periodInterval.periodType === 'year'
    ) {
      startDate = DateTime.fromJSDate(new Date())
        .set({ year: vm.config.dataObj.startYear })
        .startOf('Year')
        .toJSDate();
      endDate = DateTime.fromJSDate(new Date())
        .set({ year: vm.config.dataObj.endYear })
        .endOf('Year')
        .toJSDate();
    }
    var filters = angular.copy(vm.analysisFilters);
    // when running first time on run state filter entityItemObjects are not defined yet
    // override them by fetched filter values
    if (initialRun) {
      filters.forEach(function(filter) {
        filter.entityItemObjects = filter.filterValues;
      });
    } else {
      analysis.sort = [];
      vm.config.dataObj.sort.forEach(function(sort) {
        analysis.sort.push({
          name: sort[0]._id,
          type: sort[1].id === 1 ? 'ASC' : 'DESC'
        });
      });
    }
    var analysisObject = analysisQueryBuilder.getQuery({
      analysis,
      analysisFilters: filters,
      startDate,
      endDate,
      activeComparableColumn: vm.config.dataObj.activeComparableColumn
        ? vm.config.dataObj.activeComparableColumn._id
        : analysis.activeComparableColumn
    });
    return analysisObject.apiObject;
  }
  /**
   * @description function that is called when aggregation type is changed
   * @function
   */
  function aggregationTypeChanged() {
    const analysis = this.analysis;
    var config = vm.config.data.find(item => item.name === 'aggregationGroup');
    // when aggregation changes reset sort options
    vm.config.dataObj.sort = [[]];
    if (config && config.options) {
      var selected = _.find(config.options, vm.config.dataObj.aggregationGroup);
      if (selected) {
        analysis.group = selected;
        sortOptions = constructSortOptions(selected, analysis);
      }
    }
    vm.group = analysis.group;
    resetSlice();
  }
  /**
   * @description creates an array of objects with selected sort options
   * @function
   * @param {Object} aggregationGroup - Object containing all possible sort options.
   * @param {Object} analysis - data about the analysis used on this page.
   * @return {Array} array with selected sort options configurations
   */
  function constructSortOptions(aggregationGroup, analysis) {
    if (aggregationGroup && aggregationGroup.condition) {
      var datasetColumns = analysis.dataset.columns || [];
      return aggregationGroup.condition.map(function(condition) {
        return {
          _id: condition,
          name: analysisSliceService.mapDatasetName(condition, datasetColumns)
        };
      });
    }
    return [];
  }
  /**
   * @description resets vm.config.dataObj.sliceEntity and vm.config.dataObj.groupEntit to {}.
   * @function
   */
  function resetSlice() {
    vm.config.dataObj.sliceEntity = {};
    vm.config.dataObj.groupEntity = {};
  }
  /**
   * @description returns entity names available for slice or group action
   * @function
   * @return {Promise} promise that returns an array with available names for slice or group action
   */
  function listSliceValues() {
    return analysisSliceService.listOptions(vm.group.condition, vm.columns);
  }
  /**
   * @description resets vm.config.dataObj.groupEntity to {}
   * @function
   */
  function sliceValuesChanged() {
    vm.config.dataObj.groupEntity = {};
  }
  /**
   * @description resets vm.config.dataObj.sliceEntity to {};
   * @function
   */
  function groupValuesChanged() {
    vm.config.dataObj.sliceEntity = {};
  }
  /**
   * @description creates a configuration files primarily used for filter configurations.
   * @function
   * @param {Object} analysis - data about the analysis used on this
   * @return {Object} returns a configuration file
   */
  function createTimeFormConfiguration(analysis) {
    var sliceGroupOptions;
    if (analysis.sliceEntity) {
      sliceGroupOptions = [
        {
          _id: analysis.sliceEntity,
          name: analysisSliceService.mapDatasetName(
            analysis.sliceEntity,
            vm.columns
          )
        }
      ];
    } else if (analysis.groupEntity) {
      sliceGroupOptions = [
        {
          _id: analysis.groupEntity,
          name: analysisSliceService.mapDatasetName(
            analysis.groupEntity,
            vm.columns
          )
        }
      ];
    }
    var configData = getAnalysisFormConfiguration(sliceGroupOptions, analysis);

    let config = {
      data: configData,
      dataObj: {
        activeComparableColumn: {
          _id: analysis.activeComparableColumn
        },
        aggregationGroup: {
          _id: analysis.group._id
        },
        sliceEntity: {
          _id: analysis.sliceEntity
        },
        groupEntity: {
          _id: analysis.groupEntity
        },
        showBoundaries: analysis.showEnergyValues
      }
    };
    seriesOptions = [];
    if (analysis.calculation) {
      analysis.calculation.series.forEach(function(series) {
        seriesOptions.push({
          name: series.name,
          _id: series.name
        });
      });
      seriesOptionsLength = seriesOptions.length;
    }

    sortOptions = constructSortOptions(analysis.group, analysis);

    var sortId;
    var foundSortObject;
    config.dataObj.sort = analysis.sort.map(function(item) {
      var foundOption = sortOptions.find(function(option) {
        return option._id === item.name;
      });

      sortId = item.type === 'ASC' ? 1 : 2;
      foundSortObject = TranslationService.GetCollectionById(
        'codelists.sortDirections',
        sortId
      );
      return [
        {
          name: foundOption
            ? foundOption.name
            : gettextCatalog.getString('Unknown'),
          _id: item.name
        },
        {
          name: foundSortObject
            ? foundSortObject.name
            : gettextCatalog.getString('Unknown'),
          id: sortId,
          code: item.type
        }
      ];
    });

    var boundariesConfig = _.find(config.data, {
      name: 'showBoundaries'
    });
    var change = boundariesChanged.bind(boundariesConfig);
    change(analysis.showEnergyValues);
    if (analysis.periodInterval) {
      var periodObj = periodIntervalConstructor.getFormObj(
        analysis.periodInterval
      );
      config.dataObj = Object.assign(config.dataObj, periodObj);
    }

    config.dataObj.series = analysis.displaySeries.map(item => {
      var chartTypeName = TranslationService.GetCollectionById(
        'codelists.chartTypes',
        item.chartType
      );
      return [
        {
          name: item.yAxis,
          _id: item.yAxis
        },
        {
          id: item.chartType,
          name: chartTypeName.name
        }
      ];
    });
    config.dataObj.addBtnDisabled = addButtonToggleCheck(
      config.dataObj.series.length,
      seriesOptions.length
    );
    return config;
  }
  /**
   * @description Creates a configuration array for Filters part of the view.
   * @function
   * @param {Array} sliceGroupOptions - contains available slice groups.
   * @param {Object} analysis - data about the analysis used on this
   * @return {Array} returns an array containing configuration for Filters part of the view.
   */
  function getAnalysisFormConfiguration(sliceGroupOptions, analysis) {
    return [
      {
        componentType: 'multiSelect',
        config: {
          label: gettext('Comparable column'),
          placeholder: gettext('Select comparable column'),
          ctrlFn: function() {
            return $timeout(function() {
              return analysis.dataset.comparableColumns.map(column => ({
                _id: column,
                name: column
              }));
            });
          },
          edit: true,
          empty: gettext('There are no comparable columns.')
        },
        name: 'activeComparableColumn'
      },
      {
        componentType: 'multiSelect',
        options: [analysis.group],
        config: {
          label: gettext('Aggregation group'),
          placeholder: gettext('Select aggregation group'),
          refreshFn: {
            entity: 'aggregation-groups',
            method: 'read'
          },
          edit: false,
          empty: gettext('There are no aggregation groups.'),
          onClose: aggregationTypeChanged.bind({ analysis }),
          filterObject: {
            dataset: analysis.dataset._id
          }
        },
        name: 'aggregationGroup'
      },
      {
        componentType: 'multiSelectList',
        name: 'sort',
        removeTitle: gettext('Remove Sort'),
        addLabel: gettext('Add Sort'),
        groupLabel: gettext('Sort'),
        disabledAddBtnParam: 'sortAddButtonDisabled',
        onAdd: setSortAddButtonDisabledStatus,
        onRemove: setSortAddButtonDisabledStatus,
        selectConfigs: [
          {
            componentType: 'autocomplete',
            noDialog: true,
            entity: 'sortOptions',
            required: true,
            filterFn: function(items) {
              var selectedItems = vm.config.dataObj.sort;
              if (selectedItems) {
                var matchItem;
                items = items.filter(function(item) {
                  matchItem = selectedItems.find(function(selectedItem) {
                    if (
                      selectedItem[0] &&
                      selectedItem[0]._id &&
                      selectedItem[0]._id === item._id
                    ) {
                      return true;
                    }
                  });
                  return !matchItem;
                });
              }
              return items;
            },
            getDomainValues: async () => sortOptions,
            searchParamName: 'name',
            label: gettext('Name'),
            placeholder: gettext('Select Raw field name'),
            floatingLabel: gettext('Raw field name')
          },
          {
            componentType: 'autocomplete',
            noDialog: true,
            required: true,
            codelist: 'sortDirections',
            label: gettext('Sort type'),
            placeholder: gettext('Select sort type'),
            floatingLabel: gettext('Sort type')
          }
        ]
      },
      {
        hide: true,
        showParam: 'showDatePicker',
        componentType: 'datePickerFromTo',
        from: 'startDate',
        to: 'endDate',
        labelFrom: gettext('Start date:'),
        labelTo: gettext('End date:'),
        required: true,
        onlyStartRequired: true,
        idFrom: 'from',
        idTo: 'to'
      },
      {
        hide: true,
        showParam: 'showYearPicker',
        componentType: 'yearPickerFromTo',
        nameFrom: 'startYear',
        nameTo: 'endYear',
        fromLabel: gettext('Year from'),
        toLabel: gettext('Year to'),
        maxValueNameFrom: 'endYear',
        maxValueNameTo: 'maxYear',
        minValueNameTo: 'startYear'
      },
      {
        componentType: 'checkBox',
        name: 'showBoundaries',
        label: gettext('Show energy values'),
        action: boundariesChanged
      },
      {
        componentType: 'multiSelectList',
        name: 'series',
        disabledAddBtnParam: 'addBtnDisabled',
        removeTitle: gettext('Remove series'),
        addLabel: gettext('Add series'),
        groupLabel: gettext('Series'),
        onAdd: setSeriesNames,
        onRemove: setSeriesNames,
        selectConfigs: [
          {
            componentType: 'autocomplete',
            noDialog: true,
            entity: 'yAxis',
            required: true,
            getDomainValues: () => {
              return new Promise(resolve => {
                $timeout(() => {
                  resolve(
                    _.differenceWith(
                      angular.copy(seriesOptions),
                      vm.config.dataObj.series,
                      function(item1, item2) {
                        return item1._id === (item2[0] ? item2[0]._id : false);
                      }
                    )
                  );
                }, 300);
              });
            },
            filterObject: {
              order: 'name'
            },
            label: gettext('Y axis'),
            placeholder: gettext('Select Y axis value'),
            floatingLabel: gettext('Y axis value'),
            change: setSeriesNames,
            reset: true
          },
          {
            componentType: 'autocomplete',
            noDialog: true,
            entity: 'chartTypes',
            required: true,
            codelist: 'chartTypes',
            filterObject: {
              order: 'name'
            },
            label: gettext('Chart type'),
            placeholder: gettext('Select chart type'),
            floatingLabel: gettext('Chart type'),
            change: setSeriesNames,
            reset: true
          }
        ]
      },
      {
        componentType: 'multiSelect',
        config: {
          label: gettext('Slice entity'),
          placeholder: gettext('Select slice entity'),
          ctrlFn: listSliceValues,
          onClose: sliceValuesChanged,
          required: false
        },
        options: sliceGroupOptions,
        name: 'sliceEntity'
      },
      {
        componentType: 'multiSelect',
        config: {
          label: gettext('Group entity'),
          placeholder: gettext('Select group entity'),
          ctrlFn: listSliceValues,
          onClose: groupValuesChanged,
          required: false
        },
        options: sliceGroupOptions,
        name: 'groupEntity'
      }
    ];
  }
  /**
   * @description Checks if we the numbers of selected series is equal or greater than all the available series,
   * if it is it returns true (which is used to disable the add series button)
   * @function
   * @param {number} seriesLength - number of selected series
   * @param {number} seriesOptionsLength - number of available series
   * @return {boolean}
   */
  function addButtonToggleCheck(seriesLength, seriesOptionsLength) {
    if (seriesOptionsLength <= seriesLength) {
      return true;
    }
    return false;
  }
  /**
   * @description description.
   * @function
   * @param {dataType} binding/paramName
   * @return {dataType}
   */
  function setSortAddButtonDisabledStatus() {
    if (vm.config && vm.config.dataObj && vm.config.dataObj.sort) {
      if (sortOptions.length <= vm.config.dataObj.sort.length) {
        vm.config.dataObj.sortAddButtonDisabled = true;
      } else {
        vm.config.dataObj.sortAddButtonDisabled = false;
      }

      if (vm.config.dataObj.sort.length === 0) {
        vm.config.dataObj.sort = [[]];
      }
    }
  }
  /**
   * @description function called when the toggle show energy values is clicked
   * @function
   * @param {boolean} value - checks, if it is set to true or false
   * @return {dataType}
   */
  function boundariesChanged(value) {
    this.label = value
      ? gettext('Hide energy values')
      : gettext('Show energy values');
    setBoundaries();
  }
  /**
   * @description sets boundaries, which is called when boundaries change.
   * @function
   */
  function setBoundaries() {
    if (countryObject) {
      vm.boundaries = [
        {
          label: 'Alarm',
          value: countryObject.energyValueAlarm,
          color: 'red',
          align: 'left'
        },
        {
          label: 'Average',
          value: countryObject.energyValueAverage,
          color: 'blue',
          align: 'center'
        },
        {
          label: 'Target',
          value: countryObject.energyValueTarget,
          color: 'green',
          align: 'right'
        }
      ];
    }
  }
  /**
   * @description Sets series name
   * @function
   */
  function setSeriesNames() {
    if (vm.config.dataObj.series) {
      vm.config.dataObj.addBtnDisabled = addButtonToggleCheck(
        vm.config.dataObj.series.length,
        seriesOptionsLength
      );
      vm.seriesNames = [];
      vm.config.dataObj.series.forEach(function(item) {
        if (
          item[0] &&
          typeof item[0]._id !== 'undefined' &&
          item[1] &&
          typeof item[1].id !== 'undefined'
        ) {
          vm.seriesNames.push({
            yAxis: item[0]._id,
            chartType: item[1].id
          });
        }
      });
    }
  }
  /**
   * @description toggles the filter tab on the view.
   * @function
   */
  function showFiltersForm() {
    vm.filterFormIsShown = !vm.filterFormIsShown;
    if (vm.filterFormIsShown) {
      $timeout(function() {
        $scope.$broadcast('seriesSelectIndent');
      });
    }
  }

  /**
   * @description transform sfe-form sort array to analysis sort array.
   * @function
   * @param {Object} analysis
   * @return {Array}
   */
  function getSort(analysis) {
    if (
      vm.config != null &&
      vm.config.dataObj != null &&
      Array.isArray(vm.config.dataObj.sort)
    ) {
      return vm.config.dataObj.sort.reduce((result, sortItem) => {
        if (
          Array.isArray(sortItem) &&
          sortItem.length == 2 &&
          sortItem[0] != null &&
          sortItem[1] != null
        ) {
          result = [
            ...result,
            {
              name: sortItem[0]._id,
              type: sortItem[1].code
            }
          ];
        }

        return result;
      }, []);
    }
    return analysis.sort;
  }
  /**
   * @description Prepares data for running the analysis and calls the function that fetches the data.
   * @function
   * @param {Boolean} initialRun - tells us, if the function was called automatically on page open.
   */
  function runAnalysis(initialRun) {
    let sort = getSort(this.analysis);
    const analysis = {
      ...this.analysis,
      sort
    };
    const filtersToValidate = vm.analysisFilters.map(function(filter) {
      return {
        entity: filter.entity,
        entityItems: initialRun ? filter.filterValues : filter.entityItemObjects
      };
    });
    const validationResult = DatasetColumnHelper.validateFilters(
      filtersToValidate,
      defaultFilterEntities
    );
    if (validationResult.error) {
      AlertingService.Error(validationResult.error);
    } else {
      fetchAnalysisView(initialRun, analysis);
    }
  }
  /**
   * @description calls the functions that prepares the apiObject and assigns it to a variable that is watched,
   * which triggers fetching of the analysis view
   * @function
   * @param {Boolean} initialRun - tells us, if the function was called automatically on page open.
   * @param {Object} analysis - data about the analysis used on this
   */
  function fetchAnalysisView(initialRun, analysis) {
    var requestObj = getApiObject(initialRun, analysis);
    requestObj.limit = 1000000;
    vm.datasetCode = analysis.dataset.code;
    vm.fetchQuery = requestObj;
    vm.group.conditions;
  }
}

export default AnalysisController;
