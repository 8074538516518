import maintenanceFlowAction from '../../../redux/maintenance-flow/action/maintenance-flow.action';
import timeSeriesConfigurationsAction from '../../../redux/time-series-configurations/action/times-series-configurations.action';
import valuesActions from '../../../redux/time-series-processing-values/action/time-series-processing-values.action';

/**
 * @ngdoc service
 * @name common.MaintenanceFlowFormConfigurations
 * @description Opens form dialog
 */

MaintenanceFlowFormConfigurations.$inject = [
  'gettextCatalog',
  'gettext',
  'TranslationService',
  '$ngRedux',
  '$mdDialog',
  'TimeSeriesConfigurationModel',
  'MaintenanceFlowModel',
  'AlertingService',
  'CrudToastFactory',
  'TimeSeriesFlowHelper',
  '$timeout'
];
export default function MaintenanceFlowFormConfigurations(
  gettextCatalog,
  gettext,
  TranslationService,
  $ngRedux,
  $mdDialog,
  TimeSeriesConfigurationModel,
  MaintenanceFlowModel,
  AlertingService,
  CrudToastFactory,
  TimeSeriesFlowHelper,
  $timeout
) {
  /**
   * @description map body data.
   * @function
   * @param {Object} body
   * @param {Object} bodyData
   */
  const mapBodyData = (
    body,
    { maintenanceFlowType, finalValue, initialValue }
  ) => {
    let finalAndInitialValues = {
      finalValue: null,
      initialValue: null
    };
    if (maintenanceFlowType && maintenanceFlowType.id === 510) {
      // Meter Replacement
      finalAndInitialValues = {
        finalValue: finalValue != null ? finalValue : null,
        initialValue: initialValue != null ? initialValue : null
      };
    }
    return {
      ...body,
      ...finalAndInitialValues,
      flowType: maintenanceFlowType ? maintenanceFlowType.id : null
    };
  };

  /**
   * @description get common properties maintenance flow form configuration.
   * @function
   * @param {Object} entityId
   * @param {Array} steps - Array of objects, containing tangoWizard steps.
   * @param {Object} duplicateData - object containing data for duplicating
   * @return {Object} configuration
   */
  async function get(options) {
    const {
      item,
      timeSeries,
      timeSeriesConfiguration
    } = await TimeSeriesFlowHelper.getFlowData({
      ...options,
      entity: 'maintenance-flows'
    });

    const { actionType, steps } = options;

    const flowTypes = TranslationService.GetCollection('codelists.flowTypes');
    let filteredFlowTypes = [];
    if (Array.isArray(flowTypes)) {
      // General Maintenance Flow || Meter Replacement Maintenance Flow
      filteredFlowTypes = flowTypes.filter(
        type => type.id === 500 || type.id === 510
      );
    }
    let title;
    switch (actionType) {
    case 'update':
      title = gettextCatalog.getString('Update maintenance flow');
      break;
    case 'create':
      title = gettextCatalog.getString('Create maintenance flow');
      break;
    case 'duplicate':
      title = gettextCatalog.getString('Duplicate maintenance flow');
      break;
    default:
      title = gettextCatalog.getString('Maintenance flow');
    }

    let config = {
      name: 'maintenanceFlow',
      actions: [
        {
          //SAVE ACTION
          title: gettext('Save'),
          fn: async form => {
            let bodyData = form.getValues();
            let body = mapBodyData({}, bodyData);
            //UPDATE
            if (
              item != null &&
              timeSeriesConfiguration != null &&
              actionType === 'update'
            ) {
              const flowParams = {
                query: { timeSeriesId: item.timeSeries, id: item._id },
                body
              };
              const configurationParams = {
                query: {
                  timeSeriesId: item.timeSeries,
                  id: timeSeriesConfiguration._id
                },
                body: {
                  validFrom: bodyData.validFrom[0],
                  validTo: Array.isArray(bodyData.validTo)
                    ? bodyData.validTo[0]
                    : null
                }
              };
              let actionFlow = maintenanceFlowAction.updateMaintenanceFlow;

              await $ngRedux.dispatch(actionFlow(flowParams));
              if (timeSeriesConfiguration.validTo == null) {
                let action = timeSeriesConfigurationsAction.updateTimeSeriesConfigurations(
                  configurationParams
                );
                $ngRedux.dispatch(action);
              }
            } else {
              //CREATE
              try {
                let timeSeriesConfigurationBody = {
                  validFrom: bodyData.validFrom[0],
                  flowRef: 500
                };
                let createdFlow = await MaintenanceFlowModel.create(
                  { timeSeriesId: timeSeries._id },
                  body
                );
                let actionFlow = maintenanceFlowAction.addMaintenanceFlow;
                await $ngRedux.dispatch(actionFlow({ result: createdFlow }));
                timeSeriesConfigurationBody.flow = createdFlow.data._id;
                let createdTimeSeriesConfiguration = await TimeSeriesConfigurationModel.create(
                  { timeSeriesId: timeSeries._id },
                  timeSeriesConfigurationBody
                );
                let action =
                  timeSeriesConfigurationsAction.addTimeSeriesConfigurations;
                await $ngRedux.dispatch(
                  action({ result: createdTimeSeriesConfiguration })
                );
                CrudToastFactory.toast('create');
              } catch (err) {
                AlertingService.Error(err);
              }
            }
            //use timeout to give time to update processing values
            await $timeout();
            const reloadValueAction = valuesActions.addUpdateValuesTag({
              id: timeSeries._id,
              state: { content: true, detail: true }
            });
            $ngRedux.dispatch(reloadValueAction);

            $mdDialog.hide();
          },
          color: 'primary',
          disabledFn: form => {
            if (form.formValidity() === true) {
              return false;
            }
            return true;
          }
        }
      ],
      title,
      fields: [
        {
          id: 'maintenanceFlowType',
          title: gettextCatalog.getString('Maintenance flow type'),
          type: {
            name: 'autocomplete',
            options: {
              items: filteredFlowTypes,
              filter: items => {
                let isNumber;
                if (timeSeries != null) {
                  isNumber =
                    timeSeries.dataType == 2 || timeSeries.dataType == 3;
                } else if (Array.isArray(steps)) {
                  const commonStep = steps[0];
                  if (
                    commonStep != null &&
                    commonStep.api != null &&
                    typeof commonStep.api.getValue === 'function'
                  ) {
                    const dataType = commonStep.api.getValue('dataType');
                    if (dataType != null) {
                      isNumber = dataType.id == 2 || dataType.id == 3;
                    }
                  }
                }
                if (!isNumber) {
                  return items.filter(item => item.id == 500);
                }
                return items;
              },
              display: data => {
                return {
                  text: `${
                    data.name
                      ? data.name
                      : gettextCatalog.getString(
                        'Unknown maintenance flow type'
                      )
                  }`
                };
              }
            }
          },
          initialize: () => {
            return item
              ? TranslationService.GetCollectionById(
                'codelists.flowTypes',
                item.flowType
              )
              : null;
          },
          required: true
        },
        {
          id: 'finalValue',
          title: gettextCatalog.getString('Final value'),
          type: {
            name: 'text',
            options: {
              type: 'numerical'
            }
          },
          initialize: () => {
            return item != null && item.finalValue != null
              ? item.finalValue
              : '';
          },
          hide: api => {
            if (api && typeof api.getValue === 'function') {
              const maintenanceFlowType = api.getValue('maintenanceFlowType');
              if (
                maintenanceFlowType != null &&
                maintenanceFlowType.id == 510
              ) {
                return false;
              }
            }
            return true;
          },
          required: false
        },
        {
          id: 'initialValue',
          title: gettextCatalog.getString('Initial value'),
          type: {
            name: 'text',
            options: {
              type: 'numerical'
            }
          },
          initialize: () => {
            return item != null && item.initialValue != null
              ? item.initialValue
              : '';
          },
          hide: api => {
            if (api && typeof api.getValue === 'function') {
              const maintenanceFlowType = api.getValue('maintenanceFlowType');
              if (
                maintenanceFlowType != null &&
                maintenanceFlowType.id == 510
              ) {
                return false;
              }
            }
            return true;
          },
          disable: api => {
            if (api != null && typeof api.getValue === 'function') {
              const validTo = api.getValue('validTo');
              const initialValue = api.getValue('initialValue');
              if (validTo != null) {
                return false;
              } else if (validTo == null && initialValue != null) {
                api.setValue('initialValue', '');
              }
            }
            return true;
          },
          required: false
        }
      ]
    };
    return config;
  }

  return { get, mapBodyData };
}
